import { StyleSheet } from 'react-native';
import { Colors, Spacings, fontSize } from 'shared/styles';
import * as SharedStyles from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.LightBlue,
    flex: 1,
    flexDirection: 'row',
  },
  innerContainer: {
    backgroundColor: Colors.White,
    flex: 1,
    shadowColor: '#00000029',
    borderRadius: 8,
    shadowRadius: 2,
  },
  innerContainerStyle: { width: '100%', height: '100%' },
  touchableTitleTabStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabsContainerStyle: { height: 56 },
  rightPanelContainerTitle: { width: 292, height: 65, marginBottom: 50 },
  rightPanelContainerTitleTextStyle: { fontWeight: '900', fontFamily: 'Lato, Black', fontSize: 26 },
  rightPanelContainerTitleInnerTextStyle: {
    fontWeight: 'normal',
  },
  radioContainer: { height: 75 },
  radioButtonStyle: { width: 300 },
  radioTextStyle: { width: 300, fontWeight: 'normal', fontSize: 14, fontFamily: 'Lato, Regular' },
  addSkillContainer: {
    width: '100%',
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0,
  },
  addSkillTouchable: {
    width: 180,
    height: 40,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#0000004D',
    shadowRadius: 4,
    shadowOpacity: 1,
  },
  addSkillTextStyle: {
    color: Colors.White,
    fontSize: 16,
    fontFamily: 'Lato, Black',
    fontWeight: '800',
  },
  excelTouchable: { position: 'absolute', right: 300 },
  horizontalScrollView: {
    flex: 1,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  horizontalInnerScrollView: {
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    width: '100%',
  },
  tableComponentContainer: {
    height: '100%',
    flexDirection: 'row',
  },
  inputTag: {
    height: 68,
    justifyContent: 'flex-end',
  },
  registerTextStyle: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  touchableAddTraining: { flexDirection: 'row', alignItems: 'center', zIndex: 0 },
  touchableFilterTrainingContainer: {
    borderRadius: 18,
    width: 260,
    height: 36,
    backgroundColor: SharedStyles.Colors.Background,
    paddingLeft: Spacings.Unit * 5,
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: Spacings.Medium,
    zIndex: 0,
  },
  touchableFilterTrainingTextStyle: {
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    ...SharedStyles.TextFontStyles.LatoNormal,
    paddingBottom: 2,
  },
  plusIconContainer: { marginRight: SharedStyles.Spacings.Small },
  addNotPracticalTrainingTextStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Black,
    paddingTop: Spacings.Unit / 2,
  },
  displayNone: { display: 'none' },
  rightPanelContainer: { flex: 1, padding: 0, marginLeft: Spacings.Medium },
  tabContainer: {
    flexWrap: 'wrap',
  },
});

export default styles;
