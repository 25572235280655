import React, { useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { TrainingWorkstationTableComponent } from '../component';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import { TableRow } from 'shared/ui-component/Table';

interface Props {
  training: API.Training;
  setWorkstationCount: (count: number) => void;
}

export interface TrainingWorkstationTableRow extends TableRow {
  workstationOrOrgUnit: API.TreeObject;
  orgUnit: API.OrganizationalUnit;
  workstationRequirmentLevel?: number;
}

export const TrainingWorkstationTableContainer: React.FC<Props> = props => {
  const { training, setWorkstationCount } = props;
  const [rows, setRows] = useState<TrainingWorkstationTableRow[]>([]);
  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const workstationOrOrgUnits = await API.getTrainingWorkstationOrOrgUnit(training.id);
      if (!isMounted.current) return;
      if (API.isFailure(workstationOrOrgUnits)) {
        logger.warn(workstationOrOrgUnits);
        return;
      }

      const _result: TrainingWorkstationTableRow[] = [];

      _.map(workstationOrOrgUnits, workstationOrOrgUnit => {
        let orgUnit = API.getOrganizationalUnit(workstationOrOrgUnit.parentId);
        if (API.isFailure(orgUnit)) {
          logger.warn(orgUnit);
          return;
        }
        _result.push({
          key: workstationOrOrgUnit.id,
          workstationOrOrgUnit: workstationOrOrgUnit,
          orgUnit: orgUnit,
        });
      });

      if (!isMounted.current) return;
      setRows(_result);
      setWorkstationCount(_result.length);
    });
  }, []);

  return <TrainingWorkstationTableComponent rows={rows} />;
};
