import React from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import styles from './styles';
import { t } from 'shared/localisation/i18n';
import { ModalUtils } from 'shared/ui-component/Modal';
import { ToggleSwitch } from 'shared/ui-component/Button/ToggleButton';

interface WarningOptionProps {
  workerWorkstation: API.WorkerWorkstation;
}

export const WarningOption: React.FC<WarningOptionProps> = props => {
  const { workerWorkstation } = props;

  const modal = ModalUtils.useModal();

  const onPress = async () => {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:workstationWorkerLevelMenu.unSilenceWarning'),
        warningCancelButton: t('common:button.no'),
        warningAcceptButton: t('common:button.yes'),
        warningAcceptCallback: () => {
          API.unSilenceWorkerWorkstationWarning(workerWorkstation);
        },
      }),
    );
  };

  return (
    <>
      {workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.SILENT ? (
        <TouchableOpacity
          ref={useCallOnHover<TouchableOpacity>()}
          style={[styles.optionContainer]}
          onPress={onPress}
        >
          <Text style={styles.OptionText}>
            {t('alex:workstationWorkerLevelMenu.alertSilenced')}
          </Text>
          <View style={styles.fillerView} />
          <ToggleSwitch value={true} onValueChange={onPress} />
        </TouchableOpacity>
      ) : (
        <></>
      )}
    </>
  );
};
