import React, { useEffect, useState } from 'react';
import { MyAccountComponent } from '../component';
import * as API from 'shared/backend-data';
import { InteractionManager } from 'react-native';
import { useIsMounted } from '../../../hooks/IsMounted';
import logger from 'shared/util/Logger';
import { getWorkersAdmins } from 'shared/util/Worker';
import {
  showWarningWorkerEditAdd,
  WorkerEditAddWarning,
} from 'skillmgtweb/src/components/my-factory/workers/component/modify-worker-modal/warningModal';
import { ModalUtils } from '../../../ui-component/Modal';
import { ProfilePictureFile } from 'skillmgtweb/src/components/my-factory/workers/component/modify-worker-modal/container';
import { isValidPhoneNumber } from 'shared/util/Worker';
import * as Pattern from 'shared/util/const';

interface Props {
  showHideModal: (value: boolean) => void;
}

enum ModifyUserInputIds {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Phone = 'Phone',
  enableMobileNotif = 'enableMobileNotif',
  enableEmailNotif = 'enableEmailNotif',
  RegistrationNumber = 'RegistrationNumber',
}

interface CollectedData {
  familyName?: string;
  firstName?: string;
  phone?: string | null;
  enableMobileNotif?: string | null;
  enableEmailNotif?: string | null;
  profilePicture?: string | null;
  imageFile?: File;
  matricule?: string | null;
}

export const MyAccount: React.FC<Props> = props => {
  const closeModal = () => props.showHideModal(false);
  const [showChangePasswordModal, setShowChangePassowrdModal] = useState(false);
  const [showLoader, setLoading] = useState(false);
  const [admins, setAdmins] = useState<API.Worker[]>();
  const [worker, setWorker] = useState<API.Worker>();
  const isMounted = useIsMounted();
  const modal = ModalUtils.useModal();
  let collectedData: CollectedData = {};
  const [profilePictureFile, setProfilePictureFile] = useState<ProfilePictureFile | null>(null);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const worker = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(worker)) {
        logger.warn(worker);
      } else {
        setWorker({ ...worker });
      }
    });
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (worker) {
        const managersAndAdmins = await getWorkersAdmins();
        setAdmins(managersAndAdmins);
      }
    });
  }, [worker]);

  async function onFileChange(e: any) {
    if (!worker) return;
    setLoading(true);

    try {
      if (e.target.files) {
        const file = e.target.files[0];
        collectedData.imageFile = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          setProfilePictureFile({ link: '' + e.target?.result, file: file });
        };
      }
    } catch (error) {
      logger.error(error);
    }

    const file = e.target.files[0];
    const _worker = await API.uploadWorkerPicture(worker, file);
    if (API.isFailure(_worker)) {
      logger.warn(_worker);
    } else {
      setWorker({ ..._worker });
    }
    setLoading(false);
  }

  const handleInputs = (inputName: string, value: string) => {
    switch (inputName) {
      case ModifyUserInputIds.FirstName:
        collectedData.firstName = value;
        break;
      case ModifyUserInputIds.LastName:
        collectedData.familyName = value;
        break;
      case ModifyUserInputIds.Phone:
        if (value !== '') {
          collectedData.phone = value;
        } else {
          collectedData.phone = undefined;
        }
        break;
      case ModifyUserInputIds.RegistrationNumber:
        collectedData.matricule = value;
        break;
      case ModifyUserInputIds.enableMobileNotif:
        collectedData.enableMobileNotif = value;
        break;
      case ModifyUserInputIds.enableEmailNotif:
        collectedData.enableEmailNotif = value;
        break;
    }
  };

  function verifyAndNormalizeData(): boolean {
    if (collectedData) {
      if (collectedData.firstName) {
        if (!collectedData.firstName.length) {
          showWarningWorkerEditAdd(modal, WorkerEditAddWarning.MissingRequiredFields);
          return false;
        }
      }

      if (collectedData.familyName) {
        if (!collectedData.familyName.length) {
          showWarningWorkerEditAdd(modal, WorkerEditAddWarning.MissingRequiredFields);
          return false;
        }
      }

      if (collectedData.matricule) {
        if (collectedData.matricule === '') {
          collectedData.matricule = undefined;
        } else {
          if (!Pattern.workerPersonalIdPattern.test(collectedData.matricule)) {
            showWarningWorkerEditAdd(modal, WorkerEditAddWarning.InvalidMatricule);
            return false;
          }
        }
      }

      if (collectedData.phone) {
        if (collectedData.phone === '') {
          collectedData.phone = undefined;
        } else {
          if (!isValidPhoneNumber(collectedData.phone)) {
            showWarningWorkerEditAdd(modal, WorkerEditAddWarning.InvalidPhoneNumber);
            return false;
          }
        }
      }
    }

    return true;
  }

  async function updateUser() {
    if (!worker) return;
    if (!verifyAndNormalizeData())
      return API.createFailure_Unspecified('error while updating worker');

    let imageKey: string | undefined = undefined;
    if (profilePictureFile) {
      const s3Object = await API.uploadFile(
        profilePictureFile.file,
        API.StorageVisibility.public,
        collectedData.profilePicture ?? undefined,
      );
      if (!isMounted.current) return;
      if (API.isFailure(s3Object)) {
        logger.warn(s3Object);
      } else {
        imageKey = s3Object.key;
      }
    }

    const workerUpdateInput: API.WorkerPartialUpdateInput = {
      id: worker.id,
      familyName:
        collectedData && collectedData.familyName ? collectedData.familyName : worker.familyName,
      firstName:
        collectedData && collectedData.firstName ? collectedData.firstName : worker.firstName,
      phone: collectedData && collectedData.phone ? collectedData.phone : worker.phone,
      profilePicture: imageKey ?? collectedData.profilePicture,
      matricule: collectedData.matricule,
    };

    const _worker = await API.updateWorker(workerUpdateInput);
    if (!isMounted.current) return;
    if (API.isFailure(_worker)) {
      showWarningWorkerEditAdd(modal, _worker);
    }

    return _worker;
  }

  async function submit() {
    setLoading(true);
    const _editWorker = await updateUser();
    if (API.isFailure(_editWorker)) {
      logger.warn(_editWorker);
      setLoading(false);
      return;
    }
    closeModal();
    setLoading(false);
  }

  return (
    <MyAccountComponent
      showLoader={showLoader}
      worker={worker}
      showChangePasswordModal={showChangePasswordModal}
      closeModal={closeModal}
      onFileChange={onFileChange}
      handlePassowrdChangeModal={setShowChangePassowrdModal}
      admins={admins}
      submit={submit}
      handleInputs={handleInputs}
    />
  );
};
