import React, { useEffect, useRef, useContext } from 'react';
import { View, Text, Animated } from 'react-native';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { WorkstationOrOrgUnitPath } from 'shared/components/WorkstationOrOrgUnitPath';

import Styles from './Styles';

export const HeaderTitle: React.FC = props => {
  const { firstTitle, secondTitle, parentIdsWithDataType } = useContext(HeaderTitleContext);

  const headerTextContainerOpacity = useRef(new Animated.Value(0)).current;
  const headerSecondTextOpacity = useRef(new Animated.Value(0)).current;
  const textAnimateDuration = 400;

  useEffect(() => {
    if (!firstTitle) return;
    headerTextContainerOpacity.setValue(0);
    Animated.timing(headerTextContainerOpacity, {
      useNativeDriver: false,
      toValue: 1,
      duration: textAnimateDuration,
    }).start();
  }, [firstTitle]);

  useEffect(() => {
    if (!secondTitle) return;
    headerSecondTextOpacity.setValue(0);
    Animated.timing(headerSecondTextOpacity, {
      useNativeDriver: false,
      toValue: 1,
      duration: textAnimateDuration,
    }).start();
  }, [secondTitle]);

  return (
    <View style={Styles.headerTextContainer}>
      <Animated.View style={[Styles.headerMainText, { opacity: headerTextContainerOpacity }]}>
        <Text numberOfLines={1} style={Styles.innerRowText}>
          {capitalizeFirstLetter(firstTitle)}
        </Text>
        <Animated.Text
          ellipsizeMode="clip"
          numberOfLines={1}
          style={[Styles.innerRowBoldText, { opacity: headerSecondTextOpacity }]}
        >
          {secondTitle}
        </Animated.Text>
      </Animated.View>
      {parentIdsWithDataType && (
        <WorkstationOrOrgUnitPath parentIdsWithDataType={parentIdsWithDataType} />
      )}
    </View>
  );
};
