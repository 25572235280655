import React, { useState, useRef, useEffect } from 'react';
import { View, TextInput, InteractionManager } from 'react-native';
import { Style } from './style';
import { Colors, Spacings } from 'shared/styles';
import _, { isArray, isFunction } from 'lodash-es';
import { AttachFile } from 'shared/ui-component/AttachFile/index';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { t } from 'shared/localisation/i18n';
import { InputTextProps } from 'shared/ui-component/Input/InputText/InputText';
import * as API from 'shared/backend-data';
import { convertDataURLtoFile } from 'shared/util-ts/Functions';
import { logger } from '../../../util/Logger';
import { ModalUtils } from 'shared/ui-component/Modal';
import { TextButton } from '../../Button';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { FileRow } from 'shared/ui-component/AttachFile/FileRow';
import { isSameFile } from '../../../util/S3Upload';

export enum ParserType {
  CaseInsensitive,
  Trim,
}

export interface InputProofProps extends InputTextProps {
  workerIdAndName: { id: string; name: string };
  validatorName: string;
  skillIdAndName: { id: string; name: string };
  trainingSessionId: string;
  itemIndex?: number;
  initialProofBunble?: API.ProofBundle;
}

export const InputProof: React.FC<InputProofProps> = props => {
  const {
    workerIdAndName,
    defaultValue,
    validatorName,
    skillIdAndName,
    trainingSessionId,
    itemIndex,
    initialProofBunble,
  } = props;

  const [editing, setEditing] = useState(false);
  const [inputText, setInputText] = useState<string>('');
  const [displayedText, setDisplayedText] = useState<string>('');
  const [currentHeight, setCurrentHeight] = useState<number>(Spacings.Large);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [autoCertificateFile, setAutoCertificateFile] = useState<File>();
  const [proofBundleFiles, setProofBundleFiles] = useState<API.S3ObjectInput[]>([]);
  const [proofBundle, setProofBundle] = useState<API.ProofBundle>();
  const [displaySeeMore, setDisplaySeeMore] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const inputRef = props.inputRef ?? useRef<TextInput>(null);
  const parsedTextRef = useRef<string>('');
  const isMounted = useIsMounted();
  const fixedHeightForLongComments = 56;
  const fixedLengthForLongComments = 38;
  const modal = ModalUtils.useModal();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const _proofBundles = await API.getProofBundles(trainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(_proofBundles)) {
        logger.warn(_proofBundles);
        return _proofBundles;
      }

      if (_proofBundles)
        setProofBundle(
          _proofBundles.find(proofBundle => proofBundle.skillId === skillIdAndName.id),
        );
    });
  }, []);

  useEffect(() => {
    setProofBundle(initialProofBunble);
  }, [initialProofBunble]);

  useEffect(() => {
    if (proofBundle) {
      setProofBundleFiles([...proofBundle.files]);

      if (proofBundle.description) {
        setInputText(proofBundle.description);
      }
    }
  }, [proofBundle]);

  useEffect(() => {
    if (editing) {
      setDisplaySeeMore(false);
    } else {
      if (inputText.length > 30) {
        setCurrentHeight(fixedHeightForLongComments);
        setDisplayedText(inputText.substring(0, fixedLengthForLongComments) + '... ');
        setDisplaySeeMore(true);
      } else {
        setDisplayedText(inputText);
        setDisplaySeeMore(false);
      }
    }
  }, [inputText]);

  useEffect(() => {
    parseInputText(defaultValue ?? '');
  }, [defaultValue]);

  const parseInputText = (text: string) => {
    setDisplayedText(text);
    setInputText(text);
    if (isArray(props.parser)) {
      if (props.parser.includes(ParserType.Trim)) {
        text = text.trim();
      } else if (props.parser.includes(ParserType.CaseInsensitive)) {
        text = text.toLowerCase();
      }
    } else if (isFunction(props.parser)) {
      text = props.parser(text);
    } else {
      text = text.trim();
    }
    parsedTextRef.current = text;

    return text;
  };

  async function createAutoCertificate() {
    setLoader(true);
    const proofFile = await API.createCertificateProof(
      [workerIdAndName.name],
      [skillIdAndName.name],
      validatorName,
      false,
      false,
      undefined,
      undefined,
    );

    const file = convertDataURLtoFile(proofFile.fileContentBase64, proofFile.name);

    setAutoCertificateFile(file);

    const s3Object = await API.uploadFile(file, API.StorageVisibility.protected);
    if (!isMounted.current) return;
    if (API.isFailure(s3Object)) {
      logger.warn('Failed to upload file', s3Object);
      return;
    }

    await createOrUpdateProofBundle([s3Object]);
    setLoader(false);
    modal.displayModal(
      ModalUtils.toastConfig({
        text: t('common:attachment.attachmentAdded'),
      }),
    );
  }

  async function createOrUpdateFileProof(filesList: FileList) {
    if (filesList.length) {
      setLoader(true);
      const s3Objects: API.S3Object[] = [];
      for (let i = 0; i < filesList.length; i++) {
        const s3Object = await API.uploadFile(filesList.item(0)!, API.StorageVisibility.protected);
        if (!isMounted.current) return;
        if (API.isFailure(s3Object)) {
          logger.warn('Failed to upload file', s3Object);
          return;
        }
        s3Objects.push(s3Object);
      }

      await createOrUpdateProofBundle(s3Objects);
      setLoader(false);
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('common:attachment.attachmentAdded'),
        }),
      );
    }
  }

  /**
   *
   * @param s3Files (to be added to the existing files)
   * @returns
   */
  async function createOrUpdateProofBundle(s3Files?: API.S3Object[]) {
    if (proofBundle) {
      const updateProofBundle = await API.updateProofBundle({
        id: proofBundle.id,
        files: [...proofBundle.files, ...(s3Files ?? [])],
        description: inputText,
      });
      if (API.isFailure(updateProofBundle)) {
        logger.warn(updateProofBundle);
        return;
      }
      if (updateProofBundle) setProofBundle(updateProofBundle);
    } else {
      const _proofBundle = await API.createTrainingProofBundle(
        {
          startingDate: new Date().toISOString(),
          files: s3Files ?? [],
          review: {
            state: API.ReviewState.DRAFT,
          },
          description: inputText,
        },
        trainingSessionId,
        workerIdAndName.id,
        skillIdAndName.id,
        true,
      );
      if (API.isFailure(_proofBundle)) return _proofBundle;

      if (_proofBundle) setProofBundle(_proofBundle[0]); 
    }
  }

  const onEdit = () => {
    setEditing(true);
    setDisplaySeeMore(false);
    setDisplayedText(inputText);
    setCurrentHeight(contentHeight);
    if (props.onFocus) props.onFocus(props.inputId ? props.inputId : '', parsedTextRef.current);
    props.notEditable && props.onDisabledClicked ? props.onDisabledClicked() : setEditing(true);
  };

  const handleTextChange = (inputText: string) => {
    const _parsedText = parseInputText(inputText);
    if (props.onTextChange) props.onTextChange(props.inputId ? props.inputId : '', _parsedText);
  };

  const onBlur = () => {
    setEditing(false);
    setTimeout(() => {
      if (isMounted.current) {
        setEditing(false);
        if (props.onBlur) props.onBlur(props.inputId ? props.inputId : '', parsedTextRef.current);
        setInputText(parsedTextRef.current);
      }
    }, 200);
    if (currentHeight !== Spacings.Large) {
      if (!inputText.length) {
        setCurrentHeight(Spacings.Large);
        setDisplaySeeMore(false);
        setDisplayedText(t('alex:scheduleTrainingModal.description'));
      } else {
        setCurrentHeight(fixedHeightForLongComments);
        setDisplaySeeMore(true);
        setDisplayedText(displayedText.substring(0, fixedLengthForLongComments) + '... ');
      }
    }
    createOrUpdateProofBundle();
  };

  async function existingFileDelete(s3File: API.S3ObjectInput) {
    const _files = proofBundleFiles.filter(file => !API.isSameS3Object(file, s3File));

    if (proofBundle) {
      const updateProofBundle = await API.updateProofBundle({ ...proofBundle, files: _files });
      if (API.isFailure(updateProofBundle)) {
        logger.warn(updateProofBundle);
        return;
      }

      setProofBundle(updateProofBundle);
    }

    setProofBundleFiles(_files);

    modal.displayModal(
      ModalUtils.toastConfig({
        text: t('common:attachment.attachmentRemoved'),
      }),
    );
  }
  return (
    <View>
      <View style={[Style.inputContainer, props.style, { height: currentHeight }]}>
        <TextInput
          ref={inputRef}
          style={[Style.inputText, { height: currentHeight }]}
          testID={props.inputId}
          editable={!props.notEditable}
          placeholder={!editing ? props.placeHolder || 'Type here..' : ''}
          placeholderTextColor={Colors.Grey}
          multiline={true}
          autoCapitalize="none"
          value={displayedText}
          onChangeText={(text: string) => {
            handleTextChange(text);
          }}
          onFocus={onEdit}
          onBlur={onBlur}
          onContentSizeChange={event => {
            if (editing) setCurrentHeight(event.nativeEvent.contentSize.height);
            setContentHeight(event.nativeEvent.contentSize.height);
          }}
        />

        {displaySeeMore && (
          <TextButton
            onPress={onEdit}
            containerStyle={{
              top: Spacings.Standard,
              right: 55,
              position: 'absolute',
            }}
            text={t('common:button.seeMore')}
          ></TextButton>
        )}
        <AttachFile
          style={Style.attachFileContainer}
          addFileButtonOnlyWithIcon
          isProof
          createAutoCertificate={createAutoCertificate}
          autoCertificateFile={autoCertificateFile}
          initialFiles={proofBundleFiles}
          onFilesChange={s3Files => setProofBundleFiles([...s3Files])}
          createOrUpdateFileProof={files => createOrUpdateFileProof(files)}
          filesHeight={currentHeight}
          itemIndex={itemIndex}
          showFiles={false}
        />
      </View>
      <View style={Style.filesList}>
        {proofBundleFiles.map((file, index) => {
          return (
            <FileRow
              key={index}
              file={file}
              index={index}
              hideFileName
              onDelete={() => existingFileDelete(file)}
              containerStyle={{ marginRight: Spacings.Unit }}
            />
          );
        })}
      </View>
      {loader && <Loader />}
    </View>
  );
};
