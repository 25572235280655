import { StyleSheet } from 'react-native';
import { TextFontStyles, Spacings, Colors, fontSize } from '../../styles';

const Styles = StyleSheet.create({
  cardContainerBottom: {
    position: 'absolute',
    top: Spacings.Standard,
    padding: Spacings.Small,
  },
  cardContainerMiddle: {
    position: 'absolute',
    top: -35,
    left: 75,
    padding: Spacings.Small,
  },
  cardContainerTop: {
    position: 'absolute',
    top: -75,
    left: 5,
    padding: Spacings.Small,
    maxWidth: 180,
  },
  tableText: {
    textAlign: 'left',
    fontSize: 14,
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
    wordBreak: 'break-all',
  },
  selectedText: {
    ...TextFontStyles.LatoBlack,
  },
  container: {
    width: '100%',
    flexGrow: 1,
    flexShrink: 1,
  },
  tagIconContainer: {
    backgroundColor: Colors.Background,
    borderRadius: Spacings.Standard,
    marginRight: Spacings.Small,
    width: 32,
    height: 32,
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  pathStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: Colors.Grey,
  },
  pathContainerStyle: {
    marginLeft: 5,
  },
  textDescriptionContainer: {
    width: '100%',
  },
  fakeTextContainter: {
    opacity: 0,
    position: 'absolute',
  },
});

export default Styles;
