import React from 'react';
import { TouchableOpacity } from 'react-native';

export interface WithTouchableAction {
  onPress?: () => void;
  disabled?: boolean;
}

/**
 * A HOC we can use on any Component that has a 'onPress' prop
 * it ads a Touchable Wrapper if onPress is provided
 * @param Component: any Component with optional onPress prop
 */
export const WithTouchableWrapper = <T extends WithTouchableAction>(
  Component: React.FC<T>,
): React.FC<T> => {
  const withTouchable: React.FC<T> = props => {
    const { onPress } = props;

    if (onPress !== undefined) {
      return (
        <TouchableOpacity onPress={onPress} disabled={props.disabled}>
          <Component {...props} />
        </TouchableOpacity>
      );
    } else {
      return <Component {...props} />;
    }
  };

  
  withTouchable.displayName = `WithTouchable(${Component.displayName ||
    Component.name ||
    'Component'})`;

  return withTouchable;
};

