import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { ShadowOnHoverButton } from 'shared/ui-component/Button';
import { Spacings, Colors } from 'shared/styles';
import Styles from './style';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { IconSVG } from 'shared/ui-component/Icon';
import { InputText } from 'shared/ui-component/Input';
import * as API from 'shared/backend-data';
import { shiftColors } from 'shared/util/Workstation';
import { ReassignmentModal } from 'skillmgtweb/src/components/reassignment-modal/container';
import * as _ from 'lodash-es';
import { useModal } from 'shared/ui-component/Modal/Modal';
import { ModalUtils } from 'shared/ui-component/Modal';
import { ColorPicker } from 'shared/ui-component/ColorPicker';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import logger from 'shared/util/Logger';

interface RightPanelProps {
  shifts?: API.Shift[];
  unit: API.OrganizationalUnit | undefined;

  setIsLoading: (loader: boolean) => void;
  setShiftIds: (shiftIds: string[]) => void;
  handleShifts: (shifts: API.ShiftCreateInput[]) => void;
}

const infoIcon = require('shared/assets/svg/icon.info.svg').default;
const shiftIcon = require('shared/assets/svg/icon.shift.svg').default;
const editIcon = require('shared/assets/svg/icon.edit.svg').default;
const deleteIcon = require('shared/assets/svg/icon.trash.svg').default;
const moreIcon = require('shared/assets/svg/icon.more.treeweb.svg').default;

export const RightPanel: React.FC<RightPanelProps> = props => {
  const { shifts, unit, setIsLoading, setShiftIds, handleShifts } = props;

  const [showAddShift, setShowAddShift] = useState<boolean>(false);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [shiftColor, setShiftColor] = useState<string>(Colors.Yellow);
  const [newlyAddedShifts, setNewlyAddedShifts] = useState<API.ShiftCreateInput[]>();
  const [shiftToEdit, setShiftToEdit] = useState<API.ShiftCreateInput | undefined>();
  const [existingShifts, setExistingShifts] = useState<API.Shift[] | undefined>();
  const [shiftsToEditOrCreate, setShiftsToEditOrCreate] = useState<API.ShiftCreateInput[]>();
  const [showReassignmentModal, setShowReassignmentModal] = useState<boolean>(false);
  const [shiftToDelete, setShiftToDelete] = useState<API.Shift>();

  const modal = useModal();
  const ref = useDetectOutsideClick<View>(() => setShowColorPicker(false));

  useEffect(() => {
    setExistingShifts(shifts);
  }, [shifts]);

  useEffect(() => {
    if (existingShifts)
      setShiftIds(
        existingShifts.map(shift => {
          return shift.id;
        }),
      );
  }, [existingShifts]);

  useEffect(() => {
    if (shiftsToEditOrCreate) handleShifts(shiftsToEditOrCreate);
  }, [shiftsToEditOrCreate]);

  function addShift(shiftName: string) {
    const shift: API.ShiftCreateInput = {
      name: shiftName,
      color: shiftColor,
      parentId: unit?.id ?? '',
    };
    const _newlyAddedShifts = newlyAddedShifts ? [...newlyAddedShifts] : [];
    _newlyAddedShifts.push(shift);

    setNewlyAddedShifts(_newlyAddedShifts);
    setShiftsToEditOrCreate(shiftsToEditOrCreate ? [...shiftsToEditOrCreate, shift] : [shift]);
  }

  function editShift(shiftName: string) {
    if (!shiftToEdit) return;

    if (shiftToEdit.id) {
      if (!existingShifts) return;

      const _existingShifts = [...existingShifts];
      const shiftIndex = _existingShifts.findIndex(_shift => {
        return shiftToEdit.id === _shift.id;
      });
      if (shiftIndex > -1) {
        const updatedShift = {
          ..._existingShifts[shiftIndex],
          name: shiftName,
          color: shiftColor,
        };
        _existingShifts[shiftIndex] = updatedShift;

        setExistingShifts(_existingShifts);
        setShiftsToEditOrCreate(
          shiftsToEditOrCreate ? [...shiftsToEditOrCreate, updatedShift] : [updatedShift],
        );
      }
    } else {
      if (!newlyAddedShifts) return;

      const updatedShift = {
        name: shiftName,
        color: shiftColor,
        parentId: unit?.id ?? '',
      };

      setNewlyAddedShifts([...newlyAddedShifts, updatedShift]);
    }

    setShiftToEdit(undefined);
    setShiftColor(Colors.Yellow);
    setIsEditMode(false);
  }

  function onClickEdit(shift: API.ShiftCreateInput, newShift?: boolean) {
    setShiftColor(shift.color);
    setIsEditMode(true);
    setShowAddShift(true);

    if (newShift) {
      if (!newlyAddedShifts) return;
      const _shifts = [...newlyAddedShifts];
      const shiftIndex = _shifts.findIndex(_shift => {
        return shift.id === _shift.id;
      });
      if (shiftIndex > -1) {
        setShiftToEdit(_shifts[shiftIndex]);

        _shifts.splice(shiftIndex, 1);

        setNewlyAddedShifts(_shifts);
      }
    } else {
      if (!existingShifts) return;
      const _shifts = [...existingShifts];
      const shiftIndex = _shifts.findIndex(_shift => {
        return shift.id === _shift.id;
      });
      if (shiftIndex > -1) {
        setShiftToEdit(_shifts[shiftIndex]);
      }
    }
  }

  
  async function handleShiftDelete(shift?: API.Shift, shiftIndex?: number) {
    if (shift) {
      const rolesOnShift = await API.getRolesOnShift(shift.id);
      if (API.isFailure(rolesOnShift)) {
        logger.warn(rolesOnShift);
        return rolesOnShift;
      }

      if (shifts && shifts.length > 1 && rolesOnShift.length) {
        setShiftToDelete(shift);
        setShowReassignmentModal(true);
      } else {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: rolesOnShift.length
              ? t('alex:unitModal.shiftDeletedWithAssignmentsWarning')
              : t('alex:unitModal.shiftDeletedWarning', { shiftName: shift.name }),
            warningAcceptCallback: async () => await handleDeleteShift(shift.id),
            warningAcceptButton: t('common:button.yes'),
            warningCancelButton: t('common:button.no'),
          }),
        );
      }
    } else if (!_.isUndefined(shiftIndex)) {
      if (!newlyAddedShifts) return;

      const _shifts = [...newlyAddedShifts];
      _shifts.splice(shiftIndex, 1);
      setNewlyAddedShifts(_shifts);
    }
  }

  function removeShiftFromList(shiftId: string) {
    if (!existingShifts) return;

    const _shifts = [...existingShifts];
    const shiftIndex = _shifts.findIndex(_shift => {
      return shiftId === _shift.id;
    });
    if (shiftIndex > -1) {
      _shifts.splice(shiftIndex, 1);

      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:unitModal.shiftDeleted'),
          callback: () => setExistingShifts(_shifts),
        }),
      );
    }
  }

  async function handleDeleteShift(shiftId?: string) {
    setIsLoading(true);
    const result = await API.deleteFactoryBusinessObject<API.DataType.SHIFT>(
      shiftId ? shiftId : shiftToDelete!.id,
    );
    if (API.isFailure(result)) {
      ModalUtils.showWarningFailure(modal, result);
    }

    removeShiftFromList(shiftId ?? shiftToDelete!.id);
    setIsLoading(false);
  }

  function onItemCreation(text: string) {
    isEditMode ? editShift(text) : addShift(text);
    setShowAddShift(false);
    setShowColorPicker(false);
    setShiftColor(Colors.Yellow);
  }

  return (
    <View ref={ref} style={Styles.unitModalRightPanelContainer}>
      <View style={Styles.unitModalRightPanelHeaderContainer}>
        <View style={Styles.unitModalRightPanelItemContainer}>
          <Text style={Styles.shiftTitle}>{t('alex:unitModal.shiftTitle')}</Text>
          <ShadowOnHoverButton
            size={Spacings.Standard}
            iconSize={Spacings.xMedium}
            iconContainerStyle={Styles.addShiftButtonContainer}
            onPress={() => {
              setShowAddShift(true);
            }}
          />
        </View>
        <ToolTipWeb
          title={t('alex:unitModal.shiftDescription')}
          component={
            <IconSVG
              svgComponent={infoIcon}
              color={Colors.GreyLight}
              containerStyle={{ marginTop: Spacings.Unit }}
            />
          }
          style={Styles.infoContainer}
        />
      </View>
      {showAddShift && (
        <View>
          <View style={Styles.unitModalRightPanelItemContainer}>
            <IconSVG
              svgComponent={shiftIcon}
              color={Colors.White}
              containerStyle={[
                Styles.shiftIconContainer,
                { backgroundColor: shiftColor ?? Colors.Yellow },
              ]}
            />
            <InputText
              placeHolder={t('alex:unitModal.shiftName')}
              containerStyle={{ width: 280 }}
              showSaveButton
              onSaveButtonClicked={onItemCreation}
              defaultValue={shiftToEdit?.name}
              persistTheSaveButton
            />
          </View>
          <View style={Styles.colorsContainer}>
            {shiftColors.map(_shiftColor => {
              return (
                <TouchableOpacity
                  style={[
                    Styles.colorsButton,
                    {
                      backgroundColor: _shiftColor,
                      borderWidth: shiftColor === _shiftColor ? 1 : 0,
                    },
                  ]}
                  onPress={() => {
                    setShiftColor(_shiftColor);
                  }}
                />
              );
            })}
            <TouchableOpacity
              style={Styles.threeDotsContainer}
              onPress={() => {
                setShowColorPicker(true);
              }}
            >
              <IconSVG
                horizontal
                svgComponent={moreIcon}
                color={Colors.BlackGrey}
                containerStyle={Styles.threeDotsIcon}
              />
            </TouchableOpacity>
            <ShadowOnHoverButton
              size={Spacings.Medium}
              iconSize={Spacings.Small}
              iconContainerStyle={Styles.moreColorsButton}
              onPress={() => {
                setShowColorPicker(true);
              }}
            />
          </View>
          {showColorPicker && (
            <View style={Styles.colorPickerContainer}>
              <ColorPicker color={shiftColor} onColorChange={setShiftColor} />
            </View>
          )}
        </View>
      )}
      <View style={{ zIndex: -1 }}>
        <View style={{ marginTop: 36 }}>
          {newlyAddedShifts?.map((newlyAddedShift, index) => {
            return (
              <View style={Styles.shiftItemContainer}>
                <View style={Styles.unitModalRightPanelItemContainer}>
                  <IconSVG
                    svgComponent={shiftIcon}
                    color={Colors.White}
                    containerStyle={[
                      Styles.shiftIconContainer,
                      { marginTop: 0, backgroundColor: newlyAddedShift.color },
                    ]}
                  />
                  <Text style={Styles.shiftName}>{newlyAddedShift.name}</Text>
                </View>
                <View style={Styles.unitModalRightPanelItemContainer}>
                  <TouchableOpacity onPress={() => onClickEdit(newlyAddedShift, true)}>
                    <IconSVG svgComponent={editIcon} color={Colors.Black} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      handleShiftDelete(undefined, index);
                    }}
                  >
                    <IconSVG svgComponent={deleteIcon} color={Colors.Black} />
                  </TouchableOpacity>
                </View>
              </View>
            );
          })}
        </View>
        <View>
          {existingShifts && existingShifts.length ? (
            existingShifts.map(shift => {
              const isShiftNonInherited = Boolean(unit?.id === shift.parentId);

              if (shiftToEdit && shiftToEdit.id === shift.id) return <></>;
              else
                return (
                  <View style={Styles.shiftItemContainer}>
                    <View style={Styles.unitModalRightPanelItemContainer}>
                      <IconSVG
                        svgComponent={shiftIcon}
                        color={Colors.White}
                        containerStyle={[
                          Styles.shiftIconContainer,
                          {
                            marginTop: 0,
                            backgroundColor: isShiftNonInherited ? shift.color : Colors.GreyLight,
                          },
                        ]}
                      />
                      <Text style={Styles.shiftName}>{shift.name}</Text>
                    </View>
                    {isShiftNonInherited && (
                      <View style={Styles.unitModalRightPanelItemContainer}>
                        <TouchableOpacity onPress={() => onClickEdit(shift)}>
                          <IconSVG svgComponent={editIcon} color={Colors.Black} />
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            handleShiftDelete(shift);
                          }}
                        >
                          <IconSVG svgComponent={deleteIcon} color={Colors.Black} />
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                );
            })
          ) : (
            <></>
          )}
        </View>
      </View>
      {showReassignmentModal && unit && (
        <ReassignmentModal
          handleModalClose={() => setShowReassignmentModal(false)}
          orgUnit={unit}
          selectedShift={shiftToDelete}
          confirmAction={() => handleDeleteShift()}
        />
      )}
    </View>
  );
};
