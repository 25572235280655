export * from './Skill';
import * as skillUtils from './Skill';
import { ModalUtils, ModalContext } from 'shared/ui-component/Modal';
import * as API from 'shared/backend-data';
import { i18n } from 'shared/localisation/i18n';
import { Colors } from 'shared/styles';
import React from 'react';
import { Validity } from 'backend/src/api';
import { IconSVG } from 'shared/ui-component/Icon';

const WarningIcon = require('shared/assets/svg/icon.warning.svg').default;

export async function deleteSkillTagWithErrorModalHandling(
  skillTagId: string,
  modal: ModalContext,
): Promise<boolean> {
  const deleteFactory = await API.deleteFactoryBusinessObject<API.DataType.SKILLTAG>(skillTagId);
  if (API.isFailure(deleteFactory)) {
    if (API.isFailureType(deleteFactory, 'DeletionVeto')) {
      ModalUtils.showVetoModal(i18n.t('alex:vetoModal.deleteSkillTagNotPermitted'), deleteFactory);
    } else {
      ModalUtils.showWarningFailure(modal, deleteFactory);
    }
    return false;
  }

  return true;
}

async function forceDeleteSkill(
  skill: API.Skill,
  modal: ModalContext,
  forceDeletion: boolean | undefined,
) {
  if (forceDeletion) {
    await deleteSkillWithErrorModalHandling(skill, modal, forceDeletion);
  } else {
    modal.hideModal();
  }
}

export async function deleteSkillWithErrorModalHandling(
  skill: API.Skill,
  modal: ModalContext,
  deleteDependencies?: boolean,
): Promise<boolean> {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: i18n.t('common:loading.operationInProgress'),
      loader: true,
    }),
  );

  const result = await API.deleteFactoryBusinessObject<API.DataType.SKILL>(
    skill.id,
    undefined,
    undefined,
    false,
    deleteDependencies,
  );
  modal.hideModal();
  if (API.isFailure(result)) {
    if (API.isFailureType(result, 'DeletionVeto')) {
      let failureMessage: string;
      let _forceDeletion = true;

      if (result.message.includes(API.DataType.REQUIREMENT)) {
        _forceDeletion = false;
        failureMessage = i18n.t('alex:vetoModal.requirementsUsingSkill', {
          number: result.data.dependencyIds.length,
          skillName: skill.name,
        });
      } else if (result.message.includes(API.DataType.TRAINING)) {
        _forceDeletion = false;
        failureMessage = i18n.t('alex:vetoModal.trainingsUsingSkill', {
          number: result.data.dependencyIds.length,
          skillName: skill.name,
        });
      } else {
        failureMessage = result.message;
      }
      ModalUtils.showVetoModal(
        failureMessage,
        result,
        (forceDeletion?: boolean) => forceDeleteSkill(skill, modal, forceDeletion),
        undefined,
        undefined,
        _forceDeletion,
      );
      return false;
    } else {
      ModalUtils.showWarningFailure(modal, result);
      return false;
    }
  }

  return true;
}



export function getValidityColor(validity?: Validity | null, okColor = Colors.Black): string {
  if (!validity) return Colors.Red;

  switch (validity) {
    case Validity.OK:
      return okColor;
    case Validity.OK_EXPIRE_SOON:
      return Colors.Orange;
    case Validity.KO_EXPIRED:
      return Colors.Red;
    case Validity.KO_NEW:
      return Colors.Red;
    case Validity.KO_REJECTED:
      return Colors.Red;
    case Validity.KO_MISSING:
      return Colors.Red;
  }
}

export function renderValidityIcon(validity: Validity): JSX.Element {
  return <IconSVG svgComponent={WarningIcon} color={getValidityColor(validity, Colors.White)} />;
}

export function renderConformityIcon(workerWorkstation: API.WorkerWorkstation) {
  return renderValidityIcon(
    workerWorkstation.invalidNoRefreshSkills?.length
      ? Validity.KO_NEW
      : workerWorkstation.invalidExpiredSkills?.length
      ? Validity.KO_EXPIRED
      : workerWorkstation.validExpireSoonSkills?.length
      ? Validity.OK_EXPIRE_SOON
      : workerWorkstation.invalidMissingSkills?.length
      ? Validity.OK
      : 
        Validity.OK,
  );
}
