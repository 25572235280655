import React, { useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { styles } from './styles';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles';
import { t } from 'shared/localisation/i18n';

const InfoSVG = require('shared/assets/svg/icon.info.svg').default;

interface Props {
  onPress: () => void;
}

export const UpdateButton: React.FC<Props> = props => {
  const { onPress } = props;

  return (
    <TouchableOpacity style={[styles.container]} onPress={() => onPress()}>
      <ToolTipWeb
        title={t('common:userPermissionsReloadModal.message')}
        style={styles.toolTipMessage}
        component={
          <IconSVG svgComponent={InfoSVG} size={{ width: 32, height: 32 }} color={Colors.Yellow} />
        }
        containerStyle={styles.toolTip}
      />
    </TouchableOpacity>
  );
};
