import React, { useEffect } from 'react';
import { View } from 'react-native';
import { t } from 'shared/localisation/i18n';
import Styles from './Styles';
import { UnderAndOverStaffedWorkstations } from './underAndOverStaffedWorkstations';
import { LateTrainingSessions } from './lateTrainingSessions';
import styles from './Styles';
import { KPIGraph } from './KPIGraphs/container';
import { KPIData } from 'sharedweb/src/context/TreeTableContext';

interface Props {
  kpiData: KPIData;
  isGraphLoaded: boolean;

  setIsGraphLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstTitle: React.Dispatch<React.SetStateAction<string>>;
  setSecondTitle: React.Dispatch<React.SetStateAction<string>>;
  setIsUnderStaffedAndOverStafftedWorkstationsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const KPIDashboardComponent: React.FC<Props> = props => {
  const {
    kpiData,
    isGraphLoaded,
    setSecondTitle,
    setFirstTitle,
    setIsGraphLoaded,
    setIsUnderStaffedAndOverStafftedWorkstationsLoaded,
  } = props;

  useEffect(() => {
    setFirstTitle('');
    setSecondTitle(t('alex:header.navigation.8', undefined, false));
  }, []);

  return (
    <View style={Styles.component}>
      <View style={styles.innerContainer}>
        <KPIGraph
          kpiData={kpiData}
          setIsGraphLoaded={setIsGraphLoaded}
          isGraphLoaded={isGraphLoaded}
        />
      </View>
      <View style={Styles.widgetsContainer}>
        <UnderAndOverStaffedWorkstations
          kpiData={kpiData}
          isGraphLoaded={isGraphLoaded}
          setIsUnderStaffedAndOverStafftedWorkstationsLoaded={
            setIsUnderStaffedAndOverStafftedWorkstationsLoaded
          }
        />
        <LateTrainingSessions kpiData={kpiData} isGraphLoaded={isGraphLoaded} />
      </View>
    </View>
  );
};
