import React, { useState, useEffect, useContext } from 'react';
import WebModal from 'modal-react-native-web';
import { View, Text, InteractionManager, TouchableOpacity, ScrollView } from 'react-native';
import { ModalHeader } from 'shared/ui-component/Modal/Header';
import {
  ModalBackgroundStyle,
  ModalCardStyle,
  ModalFooterStyle,
  ModalAnchorContainerStyle,
  ModalBodyWithAnchorStyle,
} from 'shared/styles/ModalStyles';
import { t } from 'shared/localisation/i18n';
import styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors, Spacings, Styles } from 'shared/styles';
import { DropDownSingleSelection, DropDownWithWorkerLevel } from 'shared/ui-component/DropDown';
import { DropDownMultiSelection, DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import * as API from 'shared/backend-data';
import {
  WorkerDropdownOptions,
  TrainingSessionInputElements,
} from '../container/PracticalTrainingSessionModalContainer';
import { useIsMounted } from 'shared/hooks/IsMounted';
import {
  showTrainingSessionErrorWarning,
  TrainingSessionWarning,
} from 'shared/util/TrainingSessionWarningModal';
import { ModalUtils } from 'shared/ui-component/Modal';
import { Loader } from 'shared/ui-component/Loader/Loader';
import {
  DropDownWithWorkerLevelOption,
  dropDownWorkerLevels,
} from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';
import * as _ from 'lodash-es';
import { TrainingModalButton } from '../../component/index';
import { YellowButton, TextButton } from 'shared/ui-component/Button';
import { NoTrainingsCustomMessage } from './NoTrainingsCustomMessage';
import { ComplementaryTrainingsMessage } from './ComplementaryTrainingsMessage';
import { ToBeClosedTrainingsMessage } from './ToBeClosedTrainingsMessage';
import { getTrainingDurationOptions } from 'shared/ui-component/DropDown/Data/DropDownData';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { displayTrainingSessionDuplicateVetoModal } from 'shared/util/TrainingUi';
import { Mode } from 'shared/util-ts/DateUtils';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from 'skillmgtweb/src/components/navigation/Routes';
import { TrainingVersionFiles } from 'shared/ui-component/AttachFile/TrainingVersionFiles';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { InputProof } from 'shared/ui-component/Input/InputProof';
import logger from 'shared/util/Logger';
import { ButtonOnPressTypes } from 'shared/util/TrainingSession';
import Hyperlink from 'react-native-hyperlink';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';
import { SubSkills } from './SubSkills';
import Aigle from 'aigle';
import { WorkersList } from '../../add-edit-lecture-traning-session-modal/component/WorkersList';
import { WorkerDropDownOptionWithDetails } from 'shared/ui-component/DropDown/DropDown';
import {
  checkMentorsPercentageValidy,
  getTrainingSessionErrorType,
} from 'shared/util/TrainingSession';


const PracticalTraining = require('shared/assets/svg/icon.practTrain.svg').default;
const ClockIcon = require('shared/assets/svg/icon.clock.mobile.svg').default;
const WorkerIcon = require('shared/assets/svg/icon.worker.svg').default;
const WorkstationIcon = require('shared/assets/svg/icon.workstation.svg').default;
const ICON_SKILL = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const ThumbUp = require('shared/assets/svg/icon.thumbUp.svg').default;
const ThumbDown = require('shared/assets/svg/icon.thumbDownRight.svg').default;
const arrowDownIcon = require('shared/assets/svg/icon.arrowDown.mobile.svg').default;
const arrowRightIcon = require('shared/assets/svg/icon.arrowRight.mobile.svg').default;

interface Props {
  loading: boolean;
  workerDropdownOptions: WorkerDropdownOptions[];
  workstationDropdownOptions: DropDownOption[];
  trainingSessionId?: string;
  trainingWithLevel?: DropDownWithWorkerLevelOption;
  trainingWithLevelOptions?: DropDownWithWorkerLevelOption[];
  duration: DropDownOption | undefined;
  scheduledStartDate?: Date;
  mentors: WorkerDropDownOptionWithDetails[] | undefined;
  worker?: WorkerDropdownOptions;
  description?: string;
  mentorOptions?: WorkerDropDownOptionWithDetails[];
  selectedWorkstation?: DropDownOption;
  complementaryTrainings?: API.Training[];
  isTrainingSessionToBeClosed: boolean | undefined;
  orgUnit?: DropDownOption;
  workerWithOutLevel?: DropDownOption;
  workerWithOutLevelDropdownOptions?: WorkerDropDownOptionWithDetails[];
  mentorsWithOutLevel?: WorkerDropDownOptionWithDetails[];
  skillTags: Tag<API.Skill>[];
  isTrainingSessionToSchedule: boolean | undefined;
  trainingDescription?: string;

  handleAddProof?: () => void;
  handleTrainAWorkerModal?: (
    treeNode: DropDownOption,
    worker: DropDownWithWorkerLevelOption,
    _level: API.WorkstationWorkerLevels,
  ) => void;
  onChange: (date?: Date) => void;
  handleInputText: (name: string, value?: string) => void;
  handleDropdown: (name: string, value: DropDownOption | undefined) => void;
  handleTrainingWithLevel: React.Dispatch<
    React.SetStateAction<DropDownWithWorkerLevelOption | undefined>
  >;
  handleModalClose: () => void;
  handleMentorsDropdown: (value: WorkerDropDownOptionWithDetails[] | undefined) => void;
  handleTraineeDropDown: (value: DropDownWithWorkerLevelOption | undefined) => void;
  handleMentorsWithOutLevelDropdown: (value: WorkerDropDownOptionWithDetails[] | undefined) => void;
}

export const PracticalTrainingSessionModalComponent: React.FC<Props> = props => {
  const {
    loading,
    trainingSessionId,
    duration,
    scheduledStartDate,
    mentors,
    worker,
    description,
    trainingWithLevel,
    trainingWithLevelOptions,
    mentorOptions,
    selectedWorkstation,
    workerDropdownOptions,
    complementaryTrainings,
    isTrainingSessionToBeClosed,
    workstationDropdownOptions,
    orgUnit,
    workerWithOutLevel,
    workerWithOutLevelDropdownOptions,
    mentorsWithOutLevel,
    skillTags,
    isTrainingSessionToSchedule,
    trainingDescription,

    handleAddProof,
    handleTrainAWorkerModal,
    handleInputText,
    handleModalClose,
    handleDropdown,
    handleTrainingWithLevel,
    onChange,
    handleMentorsDropdown,
    handleTraineeDropDown,
    handleMentorsWithOutLevelDropdown,
  } = props;

  const [loader, setLoader] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>();
  const [buttonsArray, setButtonsArray] = useState<TrainingModalButton[]>([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isShowStep2, setIsShowStep2] = useState(false);
  const [isShowStep3, setIsShowStep3] = useState(false);
  const [isEditUnlocked, setIsEditUnlocked] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState<DropDownWithWorkerLevelOption>();
  const [selectedWorkstationId, setSelectedWorkstationId] = useState<string>();
  const [noAvailablePractices, setNoAvailablePractices] = useState<boolean>(false);
  const [isAdvancementSelected, setIsAdvancementSelected] = useState<boolean>(false);
  const [isSkillRejectedArray, setIsSkillRejectedArray] = useState<boolean[]>([]);
  const [isSkillAcquiredArray, setIsSkillAcquiredArray] = useState<boolean[]>([]);
  const [isSkillCollapsedArray, setIsSkillCollapsedArray] = useState<(boolean | undefined)[]>([]);
  const [isPracticeToSchedule, setIsPracticeToSchedule] = useState<boolean>(true);
  const [reviewer, setReviewer] = useState<API.Worker>();
  const [trainee, setTrainee] = useState<DropDownOption>();
  const [proofs, setProofs] = useState<(API.ProofBundle | undefined)[]>(
    new Array(skillTags.length).fill(undefined),
  );
  const [subSkillsProofs, setSubSkillsProofs] = useState<
    Map<string, (API.ProofBundle | undefined)[]>
  >(new Map());
  const [subSkillsIsSkillAcquiredArrayMap, setSubSkillsIsSkillAcquiredArrayMap] = useState<
    Map<string, boolean[]>
  >(new Map());
  const [subSkillsIsSkillRejectedArrayMap, setSubSkillsIsSkillRejectedArrayMap] = useState<
    Map<string, boolean[]>
  >(new Map());
  const [trainingSession, setTrainingSession] = useState<API.TrainingSession>();
  const [traineeChanged, setTraineeChanged] = useState<boolean>(false);
  const [mentorsWithDetails, seMentorsWithDetails] = useState<WorkerDropDownOptionWithDetails[]>(
    [],
  );

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const history = useHistory();

  const { isValidPermission } = useContext(PermissionManagementContext);
  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);

  useEffect(() => {
    handleModalTitleAndButtons();
  }, [trainingSession, isEditUnlocked]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!trainingSessionId) return;
      const _trainingSession = await API.getTrainingSession(trainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(_trainingSession)) return _trainingSession;

      setTrainingSession(_trainingSession);
    });
  }, [trainingSessionId]);

  useEffect(() => {
    if (isTrainingSessionToSchedule) {
      setIsPracticeToSchedule(true);
      setIsAdvancementSelected(false);
    } else if (trainingSessionId) {
      setIsPracticeToSchedule(false);
      setIsAdvancementSelected(true);
    }
  }, [trainingSessionId, isTrainingSessionToSchedule]);

  useEffect(() => {
    if (selectedWorkstation || orgUnit) setIsShowStep2(true);
  }, [orgUnit, selectedWorkstation]);

  useEffect(() => {
    if (worker) setTrainee(worker);
    else if (workerWithOutLevel) setTrainee(workerWithOutLevel);
  }, [worker, workerWithOutLevel]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (!trainingSessionId) return;

      
      const trainingSessionProofBundles = await API.getProofBundles(trainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(trainingSessionProofBundles)) {
        logger.warn(trainingSessionProofBundles);
        return;
      }

      
      
      const isTheSkillAcquired: boolean[] = [];
      const isTheSkillNotAcquired: boolean[] = [];
      const _isSkillCollapsedArray: (boolean | undefined)[] = [];
      skillTags.forEach(skillTag => {
        
        

        if (skillTag.value && API.isSkillGroup(skillTag.value)) {
          _isSkillCollapsedArray.push(true);

          const __isTheSkillAcquired: boolean[] = [];
          const __isTheSkillNotAcquired: boolean[] = [];
          skillTag.children?.forEach(subSkill => {
            const proofOfSkill = trainingSessionProofBundles.find(
              proof => proof.skillId === subSkill.key,
            );

            if (proofOfSkill) {
              if (_.isUndefined(proofOfSkill.acquired) || _.isNull(proofOfSkill.acquired)) {
                __isTheSkillAcquired.push(false);
                __isTheSkillNotAcquired.push(false);
              } else if (!proofOfSkill.acquired) {
                __isTheSkillAcquired.push(false);
                __isTheSkillNotAcquired.push(true);
              } else {
                __isTheSkillAcquired.push(true);
                __isTheSkillNotAcquired.push(false);
              }
            } else {
              __isTheSkillAcquired.push(false);
              __isTheSkillNotAcquired.push(false);
            }
          });

          subSkillsIsSkillAcquiredArrayMap.set(skillTag.key, __isTheSkillAcquired);
          setSubSkillsIsSkillAcquiredArrayMap(subSkillsIsSkillAcquiredArrayMap);

          subSkillsIsSkillRejectedArrayMap.set(skillTag.key, __isTheSkillNotAcquired);
          setSubSkillsIsSkillRejectedArrayMap(subSkillsIsSkillRejectedArrayMap);
        } else {
          _isSkillCollapsedArray.push(undefined);
        }

        const proofOfSkill = trainingSessionProofBundles.find(
          proof => proof.skillId === skillTag.key,
        );

        if (proofOfSkill) {
          if (_.isUndefined(proofOfSkill.acquired) || _.isNull(proofOfSkill.acquired)) {
            isTheSkillAcquired.push(false);
            isTheSkillNotAcquired.push(false);
          } else if (!proofOfSkill.acquired) {
            isTheSkillAcquired.push(false);
            isTheSkillNotAcquired.push(true);
          } else {
            isTheSkillAcquired.push(true);
            isTheSkillNotAcquired.push(false);
          }
        } else {
          isTheSkillAcquired.push(false);
          isTheSkillNotAcquired.push(false);
        }
      });
      setIsSkillCollapsedArray(_isSkillCollapsedArray);
      setIsSkillAcquiredArray(isTheSkillAcquired);
      setIsSkillRejectedArray(isTheSkillNotAcquired);
    });
  }, [trainingSessionId, skillTags]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const worker = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(worker)) return;

      setReviewer(worker);
    });
  }, []);

  useEffect(() => {
    if (!selectedWorkstation) return;
    setIsShowStep3(!!worker && !!trainingWithLevel);
  }, [selectedWorkstation, worker, trainingWithLevel]);

  useEffect(() => {
    setSelectedLevel(dropDownWorkerLevels.find(level => trainingWithLevel?.level === level.level));
  }, [trainingWithLevel]);

  useEffect(() => {
    if (!orgUnit) return;
    setIsShowStep3(!!workerWithOutLevel && !!trainingWithLevel);
  }, [orgUnit, workerWithOutLevel, trainingWithLevel]);

  
  useEffect(() => {
    if (trainingSession) return;

    if (
      Boolean(
        (worker || workerWithOutLevel) &&
          (selectedWorkstation || orgUnit) &&
          trainingWithLevel &&
          (mentors || mentorsWithOutLevel) &&
          scheduledStartDate,
      )
    )
      setButtonsArray(
        isValidPermission(API.Permission.trainingSessions_edit)
          ? [{ label: '$t(common:time.schedule)', onPressType: ButtonOnPressTypes.CREATE }]
          : [
              {
                label: '$t(common:button.saveAsADraft)',
                onPressType: ButtonOnPressTypes.SAVEASDRAFT,
              },
            ],
      );
    else if (!(worker || workerWithOutLevel))
      setButtonsArray([
        { label: '$t(common:button.saveAsADraft)', onPressType: ButtonOnPressTypes.SAVEASDRAFT },
      ]);
    else
      setButtonsArray([
        { label: '$t(common:button.save)', onPressType: ButtonOnPressTypes.CREATE },
      ]);
  }, [
    worker,
    workerWithOutLevel,
    selectedWorkstation,
    orgUnit,
    mentors,
    mentorsWithOutLevel,
    trainingWithLevel,
    scheduledStartDate,
  ]);

  useEffect(() => {
    if (
      trainingSession?.scheduledTraineeIds.length &&
      trainee &&
      trainingSession &&
      !API.isTrainingSessionRequestValidated(trainingSession) &&
      trainingSession.scheduledTraineeIds[0] !== trainee?.key
    ) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('alex:mobile.trainingScreen.workerRemoved'),
          warningAcceptButton: t('common:button.gotIt'),
        }),
      );
      setTraineeChanged(true);
    } else setTraineeChanged(false);
  }, [trainee, trainingSession]);

  function isValidForm(buttonType: ButtonOnPressTypes): Boolean {
    if (
      buttonType === ButtonOnPressTypes.CREATE ||
      buttonType === ButtonOnPressTypes.SAVEASDRAFT ||
      buttonType === ButtonOnPressTypes.UPDATE
    )
      return Boolean((selectedWorkstation || orgUnit) && trainingWithLevel);

    if (buttonType === ButtonOnPressTypes.SCHEDULE)
      return Boolean(
        (selectedWorkstation || orgUnit) &&
          (worker || workerWithOutLevel) &&
          trainingWithLevel &&
          scheduledStartDate &&
          duration &&
          (mentors || mentorsWithOutLevel) &&
          (checkMentorsPercentageValidy(mentors) ||
            checkMentorsPercentageValidy(mentorsWithOutLevel)),
      );

    return [
      ButtonOnPressTypes.REJECTREQUEST,
      ButtonOnPressTypes.VALIDATEREQUEST,
      ButtonOnPressTypes.DELETE,
      ButtonOnPressTypes.ADDPROOF,
      ButtonOnPressTypes.UNLOCKEDIT,
    ].includes(buttonType);
  }

  async function handleLevelSelection(level: DropDownWithWorkerLevelOption) {
    let practiceFound = false;
    trainingWithLevelOptions?.forEach(trainingWithLevel => {
      if (level.level === trainingWithLevel.level) {
        handleTrainingWithLevel(trainingWithLevel);
        practiceFound = true;
      }
    });
    setNoAvailablePractices(!practiceFound);
  }

  async function setSkillAsAcquired(
    skillTag: Tag,
    index: number,
    acquiredValue: boolean | undefined,
  ) {
    if (trainingSessionId && isSkillAcquiredArray && trainee) {
      setLoader(true);

      const _isSkillAcquiredArray = [...isSkillAcquiredArray];
      if (_isSkillAcquiredArray) _isSkillAcquiredArray[index] = !_isSkillAcquiredArray[index];
      setIsSkillAcquiredArray(_isSkillAcquiredArray);

      const _isSkillRejectedArray = [...isSkillRejectedArray];
      if (_isSkillRejectedArray) _isSkillRejectedArray[index] = false;
      setIsSkillRejectedArray(_isSkillRejectedArray);

      const setSkillToAcquired = await API.createOrUpdateProofAsAcquiredOrNot(
        skillTag.key,
        trainingSessionId,
        trainee.key,
        acquiredValue ? acquiredValue : null,
      );
      if (!isMounted.current) return;
      if (API.isFailure(setSkillToAcquired)) {
        setLoader(false);
        logger.warn(setSkillToAcquired);

        return;
      }
      const _proofs = proofs;
      _proofs[index] = setSkillToAcquired;
      setProofs(_proofs);

      if (skillTag.children) {
        const _subSkillsIsAcquiredArray = subSkillsIsSkillAcquiredArrayMap.get(skillTag.key);
        const _subSkillsIsRejectedArray = subSkillsIsSkillRejectedArrayMap.get(skillTag.key);
        const _subSkillsProofs = subSkillsProofs.get(skillTag.key);

        await Aigle.mapLimit(skillTag.children, 2, async (subSkill, _index) => {
          if (_subSkillsIsAcquiredArray)
            _subSkillsIsAcquiredArray[_index] = !_subSkillsIsAcquiredArray[_index];

          if (_subSkillsIsRejectedArray) _subSkillsIsRejectedArray[_index] = false;

          const setSkillToAcquired = await API.createOrUpdateProofAsAcquiredOrNot(
            subSkill.key,
            trainingSessionId,
            trainee.key,
            acquiredValue ? acquiredValue : null,
          );
          if (!isMounted.current) return;
          if (API.isFailure(setSkillToAcquired)) {
            setLoader(false);
            logger.warn(setSkillToAcquired);

            return setSkillToAcquired;
          }

          if (_subSkillsProofs) _subSkillsProofs[_index] = setSkillToAcquired;
        });
        if (!isMounted.current) return;

        if (_subSkillsIsAcquiredArray) {
          subSkillsIsSkillAcquiredArrayMap.set(skillTag.key, _subSkillsIsAcquiredArray);
          setSubSkillsIsSkillAcquiredArrayMap(subSkillsIsSkillAcquiredArrayMap);
        }
        if (_subSkillsIsRejectedArray) {
          subSkillsIsSkillRejectedArrayMap.set(skillTag.key, _subSkillsIsRejectedArray);
          setSubSkillsIsSkillRejectedArrayMap(subSkillsIsSkillRejectedArrayMap);
        }
        if (_subSkillsProofs) {
          subSkillsProofs.set(skillTag.key, _subSkillsProofs);
          setSubSkillsProofs(subSkillsProofs);
        }
      }

      setLoader(false);
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:scheduleTrainingModal.toastMessageAcquired'),
        }),
      );
    }
  }

  function setSkillAsCollapsed(index: number, isCollapsed: boolean) {
    const _isSkillCollapsedArray = [...isSkillCollapsedArray];
    _isSkillCollapsedArray[index] = !isCollapsed;

    setIsSkillCollapsedArray(_isSkillCollapsedArray);
  }

  async function setSkillAsRejected(
    skillTag: Tag<API.Skill>,
    index: number,
    acquiredValue: boolean,
  ) {
    if (trainingSessionId && isSkillRejectedArray && trainee) {
      setLoader(true);

      const skillRejected = [...isSkillRejectedArray];
      if (skillRejected) skillRejected[index] = !skillRejected[index];
      setIsSkillRejectedArray(skillRejected);
      const skillAcquired = [...isSkillAcquiredArray];
      if (skillAcquired) skillAcquired[index] = false;
      setIsSkillAcquiredArray(skillAcquired);
      const setSkillToRejected = await API.createOrUpdateProofAsAcquiredOrNot(
        skillTag.key,
        trainingSessionId,
        trainee.key,
        acquiredValue ? !acquiredValue : null,
      );
      if (!isMounted.current) return;
      if (API.isFailure(setSkillToRejected)) {
        setLoader(false);
        logger.warn(setSkillToRejected);
        return;
      }
      const _proofs = proofs;
      _proofs[index] = setSkillToRejected;
      setProofs(_proofs);

      if (skillTag.children) {
        const _subSkillsIsAcquiredArray = subSkillsIsSkillAcquiredArrayMap.get(skillTag.key);
        const _subSkillsIsRejectedArray = subSkillsIsSkillRejectedArrayMap.get(skillTag.key);
        const _subSkillsProofs = subSkillsProofs.get(skillTag.key);

        await Aigle.mapLimit(skillTag.children, 2, async (subSkill, _index) => {
          if (_subSkillsIsRejectedArray)
            _subSkillsIsRejectedArray[_index] = !_subSkillsIsRejectedArray[_index];

          if (_subSkillsIsAcquiredArray) _subSkillsIsAcquiredArray[_index] = false;

          const setSkillToAcquired = await API.createOrUpdateProofAsAcquiredOrNot(
            subSkill.key,
            trainingSessionId,
            trainee.key,
            acquiredValue ? !acquiredValue : null,
          );
          if (!isMounted.current) return;
          if (API.isFailure(setSkillToAcquired)) {
            setLoader(false);
            logger.warn(setSkillToAcquired);

            return setSkillToAcquired;
          }

          if (_subSkillsProofs) _subSkillsProofs[_index] = setSkillToAcquired;
        });
        if (!isMounted.current) return;

        if (_subSkillsIsAcquiredArray) {
          subSkillsIsSkillAcquiredArrayMap.set(skillTag.key, _subSkillsIsAcquiredArray);
          setSubSkillsIsSkillAcquiredArrayMap(subSkillsIsSkillAcquiredArrayMap);
        }
        if (_subSkillsIsRejectedArray) {
          subSkillsIsSkillRejectedArrayMap.set(skillTag.key, _subSkillsIsRejectedArray);
          setSubSkillsIsSkillRejectedArrayMap(subSkillsIsSkillRejectedArrayMap);
        }
        if (_subSkillsProofs) {
          subSkillsProofs.set(skillTag.key, _subSkillsProofs);
          setSubSkillsProofs(subSkillsProofs);
        }
      }

      setLoader(false);
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:scheduleTrainingModal.toastMessageNotAcquired'),
        }),
      );
    }
  }

  async function handleModalTitleAndButtons(): Promise<API.Result<void>> {
    if (!trainingSession) {
      setIsInputDisabled(false);
      setModalTitle(t('alex:scheduleTrainingModal.practicalTrainingheaderTitle.1'));
      setButtonsArray([
        {
          label: '$t(common:button.save)',
          onPressType: ButtonOnPressTypes.CREATE,
        },
      ]);
      return;
    }

    if (API.isTrainingSessionToReview(trainingSession)) {
      setIsInputDisabled(true);
      setModalTitle(t('alex:scheduleTrainingModal.practicalTrainingheaderTitle.1'));
      setButtonsArray([
        { label: '$t(common:button.reject)', onPressType: ButtonOnPressTypes.REJECTREQUEST },
        { label: '$t(common:button.validate)', onPressType: ButtonOnPressTypes.VALIDATEREQUEST },
      ]);
    } else if (API.isTrainingSessionDraftOrRequestValidated(trainingSession)) {
      setIsInputDisabled(false);
      setModalTitle(t('alex:scheduleTrainingModal.practicalTrainingheaderTitle.2'));
      setButtonsArray(
        isValidPermission(API.Permission.trainingSessions_edit)
          ? [
              { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
              {
                label: '$t(common:button.saveAsADraft)',
                onPressType: ButtonOnPressTypes.SAVEASDRAFT,
              },
              { label: '$t(common:time.schedule)', onPressType: ButtonOnPressTypes.SCHEDULE },
            ]
          : [
              { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
              {
                label: '$t(common:button.save)',
                onPressType: ButtonOnPressTypes.UPDATE,
              },
            ],
      );
    } else if (API.isTrainingSessionScheduledOrLateStart(trainingSession)) {
      setIsInputDisabled(true);
      setModalTitle(
        !isEditUnlocked
          ? t('alex:scheduleTrainingModal.practicalTrainingheaderTitle.3')
          : t('alex:scheduleTrainingModal.practicalTrainingheaderTitle.2'),
      );
      if (!isEditUnlocked) {
        if (isValidPermission(API.Permission.trainingSessions_edit))
          setButtonsArray([
            { label: '$t(common:button.edit)', onPressType: ButtonOnPressTypes.UNLOCKEDIT },
            {
              label: '$t(alex:scheduleTrainingModal.generateProof)',
              onPressType: ButtonOnPressTypes.ADDPROOF,
            },
          ]);
        else
          setButtonsArray([
            {
              label: '$t(alex:scheduleTrainingModal.generateProof)',
              onPressType: ButtonOnPressTypes.ADDPROOF,
            },
          ]);
      } else {
        setButtonsArray([
          { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
          { label: '$t(common:button.saveAsADraft)', onPressType: ButtonOnPressTypes.SAVEASDRAFT },
          { label: '$t(common:button.save)', onPressType: ButtonOnPressTypes.UPDATE },
        ]);
      }
    } else if (API.isTrainingSessionStartedOrStartedLate(trainingSession)) {
      setIsInputDisabled(true);
      setModalTitle(t('alex:scheduleTrainingModal.practicalTrainingheaderTitle.4'));
      setButtonsArray(
        isValidPermission(API.Permission.trainingSessions_edit)
          ? [
              { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
              {
                label: '$t(alex:scheduleTrainingModal.generateProof)',
                onPressType: ButtonOnPressTypes.ADDPROOF,
              },
            ]
          : [
              {
                label: '$t(alex:scheduleTrainingModal.generateProof)',
                onPressType: ButtonOnPressTypes.ADDPROOF,
              },
            ],
      );
    } else if (API.isTrainingSessionLateEnd(trainingSession)) {
      setIsInputDisabled(true);
      setModalTitle(t('alex:scheduleTrainingModal.practicalTrainingheaderTitle.5'));
      setButtonsArray(
        isValidPermission(API.Permission.trainingSessions_edit)
          ? [
              { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
              {
                label: '$t(alex:scheduleTrainingModal.generateProof)',
                onPressType: ButtonOnPressTypes.ADDPROOF,
              },
            ]
          : [
              {
                label: '$t(alex:scheduleTrainingModal.generateProof)',
                onPressType: ButtonOnPressTypes.ADDPROOF,
              },
            ],
      );
    }
  }

  async function createRequest(saveAsADraft?: boolean, traningRequestRecreated?: boolean) {
    const linkedObjectId = selectedWorkstation?.key || orgUnit?.key;
    const workerId = worker?.key || workerWithOutLevel?.key;
    if (!linkedObjectId || !trainingWithLevel) return;
    setLoader(true);

    const _trainingVersion = await API.getTrainingVersion(trainingWithLevel.key);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingVersion)) {
      setLoader(false);
      return _trainingVersion;
    }

    const levelRequirementsMap = await API.getLevelsRequirement(linkedObjectId);
    if (!isMounted.current) return;
    if (API.isFailure(levelRequirementsMap)) {
      setLoader(false);
      return levelRequirementsMap;
    }

    const levelRequirement = levelRequirementsMap.get(trainingWithLevel.level);
    if (!levelRequirement || !levelRequirement.practicalTrainingId) {
      setLoader(false);
      return;
    }

    const mentorsWithPercentage = [];
    if (mentors) {
      for (const mentor of mentors) {
        if (!API.isGhostWorker(mentor.label))
          mentorsWithPercentage.push({
            trainerId: mentor.key,
            percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
          });
      }
    } else if (mentorsWithOutLevel) {
      for (const mentor of mentorsWithOutLevel) {
        if (!API.isGhostWorker(mentor.label))
          mentorsWithPercentage.push({
            trainerId: mentor.key,
            percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
          });
      }
    }

    const practicalTrainingSession = await API.createTrainingSession({
      requirementId: levelRequirement.id,
      originId: linkedObjectId,
      scheduledTraineeIds: workerId ? [workerId] : [],
      scheduledTrainers: mentorsWithPercentage ? mentorsWithPercentage : [],
      scheduledStartDate: traningRequestRecreated
        ? undefined
        : scheduledStartDate
        ? scheduledStartDate.toISOString()
        : undefined,
      description: description,
      trainingVersionId: _trainingVersion.id,
      requestState: API.ReviewState.VALIDATED,
      durationInMin: duration?.value,
      traineeIds: [],
      isDraft: saveAsADraft,
      trainers: [],
    });
    if (traningRequestRecreated) {
      setLoader(false);
      return;
    }
    if (!isMounted.current) return;
    if (API.isFailure(practicalTrainingSession)) {
      setLoader(false);
      if (API.isFailureType(practicalTrainingSession, 'DuplicateVeto'))
        displayTrainingSessionDuplicateVetoModal(practicalTrainingSession, API.DataType.WORKER);
      else
        showTrainingSessionErrorWarning(
          modal,
          TrainingSessionWarning.ErrorWarningForPractical,
          undefined,
          undefined,
          t('common:button.ok'),
        );
    } else {
      modal.displayModal(
        ModalUtils.toastConfig({
          text:
            saveAsADraft && isValidPermission(API.Permission.trainingSessions_edit)
              ? t('alex:mobile.trainingScreen.trainingPracticalSavedAsADraft')
              : API.isTrainingSessionScheduledForAfterToday(practicalTrainingSession) &&
                isValidPermission(API.Permission.trainingSessions_edit)
              ? t('alex:mobile.trainingScreen.trainingPracticalScheduled')
              : t('alex:mobile.trainingScreen.trainingCreated'),
          callback: () => handleModalClose(),
        }),
      );
    }
    setLoader(false);
  }

  async function _deleteTrainingSession() {
    if (!trainingSessionId) return;

    setLoader(true);

    const deletedTraining = await API.deleteFactoryBusinessObject(trainingSessionId);
    if (!isMounted.current) return;
    if (API.isFailure(deletedTraining)) {
      if (API.isFailureType(deletedTraining, 'DeletionVeto')) {
        ModalUtils.showVetoModal(t('alex:mobile.trainingScreen.deletionVeto'), deletedTraining);
      } else {
        showTrainingSessionErrorWarning(
          modal,
          TrainingSessionWarning.DeleteErrorWarningForPractical,
          _deleteTrainingSession,
          undefined,
          t('common:button.yes'),
          t('common:button.no'),
        );
      }
    } else {
      if (trainingSession && !API.isTrainingSessionDraftOrRequestValidated(trainingSession)) {
        createRequest(undefined, true);
      }
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:mobile.practicalTrainingScreen.trainingDeleted'),
          callback: () => handleModalClose(),
        }),
      );
    }
    setLoader(false);
  }

  function deleteTrainingSession() {
    if (!trainingSession) return;

    showTrainingSessionErrorWarning(
      modal,
      API.isTrainingSessionDraftOrRequestValidated(trainingSession)
        ? TrainingSessionWarning.DeleteWarningForPractical
        : TrainingSessionWarning.DeleteWarningForPracticalTrainingNotToBePlanned,
      _deleteTrainingSession,
      undefined,
      t('common:button.yes'),
      t('common:button.no'),
    );
  }

  async function _scheduleTrainingSession(
    _trainingSession: API.TrainingSessionPartialUpdateInput,
  ): Promise<API.Result<void>> {
    setLoader(true);

    const updateTrainingSession = await API.updateTrainingSession(_trainingSession);
    if (!isMounted.current) return;
    setLoader(false);
    if (API.isFailure(updateTrainingSession)) {
      if (API.isFailureType(updateTrainingSession, 'DuplicateVeto'))
        displayTrainingSessionDuplicateVetoModal(updateTrainingSession);
      return updateTrainingSession;
    }
    modal.displayModal(
      ModalUtils.toastConfig({
        text: t('alex:mobile.trainingScreen.trainingPracticalScheduled'),
        callback: () => handleModalClose(),
      }),
    );
  }

  function scheduleTrainingSession(_trainingSession: API.TrainingSessionPartialUpdateInput) {
    showTrainingSessionErrorWarning(
      modal,
      TrainingSessionWarning.ScheduleWarningForPractical,
      () => _scheduleTrainingSession(_trainingSession),
      undefined,
      t('common:button.yes'),
      t('common:button.no'),
    );
  }

  async function saveAsADraft(
    _trainingSession: API.TrainingSessionUpdateInput,
  ): Promise<API.Result<void>> {
    setLoader(true);
    const draftedTrainingSession = await API.saveTrainingSessionAsADraft(_trainingSession);
    if (!isMounted.current) return;
    setLoader(false);
    if (API.isFailure(draftedTrainingSession)) {
      if (API.isFailureType(draftedTrainingSession, 'DuplicateVeto'))
        displayTrainingSessionDuplicateVetoModal(draftedTrainingSession);
      return draftedTrainingSession;
    }
    modal.displayModal(
      ModalUtils.toastConfig({
        text: t('alex:mobile.trainingScreen.trainingPracticalSavedAsADraft'),
        callback: () => handleModalClose(),
      }),
    );
  }

  async function handleButtonPress(onPressType: ButtonOnPressTypes) {
    if (!isValidForm(onPressType)) {
      console.log(onPressType);
      showTrainingSessionErrorWarning(
        modal,
        getTrainingSessionErrorType(onPressType, true),
        () => {},
        undefined,
        t('common:button.gotIt'),
      );
      return;
    }

    let linkedObjectId: string | undefined;
    if (selectedWorkstation) linkedObjectId = selectedWorkstation.key;
    if (orgUnit) linkedObjectId = orgUnit.key;
    const workerId = worker?.key || workerWithOutLevel?.key;
    const mentorsWithPercentage =
      mentors?.map(mentor => {
        return {
          trainerId: mentor.key,
          percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
        };
      }) ||
      mentorsWithOutLevel?.map(mentor => {
        return {
          trainerId: mentor.key,
          percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
        };
      });

    
    if (onPressType === ButtonOnPressTypes.CREATE) {
      createRequest();
    }
    
    else if (onPressType === ButtonOnPressTypes.REJECTREQUEST) {
      if (!trainingSessionId) return;
      setLoader(true);
      const rejectedTrainingSession = await API.rejectTrainingSession(trainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(rejectedTrainingSession)) return rejectedTrainingSession;
      setLoader(false);
      handleModalClose();
    }
    
    else if (onPressType === ButtonOnPressTypes.VALIDATEREQUEST) {
      if (!trainingSessionId) return;
      setLoader(true);
      const validatedTrainingSession = await API.validateTrainingSession(trainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(validatedTrainingSession)) return validatedTrainingSession;
      setLoader(false);
      handleModalClose();
    }
    
    else if (onPressType === ButtonOnPressTypes.DELETE) {
      if (!trainingSessionId) return;
      deleteTrainingSession();
    }
    
    else if (onPressType === ButtonOnPressTypes.SCHEDULE) {
      if (
        !trainingSessionId ||
        !workerId ||
        !linkedObjectId ||
        !mentorsWithPercentage ||
        !scheduledStartDate ||
        !duration
      )
        return;

      const trainingSession = await API.getTrainingSession(trainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(trainingSession)) {
        return trainingSession;
      }
      scheduleTrainingSession({
        id: trainingSession.id,
        scheduledTraineeIds: [workerId],
        scheduledTrainers: mentorsWithPercentage ?? [],
        scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
        durationInMin: duration?.value,
        description: description,
        isDraft: false,
      });
    }
    
    else if (onPressType === ButtonOnPressTypes.UPDATE) {
      if (!trainingSessionId || !workerId || !linkedObjectId || !selectedLevel) return;

      setLoader(true);
      const trainingSession = await API.getTrainingSession(trainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(trainingSession)) {
        setLoader(false);
        return trainingSession;
      }

      const levelRequirementsMap = await API.getLevelsRequirement(linkedObjectId);
      if (!isMounted.current) return;
      if (API.isFailure(levelRequirementsMap)) {
        setLoader(false);
        return levelRequirementsMap;
      }

      const levelRequirement = levelRequirementsMap.get(selectedLevel.level);
      if (!levelRequirement || !levelRequirement.practicalTrainingId) {
        setLoader(false);
        return;
      }

      const trainingVersion = await API.getTrainingVersionLatestForTraining(
        levelRequirement.practicalTrainingId,
      );
      if (!isMounted.current) return;
      if (API.isFailure(trainingVersion)) {
        setLoader(false);
        return trainingVersion;
      }

      const updateTrainingSession = await API.updateTrainingSession({
        ...API.deepClone(trainingSession),
        id: trainingSession.id,
        description: description,
        scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
        scheduledTraineeIds: [workerId],
        scheduledTrainers: mentorsWithPercentage,
        durationInMin: duration?.value,
        requirementId: levelRequirement.id,
        originId: linkedObjectId,
        trainingVersionId: trainingVersion.id,
      });
      if (!isMounted.current) return;
      if (API.isFailure(updateTrainingSession)) {
        setLoader(false);
        return updateTrainingSession;
      }
      if (traineeChanged)
        API.createTrainingRequestForEachTrainee(
          trainingSession.trainingVersionId,
          [...trainingSession.scheduledTraineeIds],
          trainingSession.requirementId,
          trainingSession.originId,
        );

      setLoader(false);
      handleModalClose();
    }
    
    else if (onPressType === ButtonOnPressTypes.ADDPROOF) {
      if (handleAddProof) handleAddProof();
    }
    
    else if (onPressType === ButtonOnPressTypes.UNLOCKEDIT) {
      if (isEditUnlocked) {
        if (
          !trainingSessionId ||
          !workerId ||
          !linkedObjectId ||
          !mentorsWithPercentage ||
          !scheduledStartDate ||
          !duration
        )
          return;

        setLoader(true);
        const trainingSession = await API.getTrainingSession(trainingSessionId);
        if (!isMounted.current) return;
        if (API.isFailure(trainingSession)) {
          setLoader(false);
          return trainingSession;
        }

        const updateTrainingSession = await API.updateTrainingSession({
          id: trainingSession.id,
          scheduledTraineeIds: [workerId],
          scheduledTrainers: mentorsWithPercentage,
          scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
          durationInMin: duration?.value,
          description: description,
        });
        if (!isMounted.current) return;
        if (API.isFailure(updateTrainingSession)) {
          if (API.isFailureType(updateTrainingSession, 'DuplicateVeto'))
            displayTrainingSessionDuplicateVetoModal(updateTrainingSession);
          setLoader(false);
          return updateTrainingSession;
        }
        setLoader(false);
        handleModalClose();
      } else {
        setIsEditUnlocked(true);
      }
    }
    
    else if (onPressType === ButtonOnPressTypes.SAVEASDRAFT) {
      if (!trainingSessionId) {
        createRequest(true);
        return;
      }

      const trainingSession = await API.getTrainingSession(trainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(trainingSession)) return trainingSession;

      await saveAsADraft({
        ...API.deepClone(trainingSession),
        scheduledTraineeIds: worker
          ? [worker.key]
          : workerWithOutLevel
          ? [workerWithOutLevel.key]
          : [],
        scheduledTrainers: mentorsWithPercentage ?? [],
        scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
        durationInMin: duration?.value,
        description: description,
      });

      if (traineeChanged)
        API.createTrainingRequestForEachTrainee(
          trainingSession.trainingVersionId,
          [...trainingSession.scheduledTraineeIds],
          trainingSession.requirementId,
          trainingSession.originId,
        );
    }
  }

  function handleTreeNodeChangeAndResetInputs(value?: DropDownOption) {
    
    handleTraineeDropDown(undefined);
    handleMentorsDropdown(undefined);
    handleMentorsWithOutLevelDropdown(undefined);
    handleInputText('', undefined);
    onChange(undefined);
    handleDropdown(TrainingSessionInputElements.duration, undefined);
    handleTrainingWithLevel(undefined);
    handleDropdown(API.DataType.WORKSTATION, value);
  }

  function renderButtons() {
    if (!buttonsArray) return null;

    return _.map(buttonsArray, (button, index) => {
      if (index !== buttonsArray.length - 1) {
        return (
          <View key={index} style={styles.textButtonContainer}>
            <TextButton
              text={t(button.label)}
              disabled={!isValidForm(button.onPressType)}
              onPress={() => {
                handleButtonPress(button.onPressType);
              }}
            />
            <View style={styles.textButtonDivider} />
          </View>
        );
      } else {
        return (
          <YellowButton
            key={index}
            text={t(button.label)}
            style={styles.bottomButtonStyle}
            textStyle={styles.bottomButtonTextStyle}
            onPress={() => {
              handleButtonPress(button.onPressType);
            }}
          />
        );
      }
    });
  }

  const tabs: Tab[] = [
    {
      title: {
        title: t('alex:scheduleTrainingModal.advancment'),
      },
    },
    {
      title: {
        title: t('common:content'),
      },
    },
  ];

  function onWorkstationPress(id?: string) {
    if (id) {
      const _treeNode = API.Tree.getTreeNode(id);
      if (API.isFailure(_treeNode)) {
        logger.warn(_treeNode);
        return;
      }

      setTreeNode(_treeNode);
      history.push(RouteLocations.Workstations(id));
    }
  }

  return (
    <WebModal
      animationType="fade"
      transparent
      visible={true}
      onRequestClose={() => handleModalClose()}
    >
      <View style={ModalBackgroundStyle}>
        <View style={[ModalCardStyle, styles.modal]}>
          <ModalHeader handleModalClose={() => handleModalClose()} title={modalTitle ?? ''} />

          <View style={[ModalBodyWithAnchorStyle, styles.body]}>
            {/** LEFT PANEL */}
            <View style={styles.leftPanelContainer}>
              <View style={[ModalAnchorContainerStyle, styles.iconContainer]}>
                <IconSVG svgComponent={PracticalTraining} color={Colors.White} />
              </View>
              <View style={[styles.leftPanelInputContainer]}>
                <View style={{ zIndex: 9 }}>
                  {/** TreeNode */}
                  {isShowStep2 && (
                    <TextButton
                      text={t('alex:scheduleTrainingModal.trainingFromWorkstation')}
                      textStyle={{ color: Colors.Grey }}
                      onPress={() => {
                        if (selectedWorkstationId || selectedWorkstation)
                          onWorkstationPress(selectedWorkstationId || selectedWorkstation?.key);
                        else history.push(RouteLocations.Workstations());
                      }}
                      containerStyle={{
                        top: Spacings.xMedium,
                      }}
                    />
                  )}
                  <DropDownSingleSelection
                    placeholder={t('alex:TrainingModal.chooseWorkstation')}
                    searchPlaceholder={t('alex:TrainingModal.chooseWorkstation')}
                    containerStyle={[Styles.fullWidth]}
                    inputListContainerStyle={styles.inputListContainer}
                    notEditable={loading || (isInputDisabled && !isEditUnlocked) || !!orgUnit}
                    value={selectedWorkstation || orgUnit}
                    hasSearch
                    options={workstationDropdownOptions}
                    handleChange={(_, value) => {
                      handleTreeNodeChangeAndResetInputs(value);
                      setSelectedWorkstationId(value?.key);
                    }}
                    listIcon={WorkstationIcon}
                  />
                </View>

                {/** Worker */}
                {isShowStep2 && (
                  <View style={{ zIndex: 8 }}>
                    {/** level */}
                    <DropDownWithWorkerLevel
                      options={dropDownWorkerLevels}
                      notEditable={!selectedWorkstation || (isInputDisabled && !isEditUnlocked)}
                      placeholder={t('glossary:level')}
                      searchPlaceholder={t('glossary:level')}
                      containerStyle={[Styles.fullWidth, { zIndex: 3 }]}
                      value={selectedLevel}
                      hasLevelOnly
                      handleChange={(_, item) => {
                        if (item) {
                          setSelectedLevel(item);
                          handleLevelSelection(item);
                        }
                      }}
                    />
                    {workerWithOutLevel ? (
                      <DropDownSingleSelection
                        title={t('glossary:worker')}
                        placeholder={t('alex:TrainingModal.chooseWorker')}
                        searchPlaceholder={t('alex:TrainingModal.chooseWorker')}
                        containerStyle={[Styles.fullWidth, { zIndex: 2 }]}
                        inputListContainerStyle={styles.inputListContainer}
                        notEditable
                        value={workerWithOutLevel}
                        hasSearch
                        options={[]}
                        listIcon={WorkerIcon}
                      />
                    ) : (
                      <DropDownWithWorkerLevel
                        title={t('glossary:worker')}
                        placeholder={t('alex:TrainingModal.chooseWorker')}
                        searchPlaceholder={t('alex:TrainingModal.chooseWorker')}
                        hasSearch
                        notEditable={
                          !selectedWorkstation ||
                          (isInputDisabled && !isEditUnlocked) ||
                          !isValidPermission(API.Permission.trainingSessions_edit)
                        }
                        containerStyle={[Styles.fullWidth, { zIndex: 1 }]}
                        inputListContainerStyle={[styles.inputListWorkerContainer]}
                        options={workerDropdownOptions}
                        value={worker}
                        handleChange={(_, value) => handleTraineeDropDown(value)}
                        noResultMessage={t('alex:TrainingModal.noResult')}
                        listIcon={WorkerIcon}
                      />
                    )}
                  </View>
                )}
                {(isValidPermission(API.Permission.trainingSessions_edit) || trainingSessionId) && (
                  <View style={{ zIndex: 7 }}>
                    {/** Date & Duration */}
                    {isShowStep3 && (
                      <>
                        <View style={[styles.dateTimeContainer, { zIndex: 2 }]}>
                          <View>
                            <InputDateWeb
                              style={styles.minWidth}
                              inputName={TrainingSessionInputElements.date}
                              inputPlaceholder={t('common:time.date')}
                              hasDateIcon
                              mode={Mode.Date}
                              notEditable={
                                (isInputDisabled && !isEditUnlocked) ||
                                !isValidPermission(API.Permission.trainingSessions_edit)
                              }
                              value={scheduledStartDate}
                              onChange={(_inputName, date) => onChange(date)}
                            />
                          </View>
                          <View>
                            <InputDateWeb
                              style={styles.minWidth}
                              inputName={TrainingSessionInputElements.time}
                              inputPlaceholder={t('common:time.clockTime')}
                              hasTimeIcon
                              mode={Mode.Time}
                              notEditable={
                                (isInputDisabled && !isEditUnlocked) ||
                                !isValidPermission(API.Permission.trainingSessions_edit)
                              }
                              value={scheduledStartDate}
                              onChange={(inputName, time) => onChange(time)}
                            />
                          </View>
                        </View>
                        <View style={[styles.durationContainer]}>
                          <DropDownSingleSelection
                            title={t('alex:mobile.trainingScreen.duration')}
                            options={getTrainingDurationOptions()}
                            placeholder={t('alex:mobile.trainingScreen.duration')}
                            containerStyle={styles.minWidth}
                            icon={ClockIcon}
                            notEditable={
                              (isInputDisabled && !isEditUnlocked) ||
                              !isValidPermission(API.Permission.trainingSessions_edit)
                            }
                            value={duration}
                            handleChange={(_, value) =>
                              handleDropdown(TrainingSessionInputElements.duration, value)
                            }
                          />
                        </View>
                      </>
                    )}
                  </View>
                )}
                {/** Comments */}
                {isShowStep3 && (
                  <InputTextDescription
                    inputId={TrainingSessionInputElements.description}
                    label={t('alex:scheduleTrainingModal.description')}
                    placeHolder={t('alex:scheduleTrainingModal.addDescription')}
                    defaultValue={description}
                    containerStyle={[styles.descriptionContainer, { zIndex: 6 }]}
                    onTextChange={(inputId: string, inputValue: string) => {
                      handleInputText(inputId, inputValue);
                    }}
                    notEditable={isInputDisabled && !isEditUnlocked}
                  />
                )}
              </View>
            </View>

            {/** RIGHT PANEL */}
            <View
              style={[
                styles.rightPanelContainer,
                {
                  paddingTop: isPracticeToSchedule ? Spacings.Large : 0,
                },
              ]}
            >
              {!isPracticeToSchedule && (
                <AnimatedTab
                  currentTabIndex={isAdvancementSelected ? 0 : 1}
                  tabs={tabs}
                  onTabPress={() => {
                    setIsAdvancementSelected(!isAdvancementSelected);
                  }}
                  isMobileStyle
                />
              )}
              {isAdvancementSelected ? (
                <ScrollView
                  style={[
                    styles.tabContentContainer,
                    {
                      height: 300,
                      width: '100%',
                    },
                  ]}
                >
                  {/** INFO */}
                  <Text style={styles.InfoMessage}>
                    {t('alex:mobile.practicalTrainingScreen.practiceInfoMessage')}
                  </Text>

                  {skillTags.map((skillTag, index) => {
                    return (
                      <View
                        key={skillTag.key}
                        style={[styles.advancementContainer, { zIndex: 100 - index }]}
                      >
                        <View
                          style={[
                            styles.skillAdvancmentRowContainer,
                            {
                              paddingLeft:
                                skillTag.value && API.isSkillGroup(skillTag.value) ? 0 : 32,
                            },
                          ]}
                        >
                          <View style={[styles.skillNameContainer, { maxWidth: '55%' }]}>
                            {skillTag.value && API.isSkillGroup(skillTag.value) && (
                              <TouchableOpacity
                                onPress={() => {
                                  setSkillAsCollapsed(index, !!isSkillCollapsedArray[index]);
                                }}
                              >
                                <IconSVG
                                  svgComponent={
                                    isSkillCollapsedArray[index] === true
                                      ? arrowRightIcon
                                      : arrowDownIcon
                                  }
                                  size={{
                                    width: Spacings.Standard,
                                    height: Spacings.Standard,
                                  }}
                                  color={Colors.Black}
                                  containerStyle={[
                                    styles.acquiredIconContainer,
                                    { marginTop: Spacings.Small, marginRight: 6 },
                                  ]}
                                />
                              </TouchableOpacity>
                            )}
                            <IconSVG
                              svgComponent={ICON_SKILL}
                              size={{ width: Spacings.Standard, height: Spacings.Standard }}
                              color={Colors.White}
                              containerStyle={styles.skillIconContainer}
                            />
                            <Text style={[styles.skillNameTextStyle]}>{skillTag.label}</Text>
                          </View>
                          <View style={styles.acquiredContainer}>
                            <Text style={styles.acquiredTextStyle}>
                              {t('alex:scheduleTrainingModal.acquired')}
                            </Text>
                            <View style={styles.AcquiredIconContaner}>
                              <TouchableOpacity
                                onPress={() =>
                                  setSkillAsAcquired(skillTag, index, !isSkillAcquiredArray[index])
                                }
                              >
                                <IconSVG
                                  svgComponent={ThumbUp}
                                  size={{ width: Spacings.Standard, height: Spacings.Standard }}
                                  color={
                                    isSkillAcquiredArray[index] ? Colors.Green : Colors.GreyLight
                                  }
                                  containerStyle={[
                                    styles.acquiredIconContainer,
                                    { marginTop: Spacings.Unit },
                                  ]}
                                />
                              </TouchableOpacity>
                              <TouchableOpacity
                                onPress={() =>
                                  setSkillAsRejected(skillTag, index, !isSkillRejectedArray[index])
                                }
                              >
                                <IconSVG
                                  svgComponent={ThumbDown}
                                  size={{ width: Spacings.Standard, height: Spacings.Standard }}
                                  color={
                                    isSkillRejectedArray[index] ? Colors.Red : Colors.GreyLight
                                  }
                                  containerStyle={[
                                    styles.acquiredIconContainer,
                                    { marginTop: Spacings.Small },
                                  ]}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>

                        {reviewer && trainee && props.trainingSessionId && (
                          <InputProof
                            placeHolder={t('alex:scheduleTrainingModal.description')}
                            style={{
                              marginLeft: Spacings.Large,
                            }}
                            workerIdAndName={{
                              id: trainee.key,
                              name: trainee.label,
                            }}
                            skillIdAndName={{ id: skillTag.key, name: skillTag.label }}
                            validatorName={reviewer.name}
                            trainingSessionId={props.trainingSessionId}
                            itemIndex={index}
                            initialProofBunble={proofs[index]}
                          />
                        )}

                        {skillTag.value &&
                          API.isSkillGroup(skillTag.value) &&
                          isSkillCollapsedArray[index] === false &&
                          skillTag.children && (
                            <SubSkills
                              subSkills={skillTag.children}
                              reviewer={reviewer}
                              trainee={trainee}
                              trainingSessionId={trainingSessionId}
                              proofs={subSkillsProofs.get(skillTag.key) ?? []}
                              setProofs={(proofs: (API.ProofBundle | undefined)[]) => {
                                subSkillsProofs.set(skillTag.key, proofs);
                              }}
                              isSkillAcquiredArray={
                                subSkillsIsSkillAcquiredArrayMap.get(skillTag.key) ?? []
                              }
                              setIsSkillAcquiredArray={(arr: boolean[]) => {
                                subSkillsIsSkillAcquiredArrayMap.set(skillTag.key, arr);
                                setSubSkillsIsSkillAcquiredArrayMap(
                                  new Map(subSkillsIsSkillAcquiredArrayMap),
                                );
                              }}
                              isSkillRejectedArray={
                                subSkillsIsSkillRejectedArrayMap.get(skillTag.key) ?? []
                              }
                              setIsSkillRejectedArray={(arr: boolean[]) => {
                                subSkillsIsSkillRejectedArrayMap.set(skillTag.key, arr);
                                setSubSkillsIsSkillRejectedArrayMap(
                                  new Map(subSkillsIsSkillRejectedArrayMap),
                                );
                              }}
                            />
                          )}
                      </View>
                    );
                  })}
                </ScrollView>
              ) : (
                <View style={{ flex: 1, flexDirection: 'row' }}>
                  {/** Mentor */}
                  {isShowStep3 ? (
                    <View style={{ zIndex: 1, width: '47%', marginRight: 20 }}>
                      <Text style={[styles.participantsHeader, { marginTop: 8 }]}>
                        {t('glossary:trainer_plural')}
                      </Text>
                      {(!isInputDisabled || isEditUnlocked) && (
                        <>
                          {mentorOptions?.length ? (
                            <DropDownMultiSelection
                              title={t('glossary:mentor')}
                              options={mentorOptions ?? []}
                              placeholder={t('alex:mobile.practicalTrainingScreen.selectMentor')}
                              values={mentors}
                              containerStyle={[Styles.fullWidth, { zIndex: 1 }]}
                              inputListContainerStyle={[
                                styles.inputListContainer,
                                styles.inputListWorkerContainer, 
                              ]}
                              notEditable={
                                (isInputDisabled && !isEditUnlocked) ||
                                !isValidPermission(API.Permission.trainingSessions_edit)
                              }
                              additionForm
                              listIcon={WorkerIcon}
                              handleChange={(_, value) => {
                                handleMentorsDropdown(value);
                              }}
                              showAdditionFormList={false}
                            />
                          ) : (
                            <DropDownMultiSelection
                              title={t('glossary:mentor')}
                              options={workerWithOutLevelDropdownOptions}
                              placeholder={t('alex:mobile.practicalTrainingScreen.selectMentor')}
                              values={mentorsWithOutLevel}
                              containerStyle={[Styles.fullWidth, { zIndex: 1 }]}
                              inputListContainerStyle={styles.inputListContainer}
                              notEditable={
                                (isInputDisabled && !isEditUnlocked) ||
                                !isValidPermission(API.Permission.trainingSessions_edit)
                              }
                              additionForm
                              errorMessage={
                                workerWithOutLevelDropdownOptions?.length
                                  ? ''
                                  : t('alex:scheduleTrainingModal.noMentor')
                              }
                              listIcon={WorkerIcon}
                              handleChange={(_, value) => {
                                handleMentorsWithOutLevelDropdown(value);
                              }}
                              showAdditionFormList={false}
                            />
                          )}
                        </>
                      )}
                      <View style={[styles.verticalDivider, { zIndex: 2 }]} />
                      <WorkersList
                        scheduledWorkers={mentors ? mentors : mentorsWithOutLevel ?? []}
                        traineesIds={trainingSession?.traineeIds ?? []}
                        trainingSession={trainingSession}
                        isEditUnlocked={
                          (isInputDisabled && !isEditUnlocked) ||
                          !isValidPermission(API.Permission.trainingSessions_edit)
                            ? false
                            : true
                        }
                        handleWorkers={oldMentors =>
                          mentors
                            ? handleMentorsDropdown(oldMentors)
                            : handleMentorsWithOutLevelDropdown(oldMentors)
                        }
                        isMentorList
                      />
                    </View>
                  ) : null}
                  <View style={{ width: isShowStep3 ? '47%' : '100%' }}>
                    <Text style={styles.contentOfPractice}>
                      {t('alex:scheduleTrainingModal.contentOfPractice')}
                    </Text>
                    <View style={{ zIndex: 0 }}>
                      {noAvailablePractices ? (
                        <View>
                          <NoTrainingsCustomMessage treeNodeId={selectedWorkstation?.key} />
                        </View>
                      ) : (
                        !!complementaryTrainings?.length &&
                        !trainingSessionId && (
                          <ComplementaryTrainingsMessage
                            level={trainingWithLevel?.level}
                            complementaryTrainings={complementaryTrainings}
                            handleTrainAWorkerModal={() => {
                              if (
                                !selectedWorkstation ||
                                !worker ||
                                !trainingWithLevel ||
                                !handleTrainAWorkerModal
                              )
                                return;
                              handleTrainAWorkerModal(
                                selectedWorkstation,
                                worker,
                                trainingWithLevel.level,
                              );
                            }}
                          />
                        )
                      )}
                      {!!isTrainingSessionToBeClosed && <ToBeClosedTrainingsMessage />}
                    </View>

                    {!noAvailablePractices && trainingWithLevel && (
                      <View style={{ zIndex: 0 }}>
                        <Text style={styles.descriptionTitle}>{t('alex:skills.description')}</Text>
                        <Hyperlink linkDefault={true} linkStyle={styles.linkStyle}>
                          <Text style={styles.description}>{trainingDescription}</Text>
                        </Hyperlink>
                        <TrainingVersionFiles
                          trainingVersionId={trainingWithLevel.key}
                          style={{ marginBottom: Spacings.Medium }}
                        />

                        {/** Skills */}
                        <Text style={[styles.skillsListHeaderTextStyle]}>
                          {t('alex:trainingModal.trainingAcquisitionPractical')}
                        </Text>
                        <ScrollView style={styles.skillListingContainer}>
                          {skillTags.map(skillTag => {
                            return (
                              <View key={skillTag.key} style={[styles.skillListingRowContainer]}>
                                <View style={[styles.skillNameContainer]}>
                                  <IconSVG
                                    svgComponent={ICON_SKILL}
                                    size={{
                                      width: Spacings.Standard,
                                      height: Spacings.Standard,
                                    }}
                                    color={Colors.White}
                                    containerStyle={styles.skillIconContainer}
                                  />
                                  <Text style={[styles.skillNameTextStyle]}>{skillTag.label}</Text>
                                </View>
                              </View>
                            );
                          })}
                        </ScrollView>
                      </View>
                    )}
                  </View>
                </View>
              )}
            </View>
          </View>

          {/** Bottom PANEL */}
          <View style={[ModalFooterStyle, { zIndex: -3 }]}>
            <View style={[styles.workstationModalBottomButtonContainer]}>
              {buttonsArray && renderButtons()}
            </View>
          </View>
        </View>
      </View>
      {loader && <Loader />}
    </WebModal>
  );
};
