import React, { useEffect, useRef, useState } from 'react';
import { View, ScrollView, TouchableOpacity, Animated } from 'react-native';
import {
  Tutorial1,
  Tutorial2,
  Tutorial3,
} from 'skillmgtweb/src/components/dashboard/versatility-panel/levels-tutorial/LevelsTutorial';
import { TextButton } from 'shared/ui-component/Button';
import { styles } from './styles';
import { t } from 'shared/localisation/i18n';
import { Colors } from 'shared/styles';
import { ModalUtils } from '../../ui-component/Modal';

const PAGES = [Tutorial1, Tutorial2, Tutorial3];

export const UpdatesContent: React.FC = () => {
  const [innerScrollViewWidth, setInnerScrollViewWidth] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  const modal = ModalUtils.useModal();

  useEffect(() => {
    if (innerScrollViewWidth) {
      setIsFirstPage(scrollPosition < 1);
      setIsLastPage(scrollPosition === PAGES.length - 1);
    }
  }, [scrollPosition, innerScrollViewWidth]);

  const ref = useRef<ScrollView>(null);

  return (
    <View style={styles.container}>
      <ScrollView
        ref={ref}
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContentContainer}
        onLayout={event => {
          setInnerScrollViewWidth(event.nativeEvent.layout.width);
        }}
        horizontal
        showsHorizontalScrollIndicator={false}
        onScroll={event => {
          const xOffset = event.nativeEvent.contentOffset.x;

          if (innerScrollViewWidth) setScrollPosition(Math.round(xOffset / innerScrollViewWidth));
        }}
      >
        {PAGES.map(Page => {
          return (
            <View style={styles.pageContainer}>
              <Page />
            </View>
          );
        })}
      </ScrollView>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          height: 40,
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 240,
        }}
      >
        <TextButton
          text={t('common:button.previous')}
          disabled={isFirstPage}
          onPress={() => {
            if (scrollPosition > 0) {
              ref.current?.scrollTo({
                x: (scrollPosition - 1) * innerScrollViewWidth,
              });
            }
          }}
        />
        <View style={{ flexDirection: 'row', width: '30%', justifyContent: 'space-between' }}>
          {PAGES.map((Page, index) => {
            let color = new Animated.Value(scrollPosition).interpolate({
              inputRange: [index - 1, index, index + 1], 
              outputRange: [Colors.GreyLight, Colors.Black, Colors.GreyLight],
              extrapolate: 'clamp', 
            });
            return (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  ref.current?.scrollTo({
                    x: innerScrollViewWidth ? innerScrollViewWidth * index : 0,
                  });
                }}
              >
                <Animated.View
                  style={[styles.animatedView, { backgroundColor: color }]}
                  key={index}
                />
              </TouchableOpacity>
            );
          })}
        </View>
        {isLastPage ? (
          <TextButton
            text={t('common:button.close')}
            onPress={() => {
              modal.hideModal();
            }}
          />
        ) : (
          <TextButton
            text={t('common:button.next')}
            onPress={() => {
              if (scrollPosition < PAGES.length) {
                ref.current?.scrollTo({
                  x: (scrollPosition + 1) * innerScrollViewWidth,
                });
              }
            }}
          />
        )}
      </View>
    </View>
  );
};

export default UpdatesContent;
