import { Platform } from 'react-native-web'; 
import { UserContext } from '../../context/UserContext';
import { BaseEvent, EventOptions, AmplitudeReturn, Result } from '@amplitude/analytics-types';
import logger from '../Logger';
import { isFailure } from '../../backend-data/Failure';
import Native from './AppMonitor.native';
import Web from './AppMonitor.web';
import { isPlatformServerless } from 'shared/util-ts/Functions';

const { init, logEvent } = Platform.OS === 'web' ? Web : Native;

export const API_KEY = '34302da567c51dc00c7cd386a9f395d7';

const enableAnalytics = Boolean(logger.isPROD && !isPlatformServerless);

if (enableAnalytics)
  try {
    init(API_KEY);
  } catch (e) {
    logger.error('AppMonitor ERROR ::: ', e);
  }

export enum PerfOperations {
  SyncClientCacheWithServer = 'SyncClientCacheWithServer',
  DeltaSync = 'DeltaSync',
  BaseSync = 'BaseSync',
  PersistCache = 'PersistCache',
  SaveLastSync = 'SaveLastSync',
  UpadeCache = 'UpdateCache',
  SyncFactoriesUpdateCache = 'SyncFactoriesUpdateCache',
  SyncFactories = 'SyncFactories',
}

export enum EventType {
  PERF = 'PERF',
}

export interface PerfEventProps {
  durationInMs: number;
  pk: string;
}

/**
 * Normaly you don't want to wait for the result.
 * The function is not async on purpuse to not mislead the developer to await for this function to return
 * @param operation
 * @param options
 */
export function logAnalytics(
  operation: PerfOperations,
  options: PerfEventProps,
): false | Promise<false | Result> {
  if (!enableAnalytics) return false;

  const { pk, durationInMs } = options;

  const user = UserContext.getUser();
  if (isFailure(user)) {
    logger.warn(
      'Amplitude error, event not logged as user cannot  be retireved',
      operation,
      options,
    );
    return false;
  }

  return logEvent(
    EventType.PERF,
    {
      operation: operation,
      durationInMs: durationInMs,
      pk: pk,
      date: Date.now(),
    },
    { platform: Platform.OS, user_id: user.id },
  )
    .promise.then(result => {
      return result;
    })
    .catch(error => {
      logger.warn('Amplitude error, event not logged', error, operation, options);
      return false;
    });
}
