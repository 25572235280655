import { StyleSheet, Dimensions } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  scrollContainer: {
    flexGrow: 0,
    height: '100%',
  },
  scrollContentContainer: {
    flexDirection: 'row',
    paddingLeft: Spacings.Medium,
  },
  touchableSwitch: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
  },
  pageNumberTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },
});

export default styles;
