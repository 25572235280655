import { StyleSheet, Dimensions } from 'react-native';
const { height } = Dimensions.get('window');

import { responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions';
import { TextFontStyles, Colors, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: 'rgba(52, 52, 52, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  passwordFormContainer: {
    backgroundColor: 'white',
    height: responsiveHeight(59.42),
    width: responsiveWidth(46.56),
    paddingTop: height * 0.046,
    paddingBottom: height * 0.037,
    alignItems: 'center',
  },
  formTitle: {
    fontFamily: 'Lato, Regular',
    color: '#666666',
    fontSize: height * 0.025,
    textAlign: 'center',
  },
  setTopMarginForOldPasswordInput: {
    marginTop: height * 0.045,
  },
  setTopMarginForNewPasswordInput: {
    marginTop: height * 0.015,
  },
  cancelButton: {
    marginTop: 0.029 * height,
    alignSelf: 'center',
  },
  cancelLabel: {
    color: '#999999',
    fontFamily: 'Lato, Regular',
    fontSize: 0.015 * height,
    textDecorationLine: 'underline',
  },
  forgetPasswordText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    fontSize: fontSize.Small,
  },
  apiErrorLabel: {
    color: '#F44336',
    fontFamily: 'Lato, Regular',
    fontSize: 0.015 * height,
    textDecorationLine: 'underline',
    marginTop: height * 0.02,
    textAlign: 'center',
  },
});

export default styles;
