import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Dimensions,
  Animated,
  Easing,
  InteractionManager,
} from 'react-native';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { IconSVG } from 'shared/ui-component/Icon';
import { useIsMounted } from 'shared/hooks/IsMounted';
import Styles from './Styles';
import { RouteLocations } from 'skillmgtweb/src/components/navigation/Routes';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { HeaderProps } from 'skillmgtweb/src/components/header-layout/HeaderLayout';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import * as API from 'shared/backend-data';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { WorkersStatus } from 'shared/util/Worker';
import { Filter, HeaderFilter } from 'sharedweb/src/Filter/container';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { Colors, Spacings } from 'shared/styles';
import { HeaderTitle } from './HeaderTitle.web';
import { HelpMenu } from './HelpMenu';
import { UpdateButton } from './UpdateButton/UpdateButton';
import { MyHub } from 'shared/util/MyHub';
import { DataLayer } from 'shared/backend-data/DataLayer';
import { UserContext } from 'shared/context/UserContext';
import { useModal, ModalUtils } from 'shared/ui-component/Modal';
import _ from 'lodash';
import { AppContext } from 'shared/context/AppContext';

const helpIcon = require('shared/assets/svg/icon.help.svg').default;
const NotifSVG = require('shared/assets/svg/icon.notif.mobile.svg').default;

const showModalDebounceInMs = 3000;

interface Props extends HeaderProps {
  setAction: Function;
  openLogoutBox: Function;
  showUsersModal: Function;
  showMyAccountModal: Function;
  worker: API.Worker;
  filter: HeaderFilter;
  loader?: boolean;
}

export const Header: React.FC<Props> = props => {
  const { setAction, openLogoutBox, showUsersModal, filter, loader, worker, showMyAccountModal } =
    props;

  const { width } = Dimensions.get('window');

  const isMounted = useIsMounted();

  const modal = useModal();

  const [showDropdown, setDropdown] = useState(false);
  const [dropDownMenuZIndex] = useState(999);
  const [, setScreen] = useState(width);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [openHelpMenu, setOpenHelpMenu] = useState<boolean>(false);
  const [isShowUpdateButton, setIsShowUpdateButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const displayUpdateButton = useCallback(
    _.debounce(
      () => {
        _displayUpdateButton();
      },
      showModalDebounceInMs,
      { leading: false, trailing: true },
    ),
    [isShowUpdateButton],
  );

  const dropDownMenuHeight = useRef(new Animated.Value(0)).current;

  const { isValidPermission } = useContext(PermissionManagementContext);

  useEffect(() => {
    if (showDropdown) _setUsersCount();
  }, [showDropdown]);

  useEffect(() => {
    const removeListener = MyHub.listenAppContext('TenantAppCacheSyncNeeded', async payload => {
      if (isShowUpdateButton) return;

      if (await DataLayer.isForceBaseSyncAtNextSyncNeeded(payload.pk)) {
        displayUpdateButton();
      }
    });

    const removeListener2 = MyHub.listenAppContext('ForceTenantAppCacheBaseSync', async payload => {
      if (payload.isForceBaseSync) getLatestData();
    });

    InteractionManager.runAfterInteractions(() => {
      checkIsDisplayUpdateButton();
    });

    return () => {
      removeListener();
      removeListener2();
    };
  }, [isShowUpdateButton]);

  useEffect(() => {
    if (isMounted.current) handleMenu(showDropdown);
  }, [showDropdown]);

  function _displayUpdateButton() {
    if (isShowUpdateButton) return;

    setIsShowUpdateButton(true);
  }

  function displayRefreshModal() {
    modal.displayModal(
      ModalUtils.warningConfig({
        isBlocking: true,
        warningMessage: t('common:userPermissionsReloadModal.message'),
        warningAcceptButton: t('common:button.refresh'),
        warningCancelButton: t('common:button.continue'),

        warningCancelCallback: () => modal.hideModal(),
        warningAcceptCallback: getLatestData,
      }),
    );
  }

  /**
   * Reload the window to prevent the old stored data in the components/contexts to be displayed
   */
  async function getLatestData() {
    const user = UserContext.getUser();
    if (API.isFailure(user)) return user;

    setIsLoading(true);

    await Promise.all(
      user.authorizedPks.map(async authorizedPk => {
        return DataLayer.forceBaseSyncAtNextSync(authorizedPk.pk);
      }),
    );
    if (!isMounted.current) return;
    setIsLoading(false);

    
    
    
    
    
    
    
    
    location.reload();
  }

  async function checkIsDisplayUpdateButton() {
    if (isShowUpdateButton) return;

    const user = UserContext.getUser();
    if (API.isFailure(user)) {
      API.logger.error('Header ERROR: Failed to fetch user');
      return user;
    }

    let isForceBaseSyncAtNextSyncNeeded = false;
    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) {
      API.logger.error('Failed to fetch app context');
      return appContext;
    }

    await Promise.all(
      user.authorizedPks.map(async authorizedPk => {
        const _isForceBaseSyncAtNextSyncNeeded = await DataLayer.isForceBaseSyncAtNextSyncNeeded(
          authorizedPk.pk,
        );
        if (_isForceBaseSyncAtNextSyncNeeded) {
          if (appContext.pk === authorizedPk.pk) {
            isForceBaseSyncAtNextSyncNeeded = true;
          }
        }
      }),
    );
    if (isForceBaseSyncAtNextSyncNeeded) setIsShowUpdateButton(true);
  }

  async function _setUsersCount() {
    const workers = await API.getWorkers(undefined, undefined, false, WorkersStatus.Users);
    if (!isMounted.current) return;
    if (API.isFailure(workers)) return workers;
    setUsersCount(workers.result.length);
  }

  const closeMenu = () => {
    setTimeout(() => {
      if (isMounted.current) setDropdown(false);
    }, 0.5);
  };

  const closeHelpMenu = () => {
    setTimeout(() => {
      if (isMounted.current) setOpenHelpMenu(false);
    }, 150);
  };
  const ref = useDetectOutsideClick<View>(closeMenu);
  
  Dimensions.addEventListener('change', () => {
    const { width } = Dimensions.get('window');
    setScreen(width);
  });

  const handleMenu = (bool: boolean) => {
    Animated.timing(dropDownMenuHeight, {
      useNativeDriver: false,
      toValue: bool ? 336 : 0,
      duration: bool ? 300 : 200,
      easing: Easing.elastic(1),
    }).start();
  };

  async function handleCompanySettingsPress() {
    setAction(RouteLocations.CompanySettings());

    setDropdown(false);
  }

  const RenderList: React.FC = () => {
    return (
      <>
        <TouchableOpacity ref={useCallOnHover<TouchableOpacity>()}>
          <Text
            onPress={() => {
              showMyAccountModal(true);
              setDropdown(!showDropdown);
            }}
            style={Styles.dropdownItem}
          >
            {t('common:menu.account')}
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          ref={useCallOnHover<TouchableOpacity>()}
          testID={t('common:menu.companySettings')}
          onPress={handleCompanySettingsPress}
        >
          <Text style={Styles.dropdownItem}>{t('common:menu.companySettings')}</Text>
        </TouchableOpacity>

        {isValidPermission(API.Permission.workers_invite) && (
          <TouchableOpacity style={Styles.invitationRow} ref={useCallOnHover<TouchableOpacity>()}>
            <Text
              onPress={() => {
                showUsersModal(true);
                setDropdown(!showDropdown);
              }}
              style={[Styles.dropdownItem]}
            >
              {t('common:menu.invitation')}
            </Text>
            <View style={Styles.usersCount}>
              <Text style={Styles.countStyle}>{usersCount}</Text>
            </View>
          </TouchableOpacity>
        )}

        <TouchableOpacity ref={useCallOnHover<TouchableOpacity>()}>
          <Text
            onPress={() => openLogoutBox(true)}
            style={[Styles.dropdownItem, Styles.logoutText]}
          >
            {t('common:menu.logout')}
          </Text>
        </TouchableOpacity>
      </>
    );
  };

  return (
    <View
      style={[
        Styles.headerContainer,
        window.location.pathname === RoutePaths.CompanySettings && {
          backgroundColor: Colors.White,
        },
      ]}
    >
      <View style={Styles.header}>
        <HeaderTitle />
        <View style={Styles.inputSearchContainer}>
          {filter.filterConfig && <Filter {...filter} />}
        </View>
        <View style={Styles.profileContainer}>
          <View>
            <TouchableOpacity
              ref={useCallOnHover<TouchableOpacity>(Colors.Background)}
              onPress={() => setOpenHelpMenu(!openHelpMenu)}
              style={[
                Styles.helpButton,
                { backgroundColor: openHelpMenu ? Colors.Background : Colors.Transparent },
              ]}
            >
              <IconSVG
                svgComponent={helpIcon}
                size={{ width: Spacings.Standard, height: Spacings.Standard }}
              />
            </TouchableOpacity>
            {openHelpMenu && <HelpMenu closeMenu={closeHelpMenu} />}
          </View>
          {}
          <View style={[Styles.profileNotifContainer, { display: 'none' }]}>
            <TouchableOpacity style={Styles.notifTouchableContainer}>
              <IconSVG
                svgComponent={NotifSVG}
                containerStyle={Styles.profileNotifSVG}
                size={{ width: 24, height: 24 }}
              />
              <View style={Styles.profileNotifBadge}>
                <Text style={Styles.profileNotifBadgeText}>5</Text>
              </View>
            </TouchableOpacity>
          </View>
          {isShowUpdateButton && <UpdateButton onPress={displayRefreshModal} />}
          {isLoading && <Loader />}
          <TouchableOpacity
            style={[Styles.profileDropDownTouchable]}
            onPress={() => setDropdown(!showDropdown)}
          >
            <View style={Styles.profileImageContainer}>
              {renderImage(worker.profilePicture, ImageSizes.Medium, worker.name)}
            </View>
            <Text style={Styles.userFirstNameTextStyle}>
              {capitalizeFirstLetter(API.getSecondPartOfWorkerName(worker.name))}
            </Text>
            <Image
              source={require('shared/assets/triangle-down.png')}
              style={Styles.triangleIcon}
            />
          </TouchableOpacity>
        </View>
      </View>
      {showDropdown && (
        <View style={[Styles.dropdown, { zIndex: dropDownMenuZIndex }]} ref={ref}>
          <RenderList />
        </View>
      )}

      {loader && <Loader style={Styles.loaderStyle} />}
    </View>
  );
};
