import React, { useState, useEffect, useRef } from 'react';
import * as API from 'shared/backend-data';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { KeyboardAvoidingViewWrapper } from 'shared/ui-component/KeyboardAvoidingView';
import { View, Text, TouchableOpacity, Keyboard, TextInput, Linking, Platform } from 'react-native';
import Styles from './Style';
import { IconSVG } from 'shared/ui-component/Icon';
import arrowLeft from 'shared/assets/svg/icon.arrowLeft.mobile-2.svg';
import { InputText } from 'shared/ui-component/Input';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { UserContext } from 'shared/context/UserContext';
import { t } from 'shared/localisation/i18n';
import { YellowButton } from 'shared/ui-component/Button';
import { ModalUtils } from 'shared/ui-component/Modal';
import { Colors } from 'shared/styles';
import * as _ from 'lodash-es';
import { InputsValue, InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';

interface Props {
  handleSignedInNavigation: () => void;
}

enum InputIds {
  UserCompanyName = 'UserCompanyName',
  UserCompanyDomain = 'UserCompanyDomain',
}

export const RegisterCompanyLayout: React.FC<Props> = props => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>();
  const [isDomainRegistration, setIsDomainRegistration] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [error, setError] = useState<string>();
  const inputRef = useRef<TextInput>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const modal = ModalUtils.useModal();

  const { errors, enableFormSubmit, onBlur, onChange, onSubmit } = useCustomForm<string>(
    {
      [InputIds.UserCompanyName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
    },
    onSubmitForm,
  );

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', () => setIsKeyboardOpen(true));
    Keyboard.addListener('keyboardDidHide', () => setIsKeyboardOpen(false));
  });

  useEffect(() => {
    const user = UserContext.getUser();
    setName(API.isFailure(user) ? '' : user.userAttributes.name);
  }, []);

  function externalDomainWarningMessage() {
    const demoLink = t('alex:app.requestDemoUrl');

    return (
      <Text style={Styles.warningText}>
        {Platform.OS === 'ios' ? (
          t('common:auth.registerEnterprise.externalDomainWarningIOS')
        ) : (
          <>
            {t('common:auth.registerEnterprise.externalDomianWarning.0')}
            <Text style={{ color: Colors.Yellow }} onPress={() => Linking.openURL(demoLink)}>
              {`${' ' + t('common:auth.registerEnterprise.externalDomianWarning.1')}`}
            </Text>
          </>
        )}
      </Text>
    );
  }

  async function saveEnterprise(tenantInput: API.TenantCreateInput): Promise<void> {
    if (loading) return; 
    setLoading(true);

    
    const tenant = await API.createFactoryBusinessObject(API.DataType.TENANT, tenantInput);
    if (API.isFailure(tenant)) {
      logger.error('Failed to create tenant', tenant);
      if (isMounted.current) setAPIError();
      return;
    }

    logger.debug('Tenant created', tenant);

    const tenantApp = await createTenantApp(tenant);
    if (API.isFailure(tenantApp)) {
      logger.error('Failed to createApp', tenantApp);
      if (API.isFailureType(tenantApp, 'ActionNeedsContact')) {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: externalDomainWarningMessage(),
          }),
        );
        setLoading(false);
      } else if (isMounted.current) setAPIError();

      return;
    }

    const refreshed = await UserContext.refreshSession();
    if (refreshed) {
      props.handleSignedInNavigation();
    } else {
      setLoading(false);
    }
  }

  function setAPIError() {
    setLoading(false);
    setError(t('common:error.retry'));
  }

  async function createTenantApp(
    tenant: API.Factory<API.DataType.TENANT>,
  ): Promise<API.Result<API.TenantApp>> {
    const app = await API.getFactory(
      API.DataType.APP,
      API.DataType.APP + API.SeparatorDataType + API.AppId.SKILLOP,
    );
    if (API.isFailure(app)) return app;

    
    
    const _tenantApp = await API.registerTenantApp(tenant, app);
    if (API.isFailure(_tenantApp)) return _tenantApp;

    return _tenantApp;
  }

  function onSubmitForm(values: InputsValue<string>) {
    saveEnterprise({
      name: values[InputIds.UserCompanyName],
      domain: '',
      authenticationTypes: [],
      workstationTargetsStartingAtLevel: API.workstationWorkerLevels2api(
        API.WorkstationWorkerLevels.LEVEL3,
      ),
      apps: [
        {
          id: API.AppId.SKILLOP,
          features: [
            { feature: API.AppFeature.SKILLOP_SKILL },
            { feature: API.AppFeature.SKILLOP_TRAINING },
          ],
        },
      ],
    });
  }

  const handleBackPress = () => {
    if (isDomainRegistration) {
      setIsDomainRegistration(false);
    }
  };

  return (
    <KeyboardAvoidingViewWrapper style={Styles.appContainer}>
      <View style={Styles.rootContainer}>
        <View style={Styles.topContainer}>
          {isDomainRegistration && (
            <TouchableOpacity style={Styles.backButton} onPress={handleBackPress}>
              <IconSVG svgComponent={arrowLeft} />
            </TouchableOpacity>
          )}
          <View style={Styles.headerTextContainer}>
            <Text style={Styles.headerText}>
              {isDomainRegistration
                ? t('common:auth.registerEnterprise.header2')
                : t('common:auth.registerEnterprise.header')}
            </Text>
          </View>
        </View>
        {!isKeyboardOpen && (
          <Text style={Styles.bodyText}>
            <Text style={Styles.boldBodytext}>
              {isDomainRegistration
                ? t('common:auth.registerEnterprise.greeting2')
                : t('common:auth.registerEnterprise.greeting')}{' '}
            </Text>
            {isDomainRegistration
              ? t('common:auth.registerEnterprise.instruction2')
              : t('common:auth.registerEnterprise.greeting1', {
                  name: name,
                  app: t('alex:app.name'),
                }) +
                '. ' +
                t('common:auth.registerEnterprise.instruction1')}
          </Text>
        )}
        <View style={Styles.inputContainer}>
          <View style={{ width: isDomainRegistration ? '75%' : '100%' }}>
            <InputText
              autoFocus
              inputRef={inputRef}
              inputId={isDomainRegistration ? InputIds.UserCompanyDomain : InputIds.UserCompanyName}
              placeHolder={
                isDomainRegistration
                  ? t('common:auth.registerEnterprise.instruction3')
                  : t('common:auth.registerEnterprise.entrepriseName')
              }
              onTextChange={onChange}
              onSubmitEditing={onSubmit}
              onBlur={onBlur}
              errorMessage={errors[InputIds.UserCompanyName]?.inputErrorMessage}
            />
          </View>
          {isDomainRegistration && <Text style={Styles.urlText}>-alex.com</Text>}
        </View>

        <Text style={Styles.errorMessage}>{error ?? ''}</Text>
        <View style={Styles.bottomContainer}>
          <YellowButton
            style={enableFormSubmit ? Styles.connectButton : Styles.connectButtonDisabled}
            onPress={onSubmit}
            disabled={!enableFormSubmit}
            text={isDomainRegistration ? t('common:button.create') : t('common:button.save')}
            textStyle={Styles.connectText}
          />
        </View>
        {loading && <Loader />}
      </View>
    </KeyboardAvoidingViewWrapper>
  );
};
