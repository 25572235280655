import React from 'react';
import { View, ScrollView } from 'react-native';
import styles from './styles';
import { CompanyDetails } from './CompanyDetails';
import { RoleAndPermissions } from './RoleAndPermissions';
import { Glossary } from './Glossary';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { YellowButton } from 'shared/ui-component/Button';
import { t } from 'shared/localisation/i18n';
import { DropDownWithWorkerLevelOption } from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';

interface Props {
  data: {
    companyName: string;
    loading: boolean;
    isFormUpdated: boolean;
    mentorLevelOptions: DropDownOption[];
    workstationTargetsStartingAtLevelOptions: DropDownOption[];
    isAdmin: boolean;
    defaultLanguage?: DropDownOption;
    mentorMinLevel?: DropDownOption;
    workstationTargetsStartingAtLevel?: DropDownOption;
    nameDisplayOptions?: DropDownOption[];
    selectedNameDisplayOption?: DropDownOption;
    isDisableLostLevelAlert: boolean | null;
    levelIcon?: DropDownWithWorkerLevelOption;
    levelIcons: DropDownWithWorkerLevelOption[];
  };
  functions: {
    handleInput: (inputName: string, value: string) => void;
    handleInputDropdown: (inputName: string, value: DropDownOption) => void;
    handleInputDropdownWithLevel: (
      inputName: string,
      value?: DropDownWithWorkerLevelOption,
    ) => void;
    setLoading: (value: boolean) => void;
    handleSubmit: () => void;
    handleGlossaries: (glossaries: string) => void;
  };
}

export const CompanySettingsComponent: React.FC<Props> = props => {
  const { functions, data } = props;

  return (
    <>
      <ScrollView style={styles.rootContainer} contentContainerStyle={styles.rootContainerContent}>
        <CompanyDetails
          functions={{
            ...functions,
          }}
          data={{ ...data }}
        />
        <View style={styles.sectionSeparator} />
        <RoleAndPermissions
          setLoading={functions.setLoading}
          nameDisplayOptions={data.nameDisplayOptions}
          selectedNameDisplayOption={data.selectedNameDisplayOption}
          handleInputDropdown={functions.handleInputDropdown}
          isAdmin={data.isAdmin}
        />
        <View style={styles.sectionSeparator} />
        <Glossary
          defaultLanguage={data.defaultLanguage}
          setLoading={functions.setLoading}
          handlesubmit={functions.handleGlossaries}
          isAdmin={data.isAdmin}
        />
        {data.loading && <Loader />}
      </ScrollView>
      <YellowButton
        text={t('common:button.save')}
        style={styles.buttonSave}
        textStyle={styles.buttonText}
        onPress={functions.handleSubmit}
        disabled={!data.isFormUpdated || !data.companyName || !data.isAdmin}
      />
    </>
  );
};
