import React, { useState, useEffect, useContext } from 'react';
import { View, Text, StyleProp, ViewStyle, TextStyle, InteractionManager } from 'react-native';
import { TouchableOpacity } from 'react-native-web';
import * as SharedStyles from 'shared/styles';
import Styles from './Styles';
import { RouteLocations, GlobalLocationState } from '../../navigation/Routes';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { i18n, t } from 'shared/localisation/i18n';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors, Spacings } from 'shared/styles';
import { ShadowOnHoverButton } from 'shared/ui-component/Button/ShadowOnHoverButton';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import Aigle from 'aigle';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { useHistory } from 'react-router-dom';
import { WorkstationTreePanel } from './workstation-tree-panel/WorkstationTreePanel';
import { Route } from '../../navigation/RouteKey';
import { ModifyTrainingModal } from '../../training/training-modal/container/TrainingModal';
import { AddEditWorkerModalContainer } from '../workers/component/modify-worker-modal/container';
import ReactDOM from 'react-dom';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { ModifySkillModal } from '../../my-factory/skills/components/modify-skill-modal/component';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { ImportExportFailureMessageKey } from 'shared/util/ExcelUtils';
import { IconSVG } from 'shared/ui-component/Icon/IconSVG';
import { debounce } from 'lodash-es';
import { MyFactoryContext } from '../MyFactoryContext';
import { TrainingRow } from '../trainings/TrainingsLibrary';
import { MyHub } from 'shared/util/MyHub';

interface MenuFactoryConfig extends Route {
  subMenus: React.ReactElement[];
}

interface EachMenuProps {
  menu: MenuFactoryConfig;
  isClickable: boolean;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

const OrgUnitIcon = require('shared/assets/svg/icon.unit.svg').default;
const SkillSVG = require('shared/assets/svg/icon.skill.svg').default;
const TrainingIcon = require('shared/assets/svg/icon.training.svg').default;
const PracticalTrainingIcon = require('shared/assets/svg/icon.practTrain.svg').default;
const LectureTrainingIcon = require('shared/assets/svg/icon.lecture.svg').default;
const WorkerIcon = require('shared/assets/svg/icon.worker.svg').default;

export const MenuFactory = () => {
  const history = useHistory<GlobalLocationState>();
  const refView = React.useRef<View>(null);
  const { setHideMenuFactory, hideMenuFactory } = useContext(GlobalDataContext);
  const {
    panelWidth: [panelWidth, setPanelWidth],
    treeNode: [treeNode, setTreeNode],
  } = useContext(MenuFactoryContext);
  const {
    disableFilter: [, setDisableFilter],
  } = useContext(HeaderFilterContext);
  const { trainingTableData, workerTableData } = useContext(MyFactoryContext);

  const { isValidPermission } = useContext(PermissionManagementContext);
  const [currentRoute, setCurrentRoute] = useState<RoutePaths>();
  const [practicalTrainingCount, setPracticalTrainingCount] = useState<number>(0);
  const [nonPracticalTrainingCount, setNonPracticalTrainingCount] = useState<number>(0);
  const [contractSubMenus, setContractSubMenus] = useState<React.ReactElement[]>([]);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [showWorkerModal, setShowWorkerModal] = useState(false);
  const [skills, setSkills] = useState<API.Skill[]>([]);
  const [orgUnits, setOrgUnits] = useState<API.OrganizationalUnit[]>([]);
  const [trainings, setTrainings] = useState<API.Training[]>([]);
  const [workers, setWorkers] = useState<API.Worker[]>([]);
  const [workstations, setWorkstations] = useState<API.Workstation[]>([]);

  useEffect(() => {
    if (!hideMenuFactory) {
      setDisableFilter(false);
    }
  }, [hideMenuFactory]);

  useEffect(() => {
    setPracticalTrainingAndNonPracticalLectureCount(
      trainingTableData.filteredRows.length
        ? trainingTableData.filteredRows
        : trainingTableData.rows,
    );
  }, [trainingTableData]);

  useEffect(() => {
    debounce_setContractDetails(workerTableData.filteredAndSortedRows);
  }, [workerTableData.filteredAndSortedRows]);

  const debounce_setContractDetails = debounce(setContractDetails, 3000);

  useEffect(() => {
    setCurrentRoute(history.location.pathname as RoutePaths);

    switch (history.location.pathname) {
      case RoutePaths.Workstations:
      case RoutePaths.Skills:
      case RoutePaths.Trainings:
      case RoutePaths.Workers:
        setHideMenuFactory(false);
        break;
      default:
        setHideMenuFactory(true);
        break;
    }
  }, [history.location.pathname]);

  useEffect(() => {
    return () => {
      if (treeNode?.id && !treeNode?.id.includes(history.location.pathname.split('/')[3]))
        setWorkstationTreeNode();
    };
  }, [history.location.pathname, treeNode, history.location.state]);

  useEffect(() => {
    return () => {
      if (refView.current) {
        const panel = ReactDOM.findDOMNode(refView.current) as HTMLElement;
        setPanelWidth(panel.getBoundingClientRect().width);
      }
    };
  });

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async payload => {
      switch (true) {
        case payload.data.factory.dataType === API.DataType.WORKER:
          await fetchWorkers();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.TRAINING:
          await fetchTrainings();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.ORGUNIT:
          fetchOrgUnits();
          break;

        case payload.data.factory.dataType === API.DataType.SKILL:
          await fetchSkills();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.WORKSTATION:
          fetchWorkstations();
          break;
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      fetchWorkstations();

      fetchOrgUnits();

      await fetchWorkers();
      if (!isMounted.current) return;

      await fetchTrainings();
      if (!isMounted.current) return;

      await fetchSkills();
      if (!isMounted.current) return;
    });

    return () => {
      removeListener();
    };
  }, []);

  function fetchWorkstations() {
    const __workstations = API.getWorkstations();
    if (API.isFailure(__workstations)) return __workstations;
    setWorkstations(__workstations);
  }
  async function fetchWorkers() {
    const __workers = await API.getWorkers();
    if (API.isFailure(__workers)) return __workers;
    setWorkers(__workers.result);
  }
  async function fetchTrainings() {
    const __trainings = await API.getTrainings();
    if (API.isFailure(__trainings)) return __trainings;
    setTrainings(__trainings.result);
  }
  function fetchOrgUnits() {
    const __orgUnits = API.getOrganizationalUnits();
    if (API.isFailure(__orgUnits)) return __orgUnits;
    setOrgUnits(__orgUnits);
  }
  async function fetchSkills() {
    const __skills = await API.getSkills();
    if (API.isFailure(__skills)) return __skills;
    setSkills(__skills.result);
  }

  function setWorkstationTreeNode() {
    if (!treeNode) {
      const topTreeNodes = API.Tree.getTopTreeNodes();
      if (!topTreeNodes.length)
        return API.createFailure_Unspecified(
          i18n.t(ImportExportFailureMessageKey.TopOrgUnitInaccessible),
        );

      setTreeNode(topTreeNodes[0]);
    } else if (treeNode && API.isTreeNode(API.DataType.WORKSTATION, treeNode)) {
      const parentTreeNode = API.Tree.getTreeNode(treeNode.object.parentId);
      if (API.isFailure(parentTreeNode)) return parentTreeNode;
      setTreeNode(parentTreeNode);
    }
  }

  async function setContractDetails(workers: API.Worker[]): Promise<void> {
    const contractTypeNameWithCounts = await API.getWorkersContractTypeNamewithCounts(workers);
    if (!isMounted.current) return;

    const contractSubMenus: React.ReactElement[] = contractTypeNameWithCounts.map((item, index) => (
      <SubMenu
        key={index}
        title={item.contractTypeName + ' (' + item.workerCountPercentage + '%)'}
        keyNumber={item.workerCount}
      />
    ));

    setContractSubMenus(contractSubMenus);
  }

  const handleRouteChange = (routePath: RoutePaths): void => {
    switch (routePath) {
      case RoutePaths.Workstations:
        history.push(RouteLocations.Workstations());
        break;
      case RoutePaths.Skills:
        history.push(RouteLocations.Skills());
        break;
      case RoutePaths.TrainingSessions:
        history.push(RouteLocations.TrainingSessions());
        break;
      case RoutePaths.Trainings:
        history.push(RouteLocations.Trainings());
        break;
      case RoutePaths.Workers:
        history.push(RouteLocations.Workers());
        break;

      default:
        break;
    }
    setCurrentRoute(routePath);
  };

  async function setPracticalTrainingAndNonPracticalLectureCount(
    trainingRows: TrainingRow[],
  ): Promise<void> {
    let practicalTrainingCount = 0;
    let nonPracticalTrainingCount = 0;
    await Aigle.map(trainingRows, async row => {
      if (row.isPractical === undefined) {
        const _isPractical = await API.isPracticalTraining(row.training.id);
        if (!isMounted.current) return;
        if (API.isFailure(_isPractical)) {
          logger.warn(_isPractical);
          return;
        }
        row.isPractical = _isPractical;
      }

      if (row.isPractical) {
        practicalTrainingCount++;
      } else {
        nonPracticalTrainingCount++;
      }
    });
    if (!isMounted.current) return;
    setPracticalTrainingCount(practicalTrainingCount);
    setNonPracticalTrainingCount(nonPracticalTrainingCount);
  }

  const SubMenu: React.FC<{
    title: string;
    keyNumber: number | string;
    icon?: any;
    handleSelect?: undefined | (() => void);
  }> = props => {
    return (
      <View style={[Styles.eachMenu, Styles.subMenuContainer]}>
        <TouchableOpacity
          onPress={() => {
            props.handleSelect && props.handleSelect();
          }}
          activeOpacity={props.handleSelect ? 0 : 1}
          style={[Styles.clickableMenu, !props.handleSelect && { cursor: 'default' }]}
        >
          {props.icon && (
            <IconSVG containerStyle={Styles.iconContainer} svgComponent={props.icon} />
          )}
          <Text style={[Styles.label, Styles.subMenuLabel]}>{props.title}</Text>
          <View style={Styles.addIconContainer}>
            <View
              style={[
                SharedStyles.Styles.bubbleCountContainer,
                !props.handleSelect && { backgroundColor: Colors.MediumGray },
              ]}
            >
              <Text style={SharedStyles.Styles.bubbleCount}>{props.keyNumber}</Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const menuFacory: MenuFactoryConfig[] = [
    {
      routeKeyPath: RoutePaths.Workstations,
      routeKeyTitle: t('alex:header.navigation.3'),
      routeKeyIcon: OrgUnitIcon,
      routeKeyNumber: workstations.length + orgUnits.length,
      handleSelect: handleRouteChange,
      subMenus: [],
    },
    {
      routeKeyPath: RoutePaths.Skills,
      routeKeyTitle: t('glossary:skill_plural'),
      routeKeyIcon: SkillSVG,
      routeKeyNumber: skills.length,
      handleSelect: handleRouteChange,
      subMenus: [<SubMenu key={0} title={t('alex:menuFactory.total')} keyNumber={skills.length} />],
    },
    {
      routeKeyPath: RoutePaths.Trainings,
      routeKeyTitle: t('glossary:training_plural'),
      routeKeyIcon: TrainingIcon,
      routeKeyNumber: trainings.length,
      handleSelect: handleRouteChange,
      subMenus: [
        <SubMenu
          key={0}
          title={t('glossary:trainingNotPractical_plural')}
          keyNumber={nonPracticalTrainingCount}
          icon={LectureTrainingIcon}
        />,
        <SubMenu
          key={1}
          title={t('glossary:trainingPractical_plural')}
          keyNumber={practicalTrainingCount}
          icon={PracticalTrainingIcon}
        />,
      ],
    },
    {
      routeKeyPath: RoutePaths.Workers,
      routeKeyTitle: t('glossary:worker_plural'),
      routeKeyIcon: WorkerIcon,
      subMenus: contractSubMenus,
      routeKeyNumber: workers.length,
      handleSelect: handleRouteChange,
    },
  ];

  const isMounted = useIsMounted();

  function handlePlusButton() {
    switch (history.location.pathname) {
      case RoutePaths.Skills:
        setShowSkillModal(true);
        break;
      case RoutePaths.Trainings:
        setShowTrainingModal(true);
        break;
      case RoutePaths.Workers:
        setShowWorkerModal(true);
    }
  }

  const EachMenu: React.FC<EachMenuProps> = props => {
    const { menu, style, textStyle, isClickable = true } = props;

    const [isHover, setIsHover] = useState<boolean>(false);
    const ref = useCallOnHover(
      Colors.Transparent,
      () => setIsHover(true),
      () => setIsHover(false),
    );

    if (currentRoute === RoutePaths.Workstations && menu.routeKeyPath === RoutePaths.Workstations)
      return null;

    function showPlusButton(routePath: RoutePaths): boolean {
      if (routePath === RoutePaths.Workers) {
        return isValidPermission(API.Permission.workers_edit);
      } else if (routePath === RoutePaths.Trainings) {
        return isValidPermission(API.Permission.trainings_edit);
      } else if (routePath === RoutePaths.Skills) {
        return isValidPermission(API.Permission.skills_edit);
      }
      return false;
    }

    return (
      <View style={[Styles.eachMenu, style]}>
        <TouchableOpacity
          ref={ref}
          onPress={() => {
            isClickable && menu.handleSelect(menu.routeKeyPath);
          }}
          activeOpacity={isClickable ? 0 : 1}
          style={[Styles.clickableMenu, !isClickable && { cursor: 'default' }]}
        >
          {menu.routeKeyIcon && (
            <IconSVG containerStyle={Styles.iconContainer} svgComponent={menu.routeKeyIcon} />
          )}
          <Text
            style={[
              Styles.label,
              (isHover || currentRoute?.includes(menu.routeKeyPath)) && {
                color: Colors.Black,
              },
              textStyle,
            ]}
          >
            {menu.routeKeyTitle}
          </Text>
          <View style={Styles.addIconContainer}>
            {currentRoute === menu.routeKeyPath ? (
              showPlusButton(menu.routeKeyPath) && (
                <ShadowOnHoverButton
                  size={Spacings.Standard}
                  iconSize={Spacings.xMedium}
                  iconContainerStyle={{ backgroundColor: Colors.Yellow }}
                  onPress={handlePlusButton}
                />
              )
            ) : (
              <View
                style={[
                  SharedStyles.Styles.bubbleCountContainer,
                  (isHover || !isClickable) && { backgroundColor: Colors.MediumGray },
                ]}
              >
                <Text style={SharedStyles.Styles.bubbleCount}>{menu.routeKeyNumber}</Text>
              </View>
            )}
          </View>
        </TouchableOpacity>

        {currentRoute === menu.routeKeyPath && menu.subMenus}
      </View>
    );
  };

  function displayRoutes(): JSX.Element[] {
    return menuFacory.map((eachMenu, index) => (
      <EachMenu menu={eachMenu} isClickable key={eachMenu.routeKeyTitle + index} />
    ));
  }

  function handleResize(event: MouseEvent): void {
    if (refView.current && event.buttons) {
      const panel = ReactDOM.findDOMNode(refView.current) as HTMLElement;
      const { width } = panel.getBoundingClientRect();
      panel.style.width = width + event.movementX + 'px';
    }
  }

  return (
    <div>
      {!hideMenuFactory && (
        <View
          style={[
            SharedStyles.Styles.bannerStyle,
            SharedStyles.Styles.cardMain,
            Styles.rootContainer,
            { width: panelWidth },
          ]}
          ref={refView}
        >
          <View
            style={[
              Styles.workstationTreeContainer,
              currentRoute !== RoutePaths.Workstations && { display: 'none' },
            ]}
          >
            <WorkstationTreePanel />
          </View>

          <View style={Styles.routeContainer}>{displayRoutes()}</View>
          {showTrainingModal && (
            <ModifyTrainingModal
              config={{
                editMode: false,
                trainingVersion: null,
                skillIds: [],
              }}
              handleModalClose={() => {
                setShowTrainingModal(false);
              }}
              handleRefresh={() => null}
            />
          )}
          {showWorkerModal && (
            <AddEditWorkerModalContainer showHideModal={() => setShowWorkerModal(false)} />
          )}
          {showSkillModal && <ModifySkillModal handleModalClose={() => setShowSkillModal(false)} />}
          <TouchableOpacity
            onPressIn={() => {
              window.addEventListener('mousemove', handleResize);
            }}
            onPressOut={() => {
              window.removeEventListener('mousemove', handleResize);
            }}
            style={Styles.dragableSideStyle}
          />
        </View>
      )}
    </div>
  );
};
