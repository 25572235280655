import React, { useEffect, useState, useRef } from 'react';
import { View, InteractionManager, Animated, ScrollView } from 'react-native';
import WebModal from 'modal-react-native-web';
import { Loader } from 'shared/ui-component/Loader/Loader';
import * as API from 'shared/backend-data';
import { t } from 'shared/localisation/i18n';
import Styles from './Style';
import { SkillDropDownOption, WorkerDropDownOption } from '../container';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import logger from 'shared/util/Logger';
import { ModalUtils } from 'shared/ui-component/Modal';
import { SkillModalHeader } from 'shared/ui-component/Modal/SkillModalHeader';
import { LeftPanel } from './LeftPanel';
import { RightPanel } from './RightPanel';
import { ModalBackgroundStyle, ModalCardStyle } from 'shared/styles/ModalStyles';
import Aigle from 'aigle';
import { convertDataURLtoFile } from 'shared/util-ts/Functions';



interface Props {
  skillOptions: SkillDropDownOption[] | undefined;
  workerOptions: WorkerDropDownOption[] | undefined;
  selectedSkills?: API.Skill[] | undefined;
  selectedWorkers?: API.Worker[] | undefined;
  arrowBack?: boolean;
  revokeProof?: boolean;
  renewProof?: boolean;
  submitProofAgain?: boolean;
  modalVisible: boolean;
  comment?: string;
  skillObtentionDate: Date;

  setComment: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSkillObtentionDate: React.Dispatch<React.SetStateAction<Date>>;
  setSelectedSkills: React.Dispatch<React.SetStateAction<API.Skill[] | undefined>>;
  setSelectedWorkers: React.Dispatch<React.SetStateAction<API.Worker[] | undefined>>;
  navigateToSkillBook: () => void;
  refreshSkills?: () => Promise<void>;
  showToastMessage?: (text: string, callback: Function) => void;
  renderCustomHeader?: () => JSX.Element;
  handleModalClose: () => void;
}

export const WorkerSkillAddModalComponent: React.FC<Props> = props => {
  const modal = ModalUtils.useModal();

  const isMounted = useIsMounted();

  const {
    arrowBack,
    selectedWorkers,
    selectedSkills,
    modalVisible,
    comment,
    skillObtentionDate,

    setComment,
    setSkillObtentionDate,
    handleModalClose,
    navigateToSkillBook,
    refreshSkills,
    showToastMessage,
    setSelectedSkills,
    setSelectedWorkers,
  } = props;
  const [showLoader, setShowLoader] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
  const [scannedDocs, setScannedDocs] = useState<(string | ArrayBuffer | null | undefined)[]>([]);
  const [attestAcquiredSkill, setAttestAcquiredSkill] = useState(false);
  const [validData, setValidData] = useState(false);
  const [proofAdded, setProofAdded] = useState(false);
  const [reviewer, setReviewer] = useState<API.Worker>();
  const [showFullScreenProof, setShowFullScreenProof] = useState(false);
  const [isAPIError, setIsAPIError] = useState<boolean>(false);
  const [isSkillsOrWorkersCountGreaterThanOne, setIsSkillsOrWorkersCountGreaterThanOne] =
    useState<boolean>(false);
  const [isSkillAcquired, setIsSkillAcquired] = useState<number>(-1);

  const animationRef = useRef(new Animated.Value(0));

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      const worker = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(worker)) return;
      setReviewer(worker);
    });
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setValidData(isDataValidToBeSubmitted());
      setProofAdded(Boolean(attestAcquiredSkill || selectedDocs.length !== 0));
    }
  }, [
    selectedDocs,
    selectedWorkers,
    selectedSkills,
    attestAcquiredSkill,
    skillObtentionDate,
    isSkillAcquired,
  ]);

  useEffect(() => {
    if (selectedSkills?.length) getSkillCount(selectedSkills);
  }, [selectedSkills]);

  useEffect(() => {
    Animated.timing(animationRef.current, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: false,
    }).start();
  }, [animationRef.current]);

  async function getSkillCount(selectedSkills: API.Skill[]) {
    const _skillIdsAndSkillGroupIds = await API.getSkillsAndSubSkillsIds(selectedSkills);
    if (API.isFailure(_skillIdsAndSkillGroupIds)) return _skillIdsAndSkillGroupIds;

    const skillsCount: number =
      _skillIdsAndSkillGroupIds.skillGroupIds.size + _skillIdsAndSkillGroupIds.skillIds.size;

    listenDropdownChange(skillsCount);
  }

  const isDataValidToBeSubmitted = (): boolean => {
    if (
      selectedWorkers &&
      selectedSkills &&
      ((selectedDocs && selectedDocs.length) || attestAcquiredSkill) &&
      isSkillAcquired !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCreateAutoCertificate = async (
    reviewer: API.Worker,
    workerName: string,
    skillName: string,
  ): Promise<File | undefined> => {
    selectedDocs.pop();
    const proofFile = await API.createCertificateProof(
      [workerName],
      [skillName],
      reviewer.name,
      false,
      props.revokeProof ? props.revokeProof : false,
    );
    if (!isMounted.current) return;

    const file = convertDataURLtoFile(proofFile.fileContentBase64, proofFile.name);
    selectedDocs.push(file);
    setSelectedDocs(selectedDocs);
    return file;
  };

  const handleDisplayPopUpToast = (message: string): void => {
    modal.displayModal(
      ModalUtils.toastConfig({
        text: message,
      }),
    );
  };

  const handleDiscardCheck = (): void => {
    if (showFullScreenProof || isAPIError) return;
    if (validData) {
      if (isMounted.current && !showLoader) {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: t('alex:addWorkerSkillModal.discardCheck'),
            warningAcceptButton: t('common:button.yes'),
            warningAcceptCallback: handleSubmitButton,
            warningCancelButton: t('common:button.no'),
            warningCancelCallback: handleModalClose,
          }),
        );
      }
    } else {
      handleModalClose();
    }
  };

  const showApiError = (errorMessage: string): void => {
    setIsAPIError(true);
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('common:error.unknown'),
        warningSubMessage: errorMessage,
        warningAcceptButton: t('common:button.retry'),
        warningAcceptCallback: handleSubmitButton,
        warningCancelButton: t('common:button.no'),
        warningCancelCallback: () => {
          handleModalClose();
          setIsAPIError(false);
        },
      }),
    );
  };

  const handleSubmitButton = async () => {
    if (!validData) return;

    setIsAPIError(false);
    setShowLoader(true);

    let worker = reviewer;
    if (!worker) {
      const _worker = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(_worker)) return;
      worker = _worker;
    }

    if (!validData || !selectedSkills || !selectedWorkers || !worker) return;

    const S3ObjectDataForUploadedFiles = await _.reduce(
      selectedDocs,
      async (acc, file) => {
        const s3Object = await API.uploadFile(file, API.StorageVisibility.protected);
        if (API.isFailure(s3Object)) {
          logger.warn('Failed to upload file', s3Object);
          if (isMounted.current) showApiError(s3Object.message);
        } else {
          (await acc).push(s3Object);
        }
        return acc;
      },
      Promise.resolve([] as API.S3Object[]),
    );
    if (!isMounted.current) return;

    await Aigle.map(selectedWorkers, async selectedWorker => {
      await Aigle.map(selectedSkills, async selectedSkill => {
        if (attestAcquiredSkill) {
          const proof = await handleCreateAutoCertificate(
            worker!,
            selectedWorker.name,
            selectedSkill.name,
          );
          if (!isMounted.current) return;

          if (proof) {
            const S3ObjectData = await API.uploadFile(proof, API.StorageVisibility.protected);
            if (!isMounted.current) return;
            if (API.isFailure(S3ObjectData)) {
              logger.warn('Failed to upload file', S3ObjectData);
              showApiError(S3ObjectData.message);
              return;
            }

            const proofBundle = await API.createWorkerSkillProofBundle(
              {
                startingDate: skillObtentionDate.toISOString(),
                files: [S3ObjectData],
                review: {
                  state: props.revokeProof ? API.ReviewState.REJECTED : API.ReviewState.TO_REVIEW,
                  date: new Date().toISOString(),
                  workerId: worker!.id,
                },
                description: comment,
                acquired: Boolean(!isSkillAcquired),
              },
              selectedWorker.id,
              selectedSkill.id,
            );
            if (!isMounted.current) return;

            if (API.isFailure(proofBundle)) {
              setShowLoader(false);
              logger.warn('Failed to create createdProofBundle', proofBundle);
              if (API.isFailureType(proofBundle, 'ProofBundleNotReviewed')) {
                showApiError(t('alex:mobile.addProofPage.proofAddedAlreadyWarning.3'));
                return;
              }
              showApiError('' + proofBundle.message);
              return;
            }
          }
        } else {
          if (S3ObjectDataForUploadedFiles.length === selectedDocs.length) {
            const proofBundle = await API.createWorkerSkillProofBundle(
              {
                startingDate: skillObtentionDate.toISOString(),
                files: S3ObjectDataForUploadedFiles,
                review: {
                  state: props.revokeProof ? API.ReviewState.REJECTED : API.ReviewState.TO_REVIEW,
                  date: new Date().toISOString(),
                  workerId: worker!.id,
                },
                description: comment,
                acquired: Boolean(!isSkillAcquired),
              },
              selectedWorker.id,
              selectedSkill.id,
            );
            if (!isMounted.current) return;

            if (API.isFailure(proofBundle)) {
              setShowLoader(false);
              logger.warn('Failed to create createdProofBundle', proofBundle);
              if (API.isFailureType(proofBundle, 'ProofBundleNotReviewed')) {
                showApiError(t('alex:mobile.addProofPage.proofAddedAlreadyWarning.3'));
                return;
              }
              showApiError('' + proofBundle.message);
              return;
            }
          }
        }
      });
    });

    if (refreshSkills) refreshSkills();
    handleModalClose();
    if (showToastMessage) showToastMessage(t('alex:addWorkerSkillModal.proofAdded'), () => {});
    setShowLoader(false);
  };

  function listenDropdownChange(count?: number) {
    if (!count) {
      setIsSkillsOrWorkersCountGreaterThanOne(false);
      return;
    }

    if (count > 1) setIsSkillsOrWorkersCountGreaterThanOne(true);
    else setIsSkillsOrWorkersCountGreaterThanOne(false);
  }

  return (
    <WebModal
      animationType="fade"
      transparent
      visible={modalVisible}
      onRequestClose={() => handleModalClose()}
    >
      <Animated.View style={[ModalBackgroundStyle, { opacity: animationRef.current }]}>
        <View style={[ModalCardStyle]}>
          {props.renderCustomHeader ? (
            props.renderCustomHeader()
          ) : (
            <SkillModalHeader
              historyProofBundles={[]}
              revokeProof={props.revokeProof}
              handleModalClose={handleDiscardCheck}
              onSelectProof={() => {}}
              renewProof={props.renewProof}
              submitProofAgain={props.submitProofAgain}
            />
          )}
          <ScrollView contentContainerStyle={Styles.scrollViewContentContainer}>
            <View style={Styles.rightAndLeftPanel}>
              <LeftPanel
                scannedDocs={scannedDocs}
                selectedDocs={selectedDocs}
                attestAcquiredSkill={attestAcquiredSkill}
                validData={validData}
                reviewer={reviewer}
                showFullScreenProof={showFullScreenProof}
                arrowBack={arrowBack}
                revokeProof={props.revokeProof}
                handleDiscardCheck={handleDiscardCheck}
                setAttestAcquiredSkill={setAttestAcquiredSkill}
                setScannedDocs={setScannedDocs}
                setSelectedDocs={setSelectedDocs}
                handleDisplayPopUpToast={handleDisplayPopUpToast}
                setShowFullScreenProof={setShowFullScreenProof}
                isSkillsOrWorkersCountGreaterThanOne={isSkillsOrWorkersCountGreaterThanOne}
              />
              <RightPanel
                validData={validData}
                comment={comment}
                skillOptions={props.skillOptions}
                workerOptions={props.workerOptions}
                selectedSkills={selectedSkills}
                selectedWorkers={selectedWorkers}
                preSelectedSkills={props.selectedSkills}
                preSelectedWorkers={props.selectedWorkers}
                skillObtentionDate={skillObtentionDate}
                revokeProof={props.revokeProof}
                setComment={setComment}
                setSkillObtentionDate={setSkillObtentionDate}
                handleSubmitButton={handleSubmitButton}
                setSelectedSkills={setSelectedSkills}
                setSelectedWorkers={setSelectedWorkers}
                proofAdded={proofAdded}
                listenDropdownChange={listenDropdownChange}
                isSkillsOrWorkersCountGreaterThanOne={isSkillsOrWorkersCountGreaterThanOne}
                navigateToSkillBook={navigateToSkillBook}
                setIsSkillAcquired={setIsSkillAcquired}
                isSkillAcquired={isSkillAcquired}
              />
            </View>
          </ScrollView>
        </View>
      </Animated.View>
      {showLoader && <Loader />}
    </WebModal>
  );
};
