import React, { useContext } from 'react';
import { View } from 'react-native';
import { TreeRowMagicSquare, TreeRowVersatility } from '../container';
import * as API from 'shared/backend-data';
import { InputShiftsList } from 'shared/components/InputShiftsList';
import Styles from './Styles';
import { TreeTableContext } from 'sharedweb/src/context/TreeTableContext';

interface Props {
  row: TreeRowMagicSquare | TreeRowVersatility;
  handleSelectShift(shiftId: string, row: TreeRowMagicSquare | TreeRowVersatility): void;
}

export const RenderShiftsForTable: React.FC<Props> = props => {
  const { row, handleSelectShift } = props;
  const { selectedShiftIds } = useContext(TreeTableContext);

  if (!API.isOrganizationalUnit(row.node.object) || !row.node.object.shiftIds?.length)
    return <View />;

  return (
    <View style={Styles.shiftsContainer}>
      <InputShiftsList
        shiftIds={[...(row.node.object.shiftIds ?? [])]}
        selectedShiftIds={selectedShiftIds}
        handleSelection={(shiftId: string) => handleSelectShift(shiftId, row)}
        showHover
      />
    </View>
  );
};
