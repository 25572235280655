import React from 'react';
import { UsersModalComponent } from '../component';
import * as _ from 'lodash-es';

interface Props {
  showUsersModal: (openModal: boolean) => void;
}

export const UsersModal: React.FC<Props> = props => {
  return <UsersModalComponent showUsersModal={props.showUsersModal} />;
};
