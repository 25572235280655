import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors, Spacings } from 'shared/styles';
import { HoverableIcon } from 'shared/ui-component/HoverableIcon';
import { InputText } from 'shared/ui-component/Input';
import { useModal } from '../Modal';
import { Tag } from 'shared/ui-component/Input/InputTag';
import styles from './styles';

interface EditeTagProps {
  tag: Tag;
  editHandler: (tag: Tag) => void;
}

interface DeleteTagProps {
  tag: Tag;
  deleteHandler: (tag: Tag) => void;
}

interface Props {
  editTag?: EditeTagProps;
  deleteTag?: DeleteTagProps;
  label?: string | undefined;
  placeholder?: string | undefined;
  description?: string | undefined;
  createHandler?: (label: string) => void;
  trackModalOpeningState?: (boolean: boolean) => void;
}

const trashIcon = require('shared/assets/svg/icon.trash.svg').default;
const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

export const TagModalComponent: React.FC<Props> = props => {
  const modal = useModal();

  function deleteHandler() {
    
    closeModal();
    if (props.deleteTag) props.deleteTag.deleteHandler(props.deleteTag.tag);
  }

  function closeModal() {
    if (props.trackModalOpeningState) props.trackModalOpeningState(false);
    modal.hideModal();
  }

  function onSaveHandler(label: string) {
    if (props.editTag) props.editTag.editHandler({ ...props.editTag.tag, label: label });
    else if (props.createHandler) props.createHandler(label);

    closeModal();
  }

  return (
    <View style={styles.contentContainer}>
      <View style={styles.innerContainer}>
        <View style={styles.modalHeader}>
          {!props.createHandler && (
            <HoverableIcon
              containerStyle={{
                height: 24,
                width: 24,
              }}
              svgIcon={trashIcon}
              hoverColor={Colors.Black}
              dehoverColor={Colors.Grey}
              onPress={deleteHandler}
            />
          )}
          <TouchableOpacity style={{ marginLeft: Spacings.Medium }} onPress={closeModal}>
            <IconSVG svgComponent={closeIcon} />
          </TouchableOpacity>
        </View>

        <View style={styles.inputTextContainer}>
          <InputText
            autoFocus
            style={styles.inputTextStyle}
            defaultValue={props.editTag ? props.editTag.tag.label : undefined}
            onSaveButtonClicked={onSaveHandler}
            onSubmitEditing={(inputId, inputValue) => {
              onSaveHandler(inputValue);
            }}
            showSaveButton={true}
            label={props.label}
            placeHolder={props.placeholder}
            labelStyle={styles.inputTextLabel}
            notEditable={props.editTag?.tag.editable !== undefined && !props.editTag?.tag.editable}
          />
          {props.description && <Text style={styles.description}>{props.description}</Text>}
        </View>
      </View>
    </View>
  );
};
