import React, { useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';

interface Props {
  roleIds: string[];
}

export const LazyLoadRoles: React.FC<Props> = props => {
  const { roleIds } = props;

  const [lazyLoadedRoles, setLazyLoadedRoles] = useState<API.Role[]>();

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const roles: API.Role[] = [];
      const errors: API.Failure[] = [];
      await Promise.all(
        roleIds.map(async roleId => {
          const role = await API.getRole(roleId);
          if (!isMounted.current) return;
          if (API.isFailure(role)) {
            errors.push(role);
            return;
          }

          roles.push(role);
        }),
      );

      if (errors.length) logger.error(' LazyLoadedRoles Error while fetching roles !');

      setLazyLoadedRoles(roles);
    });
  }, [roleIds]);

  return (
    <>
      {!!lazyLoadedRoles?.length
        ? lazyLoadedRoles.map(role => API.capitalizeFirstLetter(role.name)).join(', ')
        : ' ... '}
    </>
  );
};
