import React, { useState, useEffect, useContext } from 'react';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { TrainingWorkerTableComponent } from '../component';
import { TableRow } from 'shared/ui-component/Table';
import { MyHub } from 'shared/util/MyHub';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import {
  removeInvalidObjectFromUserPreference,
  extractFilterTags,
} from '../../../../../header-layout/headerFilterConfig';
import logger from 'shared/util/Logger';
import Aigle from 'aigle';

export interface TrainingWorkerTableRow extends TableRow, API.WorkerProofBundles {
  orgUnits?: API.OrganizationalUnit[];
  trainingId: string;
  trainingSkillIds: string[];
  trainingSessions?: API.TrainingSession[];
}
interface Props {
  training: API.Training;
  setWorkerCount: (count: number) => void;
}

export const TrainingWorkerTableContainer: React.FC<Props> = props => {
  const { training, setWorkerCount } = props;

  const {
    trainingWorkerScreenFilterTags: [
      trainingWorkerScreenFilterTags,
      setTrainingWorkerScreenFilterTags,
    ],
    currentRoute: [, setCurrentRoute],
    currentTabIndex: [, setCurrentTabIndex],
  } = useContext(HeaderFilterContext);

  const [data, setData] = useState<TrainingWorkerTableRow[]>([]);
  const [filterTags, setFilterTags] = useState<TagExtended[]>([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (training) {
        await fetchTrainingWorkers(training.id);
      }
      setCurrentRoute(RoutePaths.TrainingProfile);
      setCurrentTabIndex(0);

      const trainingResult = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.TrainingWorkerFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(trainingResult)) {
        logger.warn('fetch training filter: error in saving user Preference', trainingResult);
        return;
      }
      if (trainingResult) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.TrainingWorkerFilter,
          trainingResult ?? [],
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.TrainingWorkerFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save training filter: error while saving user Preference', saved);
          return;
        }
        setTrainingWorkerScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.TrainingWorkerFilter) ?? [],
        );
      }

      const removeListener = MyHub.listenBusinessObject(
        'BusinessObjectMutate',
        async ({ data }) => {
          
          if (data.factory.dataType === API.DataType.WORKERSKILL) {
            fetchTrainingWorkers(training.id);
          }
        },
      );

      return () => {
        removeListener();
        setCurrentRoute(undefined);
        setCurrentTabIndex(undefined);
      };
    });
  }, []);

  useEffect(() => {
    setFilterTags(extractFilterTags(trainingWorkerScreenFilterTags));
  }, [trainingWorkerScreenFilterTags]);

  /**
   *
   * 1. Need Add old proofBundles from workerSkill -> ProofBundles to see the history of proofs in proofBook.
   *    this will trigger the network call for fetching the proofBundles and this should be inside the proofBook component.
   *
   */
  async function workersCompletedTrainingWithProofBundles(
    trainingId: string,
  ): Promise<API.Result<API.WorkerProofBundles[]>> {
    const trainingVersion = await API.getTrainingVersionLatestForTraining(trainingId);
    if (API.isFailure(trainingVersion)) return trainingVersion;

    const skillIds = trainingVersion.skillIds;
    const workerSkillProofBundleMap: Map<
      string,
      Map<string, API.NoMetadata<API.ProofBundle>[]>
    > = new Map(); 
    const _workerIds: string[] = [];

    await Aigle.mapSeries(skillIds, async skillId => {
      const workerSkill = await API.getWorkerSkills(undefined, skillId);
      if (API.isFailure(workerSkill)) return workerSkill;

      const skill = await API.getSkill(skillId);
      if (API.isFailure(skill)) return skill;

      await API.mapLimit(workerSkill.result, async workerSkill => {
        if (
          workerSkill.validity &&
          (workerSkill.validity !== API.Validity.KO_MISSING || workerSkill.toReviewProofBundle)
        ) {
          let _proofBundles: API.NoMetadata<API.ProofBundle>[] = [];
          let workerSkills: API.NoMetadata<API.WorkerSkill>[] = [workerSkill];

          if (API.isSkillGroup(skill)) {
            const subSkills = await API.getSubSkillsFromSkillGroup(skill);
            if (API.isFailure(subSkills)) return subSkills;

            const _workerSubSkills = await API.mapLimit(subSkills, async subSkill => {
              const __workerSubSkills = await API.getWorkerSkills(undefined, subSkill.id);
              if (API.isFailure(__workerSubSkills)) return __workerSubSkills;
              return __workerSubSkills.result;
            });
            if (API.isFailure(_workerSubSkills)) return _workerSubSkills;

            workerSkills = _workerSubSkills.flat();
          }

          workerSkills.forEach(workerSkill => {
            const _proof = workerSkill.toReviewProofBundle
              ? workerSkill.toReviewProofBundle
              : workerSkill.activeProofBundle
              ? workerSkill.activeProofBundle
              : undefined;
            if (!_proof) return;

            _proofBundles.push(_proof);
          });

          if (!_proofBundles.length) return;

          const skillWorkerProofBundle = workerSkillProofBundleMap.get(skillId);
          if (skillWorkerProofBundle) {
            const workerProofBundle = skillWorkerProofBundle.get(workerSkill.workerId);
            if (workerProofBundle) {
              workerProofBundle.push(..._proofBundles);
            } else {
              skillWorkerProofBundle.set(workerSkill.workerId, [..._proofBundles]);
              _workerIds.push(workerSkill.workerId);
            }
          } else {
            const workerProofBundle: Map<string, API.NoMetadata<API.ProofBundle>[]> = new Map();
            workerSkillProofBundleMap.set(
              skillId,
              workerProofBundle.set(workerSkill.workerId, _proofBundles),
            );
            _workerIds.push(workerSkill.workerId);
          }
        }
      });
    });

    const _workers: API.Worker[] = [];
    for (const [workerId, eachWorkerGroup] of Object.entries(_.groupBy(_workerIds))) {
      if (eachWorkerGroup.length === skillIds.length) {
        const worker = await API.getWorker(workerId);
        if (API.isFailure(worker)) return worker;

        _workers.push(worker);
      }
    }

    const result: API.WorkerProofBundles[] = [];
    _workers.map(worker => {
      const _workerProofBundle: API.NoMetadata<API.ProofBundle>[] = [];

      skillIds.map(skillId => {
        const proofBundle = workerSkillProofBundleMap.get(skillId)?.get(worker.id);
        if (proofBundle) _workerProofBundle.push(...proofBundle);
      });

      result.push({
        worker: worker,
        
        proofBundles: _workerProofBundle,
        latestProofBundle: API.extractLatestProofBundle(_workerProofBundle),
      });
    });

    return result;
  }

  const fetchTrainingWorkers = async (trainingId: string) => {
    setWorkerCount(0);

    const _workersCompletedTrainingWithProofBundles =
      await workersCompletedTrainingWithProofBundles(trainingId);
    if (!isMounted.current) return;
    if (API.isFailure(_workersCompletedTrainingWithProofBundles))
      return _workersCompletedTrainingWithProofBundles;

    const latestTrainingVersion = await API.getTrainingVersionLatestForTraining(trainingId);
    if (!isMounted.current) return;
    if (API.isFailure(latestTrainingVersion)) {
      logger.warn(latestTrainingVersion);
      return;
    }

    await getTrainingWorkersTableData(
      _workersCompletedTrainingWithProofBundles,
      trainingId,
      latestTrainingVersion,
    );
  };

  async function getTrainingWorkersTableData(
    workersWhoCompletedTheTraining: API.WorkerProofBundles[],
    trainingId: string,
    latestTrainingVersion: API.TrainingVersion,
  ) {
    const result: TrainingWorkerTableRow[] = workersWhoCompletedTheTraining.map(worker => {
      return {
        key: worker.worker.id,
        worker: worker.worker,
        orgUnits: undefined,
        proofBundles: worker.proofBundles,
        latestProofBundle: worker.latestProofBundle,
        trainingId,
        trainingSkillIds: [...latestTrainingVersion.skillIds],
      };
    });
    setData(result);
    setWorkerCount(result.length);
  }

  return (
    <TrainingWorkerTableComponent
      training={training}
      rows={data}
      filterTags={filterTags}
      setWorkerCount={setWorkerCount}
    />
  );
};
