import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, Platform, InteractionManager } from 'react-native';
import { ProgressBar } from 'shared/ui-component/ProgressBar';
import { IconSVG } from 'shared/ui-component/Icon';
import moment from 'moment';
import { t } from 'shared/localisation/i18n';
import { Spacings, Colors } from '../../styles';
import * as SharedStyles from 'shared/styles/index';
import AgendaIcon from 'shared/assets/svg/icon.calendar.mobile.svg';
import ClockIcon from 'shared/assets/svg/icon.clock.mobile.svg';
import * as API from 'shared/backend-data';
import { useIsMounted } from '../../hooks/IsMounted';
import { LoaderThreeDots } from '../../ui-component/Loader/LoaderThreeDots';
import * as _ from 'lodash-es';

const isMobilePlatform = Platform.OS === 'android' || Platform.OS === 'ios';

export interface TrainingProgressProps {
  trainingSessionWithDetails: API.TrainingSessionWithDetail;
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacings.Unit,
  },
  progressBar: { flex: 1 },
  progressBarContainer: {
    flex: 1,
    paddingLeft: Spacings.Unit * 3,
    paddingTop: Spacings.Small,
  },
  firstIcon: {
    marginLeft: -Spacings.Unit,
  },
  text: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: SharedStyles.fontSize.Regular,
    marginRight: Spacings.Unit,
    color: Colors.Black,
  },
  redText: { color: Colors.Red },
  secondIcon: { marginLeft: Spacings.Standard },
  parentRowContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacedBetweenContainer: {
    justifyContent: 'space-between',
  },
  marginRight: { marginRight: Spacings.Unit * 1.5 },
});

/**
 * Possible States, Training Session:
 *
 * 1- has passed the duration and it's not ended yet  
 * 2- is in progress 
 * 3- is scheduled 
 * 4- is requested or not even scheduled 
 * 5- is ended or ended late 
 *
 * This util function could be handful: getTrainingStatusDisplayString()
 */
export const TrainingProgress: React.FC<TrainingProgressProps> = props => {
  const now = new Date();

  const { trainingSessionWithDetails } = props;
  const { trainingVersionId } = trainingSessionWithDetails;

  const isMounted = useIsMounted();

  const [isTrainingPractical, setIsTrainingPractical] = useState<boolean>();
  const [trainingSessionState, setTrainingSessionState] = useState<API.TrainingSessionState>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!trainingSessionWithDetails || !isMounted.current) return;

      await checkIsTrainingPractical();
      if (!isMounted.current) return;

      _getTrainingSessionState();
    });
  }, [trainingSessionWithDetails]);

  let JSXFragment: React.ReactFragment = <></>;

  function _getTrainingSessionState(): void {
    const _trainingSessionState = API.getTrainingSessionState(trainingSessionWithDetails);
    setTrainingSessionState(_trainingSessionState);
  }

  async function checkIsTrainingPractical(): Promise<boolean | undefined> {
    const _isPracticalTraining = await API.isPracticalTrainingVersion(trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(_isPracticalTraining)) return;
    setIsTrainingPractical(_isPracticalTraining);
  }

  if (trainingSessionState === undefined) {
    return (
      <View style={style.container}>
        <LoaderThreeDots lowPerformance />
      </View>
    );
  }

  
  if (trainingSessionState === API.TrainingSessionState.ENDING_LATE) {
    const daysDiff = trainingSessionWithDetails.durationInMin
      ? Math.abs(
          API.getTrainingSessionEstimatedEndDate(trainingSessionWithDetails).diff(
            now.getTime(),
            'days',
          ),
        )
      : 0;

    JSXFragment = (
      <>
        <View style={[style.parentRowContainer]}>
          <View style={style.rowContainer}>
            <IconSVG
              color={Colors.Black}
              svgComponent={ClockIcon}
              containerStyle={style.firstIcon}
            />
            <Text style={[style.text]}>{t('common:time.timeElapsed')}</Text>
          </View>
          <View style={[style.rowContainer, style.progressBarContainer]}>
            <ProgressBar
              percentage={100}
              style={[style.progressBar, isMobilePlatform ? style.marginRight : {}]}
            />
          </View>
        </View>
      </>
    );
  }

  
  else if (
    trainingSessionState === API.TrainingSessionState.STARTED ||
    trainingSessionState === API.TrainingSessionState.STARTED_LATE
  ) {
    const current = moment();
    const start = moment(trainingSessionWithDetails.startDate);
    const end = API.getTrainingSessionEstimatedEndDate(trainingSessionWithDetails);

    const remainingPercentage = (current.diff(start) / end.diff(start)) * 100;

    const remainingDays =
      current.to(end, true) +
      (!isMobilePlatform
        ? ' ' + t('common:time.remaining', { count: current.diff(end, 'days') }, false)
        : '');

    JSXFragment = (
      <>
        <View style={[style.parentRowContainer]}>
          <View style={style.rowContainer}>
            <IconSVG
              color={Colors.Black}
              svgComponent={ClockIcon}
              containerStyle={style.firstIcon}
            />
            <Text style={[style.text]}>{remainingDays}</Text>
          </View>
          <View style={[style.rowContainer, style.progressBarContainer]}>
            <ProgressBar
              percentage={remainingPercentage}
              style={[style.progressBar, isMobilePlatform ? style.marginRight : {}]}
            />
          </View>
        </View>
      </>
    );
  }
  
  else if (
    trainingSessionState === API.TrainingSessionState.SCHEDULED ||
    trainingSessionState === API.TrainingSessionState.STARTING_LATE ||
    trainingSessionState === API.TrainingSessionState.REQUEST ||
    trainingSessionState === API.TrainingSessionState.DRAFT ||
    trainingSessionState === API.TrainingSessionState.REQUEST_VALIDATED
  ) {
    const start = trainingSessionWithDetails.scheduledStartDate
      ? moment(trainingSessionWithDetails.scheduledStartDate)
      : undefined;

    const _color: string = start && start < moment() ? Colors.Red : Colors.Black;
    const time = start?.format('hh:mm A');

    JSXFragment = (
      <>
        <View
          style={[style.parentRowContainer, !isMobilePlatform ? style.spacedBetweenContainer : {}]}
        >
          <View style={style.rowContainer}>
            <IconSVG
              svgComponent={AgendaIcon}
              containerStyle={[style.firstIcon]}
              color={start ? _color : Colors.Black}
            />
            <Text style={[style.text, { color: _color }]}>
              {start ? (
                t('common:time.scheduledOn', {
                  date: start.format('L'),
                })
              ) : (
                <Text style={style.text}>
                  {_.isUndefined(isTrainingPractical) ? (
                    <LoaderThreeDots />
                  ) : isTrainingPractical ? (
                    t('glossary:trainingPractical')
                  ) : (
                    t('glossary:trainingNotPractical')
                  )}
                </Text>
              )}
            </Text>
          </View>
          {time && (
            <View style={style.rowContainer}>
              <IconSVG
                color={_color}
                svgComponent={ClockIcon}
                containerStyle={[isMobilePlatform ? style.secondIcon : {}]}
              />
              <Text style={[style.text, { color: _color }]}>{time}</Text>
            </View>
          )}
        </View>
      </>
    );
  }
  
  else if (
    trainingSessionState === API.TrainingSessionState.ENDED ||
    trainingSessionState === API.TrainingSessionState.ENDED_LATE
  ) {
    JSXFragment = (
      <>
        <IconSVG svgComponent={AgendaIcon} containerStyle={style.firstIcon} color={Colors.Black} />
        <Text style={style.text}>{t('alex:common.cards.trainingProgressCard.completed')}</Text>
      </>
    );
  }

  return <View style={style.container}>{JSXFragment}</View>;
};
