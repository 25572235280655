import React, { useState, useEffect, useContext } from 'react';
import * as API from 'shared/backend-data';
import { View, Text, ViewStyle, StyleProp, InteractionManager } from 'react-native';
import { DropDownSingleSelection, DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import * as _ from 'lodash-es';
import Styles from '../style';
import { DropdownType } from '../WorkstationModalComponent';
import { Colors } from 'shared/styles/Colors';
import { formatDecimalPercentageTargetCount } from 'shared/backend-data';
import { Checkbox } from 'shared/ui-component/Checkbox';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { getWorkstationTargetActualColor } from 'shared/util/WorkstationUi';

interface Props {
  level: API.WorkstationWorkerLevels;
  workstation: API.Workstation;
  orgUnitOptions: DropDownOption[];
  title: string;
  disable: boolean;
  workstationTargetActual: number;
  shiftWorkersTarget?: API.ShiftWorkersTarget;
  containerStyle?: StyleProp<ViewStyle>;
  workstationTargetActualObject?: API.WorkstationTargetActual | null;

  handleChange: (value: DropDownOption, dropdownType: DropdownType) => void;
  handleCheckBoxChange: (state: boolean | null, level: API.WorkstationWorkerLevels) => void;
}

export const countOptionsDecimal = Array.from(Array(200).keys());
export const countOptionsPercentage = Array.from(Array(101).keys());

export const LevelObjectives: React.FC<Props> = props => {
  const {
    orgUnitOptions,
    title,
    containerStyle,
    disable,
    shiftWorkersTarget,
    level,
    workstationTargetActual,
    workstationTargetActualObject,
    workstation,

    handleChange,
    handleCheckBoxChange,
  } = props;

  const { isValidPermission } = useContext(PermissionManagementContext);

  const isMounted = useIsMounted();

  const [applyPercentageTargets, setApplyPercentageTargets] = useState<boolean>(false);
  const [targetValue, setTargetValue] = useState<number>(0);
  const [minimumValue, setMinimumValue] = useState<DropDownOption>({
    key: '0',
    label: '0',
  });
  const [idealValue, setIdealValue] = useState<DropDownOption>({
    key: '0',
    label: '0',
  });
  const [orgUnit, setOrgUnit] = useState<API.OrganizationalUnit>();

  useEffect(() => {
    let minValue = 0;
    let idealValue = 0;
    let isPercentage = false;

    if (level === API.WorkstationWorkerLevels.LEVEL2) {
      minValue =
        shiftWorkersTarget?.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker ?? 0;
      idealValue =
        shiftWorkersTarget?.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0;
      isPercentage = !!shiftWorkersTarget?.workersWithLevel2AtLeastWorkstationTarget?.isPercentage;
    } else if (level === API.WorkstationWorkerLevels.LEVEL3) {
      minValue =
        shiftWorkersTarget?.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker ?? 0;
      idealValue =
        shiftWorkersTarget?.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0;
      isPercentage = !!shiftWorkersTarget?.workersWithLevel3AtLeastWorkstationTarget?.isPercentage;
    } else if (level === API.WorkstationWorkerLevels.LEVEL4) {
      minValue = shiftWorkersTarget?.workersWithLevel4WorkstationTarget?.minNumberOfWorker ?? 0;
      idealValue = shiftWorkersTarget?.workersWithLevel4WorkstationTarget?.idealNumberOfWorker ?? 0;
      isPercentage = !!shiftWorkersTarget?.workersWithLevel4WorkstationTarget?.isPercentage;
    }

    setMinimumValue({
      key: minValue.toString(),
      label: formatDecimalPercentageTargetCount(minValue, !!applyPercentageTargets),
    });
    setIdealValue({
      key: idealValue.toString(),
      label: formatDecimalPercentageTargetCount(idealValue, !!applyPercentageTargets),
    });
    setApplyPercentageTargets(isPercentage);
  }, [shiftWorkersTarget, level]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      setTargetValue(workstationTargetActual);
    });
  }, [workstationTargetActual]);

  useEffect(() => {
    getOrgUnit();
  }, [workstation]);

  async function getOrgUnit() {
    const _orgUnit = API.getOrganizationalUnit(workstation.parentId);
    if (API.isFailure(_orgUnit)) {
      API.logger.warn(_orgUnit);
      return;
    }

    setOrgUnit(_orgUnit);
  }

  function handleDropdownInputChange(type: DropdownType, value?: DropDownOption) {
    if (value) {
      if (type === DropdownType.IDEAL) {
        handleChange(value, DropdownType.IDEAL);
        setIdealValue(value);
      } else if (type === DropdownType.MINIMUM) {
        handleChange(value, DropdownType.MINIMUM);
        setMinimumValue(value);
      }
    }
  }

  return (
    <View style={[Styles.levelContainer, containerStyle]}>
      <View style={Styles.shiftNameContainer}>
        <Text style={Styles.shiftName}>{title}</Text>
      </View>

      <Checkbox
        style={Styles.percentageCheckBoxContainer}
        titleStyle={Styles.checkBoxTitle}
        onChange={e => {
          setApplyPercentageTargets(prev => !prev);
          handleCheckBoxChange(e, level);
        }}
        initialState={applyPercentageTargets}
        disabled={!isValidPermission(API.Permission.workstations_edit, orgUnit)}
      />

      <Text
        style={[
          Styles.realValue,
          {
            color: workstationTargetActualObject
              ? getWorkstationTargetActualColor(
                  workstationTargetActualObject,
                  level,
                  true,
                  !!applyPercentageTargets ? targetValue : undefined,
                )
              : Colors.Green,
          },
        ]}
      >
        {formatDecimalPercentageTargetCount(targetValue, !!applyPercentageTargets)}
      </Text>
      <DropDownSingleSelection
        options={getDropDownOptions(applyPercentageTargets)}
        value={minimumValue}
        placeholder={''}
        handleChange={(_, value) => handleDropdownInputChange(DropdownType.MINIMUM, value)}
        notEditable={
          !orgUnitOptions.length ||
          disable ||
          !isValidPermission(API.Permission.workstations_edit, orgUnit)
        }
        containerStyle={Styles.minimumValueDropdown}
        dropDownMenuContainerStyle={{ width: 70 }}
      />
      <DropDownSingleSelection
        options={getDropDownOptions(applyPercentageTargets)}
        value={idealValue}
        placeholder={''}
        handleChange={(_, value) => handleDropdownInputChange(DropdownType.IDEAL, value)}
        notEditable={
          !orgUnitOptions.length ||
          disable ||
          !isValidPermission(API.Permission.workstations_edit, orgUnit)
        }
        containerStyle={Styles.idealValueDropdown}
        dropDownMenuContainerStyle={{ width: 70 }}
        clickableContainerStyle={{
          borderBottomColor:
            minimumValue && idealValue
              ? parseInt(minimumValue?.key) > parseInt(idealValue?.key)
                ? Colors.Red
                : Colors.GreyLight
              : Colors.GreyLight,
        }}
      />
    </View>
  );
};

export function getDropDownOptions(applyPercentageTargets: boolean): DropDownOption[] {
  return applyPercentageTargets
    ? countOptionsPercentage.map(option => {
        return { key: option.toString(), label: option.toString() + '%', value: option };
      })
    : countOptionsDecimal.map(option => {
        return { key: option.toString(), label: option.toString(), value: option };
      });
}
