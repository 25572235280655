import { StyleSheet } from 'react-native';
import { Spacings, Colors } from 'shared/styles';

const styles = StyleSheet.create({
  shiftBadge: {
    width: Spacings.Unit + 2,
    height: Spacings.Unit + 2,
    borderRadius: Spacings.Unit + 2,
    position: 'absolute',
    zIndex: 3,
    left: 29,
    top: -3,
  },
  shiftIcon: {
    padding: 3,
    height: Spacings.Medium * 2,
    width: Spacings.Medium * 2,
    borderRadius: Spacings.Medium * 2,
  },
  shiftContainer: {
    height: Spacings.Medium * 2,
    width: Spacings.Medium * 2,
    marginHorizontal: Spacings.Small,
    marginBottom: Spacings.Small,
  },
  shiftsContainer: {
    marginBottom: Spacings.Medium,
    top: 6,
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
  },
  shiftsView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolTipTextStyle: { color: Colors.Black },
  toolTipContainer: {
    backgroundColor: Colors.White,
    position: 'absolute',
    right: 26,
    minWidth: 120,
    maxWidth: 200,
    bottom: -1,
  },
});

export default styles;
