import React from 'react';
import { HeaderTitleProvider } from 'shared/context/collaborateur-profile-context/CollaborateurProfileContext';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'shared/context/AppContext';
import logger from 'shared/util/Logger';
import { WorkerProfileContainer } from './container';
import * as API from 'shared/backend-data';
import { View } from 'react-native';
import { i18n } from 'shared/localisation/i18n';
import { useParams } from 'react-router-dom';
import { ProfileRouteParam } from '../../../../navigation/Routes';
import { combineDataTypeAndId } from 'shared/backend-data';

export interface WorkerProfileState {
  /**
   * if not set, the signed-in user's worker profile will be loaded
   */
  tabIndex?: number;
}

interface Props extends RouteComponentProps<{}, {}, WorkerProfileState> {}

export const WorkerProfile: React.FC<Props> = props => {
  const { id } = useParams<ProfileRouteParam>();
  let workerId = combineDataTypeAndId(API.DataType.WORKER, id);

  if (!workerId) {
    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) {
      logger.warn(appContext);
      return (
        <>
          <View>{i18n.t('common:error.unknown')}</View>
          <View>{i18n.t('common:error.retry')}</View>
        </>
      );
    } else {
      logger.debug(
        'WorkerProfile loaded with logged-in user data. If you want to display another profile please set the state accordingly.',
      );
      workerId = appContext.workerId;
    }
  }
  logger.debug('WorkerProfile workerId:', workerId);

  return (
    <HeaderTitleProvider>
      <WorkerProfileContainer workerId={workerId} {...props} />
    </HeaderTitleProvider>
  );
};
