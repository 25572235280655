import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, fontSize, Spacings } from 'shared/styles';

const styles = StyleSheet.create({
  workstationInnerContainer: {
    width: 184,
    alignSelf: 'flex-start',
    paddingVertical: 3,
  },
  worstationBannerTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 27.5,
    alignItems: 'flex-end',
  },
  workstationLevelTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Small,
  },
  workstationBannerTitleContainer: {
    flex: 0.85,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  label: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  workstationBannerCountContainer: {
    flex: 0.15,
    top: Spacings.Unit,
  },
  count: {
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    fontSize: fontSize.XLarge,
    alignSelf: 'flex-end',
  },
  separator: {
    height: Spacings.xMedium,
  },
});

export default styles;
