import * as CacheContext from './DataLayer';
export * from './DataLayer';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { BRandMigrationScriptUseOnly, closeAndSwitchTenantOrTenantApp } from 'shared/backend-data';
import { loggerAPI as logger } from 'shared/util/Logger';
import { isFailure } from './Failure';
import { AppContext } from '../context/AppContext';
import { AppVersionChecker, VersionType } from '../util/AppVersion';
import { PersistentStorage, PersistentStorageKeys } from './PersistentStorage';
import { Platform, Linking } from 'react-native';
import { ModalContext } from '../ui-component/Modal/Modal';
import { ModalUtils } from '../ui-component/Modal';
import { t } from '../localisation/i18n';
import { AsyncLock } from '../util-ts/AsyncLock';
import { DataLayer } from './DataLayer';
import { UserContext } from '../context/UserContext';

const checkClientVersionLock = new AsyncLock();

/**
 *
 * @param eventSource @see CacheUpdateEventSource
 * @param modal for user interaction
 * @returns true in case of success, false otherwise
 */
export async function checkClientVersionAndSyncClientCache(
  eventSource: CacheContext.CacheUpdateEventSource,
  modal: ModalContext,
): Promise<boolean> {
  return checkClientVersionLock.runSerial(async () => {
    logger.info('CheckClientVersion And SyncClientCache trigger by event id=' + eventSource);

    const appContext = AppContext.getContext();
    if (isFailure(appContext)) {
      ModalUtils.showWarningFailure(modal, appContext, true, true);
      return false;
    }

    if (logger.isPROD) {
      const user = UserContext.getUser();
      if (!API.isFailure(user)) {
        const isEnableLogs = await API.getUserPreference(
          API.UserPreferenceKeys_Common.EnableLogs,
          user.id,
        );
        if (!API.isFailure(isEnableLogs) && isEnableLogs) logger.setIsPROD(false);
      }
    }

    
    {
      const _hasClientUpgradedSinceLastSuccessfulAppLoading =
        await hasClientUpgradedSinceLastSuccessfulAppLoading(appContext.pk);

      if (_hasClientUpgradedSinceLastSuccessfulAppLoading === VersionType.Major) {
        if (DataLayer.pendingLocalMutation.length) {
          const result = await showConflictScreen(modal);
          if (result === false) return false;
        }
        await DataLayer.purgeCache(appContext.pk);
      } else if (_hasClientUpgradedSinceLastSuccessfulAppLoading === VersionType.Minor) {
        await DataLayer.purgeCache(appContext.pk);
      }
    }

    
    {
      const newClientAvailable = await AppVersionChecker.getLatestClientOnlineVersion();
      if (isFailure(newClientAvailable)) {
        ModalUtils.showWarningFailure(modal, newClientAvailable, true, true);
        return false;
      }

      if (newClientAvailable === VersionType.Incompatible) {
        showIncompatibilityScreen(modal);
        return false;
      }
      
      else if (newClientAvailable === VersionType.Major) {
        showAppUpgradeRequiredScreen(modal);
        return false;
      }
      
      else if (newClientAvailable === VersionType.Minor) {
        const result = await showNewVersionAvailableScreen(modal);
        if (result === true) {
          showAppUpgradeRequiredScreen(modal);
          return false;
        }
      }
    }

    
    {
      const failures: API.Failure[] = [];
      for (let i = 0; i < DataLayer.pendingLocalMutation.length; i++) {
        const pendingMutation = DataLayer.pendingLocalMutation[i];
        const result = await BRandMigrationScriptUseOnly._mutateFactoryAPI(
          pendingMutation.mutationType,
          pendingMutation.dataType,
          pendingMutation.variables,
        );
        if (API.isFailure(result)) {
          failures.push(result);
        } else {
          DataLayer.pendingLocalMutation.splice(i, 1);
        }
      }

      if (failures.length) {
        const result = await showConflictScreen(modal);
        if (result === false) return false;

        
        DataLayer.pendingLocalMutation = [];
      }
    }

    {
      const result = await DataLayer.syncClientCacheWithServer(appContext.pk);
      if (isFailure(result)) {
        ModalUtils.showWarningFailure(modal, result, true, true);
        return false;
      }
    }

    await saveClientVersionToPersistentStorage(appContext.pk);

    return true;
  });
}

/**
 * Blocking modal to ask the user what to do as there are local changes conflict with the server that cannot be merged automaticaly.
 * @returns true if user choose to loose local changes and continue.
 *          false if user wants to keep its local changes, in this case user is redirected to TenantApp selection before this function returns.
 */
function showConflictScreen(modal: ModalContext): Promise<boolean> {
  return new Promise(async resolve => {
    ModalUtils.showWarning(modal, {
      isOpaque: true,
      isBlocking: true,
      warningMessage: t('common:error.appOfflineDataConflict'),
      warningAcceptButton: t('common:button.yes'),
      warningAcceptCallback: () => resolve(true),
      warningCancelButton: t('common:button.cancel'),
      warningCancelCallback: async () => {
        closeAndSwitchTenantOrTenantApp();
        resolve(false);
      },
    });
  });
}

/**
 * Inform the user that a new version is available.
 * @returns true if user chooses to install it now.
 *          false if user chosses to ignore it.
 */
function showNewVersionAvailableScreen(modal: ModalContext): Promise<boolean> {
  return new Promise(async resolve => {
    ModalUtils.showWarning(modal, {
      isOpaque: true,
      isBlocking: true,
      warningMessage: t('common:error.appNewVersionAvailable'),
      warningAcceptButton: t('common:button.yes'),
      warningAcceptCallback: () => resolve(true),
      warningCancelButton: t('common:button.cancel'),
      warningCancelCallback: () => resolve(false),
    });
  });
}

async function saveClientVersionToPersistentStorage(pk: string): Promise<void> {
  const r = await PersistentStorage.setItem(
    PersistentStorageKeys.LastClientVersionKey,
    AppVersionChecker.getClientVersion(),
    pk,
  );
  if (!API.isFailure(r)) {
    logger.info('Client version has been persisted');
  }
}

/**
 * Blocking UI with a message explaining that online version and client version are not compatible, hence user shall try later
 * (it might takes days for the new mobile app version to be published)
 * @param modal
 */
function showIncompatibilityScreen(modal: ModalContext): void {
  ModalUtils.showWarning(modal, {
    isBlocking: true,
    warningMessage: t('common:error.appIncompatible'),
  });
}

/**
 * Blocking screen to ask for client upgrade
 * - Web client: ask user to refresh the web page
 * - Mobile client: ask user to install latest version and restart
 * @param modal
 */
function showAppUpgradeRequiredScreen(modal: ModalContext): void {
  const appStoreUrl: string = AppVersionChecker.getAppStoreURL();

  const goToAppStore = (): void => {
    Linking.openURL(appStoreUrl);
  };

  const refreshPage = (): void => {
    location.reload();
  };

  const isPlatformWeb = Platform.OS === 'web';

  ModalUtils.showWarning(modal, {
    isOpaque: true,
    isBlocking: true,
    warningMessage: isPlatformWeb
      ? t('common:error.updateApp.web')
      : t('common:error.updateApp.mobile'),
    warningAcceptCallback: isPlatformWeb ? refreshPage : goToAppStore,
    warningAcceptButton: t('common:button.refresh'),
  });
}

/**
 * Tells whether the client has been upgraded since last startup
 * @returns false or the version category change
 */
async function hasClientUpgradedSinceLastSuccessfulAppLoading(
  pk: string,
): Promise<false | VersionType> {
  const previousClientVersion = await PersistentStorage.getItem<string>(
    PersistentStorageKeys.LastClientVersionKey,
    pk,
  );
  const clientVersion = AppVersionChecker.getClientVersion();

  if (!previousClientVersion || previousClientVersion === clientVersion) return false;

  if (AppVersionChecker.isVersionNewer(clientVersion, previousClientVersion, VersionType.Major))
    return VersionType.Major;

  if (AppVersionChecker.isVersionNewer(clientVersion, previousClientVersion, VersionType.Minor))
    return VersionType.Minor;

  return VersionType.Build;
}
