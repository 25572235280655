import React, { useState } from 'react';
import {
  StyleSheet,
  TouchableOpacityProps,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import PlusIcon from 'shared/assets/svg/icon.plus.svg';
import { Colors, Styles } from 'shared/styles';
import { IconButton } from 'shared/ui-component/Button';
import { SvgProps } from 'react-native-svg';
import { useCallOnHover } from 'shared/hooks/CallOnHover';

const MAG_BUTTON_SIZE = 36;
const ICON_SIZE = 16;

const styles = StyleSheet.create({
  iconButtonStyle: { width: '100%', height: '100%' },
});

interface Props extends TouchableOpacityProps {
  icon?: React.FC<SvgProps>;
  size?: number;
  iconSize?: number;
  iconContainerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

export const ShadowOnHoverButton: React.FC<Props> = props => {
  const {
    style = {},
    icon = PlusIcon,
    size = MAG_BUTTON_SIZE,
    iconSize = ICON_SIZE,
    iconContainerStyle = {},
    onPress,
  } = props;

  const [isHover, setIsHover] = useState(false);

  return (
    <TouchableOpacity
      ref={useCallOnHover<TouchableOpacity>(
        'transparent',
        () => setIsHover(true),
        () => setIsHover(false),
      )}
      style={[
        {
          width: size,
          height: size,
          borderRadius: size,
        },
        isHover ? { ...StyleSheet.flatten(Styles.shadow), shadowOpacity: 0.1 } : {},
        style,
      ]}
    >
      <IconButton
        icon={icon}
        color={Colors.White}
        size={size}
        iconSize={iconSize}
        style={[styles.iconButtonStyle, { borderRadius: size }, iconContainerStyle]}
        onPress={onPress}
      />
    </TouchableOpacity>
  );
};
