import React, { useEffect, useState } from 'react';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { BannerSkeleton } from '../bannerSkeleton/BannerSkeletonComponent';
import { View, Text, InteractionManager, TouchableOpacity } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import styles from './styles';
import { Colors } from 'shared/styles';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import BannerStyle from '../bannerSkeleton/styles';
import {
  ModifyTrainingModal,
  TrainingModalConfig,
} from 'skillmgtweb/src/components/training/training-modal/container/TrainingModal';
import { deleteTrainingHandler } from '../../my-factory/trainings/component/deleteTrainingHandler';
import { ModalUtils } from 'shared/ui-component/Modal';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../navigation/Routes';
import { MenuItem } from 'shared/ui-component/Menu';
import * as _ from 'lodash-es';
import { getTrainingDurationLabel } from 'shared/ui-component/DropDown/Data/DropDownData';
import { MyHub } from 'shared/util/MyHub';

const practicalTrainingIcon = require('shared/assets/svg/icon.practTrain.svg').default;
const notPracticalTrainingIcon = require('shared/assets/svg/icon.lecture.svg').default;

interface Props {
  training: API.Training;
}

export const TrainingBanner: React.FC<Props> = props => {
  const { training } = props;

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const [isPractical, setIsPractical] = useState<boolean>();
  const [skillsRequireTheTrainingCount, setSkillsRequireTheTrainingCount] = useState<number>();
  const [trainingVersion, setTrainingVersion] = useState<API.TrainingVersion>();
  const [skillsRequireTheTraining, setSkillsRequireTheTraining] = useState<API.Skill[]>();
  const [trainingModalConfig, setTrainingModalConfig] = useState<TrainingModalConfig>({
    editMode: false,
    trainingVersion: null,
    skillIds: [],
  });
  const [showTrainingModal, setShowTrainingModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.TRAININGVERSION &&
        (data.tooManyMutations || data.factory.trainingVersion.trainingId === training.id)
      ) {
        handleSkillsRequireTheTraining();
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      handleSkillsRequireTheTraining();
    });

    return () => {
      removeListener();
    };
  }, [training.id]);

  async function handleSkillsRequireTheTraining() {
    const latestTrainingVersion = await API.getTrainingVersionLatestForTraining(training.id);
    if (!isMounted.current) return;
    if (API.isFailure(latestTrainingVersion)) {
      logger.warn(latestTrainingVersion);
      return;
    }

    setSkillsRequireTheTrainingCount(latestTrainingVersion.skillIds.length);
    setTrainingVersion(latestTrainingVersion);
    const skills: API.Skill[] = [];
    await Promise.all(
      _.map(latestTrainingVersion.skillIds, async skillId => {
        const skill = await API.getSkill(skillId);
        if (!isMounted.current) return;
        if (API.isFailure(skill)) {
          logger.warn(skill);
          return;
        }
        skills.push(skill);
      }),
    );

    if (!isMounted.current) return;

    setSkillsRequireTheTraining(skills);

    const isPractical = await API.isPracticalTrainingVersion(latestTrainingVersion.id);
    if (!isMounted.current) return;
    if (API.isFailure(isPractical)) {
      logger.warn(isPractical);
      return;
    }

    setIsPractical(isPractical);
  }

  async function handleTrainingEdit(trainingId: API.Training) {
    const latestTrainingVersion = await API.getTrainingVersionLatestForTraining(trainingId.id);
    if (!isMounted.current) return;
    if (API.isFailure(latestTrainingVersion)) {
      logger.warn(latestTrainingVersion);
      return;
    }
    setTrainingModalConfig({
      editMode: true,
      trainingVersion: latestTrainingVersion,
      skillIds: latestTrainingVersion.skillIds,
    });
    setShowTrainingModal(true);
  }

  function workerMenuItems(): MenuItem[] {
    return [
      {
        label: 'common:button.edit',
        onPress: () => handleTrainingEdit(training),
      },
      {
        label: 'common:button.delete',
        onPress: () =>
          deleteTrainingHandler(training.id, modal, () => history.push(RouteLocations.Trainings())),
      },
    ];
  }

  return (
    <>
      {showTrainingModal && (
        <ModifyTrainingModal
          config={trainingModalConfig}
          handleModalClose={() => {
            setShowTrainingModal(false);
            setTrainingModalConfig({
              editMode: false,
              trainingVersion: null,
              skillIds: [],
            });
          }}
          handleRefresh={() => history.push(RouteLocations.Trainings())}
        />
      )}
      <BannerSkeleton
        headerIcon={
          <View style={styles.iconContainer}>
            <TouchableOpacity onPress={() => handleTrainingEdit(training)}>
              <IconSVG
                svgComponent={isPractical ? practicalTrainingIcon : notPracticalTrainingIcon}
                size={{ height: 52, width: 52 }}
                color={Colors.White}
              />
            </TouchableOpacity>
          </View>
        }
        headerTitle={capitalizeFirstLetter(training.name)}
        menu={{
          showMenu: true,
          menuItems: workerMenuItems(),
        }}
        bannerContent={{
          component: (
            <View>
              <Text style={BannerStyle.contentTitle}>
                {isPractical ? t('glossary:trainingPractical') : t('glossary:trainingNotPractical')}
              </Text>
              {trainingVersion && trainingVersion.durationInMin ? (
                <Text style={BannerStyle.contentSubTitle}>
                  {getTrainingDurationLabel(trainingVersion.durationInMin)}
                </Text>
              ) : (
                <Text style={BannerStyle.contentSubTitle}>
                  {t('alex:trainingsTab.trainingBanner.noDuration')}
                </Text>
              )}

              {skillsRequireTheTrainingCount ? (
                <>
                  <Text style={BannerStyle.contentTitle}>
                    {t('alex:trainingsTab.trainingBanner.skillList')}
                  </Text>
                  <View style={styles.trainings}>
                    {skillsRequireTheTraining?.map(eachTraining => (
                      <Text style={styles.trainingName} key={eachTraining.id}>
                        {eachTraining.name}
                      </Text>
                    ))}
                  </View>
                </>
              ) : null}
            </View>
          ),
        }}
        files={training.files}
      />
    </>
  );
};
