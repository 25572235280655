import React, { useState, useEffect } from 'react';
import { View, Text, InteractionManager, ActivityIndicator, ScrollView } from 'react-native';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { sharedGlobalStyle } from 'shared/styles/shared-global';
import * as _ from 'lodash-es';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { Spacings, Colors } from 'shared/styles';
import { ProofBundleItem } from '../container';
import moment from 'moment';
import { OutlineButton } from 'shared/ui-component/Button';
import * as API from 'shared/backend-data';
import { WorkerSkillAddModal } from '../../../../add-workerskill-modal/container';
import { ProofBookContainerWeb } from '../../../../training/proof-book';

const SkillIcon = require('shared/assets/svg/icon.skill.small.svg').default;
const LectureIcon = require('shared/assets/svg/icon.lecture.svg').default;
const PracticalIcon = require('shared/assets/svg/icon.practTrain.svg').default;
const CheckIcon = require('shared/assets/svg/icon.check.svg').default;

interface Props {
  proofBundleItemsToValidate?: ProofBundleItem[];
  lastProofBundleSeenDate?: string;
  loader: boolean;

  proofBundleReviewed: () => void;
}

export const SkillValidatePanelComponent: React.FC<Props> = props => {
  const { lastProofBundleSeenDate, proofBundleItemsToValidate, loader } = props;

  const [workerProofBundleItemsMap, setWorkerProofBundleItemsMap] = useState<
    Map<string, ProofBundleItem[]>
  >(new Map<string, ProofBundleItem[]>());
  const [selectedProofBundleItem, setSelectedProofBundleItem] = useState<ProofBundleItem>();
  const [showWorkerSkillReviewModal, setShowWorkerSkillReviewModal] = useState<boolean>(false);
  const [showWorkerSkillAddModal, setShowWorkerSkillAddModal] = useState<boolean>(false);
  const [reSubmitSkill, setReSubmitSkill] = useState<API.Skill>();
  const [reSubmitWorker, setReSubmitWorker] = useState<API.Worker>();
  const [showProofBook, setShowProofBook] = useState<boolean>(false);
  const [resubmitProof, setResubmitProof] = useState<API.ProofBundle>();
  const [proofBookSkillIds, setProofBookSkillIds] = useState<string[]>();
  const [proofBookWorkerIds, setProofBookWorkerIds] = useState<string[]>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (proofBundleItemsToValidate) getProofBundleItemsDetails();
    });
  }, [proofBundleItemsToValidate]);

  function getTodoListCount(items: Map<string, ProofBundleItem[]>): number {
    if (!items.size) return 0;

    let count = 0;
    Array.from(items.entries()).forEach(eachItem => (count = eachItem[1].length + count));
    return count;
  }

  function isProofBundleUpdatedSinceLastView(proofBundle: ProofBundleItem): boolean {
    return !!(
      lastProofBundleSeenDate &&
      new Date(proofBundle.proofBundles[0].updatedAt).getTime() >
        new Date(lastProofBundleSeenDate).getTime()
    );
  }

  async function getProofBundleItemsDetails() {
    const tempDetailedProofBundleItemsToValidate = [...(proofBundleItemsToValidate ?? [])];

    
    let _workerProofBundleItemsMap = new Map<string, ProofBundleItem[]>();
    _.forEach(tempDetailedProofBundleItemsToValidate, tempDetailedProofBundleItemToValidate => {
      const workerProofsArray = _workerProofBundleItemsMap.get(
        tempDetailedProofBundleItemToValidate.worker.id,
      )!;
      if (workerProofsArray) {
        if (
          (tempDetailedProofBundleItemToValidate.trainingSession &&
            workerProofsArray.find(
              workerProof =>
                workerProof.trainingSession?.id ===
                tempDetailedProofBundleItemToValidate.trainingSession?.id,
            )) ||
          (tempDetailedProofBundleItemToValidate.trainingVersion &&
            workerProofsArray.find(
              workerProof =>
                workerProof.trainingVersion?.id ===
                tempDetailedProofBundleItemToValidate.trainingVersion?.id,
            ))
        ) {
          
        } else {
          workerProofsArray.push(tempDetailedProofBundleItemToValidate);
          _workerProofBundleItemsMap.set(
            tempDetailedProofBundleItemToValidate.worker.id,
            workerProofsArray,
          );
        }
      } else {
        _workerProofBundleItemsMap.set(tempDetailedProofBundleItemToValidate.worker.id, [
          tempDetailedProofBundleItemToValidate,
        ]);
      }
    });

    
    const sortedProofBundles = Array.from(_workerProofBundleItemsMap.values()).map(
      workerProofsArray =>
        _.orderBy(
          workerProofsArray,
          [
            eachItem => {
              const date =
                eachItem.proofBundles.length > 1
                  ? new Date(
                      _.max(
                        eachItem.proofBundles.map(proofBundle =>
                          new Date(proofBundle.updatedAt).getTime(),
                        ),
                      ) ?? '',
                    )
                  : new Date(eachItem.proofBundles[0].updatedAt);
              const milliseconds = date.getTime();

              return milliseconds;
            },
          ],
          ['desc'],
        ),
    );

    
    const sortedWorkerWithProofBundle = _.orderBy(
      sortedProofBundles,
      [
        eachItem => {
          const date = new Date(eachItem[0].proofBundles[0].updatedAt);
          const milliseconds = date.getTime();

          return milliseconds;
        },
      ],
      ['desc'],
    );

    _workerProofBundleItemsMap.clear();
    sortedWorkerWithProofBundle.forEach(eachData =>
      _workerProofBundleItemsMap.set(eachData[0].worker.id, eachData),
    );

    setWorkerProofBundleItemsMap(_workerProofBundleItemsMap);
  }

  function getIcon(_item: ProofBundleItem) {
    let icon = SkillIcon;

    if (_item.trainingSession) {
      if (_item.trainingSession.isPractical) {
        icon = PracticalIcon;
      } else {
        icon = LectureIcon;
      }
    } else if (_item.training) {
      icon = PracticalIcon;
    }

    return icon;
  }

  function onHandlePress(item: ProofBundleItem) {
    setSelectedProofBundleItem({ ...item });
    if (item.proofBundles[0].review.state === API.ReviewState.REJECTED_TO_RESUBMIT) {
      setReSubmitSkill(item.skill);
      setReSubmitWorker(item.reSubmitionWorker);
      setResubmitProof(item.proofBundles[0]);
      setShowWorkerSkillAddModal(true);
    } else if (item.trainingSession || item.trainingVersion) {
      setShowProofBook(true);
    } else {
      setShowWorkerSkillReviewModal(true);
    }
  }

  function handleOpenProofBook(workerIds: string[], skillIds: string[]) {
    setProofBookWorkerIds(workerIds);
    setProofBookSkillIds(skillIds);

    setShowProofBook(true);
    setShowWorkerSkillAddModal(false);
  }

  return (
    <View style={[sharedGlobalStyle.cardMain, { width: '100%', height: '100%', padding: 0 }]}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          {t('alex:dashboard.skillValidateTitle')}
          <Text style={styles.listCount}>{` (${getTodoListCount(
            workerProofBundleItemsMap,
          )})`}</Text>
        </Text>
      </View>
      {loader ? (
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator color={Colors.Yellow} size="large" />
        </View>
      ) : (
        <View style={[styles.flatListContainer]}>
          {workerProofBundleItemsMap.size &&
          Array.from(workerProofBundleItemsMap.entries()).length ? (
            <ScrollView style={[styles.flatListStyle]}>
              {Array.from(workerProofBundleItemsMap.entries()).map((item, index) => (
                <View
                  key={item[1][0].worker.id}
                  style={[styles.flatListItemStyle, { zIndex: -index }]}
                >
                  <View style={styles.profilePictureContainer}>
                    {renderImage(
                      item[1][0].worker.profilePicture,
                      ImageSizes.Large,
                      item[1][0].worker.name,
                    )}
                  </View>
                  <View style={styles.skillsContainer}>
                    <View style={styles.workerNameContainer}>
                      <EllipsisWithTooltip
                        text={capitalizeFirstLetter(item[1][0].worker.name)}
                        textStyle={styles.workerNameTextStyle}
                        toolTipContainerStyle={styles.toolTipContainer}
                      />
                    </View>
                    <View style={styles.skillsInnerContainer}>
                      {item[1].map((_item, index) => (
                        <View
                          key={_item.proofBundles[0].id + index}
                          style={[styles.skillValidateRow, { zIndex: -index }]}
                        >
                          <View style={styles.skillNameContainer}>
                            <IconSVG
                              svgComponent={getIcon(_item)}
                              containerStyle={styles.skillValidateSVG}
                              size={{
                                width: Spacings.Standard,
                                height: Spacings.Standard,
                              }}
                            />
                            <EllipsisWithTooltip
                              textStyle={styles.skillNameTextStyle}
                              text={capitalizeFirstLetter(
                                _item.training ? _item.training.name : _item.skill.name,
                              )}
                            />
                          </View>
                          {_item.skill && (
                            <View style={styles.validateSkillContainer}>
                              <OutlineButton
                                isSmallButton
                                text={
                                  _item.proofBundles[0].review.state ===
                                  API.ReviewState.REJECTED_TO_RESUBMIT
                                    ? t('common:button.submit')
                                    : t('alex:dashboard.review')
                                }
                                onPress={() => onHandlePress(_item)}
                              />
                            </View>
                          )}
                        </View>
                      ))}
                    </View>
                    <View style={styles.durationContainer}>
                      <Text style={styles.durationTextStyle}>
                        {isProofBundleUpdatedSinceLastView(item[1][0]) && (
                          <View style={styles.circleView} />
                        )}
                        {moment(item[1][0].proofBundles[0].updatedAt).fromNow()}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </ScrollView>
          ) : (
            <View style={styles.emptyListContainer}>
              <View style={styles.emptyListIconContainer}>
                <CheckIcon color={Colors.GreyLight} />
              </View>
              <Text style={styles.emptyListText}>{t('alex:dashboard.emptyToDoList')}</Text>
            </View>
          )}
        </View>
      )}

      {showWorkerSkillReviewModal &&
        selectedProofBundleItem &&
        selectedProofBundleItem.proofBundles.length > 1 && (
          <ProofBookContainerWeb
            setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
            workerIds={[selectedProofBundleItem.worker.id]}
            skillIds={selectedProofBundleItem.skill.skillIds as string[]}
            proofAlreadyValidatedWorkersAndSkills={{
              workerIds: [selectedProofBundleItem.worker.id],
              skillIds: selectedProofBundleItem.skill.skillIds as string[],
            }}
          />
        )}

      {showWorkerSkillReviewModal &&
        selectedProofBundleItem &&
        selectedProofBundleItem.proofBundles.length === 1 && (
          <ProofBookContainerWeb
            proofBundleId={selectedProofBundleItem.proofBundles[0].id}
            setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
          />
        )}
      {showProofBook &&
        (selectedProofBundleItem?.trainingSession?.id ||
          selectedProofBundleItem?.trainingVersion?.id) && (
          <ProofBookContainerWeb
            trainingSessionId={selectedProofBundleItem.trainingSession?.id}
            trainingVersionId={selectedProofBundleItem.trainingVersion?.id}
            setShowAddTrainingProofModal={setShowProofBook}
            proofBundleId={selectedProofBundleItem.proofBundles[0].id}
            workerIds={[selectedProofBundleItem.worker.id]}
          />
        )}
      {showWorkerSkillAddModal && reSubmitSkill && reSubmitWorker && (
        <WorkerSkillAddModal
          selectedSkills={[reSubmitSkill]}
          selectedWorkers={[reSubmitWorker]}
          handleModalClose={() => setShowWorkerSkillAddModal(false)}
          renewProof
          resubmitProof={resubmitProof}
          openProofBook={handleOpenProofBook}
        />
      )}
      {proofBookSkillIds && proofBookWorkerIds && showProofBook && (
        <ProofBookContainerWeb
          workerIds={proofBookWorkerIds}
          skillIds={proofBookSkillIds}
          setShowAddTrainingProofModal={setShowProofBook}
        />
      )}
    </View>
  );
};
