import React, { useState, useEffect, useContext } from 'react';
import { InteractionManager } from 'react-native';
import { TrainingSessionModalComponent } from '../component/TrainingSessionModalComponent';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import Aigle from 'aigle';
import { Tag } from 'shared/ui-component/Input/InputTag';
import * as _ from 'lodash-es';
import { WorkerDropDownOptionWithDetails } from 'shared/ui-component/DropDown/DropDown';
import {
  getTrainingDurationLabel,
  getTrainingDurationOptions,
} from 'shared/ui-component/DropDown/Data/DropDownData';
import { sortWorkers } from 'shared/backend-data';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { checkTrainingSessionDuplication } from 'shared/util/TrainingSessionWarningModal';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';
import { t } from 'shared/localisation/i18n';
import { ProofBookContainerWeb } from '../../proof-book';

interface Props {
  showModal: boolean;
  trainingSessionId?: string;
  handleModalClose: (value: boolean) => void;
}

export enum TrainingSessionInputElements {
  time = 'time',
  date = 'date',
  duration = 'duration',
  location = 'location',
  mentor = 'mentor',
  description = 'description',
  trainees = 'trainees',
  trainerComment = 'trainerDescription',
  trainingSessionCoveragePercentage = 'trainingSessionCoveragePercentage',
}

export const LectureTrainingSessionModalContainer: React.FC<Props> = props => {
  const { trainingSessionId, showModal, handleModalClose } = props;

  const isMounted = useIsMounted();
  const modal = useModal();

  const { isValidPermission } = useContext(PermissionManagementContext);

  const [trainingVersionDropdownOptions, setTrainingVersionDropdownOptions] = useState<
    DropDownOption<API.TrainingVersion>[]
  >([]);
  const [showAddTrainingProofModal, setShowAddTrainingProofModal] = useState(false);
  const [trainingVersion, setTrainingVersion] = useState<DropDownOption<API.TrainingVersion>>();
  const [scheduledStartDate, setScheduledStartDate] = useState<Date>();
  const [duration, setDuration] = useState<DropDownOption>();
  const [mentors, setMentors] = useState<WorkerDropDownOptionWithDetails[]>();
  const [workers, setWorkers] = useState<WorkerDropDownOptionWithDetails[]>([]);
  const [location, setLocation] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [workerDropdownOptions, setWorkerDropdownOptions] = useState<DropDownOption[]>([]);
  const [skillTags, setSkillTags] = useState<Tag[]>([]);
  const [trainingSession, setTrainingSession] = useState<API.TrainingSession>();
  const [trainingDescription, setTrainingDescription] = useState<string>('');
  const [trainerComment, setTrainerComment] = useState<string>();
  const [trainerFiles, setTrainerFiles] = useState<API.S3ObjectInput[]>([]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await fetchTrainings();
      if (!isMounted.current) return;

      if (!(workers || mentors)) await setWorkerOptions();
    });
  }, []);

  useEffect(() => {
    setWorkerOptions(workers, mentors);
  }, [workers, mentors]);

  useEffect(() => {
    if (trainingSessionId && showModal) {
      fetchTrainingSessionDetails(trainingSessionId);
    }
  }, [trainingSessionId, showModal]);

  async function fetchTrainingSessionDetails(trainingSessionId: string) {
    const _trainingSession = await API.getTrainingSession(trainingSessionId);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingSession)) {
      logger.warn('Error while fetching training session', trainingSession);
      return;
    }
    setTrainingSession(_trainingSession);

    const _trainingVersion = await API.getTrainingVersion(_trainingSession.trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingVersion)) {
      logger.warn(_trainingVersion);
      return;
    }

    await fetchTrainingVersionDetails(_trainingSession.trainingVersionId);
    if (!isMounted.current) return;

    const training = await API.getTraining(_trainingVersion.trainingId);
    if (!isMounted.current) return;
    if (API.isFailure(training)) {
      logger.warn(training);
      return;
    }

    setTrainingVersion({
      key: _trainingVersion.id,
      label: training.name,
      value: _trainingVersion,
    });

    if (_trainingSession.scheduledStartDate) {
      onChange(new Date(_trainingSession.scheduledStartDate));
    }

    if (_trainingSession.durationInMin) {
      setDuration({
        key: _trainingSession.durationInMin.toString(),
        label: getTrainingDurationLabel(_trainingSession.durationInMin),
        value: _trainingSession.durationInMin,
      });
    }

    
    const _workers: WorkerDropDownOptionWithDetails[] = [];
    await Aigle.map(_trainingSession.scheduledTraineeIds, async workerId => {
      const result = await getWorkerDetails(workerId);
      if (!isMounted.current) return;
      if (result) {
        _workers.push({
          key: result.id,
          label: result.name,
          value: result,
          isChecked: false,
        });
      }
    });
    setWorkers(_workers);

    
    if (_trainingSession.scheduledTrainers) {
      const _mentors = [];

      for (const trainer of _trainingSession.scheduledTrainers) {
        const _mentor = await getWorkerDetails(trainer.trainerId);
        if (!isMounted.current) return;

        if (_mentor)
          _mentors.push({
            key: _mentor.id,
            label: _mentor.name,
            value: _mentor,
            trainingSessionCoveragePercentage: trainer.percentage,
          });
      }

      if (_mentors) {
        setMentors(_mentors);
      }
    }

    setDescription(_trainingSession.description ?? undefined);
    setLocation(_trainingSession.location ?? undefined);
    setTrainerComment(_trainingSession.trainerComment ?? undefined);
    setTrainerFiles(
      _trainingSession.trainerFiles ? API.deepClone(_trainingSession.trainerFiles) : [],
    );
  }

  const getWorkerDetails = async (workerId: string): Promise<API.Worker | undefined> => {
    const worker = await API.getWorker(workerId);
    if (!isMounted.current) return;
    if (API.isFailure(worker)) {
      logger.warn('Error while fetching worker details', worker);
      return;
    }

    return worker;
  };

  const onChange = (startDate: Date) => {
    setScheduledStartDate(startDate);
  };

  async function handleInputText(name: string, value: string) {
    if (name === TrainingSessionInputElements.location) {
      setLocation(value);
    } else if (name === TrainingSessionInputElements.description) {
      setDescription(value);
    } else if (name === TrainingSessionInputElements.trainerComment) {
      if (value.length) {
        const _trainerComment = trainerComment ? trainerComment + '\n' + value : value;

        if (trainingSession) {
          const updatedTrainingSession = await saveChange({
            id: trainingSession.id,
            trainerComment: _trainerComment,
          });
          setTrainerComment(_trainerComment);
        } else {
          setTrainerComment(_trainerComment);
        }
      }
    } else {
      logger.error('handleInputText with unknown name: ' + name);
    }
  }

  async function onTrainerFilesChange(s3Files: API.S3ObjectInput[]) {
    if (trainingSession) {
      await saveChange({
        id: trainingSession.id,
        trainerFiles: s3Files,
      });
      setTrainerFiles(s3Files);
    } else {
      setTrainerFiles(s3Files);
    }
  }

  /**
   * Used to save partial changes on the TrainingSession immediatly (not waiting for the form to be submited)
   * @param trainingSession
   */
  async function saveChange(
    trainingSession: API.TrainingSessionPartialUpdateInput,
  ): Promise<API.TrainingSession | undefined> {
    modal.displayLoader(ModalUtils.LoaderConfig({}));

    const factory = await API.updateFactoryBusinessObject(
      API.DataType.TRAININGSESSION,
      trainingSession,
    );
    if (API.isFailure(factory)) {
      logger.error(factory);
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('common:error.unknown'),
        }),
      );
      return;
    }

    modal.hideLoader();

    return {
      ...factory.trainingSession,
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    };
  }

  async function handleDropdown(
    name: string,
    value?: DropDownOption | DropDownOption[] | WorkerDropDownOptionWithDetails[],
  ) {
    if (!value) return;
    if (!_.isArray(value)) {
      if (name === API.DataType.TRAINING) {
        setTrainingVersion(value);

        await fetchTrainingVersionDetails(value.key);
        if (!isMounted.current) return;
      } else if (name === TrainingSessionInputElements.duration) {
        setDuration(value);
      } else if (name === TrainingSessionInputElements.trainingSessionCoveragePercentage) {
      }
    } else {
      if (name === TrainingSessionInputElements.trainees) {
        
        if (trainingSession?.id) {
          const traineeWithDuplicateTrainingSession = await checkTrainingSessionDuplication({
            ...trainingSession,
            scheduledTraineeIds: _.map(value, worker => worker.key),
          });
          if (traineeWithDuplicateTrainingSession.length) {
            
            ModalUtils.showVetoModal(t('alex:vetoModal.mergeDuplicateSessions'), {
              type: 'DuplicateVeto',
              data: { dependencyIds: traineeWithDuplicateTrainingSession },
              message: 'duplicate training session will be merged when saving', 
            });
          }
        }

        if (trainingVersion?.value) {
          const training = await API.getTraining(trainingVersion?.value.trainingId);
          if (API.isFailure(training)) {
            logger.warn('Cannot fetch training ', training);
          } else {
            if (
              (!trainingSession ||
                (trainingSession &&
                  API.isTrainingSessionDraftOrRequestValidated(trainingSession))) &&
              training.maxTrainee &&
              value.length > training.maxTrainee
            ) {
              ModalUtils.showWarning(modal, {
                warningMessage: t('alex:scheduleTrainingModal.tooManyTrainee', {
                  count: training.maxTrainee,
                }),
                warningAcceptButton: t('common:button.ok'),
              });
              return;
            }
          }
        }

        setWorkers(value as WorkerDropDownOptionWithDetails[]); 
      } else if (name === TrainingSessionInputElements.mentor) {
        setMentors(value as WorkerDropDownOptionWithDetails[]); 
      }
    }
  }

  async function setWorkerOptions(
    workers?: WorkerDropDownOptionWithDetails[],
    mentors?: DropDownOption[],
  ) {
    const workerOptions: DropDownOption[] = [];
    setLoading(true);
    const _worker = await API.getWorkers();
    if (!isMounted.current) return;
    if (API.isFailure(_worker)) {
      logger.warn(_worker);
      setLoading(false);
      return;
    }

    sortWorkers(_worker.result).forEach(worker => {
      if (
        workers?.find(_worker => _worker.key === worker.id) ||
        mentors?.find(mentor => worker.id === mentor.key)
      ) {
        workerOptions.push({
          key: worker.id,
          label: capitalizeFirstLetter(worker.name),
          value: worker,
          disabled: true,
        });
      } else
        workerOptions.push({
          key: worker.id,
          label: capitalizeFirstLetter(worker.name),
          value: worker,
          disabled: false,
        });
    });

    if (!isValidPermission(API.Permission.trainingSessions_edit)) {
      const currentUser = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(currentUser)) return currentUser;

      const currentWorker = _.find(workerOptions, worker => worker.key === currentUser.id);
      if (currentWorker) {
        setWorkerDropdownOptions([currentWorker]);
      }
    } else setWorkerDropdownOptions(workerOptions);

    setLoading(false);
  }

  async function fetchTrainingVersionDetails(trainingVersionId: string) {
    const _trainingVersion = await API.getTrainingVersion(trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingVersion)) {
      logger.warn(_trainingVersion);
      return;
    }

    if (!duration) {
      const durationLabel = getTrainingDurationOptions().find(
        trainingDropdownOption => trainingDropdownOption.value === _trainingVersion.durationInMin,
      )?.label;

      setDuration({
        key: 'trainingVersion.durationInMin',
        value: _trainingVersion.durationInMin,
        label: durationLabel ?? '',
      });
    }

    const _training = await API.getTrainingForATrainingVersion(_trainingVersion.id);
    if (!isMounted.current) return;
    if (API.isFailure(_training)) return _training;

    if (_training.defaultTrainerId && !mentors) {
      const _mentor = await API.getWorker(_training.defaultTrainerId);
      if (!isMounted.current) return;
      if (API.isFailure(_mentor)) {
        logger.warn(_mentor);
        return;
      }
      setMentors([
        {
          key: _mentor.id.toString(),
          label: _mentor.name,
          value: _mentor,
        },
      ]);
    }

    const _skillTags: Tag[] = [];
    await Aigle.map(_trainingVersion.skillIds, async skillId => {
      const data = await API.getSkill(skillId);
      if (!isMounted.current) return;
      if (API.isFailure(data)) {
        logger.warn('failed to fetch worker details', data);
        return;
      }
      _skillTags.push({
        key: skillId,
        label: data.name,
        tagType: API.DataType.SKILL,
      });
    });

    if (_training.description) setTrainingDescription(_training.description);
    setSkillTags(_.sortBy(_skillTags, eachSkill => eachSkill.label));
  }

  async function fetchTrainings() {
    setLoading(true);

    const trainingVersions = await API.getTrainingVersionAndTrainings();
    if (!isMounted.current) return;
    if (API.isFailure(trainingVersions)) {
      logger.warn(trainingVersions);
      setLoading(false);
      return trainingVersions;
    }

    const dropdownOptions: DropDownOption<API.TrainingVersion>[] = [];
    await Promise.all(
      trainingVersions.result.map(async item => {
        const isVersionPractical = await API.isPracticalTrainingVersion(item[0].id);
        if (!isMounted.current) return;
        if (!isVersionPractical) {
          dropdownOptions.push({
            key: item[0].id,
            label: item[1].name,
            value: item[0],
          });
        }
      }),
    );
    if (!isMounted.current) return;

    setTrainingVersionDropdownOptions(_.sortBy(dropdownOptions, eachOption => eachOption.label));
    setLoading(false);
  }

  function _handleModalClose() {
    handleModalClose(false);
    setTrainingVersion(undefined);
    setDuration(undefined);
    setScheduledStartDate(undefined);
    setMentors(undefined);
    setWorkers([]);
    setDescription(undefined);
    setSkillTags([]);
    setScheduledStartDate(undefined);
    setLocation(undefined);
    setTrainerComment(undefined);
    setTrainerFiles([]);
  }

  function handleAddProof() {
    setShowAddTrainingProofModal(true);
  }

  return (
    <>
      {showAddTrainingProofModal ? (
        <ProofBookContainerWeb
          trainingSessionId={trainingSessionId}
          setShowAddTrainingProofModal={setShowAddTrainingProofModal}
          disableProofAddition={!isValidPermission(API.Permission.trainingSessions_edit)}
          closeModalTrigger={_handleModalClose}
        />
      ) : null}
      {showModal ? (
        <TrainingSessionModalComponent
          trainingVersion={trainingVersion}
          trainingSession={trainingSession}
          trainingVersionDropdownOptions={trainingVersionDropdownOptions}
          loading={loading}
          workerDropdownOptions={workerDropdownOptions}
          skillTags={skillTags}
          scheduledStartDate={scheduledStartDate}
          duration={duration}
          mentors={mentors}
          workers={workers}
          location={location}
          description={description}
          trainerComment={trainerComment}
          trainerFiles={trainerFiles}
          onFilesChange={onTrainerFilesChange}
          handleAddProof={handleAddProof}
          handleDropdown={handleDropdown}
          onChange={onChange}
          handleInputText={handleInputText}
          handleModalClose={_handleModalClose}
          trainingDescription={trainingDescription}
        />
      ) : null}
    </>
  );
};
