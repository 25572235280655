import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { Spacings } from 'shared/styles';

const styles = StyleSheet.create({
  rootContainer: {},
  textInputContainer: {
    width: 360,
    height: 36,
    backgroundColor: SharedStyles.Colors.White,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Spacings.Small,
    justifyContent: 'space-between',
    borderRadius: 100,
  },
  iconContainer: {
    flexDirection: 'row',
  },
  filterText: {
    color: SharedStyles.Colors.Grey,
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Regular,
    paddingLeft: Spacings.Small,
  },
  menuContainer: {
    position: 'absolute',
    backgroundColor: SharedStyles.Colors.White,
    width: 500,
    shadowColor: SharedStyles.Colors.ModalBackground,
    shadowOpacity: 1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 3 },
    borderRadius: Spacings.xMedium,
  },
  inputText: {},
  firstSection: {
    paddingLeft: Spacings.CardPadding,
    paddingTop: 10,
  },
  inputTag: {
    width: 500,
    borderTopEndRadius: Spacings.xMedium,
    borderTopStartRadius: Spacings.xMedium,
    zIndex: 1,
  },
  bookmarkContainer: {
    minHeight: 112,
    zIndex: 0,
  },
  bookmarkEmptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bookmarkNotEmptyContainer: {
    flexDirection: 'row',
    paddingLeft: Spacings.CardPadding,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: Spacings.Small,
  },
  bookmarkIconContainer: {
    height: Spacings.Standard,
    width: Spacings.Standard,
    borderRadius: Spacings.Standard / 2,
    backgroundColor: SharedStyles.Colors.Background,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bookmarkName: {
    fontSize: SharedStyles.fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoRegular,
    color: SharedStyles.Colors.Black,
    paddingLeft: Spacings.xMedium,
  },
  bookmarkListFirstSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bookmarkRootContainer: {
    paddingBottom: Spacings.xMedium,
  },
  bookmarkTitle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Small,
    color: SharedStyles.Colors.MediumGray,
    padding: Spacings.CardPadding,
    paddingBottom: Spacings.Standard,
  },
  bookmarkEmptyText: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Regular,
    color: SharedStyles.Colors.Grey,
  },
  dropdownContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: Spacings.Large,
    borderTopWidth: 1,
    borderColor: SharedStyles.Colors.GreyLight,
  },
  dropdownStyle: {
    width: 220,
    marginLeft: Spacings.CardPadding,
  },
  checkboxContainer: {
    marginBottom: Spacings.Medium,
    marginLeft: Spacings.CardPadding,
    zIndex: -1,
  },
  eachCheckBox: {
    marginBottom: Spacings.CardPadding,
  },
  checkBoxText: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Regular,
    marginLeft: Spacings.Small,
  },
  tagView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 100,
    paddingLeft: Spacings.Small,
    paddingRight: Spacings.Unit,
    paddingVertical: Spacings.Unit,
    backgroundColor: SharedStyles.Colors.Background,
    marginRight: Spacings.Unit,
    height: Spacings.Standard,
    maxWidth: 420,
  },
  tagText: {
    color: SharedStyles.Colors.Black,
    marginLeft: 5,
    fontSize: SharedStyles.fontSize.Small,
    maxWidth: '95%',
    ...SharedStyles.TextFontStyles.LatoRegular,
  },
  toolTipText: {
    color: SharedStyles.Colors.Black,
    marginLeft: 5,
    fontSize: SharedStyles.fontSize.Regular,
    maxWidth: '80%',
    ...SharedStyles.TextFontStyles.LatoRegular,
  },
  tagContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  trashIcon: {
    paddingRight: Spacings.xMedium,
  },
  tooltipContainer: {
    top: Spacings.Standard,
    right: -Spacings.Big + Spacings.Unit,
  },
  keywordTag: {
    backgroundColor: SharedStyles.Colors.White,
    borderWidth: 1,
    borderColor: SharedStyles.Colors.GreyLighter,
  },
  ellipsisContainer: {
    width: 250,
  },
});
export default styles;
