import { StyleSheet } from 'react-native';
import { Spacings, Colors, TextFontStyles, fontSize, ModalStyles } from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: '50%',
    backgroundColor: Colors.Background,
    borderBottomLeftRadius: 8,
    maxHeight: 444,
    paddingTop: 64,
    paddingHorizontal: 77,
  },
  trainingIcon: {
    paddingBottom: Spacings.Standard,
  },
  proofBookComplete: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  proofBookCompleteTitle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.MediumLighterGrey,
    textAlign: 'center',
  },
  proofBookCompleteDescription: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.MediumLighterGrey,
    textAlign: 'center',
  },
  addCommonProofButtonContainer: {
    paddingTop: Spacings.Big,
  },
  addCommonProofText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Small,
  },
  addCommonProofContainer: {
    height: Spacings.Standard,
  },
});

export default styles;
