import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import Aigle from 'aigle';
import { v4 as uuidv4 } from 'uuid';
import logger from './Logger';
import { AsyncLock } from '../util-ts/AsyncLock';

export enum IndexedAssignmentState {
  /**
   * Assignment as it exists in the database
   */
  DEFAULT,

  /**
   * Deleted Assignment
   */
  TO_DELETE,

  /**
   * Role AND/OR Permissions updated for the Assignment
   */
  UPDATE,

  /**
   * OU updated for the Assignment
   */
  UPDATE_OU,

  /**
   * Assignment input to be created
   */
  TO_CREATE,
}

export interface IndexedAssignment extends Omit<AssignmentWithUnitDetails, 'role'> {
  uuid?: string;
  state?: IndexedAssignmentState;
  role?: API.Role;
}

export interface AssignmentWithUnitDetails extends Omit<Assignment, 'organizationalUnitId'> {
  organizationalUnit: API.OrganizationalUnit;
}

export interface Assignment {
  organizationalUnitId: string;
  shift?: API.Shift;
  permissions: API.Permission[];
  role: API.Role;
  inherited?: boolean;
  originObjectId?: string | null;
  workerId: string;
}

const defaultIndexedAssignment: Omit<IndexedAssignment, 'uuid'> = {
  organizationalUnit: {
    __typename: 'OrganizationalUnit',
    pathIds: [],
    id: '',
    parentId: '',
    order: 0,
    name: '',
    updatedAt: '',
    updatedBy: '',
  },

  inherited: false,
  permissions: [],
  state: IndexedAssignmentState.TO_CREATE,
  shift: undefined,
  role: undefined,
  workerId: '',
};

export const getDefaultIndexedAssignment = (): IndexedAssignment => {
  return { ...API.deepClone(defaultIndexedAssignment), uuid: uuidv4() };
};

export interface WorkerWithAssignments extends API.Worker {
  assignment: API.Assignment[];
}

export function isEmptyAssignment(assignment: API.IndexedAssignment): boolean {
  return (
    assignment.state === API.IndexedAssignmentState.TO_CREATE &&
    !assignment.organizationalUnit.id &&
    !assignment.permissions
  );
}

export async function loadUserRoleRelations(): Promise<API.Result<WorkerWithAssignments[]>> {
  const workers = await API.getWorkers();
  if (API.isFailure(workers)) return workers;

  const workersAssignmentsMap = await API.getWorkersAssignments(
    true,
    false,
    workers.result.map(worker => worker.id),
  );
  if (API.isFailure(workersAssignmentsMap)) return workersAssignmentsMap;

  const workersMap = new Map<string, API.Worker>();
  workers.result.forEach(worker => {
    workersMap.set(worker.id, worker);
  });

  const result: WorkerWithAssignments[] = [];
  for (const [workerId, assignments] of workersAssignmentsMap) {
    const workerRoleUnitInfo = { ...workersMap.get(workerId)!, assignment: assignments };
    result.push(workerRoleUnitInfo);
  }

  return result;
}

export async function getRole(
  roleId: string,
  networkCall?: boolean,
): Promise<API.Result<API.Role>> {
  const factory = networkCall
    ? await API.getFactoryBusinessObject(API.DataType.ROLE, roleId, 'network-no-cache')
    : await API.getFactoryBusinessObject(API.DataType.ROLE, roleId);
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.role,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function getRoles(): Promise<API.Result<API.Role[]>> {
  const factories = await API.listFactoriesWithDataType(API.DataType.ROLE);
  if (API.isFailure(factories)) return factories;

  return _.map(factories.result, factory => {
    return {
      ...factory.role!,
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    };
  });
}

function isArrayOfWorkerIds(array: API.Worker[] | string[]): array is string[] {
  return typeof array[0] === 'string';
}
/**
 * Get the map of Workers and their OrganizationalUnitRoles.
 * Available filters: OrganizationalUnits, Permissions.
 * Returned map is safe you can use postfix '!' on the passsed workerIds:
 * const organizationalUnitRoles = workersOrganizationalUnitRolesMap.get(workerId)!
 * @param includeInherited if true, include the inherited assignments (generated by the backend). It is equivalent to nested OrganizationalUnits
 * @param includeDetails if true, includes the details (organizationalUnit) of the assignment
 * @param workerIds (optional) if set, returns only the assignments for the given workers
 * @param organizationalUnitIds (optional) if set, returns only the assignments for the given organizationalUnits
 * @param permissions (optional) if set, returns only the assignments that have all the given permissions
 * @param roleId (optional) if set, returns only the assignments that have all the given roleId
 */
export async function getWorkersAssignments(
  includeInherited: boolean,
  includeDetails: false,
  workerIds?: string[],
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<Map<string, Assignment[]>>>;
export async function getWorkersAssignments(
  includeInherited: boolean,
  includeDetails: true,
  workerIds?: string[],
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<Map<string, AssignmentWithUnitDetails[]>>>;
export async function getWorkersAssignments(
  includeInherited: boolean,
  includeDetails: true,
  workers?: API.Worker[],
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<Map<string, AssignmentWithUnitDetails[]>>>;
export async function getWorkersAssignments(
  includeInherited: boolean,
  includeDetails: false,
  workers?: API.Worker[],
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<Map<string, Assignment[]>>>;
export async function getWorkersAssignments<T extends boolean>(
  includeInherited: boolean,
  includeDetails: T,
  workerIdsOrWorkers?: string[] | API.Worker[],
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<Map<string, T extends false ? Assignment[] : AssignmentWithUnitDetails[]>>> {
  const assignmentsMap = new Map<
    string,
    T extends false ? Assignment[] : AssignmentWithUnitDetails[]
  >();

  let workers: API.Worker[] = [];
  if (workerIdsOrWorkers?.length) {
    if (isArrayOfWorkerIds(workerIdsOrWorkers)) {
      await Aigle.map(workerIdsOrWorkers, async workerId => {
        const worker = await API.getWorker(workerId);
        if (API.isFailure(worker)) {
          logger.warn(worker);
          return;
        }
        workers.push(worker);
      });
    } else {
      workers.push(...workerIdsOrWorkers);
    }
  } else {
    const _workers: API.Worker[] = [];
    const factories = await API.listFactoriesWithDataType(API.DataType.WORKER, undefined);
    if (API.isFailure(factories)) return factories;

    _.forEach(factories.result, factory => {
      if (
        factory.worker!.state !== API.WorkerState.ARCHIVED &&
        API.checkWorkerMatchTheStatusFilter(
          factory.worker!,
          API.WorkersStatus.AllWorkersExceptInvitedByEmailUsersWithoutProfileName,
        )
      ) {
        _workers.push({
          ...factory.worker,
          updatedAt: factory.updatedAt,
          updatedBy: factory.updatedBy,
        });
      }
    });

    workers = _workers;
  }

  await Aigle.forEach(workers, async worker => {
    const workerAssignment = await getAssignmentsFromScope(
      includeInherited,
      includeDetails,
      worker.scope,
      worker.id,
      organizationalUnitIds,
      permissions,
    );
    if (API.isFailure(workerAssignment)) {
      logger.warn(workerAssignment);
      return;
    }

    if (workerAssignment.length) {
      assignmentsMap.set(
        worker.id,
        workerAssignment as T extends false ? Assignment[] : AssignmentWithUnitDetails[],
      );
    }
  });

  return assignmentsMap;
}

/**
 * Get the Assignments of the specified Worker.
 * @param workerIdOrWorker
 * @param includeInherited if true, includes the inherted Assignments (descendants generated by the backend)
 * @param includeDetails if true, includes the details (organizationalUnit) to the assignment
 * @param organizationalUnitIds (optional) if set, returns only the Assignments for the given organizationalUnits
 * @param permissions (optional) if set, returns only Assignments that have all the given permissions
 * @param roleId (optional) if set returns only Assignments that have the given roleId
 *  @param excludeDetails
 */
export async function getWorkerAssignments(
  workerId: string,
  includeInherited: boolean,
  includeDetails: false,
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<Assignment[]>>;
export async function getWorkerAssignments(
  workerId: string,
  includeInherited: boolean,
  includeDetails: true,
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<AssignmentWithUnitDetails[]>>;
export async function getWorkerAssignments(
  worker: API.Worker,
  includeInherited: boolean,
  includeDetails: true,
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<AssignmentWithUnitDetails[]>>;
export async function getWorkerAssignments(
  worker: API.Worker,
  includeInherited: boolean,
  includeDetails: false,
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<Assignment[]>>;
export async function getWorkerAssignments(
  workerIdOrWorker: string | API.Worker,
  includeInherited: boolean,
  includeDetails: boolean,
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<AssignmentWithUnitDetails[] | Assignment[]>> {
  const workersAssignmentsMap = await getWorkersAssignments(
    includeInherited,
    includeDetails as boolean as any, 
    [workerIdOrWorker] as string[] | API.Worker[] as any, 
    organizationalUnitIds,
    permissions,
  );
  if (API.isFailure(workersAssignmentsMap)) return workersAssignmentsMap;

  return workersAssignmentsMap.get(
    typeof workerIdOrWorker === 'string' ? workerIdOrWorker : workerIdOrWorker.id,
  )!;
}

export async function getWorkerRoles(
  workerId: string,
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<API.Role[]>>;
export async function getWorkerRoles(
  worker: API.Worker,
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<API.Role[]>>;
export async function getWorkerRoles(
  workerIdOrWorker: string | API.Worker,
  organizationalUnitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<API.Role[]>> {
  const workersAssignments = await getWorkerAssignments(
    workerIdOrWorker as string | API.Worker as any, 
    false,
    false,
    organizationalUnitIds,
    permissions,
  );
  if (API.isFailure(workersAssignments)) return workersAssignments;

  const roles: API.Role[] = [];
  workersAssignments.forEach(assignment => {
    roles.push(assignment.role);
  });
  return _.uniqBy(roles, 'id');
}

export function handleAddIndexedAssignment(orgUnitRoles: IndexedAssignment[]): IndexedAssignment[] {
  const _currentDropDowns: IndexedAssignment[] = [...orgUnitRoles];
  _currentDropDowns.unshift(getDefaultIndexedAssignment());

  return _currentDropDowns;
}

export function mergePermissions(
  permissions1: API.Permission[],
  permissions2: API.Permission[],
): API.Permission[] {
  const result = [...permissions1];
  for (const permission2 of permissions2) {
    if (!permissions1.includes(permission2)) {
      result.push(permission2);
    }
  }
  return result;
}


/**
 *This function gets the assignment based on the specific scope
 * @param scope
 * @param unitIds
 * @param includeInherited
 */
export async function getAssignmentsFromScope<T extends boolean>(
  includeInherited: boolean,
  includeDetails: T,
  scope: string,
  workerId: string,
  unitIds?: string[],
  permissions?: API.Permission[],
): Promise<API.Result<Assignment[] | AssignmentWithUnitDetails[]>> {
  const assignments: Assignment[] = [];
  const assignmentsWithDetails: AssignmentWithUnitDetails[] = [];

  const workerScope: API.Scope = JSON.parse(scope);
  await Aigle.map(Object.entries(workerScope.nonInheritedRolesOnOrgUnits), async assignment => {
    const unitAndShiftId = assignment[0];

    const permissionsAndRoleOrOriginObject = assignment[1];
    let workerPermissions = [];
    let roleId = '';

    workerPermissions = permissionsAndRoleOrOriginObject.permissions;
    roleId = permissionsAndRoleOrOriginObject.roleId;

    if (permissions && !permissions.every(item => workerPermissions.includes(item))) return;

    const role = await getRole(roleId);
    if (API.isFailure(role)) {
      logger.warn(role);
      return;
    }

    const orgUnitId = API.extractOrgUnitIdFromScopeKey(unitAndShiftId);
    const shiftId = API.extractShiftIdFromScopeKey(unitAndShiftId);

    let shift: API.Shift | undefined;
    if (shiftId) {
      const _shift = await API.getShift(shiftId);
      if (API.isFailure(_shift)) {
        logger.warn(_shift);
        return;
      }

      shift = _shift;
    }

    if (includeDetails) {
      const organizationalUnit = API.getOrganizationalUnit(orgUnitId);
      if (API.isFailure(organizationalUnit)) {
        logger.warn(organizationalUnit);
        return;
      }

      if (unitIds) {
        const unitId = unitIds.find(unitId => unitAndShiftId.includes(unitId));
        if (!unitId) return;

        const assignmentWithDetails = {
          organizationalUnit: organizationalUnit,
          permissions: workerPermissions,
          role: role,
          inherited: false,
          shift: shift,
          workerId: workerId,
        };
        assignmentsWithDetails.push(assignmentWithDetails);
      } else {
        const assignmentWithDetails = {
          organizationalUnit: organizationalUnit,
          permissions: workerPermissions,
          role: role,
          inherited: false,
          shift: shift,
          workerId: workerId,
        };

        assignmentsWithDetails.push(assignmentWithDetails);
      }
    } else {
      if (unitIds) {
        const unitId = unitIds.find(unitId => unitAndShiftId.includes(unitId));
        if (!unitId) return;

        const assignment = {
          organizationalUnitId: orgUnitId,
          permissions: workerPermissions,
          role: role,
          inherited: false,
          shift: shift,
          workerId: workerId,
        };
        assignments.push(assignment);
      } else {
        const assignment = {
          organizationalUnitId: orgUnitId,
          permissions: workerPermissions,
          role: role,
          inherited: false,
          shift: shift,
          workerId: workerId,
        };
        assignments.push(assignment);
      }
    }
  });

  if (includeInherited)
    await Aigle.map(Object.entries(workerScope.inheritedRolesOnOrgUnits), async assignment => {
      const unitAndShiftId = assignment[0];

      const originObjectId = assignment[1];
      let workerPermissions: API.Permission[] = [];
      let roleId = '';

      const originObject = workerScope.nonInheritedRolesOnOrgUnits[originObjectId];
      if (!originObject) return;

      workerPermissions = originObject.permissions;
      roleId = originObject.roleId;

      if (permissions && !permissions.every(item => workerPermissions.includes(item))) return;

      const role = await getRole(roleId);
      if (API.isFailure(role)) {
        logger.warn(role);
        return;
      }

      const orgUnitId = unitAndShiftId.split(API.SeparatorIds)[0];
      const shiftId = unitAndShiftId.split(API.SeparatorIds)[1];

      let shift: API.Shift | undefined;
      if (shiftId) {
        const _shift = await API.getShift(shiftId);
        if (API.isFailure(_shift)) {
          logger.warn(_shift);
          return;
        }

        shift = _shift;
      }

      if (includeDetails) {
        const organizationalUnit = API.getOrganizationalUnit(orgUnitId);
        if (API.isFailure(organizationalUnit)) {
          logger.warn(organizationalUnit);
          return;
        }

        if (unitIds) {
          const unitId = unitIds.find(unitId => unitAndShiftId.includes(unitId));
          if (!unitId) return;

          const assignmentWithDetails = {
            organizationalUnit: organizationalUnit,
            permissions: workerPermissions,
            role: role,
            inherited: true,
            shift: shift,
            workerId: workerId,
            originObjectId: originObjectId,
          };
          assignmentsWithDetails.push(assignmentWithDetails);
        } else {
          const assignmentWithDetails = {
            organizationalUnit: organizationalUnit,
            permissions: workerPermissions,
            role: role,
            inherited: true,
            shift: shift,
            workerId: workerId,
            originObjectId: originObjectId,
          };

          assignmentsWithDetails.push(assignmentWithDetails);
        }
      } else {
        if (unitIds) {
          const unitId = unitIds.find(unitId => unitAndShiftId.includes(unitId));
          if (!unitId) return;

          const assignment = {
            organizationalUnitId: orgUnitId,
            permissions: workerPermissions,
            role: role,
            inherited: true,
            shift: shift,
            workerId: workerId,
            originObjectId: originObjectId,
          };
          assignments.push(assignment);
        } else {
          const assignment = {
            organizationalUnitId: orgUnitId,
            permissions: workerPermissions,
            role: role,
            inherited: true,
            shift: shift,
            workerId: workerId,
            originObjectId: originObjectId,
          };
          assignments.push(assignment);
        }
      }
    });

  return includeDetails ? assignmentsWithDetails : assignments;
}
