import React, { useState, useEffect, useContext } from 'react';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import { RouteComponentProps } from 'react-router-dom';
import { TableRow } from 'shared/ui-component/Table';
import { getExportDataForSkills } from 'shared/util/ExcelUtils';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { SkillsTable } from '../components/SkillsTable';
import { ModalUtils } from 'shared/ui-component/Modal';
import { View, InteractionManager } from 'react-native';
import Styles from '../components/SkillsTable/Styles';
import { t } from 'shared/localisation/i18n';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import {
  removeInvalidObjectFromUserPreference,
  extractFilterTags,
} from '../../../header-layout/headerFilterConfig';
import { MyHub } from 'shared/util/MyHub';
import { MyFactoryContext } from '../../MyFactoryContext';
import { deleteSkillWithErrorModalHandling } from 'shared/util/skillUi';
import { runAfterInteractions } from 'shared/util-ts/React';

export interface SkillItemRow extends TableRow {
  skill: API.Skill;
  skillTags?: API.SkillTag[];
  workers?: API.Worker[];
  workstations?: API.Workstation[];
  trainingVersionTrainings?: API.TrainingVersionAndTraining[];
  isPractical: boolean;
  validityDurationAndExpiryNoticeDuration: API.SkillValidityDurationAndExpiryNoticeDuration;
}

export const SkillsLibrary: React.FC<RouteComponentProps> = props => {
  const { skillTableData } = useContext(MyFactoryContext);
  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);
  const {
    skillScreenFilterTags: [skillScreenFilterTags, setSkillScreenFilterTags],
    currentRoute: [, setCurrentRoute],
  } = useContext(HeaderFilterContext);

  const [rows, setRows] = useState<SkillItemRow[]>([]);
  const [filterTags, setFilterTags] = useState<TagExtended[]>([]);

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.SKILL) {
        runAfterInteractions(async () => {
          await fetchRows(true);
        }, 50);
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await fetchRows();
      if (!isMounted.current) return;

      setFirstTitle(t('alex:header.skillsCatalog'));
      setSecondTitle(t('glossary:skill_plural', {}, false));
      setCurrentRoute(RoutePaths.Skills);

      const skillResult = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.SkillCatalogFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(skillResult)) {
        logger.warn('fetch skill filter: error in saving user Preference', skillResult);
        return;
      }
      if (skillResult) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.SkillCatalogFilter,
          skillResult ?? [],
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.SkillCatalogFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save skill filter: error while saving user Preference', saved);
          return;
        }
        setSkillScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.SkillCatalogFilter) ?? [],
        );
      }
    });

    return () => {
      setCurrentRoute(undefined);
      removeListener();
    };
  }, []);

  useEffect(() => {
    setFilterTags(extractFilterTags(skillScreenFilterTags));
  }, [skillScreenFilterTags]);

  /**
   * Fetch more SkillItems.
   */
  async function fetchRows(forceReload: boolean = false): Promise<void> {
    if (!forceReload && skillTableData.length) {
      setRows(skillTableData);
      return;
    }

    const _skills = await API.getSkills();
    if (!isMounted.current) return;
    if (API.isFailure(_skills)) {
      logger.warn(_skills);
      return;
    }

    const newSkillItems: SkillItemRow[] = await Promise.all(
      _.map(_skills.result, async skill => {
        const _validityAndExpiry = await API.getSkillValidityDurationAndExpiryNoticeDuration(skill);
        return {
          key: skill.id,
          skillTags: undefined,
          skill,
          worker: undefined,
          workstation: undefined,
          trainingVersionTrainings: undefined,
          isPractical: skill.isPractical,
          validityDurationAndExpiryNoticeDuration: _validityAndExpiry,
        };
      }),
    );

    setRows(newSkillItems);
  }

  async function deleteSkillItem(skillItem: SkillItemRow): Promise<void> {
    if (!isMounted.current) return;

    const deletedSkill = await deleteSkillWithErrorModalHandling(skillItem.skill, modal);
    if (!isMounted.current) return;
    if (deletedSkill === false) {
      return;
    }

    setRows(_.filter(rows, row => row.skill.id !== skillItem.skill.id));
  }

  return (
    <View style={Styles.tableOuterContainer}>
      <SkillsTable
        rows={rows}
        filterTags={filterTags}
        fetchRows={fetchRows}
        getExportData={filteredRows => getExportDataForSkills(filteredRows.map(item => item.skill))}
        deleteSkillItem={deleteSkillItem}
      />
    </View>
  );
};
