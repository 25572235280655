import React from 'react';
import { Text, StyleProp, TextStyle, TextProps } from 'react-native';
import { TextFontStyles } from 'shared/styles';
import * as _ from 'lodash-es';
import { replaceDiacriticsAndCapitalLetter } from 'shared/util-ts/Functions';

interface Props {
  text: string;
  highlightedString?: string | undefined;
  style?: StyleProp<TextStyle> | undefined;
  highlightedTextStyle?: StyleProp<TextStyle> | undefined;
  textProps?: TextProps | undefined;
}

const _TextHighLight: React.FC<Props> = props => {
  const highlightText = replaceDiacriticsAndCapitalLetter(props.highlightedString ?? '');

  const splitedTexts = props.highlightedString
    ? replaceDiacriticsAndCapitalLetter(props.text).split(highlightText)
    : [];

  
  let startingIndex = 0;

  return (
    <Text {...props.textProps} style={props.style}>
      {splitedTexts.length ? (
        splitedTexts.map((part, i) => {
          if (i !== 0)
            startingIndex = startingIndex + splitedTexts[i - 1].length + highlightText.length;

          
          const endingIndex = startingIndex + part.length;

          return (
            <>
              <Text key={i}>{props.text.substring(startingIndex, endingIndex)}</Text>
              {i < splitedTexts.length - 1 && (
                <Text key={part} style={TextFontStyles.LatoBlack}>
                  {props.text.substring(endingIndex, endingIndex + highlightText.length)}
                </Text>
              )}
            </>
          );
        })
      ) : (
        <Text>{props.text}</Text>
      )}
    </Text>
  );
};

export const TextHighLight = React.memo(_TextHighLight, (prevProps, nextProps) =>
  _.isEqual(nextProps, prevProps),
);
