
import './index.css';
import './App.css';
import './header.css';
import './login.css';
import './activity.css';
import './add_collabrators.css';
import 'react-datepicker/dist/react-datepicker.css';

import logger from 'shared/util/Logger';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'shared/localisation/i18n';
import WebModal from 'modal-react-native-web';
import { BrowserRouter } from 'react-router-dom';

const appElement = document.getElementById('root');
document.title = 'Alex';
if (!appElement) {
  logger.error('The DOM shall have a root element');
} else {
  WebModal.setAppElement('#' + appElement.id);

  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    appElement,
  );
  
  
}
