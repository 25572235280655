import React, { useState, useEffect, useContext } from 'react';
import { View, TouchableOpacity, Text, ScrollView, InteractionManager } from 'react-native';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import WebModal from 'modal-react-native-web';
import { IconSVG } from 'shared/ui-component/Icon';
import { Styles } from './Styles';
import { DropDownSingleSelection, DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { Colors, Spacings } from 'shared/styles';
import * as API from 'shared/backend-data';
import {
  DropDownWithWorkerLevelOption,
  DropDownWithWorkerLevel,
} from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';
import { YellowButton } from 'shared/ui-component/Button';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash-es';
import {
  fixNoSkillSetForWorkstation,
  fixTrainingNotSet,
} from 'shared/components/workstation-worker-level/container';
import { TreeNode } from 'shared/backend-data';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import { useIsMounted } from 'shared/hooks/IsMounted';
import {
  ModalBackgroundStyle,
  ModalCardStyle,
  ModalAnchorContainerStyle,
  ModalAnchorStyle,
} from 'shared/styles/ModalStyles';
import { ModalHeader } from 'shared/ui-component/Modal/Header';
import { TrainingCard } from 'shared/layout/cards/training/training-card/training-card/container';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Checkbox } from 'shared/ui-component/Checkbox';
import Aigle from 'aigle';
import { ModalUtils } from 'shared/ui-component/Modal';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import logger from 'shared/util/Logger';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';

const ICON_WARNING = require('shared/assets/svg/icon.warning.svg').default;
const WorkerIcon = require('shared/assets/svg/icon.worker.svg').default;
const ICON_TRAINING = require('shared/assets/svg/icon.training.svg').default;
interface Props {
  openModal: boolean;
  treeNodeId?: string;
  workerId?: string;
  trainingLevel?: API.WorkstationWorkerLevels;
  workerOptions: DropDownWithWorkerLevelOption[];
  workstationOrOrgUnitOptions: Tag<TreeNode>[];
  levelOptions: DropDownWithWorkerLevelOption[];
  requiredTrainings: TrainingOption[] | undefined;
  skillIdsWithoutTrainingSet: string[];
  selectedTreeNode?: DropDownOption;
  setSelectedTreeNode: (value?: DropDownOption) => void;
  handleModalClose: () => void;
  getTrainings: (
    workerId?: string,
    workstationId?: string,
    level?: API.WorkstationWorkerLevels,
  ) => void;
  trainWorker: (
    workerId: string,
    workstationId: string,
    level: API.WorkstationWorkerLevels,
    selectedTrainingVersions?: string[],
  ) => void;
  hideWorkerLevel?: boolean;
  originatedFromDashboard?: boolean;
}

export interface TrainingOption {
  training: API.Training;
  trainingVersion: API.TrainingVersion;
  isPractical: boolean;
}

export const TrainingModalComponent: React.FC<Props> = props => {
  const {
    treeNodeId,
    selectedTreeNode,
    workerId,
    trainingLevel,
    workerOptions,
    workstationOrOrgUnitOptions,
    levelOptions,
    requiredTrainings,
    openModal,
    skillIdsWithoutTrainingSet,
    originatedFromDashboard,

    setSelectedTreeNode,
    getTrainings,
    handleModalClose,
  } = props;

  const history = useHistory();

  const isMounted = useIsMounted();
  const modal = ModalUtils.useModal();

  const [selectedWorker, setSelectedWorker] = useState<DropDownWithWorkerLevelOption>();
  const [selectedLevel, setSelectedLevel] = useState<DropDownWithWorkerLevelOption>();
  const [isHover, setIsHover] = useState(false);
  const [scrollViewMaxHeight, setScrollViewMaxHeight] = useState<number>(100);
  const [workerIsManager, setWorkerIsManager] = useState<boolean>(false);

  const [skillsrequired, setSkillsrequired] = useState<API.Skill[]>([]);

  const [selectedTrainingVersionIds, setSelectedTrainingVersionIds] = useState<string[]>(
    new Array(),
  );

  const ScrollViewRef = useCallOnHover<ScrollView>(
    Colors.Transparent,
    () => setIsHover(true),
    () => setIsHover(false),
  );

  const { isValidPermission } = useContext(PermissionManagementContext);
  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);

  useEffect(() => {
    if (workerId) {
      setSelectedWorker(_.find(workerOptions, worker => worker.key === workerId));
    }
  }, [workerId, workerOptions]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (!workerId && !isValidPermission(API.Permission.trainingSessions_edit)) {
        const currentUser = await API.getWorker();
        if (!isMounted.current) return;
        if (API.isFailure(currentUser)) return currentUser;
        setSelectedWorker(_.find(workerOptions, worker => worker.key === currentUser.id));
      }
    });
  }, [workerOptions]);

  useEffect(() => {
    if (trainingLevel) {
      setSelectedLevel(_.find(levelOptions, option => option.level === trainingLevel));
    }
    if (requiredTrainings) {
      const requiredVersionIds = requiredTrainings.map(_requiredTrainings => {
        return _requiredTrainings.trainingVersion.id;
      });
      setSelectedTrainingVersionIds(requiredVersionIds);
    }
  }, [trainingLevel, levelOptions, requiredTrainings]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (treeNodeId) {
        const _treeNode = await API.getWorkstationOrOrganizationalUnit(treeNodeId);
        if (!isMounted.current) return;
        if (API.isFailure(_treeNode)) return _treeNode;

        setSelectedTreeNode({ key: _treeNode.id, label: _treeNode.name });
      }
    });
  }, [treeNodeId]);

  useEffect(() => {
    getTrainings(selectedWorker?.key, selectedTreeNode?.key, selectedLevel?.level);
  }, [selectedWorker, selectedTreeNode, selectedLevel]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const skills: API.Skill[] = [];
      await Aigle.map(skillIdsWithoutTrainingSet, async skillId => {
        const skill = await API.getSkill(skillId);
        if (!isMounted.current) return;
        if (API.isFailure(skill)) {
          return;
        }
        skills.push(skill);
      });
      if (!isMounted.current) return;
      setSkillsrequired(skills);
    });
  }, [skillIdsWithoutTrainingSet]);

  function onTrainWorkerClick() {
    if (requiredTrainings) {
      if (
        selectedTrainingVersionIds.length !== requiredTrainings.length ||
        skillIdsWithoutTrainingSet.length !== 0
      ) {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: t('alex:workstationWorkerLevelMenu.trainWorkerToLevel'),
            warningAcceptCallback: () => trainWorker(),
            warningAcceptButton: capitalizeFirstLetter(t('common:button.yes')),
            warningCancelButton: capitalizeFirstLetter(t('common:button.no')),
          }),
        );
      } else {
        trainWorker();
      }
    } else {
      logger.info('There is no required trainings');
    }
  }

  function trainWorker() {
    if (selectedWorker && selectedTreeNode && selectedLevel && selectedTrainingVersionIds)
      props.trainWorker(
        selectedWorker.key,
        selectedTreeNode.key,
        selectedLevel.level,
        selectedTrainingVersionIds,
      );
  }

  function handleOnChange(status: boolean | null, trainingVersionId: string) {
    const _selectedTrainingVersionIds = [...selectedTrainingVersionIds];
    if (status) {
      if (!_selectedTrainingVersionIds.includes(trainingVersionId))
        setSelectedTrainingVersionIds([..._selectedTrainingVersionIds, trainingVersionId]);
    } else {
      var index = _selectedTrainingVersionIds.indexOf(trainingVersionId);
      if (index > -1) {
        _selectedTrainingVersionIds.splice(index, 1);
      }
      setSelectedTrainingVersionIds(_selectedTrainingVersionIds);
    }
  }

  function onWorkstationPress(id: string, isSkillNotSet?: boolean) {
    const _treeNode = API.Tree.getTreeNode(id);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }

    setTreeNode(_treeNode);
    if (isSkillNotSet) {
      fixNoSkillSetForWorkstation(id, history);
    } else {
      fixTrainingNotSet(id, history);
    }
  }

  async function handleTreeNode(_: string, item?: DropDownOption) {
    setSelectedTreeNode(item);

    if (item) {
      let _orgUnit;
      const dataType = API.getDataType(item.key);

      if (dataType === API.DataType.WORKSTATION) {
        const orgUnit = await API.getOrgUnitOfWorkstation(item.key);
        if (!isMounted.current) return;
        if (API.isFailure(orgUnit)) {
          logger.warn(orgUnit);
          return;
        }

        _orgUnit = orgUnit;
      } else if (dataType === API.DataType.ORGUNIT) {
        _orgUnit = item.value;
      }

      const _isWorkerManager = await API.isWorkerManager(_orgUnit);
      if (!isMounted.current) return;
      if (API.isFailure(_isWorkerManager)) {
        logger.warn(_isWorkerManager);
        return;
      }

      setWorkerIsManager(_isWorkerManager);
    }
  }

  return (
    <WebModal visible={openModal} animationType="fade" transparent>
      <View style={ModalBackgroundStyle}>
        <View style={ModalCardStyle}>
          <ModalHeader
            handleModalClose={() => handleModalClose()}
            title={
              originatedFromDashboard
                ? t('alex:TrainingModal.modalHeader.0')
                : t('alex:TrainingModal.modalHeader.1')
            }
          />
          <View style={[Styles.upperContainer]}>
            {/** LEFT PANEL */}
            <View style={[Styles.leftContainer]}>
              <View style={[ModalAnchorContainerStyle]}>
                <View style={ModalAnchorStyle}>
                  <IconSVG svgComponent={ICON_TRAINING} color={Colors.White} />
                </View>
              </View>
              <View style={[Styles.leftInputContainer]}>
                {/** TreeNode */}
                <DropDownSingleSelection
                  options={workstationOrOrgUnitOptions}
                  notEditable={!!treeNodeId}
                  placeholder={t('alex:TrainingModal.chooseWorkstationOrUnit')}
                  title={
                    selectedTreeNode &&
                    API.getDataType(selectedTreeNode.key) === API.DataType.WORKSTATION
                      ? t('glossary:workstation')
                      : t('glossary:organizationalUnitAbbreviated')
                  }
                  searchPlaceholder={t('alex:TrainingModal.chooseWorkstationOrUnit')}
                  hasSearch
                  containerStyle={[Styles.dropdownWorkstationContainer]}
                  inputListContainerStyle={Styles.inputListContainer}
                  value={selectedTreeNode}
                  handleChange={handleTreeNode}
                />
                {selectedTreeNode && (
                  <View>
                    {/** Worker */}
                    <DropDownWithWorkerLevel
                      options={workerOptions}
                      notEditable={
                        !!workerId || !isValidPermission(API.Permission.trainingSessions_edit)
                      }
                      title={t('glossary:worker')}
                      placeholder={t('alex:TrainingModal.chooseWorker')}
                      searchPlaceholder={t('alex:TrainingModal.chooseWorker')}
                      containerStyle={[Styles.dropdownWorkerContainer]}
                      inputListContainerStyle={Styles.inputListWorkerContainer}
                      value={selectedWorker}
                      hasSearch
                      handleChange={(_, item) => {
                        setSelectedWorker(item);
                      }}
                      noResultMessage={t('alex:TrainingModal.noResult')}
                      hideWorkerLevel={props.hideWorkerLevel}
                      listIcon={WorkerIcon}
                    />
                    {/** Level */}
                    <View style={[Styles.levelContainer]}>
                      <Text style={Styles.levelText}>{t('alex:TrainingModal.selectLevel')}</Text>
                      <DropDownWithWorkerLevel
                        options={levelOptions}
                        notEditable={!!trainingLevel}
                        placeholder={t('common:button.select')}
                        containerStyle={Styles.dropdownSmallContainer}
                        value={selectedLevel}
                        hasLevelOnly
                        handleChange={(_, item) => {
                          if (item) {
                            setSelectedLevel(item);
                          }
                        }}
                      />
                    </View>
                  </View>
                )}
              </View>
            </View>

            {/** RIGHT PANEL */}
            <View
              style={[Styles.rightContainer]}
              onLayout={e => {
                setScrollViewMaxHeight(e.nativeEvent.layout.height * 0.69);
              }}
            >
              {selectedLevel && selectedWorker && selectedWorker.level > selectedLevel.level ? (
                <View style={Styles.trainingHeaderView}>
                  <Text style={Styles.trainingHeaderText}>
                    {t('alex:TrainingModal.workerAlreadyGainedLevel') + ' '}
                    <Text style={Styles.trainingHeaderTextBlack}>
                      {API.getWorkstationWorkerLevelLabel(selectedWorker.level).toLowerCase()}
                    </Text>
                  </Text>
                </View>
              ) : (
                requiredTrainings && (
                  <View>
                    <View style={Styles.trainingHeaderView}>
                      <Text style={Styles.trainingHeaderText}>
                        {(requiredTrainings.length === 0
                          ? t('common:button.none')
                          : requiredTrainings.length) +
                          ' ' +
                          (!requiredTrainings.length && !skillIdsWithoutTrainingSet.length
                            ? t('glossary:skill', undefined, false)
                            : requiredTrainings.length > 1
                            ? t(
                                'glossary:training_plural',
                                { count: requiredTrainings.length },
                                false,
                              )
                            : t('glossary:training', { count: requiredTrainings.length }, false))}
                      </Text>
                      <Text style={Styles.trainingHeaderText}>
                        {t('alex:TrainingModal.toUpgrade', undefined, false) + ' '}
                        <Text style={Styles.trainingHeaderTextBlack}>
                          {API.getWorkstationWorkerLevelLabel(selectedLevel?.level).toLowerCase()}
                        </Text>
                      </Text>
                    </View>

                    <ScrollView
                      ref={ScrollViewRef}
                      showsVerticalScrollIndicator={isHover}
                      style={[Styles.scrollViewContainer, { maxHeight: scrollViewMaxHeight }]}
                    >
                      {!requiredTrainings.length && !skillIdsWithoutTrainingSet.length && (
                        <TouchableOpacity
                          onPress={() => {
                            handleModalClose();
                            selectedTreeNode && onWorkstationPress(selectedTreeNode.key, true);
                          }}
                          style={{ paddingTop: Spacings.xMedium, paddingRight: 80 }}
                        >
                          <Text style={Styles.createTrainingTextStyle}>
                            {t('alex:TrainingModal.createSkillOnWorkstation')}
                          </Text>
                        </TouchableOpacity>
                      )}
                      {skillsrequired.map(skill => {
                        return (
                          <View key={skill.id}>
                            <TouchableOpacity
                              onPress={() => {
                                handleModalClose();
                                selectedTreeNode && onWorkstationPress(selectedTreeNode.key);
                              }}
                              style={Styles.createTrainingContainer}
                            >
                              <IconSVG
                                svgComponent={ICON_WARNING}
                                containerStyle={{
                                  marginRight: Spacings.Small,
                                }}
                                color={Colors.Red}
                              />
                              <Text
                                style={[Styles.trainingNameText, { marginTop: Spacings.Medium }]}
                              >
                                {t('alex:TrainingModal.trainingMissing', {
                                  name: skill.name,
                                })}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        );
                      })}

                      {requiredTrainings.map(trainingOption => {
                        return (
                          <View key={trainingOption.training.id} style={{ flexDirection: 'row' }}>
                            <Checkbox
                              initialState={selectedTrainingVersionIds.includes(
                                trainingOption.trainingVersion.id,
                              )}
                              onChange={status =>
                                handleOnChange(status, trainingOption.trainingVersion.id)
                              }
                              style={{ marginTop: 35, marginRight: 10 }}
                            />
                            <TrainingCard
                              key={trainingOption.training.id}
                              trainingVersionAndTraining={[
                                trainingOption.trainingVersion,
                                trainingOption.training,
                              ]}
                              containerStyle={{
                                width: '91%',
                                marginBottom: Spacings.Small,
                              }}
                            />
                          </View>
                        );
                      })}
                    </ScrollView>
                  </View>
                )
              )}
            </View>
          </View>
          {/**
           *
           */}
          {requiredTrainings &&
            isValidPermission(API.Permission.trainingSessions_edit, undefined, [
              selectedWorker?.key,
            ]) && (
              <View style={[Styles.buttonContainer]}>
                <YellowButton
                  style={Styles.trainButton}
                  text={
                    originatedFromDashboard ? t('common:button.train') : t('common:button.save')
                  }
                  textStyle={Styles.buttonTextStyle}
                  onPress={onTrainWorkerClick}
                  disabled={
                    !(
                      selectedWorker &&
                      selectedTreeNode &&
                      selectedLevel &&
                      selectedTrainingVersionIds
                    )
                  }
                />
              </View>
            )}
        </View>
      </View>
    </WebModal>
  );
};
