import { StyleSheet } from 'react-native-web';
import { TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  eachColumn: {
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableText: {
    textAlign: 'left',
    fontSize: fontSize.Regular,
    color: '#606060',
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
  },
  collabBadge: {
    position: 'absolute',
    top: -2,
    left: 30,
  },
  ellipsisText: {
    paddingLeft: Spacings.Standard,
    width: '90%',
  },
});

export default styles;
