import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import NetInfo, { NetInfoState } from '@react-native-community/netinfo';
import { t } from 'shared/localisation/i18n';
import { ModalUtils } from 'shared/ui-component/Modal';

const isPlatformWeb = Platform.OS === 'web';

/**
 * Hook used to display an error message for the user when a server errors occur
 */
export function useServerAndConnectionWarnings() {
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [isInternetReachable, setIsInternetReachable] = useState<boolean>(true);
  const networkStateRef = React.useRef<boolean>(true);
  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const [lowConnectivity, setLowConnectivity] = useState<boolean>(false);
  const modal = ModalUtils.useModal();

  useEffect(() => {
    const removeNetInfoListener = NetInfo.addEventListener(handleFirstConnectivityChange);
    return () => {
      removeNetInfoListener();
    };
  }, []);

  useEffect(() => {
    if (!isPlatformWeb) {
      if ((lowConnectivity || !isConnected) && !acknowledged) {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: lowConnectivity
              ? t('common:serverAndConnectionWarnings.connectivity.lowConnection')
              : t('common:serverAndConnectionWarnings.connectivity.noConnection'),
            warningAcceptButton: t('common:button.retry'),
            warningAcceptCallback: handleAcknowledge,
          }),
        );
      }
    }
  }, [isConnected, acknowledged]);

  const handleFirstConnectivityChange = (state: NetInfoState) => {
    setIsInternetReachable(Boolean(state.isInternetReachable!));

    if (state.type === 'cellular') {
      if (state.details.cellularGeneration === '2g') {
        setLowConnectivity(true);
      }
    }
  };

  useEffect(() => {
    networkStateRef.current = isInternetReachable;
    setTimeout(() => {
      setIsConnected(networkStateRef.current);
      if (Boolean(networkStateRef.current) !== isConnected && acknowledged) {
        setAcknowledged(false);
      }
    }, 3000);
  }, [isInternetReachable]);

  const handleAcknowledge = () => {
    setAcknowledged(true);
  };
}
