import React, { useEffect, useState, useContext, useRef } from 'react';
import { InteractionManager, Platform } from 'react-native';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { logger } from 'shared/util/Logger';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { MyHub } from 'shared/util/MyHub';
import { TrainingSessionCardMobile } from './TrainingSessionCardMobile';
import { TrainingSessionCardWeb } from './TrainingSessionCardWeb';
import { doesWorkerHavePermissionOnAllParticipants } from 'shared/util/TrainingSession';
import { wait } from 'shared/util-ts/Functions';

const isMobilePlatform = Platform.OS === 'ios' || Platform.OS === 'android';

interface Props {
  trainingSessionWithDetail: API.TrainingSessionWithDetail;
  disabled?: boolean;

  onScheduleTraining?: (
    trainingSession: API.TrainingSessionWithDetail,
    traineeDetails: API.WorkerWorkstationDetails[],
    mentorDetails?: API.WorkerWorkstationDetails[],
  ) => void;
  onStartOrAddProofTraining?: (
    trainingSession: API.TrainingSessionWithDetail,
    traineeDetails: API.WorkerWorkstationDetails[],
    mentorDetails?: API.WorkerWorkstationDetails[],
  ) => void;
  onValidateTrainingProof?: (
    trainingSession: API.TrainingSessionWithDetail,
    traineeDetails: API.WorkerWorkstationDetails[],
    mentorDetails?: API.WorkerWorkstationDetails[],
  ) => void;
  onAddProofTraining?: (trainingSessionId: string) => void;
  onCardPress: (
    trainingSession: API.TrainingSessionWithDetail,
    traineeDetails?: API.WorkerWorkstationDetails[],
    mentorDetails?: API.WorkerWorkstationDetails,
  ) => void;
}

export const TrainingSessionCard: React.FC<Props> = props => {
  const {
    trainingSessionWithDetail,
    disabled,

    onValidateTrainingProof,
    onScheduleTraining,
    onStartOrAddProofTraining,
    onAddProofTraining,
    onCardPress,
  } = props;

  const isMounted = useIsMounted();

  const [loader, setLoader] = useState<boolean>(false);
  const [mustBePlanned, setMustBePlanned] = useState<boolean>(false);
  const [startTrainingSession, setStartTrainingSession] = useState<boolean>(false);
  const [validateProofBundle, setValidateProofBundle] = useState<boolean>(false);
  const [addProofBundle, setAddProofBundle] = useState<boolean>(false);
  const [isTrainingPractical, setIsTrainingPractical] = useState<boolean>(false);
  const [mentorsDetails, setMentorsDetails] = useState<API.WorkerWorkstationDetails[]>();
  const [traineeDetails, setTraineeDetails] = useState<API.WorkerWorkstationDetails[]>([]);
  const [canUserManageTrainingSession, setCanUserManageTrainingSession] = useState<boolean>(true);

  const { isValidPermission } = useContext(PermissionManagementContext);

  const currentTrainingSession = useRef<API.TrainingSession>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await wait(100);
      if (!isMounted.current) return;

      await checkIsTrainingPractical();
      if (!isMounted.current) return;

      setCanUserManageTrainingSession(
        await doesWorkerHavePermissionOnAllParticipants(trainingSessionWithDetail.id),
      );

      clearStates();
      renderActionButton(trainingSessionWithDetail);
      fetchWorkerDetails();
      currentTrainingSession.current = trainingSessionWithDetail;
    });
  }, [trainingSessionWithDetail]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        currentTrainingSession.current &&
        data.factory.dataType === API.DataType.PROOFBUNDLE &&
        (data.tooManyMutations ||
          data.factory.proofBundle.originObjectId === currentTrainingSession.current.id)
      ) {
        renderActionButton(currentTrainingSession.current);
      }
    });

    return () => {
      removeListener();
    };
  }, []);

  async function checkIsTrainingPractical(): Promise<void> {
    const isPracticalTraining = await API.isPracticalTrainingVersion(
      trainingSessionWithDetail.trainingVersionId,
    );
    if (!isMounted.current) return;
    if (API.isFailure(isPracticalTraining)) return;
    setIsTrainingPractical(isPracticalTraining);
  }

  function clearStates() {
    setMustBePlanned(false);
    setStartTrainingSession(false);
    setValidateProofBundle(false);
    setAddProofBundle(false);
  }

  async function fetchWorkerDetails() {
    setLoader(true);

    const _trainees = await API.getWorkersWorkstationDetails(
      trainingSessionWithDetail.traineeIds.length
        ? trainingSessionWithDetail.traineeIds
        : trainingSessionWithDetail.scheduledTraineeIds,
      trainingSessionWithDetail.requirementId,
    );
    if (API.isFailure(_trainees)) {
      logger.warn(_trainees);
      setLoader(false);
      return;
    }

    setTraineeDetails(_trainees);

    const trainers = trainingSessionWithDetail.trainers.length
      ? trainingSessionWithDetail.trainers
      : trainingSessionWithDetail.scheduledTrainers;
    const _mentors = [];
    if (trainers) {
      for (const trainer of trainers) {
        const mentor = await API.getWorkerWorkstationDetail(
          trainer.trainerId,
          trainingSessionWithDetail.requirementId,
        );
        if (API.isFailure(mentor)) {
          logger.warn(mentor);
          setLoader(false);
          return;
        }
        _mentors.push(mentor);
      }
    }

    setMentorsDetails(_mentors);
    setLoader(false);
  }

  async function renderActionButton(trainingSession: API.TrainingSession) {
    if (!trainingSessionWithDetail) return;

    if (API.isTrainingSessionDraftOrRequestValidated(trainingSessionWithDetail)) {
      
      setMustBePlanned(true);
    } else if (
      API.getTrainingSessionState(trainingSessionWithDetail) ===
      API.TrainingSessionState.STARTING_LATE
    ) {
      setStartTrainingSession(true);
    } else if (API.isTrainingSessionLateEnd(trainingSessionWithDetail)) {
      setAddProofBundle(true);
    } else if (API.isTrainingSessionEndedOrEndedLate(trainingSessionWithDetail)) {
      setValidateProofBundle(true);
    }
  }

  function _onCardPress() {
    if (!loader && trainingSessionWithDetail) {
      if (!isMobilePlatform) {
        onCardPress(trainingSessionWithDetail);
      } else if (isMobilePlatform) {
        if (
          (API.isTrainingSessionScheduledOrLateStart(trainingSessionWithDetail) ||
            API.isTrainingSessionStartedOrStartedLate(trainingSessionWithDetail)) &&
          onStartOrAddProofTraining
        ) {
          onStartOrAddProofTraining(trainingSessionWithDetail, traineeDetails, mentorsDetails);
        } else if (
          mustBePlanned &&
          isValidPermission(API.Permission.trainingSessions_edit, undefined, [
            ...API.getTrainerIdsFromTraininersWithPercentage(
              API.deepClone(trainingSessionWithDetail?.trainers),
            ),
            ...trainingSessionWithDetail.traineeIds,
          ])
        ) {
          if (onScheduleTraining)
            onScheduleTraining(trainingSessionWithDetail, traineeDetails, mentorsDetails);
        } else if (
          startTrainingSession &&
          isValidPermission(API.Permission.trainingSessions_edit, undefined, [
            ...API.getTrainerIdsFromTraininersWithPercentage(
              API.deepClone(trainingSessionWithDetail?.trainers),
            ),
            ...trainingSessionWithDetail.traineeIds,
          ])
        ) {
          if (onStartOrAddProofTraining) {
            onStartOrAddProofTraining(trainingSessionWithDetail, traineeDetails, mentorsDetails);
          }
        } else if (
          (validateProofBundle || addProofBundle) &&
          isValidPermission(API.Permission.workersSkillProof_review)
        ) {
          if (onValidateTrainingProof) {
            onValidateTrainingProof(trainingSessionWithDetail, traineeDetails, mentorsDetails);
          }
        } else {
          onCardPress(trainingSessionWithDetail);
        }
      }
    }
  }

  function _onStartOrAddProofTraining(trainingSessionWithDetails: API.TrainingSessionWithDetail) {
    if (onStartOrAddProofTraining) {
      onStartOrAddProofTraining(trainingSessionWithDetails, traineeDetails, mentorsDetails);
    }
  }

  return (
    <>
      {!isMobilePlatform && trainingSessionWithDetail ? (
        <TrainingSessionCardWeb
          trainingSessionWithDetails={trainingSessionWithDetail}
          loader={loader}
          addProofBundle={addProofBundle}
          startTrainingSession={startTrainingSession}
          isTrainingPractical={isTrainingPractical}
          disabled={disabled}
          traineeDetails={traineeDetails}
          mentorsDetails={mentorsDetails}
          canWorkerMangeTrainingSession={canUserManageTrainingSession}
          onAddProofTrainingSession={onAddProofTraining}
          onStartTrainingSession={_onStartOrAddProofTraining}
          onCardPress={_onCardPress}
        />
      ) : isMobilePlatform && trainingSessionWithDetail ? (
        <TrainingSessionCardMobile
          trainingSessionWithDetails={trainingSessionWithDetail}
          loader={loader}
          mustBePlanned={mustBePlanned}
          startTrainingSession={startTrainingSession}
          validateProofBundle={validateProofBundle}
          addProofBundle={addProofBundle}
          isTrainingPractical={isTrainingPractical}
          disabled={disabled}
          traineeDetails={traineeDetails}
          mentorsDetails={mentorsDetails}
          canWorkerMangeTrainingSession={canUserManageTrainingSession}
          onCardPress={_onCardPress}
        />
      ) : (
        <></>
      )}
    </>
  );
};
