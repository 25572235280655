import { StyleSheet } from 'react-native';
import { Spacings, TextFontStyles, Colors, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  menuContainer: {
    position: 'absolute',
    right: -2,
    zIndex: 999,
  },
  backButtonContainer: {
    position: 'absolute',
    left: 0,
    zIndex: 999,
    marginLeft: Spacings.Small,
  },
  headerIconContainer: {
    width: '100%',
    marginTop: Spacings.Small,
    alignItems: 'center',
    marginBottom: Spacings.xMedium,
  },
  headerTitle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    marginBottom: 2,
    maxWidth: 200,
  },
  headerTitleContainer: {
    width: '100%',
    zIndex: 1,
  },
  subHeaderComponentTitle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
    textAlign: 'center',
    lineHeight: 20,
    zIndex: 1,
  },
  header: {
    zIndex: 2,
    alignItems: 'center',
  },
  bannerContentTitle: {
    fontSize: fontSize.XXXLarge,
    ...TextFontStyles.LatoBlack,
    alignSelf: 'flex-start',
    marginBottom: Spacings.Standard + Spacings.Unit,
  },
  bannerContentContainer: {
    marginTop: 50,
    paddingLeft: Spacings.Small,
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'hidden',
  },
  bannerContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  fileScrollContent: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  contentTitle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    marginBottom: 2,
  },
  contentSubTitle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  scrollContainer: {
    marginTop: 32,
  },
  filesContainer: {
    width: '94%',
  },
});

export default styles;
