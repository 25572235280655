import React, { useState, useEffect, useRef } from 'react';
import { View, Text } from 'react-native';
import styles from '../styles';
import { InputTag, Tag } from 'shared/ui-component/Input/InputList/InputTag';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown/DropDown';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { t } from 'shared/localisation/i18n';
import { InputList } from 'shared/ui-component/Input/InputList';
import { ModalUtils } from 'shared/ui-component/Modal';
import { FilterButton } from './FilterButton';
import { TagExtended, checkBoxConfig, HeaderFilter } from '../container';
import { Bookmark } from './Bookmark';
import * as _ from 'lodash-es';
import { Checkbox } from 'shared/ui-component/Checkbox';
import * as API from 'shared/backend-data';
import { constructShiftTagKey } from 'shared/layout/organization-unit/OrganizationUnit';
import { Colors } from 'shared/styles';

export enum FilterTagOrder {
  OrganizationUnit,
  Shift,
  Workstation,
  Worker,
  Skill,
  Training,
  WorkerTag,
  TrainingTag,
  SkillTag,
  TrainingType,
  Validity,
  Level,
  Polyvalence,
  Others,
}

const ShiftIcon = require('shared/assets/svg/icon.shift.svg').default;

interface CheckBoxValues {
  [key: string]: TagExtended;
}

export const FilterComponent: React.FC<HeaderFilter> = props => {
  const { filterConfig, tags, hideFilter, savedTags, disableFilter, onTagSelected, onSaveTags } =
    props;

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuHeight, setMenuHeight] = useState<number>(0);
  const [inputString, setInputString] = useState<string>('');
  const [showInputList, setShowInputList] = useState<boolean>(false);
  const [bookmarkTags, setBookmarkTags] = useState<TagExtended[]>([]);
  const [selectedTags, setSelectedTags] = useState<TagExtended[]>([]);
  const [isCreateBookmarkModalOpen, setIsCreateBookmarkModalOpen] = useState<boolean>(false);
  const [autoFocus, setAutoFocus] = useState<boolean>(true);
  const [checkBoxValues, setCheckBoxValues] = useState<CheckBoxValues>({});

  const inputTextRef = useRef<string>();

  const ref = useDetectOutsideClick<View>(() => {
    if (!isCreateBookmarkModalOpen) {
      onClickOutSide();
    }
  });

  const modal = ModalUtils.useModal();

  useEffect(() => {
    setBookmarkTags([]);
  }, [window.location.pathname]);

  useEffect(() => {
    const _tags = API.deepClone(savedTags) as TagExtended[];
    setSelectedTags([..._tags.filter(eachTag => !eachTag.isBookmarkTag && !eachTag.isCheckBoxTag)]);
    setBookmarkTags([..._tags.filter(eachTag => eachTag.isBookmarkTag)]);

    getCheckBoxValues(_tags);
    onTagSelected(_tags);
  }, [savedTags, showMenu]);

  function getCheckBoxValues(tags: TagExtended[]) {
    const _checkBoxValues: CheckBoxValues = {};
    tags
      .filter(eachTag => eachTag.isCheckBoxTag)
      .forEach(tag => {
        _checkBoxValues[tag.key] = tag;
      });
    setCheckBoxValues(_checkBoxValues);
  }

  function handleDropdownSingleSelect(key: string, tag?: TagExtended) {
    if (tag) {
      if (tag.value?.selectedShift) {
        const _tag: TagExtended = {
          key: constructShiftTagKey(tag.value.selectedShift.id, tag.value.id),
          label: `${tag.label} > ${tag.value.selectedShift.name}`,
          type: API.DataType.SHIFT,
          value: { parentId: tag.value.id, shiftId: tag.value.selectedShift.id },
          color: Colors.LightRed,
          order: FilterTagOrder.Shift,
        };

        setSelectedTags([...selectedTags, _tag]);
        onTagSelected([...selectedTags, _tag]);
      } else {
        setSelectedTags([...selectedTags, tag]);
        onTagSelected([...selectedTags, tag]);
      }
    }
  }

  function onClickOutSide() {
    if (showMenu) {
      const _tags = _.map(
        [...displayActiveTags(), ...Object.values(checkBoxValues)],
        tag => tag.value,
      );
      const _savedTags = _.map(
        savedTags.filter(eachTag => eachTag.isActiveBookmarkTag || !eachTag.isBookmarkTag),
        tag => tag.value,
      );

      if (!_.isEqual(_tags, _savedTags)) {
        _onSaveTags([...selectedTags, ...bookmarkTags, ...Object.values(checkBoxValues)]);
      }
    }
    setShowMenu(false);
    setBookmarkTags([]);
    setShowInputList(false);
    setInputString('');
  }

  function renderDropdown() {
    return filterConfig!.dropdown.map((eachDropdown, key) => {
      return (
        <DropDownSingleSelection
          key={eachDropdown.key}
          value={eachDropdown.value}
          options={eachDropdown.options}
          placeholder={t(eachDropdown.placeholder ?? '')}
          handleChange={(_, tag) => handleDropdownSingleSelect(eachDropdown.key, tag)}
          containerStyle={[styles.dropdownStyle, { zIndex: -key }]}
          inputListContainerStyle={eachDropdown.key === API.DataType.SHIFT && { width: 550 }}
          extraSelectorIcon={ShiftIcon}
          hasSearch
        />
      );
    });
  }

  function handleCheckBox(value: boolean | null, checkBox: checkBoxConfig) {
    const _value = {
      value: !!value,
      label: checkBox.label,
      key: checkBox.key,
      isCheckBoxTag: true,
    };
    const _checkBoxValues = {
      ...checkBoxValues,
      [checkBox.key]: _value,
    };

    setCheckBoxValues(_checkBoxValues);
  }

  function renderCheckBox() {
    return filterConfig!.checkBox!.map((checkBox, key) => (
      <View style={styles.eachCheckBox} key={key}>
        <Checkbox
          disabled={checkBox.disabled}
          text={checkBox.label}
          titleStyle={styles.checkBoxText}
          onChange={(value: boolean | null) => handleCheckBox(value, checkBox)}
          initialState={
            checkBoxValues && checkBoxValues[checkBox.key]
              ? checkBoxValues[checkBox.key].value
              : checkBox.value
          }
        />
      </View>
    ));
  }

  function handleInputTag(input: string) {
    if (input) {
      setShowInputList(true);
    }
    setInputString(input);
  }

  function handleInputListSelection(inputId: string, tags: TagExtended[]) {
    onTagSelected([...selectedTags, ...tags]);
    setSelectedTags([...selectedTags, ...tags]);
    setShowInputList(false);
    setInputString('');
  }

  function _onSaveTags(tags: TagExtended[], keepMenuOpen?: boolean) {
    const _tags = [
      ...bookmarkTags.filter(eachTag => !eachTag.isActiveBookmarkTag),
      ...tags,
      ...Object.values(checkBoxValues),
    ];

    onTagSelected(_tags);
    onSaveTags(_.uniqBy(_tags, 'key'));
    setIsCreateBookmarkModalOpen(false);
    setShowMenu(!!keepMenuOpen);
  }

  function inputTagChange(tags: TagExtended[], removedTag?: TagExtended) {
    if (removedTag && removedTag.isBookmarkTag) {
      const index = bookmarkTags.findIndex(eachTag => eachTag.key === removedTag.key);
      if (index > -1) {
        bookmarkTags[index].isActiveBookmarkTag = false;
      }
      setBookmarkTags([...bookmarkTags]);
    } else {
      setSelectedTags(tags.filter(eachTag => !eachTag.isBookmarkTag));
    }
  }

  function onBookmarkTagDelete(bookmarkTags: TagExtended[]) {
    setBookmarkTags([...bookmarkTags]);
    onSaveTags([...bookmarkTags, ...selectedTags, ...Object.values(checkBoxValues)]);
  }

  function onBookmarkTagSelect(bookmarkTags: TagExtended[]) {
    _onSaveTags([...bookmarkTags, ...Object.values(checkBoxValues)]);
  }

  function createBookmarkHandler(bookmarkLabel: string) {
    const _bookmarkTags: TagExtended[] = [
      ...bookmarkTags,
      {
        key: bookmarkLabel,
        label: bookmarkLabel,
        children: [...selectedTags, ...Object.values(checkBoxValues)],
        isBookmarkTag: true,
        isActiveBookmarkTag: true,
      },
    ];

    setBookmarkTags(_bookmarkTags);
    setSelectedTags([]);
    setIsCreateBookmarkModalOpen(false);
    onSaveTags(_bookmarkTags);
  }

  function handleBookmark(tags: TagExtended[]) {
    setIsCreateBookmarkModalOpen(true);
    modal.displayModal(
      ModalUtils.TagModalConfig({
        createHandler: (bookmarkLabel: string) => createBookmarkHandler(bookmarkLabel),
        label: t('common:filters.favoriteFilter'),
        placeholder: t('common:filters.enterName'),
      }),
    );
  }

  function displayActiveTags(): TagExtended[] {
    const tags = [...bookmarkTags, ...selectedTags];
    return tags.filter(eachTag => eachTag.isActiveBookmarkTag || !eachTag.isBookmarkTag);
  }

  function showBookmark(): boolean {
    if (bookmarkTags.some(eachTag => eachTag.isActiveBookmarkTag)) {
      return false;
    } else if (!selectedTags.length) {
      return false;
    }
    return true;
  }

  function onTyping(input?: string) {
    inputTextRef.current = input;

    if (!input) {
      setShowInputList(false);
      setInputString('');
      setAutoFocus(true);
    } else {
      setAutoFocus(false);
    }
  }

  function showFilterMenu() {
    setShowMenu(!showMenu);
    if (!showMenu) {
      setAutoFocus(true);
    }
  }

  function onPressEnter(tags?: Tag[]) {
    if (!inputTextRef.current) return;
    setInputString('');
    setShowInputList(false);

    const _tags = tags?.map(tag => {
      if (tag.type === API.DataType.SHIFT) {
        return {
          key: tag.key,
          label: tag.label,
          type: API.DataType.SHIFT,
          value: { parentId: tag.value.parentId, shiftId: tag.value.id },
          color: Colors.LightRed,
          order: FilterTagOrder.Shift,
        };
      }

      return tag;
    });

    const keywordTag: TagExtended = {
      key: inputTextRef.current,
      label: inputTextRef.current,
      children: _tags,
      isKeywordTag: true,
    };

    _onSaveTags([...bookmarkTags, ...selectedTags, keywordTag, ...Object.values(checkBoxValues)]);
  }

  if (!filterConfig) return null;
  return (
    <View style={styles.rootContainer}>
      {!hideFilter ? (
        <FilterButton
          disabled={disableFilter}
          showFilterMenu={showFilterMenu}
          tags={displayActiveTags()}
          onRemoveTag={tags => _onSaveTags(tags)}
        />
      ) : (
        <></>
      )}
      {showMenu && (
        <View
          style={styles.menuContainer}
          ref={ref}
          onLayout={event => {
            const { height } = event.nativeEvent.layout;
            setMenuHeight(height);
          }}
        >
          <InputTag
            saveOnDeleteTag
            placeholder={t('common:filters.filterData')}
            tagStyle={styles.tagView}
            style={styles.inputTag}
            autoFocus={autoFocus}
            textInputValue={''}
            selectedTags={displayActiveTags()}
            onTyping={handleInputTag}
            onChange={inputTagChange}
            onSave={_onSaveTags}
            bookmark={{
              showBookmark: showBookmark(),
              onSaveBookmark: handleBookmark,
            }}
          />
          <View style={styles.bookmarkContainer}>
            <Text style={styles.bookmarkTitle}>{t(filterConfig.filterPlaceholder)}</Text>
            <Bookmark
              tags={bookmarkTags}
              onBookmarkTagDelete={onBookmarkTagDelete}
              onBookmarkTagSelect={onBookmarkTagSelect}
            />
          </View>
          <View style={styles.dropdownContainer}>{filterConfig && renderDropdown()}</View>
          <View style={styles.checkboxContainer}>
            {filterConfig && filterConfig.checkBox && renderCheckBox()}
          </View>
          {showInputList && (
            <InputList
              initialInputText={inputString}
              placeholder={t('common:filters.filterData')}
              options={tags}
              containerStyle={{
                position: 'absolute',
                width: 500,
                height: menuHeight,
              }}
              singleSelection
              initialSelection={displayActiveTags()}
              handleSelection={handleInputListSelection}
              openInputList={setShowInputList}
              onTyping={onTyping}
              onPressEnter={onPressEnter}
              showSaveButton={true}
            />
          )}
        </View>
      )}
    </View>
  );
};
