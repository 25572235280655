import { StyleSheet, Platform } from 'react-native';
import { Colors, Spacings } from 'shared/styles';

const Style = StyleSheet.create({
  checkbox: {
    borderWidth: 1,
    backgroundColor: Colors.White,
    borderColor: Colors.Black,
    width: Platform.OS !== 'web' ? Spacings.CardPadding : Spacings.Medium,
    height: Platform.OS !== 'web' ? Spacings.CardPadding : Spacings.Medium,
    borderRadius: 4,
    flexDirection: 'row',
    marginRight: Spacings.Unit,
  },
  checkboxDisabled: {
    opacity: 0.5,
  },
  checkIcon: {
    padding: 2,
    backgroundColor: Colors.Black,
    borderColor: Colors.Black,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rootContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default Style;
