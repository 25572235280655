import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';

export default StyleSheet.create({
  trainingInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableNumber: {
    fontSize: fontSize.XLarge,
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
  },

  tableText: {
    textAlign: 'left',
    fontSize: fontSize.Regular,
    color: '#606060',
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
  },
  tableContainer: {
    marginLeft: Spacings.CardPadding,
    padding: 0,
    flex: 1,
  },
  tableOuterContainer: {
    flex: 1,
  },
  ellipsisText: {
    width: '80%',
  },
  trainingIconContainer: {
    backgroundColor: Colors.Yellow,
    borderRadius: (30 + 30) / 2,
    height: 30,
    width: 30,
    marginRight: Spacings.Unit + 1.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
