import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import * as API from 'shared/backend-data';
import { t } from 'shared/localisation/i18n';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import moment from 'moment';
import { ModalHeaderRegularTextStyle, ModalHeaderBoldTextStyle } from 'shared/styles/ModalStyles';
import { ModalHeaderText } from 'shared/ui-component/Modal/ModalHeaderText';
import { ProofBookType } from '../..';

interface Props {
  proofBundle?: API.NoMetadata<API.ProofBundle>;
  proofBookType?: ProofBookType;
}

export const ProofHeaderTitle: React.FC<Props> = props => {
  const { proofBundle, proofBookType } = props;

  const isMounted = useIsMounted();

  const [boldHeaderText, setBoldHeaderText] = useState<string>('');
  const [regularHeaderText, setRegularHeaderText] = useState<string>('');

  useEffect(() => {
    setBoldHeaderText(t('glossary:proof'));
    setRegularHeaderText(t('alex:worker.addSkillModal.title.0', undefined, false));
  }, []);

  useEffect(() => {
    getHeaderTitle();
  }, [proofBundle]);

  async function getHeaderTitle() {
    if (!proofBundle) return;

    const skill = await API.getSkill(proofBundle.skillId);
    if (!isMounted.current) return;
    if (API.isFailure(skill)) {
      logger.warn(skill);
      return;
    }
    const { validityDuration, expiryNoticeDuration } =
      await API.getSkillValidityDurationAndExpiryNoticeDuration(skill);

    if (
      proofBundle.review.state === API.ReviewState.REJECTED ||
      proofBundle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
    ) {
      setRegularHeaderText(t('alex:workerSkillReviewModal.disproved', undefined, false));
    } else if (proofBundle.review.state === API.ReviewState.TO_REVIEW) {
      setRegularHeaderText(t('alex:workerSkillReviewModal.toBeValidated', undefined, false));
    } else if (proofBundle.review.state === API.ReviewState.VALIDATED && skill) {
      if (!validityDuration) {
        setRegularHeaderText(t('alex:workerSkillReviewModal.valid', undefined, false));
      } else {
        const workerSkillExpiryDate = moment(proofBundle.startingDate).add(
          validityDuration,
          'months',
        );
        const days = workerSkillExpiryDate.diff(moment.utc(), 'days');

        if (days <= 0) {
          setRegularHeaderText(t('alex:workerSkillReviewModal.expired', undefined, false));
        } else if (!expiryNoticeDuration || days > expiryNoticeDuration) {
          setRegularHeaderText(t('alex:workerSkillReviewModal.valid', undefined, false));
        } else {
          setRegularHeaderText(t('alex:workerSkillReviewModal.expireSoon', undefined, false));
        }
      }
    }
  }

  return (
    <View>
      {proofBundle ? (
        <Text style={ModalHeaderRegularTextStyle}>
          <Text style={ModalHeaderBoldTextStyle}>{`${boldHeaderText} `}</Text>
          <Text> {`${regularHeaderText} `}</Text>
        </Text>
      ) : proofBookType === ProofBookType.TrainingBook ? (
        <ModalHeaderText
          title={t('alex:trainingSessions.addTrainingModal.practicalProofBook')}
          invert
        />
      ) : (
        <ModalHeaderText title={t('alex:proofBook.skillBook')} invert />
      )}
    </View>
  );
};
