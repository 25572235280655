import React, { useEffect, useState } from 'react';
import { InteractionManager, View, ViewStyle } from 'react-native';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { Styles } from './Styles';
import { AttachFile } from 'shared/ui-component/AttachFile/index';
import { Files } from './Files';

interface Props {
  trainingVersionId: string;
  style?: ViewStyle;
  iconOnly?: boolean;
}

export const TrainingVersionFiles: React.FC<Props> = props => {
  const { trainingVersionId, style } = props;
  const [s3Files, setS3Files] = useState<API.S3ObjectInput[]>([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const _training = await API.getTrainingForATrainingVersion(trainingVersionId);
      if (!isMounted.current) return;
      if (API.isFailure(_training)) return _training;

      setS3Files(API.deepClone(_training.files));
    });
  }, [trainingVersionId]);

  return <Files s3Files={s3Files} style={style} iconOnly={props.iconOnly}></Files>;
};
