import { Platform } from 'react-native'; 
import localForage from 'localforage';
import logger from '../util/Logger';
import { AppContext } from '../context/AppContext';
import * as API from 'shared/backend-data';
import Aigle from 'aigle';

export enum PersistentStorageKeys {
  LastSyncKey = 'lastSync', 
  LastClientVersionKey = 'lastClientVersion', 
  LastPurgeKey = 'lastPurge', 
  ForceBaseSyncAtNextSyncKey = 'forceBaseSyncAtNextSync', 
  ForceBaseSyncAtNextSyncNeededKey = 'forceBaseSyncAtNextSyncNeeded',
  
}

export type LocalForageTypeSuported =
  | Array<LocalForageTypeSuported>
  | ArrayBuffer
  | Blob
  | Float32Array
  | Float64Array
  | Int8Array
  | Int16Array
  | Int32Array
  | Number
  | Object 
  | Uint8Array
  | Uint8ClampedArray
  | Uint16Array
  | Uint32Array
  | String;

let storageDriver: LocalForageDriver | undefined = undefined;
export async function initStorageDriver(): Promise<void> {
  let driver: LocalForageDriver;
  if (Platform.OS === 'web') {
    driver = await localForage.getDriver(localForage.INDEXEDDB);
  } else {
    const { driverWithSerialization } = await import(
      '@aveq-research/localforage-asyncstorage-driver'
    );
    const serialization: LocalForageSerializer = {
      serialize,
      deserialize,
      stringToBuffer,
      bufferToString,
    };
    driver = driverWithSerialization(serialization); 
    await localForage.defineDriver(driver);
  }
  storageDriver = driver;
  logger.info('Storage driver initialized');
}

function serialize<T>(value: T, callback: (_value: string, error: any) => void) {
  
  function replacer(key: string, value: T) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()),
      };
    } else {
      return value;
    }
  }

  
  if (value instanceof Date) {
    callback(JSON.stringify({ dataType: 'Date', value: value }), null);
  } else {
    callback(JSON.stringify(value, replacer), null);
  }
}

function deserialize<T>(value: string): T {
  
  function reviver(key: string, value: any) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      } else if (value.dataType === 'Date') {
        return new Date(value.value);
      }
    }
    return value;
  }

  return JSON.parse(value, reviver);
}

function stringToBuffer(serializedString: string): ArrayBuffer {
  return new TextEncoder().encode(serializedString);
}

function bufferToString(buffer: ArrayBuffer): string {
  return new TextDecoder().decode(buffer);
}

/** One Storage Class combining web and mobile storage */
class _PersistentStorage {
  private static instance: _PersistentStorage;
  private storages: Map<string, LocalForage> = new Map();

  private initStorage(storageKey: string): LocalForage {
    if (!storageDriver) {
      throw new Error('Please call initStorageDriver() before accessing PersistentStorage');
    }

    return localForage.createInstance({
      name: storageKey,
      
      driver: storageDriver._driver,
    });
  }

  private _latestStorageKey: string = '';
  /**
   * Get or Create a storage for the given key.
   * If no key is specified the Context key is sued (@see AppContext.pk)
   * @param storageKey Optional, default Appcontext.pk
   */
  getOrCreateStorage(storageKey?: string): API.Result<LocalForage> {
    if (!storageKey) {
      const appContext = AppContext.getContext();
      if (API.isFailure(appContext)) return appContext;

      storageKey = appContext.pk;
    }

    if (!logger.isPROD && this._latestStorageKey !== storageKey) {
      logger.verbose('Active storage key changed to: ' + storageKey);
      this._latestStorageKey = storageKey;
    }

    let storage = this.storages.get(storageKey);
    if (!storage) {
      storage = this.initStorage(storageKey);
      this.storages.set(storageKey, storage);
    }

    return storage;
  }

  async deleteStorage(storageKey: string): Promise<void> {
    let storage = this.storages.get(storageKey);
    if (!storage) {
      logger.warn('Storage ' + storageKey + " doesn't exist");
      return;
    }

    await storage
      .dropInstance({
        name: storageKey,
      })
      .catch(error => {
        logger.error('Failed to purge data store storeName=' + storageKey, error);
      });
  }

  async deleteStorages(): Promise<void> {
    
    await Aigle.map(Object.keys(this.storages), storageKey => {
      this.deleteStorage(storageKey);
    });
  }

  /**
   * @param key
   * @param storageKey (Optional, default to AppContext.pk)
   */
  async getItem<V extends LocalForageTypeSuported>(
    key: string,
    storageKey?: string,
  ): Promise<V | null> {
    const storage = this.getOrCreateStorage(storageKey);
    if (API.isFailure(storage)) {
      logger.error('Cannot access current Storage in PersistentStorage.getItem', storage);
      return null;
    }

    return storage.getItem<V>(key);
  }

  /**
   * @param key
   * @param value
   * @param storageKey (Optional, default to AppContext.pk)
   */
  async setItem<V extends LocalForageTypeSuported>(
    key: string,
    value: V,
    storageKey?: string,
  ): Promise<API.Result<V>> {
    const storage = this.getOrCreateStorage(storageKey);
    if (API.isFailure(storage)) {
      logger.error(
        'Cannot access current Storage in PersistentStorage.setItem. Item ' +
          key +
          'will not be stored',
        storage,
        key,
        value,
      );
      return storage;
    }

    return storage.setItem<V>(key, value);
  }

  /**
   * @param key
   * @param storageKey (Optional, default to AppContext.pk)
   */
  async removeItem(key: string, storageKey?: string): Promise<void> {
    const storage = this.getOrCreateStorage(storageKey);
    if (API.isFailure(storage)) {
      logger.error('Cannot access current Storage in PersistentStorage.removeItem', storage);
      return;
    }

    return storage.removeItem(key);
  }

  static getInstance(): _PersistentStorage {
    if (!this.instance) {
      this.instance = new _PersistentStorage();
    }
    return this.instance;
  }
}

export const PersistentStorage = _PersistentStorage.getInstance();
