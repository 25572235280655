import React, { useEffect, useState } from 'react';
import { InteractionManager, View, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { Text } from 'react-native-web';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import styles from './styles';

interface Props {
  parentIdsWithDataType: [API.TreeDataType | API.DataType.SHIFT, readonly string[]] | undefined;
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: ViewStyle;
  maxWidth?: number;
}

export const WorkstationOrOrgUnitPath: React.FC<Props> = props => {
  const { parentIdsWithDataType, textStyle, containerStyle, maxWidth } = props;

  const isMounted = useIsMounted();

  const [pathNames, setPathNames] = useState<string[]>([]);
  const [dataType, setDataType] = useState<API.TreeDataType>();
  const [displayedPathNames, setDisplayedPathNames] = useState<string>('');

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (parentIdsWithDataType?.length) {
        fetchOrgUnitsAndSetPathNames(parentIdsWithDataType[0], parentIdsWithDataType[1]);
      }
    });
  }, [parentIdsWithDataType]);

  useEffect(() => {
    let _displayedPathNames = '';
    pathNames.forEach((pathName, index) => {
      _displayedPathNames += `${index !== 0 && index !== pathNames.length ? ' >' : ''} ${index !== pathNames.length - 1 ? pathName : ''
        }`;
    });
    setDisplayedPathNames(_displayedPathNames);
  }, [pathNames]);

  function fetchOrgUnitsAndSetPathNames(
    dataType: API.TreeDataType | API.DataType.SHIFT,
    parentIds: readonly string[],
  ): void {
    const isShift = dataType === API.DataType.SHIFT;
    const _dataType: API.TreeDataType = isShift ? API.DataType.ORGUNIT : dataType;
    let _parentIds = [...parentIds];
    if (isShift) {
      if (parentIds.length !== 1)
        logger.error('WorkstationOrOrgUnitPath => Shift DataType with parentIds.length > 1 ');

      const orgUnit = API.getOrganizationalUnit(parentIds[0]);
      if (API.isFailure(orgUnit)) {
        logger.warn(orgUnit);
        return;
      }

      _parentIds = [...orgUnit.pathIds];
    }

    const orgUnitNames: string[] = [];
    const rootUnit = API.Tree.getRootOrganizationalUnit();
    const _filteredParentIds = isShift
      ? [..._parentIds]
      : _parentIds.filter(parentId => parentId !== rootUnit?.id);

    _filteredParentIds.forEach(parentId => {
      const orgUnit = API.getOrganizationalUnit(parentId);
      if (!isMounted.current) return;
      if (API.isFailure(orgUnit)) {
        logger.warn(orgUnit);
        return;
      }

      orgUnitNames.push(orgUnit.name);
    });

    setDataType(_dataType);
    setPathNames(orgUnitNames);
  }

  
  
  return (
    <View style={[styles.pathContainer, containerStyle]}>
      <Text
        style={[styles.path, textStyle]}
        numberOfLines={1}
        ref={(element: { offsetWidth: number }) => {
          if (element && maxWidth && element.offsetWidth > maxWidth) {
            setDisplayedPathNames('... > ');
          }
        }}
      >
        {displayedPathNames}
        {pathNames[pathNames.length - 1] ? (
          <Text
            style={[
              styles.path,
              textStyle,
              dataType === API.DataType.WORKSTATION && styles.boldPath,
            ]}
          >
            {pathNames[pathNames.length - 1]}
          </Text>
        ) : null}
      </Text>
    </View>
  );
};
