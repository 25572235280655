import React, { useState } from 'react';
import { TouchableOpacity, View, StyleProp, ViewStyle, TextStyle } from 'react-native';
import styles from './style';
import { IconSVG } from 'shared/ui-component/Icon';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import { TextHighLight } from 'shared/ui-component/TextHighLight';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors } from 'shared/styles';
import { SvgProps } from 'react-native-svg';
import * as SharedStyles from 'shared/styles';
import { HoverableIcon } from 'shared/ui-component/HoverableIcon';
import { t } from 'shared/localisation/i18n';
import { WorkstationOrOrgUnitPath } from 'shared/components/WorkstationOrOrgUnitPath';
import * as API from 'shared/backend-data';
import { ToolTipWeb } from '../../../ToolTip/ToolTipWeb';

const editIcon = require('shared/assets/svg/icon.edit.svg').default;
const arrowDownIcon = require('shared/assets/svg/icon.arrowDown.mobile.svg').default;
const arrowRightIcon = require('shared/assets/svg/icon.arrowRight.mobile.svg').default;

export interface ItemRowStyling {
  itemContainerStyle?: StyleProp<ViewStyle>;
  rowStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

interface ListItemProps {
  item: Tag;
  showItemIcon: boolean;
  itemDepth: number;
  itemIcon?: React.FC<SvgProps>;
  extraSelectorIcon?: React.FC<SvgProps>;
  highlightedString?: string;
  itemRowStyling?: ItemRowStyling;
  disableOptions?: Tag[];
  showItemPath?: boolean;
  disabledExtraSelectors?: Tag[];

  onPress: (tag: Tag) => void;
  onListItemEdit?: ((tag: Tag) => void) | undefined;
  renderOption?: ((value: Tag | undefined) => JSX.Element) | undefined;
}

export const ListItem: React.FC<ListItemProps> = props => {
  const {
    item,
    itemDepth,
    highlightedString,
    itemRowStyling,
    disableOptions,
    showItemPath,
    disabledExtraSelectors,
    extraSelectorIcon,

    renderOption,
  } = props;

  const iconContainerSize = 32;

  const [rowOnHover, setIsRowOnHover] = useState(false);
  const [isNodeOpen, setIsNodeOpen] = useState<boolean>(true);

  let tagIsOu = false;

  function disableOption(): boolean {
    return !!(
      (item.isExtraSelector &&
        disabledExtraSelectors?.some(disableOption => disableOption.key === item.key)) ||
      disableOptions?.some(disableOption => disableOption.key === item.key) ||
      item.disabled
    );
  }

  const renderIcon = () => {
    return (
      props.itemIcon && (
        <IconSVG
          svgComponent={props.itemIcon}
          containerStyle={[styles.tagIconContainer, itemRowStyling?.iconContainerStyle]}
          color={item.isExtraSelector ? item.value.color : item.color ?? Colors.Black}
          size={{ width: SharedStyles.Spacings.Standard, height: SharedStyles.Spacings.Standard }}
        />
      )
    );
  };

  function getTextMaxWidth(
    renderOption?: (value: Tag | undefined) => JSX.Element,
    selectorOptions?: any[],
  ): string {
    if (renderOption) return '60%';

    if (selectorOptions?.length) {
      if (selectorOptions.length > 10) return '20%';

      const minimumSpacing = 16;
      const fullWidth = 100;
      const width = fullWidth - minimumSpacing - Math.ceil(selectorOptions.length / 2) * 10;
      const stringifiedWidth = width.toString() + '%';

      return stringifiedWidth;
    }

    return '100%';
  }

  const renderTagBody = () => {
    if (item.value && item.value.id) tagIsOu = API.isOrganizationalUnit(item.value);

    return (
      <View style={[itemRowStyling?.rowStyle, styles.tagRow]}>
        {tagIsOu && itemDepth !== 1 && (
          <TouchableOpacity onPress={() => setIsNodeOpen(!isNodeOpen)}>
            <IconSVG
              svgComponent={isNodeOpen ? arrowDownIcon : arrowRightIcon}
              size={{
                height: SharedStyles.Spacings.Standard,
                width: SharedStyles.Spacings.Standard,
              }}
              containerStyle={[styles.orgUnitIconContainer]}
            />
          </TouchableOpacity>
        )}
        {props.showItemIcon && renderIcon()}
        <View
          style={[
            styles.listItemInnerWrapper,
            renderOption && styles.listItemInnerWrapperWithRenderOption,
          ]}
        >
          <View style={styles.listItemTextWrapper}>
            <TextHighLight
              key={item.key}
              text={t(item.label)}
              highlightedString={highlightedString}
              style={[
                itemRowStyling?.textStyle,
                {
                  color: disableOption() ? SharedStyles.Colors.Grey : SharedStyles.Colors.Black,
                  maxWidth: getTextMaxWidth(
                    renderOption,
                    item.value?.extraSelector?.selectorOptions,
                  ),
                },
              ]}
            />

            <View style={styles.extraSelectorsWrapper}>
              {item.value &&
                extraSelectorIcon &&
                item.value.extraSelector?.selectorOptions.map((_extraSelectorOption: Tag) => {
                  const _disabledExtraSelector = disabledExtraSelectors?.find(
                    _disabledExtraSelector =>
                      _disabledExtraSelector.key === _extraSelectorOption.key,
                  );
                  const isDisabled = _disabledExtraSelector?.value.relatedListItemId === item.key;
                  return (
                    <View key={_extraSelectorOption.key}>
                      <TouchableOpacity
                        disabled={isDisabled}
                        style={[styles.extraSelectorTouchableWrapper]}
                        onPress={() => {
                          props.onPress({
                            ...item,
                            value: { ...item.value, selectedShift: _extraSelectorOption.value },
                          });
                        }}
                      >
                        <ToolTipWeb
                          title={_extraSelectorOption.label}
                          toolTipTextStyle={styles.toolTipTextStyle}
                          style={styles.toolTipContainer}
                          numberOfLines={1}
                          component={
                            <IconSVG
                              svgComponent={extraSelectorIcon}
                              color={isDisabled ? Colors.Grey : _extraSelectorOption.value.color}
                              containerStyle={isDisabled && styles.shadowedSelectedExtraSelector}
                              size={{ width: 28, height: 28 }}
                            />
                          }
                          isInnerHover
                        />
                      </TouchableOpacity>
                    </View>
                  );
                })}
            </View>
          </View>
          {showItemPath && (
            <WorkstationOrOrgUnitPath
              textStyle={styles.pathStyle}
              parentIdsWithDataType={item.tagPath}
            />
          )}
        </View>

        {rowOnHover && props.onListItemEdit && (
          <HoverableIcon
            size={{ width: SharedStyles.Spacings.Standard, height: SharedStyles.Spacings.Standard }}
            containerStyle={styles.editIconStyles}
            svgIcon={editIcon}
            hoverColor={SharedStyles.Colors.Black}
            dehoverColor={SharedStyles.Colors.Grey}
            onPress={() => {
              if (props.onListItemEdit) props.onListItemEdit(item);
            }}
          />
        )}
        {renderOption && <View style={styles.renderOptionContainer}>{renderOption(item)}</View>}
      </View>
    );
  };

  return (
    <View>
      <TouchableOpacity
        style={[
          styles.tagOptionContainer,
          itemRowStyling?.itemContainerStyle,
          {
            backgroundColor: rowOnHover
              ? SharedStyles.Colors.BlueRollover
              : SharedStyles.Colors.White,
          },
        ]}
        ref={useCallOnHover(
          SharedStyles.Colors.BlueRollover,
          () => {
            setIsRowOnHover(true);
          },
          () => {
            setIsRowOnHover(false);
          },
        )}
        onPress={() => {
          props.onPress(item.extraSelectorRelatedItem ?? item);
        }}
        disabled={disableOption()}
      >
        {renderTagBody()}
      </TouchableOpacity>

      {isNodeOpen &&
        item.children?.map(_item => {
          return (
            <ListItem
              item={_item}
              showItemIcon={props.showItemIcon}
              extraSelectorIcon={extraSelectorIcon}
              key={_item.key}
              itemDepth={itemDepth + 1}
              itemIcon={_item.tagIcon ?? props.itemIcon}
              highlightedString={highlightedString}
              onPress={props.onPress}
              itemRowStyling={{
                rowStyle: { marginLeft: iconContainerSize * (itemDepth - 1) },
              }}
              onListItemEdit={item.editable ? props.onListItemEdit : undefined}
              disableOptions={disableOptions}
              disabledExtraSelectors={disabledExtraSelectors}
              showItemPath={Boolean(item.tagPath)}
            />
          );
        })}
    </View>
  );
};
