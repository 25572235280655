import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card } from 'shared/ui-component/Card';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import styles, { getCardContainerStyle } from './style';
import { i18n, t } from 'shared/localisation/i18n';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors, Spacings } from 'shared/styles';
import { InputText } from 'shared/ui-component/Input';
import { OutlineButton } from 'shared/ui-component/Button';
import { MenuType, WorkstationWorker } from '../container/index';
import { TreeNode } from 'shared/backend-data/factoryCache/Tree';
import { useHistory } from 'react-router';
import { RouteLocations } from '../../../../../../navigation/Routes';
import { isOrganizationalUnit } from 'shared/util/Workstation';
import * as API from 'shared/backend-data';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { SkillBadge } from 'shared/ui-component/Badge/SkillBadge';
import { SkillBadgeStates } from 'shared/util/Skill';
import * as _ from 'lodash-es';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import { ModalUtils } from 'shared/ui-component/Modal';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { ImportExportComponent } from 'shared/ui-component/import-export-menu';
import { ExportReport, ImportExportType } from 'shared/util/ExcelUtils';
import { Workbook } from 'exceljs';
import {
  getExportDataForWorkstations,
  ImportExportFailureMessageKey,
  ImportExportFileNames,
} from 'shared/util/ExcelUtils';
import logger from 'shared/util/Logger';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { MenuCard } from 'shared/ui-component/Menu/MenuCard';
import { MenuItem } from 'shared/ui-component/Menu';
import { PlainLevelIcon } from 'shared/ui-component/Icon/LevelIcon/LevelIcon';

const ICON_WORKSTATION = require('shared/assets/svg/icon.workstation.svg').default;
const ICON_ORGUNIT = require('shared/assets/svg/icon.unit.svg').default;

interface NodeMenuContainerProps {
  
  
  openMenu: boolean;
  loadingChildren: boolean;
  orgUnitMenuItems: MenuItem[];
  menuType: MenuType;
  isStartingNode: boolean;
  selectedNode: TreeNode | undefined;
  selectedNodeWorkers: (API.Worker | WorkstationWorker)[];
  yCoordinate: number;
  preSelectedParentUnit: API.OrganizationalUnit;
  
  setOpenMenu: (openMenu: boolean) => void;
  setAddUnit: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUnitModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuType: (menuType: MenuType) => void;
  duplicateNode: (node: TreeNode) => void;
  deleteNode: (node: TreeNode) => void;
  onSubmitEditing: (inputValue: string) => void;
  inviteWorker: (worker: API.Worker) => void;
}

export const NodeMenuComponent: React.FC<NodeMenuContainerProps> = props => {
  const {
    selectedNode,
    selectedNodeWorkers,
    isStartingNode,
    yCoordinate,
    menuType,
    loadingChildren,
    orgUnitMenuItems,

    setShowUnitModal,
    setOpenMenu,
    setMenuType,
    onSubmitEditing,
    duplicateNode,
    deleteNode,
    setAddUnit,
  } = props;

  const arrowLeft = require('shared/assets/svg/icon.arrowLeft.mobile-2.svg').default;
  const trashIcon = require('shared/assets/svg/icon.trash.svg').default;
  const duplicateIcon = require('shared/assets/svg/icon.duplicate.svg').default;

  const hoverRef1 = useCallOnHover<TouchableOpacity>();
  const hoverRef2 = useCallOnHover<TouchableOpacity>();
  const history = useHistory();
  const modal = ModalUtils.useModal();
  const [nodeName, setNodeName] = useState<string>();
  const [showCard, setShowCard] = useState<boolean>(true);
  const { isValidPermission } = useContext(PermissionManagementContext);
  const { workstationTargetsStartingAtLevel, levelIconType } = useContext(GlobalDataContext);
  const disableDetectOutsideClick = useRef<boolean>(false);

  useEffect(() => {
    setNodeName(menuType === MenuType.NodeEditMenu ? selectedNode?.object.name : undefined);
  }, [selectedNode]);

  const refView = useDetectOutsideClick<View>(() => {
    
    setTimeout(() => {
      
      if (!disableDetectOutsideClick.current) setOpenMenu(false);
    }, 200);
  });

  function isWorkstationWorker(
    workerOrWorkstationWorker: API.Worker | WorkstationWorker,
  ): workerOrWorkstationWorker is WorkstationWorker {
    return (workerOrWorkstationWorker as WorkstationWorker).workerWorkstation != null;
  }

  async function getExportData(): Promise<ExportReport> {
    let treeNodeToExport = selectedNode;
    if (!treeNodeToExport) {
      const topTreeNodes = API.Tree.getTopTreeNodes();
      if (!topTreeNodes.length) {
        disableDetectOutsideClick.current = false;
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.TopOrgUnitInaccessible),
        );
      }
      treeNodeToExport = topTreeNodes[0];
    }

    const report = await getExportDataForWorkstations(
      treeNodeToExport,
      workstationTargetsStartingAtLevel,
    );

    disableDetectOutsideClick.current = false;

    return report;
  }

  function deleteTreeNode() {
    if (selectedNode) {
      deleteNode(selectedNode);
    }
  }

  function inviteWorker(worker: API.Worker) {
    if (selectedNode) {
      props.inviteWorker(worker);
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('alex:workstations.addMenu.workerInvited'),
          warningAcceptButton: t('common:button.yes'),
        }),
      );
    }
  }

  function exportToCSVConfirmed() {
    disableDetectOutsideClick.current = true;
  }

  function renderAddOptionsMenu() {
    return (
      <View>
        <TouchableOpacity
          ref={hoverRef1}
          style={styles.menuOptionContainer}
          onPress={() => {
            setMenuType(MenuType.AddOrgUnitMenu);
            setShowCard(false);
            setShowUnitModal(true);
            setAddUnit(true);
          }}
        >
          <View style={styles.rowView}>
            <IconSVG
              svgComponent={ICON_ORGUNIT}
              containerStyle={{ height: Spacings.Standard, width: Spacings.Standard }}
              size={{ height: Spacings.Standard, width: Spacings.Standard }}
            />
            <View style={styles.divider} />
            <View style={styles.flexView}>
              <Text style={styles.boldTextOption}>{t('alex:workstations.addMenu.addOrgUnit')}</Text>
              <Text style={[styles.textOption]} numberOfLines={5}>
                {t('alex:workstations.addMenu.addOrgUnitComment')}
              </Text>
            </View>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          ref={hoverRef2}
          style={styles.menuOptionContainer}
          onPress={() => setMenuType(MenuType.AddWorkstationMenu)}
        >
          <View style={styles.rowView}>
            <IconSVG
              svgComponent={ICON_WORKSTATION}
              containerStyle={{ height: Spacings.Standard, width: Spacings.Standard }}
              size={{ height: Spacings.Standard, width: Spacings.Standard }}
            />
            <View style={styles.divider} />
            <View style={styles.flexView}>
              <Text style={styles.boldTextOption}>
                {t('alex:workstations.addMenu.addWorkstation')}
              </Text>
              <Text style={[styles.textOption]} numberOfLines={5}>
                {t('alex:workstations.addMenu.addWorkstationComment')}
              </Text>
            </View>
          </View>
        </TouchableOpacity>

        <>
          <ImportExportComponent
            importExportMenuType={ImportExportType.Workstation}
            getExportData={getExportData}
            fileName={ImportExportFileNames.Workstations}
            menuOnly
            exportToCSVConfirmed={exportToCSVConfirmed}
          />
        </>
      </View>
    );
  }

  function renderAddNodeMenu() {
    return (
      <>
        <View style={styles.addNodeMenuHeader}>
          {menuType !== MenuType.NodeEditMenu && (
            <>
              <TouchableOpacity
                onPress={() => {
                  setMenuType(MenuType.AddOptionsMenu);
                }}
              >
                <IconSVG
                  svgComponent={arrowLeft}
                  size={{ height: Spacings.Standard, width: Spacings.Standard }}
                />
              </TouchableOpacity>
              <Text style={styles.headerMenuText}>{t('glossary:workstationDefault')}</Text>
            </>
          )}

          {menuType === MenuType.NodeEditMenu &&
            selectedNode &&
            !isStartingNode &&
            isValidPermission(API.Permission.workstations_edit, selectedNode) && (
              <View style={styles.menuHeader}>
                <TouchableOpacity onPress={() => duplicateNode(selectedNode)}>
                  <IconSVG
                    svgComponent={duplicateIcon}
                    size={{ height: Spacings.Standard, width: Spacings.Standard }}
                    color={Colors.Grey}
                    containerStyle={{ marginRight: 14 }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={deleteTreeNode}>
                  <IconSVG
                    svgComponent={trashIcon}
                    size={{ height: 25, width: 25 }}
                    color={Colors.Grey}
                  />
                </TouchableOpacity>
              </View>
            )}
        </View>

        <ScrollView>
          <View style={styles.addMenuButton}>
            <InputText
              inputId="#input1"
              autoFocus
              label={t('common:account.name')}
              defaultValue={
                menuType === MenuType.NodeEditMenu ? selectedNode?.object.name : undefined
              }
              onTextChange={(_, text) => setNodeName(text)}
              onSubmitEditing={(_, text) => onSubmitEditing(text)}
              showSaveButton
              onSaveButtonClicked={text => onSubmitEditing(text)}
              placeHolder={t('alex:workstations.addMenu.enterNamer')}
              notEditable={!isValidPermission(API.Permission.workstations_edit, selectedNode)}
            />
          </View>
          {loadingChildren ? (
            <LoaderThreeDots />
          ) : selectedNodeWorkers.length > 0 && menuType === MenuType.NodeEditMenu ? (
            renderNodeWorkers()
          ) : (
            renderNodeRedirection()
          )}
        </ScrollView>

        {selectedNodeWorkers[0] &&
          menuType === MenuType.NodeEditMenu &&
          !isWorkstationWorker(selectedNodeWorkers[0]) && (
            <Text style={styles.adPhoneText}>{t('alex:workstations.addMenu.phoneAd')}</Text>
          )}
      </>
    );
  }

  function renderNodeRedirection() {
    return (
      <>
        {menuType === MenuType.NodeEditMenu && (
          <Text style={styles.headerMenuBodyText}>
            {selectedNode && API.isOrganizationalUnit(selectedNode.object)
              ? t('alex:workstations.addMenu.noWorkerTrainedToOrgUnit')
              : t('alex:workstations.addMenu.noWorkerTrainedToWorkstation')}
          </Text>
        )}
        <OutlineButton
          style={styles.outlineButton}
          text={
            menuType === MenuType.NodeEditMenu
              ? selectedNode && isOrganizationalUnit(selectedNode.object)
                ? t('common:button.add')
                : t('common:train')
              : t('common:button.save')
          }
          textStyle={styles.headerMenuButtonText}
          disabled={!nodeName}
          onPress={() => {
            menuType === MenuType.NodeEditMenu
              ? history.push(
                  selectedNode && isOrganizationalUnit(selectedNode.object)
                    ? RouteLocations.Workers()
                    : RouteLocations.Home(),
                )
              : nodeName && onSubmitEditing(nodeName);
          }}
        />
      </>
    );
  }

  function renderNodeWorkers() {
    return loadingChildren ? (
      <LoaderThreeDots />
    ) : (
      <View>
        {selectedNode && (
          <Text style={styles.headerMenuBodyText}>
            {API.isOrganizationalUnit(selectedNode?.object)
              ? t('alex:workstations.addMenu.workersTrainedToOrgUnit')
              : t('alex:workstations.addMenu.workersTrainedToWorkstation')}
          </Text>
        )}
        {selectedNodeWorkers.map(worker => {
          return isWorkstationWorker(worker) ? (
            <View key={worker.worker.id} style={styles.nodeWorkerContainer}>
              {worker.worker &&
                renderImage(worker.worker.profilePicture, ImageSizes.Small, worker.worker.name)}
              <SkillBadge badgeValue={SkillBadgeStates.OK} style={styles.badgeStyle} />
              <Text numberOfLines={1} style={styles.workerNameText}>
                {worker.worker.name}
              </Text>
              <View style={{ flex: 1 }} />
              <PlainLevelIcon
                level={worker.workerWorkstation.level ?? API.WorkstationWorkerLevel.LEVEL0}
                iconSize={{ width: Spacings.Standard, height: Spacings.Standard }}
                levelIconType={levelIconType}
              />
            </View>
          ) : (
            <View key={worker.id} style={styles.nodeWorkerContainer}>
              {worker && renderImage(worker.profilePicture, ImageSizes.Small, worker.name)}
              <SkillBadge badgeValue={SkillBadgeStates.OK} style={styles.badgeStyle} />
              <Text numberOfLines={1} style={styles.workerNameText}>
                {worker.name}
              </Text>
              <View style={{ flex: 1 }} />
              <TouchableOpacity
                disabled={!!worker.userId || !isValidPermission(API.Permission.workers_invite)}
                onPress={() => inviteWorker(worker)}
              >
                <Text style={styles.inviteWorkerText} numberOfLines={1}>
                  {worker.userId
                    ? t('alex:workstations.addMenu.workerInvited')
                    : t('alex:workstations.addMenu.inviteWorker')}
                </Text>
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
    );
  }

  return (
    <>
      {menuType === MenuType.NodeEditMenu &&
      selectedNode &&
      API.isTreeNode(API.DataType.ORGUNIT, selectedNode) ? (
        <View ref={refView}>
          <MenuCard
            menuItems={orgUnitMenuItems}
            setShowMenu={() => null}
            styles={[styles.menuItemsCardContainer, { top: yCoordinate }]}
          />
        </View>
      ) : showCard ? (
        <Card
          ref={refView}
          style={
            menuType === MenuType.AddOptionsMenu
              ? styles.menuCardContainer
              : getCardContainerStyle(yCoordinate)
          }
        >
          {menuType === MenuType.AddOptionsMenu ? renderAddOptionsMenu() : renderAddNodeMenu()}
        </Card>
      ) : null}
    </>
  );
};
