import React, { useEffect, useState } from 'react';
import { TouchableOpacity, TouchableOpacityProps, GestureResponderEvent } from 'react-native';
import * as _ from 'lodash-es';
import { useIsMounted } from 'shared/hooks/IsMounted';

export interface TouchableOpacityPreventDoubleClickProps extends TouchableOpacityProps {
  ref?: React.Ref<TouchableOpacity>;
}

export const TouchableOpacityPreventDoubleClick: React.FC<TouchableOpacityPreventDoubleClickProps> =
  React.forwardRef((props, ref) => {
    const [disable, setDisable] = useState<boolean>(false);
    const isMounted = useIsMounted();

    useEffect(() => {
      setDisable(!!props.disabled);
    }, [props.disabled]);

    function _onPress(event: GestureResponderEvent) {
      setDisable(true);
      setTimeout(() => {
        if (isMounted.current) setDisable(false);
      }, 800);
      props.onPress && props.onPress(event);
    }

    return <TouchableOpacity ref={ref} {...props} onPress={_onPress} disabled={disable} />;
  });
