import React from 'react';
import { View } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { Styles } from './Styles';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { TextButton, YellowButton } from 'shared/ui-component/Button';

interface Props {
  isValid: boolean;
  editedSkill?: API.Skill;
  onSubmit: () => void;
  onPressDeleteSkill?: () => void;
}

export const ButtonsPanel: React.FC<Props> = props => {
  const { isValid, editedSkill, onSubmit: submit, onPressDeleteSkill } = props;

  return (
    <View style={[Styles.bottomPanelContainer]}>
      {editedSkill && (
        <TextButton
          text={t('common:button.delete')}
          containerStyle={Styles.deleteButtonContainer}
          onPress={onPressDeleteSkill}
        />
      )}
      <YellowButton
        text={t('common:button.save')}
        style={Styles.saveButtonContainerStyle}
        textStyle={Styles.saveButtonTextStyle}
        disabled={!isValid}
        onPress={submit}
      />
    </View>
  );
};
