import React, { useState, useContext } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { Card } from 'shared/ui-component/Card';
import { t } from 'shared/localisation/i18n';
import * as API from 'shared/backend-data';
import Styles from './styles';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { IconSVG } from 'shared/ui-component/Icon';
import { Spacings } from 'shared/styles/Dimensions';
import { Colors } from 'shared/styles/Colors';

interface Props {
  workerSkill: API.WorkerSkill;
  proofBundle: API.NoMetadata<API.ProofBundle>;

  handleAddWorkerSkillModal: (revoke: boolean) => void;
  handleSubmitAgainProof: () => void;
  deleteWorkerSkill(): Promise<void>;
}

const moreIcon = require('shared/assets/svg/button.more.svg').default;

export const ProofActionsMenu: React.FC<Props> = props => {
  const {
    workerSkill,
    proofBundle,
    deleteWorkerSkill,
    handleAddWorkerSkillModal,
    handleSubmitAgainProof,
  } = props;

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const ref = useDetectOutsideClick<View>(() => setOpenMenu(false));

  const { isValidPermission } = useContext(PermissionManagementContext);

  function _handleAddWorkerSkillModal() {
    setOpenMenu(false);
    handleAddWorkerSkillModal(false);
  }

  function handleAddWorkerSkillModalRevoke() {
    setOpenMenu(false);
    handleAddWorkerSkillModal(true);
  }

  function _handleSubmitAgainProof() {
    setOpenMenu(false);
    handleSubmitAgainProof();
  }

  function _deleteWorkerSkill() {
    setOpenMenu(false);
    deleteWorkerSkill();
  }

  return (
    <>
      {proofBundle.review.state !== API.ReviewState.TO_REVIEW &&
        isValidPermission(API.Permission.workersSkillsProof_edit) && (
          <TouchableOpacity style={Styles.menuIconContainer} onPress={() => setOpenMenu(true)}>
            <IconSVG
              svgComponent={moreIcon}
              size={{ width: Spacings.Standard, height: Spacings.Standard }}
              color={Colors.Black}
            />
          </TouchableOpacity>
        )}
      {}
      {openMenu && (
        <Card ref={ref} style={Styles.menuContainer}>
          <>
            <TouchableOpacity
              style={Styles.menuOptionToucableOpacity}
              onPress={_handleAddWorkerSkillModal}
            >
              <Text style={Styles.optionText}>
                {t('alex:workerSkillReviewModal.modalMenu.renewProof.0')}
              </Text>
              <Text style={Styles.optionDescriptionText}>
                {t('alex:workerSkillReviewModal.modalMenu.renewProof.1')}
              </Text>
            </TouchableOpacity>

            {workerSkill.validity !== API.Validity.KO_EXPIRED &&
              proofBundle.review.state !== API.ReviewState.REJECTED &&
              proofBundle.review.state !== API.ReviewState.REJECTED_TO_RESUBMIT && (
                <TouchableOpacity
                  style={Styles.menuOptionToucableOpacity}
                  onPress={handleAddWorkerSkillModalRevoke}
                >
                  <Text style={Styles.optionText}>
                    {t('alex:workerSkillReviewModal.modalMenu.revokeProof.0')}
                  </Text>
                  <Text style={Styles.optionDescriptionText}>
                    {t('alex:workerSkillReviewModal.modalMenu.revokeProof.1')}
                  </Text>
                </TouchableOpacity>
              )}
            <TouchableOpacity
              style={Styles.menuOptionToucableOpacity}
              onPress={_handleSubmitAgainProof}
            >
              <Text style={Styles.optionText}>
                {t('alex:workerSkillReviewModal.modalMenu.resubmitProof.0')}
              </Text>
              <Text style={Styles.optionDescriptionText}>
                {t('alex:workerSkillReviewModal.modalMenu.resubmitProof.1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={Styles.menuOptionToucableOpacity} onPress={_deleteWorkerSkill}>
              <Text style={Styles.deleteOptionText}>
                {t('alex:workerSkillReviewModal.modalMenu.removeSkill.0')}
              </Text>
              <Text style={Styles.optionDescriptionText}>
                {t('alex:workerSkillReviewModal.modalMenu.removeSkill.1')}
              </Text>
            </TouchableOpacity>
          </>
        </Card>
      )}
    </>
  );
};
