import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TouchableWithoutFeedback,
  ScrollView,
} from 'react-native';
import Styles from './Styles';
import { AbstractButton } from 'shared/ui-component/Button/Button';
import * as _ from 'lodash-es';
import { DependencyDetails } from './DependencyDetails';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors } from 'shared/styles';
import { t } from 'shared/localisation/i18n';
import { useModal } from 'shared/ui-component/Modal/Modal';
import { logger } from 'shared/backend-data';
import * as API from 'shared/backend-data';
export interface DependencyVetoModalProps {
  vetoMessage: string | JSX.Element;
  vetoAcceptButton?: string;
  buttonStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  dependencyIds?: string[];
  disableRedirection?: boolean;

  vetoAcceptCallback?: () => void;
  onModalHide?: () => void;
}

export const DependencyVetoModalContent: React.FC<DependencyVetoModalProps> = props => {
  const isPlatformWeb = Platform.OS === 'web';

  const modal = useModal();

  const [isHover, setIsHover] = useState(false);
  const {
    dependencyIds,
    vetoAcceptButton = t('common:button.gotIt'),
    vetoAcceptCallback = () => {
      modal.hideModal();
      if (props.vetoAcceptCallback) props.vetoAcceptCallback();
    },
  } = props;

  const ref = useCallOnHover<View>(
    Colors.Transparent,
    () => setIsHover(true),
    () => setIsHover(false),
  );

  useEffect(() => {
    if (dependencyIds && dependencyIds.length) {
      const _dataType = API.getDataType(dependencyIds[0]);
      if (API.isFailure(_dataType)) {
        logger.error(_dataType);
      }
    }
  }, [dependencyIds]);

  return (
    <TouchableOpacity style={{ flex: 1 }} activeOpacity={1} onPress={() => vetoAcceptCallback()}>
      <View style={Styles.contentContainer}>
        <TouchableWithoutFeedback>
          <View
            ref={ref}
            style={[
              Styles.innerContainer,
              isPlatformWeb && { width: 330, maxHeight: 500 },
              props.containerStyle ?? {},
            ]}
          >
            <View style={Styles.textContainer}>
              <Text style={Styles.warningText}>{props.vetoMessage}</Text>
            </View>
            {dependencyIds?.length && (
              <ScrollView showsVerticalScrollIndicator={isHover} style={{ maxHeight: 150 }}>
                {_.map(dependencyIds, (dependencyId, index) => {
                  return (
                    <DependencyDetails
                      key={index}
                      id={dependencyId}
                      disableRedirection={props.disableRedirection}
                    />
                  );
                })}
              </ScrollView>
            )}
            <View style={Styles.buttonContainer}>
              <View style={{ flex: 1 }}>
                <AbstractButton
                  text={vetoAcceptButton}
                  containerStyle={[Styles.button, props.buttonStyle]}
                  textStyle={Styles.buttonText}
                  onPress={() => vetoAcceptCallback()}
                />
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableOpacity>
  );
};
