





import * as API from 'shared/backend-data';
import * as APIf from './FactoryApiWrapper';
import * as _ from 'lodash-es';
import { BRandMigrationScriptUseOnly } from './FactoryApiWrapper';
import { DataType } from 'backend/src/api';
import Aigle from 'aigle';

export async function deleteTENANT(
  tenantId: string,
): Promise<API.Result<API.Factory<API.DataType.TENANT>>> {
  const deleteFactory = await BRandMigrationScriptUseOnly._deleteFactory(
    tenantId,
    API.DataType.TENANT,
  );
  if (API.isFailure(deleteFactory)) return deleteFactory;

  
  {
    const factories = await APIf.listSingleRelationOfBusinessObject(
      API.DataType.TENANT_APP,
      tenantId,
    );
    if (API.isFailure(factories)) return factories;

    let failures = await API.mapLimit(factories.result, async factory => {
      return BRandMigrationScriptUseOnly._deleteFactory(factory.sk, factory.dataType);
    });
    if (API.isFailure(failures)) return failures;
  }

  
  {
    const factoryWorkerTenantApps = await APIf.listFactoriesWithDataTypeUsingSkFilter(
      API.DataType.WORKER_TENANT_APP,
      tenantId,
    );
    if (API.isFailure(factoryWorkerTenantApps)) return factoryWorkerTenantApps;

    const failures2 = await API.mapLimit(factoryWorkerTenantApps.result, async factory => {
      return BRandMigrationScriptUseOnly._deleteFactory(factory.sk, factory.dataType);
    });
    if (API.isFailure(failures2)) return failures2;
  }

  return deleteFactory;
}

export async function deleteTENANT_APP(
  tenantAppId: string,
): Promise<API.Result<API.Factory<API.DataType.TENANT_APP>>> {
  const deleteFactory = await BRandMigrationScriptUseOnly._deleteFactory(
    tenantAppId,
    API.DataType.TENANT_APP,
  );
  if (API.isFailure(deleteFactory)) return deleteFactory;

  

  
  {
    const factoryWorkerTenantApps = await APIf.listFactoriesWithDataTypeUsingSkFilter(
      API.DataType.WORKER_TENANT_APP,
      tenantAppId,
    );
    if (API.isFailure(factoryWorkerTenantApps)) return factoryWorkerTenantApps;

    const failures = await API.mapLimit(factoryWorkerTenantApps.result, async factory => {
      return BRandMigrationScriptUseOnly._deleteFactory(factory.sk, factory.dataType);
    });
    if (API.isFailure(failures)) return failures;
  }

  return deleteFactory;
}

export async function deleteWORKSTATION(
  workstationId: string,
  deleteDependencies?: boolean,
): Promise<API.Result<API.Factory<API.DataType.WORKSTATION>>> {
  

  const skString =
    API.DataType.REQUIREMENT + API.SeparatorDataType + API.SeparatorIds + workstationId;
  const levelsRequirement = await API.listFactoriesWithSk(
    API.DataType.REQUIREMENT,
    skString,
    API.KeyComparator.beginsWith,
  );
  if (API.isFailure(levelsRequirement)) return levelsRequirement;

  let failures: API.Failure[] = [];
  const dependencyIds: string[] = [];
  await Promise.all(
    levelsRequirement.result.map(async factory => {
      const deleteResult = await API.deleteFactoryBusinessObject(
        factory.sk,
        undefined,
        undefined,
        false,
        deleteDependencies,
      );
      if (API.isFailure(deleteResult)) {
        failures.push(deleteResult);
        if (API.isFailureType(deleteResult, 'DeletionVeto')) {
          dependencyIds.push(...deleteResult.data.dependencyIds);
        }
      }
    }),
  );
  if (failures.length)
    return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.ORGUNIT), {
      dependencyIds,
    });

  const workerWorkstationDataString =
    API.DataType.WORKERWORKSTATION + API.SeparatorDataType + API.SeparatorIds + workstationId;
  const workstationWorkerLevelTargets = await API.listFactoriesWithData(
    API.DataType.WORKERWORKSTATION,
    workerWorkstationDataString,
    API.KeyComparator.beginsWith,
  );
  if (API.isFailure(workstationWorkerLevelTargets)) return workstationWorkerLevelTargets;

  await Promise.all(
    workstationWorkerLevelTargets.result.map(async factory => {
      const deleteResult = await API.deleteFactoryBusinessObject(
        factory.sk,
        undefined,
        undefined,
        false,
        deleteDependencies,
      );
      if (API.isFailure(deleteResult)) {
        failures.push(deleteResult);
        if (API.isFailureType(deleteResult, 'DeletionVeto')) {
          dependencyIds.push(...deleteResult.data.dependencyIds);
        }
      }
    }),
  );
  if (failures.length)
    return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.ORGUNIT), {
      dependencyIds,
    });

  return API.BRandMigrationScriptUseOnly._deleteFactory(workstationId, API.DataType.WORKSTATION);
}

async function trainingTagExists(
  createFactoryInput: API.CreateFactoryInput<DataType.TRAININGTAG>,
): Promise<API.Result<API.Factory<DataType.TRAININGTAG> | null>> {
  const trainingTags = await API.getTrainingTags();
  if (API.isFailure(trainingTags)) return trainingTags;

  for (const trainingTag of trainingTags) {
    if (trainingTag.name === createFactoryInput.trainingTag.name)
      return API.createFailure('DuplicateVeto', 'Training tag name already exists', {
        dependencyIds: [trainingTag.id],
      });
  }
  return null;
}

export async function createTRAININGTAG(
  createFactoryInput: API.CreateFactoryInput<DataType.TRAININGTAG>,
): Promise<API.Result<API.Factory<DataType.TRAININGTAG>>> {
  const trainingTag = await trainingTagExists(createFactoryInput);
  if (API.isFailure(trainingTag)) return trainingTag;

  if (trainingTag) return trainingTag;

  return BRandMigrationScriptUseOnly._mutateFactoryAPI(
    'createFactory',
    createFactoryInput.dataType,
    {
      input: createFactoryInput,
    },
  );
}

export async function updateTRAININGTAG(
  updateFactoryInput: API.UpdateFactoryInput<DataType.TRAININGTAG>,
): Promise<API.Result<API.Factory<DataType.TRAININGTAG>>> {
  const trainingTag = await trainingTagExists(updateFactoryInput);
  if (API.isFailure(trainingTag)) return trainingTag;

  if (trainingTag) return trainingTag;

  return BRandMigrationScriptUseOnly._mutateFactoryAPI(
    'updateFactory',
    updateFactoryInput.dataType,
    {
      input: updateFactoryInput,
    },
  );
}

export async function deleteSKILL(
  skillId: string,
  deleteDependencies?: boolean,
): Promise<API.Result<API.Factory<API.DataType.SKILL>>> {
  const factoryRequirements = await API.listFactoriesWithDataType(API.DataType.REQUIREMENT);
  if (API.isFailure(factoryRequirements)) return factoryRequirements;

  const linkedRequirementIds: string[] = [];
  await Aigle.map(factoryRequirements.result, async factory => {
    if (
      factory.requirement.skillTrainingVersions
        .map(skillTrainingVersion => skillTrainingVersion.skillId)
        .includes(skillId)
    ) {
      if (deleteDependencies) {
        const skillTrainingVersions = factory.requirement.skillTrainingVersions.filter(
          item => item.skillId !== skillId,
        );
        const result = await API.updateFactoryBusinessObject(API.DataType.REQUIREMENT, {
          ...factory.requirement,
          skillTrainingVersions,
        });
        if (API.isFailure(result)) return result;
      } else {
        linkedRequirementIds.push(factory.requirement.id);
      }
    }
  });
  if (linkedRequirementIds.length) {
    return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.REQUIREMENT), {
      dependencyIds: linkedRequirementIds,
    });
  }

  const trainingVersions = await API.getTrainingVersionsForSkill(skillId, false);
  if (API.isFailure(trainingVersions)) return trainingVersions;

  const trainingVersionIncluded = _.some(trainingVersions, trainingVersion => {
    if (trainingVersion.skillIds.length > 1) return true;
  });
  if (trainingVersionIncluded) {
    const dependencyIds = _.map(trainingVersions, trainingVersion => trainingVersion.id);
    return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.TRAINING), {
      dependencyIds,
    });
  }

  return API.BRandMigrationScriptUseOnly._deleteFactory(
    skillId,
    API.DataType.SKILL,
    deleteDependencies,
  );
}

async function deleteUnitRequirments(
  unitOrWorkstationId: string,
  deleteDependencies?: boolean,
): Promise<API.Result<string[]>> {
  let dependencyIds: string[] = [];
  const levelsRequirement = await API.getLevelsRequirement(unitOrWorkstationId);
  if (API.isFailure(levelsRequirement)) return levelsRequirement;

  const failures: API.Failure[] = [];

  await Promise.all(
    Array.from(levelsRequirement.values()).map(async requirement => {
      const deleteResult = await API.deleteFactoryBusinessObject(
        requirement.id,
        undefined,
        undefined,
        false,
        deleteDependencies,
      );
      if (API.isFailure(deleteResult)) {
        failures.push(deleteResult);
        if (API.isFailureType(deleteResult, 'DeletionVeto'))
          dependencyIds = [...dependencyIds, ...deleteResult.data.dependencyIds];
      }
    }),
  );
  if (failures.length) {
    if (dependencyIds.length)
      return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.REQUIREMENT), {
        dependencyIds: dependencyIds,
      });
    else return API.createFailure('MultipleFailures', 'deleteUnitRequirments', failures);
  }

  return dependencyIds;
}

export async function deleteORGUNIT(
  orgUnitId: string,
  deleteDependencies?: boolean,
): Promise<API.Result<API.Factory<API.DataType.ORGUNIT>>> {
  
  
  const workersAssignmentsMap = await API.getWorkersAssignments(false, false, undefined, [
    orgUnitId,
  ]);
  if (API.isFailure(workersAssignmentsMap)) return workersAssignmentsMap;
  if (workersAssignmentsMap.size) {
    const dependencyIds = [...workersAssignmentsMap.keys()];
    return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.WORKER), {
      dependencyIds,
    });
  }

  const failures: API.Failure[] = [];

  let proceedWithOtherDependencies = true;

  const dependencyIds = await deleteUnitRequirments(orgUnitId);
  if (API.isFailure(dependencyIds)) return dependencyIds;
  if (dependencyIds.length) {
    if (deleteDependencies) {
      const deletRequirments = await deleteUnitRequirments(orgUnitId, true);
      if (API.isFailure(deletRequirments)) return deletRequirments;

      proceedWithOtherDependencies = false;
    } else
      return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.REQUIREMENT), {
        dependencyIds,
      });
  }

  const workstationRelations = API.getUnitChildWorkstationsOrUnits(
    orgUnitId,
    API.DataType.WORKSTATION,
  );
  if (API.isFailure(workstationRelations)) return workstationRelations;

  if (workstationRelations.length) {
    if (deleteDependencies && proceedWithOtherDependencies) {
      await Promise.all(
        workstationRelations.map(async workstation => {
          const deleteResult = await API.deleteFactoryBusinessObject(
            workstation.id,
            undefined,
            undefined,
            false,
            deleteDependencies,
          );
          if (API.isFailure(deleteResult)) {
            failures.push(deleteResult);
          }
        }),
      );
      proceedWithOtherDependencies = false;
    } else {
      const dependencyIds = _.map(workstationRelations, workstation => workstation.id);
      return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.WORKSTATION), {
        dependencyIds,
      });
    }
  }

  let orgUnitRelations = API.getUnitChildWorkstationsOrUnits(orgUnitId, API.DataType.ORGUNIT, true);
  if (API.isFailure(orgUnitRelations)) return orgUnitRelations;
  if (orgUnitRelations.length) {
    if (deleteDependencies && proceedWithOtherDependencies) {
      orgUnitRelations = API.getUnitChildWorkstationsOrUnits(
        orgUnitId,
        API.DataType.ORGUNIT,
        false,
      );
      if (API.isFailure(orgUnitRelations)) return orgUnitRelations;

      await Promise.all(
        orgUnitRelations.map(async orgUnit => {
          const deletedUnitRequirements = await deleteUnitRequirments(
            orgUnit.id,
            deleteDependencies,
          );
          if (API.isFailure(deletedUnitRequirements)) return deletedUnitRequirements;

          const deletedOrgUnit = await API.deleteFactoryBusinessObject(
            orgUnit.id,
            undefined,
            undefined,
            false,
            deleteDependencies,
          );
          if (API.isFailure(deletedOrgUnit)) {
            failures.push(deletedOrgUnit);
          }
        }),
      );
    } else {
      const dependencyIds = _.map(orgUnitRelations, organizationalUnit => organizationalUnit.id);
      return API.createFailure('DeletionVeto', deletionVetoMessage(API.DataType.ORGUNIT), {
        dependencyIds,
      });
    }
  }

  if (failures.length)
    return API.createFailure('MultipleFailures', API.DataType.REQUIREMENT, failures);

  const orgUnitFactories = await API.listSingleRelationOfBusinessObject(
    API.DataType.ORGUNIT,
    orgUnitId,
  );
  if (API.isFailure(orgUnitFactories)) return orgUnitFactories;

  let facotryOrgUnit: API.Factory<API.DataType.ORGUNIT> | undefined = undefined;
  for (const orgUnitFactory of orgUnitFactories.result) {
    const deleteResult = await API.BRandMigrationScriptUseOnly._deleteFactory(
      orgUnitFactory.organizationalUnit.id,
      API.DataType.ORGUNIT,
      deleteDependencies,
    );
    if (API.isFailure(deleteResult)) return deleteResult;

    if (deleteResult.organizationalUnit.id === orgUnitId) facotryOrgUnit = deleteResult;
  }

  if (!facotryOrgUnit)
    
    return API.createFailure_Unspecified(
      'Issue while deleting orgUnit (id:' + orgUnitId + '). the orgUnit was not found!',
    );

  return facotryOrgUnit;
}

function deletionVetoMessage(dataType: API.DataType): string {
  return (
    'Some ' +
    dataType +
    ' are linked and prevent deletion. Please remove the constraints and try again.'
  );
}
