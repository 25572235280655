import React, { useContext, useRef } from 'react';
import Styles from './Style';
import { Workbook } from 'exceljs';
import { View, TouchableOpacity, Text, StyleProp, ViewStyle } from 'react-native';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import { ImportExportComponent } from 'shared/ui-component/import-export-menu';
import { ExportReport, ImportExportType } from 'shared/util/ExcelUtils';
import {
  CsvRow,
  getExportDataForWorkerTrainingSessions,
  ImportExportFileNames,
} from 'shared/util/ExcelUtils';
import logger from 'shared/util/Logger';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import * as API from 'shared/backend-data';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';

const ICON_TRAINING = require('shared/assets/svg/icon.training.svg').default;
const ICON_TRAINING_NOT_PRACTICAL = require('shared/assets/svg/icon.lecture.svg').default;
const ICON_TRAINING_PRACTICAL = require('shared/assets/svg/icon.practTrain.svg').default;

interface Props {
  style?: StyleProp<ViewStyle>;
  setShowAddTrainingMenu: (isShow: boolean) => void;
  setShowTrainAWorkerOnAworkstationModal: (isShow: boolean) => void;
  setShowPracticalModal: (isShow: boolean) => void;
  setShowLectureModal: (isShow: boolean) => void;
}

export const RequestTrainingMenu: React.FC<Props> = props => {
  const {
    style,
    setShowAddTrainingMenu,
    setShowTrainAWorkerOnAworkstationModal,
    setShowPracticalModal,
    setShowLectureModal,
  } = props;
  const disableDetectOutsideClick = useRef<boolean>(false);

  const modal = useModal();
  const cardRef = useDetectOutsideClick<View>(() => {
    
    setTimeout(() => {
      
      if (!disableDetectOutsideClick.current) setShowAddTrainingMenu(false);
    }, 200);
  });

  async function getExportData(): Promise<ExportReport> {
    const report = await getExportDataForWorkerTrainingSessions();

    disableDetectOutsideClick.current = false;

    return report;
  }

  const { isValidPermission } = useContext(PermissionManagementContext);

  function exportToCSVConfirmed() {
    disableDetectOutsideClick.current = true;
  }

  return (
    <View style={[Styles.contentContainer, style]}>
      <View ref={cardRef} style={Styles.addTrainingMenu}>
        <TouchableOpacity
          ref={useCallOnHover<TouchableOpacity>()}
          style={[Styles.addTrainingRowContainerStyle]}
          onPress={() => {
            setShowAddTrainingMenu(false);
            setShowTrainAWorkerOnAworkstationModal(true);
          }}
        >
          <View style={Styles.rowView}>
            <IconSVG
              svgComponent={ICON_TRAINING}
              containerStyle={{ height: 24, width: 24 }}
              size={{ height: 24, width: 24 }}
            />
            <View style={Styles.divider} />

            <Text style={Styles.boldTextOption}>
              {t('alex:trainingSessions.addTrainingModal.options.0')}
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          ref={useCallOnHover<TouchableOpacity>()}
          style={Styles.addTrainingRowContainerStyle}
          onPress={() => {
            setShowAddTrainingMenu(false);
            setShowPracticalModal(true);
          }}
        >
          <View style={Styles.rowView}>
            <IconSVG
              svgComponent={ICON_TRAINING_PRACTICAL}
              containerStyle={{ height: 24, width: 24 }}
              size={{ height: 24, width: 24 }}
            />
            <View style={Styles.divider} />
            <View>
              <Text style={Styles.boldTextOption}>
                {t('alex:trainingSessions.addTrainingModal.options.1')}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          ref={useCallOnHover<TouchableOpacity>()}
          style={Styles.addTrainingRowContainerStyle}
          onPress={() => {
            setShowAddTrainingMenu(false);
            setShowLectureModal(true);
          }}
        >
          <View style={Styles.rowView}>
            <IconSVG
              svgComponent={ICON_TRAINING_NOT_PRACTICAL}
              containerStyle={{ height: 24, width: 24 }}
              size={{ height: 24, width: 24 }}
            />
            <View style={Styles.divider} />
            <View>
              <Text style={Styles.boldTextOption}>
                {t('alex:trainingSessions.addTrainingModal.options.2')}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
        {isValidPermission(API.Permission.trainingSessions_edit) && (
          <ImportExportComponent
            importExportMenuType={ImportExportType.TrainingSession}
            getExportData={getExportData}
            fileName={ImportExportFileNames.TrainingSessions}
            menuOnly
            exportToCSVConfirmed={exportToCSVConfirmed}
          />
        )}
      </View>
    </View>
  );
};
