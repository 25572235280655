import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import i18n from 'i18next';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import { ModalUtils } from 'shared/ui-component/Modal';
import { ModalContext } from 'shared/ui-component/Modal/Modal';
import { getDataType } from 'shared/backend-data';

function getTrainingDeletionErrorMessage(errorType: 'veto' | 'forbidden' | 'other'): string {
  switch (errorType) {
    case 'veto':
      return i18n.t('alex:trainingsTab.deleteTrainingErrorMessages.deletionVeto');
    case 'forbidden':
      return i18n.t('alex:trainingsTab.deleteTrainingErrorMessages.forbiddenDeletion');
    default:
      return i18n.t('alex:trainingsTab.deleteTrainingErrorMessages.errorMessage.0');
  }
}

function getTrainingDeletionErrorSubMessage(
  errorType: 'veto' | 'forbidden' | 'other',
): string | undefined {
  if (errorType === 'other')
    return capitalizeFirstLetter(
      i18n.t('alex:trainingsTab.deleteTrainingErrorMessages.errorMessage.1'),
    );
}

function showTrainingDeletionErrorModal(
  modal: ModalContext,
  errorType: 'veto' | 'forbidden' | 'other',
): void {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: capitalizeFirstLetter(getTrainingDeletionErrorMessage(errorType)),
      warningSubMessage: getTrainingDeletionErrorSubMessage(errorType),
      warningAcceptButton: capitalizeFirstLetter(i18n.t('common:button.ok')),
    }),
  );
}

export async function deleteTrainingHandler(
  trainingId: string,
  modal: ModalContext,
  deletionCallback?: () => void,
): Promise<void> {
  const isTrainingPractical = await API.isPracticalTraining(trainingId);
  if (API.isFailure(isTrainingPractical)) {
    showTrainingDeletionErrorModal(modal, 'other');
    return;
  }

  if (isTrainingPractical) {
    showTrainingDeletionErrorModal(modal, 'forbidden');
    return;
  }

  const trainingVersions = await API.getTrainingVersionsForTraining(trainingId);
  if (API.isFailure(trainingVersions)) {
    showTrainingDeletionErrorModal(modal, 'other');
    return;
  }

  if (!trainingVersions.length) {
    showTrainingDeletionErrorModal(modal, 'other');
    return;
  }
  if (trainingVersions.length > 1) {
    showTrainingDeletionErrorModal(modal, 'veto');
    return;
  }

  const result = await API.deleteFactoryBusinessObject(trainingVersions[0].id);
  if (API.isFailure(result)) {
    if (API.isFailureType(result, 'DeletionVeto')) {
      const failureDataType = getDataType(result.data.dependencyIds[0]); 
      if (failureDataType === API.DataType.WORKERSKILL) {
        ModalUtils.showVetoModal(
          i18n.t('alex:trainingsTab.deleteTrainingErrorMessages.deletionVetoForProofBundle'),
          result,
        );
      } else {
        showTrainingDeletionErrorModal(modal, 'veto');
      }
    } else showTrainingDeletionErrorModal(modal, 'other');
    return;
  }

  modal.displayModal(
    ModalUtils.toastConfig({
      text: capitalizeFirstLetter(i18n.t('alex:trainingsTab.toastMessage')),
      callback: () => (deletionCallback ? deletionCallback() : {}),
    }),
  );
}
