import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Authentication } from '../login';
import { findForwardSlashAtEndOfString } from 'shared/util/const';

export const ROUTE_PATHNAME_KEY = 'ROUTE_PATHNAME_KEY';

export const RedirectComponent: React.FC<RouteComponentProps> = props => {
  useEffect(() => {
    const pathName = props.location.pathname.replace(findForwardSlashAtEndOfString, '');
    const search = props.location.search;

    setPathLocation(`${pathName}${search}`);
  }, [props.location.pathname, props.location.search]);

  async function setPathLocation(value: string) {
    window.localStorage.setItem(ROUTE_PATHNAME_KEY, value);
  }

  return <Route path="*" exact render={() => <Authentication {...props} />} />;
};
