import React from 'react';
import { View, Text } from 'react-native';
import Styles from './Style';
import { t } from '../../../localisation/i18n';

export const PasswordRules: React.FC = () => {
  return (
    <View style={Styles.passwordRulesContainer}>
      <Text style={Styles.passwordRuleText}>{t('common:auth.login.password.min12Characters')}</Text>
      <Text style={Styles.passwordRuleText}>
        {t('common:auth.login.password.needOneSpecialCharacter')}
      </Text>
      <Text style={Styles.passwordRuleText}>
        {t('common:auth.login.password.needOneUpperCase')}
      </Text>
      <Text style={Styles.passwordRuleText}>
        {t('common:auth.login.password.needOneLowerCase')}
      </Text>
      <Text style={Styles.passwordRuleText}>{t('common:auth.login.password.needOneNumber')}</Text>
    </View>
  );
};
