import { View, StyleSheet, Platform } from 'react-native';
import { OutlineButton } from '../../ui-component/Button';
import React from 'react';
import { TextFontStyles } from '../../styles';

const isWebPlatform = Platform.OS === 'web';

interface SingleActionRowProps {
  buttonText: string;
  onAction: () => void;
  disabled?: boolean;
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: isWebPlatform ? 24 : 36,
    width: '100%',
  },
  button: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 20,
    paddingVertical: 0,
    height: isWebPlatform ? 24 : 36,
  },
  text: {
    fontSize: isWebPlatform ? 12 : 16,
    ...TextFontStyles.LatoBold,
    textAlign: 'center',
  },
});

export const SingleActionRow: React.FC<SingleActionRowProps> = props => {
  const { buttonText, onAction, disabled } = props;
  return (
    <View style={styles.row}>
      <OutlineButton
        style={styles.button}
        text={buttonText}
        textStyle={styles.text}
        onPress={onAction}
        disabled={disabled}
      />
    </View>
  );
};
