import React, { useState } from 'react';
import Styles from './styles';
import { View, TouchableOpacity } from 'react-native';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown';
import { t } from 'shared/localisation/i18n';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles/Colors';
import { ContractWithContractType, ContractTypeDropDownOption } from '../container';
import moment from 'moment';
import { useCallOnHover } from 'shared/hooks/CallOnHover';

interface Props {
  contract: ContractWithContractType;
  index: number;
  contractOptions: ContractTypeDropDownOption[];
  handleContractTypeOption: (data: ContractTypeDropDownOption | undefined, index: number) => void;
  handleStartDateInput: (date: Date | null, index: number) => void;
  handleEndDateInput: (date: Date | null, index: number) => void;
  handleContractDelete: (index: number) => void;
}

const TrashIcon = require('shared/assets/svg/icon.trash.svg').default;

export const ContractRow: React.FC<Props> = props => {
  const {
    contract,
    index,
    contractOptions,
    handleContractTypeOption,
    handleStartDateInput,
    handleEndDateInput,
    handleContractDelete,
  } = props;

  const [hover, setHover] = useState<boolean>(false);

  function isExpiredContract(contract: ContractWithContractType): boolean {
    return (
      contract.startDate != null &&
      contract.endDate != null &&
      contract.endDate > contract.startDate &&
      contract.endDate < moment().toISOString()
    );
  }

  function getStartDate(): Date | undefined {
    return contract.startDate ? new Date(contract.startDate) : undefined;
  }

  return (
    <View
      key={'view' + index}
      style={[Styles.contractTypeContainer, { zIndex: -index }]}
      ref={useCallOnHover<View>(
        'transparent',
        () => setHover(true),
        () => setHover(false),
      )}
    >
      <DropDownSingleSelection
        placeholder={t('alex:worker.addEditWorker.addWorkerContractType')}
        title={t('alex:worker.addEditWorker.workerContract')}
        options={contractOptions}
        value={{
          value: contract.contractType,
          key: 'contractType' + index,
          label: contract.contractType.name,
        }}
        handleChange={(inputId: string, value: ContractTypeDropDownOption | undefined) => {
          handleContractTypeOption(value, index);
        }}
        containerStyle={Styles.contractTypeDropdown}
        notEditable={isExpiredContract(contract) && !contract.newlyUpdatedContract}
      />
      <View style={Styles.dateContainer}>
        <InputDateWeb
          hasDateIcon
          inputPlaceholder={t('common:time.startDate')}
          onChange={(_, date) => {
            handleStartDateInput(date, index);
          }}
          inputName={t('common:time.startDate')}
          value={getStartDate()}
          style={Styles.startDate}
          notEditable={isExpiredContract(contract) && !contract.newlyUpdatedContract}
        />

        <InputDateWeb
          hasDateIcon
          inputPlaceholder={t('common:time.endDate')}
          onChange={(_, date) => {
            handleEndDateInput(date, index);
          }}
          inputName={t('common:time.endDate')}
          minDate={getStartDate()}
          value={contract.endDate ? new Date(contract.endDate) : undefined}
          style={Styles.endDate}
          calendarContainerStyle={{ right: 0 }}
          notEditable={isExpiredContract(contract) && !contract.newlyUpdatedContract}
        />
      </View>
      {hover && (
        <TouchableOpacity style={Styles.trashContainer} onPress={() => handleContractDelete(index)}>
          <IconSVG svgComponent={TrashIcon} color={Colors.Grey} />
        </TouchableOpacity>
      )}
    </View>
  );
};
