import React, { useEffect, useContext, useState } from 'react';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { WorkerProfileComponent } from '../component';
import * as API from 'shared/backend-data';
import { View, InteractionManager } from 'react-native';
import { getWorker } from 'shared/util/Worker';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { CollaborateurProfileContext } from 'shared/context/collaborateur-profile-context/CollaborateurProfileContext';
import logger from 'shared/util/Logger';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import * as _ from 'lodash-es';
import { TableRow } from 'shared/ui-component/Table';
import { MyHub } from 'shared/util/MyHub';
import { WorkerWorkstationRow } from '../component/index';
import Styles from '../component/Style';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import {
  removeInvalidObjectFromUserPreference,
  extractFilterTags,
} from '../../../../../header-layout/headerFilterConfig';
import { t } from 'shared/localisation/i18n';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { RouteLocations } from '../../../../../navigation/Routes';
import { ModalUtils } from 'shared/ui-component/Modal';
import Aigle from 'aigle';
import {
  TrainingSessionWithSkillsRow,
  WorkerSkillChildRow,
  WorkerSkillInfoWithRequirementsRow,
  getNonWorkstationTrainingSessionSkillIds,
} from '../../../../../dashboard/skill-conformity-modal/component';
import {
  getTrainingSkillsStateStringForTrainingTable,
  getTrainingTableWorkerSkillComplementaryDetails,
  mapSkillsToTrainingSessionsOrTrainingVersionsOnTrainingTable,
} from '../../../../../dashboard/skill-conformity-modal/component/TrainingTable';

export interface WorkerSkillRow extends API.WorkerSkillWithComplementaryDetails, TableRow {
  workstations?: API.Workstation[];
}

interface Props extends RouteComponentProps {
  workerId: string;
}

export const WorkerProfileContainer: React.FC<Props> = props => {
  const [worker, setWorker] = useContext(CollaborateurProfileContext);
  const {
    workerWorkstationScreenFilterTags: [
      workerWorkstationScreenFilterTags,
      setWorkerWorkstationScreenFilterTags,
    ],
    workerSkillScreenFilterTags: [workerSkillScreenFilterTags, setWorkerSkillScreenFilterTags],
  } = useContext(HeaderFilterContext);
  const { workstationTargetsStartingAtLevel, setHideMenuFactory } = useContext(GlobalDataContext);

  const [contractType, setContractType] = useState<API.ContractType>();
  const [managers, setManagers] = useState<API.Worker[]>();
  const [workstations, setWorkstations] = useState<API.Workstation[]>();
  const [workerSkills, setWorkerSkills] = useState<WorkerSkillRow[]>();
  const [skillsNotAcquired, setSkillsNotAcquired] = useState<WorkerSkillRow[]>([]);
  const [skilledOrPlanToBeSkilledWorkstations, setSkilledOrPlanToBeSkilledWorkstations] = useState<
    WorkerWorkstationRow[]
  >([]);
  const [workerWorkstationFilterTags, setWorkerWorkstationFilterTags] = useState<TagExtended[]>([]);
  const [workerSkillFilterTags, setWorkerSkillFilterTags] = useState<TagExtended[]>([]);
  const [trainingSessionsForLevelSkills, setTrainingSessionsForLevelSkills] =
    useState<(TrainingSessionWithSkillsRow | WorkerSkillChildRow)[]>();

  const myWorkerRef = React.useRef(worker);

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const history = useHistory();

  useEffect(() => {
    fetchWorkerAndSetContext(props.workerId);
    setHideMenuFactory(true);
  }, [props.workerId]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKERSKILL) {
        if (data.tooManyMutations) {
          loadWorkerWorkstations();
        } else if (worker && data.factory.workerSkill.workerId === worker.id) {
          loadWorkerWorkstations();
        }
      }

      if (data.factory.dataType === API.DataType.REQUIREMENT) {
        const workstationIds = workstations && workstations.map(workstation => workstation.id);
        const workstationPathIds: string[] = [];
        workstations &&
          workstations.forEach(workstation => {
            workstationPathIds.push(...workstation.pathIds);
          });

        if (data.tooManyMutations) {
          loadWorkerWorkstations();
        } else if (
          workstationIds?.includes(data.factory.requirement.linkedObjectId) ||
          workstationPathIds?.includes(data.factory.requirement.linkedObjectId)
        ) {
          loadWorkerWorkstations();
        }
      }

      if (data.factory.dataType === API.DataType.WORKERTRAININGSESSION) {
        if (data.tooManyMutations) {
          loadWorkerWorkstations();
        } else if (worker && data.factory.workerTrainingSession.workerId === worker.id) {
          loadWorkerWorkstations();
        }
      }

      return () => {
        removeListener();
      };
    });

    InteractionManager.runAfterInteractions(async () => {
      await loadWorkerWorkstations();
      if (!isMounted.current) return;
      await loadWorkstationsForSelectedWorker();
    });
  }, [worker, workstations]);

  useEffect(() => {
    setWorkerWorkstationFilterTags(extractFilterTags(workerWorkstationScreenFilterTags));
    setWorkerSkillFilterTags(extractFilterTags(workerSkillScreenFilterTags));
  }, [workerWorkstationScreenFilterTags, workerSkillScreenFilterTags]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      const workerWorkstationUserPreference = await API.getUserPreference<
        Map<string, TagExtended[]>
      >(UserPreferenceKeys_SkillMgtApp.WorkerWorkstationCatalogFilter);
      if (!isMounted.current) return;
      if (API.isFailure(workerWorkstationUserPreference)) {
        logger.warn(
          'fetch worker workstation filter: error in fetch user Preference',
          workerWorkstationUserPreference,
        );
        return;
      }

      if (workerWorkstationUserPreference) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.WorkerWorkstationCatalogFilter,
          workerWorkstationUserPreference,
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.WorkerWorkstationCatalogFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save dashboard filter: error while saving user Preference', saved);
          return;
        }
        setWorkerWorkstationScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.WorkerWorkstationCatalogFilter) ??
            [],
        );
      }
      if (!isMounted.current) return;
      const workerSkillUserPreference = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.WorkerSkillCatalogFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(workerSkillUserPreference)) {
        logger.warn(
          'fetch worker skill filter: error in fetch user Preference',
          workerSkillUserPreference,
        );
        return;
      }

      if (workerSkillUserPreference) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.WorkerSkillCatalogFilter,
          workerSkillUserPreference,
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.WorkerSkillCatalogFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save dashboard filter: error while saving user Preference', saved);
          return;
        }
        setWorkerSkillScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.WorkerSkillCatalogFilter) ?? [],
        );
      }
    });
  }, []);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKSTATION) {
        if (data.tooManyMutations) {
          loadWorkstations(); 
        } else {
          if (data.mutationType === 'updateFactory') {
            loadWorkstationsForSelectedWorker([data.factory.workstation.id]);
          } else {
            
            loadWorkstations(); 
          }
        }
      } else if (
        data.factory.dataType === API.DataType.WORKERSKILL &&
        (data.tooManyMutations || data.factory.workerSkill.workerId === props.workerId)
      ) {
        loadWorkerSkillsForSelectedWorker();
      } else if (
        data.factory.dataType === API.DataType.WORKER &&
        (data.tooManyMutations || data.factory.worker.id === props.workerId)
      ) {
        fetchWorkerAndSetContext(props.workerId);
      } else if (data.factory.dataType === API.DataType.WORKERWORKSTATION) {
        if (data.tooManyMutations) {
          loadWorkstations(); 
        } else if (data.factory.workerWorkstation.workerId === props.workerId) {
          loadWorkstationsForSelectedWorker(
            data.factory.workerWorkstation.workstationId
              ? [data.factory.workerWorkstation.workstationId]
              : undefined,
          );
        }
      }
    });

    loadWorkstations();

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    myWorkerRef.current = worker;

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      if (!worker) return;

      loadWorkerSkillsForSelectedWorker();

      loadWorkerManagers();

      if (worker.contracts.length) {
        const lastContract = await API.getWorkerLastContract(worker);
        if (API.isFailure(lastContract) || !lastContract) {
          logger.warn('Failed to fetchContract or no contract available for worker', lastContract);
          return;
        }

        const _lastContractType = await API.getContractType(lastContract.contractTypeId);
        if (!isMounted.current) return;
        if (API.isFailure(_lastContractType)) {
          logger.warn('Failed to fetchContractType', _lastContractType);
        } else {
          setContractType(_lastContractType);
        }
      }
    });
  }, [worker]);

  async function loadWorkerWorkstations() {
    if (workstations && worker) {
      const levelSkillRows: WorkerSkillInfoWithRequirementsRow[] = [];
      const activeTrainingSessions: API.TrainingSession[] = [];

      
      await Aigle.map(workstations, async workstation => {
        const _workerWorkstation = API.getWorkerWorkstations(workstation.id, worker.id);
        if (!_workerWorkstation) return;

        
        const nonWorkstationTrainingSessionSkillIds =
          await getNonWorkstationTrainingSessionSkillIds(_workerWorkstation);
        if (!isMounted.current) return;
        if (API.isFailure(nonWorkstationTrainingSessionSkillIds)) {
          logger.warn(nonWorkstationTrainingSessionSkillIds);
          return;
        }

        const skillsAndWorkerSkills =
          await API.getSkillsAndWorkerSkillsRequiredForWorkstationLevels(
            workstation.id,
            worker.id,
            true,
            false,
          );

        if (!isMounted.current) return;
        if (API.isFailure(skillsAndWorkerSkills)) {
          logger.warn(skillsAndWorkerSkills);
          return;
        }

        for (
          let level = API.WorkstationWorkerLevels.LEVEL1;
          _workerWorkstation.targetLevel &&
          level <= API.api2workstationWorkerLevels(_workerWorkstation.targetLevel);
          level++
        ) {
          const _levelSkillRows = await getTrainingTableWorkerSkillComplementaryDetails(
            level,
            skillsAndWorkerSkills,
            nonWorkstationTrainingSessionSkillIds,
          );
          if (!isMounted.current) return;

          levelSkillRows.push(..._levelSkillRows);
        }

        const _activeTrainingSessions =
          _workerWorkstation.activeTrainingSessions.workstationActiveTrainingSessions;
        const _activeTrainingSessionIds = [
          ..._activeTrainingSessions.lowerOrEqualToTarget.fromInheritedRequirements,
          ..._activeTrainingSessions.lowerOrEqualToTarget.fromNonInheritedRequirements,
        ];

        await Aigle.mapSeries(_activeTrainingSessionIds, async activeTrainingSessionId => {
          const trainingSession = await API.getTrainingSession(activeTrainingSessionId);
          if (!isMounted.current) return;
          if (API.isFailure(trainingSession)) {
            logger.warn(trainingSession);
            return;
          }
          activeTrainingSessions.push(trainingSession);
        });
      });

      const _trainingSessionsForLevelSkills =
        await mapSkillsToTrainingSessionsOrTrainingVersionsOnTrainingTable(
          _.uniqBy(levelSkillRows, 'key'),
          _.uniqBy(activeTrainingSessions, 'id'),
        );
      if (!isMounted.current) return;

      const __trainingSessionsForLevelSkills: (
        | TrainingSessionWithSkillsRow
        | WorkerSkillChildRow
      )[] = [];
      Array.from(_trainingSessionsForLevelSkills).map(([, value]) => {
        __trainingSessionsForLevelSkills.push({
          ...value,
          worstSkillValidity: getTrainingSkillsStateStringForTrainingTable(value.skills),
        });
        value.skills.forEach(skill => __trainingSessionsForLevelSkills.push(skill));
      });
      setTrainingSessionsForLevelSkills(__trainingSessionsForLevelSkills);
    }
  }

  const fetchWorkerAndSetContext = async (workerId: string) => {
    const worker = await getWorker(workerId);
    if (!isMounted.current) return;
    if (API.isFailure(worker)) {
      logger.warn(worker);
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('alex:workerProfile.noWorkerFound'),
          warningCancelButton: t('common:button.gotIt'),
          warningAcceptCallback: () => history.push(RouteLocations.Home()),
          warningCancelCallback: () => history.push(RouteLocations.Home()),
        }),
      );
    } else {
      setWorker(worker);
    }
  };

  function loadWorkstations() {
    const _workstations = API.getWorkstations();
    setWorkstations(_workstations);
  }

  async function loadWorkerManagers() {
    if (!worker) return;

    const _managers = await API.getManagersForWorker(worker);
    if (!isMounted.current) return;
    if (API.isFailure(_managers)) {
      logger.warn('Failed to fetch Managers', _managers);
    } else {
      setManagers(_managers);
    }
  }

  async function loadWorkstationsForSelectedWorker(workstationIds?: string[]) {
    if (!worker || !workstations || !isMounted.current) return;

    let _workstationIds: string[] = [];
    if (workstationIds) {
      
      if (_.some(workstationIds, workstationId => !_workstationIds.includes(workstationId))) {
        logger.error('Cannot pass a worksttionId which is not part of the loaded Workstations');
        return;
      }
      _workstationIds = [...workstationIds];
    } else {
      _workstationIds = _.map(workstations, workstation => workstation.id);
    }

    if (!API.enableGlobalLevelComputation) {
      const workersLinkedWorkstations = await API.getWorkersLinkedWorkstations(
        [worker],
        [API.Permission.workerIsOperational],
      );
      if (!isMounted.current) return;
      if (API.isFailure(workersLinkedWorkstations)) {
        logger.warn(workersLinkedWorkstations);
        return;
      }
      _workstationIds = _workstationIds.filter(workstationId =>
        workersLinkedWorkstations.some(
          workerLinkedWorkstation => workerLinkedWorkstation.id === workstationId,
        ),
      );
    }

    const _skilledOrPlanToBeSkilledWorkstations = [...skilledOrPlanToBeSkilledWorkstations];

    const errors: API.Failure[] = [];
    await Promise.all(
      _.map(_workstationIds, async workstationId => {
        const workerWorkstation = API.getWorkerWorkstations(workstationId, worker.id);
        if (!workerWorkstation) return;

        const workstation = await API.getWorkstation(workerWorkstation.workstationId);
        if (API.isFailure(workstation)) {
          logger.warn(workstation);
          errors.push(workstation);
          return;
        }
        const rowKey = worker.id + workstation.id;
        const row: WorkerWorkstationRow = {
          key: rowKey,
          worker,
          workerWorkstation: workerWorkstation,
          workstation,
        };

        if (API.isWorkerSkilled(workerWorkstation, workstationTargetsStartingAtLevel)) {
          const index = _skilledOrPlanToBeSkilledWorkstations.findIndex(row => row.key === rowKey);
          if (index >= 0) {
            _skilledOrPlanToBeSkilledWorkstations[index] = row;
          } else {
            _skilledOrPlanToBeSkilledWorkstations.push(row);
          }
        }
      }),
    );
    if (!isMounted.current) return;

    if (errors.length) {
      logger.error('Error in fetching workstation workers ', errors);
      return;
    }
    setSkilledOrPlanToBeSkilledWorkstations(_skilledOrPlanToBeSkilledWorkstations);
  }

  async function loadWorkerSkillsForSelectedWorker() {
    if (!myWorkerRef.current || !isMounted.current) return;

    const _workerSkillsWithComp = await API.getWorkerSkillsWithComplementaryDetails(
      myWorkerRef.current.id,
    );
    if (!isMounted.current) return;
    if (API.isFailure(_workerSkillsWithComp)) {
      logger.warn(_workerSkillsWithComp);
      return;
    }

    const data: WorkerSkillRow[] = _.map(_workerSkillsWithComp.result, workerSkill => {
      return { ...workerSkill, key: workerSkill.id };
    });

    setWorkerSkills(data);
    loadWorkerSkillsNotAcquired(data);
  }

  async function loadWorkerSkillsNotAcquired(workerSkills: WorkerSkillRow[]) {
    const skills = await API.getSkills();
    if (!myWorkerRef.current || !isMounted.current) return;
    if (API.isFailure(skills)) {
      logger.warn(skills);
      return;
    }

    const _skills = skills.result.filter(
      skill => !workerSkills.some(workerSkill => workerSkill.skill.id === skill.id),
    );

    const data: WorkerSkillRow[] = _skills.map(skill => {
      return {
        key: skill.id,
        skill,
        stateString: t('alex:filters.notAcquired'),
        __typename: 'WorkerSkill',
        id: '',
        skillId: skill.id,
        workerId: myWorkerRef.current!.id,
        proofBundleIds: [],
        updatedAt: '',
        updatedBy: '',
      };
    });

    setSkillsNotAcquired(data);
  }

  async function fetchRowDetailsForWorkerSkills(
    row: WorkerSkillRow,
    isMounted: React.MutableRefObject<boolean>,
  ): Promise<boolean> {
    let updated = false;

    if (row.workstations === undefined) {
      const workstations = await API.getSkillWorkstations(row.skill.id);
      if (!isMounted) return updated;
      if (API.isFailure(workstations)) {
        logger.warn(workstations);
        return updated;
      }

      row.workstations = workstations;
      updated = true;
    }

    return updated;
  }

  return (
    <View style={Styles.outerContainer}>
      {worker ? (
        <WorkerProfileComponent
          {...props}
          worker={worker}
          contractType={contractType}
          managers={managers}
          workerSkills={workerSkills}
          skilledOrPlanToBeSkilledWorkstations={skilledOrPlanToBeSkilledWorkstations}
          workerWorkstationFilterTags={workerWorkstationFilterTags}
          workerSkillFilterTags={workerSkillFilterTags}
          skillsNotAcquired={skillsNotAcquired}
          trainingSessionsForLevelSkills={trainingSessionsForLevelSkills}
          fetchRowDetailsForWorkerSkills={fetchRowDetailsForWorkerSkills}
          setTrainingSessionsForLevelSkills={setTrainingSessionsForLevelSkills}
        />
      ) : (
        <Loader />
      )}
    </View>
  );
};
