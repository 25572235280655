import React, { useState, useEffect } from 'react';
import { View, Text, InteractionManager, TouchableOpacity } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { MyHub } from 'shared/util/MyHub';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { useHistory } from 'react-router-dom';
import Styles from './styles';
import { RouteLocations } from 'skillmgtweb/src/components/navigation/Routes';
import { formatDecimalPercentageTargetCount } from 'shared/backend-data';
import { getWorkstationTargetActualColor } from 'shared/util/WorkstationUi';

interface Props {
  workstations: API.Workstation[];
  needIdealCount?: boolean;
  selectedShift?: API.Shift;
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels;
  filterData?: {
    workers?: API.Worker[];
  };
  needSumOfTargetCounts?: boolean;

  handleOnPress?: () => void;
}

export const WorkstationTargetCounts: React.FC<Props> = props => {
  const {
    workstations,
    needIdealCount,
    selectedShift,
    workstationTargetsStartingAtLevel,
    filterData,
    needSumOfTargetCounts,

    handleOnPress,
  } = props;

  const isMounted = useIsMounted();
  const history = useHistory();

  const [workstationTargetActual, setWorkstationTargetActual] =
    useState<API.WorkstationTargetActual | null>();
  const [level2Actual, setLevel2Actual] = useState<number>(0);
  const [level3Actual, setLevel3Actual] = useState<number>(0);
  const [level4Actual, setLevel4Actual] = useState<number>(0);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKSTATION) {
        const workstationId = data.tooManyMutations ? undefined : data.factory.workstation.id;
        if (!workstationId || !!workstations.find(w => w.id === workstationId)) {
          fillWorkstationAndShiftDetails(selectedShift?.id);
        }
      } else if (data.factory.dataType === API.DataType.WORKER) {
        fillWorkstationAndShiftDetails(selectedShift?.id);
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await fillWorkstationAndShiftDetails(selectedShift?.id);
    });

    return () => {
      removeListener();
    };
  }, [workstations, selectedShift, needSumOfTargetCounts]);

  function getLevelTargetsTitle(): string {
    return selectedShift?.name ?? t('glossary:levelTarget_plural');
  }

  async function fillWorkstationAndShiftDetails(
    selectedShiftId?: string,
  ): Promise<API.Result<void>> {
    if (!workstations) return;
    if (workstations.length === 1 && !needSumOfTargetCounts) {
      const workstationIdOrShiftId = selectedShiftId ?? workstations[0].id;
      const _workstationObjectiveAndActual = await API.fetchWorkstationTargetAndWorkersAlert(
        workstations[0],
        workstationTargetsStartingAtLevel,
        selectedShiftId ? [selectedShiftId] : undefined,
        undefined,
        undefined,
        filterData,
      );
      if (!isMounted.current) return;
      if (API.isFailure(_workstationObjectiveAndActual)) {
        logger.warn(_workstationObjectiveAndActual);
        return;
      }

      const targetValues = await API.computeActualValues(
        _workstationObjectiveAndActual.workstationsTargetAndActual[workstationIdOrShiftId],
        workstations[0].id,
        workstationTargetsStartingAtLevel,
        selectedShiftId,
      );
      if (!isMounted.current) return;
      if (API.isFailure(targetValues)) {
        logger.warn(targetValues);
        return;
      }

      setLevel2Actual(targetValues.workersWithLevel2AtLeastActual);
      setLevel3Actual(targetValues.workersWithLevel3AtLeastActual);
      setLevel4Actual(targetValues.workersWithLevel4Actual);

      setWorkstationTargetActual(
        _workstationObjectiveAndActual.workstationsTargetAndActual[workstationIdOrShiftId],
      );
    } else {
      const _workstationObjectiveAndActual = await API.fetchWorkstationsTargetAndWorkersAlert(
        workstations,
        workstationTargetsStartingAtLevel,
        true,
      );
      if (!isMounted.current) return;
      if (API.isFailure(_workstationObjectiveAndActual)) {
        logger.warn(_workstationObjectiveAndActual);
        return;
      }

      setWorkstationTargetActual(_workstationObjectiveAndActual.workstationTargetAndActual);
      setLevel2Actual(
        _workstationObjectiveAndActual.workstationTargetAndActual.workersWithLevel2AtLeastActual,
      );
      setLevel3Actual(
        _workstationObjectiveAndActual.workstationTargetAndActual.workersWithLevel3AtLeastActual,
      );
      setLevel4Actual(
        _workstationObjectiveAndActual.workstationTargetAndActual.workersWithLevel4Actual,
      );
    }
  }

  function onPress() {
    if (handleOnPress) handleOnPress();
    else history.push(RouteLocations.KPIOrgUnits());
  }

  return (
    <TouchableOpacity style={Styles.workstationInnerContainer} onPress={onPress}>
      <Text style={Styles.workstationLevelTextStyle}>{getLevelTargetsTitle()}</Text>
      {workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL2 && (
        <View>
          <View style={[Styles.worstationBannerTextContainer]}>
            <View style={Styles.workstationBannerTitleContainer}>
              <Text style={Styles.label} numberOfLines={1}>
                {t('alex:workerTable.lev2AndAbove', { count: API.WorkstationWorkerLevels.LEVEL2 })}
              </Text>
            </View>
            <View style={Styles.workstationBannerCountContainer}>
              <Text
                style={[
                  Styles.count,
                  {
                    color: workstationTargetActual
                      ? getWorkstationTargetActualColor(
                          workstationTargetActual,
                          API.WorkstationWorkerLevels.LEVEL2,
                          true,
                          !!workstationTargetActual?.workersWithLevel2AtLeastWorkstationTarget
                            .isPercentage
                            ? level2Actual
                            : undefined,
                        )
                      : '',
                  },
                ]}
              >
                {formatDecimalPercentageTargetCount(
                  level2Actual,
                  !!workstationTargetActual?.workersWithLevel2AtLeastWorkstationTarget.isPercentage,
                )}
              </Text>
            </View>
          </View>
          <View style={Styles.worstationBannerTextContainer}>
            <View style={Styles.workstationBannerTitleContainer}>
              <Text style={[Styles.label]}>{t('alex:workstationBanner.minimum')}</Text>
            </View>
            <View style={Styles.workstationBannerCountContainer}>
              <Text style={[Styles.count]}>
                {formatDecimalPercentageTargetCount(
                  workstationTargetActual?.workersWithLevel2AtLeastWorkstationTarget
                    .minNumberOfWorker ?? 0,
                  !!workstationTargetActual?.workersWithLevel2AtLeastWorkstationTarget.isPercentage,
                )}
              </Text>
            </View>
          </View>
          {needIdealCount ? (
            <View style={Styles.worstationBannerTextContainer}>
              <View style={Styles.workstationBannerTitleContainer}>
                <Text style={Styles.label}>{t('alex:workstationBanner.ideal')}</Text>
              </View>
              <View style={Styles.workstationBannerCountContainer}>
                <Text style={Styles.count}>
                  {formatDecimalPercentageTargetCount(
                    workstationTargetActual?.workersWithLevel2AtLeastWorkstationTarget
                      .idealNumberOfWorker ?? 0,
                    !!workstationTargetActual?.workersWithLevel2AtLeastWorkstationTarget
                      .isPercentage,
                  )}
                </Text>
              </View>
            </View>
          ) : null}
        </View>
      )}
      <View style={Styles.separator} />
      {workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3 && (
        <View>
          <View style={[Styles.worstationBannerTextContainer]}>
            <View style={Styles.workstationBannerTitleContainer}>
              <Text style={Styles.label} numberOfLines={1}>
                {t('alex:workerTable.lev2AndAbove', { count: API.WorkstationWorkerLevels.LEVEL3 })}
              </Text>
            </View>
            <View style={Styles.workstationBannerCountContainer}>
              <Text
                style={[
                  Styles.count,
                  {
                    color: workstationTargetActual
                      ? getWorkstationTargetActualColor(
                          workstationTargetActual,
                          API.WorkstationWorkerLevels.LEVEL3,
                          true,
                          !!workstationTargetActual?.workersWithLevel3AtLeastWorkstationTarget
                            .isPercentage
                            ? level3Actual
                            : undefined,
                        )
                      : '',
                  },
                ]}
              >
                {formatDecimalPercentageTargetCount(
                  level3Actual,
                  !!workstationTargetActual?.workersWithLevel3AtLeastWorkstationTarget.isPercentage,
                )}
              </Text>
            </View>
          </View>
          <View style={Styles.worstationBannerTextContainer}>
            <View style={Styles.workstationBannerTitleContainer}>
              <Text style={[Styles.label]}>{t('alex:workstationBanner.minimum')}</Text>
            </View>
            <View style={Styles.workstationBannerCountContainer}>
              <Text style={[Styles.count]}>
                {formatDecimalPercentageTargetCount(
                  workstationTargetActual?.workersWithLevel3AtLeastWorkstationTarget
                    .minNumberOfWorker ?? 0,
                  !!workstationTargetActual?.workersWithLevel3AtLeastWorkstationTarget.isPercentage,
                )}
              </Text>
            </View>
          </View>
          {needIdealCount ? (
            <View style={Styles.worstationBannerTextContainer}>
              <View style={Styles.workstationBannerTitleContainer}>
                <Text style={Styles.label}>{t('alex:workstationBanner.ideal')}</Text>
              </View>
              <View style={Styles.workstationBannerCountContainer}>
                <Text style={Styles.count}>
                  {formatDecimalPercentageTargetCount(
                    workstationTargetActual?.workersWithLevel3AtLeastWorkstationTarget
                      .idealNumberOfWorker ?? 0,
                    !!workstationTargetActual?.workersWithLevel3AtLeastWorkstationTarget
                      .isPercentage,
                  )}
                </Text>
              </View>
            </View>
          ) : null}
        </View>
      )}
      <View style={Styles.separator} />
      {workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL4 && (
        <View>
          <View style={Styles.worstationBannerTextContainer}>
            <View style={Styles.workstationBannerTitleContainer}>
              <Text style={Styles.label} numberOfLines={1}>
                {t('alex:workerTable.lev4Actuals')}
              </Text>
            </View>
            <View style={Styles.workstationBannerCountContainer}>
              <Text
                style={[
                  Styles.count,
                  {
                    color: workstationTargetActual
                      ? getWorkstationTargetActualColor(
                          workstationTargetActual,
                          API.WorkstationWorkerLevels.LEVEL4,
                          true,
                          !!workstationTargetActual?.workersWithLevel4WorkstationTarget.isPercentage
                            ? level4Actual
                            : undefined,
                        )
                      : '',
                  },
                ]}
              >
                {formatDecimalPercentageTargetCount(
                  level4Actual,
                  !!workstationTargetActual?.workersWithLevel4WorkstationTarget.isPercentage,
                )}
              </Text>
            </View>
          </View>
          <View style={Styles.worstationBannerTextContainer}>
            <View style={Styles.workstationBannerTitleContainer}>
              <Text style={[Styles.label]}>{t('alex:workstationBanner.minimum')}</Text>
            </View>
            <View style={Styles.workstationBannerCountContainer}>
              <Text style={Styles.count}>
                {formatDecimalPercentageTargetCount(
                  workstationTargetActual?.workersWithLevel4WorkstationTarget.minNumberOfWorker ??
                    0,
                  !!workstationTargetActual?.workersWithLevel4WorkstationTarget.isPercentage,
                )}
              </Text>
            </View>
          </View>
          {needIdealCount ? (
            <View style={Styles.worstationBannerTextContainer}>
              <View style={Styles.workstationBannerTitleContainer}>
                <Text style={Styles.label}>{t('alex:workstationBanner.ideal')}</Text>
              </View>
              <View style={Styles.workstationBannerCountContainer}>
                <Text style={Styles.count}>
                  {formatDecimalPercentageTargetCount(
                    workstationTargetActual?.workersWithLevel4WorkstationTarget
                      .idealNumberOfWorker ?? 0,
                    !!workstationTargetActual?.workersWithLevel4WorkstationTarget.isPercentage,
                  )}
                </Text>
              </View>
            </View>
          ) : null}
        </View>
      )}
    </TouchableOpacity>
  );
};
