import React, { useEffect, useState, useReducer, useContext, useRef } from 'react';
import * as API from 'shared/backend-data';
import { View, Text, GestureResponderEvent, InteractionManager, ScrollView } from 'react-native';
import { TouchableOpacity } from 'react-native-web';
import { IconSVG } from 'shared/ui-component/Icon';
import { TreeNode } from 'shared/backend-data/factoryCache/Tree';
import { Colors, Spacings } from 'shared/styles';
import styles, {
  getNodeTextStyle,
  getNodeViewStyle,
  getGrabIconStyle,
  dropNodeStyle,
} from './style';
import { useCallOnHover, highlightElement, lowlightElement } from 'shared/hooks/CallOnHover';
import { t } from 'shared/localisation/i18n';
import { NodeState } from 'shared/backend-data/factoryCache/Tree';
import * as _ from 'lodash-es';
import { MyHub } from 'shared/util/MyHub';
import { useDrag, useDrop, DragObjectWithType } from 'react-dnd';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../../../navigation/Routes';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import Aigle from 'aigle';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { WorkstationsState } from '../../../workstations/WorkstationsLibrary';

interface TreeNodeProps {
  index: number;
  node: TreeNode;
  level: number;
  nodeStates: Map<string, NodeState>;
  selectedWorkstation?: string;
  refScrollView: React.RefObject<ScrollView>;

  moveTreeNodeToParent: (
    item: DragReferenceObject,
    parentId: string,
    nodeOrder?: number,
  ) => Promise<void>;
  setSelectedMenuTreeNode: (node: TreeNode) => void;
  openEditMenu: (event: GestureResponderEvent) => void;
}

export interface DragReferenceObject extends DragObjectWithType {
  id: string;
  dataType: API.TreeDataType;
  object: API.TreeObject<API.TreeDataType>;
}

const treeNodeDragType = 'treeNode';

export const TreeNodeComponent: React.FC<TreeNodeProps> = props => {
  const {
    index,
    node,
    level,
    nodeStates,
    refScrollView,
    selectedWorkstation,

    setSelectedMenuTreeNode,
    openEditMenu,
    moveTreeNodeToParent,
  } = props;

  const history = useHistory<WorkstationsState>();

  const [isNodeSelected, setIsNodeSelected] = useState<boolean>(false);
  const [nodeChildren, setNodeChildren] = useState<TreeNode[]>([]);
  const [isNodeOpen, setIsNodeOpen] = useState<boolean>(false);
  const [isNodeRootUnit, setIsNodeRootUnit] = useState<boolean>(false);
  const [isNodeOnhover, setIsNodeOnhover] = useState<boolean>(false);
  const [emptyTree, setEmptyTree] = useState<boolean>(false);
  const [enableDrag, setEnableDrag] = useState<boolean>(false);
  const [shifts, setShifts] = useState<API.Shift[]>([]);
  const [clickedTreeNode, setClickedTreeNode] = useState<boolean>();

  const {
    treeNode: [treeNode, setTreeNode],
  } = useContext(MenuFactoryContext);
  const { isValidPermission } = useContext(PermissionManagementContext);

  const isMounted = useIsMounted();

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const arrowDown = require('shared/assets/svg/icon.arrowDown.mobile.svg').default;
  const arrowRight = require('shared/assets/svg/icon.arrowRight.mobile.svg').default;
  const moreIcon = require('shared/assets/svg/icon.more.treeweb.svg').default;
  const gripIcon = require('shared/assets/svg/icon.grip.svg').default;
  const shiftIcon = require('shared/assets/svg/icon.shift.svg').default;

  const nodeIsOu = API.isOrganizationalUnit(node.object);

  const nodeViewRef = useRef<View>(null);

  useEffect(() => {
    const removeTreeNodeUpdateListener = MyHub.listenBusinessObject(
      'BusinessObjectMutate',
      ({ data }) => {
        if (API.isTreeDataType(data.factory.dataType)) {
          if (
            data.tooManyMutations ||
            (API.isMutationUpdate(data.mutationType) && data.factory.sk === node.id)
          ) {
            forceUpdate();
          }
        } else if (data.factory.dataType === API.DataType.SHIFT) {
          if (API.isTreeNode(API.DataType.ORGUNIT, node)) {
            getShiftDetails(node.object);
          }
        }
      },
    );

    const removeTreeStructureUpdateListener = MyHub.listenBusinessObject(
      'TreeStructureUpdate',
      ({}) => {
        loadNodeChildren();
      },
    );

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (API.isTreeNode(API.DataType.ORGUNIT, node)) await getShiftDetails(node.object);
    });

    return () => {
      removeTreeNodeUpdateListener();
      removeTreeStructureUpdateListener();
    };
  }, [node]);

  useEffect(() => {
    loadNodeChildren();
  }, [node]);

  useEffect(() => {
    const isSelected = treeNode ? node.id === treeNode.id : false;
    setIsNodeSelected(isSelected);
  }, [treeNode]);

  useEffect(() => {
    if (isNodeSelected && !clickedTreeNode && selectedWorkstation) {
      getPosition();
    }
  }, [isNodeSelected, clickedTreeNode, selectedWorkstation]);

  useEffect(() => {
    const nodeState = nodeStates.get(node.id);

    if (isNodeRootUnit) {
      setIsNodeOpen(true);
    }

    if (nodeState && !isNodeRootUnit) {
      const isExpanded = !nodeState.collapsed;

      setIsNodeOpen(isExpanded);
    }
  }, [nodeStates, isNodeRootUnit]);

  function getPosition() {
    const nodeTopOffset = 137;

    setTimeout(() => {
      if (nodeViewRef.current) {
        nodeViewRef.current.measureInWindow((x, y) => {
          refScrollView.current?.scrollTo(y - nodeTopOffset);
        });
      }
    }, 200);
  }

  function saveNodeState(isSelected: boolean, isOpen: boolean) {
    const nodeState: NodeState = {};
    if (isSelected) nodeState['selected'] = true;
    if (!isOpen && node.factory.dataType === API.DataType.ORGUNIT) {
      nodeState['collapsed'] = true;
    } else {
      nodeState['collapsed'] = false;
    }

    nodeStates.set(node.id, nodeState);
  }

  async function loadNodeChildren() {
    if (API.isOrganizationalUnit(node.object)) {
      const children: TreeNode[] = API.Tree.getChildrenTreeNode(node, false);

      setNodeChildren(children);

      
      if (API.Tree.isRootNode(node)) {
        setIsNodeRootUnit(true);
        setEmptyTree(!children.length);
      }
    }
  }

  function callOnHover(event: Event) {
    highlightElement(Colors.BlueRollover, event);
    setIsNodeOnhover(true);
  }

  function callOnDehover(event: Event) {
    lowlightElement(event);
    setIsNodeOnhover(false);
  }

  const ref = useCallOnHover<View>(
    Colors.BlueRollover,
    e => callOnHover(e),
    e => callOnDehover(e),
  );

  const dragReferenceObject: DragReferenceObject = {
    type: treeNodeDragType,
    id: node.id,
    dataType: node.factory.dataType,
    object: node.object,
  };

  const [{ isDragging }, dragRef] = useDrag<DragReferenceObject, unknown, { isDragging: boolean }>({
    item: dragReferenceObject,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver: isOverNode }, dropRefNode] = useDrop<
    DragReferenceObject,
    void,
    { isOver: boolean }
  >({
    accept: dragReferenceObject.type,
    drop: item => {
      moveTreeNodeToParent(item, node.id);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isOver: isAfterNode }, dropRefAfterNode] = useDrop<
    DragReferenceObject,
    void,
    { isOver: boolean }
  >({
    accept: dragReferenceObject.type,
    drop: item => {
      if (API.isOrganizationalUnit(node.object) && isNodeOpen) {
        moveTreeNodeToParent(item, node.id, 0);
      } else {
        moveTreeNodeToParent(item, node.object.parentId, node.object.order);
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  async function getShiftDetails(orgUnit: API.OrganizationalUnit): Promise<void | null> {
    if (!orgUnit.shiftIds) return null;

    const _shifts: API.Shift[] = [];
    await Aigle.map(orgUnit.shiftIds, async (shiftId: string) => {
      const shift = await API.getShift(shiftId);
      if (!isMounted.current) return;
      if (API.isFailure(shift)) {
        logger.warn(shift);
        return;
      }

      _shifts.push(shift);
    });

    setShifts(_shifts);
  }

  function displayShifts() {
    return shifts.map(shift => {
      return (
        <View>
          <IconSVG
            svgComponent={shiftIcon}
            color={shift.color}
            size={{ height: Spacings.CardPadding, width: Spacings.CardPadding }}
          />
        </View>
      );
    });
  }

  function onNodeExpandButtonClick() {
    setIsNodeOpen(!isNodeOpen);
    saveNodeState(isNodeSelected, !isNodeOpen);
  }

  return (
    <View ref={nodeViewRef} style={[{ zIndex: isNodeOnhover ? 999 : index }]}>
      <div
        ref={!isNodeRootUnit ? (API.isOrganizationalUnit(node.object) ? dropRefNode : null) : null}
      >
        <div
          ref={isNodeRootUnit ? null : enableDrag ? dragRef : null}
          style={{
            opacity: isDragging ? 0 : 1,
          }}
        >
          <View
            ref={ref}
            style={[
              getNodeViewStyle(isNodeSelected, isOverNode),
              { zIndex: isNodeOnhover ? 999 : undefined },
            ]}
          >
            <TouchableOpacity
              style={getGrabIconStyle(enableDrag, nodeIsOu, level)}
              onPressIn={() => setEnableDrag(true)}
              onPressOut={() => setEnableDrag(false)}
            >
              {isNodeOnhover && !isNodeRootUnit && (
                <IconSVG
                  svgComponent={gripIcon}
                  color={Colors.GreyLight}
                  containerStyle={{ width: Spacings.Standard - Spacings.Unit }}
                />
              )}
            </TouchableOpacity>
            {!isNodeRootUnit && node && nodeIsOu && (
              <TouchableOpacity onPress={onNodeExpandButtonClick}>
                <IconSVG
                  svgComponent={isNodeOpen ? arrowDown : arrowRight}
                  size={{ height: Spacings.Standard, width: Spacings.Standard }}
                  containerStyle={styles.orgUnitIconContainer}
                />
              </TouchableOpacity>
            )}
            <TouchableOpacity
              style={styles.nodeTextContainer}
              onPress={() => {
                setClickedTreeNode(true);
                setTreeNode(node);

                if (API.isTreeNode(API.DataType.WORKSTATION, node)) {
                  history.push(RouteLocations.Workstations(node.id));
                }
              }}
            >
              <EllipsisWithTooltip
                text={node.object.name}
                textStyle={getNodeTextStyle(level, isNodeSelected)}
                style={{ minWidth: 40 }}
                toolTipContainerStyle={{ minWidth: 100 }}
              />
              <View style={styles.shiftContainer}>
                {API.isTreeNode(API.DataType.ORGUNIT, node) && displayShifts()}
              </View>
            </TouchableOpacity>

            <View style={styles.moreButtonContainer}>
              {isNodeOnhover &&
                ((API.isTreeNode(API.DataType.ORGUNIT, node) &&
                  isValidPermission(API.Permission.workstations_edit, node.object)) ||
                  API.isTreeNode(API.DataType.WORKSTATION, node)) && (
                  <TouchableOpacity
                    onPress={(e: GestureResponderEvent) => {
                      openEditMenu(e);
                      setSelectedMenuTreeNode(node);
                      if (API.isTreeNode(API.DataType.ORGUNIT, node)) {
                        setTreeNode(node);
                      }
                    }}
                  >
                    <IconSVG svgComponent={moreIcon} color={Colors.Black} />
                  </TouchableOpacity>
                )}
            </View>
          </View>
        </div>
      </div>

      <div ref={dropRefAfterNode} style={dropNodeStyle}>
        <div
          style={{
            height: 3,
            width: '90%',
            backgroundColor: isAfterNode ? Colors.Yellow : '',
            borderWidth: 2,
          }}
        />
        <div
          style={{
            height: 9,
            width: 9,
            borderRadius: Spacings.Unit,
            backgroundColor: isAfterNode ? Colors.Yellow : '',
          }}
        />
      </div>

      {emptyTree ? (
        <Text style={styles.emptyTreeText}>{t('alex:workstations.emptyTree')}</Text>
      ) : (
        node &&
        isNodeOpen &&
        !isDragging &&
        nodeChildren.map((childNode, _index) => {
          const nodeIsOu = API.isOrganizationalUnit(node.object);
          let _level = level;
          if (level === 0 && nodeIsOu && !isNodeRootUnit) {
            _level = 1;
          }

          return (
            <TreeNodeComponent
              {...props}
              key={childNode.id}
              node={childNode}
              level={_level + 1}
              index={index - _index}
            />
          );
        })
      )}
    </View>
  );
};
