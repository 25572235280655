import React, { useState, useEffect } from 'react';
import { View, Text, InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { IconSVG } from 'shared/ui-component/Icon';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import Styles from './Styles';

interface Props {
  skillId?: string;
  skillName?: string;
}

const skillIcon = require('shared/assets/svg/icon.addProof.mobile.svg').default;

export const SkillNameListing: React.FC<Props> = props => {
  const isMounted = useIsMounted();

  const [skillName, setSkillName] = useState<string>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (!props.skillName && props.skillId) {
        const skill = await API.getSkill(props.skillId);
        if (!isMounted.current) return;
        if (API.isFailure(skill)) return;
        setSkillName(skill.name);
      } else {
        setSkillName(props.skillName);
      }
    });
  }, [props.skillId, props.skillName]);

  return (
    <>
      {skillName ? (
        <View style={Styles.skillNameListingContainer}>
          <IconSVG
            size={{ width: 24, height: 24 }}
            svgComponent={skillIcon}
            containerStyle={Styles.skillIconContainer}
          />
          <Text style={Styles.skillNameTextStyle}>{capitalizeFirstLetter(skillName)}</Text>
        </View>
      ) : (
        <LoaderThreeDots lowPerformance={false} />
      )}
    </>
  );
};
