import React from 'react';
import { View, ScrollView, Text } from 'react-native';
import * as API from 'shared/backend-data';
import { IconSVG } from 'shared/ui-component/Icon';
import { InputText, ParserType } from 'shared/ui-component/Input';
import { InputEvent } from '../container';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { WorkerAssignmentDropDowns } from 'skillmgtweb/src/components/my-factory/workers/component/modify-worker-modal/workerAssignmentDropDown/container';
import { YellowButton } from 'shared/ui-component/Button';
import styles from './styles';
import { WorkerAssignmentsInfo } from 'shared/context/inviteWorkerModalContext';
import { UserInfo } from '../../user-info/container';
import * as SharedStyles from 'shared/styles';
import { ModalCardStyle, ModalFooterStyle } from 'shared/styles/ModalStyles';
import { t } from 'shared/localisation/i18n';

interface Props {
  organizationalUnitRoles: API.IndexedAssignment[];
  searchedValue?: string;
  worker: WorkerAssignmentsInfo | undefined;
  validEmail: boolean;
  infoValidity: boolean;

  handleSubmit: () => void;
  handleModalClose: () => void;
  handleInputs: (e: InputEvent) => void;
  setOrganizationalUnitRoles: (orgUnitRoles: API.IndexedAssignment[]) => void;
}

export interface WorkerDropDownOptions extends DropDownOption {
  value: API.Worker;
}

const addUserIcon = require('shared/assets/svg/icon.user+.svg').default;

export const InviteNonReferencedWorkerPageComponent: React.FC<Props> = props => {
  const {
    worker,
    infoValidity,
    validEmail,
    searchedValue,
    organizationalUnitRoles,

    handleSubmit,
    handleModalClose,
    handleInputs,
    setOrganizationalUnitRoles,
  } = props;

  const _handleInputs = (inputId: string, inputValue: string) => {
    handleInputs({ target: { name: inputId, value: inputValue.trim() } });
  };

  return (
    <View style={[ModalCardStyle, styles.container]}>
      <View style={styles.innerContainer}>
        <ScrollView
          style={{
            zIndex: 999,
          }}
        >
          <View style={styles.row}>
            <View style={styles.photoContainer}>
              <IconSVG
                svgComponent={addUserIcon}
                color={SharedStyles.Colors.White}
                containerStyle={styles.icon}
              />
            </View>
            <InputText
              placeHolder={t('alex:inviteWorker.emailInputPlaceholder')}
              containerStyle={styles.inputText}
              onTextChange={_handleInputs}
              defaultValue={searchedValue}
              inputId="email"
              notEditable={false}
              parser={[ParserType.CaseInsensitive]}
            />
          </View>
          {validEmail && !worker && (
            <View style={styles.unitsOuterContainer}>
              <WorkerAssignmentDropDowns
                assignments={organizationalUnitRoles}
                containerStyle={[styles.unitsContainer]}
                setAssignments={setOrganizationalUnitRoles}
              />
            </View>
          )}
          {worker && (
            <View style={styles.emailExistContainer}>
              <Text style={styles.emailExistText}>{t('alex:inviteWorker.workerExist')}</Text>
              <UserInfo
                index={1}
                item={worker}
                containerStyle={{ flexDirection: 'row', flex: 1 }}
                handleModalClose={handleModalClose}
              />
            </View>
          )}
        </ScrollView>
        <View style={[ModalFooterStyle, styles.buttonContainer]}>
          <YellowButton
            style={styles.buttomButton}
            textStyle={styles.ButtomButtonText}
            text={t('alex:inviteWorker.inviteButtonText')}
            onPress={handleSubmit}
            disabled={!infoValidity}
          />
        </View>
      </View>
    </View>
  );
};
