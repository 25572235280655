/**
 * API Failures
 */
import { Requirement, DataType, Factory, CastByType, GraphQLError } from 'backend/src/api';
import Aigle from 'aigle';
import isNumber from 'lodash-es/isNumber';

/**
 * Observed from runtime console on 2020/06. It is probably incomplete or not covering all the use cases.
 */
export interface CognitoAuthError {
  message: string;
  code: string;
  name: string;
}

export type FailureTypes = Failure['type'];
type FailureTypesWithoutData = Extract<Failure, { data: undefined | never }>['type'];
type FailureTypesWithData = Exclude<FailureTypes, FailureTypesWithoutData>;
export type Failure<T extends DataType = DataType> =
  | { type: 'UnhandledBackend'; message: string; data: unknown } 
  | { type: 'Unauthorized'; message: string; data: unknown } 
  | {
      type: 'LASTSYNC_TIMEOUT'; 
      message: string;
      data: never;
    }
  | { type: 'Unspecified'; message: string; data: object | undefined }
  | {
      type: 'MultipleFailures';
      message: string;
      data: Failure[];
    }
  | {
      type: 'AuthError';
      message: string;
      data: CognitoAuthError;
    }
  | {
      type: 'GraphQLError';
      message: string;
      data: { errors: ReadonlyArray<GraphQLError>; variables?: object };
    }
  | { type: 'FactoryCacheNotReady'; message: string; data: never }
  | {
      /**
       * Error is thrown when client makes a query (it might include several nextTokens), and at the time it returns a subscription event have hit the cache
       * As the QueryCache is mapped to the FactoryCache object, the result the client received might be wrong (for instance query with beginsWith of a property that has been mutated). As a consequence throw the following error
       */
      type: 'QueryCacheOutdated';
      message: string;
      data: never;
    }
  | { type: 'InvalidArguments'; message: string; data: never }
  | {
      type: 'WorkerIdentifierNotUnique';
      message: string;
      data: { type: 'Phone' | 'Email' | 'EmployeeId'; dependencyIds: string[] };
    }
  | { type: 'InvalidArgumentsContractTypeNotSet'; message: string; data: never }
  | { type: 'InvalidArgumentsContractDateNotValid'; message: string; data: never }
  | { type: 'ObjectNotFound'; message: string; data: never }
  | {
      type: 'ProofBundleSomeObjectNotFound';
      message: string;
      data: {
        failures: Failure[];
        sucesses: Factory<DataType.PROOFBUNDLE>[];
      };
    }
  | { type: 'ProofBundleNotReviewed'; message: string; data: never } 
  | { type: 'WorkerArchiveContractNotEnded'; message: string; data: never } 
  | {
      type: 'InviteWorkerMissingInformation';
      message: string;
      data: never;
    }
  | {
      type: 'DuplicateVeto';
      message: string;
      data: { dependencyIds: string[] };
    }
  | { type: 'DeletionVeto'; message: string; data: { dependencyIds: string[] } }
  | { type: 'UpdateVeto'; message: string; data: { dependencyIds: string[] } }
  | {
      type: 'TrainingVersionNotSet';
      message: string;
      data: {
        skillIdsWithoutTrainingSet: string[];
        neededTrainingVersionIds: Map<string, Requirement>;
      };
    }
  | {
      type: 'ActionNeedsContact';
      message: string;
      data: never;
    }
  | {
      type: 'UploadFailure';
      message: string;
      data: unknown;
    };

export interface WithNextToken<R> {
  result: R;
  nextToken?: string | null | undefined;
  startedAt?: number | null | undefined;
}

export type Result<R, F extends FailureTypes = FailureTypes, T extends DataType = DataType> =
  | R
  | CastByType<Failure<T>, F>;
export type ResultWithNextToken<R, T extends FailureTypes = FailureTypes> =
  | WithNextToken<R>
  | CastByType<Failure, T>;

export function isWithNextToken<T>(result: T | WithNextToken<T>): result is WithNextToken<T> {
  return (
    (result as WithNextToken<T>).result !== undefined ||
    (result as WithNextToken<T>).nextToken != null
  );
}

/**
 * Check if the given result is of type Failure.
 * Pattern to use:
 *   const result: Result<BusinessObject> = getResult();
 *   if (isFailure(result)) return result;
 *   
 * or
 *   const result: Result<BusinessObject> = getResult();
 *   if (isFailure(result)) {
 *     logger.warn(result);
 *   } else {
 *     
 *   }
 * @param result 'null' is a valid result and won't be considered as a Failure
 */
export function isFailure<T>(result: Result<T>): result is Failure {
  if (result == null) return false; 

  
  return (result as Failure).type !== undefined && (result as Failure).message !== undefined;
}

/**
 * Returns true if the given Failure matches the given type.
 * In case of MultipleFailures, if a nested Failure matches the given type,
 * it will *MUTATE* the original failure to match it.
 * @param failure
 * @param type
 * @param detectNestedFailure (default true) when set to false, nested failure are not inspected and the given failure NOT mutated.
 */
export function isFailureType<T extends FailureTypes>(
  failure: Failure,
  type: T,
  detectNestedFailure = true,
): failure is CastByType<Failure, T> {
  if (detectNestedFailure && failure.type === 'MultipleFailures') {
    for (const _failure of failure.data) {
      if (_failure.type === type) {
        
        failure = _failure;
        return true;
      }
    }
    return false;
  } else {
    return failure.type === type;
  }
}

/**
 * Create a typed Failure
 * @param type one of @see FailureTypes
 * @param message
 * @param data (optional)
 * @returns
 */
export function createFailure<T extends FailureTypesWithoutData, D extends DataType>(
  type: T,
  message: string,
): CastByType<Failure<D>, T>;
export function createFailure<T extends FailureTypesWithData, D extends DataType>(
  type: T,
  message: string,
  data: CastByType<Failure, T>['data'],
): CastByType<Failure<D>, T>;
/**
 * NEVER USED DIRECTLY.
 * If you read this javadoc it means the overload functions types are not well defined.
 * PLEASE FIX.
 */
export function createFailure<T extends FailureTypes, D extends DataType>(
  type: T,
  message: string,
  data?: unknown, 
): CastByType<Failure<D>, T> {
  return { type: type, message: message, data: data } as CastByType<Failure<D>, T>; 
}

export function createFailure_Multiple(
  failures: Failure[],
  message?: string,
): CastByType<Failure, 'MultipleFailures'> {
  return createFailure(
    'MultipleFailures',
    message ?? failures.map(failure => failure.message).join(' ; '),
    failures,
  );
}

/**
 * extract the potential Failures contained inside the given array
 * @param results array to inspect for Failure
 */
export function extractFailures<T>(results: Result<T>[]): Result<T[]> {
  const failures: Failure[] = results.filter(result => isFailure(result));
  if (failures.length) return createFailure_Multiple(failures);

  return results as T[]; 
}

export async function mapSeries<T, R>(
  collection: Aigle.List<T>,
  iterator: Aigle.ListIterator<T, Result<R>>,
): Promise<Result<R[]>> {
  return extractFailures(await Aigle.mapSeries<T, Result<R>>(collection, iterator));
}

export async function mapLimit<T, R>(
  collection: Aigle.List<T>,
  iterator: Aigle.ListIterator<T, Result<R>>,
): Promise<Result<R[]>>;
export async function mapLimit<T, R>(
  collection: Aigle.List<T>,
  limit: number,
  iterator: Aigle.ListIterator<T, Result<R>>,
): Promise<Result<R[]>>;
export async function mapLimit<T, R>(
  collection: Aigle.List<T>,
  limitOrIterator: number | Aigle.ListIterator<T, Result<R>>,
  iterator?: Aigle.ListIterator<T, Result<R>>,
): Promise<Result<R[]>> {
  if (isNumber(limitOrIterator)) {
    if (!iterator)
      return createFailure_Unspecified(
        'When calling mapLimit with a limit, the iterator shall be passed too.',
      );

    return extractFailures(
      await Aigle.mapLimit<T, Result<R>>(collection, limitOrIterator, iterator),
    );
  } else {
    if (iterator)
      return createFailure_Unspecified(
        'When calling mapLimit without a limit, the 3rd param shall not be passed.',
      );

    return extractFailures(await Aigle.mapLimit<T, Result<R>>(collection, limitOrIterator));
  }
}

/**
 * Create a Failure with type 'Unspecified'
 * @param messageOrData a string message or data object (in this case the message is the stringified data and data is passed automatically)
 * @param data only available if message is passed as string or boolean or number
 * @returns
 */
export function createFailure_Unspecified<T extends string | object | boolean | number>(
  messageOrData: T,
  data?: T extends object ? never : object,
): CastByType<Failure, 'Unspecified'> {
  if (messageOrData === undefined || messageOrData === null) {
    return { type: 'Unspecified', message: '', data: data };
  } else if (
    typeof messageOrData === 'string' ||
    typeof messageOrData === 'boolean' ||
    typeof messageOrData === 'number'
  ) {
    return { type: 'Unspecified', message: messageOrData.toString(), data: data };
  } else {
    
    return {
      type: 'Unspecified',
      message: JSON.stringify(messageOrData),
      data: messageOrData,
    };
  }
}

/**
 * Class to be used in the backend exclusively (in the frontend we extensively rely on Failure handling)
 */
export class FailureError<T extends FailureTypes> extends Error {
  type: T;
  
  error: CastByType<Failure, T>['data'] | undefined;

  constructor(failure: Failure);
  constructor(type: T extends FailureTypesWithoutData ? T : never, message: string);
  constructor(
    type: T extends FailureTypesWithData ? T : never,
    message: string,
    data: CastByType<Failure, T>['data'],
  );
  constructor(typeOrFailure: T | Failure, message?: string, data?: CastByType<Failure, T>['data']) {
    super(isFailure(typeOrFailure) ? typeOrFailure.message : message);
    this.name = isFailure(typeOrFailure) ? typeOrFailure.type : typeOrFailure; 
    this.type = isFailure(typeOrFailure) ? (typeOrFailure.type as T) : typeOrFailure; 
    this.error = isFailure(typeOrFailure)
      ? (typeOrFailure.data as CastByType<Failure, T>['data'])
      : data; 
    Error.captureStackTrace(this, this.constructor); 
  }
}
