import React, { useEffect, useState, createContext } from 'react';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';

import Aigle from 'aigle';

export interface WorkerAssignmentsInfo {
  worker: API.Worker;
  organizationalUnits: string;
  roles: string;
  authoizationsCount: number;
}

/**
 * @param workers all workers data available
 * @param filteredWorkers workers filtered according to search input text
 */
export const inviteWorkerModalFilterContext = createContext<{
  workers: [WorkerAssignmentsInfo[], (item: WorkerAssignmentsInfo[]) => void];
  filteredWorkersData: [WorkerAssignmentsInfo[], (item: WorkerAssignmentsInfo[]) => void];
  refreshData: () => void;
  loading: [boolean, (load: boolean) => void];
}>({
  workers: [[], () => {}],
  filteredWorkersData: [[], () => {}],
  refreshData: () => {},
  loading: [false, () => {}],
});

export const InviteWorkerModalFilterProvider: React.FC = props => {
  const [workers, setWorkers] = useState<WorkerAssignmentsInfo[]>([]);
  const [filteredWorkersData, setFilteredWorkersData] = useState<WorkerAssignmentsInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  function getWorkerDetails(
    workers: API.Worker[],
    workersAssignmentsMap: Map<string, API.AssignmentWithUnitDetails[]>,
  ): WorkerAssignmentsInfo[] {
    const rowsData: WorkerAssignmentsInfo[] = [];
    setLoading(true);

    _.forEach(workers, worker => {
      const units: string[] = [];
      const roles: string[] = [];
      let permissions: API.Permission[] = [];
      const workerAssignments = workersAssignmentsMap.get(worker.id)!;
      _.forEach(workerAssignments, workerAssignment => {
        units.push(workerAssignment.organizationalUnit.name);
        if (workerAssignment.permissions) {
          permissions = [...permissions, ...workerAssignment.permissions];
          roles.push(workerAssignment.role.name);
        }
      });

      rowsData.push({
        worker: worker,
        organizationalUnits: units.join(', '),
        roles: roles.join(', '),
        authoizationsCount: _.uniq(permissions).length,
      });
    });

    setLoading(false);

    return rowsData;
  }

  async function getWorkersData(): Promise<API.Result<WorkerAssignmentsInfo[] | undefined>> {
    const workers = await API.getWorkers(undefined, undefined, false, null);
    if (!isMounted.current) return;
    if (API.isFailure(workers)) return workers;

    const workersAssignmentsMap = await API.getWorkersAssignments(
      false,
      true,
      workers.result.map(worker => worker.id),
    );
    if (!isMounted.current) return;
    if (API.isFailure(workersAssignmentsMap)) return workersAssignmentsMap;

    const rowsData = getWorkerDetails(workers.result, workersAssignmentsMap);

    const sortedRows = _.orderBy(rowsData, row => row.worker.name.toLowerCase(), ['asc']);
    setWorkers(sortedRows);
    return sortedRows;
  }

  async function refreshData() {
    const workersData = await getWorkersData();
    if (API.isFailure(workersData)) return;
    if (!isMounted.current) return;
    if (filteredWorkersData.length) {
      const _workers = workersData!.filter(workerData =>
        filteredWorkersData.some(
          filteredWorkerData => workerData.worker.id === filteredWorkerData.worker.id,
        ),
      );
      setFilteredWorkersData(_workers);
    }
  }

  async function setInitialState() {
    const workersData = await getWorkersData();
    if (!isMounted.current) return;
    if (API.isFailure(workersData)) return;

    if (workersData) {
      setFilteredWorkersData(
        workersData.filter(workerData => {
          return workerData.worker.userId;
        }),
      );
    }
  }

  useEffect(() => {
    setInitialState();
  }, []);

  return (
    <inviteWorkerModalFilterContext.Provider
      value={{
        workers: [workers, setWorkers],
        filteredWorkersData: [filteredWorkersData, setFilteredWorkersData],
        refreshData: () => refreshData(),
        loading: [loading, setLoading],
      }}
    >
      {props.children}
    </inviteWorkerModalFilterContext.Provider>
  );
};
