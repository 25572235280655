import * as QueriesSchema from 'backend/src/api/graphql/queries';
import * as MutationsSchema from 'backend/src/api/graphql/mutations';
import { API as APIgraphql, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as API from 'shared/backend-data';
import * as APIbr from './BusinessRulesAPI';
import {
  CastByType,
  Mutations,
  ItemsQueries,
  MiscQueries,
  DataType,
  DataTypeBusinessObject,
  DataTypeRelationBusinessObject,
  DataTypeUnknown,
  Factory,
  ResultWithNextToken,
  QueryVariables,
  GraphQLResult,
  WritableFactory,
  ItemsQueriesType,
  FetchPolicy,
  Result,
} from 'shared/backend-data';
import { AppContext } from 'shared/context/AppContext';
import { loggerAPI as logger, loggerPerf } from 'shared/util/Logger';
import { omitByRecursively, searchMatch } from 'shared/util-ts/Functions';
import * as _ from 'lodash-es';
import * as Perf from '../util/Perf';
import { removeProps } from './gql';
import { UserContext } from '../context/UserContext';
import { DataLayer } from './DataLayer';
import { GetFactoryQueryVariables } from 'backend/src/api/api-auto';
import { QueryCache } from './factoryCache/QueryCache';
import { MyHub } from '../util/MyHub';

interface GraphQLException {
  graphQLErrors: API.GraphQLError[];
  errors: API.GraphQLError[];
}

/**
 * Convert AppSync Server errors into typed Failure
 * @param graphQLResponse the query response holding an possible error
 * @param queryOrMutationVariables only used for loging error
 * @returns
 */
export function convertServerExceptionToFailure(
  graphQLResponse: GraphQLException | Error | unknown,
  queryOrMutationVariables: object,
): API.Failure {
  logger.debug(
    'Backend Exception received:',
    graphQLResponse,
    'from query',
    queryOrMutationVariables,
  );

  if (!graphQLResponse)
    return API.createFailure(
      'UnhandledBackend',
      'graphQLResponse is null or undefined',
      graphQLResponse,
    );

  if (!(graphQLResponse as GraphQLException).graphQLErrors && graphQLResponse instanceof Error) {
    return API.createFailure('UnhandledBackend', graphQLResponse.message, graphQLResponse);
  }

  const errors: ReadonlyArray<API.AppSyncGraphQLError> | undefined =
    (graphQLResponse as GraphQLException).graphQLErrors ||
    (graphQLResponse as GraphQLException).errors; 

  if (errors?.length) {
    const failures = errors.map(error => {
      if (!error.errorType) return API.createFailure('UnhandledBackend', error.message, error.data);

      
      
      return API.createFailure(
        error.errorType as any,
        error.message,
        error.errorInfo ?? (error.data as any),
      );
    });
    if (failures.length === 1) {
      return failures[0];
    } else {
      return API.createFailure_Multiple(failures);
    }
  }

  const unknownError = [
    'Backend unknown error',
    graphQLResponse,
    'on query:',
    JSON.stringify(queryOrMutationVariables),
  ];
  return API.createFailure('UnhandledBackend', JSON.stringify(unknownError), unknownError);
}

const inFlightQueries = new Map<string, Promise<GraphQLResult<any>>>();

/**
 * Proxy to amplify GraphQL operation that takes care to
 * track duplicate queries and send them only once to the server
 * @param query
 * @param queryVariables
 * @param authToken
 * @returns
 */
async function graphql<T>(query: string, queryVariables?: {}): Promise<GraphQLResult<T>> {
  const queryHash =
    DataLayer.mutationSentOrReceivedCounter + query + JSON.stringify(queryVariables);

  let inFlightQuery = inFlightQueries.get(queryHash) as Promise<GraphQLResult<T>> | undefined; 
  if (inFlightQuery) {
    
    logger.debug(
      'Duplicate Query. Waiting for the already send query result',
      JSON.stringify(queryVariables),
    );
  } else {
    
    inFlightQuery = APIgraphql.graphql<GraphQLQuery<T>>(
      graphqlOperation(query, queryVariables),
    ) as Promise<GraphQLResult<T>>;
    inFlightQueries.set(queryHash, inFlightQuery);
  }

  const result = await inFlightQuery;
  inFlightQueries.delete(queryHash);

  return result;
}

/**
 * Developer should not be dealing with primary keys
 * This function should be called whenever one needs primary key
 */
function getFactoryPrimaryKey(factoryDataType: DataType): API.Result<string> {
  switch (factoryDataType) {
    case DataType.APP:
      return API.globalPk;

    case DataType.WORKER_TENANT_APP:
      const user = UserContext.getUser();
      if (API.isFailure(user)) return user;
      return user.id;

    case DataType.TENANT:
    case DataType.TENANT_APP:
      const appContext = AppContext.getContext();
      if (API.isFailure(appContext)) return appContext;

      const key = appContext.pk.split(API.SeparatorIds);
      return key[0];

    case DataType.TENANTID:
      return API.tenantIDsPk;

    default: {
      const appContext = AppContext.getContext();
      if (API.isFailure(appContext)) return appContext;

      return appContext.pk;
    }
  }
}

/**
 * Get the Factory's SK and Factory's DataType for a Factory composed of 1 to several BusinessObjects (akka relation objects)
 * WARNING the inferred type is expected to match the passes businessObjectIds
 * @param businessObject1Id
 * @param businessObject2Id
 * @param businessObject3Id
 */
export function getSkAndDataType<T extends DataType>(
  businessObject1Id: string,
  businessObject2Id?: string,
  businessObject3Id?: string,
): API.Result<[string, T]> {
  let sk: string;
  if (!businessObject2Id) {
    sk = businessObject1Id;
  } else if (!businessObject3Id) {
    sk = businessObject1Id + API.SeparatorIds + businessObject2Id;
  } else {
    sk =
      businessObject1Id +
      API.SeparatorIds +
      businessObject2Id +
      API.SeparatorIds +
      businessObject3Id;
  }
  const dataType = API.getDataType<T>(sk); 
  if (API.isFailure(dataType)) {
    return dataType;
  }

  return [sk, dataType];
}

/**
 * Update factory with the given BusinessObject by merging businessObject's properties.
 * BusinessObject's properties set to 'undefined' will not be merged into factory
 * whereas 'null' properties will be merged
 * @param createFactory
 * @param businessObject
 */
function mergeFactoryInputProperty<T extends DataType, V extends API.DataTypeBusinessObject>(
  updateFactoryDataType: T,
  updateFactory: API.UpdateFactoryInput<typeof updateFactoryDataType>,
  businessObjectDataType: V,
  businessObject: API.BusinessObjectUpdateInput<typeof businessObjectDataType>,
): API.Result<
  API.DeepMergeTwoTypes<
    API.UpdateFactoryInput<typeof updateFactoryDataType>,
    API.UpdateFactoryInput<typeof businessObjectDataType>
  >
>;
function mergeFactoryInputProperty<T extends DataType, V extends API.DataTypeBusinessObject>(
  createFactoryDataType: T,
  createFactory: API.CreateFactoryInput<typeof createFactoryDataType>,
  businessObjectDataType: V,
  businessObject: API.BusinessObjectCreateInput<typeof businessObjectDataType>,
): API.Result<
  API.DeepMergeTwoTypes<
    API.CreateFactoryInput<typeof createFactoryDataType>,
    API.CreateFactoryInput<typeof businessObjectDataType>
  >
>;
function mergeFactoryInputProperty<T extends DataType, T1 extends API.DataTypeBusinessObject>(
  createFactoryDataType: T,
  createFactory: API.CreateFactoryInput<T>,
  businessObjectDataType: T1,
  businessObject: API.BusinessObjectCreateInput<T1>,
): API.Result<API.DeepMergeTwoTypes<API.CreateFactoryInput<T>, API.CreateFactoryInput<T1>>> {
  
  if (createFactoryDataType !== createFactory.dataType)
    return API.createFailure_Unspecified(
      'mergeFactoryInputProperty() passed dataType:' +
        createFactoryDataType +
        ' shall match the dataType of the input:' +
        createFactory.dataType,
    );

  const mergedCreateFactory = { ...createFactory } as any; 
  const propertyName = API.businessObjectFactoryKey[businessObjectDataType];
  if (!mergedCreateFactory[propertyName]) {
    mergedCreateFactory[propertyName] = {}; 
  }
  mergePartialBusinessObject(mergedCreateFactory[propertyName], businessObject as Object); 

  return mergedCreateFactory;
}

/**
 * Merge a Partial.BusinessObject into a given BusinessObject (passed object is mutated).
 * If PartialObject contains undefined value - recursively - they will not be copied to Object.
 * @param businessObject
 * @param partialBusinessObject
 */
function mergePartialBusinessObject<T extends U, U extends Object>(
  businessObject: T,
  partialBusinessObject: U,
): void {
  Object.assign(businessObject, partialBusinessObject);
}

/**
 * Remove null values & API.Metadata & DBreservedFactoryProperties props recursively
 * @param object
 */
export function removeNullAndMetadataAndTypeName<T extends Object>(object: T): API.NoMetadata<T> {
  return omitByRecursively(object, (value: any, key: string) => {
    return (
      value == null || 
      key === 'updatedAt' || 
      key === 'updatedBy' || 
      
      API.DBreservedFactoryProperties.includes(key)
    );
  });
}

async function _getFactoryPartialUpdateInput<T extends DataType>(
  /**
   * Test types by removing all the 'as any' inside the function, replace M by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  dataType: T,
  sk: string,
): Promise<
  API.Result<CastByType<Mutations<typeof dataType>, 'updateFactory'>['variables']['input']>
> {
  
  
  
  const factory = await getFactory(dataType, sk);
  if (API.isFailure(factory)) return factory;

  return API.deepClone(factory) as any; 
}

export function getGetFactoryQueryVariables<T extends DataType>(
  sk: string,
  dataType: T,
): API.Result<QueryVariables<'getFactory', T>> {
  const pk = getFactoryPrimaryKey(dataType);
  if (API.isFailure(pk)) return pk;

  let getFactoryQueryVariables: QueryVariables<'getFactory', T>;
  if (dataType === DataType.WORKER_TENANT_APP) {
    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) return appContext;

    getFactoryQueryVariables = {
      pk: pk,
      sk: appContext.pk, 
    };
  } else {
    getFactoryQueryVariables = {
      pk: pk,
      sk: sk,
    };
  }
  return getFactoryQueryVariables;
}

export function getDeleteFactoryMutationVariables<T extends DataType>(
  sk: string,
  dataType: T,
  deleteDependencies?: boolean,
): API.Result<CastByType<Mutations<T>, 'deleteFactory'>['variables']> {
  const pk = getFactoryPrimaryKey(dataType);
  if (API.isFailure(pk)) return pk;

  let deleteFactoryMutationVariables: CastByType<Mutations<T>, 'deleteFactory'>['variables'];
  if (dataType === DataType.WORKER_TENANT_APP) {
    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) return appContext;

    deleteFactoryMutationVariables = {
      input: { pk: pk, sk: appContext.pk, deleteDependencies: deleteDependencies },
    };
  } else {
    deleteFactoryMutationVariables = {
      input: { pk: pk, sk: sk, deleteDependencies: deleteDependencies },
    };
  }
  return deleteFactoryMutationVariables;
}

/**
 * Create the Factory for the given BusinessObject and DataType.
 * @param dataType
 * @param businessObject
 * @param disableBusinessRules
 */
export async function createFactoryBusinessObject<T extends API.DataTypeBusinessObject>(
  dataType: T,
  businessObject: API.BusinessObjectCreateInput<T>,
  disableBusinessRules = false, 
): Promise<API.Result<Factory<T>>> {
  return _createFactory(dataType, businessObject, undefined, undefined, disableBusinessRules);
}

export function getFactoryCreateInput<T extends DataType>(
  pk: string,
  dataType: T,
  businessObject1: API.BusinessObjectCreateInput<API.DataTypeBusinessObject>,
  businessObject2?: API.BusinessObjectCreateInput<API.DataTypeBusinessObject>,
  businessObject3?: API.BusinessObjectCreateInput<API.DataTypeBusinessObject>,
): API.Result<API.CreateFactoryInput<typeof dataType>> {
  let factoryCreateInput = {
    pk,
    sk: 'auto',
    dataType,
  } as API.CreateFactoryInput<typeof dataType>; 

  return _mergeFactoryProperties(
    dataType,
    factoryCreateInput,
    businessObject1,
    businessObject2,
    businessObject3,
  );
}

/**
 * When used by backend, it is mandatory to specify getFactoryPrimaryKeyBackend (which differs from frontend)
 * @param dataType
 * @param businessObject1
 * @param businessObject2
 * @param businessObject3
 * @param getFactoryPrimaryKeyBackend
 * @returns
 */
export async function getFactoryUpdateInput<
  T extends DataType,
  T1 extends API.DataTypeBusinessObject,
  T2 extends API.DataTypeBusinessObject,
  T3 extends API.DataTypeBusinessObject,
>(
  dataType: T,
  businessObject1: API.BusinessObjectUpdateInput<T1>,
  businessObject2?: API.BusinessObjectUpdateInput<T2>,
  businessObject3?: API.BusinessObjectUpdateInput<T3>,
  getFactoryPrimaryKeyBackend?: (factoryDataType: DataType) => string,
): Promise<API.Result<API.UpdateFactoryInput<typeof dataType>>> {
  let pk: string;
  if (getFactoryPrimaryKeyBackend) {
    pk = getFactoryPrimaryKeyBackend(dataType);
  } else {
    const _pk = getFactoryPrimaryKey(dataType);
    if (API.isFailure(_pk)) return _pk;
    pk = _pk;
  }

  return _getFactoryUpdateInput({
    _getFactoryUpdateInputStrategy: async (dataType: T, sk: string) => {
      return {
        pk,
        sk,
        dataType,
      };
    },
    dataType,
    businessObject1,
    businessObject2,
    businessObject3,
  });
}

/**
 * Reserved to frontEnd but it can be extended to backend by passing the getFactoryPrimaryKeyBackend like in #getFactoryUpdateInput()
 * @param dataType
 * @param businessObject1
 * @param businessObject2
 * @param businessObject3
 * @returns
 */
export async function getFactoryPartialUpdateInput<T extends DataType>(
  dataType: T,
  businessObject1: API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>,
  businessObject2?: API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>,
  businessObject3?: API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>,
): Promise<API.Result<API.UpdateFactoryInput<T>>> {
  return _getFactoryUpdateInput({
    _getFactoryUpdateInputStrategy: _getFactoryPartialUpdateInput,
    dataType,
    businessObject1,
    businessObject2,
    businessObject3,
  }) as Promise<API.Result<API.UpdateFactoryInput<T>>>;
}

type Args<T extends API.DataType> =
  | {
      _getFactoryUpdateInputStrategy: (
        dataType: T,
        sk: string,
      ) => Promise<API.Result<{ pk: string; sk: string; dataType: T }>>;
      dataType: T;
      businessObject1: API.BusinessObjectUpdateInput<DataTypeBusinessObject>;
      businessObject2?: API.BusinessObjectUpdateInput<DataTypeBusinessObject>;
      businessObject3?: API.BusinessObjectUpdateInput<DataTypeBusinessObject>;
    }
  | {
      _getFactoryUpdateInputStrategy: (
        dataType: T,
        sk: string,
      ) => Promise<API.Result<API.UpdateFactoryInput<T>>>;
      dataType: T;
      businessObject1: API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>;
      businessObject2?: API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>;
      businessObject3?: API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>;
    };
async function _getFactoryUpdateInput<T extends DataType>(
  args: Args<T>,
): Promise<API.Result<API.UpdateFactoryInput<T>>> {
  const {
    _getFactoryUpdateInputStrategy,
    dataType,
    businessObject1,
    businessObject2,
    businessObject3,
  } = args;

  const skAndDataType = getSkAndDataType(
    (businessObject1 as API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>).id,
    (businessObject2 as API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>)?.id,
    (businessObject3 as API.BusinessObjectPartialUpdateInput<DataTypeBusinessObject>)?.id,
  ) as API.Result<[string, typeof dataType]>;
  if (API.isFailure(skAndDataType)) return skAndDataType;
  if (skAndDataType[1] !== dataType)
    return API.createFailure_Unspecified(
      'DataType are not matching: ' + dataType + ' != ' + skAndDataType[1],
    );

  let factoryUpdateInput = (await _getFactoryUpdateInputStrategy(
    skAndDataType[1],
    skAndDataType[0],
  )) as API.UpdateFactoryInput<T>; 
  
  if (API.isFailure(factoryUpdateInput)) return factoryUpdateInput;

  return _mergeFactoryProperties(
    dataType,
    factoryUpdateInput,
    businessObject1,
    businessObject2,
    businessObject3,
  );
}

/**
 * Merge passed businessObjects into the factoryCreateOrUpdateInput which is mutated
 * @param dataType
 * @param factoryUpdateInput is mutated
 * @param businessObject1
 * @param businessObject2
 * @param businessObject3
 */
function _mergeFactoryProperties<
  T extends DataType,
  T1 extends API.DataTypeBusinessObject,
  T2 extends API.DataTypeBusinessObject,
  T3 extends API.DataTypeBusinessObject,
>(
  dataType: T,
  factoryUpdateInput: API.UpdateFactoryInput<typeof dataType>,
  businessObject1: API.BusinessObjectUpdateInput<T1>,
  businessObject2?: API.BusinessObjectUpdateInput<T2>,
  businessObject3?: API.BusinessObjectUpdateInput<T3>,
): API.Result<API.UpdateFactoryInput<typeof dataType>>;
function _mergeFactoryProperties<
  T extends DataType,
  T1 extends API.DataTypeBusinessObject,
  T2 extends API.DataTypeBusinessObject,
  T3 extends API.DataTypeBusinessObject,
>(
  dataType: T,
  factoryCreateInput: API.CreateFactoryInput<typeof dataType>,
  businessObject1: API.BusinessObjectCreateInput<T1>,
  businessObject2?: API.BusinessObjectCreateInput<T2>,
  businessObject3?: API.BusinessObjectCreateInput<T3>,
): API.Result<API.CreateFactoryInput<typeof dataType>>;
function _mergeFactoryProperties<
  T extends DataType,
  T1 extends API.DataTypeBusinessObject,
  T2 extends API.DataTypeBusinessObject,
  T3 extends API.DataTypeBusinessObject,
>(
  dataType: T,
  factoryCreateInput: API.CreateFactoryInput<typeof dataType>,
  businessObject1: API.BusinessObjectCreateInput<T1>,
  businessObject2?: API.BusinessObjectCreateInput<T2>,
  businessObject3?: API.BusinessObjectCreateInput<T3>,
): API.Result<API.CreateFactoryInput<typeof dataType>> {
  const dataTypes = dataType.split(API.SeparatorDataTypeRelation);
  if (dataTypes.length === 0) {
    return API.createFailure_Unspecified('DataType cannot be null');
  }

  const update1 = mergeFactoryInputProperty(
    dataType,
    factoryCreateInput,
    dataTypes[0] as T1,
    businessObject1,
  );
  if (API.isFailure(update1)) return update1;
  factoryCreateInput = update1 as any; 

  if (businessObject2 && dataTypes.length >= 2) {
    const update2 = mergeFactoryInputProperty(
      dataType,
      factoryCreateInput,
      dataTypes[1] as T2,
      businessObject2,
    );
    if (API.isFailure(update2)) return update2;
    factoryCreateInput = update2 as any; 
  } else if (businessObject2 || dataTypes.length >= 2) {
    return API.createFailure_Unspecified(
      'DataType ' +
        dataType +
        ' is not matching a relationship factory object with 2 Business Objects',
    );
  }

  if (businessObject3 && dataTypes.length >= 3) {
    const update3 = mergeFactoryInputProperty(
      dataType,
      factoryCreateInput,
      dataTypes[2] as T3,
      businessObject3,
    );
    if (API.isFailure(update3)) return update3;
    factoryCreateInput = update3 as any; 
  } else if (businessObject3 || dataTypes.length >= 3) {
    return API.createFailure_Unspecified(
      'DataType ' +
        dataType +
        ' is not matching a relationship factory object with 3 Business Objects',
    );
  }

  return factoryCreateInput;
}

async function _createFactory<T extends DataType>(
  dataType: T,
  businessObject1: API.BusinessObjectCreateInput<API.DataTypeBusinessObject>,
  businessObject2?: API.BusinessObjectCreateInput<API.DataTypeBusinessObject>,
  businessObject3?: API.BusinessObjectCreateInput<API.DataTypeBusinessObject>,
  disableBusinessRules = false, 
): Promise<API.Result<Factory<typeof dataType>>> {
  let pk;
  if (dataType === API.DataType.TENANT || dataType === API.DataType.TENANT_APP) {
    const user = UserContext.getUser();
    if (API.isFailure(user)) return user;

    pk = user.id;
  } else {
    pk = getFactoryPrimaryKey(dataType);
    if (API.isFailure(pk)) return pk;
  }

  const factoryCreateInput = getFactoryCreateInput(
    pk,
    dataType,
    businessObject1,
    businessObject2,
    businessObject3,
  );
  if (API.isFailure(factoryCreateInput)) return factoryCreateInput;

  if (disableBusinessRules) {
    return _mutateFactoryAPI('createFactory', factoryCreateInput.dataType, {
      input: factoryCreateInput,
    }) as Promise<API.Result<Factory<typeof dataType>>>;
  } else {
    return createFactoryWithBusinessRules(factoryCreateInput);
  }
}

export async function createFactoryTenantAppRelation(
  tenant: API.Factory<API.DataType.TENANT>,
  app: API.Factory<API.DataType.APP>,
): Promise<API.Result<Factory<DataType.TENANT_APP>>> {
  return _createFactory(DataType.TENANT_APP, tenant.tenant, app.app);
}

export async function createFactoryTrainingTrainingVersionRelation(
  training: API.TrainingCreateInput,
  trainingVersion: API.TrainingVersionCreateInput,
): Promise<API.Result<Factory<DataType.TRAINING_TRAININGVERSION>>> {
  return _createFactory(DataType.TRAINING_TRAININGVERSION, training, trainingVersion);
}

/**
 * Create Factory with Business Rules.
 * This proxy function aims to be removed when Business Rules will be moved to the backend
 * @param sk
 * @param dataType
 */
async function createFactoryWithBusinessRules<T extends DataType>(
  createInput: API.CreateFactoryInput<T>,
): Promise<API.Result<Factory<T>>> {
  switch (createInput.dataType) {
    case DataType.WORKERTRAININGSESSION:
      return API.createFailure_Unspecified('Forbiden. Please call createTrainingSession()');

    case DataType.TRAININGTAG:
      return APIbr.createTRAININGTAG(
        createInput as API.CreateFactoryInput<typeof createInput.dataType>,
      ) as Promise<API.Result<Factory<T>>>;

    default:
      return _mutateFactoryAPI('createFactory', createInput.dataType, {
        input: createInput as API.CreateFactoryInput<typeof createInput.dataType>,
      }) as Promise<API.Result<Factory<T>>>;
  }
}
/**
 * WARMNING. This function is NOT intended to be called (except from APIBusinessRules.ts or migration scripts)
 * @param mutationType
 * @param variables
 * @returns
 */
async function _mutateFactoryAPI<M extends Mutations<T>['type'], T extends DataType>(
  /**
   * Test types by removing all the 'as any' inside the function, replace M by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  mutationType: M,
  dataType: T,
  variables: CastByType<Mutations<typeof dataType>, typeof mutationType>['variables'],
): Promise<API.Result<Factory<typeof dataType>>> {
  
  if (
    !logger.isPROD &&
    (API.isMutationCreate(mutationType) || API.isMutationUpdate(mutationType))
  ) {
    const inputDataType = (
      variables as CastByType<Mutations<typeof dataType>, typeof mutationType>['variables']
    ).input.dataType;

    if (dataType !== inputDataType)
      return API.createFailure_Unspecified(
        '#_mutateFactoryAPI passed dataType:' +
          dataType +
          ' shall match the dataType of the input:' +
          inputDataType,
      );
  }

  const _variables = removeNullAndMetadataAndTypeName(variables);
  logger.verbose(mutationType + 'MutationVariables:', _variables);
  Perf.captureStackTrace();
  Perf.captureString(
    _variables.input.sk.substring(0, _variables.input.sk.indexOf(API.SeparatorDataType)),
    Perf.Maps[mutationType],
  );

  try {
    DataLayer.mutationSentOrReceivedCounter++;

    const result = await graphql<
      CastByType<Mutations<typeof dataType>, typeof mutationType>['result']
    >(removeProps(dataType, MutationsSchema[mutationType]), _variables);

    logger.debug(mutationType + ' result:', result, 'on query', _variables);

    const factory = API.extractDataFromFetchResult(mutationType, result as any, dataType); 
    if (API.isFailure(factory)) return factory;

    if (!result.errors?.length && factory) {
      return DataLayer.updateCacheAndBroadCast(factory);
    }
    return convertServerExceptionToFailure(result, _variables);
  } catch (error) {
    return convertServerExceptionToFailure(error, _variables);
  }
}

/**
 * Delete the Factory composed of 1 to several BusinessObjects (akka relation objects)
 * @param businessObject1Id
 * @param businessObject2Id (optional)
 * @param businessObject3Id (optional)
 * @param disableBusinessRules (optional, default=false), 
 * @param deleteDependencies (optional, default=false)
 */
export async function deleteFactoryBusinessObject<T extends DataType>(
  businessObject1Id: string,
  businessObject2Id?: string,
  businessObject3Id?: string,
  disableBusinessRules = false, 
  deleteDependencies?: boolean,
): Promise<API.Result<Factory<T>>> {
  const skAndDataType = getSkAndDataType<T>(
    businessObject1Id,
    businessObject2Id,
    businessObject3Id,
  );
  if (API.isFailure(skAndDataType)) return skAndDataType;

  if (disableBusinessRules) {
    return _deleteFactory(skAndDataType[0], skAndDataType[1]);
  } else {
    return deleteFactoryWithBusinessRules(skAndDataType[0], skAndDataType[1], deleteDependencies);
  }
}

/**
 * Delete Factory with Business Rules.
 * This proxy function aims to be removed when Business Rules will be moved to the backend
 * @param sk
 * @param dataType
 */
async function deleteFactoryWithBusinessRules<T extends DataType>(
  sk: string,
  /**
   * Test types by removing all the 'as any' inside the function, replace T by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  dataType: T,
  deleteDependencies?: boolean,
): Promise<API.Result<Factory<typeof dataType>>> {
  switch (dataType) {
    case DataType.ORGUNIT:
      return APIbr.deleteORGUNIT(sk, deleteDependencies) as any; 

    case DataType.SKILL:
      return APIbr.deleteSKILL(sk, deleteDependencies) as any; 

    case DataType.TENANT:
      return APIbr.deleteTENANT(sk) as any; 

    case DataType.TENANT_APP:
      return APIbr.deleteTENANT_APP(sk) as any; 

    case DataType.WORKERTRAININGSESSION:
      return API.createFailure_Unspecified(
        'Forbiden. Please call deleteWorker or deleteTrainingSession() or updateTrainingSession()',
      );

    case DataType.WORKSTATION:
      return APIbr.deleteWORKSTATION(sk, deleteDependencies) as any; 

    default:
      return _deleteFactory(sk, dataType, deleteDependencies);
  }
}

/**
 * This function is NOT intended to be called (except from APIBusinessRules.ts)
 * @param sk
 * @param dataType
 */
async function _deleteFactory<T extends DataType>(
  sk: string,
  dataType: T,
  deleteDependencies?: boolean,
): Promise<API.Result<Factory<typeof dataType>>> {
  const deleteMutationVariables = getDeleteFactoryMutationVariables(
    sk,
    dataType,
    deleteDependencies,
  );
  if (API.isFailure(deleteMutationVariables)) return deleteMutationVariables;

  return _mutateFactoryAPI('deleteFactory', dataType, deleteMutationVariables);
}

/**
 * Update a Factory composed of 1 or several BusinessObjects.
 * In case of a Factory with several BusinessObjects, the order shall match the targeted DataType
 * following the pattern businessObject1DataType_businessObject2DataType_businessObject3DataType_etc.
 * Partial update is supported: FactoryBusinessObjectUpdateInput properties set to 'undefined' will not override the origin database object properties
 * whereas properties set to 'null' will update the origin property with null value
 * @param businessObject1
 * @param businessObject2 (optional)
 * @param businessObject3 (optional)
 * @paeram disableBusinessRules (optional, default false) AONL BusinessRulesAPI shall set it to true
 */
export async function updateFactoryBusinessObject<T extends DataTypeBusinessObject>(
  dataType: T,
  businessObject1: API.BusinessObjectPartialUpdateInput<T>,
  businessObject2?: undefined,
  businessObject3?: undefined,
  disableBusinessRules?: boolean,
): Promise<API.Result<Factory<T>>>;
export async function updateFactoryBusinessObject<
  T extends DataTypeRelationBusinessObject,
  T1 extends DataTypeBusinessObject,
  T2 extends DataTypeBusinessObject,
  T3 extends DataTypeBusinessObject,
>(
  dataType: T,
  businessObject1: API.BusinessObjectPartialUpdateInput<T1>,
  businessObject2?: API.BusinessObjectPartialUpdateInput<T2>,
  businessObject3?: API.BusinessObjectPartialUpdateInput<T3>,
  disableBusinessRules?: boolean,
): Promise<API.Result<Factory<T>>>;
export async function updateFactoryBusinessObject<
  T extends DataType,
  T1 extends DataTypeBusinessObject,
  T2 extends DataTypeBusinessObject,
  T3 extends DataTypeBusinessObject,
>(
  dataType: T,
  businessObject1: API.BusinessObjectPartialUpdateInput<T1>,
  businessObject2?: API.BusinessObjectPartialUpdateInput<T2>,
  businessObject3?: API.BusinessObjectPartialUpdateInput<T3>,
  disableBusinessRules = false,
): Promise<API.Result<Factory<T>>> {
  const factoryUpdateInput = await getFactoryPartialUpdateInput(
    dataType,
    businessObject1,
    businessObject2,
    businessObject3,
  );
  if (API.isFailure(factoryUpdateInput)) return factoryUpdateInput;

  if (disableBusinessRules) {
    return _mutateFactoryAPI('updateFactory', factoryUpdateInput.dataType, {
      input: factoryUpdateInput,
    }) as Promise<API.Result<Factory<T>>>;
  } else {
    return updateFactoryWithBusinessRules<T>(factoryUpdateInput);
  }
}


export async function updateFactoryTenant(
  tenant: API.BusinessObjectPartialUpdateInput<DataType.TENANT>,
): Promise<API.Result<Factory<DataType.TENANT>>> {
  let factory = await _getFactoryPartialUpdateInput(DataType.TENANT, tenant.id);
  if (API.isFailure(factory)) return factory;

  
  const propertyName = API.businessObjectFactoryKey[DataType.TENANT];

  if (!factory[propertyName]) {
    return API.createFailure_Unspecified(
      'By design the factoryUpdateInput shall contain the BusinessObject ' + propertyName,
    );
  }
  mergePartialBusinessObject(factory[propertyName], tenant);

  return updateFactoryWithBusinessRules(factory);
}


export async function updateFactoryApp(
  app: API.AppPartialUpdateInput,
): Promise<API.Result<Factory<DataType.APP>>> {
  const factory = await _getFactoryPartialUpdateInput(DataType.APP, app.id);
  if (API.isFailure(factory)) return factory;

  
  const propertyName = API.businessObjectFactoryKey[DataType.APP];

  if (!factory[propertyName]) {
    return API.createFailure_Unspecified(
      'By design the factoryUpdateInput shall contain the BusinessObject ' + propertyName,
    );
  }
  mergePartialBusinessObject(factory[propertyName], app);

  return updateFactoryWithBusinessRules(factory);
}

/**
 * DeleteFactory with Business Rules.
 * This proxy function aims to be removed when Business Rules will be moved to the backend
 * @param sk
 * @param dataType
 */
async function updateFactoryWithBusinessRules<T extends DataType>(
  updateInput: API.UpdateFactoryInput<T>,
): Promise<API.Result<Factory<T>>> {
  switch (updateInput.dataType) {
    case DataType.TENANT_APP:
      return API.createFailure_Unspecified(
        'Tenant_App cannot be created directly. Either update Tenant or App.',
      );

    case DataType.WORKERTRAININGSESSION:
      return API.createFailure_Unspecified(
        'Forbiden. Please use updateWorker() or updateTrainingSession()',
      );

    case DataType.TRAININGTAG:
      return APIbr.updateTRAININGTAG(
        updateInput as API.UpdateFactoryInput<typeof updateInput.dataType>,
      ) as Promise<API.Result<Factory<T>>>; 

    default:
      return _mutateFactoryAPI('updateFactory', updateInput.dataType, {
        input: updateInput as API.UpdateFactoryInput<typeof updateInput.dataType>,
      }) as Promise<API.Result<Factory<T>>>; 
  }
}

/**
 * Get a Factory composed of 1 or several BusinessObjects (akka relation objects).
 * In case of several BusinessObjects, the order shall match the targeted DataType
 * following the pattern businessObject1DataType_businessObject2DataType_businessObject3DataType_etc.
 * @param dataType
 * @param businessObject1Id
 * @param fetchPolicy
 * @param businessObject2Id (optional)
 * @param businessObject3Id (optional)
 */
export function getFactoryBusinessObject<T extends DataType | DataTypeUnknown>(
  dataType: T,
  businessObject1Id: string,
  fetchPolicy: 'cache-only',
  businessObject2Id?: string,
  businessObject3Id?: string,
): Result<Factory<T>>;
export function getFactoryBusinessObject<T extends DataType | DataTypeUnknown>(
  dataType: T,
  businessObject1Id: string,
  fetchPolicy: 'network-no-cache',
  businessObject2Id?: string,
  businessObject3Id?: string,
): Promise<API.Result<WritableFactory<T>>>;
export function getFactoryBusinessObject<T extends DataType | DataTypeUnknown>(
  dataType: T,
  businessObject1Id: string,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  businessObject2Id?: string,
  businessObject3Id?: string,
): Result<Factory<T>> | Promise<Result<Factory<T>>>;
export function getFactoryBusinessObject<T extends DataType | DataTypeUnknown>(
  dataType: T,
  businessObject1Id: string,
  fetchPolicy?: FetchPolicy,
  businessObject2Id?: string,
  businessObject3Id?: string,
): Result<Factory<T>> | Promise<Result<WritableFactory<T> | Factory<T>>> {
  const skAndDataType = getSkAndDataType(businessObject1Id, businessObject2Id, businessObject3Id); 
  if (API.isFailure(skAndDataType)) return skAndDataType;

  return getFactory(
    dataType === DataTypeUnknown ? skAndDataType[1] : dataType,
    skAndDataType[0],
    fetchPolicy as any, 
  ) as Result<Factory<T>> | Promise<Result<WritableFactory<T> | Factory<T>>>; 
}

export function getFactory<T extends DataType>(
  dataType: T,
  sk: string,
  fetchPolicy: 'cache-only',
  pk?: string,
): Result<Factory<T>>;
export async function getFactory<T extends DataType>(
  dataType: T,
  sk: string,
  fetchPolicy: 'network-no-cache',
  pk?: string,
): Promise<Result<WritableFactory<T>>>;
export async function getFactory<T extends DataType>(
  dataType: T,
  sk: string,
  fetchPolicy?: Exclude<FetchPolicy, 'cache-only' | 'network-no-cache'>,
  pk?: string,
): Promise<Result<Factory<T>>>;
export function getFactory<T extends DataType>(
  dataType: T,
  sk: string,
  fetchPolicy: FetchPolicy = 'cache-first', 
  pk?: string,
): Result<Factory<T>> | Promise<Result<WritableFactory<T> | Factory<T>>> {
  let getFactoryQueryVariables: GetFactoryQueryVariables;
  if (pk) {
    getFactoryQueryVariables = {
      pk,
      sk,
    };
  } else {
    const _getFactoryQueryVariables = getGetFactoryQueryVariables(sk, dataType);
    if (API.isFailure(_getFactoryQueryVariables)) return _getFactoryQueryVariables;

    getFactoryQueryVariables = _getFactoryQueryVariables;
  }

  return _miscQueriesAPI('getFactory', dataType, getFactoryQueryVariables, fetchPolicy as any); 
}

function _miscQueriesAPI<T extends DataType>(
  queryType: 'getFactory',
  dataType: T,
  variables: QueryVariables<typeof queryType, T>,
  fetchPolicy: 'cache-only',
): Result<Factory<T>>;
async function _miscQueriesAPI<T extends DataType>(
  queryType: 'getFactory',
  dataType: T,
  variables: QueryVariables<typeof queryType, T>,
  fetchPolicy: 'network-no-cache',
): Promise<API.Result<WritableFactory<T>>>;
async function _miscQueriesAPI<T extends DataType>(
  queryType: 'getFactory',
  dataType: T,
  variables: QueryVariables<typeof queryType, T>,
  fetchPolicy: Exclude<FetchPolicy, 'cache-only' | 'network-no-cache'>,
): Promise<Result<Factory<T>>>;
async function _miscQueriesAPI(
  queryType: 'inviteUser',
  dataType: null,
  variables: QueryVariables<typeof queryType, DataType>,
): Promise<API.Result<boolean>>;
async function _miscQueriesAPI(
  queryType: 'getFilePreSignedUrl',
  dataType: null,
  variables: QueryVariables<typeof queryType, DataType>,
): Promise<Result<string>>;
function _miscQueriesAPI<Q extends MiscQueries<DataType>['type'], T extends DataType>(
  /**
   * Test types by removing all the 'as any' inside the function, replace M by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  queryType: Q,
  dataType: T | null,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy?: FetchPolicy,
): Result<Factory<T>> | Promise<Result<WritableFactory<T> | Factory<T> | boolean | string>> {
  logger.verbose(queryType + ' queryVariables:', queryVariables);
  Perf.captureStackTrace();
  Perf.captureString(JSON.stringify(queryVariables), Perf.Maps[queryType]);

  try {
    if (API.isGetFactory(queryType)) {
      if (!dataType)
        return API.createFailure_Unspecified('dataType shall be passed for queryType ' + queryType);
      if (!fetchPolicy)
        return API.createFailure_Unspecified(
          'fetchPolicy shall be passed for queryType ' + queryType,
        );

      const _queryVariables = queryVariables as QueryVariables<typeof queryType, T>;
      if (!_queryVariables.pk || !_queryVariables.sk)
        return API.createFailure_Unspecified(
          'pk and sk shall be passed for queryType ' + queryType,
          _queryVariables,
        );

      if (!logger.isPROD && !_queryVariables.sk.startsWith(dataType)) {
        if (dataType === API.DataType.APP || dataType === API.DataType.TENANT) {
          if (!logger.isPROD)
            logger.debug(
              'Remove this if clause once App & Tenant sk has been refactored -> ', 
            );
        } else {
          return API.createFailure_Unspecified(
            '_getFactory() passed dataType:' +
              dataType +
              ' shall match the dataType of the sk:' +
              _queryVariables.sk,
            _queryVariables,
          );
        }
      }

      
      if (fetchPolicy === 'cache-first' || fetchPolicy === 'cache-only') {
        const cachedFactory = DataLayer.queryFactoryCache(
          queryType,
          dataType,
          _queryVariables,
          fetchPolicy,
        );
        if (cachedFactory === 'ObjectNotFound') {
          return API.createFailure(
            'ObjectNotFound',
            'Object not found. Check the passed id on query' + JSON.stringify(_queryVariables),
          );
        } else if (cachedFactory) {
          return cachedFactory;
        }

        if (fetchPolicy === 'cache-only') {
          return API.createFailure(
            'ObjectNotFound',
            'Object not found. Check the passed id on query' + JSON.stringify(_queryVariables),
          );
        }
      }

      return graphql<CastByType<MiscQueries<T>, 'getFactory'>['result']>(
        removeProps(dataType ?? API.DataTypeUnknown, QueriesSchema.getFactory),
        _queryVariables,
      ).then(result => {
        logger.debug(
          queryType + ' result:',
          result,
          'with fetchPolicy=' + fetchPolicy + ' on misc query getFactory',
          _queryVariables,
        );

        const factory = API.extractDataFromFetchResult(queryType, result as any, dataType); 
        if (API.isFailure(factory)) return factory;

        
        if (factory != null && !result.errors?.length) {
          return DataLayer.updateCacheAndBroadCast(factory, fetchPolicy === 'network-no-cache');
        } else if (factory == null && !result.errors?.length) {
          return API.createFailure(
            'ObjectNotFound',
            'Object not found. Check the passed id on query' + JSON.stringify(_queryVariables),
          );
        }
        return convertServerExceptionToFailure(result, _queryVariables);
      });
    } else {
      let schema;
      if (API.isInviteUser(queryType)) {
        schema = MutationsSchema.inviteUser;
      } else if (API.isGetFilePreSignedUrl(queryType)) {
        schema = QueriesSchema[queryType];
      } else return API.createFailure_Unspecified('Not recognized queryType ' + queryType);

      return graphql<CastByType<MiscQueries<T>, typeof queryType>['result']>(
        schema,
        queryVariables,
      ).then(result => {
        logger.debug(
          queryType + ' result:',
          result,
          'with fetchPolicy=' + fetchPolicy + ' on misc query',
          queryVariables,
        );

        const data = API.extractDataFromFetchResult(queryType, result as any); 
        if (API.isFailure(data)) return data;

        
        if (data != null && !result.errors?.length) {
          return data;
        }

        return convertServerExceptionToFailure(result, queryVariables);
      });
    }
  } catch (error) {
    return convertServerExceptionToFailure(error, queryVariables);
  }
}




/**
 * This limit is usefull to override the default appsync limit of 100.
 *
 * The limit was reverted back to 999 after the following test (Large account)
 * 1- itemsQueryDatabaseLimit = 200 => 3mins46secs
 * 2- itemsQueryDatabaseLimit = 500 => 2mins40secs
 * 3- itemsQueryDatabaseLimit = 999 => 2mins31secs
 * 4- itemsQueryDatabaseLimit = 9999 => 2mins50secs (Query results containing 1k+ items are taking 10 to 25 secs "sometimes" - TBC)
 */
export const itemsQueryDatabaseLimit = 999;

async function _itemsQueryAPI<T extends DataType | DataTypeUnknown>(
  queryType: ItemsQueriesType,
  dataType: T,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy: 'network-no-cache',
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
async function _itemsQueryAPI<T extends DataType | DataTypeUnknown>(
  queryType: ItemsQueriesType,
  dataType: T,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy: Exclude<FetchPolicy, 'network-no-cache'>,
): Promise<ResultWithNextToken<Factory<T>[]>>;
async function _itemsQueryAPI<T extends DataType | DataTypeUnknown>(
  queryType: ItemsQueriesType,
  dataType: T,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy?: FetchPolicy,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>>;
/**
 * List items query
 * @param queryType
 * @param dataType
 * @param queryVariables
 *  - itemsLimit: roughly limit the number of items to return. The returned items length is not guaranted
 *    and can be lower or greater than the limit.
 *    If no itemsLimit is passed all items are returned.
 *  - nextToken (optional) marker to fetch the next items
 * @param fetchPolicy (optional, default = 'cache-first')
 */
async function _itemsQueryAPI<T extends DataType | DataTypeUnknown>(
  /**
   * Test types by removing all the 'as any' inside the function, replace T by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  queryType: ItemsQueriesType,
  dataType: T,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy: FetchPolicy = 'cache-first',
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  
  if (
    DataLayer.cacheOptions.enableUpdates?.enableCache?.enableQueryCache &&
    (fetchPolicy === 'cache-first' || fetchPolicy === 'cache-only')
  ) {
    const queryResult = QueryCache.getQueryCache().getQueryResult<T>(queryType, queryVariables);
    if (queryResult) return { result: queryResult };
  }

  let query = await __itemsQueryAPI(queryType, dataType, queryVariables, fetchPolicy);
  if (API.isFailure(query)) {
    if (API.isFailureType(query, 'QueryCacheOutdated')) {
      logger.warn(
        'Automatic retry of the query ' +
          queryType +
          ' because it contains outdated data: ' +
          query.message +
          '.',
        queryVariables,
      );
      
      query = await __itemsQueryAPI(queryType, dataType, queryVariables, fetchPolicy);
      if (API.isFailure(query) && API.isFailureType(query, 'QueryCacheOutdated')) {
        loggerPerf.warn(
          'Query was failing because of it was outdateed compared to local cahce. An automatic retry was done but failed again for the same reason. Was this automatic retry usefull ?',
        );
      }
      return query;
    }
    return query;
  }

  
  if (
    DataLayer.cacheOptions.enableUpdates?.enableCache?.enableQueryCache &&
    fetchPolicy !== 'network-no-cache'
  ) {
    QueryCache.getQueryCache().setQueryResult(
      queryType,
      queryVariables,
      query.result as Factory<T>[],
    ); 
  }

  return query;
}

async function __itemsQueryAPI<T extends DataType | DataTypeUnknown>(
  queryType: ItemsQueriesType,
  dataType: T,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy: 'network-no-cache',
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
async function __itemsQueryAPI<T extends DataType | DataTypeUnknown>(
  queryType: ItemsQueriesType,
  dataType: T,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy: Exclude<FetchPolicy, 'network-no-cache'>,
): Promise<ResultWithNextToken<Factory<T>[]>>;
async function __itemsQueryAPI<T extends DataType | DataTypeUnknown>(
  queryType: ItemsQueriesType,
  dataType: T,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy: FetchPolicy,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>>;
async function __itemsQueryAPI<T extends DataType | DataTypeUnknown>(
  /**
   * Test types by removing all the 'as any' inside the function, replace T by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  queryType: ItemsQueriesType,
  dataType: T,
  queryVariables: QueryVariables<typeof queryType, T>,
  fetchPolicy: FetchPolicy,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  logger.verbose(queryType + ' queryVariables:', queryVariables);

  
  if (!logger.isPROD) {
    let sortKey: string;
    if (API.isListFactorys(queryType)) {
      const keyCondition = (queryVariables as QueryVariables<typeof queryType, T>).sk;
      const filter = (queryVariables as QueryVariables<typeof queryType, T>).filter;

      if (filter?.dataType?.eq) {
        if (filter.dataType.eq !== dataType) {
          return API.createFailure_Unspecified(
            'DataTypes passed to listFactorys are not consistent (' +
              filter.dataType +
              ' !== ' +
              dataType +
              '). Please fix!',
            queryVariables,
          );
        }
      } else if (keyCondition?.beginsWith) {
        const firstDataType = keyCondition.beginsWith.split(API.SeparatorDataType)[0];
        if (firstDataType !== dataType) {
          return API.createFailure_Unspecified(
            'DataTypes passed to listFactorys are not consistent (' +
              firstDataType +
              ' !== ' +
              dataType +
              '). Please fix!',
            queryVariables,
          );
        }
      } else {
        if (!API.isDataType(API.DataTypeUnknown, dataType))
          return API.createFailure_Unspecified(
            'DataTypes passed to listFactorys are not consistent (dataType passd to the function shall be API.DataTypeUnknown with this queryVariables). Please fix!',
            queryVariables,
          );
      }

      sortKey =
        JSON.stringify((queryVariables as QueryVariables<typeof queryType, T>).sk) +
        JSON.stringify((queryVariables as QueryVariables<typeof queryType, T>).filter);
    } else if (API.isItemsByType(queryType)) {
      const keyCondition = (queryVariables as QueryVariables<typeof queryType, T>).dataType;
      if (keyCondition?.eq) {
        if (keyCondition.eq !== dataType) {
          return API.createFailure_Unspecified(
            'DataTypes passed to itemsByType are not consistent (' +
              keyCondition?.eq +
              ' !== ' +
              dataType +
              '). Please fix!',
            queryVariables,
          );
        }
      } else {
        if (!API.isDataType(API.DataTypeUnknown, dataType))
          return API.createFailure_Unspecified(
            'DataTypes passed to itemsByType are not consistent (dataType passed to the function shall be dataType=API.DataTypeUnknown with this queryVariables). Please fix!',
            queryVariables,
          );
      }

      sortKey =
        JSON.stringify((queryVariables as QueryVariables<typeof queryType, T>).dataType) +
        JSON.stringify((queryVariables as QueryVariables<typeof queryType, T>).filter);
    } else if (API.isItemsByData(queryType)) {
      const filter = (queryVariables as QueryVariables<typeof queryType, T>).filter;

      if (filter?.dataType) {
        if (filter.dataType.eq) {
          if (filter.dataType.eq !== dataType) {
            return API.createFailure_Unspecified(
              'DataTypes passed to itemsByData are not consistent (' +
                filter.dataType.eq +
                ' !== ' +
                dataType +
                '). Please fix!',
              queryVariables,
            );
          }
        } else if (filter.dataType.ne && !API.isDataType(API.DataTypeUnknown, dataType)) {
          return API.createFailure_Unspecified(
            'the expected dataType here should be DataTypeUnknown since we passed filter.dataType.ne Please fix!',
          );
        }
      } else {
        
        if (!API.isDataType(API.DataTypeUnknown, dataType)) {
          const data = (queryVariables as QueryVariables<typeof queryType, T>).data;
          const _dataType = data?.eq ?? data?.beginsWith ?? undefined;
          if (!_dataType || !dataType.startsWith(_dataType.split(API.SeparatorDataType)[0])) {
            return API.createFailure_Unspecified(
              'DataTypes passed to itemsByData are not consistent (NOT passing a dataType condition inside the variables is ok if you specify dataType=API.DataTypeUnknown). Please fix!',
              queryVariables,
            );
          }
        }
      }

      sortKey =
        JSON.stringify((queryVariables as QueryVariables<typeof queryType, T>).data) +
        JSON.stringify((queryVariables as QueryVariables<typeof queryType, T>).filter);
    } else if (API.isAllFactoriesByType(queryType)) {
      const queryDataType = (queryVariables as QueryVariables<typeof queryType, T>).dataType;
      if (queryDataType) {
        if (queryDataType !== dataType) {
          return API.createFailure_Unspecified(
            'DataTypes passed to allFactoriesByType are not consistent (' +
              queryDataType +
              ' !== ' +
              dataType +
              '). Please fix!',
            queryVariables,
          );
        }
      } else {
        if (!API.isDataType(API.DataTypeUnknown, dataType))
          return API.createFailure_Unspecified(
            'DataTypes passed to allFactoriesByType are not consistent (NOT passing a dataType condition inside the variables is ok if you specify dataType=API.DataTypeUnknown). Please fix!',
            queryVariables,
          );
      }

      sortKey = (queryVariables as QueryVariables<typeof queryType, T>).dataType ?? '';
    } else if (API.isSyncFactories(queryType)) {
      if (!API.isDataType(API.DataTypeUnknown, dataType)) {
        return API.createFailure_Unspecified(
          'DataTypes passed to syncFactories are not consistent (' +
            dataType +
            ' !== ' +
            API.DataTypeUnknown +
            '). Please fix!',
          queryVariables,
        );
      }

      sortKey = '';
    } else {
      return API.createFailure_Unspecified(
        'queryType ' + queryType + ' not supported !',
        queryVariables,
      );
    }

    Perf.captureStackTrace();
    Perf.captureString('sortKey:' + sortKey, Perf.Maps[queryType]);
  }

  
  if (fetchPolicy === 'cache-first' || fetchPolicy === 'cache-only') {
    if (dataType !== API.DataTypeUnknown) {
      const result = DataLayer.queryFactoryCache(queryType, dataType, queryVariables, fetchPolicy);
      if (result) {
        return { result: result as Factory<T>[] }; 
      }
    }

    if (fetchPolicy === 'cache-only') return { result: [] };
  }

  try {
    const finalResult: Factory<T>[] = [];

    let loopCount = 0;
    let startedAt: number | null | undefined;
    let nextToken = queryVariables.nextToken;
    const _queryVariables = {
      ...queryVariables,
    };

    _queryVariables.limit = queryVariables.limit
      ? Math.min(queryVariables.limit, itemsQueryDatabaseLimit)
      : itemsQueryDatabaseLimit;
    do {
      loopCount++;
      _queryVariables.nextToken = nextToken;

      const result = await graphql<CastByType<ItemsQueries<T>, typeof queryType>['result']>(
        removeProps(dataType, QueriesSchema[queryType]),
        _queryVariables,
      ); 

      logger.verbose(
        `${queryType} intermediate ${loopCount} result:`,
        result,
        'on query:',
        _queryVariables,
      );

      const factoryConnection = API.extractDataFromFetchResult(queryType, result as any, dataType); 
      if (API.isFailure(factoryConnection)) return factoryConnection;

      
      if (factoryConnection && !result.errors?.length) {
        nextToken = factoryConnection.nextToken;
        startedAt = startedAt ?? factoryConnection.startedAt; 

        
        if (
          fetchPolicy !== 'network-no-cache' &&
          DataLayer.cacheOptions.enableUpdates?.enableCache?.enableQueryCache &&
          startedAt &&
          dataType !== API.DataTypeUnknown &&
          startedAt < QueryCache.getQueryCache().getLatestEventTime(dataType)
        ) {
          return API.createFailure(
            'QueryCacheOutdated',
            "QueryCache outdated, the query won't be merged into the Cache. Please try again later",
          );
        }

        for (const factory of factoryConnection.items) {
          const _factory = DataLayer.updateCacheAndBroadCast(
            factory,
            fetchPolicy === 'network-no-cache',
          );
          if (API.isFailure(_factory)) {
            
            return _factory;
          } else {
            finalResult.push(_factory as Factory<T>); 
          }
        }
      } else {
        if (
          result.errors?.some(error => {
            return error && searchMatch(error.message, 'Transformation too large', false); 
          })
        ) {
          logger.warn(
            'Maximum AppSync load size reached with itemsLimit =' +
              _queryVariables.limit +
              ' on dataType: ' +
              dataType,
          );

          const decrementRatio = 0.75; 
          _queryVariables.limit = Math.floor(_queryVariables.limit * decrementRatio);

          if (_queryVariables.limit >= 100) {
            logger.warn(`Decreasing limit to ${_queryVariables.limit} and querying again`);
            return _itemsQueryAPI(queryType, dataType, _queryVariables as any, fetchPolicy as any); 
          }
        }
        return convertServerExceptionToFailure(result, queryVariables);
      }

      if (API.isSyncFactories(queryType)) {
        MyHub.dispatchAppContext('TenantAppLoading', {
          loadingPercentage: (loopCount / 10) * 100, 
        });
      }
    } while (
      nextToken &&
      !queryVariables.nextToken &&
      (queryVariables.limit ? finalResult.length < queryVariables.limit : true)
    );

    logger.debug(
      queryType + ' result:',
      finalResult,
      'length:',
      finalResult.length,
      'iterations:',
      loopCount,
      'nextToken:',
      nextToken,
      'with fetchPolicy=' + fetchPolicy + ' on query:',
      queryVariables,
    );
    if (finalResult.length > 3000) {
      loggerPerf.warn(
        `Performance Database loads: ${finalResult.length} items have been feteched in ${loopCount} iterations for datatType ${dataType}. Is such amount of data necessay?`,
      );
    }
    return { result: finalResult, nextToken, startedAt };
  } catch (error) {
    return convertServerExceptionToFailure(error, queryVariables);
  }
}

export async function listAllFactoriesOfBusinessObject<T extends DataType>(
  factoryBusinessObjectId: string,
  dataType: T,
  fetchPolicy: 'network-no-cache',
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
export async function listAllFactoriesOfBusinessObject<T extends DataType>(
  factoryBusinessObjectId: string,
  dataType: T,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<Factory<T>[]>>;
/**
 @description: This function is a wrapper to use listFactoryAPI. If one need to get the data
 of a business object this function can be used.
 This function apply a filter after fetching the data for a single tenant based on business object id .  
 @example: Give me all the data of a company that has skill id. It will fetch all relations and independent objects that is part of skill object.
 @param dataType: Filter results based on dataType
 @returns: List of factory objects
 */
export async function listAllFactoriesOfBusinessObject<T extends DataType>(
  factoryBusinessObjectId: string,
  dataType: T,
  fetchPolicy?: FetchPolicy,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  const pk = getFactoryPrimaryKey(dataType);
  if (API.isFailure(pk)) return pk;

  logger.warn('listAllFactoriesOfBusinessObject is not accurate and shall be reviewed before use');
  const listFactoryQueryVariables: QueryVariables<'itemsByType', T> = {
    pk: pk,
    filter: { data: { contains: factoryBusinessObjectId } },
  };
  if (itemsLimit) listFactoryQueryVariables.limit = itemsLimit;
  if (nextToken) listFactoryQueryVariables.nextToken = nextToken;

  return _itemsQueryAPI('listFactorys', dataType, listFactoryQueryVariables, fetchPolicy);
}

export async function listFactories(
  fetchPolicy: 'network-no-cache',
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<DataType>[]>>;
export async function listFactories(
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<Factory<DataType>[]>>;
/**
 * List all the factories of the current User context (current partitionKey)
 * @param pk
 * @param itemsLimit
 * @param nextToken
 * @param fetchPolicy
 */
export async function listFactories(
  fetchPolicy?: FetchPolicy,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<DataType>[] | Factory<DataType>[]>> {
  const appContext = AppContext.getContext();
  if (API.isFailure(appContext)) return appContext;

  const listFactoryQueryVariables: QueryVariables<'listFactorys', DataTypeUnknown> = {
    pk: appContext.pk,
  };
  if (itemsLimit) listFactoryQueryVariables.limit = itemsLimit;
  if (nextToken) listFactoryQueryVariables.nextToken = nextToken;

  return _itemsQueryAPI(
    'listFactorys',
    API.DataTypeUnknown,
    listFactoryQueryVariables,
    fetchPolicy,
  );
}

export async function listSingleRelationOfBusinessObject<T extends DataType>(
  dataType: T,
  idBeginsWith: string,
  fetchPolicy: 'network-no-cache',
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
export async function listSingleRelationOfBusinessObject<T extends DataType>(
  dataType: T,
  idBeginsWith: string,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<Factory<T>[]>>;
/**
 * @description: This function is a wrapper to use listFactoryAPI. If one need to get the data
 * of a business object based on it's relation to other business objects, this can be used.
 * @examples:
     Give me users related to one workstation
       idBegindsWith:"WORKSTATION#"
       dataType:DataType.WORKSTATION_WORKER
     Give me workstations attached to one skill
       idBegindsWith:"SKILL#"
       dataType:DataType.SKILL_WORKSTATION
     This function needs business object key or starting substring of the key and dataType.
 * @param idBeginsWith: This contains the begining of the business object key 
 * @param dataType: Filter results based on dataType
 * @returns: List of factory objects 

example:
  let idBeginsWith: string = "WORKSTATION#";
const roles: Array<Factory> = await API.listSingleRelationOfBusinessObject(
  DataType.WORKER,
);
 */
export async function listSingleRelationOfBusinessObject<T extends DataType>(
  dataType: T,
  idBeginsWith: string,
  fetchPolicy?: FetchPolicy,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  const pk = getFactoryPrimaryKey(dataType);
  if (API.isFailure(pk)) return pk;

  const listFactoryQueryVariables: QueryVariables<'listFactorys', T> = {
    pk: pk,
    sk: { beginsWith: idBeginsWith },
    filter: { dataType: { eq: dataType } },
  };
  if (itemsLimit) listFactoryQueryVariables.limit = itemsLimit;
  if (nextToken) listFactoryQueryVariables.nextToken = nextToken;

  return _itemsQueryAPI('listFactorys', dataType, listFactoryQueryVariables, fetchPolicy);
}

export async function listFactoriesWithSk<T extends DataType>(
  dataType: T,
  skString: string | Array<string>,
  skComparator: API.KeyComparator,
  fetchPolicy: 'network-no-cache',
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
export async function listFactoriesWithSk<T extends DataType>(
  dataType: T,
  skString: string | Array<string>,
  skComparator?: API.KeyComparator,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<Factory<T>[]>>;
export async function listFactoriesWithSk<T extends DataType>(
  dataType: T,
  skString: string | Array<string>,
  skComparator: API.KeyComparator = API.KeyComparator.beginsWith,
  fetchPolicy?: FetchPolicy,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  const pk = getFactoryPrimaryKey(dataType);
  if (API.isFailure(pk)) return pk;

  const skCondition: API.ModelIDKeyConditionInput = {};
  if (skComparator === API.KeyComparator.between) {
    if (Array.isArray(skString)) skCondition[skComparator] = skString;
    else
      return API.createFailure_Unspecified('skString shall be an array to use between comparator');
  } else {
    if (typeof skString === 'string') skCondition[skComparator] = skString;
    else
      return API.createFailure_Unspecified(
        'skString shall be a string to use comparator' + skComparator,
      );
  }

  const listFactoryQueryVariables: QueryVariables<'listFactorys', T> = {
    pk: pk,
    sk: skCondition,
  };
  if (itemsLimit) listFactoryQueryVariables.limit = itemsLimit;
  if (nextToken) listFactoryQueryVariables.nextToken = nextToken;
  return _itemsQueryAPI('listFactorys', dataType, listFactoryQueryVariables, fetchPolicy);
}

export async function listFactoriesWithDataType<T extends DataType>(
  dataType: T,
  fetchPolicy: 'network-no-cache',
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
export async function listFactoriesWithDataType<T extends DataType>(
  dataType: T,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<Factory<T>[]>>;
export async function listFactoriesWithDataType<T extends DataType>(
  dataType: T,
  fetchPolicy?: FetchPolicy,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  const pk = getFactoryPrimaryKey(dataType);
  if (API.isFailure(pk)) return pk;

  const itemsByTypeQueryVariables: QueryVariables<'itemsByType', T> = {
    pk: pk,
    dataType: { eq: dataType },
  };
  if (itemsLimit) itemsByTypeQueryVariables.limit = itemsLimit;
  if (nextToken) itemsByTypeQueryVariables.nextToken = nextToken;

  return _itemsQueryAPI('itemsByType', dataType, itemsByTypeQueryVariables, fetchPolicy);
}

export async function listFactoriesWithData<T extends DataType>(
  dataType: T,
  dataString: string | Array<string>,
  dataComparator: API.KeyComparator,
  fetchPolicy: 'network-no-cache',
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
export async function listFactoriesWithData<T extends DataType>(
  dataType: T,
  dataString: string | Array<string>,
  dataComparator?: API.KeyComparator,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<Factory<T>[]>>;
export async function listFactoriesWithData<T extends DataType>(
  dataType: T,
  dataString: string | Array<string>,
  dataComparator: API.KeyComparator = API.KeyComparator.beginsWith,
  fetchPolicy?: FetchPolicy,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  const pk = getFactoryPrimaryKey(dataType);
  if (API.isFailure(pk)) return pk;

  const dataCondition: API.ModelStringKeyConditionInput = {};
  if (dataComparator === API.KeyComparator.between) {
    if (Array.isArray(dataString)) dataCondition[dataComparator] = dataString;
    else
      return API.createFailure_Unspecified(
        'dataString shall be an array to use between comparator',
      );
  } else {
    if (typeof dataString === 'string') dataCondition[dataComparator] = dataString;
    else
      return API.createFailure_Unspecified(
        'dataString shall be a string to use comparator' + dataComparator,
      );
  }

  const itemsByDataQueryVariables: QueryVariables<'itemsByData', T> = {
    pk: pk,
    data: dataCondition,
  };
  if (itemsLimit) itemsByDataQueryVariables.limit = itemsLimit;
  if (nextToken) itemsByDataQueryVariables.nextToken = nextToken;

  return _itemsQueryAPI('itemsByData', dataType, itemsByDataQueryVariables, fetchPolicy);
}

export async function listFactoriesWithDataTypeUsingSkFilter<T extends DataType>(
  dataType: T,
  skString: string | Array<string> | API.ModelAttributeTypes | API.ModelSizeInput,
  skComparator: API.FilterComparator,
  fetchPolicy: 'network-no-cache',
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
export async function listFactoriesWithDataTypeUsingSkFilter<T extends DataType>(
  dataType: T,
  skString: string | Array<string> | API.ModelAttributeTypes | API.ModelSizeInput,
  skComparator?: API.FilterComparator,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<Factory<T>[]>>;
/**
 * @description: This function is a wrapper to use listFactoryByDataTypeAPI. If one needs to get the all the Factories of a given DataType
 * and want to further filter based on factory'sk property.
 * @examples
 *
 *  - Give me all TrainingSession_ProofBundle BusinessObjects relations that contain specific ProofBundle id.
 * ## N.B. for better PERFORMANCES, the following are conter examples:
 *  - Give me all TrainingSession_ProofBundle BusinessObjects relations that contain specific TrainingSession id
 *     -> use listSingleRelationOfBusinessObject(trainingSessionId, DataType.TrainingSession_ProofBundle) instead.
 *  - Give me all Worker BusinessObjects that contain specific worker id
 *     -> Use getFactory(workerId, DataType.Worker) instead.
 *
 * @param dataType Use local indexes to fetch the data based on dataType
 * @param skString string to search into the result Factories'sk
 * @param skComparator (optional) to choose the mode of comparaison
 */
export async function listFactoriesWithDataTypeUsingSkFilter<T extends DataType>(
  dataType: T,
  skString: string | Array<string> | API.ModelAttributeTypes | API.ModelSizeInput,
  skComparator: API.FilterComparator = API.FilterComparator.contains,
  fetchPolicy?: FetchPolicy,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  const pk = getFactoryPrimaryKey(dataType);
  if (API.isFailure(pk)) return pk;

  const skFilter: API.ModelIDInput = {};
  skFilter[skComparator] = skString as any; 

  const itemsByTypeQueryVariables: QueryVariables<'itemsByType', T> = {
    pk: pk,
    dataType: { eq: dataType },
    filter: { sk: skFilter },
  };
  if (itemsLimit) itemsByTypeQueryVariables.limit = itemsLimit;
  if (nextToken) itemsByTypeQueryVariables.nextToken = nextToken;

  return _itemsQueryAPI('itemsByType', dataType, itemsByTypeQueryVariables, fetchPolicy);
}

export async function listFactoriesWithDataTypeUsingDataFilter<T extends DataType>(
  dataType: T,
  dataString: string | Array<string> | boolean | API.ModelAttributeTypes | API.ModelSizeInput,
  dataComparator: API.FilterComparator,
  fetchPolicy: 'network-no-cache',
  itemsLimit?: number,
  nextToken?: string,
  pk?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[]>>;
export async function listFactoriesWithDataTypeUsingDataFilter<T extends DataType>(
  dataType: T,
  dataString: string | Array<string> | boolean | API.ModelAttributeTypes | API.ModelSizeInput,
  dataComparator?: API.FilterComparator,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
  itemsLimit?: number,
  nextToken?: string,
  pk?: string,
): Promise<ResultWithNextToken<Factory<T>[]>>;
/**
 * @description: This function is a wrapper to use listFactoryByDataTypeAPI. If one needs to get the all the Factories of a given DataType
 * and want to further filter based on factory'data property.
 * @examples:
 *  - Give me all WerkerSkill BusinessObjects that contain specific Worker or Skill id.
 *  - Give me all WorkstationWorkerLevelTarget BusinessObjects that contain specific Workstation or Worker id.
 *
 * @param dataType Use local indexes to fetch the data based on dataType
 * @param dataString string to search into the result Factories'sk
 * @param dataComparator (optional) to choose the mode of comparaison (@see Comparator)
 */
export async function listFactoriesWithDataTypeUsingDataFilter<T extends DataType>(
  dataType: T,
  dataString: string | Array<string> | boolean | API.ModelAttributeTypes | API.ModelSizeInput,
  dataComparator: API.FilterComparator = API.FilterComparator.contains,
  fetchPolicy?: FetchPolicy,
  itemsLimit?: number,
  nextToken?: string,
  pk?: string,
): Promise<ResultWithNextToken<WritableFactory<T>[] | Factory<T>[]>> {
  if (!pk) {
    const _pk = getFactoryPrimaryKey(dataType);
    if (API.isFailure(_pk)) return _pk;
    pk = _pk;
  }

  const dataFilter: API.ModelStringInput = {};
  if (dataComparator === API.FilterComparator.between) {
    if (Array.isArray(dataString)) dataFilter[dataComparator] = dataString;
    else
      return API.createFailure_Unspecified(
        'dataString shall be an array to use between comparator',
      );
  } else if (dataComparator === API.FilterComparator.attributeExists) {
    if (typeof dataString === 'boolean') dataFilter[dataComparator] = dataString;
    else
      return API.createFailure_Unspecified(
        'dataString shall be a booelan to use attributeExists comparator',
      );
  } else if (
    dataComparator === API.FilterComparator.attributeType ||
    dataComparator === API.FilterComparator.size
  ) {
    dataFilter[dataComparator] = dataString as any; 
  } else {
    if (typeof dataString === 'string') dataFilter[dataComparator] = dataString;
    else
      return API.createFailure_Unspecified(
        'dataString shall be a string to use comparator' + dataComparator,
      );
  }

  const itemsByTypeQueryVariables: QueryVariables<'itemsByType', T> = {
    pk: pk,
    dataType: { eq: dataType },
    filter: { data: dataFilter },
  };
  if (itemsLimit) itemsByTypeQueryVariables.limit = itemsLimit;
  if (nextToken) itemsByTypeQueryVariables.nextToken = nextToken;

  return _itemsQueryAPI('itemsByType', dataType, itemsByTypeQueryVariables, fetchPolicy);
}

export async function inviteUser(
  inviteUserInput: API.InviteUserInput,
): Promise<API.Result<boolean>> {
  const inviteUserMutationVariables: QueryVariables<'inviteUser', DataTypeUnknown> = {
    input: inviteUserInput,
  };
  return _miscQueriesAPI('inviteUser', null, inviteUserMutationVariables);
}

/**
 * Perform a sync against the server, getting all the Factories mutated since the given lastSync.
 * If lastSync is outdated, an Failure will be returned.
 * N.B. query and fragments are not saved into the cache (fetchPolicy = 'network-and-cache')
 * @param lastSync
 * @param itemsLimit
 * @param nextToken
 * @returns
 */
export async function syncFactories(
  pk: string,
  lastSync: number,
  itemsLimit?: number,
  nextToken?: string,
): Promise<ResultWithNextToken<WritableFactory<API.DataTypeUnknown>[]>> {
  /**
   * 1- The filter property is needed to filter on the pk we need because we don't query such pk
   * 2- If pk property is removed from the queryVariables the query fails and returns the unhandled backend error saying that the pk cant be null
   */
  const syncFactoriesQueryVariables: QueryVariables<'syncFactories', API.DataTypeUnknown> = {
    pk: pk,
    lastSync: lastSync,
    limit: itemsLimit ?? null,
    nextToken: nextToken ?? null,
  };

  return _itemsQueryAPI(
    'syncFactories',
    API.DataTypeUnknown,
    syncFactoriesQueryVariables,
    'network-no-cache',
  );
}

export async function getFilePreSignedUrlQuery(
  fileKey: string, 
): Promise<API.Result<string>> {
  const appContext = AppContext.getContext();
  if (API.isFailure(appContext)) return appContext;

  const getFilePreSignedUrlQueryVariables: QueryVariables<'getFilePreSignedUrl', DataTypeUnknown> =
    {
      pk: appContext.pk,
      key: fileKey,
    };

  return _miscQueriesAPI('getFilePreSignedUrl', null, getFilePreSignedUrlQueryVariables);
}

/**
 * Allows to by pass the business rules
 */
export const BRandMigrationScriptUseOnly = {
  _mutateFactoryAPI,
  _deleteFactory,
  _itemsQueryAPI,
};
