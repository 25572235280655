import { Switch, SwitchProps } from 'react-native-switch';
import React from 'react';
import { Platform } from 'react-native';
import { Colors, getCorrectedSpacings } from 'shared/styles';
import { Spacings } from 'shared/styles';

export const ToggleSwitch = (props: SwitchProps) => {
  

  const isWebPlatform = Platform.OS === 'web';

  return (
    <Switch
      circleSize={isWebPlatform ? Spacings.Medium : Spacings.Standard} 
      barHeight={isWebPlatform ? Spacings.Medium * 1.25 : getCorrectedSpacings(30)} 
      circleBorderWidth={0}
      backgroundActive={Colors.Yellow}
      backgroundInactive={Colors.GreyLight}
      circleActiveColor={Colors.White}
      circleInActiveColor={Colors.White}
      renderActiveText={false}
      renderInActiveText={false}
      switchLeftPx={2.7} 
      switchRightPx={2.7} 
      switchWidthMultiplier={2.08} 
      switchBorderRadius={30} 
      {...props}
    />
  );
};
