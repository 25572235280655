import { StyleSheet } from 'react-native-web';
import { Colors, fontSize, TextFontStyles, Spacings } from 'shared/styles';
import { ModalStyles } from 'shared/styles/ModalStyles';

const Styles = StyleSheet.create({
  iconContainer: {
    height: Spacings.Standard * 2,
    width: Spacings.Standard * 2,
    borderRadius: Spacings.Standard,
    backgroundColor: Colors.Yellow,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIconContainer: {
    flex: 1,
    alignItems: 'flex-end',
    marginTop: -Spacings.Big,
    paddingRight: Spacings.Small,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '16.5%',
    paddingLeft: Spacings.Standard + Spacings.Small,
  },
  headerText: {
    ...TextFontStyles.LatoRegular,
    fontSize: Spacings.Standard,
  },
  headerTextBlack: {
    ...TextFontStyles.LatoBlack,
    fontSize: Spacings.Standard,
  },
  upperContainer: {
    flexDirection: 'row',
    maxWidth: '100%',
    paddingLeft: ModalStyles.bodyPaddingLeft,
    paddingRight: 22,
    zIndex: Spacings.Small,
    flex: 1,
  },
  leftContainer: {
    flexDirection: 'row',
    maxHeight: '100%',
    zIndex: -1,
  },
  dropdownWorkstationContainer: {
    width: '100%',
    zIndex: 3,
  },
  dropdownWorkerContainer: {
    width: '100%',
    zIndex: 2,
  },
  dropdownSmallContainer: {
    width: '50%',
    zIndex: 1,
  },
  levelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    zIndex: -3,
  },
  levelText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingBottom: Spacings.Small,
  },
  rightContainer: {
    flex: 1,
    maxHeight: '100%',
    paddingTop: Spacings.Unit * 7,
    zIndex: -2,
    marginLeft: 50,
  },
  trainingHeaderView: {
    marginBottom: Spacings.Large,
  },
  trainingContainer: {
    marginBottom: Spacings.Medium,
    flexDirection: 'row',
    alignItems: 'center',
  },
  trainingHeaderText: {
    ...TextFontStyles.LatoRegular,
    fontSize: Spacings.Standard + 2,
  },
  trainingHeaderTextBlack: {
    ...TextFontStyles.LatoBlack,
    fontSize: Spacings.Standard + 2,
  },
  trainingIconContainer: {
    marginRight: Spacings.Medium - Spacings.Unit,
  },
  requiredTrainingName: {
    ...TextFontStyles.LatoRegular,
    fontSize: Spacings.Medium - 2,
  },
  lowerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingHorizontal: Spacings.Large + Spacings.Small,
  },
  textButtonView: {
    marginTop: 153,
    height: Spacings.Standard,
    alignSelf: 'flex-end',
  },
  trainButton: {
    height: Spacings.Unit * 8,
    width: 304,
    backgroundColor: Colors.Yellow,
    borderWidth: 0,
  },
  buttonTextStyle: {
    ...TextFontStyles.LatoBlack,
    color: Colors.White,
    fontSize: Spacings.Medium,
  },
  advContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  advBox: {
    width: 725,
    backgroundColor: Colors.Background,
    borderRadius: 10,
    flexDirection: 'row',
  },
  advleftContainer: {
    height: 50,
    marginTop: 24,
    marginLeft: 24,
  },
  advTextContainer: {
    width: 250,
    marginTop: 30,
    paddingLeft: 18,
    paddingBottom: 20,
  },
  advRightContainer: {
    marginTop: 77,
    height: 28,
    width: 196,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  appStoreButton: {
    height: '100%',
    width: 95,
    backgroundColor: 'black',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playStoreButton: {
    height: '100%',
    width: 95,
    backgroundColor: 'black',
    borderRadius: 4,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  imagContainer: {
    width: 712,
    height: 192,
    backgroundColor: Colors.Background,
  },
  createTrainingTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: 16,
    color: Colors.Grey,
  },
  createTrainingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacings.Small,
    backgroundColor: Colors.GreyUltraLight,
    borderRadius: Spacings.Small,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    paddingLeft: Spacings.Medium,
    paddingRight: Spacings.Medium,
    paddingBottom: Spacings.Medium,
    width: '92%',
  },
  trainingNameText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    color: Colors.Black,
  },
  buttonContainer: {
    width: '100%',
    alignItems: 'center',
    paddingBottom: Spacings.Unit * 5,
    paddingTop: Spacings.Unit * 12,
  },
  inputListContainer: {
    width: 550,
  },
  inputListWorkerContainer: {
    width: '100%',
  },
  leftInputContainer: { width: 270 },
});

export { Styles };
