import { StyleSheet } from 'react-native-web';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  label: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingVertical: Spacings.Small,
    paddingHorizontal: Spacings.CardPadding,
    color: Colors.Black,
  },
  disable: {
    color: Colors.Grey,
  },
  rightIcon: {
    flex: 1,
    alignItems: 'flex-end',
  },
  menuContainer: {
    position: 'absolute',
    zIndex: 1,
    marginLeft: 10,
    right: 30,
    padding: 0,
    paddingVertical: Spacings.Small,
  },
  rootContainer: {
    width: Spacings.Big - Spacings.Unit,
    alignItems: 'center',
  },
  textContainer: {
    justifyContent: 'spaceBetween',
    flexDirection: 'row',
  },
});

export default styles;
