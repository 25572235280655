import * as _ from 'lodash-es';
import {
  DataType,
  QueryVariables,
  DataTypeUnknown,
  Factory,
  ItemsQueriesType,
  createFailure_Unspecified,
  Result,
  FactoryCache,
} from 'shared/backend-data';
import { Cache } from './Cache';
import { enumValues, extractKeys, deepDiffObj, deepFreeze } from '../../util-ts/Functions';
import { DataLayer, FactoryMutationCacheEvent } from '../DataLayer';
import * as API from 'shared/backend-data';
import { loggerPerf, loggerAPI as logger } from '../../util/Logger';
import { AppContext } from '../../context/AppContext';
import { UserContext } from '../../context/UserContext';
import { MyHub } from '../../util/MyHub';

export const userPermissionsChangeDebounceInMs = 1000 * 60 * 10;
const dispatchForceTenantAppCacheBaseSyncDebounceInMs = 5000;

/**
 * DataType(s) which their factory.data is dynamic: might change of value, because of a mutation
 *
 * Expected & Known scenario:
 *     - "PROOFBUNDLE#,TO_REVIEW,SKILL#UUID" -> "PROOFBUNDLE#,VALIDATED,SKILL#UUID"
 */
const dataTypesWithDynamicData: API.DataType[] = [
  API.DataType.PROOFBUNDLE,
  API.DataType.TRAININGSESSION,
];


const beginsWithKey: keyof API.ModelStringInput = 'beginsWith';
const containsKey: keyof API.ModelStringInput = 'contains';
const modelStringPartialFilterKeys = `"(${beginsWithKey}|${containsKey})"`;
const queryWithModelStringPartialFilterKeysReg = new RegExp(modelStringPartialFilterKeys);

const extractPartialFilterFieldsReg = new RegExp(`{${modelStringPartialFilterKeys}:"(.+?)"}`);


const immutableQueryParameters = new Set([
  
  
  
  'pk',
  'sk',
  'dataType',
  'sortDirection',
  'filter',
  'limit',
  'nextToken',

  
  'createdAt',
  'and',
  'or',
  'not',

  
  'id',
]);
enumValues(API.KeyComparator).forEach(value => {
  immutableQueryParameters.add(value);
});
enumValues(API.FilterComparator).forEach(value => {
  immutableQueryParameters.add(value);
});
/**
 * For this regex:
 * Both tests shall succeed
 *     listFactorys{\“pk\“:\“546987e6-d1e4-4eb6-ad8f-4be8c1c82972,SKILLOP\“,\“mutable\“:”123",\“sk\“:{\“beginsWith\“:\“WORKERSKILL#,WORKER#fb801696-4d27-464b-9407-a421cfa01ac6\“}}
 *     listFactorys{“pk”:“546987e6-d1e4-4eb6-ad8f-4be8c1c82972,SKILLOP”,“mutable”:“123", “sk”:{“beginsWith”:“WORKERSKILL#,WORKER#fb801696-4d27-464b-9407-a421cfa01ac6”}}
 */
const regExpFindQueryMutableParameters = new RegExp(
  '(?!"(' + Array.from(immutableQueryParameters.keys()).join('|') + ')\\?":)"[^"]+?":',
);

function filterQueriesWithMutableParameters(queries: string[]): string[] {
  return queries.filter(query => {
    return regExpFindQueryMutableParameters.test(query);
  });
}

function filterQueriesWithParameters(queries: string[], parameters: string[]) {
  /**
   * For this regex:
   * Both tests shall succeed
   *     listFactorys{\“pk\“:\“546987e6-d1e4-4eb6-ad8f-4be8c1c82972,SKILLOP\“,\“sk\“:{\“beginsWith\“:\“WORKERSKILL#,WORKER#fb801696-4d27-464b-9407-a421cfa01ac6\“}}
   *     listFactorys{“pk”:“546987e6-d1e4-4eb6-ad8f-4be8c1c82972,SKILLOP”,“sk”:{“beginsWith”:“WORKERSKILL#,WORKER#fb801696-4d27-464b-9407-a421cfa01ac6”}}
   */
  const regExpFindQueryParameters = new RegExp('"(' + parameters.join('|') + ')\\?":');
  return queries.filter(query => {
    return regExpFindQueryParameters.test(query);
  });
}

/**
 * @param queries
 * @param mutatedFactory
 * @returns { queriesListingFactories ; otherQueries }
 */
function splitUpdatableQueriesOnCreateDeleteMutationAndTheOthers<T extends DataType>(
  queries: string[],
  mutatedFactory: Factory<T>,
): { queriesListingFactories: string[]; otherQueries: string[] } {
  
  
  const regExpFindUpdatableQueriesOnCreateDeleteMutation = new RegExp(
    `^(itemsByType{"pk":"${mutatedFactory.pk}","dataType":{"eq":"${mutatedFactory.dataType}"}}|listFactorys{"pk":"${mutatedFactory.pk}","sk":{"beginsWith":"${mutatedFactory.dataType}#"}})`,
  );
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  const otherQueries: string[] = [];
  const queriesListingFactories: string[] = [];
  queries.forEach(async query => {
    if (regExpFindUpdatableQueriesOnCreateDeleteMutation.test(query)) {
      queriesListingFactories.push(query);
    } else {
      otherQueries.push(query);
    }
  });
  if (otherQueries.length)
    loggerPerf.info(
      'filterUpdatableQueriesOnCreateMutation founds ' +
        otherQueries.length +
        ' querries that were not detected as updated. This could be improved by upgading regExpFindUpdatableQueriesOnCreateMutation',
    );

  return { queriesListingFactories, otherQueries };
}

/**
 * Return the sort key prop of the items or list factorys query
 */
function getItemsQuerySortKey(
  queryKey: string,
): keyof Pick<API.Factory<API.DataType>, 'sk' | 'dataType' | 'data'> {
  if (queryKey.startsWith('itemsByType' as ItemsQueriesType)) {
    return 'dataType';
  } else if (queryKey.startsWith('listFactorys' as ItemsQueriesType)) {
    return 'sk';
  } else if (queryKey.startsWith('itemsByData' as ItemsQueriesType)) {
    return 'data';
  } else {
    
    throw new Error('not support query type');
  }
}

/**
 * Get the index where the given @param item is stored or shall be stored if inserted.
 * Warning: the function requires the given @param orderedItems is passed sorted in ascending order.
 * @param item
 * @param orderedItems
 * @param sortKey
 * @param start
 * @param end
 * @returns
 */
function findLocation<T extends DataType>(
  item: Factory<T>,
  orderedItems: Factory<T>[],
  sortKey: 'sk' | 'dataType' | 'data',
  start?: number,
  end?: number,
): number {
  if (orderedItems.length === 0) return 0;

  start = start || 0;
  end = end || orderedItems.length;
  const pivot = Math.floor(start + (end - start) / 2);

  const pivotItemSortValue = orderedItems[pivot][sortKey];
  const itemSortValue = item[sortKey];
  if (itemSortValue == null) return orderedItems.length; 
  if (end - start <= 1 || pivotItemSortValue === itemSortValue) return pivot;
  if (pivotItemSortValue != null && pivotItemSortValue < itemSortValue) {
    return findLocation(item, orderedItems, sortKey, pivot, end);
  } else {
    return findLocation(item, orderedItems, sortKey, start, pivot);
  }
}

type QueryCacheData = Map<string, Factory<DataTypeUnknown>[]>;

export class QueryCache extends Cache<'QueryCache', QueryCacheData, DataTypeUnknown> {
  
  
  

  private static _areCacheItemsOrdered: boolean | undefined;

  /**
   * Get the QueryCache for the given pk
   * @param pk (optional) if not specified the context one is used
   */
  static getQueryCache(pk?: string): QueryCache {
    return Cache.getCache('QueryCache', pk) as QueryCache; 
  }

  /**
   * When TRUE, the items query results are stored sorted in ascending order.
   * It has a perf cost O(log(items.length)) at storing step.
   * But no cost when querying whith a sort order.
   * If your client is never using sort items query, it is more efficient to set it to FALSE.
   */
  static get isCacheItemsOrdered(): boolean {
    if (this._areCacheItemsOrdered === undefined)
      throw new Error(
        'isCacheItemsOrdered is not intialized. Please call setCacheItemsOrdered() before.',
      );
    return this._areCacheItemsOrdered;
  }

  static setQueryCacheAreCacheItemsOrdered(areCacheItemsOrdered: boolean) {
    this._areCacheItemsOrdered = areCacheItemsOrdered;
    logger.info('API isStoreItemsOrdered enabled = ' + areCacheItemsOrdered);
  }

  
  
  

  /**
   * (server) time of the latest event that triggered the update of this cache
   */
  private latestEventTime: Map<API.DataType, number> = new Map();

  constructor(pk: string) {
    super(pk, 'QueryCache', false, new Map());
  }

  getLatestEventTime(dataType: API.DataType): number {
    return this.latestEventTime.get(dataType) ?? 0;
  }

  protected restore_deepFreezeFactories(data: QueryCacheData): void {
    
    data.forEach(factories => {
      factories.forEach(factory => {
        const factoryCache = FactoryCache.getFactoryCache(factory.dataType, factory.pk);
        const cachedFactory = factoryCache.getFactory(factory.sk);
        deepFreeze(factory);
        factoryCache.update(
          {
            mutationType:
              API.isFailure(cachedFactory) || !cachedFactory || 'ObjectNotFound'
                ? 'createFactory'
                : 'updateFactory',
            factory,
            tooManyMutations: false,
          },
          true,
        );
      });
    });
  }

  async baseSync(): Promise<Result<QueryCacheData>> {
    return createFailure_Unspecified('Filling cache for QueryCache is not supported yet.');
  }

  protected purge_inMemoryData(data: QueryCacheData): void {
    data.clear();
  }

  /**
   * Save a query in the QueryCache. Make sure that the factories are
   * already stored inisde the FactoryCache
   * @param queryType
   * @param queryVariables
   * @param factories
   */
  setQueryResult<T extends DataType | DataTypeUnknown>(
    queryType: ItemsQueriesType,
    queryVariables: QueryVariables<typeof queryType, T>,
    factories: Factory<T>[],
  ): void {
    if (!DataLayer.cacheOptions.enableUpdates?.enableCache?.enableQueryCache) return;

    this.data.set(queryType + JSON.stringify(queryVariables), factories);

    if (
      DataLayer.cacheOptions.enableUpdates?.enableCache?.enablePersistence
        ?.enableAutoPersistenceOnChange
    ) {
      this.persist();
    }
  }

  getQueryResult<T extends DataType | DataTypeUnknown>(
    queryType: ItemsQueriesType,
    queryVariables: QueryVariables<typeof queryType, T>,
  ): Factory<T>[] | undefined {
    if (!DataLayer.cacheOptions.enableUpdates?.enableCache?.enableQueryCache) return;

    return this._getQueryResult(queryType + JSON.stringify(queryVariables));
  }

  private _getQueryResult<T extends DataType | DataTypeUnknown>(
    queryKey: string,
  ): Factory<T>[] | undefined {
    return this.data.get(queryKey) as Factory<T>[] | undefined; 
  }

  /**
   * Update or discard the cached querries that are related to the given event
   * @param cacheEvent FactoryMutationCacheEvent
   */
  update_handleFactoryMutation(
    cacheEvent: FactoryMutationCacheEvent<DataTypeUnknown, false>,
  ): void {
    const { mutationType, factory, localPreviousFactory } = cacheEvent;
    this.latestEventTime.set(factory.dataType, factory._lastChangedAt);
    
    const isFilterQueriesWithParametersEnabled = false;

    
    let linkedCachedQueries = this.getCachedQueriesKey(factory);

    
    
    if (API.isMutationUpdate(mutationType)) {
      
      
      
      let cachedQueriesToDiscard: string[] = [];
      if (isFilterQueriesWithParametersEnabled && localPreviousFactory) {
        const linkedCachedQueriesForOldObject = this.getCachedQueriesKey(localPreviousFactory);
        linkedCachedQueries = [...linkedCachedQueries, ...linkedCachedQueriesForOldObject];

        
        const updatedProperties: string[] = extractKeys(deepDiffObj(localPreviousFactory, factory));
        const updatedMutableProperties = updatedProperties.filter(
          property => !immutableQueryParameters.has(property),
        );
        cachedQueriesToDiscard = filterQueriesWithParameters(
          linkedCachedQueries,
          updatedMutableProperties,
        );
      } else {
        cachedQueriesToDiscard = filterQueriesWithMutableParameters(linkedCachedQueries);
      }

      this.deleteQuery(cachedQueriesToDiscard);
    } else if (API.isMutationCreate(mutationType)) {
      this.updateQueriesOnCreateDeleteMutation(linkedCachedQueries, factory, true);
    } else {
      
      
      
      
      this.updateQueriesOnCreateDeleteMutation(linkedCachedQueries, factory, false);
    }

    if (
      API.isFactory(API.DataType.WORKER, factory) &&
      localPreviousFactory &&
      API.isFactory(API.DataType.WORKER, localPreviousFactory) &&
      API.isScopeChanged(factory.worker.scope, localPreviousFactory.worker.scope)
    ) {
      const appContext = AppContext.getContext();
      if (API.isFailure(appContext)) {
        logger.warn(appContext);
        return;
      }

      
      
      this.forceBaseSyncAtNextSyncNeeded();
    }

    if (API.isFactory(API.DataType.USERPREFERENCE, factory)) {
      const user = UserContext.getUser();
      if (API.isFailure(user)) {
        logger.error('QueryCache ERROR: Failed to fetch user');
        return;
      }
      if (factory.userPreference.userId !== user.id) return;

      const isForceBaseSync = API.extractPreferences({
        ...factory.userPreference,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      }).get(API.UserPreferenceKeys_Common.ForceBaseSync);
      this.dispatchForceTenantAppCacheBaseSyncDebounce(isForceBaseSync);
    }
  }

  private dispatchForceTenantAppCacheBaseSyncDebounce = _.debounce(
    isForceBaseSync => {
      MyHub.dispatchAppContext('ForceTenantAppCacheBaseSync', { isForceBaseSync });
    },
    dispatchForceTenantAppCacheBaseSyncDebounceInMs,
    { leading: false, trailing: true },
  );

  private forceBaseSyncAtNextSyncNeeded = async () => {
    const user = UserContext.getUser();
    if (API.isFailure(user)) {
      logger.error('QueryCache ERROR: Failed to fetch user');
      return user;
    }

    await Promise.all(
      user.authorizedPks.map(async authorizedPk => {
        await DataLayer.forceBaseSyncAtNextSyncNeeded(authorizedPk.pk);
        MyHub.dispatchAppContext('TenantAppCacheSyncNeeded', { pk: authorizedPk.pk });
      }),
    );
  };

  /**
   * Filter the given queries to return only the ones that are linked to the given Factory.
   *
   * WARNING: App stability highly depends on this function.
   * When updating its core, ensure to also update the test cases (see QueryCache.test.ts)
   *
   * @param factory
   * @param queries
   */
  private getCachedQueriesKey<T extends DataType>(factory: Factory<T>): string[] {
    const regPk = new RegExp('"' + factory.pk + '"');
    const regSk = new RegExp('"' + factory.sk + '"');
    const regDataType = new RegExp('"' + factory.dataType + '"');
    const regData = new RegExp('"' + factory.data + '"');
    const regDynamicData = new RegExp(
      '"' + factory.dataType + API.SeparatorDataType + API.SeparatorIds,
    );

    const factoryCachedQueriesKeys: string[] = [];

    this.data.forEach((result, key) => {
      if (regPk.test(key)) {
        
        if (
          regSk.test(key) ||
          regDataType.test(key) ||
          regData.test(key) ||
          (dataTypesWithDynamicData.includes(factory.dataType) && regDynamicData.test(key))
        ) {
          factoryCachedQueriesKeys.push(key);
        }
        
        else if (queryWithModelStringPartialFilterKeysReg.test(key)) {
          const matchResult = key.match(extractPartialFilterFieldsReg);
          if (!matchResult) return;

          
          
          
          
          const partialHitField = matchResult[2];
          if (partialHitField) {
            const partialHitFieldReg = new RegExp(partialHitField);
            if (
              partialHitFieldReg.test(factory.sk) ||
              (factory.data && partialHitFieldReg.test(factory.data))
            ) {
              factoryCachedQueriesKeys.push(key);
            }
          }
        }
      }
    });

    return factoryCachedQueriesKeys;
  }

  /**
   * Delete the given query-Keys
   * @param queriesKey queries to delete. If not passed all the queries are purged
   */
  private deleteQuery(queriesKey?: string[]): boolean {
    let someKeysEvicted = false;

    if (!queriesKey) {
      someKeysEvicted = this.data.size > 0;
      this.data.clear();
    } else {
      queriesKey.forEach(async queryKey => {
        someKeysEvicted = this.data.delete(queryKey) || someKeysEvicted;
      });
    }

    return someKeysEvicted;
  }

  /**
   * Takes an array of Query-Keys and try to append the newly created Object to the updatable query
   *
   * @param queriesKey Cached queries
   * @param factory created factory
   * @param add true if the Factory needs to be added, false if it needs to be deleted
   */
  private updateQueriesOnCreateDeleteMutation<T extends DataType>(
    queriesKey: string[],
    factory: API.Factory<T>,
    add: boolean,
  ): void {
    const filteredQueries = splitUpdatableQueriesOnCreateDeleteMutationAndTheOthers(
      queriesKey,
      factory,
    );

    logger.debug(
      'QueryCache Update ' + filteredQueries.queriesListingFactories.length + ' queries',
      'Discard ' + filteredQueries.otherQueries.length + ' queries',
    );
    
    filteredQueries.queriesListingFactories.forEach(queryKey => {
      const result = this._getQueryResult<T>(queryKey);
      if (!result) {
        logger.error(
          'This should never happen. It means the filterUpdatableQueriesOnCreateDeleteMutation() is returning wrong result. Please fix!',
        );
        return;
      }

      if (add) {
        if (QueryCache.isCacheItemsOrdered) {
          const insertionIndex = findLocation(factory, result, getItemsQuerySortKey(queryKey));
          result.splice(insertionIndex, 0, factory);
        } else {
          result.push(factory);
        }
      } else {
        const index = result.findIndex(_factory => _factory.sk === factory.sk);
        if (index === -1) {
          logger.error(
            'This should not happen. It means the filterUpdatableQueriesOnCreateDeleteMutation() is returning wrong result. Please fix!',
          );
          return;
        }
        result.splice(index, 1);
      }
    });

    
    this.deleteQuery(filteredQueries.otherQueries);
  }
}
