import React, { useState, createContext } from 'react';
import * as API from 'shared/backend-data';
/**
 * This context is used to set/provide the KPI filter data (workers/workstations/trainingSessions) to all its components
 * and the states of the nodes (collapsed or expanded) for table rendering
 * @param workers
 * @param workstations
 * @param trainingSessionsWithState
 */

export interface KPIData {
  workers?: API.Worker[];
  workstations?: API.Workstation[];
  trainingSessionsWithState?: API.TrainingSessionWithState[];
}

export const TreeTableContext = createContext<{
  isUnderStaffedAndOverStaffedWorkstationsLoaded: boolean;
  setIsUnderStaffedAndOverStafftedWorkstationsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isGraphLoaded: boolean;
  setIsGraphLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  kpiData: KPIData;
  setKPIData: React.Dispatch<React.SetStateAction<KPIData>>;
  collapsedNodes: string[];
  selectedShiftIds: string[];
  setCollapsedNodes: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedShiftIds: React.Dispatch<React.SetStateAction<string[]>>;
}>({
  isUnderStaffedAndOverStaffedWorkstationsLoaded: false,
  setIsUnderStaffedAndOverStafftedWorkstationsLoaded: () => {},
  isGraphLoaded: false,
  setIsGraphLoaded: () => {},
  kpiData: {
    workers: [],
    workstations: [],
    trainingSessionsWithState: [],
  },
  setKPIData: () => {},
  collapsedNodes: [],
  selectedShiftIds: [],
  setCollapsedNodes: () => {},
  setSelectedShiftIds: () => {},
});

export const TreeTableDataProvider: React.FC = props => {
  const [kpiData, setKPIData] = useState<KPIData>({
    workers: undefined,
    workstations: undefined,
    trainingSessionsWithState: undefined,
  });
  const [collapsedNodes, setCollapsedNodes] = useState<string[]>([]);
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);
  const [isGraphLoaded, setIsGraphLoaded] = useState<boolean>(false);
  const [
    isUnderStaffedAndOverStaffedWorkstationsLoaded,
    setIsUnderStaffedAndOverStafftedWorkstationsLoaded,
  ] = useState<boolean>(false);

  return (
    <TreeTableContext.Provider
      value={{
        isUnderStaffedAndOverStaffedWorkstationsLoaded,
        isGraphLoaded,
        kpiData,
        collapsedNodes,
        selectedShiftIds,

        setKPIData,
        setCollapsedNodes,
        setIsGraphLoaded,
        setSelectedShiftIds,
        setIsUnderStaffedAndOverStafftedWorkstationsLoaded,
      }}
    >
      {props.children}
    </TreeTableContext.Provider>
  );
};
