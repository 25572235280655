import React from 'react';
import { View, Text, ViewStyle } from 'react-native';
import { t } from '../../localisation/i18n';
import { FileRow } from '../AttachFile/FileRow';
import { Styles } from './Styles';
import { S3Object } from 'backend/src/api';

interface Props {
  files: readonly S3Object[];
  containerStyle?: ViewStyle;
}

/**
 * Component to list files in both mobile and web
 */
export const ListFiles: React.FC<Props> = props => {
  const { files, containerStyle } = props;

  return (
    <View style={[Styles.container, containerStyle]}>
      <Text style={Styles.title}>{t('common:attachment.attachment', { count: files.length })}</Text>
      {files.map((eachFile, index) => (
        <FileRow file={eachFile} key={index} />
      ))}
    </View>
  );
};
