import { StyleSheet } from 'react-native-web'; 
import { Colors, TextFontStyles, Spacings } from 'shared/styles';
import * as SharedStyles from 'shared/styles/index';

const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    backgroundColor: Colors.LightBlue,
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: Spacings.CardPadding,
    paddingVertical: Spacings.Standard,
  },
  headerTextContainer: {
    flex: 0.4,
    paddingLeft: Spacings.Standard,
  },
  headerMainText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerRowText: {
    fontSize: SharedStyles.fontSize.XXXLarge,
    paddingRight: Spacings.Unit,
    ...TextFontStyles.LatoRegular,
  },
  innerRowBoldText: {
    fontSize: SharedStyles.fontSize.XXXLarge,
    ...TextFontStyles.LatoBlack,
  },
  drawerLogo: {
    width: 24,
    height: 24,
  },
  logo: {
    width: '3.26vw',
    height: '6.88vh',
    marginRight: '2.14vw',
  },
  navItem: {
    color: 'black',
    fontSize: '2.20vh',
    paddingTop: '3.98vh',
    paddingBottom: '3.98vh',
    paddingRight: '0.97vw',
    paddingLeft: '0.97vw',
  },
  navItemActive: {
    opacity: 0.86,
    color: '#a2a2a2',
    backgroundColor: '#F7F7F7',
    fontSize: '2.20vh',
    paddingTop: '3.98vh',
    paddingBottom: '3.98vh',
    paddingRight: '0.97vw',
    paddingLeft: '0.97vw',
    borderBottomWidth: 5,
    borderBottomColor: '#FC0',
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 100,
  },

  inputSearchContainer: {
    zIndex: 1,
    flex: 0.4,
  },
  inputStyle: {
    backgroundColor: '#FFFFFF',
  },
  profileContainer: {
    paddingRight: Spacings.CardPadding,
    flexDirection: 'row',
    height: 36,
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 0.2,
    zIndex: 2,
  },
  profileInnerContainer: {
    flexDirection: 'row',
    width: '23.15%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  profileNotifContainer: {
    width: 24,
    height: 24,
    marginRight: SharedStyles.Spacings.Unit * 8,
  },
  profileNotifSVG: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
  },
  helpButton: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    marginRight: Spacings.Standard,
    marginBottom: Spacings.Unit,
    borderRadius: Spacings.Standard,
  },
  profileNotifBadge: {
    width: 15,
    height: 15,
    borderRadius: 50,
    backgroundColor: 'red',
    position: 'absolute',
    top: -6,
    right: -6,
    shadowColor: 'white',
    shadowOpacity: 0.4,
    shadowOffset: {
      height: 0.5,
      width: -0.5,
    },
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notifTouchableContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    width: 24,
    borderRadius: 50,
  },
  profileNotifBadgeText: {
    color: 'white',
    fontSize: 11,
    width: '100%',
    height: '100%',
    borderRadius: 50,
    textAlign: 'center',
    paddingRight: 1,
    fontWeight: '900',
    fontFamily: 'Lato, Black',
  },
  profileImageContainer: { marginRight: 8, height: 36 },
  userFirstNameTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: SharedStyles.fontSize.Regular,
    height: 17,
    marginRight: SharedStyles.Spacings.Small,
  },
  profileDropDownTouchable: {
    flexDirection: 'row',
    height: 36,
    alignItems: 'center',
  },
  triangleIcon: {
    width: 10,
    height: 9,
  },
  dropdown: {
    backgroundColor: Colors.White,
    right: 45,
    position: 'absolute',
    top: 61,
    shadowOffset: {
      height: 2,
      width: 1,
    },
    shadowColor: Colors.ModalBackground,
    shadowOpacity: 1,
    shadowRadius: Spacings.Small,
    borderRadius: Spacings.Small,
    paddingVertical: Spacings.CardPadding / 2,
  },
  dropdownItem: {
    ...TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Regular,
    paddingHorizontal: 28,
    paddingVertical: Spacings.CardPadding / 2,
  },
  separator: {
    width: '100vw',
    height: 1,
    backgroundColor: '#e6e6e6',
  },
  logoutText: {
    color: Colors.Red,
  },
  invitationRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 16,
  },
  usersCount: {
    width: 17,
    height: 13,
    backgroundColor: Colors.Yellow,
    borderRadius: (17 + 13) / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  countStyle: {
    fontSize: 11,
    color: SharedStyles.Colors.White,
    ...SharedStyles.TextFontStyles.LatoBlack,
  },
  loaderStyle: {
    backgroundColor: 'rgba(52, 52, 52, 0)',
  },
});

export default styles;
