import React, { useContext, useEffect } from 'react';
import { View, Text } from 'react-native';
import { DropDownOption, DropDownSingleSelection } from 'shared/ui-component/DropDown/DropDown';
import { Avatar } from 'shared/ui-component/Avatar';
import { ImageSizes } from 'shared/util/RenderImage';
import { IconSVG } from 'shared/ui-component/Icon';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Checkbox } from 'shared/ui-component/Checkbox';
import * as API from 'shared/backend-data/index';
import styles from './styles';
import { Colors, fontSize, TextFontStyles } from 'shared/styles';
import { t } from 'shared/localisation/i18n';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { ModalUtils } from 'shared/ui-component/Modal';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { isGhostWorker } from 'shared/util/Worker';
import { findIndex } from 'lodash-es';
import { ScrollView } from 'react-native';
import { formatDecimalPercentageTargetCount } from 'shared/backend-data';
import { WorkerDropDownOptionWithDetails } from 'shared/ui-component/DropDown/DropDown';

const ICON_REMOVE = require('shared/assets/svg/icon.close.svg').default;
const IconCheck = require('shared/assets/svg/icon.check.svg').default;

interface Props {
  trainingSession?: API.TrainingSession;
  scheduledWorkers: WorkerDropDownOptionWithDetails[];
  traineesIds: readonly string[];
  isEditUnlocked: boolean;
  canWorkerMangeTrainingSession?: boolean;
  isMentorList?: boolean;
  handleWorkers: (_oldWorkers: WorkerDropDownOptionWithDetails[]) => void;
  createTrainingRequestForTheWorker?: (worker: string) => void;
}

interface RemoveWorkerIconProps {
  handleRemoveWorker: () => void;
  showWarningModal: boolean;
}

export const WorkersList: React.FC<Props> = props => {
  const {
    trainingSession,
    scheduledWorkers,
    traineesIds,
    isEditUnlocked,
    canWorkerMangeTrainingSession,
    isMentorList,
    handleWorkers,
    createTrainingRequestForTheWorker,
  } = props;

  useEffect(() => {
    
    if (
      isMentorList &&
      scheduledWorkers.length === 1 &&
      !scheduledWorkers[0].trainingSessionCoveragePercentage
    )
      handleWorkers(handlePercentage(scheduledWorkers[0].key, '100%', scheduledWorkers));
  }, [scheduledWorkers]);

  const { isValidPermission } = useContext(PermissionManagementContext);

  function removeWorker(workerId: string, workers: WorkerDropDownOptionWithDetails[]) {
    const _workers = [...workers];
    _workers.splice(
      findIndex(workers, worker => worker.key === workerId),
      1,
    );

    if (createTrainingRequestForTheWorker) createTrainingRequestForTheWorker(workerId);

    return _workers;
  }

  function isShowWarningModal(workerId: string) {
    if (trainingSession && trainingSession.scheduledTraineeIds.includes(workerId)) return true;
    else return false;
  }

  function handleCheckWorker(
    workerId: string,
    isChecked: boolean,
    workers: WorkerDropDownOptionWithDetails[],
  ) {
    const _workers = [...workers];
    const workerIndex = findIndex(workers, worker => worker.key === workerId);
    _workers[workerIndex] = { ..._workers[workerIndex], isChecked: isChecked };
    return _workers;
  }

  function handlePercentage(
    workerId: string,
    percentage: string | undefined,
    mentors: WorkerDropDownOptionWithDetails[],
  ) {
    const _workers = [...mentors];
    const workerIndex = findIndex(mentors, worker => worker.key === workerId);
    _workers[workerIndex] = {
      ..._workers[workerIndex],
      trainingSessionCoveragePercentage: percentage,
    };
    return _workers;
  }

  return (
    <ScrollView style={styles.workersListContainer}>
      {scheduledWorkers.map((_worker, index) => {
        return (
          <View
            style={[styles.workerRow, { zIndex: -index - 2, marginBottom: 40 }]}
            key={_worker.key}
          >
            {isMentorList && (
              <View>
                <DropDownSingleSelection
                  options={getPercentagesDropDownOptions()}
                  value={{
                    key: _worker.key,
                    label: _worker.trainingSessionCoveragePercentage
                      ? _worker.trainingSessionCoveragePercentage
                      : index === 0
                      ? formatDecimalPercentageTargetCount(100, true)
                      : formatDecimalPercentageTargetCount(0, true),
                  }}
                  notEditable={Boolean(scheduledWorkers.length === 1) || !isEditUnlocked}
                  placeholder={''}
                  dropDownMenuContainerStyle={{ width: 75 }}
                  containerStyle={{
                    width: '60%',
                    marginTop: -40,
                  }}
                  handleChange={(_, value) =>
                    handleWorkers(handlePercentage(_worker.key, value?.label, scheduledWorkers))
                  }
                />
              </View>
            )}
            <View style={styles.workerAvatar}>
              <Avatar
                name={_worker.label}
                imgS3Key={_worker.value?.profilePicture}
                size={ImageSizes.Small}
              />
              <View style={styles.horizontalDivider} />
              <EllipsisWithTooltip textStyle={styles.workerNameTextStyle} text={_worker.label} />
            </View>
            {!isGhostWorker(_worker.value?.name) && (
              <View style={styles.checkBoxContainer}>
                {trainingSession && API.isTrainingSessionValidated(trainingSession) ? (
                  
                  API.isTrainingSessionStartedOrStartedLateOrLateEnd(trainingSession) &&
                  !isMentorList ? (
                    
                    traineesIds.includes(_worker.key) ? (
                      
                      <View style={{ height: 12, width: 12 }}>
                        <IconCheck color={Colors.Green} />
                      </View>
                    ) : (
                      
                      <Text
                        style={{
                          ...TextFontStyles.LatoMedium,
                          fontSize: fontSize.Regular,
                          color: Colors.GreyLight,
                        }}
                      >
                        {t('alex:worker.profile.infoPanel.absent')}
                      </Text>
                    )
                  ) : 
                  API.isTrainingSessionDraftOrRequestValidated(trainingSession) ||
                    isEditUnlocked ? (
                    isValidPermission(API.Permission.trainingSessions_edit) ? (
                      <RemoveWorkerIcon
                        handleRemoveWorker={() => {
                          handleWorkers(removeWorker(_worker.key, scheduledWorkers));
                        }}
                        showWarningModal={isShowWarningModal(_worker.key)}
                      />
                    ) : (
                      <></>
                    )
                  ) : API.isTrainingSessionScheduledOrLateStart(trainingSession) &&
                    !isMentorList ? (
                    <Checkbox
                      initialState={_worker.isChecked}
                      onChange={state => {
                        if (state === null) return;
                        handleWorkers(handleCheckWorker(_worker.key, state, scheduledWorkers));
                      }}
                      disabled={!canWorkerMangeTrainingSession}
                    />
                  ) : (
                    <></>
                  )
                ) : trainingSession && API.isTrainingSessionToReview(trainingSession) ? (
                  <></>
                ) : isValidPermission(API.Permission.trainingSessions_edit) ? (
                  <RemoveWorkerIcon
                    handleRemoveWorker={() => {
                      handleWorkers(removeWorker(_worker.key, scheduledWorkers));
                    }}
                    showWarningModal={isShowWarningModal(_worker.key)}
                  />
                ) : (
                  <></>
                )}
              </View>
            )}
          </View>
        );
      })}
    </ScrollView>
  );
};

const RemoveWorkerIcon: React.FC<RemoveWorkerIconProps> = props => {
  const { handleRemoveWorker, showWarningModal } = props;

  const modal = ModalUtils.useModal();

  function removeWorker() {
    handleRemoveWorker();

    if (showWarningModal) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('alex:mobile.trainingScreen.workerRemoved'),
          warningAcceptButton: t('common:button.gotIt'),
        }),
      );
    }
  }
  return (
    <TouchableOpacity onPress={() => removeWorker()}>
      <IconSVG
        svgComponent={ICON_REMOVE}
        size={{ width: 24, height: 24 }}
        containerStyle={{ width: 24, height: 24 }}
      />
    </TouchableOpacity>
  );
};

function getPercentagesDropDownOptions(): DropDownOption[] {
  const countOptions = Array.from({ length: 21 }, (_, i) => i * 5);
  const countOptionsPercentage = countOptions.map(option => {
    return { key: option.toString(), label: option.toString() + '%', value: option };
  });

  return countOptionsPercentage;
}
