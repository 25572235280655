import { Platform } from 'react-native';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { AppContext } from '../context/AppContext';

export enum VersionType {
  /** Reserved for API Breaking Changes */
  Major,
  /** Reserved for Client Breaking Changes */
  Minor,
  Build,
  /** if the client needs upgrade because of an API breaking change but that the online app is not published/available yet */
  Incompatible,
}

class _AppVersionChecker {
  private static instance: _AppVersionChecker;
  private clientVersion: string | undefined;
  private onlineClientVersion: string | undefined;
  private appStoreURL: string = '';

  setOnlineClientVersion(version: string): void {
    this.onlineClientVersion = version;
  }

  setClientVersion(version: string): void {
    this.clientVersion = version;
  }
  getClientVersion(): string {
    if (!this.clientVersion)
      throw new Error('setClientVersion() before any call to getClientVersion()');
    return this.clientVersion;
  }

  setAppStoreURL(url: string): void {
    this.appStoreURL = url;
  }
  getAppStoreURL(): string {
    return this.appStoreURL;
  }

  /**
   * Compares 2 versions and returns whether the first is newer
   * up to a specific depth (minor, major, build)
   * @param newVersion first version: (string)
   * @param oldVersion second version: (string)
   * @param depth depth level (from SearchDepth enum)
   * @return true if versionA is newer, false otherwise
   */
  isVersionNewer(
    newVersion: string,
    oldVersion: string,
    depth: Omit<VersionType, VersionType.Incompatible>,
  ): boolean {
    const [versAMajor, versAMinor, versABuild] = [
      parseInt(newVersion.split('.')[0], 10),
      parseInt(newVersion.split('.')[1], 10),
      newVersion.split('.')[2],
    ];
    const [versBMajor, versBMinor, versBBuild] = [
      parseInt(oldVersion.split('.')[0], 10),
      parseInt(oldVersion.split('.')[1], 10),
      oldVersion.split('.')[2],
    ];

    const [isNewerMajor, isNewerMinor, isNewerBuild, isMajorEqual, isMinorEqual] = [
      versAMajor > versBMajor,
      versAMinor > versBMinor,
      versABuild > versBBuild,
      versAMajor === versBMajor,
      versAMinor === versBMinor,
    ];

    switch (depth) {
      case VersionType.Major:
        return isNewerMajor;

      case VersionType.Minor:
        return isMajorEqual ? isNewerMinor : isNewerMajor;

      case VersionType.Build:
        if (isMajorEqual && isMinorEqual) return isNewerBuild;
        else return isMajorEqual ? isNewerMinor : isNewerMajor;

      default:
        logger.warn('This code shall never be hitten, please fix');
        return false;
    }
  }

  /**
   * Check for the latest client online version and detects it here are breaking changes
   * @return
   *  - Failure: if one of the versions is undefined before the checks
   *  - false: if their is no update available
   *  - VersionType otherwise telling wich type of update is available
   */
  async getLatestClientOnlineVersion(): Promise<API.Result<VersionType | false>> {
    const isPlatformWeb = Platform.OS === 'web';

    
    if (!this.clientVersion) return API.createFailure_Unspecified('Cannot get client version');

    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) return appContext;

    if (appContext.pkType !== API.PkType.TenantApp)
      return API.createFailure_Unspecified(
        'Context should be a TenantApp context to retrieve onlineBackendversion and onlineClientVersion',
      );
    const appId = appContext.pk.split(API.SeparatorIds)[1];

    
    const factory = await API.getFactory(API.DataType.APP, appId, 'network-no-cache');
    if (API.isFailure(factory)) return factory;

    const onlineBackendVersion = factory.app.version;

    
    if (!this.onlineClientVersion) {
      if (isPlatformWeb) {
        AppVersionChecker.setOnlineClientVersion(onlineBackendVersion);
      } else {
        
        
        if (Platform.OS === 'ios' && factory.app.appleStoreVersion) {
          AppVersionChecker.setOnlineClientVersion(factory.app.appleStoreVersion);
        } else if (Platform.OS === 'android' && factory.app.playStoreVersion) {
          AppVersionChecker.setOnlineClientVersion(factory.app.playStoreVersion);
        }
      }
    }

    if (!this.onlineClientVersion)
      return API.createFailure_Unspecified('Cannot get online client version');

    logger.debug('ClientVersion: ' + this.clientVersion);
    logger.debug('OnlineBackendVersion: ' + onlineBackendVersion);
    logger.debug('OnlineClientVersion: ' + this.onlineClientVersion);
    logger.debug('OnlineStoreURL: ' + this.appStoreURL);

    
    
    if (this.isVersionNewer(this.onlineClientVersion, this.clientVersion, VersionType.Build)) {
      return false;
    }

    const isApiBreakingChange: boolean =
      this.clientVersion.split('.')[0] !== onlineBackendVersion.split('.')[0];

    if (isApiBreakingChange) {
      if (this.onlineClientVersion.split('.')[0] !== onlineBackendVersion.split('.')[0]) {
        return VersionType.Incompatible;
      } else {
        return VersionType.Major;
      }
    }

    const isClientBreakingChange: boolean = this.isVersionNewer(
      this.onlineClientVersion,
      this.clientVersion,
      VersionType.Minor,
    );
    if (isClientBreakingChange) return VersionType.Minor;

    return false;
  }

  static getInstance(): _AppVersionChecker {
    if (!_AppVersionChecker.instance) {
      this.instance = new _AppVersionChecker();
    }
    return _AppVersionChecker.instance;
  }
}

export const AppVersionChecker = _AppVersionChecker.getInstance();
