import { StyleSheet } from 'react-native-web'; 
import { Colors, TextFontStyles, ModalStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    minHeight: ModalStyles.minHeight,
    flexGrow: 0,
  },
  closeButtonIcon: {
    position: 'absolute',
    right: 12,
    top: 9,
  },
  innerContainer: {
    maxHeight: '100%',
    flexGrow: 1,
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    width: 688,
    height: 64,
    justifyContent: 'space-between',
    paddingLeft: ModalStyles.bodyPaddingLeft,
  },
  inputText: {
    width: 576,
  },
  messageInputContainer: {
    height: 60,
    paddingLeft: 112,
    paddingRight: 157,
    paddingTop: 20,
  },
  messageInputText: {
    width: 530,
  },
  unitsOuterContainer: {
    flex: 1,
    maxHeight: '100%',
    marginLeft: 108,
  },
  unitsContainer: {
    paddingTop: 38,
  },
  workerDropDown: {
    width: 272,
    height: 60,
    marginLeft: 36,
    zIndex: 999,
  },
  photoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: ModalStyles.anchorWidth,
    paddingTop: ModalStyles.anchorPaddingTop,
    marginRight: ModalStyles.anchorMarginRight,
  },
  icon: {
    backgroundColor: Colors.Yellow,
    borderRadius: (48 + 48) / 2,
    height: ModalStyles.anchorHeight,
    width: ModalStyles.anchorWidth,
  },
  buttonContainer: {
    paddingBottom: ModalStyles.footerBigButtonPaddingBotom,
    paddingTop: ModalStyles.footerBigButtonPaddingTop,
    alignItems: 'center',
    zIndex: -1,
  },
  buttomButton: {
    width: 336,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emailExistText: {
    paddingBottom: 27,
    color: 'grey',
    fontSize: fontSize.Regular,
  },
  emailExistContainer: {
    paddingLeft: ModalStyles.bodyPaddingLeft,
    paddingTop: 53,
  },
  goBackIconContainer: {
    paddingRight: 8,
    width: 24,
    height: 24,
  },
  ButtomButtonText: {
    fontSize: fontSize.Medium,
    ...TextFontStyles.LatoBold,
  },
});

export default styles;
