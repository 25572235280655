import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { DataLayer } from '../backend-data/DataLayer';
import { getLanguage, Locale, setLanguageAndLoadGlossary } from '../localisation/i18n';
import { MyHub } from '../util/MyHub';
import { UserContext } from './UserContext';
import * as _ from 'lodash-es';
import { PkType } from 'shared/backend-data';
import { Immutable } from '../util-ts/Functions';

export const whiteSpaceWorkerName = ' ';
export const emptyString = '';

export interface AppContext {
  pk: string;
  pkType: PkType;
  workerId: string;
}

class _AppContext {
  private static instance: _AppContext;
  private context: AppContext | null;
  private constructor() {
    this.context = null;
  }

  /**
   * Get the current App (akka TenantApp) Context. If no user is signed-in, a Failure is returned
   */
  getContext(): API.Result<Immutable<AppContext>> {
    if (!this.context) {
      return API.createFailure_Unspecified(
        'No App Context set. Please Sign-in, select an app and try again.',
      );
    }

    return this.context;
  }

  async setContext(pk: null, calledFromMigrations?: boolean): Promise<void>;
  async setContext(pk: string, calledFromMigrations?: boolean): Promise<API.Result<void>>;
  /**
   * Switch to a new Tenant or TenantApp
   * @param pk : if null is passed then the context is unloaded
   * @param calledFromMigrations (optional) shall not be used
   */
  async setContext(
    pk: string | null,
    calledFromMigrations: boolean = false,
  ): Promise<API.Result<void>> {
    const oldPk = this.context?.pk;

    
    if (pk === null) {
      this.context = null;
    } else {
      const pkType = API.getPkType(pk);
      
      if (pkType !== API.PkType.TenantApp)
        return API.createFailure_Unspecified(
          'App context can only be set to a TenantApp pk. Current pk=' + pk,
        );

      if (calledFromMigrations) {
        this.context = {
          pk,
          pkType: pkType,
          workerId: 'workerId not defined because calledFromMigrations',
        };
      } else {
        const user = UserContext.getUser();
        if (API.isFailure(user)) return user;

        const authorizedPk = user.authorizedPks.find(_authorizedPk => _authorizedPk.pk === pk);
        if (!authorizedPk) {
          return API.createFailure_Unspecified(
            'User is not allowed to switch to this pk context: ' + pk,
          );
        }

        this.context = {
          pk: authorizedPk.pk,
          pkType: pkType,
          workerId: authorizedPk.workerId ?? 'workerId shall not be empty as pkType=TenantApp',
        };
      }
    }

    
    if (oldPk !== pk) {
      logger.info('AppContext switched from: ' + oldPk + ' to: ' + pk);

      
      if (oldPk) {
        await this.unSetTenantApp(oldPk);
      }

      
      if (pk) {
        const result = await this.setTenantApp(this.context!, calledFromMigrations); 
        if (API.isFailure(result)) return result;
      }
    }
  }

  private latestTenantPk: string = '';
  private async setTenantApp(
    context: AppContext,
    calledFromMigrations: boolean,
  ): Promise<API.Result<void>> {
    const tenantPk = context.pk.split(API.SeparatorIds)[0];

    
    if (tenantPk !== this.latestTenantPk) {
      await DataLayer.subscribeToUpdates(tenantPk);

      const sync = await DataLayer.syncClientCacheWithServer(tenantPk);
      if (API.isFailure(sync)) return sync;

      this.latestTenantPk = tenantPk;
    }

    
    {
      await DataLayer.subscribeToUpdates(context.pk);

      if (calledFromMigrations) {
        const sync = await DataLayer.syncClientCacheWithServer(tenantPk);
        if (API.isFailure(sync)) return sync;
      } else {
        const factory = await this.fillMissingWorkerData(context.workerId);
        if (API.isFailure(factory)) return factory;

        
        MyHub.dispatchAppContext('TenantAppSet', {
          pk: context.pk,
          factory,
        });
      }
    }
  }

  private async unSetTenantApp(pk: string): Promise<void> {
    
    {
      
    }

    
    {
      await DataLayer.unSubscribeToUpdates(pk);

      MyHub.dispatchAppContext('TenantAppUnset', {
        pk,
      });
    }
  }

  private async fillMissingWorkerData(
    workerId: string,
  ): Promise<API.Result<API.Factory<API.DataType.WORKER>>> {
    const factory = await API.getFactoryBusinessObject(
      API.DataType.WORKER,
      workerId,
      'network-no-cache',
    );
    if (API.isFailure(factory)) return factory;

    if (
      factory.worker.name === whiteSpaceWorkerName ||
      !factory.worker.firstName ||
      !factory.worker.familyName
    ) {
      const user = UserContext.getUser();
      if (API.isFailure(user)) return user;

      let workerUpdateInput: API.WorkerPartialUpdateInput = { id: factory.worker.id };
      workerUpdateInput.name = user.userAttributes.name;
      const split = user.userAttributes.name.split(' ');
      workerUpdateInput.firstName = factory.worker.firstName.length
        ? factory.worker.firstName
        : user.userAttributes.given_name ?? split[0];
      workerUpdateInput.familyName = factory.worker.familyName.length
        ? factory.worker.familyName
        : user.userAttributes.family_name ?? split[1];

      logger.info('Updating worker name from user name', factory.worker, workerUpdateInput);

      return API.updateFactoryBusinessObject(API.DataType.WORKER, workerUpdateInput);
    } else {
      return factory;
    }
  }

  /**
   * It is important to reload the language glossary before rendering an App
   */
  async getUserPreferedLanguageAndSetLanguageAndGlossary() {
    let locale = await API.getUserPreference<Locale>(API.UserPreferenceKeys_Common.UserLanguage);
    if (API.isFailure(locale)) {
      logger.warn('Error while geting user language Preference', locale);
      locale = undefined;
    }
    
    if (!locale) {
      
      await API.saveUserPreference<Locale>(
        API.UserPreferenceKeys_Common.UserLanguage,
        getLanguage(locale).locale,
      );
    }
    
    
    setLanguageAndLoadGlossary(getLanguage(locale));
  }

  static getInstance(): _AppContext {
    if (!_AppContext.instance) {
      _AppContext.instance = new _AppContext();
    }
    return _AppContext.instance;
  }
}

export const AppContext = _AppContext.getInstance();
