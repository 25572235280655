import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    marginRight: '0.2vw',
    
    
  },
  selectButton: {
    width: '28vw',
    backgroundColor: '#F1F3F4',
    borderColor: '#D6D6D6',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 5,
    height: '4.4vh',
    flexDirection: 'row',
  },
  labelContainer: {
    width: '70%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    width: '30%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropDownLabel: {
    color: '#666666',
    fontFamily: 'Lato, Regular',
    fontSize: '1.7vh',
  },
  dropDownArrow: {
    height: '1.5vh',
    width: '0.6vw',
    resizeMode: 'contain',
  },
  flatListContainer: {
    height: '19.9vh',
    backgroundColor: 'white',
    width: '5vw',
    borderWidth: 1,
    borderColor: '#78DBD9',
    position: 'absolute',
    top: 42,
    zIndex: 999,
  },
  listItemContainer: {
    justifyContent: 'center',
    paddingTop: '1.5vh',
    paddingBottom: '1.5vh',
  },
});

export default styles;
