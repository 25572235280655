import { Platform } from 'react-native-web';
import { t } from 'shared/localisation/i18n';

const isPlatformWeb = Platform.OS === 'web';
export const CertificateWidth = 346;
export const CertificateHieght = 531;
export const A4HeightInPoints = 842;
export const A4WidthInPoints = 595;
export const PointToPixelRatio = 0.77;
export const MobilePdfDefaultHeightInPixels = (A4HeightInPoints - 20) / PointToPixelRatio; 
const pixelRatio = isPlatformWeb ? 1 : MobilePdfDefaultHeightInPixels / CertificateHieght;
const certificateFileIconWidth = 52 * pixelRatio;
const certificateFileIconHeight = 66.5 * pixelRatio;

const styles = `
.rootContainer {
  width: 100%;
  padding-top: ${16 * pixelRatio}px;
  padding-bottom: ${16 * pixelRatio}px;
  padding-right: ${16 * pixelRatio}px;
  display:flex;
  flex-direction: column;
  background-color: white;
}
.title{
  font-family: 'Lato Regular', sans-serif !important;
  text-align:left;
  font-size: ${12 * pixelRatio}px;
  margin-bottom: ${44 * pixelRatio}px;
  margin-left: ${24 * pixelRatio}px;
  margin-right: ${24 * pixelRatio}px;
  color: black;
  line-height: 1.2;
  letter-spacing: 0px;
}
.workersNamesContainer{
  margin-bottom: ${8 * pixelRatio}px;
  margin-left: ${24 * pixelRatio}px;
  margin-right: ${24 * pixelRatio}px;
}
.workers{
  font-family: 'Lato Regular', sans-serif !important;
  font-size: ${12 * pixelRatio}px;
  left: ${24 * pixelRatio}px;
  bottom:0;
  color: black;
  line-height: 1.2;
  letter-spacing: 0px;
}
.skills {
  font-family: 'Lato Regular', sans-serif !important;
  font-size: ${12 * pixelRatio}px;
  left: ${24 * pixelRatio}px;
  bottom:0;
  color: black;
  line-height: 1.2;
  letter-spacing: 0px;
  margin-bottom: ${8 * pixelRatio}px;
  margin-left: ${24 * pixelRatio}px;
  margin-right: ${24 * pixelRatio}px;
}
.skillHeader{
  font-family: 'Lato Black', sans-serif !important;
  font-weight: 900;
  font-size: ${22 * pixelRatio}px;
  margin-bottom: ${8 * pixelRatio}px;
  margin-left: ${24 * pixelRatio}px;
  margin-right: ${24 * pixelRatio}px;
  text-align:left;
  color: black;
  line-height: 1.2;
  letter-spacing: 0px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.icon{
  justify-self: center;
  justify-content: center;
  align-items: center;
  margin:auto ;
  margin-top: 0;
  margin-bottom: ${28 * pixelRatio}px;
}
.signature{
  font-family: 'Lato Regular', sans-serif !important;
  font-size: ${12 * pixelRatio}px;
  margin-left: ${24 * pixelRatio}px;
  margin-right: ${24 * pixelRatio}px;
  text-align:left;
  color: black;
  line-height: 1.2;
  letter-spacing: 0px;
}
.note{
  font-family: 'Lato Regular', sans-serif !important;
  font-size: ${8 * pixelRatio}px;
  margin-left: ${24 * pixelRatio}px;
  margin-right: ${24 * pixelRatio}px;
  margin-bottom: ${5 * pixelRatio}px;
  text-align:left;
  color: black;
  line-height: 1.2;
  letter-spacing: 0px;
}
.date{
  font-family: 'Lato Regular', sans-serif !important;
  font-size: ${12 * pixelRatio}px;
  text-align:left;
  margin-left: ${24 * pixelRatio}px;
  margin-right: ${24 * pixelRatio}px;
  margin-bottom: ${21 * pixelRatio}px;
  color: black;
  line-height: 1.2;
  letter-spacing: 0px;      
}
.signatureIcon{
  margin:auto ;
  margin-top: 0;
  width: ${90 * pixelRatio}px;
  height: ${54 * pixelRatio}px;
}
`;
const fileSvgIcon = ` <g id="certicate" transform="translate(-5852.3 -2939.5)">
<g id="icon.file" transform="translate(5846.8 2937)">
  <path
    id="Tracé_897"
    data-name="Tracé 897"
    d="M45.6,3H14.8A8.8,8.8,0,0,0,6,11.8V82.2A8.8,8.8,0,0,0,14.8,91H67.6a8.8,8.8,0,0,0,8.8-8.8V33.8Z"
    transform="translate(0 0)"
    fill="none"
    stroke="black"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1"
  />
  <path
    id="Tracé_898"
    data-name="Tracé 898"
    d="M19.5,3V33.8H50.3"
    transform="translate(26.1 0)"
    fill="none"
    stroke="black"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1"
  />
</g>
<path
  id="Icon_awesome-signature"
  data-name="Icon awesome-signature"
  d="M12.224,7.01c-1.016.069-2.465,1.073-3.2,1.4a4.06,4.06,0,0,1-1.493.479c-.443,0-.51-.318-.418-1.018.022-.157.229-1.553-.837-1.493-.492.029-1.261.486-3.324,2.471l.814-2.034A1.7,1.7,0,0,0,1.223,4.8L.147,5.525a.315.315,0,0,0-.1.433l.337.53a.312.312,0,0,0,.433.1l1.138-.763a.438.438,0,0,1,.641.532L.674,11.17a.627.627,0,0,0,.583.861.62.62,0,0,0,.443-.184c.828-.828,3.034-2.956,4.142-3.84a2.036,2.036,0,0,0,.4,1.644,1.655,1.655,0,0,0,1.285.5,4.96,4.96,0,0,0,2.006-.588,11.658,11.658,0,0,1,2.714-1.291.315.315,0,0,0,.3-.31v-.63a.311.311,0,0,0-.326-.318Z"
  transform="translate(5898.45 3011.47)"
/>
<line
  id="Ligne_83"
  data-name="Ligne 83"
  x2="50"
  transform="translate(5862.5 2980.5)"
  fill="none"
  stroke="black"
  stroke-width="1"
/>
<line
  id="Ligne_84"
  data-name="Ligne 84"
  x2="50"
  transform="translate(5862.5 2988)"
  fill="none"
  stroke="black"
  stroke-width="1"
/>
<line
  id="Ligne_85"
  data-name="Ligne 85"
  x2="50"
  transform="translate(5862.5 2996)"
  fill="none"
  stroke="black"
  stroke-width="1"
/>
<line
  id="Ligne_86"
  data-name="Ligne 86"
  x2="34"
  transform="translate(5862.5 3004)"
  fill="none"
  stroke="black"
  stroke-width="1"
/>
<line
  id="Ligne_88"
  data-name="Ligne 88"
  x2="21"
  transform="translate(5862.5 2959)"
  fill="none"
  stroke="black"
  stroke-width="1"
/>
</g>`;

export const autoCertificateHtmlContent = (
  workersNamesString: string,
  skillsOrTrainingsNamesString: string,
  date: string,
  validatorName: string,
  isProofOfTraining: boolean,
  revokeCertificate: boolean,
  comment: string = '',
  skillsIncludedInTraining: string,
  returnContentAsText?: boolean,
) =>
  returnContentAsText
    ? `
    ${t('alex:mobile.addProofPage.autoCertificate.title')}

${t(
  revokeCertificate
    ? 'alex:mobile.addProofPage.autoCertificate.workerRevokedSkill'
    : isProofOfTraining
    ? 'alex:mobile.addProofPage.autoCertificate.workerHasTraining'
    : 'alex:mobile.addProofPage.autoCertificate.workerHasSkill',
  {
    workersName: workersNamesString,
  },
)} ${skillsOrTrainingsNamesString} ${skillsIncludedInTraining}

${comment}

${t('alex:mobile.addProofPage.autoCertificate.dateOfAutoCertificate', {
  date: date,
  validatorName: validatorName,
})}
`
    : `
<head>
  <link href='https://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'>
  <title>${t('alex:mobile.addProofPage.autoCertificate.title')}</title>
  <style>${styles}</style>
</head>
<div class="rootContainer">
  <div class="title">${t('alex:mobile.addProofPage.autoCertificate.title')}</div>
  <div class="workersNamesContainer">
    <div class="workers">${t(
      revokeCertificate
        ? 'alex:mobile.addProofPage.autoCertificate.workerRevokedSkill'
        : isProofOfTraining
        ? 'alex:mobile.addProofPage.autoCertificate.workerHasTraining'
        : 'alex:mobile.addProofPage.autoCertificate.workerHasSkill',
      {
        workersName: workersNamesString,
      },
    )}</div>
  </div>
  <div class="skillHeader">${skillsOrTrainingsNamesString}</div>
  <div class="skills">${skillsIncludedInTraining}</div>
  <div class="icon">
  <svg xmlns="http://www.w3.org/2000/svg" width="${certificateFileIconWidth}" height="${certificateFileIconHeight}" viewBox="0 0 71.4 89">
  ${fileSvgIcon}
  </svg>
  </div>
  <div class="note">${comment}</div>
  <div class="signature">${t('alex:mobile.addProofPage.autoCertificate.dateOfAutoCertificate', {
    date: date,
    validatorName: validatorName,
  })}</div>
</div>
`;

export const signCertificateHtmlContent = (
  workersNamesString: string,
  skillsOrTrainingsNamesString: string,
  date: string,
  signatureBase64: string,
  proofOfTraining: boolean,
  revokeCertificate: boolean,
  comment: string = '',
  skillsIncludedInTraining: string,
) => `
<head>
  <link href='http://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'>
  <title>${t('alex:mobile.addProofPage.autoCertificate.title')}</title>
  <style>${styles}</style>
</head>
<div class="rootContainer">
  <div class="title">${t('alex:mobile.addProofPage.autoCertificate.title')}</div>
  <div class="workersNamesContainer">
  <div class="workers">${t(
    revokeCertificate
      ? 'alex:mobile.addProofPage.autoCertificate.workerRevokedSkill'
      : proofOfTraining
      ? 'alex:mobile.addProofPage.autoCertificate.workerHasTraining'
      : 'alex:mobile.addProofPage.autoCertificate.workerHasSkill',
    {
      workersName: workersNamesString,
    },
  )}
  </div>
  </div>
  <div class="skillHeader">${skillsOrTrainingsNamesString}</div>
  <div class="skills">${skillsIncludedInTraining}</div>
  <div class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="${certificateFileIconWidth}" height="${certificateFileIconHeight}" viewBox="0 0 71.4 89">
    ${fileSvgIcon}
  </svg>
</div>
<div class="note">${comment}</div>
<div class="date">${t('alex:mobile.addProofPage.autoCertificate.dateOfSignatureCertificate', {
  date: date,
})}
</div>
<img class="signatureIcon" src=${signatureBase64}/>
</div>
`;
