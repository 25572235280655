import { StyleSheet } from 'react-native';
import { Spacings, Colors, TextFontStyles, ModalStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  cardContainerStyle: { paddingBottom: ModalStyles.footerHeight },
  closeButtonIcon: {
    position: 'absolute',
    right: 12,
    top: 9,
  },
  submitBtn: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: ModalStyles.smallButtonMargin,
    height: Spacings.Big,
    paddingBottom: ModalStyles.footerSmallButtonPaddingBotom,
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  button: {
    color: Colors.Black,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginRight: 60,
  },
  reAcitveButton: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
  },
  deleteButton: {
    paddingRight: 64,
  },
  disabled: {
    color: Colors.GreyLight,
  },
  red: {
    color: Colors.Red,
  },
  setMarginTop: {
    marginTop: 48,
  },
  textInputContainer: {
    flexDirection: 'row',
    zIndex: 2,
  },
  photoContainer: {
    paddingTop: ModalStyles.anchorPaddingTop,
    paddingLeft: ModalStyles.bodyPaddingLeft,
    flexDirection: 'row',
  },
  photo: {
    height: Spacings.Big + Spacings.Unit,
    width: Spacings.Big + Spacings.Unit,
    borderRadius: (Spacings.Big + Spacings.Unit) / 2,
    backgroundColor: Colors.FlashWhite,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInputOuterContainer: {
    paddingLeft: ModalStyles.anchorMarginRight,
    paddingTop: Spacings.Unit,
  },
  textRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftInputText: {
    width: ModalStyles.inputWidthWithAnchor,
    marginRight: ModalStyles.bodyDivider,
  },
  inputText: {
    width: ModalStyles.inputWidthWithAnchor,
  },
  inputTag: {
    width: ModalStyles.inputWidthWithAnchor,
  },
  contractSectionContainer: {
    paddingTop: Spacings.Large + Spacings.Unit,
    zIndex: 1,
    marginLeft: 108,
  },
  addIcon: {
    paddingLeft: 76,
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
  },
  addIconLabel: {
    color: Colors.Black,
    fontSize: fontSize.XLarge,
    ...TextFontStyles.LatoRegular,
    paddingRight: Spacings.Small + Spacings.Unit,
  },
  contractTypeContainer: {
    flexDirection: 'row',
  },
  contractTypeDropdown: {
    width: 272,
    height: 64,
  },
  dateContainer: {
    width: 272,
    marginLeft: 40,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  startDate: {
    width: 124,
  },
  endDate: {
    width: 124,
  },
  unitContainer: {
    zIndex: -1,
    marginLeft: 108,
  },
  addUnit: {
    color: Colors.Grey,
    paddingLeft: 12,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
  },
  unitDropdownContainer: {
    paddingLeft: 111,
    flexDirection: 'column',
  },
  addUnitDiscription: {
    paddingLeft: 111,
    paddingTop: 16,
    color: Colors.Grey,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
  },
  footer: {
    height: 192,
    backgroundColor: Colors.Background,
    borderRadius: Spacings.Small,
    marginTop: Spacings.Big,
    marginLeft: 40,
    marginRight: 40,
    flexDirection: 'row',
  },
  inviteText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  inviteTextBold: {
    ...TextFontStyles.LatoBlack,
  },
  inviteTextContainer: {
    flexDirection: 'row',
    textAlign: 'center',
  },
  leftPanel: {
    width: '55%',
    paddingLeft: 74,
    alignItems: 'center',
  },
  rightPanel: {
    width: '45%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconStyle: {
    paddingVertical: Spacings.CardPadding,
  },
  footerButton: {
    width: 152,
  },
  footerButtonText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Small,
  },
  buttonContainer: {
    marginTop: Spacings.Medium,
  },
  notes: {
    zIndex: -2,
  },
  contractTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  trashContainer: {
    justifyContent: 'flex-end',
    marginLeft: Spacings.Small,
  },
  addWorkerAndUnitText: {
    color: Colors.Grey,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginTop: Spacings.Large,
  },
  toolTip: { marginLeft: Spacings.Small, marginRight: Spacings.Small },
});

export default styles;
