import React, { useState } from 'react';
import { View, Text, TouchableOpacity, ViewStyle, Platform, Linking } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors } from 'shared/styles';
import * as _ from 'lodash-es';
import { HoverableIcon } from 'shared/ui-component/HoverableIcon/index';
import * as API from 'shared/backend-data';
import { loadS3Files } from 'shared/util/S3Upload';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { Styles } from './Styles';
import { isImage } from 'shared/util-ts/Functions';
import { t } from 'shared/localisation/i18n';
import { Loader } from '../Loader/Loader';
import { ActivityIndicator, ActivityIndicatorSize } from '../Loader/ActivityIndicator';
import { LoaderThreeDots } from '../Loader/LoaderThreeDots';

const isPlatformWeb = Platform.OS === 'web';

interface Props {
  index?: number;
  file: API.S3ObjectInput | File;
  containerStyle?: ViewStyle;
  onDelete?: (index: number) => void;
  onPreviewFile?: (selectedFileURL: string) => void;
  hideFileName?: boolean;
  editDisabled?: boolean;
  uploadingInProgress?: boolean;
}

const FileIcon = require('shared/assets/svg/icon.file.svg').default;
const ImageIcon = require('shared/assets/svg/icon.image.svg').default;
const ProofIcon = require('shared/assets/svg/icon.proofTraining.svg').default;
const CloseIcon = require('shared/assets/svg/icon.close.svg').default;

export const FileRow: React.FC<Props> = props => {
  const {
    index,
    file,
    containerStyle,
    onDelete,
    onPreviewFile,
    hideFileName,
    editDisabled,
    uploadingInProgress,
  } = props;

  const [isHover, setIsHover] = useState(false);
  const isMounted = useIsMounted();
  const svgIcon = getIcon(file);

  const ref = useCallOnHover<TouchableOpacity>(
    Colors.Transparent,
    () => setIsHover(true),
    () => setIsHover(false),
  );

  function getIcon(file: API.S3ObjectInput | File) {
    let fileName = '';
    if ('bucket' in file && file.fileName) fileName = file.fileName;
    else if ('name' in file) fileName = file.name;

    if (fileName.includes(t('alex:mobile.addProofPage.autoCertificate.title'))) return ProofIcon;

    if (isImage(fileName)) {
      return ImageIcon;
    } else return FileIcon;
  }

  async function handlePress() {
    if (isPlatformWeb) {
      if ('bucket' in file) {
        const links = await loadS3Files([file]);
        const uri = links[0].downloadLink;
        window.open(uri);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          if (isMounted.current && e.target && _.isString(e.target.result) && onPreviewFile) {
            onPreviewFile(e.target.result);
          }
        };
      }
    } else if ('bucket' in file) {
      const links = await loadS3Files([file]);
      const uri = links[0].downloadLink;
      const supported = await Linking.canOpenURL(uri);
      if (supported) {
        await Linking.openURL(uri);
      }
    }
  }

  return (
    <TouchableOpacity
      ref={ref}
      style={[Styles.fileRowContainer, containerStyle]}
      onPress={handlePress}
    >
      <View style={[Styles.fileRowView, { maxWidth: _.isUndefined(index) ? '85%' : '75%' }]}>
        {uploadingInProgress ? (
          <ActivityIndicator size={ActivityIndicatorSize.small} style={Styles.iconStyle} />
        ) : (
          <IconSVG
            svgComponent={svgIcon}
            size={Styles.iconStyle}
            color={isHover ? Colors.MediumGray : Colors.Grey}
            containerStyle={Styles.iconStyle}
          />
        )}

        {!hideFileName && (
          <Text
            numberOfLines={1}
            style={[Styles.fileNameTextStyle, { maxWidth: _.isUndefined(index) ? '85%' : '75%' }]}
          >
            {'bucket' in file ? file.fileName : file.name}
          </Text>
        )}
      </View>
      {onDelete && !_.isUndefined(index) && !editDisabled && (
        <HoverableIcon
          svgIcon={CloseIcon}
          size={Styles.iconStyle}
          containerStyle={Styles.iconStyle}
          onPress={() => onDelete(index)}
          hoverColor={Colors.MediumGray}
        />
      )}
    </TouchableOpacity>
  );
};
