import React from 'react';
import { StyleSheet, TouchableOpacity, View, Text, StyleProp, ViewStyle } from 'react-native';
import { S3Image } from 'aws-amplify-react-native';
import { Colors } from 'shared/styles';
import { ImageSizes } from '../../util/RenderImage';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import * as SharedStyles from 'shared/styles';
import { styles } from 'shared/util/RenderImage';

interface Props {
  size: ImageSizes;
  imgS3Key?: string | null;
  name: string;
  style?: StyleProp<ViewStyle>;
  onPictureMissing?: () => void;
}

const style = StyleSheet.create({
  commonStyle: {
    backgroundColor: Colors.Background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    backgroundColor: Colors.Grey,
    shadowColor: Colors.Black,
    shadowRadius: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Medium,
    color: SharedStyles.Colors.White,
  },
});

export const Avatar: React.FC<Props> = props => {
  const { size, imgS3Key, name, onPictureMissing } = props;

  const composedStyle = [
    style.commonStyle,
    {
      width: size,
      height: size,
      borderRadius: size / 2,
    },
  ];

  let reactElement;

  if (imgS3Key) {
    reactElement = (
      <View style={composedStyle}>
        <S3Image
          imgKey={imgS3Key}
          style={{
            width: size,
            height: size,
            borderRadius: size / 2,
          }}
        />
      </View>
    );
  } else {
    reactElement = (
      <View
        style={[
          style.textContainer,
          {
            height: size,
            width: size,
            borderRadius: size,
          },
        ]}
      >
        <Text
          style={[
            style.textStyle,
            size === ImageSizes.Big || size === ImageSizes.Large
              ? styles.bigAndLargeImageTextStyle
              : size === ImageSizes.Medium
              ? styles.mediumMediumTextStyle
              : size === ImageSizes.Small
              ? styles.smallImageTextStyle
              : {},
          ]}
        >
          {capitalizeFirstLetter(name.charAt(0))}
        </Text>
      </View>
    );
  }

  
  if (onPictureMissing) {
    return (
      <TouchableOpacity style={props.style} onPress={onPictureMissing}>
        {reactElement}
      </TouchableOpacity>
    );
  } else {
    return reactElement;
  }
};
