import React, { useState, createContext } from 'react';
import { TagExtended } from './container';

export const HeaderFilterContext = createContext<{
  homeScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  workstationScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  trainingScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  trainingWorkerScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  workerScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  skillScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  skillWorkerScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  trainingSessionScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  KPIScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  workerWorkstationScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  workerSkillScreenFilterTags: [TagExtended[], (tags: TagExtended[]) => void];
  currentRoute: [string | undefined, (route: string | undefined) => void];
  currentTabIndex: [undefined | number, (index: number | undefined) => void];
  disableFilter: [boolean, (value: boolean) => void];
  hideFilter: [boolean, (value: boolean) => void];
}>({
  homeScreenFilterTags: [[], () => {}],
  workstationScreenFilterTags: [[], () => {}],
  trainingScreenFilterTags: [[], () => {}],
  trainingWorkerScreenFilterTags: [[], () => {}],
  workerScreenFilterTags: [[], () => {}],
  skillScreenFilterTags: [[], () => {}],
  skillWorkerScreenFilterTags: [[], () => {}],
  trainingSessionScreenFilterTags: [[], () => {}],
  KPIScreenFilterTags: [[], () => {}],
  workerWorkstationScreenFilterTags: [[], () => {}],
  workerSkillScreenFilterTags: [[], () => {}],
  currentRoute: ['', () => {}],
  currentTabIndex: [undefined, () => {}],
  disableFilter: [false, () => {}],
  hideFilter: [false, () => {}],
});

export const HeaderFilterProvider: React.FC = props => {
  const [homeScreenFilterTags, setHomeScreenFilterTags] = useState<TagExtended[]>([]);
  const [workstationScreenFilterTags, setWorkstationScreenFilterTags] = useState<TagExtended[]>([]);
  const [workerScreenFilterTags, setWorkerScreenFilterTags] = useState<TagExtended[]>([]);
  const [skillScreenFilterTags, setSkillScreenFilterTags] = useState<TagExtended[]>([]);
  const [skillWorkerScreenFilterTags, setSkillWorkerScreenFilterTags] = useState<TagExtended[]>([]);
  const [trainingSessionScreenFilterTags, setTrainingSessionScreenFilterTags] = useState<
    TagExtended[]
  >([]);
  const [KPIScreenFilterTags, setKPIScreenFilterTags] = useState<TagExtended[]>([]);
  const [workerWorkstationScreenFilterTags, setWorkerWorkstationScreenFilterTags] = useState<
    TagExtended[]
  >([]);
  const [workerSkillScreenFilterTags, setWorkerSkillScreenFilterTags] = useState<TagExtended[]>([]);
  const [trainingScreenFilterTags, setTrainingScreenFilterTags] = useState<TagExtended[]>([]);
  const [trainingWorkerScreenFilterTags, setTrainingWorkerScreenFilterTags] = useState<
    TagExtended[]
  >([]);
  const [currentRoute, setCurrentRoute] = useState<string>();
  const [currentTabIndex, setCurrentTabIndex] = useState<number>();
  const [disableFilter, setDisableFilter] = useState<boolean>(false);
  const [hideFilter, setHideFilter] = useState<boolean>(false);

  return (
    <HeaderFilterContext.Provider
      value={{
        disableFilter: [disableFilter, setDisableFilter],
        hideFilter: [hideFilter, setHideFilter],
        homeScreenFilterTags: [homeScreenFilterTags, setHomeScreenFilterTags],
        workstationScreenFilterTags: [workstationScreenFilterTags, setWorkstationScreenFilterTags],
        trainingScreenFilterTags: [trainingScreenFilterTags, setTrainingScreenFilterTags],
        trainingWorkerScreenFilterTags: [
          trainingWorkerScreenFilterTags,
          setTrainingWorkerScreenFilterTags,
        ],
        workerScreenFilterTags: [workerScreenFilterTags, setWorkerScreenFilterTags],
        skillScreenFilterTags: [skillScreenFilterTags, setSkillScreenFilterTags],
        skillWorkerScreenFilterTags: [skillWorkerScreenFilterTags, setSkillWorkerScreenFilterTags],
        trainingSessionScreenFilterTags: [
          trainingSessionScreenFilterTags,
          setTrainingSessionScreenFilterTags,
        ],
        KPIScreenFilterTags: [KPIScreenFilterTags, setKPIScreenFilterTags],
        workerWorkstationScreenFilterTags: [
          workerWorkstationScreenFilterTags,
          setWorkerWorkstationScreenFilterTags,
        ],
        workerSkillScreenFilterTags: [workerSkillScreenFilterTags, setWorkerSkillScreenFilterTags],
        currentRoute: [currentRoute, setCurrentRoute],
        currentTabIndex: [currentTabIndex, setCurrentTabIndex],
      }}
    >
      {props.children}
    </HeaderFilterContext.Provider>
  );
};
