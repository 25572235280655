import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, InteractionManager, Modal, ScrollView } from 'react-native';
import * as API from 'shared/backend-data';
import { t } from 'shared/localisation/i18n';
import Styles from './Style';
import { AddTrainingProof, WorkerProofBundleDetails } from '../container';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import logger from 'shared/util/Logger';
import { IconSVG } from 'shared/ui-component/Icon';
import { ModalUtils } from 'shared/ui-component/Modal';
import { ModalHeaderText } from 'shared/ui-component/Modal/ModalHeaderText';
import { LeftPanel } from './LeftPanel';
import { LeftPanel as PreviewFiles } from '../../../worker-skill-review-modal/LeftPanel';
import { RightPanel } from './RightPanel';
import { ModalBackgroundStyle, ModalCardStyle, ModalHeaderStyle } from 'shared/styles/ModalStyles';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { AvatarHeader } from './AvatarHeader';
import { convertDataURLtoFile } from 'shared/util-ts/Functions';
import { InputList } from 'shared/ui-component/Input/InputList';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import { InputDateWeb } from 'sharedweb/src/InputDate';

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

interface Props {
  selectedSkill?: API.Skill;
  workerName?: string;
  trainingProof: AddTrainingProof;
  selectedWorkerTags: DropDownOption[];
  training?: DropDownOption;
  trainingSkill?: API.Skill;
  workerProofBundleDetails?: Map<string, WorkerProofBundleDetails>;
  skillObtentionDate: Date;
  comment?: string;
  attestAcquiredSkill: boolean;
  selectedDocs: File[];
  files?: API.S3ObjectWithLink[];
  reviewState?: API.ReviewState;
  trainingVersion?: API.TrainingVersion;
  selectedTrainerTags?: DropDownOption[];
  disableWorkerSelection?: boolean;
  proofBundleOptions: Tag[];
  proofBundleOption?: Tag;

  isSkillAcquired: number;
  handleModalClose: () => void;
  setTrainee: (traineeId: string) => void;
  handleSkillObtentionDate: (date: Date) => void;
  handleComment: (comment: string) => void;
  handleAssetAcquiredSkill: (value: boolean) => void;
  handleSelectedDocs: (files: File[]) => void;
  saveProofBundle: (proofBundle: API.ProofBundle[]) => void;
  reviewProofBundle: (reject: boolean) => void;
  setShowLoader: (value: boolean) => void;
  setModalVisible: (value: boolean) => void;
  setSelectedWorkerTags: (tags: DropDownOption[]) => void;
  setTrainings: (tag?: DropDownOption) => void;
  getTrainingSkills: (trainingVersionId: string) => void;
  handleSelectProof: (option: Tag[]) => void;
  navigateToTrainingBook(): void;
  setIsSkillAcquired: React.Dispatch<React.SetStateAction<number>>;
}

export const TrainingProofReviewModalComponent: React.FC<Props> = props => {
  const modal = ModalUtils.useModal();

  const {
    trainingProof,
    selectedWorkerTags,
    training,
    trainingSkill,
    workerProofBundleDetails,
    skillObtentionDate,
    comment,
    attestAcquiredSkill,
    selectedDocs,
    files,
    reviewState,
    workerName,
    trainingVersion,
    selectedTrainerTags,
    disableWorkerSelection,
    proofBundleOptions,
    proofBundleOption,
    isSkillAcquired,

    setIsSkillAcquired,
    navigateToTrainingBook,
    handleSelectProof,
    saveProofBundle,
    reviewProofBundle,
    setShowLoader,
    handleSelectedDocs,
    handleAssetAcquiredSkill,
    handleComment,
    handleSkillObtentionDate,
    setTrainee,
    setModalVisible,
    setSelectedWorkerTags,
    setTrainings,
    getTrainingSkills,
    handleModalClose,
  } = props;

  const [isSkillsOrWorkersCountGreaterThanOne, setIsSkillOrWorkersCountGreaterThanOne] =
    useState<boolean>(false);

  const [selectedSkill, setSelectedSkill] = useState<API.Skill | undefined>(props.selectedSkill);
  const [scannedDocs, setScannedDocs] = useState<(string | ArrayBuffer | null | undefined)[]>([]);
  const [validData, setValidData] = useState(false);
  const [proofAdded, setProofAdded] = useState(false);
  const [reviewer, setReviewer] = useState<API.Worker>();
  const [showFullScreenProof, setShowFullScreenProof] = useState(false);
  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      const worker = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(worker)) return;
      setReviewer(worker);
    });
  }, []);

  useEffect(() => {
    return () => {
      setModalVisible(true);
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setValidData(isDataValidToBeSubmitted());
      setProofAdded(Boolean(attestAcquiredSkill) || selectedDocs.length !== 0);
    }
  }, [
    selectedDocs,
    workerName,
    selectedSkill,
    attestAcquiredSkill,
    skillObtentionDate,
    training,
    isSkillAcquired,
  ]);

  const isDataValidToBeSubmitted = (): boolean => {
    if (trainingProof) {
      return Boolean(
        training &&
          skillObtentionDate &&
          ((selectedDocs && selectedDocs.length) || attestAcquiredSkill) &&
          isSkillAcquired !== -1,
      );
    }
    return Boolean(
      workerName &&
        selectedSkill &&
        skillObtentionDate &&
        ((selectedDocs && selectedDocs.length) || attestAcquiredSkill) &&
        isSkillAcquired !== -1,
    );
  };

  const handleCreateAutoCertificate = async (
    reviewer: API.Worker,
    workerName: string,
    training: API.Training,
  ): Promise<File | undefined> => {
    selectedDocs.pop();
    const skills = await API.getSkillsFromTraining(training.id);
    if (!isMounted.current) return;
    if (API.isFailure(skills)) {
      logger.warn(skills);
      return;
    }
    const proofFile = await API.createCertificateProof(
      [workerName],
      [training.name],
      reviewer.name,
      true,
      false,
      undefined,
      undefined,
      skills.map(skill => skill.name),
    );
    if (!isMounted.current) return;
    const file = convertDataURLtoFile(proofFile.fileContentBase64, proofFile.name);
    selectedDocs.push(file);
    handleSelectedDocs(selectedDocs);

    return file;
  };

  const handleDisplayPopUpToast = (message: string): void => {
    modal.displayModal(
      ModalUtils.toastConfig({
        text: message,
      }),
    );
  };

  const showApiError = (errorMessage: string): void => {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('common:error.unknown'),
        warningSubMessage: errorMessage,
        warningAcceptButton: t('common:button.retry'),
        warningAcceptCallback: handleSubmitButton,
        warningCancelButton: t('common:button.no'),
        warningCancelCallback: handleModalClose,
      }),
    );
  };

  async function uploadToS3(proofs: File[]): Promise<API.S3Object[]> {
    const data = await _.reduce(
      proofs,
      async (acc, file) => {
        const s3Object = await API.uploadFile(file, API.StorageVisibility.protected);
        if (!isMounted.current) return [];
        if (API.isFailure(s3Object)) {
          logger.warn('Failed to upload file', s3Object);
          showApiError('' + s3Object.data);
        } else {
          (await acc).push(s3Object);
        }
        return acc;
      },
      Promise.resolve([] as API.S3Object[]),
    );
    return data;
  }

  const handleSubmitButton = async () => {
    if (!validData) return;
    setShowLoader(true);

    let worker = reviewer;
    if (!worker) {
      const _worker = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(_worker)) {
        logger.warn(_worker);
        setShowLoader(false);
        return;
      }
      worker = _worker;
    }
    let s3Objects: API.S3Object[] = [];

    if (selectedDocs.length) {
      const _s3Objects = await uploadToS3(selectedDocs);
      if (!isMounted.current) return;

      s3Objects.push(..._s3Objects);
    }

    if (
      training &&
      trainingProof &&
      trainingProof.trainingSessionOrTrainingVersionId &&
      API.getDataType(trainingProof.trainingSessionOrTrainingVersionId) ===
        API.DataType.TRAININGSESSION
    ) {
      for (const selectedWorker of selectedWorkerTags) {
        if (attestAcquiredSkill && worker) {
          const file = await handleCreateAutoCertificate(
            worker,
            selectedWorker.label,
            training.value,
          );
          if (!isMounted.current || !file) return;

          const s3Object = await API.uploadFile(file, API.StorageVisibility.protected);
          if (!isMounted.current) return;
          if (API.isFailure(s3Object)) {
            logger.warn('Failed to upload file', s3Object);
            showApiError('' + s3Object.data);
            setShowLoader(false);
            return;
          }
          s3Objects = [s3Object];
        }

        const proofBundle = await API.createTrainingProofBundle(
          {
            startingDate: skillObtentionDate.toISOString(),
            files: s3Objects,
            review: {
              state: API.ReviewState.TO_REVIEW,
              date: new Date().toISOString(),
              workerId: worker.id,
            },
            description: comment,
            acquired: Boolean(!isSkillAcquired),
          },
          trainingProof.trainingSessionOrTrainingVersionId,
          selectedWorker.key,
          undefined,
          true,
        );
        if (!isMounted.current) return;
        if (API.isFailure(proofBundle)) {
          logger.warn('Failed to create createdProofBundle', proofBundle);
          if (API.isFailureType(proofBundle, 'ProofBundleNotReviewed')) {
            showApiError(t('alex:mobile.addProofPage.proofAddedAlreadyWarning.3'));
            setShowLoader(false);
            return;
          }
          showApiError('' + proofBundle.data);
          return;
        }

        saveProofBundle(proofBundle);
      }
      setShowLoader(false);
    } else if (!trainingProof.trainingSessionOrTrainingVersionId && trainingVersion) {
      for (const selectedWorkerTag of selectedWorkerTags) {
        const training = await API.getTraining(trainingVersion.trainingId);
        if (!isMounted.current) return;
        if (API.isFailure(training)) {
          logger.warn(training);
          return;
        }
        if (attestAcquiredSkill && worker) {
          const file = await handleCreateAutoCertificate(worker, selectedWorkerTag.label, training);
          if (!isMounted.current || !file) return;

          const s3Object = await API.uploadFile(file, API.StorageVisibility.protected);
          if (!isMounted.current) return;
          if (API.isFailure(s3Object)) {
            logger.warn('Failed to upload file', s3Object);
            showApiError('' + s3Object.data);
            setShowLoader(false);
            return;
          }
          s3Objects = [s3Object];
        }

        const proofBundle = await API.createTrainingProofBundle(
          {
            startingDate: skillObtentionDate.toISOString(),
            files: s3Objects,
            review: {
              state: API.ReviewState.TO_REVIEW,
              date: new Date().toISOString(),
              workerId: worker.id,
            },
            description: comment,
            acquired: Boolean(!isSkillAcquired),
          },
          trainingVersion.id,
          selectedWorkerTag.key,
        );

        if (API.isFailure(proofBundle)) {
          if (API.isFailureType(proofBundle, 'DuplicateVeto')) {
            ModalUtils.showVetoModal(
              t('alex:vetoModal.trainingAlreadyExists'),
              proofBundle,
              undefined,
            );
            setShowLoader(false);
            return;
          }
          logger.warn('Failed to create createdProofBundle', proofBundle);
          showApiError('' + proofBundle.message);
        }
      }

      setShowLoader(false);
      handleModalClose();
    }
  };

  const title = trainingProof.traineeIdToValidate
    ? `${t('alex:workerSkillReviewModal.toBeValidated', undefined, false)}`
    : `${t('alex:worker.addSkillModal.title.1', undefined, false)}`;

  function listenDropdownChange(count?: number) {
    if (!count) {
      setIsSkillOrWorkersCountGreaterThanOne(false);
      return;
    }

    if (count > 1) setIsSkillOrWorkersCountGreaterThanOne(true);
    else setIsSkillOrWorkersCountGreaterThanOne(false);
  }

  return (
    <Modal animationType="fade" transparent>
      <View style={ModalBackgroundStyle}>
        <View style={ModalCardStyle}>
          <ScrollView contentContainerStyle={{ height: '100%' }}>
            <View
              style={[
                ModalHeaderStyle,
                !trainingProof.traineeIdToValidate && {
                  paddingBottom: 0,
                  paddingTop: 0,
                },
                { justifyContent: 'center' },
              ]}
            >
              <TouchableOpacity style={Styles.closeButtonIcon} onPress={handleModalClose}>
                <IconSVG svgComponent={closeIcon} />
              </TouchableOpacity>
              {proofBundleOptions.length > 1 && (
                <View style={Styles.inputListContainer}>
                  {showDropDownMenu && (
                    <InputList
                      placeholder={t('common:filters.filterData')}
                      options={proofBundleOptions}
                      containerStyle={Styles.inputListContainerStyle}
                      handleSelection={(inputId, option) => handleSelectProof(option)}
                      openInputList={setShowDropDownMenu}
                      singleSelection
                      initialSelection={proofBundleOption && [proofBundleOption]}
                    />
                  )}
                  <TouchableOpacity
                    disabled={!proofBundleOptions.length}
                    onPress={() => setShowDropDownMenu(!showDropDownMenu)}
                  >
                    <InputDateWeb
                      hasDateIcon
                      inputPlaceholder={t('alex:workerSkillReviewModal.history')}
                      inputName={t('alex:workerSkillReviewModal.history')}
                      onChange={() => null}
                      value={
                        proofBundleOption?.value.review.state === API.ReviewState.TO_REVIEW
                          ? new Date(proofBundleOption?.value.startingDate)
                          : proofBundleOption?.value.review.state === API.ReviewState.VALIDATED ||
                            proofBundleOption?.value.review.state === API.ReviewState.REJECTED ||
                            proofBundleOption?.value.review.state ===
                              API.ReviewState.REJECTED_TO_RESUBMIT
                          ? new Date(proofBundleOption?.value.review.date)
                          : undefined
                      }
                      disableCalendar
                    />
                  </TouchableOpacity>
                </View>
              )}
              {workerProofBundleDetails && !trainingProof.traineeIdToValidate ? (
                <AvatarHeader
                  workerProofBundleDetails={workerProofBundleDetails}
                  handleIconClick={setTrainee}
                  selectedTraineeId={selectedWorkerTags[0] && selectedWorkerTags[0].key}
                  reviewState={reviewState}
                />
              ) : (
                <ModalHeaderText title={t('glossary:proof') + ' ' + title} invert />
              )}
            </View>
            <View style={Styles.rightAndLeftPanel}>
              {files && files.length ? (
                <PreviewFiles files={files} />
              ) : (
                <LeftPanel
                  scannedDocs={scannedDocs}
                  selectedDocs={selectedDocs}
                  attestAcquiredSkill={attestAcquiredSkill}
                  validData={validData}
                  reviewer={reviewer}
                  showFullScreenProof={showFullScreenProof}
                  skillObtentionDate={skillObtentionDate}
                  setAttestAcquiredSkill={handleAssetAcquiredSkill}
                  setScannedDocs={setScannedDocs}
                  setSelectedDocs={handleSelectedDocs}
                  handleDisplayPopUpToast={handleDisplayPopUpToast}
                  setShowFullScreenProof={setShowFullScreenProof}
                  isSkillsOrWorkersCountGreaterThanOne={isSkillsOrWorkersCountGreaterThanOne}
                />
              )}

              <RightPanel
                validData={validData}
                proofAdded={proofAdded}
                comment={comment}
                selectedSkill={selectedSkill}
                workerName={workerName}
                preSelectedSkill={props.selectedSkill}
                skillObtentionDate={skillObtentionDate}
                selectedWorkerTags={selectedWorkerTags}
                training={training}
                trainingSkill={trainingSkill}
                reviewState={reviewState}
                selectedTrainerTags={selectedTrainerTags}
                reviewProofBundle={reviewProofBundle}
                setComment={handleComment}
                setSkillObtentionDate={handleSkillObtentionDate}
                handleSubmitButton={handleSubmitButton}
                setSelectedSkill={setSelectedSkill}
                setSelectedWorkerTags={setSelectedWorkerTags}
                setTraining={setTrainings}
                getTrainingSkills={getTrainingSkills}
                disableWorkerSelection={disableWorkerSelection}
                isSkillsOrWorkersCountGreaterThanOne={isSkillsOrWorkersCountGreaterThanOne}
                listenDropdownChange={listenDropdownChange}
                navigateToTrainingBook={navigateToTrainingBook}
                setIsSkillAcquired={setIsSkillAcquired}
                isSkillAcquired={isSkillAcquired}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};
