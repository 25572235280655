import React, { useEffect, useContext, useState } from 'react';
import { View, Text, InteractionManager } from 'react-native';
import { t } from 'shared/localisation/i18n';
import Styles from './Styles';
import {
  WorkstationWithOverAndUnderWorkersCount,
  getWorkstationsWithOverAndUnderWorkersCount,
} from 'shared/util/Workstation';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { KPIData } from 'sharedweb/src/context/TreeTableContext';
import { ActivityIndicator } from 'shared/ui-component/Loader/ActivityIndicator';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { wait } from 'shared/util-ts/Functions';
import { YellowButton } from 'shared/ui-component/Button';

interface Props {
  kpiData: KPIData;
  isGraphLoaded: boolean;

  setIsUnderStaffedAndOverStafftedWorkstationsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UnderAndOverStaffedWorkstations: React.FC<Props> = props => {
  const { kpiData, isGraphLoaded, setIsUnderStaffedAndOverStafftedWorkstationsLoaded } = props;
  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);

  const [topUnderStaffedWorkstations, setTopUnderStaffedWorkstations] = useState<
    WorkstationWithOverAndUnderWorkersCount[]
  >([]);
  const [topOverStaffedWorkstations, setTopOverStaffedWorkstations] = useState<
    WorkstationWithOverAndUnderWorkersCount[]
  >([]);

  const [showTopUnderStaffedWorkstations, setShowTopUnderStaffedWorkstations] =
    useState<boolean>(false);

  const [loader, setLoader] = useState<boolean>(true);

  const numberOfTopWorkstationsNeeded = 5;

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      await wait(1000);
      if (!isMounted.current) return;

      if (isGraphLoaded && kpiData.workstations && showTopUnderStaffedWorkstations) {
        setLoader(true);

        await wait(1000);

        await setUnderAndOverStaffedWorkstations(kpiData.workstations);

        setIsUnderStaffedAndOverStafftedWorkstationsLoaded(true);

        setLoader(false);
      }

      if (isGraphLoaded && !showTopUnderStaffedWorkstations) {
        setLoader(false);
      }
    });
  }, [kpiData, isGraphLoaded, showTopUnderStaffedWorkstations]);

  async function setUnderAndOverStaffedWorkstations(workstations: API.Workstation[]) {
    const workstationsWithOverAndUnderWorkersCount =
      await getWorkstationsWithOverAndUnderWorkersCount(
        workstations,
        workstationTargetsStartingAtLevel,
        true,
      );
    if (!isMounted.current) return;
    if (API.isFailure(workstationsWithOverAndUnderWorkersCount)) {
      logger.warn(workstationsWithOverAndUnderWorkersCount);
      setLoader(false);
      return;
    }

    if (!workstationsWithOverAndUnderWorkersCount) return;
    workstationsWithOverAndUnderWorkersCount.sort(compareBasedOnOverAndUnderWorkersCount);

    const indexOfPositiveItems = workstationsWithOverAndUnderWorkersCount.findIndex(
      element => element.overAndUnderWorkersCount > 0,
    );

    let underStaffedWorkstations: WorkstationWithOverAndUnderWorkersCount[] = [];
    let overStaffedWorkstations: WorkstationWithOverAndUnderWorkersCount[] = [];

    if (indexOfPositiveItems > -1) {
      underStaffedWorkstations = workstationsWithOverAndUnderWorkersCount.slice(
        0,
        indexOfPositiveItems,
      );

      overStaffedWorkstations =
        workstationsWithOverAndUnderWorkersCount.slice(indexOfPositiveItems);
    } else {
      underStaffedWorkstations = workstationsWithOverAndUnderWorkersCount;
    }

    setTopUnderStaffedWorkstations(
      underStaffedWorkstations.slice(0, numberOfTopWorkstationsNeeded),
    );
    setTopOverStaffedWorkstations(
      overStaffedWorkstations.slice(-numberOfTopWorkstationsNeeded).reverse(),
    );
    setLoader(false);
  }

  function compareBasedOnOverAndUnderWorkersCount(
    a: WorkstationWithOverAndUnderWorkersCount,
    b: WorkstationWithOverAndUnderWorkersCount,
  ) {
    if (a.overAndUnderWorkersCount < b.overAndUnderWorkersCount) {
      return -1;
    }
    if (a.overAndUnderWorkersCount > b.overAndUnderWorkersCount) {
      return 1;
    }
    return 0;
  }

  function loadFullData() {
    setShowTopUnderStaffedWorkstations(true);
  }

  return (
    <View style={[Styles.workstationWidgetContainer]}>
      <View
        style={[
          Styles.widget,
          Styles.workstationWidget,
          Styles.underStaffedWidgetContainer,
          !showTopUnderStaffedWorkstations && Styles.alignCenter,
        ]}
      >
        <Text style={Styles.widgetTitle}>
          {t('alex:KPI.KPIDashboard.topUnderStaffedWorkstations', {
            count: numberOfTopWorkstationsNeeded,
          })}
        </Text>

        {loader ? (
          <ActivityIndicator />
        ) : !showTopUnderStaffedWorkstations ? (
          <YellowButton
            text={t('alex:KPI.KPIGraph.showFullData')}
            style={Styles.bottomButtonStyle}
            textStyle={Styles.bottomButtonTextStyle}
            onPress={loadFullData}
          />
        ) : topUnderStaffedWorkstations.length ? (
          topUnderStaffedWorkstations.map((underStaffedWorkstation, index) => (
            <View
              key={underStaffedWorkstation.workstation.id}
              style={[Styles.widgetContentRow, { zIndex: -index }]}
            >
              <EllipsisWithTooltip
                text={index + 1 + '.  ' + underStaffedWorkstation.workstation.name}
                textStyle={Styles.widgetContentText}
              />
              <Text style={[Styles.widgetContentTextCount, Styles.underStaffedCount]}>
                {underStaffedWorkstation.overAndUnderWorkersCount}
              </Text>
            </View>
          ))
        ) : (
          <Text style={Styles.noText}>
            {t('alex:KPI.KPIDashboard.noTopUnderStaffedWorkstations')}
          </Text>
        )}
      </View>
      <View
        style={[
          Styles.widget,
          Styles.workstationWidget,
          !showTopUnderStaffedWorkstations && Styles.alignCenter,
        ]}
      >
        <Text style={Styles.widgetTitle}>
          {t('alex:KPI.KPIDashboard.topOverStaffedWorkstations', {
            count: numberOfTopWorkstationsNeeded,
          })}
        </Text>
        {loader ? (
          <ActivityIndicator />
        ) : !showTopUnderStaffedWorkstations ? (
          <YellowButton
            text={t('alex:KPI.KPIGraph.showFullData')}
            style={Styles.bottomButtonStyle}
            textStyle={Styles.bottomButtonTextStyle}
            onPress={loadFullData}
          />
        ) : topOverStaffedWorkstations.length ? (
          topOverStaffedWorkstations.map((overStaffedWorkstation, index) => (
            <View
              key={overStaffedWorkstation.workstation.id}
              style={[Styles.widgetContentRow, { zIndex: -index }]}
            >
              <EllipsisWithTooltip
                textStyle={Styles.widgetContentText}
                text={index + 1 + '.  ' + overStaffedWorkstation.workstation.name}
              />
              <Text style={[Styles.widgetContentTextCount, Styles.overStaffedCount]}>
                {'+' + overStaffedWorkstation.overAndUnderWorkersCount}
              </Text>
            </View>
          ))
        ) : (
          <Text style={Styles.noText}>
            {t('alex:KPI.KPIDashboard.noTopOverStaffedWorkstations')}
          </Text>
        )}
      </View>
    </View>
  );
};
