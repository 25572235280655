import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  addIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
  },
  unitContainer: {
    paddingTop: 40,
    zIndex: -1,
  },
  addUnit: {
    color: Colors.Black,
    fontSize: fontSize.XLarge,
    ...TextFontStyles.LatoRegular,
    paddingRight: Spacings.Small + Spacings.Unit,
  },
  unitDropdownContainer: {
    flexDirection: 'column',
  },
  eachUnitContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  addUnitDiscription: {
    paddingTop: 16,
    color: Colors.Grey,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: Spacings.CardPadding,
  },
  addWorkerAndUnitText: {
    color: Colors.Grey,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginTop: Spacings.CardPadding,
  },
});

export default styles;
