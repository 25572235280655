import { StyleSheet } from 'react-native';
import { Spacings } from 'shared/styles';

const Style = StyleSheet.create({
  container: {
    width: Spacings.Big * 7,
    height: Spacings.Big * 7,
  },
});

export default Style;
