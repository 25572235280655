/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type InviteUserInput = {
  pk: string,
  workerId: string,
  operation: UserInvitationOperations,
  message?: string | null,
};

export enum UserInvitationOperations {
  GIVE_ACCESS = "GIVE_ACCESS",
  REMOVE_ACCESS = "REMOVE_ACCESS",
}


export type CreateFactoryInput = {
  pk: string,
  sk: string,
  dataType: DataType,
  data?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  tagSearch?: string | null,
  tenant?: TenantInput | null,
  tenantID?: TenantIDInput | null,
  app?: AppInput | null,
  role?: RoleInput | null,
  contractType?: ContractTypeInput | null,
  organizationalUnit?: OrganizationalUnitInput | null,
  workstation?: WorkstationInput | null,
  shift?: ShiftInput | null,
  workerWorkstation?: WorkerWorkstationInput | null,
  worker?: WorkerInput | null,
  workerTag?: WorkerTagInput | null,
  skill?: SkillInput | null,
  skillTag?: SkillTagInput | null,
  training?: TrainingInput | null,
  trainingTag?: TrainingTagInput | null,
  trainingVersion?: TrainingVersionInput | null,
  trainingSession?: TrainingSessionInput | null,
  proofBundle?: ProofBundleInput | null,
  requirement?: RequirementInput | null,
  workerSkill?: WorkerSkillInput | null,
  workerTrainingSession?: WorkerTrainingSessionInput | null,
  userPreference?: UserPreferenceInput | null,
};

export enum DataType {
  TENANT = "TENANT",
  TENANTID = "TENANTID",
  APP = "APP",
  TENANT_APP = "TENANT_APP",
  WORKER_TENANT_APP = "WORKER_TENANT_APP",
  WORKER = "WORKER",
  WORKERTAG = "WORKERTAG",
  ROLE = "ROLE",
  USERPREFERENCE = "USERPREFERENCE",
  CONTRACTTYPE = "CONTRACTTYPE",
  SKILL = "SKILL",
  SKILLTAG = "SKILLTAG",
  WORKERSKILL = "WORKERSKILL",
  ORGUNIT = "ORGUNIT",
  SHIFT = "SHIFT",
  WORKSTATION = "WORKSTATION",
  WORKERWORKSTATION = "WORKERWORKSTATION",
  REQUIREMENT = "REQUIREMENT",
  TRAINING_TRAININGVERSION = "TRAINING_TRAININGVERSION",
  TRAINING = "TRAINING",
  TRAININGTAG = "TRAININGTAG",
  TRAININGVERSION = "TRAININGVERSION",
  TRAININGSESSION = "TRAININGSESSION",
  WORKERTRAININGSESSION = "WORKERTRAININGSESSION",
  PROOFBUNDLE = "PROOFBUNDLE",
}


export type TenantInput = {
  id?: string | null,
  name: string,
  domain: string,
  authenticationTypes: Array< AuthenticationType >,
  logo?: string | null,
  glossary?: string | null,
  workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
  workerFamilyNameFirst?: boolean | null,
  isDisableLostLevelAlert?: boolean | null,
  apps: Array< AppConfigInput >,
  levelIconType?: LevelIconType | null,
};

export enum AuthenticationType {
  GOOGLE = "GOOGLE",
  FACEBOOK = "FACEBOOK",
  MICROSOFT = "MICROSOFT",
  LINKEDIN = "LINKEDIN",
  MOBILE = "MOBILE",
  EMAIL = "EMAIL",
  USERID = "USERID",
}


export enum WorkstationWorkerLevel {
  LEVEL0 = "LEVEL0",
  LEVEL1 = "LEVEL1",
  LEVEL2 = "LEVEL2",
  LEVEL3 = "LEVEL3",
  LEVEL4 = "LEVEL4",
}


export type AppConfigInput = {
  id: AppId,
  features: Array< FeatureInput >,
};

export enum AppId {
  SKILLOP = "SKILLOP",
}


export type FeatureInput = {
  limit?: number | null,
  feature: AppFeature,
};

export enum AppFeature {
  SKILLOP_SKILL = "SKILLOP_SKILL",
  SKILLOP_TRAINING = "SKILLOP_TRAINING",
}


export enum LevelIconType {
  LINE = "LINE",
  PIE = "PIE",
  SQUARE = "SQUARE",
}


export type TenantIDInput = {
  id?: string | null,
};

export type AppInput = {
  id: string,
  name: string,
  logo?: string | null,
  version: string,
  playStoreVersion?: string | null,
  appleStoreVersion?: string | null,
};

export type RoleInput = {
  id?: string | null,
  name: string,
  permissions: Array< Permission >,
};

export enum Permission {
  workstations_edit = "workstations_edit",
  skills_edit = "skills_edit",
  trainings_edit = "trainings_edit",
  trainingSessions_edit = "trainingSessions_edit",
  workerIsOperational = "workerIsOperational",
  workers_edit = "workers_edit",
  workersDetail_view = "workersDetail_view",
  workersDetail_edit = "workersDetail_edit",
  workersSkillsProof_edit = "workersSkillsProof_edit",
  workersSkillProof_review = "workersSkillProof_review",
  workers_invite = "workers_invite",
  workerIsManager = "workerIsManager",
}


export type ContractTypeInput = {
  id?: string | null,
  name: string,
  openEndDate?: boolean | null,
  isPauseContract?: boolean | null,
};

export type OrganizationalUnitInput = {
  id?: string | null,
  parentId: string,
  name: string,
  pathIds?: Array< string > | null,
  order?: number | null,
  shiftIds?: Array< string > | null,
  description?: string | null,
  thirdPartyIds?: Array< string | null > | null,
};

export type WorkstationInput = {
  id?: string | null,
  name: string,
  parentId: string,
  pathIds?: Array< string > | null,
  description?: string | null,
  order?: number | null,
  shiftIds?: Array< string > | null,
  workersTargetOnShift?: string | null,
  files: Array< S3ObjectInput >,
};

export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
  fileName: string,
  visibility: StorageVisibility,
  owner?: string | null,
};

export enum StorageVisibility {
  public = "public",
  private = "private",
  protected = "protected",
}


export type ShiftInput = {
  id?: string | null,
  parentId: string,
  name: string,
  color: string,
};

export type WorkerWorkstationInput = {
  id?: string | null,
  workstationId: string,
  workerId: string,
  isTrainAuto: boolean,
  warning?: WorkstationWorkerLevelTargetWarning | null,
  targetLevel?: WorkstationWorkerLevel | null,
  previousLevel: WorkstationWorkerLevel,
  activeTrainingSessions: ActiveTrainingSessionsInput,
  level?: WorkstationWorkerLevel | null,
  numberOfRequiredSkills?: number | null,
  numberOfOkWorkerSkills?: number | null,
  validSkills?: Array< WorkerSkillInfoInput > | null,
  validExpireSoonSkills?: Array< WorkerSkillInfoInput > | null,
  invalidExpiredSkills?: Array< WorkerSkillInfoInput > | null,
  invalidNoRefreshSkills?: Array< WorkerSkillInfoInput > | null,
  invalidMissingSkills?: Array< MissingWorkerSkillInfoInput > | null,
};

export enum WorkstationWorkerLevelTargetWarning {
  SILENT = "SILENT",
  AUTO_SILENT = "AUTO_SILENT",
  EXPIRE_SOON = "EXPIRE_SOON",
  EXPIRED = "EXPIRED",
}


export type ActiveTrainingSessionsInput = {
  workstationActiveTrainingSessions: WorkstationActiveTrainingSessionInput,
  nonWorkstationActiveTrainingSessions: WorkstationActiveTrainingSessionInput,
  isEverySkillToRenewOrAcquireCovered?: boolean | null,
};

export type WorkstationActiveTrainingSessionInput = {
  lowerOrEqualToTarget: ActiveTrainingSessionsByRequirementInput,
  greaterThanTarget: ActiveTrainingSessionsByRequirementInput,
};

export type ActiveTrainingSessionsByRequirementInput = {
  fromNonInheritedRequirements: Array< string >,
  fromInheritedRequirements: Array< string >,
};

export type WorkerSkillInfoInput = {
  workerSkillId: string,
  requirmentIds: Array< string >,
};

export type MissingWorkerSkillInfoInput = {
  skillId: string,
  requirmentIds: Array< string >,
};

export type WorkerInput = {
  id?: string | null,
  userId?: string | null,
  email?: string | null,
  phone?: string | null,
  profilePicture?: string | null,
  matricule?: string | null,
  name: string,
  firstName: string,
  familyName: string,
  tagIds: Array< string >,
  contracts: Array< ContractInput >,
  state?: WorkerState | null,
  description?: string | null,
  isAdmin?: boolean | null,
  scope?: string | null,
  lastLogin?: string | null,
};

export type ContractInput = {
  contractTypeId: string,
  startDate?: string | null,
  endDate?: string | null,
  comment?: string | null,
};

export enum WorkerState {
  ACTIVE = "ACTIVE",
  ABSENT = "ABSENT",
  ARCHIVED = "ARCHIVED",
}


export type WorkerTagInput = {
  id?: string | null,
  name: string,
};

export type SkillInput = {
  id?: string | null,
  name: string,
  isPractical: boolean,
  description?: string | null,
  files: Array< S3ObjectInput >,
  skillIds?: Array< string > | null,
  tagIds: Array< string >,
  validityDuration?: number | null,
  expiryNoticeDuration?: number | null,
};

export type SkillTagInput = {
  id?: string | null,
  name: string,
};

export type TrainingInput = {
  id?: string | null,
  name: string,
  files: Array< S3ObjectInput >,
  description?: string | null,
  notes?: string | null,
  tagIds: Array< string >,
  defaultTrainerId?: string | null,
  maxTrainee?: number | null,
};

export type TrainingTagInput = {
  id?: string | null,
  name: string,
};

export type TrainingVersionInput = {
  id?: string | null,
  version?: number | null,
  trainingId: string,
  skillIds: Array< string >,
  durationInMin: number,
};

export type TrainingSessionInput = {
  id?: string | null,
  trainingVersionId: string,
  requirementId?: string | null,
  originId?: string | null,
  requestState: ReviewState,
  isDraft?: boolean | null,
  description?: string | null,
  location?: string | null,
  scheduledTrainers: Array< TrainerInput >,
  scheduledTraineeIds: Array< string >,
  scheduledStartDate?: string | null,
  durationInMin?: number | null,
  trainers: Array< TrainerInput >,
  trainerComment?: string | null,
  trainerFiles?: Array< S3ObjectInput > | null,
  traineeIds: Array< string >,
  startDate?: string | null,
  endDate?: string | null,
  endDateLimit?: string | null,
  calendarEvent?: CalendarEventInput | null,
};

export enum ReviewState {
  DRAFT = "DRAFT",
  TO_REVIEW = "TO_REVIEW",
  REJECTED = "REJECTED",
  REJECTED_TO_RESUBMIT = "REJECTED_TO_RESUBMIT",
  VALIDATED = "VALIDATED",
}


export type TrainerInput = {
  trainerId: string,
  percentage: string,
};

export type CalendarEventInput = {
  id: string,
  sequence: number,
};

export type ProofBundleInput = {
  id?: string | null,
  files: Array< S3ObjectInput >,
  startingDate: string,
  review: ReviewInput,
  description?: string | null,
  acquired?: boolean | null,
  originObjectId: string,
  workerId: string,
  skillId: string,
};

export type ReviewInput = {
  state: ReviewState,
  date?: string | null,
  workerId?: string | null,
};

export type RequirementInput = {
  id?: string | null,
  linkedObjectId: string,
  level: WorkstationWorkerLevel,
  skillTrainingVersions: Array< SkillTrainingVersionInput >,
  practicalTrainingId?: string | null,
};

export type SkillTrainingVersionInput = {
  skillId: string,
  trainingVersionId?: string | null,
};

export type WorkerSkillInput = {
  id?: string | null,
  skillId: string,
  workerId: string,
  proofBundleIds: Array< string >,
  activeProofBundle?: ProofBundleInput | null,
  toReviewProofBundle?: ProofBundleInput | null,
  validity?: Validity | null,
};

export enum Validity {
  KO_MISSING = "KO_MISSING",
  KO_NEW = "KO_NEW",
  KO_REJECTED = "KO_REJECTED",
  OK = "OK",
  OK_EXPIRE_SOON = "OK_EXPIRE_SOON",
  KO_EXPIRED = "KO_EXPIRED",
}


export type WorkerTrainingSessionInput = {
  id?: string | null,
  trainingSessionId: string,
  workerId: string,
};

export type UserPreferenceInput = {
  id?: string | null,
  userId: string,
  data: string,
};

export type ModelFactoryConditionInput = {
  dataType?: ModelDataTypeInput | null,
  data?: ModelStringInput | null,
  userGroup?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  _lastChangedAt?: ModelFloatInput | null,
  updatedBy?: ModelStringInput | null,
  tagSearch?: ModelStringInput | null,
  and?: Array< ModelFactoryConditionInput | null > | null,
  or?: Array< ModelFactoryConditionInput | null > | null,
  not?: ModelFactoryConditionInput | null,
};

export type ModelDataTypeInput = {
  eq?: DataType | null,
  ne?: DataType | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Factory = {
  __typename: "Factory",
  pk: string,
  sk: string,
  dataType: DataType,
  data?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  _deleted?: boolean | null,
  _version: number,
  _lastChangedAt: number,
  tagSearch?: string | null,
  tenant?: Tenant | null,
  tenantID?: TenantID | null,
  app?: App | null,
  role?: Role | null,
  contractType?: ContractType | null,
  organizationalUnit?: OrganizationalUnit | null,
  workstation?: Workstation | null,
  shift?: Shift | null,
  workerWorkstation?: WorkerWorkstation | null,
  worker?: Worker | null,
  workerTag?: WorkerTag | null,
  skill?: Skill | null,
  skillTag?: SkillTag | null,
  training?: Training | null,
  trainingTag?: TrainingTag | null,
  trainingVersion?: TrainingVersion | null,
  trainingSession?: TrainingSession | null,
  proofBundle?: ProofBundle | null,
  requirement?: Requirement | null,
  workerSkill?: WorkerSkill | null,
  workerTrainingSession?: WorkerTrainingSession | null,
  userPreference?: UserPreference | null,
};

export type Tenant = {
  __typename: "Tenant",
  id?: string | null,
  name: string,
  domain: string,
  authenticationTypes: Array< AuthenticationType >,
  logo?: string | null,
  glossary?: string | null,
  workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
  workerFamilyNameFirst?: boolean | null,
  isDisableLostLevelAlert?: boolean | null,
  apps:  Array<AppConfig >,
  levelIconType?: LevelIconType | null,
};

export type AppConfig = {
  __typename: "AppConfig",
  id: AppId,
  features:  Array<Feature >,
};

export type Feature = {
  __typename: "Feature",
  limit?: number | null,
  feature: AppFeature,
};

export type TenantID = {
  __typename: "TenantID",
  id?: string | null,
};

export type App = {
  __typename: "App",
  id: string,
  name: string,
  logo?: string | null,
  version: string,
  playStoreVersion?: string | null,
  appleStoreVersion?: string | null,
};

export type Role = {
  __typename: "Role",
  id?: string | null,
  name: string,
  permissions: Array< Permission >,
};

export type ContractType = {
  __typename: "ContractType",
  id?: string | null,
  name: string,
  openEndDate?: boolean | null,
  isPauseContract?: boolean | null,
};

export type OrganizationalUnit = {
  __typename: "OrganizationalUnit",
  id?: string | null,
  parentId: string,
  name: string,
  pathIds?: Array< string > | null,
  order: number,
  shiftIds?: Array< string > | null,
  description?: string | null,
  thirdPartyIds?: Array< string | null > | null,
};

export type Workstation = {
  __typename: "Workstation",
  id?: string | null,
  name: string,
  parentId: string,
  pathIds?: Array< string > | null,
  description?: string | null,
  order: number,
  files:  Array<S3Object >,
  shiftIds?: Array< string > | null,
  workersTargetOnShift?: string | null,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
  fileName: string,
  visibility: StorageVisibility,
  owner?: string | null,
};

export type Shift = {
  __typename: "Shift",
  id?: string | null,
  parentId: string,
  name: string,
  color: string,
};

export type WorkerWorkstation = {
  __typename: "WorkerWorkstation",
  id?: string | null,
  workstationId: string,
  workerId: string,
  isTrainAuto: boolean,
  warning?: WorkstationWorkerLevelTargetWarning | null,
  targetLevel?: WorkstationWorkerLevel | null,
  previousLevel: WorkstationWorkerLevel,
  activeTrainingSessions: ActiveTrainingSessions,
  level?: WorkstationWorkerLevel | null,
  numberOfRequiredSkills?: number | null,
  numberOfOkWorkerSkills?: number | null,
  validSkills?:  Array<WorkerSkillInfo > | null,
  validExpireSoonSkills?:  Array<WorkerSkillInfo > | null,
  invalidExpiredSkills?:  Array<WorkerSkillInfo > | null,
  invalidNoRefreshSkills?:  Array<WorkerSkillInfo > | null,
  invalidMissingSkills?:  Array<MissingWorkerSkillInfo > | null,
};

export type ActiveTrainingSessions = {
  __typename: "ActiveTrainingSessions",
  workstationActiveTrainingSessions: WorkstationActiveTrainingSession,
  nonWorkstationActiveTrainingSessions: WorkstationActiveTrainingSession,
  isEverySkillToRenewOrAcquireCovered?: boolean | null,
};

export type WorkstationActiveTrainingSession = {
  __typename: "WorkstationActiveTrainingSession",
  lowerOrEqualToTarget: ActiveTrainingSessionsByRequirement,
  greaterThanTarget: ActiveTrainingSessionsByRequirement,
};

export type ActiveTrainingSessionsByRequirement = {
  __typename: "ActiveTrainingSessionsByRequirement",
  fromNonInheritedRequirements: Array< string >,
  fromInheritedRequirements: Array< string >,
};

export type WorkerSkillInfo = {
  __typename: "WorkerSkillInfo",
  workerSkillId: string,
  requirmentIds: Array< string >,
};

export type MissingWorkerSkillInfo = {
  __typename: "MissingWorkerSkillInfo",
  skillId: string,
  requirmentIds: Array< string >,
};

export type Worker = {
  __typename: "Worker",
  id?: string | null,
  userId?: string | null,
  email?: string | null,
  phone?: string | null,
  profilePicture?: string | null,
  matricule?: string | null,
  name: string,
  firstName: string,
  familyName: string,
  tagIds: Array< string >,
  contracts:  Array<Contract >,
  description?: string | null,
  state?: WorkerState | null,
  isAdmin?: boolean | null,
  scope?: string | null,
  lastLogin?: string | null,
};

export type Contract = {
  __typename: "Contract",
  contractTypeId: string,
  startDate?: string | null,
  endDate?: string | null,
  comment?: string | null,
};

export type WorkerTag = {
  __typename: "WorkerTag",
  id?: string | null,
  name: string,
};

export type Skill = {
  __typename: "Skill",
  id?: string | null,
  name: string,
  isPractical: boolean,
  description?: string | null,
  files:  Array<S3Object >,
  skillIds?: Array< string > | null,
  tagIds: Array< string >,
  validityDuration?: number | null,
  expiryNoticeDuration?: number | null,
};

export type SkillTag = {
  __typename: "SkillTag",
  id?: string | null,
  name: string,
};

export type Training = {
  __typename: "Training",
  id?: string | null,
  name: string,
  files:  Array<S3Object >,
  description?: string | null,
  notes?: string | null,
  tagIds: Array< string >,
  defaultTrainerId?: string | null,
  maxTrainee?: number | null,
};

export type TrainingTag = {
  __typename: "TrainingTag",
  id?: string | null,
  name: string,
};

export type TrainingVersion = {
  __typename: "TrainingVersion",
  id?: string | null,
  version?: number | null,
  trainingId: string,
  skillIds: Array< string >,
  durationInMin: number,
};

export type TrainingSession = {
  __typename: "TrainingSession",
  id?: string | null,
  trainingVersionId: string,
  requirementId?: string | null,
  originId?: string | null,
  requestState: ReviewState,
  isDraft?: boolean | null,
  description?: string | null,
  location?: string | null,
  scheduledTrainers:  Array<Trainer >,
  scheduledTraineeIds: Array< string >,
  scheduledStartDate?: string | null,
  durationInMin?: number | null,
  calendarEvent?: CalendarEvent | null,
  trainers:  Array<Trainer >,
  trainerComment?: string | null,
  trainerFiles?:  Array<S3Object > | null,
  traineeIds: Array< string >,
  startDate?: string | null,
  endDate?: string | null,
  endDateLimit?: string | null,
};

export type Trainer = {
  __typename: "Trainer",
  trainerId: string,
  percentage: string,
};

export type CalendarEvent = {
  __typename: "CalendarEvent",
  id: string,
  sequence: number,
};

export type ProofBundle = {
  __typename: "ProofBundle",
  id?: string | null,
  files:  Array<S3Object >,
  startingDate: string,
  review: Review,
  description?: string | null,
  acquired?: boolean | null,
  originObjectId: string,
  workerId: string,
  skillId: string,
};

export type Review = {
  __typename: "Review",
  state: ReviewState,
  date?: string | null,
  workerId?: string | null,
};

export type Requirement = {
  __typename: "Requirement",
  id?: string | null,
  linkedObjectId: string,
  level: WorkstationWorkerLevel,
  skillTrainingVersions:  Array<SkillTrainingVersion >,
  practicalTrainingId?: string | null,
};

export type SkillTrainingVersion = {
  __typename: "SkillTrainingVersion",
  skillId: string,
  trainingVersionId?: string | null,
};

export type WorkerSkill = {
  __typename: "WorkerSkill",
  id?: string | null,
  skillId: string,
  workerId: string,
  proofBundleIds: Array< string >,
  activeProofBundle?: ProofBundle | null,
  toReviewProofBundle?: ProofBundle | null,
  validity?: Validity | null,
};

export type WorkerTrainingSession = {
  __typename: "WorkerTrainingSession",
  id?: string | null,
  trainingSessionId: string,
  workerId: string,
};

export type UserPreference = {
  __typename: "UserPreference",
  id?: string | null,
  userId: string,
  data: string,
};

export type UpdateFactoryInput = {
  pk: string,
  sk: string,
  dataType?: DataType | null,
  data?: string | null,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  _version: number,
  tagSearch?: string | null,
  tenant?: TenantInput | null,
  tenantID?: TenantIDInput | null,
  app?: AppInput | null,
  role?: RoleInput | null,
  contractType?: ContractTypeInput | null,
  organizationalUnit?: OrganizationalUnitInput | null,
  workstation?: WorkstationInput | null,
  shift?: ShiftInput | null,
  workerWorkstation?: WorkerWorkstationInput | null,
  worker?: WorkerInput | null,
  workerTag?: WorkerTagInput | null,
  skill?: SkillInput | null,
  skillTag?: SkillTagInput | null,
  training?: TrainingInput | null,
  trainingTag?: TrainingTagInput | null,
  trainingVersion?: TrainingVersionInput | null,
  trainingSession?: TrainingSessionInput | null,
  proofBundle?: ProofBundleInput | null,
  requirement?: RequirementInput | null,
  workerSkill?: WorkerSkillInput | null,
  workerTrainingSession?: WorkerTrainingSessionInput | null,
  userPreference?: UserPreferenceInput | null,
};

export type DeleteFactoryInput = {
  pk: string,
  sk: string,
  deleteDependencies?: boolean | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelFactoryFilterInput = {
  pk?: ModelIDInput | null,
  sk?: ModelIDInput | null,
  dataType?: ModelDataTypeInput | null,
  data?: ModelStringInput | null,
  userGroup?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  _lastChangedAt?: ModelFloatInput | null,
  updatedAt?: ModelStringInput | null,
  updatedBy?: ModelStringInput | null,
  tagSearch?: ModelStringInput | null,
  and?: Array< ModelFactoryFilterInput | null > | null,
  or?: Array< ModelFactoryFilterInput | null > | null,
  not?: ModelFactoryFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelFactoryConnection = {
  __typename: "ModelFactoryConnection",
  items?:  Array<Factory | null > | null,
  nextToken?: string | null,
  startedAt: number,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type InviteUserMutationVariables = {
  input?: InviteUserInput | null,
};

export type InviteUserMutation = {
  inviteUser?: boolean | null,
};

export type CreateFactoryMutationVariables = {
  input: CreateFactoryInput,
  condition?: ModelFactoryConditionInput | null,
};

export type CreateFactoryMutation = {
  createFactory?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type UpdateFactoryMutationVariables = {
  input: UpdateFactoryInput,
  condition?: ModelFactoryConditionInput | null,
};

export type UpdateFactoryMutation = {
  updateFactory?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type DeleteFactoryMutationVariables = {
  input: DeleteFactoryInput,
  condition?: ModelFactoryConditionInput | null,
};

export type DeleteFactoryMutation = {
  deleteFactory?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type CreateFactoryInternallyMutationVariables = {
  input: CreateFactoryInput,
  condition?: ModelFactoryConditionInput | null,
};

export type CreateFactoryInternallyMutation = {
  createFactoryInternally?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type UpdateFactoryInternallyMutationVariables = {
  input: UpdateFactoryInput,
  condition?: ModelFactoryConditionInput | null,
};

export type UpdateFactoryInternallyMutation = {
  updateFactoryInternally?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type DeleteFactoryInternallyMutationVariables = {
  input: DeleteFactoryInput,
  condition?: ModelFactoryConditionInput | null,
};

export type DeleteFactoryInternallyMutation = {
  deleteFactoryInternally?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type GetFactoryQueryVariables = {
  pk: string,
  sk: string,
};

export type GetFactoryQuery = {
  getFactory?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type ListFactorysQueryVariables = {
  pk?: string | null,
  sk?: ModelIDKeyConditionInput | null,
  filter?: ModelFactoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFactorysQuery = {
  listFactorys?:  {
    __typename: "ModelFactoryConnection",
    items?:  Array< {
      __typename: "Factory",
      pk: string,
      sk: string,
      dataType: DataType,
      data?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      _deleted?: boolean | null,
      _version: number,
      _lastChangedAt: number,
      tagSearch?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id?: string | null,
        name: string,
        domain: string,
        authenticationTypes: Array< AuthenticationType >,
        logo?: string | null,
        glossary?: string | null,
        workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
        workerFamilyNameFirst?: boolean | null,
        isDisableLostLevelAlert?: boolean | null,
        apps:  Array< {
          __typename: "AppConfig",
          id: AppId,
          features:  Array< {
            __typename: "Feature",
            limit?: number | null,
            feature: AppFeature,
          } >,
        } >,
        levelIconType?: LevelIconType | null,
      } | null,
      tenantID?:  {
        __typename: "TenantID",
        id?: string | null,
      } | null,
      app?:  {
        __typename: "App",
        id: string,
        name: string,
        logo?: string | null,
        version: string,
        playStoreVersion?: string | null,
        appleStoreVersion?: string | null,
      } | null,
      role?:  {
        __typename: "Role",
        id?: string | null,
        name: string,
        permissions: Array< Permission >,
      } | null,
      contractType?:  {
        __typename: "ContractType",
        id?: string | null,
        name: string,
        openEndDate?: boolean | null,
        isPauseContract?: boolean | null,
      } | null,
      organizationalUnit?:  {
        __typename: "OrganizationalUnit",
        id?: string | null,
        parentId: string,
        name: string,
        pathIds?: Array< string > | null,
        order: number,
        shiftIds?: Array< string > | null,
        description?: string | null,
        thirdPartyIds?: Array< string | null > | null,
      } | null,
      workstation?:  {
        __typename: "Workstation",
        id?: string | null,
        name: string,
        parentId: string,
        pathIds?: Array< string > | null,
        description?: string | null,
        order: number,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        shiftIds?: Array< string > | null,
        workersTargetOnShift?: string | null,
      } | null,
      shift?:  {
        __typename: "Shift",
        id?: string | null,
        parentId: string,
        name: string,
        color: string,
      } | null,
      workerWorkstation?:  {
        __typename: "WorkerWorkstation",
        id?: string | null,
        workstationId: string,
        workerId: string,
        isTrainAuto: boolean,
        warning?: WorkstationWorkerLevelTargetWarning | null,
        targetLevel?: WorkstationWorkerLevel | null,
        previousLevel: WorkstationWorkerLevel,
        activeTrainingSessions:  {
          __typename: "ActiveTrainingSessions",
          workstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          nonWorkstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          isEverySkillToRenewOrAcquireCovered?: boolean | null,
        },
        level?: WorkstationWorkerLevel | null,
        numberOfRequiredSkills?: number | null,
        numberOfOkWorkerSkills?: number | null,
        validSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        validExpireSoonSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidExpiredSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidNoRefreshSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidMissingSkills?:  Array< {
          __typename: "MissingWorkerSkillInfo",
          skillId: string,
          requirmentIds: Array< string >,
        } > | null,
      } | null,
      worker?:  {
        __typename: "Worker",
        id?: string | null,
        userId?: string | null,
        email?: string | null,
        phone?: string | null,
        profilePicture?: string | null,
        matricule?: string | null,
        name: string,
        firstName: string,
        familyName: string,
        tagIds: Array< string >,
        contracts:  Array< {
          __typename: "Contract",
          contractTypeId: string,
          startDate?: string | null,
          endDate?: string | null,
          comment?: string | null,
        } >,
        description?: string | null,
        state?: WorkerState | null,
        isAdmin?: boolean | null,
        scope?: string | null,
        lastLogin?: string | null,
      } | null,
      workerTag?:  {
        __typename: "WorkerTag",
        id?: string | null,
        name: string,
      } | null,
      skill?:  {
        __typename: "Skill",
        id?: string | null,
        name: string,
        isPractical: boolean,
        description?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        skillIds?: Array< string > | null,
        tagIds: Array< string >,
        validityDuration?: number | null,
        expiryNoticeDuration?: number | null,
      } | null,
      skillTag?:  {
        __typename: "SkillTag",
        id?: string | null,
        name: string,
      } | null,
      training?:  {
        __typename: "Training",
        id?: string | null,
        name: string,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        description?: string | null,
        notes?: string | null,
        tagIds: Array< string >,
        defaultTrainerId?: string | null,
        maxTrainee?: number | null,
      } | null,
      trainingTag?:  {
        __typename: "TrainingTag",
        id?: string | null,
        name: string,
      } | null,
      trainingVersion?:  {
        __typename: "TrainingVersion",
        id?: string | null,
        version?: number | null,
        trainingId: string,
        skillIds: Array< string >,
        durationInMin: number,
      } | null,
      trainingSession?:  {
        __typename: "TrainingSession",
        id?: string | null,
        trainingVersionId: string,
        requirementId?: string | null,
        originId?: string | null,
        requestState: ReviewState,
        isDraft?: boolean | null,
        description?: string | null,
        location?: string | null,
        scheduledTrainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        scheduledTraineeIds: Array< string >,
        scheduledStartDate?: string | null,
        durationInMin?: number | null,
        calendarEvent?:  {
          __typename: "CalendarEvent",
          id: string,
          sequence: number,
        } | null,
        trainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        trainerComment?: string | null,
        trainerFiles?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } > | null,
        traineeIds: Array< string >,
        startDate?: string | null,
        endDate?: string | null,
        endDateLimit?: string | null,
      } | null,
      proofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      requirement?:  {
        __typename: "Requirement",
        id?: string | null,
        linkedObjectId: string,
        level: WorkstationWorkerLevel,
        skillTrainingVersions:  Array< {
          __typename: "SkillTrainingVersion",
          skillId: string,
          trainingVersionId?: string | null,
        } >,
        practicalTrainingId?: string | null,
      } | null,
      workerSkill?:  {
        __typename: "WorkerSkill",
        id?: string | null,
        skillId: string,
        workerId: string,
        proofBundleIds: Array< string >,
        activeProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        toReviewProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        validity?: Validity | null,
      } | null,
      workerTrainingSession?:  {
        __typename: "WorkerTrainingSession",
        id?: string | null,
        trainingSessionId: string,
        workerId: string,
      } | null,
      userPreference?:  {
        __typename: "UserPreference",
        id?: string | null,
        userId: string,
        data: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt: number,
  } | null,
};

export type ItemsByTypeQueryVariables = {
  pk?: string | null,
  dataType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFactoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByTypeQuery = {
  itemsByType?:  {
    __typename: "ModelFactoryConnection",
    items?:  Array< {
      __typename: "Factory",
      pk: string,
      sk: string,
      dataType: DataType,
      data?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      _deleted?: boolean | null,
      _version: number,
      _lastChangedAt: number,
      tagSearch?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id?: string | null,
        name: string,
        domain: string,
        authenticationTypes: Array< AuthenticationType >,
        logo?: string | null,
        glossary?: string | null,
        workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
        workerFamilyNameFirst?: boolean | null,
        isDisableLostLevelAlert?: boolean | null,
        apps:  Array< {
          __typename: "AppConfig",
          id: AppId,
          features:  Array< {
            __typename: "Feature",
            limit?: number | null,
            feature: AppFeature,
          } >,
        } >,
        levelIconType?: LevelIconType | null,
      } | null,
      tenantID?:  {
        __typename: "TenantID",
        id?: string | null,
      } | null,
      app?:  {
        __typename: "App",
        id: string,
        name: string,
        logo?: string | null,
        version: string,
        playStoreVersion?: string | null,
        appleStoreVersion?: string | null,
      } | null,
      role?:  {
        __typename: "Role",
        id?: string | null,
        name: string,
        permissions: Array< Permission >,
      } | null,
      contractType?:  {
        __typename: "ContractType",
        id?: string | null,
        name: string,
        openEndDate?: boolean | null,
        isPauseContract?: boolean | null,
      } | null,
      organizationalUnit?:  {
        __typename: "OrganizationalUnit",
        id?: string | null,
        parentId: string,
        name: string,
        pathIds?: Array< string > | null,
        order: number,
        shiftIds?: Array< string > | null,
        description?: string | null,
        thirdPartyIds?: Array< string | null > | null,
      } | null,
      workstation?:  {
        __typename: "Workstation",
        id?: string | null,
        name: string,
        parentId: string,
        pathIds?: Array< string > | null,
        description?: string | null,
        order: number,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        shiftIds?: Array< string > | null,
        workersTargetOnShift?: string | null,
      } | null,
      shift?:  {
        __typename: "Shift",
        id?: string | null,
        parentId: string,
        name: string,
        color: string,
      } | null,
      workerWorkstation?:  {
        __typename: "WorkerWorkstation",
        id?: string | null,
        workstationId: string,
        workerId: string,
        isTrainAuto: boolean,
        warning?: WorkstationWorkerLevelTargetWarning | null,
        targetLevel?: WorkstationWorkerLevel | null,
        previousLevel: WorkstationWorkerLevel,
        activeTrainingSessions:  {
          __typename: "ActiveTrainingSessions",
          workstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          nonWorkstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          isEverySkillToRenewOrAcquireCovered?: boolean | null,
        },
        level?: WorkstationWorkerLevel | null,
        numberOfRequiredSkills?: number | null,
        numberOfOkWorkerSkills?: number | null,
        validSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        validExpireSoonSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidExpiredSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidNoRefreshSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidMissingSkills?:  Array< {
          __typename: "MissingWorkerSkillInfo",
          skillId: string,
          requirmentIds: Array< string >,
        } > | null,
      } | null,
      worker?:  {
        __typename: "Worker",
        id?: string | null,
        userId?: string | null,
        email?: string | null,
        phone?: string | null,
        profilePicture?: string | null,
        matricule?: string | null,
        name: string,
        firstName: string,
        familyName: string,
        tagIds: Array< string >,
        contracts:  Array< {
          __typename: "Contract",
          contractTypeId: string,
          startDate?: string | null,
          endDate?: string | null,
          comment?: string | null,
        } >,
        description?: string | null,
        state?: WorkerState | null,
        isAdmin?: boolean | null,
        scope?: string | null,
        lastLogin?: string | null,
      } | null,
      workerTag?:  {
        __typename: "WorkerTag",
        id?: string | null,
        name: string,
      } | null,
      skill?:  {
        __typename: "Skill",
        id?: string | null,
        name: string,
        isPractical: boolean,
        description?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        skillIds?: Array< string > | null,
        tagIds: Array< string >,
        validityDuration?: number | null,
        expiryNoticeDuration?: number | null,
      } | null,
      skillTag?:  {
        __typename: "SkillTag",
        id?: string | null,
        name: string,
      } | null,
      training?:  {
        __typename: "Training",
        id?: string | null,
        name: string,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        description?: string | null,
        notes?: string | null,
        tagIds: Array< string >,
        defaultTrainerId?: string | null,
        maxTrainee?: number | null,
      } | null,
      trainingTag?:  {
        __typename: "TrainingTag",
        id?: string | null,
        name: string,
      } | null,
      trainingVersion?:  {
        __typename: "TrainingVersion",
        id?: string | null,
        version?: number | null,
        trainingId: string,
        skillIds: Array< string >,
        durationInMin: number,
      } | null,
      trainingSession?:  {
        __typename: "TrainingSession",
        id?: string | null,
        trainingVersionId: string,
        requirementId?: string | null,
        originId?: string | null,
        requestState: ReviewState,
        isDraft?: boolean | null,
        description?: string | null,
        location?: string | null,
        scheduledTrainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        scheduledTraineeIds: Array< string >,
        scheduledStartDate?: string | null,
        durationInMin?: number | null,
        calendarEvent?:  {
          __typename: "CalendarEvent",
          id: string,
          sequence: number,
        } | null,
        trainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        trainerComment?: string | null,
        trainerFiles?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } > | null,
        traineeIds: Array< string >,
        startDate?: string | null,
        endDate?: string | null,
        endDateLimit?: string | null,
      } | null,
      proofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      requirement?:  {
        __typename: "Requirement",
        id?: string | null,
        linkedObjectId: string,
        level: WorkstationWorkerLevel,
        skillTrainingVersions:  Array< {
          __typename: "SkillTrainingVersion",
          skillId: string,
          trainingVersionId?: string | null,
        } >,
        practicalTrainingId?: string | null,
      } | null,
      workerSkill?:  {
        __typename: "WorkerSkill",
        id?: string | null,
        skillId: string,
        workerId: string,
        proofBundleIds: Array< string >,
        activeProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        toReviewProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        validity?: Validity | null,
      } | null,
      workerTrainingSession?:  {
        __typename: "WorkerTrainingSession",
        id?: string | null,
        trainingSessionId: string,
        workerId: string,
      } | null,
      userPreference?:  {
        __typename: "UserPreference",
        id?: string | null,
        userId: string,
        data: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt: number,
  } | null,
};

export type ItemsByDataQueryVariables = {
  pk?: string | null,
  data?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFactoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByDataQuery = {
  itemsByData?:  {
    __typename: "ModelFactoryConnection",
    items?:  Array< {
      __typename: "Factory",
      pk: string,
      sk: string,
      dataType: DataType,
      data?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      _deleted?: boolean | null,
      _version: number,
      _lastChangedAt: number,
      tagSearch?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id?: string | null,
        name: string,
        domain: string,
        authenticationTypes: Array< AuthenticationType >,
        logo?: string | null,
        glossary?: string | null,
        workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
        workerFamilyNameFirst?: boolean | null,
        isDisableLostLevelAlert?: boolean | null,
        apps:  Array< {
          __typename: "AppConfig",
          id: AppId,
          features:  Array< {
            __typename: "Feature",
            limit?: number | null,
            feature: AppFeature,
          } >,
        } >,
        levelIconType?: LevelIconType | null,
      } | null,
      tenantID?:  {
        __typename: "TenantID",
        id?: string | null,
      } | null,
      app?:  {
        __typename: "App",
        id: string,
        name: string,
        logo?: string | null,
        version: string,
        playStoreVersion?: string | null,
        appleStoreVersion?: string | null,
      } | null,
      role?:  {
        __typename: "Role",
        id?: string | null,
        name: string,
        permissions: Array< Permission >,
      } | null,
      contractType?:  {
        __typename: "ContractType",
        id?: string | null,
        name: string,
        openEndDate?: boolean | null,
        isPauseContract?: boolean | null,
      } | null,
      organizationalUnit?:  {
        __typename: "OrganizationalUnit",
        id?: string | null,
        parentId: string,
        name: string,
        pathIds?: Array< string > | null,
        order: number,
        shiftIds?: Array< string > | null,
        description?: string | null,
        thirdPartyIds?: Array< string | null > | null,
      } | null,
      workstation?:  {
        __typename: "Workstation",
        id?: string | null,
        name: string,
        parentId: string,
        pathIds?: Array< string > | null,
        description?: string | null,
        order: number,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        shiftIds?: Array< string > | null,
        workersTargetOnShift?: string | null,
      } | null,
      shift?:  {
        __typename: "Shift",
        id?: string | null,
        parentId: string,
        name: string,
        color: string,
      } | null,
      workerWorkstation?:  {
        __typename: "WorkerWorkstation",
        id?: string | null,
        workstationId: string,
        workerId: string,
        isTrainAuto: boolean,
        warning?: WorkstationWorkerLevelTargetWarning | null,
        targetLevel?: WorkstationWorkerLevel | null,
        previousLevel: WorkstationWorkerLevel,
        activeTrainingSessions:  {
          __typename: "ActiveTrainingSessions",
          workstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          nonWorkstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          isEverySkillToRenewOrAcquireCovered?: boolean | null,
        },
        level?: WorkstationWorkerLevel | null,
        numberOfRequiredSkills?: number | null,
        numberOfOkWorkerSkills?: number | null,
        validSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        validExpireSoonSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidExpiredSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidNoRefreshSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidMissingSkills?:  Array< {
          __typename: "MissingWorkerSkillInfo",
          skillId: string,
          requirmentIds: Array< string >,
        } > | null,
      } | null,
      worker?:  {
        __typename: "Worker",
        id?: string | null,
        userId?: string | null,
        email?: string | null,
        phone?: string | null,
        profilePicture?: string | null,
        matricule?: string | null,
        name: string,
        firstName: string,
        familyName: string,
        tagIds: Array< string >,
        contracts:  Array< {
          __typename: "Contract",
          contractTypeId: string,
          startDate?: string | null,
          endDate?: string | null,
          comment?: string | null,
        } >,
        description?: string | null,
        state?: WorkerState | null,
        isAdmin?: boolean | null,
        scope?: string | null,
        lastLogin?: string | null,
      } | null,
      workerTag?:  {
        __typename: "WorkerTag",
        id?: string | null,
        name: string,
      } | null,
      skill?:  {
        __typename: "Skill",
        id?: string | null,
        name: string,
        isPractical: boolean,
        description?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        skillIds?: Array< string > | null,
        tagIds: Array< string >,
        validityDuration?: number | null,
        expiryNoticeDuration?: number | null,
      } | null,
      skillTag?:  {
        __typename: "SkillTag",
        id?: string | null,
        name: string,
      } | null,
      training?:  {
        __typename: "Training",
        id?: string | null,
        name: string,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        description?: string | null,
        notes?: string | null,
        tagIds: Array< string >,
        defaultTrainerId?: string | null,
        maxTrainee?: number | null,
      } | null,
      trainingTag?:  {
        __typename: "TrainingTag",
        id?: string | null,
        name: string,
      } | null,
      trainingVersion?:  {
        __typename: "TrainingVersion",
        id?: string | null,
        version?: number | null,
        trainingId: string,
        skillIds: Array< string >,
        durationInMin: number,
      } | null,
      trainingSession?:  {
        __typename: "TrainingSession",
        id?: string | null,
        trainingVersionId: string,
        requirementId?: string | null,
        originId?: string | null,
        requestState: ReviewState,
        isDraft?: boolean | null,
        description?: string | null,
        location?: string | null,
        scheduledTrainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        scheduledTraineeIds: Array< string >,
        scheduledStartDate?: string | null,
        durationInMin?: number | null,
        calendarEvent?:  {
          __typename: "CalendarEvent",
          id: string,
          sequence: number,
        } | null,
        trainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        trainerComment?: string | null,
        trainerFiles?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } > | null,
        traineeIds: Array< string >,
        startDate?: string | null,
        endDate?: string | null,
        endDateLimit?: string | null,
      } | null,
      proofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      requirement?:  {
        __typename: "Requirement",
        id?: string | null,
        linkedObjectId: string,
        level: WorkstationWorkerLevel,
        skillTrainingVersions:  Array< {
          __typename: "SkillTrainingVersion",
          skillId: string,
          trainingVersionId?: string | null,
        } >,
        practicalTrainingId?: string | null,
      } | null,
      workerSkill?:  {
        __typename: "WorkerSkill",
        id?: string | null,
        skillId: string,
        workerId: string,
        proofBundleIds: Array< string >,
        activeProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        toReviewProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        validity?: Validity | null,
      } | null,
      workerTrainingSession?:  {
        __typename: "WorkerTrainingSession",
        id?: string | null,
        trainingSessionId: string,
        workerId: string,
      } | null,
      userPreference?:  {
        __typename: "UserPreference",
        id?: string | null,
        userId: string,
        data: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt: number,
  } | null,
};

export type AllFactoriesByTypeQueryVariables = {
  dataType: DataType,
  limit?: number | null,
  nextToken?: string | null,
};

export type AllFactoriesByTypeQuery = {
  allFactoriesByType?:  {
    __typename: "ModelFactoryConnection",
    items?:  Array< {
      __typename: "Factory",
      pk: string,
      sk: string,
      dataType: DataType,
      data?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      _deleted?: boolean | null,
      _version: number,
      _lastChangedAt: number,
      tagSearch?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id?: string | null,
        name: string,
        domain: string,
        authenticationTypes: Array< AuthenticationType >,
        logo?: string | null,
        glossary?: string | null,
        workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
        workerFamilyNameFirst?: boolean | null,
        isDisableLostLevelAlert?: boolean | null,
        apps:  Array< {
          __typename: "AppConfig",
          id: AppId,
          features:  Array< {
            __typename: "Feature",
            limit?: number | null,
            feature: AppFeature,
          } >,
        } >,
        levelIconType?: LevelIconType | null,
      } | null,
      tenantID?:  {
        __typename: "TenantID",
        id?: string | null,
      } | null,
      app?:  {
        __typename: "App",
        id: string,
        name: string,
        logo?: string | null,
        version: string,
        playStoreVersion?: string | null,
        appleStoreVersion?: string | null,
      } | null,
      role?:  {
        __typename: "Role",
        id?: string | null,
        name: string,
        permissions: Array< Permission >,
      } | null,
      contractType?:  {
        __typename: "ContractType",
        id?: string | null,
        name: string,
        openEndDate?: boolean | null,
        isPauseContract?: boolean | null,
      } | null,
      organizationalUnit?:  {
        __typename: "OrganizationalUnit",
        id?: string | null,
        parentId: string,
        name: string,
        pathIds?: Array< string > | null,
        order: number,
        shiftIds?: Array< string > | null,
        description?: string | null,
        thirdPartyIds?: Array< string | null > | null,
      } | null,
      workstation?:  {
        __typename: "Workstation",
        id?: string | null,
        name: string,
        parentId: string,
        pathIds?: Array< string > | null,
        description?: string | null,
        order: number,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        shiftIds?: Array< string > | null,
        workersTargetOnShift?: string | null,
      } | null,
      shift?:  {
        __typename: "Shift",
        id?: string | null,
        parentId: string,
        name: string,
        color: string,
      } | null,
      workerWorkstation?:  {
        __typename: "WorkerWorkstation",
        id?: string | null,
        workstationId: string,
        workerId: string,
        isTrainAuto: boolean,
        warning?: WorkstationWorkerLevelTargetWarning | null,
        targetLevel?: WorkstationWorkerLevel | null,
        previousLevel: WorkstationWorkerLevel,
        activeTrainingSessions:  {
          __typename: "ActiveTrainingSessions",
          workstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          nonWorkstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          isEverySkillToRenewOrAcquireCovered?: boolean | null,
        },
        level?: WorkstationWorkerLevel | null,
        numberOfRequiredSkills?: number | null,
        numberOfOkWorkerSkills?: number | null,
        validSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        validExpireSoonSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidExpiredSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidNoRefreshSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidMissingSkills?:  Array< {
          __typename: "MissingWorkerSkillInfo",
          skillId: string,
          requirmentIds: Array< string >,
        } > | null,
      } | null,
      worker?:  {
        __typename: "Worker",
        id?: string | null,
        userId?: string | null,
        email?: string | null,
        phone?: string | null,
        profilePicture?: string | null,
        matricule?: string | null,
        name: string,
        firstName: string,
        familyName: string,
        tagIds: Array< string >,
        contracts:  Array< {
          __typename: "Contract",
          contractTypeId: string,
          startDate?: string | null,
          endDate?: string | null,
          comment?: string | null,
        } >,
        description?: string | null,
        state?: WorkerState | null,
        isAdmin?: boolean | null,
        scope?: string | null,
        lastLogin?: string | null,
      } | null,
      workerTag?:  {
        __typename: "WorkerTag",
        id?: string | null,
        name: string,
      } | null,
      skill?:  {
        __typename: "Skill",
        id?: string | null,
        name: string,
        isPractical: boolean,
        description?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        skillIds?: Array< string > | null,
        tagIds: Array< string >,
        validityDuration?: number | null,
        expiryNoticeDuration?: number | null,
      } | null,
      skillTag?:  {
        __typename: "SkillTag",
        id?: string | null,
        name: string,
      } | null,
      training?:  {
        __typename: "Training",
        id?: string | null,
        name: string,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        description?: string | null,
        notes?: string | null,
        tagIds: Array< string >,
        defaultTrainerId?: string | null,
        maxTrainee?: number | null,
      } | null,
      trainingTag?:  {
        __typename: "TrainingTag",
        id?: string | null,
        name: string,
      } | null,
      trainingVersion?:  {
        __typename: "TrainingVersion",
        id?: string | null,
        version?: number | null,
        trainingId: string,
        skillIds: Array< string >,
        durationInMin: number,
      } | null,
      trainingSession?:  {
        __typename: "TrainingSession",
        id?: string | null,
        trainingVersionId: string,
        requirementId?: string | null,
        originId?: string | null,
        requestState: ReviewState,
        isDraft?: boolean | null,
        description?: string | null,
        location?: string | null,
        scheduledTrainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        scheduledTraineeIds: Array< string >,
        scheduledStartDate?: string | null,
        durationInMin?: number | null,
        calendarEvent?:  {
          __typename: "CalendarEvent",
          id: string,
          sequence: number,
        } | null,
        trainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        trainerComment?: string | null,
        trainerFiles?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } > | null,
        traineeIds: Array< string >,
        startDate?: string | null,
        endDate?: string | null,
        endDateLimit?: string | null,
      } | null,
      proofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      requirement?:  {
        __typename: "Requirement",
        id?: string | null,
        linkedObjectId: string,
        level: WorkstationWorkerLevel,
        skillTrainingVersions:  Array< {
          __typename: "SkillTrainingVersion",
          skillId: string,
          trainingVersionId?: string | null,
        } >,
        practicalTrainingId?: string | null,
      } | null,
      workerSkill?:  {
        __typename: "WorkerSkill",
        id?: string | null,
        skillId: string,
        workerId: string,
        proofBundleIds: Array< string >,
        activeProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        toReviewProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        validity?: Validity | null,
      } | null,
      workerTrainingSession?:  {
        __typename: "WorkerTrainingSession",
        id?: string | null,
        trainingSessionId: string,
        workerId: string,
      } | null,
      userPreference?:  {
        __typename: "UserPreference",
        id?: string | null,
        userId: string,
        data: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt: number,
  } | null,
};

export type SyncFactoriesQueryVariables = {
  pk: string,
  lastSync: number,
  limit?: number | null,
  nextToken?: string | null,
};

export type SyncFactoriesQuery = {
  syncFactories?:  {
    __typename: "ModelFactoryConnection",
    items?:  Array< {
      __typename: "Factory",
      pk: string,
      sk: string,
      dataType: DataType,
      data?: string | null,
      createdAt?: string | null,
      owner?: string | null,
      updatedAt?: string | null,
      updatedBy?: string | null,
      _deleted?: boolean | null,
      _version: number,
      _lastChangedAt: number,
      tagSearch?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id?: string | null,
        name: string,
        domain: string,
        authenticationTypes: Array< AuthenticationType >,
        logo?: string | null,
        glossary?: string | null,
        workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
        workerFamilyNameFirst?: boolean | null,
        isDisableLostLevelAlert?: boolean | null,
        apps:  Array< {
          __typename: "AppConfig",
          id: AppId,
          features:  Array< {
            __typename: "Feature",
            limit?: number | null,
            feature: AppFeature,
          } >,
        } >,
        levelIconType?: LevelIconType | null,
      } | null,
      tenantID?:  {
        __typename: "TenantID",
        id?: string | null,
      } | null,
      app?:  {
        __typename: "App",
        id: string,
        name: string,
        logo?: string | null,
        version: string,
        playStoreVersion?: string | null,
        appleStoreVersion?: string | null,
      } | null,
      role?:  {
        __typename: "Role",
        id?: string | null,
        name: string,
        permissions: Array< Permission >,
      } | null,
      contractType?:  {
        __typename: "ContractType",
        id?: string | null,
        name: string,
        openEndDate?: boolean | null,
        isPauseContract?: boolean | null,
      } | null,
      organizationalUnit?:  {
        __typename: "OrganizationalUnit",
        id?: string | null,
        parentId: string,
        name: string,
        pathIds?: Array< string > | null,
        order: number,
        shiftIds?: Array< string > | null,
        description?: string | null,
        thirdPartyIds?: Array< string | null > | null,
      } | null,
      workstation?:  {
        __typename: "Workstation",
        id?: string | null,
        name: string,
        parentId: string,
        pathIds?: Array< string > | null,
        description?: string | null,
        order: number,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        shiftIds?: Array< string > | null,
        workersTargetOnShift?: string | null,
      } | null,
      shift?:  {
        __typename: "Shift",
        id?: string | null,
        parentId: string,
        name: string,
        color: string,
      } | null,
      workerWorkstation?:  {
        __typename: "WorkerWorkstation",
        id?: string | null,
        workstationId: string,
        workerId: string,
        isTrainAuto: boolean,
        warning?: WorkstationWorkerLevelTargetWarning | null,
        targetLevel?: WorkstationWorkerLevel | null,
        previousLevel: WorkstationWorkerLevel,
        activeTrainingSessions:  {
          __typename: "ActiveTrainingSessions",
          workstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          nonWorkstationActiveTrainingSessions:  {
            __typename: "WorkstationActiveTrainingSession",
            lowerOrEqualToTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
            greaterThanTarget:  {
              __typename: "ActiveTrainingSessionsByRequirement",
              fromNonInheritedRequirements: Array< string >,
              fromInheritedRequirements: Array< string >,
            },
          },
          isEverySkillToRenewOrAcquireCovered?: boolean | null,
        },
        level?: WorkstationWorkerLevel | null,
        numberOfRequiredSkills?: number | null,
        numberOfOkWorkerSkills?: number | null,
        validSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        validExpireSoonSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidExpiredSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidNoRefreshSkills?:  Array< {
          __typename: "WorkerSkillInfo",
          workerSkillId: string,
          requirmentIds: Array< string >,
        } > | null,
        invalidMissingSkills?:  Array< {
          __typename: "MissingWorkerSkillInfo",
          skillId: string,
          requirmentIds: Array< string >,
        } > | null,
      } | null,
      worker?:  {
        __typename: "Worker",
        id?: string | null,
        userId?: string | null,
        email?: string | null,
        phone?: string | null,
        profilePicture?: string | null,
        matricule?: string | null,
        name: string,
        firstName: string,
        familyName: string,
        tagIds: Array< string >,
        contracts:  Array< {
          __typename: "Contract",
          contractTypeId: string,
          startDate?: string | null,
          endDate?: string | null,
          comment?: string | null,
        } >,
        description?: string | null,
        state?: WorkerState | null,
        isAdmin?: boolean | null,
        scope?: string | null,
        lastLogin?: string | null,
      } | null,
      workerTag?:  {
        __typename: "WorkerTag",
        id?: string | null,
        name: string,
      } | null,
      skill?:  {
        __typename: "Skill",
        id?: string | null,
        name: string,
        isPractical: boolean,
        description?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        skillIds?: Array< string > | null,
        tagIds: Array< string >,
        validityDuration?: number | null,
        expiryNoticeDuration?: number | null,
      } | null,
      skillTag?:  {
        __typename: "SkillTag",
        id?: string | null,
        name: string,
      } | null,
      training?:  {
        __typename: "Training",
        id?: string | null,
        name: string,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        description?: string | null,
        notes?: string | null,
        tagIds: Array< string >,
        defaultTrainerId?: string | null,
        maxTrainee?: number | null,
      } | null,
      trainingTag?:  {
        __typename: "TrainingTag",
        id?: string | null,
        name: string,
      } | null,
      trainingVersion?:  {
        __typename: "TrainingVersion",
        id?: string | null,
        version?: number | null,
        trainingId: string,
        skillIds: Array< string >,
        durationInMin: number,
      } | null,
      trainingSession?:  {
        __typename: "TrainingSession",
        id?: string | null,
        trainingVersionId: string,
        requirementId?: string | null,
        originId?: string | null,
        requestState: ReviewState,
        isDraft?: boolean | null,
        description?: string | null,
        location?: string | null,
        scheduledTrainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        scheduledTraineeIds: Array< string >,
        scheduledStartDate?: string | null,
        durationInMin?: number | null,
        calendarEvent?:  {
          __typename: "CalendarEvent",
          id: string,
          sequence: number,
        } | null,
        trainers:  Array< {
          __typename: "Trainer",
          trainerId: string,
          percentage: string,
        } >,
        trainerComment?: string | null,
        trainerFiles?:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } > | null,
        traineeIds: Array< string >,
        startDate?: string | null,
        endDate?: string | null,
        endDateLimit?: string | null,
      } | null,
      proofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      requirement?:  {
        __typename: "Requirement",
        id?: string | null,
        linkedObjectId: string,
        level: WorkstationWorkerLevel,
        skillTrainingVersions:  Array< {
          __typename: "SkillTrainingVersion",
          skillId: string,
          trainingVersionId?: string | null,
        } >,
        practicalTrainingId?: string | null,
      } | null,
      workerSkill?:  {
        __typename: "WorkerSkill",
        id?: string | null,
        skillId: string,
        workerId: string,
        proofBundleIds: Array< string >,
        activeProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        toReviewProofBundle?:  {
          __typename: "ProofBundle",
          id?: string | null,
          files:  Array< {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
            fileName: string,
            visibility: StorageVisibility,
            owner?: string | null,
          } >,
          startingDate: string,
          review:  {
            __typename: "Review",
            state: ReviewState,
            date?: string | null,
            workerId?: string | null,
          },
          description?: string | null,
          acquired?: boolean | null,
          originObjectId: string,
          workerId: string,
          skillId: string,
        } | null,
        validity?: Validity | null,
      } | null,
      workerTrainingSession?:  {
        __typename: "WorkerTrainingSession",
        id?: string | null,
        trainingSessionId: string,
        workerId: string,
      } | null,
      userPreference?:  {
        __typename: "UserPreference",
        id?: string | null,
        userId: string,
        data: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
    startedAt: number,
  } | null,
};

export type GetFilePreSignedUrlQueryVariables = {
  pk: string,
  key: string,
};

export type GetFilePreSignedUrlQuery = {
  getFilePreSignedUrl?: string | null,
};

export type OnCreateFactorySubscriptionVariables = {
  pk?: string | null,
};

export type OnCreateFactorySubscription = {
  onCreateFactory?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type OnUpdateFactorySubscriptionVariables = {
  pk?: string | null,
};

export type OnUpdateFactorySubscription = {
  onUpdateFactory?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};

export type OnDeleteFactorySubscriptionVariables = {
  pk?: string | null,
};

export type OnDeleteFactorySubscription = {
  onDeleteFactory?:  {
    __typename: "Factory",
    pk: string,
    sk: string,
    dataType: DataType,
    data?: string | null,
    createdAt?: string | null,
    owner?: string | null,
    updatedAt?: string | null,
    updatedBy?: string | null,
    _deleted?: boolean | null,
    _version: number,
    _lastChangedAt: number,
    tagSearch?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id?: string | null,
      name: string,
      domain: string,
      authenticationTypes: Array< AuthenticationType >,
      logo?: string | null,
      glossary?: string | null,
      workstationTargetsStartingAtLevel: WorkstationWorkerLevel,
      workerFamilyNameFirst?: boolean | null,
      isDisableLostLevelAlert?: boolean | null,
      apps:  Array< {
        __typename: "AppConfig",
        id: AppId,
        features:  Array< {
          __typename: "Feature",
          limit?: number | null,
          feature: AppFeature,
        } >,
      } >,
      levelIconType?: LevelIconType | null,
    } | null,
    tenantID?:  {
      __typename: "TenantID",
      id?: string | null,
    } | null,
    app?:  {
      __typename: "App",
      id: string,
      name: string,
      logo?: string | null,
      version: string,
      playStoreVersion?: string | null,
      appleStoreVersion?: string | null,
    } | null,
    role?:  {
      __typename: "Role",
      id?: string | null,
      name: string,
      permissions: Array< Permission >,
    } | null,
    contractType?:  {
      __typename: "ContractType",
      id?: string | null,
      name: string,
      openEndDate?: boolean | null,
      isPauseContract?: boolean | null,
    } | null,
    organizationalUnit?:  {
      __typename: "OrganizationalUnit",
      id?: string | null,
      parentId: string,
      name: string,
      pathIds?: Array< string > | null,
      order: number,
      shiftIds?: Array< string > | null,
      description?: string | null,
      thirdPartyIds?: Array< string | null > | null,
    } | null,
    workstation?:  {
      __typename: "Workstation",
      id?: string | null,
      name: string,
      parentId: string,
      pathIds?: Array< string > | null,
      description?: string | null,
      order: number,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      shiftIds?: Array< string > | null,
      workersTargetOnShift?: string | null,
    } | null,
    shift?:  {
      __typename: "Shift",
      id?: string | null,
      parentId: string,
      name: string,
      color: string,
    } | null,
    workerWorkstation?:  {
      __typename: "WorkerWorkstation",
      id?: string | null,
      workstationId: string,
      workerId: string,
      isTrainAuto: boolean,
      warning?: WorkstationWorkerLevelTargetWarning | null,
      targetLevel?: WorkstationWorkerLevel | null,
      previousLevel: WorkstationWorkerLevel,
      activeTrainingSessions:  {
        __typename: "ActiveTrainingSessions",
        workstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        nonWorkstationActiveTrainingSessions:  {
          __typename: "WorkstationActiveTrainingSession",
          lowerOrEqualToTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
          greaterThanTarget:  {
            __typename: "ActiveTrainingSessionsByRequirement",
            fromNonInheritedRequirements: Array< string >,
            fromInheritedRequirements: Array< string >,
          },
        },
        isEverySkillToRenewOrAcquireCovered?: boolean | null,
      },
      level?: WorkstationWorkerLevel | null,
      numberOfRequiredSkills?: number | null,
      numberOfOkWorkerSkills?: number | null,
      validSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      validExpireSoonSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidExpiredSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidNoRefreshSkills?:  Array< {
        __typename: "WorkerSkillInfo",
        workerSkillId: string,
        requirmentIds: Array< string >,
      } > | null,
      invalidMissingSkills?:  Array< {
        __typename: "MissingWorkerSkillInfo",
        skillId: string,
        requirmentIds: Array< string >,
      } > | null,
    } | null,
    worker?:  {
      __typename: "Worker",
      id?: string | null,
      userId?: string | null,
      email?: string | null,
      phone?: string | null,
      profilePicture?: string | null,
      matricule?: string | null,
      name: string,
      firstName: string,
      familyName: string,
      tagIds: Array< string >,
      contracts:  Array< {
        __typename: "Contract",
        contractTypeId: string,
        startDate?: string | null,
        endDate?: string | null,
        comment?: string | null,
      } >,
      description?: string | null,
      state?: WorkerState | null,
      isAdmin?: boolean | null,
      scope?: string | null,
      lastLogin?: string | null,
    } | null,
    workerTag?:  {
      __typename: "WorkerTag",
      id?: string | null,
      name: string,
    } | null,
    skill?:  {
      __typename: "Skill",
      id?: string | null,
      name: string,
      isPractical: boolean,
      description?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      skillIds?: Array< string > | null,
      tagIds: Array< string >,
      validityDuration?: number | null,
      expiryNoticeDuration?: number | null,
    } | null,
    skillTag?:  {
      __typename: "SkillTag",
      id?: string | null,
      name: string,
    } | null,
    training?:  {
      __typename: "Training",
      id?: string | null,
      name: string,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      description?: string | null,
      notes?: string | null,
      tagIds: Array< string >,
      defaultTrainerId?: string | null,
      maxTrainee?: number | null,
    } | null,
    trainingTag?:  {
      __typename: "TrainingTag",
      id?: string | null,
      name: string,
    } | null,
    trainingVersion?:  {
      __typename: "TrainingVersion",
      id?: string | null,
      version?: number | null,
      trainingId: string,
      skillIds: Array< string >,
      durationInMin: number,
    } | null,
    trainingSession?:  {
      __typename: "TrainingSession",
      id?: string | null,
      trainingVersionId: string,
      requirementId?: string | null,
      originId?: string | null,
      requestState: ReviewState,
      isDraft?: boolean | null,
      description?: string | null,
      location?: string | null,
      scheduledTrainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      scheduledTraineeIds: Array< string >,
      scheduledStartDate?: string | null,
      durationInMin?: number | null,
      calendarEvent?:  {
        __typename: "CalendarEvent",
        id: string,
        sequence: number,
      } | null,
      trainers:  Array< {
        __typename: "Trainer",
        trainerId: string,
        percentage: string,
      } >,
      trainerComment?: string | null,
      trainerFiles?:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } > | null,
      traineeIds: Array< string >,
      startDate?: string | null,
      endDate?: string | null,
      endDateLimit?: string | null,
    } | null,
    proofBundle?:  {
      __typename: "ProofBundle",
      id?: string | null,
      files:  Array< {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
        fileName: string,
        visibility: StorageVisibility,
        owner?: string | null,
      } >,
      startingDate: string,
      review:  {
        __typename: "Review",
        state: ReviewState,
        date?: string | null,
        workerId?: string | null,
      },
      description?: string | null,
      acquired?: boolean | null,
      originObjectId: string,
      workerId: string,
      skillId: string,
    } | null,
    requirement?:  {
      __typename: "Requirement",
      id?: string | null,
      linkedObjectId: string,
      level: WorkstationWorkerLevel,
      skillTrainingVersions:  Array< {
        __typename: "SkillTrainingVersion",
        skillId: string,
        trainingVersionId?: string | null,
      } >,
      practicalTrainingId?: string | null,
    } | null,
    workerSkill?:  {
      __typename: "WorkerSkill",
      id?: string | null,
      skillId: string,
      workerId: string,
      proofBundleIds: Array< string >,
      activeProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      toReviewProofBundle?:  {
        __typename: "ProofBundle",
        id?: string | null,
        files:  Array< {
          __typename: "S3Object",
          bucket: string,
          region: string,
          key: string,
          fileName: string,
          visibility: StorageVisibility,
          owner?: string | null,
        } >,
        startingDate: string,
        review:  {
          __typename: "Review",
          state: ReviewState,
          date?: string | null,
          workerId?: string | null,
        },
        description?: string | null,
        acquired?: boolean | null,
        originObjectId: string,
        workerId: string,
        skillId: string,
      } | null,
      validity?: Validity | null,
    } | null,
    workerTrainingSession?:  {
      __typename: "WorkerTrainingSession",
      id?: string | null,
      trainingSessionId: string,
      workerId: string,
    } | null,
    userPreference?:  {
      __typename: "UserPreference",
      id?: string | null,
      userId: string,
      data: string,
    } | null,
  } | null,
};
