import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  passwordRulesContainer: {
    paddingTop: Spacings.CardPadding,
  },
  passwordRuleText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: Colors.Grey,
  },
});

export default styles;
