import React, { useEffect, useState } from 'react';
import { View, Text, InteractionManager } from 'react-native';
import { TextFontStyles, Colors, Spacings, fontSize } from 'shared/styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { FileViewerWeb, FileType, getFileTypeFromFileName } from 'sharedweb/src/FileViewer';
import { S3ObjectWithLink } from 'shared/backend-data';
import { FullScreenProof } from 'sharedweb/src/FileViewer/FullScreenProof';

interface ProofProps {
  file: S3ObjectWithLink;
}

const featherFileIcon = require('shared/assets/svg/icon.certificate.svg').default;

export const Proof: React.FC<ProofProps> = props => {
  const isMounted = useIsMounted();
  const [textFileProofContent, setTextFileProofContent] = useState('Chargement du text ...');
  const [filePath, setFilePath] = useState<string>('');
  const [fileType, setFileType] = useState<FileType | undefined>();
  const [fullView, setFullView] = useState<boolean>(false);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      handleTextFiles();
      if (props.file) {
        setFilePath(props.file.downloadLink);
        const fileExtension = getFileTypeFromFileName(props.file.key);
        setFileType(fileExtension);
      }
    });
  }, [props.file]);

  const handleTextFiles = async () => {
    if (fileType?.includes('txt')) {
      await fetch(props.file?.downloadLink).then(async r => {
        const text = await r.text();
        if (isMounted.current) setTextFileProofContent(text);
      });
    }
  };

  return (
    <>
      {fileType?.includes('txt') ? (
        <View style={{ paddingHorizontal: 40 }}>
          <View style={{ marginBottom: Spacings.Unit * 5, paddingTop: 32 }}>
            <IconSVG svgComponent={featherFileIcon} size={{ width: 70, height: 88 }} />
          </View>
          <Text
            style={{
              ...TextFontStyles.LatoBlack,
              color: Colors.Black,
              fontSize: fontSize.Regular,
            }}
          >
            {textFileProofContent}
          </Text>
        </View>
      ) : (
        <FileViewerWeb filePath={filePath} fileType={fileType} onClick={() => setFullView(true)} />
      )}
      {fullView && (
        <FullScreenProof filePath={filePath} fileType={fileType} setShowModal={setFullView} />
      )}
    </>
  );
};
