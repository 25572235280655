import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { RadioButton } from 'shared/ui-component/Button';
import { RadioButtonProps } from 'shared/ui-component/Button/RadioButton';

import Styles from './styles';

interface Props extends Omit<RadioButtonProps, 'items'> {}

export const AcquiredAndNotAcquiredButtons: React.FC<Props> = props => {
  const { textStyle, disabled } = props;
  const [skillAcquiredText, setSkillAcquiredText] = useState<string>();

  useEffect(() => {
    if (props.initialItemIndex === 0) {
      setSkillAcquiredText(t('alex:proofBook.skillsAcquired'));
    } else if (props.initialItemIndex === 1) {
      setSkillAcquiredText(t('alex:proofBook.skillsNotAcquired'));
    } else {
      setSkillAcquiredText(undefined);
    }
  }, [props.initialItemIndex]);

  const radioButtons = [
    {
      item: t('alex:trainingSessions.addTrainingModal.acquiredTheSkills'),
    },
    {
      item: t('alex:trainingSessions.addTrainingModal.notAcquiredTheSkills'),
    },
  ];

  return (
    <View style={Styles.radioButtonContainer}>
      {disabled ? (
        <Text style={Styles.skillAcquiredText}>{skillAcquiredText}</Text>
      ) : (
        <RadioButton
          {...props}
          items={radioButtons}
          textStyle={[Styles.radioButtonText, textStyle]}
        />
      )}
    </View>
  );
};
