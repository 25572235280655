import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import * as _ from 'lodash-es';
import { SearchList } from '.';
import style from './Styles';
import { TagComponent, TagType } from './TagComponent';
import { Checkbox } from '../Checkbox';

export interface SearchItemProps {
  textInput: string;
  tag: SearchList;
  tags: SearchList[];
  multiCheck?: boolean;

  renderOption?: ((value: any) => JSX.Element) | undefined;
  handleSelectedTag: (tag: SearchList) => void;
}

export const SearchItem: React.FC<SearchItemProps> = props => {
  const {
    textInput,
    tag,
    tags,
    multiCheck,

    renderOption,
    handleSelectedTag,
  } = props;
  let isAlreadySelected: SearchList | undefined;

  if (textInput && tag.isParentTagNotClickable) return null;

  if (tag.children && tag.isParentTagNotClickable) {
    isAlreadySelected = tag.children.find(eachData => eachData.key === tag.key);
  } else {
    isAlreadySelected = tags.find(eachData => eachData.key === tag.key);
  }

  function _handleSelectedTag() {
    handleSelectedTag(tag);
  }

  return tag.children && tag.isParentTagNotClickable ? (
    <View style={style.parentTagContainer}>
      <Text style={style.parentTagText} numberOfLines={2}>
        {tag.label}
      </Text>
      <Text style={style.checkBoxTitleText} numberOfLines={2}>
        {tag.checkBoxTitle}
      </Text>
    </View>
  ) : (
    <TouchableOpacity key={tag.key} style={style.listContainer} onPress={_handleSelectedTag}>
      <TagComponent
        key={tag.key}
        tag={tag}
        tagType={TagType.AutoCompleteTag}
        onPress={_handleSelectedTag}
        highlightString={textInput}
      />
      {multiCheck ? (
        <Checkbox initialState={Boolean(isAlreadySelected)} onChange={_handleSelectedTag} />
      ) : renderOption ? (
        renderOption(tag)
      ) : (
        <></>
      )}
    </TouchableOpacity>
  );
};
