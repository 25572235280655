import React, { useState, useEffect } from 'react';
import * as API from 'shared/backend-data';
import { Text, View, Platform } from 'react-native';
import Styles from './Style';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { UserContext, Challenges, CognitoAuthErrorCodes } from 'shared/context/UserContext';
import { KeyboardAvoidingViewWrapper } from 'shared/ui-component/KeyboardAvoidingView';
import { IconSVG } from 'shared/ui-component/Icon';
import arrowLeft from 'shared/assets/svg/icon.arrowLeft.mobile-2.svg';
import alexIcon from 'shared/assets/svg/icon.alex.svg';
import { InputText, ParserType } from 'shared/ui-component/Input';
import * as _ from 'lodash-es';
import { t } from 'shared/localisation/i18n';
import { YellowButton } from 'shared/ui-component/Button';
import { Colors } from 'shared/styles/Colors';
import { DetailsHeader } from 'sharedmob/src/header/DetailPageHeader';
import { ExternalLinkAuthParams } from '../index';
import { InputsValue, useCustomForm, InputValidationType } from 'shared/hooks/CustomForm';

enum InputIds {
  UserId = 'UserId',
}

interface Props {
  username?: string;
  externalLinkAuthParams?: ExternalLinkAuthParams;
  emailAuthentication?: boolean;
  isWebPlatfrom: boolean;
  handleUsernameSearchResult: (usernameExists: boolean, username: string) => void;
  handleResetPasswordNavigation: (otp?: string, email?: string) => void;
  handleBackPress?: () => void;
  handleInvitedUserResetPasswordNavigation: () => void;
  handleSignedInNavigation: () => void;
  handleIdentifyScreenLayout: () => void;
}

export const IdentifyUserLayout: React.FC<Props> = props => {
  const { externalLinkAuthParams } = props;

  const [emailPhoneOrWorkerPersonalId, setEmailPhoneOrWorkerPersonalId] = useState<string>(
    props.username ?? '',
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const { errors, enableFormSubmit, onBlur, onChange, onSubmit } = useCustomForm<string>(
    {
      [InputIds.UserId]: {
        validator:
          props.emailAuthentication !== undefined
            ? props.emailAuthentication
              ? [InputValidationType.Email]
              : [InputValidationType.PhoneNumber, InputValidationType.WorkerPersonalIdPattern]
            : [
                InputValidationType.Email,
                InputValidationType.PhoneNumber,
                InputValidationType.WorkerPersonalIdPattern,
              ],
        isFieldMandatory: true,
      },
    },
    onSubmitForm,
  );
  const placeHolder =
    props.emailAuthentication !== undefined
      ? props.emailAuthentication
        ? t('common:account.email')
        : t('common:account.phone')
      : t('common:account.email') + '/' + t('common:account.phone', undefined, false);

  useEffect(() => {
    handleExternalLinkParams();
  }, [externalLinkAuthParams]);

  const handleExternalLinkParams = async () => {
    if (!externalLinkAuthParams) return;

    if (externalLinkAuthParams.otp && externalLinkAuthParams.name && externalLinkAuthParams.email) {
      await handleConfirmOTP(
        externalLinkAuthParams.otp,
        externalLinkAuthParams.name,
        externalLinkAuthParams.email,
      );
    } else if (externalLinkAuthParams.otp && externalLinkAuthParams.email)
      props.handleResetPasswordNavigation(externalLinkAuthParams.otp, externalLinkAuthParams.email);
    else if (externalLinkAuthParams.email && externalLinkAuthParams.token)
      await handleSignIn(externalLinkAuthParams.email, externalLinkAuthParams.token);
    else if (externalLinkAuthParams.email) {
      setEmailPhoneOrWorkerPersonalId(externalLinkAuthParams.email);
      handleLogin(externalLinkAuthParams.email);
    }
  };

  const handleSignIn = async (userName: string, pwd: string) => {
    const signInResult = await UserContext.signIn(userName, pwd);
    if (API.isFailure(signInResult)) {
      setError(signInResult.data.message);
    } else {
      if (
        signInResult.challengeName &&
        signInResult.challengeName === Challenges.NEW_PASSWORD_REQUIRED
      ) {
        props.handleInvitedUserResetPasswordNavigation();
      } else {
        props.handleSignedInNavigation();
      }
    }
  };

  const handleConfirmOTP = async (otp: string, userName: string, email: string) => {
    const otpResult = await UserContext.confirmOtp(userName, otp);
    if (
      API.isFailure(otpResult) &&
      otpResult.data.code !== CognitoAuthErrorCodes.NotAuthorizedException
    ) {
      setError(otpResult.data.message);
    } else {
      setEmailPhoneOrWorkerPersonalId(email);
      handleLogin(email);
    }
  };

  async function handleLogin(email?: string) {
    setLoading(true);
    const result = await UserContext.checkUserAlreadyExists(email || emailPhoneOrWorkerPersonalId);
    if (API.isFailure(result)) {
      setError(result.data.message);
      setLoading(false);
    } else {
      setLoading(false);
      if (!result && Platform.OS === 'ios') {
        setError(t('common:auth.registerEnterprise.externalDomainWarningIOS'));
      } else {
        props.handleUsernameSearchResult(result, email || emailPhoneOrWorkerPersonalId);
      }
    }
  }

  function onSubmitForm(values: InputsValue<string>) {
    handleLogin(values[InputIds.UserId]);
  }

  return (
    <KeyboardAvoidingViewWrapper style={Styles.appContainer}>
      <View style={Styles.rootContainer}>
        {props.isWebPlatfrom ? (
          <View style={Styles.topContainer}>
            <IconSVG
              svgComponent={alexIcon}
              size={{ height: 40, width: 40 }}
              color={Colors.Yellow}
              containerStyle={Styles.IconContainer}
            />
            <Text style={Styles.headerText}>
              {t('common:serverAndConnectionWarnings.connectivity.connection')}
            </Text>
          </View>
        ) : (
          <DetailsHeader
            leftIcon={arrowLeft}
            onLeftButtonPress={() => props.handleIdentifyScreenLayout()}
            title={
              props.emailAuthentication !== undefined
                ? props.emailAuthentication
                  ? t('common:auth.identification.emailHeader')
                  : t('common:auth.identification.phoneHeader')
                : Platform.OS === 'ios'
                ? t('common:auth.identification.headerIOS')
                : t('common:auth.identification.header')
            }
          />
        )}
        <View style={Styles.middleContainer}>
          <InputText
            autoFocus
            inputId={InputIds.UserId}
            placeHolder={placeHolder}
            onTextChange={onChange}
            onSubmitEditing={onSubmit}
            onBlur={onBlur}
            parser={[ParserType.CaseInsensitive]}
            errorMessage={errors[InputIds.UserId]?.inputErrorMessage}
          />
          <Text style={Styles.errorMessage}>{error ?? ''}</Text>
        </View>
        <View style={Styles.buttonContainer}>
          <YellowButton
            style={enableFormSubmit ? Styles.connectButton : Styles.connectButtonDisabled}
            onPress={onSubmit}
            disabled={!enableFormSubmit}
            text={t('common:button.login')}
            textStyle={Styles.connectText}
          />
        </View>
        {loading && <Loader />}
      </View>
    </KeyboardAvoidingViewWrapper>
  );
};
