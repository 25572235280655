import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { Spacings } from 'shared/styles';

const styles = StyleSheet.create({
  rowTextStyle: {
    fontSize: SharedStyles.fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  SelectContainer: {
    width: Spacings.Big * 6 + Spacings.Medium * 2,
    height: Spacings.Big * 7 + Spacings.Medium * 2,
    zIndex: 999,
    position: 'absolute',
    borderRadius: 8,
    backgroundColor: SharedStyles.Colors.White,
    shadowColor: SharedStyles.Colors.ShadowColor,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 3,
    minWidth: Spacings.Big * 6 + Spacings.Medium * 2,
  },
  SelectInnerContainerStyle: {
    flex: 1,
    backgroundColor: SharedStyles.Colors.White,
    borderRadius: 8,
  },
  selecInputStyle: { minHeight: 40 },
  createTagIconContainer: {
    marginRight: Spacings.Unit,
    backgroundColor: SharedStyles.Colors.Transparent,
  },
  createTagContainer: {
    padding: Spacings.Medium,
    paddingTop: Spacings.xMedium,
  },
  createTagRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  noResultContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 60,
  },
  noResult: {
    color: SharedStyles.Colors.Grey,
    fontSize: SharedStyles.fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoRegular,
    paddingHorizontal: Spacings.Small,
  },
  scrollViewStyle: {
    paddingVertical: SharedStyles.Spacings.Unit,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    minHeight: 200,
  },
  inputTagScrollView: {
    maxHeight: 150,
    flexShrink: 0,
    flexGrow: 0,
  },
});
export default styles;
