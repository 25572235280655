import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { fontSize } from 'shared/styles';

export const styles = StyleSheet.create({
  mainContainer: {
    minHeight: 64,
    paddingHorizontal: 40,
    paddingTop: 14,
  },
  row: {
    borderBottomColor: SharedStyles.Colors.Background,
    borderBottomWidth: 1,
    flexDirection: 'row',
    flex: 1,
  },
  userName: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  userEmail: {
    fontSize: fontSize.Small,
    color: SharedStyles.Colors.Grey,
    paddingTop: 1,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  userNameContainer: {
    marginLeft: 12,
    width: 216,
  },
  profilePictureContainer: {
    paddingTop: 2,
  },
  userOrganizationalUnitsText: {
    fontSize: fontSize.Regular,
    paddingTop: 1,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  userOrganizationalUnitRoleContainer: {
    marginLeft: 20,
    width: 264,
  },
  userRolesText: {
    maxWidth: 180,
    fontSize: fontSize.Small,
    color: SharedStyles.Colors.Grey,
    paddingTop: 1,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  permissionText: {
    fontSize: fontSize.Small,
    color: SharedStyles.Colors.Grey,
    paddingTop: 1,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  iconContainer: {
    width: 24,
    height: 24,
  },
  point: {
    width: 5,
    height: 5,
    backgroundColor: SharedStyles.Colors.Grey,
    borderRadius: 5 / 2,
  },
  pointContainer: {
    marginTop: 8,
    marginLeft: 3,
    marginRight: 3,
  },
  rightTextContainer: {
    position: 'absolute',
    right: 0,
    top: 2,
    width: 220,
  },
  userDidntRespondText: {
    color: SharedStyles.Colors.Grey,
    fontSize: 12,
    textAlign: 'right',
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  userDidntRespondButtons: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  sendInvitationAgainText: {
    paddingTop: 1,
    fontSize: 12,
    marginRight: SharedStyles.Spacings.Small,
    textAlign: 'right',
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  cancelInvitationText: {
    paddingTop: 1,
    fontSize: fontSize.Small,
    textAlign: 'right',
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  rightButtonContainer: {
    position: 'absolute',
    right: 0,
    top: 6,
  },
  rightButton: {
    height: 23,
    justifyContent: 'center',
    width: 96,
    alignItems: 'center',
  },
  removeAccessTextContainer: {
    width: 226,
  },
  removeAccessText: {
    color: SharedStyles.Colors.Grey,
    fontSize: fontSize.Small,
    textAlign: 'right',
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  inviteButtonText: {
    fontSize: fontSize.Small,
    ...SharedStyles.TextFontStyles.LatoBold,
  },
});
