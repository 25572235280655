import { Colors, Spacings, TextFontStyles } from 'shared/styles';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    backgroundColor: Colors.White,
    paddingTop: Spacings.Medium * 2,
    paddingLeft: Spacings.Large,
  },
  middleContainer: {},
  bodyText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 13,
    marginBottom: Spacings.Large - Spacings.Unit,
  },
  boldBodytext: {
    ...TextFontStyles.LatoBlack,
    fontSize: 32,
    marginBottom: Spacings.Standard - Spacings.Unit,
  },
  topContainer: {
    alignItems: 'flex-start',
    marginBottom: Spacings.Standard * 4 + Spacings.Unit,
  },
  connectButton: {
    width: 144,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.Yellow,
    justifyContent: 'center',
    marginTop: Spacings.Standard * 6 + Spacings.Unit,
  },
  connectButtonDisabled: {
    width: 144,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.GreyLight,
    justifyContent: 'center',
    marginTop: Spacings.Standard * 6 + Spacings.Unit,
  },
  connectText: {
    ...TextFontStyles.LatoBold,
    fontSize: 16,
    color: Colors.White,
  },
});

export default styles;
