import React from 'react';
import { Platform, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { WebEllipsisText } from './WebEllipsisText';
import { MobileEllipsisText } from './MobileEllipsisText';
import * as API from 'shared/backend-data';

export enum Position {
  TOP = 'TOP',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM',
}

export interface IEllipsisWithTooltip {
  type?: 'name';
  text: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  toolTipContainerStyle?: StyleProp<ViewStyle>;
  toolTipTextStyle?: StyleProp<TextStyle>;
  mouseHoverText?: StyleProp<TextStyle>;
  mouseHoverTextContainer?: StyleProp<ViewStyle>;
  position?: Position;
  disableToolTip?: boolean;
  iconType?: string;
  tagPath?: [API.TreeDataType | API.DataType.SHIFT, string[]];

  handleHover?: (isHover: boolean) => void;
  onMount?: () => void;
}

export const EllipsisWithTooltip: React.FC<IEllipsisWithTooltip> = props => {
  return Platform.OS === 'web' ? <WebEllipsisText {...props} /> : <MobileEllipsisText {...props} />;
};
