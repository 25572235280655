import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, InteractionManager } from 'react-native';
import { Badge } from 'shared/ui-component/Badge';
import * as API from 'shared/backend-data';
import Styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles';
import Aigle from 'aigle';
import logger from 'shared/util/Logger';
import { ToolTipWeb } from '../../ui-component/ToolTip/ToolTipWeb';

const shiftIcon = require('shared/assets/svg/icon.shift.svg').default;

interface Props {
  shiftIds: string[];
  selectedShiftIds: string[];
  shiftBadgeStates?: Map<string, boolean>;
  handleSelection: (shiftId: string) => void;
  showHover?: boolean;
}

export const InputShiftsList: React.FC<Props> = props => {
  const { shiftIds, selectedShiftIds, shiftBadgeStates, showHover, handleSelection } = props;

  const [shifts, setShifts] = useState<API.Shift[]>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      await _setShifts();
    });
  }, [shiftIds]);

  function renderBadge(shift: API.Shift): JSX.Element | null {
    return shiftBadgeStates ? (
      <Badge
        style={Styles.shiftBadge}
        dotColor={shiftBadgeStates.get(shift.id) ? Colors.Green : Colors.Red}
      />
    ) : null;
  }

  function renderShifts(): JSX.Element {
    return (
      <View style={Styles.shiftsContainer}>
        {shifts &&
          shifts.map(shift => {
            return (
              <TouchableOpacity
                key={shift.id}
                onPress={() => handleSelection(shift.id)}
                style={Styles.shiftContainer}
              >
                {renderBadge(shift)}
                {showHover ? (
                  <ToolTipWeb
                    title={shift.name}
                    toolTipTextStyle={Styles.toolTipTextStyle}
                    style={Styles.toolTipContainer}
                    numberOfLines={1}
                    component={
                      <IconSVG
                        svgComponent={shiftIcon}
                        color={Colors.White}
                        containerStyle={[
                          Styles.shiftIcon,
                          {
                            backgroundColor: selectedShiftIds.includes(shift.id)
                              ? shift.color
                              : Colors.GreyLighter,
                          },
                        ]}
                      />
                    }
                    isInnerHover
                  />
                ) : (
                  <IconSVG
                    svgComponent={shiftIcon}
                    color={Colors.White}
                    containerStyle={[
                      Styles.shiftIcon,
                      {
                        backgroundColor: selectedShiftIds.includes(shift.id)
                          ? shift.color
                          : Colors.GreyLighter,
                      },
                    ]}
                  />
                )}
              </TouchableOpacity>
            );
          })}
      </View>
    );
  }

  async function _setShifts(): Promise<API.Result<void>> {
    const _shifts: API.Shift[] = [];

    await Aigle.map(shiftIds, async shiftId => {
      const shift = await API.getShift(shiftId);
      if (API.isFailure(shift)) {
        logger.warn(shift);
        return;
      }

      _shifts.push(shift);
    });

    setShifts(_shifts);
  }

  return renderShifts();
};
