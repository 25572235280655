import * as API from 'shared/backend-data';
import {
  CastByType,
  ItemsQueries,
  DataType,
  DataTypeUnknown,
  FetchPolicy,
  GraphQLResult,
  AppSyncGraphQLError,
} from 'shared/backend-data';
import { Result } from './Failure';
import { loggerAPI as logger } from 'shared/util/Logger';
import * as GQL from './gql';
import * as _ from 'lodash-es';
import { enumKeys } from '../util-ts/Functions';
import { DataLayer } from './DataLayer';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { QueryCache } from './factoryCache/QueryCache';

type V_withoutFilter<
  T extends ItemsQueries<D>['type'],
  D extends DataType | DataTypeUnknown,
> = Omit<CastByType<ItemsQueries<D>, T>['variables'], 'filter'>;

/**
 * Create a dummy QueryResult
 * @param data
 * @returns
 */
function createDefaultQueryResult<D>(data: D, errors?: AppSyncGraphQLError): GraphQLResult<D>;
function createDefaultQueryResult<D>(errors?: AppSyncGraphQLError): GraphQLResult<undefined>;
function createDefaultQueryResult<D>(
  data?: D,
  error?: AppSyncGraphQLError,
): GraphQLResult<D | undefined> {
  const result: GraphQLResult<D | undefined> = {
    data,
  };
  if (error) result.errors = [error];

  return result;
}

/**
 * Try to compute a Query with Filter from the cache.
 * Returns null in case the reference query (the one without filter) doesn't exist or is not valid.
 * @param type
 * @param queryWithoutFilter
 * @param queryFilter
 */
async function tryToFilterItemsQueryFromQueryCache<
  T extends ItemsQueries<D>['type'],
  D extends DataType | DataTypeUnknown,
>(
  /**
   * Test types by removing all the 'as any' inside the function, replace T by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  type: T,
  queryWithoutFilter: {
    query: TypedDocumentNode<CastByType<ItemsQueries<D>, typeof type>['result']>;
    variables: V_withoutFilter<typeof type, D>;
    fetchPolicy: FetchPolicy;
  },
  queryFilter: API.ModelFactoryFilterInput,
): Promise<GraphQLResult<CastByType<ItemsQueries<D>, typeof type>['result'] | null>> {
  if (DataLayer.cacheOptions.enableUpdates?.enableCache?.enableQueryCache)
    return createDefaultQueryResult(null);

  const dataType = queryFilter.dataType?.eq;
  
  
  if (dataType) {
    let _variables;
    let _filter = _.cloneDeepWith(queryFilter);
    delete _filter.dataType;
    if (API.isListFactorys(type)) {
      _variables = queryWithoutFilter.variables as V_withoutFilter<typeof type, D>;

      if (_variables.sk?.eq) {
        logger.warn(
          'PERF: you shall call getFactory(pk, sk) rather than listFactory with variables:',
          _variables,
        );
      }

      if (_variables.sk) _filter['sk'] = _variables.sk;
    } else if (API.isItemsByData(type)) {
      _variables = queryWithoutFilter.variables as V_withoutFilter<typeof type, D>;

      if (_variables.data) _filter['data'] = _variables.data;
    } else if (API.isItemsByType(type)) {
      logger.warn(
        'PERF: you shall call itemsByType(pk, dataType) without a dataType filter:',
        queryWithoutFilter.variables,
        queryFilter,
      );
    } else {
      logger.debug(
        'QueryType not supported in tryToFilterItemsQueryFromQueryCache(): ' + type,
        queryWithoutFilter.variables,
        queryFilter,
      );
      return createDefaultQueryResult(null);
    }

    if (_variables) {
      const queryItemsByTypeOptions = {
        query: GQL.itemsByTypeGql(dataType),
        fetchPolicy: queryWithoutFilter.fetchPolicy,
        variables: {
          pk: _variables.pk ?? null,
          dataType: { eq: dataType },
          sortDirection: _variables.sortDirection ?? null,
          limit: _variables.limit ?? null,
          nextToken: _variables.nextToken ?? null,
        },
      };
      const itemsByTypeQuery = (await tryToFilterItemsQueryFromQueryCache(
        'itemsByType',
        queryItemsByTypeOptions,
        _filter,
      )) as GraphQLResult<CastByType<ItemsQueries<D>, 'itemsByType'>['result'] | null>;
      if (itemsByTypeQuery) {
        if (!itemsByTypeQuery.data) return createDefaultQueryResult(null);

        const items = itemsByTypeQuery.data.itemsByType;
        return createDefaultQueryResult({
          listFactorys: items,
          itemsByData: items,
          itemsByType: items,
          allFactoriesByType: items,
          syncFactories: items,
        });
      }
    }

    
  }

  const factories = QueryCache.getQueryCache().getQueryResult(type, queryWithoutFilter.variables);
  if (!factories) return createDefaultQueryResult(null);

  const factories2 = applyFilter(factories, queryFilter);
  if (factories2 === null) return createDefaultQueryResult(null);

  if (queryWithoutFilter.variables.limit) {
    logger.debug('Limit was not considered, return result contains all items.');
  }

  return createDefaultQueryResult({
    listFactorys: factories2,
    itemsByType: factories2,
    itemsByData: factories2,
    allFactoriesByType: factories2,
    syncFactories: factories2,
  });
}

/**
 * Extract filter from the query and return the query without the filter and the filter
 * @param queryOptions
 */
function splitQueryAndFilter<
  T extends ItemsQueries<D>['type'],
  D extends DataType | DataTypeUnknown,
>(
  /**
   * Test types by removing all the 'as any' inside the function, replace T by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  type: T,
  queryOptions: {
    query: TypedDocumentNode<CastByType<ItemsQueries<D>, typeof type>['result']>;
    variables: CastByType<ItemsQueries<D>, typeof type>['variables'];
    fetchPolicy: FetchPolicy;
  },
): [
  {
    query: TypedDocumentNode<CastByType<ItemsQueries<D>, typeof type>['result']>;
    variables: V_withoutFilter<typeof type, D>;
    fetchPolicy: FetchPolicy;
  },
  API.ModelFactoryFilterInput | null,
] {
  if (API.isAllFactoriesByType(type)) {
    return [queryOptions as any, null]; 
  } else {
    const filter = (
      queryOptions.variables as CastByType<
        ItemsQueries<D>,
        Exclude<ItemsQueries<D>['type'], 'allFactoriesByType'>
      >['variables']
    ).filter;

    if (!filter) return [queryOptions as any, null]; 

    return [
      {
        ...queryOptions,
        variables: _.omit(queryOptions.variables as any, 'filter') as any, 
      },
      filter,
    ];
  }
}

export function applyFilter<T extends DataType | DataTypeUnknown, V extends API.Factory<T>>(
  factories: V[],
  filter: API.ModelFactoryFilterInput,
): V[] | null;
export function applyFilter<T extends DataType | DataTypeUnknown>(
  factoryConnection: API.ModelFactoryConnection<T> | null | undefined,
  filter: API.ModelFactoryFilterInput,
): API.ModelFactoryConnection<T> | null;
/**
 * Filter the given factoryConnection according to the given filter
 * Return null if the filtering could not be applied or if the factoryConnection is null or its items are null)
 * @param factoryConnection
 * @param filter
 * @returns
 */
export function applyFilter<T extends DataType | DataTypeUnknown, V extends API.Factory<T>>(
  factoryConnection: V[] | API.ModelFactoryConnection<T> | null | undefined,
  filter: API.ModelFactoryFilterInput,
): V[] | API.ModelFactoryConnection<T> | null {
  if (Array.isArray(factoryConnection)) {
    return _applyFilter(factoryConnection, filter);
  }

  if (!factoryConnection || !factoryConnection.items) return null;

  if (factoryConnection.nextToken) {
    logger.warn(
      'tryComputeQueryFromCache canceled because all items are not stored inside the cache and filtering on it will return partial results.',
      factoryConnection,
      filter,
    );
    return null;
  }

  const filteredItems = _applyFilter(factoryConnection.items, filter);
  if (!filteredItems) return filteredItems;

  return {
    ...factoryConnection,
    items: filteredItems,
  };
}

function _applyFilter<T extends DataType | DataTypeUnknown, V extends API.Factory<T>>(
  factories: V[],
  filter: API.ModelFactoryFilterInput,
): V[] | null {
  if (filter.and || filter.or || filter.not || filter.userGroup) {
    logger.warn('tryComputeQueryFromCache not supporting AND | OR | NOT | UserGroup filter yet');
    return null;
  }

  const filterFields = enumKeys(filter);
  return factories.filter(factory => {
    if (!factory) return false;

    return filterFields.every(filterField => {
      switch (filterField) {
        case 'pk':
          return filterIDOrIDKey(factory.pk, filter[filterField]);
        case 'sk':
          return filterIDOrIDKey(factory.sk, filter[filterField]);
        case 'dataType':
          return filterDataType(factory.dataType, filter[filterField]);
        case 'data':
          return filterStringOrStringKey(factory.data!, filter[filterField]);
        case 'createdAt':
          return filterStringOrStringKey(factory.createdAt!, filter[filterField]);
        case 'owner':
          return filterStringOrStringKey(factory.owner!, filter[filterField]);
        case 'updatedAt':
          return filterStringOrStringKey(factory.updatedAt!, filter[filterField]);
        case 'updatedBy':
          return filterStringOrStringKey(factory.updatedBy!, filter[filterField]);
        case 'tagSearch':
          return filterStringOrStringKey(factory.tagSearch!, filter[filterField]);
        default:
          logger.error(
            `filter property ${filterField} not supported yet. Returned result might be wrong!`,
          );
          return true;
      }
    });
  });
}

/**
 * Make a copy of the given factoryConnection after applying the given field filter on its items.
 * Return null if the filtering could not be applied or if the factoryConnection is null or its items are null)
 * @param factories
 * @param keyCondition
 * @returns
 */
export function applyKeyCondition<T extends DataType | DataTypeUnknown>(
  factories: API.Factory<T>[],
  keyCondition: {
    sk?: API.ModelIDKeyConditionInput | null;
    data?: API.ModelStringKeyConditionInput | null;
    dataType?: API.ModelStringKeyConditionInput | null;
  },
): API.Factory<T>[] {
  return factories.filter(factory => {
    const filterFields = Object.keys(keyCondition) as Array<keyof typeof keyCondition>;

    return filterFields.every(filterField => {
      switch (filterField) {
        case 'sk':
          return filterIDOrIDKey(factory.sk, keyCondition[filterField]);
        case 'dataType':
          return filterStringOrStringKey(factory.dataType, keyCondition[filterField]);
        case 'data':
          return filterStringOrStringKey(factory.data!, keyCondition[filterField]);
        default:
          logger.error(
            `filter property ${filterField} not supported yet. Returned result might be wrong!`,
          );
          return true;
      }
    });
  });
}





/**
 * Return true if the filter condition is fulfilled
 * @param id field to test
 * @param modelIDInput can also pass ModelIDKeyConditionInput since ModelIDInput extends ModelIDKeyConditionInput
 */
function filterIDOrIDKey(id: string, modelIDInput: API.ModelIDInput | null | undefined): boolean {
  return filterStringOrStringKey(id, modelIDInput);
}

/**
 * Return true if the filter condition is fulfilled
 * @param dataType field to test
 * @param modelDataTypeInput
 */
function filterDataType(
  dataType: API.DataType,
  modelDataTypeInput: API.ModelDataTypeInput | null | undefined,
): boolean {
  if (!modelDataTypeInput) return true;

  if (modelDataTypeInput.eq) {
    return dataType === modelDataTypeInput.eq;
  } else if (modelDataTypeInput.ne) {
    return dataType !== modelDataTypeInput.ne;
  } else {
    logger.error('filterDataType property not supported yet. Returned result might be wrong!');
    return true;
  }
}

/**
 * Return true if the filter condition is fulfilled
 * @param s field to test
 * @param modelStringInput can also pass ModelStringKeyConditionInput since ModelStringInput extends ModelStringKeyConditionInput
 */
function filterStringOrStringKey(
  s: string | null,
  modelStringInput: API.ModelStringInput | null | undefined,
): boolean {
  if (!modelStringInput) return true;

  if (modelStringInput.attributeExists) {
    return s !== null;
  } else if (modelStringInput.attributeType) {
    return filterCheckAttributeType(s, modelStringInput.attributeType);
  } else if (modelStringInput.beginsWith) {
    return s ? s.startsWith(modelStringInput.beginsWith) : false;
  } else if (modelStringInput.between) {
    if (s === null) {
      if (modelStringInput.between.length === 0) return true;
      if (modelStringInput.between.length === 1 && modelStringInput.between[0] === null)
        return true;
      if (
        modelStringInput.between.length >= 2 &&
        modelStringInput.between[0] === null &&
        modelStringInput.between[1] === null
      )
        return true;
      return false;
    } else {
      if (modelStringInput.between.length === 0) return true;
      if (
        modelStringInput.between.length === 1 &&
        modelStringInput.between[0] &&
        s >= modelStringInput.between[0]
      )
        return true;
      if (
        modelStringInput.between.length >= 2 &&
        modelStringInput.between[0] &&
        s >= modelStringInput.between[0] &&
        modelStringInput.between[1] &&
        s <= modelStringInput.between[1]
      )
        return true;
      return false;
    }
  } else if (modelStringInput.contains) {
    return s ? s.includes(modelStringInput.contains) : false;
  } else if (modelStringInput.eq) {
    return s === modelStringInput.eq;
  } else if (modelStringInput.ge) {
    if (s === null) {
      return s === modelStringInput.ge;
    } else {
      if (modelStringInput.ge === null) return false;
      return s >= modelStringInput.ge;
    }
  } else if (modelStringInput.gt) {
    if (s === null) {
      return s === modelStringInput.gt;
    } else {
      if (modelStringInput.gt === null) return false;
      return s > modelStringInput.gt;
    }
  } else if (modelStringInput.le) {
    if (s === null) {
      return s === modelStringInput.le;
    } else {
      if (modelStringInput.le === null) return false;
      return s <= modelStringInput.le;
    }
  } else if (modelStringInput.lt) {
    if (s === null) {
      return s === modelStringInput.lt;
    } else {
      if (modelStringInput.lt === null) return false;
      return s < modelStringInput.lt;
    }
  } else if (modelStringInput.ne) {
    return s !== modelStringInput.ne;
  } else if (modelStringInput.notContains) {
    return s ? !s.includes(modelStringInput.notContains) : false;
  } else if (modelStringInput.size) {
    logger.error('filterString property size not supported yet. Returned result might be wrong!');
    return true;
  } else {
    logger.error('filterString property not supported yet. Returned result might be wrong!');
    return true;
  }
}

function filterCheckAttributeType(
  attribute: any | null,
  attributeType: API.ModelAttributeTypes | null,
): boolean {
  switch (attributeType) {
    case null:
    case API.ModelAttributeTypes._null:
      return attribute === null;
    case API.ModelAttributeTypes.bool:
      return _.isBoolean(attribute);
    case API.ModelAttributeTypes.list:
      return _.isArray(attribute);
    case API.ModelAttributeTypes.map:
      return _.isMap(attribute);
    case API.ModelAttributeTypes.number:
      return _.isNumber(attribute);
    case API.ModelAttributeTypes.numberSet:
      if (_.isSet(attribute)) {
        for (const value of attribute.values()) {
          return _.isNumber(value);
        }
        return true;
      }
      return false;
    case API.ModelAttributeTypes.string:
      return _.isString(attribute);
    case API.ModelAttributeTypes.string:
      if (_.isSet(attribute)) {
        for (const value of attribute.values()) {
          return _.isString(value);
        }
        return true;
      }
      return false;

    default:
      logger.error('Cannot detect binary/binarySet type yet. Returned result might be wrong!');
      return true;
  }
}


/**
 * Return true if the filter condition is fulfilled
 * @param int field to test
 * @param modelIntInput
 */
function filterInt(
  int: number | null,
  modelIntInput: API.ModelIntInput | null | undefined,
): boolean {
  if (!modelIntInput) return true;

  if (modelIntInput.attributeExists) {
    return int !== null;
  } else if (modelIntInput.attributeType) {
    return filterCheckAttributeType(int, modelIntInput.attributeType);
  } else if (modelIntInput.between) {
    if (int === null) {
      if (modelIntInput.between.length === 0) return true;
      if (modelIntInput.between.length === 1 && modelIntInput.between[0] === null) return true;
      if (
        modelIntInput.between.length >= 2 &&
        modelIntInput.between[0] === null &&
        modelIntInput.between[1] === null
      )
        return true;
      return false;
    } else {
      if (modelIntInput.between.length === 0) return true;
      if (
        modelIntInput.between.length === 1 &&
        modelIntInput.between[0] &&
        int >= modelIntInput.between[0]
      )
        return true;
      if (
        modelIntInput.between.length >= 2 &&
        modelIntInput.between[0] &&
        int >= modelIntInput.between[0] &&
        modelIntInput.between[1] &&
        int <= modelIntInput.between[1]
      )
        return true;
      return false;
    }
  } else if (modelIntInput.eq) {
    return int === modelIntInput.eq;
  } else if (modelIntInput.ge) {
    if (int === null) {
      return int === modelIntInput.ge;
    } else {
      if (modelIntInput.ge === null) return false;
      return int >= modelIntInput.ge;
    }
  } else if (modelIntInput.gt) {
    if (int === null) {
      return int === modelIntInput.gt;
    } else {
      if (modelIntInput.gt === null) return false;
      return int > modelIntInput.gt;
    }
  } else if (modelIntInput.le) {
    if (int === null) {
      return int === modelIntInput.le;
    } else {
      if (modelIntInput.le === null) return false;
      return int <= modelIntInput.le;
    }
  } else if (modelIntInput.lt) {
    if (int === null) {
      return int === modelIntInput.lt;
    } else {
      if (modelIntInput.lt === null) return false;
      return int < modelIntInput.lt;
    }
  } else if (modelIntInput.ne) {
    return int !== modelIntInput.ne;
  } else {
    logger.error('filterInt property not supported yet. Returned result might be wrong!');
    return true;
  }
}
