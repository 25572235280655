export * from './Training';
import * as trainingUtils from './Training';
import * as API from 'shared/backend-data';
import { Failure, WorkstationWorkerLevels } from 'shared/backend-data';
import {
  trainingSessionErrorMessage,
  TrainingSessionWarning,
} from 'shared/util/TrainingSessionWarningModal';
import { ModalUtils } from '../ui-component/Modal';
import * as _ from 'lodash-es';
import { UserPreferenceKeys_SkillMgtApp } from '../skillmgt/SkillmgtConstants';
import { DropDownWithWorkerLevelOption } from '../ui-component/DropDown/DropDownWithWorkerLevel';
import i18n from 'i18next';
import { WorkerDropDownOptionWithDetails } from 'shared/ui-component/DropDown/DropDown';

export function displayTrainingSessionDuplicateVetoModal(
  failure: Failure,
  dataType?: API.DataType,
) {
  ModalUtils.showVetoModal(
    trainingSessionErrorMessage(TrainingSessionWarning.TrainingSessionDuplicate),
    failure,
    undefined,
    dataType,
  );
}

export async function deleteTrainingTagWithErrorModalHandling(trainingTagId: string) {
  const factory = await API.deleteFactoryBusinessObject<API.DataType.TRAININGTAG>(trainingTagId);
  if (API.isFailure(factory)) {
    if (API.isFailureType(factory, 'DeletionVeto')) {
      ModalUtils.showVetoModal(i18n.t('alex:vetoModal.deleteTrainingTagNotPermitted'), factory);
    }
    return factory;
  }

  return factory;
}

/**
 * Filter the given workers that are mentors on the given workstation.
 * The external trainer is systematically added to the list of mentors.
 * @param workers
 * @param workstationId
 * @returns
 */
export async function filterMentorsForDropdown(
  workers: DropDownWithWorkerLevelOption[],
  workstationId: string,
): Promise<API.Result<API.Modify<DropDownWithWorkerLevelOption, { value: API.Worker }>[]>> {
  const highestReachableLevel = await API.getHighestReachableLevelOnWorkstation(workstationId);

  const mentorMinLevel = await API.getUserPreference<WorkstationWorkerLevels>(
    UserPreferenceKeys_SkillMgtApp.MentorMinLevel,
  );
  if (API.isFailure(mentorMinLevel)) return mentorMinLevel;

  let levelToFilterOn: API.WorkstationWorkerLevels;
  if (mentorMinLevel) {
    if (highestReachableLevel && highestReachableLevel < mentorMinLevel)
      levelToFilterOn = highestReachableLevel;
    else levelToFilterOn = mentorMinLevel;
  } else {
    levelToFilterOn = API.WorkstationWorkerLevels.LEVEL3;
  }

  const result = _.filter(workers, worker => worker.level >= levelToFilterOn).map(worker => {
    return {
      ...worker,
      value: worker.value,
    };
  });

  const extrernalTrainer = await API.getWorker(API.externalTrainerWorkerId);
  if (API.isFailure(extrernalTrainer)) {
    API.logger.error("External Trainer doesn't exists on tenant. Please create it back!");
  } else
    result.push({
      key: extrernalTrainer.id,
      label: extrernalTrainer.name,
      level: API.WorkstationWorkerLevels.LEVEL4,
      disabled: false,
      value: extrernalTrainer,
    });

  return result;
}

export function getExternalTrainerLevel(): API.WorkstationWorkerLevels {
  return API.WorkstationWorkerLevels.LEVEL4;
}
