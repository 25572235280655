import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { renderImage } from 'shared/util/RenderImage';
import { styles } from './styles';
import * as API from 'shared/backend-data';

interface Props {
  workers: API.Worker[];
  imageSize: number;
  overlapSize: number;
  profilesShownNumber: number;
  onPress: () => void;
}

export const UsersProfileDisplayer: React.FC<Props> = props => {
  const { workers, profilesShownNumber, imageSize, overlapSize } = props;
  return (
    <View style={styles.container}>
      {workers.map((worker, index) => (
        <View
          key={worker.id}
          style={[styles.profilePictureContainer, { right: index * (imageSize - overlapSize) }]}
        >
          {index === 0 && workers.length > profilesShownNumber ? (
            <TouchableOpacity
              style={[
                styles.remainingUsersCountView,
                {
                  borderRadius: (imageSize + imageSize) / 2,
                  height: imageSize,
                  width: imageSize,
                },
              ]}
              onPress={props.onPress}
            >
              <Text style={styles.remainingUsersCount}>{`+${
                workers.length - profilesShownNumber
              }`}</Text>
            </TouchableOpacity>
          ) : (
            index <= profilesShownNumber && (
              <TouchableOpacity onPress={props.onPress}>
                {renderImage(worker.profilePicture, imageSize, worker.name)}
              </TouchableOpacity>
            )
          )}
        </View>
      ))}
    </View>
  );
};
