import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  View,
  Text,
  ScrollView,
  Image,
  Animated,
  TouchableOpacity,
  LayoutChangeEvent,
} from 'react-native';
import styles from './Style';
import { AuthenticationLayout } from 'shared/layout/authentication';
import { Platform } from 'react-native';
import { getLanguage, t } from 'shared/localisation/i18n';
import { useIsMounted } from 'shared/hooks/IsMounted';

interface Props extends RouteComponentProps { }

export const Authentication: React.FC<Props> = props => {
  const scrollRef = useRef<ScrollView>(null);
  const [scrollViewWidth, setScrollViewWidth] = useState<number>();
  const [scrollPosition, setScrollPosition] = useState<Animated.Value>(new Animated.Value(0));
  const isMounted = useIsMounted();

  const locale = getLanguage().locale;
  const photos = [
    require('../../assets/welcome/' + locale + '_01.png'),
    require('../../assets/welcome/' + locale + '_02.png'),
    require('../../assets/welcome/' + locale + '_03.png'),
    require('../../assets/welcome/' + locale + '_04.png'),
  ];

  let adTexts: string[] = [];
  if (Platform.OS === 'web')
    adTexts = t('alex:auth.advertisementText.web');
  else adTexts = t('alex:auth.advertisementText.mobile');
  
  return typeof window.orientation === 'undefined' ? (
    <View style={styles.rootContainer}>
      <View style={styles.appContainer}>
        <AuthenticationLayout />
      </View>
      <View style={styles.arcView} />
      <View style={styles.adContainer}>
        <ScrollView
          ref={scrollRef}
          style={styles.scrollViewContainer}
          onLayout={(event: LayoutChangeEvent) => {
            if (isMounted.current) {
              setScrollViewWidth(event.nativeEvent.layout.width);
            }
          }}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          scrollEventThrottle={16}
          onScroll={event => {
            const xOffset = event.nativeEvent.contentOffset.x;
            if (scrollViewWidth) {
              if (isMounted.current)
                setScrollPosition(new Animated.Value(xOffset / scrollViewWidth));
            }
          }}
        >
          {photos.map((photo, index) => {
            return (
              <View style={styles.photoScrollView}>
                <View style={[styles.scrollViewItem]} key={'View' + index}>
                  <Text style={styles.headertextStyle} key={'text' + index}>
                    {adTexts[index]}
                  </Text>
                  <Image source={photo} style={styles.imageStyle} key={'image' + index} />
                </View>
              </View>
            );
          })}
        </ScrollView>
        <View style={styles.scrollBar}>
          {photos.map((photo, index) => {
            let color = scrollPosition.interpolate({
              inputRange: [index - 1, index, index + 1], 
              outputRange: ['rgba(0,0,0,1)', 'rgb(252,210,0)', 'rgba(0,0,0,1)'],
              extrapolate: 'clamp', 
            });
            return (
              <TouchableOpacity
                key={'' + index}
                onPress={() => {
                  if (isMounted.current)
                    scrollRef.current?.scrollTo({
                      x: scrollViewWidth ? scrollViewWidth * index : 0,
                    });
                }}
              >
                <Animated.View
                  style={[styles.animatedView, { backgroundColor: color }]}
                  key={index}
                />
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </View>
  ) : (
    <View style={styles.mobileContainer}>
      <AuthenticationLayout />
    </View>
  );
};
