import React, { useState } from 'react';
import { InputList, InputListProps } from 'shared/ui-component/Input/InputList';
import { Tag } from 'shared/ui-component/Input/InputTag';
import { ModalUtils } from 'shared/ui-component/Modal';
import * as API from 'shared/backend-data/index';

export interface TagInputListProps extends InputListProps {
  title?: string | undefined;
  onTagEdit?: ((tag: Tag) => Promise<API.Result<void>>) | undefined;
  onTagDelete?: ((tag: Tag) => Promise<API.Result<void>>) | undefined;
  onTagCreate?: ((text: string) => Promise<API.Result<Tag>>) | undefined;
}

export const TagInputList: React.FC<TagInputListProps> = props => {
  const [tagModalOpened, setTagModalOpened] = useState<boolean>(false);
  const [addedTag, setAddedTag] = useState<Tag>();

  const modal = ModalUtils.useModal();

  async function editTagHandler(tag: Tag) {
    setTagModalOpened(false);
    if (props.onTagEdit) await props.onTagEdit(tag);
  }

  async function deleteTagHandler(tag: Tag) {
    setTagModalOpened(false);
    if (props.onTagDelete) await props.onTagDelete(tag);
  }

  async function createTagHandler(label: string) {
    setTagModalOpened(false);
    if (props.onTagCreate) {
      const createdTag = await props.onTagCreate(label);
      if (API.isFailure(createdTag)) return createdTag;
      setAddedTag(createdTag);
    }
  }

  function openTagModal(tag: Tag) {
    setTagModalOpened(true);

    modal.displayModal(
      ModalUtils.TagModalConfig({
        editTag: {
          tag: tag,
          editHandler: editTagHandler,
        },
        deleteTag: {
          tag: tag,
          deleteHandler: deleteTagHandler,
        },
        placeholder: props.title,
        trackModalOpeningState: setTagModalOpened,
      }),
    );
  }

  return (
    <InputList
      options={props.options}
      initialSelection={props.initialSelection}
      placeholder={props.placeholder}
      optionIcon={props.optionIcon}
      openInputList={props.openInputList}
      handleSelection={props.handleSelection}
      createNewOption={
        props.createNewOption && {
          createItemPlaceHolder: props.createNewOption.createItemPlaceHolder,
          createItemHandler: () => {
            setTagModalOpened(true);
            modal.displayModal(
              ModalUtils.TagModalConfig({
                placeholder: props.title,
                createHandler: createTagHandler,
                trackModalOpeningState: setTagModalOpened,
              }),
            );
          },
          createItemIcon: props.createNewOption.createItemIcon,
        }
      }
      createNewOptionShortcut={createTagHandler}
      newlyAddedOption={addedTag}
      keepInputListOpenOnOutsideClick={tagModalOpened}
      onOptionEdit={openTagModal}
      containerStyle={props.containerStyle}
      optionRowStyling={props.optionRowStyling}
      noResultMessage={props.noResultMessage}
    />
  );
};
