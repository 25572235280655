import { StyleSheet, Dimensions } from 'react-native';
import { Colors, createRoundedStyle, Spacings, TextFontStyles, fontSize } from 'shared/styles';
const height = Dimensions.get('window').height - 80;
const ModalWidth = 350;

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  outerContainer: {
    backgroundColor: Colors.LightBlue,
    minHeight: Dimensions.get('window').height - 60,
    width: '100%',
    paddingTop: 28,
    paddingHorizontal: 20,
    paddingBottom: 8,
  },
  innerScrollView: {
    backgroundColor: Colors.White,
    borderRadius: 8,
    flexDirection: 'column',
    shadowRadius: 2,
    shadowOpacity: 1,
    shadowColor: '#00000029',
  },
  activityIndicatorContainer: {
    paddingTop: Spacings.Big,
    height: Spacings.Big * 2,
    width: Spacings.Big * 2,
    alignSelf: 'center',
    marginTop: height / 2,
  },
  arrowIconContainer: {
    marginRight: Spacings.Medium,
  },
  headerContainer: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    height: 62.5,
    width: '100%',
    paddingLeft: 28,
    paddingVertical: 20,
    flexDirection: 'row',
    backgroundColor: Colors.White,
    zIndex: 0,
  },
  collabRowTouchable: {
    justifyContent: 'center',
  },
  collabRowName: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  collabBadge: { position: 'absolute', top: -2, left: 20 },
  body: {
    borderRadius: Spacings.Small,
    marginTop: Spacings.CardPadding + Spacings.Small,
    marginBottom: Spacings.Small,
    marginHorizontal: Spacings.CardPadding,
    padding: 0,
    backgroundColor: Colors.White,
  },
  header: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Medium,
    color: '#666666',
    textAlign: 'left',
    padding: 26,
    paddingTop: 20,
    paddingBottom: 0,
  },
  circleProfil: {
    width: 85,
    height: 85,
    borderRadius: 85 / 2,
    backgroundColor: '#78DBD9',
    marginRight: 30,
  },
  container: {
    zIndex: 10,
  },
  closeImg: {
    width: 10,
    height: 10,
    top: -17,
    left: -2,
  },
  labelLink: {
    color: '#78DBD9',
    width: '15%',
    margin: 20,
    marginBottom: 0,
  },
  firstColumnOrgUnitCellContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  firstColumnWorkerCellContainer: {
    marginLeft: Spacings.Medium,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  form: {
    flex: 1,
    flexDirection: 'row',
    padding: 26,
    paddingTop: 0,
    zIndex: 6,
  },
  separator: {
    marginTop: 8,
    marginBottom: 0,
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 1.2,
    width: '100%',
  },
  tabContainer: {
    width: '100%',
    flexWrap: 'wrap',
    marginRight: Spacings.Medium,
  },
  orgUnitInfoCell: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workerInfoCell: {
    flexDirection: 'row',
  },
  shiftsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: Spacings.Small,
  },
  tagInput: {
    flexDirection: 'row',
    zIndex: 999,
    width: width * 0.38,
    borderWidth: 1,
    borderColor: '#D6D6D6',
    backgroundColor: '#F1F3F4',
    borderRadius: 4,
    height: 35,
  },
  closeIcon: {
    alignSelf: 'flex-end',
    marginRight: 4,
  },
  tag: {
    marginLeft: 5,
    color: '#FFF',
    fontWeight: '500',
    backgroundColor: '#FCD200',
    padding: 4,
    paddingLeft: 5,
    paddingRight: 27,
    height: 26,
    marginTop: 4,
    borderRadius: 45,
  },
  label: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Small,
    color: Colors.MediumGray,
    marginRight: 10,
  },
  datepicker: {
    borderWidth: 1,
    padding: 1,
    borderRadius: 0.28571429,
    width: 140,
    height: 38,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    lineHeight: 14.4,
    color: '#666666',
  },
  iconPlus: {
    width: 12,
    height: 12,
    top: 1,
    marginRight: 6,
  },
  iconArrowBack: {
    width: 8,
    height: 13,
    top: 1,
    marginRight: 6,
  },
  submitBtn: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginRight: 25,
    marginBottom: 20,
  },
  profileImagePreviewContainer: {
    flexDirection: 'column',
    marginRight: 30,
  },
  setMarginTop: {
    marginTop: 20,
  },
  setMarginRight: {
    marginRight: 5,
  },
  trashIcon: {
    fontSize: 12,
    marginLeft: width * 0.02,
  },
  enRegisterButton: {
    backgroundColor: '#78dbd9',
    padding: 10,
    borderRadius: 5,
    width: 'auto',
    marginRight: Spacings.Medium,
  },
  contractContainer: {
    zIndex: 5,
    paddingLeft: width * 0.008,
    paddingBottom: height * 0.035,
  },
  unitesContainer: {
    
    zIndex: 3,
    paddingBottom: height * 0.035,
  },
  addUniteButtonContainer: {
    flexDirection: 'row',
    paddingLeft: width * 0.025,
    alignItems: 'center',
  },
  addIcon: {
    width: 12,
    height: 12,
  },
  addLabel: {
    color: '#78DBD9',
    marginLeft: width * 0.0034,
  },
  equipeContainer: {
    paddingLeft: width * 0.025,
    paddingTop: height * 0.02,
    paddingBottom: height * 0.035,
    zIndex: 5,
    width: '50%',
  },
  regionSiteActivityDropDownContainer: {
    flexDirection: 'row',
    paddingLeft: width * 0.025,
    paddingTop: height * 0.02,
    paddingBottom: height * 0.035,
    backgroundColor: Colors.White,
    zIndex: 4,
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  roleDropDownContainer: {
    paddingLeft: width * 0.04,
    flexDirection: 'row',
    alignItems: 'center',
  },
  footer: {
    width: '100%',
    backgroundColor: 'red',
  },
  addedSkill: {
    marginLeft: 45,
    width: 180,
    marginBottom: 5,
    flexDirection: 'row',
    height: 26,
    backgroundColor: '#FCD200',
    marginTop: 6,
    borderRadius: 45,
  },
  addedSkillText: {
    marginLeft: 5,
    color: '#FFF',
    fontWeight: '500',
    padding: 4,
    paddingLeft: 5,
    paddingRight: 5,
    width: 150,
  },
  removeSkillImg: {
    width: 15,
    height: 15,
    justifyContent: 'center',
    marginTop: 6,
    marginLeft: 2,
  },
  filterContainerWrapper: { zIndex: 1 },
  polyvalenceGridView: {
    alignItems: 'center',
    width: '35%',
  },
  personalInformation: {
    marginBottom: Spacings.CardPadding,
  },
  profilePicture: {
    height: 85,
    width: 85,
    borderRadius: 85 / 2,
    
  },
  trashIconLeft: { fontSize: 12, marginLeft: -10 },
  trashIconPointer: { fontSize: 12, marginLeft: 70 },
  circleProfileText: {
    marginTop: 12,
  },
  uploadImage: { width: 20, height: 20 },
  uploadLinkLabel: {
    color: Colors.White,
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: 12,
  },
  innerPersonalInformation: { flex: 1, flexDirection: 'column' },
  nameRow: { flexDirection: 'row', marginBottom: Spacings.CardPadding },
  innerContract: {
    flexDirection: 'row',
    padding: 26,
    paddingBottom: 0,
  },
  button: { color: Colors.Black, ...TextFontStyles.LatoRegular, fontSize: 14, paddingLeft: 77 },

  contractStartEndDate: { marginLeft: 25 },
  matriculeInput: { width: 200 },
  addInput: {
    width: 200,
    marginRight: 10,
  },
  phoneNumberInput: {
    width: 265,
  },
  inputContainer: { flexDirection: 'row' },
  firstName: { marginRight: 21 },
  familyName: { marginRight: 13 },
  iconContainer: {
    flexDirection: 'row',
    marginLeft: -Spacings.Small,
  },
  icon: {
    marginTop: Spacings.Unit,
    marginLeft: Spacings.Small,
  },
  circleView: {
    ...createRoundedStyle(6),
    backgroundColor: Colors.Yellow,
    marginTop: Spacings.Unit,
    marginLeft: -Spacings.Small,
  },
  tableText: {
    width: 'auto',
    textAlign: 'left',
    fontSize: fontSize.Regular,
    color: '#606060',
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
  },
  excelTouchable: { position: 'absolute', right: 23 },
  polyvalanceContainer: {
    alignItems: 'flex-start',
  },
  tableNumber: {
    fontSize: fontSize.XLarge,
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    paddingRight: Spacings.Unit,
  },
  tableNumberContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  alignItems: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabled: {
    color: Colors.GreyLight,
  },
  archiveErrorText: {
    marginTop: Spacings.CardPadding,
    marginLeft: Spacings.CardPadding,
    color: Colors.Red,
    fontSize: '12px',
    ...TextFontStyles.LatoRegular,
  },
  tableContainer: {
    marginLeft: Spacings.CardPadding,
    padding: 0,
    flex: 1,
    height: '100%',
  },
  tableOuterContainer: { flex: 1 },
  align: {
    flexDirection: 'row',
    paddingRight: Spacings.CardPadding,
    alignItems: 'center',
  },
  closeButtonIcon: {
    position: 'absolute',
    right: 12,
    top: 9,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.ModalBackground,
    paddingTop: height * 0.1,
    paddingBottom: height * 0.1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    backgroundColor: Colors.White,
    width: ModalWidth,
    height: 'auto',
    borderRadius: 8,
  },
  workerText: {
    fontSize: fontSize.Small,
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
  },
  workerName: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    color: Colors.Black,
  },
  workerNameContainer: {
    justifyContent: 'space-evenly',
    marginLeft: Spacings.xMedium,
    width: '100%',
  },
  contentContainer: {
    margin: Spacings.Large + Spacings.Unit,
    marginBottom: Spacings.Standard,
  },
  yellowButtonStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Medium,
  },
  inputText: {
    paddingTop: 14,
  },
  activateContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingTop: Spacings.Standard,
  },
  archiveDatePicker: {
    width: 116,
  },
  activateText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingBottom: Spacings.Small,
  },
  ellipsisTooltip: {
    paddingLeft: Spacings.Unit,
    width: '80%',
  },
  ouRootContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  workerRow: { flexDirection: 'row' },
  reasonDropDownStyle: { width: Spacings.Large * 7 + Spacings.Medium },
  reasonDropDownContainerStyle: { top: 74 },
  dateInputOuterContainer: { zIndex: -1 },
  dateInputInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  endDateDropDownContainerStyle: { right: 0, justifyContent: 'flex-end' },
  verticalDivider: { height: 70 },
  archivedEndDate: { right: 0, justifyContent: 'flex-end' },
  commentsContainer: { zIndex: -4 },
  buttonContainer: { zIndex: -1 },
  buttonStyle: { marginTop: 67, height: 36, justifyContent: 'center' },
  tableScrollView: {
    width: '100%',
    height: '100%',
  },
});

export default styles;
