import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Colors, fontSize } from 'shared/styles';

interface Props {
  percentage: number;
}

export const ProgressCircle: React.FC<Props> = props => {
  const { percentage } = props;

  return (
    <CircularProgressbar
      value={percentage}
      text={`${percentage}%`}
      styles={buildStyles({
        pathColor: Colors.Yellow,
        trailColor: Colors.GreyLight,
        textColor: Colors.Black,
        textSize: fontSize.XLarge,
      })}
      strokeWidth={15}
    />
  );
};
