import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { ModalStyles } from 'shared/styles/ModalStyles';
import { Spacings, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  modal: { width: '70%', minWidth: ModalStyles.width },

  body: { flex: 1, flexDirection: 'row' },

  
  leftPanelContainer: {
    minWidth: ModalStyles.inputWidthWithAnchor,
    marginRight: ModalStyles.bodyDivider,
    flexDirection: 'row',
  },
  leftPanelInputContainer: {
    width: ModalStyles.inputWidthWithAnchor,
  },
  minWidth: {
    width: 132,
  },
  workstationModalBottomButtonContainer: {
    position: 'absolute',
    bottom: Spacings.Unit * 5,
    right: Spacings.Unit * 7,
    flexDirection: 'row',
  },
  dateTimeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  durationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  descriptionContainer: {
    marginTop: Spacings.Medium,
  },

  
  rightPanelContainer: {
    minWidth: ModalStyles.inputWidthWithAnchor,
    flexGrow: 1,
    flexShrink: 1,
    paddingTop: Spacings.Unit,
    paddingBottom: Spacings.Large,
  },
  tabContentContainer: {
    flex: 1,
  },
  workerListContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: Spacings.CardPadding,
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workerName: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    paddingLeft: Spacings.Small,
  },
  disabled: {
    color: SharedStyles.Colors.Grey,
  },
  participantText: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },

  files: { maxWidth: '100%', flex: 1 },
  addWorker: { width: '100%' },
  addWorkerInput: { width: '100%' },
  verticalDivider: { height: 24 },
  workersListContainer: { width: '100%' },
  workerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Spacings.Small,
    paddingLeft: Spacings.Unit,
    paddingRight: Spacings.Unit / 2,
    flex: 1,
  },
  workerAvatar: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0.8,
  },
  horizontalDivider: { width: Spacings.Small },
  workerNameTextStyle: {
    ...TextFontStyles.LatoMedium,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Black,
  },
  skillsAcquiredContainer: { width: '100%' },
  skillListingRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: SharedStyles.Spacings.Small,
    minWidth: 280,
    paddingRight: SharedStyles.Spacings.Unit * 3,
    paddingLeft: SharedStyles.Spacings.xMedium,
    paddingTop: Spacings.Small,
    paddingBottom: Spacings.Small,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    borderRadius: 8,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
  },
  skillListingContainer: { paddingBottom: Spacings.Small, maxHeight: 200 },
  skillsListHeaderTextStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Unit * 5,
    paddingTop: SharedStyles.Spacings.Unit / 2,
    marginTop: Spacings.Medium,
  },
  skillNameContainer: { flexDirection: 'row', maxWidth: '85%', alignItems: 'flex-start' },
  skillIconContainer: {
    width: 32,
    height: 32,
    backgroundColor: SharedStyles.Colors.MediumGray,
    borderRadius: Spacings.Standard,
  },
  skillNameTextStyle: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoMedium,
    paddingLeft: Spacings.Small,
    marginTop: Spacings.Small,
    width: '100%',
  },
  contentOfTraining: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Regular,
    marginTop: Spacings.Small,
    display: 'flex',
    alignItems: 'center',
  },
  participantsHeader: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Regular,
  },
  checkBoxContainer: {
    flex: 0.2,
    alignItems: 'flex-end',
  },
  linkStyle: {
    color: SharedStyles.Colors.DarkBlue,
  },

  description: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Regular,
    width: 276,
  },
  descriptionTitle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Small,
    marginTop: Spacings.Medium,
    color: SharedStyles.Colors.Grey,
  },
  toolTipContainer: { marginTop: Spacings.Unit, marginLeft: Spacings.Standard },

  
  yellowButton: {
    paddingLeft: Spacings.Standard + Spacings.Medium,
  },
  iconContainer: {
    height: Spacings.Standard * 2,
    width: Spacings.Standard * 2,
    borderRadius: Spacings.Standard,
    backgroundColor: SharedStyles.Colors.Yellow,
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    height: Spacings.Unit * 6,
  },
  divider2: {
    height: Spacings.Unit * 5,
  },
  textButtonContainer: { flexDirection: 'row', alignItems: 'center' },
  textButtonDivider: { width: 36 },
  bottomButtonStyle: {
    backgroundColor: SharedStyles.Colors.Yellow,
    height: 32,
    paddingHorizontal: Spacings.Unit * 8,
  },
  bottomButtonTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Regular,
    color: SharedStyles.Colors.White,
  },
  toolTip: { bottom: 20, right: 15 },
});
export default styles;
