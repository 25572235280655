import React, { useState, useEffect } from 'react';
import styles from '../styles';
import { View, Text, TouchableOpacity } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import { TagExtended } from '../container';
import { Colors } from 'shared/styles';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { Atom } from 'shared/ui-component/Input/InputList/InputTag/atom';
import { logger } from 'shared/util/Logger';

const InfoIcon = require('shared/assets/svg/icon.info.svg').default;
const DownIcon = require('shared/assets/svg/icon.down.verysmall.svg').default;
const CloseIcon = require('shared/assets/svg/icon.close.svg').default;
const BookmarkIcon = require('shared/assets/svg/icon.star.svg').default;

interface Props {
  showFilterMenu: () => void;
  tags: TagExtended[];
  onRemoveTag: (tags: TagExtended[]) => void;
  disabled: boolean;
}

export const FilterButton: React.FC<Props> = props => {
  const { showFilterMenu, tags, onRemoveTag, disabled } = props;
  const [totalTags, setTotalTags] = useState<TagExtended[]>([]);
  const [tagsToDisplay, setTagsToDisplay] = useState<TagExtended[]>([]);

  useEffect(() => {
    const _tags = [...tags];
    setTotalTags(tags);
    const sortedTags = _tags.sort((value, value1) => {
      if (value.order !== undefined && value1.order !== undefined) {
        return value.order - value1.order;
      }

      return 0;
    });

    setTagsToDisplay(sortedTags.slice(0, 2));
  }, [tags]);

  function _onRemoveTag(tag: TagExtended) {
    const _index = totalTags.findIndex(_tag => _tag.key === tag.key);

    if (_index > -1) {
      if (tag.isActiveBookmarkTag) {
        totalTags[_index].isActiveBookmarkTag = false;
      } else {
        totalTags.splice(_index, 1);
      }
    } else {
      logger.warn('Error while finding tag');
    }

    setTotalTags(totalTags);
    setTagsToDisplay(totalTags.slice(0, 2));
    onRemoveTag(totalTags);
  }

  function renderItem(item: TagExtended, key: number) {
    return (
      <Atom
        value={item}
        index={key}
        style={{ maxWidth: 144, marginBottom: 0 }}
        handleTagDelete={() => _onRemoveTag(item)}
      />
    );
  }

  return (
    <TouchableOpacity
      style={[styles.textInputContainer, disabled && { opacity: 0.6 }]}
      onPress={showFilterMenu}
      disabled={disabled}
    >
      <View style={styles.tagContainer}>
        {tagsToDisplay?.map((tag, index) => renderItem(tag, index))}
        {totalTags.length <= 1 && (
          <Text style={styles.filterText}>{t('common:filters.filterData')}</Text>
        )}
      </View>

      <View style={styles.iconContainer}>
        {totalTags.length - 2 > 0 && (
          <View style={[styles.tagView, { paddingLeft: 0 }]}>
            <Text numberOfLines={1} style={styles.tagText}>
              +{totalTags.length - 2}
            </Text>
          </View>
        )}
        {!totalTags.length ? (
          <ToolTipWeb
            title={t('common:filters.tooltip')}
            style={styles.tooltipContainer}
            component={<IconSVG svgComponent={InfoIcon} color={Colors.GreyLight} />}
          />
        ) : null}
        <TouchableOpacity onPress={showFilterMenu} disabled={disabled}>
          <IconSVG svgComponent={DownIcon} />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};
