/** Do not import @enum RoutePaths, rather use @see RouteLocations that specify the state in addition of the path */
export enum RoutePaths {
  Home = '/',
  Auth = '/auth',

  MyFactory = '/myFactory',

  Workstations = '/myFactory/workstations',
  WorkstationProfile = '/myFactory/workstation',
  WorkstationProfileRoute = '/myFactory/workstation/:id',

  Skills = '/myFactory/skills',
  SkillProfile = '/myFactory/skill',
  SkillProfileRoute = '/myFactory/skill/:id',

  Workers = '/myFactory/workers',
  WorkerAdd = '/myFactory/workers/add',
  WorkerProfile = '/myFactory/worker',
  WorkerProfileRoute = '/myFactory/worker/:id',

  TrainingSessions = '/trainingSessions',
  TrainingSessionId = 'trainingSessionId',

  Trainings = '/myFactory/trainings',
  TrainingProfile = '/myFactory/training',
  TrainingProfileRoute = '/myFactory/training/:id',

  MyAccount = '/account',
  CompanySettings = '/companySettings',

  KPI = '/KPI',
  KPIOrgUnits = '/KPIOrgUnits', 
}
