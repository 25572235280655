import React, { useState, useEffect } from 'react';
import { Text, TouchableOpacity, InteractionManager, View } from 'react-native';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import { useIsMounted } from 'shared/hooks/IsMounted';
import Styles from './Styles';
import * as _ from 'lodash-es';
import { TrainingCardProps } from '../container/index';
import { SkillExpand } from './SkillExpand';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';

export const TrainingCardComponent: React.FC<TrainingCardProps> = props => {
  const { trainingVersionAndTraining, containerStyle, forceDisplaySkillNames } = props;

  const isMounted = useIsMounted();

  const [isPracticalTraining, setIsPracticalTraining] = useState<boolean>();

  const lectureIcon = require('shared/assets/svg/icon.lecture.svg').default;
  const practicalTrainingIcon = require('shared/assets/svg/icon.practTrain.svg').default;

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      handleTrainingType();
    });
  }, [props.forceDisplayIsPracticalTraining]);

  async function handleTrainingType() {
    if (!trainingVersionAndTraining[0].id) {
      setIsPracticalTraining(false);
    } else if (_.isBoolean(props.forceDisplayIsPracticalTraining)) {
      setIsPracticalTraining(props.forceDisplayIsPracticalTraining);
    } else {
      const isPractical = await API.isPracticalTrainingVersion(trainingVersionAndTraining[0].id);
      if (!isMounted.current) return;
      if (API.isFailure(isPractical)) return;
      setIsPracticalTraining(isPractical);
    }
  }

  return (
    <TouchableOpacity
      key={trainingVersionAndTraining[0].id}
      style={[Styles.trainingRowContainer, containerStyle ?? {}]}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          width: '100%',
          paddingRight: SharedStyles.Spacings.Unit,
        }}
      >
        <IconSVG
          svgComponent={isPracticalTraining ? practicalTrainingIcon : lectureIcon}
          color={SharedStyles.Colors.Black}
        />
        <Text numberOfLines={3} style={Styles.trainingNameTextStyle}>
          {capitalizeFirstLetter(trainingVersionAndTraining[1].name)}
        </Text>
      </View>
      <SkillExpand
        skillIds={trainingVersionAndTraining[0].skillIds}
        forceDisplaySkillNames={forceDisplaySkillNames}
        files={trainingVersionAndTraining[1].files}
      />
    </TouchableOpacity>
  );
};
