import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { Spacings, fontSize } from 'shared/styles';

const LevelColumnWidth: number = 216;

const styles = StyleSheet.create({
  workstationTableContainer: { width: '100%', height: '100%' },
  addWorkstationContainer: {
    backgroundColor: SharedStyles.Colors.GreyBackground,
    width: '100%',
    height: 68,
    paddingLeft: 34,
    paddingTop: SharedStyles.Spacings.Standard,
    paddingBottom: SharedStyles.Spacings.CardPadding,
    opacity: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  addWorkstationIconContainer: { marginRight: 10 },
  addWorkstationTextStyle: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoMedium,
    color: SharedStyles.Colors.BlackGrey,
    maxWidth: '50%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: 4,
  },
  workstationRowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workstationThumbContainer: { marginRight: 12 },
  workstationNameContainer: { width: '47.5%', zIndex: 0 },
  workstationWorkersCountContainer: { flexDirection: 'row', alignItems: 'center' },
  workstationWorkersCountNameContainer: { zIndex: 0 },
  workstationLevel4Container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workstationLevel4Actual: { width: '100%', zIndex: 0, flexDirection: 'row', alignItems: 'center' },
  workstationLevel4Minimum: {
    color: SharedStyles.Colors.Grey,
  },
  workstationLevel3Container: { flexDirection: 'row', alignItems: 'center' },
  workstationLevel3ActualTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.XLarge,
    marginRight: Spacings.Unit,
  },
  workstationLevel3Minimum: {
    color: SharedStyles.Colors.Grey,
  },
  workstationMissingWorkersTextStyle: {
    ...SharedStyles.TextFontStyles.LatoMedium,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
  },
  workersContainer: { width: '100%', height: '100%' },
  trainWorkerTextStyle: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoMedium,
    color: SharedStyles.Colors.Black,
    maxWidth: '50%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: 4,
  },
  workerNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 4,
  },
  workerNameInnerContainer: { width: '75%', zIndex: 0 },
  workerTagsContainer: { width: '100%', zIndex: 0 },
  workerPolyvalenceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workerPolyvalenceTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.XLarge,
  },
  workerPolyvalenceInnerTextStyle: {
    color: SharedStyles.Colors.Grey,
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoMedium,
  },
  workerTrainingsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workerTrainingsTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.XLarge,
    color: SharedStyles.Colors.Black,
  },
  workerTrainingsInnerTextStyle: {
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    ...SharedStyles.TextFontStyles.LatoMedium,
  },
  workerWorkerSkillsTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.XLarge,
    color: SharedStyles.Colors.Black,
  },
  workerSkillsTextStyle: {
    ...SharedStyles.TextFontStyles.LatoMedium,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    paddingLeft: 3,
  },
  requirementTableContainer: {
    width: '100%',
    height: '100%',
  },
  requirementTableTitleContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 1,
  },
  requirementTableExtraContainer: {
    width: 545,
    height: 32,
    borderRadius: 20,
    flexDirection: 'row',
    backgroundColor: SharedStyles.Colors.Background,
  },
  requirementTrainingsContainer: {
    flex: 1,
    paddingTop: SharedStyles.Spacings.Unit * 10,
    flexDirection: 'row',
    paddingLeft: SharedStyles.Spacings.Large,
  },
  requirementTableInnerContainer: {
    flex: 1,
    paddingTop: SharedStyles.Spacings.Unit * 10,
    flexDirection: 'row',
    paddingLeft: SharedStyles.Spacings.Large,
  },
  requirementTableInnerExtraContainer: {
    flex: 1,
    alignItems: 'flex-start',
    minWidth: LevelColumnWidth,
    marginRight: Spacings.CardPadding,
  },
  requirementTableLevel2LoadingBanner: {
    width: '100%',
    height: '100%',
    opacity: 0.6,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 999,
  },
  requirementTableLevel2Header: {
    flexDirection: 'row',
    marginBottom: SharedStyles.Spacings.Unit * 7,
    alignItems: 'center',
  },
  requirementTableLevel2HeaderTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.XXXLarge,
    justifyContent: 'center',
    paddingTop: 3,
    marginRight: 8,
  },
  requirementTableLevel2AddContainer: {
    width: '100%',
    opacity: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 8,
    marginBottom: 24,
  },
  requirementTableLevel2AddTextStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    color: SharedStyles.Colors.Black,
    maxWidth: '50%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: 3,
    fontSize: fontSize.Regular,
  },
  requirementTableLevel2ScrollView: {
    width: '100%',
    height: '100%',
    minWidth: 216,
  },
  skillCardContainer: {
    marginBottom: SharedStyles.Spacings.Medium,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    borderRadius: 8,
    paddingHorizontal: SharedStyles.Spacings.xMedium,
    paddingVertical: SharedStyles.Spacings.Small,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
  },
  requirementTableLevel2InheritedContainer: { height: 200, width: '100%' },
  requirementTableLevel2InheritedTextStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    marginBottom: SharedStyles.Spacings.Unit * 3,
  },
  requirementTableLevel2InheritedScrollView: { width: '100%', maxHeight: '40%' },
  workstationScreenContainer: {
    flex: 1,
    backgroundColor: SharedStyles.Colors.LightBlue,
    flexDirection: 'row',
  },
  workstationScreenTreeContainer: {
    minWidth: 224,
    height: '100%',
    backgroundColor: SharedStyles.Colors.White,
    borderRadius: 8,
    shadowColor: '#00000029',
    shadowOpacity: 1,
    shadowRadius: 2,
    alignItems: 'center',
    marginRight: 16,
    zIndex: 999,
    padding: 1,
  },
  workstationScreenRightPanelContainer: {
    flex: 1,
    backgroundColor: SharedStyles.Colors.White,
    borderRadius: 8,
    shadowColor: '#00000029',
    shadowOpacity: 1,
    shadowRadius: 2,
    minWidth: 570,
    zIndex: 998,
    marginLeft: SharedStyles.Spacings.CardPadding,
  },
  workstationScreenTabsContainer: {
    height: 60,
    backgroundColor: SharedStyles.Colors.White,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  workstationScreenTabsInnerContainer: { height: 60 },
  workstationScreenRightPanelInnerContainer: {
    height: '100%',
    width: '100%',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  workstationScreenRightPanelInnerContentContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  levelSkillTouchableContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacings.Medium,
    marginHorizontal: 2,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    borderRadius: 8,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    flex: 1,
    minWidth: 216,
  },
  levelSkillTouchableInnerContainer: {
    flexDirection: 'row',
    borderRadius: 3,
  },
  descriptionContainer: {
    flexWrap: 'wrap',
    paddingLeft: 52,
  },
  levelSkillNameTextStyle: {
    fontSize: SharedStyles.fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoRegular,
    color: SharedStyles.Colors.Black,
    justifyContent: 'center',
  },
  createFactoryContainer: { flex: 1, alignItems: 'center', paddingTop: 40, flexWrap: 'wrap' },
  tutoIconContainer: { marginBottom: 23 },
  createFactoryTextStyle: {
    ...SharedStyles.TextFontStyles.PermanentMarker,
    fontSize: 36,
    color: SharedStyles.Colors.Black,
    marginBottom: 23,
  },
  createFactoryDescriptionTextStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    width: 334,
    textAlign: 'center',
    marginBottom: 16,
  },
  createFactoryDescriptionBlackTextStyle: { color: SharedStyles.Colors.Black },
  workstationBannerContainer: {
    height: '100%',
    width: 224,
    alignItems: 'center',
    paddingTop: 20,
    paddingHorizontal: 20,
    borderRadius: 8,
    zIndex: 999,
    shadowColor: SharedStyles.Colors.ShadowColor,
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  thumbContainerStyle: { marginBottom: 7 },
  workstationDescription: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    textAlign: 'center',
  },
  workstationObjectif: {
    fontSize: fontSize.XXXLarge,
    ...SharedStyles.TextFontStyles.LatoBlack,
    alignSelf: 'flex-start',
  },

  worksationLevel4Minimum: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  workstationLowerInnerContainer: { height: 231, width: 46 },
  workstationlevel4Objective: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    color: SharedStyles.Colors.Grey,
    fontSize: 16,
    alignSelf: 'flex-end',
    paddingRight: 5,
  },
  rowContainer: {
    width: '100%',
    height: 40,
    justifyContent: 'center',
    paddingLeft: SharedStyles.Spacings.Medium,
  },
  rowTextStyle: { fontSize: fontSize.Regular, ...SharedStyles.TextFontStyles.LatoNormal },
  filterContainer: {
    zIndex: 999,
    position: 'absolute',
    marginTop: SharedStyles.Spacings.Unit * 19,
  },
  filterCardStyle: {
    width: 307,
    minHeight: 340,
    maxHeight: 340,
    backgroundColor: SharedStyles.Colors.White,
    paddingHorizontal: 0,
    paddingVertical: 12,
  },
  filterInputStyle: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: SharedStyles.Spacings.Medium,
  },
  requirementTrainingInnerColumnContainer: { marginBottom: SharedStyles.Spacings.Unit * 7 },
  requirementTrainingInnerColumnTitle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.XXXLarge,
    justifyContent: 'center',
    paddingTop: 3,
  },
  requirementTrainingScrollContainer: { width: '100%', minWidth: 216 },
  noTrainingsText: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    width: 140,
  },
  skillTrainingsScroll: { width: '100%', height: '100%' },
  skillsWithNoTrainingsContainer: {
    width: '100%',
    marginBottom: SharedStyles.Spacings.xMedium,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    borderRadius: 8,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    paddingLeft: SharedStyles.Spacings.xMedium,
    paddingRight: SharedStyles.Spacings.Standard,
    paddingVertical: SharedStyles.Spacings.xMedium,
  },
  skillsWithNoTrainingsInnerContainer: {
    marginBottom: SharedStyles.Spacings.Small,
  },
  noSkillTrainingText: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: SharedStyles.Colors.Black,
  },
  createTrainingText: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Small,
    color: SharedStyles.Colors.Black,
  },
  possibleTrainingRowScrollContainer: {
    flexDirection: 'row',
    marginBottom: SharedStyles.Spacings.xMedium,
  },
  skillNameRowText: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoRegular,
    color: SharedStyles.Colors.Black,
    paddingTop: 3,
  },
  skillsWithPossibleTrainingsContainer: {
    width: '100%',
    marginBottom: SharedStyles.Spacings.xMedium,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    borderRadius: 8,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    paddingLeft: SharedStyles.Spacings.xMedium,
    paddingVertical: SharedStyles.Spacings.xMedium,
  },
  skillsWithPossibleTrainingsInnerContainer: {
    width: '100%',
    marginBottom: SharedStyles.Spacings.Small,
  },
  skillsWithPossibleTrainingsTitle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: SharedStyles.Colors.Black,
  },
  skillTitleContainer: {
    flexDirection: 'row',
    marginBottom: SharedStyles.Spacings.Unit,
  },
  skillsWithPossibleTrainingsTextStyle: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoMedium,
    color: SharedStyles.Colors.Black,
  },
  createNewTrainingContainer: { alignSelf: 'flex-end' },
  createNewTrainingTextStyle: {
    width: '100%',
    textAlign: 'right',
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Small,
  },
  trainingSkillRowContainer: {
    marginBottom: SharedStyles.Spacings.Unit,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  trainingSkillInnerRowContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  trainingSkillName: {
    paddingTop: 3,
  },
  trainingSkillsListingContainer: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: SharedStyles.Spacings.Small,
  },
  trainingSkillsListingCard: {
    width: '100%',
    marginBottom: SharedStyles.Spacings.xMedium,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    borderRadius: 8,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    paddingLeft: SharedStyles.Spacings.xMedium,
    paddingRight: SharedStyles.Spacings.Standard,
    paddingVertical: SharedStyles.Spacings.xMedium,
  },
  trainingSkillsListingLoader: {
    position: 'absolute',
    backgroundColor: SharedStyles.Colors.Grey,
    opacity: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    width: '100%',
    height: '100%',
    zIndex: 999,
  },
  practicalTrainingIconContainer: {
    marginRight: SharedStyles.Spacings.Small,
    flex: 0.1,
  },
  trainingDetailsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: SharedStyles.Spacings.Unit / 2,
  },
  trainingNameTextStyle: {
    minHeight: 24,
    justifyContent: 'center',
    paddingTop: 3,
    width: '80%',
    minWidth: 140,
    flexShrink: 1,
  },
  trainingOptionsContainer: { flexDirection: 'row', width: '100%' },
  expandSkillsContainer: { flexDirection: 'row' },
  skillsTotalTextStyle: {
    fontSize: fontSize.Small,
    color: SharedStyles.Colors.Grey,
    marginRight: SharedStyles.Spacings.Unit,
    paddingTop: 3,
  },
  chooseTrainingContainer: {
    height: SharedStyles.Spacings.Standard,
    width: SharedStyles.Spacings.Standard,
    backgroundColor: SharedStyles.Colors.GreyLight,
    borderRadius: SharedStyles.Spacings.Standard,
  },
  chooseTrainingIconContainorStyle: {
    fontSize: fontSize.Small,
    ...SharedStyles.TextFontStyles.LatoNormal,
    justifyContent: 'center',
    width: SharedStyles.Spacings.Standard,
    height: SharedStyles.Spacings.Standard,
    borderRadius: SharedStyles.Spacings.Standard,
  },
  skillsListingScrollContainer: {},
  trainingTableContainer: { width: '100%', height: '100%' },
  addTrainingTouchable: {
    backgroundColor: '#EFF1F233',
    width: '100%',
    height: 68,
    paddingLeft: 34,
    paddingTop: 24,
    paddingBottom: 20,
    opacity: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  addTrainingTextStyle: {
    fontSize: fontSize.Regular,
    fontWeight: '600',
    fontFamily: 'Lato, Black',
    color: 'grey',
    maxWidth: '50%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: 4,
  },
  trainingTableInnerContainer: { flex: 1, backgroundColor: SharedStyles.Colors.GreyBackground },
  trainingTableInnerExtraContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: SharedStyles.Spacings.Large,
  },
  trainingTableTitleContainer: {
    width: '100%',
    paddingVertical: 26,
  },
  trainingLevelsContainer: {
    flex: 1,
    paddingTop: SharedStyles.Spacings.Medium,
    flexDirection: 'row',
  },
  workstationMenuContainer: { position: 'absolute', right: 8, top: 8, zIndex: 999 },
  workerLevelTextStyle: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoMedium,
    color: SharedStyles.Colors.MediumGray,
    paddingLeft: Spacings.Unit * 1.5,
    paddingBottom: Spacings.Unit / 2,
  },

  dotted: {
    flex: 1,
    borderStyle: 'dotted',
    borderBottomWidth: 2,
    marginLeft: Spacings.Unit,
  },
  requirementColumn1: { zIndex: 0 },
  requirementColumn2: { zIndex: -1 },
  requirementColumn3: { zIndex: -2 },
  requirementColumn4: { zIndex: -3 },
  skillFilterContainerStyle: { height: 428, left: 115 },
  plusIconContainer: {
    width: Spacings.Standard,
    height: Spacings.Standard,
    backgroundColor: SharedStyles.Colors.Yellow,
    borderRadius: 50,
  },
  noSkillTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: SharedStyles.fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    width: '80%',
    marginTop: 6,
  },
  toolTipContainer: {
    top: 35,
    left: -Spacings.Medium,
  },
  iconContainer: {
    width: 32,
    height: 32,
    backgroundColor: SharedStyles.Colors.DimGray,
    borderRadius: Spacings.CardPadding,
  },
  levelSkillContentContainer: {
    paddingVertical: Spacings.Small,
    paddingHorizontal: Spacings.Unit,
    flex: 0.9,
    flexDirection: 'row',
    height: '100%',
    zIndex: 1,
  },
  levelSkillIconContainer: {
    flex: 0.13,
    minWidth: 32,
    alignItems: 'center',
    marginHorizontal: Spacings.Small,
  },
  levelSkillDeleteContainer: {
    flex: 0.1,
  },
  levelToolTipContainer: {
    justifyContent: 'center',
    flex: 0.85,
  },
  skillFromOUtext: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Small,
    color: SharedStyles.Colors.Grey,
    paddingBottom: Spacings.xMedium,
  },
  skillNameContainer: {
    flex: 1,
  },
  scrollViewContentContainer: {
    paddingBottom: 50,
  },
  trainingDetails: {
    width: '100%',
    flex: 0.9,
  },
});
export default styles;
