import { StyleSheet } from 'react-native';
import { Spacings, ModalStyles, Colors, TextFontStyles, fontSize } from 'shared/styles';

const style = StyleSheet.create({
  insideModal: {
    paddingHorizontal: Spacings.Large,
    flexDirection: 'row',
  },
  leftInputText: {
    width: ModalStyles.inputWidthWithAnchor,
    marginRight: ModalStyles.bodyDivider,
  },
  inputText: {
    width: ModalStyles.inputWidthWithAnchor,
  },
  lastContainor: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: Spacings.Large,
    marginBottom: Spacings.Large,
    zIndex: -1,
  },
  textRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  photoContainer: {
    paddingTop: ModalStyles.anchorPaddingTop,
    paddingLeft: ModalStyles.bodyPaddingLeft,
    flexDirection: 'row',
  },
  enableNotifContainor: {
    width: 230,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Spacings.Standard,
  },
  photo: {
    height: Spacings.Big + Spacings.Unit,
    width: Spacings.Big + Spacings.Unit,
    borderRadius: (Spacings.Big + Spacings.Unit) / 2,
    backgroundColor: Colors.FlashWhite,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInputContainer: {
    flexDirection: 'row',
    zIndex: 2,
  },
  textInputOuterContainer: {
    paddingLeft: ModalStyles.anchorMarginRight,
    paddingTop: Spacings.Unit,
  },
  notificationText: {
    color: Colors.Black,
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },
  enableNotificationText: {
    color: Colors.Black,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  buttons: {
    paddingRight: Spacings.Large + Spacings.xMedium,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: Spacings.Large + Spacings.xMedium,
    alignItems: 'center',
  },
  profileImageContainer: { marginRight: 8 },
  userFirstNameTextStyle: {
    ...TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    height: 17,
    marginRight: Spacings.Small,
  },
  adminsProfile: {
    flexDirection: 'row',
    marginBottom: Spacings.Standard,
    alignItems: 'center',
  },
  adminsInfo: {
    top: Spacings.Standard,
    left: -50,
    paddingVertical: Spacings.xMedium,
  },
  adminContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: Spacings.Standard,
  },
  warningModalbuttonContainer: {
    flexDirection: 'column',
    height: 120,
  },
  buttonStyle: {
    marginLeft: 0,
  },
  textButtonContainer: {
    marginRight: Spacings.Medium,
  },
});

export default style;
