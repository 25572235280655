import React, { useState } from 'react';
import { View } from 'react-native-web';
import { Card } from 'shared/ui-component/Card';
import { StyleProp, ViewStyle, TextStyle, Text } from 'react-native';
import * as _ from 'lodash-es';
import Styles from './Styles';

interface TextWithTooltip {
  text: string;
  tooltipText?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  toolTipContainerStyle?: StyleProp<ViewStyle>;
  toolTipTextStyle?: StyleProp<TextStyle>;
}
export const TextWithTooltip: React.FC<TextWithTooltip> = props => {
  const { text, textStyle, toolTipTextStyle, tooltipText } = props;
  const [showHover, setShowHover] = useState<boolean>(false);

  const onMouseEnter = () => {
    setShowHover(true);
  };

  const onMouseLeave = () => {
    setShowHover(false);
  };

  return (
    <View style={{ width: '100%' }}>
      <View
        style={[Styles.rootContainer, props.style]}
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
      >
        <Text numberOfLines={1} style={[Styles.tableText, textStyle]}>
          {text}
        </Text>
      </View>
      {showHover && (
        <Card style={[Styles.cardContainer, props.toolTipContainerStyle]}>
          <Text style={[Styles.tableText, toolTipTextStyle]}>{tooltipText ?? text}</Text>
        </Card>
      )}
    </View>
  );
};
