


import moment from 'moment';
import * as API from './index';
import { logger } from './index';
import _ from 'lodash';

type ImmutablePrimitive = undefined | null | boolean | string | number | Function;
type ImmutableMap<K, V> = ReadonlyMap<Immutable<K>, Immutable<V>>;
type ImmutableSet<T> = ReadonlySet<Immutable<T>>;
/** DeepReadonly type that works for objects, arrays and tuples */
type ImmutableObject<T> = { readonly [K in keyof T]: Immutable<T[K]> };

export type Immutable<T> = T extends ImmutablePrimitive
  ? T
  : 
  T extends Map<infer K, infer V>
  ? ImmutableMap<K, V>
  : T extends Set<infer M>
  ? ImmutableSet<M>
  : ImmutableObject<T>;


export type Locale = 'fr' | 'en' | 'es' | 'de';

export interface RoleAndPermissionInfo {
  roleId: string;
  permissions: API.Permission[];
}

export interface OrgUnitWithRoleAndPermissionInfo {
  roleAndPermissions: RoleAndPermissionInfo;
  orgUnitId: string;
  shiftId?: string;
}

export interface Scope {
  notOrgUnitScopedPermissions: API.Permission[];
  nonInheritedRolesOnOrgUnits: Record<string, RoleAndPermissionInfo>;
  inheritedRolesOnOrgUnits: Record<string, string>;
}

export const NOT_ORGUNIT_SCOPED_PERMISSIONS = [
  API.Permission.skills_edit,
  API.Permission.trainings_edit,
];

/**
 *
 */
export const enableGlobalLevelComputation = false;

export const defaultTrainingVersionDurationInMin = 120;

export const SkillGroup = 'SkillGroup';



/**
 * TrainingSession is divied into
 *
 */
export enum TrainingSessionStage {
  Request = 'Request',
  Proposal = 'Proposal',
  
  InProgress = 'InProgress',
  
  Archived = 'Archived',
}

/**
 *
 */
export enum TrainingSessionState {
  REQUEST,
  REQUEST_VALIDATED,

  DRAFT,

  SCHEDULED,
  STARTING_LATE,

  STARTED,
  STARTED_LATE,
  ENDING_LATE,

  REJECTED,
  ENDED,
  ENDED_LATE,
}

export enum TraineeOrTrainer {
  TRAINEE = 'TRAINEE',
  TRAINER = 'TRAINER',
}

export interface WorkstationTarget {
  minNumberOfWorker?: number;
  idealNumberOfWorker?: number;
  isPercentage?: boolean;
}

export interface ShiftWorkersTarget {
  workersWithLevel2AtLeastWorkstationTarget: WorkstationTarget;
  workersWithLevel3AtLeastWorkstationTarget: WorkstationTarget;
  workersWithLevel4WorkstationTarget: WorkstationTarget;
}
export interface WorkersTargetOnShift {
  [key: string]: ShiftWorkersTarget;
}

export interface RoleAndPermissionsOnShift {
  [key: string]: ShiftPermissionsAndRole;
}

export interface ShiftPermissionsAndRole {
  roleId: string;
  permissions: API.Permission[];
}

export const rootOrganizationalUnitParentId = 'root';

export const openSSOFromExternalLink = 'openSSOFromExternalLink';

export function extractWORKSTATIONWorkersTarget(
  workstation: API.Workstation | API.NoMetadata<API.Workstation>,
): WorkersTargetOnShift | undefined {
  if (!workstation.workersTargetOnShift) return;

  try {
    if (typeof workstation.workersTargetOnShift === 'string') {
      let _parsed = JSON.parse(workstation.workersTargetOnShift);
      if (typeof _parsed === 'string') {
        _parsed = JSON.parse(_parsed);
        return _parsed;
      } else {
        return _parsed;
      }
    }

    return workstation.workersTargetOnShift as WorkersTargetOnShift;
  } catch (e) {
    return undefined;
  }
}

export function extractWORKSTATIONWorkersTargetOnShift(
  workstation: API.Workstation | API.NoMetadata<API.Workstation>,
  shiftOrWorkstationId: string = workstation.id,
): ShiftWorkersTarget | undefined {
  if (!workstation.workersTargetOnShift) return;

  const workersTargetOnShift = extractWORKSTATIONWorkersTarget(workstation);
  if (!workersTargetOnShift) return workersTargetOnShift as undefined;

  return workersTargetOnShift[shiftOrWorkstationId] as ShiftWorkersTarget;
}

export function isTrainingSessionValidated(trainingSession: API.TrainingSession): boolean {
  return trainingSession.requestState === API.ReviewState.VALIDATED;
}

export function isTrainingSessionScheduledForAfterToday(
  trainingSession: API.TrainingSession,
): boolean {
  if (moment(trainingSession.scheduledStartDate).isAfter(new Date())) return true;
  else return false;
}

export function isTrainingSessionRequestRejected(
  trainingSession: API.TrainingSession | API.NoMetadata<API.TrainingSession>,
): boolean {
  return (
    trainingSession.requestState === API.ReviewState.REJECTED ||
    trainingSession.requestState === API.ReviewState.REJECTED_TO_RESUBMIT
  );
}

export function isTrainingSessionToReview(trainingSession: API.TrainingSession): boolean {
  return trainingSession.requestState === API.ReviewState.TO_REVIEW;
}

export function isTrainingSessionStartedOrStartedLateOrLateEnd(
  trainingSession: API.NoMetadata<API.TrainingSession>,
): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return (
    trainingSessionState === TrainingSessionState.STARTED ||
    trainingSessionState === TrainingSessionState.STARTED_LATE ||
    trainingSessionState === TrainingSessionState.ENDING_LATE
  );
}

export function isTrainingSessionStartedOrStartedLate(
  trainingSession: API.TrainingSession,
): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return (
    trainingSessionState === TrainingSessionState.STARTED ||
    trainingSessionState === TrainingSessionState.STARTED_LATE
  );
}

export function isTrainingSessionLateEnd(trainingSession: API.TrainingSession): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return trainingSessionState === TrainingSessionState.ENDING_LATE;
}

export function isTrainingSessionEndedOrEndedLate(
  trainingSession:
    | API.TrainingSession
    | API.NoMetadata<API.TrainingSession>
    | API.TrainingSessionCreateInput,
): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return (
    trainingSessionState === TrainingSessionState.ENDED ||
    trainingSessionState === TrainingSessionState.ENDED_LATE
  );
}

export function isTrainingSessionInProgress(trainingSession: API.TrainingSession): boolean {
  const isTheTrainingSessionEndedOrEndedLate = isTrainingSessionEndedOrEndedLate(trainingSession);
  return !isTheTrainingSessionEndedOrEndedLate;
}

export function isTrainingSessionRequested(
  trainingSession: API.NoMetadata<API.TrainingSession>,
): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return trainingSessionState === TrainingSessionState.REQUEST;
}

export function isTrainingSessionScheduledOrLateStart(
  trainingSession: API.NoMetadata<API.TrainingSession>,
): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return (
    trainingSessionState === TrainingSessionState.SCHEDULED ||
    trainingSessionState === TrainingSessionState.STARTING_LATE
  );
}

export function isTrainingSessionDraftOrRequestValidated(
  trainingSession: API.NoMetadata<API.TrainingSession>,
): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return (
    trainingSessionState === TrainingSessionState.DRAFT ||
    trainingSessionState === TrainingSessionState.REQUEST_VALIDATED
  );
}

export function isTrainingSessionRequestValidated(
  trainingSession: API.NoMetadata<API.TrainingSession>,
): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return trainingSessionState === TrainingSessionState.REQUEST_VALIDATED;
}

export function isTrainingSessionDraft(
  trainingSession: API.NoMetadata<API.TrainingSession>,
): boolean {
  const trainingSessionState = getTrainingSessionState(trainingSession);
  return trainingSessionState === TrainingSessionState.DRAFT;
}

/**
 * TrainingSession is active if not archived (i.e. ended or endedLate or requestRejected)
 *
 * @param trainingSession
 * @returns
 */
export function isTrainingSessionActive(
  trainingSession: API.NoMetadata<API.TrainingSession>,
): boolean {
  return (
    !isTrainingSessionEndedOrEndedLate(trainingSession) &&
    !isTrainingSessionRequestRejected(trainingSession)
  );
}

export function getTrainingSessionEstimatedEndDate(
  trainingSession: API.TrainingSession,
): moment.Moment {
  if (trainingSession.startDate) {
    return moment(trainingSession.startDate).add(trainingSession.durationInMin, 'minutes');
  } else if (trainingSession.scheduledStartDate) {
    return moment(trainingSession.scheduledStartDate).add(trainingSession.durationInMin, 'minutes');
  } else {
    return moment(new Date());
  }
}
/**
 * WARNING
 * Function is duplicated in MutationCreateFactoryFunction.req.vtl
 * Function is duplicated in MutationUpdateFactoryFunction.req.vtl
 * WARNING
 * @param trainingSession
 * @returns
 */
export function getTrainingSessionStage(
  trainingSession: API.NoMetadata<API.TrainingSession> | API.TrainingSessionCreateInput,
): TrainingSessionStage {
  const state = getTrainingSessionState(trainingSession);
  switch (getTrainingSessionState(trainingSession)) {
    case TrainingSessionState.REQUEST:
    case TrainingSessionState.REQUEST_VALIDATED:
      return TrainingSessionStage.Request;

    case TrainingSessionState.DRAFT:
      return TrainingSessionStage.Proposal;

    case TrainingSessionState.SCHEDULED:
    case TrainingSessionState.STARTING_LATE:
    case TrainingSessionState.STARTED:
    case TrainingSessionState.STARTED_LATE:
    case TrainingSessionState.ENDING_LATE:
      return TrainingSessionStage.InProgress;

    case TrainingSessionState.REJECTED:
    case TrainingSessionState.ENDED:
    case TrainingSessionState.ENDED_LATE:
      return TrainingSessionStage.Archived;
  }
}

/**
 * WARNING
 * Function is duplicated in MutationCreateFactoryFunction.req.vtl
 * Function is duplicated in MutationUpdateFactoryFunction.req.vtl
 * WARNING
 * @param trainingSession
 * @returns
 */
export function getTrainingSessionState(
  trainingSession: API.NoMetadata<API.TrainingSession> | API.TrainingSessionCreateInput,
): TrainingSessionState {
  const now = Date.now();
  switch (true) {
    case trainingSession.requestState === API.ReviewState.TO_REVIEW:
      return TrainingSessionState.REQUEST;

    case trainingSession.requestState === API.ReviewState.VALIDATED &&
      !!trainingSession.durationInMin &&
      !!trainingSession.startDate &&
      now >= moment(trainingSession.startDate).valueOf() &&
      !!trainingSession.endDate &&
      moment
        .duration(moment(trainingSession.startDate).diff(moment(trainingSession.endDate)))
        .asMinutes() > trainingSession.durationInMin:
      return TrainingSessionState.ENDED_LATE;

    case trainingSession.requestState === API.ReviewState.VALIDATED && !!trainingSession.endDate:
      return TrainingSessionState.ENDED;

    case trainingSession.requestState === API.ReviewState.VALIDATED &&
      !trainingSession.isDraft &&
      !!trainingSession.scheduledStartDate &&
      !!trainingSession.durationInMin &&
      !!trainingSession.scheduledTrainers.length &&
      trainingSession.scheduledTraineeIds.length &&
      !!trainingSession.trainers.length &&
      trainingSession.traineeIds.length &&
      !!trainingSession.startDate &&
      now >= moment(trainingSession.startDate).valueOf() &&
      !trainingSession.endDate &&
      now >=
        moment(trainingSession.startDate).add(trainingSession.durationInMin, 'minutes').valueOf():
      return TrainingSessionState.ENDING_LATE;

    case trainingSession.requestState === API.ReviewState.VALIDATED &&
      !trainingSession.isDraft &&
      !!trainingSession.scheduledStartDate &&
      !!trainingSession.durationInMin &&
      !!trainingSession.scheduledTrainers.length &&
      trainingSession.scheduledTraineeIds.length &&
      !!trainingSession.trainers.length &&
      trainingSession.traineeIds.length &&
      !!trainingSession.startDate &&
      now >= moment(trainingSession.startDate).valueOf() &&
      moment(trainingSession.startDate).isAfter(moment(trainingSession.scheduledStartDate)):
      return TrainingSessionState.STARTED_LATE;

    case trainingSession.requestState === API.ReviewState.VALIDATED &&
      !trainingSession.isDraft &&
      !!trainingSession.scheduledStartDate &&
      !!trainingSession.durationInMin &&
      !!trainingSession.scheduledTrainers.length &&
      trainingSession.scheduledTraineeIds.length &&
      !!trainingSession.trainers.length &&
      trainingSession.traineeIds.length &&
      !!trainingSession.startDate &&
      now >= moment(trainingSession.startDate).valueOf():
      return TrainingSessionState.STARTED;

    case trainingSession.requestState === API.ReviewState.VALIDATED &&
      !trainingSession.isDraft &&
      !!trainingSession.scheduledStartDate &&
      !!trainingSession.durationInMin &&
      !!trainingSession.scheduledTrainers.length &&
      trainingSession.scheduledTraineeIds.length &&
      !trainingSession.startDate &&
      now >= moment(trainingSession.scheduledStartDate).valueOf():
      return TrainingSessionState.STARTING_LATE;

    case trainingSession.requestState === API.ReviewState.VALIDATED &&
      !trainingSession.isDraft &&
      !!trainingSession.scheduledStartDate &&
      !!trainingSession.durationInMin &&
      !!trainingSession.scheduledTrainers.length &&
      !!trainingSession.scheduledTraineeIds.length:
      return TrainingSessionState.SCHEDULED;

    case trainingSession.requestState === API.ReviewState.VALIDATED && trainingSession.isDraft:
      return TrainingSessionState.DRAFT;

    case trainingSession.requestState === API.ReviewState.VALIDATED &&
      !trainingSession.isDraft &&
      (!trainingSession.scheduledStartDate ||
        !trainingSession.durationInMin ||
        !trainingSession.scheduledTrainers.length ||
        !trainingSession.scheduledTraineeIds.length):
      return TrainingSessionState.REQUEST_VALIDATED;

    default:
      logger.warn(
        'Default TrainingSessionState returned. Please check code, this is not expected.',
      );
      return TrainingSessionState.ENDED;
  }
}

export function capitalizeFirstLetter(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function formatDecimalPercentageTargetCount(target: number, isPercentage: boolean): string {
  return target.toString() + (isPercentage ? '%' : '');
}

/**
 *
 * @param tenantAppId Pk as Tenant#UUID,APP#UUID
 */
export function getWorkerTenantAppSk(tenantAppId: string): string | undefined {
  const workerTenantAppSk =
    API.DataType.WORKER_TENANT_APP + API.SeparatorDataType + API.SeparatorIds + tenantAppId;

  return workerTenantAppSk;
}

/**
 *
 * @param tenantAppId Pk as Tenant#UUID,APP#UUID
 */
export function getTenantPkAndSk(tenantAppId: string): string | undefined {
  const tenantSk = tenantAppId.split(API.SeparatorIds)[0];

  return tenantSk;
}

/**
 *
 * @param tenantAppId Pk as Tenant#UUID,APP#UUID
 */
export function getTenantAppSk(tenantAppId: string): string | undefined {
  const tenantAppSk =
    API.DataType.TENANT_APP + API.SeparatorDataType + API.SeparatorIds + tenantAppId;

  return tenantAppSk;
}

function isWorkerInInheritedOrNonInheritedTrainingOnWorkstation(
  workerWorkstation: Pick<Immutable<API.WorkerWorkstationUpdateInput>, 'activeTrainingSessions'>,
): boolean {
  return (
    !!workerWorkstation.activeTrainingSessions.workstationActiveTrainingSessions
      .lowerOrEqualToTarget.fromNonInheritedRequirements.length ||
    !!workerWorkstation.activeTrainingSessions.workstationActiveTrainingSessions
      .lowerOrEqualToTarget.fromInheritedRequirements.length
  );
}

export function isWorkerInTrainingOnWorkstation(
  workerWorkstation: Pick<
    Immutable<API.WorkerWorkstationUpdateInput>,
    'targetLevel' | 'activeTrainingSessions'
  >,
) {
  return (
    isWorkerTargetingALevelOnWorkstation(workerWorkstation) &&
    isWorkerInInheritedOrNonInheritedTrainingOnWorkstation(workerWorkstation)
  );
}

export function isWorkerTargetingALevelOnWorkstation(
  workerWorkstation: Pick<Immutable<API.WorkerWorkstationUpdateInput>, 'targetLevel'>,
): workerWorkstation is NonNullable<
  Pick<Immutable<API.WorkerWorkstationUpdateInput>, 'targetLevel'>
> {
  return !!workerWorkstation.targetLevel;
}

export function isWorkerInTrainAutoOnWorkstation(
  workerWorkstation: Pick<
    Immutable<API.WorkerWorkstationUpdateInput>,
    'isTrainAuto' | 'targetLevel'
  >,
): boolean {
  return !!workerWorkstation.isTrainAuto && isWorkerTargetingALevelOnWorkstation(workerWorkstation);
}

/**
 * Remove the worker from the TrainingSession - (Shall be centralized)
 *
 * @param workerId
 * @param trainingSession
 * @returns
 */
export function removeWorkerFromTrainingSession(
  workerId: string,
  trainingSession: API.TrainingSessionUpdateInput,
): API.TrainingSessionUpdateInput {
  const scheduledTraineeIds = trainingSession.scheduledTraineeIds.filter(
    scheduledTraineeId => scheduledTraineeId !== workerId,
  );
  const traineeIds = trainingSession.traineeIds.filter(traineeId => traineeId !== workerId);
  const scheduledTrainers = trainingSession.scheduledTrainers?.filter(
    trainer => trainer.trainerId !== workerId,
  );
  const trainers = trainingSession.trainers?.filter(trainer => trainer.trainerId !== workerId);

  return {
    ...trainingSession,
    scheduledTraineeIds,
    traineeIds,
    trainers,
    scheduledTrainers,
  };
}

/**
 * get the users permissions on this specific unit or shift
 * @param userScope
 * @param unitAndShiftId
 * @returns if returns undefined means the users is not assigned on this unit
 */
export function getUserPermissionsOnUnitOrShift(
  userScope: Scope,
  unitAndShiftId: string, 
): RoleAndPermissionInfo | undefined {
  const origUnitId = extractOrgUnitIdFromScopeKey(unitAndShiftId);
  const shiftId = extractShiftIdFromScopeKey(unitAndShiftId);

  let assignment: string | RoleAndPermissionInfo | undefined;
  let allShiftAssignments: string[] | RoleAndPermissionInfo[] | undefined;

  let rolesOnOrgUnits = {
    ...userScope.inheritedRolesOnOrgUnits,
    ...userScope.nonInheritedRolesOnOrgUnits,
  };

  const userAssignment = Object.keys(rolesOnOrgUnits).find(
    assignmentKey => assignmentKey === unitAndShiftId,
  );

  if (userAssignment && !shiftId) assignment = rolesOnOrgUnits[userAssignment];
  else {
    
    const allShiftAssignmentsOnUnit = Object.keys(rolesOnOrgUnits).filter(assignmentKey => {
      if (shiftId) {
        if (assignmentKey === origUnitId || assignmentKey === unitAndShiftId) return assignmentKey;
      } else {
        if (assignmentKey.includes(origUnitId)) return assignmentKey;
      }
    });
    if (allShiftAssignmentsOnUnit) allShiftAssignments = allShiftAssignmentsOnUnit;
  }

  if (allShiftAssignments) {
    
    let permissions: API.Permission[] = [];
    let roleId: string = '';

    allShiftAssignments.map(assignmentOrAssignmentKey => {
      const roleAndPermissions = extractRoleAndPermissionsFromScopeValueOrKey(
        assignmentOrAssignmentKey,
        userScope,
      );
      if (!roleAndPermissions) return;

      permissions = _.unionBy(roleAndPermissions.permissions, permissions);
      roleId = roleAndPermissions.roleId;
    });

    if (roleId)
      assignment = {
        permissions: permissions,
        roleId: roleId,
      };
  }

  return extractRoleAndPermissionsFromScopeValueOrKey(assignment, userScope);
}

export function isSkillGroup<T extends Pick<API.NoMetadata<API.Skill>, 'skillIds'>>(
  skill: T,
): skill is T & { skillIds: string[] } {
  return Boolean(skill.skillIds?.length);
}

/**
 * This function checks if any assignment permission or role has changed in the workerScope
 * @param currentScope
 * @param oldScope
 * @param excludeInheritedRoles
 * @returns
 */
export function isScopeChanged(
  currentScope: Scope | string,
  oldScope: Scope | string,
  excludeInheritedRoles?: boolean,
): boolean {
  if (!currentScope || !oldScope) {
    console.log(
      'The scope or the inherited and nonInherited params should not be undefined ',
    );
    return false;
  }

  const _currentScope =
    typeof currentScope === 'string' ? { ...JSON.parse(currentScope) } : { ...currentScope };
  const _oldScope = typeof oldScope === 'string' ? { ...JSON.parse(oldScope) } : { ...oldScope };

  if (excludeInheritedRoles) {
    delete _currentScope.inheritedRolesOnOrgUnits;
    delete _oldScope.inheritedRolesOnOrgUnits;
  }

  return !_.isEqual(_currentScope, _oldScope);
}

/**
 * it receives the intended master assignments for a worker and constructs the scope object. Backend will take care of creating the inherited assignments.
 * @param orgUnitIdWithRoleAndPermissionInfo
 * @returns
 */
export function constructNonInheritedAssignmentsAndCreateScope(
  orgUnitIdWithRoleAndPermissionInfo: OrgUnitWithRoleAndPermissionInfo[],
  inheritedRolesOnOrgUnits: Record<string, string>,
): string {
  let _assignments: Record<string, API.RoleAndPermissionInfo> = {};
  let _notOrgUnitScopedPermissions: API.Permission[] = [];

  orgUnitIdWithRoleAndPermissionInfo.forEach(assignment => {
    const assignmentKey = assignment.shiftId
      ? assignment.orgUnitId + API.SeparatorIds + assignment.shiftId
      : assignment.orgUnitId;
    _assignments[assignmentKey] = {
      roleId: assignment.roleAndPermissions.roleId,
      permissions: _.uniq(assignment.roleAndPermissions.permissions),
    };

    _notOrgUnitScopedPermissions = _.union(
      _notOrgUnitScopedPermissions,
      assignment.roleAndPermissions.permissions?.filter(permission =>
        API.NOT_ORGUNIT_SCOPED_PERMISSIONS.includes(permission),
      ),
    );
  });

  const scope: API.Scope = {
    nonInheritedRolesOnOrgUnits: _assignments,
    inheritedRolesOnOrgUnits: inheritedRolesOnOrgUnits,
    notOrgUnitScopedPermissions: _notOrgUnitScopedPermissions,
  };

  return JSON.stringify(scope);
}

export function getWorkerOrganizationalUnitIds(
  worker: API.NoMetadata<API.Worker>,
  includeInherited = false,
): string[] {
  let scope: Scope;
  if (typeof worker.scope === 'string') {
    scope = JSON.parse(worker.scope);
  } else {
    scope = worker.scope;
  }

  const orgUnitIds: string[] = [];
  let _scope: { [x: string]: string | API.RoleAndPermissionInfo } = {
    ...scope.nonInheritedRolesOnOrgUnits,
  };

  if (includeInherited) {
    _scope = { ..._scope, ...scope.inheritedRolesOnOrgUnits };
  }

  Array.from(Object.keys(_scope)).forEach(inheritedScopeKey => {
    orgUnitIds.push(extractOrgUnitIdFromScopeKey(inheritedScopeKey));
  });

  return orgUnitIds;
}

export function extractOrgUnitIdFromScopeKey(scopeKey: string): string {
  return scopeKey.split(API.SeparatorIds)[0];
}

export function extractShiftIdFromScopeKey(scopeKey: string): string | undefined {
  return scopeKey.split(API.SeparatorIds)[1];
}

export function extractScopeFromWorker(worker: API.NoMetadata<API.Worker>): Scope {
  try {
    if (typeof worker.scope === 'string') return JSON.parse(worker.scope);
    else if (worker.scope != null) return worker.scope;
    else
      return {
        inheritedRolesOnOrgUnits: {},
        nonInheritedRolesOnOrgUnits: {},
        notOrgUnitScopedPermissions: [],
      };
  } catch (e) {
    console.info('extractScopeFromWorker Failed ', JSON.stringify(e));
    return {
      inheritedRolesOnOrgUnits: {},
      nonInheritedRolesOnOrgUnits: {},
      notOrgUnitScopedPermissions: [],
    };
  }
}

/**
 * this function can recieve eather a scope key which can be constructed of #unitId,(maybe #shiftId) or a scope value and if both cases it return the permission and role of the user
 * @param scopeValueOrKey
 * @param scope
 * @returns
 */
function extractRoleAndPermissionsFromScopeValueOrKey(
  scopeValueOrKey: string | RoleAndPermissionInfo | undefined,
  scope: Scope,
): RoleAndPermissionInfo | undefined {
  if (!_.isString(scopeValueOrKey)) return scopeValueOrKey;
  if (!scope?.nonInheritedRolesOnOrgUnits || !scope?.inheritedRolesOnOrgUnits) {
    console.log(
      'The scope or the inherited and nonInherited params should not be undefined ',
    );
    return;
  }

  const nonInheritedAssignment = scope.nonInheritedRolesOnOrgUnits[scopeValueOrKey];
  if (nonInheritedAssignment) return nonInheritedAssignment;

  const inheritedAssignmentKey = scope.inheritedRolesOnOrgUnits[scopeValueOrKey];
  if (inheritedAssignmentKey) {
    const shiftId = extractShiftIdFromScopeKey(inheritedAssignmentKey);
    if (shiftId) {
      const assignmentsOnSiblingShiftsAndParentUnit = Object.keys(
        scope.nonInheritedRolesOnOrgUnits,
      ).filter(assignmentKey => {
        if (assignmentKey.includes(extractOrgUnitIdFromScopeKey(inheritedAssignmentKey)))
          return assignmentKey;
      });

      let permissions: API.Permission[] = [];
      let roleId: string = '';

      assignmentsOnSiblingShiftsAndParentUnit.map(AssignmentKey => {
        const roleAndPermissions = scope.nonInheritedRolesOnOrgUnits[AssignmentKey];
        if (!roleAndPermissions) return;

        permissions = _.unionBy(roleAndPermissions.permissions, permissions);
        roleId = roleAndPermissions.roleId;
      });

      return {
        permissions: permissions,
        roleId: roleId,
      };
    } else return scope.nonInheritedRolesOnOrgUnits[inheritedAssignmentKey];
  }

  return;
}

export function getAssignmentKeysOnUnit(unitId: string, scope: Scope, includeInherited?: string) {
  let rolesOnOrgUnits = includeInherited
    ? {
        ...scope.inheritedRolesOnOrgUnits,
        ...scope.nonInheritedRolesOnOrgUnits,
      }
    : scope.nonInheritedRolesOnOrgUnits;

  return Object.keys(rolesOnOrgUnits).filter(key => key.includes(unitId));
}

export function getTrainingIdFromTrainingVersionId(trainingVersionId: string): string | undefined {
  return trainingVersionId.split(API.SeparatorIds)[1];
}

export function getTrainingSessionSkStringByTrainingIdOrTrainingVersionId(
  trainingIdOrTrainingVersionId: string,
): string {
  const isTrainingVersion =
    API.getDataType(trainingIdOrTrainingVersionId) === API.DataType.TRAININGVERSION;
  let skString = '';

  if (isTrainingVersion) {
    skString =
      API.DataType.TRAININGSESSION +
      API.SeparatorDataType +
      API.SeparatorIds +
      trainingIdOrTrainingVersionId;
  } else {
    skString =
      API.DataType.TRAININGSESSION +
      API.SeparatorDataType +
      API.SeparatorIds +
      API.DataType.TRAININGVERSION +
      API.SeparatorDataType +
      API.SeparatorIds +
      trainingIdOrTrainingVersionId;
  }

  return skString;
}

export function getTrainingSessionDataString(
  stage: API.TrainingSessionStage,
  originId?: string,
  requirementId?: string,
): string;
export function getTrainingSessionDataString(
  stage: API.TrainingSessionStage,
  originId: string,
  requirementId?: string,
): string;
export function getTrainingSessionDataString(
  stage: API.TrainingSessionStage,
  originId: string,
  requirementId: string,
): string;
export function getTrainingSessionDataString(
  stage: API.TrainingSessionStage,
  originId?: string,
  requirementId?: string,
): string {
  let dataString = API.DataType.TRAININGSESSION + API.SeparatorDataType + API.SeparatorIds + stage;

  if (originId) dataString += API.SeparatorIds + originId;

  if (requirementId) dataString += API.SeparatorIds + requirementId;

  return dataString;
}

export function getTrainingVersionSkString(trainingId: string) {
  const skString =
    API.DataType.TRAININGVERSION + API.SeparatorDataType + API.SeparatorIds + trainingId;

  return skString;
}

export function getSkillGroupDataString() {
  return API.DataType.SKILL + API.SeparatorDataType + API.SeparatorIds + SkillGroup;
}

export enum JobType {
  EMAIL = 'EMAIL',
  BACKUP = 'BACKUP',
  TRAININGISNOTCLOSED = 'TRAININGISNOTCLOSED',
  ARCHIVEWORKER = 'ARCHIVEWORKER',
  REINSTATEWORKER = 'REINSTATEWORKER',
  DELETESKILLDEPENDENCY = 'DELETESKILLDEPENDENCY',
  DELETEWORKERSKILLDEPENDENCY = 'DELETEWORKERSKILLDEPENDENCY',
}

export interface Job {
  pk: string;
  sk: string;
  type: JobType;
  payload: string;
}

export function getTrainerIdsFromTraininersWithPercentage(
  trainers: API.TrainerInput[] | undefined | null,
): string[] {
  if (!trainers) return [];
  return trainers.map(trainerWithPercentage => {
    return trainerWithPercentage.trainerId;
  });
}
