import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  CardContainer: {
    width: 264,
    position: 'absolute',
    padding: 0,
    paddingBottom: Spacings.Small + Spacings.Unit,
    zIndex: 999,
  },
  ProfileContainer: {
    height: 80,
    flexDirection: 'row',
    paddingLeft: Spacings.CardPadding,
    paddingRight: Spacings.xMedium,
    paddingVertical: Spacings.Medium,
    width: '100%',
    flex: 1,
    zIndex: -1,
  },
  ProfileInfoContainer: {
    flexDirection: 'column',
    marginLeft: 8,
    alignSelf: 'center',
    flex: 0.8,
  },
  ProfileText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    width: '100%',
  },
  OptionsContainer: {
    marginLeft: 5,
    zIndex: 999,
  },
  ProfileImageContainer: {
    width: Spacings.Big + Spacings.Unit,
    height: Spacings.Big + Spacings.Unit,
    alignSelf: 'center',
    borderRadius: Spacings.Big + Spacings.Unit / 2,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Background,
    borderColor: Colors.Background,
    flex: 0.2,
  },
  optionContainer: {
    marginTop: 6,
    paddingLeft: 20,
    paddingRight: 15,
    minHeight: Spacings.Large,
    flexDirection: 'row',
    alignItems: 'center',
  },
  stopTrainingButton: {
    borderColor: Colors.Black,
    width: 70,
    borderRadius: 20,
    borderWidth: 1,
    padding: 5,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  stopTrainingText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Small,
  },
  OptionText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  fillerView: {
    flex: 1,
  },
  OptionTextBold: {
    ...TextFontStyles.LatoBlack,
    alignSelf: 'center',
    margin: 5,
    marginRight: 15,
    alignItems: 'center',
  },
  MissingSkillText: {
    margin: 5,
    marginRight: 15,
  },
  OptionIcon: {
    alignContent: 'center',
    alignSelf: 'center',
  },
  CircleView: {
    width: 116,
    height: Spacings.Standard,
    borderColor: Colors.Black,
    borderRadius: 20,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  LineView: {
    borderBottomColor: Colors.Background,
    borderBottomWidth: 1,
    zIndex: -2,
  },
  ProfileLevelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -2,
  },
  ProfileLevelText: {
    fontSize: fontSize.Regular,
    marginTop: 3,
  },
  alertBox: { flexDirection: 'row', paddingRight: 16, paddingTop: 16, paddingLeft: 20 },
  alertTextTitle: {
    ...TextFontStyles.LatoBlack,
    alignSelf: 'center',
    marginBottom: 5,
    marginLeft: 5,
    alignItems: 'center',
    fontSize: fontSize.Regular,
  },
  alertTextMessage: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingLeft: 32,
    paddingRight: 16,
  },
  alertButtonText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Small,
  },
  noRequirementContainer: {
    padding: Spacings.CardPadding,
    alignItems: 'center',
  },
  noRequirementText: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    textAlign: 'center',
    paddingBottom: Spacings.Standard,
  },
  menuCard: {
    left: 134,
    top: -6,
  },
  magicSquareLevelText: {
    paddingLeft: Spacings.Small,
  },
  subLabelMenu: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },
  addOutlineButton: {
    width: 108,
  },
  activityIndicator: {
    width: 'auto',
    paddingLeft: Spacings.Small,
  },
  disabledText: {
    color: Colors.GreyLight,
  },
  targetLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: Spacings.Unit,
  },
  setTargetCircleIcon: {
    height: 56,
    width: 56,
    borderRadius: 56 / 2,
    backgroundColor: Colors.GreyLight,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: Colors.Black,
    shadowOffset: {
      width: -1,
      height: 3,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4.84,
  },
  setTargetCircleIconText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Standard,
    color: Colors.White,
  },
});

export default styles;
