export function isAsyncLock(obj: any): obj is AsyncLock {
  if (obj == null) return false;
  return (obj as AsyncLock).acquire !== undefined;
}

/**
 * A lock to coordinate async functions
 */
export class AsyncLock {
  private _acquired: boolean = false;
  private _waitingResolvers: (() => void)[] = [];

  /**
   * Whether the lock is currently acquired or not. Accessing this property does not affect the
   * status of the lock.
   */
  get acquired(): boolean {
    return this._acquired;
  }

  /**
   * Acquires the lock, waiting if necessary for it to become free if it is already locked. The
   * returned promise is fulfilled once the lock is acquired.
   *
   * After acquiring the lock, you **must** call `release` when you are done with it.
   */
  async acquire(): Promise<void> {
    if (!this._acquired) {
      this._acquired = true;
      return Promise.resolve();
    }

    return new Promise(resolve => {
      this._waitingResolvers.push(resolve);
    });
  }

  /**
   * Acquires the lock if it is free and otherwise returns immediately without waiting. Returns
   * `true` if the lock was free and is now acquired, and `false` otherwise,
   */
  tryAcquire(): boolean {
    if (!this._acquired) {
      this._acquired = true;
      return true;
    }

    return false;
  }

  /**
   * Releases the lock and gives it to the next waiting acquirer, if there is one. Each acquirer
   * must release the lock exactly once.
   */
  release(): void {
    if (!this._acquired) {
      throw new Error(`Cannot release an unacquired lock`);
    }

    if (this._waitingResolvers.length > 0) {
      let resolve = this._waitingResolvers.shift()!;
      resolve();
    } else {
      this._acquired = false;
    }
  }

  /**
   * Acquires the lock, waiting if necessary for it to become free if it is already locked,
   * and execute the passed function and when it returns, the lock is released automatically.
   * The returned promise is fulfilled once the function has returned and the lock has released.
   */
  async runSerial<T>(f: () => Promise<T>): Promise<T> {
    await this.acquire();
    let result;
    try {
      
      
      
      result = await f();
    } finally {
      this.release();
    }
    return result;
  }
}
