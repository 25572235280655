export const emailPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberPattern =
  /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
export const workerPersonalIdPattern = /^[*\.?*\#?a-zA-Z0-9_-]{4,}$/;
export const passwordPattern = /^\s*\S+.{7,}$/;
export const frenchPhoneNumberPattern = /^(?:(?:\+|00)33|0)*\s*[1-9](?:[\s.-]*\d{2}){4}$/;
export const notEmptyPattern = /^(?!\s*$).+/;
export const imagePattern = /\.(jpeg|jpg|gif|png)$/;
export const imageMimePattern = /image\/(jpeg|jpg|gif|png)$/;
export const findForwardSlashAtEndOfString = /\/+$/;
export const containsNumber = /\d/;
export const frontSlash = '/';
export const seperator = '-';

export const HOUR_TO_MIN_MULTIPLIER = 60;
export const HALF_AN_HOUR_TO_MIN_MULTIPLIER = 30;
export const DAY_TO_MIN_MULTIPLIER = 24 * 60;
export const WEEK_TO_MIN_MULTIPLIER = 24 * 60 * 7;
export const MONTH_TO_MIN_MULTIPLIER = 24 * 60 * 30;
export const YEAR_TO_MONTH_MULTIPLIER = 12;
