import React, { useState, useEffect } from 'react';
import * as API from 'shared/backend-data';
import Styles from './Style';
import { UserContext, CognitoAuthErrorCodes } from 'shared/context/UserContext';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { KeyboardAvoidingViewWrapper } from 'shared/ui-component/KeyboardAvoidingView';
import { View, Text, TouchableOpacity, Keyboard } from 'react-native';
import { InputVerificationCode, InputText } from 'shared/ui-component/Input';
import { Loader } from 'shared/ui-component/Loader/Loader';
import _, { isArray, isString } from 'lodash-es';
import { IconSVG } from 'shared/ui-component/Icon';
import arrowLeft from 'shared/assets/svg/icon.arrowLeft.mobile-2.svg';
import { YellowButton, TextButton } from 'shared/ui-component/Button';
import { t } from 'shared/localisation/i18n';
import { Colors } from 'shared/styles/Colors';
import { InputValidationType } from 'shared/hooks/CustomForm';
import { InputsValue, useCustomForm } from 'shared/hooks/CustomForm';
import { PasswordRules } from '../passwordRules';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';

enum InputIds {
  UserPassword = 'UserPassword',
  UserVerificationCode = 'UserVerificationCode',
}

export interface Props {
  username: string;
  isWebPlatfrom: boolean;
  handleBackPress: () => void;
  handleGoToLogin: () => void;
  OTP?: string;
}

export const ResetPasswordLayout: React.FC<Props> = props => {
  const { OTP } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
  const [componentResetTrigger, setComponentResetTrigger] = useState<number>(0);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>();
  const isMounted = useIsMounted();

  const modal = useModal();

  const { errors, enableFormSubmit, onChange, onBlur, onSubmit, navigateInputField, refs } =
    useCustomForm<string[] | string>(
      {
        [InputIds.UserPassword]: {
          isFieldMandatory: true,
          validator: [InputValidationType.Password],
        },
        [InputIds.UserVerificationCode]: {
          isFieldMandatory: true,
          initialValue: otp,
          validator: inputOtpValidator,
        },
      },
      onSubmitForm,
    );

  function onSubmitForm(values: InputsValue<string[] | string>) {
    const otp = values[InputIds.UserVerificationCode];
    const password = values[InputIds.UserPassword];
    if (!isString(password) || !isArray(otp)) return;
    if (!_.isEmpty(otp)) {
      handleResetPassword(password, otp.join(''));
    }
  }

  function inputOtpValidator(inputValue: string | string[] | undefined) {
    if (!inputValue || !isArray(inputValue)) return false;

    let inputValid = true;
    inputValue.forEach(value => {
      if (value === '') inputValid = false;
    });

    return inputValid;
  }

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', () => setIsKeyboardOpen(true));
    Keyboard.addListener('keyboardDidHide', () => setIsKeyboardOpen(false));
  });

  useEffect(() => {
    if (OTP) {
      setOtp(OTP.split(''));
    }
  }, [OTP]);

  async function resendCode() {
    
    setComponentResetTrigger(componentResetTrigger + 1);
    const result = await UserContext.forgotPassword(props.username);

    if (API.isFailure(result)) {
      if (isMounted.current) setError(result.data.message);
    } else {
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('common:auth.validateUser.OtpSent', { email: props.username }),
        }),
      );
    }
  }

  async function handleResetPassword(password: string, otp: string) {
    if (!password) {
      logger.debug('password shall be set before changing password');
      return;
    }
    setLoading(true);
    setError(undefined);
    const result = await UserContext.forgotPasswordSubmit(props.username, password, otp);
    if (isMounted.current) {
      if (API.isFailure(result)) {
        if (result.data.code === CognitoAuthErrorCodes.CodeMismatchException) {
          setError(t('common:auth.validation.errorInvalidCode'));
        } else if (result.data.code === CognitoAuthErrorCodes.ExpiredCodeException) {
          setError(t('common:auth.validation.codeExpired'));
        } else {
          setError(result.data.message);
        }
      } else {
        props.handleGoToLogin();
      }
      setLoading(false);
    }
  }

  return (
    <KeyboardAvoidingViewWrapper style={Styles.appContainer}>
      <View style={Styles.rootContainer}>
        {props.isWebPlatfrom ? (
          <View style={Styles.topContainer}>
            <TouchableOpacity style={Styles.backButton} onPress={props.handleBackPress}>
              <IconSVG svgComponent={arrowLeft} color={Colors.Black} />
            </TouchableOpacity>
            <View style={Styles.headerTextContainer}>
              <Text style={Styles.headerText}>
                {t('common:auth.resetPassword.header', { name: props.username })}
              </Text>
            </View>
          </View>
        ) : (
          <View style={Styles.topContainer}>
            <TouchableOpacity style={Styles.backButton} onPress={props.handleBackPress}>
              <IconSVG svgComponent={arrowLeft} color={Colors.Black} />
            </TouchableOpacity>
            <View style={Styles.headerTextContainer}>
              <Text style={Styles.headerText}>
                {t('common:auth.resetPassword.header', { name: props.username })}
              </Text>
            </View>
          </View>
        )}
        {!isKeyboardOpen && (
          <View style={Styles.middleContainer}>
            <Text style={Styles.bodyText}>{t('common:auth.resetPassword.otpSent')}</Text>
            <Text style={Styles.boldBodytext}> {props.username}</Text>
            <Text style={Styles.bodyText}>{t('common:auth.resetPassword.enterCode')}</Text>
          </View>
        )}

        <View style={Styles.inputContainer}>
          <InputVerificationCode
            inputId={InputIds.UserVerificationCode}
            key={componentResetTrigger}
            style={{ backgroundColor: 'white', borderWidth: 0 }}
            initialValues={otp}
            onChange={onChange}
            onFilled={() => navigateInputField(InputIds.UserPassword)}
          />
          {error && <Text style={Styles.errorMessage}>{error}</Text>}
          {props.isWebPlatfrom && (
            <View style={Styles.termsConditionContainer}>
              <TextButton
                textStyle={Styles.resendOTPText}
                text={t('common:auth.validateUser.resendOtp')}
                onPress={resendCode}
              />
            </View>
          )}
          <InputText
            inputRef={refs[InputIds.UserPassword]}
            inputId={InputIds.UserPassword}
            placeHolder={t('common:auth.changePassword.inputNewPassword.placeholder')}
            secureTextEntry
            containerStyle={Styles.inputText}
            onTextChange={onChange}
            onSubmitEditing={onSubmit}
            onBlur={onBlur}
            errorMessage={errors[InputIds.UserPassword]?.inputErrorMessage}
          />

          <PasswordRules />
        </View>
        <View style={Styles.bottomContainer}>
          <YellowButton
            style={enableFormSubmit ? Styles.connectButton : Styles.connectButtonDisabled}
            onPress={onSubmit}
            disabled={!enableFormSubmit}
            text={t('common:button.select')}
            textStyle={Styles.connectText}
          />
          {!props.isWebPlatfrom && (
            <TextButton
              textStyle={Styles.resendText}
              text={t('common:auth.validateUser.resendOtp')}
              onPress={resendCode}
            />
          )}
        </View>

        {loading && <Loader />}
      </View>
    </KeyboardAvoidingViewWrapper>
  );
};
