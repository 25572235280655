import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';
import { loggerAPI as logger } from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { DropDownMultiSelection } from 'shared/ui-component/DropDown/DropDown';
import { Tag } from 'shared/ui-component/Input/InputTag/index';
import { Styles } from './Styles';
import * as _ from 'lodash-es';
import { SkillCreateInputWithoutTrainingType } from './index';
import { MyHub } from 'shared/util/MyHub';
import { ModalUtils } from 'shared/ui-component/Modal';
import { AttachFile } from 'shared/ui-component/AttachFile/index';
import { tagErrorModal } from 'shared/util/warning';
import { deleteSkillTagWithErrorModalHandling } from 'shared/util/skillUi';


interface Props {
  skill: SkillCreateInputWithoutTrainingType;
  isPanelEditable?: boolean;
  setSkill: React.Dispatch<React.SetStateAction<SkillCreateInputWithoutTrainingType>>;
  onFilesChange: (s3Files: API.S3ObjectInput[]) => void;
}

const LabelIcon = require('shared/assets/svg/icon.label.svg').default;

export const SkillSecondaryInputPanel: React.FC<Props> = props => {
  const { skill, isPanelEditable, onFilesChange, setSkill } = props;

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const [skillTagOptions, setSkillTagOptions] = useState<Tag[]>();
  const [skillTagValues, setSkillTagValues] = useState<Tag[]>();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.SKILLTAG) initSkillTagsOptionsAndValues();
    });

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    initSkillTagsOptionsAndValues();
  }, [skill]);

  async function updateSkillTags(inputId: string, tags: Tag[] | undefined) {
    if (!skill || !tags) return;
    setSkill({
      ...skill,
      tagIds: tags.map(tag => tag.key),
    });
  }

  /**
   * Fetch SkillTag options to populate SkillTags select dropdown data
   */
  async function initSkillTagsOptionsAndValues() {
    const skillTags = await API.getSkillTags();
    if (!isMounted.current) return;
    if (API.isFailure(skillTags)) {
      logger.warn(skillTags);
      return;
    }

    setSkillTagOptions(
      _.sortBy(skillTags, skillTag => skillTag.name.toLowerCase()).map(skillTag =>
        createSkillTagOption(skillTag),
      ),
    );

    const _skillTagValues = _.compact(
      await Promise.all(
        _.map(skill.tagIds, async tagId => {
          return await createSkillTagValue(tagId);
        }),
      ),
    );
    if (!isMounted.current) return;
    setSkillTagValues(_skillTagValues);
  }

  function createSkillTagOption(skillTag: API.NoMetadata<API.SkillTag>): Tag {
    return {
      key: skillTag.id,
      label: skillTag.name,
      editable: true,
    };
  }

  async function createSkillTagValue(skillTagId: string): Promise<Tag | undefined> {
    const _skillTag = await API.getSkillTag(skillTagId);
    if (!isMounted.current) return;
    if (API.isFailure(_skillTag)) {
      logger.warn('Failed to fetch skillTag', _skillTag);
      return;
    }

    return {
      key: skillTagId,
      label: _skillTag.name,
      editable: true,
    };
  }

  async function createSkillTag(tagName: string): Promise<API.Result<Tag<API.SkillTag>>> {
    const createSkillTag = await API.createSkillTag({ name: tagName });
    if (API.isFailure(createSkillTag)) {
      tagErrorModal(modal);
      return createSkillTag;
    }

    return { ...createSkillTag, label: createSkillTag.name, key: createSkillTag.id };
  }

  async function updateSkillTag(tag: Tag): Promise<API.Result<void>> {
    const updateSkillTag = await API.updateSkillTag({
      id: tag.key,
      name: tag.label,
    });
    if (!isMounted.current) return;
    if (API.isFailure(updateSkillTag)) {
      tagErrorModal(modal);
      return updateSkillTag;
    }
  }

  async function handlerCreateSkillTag(tag: Tag): Promise<API.Result<Tag<API.SkillTag>>> {
    const createSkillTag = await API.createSkillTag({ name: tag.label });
    if (API.isFailure(createSkillTag)) return createSkillTag;

    return { ...createSkillTag, label: createSkillTag.name, key: createSkillTag.id };
  }

  async function deleteSkillTag(tag: Tag) {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:skillsTab.addEditSkill.warnings.deleteSkillTag'),
        warningAcceptCallback: () => deleteSkillTagWithErrorModalHandling(tag.key, modal),
        warningAcceptButton: capitalizeFirstLetter(t('common:button.yes')),
        warningCancelButton: capitalizeFirstLetter(t('common:button.no')),
      }),
    );
  }

  return (
    <View style={[Styles.secondaryInputContainer]}>
      <DropDownMultiSelection
        isTag
        searchPlaceholder={t('glossary:skillTag_plural')}
        placeholder={t('glossary:skillTag_plural')}
        containerStyle={[Styles.leftPanelInputContainer, Styles.skillTagsContainer]}
        values={skillTagValues}
        options={skillTagOptions}
        title={t('glossary:skillTag_plural')}
        tagTitle={t('glossary:skillTag_plural')}
        listIcon={LabelIcon}
        notEditable={!isPanelEditable}
        createNewOption={{
          createItemHandler: handlerCreateSkillTag,
          createItemIcon: LabelIcon,
          createItemPlaceHolder: t('alex:skills.createSkillTag'),
        }}
        onTagDelete={deleteSkillTag}
        onTagCreate={createSkillTag}
        onTagEdit={updateSkillTag}
        handleChange={updateSkillTags}
      />
      <InputTextDescription
        containerStyle={Styles.commentsContainer}
        placeHolder={t('alex:skills.comments')}
        label={t('alex:skills.comments')}
        defaultValue={skill.description ?? undefined}
        notEditable={!isPanelEditable}
        onTextChange={(inputId: string, inputValue: string) => {
          setSkill({ ...skill, description: inputValue });
        }}
        onSubmitEditing={(inputId: string, inputValue: string) => {
          setSkill({ ...skill, description: inputValue });
        }}
      />
      <AttachFile
        style={Styles.attachFileContainer}
        addFile={isPanelEditable}
        initialFiles={skill.files}
        onFilesChange={onFilesChange}
      />
    </View>
  );
};
