import React, { useState, useEffect, useContext } from 'react';
import { isValidPhoneNumber } from 'shared/util/Worker';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { ModalUtils } from 'shared/ui-component/Modal';
import { Tag } from 'shared/ui-component/Input/InputTag';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { t } from 'shared/localisation/i18n';
import { WorkerState } from 'shared/backend-data';
import { AddEditWorkerModalComponent } from '../component';
import { RouteLocations } from '../../../../../navigation/Routes';
import { showWarningWorkerEditAdd, WorkerEditAddWarning } from '../warningModal';
import { useHistory } from 'react-router-dom';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import * as Pattern from 'shared/util/const';
import { Writable } from 'shared/util-ts/Functions';
import {
  archiveWorkerWithErrorModalHandling,
  deleteWorkerWithErrorModalHandling,
} from 'shared/util/WorkerUi';

export enum ModifyWorkerInputIds {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Email = 'Email',
  Phone = 'Phone',
  RegistrationNumber = 'RegistrationNumber',
  WorkerTags = 'WorkerTags',
  Notes = 'Notes',
  RoleName = 'RoleName',
}

/**
 * Tells if all the given AssignmentsWithDetails are valid
 * @param indexedAssignments
 */
export function isValidIndexedAssignment(indexedAssignments: API.IndexedAssignment[]): boolean {
  const _filteredOrgUnitRole = _.filter(
    indexedAssignments,
    indexedOrganizationalUnitRole =>
      !API.isEmptyAssignment(indexedOrganizationalUnitRole) &&
      indexedOrganizationalUnitRole.state !== API.IndexedAssignmentState.TO_DELETE,
  );
  if (!_filteredOrgUnitRole.length) return false;

  return _.every(
    _filteredOrgUnitRole,
    item => !!item.organizationalUnit.id && !!item.permissions && !!item.role,
  );
}

export interface ProfilePictureFile {
  link: string;
  file: File;
}

export type Input = {
  name: string;
  value: string;
};


export type InputEvent = {
  target: Input;
};

export interface ContractTypeDropDownOption extends DropDownOption {
  value: API.ContractType;
}

export interface ContractWithContractType extends Omit<Writable<API.Contract>, 'contractTypeId'> {
  contractType: API.ContractType;
  newlyUpdatedContract?: boolean;
}

interface CollectedData {
  familyName?: string;
  firstName?: string;
  email?: string | null;
  phone?: string | null;
  profilePicture?: string | null;
  matricule?: string | null;
  team: Tag[];
  imageFile?: File;
  contracts: ContractWithContractType[];
  state?: WorkerState;
  description?: string | null;
}

const defaultContract: ContractWithContractType = {
  __typename: 'Contract',
  contractType: {
    id: '',
    name: '',
    updatedAt: '',
    updatedBy: '',
    __typename: 'ContractType',
  },
};
const defaultCollectedData: CollectedData = {
  team: [],
  contracts: [],
};
let collectedData = API.deepClone(defaultCollectedData);

interface Props {
  /**
   * If passed Worker is edited, otherwise a new Worker is created
   */
  workerId?: string;
  showHideModal: (value: boolean) => void;
  fetchWorkerItems?: (value: boolean) => void;
}

export const AddEditWorkerModalContainer: React.FC<Props> = props => {
  const isMounted = useIsMounted();

  const { workerId, showHideModal: setDisplayWorkerModifyModal, fetchWorkerItems } = props;

  const [assignments, setAssignments] = useState<API.IndexedAssignment[]>([]);
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState<ContractWithContractType[]>(collectedData.contracts);
  const [contractTypeOptions, setContractTypeOptions] = useState<ContractTypeDropDownOption[]>([]);
  const [countryCode, setCountryCode] = useState('+33');
  const [profilePictureFile, setProfilePictureFile] = useState<ProfilePictureFile | null>(null);
  const [worker, setWorker] = useState<API.Worker>();
  const [workerTags, setWorkerTags] = useState<Tag[]>([]);
  const [showGDPRModal, setShowGDPRModal] = useState<boolean>(true);

  const { isValidPermission } = useContext(PermissionManagementContext);

  const history = useHistory();

  const modal = ModalUtils.useModal();

  useEffect(() => {
    loadData();
  }, [isMounted]);

  useEffect(() => {
    return () => {
      resetCollectedData(false);
    };
  }, []);

  useEffect(() => {
    initCollectedData();
  }, [worker]);

  async function arrangeUnits() {
    if (!worker) return;

    const workerAssignments = await API.getWorkerAssignments(worker.id, false, true);
    if (!isMounted.current) return;
    if (API.isFailure(workerAssignments)) {
      logger.warn(workerAssignments);
      return;
    }

    setAssignments(API.orderRolesBasedOnTreeOrder(workerAssignments));
  }

  function handleShowGDPRModal() {
    setShowGDPRModal(false);
  }

  async function loadData() {
    resetCollectedData(false);
    setLoading(true);

    const _result = await API.getUserPreference(
      API.UserPreferenceKeys_Common.WorkerNoteGDPRWarning,
    );
    if (API.isFailure(_result)) {
      logger.error('Error while getting user preference', _result);
      return;
    }

    if (!_result) {
      setShowGDPRModal(true);
    } else {
      setShowGDPRModal(false);
    }

    if (workerId) {
      const _worker = await API.getWorker(workerId);
      if (!isMounted.current) return;
      if (API.isFailure(_worker)) {
        logger.warn(_worker);
        return;
      }
      setWorker(_worker);
    }

    const contractTypes = await API.getContractTypes();
    if (!isMounted.current) return;
    if (API.isFailure(contractTypes)) {
      logger.warn(contractTypes);
      return;
    }

    setContractTypeOptions(
      contractTypes.map(contractType => {
        return {
          value: contractType,
          key: contractType.id,
          label: contractType.name,
        };
      }),
    );
    setLoading(false);
  }

  const initCollectedData = async () => {
    if (worker) {
      const formattedTeams = _.compact(
        await Promise.all(
          _.map(worker.tagIds, async tagId => {
            const _workerTag = await API.getWorkerTag(tagId);
            if (!isMounted.current) return;
            if (API.isFailure(_workerTag)) {
              logger.warn('Failed to fetch WorkerTag ', _workerTag);
              return;
            }
            return { label: _workerTag.name, key: _workerTag.id };
          }),
        ),
      );
      if (!isMounted.current) return;
      setWorkerTags(formattedTeams);

      const contractInfo: ContractWithContractType[] = [];
      await Promise.all(
        worker.contracts.map(async contract => {
          const contractType = await API.getContractType(contract.contractTypeId);
          if (API.isFailure(contractType)) {
            logger.warn(contractType);
            return;
          }

          contractInfo.push({
            ...contract,
            contractType: contractType,
            __typename: 'Contract',
          });
        }),
      );
      if (!isMounted.current) return;

      setContracts(contractInfo);

      await arrangeUnits();
      if (!isMounted.current) return;

      collectedData.familyName = worker.familyName;
      collectedData.firstName = worker.firstName;
      collectedData.email = worker.email;
      collectedData.phone = worker.phone;
      collectedData.profilePicture = worker.profilePicture;
      collectedData.matricule = worker.matricule;
      collectedData.description = worker.description;
    }
  };

  const resetCollectedData = (fetchWorkers: boolean) => {
    fetchWorkers && fetchWorkerItems && fetchWorkerItems(true);
    collectedData = API.deepClone(defaultCollectedData);
    setContracts(collectedData.contracts);
  };

  const addNewContract = () => {
    if (
      contracts.length === 0 ||
      (contracts.length &&
        [...contracts].every(contract => {
          if (
            contract &&
            contract.contractType &&
            !contract.endDate &&
            !contract.contractType.isPauseContract
          ) {
            showWarningWorkerEditAdd(modal, WorkerEditAddWarning.ContractNotEnded);
            return false;
          } else {
            return true;
          }
        }))
    ) {
      setContracts([...contracts, API.deepClone(defaultContract)]);
    }
  };

  const handleInputs = (inputName: string, value: string) => {
    switch (inputName) {
      case ModifyWorkerInputIds.FirstName:
        collectedData.firstName = value;
        break;
      case ModifyWorkerInputIds.LastName:
        collectedData.familyName = value;
        break;
      case ModifyWorkerInputIds.Email:
        collectedData.email = value;
        break;
      case ModifyWorkerInputIds.Phone:
        if (value !== '') {
          collectedData.phone = value;
        } else {
          collectedData.phone = undefined;
        }
        break;
      case ModifyWorkerInputIds.RegistrationNumber:
        collectedData.matricule = value;
        break;
      case ModifyWorkerInputIds.Notes:
        collectedData.description = value;
        break;
    }
  };
  const handleWorkerTagsInput = (inputId: string, teamData: Tag[] | undefined) => {
    if (!teamData) return;

    let formattedTeams: Tag[] = [];
    formattedTeams = teamData.map(item => {
      return {
        key: item?.key && item.key.includes('WORKERTAG#') ? item.key : '',
        label: item?.label ? item.label : '',
      };
    });
    setWorkerTags(formattedTeams);
  };

  const handleStartDateInput = (date: Date | null, index: number) => {
    const _contracts = [...contracts];
    _contracts[index].startDate = date ? date.toISOString() : null;
    _contracts[index].newlyUpdatedContract = true;
    setContracts(_contracts);
  };

  const handleEndDateInput = (date: Date | null, index: number) => {
    const _contracts = [...contracts];
    _contracts[index].endDate = date ? date.toISOString() : null;
    setContracts(_contracts);
  };

  const handleFormCancel = () => {
    resetCollectedData(false);
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        const file = e.target.files[0];
        collectedData.imageFile = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          setProfilePictureFile({ link: '' + e.target?.result, file: file });
        };
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const removeProfilePicture = () => {
    setProfilePictureFile(null);
    collectedData.profilePicture = undefined;
  };

  async function createWorker(): Promise<API.Result<void>> {
    let imageKey = null;
    if (profilePictureFile) {
      const s3Object = await API.uploadFile(profilePictureFile.file, API.StorageVisibility.public);
      if (API.isFailure(s3Object)) {
        logger.warn(s3Object);
      } else {
        imageKey = s3Object.key;
      }
    }
    logger.debug('ProfilePicture ===>', profilePictureFile, imageKey);

    const _workerTagIds: string[] = _.map(workerTags, tag => tag.key);

    const assignmetnsInfoForScopeCreation: API.OrgUnitWithRoleAndPermissionInfo[] = assignments.map(
      assignment => {
        return {
          orgUnitId: assignment.organizationalUnit.id,
          roleAndPermissions: {
            roleId: assignment.role!.id,
            permissions: _.uniq(assignment.permissions),
          },
          shiftId: assignment.shift?.id,
        };
      },
    );
    const workerScope = API.constructNonInheritedAssignmentsAndCreateScope(
      assignmetnsInfoForScopeCreation,
      {},
    );

    const workerInput: API.WorkerCreateInput = {
      email: collectedData.email === '' ? undefined : collectedData.email,
      firstName: collectedData.firstName ?? '',
      familyName: collectedData.familyName ?? '',
      name: collectedData.firstName + ' ' + collectedData.familyName,
      profilePicture: imageKey,
      phone: collectedData.phone || null,
      matricule: collectedData.matricule ? collectedData.matricule : null,
      contracts: collectedData.contracts.map(contract => {
        return {
          contractTypeId: contract.contractType.id,
          startDate: contract.startDate,
          endDate: contract.endDate,
          commnet: contract.comment,
        };
      }),
      tagIds: _workerTagIds,
      description: collectedData.description,
      scope: workerScope,
    };

    if (!isValidIndexedAssignment(assignments))
      return API.createFailure_Unspecified('Organizational units - Role Invalid');

    const fWorker = await API.createFactoryBusinessObject(API.DataType.WORKER, workerInput);
    if (API.isFailure(fWorker)) return fWorker;
  }

  const handleCountrySelect = (value: string) => {
    setCountryCode(value);
  };

  const handleContractTypeOption = (
    data: ContractTypeDropDownOption | undefined,
    index: number,
  ) => {
    const _contracts = [...contracts];
    if (data) {
      _contracts[index].contractType = data.value;
      _contracts[index].newlyUpdatedContract = true;
    } else {
      _contracts.splice(index, 1);
    }
    setContracts(_contracts);
  };

  function verifyAndNormalizeData(): boolean {
    
    if (
      !collectedData.firstName ||
      !collectedData.firstName.length ||
      !collectedData.familyName ||
      !collectedData.familyName.length
    ) {
      showWarningWorkerEditAdd(modal, WorkerEditAddWarning.MissingRequiredFields);
      return false;
    }

    
    if (collectedData.email) {
      if (collectedData.email === '') {
        collectedData.email = undefined;
      } else {
        if (!Pattern.emailPattern.test(collectedData.email)) {
          showWarningWorkerEditAdd(modal, WorkerEditAddWarning.InvalidEmail);
          return false;
        }
      }
    }

    
    if (collectedData.phone) {
      if (collectedData.phone === '' || collectedData.phone === countryCode) {
        collectedData.phone = null;
      } else {
        if (collectedData.phone && !isValidPhoneNumber(collectedData.phone, countryCode)) {
          showWarningWorkerEditAdd(modal, WorkerEditAddWarning.InvalidPhoneNumber);
          return false;
        }
      }
    }

    
    if (collectedData.matricule) {
      if (collectedData.matricule === '') {
        collectedData.matricule = undefined;
      } else {
        if (!Pattern.workerPersonalIdPattern.test(collectedData.matricule)) {
          showWarningWorkerEditAdd(modal, WorkerEditAddWarning.InvalidMatricule);
          return false;
        }
      }
    }

    
    if (!collectedData.email && !collectedData.phone && !collectedData.matricule) {
      showWarningWorkerEditAdd(modal, WorkerEditAddWarning.RequiredPersonalInformation);
      return false;
    }

    
    
    collectedData.contracts = contracts.filter(contract => {
      return !_.isEqual(contract, defaultContract);
    });

    

    if (!isValidIndexedAssignment(assignments)) {
      showWarningWorkerEditAdd(modal, WorkerEditAddWarning.MissingWorkerAssignment);
      return false;
    }

    
    let _isValidPermission = true;
    assignments.forEach(eachOrgUnitRole => {
      if (API.isEmptyAssignment(eachOrgUnitRole)) return;

      if (
        eachOrgUnitRole.state !== API.IndexedAssignmentState.DEFAULT &&
        !isValidPermission(API.Permission.workers_edit, eachOrgUnitRole.organizationalUnit)
      ) {
        _isValidPermission = false;
        return;
      }
    });
    if (!_isValidPermission) {
      showWarningWorkerEditAdd(modal, WorkerEditAddWarning.NoOrgUnitEditPermission);
      return false;
    }

    return true;
  }

  async function addSubmit() {
    if (!verifyAndNormalizeData()) return;

    setLoading(true);
    const result = await createWorker();
    if (!isMounted.current) return;
    setLoading(false);
    if (API.isFailure(result)) {
      showWarningWorkerEditAdd(modal, result);
      return;
    }
    resetCollectedData(true);
    functions.setDisplayWorkerModifyModal(false);
  }

  async function archiveWorker(workerUpdateInput: API.WorkerPartialUpdateInput) {
    if (workerUpdateInput) {
      const archivedWorker = await archiveWorkerWithErrorModalHandling(workerUpdateInput, modal);
      if (archivedWorker) {
        setDisplayWorkerModifyModal(false);
        history.push(RouteLocations.Workers());
      }
    }
    setLoading(false);
  }

  async function activateWorker() {
    const activateWorker = await editWorker(true);
    if (!isMounted.current) return;
    if (API.isFailure(activateWorker)) {
      logger.error(activateWorker);
      return;
    } else {
      setDisplayWorkerModifyModal(false);
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:worker.addEditWorker.warnings.reinstateWorker'),
          callback: fetchWorkerItems,
        }),
      );
    }
  }

  async function editWorker(activateWorker?: boolean): Promise<API.Result<API.Worker> | void> {
    if (!worker) return;
    if (!verifyAndNormalizeData())
      return API.createFailure_Unspecified('error while updating worker');

    setLoading(true);

    let imageKey: string | undefined = undefined;
    if (profilePictureFile) {
      const s3Object = await API.uploadFile(
        profilePictureFile.file,
        API.StorageVisibility.public,
        collectedData.profilePicture ?? undefined,
      );
      if (!isMounted.current) return;
      if (API.isFailure(s3Object)) {
        logger.warn(s3Object);
      } else {
        imageKey = s3Object.key;
      }
    }

    const assignmetnsInfoForScopeCreation: API.OrgUnitWithRoleAndPermissionInfo[] = assignments.map(
      assignment => {
        return {
          orgUnitId: assignment.organizationalUnit.id,
          roleAndPermissions: {
            roleId: assignment.role!.id,
            permissions: _.uniq(assignment.permissions),
          },
          shiftId: assignment.shift?.id,
        };
      },
    );
    const workerScope = API.constructNonInheritedAssignmentsAndCreateScope(
      assignmetnsInfoForScopeCreation,
      API.extractScopeFromWorker(worker).inheritedRolesOnOrgUnits,
    );

    const workerUpdateInput: API.WorkerPartialUpdateInput = {
      id: worker.id,
      familyName: collectedData.familyName,
      firstName: collectedData.firstName,
      name: '',
      email: collectedData.email,
      phone: collectedData.phone,
      matricule: collectedData.matricule,
      profilePicture: imageKey ?? collectedData.profilePicture,
      state: activateWorker ? API.WorkerState.ACTIVE : collectedData.state ?? undefined,
      description: collectedData.description,
      contracts: collectedData.contracts.map(contract => {
        return {
          contractTypeId: contract.contractType.id,
          startDate: contract.startDate,
          endDate: contract.endDate,
          comment: contract.comment,
        };
      }),
      tagIds: workerTags.map(tag => tag.key),
      scope: workerScope,
    };

    const _worker = await API.updateWorker(workerUpdateInput);
    if (!isMounted.current) return;
    if (API.isFailure(_worker)) {
      showWarningWorkerEditAdd(modal, _worker);
      setLoading(false);
      return _worker;
    }

    return _worker;
  }

  async function editSubmit() {
    const _editWorker = await editWorker();
    if (API.isFailure(_editWorker)) {
      logger.warn(_editWorker);
      return;
    }
    functions.setDisplayWorkerModifyModal(false);
  }

  const warnAdminOrInvitedWorker = () => {
    showWarningWorkerEditAdd(modal, WorkerEditAddWarning.AdminOrInvitedPersonalInformation);
  };

  function confirmDelete(index: number) {
    contracts.splice(index, 1);
    setContracts(API.deepClone(contracts));
  }

  function handleContractDelete(index: number) {
    showWarningWorkerEditAdd(
      modal,
      WorkerEditAddWarning.Delete,
      t('common:button.yes'),
      () => confirmDelete(index),
      t('common:button.no'),
    );
  }

  async function deleteWorker() {
    if (!data.worker) return;

    setLoading(true);

    const deleteWorker = await deleteWorkerWithErrorModalHandling(data.worker, modal);
    if (!isMounted.current) return;

    if (deleteWorker) {
      setDisplayWorkerModifyModal(false);
      setLoading(false);
      history.push(RouteLocations.Workers());
    } else {
      setLoading(false);
    }
  }

  const data = {
    showLoader: loading,
    editWorker: workerId ? true : false,
    profilePictureFile,
    contractTypeOptions,
    contracts,
    countryCode,
    worker,
    workerTags,
    indexedAssginments: assignments,
    showGDPRModal,
  };
  const functions = {
    removeProfilePicture,
    onFileChange,
    handleInputs,
    handleContractTypeOption,
    handleCountrySelect,
    handleFormCancel,
    handleWorkerTagsInput,
    addSubmit,
    editSubmit,
    handleStartDateInput,
    handleEndDateInput,
    addNewContract,
    warnAdminOrInvitedWorker,
    archiveWorker,
    deleteWorker,
    setDisplayWorkerModifyModal,
    setLoading,
    activateWorker,
    handleContractDelete,
    setAssignments,
    handleShowGDPRModal,
  };
  return <AddEditWorkerModalComponent data={data} functions={functions} />;
};
