import React, { useState, useEffect, useContext } from 'react';
import { Colors } from 'shared/styles';
import * as API from 'shared/backend-data';
import { TableNumberWithMenu } from 'sharedweb/src/NumberWithMenu';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../../../navigation/Routes';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { InteractionManager } from 'react-native';
import Aigle from 'aigle';
import * as _ from 'lodash-es';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';

interface SkillDetails {
  totalSkills: number;
  validSkillIds: string[];
  nbOfExpireSoonSkills?: number;
  nbOfExpiredSkills?: number;
}

export const SkillCount: React.FC<SkillDetails> = props => {
  const { totalSkills, validSkillIds, nbOfExpiredSkills, nbOfExpireSoonSkills } = props;
  const isMounted = useIsMounted();
  const history = useHistory();
  const { isValidPermission } = useContext(PermissionManagementContext);

  const [skills, setSkills] = useState<API.Skill[]>([]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const _skills: API.Skill[] = [];
      await Aigle.map(validSkillIds, async validSkill => {
        const ids = validSkill.split(API.SeparatorIds);
        const _validSkill = await API.getSkill(ids[2]);
        if (!isMounted.current) return;
        if (API.isFailure(_validSkill)) {
          logger.warn('Failed to fetch Skill ', _validSkill);
          return _validSkill;
        }
        _skills.push(_validSkill);
      });

      setSkills(_skills);
    });
  }, [validSkillIds]);

  let skillCountColor = Colors.Black;
  if (nbOfExpiredSkills) skillCountColor = Colors.Red;
  else if (nbOfExpireSoonSkills) skillCountColor = Colors.Orange;

  return (
    <TableNumberWithMenu
      list={skills}
      count={`${skills.length ?? '-'}/${totalSkills ?? '-'}`}
      countColor={skillCountColor}
      style={{ left: 60 }}
      onMenuItemPress={(id: string) => history.push(RouteLocations.SkillProfile(id))}
      disabled={!isValidPermission(API.Permission.skills_edit)}
    />
  );
};
