import { StyleSheet } from 'react-native';
import { Colors, Spacings } from 'shared/styles';
import * as SharedStyles from 'shared/styles/index';

const shared = {
  tabInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    
    
    
  } as StyleSheet.NamedStyles<any>,
  tabInnerContainerWeb: {
    height: '100%',
    borderRadius: 50,
    paddingTop: SharedStyles.Spacings.Small,
    paddingHorizontal: 40,
    alignContent: 'center',
    justifyContent: 'center',
  } as StyleSheet.NamedStyles<any>,
  titleTabTextStyle: {
    textAlign: 'center',
    fontSize: SharedStyles.fontSize.Small,
  } as StyleSheet.NamedStyles<any>,
};

const styles = StyleSheet.create({
  container: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    width: 'auto',
    flexDirection: 'column',
    alignSelf: 'center',
    paddingHorizontal: 35,
  },
  mobContainer: {
    height: 60,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    width: '100%',
    flexDirection: 'column',
  },
  tabsTitleContainer: {
    width: '100%',
    height: '100%',
  },
  mobTabsTitleContainer: {
    width: '100%',
    height: '60%',
    paddingLeft: 20,
    paddingTop: 16,
  },
  tabsTitleTextStyle: { fontSize: 16, fontFamily: 'Lato, Black', fontWeight: '900' },
  tabsInnerContainer: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    alignContent: 'center',
    paddingTop: Spacings.CardPadding,
    paddingBottom: 1,
  },
  mobTabsInnerContainer: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    borderBottomColor: Colors.Background,
    borderBottomWidth: 1,
  },
  tabsInnerView: { width: '100%', height: '100%' },
  tabOuterContainer: {
    height: 32,
    backgroundColor: SharedStyles.Colors.Background,
    borderRadius: 20,
    alignContent: 'center',
  },
  mobTabOuterContainer: {
    width: '100%',
    height: '100%',
    alignContent: 'center',
    paddingTop: Spacings.CardPadding,
  },
  iconTabInnerContainer: { flex: 1, alignItems: 'center' },
  tabInnerContainer: {
    ...shared.tabInnerContainer,
    ...shared.tabInnerContainerWeb,
    backgroundColor: SharedStyles.Colors.Background,
  },
  tabInnerContainerSelected: {
    ...shared.tabInnerContainer,
    ...shared.tabInnerContainerWeb,
    backgroundColor: SharedStyles.Colors.White,
    
    
    shadowColor: '#00000029',
    shadowRadius: 2,
  },
  mobTabInnerContainer: {
    ...shared.tabInnerContainer,
  },
  iconSVGWrapper: {
    backgroundColor: Colors.Yellow,
    width: 7,
    height: 7,
    borderRadius: 50,
    position: 'absolute',
    top: 0,
    right: 6,
  },

  titleTabTextStyle: {
    ...shared.titleTabTextStyle,
    ...SharedStyles.TextFontStyles.LatoMedium,
    color: SharedStyles.Colors.Grey,
  },
  titleTabTextStyleSelected: {
    ...shared.titleTabTextStyle,
    ...SharedStyles.TextFontStyles.LatoBold,
    color: SharedStyles.Colors.Grey,
  },
  mobTitleTabTextStyle: {
    ...shared.titleTabTextStyle,
    ...SharedStyles.TextFontStyles.LatoMedium,
    fontSize: SharedStyles.fontSize.Regular,
    paddingTop: Spacings.Unit / 4,
  },

  titleTabTotalNumberTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: 10,
    maxHeight: 14,
  },
  mobTitleTabTotalNumberTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Small,
    maxHeight: 14,
  },

  tabsInnerContainerContentContainerStyle: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    width: '100%',
  },
  tabIndexView: {
    width: '100%',
    height: 3,
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
  },
  tabIndexInnerView: {
    height: '100%',
    backgroundColor: SharedStyles.Colors.Black,
    borderRadius: 2,
  },
  tabItem: {
    flexGrow: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  tabContainerMobile: {
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    flex: 1,
  },
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: SharedStyles.Colors.Background,
    borderRadius: 20,
    height: 32,
  },
});
export default styles;
