import { StyleSheet } from 'react-native';
import { TextFontStyles, Spacings, Colors, fontSize } from 'shared/styles';
import { ROW_HEIGHT } from '../../workstation-worker-level-table/styles';

const styles = StyleSheet.create({
  activityIndicatorView: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  workerNameContainer: {
    width: '100%',
    height: '100%',
  },
  polyvalenceContainer: {
    height: 128,
    justifyContent: 'center',
  },
  polyvalenceText: {
    ...TextFontStyles.LatoBlack,
    transform: [{ rotate: '-45deg' }],
    height: 25,
    position: 'absolute',
    marginLeft: '75%',
    fontSize: fontSize.Regular,
    fontWeight: 900,
    opacity: 1,
    letterSpacing: 0,
    marginTop: 38,
  },
  InnerTableContainer: {
    width: '100%',
  },
  emptyWorkerText: {
    transform: [{ rotate: '-45deg' }],
    minWidth: '120%',
    marginTop: '70%',
  },
  usersFlatList: {
    paddingTop: Spacings.Small,
    paddingBottom: Spacings.xMedium,
  },
  userTouchableOpacity: {
    flexDirection: 'row',
    height: ROW_HEIGHT,
    alignItems: 'center',
    width: '100%',
    paddingBottom: 2,
    paddingLeft: Spacings.MobileIconSize,
    justifyContent: 'space-between',
    paddingRight: Spacings.Standard,
  },
  userShiftWrapper: { justifyContent: undefined, alignItems: 'center', paddingTop: Spacings.Small },
  userShiftText: {
    borderRadius: 10,
    backgroundColor: Colors.Background,
    paddingHorizontal: Spacings.Unit + Spacings.Unit / 2,
    paddingVertical: Spacings.Unit / 2,
    width: undefined,
    flex: undefined,
    minWidth: undefined,
    flexGrow: undefined,
    flexShrink: undefined,
  },
  userInnerView: { height: '100%', justifyContent: 'flex-end' },
  userNameText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingLeft: 6,
    color: Colors.Black,
  },
  userPolyNo: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.XLarge,
    zIndex: -1,
    width: Spacings.CardPadding * 2,
    textAlign: 'center',
  },
  workerHoverText: {
    ...TextFontStyles.LatoBlack,
  },
  workerHoverTextContainer: {
    backgroundColor: Colors.GreyUltraLight,
    paddingTop: 3,
    paddingBottom: Spacings.Unit,
    borderRadius: Spacings.Unit,
  },
  workerSkillBadge: {
    top: -5,
  },
  workerNameRowContainer: {
    flex: 0.9,
    flexDirection: 'row',
  },
  workerName: {
    width: '80%',
  },
  nameText: {
    ...TextFontStyles.SemiBold,
    textAlign: 'left',
    fontSize: fontSize.Regular,
    color: Colors.MediumGray,
    justifyContent: 'center',
  },
  sortComponent: {
    paddingLeft: 28,
  },
});

export default styles;
