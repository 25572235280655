import React from 'react';
import { useModal, ModalConfig, ModalContext } from './Modal';
import { Toast } from './Toast';
import { DropdownPicker } from './DropdownPicker';
import { Warning, WarningModalProps } from './Warning';
import { Menu } from './Menu';
import { TenantAppLoading } from './LoadingData';
import { Loader } from './loader';
import { AddProofLoading } from './AddProofLoading';
import { TagModalComponent } from './TagModal';
import { CustomModal } from './CustomModal';
import { showVetoModal } from './VetoModal';
import { Failure } from '../../backend-data/Failure';
import { t } from '../../localisation/i18n';
import { CompleteUserInfo } from 'shared/ui-component/Modal/CompleteUserInfo/container/index';
import { AppFeature } from 'backend/src/api';
import { getWorkersAdmins } from '../../util/Worker';

export { ModalProvider, useModal } from './Modal';
export type { ModalContext } from './Modal';

type CommonConfig = Omit<ModalConfig, 'content'>;

type WarningModalProps2 = WarningModalProps & { isOpaque?: boolean };

/**
 * Magic Function that returns a config for given component props
 * @param Comp
 * @param config
 */
function configBuilder<T extends {}>(
  Comp: React.FC<T>,
  config?: CommonConfig,
): (props: T, customConfig?: CommonConfig) => ModalConfig {
  return (props, customConfig?: CommonConfig) => ({
    content: <Comp {...props} />,
    ...config,
    ...customConfig,
  });
}

const toastConfig = configBuilder(Toast, {
  hideOnClick: false,
  opaque: false,
  mobileModalOpacity: 0.5,
});

const dropdownConfig = configBuilder(DropdownPicker, {
  hideOnClick: true,
  opaque: false,
  mobileModalOpacity: 0.5,
});

const warningConfig = configBuilder(Warning, {
  hideOnClick: false,
  opaque: false,
  mobileModalOpacity: 0.5,
});

const warningOpaqueConfig = configBuilder(Warning, {
  hideOnClick: false,
  opaque: true,
  mobileModalOpacity: 1,
});

function showWarning(modal: ModalContext, props: WarningModalProps2) {
  modal.displayModal(props.isOpaque ? warningOpaqueConfig(props) : warningConfig(props));
}

/**
 * Dispay a Warning Modal with failure's details
 * @param failure API.Failure to extract error details from
 * @param isBlocking optional (default false): make the modal non-dismissible
 * @param isOpaque optional (default false): hide backdrop
 */
function showWarningFailure(
  modal: ModalContext,
  failure: Failure,
  isBlocking?: boolean,
  isOpaque?: boolean,
) {
  const modalConfig = (props: WarningModalProps): ModalConfig => {
    return isOpaque ? warningOpaqueConfig(props) : warningConfig(props);
  };

  modal.displayModal(
    modalConfig({
      isBlocking: !!isBlocking,
      isGeneric: true,
      details: failure.message.length ? failure.message : t('common:error.noDetails'),
      warningMessage: t('common:error.generic'),
      warningAcceptButton: t('common:button.details'),
    }),
  );
}

async function showFeatureMissing(
  modal: ModalContext,
  showFeatureMissingAcceptCallback: () => void,
  feature?: AppFeature,
  isOpaque?: boolean,
): Promise<void> {
  const admins = await getWorkersAdmins();

  const modalConfig = (props: WarningModalProps): ModalConfig => {
    return isOpaque ? warningOpaqueConfig(props) : warningConfig(props);
  };

  modal.displayModal(
    modalConfig({
      warningMessage: t('alex:app.featureMissing.training.message'),
      warningSubMessage: t('alex:app.featureMissing.training.subMessage', {
        admins: admins?.map(admin => admin.name).join(', '),
      }),
      warningAcceptButton: t('common:button.ok'),
      warningAcceptCallback: showFeatureMissingAcceptCallback,
      
      
      
      
      
      
      
      isGeneric: false,
      
    }),
  );
}

const menuConfig = configBuilder(Menu, {
  hideOnClick: false,
  opaque: false,
  mobileModalOpacity: 0,
});

const TenantAppLoadingConfig = configBuilder(TenantAppLoading, {
  hideOnClick: false,
  opaque: true,
  mobileModalOpacity: 0.5,
});

const CompleteUserData = configBuilder(CompleteUserInfo, {
  hideOnClick: false,
  opaque: true,
  mobileModalOpacity: 0.5,
});

const AddProofLoadingConfig = configBuilder(AddProofLoading, {
  hideOnClick: false,
  opaque: true,
  mobileModalOpacity: 0.5,
});

const LoaderConfig = configBuilder(Loader);

const TagModalConfig = configBuilder(TagModalComponent, {
  hideOnClick: false,
  opaque: false,
  mobileModalOpacity: 0.5,
});

const CustomModalConfig = configBuilder(CustomModal, {
  hideOnClick: false,
  opaque: false,
  mobileModalOpacity: 0.5,
});

export const ModalUtils = {
  useModal,
  showWarning,
  warningConfig,
  showWarningFailure,
  showVetoModal,
  toastConfig,
  dropdownConfig,
  menuConfig,
  TenantAppLoadingConfig,
  LoaderConfig,
  AddProofLoadingConfig,
  TagModalConfig,
  CustomModalConfig,
  CompleteUserData,
  showFeatureMissing,
};
