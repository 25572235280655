import * as _ from 'lodash-es';
import moment from 'moment';
import logger from './Logger';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, i18n } from 'shared/localisation/i18n';
import * as Perf from './Perf';
import { Validity } from 'backend/src/api';
import Aigle from 'aigle';
import { searchMatch } from '../util-ts/Functions';
import { getExcelDelimiter } from './ExcelUtils';
import { TableRow } from 'shared/ui-component/Table';

export interface SkillsToRenewOrAcquire {
  skillsToRenew: string[];
  skillsToAcquire: string[];
}

export enum SkillBadgeStates {
  OK = 1,
  EXPIRE_SOON = 2,
  EXPIRED = 3,
}

export const SKILL_MAX_MONTHS = 120;
export const SKILL_MAX_YEARS = 10;
export const SKILL_EXPIRY_NOTICE_MAX_DAYS = 180;

export interface RequiredSkillAndWorkerSkill {
  workerSkill?: API.WorkerSkill;
  requiredSkill: API.Skill;
  requirementId: string;
  workstationId: string;
  requiredTrainingVersionId?: string | null;
}

export interface SkillWorkerTableRow extends TableRow {
  worker: API.Worker;
  skillData?: API.WorkerSkillWithComplementaryDetails;
  orgUnits?: API.OrganizationalUnit[];
}

export interface WorkstationSkillsRequiredInLevels {
  skillsRequiredInLevels: Map<API.WorkstationWorkerLevels, RequiredSkillAndWorkerSkill[]>;
  totalSkills: string[];
}

export interface WorkerSkillRequirement {
  workerId: string;
  skillId: string;
  workerSkillId?: string; 
}

export interface WorkerSkillWithComplementaryDetails extends API.WorkerSkill {
  stateString: string;
  daysDifference?: null | number;
  expiryDate?: null | string;
  skill: API.Skill;
}




/**
 * CAREFUL! Duplicated Function in backend lambdas!
 * !
 * !
 * @param skill
 * @returns
 */
export async function getSkillValidityDurationAndExpiryNoticeDuration(
  skill: API.Skill | API.NoMetadata<API.Skill>,
): Promise<API.SkillValidityDurationAndExpiryNoticeDuration> {
  let validityDuration: number | null | undefined;
  let expiryNoticeDuration: number | null | undefined;

  if (API.isSkillGroup(skill)) {
    
    await Aigle.mapSeries(skill.skillIds, async skillId => {
      const skill = await API.getSkill(skillId);
      if (API.isFailure(skill)) return skill;

      const { validityDuration: _validityDuration, expiryNoticeDuration: _expiryNoticeDuration } =
        await getSkillValidityDurationAndExpiryNoticeDuration(skill);

      if (_validityDuration && _expiryNoticeDuration) {
        if (!validityDuration || validityDuration > _validityDuration) {
          validityDuration = _validityDuration;
          expiryNoticeDuration = _expiryNoticeDuration;
        }
      }
    });
  } else {
    validityDuration = skill.validityDuration;
    expiryNoticeDuration = skill.expiryNoticeDuration;
  }

  return { validityDuration, expiryNoticeDuration };
}

export async function getSkillsAndSubSkillsIds(
  skills: API.NoMetadata<API.Skill>[],
): Promise<API.Result<{ skillIds: Set<string>; skillGroupIds: Set<string> }>> {
  let skillIds = new Set<string>();
  let skillGroupIds = new Set<string>();

  
  const failure = await API.mapSeries(skills, async skill => {
    const _isSkillGroup = API.isSkillGroup(skill);

    (_isSkillGroup ? skillGroupIds : skillIds).add(skill.id);

    if (_isSkillGroup) {
      const _subSkills = await API.mapSeries(skill.skillIds, async skillId => {
        return API.getSkill(skillId);
      });
      if (API.isFailure(_subSkills)) return _subSkills;

      const _skillsAndSkillGroupIds = await getSkillsAndSubSkillsIds(_subSkills);
      if (API.isFailure(_skillsAndSkillGroupIds)) return _skillsAndSkillGroupIds;

      skillIds = new Set([...skillIds, ..._skillsAndSkillGroupIds.skillIds]);
      skillGroupIds = new Set([...skillGroupIds, ..._skillsAndSkillGroupIds.skillGroupIds]);
    }
  });
  if (API.isFailure(failure)) return failure;

  return { skillIds, skillGroupIds };
}

export async function getSubSkillsFromSkillGroup(
  skill: API.Modify<API.Skill, { skillIds: string[] }>,
): Promise<API.Result<API.Skill[]>> {
  const skills = await API.mapSeries(skill.skillIds, async skillId => {
    const _skill = await API.getSkill(skillId);
    if (API.isFailure(_skill)) return _skill;
    if (API.isSkillGroup(_skill)) {
      return getSubSkillsFromSkillGroup(_skill);
    } else {
      return _skill;
    }
  });
  if (API.isFailure(skills)) return skills;

  return skills.flat();
}

export function getWorkerSkillValidityInfoKey(obj: API.WorkerSkill) {
  return obj.workerId + obj.skillId;
}

export async function getSkill(skillId: string): Promise<API.Result<API.Skill>> {
  const result = await API.getFactoryBusinessObject(API.DataType.SKILL, skillId);
  if (API.isFailure(result)) return result;

  return { ...result.skill, updatedAt: result.updatedAt, updatedBy: result.owner };
}

/**
 * Retrieve all the Skills id of the Company (Tenant)
 * @param itemsLimit
 * @param nextToken
 */
export async function getSkillIds(
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<string[]>> {
  return _getSkills(true, itemsLimit, nextToken);
}

/**
 * Retrieve all the Skills of the Company (Tenant)
 * To not mix with WorkerSkill (Skill that a Worker have)
 * or LevelSkill (a Skill required by a Workstation)
 * @param itemsLimit
 * @param nextToken
 */
export async function getSkills(
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.Skill[]>> {
  return _getSkills(false, itemsLimit, nextToken);
}

async function _getSkills(
  returnOnlyIds: true,
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<string[]>>;
async function _getSkills(
  returnOnlyIds: false,
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.Skill[]>>;
async function _getSkills(
  returnOnlyIds: boolean,
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.Skill[] | string[]>> {
  const factories = await API.listFactoriesWithDataType(
    API.DataType.SKILL,
    undefined,
    itemsLimit,
    nextToken,
  );
  if (API.isFailure(factories)) return factories;

  if (returnOnlyIds) {
    return {
      result: _.map(factories.result, factory => factory.skill!.id),
      nextToken: factories.nextToken,
    };
  } else {
    return {
      result: _.map(factories.result, factory => {
        return { ...factory.skill!, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
      }),
      nextToken: factories.nextToken,
    };
  }
}

/**
 * Return the Skill for the given name.
 * Returns a Failure if there are several Skills with the same name.
 * WARNING: it shall not be used to test the existence of a Skill as the result depends on the User scope (what data user can see).
 * @param skillName
 */
export async function getSkillByName(
  skillName: string,
): Promise<API.Result<API.Skill | undefined>> {
  const skills = await API.getSkills();
  if (API.isFailure(skills)) return skills;

  let result: API.Skill | undefined = undefined;
  for (const skill of skills.result) {
    if (searchMatch(skill.name, skillName, true)) {
      if (result)
        return API.createFailure_Unspecified(
          'Several Skills have the name (' + skillName + '). Consider passing the id instead.',
        );
      result = skill;
    }
  }
  return result;
}

/**
 * Construct the WorkerSkill id form a workerId and a skillId
 * @param workerId
 * @param skillId
 * @returns
 */
export function getWorkerSkillId(workerId: string, skillId: string): API.Result<string> {
  const workerDataType = API.getDataType(workerId);
  if (API.isFailure(workerDataType)) return workerDataType;
  if (workerDataType !== API.DataType.WORKER)
    return API.createFailure_Unspecified('Passed id (' + workerId + ') shall be of type Worker');

  const skillDataType = API.getDataType(skillId);
  if (API.isFailure(skillDataType)) return skillDataType;
  if (skillDataType !== API.DataType.SKILL)
    return API.createFailure_Unspecified('Passed id (' + skillId + ') shall be of type Skill');

  return (
    API.DataType.WORKERSKILL +
    API.SeparatorDataType +
    API.SeparatorIds +
    workerId +
    API.SeparatorIds +
    skillId
  );
}

/**
 * Extract workerId and skillId from a workerSkillId
 * @param workerSkillId
 */
export function getWorkerIdSkillId(
  workerSkillId: string,
): API.Result<{ workerId: string; skillId: string }> {
  const dataType = API.getDataType(workerSkillId);
  if (API.isFailure(dataType)) return dataType;
  if (dataType !== API.DataType.WORKERSKILL)
    return API.createFailure_Unspecified(
      'Passed id (' + workerSkillId + ') shall be of type WorkerSkill',
    );

  const ids = workerSkillId.split(API.SeparatorIds);
  return {
    workerId: ids[1],
    skillId: ids[2],
  };
}

export async function getWorkerSkill(
  workerId: string,
  skillId: string,
): Promise<API.Result<API.WorkerSkill>> {
  const workerSkillId = getWorkerSkillId(workerId, skillId);
  if (API.isFailure(workerSkillId)) return workerSkillId;

  const factory = await API.getFactoryBusinessObject(API.DataType.WORKERSKILL, workerSkillId);
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.workerSkill,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function getWorkerSkillByWorkerSkillId(
  workerSkillId: string,
): Promise<API.Result<API.WorkerSkill>> {
  const result = await API.getFactory(API.DataType.WORKERSKILL, workerSkillId);
  if (API.isFailure(result)) return result;

  return { ...result.workerSkill, updatedAt: result.updatedAt, updatedBy: result.updatedBy };
}

/**
 * Retrieve all the WorkerSkills
 * @param workerId (optional can't be used with skillId) if not set all the WorkerSkills will be returned
 * @param skillId (optional can't be used with workerId) if not set all the WorkerSkills will be returned
 * @param itemsLimit limit roughly the number of returned WorkerSkills
 * @param nextToken
 */ export async function getWorkerSkills(
  workerId?: string,
  skillId?: string,
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.WorkerSkill[]>> {
  let factories: API.ResultWithNextToken<API.Factory<API.DataType.WORKERSKILL>[]>;
  if (workerId && skillId) {
    return API.createFailure_Unspecified(
      'Performance: please use ' + getWorkerSkill.name + ' instead',
    );
  } else if (workerId) {
    const id = API.DataType.WORKERSKILL + API.SeparatorDataType + API.SeparatorIds + workerId;
    factories = await API.listFactoriesWithSk(
      API.DataType.WORKERSKILL,
      id,
      API.KeyComparator.beginsWith,
      undefined,
      itemsLimit,
      nextToken,
    );
  } else if (skillId) {
    const data = API.DataType.WORKERSKILL + API.SeparatorDataType + API.SeparatorIds + skillId;
    factories = await API.listFactoriesWithData(
      API.DataType.WORKERSKILL,
      data,
      API.KeyComparator.beginsWith,
      undefined,
      itemsLimit,
      nextToken,
    );
  } else {
    factories = await API.listFactoriesWithDataType(
      API.DataType.WORKERSKILL,
      undefined,
      itemsLimit,
      nextToken,
    );
  }
  if (API.isFailure(factories)) return factories;

  return {
    result: _.map(factories.result, factory => {
      return {
        ...factory.workerSkill,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      };
    }),
    nextToken: factories.nextToken,
  };
}

/**
 * Retrieve all the WorkerSkills with their complementary details (skill, stateString, daysDifference and expiryDate)
 * @param workerId (optional can't be used with skillId) if not set all the WorkerSkills will be returned
 * @param skillId (optional can't be used with workerId) if not set all the WorkerSkills will be returned
 * @param itemsLimit limit roughly the number of returned WorkerSkills
 * @param nextToken
 */ export async function getWorkerSkillsWithComplementaryDetails(
  workerId?: string,
  skillId?: string,
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.WorkerSkillWithComplementaryDetails[]>> {
  const workerSkills = await getWorkerSkills(workerId, skillId, itemsLimit, nextToken);
  if (API.isFailure(workerSkills)) return workerSkills;
  const workerSkillsWithComp = _.compact(
    await Aigle.map(workerSkills.result, async workerSkill => {
      const workerSkillComp = await API.getWorkerSkillComplementaryDetails(workerSkill);
      if (API.isFailure(workerSkillComp)) {
        logger.warn('Failed to fetch WorkerSkillComplementaryDetails', workerSkillComp);
        return;
      }

      return workerSkillComp;
    }),
  );
  return {
    nextToken: workerSkills.nextToken,
    startedAt: workerSkills.startedAt,
    result: workerSkillsWithComp,
  };
}

export async function deleteWorkerSkill(
  workerSkillId: string,
): Promise<API.Result<API.Factory<API.DataType.WORKERSKILL>>> {
  return API.deleteFactoryBusinessObject(workerSkillId);
}

export async function getWorkerSkillComplementaryDetails(
  workerSkill: API.WorkerSkill,
): Promise<API.Result<WorkerSkillWithComplementaryDetails>> {
  const skill = await API.getSkill(workerSkill.skillId);
  if (API.isFailure(skill)) return skill;

  if (API.isSkillGroup(skill)) {
    let stateString = '';
    if (!workerSkill.validity) stateString = '';
    if (workerSkill.validity === Validity.OK)
      stateString = capitalizeFirstLetter(i18n.t('alex:workerSkill.valid'));
    else if (workerSkill.validity === Validity.OK_EXPIRE_SOON)
      stateString = capitalizeFirstLetter(i18n.t('alex:workerSkill.expiresSoon'));
    else if (workerSkill.validity === Validity.KO_EXPIRED)
      stateString = capitalizeFirstLetter(i18n.t('alex:workerSkill.expired'));
    else if (workerSkill.validity === Validity.KO_MISSING)
      stateString = capitalizeFirstLetter(i18n.t('alex:workerSkill.proofMissing'));
    else if (workerSkill.validity === Validity.KO_REJECTED)
      stateString = capitalizeFirstLetter(i18n.t('alex:workerSkill.proofRejectedOn'));
    else if (workerSkill.validity === Validity.KO_NEW)
      stateString = capitalizeFirstLetter(i18n.t('alex:workerSkill.waitingForValidation'));

    return {
      ...workerSkill,
      expiryDate: null,
      daysDifference: null,
      stateString,
      skill,
    };
  }

  const { validityDuration, expiryNoticeDuration } =
    await getSkillValidityDurationAndExpiryNoticeDuration(skill);

  const activeProofBundle = workerSkill.activeProofBundle;
  const toReviewProofBundle = workerSkill.toReviewProofBundle;

  if (!activeProofBundle) {
    if (!toReviewProofBundle) {
      return {
        ...workerSkill,
        expiryDate: null,
        daysDifference: null,
        stateString: capitalizeFirstLetter(i18n.t('alex:workerSkill.proofMissing')),
        skill,
      };
    } else {
      return {
        ...workerSkill,
        expiryDate: null,
        daysDifference: null,
        stateString: capitalizeFirstLetter(i18n.t('alex:workerSkill.waitingForValidation')),
        skill,
      };
    }
  }

  const newProofbundlePendingReview = toReviewProofBundle
    ? toReviewProofBundle.startingDate > activeProofBundle.startingDate
    : false;

  if (
    workerSkill.activeProofBundle?.review?.state === API.ReviewState.REJECTED ||
    workerSkill.activeProofBundle?.review?.state === API.ReviewState.REJECTED_TO_RESUBMIT
  ) {
    return {
      ...workerSkill,
      expiryDate: null,
      daysDifference: null,
      stateString: capitalizeFirstLetter(
        newProofbundlePendingReview
          ? i18n.t('alex:workerSkill.waitingForValidation')
          : i18n.t('alex:workerSkill.proofRejectedOn', {
              date: moment(activeProofBundle.review.date).format('L'),
            }),
      ),
      skill,
    };
  }

  if (validityDuration == null || validityDuration === 0) {
    return {
      ...workerSkill,
      expiryDate: null,
      daysDifference: null,
      stateString: capitalizeFirstLetter(
        newProofbundlePendingReview
          ? i18n.t('alex:workerSkill.waitingForValidation')
          : i18n.t('alex:workerSkill.neverExpire'),
      ),
      skill,
    };
  }

  const workerSkillExpiryDate = moment(activeProofBundle.startingDate).add(
    validityDuration,
    'months',
  );
  const days = workerSkillExpiryDate.diff(moment(new Date()).utc(), 'days');
  
  const months = workerSkillExpiryDate.diff(moment.utc(), 'months', true);
  const monthsRounded = Math.round(months);

  const duration =
    months < 1
      ? `${days} ${i18n.t('common:time.day', { count: days })}`
      : `${monthsRounded} ${i18n.t('common:time.month', { count: monthsRounded })}`;

  if (days <= 0) {
    return {
      ...workerSkill,
      expiryDate: workerSkillExpiryDate.toISOString(),
      daysDifference: days,
      stateString: capitalizeFirstLetter(
        newProofbundlePendingReview
          ? i18n.t('alex:workerSkill.waitingForValidation')
          : i18n.t('alex:workerSkill.expiredSinceWithDate', {
              duration: workerSkillExpiryDate.fromNow(true),
              date: moment(workerSkillExpiryDate).format('L'),
            }),
      ),
      skill,
    };
  } else if (!expiryNoticeDuration || days > expiryNoticeDuration) {
    return {
      ...workerSkill,
      expiryDate: workerSkillExpiryDate.toISOString(),
      daysDifference: days,
      stateString: capitalizeFirstLetter(
        newProofbundlePendingReview
          ? i18n.t('alex:workerSkill.waitingForValidation')
          : i18n.t('alex:workerSkill.validUntilWithDate', {
              duration,
              date: moment(workerSkillExpiryDate).format('L'),
            }),
      ),
      skill,
    };
  } else {
    return {
      ...workerSkill,
      expiryDate: workerSkillExpiryDate.toISOString(),
      daysDifference: days,
      stateString: capitalizeFirstLetter(
        newProofbundlePendingReview
          ? i18n.t('alex:workerSkill.waitingForValidation')
          : i18n.t('alex:workerSkill.expiresInWithDate', {
              duration,
              date: moment(workerSkillExpiryDate).format('L'),
            }),
      ),
      skill,
    };
  }
}

type WorkerSkillValidityCompare = Pick<
  API.WorkerSkillWithComplementaryDetails,
  | 'validity'
  | 'expiryDate'
  | 'daysDifference'
  | 'stateString'
  | 'activeProofBundle'
  | 'toReviewProofBundle'
>;
/**
 * Sort WorkerSkill Validity first against their criticity, then against their expiry date
 * order
 * 1. Skill waiting for validation
 * 2. Skill Missing
 * 3. Expired
 * 4. Expires soon
 * 5. Rejected
 * 6. Valid
 * @param a : WorkerSkillValidity
 * @param b : WorkerSkillValidity to compare to the first one
 */
export function compareWorkerSkillValidity(
  a: WorkerSkillValidityCompare,
  b: WorkerSkillValidityCompare,
): number {
  
  if (a.toReviewProofBundle && b.toReviewProofBundle) {
    return 0;
  } else if (a.toReviewProofBundle) {
    return 1;
  } else if (b.toReviewProofBundle) {
    return -1;
  }

  
  if (a.validity === Validity.KO_MISSING && b.validity === Validity.KO_MISSING) {
    return 0;
  } else if (a.validity === Validity.KO_MISSING && b.validity !== Validity.KO_MISSING) {
    return 1;
  } else if (a.validity !== Validity.KO_MISSING && b.validity === Validity.KO_MISSING) {
    return -1;
  }

  
  if (a.validity === Validity.KO_EXPIRED && b.validity === Validity.KO_EXPIRED) {
    return 0;
  } else if (a.validity === Validity.KO_EXPIRED && b.validity !== Validity.KO_EXPIRED) {
    return 1;
  } else if (a.validity !== Validity.KO_EXPIRED && b.validity === Validity.KO_EXPIRED) {
    return -1;
  }

  
  if (a.validity === Validity.OK_EXPIRE_SOON && b.validity === Validity.OK_EXPIRE_SOON) {
    return 0;
  } else if (a.validity === Validity.OK_EXPIRE_SOON && b.validity !== Validity.OK_EXPIRE_SOON) {
    return 1;
  } else if (a.validity !== Validity.OK_EXPIRE_SOON && b.validity === Validity.OK_EXPIRE_SOON) {
    return -1;
  }

  
  if (
    a.validity === API.Validity.KO_REJECTED &&
    b.validity === API.Validity.KO_REJECTED &&
    b.activeProofBundle?.review.date &&
    a.activeProofBundle?.review.date
  ) {
    return b.activeProofBundle.review.date.localeCompare(a.activeProofBundle.review.date);
  } else if (a.validity === API.Validity.KO_REJECTED && b.validity !== API.Validity.KO_REJECTED) {
    return 1;
  } else if (a.validity !== API.Validity.KO_REJECTED && b.validity === API.Validity.KO_REJECTED) {
    return -1;
  }

  
  if (a.validity === Validity.OK) {
    if (!a.expiryDate && !b.expiryDate) return 0;
    if (!a.expiryDate) return -1;
    if (!b.expiryDate) return 1;
  }

  
  if (a.daysDifference != null && b.daysDifference != null)
    return b.daysDifference - a.daysDifference;

  
  return b.stateString.localeCompare(a.stateString);
}

/**
 * @param rows
 * @param sortDirection
 * @returns a sorted copy of the array (like _orderBy)
 */
export function orderByWorkerSkillValidity<T extends WorkerSkillValidityCompare>(
  rows: T[],
  sortDirection: API.SortDirection,
): T[] {
  
  return rows.slice().sort((a, b) => {
    return sortDirection === API.SortDirection.asc
      ? -compareWorkerSkillValidity(a, b)
      : compareWorkerSkillValidity(a, b);
  });
}

/**
 * Warning this result cannot be cached as it depends of the moment of the call.
 * Get the status of WorkerSkills for a given list of Workers and Workstations at the present time (now).
 * If no list is provided, it will be computed for all the company Workers.
 * @param workerIds optional
 * @param workstationIds optional
 */
export async function getWorkstationsWorkersSkillsStatus(
  workerIds?: string[],
  workstationIds?: string[],
): Promise<API.Result<SkillsToRenewOrAcquire>> {
  const t0 = Date.now();

  if (!workerIds) {
    const workers = await API.getWorkers();
    if (API.isFailure(workers)) return workers;

    workerIds = _.map(workers.result, worker => worker.id);
  }
  if (!workstationIds) {
    const workstations = API.getWorkstations();
    workstationIds = workstations.map(workstation => workstation.id);
  }

  const skillsToRenew = new Set<string>();
  const skillsToAcquire = new Set<string>();
  workstationIds.forEach(workstationId => {
    workerIds.forEach(workerId => {
      const workerWorkstation = API.getWorkerWorkstations(workstationId, workerId);
      if (!workerWorkstation) return;

      if (
        workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRED ||
        workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON ||
        
        API.isWorkerInTrainingOnWorkstation(workerWorkstation as API.WorkerWorkstation)
      ) {
        _.forEach(
          [
            ...(workerWorkstation.validExpireSoonSkills ?? []),
            ...(workerWorkstation.invalidExpiredSkills ?? []),
          ],
          validExpireSoonSkill => {
            const workerIdSkillId = API.getWorkerIdSkillId(validExpireSoonSkill.workerSkillId);
            if (API.isFailure(workerIdSkillId)) return workerIdSkillId;

            const { workerId, skillId } = workerIdSkillId;
            skillsToRenew.add(workerId + API.SeparatorIds + skillId);
          },
        );

        workerWorkstation.invalidNoRefreshSkills?.forEach(invalidNoRefreshSkill => {
          const workerIdSkillId = API.getWorkerIdSkillId(invalidNoRefreshSkill.workerSkillId);
          if (API.isFailure(workerIdSkillId)) return workerIdSkillId;

          const { workerId, skillId } = workerIdSkillId;
          skillsToAcquire.add(workerId + API.SeparatorIds + skillId);
        });

        workerWorkstation.invalidMissingSkills?.forEach(invalidMissingSkill => {
          skillsToAcquire.add(workerId + API.SeparatorIds + invalidMissingSkill.skillId);
        });
      }
    });
  });

  Perf.captureString('getWorkstationsWorkersSkillsStatus', undefined, Date.now() - t0);

  return {
    skillsToRenew: Array.from(skillsToRenew),
    skillsToAcquire: Array.from(skillsToAcquire),
  };
}

export async function getSkillTags(): Promise<API.Result<API.SkillTag[]>> {
  const factories = await API.listFactoriesWithDataType(API.DataType.SKILLTAG);
  if (API.isFailure(factories)) return factories;

  return _.map(factories.result, factory => {
    return { ...factory.skillTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
  });
}

export async function getSkillTag(skillTagId: string): Promise<API.Result<API.SkillTag>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.SKILLTAG, skillTagId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.skillTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

/**
 * Get the skill, paths of workstations requiring the skill,
 * and trainings giving the skill for a skillId
 * @param skillId
 * @param workerId - optional: include only workstations where worker is operational
 */
export async function getSkillInfo(
  skillId: string,
  workerId: string,
  includeTrainingsInfo?: boolean,
): Promise<API.Result<{ skill: API.Skill; workstations: string; trainings: string }>> {
  let trainingsNames: string = '';
  let workstationPaths: string = '';

  const _skill = await API.getSkill(skillId);
  if (API.isFailure(_skill)) return _skill;

  const workstationsRequiringTheSkill = await API.getSkillWorkstations(skillId, workerId);
  if (API.isFailure(workstationsRequiringTheSkill)) return workstationsRequiringTheSkill;

  await Aigle.map(workstationsRequiringTheSkill, async _workstation => {
    const _workstationPath = await API.getOrgUnitPathNames(_workstation.id);
    if (API.isFailure(_workstationPath)) return _workstationPath;

    workstationPaths +=
      '[' + [..._workstationPath, _workstation.name].join(getExcelDelimiter()) + ']' + ';';
  });

  if (includeTrainingsInfo) {
    const _skillTrainingVersions = await API.getTrainingVersionsForSkill(skillId, true);
    if (API.isFailure(_skillTrainingVersions)) return _skillTrainingVersions;

    await Aigle.map(_skillTrainingVersions, async _skillTrainingVersion => {
      const _training = await API.getTraining(_skillTrainingVersion.trainingId);
      if (API.isFailure(_training)) return _training;

      trainingsNames += _training.name + '; ';
    });
  }

  return {
    skill: _skill,
    workstations: workstationPaths,
    trainings: trainingsNames,
  };
}

export async function getSkillsFromTraining(trainingId: string): Promise<API.Result<API.Skill[]>> {
  const skills: API.Skill[] = [];
  const trainingVersions = await API.getTrainingVersionsForTraining(trainingId);
  if (API.isFailure(trainingVersions)) {
    logger.warn(trainingVersions);
    return trainingVersions;
  }

  for (const trainingVersion of trainingVersions) {
    for (const skillId of trainingVersion.skillIds) {
      const skill = await API.getSkill(skillId);
      if (API.isFailure(skill)) {
        logger.warn(skill);
        return skill;
      }

      skills.push(skill);
    }
  }

  return skills;
}

/**
 *
 * @param workerSkill
 * @param date (optional, default = current date) compute the skill validity at the given date
 * @returns
 */
export async function computeWorkerSkillValidity(
  workerSkill: API.NoMetadata<API.WorkerSkill>,
  date?: Date,
): Promise<API.Result<Validity>> {
  const skill = await getSkill(workerSkill.skillId);
  if (API.isFailure(skill)) {
    logger.warn('Failed to fetch skill');
    return skill;
  }

  const { validityDuration, expiryNoticeDuration } =
    await getSkillValidityDurationAndExpiryNoticeDuration(skill);

  const activeProofBundle = workerSkill.activeProofBundle;
  const toReviewProofBundle = workerSkill.toReviewProofBundle;

  if (!activeProofBundle) {
    if (!toReviewProofBundle) {
      return Validity.KO_MISSING;
    }
    return Validity.KO_NEW;
  }

  if (
    activeProofBundle.review.state === API.ReviewState.REJECTED ||
    activeProofBundle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
  ) {
    return Validity.KO_REJECTED;
  }

  if (validityDuration == null || validityDuration === 0) {
    return Validity.OK;
  }

  const workerSkillExpiryDate = moment(activeProofBundle.startingDate).add(
    validityDuration,
    'months',
  );

  const days = workerSkillExpiryDate.diff(moment(date).utc(), 'days');
  if (days <= 0) {
    return Validity.KO_EXPIRED;
  }

  if (!expiryNoticeDuration || days > expiryNoticeDuration) {
    return Validity.OK;
  }

  return Validity.OK_EXPIRE_SOON;
}

/**
 * Check if there is at least 1 practical skill in the given list and returns the name of it
 * @param skillIds
 * @returns name of the first practical skill found, undefined otherwise
 */
export async function findPracticalSkill(
  skillIds: readonly string[],
): Promise<API.Result<string | undefined>> {
  let isPracticalSkill: string | undefined = undefined;
  for (const skillId of skillIds) {
    const skill = await API.getSkill(skillId);
    if (API.isFailure(skill)) return skill;

    if (skill.isPractical) {
      isPracticalSkill = skill.name;
      break;
    }
  }
  return isPracticalSkill;
}

/**
 * This function will return the required skills for a Worker on a Workstation to reach each levels
 * @param workstationId
 * @param workerId
 */
export async function getSkillsAndWorkerSkillsRequiredForWorkstationLevels(
  workstationId: string,
  workerId: string,
  includeInherited: boolean,
  excludeNonInherited: boolean,
): Promise<API.Result<WorkstationSkillsRequiredInLevels>> {
  const skillIds: string[] = [];
  const skillsRequiredInLevels: Map<API.WorkstationWorkerLevels, RequiredSkillAndWorkerSkill[]> =
    new Map();

  const levelRequirements = await API.getLevelsRequirementsWithInheritedAndOrDescendent(
    workstationId,
    includeInherited,
    false,
    excludeNonInherited,
  );
  if (API.isFailure(levelRequirements)) return levelRequirements;

  const workerWorkerSkills = await API.getWorkerSkills(workerId);
  if (API.isFailure(workerWorkerSkills)) return workerWorkerSkills;

  const workerWorkerSkillsMap = new Map<string, API.WorkerSkill>();
  workerWorkerSkills.result.forEach(workerSkill =>
    workerWorkerSkillsMap.set(workerSkill.skillId, workerSkill),
  );

  for (let i = API.WorkstationWorkerLevels.LEVEL1; i <= API.WorkstationWorkerLevels.LEVEL4; i++) {
    const data: RequiredSkillAndWorkerSkill[] = [];
    await Aigle.map(levelRequirements.get(i) ?? [], async requirement => {
      await Aigle.map(requirement.skillTrainingVersions, async skillTrainingVersion => {
        skillIds.push(skillTrainingVersion.skillId);

        const skill = await API.getSkill(skillTrainingVersion.skillId);
        if (API.isFailure(skill)) return skill;

        const workerSkill = workerWorkerSkillsMap.get(skill.id);
        if (!workerSkill) {
          data.push({
            requiredSkill: skill,
            requiredTrainingVersionId: skillTrainingVersion.trainingVersionId,
            workerSkill: undefined,
            requirementId: requirement.id,
            workstationId,
          });
        } else {
          data.push({
            requiredSkill: skill,
            requiredTrainingVersionId: skillTrainingVersion.trainingVersionId,
            workerSkill: workerSkill,
            requirementId: requirement.id,
            workstationId,
          });
        }
      });
    });

    skillsRequiredInLevels.set(i, data);
  }

  return {
    totalSkills: skillIds,
    skillsRequiredInLevels: skillsRequiredInLevels,
  };
}

export function isWorkerSkillValid(validity: API.Validity | undefined | null) {
  return !!validity && (validity === Validity.OK || validity === Validity.OK_EXPIRE_SOON);
}

/**
 * checks if we can unlink a skill from training, for practical skill to unlink it from training we have to remove it from requirment
 * @param isSkillPractical
 * @returns
 */
export function isSkillUnlinkable(isSkillPractical: boolean | undefined): boolean {
  return !isSkillPractical;
}

/**
 * Pass the skillId and get the Skill Groups related to this skillId
 * @param skillId
 * @returns API.Skill[]
 */
export async function getSkillGroupsForASkill(skillId: string): Promise<API.Result<API.Skill[]>> {
  const skillGroups = await getSkillGroups();
  if (API.isFailure(skillGroups)) {
    return skillGroups;
  }

  return skillGroups.filter(_skillGroup => _skillGroup.skillIds?.includes(skillId));
}

async function getSkillGroups(): Promise<API.Result<API.Skill[]>> {
  const dataString = API.getSkillGroupDataString();
  const factories = await API.listFactoriesWithDataTypeUsingDataFilter(
    API.DataType.SKILL,
    dataString,
    API.FilterComparator.eq,
  );
  if (API.isFailure(factories)) return factories;

  return factories.result.map(factory => {
    return {
      ...factory.skill,
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    };
  });
}
