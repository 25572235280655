import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import { WorkerAndWorkstationVersatilityRow } from '../../WorkersLibrary';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import * as _ from 'lodash-es';
import { WorkerState } from 'shared/backend-data';
import styles from '../Styles';
import * as API from 'shared/backend-data';
import { IconSVG } from 'shared/ui-component/Icon';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { Colors, Spacings } from 'shared/styles';
import { t } from 'shared/localisation/i18n';

interface TrainingColumnInterface {
  row: WorkerAndWorkstationVersatilityRow;
}

export const TrainingColumn: React.FC<TrainingColumnInterface> = props => {
  const { row } = props;

  const { isValidPermission } = useContext(PermissionManagementContext);

  return (
    <View>
      {row.activeTrainingSessions &&
      !row.activeTrainingSessions.length &&
      !isValidPermission(API.Permission.workersDetail_view) ? (
        <ToolTipWeb
          title={t('alex:worker.addEditWorker.warnings.noViewInfoPermission')}
          component={
            <IconSVG
              svgComponent={require('shared/assets/svg/icon.notAssign.svg').default}
              color={Colors.GreyLight}
              size={{ width: Spacings.MobileIconSize, height: Spacings.MobileIconSize }}
              containerStyle={{
                alignItems: 'flex-start',
              }}
            />
          }
        />
      ) : (
        <Text style={[styles.tableNumber, row.state === WorkerState.ARCHIVED && styles.disabled]}>
          {!row.activeTrainingSessions ? '' : row.activeTrainingSessions.length}
        </Text>
      )}
    </View>
  );
};
