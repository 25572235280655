import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Card } from 'shared/ui-component/Card';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import styles from './styles';
import * as API from 'shared/backend-data';
import { ToggleSwitch } from 'shared/ui-component/Button/ToggleButton';
import { t } from 'shared/localisation/i18n';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Spacings } from 'shared/styles';

const modalOffset = 125;

interface Props {
  cordinates?: { x: number; y: number };
  workerWorkstation: API.WorkerWorkstation;
  workerSkillsAndRequiredSkillsByLevel?: Map<
    number,
    {
      numberOfRequiredSkills: number;
      numberOfValidSkills: number;
      numberOfRequiredSkillsOnSingleLevel: number;
    }
  >;

  closeMenu: () => void;
  handleMaintainSelect: (maintainLevel: API.WorkstationWorkerLevels) => void;
  setTrainingLevel: (trainLevel: API.WorkstationWorkerLevels | undefined) => void;
  setShowTrainingModal: (bool: boolean) => void;
  handleUnassignWorkerAndStopTrainings: () => Promise<void>;
}

interface StartTrainigOptionProps {
  trainingLevel: API.WorkstationWorkerLevels;
  numberOfRequiredSkills: number;
  numberOfValidSkills: number;
}

interface UnassignAndStopTrainingsOptionProps {
  isWorkerInTrainingOnWorkstation: boolean;
}

interface MaintainOptionProps {
  isMaintain: boolean;
  maintainLevel: API.WorkstationWorkerLevels;
}

export const LevelTargetAndMaintainInfoModal: React.FC<Props> = props => {
  const {
    cordinates,
    workerWorkstation,
    workerSkillsAndRequiredSkillsByLevel,

    handleUnassignWorkerAndStopTrainings,
    setTrainingLevel,
    setShowTrainingModal,
    handleMaintainSelect,
    closeMenu,
  } = props;

  const myRef = useDetectOutsideClick<View>(closeMenu);

  const StartTrainingOptions: React.FC<StartTrainigOptionProps> = props => {
    const { trainingLevel, numberOfRequiredSkills, numberOfValidSkills } = props;

    let outerMenuLabel: string = '';

    const menuItemLabel = t('alex:workstationWorkerLevelMenu.levelTrainOption', {
      level: API.getWorkstationWorkerLevelLabel(trainingLevel, undefined, false),
    });

    outerMenuLabel = menuItemLabel;

    return (
      <TouchableOpacity
        style={[styles.optionContainer, { zIndex: 1 }, { justifyContent: 'space-between' }]}
        ref={useCallOnHover<TouchableOpacity>()}
        onPress={() => {
          setTrainingLevel(trainingLevel);
          setShowTrainingModal(true);
        }}
      >
        <Text>{outerMenuLabel}</Text>
        <Text
          style={[styles.OptionTextBold, { marginRight: Spacings.Small }]}
        >{`${numberOfValidSkills}/${numberOfRequiredSkills}`}</Text>
      </TouchableOpacity>
    );
  };

  const UnassignAndStopTrainingsOption: React.FC<UnassignAndStopTrainingsOptionProps> = props => {
    const { isWorkerInTrainingOnWorkstation } = props;

    const label = isWorkerInTrainingOnWorkstation
      ? t('alex:workstationWorkerLevelMenu.unassignAndStopTrainings')
      : t('alex:workstationWorkerLevelMenu.unassign');

    return (
      <TouchableOpacity
        style={[styles.optionContainer, { zIndex: 1 }]}
        ref={useCallOnHover<TouchableOpacity>()}
        onPress={() => {
          handleUnassignWorkerAndStopTrainings();
        }}
      >
        <Text>{label}</Text>
      </TouchableOpacity>
    );
  };

  const MaintainOption: React.FC<MaintainOptionProps> = props => {
    const { isMaintain, maintainLevel } = props;

    return (
      <TouchableOpacity
        ref={useCallOnHover<TouchableOpacity>()}
        style={[
          styles.optionContainer,
          { justifyContent: 'space-between', paddingRight: Spacings.Unit * 3 },
        ]}
        onPress={() => handleMaintainSelect(maintainLevel)}
      >
        <Text style={styles.OptionText}>
          {t('alex:workstationWorkerLevelMenu.maintainOption', {
            level: API.getWorkstationWorkerLevelLabel(maintainLevel, undefined, false),
          })}
        </Text>
        <ToggleSwitch
          value={isMaintain}
          onValueChange={() => {
            handleMaintainSelect(maintainLevel);
          }}
        />
      </TouchableOpacity>
    );
  };

  function displayOptions() {
    if (!workerWorkstation.level) return;

    const maxLevel = API.WorkstationWorkerLevels.LEVEL4;
    const minLevel = API.WorkstationWorkerLevels.LEVEL1;

    const jsxArray: JSX.Element[] = [];
    for (let level = minLevel; level <= maxLevel; level++) {
      if (level <= API.api2workstationWorkerLevels(workerWorkstation.level)) {
        const isMaintain =
          API.isWorkerInTrainAutoOnWorkstation(workerWorkstation) &&
          API.isWorkerTargetingALevelOnWorkstation(workerWorkstation) &&
          level === API.api2workstationWorkerLevels(workerWorkstation.targetLevel!);

        jsxArray.push(<MaintainOption key={level} isMaintain={isMaintain} maintainLevel={level} />);
      } else {
        const numberOfRequiredSkillsOnSingleLevel =
          workerSkillsAndRequiredSkillsByLevel?.get(level)?.numberOfRequiredSkillsOnSingleLevel ??
          0;
        if (!numberOfRequiredSkillsOnSingleLevel) continue;

        if (
          !API.isWorkerInTrainingOnWorkstation(workerWorkstation) ||
          (API.isWorkerInTrainingOnWorkstation(workerWorkstation) &&
            API.api2workstationWorkerLevels(workerWorkstation.targetLevel!) !== level)
        )
          jsxArray.push(
            <StartTrainingOptions
              key={level}
              trainingLevel={level}
              numberOfRequiredSkills={
                workerSkillsAndRequiredSkillsByLevel?.get(level)?.numberOfRequiredSkills ?? 0
              }
              numberOfValidSkills={
                workerSkillsAndRequiredSkillsByLevel?.get(level)?.numberOfValidSkills ?? 0
              }
            />,
          );
      }
    }
    if (API.isWorkerTargetingALevelOnWorkstation(workerWorkstation)) {
      jsxArray.push(
        <UnassignAndStopTrainingsOption
          key={'notTarget'}
          isWorkerInTrainingOnWorkstation={API.isWorkerInTrainingOnWorkstation(workerWorkstation)}
        />,
      );
    }

    return jsxArray;
  }

  return (
    <Card
      ref={myRef}
      style={[
        styles.CardContainer,
        cordinates ? { left: cordinates.x + modalOffset, bottom: cordinates.y } : { opacity: 0 },
        { width: 296, maxHeight: 223 },
      ]}
    >
      {displayOptions()}
    </Card>
  );
};
