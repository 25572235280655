import React, { useContext } from 'react';
import { UserInfoComponent, WorkerOrgUnitRolesInfo } from '../component';
import * as API from 'shared/backend-data';
import { ModalUtils } from 'shared/ui-component/Modal';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { inviteWorkerModalFilterContext } from 'shared/context/inviteWorkerModalContext';
import { StyleProp, ViewStyle } from 'react-native';
import { inviteWorker } from 'shared/util/WorkerUi';

interface Props {
  index: number;
  item: WorkerOrgUnitRolesInfo;
  handleModalClose: () => void;
  searchedInput?: string | undefined;
  containerStyle?: StyleProp<ViewStyle>;
}

export const UserInfo: React.FC<Props> = props => {
  const {
    item: workerOrgUnitRoles,
    searchedInput,
    containerStyle,
    index,

    handleModalClose,
  } = props;

  const {
    refreshData,
    loading: [, setLoading],
  } = useContext(inviteWorkerModalFilterContext);

  const isMounted = useIsMounted();
  const modal = ModalUtils.useModal();

  async function removeUserAccess(worker: API.Worker) {
    setLoading(true);
    await inviteWorker(worker, modal, API.UserInvitationOperations.REMOVE_ACCESS, () => {
      if (!isMounted.current) return;
      refreshData();
    });
    if (!isMounted.current) return;
    setLoading(false);
  }

  async function giveUserAccess(worker: API.Worker) {
    setLoading(true);
    await inviteWorker(worker, modal, API.UserInvitationOperations.GIVE_ACCESS, () => {
      if (!isMounted.current) return;
      refreshData();
    });
    if (!isMounted.current) return;
    setLoading(false);
  }

  return (
    <UserInfoComponent
      index={index}
      item={workerOrgUnitRoles}
      searchedInput={searchedInput}
      containerStyle={containerStyle}
      removeUserAccess={removeUserAccess}
      giveUserAccess={giveUserAccess}
      handleModalClose={handleModalClose}
    />
  );
};
