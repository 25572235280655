import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles } from 'shared/styles';
import { Platform } from 'react-native';
const isPlatformWeb = Platform.OS === 'web';

export const Style = StyleSheet.create({
  rootContainer: {
    justifyContent: 'flex-end',
    height: 64,
  },
  labelContainer: {
    ...TextFontStyles.LatoRegular,
    color: Colors.DimGray,
    fontSize: isPlatformWeb ? 12 : 14,
  },
  inputContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  inputText: {
    ...TextFontStyles.LatoRegular,
    flex: 1,
    paddingBottom: Spacings.Small,
    paddingTop: Spacings.Unit,
    paddingLeft: 0,
    backgroundColor: Colors.White,
    fontSize: isPlatformWeb ? 14 : 16,
  },
  submissionIconContainer: {
    justifyContent: 'center',
    borderWidth: 1,
    width: Spacings.Standard,
    height: Spacings.Standard,
    borderColor: Colors.Grey,
    borderRadius: Spacings.Standard,
  },
  submissionIconOnHoverContainer: {
    justifyContent: 'center',
    borderWidth: 0,
    width: Spacings.Standard,
    height: Spacings.Standard,
    backgroundColor: Colors.Yellow,
    borderColor: Colors.Grey,
    borderRadius: Spacings.Standard,
  },
  closeIcon: {
    justifyContent: 'center',
    height: Spacings.Standard,
    width: Spacings.Standard,
  },
  invalidInput: {
    color: Colors.Red,
    fontSize: Spacings.xMedium,
    marginTop: Spacings.Unit - 1,
    alignSelf: 'center',
  },
  errorMessageContainer: {
    position: 'absolute',
    left: 0,
    bottom: -19,
    height: 19,
  },
});
