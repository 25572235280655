import React, { useRef, useState, useCallback } from 'react';
import { View, DimensionValue } from 'react-native';
import * as _ from 'lodash-es';
import { TableColumn, TableRow } from 'shared/ui-component/Table';
import { TouchableSortComponent } from './TouchableSortComponent';
import ReactDOM from 'react-dom';
import { TouchableOpacity } from 'react-native-web';
import style from './styles';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors } from 'shared/styles/Colors';

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export interface FilterMenuOptions {
  label: string;
  key: string;
  onClick: (value: boolean) => void;
  width: number;
}

const sortings: SortDirection[] = [SortDirection.asc, SortDirection.desc];

interface IProps<R extends TableRow> {
  index: number;
  onSortClick: (direction: SortDirection) => void;
  data: TableColumn<R>;
  activeSort: boolean;
  sortDirection: SortDirection;
  columnWidthChanged: (index: number, width: string) => void;
  columnWidthArray: string[];
  tableHeight: number;
  tableWidth: number;
}

export const SortComponent = <R extends TableRow>(props: React.PropsWithChildren<IProps<R>>) => {
  const {
    data,
    activeSort,
    onSortClick,
    sortDirection,
    index,
    columnWidthChanged,
    columnWidthArray,
    tableHeight,
    tableWidth,
  } = props;
  const widthRef = useRef<number>(0);
  const columnHeader = useRef<View>(null);
  const ghostRef = useRef<View>(null);
  const minColumnWidth = 10;
  const maxColumnWidth = 100;

  const [maxWidth, setMaxWidth] = useState<number>(maxColumnWidth);
  const [isMousePressed, setIsMousePressed] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isHover, setIsHover] = useState<boolean>(false);

  const ref = useCallOnHover<View>(
    Colors.BlueRollover,
    e => setIsHover(true),
    e => setIsHover(false),
  );

  function calculateColumnWidth(width: number): number {
    let _width = width === 0 ? minColumnWidth : width;

    const sumOfColumnWidth = columnWidthArray
      .map((eachColumnWidth, columnIndex) => {
        if (index === columnIndex) {
          return 0;
        }
        return parseInt(eachColumnWidth.split('%')[0], 10);
      })
      .reduce((first, second) => first + second);

    const remainingWidth = maxColumnWidth - sumOfColumnWidth;

    setMaxWidth(remainingWidth);

    if (width >= remainingWidth) {
      _width = remainingWidth;
    } else if (width <= minColumnWidth) {
      _width = minColumnWidth;
    }

    return isNaN(_width) ? minColumnWidth : _width;
  }

  function _handleResize(movedPx: number) {
    const percentage = (movedPx / tableWidth) * 100;
    const panel = ReactDOM.findDOMNode(columnHeader.current) as HTMLElement;
    const width = calculateColumnWidth(percentage);

    panel.style.width = `${width}%`;
    panel.style.maxWidth = `${maxWidth}%`;
    panel.style.minWidth = `${minColumnWidth}%`;
    widthRef.current = width;
  }

  const handleMouseDown = useCallback(event => {
    if (columnHeader.current && ghostRef.current) {
      const ghostPanel = ReactDOM.findDOMNode(ghostRef.current) as HTMLElement;
      const headerPanel = ReactDOM.findDOMNode(columnHeader.current) as HTMLElement;

      ghostPanel.style.top = `${headerPanel.offsetTop}px`;
      ghostPanel.style.left = `${headerPanel.getBoundingClientRect().width - 4}px`;
    }
  }, []);

  function convertPxToInt(value: string): number {
    return parseInt(value.split('px')[0], 10);
  }

  const handleGhostMovement = useCallback(
    event => {
      if (ghostRef.current && event.buttons) {
        const ghostPanel = ReactDOM.findDOMNode(ghostRef.current) as HTMLElement;

        ghostPanel.style.left = `${convertPxToInt(ghostPanel.style.left) + event.movementX}px`;

        _handleResize(convertPxToInt(ghostPanel.style.left));
      }
    },
    [columnWidthArray, tableWidth],
  );

  function onPressIn() {
    setSelectedIndex(index);
    window.addEventListener('mousedown', handleMouseDown, true);
    window.addEventListener('mousemove', handleGhostMovement, true);
    setIsMousePressed(true);
  }

  function onPressOut() {
    setSelectedIndex(-1);
    window.removeEventListener('mousedown', handleMouseDown, true);
    window.removeEventListener('mousemove', handleGhostMovement, true);

    if (widthRef.current) columnWidthChanged(index, `${widthRef.current}%`);
    setIsMousePressed(false);
  }

  function calculateLeft(): number {
    let left = 0;
    if (columnHeader.current && ghostRef.current) {
      const ghostPanel = ReactDOM.findDOMNode(ghostRef.current) as HTMLElement;
      const headerPanel = ReactDOM.findDOMNode(columnHeader.current) as HTMLElement;

      ghostPanel.style.top = `${headerPanel.offsetTop}px`;
      ghostPanel.style.left = `${headerPanel.getBoundingClientRect().width - 4}px`;
      left = convertPxToInt(ghostPanel.style.left);
    }

    return left;
  }

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: columnWidthArray[index] as DimensionValue,
          zIndex: selectedIndex === index ? 1 : 0,
        },
      ]}
      ref={columnHeader}
    >
      <TouchableSortComponent
        sortDirection={sortDirection}
        label={data.label}
        needSort={!!data.sort}
        activeSort={activeSort}
        handleOnPress={onSortClick}
        sortings={sortings}
      />

      <View ref={ghostRef} style={[style.dragableAreaGhost, { left: calculateLeft() }]} />
      {index !== columnWidthArray.length - 1 && (
        <TouchableOpacity
          ref={ref}
          style={[
            style.dragableArea,
            isMousePressed && style.dragableBorder,
            { height: tableHeight },
            isHover && style.dragableBorderOnHover,
          ]}
          onPressIn={onPressIn}
          onPressOut={onPressOut}
          activeOpacity={1}
        />
      )}
    </View>
  );
};
