import { StyleSheet, TextStyle } from 'react-native';
import { Spacings, TextFontStyles, Colors, fontSize } from 'shared/styles';
export const minModalHeight = 228;
export const maxModalHeight = 344;

const styles = StyleSheet.create({
  menuCardContainer: {
    position: 'absolute',
    marginLeft: '96.5%',
    width: Spacings.xMedium + 8 * Spacings.Big,
    paddingHorizontal: 0,
    justifyContent: 'space-between',
    paddingTop: Spacings.Small,
    paddingBottom: Spacings.Medium,
    zIndex: 2,
  },
  boldTextOption: {
    ...TextFontStyles.LatoBlack,
    fontSize: 14,
    maxWidth: '100%',
  },
  textOption: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    maxWidth: '100%',
  },
  addNodeMenuHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  headerMenuText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: Colors.Grey,
    marginRight: Spacings.Unit,
  },
  addMenuButton: {
    marginBottom: Spacings.Large - Spacings.Unit,
  },
  headerMenuBodyText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: Colors.Grey,
  },
  headerMenuButtonText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Small,
  },
  nodeWorkerContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingRight: Spacings.Unit,
    alignItems: 'center',
    marginTop: Spacings.Medium,
  },
  badgeStyle: {
    alignSelf: 'flex-start',
    marginRight: Spacings.Small,
  },
  workerNameText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    alignSelf: 'center',
    marginBottom: -3,
  },
  inviteWorkerText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
  adPhoneText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: Colors.Black,
    marginTop: Spacings.Medium - Spacings.Unit,
  },
  outlineButton: {
    width: 152,
    height: Spacings.Standard,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: Spacings.Large - Spacings.Unit,
  },
  menuHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
  },
  flexView: {
    flex: 1,
  },
  rowView: {
    flexDirection: 'row',
  },
  divider: {
    width: Spacings.xMedium,
  },
  menuOptionContainer: {
    width: '100%',
    paddingRight: Spacings.Unit * 5,
    paddingLeft: Spacings.Medium,
    paddingTop: Spacings.Medium,
    paddingBottom: Spacings.Medium,
    borderRadius: Spacings.Small,
  },
  menuItemsCardContainer: {
    position: 'absolute',
    marginLeft: '96.5%',
    minHeight: minModalHeight,
    maxHeight: maxModalHeight,
    paddingHorizontal: 0,
    justifyContent: 'space-between',
    paddingTop: Spacings.Small + Spacings.Unit,
    zIndex: 2,
    right: -150,
  },
});

export const getCardContainerStyle = (Ycordinates: number): TextStyle => {
  return {
    position: 'absolute',
    marginLeft: '96.5%',
    width: 324,
    minHeight: minModalHeight,
    maxHeight: maxModalHeight,
    paddingHorizontal: 0,
    justifyContent: 'space-between',
    paddingTop: Spacings.Small + Spacings.Unit,
    paddingLeft: Spacings.Standard - Spacings.Unit,
    paddingRight: Spacings.Medium,
    top: Ycordinates,
    zIndex: 2,
  };
};

export default styles;
