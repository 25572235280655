import React, { useState, useEffect, useContext } from 'react';
import { View, InteractionManager, TouchableOpacity } from 'react-native';
import * as API from 'shared/backend-data';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import * as _ from 'lodash-es';
import { t } from 'shared/localisation/i18n';
import Styles from './Styles';
import * as SharedStyles from 'shared/styles';
import { useHistory } from 'react-router-dom';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import {
  Table,
  TableColumn,
  TableRow,
  TableWidth,
  TableMediumColumnWidth,
  TableLargeColumnWidth,
} from 'shared/ui-component/Table';
import { TreeTableNode } from 'shared/backend-data/factoryCache/Tree';
import { TableNumberWithMenu } from 'sharedweb/src/NumberWithMenu';
import { IconSVG } from 'shared/ui-component/Icon';
import { ActivityIndicator } from 'shared/ui-component/Loader/ActivityIndicator';
import { MenuItem } from 'shared/ui-component/Menu';
import { Card } from 'shared/ui-component/Card';
import { RadioButton } from 'shared/ui-component/Button';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';

import {
  TreeRowVersatility,
  TreeRowMagicSquare,
  KPI_Tabs,
  MagicSquareAlertLevel,
} from '../container';
import { RenderShiftsForTable } from './RenderShifts';
import { getDropDownOptions } from '../../../../my-factory/workstations/component/workstation-modal/component/workstationLevelObjectives/LevelObjectives';
import { DropDownOption, DropDownSingleSelection } from 'shared/ui-component/DropDown/DropDown';
import { TreeTableContext } from 'sharedweb/src/context/TreeTableContext';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';

const moreIcon = require('shared/assets/svg/icon.more.small.svg').default;

const orgUnitIcon = require('shared/assets/svg/icon.workstation.svg').default;
const workstationOkIcon = require('shared/assets/svg/icon.thumbUp.svg').default;
const workstationNotOkIcon = require('shared/assets/svg/icon.thumbDown.svg').default;

const arrowDownIcon = require('shared/assets/svg/icon.arrowDown.mobile.svg').default;
const arrowLeftIcon = require('shared/assets/svg/icon.arrowLeft.mobile-2.svg').default;
const arrowRightIcon = require('shared/assets/svg/icon.arrowRight.mobile.svg').default;

const displayUnitMenuItems: MenuItem[] = [
  {
    label: t('alex:KPI.KPIGraph.unit.decimal'),
    onPress: () => null,
  },
  {
    label: t('alex:KPI.KPIGraph.unit.percentage'),
    onPress: () => null,
  },
];

interface Props {
  dataVersatilityRows?: TreeRowVersatility[];
  selectedTab: KPI_Tabs;
  radioIndex: number;
  dataMagicSquare?: TreeRowMagicSquare[];

  setRadioIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<KPI_Tabs>>;
  handleRowPress(row: TreeRowVersatility | TreeRowMagicSquare): void;
  handleSelectShift(shiftId: string, row: TreeRowVersatility | TreeRowMagicSquare): void;
  fetchVersatilityRowDetails(
    row: TreeRowVersatility,
    isMounted: React.MutableRefObject<boolean>,
  ): Promise<boolean>;
  handleWorkstationUpdate(
    tag: DropDownOption,
    level: API.WorkstationWorkerLevels,
    workstationId: string,
    shiftId?: string,
  ): Promise<void>;
}

export const KPIOrgUnitsComponent: React.FC<Props> = props => {
  const {
    dataVersatilityRows,
    selectedTab,
    radioIndex,
    dataMagicSquare,

    setRadioIndex,
    setSelectedTab,
    handleRowPress,
    handleSelectShift,
    fetchVersatilityRowDetails,
    handleWorkstationUpdate,
  } = props;
  const { levelIconType, workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);
  const { selectedShiftIds } = useContext(TreeTableContext);
  const { isValidPermission } = useContext(PermissionManagementContext);

  const history = useHistory();
  const displayUnitMenuRef = useDetectOutsideClick<View>(() => {
    setShowMenu(false);
  });

  const [targetLevelClicked, setTargetLevelClicked] = useState<API.WorkstationWorkerLevels>();

  const [columnsVersatility, setColumnsVersatility] = useState<TableColumn<TreeRowVersatility>[]>();
  const [columnsMagicSquare, setColumnsMagicSquare] = useState<TableColumn<TreeRowMagicSquare>[]>();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [tabs, setTabs] = useState<Tab[]>([]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {});
    getTabs();
  }, []);

  useEffect(() => {
    getTableColumns(selectedTab);
  }, [selectedTab, targetLevelClicked, selectedShiftIds, radioIndex]);

  function getTabs(): void {
    const _tabs: Tab[] = [
      {
        title: {
          title: t('alex:KPI.orgUnitsKPITab.versatility'),
        },
      },
    ];

    setTabs(
      levelIconType === API.LevelIconType.SQUARE
        ? [..._tabs, { title: { title: t('alex:KPI.orgUnitsKPITab.magicSquares') } }]
        : _tabs,
    );
  }

  function handleRadioChange(text: string, index: number) {
    setRadioIndex(index);
    setShowMenu(false);
  }

  async function _handleDropdownChange(
    level: API.WorkstationWorkerLevels,
    row: TreeRowVersatility,
    tag?: DropDownOption,
    shiftId?: string,
  ) {
    setTargetLevelClicked(undefined);
    if (tag) handleWorkstationUpdate(tag, level, row.key, shiftId);
  }

  function enableDropdown(selectedShiftIds: string[], node: TreeTableNode): boolean {
    if (API.getDataType(node.object.id) === API.DataType.ORGUNIT) {
      return false;
    } else if (
      !node.object.shiftIds ||
      node.object.shiftIds.length === 1 ||
      !node.object.shiftIds.length ||
      selectedShiftIds.length === 1
    ) {
      return true;
    }

    return false;
  }

  /**
   * Fetch columns for kpi tables
   * @param tab To specify which tab is selected
   */
  function getTableColumns(tab: KPI_Tabs): void {
    switch (tab) {
      case KPI_Tabs.VERSATILITY:
        const _columnsVersatility: TableColumn<TreeRowVersatility>[] = [
          {
            label: t('alex:header.navigation.2'),
            width: TableLargeColumnWidth,
            renderCell: row => {
              return (
                <View
                  style={[
                    Styles.firstColumnCellContainer,
                    {
                      paddingLeft: row.info?.isWorkstation
                        ? (row.node.object.pathIds.length - 3) * SharedStyles.Spacings.Large
                        : (row.node.object.pathIds.length - 2) * SharedStyles.Spacings.Large,
                      marginLeft: row.info?.isWorkstation ? SharedStyles.Spacings.Standard : 0,
                    },
                  ]}
                >
                  {!row.info?.isWorkstation && (
                    <IconSVG
                      svgComponent={row.node.isExpanded ? arrowDownIcon : arrowRightIcon}
                      color={SharedStyles.Colors.Black}
                      containerStyle={Styles.arrowIconContainer}
                      size={{
                        width: SharedStyles.Spacings.Standard,
                        height: SharedStyles.Spacings.Standard,
                      }}
                    />
                  )}
                  <IconSVG
                    svgComponent={
                      row.info?.isWorkstation
                        ? row.info.isTargetOK
                          ? workstationOkIcon
                          : workstationNotOkIcon
                        : orgUnitIcon
                    }
                    containerStyle={[
                      SharedStyles.Styles.tableObjectIconContainer,
                      {
                        backgroundColor: !isDisplayRowInfo(row)
                          ? SharedStyles.Colors.Black
                          : row.info?.isTargetOK
                          ? SharedStyles.Colors.Green
                          : SharedStyles.Colors.Red,
                        marginLeft: row.info?.isWorkstation ? SharedStyles.Spacings.Small : 0,
                      },
                    ]}
                    size={
                      row.info?.isWorkstation
                        ? {
                            width: SharedStyles.Spacings.Medium,
                            height: SharedStyles.Spacings.Medium,
                          }
                        : {
                            width: SharedStyles.Spacings.Standard,
                            height: SharedStyles.Spacings.Standard,
                          }
                    }
                    color={SharedStyles.Colors.White}
                  />
                  <EllipsisWithTooltip
                    text={row.node.object.name}
                    textStyle={SharedStyles.Styles.tableText}
                  />
                  <View>
                    <RenderShiftsForTable row={row} handleSelectShift={handleSelectShift} />
                  </View>
                </View>
              );
            },
          },
          {
            label: t('alex:workerTable.lev2AndAbove', {
              count: API.WorkstationWorkerLevels.LEVEL2,
            }),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  countStyle={Styles.textCountStyle}
                  list={[]}
                  disableHover
                  count={API.formatDecimalPercentageTargetCount(
                    row.info?.level2actual ?? 0,
                    !!radioIndex,
                  )}
                  onMenuItemPress={() => {}}
                  countColor={
                    row.info?.isTargetOK ? SharedStyles.Colors.Black : SharedStyles.Colors.Red
                  }
                />
              ) : (
                <></>
              ),
          },
          {
            label: t('glossary:levelTarget'),
            width: TableWidth,
            renderCell: row => {
              const _selectedShiftIds: string[] = [];

              selectedShiftIds.forEach(shiftId =>
                _selectedShiftIds.push(
                  ...(row.node.object.shiftIds?.filter(_shiftId => _shiftId === shiftId) ?? []),
                ),
              );

              return isDisplayRowInfo(row) ? (
                <ToolTipWeb
                  title={
                    enableDropdown(_selectedShiftIds, row.node)
                      ? ''
                      : t('alex:KPI.orgUnitsKPITab.selectOneShift')
                  }
                  style={Styles.toolTip}
                  component={
                    <DropDownSingleSelection
                      options={getDropDownOptions(!!radioIndex)}
                      value={{
                        key: API.formatDecimalPercentageTargetCount(
                          row.info?.level2target ?? 0,
                          !!radioIndex,
                        ),
                        label: API.formatDecimalPercentageTargetCount(
                          row.info?.level2target ?? 0,
                          !!radioIndex,
                        ),
                      }}
                      placeholder={''}
                      handleChange={(inputId, option) =>
                        _handleDropdownChange(
                          API.WorkstationWorkerLevels.LEVEL2,
                          row,
                          option,
                          _selectedShiftIds[0],
                        )
                      }
                      containerStyle={Styles.minimumValueDropdown}
                      notEditable={
                        !enableDropdown(_selectedShiftIds, row.node) ||
                        !isValidPermission(API.Permission.workstations_edit, row.node)
                      }
                      textStyle={Styles.countStyle}
                    />
                  }
                />
              ) : (
                <></>
              );
            },
          },
          {
            label: t('alex:workerTable.lev2AndAbove', {
              count: API.WorkstationWorkerLevels.LEVEL3,
            }),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  countStyle={Styles.textCountStyle}
                  list={[]}
                  disableHover
                  count={API.formatDecimalPercentageTargetCount(
                    row.info?.level3actual ?? 0,
                    !!radioIndex,
                  )}
                  onMenuItemPress={() => {}}
                  countColor={
                    row.info?.isTargetOK ? SharedStyles.Colors.Black : SharedStyles.Colors.Red
                  }
                />
              ) : (
                <></>
              ),
          },
          {
            label: t('glossary:levelTarget'),
            width: TableWidth,
            renderCell: row => {
              const _selectedShiftIds: string[] = [];

              selectedShiftIds.forEach(shiftId =>
                _selectedShiftIds.push(
                  ...(row.node.object.shiftIds?.filter(_shiftId => _shiftId === shiftId) ?? []),
                ),
              );

              return isDisplayRowInfo(row) ? (
                <ToolTipWeb
                  title={
                    enableDropdown(_selectedShiftIds, row.node)
                      ? ''
                      : t('alex:KPI.orgUnitsKPITab.selectOneShift')
                  }
                  style={Styles.toolTip}
                  component={
                    <DropDownSingleSelection
                      options={getDropDownOptions(!!radioIndex)}
                      value={{
                        key: API.formatDecimalPercentageTargetCount(
                          row.info?.level3target ?? 0,
                          !!radioIndex,
                        ),
                        label: API.formatDecimalPercentageTargetCount(
                          row.info?.level3target ?? 0,
                          !!radioIndex,
                        ),
                      }}
                      placeholder={''}
                      handleChange={(inputId, option) =>
                        _handleDropdownChange(
                          API.WorkstationWorkerLevels.LEVEL3,
                          row,
                          option,
                          _selectedShiftIds[0],
                        )
                      }
                      containerStyle={Styles.minimumValueDropdown}
                      notEditable={
                        !enableDropdown(_selectedShiftIds, row.node) ||
                        !isValidPermission(API.Permission.workstations_edit, row.node)
                      }
                      textStyle={Styles.countStyle}
                    />
                  }
                />
              ) : (
                <></>
              );
            },
          },
          {
            label: t('alex:workerTable.lev2AndAbove', {
              count: API.WorkstationWorkerLevels.LEVEL4,
            }),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  countStyle={Styles.textCountStyle}
                  list={[]}
                  disableHover
                  count={API.formatDecimalPercentageTargetCount(
                    row.info?.level4actual ?? 0,
                    !!radioIndex,
                  )}
                  onMenuItemPress={() => {}}
                  countColor={
                    row.info?.isTargetOK ? SharedStyles.Colors.Black : SharedStyles.Colors.Red
                  }
                />
              ) : (
                <></>
              ),
          },
          {
            label: t('glossary:levelTarget'),
            width: TableMediumColumnWidth,
            renderCell: row => {
              const _selectedShiftIds: string[] = [];

              selectedShiftIds.forEach(shiftId =>
                _selectedShiftIds.push(
                  ...(row.node.object.shiftIds?.filter(_shiftId => _shiftId === shiftId) ?? []),
                ),
              );

              return isDisplayRowInfo(row) ? (
                <ToolTipWeb
                  title={
                    enableDropdown(_selectedShiftIds, row.node)
                      ? ''
                      : t('alex:KPI.orgUnitsKPITab.selectOneShift')
                  }
                  style={Styles.toolTip}
                  component={
                    <DropDownSingleSelection
                      options={getDropDownOptions(!!radioIndex)}
                      value={{
                        key: API.formatDecimalPercentageTargetCount(
                          row.info?.level4target ?? 0,
                          !!radioIndex,
                        ),
                        label: API.formatDecimalPercentageTargetCount(
                          row.info?.level4target ?? 0,
                          !!radioIndex,
                        ),
                      }}
                      placeholder={''}
                      handleChange={(inputId, option) =>
                        _handleDropdownChange(
                          API.WorkstationWorkerLevels.LEVEL4,
                          row,
                          option,
                          _selectedShiftIds[0],
                        )
                      }
                      containerStyle={Styles.minimumValueDropdown}
                      notEditable={
                        !enableDropdown(_selectedShiftIds, row.node) ||
                        !isValidPermission(API.Permission.workstations_edit, row.node)
                      }
                      textStyle={Styles.countStyle}
                    />
                  }
                />
              ) : (
                <></>
              );
            },
          },
        ];

        if (workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL3)
          _columnsVersatility.splice(1, 2);
        if (workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL4)
          _columnsVersatility.splice(1, 4);

        setColumnsVersatility(_columnsVersatility);
        break;

      
      
      

      case KPI_Tabs.MAGICSQUARE:
        const _columnsMagicSquare: TableColumn<TreeRowMagicSquare>[] = [
          {
            label: t('alex:header.navigation.2'),
            width: TableLargeColumnWidth,
            renderCell: row => {
              return (
                <View
                  style={[
                    Styles.firstColumnCellContainer,
                    {
                      paddingLeft: row.info.isWorkstation
                        ? (row.node.object.pathIds.length - 3) * SharedStyles.Spacings.Large
                        : (row.node.object.pathIds.length - 2) * SharedStyles.Spacings.Large,
                      marginLeft: row.info.isWorkstation ? SharedStyles.Spacings.Standard : 0,
                    },
                  ]}
                >
                  {!row.info.isWorkstation && (
                    <IconSVG
                      svgComponent={row.node.isExpanded ? arrowDownIcon : arrowRightIcon}
                      color={SharedStyles.Colors.Black}
                      containerStyle={Styles.arrowIconContainer}
                      size={{
                        width: SharedStyles.Spacings.Standard,
                        height: SharedStyles.Spacings.Standard,
                      }}
                    />
                  )}
                  <IconSVG
                    svgComponent={
                      row.info.isWorkstation
                        ? row.info.alertLevel === MagicSquareAlertLevel.RED
                          ? workstationNotOkIcon
                          : workstationOkIcon
                        : orgUnitIcon
                    }
                    containerStyle={[
                      SharedStyles.Styles.tableObjectIconContainer,
                      {
                        backgroundColor: !isDisplayRowInfo(row)
                          ? SharedStyles.Colors.Black
                          : row.info.alertLevel === MagicSquareAlertLevel.GREEN
                          ? SharedStyles.Colors.Green
                          : row.info.alertLevel === MagicSquareAlertLevel.ORANGE
                          ? SharedStyles.Colors.Orange
                          : SharedStyles.Colors.Red,
                        marginLeft: row.info.isWorkstation ? SharedStyles.Spacings.Small : 0,
                      },
                    ]}
                    size={
                      row.info.isWorkstation
                        ? {
                            width: SharedStyles.Spacings.Medium,
                            height: SharedStyles.Spacings.Medium,
                          }
                        : {
                            width: SharedStyles.Spacings.Standard,
                            height: SharedStyles.Spacings.Standard,
                          }
                    }
                    color={SharedStyles.Colors.White}
                  />
                  <EllipsisWithTooltip
                    text={row.node.object.name}
                    textStyle={SharedStyles.Styles.tableText}
                  />
                  <View>
                    <RenderShiftsForTable row={row} handleSelectShift={handleSelectShift} />
                  </View>
                </View>
              );
            },
          },
          {
            label: API.getWorkstationWorkerLevelLabel(API.WorkstationWorkerLevels.LEVEL1),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  list={[]}
                  disableHover
                  count={row.info.level1Exact}
                  onMenuItemPress={() => {}}
                  countColor={SharedStyles.Colors.Black}
                />
              ) : (
                <></>
              ),
          },
          {
            label: API.getWorkstationWorkerLevelLabel(API.WorkstationWorkerLevels.LEVEL2),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  list={[]}
                  disableHover
                  count={row.info.level2Exact}
                  onMenuItemPress={() => {}}
                  countColor={SharedStyles.Colors.Black}
                />
              ) : (
                <></>
              ),
          },
          {
            label: API.getWorkstationWorkerLevelLabel(API.WorkstationWorkerLevels.LEVEL3),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  list={[]}
                  disableHover
                  count={row.info.level3Exact}
                  onMenuItemPress={() => {}}
                  countColor={SharedStyles.Colors.Black}
                />
              ) : (
                <></>
              ),
          },
          {
            label: API.getWorkstationWorkerLevelLabel(API.WorkstationWorkerLevels.LEVEL4),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  list={[]}
                  disableHover
                  count={row.info.level4Exact}
                  onMenuItemPress={() => {}}
                  countColor={SharedStyles.Colors.Black}
                />
              ) : (
                <></>
              ),
          },
          {
            label: t('alex:KPI.magicSquareTable.numberOfSquaresColumn'),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  list={[]}
                  disableHover
                  count={row.info.totalMagicSquares}
                  onMenuItemPress={() => {}}
                  countColor={SharedStyles.Colors.Black}
                />
              ) : (
                <></>
              ),
          },
          {
            label: t('alex:KPI.magicSquareTable.meanPerEmployee'),
            width: TableWidth,
            renderCell: row =>
              isDisplayRowInfo(row) ? (
                <TableNumberWithMenu
                  list={[
                    {
                      id: '' + MagicSquareAlertLevel.GREEN,
                      name: t('alex:KPI.magicSquareTable.alertDescription.0'),
                    },
                    {
                      id: '' + MagicSquareAlertLevel.RED,
                      name: t('alex:KPI.magicSquareTable.alertDescription.1'),
                    },
                    {
                      id: '' + MagicSquareAlertLevel.ORANGE,
                      name: t('alex:KPI.magicSquareTable.alertDescription.2'),
                    },
                  ]}
                  disableHover
                  count={row.info.meanPerEmployee}
                  onMenuItemPress={() => {}}
                  countColor={
                    row.info.alertLevel === MagicSquareAlertLevel.GREEN
                      ? SharedStyles.Colors.Green
                      : row.info.alertLevel === MagicSquareAlertLevel.ORANGE
                      ? SharedStyles.Colors.Orange
                      : SharedStyles.Colors.Red
                  }
                />
              ) : (
                <></>
              ),
          },
        ];

        setColumnsMagicSquare(_columnsMagicSquare);
        break;
    }
  }

  function isDisplayRowInfo(row: TreeRowVersatility | TreeRowMagicSquare): boolean {
    return row.info?.isWorkstation || (!row.node.isExpanded && (row.info?.childrenCount ?? 0) > 0);
  }

  function renderTable(): JSX.Element | null | undefined {
    const activityIndicator = <ActivityIndicator style={Styles.activityIndicator} />;

    switch (selectedTab) {
      case KPI_Tabs.VERSATILITY:
        return (
          <Table
            disableSorting
            disableRowClick={false}
            onRowPress={handleRowPress}
            columnDescriptors={columnsVersatility ?? []}
            rows={[...(dataVersatilityRows ?? [])]}
            rowLazyLoadProperties={fetchVersatilityRowDetails}
          />
        );

      case KPI_Tabs.MAGICSQUARE:
        return dataMagicSquare ? (
          <Table
            disableRowClick={false}
            disableSorting
            onRowPress={handleRowPress}
            columnDescriptors={columnsMagicSquare ?? []}
            rows={dataMagicSquare ?? []}
          />
        ) : (
          activityIndicator
        );

      default:
        return null;
    }
  }

  return (
    <View style={[SharedStyles.Styles.cardMain, Styles.tableContainer]}>
      <View style={Styles.topBarContainer}>
        <TouchableOpacity style={Styles.backArrowIconContainer} onPress={() => history.goBack()}>
          <IconSVG
            svgComponent={arrowLeftIcon}
            color={SharedStyles.Colors.Black}
            size={{
              width: SharedStyles.Spacings.Standard,
              height: SharedStyles.Spacings.Standard,
            }}
          />
        </TouchableOpacity>
        <View style={Styles.tabContainer}>
          <AnimatedTab
            currentTabIndex={selectedTab}
            tabs={tabs}
            onTabPress={index => setSelectedTab(index)}
          />
        </View>
        <TouchableOpacity style={Styles.moreIcon} onPress={() => setShowMenu(!showMenu)}>
          <IconSVG
            svgComponent={moreIcon}
            color={SharedStyles.Colors.Black}
            size={{
              width: SharedStyles.Spacings.Standard,
              height: SharedStyles.Spacings.Standard,
            }}
          />
        </TouchableOpacity>
        {showMenu ? (
          <Card style={Styles.card} ref={displayUnitMenuRef}>
            <RadioButton
              items={displayUnitMenuItems.map(item => {
                return { item: item.label };
              })}
              onChange={handleRadioChange}
              initialItemIndex={radioIndex}
              radioButtonColor={SharedStyles.Colors.Black}
              textStyle={Styles.radioText}
            />
          </Card>
        ) : null}
      </View>

      <View style={Styles.tableOuterContainer}>{renderTable()}</View>
    </View>
  );
};
