import React from 'react';
import { View, Text } from 'react-native';
import { t } from 'shared/localisation/i18n';
import Styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors, Spacings } from 'shared/styles';
import * as _ from 'lodash-es';

const ICON_INFO = require('shared/assets/svg/icon.info.svg').default;

interface Props {
  isPractical?: boolean;
  withIcon?: boolean;
}

export const ToBeClosedTrainingsMessage: React.FC<Props> = props => {
  const { isPractical = true, withIcon = false } = props;
  return (
    <View style={Styles.complementaryTrainingsContainer}>
      {withIcon && (
        <IconSVG
          svgComponent={ICON_INFO}
          containerStyle={{ width: 24, height: 24, marginRight: Spacings.Small }}
          color={Colors.GreyLight}
          size={{ width: 24, height: 24 }}
        />
      )}
      <View style={Styles.warningMessageContainer}>
        <Text style={Styles.warningTextStyle}>
          {isPractical
            ? t('alex:TrainingModal.toBeClosedPracticalTraining')
            : t('alex:TrainingModal.toBeClosedTraining')}
        </Text>
      </View>
    </View>
  );
};
