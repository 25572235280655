import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, InteractionManager } from 'react-native';
import {
  t,
  LanguageGlossaries,
  languages,
  Languages,
  Language,
  getLanguage,
  updateLanguageGlossaries,
  setLanguageAndLoadGlossary,
} from 'shared/localisation/i18n';
import { useIsMounted } from 'shared/hooks/IsMounted';
import styles from './styles';
import * as _ from 'lodash-es';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { IconSVG } from 'shared/ui-component/Icon';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { ModalUtils } from 'shared/ui-component/Modal';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { Colors } from 'shared/styles';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';

interface Props {
  defaultLanguage?: DropDownOption;
  isAdmin: boolean;
  setLoading: (value: boolean) => void;
  handlesubmit: (glossaries: string) => void;
}

interface TranslationRowProps {
  index: number;
  translationKey: string;
  isLevelsKeys?: boolean;
}

const EditIcon = require('shared/assets/svg/icon.edit.svg').default;

export const Glossary: React.FC<Props> = props => {
  const { defaultLanguage, isAdmin, setLoading, handlesubmit } = props;
  const isMounted = useIsMounted();
  const [language, setLanguage] = useState<Language>(getLanguage());
  const [levelsTranslationKeys, setLevelsTranslationKeys] = useState<string[]>([]);
  const [translationKeys, setTranslationKeys] = useState<string[]>([]);
  const [tenant, setTenant] = useState<API.Tenant>();
  const [tenantGlossaries, setTenantGlossaries] = useState<LanguageGlossaries>(
    _.mapValues(
      _.keyBy(languages, language => language.locale) as Languages, 
      language => {
        return {}; 
      },
    ),
  );
  const modal = ModalUtils.useModal();

  useEffect(() => {
    if (defaultLanguage) setLanguage(defaultLanguage.value);
  }, [defaultLanguage]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const _tenant = await API.getTenant();
      if (API.isFailure(_tenant)) return _tenant;

      setTenant(_tenant);
    });
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      if (tenant && tenant.glossary) {
        const _tenantGlossaries: LanguageGlossaries = JSON.parse(tenant.glossary);
        setTenantGlossaries(_tenantGlossaries);

        await Promise.all(
          Object.entries(_tenantGlossaries[language.locale]).map(
            async ([glossaryKey, glossaryValue]) => {
              language.defaultGlossary[glossaryKey] = glossaryValue;
            },
          ),
        );

        await setLanguageAndLoadGlossary(language);
        if (!isMounted.current) return;
        setLanguage(language);
      }
    });
  }, [tenant]);

  useEffect(() => {
    const _translationKeys = Object.keys(languages[language.locale].defaultGlossary).filter(key => {
      return !(
        key.endsWith(getPluralKey('')) ||
        key.endsWith('Default') ||
        key.endsWith('Description')
      );
    });
    setTranslationKeys(_translationKeys);

    setLevelsTranslationKeys(['level_0', 'level_1', 'level_2', 'level_3', 'level_4']);
  }, []);

  function getPluralKey(key: string): string {
    return key + '_plural';
  }

  function getPluralDefaultValue(value: string): string {
    return value + 's';
  }

  async function handleGlossaryModalEdit(tag: Tag, isDescription?: boolean) {
    setLoading(true);

    let { key: translationKey, label: value } = tag;

    
    if (value === '') return;

    
    if (translationKey.match(/^level_.$/)) {
      value = value.replace(t('glossary:level'), '$t(glossary:level)');
      if (!value.startsWith('$t(glossary:level)')) {
        value = '$t(glossary:level) ' + value;
      }
    }

    
    value = value.toLowerCase();

    tenantGlossaries[language.locale][translationKey] = value;

    
    if (translationKey !== getPluralKey(translationKey) && !isDescription) {
      tenantGlossaries[language.locale][getPluralKey(translationKey)] =
        getPluralDefaultValue(value);
    }

    const result = await updateLanguageGlossaries(tenantGlossaries);
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      setLoading(false);
      logger.warn('Language Glossary could not be saved. Please try again');
      return;
    }
    setTenant(result);

    const glossariesJsonString = JSON.stringify(tenantGlossaries);
    handlesubmit(glossariesJsonString);
    setLoading(false);
  }

  function handleGlossaryModal(
    keyword: string,
    value: string,
    defaultValue: string,
    description: string,
    isDescription?: boolean,
  ) {
    modal.displayModal(
      ModalUtils.TagModalConfig({
        label: defaultValue,
        placeholder: defaultValue,
        description: description,
        editTag: {
          tag: {
            key: keyword,
            label: value,
          },
          editHandler: tag => handleGlossaryModalEdit(tag, isDescription),
        },
        createHandler: () => { },
      }),
    );
  }

  const TranslationRow: React.FC<TranslationRowProps> = props => {
    const { translationKey, index, isLevelsKeys } = props;
    const [showEditIcon, setShowEditIcon] = useState<boolean>(false);
    const ref = useCallOnHover<View>(
      '',
      () => setShowEditIcon(true),
      () => setShowEditIcon(false),
    );

    return (
      <View style={[styles.eachGlossaryContainer, { zIndex: -index }]} ref={ref}>
        <EllipsisWithTooltip
          text={
            isLevelsKeys
              ? t('glossary:' + translationKey + 'Description')
              : t('glossary:' + translationKey)
          }
          textStyle={isLevelsKeys ? styles.eachLevelDescription : styles.glossary}
          style={styles.glossaryTextContainer}
        />

        <View style={styles.editIcon}>
          <TouchableOpacity
            onPress={() => {
              isLevelsKeys
                ? handleGlossaryModal(
                  translationKey + 'Description',
                  t(['glossary:' + translationKey + 'Description', translationKey]),
                  t(['glossary:' + translationKey + 'Default', translationKey]),
                  '',
                  true,
                )
                : handleGlossaryModal(
                  translationKey,
                  t('glossary:' + translationKey),
                  t(['glossary:' + translationKey + 'Default', translationKey]),
                  t(['glossary:' + translationKey + 'Description', translationKey]),
                );
            }}
          >
            {isAdmin && (
              <IconSVG
                svgComponent={EditIcon}
                color={!showEditIcon ? Colors.White : Colors.Black}
              />
            )}
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.glossaryContainer}>
      <View style={styles.translationKeysContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{t('common:tenant.glossary')}</Text>
          <Text style={styles.roleDescription}>{t('common:tenant.glossaryDescription')}</Text>
        </View>
        <View style={styles.glossaryList}>
          {translationKeys.map((translationKey, index) => (
            <TranslationRow translationKey={translationKey} key={translationKey} index={index} />
          ))}
        </View>
      </View>

      <View style={styles.levelsContainer}>
        <Text style={styles.levelsTitle}>{t('common:tenant.levesTitle')}</Text>
        <Text style={styles.eachLevelDescription}>{t('common:tenant.levelsDescription')}</Text>
        {levelsTranslationKeys.map((translationKey, index) => (
          <View style={[styles.eachLevelContainer, { zIndex: -index }]} key={index}>
            <Text style={styles.eachLevelTitle}>{t('glossary:' + translationKey)}</Text>
            <TranslationRow
              translationKey={translationKey}
              key={translationKey}
              index={index}
              isLevelsKeys
            />
          </View>
        ))}
      </View>
    </View>
  );
};
