import React from 'react';
import WebModal from 'modal-react-native-web';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import * as _ from 'lodash-es';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { ModalBackgroundStyle } from 'shared/styles/ModalStyles';
import { ReassignmentModalProps } from '../container';
import { t } from 'shared/localisation/i18n';
import { IconSVG } from 'shared/ui-component/Icon';
import Styles from './style';
import { YellowButton } from 'shared/ui-component/Button';
import * as API from 'shared/backend-data';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import {
  OrganizationUnit,
  DeleteIconState,
} from 'shared/layout/organization-unit/OrganizationUnit';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

interface ReassignmentModalComponentProps extends ReassignmentModalProps {
  isLoading?: boolean;
  workersInShiftOrOrgUnit: API.Worker[];
  allShifts: API.Shift[];
  selectedRole: API.IndexedAssignment;

  setSelectedOrgUnitOrShift: (ou: API.IndexedAssignment) => void;
  submit: () => void;

  extraSelectorsToDisable?: Tag[];
  nodesToDisable?: Tag[];

  hideSubHeader?: boolean;
}

export const ReassignmentModalComponent: React.FC<ReassignmentModalComponentProps> = props => {
  const {
    isLoading,
    workersInShiftOrOrgUnit,
    allShifts,
    selectedRole,
    extraSelectorsToDisable,
    nodesToDisable,
    hideSubHeader,
    handleModalClose,
    setSelectedOrgUnitOrShift,
    submit,
  } = props;

  return (
    <WebModal animationType="fade" transparent onRequestClose={() => handleModalClose()}>
      {isLoading && <Loader />}
      <View style={ModalBackgroundStyle}>
        <View style={Styles.modalBackground}>
          <TouchableOpacity style={Styles.closeIconContainer} onPress={handleModalClose}>
            <IconSVG svgComponent={closeIcon} />
          </TouchableOpacity>

          <Text style={Styles.titleStyle}>{t('alex:reassignmentModal.header')}</Text>
          {!hideSubHeader && <Text>{t('alex:reassignmentModal.subHeader')}</Text>}

          <OrganizationUnit
            defaultAssignment={selectedRole}
            index={-1}
            displayDeleteIcon={DeleteIconState.Hide}
            dropDownContainerStyle={Styles.unitsContainer}
            inputListContainerStyle={{ width: 550 }}
            assignments={[selectedRole]}
            allShifts={allShifts}
            removeUnitRole={() => {}}
            handleChange={_indexedAssignment => setSelectedOrgUnitOrShift(_indexedAssignment)}
            hidePermission
            preDisabledExtraSelectors={
              extraSelectorsToDisable ? extraSelectorsToDisable : undefined
            }
            preDisabledNodes={nodesToDisable ? nodesToDisable : undefined}
          />

          <ScrollView contentContainerStyle={Styles.workersContainer}>
            {workersInShiftOrOrgUnit.map(worker => {
              return (
                <View key={worker.id} style={Styles.nodeWorkerContainer}>
                  {worker && renderImage(worker.profilePicture, ImageSizes.Small, worker.name)}
                  <Text numberOfLines={1} style={Styles.workerNameText}>
                    {worker.name}
                  </Text>
                </View>
              );
            })}
          </ScrollView>

          <YellowButton
            text={t('common:button.save')}
            style={Styles.saveButton}
            textStyle={Styles.saveButtonTextStyle}
            onPress={submit}
          />
        </View>
      </View>
    </WebModal>
  );
};
