import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Styles } from './Styles';
import * as API from 'shared/backend-data';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { IconSVG } from 'shared/ui-component/Icon';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { Colors, Spacings } from 'shared/styles';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import { LevelProgressAndTrainingStatus } from './LevelProgressAndTrainingStatus';
import { LevelIcon } from 'shared/ui-component/Icon/LevelIcon/LevelIcon';
import { LevelTargetAndMaintainOption } from '../../workstation-worker-menu/LevelTargetAndMaintainOption';
import { t } from 'shared/localisation/i18n';

const crossIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;
const workstationIcon = require('shared/assets/svg/icon.workstation.svg').default;

interface Props {
  workerWorkstation: API.WorkerWorkstation;
  worker: API.Worker;
  workstation: API.Workstation;
  loading: boolean;
  levelIconType?: API.LevelIconType;
  workstationSkillsRequiredInLevels?: API.WorkstationSkillsRequiredInLevels;
  totalWorkerSkillPercentage: number;
  allLevelTrainingSessionsEstimationDate?: string;
  showTargetAndMaintain: boolean;

  setShowAddWorkerSkillModal: (show: boolean) => void;
  setShowTrainingModal: (isShow: boolean) => void;
  handleUnassignWorkerAndStopTrainings: () => Promise<void>;
  handleModalClose: () => void;
  handleMaintainSelect: (maintainLevel: API.WorkstationWorkerLevels) => void;
  handleSetTrainingLevel: (trainingLevel: API.WorkstationWorkerLevels | undefined) => Promise<void>;
}

export const Header: React.FC<Props> = props => {
  const {
    workerWorkstation,
    worker,
    workstation,
    loading,
    levelIconType,
    workstationSkillsRequiredInLevels,
    totalWorkerSkillPercentage,
    allLevelTrainingSessionsEstimationDate,
    showTargetAndMaintain,

    setShowAddWorkerSkillModal,
    setShowTrainingModal,
    handleUnassignWorkerAndStopTrainings,
    handleSetTrainingLevel,
    handleModalClose,
    handleMaintainSelect,
  } = props;

  return (
    <View style={[Styles.headerContainer]}>
      <View style={[Styles.headerInnerContainer]}>
        <View style={Styles.workerInfoOuterContainer}>
          <View style={[Styles.profileImageContainer]}>
            <View style={Styles.workerProfile}>
              {worker && renderImage(worker.profilePicture, ImageSizes.Large, worker.name)}
            </View>
            <IconSVG
              svgComponent={workstationIcon}
              containerStyle={Styles.workstationIconContainer}
              color={Colors.White}
            />
          </View>
          <View style={Styles.workerInfoContainer}>
            <EllipsisWithTooltip
              text={worker.name}
              textStyle={Styles.workerInfoText}
              mouseHoverTextContainer={Styles.workerInfoTextEllipsis}
            />
            <EllipsisWithTooltip
              text={workstation.name}
              textStyle={[Styles.worstationInfoText, { paddingTop: Spacings.Unit / 2 }]}
              mouseHoverTextContainer={Styles.worstationInfoTextEllipsis}
            />
          </View>
        </View>
        {loading ? (
          <LoaderThreeDots lowPerformance={false} />
        ) : (
          !!workerWorkstation.level && (
            <View style={Styles.levelContainer}>
              <View style={Styles.levelInnerContainer}>
                <LevelIcon
                  workerWorkstation={workerWorkstation}
                  iconSize={{
                    height: Spacings.CardPadding * 2,
                    width: Spacings.CardPadding * 2,
                  }}
                  levelIconType={levelIconType}
                />
              </View>
              <View style={[Styles.rowLevelContainer]}>
                <Text style={Styles.levelInfoText}>{`${t(
                  `glossary:level_${API.api2workstationWorkerLevels(workerWorkstation.level)}`,
                )}`}</Text>
                {/**
                 *
                 */}
                <LevelTargetAndMaintainOption
                  workerWorkstation={workerWorkstation}
                  workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
                  style={Styles.levelTargetAndMaintainContainer}
                  maintainInfoModalCoordinates={{ x: -40 }}
                  showOnlyTargetLevel
                  handleMaintainSelect={handleMaintainSelect}
                  handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
                  setTrainingLevel={handleSetTrainingLevel}
                  setShowTrainingModal={setShowTrainingModal}
                />
              </View>
            </View>
          )
        )}

        <View style={Styles.progressBarContainer}>
          <LevelProgressAndTrainingStatus
            showTrainingLevel
            workerWorkstation={workerWorkstation}
            workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
            percentage={totalWorkerSkillPercentage}
            estimatedTime={allLevelTrainingSessionsEstimationDate}
            setTrainingLevel={handleSetTrainingLevel}
            setShowTrainingModal={setShowTrainingModal}
            handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
            handleMaintainSelect={handleMaintainSelect}
            maintainInfoModalCoordinates={{ x: -30, y: -100 }}
            showEstimatedTime={!!workerWorkstation.targetLevel}
            showTargetAndMaintain={showTargetAndMaintain}
          />
        </View>
        <TouchableOpacity
          style={[Styles.closeIconContainer]}
          onPress={() => {
            handleModalClose();
          }}
        >
          <IconSVG svgComponent={crossIcon} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
