import React from 'react';
import { View, Text } from 'react-native';
import { t } from 'shared/localisation/i18n';
import styles from 'skillmgtweb/src/components/add-workerskill-modal/component/Style';

export const UnsupportedComponent = () => (
  <View>
    <Text style={styles.errorMessage}>
      {t('alex:workerSkillReviewModal.unsupportedFormatError')}
    </Text>
  </View>
);
