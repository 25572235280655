import React, { useState } from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';
import Styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { PageSwitch } from 'shared/layout/page-switch/container';
import { Colors } from 'shared/styles/Colors';
import * as _ from 'lodash-es';
import { Proof } from './Proof';
import FileSaver from 'file-saver';
import { Spacings } from 'shared/styles';
import { S3ObjectWithLink } from 'shared/backend-data';

const downloadIcon = require('shared/assets/svg/icon.download.svg').default;
const arrowBackIcon = require('shared/assets/svg/icon.arrowLeft.mobile-2.svg').default;

interface LeftPanelProps {
  files: S3ObjectWithLink[];
  arrowBack?: boolean;
  handleModalClose?: () => void;
}

export const LeftPanel: React.FC<LeftPanelProps> = props => {
  const { files, arrowBack, handleModalClose } = props;

  const [selectedProofIndex, setSelectedProofIndex] = useState<number>(0);

  function handlePreviewPagesSwitch(index: number) {
    setTimeout(function () {
      setSelectedProofIndex(index);
    }, 6);
  }

  const renderLeftPanel = () => {
    return (
      <ScrollView
        style={[Styles.leftPanel]}
        contentContainerStyle={Styles.leftPanelContentContainer}
        horizontal
        scrollEnabled={false}
        showsHorizontalScrollIndicator={false}
      >
        {arrowBack && handleModalClose && (
          <TouchableOpacity style={Styles.arrowBackContainer} onPress={handleModalClose}>
            <IconSVG
              svgComponent={arrowBackIcon}
              size={{ width: Spacings.Standard, height: Spacings.Standard }}
            />
          </TouchableOpacity>
        )}
        <View style={Styles.leftPanelSecondPage}>
          <View style={Styles.addRemoveProofHeader}>
            <View style={[Styles.addRemoveProofContainer, { justifyContent: 'flex-end' }]}>
              <TouchableOpacity
                style={Styles.roundTouchable}
                onPress={() => {
                  FileSaver.saveAs(
                    files[selectedProofIndex].downloadLink,
                    files[selectedProofIndex].fileName ?? undefined,
                  );
                }}
              >
                <IconSVG
                  svgComponent={downloadIcon}
                  size={{ width: Spacings.Standard, height: Spacings.Standard }}
                  color={Colors.Black}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={Styles.proofsPreviewContainer}>
            <ScrollView
              horizontal
              style={Styles.proofsPreviewScrollContainer}
              contentContainerStyle={Styles.proofsPreviewScrollContentContainer}
              scrollEnabled={false}
            >
              <TouchableOpacity
                style={{
                  width: '100%',
                }}
              >
                <Proof file={files[selectedProofIndex]} />
              </TouchableOpacity>
            </ScrollView>
          </View>
          <PageSwitch
            maxIndex={files.length}
            onSwitch={handlePreviewPagesSwitch}
            currentIndex={selectedProofIndex}
          />
        </View>
      </ScrollView>
    );
  };
  return <>{renderLeftPanel()}</>;
};
