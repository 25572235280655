import React, { useState } from 'react';
import style from './style';
import { Card } from '../Card';
import { TouchableOpacity, Text, View, StyleProp, ViewStyle } from 'react-native';
import { MenuItem, MenuWidth } from '.';
import ReactDOM from 'react-dom';
import { t } from '../../localisation/i18n';
import { useCallOnHover } from '../../hooks/CallOnHover';
import { useDetectOutsideClick } from '../../hooks/DetectOutsideClick';

interface Props {
  menuItems: MenuItem[];
  setShowMenu: (value: boolean) => void;
  menuWidth?: MenuWidth;
  position?: 'bottom-left' | 'top-left' | 'bottom-right' | 'top-right';
  onMenuPress?: (value: boolean) => void;
  styles?: StyleProp<ViewStyle>;
}

export const MenuCard: React.FC<Props> = props => {
  const { setShowMenu, onMenuPress, styles } = props;

  const [isMenuOverflow, setIsMenuOverflow] = useState<boolean>(false);

  let positionStyle;
  switch (props.position ?? 'bottom-left') {
    case 'bottom-left':
      positionStyle = { top: 26, right: 30 };
      break;
    case 'top-left':
      positionStyle = { bottom: 26, right: 30 };
      break;
    case 'bottom-right':
      positionStyle = { top: 26, left: 0 };
      break;
    case 'top-right':
      positionStyle = { bottom: 26, left: 0 };
      break;
  }

  let menuWidth;
  switch (props.menuWidth ?? MenuWidth.Small) {
    case MenuWidth.Small:
      menuWidth = 150;
      break;
    case MenuWidth.Medium:
      menuWidth = 200;
      break;
    case MenuWidth.Large:
      menuWidth = 300;
      break;
  }

  const ref = useDetectOutsideClick<View>(() => {
    setShowMenu(false);
    onMenuPress && onMenuPress(false);
  });

  const MenuItem: React.FC<{ menuItem: MenuItem }> = props => {
    if (props.menuItem.disable) return null;

    const _onPress = () => {
      props.menuItem.onPress();
      onMenuPress && onMenuPress(false);
      setShowMenu(false);
    };
    return (
      <>
        <TouchableOpacity
          ref={useCallOnHover<TouchableOpacity>()}
          onPress={_event => {
            _onPress();
          }}
          style={style.textContainer}
        >
          <Text style={[style.label, props.menuItem.style ?? null]}>{t(props.menuItem.label)}</Text>
          {props.menuItem.subLabel && (
            <Text style={[style.label, props.menuItem.subLabelStyle ?? null]}>
              {t(props.menuItem.subLabel)}
            </Text>
          )}
        </TouchableOpacity>
      </>
    );
  };

  return (
    <Card
      style={[
        style.menuContainer,
        positionStyle,
        isMenuOverflow && { bottom: 0, top: 'unset' },
        { width: menuWidth },
        styles,
      ]}
      ref={ref => {
        const node = ReactDOM.findDOMNode(ref) as Element;
        if (node) {
          if (node.getBoundingClientRect().bottom > window.innerHeight) {
            setIsMenuOverflow(true);
          }
        }
      }}
    >
      <View ref={ref}>
        {props.menuItems.map((menuItem, index) => (
          <MenuItem key={index} menuItem={menuItem} />
        ))}
      </View>
    </Card>
  );
};
