import React, { useState, useEffect } from 'react';
import { Text, ScrollView, TouchableOpacity, View, ViewStyle } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import Styles from './Styles';
import * as _ from 'lodash-es';
import { SkillNameListing } from './SkillNameListing';
import * as API from 'shared/backend-data';
import { Spacings } from 'shared/styles';
import { FileRow } from 'shared/ui-component/AttachFile/FileRow';

const arrowDown = require('shared/assets/svg/icon.next.small.svg').default;
const AttachIcon = require('shared/assets/svg/icon.attachment.svg').default;

interface Props {
  skillIds: readonly string[];
  files?: readonly API.S3ObjectInput[];
  containerStyle?: ViewStyle;
  forceDisplaySkillNames?: string[];
}

export const SkillExpand: React.FC<Props> = props => {
  const { skillIds, containerStyle, forceDisplaySkillNames, files } = props;

  const [isSkillsListExpanded, setIsSkillsListExpanded] = useState(false);
  const [skillsCount, setSkillsCount] = useState(
    skillIds.length + (forceDisplaySkillNames ? forceDisplaySkillNames?.length : 0),
  );

  useEffect(() => {
    setSkillsCount(skillIds.length + (forceDisplaySkillNames ? forceDisplaySkillNames?.length : 0));
  }, [skillIds, forceDisplaySkillNames]);

  return (
    <View style={[Styles.skillExpandContainer, containerStyle]}>
      <TouchableOpacity
        style={Styles.touchableContainer}
        onPress={() => {
          setIsSkillsListExpanded(prev => !prev);
        }}
      >
        <View style={Styles.rowView}>
          <Text style={[Styles.trainingSkillCountTextStyle]}>
            {skillsCount}{' '}
            {t(
              'glossary:skill',
              {
                count: skillsCount,
              },
              false,
            )}
          </Text>

          {!!files?.length && (
            <View style={Styles.rowView}>
              <Text style={Styles.fileTextStyle}>{files.length}</Text>
              <IconSVG
                svgComponent={AttachIcon}
                containerStyle={{ width: 24, height: 24 }}
                size={{ width: 24, height: 24 }}
                color={SharedStyles.Colors.Grey}
              />
            </View>
          )}
        </View>
        <IconSVG
          svgComponent={arrowDown}
          size={{ width: 24, height: 24 }}
          containerStyle={[
            Styles.trainingIconContainer,
            { transform: [{ rotate: isSkillsListExpanded ? '270deg' : '90deg' }] },
          ]}
          color={skillsCount ? SharedStyles.Colors.Black : SharedStyles.Colors.Grey}
        />
      </TouchableOpacity>
      {isSkillsListExpanded && (
        <ScrollView style={Styles.scrollViewContainer}>
          {_.map(forceDisplaySkillNames, skillName => {
            return <SkillNameListing key={Math.random().toString()} skillName={skillName} />;
          })}
          {_.map(skillIds, skillId => {
            return <SkillNameListing key={skillId} skillId={skillId} />;
          })}
          {!!files?.length &&
            _.map(files, file => {
              return (
                <FileRow
                  key={file.key}
                  file={file}
                  containerStyle={{ marginBottom: Spacings.Small }}
                />
              );
            })}
        </ScrollView>
      )}
    </View>
  );
};
