import React from 'react';
import { ViewStyle } from 'react-native';
import { Card } from 'shared/ui-component/Card';
import _ColorPicker from 'react-native-wheel-color-picker';
import Style from './Style';

export interface ColorPickerProps {
  style?: ViewStyle;
  color?: string;
  onColorChange: (color: string) => void;
}

export const ColorPicker: React.FC<ColorPickerProps> = props => {
  const { style, onColorChange, color } = props;

  return (
    <Card style={[Style.container, style]}>
      <_ColorPicker color={color} swatches={false} onColorChange={onColorChange} thumbSize={30} />
    </Card>
  );
};
