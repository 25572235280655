import React, { useState, useEffect, useContext } from 'react';
import { ViewTrainingComponent } from '../component/ViewTrainingComponent';
import * as API from 'shared/backend-data';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import * as _ from 'lodash-es';
import { MyHub } from 'shared/util/MyHub';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { useParams } from 'react-router-dom';
import { ProfileRouteParam } from '../../../navigation/Routes';
import { combineDataTypeAndId } from 'shared/backend-data';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';

export interface AddTrainingToNodeLevelRequirement {
  nodeId: string;
  requirementId?: string;
  requirementLevel?: API.WorkstationWorkerLevels;
}

interface Props extends RouteComponentProps<{}, {}> {}

export const ViewTraining: React.FC<Props> = props => {
  const isMounted = useIsMounted();

  const { setHideMenuFactory } = useContext(GlobalDataContext);

  const [training, setTraining] = useState<API.Training | undefined>();

  const { id } = useParams<ProfileRouteParam>();

  useEffect(() => {
    if (id) {
      reFetchTraining(combineDataTypeAndId(API.DataType.TRAINING, id));
      setHideMenuFactory(true);
    }
  }, [id]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.TRAINING &&
        (data.tooManyMutations || data.factory.training.id === training?.id)
      )
        reFetchTraining();
    });

    return () => {
      removeListener();
    };
  }, []);

  const history = useHistory();

  function handleGoBack() {
    history.goBack();
  }

  async function reFetchTraining(trainingId?: string) {
    const _trainingId = trainingId || training?.id;
    if (!_trainingId) return;

    const _training = await API.getTraining(_trainingId);
    if (!isMounted.current) return;
    if (API.isFailure(_training)) return _training;

    setTraining(_training);
  }

  return <ViewTrainingComponent training={training} handleGoBack={handleGoBack} />;
};
