import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import styles from './Styles';
import { StackedAreaGraph, ChartConfig } from 'sharedweb/src/Graph/StackedAreaGraph';
import { t } from 'shared/localisation/i18n';
import { TrainingSessionActualData, ForecastTrainingGraphData } from '../container';
import { HOUR_LABOUR_COST, CURRENCY } from '../../../../company-settings/container';
import { MenuItem } from 'shared/ui-component/Menu';
import { Colors } from 'shared/styles';

interface Props {
  trainingSessionActualGraphData: TrainingSessionActualData[];
  trainingSessionForecastGraphData: ForecastTrainingGraphData[];
  actualGraphLoader: boolean;
  forcastGraphLoader: boolean;
  noDataForForecastGraphData: boolean;
  noDataForActualGraphData: boolean;
  isTooMuchDataToLoadInForecastGraph: boolean;

  loadFullData: () => void;
}

enum TypeOfGraphData {
  count,
  hour,
}

enum xAxisKeys {
  month = 'month',
  total = 'total',
  cost = 'cost',
  hours = 'hours',
}

export enum yAxisKeys {
  plannedTrainingCount = 'plannedTrainingCount',
  plannedTrainingHours = 'plannedTrainingHours',
  inProgressCount = 'inProgressCount',
  inProgressHours = 'inProgressHours',
  lateCount = 'lateCount',
  lateHours = 'lateHours',
  toAcquireCount = 'toAcquireCount',
  toAcquireHours = 'toAcquireHours',
  toRenewCount = 'toRenewCount',
  toRenewHours = 'toRenewHours',
}

export const KPIGraphComponent: React.FC<Props> = props => {
  const {
    trainingSessionActualGraphData,
    trainingSessionForecastGraphData,
    noDataForForecastGraphData,
    noDataForActualGraphData,
    actualGraphLoader,
    forcastGraphLoader,
    isTooMuchDataToLoadInForecastGraph,

    loadFullData,
  } = props;

  const trainingSessionActualConfig: ChartConfig = {
    xAxisKey: xAxisKeys.month,
    xAxisKey1: xAxisKeys.total,
    xAxisKey2: xAxisKeys.cost,
    yAxisConfig: [
      {
        key: yAxisKeys.lateCount,
        label: t('alex:trainingSessions.trainingTypes.late', undefined, false),
        color: Colors.Red,
      },
      {
        key: yAxisKeys.inProgressCount,
        label: t('alex:trainingSessions.trainingTypes.inProgress', undefined, false),
        color: Colors.Orange,
      },
      {
        key: yAxisKeys.plannedTrainingCount,
        label: t('alex:KPI.KPIGraph.plannedTraining', undefined, false),
        color: Colors.Yellow,
      },
    ],
  };

  const trainingSessionForecastConfig: ChartConfig = {
    xAxisKey: xAxisKeys.month,
    xAxisKey1: xAxisKeys.total,
    xAxisKey2: xAxisKeys.cost,
    yAxisConfig: [
      {
        key: yAxisKeys.toRenewCount,
        label: t('alex:KPI.KPIGraph.trainingToRenew', undefined, false),
        color: Colors.MediumBlue,
      },
      {
        key: yAxisKeys.toAcquireCount,
        label: t('alex:KPI.KPIGraph.trainingToAcquire', undefined, false),
        color: Colors.DarkBlue,
      },
    ],
  };

  const [trainingSessionActualConfigByType, setTrainingSessionActualConfigByType] =
    useState<ChartConfig>(trainingSessionActualConfig);
  const [trainingSessionForecastConfigByType, setTrainingSessionForecastConfigByType] =
    useState<ChartConfig>(trainingSessionForecastConfig);
  const [formatXaxisWithHour, setFormatXaxisWithHour] = useState<boolean>(false);
  const [yAxisMaxLimit, setYAxisMaxLimit] = useState<number | string>(0);

  useEffect(() => {
    handleMaxLimit(xAxisKeys.total);
  }, [trainingSessionActualGraphData, trainingSessionForecastGraphData]);

  function handleMaxLimit(key: xAxisKeys) {
    let maxLimit = 0;

    if (key === xAxisKeys.total) {
      trainingSessionActualGraphData.map(_trainingSessionActualGraphData => {
        if (maxLimit < _trainingSessionActualGraphData.total) {
          maxLimit = _trainingSessionActualGraphData.total;
        }
      });

      trainingSessionForecastGraphData.map(_trainingSessionForecastGraphData => {
        if (maxLimit < _trainingSessionForecastGraphData.total) {
          maxLimit = _trainingSessionForecastGraphData.total;
        }
      });
    } else if (key === xAxisKeys.hours) {
      trainingSessionActualGraphData.map(_trainingSessionActualGraphData => {
        if (maxLimit < _trainingSessionActualGraphData.hours) {
          maxLimit = _trainingSessionActualGraphData.hours;
        }
      });

      trainingSessionForecastGraphData.map(_trainingSessionForecastGraphData => {
        if (maxLimit < _trainingSessionForecastGraphData.hours) {
          maxLimit = _trainingSessionForecastGraphData.hours;
        }
      });
    }

    setYAxisMaxLimit(maxLimit + (maxLimit * 10) / 100);
  }

  function handleForecastGraph(typeOfGraphData: TypeOfGraphData) {
    if (typeOfGraphData === TypeOfGraphData.hour) {
      setFormatXaxisWithHour(true);
      setTrainingSessionForecastConfigByType({
        ...trainingSessionForecastConfig,
        xAxisKey1: xAxisKeys.hours,
        yAxisConfig: [
          {
            ...trainingSessionForecastConfig.yAxisConfig[0],
            key: yAxisKeys.toRenewHours,
          },
          {
            ...trainingSessionForecastConfig.yAxisConfig[1],
            key: yAxisKeys.toAcquireHours,
          },
        ],
      });

      setTrainingSessionActualConfigByType({
        ...trainingSessionActualConfig,
        xAxisKey1: xAxisKeys.hours,
        yAxisConfig: [
          {
            ...trainingSessionActualConfig.yAxisConfig[0],
            key: yAxisKeys.lateHours,
          },

          {
            ...trainingSessionActualConfig.yAxisConfig[1],
            key: yAxisKeys.inProgressHours,
          },
          {
            ...trainingSessionActualConfig.yAxisConfig[2],
            key: yAxisKeys.plannedTrainingHours,
          },
        ],
      });
      handleMaxLimit(xAxisKeys.hours);
    } else {
      handleMaxLimit(xAxisKeys.total);

      setFormatXaxisWithHour(false);
      setTrainingSessionActualConfigByType(trainingSessionActualConfig);
      setTrainingSessionForecastConfigByType(trainingSessionForecastConfig);
    }
  }

  const menuItemsForForecastGraph: MenuItem[] = [
    {
      label: t('alex:KPI.KPIGraph.displayInTrainingsCount'),
      onPress: () => null,
    },
    {
      label: t('alex:KPI.KPIGraph.displayInTrainingHours'),
      onPress: () => null,
    },
  ];

  return (
    <View style={styles.rootContainer}>
      <StackedAreaGraph
        data={trainingSessionActualGraphData}
        title={t('alex:KPI.KPIGraph.plannedTrainings')}
        config={trainingSessionActualConfigByType}
        toolTipText={t('alex:KPI.KPIGraph.actualGraphInfo', {
          cost: HOUR_LABOUR_COST,
          currency: CURRENCY,
        })}
        containerStyle={styles.leftGraph}
        loader={actualGraphLoader}
        noData={noDataForActualGraphData}
        noDataLabel={t('alex:KPI.KPIGraph.noPlannedTraining')}
        yAxisMaxLimit={yAxisMaxLimit}
        formatXaxisWithHour={formatXaxisWithHour}
      />
      <StackedAreaGraph
        data={trainingSessionForecastGraphData}
        title={t('alex:KPI.KPIGraph.notPlannedTrainings')}
        config={trainingSessionForecastConfigByType}
        toolTipText={t('alex:KPI.KPIGraph.forecastGraphInfo', {
          cost: HOUR_LABOUR_COST,
          currency: CURRENCY,
        })}
        menuItems={menuItemsForForecastGraph}
        onRadioButtonChange={handleForecastGraph}
        loader={forcastGraphLoader}
        noData={noDataForForecastGraphData}
        noDataLabel={t('alex:KPI.KPIGraph.noUnplannedTraining')}
        yAxisMaxLimit={yAxisMaxLimit}
        formatXaxisWithHour={formatXaxisWithHour}
        isTooMuchDataToLoad={isTooMuchDataToLoadInForecastGraph}
        loadFullData={loadFullData}
      />
    </View>
  );
};
