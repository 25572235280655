import React, { useState, useRef, useEffect } from 'react';
import { View, ScrollView, StyleProp, TextStyle, ViewStyle, Platform } from 'react-native';
import styles from './styles';
import * as _ from 'lodash-es';
import { TabItem } from './TabItem';

export interface Tab {
  title: TabTitle;
}

export interface IndexedTab extends Tab {
  index: number;
}

interface TabTitle {
  title: string;
  warningIcon?: boolean;
  warningIconColor?: string;
  tabTotal?: number;
  disabled?: boolean;
}

interface Props {
  tabs: Tab[];
  currentTabIndex: number;
  tabTextStyle?: StyleProp<TextStyle>;
  isMobileStyle?: boolean;
  style?: StyleProp<ViewStyle>;
  innerContainerStyle?: StyleProp<ViewStyle>;

  onTabPress?: (index: number) => void;
}

export const AnimatedTab: React.FC<Props> = props => {
  const {
    tabs,
    currentTabIndex,
    tabTextStyle,
    isMobileStyle,
    style,
    innerContainerStyle,

    onTabPress,
  } = props;

  const [selectedTab, setSelectedTab] = useState<IndexedTab>();

  const scrollRef = useRef<ScrollView>(null);

  const isPlatformWebStyle = isMobileStyle ? false : Platform.OS === 'web';

  /**
   * In some places the tabs array changed for the same animated tab for example in the workstation screen or the skill add edit, and the currentTabIndex could be not handled correctly when switching to another length of tabs
   * so that's an extra check:
   * if we had 3 tabs x y z
   * and then we switched to 2 tabs a b
   * if the selected tab was z, and we did not reset the currentTabIndex (which will be 3 in this case ) from the parent component it will be pointing to non existing index
   * if the tabs did not change im okay if the currentTabIndex did not changed since it will still be in the same range
   */
  useEffect(() => {
    handleTabPress({ ...tabs[0], index: 0 });
  }, [tabs.length]);

  useEffect(() => {
    handleTabPress({ ...tabs[currentTabIndex], index: currentTabIndex });
  }, [currentTabIndex]);

  const handleTabPress = (item: IndexedTab, callOnTabPress?: boolean) => {
    
    if (item.index === selectedTab?.index) return;

    setSelectedTab(item);
    if (onTabPress && callOnTabPress) onTabPress(item.index);
  };

  return (
    <View style={[isPlatformWebStyle ? styles.container : styles.mobContainer, style]}>
      <ScrollView
        ref={scrollRef}
        style={[
          isPlatformWebStyle ? styles.tabsInnerContainer : styles.mobTabsInnerContainer,
          innerContainerStyle,
        ]}
        contentContainerStyle={[styles.tabsInnerContainerContentContainerStyle]}
        horizontal
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
      >
        <View style={isPlatformWebStyle ? styles.tabContainer : styles.tabContainerMobile}>
          {tabs.map((item, itemIndex) => {
            return (
              <TabItem
                key={itemIndex}
                item={item}
                itemIndex={itemIndex}
                selectedTabIndex={selectedTab?.index ?? 0}
                handleTabPress={handleTabPress}
                tabTextStyle={tabTextStyle}
                isMobileStyle={isMobileStyle}
              />
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};
