import React, { useState } from 'react';
import { View, Text, Image, Animated, Dimensions, ScrollView, Alert, Platform } from 'react-native';
import { styles } from './Styles';
import { YellowButton } from 'shared/ui-component/Button';
import { getLanguage, t } from 'shared/localisation/i18n';

const locale = getLanguage().locale;





const photos = {
  fr: [
    require('shared/assets/tutorial-images/fr_01.png'),
    require('shared/assets/tutorial-images/fr_02.png'),
    require('shared/assets/tutorial-images/fr_03.png'),
    require('shared/assets/tutorial-images/fr_04.png'),
  ],
  en: [
    require('shared/assets/tutorial-images/en_01.png'),
    require('shared/assets/tutorial-images/en_02.png'),
    require('shared/assets/tutorial-images/en_03.png'),
    require('shared/assets/tutorial-images/en_04.png'),
  ],
  de: [
    require('shared/assets/tutorial-images/de_01.png'),
    require('shared/assets/tutorial-images/de_02.png'),
    require('shared/assets/tutorial-images/de_03.png'),
    require('shared/assets/tutorial-images/de_04.png'),
  ],
  es: [
    require('shared/assets/tutorial-images/es_01.png'),
    require('shared/assets/tutorial-images/es_02.png'),
    require('shared/assets/tutorial-images/es_03.png'),
    require('shared/assets/tutorial-images/es_04.png'),
  ],
};

const { width } = Dimensions.get('window');
type Props = {
  handleIdentifyUserLayout: (emailAuthentication: boolean | undefined) => void;
};

export const IdentifyScreen: React.FC<Props> = props => {
  const [scrollPosition, setScrollPosition] = useState<Animated.AnimatedDivision>(
    Animated.divide(0, width),
  );

  const adTexts = t('alex:auth.advertisementText.mobile');

  const handleSocialLogin = (type: string) => {
    
    
    
    
    
    
    
    
    
    
    Alert.alert(t('common:error.comingSoon'));
  };

  const handleAuthenticationNavigation = (emailAuthentication: boolean | undefined) => {
    props.handleIdentifyUserLayout(emailAuthentication);
  };

  return (
    <View style={styles.rootContainer}>
      <ScrollView
        style={styles.scrollViewImageContainer}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        onScroll={event => {
          const xOffset = event.nativeEvent.contentOffset.x;
          setScrollPosition(Animated.divide(xOffset, width));
        }}
      >
        {photos[locale].map((photo, index) => {
          return (
            <View style={styles.scrollItemContainer} key={'view' + index}>
              <Text style={styles.headertextStyle}>{adTexts[index]}</Text>
              <View style={styles.imageStyle}>
                <Image source={photo} />
              </View>
            </View>
          );
        })}
      </ScrollView>
      <View style={styles.connectionContainer}>
        <View style={styles.scrollBar}>
          {photos[locale].map((photo, index) => {
            let opacity = scrollPosition.interpolate({
              inputRange: [index - 1, index, index + 1], 
              outputRange: [0.3, 1, 0.3], 
              extrapolate: 'clamp', 
            });
            return <Animated.View style={[styles.animatedView, { opacity }]} key={index} />;
          })}
        </View>
        <Text style={styles.connectionBartextStyle}>
          {Platform.OS === 'ios'
            ? t('common:auth.identification.connectionBarIOS')
            : t('common:auth.identification.connectionBar')}
        </Text>
        <View style={styles.connectionBarContainer}>
          {}
          <YellowButton
            style={styles.loginButton}
            onPress={() => handleAuthenticationNavigation(undefined)}
            text={t('common:auth.identification.buttonText')}
          />
        </View>
      </View>
    </View>
  );
};
