import { StyleSheet, Dimensions } from 'react-native';
import { Spacings, TextFontStyles, fontSize } from 'shared/styles';

export default StyleSheet.create({
  outerContainer: {
    paddingHorizontal: Spacings.CardPadding,
    paddingBottom: Spacings.Small,
    paddingTop: Spacings.Unit * 7,
    height: '100%',
  },
  skillsTableContainer: {
    borderRadius: 8,
    shadowColor: '#00000029',
    shadowOpacity: 1,
    shadowRadius: 2,
    height: '100%',
  },
  tableContainerStyle: { height: Dimensions.get('window').height - 170 },
  rowFonts: {
    fontSize: 14,
    color: '#666666',
  },
  headerStyle: {
    color: '#AFAFAF',
    fontSize: 13,
    fontWeight: 'bold',
  },
  width100: {
    width: '100%',
  },
  rowStyle: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  icon: {
    width: 18,
    height: 18,
  },
  container: {
    marginTop: 30,
  },
  editContainer: {
    minHeight: 579,
    padding: 24,
    width: '100%',
    marginBottom: 150,
  },
  showMoreRow: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    justifyContent: 'center',
  },
  showMore: {
    fontSize: 16,
    color: '#78dbd9',
    marginTop: 30,
    marginBottom: 10,
  },
  arrowImage: {
    width: 8,
    height: 14.133,
  },
  arrowImageContainer: {
    paddingRight: 10,
    paddingLeft: 5,
  },
  editIcon: {
    width: 16,
    height: 16,
    marginLeft: 10,
  },
  sortIcon: {
    width: 12,
    height: 12,
    marginLeft: 10,
  },
  activeRow: { backgroundColor: '#F1F3F4' },
  flatListContainer: {
    height: Dimensions.get('window').height - 163,
    width: '100%',
  },
  skillRow: {
    flexDirection: 'row',
    width: '100%',
    height: 68,
    borderTopColor: '#F5F5F5',
    borderTopWidth: 1,
    paddingLeft: 28,
  },
  skillTouchable: {
    width: '33.57%',
    justifyContent: 'center',
    paddingLeft: 10,
  },
  skillInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  skillIcon: { marginRight: 16 },
  skillTextStyle: {
    width: '80%',
    textAlign: 'left',
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: '#606060',
    justifyContent: 'center',
  },
  skillPstContainer: {
    width: '32.5%',
    justifyContent: 'center',
  },
  skillPstInnerContainer: {
    width: '100%',
    height: '35.3%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  skillCollabsContainer: {
    width: '34%',
    justifyContent: 'center',
  },
  skillCollabsInnerContainer: {
    width: '100%',
    height: '35.3%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  skillCollabsTextStyle: {
    width: '80%',
    textAlign: 'left',
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: '#606060',
    justifyContent: 'center',
  },
  tableNumberContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableText: {
    textAlign: 'left',
    fontSize: fontSize.Regular,
    color: '#606060',
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
  },
  tableContainer: {
    marginLeft: Spacings.CardPadding,
    padding: 0,
    flex: 1,
  },
  tableOuterContainer: { flex: 1 },
  columnContainer: {
    alignItems: 'flex-start',
  },
  ellipsisText: {
    width: '80%',
  },
});
