import React, { useState, useEffect, useContext, useRef } from 'react';
import { View, InteractionManager, ScrollView, ActivityIndicator } from 'react-native';
import { Card } from 'shared/ui-component/Card';
import { t } from 'shared/localisation/i18n';
import * as SharedStyles from 'shared/styles';
import { ModalBackgroundStyle, ModalCardStyle } from 'shared/styles/ModalStyles';
import WebModal from 'modal-react-native-web';
import * as API from 'shared/backend-data';
import { WorkerSkillAddModal } from 'skillmgtweb/src/components/add-workerskill-modal/container';
import { TableRow } from 'shared/ui-component/Table';
import * as _ from 'lodash-es';
import { Styles } from './Styles';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { Loader } from 'shared/ui-component/Modal/loader';
import { MyHub } from 'shared/util/MyHub';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { ModalUtils } from 'shared/ui-component/Modal';
import { ProofBookContainerWeb } from '../../../training/proof-book';
import { TrainWorkerModal } from '../../../training/train-worker-modal/container';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { TrainingTable } from './TrainingTable';
import { Header } from './Header';
import Aigle from 'aigle';

export const UnassociatedTrainingId = 'UNASSOCIATED';

export interface WorkerSkillInfoWithRequirementsRow extends TableRow {
  skill: API.Skill;
  stateString: string;
  workstationId: string;
  validity?: API.Validity | null | undefined;
  toReviewProofBundle?: API.NoMetadata<API.ProofBundle> | null | undefined;
  level: API.WorkstationWorkerLevels;
  proofBundle?: API.NoMetadata<API.ProofBundle> | null;
  requiredTrainingVersionId?: string | null;
  requirementId: string;
  acquired?: boolean | null;
  nonWorkstationTrainingSessions?: API.TrainingSession[];
}

export interface TrainingSessionWithSkillsRow extends TableRow {
  trainingName: string;
  trainingVersionId: string | undefined;
  workstationId: string;
  trainingSession: { trainingSessionState: API.TrainingSessionState; id: string } | undefined;
  skills: WorkerSkillChildRow[];
  isCollapsed: boolean;
  isTrainingCollapsed: boolean;
  worstSkillValidity?: WorkerSkillChildRow;
}

export interface WorkerSkillChildRow extends WorkerSkillInfoWithRequirementsRow {
  /**
   * Could be an active training session, a suggested training version, or the required skill is not associated with any training
   */
  parentTrainingKey: string;
  isCollapsed: boolean;
  workstationId: string;
}

interface Props {
  worker: API.Worker;
  workstation: API.Workstation;
  workerWorkstation: API.WorkerWorkstation;
  loading: boolean;
  showTrainingModal: boolean;
  showTargetAndMaintain: boolean;

  handleUnassignWorkerAndStopTrainings(): Promise<void>;
  handleMaintainSelect: (maintainLevel: API.WorkstationWorkerLevels) => void;
  handleModalClose: () => void;
  refetchData: () => Promise<void>;
  setShowTrainingModal(show: boolean): void;
}

export const validAndTotalWorkerSkills: Map<
  API.WorkstationWorkerLevels,
  { totalWorkerSkills: number; validWorkerSkills: number }
> = new Map();

export const SkillConformityModalComponent: React.FC<Props> = props => {
  const {
    worker,
    workstation,
    workerWorkstation,
    loading,
    showTrainingModal,
    showTargetAndMaintain,

    handleModalClose,
    refetchData,
    handleUnassignWorkerAndStopTrainings,
    handleMaintainSelect,
    setShowTrainingModal,
  } = props;

  const { levelIconType } = useContext(GlobalDataContext);

  const isMounted = useIsMounted();

  const [tabsMap, setTabsMap] = useState<Map<API.WorkstationWorkerLevels, Tab>>(
    new Map([
      [
        API.WorkstationWorkerLevels.LEVEL1,
        {
          title: {
            title: `${t(`glossary:level_${API.WorkstationWorkerLevels.LEVEL1}`)}`,
          },
        },
      ],
      [
        API.WorkstationWorkerLevels.LEVEL2,
        {
          title: {
            title: `${t(`glossary:level_${API.WorkstationWorkerLevels.LEVEL2}`)}`,
          },
        },
      ],
      [
        API.WorkstationWorkerLevels.LEVEL3,
        {
          title: {
            title: `${t(`glossary:level_${API.WorkstationWorkerLevels.LEVEL3}`)}`,
          },
        },
      ],
      [
        API.WorkstationWorkerLevels.LEVEL4,
        {
          title: {
            title: `${t(`glossary:level_${API.WorkstationWorkerLevels.LEVEL4}`)}`,
          },
        },
      ],
    ]),
  );
  const [showWorkerSkillReviewModal, setShowWorkerSkillReviewModal] = useState(false);
  const [showAddWorkerSkillModal, setShowAddWorkerSkillModal] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState<API.Skill>();
  const [proofBundle, setProofBundle] = useState<API.NoMetadata<API.ProofBundle> | null>();
  const [isDisplayLoader, setIsDisplayLoader] = useState(false);
  const [proofBookSkillIds, setProofBookSkillIds] = useState<string[]>();
  const [proofBookWorkerIds, setProofBookWorkerIds] = useState<string[]>();
  const [showProofBook, setShowProofBook] = useState(false);
  const [levelTabSelected, setLevelTabSelected] = useState<API.WorkstationWorkerLevels>(0);
  const [workstationSkillsRequiredInLevels, setWorkstationSkillsRequiredInLevels] =
    useState<API.WorkstationSkillsRequiredInLevels>();
  const [trainingLevel, setTrainingLevel] = useState<API.WorkstationWorkerLevels>();
  const [totalWorkerSkillPercentage, setTotalWorkerSkillPercentage] = useState<number>(0);
  const [allLevelTrainingSessionsEstimationDate, setAllLevelTrainingSessionsEstimationDate] =
    useState<string>();
  const [nonWorkstationTrainingSessionSkillIds, setNonWorkstationTrainingSessionSkillIds] =
    useState<Map<string, { trainingSession: API.TrainingSession; skillIds: string[] }>>(new Map());

  const modal = ModalUtils.useModal();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
        if (data.factory.dataType === API.DataType.WORKERSKILL) {
          if (data.tooManyMutations) {
            handleSkills(workerWorkstation);
          } else if (data.factory.workerSkill.workerId === workerWorkstation.workerId) {
            handleSkills(workerWorkstation);
          }
        }

        if (data.factory.dataType === API.DataType.REQUIREMENT) {
          if (data.tooManyMutations) {
            handleSkills(workerWorkstation);
          } else if (
            data.factory.requirement.linkedObjectId === workerWorkstation.workstationId ||
            workstation.pathIds.includes(data.factory.requirement.linkedObjectId)
          ) {
            handleSkills(workerWorkstation);
          }
        }

        if (data.factory.dataType === API.DataType.WORKERTRAININGSESSION) {
          if (data.tooManyMutations) {
            handleSkills(workerWorkstation);
          } else if (data.factory.workerTrainingSession.workerId === workerWorkstation.workerId) {
            handleSkills(workerWorkstation);
          }
        }
      });

      handleSkills(workerWorkstation);

      return () => {
        removeListener();
      };
    });
  }, [workerWorkstation]);

  useEffect(() => {
    const total = computeTotalWorkerSkillPercentage();
    if (total !== totalWorkerSkillPercentage) setTotalWorkerSkillPercentage(total);
  });

  function computeTotalWorkerSkillPercentage(): number {
    let valid = 0;
    let total = 0;

    const _maxLevel = API.isWorkerTargetingALevelOnWorkstation(workerWorkstation)
      ? API.api2workstationWorkerLevels(workerWorkstation.targetLevel!)
      : API.WorkstationWorkerLevels.LEVEL4;

    for (let _level = API.WorkstationWorkerLevels.LEVEL1; _level <= _maxLevel; _level++) {
      const validAndTotal = validAndTotalWorkerSkills.get(_level);
      if (validAndTotal) {
        valid += validAndTotal.validWorkerSkills;
        total += validAndTotal.totalWorkerSkills;
      }
    }

    return Math.ceil((valid / total) * 100);
  }

  async function handleSkills(workerWorkstation: API.WorkerWorkstation) {
    setIsDisplayLoader(true);

    
    const skillsAndWorkerSkills = await API.getSkillsAndWorkerSkillsRequiredForWorkstationLevels(
      workerWorkstation.workstationId,
      workerWorkstation.workerId,
      true,
      false,
    );
    if (!isMounted.current) return;
    if (API.isFailure(skillsAndWorkerSkills)) {
      logger.error('error while feching the skills and worker skills', skillsAndWorkerSkills);
      setIsDisplayLoader(false);
      return skillsAndWorkerSkills;
    }

    setWorkstationSkillsRequiredInLevels(skillsAndWorkerSkills);

    
    const trainingSessionLevelsEstimationDate =
      await API.getWorkerWorkstationActiveTrainingSessionsEstimatedEndDate(workerWorkstation);
    if (!isMounted.current) return;
    setAllLevelTrainingSessionsEstimationDate(trainingSessionLevelsEstimationDate);

    const _nonWorkstationTrainingSessionSkillIds = await getNonWorkstationTrainingSessionSkillIds(
      workerWorkstation,
    );
    if (!isMounted.current) return;
    setNonWorkstationTrainingSessionSkillIds(new Map(_nonWorkstationTrainingSessionSkillIds));

    setIsDisplayLoader(false);
  }

  function handleOpenProofBook(workerIds: string[], skillIds: string[]) {
    setProofBookWorkerIds(workerIds);
    setProofBookSkillIds(skillIds);

    setShowProofBook(true);
    setShowAddWorkerSkillModal(false);
  }

  async function handleSetTrainingLevel(
    trainingLevel: API.WorkstationWorkerLevels | undefined,
  ): Promise<void> {
    if (trainingLevel) {
      const r = await API.setTarget(
        workerWorkstation.workstationId,
        workerWorkstation.workerId,
        trainingLevel,
        false,
      );
      if (!isMounted.current) return;
      if (API.isFailure(r)) {
        ModalUtils.showWarningFailure(modal, r);
        return;
      }
    } else {
      setTrainingLevel(trainingLevel);
    }
    setTrainingLevel(trainingLevel);
  }

  function isShowLoader() {
    return isDisplayLoader;
  }

  return (
    <WebModal animationType="fade" transparent>
      {loading && <Loader />}
      <View style={ModalBackgroundStyle}>
        <Card style={[ModalCardStyle, Styles.modalCardStyle]}>
          {
            <Header
              workerWorkstation={workerWorkstation}
              worker={worker}
              workstation={workstation}
              loading={loading}
              levelIconType={levelIconType}
              totalWorkerSkillPercentage={totalWorkerSkillPercentage}
              allLevelTrainingSessionsEstimationDate={allLevelTrainingSessionsEstimationDate}
              workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
              setShowAddWorkerSkillModal={setShowAddWorkerSkillModal}
              handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
              handleModalClose={handleModalClose}
              handleSetTrainingLevel={handleSetTrainingLevel}
              handleMaintainSelect={handleMaintainSelect}
              setShowTrainingModal={setShowTrainingModal}
              showTargetAndMaintain={showTargetAndMaintain}
            />
          }
          <ScrollView stickyHeaderIndices={[0]}>
            <View
              style={[
                Styles.fullWidth,
                {
                  backgroundColor: SharedStyles.Colors.White,
                },
              ]}
            >
              <AnimatedTab
                currentTabIndex={0}
                tabs={Array.from(tabsMap.values())}
                innerContainerStyle={{ paddingTop: SharedStyles.Spacings.Standard }}
                onTabPress={index => {
                  setLevelTabSelected(index);
                }}
              />
            </View>
            {isShowLoader() && (
              <>
                <View style={Styles.loaderContainer}></View>
                <ActivityIndicator
                  style={Styles.loaderStyle}
                  color={SharedStyles.Colors.Yellow}
                  size="large"
                />
              </>
            )}

            <View
              style={{
                display:
                  levelTabSelected === API.WorkstationWorkerLevels.LEVEL1 - 1 ? 'flex' : 'none',
              }}
            >
              {workstationSkillsRequiredInLevels && (
                <TrainingTable
                  workerWorkstation={workerWorkstation}
                  workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
                  level={API.WorkstationWorkerLevels.LEVEL1}
                  worker={worker}
                  tabs={tabsMap}
                  nonWorkstationTrainingSessionSkillIds={nonWorkstationTrainingSessionSkillIds}
                  setTabsMap={setTabsMap}
                  handleSetTrainingLevel={handleSetTrainingLevel}
                  setShowTrainingModal={setShowTrainingModal}
                  handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
                  handleMaintainSelect={handleMaintainSelect}
                  showTargetAndMaintain={showTargetAndMaintain}
                />
              )}
            </View>
            <View
              style={{
                display:
                  levelTabSelected === API.WorkstationWorkerLevels.LEVEL2 - 1 ? 'flex' : 'none',
              }}
            >
              {workstationSkillsRequiredInLevels && (
                <TrainingTable
                  workerWorkstation={workerWorkstation}
                  workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
                  worker={worker}
                  tabs={tabsMap}
                  level={API.WorkstationWorkerLevels.LEVEL2}
                  nonWorkstationTrainingSessionSkillIds={nonWorkstationTrainingSessionSkillIds}
                  setTabsMap={setTabsMap}
                  handleSetTrainingLevel={handleSetTrainingLevel}
                  setShowTrainingModal={setShowTrainingModal}
                  handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
                  handleMaintainSelect={handleMaintainSelect}
                  showTargetAndMaintain={showTargetAndMaintain}
                />
              )}
            </View>

            <View
              style={{
                display:
                  levelTabSelected === API.WorkstationWorkerLevels.LEVEL3 - 1 ? 'flex' : 'none',
              }}
            >
              {workstationSkillsRequiredInLevels && (
                <TrainingTable
                  workerWorkstation={workerWorkstation}
                  workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
                  worker={worker}
                  tabs={tabsMap}
                  level={API.WorkstationWorkerLevels.LEVEL3}
                  nonWorkstationTrainingSessionSkillIds={nonWorkstationTrainingSessionSkillIds}
                  setTabsMap={setTabsMap}
                  handleSetTrainingLevel={handleSetTrainingLevel}
                  setShowTrainingModal={setShowTrainingModal}
                  handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
                  handleMaintainSelect={handleMaintainSelect}
                  showTargetAndMaintain={showTargetAndMaintain}
                />
              )}
            </View>
            <View
              style={{
                display:
                  levelTabSelected === API.WorkstationWorkerLevels.LEVEL4 - 1 ? 'flex' : 'none',
              }}
            >
              {workstationSkillsRequiredInLevels && (
                <TrainingTable
                  workerWorkstation={workerWorkstation}
                  workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
                  worker={worker}
                  tabs={tabsMap}
                  level={API.WorkstationWorkerLevels.LEVEL4}
                  nonWorkstationTrainingSessionSkillIds={nonWorkstationTrainingSessionSkillIds}
                  setTabsMap={setTabsMap}
                  handleSetTrainingLevel={handleSetTrainingLevel}
                  setShowTrainingModal={setShowTrainingModal}
                  handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
                  handleMaintainSelect={handleMaintainSelect}
                  showTargetAndMaintain={showTargetAndMaintain}
                />
              )}
            </View>
          </ScrollView>
        </Card>
      </View>
      {showWorkerSkillReviewModal && proofBundle && (
        <ProofBookContainerWeb
          proofBundleId={proofBundle.id}
          setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
        />
      )}
      {showAddWorkerSkillModal && (
        <WorkerSkillAddModal
          arrowBack
          selectedSkills={selectedSkill ? [selectedSkill] : []}
          selectedWorkers={[worker]}
          handleModalClose={() => {
            setShowAddWorkerSkillModal(false);
            setSelectedSkill(undefined);
          }}
          refreshSkills={async () => {
            refetchData();
          }}
          openProofBook={handleOpenProofBook}
        />
      )}
      {proofBookSkillIds && proofBookWorkerIds && showProofBook && (
        <ProofBookContainerWeb
          workerIds={proofBookWorkerIds}
          skillIds={proofBookSkillIds}
          setShowAddTrainingProofModal={setShowProofBook}
        />
      )}
      {showTrainingModal && (
        <TrainWorkerModal
          openModal={showTrainingModal}
          workerId={workerWorkstation.workerId}
          treeNodeId={workerWorkstation.workstationId}
          trainingLevel={trainingLevel}
          handleModalClose={() => {
            setShowTrainingModal(false);
          }}
          originatedFromDashboard
        />
      )}
    </WebModal>
  );
};

export async function getNonWorkstationTrainingSessionSkillIds(
  workerWorkstation: API.WorkerWorkstation,
): Promise<
  Map<
    string,
    {
      trainingSession: API.TrainingSession;
      skillIds: string[];
    }
  >
> {
  const _nonWorkstationTrainingSessionSkillIds = new Map<
    string,
    { trainingSession: API.TrainingSession; skillIds: string[] }
  >();
  await Aigle.mapSeries(
    [
      ...workerWorkstation.activeTrainingSessions.nonWorkstationActiveTrainingSessions
        .greaterThanTarget.fromInheritedRequirements,
      ...workerWorkstation.activeTrainingSessions.nonWorkstationActiveTrainingSessions
        .greaterThanTarget.fromNonInheritedRequirements,
      ...workerWorkstation.activeTrainingSessions.nonWorkstationActiveTrainingSessions
        .lowerOrEqualToTarget.fromInheritedRequirements,
      ...workerWorkstation.activeTrainingSessions.nonWorkstationActiveTrainingSessions
        .lowerOrEqualToTarget.fromNonInheritedRequirements,
    ],
    async trainingSessionId => {
      const trainingSession = await API.getTrainingSession(trainingSessionId);
      if (API.isFailure(trainingSession)) return trainingSession;

      const trainingVersionId = trainingSession.trainingVersionId;
      const trainingVersion = await API.getTrainingVersion(trainingVersionId);
      if (API.isFailure(trainingVersion)) return trainingVersion;

      const skillIds = [...trainingVersion.skillIds];
      _nonWorkstationTrainingSessionSkillIds.set(trainingSessionId, {
        skillIds: skillIds,
        trainingSession: trainingSession,
      });
    },
  );

  return _nonWorkstationTrainingSessionSkillIds;
}
