import React, { useRef, useState, useEffect, useContext } from 'react';
import { View, TouchableOpacity, Text, ScrollView, InteractionManager } from 'react-native';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { ChangePassword } from 'shared/layout/authentication/change-password';
import {
  t,
  languages,
  Locale,
  getLanguage,
  setLanguageAndLoadGlossary,
  Language,
} from 'shared/localisation/i18n';
import { ModalHeader } from 'shared/ui-component/Modal/Header';
import WebModal from 'modal-react-native-web';
import { ModalBackgroundStyle, ModalCardStyle } from 'shared/styles/ModalStyles';
import style from './styles';
import { InputText } from 'shared/ui-component/Input';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import { IconSVG } from '../../../ui-component/Icon';
import { Colors, Spacings } from '../../../styles';
import { TextButton, YellowButton } from 'shared/ui-component/Button';
import { ToggleSwitch } from 'shared/ui-component/Button/ToggleButton';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import logger from 'shared/util/Logger';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { RouteLocations } from 'skillmgtweb/src/components/navigation/Routes';
import { useHistory } from 'react-router-dom';
import { ToolTipWeb } from '../../../ui-component/ToolTip/ToolTipWeb';
import { DeleteAccount } from './DeleteAccount';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';

const InfoSVG = require('shared/assets/svg/icon.info.svg').default;

interface Props {
  showLoader: boolean;
  worker: API.Worker | undefined;
  admins?: API.Worker[];
  showChangePasswordModal: boolean;
  closeModal: () => void;
  handlePassowrdChangeModal: Function;
  submit: () => Promise<void>;
  handleInputs: (inputName: string, value: string) => void;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

enum MyAccountInputIds {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Email = 'Email',
  Phone = 'Phone',
  RegistrationNumber = 'RegistrationNumber',
  UserPassword = 'Password',
  UserId = 'UserId',
}

interface DropDownOptions {
  key: string;
  label: string;
  value?: string;
}

const dropdownOptions: DropDownOptions[] = _.map(languages, language => {
  return {
    key: language.locale,
    label: language.label,
    value: language.locale,
  };
});

export const MyAccountComponent: React.FC<Props> = props => {
  const {
    showLoader,
    worker,
    showChangePasswordModal,
    closeModal,
    handlePassowrdChangeModal,
    admins,
    submit,
  } = props;

  const PhotoIcon = require('shared/assets/svg/icon.camera.avatar.svg').default;
  const [emailNotificationEnabled, setEmailNotificationEnabled] = useState<boolean>(true);
  const [language, setLanguage] = useState<Language>(getLanguage());
  const currentLanguage: DropDownOption = {
    key: language.locale,
    label: language.label,
  };
  const history = useHistory();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const { isValidPermission } = useContext(PermissionManagementContext);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const _emailNotificationEnabled = await API.getUserPreference<boolean>(
        API.UserPreferenceKeys_Common.ReceiveEmailNotifications,
      );
      if (API.isFailure(_emailNotificationEnabled)) {
        logger.warn(_emailNotificationEnabled);
        return;
      }
      if (_emailNotificationEnabled != undefined)
        setEmailNotificationEnabled(_emailNotificationEnabled);
    });
  }, []);

  async function setUserPreference(value: boolean) {
    const result = await API.saveUserPreference<boolean>(
      API.UserPreferenceKeys_Common.ReceiveEmailNotifications,
      value,
    );
    if (API.isFailure(result)) {
      logger.warn(result);
      return;
    }

    setEmailNotificationEnabled(value);
  }

  const { errors, onBlur, onChange, onSubmit, refs } = useCustomForm<string>(
    {
      [MyAccountInputIds.FirstName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
        initialValue: worker ? worker.firstName : '',
      },
      [MyAccountInputIds.LastName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
        initialValue: worker ? worker.familyName : '',
      },
      [MyAccountInputIds.Email]: {
        validator: [InputValidationType.Email],
        isFieldMandatory: false,
      },
      [MyAccountInputIds.Phone]: {
        validator: [InputValidationType.PhoneNumber],
        isFieldMandatory: false,
      },
      [MyAccountInputIds.RegistrationNumber]: {
        validator: [InputValidationType.WorkerPersonalIdPattern],
        isFieldMandatory: false,
      },
    },
    submit,
  );

  function onMyProfilePress() {
    if (worker) history.push(RouteLocations.WorkerProfile(worker.id));
    closeModal();
  }

  const handleInputs = (inputId: string, inputValue: string) => {
    onChange(inputId, inputValue);
    props.handleInputs(inputId, inputValue);
  };

  return (
    <WebModal animationType="fade" transparent visible={true}>
      <View style={ModalBackgroundStyle}>
        <View style={[ModalCardStyle]}>
          <input
            ref={inputFileRef}
            hidden
            id="file-input"
            type="file"
            accept="image/*"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onFileChange(e)}
          />
          <ModalHeader handleModalClose={closeModal} title={t('common:account.myAccount')} />
          <ScrollView>
            <View style={style.textInputContainer}>
              <View style={style.photoContainer}>
                <TouchableOpacity style={style.photo} onPress={() => inputFileRef.current?.click()}>
                  {worker?.profilePicture ? (
                    renderImage(worker.profilePicture, ImageSizes.Large, worker.name)
                  ) : (
                    <IconSVG svgComponent={PhotoIcon} color={Colors.Grey} />
                  )}
                </TouchableOpacity>
              </View>
              <View style={style.textInputOuterContainer}>
                <View style={style.textRowContainer}>
                  <InputText
                    inputRef={refs[MyAccountInputIds.FirstName]}
                    containerStyle={style.leftInputText}
                    placeHolder={t('common:account.firstName')}
                    inputId={MyAccountInputIds.FirstName}
                    errorMessage={errors[MyAccountInputIds.FirstName]?.inputErrorMessage}
                    onBlur={onBlur}
                    defaultValue={worker && worker.firstName ? worker.firstName : ''}
                    onTextChange={(inputId: string, inputValue: string) =>
                      handleInputs(inputId, inputValue)
                    }
                  />
                  <InputText
                    inputRef={refs[MyAccountInputIds.LastName]}
                    containerStyle={style.inputText}
                    placeHolder={t('common:account.lastName')}
                    inputId={MyAccountInputIds.LastName}
                    onBlur={onBlur}
                    defaultValue={worker && worker.familyName ? worker.familyName : ''}
                    errorMessage={errors[MyAccountInputIds.LastName]?.inputErrorMessage}
                    onTextChange={(inputId: string, inputValue: string) =>
                      handleInputs(inputId, inputValue)
                    }
                  />
                </View>
                <View style={style.textRowContainer}>
                  <InputText
                    inputRef={refs[MyAccountInputIds.UserId]}
                    containerStyle={style.leftInputText}
                    placeHolder={t('common:account.identifier')}
                    defaultValue={worker && worker.email ? worker.email : ''}
                    inputId={MyAccountInputIds.UserId}
                    notEditable={true}
                  />

                  <View style={[style.inputText, { flexDirection: 'row' }]}>
                    <InputText
                      inputRef={refs[MyAccountInputIds.UserPassword]}
                      inputId={MyAccountInputIds.UserPassword}
                      placeHolder={t('common:account.password')}
                      defaultValue="......"
                      notEditable={true}
                      secureTextEntry
                    />
                    <TextButton
                      text={t('common:account.change')}
                      onPress={() => handlePassowrdChangeModal(true)}
                      containerStyle={{ marginTop: 40, marginLeft: Spacings.Large * 2 }}
                    />
                  </View>
                </View>
                <View style={style.textRowContainer}>
                  <InputText
                    inputRef={refs[MyAccountInputIds.Email]}
                    containerStyle={style.leftInputText}
                    placeHolder={t('common:account.email')}
                    defaultValue={worker && worker.email ? worker.email : ''}
                    inputId={MyAccountInputIds.Email}
                    notEditable={true}
                  />
                  <InputText
                    inputRef={refs[MyAccountInputIds.Phone]}
                    containerStyle={style.inputText}
                    placeHolder={t('common:account.phone')}
                    defaultValue={worker && worker.phone ? worker.phone : ''}
                    inputId={MyAccountInputIds.Phone}
                    onTextChange={(inputId: string, inputValue: string) =>
                      handleInputs(inputId, inputValue)
                    }
                    notEditable={!isValidPermission(API.Permission.workers_edit)}
                  />
                </View>

                <View style={style.textRowContainer}>
                  <InputText
                    inputRef={refs[MyAccountInputIds.RegistrationNumber]}
                    containerStyle={style.leftInputText}
                    placeHolder={t('common:account.workerPersonalId')}
                    defaultValue={worker && worker.matricule ? worker.matricule : ''}
                    inputId={MyAccountInputIds.RegistrationNumber}
                    onTextChange={(inputId: string, inputValue: string) =>
                      handleInputs(inputId, inputValue)
                    }
                    notEditable={!isValidPermission(API.Permission.workers_edit)}
                  />
                  <DropDownSingleSelection
                    containerStyle={[style.inputText, { zIndex: 2 }]}
                    placeholder={t('common:langAndGlossary.inputLanguage.placeholder')}
                    title={t('common:langAndGlossary.inputLanguage.placeholder')}
                    tagTitle={t('common:langAndGlossary.inputLanguage.placeholder')}
                    options={dropdownOptions}
                    value={currentLanguage}
                    handleChange={async (event, data) => {
                      const _language = _.find(
                        languages,
                        language => language.locale === data?.value,
                      );
                      if (_language) {
                        setLanguage(_language);

                        setLanguageAndLoadGlossary(_language);

                        const result = await API.saveUserPreference<Locale>(
                          API.UserPreferenceKeys_Common.UserLanguage,
                          _language.locale,
                        );
                        if (API.isFailure(result)) {
                          logger.warn('Error while saving user language Preference', result);
                        }
                      }
                    }}
                  />
                </View>
                <View style={style.lastContainor}>
                  <View style={style.leftInputText}>
                    <View style={style.adminContainer}>
                      <Text style={style.notificationText}>
                        {t('common:account.administrators')}
                      </Text>
                      <ToolTipWeb
                        style={style.adminsInfo}
                        component={
                          <IconSVG
                            svgComponent={InfoSVG}
                            size={{ width: 26, height: 26 }}
                            color={Colors.GreyLight}
                          />
                        }
                        title={t('common:account.administratorsDescription')}
                      />
                    </View>

                    <View>
                      {admins &&
                        admins.map(admin => {
                          return (
                            <View style={style.adminsProfile} key={admin.id}>
                              <View style={style.profileImageContainer}>
                                {renderImage(
                                  admin?.profilePicture,
                                  ImageSizes.Small,
                                  admin?.firstName,
                                )}
                              </View>

                              <Text style={style.userFirstNameTextStyle}>
                                {admin ? admin.name : ''}
                              </Text>
                            </View>
                          );
                        })}
                    </View>
                  </View>
                  <View style={style.inputText}>
                    <Text style={[style.notificationText, { marginBottom: 34 }]}>
                      {t('common:notification.notification')}
                    </Text>
                    <View style={style.enableNotifContainor}>
                      <Text style={style.enableNotificationText}>
                        {t('common:notification.enableNotificationEmail')}
                      </Text>
                      <ToggleSwitch
                        onValueChange={value => {
                          setUserPreference(value);
                        }}
                        value={emailNotificationEnabled}
                      />
                    </View>
                    {}
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
          <View style={style.buttons}>
            {isValidPermission(API.Permission.workerIsOperational) && (
              <TextButton
                text={t('common:menu.profile')}
                onPress={onMyProfilePress}
                containerStyle={style.textButtonContainer}
              />
            )}
            <DeleteAccount styles={style.textButtonContainer} />
            <YellowButton onPress={onSubmit} text={t('common:button.save')} isSmallButton />
          </View>
        </View>
        {showLoader && <Loader />}
        {showChangePasswordModal && (
          <ChangePassword
            handleModalClose={handlePassowrdChangeModal}
            username={worker ? worker.email : ''}
          />
        )}
      </View>
    </WebModal>
  );
};
