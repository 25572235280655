import { StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { Spacings, TextFontStyles, Colors, createRoundedStyle, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
    height: '100%',
  },
  treePanelOpacity: {
    opacity: 0.5,
  },
  mainCardContainer: {
    paddingHorizontal: 0,
    flexDirection: 'row',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
  },
  treeHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: Spacings.Medium,
    paddingRight: Spacings.Unit * 3,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Background,
    paddingBottom: Spacings.Big,
  },
  headerText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.Black,
    marginRight: Spacings.Small,
    marginVertical: 18,
  },
  greyBubbleView: {
    borderRadius: 10,
    minWidth: Spacings.Medium,
    height: Spacings.Medium,
    backgroundColor: Colors.GreyLight,
    marginBottom: -1,
    paddingHorizontal: Spacings.Unit,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countNumber: {
    ...TextFontStyles.LatoBlack,
    fontSize: 11,
    color: Colors.White,
  },
  orgUnitIconContainer: {
    marginRight: Spacings.Unit,
  },
  addIconContainer: {
    ...createRoundedStyle(24),
  },
  iconRoundedContatiner: {
    ...createRoundedStyle(24),
    backgroundColor: Colors.Background,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: Spacings.Small,
  },
  emptyTreeText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
    alignSelf: 'center',
    position: 'absolute',
    marginTop: '75%',
  },
  blockView: {
    position: 'absolute',
    height: '104%',
    width: '100%',
    marginTop: -Spacings.Small - Spacings.Unit,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  shiftContainer: {
    flexDirection: 'row',
  },
  nodeTextContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreButtonContainer: {
    width: Spacings.CardPadding,
    flexDirection: 'row-reverse',
  },
});

export const getNodeTextStyle = (level: number, selected: boolean): TextStyle => {
  if (level === 0) {
    return {
      ...TextFontStyles.LatoBlack,
      fontSize: fontSize.Regular,
    };
  } else {
    if (selected) {
      return {
        ...TextFontStyles.LatoBlack,
        fontSize: fontSize.Regular,
      };
    } else {
      return {
        ...TextFontStyles.LatoRegular,
        fontSize: fontSize.Regular,
      };
    }
  }
};

export const getNodeViewStyle = (selected: boolean, isOverNode: boolean): ViewStyle => ({
  flexDirection: 'row',
  paddingRight: Spacings.Small,
  height: Spacings.Large - 6,
  backgroundColor: selected ? Colors.BlueRollover : Colors.Transparent,
  alignItems: 'center',
  borderColor: Colors.Yellow,
  borderWidth: isOverNode ? 2 : 0,
  borderRadius: 3,
});

export const getGrabIconStyle = (
  enableDrag: boolean,
  nodeIsOu: boolean,
  level: number,
): React.CSSProperties => ({
  cursor: enableDrag ? 'grabbing' : 'grab',
  justifyContent: 'left',
  width:
    level < 2
      ? Spacings.Medium
      : nodeIsOu
      ? Spacings.Medium + (level - 1) * 20
      : Spacings.Medium + (level - 1) * 20 + Spacings.Small,
});

export const dropNodeStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  height: 3,
  marginTop: -3,
  zIndex: 9,
};

export default styles;
