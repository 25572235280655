import React from 'react';
import { View, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import { Spacings } from 'shared/styles';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import Style from './Style';
import { Tag } from '.';

const CloseIcon = require('shared/assets/svg/icon.close.svg').default;
const BookmarkIcon = require('shared/assets/svg/icon.star.svg').default;

interface Props {
  index: number;
  value: Tag;
  style?: StyleProp<ViewStyle>;

  handleTagDelete: () => void;
}

export const Atom: React.FC<Props> = props => {
  const { value, index, style, handleTagDelete } = props;

  return (
    <View
      style={[
        { zIndex: 100 - index },
        Style.tagView,
        style,
        value.isKeywordTag && Style.keywordTag,
        value.color ? { backgroundColor: value.color } : null,
      ]}
    >
      {value.isBookmarkTag && (
        <IconSVG
          svgComponent={BookmarkIcon}
          size={{ height: Spacings.Medium, width: Spacings.Medium }}
        />
      )}
      <EllipsisWithTooltip
        text={t(value.label)}
        textStyle={Style.tagText}
        iconType={value.type}
        toolTipContainerStyle={Style.toolTipContainer}
        tagPath={value.tagPath}
      />

      <TouchableOpacity onPress={handleTagDelete}>
        <IconSVG svgComponent={CloseIcon} />
      </TouchableOpacity>
    </View>
  );
};
