import React, { useState, useRef, useContext } from 'react';
import { View, Text, ScrollView, Animated, TouchableOpacity } from 'react-native';
import styles, { scrollViewWidth } from './styles';
import { Card } from 'shared/ui-component/Card';
import * as _ from 'lodash-es';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles/Colors';
import { t } from 'shared/localisation/i18n';
import { TextButton } from 'shared/ui-component/Button';
import { LevelIcon } from 'shared/ui-component/Icon/LevelIcon/LevelIcon';
import * as API from 'shared/backend-data';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { ActiveTrainingSessionsInput } from 'backend/src/api/api-auto';

const iconSize = { width: 28, height: 28 };

const pagesCount = 8;
interface Props {
  closeMenu: () => void;
}

export const WorkstationWorkerLevelInfoMenu: React.FC<Props> = props => {
  const myRef = useDetectOutsideClick<View>(props.closeMenu);

  const { levelIconType } = useContext(GlobalDataContext);

  const scrollRef = useRef<ScrollView>(null);

  const [scrollPosition, setScrollPosition] = useState<number>(0);

  let opacity = scrollPosition < 1 ? 0 : 1;

  const activeTrainingSessions: ActiveTrainingSessionsInput = {
    workstationActiveTrainingSessions: {
      lowerOrEqualToTarget: {
        fromNonInheritedRequirements: [],
        fromInheritedRequirements: [],
      },
      greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
    },
    nonWorkstationActiveTrainingSessions: {
      lowerOrEqualToTarget: {
        fromNonInheritedRequirements: [],
        fromInheritedRequirements: [],
      },
      greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
    },
  };

  const activeTrainingSessionsWithTraining: ActiveTrainingSessionsInput = {
    workstationActiveTrainingSessions: {
      lowerOrEqualToTarget: {
        fromNonInheritedRequirements: [],
        fromInheritedRequirements: [],
      },
      greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
    },
    nonWorkstationActiveTrainingSessions: {
      lowerOrEqualToTarget: {
        fromNonInheritedRequirements: [],
        fromInheritedRequirements: [],
      },
      greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
    },
  };

  const Screens = [
    () => {
      return (
        <View style={[styles.firstScreenInfoContainerView]}>
          <Text style={[styles.levelText, styles.centeredText]}>
            {t('alex:dashboardInfoMenu.workerIsNotAssignedOnTheWorkstation')}
          </Text>

          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL0,
                  targetLevel: undefined,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 0 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_0Description')}</Text>
          </View>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: undefined,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 1 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_1Description')}</Text>
          </View>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: undefined,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 2 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_2Description')}</Text>
          </View>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: undefined,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 3 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_3Description')}</Text>
          </View>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL4,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: undefined,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 4 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_4Description')}</Text>
          </View>
        </View>
      );
    },
    () => {
      return (
        <View style={[styles.firstScreenInfoContainerView]}>
          <Text style={[styles.levelText, styles.centeredText]}>
            {t('alex:dashboardInfoMenu.workerIsAssignedOnTheWorkstation')}
          </Text>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL0,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL1,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 0 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_0Description')}</Text>
          </View>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 1 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_1Description')}</Text>
          </View>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 2 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_2Description')}</Text>
          </View>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 3 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_3Description')}</Text>
          </View>
          <View style={styles.rowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <Text style={styles.levelText}>
              {t('alex:dashboardInfoMenu.workerLevel', { level: 4 })}{' '}
            </Text>
            <Text style={styles.levelTextDescription}>{t('glossary:level_4Description')}</Text>
          </View>
        </View>
      );
    },
    () => {
      return (
        <View style={styles.infoContainerView}>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL0,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL1,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>

            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL4,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <Text style={[styles.centreLevelTextDescription]}>
            {t('alex:dashboardInfoMenu.levelTrainDescription')}
          </Text>
        </View>
      );
    },
    () => {
      return (
        <View style={styles.infoContainerView}>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL0,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL1,
                  isTrainAuto: true,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  isTrainAuto: true,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  isTrainAuto: true,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: true,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelEdgeIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL4,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: true,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <Text style={[styles.centreLevelTextDescription]}>
            {t('alex:dashboardInfoMenu.levelLockDescription')}
          </Text>
        </View>
      );
    },
    () => {
      return (
        <View style={styles.infoContainerView}>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL0,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL1,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL4,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <Text style={styles.expireTextDescription}>
            {t('alex:dashboardInfoMenu.levelExpireSoonDescription')}
          </Text>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL0,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL1,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL4,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <Text style={styles.expireTextDescription}>
            {t('alex:dashboardInfoMenu.levelExpireSoonInTrainingDescription')}
          </Text>
        </View>
      );
    },
    () => {
      return (
        <View style={styles.infoContainerView}>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL0,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL1,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL4,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <Text style={styles.expireTextDescription}>
            {t('alex:dashboardInfoMenu.levelExpiredDescription')}
          </Text>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL4,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  isTrainAuto: false,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <Text style={styles.expireTextDescription}>
            {t('alex:dashboardInfoMenu.levelExpiredInTrainingDescription')}
          </Text>
        </View>
      );
    },
    () => {
      return (
        <View style={styles.infoContainerView}>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL1,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <Text style={styles.expireTextDescription}>
            {t('alex:dashboardInfoMenu.levelExpiredLostLevelDescription')}
          </Text>
        </View>
      );
    },
    () => {
      return (
        <View style={styles.infoContainerView}>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL1,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL1,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL0,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <View style={styles.centreRowView}>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL2,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL2,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL1,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL3,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL3,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL2,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
            <View style={styles.workerLevelCenterIconStyle}>
              <LevelIcon
                workerWorkstation={{
                  level: API.WorkstationWorkerLevel.LEVEL3,
                  previousLevel: API.WorkstationWorkerLevel.LEVEL4,
                  targetLevel: API.WorkstationWorkerLevel.LEVEL4,
                  warning: API.WorkstationWorkerLevelTargetWarning.EXPIRED,
                  isTrainAuto: false,
                  activeTrainingSessions: activeTrainingSessionsWithTraining,
                }}
                iconSize={iconSize}
                levelIconType={levelIconType}
              />
            </View>
          </View>
          <Text style={styles.expireTextDescription}>
            {t('alex:dashboardInfoMenu.levelExpiredLostLevelInTrainingDescription')}
          </Text>
        </View>
      );
    },
  ];

  return (
    <Card style={styles.CardContainer} ref={myRef}>
      <ScrollView
        ref={scrollRef}
        style={styles.scrollViewContainer}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        onScroll={event => {
          const xOffset = event.nativeEvent.contentOffset.x;
          if (scrollViewWidth) setScrollPosition(xOffset / scrollViewWidth);
        }}
      >
        {Screens.map((screen, index) => {
          return (
            <View key={index} style={styles.scrollViewItem}>
              {screen()}
            </View>
          );
        })}
      </ScrollView>
      <View style={{ flex: 1 }} />
      <View style={styles.scrollbarContainer}>
        <View style={{ opacity: opacity }}>
          <TextButton
            text={t('common:button.previous')}
            onPress={() => {
              if (scrollPosition > 0) {
                scrollRef.current?.scrollTo({
                  x: (scrollPosition - 1) * scrollViewWidth,
                });
              }
            }}
          />
        </View>
        <View style={styles.scrollBar}>
          {Screens.map((screen, index) => {
            let color = new Animated.Value(scrollPosition).interpolate({
              inputRange: [index - 1, index, index + 1], 
              outputRange: [Colors.GreyLight, Colors.Black, Colors.GreyLight],
              extrapolate: 'clamp', 
            });
            return (
              <TouchableOpacity
                key={index}
                onPress={() => {
                  scrollRef.current?.scrollTo({
                    x: scrollViewWidth ? scrollViewWidth * index : 0,
                  });
                }}
              >
                <Animated.View
                  style={[styles.animatedView, { backgroundColor: color }]}
                  key={index}
                />
              </TouchableOpacity>
            );
          })}
        </View>
        <TextButton
          text={t('common:button.next')}
          onPress={() => {
            if (scrollPosition < pagesCount) {
              scrollRef.current?.scrollTo({
                x: (scrollPosition + 1) * scrollViewWidth,
              });
            }
          }}
        />
      </View>
    </Card>
  );
};
