import React, { useState, useContext } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import * as _ from 'lodash-es';
import { HoverableIcon } from 'shared/ui-component/HoverableIcon/index';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors, Spacings } from 'shared/styles';
import { IconSVG } from 'shared/ui-component/Icon';
import * as API from 'shared/backend-data';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import { useEffect } from 'react';
import logger from 'shared/util/Logger';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from 'skillmgtweb/src/components/navigation/Routes';
import WebStyles from './Styles';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';

interface Props {
  id: string;
  disableRedirection?: boolean;
}

const openInNewIcon = require('shared/assets/svg/icon.openInNew.svg').default;

let _icon = require('shared/assets/svg/icon.workstation.svg').default;

export const DependencyDetails: React.FC<Props> = props => {
  const history = useHistory();

  const [isHover, setIsHover] = useState(false);
  const [onOpenInNew, setOnOpenInNew] = useState(() => () => {});
  const [name, setName] = useState<string>();

  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);

  useEffect(() => {
    if (!props.id) return;

    fetchDetails();
  }, [props.id]);

  const ref = useCallOnHover<TouchableOpacity>(
    Colors.Transparent,
    () => setIsHover(true),
    () => setIsHover(false),
  );

  function onWorkstationPress(id: string) {
    const _treeNode = API.Tree.getTreeNode(id);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }

    setTreeNode(_treeNode);
    history.push(RouteLocations.Workstations(id));
  }

  async function fetchDetails() {
    const _dataType = API.getDataType(props.id);
    if (API.isFailure(_dataType)) {
      logger.error(_dataType);
      return _dataType;
    }

    if (_dataType === API.DataType.ORGUNIT || _dataType === API.DataType.WORKSTATION) {
      _icon = require('shared/assets/svg/icon.workstation.svg').default;

      const orgUnitOrWorkstation = await API.getWorkstationOrOrganizationalUnit(props.id);
      if (API.isFailure(orgUnitOrWorkstation)) return orgUnitOrWorkstation;
      setOnOpenInNew(() => () => {
        onWorkstationPress(orgUnitOrWorkstation.id);
      });
      setName(orgUnitOrWorkstation.name);
    } else if (_dataType === API.DataType.SKILL) {
      _icon = require('shared/assets/svg/icon.skill.svg').default;

      const skill = await API.getSkill(props.id);
      if (API.isFailure(skill)) return skill;

      setOnOpenInNew(() => () => {
        history.push(RouteLocations.SkillProfile(skill.id));
      });
      setName(skill.name);
    } else if (_dataType === API.DataType.WORKER) {
      _icon = require('shared/assets/svg/icon.worker.svg').default;

      const worker = await API.getWorker(props.id);
      if (API.isFailure(worker)) return worker;

      setOnOpenInNew(() => () => {
        history.push(RouteLocations.WorkerProfile(worker.id));
      });
      setName(worker.name);
    } else if (_dataType === API.DataType.TRAININGSESSION) {
      _icon = require('shared/assets/svg/icon.training.svg').default;

      const trainingSession = await API.getTrainingSession(props.id);
      if (API.isFailure(trainingSession)) return trainingSession;

      const trainingName = await API.getTrainingNameForATrainingVersion(
        trainingSession.trainingVersionId,
      );

      setOnOpenInNew(() => () => {
        history.push(RouteLocations.TrainingSessions(props.id));
      });
      setName(trainingName);
    } else if (_dataType === API.DataType.TRAININGVERSION) {
      _icon = require('shared/assets/svg/icon.training.svg').default;

      const trainingVersion = await API.getTrainingVersion(props.id);
      if (API.isFailure(trainingVersion)) return trainingVersion;

      const trainingName = await API.getTrainingNameForATrainingVersion(trainingVersion.id);

      setOnOpenInNew(() => () => {
        history.push(RouteLocations.TrainingSessions());
      });
      setName(trainingName);
    } else if (_dataType === API.DataType.TRAINING) {
      _icon = require('shared/assets/svg/icon.training.svg').default;

      const training = await API.getTraining(props.id);
      if (API.isFailure(training)) return training;

      setOnOpenInNew(() => () => {
        history.push(RouteLocations.Trainings());
      });
      setName(training.name);
    } else if (_dataType === API.DataType.WORKERSKILL) {
      _icon = require('shared/assets/svg/icon.worker2.svg').default;

      const workerSkillFactory = await API.getFactoryBusinessObject(
        API.DataType.WORKERSKILL,
        props.id,
      );
      if (API.isFailure(workerSkillFactory)) return workerSkillFactory;

      const worker = await API.getWorker(workerSkillFactory.workerSkill.workerId);
      if (API.isFailure(worker)) return worker;

      setOnOpenInNew(() => () => {
        history.push(RouteLocations.WorkerProfile(worker.id, 1));
      });
      setName(worker.name);
    } else if (_dataType === API.DataType.REQUIREMENT) {
      _icon = require('shared/assets/svg/icon.workstation.svg').default;

      const requirement = await API.getRequirement(props.id);
      if (API.isFailure(requirement)) return requirement;

      const orgUnitOrWorkstation = await API.getWorkstationOrOrganizationalUnit(
        requirement.linkedObjectId,
      );
      if (API.isFailure(orgUnitOrWorkstation)) return orgUnitOrWorkstation;

      setOnOpenInNew(() => () => {
        onWorkstationPress(orgUnitOrWorkstation.id);
      });
      setName(orgUnitOrWorkstation.name);
    } else if (_dataType === API.DataType.SHIFT) {
      _icon = require('shared/assets/svg/icon.shift.svg').default;

      const shift = await API.getShift(props.id);
      if (API.isFailure(shift)) return shift;

      setOnOpenInNew(() => () => {
        history.push(RouteLocations.Workstations());
      });
      setName(shift.name);
    }
  }

  return (
    <>
      {name ? (
        <TouchableOpacity
          ref={ref}
          style={WebStyles.dependencyDetailContainer}
          onPress={onOpenInNew}
        >
          <View style={WebStyles.innerContainerDependencyDetails}>
            <IconSVG
              containerStyle={WebStyles.iconContainer}
              size={{ width: Spacings.Standard, height: Spacings.Standard }}
              svgComponent={_icon}
            />
            <Text style={WebStyles.textStyle}>{name}</Text>
          </View>
          {isHover && !props.disableRedirection && (
            <HoverableIcon
              containerStyle={WebStyles.hoverIconContainer}
              size={{ width: Spacings.Standard, height: Spacings.Standard }}
              svgIcon={openInNewIcon}
              onPress={onOpenInNew}
            />
          )}
        </TouchableOpacity>
      ) : (
        <LoaderThreeDots lowPerformance={true} />
      )}
    </>
  );
};
