import React, { useState, useEffect, useContext } from 'react';
import WebModal from 'modal-react-native-web';
import { View, InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { WorkstationModalProps } from '../container/WorkstationModal';
import Styles from './style';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { ModalHeader } from 'shared/ui-component/Modal/Header';
import { LeftPanel } from './LeftPanel';
import { RightPanel } from './RightPanel';
import { t, capitalizeFirstLetter } from 'shared/localisation/i18n';
import {
  ModalBackgroundStyle,
  ModalBodyWithAnchorStyle,
  ModalCardStyle,
  ModalFooterStyle,
} from 'shared/styles/ModalStyles';
import { TextButton, YellowButton } from 'shared/ui-component/Button';
import { ModalContext, ModalUtils } from 'shared/ui-component/Modal';
import { ImportExportFailureMessageKey } from 'shared/util/ExcelUtils';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../../../../navigation/Routes';
import {
  deleteWorkstation as _deleteWorkstation,
  deleteWorkstationWarning,
  duplicateWorkstation as _duplicateWorkstation,
  copyWorkstationOrOrganizationalUnitAndRequirements,
} from 'shared/util/WorkstationUi';

export enum DropdownType {
  IDEAL,
  MINIMUM,
}

export const WorkstationModalComponent: React.FC<WorkstationModalProps> = props => {
  const isMounted = useIsMounted();

  const {
    modalVisible,
    initialOrgUnitId,
    selectedWorkstation,
    handleModalClose,
    workstationTargetsStartingAtLevel,
  } = props;
  const modal = ModalUtils.useModal();
  const history = useHistory();

  const [workstationName, setWorkstationName] = useState<string>();
  const [workstationDescription, setWorkstationDescription] = useState<string | null>();
  const [workstationFiles, setWorkstationFiles] = useState<API.S3ObjectInput[]>([]);
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<
    DropDownOption<API.OrganizationalUnit> | undefined
  >();
  const [orgUnitOptions, setOrgUnitOptions] = useState<DropDownOption<API.OrganizationalUnit>[]>(
    [],
  );

  const { isValidPermission } = useContext(PermissionManagementContext);

  const [workersTargetOnShiftData, setWorkersTargetOnShiftData] =
    useState<API.WorkersTargetOnShift>();

  const [isDataValid, setIsDataValid] = useState<true | string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      fetchOrgUnitOptions();
    });
  }, []);

  useEffect(() => {
    if (!orgUnitOptions.length || !initialOrgUnitId) return;
    handleInitialSelectedOu();
  }, [initialOrgUnitId, orgUnitOptions]);

  useEffect(() => {
    checkDataValidity();
  }, [workstationName, selectedOrgUnit]);

  useEffect(() => {
    if (!orgUnitOptions.length) return;
    fillWorkstationDetails(selectedWorkstation);
    if (selectedWorkstation && selectedWorkstation.workersTargetOnShift) {
      
      if (typeof JSON.parse(selectedWorkstation.workersTargetOnShift) === 'string') {
        
        setWorkersTargetOnShiftData(
          JSON.parse(JSON.parse(selectedWorkstation.workersTargetOnShift)),
        );
      } else {
        setWorkersTargetOnShiftData(JSON.parse(selectedWorkstation.workersTargetOnShift));
      }
    }
  }, [selectedWorkstation, orgUnitOptions]);

  async function submit(): Promise<void> {
    if (isDataValid !== true) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: isDataValid,
          warningAcceptButton: capitalizeFirstLetter(t('common:button.gotIt')),
        }),
      );
      return;
    }

    setIsLoading(true);

    const workstationInput: API.WorkstationCreateInput = {
      name: workstationName!,
      parentId: selectedOrgUnit?.key!,
      description: workstationDescription,
      files: workstationFiles,
      workersTargetOnShift: workersTargetOnShiftData
        ? JSON.stringify(workersTargetOnShiftData)
        : undefined,
    };
    const workstation = selectedWorkstation
      ? await API.updateWorkstation({ ...workstationInput, id: selectedWorkstation.id })
      : await API.createWorkstation(workstationInput);

    if (!isMounted.current) return;
    if (API.isFailure(workstation)) {
      logger.warn('Failed to create Workstation');
    }

    await props.refreshWorkstations();
    if (!isMounted.current) return;

    clearWorkstationDetails();
    props.setSelectedWorkstation(undefined);
    setIsLoading(false);
    handleModalClose();
  }

  function fetchOrgUnitOptions(): void {
    const orgUnits = API.getOrganizationalUnits();

    setOrgUnitOptions(
      orgUnits.map(ou => {
        return { key: ou.id, label: ou.name, value: ou };
      }),
    );
  }

  function clearWorkstationDetails() {
    setWorkstationName('');
    setWorkstationDescription('');
  }

  function fillWorkstationDetails(selectedWorkstation: API.Workstation | undefined): void {
    if (selectedWorkstation) {
      setWorkstationName(selectedWorkstation.name);
      setWorkstationDescription(selectedWorkstation.description);
      setWorkstationFiles([...selectedWorkstation.files]);
    } else {
      clearWorkstationDetails();
    }
  }

  function checkDataValidity(): void {
    if (!workstationName) {
      setIsDataValid(
        String(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, {
            name: t('glossary:workstation'),
          }),
        ),
      );
      return;
    }
    if (!selectedOrgUnit) {
      setIsDataValid(
        String(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, {
            name: t('glossary:organizationalUnit'),
          }),
        ),
      );
      return;
    }

    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

    setIsDataValid(true);
  }

  function handleInitialSelectedOu(): void {
    setSelectedOrgUnit(
      _.find(orgUnitOptions, option => {
        return option.key === initialOrgUnitId;
      }),
    );
  }

  function onWorkstationFilesChange(s3Files: API.S3ObjectInput[]) {
    setWorkstationFiles([...s3Files]);
  }

  async function deleteWorkstation(workstationId: string, modal: ModalContext) {
    setIsLoading(true);

    const deleteWorkstation = await _deleteWorkstation(workstationId, modal, undefined, () =>
      history.push(RouteLocations.Workstations()),
    );
    if (!isMounted.current) return;
    if (API.isFailure(deleteWorkstation)) {
      logger.warn(deleteWorkstation);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  }

  async function handleDelete() {
    if (selectedWorkstation) {
      deleteWorkstationWarning(
        modal,
        () => deleteWorkstation(selectedWorkstation.id, modal),
        selectedWorkstation.name,
      );
    }
  }

  async function duplicateWorkstation() {
    if (selectedWorkstation) {
      setIsLoading(true);
      const newWorkstation = await copyWorkstationOrOrganizationalUnitAndRequirements(
        modal,
        selectedWorkstation,
      );

      if (!isMounted.current) return;
      if (API.isFailure(newWorkstation)) {
        logger.warn(newWorkstation);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    }
  }

  return (
    <WebModal
      animationType="fade"
      transparent
      visible={modalVisible}
      onRequestClose={() => handleModalClose()}
    >
      {isLoading && <Loader />}
      <View style={ModalBackgroundStyle}>
        <View style={[ModalCardStyle, { width: 908 }]}>
          <ModalHeader
            handleModalClose={handleModalClose}
            title={t('glossary:workstationDefault')}
          />
          <View style={[ModalBodyWithAnchorStyle, Styles.workstationModalPanelContainer]}>
            <LeftPanel
              workstationDescription={workstationDescription}
              workstationName={workstationName}
              orgUnitOptions={orgUnitOptions}
              selectedOrgUnit={selectedOrgUnit}
              workstationFiles={workstationFiles}
              onWorkstationFilesChange={onWorkstationFilesChange}
              setSelectedOrgUnit={setSelectedOrgUnit}
              setWorkstationName={setWorkstationName}
              setWorkstationDescription={setWorkstationDescription}
              initialOrgUnitId={initialOrgUnitId}
              onSubmitForm={submit}
            />

            <RightPanel
              orgUnitOptions={orgUnitOptions}
              workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
              workstation={selectedWorkstation}
              setWorkersTargetOnShiftData={setWorkersTargetOnShiftData}
              workersTargetOnShiftData={workersTargetOnShiftData}
            />
          </View>
          <View style={[ModalFooterStyle, Styles.footerContainer]}>
            {isValidPermission(API.Permission.workstations_edit) && (
              <View style={Styles.buttonContainer}>
                <View style={Styles.workstationModalYellowButtonContainer}>
                  <TextButton onPress={handleDelete} text={t('common:button.delete')} />
                </View>

                <View style={Styles.workstationModalYellowButtonContainer}>
                  <TextButton
                    onPress={() => _duplicateWorkstation(modal, duplicateWorkstation)}
                    text={t('alex:workstationModal.duplicateWorkstation')}
                  />
                </View>

                <YellowButton
                  textStyle={Styles.saveText}
                  style={Styles.saveButton}
                  onPress={submit}
                  text={t('common:button.save')}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    </WebModal>
  );
};
