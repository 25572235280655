import { StyleSheet } from 'react-native';
import { Spacings, TextFontStyles, Colors, fontSize } from 'shared/styles/index';

export const MobileStyles = StyleSheet.create({
  levelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Spacings.Small,
  },
  levelIcon: {
    paddingRight: Spacings.Unit,
  },
  subText: {
    ...TextFontStyles.LatoRegular,
    marginBottom: -5,
  },
  level: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Medium,
    marginBottom: -5,
  },
  disabledText: {
    color: Colors.Grey,
  },
  placeholder: {
    ...TextFontStyles.LatoRegular,
    fontSize: 16,
  },
  disabled: {
    color: Colors.Grey,
  },
  regularTextStyle: { ...TextFontStyles.LatoRegular, fontSize: fontSize.Regular },
});
