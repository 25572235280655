import React, { useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { SkillWorkstationTableComponent } from '../component';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import { TableRow } from 'shared/ui-component/Table';

interface Props {
  skill: API.Skill;
  setWorkstationCount: (count: number) => void;
}

export interface SkillWorkstationTableRow extends TableRow {
  workstation: API.Workstation;
  orgUnit: API.OrganizationalUnit;
  workstationRequirmentLevel: API.WorkstationWorkerLevels;
}

export const SkillWorkstationTableContainer: React.FC<Props> = props => {
  const { skill, setWorkstationCount } = props;

  const [rows, setRows] = useState<SkillWorkstationTableRow[]>([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      const workstations = await API.getSkillWorkstations(skill.id);
      if (!isMounted.current) return;
      if (API.isFailure(workstations)) {
        logger.warn(workstations);
        return;
      }

      const tableRows: SkillWorkstationTableRow[] = [];
      for (const workstation of workstations) {
        const orgUnit = API.getOrganizationalUnit(workstation.parentId);
        if (!isMounted.current) return;
        if (API.isFailure(orgUnit)) {
          logger.warn(orgUnit);
          return;
        }

        tableRows.push({
          key: workstation.id,
          workstation: workstation,
          orgUnit: orgUnit,
          workstationRequirmentLevel: workstation.level,
        });
      }
      if (!isMounted.current) return;
      setRows(tableRows);
      setWorkstationCount(tableRows.length);
    });
  }, []);

  return <SkillWorkstationTableComponent rows={rows} />;
};
