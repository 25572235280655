import React, { useEffect, useRef, useState } from 'react';
import { LevelProgressAndTrainingStatus } from './LevelProgressAndTrainingStatus';
import { Styles } from './Styles';
import * as API from 'shared/backend-data';
import {
  TrainingSessionWithSkillsRow,
  UnassociatedTrainingId,
  WorkerSkillChildRow,
  WorkerSkillInfoWithRequirementsRow,
  validAndTotalWorkerSkills,
} from './index';
import { t } from 'shared/localisation/i18n';
import { InteractionManager } from 'react-native';
import * as SharedStyles from 'shared/styles';
import _ from 'lodash';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import Aigle from 'aigle';
import { Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { WorkerSkillConformityTable } from './WorkerSkillConformityTable';

interface Props {
  workerWorkstation: API.WorkerWorkstation;
  workstationSkillsRequiredInLevels: API.WorkstationSkillsRequiredInLevels;
  level: API.WorkstationWorkerLevels;
  worker: API.Worker;
  tabs: Map<API.WorkstationWorkerLevels, Tab>;
  nonWorkstationTrainingSessionSkillIds: Map<
    string,
    { trainingSession: API.TrainingSession; skillIds: string[] }
  >;
  showTargetAndMaintain: boolean;

  setTabsMap: React.Dispatch<React.SetStateAction<Map<API.WorkstationWorkerLevels, Tab>>>;
  handleSetTrainingLevel: (trainingLevel: API.WorkstationWorkerLevels | undefined) => Promise<void>;
  setShowTrainingModal: (show: boolean) => void;
  handleUnassignWorkerAndStopTrainings: () => Promise<void>;
  handleMaintainSelect: (maintainLevel: API.WorkstationWorkerLevels) => void;
}

export const TrainingTable: React.FC<Props> = props => {
  const {
    workerWorkstation,
    workstationSkillsRequiredInLevels,
    level,
    worker,
    tabs,
    nonWorkstationTrainingSessionSkillIds,
    showTargetAndMaintain,

    setTabsMap,
    handleSetTrainingLevel,
    setShowTrainingModal,
    handleUnassignWorkerAndStopTrainings,
    handleMaintainSelect,
  } = props;

  const [levelWorkerSkillPercentage, setLevelWorkerSkillPercentage] = useState<number>(0);
  const [levelTrainingSessionsEstimationDate, setLevelTrainingSessionsEstimationDate] =
    useState<string>();
  const [levelSkillValidity, setLevelSkillValidity] =
    useState<WorkerSkillInfoWithRequirementsRow>();
  const [trainingSessionsForLevelSkills, setTrainingSessionsForLevelSkills] =
    useState<(TrainingSessionWithSkillsRow | WorkerSkillChildRow)[]>();

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;

      handleSkills(workerWorkstation);
    });
  }, [workerWorkstation, workstationSkillsRequiredInLevels, nonWorkstationTrainingSessionSkillIds]);

  async function handleSkills(workerWorkstation: API.WorkerWorkstation): Promise<API.Result<void>> {
    const levelSkillRows: WorkerSkillInfoWithRequirementsRow[] = [];

    let totalLevelWorkerSkill = 0;
    let validLevelWorkerSkill = 0;

    const _levelSkillRows = await getTrainingTableWorkerSkillComplementaryDetails(
      level,
      workstationSkillsRequiredInLevels,
      nonWorkstationTrainingSessionSkillIds,
    );
    if (!isMounted.current) return;
    levelSkillRows.push(..._levelSkillRows);

    _levelSkillRows.forEach(_levelSkillRow => {
      totalLevelWorkerSkill++;
      if (
        _levelSkillRow.validity === API.Validity.OK ||
        _levelSkillRow.validity === API.Validity.OK_EXPIRE_SOON
      ) {
        validLevelWorkerSkill++;
      }
    });

    setLevelWorkerSkillPercentage(Math.ceil((validLevelWorkerSkill / totalLevelWorkerSkill) * 100));
    validAndTotalWorkerSkills.set(level, {
      totalWorkerSkills: totalLevelWorkerSkill,
      validWorkerSkills: validLevelWorkerSkill,
    });

    
    getLevelSkillValidityAndAlert(levelSkillRows);

    const _activeTrainingSessions =
      workerWorkstation.activeTrainingSessions.workstationActiveTrainingSessions;
    const _activeTrainingSessionIds = [
      ..._activeTrainingSessions.lowerOrEqualToTarget.fromInheritedRequirements,
      ..._activeTrainingSessions.lowerOrEqualToTarget.fromNonInheritedRequirements,
      ..._activeTrainingSessions.greaterThanTarget.fromNonInheritedRequirements,
      ..._activeTrainingSessions.greaterThanTarget.fromInheritedRequirements,
    ];

    const activeTrainingSessions: API.TrainingSession[] = [];
    await Aigle.mapSeries(_activeTrainingSessionIds, async activeTrainingSessionId => {
      const trainingSession = await API.getTrainingSession(activeTrainingSessionId);
      if (!isMounted.current) return;
      if (API.isFailure(trainingSession)) {
        logger.warn(trainingSession);
        return;
      }
      activeTrainingSessions.push(trainingSession);
    });

    const _trainingSessionsForLevelSkills =
      await mapSkillsToTrainingSessionsOrTrainingVersionsOnTrainingTable(
        levelSkillRows,
        activeTrainingSessions,
      );

    const __trainingSessionsForLevelSkills: (TrainingSessionWithSkillsRow | WorkerSkillChildRow)[] =
      [];
    Array.from(_trainingSessionsForLevelSkills).map(([, value]) => {
      __trainingSessionsForLevelSkills.push({
        ...value,
        worstSkillValidity: getTrainingSkillsStateStringForTrainingTable(value.skills),
      });
      value.skills.forEach(skill => __trainingSessionsForLevelSkills.push(skill));
    });
    setTrainingSessionsForLevelSkills(__trainingSessionsForLevelSkills);

    const trainingSessionLevelEstimationDate = await getTrainingSessionEstimationEndDate(
      __trainingSessionsForLevelSkills,
    );
    if (!isMounted.current) return;
    setLevelTrainingSessionsEstimationDate(trainingSessionLevelEstimationDate);
  }

  function getAlertForTabs(
    level: API.WorkstationWorkerLevels,
    workerSkill?: WorkerSkillInfoWithRequirementsRow,
  ) {
    if (!workerSkill) return;

    if (workerSkill?.validity !== API.Validity.OK) {
      const tab = tabs.get(level);
      if (tab) {
        const updatedMap = tabs.set(level, {
          ...tab,
          title: {
            ...tab.title,
            warningIcon: true,
            warningIconColor:
              workerSkill.validity === API.Validity.OK_EXPIRE_SOON
                ? SharedStyles.Colors.Orange
                : SharedStyles.Colors.Red,
          },
        });
        setTabsMap(new Map(updatedMap));
      }
    }
  }

  function getLevelSkillValidityAndAlert(rows: WorkerSkillInfoWithRequirementsRow[]) {
    const orderedLevelSkillsByValidity = API.orderByWorkerSkillValidity(
      rows,
      API.SortDirection.asc,
    );
    if (orderedLevelSkillsByValidity) {
      setLevelSkillValidity(orderedLevelSkillsByValidity[0]);

      getAlertForTabs(level, orderedLevelSkillsByValidity[0]);
    }
  }

  async function getTrainingSessionEstimationEndDate(
    trainingSessionsForLevelSkills: (TrainingSessionWithSkillsRow | WorkerSkillChildRow)[],
  ): Promise<string | undefined> {
    const trainingSessionIds = getTrainingSessionIds(trainingSessionsForLevelSkills);
    const estimationDate = await API.getTrainingSessionsEstimatedEndDate(trainingSessionIds);
    if (!isMounted.current) return;
    if (API.isFailure(estimationDate)) {
      logger.warn(estimationDate);
      return;
    }

    return estimationDate;
  }

  function getTrainingSessionIds(
    skillRows: (TrainingSessionWithSkillsRow | WorkerSkillChildRow)[],
  ): string[] {
    const trainingSessionIds: string[] = [];

    skillRows.forEach(row => {
      if (API.getDataType(row.key) === API.DataType.TRAININGSESSION) {
        trainingSessionIds.push(row.key);
      }
    });

    return trainingSessionIds;
  }

  return (
    <>
      <LevelProgressAndTrainingStatus
        style={Styles.progressCircleEachLevelContainer}
        workerWorkstation={workerWorkstation}
        workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
        percentage={levelWorkerSkillPercentage}
        estimatedTime={levelTrainingSessionsEstimationDate}
        skillValidity={levelSkillValidity}
        maintainInfoModalCoordinates={{ x: -50, y: -100 }}
        setTrainingLevel={handleSetTrainingLevel}
        setShowTrainingModal={setShowTrainingModal}
        handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
        handleMaintainSelect={handleMaintainSelect}
        showTargetAndMaintain={showTargetAndMaintain}
      />
      {trainingSessionsForLevelSkills && (
        <WorkerSkillConformityTable
          worker={worker}
          trainingSessionsWithSkillsRow={trainingSessionsForLevelSkills}
          setTrainingSessionsForLevelSkills={setTrainingSessionsForLevelSkills}
        />
      )}
    </>
  );
};

export async function getTrainingTableWorkerSkillComplementaryDetails(
  level: API.WorkstationWorkerLevels,
  skillsAndWorkerSkills: API.WorkstationSkillsRequiredInLevels,
  nonWorkstationTrainingSessionSkillIds: Map<
    string,
    {
      trainingSession: API.TrainingSession;
      skillIds: string[];
    }
  >,
): Promise<WorkerSkillInfoWithRequirementsRow[]> {
  const _rows: WorkerSkillInfoWithRequirementsRow[] = [];

  await Aigle.map(
    skillsAndWorkerSkills.skillsRequiredInLevels.get(level) ?? [],
    async eachSkill => {
      let _row: WorkerSkillInfoWithRequirementsRow;
      if (!eachSkill.workerSkill) {
        _row = {
          key: eachSkill.requiredSkill.id,
          stateString: t('alex:workerSkill.proofMissing'),
          skill: eachSkill.requiredSkill,
          validity: API.Validity.KO_MISSING,
          level: level,
          requiredTrainingVersionId: eachSkill.requiredTrainingVersionId,
          requirementId: eachSkill.requirementId,
          workstationId: eachSkill.workstationId,
        };
      } else {
        const _workerSkillComp = await API.getWorkerSkillComplementaryDetails(
          eachSkill.workerSkill,
        );
        if (API.isFailure(_workerSkillComp)) {
          logger.warn('Failed to fetch skill,', _workerSkillComp);
          return;
        }

        _row = {
          key: _workerSkillComp.skill.id,
          skill: _workerSkillComp.skill,
          validity: _workerSkillComp.validity,
          stateString: _workerSkillComp.stateString,
          toReviewProofBundle: API.deepClone(_workerSkillComp.toReviewProofBundle),
          level: level,
          proofBundle: _workerSkillComp.toReviewProofBundle ?? _workerSkillComp.activeProofBundle,
          requiredTrainingVersionId: eachSkill.requiredTrainingVersionId,
          requirementId: eachSkill.requirementId,
          acquired: _workerSkillComp.activeProofBundle?.acquired,
          workstationId: eachSkill.workstationId,
        };
      }

      if (!_row.acquired) {
        const _activeTrainingSessionsCoveringTheSkill: API.TrainingSession[] = [];
        Array.from(nonWorkstationTrainingSessionSkillIds.values()).forEach(
          ({ trainingSession, skillIds }) => {
            if (skillIds.includes(eachSkill.requiredSkill.id))
              _activeTrainingSessionsCoveringTheSkill.push(trainingSession);
          },
        );
        _row.nonWorkstationTrainingSessions = _activeTrainingSessionsCoveringTheSkill;
      }

      _rows.push(_row);
    },
  );

  return _rows;
}

export async function mapSkillsToTrainingSessionsOrTrainingVersionsOnTrainingTable(
  skillsRows: WorkerSkillInfoWithRequirementsRow[],
  activeTrainingSessions: API.TrainingSession[],
): Promise<Map<string, TrainingSessionWithSkillsRow>> {
  const trainingsOfTargetedNonInheritedSkillsRows: Map<string, TrainingSessionWithSkillsRow> =
    new Map();

  await Aigle.mapSeries(skillsRows, async skill => {
    /**
     * A flag to show OR to hide the skill in Unassociated training section OR in Suggested trainings section
     */
    let isAtLeastOneTrainingSessionDeliveringSkill = false;

    
    await Aigle.mapSeries(activeTrainingSessions, async trainingSession => {
      if (skill.requiredTrainingVersionId !== trainingSession.trainingVersionId) return;

      const trainingVersion = await API.getTrainingVersion(trainingSession.trainingVersionId);
      if (API.isFailure(trainingVersion)) return trainingVersion;

      const isTrainingDeliverSkill = trainingVersion.skillIds.includes(skill.skill.id);
      if (!isTrainingDeliverSkill) return;

      isAtLeastOneTrainingSessionDeliveringSkill = true;
      const _trainingSessionSkillsRows = trainingsOfTargetedNonInheritedSkillsRows.get(
        trainingSession.id,
      );
      if (_trainingSessionSkillsRows) {
        _trainingSessionSkillsRows.skills.push({
          ...skill,
          parentTrainingKey: trainingSession.id,
          isCollapsed: true,
          key: skill.skill.id + trainingSession.id,
        });
      } else {
        const training = await API.getTraining(trainingVersion.trainingId);
        if (API.isFailure(training)) return training;

        trainingsOfTargetedNonInheritedSkillsRows.set(trainingSession.id, {
          key: trainingSession.id,
          trainingName: training.name,
          trainingVersionId: trainingVersion.id,
          trainingSession: {
            trainingSessionState: API.getTrainingSessionState(trainingSession),
            id: trainingSession.id,
          },
          skills: [
            {
              ...skill,
              parentTrainingKey: trainingSession.id,
              isCollapsed: true,
              key: skill.skill.id + trainingSession.id,
            },
          ],
          isCollapsed: false,
          isTrainingCollapsed: true,
          workstationId: skill.workstationId,
        });
      }
    });

    
    if (!isAtLeastOneTrainingSessionDeliveringSkill) {
      if (skill.requiredTrainingVersionId) {
        
        const _trainingSessionSkillsRows = trainingsOfTargetedNonInheritedSkillsRows.get(
          skill.requiredTrainingVersionId,
        );
        if (_trainingSessionSkillsRows) {
          _trainingSessionSkillsRows.skills.push({
            ...skill,
            parentTrainingKey: skill.requiredTrainingVersionId,
            isCollapsed: true,
            key: skill.skill.id + skill.requiredTrainingVersionId,
          });
        } else {
          const trainingVersion = await API.getTrainingVersion(skill.requiredTrainingVersionId);
          if (API.isFailure(trainingVersion)) return trainingVersion;

          const training = await API.getTraining(trainingVersion.trainingId);
          if (API.isFailure(training)) return training;

          trainingsOfTargetedNonInheritedSkillsRows.set(skill.requiredTrainingVersionId, {
            key: skill.requiredTrainingVersionId,
            trainingName: training.name,
            trainingVersionId: skill.requiredTrainingVersionId,
            trainingSession: undefined,
            skills: [
              {
                ...skill,
                parentTrainingKey: skill.requiredTrainingVersionId,
                isCollapsed: true,
                requirementId: skill.requirementId,
                key: skill.skill.id + skill.requiredTrainingVersionId,
              },
            ],
            isCollapsed: false,
            isTrainingCollapsed: true,
            workstationId: skill.workstationId,
          });
        }
      } else {
        
        const _unassociatedTrainingSkillsRows =
          trainingsOfTargetedNonInheritedSkillsRows.get(UnassociatedTrainingId);
        if (_unassociatedTrainingSkillsRows) {
          _unassociatedTrainingSkillsRows.skills.push({
            ...skill,
            parentTrainingKey: UnassociatedTrainingId,
            isCollapsed: true,
          });
        } else {
          trainingsOfTargetedNonInheritedSkillsRows.set(UnassociatedTrainingId, {
            key: UnassociatedTrainingId,
            trainingName: t('alex:skillConformityModal.headers.3'),
            trainingVersionId: undefined,
            trainingSession: undefined,
            skills: [{ ...skill, parentTrainingKey: UnassociatedTrainingId, isCollapsed: true }],
            isCollapsed: false,
            isTrainingCollapsed: true,
            workstationId: skill.workstationId,
          });
        }
      }
    }
  });

  return trainingsOfTargetedNonInheritedSkillsRows;
}

export function getTrainingSkillsStateStringForTrainingTable(
  skills: WorkerSkillChildRow[],
): WorkerSkillChildRow | undefined {
  let worstSkillValidity: WorkerSkillChildRow | undefined;

  skills.forEach(skill => {
    if (!skill.validity) return;
    if (!worstSkillValidity) worstSkillValidity = skill;

    if (API.compareWorkerSkillValidity(worstSkillValidity, skill) < 0) worstSkillValidity = skill;
  });

  return worstSkillValidity;
}
