import React, { useState, useEffect } from 'react';
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  Platform,
  StyleProp,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { HoverableSaveButton } from 'shared/ui-component/Button/HoverableSaveButton';
import * as _ from 'lodash-es';
import Style from './Style';
import { SvgProps } from 'react-native-svg';
import { Spacings, Colors } from 'shared/styles';
import CloseIcon from 'shared/assets/svg/icon.close.svg';
import BookmarkIcon from 'shared/assets/svg/icon.star.svg';
import { HoverableIcon } from '../../../HoverableIcon';
import { t } from 'shared/localisation/i18n';
import { TreeDataType } from 'shared/backend-data/factoryCache/Tree';
import * as API from 'shared/backend-data';
import { Atom } from './atom';

export interface Tag<T = any> {
  key: string;
  label: string;
  editable?: boolean;
  disabled?: boolean;
  tagIcon?: React.FC<SvgProps>;
  isBookmarkTag?: boolean;
  isKeywordTag?: boolean;
  value?: T;
  children?: Tag<T>[];
  tagPath?: [TreeDataType | API.DataType.SHIFT, string[]];
  type?: string;
  isExtraSelector?: boolean;
  extraSelectorRelatedItem?: Tag;
  color?: string;
  order?: number;
}

interface Props {
  placeholder?: string;
  selectedTags?: Tag[];
  autoFocus?: boolean;
  additionFormStyling?: boolean;
  inputRef?: React.RefObject<TextInput>;
  style?: StyleProp<ViewStyle>;
  tagStyle?: StyleProp<ViewStyle>;
  inputTextStyle?: StyleProp<ViewStyle>;
  textInputValue?: string;
  saveOnDeleteTag?: boolean;
  showSaveButton?: boolean;

  onChange: (tags: Tag[], removedTag?: Tag) => void;
  onTyping?: ((input: string) => void) | undefined;
  onKeyPress?: ((event: NativeSyntheticEvent<TextInputKeyPressEventData>) => void) | undefined;
  onSave: (tags: Tag[], keepMenuOpen?: boolean) => void;
  onRemoveTag?: ((tag: Tag) => void) | undefined;
  singleSelection?: boolean | undefined;
  
  bookmark?:
    | {
        showBookmark: boolean;
        onSaveBookmark: (tags: Tag[]) => void;
      }
    | undefined;
  onPressEnter?: () => void;
}

export const InputTag: React.FC<Props> = props => {
  const { bookmark, autoFocus, singleSelection, showSaveButton = false, onPressEnter } = props;

  const [tagValues, setTagValues] = useState<Map<string, Tag>>(new Map());

  const inputTextRef = props.inputRef ?? React.createRef<TextInput>();

  useEffect(() => {
    if (props.selectedTags) {
      populateValues(props.selectedTags);
    }
  }, [props.selectedTags]);

  useEffect(() => {
    if (autoFocus && inputTextRef.current) {
      inputTextRef.current.focus();
    }
  }, [autoFocus]);

  const populateValues = (tags: Tag[]) => {
    tagValues.clear();
    tags.forEach((tag: Tag) => {
      tagValues.set(tag.key, tag);
    });

    setTagValues(API.deepClone(tagValues));
  };

  const handleTagInput = (event?: any) => {
    if (event && props.onKeyPress) props.onKeyPress(event);
  };

  const handleTagDelete = (key: string) => {
    if (props.onRemoveTag) props.onRemoveTag(tagValues.get(key)!);
    const removedTag = tagValues.get(key);
    tagValues.delete(key);
    props.onChange(Array.from(tagValues.values()), removedTag);
    const _tagValues = API.deepClone(tagValues);
    setTagValues(_tagValues);

    if (props.saveOnDeleteTag) onSavePress(Array.from(_tagValues.values()), true);
  };

  const onSavePress = (tags: Tag[], keepMenuOpen?: boolean) => {
    props.onSave(tags, keepMenuOpen ? true : undefined);
  };

  function renderTags() {
    if (singleSelection) return null;

    const orderedMap = new Map(
      [...tagValues].sort(([, value], [, value1]) => {
        if (value.order !== undefined && value1.order !== undefined) {
          return value.order - value1.order;
        }

        return 0;
      }),
    );

    return Array.from(orderedMap).map(([key, value], index) => {
      return (
        <Atom
          key={key}
          value={value}
          index={index}
          handleTagDelete={() => handleTagDelete(key)}
          style={props.tagStyle}
        />
      );
    });
  }

  function onSubmitEditing() {
    if (onPressEnter) {
      onPressEnter();
    }
    onSavePress(Array.from(tagValues.values()));
  }

  return (
    <>
      <View style={[Style.maincontainer, props.style]}>
        <View style={Style.container}>
          {renderTags()}
          <TextInput
            ref={inputTextRef}
            style={[
              Style.inputText,
              Platform.OS === 'web' ? { outline: 'none' } : {},
              tagValues.size
                ? { marginLeft: Spacings.xMedium }
                : { marginLeft: Spacings.CardPadding },
              props.inputTextStyle,
              props.additionFormStyling && {
                paddingTop: Spacings.Small,
                paddingBottom: Spacings.Small,
              },
            ]}
            onKeyPress={handleTagInput}
            onChangeText={props.onTyping}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            numberOfLines={1}
            ellipsizeMode={'tail'}
            value={props.textInputValue}
            onSubmitEditing={onSubmitEditing}
          />
        </View>
        {bookmark && bookmark.showBookmark && (
          <HoverableIcon
            containerStyle={Style.hoverIconContainer}
            size={Style.hoverIcon}
            svgIcon={BookmarkIcon}
            hoverColor={Colors.Yellow}
            dehoverColor={Colors.Grey}
            onPress={() => bookmark.onSaveBookmark(Array.from(tagValues.values()))}
          />
        )}
        {(!singleSelection || showSaveButton) && (
          <View style={Style.submitButtonContainer}>
            <HoverableSaveButton onPress={() => onSavePress(Array.from(tagValues.values()))} />
          </View>
        )}
      </View>
    </>
  );
};
