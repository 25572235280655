import React, { useState, useRef, useEffect } from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Spacings, Colors } from 'shared/styles';
import { t } from 'shared/localisation/i18n';
import * as _ from 'lodash-es';
import { FileRow } from './FileRow';
import * as API from 'shared/backend-data';
import { Styles } from './Styles';
import { FullScreenProof } from 'sharedweb/src/FileViewer/FullScreenProof';
import { getFileTypeFromDataURL } from 'sharedweb/src/FileViewer';
import { ModalUtils, useModal } from '../Modal';
import Aigle from 'aigle';
import { useIsMounted } from '../../hooks/IsMounted';

interface Props {
  initialFiles?: readonly API.S3ObjectInput[] | undefined | null;
  /** Called when the list of files has changed (Addition or deletion) */
  onFilesChange?: (files: API.S3ObjectInput[]) => void;
  style?: ViewStyle;
  /** enable to add / remove files */
  addFile?: boolean;
  /** Used to remove the title 'attachements' next to the (add) attachement icon */
  addFileButtonOnlyWithIcon?: boolean;
  /** Files will be represented only with their icon */
  hideFileName?: boolean;
  isProof?: boolean;
  createAutoCertificate?: () => void;
  createOrUpdateFileProof?: (files: FileList) => void;

  autoCertificateFile?: File;
  filesHeight?: number;
  itemIndex?: number;
  showFiles?: boolean;
  filesStyle?: ViewStyle;
  disableFilePreview?: boolean;
}

const AttachIcon = require('shared/assets/svg/icon.attachment.svg').default;
const ProofIcon = require('shared/assets/svg/icon.proofTraining.svg').default;
const FileIcon = require('shared/assets/svg/icon.file.svg').default;

export const AttachFile: React.FC<Props> = props => {
  const {
    style,
    addFile = true,
    initialFiles,
    disableFilePreview = false,
    isProof,
    addFileButtonOnlyWithIcon,
    autoCertificateFile,
    showFiles = true,
    hideFileName,
    filesStyle = Styles.filesStyle,
  } = props;

  const inputFileRef = useRef<HTMLInputElement>(null);
  const modal = useModal();
  const isMounted = useIsMounted();

  const [color, setColor] = useState(Colors.Grey);
  const [containerColor, setContainerColor] = useState(Colors.Transparent);
  const [s3Files, setS3Files] = useState<API.S3ObjectInput[]>();
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [newFilesUploadInProgress, setNewFilesUploadInProgress] = useState(false);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [selectedFileURI, setSelectedFileURI] = useState<string>();

  useEffect(() => {
    if (initialFiles) {
      setS3Files([...initialFiles]);
    }
  }, [initialFiles]);

  useEffect(() => {
    if (autoCertificateFile) {
      const _files = [...(newFiles ?? [])];
      _files.push(autoCertificateFile);
      setNewFiles(_files);
    }
  }, [autoCertificateFile]);

  useEffect(() => {
    if (newFiles.length) {
      uploadFiles(newFiles);
    }
  }, [newFiles]);

  useEffect(() => {
    
    
    
    if (s3Files && !API.areSameS3Objects(s3Files, initialFiles ?? [])) {
      if (props.onFilesChange) props.onFilesChange(s3Files);
    }
  }, [s3Files]);

  async function uploadFiles(newFiles: File[]) {
    setNewFilesUploadInProgress(true);

    
    
    const s3FileObjects: API.S3Object[] = [];
    await Aigle.mapLimit(newFiles, 10, async file => {
      const uploadedFile = await API.uploadFile(file, API.StorageVisibility.public);
      if (!isMounted.current) return;
      if (API.isFailure(uploadedFile)) {
        API.logger.warn(uploadedFile);
        return;
      }
      s3FileObjects.push(uploadedFile);
    });

    setNewFilesUploadInProgress(false);

    const _newfiles = newFiles.filter(
      file => !s3FileObjects.find(s3File => API.isSameFile(file, s3File)),
    );
    setNewFiles(_newfiles);
    setS3Files([...(s3Files ?? []), ...s3FileObjects]);
  }

  const ref = useCallOnHover<TouchableOpacity>(
    Colors.Transparent,
    () => {
      setColor(Colors.MediumGray);
      setContainerColor(Colors.GreyLight);
    },
    () => {
      setColor(Colors.Grey);
      setContainerColor(Colors.Transparent);
    },
  );

  return (
    <View style={[Styles.container, style]}>
      <input
        ref={inputFileRef}
        hidden
        id="file-input"
        type="file"
        multiple
        onChange={e => {
          if (!e.target.files) return;
          const files = e.target.files;
          if (props.createOrUpdateFileProof) props.createOrUpdateFileProof(files);

          setNewFiles(prev => [...(prev ?? []), ...files]);
        }}
      />
      {addFile ? (
        <>
          {showButtons && (
            <View>
              <TouchableOpacity
                ref={ref}
                style={[
                  Styles.buttonsContainer,
                  {
                    bottom: 50,
                  },
                ]}
                onPress={() => {
                  inputFileRef.current?.click();

                  setShowButtons(false);
                }}
              >
                <IconSVG
                  svgComponent={FileIcon}
                  size={{
                    width: Spacings.Standard,
                    height: Spacings.Standard,
                  }}
                  color={Colors.White}
                  containerStyle={Styles.iconContainerStyle}
                />
              </TouchableOpacity>

              <TouchableOpacity
                ref={ref}
                style={[
                  Styles.buttonsContainer,
                  {
                    bottom: Spacings.Small,
                  },
                ]}
                onPress={() => {
                  if (props.createAutoCertificate) props.createAutoCertificate();
                  setShowButtons(false);
                }}
              >
                <IconSVG
                  svgComponent={ProofIcon}
                  size={{
                    width: Spacings.Standard,
                    height: Spacings.Standard,
                  }}
                  color={Colors.White}
                  containerStyle={[Styles.iconContainerStyle, { backgroundColor: Colors.Yellow }]}
                />
              </TouchableOpacity>
            </View>
          )}
          <TouchableOpacity
            ref={ref}
            style={[Styles.touchableContainer]}
            onPress={() => {
              isProof ? setShowButtons(!showButtons) : inputFileRef.current?.click();
            }}
          >
            <IconSVG
              svgComponent={AttachIcon}
              size={{ width: Spacings.Standard, height: Spacings.Standard }}
              color={Colors.Grey}
              containerStyle={[
                isProof ? Styles.containerStyle : Styles.iconStyle,
                {
                  backgroundColor: isProof ? containerColor : Colors.Transparent,
                },
              ]}
            />
            {!addFileButtonOnlyWithIcon && (
              <Text style={[Styles.titleTextStyle, { color: color }]}>
                {t('common:button.addAnAttachment')}
              </Text>
            )}
          </TouchableOpacity>
        </>
      ) : (
        <Text style={Styles.disabledTextStyle}>
          {t('common:attachment.attachment', { count: s3Files?.length })}
        </Text>
      )}
      <View style={{ height: addFile ? Spacings.Medium : Spacings.Unit }} />
      {showFiles && (
        <View style={Styles.filesContainer}>
          {s3Files &&
            s3Files.map((existingFile, index) => {
              return (
                <FileRow
                  key={existingFile.key}
                  file={existingFile}
                  index={index}
                  onDelete={index => {
                    const s3FileToDelete = s3Files[index];

                    modal.displayModal(
                      ModalUtils.warningConfig({
                        warningMessage: `${t('common:button.delete')} ${s3FileToDelete.fileName}?`,
                        warningAcceptCallback: async () => {
                          const _existingFiles = [...s3Files];
                          _existingFiles.splice(index, 1);
                          setS3Files(_existingFiles);
                        },
                        warningAcceptButton: t('common:button.yes'),
                        warningCancelButton: t('common:button.no'),
                      }),
                    );
                  }}
                  onPreviewFile={disableFilePreview ? undefined : setSelectedFileURI}
                  hideFileName={isProof || hideFileName}
                  containerStyle={filesStyle}
                  editDisabled={!addFile}
                />
              );
            })}
          {newFiles.map((file, index) => {
            return (
              <FileRow
                key={index}
                file={file}
                index={index}
                onDelete={index => {
                  const _newFiles = [...newFiles];
                  _newFiles.splice(index, 1);
                  setNewFiles(_newFiles);
                }}
                onPreviewFile={disableFilePreview ? undefined : setSelectedFileURI}
                hideFileName={isProof || hideFileName}
                editDisabled={!addFile}
                containerStyle={filesStyle}
                uploadingInProgress={newFilesUploadInProgress}
              />
            );
          })}
        </View>
      )}
      {selectedFileURI && (
        <FullScreenProof
          filePath={selectedFileURI}
          fileType={getFileTypeFromDataURL(selectedFileURI)}
          setShowModal={(show: boolean) => {
            setSelectedFileURI(show ? selectedFileURI : undefined);
          }}
        />
      )}
    </View>
  );
};
