import React, { useState, useContext, useEffect } from 'react';
import { View, InteractionManager } from 'react-native';
import Styles from './Style';
import * as API from 'shared/backend-data';
import { SkillDropDownOption, WorkerDropDownOption } from '../container';
import { InputText } from 'shared/ui-component/Input';
import { YellowButton } from 'shared/ui-component/Button';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import { Colors } from 'shared/styles/Colors';
import { DropDownMultiSelection } from 'shared/ui-component/DropDown/DropDown';
import { t } from 'shared/localisation/i18n';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { ValidityDisplay } from 'shared/ui-component/validity-display/ValidityDisplay';
import { Spacings } from 'shared/styles';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import * as _ from 'lodash-es';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import { AcquiredAndNotAcquiredButtons } from 'shared/layout/summaryBook/AcquiredAndNotAcquiredButtons/AcquiredAndNotAcquiredButtons';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';

interface RightPanelProps {
  validData: boolean;
  comment: string | undefined;
  skillOptions: SkillDropDownOption[] | undefined;
  workerOptions: WorkerDropDownOption[] | undefined;
  selectedSkills?: API.Skill[] | undefined;
  selectedWorkers?: API.Worker[] | undefined;
  preSelectedSkills?: API.Skill[] | undefined;
  preSelectedWorkers?: API.Worker[] | undefined;
  skillObtentionDate: Date | undefined;
  revokeProof?: boolean;
  proofAdded?: boolean;
  isSkillsOrWorkersCountGreaterThanOne: boolean;
  isSkillAcquired: number;

  navigateToSkillBook: () => void;
  listenDropdownChange: (dropdownValueCount?: number) => void;
  setSkillObtentionDate: React.Dispatch<React.SetStateAction<Date>>;
  setComment: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedSkills: React.Dispatch<React.SetStateAction<API.Skill[] | undefined>>;
  setSelectedWorkers: React.Dispatch<React.SetStateAction<API.Worker[] | undefined>>;
  handleSubmitButton: () => void;
  setIsSkillAcquired: React.Dispatch<React.SetStateAction<number>>;
}

const addProofIcon = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const workerIcon = require('shared/assets/svg/icon.worker.svg').default;

enum InputIds {
  WorkerName = 'WorkerName',
  SkillName = 'SkillName',
  Comments = 'Comments',
}

export const RightPanel: React.FC<RightPanelProps> = props => {
  const isMounted = useIsMounted();

  const {
    skillOptions,
    workerOptions,
    selectedSkills,
    selectedWorkers,
    validData,
    skillObtentionDate,
    comment,
    proofAdded,
    isSkillsOrWorkersCountGreaterThanOne,
    isSkillAcquired,

    setIsSkillAcquired,
    navigateToSkillBook,
    listenDropdownChange,
    setSkillObtentionDate,
    setComment,
    setSelectedSkills,
    setSelectedWorkers,
    handleSubmitButton,
  } = props;

  const { errors, onBlur, onChange, refs } = useCustomForm<string>(
    {
      [InputIds.WorkerName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
      [InputIds.SkillName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
      [InputIds.Comments]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
    },
    onSubmitForm,
  );

  const { isValidPermission } = useContext(PermissionManagementContext);

  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [isEnableGenerateProofBook, setIsEnableGenerateProofBook] = useState(false);

  useEffect(() => {
    if (props.revokeProof) {
      setIsSkillAcquired(1);
    }
  }, [props.revokeProof]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await enableGenerateSkillBook();
    });
  }, [selectedSkills, selectedWorkers]);

  const handleShowWebCalendar = (show: boolean) => {
    if (isMounted.current) setShowSubmitButton(!show);
  };

  function onSubmitForm() {
    handleSubmitButton();
  }

  function isShowSkillValidity() {
    return !selectedSkills || selectedSkills?.length === 1;
  }

  async function handleSkillChange(inputId: string, _skills?: SkillDropDownOption[]) {
    const __skills = _.map(_skills, skill => skill.value);

    setSelectedSkills(__skills);

    const _skillIdsAndSkillGroupIds = await API.getSkillsAndSubSkillsIds(__skills);
    if (API.isFailure(_skillIdsAndSkillGroupIds)) return _skillIdsAndSkillGroupIds;

    const skillsCount: number = _skills
      ? _skillIdsAndSkillGroupIds.skillGroupIds.size + _skillIdsAndSkillGroupIds.skillIds.size
      : 0;

    listenDropdownChange(_.max([selectedWorkers?.length ?? 0, skillsCount ?? 0]));
  }

  function handleWorkerDropdown(inputId: string, _workers?: WorkerDropDownOption[]) {
    setSelectedWorkers(_.map(_workers, worker => worker.value));
    listenDropdownChange(_.max([selectedSkills?.length ?? 0, _workers?.length ?? 0]));
  }

  async function enableGenerateSkillBook(): Promise<API.Result<void>> {
    const _skillIdsAndSkillGroupIds = await API.getSkillsAndSubSkillsIds(selectedSkills ?? []);
    if (API.isFailure(_skillIdsAndSkillGroupIds)) return _skillIdsAndSkillGroupIds;

    const skillsAndSubSkillsCount =
      _skillIdsAndSkillGroupIds.skillGroupIds.size + _skillIdsAndSkillGroupIds.skillIds.size;

    setIsEnableGenerateProofBook(
      !!(
        skillsAndSubSkillsCount &&
        selectedWorkers?.length &&
        (skillsAndSubSkillsCount > 1 || selectedWorkers?.length > 1)
      ),
    );
  }

  function handleRadioButtons(item: string, index: number): void {
    setIsSkillAcquired(index);
  }

  const renderRightPanel = () => {
    return (
      <View style={[Styles.rightPanelContainer]}>
        <View style={[Styles.rightPanelInputsContainer]}>
          <DropDownMultiSelection
            inputId={InputIds.SkillName}
            placeholder={t('alex:workerSkillReviewModal.chooseSkill')}
            searchPlaceholder={t('alex:workerSkillReviewModal.chooseSkill')}
            title={t('glossary:skill')}
            containerStyle={Styles.skillDropDown}
            inputListContainerStyle={Styles.fullWidth}
            options={skillOptions ?? []}
            notEditable={!skillOptions || props.revokeProof}
            listIcon={addProofIcon}
            values={
              selectedSkills
                ? _.map(selectedSkills, selectedSkill => {
                    return {
                      key: selectedSkill.id,
                      label: selectedSkill.name,
                      value: selectedSkill,
                    };
                  })
                : undefined
            }
            dropDownMenuContainerStyle={{ top: Spacings.Large }}
            handleChange={handleSkillChange}
            errorMessage={
              proofAdded
                ? selectedSkills
                  ? undefined
                  : t('common:error.validationError')
                : undefined
            }
          />
          <DropDownMultiSelection
            inputId={InputIds.WorkerName}
            placeholder={t('alex:workerSkillReviewModal.chooseWorker')}
            title={t('glossary:worker')}
            containerStyle={Styles.workerDropDown}
            inputListContainerStyle={{ width: '100%' }}
            options={workerOptions ?? []}
            notEditable={!workerOptions || props.revokeProof}
            listIcon={workerIcon}
            searchPlaceholder={t('alex:workerSkillReviewModal.chooseWorker')}
            values={
              selectedWorkers
                ? _.map(selectedWorkers, selectedWorker => {
                    return {
                      key: selectedWorker.id,
                      label: selectedWorker.name,
                      value: selectedWorker,
                    };
                  })
                : undefined
            }
            handleChange={handleWorkerDropdown}
          />
          {!isSkillsOrWorkersCountGreaterThanOne && (
            <AcquiredAndNotAcquiredButtons
              onChange={handleRadioButtons}
              initialItemIndex={isSkillAcquired}
              radioButtonColor={Colors.Black}
              textStyle={{ color: Colors.Black }}
              disabled={props.revokeProof}
            />
          )}

          {!isSkillsOrWorkersCountGreaterThanOne ? (
            <>
              <View style={Styles.obtentionDateAndValidityContainer}>
                <InputDateWeb
                  hasDateIcon
                  inputPlaceholder={t('alex:workerSkillReviewModal.obtention')}
                  onChange={(inputName, date) => {
                    
                    const currentDate = new Date();
                    date.setHours(currentDate.getHours());
                    date.setMinutes(currentDate.getMinutes());
                    date.setSeconds(currentDate.getSeconds());
                    setSkillObtentionDate(date);
                  }}
                  inputName={t('alex:workerSkillReviewModal.obtention')}
                  value={skillObtentionDate}
                  maxDate={new Date()}
                  onShowWebCalendar={handleShowWebCalendar}
                />
                {isShowSkillValidity() && (
                  <ValidityDisplay
                    startingDate={skillObtentionDate}
                    skill={selectedSkills ? selectedSkills[0] : selectedSkills}
                  />
                )}
              </View>
              <View style={Styles.commentsContainer}>
                <InputTextDescription
                  inputRef={refs[InputIds.Comments]}
                  inputId={InputIds.Comments}
                  placeHolder={t('alex:workerSkillReviewModal.comments')}
                  style={Styles.commentsContainer}
                  onTextChange={(textid, text) => {
                    setComment(text);
                    onChange(textid, text);
                  }}
                  defaultValue={comment}
                  label={t('alex:workerSkillReviewModal.comments')}
                  onBlur={onBlur}
                  errorMessage={errors[InputIds.Comments]?.inputErrorMessage}
                />
              </View>
            </>
          ) : null}
        </View>
        {isSkillsOrWorkersCountGreaterThanOne ? (
          <View style={Styles.submitButtonContainer}>
            {showSubmitButton && (
              <YellowButton
                onPress={navigateToSkillBook}
                style={[
                  Styles.submitButtonStyle,
                  { backgroundColor: isEnableGenerateProofBook ? Colors.Yellow : Colors.GreyLight },
                ]}
                text={t('alex:proofBook.generateSkillBook')}
                textStyle={Styles.submitButtonTextStyle}
                disabled={
                  !isEnableGenerateProofBook ||
                  !isValidPermission(
                    API.Permission.workersSkillsProof_edit,
                    undefined,
                    selectedWorkers?.map(selectedWorker => selectedWorker.id),
                  )
                }
              />
            )}
          </View>
        ) : (
          <View style={Styles.submitButtonContainer}>
            {showSubmitButton && (
              <YellowButton
                onPress={onSubmitForm}
                style={[
                  Styles.submitButtonStyle,
                  { backgroundColor: validData ? Colors.Yellow : Colors.GreyLight },
                ]}
                text={
                  props.revokeProof
                    ? t('common:button.revoke')
                    : t('alex:workerSkillReviewModal.submitForValidation')
                }
                textStyle={Styles.submitButtonTextStyle}
                disabled={
                  !validData ||
                  !isValidPermission(
                    API.Permission.workersSkillsProof_edit,
                    undefined,
                    selectedWorkers?.map(selectedWorker => selectedWorker.id),
                  )
                }
              />
            )}
          </View>
        )}
      </View>
    );
  };
  return <>{renderRightPanel()}</>;
};
