import React, { useState, useEffect, useContext } from 'react';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { InteractionManager } from 'react-native';
import * as _ from 'lodash-es';
import { TableRow } from 'shared/ui-component/Table';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { t } from 'shared/localisation/i18n';
import { WorkersTable } from './component/WorkersTable';
import { SkillBadgeStates } from 'shared/util/Skill';
import { View } from 'react-native';
import Styles from './component/Styles';
import { MyHub } from 'shared/util/MyHub';
import { WorkersStatus } from 'shared/util/Worker';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import {
  removeInvalidObjectFromUserPreference,
  extractFilterTags,
} from '../../header-layout/headerFilterConfig';
import { MyFactoryContext } from '../MyFactoryContext';

interface WorkerSkills {
  validSkills: API.Skill[];
  workerSkills: API.WorkerSkill[];
}

interface Props {}

export interface WorkerAndWorkstationVersatilityRow extends TableRow, API.Worker {
  tags?: API.WorkerTag[];
  versatility?: API.Workstation[];
  activeTrainingSessions?: API.TrainingSession[];
  badgeState?: SkillBadgeStates;
  workerAssignments?: API.AssignmentWithUnitDetails[];
  workerBadge?: SkillBadgeStates;
  filterData?: (data: API.Worker[]) => void;
}

export const WorkersLibrary: React.FC<Props> = props => {
  const {
    workerScreenFilterTags: [workerScreenFilterTags, setWorkerScreenFilterTags],
    currentRoute: [, setCurrentRoute],
  } = useContext(HeaderFilterContext);
  const { workerTableData } = useContext(MyFactoryContext);
  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);

  const [rows, setRows] = useState<WorkerAndWorkstationVersatilityRow[]>([]);
  const [filterTags, setFilterTags] = useState<TagExtended[]>([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    setFirstTitle(t('alex:header.workersCatalog'));
    setSecondTitle(t('glossary:worker_plural', undefined, false));
    setCurrentRoute(RoutePaths.Workers);

    fetchRows();
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      const userPreference = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.WorkerCatalogFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(userPreference)) {
        logger.warn('fetch worker filter: error in saving user Preference', userPreference);
        return;
      }
      if (userPreference) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.DashboardFilter,
          userPreference,
        );
        if (!isMounted.current) return;

        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.WorkerCatalogFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save worker filter: error while saving user Preference', saved);
          return;
        }

        setWorkerScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.WorkerCatalogFilter) ?? [],
        );
      }
    });

    return () => {
      setCurrentRoute(undefined);
    };
  }, []);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKER) {
        fetchRows();
      }
    });

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    setFilterTags(extractFilterTags(workerScreenFilterTags));
  }, [workerScreenFilterTags]);

  /**
   * Fetch data for worker table
   * @param showArchived boolean - To show archived workers
   * @param forceReload boolean - To reload the table forcefully
   */
  async function fetchRows(
    showArchived: boolean = true,
    forceReload: boolean = false,
  ): Promise<void> {
    if (workerTableData.rows.length && !forceReload) {
      setRows(workerTableData.rows);
      return;
    }

    const _workers = await API.getWorkers(
      undefined,
      undefined,
      showArchived,
      WorkersStatus.AllWorkersExceptInvitedByEmailUsersWithoutProfileName,
    );
    if (!isMounted.current) return;
    if (API.isFailure(_workers)) {
      logger.warn(_workers);
      return;
    }

    setRows(
      _workers.result.map(worker => {
        const _row: WorkerAndWorkstationVersatilityRow = {
          ...worker,
          key: worker.id,
          tags: undefined,
          versatility: undefined,
          activeTrainingSessions: undefined,
          workerAssignments: undefined,
          workerBadge: undefined,
        };
        return _row;
      }),
    );
  }

  return (
    <View style={Styles.tableOuterContainer}>
      <WorkersTable rows={rows} filterTags={filterTags} fetchRows={fetchRows} />
    </View>
  );
};
