import React, { useState, useEffect } from 'react';
import { InputText } from 'shared/ui-component/Input';
import { View, Text, TouchableOpacity, Keyboard, Linking, ScrollView } from 'react-native';
import { UserContext } from 'shared/context/UserContext';
import { KeyboardAvoidingViewWrapper } from 'shared/ui-component/KeyboardAvoidingView';
import Styles from './Style';
import { Colors } from 'shared/styles/Colors';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { IconSVG } from 'shared/ui-component/Icon';
import arrowLeft from 'shared/assets/svg/icon.arrowLeft.mobile-2.svg';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { YellowButton } from 'shared/ui-component/Button';
import { ModalUtils } from 'shared/ui-component/Modal';
import { InputsValue, InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import { PasswordRules } from '../passwordRules';

export enum UserType {
  NewUser = 'NewUser',
  InvitedUser = 'InvitedUser',
}

export interface Props {
  userType: UserType;
  username: string;
  isWebPlatfrom: boolean;
  handleNavigation: Function;

  handleBackPress: () => void;
}

enum InputIds {
  UserFirstName = 'UserFirstName',
  UserLastName = 'UserLastName',
  UserPassword = 'UserPassword',
}

export const SignUpLayout: React.FC<Props> = props => {
  const [error, setError] = useState<string>();
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>();

  const modal = ModalUtils.useModal();

  const isMounted = useIsMounted();

  const { errors, enableFormSubmit, onBlur, onChange, onSubmit, refs, navigateInputField } =
    useCustomForm<string>(
      {
        [InputIds.UserFirstName]: {
          validator: [InputValidationType.NotEmpty],
          isFieldMandatory: true,
        },
        [InputIds.UserLastName]: {
          isFieldMandatory: true,
          validator: [InputValidationType.NotEmpty],
        },
        [InputIds.UserPassword]: {
          validator: [InputValidationType.Password],
          isFieldMandatory: true,
        },
      },
      onSubmitForm,
    );

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', () => setIsKeyboardOpen(true));
    Keyboard.addListener('keyboardDidHide', () => setIsKeyboardOpen(false));
  }, []);

  async function handleSignUpSubmit(firstName: string, lastName: string, password: string) {
    if (!firstName || !lastName || !password) {
      logger.debug('firstName, lastname or password shall be set before calling this method');
      return;
    }

    modal.displayLoader(ModalUtils.LoaderConfig({}));
    const result = await UserContext.signUp(props.username, firstName, lastName, password);
    modal.hideLoader();
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      setError(result.data.message);
      return;
    }
    props.handleNavigation(password, firstName, lastName);
  }

  const handleSetPassword = async (firstName: string, lastName: string, password: string) => {
    if (!firstName || !lastName || !password) {
      logger.warn('firstName, lastname or password shall be set before calling this method');
      return;
    }

    modal.displayLoader(ModalUtils.LoaderConfig({}));
    const result = await UserContext.completeNewPassword(password);
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      setError(result.data.message);
      modal.hideLoader();
      return;
    }

    const updatedAttributes = await UserContext.updateUserAttributes({
      given_name: firstName,
      family_name: lastName,
      name: firstName + ' ' + lastName,
    });
    if (!isMounted.current) return;
    if (API.isFailure(updatedAttributes)) {
      setError(updatedAttributes.data.message);
      modal.hideLoader();
      logger.info('Failed to update user attributes');
      return;
    }

    modal.hideLoader();
    const refreshed = await UserContext.signInFromLastSession();
    if (refreshed) props.handleNavigation();
  };

  function onSubmitForm(values: InputsValue<string>) {
    const firstName = capitalizeFirstLetter(values[InputIds.UserFirstName]);
    const lastName = capitalizeFirstLetter(values[InputIds.UserLastName]);
    const password = values[InputIds.UserPassword];

    props.userType === UserType.NewUser
      ? handleSignUpSubmit(firstName, lastName, password)
      : handleSetPassword(firstName, lastName, password);
  }

  return (
    <KeyboardAvoidingViewWrapper style={Styles.appContainer}>
      <View style={Styles.rootContainer}>
        {props.isWebPlatfrom ? (
          <View style={Styles.topContainer}>
            <TouchableOpacity style={Styles.backButton} onPress={props.handleBackPress}>
              <IconSVG
                svgComponent={arrowLeft}
                color={Colors.Black}
                containerStyle={Styles.IconContainer}
              />
            </TouchableOpacity>
            <View style={Styles.headerTextContainer}>
              <Text style={Styles.headerText}>
                {props.userType == 'InvitedUser'
                  ? t('common:auth.signup.headerInvitedAccount', { app: t('alex:app.name') })
                  : t('common:auth.signup.headerNewAccount', { app: t('alex:app.name') })}
              </Text>
            </View>
          </View>
        ) : (
          <View style={Styles.topContainer}>
            <TouchableOpacity style={Styles.backButton} onPress={props.handleBackPress}>
              <IconSVG svgComponent={arrowLeft} color={Colors.Black} />
            </TouchableOpacity>
            <View style={Styles.headerTextContainer}>
              <Text style={Styles.headerText}>
                {props.userType == 'InvitedUser'
                  ? t('common:auth.signup.headerInvitedAccount', { app: t('alex:app.name') })
                  : t('common:auth.signup.headerNewAccount', { app: t('alex:app.name') })}
              </Text>
            </View>
          </View>
        )}
        {!isKeyboardOpen && (
          <Text style={Styles.bodyText}>
            {props.userType == 'InvitedUser'
              ? t('common:auth.signup.createInvitedAccount', { app: t('alex:app.name') })
              : t('common:auth.signup.createNewAccount')}
            <Text style={Styles.boldBodyText}>{t('common:auth.signup.instruction')} </Text>
          </Text>
        )}
        <ScrollView
          contentContainerStyle={[
            {
              flexGrow: 1,
              justifyContent: 'space-between',
              flexDirection: 'column',
            },
          ]}
        >
          <>
            <View style={Styles.middleContainer}>
              <InputText
                autoFocus
                inputRef={refs[InputIds.UserFirstName]}
                inputId={InputIds.UserFirstName}
                placeHolder={t('common:account.firstName')}
                onTextChange={onChange}
                onSubmitEditing={() => {
                  navigateInputField(InputIds.UserLastName);
                }}
                errorMessage={errors[InputIds.UserFirstName]?.inputErrorMessage}
              />
              <InputText
                inputRef={refs[InputIds.UserLastName]}
                inputId={InputIds.UserLastName}
                placeHolder={t('common:account.lastName')}
                containerStyle={Styles.inputText}
                onTextChange={onChange}
                onSubmitEditing={() => {
                  navigateInputField(InputIds.UserPassword);
                }}
                errorMessage={errors[InputIds.UserLastName]?.inputErrorMessage}
              />
              <InputText
                inputRef={refs[InputIds.UserPassword]}
                inputId={InputIds.UserPassword}
                placeHolder={t('common:auth.signup.choosePassword')}
                secureTextEntry
                containerStyle={Styles.inputText}
                onTextChange={onChange}
                onSubmitEditing={onSubmit}
                onBlur={onBlur}
                errorMessage={errors[InputIds.UserPassword]?.inputErrorMessage}
              />
              <PasswordRules />
              <Text style={Styles.errorMessage}>{error ?? ''}</Text>
            </View>

            <View>
              <YellowButton
                style={enableFormSubmit ? Styles.connectButton : Styles.connectButtonDisabled}
                onPress={onSubmit}
                disabled={!enableFormSubmit}
                text={t('common:button.create')}
                textStyle={Styles.connectText}
              />
              {!isKeyboardOpen && !props.isWebPlatfrom && (
                <Text style={Styles.termsConditionText}>
                  {t('common:auth.signup.termsOfServiceAndPrivacyPolicy.0')}
                  <Text
                    style={Styles.greyText}
                    onPress={() =>
                      Linking.openURL('https://yelhow.com/legal/terms-and-conditions/')
                    }
                  >
                    {' ' +
                      t('common:auth.signup.termsOfServiceAndPrivacyPolicy.1', undefined, false)}
                  </Text>
                  {' ' + t('common:auth.signup.termsOfServiceAndPrivacyPolicy.2', undefined, false)}
                  <Text
                    style={Styles.greyText}
                    onPress={() => Linking.openURL('https://yelhow.com/legal/privacy-policy/')}
                  >
                    {' ' +
                      t('common:auth.signup.termsOfServiceAndPrivacyPolicy.3', undefined, false)}
                  </Text>
                  {'.'}
                </Text>
              )}
              {props.isWebPlatfrom && (
                <View style={Styles.termsConditionContainer}>
                  <Text style={Styles.termsConditionText}>
                    {t('common:auth.signup.termsOfServiceAndPrivacyPolicy.0')}
                    <Text
                      style={Styles.greyText}
                      onPress={() =>
                        window.open('https://yelhow.com/legal/terms-and-conditions/', '_blank')
                      }
                    >
                      {' ' +
                        t('common:auth.signup.termsOfServiceAndPrivacyPolicy.1', undefined, false)}
                    </Text>
                    {' ' +
                      t('common:auth.signup.termsOfServiceAndPrivacyPolicy.2', undefined, false)}
                    <Text
                      style={Styles.greyText}
                      onPress={() =>
                        window.open('https://yelhow.com/legal/privacy-policy/', '_blank')
                      }
                    >
                      {' ' +
                        t('common:auth.signup.termsOfServiceAndPrivacyPolicy.3', undefined, false)}
                    </Text>
                    {'.'}
                  </Text>
                </View>
              )}
            </View>
          </>
        </ScrollView>
      </View>
    </KeyboardAvoidingViewWrapper>
  );
};
