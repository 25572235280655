import { StyleSheet } from 'react-native';
import { CELL_WIDTH, HEADER_HEIGHT, HEADER_TEXT_WIDTH } from '../versatility-panel/styles';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';
import { NodeCellHorizontalTranslation } from './NodeCell';

export const ROW_HEIGHT = 47;

const styles = StyleSheet.create({
  activityIndicatorView: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowLevelCellsGrid: {
    flexDirection: 'row',
    height: ROW_HEIGHT,
  },
  rowLevelLoadingGrid: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  innerLevelCellsGrid: {
    zIndex: -1,
    flexDirection: 'column',
    marginTop: HEADER_HEIGHT,
    height: '100%',
  },
  horizontalLevelCellsGrid: {
    height: '100%',
    width: '100%',
  },
  levelCellsGrid: {
    height: '100%',
    width: '100%',
  },
  workstationContainer: {
    justifyContent: 'center',
    transform: [{ rotate: '-45deg' }],
    position: 'absolute',
    width: HEADER_TEXT_WIDTH,
  },
  workstationNameContainer: {
    zIndex: 1,
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
  },
  shadow: {
    shadowColor: Colors.Black,
    shadowOffset: {
      width: -1,
      height: 3,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4.84,
  },
  workstationHoverText: {
    ...TextFontStyles.LatoBlack,
  },
  workstationHoverTextContainer: {
    backgroundColor: Colors.GreyUltraLight,
    paddingLeft: Spacings.Small,
    paddingTop: 3,
    paddingBottom: Spacings.Unit,
    borderRadius: Spacings.Unit,
  },
  workstationText: {
    color: Colors.Black,
    fontSize: fontSize.Regular,
  },
  orgUnitText: {
    color: Colors.MediumGray,
    fontSize: fontSize.Standard,
  },
  workstationTooltipContainer: {
    maxWidth: 134,
  },
  workerWorkstationLevelsScrollingContainer: {
    marginTop: HEADER_HEIGHT + Spacings.xMedium,
    position: 'absolute',
    paddingLeft: Spacings.CardPadding,
    zIndex: -1,
    overflow: 'hidden',
  },
  alexIconContainer: {
    position: 'absolute',
  },
  orgUnitCellContainer: {
    left: -NodeCellHorizontalTranslation,
  },
  shiftThumbIconOuterWrapper: {
    width: CELL_WIDTH,
    justifyContent: 'flex-end',
  },
  shiftThumbIconInnerWrapper: { left: -14 },
});

export default styles;
