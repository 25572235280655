import { Result, createFailure, createFailure_Unspecified } from 'shared/backend-data/Failure';
import { searchMatch } from 'shared/util-ts/Functions';
import * as API from 'backend/src/api';

/**
 * Look for a Worker with the given name or email or phone or employeeId.
 * If found, check that all identifiers match otherwise return a Failure.
 * Returns a Failure if there are several Workers with the same name.
 * WARNING it shall not be used to test the existence of a Worker as the result depends on the User scope (what data user can see).
 * @param name - the name of the workstation
 */
export async function _findWorkerByNameOrEmailOrPhoneOrMatricule(
  workers: API.Worker[],
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  matricule?: string | null,
  checkAllIdentifiersMatch = true,
): Promise<Result<API.Worker | undefined>> {
  if (!name && !email && !phone && !matricule)
    return createFailure('InvalidArguments', 'should pass at least one worker attribute');

  let result: API.Worker | undefined = undefined;
  for (const worker of workers) {
    let sameName: boolean | null = null;
    if (name?.length) {
      sameName = searchMatch(name, worker.name, true);
      if (!sameName) {
        
        const names = name.split(' ');
        for (let i = names.length - 1; i > 0; i--) {
          let lastName = '';
          for (let j = i; j <= names.length - 1; j++) {
            lastName = lastName + (lastName.length ? ' ' : '') + names[j];
          }
          let firstName = '';
          for (let j = 0; j < i; j++) {
            firstName = firstName + (firstName.length ? ' ' : '') + names[j];
          }
          sameName = searchMatch(lastName + ' ' + firstName, worker.name, true);
          if (sameName) break;
        }
      }
    }

    const sameEmail = email?.length ? searchMatch(email, worker.email ?? '', true) : null;

    const samePhone = phone?.length ? searchMatch(phone, worker.phone ?? '', true) : null;

    const sameMatricule = matricule?.length
      ? searchMatch(matricule, worker.matricule ?? '', true)
      : null;

    if (sameName || sameEmail || samePhone || sameMatricule) {
      if (checkAllIdentifiersMatch) {
        
        if (
          (sameName == null || sameName) &&
          (sameEmail == null || sameEmail) &&
          (samePhone == null || samePhone) &&
          sameMatricule == null &&
          sameMatricule
        ) {
          if (name)
            return createFailure_Unspecified(
              'A worker with the same name (' +
                name +
                ") exists but some of email/phone/employeeId don't match",
            );
          else
            return createFailure_Unspecified(
              "A worker with the same email, phone or employeeId exists but some of email/phone/employeeId don't match",
            );
        }
      }

      if (result)
        return createFailure_Unspecified(
          'Several Workers have the name (' +
            name +
            '). Consider passing the email and/or phone and/or employeeId instead.',
        );
      result = worker;
    }
  }

  return result;
}
