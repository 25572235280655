import React, { useState, useEffect, useContext } from 'react';
import { View, InteractionManager, ActivityIndicator } from 'react-native';
import { useHistory } from 'react-router-dom';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { t } from 'shared/localisation/i18n';
import Styles from './Styles';
import * as SharedStyles from 'shared/styles';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import {
  Table,
  TableColumn,
  TableRow,
  TableLargeColumnWidth,
  TableMediumColumnWidth,
} from 'shared/ui-component/Table';
import { TreeNode, TreeTableNode } from 'shared/backend-data/factoryCache/Tree';
import { MenuItem, MenuWidth } from 'shared/ui-component/Menu';
import { ModalUtils } from 'shared/ui-component/Modal';
import { TableNumberWithMenu } from 'sharedweb/src/NumberWithMenu';
import { IconSVG } from 'shared/ui-component/Icon';
import { MyHub } from 'shared/util/MyHub';
import { TreeTableContext } from 'sharedweb/src/context/TreeTableContext';
import { renderImage, ImageSizes } from 'shared/util/RenderImage';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import logger from 'shared/util/Logger';
import { RouteLocations } from '../../../navigation/Routes';
import { InputShiftsList } from 'shared/components/InputShiftsList';
import { ReassignmentModal } from '../../../reassignment-modal/container';
import { UnitModal } from '../../workstations/component/orgunit-modal/container';
import { AddEditWorkerModalContainer } from './modify-worker-modal/container';
import { Tag } from 'shared/ui-component/Input/InputTag';
import {
  copyWorkstationOrOrganizationalUnitAndRequirements,
  deleteOrganizationalUnitWithErrorModalHandling,
} from 'shared/util/WorkstationUi';

interface OrgUnitRowInfo {
  node: TreeTableNode;
  assignedWorkerIds: string[];
  indentation: number;
}
interface WorkerRowInfo {
  workerId: string;
  orgUnitId: string;
  workerName: string;
  workerProfilePicture?: string | null;
  indentation: number;
}

export interface OrgUnitTreeRow extends TableRow {
  info: OrgUnitRowInfo;
}
export interface WorkerTreeRow extends TableRow {
  info: WorkerRowInfo;
}
export type AssignmentTreeRow = OrgUnitTreeRow | WorkerTreeRow;

export function isRowOrgUnit(row: AssignmentTreeRow): row is OrgUnitTreeRow {
  return 'node' in row.info;
}
export function isRowWorker(row: AssignmentTreeRow): row is WorkerTreeRow {
  return 'workerId' in row.info;
}

enum AssignmentMenuType {
  EDIT_SHIFT = 'EDIT_SHIFT',
  REASSIGNMENT = 'REASSIGNMENT',
  EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT',
}
interface MenuHandlerOptions {
  menuType: AssignmentMenuType;
  row: AssignmentTreeRow;
}

interface AssignmentTableProps {
  filter: {
    /**
     * Returns the filtered Rows
     * @param rows to filter
     * @param filters to apply
     */
    filterRows: (rows: AssignmentTreeRow[], tags: Tag[]) => Promise<AssignmentTreeRow[]>;
    /** The available tags inside the filter */
    tags: Tag[];
  };
}

const orgUnitIcon = require('shared/assets/svg/icon.workstation.svg').default;
const arrowDownIcon = require('shared/assets/svg/icon.arrowDown.mobile.svg').default;
const arrowRightIcon = require('shared/assets/svg/icon.arrowRight.mobile.svg').default;

export const AssignmentTable: React.FC<AssignmentTableProps> = props => {
  const { filter } = props;

  const { collapsedNodes, setCollapsedNodes } = useContext(TreeTableContext);
  const { isValidPermission } = useContext(PermissionManagementContext);

  const history = useHistory();
  const isMounted = useIsMounted();
  const modal = ModalUtils.useModal();

  const [rows, setRows] = useState<AssignmentTreeRow[]>();
  const [filteredRows, setFilteredRows] = useState<AssignmentTreeRow[]>();
  const [columns, setColumns] = useState<TableColumn<AssignmentTreeRow>[]>();
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);

  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [activeEditShiftRow, setActiveEditShiftRow] = useState<OrgUnitTreeRow | undefined>();
  const [activeReassignmentRow, setActiveReassignmentRow] = useState<OrgUnitTreeRow | undefined>();
  const [activeEditAssignmentRow, setActiveEditAssignmentRow] = useState<
    WorkerTreeRow | undefined
  >();

  /**
   * Fetch columns for assignment table
   */
  function getTableColumns(): void {
    const _columns: TableColumn<AssignmentTreeRow>[] = [
      {
        label: t('alex:header.navigation.2'),
        width: TableLargeColumnWidth,
        renderCell: row => {
          return isRowOrgUnit(row) ? (
            <View
              style={[
                Styles.firstColumnOrgUnitCellContainer,
                {
                  paddingLeft:
                    row.info.indentation *
                    (SharedStyles.Spacings.Standard + SharedStyles.Spacings.Medium),
                },
              ]}
            >
              <IconSVG
                svgComponent={getArrowIcon(row)}
                color={SharedStyles.Colors.Black}
                containerStyle={Styles.arrowIconContainer}
                size={{
                  width: SharedStyles.Spacings.Standard,
                  height: SharedStyles.Spacings.Standard,
                }}
              />

              <IconSVG
                svgComponent={orgUnitIcon}
                containerStyle={[
                  SharedStyles.Styles.tableObjectIconContainer,
                  {
                    backgroundColor: SharedStyles.Colors.Black,
                  },
                ]}
                size={{
                  width: SharedStyles.Spacings.Medium,
                  height: SharedStyles.Spacings.Medium,
                }}
                color={SharedStyles.Colors.White}
              />
              <EllipsisWithTooltip
                text={row.info.node.object.name}
                textStyle={SharedStyles.Styles.tableText}
              />
            </View>
          ) : (
            <View
              style={[
                Styles.firstColumnWorkerCellContainer,
                {
                  paddingLeft:
                    row.info.indentation *
                      (SharedStyles.Spacings.Standard + SharedStyles.Spacings.Medium) -
                    SharedStyles.Spacings.Medium,
                },
              ]}
            >
              {renderImage(row.info.workerProfilePicture, ImageSizes.Medium, row.info.workerName)}
              <EllipsisWithTooltip
                text={row.info.workerName}
                textStyle={[
                  SharedStyles.Styles.tableText,
                  { paddingLeft: SharedStyles.Spacings.Small },
                ]}
              />
            </View>
          );
        },
      },
      {
        label: '',
        width: TableMediumColumnWidth,
        renderCell: row => renderShifts(row),
      },
      {
        label: '',
        width: TableMediumColumnWidth,
        renderCell: row => renderAssignmentCount(row),
      },
    ];

    setColumns(_columns);
  }

  function renderAssignmentCount(row: AssignmentTreeRow): React.ReactElement {
    if (isRowWorker(row)) return <></>;

    return (
      <View>
        <TableNumberWithMenu
          list={[]}
          disableHover
          count={row.info.assignedWorkerIds.length}
          onMenuItemPress={() => {}}
          countColor={SharedStyles.Colors.Black}
        />
      </View>
    );
  }

  function handleSelectShift(shiftId: string): void {
    if (!selectedShiftIds.includes(shiftId)) {
      setSelectedShiftIds([...selectedShiftIds, shiftId]);
    } else {
      setSelectedShiftIds(
        [...selectedShiftIds].filter(selectedShiftId => shiftId !== selectedShiftId),
      );
    }
  }

  function renderShifts(row: AssignmentTreeRow): React.ReactElement {
    if (isRowWorker(row) || !row.info.node.object.shiftIds) return <></>;

    return (
      <View style={Styles.shiftsContainer}>
        <InputShiftsList
          shiftIds={[...row.info.node.object.shiftIds]}
          selectedShiftIds={selectedShiftIds}
          handleSelection={handleSelectShift}
        />
      </View>
    );
  }

  function getArrowIcon(row: OrgUnitTreeRow): React.FC {
    return row.info.node.isExpanded ? arrowDownIcon : arrowRightIcon;
  }

  /**
   * Fetch rows for assignment table
   */
  async function getTableRows(
    flatTree: TreeNode[],
    refresh?: boolean,
  ): Promise<API.Result<AssignmentTreeRow[] | undefined>> {
    const _rows: AssignmentTreeRow[] = [];

    let _initialSelectedShiftIds: string[] = [];

    for (const _node of flatTree) {
      if (API.isTreeNode(API.DataType.ORGUNIT, _node)) {
        const isExpanded: boolean = !collapsedNodes.includes(_node.id);

        const orgUnitInfo: OrgUnitRowInfo = {
          node: { ...API.deepClone(_node), isExpanded },
          indentation: _node.object.pathIds.length - 1,
          assignedWorkerIds: [],
        };

        let workersAssigned: API.Worker[] = [];

        const orgUnitSelectedShiftIds: string[] = !orgUnitInfo.node.object.shiftIds
          ? []
          : refresh
          ? [...orgUnitInfo.node.object.shiftIds].filter(shiftId =>
              selectedShiftIds.includes(shiftId),
            )
          : [...orgUnitInfo.node.object.shiftIds];

        if (!_node.object.shiftIds?.length) {
          const workersInOrgUnit = await API.getWorkersInOrganizationalUnits([orgUnitInfo.node.id]);
          if (API.isFailure(workersInOrgUnit)) {
            logger.error(workersInOrgUnit);
            return workersInOrgUnit;
          }

          workersAssigned = workersInOrgUnit.result;
        } else {
          const _selectedShiftIds = orgUnitSelectedShiftIds.length
            ? orgUnitSelectedShiftIds
            : _node.object.shiftIds;

          await Promise.all(
            _selectedShiftIds.map(async shiftId => {
              const workersOnShift = await API.getWorkersInShift(shiftId, _node.id);
              if (API.isFailure(workersOnShift)) {
                logger.error(workersOnShift);
                return workersOnShift;
              }

              workersAssigned = _.unionBy(workersAssigned, workersOnShift, worker => worker.id);
            }),
          );
          if (!isMounted.current) return;
        }

        orgUnitInfo.assignedWorkerIds = workersAssigned.map(worker => worker.id);

        const orgUnitRow: OrgUnitTreeRow = {
          key: _node.id,
          info: orgUnitInfo,
        };

        _rows.push(orgUnitRow);

        workersAssigned.map((worker: API.Worker) => {
          const workerInfo: WorkerRowInfo = {
            workerId: worker.id,
            orgUnitId: _node.id,
            workerName: worker.name,
            workerProfilePicture: worker.profilePicture,
            indentation: _node.object.pathIds.length,
          };

          const workerRow: WorkerTreeRow = {
            key: _node.id + API.SeparatorIds + worker.id,
            info: workerInfo,
          };

          _rows.push(workerRow);
        });

        if (!refresh)
          _initialSelectedShiftIds = _.union(_initialSelectedShiftIds, _node.object.shiftIds ?? []);
      }
    }

    if (!refresh) setSelectedShiftIds(_initialSelectedShiftIds);
    setRows(_rows);
  }

  function filterRows(): API.Result<void> {
    const _filteredData: AssignmentTreeRow[] = [];

    (rows ?? []).map(row => {
      let isShown: boolean = true;

      if (isRowOrgUnit(row)) {
        for (const parentId of row.info.node.parentIds) {
          if (collapsedNodes.includes(parentId)) isShown = false;
        }

        if (isShown) {
          const isExpanded: boolean = !collapsedNodes.includes(row.info.node.id);

          _filteredData.push({
            ...row,
            info: {
              ...row.info,
              node: { ...row.info.node, isExpanded },
            },
          });
        }
      } else {
        const assignedUnit = API.getOrganizationalUnit(row.info.orgUnitId);
        if (API.isFailure(assignedUnit)) return assignedUnit;

        for (const parentId of assignedUnit.pathIds) {
          if (collapsedNodes.includes(parentId)) isShown = false;
        }

        if (isShown)
          _filteredData.push({
            ...row,
          });
      }
    });

    setFilteredRows(_filteredData);
  }

  function handleRowPress(row: AssignmentTreeRow): void {
    if (isRowWorker(row)) {
      history.push(RouteLocations.WorkerProfile(row.info.workerId));
      return;
    }

    if (!collapsedNodes.includes(row.info.node.id)) {
      setCollapsedNodes([...collapsedNodes, row.info.node.id]);
    } else {
      setCollapsedNodes([...collapsedNodes].filter(nodeId => nodeId !== row.info.node.id));
    }
  }

  function handleMenuOpenClose(options?: MenuHandlerOptions): void {
    if (!options) {
      setActiveEditShiftRow(undefined);
      setActiveReassignmentRow(undefined);
      setActiveEditAssignmentRow(undefined);
      return;
    }

    switch (options.menuType) {
      case AssignmentMenuType.EDIT_SHIFT:
        isRowOrgUnit(options.row) && setActiveEditShiftRow(options.row);
        setActiveReassignmentRow(undefined);
        setActiveEditAssignmentRow(undefined);
        break;

      case AssignmentMenuType.REASSIGNMENT:
        setActiveEditShiftRow(undefined);
        isRowOrgUnit(options.row) && setActiveReassignmentRow(options.row);
        setActiveEditAssignmentRow(undefined);
        break;

      case AssignmentMenuType.EDIT_ASSIGNMENT:
        setActiveEditShiftRow(undefined);
        setActiveReassignmentRow(undefined);
        isRowWorker(options.row) && setActiveEditAssignmentRow(options.row);
        break;

      default:
        break;
    }
  }

  function handleModalClose(): void {
    handleMenuOpenClose();
  }

  function getMenuOptions(row: AssignmentTreeRow): MenuItem[] {
    return isRowWorker(row)
      ? [
          {
            label: t('alex:workersAssignmentTable.menuOptions.workerRowMenu'),
            onPress: () => {
              handleMenuOpenClose({ menuType: AssignmentMenuType.EDIT_ASSIGNMENT, row });
            },
            disable: !isValidPermission(API.Permission.workers_edit),
          },
        ]
      : [
          {
            label: t('alex:workersAssignmentTable.menuOptions.orgUnitRowMenu.0'),
            onPress: () => {
              handleMenuOpenClose({ menuType: AssignmentMenuType.EDIT_SHIFT, row });
            },
            disable: !isValidPermission(API.Permission.workers_edit),
          },
          {
            label: t('alex:workersAssignmentTable.menuOptions.orgUnitRowMenu.1'),
            onPress: () => {
              handleMenuOpenClose({ menuType: AssignmentMenuType.REASSIGNMENT, row });
            },
            disable: !isValidPermission(API.Permission.workers_edit),
          },
        ];
  }

  async function duplicateNode(node: TreeNode): Promise<void> {
    setLoadingAction(true);

    const newOrgUnit = await copyWorkstationOrOrganizationalUnitAndRequirements(modal, node.object);

    if (!isMounted.current) return;
    if (API.isFailure(newOrgUnit)) {
      logger.warn('Failed to duplicateNode', newOrgUnit);
    }
    setLoadingAction(false);
  }

  async function deleteNode(node: TreeNode): Promise<void> {
    setLoadingAction(true);

    if (API.isOrganizationalUnit(node.object))
      await deleteOrganizationalUnitWithErrorModalHandling(node.id, modal);

    if (!isMounted.current) return;

    setLoadingAction(false);
  }

  function renderActiveModal(): JSX.Element | null {
    if (activeEditShiftRow) {
      return API.isOrganizationalUnit(activeEditShiftRow.info.node.object) ? (
        <UnitModal
          handleModalClose={handleModalClose}
          node={activeEditShiftRow.info.node}
          duplicateNode={duplicateNode}
          deleteNode={deleteNode}
        />
      ) : null;
    } else if (activeReassignmentRow) {
      return API.isOrganizationalUnit(activeReassignmentRow.info.node.object) ? (
        <ReassignmentModal
          hideSubHeader
          handleModalClose={handleModalClose}
          orgUnit={activeReassignmentRow.info.node.object}
        />
      ) : null;
    } else if (activeEditAssignmentRow) {
      return isRowWorker(activeEditAssignmentRow) ? (
        <AddEditWorkerModalContainer
          workerId={activeEditAssignmentRow.info.workerId}
          showHideModal={() => handleMenuOpenClose()}
        />
      ) : null;
    }

    return null;
  }

  function renderTable(): JSX.Element {
    const activityIndicator = (
      <View style={Styles.activityIndicatorContainer}>
        <ActivityIndicator color={SharedStyles.Colors.Yellow} size="large" />
      </View>
    );

    return rows && !loadingAction ? (
      <View style={{ flex: 1 }}>
        <Table
          disableSorting
          disableRowClick={false}
          onRowPress={row => {
            handleRowPress(row);
          }}
          filter={{
            filterRows: filter.filterRows,
            tags: filter.tags,
          }}
          columnDescriptors={columns ?? []}
          rows={filteredRows ?? []}
          rowMenuWidth={MenuWidth.Medium}
          rowMenu={row => getMenuOptions(row)}
        />
        {renderActiveModal()}
      </View>
    ) : (
      activityIndicator
    );
  }

  async function getTableData(refreshData?: boolean): Promise<void> {
    if (refreshData || !rows) {
      const _flattenedTree = API.Tree.getFlatAndOrderedTree(undefined, true);

      await getTableRows(_flattenedTree, refreshData);
      if (!isMounted.current) return;
    }

    filterRows();
  }

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
        if (data.factory.dataType === API.DataType.WORKER) {
          getTableData(true);
        }
      });

      const removeListener2 = MyHub.listenBusinessObject('TreeStructureUpdate', () => {
        getTableData(true);
      });

      getTableColumns();
      await getTableData();
      if (!isMounted.current) return;

      return () => {
        removeListener();
        removeListener2();
      };
    });
  }, [rows]);

  useEffect(() => {
    filterRows();
  }, [collapsedNodes]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      await getTableData(true);
    });
  }, [selectedShiftIds]);

  return renderTable();
};
