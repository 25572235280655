import React, { useState, useEffect, useContext } from 'react';
import { SkillProfileComponent } from '../components/SkillProfileComponent';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { MyHub } from 'shared/util/MyHub';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { useParams } from 'react-router-dom';
import { ProfileRouteParam } from '../../../navigation/Routes';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';

interface Props { }

export const AddEditSkill: React.FC<Props> = props => {
  const isMounted = useIsMounted();
  const { setHideMenuFactory } = useContext(GlobalDataContext);
  const { id } = useParams<ProfileRouteParam>();

  const [skill, setSkill] = useState<API.Skill>();

  useEffect(() => {
    if (id) {
      setHideMenuFactory(true);
      reFetchSkill(API.combineDataTypeAndId(API.DataType.SKILL, id));
    }
  }, [id]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.SKILL &&
        (data.tooManyMutations || data.factory.skill.id === skill?.id)
      ) {
        reFetchSkill();
      }
    });

    return () => {
      removeListener();
    };
  }, [skill]);

  async function reFetchSkill(skillId?: string) {
    const _skillId = skillId || skill?.id;
    if (!_skillId) return;

    const _skill = await API.getSkill(_skillId);
    if (!isMounted.current) return;
    if (API.isFailure(_skill)) return _skill;

    setSkill(_skill);
  }

  return <SkillProfileComponent skill={skill} />;
};
