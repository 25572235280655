import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
  },
  rootContainer: {
    flex: 1,
    backgroundColor: Colors.White,
    paddingTop: Spacings.Large + Spacings.Standard - Spacings.Unit, 
    paddingLeft: Spacings.Large, 
    paddingBottom: Spacings.Large + Spacings.Unit + 1, 
  },
  topContainer: {
    flexDirection: 'row',
  },
  middleContainer: {
    marginTop: Spacings.Standard + Spacings.Unit,
  },
  bottomContainer: {
    marginTop: Spacings.Standard * 4,
  },
  termsConditionContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  inputContainer: {
    marginTop: Spacings.Standard * 4,
  },
  connectButton: {
    width: 144,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.Yellow,
    justifyContent: 'center',
  },
  connectButtonDisabled: {
    width: 144,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.GreyLight,
    justifyContent: 'center',
  },
  connectText: {
    ...TextFontStyles.LatoBold,
    fontSize: 16,
    color: Colors.White,
  },
  backButton: {
    marginLeft: -Spacings.Small - Spacings.Unit, 
    marginRight: Spacings.Small,
    alignSelf: 'flex-end',
  },
  headerText: {
    ...TextFontStyles.LatoBlack,
    fontSize: 26,
  },
  headerTextContainer: {
    width: '100%',
  },
  errorMessage: {
    ...TextFontStyles.LatoBold,
    color: Colors.Red,
    marginTop: 15,
    fontSize: 14,
  },
  inputText: {
    marginTop: Spacings.Large,
  },
  bodyText: {
    ...TextFontStyles.LatoNormal,
    fontSize: 14,
    marginBottom: Spacings.Unit,
  },
  boldBodytext: {
    ...TextFontStyles.LatoBlack,
    fontSize: 14,
    marginBottom: Spacings.Standard,
  },
  forgetPasswordText: {
    ...TextFontStyles.LatoNormal,
    color: Colors.Grey,
    fontSize: 12,
  },
  resendText: {
    color: Colors.Grey,
    fontSize: 14,
    alignSelf: 'center',
    marginTop: '5%',
  },
  resendOTPText: {
    color: Colors.Grey,
    fontSize: fontSize.Small,
    marginTop: '5%',
  },
});

export default styles;
