import * as _ from 'lodash-es';
import {
  Result,
  isFailure,
  createFailure_Unspecified,
  createFailure,
  ResultWithNextToken,
} from '../Failure';
import {
  DataType,
  Factory,
  listFactoriesWithDataType,
  SeparatorDataType,
  SeparatorIds,
} from 'shared/backend-data';
import { Cache } from './Cache';
import { loggerAPI as logger } from '../../util/Logger';
import { LocalForageTypeSuported } from '../PersistentStorage';
import { QueryCache } from './QueryCache';

export abstract class FactoryCache<
  T extends DataType,
  V extends LocalForageTypeSuported = {},
> extends Cache<T, V, T> {
  
  
  

  /**
   * Get the FactoryCache for the given pk & dataType
   * @param dataType
   * @param pk (optional) if not specified the context one is used
   */
  static getFactoryCache<T extends DataType, V extends LocalForageTypeSuported = {}>(
    dataType: T,
    pk?: string,
  ): FactoryCache<T, V> {
    return Cache.getCache(dataType, pk) as unknown as FactoryCache<T, V>; 
  }

  
  
  

  protected dataType: T;

  protected constructor(pk: string, dataType: T, fill: boolean, emptyData: V) {
    super(pk, dataType, fill, emptyData);
    this.dataType = dataType;
  }

  async baseSync(): Promise<Result<V>> {
    
    
    
    const factories = (await listFactoriesWithDataType(
      this.cacheType,
      'network-no-cache',
    )) as ResultWithNextToken<Factory<T>[]>; 
    if (isFailure(factories)) return factories;

    if (factories.nextToken)
      return createFailure_Unspecified(
        'baseSync for cache (' + this.cacheType + ') is partial, we shall not have nextToken',
      );

    const data = this.structureData(factories.result);
    if (isFailure(data)) return data;

    logger.info(
      'FactoryCache for dataType = ' +
        this.cacheType +
        ' synced with ' +
        factories.result.length +
        ' items',
      data,
    );

    return data;
  }

  /**
   * Called when filling the cache.
   * @params ALL the factories of the FactoryCache's type to structure
   * @returns a structured data set from the given factories (it shall includes all of them!)
   */
  protected abstract structureData(factories: Factory<T>[]): Result<V>;

  /**
   * Get the keys of the given factorySkOrData, removing the composite dataTypes,
   * Exemple: "dataType1#,id1,id2,state" => ["id1","id2","state"]
   *         ["dataType1#","id1","id2","state"] => ["id1","id2","state"]
   *
   * @param factorySkOrData (compositeDataType | id1 | string),(compositeDataType2 | id2 | string),..
   * where compositeDataType = datatype#, id = dataType#uuid
   */
  protected getKeys(factorySkOrData: string | string[]): string[] {
    const keys =
      typeof factorySkOrData === 'string' ? factorySkOrData.split(SeparatorIds) : factorySkOrData;
    return keys.filter(key => {
      const idChunks = key.split(SeparatorDataType);
      return idChunks.length <= 1 ? true : idChunks[1].length;
    });
  }

  /**
   * @param sk : the Factory sk
   * @param isCacheFilledRequired (default = false), if set to true a Failure will be thrown if the cache is not filled (to inform that result will not be accurate)
   * @returns
   *  - the Factory matching the given sk if found,
   *  - Failure 'FactoryCacheNotReady' except if the cache is initializing
   *  - 'ObjectNotFound' if object doesn't exists (only available if cache is filled),
   *  - undefined otherwise
   */
  getFactory(
    sk: string,
    isCacheFilledRequired?: boolean,
  ): Result<Factory<T>, 'FactoryCacheNotReady'> | 'ObjectNotFound' | undefined;
  /**
   * @param skIds ids that compose the Factory's sk
   * All ids shall be passed in the correct order @see getKeys() for the structure of the Factory sk / data
   */
  getFactory(
    skIds: string[],
    isCacheFilledRequired?: boolean,
  ): Result<Factory<T>, 'FactoryCacheNotReady'> | 'ObjectNotFound' | undefined;
  getFactory(
    skOrIds: string | string[],
    isCacheFilledRequired?: boolean,
  ): Result<Factory<T>, 'FactoryCacheNotReady'> | 'ObjectNotFound' | undefined {
    if (this.cacheData.isInitialized === null) {
      
      return undefined; 
    }
    if (this.cacheData.isInitialized === false) {
      return createFailure(
        'FactoryCacheNotReady',
        'FactoryCache (dataType=' + this.dataType + ') is not initialized : try again later.',
      );
    }

    if (isCacheFilledRequired && !this.cacheData.isFilled) {
      return createFailure(
        'FactoryCacheNotReady',
        'FactoryCache (dataType=' + this.dataType + ') is not filled while it is a requirement.',
      );
    }

    const _factory = this._getFactory(skOrIds);
    if (_factory) return _factory;

    if (this.cacheData.isFilled) {
      return 'ObjectNotFound';
    } else {
      return undefined;
    }
  }

  /**
   * Get all the Factories (matching the dataType of the FactoryCache)
   */
  getFactories(): Result<Factory<T>[], 'FactoryCacheNotReady'>;
  /**
   * @returns the Factories with sk/data startingWith/equal the given string/keys
   *
   * @param index use the factory 'sk' / 'data' search index
   * @param type of match
   * @param factorySkOrData_or_keys : the (begining of the) Factory's sk/data
   *                        or the (first) keys stored inside the Factory's sk / data
   * For 'startingWith': only the first keys are required but the order, without miss, shall be respected @see getKeys() for the structure of the factorySkOrData
   */
  getFactories(input: {
    index: 'sk' | 'data';
    type: 'equal' | 'startingWith';
    factorySkOrData_or_keys: string | string[];
  }): Result<Factory<T>[], 'FactoryCacheNotReady'>;
  getFactories(input?: {
    index: 'sk' | 'data';
    type: 'equal' | 'startingWith';
    factorySkOrData_or_keys: string | string[];
  }): Result<Factory<T>[], 'FactoryCacheNotReady'> {
    if (!this.cacheData.isInitialized) {
      return createFailure(
        'FactoryCacheNotReady',
        'FacotryCache (dataType=' +
          this.dataType +
          ') is not initialized or initializing: try again later.',
      );
    }

    if (!input) {
      if (!this.cacheData.isFilled) {
        return createFailure(
          'FactoryCacheNotReady',
          'FacotryCache (dataType=' + this.dataType + ') is not filled, thus it cannot be queried.',
        );
      }

      return this._getFactories();
    } else {
      
      let cacheEnoughFilled = this.cacheData.isFilled;
      if (!cacheEnoughFilled) {
        
        if (input.index === 'sk') {
          const keys = this.getKeys(input.factorySkOrData_or_keys);
          while (keys.length) {
            if (
              QueryCache.getQueryCache().getQueryResult('listFactorys', {
                pk: this.pk,
                sk: {
                  beginsWith:
                    this.dataType + SeparatorDataType + SeparatorIds + keys.join(SeparatorIds),
                },
              })
            ) {
              cacheEnoughFilled = true;
              break;
            }

            keys.pop();
          }
        } else {
          const keys = this.getKeys(input.factorySkOrData_or_keys);
          while (keys.length) {
            if (
              QueryCache.getQueryCache().getQueryResult('itemsByData', {
                pk: this.pk,
                data: {
                  beginsWith:
                    this.dataType + SeparatorDataType + SeparatorIds + keys.join(SeparatorIds),
                },
              })
            ) {
              cacheEnoughFilled = true;
              break;
            }

            keys.pop();
          }
        }
      }

      if (!cacheEnoughFilled) {
        return createFailure(
          'FactoryCacheNotReady',
          'FacotryCache (dataType=' +
            this.dataType +
            ') is not filled with the required data, thus it cannot be queried.',
        );
      }

      return this._getFactories({
        index: input.index,
        type: input.type,
        factorySkOrData_or_keys: input.factorySkOrData_or_keys,
      });
    }
  }

  protected abstract _getFactory(skOrIds: string | string[]): Factory<T> | undefined;
  protected abstract _getFactories(input?: {
    index: 'sk' | 'data';
    type: 'equal' | 'startingWith';
    factorySkOrData_or_keys: string | string[];
  }): Factory<T>[];
}
