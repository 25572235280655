import React, { useState, useEffect } from 'react';
import * as API from 'shared/backend-data';
import { TrainingModalComponent, TrainingOption } from '../component';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import {
  dropDownWorkerLevels,
  DropDownWithWorkerLevelOption,
} from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';
import { ModalUtils } from 'shared/ui-component/Modal';
import {
  showTrainingSessionErrorWarning,
  TrainingSessionWarning,
} from 'shared/util/TrainingSessionWarningModal';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { t, capitalizeFirstLetter } from 'shared/localisation/i18n';
import { TreeNode, sortWorkers, WorkstationWorkerLevels } from 'shared/backend-data';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../../navigation/Routes';
import { getTreeNodeTag } from 'shared/util/WorkstationUi';

interface Props {
  openModal: boolean;
  workerId?: string;
  treeNodeId?: string;
  trainingLevel?: API.WorkstationWorkerLevels;
  disableRedirection?: boolean;
  originatedFromDashboard?: boolean;

  handleModalClose: () => void;
}

export const TrainWorkerModal: React.FC<Props> = props => {
  const {
    openModal,
    workerId,
    treeNodeId,
    trainingLevel,
    originatedFromDashboard,

    handleModalClose,
  } = props;
  const [workerOptions, setWorkerOptions] = useState<DropDownWithWorkerLevelOption[]>([]);
  const [workstationOrOrgUnitOptions, setWorkstationOrOrgUnitOptions] = useState<Tag<TreeNode>[]>(
    [],
  );
  const [levelOptions, setlevelOptions] = useState<DropDownWithWorkerLevelOption[]>([]);
  const [requiredTrainings, setRequiredTrainings] = useState<TrainingOption[]>();
  const [skillIdsWithoutTrainingSet, setSkillIdsWithoutTrainingSet] = useState<string[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [hideWorkerLevel, setHideWorkerLevel] = useState<boolean>(false);
  const [selectedTreeNode, setSelectedTreeNode] = useState<DropDownOption>();
  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();
  const history = useHistory();

  useEffect(() => {
    if (!openModal) return;

    fetchModalData();

    return () => {
      setWorkstationOrOrgUnitOptions([]);
      setWorkerOptions([]);
      setlevelOptions([]);
      setRequiredTrainings(undefined);
    };
  }, [openModal]);

  useEffect(() => {
    if (workerId && treeNodeId && trainingLevel) {
      getTrainings(workerId, treeNodeId, trainingLevel);
    }
  }, [workerId, treeNodeId, trainingLevel]);

  useEffect(() => {
    fetchWorkersWithLevels();
  }, [selectedTreeNode]);

  async function fetchWorkersWithLevels() {
    if (!selectedTreeNode) return;

    setLoader(true);

    const _workersOptions: DropDownWithWorkerLevelOption[] = [];
    let workers: API.Worker[] = [];
    let orgUnitId = '';

    const dataType = API.getDataType(selectedTreeNode.key);
    if (API.isFailure(dataType)) {
      logger.warn(dataType);
      setLoader(false);
      return;
    }
    if (dataType === API.DataType.WORKSTATION) {
      const data = await API.getWorkstation(selectedTreeNode.key);
      if (!isMounted.current) return;
      if (API.isFailure(data)) {
        logger.warn(data);
        setLoader(false);
        return;
      }
      orgUnitId = data.parentId;
    } else if (dataType === API.DataType.ORGUNIT) {
      orgUnitId = selectedTreeNode.key;
    }

    if (API.enableGlobalLevelComputation) {
      const _workers = await API.getWorkers([API.Permission.workerIsOperational]);
      if (!isMounted.current) return;
      if (API.isFailure(_workers)) {
        logger.warn(_workers);
        setLoader(false);
        return;
      }
      workers = _workers.result;
    } else {
      const _workers = await API.getWorkersInOrganizationalUnits(
        [orgUnitId],
        [API.Permission.workerIsOperational],
      );
      if (!isMounted.current) return;
      if (API.isFailure(_workers)) {
        logger.warn(_workers);
        setLoader(false);
        return;
      }
      workers = _workers.result;
    }

    if (dataType === API.DataType.WORKSTATION) {
      sortWorkers(workers).forEach(worker => {
        const _workerWorkstation = API.getWorkerWorkstations(selectedTreeNode.key, worker.id);
        if (!!_workerWorkstation?.level) {
          _workersOptions.push({
            key: worker.id,
            label: capitalizeFirstLetter(worker.name),
            value: worker,
            level: API.api2workstationWorkerLevels(_workerWorkstation?.level),
          });
        }
      });
      setHideWorkerLevel(false);
    } else if (dataType === API.DataType.ORGUNIT) {
      sortWorkers(workers).forEach(worker => {
        _workersOptions.push({
          key: worker.id,
          label: capitalizeFirstLetter(worker.name),
          value: worker,
          level: WorkstationWorkerLevels.LEVEL0,
        });
      });
      setHideWorkerLevel(true);
    }
    setLoader(false);

    setWorkerOptions(_workersOptions);
  }

  function fetchModalData() {
    const _rootNodes = API.Tree.getTopTreeNodes();
    const _workstationOrOrgUnitOptions: Tag<TreeNode>[] = [];

    _rootNodes.forEach(rootNode => {
      _workstationOrOrgUnitOptions.push(getTreeNodeTag(rootNode));
    });

    setWorkstationOrOrgUnitOptions(_workstationOrOrgUnitOptions);

    const _levelOption =
      trainingLevel && dropDownWorkerLevels[trainingLevel - 1]
        ? [dropDownWorkerLevels[trainingLevel - 1]] 
        : dropDownWorkerLevels;

    setlevelOptions(_levelOption);
  }

  async function getTrainings(
    workerId?: string,
    treeNodeId?: string,
    level?: API.WorkstationWorkerLevels,
  ): Promise<API.Result<void>> {
    if (!workerId || !treeNodeId || !level) {
      if (requiredTrainings) setRequiredTrainings(undefined);
      return;
    }

    let trainingVersions: Map<string, API.Requirement> = new Map();
    const _trainingVersions = await API.getTrainingVersionIdsForWorkerToReachLevel(
      workerId,
      treeNodeId,
      level,
    );

    if (!isMounted.current) return;
    if (API.isFailure(_trainingVersions)) {
      if (API.isFailureType(_trainingVersions, 'TrainingVersionNotSet')) {
        const errors = _trainingVersions.data;
        if (errors.neededTrainingVersionIds?.size) {
          trainingVersions = errors.neededTrainingVersionIds;
        }
        if (errors.skillIdsWithoutTrainingSet?.length) {
          setSkillIdsWithoutTrainingSet(errors.skillIdsWithoutTrainingSet);
        }
      } else {
        logger.error(_trainingVersions);
        return _trainingVersions;
      }
    } else {
      trainingVersions = _trainingVersions;
    }

    const _requiredTrainings: TrainingOption[] = [];
    const errors: API.Failure[] = [];

    await Promise.all(
      Array.from(trainingVersions).map(async ([trainingVersionId, req]) => {
        const isPractical = await API.isPracticalTrainingVersion(trainingVersionId);
        if (API.isFailure(isPractical)) {
          errors.push(isPractical);
          return;
        }

        const trainingVersion = await API.getTrainingVersion(trainingVersionId);
        if (API.isFailure(trainingVersion)) {
          errors.push(trainingVersion);
          return;
        }

        const training = await API.getTraining(trainingVersion.trainingId);
        if (API.isFailure(training)) {
          errors.push(training);
          return;
        }

        _requiredTrainings.push({
          training: training,
          trainingVersion: trainingVersion,
          isPractical: isPractical,
        });
      }),
    );
    if (!isMounted.current) return;
    if (errors.length > 0) {
      logger.error('WorkstationWorkerModalContainer: failed to get requiredTraining', errors);
    }

    setRequiredTrainings(_requiredTrainings);
  }

  const vetoModalClose = () => {
    modal.hideModal();
    history.push(RouteLocations.TrainingSessions());
    if (props.disableRedirection) handleModalClose();
  };

  function displayDuplicateTrainingSessionError(result?: API.Failure) {
    const dependencyList: string[] = [];
    if (result && result.data && _.isArray(result.data)) {
      _.forEach(result.data, dependency => {
        if (dependency.data)
          dependency.data.dependencyIds.forEach((_dependency: string) => {
            dependencyList.push(_dependency);
          });
      });

      ModalUtils.showVetoModal(
        dependencyList.length > 2
          ? t(
              'alex:mobile.practicalTrainingScreen.trainingSessionDuplicateError.2.trainingSessionAlreadyExist_plural',
            )
          : t(
              'alex:mobile.practicalTrainingScreen.trainingSessionDuplicateError.2.trainingSessionAlreadyExist',
            ),
        result,
        vetoModalClose,
        API.DataType.TRAININGSESSION,
        props.disableRedirection,
      );
    }
  }

  async function trainWorkerWithErrorHandling(
    workerId: string,
    workstationId: string,
    level: API.WorkstationWorkerLevels,
    trainingVersionIds?: string[],
  ): Promise<API.Result<void>> {
    setLoader(true);
    const result = await API.createTrainingSessionRequests(
      workstationId,
      workerId,
      level,
      true,
      trainingVersionIds,
    );

    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      if (API.isFailureType(result, 'DuplicateVeto')) {
        displayDuplicateTrainingSessionError(result);
      } else if (API.isFailureType(result, 'MultipleFailures')) {
        if (_.some(result.data, failure => API.isFailureType(failure, 'DuplicateVeto')))
          displayDuplicateTrainingSessionError(result);
      } else if (API.isFailureType(result, 'TrainingVersionNotSet')) {
        setLoader(false);
        handleModalClose();
        
      } else {
        showTrainingSessionErrorWarning(modal, TrainingSessionWarning.ErrorWarningForPractical);
        logger.error('error: training result', result);
      }
      setLoader(false);
      return;
    }

    setLoader(false);
    handleModalClose();
  }

  return (
    <>
      {openModal && (
        <TrainingModalComponent
          selectedTreeNode={selectedTreeNode}
          treeNodeId={treeNodeId}
          workerId={workerId}
          trainingLevel={trainingLevel}
          openModal={openModal}
          workerOptions={workerOptions}
          workstationOrOrgUnitOptions={workstationOrOrgUnitOptions}
          levelOptions={levelOptions}
          requiredTrainings={requiredTrainings}
          skillIdsWithoutTrainingSet={skillIdsWithoutTrainingSet}
          setSelectedTreeNode={setSelectedTreeNode}
          handleModalClose={handleModalClose}
          getTrainings={getTrainings}
          trainWorker={trainWorkerWithErrorHandling}
          hideWorkerLevel={hideWorkerLevel}
          originatedFromDashboard={originatedFromDashboard}
        />
      )}
      {loader && <Loader />}
    </>
  );
};
