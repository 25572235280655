import React from 'react';
import { TenantAppRoutes, AuthenticationRoutes } from './components/navigation/Routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppWrapper } from 'shared/AppWrapper';
import { ModalProvider } from 'shared/ui-component/Modal';
import { version } from '../package.json';

const App: React.FC<RouteComponentProps> = props => {
  return (
    <ModalProvider>
      <AppWrapper clientVersion={version} signInContent={<AuthenticationRoutes {...props} />} appContent={<TenantAppRoutes {...props} />} />
    </ModalProvider>
  );
};

export default withRouter(App);
