import React, { useState } from 'react';
import { Text, ScrollView, TouchableOpacity, View, ViewStyle } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import Styles from './Styles';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { FileRow } from 'shared/ui-component/AttachFile/FileRow';
import { Spacings } from 'shared/styles';

const AttachFile = require('shared/assets/svg/icon.attachment.svg').default;
const arrowDown = require('shared/assets/svg/icon.next.small.svg').default;

interface Props {
  files: readonly API.S3Object[];
  containerStyle?: ViewStyle;
}

export const FileExpand: React.FC<Props> = props => {
  const { files, containerStyle } = props;

  const [isFileListExpanded, setIsFileListExpanded] = useState(false);

  const filesCount = files.length;

  return (
    <View style={[Styles.fileExpandContainer, containerStyle]}>
      <TouchableOpacity
        style={Styles.touchableContainer}
        disabled={!filesCount}
        onPress={() => {
          setIsFileListExpanded(prev => !prev);
        }}
      >
        <View style={Styles.rowView}>
          <Text style={[Styles.fileCountTextStyle]}>{filesCount}</Text>
          <IconSVG
            svgComponent={AttachFile}
            size={{ width: 24, height: 24 }}
            containerStyle={{ width: 24, height: 24, marginLeft: Spacings.Unit }}
            color={SharedStyles.Colors.Grey}
          />
        </View>
        <IconSVG
          svgComponent={arrowDown}
          size={{ width: 24, height: 24 }}
          containerStyle={[
            Styles.trainingIconContainer,
            { transform: [{ rotate: isFileListExpanded ? '270deg' : '90deg' }] },
          ]}
          color={filesCount ? SharedStyles.Colors.Black : SharedStyles.Colors.Grey}
        />
      </TouchableOpacity>
      {isFileListExpanded && (
        <ScrollView style={{ paddingTop: Spacings.Unit * 5 }}>
          {_.map(files, (file, index) => {
            return <FileRow key={index} file={file} index={index} />;
          })}
        </ScrollView>
      )}
    </View>
  );
};
