import React, { useEffect, useState, useRef, useContext } from 'react';
import { View, TouchableOpacity, InteractionManager, ScrollView } from 'react-native';
import { Text } from 'react-native-web';
import WebModal from 'modal-react-native-web';
import Styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { ModalHeaderText } from 'shared/ui-component/Modal/ModalHeaderText';
import { Colors } from 'shared/styles';
import { InputText, ParserType } from 'shared/ui-component/Input';
import { t, i18n, capitalizeFirstLetter, getLanguage } from 'shared/localisation/i18n';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { Tag } from 'shared/ui-component/Input/InputTag';
import {
  ContractTypeDropDownOption,
  ProfilePictureFile,
  ContractWithContractType,
  ModifyWorkerInputIds,
} from '../container';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { WorkerArchiveORActivateModal } from '../../WorkerArchiveORActivateModal';
import { WorkerAssignmentDropDowns } from '../workerAssignmentDropDown/container';
import { ModalBackgroundStyle, ModalCardStyle, ModalHeaderStyle } from 'shared/styles/ModalStyles';
import { Spacings } from 'shared/styles/index';
import { ShadowOnHoverButton } from 'shared/ui-component/Button/ShadowOnHoverButton';
import { YellowButton, TextButton } from 'shared/ui-component/Button';
import { ContractRow } from './contractRow';
import { DropDownMultiSelection } from 'shared/ui-component/DropDown/DropDown';
import * as _ from 'lodash-es';
import { MyHub } from 'shared/util/MyHub';
import { useModal } from 'shared/ui-component/Modal/Modal';
import { ModalUtils } from 'shared/ui-component/Modal';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { tagErrorModal } from 'shared/util/warning';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;
const PhotoIcon = require('shared/assets/svg/icon.camera.avatar.svg').default;
const labelIcon = require('shared/assets/svg/icon.label.svg').default;
const InfoIcon = require('shared/assets/svg/icon.info.svg').default;

export interface ErrorOverlay {
  message: string;
  okButtonLabel: string;
  handleOk: () => void;
  handleCancel: () => void;
}

const imageStyle = {
  height: Spacings.Big + Spacings.Unit,
  width: Spacings.Big + Spacings.Unit,
  borderRadius: (Spacings.Big + Spacings.Unit) / 2,
  backgroundColor: Colors.FlashWhite,
  alignItems: 'center',
  justifyContent: 'center',
};

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface AddCollaboratorProps {
  data: {
    showLoader: boolean;
    profilePictureFile: ProfilePictureFile | null;
    contractTypeOptions: ContractTypeDropDownOption[];
    contracts: ContractWithContractType[];
    countryCode: string;
    editWorker: boolean; 
    worker?: API.Worker;
    workerTags: Tag[];
    indexedAssginments: API.IndexedAssignment[];
    showGDPRModal: boolean;
  };
  functions: {
    removeProfilePicture: () => void;
    onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleInputs: (inputName: string, value: string) => void;
    handleContractTypeOption: (data: ContractTypeDropDownOption | undefined, index: number) => void;
    handleCountrySelect: (value: string) => void;
    handleFormCancel: () => void;
    handleWorkerTagsInput: (inputId: string, teamData: Tag[] | undefined) => void;
    addSubmit: () => Promise<void>;
    editSubmit: () => Promise<void>;
    handleStartDateInput: (date: Date | null, index: number) => void;
    handleEndDateInput: (date: Date | null, index: number) => void;
    addNewContract: () => void;
    warnAdminOrInvitedWorker: () => void;
    archiveWorker: (worker: API.WorkerPartialUpdateInput) => void;
    deleteWorker: () => void;
    setDisplayWorkerModifyModal: (value: boolean) => void;
    activateWorker: () => void;
    setLoading: (load: boolean) => void;
    handleContractDelete: (index: number) => void;
    setAssignments: (asssignments: API.IndexedAssignment[]) => void;
    handleShowGDPRModal: () => void;
  };
}

export const AddEditWorkerModalComponent: React.FC<AddCollaboratorProps> = props => {
  const { data, functions } = props;

  const isMounted = useIsMounted();

  const { isValidPermission } = useContext(PermissionManagementContext);

  const language = getLanguage();

  const [workerTagsOptions, setWorkerTagsOptions] = useState<Tag[]>([]);
  const [activeArchiveButton, setActiveArchiveButton] = useState<boolean>(false);
  const [activeWorkerButton, setActiveWorkerButton] = useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [reinstateModal, setReinstateModal] = useState<boolean>(false);
  const [openWorkerTagMenu, setOpenWorkerTagMenu] = useState<boolean>(false);
  const [idExists, setIdExists] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneNumberNotValid, setPhoneNumberNotValid] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>();
  const isWorkerGDPRButtonAcknoledged = useRef<boolean>(false);
  const DGPRcheckBoxValue = useRef<boolean>(false);

  const { errors, onBlur, onChange, onSubmit, navigateInputField, refs } = useCustomForm<string>(
    {
      [ModifyWorkerInputIds.FirstName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
        initialValue: data.worker?.firstName,
      },
      [ModifyWorkerInputIds.LastName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
        initialValue: data.worker?.familyName,
      },
      [ModifyWorkerInputIds.Email]: {
        validator: [InputValidationType.Email],
        isFieldMandatory: data.worker?.matricule || idExists ? false : true,
        initialValue: data.worker?.email,
      },
      [ModifyWorkerInputIds.Phone]: {
        validator: [InputValidationType.PhoneNumber],
        isFieldMandatory: false,
        initialValue: data.worker?.phone,
      },
      [ModifyWorkerInputIds.RegistrationNumber]: {
        validator: [InputValidationType.WorkerPersonalIdPattern],
        isFieldMandatory: false,
        initialValue: data.worker?.matricule,
      },
      [ModifyWorkerInputIds.Notes]: {
        isFieldMandatory: false,
        initialValue: data.worker?.description,
      },
    },
    onSubmitForm,
  );
  const modal = useModal();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKERTAG) {
        getWorkerTagsOptions();
      }
    });

    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;

      getWorkerTagsOptions();
    });

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    if (language.locale === 'en') {
      setCountryCode('us');
    } else {
      setCountryCode(language.locale);
    }
  }, [language]);

  useEffect(() => {
    if (!data.showGDPRModal) {
      modal.hideModal();
    }
  }, [data.showGDPRModal]);

  useEffect(() => {
    if (data.worker?.state === API.WorkerState.ARCHIVED) {
      setActiveArchiveButton(false);
      setActiveWorkerButton(true);
    } else {
      setActiveArchiveButton(true);
      setActiveWorkerButton(false);
    }
  }, [data.worker, data.contracts]);

  const getWorkerTagsOptions = async () => {
    const workerTags = await API.getWorkerTags();
    if (!isMounted.current) return;
    if (API.isFailure(workerTags)) {
      logger.error(workerTags);
      return;
    }

    setWorkerTagsOptions(
      workerTags.map(workerTag => {
        return { key: workerTag.id, label: workerTag.name, editable: true };
      }),
    );
  };

  const handleArchiveOrReinstate = () => {
    setReinstateModal(true);
  };

  function handleDGPRCheckBox(value: boolean) {
    DGPRcheckBoxValue.current = value;
  }

  async function _handleDGPRButton() {
    if (DGPRcheckBoxValue.current) {
      const _result = await API.saveUserPreference(
        API.UserPreferenceKeys_Common.WorkerNoteGDPRWarning,
        true,
      );
      if (API.isFailure(_result)) {
        logger.error('Error while saving user preference', _result);
        return _result;
      }
    }

    functions.handleShowGDPRModal();
  }

  const handleInputs = (inputId: string, inputValue: string) => {
    onChange(inputId, inputValue);

    if (
      !isWorkerGDPRButtonAcknoledged.current &&
      data.showGDPRModal &&
      inputId === ModifyWorkerInputIds.Notes
    ) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('alex:workerProfile.workerNoteGDPRMessage'),
          isBlocking: true,
          warningAcceptButton: t('common:button.gotIt'),
          containerStyle: { height: 'auto' },
          checkBoxWarning: t('common:button.dontShowAgain'),
          checkBoxCallback: handleDGPRCheckBox,
          warningAcceptCallback: _handleDGPRButton,
        }),
      );
      isWorkerGDPRButtonAcknoledged.current = true;
    }

    functions.handleInputs(inputId, inputValue);
  };

  async function createWorkerTag(name: string) {
    const createdWorkerTag = await API.createWorkerTag({ name: name });
    if (API.isFailure(createdWorkerTag)) {
      tagErrorModal(modal);
      return createdWorkerTag;
    }

    return {
      ...createdWorkerTag,
      key: createdWorkerTag.id,
      label: createdWorkerTag.name,
    };
  }

  async function updateWorkerTag(tag: Tag): Promise<API.Result<void>> {
    const updatedWorkerTag = await API.updateWorkerTag({
      id: tag.key,
      name: tag.label,
    });
    if (API.isFailure(updatedWorkerTag)) {
      tagErrorModal(modal);
      return updatedWorkerTag;
    }
  }

  async function deleteWorkerTag(tag: Tag): Promise<void> {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:worker.addEditWorker.warnings.deleteWorkerTag'),
        warningAcceptButton: capitalizeFirstLetter(t('common:button.yes')),
        warningCancelButton: capitalizeFirstLetter(t('common:button.no')),
        warningAcceptCallback: () => _deleteWorkerTag(tag),
      }),
    );
  }

  function onSubmitForm() {
    if (data.editWorker) {
      functions.editSubmit();
    } else {
      functions.addSubmit();
    }
  }

  async function _deleteWorkerTag(tag: Tag): Promise<API.Result<void>> {
    const deletedWorkerTag = await API.deleteFactoryBusinessObject(tag.key);
    if (!isMounted.current) return;
    if (API.isFailure(deletedWorkerTag)) {
      if (API.isFailureType(deletedWorkerTag, 'DeletionVeto')) {
        ModalUtils.showVetoModal(
          i18n.t('alex:vetoModal.deleteWorkerTagNonManageableWorkers'),
          deletedWorkerTag,
        );
      }
      return deletedWorkerTag;
    }
  }

  async function handlerCreateWorkerTag(tag: Tag): Promise<API.Result<Tag<API.WorkerTag>>> {
    const createdWorkerTag = await API.createWorkerTag({ name: tag.label });
    if (API.isFailure(createdWorkerTag)) return createdWorkerTag;

    return { ...createdWorkerTag, label: createdWorkerTag.name, key: createdWorkerTag.id };
  }

  function isEmailNotEditable(worker?: API.Worker): boolean {
    return !!worker?.userId && !!worker.email;
  }

  function isPhoneInputDisabled(worker?: API.Worker): boolean {
    return !!worker?.userId && !!worker.phone;
  }

  function isCountryData(country: CountryData | {}): country is CountryData {
    const c = country as CountryData;
    return (
      c.countryCode !== undefined &&
      c.dialCode !== undefined &&
      c.format !== undefined &&
      c.name !== undefined
    );
  }

  return (
    <WebModal animationType="fade" transparent visible={true}>
      <View style={ModalBackgroundStyle}>
        <View style={[ModalCardStyle, Styles.cardContainerStyle]}>
          <View style={ModalHeaderStyle}>
            <input
              ref={inputFileRef}
              hidden
              id="file-input"
              type="file"
              accept="image/*"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => functions.onFileChange(e)}
            />
            <TouchableOpacity
              style={Styles.closeButtonIcon}
              onPress={() => functions.setDisplayWorkerModifyModal(false)}
            >
              <IconSVG svgComponent={closeIcon} />
            </TouchableOpacity>
            <ModalHeaderText
              title={
                t('alex:worker.addEditWorker.profileOf') +
                ' ' +
                t('glossary:worker', undefined, false)
              }
              invert
            />
          </View>
          <ScrollView>
            <View style={Styles.textInputContainer}>
              <View style={Styles.photoContainer}>
                <TouchableOpacity
                  style={Styles.photo}
                  onPress={() => inputFileRef.current?.click()}
                >
                  {data.profilePictureFile?.file ? (
                    <img
                      src={URL.createObjectURL(data.profilePictureFile.file)}
                      style={imageStyle}
                    />
                  ) : data.worker?.profilePicture ? (
                    renderImage(data.worker.profilePicture, ImageSizes.Large, data.worker.name)
                  ) : (
                    <IconSVG svgComponent={PhotoIcon} color={Colors.Grey} />
                  )}
                </TouchableOpacity>
              </View>
              {/**
               * This structure of 1 container ,containing multiple horizontal inner containers is important to give a horizontal TAB jumping between inputs
               * So whatever changes that needs to be done here, take care of that feature dear dev <3
               * And that solution was made because in react native TextInput component we don't have the tabindex property to pass
               */}
              <View style={Styles.textInputOuterContainer}>
                <View style={Styles.textRowContainer}>
                  <InputText
                    autoFocus
                    inputRef={refs[ModifyWorkerInputIds.FirstName]}
                    containerStyle={Styles.leftInputText}
                    placeHolder={t('common:account.firstName')}
                    defaultValue={data.worker?.firstName}
                    onTextChange={handleInputs}
                    inputId={ModifyWorkerInputIds.FirstName}
                    errorMessage={errors[ModifyWorkerInputIds.FirstName]?.inputErrorMessage}
                    onBlur={onBlur}
                    onSubmitEditing={() => {
                      navigateInputField(ModifyWorkerInputIds.LastName);
                    }}
                  />
                  <InputText
                    inputRef={refs[ModifyWorkerInputIds.LastName]}
                    containerStyle={Styles.inputText}
                    placeHolder={t('common:account.lastName')}
                    onTextChange={handleInputs}
                    defaultValue={data.worker?.familyName}
                    inputId={ModifyWorkerInputIds.LastName}
                    onBlur={onBlur}
                    errorMessage={errors[ModifyWorkerInputIds.LastName]?.inputErrorMessage}
                    onSubmitEditing={() => {
                      navigateInputField(ModifyWorkerInputIds.Email);
                    }}
                  />
                </View>
                <View style={Styles.textRowContainer}>
                  <InputText
                    inputRef={refs[ModifyWorkerInputIds.Email]}
                    containerStyle={Styles.leftInputText}
                    placeHolder={t('common:account.email')}
                    defaultValue={data.worker?.email ?? ''}
                    onTextChange={handleInputs}
                    inputId={ModifyWorkerInputIds.Email}
                    notEditable={isEmailNotEditable(data.worker)}
                    errorMessage={
                      idExists ? undefined : errors[ModifyWorkerInputIds.Email]?.inputErrorMessage
                    }
                    onSubmitEditing={() => {
                      navigateInputField(ModifyWorkerInputIds.Phone);
                    }}
                    parser={[ParserType.CaseInsensitive]}
                  />
                  <PhoneInput
                    country={countryCode}
                    inputStyle={{
                      width: 282,
                      borderColor: phoneNumberNotValid ? Colors.Red : Colors.GreyLight,
                    }}
                    containerStyle={{ marginTop: 30 }}
                    onBlur={(event, country: CountryData) => {
                      if (phoneNumber?.length !== country.format.length) {
                        setPhoneNumberNotValid(true);
                      } else {
                        setPhoneNumberNotValid(false);
                      }
                    }}
                    buttonStyle={{
                      borderTopLeftRadius: Spacings.Unit,
                      borderBottomLeftRadius: Spacings.Unit,
                      marginLeft: 1,
                      borderBlockColor: phoneNumberNotValid ? Colors.Red : Colors.GreyLight,
                    }}
                    disabled={isPhoneInputDisabled(data.worker)}
                    enableSearch
                    countryCodeEditable={false}
                    value={data.worker?.phone ?? ''}
                    onChange={(value, country, event, formattedValue) => {
                      setPhoneNumber(formattedValue);
                      if (isCountryData(country)) {
                        functions.handleCountrySelect('+' + country.dialCode);
                      }
                      handleInputs(ModifyWorkerInputIds.Phone, formattedValue);
                    }}
                  />
                </View>
                <View style={[Styles.textRowContainer, { zIndex: -1 }]}>
                  <InputText
                    inputRef={refs[ModifyWorkerInputIds.RegistrationNumber]}
                    containerStyle={Styles.leftInputText}
                    placeHolder={t('common:account.workerPersonalId')}
                    defaultValue={data.worker?.matricule ?? ''}
                    onTextChange={handleInputs}
                    inputId={ModifyWorkerInputIds.RegistrationNumber}
                    onBlur={(inputId: string, inputValue: string) => {
                      setIdExists(true);
                      onBlur(inputId, inputValue);
                    }}
                    errorMessage={
                      errors[ModifyWorkerInputIds.RegistrationNumber]?.inputErrorMessage
                    }
                    onSubmitEditing={() => {
                      setOpenWorkerTagMenu(true);
                    }}
                  />
                  <DropDownMultiSelection
                    openDropDownMenu={openWorkerTagMenu}
                    containerStyle={Styles.inputTag}
                    placeholder={t('alex:worker.addEditWorker.sections.3')}
                    title={t('alex:worker.addEditWorker.sections.3')}
                    tagTitle={t('glossary:workerTag')}
                    isTag
                    options={workerTagsOptions}
                    values={data.workerTags}
                    listIcon={labelIcon}
                    createNewOption={{
                      createItemIcon: labelIcon,
                      createItemPlaceHolder: t('alex:worker.addEditWorker.createWorkerTag'),
                      createItemHandler: handlerCreateWorkerTag,
                    }}
                    onTagCreate={createWorkerTag}
                    onTagEdit={updateWorkerTag}
                    onTagDelete={deleteWorkerTag}
                    handleChange={(inputId: string, teamData: Tag[] | undefined) => {
                      navigateInputField(ModifyWorkerInputIds.Notes);
                      functions.handleWorkerTagsInput(inputId, teamData);
                    }}
                  />
                </View>
                <InputTextDescription
                  inputRef={refs[ModifyWorkerInputIds.Notes]}
                  containerStyle={Styles.notes}
                  placeHolder={t('common:account.notes')}
                  defaultValue={data.worker?.description ?? ''}
                  onTextChange={handleInputs}
                  inputId={ModifyWorkerInputIds.Notes}
                  onBlur={onBlur}
                  errorMessage={errors[ModifyWorkerInputIds.Notes]?.inputErrorMessage}
                  onSubmitEditing={onSubmit}
                />
              </View>
            </View>
            <View style={Styles.contractSectionContainer}>
              <View style={Styles.contractTitleContainer}>
                <Text style={Styles.addIconLabel}>{t('common:worker.contract')}</Text>
                <ShadowOnHoverButton
                  size={Spacings.Standard}
                  iconSize={12}
                  onPress={functions.addNewContract}
                  iconContainerStyle={{
                    backgroundColor: Colors.Yellow,
                  }}
                />
                <View style={Styles.toolTip}>
                  <ToolTipWeb
                    component={<IconSVG svgComponent={InfoIcon} color={Colors.GreyLight} />}
                    title={t('alex:worker.addEditWorker.warnings.missingContract')}
                  />
                </View>
              </View>

              {!data.contracts.length ? (
                <Text style={Styles.addWorkerAndUnitText}>
                  {t('alex:worker.addEditWorker.addWorkContract')}
                </Text>
              ) : (
                data.contracts.map((contract, index) => {
                  if (contract.contractType.isPauseContract) return;
                  return (
                    <ContractRow
                      key={index}
                      contract={contract}
                      index={index}
                      contractOptions={data.contractTypeOptions}
                      handleContractTypeOption={functions.handleContractTypeOption}
                      handleStartDateInput={functions.handleStartDateInput}
                      handleEndDateInput={functions.handleEndDateInput}
                      handleContractDelete={functions.handleContractDelete}
                    />
                  );
                })
              )}
            </View>
            <View style={Styles.unitContainer}>
              <WorkerAssignmentDropDowns
                worker={data.worker}
                assignments={data.indexedAssginments}
                setAssignments={functions.setAssignments}
              />
            </View>
          </ScrollView>
          {data.editWorker &&
          data.worker &&
          !data.worker.email &&
          !data.worker.phone &&
          !data.worker.matricule ? (
            <View />
          ) : (
            <View style={[Styles.submitBtn]}>
              {!activeArchiveButton ? (
                <>
                  <TextButton
                    text={t('alex:worker.addEditWorker.deleteWorker')}
                    containerStyle={Styles.deleteButton}
                    onPress={functions.deleteWorker}
                    disabled={!isValidPermission(API.Permission.workers_edit)}
                  />
                  <YellowButton
                    text={t('glossary:workerReinstate')}
                    onPress={() => handleArchiveOrReinstate()}
                    textStyle={Styles.reAcitveButton}
                    style={{ height: 32, paddingHorizontal: 43 }}
                  />
                </>
              ) : (
                <>
                  {data.worker?.id && (
                    <Text style={Styles.button} onPress={() => handleArchiveOrReinstate()}>
                      {t('glossary:workerSuspend')}
                    </Text>
                  )}
                  <YellowButton
                    text={t('common:button.save')}
                    onPress={onSubmit}
                    style={{ height: 35, paddingHorizontal: 35 }}
                    textStyle={{ fontSize: 14 }}
                  />
                </>
              )}
            </View>
          )}
          {data.showLoader && <Loader />}
        </View>
        {data.worker && reinstateModal && (
          <WorkerArchiveORActivateModal
            setShowModal={setReinstateModal}
            worker={data.worker}
            successCallback={() => {
              if (!isMounted.current) return;
              functions.setDisplayWorkerModifyModal(false);
            }}
          />
        )}
      </View>
    </WebModal>
  );
};
