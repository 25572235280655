import Fonts from './Fonts';
import { TextStyle, Platform } from 'react-native';

interface TextStyles {
  LatoNormal: TextStyle;
  LatoRegular: TextStyle;
  LatoMedium: TextStyle;
  LatoBold: TextStyle;
  LatoBlack: TextStyle;
  PermanentMarker: TextStyle;
  SemiBold?: TextStyle;
}

const webFontFamily = {
  LatoNormal: {
    fontFamily: Fonts.Lato.LatoWeb,
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  LatoRegular: {
    fontFamily: Fonts.Lato.LatoWeb,
    fontStyle: 'normal',
    fontWeight: '400',
  },
  LatoMedium: {
    fontFamily: Fonts.Lato.LatoWeb,
    fontStyle: 'normal',
    fontWeight: '500',
  },
  LatoBold: {
    fontFamily: Fonts.Lato.LatoWeb,
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  LatoBlack: {
    fontFamily: Fonts.Lato.LatoWeb,
    fontStyle: 'normal',
    fontWeight: '900',
  },
  PermanentMarker: {
    fontFamily: Fonts.PermanentMarker,
    fontStyle: 'normal',
    fontWeight: '400',
  },
  SemiBold: {
    fontFamily: Fonts.Lato.LatoWeb,
    fontStyle: 'normal',
    fontWeight: '600',
  },
};

const mobileFontFamily = {
  LatoNormal: {
    fontFamily: Fonts.Lato.LatoWeb,
  },
  LatoRegular: {
    fontFamily: Fonts.Lato.Regular,
  },
  LatoMedium: {
    fontFamily: Fonts.Lato.Medium,
  },
  LatoBold: {
    fontFamily: Fonts.Lato.Bold,
  },
  LatoBlack: {
    fontFamily: Fonts.Lato.Black,
  },
  PermanentMarker: {
    fontFamily: Fonts.PermanentMarker,
  },
};

export const TextFontStyles: TextStyles = Platform.OS === 'web' ? webFontFamily : mobileFontFamily;
