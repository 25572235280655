import { View, Animated, Easing, Dimensions, InteractionManager } from 'react-native';
import React, { useEffect, useRef, useContext, useState, useCallback } from 'react';
import styles from './styles';
import { VersatilityPanel } from './versatility-panel/VersatilityPanel';
import { SkillValidatePanelContainer } from './skill-validate-panel/skill-validate/container';
import { TrainingInfoPanelContainer } from './training-info-panel/container/TrainingInfoPanel';
import { RouteComponentProps } from 'react-router-dom';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { getLanguage, t } from 'shared/localisation/i18n';
import {
  DashboardFilterContext,
  ParentWithWorkers,
} from 'shared/context/dashboard-context/DashboardFilterContext';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { getWorkerWorkstations, sortWorkers } from 'shared/backend-data';
import * as _ from 'lodash-es';
import {
  DropdownConfigKey,
  HomeOtherFilterKey,
  removeInvalidObjectFromUserPreference,
  CheckBoxConfigKey,
} from 'skillmgtweb/src/components/header-layout/headerFilterConfig';
import * as API from 'shared/backend-data';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import Aigle from 'aigle';
import { isArrayEmpty } from 'shared/util-ts/Functions';
import { WorkerTrainingStatusForFilter } from '../header-layout/HeaderLayout';
import { MyHub } from 'shared/util/MyHub';

interface DashboardProps extends RouteComponentProps {
  versatilityPanelFullScreenHandler: React.Dispatch<React.SetStateAction<boolean>>;
  versatilityPanelFullScreen: boolean;
}

interface WorkerWorkstationFilterData {
  workers: API.Worker[];
  workstations: API.Workstation[];
}

const updateDataDebounceInMs = 1500;

export const Dashboard: React.FC<DashboardProps> = props => {
  const { versatilityPanelFullScreen, versatilityPanelFullScreenHandler } = props;

  const isMounted = useIsMounted();

  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);
  const {
    workers: [, setWorkers],
    workstations: [, setWorkstations],
    workersByParentWithDetails: [, setWorkersByShiftsOrOrgUnit],
  } = useContext(DashboardFilterContext);
  const {
    workstationTargetsStartingAtLevel,
    isWorkerLastNameFirst,
    dashboardWorkersSortDirection,
  } = useContext(GlobalDataContext);

  const locale = getLanguage().locale;

  const [windowHeight, setWindowHeight] = useState<number>(Dimensions.get('window').height);
  const [windowWidth, setWindowWidth] = useState<number>(Dimensions.get('window').width);

  const [allWorkstations, setAllWorkstations] = useState<API.Workstation[]>();
  const [allWorkers, setAllWorkers] = useState<API.Worker[]>();

  const [computeToDoList, setComputeToDoList] = useState<boolean>(false);
  const [validatePermissionOnSubUnits, setValidatePermissionOnSubUnits] = useState<boolean>();

  const {
    homeScreenFilterTags: [homeScreenFilterTags, setHomeScreenFilterTags],
    currentRoute: [, setCurrentRoute],
  } = useContext(HeaderFilterContext);

  const debouncedUpdateData = useCallback(
    _.debounce(updateData, updateDataDebounceInMs, { leading: false, trailing: true }),
    [],
  );

  const paddingLeftAndRight = useRef(new Animated.Value(20)).current;
  const paddingBottom = useRef(new Animated.Value(8)).current;

  useEffect(() => {
    if (locale === 'de') {
      setFirstTitle(t('glossary:workerVersatility', undefined, false));
      setSecondTitle(t('alex:header.manage'));
    } else {
      setFirstTitle(t('alex:header.manage'));
      setSecondTitle(t('glossary:workerVersatility', undefined, false));
    }

    setCurrentRoute(RoutePaths.Home);

    const resizeListener = () => {
      setWindowWidth(Dimensions.get('window').width);
      setWindowHeight(Dimensions.get('window').height);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      setCurrentRoute(undefined);
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    handlePadding(versatilityPanelFullScreen);
  }, [props.versatilityPanelFullScreen]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async payload => {
      switch (true) {
        case payload.data.factory.dataType === API.DataType.WORKER:
          await fetchAllOperationalWorkers();
          break;

        case payload.data.factory.dataType === API.DataType.WORKSTATION:
          fetchAllWorkstations();
          break;
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      handleUserPreference();

      fetchAllWorkstations();

      await fetchAllOperationalWorkers();
      if (!isMounted.current) return;
    });

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      debouncedUpdateData(
        homeScreenFilterTags,
        allWorkers,
        allWorkstations,
        isWorkerLastNameFirst,
        dashboardWorkersSortDirection,
      );
    });
  }, [
    homeScreenFilterTags,
    allWorkers,
    allWorkstations,
    isWorkerLastNameFirst,
    dashboardWorkersSortDirection,
  ]);

  async function fetchAllOperationalWorkers() {
    const _workers = await API.getWorkers([API.Permission.workerIsOperational]);
    if (!isMounted.current) return _workers;
    if (API.isFailure(_workers)) return _workers;
    setAllWorkers(_workers.result);
  }

  function fetchAllWorkstations() {
    const _workstations = API.getWorkstations(undefined, false, true);
    if (API.isFailure(_workstations)) return _workstations;
    setAllWorkstations(_workstations);
  }

  async function handleUserPreference() {
    const userPreference = await API.getUserPreference<Map<string, TagExtended[]>>(
      UserPreferenceKeys_SkillMgtApp.DashboardFilter,
    );
    if (!isMounted.current) return;
    if (API.isFailure(userPreference)) {
      logger.warn('fetch dashboard filter: error in fetch user Preference', userPreference);
      return;
    }

    if (userPreference) {
      const userPreferenceData = await removeInvalidObjectFromUserPreference(
        UserPreferenceKeys_SkillMgtApp.DashboardFilter,
        userPreference,
      );
      if (!isMounted.current) return;
      const saved = await API.saveUserPreference(
        UserPreferenceKeys_SkillMgtApp.DashboardFilter,
        userPreferenceData,
      );
      if (!isMounted.current) return;
      if (API.isFailure(saved)) {
        logger.warn('save dashboard filter: error while saving user Preference', saved);
        return;
      }
      setHomeScreenFilterTags(
        userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.DashboardFilter) ?? [],
      );
    }
  }

  async function filterData(
    isKeywordFiltering: boolean,
    filterTags: TagExtended[],
    workers: API.Worker[],
    workstations: API.Workstation[],
  ): Promise<WorkerWorkstationFilterData> {
    if (isKeywordFiltering && isArrayEmpty(filterTags)) {
      return {
        workers: [],
        workstations: [],
      };
    }

    let filteredWorkers: API.Worker[] = isKeywordFiltering ? [] : workers;
    let filteredWorkstations: API.Workstation[] = isKeywordFiltering ? [] : workstations;
    let updateWorker: boolean = false;
    let updateWorkstation: boolean = false;
    let showSubUnits = true;
    let showSelectedUnits = false;

    const checkBoxTag = filterTags.find(tag => tag.key === CheckBoxConfigKey.SHOW_SUB_UNITS);
    if (checkBoxTag) {
      showSubUnits = checkBoxTag.value;
    }
    setValidatePermissionOnSubUnits(showSubUnits);

    const _checkBoxTag = filterTags.find(
      tag => tag.key === CheckBoxConfigKey.SHOW_SELECTED_ORG_UNITS,
    );
    if (_checkBoxTag) {
      showSelectedUnits = _checkBoxTag.value;
    }

    const shiftFilters = filterTags.filter(eachTag => eachTag.type === DropdownConfigKey.SHIFT);
    const orgUnitFilters = filterTags.filter(eachTag => eachTag.type === DropdownConfigKey.ORGUNIT);
    const workerFilters = filterTags.filter(eachTag => eachTag.type === DropdownConfigKey.WORKER);
    const workstationFilters = filterTags.filter(
      eachTag => eachTag.type === DropdownConfigKey.WORKSTATION,
    );
    const workerTagsFilter = filterTags.filter(
      eachTag => eachTag.type === DropdownConfigKey.WORKER_TAG,
    );
    const levelTagFilters = filterTags.filter(eachTag => eachTag.type === DropdownConfigKey.LEVEL);
    const otherFilters = filterTags.filter(
      eachTag => eachTag.type === DropdownConfigKey.HOME_OTHER_FILTER,
    );

    
    
    if (shiftFilters.length) {
      await Aigle.mapSeries(shiftFilters, async eachFilter => {
        orgUnitFilters.push({
          key: eachFilter.value.parentId,
          label: '',
          type: API.DataType.SHIFT,
          value: eachFilter.value,
        });
      });
    }

    
    if (orgUnitFilters.length) {
      updateWorkstation = true;
      updateWorker = true;

      const workstationsGotFromOUs: API.Workstation[] = [];
      const workersGotFromOUs: API.Worker[] = [];

      await Aigle.mapSeries(orgUnitFilters, async eachTag => {
        const _filteredWorkstations = API.getWorkstations([eachTag.key], showSubUnits);
        workstationsGotFromOUs.push(..._filteredWorkstations);

        if (eachTag.type === API.DataType.SHIFT) {
          const _filteredWorkers = await API.getWorkersInShift(
            eachTag.value.shiftId,
            eachTag.value.parentId,
            undefined,
            !showSelectedUnits,
            false,
            [API.Permission.workerIsOperational],
          );
          if (!isMounted.current) return;
          if (API.isFailure(_filteredWorkers)) {
            logger.warn(_filteredWorkers);
            return;
          }

          workersGotFromOUs.push(..._filteredWorkers);
        } else {
          const _filteredWorkers = await API.getWorkersInOrganizationalUnits(
            [eachTag.key],
            [API.Permission.workerIsOperational],
            showSubUnits,
            !showSelectedUnits,
          );
          if (!isMounted.current) return;
          if (API.isFailure(_filteredWorkers)) {
            logger.warn(_filteredWorkers);
            return;
          }
          workersGotFromOUs.push(..._filteredWorkers.result);
        }
      });

      if (isKeywordFiltering) {
        filteredWorkers = workersGotFromOUs;
        filteredWorkstations = workstationsGotFromOUs;
      } else {
        filteredWorkers = _.filter(
          filteredWorkers,
          _worker => !!workersGotFromOUs.find(__worker => __worker.id === _worker.id),
        );

        filteredWorkstations = _.filter(
          filteredWorkstations,
          _workstation =>
            !!workstationsGotFromOUs.find(__workstation => __workstation.id === _workstation.id),
        );
      }
    }

    
    if (workstationFilters.length) {
      updateWorkstation = true;
      updateWorker = true;
      const workersGotFromWorkstation: API.Worker[] = [];

      const data = _.filter(
        isKeywordFiltering ? workstations : filteredWorkstations,
        eachWorkstation =>
          _.some(workstationFilters, eachFilter => eachFilter.key === eachWorkstation.id),
      );

      await Aigle.mapSeries(data, async workstation => {
        const orgUnit = API.Tree.getParent(workstation.id);
        if (API.isFailure(orgUnit)) {
          logger.warn(orgUnit);
          return;
        }
        if (orgUnit) {
          const _filteredWorkers = await API.getWorkersInOrganizationalUnits(
            [orgUnit.id],
            [API.Permission.workerIsOperational],
            showSubUnits,
            !showSelectedUnits,
          );
          if (!isMounted.current) return;
          if (API.isFailure(_filteredWorkers)) {
            logger.warn(_filteredWorkers);
            return;
          }

          workersGotFromWorkstation.push(..._filteredWorkers.result);
        }
      });

      if (isKeywordFiltering) {
        filteredWorkers = [...filteredWorkers, ...workersGotFromWorkstation];
        filteredWorkstations = [...filteredWorkstations, ...data];
      } else {
        filteredWorkstations = data;
        filteredWorkers = workersGotFromWorkstation;
      }
    }

    
    if (workerFilters.length) {
      updateWorker = true;

      const data = _.filter(isKeywordFiltering ? workers : filteredWorkers, eachWorker =>
        _.some(workerFilters, eachFilter => eachFilter.key === eachWorker.id),
      );

      if (isKeywordFiltering) {
        filteredWorkers = [...filteredWorkers, ...data];
      } else {
        filteredWorkers = data;
      }
    }

    
    if (workerTagsFilter.length) {
      updateWorker = true;

      const data = (isKeywordFiltering ? workers : filteredWorkers).filter(eachWorker =>
        _.some(workerTagsFilter, eachFilter =>
          _.some(eachWorker.tagIds, eachTag => eachTag === eachFilter.key),
        ),
      );

      if (isKeywordFiltering) {
        filteredWorkers = [...filteredWorkers, ...data];
      } else {
        filteredWorkers = data;
      }
    }

    
    if (levelTagFilters.length) {
      updateWorker = true;
      updateWorkstation = true;

      const _workstationIds = (isKeywordFiltering ? workstations : filteredWorkstations).map(
        eachWorkstation => eachWorkstation.id,
      );
      const _workerIds = (isKeywordFiltering ? workers : filteredWorkers).map(
        eachWorker => eachWorker.id,
      );
      const workersGotFromWorkerWorkstationObject: API.Worker[] = [];
      const workstationsGotFromWorkerWorkstationObject: API.Workstation[] = [];

      _workstationIds.forEach(workstationId => {
        _workerIds.forEach(workerId => {
          const workerWorkstation = getWorkerWorkstations(workstationId, workerId);
          levelTagFilters.forEach(eachTag => {
            if (
              workerWorkstation &&
              (workerWorkstation.level === eachTag.key ||
                (API.isWorkerInTrainingOnWorkstation(workerWorkstation) &&
                  eachTag.key === WorkerTrainingStatusForFilter.TRAIN))
            ) {
              const workstation = filteredWorkstations.find(
                eachWorkstation => eachWorkstation.id === workerWorkstation.workstationId,
              );
              const worker = filteredWorkers.find(
                eachWorker => eachWorker.id === workerWorkstation.workerId,
              );
              if (workstation) workstationsGotFromWorkerWorkstationObject.push(workstation);
              if (worker) workersGotFromWorkerWorkstationObject.push(worker);
            }
          });
        });
      });

      if (isKeywordFiltering) {
        filteredWorkers = [...filteredWorkers, ...workersGotFromWorkerWorkstationObject];
        filteredWorkstations = [
          ...filteredWorkstations,
          ...workstationsGotFromWorkerWorkstationObject,
        ];
      } else {
        filteredWorkers = _.uniqBy(workersGotFromWorkerWorkstationObject, 'id');
        filteredWorkstations = _.uniqBy(workstationsGotFromWorkerWorkstationObject, 'id');
      }
    }

    
    if (otherFilters.length) {
      updateWorker = true;
      updateWorkstation = true;

      const workersGotFromWorkerWorkstationObject: API.Worker[] = [];
      const workstationsGotFromWorkerWorkstationObject: API.Workstation[] = [];

      _.map(otherFilters, async eachTag => {
        _.map(isKeywordFiltering ? workstations : filteredWorkstations, async eachWorkstation => {
          _.map(isKeywordFiltering ? workers : filteredWorkers, async eachWorker => {
            const workerWorkstation = getWorkerWorkstations(eachWorkstation.id, eachWorker.id);

            if (workerWorkstation) {
              if (
                workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON &&
                eachTag.key === HomeOtherFilterKey.ATLEAST_ONE_SKILL_EXPIRING_SOON
              ) {
                workersGotFromWorkerWorkstationObject.push(eachWorker);
                workstationsGotFromWorkerWorkstationObject.push(eachWorkstation);
              } else if (
                workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRED &&
                eachTag.key === HomeOtherFilterKey.ATLEAST_ONE_SKILL_EXPIRED
              ) {
                workersGotFromWorkerWorkstationObject.push(eachWorker);
                workstationsGotFromWorkerWorkstationObject.push(eachWorkstation);
              }
            }
          });
        });
      });

      if (isKeywordFiltering) {
        filteredWorkers = [...filteredWorkers, ...workersGotFromWorkerWorkstationObject];
        filteredWorkstations = [
          ...filteredWorkstations,
          ...workstationsGotFromWorkerWorkstationObject,
        ];
      } else {
        filteredWorkers = _.uniqBy(workersGotFromWorkerWorkstationObject, 'id');
        filteredWorkstations = _.uniqBy(workstationsGotFromWorkerWorkstationObject, 'id');
      }
    }

    if (!updateWorker) {
      filteredWorkers = workers ?? [];
    }
    if (!updateWorkstation) {
      filteredWorkstations = workstations ?? [];
    }

    return {
      workers: filteredWorkers,
      workstations: filteredWorkstations,
    };
  }

  async function updateData(
    homeScreenFilterTags: TagExtended[],
    allWorkers: API.Worker[] | undefined,
    allWorkstations: API.Workstation[] | undefined,
    isWorkerLastNameFirst: boolean,
    dashboardWorkersSortDirection?: API.SortDirection,
  ) {
    if (!allWorkers || !allWorkstations) return;

    let filteredWorkers = allWorkers;
    let filteredWorkstations = allWorkstations;

    let filterTags: TagExtended[] = [];
    let keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(homeScreenFilterTags, tag => {
      if (tag.isActiveBookmarkTag) {
        filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      const data = await filterData(true, keywordTags, filteredWorkers, filteredWorkstations);
      if (!isMounted.current) return;

      filteredWorkers = data.workers;
      filteredWorkstations = data.workstations;
    }

    if (filterTags.length) {
      const data = await filterData(false, filterTags, filteredWorkers, filteredWorkstations);
      if (!isMounted.current) return;

      filteredWorkers = data.workers;
      filteredWorkstations = data.workstations;
    }

    const workers = sortWorkers(_.uniqBy(filteredWorkers, 'id'));
    setWorkers(workers);

    const workstations = _.uniqBy(filteredWorkstations, 'id');
    setWorkstations(workstations);

    const shiftFilters = filterTags.filter(eachTag => eachTag.type === DropdownConfigKey.SHIFT);
    const orgUnitFilters = filterTags.filter(eachTag => eachTag.type === DropdownConfigKey.ORGUNIT);

    let showSubUnits = true;
    const checkBoxTag = filterTags.find(tag => tag.key === CheckBoxConfigKey.SHOW_SUB_UNITS);
    if (checkBoxTag) {
      showSubUnits = checkBoxTag.value;
    }

    let showOnlySelectedUnits = false;
    const _checkBoxTag = filterTags.find(
      tag => tag.key === CheckBoxConfigKey.SHOW_SELECTED_ORG_UNITS,
    );
    if (_checkBoxTag) {
      showOnlySelectedUnits = _checkBoxTag.value;
    }

    return groupWorkersByShift(
      workers,
      workstations,
      shiftFilters,
      orgUnitFilters,
      showSubUnits,
      !showOnlySelectedUnits,
      isWorkerLastNameFirst,
      dashboardWorkersSortDirection,
    );
  }

  async function groupWorkersByShift(
    filteredWorkers: API.Worker[],
    filteredWorkstations: API.Workstation[],
    shiftFilters: TagExtended[],
    orgUnitFilters: TagExtended[],
    includeNested: boolean,
    includeInherited: boolean,
    isWorkerLastNameFirst: boolean,
    dashboardWorkersSortDirection?: API.SortDirection,
  ) {
    const t0 = Date.now();

    const workstationsWithNoShifts = _.filter(filteredWorkstations, _workstation => {
      return !_workstation.shiftIds?.length;
    });

    const workerIdsByShiftIdOrOrgUnit = new Map<string, Set<string>>();

    const shifts = await API.getShifts();
    if (API.isFailure(shifts)) return shifts;

    const _orgUnitIds = orgUnitFilters.map(orgUnit => orgUnit.key);
    const _parentsIds: string[] = [];
    const childrenIds: string[] = [];
    if (includeInherited) {
      _orgUnitIds.forEach(organizationalUnitId => {
        const treeNode = API.Tree.getTreeNode(organizationalUnitId);
        if (API.isFailure(treeNode)) {
          logger.error(
            'WARNING getOrgUnits is returning partial result: ' + treeNode.message,
            treeNode,
          );
          return;
        }
        _parentsIds.push(...treeNode.parentIds);
      });
    }

    if (includeNested) {
      _orgUnitIds.forEach(organizationalUnitId => {
        const children = API.Tree.getChildren(
          organizationalUnitId,
          includeNested,
          API.DataType.ORGUNIT,
        );
        if (API.isFailure(children)) {
          logger.error(
            'WARNING getOrgUnits is returning partial result: ' + children.message,
            children,
          );
          return;
        }
        childrenIds.push(...children.map(orgUnit => orgUnit.id));
      });
    }

    const filteredAssignments = await API.getWorkersAssignments(
      true,
      false,
      filteredWorkers.map(worker => worker.id),
      _orgUnitIds.length ? _.uniq([..._orgUnitIds, ..._parentsIds, ...childrenIds]) : undefined,
    );
    if (!isMounted.current) return;
    if (API.isFailure(filteredAssignments)) return filteredAssignments;

    Array.from(filteredAssignments.entries()).forEach(([workerId, value]) => {
      value.forEach(_inheritedOrNonInheritedAssignment => {
        if (_inheritedOrNonInheritedAssignment.inherited) return;

        let shiftIds = new Set<string>();
        if (_inheritedOrNonInheritedAssignment.shift) {
          shiftIds.add(_inheritedOrNonInheritedAssignment.shift.id);
        } else {
          
          
          if (workstationsWithNoShifts.length) {
            const isWorkerAssignedToAtLeastOneWorkstation = _.some(workstationsWithNoShifts, ws => {
              const workerWorkstation = getWorkerWorkstations(ws.id, workerId);
              return Boolean(workerWorkstation);
            });
            if (isWorkerAssignedToAtLeastOneWorkstation) {
              const workersAssignedToOrgUnits = workerIdsByShiftIdOrOrgUnit.get(
                API.DataType.ORGUNIT,
              );

              if (workersAssignedToOrgUnits) workersAssignedToOrgUnits.add(workerId);
              else workerIdsByShiftIdOrOrgUnit.set(API.DataType.ORGUNIT, new Set([workerId]));
            }
          }

          const node = API.Tree.getTreeNode<API.DataType.ORGUNIT>(
            _inheritedOrNonInheritedAssignment.organizationalUnitId,
          );
          if (API.isFailure(node)) {
            logger.error('Failed to fetch OrgUnit ', node);
          } else {
            node.object.shiftIds?.forEach(shiftId => {
              shiftIds.add(shiftId);
            });
          }
        }

        shiftIds.forEach(shiftId => {
          if (
            !shiftFilters.length ||
            shiftFilters.some(_shiftFilter => _shiftFilter.value.shiftId === shiftId)
          ) {
            const workersAssignedToShift = workerIdsByShiftIdOrOrgUnit.get(shiftId);

            if (workersAssignedToShift) workersAssignedToShift.add(workerId);
            else workerIdsByShiftIdOrOrgUnit.set(shiftId, new Set([workerId]));
          }
        });
      });
    });

    filteredWorkers.forEach(worker => {
      let workerFound = false;
      Array.from(workerIdsByShiftIdOrOrgUnit.entries()).forEach(([shiftIdOrOrgUnit, workerIds]) => {
        workerIds.forEach(workerId => {
          if (workerId === worker.id) {
            workerFound = true;
            return;
          }
        });
      });

      if (!workerFound) {
        const workersAssignedToOrgUnits = workerIdsByShiftIdOrOrgUnit.get(API.DataType.ORGUNIT);

        if (workersAssignedToOrgUnits) workersAssignedToOrgUnits.add(worker.id);
        else workerIdsByShiftIdOrOrgUnit.set(API.DataType.ORGUNIT, new Set([worker.id]));
      }
    });

    let _workersByParent = new Map<string, ParentWithWorkers>();
    let shiftsAndOrgUnitCounter = 0;
    let workersCounter = 0;

    const treeNodes = API.Tree.getFlatAndOrderedTree();
    let shiftsInOrder = new Set<string>();

    shiftsInOrder.add(API.DataType.ORGUNIT); 
    
    treeNodes.forEach(treeNode => {
      if (API.getDataType(treeNode.id) === API.DataType.ORGUNIT && treeNode.object.shiftIds) {
        treeNode.object.shiftIds.forEach(shiftId => shiftsInOrder.add(shiftId));
      }
    });

    Array.from(workerIdsByShiftIdOrOrgUnit.entries()).forEach(([shiftIdOrOrgUnit, workerIds]) => {
      const workers: API.Worker[] = [];
      workerIds.forEach(workerId => {
        const _worker = filteredWorkers.find(__worker => __worker.id === workerId);
        if (_worker) workers.push(_worker);
        else logger.error('Failed to find worker among filtered workers');
      });

      if (API.getDataType(shiftIdOrOrgUnit) === API.DataType.SHIFT) {
        const shift = shifts.find(_shift => _shift.id === shiftIdOrOrgUnit);

        if (shift) {
          shiftsAndOrgUnitCounter++;
          workersCounter = workersCounter + workers.length;

          _workersByParent.set(shiftIdOrOrgUnit, {
            workers: sortWorkers(workers, dashboardWorkersSortDirection, isWorkerLastNameFirst),
            shift: shift,
          });
        } else logger.error('Failed to find shift among filtered shifts');
      } else {
        shiftsAndOrgUnitCounter++;
        workersCounter = workersCounter + workers.length;

        _workersByParent.set(shiftIdOrOrgUnit, {
          workers: sortWorkers(workers, dashboardWorkersSortDirection, isWorkerLastNameFirst),
          shift: undefined,
        });
      }
    });

    let sortedWorkersByShiftsOrOrgUnit = new Map<string, ParentWithWorkers>();

    shiftsInOrder.forEach(shiftId => {
      Array.from(_workersByParent.entries()).forEach(([shiftIdOrOrgUnit, value]) => {
        if (shiftId === shiftIdOrOrgUnit) {
          sortedWorkersByShiftsOrOrgUnit.set(shiftIdOrOrgUnit, value);
        }
      });
    });

    const t1 = Date.now();
    logger.info(' workersByShiftsOrOrgUnit => ', ' took in ms ', t1 - t0);

    setWorkersByShiftsOrOrgUnit({
      workersByParents: new Map(sortedWorkersByShiftsOrOrgUnit),
      shiftsAndOrgUnitCount: shiftsAndOrgUnitCounter,
      workersCount: workersCounter,
    });
  }

  const handlePadding = (bool: boolean) => {
    Animated.timing(paddingLeftAndRight, {
      useNativeDriver: false,
      toValue: bool ? 0 : 20,
      duration: bool ? 1200 : 800,
      easing: Easing.elastic(1),
    }).start();
    Animated.timing(paddingBottom, {
      useNativeDriver: false,
      toValue: bool ? 0 : 8,
      duration: bool ? 1200 : 800,
      easing: Easing.elastic(1),
    }).start();
  };

  return (
    <Animated.View
      style={[
        styles.container,
        {
          paddingLeft: paddingLeftAndRight,
          paddingRight: paddingLeftAndRight,
          paddingBottom: paddingBottom,
        },
        props.versatilityPanelFullScreen ? { width: windowWidth, height: windowHeight } : {},
        props.versatilityPanelFullScreen ? styles.containerFullScreen : {},
      ]}
    >
      <View
        style={[
          styles.mainCnt,
          props.versatilityPanelFullScreen ? styles.mainContainerFullScreen : {},
        ]}
      >
        <View
          style={[
            styles.leftSide,
            props.versatilityPanelFullScreen ? styles.lefSideFullScreen : {},
          ]}
        >
          <VersatilityPanel
            versatilityPanelFullScreen={props.versatilityPanelFullScreen}
            workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
            setComputeToDoList={setComputeToDoList}
            versatilityPanelFullScreenHandler={versatilityPanelFullScreenHandler}
          />
        </View>
        {props.versatilityPanelFullScreen ? (
          <></>
        ) : (
          <View style={styles.rightContainer}>
            <View style={styles.trainingPanelContainer}>
              <TrainingInfoPanelContainer
                validatePermissionOnSubUnits={validatePermissionOnSubUnits}
              />
            </View>
            <View style={styles.skillValidateContainer}>
              <SkillValidatePanelContainer computeToDoList={computeToDoList} />
            </View>
          </View>
        )}
      </View>
    </Animated.View>
  );
};
