import React, { useState, useEffect, useContext } from 'react';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { View, TouchableOpacity, Text, TextStyle } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import styles from './Style';
import { Colors } from 'shared/styles';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { AppFeature } from 'shared/backend-data';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from './Routes';

export interface Route {
  routeKeyPath: RoutePaths;
  subRouteKeypath?: RoutePaths[];
  routeKeyTitle: string;
  routeKeyTitleStyle?: TextStyle;
  routeKeyIcon?: any;
  routeKeyNumber?: number;
  handleSelect: (path: RoutePaths) => void;
}
interface Props extends Route {
  selectedRouteKeyPath: RoutePaths;
  routeKeyIconSVGSize?: { width: number; height: number };
  drawerOpened: boolean;
  handleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  /** Check the specified Feature is available  */
  requireFeature?: AppFeature;
}

export const RouteKey: React.FC<Props> = props => {
  const {
    routeKeyPath,
    subRouteKeypath,
    routeKeyTitle,
    routeKeyTitleStyle,
    routeKeyIcon,
    routeKeyNumber,
    handleSelect,
    selectedRouteKeyPath,
    routeKeyIconSVGSize,
    drawerOpened,
    handleDrawer,
    requireFeature,
  } = props;

  const { features } = useContext(GlobalDataContext);

  const modal = useModal();
  const [hoveringState, setHoveringState] = useState(false);
  const [routeKeyPressed, setRouteKeyPressed] = useState(false);
  const [disabledFeatureMissing, setDisabledFeatureMissing] = useState(false);
  const hoverRef = useCallOnHover<View>(
    undefined,
    () => {
      setHoveringState(true);
    },
    () => {
      setHoveringState(false);
    },
  );
  const [navigateToHome, setNavigateToHome] = useState<boolean>(false);

  useEffect(() => {
    if (requireFeature) {
      setDisabledFeatureMissing(!features.some(f => f.feature === requireFeature));
    } else {
      setDisabledFeatureMissing(false);
    }
  }, [features, requireFeature]);

  useEffect(() => {
    if (
      routeKeyPath === selectedRouteKeyPath ||
      subRouteKeypath?.some(_routeKeyPath => _routeKeyPath === selectedRouteKeyPath)
    ) {
      setRouteKeyPressed(true);
    } else {
      setRouteKeyPressed(false);
    }
  }, [props.selectedRouteKeyPath]);

  useEffect(() => {
    if (navigateToHome) {
      const history = useHistory();
      history.push(RouteLocations.Home());
    }
  }, [navigateToHome]);

  function handleSelectRouteKey(routeKeyPath: RoutePaths) {
    handleSelect(routeKeyPath);
    handleDrawer(false);
  }

  function showFeatureMissingAcceptCallback() {
    setNavigateToHome(true);
  }

  return (
    <TouchableOpacity
      style={[styles.rowFlexView, styles.routeKeyOuterContainer]}
      onPress={() => {
        if (!routeKeyIcon && !drawerOpened) {
          handleDrawer(!drawerOpened);
        } else {
          if (disabledFeatureMissing) {
            ModalUtils.showFeatureMissing(modal, showFeatureMissingAcceptCallback, requireFeature);
          } else {
            handleSelectRouteKey(routeKeyPath);
          }
        }
      }}
    >
      <View
        ref={hoverRef}
        style={[
          styles.routeKeyTouchable,
          {
            backgroundColor: !routeKeyIcon
              ? 'transparent'
              : routeKeyPressed
              ? Colors.Yellow
              : hoveringState
              ? Colors.Black
              : 'transparent',
          },
        ]}
      >
        {routeKeyIcon && (
          <IconSVG
            svgComponent={routeKeyIcon}
            size={routeKeyIconSVGSize}
            color={
              disabledFeatureMissing
                ? Colors.LightGrey
                : routeKeyPressed
                ? Colors.White
                : Colors.Grey
            }
          />
        )}
      </View>
      {drawerOpened && (
        <View key={routeKeyPath} style={[styles.routeKeyTextContainer]}>
          <View style={styles.subRouteTextContainer}>
            <Text
              style={[
                styles.routeKeyTextStyle,
                {
                  color: disabledFeatureMissing
                    ? Colors.LightGrey
                    : routeKeyPressed
                    ? Colors.White
                    : Colors.Grey,
                },
                routeKeyTitleStyle,
              ]}
              numberOfLines={1}
            >
              {routeKeyTitle}
            </Text>
          </View>
          {routeKeyNumber && (
            <View style={styles.routeKeyNumberInnerContainer}>
              <Text
                style={[
                  styles.routeKeyNumberText,
                  {
                    color: routeKeyPressed ? Colors.White : Colors.Black,
                  },
                ]}
              >
                {routeKeyNumber}
              </Text>
            </View>
          )}
        </View>
      )}
    </TouchableOpacity>
  );
};
