import React, { useState, useEffect, useContext } from 'react';
import { View, TouchableOpacity, Text, ScrollView, InteractionManager } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { useIsMounted } from 'shared/hooks/IsMounted';
import Styles from './style';
import { t } from 'shared/localisation/i18n';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { Spacings } from 'shared/styles';
import { deepClone } from 'shared/backend-data';
import { isUnauthorizedUnitType, UnauthorizedUnit } from './index';

const expandIcon = require('shared/assets/svg/icon.down.verysmall.svg').default;
const practicalTrainingIcon = require('shared/assets/svg/icon.practTrain.svg').default;
const notPracticalTrainingIcon = require('shared/assets/svg/icon.lecture.svg').default;
const skillIcon = require('shared/assets/svg/icon.skill.small.svg').default;
const linkIcon = require('shared/assets/svg/icon.link.svg').default;

interface TrainingSkillsListingProps {
  treeNode?: API.TreeNode;
  selectableTraining?: boolean;
  selectableSkillId?: string;
  trainingVersionId: string;
  skillIds: string[];
  requirement?: API.Requirement;
  disabled?: boolean;
  handleWorkstationTrainingModal?: (
    isShow: boolean,
    editMode: boolean,
    trainingVersion: API.TrainingVersion | null | undefined,
    skillIds: string[],
  ) => void;
  inheritedUnit?: API.TreeObject | UnauthorizedUnit;
  refreshLevelSkills?: () => Promise<void>;
}

interface TrainingSkillRow {
  skillName: string;
  isSkillRequired: boolean;
}

export const TrainingSkillsListing: React.FC<TrainingSkillsListingProps> = props => {
  const {
    treeNode,
    selectableTraining,
    trainingVersionId,
    skillIds,
    requirement,
    selectableSkillId,
    disabled = false,
    handleWorkstationTrainingModal,
    refreshLevelSkills,
    inheritedUnit,
  } = props;

  const isMounted = useIsMounted();

  const [isSkillsExpanded, setIsSkillsExpanded] = useState(false);
  const [trainingVersion, setTrainingVersion] = useState<API.TrainingVersion>();
  const [training, setTraining] = useState<API.Training>();
  const [requiredSkills, setRequiredSkills] = useState<API.Skill[]>();
  const [notRequiredSkills, setNotRequiredSkills] = useState<API.Skill[]>();
  const [isTrainingVersionPractical, setIsTrainingVersionPractical] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { isValidPermission } = useContext(PermissionManagementContext);
  const ref = useCallOnHover<View>(
    SharedStyles.Colors.White,
    () => {
      setIsHover(true);
    },
    () => setIsHover(false),
  );

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      await loadTrainingSkillsListing();
    });
  }, [trainingVersionId]);

  async function loadTrainingSkillsListing() {
    const tempTrainingVerion = await API.getTrainingVersion(trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(tempTrainingVerion)) {
      logger.warn('Failed to fetch training version', tempTrainingVerion);
      return;
    }
    setTrainingVersion(tempTrainingVerion);

    const tempTraining = await API.getTraining(tempTrainingVerion.trainingId);
    if (!isMounted.current) return;
    if (API.isFailure(tempTraining)) {
      logger.warn('Failed to fetch training ', tempTraining);
      return;
    }
    setTraining(tempTraining);

    const requiredSkills: API.Skill[] = [];
    const notRequiredSkills: API.Skill[] = [];
    await Promise.all(
      _.map(tempTrainingVerion?.skillIds, async (skillId, skillIndex) => {
        const skill = await API.getSkill(skillId);
        if (!isMounted.current) return;
        if (API.isFailure(skill)) {
          logger.warn('Failed to fetch skill', skill);
          return;
        }
        if (skillIndex === 0) {
          setIsTrainingVersionPractical(skill.isPractical);
        }
        if (_.includes(skillIds, skill.id)) {
          requiredSkills.push(skill);
        } else {
          notRequiredSkills.push(skill);
        }
      }),
    );
    setRequiredSkills(requiredSkills);
    setNotRequiredSkills(notRequiredSkills);

    const allTrainingVersions = await API.getTrainingVersions();
    if (!isMounted.current) return;
    if (API.isFailure(allTrainingVersions)) {
      logger.warn('Failed to fetch training versions');
      return;
    }

    _.forEach(requiredSkills, skill => {
      const possibleTrainingVerisions: API.TrainingVersion[] = [];
      _.forEach(allTrainingVersions.result, trainingVersion => {
        if (_.includes(trainingVersion.skillIds, skill.id))
          possibleTrainingVerisions.push(trainingVersion);
      });
    });
  }

  async function selectTrainingVersion() {
    if (!requirement || !selectableSkillId) return;

    const newSkillTrainingVersions: API.SkillTrainingVersionInput[] = deepClone(
      requirement.skillTrainingVersions,
    );
    let skillTrainingVersionIndex = 0;

    const selectedSkillTrainingVersion = _.find(
      newSkillTrainingVersions,
      (skillTrainingVersion, index) => {
        if (skillTrainingVersion.skillId === selectableSkillId) {
          skillTrainingVersionIndex = index;
          return true;
        } else {
          return false;
        }
      },
    );
    if (!selectedSkillTrainingVersion) return;

    newSkillTrainingVersions[skillTrainingVersionIndex] = {
      skillId: selectedSkillTrainingVersion.skillId,
      trainingVersionId: trainingVersionId,
    };

    const updatedRequirement = await API.updateRequirement({
      ...requirement,
      skillTrainingVersions: newSkillTrainingVersions,
    });
    if (!isMounted.current) return;
    if (API.isFailure(updatedRequirement)) {
      logger.warn('Failed to update requirement ', updatedRequirement);
      setIsLoading(false);
      return;
    }

    if (refreshLevelSkills) await refreshLevelSkills();
    if (!isMounted.current) return;
    setIsLoading(false);
    loadTrainingSkillsListing();
  }

  async function deleteTrainingVersionFromRequirement() {
    if (!requirement) return;

    const newSkillTrainingVersions: API.SkillTrainingVersionInput[] = _.map(
      requirement.skillTrainingVersions,
      skillTrainingVersion => {
        return {
          skillId: skillTrainingVersion.skillId,
          trainingVersionId:
            skillTrainingVersion.trainingVersionId === trainingVersionId
              ? ''
              : skillTrainingVersion.trainingVersionId,
        };
      },
    );

    const updatedRequirement = await API.updateRequirement({
      ...requirement,
      skillTrainingVersions: newSkillTrainingVersions,
    });
    if (!isMounted.current) return;
    if (API.isFailure(updatedRequirement)) {
      logger.warn('Failed to update requirement ', updatedRequirement);
      setIsLoading(false);
      return;
    }

    loadTrainingSkillsListing();
    if (refreshLevelSkills) await refreshLevelSkills();
    if (!isMounted.current) return;
    setIsLoading(false);
  }

  const TrainingSkillRow: React.FC<TrainingSkillRow> = props => {
    return (
      <View style={Styles.trainingSkillRowContainer}>
        <View style={Styles.trainingSkillInnerRowContainer}>
          <IconSVG
            svgComponent={skillIcon}
            size={{ width: 24, height: 24 }}
            containerStyle={{ height: 24, width: 24 }}
            color={props.isSkillRequired ? SharedStyles.Colors.Black : SharedStyles.Colors.Grey}
          />
          <Text
            style={[
              Styles.trainingSkillName,
              {
                color: props.isSkillRequired ? SharedStyles.Colors.Black : SharedStyles.Colors.Grey,
              },
            ]}
          >
            {props.skillName}
          </Text>
        </View>
      </View>
    );
  };

  return (
    <View ref={ref}>
      <TouchableOpacity
        onPress={() => {
          if (handleWorkstationTrainingModal)
            handleWorkstationTrainingModal(true, true, trainingVersion, []);
        }}
        style={Styles.trainingSkillsListingContainer}
        disabled={disabled}
      >
        {isLoading && (
          <View style={Styles.trainingSkillsListingLoader}>
            <LoaderThreeDots />
          </View>
        )}
        <TouchableOpacity style={Styles.practicalTrainingIconContainer}>
          <IconSVG
            svgComponent={
              isTrainingVersionPractical ? practicalTrainingIcon : notPracticalTrainingIcon
            }
            color={disabled ? SharedStyles.Colors.Grey : SharedStyles.Colors.Black}
            size={{ width: 28, height: 24 }}
            containerStyle={{ height: 24, width: 28 }}
          />
        </TouchableOpacity>
        <View style={Styles.trainingDetails}>
          <View style={Styles.trainingDetailsContainer}>
            <Text
              style={[
                Styles.trainingNameTextStyle,
                { color: disabled ? SharedStyles.Colors.Grey : SharedStyles.Colors.Black },
              ]}
            >
              {training?.name ?? <LoaderThreeDots />}
            </Text>
            {!props.selectableTraining && (
              <View>
                {!isTrainingVersionPractical &&
                  !disabled &&
                  isValidPermission(API.Permission.workstations_edit, treeNode) && (
                    <TouchableOpacity
                      onPress={deleteTrainingVersionFromRequirement}
                      style={Styles.chooseTrainingContainer}
                    >
                      <IconSVG
                        size={{ width: 14, height: 14 }}
                        containerStyle={{
                          height: SharedStyles.Spacings.Standard,
                          width: SharedStyles.Spacings.Standard,
                          backgroundColor: SharedStyles.Colors.Yellow,
                          borderRadius: SharedStyles.Spacings.Standard,
                        }}
                        svgComponent={linkIcon}
                        color={SharedStyles.Colors.White}
                      />
                    </TouchableOpacity>
                  )}
              </View>
            )}
          </View>
          {!selectableTraining ? (
            <View style={Styles.expandSkillsContainer}>
              <Text style={Styles.skillsTotalTextStyle}>
                {`${trainingVersion?.skillIds.length} ${t(
                  'glossary:skill',
                  { count: trainingVersion?.skillIds.length },
                  false,
                  undefined,
                )}`}
              </Text>
              <TouchableOpacity
                style={{ transform: isSkillsExpanded ? [{ rotate: '-180deg' }] : [] }}
                onPress={() => setIsSkillsExpanded(prev => !prev)}
              >
                <IconSVG
                  svgComponent={expandIcon}
                  size={{
                    width: SharedStyles.Spacings.Standard,
                    height: SharedStyles.Spacings.Standard,
                  }}
                  containerStyle={{
                    height: 24,
                    width: 24,
                  }}
                  color={SharedStyles.Colors.Grey}
                />
              </TouchableOpacity>
            </View>
          ) : (
            <TouchableOpacity
              style={Styles.chooseTrainingContainer}
              onPress={selectTrainingVersion}
            >
              <IconSVG
                svgComponent={linkIcon}
                size={{ width: 14, height: 14 }}
                containerStyle={[
                  Styles.chooseTrainingIconContainorStyle,
                  {
                    marginRight: Spacings.Standard,
                    backgroundColor: isHover
                      ? SharedStyles.Colors.Yellow
                      : SharedStyles.Colors.GreyLight,
                  },
                ]}
                color={SharedStyles.Colors.White}
              />
            </TouchableOpacity>
          )}
          {isSkillsExpanded && trainingVersion?.skillIds && !selectableTraining && (
            <ScrollView style={Styles.skillsListingScrollContainer}>
              {_.map(requiredSkills, skill => {
                return (
                  <TrainingSkillRow
                    key={skill.id}
                    isSkillRequired={!disabled}
                    skillName={skill.name}
                  />
                );
              })}
              {_.map(notRequiredSkills, skill => {
                return (
                  <TrainingSkillRow key={skill.id} isSkillRequired={false} skillName={skill.name} />
                );
              })}
            </ScrollView>
          )}
          {inheritedUnit &&
            (isUnauthorizedUnitType(inheritedUnit) ? (
              <Text style={Styles.skillFromOUtext}>
                {t(
                  'alex:workstations.workstationPanel.requirementTable.requirementSkillColumn.inheritedSkillFromUnAuthorizedUnit',
                )}
              </Text>
            ) : (
              <Text style={Styles.skillFromOUtext}>
                {t(
                  'alex:workstations.workstationPanel.requirementTable.requirementSkillColumn.inheritedTraining',
                  { unitName: inheritedUnit.name },
                )}
              </Text>
            ))}
        </View>
      </TouchableOpacity>
    </View>
  );
};
