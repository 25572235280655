import React, { useState, useEffect } from 'react';
import { InteractionManager, ViewStyle } from 'react-native';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import { InputList } from 'shared/ui-component/Input/InputList';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import { t } from 'shared/localisation/i18n';
import { ModifySkillModal } from './modify-skill-modal/component';

export interface SkillsFilterProps {
  disabledSkillIds: string[];
  addCreateItemRow?: boolean;
  containerStyle?: ViewStyle;
  onCreateSkill?: (filterInput?: string) => void;
  setIsSkillsFilterOpen: (bool: boolean) => void;
  onSave: (skills: API.Skill[]) => void;
}

const SkillIcon = require('shared/assets/svg/icon.skill.svg').default;

export const SkillsFilter: React.FC<SkillsFilterProps> = props => {
  const { disabledSkillIds, setIsSkillsFilterOpen, onSave } = props;

  const [skills, setSkills] = useState<API.Skill[]>([]);
  const [options, setOptions] = useState<Tag[]>([]);
  const [showSkillModal, setShowSkillModal] = useState(false);

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const skillsArray = await API.getSkills();
      if (!isMounted.current) return;
      if (API.isFailure(skillsArray)) {
        logger.warn('Failed to fetch skills');
        return;
      }

      setSkills(skillsArray.result);
    });
  }, []);

  useEffect(() => {
    if (!skills.length) return;

    const _skills: Tag[] = skills.map(skill => {
      const option: Tag = {
        key: skill.id,
        label: skill.name,
        value: skill,
      };
      if (disabledSkillIds && disabledSkillIds.includes(skill.id)) option.disabled = true;
      return option;
    });
    setOptions(_.sortBy(_skills, eachSkill => eachSkill.label));
  }, [skills, disabledSkillIds]);

  function createSkillHandler() {
    if (props.onCreateSkill) {
      props.onCreateSkill();
      return;
    }

    setShowSkillModal(true);
  }

  const createSkillWithPlaceholderNameHandler = (label: string): void => {
    if (props.onCreateSkill) props.onCreateSkill(label);
  };

  
  function handleSelection(inputId: string, tags: Tag[]) {
    onSave(
      _.uniqBy(
        _.map(tags, tag => tag.value),
        'id',
      ),
    );
  }

  return (
    <>
      {showSkillModal && <ModifySkillModal handleModalClose={() => setShowSkillModal(false)} />}
      <InputList
        options={options}
        placeholder={t('alex:skills.skillFilter:1')}
        optionIcon={SkillIcon}
        handleSelection={handleSelection}
        openInputList={setIsSkillsFilterOpen}
        createNewOption={
          props.addCreateItemRow
            ? {
                createItemPlaceHolder: t('alex:skills.skillFilter:0'),
                createItemIcon: SkillIcon,
                createItemHandler: createSkillHandler,
              }
            : undefined
        }
        createNewOptionShortcut={createSkillWithPlaceholderNameHandler}
        containerStyle={props.containerStyle}
      />
    </>
  );
};
