import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles, { ROW_HEIGHT } from './styles';
import { Spacings, TextFontStyles } from 'shared/styles';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ActivityIndicator,
  InteractionManager,
  View,
  ScrollView,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { WorkstationWorkerLevelContainer } from 'shared/components/workstation-worker-level/container';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import {
  HEADER_HEIGHT,
  CELL_WIDTH,
  HEADER_TEXT_WIDTH,
  SCROLLBAR_WIDTH,
} from '../versatility-panel/styles';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { Colors } from 'shared/styles';
import {
  DashboardFilterContext,
  ParentWithWorkers,
  WorkersByParentWithDetails,
} from 'shared/context/dashboard-context/DashboardFilterContext';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { WorkstationWorkerMenuObject } from '../versatility-panel/VersatilityPanel';
import logger from 'shared/util/Logger';
import { SvgProps } from 'react-native-svg';
import { IconSVG } from 'shared/ui-component/Icon';
import NodeCell, { NodeCellHorizontalTranslation } from './NodeCell';
import {
  extractOrgUnitsAndWorkstationsFromMap,
  WorkerWithParentNode,
  WorkstationTargetActualWithDetailsMap,
  WorkstationsAndParentOrgUnitsMap,
} from '../workstation-table/component/WorkstationTableComponent';
import ShiftThumbIcon from 'shared/ui-component/ShiftThumbIcon/index';
import { getWorkerWorkstations } from 'shared/backend-data';

export const LinearGradient = require('react-native-web-linear-gradient').default;

const GradientWidth = 44;

const PERF_filterDisplayedWorkersAndWorkstationsInMs = 350;

interface Props {
  menuObject: WorkstationWorkerMenuObject;
  openMenu: boolean;
  workerWorkstationsScrollViewRef: React.MutableRefObject<ScrollView | undefined>;
  computeMatrixLevelIcons: boolean;
  workstationsScrollViewRef: React.MutableRefObject<ScrollView | undefined>;
  rightContainerFullWidth: number;
  levelsTableFullHeight: number;
  workstationsScrollViewOffset: number;
  workersScrollViewFullHeight: number;
  workersScrollViewOffset: number;
  isScrolling: boolean;
  selectedElement?: Element;
  treeNode?: API.TreeNode<API.TreeDataType>;
  displayedLeftPanelFilteredWorkersAndParents?: Array<WorkerWithParentNode | ParentWithWorkers>;
  displayedFilteredNodes?: API.TreeObject[];
  workstationsAndParentOrgUnitsMap: WorkstationsAndParentOrgUnitsMap | undefined;
  nodeCount: number;
  workstationFooterDataMap: WorkstationTargetActualWithDetailsMap | undefined;

  setDisplayedLeftPanelFilteredWorkersAndParents: (
    workers: Array<WorkerWithParentNode | ParentWithWorkers> | undefined,
  ) => void;
  setDisplayedUpperPanelFilteredNodes: (nodes: API.TreeObject[] | undefined) => void;
  setTreeNode: (treeNode?: API.TreeNode<API.TreeDataType> | undefined) => void;
  setWorkstationsScrollViewOffset: (offset: number) => void;
  setLevelsTableFullHeight: (height: number) => void;
  setComputeObjectives: (bool: boolean) => void;
  setSelectedElement: (element?: Element) => void;
  setMenuObject: (obj: WorkstationWorkerMenuObject) => void;
  setOpenMenu: (bool: boolean) => void;
  onWorkerWorkstationTableScroll: (offset: number) => void;
  onWorkstationTableScroll: (offset: number) => void;
  setSelectedTargetsMenuElement: (element?: Element) => void;
  setOpenTargetsMenu: (bool: boolean) => void;
  setComputeToDoList: (bool: boolean) => void;
}

interface WorkstationWorkerLevelTableProps extends Props {
  adaptableHeight?: number;
  workersScrollViewDisplayedHeight: number;
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels;

  getSavedScrollOffsets: () => void;
  handleShiftIconPress: (workstationFooterData: API.WorkstationTargetActualWithDetails) => void;
}
interface WorkstationWorkerLevelTableComponentProps extends Props {
  filteredWorkstations?: API.Workstation[];
  workersByShiftsOrOrgUnitWithDetails?: WorkersByParentWithDetails;
  renderMatrix: boolean;
  adaptableHeight?: number;
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels;

  handleShiftIconPress: (workstationFooterData: API.WorkstationTargetActualWithDetails) => void;
}

interface WorkerWorkstationLevelsScrollingProps {
  workersCount: number;
  workstationsCount: number;
  containerStyle?: StyleProp<ViewStyle>;
  rowContainerStyle?: StyleProp<ViewStyle>;
}

let AlexIcon: React.FC<SvgProps> = require('shared/assets/svg/icon.dashboard.svg').default;

const _AlexIconsScrollingPanel: React.FC<WorkerWorkstationLevelsScrollingProps> = props => {
  const { workersCount, workstationsCount, containerStyle, rowContainerStyle } = props;

  
  const workersArr = Array.from(Array(workersCount).keys());
  const workstationsArr = Array.from(Array(workstationsCount).keys());

  return (
    <View style={containerStyle}>
      {workersArr.map(index1 => {
        return (
          <View key={index1} style={[styles.rowLevelCellsGrid, rowContainerStyle]}>
            {workstationsArr.map(index2 => {
              return (
                <IconSVG
                  key={index1.toString() + index2.toString()}
                  svgComponent={AlexIcon}
                  color={Colors.GreyLight}
                  size={{ width: 28, height: 28 }}
                  containerStyle={[styles.alexIconContainer, { marginLeft: CELL_WIDTH * index2 }]}
                />
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

export const AlexIconsScrollingPanel = React.memo(
  _AlexIconsScrollingPanel,
  (prevProps, nextProps) => {
    const isEqual =
      _.isEqual(prevProps.workersCount, nextProps.workersCount) &&
      _.isEqual(prevProps.workstationsCount, nextProps.workstationsCount);

    return isEqual;
  },
);

const _WorkstationWorkerLevelTableComponent: React.FC<
  WorkstationWorkerLevelTableComponentProps
> = props => {
  const {
    isScrolling,
    workersByShiftsOrOrgUnitWithDetails,
    filteredWorkstations,
    workstationsScrollViewRef,
    workerWorkstationsScrollViewRef,
    workersScrollViewFullHeight,
    displayedFilteredNodes,
    displayedLeftPanelFilteredWorkersAndParents,
    selectedElement,
    computeMatrixLevelIcons,
    openMenu,
    menuObject,
    levelsTableFullHeight,
    workstationsScrollViewOffset,
    workersScrollViewOffset,
    renderMatrix,
    treeNode,
    adaptableHeight,
    rightContainerFullWidth,
    workstationsAndParentOrgUnitsMap,
    nodeCount,
    workstationFooterDataMap,

    setComputeToDoList,
    handleShiftIconPress,
    setComputeObjectives,
    setWorkstationsScrollViewOffset,
    onWorkstationTableScroll,
    onWorkerWorkstationTableScroll,
    setLevelsTableFullHeight,
    setSelectedElement,
    setOpenMenu,
    setMenuObject,
    setTreeNode,
    setSelectedTargetsMenuElement,
    setOpenTargetsMenu,
  } = props;

  const workerWorkstationsMounted = React.useRef<number>(0);

  const [maxDisplayedFilteredWorkersCount, setMaxDisplayedFilteredWorkersCount] = useState(0);
  const [maxDisplayedFilteredNodesCount, setMaxDisplayedFilteredNodesCount] = useState(0);

  useEffect(() => {
    if (displayedLeftPanelFilteredWorkersAndParents?.length) {
      setMaxDisplayedFilteredWorkersCount(displayedLeftPanelFilteredWorkersAndParents.length);
    }

    if (displayedFilteredNodes?.length) {
      setMaxDisplayedFilteredNodesCount(displayedFilteredNodes.length);
    } else if (displayedFilteredNodes?.length === 0) {
      
      setComputeToDoList(true);
    }
  }, [displayedLeftPanelFilteredWorkersAndParents, displayedFilteredNodes]);

  function onWorkerWorkstationLevelMount() {
    if (!displayedLeftPanelFilteredWorkersAndParents || !displayedFilteredNodes) return;
    workerWorkstationsMounted.current++;

    

    
    if (computeMatrixLevelIcons) setComputeObjectives(true);
  }

  function isRenderWorkerWorkstationMatrix() {
    return !(
      !renderMatrix ||
      !workersByShiftsOrOrgUnitWithDetails ||
      !filteredWorkstations ||
      !displayedLeftPanelFilteredWorkersAndParents ||
      !displayedFilteredNodes ||
      isScrolling
    );
  }

  function _onWorkerWorkstationsScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    onWorkerWorkstationTableScroll(event.nativeEvent.contentOffset.y);
  }

  function isRenderWorkerWorkstationScrollingPanel() {
    return !!maxDisplayedFilteredWorkersCount && !!maxDisplayedFilteredNodesCount;
  }

  function renderNodesCells(
    workstationsAndParentOrgUnitsMap: WorkstationsAndParentOrgUnitsMap,
  ): JSX.Element[] {
    const jsx: JSX.Element[] = [];
    Array.from(workstationsAndParentOrgUnitsMap.values()).map(_wsAndParent => {
      jsx.push(
        <NodeCell
          key={_wsAndParent.orgUnit.id}
          node={_wsAndParent.orgUnit}
          index={jsx.length}
          setTreeNode={setTreeNode}
        />,
      );

      _wsAndParent.workstations.map(workstation => {
        const isWorkstationHighlighted = treeNode?.id === workstation.id;
        jsx.push(
          <NodeCell
            key={workstation.id}
            node={workstation}
            index={jsx.length}
            isHighlighted={isWorkstationHighlighted}
            textStyle={isWorkstationHighlighted ? { ...TextFontStyles.LatoBlack } : {}}
            setTreeNode={setTreeNode}
          />,
        );
      });
    });

    return jsx;
  }

  /**
   * This function will return the target values according to shift or workstation inside the dashboard
   * @param workstationFooterData
   * @returns
   */
  function getWorkstationTargetActual(
    workstationFooterData?: API.WorkstationTargetActualWithDetails,
  ): API.WorkstationTargetActual | undefined {
    if (!workstationFooterData) return;

    if (workstationFooterData.shift) {
      return workstationFooterData?.workstationsTargetActual[workstationFooterData.shift.id];
    } else {
      return workstationFooterData?.workstationsTargetActual[workstationFooterData.workstation.id];
    }
  }

  return (
    <View onLayout={e => setLevelsTableFullHeight(e.nativeEvent.layout.height)} style={{ flex: 1 }}>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={{
          opacity: 1,
          position: 'absolute',
          top: HEADER_HEIGHT,
          height: levelsTableFullHeight - Spacings.xMedium - HEADER_HEIGHT,
          width: GradientWidth,
          zIndex: 2,
        }}
        colors={[Colors.White, Colors.ShadowWhite]}
      />
      {isRenderWorkerWorkstationScrollingPanel() && (
        <View
          style={[
            {
              width: rightContainerFullWidth,
              height: levelsTableFullHeight - HEADER_HEIGHT - Spacings.Medium,
              display: isScrolling ? 'flex' : 'none',
            },
            styles.workerWorkstationLevelsScrollingContainer,
          ]}
        >
          <AlexIconsScrollingPanel
            workersCount={maxDisplayedFilteredWorkersCount}
            workstationsCount={maxDisplayedFilteredNodesCount}
          />
        </View>
      )}
      {workstationsAndParentOrgUnitsMap !== undefined ? (
        <ScrollView
          ref={workstationsScrollViewRef as React.MutableRefObject<ScrollView>}
          contentContainerStyle={{
            width: nodeCount * CELL_WIDTH + HEADER_TEXT_WIDTH - CELL_WIDTH - SCROLLBAR_WIDTH,
          }}
          style={{ zIndex: 1 }}
          horizontal
          showsHorizontalScrollIndicator={true}
          scrollEventThrottle={16}
          onScroll={e => {
            setWorkstationsScrollViewOffset(e.nativeEvent.contentOffset.x);
            onWorkstationTableScroll(e.nativeEvent.contentOffset.x);
          }}
        >
          {/**
           * Render Nodes Cells
           */}
          {workstationsAndParentOrgUnitsMap && renderNodesCells(workstationsAndParentOrgUnitsMap)}

          <ScrollView
            ref={workerWorkstationsScrollViewRef as React.MutableRefObject<ScrollView>}
            scrollEventThrottle={16}
            showsVerticalScrollIndicator={false}
            style={[
              {
                marginTop: HEADER_HEIGHT,
                height: (adaptableHeight ?? 0) - HEADER_HEIGHT,
              },
            ]}
            contentContainerStyle={{
              height: workersScrollViewFullHeight,
            }}
            onScroll={_onWorkerWorkstationsScroll}
          >
            {isRenderWorkerWorkstationMatrix() && (
              <View
                style={[
                  {
                    height: levelsTableFullHeight - HEADER_HEIGHT,
                    left:
                      Spacings.Small +
                      workstationsScrollViewOffset -
                      (workstationsScrollViewOffset % CELL_WIDTH),
                    top: workersScrollViewOffset - (workersScrollViewOffset % ROW_HEIGHT),
                  },
                ]}
              >
                {displayedLeftPanelFilteredWorkersAndParents!.map((workerOrShift, workerIndex) => {
                  const z = displayedLeftPanelFilteredWorkersAndParents!.length - workerIndex;
                  const isWorker = 'id' in workerOrShift;

                  if (isWorker) {
                    const _worker = workerOrShift;

                    return (
                      <View
                        key={`${workerIndex}${_worker.id}`}
                        style={[styles.rowLevelCellsGrid, { zIndex: z }]}
                      >
                        {displayedFilteredNodes!.map((node, nodeIndex) => {
                          if (API.isWorkstation(node)) {
                            const workstation = node;
                            const workstationIndex = nodeIndex;
                            const _workerWorkstation = getWorkerWorkstations(
                              workstation.id,
                              workerOrShift.id,
                            );

                            return (
                              <WorkstationWorkerLevelContainer
                                
                                key={`${_worker.id}${workstation.id}${workstationIndex}`}
                                workstationId={workstation.id}
                                workerWorkstation={_workerWorkstation}
                                isComputationDisabled={!_workerWorkstation}
                                style={{
                                  zIndex: -workstationIndex,
                                  marginLeft:
                                    CELL_WIDTH * workstationIndex - NodeCellHorizontalTranslation,
                                }}
                                worker={workerOrShift}
                                routeFrom={RoutePaths.Home}
                                selectedElement={selectedElement}
                                menuObject={menuObject}
                                openMenu={openMenu}
                                setSelectedElement={setSelectedElement}
                                setOpenMenu={setOpenMenu}
                                setMenuObject={setMenuObject}
                                onMount={onWorkerWorkstationLevelMount}
                                setTreeNode={setTreeNode}
                              />
                            );
                          }
                        })}
                      </View>
                    );
                  } else {
                    const shift = workerOrShift.shift;
                    return (
                      <View
                        key={`${workerIndex}${shift ? shift.id : API.DataType.ORGUNIT}`}
                        style={[styles.rowLevelCellsGrid, { zIndex: z }]}
                      >
                        {displayedFilteredNodes!.map((node, nodeIndex) => {
                          const isWorkstation = API.isWorkstation(node);

                          if (displayedFilteredNodes!.length - 1 === nodeIndex) {
                            InteractionManager.runAfterInteractions(() => {
                              setComputeToDoList(true);
                            });
                          }

                          const workstationFooterData = workstationFooterDataMap?.get(
                            node.id + (shift ? API.SeparatorIds + shift.id : ''),
                          );
                          const workstationsTargetActual =
                            getWorkstationTargetActual(workstationFooterData);

                          return (
                            <View
                              key={node.id + shift?.id}
                              style={styles.shiftThumbIconOuterWrapper}
                            >
                              {isWorkstation && workstationsTargetActual ? (
                                <View style={styles.shiftThumbIconInnerWrapper}>
                                  <ShiftThumbIcon
                                    workstation={node}
                                    shift={shift}
                                    workstationsTargetActual={workstationsTargetActual}
                                    workstationFooterData={workstationFooterData}
                                    setOpenTargetsMenu={setOpenTargetsMenu}
                                    setSelectedTargetsMenuElement={setSelectedTargetsMenuElement}
                                    setTreeNode={setTreeNode}
                                    handleShiftIconPress={handleShiftIconPress}
                                  />
                                </View>
                              ) : (
                                <></>
                              )}
                            </View>
                          );
                        })}
                      </View>
                    );
                  }
                })}
              </View>
            )}
          </ScrollView>
        </ScrollView>
      ) : (
        <View
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <ActivityIndicator color={Colors.Yellow} size="large" />
        </View>
      )}
    </View>
  );
};

const WorkstationWorkerLevelTableComponent = React.memo<WorkstationWorkerLevelTableComponentProps>(
  _WorkstationWorkerLevelTableComponent,
  (prevProps, nextProps) => {
    const isEqual =
      _.isEqual(prevProps.filteredWorkstations, nextProps.filteredWorkstations) &&
      _.isEqual(
        prevProps.workersByShiftsOrOrgUnitWithDetails,
        nextProps.workersByShiftsOrOrgUnitWithDetails,
      ) &&
      _.isEqual(
        prevProps.displayedLeftPanelFilteredWorkersAndParents,
        nextProps.displayedLeftPanelFilteredWorkersAndParents,
      ) &&
      _.isEqual(prevProps.displayedFilteredNodes, nextProps.displayedFilteredNodes) &&
      _.isEqual(prevProps.renderMatrix, nextProps.renderMatrix) &&
      _.isEqual(prevProps.isScrolling, nextProps.isScrolling) &&
      _.isEqual(prevProps.treeNode, nextProps.treeNode) &&
      _.isEqual(prevProps.adaptableHeight, nextProps.adaptableHeight) &&
      _.isEqual(prevProps.workstationFooterDataMap, nextProps.workstationFooterDataMap);

    return isEqual;
  },
);

export const WorkstationWorkerLevelTable: React.FC<WorkstationWorkerLevelTableProps> = props => {
  const {
    selectedElement,
    menuObject,
    openMenu,
    workerWorkstationsScrollViewRef,
    computeMatrixLevelIcons,
    workstationsScrollViewRef,
    rightContainerFullWidth,
    levelsTableFullHeight,
    workstationsScrollViewOffset,
    workersScrollViewFullHeight,
    workersScrollViewDisplayedHeight,
    workersScrollViewOffset,
    isScrolling,
    treeNode,
    displayedFilteredNodes,
    displayedLeftPanelFilteredWorkersAndParents,
    adaptableHeight,
    workstationTargetsStartingAtLevel,
    workstationsAndParentOrgUnitsMap,
    nodeCount,
    workstationFooterDataMap,

    setDisplayedUpperPanelFilteredNodes,
    setDisplayedLeftPanelFilteredWorkersAndParents,
    setTreeNode,
    setWorkstationsScrollViewOffset,
    setLevelsTableFullHeight,
    setComputeObjectives,
    setSelectedElement,
    setMenuObject,
    setOpenMenu,
    onWorkerWorkstationTableScroll,
    onWorkstationTableScroll,
    getSavedScrollOffsets,
    setSelectedTargetsMenuElement,
    setOpenTargetsMenu,
    handleShiftIconPress,
    setComputeToDoList,
  } = props;

  const {
    workstations: [filteredWorkstations],
    workersByParentWithDetails: [workersByShiftsOrOrgUnitWithDetails],
  } = useContext(DashboardFilterContext);

  const debounceFilterDisplayedWorkersAndWorkstations = useCallback(
    _.debounce(
      filterDisplayedWorkersAndWorkstations,
      PERF_filterDisplayedWorkersAndWorkstationsInMs,
    ),
    [],
  );

  const isMounted = useIsMounted();

  const [renderMatrix, setRenderMatrix] = useState<boolean>(false);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;

      if (!displayedFilteredNodes?.length) {
        setTreeNode(undefined);
      } else {
        for (const _node of displayedFilteredNodes) {
          if (API.isWorkstation(_node) && _node.pathIds.length > 2) {
            const _treeNode = API.Tree.getTreeNode(_node.id);
            if (API.isFailure(_treeNode)) {
              logger.warn(_treeNode);
              return;
            }

            setTreeNode(_treeNode);
            return;
          }
        }
      }
    });
  }, [displayedFilteredNodes]);

  useEffect(() => {
    if (
      filteredWorkstations?.length &&
      workersByShiftsOrOrgUnitWithDetails?.workersByParents.size &&
      !renderMatrix
    ) {
      allowWorkerWorkstationMatrixRender();
    }
  }, [filteredWorkstations, workersByShiftsOrOrgUnitWithDetails, renderMatrix]);

  useEffect(() => {
    if (!renderMatrix || !workersByShiftsOrOrgUnitWithDetails || !filteredWorkstations) return;

    getSavedScrollOffsets();
  }, [renderMatrix, workersByShiftsOrOrgUnitWithDetails, filteredWorkstations]);

  useEffect(() => {
    debounceFilterDisplayedWorkersAndWorkstations(
      workersByShiftsOrOrgUnitWithDetails,
      workstationsAndParentOrgUnitsMap,
      workstationsScrollViewOffset,
      rightContainerFullWidth,
      workersScrollViewDisplayedHeight,
      workersScrollViewOffset,
    );
  }, [
    workersByShiftsOrOrgUnitWithDetails,
    workstationsAndParentOrgUnitsMap,
    workstationsScrollViewOffset,
    rightContainerFullWidth,
    workersScrollViewDisplayedHeight,
    workersScrollViewOffset,
    isScrolling,
  ]);

  function filterDisplayedWorkersAndWorkstations(
    _workersByShiftsOrOrgUnitWithDetails: WorkersByParentWithDetails | undefined,
    workstationsAndParentOrgUnitsMap: WorkstationsAndParentOrgUnitsMap | undefined,
    _workstationsScrollViewOffset: number,
    _rightContainerFullWidth: number,
    _workersScrollViewDisplayedHeight: number,
    _workersScrollViewOffset: number,
  ) {
    if (!isMounted.current) return;

    
    if (_workersByShiftsOrOrgUnitWithDetails) {
      let _filteredWorkersOrShiftOrOrgUnit: Array<WorkerWithParentNode | ParentWithWorkers> = [];
      Array.from(_workersByShiftsOrOrgUnitWithDetails.workersByParents.entries()).forEach(
        ([, workersByShift]) => {
          _filteredWorkersOrShiftOrOrgUnit.push(workersByShift);
          _filteredWorkersOrShiftOrOrgUnit = [
            ..._filteredWorkersOrShiftOrOrgUnit,
            ...workersByShift.workers.map(_worker => {
              return { ..._worker, nodeId: workersByShift.shift?.id ?? API.DataType.ORGUNIT };
            }),
          ];
        },
      );

      const _displayedFilteredWorkers = [..._filteredWorkersOrShiftOrOrgUnit].slice(
        Math.floor(_workersScrollViewOffset / ROW_HEIGHT),
        Math.floor(_workersScrollViewOffset / ROW_HEIGHT) +
          Math.ceil(
            (_workersScrollViewDisplayedHeight - (Spacings.xMedium + Spacings.Small)) / ROW_HEIGHT,
          ) +
          1,
      );
      setDisplayedLeftPanelFilteredWorkersAndParents(_displayedFilteredWorkers);
    }

    
    if (workstationsAndParentOrgUnitsMap) {
      const _filteredNodes = extractOrgUnitsAndWorkstationsFromMap(
        workstationsAndParentOrgUnitsMap,
      );

      const _displayedFilteredNodes = [..._filteredNodes].slice(
        Math.floor(_workstationsScrollViewOffset / CELL_WIDTH),
        Math.floor(_workstationsScrollViewOffset / CELL_WIDTH) +
          Math.ceil((_rightContainerFullWidth - Spacings.Medium) / CELL_WIDTH),
      );
      setDisplayedUpperPanelFilteredNodes(_displayedFilteredNodes);
    }
  }

  function allowWorkerWorkstationMatrixRender() {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;

      setRenderMatrix(true);
    });
  }

  return (
    <WorkstationWorkerLevelTableComponent
      renderMatrix={renderMatrix}
      selectedElement={selectedElement}
      displayedLeftPanelFilteredWorkersAndParents={displayedLeftPanelFilteredWorkersAndParents}
      displayedFilteredNodes={displayedFilteredNodes}
      isScrolling={isScrolling}
      workstationsScrollViewRef={workstationsScrollViewRef}
      filteredWorkstations={filteredWorkstations}
      workersByShiftsOrOrgUnitWithDetails={workersByShiftsOrOrgUnitWithDetails}
      workerWorkstationsScrollViewRef={workerWorkstationsScrollViewRef}
      computeMatrixLevelIcons={computeMatrixLevelIcons}
      rightContainerFullWidth={rightContainerFullWidth}
      openMenu={openMenu}
      menuObject={menuObject}
      levelsTableFullHeight={levelsTableFullHeight}
      workstationsScrollViewOffset={workstationsScrollViewOffset}
      workersScrollViewOffset={workersScrollViewOffset}
      workersScrollViewFullHeight={workersScrollViewFullHeight}
      treeNode={treeNode}
      adaptableHeight={adaptableHeight}
      workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
      workstationsAndParentOrgUnitsMap={workstationsAndParentOrgUnitsMap}
      nodeCount={nodeCount}
      workstationFooterDataMap={workstationFooterDataMap}
      setSelectedTargetsMenuElement={setSelectedTargetsMenuElement}
      setDisplayedLeftPanelFilteredWorkersAndParents={
        setDisplayedLeftPanelFilteredWorkersAndParents
      }
      setDisplayedUpperPanelFilteredNodes={setDisplayedUpperPanelFilteredNodes}
      setTreeNode={setTreeNode}
      onWorkstationTableScroll={onWorkstationTableScroll}
      onWorkerWorkstationTableScroll={onWorkerWorkstationTableScroll}
      setMenuObject={setMenuObject}
      setOpenMenu={setOpenMenu}
      setLevelsTableFullHeight={setLevelsTableFullHeight}
      setComputeObjectives={setComputeObjectives}
      setSelectedElement={setSelectedElement}
      setWorkstationsScrollViewOffset={setWorkstationsScrollViewOffset}
      setOpenTargetsMenu={setOpenTargetsMenu}
      handleShiftIconPress={handleShiftIconPress}
      setComputeToDoList={setComputeToDoList}
    />
  );
};
