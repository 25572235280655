import React, { useEffect, useState } from 'react';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { CompleteUserInfoComponent } from '../component';
import * as API from 'shared/backend-data';
import { ProfilePictureFile } from 'skillmgtweb/src/components/modify-worker-modal/container';
import logger from 'shared/util/Logger';
import { InteractionManager } from 'react-native';

interface Props {
  showHideModal: (value: boolean) => void;
}
enum CompleteUserInfoInputIds {
  FirstName = 'FirstName',
  LastName = 'LastName',
}

interface CollectedData {
  familyName?: string;
  firstName?: string;
  profilePicture?: string | null;
  imageFile?: File;
}

export const CompleteUserInfo: React.FC<Props> = props => {
  const { showHideModal } = props;
  const closeModal = () => showHideModal(false);

  const [worker, setWorker] = useState<API.Worker>();
  const [profilePictureFile, setProfilePictureFile] = useState<ProfilePictureFile | null>(null);
  let collectedData: CollectedData = {};
  const [showLoader, setLoading] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const worker = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(worker)) {
        logger.warn(worker);
      } else {
        setWorker({ ...worker });
      }
    });
  }, []);

  async function onFileChange(e: any) {
    if (!worker) return;
    setLoading(true);

    try {
      if (e.target.files) {
        const file = e.target.files[0];
        collectedData.imageFile = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          setProfilePictureFile({ link: '' + e.target?.result, file: file });
        };
      }
    } catch (error) {
      logger.error(error);
    }

    const file = e.target.files[0];
    const _worker = await API.uploadWorkerPicture(worker, file);
    if (API.isFailure(_worker)) {
      logger.warn(_worker);
    } else {
      setWorker({ ..._worker });
    }
    setLoading(false);
  }

  async function updateUser() {
    if (!worker) return;

    let imageKey: string | undefined;
    if (profilePictureFile) {
      const s3Object = await API.uploadFile(
        profilePictureFile.file,
        API.StorageVisibility.public,
        collectedData.profilePicture ?? undefined,
      );
      if (!isMounted.current) return;
      if (API.isFailure(s3Object)) {
        logger.warn(s3Object);
      } else {
        imageKey = s3Object.key;
      }
    }

    const workerUpdateInput: API.WorkerPartialUpdateInput = {
      id: worker.id,
      familyName:
        collectedData && collectedData.familyName ? collectedData.familyName : worker.familyName,
      firstName:
        collectedData && collectedData.firstName ? collectedData.firstName : worker.firstName,

      profilePicture: imageKey ?? collectedData.profilePicture,
    };

    const _worker = await API.updateWorker(workerUpdateInput);
    if (!isMounted.current) return;
    if (API.isFailure(_worker)) {
      logger.warn('Failure in Editing the worker', _worker);
      return _worker;
    }

    return _worker;
  }

  async function submit() {
    setLoading(true);
    const _editWorker = await updateUser();
    if (!isMounted.current) return;
    if (API.isFailure(_editWorker)) {
      logger.warn(_editWorker);
      setLoading(false);
      return;
    }
    closeModal();
    setLoading(false);
  }

  const handleInputs = (inputName: string, value: string) => {
    switch (inputName) {
      case CompleteUserInfoInputIds.FirstName:
        collectedData.firstName = value;
        break;
      case CompleteUserInfoInputIds.LastName:
        collectedData.familyName = value;
        break;
    }
  };

  return (
    <CompleteUserInfoComponent
      showLoader={showLoader}
      worker={worker}
      closeModal={closeModal}
      onFileChange={onFileChange}
      submit={submit}
      handleInputs={handleInputs}
    />
  );
};
