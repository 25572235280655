import React, { useEffect, useState, useContext } from 'react';
import { TreeNode, TreeDataType } from 'shared/backend-data/factoryCache/Tree';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { NodeMenuComponent } from '../component/index';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { InteractionManager } from 'react-native';
import * as _ from 'lodash-es';
import { ModalContext, ModalUtils } from 'shared/ui-component/Modal';
import { LoaderTrigger } from '../../../WorkstationTreePanel';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { UnitModal } from 'skillmgtweb/src/components/my-factory/workstations/component/orgunit-modal/container';
import { MenuItem } from 'shared/ui-component/Menu';
import { ReassignmentModal } from 'skillmgtweb/src/components/reassignment-modal/container';
import { t } from 'shared/localisation/i18n';
import { inviteWorker as _inviteWorker } from 'shared/util/WorkerUi';
import {
  deleteOrgUnitWarning,
  duplicateOrgUnit,
  deleteOrganizationalUnitWithErrorModalHandling,
  copyWorkstationOrOrganizationalUnitAndRequirements,
  deleteWorkstation as _deleteWorkstation,
  duplicateWorkstation,
  deleteWorkstationWarning,
} from 'shared/util/WorkstationUi';

interface Props {
  
  
  openMenu: boolean;
  topTreeNodes: TreeNode[];
  menuType: MenuType;
  selectedNode: TreeNode | undefined;
  defaultOrgUnit: API.OrganizationalUnit;
  yCoordinate: number;
  
  setOpenMenu: (openMenu: boolean) => void;
  setMenuType: (menuType: MenuType) => void;
  handleLoader: (isLoading: boolean, loadingTrigger?: LoaderTrigger) => void;
  saveTreeState: () => Promise<void>;
  setSelectedNode: (treeNode: API.TreeNode | undefined) => void;
}

export enum MenuType {
  AddOptionsMenu,
  AddOrgUnitMenu,
  AddWorkstationMenu,
  NodeEditMenu,
}

export interface WorkstationWorker {
  worker: API.Worker;
  workerWorkstation: API.WorkerWorkstation;
}

export const TreeNodeMenu: React.FC<Props> = props => {
  const {
    selectedNode,
    defaultOrgUnit,
    yCoordinate,
    topTreeNodes,
    openMenu,
    menuType,

    setOpenMenu,
    setMenuType,
    handleLoader,
    saveTreeState,
    setSelectedNode,
  } = props;

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const [loadingChildren, setLoadingChildren] = useState<boolean>(false);
  const [isTopTreeNode, setIsTopTreeNode] = useState<boolean>(false);
  const [selectedNodeWorkers, setSelectedNodeWorkers] = useState<
    API.Worker[] | WorkstationWorker[]
  >([]);
  const [preSelectedParentUnit, setPreSelectedParentUnit] =
    useState<API.OrganizationalUnit>(defaultOrgUnit);
  const [addUnit, setAddUnit] = useState<boolean>(false);
  const [showReassignmentModal, setShowReassignmentModal] = useState<boolean>(false);
  const [workerAssignments, setWorkerAssignments] = useState<API.AssignmentWithUnitDetails[]>();
  const [showUnitModal, setShowUnitModal] = useState<boolean>(false);

  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);

  useEffect(() => {
    setLoadingChildren(true);

    if (selectedNode) {
      if (topTreeNodes.includes(selectedNode)) {
        setIsTopTreeNode(true);
      } else {
        setIsTopTreeNode(false);
      }
      if (API.isOrganizationalUnit(selectedNode.object))
        setPreSelectedParentUnit(selectedNode.object);
    }

    InteractionManager.runAfterInteractions(async () => {
      loadSelectedNodeWorkers();
    });
  }, [selectedNode]);

  const orgUnitMenuItems: MenuItem[] = [
    {
      label: t('common:button.edit'),
      onPress: editOU,
    },
    {
      label: t('alex:workstations.addMenu.addOrgUnit'),
      onPress: addOU,
    },
    {
      label: t('alex:workstations.addMenu.addWorkstation'),
      onPress: addWorkstation,
    },
    {
      label: t('alex:unitModal.duplicateUnit'),
      onPress: duplicateOU,
    },
    {
      label: t('common:button.delete'),
      onPress: deleteOUWithModalWarning,
    },
  ];

  function duplicateOU() {
    if (selectedNode) {
      duplicateOrgUnit(modal, () => duplicateNode(selectedNode));
    }
  }

  function addWorkstation() {
    setMenuType(MenuType.AddWorkstationMenu);
  }

  function editOU() {
    setShowUnitModal(true);
    setAddUnit(false);
  }

  function addOU() {
    setShowUnitModal(true);
    setAddUnit(true);
  }

  async function deleteOUWithModalWarning() {
    if (!selectedNode) return;

    deleteOrgUnitWarning(modal, deleteOU, selectedNode.object.name);
  }

  async function deleteOU() {
    if (!selectedNode) return;
    const _workerAssignments = await API.getOrgUnitWorkersAssignments(selectedNode.id, false);
    if (API.isFailure(_workerAssignments)) {
      logger.error('getOrgUnitWorkerAssignments:error', _workerAssignments);
      return;
    }
    setWorkerAssignments(_workerAssignments);

    if (_workerAssignments.length) {
      setShowReassignmentModal(true);
    } else {
      await deleteOrganizationalUnitWithErrorModalHandling(
        selectedNode.id,
        modal,
        undefined,
        handleLoader,
      );
      setOpenMenu(false);
      setSelectedNode(undefined);
    }
  }

  async function loadSelectedNodeWorkers() {
    if (selectedNode) {
      if (API.isOrganizationalUnit(selectedNode.object)) {
        const workers = await API.getWorkersInOrganizationalUnits([selectedNode.id]);
        if (!isMounted.current) return;
        if (API.isFailure(workers)) {
          logger.error('getWorkersInOrganizationalUnits:error', workers);
        } else {
          setSelectedNodeWorkers(workers.result);
        }
      } else {
        const workers = await API.getWorkersSkilledOrPlannedToBeSkilled(
          workstationTargetsStartingAtLevel,
          [selectedNode.id],
          undefined,
        );
        if (!isMounted.current) return;
        if (API.isFailure(workers)) {
          logger.error('getWorkersInOrganizationalUnits:error', workers);
        } else {
          const workstationWorker: WorkstationWorker[] = [];
          const errors: API.Failure[] = [];
          await Promise.all(
            workers.map(async wWlevel => {
              const worker = await API.getWorker(wWlevel.workerId);
              if (API.isFailure(worker)) {
                errors.push(worker);
              } else {
                workstationWorker.push({
                  worker: worker,
                  workerWorkstation: wWlevel,
                });
              }
            }),
          );
          if (!isMounted.current) return;
          if (errors.length) {
            logger.error('Error in fetching workstation workers ', errors);
          } else {
            setSelectedNodeWorkers(workstationWorker);
          }
        }
      }
    }
    setLoadingChildren(false);
  }

  async function inviteWorker(worker: API.Worker) {
    setOpenMenu(false);
    handleLoader(true);
    await _inviteWorker(worker, modal, API.UserInvitationOperations.GIVE_ACCESS);
    if (!isMounted.current) return;
    handleLoader(false);
  }

  async function createNode(name: string, treeObjectDataType: TreeDataType): Promise<void> {
    await saveTreeState();
    if (!isMounted.current) return;

    const parentOrgUnit: API.OrganizationalUnit =
      selectedNode && API.isOrganizationalUnit(selectedNode.object)
        ? selectedNode.object
        : defaultOrgUnit;
    if (treeObjectDataType === API.DataType.ORGUNIT) {
      const organizationalUnit = await API.createOrganizationalUnit({
        parentId: parentOrgUnit.id,
        name: name,
      });
      if (!isMounted.current) return;
      if (API.isFailure(organizationalUnit)) {
        logger.error('Failed to create unit', organizationalUnit);
      }
    } else {
      const workstation = await API.createWorkstation({
        name: name,
        parentId: parentOrgUnit.id,
        description: '',
        files: [],
      });
      if (!isMounted.current) return;
      if (API.isFailure(workstation)) {
        logger.error('Failed to create workstation', workstation);
      }
    }
  }

  async function duplicateNode(node: TreeNode) {
    setOpenMenu(false);
    handleLoader(true, LoaderTrigger.duplicateNode);

    await saveTreeState();
    if (!isMounted.current) return;

    const result = await copyWorkstationOrOrganizationalUnitAndRequirements(modal, node.object);

    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      handleLoader(false, LoaderTrigger.duplicateNode);
      logger.warn('failed to duplicateNode', result);
    }

    handleLoader(false, LoaderTrigger.duplicateNode);
  }

  async function deleteWorkstation(workstationId: string, modal: ModalContext) {
    handleLoader(true);

    const deleteWorkstation = await _deleteWorkstation(workstationId, modal);
    if (!isMounted.current) return;
    if (API.isFailure(deleteWorkstation)) {
      logger.warn(deleteWorkstation);
      handleLoader(false);

      return;
    }

    handleLoader(false);
  }

  async function deleteNode(node: TreeNode) {
    handleLoader(true);
    if (API.isOrganizationalUnit(node.object)) {
      deleteOUWithModalWarning();
    } else {
      if (selectedNode)
        deleteWorkstationWarning(
          modal,
          () => deleteWorkstation(node.id, modal),
          selectedNode.object.name,
        );
    }

    await saveTreeState();
    if (!isMounted.current) return;

    handleLoader(false);
  }

  async function editNodeName(value: string, node: TreeNode) {
    if (node && value.length) {
      handleLoader(true);
      if (API.isOrganizationalUnit(node.object)) {
        const updateOrgUnitResult = await API.updateOrganizationalUnit({
          id: node.object.id,
          name: value,
        });
        if (API.isFailure(updateOrgUnitResult)) {
          logger.error('Failed to update unit', updateOrgUnitResult);
        }
      } else {
        const WorkstationUpdateResult = await API.updateWorkstation({
          id: node.object.id,
          name: value,
        });
        if (API.isFailure(WorkstationUpdateResult)) {
          logger.warn('Failed to update workstation', WorkstationUpdateResult);
        }
      }
      handleLoader(false);
    }
  }

  async function onSubmitEditing(inputValue: string): Promise<void> {
    setOpenMenu(false);
    switch (menuType) {
      case MenuType.AddOrgUnitMenu:
        handleLoader(true);
        await createNode(inputValue, API.DataType.ORGUNIT);
        break;

      case MenuType.AddWorkstationMenu:
        handleLoader(true);
        await createNode(inputValue, API.DataType.WORKSTATION);
        break;

      case MenuType.NodeEditMenu:
        if (selectedNode) {
          await editNodeName(inputValue, selectedNode);
        }
        break;
    }
  }

  async function _deleteNode() {
    if (selectedNode) {
      await deleteNode(selectedNode);
      setOpenMenu(false);
    }
  }

  function _duplicateNode(node: TreeNode) {
    if (API.isOrganizationalUnit(node.object)) {
      duplicateOrgUnit(modal, () => duplicateNode(node));
    } else {
      duplicateWorkstation(modal, () => duplicateNode(node));
    }
  }

  return (
    <>
      {openMenu && (
        <NodeMenuComponent
          selectedNode={selectedNode}
          selectedNodeWorkers={selectedNodeWorkers}
          yCoordinate={yCoordinate}
          isStartingNode={isTopTreeNode}
          openMenu={openMenu}
          loadingChildren={loadingChildren}
          menuType={menuType}
          preSelectedParentUnit={preSelectedParentUnit}
          orgUnitMenuItems={orgUnitMenuItems}
          setMenuType={setMenuType}
          duplicateNode={_duplicateNode}
          deleteNode={deleteNode}
          onSubmitEditing={onSubmitEditing}
          inviteWorker={inviteWorker}
          setOpenMenu={setOpenMenu}
          setShowUnitModal={setShowUnitModal}
          setAddUnit={setAddUnit}
        />
      )}

      {showUnitModal && (
        <UnitModal
          handleModalClose={() => {
            setOpenMenu(false);
            setShowUnitModal(false);
            setAddUnit(false);
          }}
          duplicateNode={_duplicateNode}
          deleteNode={deleteNode}
          preSelectedParentUnit={preSelectedParentUnit}
          node={addUnit ? undefined : selectedNode}
        />
      )}
      {showReassignmentModal &&
        selectedNode &&
        workerAssignments &&
        API.isOrganizationalUnit(selectedNode.object) && (
          <ReassignmentModal
            handleModalClose={() => setShowReassignmentModal(false)}
            orgUnit={selectedNode.object}
            workerAssignments={workerAssignments}
            confirmAction={_deleteNode}
          />
        )}
    </>
  );
};
