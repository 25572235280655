import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { SvgProps } from 'react-native-svg';
import { Spacings, TextFontStyles, Colors, fontSize } from 'shared/styles';

interface DetailsHeaderProps {
  title?: string;
  leftIcon?: React.FC<SvgProps>;
  rightText?: string;
  rightIcon?: React.FC<SvgProps>;
  onRightButtonClick?: () => void;
  onLeftButtonPress?: () => void;
}

export const DetailsHeader: React.FC<DetailsHeaderProps> = props => (
  <View style={styles.rootContainer}>
    <View style={styles.header}>
      <Text style={styles.headerText}>{props.title}</Text>
    </View>
    <View style={styles.buttonContainer}>
      <TouchableOpacity onPress={props.onLeftButtonPress} style={styles.leftIcon}>
        {props.leftIcon && (
          <IconSVG
            svgComponent={props.leftIcon}
            color={Colors.Black}
            size={{ height: Spacings.MobileIconSize, width: Spacings.MobileIconSize }}
          />
        )}
      </TouchableOpacity>
    </View>
    <View style={styles.textContainer}>
      {props.onRightButtonClick && (
        <TouchableOpacity onPress={props.onRightButtonClick} style={styles.rightText}>
          {props.rightText && <Text style={styles.rightText}>{props.rightText}</Text>}
          {props.rightIcon && <IconSVG svgComponent={props.rightIcon} color={Colors.Black} />}
        </TouchableOpacity>
      )}
    </View>
  </View>
);

const styles = StyleSheet.create({
  rootContainer: {
    width: '100%',
    alignItems: 'center',
  },
  leftIcon: {
    left: 0,
  },
  textContainer: {
    position: 'absolute',
    margin: Spacings.Standard,
    marginTop: Spacings.xMedium,
    right: 0,
  },
  header: {
    margin: Spacings.Standard,
    marginTop: Spacings.xMedium,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    maxWidth: '70%',
  },
  buttonContainer: {
    position: 'absolute',
    paddingLeft: Spacings.Standard,
    marginTop: Spacings.xMedium,
    flexDirection: 'row',
    width: '100%',
  },
  headerText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Medium,
    textAlign: 'center',
  },
  rightText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
  },
});
