import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';
import { Platform } from 'react-native';
const isPlatformWeb = Platform.OS === 'web';

export const Style = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.Background,
    borderRadius: Spacings.Standard,
    paddingHorizontal: Spacings.Medium,
  },
  inputText: {
    ...TextFontStyles.LatoRegular,
    fontSize: isPlatformWeb ? fontSize.Regular : fontSize.Medium,
    borderWidth: 0,
    outlineStyle: 'none',
    width: '100%',
    paddingRight: Spacings.Medium,
    paddingTop: Spacings.Small,
    paddingBottom: Spacings.Small,
    color: Colors.Grey,
  },
  attachFileContainer: {
    alignSelf: 'flex-end',
    width: Spacings.Standard,
    height: '100%',
    paddingTop: Spacings.Unit,
  },
  filesList: {
    width: 280,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Spacings.Large,
    paddingTop: Spacings.Small,
  },
});
