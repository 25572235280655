import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, InteractionManager } from 'react-native';
import { History } from 'history';
import { t, getLanguage } from 'shared/localisation/i18n';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import styles from './Styles';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';

interface menuItem {
  title: string;
  location?: History.LocationDescriptor;
}
interface Props {
  closeMenu: Function;
}

export const HelpMenu: React.FC<Props> = props => {
  const language = getLanguage();

  const dashboardMenuItmes: menuItem[] = [
    {
      title: t('alex:header.helpModal.dashboardScreenMenuItems.0'),
      location: 'Que-signifient-les-icônes-ILUO.html',
    },
    {
      title: t('alex:header.helpModal.dashboardScreenMenuItems.1'),
      location: 'Que-signifient-les-alertes-dans-les-objectifs-des-postes-de-travail.html',
    },
    {
      title: t('alex:header.helpModal.dashboardScreenMenuItems.2'),
      location: 'Combien-de-niveaux-3-et-4-sont-requis-sur-les-post.html',
    },
    {
      title: t('alex:header.helpModal.dashboardScreenMenuItems.3'),
      location: 'Où-voir-combien-de-collaborateurs-on- le-niveau-3-ou-4-sur-un-poste.html',
    },
    {
      title: t('alex:header.helpModal.dashboardScreenMenuItems.4'),
      location: 'Comment-monter-en-compétence-un-collaborateur-sur-un-poste.html',
    },
    {
      title: t('alex:header.helpModal.dashboardScreenMenuItems.5'),
      location: 'Où-voir-toutes-les-compétences-dun-collaborateur.html',
    },
    {
      title: t('alex:header.helpModal.dashboardScreenMenuItems.6'),
      location: 'Comment-filtrer-mon-tableau-de-bord.html',
    },
    {
      title: t('alex:header.helpModal.dashboardScreenMenuItems.7'),
      location: 'Comment-configurer-les-objectifs-de-polyvalence.html',
    },
  ];

  const trainingSessionScreenMenuItmes: menuItem[] = [
    {
      title: t('alex:header.helpModal.trainingSessionScreenMenuItems.0'),
      location: 'Comment-demander-un-tutorat-pour-un-collaborateur.html',
    },
    {
      title: t('alex:header.helpModal.trainingSessionScreenMenuItems.1'),
      location: 'Comment-demander-une-formation-pour-un-collaborateur.html',
    },
    {
      title: t('alex:header.helpModal.trainingSessionScreenMenuItems.2'),
      location: 'où-suivre-lavancée-des-formations.html',
    },
    {
      title: t('alex:header.helpModal.trainingSessionScreenMenuItems.3'),
      location: 'Comment-ajouter-une-preuve-lorsque-la-formation-est-finie.html',
    },
  ];

  const unitAndWorkstationScreenMenuItmes: menuItem[] = [
    {
      title: t('alex:header.helpModal.unitAndWorkstationScreenMenuItems.0'),
      location: 'Quelle-est-la-différence-entre-un-tutorat-et-une-formation.html',
    },
    {
      title: t('alex:header.helpModal.unitAndWorkstationScreenMenuItems.1'),
      location: 'Comment-configurer-les-postes.html',
    },
  ];

  const skillsScreenMenuItems: menuItem[] = [
    {
      title: t('alex:header.helpModal.skillsScreenMenuItems.0'),
      location: 'Compétence-quelle-est-la-différence-entre-une-compétence-pratique-et-théorique.html',
    },
    {
      title: t('alex:header.helpModal.skillsScreenMenuItems.1'),
      location: 'Comment-configurer-une-compétence.html',
    },
    {
      title: t('alex:header.helpModal.importDataInMass'),
      location: 'Comment-importer-en-masse-vos-données.html',
    },
  ];

  const trainingsScreenMenuItems: menuItem[] = [
    {
      title: t('alex:header.helpModal.trainingsScreenMenuItems.0'),
      location: 'Quest-ce-quune-formation-pratique-et-théorique.html',
    },
    {
      title: t('alex:header.helpModal.trainingsScreenMenuItems.1'),
      location: 'Comment-configurer-un-tutorat.html',
    },
    {
      title: t('alex:header.helpModal.importDataInMass'),
      location: 'Comment-importer-en-masse-vos-données.html',
    },
  ];

  const workersScreenMenuItems: menuItem[] = [
    {
      title: t('alex:header.helpModal.workersScreenMenuItems.0'),
      location: 'Où-voir-toutes-les-compétences-dun-collaborateur.html',
    },
    {
      title: t('alex:header.helpModal.workersScreenMenuItems.1'),
      location: 'Où-voir-lhistorique-des-preuves-dun-collaborateur.html',
    },
    {
      title: t('alex:header.helpModal.workersScreenMenuItems.2'),
      location: 'Comment-ajouter-à-un-collaborateur-une-preuve-de-compétence.html',
    },
    {
      title: t('alex:header.helpModal.workersScreenMenuItems.3'),
      location: 'Comment-ajouter-une-preuve-de-compétence.html',
    },
    {
      title: t('alex:header.helpModal.workersScreenMenuItems.4'),
      location: 'Comment-ajouter-une-preuve-de-formation.html',
    },
    {
      title: t('alex:header.helpModal.workersScreenMenuItems.5'),
      location: 'Comment-renouveler-une-preuve.html',
    },
    {
      title: t('alex:header.helpModal.workersScreenMenuItems.6'),
      location: 'Comment-inviter-un-collègue.html',
    },
    {
      title: t('alex:header.helpModal.workersScreenMenuItems.7'),
      location: 'Comment-configurer-les-permissions-des-collaborateurs-dans-alex.html',
    },
    {
      title: t('alex:header.helpModal.importDataInMass'),
      location: 'Comment-importer-en-masse-vos-données.html',
    },
  ];

  const [menuItems, setMenuItems] = useState<menuItem[]>(dashboardMenuItmes);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      _setMenuItems();
    });
  }, []);

  function _setMenuItems() {
    let _menuItems: menuItem[] = [];

    switch (window.location.pathname) {
      case RoutePaths.Skills:
        _menuItems = skillsScreenMenuItems;
        break;
      case RoutePaths.Trainings:
        _menuItems = trainingsScreenMenuItems;
        break;
      case RoutePaths.Workers:
        _menuItems = workersScreenMenuItems;
        break;
      case RoutePaths.Home:
        _menuItems = dashboardMenuItmes;
        break;
      case RoutePaths.Workstations:
        _menuItems = unitAndWorkstationScreenMenuItmes;
        break;
      case RoutePaths.MyFactory:
        _menuItems = unitAndWorkstationScreenMenuItmes;
        break;
      case RoutePaths.TrainingSessions:
        _menuItems = trainingSessionScreenMenuItmes;
        break;
    }

    setMenuItems(_menuItems);
  }

  function handleItemPress(item?: menuItem) {
    if (language.locale === 'fr') {
      if (item)
        window.open(
          `http://daher-alex-help.s3-website.eu-west-3.amazonaws.com/${language.locale}/${t('alex:app.name')}/${item.location}`,
          '_blank',
        );
      else
        window.open(`http://daher-alex-help.s3-website.eu-west-3.amazonaws.com/${language.locale}/${t('alex:app.name')}`, '_blank');
    } else {
      
      if (item)
        window.open(`http://daher-alex-help.s3-website.eu-west-3.amazonaws.com/en/${t('alex:app.name')}/${item.location}`, '_blank');
      else window.open(`http://daher-alex-help.s3-website.eu-west-3.amazonaws.com/en/${t('alex:app.name')}`, '_blank');
    }
  }

  const RenderItems: React.FC = () => {
    return (
      <>
        {menuItems &&
          menuItems.map((item, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  handleItemPress(item);
                }}
                key={index}
                style={styles.item}
                ref={useCallOnHover<TouchableOpacity>()}
              >
                <Text>{item.title}</Text>
              </TouchableOpacity>
            );
          })}
      </>
    );
  };

  return (
    <View style={styles.container} ref={useDetectOutsideClick<View>(props.closeMenu)}>
      <RenderItems />
      <TouchableOpacity
        ref={useCallOnHover<TouchableOpacity>()}
        onPress={() => handleItemPress()}
        style={[styles.item, { marginBottom: 0 }]}
      >
        <Text>{t('common:button.seeMore')}</Text>
      </TouchableOpacity>
    </View>
  );
};
