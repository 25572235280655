import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  profilePicContainer: {
    zIndex: -1,
  },
  normalText: {
    letterSpacing: 0.28,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
  reason: {
    ...TextFontStyles.LatoBlack,
  },
  dateString: {
    ...TextFontStyles.LatoRegular,
  },
  yellowButtonStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Medium,
    paddingHorizontal: Spacings.Big,
  },
  archiveContainer: {
    marginTop: Spacings.Standard + Spacings.Unit,
  },
  yellowButton: {
    height: 36,
    justifyContent: 'center',
    marginTop: Spacings.Unit,
  },
  inheritedUnitsText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  inheritedUnitsList: {
    left: -100,
    top: Spacings.Standard,
    minWidth: 120,
    maxWidth: 185,
    padding: 20,
    width: 185,
  },
  roleContainer: {
    flexDirection: 'row',
    width: 185,
    flexWrap: 'wrap',
  },
  unitPathContainer: {
    maxWidth: 170,
  },
  QRCodeContainer: {
    width: '100%',
    alignItems: 'center',
  },
  QRCodeImage: {
    height: 200,
    width: 200,
  },
  managerTextContainer: {
    flexDirection: 'row',
  },
});

export default styles;
