import * as _ from 'lodash-es';
import { Result, isFailure, FailureError } from '../Failure';
import { DataType, DataTypeUnknown, NotUndefined } from 'shared/backend-data';
import { AsyncLock } from '../../util-ts/AsyncLock';
import { loggerAPI as logger } from '../../util/Logger';
import { LocalForageTypeSuported, PersistentStorage } from '../PersistentStorage';
import { DataLayer, FactoryMutationCacheEvent } from '../DataLayer';
import { AppContext } from '../../context/AppContext';

const autoPersistOnChangeThrottle = 60000; 

export type CacheType = DataType | 'QueryCache';

export type CacheData<V extends LocalForageTypeSuported = {}> = {
  data: V;
  isFilled: boolean;
  /**
   * False when it is not initialized
   * Null when it is initializing
   * True when the expected data (see fillStrategy) is loaded
   */
  isInitialized: boolean | null;
};

export type Caches = {
  [key in CacheType]?: Cache<key>;
};

export type CachesConfig = {
  [key in CacheType]: {
    /** Tells whether this cache shall be filled with all its data when @see fill() is called */
    fillStrategy: boolean;
    /** Cache class to instantiate */
    class: new (pk: string, dataType: key, fillStrategy: boolean) => NotUndefined<Caches[key]>;
  };
};

/**
 * A cache tailored to store Factory
 */
export abstract class Cache<
  CT extends CacheType,
  V extends LocalForageTypeSuported = {},
  T extends DataType | DataTypeUnknown = CT extends 'QueryCache' ? DataTypeUnknown : CT,
> {
  
  
  

  private static readonly cachesPkMap: Map<string, Caches> = new Map();
  private static _cachesConfig: CachesConfig | null = null;

  static setCachesConfig(cachesConfig: CachesConfig) {
    this._cachesConfig = cachesConfig;
  }

  private static get cachesConfig(): CachesConfig {
    if (!Cache._cachesConfig) {
      throw new Error(
        'CachesConfig is not intialized. Please call ' + Cache.setCachesConfig.name + '() before.',
      );
    }
    return Cache._cachesConfig;
  }

  private static getCaches(pk: string): Caches {
    let pkCaches = this.cachesPkMap.get(pk);
    if (!pkCaches) {
      pkCaches = {};
      this.cachesPkMap.set(pk, pkCaches);
      logger.debug('CachesMap created for pk=' + pk);
    }
    return pkCaches;
  }

  /**
   * Get or create the Cache for the given pk and cacheType
   * @param cacheType
   * @param pk (optional) if not specified the context one is used
   */
  protected static getCache<CT extends CacheType>(cacheType: CT, pk?: string): Cache<CT> {
    if (!pk) {
      const context = AppContext.getContext();
      if (isFailure(context)) throw new FailureError(context);

      pk = context.pk;
    }

    const pkCaches = Cache.getCaches(pk);
    let cache = pkCaches[cacheType];
    if (cache === undefined) {
      const cacheConfig = Cache.cachesConfig[cacheType];
      cache = new cacheConfig.class(pk, cacheType, cacheConfig.fillStrategy);
      pkCaches[cacheType] = cache;
    }

    return cache;
  }

  /** @see purge() */
  static async purgeCaches(pk?: string): Promise<void> {
    if (pk) {
      for (const cache of Object.values(Cache.getCaches(pk))) {
        await cache.purge();
      }
    } else {
      for (const pk of Array.from(this.cachesPkMap.keys())) {
        await this.purgeCaches(pk);
      }
    }
  }

  /** @see persist() */
  static async persistCaches(pk: string): Promise<boolean> {
    const t0 = Date.now();

    let allSucessful = true;

    for (const cache of Object.values(Cache.getCaches(pk))) {
      const result = await cache.persist();
      if (result === false) allSucessful = false;
    }

    logger.info(
      'Cache ' +
        (allSucessful ? '' : 'partially') +
        ' persisted in ' +
        (Date.now() - t0) +
        ' ms for pk: ' +
        pk,
    );

    return allSucessful;
  }

  
  
  
  protected pk: string;
  protected cacheType: CT;
  /** Tells whether this cache shall be filled with all its data upon @see fill() */
  protected fillStrategy: boolean;
  private emptyData: V;
  protected cacheData: CacheData<V>;
  protected lock: AsyncLock;
  protected queue: FactoryMutationCacheEvent<T, false>[];

  /**
   * pk and cacheType shall be unique
   * @param pk
   * @param cacheType
   * @param fillStrategy whether the cache is intended to be filled at initialization time
   * @param emptyData to the data payload with no data at all
   */
  protected constructor(pk: string, cacheType: CT, fillStrategy: boolean, emptyData: V) {
    const existingCache = Cache.getCaches(pk)[cacheType];
    if (existingCache !== undefined) {
      throw new Error('Cache for pk=' + pk + ' and cacheType=' + cacheType + ' shall be unique');
    }

    this.pk = pk;
    this.cacheType = cacheType;
    this.fillStrategy = fillStrategy;
    this.emptyData = emptyData;
    this.cacheData = { data: this.emptyData, isFilled: false, isInitialized: false };
    this.lock = new AsyncLock();
    this.queue = [];
  }

  protected get data(): V {
    return this.cacheData.data;
  }

  /**
   * Try to restore persisted cache data.
   * @returns false in case of failure, true otherwise
   */
  async restore(): Promise<boolean> {
    return this.lock.runSerial(async () => {
      const persistedData = await this.getPersistedData();
      if (persistedData === null) return false;

      
      
      if (this.fillStrategy === true && persistedData.isFilled === false) {
        await this._fill();
        if (this.cacheData.isFilled === false) return false;
      }
      
      this.cacheData = persistedData;

      
      this.queue.forEach(factoryMutationEvent => {
        this.update(factoryMutationEvent, true);
      });

      logger.info(
        'Cache for pk: ' +
          this.pk +
          ' cacheType: ' +
          this.cacheType +
          ' restored with ' +
          (this.cacheData.data instanceof Map
            ? this.cacheData.data.size
            : (this.cacheData.data as any).sk?.size) +
          ' items',
        this.cacheData,
      );

      return true;
    });
  }

  protected async getPersistedData(): Promise<CacheData<V> | null> {
    const persistedData = await PersistentStorage.getItem<CacheData<V>>(this.cacheType, this.pk);
    if (persistedData !== null) {
      this.restore_deepFreezeFactories(persistedData.data);
    }

    return persistedData;
  }

  /**
   * Factories when persisted are NOT stored with their frozeen state.
   * So the forzen state needs to be restored manualy parsing the given restored data.
   * @param persistedData
   */
  protected abstract restore_deepFreezeFactories(data: V): void;

  /**
   * Fill-in the cache by querying all the required data so it is in sync with the server
   * @param force: (optional) force filling the cache regarless of the cacheConfig
   */
  async fill(force: boolean = false): Promise<void> {
    return this.lock.runSerial(async () => {
      return this._fill(force);
    });
  }
  private async _fill(force: boolean = false): Promise<void> {
    if (this.cacheData.isFilled) {
      logger.debug('Cache (cacheType=' + this.cacheType + ') is already filled.');
      return;
    }

    this.cacheData.isInitialized = null;

    if (force === false && this.fillStrategy === false) {
      logger.debug(
        'Cache (cacheType=' + this.cacheType + ') fill() bypassed (because of cache configuration)',
      );
    } else {
      const data = await this.baseSync();
      if (isFailure(data)) {
        logger.warn('Cannot fill the cache pk=' + this.pk + ' type=' + this.cacheType);
        this.cacheData.data = this.emptyData;
        this.cacheData.isFilled = false;
      } else {
        this.cacheData.data = data;
        this.cacheData.isFilled = true;
      }
    }

    
    this.queue.forEach(factoryMutationEvent => {
      this.update(factoryMutationEvent, true);
    });

    this.cacheData.isInitialized = true;

    if (
      DataLayer.cacheOptions.enableUpdates?.enableCache?.enablePersistence
        ?.enableAutoPersistenceOnChange
    ) {
      this.persist();
    }
  }

  /**
   * Get all the server data to fill this cache
   */
  protected abstract baseSync(): Promise<Result<V>>;

  /** Purge the given Cache freeing memory and persistent storage */
  async purge(): Promise<void> {
    return this.lock.runSerial(async () => {
      this.purge_inMemoryData(this.cacheData.data);
      this.cacheData.isFilled = false;

      await this.purge_persistedData();
    });
  }

  /**
   * Pruge the specified data by mutating it but not destroying it
   * @param data
   */
  protected abstract purge_inMemoryData(data: V): void;

  /**
   * Pruge the persistent storage
   */
  private purge_persistedData(): Promise<void> {
    return PersistentStorage.removeItem(this.cacheType, this.pk);
  }

  /**
   * Schedule the record of the in-memory cache into the persistent storage
   * for the next opportunity window. There is no warranty that the cache will be persisted.
   */
  async persist(): Promise<void>;
  /**
   * Try to persit immediatly the in-memory cache into the persistent storage
   * @param persistImmediatly true
   * @results false in case of failure
   */
  async persist(persistImmediatly: true): Promise<boolean>;
  async persist(persistImmediatly: boolean = false): Promise<void | boolean> {
    if (persistImmediatly) {
      return this.lock.runSerial(async () => {
        if (this.cacheData.isInitialized === false) return false;

        return this._persist(this.cacheData);
      });
    }

    return this.persistWithThrottle();
  }

  protected async _persist(cacheData: CacheData<V>): Promise<boolean> {
    const result = await PersistentStorage.setItem(this.cacheType, cacheData, this.pk);
    if (isFailure(result)) {
      logger.warn(this.pk + ' - ' + this.cacheType + ' could not be persisted', cacheData);
      return false;
    }

    logger.verbose(this.pk + ' - ' + this.cacheType + ' persisted', cacheData);
    return true;
  }

  private persistWithThrottleTooLongCount = 0;
  private persistWithThrottle = _.throttle(
    async () => {
      const t0 = Date.now();
      await this.persist(true);
      const delay = Date.now() - t0;
      logger.debug(
        this.pk + ' - ' + this.cacheType + ' auto persisted in ' + delay + ' ms',
        this.cacheData,
      );

      if (delay > 1000) {
        if (delay > 10000) {
          this.persistWithThrottleTooLongCount = 3; 
        } else {
          this.persistWithThrottleTooLongCount++;
        }
        logger.warn(
          this.pk + ' - ' + this.cacheType + ' auto persisted is taking too long: ' + delay + ' ms',
          this.cacheData,
        );

        
        if (
          this.persistWithThrottleTooLongCount >= 3 &&
          DataLayer.cacheOptions.enableUpdates?.enableCache?.enablePersistence
            ?.enableAutoPersistenceOnChange
        ) {
          logger.warn(
            this.pk + ' - ' + this.cacheType + ' auto persistence has been disabled automaticaly',
          );
          DataLayer.cacheOptions.enableUpdates.enableCache.enablePersistence.enableAutoPersistenceOnChange =
            false;
        }
      } else {
        this.persistWithThrottleTooLongCount = Math.max(
          0,
          this.persistWithThrottleTooLongCount - 1,
        );
      }
    },
    autoPersistOnChangeThrottle,
    {
      leading: false,
      trailing: true,
    },
  );

  /**
   * Process Factory's mutation in order to update the Cache instance
   * @param factoryMutationCacheEvent
   * @param disableAutoPersist even if the global parameter enableAutoPersistenceOnChange is set
   * (usualy it is not performant to auto persist while restoring / importing data : a final persist is more efficient)
   */
  update(
    factoryMutationCacheEvent: FactoryMutationCacheEvent<T, false>,
    disableAutoPersist: boolean,
  ): void {
    if (!this.cacheData.isInitialized) {
      this.queue.push(factoryMutationCacheEvent);
      return;
    }

    this.update_handleFactoryMutation(factoryMutationCacheEvent);

    if (
      !disableAutoPersist &&
      DataLayer.cacheOptions.enableUpdates?.enableCache?.enablePersistence
        ?.enableAutoPersistenceOnChange
    ) {
      this.persist();
    }
  }

  /**
   * Process Factory's mutation in order to update the Cache instance
   * @param cacheEvent
   */
  protected abstract update_handleFactoryMutation(
    cacheEvent: FactoryMutationCacheEvent<T, false>,
  ): void;
}
