import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity, ScrollView } from 'react-native';
import { Colors } from 'shared/styles/index';
import { IconSVG } from 'shared/ui-component/Icon';
import { PageSwitchProps } from '../container/index';
import { useIsMounted } from 'shared/hooks/IsMounted';
import Styles from './Style';
import { TextButton } from 'shared/ui-component/Button';

const backIcon = require('shared/assets/svg/icon.arrowLeft.mobile-2.svg').default;
const nextIcon = require('shared/assets/svg/icon.arrowLeft.mobile-2.svg').default;

const indexCellWidth = 24;
const indexCellMargin = 16;
const keepPreviousIndexShowingOffset = 32;

export const PageSwitchComponent: React.FC<PageSwitchProps> = props => {
  const isMounted = useIsMounted();
  const [indexArray, setIndexArray] = useState<number[]>([0]);
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex || 0);

  useEffect(() => {
    if (currentIndex !== -1) {
      if (currentIndex > props.maxIndex - 1 && currentIndex - 1 >= 0) {
        if (isMounted.current) setCurrentIndex(prev => prev - 1);
      }
      if (currentIndex < 0 && currentIndex + 1 < props.maxIndex - 1) {
        if (isMounted.current) setCurrentIndex(prev => prev + 1);
      }
    }
  }, [props.maxIndex]);

  useEffect(() => {
    if (props.maxIndex) {
      if (props.startFromZero) {
        const arr = Array.from(Array(props.maxIndex).keys());
        if (isMounted.current) setIndexArray(arr);
      } else {
        const arr = Array.from(Array(props.maxIndex + 1).keys());
        arr.splice(0, 1);
        if (isMounted.current) setIndexArray(arr);
      }
    }
  }, [props.maxIndex]);

  useEffect(() => {
    if (props.currentIndex !== undefined) setCurrentIndex(props.currentIndex);
  }, [props.currentIndex]);

  useEffect(() => {
    scollIndexRef.current?.scrollTo({
      x: currentIndex * (indexCellWidth + indexCellMargin) - keepPreviousIndexShowingOffset,
      animated: true,
    });
    if (props.onSwitch) props.onSwitch(currentIndex);
  }, [currentIndex]);

  const handleIndexPress = (index: number) => {
    if (isMounted.current) setCurrentIndex(index);
  };
  const handleGoBack = () => {
    if (currentIndex > 0) {
      if (isMounted.current) setCurrentIndex(prev => prev - 1);
    }
  };
  const handleGoNext = () => {
    if (!(currentIndex === indexArray.length - 1) && isMounted.current)
      setCurrentIndex(prev => prev + 1);
  };

  const scollIndexRef = useRef<ScrollView>(null);

  return (
    <View style={[Styles.container, props.containerStyle]}>
      <TouchableOpacity onPress={() => handleGoBack()} disabled={currentIndex === 0}>
        <IconSVG
          svgComponent={backIcon}
          size={{ width: 24, height: 24 }}
          color={currentIndex === 0 ? Colors.GreyLight : Colors.Grey}
        />
      </TouchableOpacity>
      <ScrollView
        ref={scollIndexRef}
        style={Styles.scrollContainer}
        horizontal
        contentContainerStyle={Styles.scrollContentContainer}
        showsHorizontalScrollIndicator={false}
      >
        {indexArray.map((pageIndex, arrayIndex) => (
          <TextButton
            key={arrayIndex}
            text={pageIndex.toString()}
            onPress={() => handleIndexPress(arrayIndex)}
            textStyle={[
              Styles.pageNumberTextStyle,
              { color: currentIndex === arrayIndex ? Colors.Black : Colors.Grey },
            ]}
            containerStyle={[
              Styles.touchableSwitch,
              { marginRight: indexCellMargin, width: indexCellWidth },
            ]}
          />
        ))}
      </ScrollView>
      <TouchableOpacity
        onPress={() => handleGoNext()}
        disabled={currentIndex === indexArray.length - 1}
        style={{ transform: [{ rotate: '-180deg' }] }}
      >
        <IconSVG
          svgComponent={nextIcon}
          size={{ width: 24, height: 24 }}
          color={currentIndex === indexArray.length - 1 ? Colors.GreyLight : Colors.Grey}
        />
      </TouchableOpacity>
    </View>
  );
};
