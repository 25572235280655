import { Colors, TextFontStyles, Spacings, createRoundedStyle, fontSize } from 'shared/styles';
import { StyleSheet } from 'react-native-web'; 

const Style = StyleSheet.create({
  alignCard: {
    marginLeft: -200,
  },
  cardContainer: {
    marginTop: Spacings.Standard,
    padding: 0,
    paddingVertical: Spacings.Small,
    minWidth: '240px',
    position: 'absolute',
    top: 0,
    left: -Spacings.Standard,
  },
  label: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingVertical: Spacings.Small,
    paddingHorizontal: Spacings.CardPadding,
  },
  hover: {
    backgroundColor: Colors.BlueRollover,
  },
  checkBoxContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: Spacings.Small,
  },
  displayLabel: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: Colors.Grey,
  },
  tableHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  tableHeader: {
    fontSize: fontSize.Regular,
    wordBreak: 'break-all',
  },
  iconContainer: {
    flexDirection: 'row',
    marginLeft: -Spacings.Small,
  },
  icon: {
    marginTop: Spacings.Unit,
    marginLeft: Spacings.Small,
  },
  circleView: {
    ...createRoundedStyle(6),
    backgroundColor: Colors.Yellow,
    marginTop: -(Spacings.Unit + Spacings.Small),
    marginLeft: -Spacings.Unit,
  },
  dragableArea: {
    width: 10,
    cursor: 'col-resize',
  },
  dragableAreaGhost: {
    width: 10,
    cursor: 'col-resize',
    position: 'absolute',
    height: '100%',
  },
  dragableBorder: {
    borderRightColor: Colors.Yellow,
    borderRightWidth: 2,
  },
  dragableBorderGhost: {
    borderRightWidth: 0,
    borderRightColor: Colors.Transparent,
  },
  dragableBorderOnHover: {
    borderRightColor: Colors.GreyLight,
    borderRightWidth: 2,
  },
});

export default Style;
