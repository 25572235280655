import React, { useContext } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, NativeScrollEvent } from 'react-native';
import styles from './styles';
import { Colors, TextFontStyles, fontSize } from 'shared/styles';
import { EllipsisWithTooltip, Position } from 'shared/ui-component/EllipsisWithTooltip';
import * as API from 'shared/backend-data';
import { NodeAndWorkers, WorkerDetail } from '.';
import { RouteLocations } from '../../../navigation/Routes';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { useHistory } from 'react-router-dom';
import { renderImage } from 'shared/util/RenderImage';
import { capitalize } from 'shared/localisation/i18n';
import { WorkerSkillBadge } from 'shared/ui-component/Badge';
import { WorkerVersatilityCount } from './WorkerVersatilityCount';
import { ImageSizes } from 'shared/util/RenderImage';
import * as _ from 'lodash-es';
import { HEADER_HEIGHT } from '../../versatility-panel/styles';
import { FlashList } from '@shopify/flash-list';

interface Props {
  nodeAndWorkers: NodeAndWorkers;
  adaptableHeight: number;
  workersScrollViewRef: any;

  setWorkersScrollViewDisplayedHeight: (height: number) => void;
  onWorkerTableScroll: (scroll: number) => void;
  setWorkersScrollViewOffset: (offset: number) => void;
}

interface WorkerRowProps {
  worker: WorkerDetail;
  index: number;
}

const _WorkerRow: React.FC<WorkerRowProps> = props => {
  const { worker, index } = props;

  const { isValidPermission } = useContext(PermissionManagementContext);

  const history = useHistory();

  return (
    <TouchableOpacity
      key={worker.id}
      style={[styles.userTouchableOpacity, { zIndex: -index }]}
      onPress={() => {
        history.push(RouteLocations.WorkerProfile(worker.id));
      }}
      disabled={!isValidPermission(API.Permission.workersDetail_view, undefined, [worker.id])}
    >
      <View style={[styles.workerNameRowContainer]}>
        {worker.isRenderDetails &&
          renderImage(worker.profilePicture, ImageSizes.Small, worker.name.charAt(0))}

        {worker.isRenderDetails && (
          <WorkerSkillBadge workerId={worker.id} style={styles.workerSkillBadge} />
        )}

        <EllipsisWithTooltip
          text={`${capitalize(worker.name)}`}
          textStyle={styles.userNameText}
          style={styles.workerName}
          toolTipContainerStyle={{ width: '100%' }}
          mouseHoverTextContainer={styles.workerHoverTextContainer}
          mouseHoverText={styles.workerHoverText}
          position={Position.TOP}
        />
      </View>
      <View>{worker.isRenderDetails && <WorkerVersatilityCount worker={worker} />}</View>
    </TouchableOpacity>
  );
};

const WorkerRow = React.memo(_WorkerRow, (prevProps, nextProps) => {
  const isEqual =
    _.isEqual(prevProps.worker, nextProps.worker) &&
    _.isEqual(prevProps.index, nextProps.index) &&
    _.isEqual(prevProps.index, nextProps.index);

  return isEqual;
});

const _WorkersList: React.FC<Props> = props => {
  const {
    nodeAndWorkers,
    adaptableHeight,
    workersScrollViewRef,

    setWorkersScrollViewDisplayedHeight,
    onWorkerTableScroll,
    setWorkersScrollViewOffset,
  } = props;

  return (
    <View
      style={[
        styles.usersFlatList,
        {
          width: '100%',
          height: adaptableHeight - HEADER_HEIGHT,
        },
      ]}
    >
      <FlashList
        ref={workersScrollViewRef as any}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        removeClippedSubviews={true}
        onLayout={e => {
          setWorkersScrollViewDisplayedHeight(e.nativeEvent.layout.height);
        }}
        onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
          onWorkerTableScroll(event.nativeEvent.contentOffset.y);
          setWorkersScrollViewOffset(event.nativeEvent.contentOffset.y);
        }}
        data={nodeAndWorkers}
        estimatedItemSize={46}
        renderItem={({ item, index }) => {
          const isShift = item && 'parentId' in item;
          if (!item || isShift) {
            return (
              <View style={{ zIndex: -index }} key={item?.id ?? API.DataType.ORGUNIT}>
                <View style={[styles.userTouchableOpacity, styles.userShiftWrapper]}>
                  {item && (
                    <EllipsisWithTooltip
                      style={[styles.userShiftText]}
                      text={API.capitalizeFirstLetter(item.name)}
                      mouseHoverText={{
                        ...TextFontStyles.LatoBlack,
                      }}
                      position={Position.TOP}
                      textStyle={{
                        color: Colors.MediumGray,
                        fontSize: fontSize.Standard,
                      }}
                    />
                  )}
                </View>
              </View>
            );
          } else {
            return <WorkerRow worker={item} index={index} />;
          }
        }}
        keyExtractor={(item, index) => {
          if (!item) {
            return API.DataType.ORGUNIT;
          } else if ('parentId' in item) {
            return item.id;
          } else {
            return item.id + index.toString();
          }
        }}
      />
    </View>
  );
};

export const WorkersList = React.memo(_WorkersList, (prevProps, nextProps) => {
  const isEqual = _.isEqual(prevProps.nodeAndWorkers, nextProps.nodeAndWorkers);

  return isEqual;
});
