import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { API as APIgraphql, Amplify } from 'aws-amplify';
import awsconfig from 'backend/src/aws-exports';
import { UserContext } from 'shared/context/UserContext';
import { GraphQLSubscription } from '@aws-amplify/api';
import * as SubscriptionsSchema from 'backend/src/api/graphql/subscriptions';
import { MyHub } from 'shared/util/MyHub';
import { Platform } from 'react-native'; 
import { Result } from './Failure';
import { loggerAPI as logger } from 'shared/util/Logger';
import * as GQL from './gql';
import { isFailure } from './Failure';
import { initStorageDriver, PersistentStorage, PersistentStorageKeys } from './PersistentStorage';
import { enumKeys, enumValues } from '../util-ts/Functions';
import { Cache, Caches, CachesConfig } from './factoryCache/Cache';
import { deepFreeze } from 'shared/util-ts/Functions';
import Aigle from 'aigle';
import { ZenObservable } from 'zen-observable-ts';
import { AsyncLock } from '../util-ts/AsyncLock';
import { applyKeyCondition, applyFilter } from 'shared/backend-data/QueryUtil';

import {
  CastByType,
  DataType,
  DataTypeUnknown,
  FactoryCache,
  FetchPolicy,
  GenericFactoryCache,
  GraphQLResult,
  ItemsQueriesType,
  Mutations,
  NotUndefined,
  QueryVariables,
  Subscriptions,
  WritableFactory,
} from 'shared/backend-data';
import { QueryCache } from './factoryCache/QueryCache';

import { logAnalytics, PerfOperations } from 'shared/util/AppMonitor';

/** We cannot guarantee that client have the same time as the server, so here is defined the accepted tolerance */
const ALLOWED_TIME_GAP_BETWEEN_CLIENT_AND_SERVER_IN_MS = 60 * 1000;

/** Cache Persistence life time value */
const CACHE_TIMEOUT_IN_MS = 7 * 24 * 3600 * 1000;

const asyncLock = new AsyncLock();

export type DataLayerOptions = {
  /** Receive Factory updates by registering to subscrition events */
  enableUpdates?: {
    enableCache?: {
      enablePersistence?: {
        /**
         * Auto persist the cache on changes. This is not required for the app sync mechanism to work.
         * It is useful to save the querries result of CacheTypes that are not filled by setting (like ProofBundle)
         */
        enableAutoPersistenceOnChange: boolean;
      };
      enableQueryCache?: {
        queryCacheAreCacheItemsOrdered: boolean;
      };
      factoryCacheDefaultFill: boolean;
      factoryCacheCustom: {
        [key in DataType]?: {
          /** Tells whether this cache shall be filled with all its data upon @see fill() */
          fill: boolean;
          /** FactoryCache class to instantiate */
          class?: new (pk: string, dataType: key, fill: boolean) => NotUndefined<Caches[key]>;
        };
      };
    };
  };
};

export enum CacheUpdateEventSource {
  ServerVersionChange = 'ServerVersionChange (API breaking change pushed)',
  ClientVersionChange = 'ClientVersionChange (Client breaking change pushed)',
  TenantAppSet = 'TenantOrTenantAppSet (App starting)',
  SubscriptionClientReconnect = 'SubscriptionClientReconnect (App reconnected)',
}

export type FactoryMutationCacheEvent<T extends DataType | DataTypeUnknown, B extends boolean> = {
  /**
   * True = when there are too many BusinessObject mutations received then individual mutation events are not broadcasted (to save CPU).
   * Listener shall then consider that their BusinessObject MIGHT have been updated
   * (and then should update the component after checking it was).
   * False = when individual mutation is broadcasted normaly (with the Factory payload).
   */
  tooManyMutations: B;
} & (
  | {
      mutationType: API.Mutations<DataType>['type'];
      factory: API.Factory<T>;
      /**
       * localPreviousFactory is neither guaranted to be passed nor accurate!
       * In case of subscription event, this value is undefined (so far 04/2021).
       * In case of own update event, it is grabbed from the cache before sending an Update mutation.
       * Other client mutation might have reached the client (via subscription) and update the Factory
       * before the update mutation returns, making this value not accurate.
       */
      localPreviousFactory?: API.Factory<T>;
      tooManyMutations: false;
    }
  | {
      factory: { dataType: T };
      tooManyMutations: true;
    }
);

type PendingMutationEvent<
  M extends Mutations<T>['type'] = Mutations<DataType>['type'],
  T extends DataType = DataType,
> = {
  mutationType: M;
  dataType: T;
  variables: CastByType<Mutations<T>, M>['variables'];
};

type SyncFactory = {
  factory: API.WritableFactory<API.DataTypeUnknown>;
  newlyCreated: boolean;
};

class APIDataLayer {
  private static instance: APIDataLayer;
  private subscriptionObjectsMap: Map<string, ZenObservable.Subscription[]> = new Map();

  private _options: DataLayerOptions | undefined;

  /**
   * Local (offline) mutations pending to be synced with the server
   */
  public pendingLocalMutation: PendingMutationEvent[] = [];
  private _pauseFactoryMutationBroadcast: boolean = false;
  private _pauseFactoryMutationBroadcastQueue: FactoryMutationCacheEvent<API.DataType, false>[] =
    [];
  private pauseSubscriptionEventHandlingQueue = new Map<string, WritableFactory<DataType>[]>();

  /**
   * Track each time a subscription event is received or when a mutation is sent
   */
  public mutationSentOrReceivedCounter: number = 0;

  /** private constructor to force singleton */
  private constructor() {}

  get cacheOptions(): DataLayerOptions {
    if (!this._options)
      throw new Error('CachesOptions not initialized, please call init() before.');
    return this._options;
  }

  /**
   * Init the Cache Context. If no argument is passed then default values are used to init setCachesConfig().
   * @param options
   * @returns
   */
  async initConfig(options?: DataLayerOptions | undefined): Promise<void> {
    this._options = options ?? {};

    await initStorageDriver();

    if (this.cacheOptions.enableUpdates?.enableCache) {
      const factoryCacheDefaultFill =
        this.cacheOptions.enableUpdates.enableCache.factoryCacheDefaultFill ?? false;
      const factoryCacheCustom =
        this.cacheOptions.enableUpdates.enableCache.factoryCacheCustom ?? {};

      
      let cachesConfig: CachesConfig = {} as CachesConfig; 
      {
        
        cachesConfig['QueryCache'] = { fillStrategy: false, class: QueryCache };

        
        for (const dataType of enumValues(DataType)) {
          const customConfig = factoryCacheCustom[dataType];
          cachesConfig[dataType] = {
            fillStrategy: customConfig !== undefined ? customConfig.fill : factoryCacheDefaultFill,
            class: (customConfig?.class as any) ?? GenericFactoryCache, 
          };
        }
      }
      Cache.setCachesConfig(cachesConfig);

      if (this.cacheOptions.enableUpdates.enableCache.enableQueryCache) {
        QueryCache.setQueryCacheAreCacheItemsOrdered(
          this.cacheOptions.enableUpdates.enableCache.enableQueryCache
            .queryCacheAreCacheItemsOrdered,
        );
      }

      logger.info('API Cache options = ', this.cacheOptions);
    } else {
      logger.info('API Cache disabled');
    }
  }

  /**
   * Restore the cache from the persisted storage
   * @param pk
   * @returns false in case of failure, true otherwise
   */
  private async restoreCache(pk: string): Promise<boolean> {
    return this.cacheLock.runSerial(async () => {
      for (const dataType of API.getPkDataTypes(pk)) {
        if (!(await FactoryCache.getFactoryCache(dataType, pk).restore())) return false;
      }

      
      if (this.cacheOptions.enableUpdates?.enableCache?.enableQueryCache) {
        if (!(await QueryCache.getQueryCache(pk).restore())) return false;
      }

      return true;
    });
  }

  /**
   * Fill the cache with all required data
   * @param pk
   * @returns
   */
  private async fillCache(pk: string): Promise<void> {
    return this.cacheLock.runSerial(async () => {
      const t0 = Date.now();
      logger.debug('Cache filling starting for pk: ' + pk);

      const pkType = API.getPkType(pk);
      let dataTypeCounter = 0;

      await Aigle.forEachLimit(API.getPkDataTypes(pk), 10, async dataType => {
        await FactoryCache.getFactoryCache(dataType, pk).fill();

        dataTypeCounter++;

        if (pkType === API.PkType.TenantApp) {
          MyHub.dispatchAppContext('TenantAppLoading', {
            loadingPercentage: (dataTypeCounter / enumKeys(API.DataType).length) * 100,
          });
        }
      });

      if (this.cacheOptions.enableUpdates?.enableCache?.enableQueryCache) {
        await QueryCache.getQueryCache(pk).fill();
      }

      logger.info(
        'Cache fill for pk: ' +
          pk +
          ' with all data on ' +
          dataTypeCounter +
          ' types in ' +
          (new Date().getTime() - t0) +
          ' ms.',
      );
    });
  }

  /**
   * Empty the in-memory cache and persistent storage
   * @param pk
   * @returns
   */
  async purgeCache(pk: string): Promise<void> {
    await Cache.purgeCaches(pk);

    await this.saveLastSyncToPersistentStorage(pk, null);
    await this.saveLastPurgeToPersistentStorage(pk);

    logger.info('Cache purged for pk: ' + pk);
  }

  /**
   * Subscribe to Factory mutations happening on the given pk
   * @param pk
   * @returns
   */
  async subscribeToUpdates(pk: string): Promise<void> {
    if (!this.cacheOptions.enableUpdates) return;

    
    if (this.subscriptionObjectsMap.get(pk)) return;

    
    const sub1 = APIgraphql.graphql<
      GraphQLSubscription<CastByType<Subscriptions<DataTypeUnknown>, 'onCreateFactory'>['result']>
    >({
      query: GQL.removeProps(API.DataTypeUnknown, SubscriptionsSchema.onCreateFactory),
      variables: { pk: pk },
    }).subscribe({
      next: d => {
        this.onSubscriptionEvent('onCreateFactory', d.value);
      },
      error: async error => {
        logger.error('Subscription onCreateFactory error:', error.error?.errors, error);
      },
    });

    
    const sub2 = APIgraphql.graphql<
      GraphQLSubscription<CastByType<Subscriptions<DataTypeUnknown>, 'onUpdateFactory'>['result']>
    >({
      query: GQL.onUpdateFactoryGql(API.DataTypeUnknown),
      variables: { pk: pk },
    }).subscribe({
      next: d => {
        this.onSubscriptionEvent('onUpdateFactory', d.value);
      },
      error: async error => {
        logger.error('Subscription onUpdateFactory error:', error.error?.errors, error);
      },
    });

    
    const sub3 = APIgraphql.graphql<
      GraphQLSubscription<CastByType<Subscriptions<DataTypeUnknown>, 'onDeleteFactory'>['result']>
    >({
      query: GQL.onDeleteFactoryGql(API.DataTypeUnknown),
      variables: { pk: pk },
    }).subscribe({
      next: d => {
        this.onSubscriptionEvent('onDeleteFactory', d.value);
      },
      error: async error => {
        logger.error('Subscription onDeleteFactory error:', error.error?.errors, error);
      },
    });

    this.subscriptionObjectsMap.set(pk, [sub1, sub2, sub3]);
  }

  /**
   * Remove the subscriptions to Factory mutation for a given pk
   * @param pk
   */
  async unSubscribeToUpdates(pk: string) {
    const subscribtionObjects = this.subscriptionObjectsMap.get(pk);
    if (subscribtionObjects) {
      subscribtionObjects.forEach(subscribtionObject => {
        subscribtionObject.unsubscribe();
      });
      this.subscriptionObjectsMap.delete(pk);
    }
  }

  async unSubscribeToAllUpdates() {
    Array.from(this.subscriptionObjectsMap.keys()).forEach(pk => {
      this.unSubscribeToUpdates(pk);
    });
  }

  private onSubscriptionEvent<T extends Subscriptions<API.DataType>['type']>(
    /**
     * Test types by removing all the 'as any' inside the function, replace T by 'createFactory' or 'updateFactory' or ...
     * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
     */
    type: T,
    result: GraphQLResult<CastByType<Subscriptions<API.DataTypeUnknown>, typeof type>['result']>,
  ): void {
    logger.verbose('on onSubscriptionEvent', type, result);

    this.mutationSentOrReceivedCounter++;

    const factory = API.extractDataFromFetchResult(type, result as any); 
    if (API.isFailure(factory)) {
      logger.error('Error onSubscriptionEvent: ' + factory.message, factory, result);
      return;
    }

    if (!factory) {
      logger.error('Cache ' + type + ' subscription event received with a null factory:', factory);
      return;
    }

    logger.debug('on onSubscriptionEvent', type, factory);

    const pauseQueue = this.pauseSubscriptionEventHandlingQueue.get(factory.pk);
    if (pauseQueue) {
      pauseQueue.push(factory);
    } else {
      this.updateCacheAndBroadCast(factory);
    }
  }

  

  private cacheLock = new AsyncLock();

  /**
   * Sync data with server (fetch all data, persist them and save lastSyncDate into the persistent cache)
   * @params pk to sync
   */
  async syncClientCacheWithServer(pk: string): Promise<Result<void>> {
    const t0 = Date.now();

    await this._syncClientCacheWithServer(pk);

    logAnalytics(PerfOperations.SyncClientCacheWithServer, { pk, durationInMs: Date.now() - t0 });
  }
  async _syncClientCacheWithServer(pk: string): Promise<Result<void>> {
    if (!this.cacheOptions.enableUpdates?.enableCache) return;

    let pauseQueue = this.pauseSubscriptionEventHandlingQueue.get(pk);
    if (!pauseQueue) {
      pauseQueue = [];
      this.pauseSubscriptionEventHandlingQueue.set(pk, pauseQueue);
    }

    this.pauseFactoryMutationBroadcast(true);

    const t0 = Date.now();
    let startedAt = await this.deltaSync(pk);
    logAnalytics(PerfOperations.DeltaSync, { pk, durationInMs: Date.now() - t0 });
    if (isFailure(startedAt)) {
      logger.warn(
        'Sync client failure: ' + startedAt.message + '. Falling back to base sync.',
        startedAt,
      );

      const t0 = Date.now();
      startedAt = await this.baseSync(pk);
      const t1 = Date.now();
      logAnalytics(PerfOperations.BaseSync, { pk, durationInMs: t1 - t0 });
    } else if (startedAt === false) {
      const t0 = Date.now();
      startedAt = await this.baseSync(pk);
      logAnalytics(PerfOperations.BaseSync, { pk, durationInMs: Date.now() - t0 });
    }

    const t1 = Date.now();
    const result = await Cache.persistCaches(pk);
    logAnalytics(PerfOperations.PersistCache, { pk, durationInMs: Date.now() - t1 });
    if (result === false)
      return API.createFailure_Unspecified(
        'Some FactoryCaches failed to persist in persistant storage',
      );

    const t2 = Date.now();
    await this.saveLastSyncToPersistentStorage(pk, startedAt);
    logAnalytics(PerfOperations.SaveLastSync, { pk, durationInMs: Date.now() - t2 });

    this.pauseFactoryMutationBroadcast(false);

    
    if (pauseQueue.length > 0)
      logger.info('Processing ' + pauseQueue.length + ' Subscription Events for pk=' + pk);

    const t3 = Date.now();
    while (pauseQueue.length > 0) {
      const factory = pauseQueue.shift();
      if (factory) this.updateCacheAndBroadCast(factory);
    }
    logAnalytics(PerfOperations.UpadeCache, { pk, durationInMs: Date.now() - t3 });
    this.pauseSubscriptionEventHandlingQueue.delete(pk);
  }

  async forceBaseSyncAtNextSyncNeeded(pk: string) {
    return PersistentStorage.setItem(
      PersistentStorageKeys.ForceBaseSyncAtNextSyncNeededKey,
      true,
      pk,
    );
  }
  async forceBaseSyncAtNextSync(pk: string) {
    return PersistentStorage.setItem(PersistentStorageKeys.ForceBaseSyncAtNextSyncKey, true, pk);
  }
  async isForceBaseSyncAtNextSyncNeeded(pk: string) {
    return PersistentStorage.getItem<boolean>(
      PersistentStorageKeys.ForceBaseSyncAtNextSyncNeededKey,
      pk,
    );
  }
  async isForceBaseSyncAtNextSync(pk: string): Promise<boolean | null> {
    return PersistentStorage.getItem<boolean>(PersistentStorageKeys.ForceBaseSyncAtNextSyncKey, pk);
  }

  /**
   * Make a base sync fetching all the data that needs to be synced
   * @param pk to sync
   * @returns startedAd time
   */
  private async baseSync(pk: string): Promise<number> {
    await this.purgeCache(pk);

    const startedAd = Date.now() - ALLOWED_TIME_GAP_BETWEEN_CLIENT_AND_SERVER_IN_MS;
    await this.fillCache(pk);

    await PersistentStorage.setItem(PersistentStorageKeys.ForceBaseSyncAtNextSyncKey, false, pk);
    await PersistentStorage.setItem(
      PersistentStorageKeys.ForceBaseSyncAtNextSyncNeededKey,
      false,
      pk,
    );

    const userPreferenceFactory = await API._getOrCreateUserTenantAppPreference();
    if (!API.isFailure(userPreferenceFactory) && userPreferenceFactory.pk === pk) {
      let userPreferenceIsForceBaseSync = API.extractPreferences({
        ...userPreferenceFactory.userPreference,
        updatedAt: userPreferenceFactory.updatedAt,
        updatedBy: userPreferenceFactory.updatedBy,
      }).get(API.UserPreferenceKeys_Common.ForceBaseSync);

      await API.saveUserPreference(API.UserPreferenceKeys_Common.ForceBaseSync, false);
    }

    return startedAd;
  }

  /**
   * @param pk to sync
   * @returns
   *  - startedAt (datetime of fetching the sync factories) in case of sucessful sync query
   *  - false for expected reason of failure like :
   *    -- persisted cache timed out
   *    -- no lastSyncDate (this is the first time ever this app is launching on this client)
   *    -- lastSync is greater than the delta sync date time to live
   *  - failure for other errors
   */
  private async deltaSync(pk: string): Promise<Result<false | number>> {
    if (await this.isForceBaseSyncAtNextSync(pk)) return false;

    if (await this.isPersistedCacheTimedOut(pk)) return false;

    const userPreferenceFactory = await API._getOrCreateUserTenantAppPreference();
    if (!API.isFailure(userPreferenceFactory) && userPreferenceFactory.pk === pk) {
      const userPreferenceIsForceBaseSync = API.extractPreferences({
        ...userPreferenceFactory.userPreference,
        updatedAt: userPreferenceFactory.updatedAt,
        updatedBy: userPreferenceFactory.updatedBy,
      }).get(API.UserPreferenceKeys_Common.ForceBaseSync);

      if (userPreferenceIsForceBaseSync) return false;
    }

    const lastSync = await PersistentStorage.getItem<number>(PersistentStorageKeys.LastSyncKey, pk);
    if (!lastSync) return false;

    if (!(await this.restoreCache(pk))) return false;

    
    const t0 = Date.now();
    const factories = await API.syncFactories(pk, lastSync);
    logAnalytics(PerfOperations.SyncFactories, { pk, durationInMs: Date.now() - t0 });
    if (isFailure(factories)) return factories;

    if (!factories?.result)
      return API.createFailure_Unspecified('SyncFactories does not contains result', factories);

    
    const syncedFactories = this.packSyncFactories(factories.result);
    const t2 = Date.now();
    for (const syncedFactory of syncedFactories) {
      const factory = this.updateCacheAndBroadCast(syncedFactory.factory);
      if (isFailure(factory)) return factory;
    }
    logAnalytics(PerfOperations.SyncFactoriesUpdateCache, { pk, durationInMs: Date.now() - t2 });

    if (!factories.startedAt) {
      logger.error(
        'syncFactories shall returns a startedAt property, it is mandatory. The Delta sync is considered as a failure and will be trashed.',
      );
      return false;
    }

    return factories.startedAt;
  }

  /**
   * @param factories
   * filters the given factories so that if the Factory was mutated several times
   * it keeps only the latest one
   */
  private packSyncFactories(factories: API.WritableFactory<DataTypeUnknown>[]): SyncFactory[] {
    const factoriesMaxVersionMap = new Map<string, SyncFactory>();
    factories.forEach(factory => {
      const key = factory.pk + factory.sk;
      let syncFactory = factoriesMaxVersionMap.get(key);
      if (!syncFactory) {
        syncFactory = { factory, newlyCreated: factory._version === 1 };
      } else if (factory._version > syncFactory.factory._version) {
        
        syncFactory.factory = factory;
      }
      
      else if (factory._version === 1) {
        syncFactory.newlyCreated = true;
      } else {
        return;
      }
      factoriesMaxVersionMap.set(key, syncFactory);
    });

    return Array.from(factoriesMaxVersionMap.values());
  }

  private async saveLastSyncToPersistentStorage(
    pk: string,
    lastSync: number | null,
  ): Promise<void> {
    if (lastSync === null) {
      await PersistentStorage.removeItem(PersistentStorageKeys.LastSyncKey, pk);
    } else {
      const r = await PersistentStorage.setItem(PersistentStorageKeys.LastSyncKey, lastSync, pk);
      if (!API.isFailure(r)) {
        logger.info('Client lastSync has been persisted for pk: ' + pk);
      }
    }
  }

  private async saveLastPurgeToPersistentStorage(pk: string): Promise<void> {
    const r = await PersistentStorage.setItem(PersistentStorageKeys.LastPurgeKey, Date.now(), pk);
    if (!API.isFailure(r)) {
      logger.debug('Client lastPurge has been persisted for pk: ' + pk);
    }
  }

  private async isPersistedCacheTimedOut(pk: string): Promise<boolean> {
    const lastPurge = await PersistentStorage.getItem<number>(
      PersistentStorageKeys.LastPurgeKey,
      pk,
    );
    if (!lastPurge) return true;

    return lastPurge + CACHE_TIMEOUT_IN_MS < Date.now();
  }

  /**
   * Query the FactoryCache
   * @param queryType
   * @param dataType SHALL be consistent with the passed queryVariables in order to get accurte results (particularly with the beginsWith queryVariable).
   *                 It is ensured here by callling this function only from @see _itemsFactoryAPI() where the dataType/queryVariables cehck is done.
   * @param queryVariables
   * @param fetchPolicy
   * @returns the result if found, 'ObjectNotFound' if the cache was filled but no object found, undefined otherwise
   */
  queryFactoryCache<T extends DataType>(
    queryType: 'getFactory',
    dataType: T,
    queryVariables: QueryVariables<typeof queryType, T>,
    fetchPolicy: FetchPolicy,
  ): API.Factory<T> | 'ObjectNotFound' | undefined;
  queryFactoryCache<T extends DataType>(
    queryType: ItemsQueriesType,
    dataType: T,
    queryVariables: QueryVariables<typeof queryType, T>,
    fetchPolicy: FetchPolicy,
  ): API.Factory<T>[] | undefined;
  queryFactoryCache<T extends DataType>(
    queryType: ItemsQueriesType | 'getFactory',
    dataType: T,
    queryVariables: QueryVariables<typeof queryType, T>,
    fetchPolicy: FetchPolicy,
  ): (API.Factory<T>[] | undefined) | (API.Factory<T> | 'ObjectNotFound' | undefined) {
    if (
      !this.cacheOptions.enableUpdates?.enableCache ||
      fetchPolicy === 'network-and-cache' ||
      fetchPolicy === 'network-no-cache'
    )
      return;

    if (API.isAllFactoriesByType(queryType) || API.isSyncFactories(queryType)) {
      
      
      return;
    }

    const queryPk = (queryVariables as QueryVariables<typeof queryType, T>).pk;
    if (!queryPk) return;

    const factoryCache = FactoryCache.getFactoryCache(dataType, queryPk);

    if (API.isGetFactory(queryType)) {
      const sk = (queryVariables as QueryVariables<typeof queryType, T>).sk;
      if (!sk) return;

      const factory = factoryCache.getFactory(sk);
      if (API.isFailure(factory)) {
        logger.warn('CacheContext (pk=' + queryPk + ') : ' + factory.message, factory);
        return;
      }

      return factory;
    } else if (
      API.isItemsByType(queryType) ||
      API.isListFactorys(queryType) ||
      API.isItemsByData(queryType)
    ) {
      if (
        (queryVariables as QueryVariables<typeof queryType, T>).nextToken ||
        (queryVariables as QueryVariables<typeof queryType, T>).limit ||
        (queryVariables as QueryVariables<typeof queryType, T>).sortDirection
      ) {
        logger.warn(
          'queryFactoryCache: The query contains one or more unsupported variables (either nextToken, limit, or sortDirection)',
          queryVariables,
        );
        return;
      }

      
      let factories: API.Result<API.Factory<T>[]>;
      if (API.isItemsByType(queryType)) {
        const keyCondition = (queryVariables as QueryVariables<typeof queryType, T>).dataType;
        if (keyCondition?.eq !== dataType) {
          logger.warn(
            'queryFactoryCache for itemsByType only supports keyCondition.eq=dataType.',
            queryVariables,
          );
          return;
        }
        factories = factoryCache.getFactories();
      } else if (API.isListFactorys(queryType)) {
        const keyCondition = (queryVariables as QueryVariables<typeof queryType, T>).sk;

        if (keyCondition?.eq) {
          factories = factoryCache.getFactories({
            index: 'sk',
            type: 'equal',
            factorySkOrData_or_keys: keyCondition.eq,
          });
        } else if (keyCondition?.beginsWith) {
          factories = factoryCache.getFactories({
            index: 'sk',
            type: 'startingWith',
            factorySkOrData_or_keys: keyCondition.beginsWith,
          });
        } else if (
          keyCondition?.ge ||
          keyCondition?.gt ||
          keyCondition?.lt ||
          keyCondition?.le ||
          keyCondition?.between
        ) {
          factories = factoryCache.getFactories();
          if (!API.isFailure(factories)) {
            factories = applyKeyCondition(factories, {
              sk: keyCondition,
            });
          }
        } else {
          factories = factoryCache.getFactories();
        }
      } else if (API.isItemsByData(queryType)) {
        const keyCondition = (queryVariables as QueryVariables<typeof queryType, T>).data;

        if (keyCondition?.eq) {
          factories = factoryCache.getFactories({
            index: 'data',
            type: 'equal',
            factorySkOrData_or_keys: keyCondition.eq,
          });
        } else if (keyCondition?.beginsWith) {
          factories = factoryCache.getFactories({
            index: 'data',
            type: 'startingWith',
            factorySkOrData_or_keys: keyCondition.beginsWith,
          });
        } else if (
          keyCondition?.ge ||
          keyCondition?.gt ||
          keyCondition?.lt ||
          keyCondition?.le ||
          keyCondition?.between
        ) {
          factories = factoryCache.getFactories();
          if (!API.isFailure(factories)) {
            factories = applyKeyCondition(factories, {
              data: keyCondition,
            });
          }
        } else {
          factories = factoryCache.getFactories();
        }
      } else {
        logger.error(
          'queryFactoryCache queryType ' + queryType + ' not recognized : please fix code',
        );
        return;
      }

      if (API.isFailure(factories)) {
        logger.debug('CacheContext: ' + factories.message, factories);
        return;
      }

      
      const queryFilter = (queryVariables as QueryVariables<typeof queryType, T>).filter;
      if (queryFilter) {
        return applyFilter(factories, queryFilter) ?? undefined;
      } else {
        return factories;
      }
    } else {
      logger.error(`queryFactoryCache: the query ${queryType} type is not supported`);
      return;
    }
  }

  pauseFactoryMutationBroadcast(b: boolean) {
    this._pauseFactoryMutationBroadcast = b;

    if (b === false) {
      this._pauseFactoryMutationBroadcastQueue.forEach(factoryMutationCacheEvent => {
        MyHub.dispatchBusinessObject('BusinessObjectMutate', {
          data: factoryMutationCacheEvent,
        });
      });
    }
  }

  /**
   * Update cache according to the passed mutation:
   *  - Mutate the cached factory with the given factory
   *  - Discard the cached queries that depends on the given factory
   *  - Broadcast asynchrnously the event through the 'BusinessObjectMutate' channel
   * @param factory Factory that was mutated
   * @param bypassCaching (optional, default=false) : if true, it forces the factory to not be saved into the cache
   * @returns the (cached) Factory marked as an immutable object
   */
  updateCacheAndBroadCast<T extends DataType>(
    factory: API.WritableFactory<T>,
    bypassCaching: boolean = false,
  ): API.Result<API.Factory<T>> {
    let localPreviousFactory: API.Factory<T> | undefined;
    const _localPreviousFactory = API.getFactory(
      factory.dataType as T, 
      factory.sk,
      'cache-only',
      factory.pk,
    );

    if (API.isFailure(_localPreviousFactory)) {
      if (!API.isFailureType(_localPreviousFactory, 'ObjectNotFound')) {
        logger.warn(
          'Error getting previous factory, it should not happen, please check: ' +
            _localPreviousFactory.message,
          _localPreviousFactory,
        );
      }
    } else {
      localPreviousFactory = _localPreviousFactory;
    }

    
    if (localPreviousFactory && localPreviousFactory._version >= factory._version) {
      if (localPreviousFactory._version > factory._version) {
        
        
        logger.debug(
          'UpdateCache: Factory outdated: A most recent version is present in the Cache. (Cache will not be updated)',
          localPreviousFactory,
          factory,
        );
      }
      return localPreviousFactory;
    }

    const _factory = deepFreeze(factory);

    /**
     *  cacheMutationType is slightly different that the original mutation event in order to reflect the mutation for the factory inside the client cache
     */
    let cacheMutationType: API.Mutations<DataType>['type'];
    
    
    
    if (factory._deleted) {
      cacheMutationType = 'deleteFactory';
    } else if (!localPreviousFactory || factory._version === 1) {
      cacheMutationType = 'createFactory';
    } else {
      cacheMutationType = 'updateFactory';
    }

    const factoryMutationCacheEvent: FactoryMutationCacheEvent<T, false> = {
      factory: _factory,
      mutationType: cacheMutationType,
      localPreviousFactory,
      tooManyMutations: false,
    };

    if (this.cacheOptions.enableUpdates?.enableCache && !bypassCaching) {
      
      const factoryCache = FactoryCache.getFactoryCache(_factory.dataType as T, _factory.pk);
      factoryCache.update(factoryMutationCacheEvent, this._pauseFactoryMutationBroadcast);

      
      if (this.cacheOptions.enableUpdates.enableCache.enableQueryCache) {
        QueryCache.getQueryCache(_factory.pk).update(
          factoryMutationCacheEvent,
          this._pauseFactoryMutationBroadcast,
        );
      }
    }

    
    if (this._pauseFactoryMutationBroadcast) {
      this._pauseFactoryMutationBroadcastQueue.push(factoryMutationCacheEvent);
    } else {
      MyHub.dispatchBusinessObject('BusinessObjectMutate', {
        data: factoryMutationCacheEvent,
      });
    }

    return _factory;
  }

  static getInstance(): APIDataLayer {
    if (!APIDataLayer.instance) {
      APIDataLayer.instance = new APIDataLayer();

      
      
      if (Platform.OS === 'web' && typeof window !== 'undefined') {
        awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
        awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
      }

      
      Amplify.configure({
        ...awsconfig,
        API: {
          graphql_headers: async () => {
            return {
              Authorization: await UserContext.getJwtToken(),
            };
          },
        },
      });
    }

    return APIDataLayer.instance;
  }
}

/**
 * Data context that will define how to interact with the data API (cache strategy, database change update policy, etc.)
 * @see initConfig
 * @see DataLayerOptions
 */
export const DataLayer = APIDataLayer.getInstance();
