import React from 'react';
import { HeaderTitleProvider } from 'shared/context/collaborateur-profile-context/CollaborateurProfileContext';
import { RouteComponentProps } from 'react-router-dom';
import { TrainingsComponent } from './component';

export const Trainings: React.FC<RouteComponentProps> = props => (
  <HeaderTitleProvider>
    <TrainingsComponent {...props} />
  </HeaderTitleProvider>
);
