/**
 * This file is manualy generated until we can automatically generate it as api-auto.ts
 * The aim is to create the API output types and remove the optional types on properties that are not optional.
 * It also includes update types for the business objects.
 * Warning, don't import from elsewhere than './api-auto' (as we are copy/pasting this file to backend/function via commit hook, it might break the compilation of the function)
 */
import { GraphQLError } from 'graphql/error/GraphQLError';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as APIa from './api/api-auto';
import { DataType } from './api/api-auto';
import logger from 'shared/util/Logger';
import { Immutable, Writable, hasKey } from 'shared/util-ts/Functions';
import { Result, createFailure_Unspecified, isFailure } from 'shared/backend-data/Failure';
import { AsyncLock } from 'shared/util-ts/AsyncLock';
import { cloneDeep } from 'lodash-es';
import { validate as validateUUID } from 'uuid';

export type { GraphQLError } from 'graphql/error/GraphQLError';
export type { GraphQLResult } from '@aws-amplify/api-graphql';
export {
  
  DataType,
  WorkstationWorkerLevelTargetWarning,
  WorkerState,
  StorageVisibility,
  ModelSortDirection,
  Permission,
  AuthenticationType,
  ReviewState,
  ModelAttributeTypes,
  UserInvitationOperations,
  Validity,
  WorkstationWorkerLevel,
  AppId,
  AppFeature,
  LevelIconType,
} from './api/api-auto';
export type {
  AppConfig,
  Feature,
  
  ModelIDInput,
  ModelIDKeyConditionInput,
  ModelSizeInput,
  ModelStringInput,
  ModelStringKeyConditionInput,
  ModelFactoryFilterInput,
  ModelDataTypeInput,
  ModelIntInput,
  ModelFloatInput,
  InviteUserInput,
} from './api/api-auto';

/**
 * sk =  dataType#uuid OR dataType#,dataType1#uuuId1,dataType2#uuId2 (OR dataType1#uuuId1,dataType2#uuId2 for backward compatibility)
 */
export const SeparatorIds = ',';
/**
 * id/sk = dataType#uuid OR dataType#,dataType1#uuuId1,dataType2#uuId2 (OR dataType1#uuuId1,dataType2#uuId2 for backward compatibility)
 */
export const SeparatorDataType = '#';
/**
 * dataTypeRelation = dataType1_dataType2
 */
export const SeparatorDataTypeRelation = '_';

/**
 * The following props are special to the database as records metadata that shall not be set directly
 * '_version' is an exception as we need it when mutating an object
 */
export const DBreservedFactoryProperties = ['__typename', '_lastChangedAt', '_delete'];

/**
 * Possible Partition Key Type
 */
export enum PkType {
  TenantApp,
  Tenant,
  Global,
  User,
}
/**
 * The pk of the global partition ( that contains App object)
 */
export const globalPk = 'global';

export const tenantIDsPk = 'TenantIDs';

export const adminRoleId = DataType.ROLE + SeparatorDataType + '0';
export const workerRoleId = DataType.ROLE + SeparatorDataType + '1';
export const workerManagerRoleId = DataType.ROLE + SeparatorDataType + '2';
export const observerRoleId = DataType.ROLE + SeparatorDataType + '3';
export const qualityRoleId = DataType.ROLE + SeparatorDataType + '4';
export const humanRessourceRoleId = DataType.ROLE + SeparatorDataType + '5';
export const externalTrainerEmail = 'externalTrainer@yelhow.com';
export const externalTrainerWorkerId = DataType.WORKER + SeparatorDataType + 'ExternalTrainer';

export interface AuthorizedPk {
  pk: string;
  workerId?: string;
}

/** When updating this defition, please update NoMetadata and mergePartialBusinessObject() */
type Metadata<T> = T & { updatedAt: string; updatedBy: string };
export type NoMetadata<T> = Omit<T, 'updatedAt' | 'updatedBy'>;

export const DataTypeUnknown = 'DataTypeUnknown';
export type DataTypeUnknown = typeof DataTypeUnknown;
/** extends DataType to handle DataTypeUnknown */
type DataType2<T extends DataType | DataTypeUnknown> = T extends DataTypeUnknown ? DataType : T;
/**
 * WARNING this list shall be manually maintained when new relation type are set into the database schema
 *
 *   type FilterRelationFactory< T extends string> = T extends `${infer BO1}${typeof SeparatorDataTypeRelation}${infer BO2}` ? T : never;
 *   type DataTypeStrings = keyof typeof DataType; <- we could rely on that string types instead of DataType type everywhere in the code ..
 */
export type DataTypeRelationBusinessObject =
  | DataType.TENANT_APP
  | DataType.WORKER_TENANT_APP
  | DataType.TRAINING_TRAININGVERSION;
/**
 * Any dataType that is not a relationship datatype (i.e. containing #API.SeparatorDataTypeRelation)
 * WARNING this list shall be manually maintained when new businessobject types are set into the database schema
 *
 * export const FILTER_KEYS = ['name', 'surname'] as const;
 * export type FilterKey = typeof FILTER_KEYS[number];
 */
export type DataTypeBusinessObject = Exclude<DataType, DataTypeRelationBusinessObject>;
export const businessObjectFactoryKey: {
  [K in DataTypeBusinessObject]: BusinessObjectFactoryKeys<K>;
} = {
  APP: 'app',
  CONTRACTTYPE: 'contractType',
  ORGUNIT: 'organizationalUnit',
  PROOFBUNDLE: 'proofBundle',
  REQUIREMENT: 'requirement',
  ROLE: 'role',
  SHIFT: 'shift',
  SKILL: 'skill',
  SKILLTAG: 'skillTag',
  TENANT: 'tenant',
  TRAINING: 'training',
  TRAININGTAG: 'trainingTag',
  TRAININGSESSION: 'trainingSession',
  TRAININGVERSION: 'trainingVersion',
  USERPREFERENCE: 'userPreference',
  WORKSTATION: 'workstation',
  WORKERWORKSTATION: 'workerWorkstation',
  WORKER: 'worker',
  WORKERSKILL: 'workerSkill',
  WORKERTAG: 'workerTag',
  WORKERTRAININGSESSION: 'workerTrainingSession',
  TENANTID: 'tenantID',
};

type BusinessObjectFactoryKeys<T extends DataType | DataTypeUnknown = DataTypeUnknown> =
  keyof ExtractObjectProps<T extends DataType ? Factory<T> : NonNullableProperties<___Factory>>;

export type BusinessObjectCreateInput<T extends DataTypeBusinessObject> = Flatten<
  ExtractObjectProps<CreateFactoryInput<T>>
>;
export type BusinessObjectUpdateInput<T extends DataTypeBusinessObject> = Flatten<
  ExtractObjectProps<UpdateFactoryInput<T>>
>;
export type BusinessObjectPartialUpdateInput<T extends DataTypeBusinessObject> = PartialUpdate<
  BusinessObjectUpdateInput<T>
>;
export type BusinessObject<T extends DataTypeBusinessObject> = Flatten<
  ExtractObjectProps<Factory<T>>
>;

export type CreateFactoryInput<T extends DataType> = Cast<_CreateFactoryInput, 'dataType', T>;
type _CreateFactoryInput = Omit<APIa.CreateFactoryInput, BusinessObjectFactoryKeys> &
  (
    | {
        dataType: DataType.APP;
        app: AppCreateInput;
      }
    | {
        dataType: DataType.CONTRACTTYPE;
        contractType: ContractTypeCreateInput;
      }
    | {
        dataType: DataType.ORGUNIT;
        organizationalUnit: OrganizationalUnitCreateInput;
      }
    | {
        dataType: DataType.PROOFBUNDLE;
        proofBundle: ProofBundleCreateInput;
      }
    | {
        dataType: DataType.REQUIREMENT;
        requirement: RequirementCreateInput;
      }
    | {
        dataType: DataType.ROLE;
        role: RoleCreateInput;
      }
    | {
        dataType: DataType.SHIFT;
        shift: ShiftCreateInput;
      }
    | {
        dataType: DataType.SKILL;
        skill: SkillCreateInput;
      }
    | {
        dataType: DataType.SKILLTAG;
        skillTag: SkillTagCreateInput;
      }
    | {
        dataType: DataType.TENANT;
        tenant: TenantCreateInput;
      }
    | {
        dataType: DataType.TENANT_APP;
        app: AppCreateInput;
        tenant: TenantCreateInput;
      }
    | {
        dataType: DataType.TRAINING;
        training: TrainingCreateInput;
      }
    | {
        dataType: DataType.TRAININGSESSION;
        trainingSession: TrainingSessionCreateInput;
      }
    | {
        dataType: DataType.TRAINING_TRAININGVERSION;
        training: TrainingCreateInput;
        trainingVersion: TrainingVersionCreateInput;
      }
    | {
        dataType: DataType.TRAININGTAG;
        trainingTag: TrainingTagCreateInput;
      }
    | {
        dataType: DataType.TRAININGVERSION;
        trainingVersion: TrainingVersionCreateInput;
      }
    | {
        dataType: DataType.USERPREFERENCE;
        userPreference: UserPreferenceCreateInput;
      }
    | {
        dataType: DataType.WORKER;
        worker: WorkerCreateInput;
      }
    | {
        dataType: DataType.WORKERSKILL;
        workerSkill: WorkerSkillCreateInput;
      }
    | {
        dataType: DataType.WORKERTAG;
        workerTag: WorkerTagCreateInput;
      }
    | {
        dataType: DataType.WORKER_TENANT_APP;
        app: AppCreateInput;
        tenant: TenantCreateInput;
        worker: WorkerCreateInput;
      }
    | {
        dataType: DataType.WORKERTRAININGSESSION;
        workerTrainingSession: WorkerTrainingSessionCreateInput;
      }
    | {
        dataType: DataType.WORKSTATION;
        workstation: WorkstationCreateInput;
      }
    | {
        dataType: DataType.WORKERWORKSTATION;
        workerWorkstation: WorkerWorkstationCreateInput;
      }
    | {
        dataType: DataType.TENANTID;
        tenantID: TenantIDCreateInput;
      }
  );

export type PartialUpdateFactoryInput<T extends DataType> = PartialUpdate<UpdateFactoryInput<T>>;
export type UpdateFactoryInput<T extends DataType> = Cast<_UpdateFactoryInput, 'dataType', T>;
type _UpdateFactoryInput = Omit<APIa.UpdateFactoryInput, BusinessObjectFactoryKeys> &
  (
    | {
        dataType: DataType.APP;
        app: AppUpdateInput;
      }
    | {
        dataType: DataType.CONTRACTTYPE;
        contractType: ContractTypeUpdateInput;
      }
    | {
        dataType: DataType.ORGUNIT;
        organizationalUnit: OrganizationalUnitUpdateInput;
      }
    | {
        dataType: DataType.PROOFBUNDLE;
        proofBundle: ProofBundleUpdateInput;
      }
    | {
        dataType: DataType.REQUIREMENT;
        requirement: RequirementUpdateInput;
      }
    | {
        dataType: DataType.ROLE;
        role: RoleUpdateInput;
      }
    | {
        dataType: DataType.SHIFT;
        shift: ShiftUpdateInput;
      }
    | {
        dataType: DataType.SKILL;
        skill: SkillUpdateInput;
      }
    | {
        dataType: DataType.SKILLTAG;
        skillTag: SkillTagUpdateInput;
      }
    | {
        dataType: DataType.TENANT;
        tenant: TenantUpdateInput;
      }
    | {
        dataType: DataType.TENANT_APP;
        app: AppUpdateInput;
        tenant: TenantUpdateInput;
      }
    | {
        dataType: DataType.TRAINING;
        training: TrainingUpdateInput;
      }
    | {
        dataType: DataType.TRAINING_TRAININGVERSION;
        training: TrainingUpdateInput;
        trainingVersion: TrainingVersionUpdateInput;
      }
    | {
        dataType: DataType.TRAININGSESSION;
        trainingSession: TrainingSessionUpdateInput;
      }
    | {
        dataType: DataType.TRAININGTAG;
        trainingTag: TrainingTagUpdateInput;
      }
    | {
        dataType: DataType.TRAININGVERSION;
        trainingVersion: TrainingVersionUpdateInput;
      }
    | {
        dataType: DataType.USERPREFERENCE;
        userPreference: UserPreferenceUpdateInput;
      }
    | {
        dataType: DataType.WORKER;
        worker: WorkerPartialUpdateInput;
      }
    | {
        dataType: DataType.WORKERSKILL;
        workerSkill: WorkerSkillUpdateInput;
      }
    | {
        dataType: DataType.WORKERTAG;
        workerTag: WorkerTagUpdateInput;
      }
    | {
        dataType: DataType.WORKER_TENANT_APP;
        app: AppUpdateInput;
        tenant: TenantUpdateInput;
        worker: WorkerUpdateInput;
      }
    | {
        dataType: DataType.WORKERTRAININGSESSION;
        workerTrainingSession: WorkerTrainingSessionUpdateInput;
      }
    | {
        dataType: DataType.WORKSTATION;
        workstation: WorkstationUpdateInput;
      }
    | {
        dataType: DataType.WORKERWORKSTATION;
        workerWorkstation: WorkerWorkstationUpdateInput;
      }
    | {
        dataType: DataType.TENANTID;
        tenantID: TenantIDUpdateInput;
      }
  );

export type Factory<T extends DataType | DataTypeUnknown> = Immutable<_Factory<T>>;
export type WritableFactory<T extends DataType | DataTypeUnknown> = _Factory<T>;
type _Factory<T extends DataType | DataTypeUnknown> = T extends DataType
  ? Cast<__Factory, 'dataType', DataType2<T>>
  : __Factory;
/** Arbitrary extracted from CreateFactoryMutation (it could be extracted from another Mutation...) */
type ____Factory = NonNullable<APIa.CreateFactoryMutation['createFactory']>;
type ___Factory = Modify<
  ____Factory,
  {
    createdAt: string;
    owner: string;
    updatedAt: string;
    updatedBy: string;
  }
>;
type __Factory = Omit<___Factory, BusinessObjectFactoryKeys> &
  (
    | {
        dataType: DataType.APP;
        app: NoMetadata<App>;
      }
    | {
        dataType: DataType.CONTRACTTYPE;
        contractType: NoMetadata<ContractType>;
      }
    | {
        dataType: DataType.ORGUNIT;
        organizationalUnit: NoMetadata<OrganizationalUnit>;
      }
    | {
        dataType: DataType.PROOFBUNDLE;
        proofBundle: NoMetadata<ProofBundle>;
      }
    | {
        dataType: DataType.REQUIREMENT;
        requirement: NoMetadata<Requirement>;
      }
    | {
        dataType: DataType.ROLE;
        role: NoMetadata<Role>;
      }
    | {
        dataType: DataType.SHIFT;
        shift: NoMetadata<Shift>;
      }
    | {
        dataType: DataType.SKILL;
        skill: NoMetadata<Skill>;
      }
    | {
        dataType: DataType.SKILLTAG;
        skillTag: NoMetadata<SkillTag>;
      }
    | {
        dataType: DataType.TENANT;
        tenant: NoMetadata<Tenant>;
      }
    | {
        dataType: DataType.TENANT_APP;
        app: NoMetadata<App>;
        tenant: NoMetadata<Tenant>;
      }
    | {
        dataType: DataType.TRAINING;
        training: NoMetadata<Training>;
      }
    | {
        dataType: DataType.TRAINING_TRAININGVERSION;
        training: NoMetadata<Training>;
        trainingVersion: NoMetadata<TrainingVersion>;
      }
    | {
        dataType: DataType.TRAININGSESSION;
        trainingSession: NoMetadata<TrainingSession>;
      }
    | {
        dataType: DataType.TRAININGTAG;
        trainingTag: NoMetadata<TrainingTag>;
      }
    | {
        dataType: DataType.TRAININGVERSION;
        trainingVersion: NoMetadata<TrainingVersion>;
      }
    | {
        dataType: DataType.USERPREFERENCE;
        userPreference: NoMetadata<UserPreference>;
      }
    | {
        dataType: DataType.WORKER;
        worker: NoMetadata<Worker>;
      }
    | {
        dataType: DataType.WORKERSKILL;
        workerSkill: NoMetadata<WorkerSkill>;
      }
    | {
        dataType: DataType.WORKERTAG;
        workerTag: NoMetadata<WorkerTag>;
      }
    | {
        dataType: DataType.WORKER_TENANT_APP;
        app: NoMetadata<App>;
        tenant: NoMetadata<Tenant>;
        worker: NoMetadata<Worker>;
      }
    | {
        dataType: DataType.WORKERTRAININGSESSION;
        workerTrainingSession: NoMetadata<WorkerTrainingSession>;
      }
    | {
        dataType: DataType.WORKSTATION;
        workstation: NoMetadata<Workstation>;
      }
    | {
        dataType: DataType.WORKERWORKSTATION;
        workerWorkstation: NoMetadata<WorkerWorkstation>;
      }
    | {
        dataType: DataType.TENANTID;
        tenantID: NoMetadata<TenantID>;
      }
  );

export type TenantCreateInput = APIa.TenantInput;
export interface TenantUpdateInput extends TenantCreateInput {
  id: string;
}
export interface TenantPartialUpdateInput extends PartialUpdate<TenantUpdateInput> {}
export type Tenant = Immutable<
  Metadata<
    {
      __typename: 'Tenant';
      apps: Array<APIa.AppConfig>;
    } & TenantUpdateInput
  >
>;

export interface TenantIDCreateInput extends APIa.TenantIDInput {}
export interface TenantIDUpdateInput extends TenantIDCreateInput {
  id: string;
}
export interface TenantIDPartialUpdateInput extends PartialUpdate<TenantIDUpdateInput> {}
export type TenantID = Immutable<
  Metadata<
    {
      __typename: 'TenantID';
    } & TenantIDUpdateInput
  >
>;

export interface AppCreateInput extends APIa.AppInput {}
export interface AppUpdateInput extends AppCreateInput {}
export interface AppPartialUpdateInput extends PartialUpdate<AppUpdateInput> {}
export type App = Immutable<
  Metadata<
    {
      __typename: 'App';
    } & AppUpdateInput
  >
>;

export type RoleCreateInput = APIa.RoleInput;
export interface RoleUpdateInput extends RoleCreateInput {
  id: string;
}
export interface RolePartialUpdateInput extends PartialUpdate<RoleUpdateInput> {}
export type Role = Immutable<
  Metadata<
    {
      __typename: 'Role';
    } & RoleUpdateInput
  >
>;

export type ContractTypeCreateInput = APIa.ContractTypeInput;
export interface ContractTypeUpdateInput extends ContractTypeCreateInput {
  id: string;
}
export interface ContractTypePartialUpdateInput extends PartialUpdate<ContractTypeUpdateInput> {}
export type ContractType = Immutable<
  Metadata<
    {
      __typename: 'ContractType';
    } & ContractTypeUpdateInput
  >
>;

export type ContractInput = APIa.ContractInput;
export type Contract = APIa.Contract;

export type OrganizationalUnitCreateInput = APIa.OrganizationalUnitInput;
export interface OrganizationalUnitUpdateInput extends OrganizationalUnitCreateInput {
  id: string;
}
export interface OrganizationalUnitPartialUpdateInput
  extends PartialUpdate<OrganizationalUnitUpdateInput> {}
export type OrganizationalUnit = Immutable<
  Metadata<
    {
      __typename: 'OrganizationalUnit';
      order: number;
      pathIds: Array<string>;
    } & OrganizationalUnitUpdateInput
  >
>;

export interface WorkstationCreateInput extends APIa.WorkstationInput {}
export interface WorkstationUpdateInput extends WorkstationCreateInput {
  id: string;
}
export interface WorkstationPartialUpdateInput extends PartialUpdate<WorkstationUpdateInput> {}
export type Workstation = Immutable<
  Metadata<
    {
      __typename: 'Workstation';
      files: Array<S3Object>;
      order: number;
      pathIds: Array<string>;
    } & WorkstationUpdateInput
  >
>;

export interface WorkerWorkstationCreateInput extends APIa.WorkerWorkstationInput {}
export interface WorkerWorkstationUpdateInput extends WorkerWorkstationCreateInput {
  id: string;
  previousLevel: APIa.WorkstationWorkerLevel;
  isTrainAuto: boolean;
}
export interface WorkerWorkstationPartialUpdateInput
  extends PartialUpdate<WorkerWorkstationUpdateInput> {}
export type WorkerWorkstation = Immutable<
  Metadata<
    {
      __typename: 'WorkerWorkstation';
      validSkills?: Array<WorkerSkillInfo> | null;
      validExpireSoonSkills?: Array<WorkerSkillInfo> | null;
      invalidExpiredSkills?: Array<WorkerSkillInfo> | null;
      invalidNoRefreshSkills?: Array<WorkerSkillInfo> | null;
      invalidMissingSkills?: Array<MissingWorkerSkillInfo> | null;
    } & WorkerWorkstationUpdateInput
  >
>;

export type WorkerSkillInfoInput = APIa.WorkerSkillInfoInput;
export type WorkerSkillInfo = APIa.WorkerSkillInfo;

export type MissingWorkerSkillInfoInput = APIa.MissingWorkerSkillInfoInput;
export type MissingWorkerSkillInfo = APIa.MissingWorkerSkillInfo;

export interface WorkerCreateInput extends APIa.WorkerInput {}
export interface WorkerUpdateInput extends WorkerCreateInput {
  id: string;
}
export interface WorkerPartialUpdateInput extends PartialUpdate<WorkerUpdateInput> {}
export type Worker = Immutable<
  Metadata<
    {
      __typename: 'Worker';
      contracts: Array<Contract>;
      isAdmin: boolean;
      state: APIa.WorkerState;
      /** Only settable by Backend */
      scope: string;
    } & WorkerUpdateInput
  >
>;

export type WorkerTagCreateInput = APIa.WorkerTagInput;
export interface WorkerTagUpdateInput extends WorkerTagCreateInput {
  id: string;
}
export interface WorkerTagPartialUpdateInput extends PartialUpdate<WorkerTagUpdateInput> {}
export type WorkerTag = Immutable<
  Metadata<
    {
      __typename: 'WorkerTag';
    } & WorkerTagUpdateInput
  >
>;

export type ShiftCreateInput = APIa.ShiftInput;
export interface ShiftUpdateInput extends ShiftCreateInput {
  id: string;
}
export interface ShiftPartialUpdateInput extends PartialUpdate<ShiftUpdateInput> {}
export type Shift = Immutable<
  Metadata<
    {
      __typename: 'Shift';
    } & ShiftUpdateInput
  >
>;

export interface SkillCreateInput extends APIa.SkillInput {}
export interface SkillUpdateInput extends SkillCreateInput {
  id: string;
}
export interface SkillPartialUpdateInput extends PartialUpdate<SkillUpdateInput> {}
export type Skill = Immutable<
  Metadata<
    {
      __typename: 'Skill';
      files: Array<S3Object>;
    } & SkillUpdateInput
  >
>;

export type SkillTagCreateInput = APIa.SkillTagInput;
export interface SkillTagUpdateInput extends SkillTagCreateInput {
  id: string;
}
export interface SkillTagPartialUpdateInput extends PartialUpdate<SkillTagUpdateInput> {}
export type SkillTag = Immutable<
  Metadata<
    {
      __typename: 'SkillTag';
    } & SkillTagUpdateInput
  >
>;

export type TrainingTagCreateInput = APIa.TrainingTagInput;
export interface TrainingTagUpdateInput extends TrainingTagCreateInput {
  id: string;
}
export interface TrainingTagPartialUpdateInput extends PartialUpdate<TrainingTagUpdateInput> {}
export type TrainingTag = Immutable<
  Metadata<
    {
      __typename: 'TrainingTag';
    } & TrainingTagUpdateInput
  >
>;

export interface TrainingCreateInput extends APIa.TrainingInput {}
export interface TrainingUpdateInput extends TrainingCreateInput {
  id: string;
}
export interface TrainingPartialUpdateInput extends PartialUpdate<TrainingUpdateInput> {}
export type Training = Immutable<
  Metadata<
    {
      files: Array<S3Object>;
      __typename: 'Training';
    } & TrainingUpdateInput
  >
>;

export type TrainingVersionCreateInput = APIa.TrainingVersionInput;
export interface TrainingVersionUpdateInput extends TrainingVersionCreateInput {
  id: string;
  version: number;
}
export interface TrainingVersionPartialUpdateInput
  extends PartialUpdate<TrainingVersionUpdateInput> {}
export type TrainingVersion = Immutable<
  Metadata<
    {
      __typename: 'TrainingVersion';
    } & TrainingVersionUpdateInput
  >
>;

export type TrainingSessionCreateInput = APIa.TrainingSessionInput;
export interface TrainingSessionUpdateInput extends TrainingSessionCreateInput {
  id: string;
}
export interface TrainingSessionPartialUpdateInput
  extends PartialUpdate<TrainingSessionUpdateInput> {}
export type TrainingSession = Immutable<
  Metadata<
    {
      __typename: 'TrainingSession';
      calendarEvent?: CalendarEvent | null;
      trainerFiles?: Array<S3Object> | null;
    } & TrainingSessionUpdateInput
  >
>;

export type CalendarEventInput = APIa.CalendarEventInput;
export type CalendarEvent = APIa.CalendarEvent;

export type ReviewInput = APIa.ReviewInput;
export type Review = APIa.Review;

export interface ProofBundleCreateInput extends APIa.ProofBundleInput {}
export interface ProofBundleUpdateInput extends ProofBundleCreateInput {
  id: string;
}
export interface ProofBundlePartialUpdateInput extends PartialUpdate<ProofBundleUpdateInput> {}
export type ProofBundle = Immutable<
  Metadata<
    {
      __typename: 'ProofBundle';
      files: Array<S3Object>;
      review: Review;
    } & ProofBundleUpdateInput
  >
>;

export type S3ObjectInput = APIa.S3ObjectInput;
export type S3Object = APIa.S3Object;

export type TrainerInput = APIa.TrainerInput;
export type Trainer = APIa.Trainer;

export type SkillTrainingVersionInput = APIa.SkillTrainingVersionInput;
export type SkillTrainingVersion = APIa.SkillTrainingVersion;

export type RequirementCreateInput = APIa.RequirementInput;
export interface RequirementUpdateInput extends RequirementCreateInput {
  id: string;
}
export interface RequirementPartialUpdateInput extends PartialUpdate<RequirementUpdateInput> {}
export type Requirement = Immutable<
  Metadata<
    {
      __typename: 'Requirement';
      skillTrainingVersions: Array<SkillTrainingVersion>;
    } & RequirementUpdateInput
  >
>;

export type WorkerSkillCreateInput = APIa.WorkerSkillInput;
export interface WorkerSkillUpdateInput extends WorkerSkillCreateInput {
  id: string;
}
export interface WorkerSkillPartialUpdateInput extends PartialUpdate<WorkerSkillUpdateInput> {}
export type WorkerSkill = Immutable<
  Metadata<
    {
      __typename: 'WorkerSkill';
      activeProofBundle?: NoMetadata<ProofBundle> | null;
      toReviewProofBundle?: NoMetadata<ProofBundle> | null;
    } & WorkerSkillUpdateInput
  >
>;

export type WorkerTrainingSessionCreateInput = APIa.WorkerTrainingSessionInput;
export interface WorkerTrainingSessionUpdateInput extends WorkerTrainingSessionCreateInput {
  id: string;
}
export interface WorkerTrainingSessionPartialUpdateInput
  extends PartialUpdate<WorkerTrainingSessionUpdateInput> {}
export type WorkerTrainingSession = Immutable<
  Metadata<
    {
      __typename: 'WorkerTrainingSession';
    } & WorkerTrainingSessionUpdateInput
  >
>;

export interface SkillValidityDurationAndExpiryNoticeDuration
  extends Pick<Skill, 'validityDuration' | 'expiryNoticeDuration'> {}

export type UserPreferenceCreateInput = APIa.UserPreferenceInput;
export interface UserPreferenceUpdateInput extends UserPreferenceCreateInput {
  id: string;
}
export interface UserPreferencePartialUpdateInput
  extends PartialUpdate<UserPreferenceUpdateInput> {}
export type UserPreference = Immutable<
  Metadata<
    {
      __typename: 'UserPreference';
    } & UserPreferenceUpdateInput
  >
>;




export type Subscriptions<T extends DataType | DataTypeUnknown> =
  | {
      type: 'onCreateFactory';
      variables: APIa.OnCreateFactorySubscriptionVariables;
      result: Modify<
        APIa.OnCreateFactorySubscription,
        { onCreateFactory: WritableFactory<T> | null }
      >;
    }
  | {
      type: 'onUpdateFactory';
      variables: APIa.OnUpdateFactorySubscriptionVariables;
      result: Modify<
        APIa.OnUpdateFactorySubscription,
        { onUpdateFactory: WritableFactory<T> | null }
      >;
    }
  | {
      type: 'onDeleteFactory';
      variables: APIa.OnDeleteFactorySubscriptionVariables;
      result: Modify<
        APIa.OnDeleteFactorySubscription,
        { onDeleteFactory: WritableFactory<T> | null }
      >;
    };
export function isSubscriptionCreate(
  subscriptionType: string,
): subscriptionType is 'onCreateFactory' {
  return subscriptionType === 'onCreateFactory';
}
export function isSubscriptionUpdate(
  subscriptionType: string,
): subscriptionType is 'onUpdateFactory' {
  return subscriptionType === 'onUpdateFactory';
}
export function isSubscriptionDelete(
  subscriptionType: string,
): subscriptionType is 'onDeleteFactory' {
  return subscriptionType === 'onDeleteFactory';
}

export type Mutations<T extends DataType> =
  | {
      type: 'createFactory';
      variables: Modify<
        APIa.CreateFactoryMutationVariables,
        {
          input: CreateFactoryInput<T>;
        }
      >;
      result: Modify<APIa.CreateFactoryMutation, { createFactory: WritableFactory<T> | null }>;
    }
  | {
      type: 'updateFactory';
      variables: Modify<
        APIa.UpdateFactoryMutationVariables,
        {
          input: UpdateFactoryInput<T>;
        }
      >;
      result: Modify<APIa.UpdateFactoryMutation, { updateFactory: WritableFactory<T> | null }>;
    }
  | {
      type: 'deleteFactory';
      variables: Modify<
        APIa.DeleteFactoryMutationVariables,
        {
          input: APIa.DeleteFactoryInput;
        }
      >;
      result: Modify<APIa.DeleteFactoryMutation, { deleteFactory: WritableFactory<T> | null }>;
    };

export function isMutationCreate(mutationType: string): mutationType is 'createFactory' {
  return mutationType === 'createFactory';
}
export function isMutationUpdate(mutationType: string): mutationType is 'updateFactory' {
  return mutationType === 'updateFactory';
}
export function isMutationDelete(mutationType: string): mutationType is 'deleteFactory' {
  return mutationType === 'deleteFactory';
}

export type InternalMutations<T extends DataType> =
  | {
      type: 'createFactoryInternally';
      variables: Modify<
        APIa.CreateFactoryInternallyMutationVariables,
        {
          input: CreateFactoryInput<T>;
        }
      >;
      result: Modify<
        APIa.CreateFactoryInternallyMutation,
        { createFactoryInternally: WritableFactory<T> | null }
      >;
    }
  | {
      type: 'updateFactoryInternally';
      variables: Modify<
        APIa.UpdateFactoryInternallyMutationVariables,
        {
          input: UpdateFactoryInput<T>;
        }
      >;
      result: Modify<
        APIa.UpdateFactoryInternallyMutation,
        { updateFactoryInternally: WritableFactory<T> | null }
      >;
    }
  | {
      type: 'deleteFactoryInternally';
      variables: APIa.DeleteFactoryInternallyMutationVariables;
      result: Modify<
        APIa.DeleteFactoryInternallyMutation,
        { deleteFactoryInternally: WritableFactory<T> | null }
      >;
    };

export function isMutationCreateInternally(
  mutationType: string,
): mutationType is 'createFactoryInternally' {
  return mutationType === 'CreateFactoryInternallyMutationVariables';
}
export function isMutationUpdateInternally(
  mutationType: string,
): mutationType is 'updateFactoryInternally' {
  return mutationType === 'updateFactoryInternally';
}
export function isMutationDeleteInternally(
  mutationType: string,
): mutationType is 'deleteFactoryInternally' {
  return mutationType === 'deleteFactoryInternally';
}

export type ModelFactoryConnection<T extends DataType | DataTypeUnknown> = Modify<
  NonNullable<APIa.ListFactorysQuery['listFactorys']>, 
  { items: WritableFactory<T>[] }
>;

export type ItemsQueriesType = ItemsQueries<DataType>['type'];
export type ItemsQueries<T extends DataType | DataTypeUnknown> =
  | {
      type: 'listFactorys';
      variables: APIa.ListFactorysQueryVariables;
      result: Modify<APIa.ListFactorysQuery, { listFactorys: ModelFactoryConnection<T> | null }>;
    }
  | {
      type: 'itemsByType';
      variables: APIa.ItemsByTypeQueryVariables;
      result: Modify<APIa.ItemsByTypeQuery, { itemsByType: ModelFactoryConnection<T> | null }>;
    }
  | {
      type: 'itemsByData';
      variables: APIa.ItemsByDataQueryVariables;
      result: Modify<APIa.ItemsByDataQuery, { itemsByData: ModelFactoryConnection<T> | null }>;
    }
  | {
      /** WARNING this query type is performing a SCAN on the database (very low performance) and shall not be used in PRODUCTION (only in migration scripts) */
      type: 'allFactoriesByType';
      variables: APIa.AllFactoriesByTypeQueryVariables;
      result: Modify<
        APIa.AllFactoriesByTypeQuery,
        { allFactoriesByType: ModelFactoryConnection<T> | null }
      >;
    }
  | {
      type: 'syncFactories';
      variables: APIa.SyncFactoriesQueryVariables;
      result: Modify<
        APIa.SyncFactoriesQuery,
        { syncFactories: ModelFactoryConnection<DataTypeUnknown> | null }
      >;
    };
export function isListFactorys(queryType: string): queryType is 'listFactorys' {
  return queryType === 'listFactorys';
}
export function isItemsByType(queryType: string): queryType is 'itemsByType' {
  return queryType === 'itemsByType';
}
export function isItemsByData(queryType: string): queryType is 'itemsByData' {
  return queryType === 'itemsByData';
}
export function isAllFactoriesByType(queryType: string): queryType is 'allFactoriesByType' {
  return queryType === 'allFactoriesByType';
}
export function isSyncFactories(queryType: string): queryType is 'syncFactories' {
  return queryType === 'syncFactories';
}

export type MiscQueries<T extends DataType | DataTypeUnknown = DataType> =
  | {
      type: 'getFactory';
      variables: APIa.GetFactoryQueryVariables;
      result: Modify<APIa.GetFactoryQuery, { getFactory: WritableFactory<T> | null }>;
    }
  | {
      type: 'inviteUser';
      variables: APIa.InviteUserMutationVariables;
      result: APIa.InviteUserMutation;
    }
  | {
      type: 'getFilePreSignedUrl';
      variables: APIa.GetFilePreSignedUrlQueryVariables;
      result: APIa.GetFilePreSignedUrlQuery;
    };
export function isGetFactory(type: string): type is 'getFactory' {
  return type === 'getFactory';
}
export function isInviteUser(type: string): type is 'inviteUser' {
  return type === 'inviteUser';
}
export function isGetFilePreSignedUrl(type: string): type is 'getFilePreSignedUrl' {
  return type === 'getFilePreSignedUrl';
}

export type QueryVariables<
  Q extends ItemsQueriesType | MiscQueries<T>['type'],
  T extends DataType | DataTypeUnknown,
> = CastByType<ItemsQueries<T> | MiscQueries<T>, Q>['variables'];

export function extractDataFromFetchResult<T extends Subscriptions<D>['type'], D extends DataType>(
  type: T,
  result: GraphQLResult<CastByType<Subscriptions<D>, typeof type>['result']>,
): Result<WritableFactory<D> | null | undefined>;
export function extractDataFromFetchResult<T extends Mutations<D>['type'], D extends DataType>(
  type: T,
  result: GraphQLResult<CastByType<Mutations<D>, typeof type>['result']>,
  dataType: D,
): Result<WritableFactory<D> | null | undefined>;
export function extractDataFromFetchResult<
  T extends InternalMutations<D>['type'],
  D extends DataType,
>(
  type: T,
  result: GraphQLResult<CastByType<InternalMutations<D>, typeof type>['result']>,
  dataType: D,
): Result<WritableFactory<D> | null | undefined>;
export function extractDataFromFetchResult<
  T extends ItemsQueries<D>['type'],
  D extends DataType | DataTypeUnknown,
>(
  type: T,
  result: GraphQLResult<CastByType<ItemsQueries<D>, typeof type>['result']>,
  dataType: D,
): Result<ModelFactoryConnection<D> | null | undefined>;
export function extractDataFromFetchResult<D extends DataType>(
  type: 'getFactory',
  result: GraphQLResult<CastByType<MiscQueries<D>, typeof type>['result']>,
  dataType: D,
): Result<WritableFactory<D> | null | undefined>;
export function extractDataFromFetchResult<
  T extends Exclude<MiscQueries<D>['type'], 'getFactory'>,
  D extends DataType,
>(
  type: T,
  result: GraphQLResult<CastByType<MiscQueries<D>, typeof type>['result']>,
): Result<boolean | string | null | undefined>;
/**
 * Extract the payload from the given query/mutation result.
 * @param type
 * @param result to inspect
 * @returns undefined in case the result data payload is null or undefined
 */
export function extractDataFromFetchResult<
  T extends
    | Subscriptions<D>['type']
    | Mutations<D>['type']
    | InternalMutations<D>['type']
    | ItemsQueries<D>['type']
    | MiscQueries<D>['type'],
  R extends
    | Subscriptions<D>['result']
    | Mutations<D>['result']
    | InternalMutations<D>['result']
    | ItemsQueries<D>['result']
    | MiscQueries<D>['result'],
  D extends DataType,
>(
  /**
   * Test types by removing all the 'as any' inside the function, replace T by 'createFactory' or 'updateFactory' or ...
   * If no typescript error is raised, then there you can put back the 'as any' as they are required until typescript improves it support for conditional types.
   */
  type: T,
  result: GraphQLResult<R>,
  dataType?: D | undefined,
): Result<WritableFactory<D> | ModelFactoryConnection<D> | boolean | string | null | undefined> {
  if (!result.data) return result.data;
  return extractDataFromResult(type as any, result.data as any, dataType); 
}

export function extractDataFromResult<T extends Subscriptions<D>['type'], D extends DataType>(
  type: T,
  result: CastByType<Subscriptions<D>, typeof type>['result'],
): Result<WritableFactory<D> | null>;
export function extractDataFromResult<T extends Mutations<D>['type'], D extends DataType>(
  type: T,
  result: CastByType<Mutations<D>, typeof type>['result'],
  dataType: D,
): Result<WritableFactory<D> | null>;
export function extractDataFromResult<T extends InternalMutations<D>['type'], D extends DataType>(
  type: T,
  result: CastByType<InternalMutations<D>, typeof type>['result'],
  dataType: D,
): Result<WritableFactory<D> | null>;
export function extractDataFromResult<
  T extends ItemsQueries<D>['type'],
  D extends DataType | DataTypeUnknown,
>(
  type: T,
  result: CastByType<ItemsQueries<D>, typeof type>['result'],
  dataType: D,
): Result<ModelFactoryConnection<D> | null>;
export function extractDataFromResult<T extends MiscQueries<D>['type'], D extends DataType>(
  type: T,
  result: Result<CastByType<MiscQueries<D>, typeof type>['result']>,
  dataType?: D | undefined,
): Result<WritableFactory<D> | boolean | string | null>;
export function extractDataFromResult<
  M extends
    | Subscriptions<D>['type']
    | Mutations<D>['type']
    | InternalMutations<D>['type']
    | ItemsQueries<D>['type']
    | MiscQueries<D>['type'],
  R extends
    | Subscriptions<D>['result']
    | Mutations<D>['result']
    | InternalMutations<D>['result']
    | ItemsQueries<D>['result']
    | MiscQueries<D>['result'],
  D extends DataType,
>(
  type: M,
  data: R,
  dataType?: D | undefined,
): Result<WritableFactory<D> | ModelFactoryConnection<D> | boolean | string | null> {
  if (isSubscriptionCreate(type)) {
    return (data as CastByType<Subscriptions<D>, typeof type>['result']).onCreateFactory;
  }
  if (isSubscriptionUpdate(type)) {
    return (data as CastByType<Subscriptions<D>, typeof type>['result']).onUpdateFactory;
  }
  if (isSubscriptionDelete(type)) {
    return (data as CastByType<Subscriptions<D>, typeof type>['result']).onDeleteFactory;
  }

  if (isMutationCreate(type)) {
    return (data as CastByType<Mutations<D>, typeof type>['result']).createFactory;
  }
  if (isMutationUpdate(type)) {
    return (data as CastByType<Mutations<D>, typeof type>['result']).updateFactory;
  }
  if (isMutationDelete(type)) {
    return (data as CastByType<Mutations<D>, typeof type>['result']).deleteFactory;
  }

  if (isMutationCreateInternally(type)) {
    return (data as CastByType<InternalMutations<D>, typeof type>['result'])
      .createFactoryInternally;
  }
  if (isMutationUpdateInternally(type)) {
    return (data as CastByType<InternalMutations<D>, typeof type>['result'])
      .updateFactoryInternally;
  }
  if (isMutationDeleteInternally(type)) {
    return (data as CastByType<InternalMutations<D>, typeof type>['result'])
      .deleteFactoryInternally;
  }

  if (isListFactorys(type)) {
    return (data as CastByType<ItemsQueries<D>, typeof type>['result']).listFactorys;
  }
  if (isItemsByType(type)) {
    return (data as CastByType<ItemsQueries<D>, typeof type>['result']).itemsByType;
  }
  if (isItemsByData(type)) {
    return (data as CastByType<ItemsQueries<D>, typeof type>['result']).itemsByData;
  }
  if (isAllFactoriesByType(type)) {
    return (data as CastByType<ItemsQueries<D>, typeof type>['result']).allFactoriesByType;
  }
  
  if (isSyncFactories(type)) {
    return (data as CastByType<ItemsQueries<D>, typeof type>['result'])
      .syncFactories as ModelFactoryConnection<D>;
  }

  if (isGetFactory(type)) {
    return (data as CastByType<MiscQueries<D>, typeof type>['result']).getFactory;
  }
  if (isInviteUser(type)) {
    const r = (data as CastByType<MiscQueries<D>, typeof type>['result']).inviteUser;
    return r === undefined ? null : r;
  }
  if (isGetFilePreSignedUrl(type)) {
    const r = (data as CastByType<MiscQueries<D>, typeof type>['result']).getFilePreSignedUrl;
    return r === undefined ? null : r;
  }

  return createFailure_Unspecified(
    'Factory query or mutation type ' + type + ' not supported in api.ts file. Please add it!',
  );
}

/**
 * To keep in sync with ModelIDInput & ModelStringInput
 * Supported comparator for Filter.
 * In term of performance: EqualNotEqual > BeginsWith > Contains
 */
export enum FilterComparator {
  ne = 'ne',
  eq = 'eq',
  le = 'le',
  lt = 'lt',
  ge = 'ge',
  gt = 'gt',
  contains = 'contains',
  notContains = 'notContains',
  between = 'between',
  beginsWith = 'beginsWith',
  attributeExists = 'attributeExists',
  attributeType = 'attributeType',
  size = 'size',
}
/**
 * To keep in sync with KeyConditionInput & ModelStringKeyConditionInput
 * Supported comparator for Sorting Key.
 * In term of performance: EqualNotEqual > BeginsWith
 */
export enum KeyComparator {
  eq = 'eq',
  le = 'le',
  lt = 'lt',
  ge = 'ge',
  gt = 'gt',
  between = 'between',
  beginsWith = 'beginsWith',
}

export interface AppSyncGraphQLError extends GraphQLError {
  readonly errorType?: string;
  readonly data?: unknown;
  readonly errorInfo?: unknown;
}

/**
 * cache-first : cache hit, if no result network hit with saving into cache
 * cache-only : cache hit
 * network-and-cache : network hit with saving into cache
 * network-no-cache : network hit without saving into cache
 */
export type FetchPolicy = 'cache-first' | 'cache-only' | 'network-and-cache' | 'network-no-cache';





export type ValueOf<T> = T[keyof T];
export interface Dictionary<T> {
  [index: string]: T;
}

export type Tuple<T, N extends number, R extends readonly T[] = []> = R['length'] extends N
  ? R
  : Tuple<T, N, readonly [T, ...R]>;

type PartialUpdate<T> = Partial<T> & { id: string };
/**
 * Extract the property of an object that have object type.
 * For instance ExtractObjectProps<{a: string, b: object}> will return { b: object}
 */
export type ExtractObjectProps<L> = {
  [Property in keyof L as L[Property] extends object ? Property : never]: L[Property];
};
export type OptionalKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? K : never }[keyof T];
export type RequiredKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? never : K }[keyof T];
export type NonNullableProperties<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};
export type Modify<T, Properties> = Omit<T, keyof Properties> & Properties;

/**
 * Return the conditional type C matching the 'type' property T
 */
export type CastByType<C extends { type: string }, T extends C['type']> = Cast<C, 'type', T>;

/**
 * Return the conditional type C matching the property(P) = T
 */
export type Cast<
  C extends Record<P, string | boolean>,
  P extends keyof C,
  T extends C[P],
> = C extends Record<P, T> ? ({} extends Omit<C, P> ? never : C) : never;


export type NotUndefined<T> = T extends undefined ? never : T;

/**
 * cover most cases, but it won't accept arrays like [...anotherArray, 'element']
 * and no checl on length
 */
export type NonEmptyArray<T> = T[] & { 0: T };
export type NonEmptyString<T extends string> = '' extends T ? never : T;

export type ExcludeStringProperty<T> = { [K in keyof T as T[K] extends string ? never : K]: T[K] };

export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
export type PickAndFlatten<T, K extends keyof T> = UnionToIntersection<T[K]>;
export type Flatten<T> = PickAndFlatten<T, keyof T>;

/** unpack the type inside an array */
export type UnpackArray<T> = T extends (infer U)[] ? U : T;

/*
type Head<T> = T extends [infer I, ...infer _Rest] ? I : never;
type Tail<T> = T extends [infer _I, ...infer Rest] ? Rest : never;

type Zip_DeepMergeTwoTypes<T, U> = T extends []
  ? U
  : U extends []
  ? T
  : [DeepMergeTwoTypes<Head<T>, Head<U>>, ...Zip_DeepMergeTwoTypes<Tail<T>, Tail<U>>];

/**
 * Take two objects T and U and create the new one with uniq keys for T a U objectI
 * helper generic for `DeepMergeTwoTypes`
 */
type GetObjDifferentKeys<
  T,
  U,
  T0 = Omit<T, keyof U> & Omit<U, keyof T>,
  T1 = { [K in keyof T0]: T0[K] },
> = T1;
/**
 * Take two objects T and U and create the new one with the same objects keys
 * helper generic for `DeepMergeTwoTypes`
 */
type GetObjSameKeys<T, U> = Omit<T | U, keyof GetObjDifferentKeys<T, U>>;

type MergeTwoObjects<
  T,
  U,
  
  T0 = Partial<GetObjDifferentKeys<T, U>> & {
    
    [K in keyof GetObjSameKeys<T, U>]: DeepMergeTwoTypes<T[K], U[K]>;
  },
  T1 = { [K in keyof T0]: T0[K] },
> = T1;


export type DeepMergeTwoTypes<T, U> = [T, U] extends [any[], any[]]
  ? Zip_DeepMergeTwoTypes<T, U>
  : 
  [T, U] extends [Record<string, unknown>, Record<string, unknown>]
  ? MergeTwoObjects<T, U>
  : T | U;

/**
 * Recursive String Splitting. For instance:
 * type S3 = Split<"1.2", ".">
 * type S4 = Split<"1.2.3", ".">
 */
export type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends ''
  ? []
  : S extends `${infer T}${D}${infer U}`
  ? [T, ...Split<U, D>]
  : [S];

/**
 * Get the length of an array
 * Usefull to get the last item of a tuple
 */
export type GetLength<T extends unknown[]> = T['length'];

/**
 * Omits properties that have type `never`. Utilizes key-remapping introduced in
 * TS4.1.
 *
 * @example
 * ```ts
 * type A = { x: never; y: string; }
 * OmitNever<A> 
 * ```
 */
type OmitNever<T extends Record<string, unknown>> = {
  [K in keyof T as T[K] extends never ? never : K]: T[K];
};

/**
 * Constructs a Record type that only includes shared properties between `A` and
 * `B`. If the value of a key is different in `A` and `B`, `SharedProperties<A,
 * B>` attempts to choose a type that is assignable to the types of both values.
 *
 * Note that this is NOT equivalent to `A & B`.
 *
 * @example
 * ```ts
 * type A = { x: string; y: string; }
 * type B = { y: string; z: string }
 * type C = { y: string | number; }
 *
 * A & B                  
 * SharedProperties<A, B> 
 * SharedProperties<B, C> 
 * ```
 */
export type SharedProperties<A, B> = OmitNever<Pick<A & B, keyof A & keyof B>>;




/**
 * Check if the type of the given pk
 * @pk
 */
export function getPkType(pk: string): PkType {
  if (pk === globalPk) return PkType.Global;

  if (!pk.includes(SeparatorDataType)) return PkType.User;

  const length = pk.split(SeparatorIds).length;
  if (length === 1) return PkType.Tenant;
  else if (length === 2) return PkType.TenantApp;
  else {
    throw new Error('Unknown pk type: ' + pk);
  }
}

export function getPkDataTypes(pk: string): DataType[] {
  switch (getPkType(pk)) {
    case PkType.Global:
      return [DataType.APP];

    case PkType.User:
      return [DataType.WORKER_TENANT_APP];

    case PkType.Tenant:
      return [DataType.TENANT, DataType.TENANT_APP, DataType.WORKER_TENANT_APP];

    case PkType.TenantApp:
      const tenantAppPk = pk.split(SeparatorIds);
      if (tenantAppPk[1] === DataType.APP + SeparatorDataType + APIa.AppId.SKILLOP) {
        return Object.values(DataType).filter(dataType => {
          if (dataType === DataType.APP) return false;
          if (dataType === DataType.WORKER_TENANT_APP) return false;
          if (dataType === DataType.TENANT) return false;
          if (dataType === DataType.TENANT_APP) return false;
          if (dataType === DataType.TENANTID) return false;
          return true;
        });
      } else {
        throw Error('getPkDataTypes : unknown appId: ' + tenantAppPk[1] + pk);
      }
  }
}

/**
 * Extract the DataType of a FacotryBusinessObject's id (e.g. WORKSTATION#uuid)
 * It also accepts relationFactory's sk composed of FacotryBusinessObjects'id (e.g. ORGUNIT#uuid,WORKSTATION#uuid)
 * @param objectIdOrFactorySk
 */
export function getDataType<T extends DataType>(objectIdOrFactorySk: string): Result<T> {
  const objectDataType = objectIdOrFactorySk.split(SeparatorIds)[0].split(SeparatorDataType)[0];

  if (hasKey(DataType, objectDataType)) return DataType[objectDataType] as T; 

  return createFailure_Unspecified(
    `Cannot find dataType: ${objectDataType} parsing: ${objectIdOrFactorySk}`,
  );
}

/**
 * Tells whether objectToTest is of the given DataType or DataTypeUnknown.
 * WARNING: 1st arg shall be of type API.DataType.XXX or DataTypeUnknown.
 * @param dataType data type to test against
 * @param objectToTest
 * @returns
 */
export function isDataType<T extends DataType | DataTypeUnknown>(
  dataType: T,
  objectToTest: DataType | DataTypeUnknown,
): objectToTest is T {
  return objectToTest === dataType;
}

/**
 * @deprecated use #isTreeNode() or #isFactory()
 * . Tells whether the passed BusinessObject is of the given dataType and cast it (for typescript)
 * @param dataType
 * @param businessObject
 * @returns
 */
export function isBusinessObject<T extends DataTypeBusinessObject>(
  dataType: T,
  /**
   * WARNING BusinessObject<DataTypeBusinessObject> doens't works well with typing (see Flaten<> type that breaks the | distribution)
   * Pay extra attention that the object you pass here extends BusinessObject
   */
  businessObject: BusinessObject<DataTypeBusinessObject>,
): businessObject is BusinessObject<T> {
  const businessObjectDataType = getDataType((businessObject as any).id ?? ''); 
  if (isFailure(businessObjectDataType)) return false;

  return businessObjectDataType === dataType;
}

/**
 * Tells whether the passed Factory is of the given dataType and cast it (for typescript)
 * @param dataType
 * @param factory
 * @returns
 */
export function isFactory<T extends DataType>(
  dataType: T,
  factory: Factory<DataType>,
): factory is Factory<T> {
  return factory.dataType === dataType;
}

const getOrCreateFactoryAsyncLockMap = new Map<string, { counter: number; asyncLock: AsyncLock }>();
/**
 * Try to get a Factory and if not found create it.
 * This function protects against concurrent calls inside the same client
 * to prevent creating the same Factory several times.
 * Although there is no guaranty the Factory will not be created multiple times in case
 * several clients call this function with the exact same parameters at the same time.
 * @param dataType name of OrgUnit to find or create
 * @param _getOrCreateFactory function that look for a Factory and if not found create the Factory. The return type can be tweaked by specifying V type
 * @param subkey (optional) for optimization purpose: the subKey under which the function shall be protected against concurrent calls (usually the name of the BusinessObject we are trying to get)
 * @return the found or newly created Factory
 */
export async function getOrCreateFactory<T extends DataType, V extends {} = Result<Factory<T>>>(
  dataType: T,
  _getOrCreateFactory: () => Promise<V>,
  subkey?: string,
): Promise<V> {
  const key = dataType + (subkey ?? '');
  
  let v = getOrCreateFactoryAsyncLockMap.get(key);
  if (!v) {
    v = { counter: 0, asyncLock: new AsyncLock() };
  }
  getOrCreateFactoryAsyncLockMap.set(key, { counter: v.counter + 1, asyncLock: v.asyncLock });

  
  const result = await v.asyncLock.runSerial(_getOrCreateFactory);

  
  
  v = getOrCreateFactoryAsyncLockMap.get(key)!; 
  if (v.counter === 1) getOrCreateFactoryAsyncLockMap.delete(key);
  else getOrCreateFactoryAsyncLockMap.set(key, { counter: v.counter - 1, asyncLock: v.asyncLock });

  return result;
}


export function extractIdForNonCombinedIds(id: string): string {
  return id.split(SeparatorDataType)[1];
}

export function isID(str: string) {
  const id = str.split(SeparatorDataType);
  if (id.length === 1) return validateUUID(id[0]);
  if (id.length === 2) return validateUUID(id[1]);

  logger.error('Not sure how to parse id (' + str + '). Please fix the code.');
  return false;
}

export function combineDataTypeAndId(dataType: DataType, id: string): string {
  return `${dataType}${SeparatorDataType}${id}`;
}

export function deepClone<T extends DataType>(object: Factory<T>): WritableFactory<T>;
export function deepClone<T extends Exclude<object | null | undefined, Factory<DataType>>>(
  object: T,
): Writable<T>;
export function deepClone<T extends DataType | object>(
  object: T extends DataType ? Factory<T> : T,
): T extends DataType ? WritableFactory<T> : Writable<T> {
  try {
    return structuredClone(object) as T extends DataType ? WritableFactory<T> : Writable<T>; 
  } catch (e) {
    logger.info('structuredClone() failed to clone object, using _.cloneDeep() instead.');

    return cloneDeep(object) as T extends DataType ? WritableFactory<T> : Writable<T>; 
  }
}
