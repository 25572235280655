import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { Colors, getCorrectedSpacings } from '../styles';
import * as SharedStyles from 'shared/styles/index';
import { capitalizeFirstLetter } from '../localisation/i18n';
import { S3Image } from 'aws-amplify-react-native';

export enum ImageSizes {
  Small = getCorrectedSpacings(20),
  Medium = getCorrectedSpacings(30),
  Large = getCorrectedSpacings(48),
  Big = getCorrectedSpacings(76),
}

export const renderImage = (image: string | null | undefined, size: ImageSizes, name?: string) => {
  if (image) {
    return (
      <S3Image
        imgKey={image}
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
        }}
      />
    );
  } else if (name) {
    return (
      <View
        style={[
          {
            backgroundColor: Colors.Grey,
            borderRadius: size,
            shadowColor: Colors.Black,
            shadowRadius: 2,
            height: size,
            width: size,
          },
          styles.alignAndJustifyCenter,
        ]}
      >
        <Text
          style={[
            styles.userInitialsTextStyle,
            size === ImageSizes.Big || size === ImageSizes.Large
              ? styles.bigAndLargeImageTextStyle
              : size === ImageSizes.Medium
              ? styles.mediumMediumTextStyle
              : size === ImageSizes.Small
              ? styles.smallImageTextStyle
              : {},
          ]}
        >
          {capitalizeFirstLetter(name.charAt(0))}
        </Text>
      </View>
    );
  } else return <></>;
};

export const styles = StyleSheet.create({
  alignAndJustifyCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  userInitialsTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Medium,
    color: SharedStyles.Colors.White,
  },
  bigAndLargeImageTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.XXXLarge,
  },
  mediumMediumTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Medium,
  },
  smallImageTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Small,
  },
});
