import { v4 as uuid } from 'uuid';
import { AppContext } from 'shared/context/AppContext';
import { Storage } from 'aws-amplify';
import * as API from 'shared/backend-data';
import config from 'backend/src/aws-exports';
import logger from 'shared/util/Logger';
import { S3ObjectInput, StorageVisibility } from 'shared/backend-data';
import * as _ from 'lodash-es';
import { isImage, isPlatformServerless } from '../util-ts/Functions';
import { UserContext } from '../context/UserContext';
import { Immutable } from 'shared/util-ts/Functions';
const { aws_user_files_s3_bucket_region: region, aws_user_files_s3_bucket: bucket } = config;

export interface S3ObjectWithLink extends S3ObjectInput {
  downloadLink: string;
  allowDownload: boolean;
}

export function isS3Object(obj: any): obj is S3ObjectInput {
  return (
    (obj as S3ObjectInput).bucket !== undefined &&
    (obj as S3ObjectInput).region !== undefined &&
    (obj as S3ObjectInput).key !== undefined
  );
}

export function isSameS3Object(
  s3Object1: API.S3ObjectInput,
  s3Object2: API.S3ObjectInput,
): boolean {
  if (s3Object1.bucket !== s3Object2.bucket) return false;
  if (s3Object1.region !== s3Object2.region) return false;
  if (s3Object1.key !== s3Object2.key) return false;
  if (s3Object1.fileName !== s3Object2.fileName) return false;
  
  

  return true;
}

export function areSameS3Objects(
  s3Objects1: readonly API.S3ObjectInput[],
  s3Objects2: readonly API.S3ObjectInput[],
): boolean {
  if (s3Objects1.length !== s3Objects2.length) return false;

  return s3Objects1.every(s3File1 =>
    s3Objects2.find(s3File2 => isSameS3Object(s3File1, s3File2)) ? true : false,
  );
}

export async function isSameFile(file1: File, s3Object2: API.S3Object): Promise<boolean> {
  if (file1.name !== s3Object2.fileName) return false;

  
  return true;
}

export async function getFile(file: API.S3Object): Promise<API.Result<Object>> {
  try {
    if (file.visibility === StorageVisibility.protected)
      return Storage.get(file.key, {
        level: file.visibility,
        identityId: file.owner ?? undefined,
        download: true,
      });
    return Storage.get(file.key, {
      level: file.visibility,
      download: true,
    });
  } catch (err) {
    const error = ['S3Upload getFile error', err];
    logger.error(error);
    return API.createFailure_Unspecified(error);
  }
}

export async function getFileLink(file: API.S3ObjectInput): Promise<API.Result<string>> {
  try {
    if (file.visibility === StorageVisibility.protected) {
      return (
        await Storage.get(file.key, {
          level: file.visibility,
          identityId: file.owner ?? undefined,
          download: false,
        })
      ).toString();
    }
    return (
      await Storage.get(file.key, {
        level: file.visibility,
        download: false,
      })
    ).toString();
  } catch (err) {
    const error = ['S3Upload getFileLink error', err];
    logger.debug(error);
    return API.createFailure_Unspecified(error);
  }
}

/**
 * Upload a File
 * @param file
 * @param visibility of the uploaded file
 * @param filename (optional)
 * @param oldKey (optional) usefull if you want to update an existing object
 */
export async function uploadFile(
  file: File | Blob | API.ProofFile,
  visibility: StorageVisibility,
  fileName?: string,
  oldKey?: string,
  contentType?: string,
): Promise<API.Result<API.S3Object>> {
  const appContext = AppContext.getContext();
  if (API.isFailure(appContext)) return appContext;

  const user = UserContext.getUser();
  if (API.isFailure(user)) return user;

  const name: string = fileName ?? (file as File).name ?? '';

  const extensionArray: RegExpExecArray | null = /([^.]+)(\.(\w+))?$/.exec(name);

  let key: string;
  if (oldKey && oldKey.length) {
    key = oldKey;
  } else {
    let extension = extensionArray ? `.${extensionArray[3]}` : null;
    key = `${appContext.pk}/${user.id}/${uuid()}${extension}`;
  }

  logger.debug(
    'S3Upload uploadFile key:',
    key,
    'oldKey:',
    oldKey,
    'pk:',
    appContext.pk,
    'userId:',
    user.id,
    'visibility:',
    visibility,
  );

  const _file = isPlatformServerless ? (file as API.ProofFile).fileContentBase64 : file; 

  try {
    return Storage.put(key, _file, {
      level: visibility,
      contentType: contentType ?? file.type,
    }).then((result: any) => {
      logger.debug('S3Upload uploadFile sucessful', result);
      return {
        __typename: 'S3Object',
        bucket: bucket,
        region: region,
        key: result.key,
        fileName: name,
        visibility,
        owner: user.identityId,
      };
    });
  } catch (err) {
    logger.error('S3Upload uploadFile error', err);
    return API.createFailure('UploadFailure', JSON.stringify(err), err);
  }
}

export async function deleteS3Object(s3Object: API.S3Object): Promise<API.Result<void>> {
  const appContext = AppContext.getContext();
  if (API.isFailure(appContext)) return appContext;

  const user = UserContext.getUser();
  if (API.isFailure(user)) return user;

  logger.debug('S3Upload deleteFile key:', s3Object.key, s3Object);

  try {
    return Storage.remove(s3Object.key, {
      level: s3Object.visibility,
    }).then((result: any) => {
      logger.debug('S3Upload deleteFile sucessful', result);
    });
  } catch (err) {
    logger.error('S3Upload deleteFile error', err);
    return API.createFailure('UploadFailure', JSON.stringify(err), err);
  }
}

export async function loadS3Files(files: API.S3ObjectInput[]): Promise<S3ObjectWithLink[]> {
  const filesWithLinks: S3ObjectWithLink[] = _.compact(
    await Promise.all(
      files.map(async file => {
        const downloadLink = await getFileLink(file);
        if (API.isFailure(downloadLink)) {
          logger.error(downloadLink);
          return;
        }
        return {
          ...file,
          downloadLink,
          allowDownload: true,
        };
      }),
    ),
  );
  return filesWithLinks;
}

export async function loadFiles(
  proofOrSkillOrTraining: Immutable<API.NoMetadata<API.ProofBundle>> | API.Skill | API.Training,
): Promise<S3ObjectWithLink[]> {
  const filesWithLinks: S3ObjectWithLink[] = _.compact(
    await Promise.all(
      proofOrSkillOrTraining.files.map(async file => {
        const _isImage = isImage(file.key);
        const downloadLink = await getFileLink(file);
        if (API.isFailure(downloadLink)) {
          logger.error(downloadLink);
          return;
        }
        return {
          ...file,
          downloadLink,
          allowDownload: _isImage,
        };
      }),
    ),
  );
  return filesWithLinks;
}
