import React, { useState, useContext, useRef, useEffect } from 'react';
import WebModal from 'modal-react-native-web';
import { View, TouchableOpacity, ScrollView, Text } from 'react-native';
import { ModalHeaderText } from 'shared/ui-component/Modal/ModalHeaderText';
import { IconSVG } from 'shared/ui-component/Icon';
import { OutlineButton } from 'shared/ui-component/Button';
import { styles } from './styles';
import { WorkerOrgUnitRolesInfo } from '../../user-info/component';
import { UserInfo } from '../../user-info/container';
import { InviteNonReferencedWorkerPage } from '../../invite-non-referenced-worker/container';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { t } from 'shared/localisation/i18n';
import { InputSearch } from 'shared/ui-component/Input';
import { escapeRegExp } from 'shared/ui-component/Input/InputTag';
import { inviteWorkerModalFilterContext } from 'shared/context/inviteWorkerModalContext';
import { ModalBackgroundStyle, ModalCardStyle, ModalHeaderStyle } from 'shared/styles/ModalStyles';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import * as API from 'shared/backend-data';

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;
const arrowBack = require('shared/assets/svg/icon.arrowLeft.mobile-2.svg').default;

interface Props {
  showUsersModal: (openModal: boolean) => void;
}

export const UsersModalComponent: React.FC<Props> = props => {
  const { showUsersModal } = props;
  const [textInput, setTextInput] = useState<string>('');
  const [noResultFound, setNoResultFound] = useState<boolean>(false);
  const [usersListHeader, setUsersListHeader] = useState<boolean>(true);
  const {
    workers: [workers],
    filteredWorkersData: [filteredWorkersData, setFilteredWorkersData],
    loading: [loading],
  } = useContext(inviteWorkerModalFilterContext);

  const scrollRef = useRef<ScrollView>(null);

  const { isValidPermission } = useContext(PermissionManagementContext);

  const onTyping = (input: string) => {
    if (input === '') {
      clearInput();
    } else {
      const inputWithoutSpaces = input.trim();
      const filterResult = filter(inputWithoutSpaces);
      if (!filterResult.length) setNoResultFound(true);
      else if (noResultFound) setNoResultFound(false);
      setFilteredWorkersData(filterResult);
      setTextInput(inputWithoutSpaces);
    }
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (noResultFound) {
          setUsersListHeader(false);
          slideToInvitationPage();
        }
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [noResultFound]);

  const slideToInvitationPage = () => {
    scrollRef.current?.scrollTo({ x: 800, animated: true });
  };

  const slideBack = () => {
    scrollRef.current?.scrollTo({ x: 0, animated: true });
  };

  const clearInput = () => {
    setFilteredWorkersData(
      workers!.filter(workerData => {
        return workerData.worker.userId;
      }),
    );
    if (noResultFound) setNoResultFound(false);
    setTextInput('');
  };

  const filter = (inputText: string): WorkerOrgUnitRolesInfo[] => {
    const regex = RegExp(escapeRegExp(inputText), 'i');
    let filteredRows: WorkerOrgUnitRolesInfo[] = [];

    if (workers.length) {
      filteredRows = workers.filter((row: WorkerOrgUnitRolesInfo) => {
        return (
          regex.test(row.worker.name) ||
          regex.test(row.organizationalUnits) ||
          regex.test(row.worker.email ?? '')
        );
      });
    }

    return filteredRows;
  };

  return (
    <WebModal
      animationType="fade"
      transparent
      visible={true}
      onRequestClose={() => showUsersModal(false)}
    >
      <View style={[ModalBackgroundStyle]}>
        <View style={[ModalCardStyle, styles.container]}>
          {usersListHeader ? (
            <View style={[ModalHeaderStyle, styles.headerContainer]}>
              <TouchableOpacity
                style={styles.closeButtonIcon}
                onPress={() => showUsersModal(false)}
              >
                <IconSVG svgComponent={closeIcon} />
              </TouchableOpacity>
              <ModalHeaderText title={t('common:auth.invitation.invitation_plural')} />
              <View style={styles.inputSearchContainer}>
                <InputSearch
                  placeHolder={t('alex:inviteWorker.searchPlaceHolder')}
                  onInputTextChange={text => onTyping(text)}
                  inputStyle={styles.inputStyle}
                  onPressClearButton={clearInput}
                  textFieldStyle={styles.searchInputTextField}
                />
              </View>
            </View>
          ) : (
            <View style={[ModalHeaderStyle, styles.headerContainer]}>
              <TouchableOpacity
                style={styles.closeButtonIcon}
                onPress={() => showUsersModal(false)}
              >
                <IconSVG svgComponent={closeIcon} />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.goBackIconContainer}
                onPress={() => {
                  setUsersListHeader(true);
                  slideBack();
                }}
              >
                <IconSVG svgComponent={arrowBack} />
              </TouchableOpacity>
              <ModalHeaderText
                title={
                  t('alex:inviteWorker.invitationModalHeader.boldHeaderText') +
                  ' ' +
                  t('alex:inviteWorker.invitationModalHeader.regularHeaderText', undefined, false)
                }
                invert
              />
            </View>
          )}
          <ScrollView
            ref={scrollRef}
            contentContainerStyle={[styles.scrollViewContentContainer]}
            horizontal
            scrollEnabled={false}
          >
            <View style={[styles.firstPageContainer]}>
              {noResultFound ? (
                <View style={styles.noResultView}>
                  <Text style={styles.noResultText}>{t('alex:inviteWorker.noResultFound.0')}</Text>
                  {isValidPermission(API.Permission.workers_edit) ? (
                    <>
                      <Text style={styles.noResultText}>
                        {t('alex:inviteWorker.noResultFound.1')}
                      </Text>
                      <OutlineButton
                        style={styles.inviteButton}
                        textStyle={styles.inviteButtonText}
                        onPress={() => {
                          setUsersListHeader(false);
                          slideToInvitationPage();
                        }}
                        text={t('common:button.invite')}
                      />
                    </>
                  ) : (
                    <Text style={styles.noResultText}>
                      {t('alex:inviteWorker.noResultFound.2')}
                    </Text>
                  )}
                </View>
              ) : (
                <ScrollView style={[styles.itemsScrollView]}>
                  {filteredWorkersData.map((workerAssignmentInfo, index) => (
                    <UserInfo
                      index={index}
                      key={workerAssignmentInfo.worker.id}
                      item={workerAssignmentInfo}
                      searchedInput={textInput}
                      handleModalClose={() => {
                        showUsersModal(false);
                      }}
                    />
                  ))}
                </ScrollView>
              )}
            </View>
            <InviteNonReferencedWorkerPage
              handleModalClose={() => {
                showUsersModal(false);
              }}
              searchedValue={textInput}
            />
          </ScrollView>
          {loading && <Loader />}
        </View>
      </View>
    </WebModal>
  );
};
