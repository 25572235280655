import React, { useState, useEffect, useCallback } from 'react';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { SkillBadge } from './SkillBadge';
import { SkillBadgeStates } from '../../util/Skill';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { MyHub } from 'shared/util/MyHub';
import * as _ from 'lodash-es';
import { DataType } from 'shared/backend-data';

interface Props {
  workerId: string;
  badgeValueState?: SkillBadgeStates;
  style?: StyleProp<ViewStyle>;
}

export const WorkerSkillBadge: React.FC<Props> = props => {
  const { workerId, badgeValueState, style } = props;

  const [badgeValue, setBadgeValue] = useState<SkillBadgeStates | null>(null);

  const updateBadgeState = useCallback(
    _.debounce(_workerId => {
      fetchComputeAndSetBadgeState(_workerId);
    }, API.PERF_workersWorkstationsUpdateDebounceInMs),
    [],
  );

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === DataType.WORKERWORKSTATION &&
        (data.tooManyMutations || workerId === data.factory.workerWorkstation.workerId)
      ) {
        updateBadgeState(workerId);
      }
    });

    if (badgeValueState) {
      setBadgeValue(badgeValueState);
    } else {
      fetchComputeAndSetBadgeState(workerId);
    }

    return () => {
      removeListener();
    };
  }, [workerId, badgeValueState]);

  function fetchComputeAndSetBadgeState(workerId: string) {
    const badgeState = API.computeWorkerBadgeState(workerId);
    if (API.isFailure(badgeState)) {
      logger.warn(badgeState);
      return;
    }
    setBadgeValue(badgeState ?? null);
  }

  return <SkillBadge badgeValue={badgeValue} style={[styles.badgeIcon, style]} />;
};

const styles = StyleSheet.create({
  badgeIcon: {
    top: -10,
    left: 1,
  },
});
