import { StyleSheet, ViewStyle, StyleProp } from 'react-native';
import { Colors, Spacings } from 'shared/styles';
import { TextFontStyles } from './TextStyles';
import * as _ from 'lodash-es';

export const Styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  fullWidth: { width: '100%' },
  shadow: {
    shadowColor: Colors.Black,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowOffset: {
      height: 5,
      width: 0,
    },
    elevation: 5,
  },
  cardMain: {
    backgroundColor: '#ffffff',
    padding: 30,
    borderRadius: 6,
    textAlign: 'left',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  bannerStyle: {
    height: '100%',
    width: 224,
    alignItems: 'center',
    paddingTop: Spacings.xMedium,
    paddingHorizontal: Spacings.xMedium,
    paddingBottom: Spacings.Medium,
    borderRadius: Spacings.Small,
    zIndex: 999,
    shadowColor: Colors.ShadowColor,
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  tableContainer: {
    marginTop: Spacings.CardPadding + Spacings.Small,
    marginBottom: Spacings.Small,
    marginHorizontal: Spacings.CardPadding,
    padding: 0,
  },
  bubbleCountContainer: {
    height: Spacings.Medium,
    minWidth: Spacings.Medium,
    backgroundColor: Colors.GreyLight,
    borderRadius: Spacings.Medium / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bubbleCount: {
    padding: Spacings.Unit,
    ...TextFontStyles.LatoBlack,
    fontSize: 11,
    color: Colors.White,
  },
  tableObjectIconContainer: {
    backgroundColor: Colors.Yellow,
    borderRadius: (30 + 30) / 2,
    height: 30,
    width: 30,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableText: {
    textAlign: 'left',
    fontSize: 14,
    color: Colors.MediumGray,
    ...TextFontStyles.SemiBold,
    justifyContent: 'center',
  },
  inputTag: {
    zIndex: 1,
    width: '100%',
  },
});

export const extractSizeStyle = (style: StyleProp<any>) => {
  const flattenedStyle = StyleSheet.flatten(style);
  return _.pick(flattenedStyle, ['flex', 'width', 'height']);
};


export const extractBorderRadiusStyle = (style: StyleProp<any>) => {
  const flattenedStyle = StyleSheet.flatten(style);
  return _.pick(flattenedStyle, [
    'borderRadius',
    'borderTopLeftRadius',
    'borderTopRightRadius',
    'borderBottomLeftRadius',
    'borderBottomRightRadius',
  ]);
};

export const createRoundedStyle = (size: number): ViewStyle => ({
  borderRadius: size / 2,
  width: size,
  height: size,
});

export default Styles;
