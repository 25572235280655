import React, { useEffect, useState, useContext, useRef } from 'react';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash-es';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { TreeTableNode, TreeNode } from 'shared/backend-data/factoryCache/Tree';
import { TableRow } from 'shared/ui-component/Table';
import { KPIOrgUnitsComponent } from '../component';
import { TreeTableContext } from 'sharedweb/src/context/TreeTableContext';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { RouteLocations } from '../../../../navigation/Routes';
import logger from 'shared/util/Logger';
import { convertAllTargetsFromDecimalToPercentage, TargetUnit } from 'shared/backend-data';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { MyHub } from 'shared/util/MyHub';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { Loader } from 'shared/ui-component/Loader/Loader';

export enum KPI_Tabs {
  VERSATILITY,
  
  MAGICSQUARE,
}

export enum MagicSquareAlertLevel {
  GREEN = 0,
  ORANGE = 1,
  RED = 2,
}

interface RowInfo {
  isWorkstation: boolean;
  childrenCount: number;
}
export interface TreeRow extends TableRow {
  node: TreeTableNode;
}

interface VersatilityRowInfo extends RowInfo {
  isTargetOK: boolean;
  level2actual?: number;
  level2target?: number;
  level3actual?: number;
  level3target?: number;
  level4actual: number;
  level4target: number;
  assignedWorkers?: number;
}
export interface TreeRowVersatility extends TreeRow {
  info?: VersatilityRowInfo;
}

interface MagicSquareRowInfo extends RowInfo {
  level1Exact: number;
  level2Exact: number;
  level3Exact: number;
  level4Exact: number;
  totalMagicSquares: number;
  meanPerEmployee: number;
  alertLevel: MagicSquareAlertLevel;
}
export interface TreeRowMagicSquare extends TreeRow {
  info: MagicSquareRowInfo;
}

export const KPIOrgUnits: React.FC = () => {
  const isMounted = useIsMounted();

  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState<KPI_Tabs>(KPI_Tabs.VERSATILITY);
  const [dataVersatilityRows, setDataVersatilityRows] = useState<TreeRowVersatility[]>();
  const [radioIndex, setRadioIndex] = useState<number>(0);
  const [dataMagicSquare, setDataMagicSquare] = useState<TreeRowMagicSquare[]>();
  const [loader, setLoader] = useState<boolean>(false);
  const [updateTableValue, setUpdateTableValue] = useState<number>(0);

  const selectedShiftIdsRef = useRef<string[]>([]);
  const dataVersatilityRowsRef = useRef<TreeRowVersatility[]>([]);

  const { collapsedNodes, selectedShiftIds, setSelectedShiftIds, setCollapsedNodes } =
    useContext(TreeTableContext);
  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);
  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const removeListener = MyHub.listenBusinessObject(
        'BusinessObjectMutate',
        async ({ data }) => {
          if (data.factory.dataType === API.DataType.WORKERWORKSTATION) {
            await getTableData();
            if (!isMounted.current) return;
          }
        },
      );

      const removeListener2 = MyHub.listenBusinessObject('TreeStructureUpdate', async () => {
        await getTableData();
        if (!isMounted.current) return;
      });

      return () => {
        removeListener();
        removeListener2();
      };
    });
  }, [selectedTab]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await getTableData();
      if (!isMounted.current) return;

      const shifts = await API.getShifts();
      if (!isMounted.current) return;
      if (API.isFailure(shifts)) {
        logger.warn(shifts);
        return;
      }
      selectedShiftIdsRef.current = shifts.map(shift => shift.id);
      setSelectedShiftIds(selectedShiftIdsRef.current);
    });
    return () => {
      setCollapsedNodes([]);
    };
  }, []);

  useEffect(() => {
    getTableData();
  }, [radioIndex]);

  useEffect(() => {
    const data = filterVersatilityTableRows(dataVersatilityRowsRef.current);
    setDataVersatilityRows([...data]);
  }, [collapsedNodes, selectedShiftIds, updateTableValue]);

  async function handleWorkstationUpdate(
    tag: DropDownOption,
    level: API.WorkstationWorkerLevels,
    workstationId: string,
    shiftId?: string,
  ) {
    setLoader(true);

    const workstationData = await API.getWorkstation(workstationId);
    if (!isMounted.current) return;
    if (API.isFailure(workstationData)) {
      logger.warn(workstationData);
      setLoader(false);
      return;
    }

    let parsedObject: API.WorkersTargetOnShift = {};

    if (workstationData.workersTargetOnShift) {
      parsedObject = JSON.parse(workstationData.workersTargetOnShift);

      if (level === API.WorkstationWorkerLevels.LEVEL2) {
        if (parsedObject[shiftId ?? workstationId]) {
          if (parsedObject[shiftId ?? workstationId].workersWithLevel2AtLeastWorkstationTarget) {
            parsedObject[shiftId ?? workstationId] = {
              ...parsedObject[shiftId ?? workstationId],
              workersWithLevel2AtLeastWorkstationTarget: {
                ...parsedObject[shiftId ?? workstationId].workersWithLevel2AtLeastWorkstationTarget,
                isPercentage: !!radioIndex,
                minNumberOfWorker: tag.value,
              },
            };
          } else {
            parsedObject[
              shiftId ?? workstationId
            ].workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker = tag.value;
            parsedObject[
              shiftId ?? workstationId
            ].workersWithLevel2AtLeastWorkstationTarget.isPercentage = !!radioIndex;
          }
        } else {
          parsedObject[shiftId ?? workstationId] = {
            workersWithLevel2AtLeastWorkstationTarget: {
              isPercentage: !!radioIndex,
              minNumberOfWorker: tag.value,
            },
            workersWithLevel3AtLeastWorkstationTarget: {},
            workersWithLevel4WorkstationTarget: {},
          };
        }
      } else if (level === API.WorkstationWorkerLevels.LEVEL3) {
        if (parsedObject[shiftId ?? workstationId]) {
          if (parsedObject[shiftId ?? workstationId]?.workersWithLevel3AtLeastWorkstationTarget) {
            parsedObject[shiftId ?? workstationId] = {
              ...parsedObject[shiftId ?? workstationId],
              workersWithLevel3AtLeastWorkstationTarget: {
                ...parsedObject[shiftId ?? workstationId].workersWithLevel3AtLeastWorkstationTarget,
                isPercentage: !!radioIndex,
                minNumberOfWorker: tag.value,
              },
            };
          } else {
            parsedObject[
              shiftId ?? workstationId
            ].workersWithLevel3AtLeastWorkstationTarget.isPercentage = !!radioIndex;
            parsedObject[
              shiftId ?? workstationId
            ].workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker = tag.value;
          }
        } else {
          parsedObject[shiftId ?? workstationId] = {
            workersWithLevel2AtLeastWorkstationTarget: {},
            workersWithLevel3AtLeastWorkstationTarget: {
              isPercentage: !!radioIndex,
              minNumberOfWorker: tag.value,
            },
            workersWithLevel4WorkstationTarget: {},
          };
        }
      } else if (level === API.WorkstationWorkerLevels.LEVEL4) {
        if (parsedObject[shiftId ?? workstationId]) {
          if (parsedObject[shiftId ?? workstationId].workersWithLevel4WorkstationTarget) {
            parsedObject[shiftId ?? workstationId] = {
              ...parsedObject[shiftId ?? workstationId],
              workersWithLevel4WorkstationTarget: {
                ...parsedObject[shiftId ?? workstationId].workersWithLevel4WorkstationTarget,
                isPercentage: !!radioIndex,
                minNumberOfWorker: tag.value,
              },
            };
          } else {
            parsedObject[
              shiftId ?? workstationId
            ].workersWithLevel4WorkstationTarget.minNumberOfWorker = tag.value;
            parsedObject[shiftId ?? workstationId].workersWithLevel4WorkstationTarget.isPercentage =
              !!radioIndex;
          }
        } else {
          parsedObject[shiftId ?? workstationId] = {
            workersWithLevel2AtLeastWorkstationTarget: {},
            workersWithLevel3AtLeastWorkstationTarget: {},
            workersWithLevel4WorkstationTarget: {
              isPercentage: !!radioIndex,
              minNumberOfWorker: tag.value,
            },
          };
        }
      }
    } else {
      if (level === API.WorkstationWorkerLevels.LEVEL2) {
        parsedObject[shiftId ?? workstationId] = {
          workersWithLevel2AtLeastWorkstationTarget: {
            isPercentage: !!radioIndex,
            minNumberOfWorker: tag.value,
          },
          workersWithLevel3AtLeastWorkstationTarget: {},
          workersWithLevel4WorkstationTarget: {},
        };
      } else if (level === API.WorkstationWorkerLevels.LEVEL3) {
        parsedObject[shiftId ?? workstationId] = {
          workersWithLevel2AtLeastWorkstationTarget: {},
          workersWithLevel3AtLeastWorkstationTarget: {
            isPercentage: !!radioIndex,
            minNumberOfWorker: tag.value,
          },
          workersWithLevel4WorkstationTarget: {},
        };
      } else if (level === API.WorkstationWorkerLevels.LEVEL4) {
        parsedObject[shiftId ?? workstationId] = {
          workersWithLevel2AtLeastWorkstationTarget: {},
          workersWithLevel3AtLeastWorkstationTarget: {},
          workersWithLevel4WorkstationTarget: {
            isPercentage: !!radioIndex,
            minNumberOfWorker: tag.value,
          },
        };
      }
    }

    const updateWorkstation = await API.updateWorkstation({
      id: workstationData.id,
      workersTargetOnShift: JSON.stringify(parsedObject),
    });
    if (!isMounted.current) return;
    if (API.isFailure(updateWorkstation)) {
      logger.warn(updateWorkstation);
      setLoader(false);
      return;
    }

    const index = dataVersatilityRowsRef.current?.findIndex(
      eachRow => eachRow.key === workstationData.id,
    );
    if (index !== undefined && index !== -1 && dataVersatilityRowsRef.current) {
      dataVersatilityRowsRef.current[index].info = undefined;
    }

    setUpdateTableValue(new Date().getTime());

    setLoader(false);
  }

  async function getTableData() {
    const _flattenedTree: TreeNode[] = API.Tree.getFlatAndOrderedTree(API.Tree.getTopTreeNodes());
    await getTableRows(selectedTab, _flattenedTree);
  }

  /**
   * Fetch rows for kpi tables
   * @param setting TableSetting - To specify which one of the 3 tables we're considering
   */
  async function getTableRows(setting: KPI_Tabs, flatTree: TreeNode[]): Promise<void> {
    switch (setting) {
      case KPI_Tabs.VERSATILITY:
        await getRowsForVersatilityTable(flatTree);
        break;

      case KPI_Tabs.MAGICSQUARE:
        await getRowsForMagicSquareTable(flatTree);
        break;
    }
  }

  async function getRowsForMagicSquareTable(
    flatTree: TreeNode[],
  ): Promise<TreeRowMagicSquare[] | undefined> {
    const _info: Map<string, MagicSquareRowInfo> = new Map<string, MagicSquareRowInfo>();

    
    const fallbackData: MagicSquareRowInfo = {
      isWorkstation: false,
      childrenCount: 0,
      alertLevel: MagicSquareAlertLevel.RED,
      level1Exact: 0,
      level2Exact: 0,
      level3Exact: 0,
      level4Exact: 0,
      totalMagicSquares: 0,
      meanPerEmployee: 0,
    };

    for (const node of flatTree) {
      if (API.isTreeNode(API.DataType.WORKSTATION, node)) {
        const shiftIds: string[] = (node.object.shiftIds ?? []).filter(shiftId =>
          selectedShiftIdsRef.current.includes(shiftId),
        );

        const targetDetails = await API.fetchWorkstationTargetAndWorkersAlert(
          node.object,
          workstationTargetsStartingAtLevel,
          shiftIds,
        );
        if (!isMounted.current) return;
        if (API.isFailure(targetDetails)) {
          logger.warn(targetDetails);
          return;
        }

        let totalMagicSquares: number = 0;
        let meanPerEmployee: number = 0;
        let level1Exact: number = 0;
        let level2Exact: number = 0;
        let level3Exact: number = 0;
        let level4Exact: number = 0;
        let alertLevel: MagicSquareAlertLevel;

        let ids: string[];
        if (shiftIds.length) {
          ids = shiftIds;
        } else if (!shiftIds.length && !node.object.shiftIds?.length) {
          ids = [node.id];
        } else {
          ids = [];
        }
        ids.forEach((id: string) => {
          const workersWithLevel1AtLeastActual =
            targetDetails.workstationsTargetAndActual[id].workersWithLevel1AtLeastActual ?? 0;
          level1Exact +=
            workersWithLevel1AtLeastActual -
            targetDetails.workstationsTargetAndActual[id].workersWithLevel2AtLeastActual;

          level2Exact +=
            targetDetails.workstationsTargetAndActual[id].workersWithLevel2AtLeastActual -
            targetDetails.workstationsTargetAndActual[id].workersWithLevel3AtLeastActual;

          level3Exact +=
            targetDetails.workstationsTargetAndActual[id].workersWithLevel3AtLeastActual -
            targetDetails.workstationsTargetAndActual[id].workersWithLevel4Actual;

          level4Exact += targetDetails.workstationsTargetAndActual[id].workersWithLevel4Actual;

          totalMagicSquares += level1Exact + 2 * level2Exact + 3 * level3Exact + 4 * level4Exact;

          meanPerEmployee +=
            workersWithLevel1AtLeastActual === 0
              ? 0
              : Math.round((totalMagicSquares / workersWithLevel1AtLeastActual) * 10) / 10;
        });

        
        
        if ((level1Exact >= 1 || level2Exact >= 1) && level3Exact >= 1 && level4Exact >= 1) {
          alertLevel = MagicSquareAlertLevel.GREEN;
        } else if (
          (level4Exact == 0 && level3Exact == 0) ||
          level1Exact + level2Exact + level3Exact + level4Exact === 1
        ) {
          alertLevel = MagicSquareAlertLevel.RED;
        } else {
          alertLevel = MagicSquareAlertLevel.ORANGE;
        }

        const workstationData: MagicSquareRowInfo = {
          isWorkstation: true,
          childrenCount: 0,
          level1Exact,
          level2Exact,
          level3Exact,
          level4Exact,
          totalMagicSquares,
          meanPerEmployee,
          alertLevel,
        };
        _info.set(node.id, workstationData);

        for (const parentId of node.parentIds) {
          const parentData = _info.get(parentId);
          if (!parentData) {
            _info.set(parentId, { ...workstationData, isWorkstation: false, childrenCount: 1 });
          } else {
            let totalMagicSquares: number;

            const parentLevel1Exact = parentData.level1Exact + workstationData.level1Exact;
            const parentLevel2Exact = parentData.level2Exact + workstationData.level2Exact;
            const parentLevel3Exact = parentData.level3Exact + workstationData.level3Exact;
            const parentLevel4Exact = parentData.level4Exact + workstationData.level4Exact;
            const parentLevel1AtLeast =
              parentLevel1Exact + parentLevel2Exact + parentLevel3Exact + parentLevel4Exact;

            totalMagicSquares =
              parentLevel1Exact +
              2 * parentLevel2Exact +
              3 * parentLevel3Exact +
              4 * parentLevel4Exact;

            meanPerEmployee =
              parentLevel1AtLeast === 0
                ? 0
                : Math.round((totalMagicSquares / parentLevel1AtLeast) * 10) / 10;

            let alertLevel: MagicSquareAlertLevel;
            if (parentData.alertLevel === MagicSquareAlertLevel.RED)
              alertLevel = MagicSquareAlertLevel.RED;
            else if (parentData.alertLevel === MagicSquareAlertLevel.ORANGE)
              alertLevel =
                workstationData.alertLevel === MagicSquareAlertLevel.RED
                  ? MagicSquareAlertLevel.RED
                  : MagicSquareAlertLevel.ORANGE;
            else alertLevel = workstationData.alertLevel;

            const _data: MagicSquareRowInfo = {
              isWorkstation: false,
              childrenCount: parentData.childrenCount + 1,
              alertLevel,
              level1Exact: parentLevel1Exact,
              level2Exact: parentLevel2Exact,
              level3Exact: parentLevel3Exact,
              level4Exact: parentLevel4Exact,
              totalMagicSquares,
              meanPerEmployee,
            };
            _info.set(parentId, _data);
          }
        }
      } else if (API.isTreeNode(API.DataType.ORGUNIT, node) && !node.children.length) {
        _info.set(node.id, fallbackData);
      } else {
        logger.error('TreeNode type not implemented yet, please fix: ' + node.factory.dataType);
      }
    }

    const _rows: TreeRowMagicSquare[] = flatTree.map(node => {
      const isExpanded: boolean = !collapsedNodes.includes(node.id);

      const row: TreeRowMagicSquare = {
        key: node.id,
        node: { ...node, isExpanded },
        info: _info.get(node.id) ?? fallbackData,
      };
      return row;
    });

    const data = filterMagicSquareTableRow(_rows);

    setDataMagicSquare(data);
  }

  function handleRowPress(row: TreeRowMagicSquare | TreeRowVersatility): void {
    if (API.isTreeNode(API.DataType.WORKSTATION, row.node)) {
      setTreeNode(row.node);
      history.push(RouteLocations.Workstations(row.node.object.id, undefined, undefined));
      return;
    }

    if (!collapsedNodes.includes(row.node.id)) {
      setCollapsedNodes([...collapsedNodes, row.node.id]);
      const children = API.Tree.getChildren(row.node.object.id, true, API.DataType.WORKSTATION);
      if (API.isFailure(children)) {
        logger.warn(children);
        return;
      }

      const fallbackData: VersatilityRowInfo = {
        isWorkstation: false,
        childrenCount: 0,
        assignedWorkers: 0,
        isTargetOK: true,
        level2actual:
          workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2 ? 0 : undefined,
        level2target:
          workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2 ? 0 : undefined,
        level3actual:
          workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3 ? 0 : undefined,
        level3target:
          workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3 ? 0 : undefined,
        level4actual: 0,
        level4target: 0,
      };

      for (const child of children) {
        const foundData = dataVersatilityRowsRef.current?.find(eachRow => eachRow.key === child.id);

        fallbackData.childrenCount = fallbackData.childrenCount + 1;
        fallbackData.assignedWorkers =
          fallbackData.assignedWorkers! + (foundData?.info?.assignedWorkers ?? 0);
        fallbackData.isTargetOK = !foundData?.info?.isTargetOK ? false : fallbackData.isTargetOK;
        fallbackData.level2actual =
          workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2
            ? (fallbackData.level2actual ?? 0) + (foundData?.info?.level2actual ?? 0)
            : undefined;
        fallbackData.level2target =
          workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2
            ? (fallbackData.level2target ?? 0) + (foundData?.info?.level2target ?? 0)
            : undefined;
        fallbackData.level3actual =
          workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3
            ? (fallbackData.level3actual ?? 0) + (foundData?.info?.level3actual ?? 0)
            : undefined;
        fallbackData.level3target =
          workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3
            ? (fallbackData.level3target ?? 0) + (foundData?.info?.level3target ?? 0)
            : undefined;
        fallbackData.level4actual =
          (fallbackData.level4actual ?? 0) + (foundData?.info?.level4actual ?? 0);
        fallbackData.level4target =
          (fallbackData.level4target ?? 0) + (foundData?.info?.level4target ?? 0);
      }

      const index = dataVersatilityRowsRef.current?.findIndex(
        eachRow => eachRow.key === row.node.id,
      );
      if (index !== undefined && index !== -1 && dataVersatilityRowsRef.current) {
        dataVersatilityRowsRef.current[index].info = fallbackData;
      }
    } else {
      setCollapsedNodes([...collapsedNodes].filter(nodeId => nodeId !== row.node.id));
    }
  }

  function filterVersatilityTableRows(rows: TreeRowVersatility[]): TreeRowVersatility[] {
    const _filteredDataVersatility: TreeRowVersatility[] = [];

    (rows ?? []).map(row => {
      let isShown: boolean = true;

      for (const parentId of row.node.parentIds) {
        if (collapsedNodes.includes(parentId)) isShown = false;
      }
      if (isShown) {
        _filteredDataVersatility.push({
          ...row,
          node: { ...row.node, isExpanded: !collapsedNodes.includes(row.node.id) },
        });
      }
    });

    return _filteredDataVersatility;
  }

  function filterMagicSquareTableRow(rows: TreeRowMagicSquare[]): TreeRowMagicSquare[] {
    const _filteredDataMagicSquare: TreeRowMagicSquare[] = [];

    (dataMagicSquare ?? []).map(row => {
      let isShown: boolean = true;

      for (const parentId of row.node.parentIds) {
        if (collapsedNodes.includes(parentId)) isShown = false;
      }
      if (isShown)
        _filteredDataMagicSquare.push({
          ...row,
          node: { ...row.node, isExpanded: !collapsedNodes.includes(row.node.id) },
        });
    });

    return _filteredDataMagicSquare;
  }

  async function getRowsForVersatilityTable(flatTree: TreeNode[]): Promise<void> {
    const _rows: TreeRowVersatility[] = [];

    for (const node of flatTree) {
      const isExpanded: boolean = !collapsedNodes.includes(node.id);

      const row: TreeRowVersatility = {
        key: node.id,
        node: { ...node, isExpanded },
      };

      _rows.push(row);
    }

    const data = filterVersatilityTableRows(_rows);
    dataVersatilityRowsRef.current = data;
    setDataVersatilityRows(data);
  }

  async function fetchVersatilityRowDetails(
    row: TreeRowVersatility,
    isMounted: React.MutableRefObject<boolean>,
  ): Promise<boolean> {
    let updated = false;
    if (row.info === undefined) {
      const _info: Map<string, VersatilityRowInfo> = new Map<string, VersatilityRowInfo>();
      
      const fallbackData: VersatilityRowInfo = {
        isWorkstation: false,
        childrenCount: 0,
        assignedWorkers: 0,
        isTargetOK: true,
        level2actual:
          workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2 ? 0 : undefined,
        level2target:
          workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2 ? 0 : undefined,
        level3actual:
          workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3 ? 0 : undefined,
        level3target:
          workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3 ? 0 : undefined,
        level4actual: 0,
        level4target: 0,
      };

      if (API.isTreeNode(API.DataType.WORKSTATION, row.node)) {
        const shiftIds: string[] = [...(row.node.object.shiftIds ?? [])].filter(shiftId =>
          selectedShiftIdsRef.current.includes(shiftId),
        );

        let workstationData: VersatilityRowInfo = {
          isWorkstation: true,
          childrenCount: 0,
          isTargetOK: true,
          level2actual: 0,
          level2target: 0,
          level3actual: 0,
          level3target: 0,
          level4actual: 0,
          level4target: 0,
        };

        if (!shiftIds.length && row.node.object.shiftIds?.length) {
          _info.set(row.node.id, workstationData);
        } else {
          const workstation = await API.getWorkstation(row.node.id);
          if (!isMounted.current) return false;
          if (API.isFailure(workstation)) {
            logger.warn(workstation);
            return false;
          }

          const decimalTargets = await API.fetchWorkstationTargetAndWorkersAlert(
            workstation,
            workstationTargetsStartingAtLevel,
            shiftIds,
            true,
            TargetUnit.DECIMAL,
          );
          if (!isMounted.current) return false;
          if (API.isFailure(decimalTargets)) {
            logger.warn(decimalTargets);
            return false;
          }

          const percentageTargets = await API.fetchWorkstationTargetAndWorkersAlert(
            workstation,
            workstationTargetsStartingAtLevel,
            shiftIds,
            true,
            TargetUnit.PERCENTAGE,
          );
          if (!isMounted.current) return false;
          if (API.isFailure(percentageTargets)) {
            logger.warn(percentageTargets);
            return false;
          }

          const convertedTargets =
            radioIndex === TargetUnit.DECIMAL ? decimalTargets : percentageTargets;

          const numOfAssignedWorkers = await API.workersAssignedInShiftOrWorkstation(
            row.node.id,
            undefined,
          );
          if (API.isFailure(numOfAssignedWorkers)) return false;

          workstationData = {
            isWorkstation: true,
            childrenCount: 0,
            assignedWorkers: numOfAssignedWorkers ?? 0,
            isTargetOK: convertedTargets.isTargetOK,
            level2actual:
              workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2
                ? convertedTargets.workstationsTargetAndActual[row.node.id]
                    .workersWithLevel2AtLeastActual
                : undefined,
            level2target:
              workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2
                ? convertedTargets.workstationsTargetAndActual[row.node.id]
                    .workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker
                : undefined,
            level3actual:
              workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3
                ? convertedTargets.workstationsTargetAndActual[row.node.id]
                    .workersWithLevel3AtLeastActual
                : undefined,
            level3target:
              workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3
                ? convertedTargets.workstationsTargetAndActual[row.node.id]
                    .workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker
                : undefined,
            level4actual:
              convertedTargets.workstationsTargetAndActual[row.node.id].workersWithLevel4Actual,
            level4target:
              convertedTargets.workstationsTargetAndActual[row.node.id]
                .workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0,
          };
        }

        _info.set(row.node.id, workstationData);
      } else if (!API.isTreeNode(API.DataType.WORKSTATION, row.node) && !row.node.children.length)
        _info.set(row.node.id, fallbackData);

      let info = _info.get(row.node.id) ?? fallbackData;

      if (
        !API.isTreeNode(API.DataType.WORKSTATION, row.node) &&
        radioIndex === TargetUnit.PERCENTAGE
      ) {
        const workersInOrgUnits = await API.getWorkersInOrganizationalUnits([row.node.id]);
        if (API.isFailure(workersInOrgUnits)) {
          logger.warn(workersInOrgUnits);
          return false;
        }

        const _dataToConvert: API.WorkstationTargetActual = {
          workersWithLevel2AtLeastActual: info.level2actual ?? 0,
          workersWithLevel3AtLeastActual: info.level3actual ?? 0,
          workersWithLevel4Actual: info.level4actual,
          workersWithLevel2AtLeastWorkstationTarget: { minNumberOfWorker: info.level2target },
          workersWithLevel3AtLeastWorkstationTarget: { minNumberOfWorker: info.level3target },
          workersWithLevel4WorkstationTarget: { minNumberOfWorker: info.level4target },
        };
        const orgUnitPercentInfo = await convertAllTargetsFromDecimalToPercentage(
          _dataToConvert,
          row.node.id,
          row.node.id,
          true,
          workersInOrgUnits.result.length ?? 0,
        );
        if (API.isFailure(orgUnitPercentInfo)) {
          logger.warn(orgUnitPercentInfo);
          return false;
        }

        info = {
          ...info,
          level2actual: orgUnitPercentInfo.workersWithLevel2AtLeastActual,
          level2target:
            orgUnitPercentInfo.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker,
          level3actual: orgUnitPercentInfo.workersWithLevel3AtLeastActual,
          level3target:
            orgUnitPercentInfo.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker,
          level4actual: orgUnitPercentInfo.workersWithLevel4Actual,
          level4target:
            orgUnitPercentInfo.workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0,
        };
      }

      row.info = info;
      const index = dataVersatilityRowsRef.current?.findIndex(
        eachRow => eachRow.key === row.node.id,
      );
      if (index !== undefined && index !== -1 && dataVersatilityRowsRef.current) {
        dataVersatilityRowsRef.current[index].info = info;
      }
      updated = true;
    }

    return updated;
  }

  async function handleSelectShift(shiftId: string, row: TreeRowVersatility): Promise<void> {
    if (!selectedShiftIdsRef.current.includes(shiftId)) {
      selectedShiftIdsRef.current = [...selectedShiftIdsRef.current, shiftId];
    } else {
      selectedShiftIdsRef.current = [...selectedShiftIdsRef.current].filter(
        selectedShiftId => shiftId !== selectedShiftId,
      );
    }
    setSelectedShiftIds(selectedShiftIdsRef.current);

    const children = API.Tree.getChildren(row.node.object.id, true);
    if (!isMounted.current) return;
    if (API.isFailure(children)) {
      logger.warn(children);
      return;
    }

    _.map(children, children => {
      const index = dataVersatilityRowsRef.current?.findIndex(
        eachRow => eachRow.key === children.id,
      );
      if (index !== undefined && index !== -1 && dataVersatilityRowsRef.current) {
        dataVersatilityRowsRef.current[index].info = undefined;
      }
    });
  }

  return (
    <>
      <KPIOrgUnitsComponent
        dataVersatilityRows={dataVersatilityRows}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        handleRowPress={handleRowPress}
        handleSelectShift={handleSelectShift}
        fetchVersatilityRowDetails={fetchVersatilityRowDetails}
        setRadioIndex={setRadioIndex}
        radioIndex={radioIndex}
        dataMagicSquare={dataMagicSquare}
        handleWorkstationUpdate={handleWorkstationUpdate}
      />
      {loader && <Loader />}
    </>
  );
};
