export enum UserPreferenceKeys_SkillMgtApp {
  DashboardFilter = 'DashboardFilter',
  WorkstationFilter = 'WorkstationFilter',
  SkillCatalogFilter = 'SkillCatalogFilter',
  WorkerCatalogFilter = 'WorkerCatalogFilter',
  WorkerWorkstationCatalogFilter = 'WorkerWorkstationCatalogFilter',
  WorkerSkillCatalogFilter = 'WorkerSkillCatalogFilter',
  SkillWorkstationCatalogFilter = 'SkillWorkstationCatalogFilter',
  SkillWorkerCatalogFilter = 'SkillWorkerCatalogFilter',
  TrainingSessionFilter = 'TrainingSessionFilter',
  KPIFilter = 'KPIFilter',
  TrainingFilter = 'TrainingFilter',
  TrainingWorkerFilter = 'TrainingWorkerFilter',
  TreePanelNodesState = 'TreePanelNodesState',
  ProofBundleLastUpdatedDate = 'ProofBundleLastUpdatedDate',
  MobileHomePageFilter = 'MobileHomePageFilter',
  MentorMinLevel = 'MentorMinLevel',
  DashboardWorkstationLevelScrollViewOffset = 'DashboardWorkstationLevelScrollViewOffset',
  ShowWorkstationTargetFooter = 'ShowWorkstationTargetFooter',
  DashboardSort = 'DashboardSort',
}
