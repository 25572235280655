import React, { useState, useEffect, useContext } from 'react';
import WebModal from 'modal-react-native-web';
import { View, Text, ScrollView, InteractionManager } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { InputText } from 'shared/ui-component/Input';
import * as API from 'shared/backend-data';
import {
  DropDownSingleSelection,
  DropDownOption,
  DropDownMultiSelection,
} from 'shared/ui-component/DropDown/DropDown';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { TrainingModalProps } from '../container/TrainingModal';
import Styles from './style';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { t, capitalizeFirstLetter } from 'shared/localisation/i18n';
import { handleWarningModal } from '../../../my-factory/workstations/component/RequirementTable';
import { ModalUtils } from 'shared/ui-component/Modal';
import {
  ModalBackgroundStyle,
  ModalCardStyle,
  ModalAnchorContainerStyle,
  ModalAnchorStyle,
  ModalBodyWithAnchorStyle,
} from 'shared/styles/ModalStyles';
import { AttachFile } from 'shared/ui-component/AttachFile/index';
import { MyHub } from 'shared/util/MyHub';
import { SkillCard } from '../../../skill-card/index';
import { Tag } from 'shared/ui-component/Input/InputTag';
import { TextButton, YellowButton } from 'shared/ui-component/Button/index';
import { ModalHeader } from 'shared/ui-component/Modal/Header';
import {
  getTrainingDurationLabel,
  getTrainingDurationOptions,
} from 'shared/ui-component/DropDown/Data/DropDownData';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { deleteTrainingHandler } from '../../../my-factory/trainings/component/deleteTrainingHandler';
import { RouteLocations } from '../../../navigation/Routes';
import { useHistory } from 'react-router-dom';
import { DropDownWithWorkerLevel } from 'shared/ui-component/DropDown';
import {
  DropDownWithWorkerLevelOption,
  dropDownWorkerLevels,
} from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';
import { Spacings } from 'shared/styles';
import { tagErrorModal } from 'shared/util/warning';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';
import {
  filterMentorsForDropdown,
  deleteTrainingTagWithErrorModalHandling,
} from 'shared/util/TrainingUi';

const ICON_CLOCK = require('shared/assets/svg/icon.clock.mobile.svg').default;
const ICON_INFO = require('shared/assets/svg/icon.info.svg').default;
const ICON_TAG = require('shared/assets/svg/icon.label.svg').default;
const ICON_SKILL = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const ICON_practicalTraining = require('shared/assets/svg/icon.practTrain.svg').default;
const ICON_notPracticalTraining = require('shared/assets/svg/icon.lecture.svg').default;

enum InputIds {
  Training = 'Training',
  Duration = 'Duration',
  Comments = 'Comments',
  Descriptions = 'Descriptions',
  AddSkill = 'AddSkill',
  DefaultTrainerId = 'DefaultTrainerId',
  MaxNumberOfTrainee = 'MaxNumberOfTrainee',
}

export const TrainingModalComponent: React.FC<TrainingModalProps> = props => {
  const {
    config,
    isPassive,
    preSelectedSkill,
    trainingNamePlaceholder,

    onPassiveSubmit,
    handleModalClose,
    handleRefresh,
  } = props;

  const isMounted = useIsMounted();

  const { isValidPermission } = useContext(PermissionManagementContext);

  const modal = ModalUtils.useModal();

  const history = useHistory();

  const defaultTrainingVersionDuration = {
    key: String(API.defaultTrainingVersionDurationInMin),
    label: getTrainingDurationLabel(API.defaultTrainingVersionDurationInMin),
    value: API.defaultTrainingVersionDurationInMin,
  };

  const maxTraineeOptions: DropDownOption<number>[] = [...Array(51).keys()].map(i => {
    return { key: '' + i, label: i === 0 ? t('alex:TrainingModal.noLimit') : '' + i, value: i };
  });

  const [training, setTraining] = useState<API.Training>();
  const [trainingName, setTrainingName] = useState<string>();
  const [trainingDescription, setTrainingDescription] = useState<string>();
  const [defaultTrainer, setDefaultTrainer] = useState<DropDownOption>();
  const [defaultTrainerOptions, setDefaultTrainerOptions] = useState<DropDownOption[]>([]);
  const [maxTrainee, setMaxTrainee] = useState<DropDownOption<number>>();
  const [trainingNotes, setTrainingNotes] = useState<string>();
  const [skills, setSkills] = useState<API.Skill[]>();
  const [skillDeleted, setSkillDeleted] = useState<boolean>(false);
  const [skillAdded, setSkillAdded] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isDataValid, setIsDataValid] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const [totalSkills, setTotalSkills] = useState<DropDownOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTrainingVersionPractical, setIsTrainingVersionPractical] = useState<boolean>();
  const [trainingFiles, setTrainingFiles] = useState<API.S3ObjectInput[]>([]);
  const [trainingTagOptions, setTrainingTagOptions] = useState<Tag[]>([]);
  const [trainingTags, setTrainingTags] = useState<Tag[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<DropDownWithWorkerLevelOption>();
  const [latestTrainingVersionDurationInMin, setLatestTrainingVersionDurationInMin] =
    useState<DropDownOption>(defaultTrainingVersionDuration);
  const [practicalTrainingLinkedTreeNodeDropDown, setPracticalTrainingLinkedTreeNodeDropDown] =
    useState<DropDownOption<API.TreeObject>>();

  const ref = useCallOnHover<ScrollView>(
    SharedStyles.Colors.White,
    () => setIsHover(true),
    () => setIsHover(false),
  );

  const { errors, onBlur, onChange, navigateInputField, refs } = useCustomForm<string>(
    {
      [InputIds.Training]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
      [InputIds.Duration]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
      [InputIds.Comments]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
      [InputIds.Descriptions]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
      [InputIds.AddSkill]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
      [InputIds.DefaultTrainerId]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
      [InputIds.MaxNumberOfTrainee]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
    },
    onSubmitForm,
  );

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.TRAINING &&
        (data.tooManyMutations || data.factory.training.id === training?.id)
      ) {
        fillTrainingDetails();
      }
    });

    return () => {
      removeListener();
    };
  }, [training]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.TRAININGTAG) {
        fetchTrainingTags();
      }
    });

    fetchTrainingTags();

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const workers = await API.getWorkers();
      if (API.isFailure(workers)) {
        logger.warn(workers);
        return workers;
      }

      const _defaultTrainerOptions: DropDownOption[] = [];
      workers.result.forEach(worker => {
        _defaultTrainerOptions.push({ key: worker.id, label: worker.name, value: worker });
      });

      setDefaultTrainerOptions(_defaultTrainerOptions);
    });
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      await fetchTreeNodeForPracticalTrainingVersion();
    });
  }, [config.trainingVersion]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !config.trainingVersion) return;

      const _isPractical = await API.isPracticalTrainingVersion(config.trainingVersion.id);
      if (!isMounted.current) return;
      if (API.isFailure(_isPractical)) {
        logger.warn('Failed to fetch type of training version');
        return;
      }
      if (_isPractical) await fetchMentorOptionsForPracticalTrainingVersion();
    });
  }, [practicalTrainingLinkedTreeNodeDropDown]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (practicalTrainingLinkedTreeNodeDropDown && training) {
        const levelRequirements = await API.getLevelsRequirement(
          practicalTrainingLinkedTreeNodeDropDown.key,
        );
        if (!isMounted.current) return;
        if (API.isFailure(levelRequirements)) {
          logger.warn(levelRequirements);
          return levelRequirements;
        }

        levelRequirements.forEach(levelRequirement => {
          if (levelRequirement.practicalTrainingId === training.id) {
            dropDownWorkerLevels.forEach(dropDownWorkerLevel => {
              if (dropDownWorkerLevel.key === levelRequirement.level)
                setSelectedLevel(dropDownWorkerLevel);
            });
          }
        });
      }
    });
  }, [training, practicalTrainingLinkedTreeNodeDropDown]);

  useEffect(() => {
    fillTrainingDetails();
  }, [config, config.editMode, config.trainingVersion, defaultTrainerOptions]);

  useEffect(() => {
    checkDataValidity();
  }, [trainingName, skills, isPassive, isTrainingVersionPractical, trainingNamePlaceholder]);

  useEffect(() => {
    setTrainingName(trainingNamePlaceholder);
  }, [trainingNamePlaceholder]);

  async function fetchTreeNodeForPracticalTrainingVersion() {
    if (!config.trainingVersion) return;
    const workstationOrOUArray = await API.getWorkstationsOrOrganizationalUnits(
      config.trainingVersion.id,
    );
    if (!isMounted.current) return;
    if (API.isFailure(workstationOrOUArray)) return workstationOrOUArray;
    if (!workstationOrOUArray.length) return;
    setPracticalTrainingLinkedTreeNodeDropDown({
      key: workstationOrOUArray[0].id,
      label: workstationOrOUArray[0].name,
      value: workstationOrOUArray[0],
    });
  }

  async function fetchMentorOptionsForPracticalTrainingVersion() {
    if (!practicalTrainingLinkedTreeNodeDropDown) return;

    const _mentorOptions = await filterMentorsForDropdown(
      defaultTrainerOptions.map(defaultTrainerOption => {
        const _workerWorkstation = API.getWorkerWorkstations(
          practicalTrainingLinkedTreeNodeDropDown.key,
          defaultTrainerOption.key,
        );

        return {
          ...defaultTrainerOption,
          level: _workerWorkstation?.level
            ? API.api2workstationWorkerLevels(_workerWorkstation.level)
            : API.WorkstationWorkerLevels.LEVEL0,
        };
      }),
      practicalTrainingLinkedTreeNodeDropDown.key,
    );
    if (!isMounted.current) return;
    if (API.isFailure(_mentorOptions)) {
      logger.warn(_mentorOptions);
      return;
    }

    setDefaultTrainerOptions(_mentorOptions);
  }

  async function fetchTrainingTags() {
    const _trainingTags = await API.getTrainingTags();
    if (!isMounted.current) return;
    if (API.isFailure(_trainingTags)) {
      logger.warn(_trainingTags);
      return;
    }
    setTrainingTagOptions(
      _trainingTags.map(trainingTag => {
        return { key: trainingTag.id, label: trainingTag.name, editable: true };
      }),
    );
  }

  function onFilesChange(s3Files: API.S3ObjectInput[]) {
    setTrainingFiles(s3Files);
  }

  async function handleEditTraining() {
    if (!training || !training.id || !config.trainingVersion || !config.trainingVersion.id) return;

    setIsLoading(true);

    const updatedTraining = await API.updateTraining({
      id: training.id,
      name: trainingName,
      description: trainingDescription,
      notes: trainingNotes,
      files: trainingFiles,
      tagIds: _.map(trainingTags, trainingTag => trainingTag.key),
      defaultTrainerId: defaultTrainer?.key,
      maxTrainee: maxTrainee?.value,
    });
    if (!isMounted.current) return;
    if (API.isFailure(updatedTraining)) {
      setIsLoading(false);
      logger.warn('Failed to update Training', updatedTraining);
      return;
    }

    const updatedTrainingVersion = await API.updateTrainingVersioMaybeDelete({
      ...config.trainingVersion,
      skillIds: _.map(skills, skill => skill.id),
      durationInMin: latestTrainingVersionDurationInMin?.value,
    });
    if (!isMounted.current) return;
    if (API.isFailure(updatedTrainingVersion)) {
      setIsLoading(false);
      logger.warn('Failed to update Training Version', updatedTrainingVersion);
      return;
    }

    if (!isMounted.current) return;

    setIsLoading(false);
    handleModalClose(false);
    handleRefresh();
  }

  async function createTraining(): Promise<API.Result<API.TrainingVersionAndTraining | undefined>> {
    const createdTrainingVersionAndTraining = await API.createTraining(
      {
        name: trainingName!,
        description: trainingDescription ?? '',
        notes: trainingNotes ?? '',
        files: trainingFiles,
        tagIds: _.map(trainingTags, trainingTag => trainingTag.key),
        defaultTrainerId: defaultTrainer?.key,
        maxTrainee: maxTrainee?.value,
      },
      {
        skillIds: _.map(skills, skill => skill.id),
        durationInMin: latestTrainingVersionDurationInMin?.value,
      },
    );
    if (API.isFailure(createdTrainingVersionAndTraining)) {
      logger.warn('Failed to create training ', createdTrainingVersionAndTraining);
      setIsLoading(false);
      if (API.isFailureType(createdTrainingVersionAndTraining, 'DuplicateVeto'))
        ModalUtils.showVetoModal(
          t('alex:trainingModal.duplicateTraininglName'),
          createdTrainingVersionAndTraining,
        );
      else return createdTrainingVersionAndTraining;
    }

    return createdTrainingVersionAndTraining;
  }

  async function onSubmitForm() {
    if (!isDataValid) return;

    if (config.editMode) {
      if (!config.trainingVersion?.id) return;

      const skillsChanged = Boolean(skillAdded || skillDeleted);

      handleWarningModal(
        modal,
        isTrainingVersionPractical && skillsChanged
          ? t('alex:trainingModal.editPracticalTrainingWarning.0', {
              treeNodeInfo:
                practicalTrainingLinkedTreeNodeDropDown?.value &&
                API.isWorkstation(practicalTrainingLinkedTreeNodeDropDown.value)
                  ? t(
                      'alex:trainingModal.editPracticalTrainingWarning.1',
                      {
                        treeNodeName: practicalTrainingLinkedTreeNodeDropDown?.label,
                      },
                      false,
                    )
                  : t(
                      'alex:trainingModal.editPracticalTrainingWarning.2',
                      {
                        treeNodeName: practicalTrainingLinkedTreeNodeDropDown?.label,
                      },
                      false,
                    ),
            })
          : t('alex:trainingModal.editTraining'),
        isTrainingVersionPractical && skillsChanged
          ? t('alex:trainingModal.editPracticalTrainingWarning.3')
          : '',
        t('common:button.yes'),
        t('common:button.no'),
        () => handleEditTraining(),
      );
    } else if (isPassive && onPassiveSubmit) {
      onPassiveSubmit([
        {
          skillIds: _.map(skills, skill => skill.id),
          durationInMin: latestTrainingVersionDurationInMin.value,
        },
        {
          name: trainingName ?? '',
          description: trainingDescription,
          notes: trainingNotes,
          files: trainingFiles,
          tagIds: _.map(trainingTags, trainingTag => trainingTag.key),
          defaultTrainerId: defaultTrainer?.key,
          maxTrainee: maxTrainee?.value,
        },
      ]);
      handleModalClose(false);
    } else {
      setIsLoading(true);

      const _createdTrainingVersionAndTraining = await createTraining();
      if (!isMounted.current) return;

      if (!isMounted.current) return;

      setIsLoading(false);

      const _createdTrainingVerison = API.isFailure(_createdTrainingVersionAndTraining)
        ? undefined
        : _createdTrainingVersionAndTraining?.[0];
      handleModalClose(false, _createdTrainingVerison);
      handleRefresh();
    }
  }

  async function onDeleteTraining() {
    if (config.trainingVersion?.trainingId)
      deleteTrainingHandler(config.trainingVersion.trainingId, modal, () =>
        history.push(RouteLocations.Trainings()),
      );
  }

  async function fillTrainingDetails() {
    let isPractical: Boolean | undefined = undefined;

    if (config?.trainingVersion?.id) {
      const _isPractical = await API.isPracticalTrainingVersion(config.trainingVersion.id);
      if (!isMounted.current) return;
      if (API.isFailure(_isPractical)) {
        logger.warn('Failed to fetch type of training version');
        return;
      }
      isPractical = _isPractical;
      setIsTrainingVersionPractical(_isPractical);
    }

    const _skills = await API.getSkills();
    if (!isMounted.current) return;
    if (API.isFailure(_skills)) {
      logger.warn('Failed to fetch skills', _skills);
      return;
    }
    const skills = _.map(_skills.result, skill => {
      return {
        key: skill.id,
        label: skill.name,
        value: skill,
        disabled: config?.trainingVersion?.id
          ? isPractical
            ? !skill.isPractical
            : skill.isPractical
          : skill.isPractical,
      };
    });
    setTotalSkills(_.sortBy(skills, eachSkill => eachSkill.label));

    if (!config.editMode) {
      setIsTrainingVersionPractical(false);

      const preSelectedSkills: API.Skill[] = [];
      await Promise.all(
        _.map(config.skillIds, async skillId => {
          const skill = await API.getSkill(skillId);
          if (!isMounted.current) return;
          if (API.isFailure(skill)) {
            logger.warn('Failed to fetch skill', skill);
            return;
          }
          preSelectedSkills.push(skill);
        }),
      );
      if (!isMounted.current) return;
      setSkills(preSelectedSkills);
    } else {
      if (!config.trainingVersion) return;

      if (config.trainingVersion.durationInMin)
        setLatestTrainingVersionDurationInMin({
          key: config.trainingVersion.durationInMin.toString(),
          label: getTrainingDurationLabel(config.trainingVersion.durationInMin),
          value: config.trainingVersion.durationInMin,
        });

      const training = await API.getTraining(config.trainingVersion.trainingId);
      if (!isMounted.current) return;
      if (API.isFailure(training)) {
        logger.warn('Failed to fetch training ', training);
        return;
      }
      setTraining(training);

      if (training.defaultTrainerId)
        setDefaultTrainer(
          defaultTrainerOptions.find(
            defaultTrainerOption => defaultTrainerOption.key === training.defaultTrainerId,
          ),
        );

      if (training.maxTrainee !== undefined) {
        setMaxTrainee(maxTraineeOptions.find(option => option.value === training.maxTrainee));
      }

      setTrainingName(training.name);

      setTrainingDescription(training.description ?? '');

      setTrainingNotes(training.notes ?? '');

      const _trainingTags = _.compact(
        await Promise.all(
          _.map(training.tagIds, async tagId => {
            const _tag = await API.getTrainingTag(tagId);
            if (!isMounted.current) return;
            if (API.isFailure(_tag)) {
              logger.warn('Failed to fetch Training Tag ', _tag);
              return;
            }
            return {
              key: _tag.id,
              label: _tag.name,
            };
          }),
        ),
      );
      if (!isMounted.current) return;
      setTrainingTags(_trainingTags);

      const trainingVersionSkills: API.Skill[] = [];
      for (const skillId of config.trainingVersion.skillIds) {
        const skill = await API.getSkill(skillId);
        if (!isMounted.current) return;
        if (API.isFailure(skill)) {
          logger.warn('Failed to fetch skill', skill);
          return;
        }
        trainingVersionSkills.push(skill);
      }
      setSkills(trainingVersionSkills);
    }
  }

  function checkDataValidity() {
    if (
      trainingName &&
      ((skills &&
        _.every(skills, skill => {
          return skill.isPractical === isTrainingVersionPractical;
        })) ||
        isPassive)
    ) {
      setIsDataValid(true);
    } else {
      setIsDataValid(false);
    }
  }

  function handleTags(inputId: string, tags: Tag[] | undefined) {
    if (!tags) return;

    let _tags: Tag[] = [];
    _tags = tags.map(item => {
      return {
        key: item.key,
        label: item.label,
      };
    });
    setTrainingTags(_tags);
  }

  async function deleteTrainingTag(tag: Tag): Promise<void> {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:trainingsTab.warnings.deleteTrainingTag'),
        warningAcceptButton: capitalizeFirstLetter(t('common:button.yes')),
        warningCancelButton: capitalizeFirstLetter(t('common:button.no')),
        warningAcceptCallback: () => _deleteTrainingTag(tag),
      }),
    );
  }

  async function _deleteTrainingTag(tag: Tag): Promise<API.Result<void>> {
    const deletedTrainingTag = await deleteTrainingTagWithErrorModalHandling(tag.key);
    if (!isMounted.current) return;
    if (API.isFailure(deletedTrainingTag)) return deletedTrainingTag;
  }

  async function createTrainingTag(tagName: string): Promise<API.Result<Tag<API.TrainingTag>>> {
    const createTrainingTag = await API.createTrainingTag({ name: tagName });
    if (API.isFailure(createTrainingTag)) {
      tagErrorModal(modal);
      return createTrainingTag;
    }

    return { ...createTrainingTag, label: createTrainingTag.name, key: createTrainingTag.id };
  }

  async function handlerCreateTrainingTag(tag: Tag): Promise<API.Result<Tag<API.TrainingTag>>> {
    const createTrainingTag = await API.createTrainingTag({ name: tag.label });
    if (API.isFailure(createTrainingTag)) return createTrainingTag;

    return { ...createTrainingTag, label: createTrainingTag.name, key: createTrainingTag.id };
  }

  async function updateTrainingTag(tag: Tag): Promise<API.Result<void>> {
    const updatedTrainingTag = await API.updateTrainingTag({
      id: tag.key,
      name: tag.label,
    });
    if (!isMounted.current) return;
    if (API.isFailure(updatedTrainingTag)) {
      tagErrorModal(modal);
      return updatedTrainingTag;
    }
  }

  function handleDeleteSkill(skillId: string) {
    if (!skills) return;

    const _skills = [...skills];
    _skills.splice(
      _.findIndex(skills, skill => skill.id === skillId),
      1,
    );
    setSkills(_skills);
  }

  function isInputListOpen(isOpen: boolean) {
    if (isOpen) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }
  }

  return (
    <WebModal
      animationType="fade"
      transparent
      onRequestClose={() => {
        handleModalClose(false);
      }}
    >
      {isLoading && <Loader />}
      <View style={[ModalBackgroundStyle]}>
        <View style={[ModalCardStyle]}>
          <ModalHeader
            handleModalClose={() => {
              handleModalClose(false);
            }}
            title={
              config.editMode && isTrainingVersionPractical
                ? t('glossary:trainingPractical')
                : t('glossary:trainingNotPractical')
            }
          />
          <ScrollView style={Styles.modalBodyContainer}>
            <View
              style={[
                Styles.workstationModalPanelContainer,
                ModalBodyWithAnchorStyle,
                Styles.workstationModalPanelContainer2,
              ]}
            >
              <View style={[Styles.workstationModalLeftPanelContainer]}>
                <View style={[ModalAnchorContainerStyle]}>
                  <View style={ModalAnchorStyle}>
                    <IconSVG
                      svgComponent={
                        isTrainingVersionPractical
                          ? ICON_practicalTraining
                          : ICON_notPracticalTraining
                      }
                      color={SharedStyles.Colors.White}
                    />
                  </View>
                </View>
                {/** LEFT PANEL */}
                <View style={[Styles.leftPanelInnerContainer]}>
                  {/** Name */}
                  {isTrainingVersionPractical ? (
                    <View style={[Styles.workstationModalLeftPanelDropDownContainer]}>
                      <View style={Styles.workstationContainor}>
                        <TextButton
                          text={t('alex:scheduleTrainingModal.trainingFromWorkstation')}
                          textStyle={{ color: SharedStyles.Colors.Grey }}
                          onPress={() => {
                            if (practicalTrainingLinkedTreeNodeDropDown)
                              history.push(
                                RouteLocations.Workstations(
                                  practicalTrainingLinkedTreeNodeDropDown.key,
                                  2,
                                  2,
                                ),
                              );
                            else history.push(RouteLocations.Workstations());
                          }}
                          containerStyle={Styles.textButtonContainor}
                        />
                        <DropDownSingleSelection
                          value={practicalTrainingLinkedTreeNodeDropDown}
                          containerStyle={Styles.fullWidth}
                          textStyle={Styles.dropDownTextStyle}
                          notEditable
                        />
                      </View>

                      {/** level */}
                      <DropDownWithWorkerLevel
                        options={dropDownWorkerLevels}
                        title={t('glossary:level')}
                        placeholder={t('glossary:level')}
                        searchPlaceholder={t('glossary:level')}
                        containerStyle={Styles.levelsContainer}
                        value={selectedLevel}
                        notEditable
                        hasLevelOnly
                        handleChange={(_, item) => {
                          if (item) {
                            setSelectedLevel(item);
                          }
                        }}
                      />
                    </View>
                  ) : (
                    <InputText
                      inputRef={refs[InputIds.Training]}
                      inputId={InputIds.Training}
                      onBlur={onBlur}
                      errorMessage={errors[InputIds.Training]?.inputErrorMessage}
                      style={[Styles.workstationModalLeftPanelInputTextContainer]}
                      placeHolder={
                        isTrainingVersionPractical
                          ? t('alex:trainingModal.nameOfTheTrainingPractical')
                          : t('alex:trainingModal.nameOfTheTrainingNonPractical')
                      }
                      defaultValue={trainingName}
                      onTextChange={(inputId: string, inputValue: string) => {
                        onChange(inputId, inputValue);
                        setTrainingName(inputValue);
                      }}
                      onSubmitEditing={(inputId: string, inputValue: string) => {
                        setTrainingName(inputValue);
                        navigateInputField(InputIds.Training);
                      }}
                      notEditable={!isValidPermission(API.Permission.trainings_edit)}
                    />
                  )}
                  {/** Duration */}
                  <View style={Styles.durationContainer}>
                    <DropDownSingleSelection
                      inputId={InputIds.Duration}
                      title={t('common:time.duration')}
                      placeholder={t('common:time.duration')}
                      options={getTrainingDurationOptions()}
                      icon={ICON_CLOCK}
                      containerStyle={{ width: 136 }}
                      value={latestTrainingVersionDurationInMin}
                      handleChange={(_, durationOption) => {
                        if (durationOption) setLatestTrainingVersionDurationInMin(durationOption);
                      }}
                      notEditable={!isValidPermission(API.Permission.trainings_edit)}
                      errorMessage={errors[InputIds.Duration]?.inputErrorMessage}
                    />
                    <View style={Styles.horizontalDivider} />
                    <ToolTipWeb
                      title={t('alex:trainingModal.trainingDurationToolTip')}
                      style={Styles.toolTipStyle}
                      component={
                        <IconSVG
                          svgComponent={ICON_INFO}
                          size={{ width: 24, height: 24 }}
                          containerStyle={{ width: 24, height: 24 }}
                          color={SharedStyles.Colors.GreyLight}
                        />
                      }
                    />
                  </View>
                  {/** Default trainer */}
                  <DropDownSingleSelection
                    inputId={InputIds.DefaultTrainerId}
                    title={t('alex:TrainingModal.defaultTrainer')}
                    placeholder={t('alex:TrainingModal.defaultTrainer')}
                    options={defaultTrainerOptions}
                    containerStyle={Styles.inputDefaultTrainer}
                    value={defaultTrainer}
                    handleChange={(_, value) => {
                      if (value) setDefaultTrainer(value);
                    }}
                    notEditable={!isValidPermission(API.Permission.trainings_edit)}
                    errorMessage={
                      defaultTrainerOptions?.length ? '' : t('alex:scheduleTrainingModal.noMentor')
                    }
                    hasSearch
                  />

                  {/** Maximum Trainee */}
                  {!isTrainingVersionPractical && (
                    <DropDownSingleSelection
                      inputId={InputIds.MaxNumberOfTrainee}
                      title={t('alex:TrainingModal.maxTrainee')}
                      placeholder={t('alex:TrainingModal.maxTrainee')}
                      options={maxTraineeOptions}
                      containerStyle={Styles.inputMaxTrainee}
                      value={maxTrainee}
                      handleChange={(_, option) => {
                        setMaxTrainee(option);
                      }}
                      notEditable={!isValidPermission(API.Permission.trainings_edit)}
                    />
                  )}

                  {/** Tag */}
                  <DropDownMultiSelection
                    placeholder={t('glossary:trainingTag_plural')}
                    title={t('glossary:trainingTag_plural')}
                    tagTitle={t('glossary:trainingTag_plural')}
                    containerStyle={[Styles.inputTag]}
                    options={trainingTagOptions}
                    values={trainingTags}
                    listIcon={ICON_TAG}
                    isTag
                    createNewOption={{
                      createItemIcon: ICON_TAG,
                      createItemPlaceHolder: t('alex:trainingModal.createTrainingTag'),
                      createItemHandler: handlerCreateTrainingTag,
                    }}
                    onTagDelete={deleteTrainingTag}
                    onTagEdit={updateTrainingTag}
                    onTagCreate={createTrainingTag}
                    handleChange={handleTags}
                    notEditable={!isValidPermission(API.Permission.trainings_edit)}
                  />
                  <View style={[Styles.smallDivider]} />
                  {/** notes */}
                  <InputTextDescription
                    inputRef={refs[InputIds.Comments]}
                    inputId={InputIds.Comments}
                    onBlur={onBlur}
                    errorMessage={errors[InputIds.Comments]?.inputErrorMessage}
                    style={[Styles.workstationModalLeftPanelInputTextContainer]}
                    placeHolder={t('common:account.notes')}
                    defaultValue={trainingNotes}
                    onTextChange={(inputId: string, inputValue: string) => {
                      onChange(inputId, inputValue);
                      setTrainingNotes(inputValue);
                    }}
                    onSubmitEditing={(inputId: string, inputValue: string) => {
                      navigateInputField(InputIds.Comments);
                      setTrainingNotes(inputValue);
                    }}
                    notEditable={!isValidPermission(API.Permission.trainings_edit)}
                  />

                  {/** INFO */}
                  {isTrainingVersionPractical && (
                    <View style={Styles.infoContainer}>
                      <IconSVG
                        svgComponent={ICON_INFO}
                        size={{ width: Spacings.Standard, height: Spacings.Standard }}
                        containerStyle={{ width: Spacings.Standard, height: Spacings.Standard }}
                        color={SharedStyles.Colors.GreyLight}
                      />
                      <Text style={Styles.practiceInfo}>
                        {skills && skills?.length > 1
                          ? t('alex:TrainingModal.practicalTrainingInfo.1')
                          : t('alex:TrainingModal.practicalTrainingInfo.0')}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              {/** RIGHT PANEL */}
              <View style={[Styles.trainingModalRightPanelContainer]}>
                <Text style={Styles.contentOfPractice}>
                  {isTrainingVersionPractical
                    ? t('alex:scheduleTrainingModal.contentOfPractice')
                    : t('alex:scheduleTrainingModal.contentOfTraining')}
                </Text>
                {/** description */}
                <InputTextDescription
                  inputRef={refs[InputIds.Descriptions]}
                  inputId={InputIds.Descriptions}
                  onBlur={onBlur}
                  errorMessage={errors[InputIds.Descriptions]?.inputErrorMessage}
                  style={[Styles.workstationModalLeftPanelInputTextContainer]}
                  placeHolder={t('alex:skills.description')}
                  defaultValue={trainingDescription}
                  onTextChange={(inputId: string, inputValue: string) => {
                    onChange(inputId, inputValue);
                    setTrainingDescription(inputValue);
                  }}
                  onSubmitEditing={(inputId: string, inputValue: string) => {
                    navigateInputField(InputIds.Descriptions);
                    setTrainingDescription(inputValue);
                  }}
                  notEditable={!isValidPermission(API.Permission.trainings_edit)}
                />
                <View style={[Styles.divider]} />
                {/** Files */}

                <AttachFile
                  style={Styles.fullWidth}
                  initialFiles={training?.files}
                  onFilesChange={onFilesChange}
                  addFile={isValidPermission(API.Permission.trainings_edit)}
                />
                <View style={[Styles.divider]} />
                <View style={[Styles.trainingModalRightPanel]}>
                  <Text style={[Styles.trainingModalRightPanelTextStyle]}>
                    {isTrainingVersionPractical
                      ? t('alex:trainingModal.trainingAcquisitionPractical')
                      : t('alex:trainingModal.trainingAcquisitionNonPractical')}
                  </Text>
                  <DropDownMultiSelection
                    inputId={InputIds.AddSkill}
                    additionForm
                    showAdditionFormList={false}
                    containerStyle={Styles.addSkillContainer}
                    additionFormContainerStyle={Styles.addSkillAdditionContainer}
                    placeholder={t('alex:trainingModal.addSkill')}
                    searchPlaceholder={t('alex:trainingModal.addSkill')}
                    values={_.map(skills, skill => {
                      return {
                        value: skill,
                        key: skill.id,
                        label: skill.name,
                      };
                    })}
                    options={totalSkills}
                    listIcon={ICON_SKILL}
                    handleChange={(inputId, values) => {
                      setSkills(
                        _.map(values, value => {
                          return { ...value.value };
                        }),
                      );
                      setSkillAdded(true);
                    }}
                    notEditable={!isValidPermission(API.Permission.trainings_edit)}
                    errorMessage={errors[InputIds.AddSkill]?.inputErrorMessage}
                    isInputListOpen={isInputListOpen}
                  />
                  <View style={[Styles.skillsListingOuterContainer]}>
                    <ScrollView
                      ref={ref}
                      showsVerticalScrollIndicator={isHover}
                      style={[Styles.skillsListingContainer]}
                    >
                      {isPassive && (
                        <View key={1} style={[Styles.skillListingRowContainer]}>
                          <View style={[Styles.skillNameContainer]}>
                            <IconSVG
                              svgComponent={ICON_SKILL}
                              containerStyle={Styles.skillIconContainer}
                            />
                            <Text style={[Styles.skillNameTextStyle]} numberOfLines={1}>
                              {preSelectedSkill?.name}
                            </Text>
                          </View>
                        </View>
                      )}
                      {skills &&
                        _.map(skills, (skill, index) => {
                          return (
                            <SkillCard
                              key={index}
                              skillOrSkillId={skill}
                              containerStyle={Styles.skillCardContainerStyle}
                              deletable={
                                isValidPermission(API.Permission.trainings_edit) &&
                                API.isSkillUnlinkable(skill.isPractical)
                              }
                              onDelete={() => {
                                handleDeleteSkill(skill.id);
                                setSkillDeleted(true);
                              }}
                            />
                          );
                        })}
                    </ScrollView>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
          {isValidPermission(API.Permission.trainings_edit) && (
            <View style={Styles.buttonsContainer}>
              <TextButton
                text={t('common:button.delete')}
                containerStyle={Styles.trainingModalDeleteButtonContainer}
                onPress={onDeleteTraining}
              />
              <YellowButton
                style={Styles.trainingModalYellowButtonContainer}
                disabled={disableSaveButton || !isDataValid}
                text={t('common:button.save')}
                textStyle={Styles.workstationModalYellowButtonTextStyle}
                onPress={onSubmitForm}
              />
            </View>
          )}
        </View>
      </View>
    </WebModal>
  );
};
