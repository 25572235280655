import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
  },
  rootContainer: {
    flex: 1,
    backgroundColor: Colors.White,
    paddingTop: Spacings.Medium * 2,
    paddingLeft: Spacings.Large,
  },
  IconContainer: {
    marginBottom: Spacings.Standard * 4 + Spacings.Unit, 
  },
  topContainer: {
    alignItems: 'flex-start',
    marginBottom: Spacings.Large + Spacings.Medium + Spacings.Unit, 
  },
  middleContainer: {
    marginBottom: Spacings.Standard * 3 + Spacings.Small - 2,
  },
  connectButton: {
    width: 144,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.Yellow,
    justifyContent: 'center',
  },
  connectButtonDisabled: {
    width: 144,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.GreyLight,
    justifyContent: 'center',
  },
  connectText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Medium,
    color: Colors.White,
  },
  backButton: {
    position: 'absolute',
    zIndex: 1,
  },
  headerText: {
    ...TextFontStyles.LatoBlack,
    fontSize: 32,
  },
  headerTextContainer: {
    padding: 2,
    alignItems: 'center',
    width: '100%',
  },
  errorMessage: {
    ...TextFontStyles.LatoBold,
    color: Colors.Red,
    marginTop: 15,
    fontSize: fontSize.Regular,
  },
  buttonContainer: {},
});

export default styles;
