import React from 'react';
import {
  TouchableOpacity,
  Text,
  Platform,
  StyleSheet,
  ScrollView,
  ViewStyle,
  View,
} from 'react-native';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { DropDownOption } from './index';
import { Spacings, Colors, TextFontStyles, fontSize } from 'shared/styles/index';
import { capitalizeFirstLetter } from '../../../localisation/i18n';

type DropDownMenuProps<T extends DropDownOption> = {
  inputId?: string | undefined;
  options: T[];
  dropDownMenuContainerStyle?: ViewStyle | undefined;
  hasLevelOnly: boolean;
  handleMenuDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (inputId: string, value: T | undefined) => void;
  renderOption?: ((value: T | undefined) => JSX.Element) | undefined;
};

export const DropDownMenu = <T extends DropDownOption>(
  props: React.PropsWithChildren<DropDownMenuProps<T>>,
) => {
  const { options, handleMenuDisplay, handleChange, renderOption, hasLevelOnly } = props;
  let ref: React.RefObject<View> | undefined = undefined;
  if (Platform.OS === 'web') {
    ref = useDetectOutsideClick<View>(() => handleMenuDisplay(false));
  }
  return (
    <View ref={ref} style={[styles.dropDownFlatListOptions, props.dropDownMenuContainerStyle]}>
      <ScrollView>
        {options.map(option => {
          return (
            <TouchableOpacity
              key={option.key}
              ref={useCallOnHover<TouchableOpacity>()}
              onPress={() => {
                handleChange(props.inputId ?? '', option);
                handleMenuDisplay(false);
              }}
              style={[
                styles.dropDownTouchable,
                { paddingLeft: hasLevelOnly ? Spacings.Medium : 28 },
              ]}
            >
              {!hasLevelOnly && (
                <Text style={styles.dropDownTextStyle}>{capitalizeFirstLetter(option.label)}</Text>
              )}
              {renderOption && renderOption(option)}
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  dropDownFlatListOptions: {
    backgroundColor: Colors.White,
    position: 'absolute',
    top: 64,
    width: '100%',
    maxHeight: 190,
    alignSelf: 'center',
    zIndex: 999,
    borderRadius: 8,
    shadowColor: Colors.ModalBackground,
    shadowOpacity: 1,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 3 },
    paddingVertical: Spacings.Unit,
  },
  dropDownTouchable: {
    flex: 1,
    height: 40,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: Spacings.Unit * 7,
    flexDirection: 'row',
    paddingVertical: Spacings.Small,
  },
  dropDownTextStyle: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Black,
    fontSize: fontSize.Regular,
    width: '100%',
  },
});
