import { StyleSheet } from 'react-native';
import { CELL_WIDTH } from 'skillmgtweb/src/components/dashboard/versatility-panel/styles';
import { Spacings, Colors } from '../../../styles';
import { TextFontStyles, fontSize } from '../../../styles';

const styles = StyleSheet.create({
  container: {
    height: CELL_WIDTH,
    width: CELL_WIDTH,
    justifyContent: 'center',
    position: 'absolute',
    alignItems: 'center',
  },
  toolTip: {
    width: 150,
    zIndex: 999,
    top: Spacings.Standard,
    left: -Spacings.CardPadding,
  },
  notAssignIcon: {
    left: -Spacings.Unit,
  },
  trainingSessionsListingContainer: {
    width: '100%',
    flex: 1,
  },
  warningText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 18,
    color: Colors.Black,
    marginBottom: Spacings.Medium,
  },
  trainingSessionRow: {
    paddingLeft: Spacings.Unit,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacings.xMedium,
    paddingVertical: Spacings.Unit,
  },
  iconContainer: {
    marginRight: Spacings.Small,
  },
  trainingSessionName: {
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  trainingSessionsList: {
    maxHeight: 200,
  },
});

export default styles;
