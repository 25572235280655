import { StyleSheet, Dimensions } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize, ModalStyles } from 'shared/styles';

const { width, height } = Dimensions.get('window');

export const styles = StyleSheet.create({
  closeIconContainer: { position: 'absolute', top: 8, right: 12 },
  buttons: {
    paddingRight: Spacings.Large + Spacings.xMedium,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: Spacings.Large + Spacings.xMedium,
    marginTop: Spacings.Large + Spacings.xMedium,
    alignItems: 'center',
  },
  textInputContainer: {
    flexDirection: 'row',
    zIndex: 2,
  },
  textInputOuterContainer: {
    paddingLeft: ModalStyles.anchorMarginRight,
    paddingTop: Spacings.Unit,
  },
  title: {
    paddingTop: 74,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Big,
    color: Colors.BlackLight,
  },
  progressBar: {
    marginTop: Spacings.CardPadding,
    width: 300,
    marginHorizontal: Spacings.Standard,
  },
  icon: {
    height: 112,
    width: 112,
  },
  rootContainer: {
    alignItems: 'center',
    paddingTop: 132,
    backgroundColor: Colors.White,
    height: height + Spacings.Big,
    width,
    zIndex: 1,
    position: 'absolute',
  },
  textRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  photoContainer: {
    paddingTop: ModalStyles.anchorPaddingTop,
    paddingLeft: ModalStyles.bodyPaddingLeft,
    flexDirection: 'row',
  },
  leftInputText: {
    width: ModalStyles.inputWidthWithAnchor,
    marginRight: ModalStyles.bodyDivider,
  },
  inputText: {
    width: ModalStyles.inputWidthWithAnchor,
  },
  photo: {
    height: Spacings.Big + Spacings.Unit,
    width: Spacings.Big + Spacings.Unit,
    borderRadius: (Spacings.Big + Spacings.Unit) / 2,
    backgroundColor: Colors.FlashWhite,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
