import React, { useState, useEffect, useCallback } from 'react';
import {
  View,
  ScrollView,
  TextInput,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  ViewStyle,
  Text,
  StyleProp,
} from 'react-native';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { InputTag, Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import * as _ from 'lodash-es';
import Styles from './style';
import { SvgProps } from 'react-native-svg';
import { ListItem, ItemRowStyling } from './ListItem.tsx';
import { replaceDiacriticsAndCapitalLetter } from 'shared/util-ts/Functions';
import * as API from 'shared/backend-data';
import { FlashList } from '@shopify/flash-list';
import { t } from '../../../localisation/i18n';

export interface CreateItemProp {
  createItemPlaceHolder: string;
  createItemIcon: React.FC<SvgProps>;

  createItemHandler: (tag: Tag) => any;
}

export interface InputListProps {
  inputId?: string;
  options: Tag[];
  placeholder: string;
  optionIcon?: React.FC<SvgProps>;
  extraSelectorIcon?: React.FC<SvgProps>;
  initialSelection?: Tag[];
  disableOptions?: Tag[];
  containerStyle?: StyleProp<ViewStyle>;
  createNewOption?: CreateItemProp;
  newlyAddedOption?: Tag;
  optionRowStyling?: ItemRowStyling;
  keepInputListOpenOnOutsideClick?: boolean;
  singleSelection?: boolean;
  initialInputText?: string;
  additionFormStyling?: boolean;
  customMessage?: JSX.Element;
  noResultMessage?: string;
  showSaveButton?: boolean;
  disabledExtraSelectors?: Tag[];

  createNewOptionShortcut?: (label: string) => Promise<API.Failure | undefined> | void;
  renderOption?: (value: Tag | undefined) => JSX.Element;
  handleSelection: (inputId: string, items: Tag[], tagsNotFound?: string[]) => void;
  openInputList: (open: boolean) => void;
  onOptionEdit?: (tag: Tag) => void | undefined;
  onTyping?: (input?: string, inputId?: string) => void | undefined;
  onPressEnter?: (tags?: Tag[]) => void | undefined;
}

export const InputList = (props: InputListProps) => {
  const {
    inputId,
    createNewOption,
    singleSelection,
    customMessage,
    initialInputText = '',
    noResultMessage,
    disableOptions,
    showSaveButton = false,
    containerStyle,
    disabledExtraSelectors,
    extraSelectorIcon,

    createNewOptionShortcut,
    onTyping,
    renderOption,
    openInputList,
    onPressEnter,
  } = props;

  const isMounted = useIsMounted();

  const [_options, _setOptions] = useState<Tag[]>([]);
  const [disabledOptionsMap, setDisabledOptionsMap] = useState<Map<string, Tag>>(new Map());
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<Tag[]>([]);
  const [typingFilter, setTypingFilter] = useState<string>();

  const ref = useDetectOutsideClick<View>(() => {
    setTimeout(() => {
      if (isMounted.current && !props.keepInputListOpenOnOutsideClick) {
        _handleSelection();
        openInputList(false);
      }
    }, 100);
  });

  const inputTextRef = React.createRef<TextInput>();
  const optionsRef = React.useRef<Tag[]>([]);
  const tagsNotFoundOnPaste = React.useRef<string[]>([]);

  useEffect(() => {
    updateListAndInputTagValuesAccordingToOptionsProp(API.deepClone(props.options));
  }, [props.options]);

  useEffect(() => {
    if (initialInputText) {
      setTypingFilter(initialInputText);
      if (_options.length) {
        handleTyping(initialInputText);
      }
    }
  }, [initialInputText, _options]);

  useEffect(() => {
    if (props.initialSelection?.length) {
      setSelectedTags(props.initialSelection);
      updateOptionsAccordingToTheInitialSelectedOptions(
        props.initialSelection,
        API.deepClone(props.options),
      );
    }
  }, []);

  useEffect(() => {
    if (props.newlyAddedOption) {
      handleListOptionSelection({ ...props.newlyAddedOption }, true);
    }
  }, [props.newlyAddedOption]);

  useEffect(() => {
    if (!typingFilter) return;

    orderAndSetFilteredOptions(
      _options.filter(updatedOption => {
        return filteredOptions.find(filteredOption => updatedOption.key === filteredOption.key);
      }),
    );
  }, [_options]);

  useEffect(() => {
    if (!typingFilter) return;
    inputTextRef.current?.clear();
  }, [selectedTags]);

  function orderAndSetFilteredOptions(_fitleredOptions: Tag[]) {
    setFilteredOptions(_.orderBy(_fitleredOptions, [option => option.label.toLowerCase()]));
  }

  /**
   * This function update dropdown options with the selected option with disabled true else false
   * @param selectedItems
   * @param options
   */
  function updateOptions(selectedItems: Tag[], options: Tag[]): Tag[] {
    const result = options.map(option => {
      if (
        selectedItems.find(
          selectedTag =>
            option.key === selectedTag.key && selectedTag.value && !selectedTag.value.selectedShift,
        )
      ) {
        disabledOptionsMap.set(option.key, option);
        return { ...option, disabled: true };
      } else if (option.children?.length) {
        const _result = updateOptions(selectedItems, _.uniqBy(option.children, 'key'));
        return {
          ...option,
          children: _result,
        };
      }
      return { ...option, disabled: option.disabled ?? false };
    });
    return result;
  }

  function updateOptionsAccordingToTheInitialSelectedOptions(selectedItems: Tag[], options: Tag[]) {
    optionsRef.current = updateOptions(selectedItems, options);

    _setOptions(optionsRef.current);
    setDisabledOptionsMap(disabledOptionsMap);
  }

  function onKeyPress(event: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    if (event.nativeEvent.key !== 'Enter') return;

    if (typingFilter) {
      if (filteredOptions.length && !filteredOptions[0].disabled) {
        handleListOptionSelection(filteredOptions[0]);
        inputTextRef.current?.clear();
      }

      return;
    }

    props.handleSelection(inputId ?? '', _.uniqBy(selectedTags, 'key'));
    props.openInputList(false);
  }

  function updateListAndInputTagValuesAccordingToOptionsProp(updatedOptions: Tag[]) {
    optionsRef.current = updatedOptions.map(option => {
      if (disabledOptionsMap.get(option.key)) return { ...option, disabled: true };
      return option;
    });
    _setOptions(optionsRef.current);

    
    if (selectedTags.length) {
      setSelectedTags(
        updatedOptions.filter(updatedOption => {
          return selectedTags.find(selectedTag => updatedOption.key === selectedTag.key);
        }),
      );
    }
  }

  const filterFunction = useCallback(
    _.debounce(input => {
      const _filterOptions = filterOptions(input, API.deepClone(_options));

      orderAndSetFilteredOptions(_filterOptions);
    }, 1000),
    [_options],
  );

  function handleTyping(input: string) {
    setTypingFilter(input);
    onTyping && onTyping(input, inputId);
    if (!input) tagsNotFoundOnPaste.current = [];

    filterFunction(input);
  }

  function filterOptions(input: string, options: Tag[]): Tag[] {
    const _extraSelectorsFilterOptions: Tag[] = [];
    const inputArraySplitBySeparator = input.split(';');
    const containsSeparator = input.includes(';');
    const tagResult: Tag[] = [];

    function _filterExtraSelectorsOptions(
      input: string,
      option: Tag,
      extraSelectorsFilterOptions: Tag[],
    ) {
      
      if (!input) return;

      const extraSelectorOptions = option.value.extraSelector.selectorOptions;

      extraSelectorOptions.forEach((_extraSelector: Tag) => {
        if (
          _.includes(
            replaceDiacriticsAndCapitalLetter(_extraSelector.label),
            replaceDiacriticsAndCapitalLetter(input),
          )
        )
          extraSelectorsFilterOptions.push(_extraSelector);
      });
    }

    function _filterOptions(
      input: string,
      options: Tag[],
      extraSelectorsFilterOptions: Tag[],
    ): Tag[] {
      const matchingOptions = _.map(options, option => {
        if (option.value?.extraSelector) {
          _filterExtraSelectorsOptions(input, option, extraSelectorsFilterOptions);
        }

        if (
          _.includes(
            replaceDiacriticsAndCapitalLetter(option.label),
            replaceDiacriticsAndCapitalLetter(input),
          ) ||
          (option.value?.matricule &&
            _.includes(
              replaceDiacriticsAndCapitalLetter(option.value.matricule),
              replaceDiacriticsAndCapitalLetter(input),
            ))
        ) {
          if (option.children?.length)
            option.children = _filterOptions(input, option.children, extraSelectorsFilterOptions);
          return option;
        } else if (option.children?.length)
          return _filterOptions(input, option.children, extraSelectorsFilterOptions);
      });

      return _.compact(matchingOptions.flat());
    }

    inputArraySplitBySeparator.forEach(eachInput => {
      if (eachInput.trimStart()) {
        if (containsSeparator && !singleSelection) {
          const filteredOptionResult = _filterOptions(
            eachInput.trim(),
            options,
            _extraSelectorsFilterOptions,
          );

          if (
            filteredOptionResult[0] &&
            replaceDiacriticsAndCapitalLetter(filteredOptionResult[0].label) ===
              replaceDiacriticsAndCapitalLetter(eachInput).trim()
          ) {
            handleListOptionSelection(filteredOptionResult[0]);
            setTypingFilter('');
          } else {
            tagsNotFoundOnPaste.current = [...tagsNotFoundOnPaste.current, eachInput];
          }
        }

        tagResult.push(
          ..._filterOptions(eachInput.trim(), options, _extraSelectorsFilterOptions),
          ..._.uniqBy(_extraSelectorsFilterOptions, _option => _option.value.id),
        );
      }
    });

    return tagResult;
  }

  function handleListOptionSelection(tag: Tag, newlyAdded: boolean = false) {
    tag.disabled = true;
    updateDisabledOptionsMap(tag, 'add');
    if (!newlyAdded) {
      optionsRef.current = updateTagList(tag, optionsRef.current);
      _setOptions(optionsRef.current);
    } else {
      optionsRef.current = [...optionsRef.current, tag];
      _setOptions(optionsRef.current);
    }
    setSelectedTags(prev => [...prev, tag]);

    if (singleSelection) {
      props.handleSelection(inputId ?? '', [tag]);
      openInputList(false);
    }

    setTypingFilter('');
    inputTextRef.current?.focus();
  }

  function handleRemovingTagFromInputTag(tag: Tag) {
    tag.disabled = false;
    updateDisabledOptionsMap(tag, 'remove');
    optionsRef.current = updateTagList(tag, optionsRef.current);
    _setOptions(optionsRef.current);
  }

  function updateDisabledOptionsMap(tag: Tag, operation: 'remove' | 'add') {
    if (operation === 'add') disabledOptionsMap.set(tag.key, tag);
    else if (operation === 'remove') disabledOptionsMap.delete(tag.key);
    setDisabledOptionsMap(API.deepClone(disabledOptionsMap));
  }

  function updateTagList(updatedTag: Tag, tagList: Tag[]): Tag[] {
    return tagList.map(option => {
      if (updatedTag.key === option.key) return updatedTag;
      if (option.children) option.children = updateTagList(updatedTag, option.children);
      return option;
    });
  }

  function renderCreateItemRow() {
    return (
      createNewOption && (
        <ListItem
          key={createNewOption.createItemPlaceHolder}
          item={{
            key: createNewOption.createItemPlaceHolder,
            label: createNewOption.createItemPlaceHolder,
          }}
          itemIcon={createNewOption.createItemIcon}
          showItemIcon={true}
          onPress={
            typingFilter && createNewOptionShortcut
              ? () => {
                  createNewOptionShortcut(typingFilter);
                  setTypingFilter('');
                }
              : createNewOption.createItemHandler
          }
          itemDepth={1}
          itemRowStyling={{
            itemContainerStyle: Styles.createTagContainer,
            iconContainerStyle: Styles.createTagIconContainer,
          }}
        />
      )
    );
  }

  function _handleSelection() {
    props.handleSelection(
      inputId ?? '',
      _.uniqBy(selectedTags, 'key'),
      tagsNotFoundOnPaste.current,
    );
  }

  function renderCustomMessage(): JSX.Element | undefined {
    return customMessage;
  }

  function _onPressEnter() {
    if (onPressEnter) onPressEnter(filteredOptions);
  }

  function _onSave() {
    if (onPressEnter) {
      onPressEnter(filteredOptions);
    } else {
      _handleSelection();
      openInputList(false);
    }
  }

  function listHeaderComponent(): JSX.Element | undefined {
    return (
      <>
        {renderCustomMessage()}
        {renderCreateItemRow()}
      </>
    );
  }

  return (
    <View ref={ref} style={[Styles.SelectContainer, containerStyle]}>
      <View style={[Styles.SelectInnerContainerStyle]}>
        <ScrollView style={Styles.inputTagScrollView}>
          <InputTag
            singleSelection={singleSelection}
            inputRef={inputTextRef}
            autoFocus
            style={Styles.selecInputStyle}
            placeholder={props.placeholder}
            selectedTags={selectedTags}
            additionFormStyling={props.additionFormStyling}
            textInputValue={typingFilter}
            showSaveButton={showSaveButton}
            onTyping={handleTyping}
            onKeyPress={onKeyPress}
            onRemoveTag={handleRemovingTagFromInputTag}
            onChange={tags => {
              setSelectedTags(tags);
            }}
            onSave={_onSave}
            onPressEnter={_onPressEnter}
          />
        </ScrollView>

        <FlashList
          keyExtractor={item => item.key}
          ListHeaderComponent={listHeaderComponent()}
          ListEmptyComponent={
            <View style={Styles.noResultContainer}>
              <Text style={Styles.noResult}>{noResultMessage || t('common:filters.noResult')}</Text>
            </View>
          }
          data={typingFilter ? filteredOptions : _options}
          renderItem={({ item }) => {
            return (
              <ListItem
                item={item}
                itemIcon={
                  item.isExtraSelector && extraSelectorIcon
                    ? extraSelectorIcon
                    : props.optionIcon || item.tagIcon
                }
                extraSelectorIcon={extraSelectorIcon}
                key={item.key}
                showItemIcon
                highlightedString={typingFilter}
                onPress={handleListOptionSelection}
                itemDepth={1}
                onListItemEdit={
                  props.onOptionEdit
                    ? (tag: Tag) => {
                        const _tag = { ...tag };
                        delete _tag.disabled;
                        if (props.onOptionEdit) props.onOptionEdit(_tag);
                      }
                    : undefined
                }
                renderOption={renderOption}
                disableOptions={disableOptions}
                disabledExtraSelectors={disabledExtraSelectors}
                showItemPath={Boolean(item.tagPath)}
              />
            );
          }}
        />
      </View>
    </View>
  );
};
