import React, { useState } from 'react';
import { View, Text, Platform, Dimensions, TouchableOpacity } from 'react-native';
import WebModal from 'modal-react-native-web';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { InputText } from 'shared/ui-component/Input';
import * as API from 'shared/backend-data';
import Styles from './Style';
import { UserContext, CognitoAuthErrorCodes } from 'shared/context/UserContext';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { TextButton, YellowButton } from 'shared/ui-component/Button';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { InputsValue, useCustomForm, InputValidationType } from 'shared/hooks/CustomForm';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors, Spacings } from 'shared/styles';
import { ModalUtils } from 'shared/ui-component/Modal';
import { useModal } from 'shared/ui-component/Modal/Modal';

const { width } = Dimensions.get('window');

enum InputIds {
  UserOldPassword = 'UserOldPassword',
  UserNewPassword = 'UserNewPassword',
}

export interface Props {
  handleModalClose: any;
  username?: string | null | undefined;
}

export const ChangePassword: React.FC<Props> = props => {
  const [modalVisible, setModalVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const isMounted = useIsMounted();
  const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;
  const modal = useModal();

  const { errors, onBlur, onChange, onSubmit, navigateInputField, refs } = useCustomForm<string>(
    {
      [InputIds.UserOldPassword]: {
        isFieldMandatory: true,
      },
      [InputIds.UserNewPassword]: {
        validator: [InputValidationType.Password],
        isFieldMandatory: true,
      },
    },
    onSubmitForm,
  );

  const handleModalClose = () => {
    setModalVisible(false);
    props.handleModalClose(false);
  };

  async function handleResetPassword() {
    if (props.username) {
      const result = await UserContext.forgotPassword(props.username);

      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        if (result.data.code === CognitoAuthErrorCodes.NotAuthorizedException)
          setErrorMessage(t('common:auth.resetPassword.unauthorized'));
        else setErrorMessage(result.data.message);

        return;
      }

      modal.displayModal(
        ModalUtils.toastConfig({
          text: capitalizeFirstLetter(t('common:auth.resetPassword.emailSent')),
          callback: () => handleModalClose(),
        }),
      );

      
    }
  }

  async function onSubmitForm(values: InputsValue<string>) {
    setLoading(true);
    setErrorMessage(undefined);
    const oldPassword = values[InputIds.UserOldPassword];
    const newPassword = values[InputIds.UserNewPassword];

    const changepasswordResult = await UserContext.changePassword(oldPassword, newPassword);
    if (!isMounted.current) return;

    if (API.isFailure(changepasswordResult)) {
      setLoading(false);
      if (changepasswordResult.data.code === CognitoAuthErrorCodes.NotAuthorizedException) {
        setErrorMessage(t('common:auth.login.password.incorrectPassword'));
      } else {
        setErrorMessage(changepasswordResult.data.message);
      }
    } else {
      setLoading(false);
      handleModalClose();
    }
  }

  return (
    <WebModal
      animationType="fade"
      transparent
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}
    >
      <View style={Styles.contentContainer}>
        <View style={Styles.passwordFormContainer}>
          <TouchableOpacity style={Styles.cancelButton} onPress={handleModalClose}>
            <IconSVG svgComponent={closeIcon} />
          </TouchableOpacity>
          <View style={{ paddingHorizontal: Spacings.Large }}>
            <Text style={Styles.formTitle}>{t('common:auth.changePassword.header')}</Text>
            <InputText
              autoFocus
              inputRef={refs[InputIds.UserOldPassword]}
              inputId={InputIds.UserOldPassword}
              notEditable={false}
              onTextChange={onChange}
              placeHolder={t('common:auth.changePassword.inputOldPassword.placeholder')}
              secureTextEntry={true}
              label={t('common:auth.changePassword.inputOldPassword.label')}
              style={{
                width: Platform.OS === 'web' ? 260 : width * 0.765,
                borderColor: Platform.OS === 'web' ? undefined : Colors.GreyLight,
              }}
              onSubmitEditing={() => {
                navigateInputField(InputIds.UserNewPassword);
              }}
            />
            <TextButton
              text={t('common:auth.login.password.resetPassword')}
              textStyle={Styles.forgetPasswordText}
              onPress={handleResetPassword}
            />
            <View style={Styles.setTopMarginForNewPasswordInput} />
            <InputText
              inputRef={refs[InputIds.UserNewPassword]}
              inputId={InputIds.UserNewPassword}
              notEditable={false}
              onTextChange={onChange}
              onBlur={onBlur}
              placeHolder={t('common:auth.changePassword.inputNewPassword.placeholder')}
              secureTextEntry={true}
              label={t('common:auth.changePassword.inputNewPassword.label')}
              style={{
                width: Platform.OS === 'web' ? 260 : width * 0.765,
                borderColor: Platform.OS === 'web' ? undefined : Colors.GreyLight,
              }}
              onSubmitEditing={onSubmit}
              errorMessage={errors[InputIds.UserNewPassword]?.inputErrorMessage}
            />

            <Text style={Styles.apiErrorLabel}>{errorMessage ?? ''}</Text>
            <YellowButton
              style={{ marginBottom: Spacings.Standard, marginTop: Spacings.Standard }}
              text={t('common:button.save')}
              onPress={onSubmit}
              isSmallButton
            />
          </View>
          {loading && <Loader />}
        </View>
      </View>
    </WebModal>
  );
};
