import React, { useContext, useEffect, useState } from 'react';
import { View, InteractionManager } from 'react-native';
import { KPIDashboardComponent } from '../components';
import { KPIBanner } from '../../banner/KPIBanner/KPIBanner';
import Styles from '../components/Styles';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { TagExtended } from 'sharedweb/src/Filter/container';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import {
  removeInvalidObjectFromUserPreference,
  DropdownConfigKey,
  TrainingTypeKey,
} from '../../header-layout/headerFilterConfig';
import { TreeTableContext } from 'sharedweb/src/context/TreeTableContext';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import Aigle from 'aigle';
import { isArrayEmpty } from 'shared/util-ts/Functions';
import { useFeature } from 'shared/hooks/useFeature';
import { MyHub } from 'shared/util/MyHub';

interface FilteredData {
  workers: API.Worker[];
  workstations: API.Workstation[];
  trainingSessions: API.TrainingSessionWithDetail[];
}

export const KPIScreen: React.FC = () => {
  useFeature(API.AppFeature.SKILLOP_TRAINING);

  const isMounted = useIsMounted();

  const [loading, setLoading] = useState<boolean>(true);
  const [_operationalWorkers, setOperationalWorkers] = useState<API.Worker[]>([]);
  const [_trainingSessions, setTrainingSessions] = useState<API.TrainingSession[]>([]);
  const [_workstations, setWorkstations] = useState<API.Workstation[]>([]);

  const {
    setKPIData,
    isGraphLoaded,
    kpiData: kpiData,
    setIsGraphLoaded,
    setIsUnderStaffedAndOverStafftedWorkstationsLoaded,
  } = useContext(TreeTableContext);

  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);
  const {
    KPIScreenFilterTags: [KPIScreenFilterTags, setKPIScreenFilterTags],
    currentRoute: [, setCurrentRoute],
  } = useContext(HeaderFilterContext);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!loading) {
        await filterTrainingSessions(KPIScreenFilterTags);
      }
    });
  }, [KPIScreenFilterTags, loading]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      setLoading(true);
      const KPIFilterResult = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.KPIFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(KPIFilterResult)) {
        logger.warn('fetch training filter: error in fetch user Preference', KPIFilterResult);
        setLoading(false);
        return;
      }

      if (KPIFilterResult) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.KPIFilter,
          KPIFilterResult,
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.KPIFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          setLoading(false);
          logger.warn('save KPI filter: error while saving user Preference', saved);
          return;
        }

        setKPIScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.KPIFilter) ?? [],
        );
      }
      setCurrentRoute(RoutePaths.KPI);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async payload => {
      switch (true) {
        case payload.data.factory.dataType === API.DataType.WORKER:
          await fetchOperationalWorkers();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.TRAININGSESSION:
          await fetchTrainingSessions();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.WORKSTATION:
          fetchWorkstations();
          break;
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      fetchWorkstations();

      await fetchOperationalWorkers();
      if (!isMounted.current) return;

      await fetchTrainingSessions();
      if (!isMounted.current) return;
    });

    return () => {
      removeListener();
    };
  }, []);

  function fetchWorkstations() {
    const __workstations = API.getWorkstations();
    if (API.isFailure(__workstations)) return __workstations;
    setWorkstations(__workstations);
  }
  async function fetchOperationalWorkers() {
    const __workers = await API.getWorkers([API.Permission.workerIsOperational]);
    if (API.isFailure(__workers)) return __workers;
    setOperationalWorkers(__workers.result);
  }
  async function fetchTrainingSessions() {
    const __trainingSessions = await API.getTrainingSessions();
    if (API.isFailure(__trainingSessions)) return __trainingSessions;
    setTrainingSessions(__trainingSessions);
  }

  function getTrainingSessionWithState(
    _trainingSessionsWithDetail: API.TrainingSessionWithDetail[],
  ): API.TrainingSessionWithState[] {
    return _trainingSessionsWithDetail.map(trainingSession => {
      return {
        ...trainingSession,
        state: API.getTrainingSessionState(trainingSession),
      };
    });
  }

  async function filterData(
    isKeywordFiltering: boolean,
    filterTags: TagExtended[],
    workers: API.Worker[],
    workstations: API.Workstation[],
    trainingSessions: API.TrainingSessionWithDetail[],
  ): Promise<FilteredData | undefined> {
    if (isKeywordFiltering && isArrayEmpty(filterTags)) {
      return {
        workers: [],
        workstations: [],
        trainingSessions: [],
      };
    }

    const shiftFilters: TagExtended[] = [];
    const ouFilters: TagExtended[] = [];
    const workerFilters: TagExtended[] = [];
    const workerTagFilters: TagExtended[] = [];
    const trainingTypeFilters: TagExtended[] = [];
    const workstationFilters: TagExtended[] = [];
    const trainingFilters: TagExtended[] = [];

    let _filteredWorkers: API.Worker[] = isKeywordFiltering ? [] : workers;
    let _filteredTrainingSessions: API.TrainingSessionWithDetail[] = isKeywordFiltering
      ? []
      : trainingSessions;
    let _filteredWorkstations: API.Workstation[] = isKeywordFiltering ? [] : workstations;

    _.forEach(filterTags, filterTag => {
      if (filterTag.type === DropdownConfigKey.SHIFT) shiftFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.ORGUNIT) ouFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.WORKER_TAG) workerTagFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.TYPE_OF_TRAINING)
        trainingTypeFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.WORKSTATION) workstationFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.TRAINING) trainingFilters.push(filterTag);
    });

    
    if (shiftFilters.length) {
      await Aigle.map(shiftFilters, async eachFilter => {
        const workersInShift = await API.getWorkersInShift(
          eachFilter.value.shiftId,
          eachFilter.value.parentId,
        );
        if (!isMounted.current) return;
        if (API.isFailure(workersInShift)) {
          logger.warn(workersInShift);
          return;
        }

        workersInShift.forEach(worker => {
          workerFilters.push({
            key: worker.id,
            label: '',
          });
        });
      });

      if (!workerFilters.length && !isKeywordFiltering) {
        _filteredTrainingSessions = [];
        _filteredWorkers = [];
        _filteredWorkstations = [];
      }
    }

    
    if (ouFilters.length) {
      const orgUnitIds = ouFilters.map(eachFilter => eachFilter.key);
      const workstations = API.getWorkstations(orgUnitIds);

      let OUAndWorkstationIds = [
        ...workstations.map(eachWorkstation => eachWorkstation.id),
        ...ouFilters.map(OU => OU.key),
      ];
      _filteredWorkstations = workstations;

      const workers = await API.getWorkersInOrganizationalUnits(
        orgUnitIds,
        [API.Permission.workerIsOperational],
        false,
        false,
      );
      if (!isMounted.current) return;
      if (API.isFailure(workers)) {
        logger.warn(workers);
        return;
      }

      _filteredWorkers = workers.result;

      const trainingSessionData = _.filter(
        isKeywordFiltering ? trainingSessions : _filteredTrainingSessions,
        eachData =>
          Boolean(eachData.linkedObjectId && OUAndWorkstationIds.includes(eachData.linkedObjectId)),
      );

      if (isKeywordFiltering) {
        _filteredTrainingSessions = [...trainingSessionData, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = trainingSessionData;
      }
    }

    
    if (workstationFilters.length) {
      const workstationData = _.filter(
        isKeywordFiltering ? workstations : _filteredWorkstations,
        eachData => _.some(workstationFilters, filter => filter.key === eachData.id),
      );

      const trainingSessionData = _.filter(
        isKeywordFiltering ? trainingSessions : _filteredTrainingSessions,
        eachData => _.some(workstationFilters, filter => filter.key === eachData.linkedObjectId),
      );

      if (isKeywordFiltering) {
        _filteredTrainingSessions = [...trainingSessionData, ..._filteredTrainingSessions];
        _filteredWorkstations = [...workstationData, ..._filteredWorkstations];
      } else {
        _filteredTrainingSessions = trainingSessionData;
        _filteredWorkstations = workstationData;
      }
    }

    
    if (workerFilters.length) {
      const workerData = _.filter(isKeywordFiltering ? workers : _filteredWorkers, eachData =>
        _.some(workerFilters, filter => filter.key === eachData.id),
      );

      if (isKeywordFiltering) {
        _filteredWorkers = [...workerData, ..._filteredWorkers];
      } else {
        _filteredWorkers = workerData;
      }

      const trainingSessionData = _.filter(
        isKeywordFiltering ? trainingSessions : _filteredTrainingSessions,
        eachData =>
          _.some(
            _filteredWorkers,
            filter =>
              eachData.scheduledTrainers?.find(mentor => mentor.trainerId === filter.id) ||
              eachData.scheduledTraineeIds.includes(filter.id) ||
              eachData.trainers?.find(mentor => mentor.trainerId === filter.id) ||
              eachData.traineeIds.includes(filter.id),
          ),
      );

      if (isKeywordFiltering) {
        _filteredTrainingSessions = [...trainingSessionData, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = trainingSessionData;
      }
    }

    
    if (trainingTypeFilters.length) {
      const trainingSessionData = _.filter(
        isKeywordFiltering ? trainingSessions : _filteredTrainingSessions,
        eachData =>
          _.some(
            trainingTypeFilters,
            filter =>
              (filter.key === TrainingTypeKey.PRACTICAL && eachData.isPractical) ||
              (filter.key === TrainingTypeKey.LECTURE && !eachData.isPractical),
          ),
      );

      if (isKeywordFiltering) {
        _filteredTrainingSessions = [...trainingSessionData, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = trainingSessionData;
      }
    }

    
    if (workerTagFilters.length) {
      const workerData = _.filter(isKeywordFiltering ? workers : _filteredWorkers, eachData =>
        _.some(workerTagFilters, filter => _.some(eachData.tagIds, tagId => filter.key === tagId)),
      );

      if (isKeywordFiltering) {
        _filteredWorkers = [...workerData, ..._filteredWorkers];
      } else {
        _filteredWorkers = workerData;
      }

      const trainingSessionData = _.filter(
        isKeywordFiltering ? trainingSessions : _filteredTrainingSessions,
        eachData =>
          _.some(
            _filteredWorkers,
            filter =>
              eachData.scheduledTrainers?.find(mentor => mentor.trainerId === filter.id) ||
              eachData.scheduledTraineeIds.includes(filter.id) ||
              eachData.trainers?.find(mentor => mentor.trainerId === filter.id) ||
              eachData.traineeIds.includes(filter.id),
          ),
      );

      if (isKeywordFiltering) {
        _filteredTrainingSessions = [...trainingSessionData, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = trainingSessionData;
      }
    }

    
    if (trainingFilters.length && _filteredTrainingSessions) {
      const trainingSessionData = _.filter(
        isKeywordFiltering ? trainingSessions : _filteredTrainingSessions,
        eachData =>
          _.some(trainingFilters, filter => filter.key === eachData.trainingVersion.trainingId),
      );

      if (isKeywordFiltering) {
        _filteredTrainingSessions = [...trainingSessionData, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = trainingSessionData;
      }
    }

    return {
      workers: _filteredWorkers,
      workstations: _filteredWorkstations,
      trainingSessions: _filteredTrainingSessions,
    };
  }

  async function filterTrainingSessions(KPIScreenFilterTags: TagExtended[]) {
    let _filteredWorkers: API.Worker[] = [..._operationalWorkers];
    let _filteredWorkstations: API.Workstation[] = [..._workstations];
    let _filteredTrainingSessions = await API.getTrainingSessionsWithDetail(_trainingSessions);
    let _filterTags: TagExtended[] = [];
    let _keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    if (!isMounted.current) return;
    if (API.isFailure(_filteredTrainingSessions)) {
      logger.warn(_trainingSessions);
      return;
    }

    _.forEach(KPIScreenFilterTags, tag => {
      if (tag.isActiveBookmarkTag) {
        _filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        _keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        _filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      const data = await filterData(
        true,
        _keywordTags,
        _filteredWorkers,
        _filteredWorkstations,
        _filteredTrainingSessions,
      );
      if (!isMounted.current) return;

      if (data) {
        _filteredWorkers = data.workers;
        _filteredWorkstations = data.workstations;
        _filteredTrainingSessions = data.trainingSessions;
      }
    }

    if (_filterTags.length) {
      const data = await filterData(
        false,
        _filterTags,
        _filteredWorkers,
        _filteredWorkstations,
        _filteredTrainingSessions,
      );
      if (!isMounted.current) return;

      if (data) {
        _filteredWorkers = data.workers;
        _filteredWorkstations = data.workstations;
        _filteredTrainingSessions = data.trainingSessions;
      }
    }

    setKPIData({
      workers: _.uniqBy(_filteredWorkers, 'id'),
      workstations: _.uniqBy(_filteredWorkstations, 'id'),
      trainingSessionsWithState: _.uniqBy(
        getTrainingSessionWithState(_filteredTrainingSessions),
        'id',
      ),
    });
  }

  return (
    <View style={Styles.container}>
      <KPIBanner kpiData={kpiData} isGraphLoaded={isGraphLoaded} />
      <KPIDashboardComponent
        isGraphLoaded={isGraphLoaded}
        kpiData={kpiData}
        setIsGraphLoaded={setIsGraphLoaded}
        setFirstTitle={setFirstTitle}
        setSecondTitle={setSecondTitle}
        setIsUnderStaffedAndOverStafftedWorkstationsLoaded={
          setIsUnderStaffedAndOverStafftedWorkstationsLoaded
        }
      />
    </View>
  );
};
