import { StyleSheet } from 'react-native';
import { Colors, Spacings, fontSize, TextFontStyles } from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.LightBlue,
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: Spacings.Medium,
    height: '100%',
    marginBottom: Spacings.Small,
  },
  component: {
    flex: 1,
    height: '100%',
    marginLeft: Spacings.Medium,
    backgroundColor: Colors.White,
    padding: Spacings.Medium,
    borderRadius: 6,
    textAlign: 'left',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  widgetsContainer: {
    flex: 1,
    flexDirection: 'row',
    right: Spacings.Medium,
    left: Spacings.Medium,
    position: 'absolute',
    bottom: Spacings.Medium,
  },
  widget: {
    borderRadius: 8,
    shadowColor: '#00000021',
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    padding: Spacings.Medium,
    opacity: 1,
    height: 232,
  },
  lateTrainingWidgetContainer: {
    width: '33.33%',
  },
  workstationWidgetContainer: {
    flex: 1,
    flexDirection: 'row',
    marginRight: Spacings.Medium,
  },
  workstationWidget: {
    width: '49.2%',
  },
  widgetTitle: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
    alignSelf: 'flex-start',
    marginBottom: Spacings.Medium,
  },
  widgetContentRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Spacings.xMedium,
  },
  widgetContentText: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    alignSelf: 'flex-start',
  },
  widgetContentTextCount: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
    alignSelf: 'flex-start',
  },

  buttonTextStyle: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
  },

  buttonContainer: {
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: Spacings.Small,
  },
  overStaffedCount: {
    color: Colors.Orange,
  },
  underStaffedCount: {
    color: Colors.Red,
  },
  topLateTrainingSessionCount: {
    color: Colors.Red,
  },
  underStaffedWidgetContainer: {
    marginRight: Spacings.Medium,
  },
  innerContainer: {
    flex: 1,
    backgroundColor: Colors.LightBlue,
    flexDirection: 'row',
    right: Spacings.Medium,
    left: Spacings.Medium,
    position: 'absolute',
    bottom: 265,
    top: Spacings.Medium,
  },
  noText: {
    textAlign: 'center',
    marginTop: Spacings.Standard,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
  bottomButtonStyle: {
    backgroundColor: Colors.Yellow,
    height: 32,
    paddingHorizontal: Spacings.Unit * 8,
    width: 200,
    marginTop: Spacings.Big,
  },
  bottomButtonTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.White,
  },
  alignCenter: {
    alignItems: 'center',
  },
});

export default styles;
