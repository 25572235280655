import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Colors, Spacings } from 'shared/styles';
import { t } from '../../localisation/i18n';
import { MenuItem } from '../Menu';

interface Props {
  menuItems: MenuItem[];
}

const style = StyleSheet.create({
  menuContainer: {
    position: 'absolute',
    top: 60,
    right: 30,
    backgroundColor: Colors.White,
    paddingBottom: Spacings.Medium,
    borderRadius: Spacings.Small,
    shadowColor: Colors.Black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,

    elevation: 5,
  },
  menuItem: {
    padding: Spacings.Medium,
    paddingBottom: 0,
    paddingHorizontal: Spacings.CardPadding,
  },
  deleteButton: {
    color: Colors.Red,
  },
  menuText: {
    fontSize: Spacings.Medium,
  },
});

export const Menu: React.FC<Props> = props => {
  const { menuItems } = props;

  return (
    <View style={style.menuContainer}>
      {menuItems.map((eachItem, index) => (
        <TouchableOpacity
          onPress={eachItem.onPress}
          style={style.menuItem}
          key={index}
          disabled={eachItem.disable}
        >
          <Text
            style={[
              eachItem.style,
              style.menuText,
              eachItem.disable && { color: Colors.GreyLight },
            ]}
          >
            {t(eachItem.label)}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};
