import React from 'react';
import { ViewStyle, StyleProp } from 'react-native';
import { Colors, getCorrectedSpacings } from 'shared/styles';
import { IconSVG } from 'shared/ui-component/Icon';
import ThumbUp from 'shared/assets/svg/icon.thumbUp.svg';
import ThumbDown from 'shared/assets/svg/icon.thumbDown.svg';
import IconAlex from 'shared/assets/svg/icon.alex.svg';

const ICON_SIZE_CARD = getCorrectedSpacings(48);
const ICON_SIZE_HEADER = getCorrectedSpacings(76);
const ICON_SIZE_ROW = getCorrectedSpacings(30);

interface ThumbIconProps {
  status?: boolean | string;
  preSelectedColor?: string;
  type: 'card' | 'header' | 'row';
  containerStyle?: StyleProp<ViewStyle>;
}

export const ThumbIcon: React.FC<ThumbIconProps> = props => {
  const { status, preSelectedColor, type, containerStyle } = props;

  const loading = status === '...' || status === undefined;

  const backgroundColor = loading
    ? Colors.White
    : preSelectedColor
    ? preSelectedColor
    : status
    ? Colors.Green
    : Colors.Red;
  const size =
    type === 'card' ? ICON_SIZE_CARD : type === 'header' ? ICON_SIZE_HEADER : ICON_SIZE_ROW;
  const iconSize = loading ? size : size / 2;
  const iconStyle: ViewStyle = {
    backgroundColor: backgroundColor,
    width: size,
    height: size,
    borderRadius: size,
    justifyContent: 'center',
  };

  const SVGIcon = loading ? IconAlex : status ? ThumbUp : ThumbDown;

  return (
    <IconSVG
      containerStyle={[iconStyle, containerStyle]}
      svgComponent={SVGIcon}
      color={loading ? Colors.GreyLight : Colors.White}
      size={{ width: iconSize, height: iconSize }}
    />
  );
};
