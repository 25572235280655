import React from 'react';
import { View, Text } from 'react-native';
import { t } from 'shared/localisation/i18n';
import * as SharedStyles from 'shared/styles';
import { Styles } from './Styles';
import * as _ from 'lodash-es';
import { RadioButton } from 'shared/ui-component/Button';
import { SkillCreateInputWithoutTrainingType } from './index';
import { isSkillGroup } from 'shared/backend-data';

interface Props {
  skill: SkillCreateInputWithoutTrainingType;
  isPanelEditable: boolean;
  setSkill: React.Dispatch<React.SetStateAction<SkillCreateInputWithoutTrainingType>>;
}

export const SkillTrainingTypePanel: React.FC<Props> = props => {
  const { skill, isPanelEditable, setSkill } = props;

  return (
    <View style={[Styles.midPanelContainer]}>
      <Text style={[Styles.midPanelTitleStyle]}>
        {t('alex:skills.trainingType')} {t('alex:skills.toObtainSkill', {}, false)}
      </Text>
      <View style={Styles.midPanelDivider} />
      <RadioButton
        initialItemIndex={
          skill.isPractical === true ? 0 : skill.isPractical === false ? 1 : undefined
        }
        items={[
          {
            item: t('glossary:trainingPractical'),
            description: ' ' + t('alex:skills.trainingPracticalDescription'),
          },
          {
            item: t('glossary:trainingNotPractical'),
            description: ' ' + t('alex:skills.trainingNotPracticalDescription'),
          },
        ]}
        roundedWidth={20}
        radioButtonColor={SharedStyles.Colors.Black}
        disabled={!isPanelEditable}
        onChange={(item, index) => {
          setSkill({
            ...skill,
            isPractical: index === 0 ? true : false,
            
            skillIds: isSkillGroup(skill) ? [] : skill.skillIds,
          });
        }}
      />
    </View>
  );
};
