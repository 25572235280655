import * as API from 'shared/backend-data';
import * as APIf from 'shared/backend-data/FactoryApiWrapper';
import * as _ from 'lodash-es';
import { AppContext } from '../context/AppContext';
import { UserContext } from '../context/UserContext';
import logger from './Logger';
import { FetchPolicy } from 'shared/backend-data';

export interface TenantApp {
  tenant: API.Tenant;
  app: API.App;
}

export interface WorkerTenantApp extends TenantApp {
  worker: API.Worker;
}





export async function updateTenant(
  tenant: API.TenantPartialUpdateInput,
): Promise<API.Result<API.Tenant>> {
  
  const factory = await API.updateFactoryBusinessObject(API.DataType.TENANT, tenant);
  if (API.isFailure(factory)) return factory;

  return { ...factory.tenant, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

/**
 * Get the Tenant if user has access to it.
 * @param tenantId (Optional) if not set the current Tenant is returned
 */
export async function getTenant(tenantId?: string): Promise<API.Result<API.Tenant>> {
  if (!tenantId) {
    const tenantApp = await getUserTenantApp();
    if (API.isFailure(tenantApp)) return tenantApp;

    return tenantApp.tenant;
  }

  const factory = await API.getFactory(API.DataType.TENANT, tenantId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.tenant, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}


export async function getTenants(
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.Tenant[]>> {
  const factories = await APIf.listFactoriesWithDataType(
    API.DataType.TENANT,
    undefined,
    itemsLimit,
    nextToken,
  );
  if (API.isFailure(factories)) return factories;

  return {
    result: _.map(factories.result, factory => {
      return { ...factory.tenant!, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
    }),
    nextToken: factories.nextToken,
  };
}





export async function updateApp(app: API.AppPartialUpdateInput): Promise<API.Result<API.App>> {
  const factory = await APIf.updateFactoryApp(app);
  if (API.isFailure(factory)) return factory;

  return { ...factory.app, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}





/**
 * Register an App to a Tenant. It will create a Worker for the signIn User, associate it to the TenantApp and update the pk on the backend.
 * @param tenant
 * @param app
 */
export async function registerTenantApp(
  tenant: API.Factory<API.DataType.TENANT>,
  app: API.Factory<API.DataType.APP>,
): Promise<API.Result<API.TenantApp>> {
  const factory = await API.createFactoryTenantAppRelation(tenant, app);
  if (API.isFailure(factory)) return factory;

  return {
    tenant: { ...factory.tenant, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy },
    app: { ...factory.app, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy },
  };
}

/**
 * Unregister an App from a Tenant
 * @param tenantId
 * @param appId
 */
export async function unregisterTenantApp(
  tenantId: string,
  appId: string,
): Promise<API.Result<API.TenantApp>> {
  const factory = await API.deleteFactoryBusinessObject<API.DataType.TENANT_APP>(tenantId, appId);
  if (API.isFailure(factory)) return factory;

  return {
    tenant: { ...factory.tenant, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy },
    app: { ...factory.app, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy },
  };
}





/**
 * Get the current logged-in User TenantApp. Will fails if AppContext is not set on a TenantApp
 */
export async function getUserTenantApp(): Promise<API.Result<API.TenantApp>> {
  const appContext = AppContext.getContext();
  if (API.isFailure(appContext)) return appContext;

  if (appContext.pkType !== API.PkType.TenantApp)
    return API.createFailure_Unspecified('Context is not a TenantApp: ' + appContext.pk);

  const pk = appContext.pk.split(API.SeparatorIds);

  const tenant = await getTenant(pk[0]);
  if (API.isFailure(tenant)) return tenant;

  const factoryApp = await API.getFactory(API.DataType.APP, pk[1]);
  if (API.isFailure(factoryApp)) return factoryApp;

  return {
    tenant,
    app: { ...factoryApp.app, updatedAt: factoryApp.updatedAt, updatedBy: factoryApp.updatedBy },
  };
}

export async function getUserTenantApps(
  userId: string,
  fetchPolicy: 'network-no-cache',
): Promise<API.Result<API.WritableFactory<API.DataType.WORKER_TENANT_APP>[]>>;
export async function getUserTenantApps(
  userId?: string,
  fetchPolicy?: Exclude<FetchPolicy, 'network-no-cache'>,
): Promise<API.Result<API.Factory<API.DataType.WORKER_TENANT_APP>[]>>;
/**
 * Get the WorkerTenantApps where the given User is registered
 * @param userId (optional) if not set the current User is used.
 * WARNING userId is different from workerId
 */
export async function getUserTenantApps(
  userId?: string,
  fetchPolicy?: FetchPolicy,
): Promise<
  API.Result<
    | API.WritableFactory<API.DataType.WORKER_TENANT_APP>[]
    | API.Factory<API.DataType.WORKER_TENANT_APP>[]
  >
> {
  if (!userId) {
    const user = UserContext.getUser();
    if (API.isFailure(user)) return user;

    userId = user.id;
  }

  if (fetchPolicy === 'network-no-cache') {
    const factories = await APIf.listFactoriesWithDataType(
      API.DataType.WORKER_TENANT_APP,
      fetchPolicy,
    );
    if (API.isFailure(factories)) return factories;

    return factories.result;
  } else {
    const factories = await APIf.listFactoriesWithDataType(
      API.DataType.WORKER_TENANT_APP,
      fetchPolicy,
    );
    if (API.isFailure(factories)) return factories;

    return factories.result;
  }
}

/**
 * Triggers the closing of the current TenantApp (if loaded, no effect otherwise).
 * Consequently it triggers the 'AppUnset' event that is listened by the navigation handler#
 * to bring back the user to the tenant/tenantApp selection screen
 * @returns
 */
export async function closeAndSwitchTenantOrTenantApp() {
  const user = UserContext.getUser();
  if (API.isFailure(user)) {
    logger.warn(
      'There is no business logic to call closeTenantOrTenantApp() if user is not set, please fix code',
      user.message,
      user,
    );
    return;
  }

  await AppContext.setContext(null);
}
