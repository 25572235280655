import React, { useState, useEffect, useContext } from 'react';
import { InteractionManager, Animated } from 'react-native';
import * as API from 'shared/backend-data';
import { Header } from 'sharedweb/src/Header/Header.web';
import './styles.css';
import { UserContext } from 'shared/context/UserContext';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import styles from './styles';
import { wait } from 'shared/util-ts/Functions';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { RouteComponentProps } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { ModalUtils } from 'shared/ui-component/Modal';
import { UsersModal } from '../users-modal/modal/container';
import { InviteWorkerModalFilterProvider } from 'shared/context/inviteWorkerModalContext';
import { t } from 'shared/localisation/i18n';
import {
  dropdownConfigInitialValues,
  DropdownConfigKey,
  FilterScreenConfig,
  DropdownInitialConfig,
  TrainingTypeKey,
  HomeOtherFilterKey,
  FilterConfigKey,
  WorkerSkillOtherFilterKey,
  CheckBoxConfigKey,
} from './headerFilterConfig';
import { sortWorkers } from 'shared/backend-data';
import { getTreeNodeWithShiftTag } from 'shared/layout/organization-unit/OrganizationUnit';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { TagExtended, FilterConfig } from 'sharedweb/src/Filter/container';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { WorkstationWorkerLevel, WorkerState } from 'backend/src/api';
import * as _ from 'lodash-es';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { MyAccount } from 'shared/layout/my-account/container';
import { DashboardScrollViewOffset } from '../dashboard/versatility-panel/VersatilityPanel';
import { Colors } from 'shared/styles';
import { FilterTagOrder } from 'sharedweb/src/Filter/component';
import { RouteLocations } from '../navigation/Routes';
import { MyHub } from 'shared/util/MyHub';

const WorkstationIcon = require('shared/assets/svg/icon.workstation.svg').default;
const WorkerIcon = require('shared/assets/svg/icon.worker.svg').default;
const SkillIcon = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const WorkerTagIcon = require('shared/assets/svg/icon.team.svg').default;
const TrainingIcon = require('shared/assets/svg/icon.training.svg').default;
const UnitIcon = require('shared/assets/svg/icon.unit.small.svg').default;

const workerSkillOtherTagColor = Colors.GreenSoft;
const skillWorkerOtherTagColor = Colors.Yellow;
const homeOtherTagColor = Colors.BlueSky;
const workerOtherTagColor = Colors.Turquoise;

const SkillValidityTagColor = Colors.MediumBlue;
const levelTagColor = Colors.LightMagenta;
const trainingTypeTagColor = Colors.LightPink;
const workstationTagColor = Colors.LightRedOrange;
const trainingTagColor = Colors.LightYellow;
const trainingTagsTagColor = Colors.MediumLightYellow;
const workerTagColor = Colors.LightCyan;
const workerTagsTagColor = Colors.LightGreenCyan;
const orgUnitTagColor = Colors.LightGreenCyan2;
const skillTagColor = Colors.LightGreen;
const skillTagsTagColor = Colors.LightGreen2;
const contractTagColor = Colors.FadedRed;

export interface HeaderProps extends RouteComponentProps {
  headerSideBarFullScreen: boolean;
}

export enum WorkerTrainingStatusForFilter {
  TRAIN = 'TRAIN',
  TRAIN_AUTO = 'TRAIN_AUTO',
}

enum WorkerProfileTabs {
  Training,
  Skill,
  Workstation,
}

export const HeaderLayout: React.FC<HeaderProps> = props => {
  const { history, headerSideBarFullScreen } = props;
  
  
  const skillValidityOptions: TagExtended[] = [
    {
      key: API.Validity.OK,
      label: t('alex:workerSkill.valid'),
      value: API.Validity.OK,
      type: DropdownConfigKey.SKILL_VALIDITY,
      color: SkillValidityTagColor,
      order: FilterTagOrder.Validity,
    },
    {
      key: API.Validity.OK_EXPIRE_SOON,
      label: t('alex:workerSkill.expireSoon'),
      value: API.Validity.OK_EXPIRE_SOON,
      type: DropdownConfigKey.SKILL_VALIDITY,
      color: SkillValidityTagColor,
      order: FilterTagOrder.Validity,
    },
    {
      key: API.Validity.KO_EXPIRED,
      label: t('alex:workerSkill.expired'),
      value: API.Validity.KO_EXPIRED,
      type: DropdownConfigKey.SKILL_VALIDITY,
      color: SkillValidityTagColor,
      order: FilterTagOrder.Validity,
    },
  ];
  const levelOptions: TagExtended[] = [
    {
      key: WorkstationWorkerLevel.LEVEL1,
      label: 'glossary:level_1',
      value: WorkstationWorkerLevel.LEVEL1,
      type: DropdownConfigKey.LEVEL,
      color: levelTagColor,
      order: FilterTagOrder.Level,
    },
    {
      key: WorkstationWorkerLevel.LEVEL2,
      label: 'glossary:level_2',
      value: WorkstationWorkerLevel.LEVEL2,
      type: DropdownConfigKey.LEVEL,
      color: levelTagColor,
      order: FilterTagOrder.Level,
    },
    {
      key: WorkstationWorkerLevel.LEVEL3,
      label: 'glossary:level_3',
      value: WorkstationWorkerLevel.LEVEL3,
      type: DropdownConfigKey.LEVEL,
      color: levelTagColor,
      order: FilterTagOrder.Level,
    },
    {
      key: WorkstationWorkerLevel.LEVEL4,
      label: 'glossary:level_4',
      value: WorkstationWorkerLevel.LEVEL4,
      type: DropdownConfigKey.LEVEL,
      color: levelTagColor,
      order: FilterTagOrder.Level,
    },
    {
      key: WorkerTrainingStatusForFilter.TRAIN,
      label: 'alex:filters.inTraining',
      value: WorkerTrainingStatusForFilter.TRAIN,
      type: DropdownConfigKey.LEVEL,
      color: levelTagColor,
    },
    {
      key: WorkerTrainingStatusForFilter.TRAIN_AUTO,
      label: 'alex:filters.lockedLevel',
      value: WorkerTrainingStatusForFilter.TRAIN_AUTO,
      type: DropdownConfigKey.LEVEL,
      color: levelTagColor,
      order: FilterTagOrder.Level,
    },
  ];
  const trainingTypeOptions: TagExtended[] = [
    {
      key: TrainingTypeKey.PRACTICAL,
      label: 'glossary:trainingPractical',
      type: DropdownConfigKey.TYPE_OF_TRAINING,
      color: trainingTypeTagColor,
    },
    {
      key: TrainingTypeKey.LECTURE,
      label: 'glossary:trainingNotPractical',
      type: DropdownConfigKey.TYPE_OF_TRAINING,
      color: trainingTypeTagColor,
    },
  ];
  const homeOtherFilter: TagExtended[] = [
    {
      key: HomeOtherFilterKey.ATLEAST_ONE_SKILL_EXPIRING_SOON,
      label: 'alex:filters.skillExpiredSoon',
      value: HomeOtherFilterKey.ATLEAST_ONE_SKILL_EXPIRING_SOON,
      type: DropdownConfigKey.HOME_OTHER_FILTER,
      color: homeOtherTagColor,
      order: FilterTagOrder.Others,
    },
    {
      key: HomeOtherFilterKey.ATLEAST_ONE_SKILL_EXPIRED,
      label: 'alex:filters.skillExpired',
      value: HomeOtherFilterKey.ATLEAST_ONE_SKILL_EXPIRED,
      type: DropdownConfigKey.HOME_OTHER_FILTER,
      color: homeOtherTagColor,
      order: FilterTagOrder.Others,
    },
  ];
  const workerSkillOtherFilter: TagExtended[] = [
    {
      key: WorkerSkillOtherFilterKey.SKILL_NOT_ACQUIRED,
      label: 'alex:filters.skillsNotAcquriredByWorker',
      value: WorkerSkillOtherFilterKey.SKILL_NOT_ACQUIRED,
      type: DropdownConfigKey.WORKER_SKILL_OTHER_FILTER,
      color: workerSkillOtherTagColor,
      order: FilterTagOrder.Others,
    },
  ];
  const skillWorkerOtherFilter: TagExtended[] = [
    {
      key: WorkerSkillOtherFilterKey.SKILL_NOT_ACQUIRED,
      label: 'alex:filters.workersDoNotHaveSkill',
      value: WorkerSkillOtherFilterKey.SKILL_NOT_ACQUIRED,
      type: DropdownConfigKey.SKILL_WORKER_OTHER_FILTER,
      color: skillWorkerOtherTagColor,
      order: FilterTagOrder.Others,
    },
  ];
  const workerOtherFilter: TagExtended[] = [
    {
      key: WorkerState.ARCHIVED,
      label: 'alex:filters.showArchivedWorkers',
      value: WorkerState.ARCHIVED,
      type: DropdownConfigKey.WORKER_OTHER_FILTER,
      color: workerOtherTagColor,
      order: FilterTagOrder.Others,
    },
    {
      key: WorkerState.ACTIVE,
      label: 'alex:filters.hideActiveWorkers',
      value: WorkerState.ACTIVE,
      type: DropdownConfigKey.WORKER_OTHER_FILTER,
      color: workerOtherTagColor,
      order: FilterTagOrder.Others,
    },
  ];
  const filterScreenConfig: FilterScreenConfig = {
    [RoutePaths.Home]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.ORGUNIT],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER],
        dropdownConfigInitialValues[DropdownConfigKey.WORKSTATION],
        dropdownConfigInitialValues[DropdownConfigKey.LEVEL],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER_TAG],
        dropdownConfigInitialValues[DropdownConfigKey.HOME_OTHER_FILTER],
      ],
      checkBox: [
        {
          label: 'alex:filters.showSubUnits',
          key: CheckBoxConfigKey.SHOW_SUB_UNITS,
          value: true,
          color: '',
        },
        {
          label: 'alex:filters.showInheritedWorkers',
          key: CheckBoxConfigKey.SHOW_SELECTED_ORG_UNITS,
          value: false,
          color: '',
        },
      ],
      filterPlaceholder: 'alex:filters.filterHome',
    },
    [RoutePaths.Workstations]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.ORGUNIT],
        dropdownConfigInitialValues[DropdownConfigKey.WORKSTATION],
      ],
      filterPlaceholder: 'alex:filters.filterWorkstation',
    },
    [RoutePaths.Workers]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.ORGUNIT],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER_TAG],
        dropdownConfigInitialValues[DropdownConfigKey.CONTRACT],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER_VERSATILITY],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER_OTHER_FILTER],
      ],
      checkBox: [
        {
          label: 'alex:filters.showOperationalAndNotOperationalWorkers',
          key: CheckBoxConfigKey.INCLUDE_WORKERS_NOT_OPERATIONAL,
          value: true,
          color: '',
        },
        {
          label: 'alex:filters.showInheritedWorkers',
          key: CheckBoxConfigKey.SHOW_SELECTED_ORG_UNITS,
          disabled: true,
          color: '',
        },
      ],
      filterPlaceholder: 'alex:filters.filterWorkers',
    },
    [RoutePaths.TrainingSessions]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.ORGUNIT],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER_TAG],
        dropdownConfigInitialValues[DropdownConfigKey.TYPE_OF_TRAINING],
        dropdownConfigInitialValues[DropdownConfigKey.WORKSTATION],
        dropdownConfigInitialValues[DropdownConfigKey.TRAINING],
        dropdownConfigInitialValues[DropdownConfigKey.TRAINING_TAG],
      ],
      filterPlaceholder: 'alex:filters.filterTrainingSession',
    },
    [RoutePaths.Skills]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.SKILL],
        dropdownConfigInitialValues[DropdownConfigKey.VALIDITY],
        dropdownConfigInitialValues[DropdownConfigKey.SKILL_TAG],
        dropdownConfigInitialValues[DropdownConfigKey.TYPE_OF_TRAINING],
      ],
      filterPlaceholder: 'alex:filters.filterSkill',
    },
    [RoutePaths.KPI]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.ORGUNIT],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER_TAG],
        dropdownConfigInitialValues[DropdownConfigKey.TYPE_OF_TRAINING],
        dropdownConfigInitialValues[DropdownConfigKey.WORKSTATION],
        dropdownConfigInitialValues[DropdownConfigKey.TRAINING],
      ],
      filterPlaceholder: 'common:filters.filter',
    },
    [FilterConfigKey.SkillWorker]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.ORGUNIT],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER],
      ],
      filterPlaceholder: 'alex:filters.filterSkill',
    },
    [FilterConfigKey.WorkerWorkstation]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.ORGUNIT],
        dropdownConfigInitialValues[DropdownConfigKey.SKILL],
        dropdownConfigInitialValues[DropdownConfigKey.WORKSTATION],
        dropdownConfigInitialValues[DropdownConfigKey.TRAINING],
        dropdownConfigInitialValues[DropdownConfigKey.LEVEL],
      ],
      filterPlaceholder: 'common:filters.filter',
    },
    [FilterConfigKey.WorkerSkill]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.SKILL],
        dropdownConfigInitialValues[DropdownConfigKey.SKILL_VALIDITY],
        dropdownConfigInitialValues[DropdownConfigKey.WORKSTATION],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER_SKILL_OTHER_FILTER],
      ],
      filterPlaceholder: 'common:filters.filter',
    },
    [RoutePaths.Trainings]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.WORKSTATION],
        dropdownConfigInitialValues[DropdownConfigKey.SKILL],
        dropdownConfigInitialValues[DropdownConfigKey.TYPE_OF_TRAINING],
        dropdownConfigInitialValues[DropdownConfigKey.TRAINING_TAG],
      ],
      filterPlaceholder: 'alex:filters.filterTraining',
    },
    [FilterConfigKey.TrainingWorker]: {
      dropdown: [
        dropdownConfigInitialValues[DropdownConfigKey.ORGUNIT],
        dropdownConfigInitialValues[DropdownConfigKey.WORKER],
      ],
      filterPlaceholder: 'alex:filters.filterTraining',
    },
  };

  const {
    homeScreenFilterTags: [homeScreenFilterTags, setHomeScreenFilterTags],
    workstationScreenFilterTags: [workstationScreenFilterTags, setWorkstationScreenFilterTags],
    workerScreenFilterTags: [workerScreenFilterTags, setWorkerScreenFilterTags],
    skillScreenFilterTags: [skillScreenFilterTags, setSkillScreenFilterTags],
    trainingSessionScreenFilterTags: [
      trainingSessionScreenFilterTags,
      setTrainingSessionScreenFilterTags,
    ],
    workerWorkstationScreenFilterTags: [
      workerWorkstationScreenFilterTags,
      setWorkerWorkstationScreenFilterTags,
    ],
    workerSkillScreenFilterTags: [workerSkillScreenFilterTags, setWorkerSkillScreenFilterTags],
    trainingScreenFilterTags: [trainingScreenFilterTags, setTrainingScreenFilterTags],
    KPIScreenFilterTags: [KPIScreenFilterTags, setKPIScreenFilterTags],
    trainingWorkerScreenFilterTags: [
      trainingWorkerScreenFilterTags,
      setTrainingWorkerScreenFilterTags,
    ],
    skillWorkerScreenFilterTags: [skillWorkerScreenFilterTags, setSkillWorkerScreenFilterTags],
    currentRoute: [currentRoute],
    currentTabIndex: [currentTabIndex],
    disableFilter: [disableFilter, setDisableFilter],
    disableFilter: [hideFilter, setHideFilter],
  } = useContext(HeaderFilterContext);

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const [loader, setLoader] = useState<boolean>(false);
  const [worker, setWorker] = useState<API.Worker>();
  const [headerZIndex, setHeaderZIndex] = useState(2);
  const [usersModal, showUsersModal] = useState(false);
  const [myAccountModal, showMyAccountModal] = useState<boolean>(false);
  const [filterTags, setFilterTags] = useState<Map<string, TagExtended[]>>(new Map());
  const [skills, setSkills] = useState<API.Skill[]>([]);
  const [orgUnits, setOrgUnits] = useState<API.OrganizationalUnit[]>([]);
  const [trainings, setTrainings] = useState<API.Training[]>([]);
  const [workers, setWorkers] = useState<API.Worker[]>([]);
  const [workstations, setWorkstations] = useState<API.Workstation[]>([]);
  const [workerTags, setWorkerTags] = useState<API.WorkerTag[]>([]);
  const [contractTypes, setContractTypes] = useState<API.ContractType[]>([]);
  const [trainingTags, setTrainingTags] = useState<API.TrainingTag[]>([]);
  const [archivedWorkers, setArchivedWorkers] = useState<API.Worker[]>([]);
  const [skillTags, setSkillTags] = useState<API.SkillTag[]>([]);
  const [shifts, setShifts] = useState<API.Shift[]>([]);
  const [pausedContractTypes, setPausedContractTypes] = useState<API.ContractType[]>([]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async payload => {
      switch (true) {
        case payload.data.factory.dataType === API.DataType.WORKER:
          await _getWorkers();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.TRAINING:
          await _getTrainings();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.ORGUNIT:
          _getOrgUnits();
          break;

        case payload.data.factory.dataType === API.DataType.SKILL:
          await _getSkills();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.SKILLTAG:
          await _getSkillTags();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.TRAININGTAG:
          await _getTrainingTags();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.WORKERTAG:
          await _getWorkerTags();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.SHIFT:
          await _getShifts();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.CONTRACTTYPE:
          await _getContractTypes();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.WORKSTATION:
          _getWorkstations();
          break;
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      _getWorkstations();

      _getOrgUnits();

      await _getTrainings();
      if (!isMounted.current) return;

      await _getWorkerTags();
      if (!isMounted.current) return;

      await _getShifts();
      if (!isMounted.current) return;

      await _getWorkers();
      if (!isMounted.current) return;

      await _getTrainingTags();
      if (!isMounted.current) return;

      await _getSkills();
      if (!isMounted.current) return;

      await _getSkillTags();
      if (!isMounted.current) return;

      await _getContractTypes();
      if (!isMounted.current) return;
    });

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const _worker = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(_worker)) {
        logger.warn('worker error', _worker);
        return _worker;
      }

      setWorker(_worker);
    });
  }, []);

  useEffect(() => {
    wait(headerSideBarFullScreen ? 0 : 1200).then(() => {
      if (isMounted.current) setHeaderZIndex(headerSideBarFullScreen ? 0 : 2);
    });
  }, [props.headerSideBarFullScreen]);

  useEffect(() => {
    if (
      history.location.pathname === RoutePaths.Home ||
      history.location.pathname === RoutePaths.TrainingSessions
    ) {
      setDisableFilter(false);
      setHideFilter(false);
    } else if (history.location.pathname === RoutePaths.KPIOrgUnits) setHideFilter(true);
    else setHideFilter(false);
  }, [history.location.pathname]);

  const [dropdownConfig, setDropdownConfig] = useState<DropdownInitialConfig>(
    dropdownConfigInitialValues,
  );
  const [homeScreenTags, setHomeScreenTags] = useState<TagExtended[]>([]);
  const [workstationScreenTags, setWorkstationScreenTags] = useState<TagExtended[]>([]);
  const [trainingScreenTags, setTrainingScreenTags] = useState<TagExtended[]>([]);
  const [KPIScreenTags, setKPIScreenTags] = useState<TagExtended[]>([]);
  const [trainingWorkerScreenTags, setTrainingWorkerScreenTags] = useState<TagExtended[]>([]);
  const [workerScreenTags, setWorkerScreenTags] = useState<TagExtended[]>([]);
  const [skillScreenTags, setSkillScreenTags] = useState<TagExtended[]>([]);
  const [skillWorkerScreenTags, setSkillWorkerScreenTags] = useState<TagExtended[]>([]);
  const [trainingSessionScreenTags, setTrainingSessionScreenTags] = useState<TagExtended[]>([]);
  const [workerWorkstationTags, setWorkerWorkstationTags] = useState<TagExtended[]>([]);
  const [workerSkillTags, setWorkerSkillTags] = useState<TagExtended[]>([]);
  const [homeScreenFilterConfig, setHomeScreenFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[RoutePaths.Home],
  );
  const [workstationScreenFilterConfig, setWorkstationScreenFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[RoutePaths.Workstations],
  );
  const [workerScreenFilterConfig, setWorkerScreenFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[RoutePaths.Workers],
  );
  const [skillScreenFilterConfig, setSkillScreenFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[RoutePaths.Skills],
  );
  const [skillWorkerScreenFilterConfig, setSkillWorkerScreenFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[FilterConfigKey.SkillWorker],
  );
  const [trainingSessionScreenFilterConfig, setTrainingSessionScreenFilterConfig] =
    useState<FilterConfig>(filterScreenConfig[RoutePaths.TrainingSessions]);
  const [KPIScreenFilterConfig, setKPIScreenFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[RoutePaths.KPI],
  );
  const [workerWorkstationFilterConfig, setWorkerWorkstationFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[FilterConfigKey.WorkerWorkstation],
  );
  const [workerSkillFilterConfig, setWorkerSkillFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[FilterConfigKey.WorkerSkill],
  );
  const [trainingFilterConfig, setTrainingFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[RoutePaths.Trainings],
  );
  const [trainingWorkerFilterConfig, setTrainingWorkerFilterConfig] = useState<FilterConfig>(
    filterScreenConfig[FilterConfigKey.TrainingWorker],
  );

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      fetchDataForDropdown();
    });
  }, [
    workers,
    archivedWorkers,
    workstations,
    workerTags,
    trainings,
    skills,
    skillTags,
    contractTypes,
    pausedContractTypes,
    orgUnits,
    shifts,
  ]);

  async function _getContractTypes() {
    const _contractTypes = await API.getContractTypes();
    if (!isMounted.current) return;
    if (API.isFailure(_contractTypes)) {
      logger.warn(_contractTypes);
      return;
    }
    setContractTypes(_contractTypes);

    const _pausedContractTypes = await API.getContractTypes(true);
    if (!isMounted.current) return;
    if (API.isFailure(_pausedContractTypes)) {
      logger.warn(_pausedContractTypes);
      return;
    }
    setPausedContractTypes(_pausedContractTypes);
  }
  function _getWorkstations() {
    const __workstations = API.getWorkstations(undefined, false, true);
    if (API.isFailure(__workstations)) return __workstations;
    setWorkstations(__workstations);
  }
  async function _getShifts() {
    const __shifts = await API.getShifts();
    if (API.isFailure(__shifts)) return __shifts;
    setShifts(__shifts);
  }
  async function _getWorkers() {
    const __workers = await API.getWorkers(undefined, undefined, true);
    if (API.isFailure(__workers)) return __workers;
    setWorkers(__workers.result);

    const archivedWorkers: API.Worker[] = [];
    const workers: API.Worker[] = [];
    __workers.result.forEach(eachWorker =>
      eachWorker.state === API.WorkerState.ARCHIVED
        ? archivedWorkers.push(eachWorker)
        : workers.push(eachWorker),
    );
    setArchivedWorkers(archivedWorkers);
  }
  async function _getTrainings() {
    const __trainings = await API.getTrainings();
    if (API.isFailure(__trainings)) return __trainings;
    setTrainings(__trainings.result);
  }
  async function _getWorkerTags() {
    const __workerTags = await API.getWorkerTags();
    if (API.isFailure(__workerTags)) return __workerTags;
    setWorkerTags(__workerTags);
  }
  async function _getSkillTags() {
    const __skillTags = await API.getSkillTags();
    if (API.isFailure(__skillTags)) return __skillTags;
    setSkillTags(__skillTags);
  }
  async function _getTrainingTags() {
    const __trainingTags = await API.getTrainingTags();
    if (API.isFailure(__trainingTags)) return __trainingTags;
    setTrainingTags(__trainingTags);
  }
  function _getOrgUnits() {
    const __orgUnits = API.getOrganizationalUnits();
    if (API.isFailure(__orgUnits)) return __orgUnits;
    setOrgUnits(__orgUnits);
  }
  async function _getSkills() {
    const __skills = await API.getSkills();
    if (API.isFailure(__skills)) return __skills;
    setSkills(__skills.result);
  }

  function updateDropdownConfig(dropdownConfig: DropdownInitialConfig) {
    const _tags: TagExtended[] = [];
    Object.keys(dropdownConfigInitialValues).forEach(eachKey => {
      _tags.push(...(dropdownConfig[eachKey].options ?? []));
    });
    setHomeScreenTags([
      ...(dropdownConfig[DropdownConfigKey.SHIFT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKSTATION].options ?? []),
      ...(levelOptions ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER_TAG].options ?? []),
      ...(homeOtherFilter ?? []),
    ]);
    setHomeScreenFilterConfig({
      ...homeScreenFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SHIFT],
        dropdownConfig[DropdownConfigKey.WORKER],
        dropdownConfig[DropdownConfigKey.WORKSTATION],
        dropdownConfig[DropdownConfigKey.LEVEL],
        dropdownConfig[DropdownConfigKey.WORKER_TAG],
        dropdownConfig[DropdownConfigKey.HOME_OTHER_FILTER],
      ],
    });
    setWorkstationScreenTags([
      ...(dropdownConfig[DropdownConfigKey.SHIFT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKSTATION].options ?? []),
    ]);
    setWorkstationScreenFilterConfig({
      ...workstationScreenFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SHIFT],
        dropdownConfig[DropdownConfigKey.WORKSTATION],
      ],
    });
    setWorkerScreenTags([
      ...(dropdownConfig[DropdownConfigKey.SHIFT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER_TAG].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.CONTRACT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER_VERSATILITY].options ?? []),
      ...(workerOtherFilter ?? []),
    ]);
    setWorkerScreenFilterConfig({
      ...workerScreenFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SHIFT],
        dropdownConfig[DropdownConfigKey.WORKER],
        dropdownConfig[DropdownConfigKey.WORKER_TAG],
        dropdownConfig[DropdownConfigKey.CONTRACT],
        dropdownConfig[DropdownConfigKey.WORKER_VERSATILITY],
        dropdownConfig[DropdownConfigKey.WORKER_OTHER_FILTER],
      ],
    });
    setTrainingSessionScreenTags([
      ...(dropdownConfig[DropdownConfigKey.SHIFT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER_TAG].options ?? []),
      ...(trainingTypeOptions ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKSTATION].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.TRAINING].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.TRAINING_TAG].options ?? []),
    ]);
    setTrainingSessionScreenFilterConfig({
      ...trainingSessionScreenFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SHIFT],
        dropdownConfig[DropdownConfigKey.WORKER],
        dropdownConfig[DropdownConfigKey.WORKER_TAG],
        dropdownConfig[DropdownConfigKey.TYPE_OF_TRAINING],
        dropdownConfig[DropdownConfigKey.WORKSTATION],
        dropdownConfig[DropdownConfigKey.TRAINING],
        dropdownConfig[DropdownConfigKey.TRAINING_TAG],
      ],
    });
    setKPIScreenFilterConfig({
      ...KPIScreenFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SHIFT],
        dropdownConfig[DropdownConfigKey.WORKSTATION],
        dropdownConfig[DropdownConfigKey.TYPE_OF_TRAINING],
        dropdownConfig[DropdownConfigKey.WORKER_TAG],
        dropdownConfig[DropdownConfigKey.TRAINING],
      ],
    });
    setKPIScreenTags([
      ...(dropdownConfig[DropdownConfigKey.SHIFT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKSTATION].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.TRAINING].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER_TAG].options ?? []),
      ...(trainingTypeOptions ?? []),
    ]);

    setSkillScreenTags([
      ...(dropdownConfig[DropdownConfigKey.SKILL].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.VALIDITY].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.SKILL_TAG].options ?? []),
      ...(trainingTypeOptions ?? []),
    ]);
    setSkillScreenFilterConfig({
      ...skillScreenFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SKILL],
        dropdownConfig[DropdownConfigKey.VALIDITY],
        dropdownConfig[DropdownConfigKey.SKILL_TAG],
        dropdownConfig[DropdownConfigKey.TYPE_OF_TRAINING],
      ],
    });
    setSkillWorkerScreenTags([
      ...(dropdownConfig[DropdownConfigKey.SHIFT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER].options ?? []),
      ...(skillWorkerOtherFilter ?? []),
    ]);
    setSkillWorkerScreenFilterConfig({
      ...skillWorkerScreenFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SHIFT],
        dropdownConfig[DropdownConfigKey.WORKER],
        dropdownConfig[DropdownConfigKey.SKILL_WORKER_OTHER_FILTER],
      ],
    });
    setWorkerWorkstationTags([
      ...(dropdownConfig[DropdownConfigKey.SHIFT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.SKILL].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKSTATION].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.TRAINING].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.LEVEL].options ?? []),
    ]);
    setWorkerWorkstationFilterConfig({
      ...workerWorkstationFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SHIFT],
        dropdownConfig[DropdownConfigKey.SKILL],
        dropdownConfig[DropdownConfigKey.WORKSTATION],
        dropdownConfig[DropdownConfigKey.TRAINING],
        dropdownConfig[DropdownConfigKey.LEVEL],
      ],
    });
    setWorkerSkillTags([
      ...(dropdownConfig[DropdownConfigKey.SKILL].options ?? []),
      ...(skillValidityOptions ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKSTATION].options ?? []),
      ...(workerSkillOtherFilter ?? []),
    ]);
    setWorkerSkillFilterConfig({
      ...workerSkillFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.SKILL],
        dropdownConfig[DropdownConfigKey.SKILL_VALIDITY],
        dropdownConfig[DropdownConfigKey.WORKSTATION],
        dropdownConfig[DropdownConfigKey.WORKER_SKILL_OTHER_FILTER],
      ],
    });
    setTrainingScreenTags([
      ...(dropdownConfig[DropdownConfigKey.WORKSTATION].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.SKILL].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.TRAINING].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.TYPE_OF_TRAINING].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.TRAINING_TAG].options ?? []),
    ]);
    setTrainingFilterConfig({
      ...trainingFilterConfig,
      dropdown: [
        dropdownConfig[DropdownConfigKey.WORKSTATION],
        dropdownConfig[DropdownConfigKey.SKILL],
        dropdownConfig[DropdownConfigKey.TRAINING],
        dropdownConfig[DropdownConfigKey.TYPE_OF_TRAINING],
        dropdownConfig[DropdownConfigKey.TRAINING_TAG],
      ],
    });
    setTrainingWorkerScreenTags([
      ...(dropdownConfig[DropdownConfigKey.SHIFT].options ?? []),
      ...(dropdownConfig[DropdownConfigKey.WORKER].options ?? []),
    ]);
    setTrainingWorkerFilterConfig({
      ...trainingWorkerFilterConfig,
      dropdown: [dropdownConfig[DropdownConfigKey.SHIFT], dropdownConfig[DropdownConfigKey.WORKER]],
    });
  }

  async function fetchDataForDropdown(dataType?: API.DataType) {
    
    if (dataType === API.DataType.SHIFT || !dataType) {
      const _orgUnitsWithShift = fetchOrgUnitWithShifts();
      dropdownConfig[DropdownConfigKey.SHIFT] = {
        ...dropdownConfig[DropdownConfigKey.SHIFT],
        options: _orgUnitsWithShift,
      };
    }

    if (dataType === API.DataType.ORGUNIT || !dataType) {
      const _orgUnits = fetchOrgUnits();
      dropdownConfig[DropdownConfigKey.ORGUNIT] = {
        ...dropdownConfig[DropdownConfigKey.ORGUNIT],
        options: _orgUnits,
      };
    }

    
    if (dataType === API.DataType.WORKER || !dataType) {
      const _workers = fetchWorkers();
      dropdownConfig[DropdownConfigKey.WORKER] = {
        ...dropdownConfig[DropdownConfigKey.WORKER],
        options: _workers,
      };
    }

    
    if (dataType === API.DataType.WORKSTATION || !dataType) {
      const _workstations = fetchWorkstations();
      dropdownConfig[DropdownConfigKey.WORKSTATION] = {
        ...dropdownConfig[DropdownConfigKey.WORKSTATION],
        options: _workstations,
      };
    }

    
    if (dataType === API.DataType.WORKERTAG || !dataType) {
      const _workerTags = fetchWorkerTags();

      dropdownConfig[DropdownConfigKey.WORKER_TAG] = {
        ...dropdownConfig[DropdownConfigKey.WORKER_TAG],
        options: _workerTags,
      };
    }

    
    if (dataType === API.DataType.SKILL || !dataType) {
      const _skills = fetchSkills();

      dropdownConfig[DropdownConfigKey.SKILL] = {
        ...dropdownConfig[DropdownConfigKey.SKILL],
        options: _skills,
      };
    }

    
    if (dataType === API.DataType.SKILLTAG || !dataType) {
      const _skillTags = fetchSkillTags();

      dropdownConfig[DropdownConfigKey.SKILL_TAG] = {
        ...dropdownConfig[DropdownConfigKey.SKILL_TAG],
        options: _skillTags,
      };
    }

    
    if (dataType === API.DataType.TRAINING || !dataType) {
      const _trainings = fetchTrainings();
      dropdownConfig[DropdownConfigKey.TRAINING] = {
        ...dropdownConfig[DropdownConfigKey.TRAINING],
        options: _trainings,
      };
    }

    
    if (dataType === API.DataType.TRAININGTAG || !dataType) {
      const _trainingTags = fetchTrainingTags();
      dropdownConfig[DropdownConfigKey.TRAINING_TAG] = {
        ...dropdownConfig[DropdownConfigKey.TRAINING_TAG],
        options: _trainingTags,
      };
    }

    
    if (dataType === API.DataType.CONTRACTTYPE || !dataType) {
      const _contractTypes = fetchContractType();

      dropdownConfig[DropdownConfigKey.CONTRACT] = {
        ...dropdownConfig[DropdownConfigKey.CONTRACT],
        options: _contractTypes,
      };
      const _pausedContractTypes = fetchPausedContractType();

      dropdownConfig[DropdownConfigKey.WORKER_OTHER_FILTER] = {
        ...dropdownConfig[DropdownConfigKey.WORKER_OTHER_FILTER],
        options: [...workerOtherFilter, ...(_pausedContractTypes ?? [])],
      };
    }

    dropdownConfig[DropdownConfigKey.LEVEL] = {
      ...dropdownConfig[DropdownConfigKey.LEVEL],
      options: levelOptions,
    };

    dropdownConfig[DropdownConfigKey.TYPE_OF_TRAINING] = {
      ...dropdownConfig[DropdownConfigKey.TYPE_OF_TRAINING],
      options: trainingTypeOptions,
    };

    dropdownConfig[DropdownConfigKey.HOME_OTHER_FILTER] = {
      ...dropdownConfig[DropdownConfigKey.HOME_OTHER_FILTER],
      options: homeOtherFilter,
    };

    dropdownConfig[DropdownConfigKey.WORKER_SKILL_OTHER_FILTER] = {
      ...dropdownConfig[DropdownConfigKey.WORKER_SKILL_OTHER_FILTER],
      options: workerSkillOtherFilter,
    };

    dropdownConfig[DropdownConfigKey.SKILL_WORKER_OTHER_FILTER] = {
      ...dropdownConfig[DropdownConfigKey.SKILL_WORKER_OTHER_FILTER],
      options: skillWorkerOtherFilter,
    };

    dropdownConfig[DropdownConfigKey.SKILL_VALIDITY] = {
      ...dropdownConfig[DropdownConfigKey.SKILL_VALIDITY],
      options: skillValidityOptions,
    };

    updateDropdownConfig(dropdownConfig);
    setDropdownConfig(dropdownConfig);
  }

  function fetchWorkstations(): TagExtended[] | undefined {
    const _workstations: TagExtended[] | undefined = [];
    workstations.forEach(eachWorkstation => {
      _workstations.push({
        key: eachWorkstation.id,
        label: eachWorkstation.name,
        value: eachWorkstation,
        type: DropdownConfigKey.WORKSTATION,
        tagIcon: WorkstationIcon,
        tagPath: [API.DataType.WORKSTATION, eachWorkstation.pathIds.slice(0, -1)], 
        color: workstationTagColor,
        order: FilterTagOrder.Workstation,
      });
    });

    return _workstations;
  }

  function fetchTrainings(): TagExtended[] | undefined {
    const _trainings = trainings
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(eachTraining => {
        return {
          key: eachTraining.id,
          label: eachTraining.name,
          value: eachTraining,
          type: DropdownConfigKey.TRAINING,
          tagIcon: TrainingIcon,
          color: trainingTagColor,
          order: FilterTagOrder.Training,
        };
      });
    return _trainings;
  }

  function fetchWorkers(): TagExtended[] | undefined {
    const _workers = sortWorkers([...workers, ...archivedWorkers]).map(eachWorker => {
      return {
        key: eachWorker.id,
        label: eachWorker.name,
        value: eachWorker,
        type: DropdownConfigKey.WORKER,
        tagIcon: WorkerIcon,
        color: workerTagColor,
        order: FilterTagOrder.Worker,
      };
    });

    return _workers;
  }

  function getTagObject(treeNode: API.TreeNode): TagExtended {
    return {
      ...treeNode,
      key: treeNode.id,
      label: treeNode.object.name,
      value: treeNode,
      children: _.map(treeNode.children, getTagObject),
      type: DropdownConfigKey.ORGUNIT,
      tagIcon: UnitIcon,
      tagPath: [API.DataType.ORGUNIT, treeNode.parentIds],
      color: orgUnitTagColor,
      order: FilterTagOrder.OrganizationUnit,
    };
  }

  function fetchOrgUnits(): TagExtended[] | undefined {
    return _.map(API.Tree.getOrganizationalUnitTreeStructure(), child => {
      return getTagObject(child);
    });
  }

  function fetchOrgUnitWithShifts(): TagExtended[] | undefined {
    return _.map(API.Tree.getOrganizationalUnitTreeStructure(), child => {
      return getTreeNodeWithShiftTag(child, shifts);
    });
  }

  function fetchWorkerTags(): TagExtended[] | undefined {
    const _workerTags = workerTags.map(eachTag => {
      return {
        key: eachTag.id,
        label: eachTag.name,
        value: eachTag,
        type: DropdownConfigKey.WORKER_TAG,
        tagIcon: WorkerTagIcon,
        color: workerTagsTagColor,
        order: FilterTagOrder.WorkerTag,
      };
    });
    return _.sortBy(_workerTags, _workerTag => _workerTag.label.toLowerCase());
  }

  function fetchSkills(): TagExtended[] | undefined {
    const _skills = skills.map(eachSkill => {
      return {
        key: eachSkill.id,
        label: eachSkill.name,
        value: eachSkill,
        type: DropdownConfigKey.SKILL,
        tagIcon: SkillIcon,
        color: skillTagColor,
        order: FilterTagOrder.Skill,
      };
    });
    return _skills;
  }

  function fetchSkillTags(): TagExtended[] | undefined {
    const _skillTags = skillTags.map(eachTag => {
      return {
        key: eachTag.id,
        label: eachTag.name,
        value: eachTag,
        type: DropdownConfigKey.SKILL_TAG,
        color: skillTagsTagColor,
        order: FilterTagOrder.SkillTag,
      };
    });
    return _.sortBy(_skillTags, _skillTag => _skillTag.label.toLowerCase());
  }

  function fetchTrainingTags(): TagExtended[] | undefined {
    const _trainingTags = trainingTags.map(eachTag => {
      return {
        key: eachTag.id,
        label: eachTag.name,
        value: eachTag,
        type: DropdownConfigKey.TRAINING_TAG,
        color: trainingTagsTagColor,
        order: FilterTagOrder.TrainingTag,
      };
    });
    return _.sortBy(_trainingTags, _trainingTag => _trainingTag.label.toLowerCase());
  }

  function fetchContractType(): TagExtended[] | undefined {
    const _contractTypes = contractTypes.map(eachContract => {
      return {
        key: eachContract.id,
        label: eachContract.name,
        value: eachContract,
        type: DropdownConfigKey.CONTRACT,
        color: contractTagColor,
        order: FilterTagOrder.Others,
      };
    });
    return _contractTypes;
  }

  function fetchPausedContractType(): TagExtended[] | undefined {
    const _pausedContractTypes = pausedContractTypes.map(eachContract => {
      return {
        key: eachContract.id,
        label: eachContract.name,
        value: eachContract,
        type: DropdownConfigKey.CONTRACT,
        color: contractTagColor,
        order: FilterTagOrder.Others,
      };
    });
    return _pausedContractTypes;
  }

  async function setAction(location: LocationDescriptor) {
    if (!worker) {
      logger.debug('First signIn and create a TenantApp');
      return;
    }

    props.history.push(location);
  }

  const handleWarningAccept = async () => {
    const result = await UserContext.signOut();
    if (API.isFailure(result)) {
      
      logger.warn('loging out error', result);
    }
    history.push(RouteLocations.Home());
  };

  function showLogOutModal() {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('common:auth.logout.header'),
        warningAcceptButton: t('common:auth.logout.ok'),
        warningAcceptCallback: () => handleWarningAccept(),
      }),
    );
  }

  function currentSavedTags(): TagExtended[] {
    if (currentRoute === RoutePaths.Home) {
      return homeScreenFilterTags;
    } else if (currentRoute === RoutePaths.Workstations) {
      return workstationScreenFilterTags;
    } else if (currentRoute === RoutePaths.Workers) {
      return workerScreenFilterTags;
    } else if (currentRoute === RoutePaths.Skills) {
      return skillScreenFilterTags;
    } else if (currentRoute === RoutePaths.SkillProfile) {
      if (currentTabIndex === 0) {
        return skillWorkerScreenFilterTags;
      }
    } else if (currentRoute === RoutePaths.TrainingSessions) {
      return trainingSessionScreenFilterTags;
    } else if (currentRoute === RoutePaths.WorkerProfile) {
      if (currentTabIndex === WorkerProfileTabs.Workstation) {
        return workerWorkstationScreenFilterTags;
      } else if (currentTabIndex === WorkerProfileTabs.Skill) {
        return workerSkillScreenFilterTags;
      }
    } else if (currentRoute === RoutePaths.Trainings) {
      return trainingScreenFilterTags;
    } else if (currentRoute === RoutePaths.KPI) {
      return KPIScreenFilterTags;
    } else if (currentRoute === RoutePaths.TrainingProfile) {
      if (currentTabIndex === 0) {
        return trainingWorkerScreenFilterTags;
      }
    }
    return [];
  }

  function currentTags(): TagExtended[] {
    if (currentRoute === RoutePaths.Home) {
      return homeScreenTags;
    } else if (currentRoute === RoutePaths.Workstations) {
      return workstationScreenTags;
    } else if (currentRoute === RoutePaths.Workers) {
      return workerScreenTags;
    } else if (currentRoute === RoutePaths.Skills) {
      return skillScreenTags;
    } else if (currentRoute === RoutePaths.SkillProfile) {
      if (currentTabIndex === 0) {
        return skillWorkerScreenTags;
      }
    } else if (currentRoute === RoutePaths.TrainingSessions) {
      return trainingSessionScreenTags;
    } else if (currentRoute === RoutePaths.WorkerProfile) {
      if (currentTabIndex === WorkerProfileTabs.Workstation) {
        return workerWorkstationTags;
      } else if (currentTabIndex === WorkerProfileTabs.Skill) {
        return workerSkillTags;
      }
    } else if (currentRoute === RoutePaths.Trainings) {
      return trainingScreenTags;
    } else if (currentRoute === RoutePaths.KPI) {
      return KPIScreenTags;
    } else if (currentRoute === RoutePaths.TrainingProfile) {
      if (currentTabIndex === 0) {
        return trainingWorkerScreenTags;
      }
    }

    return [];
  }

  function currentConfig(): FilterConfig | undefined {
    if (currentRoute === RoutePaths.Home) {
      return homeScreenFilterConfig;
    }
    if (currentRoute === RoutePaths.Workstations) {
      return workstationScreenFilterConfig;
    } else if (currentRoute === RoutePaths.Workers) {
      return workerScreenFilterConfig;
    } else if (currentRoute === RoutePaths.Skills) {
      return skillScreenFilterConfig;
    } else if (currentRoute === RoutePaths.SkillProfile) {
      if (currentTabIndex === 0) {
        return skillWorkerScreenFilterConfig;
      }
    } else if (currentRoute === RoutePaths.TrainingSessions) {
      return trainingSessionScreenFilterConfig;
    } else if (currentRoute === RoutePaths.KPI) {
      return KPIScreenFilterConfig;
    } else if (currentRoute === RoutePaths.WorkerProfile) {
      if (currentTabIndex === WorkerProfileTabs.Workstation) {
        return workerWorkstationFilterConfig;
      } else if (currentTabIndex === WorkerProfileTabs.Skill) {
        return workerSkillFilterConfig;
      }
    } else if (currentRoute === RoutePaths.Trainings) {
      return trainingFilterConfig;
    } else if (currentRoute === RoutePaths.TrainingProfile) {
      if (currentTabIndex === 0) {
        return trainingWorkerFilterConfig;
      }
    }

    return undefined;
  }

  function needToSaveValue(type?: string): boolean {
    switch (type) {
      case DropdownConfigKey.WORKER:
      case DropdownConfigKey.WORKSTATION:
      case DropdownConfigKey.LEVEL:
      case DropdownConfigKey.WORKER_TAG:
      case DropdownConfigKey.SKILL_TAG:
      case DropdownConfigKey.TRAINING_TAG:
      case DropdownConfigKey.SKILL:
      case DropdownConfigKey.CONTRACT:
      case DropdownConfigKey.TRAINING:
        return false;
      default:
        return true;
    }
  }

  async function onSaveTags(tags: TagExtended[]) {
    
    
    const _tags = tags.map(eachTag => {
      if (eachTag.isKeywordTag || eachTag.isBookmarkTag) {
        const tagChildren = (eachTag.children ?? []).map((child: TagExtended) => {
          if (child.type && child.type === API.DataType.ORGUNIT) {
            child.children = [];
            child.value.children = [];
            if (child.value?.extraSelector) child.value.extraSelector = {};
          }

          return {
            key: child.key,
            value: needToSaveValue(child.type) ? child.value : undefined, 
            label: child.label,
            type: child.type,
            color: child.color,
            order: child.order,
            tagPath: child.tagPath,
          };
        });

        eachTag.children = tagChildren;
      }

      if (eachTag.type && eachTag.type === API.DataType.ORGUNIT) {
        eachTag.children = [];
        eachTag.value.children = [];
        if (eachTag.value?.extraSelector) eachTag.value.extraSelector = {};
      }

      if (eachTag.isBookmarkTag || eachTag.isCheckBoxTag || eachTag.isKeywordTag) {
        return eachTag;
      }

      return {
        key: eachTag.key,
        value: needToSaveValue(eachTag.type) ? eachTag.value : undefined, 
        label: eachTag.label,
        type: eachTag.type,
        color: eachTag.color,
        order: eachTag.order,
        tagPath: eachTag.tagPath,
      };
    });

    if (currentRoute === RoutePaths.Home) {
      filterTags.set(UserPreferenceKeys_SkillMgtApp.DashboardFilter, _tags);
      setFilterTags(filterTags);
      setLoader(true);
      const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.DashboardFilter,
        filterTags,
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        setLoader(false);
        logger.warn('saveFilter: error in saving dashboard user Preference', result);
        return;
      }

      setLoader(false);
      setHomeScreenFilterTags([...tags]);

      const savedResult = await API.saveUserPreference<DashboardScrollViewOffset>(
        UserPreferenceKeys_SkillMgtApp.DashboardWorkstationLevelScrollViewOffset,
        {
          workstationsScrollViewOffset: 0,
          workersScrollViewOffset: 0,
        },
      );
      if (!isMounted.current) return;
      if (API.isFailure(savedResult)) {
        logger.warn('error in saving user Preference', savedResult);
        return;
      }
    } else if (currentRoute === RoutePaths.Workstations) {
      filterTags.set(UserPreferenceKeys_SkillMgtApp.WorkstationFilter, tags);
      setFilterTags(filterTags);
      setLoader(true);

      const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.WorkstationFilter,
        filterTags,
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        setLoader(false);
        logger.warn('saveFilter: error in saving workstation screen user Preference', result);
        return;
      }

      setLoader(false);
      setWorkstationScreenFilterTags([...tags]);
    } else if (currentRoute === RoutePaths.Workers) {
      filterTags.set(UserPreferenceKeys_SkillMgtApp.WorkerCatalogFilter, tags);
      setFilterTags(filterTags);
      setLoader(true);

      const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.WorkerCatalogFilter,
        filterTags,
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        setLoader(false);
        logger.warn('saveFilter: error in saving worker screen user Preference', result);
        return;
      }

      setLoader(false);
      setWorkerScreenFilterTags([...tags]);
    } else if (currentRoute === RoutePaths.Skills) {
      filterTags.set(UserPreferenceKeys_SkillMgtApp.SkillCatalogFilter, tags);
      setFilterTags(filterTags);
      setLoader(true);

      const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.SkillCatalogFilter,
        filterTags,
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        setLoader(false);
        logger.warn('saveFilter: error in saving skill screen user Preference', result);
        return;
      }

      setLoader(false);
      setSkillScreenFilterTags([...tags]);
    } else if (currentRoute === RoutePaths.SkillProfile) {
      if (currentTabIndex === 0) {
        if (tags.find(tag => tag.type === DropdownConfigKey.SKILL_WORKER_OTHER_FILTER)) {
          tags = tags.filter(tag => tag.type === DropdownConfigKey.SKILL_WORKER_OTHER_FILTER);
        }
        filterTags.set(UserPreferenceKeys_SkillMgtApp.SkillWorkerCatalogFilter, tags);
        setFilterTags(filterTags);
        setLoader(true);

        const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
          UserPreferenceKeys_SkillMgtApp.SkillWorkerCatalogFilter,
          filterTags,
        );
        if (!isMounted.current) return;
        if (API.isFailure(result)) {
          setLoader(false);
          logger.warn('saveFilter: error in saving skill profile user Preference', result);
          return;
        }

        setLoader(false);
        setSkillWorkerScreenFilterTags([...tags]);
      }
    } else if (currentRoute === RoutePaths.TrainingSessions) {
      filterTags.set(UserPreferenceKeys_SkillMgtApp.TrainingSessionFilter, tags);
      setFilterTags(filterTags);
      setLoader(true);

      const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.TrainingSessionFilter,
        filterTags,
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        setLoader(false);
        logger.warn('saveFilter: error in saving training session screen user Preference', result);
        return;
      }

      setLoader(false);
      setTrainingSessionScreenFilterTags([...tags]);
    } else if (currentRoute === RoutePaths.Trainings) {
      filterTags.set(UserPreferenceKeys_SkillMgtApp.TrainingFilter, tags);
      setFilterTags(filterTags);
      setLoader(true);

      const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.TrainingFilter,
        filterTags,
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        setLoader(false);
        logger.warn('saveFilter: error in saving training screen user Preference', result);
        return;
      }

      setLoader(false);
      setTrainingScreenFilterTags([...tags]);
    } else if (currentRoute === RoutePaths.KPI) {
      filterTags.set(UserPreferenceKeys_SkillMgtApp.KPIFilter, tags);
      setFilterTags(filterTags);
      setLoader(true);

      const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.KPIFilter,
        filterTags,
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        setLoader(false);
        logger.warn('saveFilter: error in saving KPI screen user Preference', result);
        return;
      }

      setLoader(false);
      setKPIScreenFilterTags([...tags]);
    } else if (currentRoute === RoutePaths.WorkerProfile) {
      if (currentTabIndex === WorkerProfileTabs.Workstation) {
        filterTags.set(UserPreferenceKeys_SkillMgtApp.WorkerWorkstationCatalogFilter, tags);
        setFilterTags(filterTags);
        setLoader(true);

        const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
          UserPreferenceKeys_SkillMgtApp.WorkerWorkstationCatalogFilter,
          filterTags,
        );
        if (!isMounted.current) return;
        if (API.isFailure(result)) {
          setLoader(false);
          logger.warn('saveFilter: error in saving worker profile screen user Preference', result);
          return;
        }

        setLoader(false);
        setWorkerWorkstationScreenFilterTags([...tags]);
      } else if (currentTabIndex === WorkerProfileTabs.Skill) {
        if (tags.find(tag => tag.type === DropdownConfigKey.WORKER_SKILL_OTHER_FILTER)) {
          tags = tags.filter(tag => tag.type === DropdownConfigKey.WORKER_SKILL_OTHER_FILTER);
        }
        filterTags.set(UserPreferenceKeys_SkillMgtApp.WorkerSkillCatalogFilter, tags);
        setFilterTags(filterTags);
        setLoader(true);

        const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
          UserPreferenceKeys_SkillMgtApp.WorkerSkillCatalogFilter,
          filterTags,
        );
        if (!isMounted.current) return;
        if (API.isFailure(result)) {
          setLoader(false);
          logger.warn('saveFilter: error in saving worker skill screen user Preference', result);
          return;
        }

        setLoader(false);
        setWorkerSkillScreenFilterTags([...tags]);
      }
    } else if (currentRoute === RoutePaths.TrainingProfile) {
      if (currentTabIndex === 0) {
        filterTags.set(UserPreferenceKeys_SkillMgtApp.TrainingWorkerFilter, tags);
        setFilterTags(filterTags);
        setLoader(true);

        const result = await API.saveUserPreference<Map<string, TagExtended[]>>(
          UserPreferenceKeys_SkillMgtApp.TrainingWorkerFilter,
          filterTags,
        );
        if (!isMounted.current) return;
        if (API.isFailure(result)) {
          setLoader(false);
          logger.warn(
            'saveFilter: error in saving trainings worker screen user Preference',
            result,
          );
          return;
        }

        setLoader(false);
        setTrainingWorkerScreenFilterTags([...tags]);
      }
    }
  }

  function onTagSelected(tags: TagExtended[]) {
    let _filterConfig: FilterConfig | undefined;

    if (currentRoute === RoutePaths.Workers) _filterConfig = workerScreenFilterConfig;
    else if (currentRoute === RoutePaths.Home) _filterConfig = homeScreenFilterConfig;
    if (!_filterConfig) return;

    const index = (_filterConfig.checkBox ?? []).findIndex(
      checkBox => checkBox.key === CheckBoxConfigKey.SHOW_SELECTED_ORG_UNITS,
    );

    if (
      tags.find(
        tag =>
          tag.type === DropdownConfigKey.ORGUNIT ||
          tag.type === DropdownConfigKey.SHIFT ||
          tag.type === DropdownConfigKey.WORKSTATION,
      )
    ) {
      if (index > -1) {
        _filterConfig.checkBox![index].disabled = false;
      }
    } else {
      if (index > -1) {
        _filterConfig.checkBox![index].disabled = true;
      }
    }

    if (currentRoute === RoutePaths.Workers) {
      setWorkerScreenFilterConfig(_filterConfig);
    } else if (currentRoute === RoutePaths.Home) {
      setHomeScreenFilterConfig(_filterConfig);
    }
  }

  return (
    <Animated.View
      style={[
        styles.header,
        {
          zIndex: headerZIndex,
        },
      ]}
    >
      {usersModal && (
        <InviteWorkerModalFilterProvider>
          <UsersModal showUsersModal={showUsersModal} />
        </InviteWorkerModalFilterProvider>
      )}
      {myAccountModal && <MyAccount showHideModal={showMyAccountModal} />}
      {worker && (
        <Header
          {...props}
          setAction={setAction}
          openLogoutBox={showLogOutModal}
          showUsersModal={showUsersModal}
          showMyAccountModal={showMyAccountModal}
          worker={worker}
          loader={loader}
          filter={{
            disableFilter,
            hideFilter,
            filterConfig: currentConfig(),
            tags: currentTags(),
            onSaveTags: onSaveTags,
            savedTags: currentSavedTags(),
            onTagSelected: onTagSelected,
          }}
        />
      )}
    </Animated.View>
  );
};
