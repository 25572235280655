import React from 'react';
import { KeyboardAvoidingView, StyleProp, ViewStyle, Platform } from 'react-native';

interface Props {
  children: React.ReactElement | null;
  style?: StyleProp<ViewStyle>;
}

export const KeyboardAvoidingViewWrapper = ({ children, style }: Props) => (
  <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={style}>
    {children}
  </KeyboardAvoidingView>
);
