import React, { useState, useContext, useEffect } from 'react';
import { TouchableOpacity, View, Text, ScrollView, StyleProp, ViewStyle } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { LevelSkillsPaneContent, UnauthorizedUnit } from './index';
import { LevelSkillTouchableRow } from './LevelSkillTouchableRow';
import { SkillsFilter } from 'skillmgtweb/src/components/my-factory/skills/components/SkillsFilter';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import Styles from './style';
import { t } from 'shared/localisation/i18n';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';

interface Props {
  isLoading: boolean;
  addedSkills: string[];
  levelSkillsPaneContents: Map<API.WorkstationWorkerLevels, LevelSkillsPaneContent> | null;
  requirementLevel: API.WorkstationWorkerLevels;
  treeNode?: API.TreeNode;
  style?: StyleProp<ViewStyle>;
  onPressSkill: (skill: API.Skill) => void;
  onDeleteSkill: (skill: API.Skill, requirement: API.Requirement) => void;
  setSourceRequirement: (requirement: API.Requirement | null | undefined) => void;
  setSourceRequirementLevel: (level: API.WorkstationWorkerLevels) => void;
  addSkillAndUpdateRequirment: (skills: API.Skill[]) => void;
  onCreateSkill: (filterInput?: string) => void;
}

const plusIconSmall = require('shared/assets/svg/icon.plus.small.svg').default;

interface InheritedLinkedObjectWithSkill {
  linkedObject?: API.OrganizationalUnit | API.Workstation | UnauthorizedUnit;
  skill: API.Skill;
}

export const RequirementSkillColumn: React.FC<Props> = props => {
  const {
    isLoading,
    addedSkills,
    levelSkillsPaneContents,
    requirementLevel,
    style,
    treeNode,
    onPressSkill,
    onCreateSkill,
    onDeleteSkill,
    setSourceRequirement,
    setSourceRequirementLevel,
    addSkillAndUpdateRequirment,
  } = props;

  const { isValidPermission } = useContext(PermissionManagementContext);

  const [isRequirementSkillsFilterOpen, setIsRequirementSkillsFilterOpen] = useState(false);
  const [skillsWithLinkedUnitName, setSkillsLinkedUnitName] = useState<
    Map<string, InheritedLinkedObjectWithSkill>
  >(new Map());
  const [isHover, setIsHover] = useState(false);

  const ref = useCallOnHover<ScrollView>(
    SharedStyles.Colors.White,
    () => setIsHover(true),
    () => setIsHover(false),
  );

  useEffect(() => {
    const _skillsWithLinkedUnitName: Map<string, InheritedLinkedObjectWithSkill> = new Map();
    const _skills = levelSkillsPaneContents?.get(requirementLevel)?.levelSkills.skills || [];
    _skills.forEach(eachSkill => _skillsWithLinkedUnitName.set(eachSkill.id, { skill: eachSkill }));

    levelSkillsPaneContents?.get(requirementLevel)?.inheritedLevelSkills.map(eachInheritedSkill => {
      const _inheritedSkills = eachInheritedSkill.skills;
      _inheritedSkills.forEach(_eachInheritedSkill =>
        _skillsWithLinkedUnitName.set(_eachInheritedSkill.id, {
          skill: _eachInheritedSkill,
          linkedObject: eachInheritedSkill.linkedObject,
        }),
      );
    });
    setSkillsLinkedUnitName(_skillsWithLinkedUnitName);
  }, [levelSkillsPaneContents, requirementLevel]);

  if (!levelSkillsPaneContents) return null;

  return (
    <View style={[Styles.requirementTableInnerExtraContainer, style]}>
      {isRequirementSkillsFilterOpen && (
        <SkillsFilter
          addCreateItemRow
          disabledSkillIds={addedSkills}
          containerStyle={Styles.skillFilterContainerStyle}
          onCreateSkill={onCreateSkill}
          setIsSkillsFilterOpen={setIsRequirementSkillsFilterOpen}
          onSave={addSkillAndUpdateRequirment}
        />
      )}
      {isLoading && (
        <View style={Styles.requirementTableLevel2LoadingBanner}>
          <LoaderThreeDots lowPerformance={false} />
        </View>
      )}
      <View style={[Styles.requirementTableLevel2Header]}>
        <Text style={[Styles.requirementTableLevel2HeaderTextStyle]}>
          {API.getWorkstationWorkerLevelLabel(requirementLevel)}
        </Text>
        {isValidPermission(API.Permission.workstations_edit, treeNode) && (
          <TouchableOpacity
            onPress={() => {
              setSourceRequirementLevel(requirementLevel);
              setSourceRequirement(
                levelSkillsPaneContents.get(requirementLevel)?.levelSkills.sourceRequirement,
              );
              setIsRequirementSkillsFilterOpen(true);
            }}
          >
            <IconSVG
              svgComponent={plusIconSmall}
              size={{ width: 24, height: 24 }}
              color={SharedStyles.Colors.White}
              containerStyle={Styles.plusIconContainer}
            />
          </TouchableOpacity>
        )}
      </View>
      {skillsWithLinkedUnitName.size ? (
        <ScrollView
          ref={ref}
          style={[Styles.requirementTableLevel2ScrollView]}
          showsVerticalScrollIndicator={isHover}
          contentContainerStyle={Styles.scrollViewContentContainer}
        >
          {_.map(
            Array.from(skillsWithLinkedUnitName.entries()),
            (skillWithLinkedUnit, skillIndex) => {
              return (
                <View
                  key={skillWithLinkedUnit[0]}
                  style={{
                    zIndex: 999 - skillIndex,
                  }}
                >
                  <LevelSkillTouchableRow
                    treeNode={treeNode}
                    skillIndex={skillIndex}
                    skill={skillWithLinkedUnit[1].skill}
                    iconBackgroundColor={
                      skillWithLinkedUnit[1].linkedObject
                        ? SharedStyles.Colors.GreyLight
                        : SharedStyles.Colors.DimGray
                    }
                    onPressSkill={onPressSkill}
                    inheritedUnit={skillWithLinkedUnit[1].linkedObject}
                    onDelete={() => {
                      onDeleteSkill(
                        skillWithLinkedUnit[1].skill,
                        levelSkillsPaneContents.get(requirementLevel)?.levelSkills
                          .sourceRequirement!,
                      );
                    }}
                  />
                </View>
              );
            },
          )}
        </ScrollView>
      ) : (
        <Text style={Styles.noSkillTextStyle}>
          {t('alex:workstations.workstationPanel.requirementTable.requirementSkillColumn.noSkill', {
            level: requirementLevel,
          })}
        </Text>
      )}
    </View>
  );
};
