import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import logger from 'shared/util/Logger';
import { DataType, WorkstationWorkerLevel } from 'backend/src/api';
import Aigle from 'aigle';
import {
  createTrainingSessionRequests,
  removeWorkerFromTrainingSessionsAndUpdate,
} from './TrainingSession';
import { t } from '../localisation/i18n';
import moment, { Moment } from 'moment';
import { Immutable, isSameStringArray } from '../util-ts/Functions';

/** PERF parameter (idealy = 0 but tweaked to 2000ms in 04/2022 for better UI responsivness. It should be readjusted from time to time) */
export const PERF_workersWorkstationsUpdateDebounceInMs = 2000;

/**
 *
 */

export enum WorkstationWorkerLevels {
  LEVEL0 = 0,
  LEVEL1 = 1,
  LEVEL2 = 2,
  LEVEL3 = 3,
  LEVEL4 = 4,
}
export const api2workstationWorkerLevels = (
  level: WorkstationWorkerLevel,
): WorkstationWorkerLevels => {
  switch (level) {
    case WorkstationWorkerLevel.LEVEL0:
      return WorkstationWorkerLevels.LEVEL0;
    case WorkstationWorkerLevel.LEVEL1:
      return WorkstationWorkerLevels.LEVEL1;
    case WorkstationWorkerLevel.LEVEL2:
      return WorkstationWorkerLevels.LEVEL2;
    case WorkstationWorkerLevel.LEVEL3:
      return WorkstationWorkerLevels.LEVEL3;
    case WorkstationWorkerLevel.LEVEL4:
      return WorkstationWorkerLevels.LEVEL4;
  }
};
export const workstationWorkerLevels2api = (level: number): WorkstationWorkerLevel => {
  switch (level) {
    case 0:
      return WorkstationWorkerLevel.LEVEL0;
    case 1:
      return WorkstationWorkerLevel.LEVEL1;
    case 2:
      return WorkstationWorkerLevel.LEVEL2;
    case 3:
      return WorkstationWorkerLevel.LEVEL3;
    case 4:
      return WorkstationWorkerLevel.LEVEL4;
    default:
      logger.error('Level ' + level + ' is not a valid level');
      return WorkstationWorkerLevel.LEVEL0;
  }
};

/**
 * Use this function to get the (user customized) level label (see glossary)
 * like "Level 1" or "Level apprentice"
 * @param onlyValue if true it removes the "label " prefix
 */
export function getWorkstationWorkerLevelLabel(
  level: WorkstationWorkerLevels | WorkstationWorkerLevel | null | undefined,
  onlyValue?: boolean,
  capitalizeFirstLetter: boolean = true,
): string {
  if (!level) return '';

  let _level: WorkstationWorkerLevels;
  if (typeof level === 'string') {
    _level = api2workstationWorkerLevels(level as WorkstationWorkerLevel); 
  } else {
    _level = level;
  }

  if (onlyValue) {
    return '' + _level;
  } else {
    return t('glossary:level_' + _level, undefined, capitalizeFirstLetter);
  }
}

/**
 * NOT TO BE USED BY COMPONENTS (Creation of the WorkerWorkstation objects is handled by the backend)
 * @param workstationId
 * @param workerId
 * @returns
 */
export async function createWorkerWorkstation(
  workstationId: string,
  workerId: string,
): Promise<API.Result<API.WorkerWorkstation>> {
  if (API.enableGlobalLevelComputation) {
    const workstation = await API.getWorkstation(workstationId);
    if (API.isFailure(workstation)) return workstation;

    const _isWorkerWorkstationShouldBeComputed = await API.isWorkerAssignedToOrganizationalUnit(
      workerId,
      workstation.parentId,
    );
    if (API.isFailure(_isWorkerWorkstationShouldBeComputed))
      return _isWorkerWorkstationShouldBeComputed;
    if (!_isWorkerWorkstationShouldBeComputed) {
      const errorMessage =
        'Worker level on Workstation shall not be computed for outside assigned OrgUnits. Please check which component is calling this function with wrong workerId/workstaionId couple: ' +
        workerId +
        '/' +
        workstationId;
      return API.createFailure('ObjectNotFound', errorMessage);
    }
  }

  const workerWorkstationInput: API.WorkerWorkstationCreateInput = {
    workstationId: workstationId,
    workerId: workerId,
    isTrainAuto: false,
    previousLevel: WorkstationWorkerLevel.LEVEL0,
    targetLevel: null,
    warning: null,
    activeTrainingSessions: {
      workstationActiveTrainingSessions: {
        lowerOrEqualToTarget: {
          fromInheritedRequirements: [],
          fromNonInheritedRequirements: [],
        },
        greaterThanTarget: {
          fromInheritedRequirements: [],
          fromNonInheritedRequirements: [],
        },
      },
      nonWorkstationActiveTrainingSessions: {
        lowerOrEqualToTarget: {
          fromInheritedRequirements: [],
          fromNonInheritedRequirements: [],
        },
        greaterThanTarget: {
          fromInheritedRequirements: [],
          fromNonInheritedRequirements: [],
        },
      },
    },
  };

  const factory = await API.createFactoryBusinessObject(
    API.DataType.WORKERWORKSTATION,
    workerWorkstationInput,
  );
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.workerWorkstation,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function silenceWorkerWorkstationWarning(
  workerWorkstation: API.WorkerWorkstation,
): Promise<API.Result<void>> {
  const workerWorkstationUpdateInput = silenceWarning(workerWorkstation);
  if (API.isFailure(workerWorkstationUpdateInput)) return workerWorkstationUpdateInput;

  const result2 = await updateWorkerWorkstation(workerWorkstationUpdateInput);
  if (API.isFailure(result2)) return result2;
}

/**
 * Silent warning on the given workerWorkstationUpdateInput
 * @param workerWorkstationUpdateInput
 * @returns
 */
function silenceWarning(
  workerWorkstationUpdateInput: Immutable<API.WorkerWorkstationUpdateInput>,
): API.Result<API.WorkerWorkstationUpdateInput> {
  const input = API.deepClone(workerWorkstationUpdateInput);

  if (
    input.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON ||
    input.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRED
  ) {
    input.warning = API.WorkstationWorkerLevelTargetWarning.SILENT;
  } else {
    logger.warn(
      "removeWorkerLevelWarning function shall not be called when warning is '" +
        input.warning +
        "'",
    );
  }

  return input;
}

export async function unSilenceWorkerWorkstationWarning(
  workerWorkstation: API.WorkerWorkstation,
): Promise<API.Result<void>> {
  if (workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.SILENT) {
    const _workerWorkstation = API.getWorkerWorkstations(
      workerWorkstation.workstationId,
      workerWorkstation.workerId,
    );
    if (!_workerWorkstation) return;

    const __workerWorkstation = API.deepClone(_workerWorkstation);
    __workerWorkstation.warning = null;

    const result = await updateWorkerWorkstation(__workerWorkstation);
    if (API.isFailure(result)) return result;
  }
}

/**
 * Stop training the given Worker on the given Workstation.
 * Returns the list of TrainingSession request canceled for the Worker on the given Workstation
 * @param workerWorkstation
 * @param stopTrainings : stop trainings linked to this target
 */
export async function removeTargetWorkerAndStopTrainings(
  workerWorkstation: API.WorkerWorkstation,
  stopTrainings: boolean,
): Promise<API.Result<API.TrainingSession[]>> {
  const _workerWorkstation = API.deepClone(workerWorkstation);

  _workerWorkstation.targetLevel = null;

  _workerWorkstation.warning = null;

  if (API.isWorkerInTrainAutoOnWorkstation(workerWorkstation)) {
    _workerWorkstation.isTrainAuto = false;
  }

  const result = await API.updateWorkerWorkstation(_workerWorkstation);
  if (API.isFailure(result)) return result;

  if (!stopTrainings) return [];

  return removeWorkerFromTrainingSessionsAndUpdate(
    workerWorkstation.workerId,
    workerWorkstation.workstationId,
  );
}

/**
 * Set Wortker target on a Workstation.
 * @param workstationId
 * @param workerId
 * @param targetLevel
 * @param autoTrain (default false)
 */
export async function setTarget(
  workstationId: string,
  workerId: string,
  targetLevel: WorkstationWorkerLevels,
  autoTrain: boolean = false,
): Promise<API.Result<API.WorkerWorkstation>> {
  const workerWorkstation = getWorkerWorkstations(workstationId, workerId);
  if (!workerWorkstation)
    return API.createFailure(
      'ObjectNotFound',
      `WorkerWorkstation not found while it should exist (workerId:(${workerId} workstationId:(${workstationId})`,
    );

  const workerWorkstationUpdateInput = API.deepClone(workerWorkstation);

  workerWorkstationUpdateInput.isTrainAuto = autoTrain;
  workerWorkstationUpdateInput.targetLevel = workstationWorkerLevels2api(targetLevel);

  return API.updateWorkerWorkstation(workerWorkstationUpdateInput);
}

/**
 * Set Wortker target on a Workstation and start the trainings (only the Workstation trainings, not orgUnit trainings).
 * @param workstationId
 * @param workerId
 * @param targetLevel
 * @param autoTrain (default false)
 * @param selectedTrainingVersionids @see createTrainingSessionRequests
 * @param comments @see createTrainingSessionRequests
 * @param endDateLimit @see createTrainingSessionRequests
 */
export async function setTargetAndTrainWorker(
  workstationId: string,
  workerId: string,
  targetLevel: WorkstationWorkerLevels,
  autoTrain: boolean = false,
  selectedTrainingVersionids?: string[],
  comments?: string,
  endDateLimit?: string,
): Promise<API.Result<API.TrainingSession[]>> {
  const result = await setTarget(workstationId, workerId, targetLevel, autoTrain);
  if (API.isFailure(result)) return result;

  return createTrainingSessionRequests(
    workstationId,
    workerId,
    targetLevel,
    true,
    selectedTrainingVersionids,
    comments,
    endDateLimit,
  );
}

export async function updateWorkerWorkstation(
  workerWorkstation: API.WorkerWorkstationUpdateInput,
): Promise<API.Result<API.WorkerWorkstation>> {
  const factory = await API.updateFactoryBusinessObject(
    API.DataType.WORKERWORKSTATION,
    workerWorkstation,
  );
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.workerWorkstation,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function getWorkerWorkstationActiveTrainingSessionsEstimatedEndDate(
  workerWorkstation: API.WorkerWorkstation,
): Promise<undefined | string> {
  const _trainingSessionIds: string[] = [];
  [
    ...workerWorkstation.activeTrainingSessions.workstationActiveTrainingSessions
      .lowerOrEqualToTarget.fromNonInheritedRequirements,
    ...workerWorkstation.activeTrainingSessions.workstationActiveTrainingSessions
      .lowerOrEqualToTarget.fromInheritedRequirements,
  ].forEach(trainingSessionId => {
    _trainingSessionIds.push(trainingSessionId);
  });

  return await getTrainingSessionsEstimatedEndDate(_trainingSessionIds);
}

export async function getTrainingSessionsEstimatedEndDate(
  trainingSessionIds: string[],
): Promise<string | undefined> {
  let farthestDate: undefined | moment.Moment = undefined;

  await Aigle.mapSeries(trainingSessionIds, async trainingSessionId => {
    const trainingSession = await API.getTrainingSession(trainingSessionId);
    if (API.isFailure(trainingSession)) return trainingSession;

    if (!trainingSession.startDate && !trainingSession.scheduledStartDate) return;

    const estimatedEndDate = API.getTrainingSessionEstimatedEndDate(trainingSession);
    if (!farthestDate || estimatedEndDate > farthestDate) {
      farthestDate = estimatedEndDate;
    }
  });

  if (farthestDate) {
    return t('alex:workstationWorkerLevelMenu.estimatedEndDate', {
      diff: (farthestDate as Moment).fromNow(),
    });
  }

  return farthestDate;
}


export async function maintainWorkerLevel(
  workerWorkstation: API.WorkerWorkstation,
  maintainLevel: API.WorkstationWorkerLevels,
): Promise<API.Result<API.TrainingSession[]>> {
  return setTargetAndTrainWorker(
    workerWorkstation.workstationId,
    workerWorkstation.workerId,
    maintainLevel,
    true,
  );
}

export async function unmaintainWorkerLevel(
  workerWorkstation: API.WorkerWorkstation,
  isDeleteTrainingSessionRequests: boolean,
): Promise<API.Result<API.TrainingSession[]>> {
  const _workerWorkstation = API.deepClone(workerWorkstation);

  if (API.isWorkerInTrainAutoOnWorkstation(workerWorkstation)) {
    _workerWorkstation.isTrainAuto = false;

    const result = await API.updateWorkerWorkstation(_workerWorkstation);
    if (API.isFailure(result)) return result;

    if (isDeleteTrainingSessionRequests) {
      return removeWorkerFromTrainingSessionsAndUpdate(
        workerWorkstation.workerId,
        workerWorkstation.workstationId,
      );
    } else {
      return [];
    }
  } else {
    return API.createFailure_Unspecified(
      `Level is not maintained to be unmaintained ${workerWorkstation.id}`,
    );
  }
}

/**
 * To sort an array with workstation worker level in descending order
 * @param data contains workstationWorkerLevel key to be sorted
 */
export function sortByWorkstationWorkerLevel<
  T extends { workerWorkstation: API.WorkerWorkstation | undefined },
>(data: Array<T>) {
  return _.orderBy(data, [eachData => eachData.workerWorkstation?.level], ['desc']);
}

/**
 * Return all WorkerWorkstation related to the passed workstationId and/or workerId
 * @param workstationId (optional)
 * @param workerId (optional)
 * @returns a single WorkerWorkstation/undefined if workerId AND workstationId are specified, an array of WorkerWorkstation otherwise
 */
export function getWorkerWorkstationFactories(
  workstationId: string,
  workerId: string,
): API.Factory<API.DataType.WORKERWORKSTATION> | undefined;
export function getWorkerWorkstationFactories(
  workstationId?: string,
  workerId?: string,
): API.Factory<API.DataType.WORKERWORKSTATION>[];
export function getWorkerWorkstationFactories(
  workstationId?: string,
  workerId?: string,
):
  | API.Factory<API.DataType.WORKERWORKSTATION>[]
  | API.Factory<API.DataType.WORKERWORKSTATION>
  | undefined {
  const factoryCache = API.FactoryCache.getFactoryCache(API.DataType.WORKERWORKSTATION);

  if (workstationId && workerId) {
    const factory = factoryCache.getFactory([workerId, workstationId], true);
    if (API.isFailure(factory)) {
      logger.error('getWorkerWorkstationFactories failed: ' + factory.message, factory);
      return;
    }
    if (factory === 'ObjectNotFound' || !factory) {
      return;
    }
    return factory;
  } else {
    let factories: API.Result<API.Factory<DataType.WORKERWORKSTATION>[]>;
    if (workstationId) {
      factories = factoryCache.getFactories({
        index: 'data',
        type: 'startingWith',
        factorySkOrData_or_keys: [workstationId],
      });
    } else if (workerId) {
      factories = factoryCache.getFactories({
        index: 'sk',
        type: 'startingWith',
        factorySkOrData_or_keys: [workerId],
      });
    } else {
      factories = factoryCache.getFactories();
    }
    if (API.isFailure(factories)) {
      logger.error('getWorkerWorkstationFactories failed: ' + factories.message, factories);
      return [];
    }

    return factories;
  }
}

/**
 * See getWorkerWorkstationFactories()
 * @see getWorkerWorkstationFactories()
 */
export function getWorkerWorkstations(
  workstationId: string,
  workerId: string,
): API.WorkerWorkstation | undefined;
export function getWorkerWorkstations(
  workstationId?: undefined,
  workerId?: string,
): API.WorkerWorkstation[];
export function getWorkerWorkstations(
  workstationId?: string,
  workerId?: string,
): API.WorkerWorkstation[] | API.WorkerWorkstation | undefined {
  if (workstationId && workerId) {
    const workerWorkstation = getWorkerWorkstationFactories(workstationId, workerId);
    return workerWorkstation
      ? {
          ...workerWorkstation.workerWorkstation,
          updatedAt: workerWorkstation.updatedAt,
          updatedBy: workerWorkstation.updatedBy,
        }
      : undefined;
  } else {
    return getWorkerWorkstationFactories(workstationId, workerId).map(factory => {
      return {
        ...factory.workerWorkstation,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      };
    });
  }
}

export function getWorkersWorkstations(
  workstationIds: string[],
  workerIds: string[],
): API.WorkerWorkstation[] {
  const _workerWorkstations: API.WorkerWorkstation[] = [];
  workstationIds.forEach(workstationId => {
    workerIds.forEach(workerId => {
      const workerWorkstation = getWorkerWorkstations(workstationId, workerId);
      if (!workerWorkstation) return;
      _workerWorkstations.push(workerWorkstation);
    });
  });

  return _workerWorkstations;
}

/**
 * Returns not null target level for the worker on the given workstation.
 */
export function getWorkerWorkstationActualTargetLevel(
  workerWorkstation: API.WorkerWorkstation,
): API.WorkstationWorkerLevels {
  return workerWorkstation.targetLevel
    ? API.api2workstationWorkerLevels(workerWorkstation.targetLevel)
    : API.api2workstationWorkerLevels(workerWorkstation.previousLevel);
}

export function isWorkerSkillInfo(obj: any): obj is API.WorkerSkillInfoInput {
  return (
    (obj as API.WorkerSkillInfoInput).requirmentIds !== undefined &&
    (obj as API.WorkerSkillInfoInput).workerSkillId !== undefined
  );
}

export function isSameWorkerSkillInfo(
  object1: API.WorkerSkillInfoInput,
  object2: API.WorkerSkillInfoInput,
): boolean {
  if (object1.workerSkillId !== object2.workerSkillId) return false;
  if (!isSameStringArray(object1.requirmentIds, object2.requirmentIds)) return false;

  return true;
}

export function isMissingWorkerSkillInfo(obj: any): obj is API.MissingWorkerSkillInfoInput {
  return (
    (obj as API.MissingWorkerSkillInfoInput).requirmentIds !== undefined &&
    (obj as API.MissingWorkerSkillInfoInput).skillId !== undefined
  );
}
export function isSameMissingWorkerSkillInfo(
  object1: API.MissingWorkerSkillInfoInput,
  object2: API.MissingWorkerSkillInfoInput,
): boolean {
  if (object1.skillId !== object2.skillId) return false;
  if (!isSameStringArray(object1.requirmentIds, object2.requirmentIds)) return false;

  return true;
}

export function isSameWorkstationWorkerLevel(
  targetLevel1: API.WorkstationWorkerLevel | null | undefined,
  targetLevel2: API.WorkstationWorkerLevels | null | undefined,
): boolean {
  if (targetLevel1 == null) {
    return targetLevel2 == null;
  }

  return targetLevel2 === API.api2workstationWorkerLevels(targetLevel1);
}
