import * as API from 'shared/backend-data';
import logger from './Logger';
import * as _ from 'lodash-es';
import Aigle from 'aigle';
import { Immutable } from 'shared/util-ts/Functions';





interface TrainingVersionIdsAndSkillIdsWithoutTrainingSet {
  validTrainingVersionsIds: API.SkillTrainingVersion[];
  skillIdsWithoutTrainingSet: string[];
}

export async function createRequirement(
  requirementInput: API.RequirementCreateInput,
): Promise<API.Result<API.Requirement>> {
  const factory = await API.createFactoryBusinessObject(API.DataType.REQUIREMENT, requirementInput);
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.requirement,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function updateRequirement(
  requirementInput: API.RequirementPartialUpdateInput,
): Promise<API.Result<API.Requirement>> {
  const factory = await API.updateFactoryBusinessObject(API.DataType.REQUIREMENT, requirementInput);
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.requirement,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function getRequirement(requirementId: string): Promise<API.Result<API.Requirement>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.REQUIREMENT, requirementId);
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.requirement,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

/**
 * Get All the Requirements for all Workstation/OrganizationalUnit and all Levels.
 */
export async function getRequirements(
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.Requirement[]>> {
  const factories = await API.listFactoriesWithDataType(
    API.DataType.REQUIREMENT,
    undefined,
    itemsLimit,
    nextToken,
  );
  if (API.isFailure(factories)) return factories;

  return {
    result: _.map(factories.result, factory => {
      return {
        ...factory.requirement!,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      };
    }),
    nextToken: factories.nextToken,
  };
}

/**
 * Get a map of Level -> Requirement for the given Workstation/OrganizationalUnit.
 * @param workstationOrOrganizationalUnitId
 * @returns
 */
export async function getLevelsRequirement(
  workstationOrOrganizationalUnitId: string,
): Promise<API.Result<Map<API.WorkstationWorkerLevels, API.Requirement>>> {
  const levelsRequirements = await getLevelsRequirementsWithInheritedAndOrDescendent(
    workstationOrOrganizationalUnitId,
    false,
    false,
  );
  if (API.isFailure(levelsRequirements)) return levelsRequirements;

  const levelRequirement = new Map<API.WorkstationWorkerLevels, API.Requirement>();
  levelsRequirements.forEach((requirement, level) => {
    if (levelRequirement.has(level)) {
      logger.warn(
        'Requirement for LinkedObject: ' +
          workstationOrOrganizationalUnitId +
          ' contains more than one Requirement for Level: ' +
          level +
          '. This shall not be -> database inconsistency. As a fallbak, only the first Requirement will be considered.',
      );
    }
    levelRequirement.set(level, requirement[0]);
  });
  return levelRequirement;
}

/**
 * Get the Levels' Requirements for the given Workstation/OrganizationalUnit.
 * @param workstationOrOrgUnitId
 * @returns
 */
async function fetchLevelsRequirement(
  workstationOrOrgUnitId: string,
): Promise<API.Result<API.Requirement[]>> {
  const skString =
    API.DataType.REQUIREMENT + API.SeparatorDataType + API.SeparatorIds + workstationOrOrgUnitId;
  const factories = await API.listFactoriesWithSk(
    API.DataType.REQUIREMENT,
    skString,
    API.KeyComparator.beginsWith,
  );
  if (API.isFailure(factories)) {
    logger.warn('Failed to fetch requirement ', factories);
    return factories;
  }

  const _requirements: API.Requirement[] = _.map(factories.result, factory => {
    return { ...factory.requirement!, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
  });
  return _requirements;
}

/**
 * Get the Levels' Requirement for a given Workstation/OrganizationalUnit.
 * It returns only the Levels that are registered into the database.
 * @param workstationOrOrganizationalUnitId
 * @param includeInherited If false only 1 Requirement per level is returned.
 *        If true, returns also the SkillBundles of the given Workstation/OrganizationalUnit's
 *        ancestors, ordered "greater ancestor first".
 *  @param includeDescendent If true, returns also the SkillBundles of the
 *        all the given Workstation/OrganizationalUnit's descendents.
 *
 */
export async function getLevelsRequirementsWithInheritedAndOrDescendent(
  workstationOrOrganizationalUnitId: string,
  includeInherited: boolean,
  includeDescendent: boolean,
  excludeNonInherited: boolean = false,
): Promise<API.Result<Map<API.WorkstationWorkerLevels, API.Requirement[]>>> {
  const _pathIds: string[] = [];

  if (includeInherited) {
    const workstationOrOrganizationalUnit = await API.getWorkstationOrOrganizationalUnit(
      workstationOrOrganizationalUnitId,
    );
    if (API.isFailure(workstationOrOrganizationalUnit)) return workstationOrOrganizationalUnit;

    let __pathIds = workstationOrOrganizationalUnit.pathIds;
    if (excludeNonInherited)
      __pathIds = __pathIds.filter(_pathId => _pathId !== workstationOrOrganizationalUnitId);

    _pathIds.push(...__pathIds);
  }
  if (includeDescendent) {
    const children = API.Tree.getChildren(workstationOrOrganizationalUnitId, true);
    if (API.isFailure(children)) return children;

    _pathIds.push(
      ...children.map(child => {
        return child.id;
      }),
    );
  }
  if (!includeInherited && !includeDescendent && !excludeNonInherited) {
    _pathIds.push(workstationOrOrganizationalUnitId);
  }

  const pathIds = _.uniq(_pathIds);
  const errors: API.Failure[] = [];
  const levelsRequirements = new Map<API.WorkstationWorkerLevels, API.Requirement[]>();
  await Aigle.mapSeries(pathIds, async _workstationOrOrgUnitId => {
    const _nodeRequirements = await fetchLevelsRequirement(_workstationOrOrgUnitId);
    if (API.isFailure(_nodeRequirements)) {
      if (!API.isFailureType(_nodeRequirements, 'ObjectNotFound')) {
        errors.push(_nodeRequirements);
      }
      return;
    }

    for (const requirement of _nodeRequirements) {
      let levelRequirements = levelsRequirements.get(
        API.api2workstationWorkerLevels(requirement.level),
      );
      if (!levelRequirements) levelRequirements = [];
      levelRequirements.push(requirement);
      levelsRequirements.set(API.api2workstationWorkerLevels(requirement.level), levelRequirements);
    }
  });
  if (errors.length) return API.createFailure_Multiple(errors);

  return levelsRequirements;
}





export function createSkillTrainingVersions(
  skillIds: string[],
  trainingVersionIds?: (string | undefined)[],
): API.SkillTrainingVersionInput[] {
  return skillIds.map((skillId, index) => {
    return {
      skillId: skillId,
      trainingVersionId: trainingVersionIds ? trainingVersionIds[index] : undefined,
    };
  });
}

/**
 * Extract the list of Skill from the given Requirement(s) without duplicates.
 * The length of the return list might vary from the length of the list returned by extractTrainingVersionIds().
 * If you need same size, simply use requirement.skillTrainingVersions property.
 * @param requirements
 */
export function extractSkillIds(
  requirements: Immutable<API.RequirementUpdateInput | API.RequirementUpdateInput[]>,
): string[] {
  let _requirements = _.isArray(requirements) ? requirements : [requirements];
  let skillIds: string[] = [];
  _.forEach(_requirements, requirement => {
    skillIds = _.union(
      skillIds,
      _.map(requirement.skillTrainingVersions, skillTraining => skillTraining.skillId),
    );
  });
  return skillIds;
}

/**
 * Get the TrainingVersion associated to a Skill inside a Requirement
 * @param requirement
 * @param skillId
 */
export function extractTrainingVersionIdForSkill(
  requirement: API.Requirement,
  skillId: string,
): string | undefined | null {
  for (const skillTrainingVersion of requirement.skillTrainingVersions) {
    if (skillTrainingVersion.skillId === skillId) return skillTrainingVersion.trainingVersionId;
  }
  return undefined;
}

/**
 * Extract the list of valid TrainingVersions and skillIds without Training from the given Requirement(s) without duplicates.
 * The length of the return list might be less than the length of the list returned by extractSkillIds().
 * If you need same size, simply use requirement.skillTrainingVersions property.
 * @param requirements
 */



export function extractTrainingVersionIdsAndSkillIdsWithoutTrainingSet(
  requirements: API.Requirement | readonly API.Requirement[],
): TrainingVersionIdsAndSkillIdsWithoutTrainingSet {
  let _requirements = _.isArray(requirements) ? requirements : [requirements];
  const skillIdsWithoutTrainingSet = new Set<string>();
  const validTrainingVersionsIds = new Set<API.SkillTrainingVersion>();
  _.forEach(_requirements, requirement => {
    _.forEach(requirement.skillTrainingVersions, skillTrainingVersion => {
      if (skillTrainingVersion.trainingVersionId) {
        validTrainingVersionsIds.add(skillTrainingVersion);
      } else {
        skillIdsWithoutTrainingSet.add(skillTrainingVersion.skillId);
      }
    });
  });

  return {
    validTrainingVersionsIds: Array.from(validTrainingVersionsIds),
    skillIdsWithoutTrainingSet: Array.from(skillIdsWithoutTrainingSet),
  };
}
