import React, { useState, useEffect } from 'react';
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  Platform,
  StyleProp,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  ScrollView,
} from 'react-native';
import * as API from 'shared/backend-data';
import CloseIcon from 'shared/assets/svg/icon.close.svg';
import EditIcon from 'shared/assets/svg/icon.edit.svg';
import { IconSVG } from 'shared/ui-component/Icon';
import * as _ from 'lodash-es';
import Style from './Style';
import { AutoCompleteItem } from './AutoCompleteItem';
import { t } from '../../../localisation/i18n';
import { Colors } from '../../../styles';
import { useDetectOutsideClick } from '../../../hooks/DetectOutsideClick';

const ArrowIcon = require('shared/assets/svg/icon.list.mobile.svg').default;

const SCROLLVIEW_HEIGHT = 160;

export enum InputTagType {
  FilterTag = 'FilterTag',
  Web = 'Web',
  Mobile = 'Mobile',
}

interface Props {
  style?: StyleProp<ViewStyle>;
  inputRef?: React.RefObject<TextInput>;
  tagStyle?: StyleProp<ViewStyle>;
  placeholder?: string;
  initialTags?: Tag[];
  addExternalTag?: Tag | null;
  options?: Tag[];
  enableAutoComplete?: boolean;
  enableTagAddition?: boolean;
  autoFocus?: boolean;
  customAutoComplete?: (text: string) => Tag[];
  onTyping?: (input: string) => void;
  onKeyPress?: (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  onChange: (tags: Tag[]) => void;
  onDeleteTag?: (tag: Tag) => void;
  tagType?: InputTagType;
  title?: string;
  inputTextStye?: StyleProp<ViewStyle>;
  dropdownContainerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  notSelectable?: boolean;
}

export interface Tag<T = any> {
  key: string;
  label: string;
  editable?: boolean;
  disabled?: boolean;
  tagType?: string;
  trainingversion?: API.TrainingVersion; 
  value?: string;
  children?: Tag[];
  isCheckBoxTag?: boolean;
}

export const InputTag: React.FC<Props> = props => {
  const [tagValues, setTagValues] = useState<Map<string, Tag>>(new Map());
  const [text, setText] = useState<string>('');
  const [textInput, setTextInput] = useState<string>('');
  const [textValue, setTextValue] = useState<string>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [autoCompleteResult, setAutoCompleteResult] = useState<Tag[]>([]);
  const [optionsMap, setOptionsMap] = useState<Map<string, string>>(new Map());
  const [showdropdown, setShowdropdown] = useState<boolean>(false);
  const [textInputEditable, setTextInputEditable] = useState<boolean>(false);
  const inputTextRef = props.inputRef ?? React.createRef<TextInput>();
  const { tagType = InputTagType.Web, disabled, notSelectable } = props;

  let ref: React.RefObject<View> | undefined = undefined;
  if (Platform.OS == 'web') {
    ref = useDetectOutsideClick<View>(() => setShowdropdown(false));
  }

  useEffect(() => {
    tagValues.clear();
    setTagValues(new Map());
    props.initialTags?.forEach((tag: Tag) => {
      addTag(tag, false);
    });
  }, [props.initialTags]);

  useEffect(() => {
    if (props.addExternalTag) {
      addTag(props.addExternalTag, true);
    }
  }, [props.addExternalTag]);

  useEffect(() => {
    props.options?.forEach(tag => {
      optionsMap.set(tag.key, tag.label);
    });
    setOptionsMap(new Map(optionsMap));
    if (props.options) setAutoCompleteResult(props.options);
  }, [props.options]);

  const valueExists = (value: string) => {
    for (let [k, v] of optionsMap) {
      if (v === value) {
        return k;
      }
    }
  };

  const handleTagDelete = (key: string) => {
    if (props.onDeleteTag) props.onDeleteTag(tagValues.get(key)!);
    tagValues.delete(key);
    props.onChange(Array.from(tagValues.values()));
    setTagValues(API.deepClone(tagValues));
  };

  const handleTagEdit = (key: string) => {
    if (tagValues.get(key)?.label) {
      setTextValue(tagValues.get(key)?.label);
      tagValues.delete(key);
      setTagValues(API.deepClone(tagValues));
      inputTextRef.current?.focus();
    }
  };

  const handleTagInput = (event?: any) => {
    if (!event && text) {
      inputTextRef.current?.clear();
      if (props.enableTagAddition) {
        addTag(
          {
            key: valueExists(text) ?? text,
            label: text,
            editable: false,
          },
          true,
        );
      }
      setShowDropdown(false);
      setTextInputEditable(false);
    }
    if (event && props.onKeyPress) {
      props.onKeyPress(event);
    }
    if (event?.key === 'Enter' && event.target.value) {
      const value = Platform.OS !== 'web' ? text : event.target.value;
      event.target.value = '';
      inputTextRef.current?.clear();
      if (props.enableTagAddition) {
        addTag(
          {
            key: valueExists(value) ?? value,
            label: value,
            editable: false,
          },
          true,
        );
      }
      setShowDropdown(false);
      setTextInputEditable(false);
    }
  };

  const selectData = (tag: Tag) => {
    addTag(tag, true);
    setShowDropdown(false);
    inputTextRef.current?.clear();
  };

  function onTyping(input: string) {
    if (textValue) inputTextRef.current?.clear();
    setText(input);
    if (props.enableAutoComplete) {
      setTextInput(input);
      if (props.customAutoComplete) {
        setAutoCompleteResult(props.customAutoComplete(input));
      } else {
        setAutoCompleteResult(autoComplete(input));
      }
      setShowDropdown(true);
    }
    if (props.onTyping) props.onTyping(input);
  }

  function autoComplete(inputText: string): Tag[] {
    const regex = RegExp(escapeRegExp(inputText), 'i');
    let suggestions: Tag[] = [];
    if (props.options) {
      suggestions = props.options?.filter(tag => {
        return regex.test(tag.label);
      });
    }

    return suggestions;
  }

  function addTag(tag: Tag, isOnChange: boolean) {
    tagValues.set(tag.key, tag);
    setTagValues(API.deepClone(tagValues));
    if (isOnChange) props.onChange(Array.from(tagValues.values()));
  }

  function renderTags() {
    return Array.from(tagValues).map(([key, value]) => {
      return (
        <View key={key} style={[Style.tagView, props.tagStyle]}>
          <Text style={Style.tagText}>{value.label}</Text>
          {value.editable && (
            <TouchableOpacity onPress={() => handleTagEdit(key)}>
              <IconSVG svgComponent={EditIcon} />
            </TouchableOpacity>
          )}
          <TouchableOpacity onPress={() => handleTagDelete(key)}>
            <IconSVG svgComponent={CloseIcon} />
          </TouchableOpacity>
        </View>
      );
    });
  }

  const getTagValues = () => {
    let value: string | undefined = undefined;
    tagValues.forEach(eachTag => {
      value = value ? `${eachTag.label}, ${value}` : eachTag.label;
    });

    return value;
  };

  
  if (props.tagType === InputTagType.FilterTag) {
    return (
      <>
        <View style={[Style.container, props.style]}>
          {renderTags()}
          <TextInput
            ref={inputTextRef}
            style={[
              Style.inputText,
              Platform.OS === 'web' ? { outline: 'none' } : {},
              props.inputTextStye,
            ]}
            onKeyPress={handleTagInput}
            onChangeText={input => onTyping(input)}
            placeholder={props.placeholder}
            value={textValue}
            autoFocus={props.autoFocus}
          />
        </View>
        {props.enableAutoComplete && showDropdown && (
          <View style={[Style.autoCompleteMenu]}>
            <TouchableOpacity onPress={() => handleTagInput()} style={[Style.addItemContainer]}>
              <Text style={[Style.addItemTextStyle]}>
                <Text style={[Style.itemTextStyle]}>{t('common:button.add')}</Text>
                {' ' + text}
              </Text>
            </TouchableOpacity>
            {autoCompleteResult.map((tag, index) => (
              <AutoCompleteItem
                key={index}
                tag={tag}
                selectData={selectData}
                highlightString={textInput}
              />
            ))}
          </View>
        )}
      </>
    );
  }

  return (
    <>
      <View style={[Style.container, props.style]} ref={ref}>
        <Text style={Style.title}>{getTagValues() && (props.title || props.placeholder)}</Text>
        <TouchableOpacity
          onPress={() => setShowdropdown(!showdropdown)}
          style={[Style.border, (disabled || notSelectable) && Style.disabled]}
          disabled={disabled || notSelectable}
        >
          <Text
            style={[Style.inputText, (disabled || !getTagValues()) && { color: Colors.Grey }]}
            numberOfLines={1}
          >
            {getTagValues() ?? props.placeholder}
          </Text>
          {!props.enableTagAddition && !disabled && !notSelectable && (
            <IconSVG svgComponent={ArrowIcon} color={Colors.Black} />
          )}
        </TouchableOpacity>
        {showdropdown && tagType === InputTagType.Web && (
          <View style={[Style.dropdownContainer, props.dropdownContainerStyle]}>
            <View style={[Style.tagContainer, !textInputEditable && Style.border]}>
              {renderTags()}
              {!textInputEditable && (
                <TouchableOpacity style={Style.label} onPress={() => setTextInputEditable(true)}>
                  <Text>{t('common:account.enterLabel')}</Text>
                </TouchableOpacity>
              )}
            </View>

            {textInputEditable && (
              <TextInput
                ref={inputTextRef}
                style={[Style.inputText, Platform.OS === 'web' ? { outline: 'none' } : {}]}
                onKeyPress={handleTagInput}
                onChangeText={input => onTyping(input)}
                value={textValue}
                autoFocus={true}
                editable={textInputEditable}
              />
            )}
            <ScrollView style={{ height: SCROLLVIEW_HEIGHT }}>
              {autoCompleteResult.length ? (
                autoCompleteResult.map((tag, index) => (
                  <AutoCompleteItem
                    style={Style.autoCompleteStyle}
                    key={index}
                    tag={tag}
                    selectData={selectData}
                    highlightString={textInput}
                  />
                ))
              ) : (
                <View style={Style.noResultContainer}>
                  <Text style={Style.noResult}>{t('common:filters.noResult')}</Text>
                </View>
              )}
            </ScrollView>
          </View>
        )}
      </View>
    </>
  );
};

export function escapeRegExp(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); 
}
