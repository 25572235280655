import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { ModalStyles } from 'shared/styles/ModalStyles';

const styles = StyleSheet.create({
  unitModalPanelContainer: {
    flexDirection: 'row',
    flex: 1,
    zIndex: 998,
  },
  unitModalYellowButtonContainer: {
    position: 'absolute',
    bottom: 36,
    right: ModalStyles.smallButtonMargin,
    zIndex: 996,
    flexDirection: 'row',
  },
  unitModalCloseIconContainer: { position: 'absolute', top: 8, right: 12 },
  unitModalLeftPanelContainer: {
    flexDirection: 'row',
    marginRight: ModalStyles.bodyDivider,
  },
  unitModalLeftPanelInputContainer: {
    width: ModalStyles.inputWidthWithAnchor,
    paddingTop: SharedStyles.Spacings.Unit,
  },
  unitModalLeftPanelDropDownContainer: { zIndex: 999 },
  unitModalRightPanelHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 2,
  },
  unitModalRightPanelItemContainer: {
    flexDirection: 'row',
  },
  shiftTitle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.XXXLarge,
    marginRight: SharedStyles.Spacings.Small,
  },
  shiftName: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Regular,
    marginTop: SharedStyles.Spacings.Unit,
  },
  infoContainer: {
    top: SharedStyles.Spacings.Medium,
    right: SharedStyles.Spacings.Medium,
    zIndex: 999,
  },
  unitModalRightPanelContainer: {
    paddingTop: SharedStyles.Spacings.Standard,
    paddingLeft: SharedStyles.Spacings.Small,
    width: 330,
  },
  addShiftButtonContainer: {
    marginTop: SharedStyles.Spacings.Unit,
    backgroundColor: SharedStyles.Colors.Yellow,
  },
  shiftIconContainer: {
    width: 30,
    height: 30,
    borderRadius: SharedStyles.Spacings.Standard,
    marginTop: 32,
    marginRight: SharedStyles.Spacings.Small,
  },
  createButton: {
    width: SharedStyles.Spacings.Standard,
    height: SharedStyles.Spacings.Standard,
    borderRadius: SharedStyles.Spacings.Standard,
    borderWidth: 1,
    position: 'absolute',
    top: SharedStyles.Spacings.Large,
    right: SharedStyles.Spacings.Small,
    borderColor: SharedStyles.Colors.Yellow,
  },
  colorsButton: {
    height: SharedStyles.Spacings.Medium,
    width: SharedStyles.Spacings.Medium,
    borderRadius: SharedStyles.Spacings.Standard,
    marginRight: SharedStyles.Spacings.Unit,
  },
  colorsContainer: {
    flexDirection: 'row',
    marginLeft: SharedStyles.Spacings.Large,
    marginTop: SharedStyles.Spacings.Small,
  },
  mutateShiftIcons: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
  },
  shiftItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: SharedStyles.Spacings.xMedium,
  },
  moreColorsButton: {
    width: SharedStyles.Spacings.Medium,
    height: SharedStyles.Spacings.Medium,
    borderRadius: SharedStyles.Spacings.Standard,
    backgroundColor: SharedStyles.Colors.Yellow,
  },
  buttonsContainer: {
    marginTop: SharedStyles.Spacings.Small,
    marginRight: SharedStyles.Spacings.Large,
  },
  colorPickerContainer: {
    marginTop: SharedStyles.Spacings.Standard,
    marginLeft: SharedStyles.Spacings.xMedium,
  },
  threeDotsContainer: {
    height: SharedStyles.Spacings.Medium,
    width: SharedStyles.Spacings.Medium,
    marginRight: SharedStyles.Spacings.Unit / 2,
  },
  threeDotsIcon: {
    bottom: SharedStyles.Spacings.Unit,
  },
});
export default styles;
