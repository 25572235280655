import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  TextInput,
  Platform,
  Dimensions,
} from 'react-native';
import Style from './Style';

export interface Props {
  data: any;
  label?: any;
  placeHolder: string;
  handleSearchInput: any;
  dataKey?: string;
  inputWidth?: any;
  inputHeight?: any;
  dropDownWidth?: any;
  dropDownHeight?: any;
  containerStyle?: any;
  inputStyle?: any;
  listStyle?: any;
  placeHolderStyle?: any;
}

const { height, width } = Dimensions.get('window');


export const DropDownWithSearchBox: React.FC<Props> = props => {
  const [modalVisible, showModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentList, setCurrentList] = useState<typeof props.data | undefined>();
  const keyExtractor = (item: any, index: any) => index.toString();

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setCurrentList(props.data);
    }
  }, [props.data]);

  const handleDropDownSelect = (item: any) => {
    if (props.dataKey) {
      setSearchKeyword(item[props.dataKey]);
    } else {
      setSearchKeyword(item);
    }
    props.handleSearchInput(item, true);
    showModal(false);
  };

  const filterList = (searchKey: string) => {
    setSearchKeyword(searchKey);
    handleFilter(searchKey);
  };

  const handleFilter = (filterKey: string) => {
    const fullList = props.data;
    let filteredList = '';
    if (fullList && fullList.length > 0) {
      if (props.dataKey) {
        filteredList = fullList.filter((item: any) => {
          
          if (item[props.dataKey!].toLowerCase().match(filterKey.toLowerCase())) return item;
        });
      } else {
        filteredList = fullList.filter((item: any) => {
          if (item.toLowerCase().match(filterKey.toLowerCase())) return item;
        });
      }

      if (!filterKey || filterKey === '') {
        showModal(true);
        setCurrentList(fullList);
        props.handleSearchInput(filterKey, false);
      } else if (!filteredList.length) {
        props.handleSearchInput(filterKey, false);
        showModal(false);
        setCurrentList([]);
      } else if (Array.isArray(filteredList)) {
        props.handleSearchInput(filterKey, false);
        showModal(true);
        setCurrentList(filteredList);
      }
    } else {
      props.handleSearchInput(filterKey, false);
    }
  };

  return (
    <View style={[Style.container, { ...props.containerStyle }]}>
      <Text style={Style.label}>{props.label}</Text>
      <TextInput
        onFocus={
          props.data && props.data.length > 0 ? () => showModal(true) : () => showModal(false)
        }
        style={[
          Style.textInput,
          {
            width: props.inputWidth
              ? props.inputWidth
              : Platform.OS === 'web'
                ? '44.5vw'
                : width * 0.23,
            height: props.inputHeight
              ? props.inputHeight
              : Platform.OS === 'web'
                ? '4vh'
                : height * 0.05,
          },
          { ...props.inputStyle },
        ]}
        onChangeText={(text: string) => filterList(text)}
        value={searchKeyword}
        placeholder={props.placeHolder}
        placeholderTextColor={props.placeHolderStyle}
      />
      {modalVisible && (
        <View
          style={[
            Style.flatListContainer,
            {
              width: props.dropDownWidth
                ? props.dropDownWidth
                : Platform.OS === 'web'
                  ? '44.5vw'
                  : width * 0.23,
              height: props.dropDownHeight
                ? props.dropDownHeight
                : Platform.OS === 'web'
                  ? '19.9vh'
                  : height * 0.05,
            },
            { ...props.listStyle },
          ]}
        >
          <FlatList
            bounces={false}
            data={currentList}
            extraData={currentList}
            keyExtractor={keyExtractor}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => handleDropDownSelect(item)}
                style={[
                  Style.listItemContainer,
                  {
                    backgroundColor: index % 2 == 0 ? '#F1F3F4' : '#FFFFF',
                  },
                ]}
              >
                <Text style={Style.dropDownLabel}>
                  {props.dataKey ? item[props.dataKey] : item}
                </Text>
              </TouchableOpacity>
            )}
          />
        </View>
      )}
    </View>
  );
};

