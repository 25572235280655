import { StyleSheet } from 'react-native';
import { Colors } from 'shared/styles';

export const Styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  crossIcon: { position: 'absolute', top: 16, right: 28, zIndex: 2 },
  proofContainer: {
    flex: 1,
    backgroundColor: Colors.ModalBackground,
    paddingHorizontal: 100,
    paddingTop: 70,
    paddingBottom: 100,
    zIndex: 1,
  },
  scrollViewContainer: {
    alignItems: 'center',
  },
  clickableContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
});
