import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Animated,
  Easing,
  StyleProp,
  TextStyle,
  Platform,
} from 'react-native';
import { Colors } from 'shared/styles';
import styles from './styles';
import * as _ from 'lodash-es';
import * as SharedStyles from 'shared/styles/index';
import { Tab, IndexedTab } from './AnimatedTab';
import { IconSVG } from 'shared/ui-component/Icon';

interface Props {
  item: Tab;
  itemIndex: number;
  selectedTabIndex: number;
  handleTabPress: (item: IndexedTab, callOnTabPress?: boolean | undefined) => void;
  tabTextStyle?: StyleProp<TextStyle>;
  isMobileStyle?: boolean;
}

export const TabItem: React.FC<Props> = props => {
  const { item, itemIndex, selectedTabIndex, handleTabPress, tabTextStyle, isMobileStyle } = props;

  const [isTabItemSelected, setIsTabItemSelected] = useState(false);
  const isPlatformWebStyle = isMobileStyle ? false : Platform.OS == 'web';

  const translateBottomViewScaleY = useRef(new Animated.Value(1)).current;
  const bottomViewOpacity = useRef(new Animated.Value(0)).current;
  const redDotIcon = require('shared/assets/svg/icon.skill.small.svg').default;

  useEffect(() => {
    setIsTabItemSelected(selectedTabIndex === itemIndex);
  }, [selectedTabIndex, itemIndex]);

  useEffect(() => {
    Animated.timing(bottomViewOpacity, {
      useNativeDriver: true,
      toValue: isTabItemSelected ? 1 : 0,
      duration: isTabItemSelected ? 1000 : 350,
      easing: Easing.elastic(1),
    }).start();
  }, [isTabItemSelected]);

  return (
    <TouchableOpacity
      key={itemIndex}
      style={[styles.tabItem]}
      onPress={() => {
        handleTabPress({ ...item, index: itemIndex }, true);
      }}
      disabled={item.title.disabled}
    >
      <Animated.View
        style={[
          styles.tabIndexView,
          {
            backgroundColor: isPlatformWebStyle
              ? Colors.Transparent
              : isTabItemSelected
              ? Colors.Black
              : Colors.LightGrey,
            transform: [{ scaleY: translateBottomViewScaleY }],
            opacity: bottomViewOpacity,
          },
        ]}
      />
      <View style={isPlatformWebStyle ? styles.tabOuterContainer : styles.mobTabOuterContainer}>
        <View
          style={[
            isPlatformWebStyle
              ? isTabItemSelected
                ? styles.tabInnerContainerSelected
                : styles.tabInnerContainer
              : styles.mobTabInnerContainer,
          ]}
        >
          <View>
            {item.title.warningIcon ? (
              <IconSVG
                svgComponent={redDotIcon}
                color={SharedStyles.Colors.White}
                size={{ width: 0, height: 0 }}
                containerStyle={{
                  width: 6,
                  height: 6,
                  backgroundColor: item.title.warningIconColor,
                  borderRadius: 20,
                  marginRight: 8,
                }}
              />
            ) : (
              <></>
            )}
          </View>
          <Text
            style={[
              isPlatformWebStyle
                ? isTabItemSelected
                  ? styles.titleTabTextStyleSelected
                  : styles.titleTabTextStyle
                : styles.mobTitleTabTextStyle,
              {
                color: item.title.disabled
                  ? Colors.GreyLight
                  : isTabItemSelected
                  ? Colors.Black
                  : Colors.Grey,
                marginRight: _.isNumber(item.title.tabTotal) ? SharedStyles.Spacings.Unit : 0,
              },
              tabTextStyle,
            ]}
          >
            {item.title.title}
          </Text>
          <Text
            style={[
              isPlatformWebStyle
                ? styles.titleTabTotalNumberTextStyle
                : styles.mobTitleTabTotalNumberTextStyle,
              {
                color: item.title.disabled
                  ? Colors.GreyLight
                  : isTabItemSelected
                  ? Colors.Black
                  : Colors.Grey,
              },
            ]}
          >
            {item.title.tabTotal}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};
