import { StyleSheet } from 'react-native';
import { Spacings } from 'shared/styles';

const styles = StyleSheet.create({
  CardContainer: {
    position: 'absolute',
    padding: Spacings.CardPadding,
    zIndex: 999,
  },
});

export default styles;
