import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  View,
  Text,
  ScrollView,
  InteractionManager,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  FlatList,
} from 'react-native';
import * as SharedStyles from 'shared/styles';
import styles from './styles';
import * as API from 'shared/backend-data';
import { DashboardContext } from 'shared/context/dashboard-context/DashboardContextProvider';
import { WorkerTableComponent } from '../worker-table/component';
import {
  WorkerWithParentNode,
  WorkstationTableComponent,
} from '../workstation-table/component/WorkstationTableComponent';
import { WorkstationWorkerMenu } from '../workstation-worker-menu/index';
import { ImportExportComponent } from 'shared/ui-component/import-export-menu';
import { ImportExportType } from 'shared/util/ExcelUtils';
import { getExportDataForSkillMatrix, ImportExportFileNames } from 'shared/util/ExcelUtils';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { Colors } from 'shared/styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { WorkstationWorkerLevelInfoMenu } from '../workstation-worker-level-info-menu/index';
import * as _ from 'lodash-es';
import { TouchableOpacity } from 'react-native-web';
import { LEFT_PANEL_WIDTH, SCROLLBAR_WIDTH, HEADER_HEIGHT } from './styles';
import { t } from 'shared/localisation/i18n';
import { YellowButton } from 'shared/ui-component/Button';
import { RouteLocations } from '../../navigation/Routes';
import { SkillBadgeStates } from 'shared/util/Skill';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import logger from 'shared/util/Logger';
import {
  DashboardFilterContext,
  ParentWithWorkers,
  WorkersByParentWithDetails,
} from 'shared/context/dashboard-context/DashboardFilterContext';
import { WorkstationOrOrgUnitPath } from 'shared/components/WorkstationOrOrgUnitPath';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { ROW_HEIGHT } from '../workstation-worker-level-table/styles';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { WorkstationTargetsMenu } from './wokrstation-targets-menu';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';
import { MyHub } from 'shared/util/MyHub';

const Tutorial = require('shared/components/tutorial/Tutorial').default;

const FullScreenSVG = require('shared/assets/svg/ic_expand.svg').default;
const InfoSVG = require('shared/assets/svg/icon.info.svg').default;
const AlexIcon = require('shared/assets/svg/icon.alex.svg').default;
const EnterpriseIcon = require('shared/assets/svg/illus.svg').default;

export interface WorkstationWorkerMenuObject {
  workerWorkstation: API.WorkerWorkstation;
  worker: API.Worker;
  parentOrgUnit?: API.OrganizationalUnit;
  noRequirementForWorkstation?: boolean;

  handleMaintainSelect: (maintainLevel: API.WorkstationWorkerLevels) => Promise<API.Result<void>>;
  handleUnassignWorkerAndStopTrainings: () => Promise<void>;
  handleSilenceWarning: () => void;
}

export interface WorkerInfo {
  worker: API.Worker;
  versatility: number | null;
  workerBadgeState: SkillBadgeStates | null;
}
interface TableViewProps {
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels;
  filterErrorMessage: FilterErrorMessage | null;
  versatilityPanelFullScreen: boolean;
  computeObjectives: boolean;
  workersFlatListRef: React.MutableRefObject<FlatList | undefined>;
  selectedElement?: Element;
  menuObject: WorkstationWorkerMenuObject;
  openMenu: boolean;
  selectedTargetsMenuElement?: Element;
  openTargetsMenu: boolean;
  workersWorkstationsScrollViewRef: React.MutableRefObject<ScrollView | undefined>;
  commonScrollViewRef: React.MutableRefObject<ScrollView | undefined>;
  computeMatrixLevelIcons: boolean;
  workstationsScrollViewRef: React.MutableRefObject<ScrollView | undefined>;
  workerPanelWidthRef: React.MutableRefObject<number | undefined>;
  workstationPanelWidthRef: React.MutableRefObject<string | undefined>;
  rightContainerFullWidth: number;
  levelsTableFullHeight: number;
  workstationsScrollViewOffset: number;
  workersScrollViewFullHeight: number;
  workersScrollViewDisplayedHeight: number;
  workersScrollViewOffset: number;
  isScrolling: boolean;
  treeNode?: API.TreeNode<API.TreeDataType>;
  filteredWorkers?: API.Worker[];
  workersByParentWithDetails?: WorkersByParentWithDetails;

  setWorkstationsScrollViewOffset: (offset: number) => void;
  setLevelsTableFullHeight: (height: number) => void;
  setComputeObjectives: (bool: boolean) => void;
  setSelectedElement: (element?: Element) => void;
  setSelectedTargetsMenuElement: (element?: Element) => void;
  setMenuObject: (obj: WorkstationWorkerMenuObject) => void;
  setOpenTargetsMenu: (bool: boolean) => void;
  setOpenMenu: (bool: boolean) => void;
  setWorkersScrollViewOffset: (offset: number) => void;
  setWorkersScrollViewDisplayedHeight: (height: number) => void;
  setWorkersScrollViewFullHeight: (height: number) => void;
  setComputeMatrixLevelIcons: (bool: boolean) => void;
  onWorkerTableScroll: (offset: number) => void;
  onWorkerWorkstationTableScroll: (offset: number) => void;
  onWorkstationTableScroll: (offset: number) => void;
  setComputeToDoList: (bool: boolean) => void;
  setRightContainerFullWidth: (offset: number) => void;
  setTreeNode: (treeNode?: API.TreeNode<API.TreeDataType>) => void;
  getSavedScrollOffsets: () => void;
  handleShiftIconPress: (workstationFooterData: API.WorkstationTargetActualWithDetails) => void;
}
type FilterErrorMessage = {
  header: string;
  message: string;
};

interface Props {
  versatilityPanelFullScreen: boolean;
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels;

  setComputeToDoList: (bool: boolean) => void;
  versatilityPanelFullScreenHandler: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface DashboardScrollViewOffset {
  workersScrollViewOffset: number;
  workstationsScrollViewOffset: number;
  workerPanelWidth?: number;
  workstationPanelWidth?: string;
}

const TableView: React.FC<TableViewProps> = props => {
  const {
    versatilityPanelFullScreen,
    filterErrorMessage,
    computeObjectives,
    workersFlatListRef,
    menuObject,
    workersScrollViewDisplayedHeight,
    workersWorkstationsScrollViewRef,
    computeMatrixLevelIcons,
    workstationsScrollViewRef,
    rightContainerFullWidth,
    openMenu,
    levelsTableFullHeight,
    workstationsScrollViewOffset,
    workersScrollViewFullHeight,
    workersScrollViewOffset,
    isScrolling,
    treeNode,
    workstationTargetsStartingAtLevel,
    commonScrollViewRef,
    workerPanelWidthRef,
    workersByParentWithDetails,
    filteredWorkers,

    handleShiftIconPress,
    setOpenTargetsMenu,
    setComputeObjectives,
    setMenuObject,
    setSelectedElement,
    setLevelsTableFullHeight,
    setWorkstationsScrollViewOffset,
    onWorkerTableScroll,
    setWorkersScrollViewOffset,
    setWorkersScrollViewDisplayedHeight,
    setWorkersScrollViewFullHeight,
    setComputeMatrixLevelIcons,
    onWorkerWorkstationTableScroll,
    onWorkstationTableScroll,
    setComputeToDoList,
    setRightContainerFullWidth,
    setOpenMenu,
    setTreeNode,
    getSavedScrollOffsets,
    setSelectedTargetsMenuElement,
  } = props;

  const [adaptableWidth, setAadaptableWidth] = useState<number>(LEFT_PANEL_WIDTH + SCROLLBAR_WIDTH);
  const [adaptableHeight, setAadaptableHeight] = useState<number>(0);
  const [isMousePress, setIsMousePress] = useState<boolean>(false);
  const [
    displayedLeftPanelFilteredWorkersAndParents,
    setDisplayedLeftPanelFilteredWorkersAndParents,
  ] = useState<Array<WorkerWithParentNode | ParentWithWorkers> | undefined>();
  const [isHover, setIsHover] = useState<boolean>(false);

  const refForDragableArea = useCallOnHover<View>(
    Colors.BlueRollover,
    e => setIsHover(true),
    e => setIsHover(false),
  );

  const refViewForWorker = React.useRef<View>(null);
  const refViewForWorkstation = React.useRef<View>(null);

  const workerPanelMinWidth = 219;
  const workerPanelMaxWidth = 500;

  useEffect(() => {
    const panelForWorker = ReactDOM.findDOMNode(refViewForWorker.current) as HTMLElement;
    panelForWorker.style.width = `${workerPanelWidthRef.current}px`;
  }, [workerPanelWidthRef.current]);

  function onPressIn() {
    window.addEventListener('mousemove', handleResize, true);
    setIsHover(false);
    setIsMousePress(true);
  }

  function onPressOut() {
    window.removeEventListener('mousemove', handleResize, true);
    setIsMousePress(false);
  }

  const handleResize = useCallback(event => {
    if (refViewForWorker.current && event.buttons) {
      const panelForWorker = ReactDOM.findDOMNode(refViewForWorker.current) as HTMLElement;

      const widthOnMouseMoveForWorkers =
        panelForWorker.getBoundingClientRect().width + event.movementX;
      panelForWorker.style.width = `${widthOnMouseMoveForWorkers}px`;
      panelForWorker.style.minWidth = workerPanelMinWidth + 'px';
      panelForWorker.style.maxWidth = workerPanelMaxWidth + 'px';
      workerPanelWidthRef.current = widthOnMouseMoveForWorkers;
    }
  }, []);

  function calculateWorkersAndShiftsCount(): number {
    return (
      (workersByParentWithDetails?.workersCount || 0) +
      (workersByParentWithDetails?.shiftsAndOrgUnitCount || 0)
    );
  }

  return (
    <View
      style={[
        styles.TableViewContainer,
        versatilityPanelFullScreen ? styles.tableViewContainerFullScreen : {},
      ]}
      onLayout={(event: LayoutChangeEvent) => {
        setAadaptableWidth(event.nativeEvent.layout.width);
        setAadaptableHeight(event.nativeEvent.layout.height);
      }}
    >
      {}
      <View
        style={{
          width: workerPanelMinWidth,
          zIndex: 2,
          height: '100%',
          backgroundColor: Colors.White,
        }}
        ref={refViewForWorker}
      >
        <WorkerTableComponent
          workersScrollViewRef={workersFlatListRef}
          adaptableHeight={adaptableHeight}
          displayedLeftPanelFilteredWorkersAndParents={displayedLeftPanelFilteredWorkersAndParents}
          setWorkersScrollViewFullHeight={setWorkersScrollViewFullHeight}
          setWorkersScrollViewDisplayedHeight={setWorkersScrollViewDisplayedHeight}
          setWorkersScrollViewOffset={setWorkersScrollViewOffset}
          onWorkerTableScroll={onWorkerTableScroll}
          setComputeMatrixLevelIcons={setComputeMatrixLevelIcons}
          setComputeToDoList={setComputeToDoList}
        />
        <TouchableOpacity
          ref={refForDragableArea}
          style={[
            styles.dragableSideStyle,
            isMousePress && styles.draggableBorder,
            isHover && styles.dragableBorderOnHover,
          ]}
          onPressIn={onPressIn}
          onPressOut={onPressOut}
          activeOpacity={1}
        />
      </View>
      <View
        ref={refViewForWorkstation}
        style={[
          styles.rightContainer,
          {
            flex: 1,
            height: adaptableHeight,
            zIndex: 1,
          },
        ]}
      >
        <View>
          <WorkstationTableComponent
            adaptableHeight={adaptableHeight}
            adaptableWidth={adaptableWidth}
            computeObjectives={computeObjectives}
            workersScrollViewDisplayedHeight={workersScrollViewDisplayedHeight}
            menuObject={menuObject}
            workerWorkstationsScrollViewRef={workersWorkstationsScrollViewRef}
            computeMatrixLevelIcons={computeMatrixLevelIcons}
            workstationsScrollViewRef={workstationsScrollViewRef}
            rightContainerFullWidth={rightContainerFullWidth}
            openMenu={openMenu}
            levelsTableFullHeight={levelsTableFullHeight}
            workstationsScrollViewOffset={workstationsScrollViewOffset}
            workersScrollViewFullHeight={workersScrollViewFullHeight}
            workersScrollViewOffset={workersScrollViewOffset}
            isScrolling={isScrolling}
            workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
            treeNode={treeNode}
            displayedLeftPanelFilteredWorkersAndParents={
              displayedLeftPanelFilteredWorkersAndParents
            }
            setDisplayedLeftPanelFilteredWorkersAndParents={
              setDisplayedLeftPanelFilteredWorkersAndParents
            }
            setWorkstationsScrollViewOffset={setWorkstationsScrollViewOffset}
            setLevelsTableFullHeight={setLevelsTableFullHeight}
            setComputeObjectives={setComputeObjectives}
            setSelectedElement={setSelectedElement}
            setMenuObject={setMenuObject}
            setOpenMenu={setOpenMenu}
            onWorkerTableScroll={onWorkerTableScroll}
            onWorkerWorkstationTableScroll={onWorkerWorkstationTableScroll}
            onWorkstationTableScroll={onWorkstationTableScroll}
            setRightContainerFullWidth={setRightContainerFullWidth}
            setComputeToDoList={setComputeToDoList}
            setTreeNode={setTreeNode}
            getSavedScrollOffsets={getSavedScrollOffsets}
            setSelectedTargetsMenuElement={setSelectedTargetsMenuElement}
            setOpenTargetsMenu={setOpenTargetsMenu}
            handleShiftIconPress={handleShiftIconPress}
            filteredWorkers={filteredWorkers}
          />
          {filterErrorMessage && (
            <View
              style={[
                styles.errorMessageContainer,
                {
                  height: adaptableHeight - HEADER_HEIGHT,
                },
              ]}
            >
              <Text style={styles.errorMessageHeader}>{filterErrorMessage.header}</Text>
              <Text style={styles.errorMessageText}>{filterErrorMessage.message}</Text>
            </View>
          )}
        </View>
      </View>
      <View
        style={{
          width: '2%',
          height: adaptableHeight,
          paddingTop: HEADER_HEIGHT,
        }}
      >
        <ScrollView
          ref={commonScrollViewRef as React.MutableRefObject<ScrollView>}
          onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
            onWorkerWorkstationTableScroll(event.nativeEvent.contentOffset.y);

            if (workersWorkstationsScrollViewRef.current) {
              workersWorkstationsScrollViewRef.current.scrollTo({
                y: event.nativeEvent.contentOffset.y,
                animated: false,
              });
            }
          }}
          style={styles.commonScrollView}
        >
          <View style={{ height: ROW_HEIGHT * calculateWorkersAndShiftsCount() }}></View>
        </ScrollView>
      </View>
    </View>
  );
};

const GreetingView: React.FC = () => {
  const history = useHistory();

  return (
    <View style={styles.welcomeMessageContainer}>
      <IconSVG
        svgComponent={AlexIcon}
        size={{ height: 72, width: 72 }}
        color={Colors.Yellow}
        containerStyle={styles.alexIconContaier}
      />
      <IconSVG
        svgComponent={EnterpriseIcon}
        size={{ height: 194, width: 406 }}
        color={Colors.Yellow}
        containerStyle={styles.enterpriseIconContaier}
      />
      <Text style={styles.greetingText}>{t('alex:dashboard.greetingTitle')}</Text>
      <Text style={styles.greetingInfoText}>{t('alex:dashboard.greetingMessage')}</Text>
      <YellowButton
        style={styles.actionButton}
        textStyle={styles.actionText}
        text={t('alex:dashboard.greetingAction')}
        onPress={() => {
          history.push(RouteLocations.Workstations());
        }}
      />
    </View>
  );
};

export const VersatilityPanel: React.FC<Props> = props => {
  const {
    workstationTargetsStartingAtLevel,

    setComputeToDoList,
  } = props;

  const history = useHistory();

  const isMounted = useIsMounted();

  const [treeNode, setTreeNode] = useState<API.TreeNode>();
  const [showInfoMenu, setShowInfoMenu] = useState<boolean>(false);
  const [filterErrorMessage, setFilterErrorMessage] = useState<FilterErrorMessage | null>(null);

  const [openMenu, setOpenMenu] = useState(false);
  const [menuObject, setMenuObject] = useState<WorkstationWorkerMenuObject>(
    {} as WorkstationWorkerMenuObject,
  );
  const [selectedElement, setSelectedElement] = useState<Element>();
  const [openTargetsMenu, setOpenTargetsMenu] = useState(false);
  const [selectedTargetsMenuElement, setSelectedTargetsMenuElement] = useState<Element>();

  const [versatilityPanelElement, setVersatilityPanelElement] = useState<Element>();
  const [computeMatrixLevelIcons, setComputeMatrixLevelIcons] = useState<boolean>(false);
  const [computeObjectives, setComputeObjectives] = useState<boolean>(false);

  const [rightContainerFullWidth, setRightContainerFullWidth] = useState(0);
  const [levelsTableFullHeight, setLevelsTableFullHeight] = useState(0);
  const [workstationsScrollViewOffset, setWorkstationsScrollViewOffset] = useState(0);
  const [workersScrollViewFullHeight, setWorkersScrollViewFullHeight] = useState(0);
  const [workersScrollViewDisplayedHeight, setWorkersScrollViewDisplayedHeight] = useState(0);
  const [workersScrollViewOffset, setWorkersScrollViewOffset] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [selectedWorkstationFooterData, setSelectedWorkstationFooterData] =
    useState<API.WorkstationTargetActualWithDetails>();
  const modal = useModal();

  const accountHasNoWorkstations = useContext(DashboardContext);
  const {
    treeNode: [, _setTreeNode],
  } = useContext(MenuFactoryContext);

  const {
    workstations: [filteredWorkstations],
    workers: [filteredWorkers],
    workersByParentWithDetails: [workersByParentWithDetails],
  } = useContext(DashboardFilterContext);

  const refView = React.useRef<View>(null);
  const refOpenMenu = React.useRef<boolean>(false);

  const workersFlatListRef = useRef<FlatList>();
  const commonScrollViewRef = useRef<ScrollView>();
  const workstationsScrollViewRef = useRef<ScrollView>();
  const workersWorkstationsScrollViewRef = useRef<ScrollView>();

  const workersScrollViewProgScroll = React.useRef(0);
  const workstationsScrollViewProgSroll = React.useRef(0);
  const workerWorkstationsScrollViewProgScroll = React.useRef(0);

  const workerPanelWidthRef = React.useRef<number | undefined>();
  const workstationPanelWidthRef = React.useRef<string | undefined>();

  
  useEffect(() => {
    InteractionManager.runAfterInteractions(() => checkForTutorial());

    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', data => {
      if (data.data.factory.dataType === API.DataType.USERPREFERENCE) checkForTutorial();
    });

    return () => {
      removeListener();
      InteractionManager.runAfterInteractions(() => {
        requestAnimationFrame(() => {
          API.saveUserPreference<DashboardScrollViewOffset>(
            UserPreferenceKeys_SkillMgtApp.DashboardWorkstationLevelScrollViewOffset,
            {
              workstationsScrollViewOffset: workstationsScrollViewProgSroll.current,
              workersScrollViewOffset: workersScrollViewProgScroll.current,
              workerPanelWidth: workerPanelWidthRef.current,
              workstationPanelWidth: workstationPanelWidthRef.current,
            },
          );
        });
      });
    };
  }, []);

  useEffect(() => {
    refOpenMenu.current = openMenu;
  }, [openMenu]);

  useEffect(() => {
    if (!refView.current) return;

    const node = ReactDOM.findDOMNode(refView.current) as Element;
    setVersatilityPanelElement(node);
  }, [refView]);

  useEffect(() => {
    if (!filteredWorkers || !filteredWorkstations) return;

    handleFilterChange(filteredWorkers, filteredWorkstations);
  }, [filteredWorkers, filteredWorkstations]);

  async function checkForTutorial() {
    const userPreference = await API.getUserPreference(
      API.UserPreferenceKeys_Common.TutorialAcknowledged,
    );

    if (!userPreference && isMounted.current) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: <Tutorial />,
          containerStyle: { width: SharedStyles.ModalStyles.width * 1.5, height: 600 },
          isBlocking: true,
        }),
      );
    }
  }

  function _handleShiftIconPress(workstationFooterData: API.WorkstationTargetActualWithDetails) {
    setSelectedWorkstationFooterData(workstationFooterData);
  }

  async function getSavedScrollOffsets() {
    const result = await API.getUserPreference<DashboardScrollViewOffset>(
      UserPreferenceKeys_SkillMgtApp.DashboardWorkstationLevelScrollViewOffset,
    );
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      logger.warn('error in fetching user Preference', result);
      return;
    }

    if (result) {
      if (result.workerPanelWidth) {
        workerPanelWidthRef.current = result.workerPanelWidth;
      }
      if (result.workstationPanelWidth) {
        workstationPanelWidthRef.current = result.workstationPanelWidth;
      }
      if (result.workstationsScrollViewOffset)
        scrollWorkstations(result.workstationsScrollViewOffset);

      if (result.workersScrollViewOffset) scrollWorkers(result.workersScrollViewOffset);
    }
  }

  function onWorkstationTableScroll(offset: number) {
    workstationsScrollViewProgSroll.current = offset;

    startScrolling();
  }

  function onWorkerTableScroll(offset: number) {
    if (
      workersScrollViewProgScroll.current === offset ||
      !workersWorkstationsScrollViewRef?.current
    )
      return;

    workersScrollViewProgScroll.current = offset;

    if (workerWorkstationsScrollViewProgScroll.current !== offset) {
      startScrolling();
      scrollWorkerWorkstations(workersScrollViewProgScroll.current);
    }
  }

  function onWorkerWorkstationTableScroll(offset: number) {
    if (workerWorkstationsScrollViewProgScroll.current === offset || !workersFlatListRef?.current)
      return;

    workerWorkstationsScrollViewProgScroll.current = offset;

    if (workersScrollViewProgScroll.current !== offset) {
      startScrolling();
      scrollWorkers(workerWorkstationsScrollViewProgScroll.current);
    }
  }

  const stopScrolling = useCallback(
    _.debounce(() => {
      setIsScrolling(false);
    }, 800),
    [],
  );

  const scrollWorkerWorkstations = useCallback(
    _.debounce((yOffset: number) => {
      if (!workersWorkstationsScrollViewRef?.current) return;

      workersWorkstationsScrollViewRef.current.scrollTo({
        y: yOffset,
        animated: false,
      });

      if (commonScrollViewRef.current) {
        commonScrollViewRef.current.scrollTo({
          y: workersScrollViewProgScroll.current,
          animated: false,
        });
      }
    }, 10),
    [],
  );

  const scrollWorkers = useCallback(
    _.debounce((yOffset: number) => {
      if (!workersFlatListRef?.current) return;

      workersFlatListRef.current.scrollToOffset({
        offset: yOffset,
        animated: false,
      });

      if (commonScrollViewRef.current) {
        commonScrollViewRef.current.scrollTo({
          y: yOffset,
          animated: false,
        });
      }
    }, 10),
    [],
  );

  const scrollWorkstations = useCallback(
    _.debounce((yOffset: number) => {
      if (!workstationsScrollViewRef?.current) return;

      workstationsScrollViewRef.current.scrollTo({
        x: yOffset,
        animated: false,
      });
    }, 10),
    [],
  );

  const startScrolling = () => {
    if (!isScrolling) setIsScrolling(true);

    stopScrolling();
  };

  async function handleFilterChange(
    workersFiltered: API.Worker[],
    workerstationsFiltered: API.Workstation[],
  ) {
    if (!isMounted.current) return;
    setFilterErrorMessage(null);
    if (!workersFiltered.length || !workerstationsFiltered.length) {
      if (!workersFiltered.length && !workerstationsFiltered.length) {
        setFilterErrorMessage({
          header: t('alex:dashboard.errorNoWorkersNorWorkstation'),
          message: '',
        });
      } else if (!workerstationsFiltered.length) {
        setFilterErrorMessage({
          header: t('alex:dashboard.noResult'),
          message: t('alex:dashboard.errorNoWorkstations'),
        });
      } else {
        setFilterErrorMessage({
          header: t('alex:dashboard.noResult'),
          message: t('alex:dashboard.errorNoWorkers'),
        });
      }
    } else {
      
      setFilterErrorMessage(null);
    }
  }

  function onPressWorkstationTargetMenu() {
    if (selectedWorkstationFooterData) {
      const _treeNode = API.Tree.getTreeNode(selectedWorkstationFooterData.workstation.id);
      if (API.isFailure(_treeNode)) {
        logger.warn(_treeNode);
        return;
      }
      _setTreeNode(_treeNode);
      history.push(
        RouteLocations.Workstations(
          selectedWorkstationFooterData.workstation.id,
          undefined,
          undefined,
          selectedWorkstationFooterData.shift?.id,
        ),
      );
    }
  }

  return (
    <View
      ref={refView}
      style={[
        SharedStyles.Styles.cardMain,
        {
          padding: 0,
          flex: 1,
        },
        props.versatilityPanelFullScreen ? styles.outerViewFullScreen : {},
      ]}
    >
      {accountHasNoWorkstations ? (
        <GreetingView />
      ) : (
        <>
          <View style={styles.topViewContainer}>
            {treeNode && (
              <View style={styles.iconContainer}>
                <WorkstationOrOrgUnitPath
                  parentIdsWithDataType={[API.DataType.WORKSTATION, treeNode.parentIds]}
                />
              </View>
            )}
            <View style={styles.exportIconContainer}>
              <ImportExportComponent
                importExportMenuType={ImportExportType.SkillsMatrix}
                getExportData={() =>
                  getExportDataForSkillMatrix(filteredWorkers, filteredWorkstations)
                }
                exportOnly
                fileName={ImportExportFileNames.SkillMatrix}
                style={{ width: 'auto' }}
              />
            </View>
            <TouchableOpacity
              style={styles.iconContainer}
              onPress={() => {
                setShowInfoMenu(!showInfoMenu);
              }}
            >
              <IconSVG
                svgComponent={InfoSVG}
                size={{ width: 26, height: 26 }}
                color={Colors.GreyLight}
              />
            </TouchableOpacity>
            {showInfoMenu && (
              <WorkstationWorkerLevelInfoMenu closeMenu={() => setShowInfoMenu(false)} />
            )}
            <TouchableOpacity
              style={styles.fullScreenIconContainer}
              onPress={() => {
                props.versatilityPanelFullScreenHandler(prev => !prev);
              }}
            >
              <IconSVG svgComponent={FullScreenSVG} color={Colors.GreyLight} />
            </TouchableOpacity>
          </View>

          <TableView
            commonScrollViewRef={commonScrollViewRef}
            versatilityPanelFullScreen={props.versatilityPanelFullScreen}
            filterErrorMessage={filterErrorMessage}
            workersFlatListRef={workersFlatListRef}
            computeObjectives={computeObjectives}
            selectedElement={selectedElement}
            menuObject={menuObject}
            openMenu={openMenu}
            selectedTargetsMenuElement={selectedTargetsMenuElement}
            openTargetsMenu={openTargetsMenu}
            workersWorkstationsScrollViewRef={workersWorkstationsScrollViewRef}
            computeMatrixLevelIcons={computeMatrixLevelIcons}
            workstationsScrollViewRef={workstationsScrollViewRef}
            rightContainerFullWidth={rightContainerFullWidth}
            levelsTableFullHeight={levelsTableFullHeight}
            workstationsScrollViewOffset={workstationsScrollViewOffset}
            workersScrollViewFullHeight={workersScrollViewFullHeight}
            workersScrollViewDisplayedHeight={workersScrollViewDisplayedHeight}
            workersScrollViewOffset={workersScrollViewOffset}
            isScrolling={isScrolling}
            workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
            treeNode={treeNode}
            filteredWorkers={filteredWorkers}
            workstationPanelWidthRef={workstationPanelWidthRef}
            workerPanelWidthRef={workerPanelWidthRef}
            workersByParentWithDetails={workersByParentWithDetails}
            onWorkerTableScroll={onWorkerTableScroll}
            onWorkerWorkstationTableScroll={onWorkerWorkstationTableScroll}
            onWorkstationTableScroll={onWorkstationTableScroll}
            setComputeToDoList={setComputeToDoList}
            setRightContainerFullWidth={setRightContainerFullWidth}
            setWorkersScrollViewFullHeight={setWorkersScrollViewFullHeight}
            setWorkersScrollViewDisplayedHeight={setWorkersScrollViewDisplayedHeight}
            setWorkersScrollViewOffset={setWorkersScrollViewOffset}
            setComputeMatrixLevelIcons={setComputeMatrixLevelIcons}
            setWorkstationsScrollViewOffset={setWorkstationsScrollViewOffset}
            setLevelsTableFullHeight={setLevelsTableFullHeight}
            setComputeObjectives={setComputeObjectives}
            setSelectedElement={setSelectedElement}
            setMenuObject={setMenuObject}
            setSelectedTargetsMenuElement={setSelectedTargetsMenuElement}
            setOpenTargetsMenu={setOpenTargetsMenu}
            setOpenMenu={setOpenMenu}
            setTreeNode={setTreeNode}
            getSavedScrollOffsets={getSavedScrollOffsets}
            handleShiftIconPress={_handleShiftIconPress}
          />
          {openMenu && selectedElement && (
            <WorkstationWorkerMenu
              levelIconElement={selectedElement}
              parentPanelElement={versatilityPanelElement}
              menuObject={menuObject}
              setOpenMenu={setOpenMenu}
              setTreeNode={setTreeNode}
            />
          )}
          {openTargetsMenu && selectedTargetsMenuElement && selectedWorkstationFooterData && (
            <WorkstationTargetsMenu
              targetsMenuElement={selectedTargetsMenuElement}
              parentPanelElement={versatilityPanelElement}
              selectedWorkstationFooterData={selectedWorkstationFooterData}
              setOpenTargetsMenu={setOpenTargetsMenu}
              setTreeNode={setTreeNode}
              handleOnPress={onPressWorkstationTargetMenu}
              filterData={{
                workers: filteredWorkers,
              }}
            />
          )}
        </>
      )}
    </View>
  );
};
