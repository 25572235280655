import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
  AlexIconsScrollingPanel,
  WorkstationWorkerLevelTable,
} from '../../workstation-worker-level-table/index';
import styles from './styles';
import { View, ScrollView } from 'react-native';
import * as _ from 'lodash-es';
import {
  DashboardFilterContext,
  ParentWithWorkers,
} from 'shared/context/dashboard-context/DashboardFilterContext';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { MyHub } from 'shared/util/MyHub';
import { WorkstationWorkerMenuObject } from '../../versatility-panel/VersatilityPanel';
import { Spacings } from 'shared/styles';
import Aigle from 'aigle';

interface Props {
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels;
  adaptableHeight: number;
  adaptableWidth: number;
  computeObjectives: boolean;
  workersScrollViewDisplayedHeight: number;
  menuObject: WorkstationWorkerMenuObject;
  workerWorkstationsScrollViewRef: React.MutableRefObject<ScrollView | undefined>;
  computeMatrixLevelIcons: boolean;
  workstationsScrollViewRef: React.MutableRefObject<ScrollView | undefined>;
  rightContainerFullWidth: number;
  openMenu: boolean;
  levelsTableFullHeight: number;
  workstationsScrollViewOffset: number;
  workersScrollViewFullHeight: number;
  workersScrollViewOffset: number;
  isScrolling: boolean;
  treeNode?: API.TreeNode<API.TreeDataType>;
  filteredWorkers?: API.Worker[];
  displayedLeftPanelFilteredWorkersAndParents?: Array<WorkerWithParentNode | ParentWithWorkers>;

  setDisplayedLeftPanelFilteredWorkersAndParents: (
    workers: Array<WorkerWithParentNode | ParentWithWorkers> | undefined,
  ) => void;
  setWorkstationsScrollViewOffset: (offset: number) => void;
  setLevelsTableFullHeight: (height: number) => void;
  setComputeObjectives: (bool: boolean) => void;
  setSelectedElement: (element?: Element) => void;
  setMenuObject: (obj: WorkstationWorkerMenuObject) => void;
  setOpenMenu: (bool: boolean) => void;
  onWorkerTableScroll: (offset: number) => void;
  onWorkerWorkstationTableScroll: (offset: number) => void;
  onWorkstationTableScroll: (offset: number) => void;
  setRightContainerFullWidth: (width: number) => void;
  setComputeToDoList: (bool: boolean) => void;
  setTreeNode: (treeNode?: API.TreeNode<API.TreeDataType>) => void;
  getSavedScrollOffsets: () => void;
  setSelectedTargetsMenuElement: (element?: Element) => void;
  setOpenTargetsMenu: (bool: boolean) => void;
  handleShiftIconPress: (workstationFooterData: API.WorkstationTargetActualWithDetails) => void;
}

interface FooterData {
  workstation: API.Workstation;
  shift?: API.Shift;
}

export interface WorkerWithParentNode extends API.Worker {
  nodeId: string;
}

export type WorkstationTargetActualWithDetailsMap = Map<
  string,
  API.WorkstationTargetActualWithDetails
>;

interface WorkstationsAndParentOrgUnit {
  orgUnit: API.OrganizationalUnit;
  workstations: API.Workstation[];
}

export type WorkstationsAndParentOrgUnitsMap = Map<string, WorkstationsAndParentOrgUnit>;

export const extractOrgUnitsAndWorkstationsFromMap = (
  map: WorkstationsAndParentOrgUnitsMap,
): API.TreeObject[] => {
  let _arr: API.TreeObject[] = [];
  for (let [, v] of map) {
    _arr.push(v.orgUnit);
    _arr = [..._arr, ...v.workstations];
  }

  return _arr;
};

const _WorkstationTableComponent: React.FC<Props> = props => {
  const {
    workstationTargetsStartingAtLevel,
    adaptableHeight,
    workersScrollViewDisplayedHeight,
    menuObject,
    workerWorkstationsScrollViewRef,
    computeMatrixLevelIcons,
    workstationsScrollViewRef,
    rightContainerFullWidth,
    openMenu,
    levelsTableFullHeight,
    workstationsScrollViewOffset,
    workersScrollViewFullHeight,
    workersScrollViewOffset,
    isScrolling,
    treeNode,
    filteredWorkers,
    displayedLeftPanelFilteredWorkersAndParents,

    setDisplayedLeftPanelFilteredWorkersAndParents,
    setWorkstationsScrollViewOffset,
    setLevelsTableFullHeight,
    setComputeObjectives,
    setSelectedElement,
    setMenuObject,
    setOpenMenu,
    onWorkerWorkstationTableScroll,
    onWorkstationTableScroll,
    setRightContainerFullWidth,
    setComputeToDoList,
    setTreeNode,
    getSavedScrollOffsets,
    setSelectedTargetsMenuElement,
    setOpenTargetsMenu,
    handleShiftIconPress,
  } = props;

  const isMounted = useIsMounted();

  const {
    workstations: [filteredWorkstations],
  } = useContext(DashboardFilterContext);

  const [workstationTargetActualWithDetailsMap, setWorkstationTargetActualWithDetailsMap] =
    useState<WorkstationTargetActualWithDetailsMap>();
  const [displayedUpperPanelFilteredNodes, setDisplayedUpperPanelFilteredNodes] = useState<
    API.TreeObject[] | undefined
  >();
  const [maxDisplayedFilteredWorkstationsCount, setMaxDisplayedFilteredWorkstationsCount] =
    useState(0);
  const [workstationsAndParentOrgUnitsMap, setWorkstationsAndParentOrgUnitsMap] =
    useState<WorkstationsAndParentOrgUnitsMap>();
  const [nodeCount, setNodeCount] = useState(0);

  const updateWorkstationWorkerObjectives = useCallback(
    _.debounce(computeThumbsForWorkstations, API.PERF_workersWorkstationsUpdateDebounceInMs, {
      leading: false,
      trailing: true,
    }),
    [],
  );

  useEffect(() => {
    if (!filteredWorkstations) return;

    groupWorkstationsByParentOrgUnit(filteredWorkstations);
  }, [filteredWorkstations]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKERWORKSTATION) {
        updateWorkstationWorkerObjectives(
          filteredWorkers,
          displayedUpperPanelFilteredNodes,
          displayedLeftPanelFilteredWorkersAndParents,
        );
      }
    });

    updateWorkstationWorkerObjectives(
      filteredWorkers,
      displayedUpperPanelFilteredNodes,
      displayedLeftPanelFilteredWorkersAndParents,
    );

    return () => {
      removeListener();
    };
  }, [
    displayedUpperPanelFilteredNodes,
    displayedLeftPanelFilteredWorkersAndParents,
    filteredWorkers,
  ]);

  useEffect(() => {
    if (!displayedUpperPanelFilteredNodes?.length) return;

    setMaxDisplayedFilteredWorkstationsCount(displayedUpperPanelFilteredNodes.length);
  }, [displayedUpperPanelFilteredNodes]);

  function groupWorkstationsByParentOrgUnit(filteredWorkstations: API.Workstation[]) {
    const map: WorkstationsAndParentOrgUnitsMap = new Map();
    let _nodeCount = 0;

    filteredWorkstations.map(_workstation => {
      const parentId = _workstation.parentId;

      const siblingWorkstations = map.get(parentId);
      if (siblingWorkstations) {
        siblingWorkstations.workstations.push(_workstation);
      } else {
        const _orgUnit = API.getOrganizationalUnit(parentId);
        if (API.isFailure(_orgUnit)) return;

        map.set(parentId, {
          workstations: [_workstation],
          orgUnit: _orgUnit,
        });
        _nodeCount++;
      }
      _nodeCount++;
    });

    setWorkstationsAndParentOrgUnitsMap(map);
    setNodeCount(_nodeCount);
  }

  async function computeThumbsForWorkstations(
    filteredWorkers: API.Worker[] | undefined,
    displayedFilteredNodes: API.TreeObject[] | undefined,
    displayedFilteredNodesAndWorkers?: Array<WorkerWithParentNode | ParentWithWorkers>,
  ) {
    if (!isMounted.current) return; 

    const dataForFooter: FooterData[] = [];
    const _workstationFooterDataMap: WorkstationTargetActualWithDetailsMap = new Map();

    if (
      !displayedFilteredNodes?.length ||
      !displayedFilteredNodesAndWorkers?.length ||
      !filteredWorkers?.length
    ) {
      setWorkstationTargetActualWithDetailsMap(undefined);
      return;
    }

    const shiftIds = new Set<string>();
    displayedFilteredNodes.forEach(displayedFilteredNode => {
      if (API.isOrganizationalUnit(displayedFilteredNode)) return;

      displayedFilteredNodesAndWorkers?.forEach(displayedFilteredWorkerOrNode => {
        const isNode = 'workers' in displayedFilteredWorkerOrNode;
        if (
          isNode &&
          ((!displayedFilteredWorkerOrNode.shift && !displayedFilteredNode.shiftIds?.length) ||
            (!!displayedFilteredWorkerOrNode.shift &&
              displayedFilteredNode.shiftIds?.includes(displayedFilteredWorkerOrNode.shift.id)))
        ) {
          dataForFooter.push({
            workstation: displayedFilteredNode,
            shift: displayedFilteredWorkerOrNode.shift,
          });
          if (displayedFilteredWorkerOrNode.shift)
            shiftIds.add(displayedFilteredWorkerOrNode.shift.id);
        }
      });
    });

    const filteredWorkerAssignments = await API.getWorkersAssignments(
      true,
      false,
      filteredWorkers.map(worker => worker.id),
    );
    if (!isMounted.current) return;
    if (API.isFailure(filteredWorkerAssignments)) {
      logger.error('Failed to fetch the WORS of the filtered workers ', filteredWorkerAssignments);
      return filteredWorkerAssignments;
    }

    await Aigle.eachSeries(dataForFooter, async eachData => {
      const key =
        eachData.workstation.id + (eachData.shift?.id ? API.SeparatorIds + eachData.shift.id : '');

      const workstationsTargetAndActual = await API.fetchWorkstationTargetAndWorkersAlert(
        eachData.workstation,
        workstationTargetsStartingAtLevel,
        eachData.shift ? [eachData.shift.id] : undefined,
        undefined,
        undefined,
        {
          workers: filteredWorkers,
          assignments: filteredWorkerAssignments,
        },
      );
      if (!isMounted.current) return;
      if (API.isFailure(workstationsTargetAndActual)) {
        logger.warn(workstationsTargetAndActual);
        return;
      }
      _workstationFooterDataMap.set(key, {
        workstation: eachData.workstation,
        shift: eachData.shift,
        workstationsTargetActual: workstationsTargetAndActual.workstationsTargetAndActual,
      });
    });
    if (!isMounted.current) return;

    setWorkstationTargetActualWithDetailsMap(_workstationFooterDataMap);
  }

  return (
    <>
      <View
        onLayout={e => {
          setRightContainerFullWidth(e.nativeEvent.layout.width);
        }}
        style={[styles.rightContainer]}
      >
        <View
          style={[
            styles.workstationWorkerLevelTableContainer,
            {
              height: adaptableHeight,
            },
          ]}
        >
          <WorkstationWorkerLevelTable
            workersScrollViewDisplayedHeight={workersScrollViewDisplayedHeight}
            menuObject={menuObject}
            workerWorkstationsScrollViewRef={workerWorkstationsScrollViewRef}
            computeMatrixLevelIcons={computeMatrixLevelIcons}
            workstationsScrollViewRef={workstationsScrollViewRef}
            rightContainerFullWidth={rightContainerFullWidth}
            openMenu={openMenu}
            adaptableHeight={adaptableHeight}
            levelsTableFullHeight={levelsTableFullHeight}
            workstationsScrollViewOffset={workstationsScrollViewOffset}
            workersScrollViewFullHeight={workersScrollViewFullHeight}
            workersScrollViewOffset={workersScrollViewOffset}
            isScrolling={isScrolling}
            treeNode={treeNode}
            displayedLeftPanelFilteredWorkersAndParents={
              displayedLeftPanelFilteredWorkersAndParents
            }
            displayedFilteredNodes={displayedUpperPanelFilteredNodes}
            workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
            workstationsAndParentOrgUnitsMap={workstationsAndParentOrgUnitsMap}
            nodeCount={nodeCount}
            workstationFooterDataMap={workstationTargetActualWithDetailsMap}
            setDisplayedLeftPanelFilteredWorkersAndParents={
              setDisplayedLeftPanelFilteredWorkersAndParents
            }
            setDisplayedUpperPanelFilteredNodes={setDisplayedUpperPanelFilteredNodes}
            setWorkstationsScrollViewOffset={setWorkstationsScrollViewOffset}
            setLevelsTableFullHeight={setLevelsTableFullHeight}
            setComputeObjectives={setComputeObjectives}
            setSelectedElement={setSelectedElement}
            setMenuObject={setMenuObject}
            setOpenMenu={setOpenMenu}
            onWorkerWorkstationTableScroll={onWorkerWorkstationTableScroll}
            onWorkstationTableScroll={onWorkstationTableScroll}
            setTreeNode={setTreeNode}
            getSavedScrollOffsets={getSavedScrollOffsets}
            setSelectedTargetsMenuElement={setSelectedTargetsMenuElement}
            setOpenTargetsMenu={setOpenTargetsMenu}
            handleShiftIconPress={handleShiftIconPress}
            setComputeToDoList={setComputeToDoList}
          />
        </View>

        <View
          style={[
            styles.alexIconsScrollingContainer,
            {
              display: isScrolling && maxDisplayedFilteredWorkstationsCount ? 'flex' : 'none',
              width: '100%',
              top: adaptableHeight,
            },
          ]}
        >
          <AlexIconsScrollingPanel
            workersCount={
              workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL2
                ? 3
                : workstationTargetsStartingAtLevel === API.WorkstationWorkerLevels.LEVEL3
                ? 2
                : 1
            }
            rowContainerStyle={{
              marginBottom: Spacings.Standard + Spacings.Unit / 2,
            }}
            workstationsCount={maxDisplayedFilteredWorkstationsCount}
          />
        </View>
      </View>
    </>
  );
};

export const WorkstationTableComponent = React.memo(_WorkstationTableComponent);
