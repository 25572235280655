import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { UnsupportedComponent } from './UnsupportedComponent';
import { TouchableOpacityPreventDoubleClick } from 'shared/ui-component/TouchableOpacityPreventDoubleClick';
import { Styles } from './Styles';

export enum FileType {
  pdf = 'pdf',
  image = 'image',
}

export interface FileViewerProps {
  /**
   * a url or a data-url
   */
  filePath: string;
  fileType?: FileType;
  onClick?: Function;
  fullScreenView?: boolean;
}

enum FileMime {
  pdf = 'application/pdf',
  png = 'image/png',
  bmp = 'image/bmp',
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  avif = 'image/avif',
  svg = 'image/svg+xml',
  webp = 'image/webp',
}

enum FileExtension {
  pdf = 'pdf',
  bmp = 'bmp',
  gif = 'gif',
  jpeg = 'jpeg',
  jpg = 'jpg',
  png = 'png',
  svg = 'svg',
  webp = 'webp',
}

export const FileViewerWeb: React.FC<FileViewerProps> = props => {
  const { filePath, fileType, onClick, fullScreenView } = props;
  const [numPages, setNumPages] = useState<number | null>(null);
  const [errorHappened, setErrorHappened] = useState<boolean>(false);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function errorHappenedWhileLoading() {
    setErrorHappened(true);
  }

  function renderComponent(fileType?: FileType) {
    switch (fileType) {
      case FileType.pdf:
        return (
          <ScrollView contentContainerStyle={Styles.scrollViewContainer}>
            {errorHappened ? (
              <UnsupportedComponent />
            ) : (
              <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={fullScreenView ? 800 : 250}
                    onGetTextError={errorHappenedWhileLoading}
                    onGetAnnotationsError={errorHappenedWhileLoading}
                    onRenderError={errorHappenedWhileLoading}
                    onLoadError={errorHappenedWhileLoading}
                  />
                ))}
              </Document>
            )}
          </ScrollView>
        );

      case FileType.image:
        return (
          <embed
            key={filePath}
            src={filePath}
            style={{ height: '100%', width: '100%', objectFit: 'contain', resize: 'none' }}
          ></embed>
        );

      default:
        return <UnsupportedComponent />;
    }
  }

  return (
    <TouchableOpacityPreventDoubleClick
      onPress={() => (onClick ? onClick() : null)}
      style={Styles.clickableContainer}
    >
      {renderComponent(fileType)}
    </TouchableOpacityPreventDoubleClick>
  );
};

export function getFileTypeFromDataURL(dataUrl: string): FileType | undefined {
  if (dataUrl.includes(FileMime.pdf)) return FileType.pdf;
  if (
    dataUrl.includes(FileMime.png) ||
    dataUrl.includes(FileMime.bmp) ||
    dataUrl.includes(FileMime.gif) ||
    dataUrl.includes(FileMime.jpeg) ||
    dataUrl.includes(FileMime.avif) ||
    dataUrl.includes(FileMime.svg) ||
    dataUrl.includes(FileMime.webp)
  )
    return FileType.image;
}

export function getFileTypeFromFileName(fileName: string): FileType | undefined {
  const _fileName = fileName.substr(fileName.lastIndexOf('.') + 1);

  switch (_fileName.toLowerCase()) {
    case FileExtension.bmp:
    case FileExtension.gif:
    case FileExtension.jpeg:
    case FileExtension.jpg:
    case FileExtension.png:
    case FileExtension.svg:
    case FileExtension.webp:
      return FileType.image;
    case FileExtension.pdf:
      return FileType.pdf;
  }
}
