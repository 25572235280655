import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Text, StyleProp, TextStyle } from 'react-native';
import generateStyles from './Style';

const DEFAULT_ROUNDED_WIDTH = 18;

interface Item {
  item: string;
  description?: string;
}
export interface RadioButtonProps {
  items: Array<Item>;
  initialItemIndex?: number;
  disabled?: boolean;
  rowDirection?: boolean;
  radioButtonColor?: string;
  roundedWidth?: number;
  textStyle?: StyleProp<TextStyle>;
  onChange: (text: string, index: number) => void;
}

export const RadioButton: React.FC<RadioButtonProps> = props => {
  const {
    items,
    rowDirection,
    initialItemIndex,
    disabled = false,
    radioButtonColor,
    roundedWidth,
    textStyle,
  } = props;

  const [itemSeclected, setItemSelected] = useState<number>(-1);

  const styles = generateStyles(disabled, roundedWidth ?? DEFAULT_ROUNDED_WIDTH, radioButtonColor);

  useEffect(() => {
    setItemSelected(initialItemIndex ?? -1);
  }, [props.initialItemIndex]);

  function handlePress(index: number) {
    setItemSelected(index);
    props.onChange(items[index].item, index);
  }

  function getContainerDimension(roundedWidth?: number): number {
    return roundedWidth ? roundedWidth + 4 : DEFAULT_ROUNDED_WIDTH + 4;
  }

  return (
    <View
      style={[
        {
          flexDirection: rowDirection ? 'row' : 'column',
        },
      ]}
    >
      {items.map((item, index) => (
        <View key={item.item}>
          <TouchableOpacity
            style={styles.item}
            disabled={disabled}
            onPress={() => handlePress(index)}
          >
            <View
              style={[
                styles.radioButtonContainer,
                {
                  height: getContainerDimension(roundedWidth),
                  width: getContainerDimension(roundedWidth),
                },
              ]}
            >
              <View style={styles.radioButton}>
                {itemSeclected === index && <View style={styles.radioButtonSelected} />}
              </View>
            </View>
            <Text style={[styles.boldTextStyle, textStyle]}>
              {item.item} <Text style={styles.descriptionTextStyle}>{item.description}</Text>
            </Text>
          </TouchableOpacity>
          {index !== items.length - 1 && <View style={styles.divider} />}
        </View>
      ))}
    </View>
  );
};
