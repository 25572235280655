import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { IconSVG } from '../../Icon';
import NextIcon from 'shared/assets/svg/icon.nextPage.mobile.svg';
import PreviousIcon from 'shared/assets/svg/icon.previous.small.svg';
import { Colors } from '../../../styles';
import { t } from '../../../localisation/i18n';
import { useCallOnHover } from 'shared/hooks/CallOnHover';

import styles from './styles';

interface Props {
  batchCount: number;
  maxIndex: number;
  currentIndex: number;
  totalCount: number;
  isFilterApplied: boolean;

  onSwitch: (index: number) => void;
}

export const TablePagination: React.FC<Props> = props => {
  const { totalCount, currentIndex, batchCount, isFilterApplied, onSwitch } = props;
  const [startCount, setStartCount] = useState<number>(0);
  const [endCount, setEndCount] = useState<number>(0);
  const [isHoverNext, setIsHoverNext] = useState<boolean>(false);
  const [isHoverPrevious, setIsHoverPrevious] = useState<boolean>(false);

  const nextRef = useCallOnHover<TouchableOpacity>(
    '',
    () => setIsHoverNext(true),
    () => setIsHoverNext(false),
  );

  const previousRef = useCallOnHover<TouchableOpacity>(
    '',
    () => setIsHoverPrevious(true),
    () => setIsHoverPrevious(false),
  );

  useEffect(() => {
    const _startCount = currentIndex * batchCount + 1;
    let _endCount = (currentIndex + 1) * batchCount;

    if (_endCount > totalCount) {
      _endCount = totalCount;
    }

    setStartCount(_startCount);
    setEndCount(_endCount);
  }, [currentIndex, totalCount]);

  function onClickPrevious() {
    onSwitch(currentIndex - 1);
  }

  function onClickNext() {
    onSwitch(currentIndex + 1);
  }

  return (
    <View style={styles.rootContainer}>
      <View>
        {isFilterApplied ? (
          <Text style={styles.text}>{`${startCount}-${endCount} ${t(
            'alex:table.more',
            undefined,
            false,
          )}`}</Text>
        ) : (
          <Text style={styles.text}>{`${startCount}-${endCount} ${t(
            'alex:table.on',
            undefined,
            false,
          )} ${totalCount}`}</Text>
        )}
      </View>

      <TouchableOpacity onPress={onClickPrevious} disabled={currentIndex === 0} ref={previousRef}>
        <IconSVG
          svgComponent={PreviousIcon}
          color={
            currentIndex === 0
              ? Colors.GreyLight
              : isHoverPrevious
              ? Colors.MediumGray
              : Colors.Grey
          }
        />
      </TouchableOpacity>

      <TouchableOpacity onPress={onClickNext} disabled={endCount >= totalCount} ref={nextRef}>
        <IconSVG
          svgComponent={NextIcon}
          color={
            endCount >= totalCount
              ? Colors.GreyLight
              : isHoverNext
              ? Colors.MediumGray
              : Colors.Grey
          }
        />
      </TouchableOpacity>
    </View>
  );
};
