import React, { useState, useContext } from 'react';
import { View } from 'react-native';
import { Colors, Spacings } from 'shared/styles';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { IconSVG } from 'shared/ui-component/Icon';
import { t, getGender, capitalizeFirstLetter } from 'shared/localisation/i18n';
import { useHistory } from 'react-router-dom';
import {
  TableColumn,
  Table,
  Tables,
  TableWidth,
  TableLargeColumnWidth,
  TableMediumColumnWidth,
} from 'shared/ui-component/Table';
import { EllipsisWithTooltip, Position } from 'shared/ui-component/EllipsisWithTooltip';
import { SortDirection } from '../../../../sort';
import { SkillItemRow } from '../../containers/SkillsLibrary';
import * as SharedStyles from 'shared/styles';
import Styles from './Styles';
import { RouteLocations } from '../../../../navigation/Routes';
import { ExportReport, ImportExportFileNames } from 'shared/util/ExcelUtils';
import { ImportExportType } from 'shared/util/ExcelUtils';
import { Tag } from 'shared/ui-component/Input/InputTag';
import { ModalUtils } from 'shared/ui-component/Modal';
import logger, { loggerPerf } from 'shared/util/Logger';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { TableNumberWithMenu } from 'sharedweb/src/NumberWithMenu';
import {
  DropdownConfigKey,
  TrainingTypeKey,
  ValidityFilterKey,
} from '../../../../header-layout/headerFilterConfig';
import { replaceDiacriticsAndCapitalLetter, isArrayEmpty } from 'shared/util-ts/Functions';
import { MyFactoryContext } from '../../../MyFactoryContext';
import { ModifySkillModal } from '../modify-skill-modal/component/index';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';

const SkillSVG = require('shared/assets/svg/icon.skill.svg').default;

interface SkillsTableProps {
  rows: SkillItemRow[];
  filterTags: Tag[];
  deleteSkillItem: (skillItem: SkillItemRow) => Promise<void>;
  fetchRows: (forceReload?: boolean) => Promise<void>;
  getExportData: (filteredRows: SkillItemRow[]) => Promise<ExportReport>;
}

export const _SkillsTable: React.FC<SkillsTableProps> = props => {
  const { rows, fetchRows, getExportData, deleteSkillItem } = props;

  const [showModifySkillModal, setShowModifySkillModal] = useState<boolean>(false);
  const [skillId, setSlillId] = useState<string>('');

  const modal = ModalUtils.useModal();

  const isMounted = useIsMounted();

  const history = useHistory();

  const { isValidPermission } = useContext(PermissionManagementContext);
  const { setSkillTableData } = useContext(MyFactoryContext);
  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);

  function onWorkstationPress(id: string) {
    const _treeNode = API.Tree.getTreeNode(id);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }

    setTreeNode(_treeNode);
    history.push(RouteLocations.Workstations(id));
  }

  const columns: TableColumn<SkillItemRow>[] = [
    {
      label: t('alex:skills.table.header.0'),
      sort: sortByName,
      width: TableLargeColumnWidth,
      renderCell: (row, _, indexOfRow) => (
        <View style={Styles.skillInfoContainer}>
          <IconSVG
            svgComponent={SkillSVG}
            containerStyle={SharedStyles.Styles.tableObjectIconContainer}
            color={Colors.White}
          />
          <EllipsisWithTooltip
            text={capitalizeFirstLetter(row.skill.name)}
            textStyle={SharedStyles.Styles.tableText}
            style={Styles.ellipsisText}
            position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
          />
        </View>
      ),
    },
    {
      label: t('alex:skills.table.header.5'),
      sort: sortByTag,
      width: TableMediumColumnWidth,
      renderCell: (row, _, indexOfRow) => {
        return (
          <EllipsisWithTooltip
            text={
              row.skillTags
                ? row.skillTags.map(tagItem => capitalizeFirstLetter(tagItem.name)).toString()
                : '-'
            }
            textStyle={SharedStyles.Styles.tableText}
            style={Styles.ellipsisText}
            position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
          />
        );
      },
    },
    {
      label: t('alex:skills.table.header.3'),
      sort: sortByValidity,
      width: TableWidth,
      renderCell: (row, index, indexOfRow) => {
        return (
          <EllipsisWithTooltip
            text={
              row.validityDurationAndExpiryNoticeDuration.validityDuration
                ? `${row.validityDurationAndExpiryNoticeDuration.validityDuration} ${t(
                    'common:time.month',
                    {
                      count: row.validityDurationAndExpiryNoticeDuration.validityDuration,
                    },
                  )}`
                : t('alex:workerSkill.neverExpire')
            }
            textStyle={SharedStyles.Styles.tableText}
            position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
          />
        );
      },
    },
    {
      label: t('alex:skills.table.header.1'),
      width: TableWidth,
      sort: sortByWorkstations,
      renderCell: row => {
        return row.workstations === undefined ? (
          <LoaderThreeDots />
        ) : (
          <TableNumberWithMenu list={row.workstations} onMenuItemPress={onWorkstationPress} />
        );
      },
    },
    {
      label: t('alex:skills.table.header.4'),
      sort: sortByPractical,
      width: TableWidth,
      renderCell: row => {
        return (
          <EllipsisWithTooltip
            text={
              row.isPractical ? t('glossary:trainingPractical') : t('glossary:trainingNotPractical')
            }
            textStyle={SharedStyles.Styles.tableText}
          />
        );
      },
    },
  ];

  function sortByTag(rows: SkillItemRow[], sortDirection: SortDirection) {
    return _.orderBy(
      rows,
      e =>
        _.map(e.skillTags, tag => tag.name)
          .join(', ')
          .toLowerCase(),
      [sortDirection],
    );
  }

  function sortByPractical(rows: SkillItemRow[], sortDirection: SortDirection) {
    return _.orderBy(rows, e => e.isPractical, [sortDirection]);
  }

  function sortByName(rows: SkillItemRow[], sortDirection: SortDirection) {
    return _.orderBy(rows, e => replaceDiacriticsAndCapitalLetter(e.skill.name), [sortDirection]);
  }

  function sortByValidity(rows: SkillItemRow[], sortDirection: SortDirection) {
    return _.orderBy(rows, e => e.validityDurationAndExpiryNoticeDuration.validityDuration, [
      sortDirection,
    ]);
  }

  function sortByWorkstations(rows: SkillItemRow[], sortDirection: SortDirection) {
    return _.orderBy(rows, e => e.workstations?.length, [sortDirection]);
  }

  function filterData(
    isKeyWordFiltering: boolean,
    filterTags: TagExtended[],
    skills: SkillItemRow[],
  ): SkillItemRow[] {
    if (isKeyWordFiltering && isArrayEmpty(filterTags)) return [];

    let _filteredSkills: SkillItemRow[] = isKeyWordFiltering ? [] : skills;

    const skillFilters: TagExtended[] = [];
    const skillValidityFilter: TagExtended[] = [];
    const tagFilter: TagExtended[] = [];
    const trainingTypeFilter: TagExtended[] = [];

    _.forEach(filterTags, tag => {
      if (tag.type === DropdownConfigKey.SKILL) skillFilters.push(tag);
      else if (tag.type === DropdownConfigKey.SKILL_TAG) tagFilter.push(tag);
      else if (tag.type === DropdownConfigKey.VALIDITY) skillValidityFilter.push(tag);
      else if (tag.type === DropdownConfigKey.TYPE_OF_TRAINING) trainingTypeFilter.push(tag);
    });

    if (skillFilters.length) {
      const data = _.filter(isKeyWordFiltering ? skills : _filteredSkills, row =>
        _.some(skillFilters, filter => filter.key === row.skill.id),
      );

      if (isKeyWordFiltering) {
        _filteredSkills = [..._filteredSkills, ...data];
      } else {
        _filteredSkills = data;
      }
    }

    if (tagFilter.length) {
      const data = _.filter(isKeyWordFiltering ? skills : _filteredSkills, row => {
        if (row.skillTags === undefined) return true;

        return _.some(tagFilter, filter =>
          _.some(row.skillTags, skillTag => skillTag.id === filter.key),
        );
      });

      if (isKeyWordFiltering) {
        _filteredSkills = [..._filteredSkills, ...data];
      } else {
        _filteredSkills = data;
      }
    }

    if (skillValidityFilter.length) {
      const data = _.filter(isKeyWordFiltering ? skills : _filteredSkills, row =>
        _.some(skillValidityFilter, filter => {
          if (filter.key === ValidityFilterKey.LIMITLESS) {
            return row.validityDurationAndExpiryNoticeDuration.validityDuration === null;
          } else if (row.validityDurationAndExpiryNoticeDuration.validityDuration) {
            return filter.value <= row.validityDurationAndExpiryNoticeDuration.validityDuration;
          }
        }),
      );

      if (isKeyWordFiltering) {
        _filteredSkills = [..._filteredSkills, ...data];
      } else {
        _filteredSkills = data;
      }
    }

    if (trainingTypeFilter.length) {
      const data = _.filter(isKeyWordFiltering ? skills : _filteredSkills, row =>
        _.some(trainingTypeFilter, filter => {
          if (filter.key === TrainingTypeKey.PRACTICAL) {
            return row.skill.isPractical;
          } else {
            return !row.skill.isPractical;
          }
        }),
      );

      if (isKeyWordFiltering) {
        _filteredSkills = [..._filteredSkills, ...data];
      } else {
        _filteredSkills = data;
      }
    }

    return _.uniqBy(_filteredSkills, skill => skill.skill.id);
  }

  async function filterRows(rows: SkillItemRow[], tags: TagExtended[]): Promise<SkillItemRow[]> {
    if (!tags.length) return rows;

    let _filteredRows: SkillItemRow[] = rows;
    let _filterTags: TagExtended[] = [];
    let _keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(tags, tag => {
      if (tag.isActiveBookmarkTag) {
        _filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        _keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        _filterTags.push(tag);
      } else {
        _filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      _filteredRows = filterData(true, _keywordTags, _filteredRows);
    }

    if (_filterTags.length) {
      _filteredRows = filterData(false, _filterTags, _filteredRows);
    }

    return _filteredRows;
  }

  async function onMenuPress(skillItem: SkillItemRow, key: 'edit' | 'delete') {
    if (key === 'edit') {
      setSlillId(skillItem.skill.id);
      setShowModifySkillModal(true);
    } else if (key === 'delete') {
      const skillDependencies = await API.getSkillDependencies(skillItem.skill.id);
      if (!isMounted.current) return;
      if (API.isFailure(skillDependencies)) return skillDependencies;

      if (!skillDependencies.length) {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: `${t('common:error.delete', { context: getGender('skill') })} ${
              skillItem && skillItem.skill.name
            }?`,
            warningAcceptCallback: () => {
              if (skillItem) deleteSkillItem(skillItem);
            },
            warningAcceptButton: t('common:button.yes'),
            warningCancelButton: t('common:button.no'),
          }),
        );
      } else {
        if (skillItem) deleteSkillItem(skillItem);
      }
    }
  }

  async function fetchRowDetails(
    row: SkillItemRow,
    isRowMounted: React.MutableRefObject<boolean>,
  ): Promise<boolean> {
    let updated = false;

    if (row.trainingVersionTrainings === undefined) {
      const skillTrainingVersionTrainings = await API.getTrainingVersionAndTrainingsForSkill(
        row.skill.id,
        true,
      );
      if (!isRowMounted.current) return updated;
      if (API.isFailure(skillTrainingVersionTrainings)) {
        logger.warn(
          'Failed while fetching trainings for skill id=' + row.skill.id,
          skillTrainingVersionTrainings,
        );
      } else {
        row.trainingVersionTrainings = skillTrainingVersionTrainings;
        updated = true;
      }
    }

    if (row.workstations === undefined) {
      const skillWorkstations = await API.getSkillWorkstations(row.skill.id);
      if (!isRowMounted.current) return updated;
      if (API.isFailure(skillWorkstations)) {
        logger.warn('Failed while fetching skill workstation in skill table', skillWorkstations);
      } else {
        row.workstations = skillWorkstations;
        updated = true;
      }
    }

    if (row.skillTags === undefined) {
      const _skillTags = _.compact(
        await Promise.all(
          _.map(row.skill.tagIds, async tagId => {
            const _skillTag = await API.getSkillTag(tagId);
            if (!isMounted.current) return;
            if (API.isFailure(_skillTag)) {
              logger.warn('Failed to fetch skill tag', _skillTag);
            } else {
              updated = true;
              return _skillTag;
            }
          }),
        ),
      );

      row.skillTags = _skillTags;
    }

    return updated;
  }

  function saveRowData(rows: SkillItemRow[]) {
    setSkillTableData(rows);
  }

  return (
    <View style={[SharedStyles.Styles.cardMain, Styles.tableContainer]}>
      <Table
        columnDescriptors={columns}
        rows={rows}
        sortPreferenceKey={Tables.SKILLS}
        containerStyle={{
          borderRadius: Spacings.Small,
        }}
        rowMenu={row => {
          return [
            {
              label: 'common:button.edit',
              onPress: () => onMenuPress(row, 'edit'),
              disable: !isValidPermission(API.Permission.skills_edit),
            },
            {
              label: 'common:button.delete',
              onPress: () => onMenuPress(row, 'delete'),
              disable: !isValidPermission(API.Permission.skills_edit),
            },
          ];
        }}
        rowLazyLoadProperties={fetchRowDetails}
        importExport={{
          excelIcon: true,
          getExportData: getExportData,
          refreshData: async () => {
            fetchRows(true);
          },
          importExportType: ImportExportType.Skill,
          importExportFileName: ImportExportFileNames.Skills,
          showImportExport: isValidPermission(API.Permission.skills_edit),
        }}
        filter={{
          filterRows: filterRows,
          tags: props.filterTags,
        }}
        onRowPress={row => history.push(RouteLocations.SkillProfile(row.skill.id))}
        disableRowClick={!isValidPermission(API.Permission.skills_edit)}
        saveRowData={saveRowData}
      />
      {showModifySkillModal && (
        <ModifySkillModal
          skillId={skillId}
          handleModalClose={() => setShowModifySkillModal(false)}
        />
      )}
    </View>
  );
};

export const SkillsTable = React.memo(_SkillsTable, (prevProps, nextProps) => {
  const propsAreEqual =
    (prevProps.rows === nextProps.rows || (!prevProps.rows.length && !nextProps.rows.length)) &&
    (prevProps.filterTags === nextProps.filterTags ||
      (!prevProps.filterTags.length && !nextProps.filterTags.length));
  if (!propsAreEqual)
    loggerPerf.debug(
      'Re-Rendering component SkillsTable (prevProps, nextProps)',
      prevProps,
      nextProps,
    );
  return propsAreEqual;
});
