import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginLeft: '1.1vw',
  },
  tagButton: {
    width: '7.2vw',
    backgroundColor: '#D6D6D6',
    paddingTop: '0.075vh',
    paddingBottom: '0.075vh',
    borderRadius: 8,
    marginTop: '1vh',
    flexDirection: 'row',
  },
  tagLabel: {
    width: '100%',
    color: '#666666',
    fontFamily: 'Lato, Regular',
    fontSize: '1.5vh' as any,
  },
  tagLabelContainer: {
    width: '80%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: '0.6vw',
    
  },
  closeButtonContainer: {
    width: '20%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    
  },
  closeButtonIcon: {
    height: '1vh',
    width: '1vh',
    resizeMode: 'contain',
    alignSelf: 'center',
    marginRight: '1vw',
  },
});

export default styles;
