import React, { useContext } from 'react';
import { Avatar } from 'shared/ui-component/Avatar';
import { View, Text, StyleSheet, StyleProp, ViewStyle, Platform } from 'react-native';
import { Spacings } from '../../styles';
import { Worker } from 'shared/backend-data';
import { t } from 'shared/localisation/i18n';
import { ImageSizes } from 'shared/util/RenderImage';
import * as SharedStyles from 'shared/styles/index';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { EllipsisWithTooltip } from '../../ui-component/EllipsisWithTooltip';
import { PlainLevelIcon } from '../../ui-component/Icon/LevelIcon/LevelIcon';
import { GlobalDataContext } from '../../skillmgt/context/GlobalDataContext';

const isMobilePlatform = Platform.OS === 'android' || Platform.OS === 'ios';
const IconCheck = require('shared/assets/svg/icon.check.svg').default;

interface WorkerSkillProps {
  workstationWorkerLevel?: API.WorkerWorkstation;
  isTrainer?: boolean;
  isPracticalTraining: boolean;
}

export interface WorkerRowProps extends WorkerSkillProps {
  displayWorkerLevelIcon: boolean;
  worker: Worker;
  style?: StyleProp<ViewStyle>;
  isAbsent?: boolean;
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginBottom: Spacings.Unit,
    height: 24,
    overflow: 'hidden',
    flex: 1,
  },
  leftContent: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: isMobilePlatform ? 0.7 : 0.65,
  },
  workerName: {
    ...SharedStyles.TextFontStyles.LatoMedium,
    fontSize: isMobilePlatform ? 16 : 14,
    paddingLeft: Spacings.Small,
    flexShrink: 1,
  },
  rightContent: {
    flexDirection: 'row',
    flex: 0.35,
    alignItems: 'center',
    height: '100%',
    marginLeft: Spacings.Small,
    justifyContent: 'flex-start',
  },
  boldTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Regular,
  },
  regularTextStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Regular,
  },
  levelAbbreviatedTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: SharedStyles.fontSize.Regular,
    marginRight: Spacings.Unit / 2,
  },
  wWLevelContainer: { paddingBottom: Spacings.Unit, paddingRight: Spacings.Unit },
  absentTextStyle: {
    ...SharedStyles.TextFontStyles.LatoMedium,
    fontSize: SharedStyles.fontSize.Regular,
    color: SharedStyles.Colors.GreyLight,
  },
  iconCheckContainer: { height: 12, width: 12 },
  presenceContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 0.3,
    alignItems: 'center',
    height: '100%',
    marginLeft: Spacings.Small,
  },
});

const WorkerSkillText: React.FC<WorkerSkillProps> = props => {
  const { isTrainer, workstationWorkerLevel, isPracticalTraining } = props;
  return (
    <>
      {isTrainer ? (
        <Text style={styles.regularTextStyle} numberOfLines={1}>
          {isPracticalTraining ? t('glossary:mentor') : t('glossary:trainer')}
        </Text>
      ) : (
        workstationWorkerLevel && (
          <>
            <Text style={[styles.levelAbbreviatedTextStyle]} numberOfLines={1}>
              {t('glossary:levelAbbreviated')}
            </Text>
            <Text style={styles.boldTextStyle} numberOfLines={1}>
              {workstationWorkerLevel.level
                ? API.api2workstationWorkerLevels(workstationWorkerLevel.level)
                : ''}
            </Text>
          </>
        )
      )}
    </>
  );
};

export const WorkerRow: React.FC<WorkerRowProps> = props => {
  const {
    worker,
    workstationWorkerLevel,
    displayWorkerLevelIcon = false,
    isTrainer = false,
    style,
    isPracticalTraining,
    isAbsent,
  } = props;
  const showLevelIcon = displayWorkerLevelIcon && workstationWorkerLevel && isPracticalTraining;
  const { levelIconType } = useContext(GlobalDataContext);

  return (
    <View style={[styles.row, isTrainer && { marginBottom: 0 }, style]}>
      <View style={[styles.leftContent, isTrainer || showLevelIcon ? { flex: 1 } : null]}>
        <Avatar
          size={ImageSizes.Small}
          imgS3Key={worker && worker.profilePicture}
          name={worker?.name ?? ''}
        />
        {isMobilePlatform ? (
          <EllipsisWithTooltip
            textStyle={styles.workerName}
            text={worker?.name ?? ''}
            style={{ width: '90%' }}
          />
        ) : (
          <EllipsisWithTooltip textStyle={styles.workerName} text={worker?.name ?? ''} />
        )}
      </View>

      <>
        {_.isUndefined(isAbsent) ? (
          <View style={[isTrainer || showLevelIcon ? styles.rightContent : null]}>
            {showLevelIcon && (
              <View style={styles.wWLevelContainer}>
                <PlainLevelIcon
                  level={workstationWorkerLevel.level ?? API.WorkstationWorkerLevel.LEVEL0}
                  levelIconType={levelIconType}
                  iconSize={{ height: Spacings.CardPadding, width: Spacings.CardPadding }}
                />
              </View>
            )}
            {(isTrainer || showLevelIcon) && (
              <WorkerSkillText
                isTrainer={isTrainer}
                workstationWorkerLevel={workstationWorkerLevel}
                isPracticalTraining={isPracticalTraining}
              />
            )}
          </View>
        ) : !isMobilePlatform && !isPracticalTraining ? (
          <View style={[styles.presenceContainer]}>
            {isAbsent ? (
              <Text style={styles.absentTextStyle}>
                {t('alex:worker.profile.infoPanel.absent')}
              </Text>
            ) : (
              <View style={styles.iconCheckContainer}>
                <IconCheck color={SharedStyles.Colors.Green} />
              </View>
            )}
          </View>
        ) : (
          <></>
        )}
      </>
    </View>
  );
};
