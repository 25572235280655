import React from 'react';
import { View, Text, Platform } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { Colors } from 'shared/styles';
import { OutlineButton } from 'shared/ui-component/Button';
import { IconSVG } from 'shared/ui-component/Icon';

import Styles from './styles';

const PracticalTrainingIcon = require('shared/assets/svg/icon.practTrain.svg').default;
const LectureTrainingIcon = require('shared/assets/svg/icon.lecture.svg').default;
const CompletedIcon = require('shared/assets/svg/icon.checked.small.svg').default;

interface Props {
  onCommonProofPress: () => void;
  isChapterContainsGhostTrainee?: boolean;

  isProofBookCompleted: boolean;
  isTrainingSessionPractical: boolean;
}

export const AddCommonProof: React.FC<Props> = props => {
  const {
    onCommonProofPress,
    isChapterContainsGhostTrainee,
    isProofBookCompleted,
    isTrainingSessionPractical,
  } = props;

  return (
    <View style={Styles.container}>
      {(Platform.OS === 'web' || isProofBookCompleted) && (
        <View style={[!isProofBookCompleted && Styles.trainingIcon]}>
          <IconSVG
            svgComponent={isTrainingSessionPractical ? PracticalTrainingIcon : LectureTrainingIcon}
            size={{ width: 80, height: 80 }}
            color={Colors.GreyLight}
          />
        </View>
      )}

      <View style={Styles.proofBookComplete}>
        <Text style={Styles.proofBookCompleteTitle}>
          {t('alex:trainingSessions.addTrainingModal.completeProofBook')}
        </Text>
        {isProofBookCompleted ? (
          <IconSVG svgComponent={CompletedIcon} color={Colors.MediumLighterGrey} />
        ) : null}
      </View>
      {!isProofBookCompleted && (
        <>
          <Text style={Styles.proofBookCompleteDescription}>
            {t('alex:trainingSessions.addTrainingModal.completeProofBookDescription')}
          </Text>
          <View style={Styles.addCommonProofButtonContainer}>
            <OutlineButton
              text={t('alex:trainingSessions.addTrainingModal.addCommonProofForWorkerSkills.0')}
              textStyle={Styles.addCommonProofText}
              style={Styles.addCommonProofContainer}
              onPress={onCommonProofPress}
              disabled={isChapterContainsGhostTrainee}
            />
          </View>
        </>
      )}
    </View>
  );
};
