import React, { useEffect, useState } from 'react';
import { WorkerSkillAddModalComponent } from '../component/AddWorkerSkillModal';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { InteractionManager } from 'react-native';
import { sortWorkers } from 'shared/backend-data';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';

export interface Qualification {
  skill: API.Skill;
  startingDate: string;
  proofFiles: any[];
  description: string | undefined;
}

export interface SkillDropDownOption extends DropDownOption {
  value: API.Skill;
}
export interface WorkerDropDownOption extends DropDownOption {
  value: API.Worker;
}

interface Props {
  selectedWorkers?: API.Worker[];
  selectedSkills?: API.Skill[];
  arrowBack?: boolean;
  revokeProof?: boolean;
  renewProof?: boolean;
  submitProofAgain?: boolean;
  resubmitProof?: API.ProofBundle;

  openProofBook?: (workerIds: string[], skillIds: string[]) => void;
  showToastMessage?: (text: string, callback: Function) => void;
  refreshSkills?: () => Promise<void>;
  renderCustomHeader?: () => JSX.Element;
  handleModalClose: () => void;
}

export const WorkerSkillAddModal: React.FC<Props> = props => {
  const { openProofBook } = props;

  const [skillOptions, setSkillOptions] = useState<SkillDropDownOption[]>();
  const [workersOptions, setWorkersOptions] = useState<WorkerDropDownOption[]>();
  const [selectedSkills, setSelectedSkills] = useState<API.Skill[] | undefined>(
    props.selectedSkills,
  );
  const [selectedWorkers, setSelectedWorkers] = useState<API.Worker[] | undefined>(
    props.selectedWorkers,
  );
  const [modalVisible, setModalVisible] = useState(true);
  const [skillObtentionDate, setSkillObtentionDate] = useState<Date>(new Date());
  const [comment, setComment] = useState<string>();

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      await loadSkills();
      if (!isMounted.current) return;

      await loadWorkers();
      if (!isMounted.current) return;
    });
  }, []);

  useEffect(() => {
    if (props.resubmitProof?.description) setComment(props.resubmitProof.description);
    if (props.resubmitProof?.review.date)
      setSkillObtentionDate(new Date(props.resubmitProof.review.date));
  }, [props.resubmitProof]);

  async function loadWorkers() {
    const workers = await API.getWorkers();
    if (API.isFailure(workers)) {
      logger.error(workers);
    } else {
      const options: WorkerDropDownOption[] = [];
      sortWorkers(workers.result).forEach(worker => {
        options.push({
          key: worker.id,
          value: worker,
          label: capitalizeFirstLetter(worker.name),
        });
      });
      if (isMounted.current) setWorkersOptions(options);
    }
  }

  async function loadSkills() {
    const skills = await API.getSkills();
    if (API.isFailure(skills)) {
      logger.warn(skills);
    } else {
      const options: SkillDropDownOption[] = [];
      skills.result.forEach(skill => {
        options.push({
          key: skill.id,
          value: skill,
          label: capitalizeFirstLetter(skill.name),
        });
      });
      if (isMounted.current) setSkillOptions(_.sortBy(options, option => option.label));
    }
  }

  function navigateToSkillBook() {
    if (openProofBook && selectedSkills && selectedWorkers) {
      openProofBook(
        selectedWorkers?.map(worker => worker.id),
        selectedSkills.map(skill => skill.id),
      );
      handleModalClose();
    }
  }

  const handleModalClose = (): void => {
    if (!isMounted.current) return;
    setModalVisible(false);
    if (props.handleModalClose) props.handleModalClose();
  };

  return (
    <>
      <WorkerSkillAddModalComponent
        skillOptions={skillOptions}
        workerOptions={workersOptions}
        arrowBack={props.arrowBack}
        revokeProof={props.revokeProof}
        renewProof={props.renewProof}
        submitProofAgain={props.submitProofAgain}
        refreshSkills={props.refreshSkills}
        showToastMessage={props.showToastMessage}
        renderCustomHeader={props.renderCustomHeader}
        navigateToSkillBook={navigateToSkillBook}
        setSelectedSkills={setSelectedSkills}
        setSelectedWorkers={setSelectedWorkers}
        selectedSkills={selectedSkills}
        selectedWorkers={selectedWorkers}
        modalVisible={modalVisible}
        handleModalClose={handleModalClose}
        setSkillObtentionDate={setSkillObtentionDate}
        setComment={setComment}
        skillObtentionDate={skillObtentionDate}
        comment={comment}
      />
    </>
  );
};
