import React from 'react';
import { View, ActivityIndicator as ActivityIndicatorReact } from 'react-native';
import WebStyles from './WebStyles';
import { Colors } from 'shared/styles';
import { StyleProp, ViewStyle } from 'react-native';

export enum ActivityIndicatorSize {
  large = 'large',
  small = 'small',
}

interface ActivityIndicatorProps {
  style?: StyleProp<ViewStyle>;
  size?: ActivityIndicatorSize;
}

export const ActivityIndicator = (props: ActivityIndicatorProps) => {
  const { size = ActivityIndicatorSize.large } = props;
  return (
    <View style={[WebStyles.activityIndicatorView, props.style]}>
      <ActivityIndicatorReact color={Colors.Yellow} size={size} />
    </View>
  );
};
