import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import { WorkstationWorkerLevels } from 'shared/util/WorkerLevel';
import { t } from 'shared/localisation/i18n';
import {
  DropDownSingleSelection,
  DropDownSingleSelectionProps,
} from 'shared/ui-component/DropDown/DropDown';
import { workstationWorkerLevels2api } from 'shared/util/WorkerLevel';
import { Styles } from './Styles';
import { PlainLevelIcon } from '../../Icon/LevelIcon/LevelIcon';
import { LevelIconType } from 'backend/src/api';
import { Spacings } from '../../../styles';
import { DropDownWithWorkerLevelOption } from '../DropDownWithWorkerLevel';

export const dropDownWithCustomLevelIcon: DropDownWithWorkerLevelOption[] = [
  {
    key: LevelIconType.LINE,
    label: t('common:tenant.levelIconLabel.2'),
    level: WorkstationWorkerLevels.LEVEL3,
    value: LevelIconType.LINE,
  },
  {
    key: LevelIconType.PIE,
    label: t('common:tenant.levelIconLabel.0'),
    level: WorkstationWorkerLevels.LEVEL3,
    value: LevelIconType.PIE,
  },
  {
    key: LevelIconType.SQUARE,
    label: t('common:tenant.levelIconLabel.1'),
    level: WorkstationWorkerLevels.LEVEL3,
    value: LevelIconType.SQUARE,
  },
];

function renderLevel(value: DropDownWithWorkerLevelOption | undefined): JSX.Element {
  if (!value) return <View />;

  return (
    <View style={Styles.levelContainer}>
      <PlainLevelIcon
        level={workstationWorkerLevels2api(value.level)}
        iconSize={{ height: Spacings.CardPadding, width: Spacings.CardPadding }}
        levelIconType={value.value}
      />
    </View>
  );
}

export const DropDownWithCustomLevelIcon: React.FC<
  DropDownSingleSelectionProps<DropDownWithWorkerLevelOption>
> = props => {
  const _props = {
    ...props,
    renderOption: (value: DropDownWithWorkerLevelOption | undefined) => {
      return renderLevel(value);
    },
  };
  return DropDownSingleSelection(_props);
};
