import { StyleSheet } from 'react-native';
import { TextFontStyles, Colors, Spacings, getCorrectedSpacings } from '../../../styles';

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: 'rgba(52, 52, 52, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    backgroundColor: 'white',
    borderRadius: 5,
    paddingHorizontal: Spacings.Standard,
    paddingTop: getCorrectedSpacings(26.5),
    paddingBottom: Spacings.CardPadding,
    width: 330,
    height: 196,
  },
  buttonText: {
    ...TextFontStyles.LatoBold,
    fontSize: 16,
    color: Colors.Black,
  },
  warningText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 18,
    color: Colors.Black,
  },
  checkBoxText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    color: Colors.Black,
  },
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: Spacings.xMedium,
    top: Spacings.Small,
  },
  textContainer: {
    minHeight: getCorrectedSpacings(99),
  },
  warningSubTextContainer: {
    maxHeight: 400,
  },
  warningSubText: {
    paddingTop: Spacings.Medium,
  },
  inputTextContainer: {
    marginBottom: Spacings.CardPadding,
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingTop: Spacings.xMedium,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    padding: Spacings.Small,
    paddingHorizontal: getCorrectedSpacings(31),
    backgroundColor: Colors.White,
    borderRadius: Spacings.CardPadding,
    borderWidth: 1,
  },
  buttonSeparator: {
    width: Spacings.Medium,
  },
  cancelButtonContainer: {
    flex: 1,
  },
});

export default styles;
