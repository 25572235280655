import { StyleSheet } from 'react-native';
import { Colors, createRoundedStyle, fontSize, Spacings, TextFontStyles } from 'shared/styles';

const styles = StyleSheet.create({
  fullWidth: { width: '100%' },
  leftPanel: {
    flex: 1,
    maxWidth: '50%',
    backgroundColor: Colors.Background,
    borderBottomLeftRadius: Spacings.Small,
  },
  leftPanelContentContainer: {
    flex: 1,
    borderBottomLeftRadius: Spacings.Small,
  },
  alexIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Spacings.Large,
    marginBottom: 18,
  },
  addProofContainer: {
    flex: 1,
  },
  addCommonProofForWorkerSkills: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.MediumLighterGrey,
  },
  addCommonProofForWorkerDescription: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.MediumLighterGrey,
  },
  addCommonProofForWorkerSkillsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 60,
    textAlign: 'center',
  },
  proofAddIconContainer: {
    flexDirection: 'row',
    paddingVertical: Spacings.Medium,
    borderBottomColor: Colors.GreyLighter,
    borderBottomWidth: 0.5,
    borderStyle: 'dashed',
    alignItems: 'center',
    flex: 1,
  },
  proofAddText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingLeft: Spacings.Medium,
  },
  proofAddContainer: {
    paddingVertical: Spacings.Standard,
    paddingHorizontal: Spacings.Big - Spacings.Unit,
  },
  leftPanelSecondPage: {
    width: '100%',
    borderBottomLeftRadius: Spacings.Small,
    paddingBottom: Spacings.CardPadding,
  },
  addRemoveProofHeader: {
    width: '100%',
    height: Spacings.Large,
    flexDirection: 'row',
    paddingRight: 34,
    top: Spacings.Small,
    right: Spacings.Unit,
    backgroundColor: 'transparent',
    zIndex: Spacings.CardPadding,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addRemoveProofContainer: {
    flexDirection: 'row',
    width: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  circleView: {
    ...createRoundedStyle(24),
    backgroundColor: Colors.Yellow,
    marginRight: Spacings.Small,
  },
  roundTouchable: { borderRadius: 100, marginRight: Spacings.Small },
  proofsPreviewContainer: {
    flex: 1,
    paddingBottom: Spacings.Unit,
    width: '100%',
    height: '100%',
  },
  proofsPreviewScrollContentContainer: {
    width: 400,
    height: 386,
  },
  commonProofLabel: {
    ...TextFontStyles.LatoRegular,
    paddingLeft: Spacings.Big - Spacings.Unit,
    color: Colors.Grey,
    fontSize: fontSize.Small,
  },
});

export default styles;
