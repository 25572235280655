import React from 'react';
import { WorkstationModalComponent } from '../component/WorkstationModalComponent';
import * as API from 'shared/backend-data';

export interface WorkstationModalProps {
  modalVisible: boolean;
  initialOrgUnitId: string;
  selectedWorkstation: API.Workstation | undefined;
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels;

  handleModalClose: () => void;
  refreshWorkstations: () => Promise<void>;
  setSelectedWorkstation: React.Dispatch<React.SetStateAction<API.Workstation | undefined>>;
}

export const WorkstationModal: React.FC<WorkstationModalProps> = props => {
  return <WorkstationModalComponent {...props} />;
};
