import { StyleSheet } from 'react-native';
import { Colors, Spacings } from 'shared/styles';

const styles = StyleSheet.create({
  rootContainer: {
    backgroundColor: Colors.White,
    flex: 1,
    flexDirection: 'row',
  },
  leftGraph: {
    marginRight: Spacings.Medium,
  },
});

export default styles;
