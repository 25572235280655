import { StyleSheet } from 'react-native';
import { Spacings } from 'shared/styles';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 545,
    alignItems: 'center',
  },
  scrollView: { width: '100%', maxHeight: '87%' },
  scrollViewContentContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
  pageContainer: { width: '100%', height: '100%' },
  pageSwitch: { marginBottom: Spacings.Medium },
  iUnderstand: { width: '20%' },
  animatedView: {
    height: Spacings.Small,
    width: Spacings.Small,
    margin: 6,
    borderRadius: 5,
  },
});
