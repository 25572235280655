import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import { WorkstationWorkerLevels, getWorkstationWorkerLevelLabel } from 'shared/util/WorkerLevel';
import { t } from 'shared/localisation/i18n';
import {
  DropDownSingleSelection,
  DropDownSingleSelectionProps,
  DropDownOption,
} from 'shared/ui-component/DropDown/DropDown';
import { workstationWorkerLevels2api } from 'shared/util/WorkerLevel';
import { Styles } from './Styles';
import { PlainLevelIcon } from '../../Icon/LevelIcon/LevelIcon';
import { LevelIconType } from 'backend/src/api';
import { Spacings } from '../../../styles';
import { GlobalDataContext } from '../../../skillmgt/context/GlobalDataContext';

export interface DropDownWithWorkerLevelOption extends DropDownOption {
  level: WorkstationWorkerLevels;
}

export const dropDownWorkerLevels: DropDownWithWorkerLevelOption[] = [
  {
    key: workstationWorkerLevels2api(WorkstationWorkerLevels.LEVEL1),
    label: workstationWorkerLevels2api(WorkstationWorkerLevels.LEVEL1),
    level: WorkstationWorkerLevels.LEVEL1,
  },
  {
    key: workstationWorkerLevels2api(WorkstationWorkerLevels.LEVEL2),
    label: workstationWorkerLevels2api(WorkstationWorkerLevels.LEVEL2),
    level: WorkstationWorkerLevels.LEVEL2,
  },
  {
    key: workstationWorkerLevels2api(WorkstationWorkerLevels.LEVEL3),
    label: workstationWorkerLevels2api(WorkstationWorkerLevels.LEVEL3),
    level: WorkstationWorkerLevels.LEVEL3,
  },
  {
    key: workstationWorkerLevels2api(WorkstationWorkerLevels.LEVEL4),
    label: workstationWorkerLevels2api(WorkstationWorkerLevels.LEVEL4),
    level: WorkstationWorkerLevels.LEVEL4,
  },
];

function renderLevel(
  value: DropDownWithWorkerLevelOption | undefined,
  levelIconType: LevelIconType,
  notEditable: boolean = false,
  hideWorkerLevel?: boolean,
): JSX.Element {
  if (!value) return <View />;
  return (
    <>
      {hideWorkerLevel ? null : (
        <View style={Styles.levelContainer}>
          <View style={Styles.levelIcon}>
            <PlainLevelIcon
              level={workstationWorkerLevels2api(value.level)}
              iconSize={{ height: Spacings.CardPadding, width: Spacings.CardPadding }}
              levelIconType={levelIconType}
            />
          </View>
          <Text style={[Styles.subText, Styles.regularTextStyle]}>{t('glossary:level')}&nbsp;</Text>
          <Text style={[Styles.level, Styles.regularTextStyle]}>
            {getWorkstationWorkerLevelLabel(value.level, true)}
          </Text>
        </View>
      )}
    </>
  );
}

export const DropDownWithWorkerLevel: React.FC<
  DropDownSingleSelectionProps<DropDownWithWorkerLevelOption>
> = props => {
  const { levelIconType } = useContext(GlobalDataContext);

  const _props = {
    ...props,
    renderOption: (value: DropDownWithWorkerLevelOption | undefined) => {
      return renderLevel(value, levelIconType, props.notEditable, props.hideWorkerLevel);
    },
  };
  return DropDownSingleSelection(_props);
};
