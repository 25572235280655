import * as API from 'shared/backend-data';
import logger from './Logger';
import { AsyncLock } from '../util-ts/AsyncLock';
import { UserContext } from '../context/UserContext';

export enum UserPreferenceKeys_Common {
  UserLanguage = 'UserLanguage',
  RGPDExcelExportNotice = 'RGPDExcelExportNotice',
  WorkerNoteGDPRWarning = 'WorkerNoteGDPRWarning',
  TablePreferences = 'TablePreferences',
  ReceiveEmailNotifications = 'ReceiveEmailNotifications',
  TutorialAcknowledged = 'TutorialAcknowledged',
  ForceBaseSync = 'ForceBaseSync',
  EnableLogs = 'EnableLogs',
}

export function replacer(this: any, key: any, value: any) {
  const originalObject = this[key];
  if (originalObject instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(originalObject.entries()), 
    };
  } else {
    return value;
  }
}

function reviver(key: any, value: any) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

export function extractPreferences(userPreference: API.UserPreference): Map<string, any> {
  try {
    const parsedObject = JSON.parse(userPreference.data, reviver);
    if (!(parsedObject instanceof Map)) {
      logger.error('Error parsing the userPreference', userPreference);
      return new Map<string, any>();
    }
    return parsedObject;
  } catch (error) {
    logger.error('Error parsing the userPreference', userPreference);
    return new Map<string, any>();
  }
}

/**
 * Get the User's Preferences
 * @param userId (Optional) if not set the current User is used
 */
export async function _getOrCreateUserTenantAppPreference(
  userId?: string,
): Promise<API.Result<API.Factory<API.DataType.USERPREFERENCE>>> {
  const factory = await API.getOrCreateFactory(
    API.DataType.USERPREFERENCE,
    async () => {
      if (!userId) {
        const user = UserContext.getUser();
        if (API.isFailure(user)) return user;

        userId = user.id;
      }

      let factory = await API.getFactoryBusinessObject(
        API.DataType.USERPREFERENCE,
        API.DataType.USERPREFERENCE + API.SeparatorDataType + userId,
      );
      if (API.isFailure(factory)) {
        if (API.isFailureType(factory, 'ObjectNotFound')) {
          const preferences = new Map<string, any>();

          const userPreferenceInput: API.UserPreferenceCreateInput = {
            userId,
            data: JSON.stringify(preferences, replacer),
          };

          factory = await API.createFactoryBusinessObject(
            API.DataType.USERPREFERENCE,
            userPreferenceInput,
          );
          if (API.isFailure(factory)) return factory;
        } else {
          return factory;
        }
      }
      return factory;
    },
    userId,
  );
  if (API.isFailure(factory)) return factory;

  return factory;
}

/**
 * Get a UserPreference
 * WARINING: There is no sync between database and user preference.
 * The user preference content might be outdated because database might have changed since the user prefrence was saved.
 * This is the responsability of the caller for example to handle the user preference referencing deleted object id
 *
 * @param key
 * @param userId (Optional) if not set the current User is used
 */
export async function getUserPreference<T>(
  key: string, 
  userId?: string,
): Promise<API.Result<T | undefined>> {
  const userPreferenceFactory = await _getOrCreateUserTenantAppPreference(userId);
  if (API.isFailure(userPreferenceFactory)) return userPreferenceFactory;

  return extractPreferences({
    ...userPreferenceFactory.userPreference,
    updatedAt: userPreferenceFactory.updatedAt,
    updatedBy: userPreferenceFactory.updatedBy,
  }).get(key);
}

const saveUserPreferenceLock = new AsyncLock();
/**
 * Save a UserPreference
 * @param key the preference key
 * @param value the preference value. If undefined is passed, the preference is removed
 * @param userId (Optional) if not set the current User is used
 */
export async function saveUserPreference<T>(
  key: string, 
  value: T | undefined, 
  userId?: string,
): Promise<API.Result<void>> {
  return saveUserPreferenceLock.runSerial(async () => {
    const _userPreferenceFactory = await _getOrCreateUserTenantAppPreference(userId);
    if (API.isFailure(_userPreferenceFactory)) return _userPreferenceFactory;

    const userPreferenceFactory = API.deepClone(_userPreferenceFactory);
    if (userPreferenceFactory) {
      const preferences = extractPreferences({
        ...userPreferenceFactory.userPreference,
        updatedAt: userPreferenceFactory.updatedAt,
        updatedBy: userPreferenceFactory.updatedBy,
      });
      if (value === undefined) {
        preferences.delete(key);
      } else {
        preferences.set(key, value);
      }

      const userPreference = userPreferenceFactory.userPreference;
      userPreference.data = JSON.stringify(preferences, replacer);
      logger.verbose('Saving preference key=', key, ' value=', value + ' as', userPreference.data);
      const updated = await API.updateFactoryBusinessObject(
        API.DataType.USERPREFERENCE,
        userPreference,
      );
      if (API.isFailure(updated)) return updated;
    }
  });
}
