import React from 'react';
import { WorkerAndWorkstationVersatilityRow } from '../../WorkersLibrary';
import * as _ from 'lodash-es';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { WorkerState } from 'shared/backend-data';
import styles from '../Styles';
import * as SharedStyles from 'shared/styles';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';

interface Props {
  row: WorkerAndWorkstationVersatilityRow;
}

export const OrgUnitColumn: React.FC<Props> = props => {
  const { row } = props;

  const displayValue: string | undefined = (row.workerAssignments ?? [])
    .map(workerAssignment => workerAssignment.organizationalUnit.name.trim())
    .join(', ');

  return displayValue ? (
    <EllipsisWithTooltip
      text={displayValue}
      textStyle={
        row.state === WorkerState.ARCHIVED ? styles.disabled : SharedStyles.Styles.tableText
      }
    />
  ) : (
    <LoaderThreeDots />
  );
};
