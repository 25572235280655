import React, { useState, useEffect } from 'react';
import { UnitModalComponent } from '../component';
import { TreeNode } from 'shared/backend-data/factoryCache/Tree';
import * as API from 'shared/backend-data';
import { InteractionManager } from 'react-native';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { Loader } from 'shared/ui-component/Loader/Loader';
import Aigle from 'aigle';

export interface UnitModalProps {
  modalVisible?: boolean;
  handleModalClose: () => void;
  duplicateNode: (node: TreeNode) => void;
  deleteNode: (node: TreeNode) => void;
  node?: TreeNode;
  preSelectedParentUnit?: API.OrganizationalUnit;
}

export type ShiftNameAndColor = {
  name: string;
  color: string;
};

export type UnitDetails = {
  name: string | undefined;
  parentId: string | undefined;
  description: string | undefined | null;
  shiftIds?: string[];
  thirdPartyIds?: string[];
};

export const UnitModal: React.FC<UnitModalProps> = props => {
  const { node, preSelectedParentUnit } = props;

  const [shiftsToCreate, setShiftsToCreate] = useState<API.ShiftCreateInput[]>();
  const [shifts, setShifts] = useState<API.Shift[]>();
  const [orgUnit, setOrgUnit] = useState<API.OrganizationalUnit>();
  const [assignments, setAssignments] = useState<API.AssignmentWithUnitDetails[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMounted = useIsMounted();

  useEffect(() => {
    if (node && node.object) if (API.isOrganizationalUnit(node.object)) setOrgUnit(node.object);
  }, [node]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !orgUnit) return;
      const _shifts = await getShiftsForUnit(orgUnit);
      if (_shifts) setShifts([..._shifts]);

      const orgUnitWorkerAssignments = await API.getOrgUnitWorkersAssignments(orgUnit.id, false);
      if (API.isFailure(orgUnitWorkerAssignments)) {
        logger.error('getOrgUnitWorkerAssignments:error', orgUnitWorkerAssignments);
        return;
      }

      setAssignments(orgUnitWorkerAssignments);
    });
  }, [orgUnit]);

  async function onSubmitUnit(unitDetails: UnitDetails): Promise<void> {
    setIsLoading(true);
    if (orgUnit) {
      if (unitDetails.parentId && unitDetails.name) {
        const updateOrgUnitResult = await API.updateOrganizationalUnit({
          id: orgUnit.id,
          parentId: unitDetails.parentId,
          name: unitDetails.name,
          description: unitDetails.description,
          shiftIds: unitDetails.shiftIds,
        });
        if (API.isFailure(updateOrgUnitResult)) {
          setIsLoading(false);
          logger.error('Failed to update unit', updateOrgUnitResult);
          return;
        }

        await createOrUpdateShifts(shiftsToCreate, orgUnit.id);
      }
    } else {
      if (unitDetails.parentId && unitDetails.name) {
        const organizationalUnit = await API.createOrganizationalUnit({
          parentId: unitDetails.parentId,
          name: unitDetails.name,
          description: unitDetails.description,
        });
        if (!isMounted.current) return;
        if (API.isFailure(organizationalUnit)) {
          setIsLoading(false);
          logger.error('Failed to create unit', organizationalUnit);
          return;
        }

        await createOrUpdateShifts(shiftsToCreate, organizationalUnit.id);
      }
    }
    setIsLoading(false);
  }

  async function createOrUpdateShifts(
    shiftToCreateOrUpdate: API.ShiftCreateInput[] | undefined,
    unitId: string,
  ): Promise<void> {
    if (!shiftToCreateOrUpdate || !shiftToCreateOrUpdate.length) return;

    await Aigle.mapSeries(shiftToCreateOrUpdate, async shiftToCreate => {
      if (shiftToCreate.id) {
        const updateShift = await API.updateShift({ ...shiftToCreate, id: shiftToCreate.id });
        if (API.isFailure(updateShift)) {
          logger.warn(updateShift);
          return;
        }
      } else {
        const createdShift = await API.createShift({ ...shiftToCreate, parentId: unitId });
        if (API.isFailure(createdShift)) {
          logger.warn(createdShift);
          return;
        }
      }
    });
  }

  async function getShiftsForUnit(
    orgUnit: API.OrganizationalUnit,
  ): Promise<API.Shift[] | undefined> {
    const orgUnitShifts: API.Shift[] = [];
    if (!orgUnit.shiftIds || !orgUnit.shiftIds?.length) return;

    await Promise.all(
      orgUnit.shiftIds?.map(async shiftId => {
        const shift = await API.getShift(shiftId);
        if (API.isFailure(shift)) {
          logger.warn(shift);
          return;
        }
        orgUnitShifts.push(shift);
      }),
    );

    return orgUnitShifts;
  }
  return (
    <>
      {isLoading && <Loader />}
      <UnitModalComponent
        onSubmit={onSubmitUnit}
        handleShifts={shifts => setShiftsToCreate(shifts)}
        shifts={shifts}
        assignments={assignments}
        {...props}
        preSelectedParentUnit={preSelectedParentUnit}
        setIsLoading={setIsLoading}
      />
    </>
  );
};
