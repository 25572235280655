import { StyleSheet } from 'react-native-web';
import { Colors } from '../../styles';

const styles = StyleSheet.create({
  backgroundBar: {
    flexDirection: 'row',
    height: 4,
    backgroundColor: Colors.Background,
    borderRadius: 4,
    overflow: 'hidden',
  },
  progressBar: {
    alignSelf: 'flex-start',
    backgroundColor: Colors.Yellow,
    height: '100%',
    borderRadius: 4,
  },
  infiniteProgressBar: {
    width: '50%',
    position: 'absolute',
  },
  infiniteProgressBarAnimation: {
    animationKeyframes: [
      {
        '0%': { left: '-50%' },
        '100%': { left: '100%' },
      },
    ],
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    animationPlayState: 'running',
  },
});

export default styles;
