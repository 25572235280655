import React, { useContext, useEffect, useState } from 'react';
import { View, TouchableOpacity, Platform, Text } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { PageSwitch } from 'shared/layout/page-switch/container';
import { YellowButton } from 'shared/ui-component/Button';
import { t } from 'shared/localisation/i18n';
import * as API from 'shared/backend-data';
import Styles from './styles';
import { Colors } from 'shared/styles';
import { ModalUtils } from 'shared/ui-component/Modal';
import { Chapter } from '../SummaryBook';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from '../../../util/Logger';
import { getProofStateText } from '../functions';
import { PermissionManagementContext } from '../../../context/PermissionManagementContext';

const SummaryIcon = require('shared/assets/svg/icon.list.svg').default;
const TickIcon = require('shared/assets/svg/icon.checked.svg').default;

interface Props {
  chapters: Chapter[];
  oneWorkerAndOneSkill: boolean;
  isProofBookValidated: boolean;
  isProofBookCompleted: boolean;
  allowPartialSubmission: boolean;
  currentPageIndex?: number;
  isProofBookSubmitted?: boolean;
  disableProofValidation?: boolean;
  chapter?: Chapter;

  handleSummaryButton: () => void;
  onPageSwitch: (index: number) => void;
  handleSubmitToReview: () => void;
}

export const BottomPanel: React.FC<Props> = props => {
  const {
    chapters,
    currentPageIndex,
    oneWorkerAndOneSkill,
    chapter,
    allowPartialSubmission,
    isProofBookCompleted,
    isProofBookSubmitted,

    handleSummaryButton,
    onPageSwitch,
    handleSubmitToReview,
  } = props;
  const modal = ModalUtils.useModal();
  const isMounted = useIsMounted();

  const [submitButtonLabel, setSubmitButtonLabel] = useState<string>(
    t('alex:proofBook.validateAllWorkers'),
  );
  const [proofStateText, setProofStateText] = useState<string>();

  const { isValidPermission } = useContext(PermissionManagementContext);

  useEffect(() => {
    if (
      chapter?.skills &&
      chapter.trainees &&
      chapter.skills.length > 1 &&
      chapter.trainees.length > 1
    ) {
      setSubmitButtonLabel(t('alex:proofBook.validateAllWorkers'));
    } else if (chapter?.skills && chapter?.skills.length > 1) {
      setSubmitButtonLabel(t('alex:proofBook.validateTheWorkerSkills'));
    } else if (chapter?.skills && chapter?.skills.length === 1) {
      setSubmitButtonLabel(t('alex:proofBook.validateTheSkill'));
    }

    if (chapter?.proofBundles && chapter.proofBundles.length === 1) {
      if (
        chapter.proofBundles[0].review.state === API.ReviewState.VALIDATED ||
        chapter.proofBundles[0].review.state === API.ReviewState.REJECTED ||
        chapter.proofBundles[0].review.state === API.ReviewState.REJECTED_TO_RESUBMIT
      ) {
        _getProofStateText(chapter.proofBundles[0]);
      }
    }
  }, [chapter]);

  async function _getProofStateText(proofBundle: API.ProofBundle) {
    const text = await getProofStateText(proofBundle);
    if (!isMounted.current) return;
    if (API.isFailure(text)) {
      logger.warn(text);
      return;
    }
    setProofStateText(text);
  }

  function getProofCount(isSubmitted: boolean, chapter?: Chapter): number {
    let count = 0;

    if (!isSubmitted && chapter?.proofBundles) {
      return chapter.proofBundles.length;
    }
    chapter?.proofBundles?.forEach(proofBundle => {
      if (isSubmitted && proofBundle.review.state === API.ReviewState.TO_REVIEW) {
        count++;
      }
    });

    return count;
  }

  function _handleProofBookSubmitToReview() {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: chapter?.isChapterSubmitted
          ? t('alex:trainingSessions.addTrainingModal.validateProofWarning', {
              count: getProofCount(true, chapter),
            })
          : t('alex:trainingSessions.addTrainingModal.submitForValidationWarning', {
              count: !allowPartialSubmission
                ? getProofCount(false, chapters[0])
                : getProofCount(false, chapter),
            }),
        warningAcceptButton: t('common:button.yes'),
        warningCancelButton: t('common:button.no'),
        warningAcceptCallback: handleSubmitToReview,
        containerStyle: Platform.OS !== 'web' ? { width: 300 } : null,
      }),
    );
  }

  function _handleSummaryButton() {
    handleSummaryButton();
    setSubmitButtonLabel(t('alex:proofBook.validateAllWorkers'));
  }

  function handleBottomButton(): boolean {
    if (!allowPartialSubmission) {
      if (!isProofBookCompleted) return true;

      if (isProofBookSubmitted) return !isValidPermission(API.Permission.workersSkillProof_review);
      if (isProofBookCompleted) return !isValidPermission(API.Permission.workersSkillsProof_edit);
    } else {
      if (!chapter?.isChapterCompleted) return true;

      if (chapter.isChapterSubmitted) {
        return !isValidPermission(API.Permission.workersSkillProof_review);
      } else if (chapter.isChapterCompleted) {
        return !isValidPermission(API.Permission.workersSkillsProof_edit);
      }
    }

    return false;
  }

  return (
    <View style={Styles.bottomPanel}>
      <View style={Styles.bottomPanelContainer}>
        <TouchableOpacity style={Styles.summaryIconContainer} onPress={_handleSummaryButton}>
          {oneWorkerAndOneSkill ? null : (
            <IconSVG svgComponent={SummaryIcon} color={Colors.Black} />
          )}
        </TouchableOpacity>
        <View style={Styles.patchSwitchContainer}>
          <PageSwitch
            maxIndex={chapters.length}
            containerStyle={Styles.patchSwitchContainer}
            onSwitch={onPageSwitch}
            currentIndex={currentPageIndex}
          />
        </View>
        {!chapter?.isChapterValidated ? (
          <View style={[Styles.buttonContainer]}>
            {Platform.OS === 'web' ? (
              <View style={Styles.skillButtonContainer}>
                <YellowButton
                  onPress={_handleProofBookSubmitToReview}
                  style={Styles.submitButtonStyle}
                  text={
                    chapter?.isChapterSubmitted
                      ? submitButtonLabel
                      : t('alex:trainingSessions.addTrainingModal.submitForValidation')
                  }
                  textStyle={Styles.submitButtonTextStyle}
                  disabled={handleBottomButton()}
                />
              </View>
            ) : (
              <YellowButton
                onPress={_handleProofBookSubmitToReview}
                style={[Styles.submitButtonStyle]}
                icon={TickIcon}
                textStyle={Styles.submitButtonTextStyle}
                disabled={handleBottomButton()}
              />
            )}
          </View>
        ) : (
          Platform.OS === 'web' && (
            <View style={Styles.buttonContainer}>
              <Text style={Styles.proofTextState}>{proofStateText}</Text>
            </View>
          )
        )}
      </View>
      {Platform.OS !== 'web' && chapter?.isChapterValidated && (
        <View style={Styles.validityContainer}>
          <Text style={Styles.proofTextState}>{proofStateText}</Text>
        </View>
      )}
    </View>
  );
};
