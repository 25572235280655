import React from 'react';
import { View, TouchableOpacity, Text, ViewStyle, StyleProp, TextStyle } from 'react-native';
import CheckIcon from 'shared/assets/svg/icon.check.svg';
import { Colors } from 'shared/styles';
import { t } from 'shared/localisation/i18n';
import Style from './Style';

export interface CheckboxProps {
  text?: string | undefined;
  initialState?: boolean | null | undefined;
  threeState?: boolean;
  disabled?: boolean | undefined;
  style?: ViewStyle | undefined;
  checkBoxColor?: string | undefined;
  titleStyle?: StyleProp<TextStyle> | undefined;
  onChange?: (state: boolean | null) => void;
}

export const Checkbox: React.FC<CheckboxProps> = props => {
  let { initialState } = props;

  if (initialState === undefined) {
    initialState = false;
  } else if (!props.threeState && initialState == null) {
    initialState = false;
  }

  const handlePress = () => {
    let stateChange: boolean | null | undefined = !initialState;
    if (props.threeState && initialState === null) {
      stateChange = true;
    } else if (props.threeState && initialState === true) {
      stateChange = null;
    }

    if (props.onChange) {
      props.onChange(stateChange);
    }
  };

  return (
    <View style={[props.style]}>
      <TouchableOpacity
        style={[Style.rootContainer]}
        disabled={props.disabled ?? false}
        onPress={() => handlePress()}
      >
        <View
          style={[
            Style.checkbox,
            props.disabled && Style.checkboxDisabled,
            {
              borderColor: props.checkBoxColor ?? Colors.Black,
            },
          ]}
        >
          {initialState && (
            <View
              style={[
                Style.checkIcon,
                {
                  backgroundColor: props.checkBoxColor ?? Colors.Black,
                },
              ]}
            >
              <CheckIcon color={Colors.White} />
            </View>
          )}
          {props.threeState && !initialState && initialState !== null && (
            <View style={[Style.checkIcon, { opacity: 0.45 }]}>
              <CheckIcon color={Colors.White} />
            </View>
          )}
          {props.threeState && initialState === null && <View style={Style.checkIcon} />}
        </View>
        <Text style={[props.titleStyle, props.disabled && Style.checkboxDisabled]}>
          {t(props.text ?? '')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
