import { StyleSheet } from 'react-native';
import {
  Colors,
  createRoundedStyle,
  fontSize,
  ModalStyles,
  TextFontStyles,
  Spacings,
} from 'shared/styles';

export const Styles = StyleSheet.create({
  modalCardStyle: { padding: 0, width: 932 },
  tableStyle: {
    borderRadius: 8,
  },
  rowLevelContainer: {
    alignItems: 'flex-start',
    maxHeight: '100%',
    marginTop: -Spacings.Unit,
    paddingLeft: Spacings.Small,
  },
  workerProfile: {
    zIndex: 1,
  },
  rowWsNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSkillsContainer: {
    flexDirection: 'row',
    minWidth: '22%',
    maxWidth: '50%',
    marginRight: Spacings.Standard * 2 - Spacings.Unit,
    alignItems: 'center',
  },
  iconContainer: {
    height: 32,
    width: 32,
    backgroundColor: Colors.Yellow,
    borderRadius: (32 + 32) / 2,
  },
  workstationIconContainer: {
    height: Spacings.Standard * 2,
    width: Spacings.Standard * 2,
    backgroundColor: Colors.Yellow,
    borderRadius: (Spacings.Standard * 2 + Spacings.Standard * 2) / 2,
    left: -Spacings.Small,
  },
  iconContainer2: {
    height: 24,
    width: 24,
    borderRadius: (24 + 24) / 2,
    marginBottom: 2,
    backgroundColor: Colors.Background,
  },
  closeIconContainer: {
    height: 24,
    width: 24,
    top: -Spacings.Medium,
    zIndex: -1,
  },

  iconRoundedContatiner: {
    ...createRoundedStyle(24),
    backgroundColor: Colors.Background,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: Spacings.Small,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: ModalStyles.headerHeight,
    paddingLeft: Spacings.Large + Spacings.Unit,
    paddingRight: Spacings.Medium,
    paddingTop: Spacings.Medium,
    paddingBottom: Spacings.Medium,
    borderBottomColor: Colors.FlashWhite,
    borderBottomWidth: 1,
    zIndex: 2,
  },
  headerInnerContainer: {
    height: '100%',
    width: '100%',
    paddingTop: Spacings.Small,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  workerInfoOuterContainer: {
    flexDirection: 'row',
    flex: 0.4,
  },
  profileImageContainer: {
    borderRadius: Spacings.CardPadding,
    flexDirection: 'row',
  },
  workerInfoContainer: {
    marginLeft: '1%',
    width: '60%',
  },
  workerInfoText: {
    ...TextFontStyles.LatoMedium,
    color: Colors.Black,
    fontSize: fontSize.Medium,
  },
  workerInfoTextEllipsis: { zIndex: 2 },
  worstationInfoText: {
    ...TextFontStyles.LatoMedium,
    color: Colors.Black,
    fontSize: fontSize.Medium,
  },
  worstationInfoTextEllipsis: { zIndex: 1 },
  levelInfoText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Black,
    fontSize: fontSize.Medium,
  },
  targetLevelInfoText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Black,
    fontSize: fontSize.Medium,
  },
  boldText: {
    ...TextFontStyles.LatoBold,
    color: Colors.DarkBlack,
    fontSize: fontSize.Regular,
  },
  filterContainer: {
    height: '12.8%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowFilterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowSkillContainer: {
    flexDirection: 'row',
    alignitems: 'center',
    width: '100%',
  },
  scrollViewContainer: {
    height: '70.7%',
    width: ModalStyles.width,
  },
  buttonSkillTouchableHover: {
    shadowColor: Colors.Black,
    shadowOpacity: 0.5,
    shadowRadius: 3,
  },
  buttonSkillTextStyle: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Small,
  },
  footerContainer: {
    height: 50,
    width: '100%',
    backgroundColor: 'transparent',
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerTextStyle: {
    marginRight: 30,
    textAlign: 'left',
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    color: '#606060',
    justifyContent: 'center',
  },
  skillDetailsCellContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
  statusMessageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
  tableContainer: {
    height: 'auto',
  },
  levelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.2,
    marginTop: -Spacings.Unit / 2,
    paddingLeft: Spacings.xMedium,
    zIndex: 1,
  },
  levelInnerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionArrowStyle: { position: 'absolute', right: -Spacings.Unit * 12 },
  fullWidth: { width: '100%' },
  loaderContainer: {
    width: '100%',
    height: '100%',
    zIndex: 998,
    backgroundColor: Colors.DarkGrey,
    opacity: 0.3,
    position: 'absolute',
  },
  loaderStyle: {
    width: '100%',
    height: '100%',
    zIndex: 999,
    position: 'absolute',
  },
  levelTargetAndMaintainContainer: {
    paddingLeft: 0,
    marginTop: 0,
    borderBottomColor: Colors.GreyLight,
    paddingBottom: Spacings.Unit,
    borderBottomWidth: 1,
  },
  levelTargetAndMaintainContainerForNoTarget: {
    marginTop: 0,
  },
  progressBarContainer: {
    flex: 0.4,
    zIndex: 0,
    top: -Spacings.Small,
    paddingLeft: Spacings.Standard,
  },
  progressCircleEachLevelContainer: {
    height: 60,
    marginTop: Spacings.CardPadding,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Spacings.CardPadding * 2,
    zIndex: 1,
  },
  progressCircleEachLevelStyle: {
    width: 56,
  },
  barAndEstimationContainer: {
    flexDirection: 'row',
    paddingLeft: Spacings.CardPadding * 2,
    alignItems: 'center',
  },
  estimationDateText: {
    width: 200,
    paddingLeft: Spacings.Small,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
  },
  progressCircleHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  estimationDateTextHeader: {
    paddingLeft: Spacings.Small,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
  },
  trainingStatusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressCircleContainer: {
    width: 56,
    height: 56,
  },
  skillValidtyContainer: {
    flexDirection: 'row',
  },
  inProgressTrainingSessionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: Spacings.Medium,
  },
  inProgressTrainingSessionText: {
    color: Colors.MediumGray,
    marginTop: -Spacings.Unit / 2,
    fontSize: fontSize.Small,
  },
  nonWorkstationTrainingSessionInProgress: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
});
