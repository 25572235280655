import React, { useContext, useEffect } from 'react';
import { Text, View } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { TouchableOpacityPreventDoubleClick } from 'shared/ui-component/TouchableOpacityPreventDoubleClick';
import * as API from 'shared/backend-data';
import { TrainingProgress } from 'shared/layout/cards/TrainingProgress';
import { SingleActionRow } from 'shared/layout/cards/SingleActionRow';
import { WorkerRow } from 'shared/layout/cards/WorkerRow';
import { Spacings } from 'shared/styles';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import Styles from './Styles.web';
import { TrainingSessionCardMobileProps } from './TrainingSessionCardMobile';
import { SkillExpand } from '../training-card/training-card/component/SkillExpand';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import * as _ from 'lodash-es';
import { DoubleActionRow } from '../../DoubleActionRow';
import { ModalUtils } from '../../../../ui-component/Modal';

type TrainingSessionCardWeb = Omit<
  TrainingSessionCardMobileProps,
  'mustBePlanned' | 'mustBeValidated' | 'validateProofBundle'
>;

interface TrainingSessionCardWebProps extends TrainingSessionCardWeb {
  onAddProofTrainingSession?: (trainingSessionId: string) => void;
  onStartTrainingSession?: (trainingSessionWithDetails: API.TrainingSessionWithDetail) => void;
  canWorkerMangeTrainingSession?: boolean;
}

export const TrainingSessionCardWeb: React.FC<TrainingSessionCardWebProps> = props => {
  const {
    trainingSessionWithDetails,
    loader,
    startTrainingSession,
    addProofBundle,
    isTrainingPractical,
    disabled,
    mentorsDetails,
    traineeDetails,
    canWorkerMangeTrainingSession,

    onAddProofTrainingSession,
    onStartTrainingSession,
    onCardPress,
  } = props;

  const modal = ModalUtils.useModal();

  const { isValidPermission } = useContext(PermissionManagementContext);

  function onRejectTrainingSessionRequest() {
    if (!trainingSessionWithDetails) return;

    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: isTrainingPractical
          ? t('alex:trainingSessions.warnings.rejectPracticalTrainingSession')
          : t('alex:trainingSessions.warnings.rejectNotPracticalTrainingSession'),
        warningAcceptCallback: () => API.rejectTrainingSession(trainingSessionWithDetails.id),
        warningAcceptButton: t('common:button.yes'),
        warningCancelButton: t('common:button.no'),
      }),
    );
  }

  function onValidateTrainingSessionRequest() {
    if (!trainingSessionWithDetails) return;

    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: isTrainingPractical
          ? t('alex:trainingSessions.warnings.validatePracticalTrainingSession')
          : t('alex:trainingSessions.warnings.validateNotPracticalTrainingSession'),
        warningAcceptCallback: () => API.validateTrainingSession(trainingSessionWithDetails.id),
        warningAcceptButton: t('common:button.yes'),
        warningCancelButton: t('common:button.no'),
      }),
    );
  }

  if (!trainingSessionWithDetails) return null;

  return (
    <View style={[Styles.cardContainer]}>
      <TouchableOpacityPreventDoubleClick onPress={onCardPress} disabled={disabled}>
        {trainingSessionWithDetails && (
          <TrainingProgress trainingSessionWithDetails={trainingSessionWithDetails} />
        )}

        <Text style={[Styles.trainingTitle]}>{trainingSessionWithDetails.training.name}</Text>
        {loader && <LoaderThreeDots lowPerformance={false} />}
        {!loader &&
          traineeDetails.map(eachTrainee => (
            <WorkerRow
              key={eachTrainee.worker.id}
              displayWorkerLevelIcon
              worker={eachTrainee.worker}
              workstationWorkerLevel={eachTrainee.workerWorkstation}
              isTrainer={false}
              isPracticalTraining={isTrainingPractical}
              isAbsent={
                isTrainingPractical ||
                !API.isTrainingSessionStartedOrStartedLateOrLateEnd(trainingSessionWithDetails)
                  ? undefined
                  : !_.find(
                      trainingSessionWithDetails.traineeIds,
                      traineeId => traineeId === eachTrainee.worker.id,
                    )
              }
            />
          ))}
        {!loader && mentorsDetails?.length ? (
          mentorsDetails.map(eachMentor => (
            <WorkerRow
              displayWorkerLevelIcon
              worker={eachMentor.worker}
              workstationWorkerLevel={eachMentor.workerWorkstation}
              isTrainer
              isPracticalTraining={isTrainingPractical}
            />
          ))
        ) : (
          <></>
        )}
        <View style={[Styles.spacer]} />
        {trainingSessionWithDetails.trainingVersion ? (
          <SkillExpand
            skillIds={trainingSessionWithDetails.trainingVersion.skillIds}
            files={trainingSessionWithDetails.training?.files}
          />
        ) : (
          <LoaderThreeDots lowPerformance={false} />
        )}
        <View style={{ height: Spacings.xMedium }} />

        {trainingSessionWithDetails &&
          API.isTrainingSessionToReview(trainingSessionWithDetails) &&
          (isValidPermission(API.Permission.trainingSessions_edit, undefined, [
            ...API.getTrainerIdsFromTraininersWithPercentage(
              API.deepClone(trainingSessionWithDetails?.trainers),
            ),
            ...trainingSessionWithDetails.traineeIds,
          ]) ? (
            <>
              <DoubleActionRow
                buttonLeftText={t('common:button.reject')}
                buttonRightText={t('common:button.validate')}
                onLeftAction={onRejectTrainingSessionRequest}
                onRightAction={onValidateTrainingSessionRequest}
                disabled={!canWorkerMangeTrainingSession}
              />
              <View style={Styles.buttonSpacer} />
            </>
          ) : (
            <View>
              <Text>{t('alex:trainingSessions.waitingForValidation')}</Text>
            </View>
          ))}
        {startTrainingSession &&
          trainingSessionWithDetails &&
          isValidPermission(API.Permission.trainingSessions_edit, undefined, [
            ...API.getTrainerIdsFromTraininersWithPercentage(
              API.deepClone(trainingSessionWithDetails?.trainers),
            ),
            ...trainingSessionWithDetails.traineeIds,
          ]) &&
          !isTrainingPractical && (
            <>
              <SingleActionRow
                buttonText={t('common:button.start')}
                onAction={() => {
                  if (onStartTrainingSession) onStartTrainingSession(trainingSessionWithDetails);
                }}
                disabled={!canWorkerMangeTrainingSession}
              />
              <View style={Styles.buttonSpacer} />
            </>
          )}
        {addProofBundle &&
          trainingSessionWithDetails &&
          isValidPermission(API.Permission.trainingSessions_edit, undefined, [
            ...API.getTrainerIdsFromTraininersWithPercentage(
              API.deepClone(trainingSessionWithDetails?.trainers),
            ),
            ...trainingSessionWithDetails.traineeIds,
          ]) && (
            <>
              <SingleActionRow
                buttonText={t('alex:scheduleTrainingModal.generateProof')}
                onAction={() => {
                  if (onAddProofTrainingSession)
                    onAddProofTrainingSession(trainingSessionWithDetails.id);
                }}
                disabled={!canWorkerMangeTrainingSession}
              />
              <View style={Styles.buttonSpacer} />
            </>
          )}
      </TouchableOpacityPreventDoubleClick>
    </View>
  );
};
