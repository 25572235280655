import React, { useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';
import moment from 'moment';
import * as API from 'shared/backend-data';
import Aigle from 'aigle';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { logger } from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { t } from 'shared/localisation/i18n';
import { KPIGraphComponent, yAxisKeys } from '../component';
import { HOUR_LABOUR_COST, CURRENCY } from '../../../../company-settings/container';
import { KPIData } from 'sharedweb/src/context/TreeTableContext';
import { wait } from 'shared/util-ts/Functions';

export interface TrainingSessionActualData {
  month: string;
  plannedTrainingCount: number;
  inProgressCount: number;
  lateCount: number;
  plannedTrainingHours: number;
  inProgressHours: number;
  lateHours: number;
  total: number;
  cost: string;
  hours: number;
  pluralLabels?: {
    plannedTrainingCount?: string;
  };
}

export interface ForecastTrainingGraphData {
  month: string;
  toAcquireCount: number;
  toRenewCount: number;
  toAcquireHours: number;
  toRenewHours: number;
  total: number;
  cost: string;
  hours: number;
}

interface TrainingSessionTypeObjects {
  plannedTraining?: API.TrainingSession[];
  inProgress?: API.TrainingSession[];
  late?: API.TrainingSession[];
}

interface CostAndHour {
  cost: number;
  hours: number;
}

interface WorkerTrainingVersionDetails {
  workerId: string;
  trainingVersionId: string;
  toBeStartedDate: moment.Moment;
  renew: boolean | null;
}

interface TrainingsToAcquireAndRenew {
  toRenew: WorkerTrainingVersionDetails[];
  toAcquire: WorkerTrainingVersionDetails[];
}

interface ForeCastTrainingData extends WorkerTrainingVersionDetails {
  cost: number;
  hours: number;
  endDate: moment.Moment;
  month: string;
}

const MONTH_COUNT = 5;
const timeoutForEachAsyncOperation = 10;
const mapBatchCount = 1000;
const MonthIncrementCounter = 1;
const timeRangeStartDate = moment().clone().startOf('month').valueOf();
const timeRangeEndDate = moment().add(MONTH_COUNT, 'months').endOf('month').valueOf();

interface Props {
  kpiData: KPIData;
  isGraphLoaded: boolean;

  setIsGraphLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const KPIGraph: React.FC<Props> = props => {
  const [trainingSessionActualGraphData, setTrainingSessionActualGraphData] = useState<
    TrainingSessionActualData[]
  >([]);
  const [trainingSessionForecastGraphData, setTrainingSessionForecastGraphData] = useState<
    ForecastTrainingGraphData[]
  >([]);
  const [noDataForActualGraphData, setNoDataForActualGraphData] = useState<boolean>(false);
  const [noDataForForecastGraphData, setNoDataForForecastGraphData] = useState<boolean>(false);
  const [isTooMuchDataToLoadInForecastGraph, setIsTooMuchDataToLoadInForecastGraph] =
    useState<boolean>(false);

  const { kpiData, isGraphLoaded, setIsGraphLoaded } = props;
  const isMounted = useIsMounted();

  const forecastGraphLimit = 500;

  useEffect(() => {
    loadGraph();
  }, [kpiData]);

  async function loadGraph() {
    if (!kpiData.workers || !kpiData.workstations) return;
    setIsGraphLoaded(false);
    await fetchTrainingForecastGraph(true);
    if (!isMounted.current) return;
    await fetchTrainingSessionForActualGraph();
    setIsGraphLoaded(true);
  }

  function kFormatter(num: number): string {
    if (Math.abs(num) === 0) {
      return '0';
    } else if (Math.abs(num) > 999) {
      return `${Math.round(Math.sign(num) * (Math.abs(num) / 1000) * 10) / 10} k${CURRENCY}`;
    }

    return `${Math.round(Math.sign(num) * Math.abs(num) * 10) / 10} ${CURRENCY}`;
  }

  function convertMinToHour(value: number) {
    return Math.round((value / 60) * 10) / 10;
  }

  function computeCost(duration: number, numberOfParticipants: number): number {
    return (duration / 60) * HOUR_LABOUR_COST * numberOfParticipants;
  }

  async function computeCostForForecastGraph(
    trainingVersion: API.TrainingVersion,
    numberOfParticipants?: number,
  ): Promise<API.Result<number | undefined>> {
    const practicalTrainingParticipantsNumber = 2;
    const minNumberOfParticipants = 1;

    const isTrainingPractical = await API.isPracticalTraining(trainingVersion.trainingId);
    if (!isMounted.current) return 0;
    if (API.isFailure(isTrainingPractical)) {
      logger.warn(isTrainingPractical);
      return 0;
    }

    if (isTrainingPractical) {
      return computeCost(trainingVersion.durationInMin, practicalTrainingParticipantsNumber);
    }

    if (numberOfParticipants) {
      return computeCost(trainingVersion.durationInMin, numberOfParticipants);
    }

    
    
    return computeCost(trainingVersion.durationInMin, minNumberOfParticipants);
  }

  function computeTrainingDuration(
    trainingStartDate: moment.Moment,
    trainingEndDate: moment.Moment,
    month: string,
  ): number | undefined {
    if (
      trainingStartDate.format('MMM').toUpperCase() === trainingEndDate.format('MMM').toUpperCase()
    ) {
      return undefined;
    } else if (
      trainingStartDate.format('MMM').toUpperCase() === month &&
      trainingEndDate.format('MMM').toUpperCase() !== month
    ) {
      return moment().endOf('month').diff(trainingStartDate, 'minutes');
    } else if (
      trainingStartDate.format('MMM').toUpperCase() !== month &&
      trainingEndDate.format('MMM').toUpperCase() === month
    ) {
      return trainingEndDate.diff(moment(trainingEndDate).startOf('month'), 'minutes');
    } else {
      return moment().endOf('month').diff(moment().startOf('month'), 'minutes');
    }
  }

  async function groupToRenewAndToAcquireTrainings(
    workerTrainingVersionDetails: WorkerTrainingVersionDetails[],
  ): Promise<TrainingsToAcquireAndRenew> {
    return {
      toRenew: workerTrainingVersionDetails.filter(
        workerTrainingVersionDetail => workerTrainingVersionDetail.renew,
      ),
      toAcquire: workerTrainingVersionDetails.filter(
        workerTrainingVersionDetail => !workerTrainingVersionDetail.renew,
      ),
    };
  }

  async function computeTrainingSessionsCostAndHours(
    trainingSessions: API.TrainingSession[],
    month: string,
  ): Promise<CostAndHour> {
    let cost: number = 0;
    let minutes: number = 0;

    await Aigle.map(trainingSessions, async trainingSession => {
      const participants = trainingSession.traineeIds.length
        ? trainingSession.traineeIds
        : trainingSession.scheduledTraineeIds;

      const training = await API.getTrainingForATrainingVersion(trainingSession.trainingVersionId);
      if (!isMounted.current) return;
      if (API.isFailure(training)) {
        logger.warn(training);
        return;
      }

      const startDateOfTrainingSession =
        trainingSession.startDate ?? trainingSession.scheduledStartDate;
      const endDateOfTrainingSession = API.getTrainingSessionEstimatedEndDate(trainingSession);
      let trainingDuration = computeTrainingDuration(
        moment(startDateOfTrainingSession),
        endDateOfTrainingSession,
        month,
      );

      if (!trainingDuration) {
        trainingDuration = trainingSession.durationInMin ? trainingSession.durationInMin : 0;
      }

      const isPractical = await API.isPracticalTraining(training.id);
      if (!isMounted.current) return;
      if (API.isFailure(isPractical)) {
        logger.warn(isPractical);
        return;
      }

      minutes = minutes + trainingDuration;

      if (isPractical) {
        cost = cost + computeCost(trainingDuration, 2);
      } else {
        cost = cost + computeCost(trainingDuration, participants.length);
      }
    });

    return { cost: Math.round(cost * 10) / 10, hours: convertMinToHour(minutes) };
  }

  async function fetchTrainingSessionForActualGraph() {
    const data = new Map<string, TrainingSessionTypeObjects>();

    const { trainingSessionsWithState } = kpiData;

    const _startDateTimeRange = moment(timeRangeStartDate);
    const result: TrainingSessionActualData[] = [];

    while (
      _startDateTimeRange.format('MMM').toUpperCase() ===
        moment(timeRangeEndDate).format('MMM').toUpperCase() ||
      moment(_startDateTimeRange).valueOf() < timeRangeEndDate
    ) {
      data.set(_startDateTimeRange.format('MMM').toUpperCase(), {});
      _startDateTimeRange.add(MonthIncrementCounter, 'month');
    }

    trainingSessionsWithState?.forEach(trainingSessionWithState => {
      if (
        trainingSessionWithState.state === API.TrainingSessionState.SCHEDULED || 
        trainingSessionWithState.state === API.TrainingSessionState.STARTING_LATE || 
        trainingSessionWithState.state === API.TrainingSessionState.ENDING_LATE || 
        trainingSessionWithState.state === API.TrainingSessionState.STARTED || 
        trainingSessionWithState.state === API.TrainingSessionState.STARTED_LATE 
      ) {
        let startDate;
        if (trainingSessionWithState.startDate ?? trainingSessionWithState.scheduledStartDate)
          startDate = moment(
            trainingSessionWithState.startDate ?? trainingSessionWithState.scheduledStartDate,
          );

        const endDate = API.getTrainingSessionEstimatedEndDate(trainingSessionWithState);

        if (
          startDate &&
          moment(endDate).valueOf() < timeRangeEndDate &&
          moment(endDate).valueOf() > timeRangeStartDate
        ) {
          while (
            startDate.format('MMM').toUpperCase() === endDate.format('MMM').toUpperCase() ||
            startDate.valueOf() < endDate.valueOf()
          ) {
            if (data.has(startDate.format('MMM').toUpperCase())) {
              let month = moment(startDate).format('MMM').toUpperCase();

              if (
                trainingSessionWithState.state === API.TrainingSessionState.STARTED ||
                trainingSessionWithState.state === API.TrainingSessionState.STARTED_LATE
              ) {
                const inProgress = data.get(month)?.inProgress;

                data.set(month, {
                  ...data.get(month),
                  inProgress: [...(inProgress ?? []), trainingSessionWithState],
                });
              } else if (trainingSessionWithState.state === API.TrainingSessionState.SCHEDULED) {
                const plannedTraining = data.get(month)?.plannedTraining;

                data.set(month, {
                  ...data.get(month),
                  plannedTraining: [...(plannedTraining ?? []), trainingSessionWithState],
                });
              } else if (
                trainingSessionWithState.state === API.TrainingSessionState.STARTING_LATE
              ) {
                const late = data.get(month)?.late;

                data.set(month, {
                  ...data.get(month),
                  late: [...(late ?? []), trainingSessionWithState],
                });
              }
            }

            startDate.add(MonthIncrementCounter, 'month');
          }
        }
      }
    });

    for (const month of Array.from(data.keys())) {
      const trainingSessionActualObject = data.get(month);

      if (trainingSessionActualObject) {
        const lateCount = (trainingSessionActualObject.late ?? []).length;
        const plannedTrainingCount = (trainingSessionActualObject.plannedTraining ?? []).length;
        const inProgressCount = (trainingSessionActualObject.inProgress ?? []).length;
        const totalTrainingSessions = [
          ...(trainingSessionActualObject.late ?? []),
          ...(trainingSessionActualObject.plannedTraining ?? []),
          ...(trainingSessionActualObject.inProgress ?? []),
        ];

        const plannedTrainingCostAndHours = await computeTrainingSessionsCostAndHours(
          trainingSessionActualObject.plannedTraining ?? [],
          month,
        );
        if (!isMounted.current) return;

        await wait(timeoutForEachAsyncOperation);

        const inProgressCostAndHours = await computeTrainingSessionsCostAndHours(
          trainingSessionActualObject.inProgress ?? [],
          month,
        );
        if (!isMounted.current) return;

        await wait(timeoutForEachAsyncOperation);

        const lateCostAndHours = await computeTrainingSessionsCostAndHours(
          trainingSessionActualObject.late ?? [],
          month,
        );
        if (!isMounted.current) return;

        await wait(timeoutForEachAsyncOperation);

        result.push({
          month,
          lateCount,
          plannedTrainingCount,
          inProgressCount,
          plannedTrainingHours: plannedTrainingCostAndHours.hours,
          inProgressHours: inProgressCostAndHours.hours,
          lateHours: lateCostAndHours.hours,
          total: totalTrainingSessions.length,
          cost: kFormatter(
            plannedTrainingCostAndHours.cost + inProgressCostAndHours.cost + lateCostAndHours.cost,
          ).toString(),
          hours:
            plannedTrainingCostAndHours.hours +
            inProgressCostAndHours.hours +
            lateCostAndHours.hours,
          pluralLabels: {
            [yAxisKeys.plannedTrainingCount]:
              plannedTrainingCount > 1
                ? t('alex:KPI.KPIGraph.plannedTrainings', undefined, false)
                : undefined,
          },
        });
      }
    }

    setNoDataForActualGraphData(result.every(eachResult => eachResult.total === 0));
    setTrainingSessionActualGraphData(result);
  }

  async function computeTrainingDetails(
    acquiredTrainings: WorkerTrainingVersionDetails[],
  ): Promise<ForeCastTrainingData[]> {
    const forecastTrainingData: ForeCastTrainingData[] = [];

    await Aigle.map(acquiredTrainings, async acquiredTraining => {
      const trainingVersion = await API.getTrainingVersion(acquiredTraining.trainingVersionId);
      if (!isMounted.current) return;
      if (API.isFailure(trainingVersion)) {
        logger.warn(trainingVersion);
        return trainingVersion;
      }

      const cost = await computeCostForForecastGraph(trainingVersion);
      if (!isMounted.current) return;
      if (API.isFailure(cost)) {
        logger.warn(cost);
        return;
      }

      if (cost) {
        forecastTrainingData.push({
          ...acquiredTraining,
          month: acquiredTraining.toBeStartedDate.format('MMM').toUpperCase(),
          cost,
          hours: convertMinToHour(trainingVersion.durationInMin),
          endDate: moment(
            acquiredTraining.toBeStartedDate.valueOf() +
              Math.floor(trainingVersion.durationInMin * 60 * 1000),
          ),
        });
      }
    });
    if (!isMounted.current) return [];

    return forecastTrainingData;
  }

  function computeForecastGraphData(
    toAcquire: ForeCastTrainingData[],
    toRenew: ForeCastTrainingData[],
  ) {
    const toAcquireMap = new Map<string, ForeCastTrainingData[]>();
    const toRenewMap = new Map<string, ForeCastTrainingData[]>();
    const forecastTrainingGraphData: ForecastTrainingGraphData[] = [];
    let _noDataForForecastGraphData = true;

    _.forEach(toAcquire, _toAcquire => {
      const startDate = _toAcquire.toBeStartedDate;

      if (
        _toAcquire.endDate.valueOf() < timeRangeEndDate &&
        _toAcquire.endDate.valueOf() > timeRangeStartDate
      ) {
        while (
          startDate.format('MMM').toUpperCase() ===
            _toAcquire.endDate.format('MMM').toUpperCase() ||
          startDate.valueOf() < _toAcquire.endDate.valueOf()
        ) {
          const month = moment(startDate).format('MMM').toUpperCase();

          toAcquireMap.set(month, [...(toAcquireMap.get(month) ?? []), _toAcquire]);

          startDate.add(MonthIncrementCounter, 'month');
        }
      }
    });

    _.forEach(toRenew, _toRenew => {
      const startDate = _toRenew.toBeStartedDate;

      if (
        _toRenew.endDate.valueOf() < timeRangeEndDate &&
        _toRenew.endDate.valueOf() > timeRangeStartDate
      ) {
        while (
          startDate.format('MMM').toUpperCase() === _toRenew.endDate.format('MMM').toUpperCase() ||
          startDate.valueOf() < _toRenew.endDate.valueOf()
        ) {
          const month = moment(startDate).format('MMM').toUpperCase();

          toRenewMap.set(month, [...(toRenewMap.get(month) ?? []), _toRenew]);

          startDate.add(MonthIncrementCounter, 'month');
        }
      }
    });

    const startDate = moment(timeRangeStartDate);

    while (
      startDate.format('MMM').toUpperCase() ===
        moment(timeRangeEndDate).format('MMM').toUpperCase() ||
      moment(startDate).valueOf() < timeRangeEndDate
    ) {
      const month = moment(startDate).format('MMM').toUpperCase();

      const toAcquireCount = toAcquireMap.get(month)?.length ?? 0;
      const toRenewCount = toRenewMap.get(month)?.length ?? 0;
      const totalTrainingCount = toAcquireCount + toRenewCount;

      if (totalTrainingCount) {
        _noDataForForecastGraphData = false;
      }

      let toAcquireHours = 0;
      let toRenewHours = 0;
      let totalTrainingCost = 0;
      let totalTrainingHours = 0;

      toRenewMap.get(month)?.forEach(toRenew => {
        toRenewHours = toRenewHours + toRenew.hours;
        totalTrainingCost = totalTrainingCost + toRenew.cost;
        totalTrainingHours = totalTrainingHours + toRenew.hours;
      });

      toAcquireMap.get(month)?.forEach(toAcquire => {
        toAcquireHours = toAcquireHours + toAcquire.hours;
        totalTrainingCost = totalTrainingCost + toAcquire.cost;
        totalTrainingHours = totalTrainingHours + toAcquire.hours;
      });

      forecastTrainingGraphData.push({
        month,
        toAcquireCount,
        toRenewCount,
        toAcquireHours,
        toRenewHours,
        total: totalTrainingCount,
        cost: kFormatter(totalTrainingCost),
        hours: totalTrainingHours,
      });

      startDate.add(MonthIncrementCounter, 'month');
    }

    setNoDataForForecastGraphData(_noDataForForecastGraphData);
    setTrainingSessionForecastGraphData(forecastTrainingGraphData);
  }

  async function getFilteredWorkerWorkstations(): Promise<API.Result<API.WorkerWorkstation[]>> {
    const { workers, workstations } = kpiData;
    if (!workers || !workstations) return [];

    const _workerWorkstations: API.WorkerWorkstation[] = [];

    workstations.map(workstation => {
      workers.map(worker => {
        const workerWorkstation = API.getWorkerWorkstations(workstation.id, worker.id);

        if (workerWorkstation) _workerWorkstations.push(workerWorkstation);
      });
    });

    return _workerWorkstations;
  }

  /**
   * This function is calculating the ToBeStartDate of the training depending on the workerSkill validity
   * @param workerSkill
   * @returns
   */
  function computeToBeStartedDate(
    workerSkillValidity: API.Validity,
    workerSkill: API.WorkerSkillWithComplementaryDetails,
    trainingVersion: API.TrainingVersion,
  ): moment.Moment {
    switch (workerSkillValidity) {
      
      case API.Validity.KO_EXPIRED:
      case API.Validity.KO_MISSING:
      case API.Validity.KO_NEW:
        return moment();
      
      case API.Validity.KO_REJECTED:
        return moment().valueOf() < moment(workerSkill.activeProofBundle?.review.date).valueOf()
          ? moment(workerSkill.activeProofBundle?.review.date)
          : moment();
      case API.Validity.OK_EXPIRE_SOON:
        if (workerSkill.expiryDate) {
          const startDate =
            moment(workerSkill.expiryDate).valueOf() -
            Math.floor(trainingVersion.durationInMin * 60 * 1000);
          return moment(startDate);
        }
      default:
        logger.info('There is no matching validity');
        return moment();
    }
  }

  function computeRenew(validity: API.Validity): boolean | null {
    switch (validity) {
      case API.Validity.KO_EXPIRED:
      case API.Validity.OK_EXPIRE_SOON:
        return true;

      case API.Validity.KO_MISSING:
      case API.Validity.KO_NEW:
      case API.Validity.KO_REJECTED:
        return false;

      case API.Validity.OK:
        logger.info('This case will not happen');
        return null;
      default:
        logger.info('There is no matching validity');
        return null;
    }
  }

  async function computeWorkerWorkstationSkillInfoDetails(
    workerWorkstations: API.WorkerWorkstation[],
  ): Promise<API.Result<WorkerTrainingVersionDetails[]>> {
    const workerTrainingDetails: WorkerTrainingVersionDetails[] = [];
    const errors: API.Failure[] = [];

    const requirements = await API.getRequirements();
    if (!isMounted.current) return [];
    if (API.isFailure(requirements)) {
      logger.warn(requirements);
      return [];
    }

    await wait(timeoutForEachAsyncOperation);

    const trainingVersions = await API.getTrainingVersions();
    if (!isMounted.current) return [];
    if (API.isFailure(trainingVersions)) {
      logger.warn(trainingVersions);
      return [];
    }

    await wait(timeoutForEachAsyncOperation);

    const requirementsUpdated: API.RequirementUpdateInput[] = [];
    const workerSkillInfoHavingWorkerSkills: any = [];

    _.map(requirements.result, requirement => {
      const skillTrainingVersionUpdated: API.SkillTrainingVersionInput[] = [];

      _.map(requirement.skillTrainingVersions, (skillTrainingVersion, index) => {
        if (!skillTrainingVersion.trainingVersionId) {
          const trainingVersion = trainingVersions.result.find(trainingVersion =>
            trainingVersion.skillIds.includes(skillTrainingVersion.skillId),
          );

          if (trainingVersion) {
            skillTrainingVersionUpdated.push({
              skillId: requirement.skillTrainingVersions[index].skillId,
              trainingVersionId: trainingVersion.id,
            });
          } else {
            logger.warn('This skill dont have training', skillTrainingVersion.skillId);
          }
        } else {
          skillTrainingVersionUpdated.push(skillTrainingVersion);
        }
      });

      requirementsUpdated.push({
        ...requirement,
        skillTrainingVersions: skillTrainingVersionUpdated,
      });
    });

    _.map(workerWorkstations, workerWorkstation => {
      
      const workerSkillInfoNotHavingWorkerSkills: any[] = [
        ...(workerWorkstation.invalidMissingSkills ?? []),
      ];
      if (workerSkillInfoNotHavingWorkerSkills.length) {
        _.map(workerSkillInfoNotHavingWorkerSkills, workerSkillInfoNotHavingWorkerSkill => {
          _.map(workerSkillInfoNotHavingWorkerSkill.requirmentIds, requirementId => {
            const requirement = requirementsUpdated.find(
              _requirment => _requirment.id === requirementId,
            );

            if (requirement) {
              _.map(requirement.skillTrainingVersions, skillTrainingVersion => {
                if (skillTrainingVersion.trainingVersionId) {
                  workerTrainingDetails.push({
                    workerId: workerWorkstation.workerId,
                    trainingVersionId: skillTrainingVersion.trainingVersionId,
                    toBeStartedDate: moment(),
                    renew: false,
                  });
                } else {
                  logger.warn('This skill dont have training', skillTrainingVersion.skillId);
                }
              });
            }
          });
        });
      }

      
      workerSkillInfoHavingWorkerSkills.push(
        ...(workerWorkstation.invalidExpiredSkills ?? []),
        ...(workerWorkstation.invalidNoRefreshSkills ?? []),
        ...(workerWorkstation.validExpireSoonSkills ?? []),
        ...(workerWorkstation.validSkills ?? []),
      );
    });

    const uniqWorkerSkillInfoHavingWorkerSkills: any = _.uniqBy(
      workerSkillInfoHavingWorkerSkills,
      'workerSkillId',
    );

    if (uniqWorkerSkillInfoHavingWorkerSkills.length) {
      await Aigle.mapLimit(
        uniqWorkerSkillInfoHavingWorkerSkills,
        mapBatchCount,
        async workerSkillInfo => {
          await wait(timeoutForEachAsyncOperation);
          const workerIdAndSkillId = API.getWorkerIdSkillId(workerSkillInfo.workerSkillId);
          if (API.isFailure(workerIdAndSkillId)) {
            logger.warn(workerIdAndSkillId);
            return;
          }

          if (workerIdAndSkillId) {
            const workerSkill = await API.getWorkerSkill(
              workerIdAndSkillId.workerId,
              workerIdAndSkillId.skillId,
            );
            if (!isMounted.current) return;
            if (API.isFailure(workerSkill)) {
              logger.warn(workerSkill);
              return;
            }
            const workerSkillValidity = await API.computeWorkerSkillValidity(
              workerSkill,
              new Date(timeRangeEndDate),
            );
            if (!isMounted.current) return;
            if (API.isFailure(workerSkillValidity)) {
              logger.warn(workerSkillValidity);
              return;
            }

            if (workerSkillValidity !== API.Validity.OK) {
              const trainingVersions = await API.getTrainingVersionsForSkill(
                workerIdAndSkillId.skillId,
                false,
              );
              if (!isMounted.current) return;
              if (API.isFailure(trainingVersions)) {
                logger.warn(trainingVersions);
                return;
              }

              if (trainingVersions && trainingVersions[0]) {
                const trainingVersion = trainingVersions[0];
                if (trainingVersion) {
                  const workerSkillWithDetails = await API.getWorkerSkillComplementaryDetails(
                    workerSkill,
                  );
                  if (!isMounted.current) return;
                  if (API.isFailure(workerSkillWithDetails)) {
                    logger.warn(workerSkillWithDetails);
                    return;
                  }

                  workerTrainingDetails.push({
                    workerId: workerIdAndSkillId.workerId,
                    trainingVersionId: trainingVersion.id,
                    toBeStartedDate: computeToBeStartedDate(
                      workerSkillValidity,
                      workerSkillWithDetails,
                      trainingVersion,
                    ),
                    renew: computeRenew(workerSkillValidity),
                  });
                }
              }
            } else {
              logger.warn('This skill dont have training', workerIdAndSkillId.skillId);
            }
          }
        },
      );
    }

    if (errors.length) return API.createFailure_Multiple(errors);

    return workerTrainingDetails;
  }

  /**
   * This function will remove the corresponding workerId and training versionId, if the training session exists.
   * @param workerIdAndTrainingVersionIdData
   * @returns
   */
  async function removeWorkerTrainingVersionDataIncludedInTrainingSession(
    workerIdAndTrainingVersionIdData: WorkerTrainingVersionDetails[],
  ): Promise<API.Result<WorkerTrainingVersionDetails[] | undefined>> {
    const trainingSessions = await API.getTrainingSessions([API.TrainingSessionStage.InProgress]);
    if (!isMounted.current) return;
    if (API.isFailure(trainingSessions)) {
      logger.warn(trainingSessions);
      return;
    }

    const copyMergedWorkerIdAndTrainingVersionIdData = [...workerIdAndTrainingVersionIdData];

    trainingSessions.forEach(trainingSession => {
      if (
        API.isTrainingSessionStartedOrStartedLate(trainingSession) ||
        API.isTrainingSessionScheduledOrLateStart(trainingSession)
      ) {
        const index = copyMergedWorkerIdAndTrainingVersionIdData.findIndex(
          _copyMergedWorkerIdAndTrainingVersionIdData =>
            _copyMergedWorkerIdAndTrainingVersionIdData.trainingVersionId ===
              trainingSession.trainingVersionId &&
            trainingSession.traineeIds.some(
              traineeId => traineeId === _copyMergedWorkerIdAndTrainingVersionIdData.workerId,
            ),
        );
        if (index !== -1) {
          copyMergedWorkerIdAndTrainingVersionIdData.splice(index, 1);
        }
      }
    });

    return copyMergedWorkerIdAndTrainingVersionIdData;
  }

  async function computedSkillInfoFromActiveTrainingSessions(): Promise<
    API.Result<WorkerTrainingVersionDetails[] | undefined>
  > {
    const { workers, trainingSessionsWithState } = kpiData;
    const trainingSessions = await API.getTrainingSessions([
      API.TrainingSessionStage.Request,
      API.TrainingSessionStage.Proposal,
    ]);
    if (!isMounted.current) return;
    if (API.isFailure(trainingSessions)) {
      logger.warn(trainingSessions);
      return;
    }

    const workerTrainingVersionDetails: WorkerTrainingVersionDetails[] = [];

    const workerSkills = await API.getWorkerSkills();
    if (!isMounted.current) return;
    if (API.isFailure(workerSkills)) {
      logger.warn(workerSkills);
      return;
    }

    const trainingVersions = await API.getTrainingVersions();
    if (!isMounted.current) return;
    if (API.isFailure(trainingVersions)) {
      logger.warn(trainingVersions);
      return;
    }

    if (trainingSessionsWithState) {
      await Aigle.map(trainingSessionsWithState, async trainingSession => {
        if (
          API.isTrainingSessionRequested(trainingSession) ||
          API.isTrainingSessionDraft(trainingSession) ||
          API.isTrainingSessionRequestValidated(trainingSession)
        ) {
          const traineeIds = trainingSession.scheduledTraineeIds.filter(traineeId =>
            workers?.some(worker => worker.id === traineeId),
          );

          if (traineeIds.length) {
            const trainingVersion: API.TrainingVersion | undefined = trainingVersions.result.find(
              trainingVersion => trainingVersion.id === trainingSession.trainingVersionId,
            );

            let workerSkillFullFiled = true;

            if (trainingVersion) {
              await Aigle.map(traineeIds, async traineeId => {
                await Aigle.map(trainingVersion.skillIds, async skillId => {
                  if (!workerSkillFullFiled) return;

                  const workerSkill = workerSkills.result.find(
                    workerSkill =>
                      workerSkill.workerId === traineeId && workerSkill.skillId === skillId,
                  );

                  if (!workerSkill) {
                    workerSkillFullFiled = false;
                  } else {
                    const validity = await API.computeWorkerSkillValidity(
                      workerSkill,
                      new Date(timeRangeEndDate),
                    );
                    if (!isMounted.current) return;
                    if (API.isFailure(validity)) {
                      logger.warn(validity);
                      return;
                    }

                    if (
                      !(
                        validity === API.Validity.KO_EXPIRED ||
                        validity === API.Validity.OK_EXPIRE_SOON
                      )
                    ) {
                      workerSkillFullFiled = false;
                    }
                  }
                });

                if (!workerSkillFullFiled) {
                  workerTrainingVersionDetails.push({
                    workerId: traineeId,
                    trainingVersionId: trainingVersion.id,
                    toBeStartedDate: moment(),
                    renew: false,
                  });
                } else {
                  workerTrainingVersionDetails.push({
                    workerId: traineeId,
                    trainingVersionId: trainingVersion.id,
                    toBeStartedDate: moment(),
                    renew: false,
                  });
                }
              });
            }
          }
        }
      });
    }

    return workerTrainingVersionDetails;
  }

  async function fetchTrainingForecastGraph(loadFullGraphData?: boolean) {
    

    const workerWorkstations = await getFilteredWorkerWorkstations();
    if (!isMounted.current) return;
    if (API.isFailure(workerWorkstations)) {
      logger.warn(workerWorkstations);
      return;
    }
    let tooMuchDataToLoad = false;

    await wait(timeoutForEachAsyncOperation);

    if (!loadFullGraphData && workerWorkstations.length > forecastGraphLimit) {
      tooMuchDataToLoad = true;
    }

    const _computedSkillInfoFromActiveTrainingSessions =
      await computedSkillInfoFromActiveTrainingSessions();
    if (!isMounted.current) return;
    if (API.isFailure(_computedSkillInfoFromActiveTrainingSessions)) {
      logger.warn(_computedSkillInfoFromActiveTrainingSessions);
      return;
    }

    await wait(timeoutForEachAsyncOperation);

    const computedSkillInfoDetails = await computeWorkerWorkstationSkillInfoDetails(
      tooMuchDataToLoad ? workerWorkstations.slice(0, forecastGraphLimit) : workerWorkstations,
    );
    if (!isMounted.current) return;
    if (API.isFailure(computedSkillInfoDetails)) {
      logger.warn(computedSkillInfoDetails);
      return;
    }

    await wait(timeoutForEachAsyncOperation);

    
    const groupByWorkerIdAndTrainingVersionId = _.groupBy(
      [...computedSkillInfoDetails, ...(_computedSkillInfoFromActiveTrainingSessions ?? [])],
      computedSkillInfoDetail => {
        return `${computedSkillInfoDetail.workerId},${computedSkillInfoDetail.trainingVersionId}`;
      },
    );

    
    
    const mergedWorkerTrainingVersionDetailsData: WorkerTrainingVersionDetails[] = [];

    for (const [key, eachGroup] of Object.entries(groupByWorkerIdAndTrainingVersionId)) {
      if (eachGroup.length > 1) {
        const groupHavingRenew = eachGroup.filter(eachOne => eachOne.renew);
        const groupNotHavingRenew = eachGroup.filter(eachOne => !eachOne.renew);

        if (groupHavingRenew.length) {
          mergedWorkerTrainingVersionDetailsData.push(groupHavingRenew[0]);
        } else if (groupNotHavingRenew.length) {
          mergedWorkerTrainingVersionDetailsData.push(groupNotHavingRenew[0]);
        }
      } else {
        mergedWorkerTrainingVersionDetailsData.push(...eachGroup);
      }
    }

    await wait(timeoutForEachAsyncOperation);

    
    const removedWorkerTrainingVersionDataIncludedInTrainingSession =
      await removeWorkerTrainingVersionDataIncludedInTrainingSession(
        mergedWorkerTrainingVersionDetailsData,
      );
    if (!isMounted.current) return;
    if (API.isFailure(removedWorkerTrainingVersionDataIncludedInTrainingSession)) {
      logger.warn(removedWorkerTrainingVersionDataIncludedInTrainingSession);
      return removedWorkerTrainingVersionDataIncludedInTrainingSession;
    }

    await wait(timeoutForEachAsyncOperation);

    if (removedWorkerTrainingVersionDataIncludedInTrainingSession) {
      const toRenewAndToAcquireTrainings = await groupToRenewAndToAcquireTrainings(
        removedWorkerTrainingVersionDataIncludedInTrainingSession,
      );

      if (!isMounted.current) return;

      await wait(timeoutForEachAsyncOperation);

      const toRenewTrainings = toRenewAndToAcquireTrainings.toRenew;
      const toAcquireTrainings = toRenewAndToAcquireTrainings.toAcquire;
      const toAcquireTrainingsWithDetails = await computeTrainingDetails(toAcquireTrainings);
      if (!isMounted.current) return;

      await wait(timeoutForEachAsyncOperation);

      const toRenewTrainingsWithDetails = await computeTrainingDetails(toRenewTrainings);
      if (!isMounted.current) return;

      await wait(timeoutForEachAsyncOperation);

      computeForecastGraphData(toAcquireTrainingsWithDetails, toRenewTrainingsWithDetails);
    }

    if (tooMuchDataToLoad) {
      setIsTooMuchDataToLoadInForecastGraph(true);
    } else {
      setIsTooMuchDataToLoadInForecastGraph(false);
    }
  }

  async function loadFullData() {
    setIsGraphLoaded(false);
    await fetchTrainingForecastGraph(true);
    if (!isMounted.current) return;
    setIsGraphLoaded(true);
  }

  return (
    <KPIGraphComponent
      trainingSessionActualGraphData={trainingSessionActualGraphData}
      trainingSessionForecastGraphData={trainingSessionForecastGraphData}
      noDataForForecastGraphData={noDataForForecastGraphData}
      noDataForActualGraphData={noDataForActualGraphData}
      actualGraphLoader={!isGraphLoaded}
      forcastGraphLoader={!isGraphLoaded}
      isTooMuchDataToLoadInForecastGraph={isTooMuchDataToLoadInForecastGraph}
      loadFullData={loadFullData}
    />
  );
};
