import * as API from 'shared/backend-data';
import React, { useEffect, useState } from 'react';
import { ModalUtils } from 'shared/ui-component/Modal';
import * as _ from 'lodash-es';
import { WorkerAssignmentDropDownsComponent } from '../component';
import { StyleProp, ViewStyle } from 'react-native';
import { showWarningWorkerEditAdd, WorkerEditAddWarning } from '../../warningModal';
import { t } from 'shared/localisation/i18n';

interface Props {
  worker?: API.Worker;
  assignments: API.IndexedAssignment[];
  containerStyle?: StyleProp<ViewStyle>;
  setAssignments: (assignments: API.IndexedAssignment[]) => void;
}

export const WorkerAssignmentDropDowns: React.FC<Props> = props => {
  const { worker, assignments, containerStyle, setAssignments } = props;

  const [allShifts, setAllShifts] = useState<API.Shift[]>();

  const modal = ModalUtils.useModal();

  useEffect(() => {
    fetchShifts();
  }, []);

  function handleChange(indexedAssignment: API.IndexedAssignment) {
    const index = assignments.findIndex(unitElement => unitElement.uuid === indexedAssignment.uuid);

    if (index > -1) {
      const _assignments = [...assignments];

      if (indexedAssignment.state === API.IndexedAssignmentState.TO_DELETE) {
        _assignments.splice(index, 1);
      } else _assignments[index] = indexedAssignment;

      setAssignments(_assignments);
    }
  }

  function handleAddUnitButton() {
    const currentDropDowns = API.handleAddIndexedAssignment([...assignments]);
    setAssignments(currentDropDowns);
  }

  function removeUnitRole(indexedAssignment: API.IndexedAssignment) {
    const toDeleteIndex = assignments.findIndex(
      unitElement => unitElement.uuid === indexedAssignment.uuid,
    );

    if (assignments[toDeleteIndex].state !== API.IndexedAssignmentState.TO_CREATE)
      showWarningWorkerEditAdd(
        modal,
        WorkerEditAddWarning.Delete,
        t('common:button.yes'),
        () => handleChange(indexedAssignment),
        t('common:button.no'),
      );
    else handleChange(indexedAssignment);
  }

  async function fetchShifts() {
    const shifts = await API.getShifts();
    if (API.isFailure(shifts)) return shifts;

    setAllShifts(shifts);
  }

  return (
    <>
      {allShifts && (
        <WorkerAssignmentDropDownsComponent
          worker={worker}
          assignments={assignments}
          containerStyle={containerStyle}
          allShifts={allShifts}
          handleEditAddUniteButton={handleAddUnitButton}
          removeUnitAssignment={removeUnitRole}
          handleChange={handleChange}
        />
      )}
    </>
  );
};
