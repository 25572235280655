import { StyleSheet } from 'react-native';
import { Spacings } from '../../../styles';
const Styles = StyleSheet.create({
  iconContainer: {
    width: Spacings.Large + Spacings.Medium,
    height: Spacings.Large + Spacings.Unit,
    borderRadius: Spacings.Unit,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Styles;
