export * from './Worker';
import * as workerUtils from './Worker';
import { updateWorker } from './Worker';
import { getInvitationToastMessage } from './Worker';
import { ModalUtils, ModalContext } from '../ui-component/Modal';
import { t, capitalizeFirstLetter, i18n } from '../localisation/i18n';
import * as API from 'shared/backend-data';
import { AppContext } from '../context/AppContext';
import { getInvitationErrorMessage } from './Worker';

/**
 * Archive a worker. Promise returns true if success or false if an error was raised (and displayed to the user)
 * (Business Rules will remove the Worker from the TrainingSessions the Worker is assigned to)
 * @param workerUpdateInput shall contains the worker's id
 * and optional additional worker's properties to update.
 * The state property will be overriden to "ARCHIVED".
 */

export async function archiveWorkerWithErrorModalHandling(
  workerUpdateInput: API.WorkerPartialUpdateInput,
  modal: ModalContext,
): Promise<boolean> {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: t('common:loading.operationInProgress'),
      loader: true,
    }),
  );

  const updatedWorker = await API.updateWorker({
    ...workerUpdateInput,
    state: API.WorkerState.ARCHIVED,
  });

  return new Promise(resolve => {
    if (API.isFailure(updatedWorker)) {
      if (API.isFailureType(updatedWorker, 'WorkerArchiveContractNotEnded')) {
        modal.hideModal();
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: capitalizeFirstLetter(
              i18n.t('alex:worker.addEditWorker.warnings.archiveWorkerInformationError'),
            ),
            warningAcceptButton: capitalizeFirstLetter(i18n.t('common:button.gotIt')),
            warningAcceptCallback: () => resolve(false),
            warningCancelCallback: () => resolve(false),
          }),
        );
      } else if (API.isFailureType(updatedWorker, 'Unauthorized')) {
        modal.hideModal();
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: capitalizeFirstLetter(
              i18n.t('alex:worker.addEditWorker.warnings.archiveWorkerPermissionWarning'),
            ),
            warningAcceptButton: capitalizeFirstLetter(i18n.t('common:button.gotIt')),
            warningAcceptCallback: () => resolve(false),
            warningCancelCallback: () => resolve(false),
          }),
        );
      } else {
        modal.hideModal();
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: capitalizeFirstLetter(i18n.t('common:error.retry')),
            warningAcceptButton: capitalizeFirstLetter(i18n.t('common:button.retry')),
            warningAcceptCallback: () => resolve(false),
            warningCancelCallback: () => resolve(false),
          }),
        );
      }
    } else {
      modal.hideModal();
      modal.displayModal(
        ModalUtils.toastConfig({
          text: capitalizeFirstLetter(
            updatedWorker.state === API.WorkerState.ARCHIVED
              ? i18n.t('alex:worker.addEditWorker.warnings.archiveToastWarning')
              : i18n.t('alex:worker.addEditWorker.warnings.willBeArchivedToastWarning'),
          ),
          callback: () => resolve(true),
        }),
      );
    }
  });
}

/**
 * Delete a worker. Promise returns true if success or false if an error was raised (and displayed to the user)
 * (Business Rules will remove the necessary linked Objects)
 * @param worker
 */

export async function deleteWorkerWithErrorModalHandling(
  worker: API.Worker,
  modal: ModalContext,
): Promise<boolean> {
  return new Promise(resolve => {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:worker.addEditWorker.warnings.confirmWorkerDelete', {
          name: worker.name,
        }),
        warningAcceptButton: t('common:button.yes'),
        warningAcceptCallback: async () => {
          resolve(await _delete());
        },
        warningCancelButton: t('common:button.no'),
        warningCancelCallback: () => resolve(false),
      }),
    );
  });

  async function _delete(): Promise<boolean> {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: i18n.t('common:loading.operationInProgress'),
        loader: true,
      }),
    );

    const result = await API.deleteFactoryBusinessObject<API.DataType.WORKER>(worker.id);
    modal.hideModal();
    if (API.isFailure(result)) {
      if (API.isFailureType(result, 'DeletionVeto')) {
        ModalUtils.showVetoModal(result.message, result);
        return false;
      } else if (API.isFailureType(result, 'Unauthorized')) {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: t('alex:worker.addEditWorker.warnings.deleteWorkerPermissionWarning'),
            warningAcceptButton: t('common:button.gotIt'),
          }),
        );
        return false;
      } else {
        ModalUtils.showWarningFailure(modal, result);
        return false;
      }
    }

    modal.displayModal(
      ModalUtils.toastConfig({
        text: capitalizeFirstLetter(
          i18n.t('alex:worker.addEditWorker.warnings.deleteWorkerToastWarning'),
        ),
      }),
    );
    return true;
  }
}

export function showInvitationErrorModal(
  modal: ModalContext,
  operation: API.UserInvitationOperations,
  message?: string,
): void {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: getInvitationErrorMessage(operation)!,
      warningSubMessage: capitalizeFirstLetter(
        message ? message : i18n.t('alex:inviteWorker.errorMessage.1'),
      ),
      warningAcceptButton: capitalizeFirstLetter(i18n.t('common:button.ok')),
    }),
  );
}

export async function inviteWorker(
  worker: API.NoMetadata<API.Worker>,
  modal: ModalContext,
  operation: API.UserInvitationOperations,
  invitationCallback?: () => void,
): Promise<API.Result<void>> {
  let invitation;
  const appContext = AppContext.getContext();
  if (API.isFailure(appContext)) {
    showInvitationErrorModal(modal, operation);
    return;
  }

  if (operation === API.UserInvitationOperations.GIVE_ACCESS) {
    invitation = await API.inviteUser({
      workerId: worker.id!,
      pk: appContext.pk,
      operation: API.UserInvitationOperations.GIVE_ACCESS,
    });
  } else if (operation === API.UserInvitationOperations.REMOVE_ACCESS) {
    invitation = await API.inviteUser({
      pk: appContext.pk,
      workerId: worker.id,
      operation: API.UserInvitationOperations.REMOVE_ACCESS,
    });
  }

  if (API.isFailure(invitation)) {
    if (API.isFailureType(invitation, 'InviteWorkerMissingInformation')) {
      showInvitationErrorModal(
        modal,
        operation,
        t('alex:inviteWorker.inviteWorkerMissingInformation'),
      );
    } else if (API.isFailureType(invitation, 'Unauthorized')) {
      showInvitationErrorModal(
        modal,
        operation,
        t('alex:inviteWorker.notAuthorizedToInviteWorker'),
      );
    } else if (API.isFailureType(invitation, 'InvalidArguments')) {
      return invitation;
    } else {
      showInvitationErrorModal(modal, operation);
    }
    return invitation;
  }
  modal.displayModal(
    ModalUtils.toastConfig({
      text: getInvitationToastMessage(operation),
      callback: () => (invitationCallback ? invitationCallback() : {}),
    }),
  );
}
