import React, { useEffect, Component } from 'react';
import ReactDOM from 'react-dom';
import { Colors } from 'shared/styles';
import { Platform } from 'react-native';

export function highlightElement(hoverColor: string, event: Event) {
  
  if (event.target) {
    const target = event.target as HTMLElement;
    target.style.background = hoverColor;
  }
}

export function lowlightElement(event: Event) {
  if (event.target) {
    const target = event.target as HTMLElement;
    target.style.background = '';
  }
}

/**
 * Hook used to call @var callOnHover on the refrenced component whenever the mouse hover over it
 * and @var callOnDehover on the refrenced component whenever the mouse exit it
 * if no @var callOnDehover and @var callOnHover passed hook will highlight
 * @param callOnHover
 * @param callOnDehover
 */
export function useCallOnHover<T extends Component>(
  hoverColor: string = Colors.BlueRollover,
  callOnHover?: (event: Event) => void,
  callOnDehover?: (event: Event) => void,
) {
  function mouseEnterListener(event: Event) {
    if (callOnHover) {
      callOnHover(event);
    } else {
      highlightElement(hoverColor, event);
    }
  }

  function mouseLeaveListener(event: Event) {
    if (callOnDehover) {
      callOnDehover(event);
    } else {
      lowlightElement(event);
    }
  }

  useEffect(() => {
    if (component && Platform.OS === 'web') {
      ReactDOM.findDOMNode(component.current)?.addEventListener('mouseenter', mouseEnterListener);

      ReactDOM.findDOMNode(component.current)?.addEventListener(
        'mouseleave',
        mouseLeaveListener,
        false,
      );
    }
    return () => {
      if (component && Platform.OS === 'web') {
        ReactDOM.findDOMNode(component.current)?.removeEventListener(
          'mouseenter',
          mouseEnterListener,
        );
        ReactDOM.findDOMNode(component.current)?.removeEventListener(
          'mouseleave',
          mouseLeaveListener,
        );
      }
    };
  });
  if (Platform.OS === 'ios' || Platform.OS === 'android') return null;

  const component = React.createRef<T>();
  return component;
}
