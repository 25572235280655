import React, { useState, useEffect, useContext } from 'react';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { SkillWorkerTableComponent } from '../component';
import { MyHub } from 'shared/util/MyHub';
import Aigle from 'aigle';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import {
  extractFilterTags,
  removeInvalidObjectFromUserPreference,
} from '../../../../../header-layout/headerFilterConfig';
import { t } from 'shared/localisation/i18n';
import { SkillWorkerTableRow } from 'shared/util/Skill';

interface Props {
  skill: API.Skill;
  setWorkerCount: (count: number) => void;
}

export const SkillWorkerTableContainer: React.FC<Props> = props => {
  const { skill, setWorkerCount } = props;
  const {
    currentRoute: [, setCurrentRoute],
    currentTabIndex: [, setCurrentTabIndex],
    skillWorkerScreenFilterTags: [skillWorkerScreenFilterTags, setSkillWorkerScreenFilterTags],
  } = useContext(HeaderFilterContext);
  const [data, setData] = useState<SkillWorkerTableRow[]>([]);
  const [workerNotHavingSkill, setWorkerNotHavingSkill] = useState<SkillWorkerTableRow[]>([]);
  const [filterTags, setFilterTags] = useState<TagExtended[]>([]);

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      setCurrentRoute(RoutePaths.SkillProfile);
      setCurrentTabIndex(0);

      const skillResult = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.SkillWorkerCatalogFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(skillResult)) {
        logger.warn('fetch skill filter: error in saving user Preference', skillResult);
        return;
      }
      if (skillResult) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.SkillWorkerCatalogFilter,
          skillResult ?? [],
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.SkillWorkerCatalogFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save skill filter: error while saving user Preference', saved);
          return;
        }
        setSkillWorkerScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.SkillWorkerCatalogFilter) ?? [],
        );
      }
    });

    return () => {
      setCurrentRoute(undefined);
      setCurrentTabIndex(undefined);
    };
  }, []);

  useEffect(() => {
    setFilterTags(extractFilterTags(skillWorkerScreenFilterTags));
  }, [skillWorkerScreenFilterTags]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !skill) return;
      await fetchWorkerSkill(skill.id);
    });
  }, [skill]);

  async function fetchWorkerSkill(skillId: string) {
    const result = await API.getWorkers(undefined, skillId);
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      logger.warn(result);
      return;
    }

    await getSkillWorkerTableData(result.result);
  }

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
        if (
          data.factory.dataType === API.DataType.WORKERSKILL &&
          (data.tooManyMutations || data.factory.workerSkill.skillId === skill.id)
        ) {
          fetchWorkerSkill(skill.id);
        } else if (data.factory.dataType === API.DataType.WORKER) {
          fetchWorkerSkill(skill.id);
        } else if (data.factory.dataType === API.DataType.ORGUNIT) {
          fetchWorkerSkill(skill.id);
        } else if (
          data.factory.dataType === API.DataType.WORKERSKILL &&
          (data.tooManyMutations || data.factory.workerSkill.skillId === skill.id)
        ) {
          fetchWorkerSkill(skill.id);
        }
      });

      return () => {
        removeListener();
      };
    });
  }, [skill]);

  const getSkillWorkerTableData = async (workers: API.Worker[]) => {
    const result: SkillWorkerTableRow[] = [];
    await Aigle.map(workers, async eachWorker => {
      const _skillData = await fetchSkillData(skill.id, eachWorker.id);
      result.push({
        key: eachWorker.id,
        worker: eachWorker,
        skillData: _skillData,
        orgUnits: undefined,
      });
    });

    if (!isMounted.current) return;
    setData(result);
    setWorkerCount(result.length);
    loadWorkersWithNoSkill(result);
  };

  async function loadWorkersWithNoSkill(workerSkills: SkillWorkerTableRow[]) {
    const workers = await API.getWorkers([API.Permission.workerIsOperational]);
    if (!isMounted.current) return;
    if (API.isFailure(workers)) {
      logger.warn(workers);
      return;
    }

    const _workers = workers.result.filter(
      worker => !workerSkills.some(workerSkill => workerSkill.worker.id === worker.id),
    );
    const data: SkillWorkerTableRow[] = _workers.map(worker => {
      return {
        key: worker.id,
        worker,
        skillData: {
          stateString: t('alex:filters.notAcquired'),
          __typename: 'WorkerSkill',
          skillId: skill.id,
          workerId: worker.id,
          proofBundleIds: [],
          updatedAt: '',
          updatedBy: '',
          id: '',
          skill,
        },
      };
    });
    setWorkerNotHavingSkill(data);
  }

  const fetchSkillData = async (
    skillId: string,
    workerId: string,
  ): Promise<API.WorkerSkillWithComplementaryDetails | undefined> => {
    const workerSkill = await API.getWorkerSkill(workerId, skillId);
    if (!isMounted.current) return;
    if (API.isFailure(workerSkill)) {
      logger.warn('fetchWorkerSkill error', workerSkill);
      return;
    }
    const workerSkillWithCompDetails = await API.getWorkerSkillComplementaryDetails(workerSkill);
    if (!isMounted.current) return;
    if (API.isFailure(workerSkillWithCompDetails)) {
      logger.warn('fetchWorkerSkillWithComplementaryDetails error', workerSkillWithCompDetails);
      return;
    }

    return workerSkillWithCompDetails;
  };

  return (
    <SkillWorkerTableComponent
      fetchWorkerSkill={fetchWorkerSkill}
      skill={skill}
      rows={data}
      filterTags={filterTags}
      workerNotHavingSkill={workerNotHavingSkill}
    />
  );
};
