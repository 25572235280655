import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import upIcon from 'shared/assets/upicon.png';
import downIcon from 'shared/assets/downicon.png';
import Style from './Style';



interface Props {
  value?: number;
  min?: number;
  max?: number;
  disabled: boolean;
  step?: number;
}

export const InputNumber: React.FC<Props> = props => {
  const [count, setCount] = useState(0);
  const step = props.step || 1;

  const incrementCount = () => {
    const newValue = count + step;
    if (props.max && newValue < props.max) {
      setCount(newValue);
    } else {
      setCount(props.max as number);
    }
  };

  const decrementCount = () => {
    const newValue = count - step;
    if (props.min && newValue > props.min) {
      setCount(newValue);
    } else {
      setCount(props.min as number);
    }
  };

  return (
    <View style={Style.container}>
      <View style={Style.countContainer}>
        <Text style={Style.count}>{props.value ? props.value : count}</Text>
      </View>
      <View style={Style.buttonContainer}>
        <TouchableOpacity
          disabled={props.value ? true : false}
          onPress={() => incrementCount()}
          style={Style.buttonUp}
        >
          <Image source={upIcon} style={Style.icon} />
        </TouchableOpacity>
        <TouchableOpacity
          disabled={props.value ? true : false}
          onPress={() => decrementCount()}
          style={Style.buttonDown}
        >
          <Image source={downIcon} style={Style.icon} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

