import { StyleSheet } from 'react-native';
import { Spacings, Colors, ModalStyles, fontSize, TextFontStyles } from 'shared/styles';

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    paddingRight: Spacings.CardPadding + Spacings.CardPadding,
    paddingLeft: Spacings.CardPadding + Spacings.CardPadding,
    paddingBottom: Spacings.Small,
    backgroundColor: Colors.White,
    flexDirection: 'row',
  },
  rootContainerContent: { width: '100%', flexDirection: 'row', paddingBottom: Spacings.Standard },

  width: {
    width: ModalStyles.inputWidthWithAnchor,
  },
  title: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  separator: {
    marginTop: Spacings.Big,
  },
  companyTitleContainer: {
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  workstationTargetTitleContainer: {
    zIndex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoIcon: {
    top: Spacings.Standard,
    right: -Spacings.Big,
    width: 250,
  },
  checkBoxText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingLeft: Spacings.Small,
  },
  checkBox: {
    marginTop: Spacings.xMedium,
  },
  roleTitleContainer: {},
  sectionSeparator: {
    width: Spacings.Big + Spacings.Unit,
  },
  roleDescription: {
    fontSize: fontSize.Small,
    paddingTop: Spacings.Small,
    color: Colors.Grey,
    ...TextFontStyles.LatoRegular,
  },
  roleContainer: {
    marginTop: Spacings.CardPadding,
  },
  roleListContainer: {
    marginTop: Spacings.Standard,
    maxHeight: 372,
  },
  roleName: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    color: Colors.Black,
  },
  permissions: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
  },
  eachRoleList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: Spacings.xMedium,
    flex: 1,
  },
  glossaryContainer: { width: '100%', flex: 1 },
  translationKeysContainer: {
    marginTop: Spacings.CardPadding,
    height: 530,
  },
  eachGlossaryContainer: {
    minWidth: 172,
    paddingVertical: 6,
    height: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  glossaryList: {
    minWidth: 172,
    flex: 1,
    flexWrap: 'wrap',
    marginTop: Spacings.xMedium,
  },
  glossary: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
  },
  eachLevelContainer: { marginTop: Spacings.Medium, width: 300 },
  eachLevelTitle: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
  },
  eachLevelDescription: {
    fontSize: fontSize.Small,
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
  },
  levelsTitle: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
  },
  levelsContainer: { minWidth: 300, paddingBottom: Spacings.Standard },
  glossaryTextContainer: {
    width: '85%',
  },
  editIcon: {
    width: '15%',
  },
  permissionCountContainer: {
    flexDirection: 'row',
  },
  roleTextInput: {
    width: 328,
  },
  roleInputContainer: {
    paddingLeft: Spacings.Big - Spacings.Unit,
  },
  defaultPermissionText: {
    paddingTop: Spacings.Big - Spacings.Unit,
    fontSize: fontSize.XLarge,
    ...TextFontStyles.LatoRegular,
  },
  buttonSave: {
    height: 32,
    width: 120,
    position: 'absolute',
    right: Spacings.Standard,
    bottom: Spacings.xMedium,
  },
  buttonRoleSave: {
    height: 32,
    width: 120,
  },
  buttonText: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
  },
  buttonContainer: {
    alignItems: 'flex-end',
    paddingTop: Spacings.Standard,
    paddingRight: Spacings.Standard,
    paddingBottom: Spacings.Standard,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  roleNameContainer: {
    flex: 0.9,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  deleteButton: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  deleteButtonContainer: {
    marginRight: Spacings.Large,
  },
  titleContainer: {
    width: 350,
  },
  modalCardStyle: {
    width: ModalStyles.width,
    borderRadius: 8,
    backgroundColor: Colors.White,
  },
  displayOfNamesContainer: {
    marginTop: Spacings.Large,
  },
  iconContainer: {
    marginRight: Spacings.Small,
    marginLeft: 2,
  },
});

export default styles;
