import { ModalUtils } from 'shared/ui-component/Modal';
import { t } from 'shared/localisation/i18n';
import { ModalContext } from 'shared/ui-component/Modal/Modal';
import { Failure, isFailureType, isFailure } from 'shared/backend-data';
import logger from 'shared/util/Logger';

export enum WorkerEditAddWarning {
  ContractNotEnded = 'ContractNotEnded',
  StartEndDateContract = 'StartEndDateContract',
  MissingRequiredFields = 'MissingRequiredFields',
  InvalidEmail = 'InvalidEmail',
  InvalidPhoneNumber = 'InvalidPhoneNumber',
  InvalidMatricule = 'InvalidMatricule',
  RequiredPersonalInformation = 'RequiredPersonalInformation',
  MissingWorkerAssignment = 'MissingWorkerAssignment',
  AdminOrInvitedPersonalInformation = 'AdminOrInvitedPersonalInformation',
  NoOrgUnitEditPermission = 'NoOrgUnitEditPermission',
  Delete = 'Delete',
  ArchiveInFuture = 'ArchiveInFuture',
  ReInstateInFuture = 'ReInstateInFuture',
}

const workerEditAddWarningsMessage: Record<WorkerEditAddWarning, string> = {
  ContractNotEnded: 'alex:worker.addEditWorker.warnings.contractNotEnded',
  StartEndDateContract: 'alex:worker.addEditWorker.warnings.startEndDateContract',
  MissingRequiredFields: 'alex:worker.addEditWorker.warnings.requiredFields',
  InvalidEmail: 'alex:worker.addEditWorker.warnings.invalidEmail',
  InvalidPhoneNumber: 'alex:worker.addEditWorker.warnings.invalidPhoneNumber',
  InvalidMatricule: 'alex:worker.addEditWorker.warnings.invalidMatricule',
  RequiredPersonalInformation: 'alex:worker.addEditWorker.warnings.requiredPersonalInformation',
  MissingWorkerAssignment: 'alex:worker.addEditWorker.warnings.missingWorkerScope',
  AdminOrInvitedPersonalInformation:
    'alex:worker.addEditWorker.warnings.adminOrInvitedPersonalInformation',
  NoOrgUnitEditPermission: 'alex:worker.addEditWorker.warnings.noOrgUnitEditPermission',
  Delete: 'alex:worker.addEditWorker.warnings.confirmDelete',
  ArchiveInFuture: 'alex:worker.addEditWorker.warnings.archiveInFuture',
  ReInstateInFuture: 'alex:worker.addEditWorker.warnings.reInstateInFuture',
};

export async function showWarningWorkerEditAdd(
  modal: ModalContext,
  errorType: WorkerEditAddWarning | Failure,
  warningAcceptButton?: string,
  warningAcceptCallback?: () => void,
  warningCancelButton?: string,
  warningCancelCallback?: () => void,
) {
  let message: string;
  let acceptButton: string;

  if (isFailure(errorType)) {
    if (isFailureType(errorType, 'WorkerIdentifierNotUnique')) {
      if (errorType.data.type === 'Email')
        message = t('alex:worker.addEditWorker.warnings.duplicateEmail');
      else if (errorType.data.type === 'Phone')
        message = t('alex:worker.addEditWorker.warnings.duplicatePhoneNumber');
      else if (errorType.data.type === 'EmployeeId')
        message = t('alex:worker.addEditWorker.warnings.duplicateMatricule');
      else {
        logger.warn(
          'Unknonw WorkerIdentifierNotUnique type:' +
            errorType.data.type +
            '. Please fix the code! WorkerEditAddWarning.DuplicateMatricule will be passed instead which is not accurate.',
        );
        message = t('common:error.unknown');
      }

      ModalUtils.showVetoModal(message, errorType);
      return;
    } else if (isFailureType(errorType, 'InvalidArgumentsContractTypeNotSet')) {
      message = t('alex:worker.addEditWorker.warnings.contractTypeCantBeEmpty');
      acceptButton = t('common:button.gotIt');
    } else if (isFailureType(errorType, 'InvalidArgumentsContractDateNotValid')) {
      message = t(workerEditAddWarningsMessage[WorkerEditAddWarning.StartEndDateContract]);
      acceptButton = t('common:button.gotIt');
    } else if (
      isFailureType(errorType, 'MultipleFailures') &&
      errorType.data.find(failure => failure.type === 'Unauthorized')
    ) {
      message = t('alex:worker.addEditWorker.warnings.notAuthirizedWarning');
      acceptButton = t('common:button.gotIt');
    } else {
      message = t('alex:worker.addEditWorker.warnings.createWorkerError');
      acceptButton = t('common:button.retry');
    }
  } else {
    message = t(workerEditAddWarningsMessage[errorType]);

    if (warningAcceptButton) {
      acceptButton = warningAcceptButton;
    } else {
      switch (errorType) {
        case WorkerEditAddWarning.MissingRequiredFields:
        case WorkerEditAddWarning.InvalidEmail:
        case WorkerEditAddWarning.InvalidPhoneNumber:
        case WorkerEditAddWarning.InvalidMatricule:
        case WorkerEditAddWarning.StartEndDateContract:
        case WorkerEditAddWarning.ContractNotEnded:
        case WorkerEditAddWarning.RequiredPersonalInformation:
        case WorkerEditAddWarning.MissingWorkerAssignment:
        case WorkerEditAddWarning.NoOrgUnitEditPermission:
        case WorkerEditAddWarning.AdminOrInvitedPersonalInformation:
        case WorkerEditAddWarning.MissingWorkerAssignment:
          acceptButton = t('common:button.complete');
          break;

        case WorkerEditAddWarning.ArchiveInFuture:
        case WorkerEditAddWarning.ReInstateInFuture:
          acceptButton = t('common:button.gotIt');
          break;

        default:
          acceptButton = t('common:button.complete');
      }
    }
  }

  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: message,
      warningAcceptButton: warningAcceptButton ?? acceptButton,
      warningAcceptCallback: warningAcceptCallback,
      warningCancelButton: warningCancelButton,
      warningCancelCallback: warningCancelCallback,
    }),
  );
}
