import { StyleSheet } from 'react-native';
import { Spacings } from 'shared/styles';
import * as SharedStyles from 'shared/styles';

const styles = StyleSheet.create({
  trainingRowContainer: {
    marginBottom: SharedStyles.Spacings.Medium,
    width: 280,
    paddingBottom: SharedStyles.Spacings.Medium,
    paddingTop: SharedStyles.Spacings.Unit * 1.5,
    paddingRight: SharedStyles.Spacings.Unit * 3,
    paddingLeft: SharedStyles.Spacings.Unit * 5,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    borderRadius: 8,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
  },
  trainingIconContainer: {
    height: 24,
    width: 24,
  },
  trainingTypeTextStyle: {
    fontSize: SharedStyles.fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
    marginBottom: Spacings.Small + 1,
  },
  trainingSkillCountTextStyle: {
    fontSize: SharedStyles.fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
    marginRight: Spacings.Unit * 12,
  },
  trainingNameTextStyle: {
    fontSize: SharedStyles.fontSize.Medium,
    ...SharedStyles.TextFontStyles.LatoMedium,
    marginBottom: Spacings.Unit * 3 - 1,
    marginTop: Spacings.Small,
    marginLeft: Spacings.Unit,
  },
  skillNameListingContainer: { flexDirection: 'row', marginBottom: SharedStyles.Spacings.Small },
  skillIconContainer: { height: 24, width: 24, marginRight: SharedStyles.Spacings.Small },
  skillNameTextStyle: {
    fontSize: SharedStyles.fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
    paddingTop: Spacings.Unit / 2,
  },
  touchableContainer: { flexDirection: 'row', justifyContent: 'space-between', width: '100%' },
  skillExpandContainer: { width: '100%' },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileTextStyle: {
    fontSize: SharedStyles.fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    ...SharedStyles.TextFontStyles.LatoRegular,
    marginRight: Spacings.Unit,
    paddingBottom: Spacings.Unit / 2,
  },
  scrollViewContainer: { paddingTop: Spacings.Unit * 2.5 },
});

export default styles;
