import React, { useState, createContext, useEffect, useRef, useContext } from 'react';
import * as API from 'shared/backend-data';
import { AppContext } from './AppContext';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { MyHub } from 'shared/util/MyHub';
import { GlobalDataContext } from '../skillmgt/context/GlobalDataContext';

export interface PermissionManagementData {
  /**
   * Check if the current User has the specified permission
   * @param permission
   * @param orgUnitOrTreeNode (optional) if set, the user needs to have the permission on the given orgUnit. If not set any orgUnit where the user has the permission will succeed
   * @param bypassForWorkerIds (optional) if the current user is included in this list, the permission check is by passed
   * @param workerAssignments (optional) if the current user has the required permission against these roles (if this param is passed we dont rely on orgUnitOrTreeNode anymore)
   * @returns
   */
  isValidPermission: (
    permission: API.Permission,
    orgUnitOrTreeNode?: API.OrganizationalUnit | API.TreeNode,
    bypassForWorkerIds?: (string | undefined)[],
    workerAssignments?: API.AssignmentWithUnitDetails[] | undefined,
  ) => boolean;
  /**
   * Check if the current User is the given Worker
   * @param workerId
   * @returns
   */
  isUser: (workerId: string | undefined) => boolean;
  isUserPermissionReady: boolean;
}

export const PermissionManagementContext = createContext<PermissionManagementData>({
  isValidPermission: () => false,
  isUser: () => false,
  isUserPermissionReady: false,
});

export const PermissionManagementProvider: React.FC = props => {
  const [userAssigmnets, setUserAssignments] = useState<API.Assignment[]>();
  const [isUserPermissionReady, setIsUserPermissionReady] = useState<boolean>(false);

  const userWorkerId = useRef<string>();
  const userPermissions = useRef<Set<API.Permission>>(new Set());

  const { features } = useContext(GlobalDataContext);

  useEffect(() => {
    const removeMutationListener = MyHub.listenBusinessObject(
      'BusinessObjectMutate',
      ({ data }) => {
        if (
          data.factory.dataType === API.DataType.WORKER_TENANT_APP &&
          (data.tooManyMutations || data.factory.worker.id === userWorkerId.current)
        ) {
          getPermissions();
        } else if (
          data.factory.dataType === API.DataType.WORKER &&
          (data.tooManyMutations || data.factory.worker.id === userWorkerId.current)
        ) {
          getPermissions();
        }
      },
    );

    getPermissions();

    return () => {
      removeMutationListener();
    };
  }, []);

  async function getPermissions(): Promise<void> {
    setIsUserPermissionReady(false);

    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) {
      logger.warn(appContext);
      return;
    }

    userWorkerId.current = appContext.workerId;
    const workersAssignmentsMap = await API.getWorkersAssignments(true, false, [
      appContext.workerId,
    ]);
    if (API.isFailure(workersAssignmentsMap)) {
      logger.warn(workersAssignmentsMap);
      setIsUserPermissionReady(false);
      return;
    }

    const _workerAssignments = workersAssignmentsMap.get(appContext.workerId);

    setUserAssignments(_workerAssignments);
    const _permissions = new Set<API.Permission>([]);
    _.map(_workerAssignments, workerAssignment => {
      _.forEach(workerAssignment.permissions, permission => {
        _permissions.add(permission);
      });
    });
    userPermissions.current = _permissions;

    setIsUserPermissionReady(true);
  }

  function isTreeNodeOrOrgUnit(
    object: API.OrganizationalUnit | API.TreeNode,
  ): object is API.TreeNode {
    const treeNode = object as API.TreeNode;
    if (treeNode.children) return true;

    return false;
  }

  function isUser(workerIdToCheck: string | undefined): boolean {
    if (!workerIdToCheck) return false;

    return workerIdToCheck === userWorkerId.current;
  }

  function isValidPermission(
    permission: API.Permission,
    orgUnitOrTreeNode?: API.OrganizationalUnit | API.TreeNode,
    bypassForWorkerIds?: (string | undefined)[],
    workerAssignments?: API.AssignmentWithUnitDetails[] | undefined,
  ): boolean {
    function check1(): boolean {
      if (
        bypassForWorkerIds?.find(
          idToGivePermissionTo => idToGivePermissionTo === userWorkerId.current,
        )
      )
        return true;

      if (!userAssigmnets) return false;

      if (!orgUnitOrTreeNode) {
        if (workerAssignments) {
          const workerAssignmentsToCheckAgainstOrgUnitIdsMap = new Map<string, string>();
          workerAssignments.forEach(workerAssignmentCheckAgainst => {
            workerAssignmentsToCheckAgainstOrgUnitIdsMap.set(
              workerAssignmentCheckAgainst.organizationalUnit.id,
              workerAssignmentCheckAgainst.organizationalUnit.id,
            );
          });

          const sharedRoles = userAssigmnets.filter(userAssignment => {
            return workerAssignmentsToCheckAgainstOrgUnitIdsMap.get(
              userAssignment.organizationalUnitId,
            );
          });

          return !!sharedRoles.find(sharedRole => sharedRole.permissions.includes(permission));
        }

        return userPermissions.current.has(permission);
      }

      
      let _orgUnit: API.OrganizationalUnit;
      if (isTreeNodeOrOrgUnit(orgUnitOrTreeNode)) {
        if (API.isOrganizationalUnit(orgUnitOrTreeNode.object)) {
          _orgUnit = orgUnitOrTreeNode.object;
        } else {
          const parentOrgUnit = API.getOrganizationalUnit(orgUnitOrTreeNode.object.parentId);
          if (API.isFailure(parentOrgUnit)) return false;

          _orgUnit = parentOrgUnit;
        }
      } else {
        _orgUnit = orgUnitOrTreeNode;
      }

      let isWorkerAuthirized = false;

      for (const userOrgUnitRole of userAssigmnets) {
        if (
          userOrgUnitRole.organizationalUnitId === _orgUnit.id &&
          userOrgUnitRole.permissions.includes(permission)
        ) {
          isWorkerAuthirized = true;
          break;
        } else {
          if (userOrgUnitRole.originObjectId) {
            for (const assignment of userAssigmnets) {
              if (
                assignment.organizationalUnitId ===
                  API.extractOrgUnitIdFromScopeKey(userOrgUnitRole.originObjectId!) &&
                assignment.permissions.includes(permission)
              ) {
                isWorkerAuthirized = true;
                break;
              }
            }
          }
        }
      }
      return isWorkerAuthirized;
    }

    if (permission === API.Permission.trainingSessions_edit) {
      
      if (!features.some(f => f.feature === API.AppFeature.SKILLOP_TRAINING)) return false;
    }

    return check1();
  }

  return (
    <PermissionManagementContext.Provider
      value={{
        isValidPermission,
        isUser,
        isUserPermissionReady,
      }}
    >
      {props.children}
    </PermissionManagementContext.Provider>
  );
};
