import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  closeIconContainer: { position: 'absolute', top: 8, right: 12 },
  modalBackground: {
    minHeight: 436,
    borderRadius: 8,
    backgroundColor: Colors.White,
    maxHeight: '100%',
    padding: Spacings.Standard,
    paddingLeft: Spacings.Large,
  },
  titleStyle: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.XLarge,
    marginBottom: Spacings.Small,
  },
  saveButton: {
    height: 35,
    width: '90%',
    alignSelf: 'center',
    marginTop: Spacings.Medium,
  },
  saveButtonTextStyle: { fontSize: fontSize.Regular },
  workerNameText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    alignSelf: 'center',
    marginLeft: Spacings.Unit,
  },
  nodeWorkerContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingRight: Spacings.Unit,
    alignItems: 'center',
    marginTop: Spacings.Medium,
    paddingLeft: Spacings.Unit,
  },
  workersContainer: { maxHeight: 150, zIndex: -1 },
  unitsContainer: {
    marginBottom: Spacings.Small,
    width: 550,
  },
});
export default styles;
