import { v4 as uuidv4 } from 'uuid';
import { Auth, Hub } from 'aws-amplify';
import {
  ClientMetadata,
  ICognitoUserPoolData,
  CodeDeliveryDetails,
  CognitoUser,
  ISignUpResult,
  ChallengeName,
} from 'amazon-cognito-identity-js';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { AppContext } from './AppContext';
import { getLanguage } from 'shared/localisation/i18n';
import { CognitoAuthError } from 'shared/backend-data';
import * as Pattern from 'shared/util/const';
import { MyHub } from '../util/MyHub';
import { DataLayer } from '../backend-data/DataLayer';
import { Immutable } from '../util-ts/Functions';

export enum SignUpAttributes {
  email = 'email',
  phoneNumber = 'phone_number',
  
  username = 'username',
  null = 'null',
  matricule = 'preferred_username',
}

export interface CognitoUserSession {
  idToken: CognitoIdToken;
  refreshToken: CognitoRefreshToken;
  accessToken: CognitoAccessToken;
  clockDrift: number;
}

export interface CognitoAccessToken {
  payload: { [key: string]: any };
  jwtToken(): string;
}

export interface CognitoIdToken {
  payload: { [key: string]: any };
  jwtToken: string;
}

export interface CognitoRefreshToken {
  token: string;
}

export interface CognitoStorage {
  [key: string]: string;
}

export enum Challenges {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

/**
 * Reversed enginered on 2020/06 from sign-in result. It is probably incomplete or not covering all the use cases.
 */
export interface CognitoUserExtended extends CognitoUser {
  Session: any;
  attributes?: any; 
  authenticationFlowType: string;
  challengeName?: ChallengeName;
  challengeParam?: {
    requiredAttributes: any[];
    userAttributes: {
      email: string;
      email_verified: boolean;
      name: string;
    };
  };
  client: ClientMetadata; 
  deviceKey?: string | null;
  keyPrefix: string | null;
  pool: ICognitoUserPoolData;
  preferredMFA?: string;
  signInUserSession: CognitoUserSession;
  storage: CognitoStorage;
  userDataKey: string;
  username: string;
}

/**
 * Reversed ingenered on 2020/06. It is probably incomplete or not covering all the use cases.
 */
export interface SignUpResult extends ISignUpResult {
  user: CognitoUserExtended;
}

/**
 * Values have to match Cognito documentation. Extracted on 2020/06, it is probably incomplete or not covering all the use cases.
 */
export enum CognitoAuthErrorCodes {
  UserNotFoundException = 'UserNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException',
  /** Email alias already exists */
  AliasExistsException = 'AliasExistsException',
  CodeMismatchException = 'CodeMismatchException',
  ExpiredCodeException = 'ExpiredCodeException',
  NetworkError = 'NetworkError',
}

export interface User {
  cognitoUser: CognitoUserExtended;
  id: string;
  identityId: string;
  userAttributes: UserAttributes;
  authorizedPks: API.AuthorizedPk[];
}

interface UserAttributes {
  given_name?: string;
  family_name?: string;
  name: string;
}

class _UserContext {
  private static instance: _UserContext;
  private user: User | null = null;

  static getInstance(): _UserContext {
    if (!_UserContext.instance) {
      _UserContext.instance = new _UserContext();

      Hub.listen('auth', data => {
        if (
          data.payload.event === 'tokenRefresh_failure' &&
          data.payload.data.code === CognitoAuthErrorCodes.NotAuthorizedException
        )
          
          _UserContext.instance.signOut();
      });

      logger.debug('Initializing the UserContext ');
    }
    return _UserContext.instance;
  }

  /**
   * Set the current User and the AppContext
   * @param cognitoUser
   */
  private async setUser(cognitoUser: CognitoUserExtended | null): Promise<void> {
    if (cognitoUser === null) {
      await DataLayer.unSubscribeToAllUpdates();

      
      {
        const purgeablePks = !this.user
          ? []
          : this.user.authorizedPks.map(authorizedPk => authorizedPk.pk);

        for (const pk of purgeablePks) {
          await DataLayer.purgeCache(pk);
        }
      }

      await AppContext.setContext(null);

      const userId = this.user?.id ?? 'No previous userId set';
      this.user = null;
      logger.info('UserContext unloaded (userId: ' + userId + ')');

      MyHub.dispatchAppContext('UserUnset', {
        userId,
      });
    } else {
      try {
        const signedInUserIdentity = await this.getUserIdentityId();
        if (API.isFailure(signedInUserIdentity)) {
          logger.warn('SetUSer: failed to get user identity id', signedInUserIdentity);
          return;
        }

        const session = cognitoUser.getSignInUserSession();
        if (
          !session &&
          cognitoUser.challengeName &&
          cognitoUser.challengeName !== 'NEW_PASSWORD_REQUIRED' 
        ) {
          logger.warn('SetUSer: failed to get user session');

          return;
        }

        let userId = cognitoUser.username;
        const idToken = session ? session.getIdToken() : undefined;
        const linkedUserId = idToken ? idToken.payload.linkedUserName : undefined;
        if (linkedUserId) userId = linkedUserId;
        const authorizedPks = idToken
          ? JSON.parse(idToken.payload.authorizedPks)
          : [{ pk: userId }]; 
        const userAttributes = {
          given_name: idToken ? idToken.payload.given_name : '',
          family_name: idToken ? idToken.payload.family_name : '',
          name: idToken
            ? idToken.payload.name
            : cognitoUser.challengeParam?.userAttributes?.name ?? '',
        };

        this.user = {
          cognitoUser,
          id: userId,
          identityId: signedInUserIdentity,
          userAttributes,
          authorizedPks,
        };

        await DataLayer.subscribeToUpdates(userId);
        const sync = await DataLayer.syncClientCacheWithServer(userId);
        if (API.isFailure(sync)) {
          logger.error("Couldn't set AppContext data: " + sync.message, sync);
        } else {
          logger.info('UserContext loaded (userId: ' + userId + ')');
          logger.debug('UserContext loaded (userId: ' + userId + ') details: ', this.user);

          MyHub.dispatchAppContext('UserSet', {
            userId: userId,
          });
        }
      } catch (error) {
        logger.error("Couldn't set AppContext data", error);
      }
    }
  }

  getUser(): API.Result<Immutable<User>, 'AuthError'> {
    if (!this.user) {
      const error: CognitoAuthError = {
        message: 'User not set, please sign-in first and try again',
        code: '',
        name: '',
      };
      return API.createFailure('AuthError', error.message, error);
    }

    return this.user;
  }

  async getUserIdentityId(): Promise<API.Result<string, 'AuthError'>> {
    return Auth.currentCredentials()
      .then(data => {
        logger.debug('user identity ID', data);
        return data.identityId;
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('getUserIdentityId error', err);
        return API.createFailure('AuthError', 'getUserIdentityId', err);
      });
  }

  async checkUserAlreadyExists(usernameOrAlias: string): Promise<API.Result<boolean, 'AuthError'>> {
    logger.debug('Checking if user exists under username or alias:', usernameOrAlias);

    return Auth.confirmSignUp(usernameOrAlias, 'fakeCode', {
      forceAliasCreation: false,
    })
      .then(data => {
        
        logger.debug('checkUserAlreadyExist', data);
        return true;
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('checkUserAlreadyExists error', err);
        switch (err.code) {
          case CognitoAuthErrorCodes.UserNotFoundException:
            return false;
          case CognitoAuthErrorCodes.NotAuthorizedException:
            return true;
          case CognitoAuthErrorCodes.AliasExistsException:
            return true;
          case CognitoAuthErrorCodes.CodeMismatchException:
            return true;
          case CognitoAuthErrorCodes.ExpiredCodeException:
            return true;
          default:
            logger.debug('checkUserAlreadyExists error not recognized', err);
            return API.createFailure(
              'AuthError',
              'checkUserAlreadyExists error not recognized',
              err,
            );
        }
      });
  }

  async changePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<API.Result<void, 'AuthError'>> {
    const user = this.getUser();
    if (API.isFailure(user)) return user;

    return Auth.changePassword(user, oldPassword, newPassword)
      .then(() => {
        return;
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('changePassword error', err);
        return API.createFailure('AuthError', 'changePassword unknonwn error', err);
      });
  }

  async completeNewPassword(newPassword: string): Promise<API.Result<CognitoUser, 'AuthError'>> {
    const user = this.getUser();
    if (API.isFailure(user)) return user;

    logger.debug(
      'completeNewPassword called with',
      user.cognitoUser,
      newPassword,
      user.cognitoUser.challengeParam?.requiredAttributes,
    );
    return Auth.completeNewPassword(
      user.cognitoUser,
      newPassword,
      user.cognitoUser.challengeParam?.requiredAttributes,
    )
      .then(async (data: CognitoUserExtended) => {
        logger.debug('completeNewPassword result', data);
        await this.setUser(data);
        return data;
      })
      .catch((err: CognitoAuthError) => {
        logger.error('completeNewPassword error', err);
        return API.createFailure('AuthError', 'completeNewPassword unknown error', err);
      });
  }

  async updateUserAttributes(attributes: UserAttributes): Promise<API.Result<string, 'AuthError'>> {
    const user = this.getUser();
    if (API.isFailure(user)) return user;

    return Auth.updateUserAttributes(user.cognitoUser, attributes)
      .then(async data => {
        logger.debug('updateUserAttributes result', data);
        return data;
      })
      .catch((err: CognitoAuthError) => {
        logger.error('updateUserAttributes error', err);
        return API.createFailure('AuthError', 'updateUserAttributes', err);
      });
  }

  async forgotPassword(
    usernameOrAlias: string,
  ): Promise<API.Result<{ CodeDeliveryDetails: CodeDeliveryDetails }, 'AuthError'>> {
    return Auth.forgotPassword(usernameOrAlias)
      .then(data => {
        logger.debug('forgotPassword sent', data);
        return data;
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('forgotPassword error', err);
        return API.createFailure('AuthError', 'forgotPassword', err);
      });
  }

  async forgotPasswordSubmit(
    usernameAlias: string,
    password: string,
    otp: string,
  ): Promise<API.Result<void, 'AuthError'>> {
    return Auth.forgotPasswordSubmit(usernameAlias, otp, password)
      .then(() => {
        return;
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('forgotPasswordSubmit error', err);
        return API.createFailure('AuthError', 'forgotPasswordSubmit', err);
      });
  }

  /**
   * Try to sign in from the last session, returns true in case of success.
   * It will refresh the accessToken automatically if EXPIRED
   */
  async signInFromLastSession(): Promise<boolean> {
    return Auth.currentSession()
      .then(async data => {
        logger.info('user session', data);
        await this.setUser(await Auth.currentAuthenticatedUser());
        return true;
      })
      .catch(err => {
        logger.debug('user current session error', err);
        return false;
      });
  }

  async refreshSession(): Promise<boolean> {
    return new Promise(resolve => {
      Auth.currentAuthenticatedUser()
        .then((cognitoUser: CognitoUserExtended) => {
          const session = cognitoUser.getSignInUserSession();
          if (!session) {
            resolve(false);
          } else {
            cognitoUser.refreshSession(session.getRefreshToken(), (err: any, session: any) => {
              if (err) {
                logger.warn('Refresh session error', err);
                resolve(false);
              } else {
                this.setUser(cognitoUser)
                  .then(() => {
                    resolve(true);
                  })
                  .catch(err2 => {
                    logger.error('SetUSer error', err2);
                    resolve(false);
                  });
              }
            });
          }
        })
        .catch(error => {
          logger.debug('refreshSession error', error);
          resolve(false);
        });
    });
  }

  async signIn(
    usernameAliasOrSignInOpts: string,
    password: string,
  ): Promise<API.Result<CognitoUser, 'AuthError'>> {
    return Auth.signIn(usernameAliasOrSignInOpts, password)
      .then(async (data: CognitoUserExtended) => {
        logger.debug('signIn result', data);
        await this.setUser(data);
        return data;
      })
      .catch((err: CognitoAuthError) => {
        logger.warn('signIn error', err);
        return API.createFailure('AuthError', 'signIn', err);
      });
  }

  async signUp(
    emailPhoneOrWorkerPersonalId: string,
    firstName: string,
    lastName: string,
    password: string,
  ): Promise<API.Result<ISignUpResult, 'AuthError'>> {
    const userId = uuidv4();

    let userIdentityType = SignUpAttributes.null;
    if (Pattern.emailPattern.test(emailPhoneOrWorkerPersonalId)) {
      userIdentityType = SignUpAttributes.email;
    } else if (Pattern.phoneNumberPattern.test(emailPhoneOrWorkerPersonalId)) {
      userIdentityType = SignUpAttributes.phoneNumber;
    } else if (Pattern.workerPersonalIdPattern.test(emailPhoneOrWorkerPersonalId)) {
      userIdentityType = SignUpAttributes.matricule;
    }

    const name = firstName ? firstName + ' ' : '' + lastName;
    logger.debug(
      'Signing-up userIdentityType',
      userIdentityType,
      'username:',
      emailPhoneOrWorkerPersonalId,
      'name:',
      name,
    );

    return Auth.signUp({
      username: userId,
      password: password,
      attributes: {
        [userIdentityType]: emailPhoneOrWorkerPersonalId,
        given_name: firstName,
        family_name: lastName,
        name: name,
        locale: getLanguage().locale,
      },
      validationData: [],
    })
      .then(async (data: ISignUpResult) => {
        logger.debug('signUp result:', data);
        await this.setUser(data.user as CognitoUserExtended);
        return data;
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('Error returned by signup:', err);
        return API.createFailure('AuthError', 'signUp', err);
      });
  }

  async confirmOtp(username: string, otp: string): Promise<API.Result<void, 'AuthError'>> {
    return Auth.confirmSignUp(username, otp, {
      forceAliasCreation: true, 
    })
      .then(data => {
        logger.debug('confirmSignUp result', data);
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('confirmSignUp error', err);
        return API.createFailure('AuthError', 'confirmSignUp', err);
      });
  }

  async resendSignUpCode(username: string): Promise<API.Result<void, 'AuthError'>> {
    return Auth.resendSignUp(username)
      .then(result => {
        logger.debug('resendCode result', result);
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('resendCode error', err);
        return API.createFailure('AuthError', 'resendCode', err);
      });
  }

  async signOut(): Promise<API.Result<void, 'AuthError'>> {
    return Auth.signOut()
      .then(async result => {
        await this.setUser(null);
        return;
      })
      .catch((err: CognitoAuthError) => {
        logger.debug('logout error', err);
        return API.createFailure('AuthError', 'signOut', err);
      });
  }

  async getJwtToken(): Promise<string> {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  }
}

export const UserContext = _UserContext.getInstance();
