import React, { useState, useContext } from 'react';
import { TouchableOpacity, View, Text, TextStyle } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import * as SharedStyles from 'shared/styles';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import * as API from 'shared/backend-data';
import Styles from './style';
import { HoverableIcon } from 'shared/ui-component/HoverableIcon';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { UnauthorizedUnit, isUnauthorizedUnitType } from './index';

const trashIcon = require('shared/assets/svg/icon.trash.svg').default;

interface levelSkillTouchableRowProps {
  skill: API.Skill;
  skillIndex: number;
  textStyle?: TextStyle;
  iconBackgroundColor?: string;
  treeNode?: API.TreeNode;
  inheritedUnit?: API.TreeObject | UnauthorizedUnit;
  onPressSkill: (skill: API.Skill) => void;
  onDelete?: () => void;
}

const addProofIcon = require('shared/assets/svg/icon.addProof.mobile.svg').default;

export const LevelSkillTouchableRow: React.FC<levelSkillTouchableRowProps> = props => {
  const {
    skill,
    skillIndex,
    textStyle,
    iconBackgroundColor,
    treeNode,
    inheritedUnit,
    onPressSkill,
    onDelete,
  } = props;

  const [isHover, setIsHover] = useState(false);

  const { isValidPermission } = useContext(PermissionManagementContext);

  return (
    <TouchableOpacity
      ref={useCallOnHover<TouchableOpacity>(
        SharedStyles.Colors.BlueRollover,
        () => {
          setIsHover(true);
        },
        () => {
          setIsHover(false);
        },
      )}
      style={[Styles.levelSkillTouchableContainer, { zIndex: 999 - skillIndex }]}
      onPress={() => onPressSkill(skill)}
    >
      <View style={Styles.skillNameContainer}>
        <View style={Styles.levelSkillContentContainer}>
          <View style={Styles.levelSkillIconContainer}>
            <IconSVG
              svgComponent={addProofIcon}
              size={{
                width: SharedStyles.Spacings.Standard,
                height: SharedStyles.Spacings.Standard,
              }}
              containerStyle={[
                Styles.iconContainer,
                iconBackgroundColor ? { backgroundColor: iconBackgroundColor } : null,
              ]}
              color={SharedStyles.Colors.White}
            />
          </View>
          <View style={Styles.levelToolTipContainer}>
            <Text
              style={[
                Styles.levelSkillNameTextStyle,

                textStyle ?? {},
                { zIndex: 999 - skillIndex },
              ]}
            >
              {capitalizeFirstLetter(skill.name)}
            </Text>
          </View>
        </View>
        <View style={Styles.descriptionContainer}>
          {inheritedUnit &&
            (isUnauthorizedUnitType(inheritedUnit) ? (
              <Text style={Styles.skillFromOUtext}>
                {t(
                  'alex:workstations.workstationPanel.requirementTable.requirementSkillColumn.inheritedSkillFromUnAuthorizedUnit',
                )}
              </Text>
            ) : (
              <Text style={Styles.skillFromOUtext}>
                {t(
                  'alex:workstations.workstationPanel.requirementTable.requirementSkillColumn.inheritedSkill',
                  { unitName: inheritedUnit.name },
                )}
              </Text>
            ))}
        </View>
      </View>
      {isValidPermission(API.Permission.workstations_edit, treeNode) && (
        <View style={Styles.levelSkillDeleteContainer}>
          {isHover && !inheritedUnit && onDelete ? (
            <HoverableIcon svgIcon={trashIcon} index={skillIndex} onPress={onDelete} />
          ) : null}
        </View>
      )}
    </TouchableOpacity>
  );
};
