import React from 'react';
import { Animated, View, TouchableOpacity, Text } from 'react-native';
import WebModal from 'modal-react-native-web';
import { ModalBackgroundStyle, ModalCardStyle, ModalHeaderStyle } from 'shared/styles/ModalStyles';
import { IconSVG } from 'shared/ui-component/Icon';
import Styles from '../styles';
import { ProofHeaderTitle } from '../headerText';
import { t } from 'shared/localisation/i18n';
import { AbstractButton } from 'shared/ui-component/Button';
import { ProofBookType } from '../..';

interface Props {
  submitPage: () => void;
  submitBook: () => void;
  handleModalCloseButton: () => void;

  proofBookType?: ProofBookType;
}

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

export const SkillPageValidateReviewModal: React.FC<Props> = props => {
  const { proofBookType, submitPage, submitBook, handleModalCloseButton } = props;

  return (
    <WebModal
      animationType="fade"
      transparent
      visible={true}
      onRequestClose={handleModalCloseButton}
    >
      <Animated.View style={[ModalBackgroundStyle, Styles.rejectModalBackground]}>
        <View style={[ModalCardStyle, Styles.rejectModalContainer]}>
          <View style={[ModalHeaderStyle, Styles.modalHeader]}>
            <TouchableOpacity style={Styles.closeButtonIcon} onPress={handleModalCloseButton}>
              <IconSVG svgComponent={closeIcon} />
            </TouchableOpacity>
            <View style={Styles.headerTitleContainer}>
              <ProofHeaderTitle proofBookType={proofBookType} />
            </View>
          </View>

          <View style={Styles.proofModalValidationContentContainer}>
            <Text style={Styles.proofModalContentWarning}>{t('alex:proofBook.selectOne')}</Text>
          </View>

          <View style={Styles.proofModalContainer}>
            <AbstractButton
              text={t('alex:proofBook.submitOnlyThisPage')}
              containerStyle={Styles.proofModalReviewContainerButton}
              textStyle={Styles.proofModalContainerBottonText}
              onPress={submitPage}
            />
            <AbstractButton
              text={
                proofBookType
                  ? t('alex:proofBook.submitTrainingBookWarning')
                  : t('alex:proofBook.submitSkillBookWarning')
              }
              containerStyle={[Styles.proofModalReviewContainerButton, Styles.reviewModalPadding]}
              textStyle={Styles.proofModalContainerBottonText}
              onPress={submitBook}
            />
          </View>
        </View>
      </Animated.View>
    </WebModal>
  );
};
