import React, { useEffect } from 'react';
import { StyleSheet, Text, Platform, View } from 'react-native';
import { StyleSheet as StyleSheetWeb } from 'react-native-web';
import { Colors, TextFontStyles, fontSize, Spacings } from 'shared/styles';
import { useModal } from './Modal';

interface Props {
  text: string;
  callback?: Function;
  onScreenDuration?: number;
}

const style = StyleSheet.create({
  toastContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
  },
  toast: {
    opacity: 0.75,
    width: '95%',
    height: 130,
    backgroundColor: Colors.Black,
    justifyContent: 'center',
    borderRadius: 10,
    alignItems: 'center',
    padding: Spacings.Small,
  },
  text: {
    ...TextFontStyles.LatoMedium,
    fontSize: fontSize.Big,
    textAlignVertical: 'center',
    textAlign: 'center',
    color: Colors.White,
  },
});

const webStyle = StyleSheetWeb.create({
  toastWeb: {
    width: 328,
  },
  webText: {
    ...TextFontStyles.LatoMedium,
    fontSize: '18px',
  },
});




const ON_SCREEN_DURATION = 1000;

export const Toast: React.FC<Props> = props => {
  const { text, callback, onScreenDuration } = props;
  const isPlatformWeb = Platform.OS === 'web';

  const modal = useModal();

  
  useEffect(() => {
    setTimeout(() => {
      modal.hideModal();
      callback && callback();
    }, onScreenDuration ?? ON_SCREEN_DURATION);
  }, []);

  return (
    <View style={style.toastContainer}>
      <View style={[style.toast, isPlatformWeb && webStyle.toastWeb]}>
        <Text style={[style.text, isPlatformWeb && webStyle.webText]}>{text}</Text>
      </View>
    </View>
  );
};
