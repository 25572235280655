import React from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import { Badge } from '.';
import { Colors, Spacings } from '../../styles';
import { SkillBadgeStates } from '../../util/Skill';

export interface BadgeProps extends ViewProps {
  badgeValue: SkillBadgeStates | null | undefined;
}

export const SkillBadge: React.FC<BadgeProps> = props => {
  const { badgeValue, style } = props;

  let dotColor: string;
  switch (badgeValue) {
    case SkillBadgeStates.OK:
      dotColor = Colors.Green;
      break;
    case SkillBadgeStates.EXPIRE_SOON:
      dotColor = Colors.Yellow;
      break;
    case SkillBadgeStates.EXPIRED:
      dotColor = Colors.Red;
      break;
    default:
      dotColor = Colors.Grey;
  }

  return <Badge style={[styles.badge, style]} dotColor={dotColor} />;
};

const styles = StyleSheet.create({
  badge: {
    marginBottom: Spacings.Small + 2,
    marginLeft: 1,
  },
});
