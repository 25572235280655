import React from 'react';
import { ViewStyle, View, StyleSheet, StyleProp, PixelRatio } from 'react-native';
import { SvgProps } from 'react-native-svg';

interface IconSVGProps {
  svgComponent: React.FC<SvgProps>; 
  size?: { width: number; height: number } | undefined;
  containerStyle?: StyleProp<ViewStyle> | undefined;
  color?: string | undefined;
  horizontal?: boolean | undefined;
}

export const defaultSVGSize = (): { width: number; height: number } => {
  const pixelRatio = PixelRatio.get();

  switch (true) {
    case pixelRatio === 2:
      return { width: 24, height: 24 };

    case pixelRatio >= 2.5 && pixelRatio <= 3:
      return { width: 26, height: 26 };

    default:
      return { width: 24, height: 24 };
  }
};

const style = StyleSheet.create({
  defaultContainerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  horizontalTransform: {
    transform: [{ rotate: '90deg' }],
  },
});

/**
 * Display an SVG image
 * @param props
 */
export const IconSVG: React.FC<IconSVGProps> = props => {
  const {
    svgComponent: SVGComponent,
    size = defaultSVGSize(),
    containerStyle,
    color,
    horizontal,
  } = props;

  return (
    <View
      style={[
        style.defaultContainerStyle,
        ,
        containerStyle,
        horizontal && style.horizontalTransform,
      ]}
    >
      <SVGComponent fill={color} width={size.width} height={size.height} />
    </View>
  );
};
