import React from 'react';
import { FilterComponent } from '../component';
import * as _ from 'lodash-es';
import { DropDownSingleSelectionProps } from 'shared/ui-component/DropDown/DropDown';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import { CheckboxProps } from 'shared/ui-component/Checkbox';

export interface TagExtended extends Tag {
  isActiveBookmarkTag?: boolean;
  isCheckBoxTag?: boolean;
  userClickedCheckBox?: boolean; 
}

export interface DropdownConfig extends DropDownSingleSelectionProps<TagExtended> {
  key: string;
  label: string;
}

export interface checkBoxConfig extends TagExtended, CheckboxProps {
  key: string;
}

export interface FilterConfig {
  dropdown: DropdownConfig[];
  filterPlaceholder: string;
  checkBox?: checkBoxConfig[];
  initialFilterValues?: Tag[];
}

export interface HeaderFilter {
  filterConfig?: FilterConfig;
  tags: TagExtended[];
  savedTags: TagExtended[];
  disableFilter: boolean;
  hideFilter: boolean;
  onSaveTags: (tags: TagExtended[]) => void;
  onTagSelected: (tags: TagExtended[]) => void;
}

export const Filter: React.FC<HeaderFilter> = props => {
  return <FilterComponent {...props} />;
};
