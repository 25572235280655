import React, { useState, useEffect } from 'react';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { UserContext } from 'shared/context/UserContext';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { LoginLayout } from 'shared/layout/authentication/login';
import { IdentifyScreen } from 'shared/layout/authentication/identify-screen';
import { IdentifyUserLayout } from 'shared/layout/authentication/identify-user';
import { SignUpLayout } from 'shared/layout/authentication/sign-up';
import { RegisterCompanyLayout } from 'shared/layout/authentication/register-company';
import { ResetPasswordLayout } from 'shared/layout/authentication/reset-password';
import { ValidateUserLayout } from 'shared/layout/authentication/validate-user';
import { Platform } from 'react-native';
import { AppContext } from '../../context/AppContext';
import { TenantSelect } from 'shared/layout/authentication/select-tenant';
import { useIsMounted } from '../../hooks/IsMounted';
import { UserType } from './sign-up';
import { StackNavigationProp } from '@react-navigation/stack/lib/typescript/src/types';
import { RouteProp } from '@react-navigation/native';
import { StackNavigatorParamlist } from 'skillmgtmob/src/navigation/Route';
import { RouteNameCommon } from 'sharedmob/src/navigation/Route';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { RemoveSearchParam } from 'shared/util/Routes';

enum Layouts {
  Loader = 'Loader',
  IdentifyScreenLayout = 'IdentifyScreenLayout',
  IdentifyUserLayout = 'IdentifyUserLayout',
  LoginLayout = 'LoginLayout',
  SignUpLayout = 'SignUpLayout',
  ValidateUserLayout = 'ValidateUserLayout',
  ResetPasswordLayout = 'ResetPasswordLayout',
  InvitedUserSetPasswordLayout = 'InvitedUserSetPasswordLayout',
  RegisterCompanyLayout = 'RegisterCompanyLayout',
  MultiTenantSelectLayout = 'MultiTenantSelectLayout',
}

export interface ExternalLinkParams {
  [key: string]: string | null | undefined;
}
export interface ExternalLinkAuthParams {
  email: string;
  name?: string | null;
  otp?: string | null;
  token?: string | null;
}

interface AuthenticationLayoutProps {
  navigation?: StackNavigationProp<StackNavigatorParamlist>;
  route?: RouteProp<StackNavigatorParamlist, RouteNameCommon.Authentication_Layout>;
}

export const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = props => {
  const isWebPlatform = Platform.OS === 'web';
  const [displayLayout, setDisplayLayout] = useState<Layouts>(
    isWebPlatform ? Layouts.IdentifyUserLayout : Layouts.IdentifyScreenLayout,
  );
  const [username, setUsername] = useState<string>('');
  const [emailAuthentication, setEmailAuthentication] = useState<boolean>();
  const [password, setPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [OTP, setOTP] = useState<string>('');
  const [userTenantApps, setUserTenantApps] = useState<
    API.Factory<API.DataType.WORKER_TENANT_APP>[]
  >([]);
  const [externalLinkAuthParams, setExternalLinkAuthParams] = useState<ExternalLinkAuthParams>();

  const isMounted = useIsMounted();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const externalLinkParams = getExternalLinkParams();

    if (externalLinkParams.email) {
      setExternalLinkAuthParams({
        email: externalLinkParams.email.replace(' ', '+'), 
        token: externalLinkParams.token,
        otp: externalLinkParams.otp,
        name: externalLinkParams.name,
      });

      UserContext.signOut();

      if (displayLayout !== Layouts.IdentifyUserLayout)
        setDisplayLayout(Layouts.IdentifyUserLayout);
    }

    autoLogin();
  }, [props.route?.params]);

  function getExternalLinkParams(): Readonly<ExternalLinkParams> {
    if (isWebPlatform) {
      if (window.location.search) {
        const urlParams = new URLSearchParams(window.location.search);
        const externalLinkParams: ExternalLinkParams = {};
        for (let [key, value] of urlParams.entries()) {
          externalLinkParams[key] = value;
        }
        return Object.freeze(externalLinkParams);
      }
    } else {
      if (props.route?.params) {
        return props.route?.params;
      }
    }

    return {};
  }

  const autoLogin = async () => {
    const isUserSignedIn = await UserContext.signInFromLastSession();
    if (!isMounted.current) return;
    if (isUserSignedIn) handleSignedInNavigation();
  };

  function handleResetPasswordNavigation(otp?: string, email?: string) {
    if (otp && email) {
      setUsername(email);
      setOTP(otp);
    }
    setDisplayLayout(Layouts.ResetPasswordLayout);
  }

  function handleUsernameSearchResult(_usernameExists: boolean, _username: string) {
    setUsername(_username);
    if (_usernameExists) {
      setDisplayLayout(Layouts.LoginLayout);
    } else {
      setDisplayLayout(Layouts.SignUpLayout);
    }
  }

  async function handleSignedInNavigation(): Promise<void> {
    setLoading(true);

    const user = UserContext.getUser();
    if (API.isFailure(user)) {
      logger.warn(user);
      setLoading(false);
      return;
    }

    const userTenantApps = await API.getUserTenantApps(user.id, 'network-no-cache');
    if (!isMounted.current) return;
    if (API.isFailure(userTenantApps)) {
      logger.warn(userTenantApps);
      setLoading(false);
      return;
    }

    if (userTenantApps.length === 0) {
      if (isWebPlatform) {
        history.push(RemoveSearchParam());
      }
      setDisplayLayout(Layouts.RegisterCompanyLayout);
    } else if (userTenantApps.length === 1) {
      const result = await AppContext.setContext(
        userTenantApps[0].tenant.id + API.SeparatorIds + userTenantApps[0].app.id,
      );
      if (API.isFailure(result)) {
        setLoading(false);
        logger.error(result);
      }
    } else {
      setUserTenantApps(userTenantApps);
      setDisplayLayout(Layouts.MultiTenantSelectLayout);
    }

    setLoading(false);
  }

  function handleHomeNavigationFromValidation() {
    if (isWebPlatform) {
      history.push(RemoveSearchParam());
    }
    setDisplayLayout(Layouts.RegisterCompanyLayout);
  }

  function handleInvitedUserResetPasswordNavigation() {
    if (isWebPlatform) {
      history.push(RemoveSearchParam());
    }
    setDisplayLayout(Layouts.InvitedUserSetPasswordLayout);
  }

  function handleIdentifyScreenLayout() {
    setDisplayLayout(Layouts.IdentifyScreenLayout);
  }

  const handleBackPress = () => {
    switch (displayLayout) {
      case Layouts.Loader:
        break;
      case Layouts.IdentifyScreenLayout:
        break;
      case Layouts.IdentifyUserLayout:
        if (Platform.OS !== 'web') {
          setEmailAuthentication(undefined);
          setDisplayLayout(Layouts.IdentifyScreenLayout);
        }
        break;
      case Layouts.LoginLayout:
        setDisplayLayout(Layouts.IdentifyUserLayout);
        break;
      case Layouts.ValidateUserLayout:
        setDisplayLayout(Layouts.IdentifyUserLayout);
        break;
      case Layouts.ResetPasswordLayout:
        setDisplayLayout(Layouts.LoginLayout);
        break;
      case Layouts.SignUpLayout:
        setDisplayLayout(Layouts.IdentifyUserLayout);
        break;
      case Layouts.InvitedUserSetPasswordLayout:
        setDisplayLayout(Layouts.LoginLayout);
        break;
      case Layouts.RegisterCompanyLayout:
        break;
    }
  };

  function showLogin() {
    if (isWebPlatform) {
      history.push(RemoveSearchParam());
    }
    setDisplayLayout(Layouts.LoginLayout);
  }

  function handleGoToValidateUser(_password: string, _firstName: string, _lastName: string) {
    setPassword(_password);
    setFirstName(_firstName);
    setLastName(_lastName);
    setDisplayLayout(Layouts.ValidateUserLayout);
  }

  function handleIdentifyUserLayout(emailAuthentication: boolean | undefined) {
    setEmailAuthentication(emailAuthentication);
    setDisplayLayout(Layouts.IdentifyUserLayout);
  }

  function handleLayoutRender() {
    switch (displayLayout) {
      case Layouts.IdentifyScreenLayout:
        return <IdentifyScreen handleIdentifyUserLayout={handleIdentifyUserLayout} />;
      case Layouts.IdentifyUserLayout:
        return (
          <IdentifyUserLayout
            isWebPlatfrom={isWebPlatform}
            externalLinkAuthParams={externalLinkAuthParams}
            username={username}
            emailAuthentication={emailAuthentication}
            handleUsernameSearchResult={handleUsernameSearchResult}
            handleBackPress={handleBackPress}
            handleResetPasswordNavigation={handleResetPasswordNavigation}
            handleSignedInNavigation={handleSignedInNavigation}
            handleInvitedUserResetPasswordNavigation={handleInvitedUserResetPasswordNavigation}
            handleIdentifyScreenLayout={handleIdentifyScreenLayout}
          />
        );
      case Layouts.LoginLayout:
        return (
          <LoginLayout
            username={username}
            isWebPlatfrom={isWebPlatform}
            handleInvitedUserResetPasswordNavigation={handleInvitedUserResetPasswordNavigation}
            handleResetPasswordNavigation={handleResetPasswordNavigation}
            handleSignedInNavigation={handleSignedInNavigation}
            handleBackPress={handleBackPress}
          />
        );
      case Layouts.SignUpLayout:
        return (
          <SignUpLayout
            userType={UserType.NewUser}
            username={username}
            isWebPlatfrom={isWebPlatform}
            handleBackPress={handleBackPress}
            handleNavigation={handleGoToValidateUser}
          />
        );
      case Layouts.ValidateUserLayout:
        return (
          <ValidateUserLayout
            username={username}
            password={password}
            firstName={firstName}
            lastName={lastName}
            isWebPlatfrom={isWebPlatform}
            handleBackPress={handleBackPress}
            handleHomeNavigation={handleHomeNavigationFromValidation}
          />
        );
      case Layouts.ResetPasswordLayout:
        return (
          <ResetPasswordLayout
            username={username}
            isWebPlatfrom={isWebPlatform}
            OTP={OTP}
            handleBackPress={handleBackPress}
            handleGoToLogin={showLogin}
          />
        );
      case Layouts.InvitedUserSetPasswordLayout:
        return (
          <SignUpLayout
            userType={UserType.InvitedUser}
            username={username}
            isWebPlatfrom={isWebPlatform}
            handleBackPress={handleBackPress}
            handleNavigation={handleSignedInNavigation}
          />
        );
      case Layouts.RegisterCompanyLayout:
        return <RegisterCompanyLayout handleSignedInNavigation={handleSignedInNavigation} />;
      case Layouts.MultiTenantSelectLayout:
        return <TenantSelect isWebPlatfrom={isWebPlatform} userTenantApps={userTenantApps} />;
    }
  }

  return (
    <>
      {handleLayoutRender()}
      {loading && <Loader />}
    </>
  );
};
