import React from 'react';
import { View, Text } from 'react-native';
import { t } from 'shared/localisation/i18n';
import Styles from './styles';
import * as _ from 'lodash-es';

export const ToStartTrainingsMessage: React.FC = () => {
  return (
    <View style={Styles.complementaryTrainingsContainer}>
      <View style={Styles.warningMessageContainer}>
        <Text style={Styles.warningTextStyle}>{t('alex:TrainingModal.toStartTraining')}</Text>
      </View>
    </View>
  );
};
