import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Spacings.CardPadding,
    paddingTop: 14,
    paddingBottom: 17,
  },
  footer: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
  },
  header: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },
  headerDisabled: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
  buttonStyle: {
    backgroundColor: 'transparent',
  },
  dotStyle: {
    width: 18,
    height: 18,
    color: Colors.White,
  },
  dateTextContainer: {
    marginBottom: Spacings.Small,
  },
  dateTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  rowTotalNumberStyle: {
    fontWeight: '900',
    fontSize: 36,
    fontFamily: 'Lato, Black',
    opacity: 1,
    paddingRight: 14,
    marginTop: -2,
  },
  refreshPanel: {
    flexDirection: 'row',
    zIndex: 1,
  },
  trainingPanel: {
    flexDirection: 'row',
    zIndex: 0,
  },
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  separator: {
    margin: 5,
  },
  loader: {
    paddingRight: Spacings.CardPadding,
  },
  rootContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  panelContainer: {
    flex: 0.9,
  },
  tooltipContainer: {
    right: Spacings.Big,
    width: Spacings.Big * 6,
  },
});

export default styles;
