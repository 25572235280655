import React, { useEffect, useState } from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors } from 'shared/styles';
import styles from './styles';
import { t } from 'shared/localisation/i18n';

interface SkillConformityOptionProps {
  workerWorkstation: API.WorkerWorkstation;
  workstationSkillsRequiredInLevels?: API.WorkstationSkillsRequiredInLevels;

  onPress: () => void;
}

export const SkillConformityOption: React.FC<SkillConformityOptionProps> = props => {
  const { workerWorkstation, workstationSkillsRequiredInLevels, onPress } = props;

  const [numberOfRequiredSkills, setNumberOfRequiredSkills] = useState<number>(0);
  const [numberOfValidSkills, setNumberOfValidSkills] = useState<number>(0);
  const [containsExpiredSkills, setContainsExpiredSkills] = useState<boolean>(false);
  const [containsExpiredSoonSkills, setContainsExpiredSoonSkills] = useState<boolean>(false);

  useEffect(() => {
    fetchWorkstationSkillsRequiredInLevels();
  }, [workstationSkillsRequiredInLevels]);

  async function fetchWorkstationSkillsRequiredInLevels() {
    if (workstationSkillsRequiredInLevels) {
      let _numberOfRequiredSkills = 0;
      let _numberOfValidSkills = 0;

      const maxLevel = API.isWorkerTargetingALevelOnWorkstation(workerWorkstation)
        ? API.getWorkerWorkstationActualTargetLevel(workerWorkstation)
        : API.WorkstationWorkerLevels.LEVEL4;

      for (let level = API.WorkstationWorkerLevels.LEVEL0; level <= maxLevel; level++) {
        _.map(
          workstationSkillsRequiredInLevels.skillsRequiredInLevels.get(level) ?? [],
          eachSkill => {
            if (eachSkill.workerSkill) {
              if (eachSkill.workerSkill.validity === API.Validity.OK_EXPIRE_SOON) {
                setContainsExpiredSoonSkills(true);
                _numberOfValidSkills++;
              } else if (
                eachSkill.workerSkill.validity === API.Validity.KO_EXPIRED ||
                eachSkill.workerSkill.validity === API.Validity.KO_MISSING ||
                eachSkill.workerSkill.validity === API.Validity.KO_REJECTED ||
                eachSkill.workerSkill.validity === API.Validity.KO_NEW
              ) {
                setContainsExpiredSkills(true);
              } else {
                _numberOfValidSkills++;
              }
            }
            _numberOfRequiredSkills++;
          },
        );
      }
      setNumberOfRequiredSkills(_numberOfRequiredSkills);
      setNumberOfValidSkills(_numberOfValidSkills);
    }
  }

  const isNoTargetAndNoValidSkill =
    !API.isWorkerTargetingALevelOnWorkstation(workerWorkstation) && !numberOfValidSkills;
  const skillsOption = isNoTargetAndNoValidSkill
    ? t('alex:workstationWorkerLevelMenu.noTargetedSkills')
    : t('alex:workstationWorkerLevelMenu.workstationSkills', {
        count: 2,
      });
  const noRequiredSkills = numberOfRequiredSkills === 0;

  return (
    <TouchableOpacity
      ref={useCallOnHover<TouchableOpacity>()}
      style={[styles.optionContainer]}
      onPress={onPress}
    >
      <Text style={styles.OptionText}>{skillsOption}</Text>
      <View style={styles.fillerView}></View>
      {!noRequiredSkills && (
        <Text
          style={[
            styles.OptionTextBold,
            {
              color: containsExpiredSkills
                ? Colors.Red
                : containsExpiredSoonSkills
                ? Colors.Orange
                : Colors.Black,
            },
          ]}
        >
          {!isNoTargetAndNoValidSkill &&
            numberOfValidSkills.toString() +
              (API.isWorkerTargetingALevelOnWorkstation(workerWorkstation)
                ? '/' + numberOfRequiredSkills.toString()
                : '')}
        </Text>
      )}
    </TouchableOpacity>
  );
};
