import React, { useState } from 'react';
import { View, TouchableOpacity, Text, ScrollView, StyleProp, ViewStyle } from 'react-native';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { IconSVG } from 'shared/ui-component/Icon';
import styles from './Styles';
import { Colors, Spacings } from 'shared/styles';
import { Card } from 'shared/ui-component/Card';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';

interface List {
  name: string;
  id: string;
}

interface Props<T extends List> {
  list: T[];
  count?: number | string;
  countColor?: string;
  style?: StyleProp<ViewStyle>;
  countStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  disableHover?: boolean;
  persistIcon?: boolean;
  iconColor?: string;

  onMenuItemPress: (id: string) => void;
  onItemPress?: () => void;
}

const ArrowDownIcon = require('shared/assets/svg/ic.open.list.svg').default;

interface RenderRowProps<T extends List> {
  row: T;
}

/**
 * This component is used to display count in tables and show the related objects when we click on that.
 *
 * @prop count: 'List' array length will display as count as default.
 * if we need to display different value use this prop (eg: skill count in table)
 */
export const TableNumberWithMenu = <T extends List>(props: React.PropsWithChildren<Props<T>>) => {
  const {
    count,
    list,
    countColor,
    style,
    disabled,
    disableHover,
    persistIcon,
    iconColor,
    countStyle,

    onMenuItemPress,
    onItemPress,
  } = props;
  const [isHover, setIsHover] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const ref = useCallOnHover<TouchableOpacity>(
    Colors.BlueRollover,
    () => {
      setIsHover(true);
    },
    () => {
      setIsHover(false);
    },
  );
  const rootRef = useDetectOutsideClick<View>(() => {
    setShowMenu(false);
  });

  const RenderRow = <T extends List>(props: React.PropsWithChildren<RenderRowProps<T>>) => {
    const [isHoverOnMenuItem, setIsHoverOnMenuItem] = useState<boolean>(false);

    const ref = useCallOnHover<TouchableOpacity>(
      Colors.Transparent,
      () => {
        setIsHoverOnMenuItem(true);
      },
      () => {
        setIsHoverOnMenuItem(false);
      },
    );
    const { row } = props;

    return (
      <TouchableOpacity
        style={styles.menuNameContainer}
        ref={ref}
        onPress={() => onMenuItemPress(row.id)}
        disabled={disabled}
      >
        <Text
          style={[
            styles.menuName,
            isHoverOnMenuItem && !disabled && { textDecorationLine: 'underline' },
          ]}
        >
          {row.name}
        </Text>
      </TouchableOpacity>
    );
  };

  function handleOnPress() {
    if (onItemPress) onItemPress();
    if (list.length) setShowMenu(!showMenu);
  }

  return (
    <View ref={rootRef} style={countStyle}>
      <TouchableOpacity
        ref={ref}
        style={[
          styles.countContainer,
          (!!list.length || onItemPress) &&
            isHover &&
            !persistIcon && { backgroundColor: Colors.BlueRollover },
        ]}
        onPress={handleOnPress}
      >
        <Text style={[styles.tableNumber, countColor ? { color: countColor } : null]}>
          {count ?? list.length}
        </Text>
        {((!disableHover && isHover) || persistIcon) && (
          <IconSVG
            svgComponent={ArrowDownIcon}
            size={{ width: Spacings.Small, height: Spacings.Unit }}
            color={iconColor ?? Colors.Grey}
          />
        )}
      </TouchableOpacity>
      {showMenu && (
        <Card style={[styles.cardContainer, style]}>
          <ScrollView style={styles.scrollView}>
            {list.map((eachList, index) => (
              <RenderRow row={eachList} key={index} />
            ))}
          </ScrollView>
        </Card>
      )}
    </View>
  );
};
