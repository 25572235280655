import React from 'react';
import { StyleSheet, TouchableOpacityProps } from 'react-native';
import PlusIcon from 'shared/assets/svg/icon.plus.svg';
import { Colors, Styles, Spacings } from 'shared/styles';
import { IconButton } from 'shared/ui-component/Button';

const FAB_BUTTON_SIZE = 56;
const styles = StyleSheet.create({
  fabButton: {
    position: 'absolute',
    right: Spacings.Standard,
    bottom: Spacings.Standard,
    backgroundColor: Colors.Yellow,
    ...StyleSheet.flatten(Styles.shadow),
  },
});

interface FabButtonProps extends TouchableOpacityProps {
  onPress?: () => void;
  size?: number;
  iconSize?: number;
}

export const FabButton: React.FC<FabButtonProps> = props => {
  return (
    <IconButton
      icon={PlusIcon}
      color={Colors.White}
      size={props.size ? props.size : FAB_BUTTON_SIZE}
      iconSize={props.iconSize}
      style={[styles.fabButton, props.style]}
      onPress={props.onPress}
    />
  );
};
