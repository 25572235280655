import React from 'react';
import Styles from './style';
import { View } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import { InputText } from 'shared/ui-component/Input';
import { DropDownSingleSelection, DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { t } from 'shared/localisation/i18n';
import {
  ModalAnchorStyle,
  ModalAnchorContainerStyle,
  ModalInputWithAnchorStyle,
} from 'shared/styles/ModalStyles';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';

interface LeftPanelProps {
  UnitName: string | undefined;
  orgUnitOptions: DropDownOption[];
  selectedOrgUnit: DropDownOption | undefined;
  UnitDescription: string | null | undefined;
  setUnitName: (name: string) => void;
  setSelectedParentOrgUnit: (ou: DropDownOption | undefined) => void;
  setUnitDescription: (desc: string) => void;
  onSubmitForm: () => void;
  isRootUnit: boolean;
}

const unitIcon = require('shared/assets/svg/icon.unit.svg').default;

enum InputIds {
  UnitName = 'UnitName',
  Description = 'Description',
}

export const LeftPanel: React.FC<LeftPanelProps> = props => {
  const {
    UnitName,
    orgUnitOptions,
    selectedOrgUnit,
    UnitDescription,
    setSelectedParentOrgUnit,
    setUnitName,
    setUnitDescription,
    onSubmitForm,
    isRootUnit,
  } = props;

  const { errors, onBlur, onChange, navigateInputField, refs } = useCustomForm<string>(
    {
      [InputIds.UnitName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
      [InputIds.Description]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
    },
    onSubmitForm,
  );

  return (
    <View style={Styles.unitModalLeftPanelContainer}>
      <View style={ModalAnchorContainerStyle}>
        <View style={ModalAnchorStyle}>
          <IconSVG svgComponent={unitIcon} color={SharedStyles.Colors.White} />
        </View>
      </View>
      <View style={[Styles.unitModalLeftPanelInputContainer]}>
        <View>
          <InputText
            inputRef={refs[InputIds.UnitName]}
            inputId={InputIds.UnitName}
            style={ModalInputWithAnchorStyle}
            onBlur={onBlur}
            errorMessage={errors[InputIds.UnitName]?.inputErrorMessage}
            placeHolder={t('common:account.name')}
            defaultValue={UnitName}
            onTextChange={(inputId: string, inputValue: string) => {
              onChange(inputId, inputValue);
              setUnitName(inputValue);
            }}
            onSubmitEditing={(inputId: string, inputValue: string) => {
              setUnitName(inputValue);
              navigateInputField(InputIds.UnitName);
            }}
          />
        </View>
        {!isRootUnit && (
          <View style={[Styles.unitModalLeftPanelDropDownContainer]}>
            <DropDownSingleSelection
              options={orgUnitOptions}
              value={selectedOrgUnit}
              placeholder={t('alex:workstationModal.dropdownPlaceHolder')}
              handleChange={(_, option) => setSelectedParentOrgUnit(option)}
              notEditable={!orgUnitOptions.length}
              title={t('glossary:organizationalUnitAbbreviated')}
              containerStyle={{ width: 280 }}
            />
          </View>
        )}

        <InputTextDescription
          inputRef={refs[InputIds.Description]}
          inputId={InputIds.Description}
          onBlur={onBlur}
          errorMessage={errors[InputIds.Description]?.inputErrorMessage}
          style={ModalInputWithAnchorStyle}
          placeHolder={t('alex:workstationModal.description')}
          defaultValue={UnitDescription ?? ''}
          onTextChange={(inputId: string, inputValue: string) => {
            onChange(inputId, inputValue);
            setUnitDescription(inputValue);
          }}
          onSubmitEditing={(inputId: string, inputValue: string) => {
            setUnitDescription(inputValue);
            navigateInputField(InputIds.Description);
          }}
        />
      </View>
    </View>
  );
};
