export * from './ProofBundle';
import * as API from 'shared/backend-data';
import { Colors } from 'shared/styles/Colors';

export function getProofBundleValidityColor(
  proofBundle: API.NoMetadata<API.ProofBundle>,
  okColor = Colors.Black,
) {
  switch (proofBundle.review.state) {
    case API.ReviewState.VALIDATED:
      return okColor;
    case API.ReviewState.REJECTED:
    case API.ReviewState.REJECTED_TO_RESUBMIT:
      return Colors.Red;
    case API.ReviewState.TO_REVIEW:
      return Colors.MediumGray;
  }
}
