import React, { useState } from 'react';
import Styles from './style';
import { View, Text, ScrollView } from 'react-native';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { WorkstationLevelObjectives } from './workstationLevelObjectives/WorkstationLevelObjectives';
import * as API from 'shared/backend-data';
import { t } from 'shared/localisation/i18n';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { IconSVG } from 'shared/ui-component/Icon/IconSVG';
import { Colors } from 'shared/styles/Colors';
import { Checkbox } from 'shared/ui-component/Checkbox';
import * as _ from 'lodash-es';

interface RightPanelProps {
  orgUnitOptions: DropDownOption[];
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels;
  workstation?: API.Workstation;
  workersTargetOnShiftData?: API.WorkersTargetOnShift;

  setWorkersTargetOnShiftData: React.Dispatch<
    React.SetStateAction<API.WorkersTargetOnShift | undefined>
  >;
}

export const RightPanel: React.FC<RightPanelProps> = props => {
  const {
    workstationTargetsStartingAtLevel,
    orgUnitOptions,
    workstation,
    workersTargetOnShiftData,

    setWorkersTargetOnShiftData,
  } = props;

  const [applySameTargets, setApplySameTargets] = useState<boolean | null>(false);

  function handleApplySameTargetsCheckBox(state: boolean | null) {
    setApplySameTargets(state);
    if (state && workstation && workstation.shiftIds && workersTargetOnShiftData) {
      workstation.shiftIds.map((shiftId: string, index: number) => {
        if (index !== 0 && workstation.shiftIds && workstation.shiftIds[0]) {
          workersTargetOnShiftData[shiftId] = workersTargetOnShiftData[workstation.shiftIds[0]];
        }
      });
    }

    setWorkersTargetOnShiftData({ ...workersTargetOnShiftData });
  }

  return (
    <View style={Styles.workstationModalRightPanelContainer}>
      <View style={Styles.toolTipContainer}>
        <Text style={Styles.levelTargetTitle}>{t('$t(glossary:levelTarget_plural)')}</Text>
        <ToolTipWeb
          title={t('alex:workstationModal.workstationLevelObjectives.info')}
          style={Styles.infoIcon}
          component={
            <IconSVG
              svgComponent={require('shared/assets/svg/icon.info.svg').default}
              color={Colors.Grey}
            />
          }
        />
      </View>

      {workstation && workstation.shiftIds && workstation.shiftIds.length > 1 ? (
        <Checkbox
          text={t('alex:workstationModal.workstationLevelObjectives.applySameTargets')}
          style={Styles.checkBoxContainer}
          titleStyle={Styles.checkBoxTitle}
          onChange={handleApplySameTargetsCheckBox}
          initialState={applySameTargets}
        />
      ) : null}

      <ScrollView>
        {workstation && workstation.shiftIds && workstation.shiftIds.length ? (
          workstation.shiftIds.map((shiftId: string, index: number) => (
            <WorkstationLevelObjectives
              key={shiftId}
              index={index}
              containerStyle={Styles.workstationModalRightPanelLevel2Container}
              orgUnitOptions={orgUnitOptions}
              level={workstationTargetsStartingAtLevel}
              shiftId={shiftId}
              workstation={workstation}
              disable={!!applySameTargets}
              setWorkersTargetOnShiftData={setWorkersTargetOnShiftData}
              workersTargetOnShiftData={workersTargetOnShiftData}
            />
          ))
        ) : workstation ? (
          <WorkstationLevelObjectives
            index={0}
            containerStyle={Styles.workstationModalRightPanelLevel2Container}
            orgUnitOptions={orgUnitOptions}
            level={workstationTargetsStartingAtLevel}
            workstation={workstation}
            disable={!!applySameTargets}
            setWorkersTargetOnShiftData={setWorkersTargetOnShiftData}
            workersTargetOnShiftData={workersTargetOnShiftData}
          />
        ) : null}
      </ScrollView>
    </View>
  );
};
