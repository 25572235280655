import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  iconContainer: {
    height: 76,
    width: 76,
    borderRadius: 76 / 2,
    backgroundColor: Colors.Yellow,
    alignItems: 'center',
    justifyContent: 'center',
  },
  trainings: {
    flexDirection: 'column',
  },
  trainingName: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginBottom: 2,
    lineHeight: 18,
  },
  contentContainer: {
    height: '100%',
  },
});

export default styles;
