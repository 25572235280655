export const Colors = {
  Transparent: 'transparent',
  Black: '#020000',
  DarkBlack: '#000000',
  White: '#FFFFFF',
  BlackLight: '#707070',
  Background: '#EFF1F2',
  Grey: '#999999',
  GreyLight: '#D6D6D6',
  GreyLighter: '#D0D0D0',
  DarkGrey: '#AFAFAF',
  MediumLighterGrey: '#8E8E8E',
  MediumGray: '#606060',
  FlashWhite: '#F1F3F4',
  Yellow: '#FCD200',
  DarkYellow: '#EAC300',
  Red: '#FF302B',
  Green: '#3FD64A',
  GreenSoft: '#30E8A4',
  BlueSky: '#C4F2F2',
  Turquoise: '#78DBD9',
  ModalBackground: '#0000004D',
  LightBlue: '#EDFBFB',
  MediumBlue: '#42A5F5',
  BlackGrey: '#2E2E2E',
  LightGrey: '#3C3C3C',
  BlueRollover: '#EDFBFB',
  DimGray: '#666666',
  Orange: '#FF9D2B',
  GreyUltraLight: '#FAFAFA',
  GreyBackground: '#EFF1F233',
  ShadowGrey: '#34343480',
  ShadowColor: '#00000029',
  ShadowColor2: '#00000021',
  FadedRed: '#fc9492',
  DarkBlue: '#2D8DE3',
  ShadowWhite: '#FFFFFF00',
  Purple: '#E600C3',
  LightMagenta: '#ECB5EB',
  LightPink: '#FFCFDE',
  LightRedOrange: '#FFCFBA',
  LightYellow: '#FFDE8C',
  MediumLightYellow: '#F9F871',
  LightCyan: '#7AAFFF',
  LightGreenCyan: '#A9F9E8',
  LightGreenCyan2: '#A6FED3',
  LightGreen: '#B6FF85',
  LightGreen2: '#D3FE92',
  LightRed: '#FF927A',
  GreenSquareIcon: '#3fd64aff',
};
