import { StyleSheet } from 'react-native';
import { Spacings } from 'shared/styles';

export const styles = StyleSheet.create({
  container: {
    marginRight: 16,
  },
  toolTip: {
    marginRight: Spacings.Unit + Spacings.Unit / 2,
    marginTop: -4,
  },
  toolTipMessage: { opacity: 0.87, marginTop: 35, marginLeft: -60 },
});
