import { StyleSheet } from 'react-native';
import { TextFontStyles, Colors, Spacings, fontSize } from '../../styles';

const Styles = StyleSheet.create({
  cardContainer: {
    position: 'absolute',
    top: Spacings.Standard,
    padding: Spacings.Small,
  },
  tableText: {
    textAlign: 'left',
    fontSize: fontSize.Regular,
    color: Colors.MediumGray,
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
  },
  rootContainer: {
    width: '100%',
    position: 'relative',
  },
});

export default Styles;
