import React, { useEffect, useContext, useState } from 'react';
import * as _ from 'lodash-es';
import { RouteComponentProps } from 'react-router-dom';
import { WorkstationsComponent } from './component';
import { InteractionManager, View } from 'react-native';
import { WorkstationBanner } from '../../banner/workstationBanner/WorkstationBanner';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { useHistory } from 'react-router-dom';
import Styles from './component/style';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { ProfileRouteParam } from '../../navigation/Routes';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import logger from 'shared/util/Logger';
import { removeInvalidObjectFromUserPreference } from '../../header-layout/headerFilterConfig';
import { MyHub } from 'shared/util/MyHub';
import { useParams } from 'react-router-dom';
import { combineDataTypeAndId } from 'shared/backend-data';

export interface WorkstationsState {
  tabIndex?: number;
  subTabIndex?: number;
  selectedShiftId?: string;
}

export const Workstations: React.FC<RouteComponentProps<{}, {}, WorkstationsState>> = props => {
  const [preSelectedShiftId, setPreSelectedShiftId] = useState<string | undefined>();
  const [preSelectedWorkstationId, setPreSelectedWorkstationId] = useState<string | undefined>();

  const {
    treeNode: [treeNode, setTreeNode],
  } = useContext(MenuFactoryContext);
  const {
    currentRoute: [, setCurrentRoute],
    workstationScreenFilterTags: [, setWorkstationScreenFilterTags],
  } = useContext(HeaderFilterContext);
  const { setFirstTitle, setSecondTitle, setOnPressBack, setParentIdsWithDataType } =
    useContext(HeaderTitleContext);
  const { id } = useParams<ProfileRouteParam>();

  const isMounted = useIsMounted();

  const history = useHistory<WorkstationsState>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      setCurrentRoute(RoutePaths.Workstations);

      const skillResult = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.WorkstationFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(skillResult)) {
        logger.warn('fetch skill filter: error in saving user Preference', skillResult);
        return;
      }
      if (skillResult) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.WorkstationFilter,
          skillResult ?? [],
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.WorkstationFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save skill filter: error while saving user Preference', saved);
          return;
        }
        setWorkstationScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.WorkstationFilter) ?? [],
        );
      }
    });

    return () => {
      setParentIdsWithDataType(undefined);
      setCurrentRoute(undefined);
      setOnPressBack(() => () => {
        history.goBack();
      });
    };
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      if (!id) return;
      const workstationId = combineDataTypeAndId(API.DataType.WORKSTATION, id);

      const workstation = await API.getWorkstation(workstationId);
      if (!isMounted.current) return;
      if (API.isFailure(workstation)) {
        logger.warn(workstation);
        return;
      }

      const _treeNode = API.Tree.getTreeNode(workstation.id);
      if (API.isFailure(_treeNode)) return _treeNode;

      setTreeNode(_treeNode);
      setPreSelectedWorkstationId(combineDataTypeAndId(API.DataType.WORKSTATION, id));
      setPreSelectedShiftId(history.location.state?.selectedShiftId);
    });
  }, [id]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.WORKSTATION &&
        treeNode?.object &&
        (data.tooManyMutations || data.factory.workstation.id === treeNode.object.id)
      ) {
        setFirstTitle(capitalizeFirstLetter(treeNode.object.name));
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      handleWorkstationNavigationDetails();
    });

    return () => {
      removeListener();
    };
  }, [treeNode]);

  /**
   * If a workstation is selected, change the treeNode, change the header title, and assign a new goBack callback,
   * If a workstation is not selected, change the header title and re-assign the normal goBack() callback
   */
  function handleWorkstationNavigationDetails() {
    if (!treeNode) return;

    if (API.isTreeNode(API.DataType.WORKSTATION, treeNode)) {
      setFirstTitle(capitalizeFirstLetter(treeNode.object.name));
      setSecondTitle('');
      setParentIdsWithDataType([API.DataType.WORKSTATION, treeNode.parentIds]);
      setOnPressBack(() => async () => {
        const parentTreeNode = API.Tree.getTreeNode(treeNode.object.parentId);
        if (API.isFailure(parentTreeNode)) return parentTreeNode;

        setTreeNode(parentTreeNode);
        history.location.state = { selectedShiftId: undefined };
        setOnPressBack(() => () => {
          history.goBack();
        });
      });
    } else {
      setOnPressBack(() => () => {
        history.goBack();
      });
      if (API.isOrganizationalUnit(treeNode.object)) {
        if (API.Tree.isRootNode(treeNode)) {
          setFirstTitle(t('alex:workstationTable.headerTitle.0'));
          setSecondTitle(t('alex:workstationTable.headerTitle.1'));
          setParentIdsWithDataType(undefined);
        } else {
          setFirstTitle(capitalizeFirstLetter(treeNode.object.name));
          setSecondTitle(' ');
          setParentIdsWithDataType([API.DataType.ORGUNIT, treeNode.parentIds]);
        }
      }
    }
  }

  return (
    <View style={Styles.workstationScreenContainer}>
      {treeNode &&
        API.isTreeNode(API.DataType.WORKSTATION, treeNode) &&
        preSelectedWorkstationId && (
          <WorkstationBanner
            workstationId={preSelectedWorkstationId}
            preSelectedShiftId={preSelectedShiftId}
          />
        )}
      <WorkstationsComponent treeNode={treeNode} setTreeNode={setTreeNode} />
    </View>
  );
};
