import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  Text,
  ViewStyle,
  StyleProp,
  Platform,
  TextStyle,
  NativeSyntheticEvent,
  TextInputContentSizeChangeEventData,
} from 'react-native';
import CrossIcon from 'shared/assets/svg/icon.close.svg';
import EyeOffIcon from 'shared/assets/svg/icon.eyeOff.svg';
import EyeIcon from 'shared/assets/svg/icon.eye.svg';
import { IconSVG } from 'shared/ui-component/Icon';
import { Style } from './Style';
import { Colors } from 'shared/styles';
import _, { isArray, isFunction, isString } from 'lodash-es';
import { HoverableSaveButton } from 'shared/ui-component/Button/HoverableSaveButton';
import { useIsMounted } from 'shared/hooks/IsMounted';

export enum ParserType {
  CaseInsensitive,
  Trim,
}

export interface InputTextProps {
  placeHolder?: string | undefined;
  label?: string | undefined;
  labelStyle?: StyleProp<TextStyle>;
  defaultValue?: string | undefined;
  inputId?: string;
  multiline?: boolean;
  nbLine?: number;
  autoFocus?: boolean;
  notEditable?: boolean;
  secureTextEntry?: boolean;
  inputRef?: React.RefObject<TextInput>;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  errorMessage?: string;
  /** Add a save button to trigger a onSaveButton event. USefull for creating new items in an input tag for instance */
  showSaveButton?: boolean;
  persistTheSaveButton?: boolean;
  onSaveButtonClicked?: (item: string) => void;
  onDisabledClicked?: () => void;
  onSubmitEditing?: (inputId: string, inputValue: string) => void;
  onTextChange?: (inputId: string, inputValue: string) => void;
  onBlur?: (inputId: string, inputValue: string) => void;
  onFocus?: (inputId: string, inputValue: string) => void;
  /**
   *
   * @param parser It can be an array of ParserType or a custom
   * parser function, By default it will trim the leading and trailing white space of the inputed text,
   */
  parser?: ParserType[] | ((inputText: string) => string);
  onContentSizeChange?: (e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => void;
}

export const InputText: React.FC<InputTextProps> = props => {
  const [editing, setEditing] = useState(props.autoFocus);
  const [inputText, setInputText] = useState<string>('');

  const [secureText, setSecureText] = useState<boolean>(
    props.secureTextEntry ? props.secureTextEntry : false,
  );
  const inputRef = props.inputRef ?? useRef<TextInput>(null);
  const parsedTextRef = useRef<string>('');
  const isPlatformWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();

  useEffect(() => {
    parseInputText(props.defaultValue ?? '');
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current?.focus();
    }
  }, [props.autoFocus]);

  const parseInputText = (text: string) => {
    setInputText(text);
    if (isArray(props.parser)) {
      if (props.parser.includes(ParserType.Trim)) {
        text = text.trim();
      } else if (props.parser.includes(ParserType.CaseInsensitive)) {
        text = text.toLowerCase();
      }
    } else if (isFunction(props.parser)) {
      text = props.parser(text);
    }
    parsedTextRef.current = text;

    return text;
  };

  const onEdit = (_event: any) => {
    setEditing(true);
    if (props.onFocus) props.onFocus(props.inputId ? props.inputId : '', parsedTextRef.current);
    props.notEditable && props.onDisabledClicked ? props.onDisabledClicked() : setEditing(true);
  };

  const onEndEditing = () => {
    setEditing(false);
    handleTextSubmit();
  };

  const clearInput = () => {
    inputRef.current?.clear();
    parsedTextRef.current = '';
    parseInputText('');

    if (props.onTextChange) props.onTextChange(props.inputId ? props.inputId : '', '');
  };

  const toggleSecureTextEntry = () => {
    setSecureText(!secureText);
  };

  const handleTextChange = (inputText: string) => {
    const _parsedText = parseInputText(inputText);

    if (props.onTextChange) props.onTextChange(props.inputId ? props.inputId : '', _parsedText);
  };

  const handleTextSubmit = () => {
    if (props.onSubmitEditing)
      props.onSubmitEditing(props.inputId ? props.inputId : '', parsedTextRef.current);
  };

  const onSaveButton = () => {
    if (props.onSaveButtonClicked) {
      const text = parsedTextRef.current;
      clearInput();
      props.onSaveButtonClicked(text);
    }
  };

  const onBlur = () => {
    setTimeout(() => {
      if (isMounted.current && !props.notEditable) {
        setEditing(false);
        if (props.onBlur) props.onBlur(props.inputId ? props.inputId : '', parsedTextRef.current);
        setInputText(parsedTextRef.current.trim());
      }
    }, 200);
  };

  return (
    <View style={[Style.rootContainer, props.containerStyle]}>
      <Text style={[Style.labelContainer, props.labelStyle]}>
        {inputText || editing ? props.label || props.placeHolder : ''}
      </Text>
      <View style={[Style.inputContainer, props.style]}>
        <TextInput
          ref={inputRef}
          style={[Style.inputText, isPlatformWeb && { outline: 0 }]}
          secureTextEntry={secureText}
          testID={props.inputId}
          autoFocus={props.autoFocus}
          editable={!props.notEditable}
          placeholder={!editing ? props.placeHolder || 'Type here..' : ''}
          placeholderTextColor={Colors.Grey}
          multiline={props.multiline}
          numberOfLines={props.nbLine}
          autoCapitalize="none"
          value={inputText}
          onBlur={onBlur}
          onChangeText={handleTextChange}
          onSubmitEditing={onEndEditing}
          onFocus={onEdit}
          blurOnSubmit={false}
          onContentSizeChange={props.onContentSizeChange}
        />
        {(inputText || props.secureTextEntry) && !props.notEditable && editing && (
          <TouchableOpacity
            style={Style.closeIcon}
            onPress={props.secureTextEntry ? toggleSecureTextEntry : clearInput}
          >
            <IconSVG
              svgComponent={props.secureTextEntry ? (secureText ? EyeOffIcon : EyeIcon) : CrossIcon}
              color={props.secureTextEntry ? Colors.Grey : Colors.Black}
            />
          </TouchableOpacity>
        )}
        {(inputText || props.secureTextEntry) &&
          !props.notEditable &&
          (editing || props.persistTheSaveButton) &&
          props.showSaveButton && <HoverableSaveButton onPress={onSaveButton} />}
      </View>
      <View
        style={{
          borderBottomWidth: props.notEditable ? 0 : 1,
          borderBottomColor: editing
            ? Colors.Yellow
            : props.errorMessage && isString(props.errorMessage)
            ? Colors.Red
            : Colors.GreyLight,
        }}
      />
      <View style={Style.errorMessageContainer}>
        {!!props.errorMessage && <Text style={Style.invalidInput}>{props.errorMessage}</Text>}
      </View>
    </View>
  );
};
