import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import AlexIcon from 'shared/assets/svg/iconAlex512.svg';
import { IconSVG } from 'shared/ui-component/Icon';
import { ProgressBar } from 'shared/ui-component/ProgressBar';
import { MyHub } from 'shared/util/MyHub';
import { t } from 'shared/localisation/i18n';
import { StyleSheet, Dimensions } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';
import { useModal } from './Modal';

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  title: {
    paddingTop: 74,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Big,
    color: Colors.BlackLight,
  },
  progressBar: {
    marginTop: Spacings.CardPadding,
    width: 300,
    marginHorizontal: Spacings.Standard,
  },
  icon: {
    height: 112,
    width: 112,
  },
  rootContainer: {
    alignItems: 'center',
    paddingTop: 132,
    backgroundColor: Colors.White,
    height: height + Spacings.Big,
    width,
    zIndex: 1,
    position: 'absolute',
  },
});

interface Props {
  name: string;
  opacity?: number;
}

export const TenantAppLoading: React.FC<Props> = props => {
  const { name, opacity } = props;

  const [percentage, setPercentage] = useState<number | undefined>(0);

  const modal = useModal();

  useEffect(() => {
    const removeUserSessionListener = MyHub.listenAppContext('TenantAppLoading', payload => {
      if (payload.closeModal) modal.hideModal();

      setPercentage(payload.loadingPercentage);
    });

    return () => {
      removeUserSessionListener();
    };
  });

  return (
    <View style={[styles.rootContainer, { opacity: opacity ?? 1 }]}>
      <IconSVG svgComponent={AlexIcon} size={{ height: 112, width: 112 }} />
      <View style={{ alignItems: 'center' }}>
        <Text style={styles.title}>{t('common:auth.selectTenantApp.header', { name })}</Text>
        <ProgressBar percentage={percentage} style={styles.progressBar} />
      </View>
    </View>
  );
};
