import React, { useState, useEffect, useContext } from 'react';
import WebModal from 'modal-react-native-web';
import { View, Text, ScrollView, InteractionManager } from 'react-native';
import { ModalHeader } from 'shared/ui-component/Modal/Header';
import {
  ModalBackgroundStyle,
  ModalCardStyle,
  ModalBodyWithAnchorStyle,
  ModalFooterStyle,
  ModalAnchorContainerStyle,
} from 'shared/styles/ModalStyles';
import { t } from 'shared/localisation/i18n';
import styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors, Spacings, Styles } from 'shared/styles';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown';
import { DropDownMultiSelection, DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { getTrainingDurationOptions } from 'shared/ui-component/DropDown/Data/DropDownData';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import { InputText } from 'shared/ui-component/Input';
import * as API from 'shared/backend-data';
import { Tag } from 'shared/ui-component/Input/InputTag';
import { TrainingSessionInputElements } from '../container/LectureTrainingSessionModalContainer';
import { useIsMounted } from 'shared/hooks/IsMounted';
import {
  showTrainingSessionErrorWarning,
  TrainingSessionWarning,
} from 'shared/util/TrainingSessionWarningModal';
import logger from 'shared/util/Logger';
import { ModalUtils } from 'shared/ui-component/Modal';
import { Loader } from 'shared/ui-component/Loader/Loader';
import * as _ from 'lodash-es';
import { TrainingModalButton } from '../../component/index';
import { YellowButton, TextButton } from 'shared/ui-component/Button';
import { WorkersList } from './WorkersList';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { ToBeClosedTrainingsMessage } from 'skillmgtweb/src/components/training/add-edit-practical-traning-session-modal/component/ToBeClosedTrainingsMessage';
import { ToStartTrainingsMessage } from '../../add-edit-practical-traning-session-modal/component/ToStartTrainingsMessage';
import { displayTrainingSessionDuplicateVetoModal } from 'shared/util/TrainingUi';
import { Mode } from 'shared/util-ts/DateUtils';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { TrainingVersionFiles } from 'shared/ui-component/AttachFile/TrainingVersionFiles';
import { ButtonOnPressTypes } from 'shared/util/TrainingSession';
import Hyperlink from 'react-native-hyperlink';
import {
  doesWorkerHavePermissionOnAllParticipants,
  createTrainingRequestForEachTrainee,
} from 'shared/util/TrainingSession';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';
import { AttachFile } from 'shared/ui-component/AttachFile';
import { WorkerDropDownOptionWithDetails } from 'shared/ui-component/DropDown/DropDown';
import {
  checkMentorsPercentageValidy,
  getTrainingSessionErrorType,
} from 'shared/util/TrainingSession';
import { AppContext } from 'shared/context/AppContext';



const UpgradeWorker = require('shared/assets/svg/icon.lecture.svg').default;
const ClockIcon = require('shared/assets/svg/icon.clock.mobile.svg').default;
const WorkerIcon = require('shared/assets/svg/icon.worker.svg').default;
const ICON_SKILL = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const InfoIcon = require('shared/assets/svg/icon.info.svg').default;
const LockIcon = require('shared/assets/svg/icon.lock.closed.svg').default;

interface Props {
  trainingVersion: DropDownOption<API.TrainingVersion> | undefined;
  trainingVersionDropdownOptions: DropDownOption<API.TrainingVersion>[];
  loading: boolean;
  workerDropdownOptions: DropDownOption<API.Worker>[];
  skillTags: Tag[];
  scheduledStartDate?: Date;
  duration: DropDownOption<number> | undefined;
  mentors: WorkerDropDownOptionWithDetails[] | undefined;
  workers: WorkerDropDownOptionWithDetails[];
  location?: string;
  description?: string;
  trainingSession?: API.TrainingSession;
  trainerComment?: string;
  trainerFiles?: API.S3ObjectInput[];
  onFilesChange: (s3File: API.S3ObjectInput[]) => void;
  handleAddProof?: () => void;
  handleModalClose: () => void;
  handleDropdown: (name: string, value?: DropDownOption | DropDownOption[]) => void;
  onChange: (date: Date) => void;
  handleInputText: (name: string, value: string) => Promise<void>;
  trainingDescription?: string;
}

const enum TabIndex {
  Trainee,
  Content,
  Trainer,
}


export const TrainingSessionModalComponent: React.FC<Props> = props => {
  const {
    trainingVersionDropdownOptions,
    loading,
    workerDropdownOptions,
    scheduledStartDate,
    duration,
    mentors,
    skillTags,
    workers,
    location,
    description,
    trainingVersion,
    trainingSession,
    trainingDescription,
    trainerComment,
    trainerFiles,

    handleAddProof,
    handleDropdown,
    onChange,
    handleModalClose,
    handleInputText,
  } = props;

  const [loader, setLoader] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>();
  const [buttonsArray, setButtonsArray] = useState<TrainingModalButton[]>([]);
  const [checkedWorkers, setCheckedWorkers] = useState<WorkerDropDownOptionWithDetails[]>([]);
  const [isEditUnlocked, setIsEditUnlocked] = useState(false);
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.Trainee);
  const [workersToCreateTrainingRequestFor, setWorkersToCreateTrainingRequestFor] = useState<
    string[]
  >([]);
  const [_canWorkerManageTrainingSession, _setCanWorkerManageTrainingSession] =
    useState<boolean>(true);
  const [isLoginWorkerATrainer, setIsLoginWorkerATrainer] = useState<boolean>();
  const [loginWorker, setLoginWorker] = useState<API.Worker>();
  const [hasUserPermissionToShowTrainerTab, setHasUserPermissionToShowTrainerTab] =
    useState<boolean>(false);
  const [workerAddedOrRemovedHimself, setWorkerAddedOrRemovedHimself] = useState<boolean>();

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const { isValidPermission, isUser } = useContext(PermissionManagementContext);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      getLoginWorker();
    });
  }, []);

  useEffect(() => {
    setHasUserPermissionToShowTrainerTab(
      Boolean(mentors?.find(mentor => isUser(mentor?.key))) ||
        isValidPermission(API.Permission.trainingSessions_edit),
    );
  }, [mentors]);

  useEffect(() => {
    handleModalTitleAndButtons();
  }, [trainingSession, isEditUnlocked]);

  useEffect(() => {
    setCheckedWorkers(
      _.compact(_.filter(workers, worker => worker.isChecked || _.isUndefined(worker.isChecked))),
    );
  }, [workers]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !trainingSession) return;
      const workerRemovedOrAddedHimself = await isWorkerAddOrRemovedHimself(
        _.map(workers, worker => worker.key),
      );
      if (!isMounted.current) return;
      if (API.isFailure(workerRemovedOrAddedHimself)) return false;
      setWorkerAddedOrRemovedHimself(workerRemovedOrAddedHimself);
    });
  }, [workers, trainingSession]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !trainingSession) return;

      _setCanWorkerManageTrainingSession(
        await doesWorkerHavePermissionOnAllParticipants(trainingSession.id),
      );

      const _isLoginWorkerATrainer = await API.isLoginWorkerTrainerOnATrainingSession(
        trainingSession.id,
      );
      if (!isMounted.current) return;
      if (API.isFailure(_isLoginWorkerATrainer)) {
        logger.warn(_isLoginWorkerATrainer);
        return;
      }

      setIsLoginWorkerATrainer(_isLoginWorkerATrainer);
    });
  }, [trainingSession]);

  
  useEffect(() => {
    if (trainingSession) return;

    if (
      Boolean(
        workers.length &&
          trainingVersion &&
          mentors &&
          scheduledStartDate &&
          checkMentorsPercentageValidy(mentors),
      )
    )
      setButtonsArray(
        isValidPermission(API.Permission.trainingSessions_edit)
          ? [{ label: '$t(common:time.schedule)', onPressType: ButtonOnPressTypes.CREATE }]
          : [
              {
                label: '$t(common:button.save)',
                onPressType: ButtonOnPressTypes.CREATE,
              },
            ],
      );
    else if (!workers.length && isValidPermission(API.Permission.trainingSessions_edit))
      setButtonsArray([
        { label: '$t(common:button.saveAsADraft)', onPressType: ButtonOnPressTypes.SAVEASDRAFT },
      ]);
    else
      setButtonsArray([
        { label: '$t(common:button.save)', onPressType: ButtonOnPressTypes.CREATE },
      ]);
  }, [workers, trainingVersion, mentors, scheduledStartDate]);

  async function getLoginWorker() {
    const worker = await API.getWorker();
    if (API.isFailure(worker)) {
      logger.warn(worker);
      return worker;
    }
    setLoginWorker(worker);
  }

  function isValidForm(buttonType: string): Boolean {
    if (
      buttonType === ButtonOnPressTypes.CREATE ||
      buttonType === ButtonOnPressTypes.SAVEASDRAFT ||
      buttonType === ButtonOnPressTypes.UPDATE
    ) {
      return Boolean(trainingVersion);
    }
    if (buttonType === ButtonOnPressTypes.SCHEDULE) {
      return Boolean(
        trainingSession &&
          trainingVersion &&
          workers.length &&
          mentors?.length &&
          scheduledStartDate &&
          duration &&
          checkMentorsPercentageValidy(mentors),
      );
    }
    if (buttonType === ButtonOnPressTypes.START) {
      return Boolean(
        trainingSession &&
          trainingVersion &&
          checkedWorkers.length &&
          mentors?.length &&
          scheduledStartDate &&
          duration &&
          checkMentorsPercentageValidy(mentors),
      );
    }
    if (
      buttonType === ButtonOnPressTypes.REJECTREQUEST ||
      buttonType === ButtonOnPressTypes.VALIDATEREQUEST ||
      buttonType === ButtonOnPressTypes.DELETE ||
      buttonType === ButtonOnPressTypes.ADDPROOF ||
      buttonType === ButtonOnPressTypes.UNLOCKEDIT
    ) {
      return true;
    }
    return false;
  }

  async function isWorkerAddOrRemovedHimself(
    updatedSchedluedTraineeIds: string[],
  ): Promise<API.Result<boolean>> {
    if (!trainingSession) return false;

    if (API.getTrainingSessionStage(trainingSession) !== API.TrainingSessionStage.Proposal)
      return false;

    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) return appContext;

    const removedWorkers = _.difference(
      trainingSession.scheduledTraineeIds,
      updatedSchedluedTraineeIds,
    );
    const addedWorkers = _.difference(
      updatedSchedluedTraineeIds,
      trainingSession.scheduledTraineeIds,
    );
    return (
      (removedWorkers.length === 1 &&
        removedWorkers[0] === appContext.workerId &&
        !addedWorkers.length) ||
      (addedWorkers.length === 1 &&
        addedWorkers[0] === appContext.workerId &&
        !removedWorkers.length)
    );
  }

  function isUserAllowedToModifySession(buttonType: string): boolean {
    if (!_canWorkerManageTrainingSession) return false;
    if (
      buttonType === ButtonOnPressTypes.START ||
      buttonType === ButtonOnPressTypes.SCHEDULE ||
      buttonType === ButtonOnPressTypes.UNLOCKEDIT
    ) {
      if (isLoginWorkerATrainer) return true;
    }

    if (buttonType === ButtonOnPressTypes.ADDPROOF && _canWorkerManageTrainingSession) return true;

    if (!isValidPermission(API.Permission.trainingSessions_edit)) {
      if (trainingSession) {
        
        if (
          ((buttonType === ButtonOnPressTypes.DELETE || buttonType === ButtonOnPressTypes.UPDATE) &&
            API.isTrainingSessionRequestValidated(trainingSession) &&
            trainingSession.scheduledTraineeIds.length === 1 &&
            trainingSession.scheduledTraineeIds[0] === loginWorker?.id) ||
          (API.isTrainingSessionDraftOrRequestValidated(trainingSession) &&
            buttonType !== ButtonOnPressTypes.DELETE &&
            (buttonType !== ButtonOnPressTypes.UPDATE ||
              (buttonType === ButtonOnPressTypes.UPDATE && workerAddedOrRemovedHimself)))
        ) {
          return true;
        }
      } else {
        
        if (workers.length === 1 && loginWorker?.id === workers[0].key) {
          return true;
        }
      }

      return false;
    }

    return true;
  }

  async function _createTrainingSession(saveAsADraft?: boolean): Promise<void> {
    setLoader(true);

    const mentorsWithPercentage = [];
    if (mentors) {
      for (const mentor of mentors) {
        if (!API.isGhostWorker(mentor.label))
          mentorsWithPercentage.push({
            trainerId: mentor.key,
            percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
          });
      }
    }

    const trainingSession = await API.createTrainingSession({
      trainingVersionId: trainingVersion!.key,
      scheduledTraineeIds: workers.map(eachTag => eachTag.key),
      traineeIds: [],
      scheduledTrainers: mentorsWithPercentage ? mentorsWithPercentage : [],
      scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
      durationInMin: duration?.value,
      description,
      location,
      requestState: API.ReviewState.VALIDATED,
      isDraft: saveAsADraft,
      trainerComment,
      trainerFiles,
      trainers: [],
    });

    if (!isMounted.current) return;
    if (API.isFailure(trainingSession)) {
      setLoader(false);
      if (API.isFailureType(trainingSession, 'DuplicateVeto'))
        displayTrainingSessionDuplicateVetoModal(trainingSession, API.DataType.WORKER);
      else if (API.isFailureType(trainingSession, 'InvalidArguments')) {
        showTrainingSessionErrorWarning(
          modal,
          TrainingSessionWarning.TrainingWithNoSkillWarning,
          () => {},
          undefined,
          t('common:button.gotIt'),
        );
      } else
        showTrainingSessionErrorWarning(
          modal,
          TrainingSessionWarning.ErrorWarningForLecture,
          undefined,
          undefined,
          t('common:button.ok'),
        );
      logger.warn(trainingSession);
    } else {
      modal.displayModal(
        ModalUtils.toastConfig({
          text: saveAsADraft
            ? t('alex:mobile.trainingScreen.trainingNotPracticalSavedAsADraft')
            : API.isTrainingSessionScheduledForAfterToday(trainingSession) &&
              isValidPermission(API.Permission.trainingSessions_edit)
            ? t('alex:mobile.trainingScreen.trainingNotPracticalScheduled')
            : t('alex:mobile.trainingScreen.trainingCreated'),
          callback: () => {
            handleModalClose();
          },
        }),
      );
    }
    setLoader(false);
  }

  async function _deleteTrainingSession(): Promise<void> {
    if (!trainingSession) return;

    setLoader(true);
    const deletedTraining = await API.deleteFactoryBusinessObject(trainingSession.id);
    if (!isMounted.current) return;
    if (API.isFailure(deletedTraining)) {
      logger.warn(deletedTraining);
      if (API.isFailureType(deletedTraining, 'DeletionVeto')) {
        ModalUtils.showVetoModal(t('alex:mobile.trainingScreen.deletionVeto'), deletedTraining);
      } else {
        showTrainingSessionErrorWarning(
          modal,
          TrainingSessionWarning.DeleteErrorWarningForLecture,
          _deleteTrainingSession,
          undefined,
          t('common:button.yes'),
          t('common:button.no'),
        );
      }
    } else {
      if (!API.isTrainingSessionRequestValidated(trainingSession) || workers.length > 1) {
        await createTrainingRequestForEachTrainee(
          trainingVersion!.key,
          workers.map(worker => {
            return worker.key;
          }),
        );
      }
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:mobile.practicalTrainingScreen.trainingNotPracticalDeleted'),
          callback: () => {
            handleModalClose();
          },
        }),
      );
    }
    setLoader(false);
  }

  function deleteTrainingSession(): void {
    if (!trainingSession) return;
    const warningMessage = getWarningMessage();
    showTrainingSessionErrorWarning(
      modal,
      warningMessage,
      _deleteTrainingSession,
      undefined,
      t('common:button.confirm'),
      t('common:button.cancel'),
    );
  }

  function getWarningMessage(): TrainingSessionWarning {
    if (!trainingSession) return TrainingSessionWarning.DeleteWarningForLecture;

    if (!API.isTrainingSessionDraftOrRequestValidated(trainingSession) || workers.length > 1)
      return TrainingSessionWarning.DeleteWarningForLectureNotToBePlanned;
    else return TrainingSessionWarning.DeleteWarningForLecture;
  }

  async function saveAsADraft(
    trainingSession: API.TrainingSessionPartialUpdateInput,
  ): Promise<API.Result<void>> {
    setLoader(true);
    const draftedTrainingSession = await API.saveTrainingSessionAsADraft(trainingSession);
    if (!isMounted.current) return;
    setLoader(false);
    if (API.isFailure(draftedTrainingSession)) {
      if (API.isFailureType(draftedTrainingSession, 'DuplicateVeto'))
        displayTrainingSessionDuplicateVetoModal(draftedTrainingSession, API.DataType.WORKER);
      return draftedTrainingSession;
    }

    
    if (workersToCreateTrainingRequestFor.length)
      await createTrainingRequestForEachTrainee(
        trainingVersion!.key,
        workersToCreateTrainingRequestFor,
      );

    modal.displayModal(
      ModalUtils.toastConfig({
        text: t('alex:mobile.trainingScreen.trainingNotPracticalSavedAsADraft'),
        callback: () => {
          handleModalClose();
        },
      }),
    );
  }

  async function _scheduleTrainingSession(_trainingSession: API.TrainingSessionPartialUpdateInput) {
    setLoader(true);
    const updateTrainingSession = await API.updateTrainingSession(_trainingSession);
    if (!isMounted.current) return;
    setLoader(false);
    if (API.isFailure(updateTrainingSession)) {
      if (API.isFailureType(updateTrainingSession, 'DuplicateVeto'))
        displayTrainingSessionDuplicateVetoModal(updateTrainingSession, API.DataType.WORKER);
      else if (API.isFailureType(updateTrainingSession, 'InvalidArguments'))
        showTrainingSessionErrorWarning(
          modal,
          TrainingSessionWarning.TrainingWithNoSkillWarning,
          () => {},
          undefined,
          t('common:button.gotIt'),
        );

      return updateTrainingSession;
    }

    
    if (workersToCreateTrainingRequestFor.length)
      await createTrainingRequestForEachTrainee(
        trainingVersion!.key,
        workersToCreateTrainingRequestFor,
      );

    modal.displayModal(
      ModalUtils.toastConfig({
        text: t('alex:mobile.trainingScreen.trainingNotPracticalScheduled'),
        callback: () => {
          handleModalClose();
        },
      }),
    );
  }

  function scheduleTrainingSession(_trainingSession: API.TrainingSessionPartialUpdateInput): void {
    showTrainingSessionErrorWarning(
      modal,
      TrainingSessionWarning.ScheduleWarningForNotPractical,
      () => _scheduleTrainingSession(_trainingSession),
      undefined,
      t('common:button.yes'),
      t('common:button.no'),
    );
  }

  async function _startTrainingSession(
    _trainingSession: API.TrainingSessionUpdateInput,
  ): Promise<API.Result<void>> {
    setLoader(true);
    const updateTrainingSession = await API.startTrainingSession(_trainingSession);
    if (!isMounted.current) return;
    setLoader(false);
    if (API.isFailure(updateTrainingSession)) {
      if (API.isFailureType(updateTrainingSession, 'InvalidArguments'))
        showTrainingSessionErrorWarning(
          modal,
          TrainingSessionWarning.TrainingWithNoSkillWarning,
          () => {},
          undefined,
          t('common:button.gotIt'),
        );
      return updateTrainingSession;
    }

    modal.displayModal(
      ModalUtils.toastConfig({
        text: t('alex:mobile.trainingScreen.trainingNotPracticalStarted'),
        callback: () => {
          handleModalClose();
        },
      }),
    );
  }

  function startTrainingSession(_trainingSession: API.TrainingSessionUpdateInput): void {
    showTrainingSessionErrorWarning(
      modal,
      TrainingSessionWarning.StartWarningForNotPractical,
      () => _startTrainingSession(_trainingSession),
      undefined,
      t('common:button.yes'),
      t('common:button.no'),
    );
  }

  async function handleModalTitleAndButtons(): Promise<API.Result<void>> {
    if (!trainingSession) {
      setIsInputDisabled(false);
      setModalTitle(t('alex:scheduleTrainingModal.notPracticalTrainingheaderTitle.1'));
      setButtonsArray([
        {
          label: '$t(common:button.save)',
          onPressType: ButtonOnPressTypes.CREATE,
        },
      ]);
      return;
    }

    const _trainingSession = await API.getTrainingSession(trainingSession.id);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingSession)) return _trainingSession;

    if (API.isTrainingSessionToReview(_trainingSession)) {
      setIsInputDisabled(true);
      setModalTitle(t('alex:scheduleTrainingModal.notPracticalTrainingheaderTitle.1'));
      setButtonsArray([
        { label: '$t(common:button.reject)', onPressType: ButtonOnPressTypes.REJECTREQUEST },
        { label: '$t(common:button.validate)', onPressType: ButtonOnPressTypes.VALIDATEREQUEST },
      ]);
    } else if (API.isTrainingSessionDraftOrRequestValidated(_trainingSession)) {
      setIsInputDisabled(false);
      setModalTitle(t('alex:scheduleTrainingModal.notPracticalTrainingheaderTitle.2'));
      setButtonsArray(
        isValidPermission(API.Permission.trainingSessions_edit)
          ? [
              { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
              {
                label: '$t(common:button.saveAsADraft)',
                onPressType: ButtonOnPressTypes.SAVEASDRAFT,
              },
              { label: '$t(common:time.schedule)', onPressType: ButtonOnPressTypes.SCHEDULE },
            ]
          : [
              { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
              {
                label: '$t(common:button.save)',
                onPressType: ButtonOnPressTypes.UPDATE,
              },
            ],
      );
    } else if (API.isTrainingSessionScheduledOrLateStart(_trainingSession)) {
      setIsInputDisabled(true);
      setModalTitle(
        !isEditUnlocked
          ? t('alex:scheduleTrainingModal.notPracticalTrainingheaderTitle.3')
          : t('alex:scheduleTrainingModal.notPracticalTrainingheaderTitle.2'),
      );
      if (!isEditUnlocked) {
        setButtonsArray([
          { label: '$t(common:button.edit)', onPressType: ButtonOnPressTypes.UNLOCKEDIT },
          {
            label: '$t(alex:TrainingModal.startTheTraining)',
            onPressType: ButtonOnPressTypes.START,
          },
        ]);
      } else {
        setButtonsArray([
          { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
          { label: '$t(common:button.saveAsADraft)', onPressType: ButtonOnPressTypes.SAVEASDRAFT },
          { label: '$t(common:time.schedule)', onPressType: ButtonOnPressTypes.SCHEDULE },
        ]);
      }
    } else if (API.isTrainingSessionStartedOrStartedLate(_trainingSession)) {
      setIsInputDisabled(true);
      setModalTitle(t('alex:scheduleTrainingModal.notPracticalTrainingheaderTitle.4'));
      setButtonsArray(
        isValidPermission(API.Permission.trainingSessions_edit)
          ? [
              { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },
              {
                label: '$t(alex:scheduleTrainingModal.generateProof)',
                onPressType: ButtonOnPressTypes.ADDPROOF,
              },
            ]
          : [
              {
                label: '$t(alex:scheduleTrainingModal.generateProof)',
                onPressType: ButtonOnPressTypes.ADDPROOF,
              },
            ],
      );
    } else if (API.isTrainingSessionLateEnd(_trainingSession)) {
      setIsInputDisabled(true);
      setModalTitle(t('alex:scheduleTrainingModal.notPracticalTrainingheaderTitle.5'));
      setButtonsArray(
        isValidPermission(API.Permission.trainingSessions_edit)
          ? [
              { label: '$t(common:button.delete)', onPressType: ButtonOnPressTypes.DELETE },

              {
                label: '$t(alex:scheduleTrainingModal.generateProof)',
                onPressType: ButtonOnPressTypes.ADDPROOF,
              },
            ]
          : [
              {
                label: '$t(alex:scheduleTrainingModal.generateProof)',
                onPressType: ButtonOnPressTypes.ADDPROOF,
              },
            ],
      );
    }
  }

  async function handleButtonPress(onPressType: string): Promise<API.Result<void>> {
    if (!isValidForm(onPressType)) {
      console.log(onPressType);
      showTrainingSessionErrorWarning(
        modal,
        getTrainingSessionErrorType(onPressType),
        () => {},
        undefined,
        t('common:button.gotIt'),
      );
      return;
    }

    
    if (onPressType === ButtonOnPressTypes.CREATE) {
      _createTrainingSession();
    }
    
    else if (onPressType === ButtonOnPressTypes.REJECTREQUEST) {
      if (!trainingSession) return;
      setLoader(true);
      const rejectedTrainingSession = await API.rejectTrainingSession(trainingSession.id);
      if (!isMounted.current) return;
      if (API.isFailure(rejectedTrainingSession)) return rejectedTrainingSession;
      setLoader(false);
      handleModalClose();
    }
    
    else if (onPressType === ButtonOnPressTypes.VALIDATEREQUEST) {
      if (!trainingSession) return;
      setLoader(true);
      const validatedTrainingSession = await API.validateTrainingSession(trainingSession.id);
      if (!isMounted.current) return;
      if (API.isFailure(validatedTrainingSession)) return validatedTrainingSession;
      setLoader(false);
      handleModalClose();
    }
    
    else if (onPressType === ButtonOnPressTypes.SAVEASDRAFT) {
      if (!trainingSession) {
        _createTrainingSession(true);
        return;
      }

      saveAsADraft({
        id: trainingSession.id,
        scheduledTraineeIds: _.map(workers, worker => worker.key),
        scheduledTrainers: mentors
          ? mentors.map(mentor => {
              return {
                trainerId: mentor.key,
                percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
              };
            })
          : [],
        scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
        durationInMin: duration?.value,
        description: description,
        location: location,
      });
    }
    
    else if (onPressType === ButtonOnPressTypes.DELETE) {
      if (!trainingSession) return;

      deleteTrainingSession();
    }
    
    else if (onPressType === ButtonOnPressTypes.SCHEDULE) {
      if (
        !trainingSession ||
        !trainingVersion ||
        !workers.length ||
        !mentors ||
        !scheduledStartDate ||
        !duration
      )
        return;
      scheduleTrainingSession({
        id: trainingSession.id,
        scheduledTraineeIds: _.map(workers, worker => worker.key),
        scheduledTrainers: mentors.map(mentor => {
          return {
            trainerId: mentor.key,
            percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
          };
        }),
        scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
        durationInMin: duration.value,
        description: description,
        location: location,
        trainerComment,
        trainerFiles,
        isDraft: false,
      });
    }
    
    else if (onPressType === ButtonOnPressTypes.START) {
      if (!trainingSession) return;

      startTrainingSession({
        ...API.deepClone(trainingSession),
        scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
        scheduledTraineeIds: _.map(workers, worker => worker.key),
        traineeIds: _.map(checkedWorkers, worker => worker.key),
        scheduledTrainers: mentors
          ? mentors.map(mentor => {
              return {
                trainerId: mentor.key,
                percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
              };
            })
          : [],
        trainers: mentors
          ? mentors.map(mentor => {
              return {
                trainerId: mentor.key,
                percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
              };
            })
          : [],
        durationInMin: duration?.value,
        description: description,
        location: location,
      });
    }
    
    else if (onPressType === ButtonOnPressTypes.ADDPROOF) {
      if (handleAddProof) handleAddProof();
    }
    
    else if (
      onPressType === ButtonOnPressTypes.UNLOCKEDIT ||
      onPressType === ButtonOnPressTypes.UPDATE
    ) {
      if (isEditUnlocked || onPressType === ButtonOnPressTypes.UPDATE) {
        if (!trainingSession || !workers.length || !trainingVersion) return;

        setLoader(true);
        const updateTrainingSession = await API.updateTrainingSession({
          id: trainingSession.id,
          scheduledStartDate: scheduledStartDate ? scheduledStartDate.toISOString() : undefined,
          scheduledTraineeIds: _.map(workers, worker => worker.key),
          scheduledTrainers: mentors
            ? mentors.map(mentor => {
                return {
                  trainerId: mentor.key,
                  percentage: mentor.trainingSessionCoveragePercentage ?? '0%',
                };
              })
            : undefined,
          durationInMin: duration?.value,
          description: description,
          location: location,
          trainerComment,
          trainerFiles,
        });
        if (!isMounted.current) return;
        if (API.isFailure(updateTrainingSession)) {
          if (API.isFailureType(updateTrainingSession, 'DuplicateVeto'))
            displayTrainingSessionDuplicateVetoModal(updateTrainingSession, API.DataType.WORKER);
          setLoader(false);
          return updateTrainingSession;
        }
        setLoader(false);
        
        if (workersToCreateTrainingRequestFor.length)
          await createTrainingRequestForEachTrainee(
            trainingVersion.key,
            workersToCreateTrainingRequestFor,
          );
        handleModalClose();
      } else {
        setIsEditUnlocked(true);
      }
    }
  }

  function renderButtons(): JSX.Element[] | JSX.Element | null {
    if (!buttonsArray) return null;

    return (
      <>
        {_.map(buttonsArray, (button, index) => {
          if (index !== buttonsArray.length - 1) {
            return (
              <View style={styles.textButtonContainer} key={button.label}>
                <TextButton
                  text={t(button.label)}
                  disabled={
                    !isValidForm(button.onPressType) ||
                    !isUserAllowedToModifySession(button.onPressType)
                  }
                  onPress={() => {
                    handleButtonPress(button.onPressType);
                  }}
                />
                <View style={styles.textButtonDivider} />
              </View>
            );
          } else {
            return (
              <YellowButton
                key={button.label}
                text={t(button.label)}
                disabled={!isUserAllowedToModifySession(button.onPressType)}
                style={styles.bottomButtonStyle}
                textStyle={styles.bottomButtonTextStyle}
                onPress={() => {
                  handleButtonPress(button.onPressType);
                }}
              />
            );
          }
        })}
        {!_canWorkerManageTrainingSession && (
          <View style={styles.toolTipContainer}>
            <ToolTipWeb
              title={t('alex:scheduleTrainingModal.workerCantManageTrainingSession')}
              component={<IconSVG svgComponent={InfoIcon} color={Colors.GreyLight} />}
              style={styles.toolTip}
            />
          </View>
        )}
      </>
    );
  }

  function allowNewWorkersAddition(): Boolean | undefined {
    if (!trainingSession) return;

    return Boolean(
      !API.isTrainingSessionStartedOrStartedLateOrLateEnd(trainingSession) &&
        (!API.isTrainingSessionScheduledOrLateStart(trainingSession) ||
          (API.isTrainingSessionScheduledOrLateStart(trainingSession) && isEditUnlocked)) &&
        !API.isTrainingSessionToReview(trainingSession) &&
        (isValidPermission(API.Permission.trainingSessions_edit) ||
          API.isTrainingSessionDraft(trainingSession)),
    );
  }

  const tabs: Tab[] = [
    {
      
      title: {
        title: t('alex:scheduleTrainingModal.participants'),
      },
    },
    {
      
      title: {
        title: t('common:content'),
      },
    },
    {
      
      title: {
        title: t('alex:scheduleTrainingModal.trainerTab'),
        warningIcon: true,
        
      },
    },
  ];

  return (
    <WebModal
      animationType="fade"
      transparent
      visible={true}
      onRequestClose={() => handleModalClose()}
    >
      <View style={ModalBackgroundStyle}>
        <View style={[ModalCardStyle, styles.modal]}>
          <ModalHeader handleModalClose={() => handleModalClose()} title={modalTitle ?? ''} />

          <View style={[ModalBodyWithAnchorStyle, styles.body]}>
            {/** LEFT PANEL */}
            <View style={[styles.leftPanelContainer]}>
              <View style={[ModalAnchorContainerStyle, styles.iconContainer]}>
                <IconSVG svgComponent={UpgradeWorker} color={Colors.White} />
              </View>
              <View style={[styles.leftPanelInputContainer]}>
                {/** Training */}
                <DropDownSingleSelection
                  title={t('glossary:training')}
                  placeholder={t('alex:scheduleTrainingModal.selectTraining')}
                  searchPlaceholder={t('alex:scheduleTrainingModal.selectTraining')}
                  hasSearch
                  listIcon={UpgradeWorker}
                  options={trainingVersionDropdownOptions}
                  value={trainingVersion}
                  notEditable={loading || !!trainingSession || isInputDisabled}
                  containerStyle={[Styles.fullWidth, { zIndex: 9 }]}
                  handleChange={(_, value) => handleDropdown(API.DataType.TRAINING, value)}
                />
                {(isValidPermission(API.Permission.trainingSessions_edit) || trainingSession) && (
                  <View style={{ zIndex: 8 }}>
                    {/** Date */}
                    <View style={[styles.dateTimeContainer, { zIndex: 2 }]}>
                      <View>
                        <InputDateWeb
                          style={styles.minWidth}
                          inputName={TrainingSessionInputElements.date}
                          inputPlaceholder={t('common:time.date')}
                          hasDateIcon
                          mode={Mode.Date}
                          notEditable={
                            (isInputDisabled && !isEditUnlocked) ||
                            (!isValidPermission(API.Permission.trainingSessions_edit)
                              ? !isLoginWorkerATrainer && isEditUnlocked
                              : !isValidPermission(API.Permission.trainingSessions_edit))
                          }
                          value={scheduledStartDate}
                          onChange={(_inputName, date) => onChange(date)}
                        />
                      </View>
                      <View>
                        <InputDateWeb
                          style={styles.minWidth}
                          inputName={TrainingSessionInputElements.time}
                          inputPlaceholder={t('common:time.clockTime')}
                          hasTimeIcon
                          mode={Mode.Time}
                          notEditable={
                            (isInputDisabled && !isEditUnlocked) ||
                            (!isValidPermission(API.Permission.trainingSessions_edit)
                              ? !isLoginWorkerATrainer && isEditUnlocked
                              : !isValidPermission(API.Permission.trainingSessions_edit))
                          }
                          value={scheduledStartDate}
                          onChange={(_inputName, time) => onChange(time)}
                        />
                      </View>
                    </View>

                    {/** Duration */}
                    <DropDownSingleSelection
                      title={t('alex:mobile.trainingScreen.duration')}
                      placeholder={t('alex:mobile.trainingScreen.duration')}
                      containerStyle={[styles.minWidth, { zIndex: 1 }]}
                      options={getTrainingDurationOptions()}
                      icon={ClockIcon}
                      value={duration}
                      notEditable={
                        (isInputDisabled && !isEditUnlocked) ||
                        (!isValidPermission(API.Permission.trainingSessions_edit)
                          ? !isLoginWorkerATrainer && isEditUnlocked
                          : !isValidPermission(API.Permission.trainingSessions_edit))
                      }
                      handleChange={(_, value) =>
                        handleDropdown(TrainingSessionInputElements.duration, value)
                      }
                    />
                  </View>
                )}
                {/** Location */}
                {isInputDisabled && !isEditUnlocked && !location ? (
                  <></>
                ) : (
                  <InputTextDescription
                    inputId={TrainingSessionInputElements.location}
                    containerStyle={[styles.descriptionContainer, { zIndex: 7 }]}
                    placeHolder={t('alex:scheduleTrainingModal.placeAndNotes')}
                    defaultValue={location}
                    onTextChange={(inputId: string, inputValue: string) => {
                      handleInputText(inputId, inputValue);
                    }}
                    notEditable={
                      (trainingSession &&
                        !API.isTrainingSessionRequestValidated(trainingSession) &&
                        isInputDisabled &&
                        !isEditUnlocked) ||
                      (!isValidPermission(API.Permission.trainingSessions_edit)
                        ? !isLoginWorkerATrainer && isEditUnlocked
                        : !isValidPermission(API.Permission.trainingSessions_edit))
                    }
                  />
                )}
              </View>
            </View>

            {/** RIGHT PANEL */}
            <View style={[styles.rightPanelContainer]}>
              <AnimatedTab
                currentTabIndex={tabIndex}
                tabs={
                  hasUserPermissionToShowTrainerTab ? tabs : tabs.filter((_tab, index) => index < 2)
                }
                onTabPress={_tabIndex => {
                  setTabIndex(_tabIndex);
                }}
                isMobileStyle
              />
              <View style={styles.verticalDivider} />

              {tabIndex === TabIndex.Trainee && (
                <View
                  style={[
                    styles.tabContentContainer,
                    { flexDirection: 'row', justifyContent: 'space-between' },
                  ]}
                >
                  <View style={{ width: '47%' }}>
                    {/** Mentor */}
                    <Text style={styles.participantsHeader}>{t('glossary:trainer_plural')}</Text>
                    {!trainingSession || allowNewWorkersAddition() ? (
                      <DropDownMultiSelection
                        containerStyle={[styles.addWorker, { zIndex: 1 }]}
                        title={t('glossary:trainer')}
                        placeholder={t('alex:mobile.trainingScreen.addTrainer')}
                        searchPlaceholder={t('alex:mobile.trainingScreen.addTrainer')}
                        notEditable={
                          (isInputDisabled && !isEditUnlocked) ||
                          (!isValidPermission(API.Permission.trainingSessions_edit)
                            ? !isLoginWorkerATrainer && isEditUnlocked
                            : !isValidPermission(API.Permission.trainingSessions_edit))
                        }
                        inputListContainerStyle={styles.addWorkerInput}
                        listIcon={WorkerIcon}
                        options={workerDropdownOptions}
                        values={mentors}
                        additionForm
                        showAdditionFormList={false}
                        handleChange={(_, value) =>
                          handleDropdown(TrainingSessionInputElements.mentor, value)
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <View style={styles.verticalDivider} />
                    <WorkersList
                      scheduledWorkers={mentors ?? []}
                      traineesIds={trainingSession?.traineeIds ?? []}
                      trainingSession={trainingSession}
                      isEditUnlocked={
                        (isInputDisabled && !isEditUnlocked) ||
                        !isValidPermission(API.Permission.trainingSessions_edit)
                          ? false
                          : true
                      }
                      handleWorkers={oldMentors =>
                        handleDropdown(TrainingSessionInputElements.mentor, oldMentors)
                      }
                      canWorkerMangeTrainingSession={
                        (!isValidPermission(API.Permission.trainingSessions_edit)
                          ? isLoginWorkerATrainer
                          : !isValidPermission(API.Permission.trainingSessions_edit)) ||
                        _canWorkerManageTrainingSession
                      }
                      isMentorList
                    />
                  </View>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.participantsHeader}>{t('glossary:worker_plural')}</Text>
                    {!trainingSession || allowNewWorkersAddition() ? (
                      <DropDownMultiSelection
                        containerStyle={[styles.addWorker, { zIndex: 1 }]}
                        inputListContainerStyle={styles.addWorkerInput}
                        options={workerDropdownOptions}
                        placeholder={t('alex:scheduleTrainingModal.addWorker')}
                        searchPlaceholder={t('alex:scheduleTrainingModal.addWorker')}
                        values={workers}
                        additionForm
                        showAdditionFormList={false}
                        handleChange={(_, value) =>
                          handleDropdown(TrainingSessionInputElements.trainees, value)
                        }
                      />
                    ) : API.isTrainingSessionStartedOrStartedLateOrLateEnd(trainingSession!) ? (
                      <>
                        <View style={styles.divider2} />
                        <ToBeClosedTrainingsMessage isPractical={false} />
                      </>
                    ) : trainingSession &&
                      API.isTrainingSessionScheduledOrLateStart(trainingSession!) ? (
                      <>
                        <View style={styles.divider2} />
                        <ToStartTrainingsMessage />
                      </>
                    ) : (
                      <></>
                    )}
                    <View style={styles.verticalDivider} />
                    <WorkersList
                      scheduledWorkers={workers}
                      traineesIds={trainingSession?.traineeIds ?? []}
                      trainingSession={trainingSession}
                      isEditUnlocked={
                        isEditUnlocked &&
                        (isValidPermission(API.Permission.trainingSessions_edit) ||
                          Boolean(trainingSession?.isDraft))
                      }
                      handleWorkers={oldWorkers =>
                        handleDropdown(TrainingSessionInputElements.trainees, oldWorkers)
                      }
                      createTrainingRequestForTheWorker={workerId =>
                        setWorkersToCreateTrainingRequestFor([
                          ...workersToCreateTrainingRequestFor,
                          workerId,
                        ])
                      }
                      canWorkerMangeTrainingSession={
                        (!isValidPermission(API.Permission.trainingSessions_edit)
                          ? isLoginWorkerATrainer
                          : !isValidPermission(API.Permission.trainingSessions_edit)) ||
                        _canWorkerManageTrainingSession
                      }
                    />
                  </View>
                </View>
              )}
              {tabIndex === TabIndex.Content && trainingVersion && (
                <View style={styles.tabContentContainer}>
                  <Text style={styles.contentOfTraining}>
                    {t('alex:scheduleTrainingModal.contentOfTraining')}
                  </Text>
                  <Text style={styles.descriptionTitle}>{t('alex:skills.description')}</Text>
                  <Hyperlink linkDefault={true} linkStyle={styles.linkStyle}>
                    <Text style={styles.description}>{trainingDescription}</Text>
                  </Hyperlink>
                  <TrainingVersionFiles trainingVersionId={trainingVersion.key} />

                  {/** Skills */}
                  <Text style={[styles.skillsListHeaderTextStyle]}>
                    {t('alex:trainingModal.trainingAcquisitionNonPractical')}
                  </Text>
                  <ScrollView style={styles.skillListingContainer}>
                    {skillTags.map(skillTag => {
                      return (
                        <View key={skillTag.key} style={[styles.skillListingRowContainer]}>
                          <View style={[styles.skillNameContainer]}>
                            <IconSVG
                              svgComponent={ICON_SKILL}
                              size={{ width: Spacings.Standard, height: Spacings.Standard }}
                              color={Colors.White}
                              containerStyle={styles.skillIconContainer}
                            />
                            <Text style={[styles.skillNameTextStyle]}>{skillTag.label}</Text>
                          </View>
                        </View>
                      );
                    })}
                  </ScrollView>
                </View>
              )}
              {tabIndex === TabIndex.Trainer && (
                <View style={styles.tabContentContainer}>
                  <Text style={styles.contentOfTraining}>
                    <IconSVG
                      svgComponent={LockIcon}
                      size={{ width: Spacings.Standard, height: Spacings.Standard }}
                      containerStyle={{ paddingRight: Spacings.Small }}
                    />
                    {t('alex:scheduleTrainingModal.trainerTabDescription')}
                  </Text>

                  <Text style={styles.descriptionTitle}>{t('common:chat.message_plural')}</Text>
                  <ScrollView style={{ flexGrow: 0 }}>
                    <Hyperlink linkDefault={true} linkStyle={styles.linkStyle}>
                      <Text style={styles.description}>{trainerComment}</Text>
                    </Hyperlink>
                  </ScrollView>
                  <InputText
                    label={t('common:chat.addMessage')}
                    showSaveButton
                    placeHolder={t('common:chat.addMessage')}
                    onSaveButtonClicked={value =>
                      handleInputText(TrainingSessionInputElements.trainerComment, value)
                    }
                    multiline
                  ></InputText>

                  <AttachFile
                    style={{ marginTop: Spacings.Standard }}
                    initialFiles={trainerFiles}
                    onFilesChange={props.onFilesChange}
                  />
                </View>
              )}
            </View>
          </View>
          {/** BOTTOM PANEL */}
          <View style={[ModalFooterStyle]}>
            <View style={[styles.workstationModalBottomButtonContainer]}>
              {buttonsArray && renderButtons()}
            </View>
          </View>
        </View>
      </View>
      {loader && <Loader />}
    </WebModal>
  );
};
