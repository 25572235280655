export * from './Workstation';
import * as workstationUtils from './Workstation';
import { LoaderTrigger } from 'skillmgtweb/src/components/my-factory/menuFactory/workstation-tree-panel/WorkstationTreePanel';
import { ModalContext } from 'shared/ui-component/Modal/Modal';
import * as API from 'shared/backend-data';
import { TreeNode, WorkstationWorkerLevels, logger } from 'shared/backend-data';
import { Colors } from '../styles';
import { WorkstationTargetActual } from './Workstation';
import Aigle from 'aigle';
import * as _ from 'lodash-es';
import { i18n, capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { CopyWorkstationOrOrgUnit } from './Workstation';
import { copyWorkstationOrOrganizationalUnit, copyShifts } from './Workstation';
import { ModalUtils } from 'shared/ui-component/Modal';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import { userPermissionsChangeDebounceInMs } from 'shared/backend-data/factoryCache/QueryCache';

export const UnitIcon = require('shared/assets/svg/icon.unit.small.svg').default;
export const WorkstationIcon = require('shared/assets/svg/icon.workstation.svg').default;

export function getTreeNodeTag(
  treeNode: TreeNode,
  disabledTreeNodeIds?: string[],
  onlyWorkstations?: boolean,
  onlyOrgUnits?: boolean,
): Tag<TreeNode> {
  let icon;

  if (treeNode.factory.dataType === API.DataType.ORGUNIT) {
    icon = UnitIcon;
  } else if (treeNode.factory.dataType === API.DataType.WORKSTATION) {
    icon = WorkstationIcon;
  }

  return {
    ...treeNode,
    key: treeNode.id,
    label: treeNode.object.name,
    value: treeNode,
    tagIcon: icon,
    children: _.map(treeNode.children, child => {
      return getTreeNodeTag(child);
    }),
    disabled:
      (onlyWorkstations && treeNode.factory.dataType === API.DataType.ORGUNIT) ||
      (onlyOrgUnits && treeNode.factory.dataType === API.DataType.WORKSTATION) ||
      (!!disabledTreeNodeIds?.length && _.includes(disabledTreeNodeIds, treeNode.object.id)),
  };
}

export function getWorkstationTargetActualColor(
  workstationTargetActual: API.WorkstationTargetActual,
  level: WorkstationWorkerLevels,
  useGreenInsteadOfBlack: boolean = false,
  actualTargetInPercentage?: number,
): string {
  switch (level) {
    case WorkstationWorkerLevels.LEVEL2:
      if (
        (actualTargetInPercentage ?? workstationTargetActual.workersWithLevel2AtLeastActual) <
        (workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker ?? 0)
      ) {
        return Colors.Red;
      }
      if (
        workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker &&
        (actualTargetInPercentage ?? workstationTargetActual.workersWithLevel2AtLeastActual) >
          workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker
      ) {
        return Colors.Orange;
      }
      break;

    case WorkstationWorkerLevels.LEVEL3:
      if (
        (actualTargetInPercentage ?? workstationTargetActual.workersWithLevel3AtLeastActual) <
        (workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker ?? 0)
      ) {
        return Colors.Red;
      }
      if (
        workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker &&
        (actualTargetInPercentage ?? workstationTargetActual.workersWithLevel3AtLeastActual) >
          workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker
      ) {
        return Colors.Orange;
      }
      break;

    case WorkstationWorkerLevels.LEVEL4:
      if (
        (actualTargetInPercentage ?? workstationTargetActual.workersWithLevel4Actual) <
        (workstationTargetActual.workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0)
      ) {
        return Colors.Red;
      }
      if (
        workstationTargetActual.workersWithLevel4WorkstationTarget.idealNumberOfWorker &&
        (actualTargetInPercentage ?? workstationTargetActual.workersWithLevel4Actual) >
          workstationTargetActual.workersWithLevel4WorkstationTarget.idealNumberOfWorker
      ) {
        return Colors.Orange;
      }
      break;

    default:
  }

  return useGreenInsteadOfBlack ? Colors.Green : Colors.Black;
}

export async function deleteWorkstation(
  workstationId: string,
  modal: ModalContext,
  deleteDependencies?: boolean,
  deletionCallback?: () => void,
) {
  const _deleteWorkstation = await API.deleteFactoryBusinessObject(
    workstationId,
    undefined,
    undefined,
    false,
    deleteDependencies,
  );
  if (API.isFailure(_deleteWorkstation)) {
    if (API.isFailureType(_deleteWorkstation, 'DeletionVeto')) {
      ModalUtils.showVetoModal(
        i18n.t('alex:workstationTable.warnings.failedToDeleteWorkstationRemoveDependencies'),
        _deleteWorkstation,
        async (forceDeletion?: boolean) => {
          if (forceDeletion) await deleteWorkstation(workstationId, modal, forceDeletion);
          else modal.hideModal();
        },
      );
    } else {
      ModalUtils.showWarningFailure(modal, _deleteWorkstation);
    }
    return false;
  }

  modal.displayModal(
    ModalUtils.toastConfig({
      text: capitalizeFirstLetter(i18n.t('alex:workstationTable.warnings.workstationDeleted')),
      callback: () => (deletionCallback ? deletionCallback() : {}),
    }),
  );
}

export function deleteWorkstationWarning(
  modal: ModalContext,
  deleteWarningCallback: () => void,
  name: string,
): void {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: capitalizeFirstLetter(
        t('alex:workstationTable.warnings.deleteWorkstationWarning', { name }),
      ),
      warningAcceptCallback: deleteWarningCallback,
      warningAcceptButton: t('common:button.yes'),
      warningCancelButton: t('common:button.no'),
    }),
  );
}

export function deleteOrgUnitWarning(
  modal: ModalContext,
  deleteWarningCallback: () => void,
  name: string,
): void {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: capitalizeFirstLetter(t('alex:workstations.addMenu.deleteOrgUnit', { name })),
      warningAcceptCallback: deleteWarningCallback,
      warningAcceptButton: t('common:button.yes'),
      warningCancelButton: t('common:button.no'),
    }),
  );
}

export function duplicateWorkstation(modal: ModalContext, deleteWarningCallback: () => void) {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: capitalizeFirstLetter(t('alex:workstations.addMenu.duplicateWorkstation')),
      warningAcceptCallback: deleteWarningCallback,
      warningAcceptButton: t('common:button.yes'),
      warningCancelButton: t('common:button.no'),
    }),
  );
}

export function duplicateOrgUnit(modal: ModalContext, deleteWarningCallback: () => void) {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: capitalizeFirstLetter(t('alex:workstations.addMenu.duplicateOrgUnit')),
      warningAcceptCallback: deleteWarningCallback,
      warningAcceptButton: t('common:button.yes'),
      warningCancelButton: t('common:button.no'),
    }),
  );
}

export async function copyRequirements(
  modal: ModalContext,
  data: CopyWorkstationOrOrgUnit[],
): Promise<API.Result<CopyWorkstationOrOrgUnit[]>> {
  const failedObjects: CopyWorkstationOrOrgUnit[] = [];

  
  const timeout = userPermissionsChangeDebounceInMs + 3000;

  setTimeout(async () => {
    await Aigle.map(data, async eachData => {
      const levelsRequirements = await API.getLevelsRequirement(eachData.workstationOrOrgUnit.id);
      if (API.isFailure(levelsRequirements)) {
        return levelsRequirements;
      }

      await Aigle.map(Array.from(levelsRequirements), async ([, requirement]) => {
        const newRequirementInput: API.RequirementCreateInput = API.deepClone(requirement);
        delete newRequirementInput.id;
        delete newRequirementInput.practicalTrainingId;
        const newSkillTrainingVersionsArray = _.compact(
          await Aigle.map(newRequirementInput.skillTrainingVersions, async skillTrainingVersion => {
            const _skill = await API.getSkill(skillTrainingVersion.skillId);
            if (API.isFailure(_skill)) {
              logger.warn('Failed to fetch skill ', _skill);
              return;
            }
            if (_skill.isPractical) return { ...skillTrainingVersion, trainingVersionId: null };
            return skillTrainingVersion;
          }),
        );
        newRequirementInput.linkedObjectId = eachData.copiedWorkstationOrOrgUnit.id;
        newRequirementInput.skillTrainingVersions = newSkillTrainingVersionsArray;

        const newRequirement = await API.createRequirement(newRequirementInput);
        if (API.isFailure(newRequirement)) {
          if (API.isFailureType(newRequirement, 'Unauthorized')) {
            failedObjects.push(eachData);
          } else {
            return newRequirement;
          }
        }
      });
    });

    if (failedObjects.length) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: i18n.t('alex:unitModal.copyRequirementWarning'),
          warningAcceptCallback: async () => {
            modal.hideModal();
            await copyRequirements(modal, failedObjects); 
          },
          warningAcceptButton: i18n.t('common:button.retry'),
          warningCancelButton: i18n.t('common:button.no'),
        }),
      );
    }
  }, timeout);

  return failedObjects;
}

export async function copyWorkstationOrOrganizationalUnitAndRequirements(
  modal: ModalContext,
  workstationOrOrganizationalUnit: API.TreeObject,
  parentId?: string,
): Promise<API.Result<API.TreeObject | undefined>> {
  const copiedWorkstationOrOrganizationalUnit = await copyWorkstationOrOrganizationalUnit(
    workstationOrOrganizationalUnit,
    parentId,
  );
  if (API.isFailure(copiedWorkstationOrOrganizationalUnit))
    return copiedWorkstationOrOrganizationalUnit;

  const copiedShifts = await copyShifts(copiedWorkstationOrOrganizationalUnit);
  if (API.isFailure(copiedShifts)) {
    return copiedShifts;
  }

  const copiedRequired = await copyRequirements(modal, copiedWorkstationOrOrganizationalUnit);
  if (API.isFailure(copiedRequired)) {
    return copiedRequired;
  }

  return;
}

/**
 * Try to delete the specified OrganizationalUnit.
 * Returns false in case the deletion could not proceed.
 */

export async function deleteOrganizationalUnitWithErrorModalHandling(
  orgUnitId: string,
  modal: ModalContext,
  deleteDependencies?: boolean,
  handleLoader?: (isLoading: boolean, loadingTrigger?: LoaderTrigger) => void,
): Promise<boolean> {
  if (handleLoader) handleLoader(true);
  const result = await API.deleteFactoryBusinessObject<API.DataType.ORGUNIT>(
    orgUnitId,
    undefined,
    undefined,
    false,
    deleteDependencies,
  );
  modal.hideModal(); 
  if (API.isFailure(result)) {
    if (API.isFailureType(result, 'DeletionVeto')) {
      let failureMessage: string;
      if (result.message === API.DataType.WORKER) {
        failureMessage = i18n.t('alex:workstationTable.warnings.workersAssignedToOrgUnit');
      } else if (result.message === API.DataType.WORKSTATION) {
        failureMessage = i18n.t('alex:workstationTable.warnings.orgUnitWithWorkstationChildren');
      } else if (result.message === API.DataType.ORGUNIT) {
        failureMessage = i18n.t('alex:workstationTable.warnings.orgUnitWithOrgUnitChildren');
      } else if (
        result.message === API.DataType.REQUIREMENT ||
        result.message === API.DataType.SHIFT
      ) {
        failureMessage = i18n.t(
          'alex:workstationTable.warnings.failedToDeleteOrgUnitRemoveDependencies',
        );
      } else {
        failureMessage = result.message;
      }

      ModalUtils.showVetoModal(failureMessage, result, async (forceDeletion?: boolean) => {
        if (forceDeletion)
          await deleteOrganizationalUnitWithErrorModalHandling(
            orgUnitId,
            modal,
            forceDeletion,
            handleLoader,
          );
        else modal.hideModal();
      });
    } else {
      ModalUtils.showWarningFailure(modal, result);
    }

    if (handleLoader) handleLoader(false);
    return false;
  }

  modal.displayModal(
    ModalUtils.toastConfig({
      text: capitalizeFirstLetter(i18n.t('alex:workstationTable.warnings.orgUnitDeleted')),
    }),
  );

  if (handleLoader) handleLoader(false);
  return true;
}
