import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Spacings, createRoundedStyle, Colors, TextFontStyles, fontSize } from 'shared/styles';

interface RadioButtonStyle {
  item: ViewStyle;
  radioButton: ViewStyle;
  radioButtonContainer: ViewStyle;
  radioButtonSelected: ViewStyle;
  boldTextStyle: TextStyle;
  descriptionTextStyle: TextStyle;
  divider: ViewStyle;
}

const generateStyles = (
  disabled = false,
  roundedWith: number,
  radioButtonColor?: string,
): StyleSheet.NamedStyles<RadioButtonStyle> => {
  const color = disabled ? Colors.GreyLight : radioButtonColor || Colors.Turquoise;
  return StyleSheet.create({
    item: {
      flexDirection: 'row',
    },
    radioButton: {
      ...createRoundedStyle(roundedWith),
      borderWidth: 1,
      backgroundColor: Colors.White,
      borderColor: color,
      padding: Spacings.Unit / 2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    radioButtonContainer: {
      marginRight: Spacings.xMedium,
      alignItems: 'center',
      justifyContent: 'center',
    },
    radioButtonSelected: {
      backgroundColor: color,
      ...createRoundedStyle(roundedWith * (2 / 3)),
    },
    boldTextStyle: {
      ...TextFontStyles.LatoBlack,
      fontSize: fontSize.Regular,
      paddingTop: Spacings.Unit / 2,
    },
    descriptionTextStyle: { ...TextFontStyles.LatoRegular, fontSize: fontSize.Regular },
    divider: { height: Spacings.Medium },
  });
};

export default generateStyles;
