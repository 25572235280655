import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import logger from 'shared/util/Logger';
import {
  NoMetadata,
  ShiftWorkersTarget,
  WorkersTargetOnShift,
  getWorkerWorkstationFactories,
  getWorkerWorkstations,
} from 'shared/backend-data';
import Aigle from 'aigle';
import { i18n, t } from 'shared/localisation/i18n';
import { searchMatch, wait } from '../util-ts/Functions';
import { broadcastBusinesObjectMutateEventDebounce } from './MyHub';
import { WorkstationWorkerLevel } from 'backend/src/api';
import { ImportExportFailureMessageKey } from './ExcelUtils';
import { Immutable } from 'shared/util-ts/Functions';
import { v4 as uuidv4 } from 'uuid';

export const pathIdsDelimeter = ' > ';

const lowPerformaceIterationTimeout = 100;





export interface WorkstationTargetActualWithDetails {
  workstation: API.Workstation;
  shift?: API.Shift;
  workstationsTargetActual: WorkstationsTargetActual;
}

export type WorkstationWithOverAndUnderWorkersCount = {
  workstation: API.Workstation;
  overAndUnderWorkersCount: number;
};

export enum TargetUnit {
  DECIMAL,
  PERCENTAGE,
}

interface SkillWorkstation extends API.Workstation {
  skillId: string;
  level: API.WorkstationWorkerLevels;
}

export interface CopyWorkstationOrOrgUnit {
  workstationOrOrgUnit: API.TreeObject;
  copiedWorkstationOrOrgUnit: API.TreeObject;
}

interface ComputeWorkstationWorkersLevelCount {
  level1AndAboveCount: number;
  level2AndAboveCount: number;
  level3AndAboveCount: number;
  level4Count: number;
  isWorkerOK: boolean;
}

export function extractWORKSTATIONWorkersTargetOnShiftIDS(
  workstation: API.Workstation | NoMetadata<API.Workstation>,
): string[] {
  if (!workstation.workersTargetOnShift) return [];

  const _workersTargetOnShift: WorkersTargetOnShift | undefined =
    API.extractWORKSTATIONWorkersTarget(workstation);

  return Object.keys(_workersTargetOnShift ?? {});
}

export async function createWorkstation(
  workstationInput: API.WorkstationCreateInput,
): Promise<API.Result<API.Workstation>> {
  const factory = await API.createFactoryBusinessObject(API.DataType.WORKSTATION, workstationInput);
  if (API.isFailure(factory)) return factory;

  const workstation = {
    ...factory.workstation,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };

  return workstation;
}

export async function updateWorkstation(
  workstationInput: API.WorkstationPartialUpdateInput,
): Promise<API.Result<API.Workstation>> {
  const factory = await API.updateFactoryBusinessObject(API.DataType.WORKSTATION, workstationInput);
  if (API.isFailure(factory)) return factory;

  return { ...factory.workstation, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

/**
 * Get the list of Workstations belonging to the given OrganizationalUnit, without duplicates.
 * @param organizationalUnitIds if none is specified, all OrganizationalUnits are considered
 * @param includeNested (optional, default=false) if true, it also returns the nested OrganizationalUnits' Workstations
 *  (if organizationalUnit is not set, it has no effect and equivalent to true)
 */
export function getWorkstations(
  organizationalUnitIds?: string[],
  includeNested = false,
  treeStructureOrdered = false,
): API.Workstation[] {
  const workstations: API.Workstation[] = [];
  const orgUnitsTreeStructure = API.Tree.getTopTreeNodes();

  if (!organizationalUnitIds) {
    if (treeStructureOrdered && orgUnitsTreeStructure) {
      function appendWorkstations(orgUnitTreeNode: API.TreeNode<API.DataType.ORGUNIT>) {
        const childOrgUnits: API.TreeNode<API.DataType.ORGUNIT>[] = [];
        orgUnitTreeNode.children.forEach(_child => {
          
          if (API.isTreeNode(API.DataType.WORKSTATION, _child)) {
            workstations.push({
              ..._child.object,
              updatedAt: _child.factory.updatedAt,
              updatedBy: _child.factory.updatedBy,
            });
          } else if (API.isTreeNode(API.DataType.ORGUNIT, _child)) {
            childOrgUnits.push(_child);
          } else {
            logger.error('This TreeDataType is not handled yet: ' + _child.factory.dataType);
          }
        });
        childOrgUnits.forEach(__child => appendWorkstations(__child));
      }

      orgUnitsTreeStructure.forEach(treeNode => {
        appendWorkstations(treeNode);
      });
    } else {
      const _workstations = API.FactoryCache.getFactoryCache(
        API.DataType.WORKSTATION,
      ).getFactories();
      if (API.isFailure(_workstations)) {
        logger.error(
          'ERROR -> getWorkstations will partial result. Reason :' + _workstations.message,
          _workstations,
        );
        return [];
      }
      _workstations.forEach(f => {
        workstations.push({ ...f.workstation, updatedAt: f.updatedAt, updatedBy: f.updatedBy });
      });
    }
  } else {
    organizationalUnitIds.forEach(organizationalUnitId => {
      const _workstations = API.Tree.getChildren(
        organizationalUnitId,
        includeNested,
        API.DataType.WORKSTATION,
      );
      if (API.isFailure(_workstations)) {
        logger.error(
          'WARNING getWorkstations is returning partial result: ' + _workstations.message,
          _workstations,
        );
        return;
      }

      workstations.push(..._workstations);
    });
  }

  return _.uniqBy(workstations, 'id');
}

/**
 * Get the list of Workstations belonging to the given Workers (without duplicates) where the workers have all the given permissions
 * @param workers (optional) if not set, all Workers are considered
 * @param permissions (optional) if set, returns only Workstations where the worker has all the given permissions
 */
export async function getWorkersLinkedWorkstations(
  workers: API.Worker[] = [],
  permissions?: API.Permission[],
): Promise<API.Result<API.Workstation[]>> {
  const workersOrgUnitRolesMap = await API.getWorkersAssignments(
    false,
    false,
    workers.map(worker => worker.id),
    undefined,
    permissions,
  );
  if (API.isFailure(workersOrgUnitRolesMap)) return workersOrgUnitRolesMap;

  let orgUnitIds: string[] = [];
  workersOrgUnitRolesMap.forEach(value => {
    value.forEach(orgUnitRole => {
      orgUnitIds = _.unionBy(orgUnitIds, [orgUnitRole.organizationalUnitId]);
    });
  });

  return API.getWorkstations(orgUnitIds, true);
}

export async function getWorkstation(workstationId: string): Promise<API.Result<API.Workstation>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.WORKSTATION, workstationId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.workstation, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

/**
 * Gets the Workstations and Organizational Units that have this training version in their requirement
 * @param trainingVersionId
 */
export async function getWorkstationsOrOrganizationalUnits(
  trainingVersionId: string,
): Promise<API.Result<API.TreeObject[]>> {
  const requirements = await API.getRequirements();
  if (API.isFailure(requirements)) return requirements;

  const wsAndOrgUnitIds: string[] = [];
  _.map(requirements.result, requirement => {
    const trainingVersionIds = _.map(
      requirement.skillTrainingVersions,
      skillTrainingVersion => skillTrainingVersion.trainingVersionId,
    );
    if (_.includes(trainingVersionIds, trainingVersionId))
      wsAndOrgUnitIds.push(requirement.linkedObjectId);
  });

  const wsAndOrgUnits: API.TreeObject[] = [];
  const errors: API.Failure[] = [];
  await Promise.all(
    _.map(wsAndOrgUnitIds, async id => {
      const unit = await API.getWorkstationOrOrganizationalUnit(id);
      if (API.isFailure(unit)) {
        errors.push(unit);
        return;
      }
      wsAndOrgUnits.push(unit);
    }),
  );

  if (errors.length) return API.createFailure_Multiple(errors);

  return wsAndOrgUnits;
}

/**
 * Returns:
 *  - Workstation matching the given name that is a direct child of the given parentId
 *  - Failure if no Workstation matching the given name is found
 *  - Failure if there are several Workstations with the same name.
 * N.B. it shall not be used to test the existence of a Workstation as the result depends on the User scope (what data user can see).
 * @param name - the name of the workstation
 * @param parentId - optional: id of parent org unit - if undefined consider all org units
 * @param exactMatch (optional, default = true)
 */
export function getWorkstationByName(
  name: string,
  parentId?: string,
  exactMatch = true,
): API.Result<API.Workstation> {
  return _getWorkstationByName(API.DataType.WORKSTATION, name, parentId, exactMatch);
}

function _getWorkstationByName<T extends API.TreeDataType>(
  dataType: T,
  name: string,
  parentId?: string,
  exactMatch = true,
): API.Result<API.TreeObject<T>> {
  const treeObjects = API.Tree.getTreeObjectsByName(name, parentId, dataType, exactMatch);
  if (API.isFailure(treeObjects)) return treeObjects;
  if (treeObjects.length === 0) {
    let parentError = '';
    if (parentId) {
      const parentOrgUnit = API.getOrganizationalUnit(parentId);
      if (API.isFailure(parentOrgUnit)) {
        parentError = ' and the parent (error: "' + parentOrgUnit.message + '")';
      } else {
        parentError = ' and the parent ("' + parentOrgUnit.name + '")';
      }
    }

    return API.createFailure(
      'ObjectNotFound',
      `Tree object with the name "${name}"${parentError} could not be found. Make sure the names are correct and that yoo have the right permissions.`,
    );
  }
  if (treeObjects.length > 1)
    return API.createFailure_Unspecified(
      `Several tree objects have the name "${name}". Consider passing the id instead.`,
    );
  return treeObjects[0];
}

/**
 * Returns:
 *  - OrganizationalUnit matching the given name that is a direct child of the given parentId
 *  - Failure if no OrganizationalUnit matching the given name is found
 *  - Failure if there are several OrganizationalUnits with the same name.
 * N.B. it shall not be used to test the existence of a OrgUnit as the result depends on the User scope (what data user can see).
 * @param name - the name of the OrgUnit
 * @param parentId - optional: id of parent org unit - if undefined consider all org units
 * @param exactMatch (optional, default = true)
 */
export function getOrgUnitByName(
  name: string,
  parentId?: string,
  exactMatch = true,
): API.Result<API.OrganizationalUnit> {
  return _getWorkstationByName(API.DataType.ORGUNIT, name, parentId, exactMatch);
}





export async function createOrganizationalUnit(
  organizationalUnitInput: API.OrganizationalUnitCreateInput,
): Promise<API.Result<API.OrganizationalUnit>> {
  const factory = await API.createFactoryBusinessObject(
    API.DataType.ORGUNIT,
    organizationalUnitInput,
  );
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.organizationalUnit,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function updateOrganizationalUnit(
  organizationalUnit: API.OrganizationalUnitPartialUpdateInput,
): Promise<API.Result<API.OrganizationalUnit>> {
  const factory = await API.updateFactoryBusinessObject(API.DataType.ORGUNIT, organizationalUnit);
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.organizationalUnit,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export function getOrganizationalUnit(
  organizationalUnitId: string,
): API.Result<API.OrganizationalUnit> {
  const node = API.Tree.getTreeNode<API.DataType.ORGUNIT>(organizationalUnitId);
  if (API.isFailure(node)) return node;

  return {
    ...node.factory.organizationalUnit,
    updatedAt: node.factory.updatedAt,
    updatedBy: node.factory.updatedBy,
  };
}

export function orderRolesBasedOnTreeOrder(
  workerAssignments: API.IndexedAssignment[],
  nodesToCheck?: API.TreeNode[],
): API.IndexedAssignment[] {
  let orderdRoles: API.IndexedAssignment[] = [];
  let topNodes;

  if (nodesToCheck) topNodes = nodesToCheck;
  else topNodes = API.Tree.getTopTreeNodes();

  for (const node of topNodes) {
    const rolesOnThisNode = workerAssignments.filter(
      role => role.organizationalUnit.id === node.id,
    ); 

    if (rolesOnThisNode) {
      rolesOnThisNode.forEach(role => {
        orderdRoles.push({ ...role, uuid: uuidv4() });
      });
    }

    orderdRoles = [...orderdRoles, ...orderRolesBasedOnTreeOrder(workerAssignments, node.children)];
  }

  return orderdRoles;
}

/**
 * Get the specified organizational unit from network no chache
 * @param organizationalUnitId
 * @returns
 */
export async function getOrganizationalUnitFromNetwork(
  organizationalUnitId: string,
): Promise<API.Result<API.OrganizationalUnit>> {
  const unit = await API.getFactory(API.DataType.ORGUNIT, organizationalUnitId, 'network-no-cache');
  if (API.isFailure(unit)) return unit;

  return {
    ...unit.organizationalUnit,
    updatedAt: unit.updatedAt,
    updatedBy: unit.updatedBy,
  };
}

export function getOrganizationalUnits(): API.OrganizationalUnit[] {
  return API.Tree.getTreeObjects(API.DataType.ORGUNIT);
}
/**
 * Get all the organizational units from network no chache
 * @param itemsLimit
 * @param nextToken
 */
export async function getOrganizationalUnitsFromNetwork(
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.OrganizationalUnit[]>> {
  const factories = await API.listFactoriesWithDataType(
    API.DataType.ORGUNIT,
    undefined,
    itemsLimit,
    nextToken,
  );
  if (API.isFailure(factories)) return factories;

  return {
    result: _.map(factories.result, factory => {
      return {
        ...factory.organizationalUnit!,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      };
    }),
    nextToken: factories.nextToken,
  };
}

/**
 * Takes an organizational unit name and a parent OrgUnit
 * Checks if parent OrgUnit contains an OrgUnit with specified name, creates it if not found.
 * @param name name of OrgUnit to find or create
 * @param parentId id of parent orgUnit (if not set, the 1st top accessible orgUnit will be used)
 * @return the found or newly created OrgUnit
 */
export async function getOrCreateOrganizationalUnit(
  name: string,
  parentId?: string,
): Promise<API.Result<API.Factory<API.DataType.ORGUNIT>>> {
  return API.getOrCreateFactory(
    API.DataType.ORGUNIT,
    async () => {
      if (!parentId) {
        
        const topTreeNodes = API.Tree.getTopTreeNodes();
        if (!topTreeNodes.length)
          return API.createFailure_Unspecified(
            i18n.t(ImportExportFailureMessageKey.TopOrgUnitInaccessible),
          );

        parentId = topTreeNodes[0].id; 
      }

      const parentTreeNode = API.Tree.getTreeNode(parentId);
      if (API.isFailure(parentTreeNode)) return parentTreeNode;

      for (const childTreeNode of API.Tree.getChildrenTreeNode(
        parentTreeNode,
        false,
        API.DataType.ORGUNIT,
      )) {
        
        
        if (searchMatch(childTreeNode.object.name, name, true)) {
          return API.deepClone(childTreeNode.factory);
        }
      }

      const newFactory = await API.createFactoryBusinessObject(API.DataType.ORGUNIT, {
        parentId,
        name,
      });
      if (API.isFailure(newFactory)) return newFactory;

      
      
      
      
      
      await wait(broadcastBusinesObjectMutateEventDebounce + 50);

      return newFactory;
    },
    name + parentId,
  );
}

/**
 * Get the OrganizationalUnits where the specified Worker belongs to.
 * @param workerId
 * @param permissions (optional) if set, returns only orgunits that have all the given permissions
 * @param includeNested (optional, default=true) if true, include the inherted asssignments (descendants)
 */
export async function getWorkerOrganizationalUnits(
  workerId: string,
  permissions?: API.Permission[],
  includeNested = true,
): Promise<API.Result<API.OrganizationalUnit[]>> {
  const workerAssignments = await API.getWorkerAssignments(
    workerId,
    includeNested,
    true,
    undefined,
    permissions,
  );
  if (API.isFailure(workerAssignments)) return workerAssignments;

  return _.reduce(
    workerAssignments,
    (acc, workerAssignment) => {
      const orgUnit = workerAssignment.organizationalUnit;

      if (includeNested) {
        
        if (_.some(acc, prevOrgUnit => prevOrgUnit.id === orgUnit.id)) return acc;
        acc.push(orgUnit);
        return acc;
      } else {
        const newAcc: API.OrganizationalUnit[] = [];
        let addOrgUnit = true;
        for (const prevOrgUnit of acc) {
          
          if (prevOrgUnit.id === orgUnit.id) return acc;

          
          if (orgUnit.pathIds.includes(prevOrgUnit.id)) {
            addOrgUnit = false;
          }

          
          if (!prevOrgUnit.pathIds.includes(orgUnit.id)) {
            newAcc.push(prevOrgUnit);
          }
        }
        if (addOrgUnit) newAcc.push(orgUnit);

        return newAcc;
      }
    },
    [] as API.OrganizationalUnit[],
  );
}





export interface WorkersVersatility {
  [workerId: string]: {
    versatility: number;
    magicSquares: number;
    workstationsWithMagicSquares: number;
  };
}

export interface WorkstationsTargetActual {
  [workstationId: string]: API.WorkstationTargetActual;
}

/**
 * Compute Workers versatility and Worksations' objectives and
 * actual number of Workers with Level2, Level3 and Level4 (see workstationTargetsStartingAtLevel)
 * @param workers Selected Workers to compute their Versatility
 * @param workstations Selected Workstations to calculate their Objectives
 * @param workstationTargetsStartingAtLevel Selected workstation target company setting
 */
export function getWorkersVersatilityAndWorkstationsObjectiveActuals(
  workers: API.Worker[],
  workstations: API.Workstation[],
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  shiftId?: string,
): {
  workersVersatility: WorkersVersatility;
  workstationsTargetActual: WorkstationsTargetActual;
} {
  function getDefaultTargets(
    workersTargetOnShift: API.ShiftWorkersTarget | undefined,
  ): API.WorkstationTargetActual {
    return {
      workersWithLevel1AtLeastActual: 0,
      workersWithLevel2AtLeastActual: 0,
      workersWithLevel2AtLeastWorkstationTarget: {
        minNumberOfWorker:
          workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker,
        idealNumberOfWorker:
          workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker,
        isPercentage: workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.isPercentage,
      },

      workersWithLevel3AtLeastActual: 0,
      workersWithLevel3AtLeastWorkstationTarget: {
        minNumberOfWorker:
          workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker,
        idealNumberOfWorker:
          workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker,
        isPercentage: workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.isPercentage,
      },

      workersWithLevel4Actual: 0,
      workersWithLevel4WorkstationTarget: {
        minNumberOfWorker:
          workersTargetOnShift?.workersWithLevel4WorkstationTarget?.minNumberOfWorker,
        idealNumberOfWorker:
          workersTargetOnShift?.workersWithLevel4WorkstationTarget?.idealNumberOfWorker,
        isPercentage: workersTargetOnShift?.workersWithLevel4WorkstationTarget?.isPercentage,
      },
    };
  }

  const workersVersatility: WorkersVersatility = {};
  const workstationsTargetActual: WorkstationsTargetActual = {};

  if (!workers.length) {
    _.forEach(workstations, workstation => {
      const _workersTargetOnShift = API.extractWORKSTATIONWorkersTargetOnShift(
        workstation,
        shiftId,
      );
      if (isReturnDefaultTarget(shiftId, workstation, _workersTargetOnShift)) {
        workstationsTargetActual[workstation.id] = getDefaultTargets(_workersTargetOnShift);
      }
    });

    return {
      workersVersatility,
      workstationsTargetActual,
    };
  }

  _.forEach(workstations, workstation => {
    _.forEach(workers, worker => {
      let workerVersatility = workersVersatility[worker.id];
      if (!workerVersatility) {
        workerVersatility = {
          versatility: 0,
          magicSquares: 0,
          workstationsWithMagicSquares: 0,
        };
        workersVersatility[worker.id] = workerVersatility;
      }

      const workerWorkstation = getWorkerWorkstations(workstation.id, worker.id);

      if (workerWorkstation?.level) {
        workerVersatility.magicSquares += API.api2workstationWorkerLevels(workerWorkstation.level);

        if (workerWorkstation.level !== WorkstationWorkerLevel.LEVEL0)
          workerVersatility.workstationsWithMagicSquares += 1;

        if (API.isWorkerSkilled(workerWorkstation, workstationTargetsStartingAtLevel))
          workerVersatility.versatility += 1;
      }

      let incrementActualWorkers = true;
      const _workersTargetOnShift = API.extractWORKSTATIONWorkersTargetOnShift(
        workstation,
        shiftId,
      );

      if (!workstationsTargetActual[workstation.id]) {
        if (isReturnDefaultTarget(shiftId, workstation, _workersTargetOnShift))
          workstationsTargetActual[workstation.id] = getDefaultTargets(_workersTargetOnShift);
        else incrementActualWorkers = false;
      }

      if (incrementActualWorkers && workerWorkstation?.level) {
        switch (API.api2workstationWorkerLevels(workerWorkstation.level)) {
          case API.WorkstationWorkerLevels.LEVEL4:
            workstationsTargetActual[workstation.id].workersWithLevel4Actual++;

          case API.WorkstationWorkerLevels.LEVEL3:
            workstationsTargetActual[workstation.id].workersWithLevel3AtLeastActual++;

          case API.WorkstationWorkerLevels.LEVEL2:
            workstationsTargetActual[workstation.id].workersWithLevel2AtLeastActual++;

          case API.WorkstationWorkerLevels.LEVEL1:
            workstationsTargetActual[workstation.id].workersWithLevel1AtLeastActual =
              (workstationsTargetActual[workstation.id].workersWithLevel1AtLeastActual ?? 0) + 1;
        }
      }
    });
  });

  return {
    workersVersatility,
    workstationsTargetActual,
  };
}

function isReturnDefaultTarget(
  shiftId: string | undefined,
  workstation: API.Workstation,
  workersTargetOnShift: API.ShiftWorkersTarget | undefined,
): boolean {
  return (
    
    Boolean(workersTargetOnShift) ||
    
    Boolean(
      !shiftId &&
        !API.extractWORKSTATIONWorkersTargetOnShiftIDS(workstation).some(_shiftId =>
          _shiftId.includes(API.DataType.SHIFT),
        ) &&
        !workstation.shiftIds?.length,
    ) ||
    
    Boolean(shiftId && workstation.shiftIds?.includes(shiftId))
  );
}

export interface WorkstationTargetValues {
  workersWithLevel1AtLeastActual?: number; 
  workersWithLevel2AtLeastActual: number;
  workersWithLevel3AtLeastActual: number;
  workersWithLevel4Actual: number;
}

export interface WorkstationTargetActual extends ShiftWorkersTarget, WorkstationTargetValues {}

/**
 * Function returns workstation id's where the worker got skilled on a given workstation id's.
 * @param workerId
 * @param workstationIds (optional) If not specified, all the company Workstations are considered.
 */
export async function getWorkerSkilledWorkstations(
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  workerId: string,
  workstationIds?: string[],
): Promise<API.Result<string[]>> {
  if (!workstationIds) {
    const allWorkstations = API.getWorkstations();
    workstationIds = allWorkstations.map(workstation => workstation.id);
  }

  const wWorkerLevels = await API.getWorkersSkilled(
    workstationIds,
    [workerId],
    workstationTargetsStartingAtLevel,
  );
  if (API.isFailure(wWorkerLevels)) {
    logger.warn('getWorkerSkilledWorkstations error', wWorkerLevels);
    return wWorkerLevels;
  }

  return wWorkerLevels.map(eachWWorkerLevel => eachWWorkerLevel.workstationId);
}

/**
 * @param treeObject to test
 * @returns
 */
export function isOrganizationalUnit(
  treeObject: API.TreeObject, 
): treeObject is API.OrganizationalUnit {
  return API.isBusinessObject(API.DataType.ORGUNIT, treeObject);
}

/**
 * @param treeObject to test
 * @returns
 */
export function isWorkstation(
  treeObject: API.TreeObject, 
): treeObject is API.Workstation {
  return API.isBusinessObject(API.DataType.WORKSTATION, treeObject);
}

export function isShift(id: string): boolean {
  const businessObjectDataType = API.getDataType(id);
  if (API.isFailure(businessObjectDataType)) return false;

  return businessObjectDataType === API.DataType.SHIFT;
}

export async function getWorkstationOrOrganizationalUnit(
  workstationOrOrganizationalUnitId: string,
): Promise<API.Result<API.TreeObject>> {
  const dataType = API.getDataType(workstationOrOrganizationalUnitId);
  if (API.isFailure(dataType)) return dataType;

  if (dataType === API.DataType.WORKSTATION) {
    return API.getWorkstation(workstationOrOrganizationalUnitId);
  } else if (dataType === API.DataType.ORGUNIT) {
    return API.getOrganizationalUnit(workstationOrOrganizationalUnitId);
  } else {
    return API.createFailure_Unspecified(
      'id:' + workstationOrOrganizationalUnitId + ' is neither a Workstation nor a OrgUnit',
    );
  }
}

/**
 * Get the Workstations that require the given Skill, for each Level.
 * @param skillId
 * @param workerId (Optional): include only workstations where worker is operational
 * @param level (Optional): limit to the given level
 */
export async function getSkillWorkstations(
  skillId: string,
  workerId?: string,
  level?: WorkstationWorkerLevel,
): Promise<API.Result<SkillWorkstation[]>> {
  const requirements = await API.getRequirements();
  if (API.isFailure(requirements)) return requirements;

  const skillWorkstations: SkillWorkstation[] = [];
  const errors: API.Failure[] = [];

  for (const requirement of requirements.result) {
    if (!level || requirement.level === level) {
      if (API.extractSkillIds(requirement).includes(skillId)) {
        const linkedObject = await API.getWorkstationOrOrganizationalUnit(
          requirement.linkedObjectId,
        );
        if (API.isFailure(linkedObject)) {
          if (
            API.isFailureType(linkedObject, 'Unauthorized') ||
            API.isFailureType(linkedObject, 'ObjectNotFound')
          ) {
            logger.warn(
              'user unauthorized to view some of the skills workstations or the the object doesnt exist',
              linkedObject,
            );
          } else {
            errors.push(linkedObject);
          }
          continue;
        }

        let workstationsToAdd = [];
        if (API.isWorkstation(linkedObject)) {
          workstationsToAdd.push(linkedObject);
        }
        
        else if (API.isOrganizationalUnit(linkedObject)) {
          const orgUnitWorkstations = API.Tree.getChildren(
            linkedObject.id,
            true,
            API.DataType.WORKSTATION,
          );
          if (API.isFailure(orgUnitWorkstations)) {
            errors.push(orgUnitWorkstations);
            continue;
          }
          workstationsToAdd.push(...orgUnitWorkstations);
        }

        if (workerId) {
          workstationsToAdd = _.filter(workstationsToAdd, workstation => {
            const workerWorkstation = getWorkerWorkstationFactories(workstation.id, workerId);

            return !!workerWorkstation;
          });
        }

        for (const workstation of workstationsToAdd) {
          if (skillWorkstations.every(item => item.id !== workstation.id)) {
            
            skillWorkstations.push({
              ...workstation,
              level: API.api2workstationWorkerLevels(requirement.level),
              skillId,
            });
          }
        }
      }
    }
  }
  if (errors.length) return API.createFailure_Multiple(errors);

  return skillWorkstations;
}

export const orgUnitPathNameNotReachable = '?';

/**
 * Get the given OrgUnit's full path name
 * or the given Workstation full path name (list of parents OrgUnit's name)
 * @param workstationOrOrgUnit
 */
export async function getOrgUnitPathNames(
  _workstationOrOrgUnit: API.TreeObject | string,
): Promise<string[]> {
  let workstationOrOrgUnit: API.TreeObject;
  if (_.isString(_workstationOrOrgUnit)) {
    const result = await API.getWorkstationOrOrganizationalUnit(_workstationOrOrgUnit);
    if (API.isFailure(result)) {
      logger.warn('Failed to fetch workstation/orgUnit with id: ' + _workstationOrOrgUnit, result);
      return [orgUnitPathNameNotReachable];
    }

    workstationOrOrgUnit = result;
  } else {
    workstationOrOrgUnit = _workstationOrOrgUnit;
  }

  let orgUnit: API.OrganizationalUnit;
  if (API.isWorkstation(workstationOrOrgUnit)) {
    const _orgUnit = API.getOrganizationalUnit(workstationOrOrgUnit.parentId);
    if (API.isFailure(_orgUnit)) {
      logger.warn('Failed to fetch OrgUnit', _orgUnit);
      return [orgUnitPathNameNotReachable];
    }
    orgUnit = _orgUnit;
  } else {
    orgUnit = workstationOrOrgUnit as API.OrganizationalUnit; 
  }

  const orgUnitNames: string[] = await Aigle.map(orgUnit.pathIds, async pathId => {
    const orgUnit = API.getOrganizationalUnit(pathId);
    if (API.isFailure(orgUnit)) {
      logger.warn('Failed to fetch OrgUnit', orgUnit);
      return orgUnitPathNameNotReachable;
    } else {
      return orgUnit.name;
    }
  });

  return orgUnitNames;
}

export function displayOrgUnitPathNames(orgUnitPathNames: string[]): string {
  return orgUnitPathNames.join(pathIdsDelimeter);
}

/**
 * Get the Worker's OrganizationalUnits' path names
 * @param workerId string
 */
export async function getWorkerOrgUnitPathNames(workerId: string): Promise<string[][]> {
  const workerOrgUnits = await API.getWorkerOrganizationalUnits(workerId, undefined, false);
  if (API.isFailure(workerOrgUnits)) {
    logger.warn('Failed to fetch OrgUnit', workerOrgUnits);
    return [[orgUnitPathNameNotReachable]];
  }

  return Aigle.map(workerOrgUnits, async orgUnit => getOrgUnitPathNames(orgUnit));
}

export async function copyShifts(data: CopyWorkstationOrOrgUnit[]) {
  await Aigle.map(data, async eachData => {
    const isOrgUnitObject = API.isOrganizationalUnit(eachData.copiedWorkstationOrOrgUnit);

    if (!eachData.workstationOrOrgUnit.shiftIds) return;

    if (isOrgUnitObject) {
      await Aigle.mapSeries(
        API.deepClone(eachData.workstationOrOrgUnit.shiftIds),
        async shiftId => {
          const shift = await API.getShift(shiftId);
          if (API.isFailure(shift)) return shift;

          
          if (shift.parentId === eachData.workstationOrOrgUnit.id) {
            const shiftObject: API.ShiftCreateInput = {
              parentId: eachData.copiedWorkstationOrOrgUnit.id,
              name: shift.name,
              color: shift.color,
            };

            const createdShift = await API.createShift(shiftObject);
            if (API.isFailure(createdShift)) {
              return createdShift;
            }
          }
        },
      );
    }
  });
}

/**
 * Copy a Workstation or a OrganizationalUnit
 * @param workstationOrOrganizationalUnit
 * @param final Array : is mutated
 * @param parentId (Optional). If not set, the same parent as workstationOrOrganizationalUnit is used.
 */
export async function copyWorkstationOrOrganizationalUnit(
  workstationOrOrganizationalUnit: API.TreeObject,
  parentId?: string,
): Promise<API.Result<CopyWorkstationOrOrgUnit[]>> {
  const finalArray: CopyWorkstationOrOrgUnit[] = [];
  const copiedWorkstationOrOrganizationalUnit = await _copyWorkstationOrOrganizationalUnit(
    workstationOrOrganizationalUnit,
    parentId,
  );
  if (API.isFailure(copiedWorkstationOrOrganizationalUnit))
    return copiedWorkstationOrOrganizationalUnit;

  finalArray.push({
    workstationOrOrgUnit: workstationOrOrganizationalUnit,
    copiedWorkstationOrOrgUnit: copiedWorkstationOrOrganizationalUnit,
  });

  const children = API.Tree.getChildren(workstationOrOrganizationalUnit.id);
  if (API.isFailure(children)) return children;

  const failures: API.Failure[] = [];
  await Promise.all(
    _.map(children, async child => {
      const copy = await copyWorkstationOrOrganizationalUnit(
        child,
        copiedWorkstationOrOrganizationalUnit.id,
      );
      if (API.isFailure(copy)) failures.push(copy);
      else finalArray.push(...copy);
    }),
  );
  if (failures.length) return API.createFailure_Multiple(failures);

  return finalArray;
}

/**
 * To get the workers assigned to workstation or shift
 * @param workstationId
 * @param shiftId
 */
export async function workersAssignedInShiftOrWorkstation(
  workstationId: string,
  shiftId: string | undefined,
): Promise<API.Result<number>> {
  const workstation = await API.getWorkstation(workstationId);
  if (API.isFailure(workstation)) return workstation;

  if (shiftId) {
    const workersAssigned = await API.getWorkersInShift(
      shiftId,
      workstation.parentId,
      undefined,
      true,
      false,
      [API.Permission.workerIsOperational],
    );
    if (API.isFailure(workersAssigned)) return workersAssigned;

    return workersAssigned.length;
  } else {
    let workersAssigned: API.Worker[];

    if (API.enableGlobalLevelComputation) {
      const _workers = await API.getWorkers([API.Permission.workerIsOperational]);
      if (API.isFailure(_workers)) {
        logger.warn(_workers);
        return _workers;
      }
      workersAssigned = _workers.result;
    } else {
      const _workers = await API.getWorkersInOrganizationalUnits(
        [workstation.parentId],
        [API.Permission.workerIsOperational],
      );
      if (API.isFailure(_workers)) {
        logger.warn(_workers);
        return _workers;
      }
      workersAssigned = _workers.result;
    }

    return workersAssigned.length;
  }
}

export function toPercentage(
  numOfAssignedWorkers: number,
  isFloor: boolean,
  target?: number,
): number {
  let value = 0;

  if (numOfAssignedWorkers)
    if (isFloor) value = Math.floor(((target ?? 0) / numOfAssignedWorkers) * 100);
    else value = Math.ceil(((target ?? 0) / numOfAssignedWorkers) * 100);

  return value > 100 ? 100 : value;
}

export function toDecimal(numOfAssignedWorkers: number, isFloor: boolean, target?: number): number {
  let value = 0;

  if (numOfAssignedWorkers)
    if (isFloor) value = Math.floor(((target ?? 0) / 100) * numOfAssignedWorkers);
    else value = Math.ceil(((target ?? 0) / 100) * numOfAssignedWorkers);

  return value;
}

/**
 * Convert workstation/shift targets from decimals (number of workers) to percentages
 * @param workstationTargets - workstation/shift targets to convert to percentages
 * @returns workstationTargets in percentage
 */
export async function convertLevelTargetsFromDecimalToPercentage(
  workstationTargets: API.WorkstationTargetActual,
  workstationId: string,
  shiftId: string | undefined,
  level?: API.WorkstationWorkerLevels,
  needActual: boolean = false,
  numOfWorkers?: number,
): Promise<API.Result<API.WorkstationTargetActual>> {
  const result = { ...workstationTargets };

  let _numOfWorkers = numOfWorkers;

  if (_numOfWorkers === undefined) {
    const numOfAssignedWorkers = await workersAssignedInShiftOrWorkstation(workstationId, shiftId);
    if (API.isFailure(numOfAssignedWorkers)) return numOfAssignedWorkers;

    _numOfWorkers = numOfAssignedWorkers;
  }

  if (needActual) {
    result.workersWithLevel2AtLeastActual = toPercentage(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel2AtLeastActual,
    );

    result.workersWithLevel3AtLeastActual = toPercentage(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel3AtLeastActual,
    );

    result.workersWithLevel4Actual = toPercentage(
      _numOfWorkers,
      false,
      workstationTargets.workersWithLevel4Actual,
    );
  }

  if (
    (level === API.WorkstationWorkerLevels.LEVEL2 &&
      workstationTargets.workersWithLevel2AtLeastWorkstationTarget?.isPercentage) ||
    (level === API.WorkstationWorkerLevels.LEVEL3 &&
      workstationTargets.workersWithLevel3AtLeastWorkstationTarget?.isPercentage) ||
    (level === API.WorkstationWorkerLevels.LEVEL4 &&
      workstationTargets.workersWithLevel4WorkstationTarget?.isPercentage)
  ) {
    logger.info(
      'Targets already in percentage for workstation/shift:',
      workstationId,
      ' on level ',
      level,
    );
    return result;
  }

  if (
    !workstationTargets.workersWithLevel2AtLeastWorkstationTarget?.isPercentage &&
    level === API.WorkstationWorkerLevels.LEVEL2
  ) {
    result.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker = toPercentage(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker,
    );

    result.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker = toPercentage(
      _numOfWorkers,
      false,
      workstationTargets.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker,
    );
  }

  if (
    !workstationTargets.workersWithLevel3AtLeastWorkstationTarget?.isPercentage &&
    level === API.WorkstationWorkerLevels.LEVEL3
  ) {
    result.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker = toPercentage(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker,
    );

    result.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker = toPercentage(
      _numOfWorkers,
      false,
      workstationTargets.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker,
    );
  }

  if (
    !workstationTargets.workersWithLevel4WorkstationTarget?.isPercentage &&
    level === API.WorkstationWorkerLevels.LEVEL4
  ) {
    result.workersWithLevel4WorkstationTarget.minNumberOfWorker = toPercentage(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel4WorkstationTarget.minNumberOfWorker,
    );

    result.workersWithLevel4WorkstationTarget.idealNumberOfWorker = toPercentage(
      _numOfWorkers,
      false,
      workstationTargets.workersWithLevel4WorkstationTarget.idealNumberOfWorker,
    );
  }

  return result;
}

export async function convertAllTargetsFromPercentageToDecimal(
  workstationTargets: API.WorkstationTargetActual,
  workstationId: string,
  shiftId: string | undefined,
  needActual: boolean = false,
): Promise<API.Result<API.WorkstationTargetActual>> {
  const result = { ...workstationTargets };

  const level1Decimals = await convertLevelTargetsFromPercentageToDecimal(
    workstationTargets,
    workstationId,
    API.WorkstationWorkerLevels.LEVEL1,
    shiftId,
    needActual,
  );
  if (API.isFailure(level1Decimals)) return level1Decimals;

  const level2Decimals = await convertLevelTargetsFromPercentageToDecimal(
    workstationTargets,
    workstationId,
    API.WorkstationWorkerLevels.LEVEL2,
    shiftId,
    needActual,
  );
  if (API.isFailure(level2Decimals)) return level2Decimals;

  const level3Decimals = await convertLevelTargetsFromPercentageToDecimal(
    workstationTargets,
    workstationId,
    API.WorkstationWorkerLevels.LEVEL3,
    shiftId,
    needActual,
  );
  if (API.isFailure(level3Decimals)) return level3Decimals;

  const level4Decimals = await convertLevelTargetsFromPercentageToDecimal(
    workstationTargets,
    workstationId,
    API.WorkstationWorkerLevels.LEVEL4,
    shiftId,
    needActual,
  );
  if (API.isFailure(level4Decimals)) return level4Decimals;

  result.workersWithLevel1AtLeastActual = level1Decimals.workersWithLevel1AtLeastActual;
  result.workersWithLevel2AtLeastActual = level2Decimals.workersWithLevel2AtLeastActual;
  result.workersWithLevel2AtLeastWorkstationTarget =
    level2Decimals.workersWithLevel2AtLeastWorkstationTarget;
  result.workersWithLevel3AtLeastActual = level3Decimals.workersWithLevel3AtLeastActual;
  result.workersWithLevel3AtLeastWorkstationTarget =
    level3Decimals.workersWithLevel3AtLeastWorkstationTarget;
  result.workersWithLevel4Actual = level4Decimals.workersWithLevel4Actual;
  result.workersWithLevel4WorkstationTarget = level4Decimals.workersWithLevel4WorkstationTarget;

  return result;
}

export async function convertAllTargetsFromDecimalToPercentage(
  workstationTargets: API.WorkstationTargetActual,
  workstationId: string,
  shiftId: string | undefined,
  needActual: boolean = false,
  numOfWorkers?: number,
): Promise<API.Result<API.WorkstationTargetActual>> {
  const result = { ...workstationTargets };

  const level1Percentages = await convertLevelTargetsFromDecimalToPercentage(
    workstationTargets,
    workstationId,
    shiftId,
    API.WorkstationWorkerLevels.LEVEL1,
    needActual,
    numOfWorkers,
  );
  if (API.isFailure(level1Percentages)) return level1Percentages;

  const level2Percentages = await convertLevelTargetsFromDecimalToPercentage(
    workstationTargets,
    workstationId,
    shiftId,
    API.WorkstationWorkerLevels.LEVEL2,
    needActual,
    numOfWorkers,
  );
  if (API.isFailure(level2Percentages)) return level2Percentages;

  const level3Percentages = await convertLevelTargetsFromDecimalToPercentage(
    workstationTargets,
    workstationId,
    shiftId,
    API.WorkstationWorkerLevels.LEVEL3,
    needActual,
    numOfWorkers,
  );
  if (API.isFailure(level3Percentages)) return level3Percentages;

  const level4Percentages = await convertLevelTargetsFromDecimalToPercentage(
    workstationTargets,
    workstationId,
    shiftId,
    API.WorkstationWorkerLevels.LEVEL4,
    needActual,
    numOfWorkers,
  );
  if (API.isFailure(level4Percentages)) return level4Percentages;

  result.workersWithLevel1AtLeastActual = level1Percentages.workersWithLevel1AtLeastActual;
  result.workersWithLevel2AtLeastActual = level2Percentages.workersWithLevel2AtLeastActual;
  result.workersWithLevel2AtLeastWorkstationTarget =
    level2Percentages.workersWithLevel2AtLeastWorkstationTarget;
  result.workersWithLevel3AtLeastActual = level3Percentages.workersWithLevel3AtLeastActual;
  result.workersWithLevel3AtLeastWorkstationTarget =
    level3Percentages.workersWithLevel3AtLeastWorkstationTarget;
  result.workersWithLevel4Actual = level4Percentages.workersWithLevel4Actual;
  result.workersWithLevel4WorkstationTarget = level4Percentages.workersWithLevel4WorkstationTarget;

  return result;
}

/**
 * Convert workstation/shift targets from percentages to decimals (number of workers)
 * @param currentTargets - workstation/shift targets to convert to percentages
 * @returns workstationTargets in decimals (number of workers)
 */
export async function convertLevelTargetsFromPercentageToDecimal(
  workstationTargets: API.WorkstationTargetActual,
  workstationId: string,
  level: API.WorkstationWorkerLevels,
  shiftId: string | undefined,
  needActual: boolean = false,
  numOfWorkers?: number,
): Promise<API.Result<API.WorkstationTargetActual>> {
  const result = { ...workstationTargets };

  if (
    (level === API.WorkstationWorkerLevels.LEVEL2 &&
      !workstationTargets.workersWithLevel2AtLeastWorkstationTarget?.isPercentage) ||
    (level === API.WorkstationWorkerLevels.LEVEL3 &&
      !workstationTargets.workersWithLevel3AtLeastWorkstationTarget?.isPercentage) ||
    (level === API.WorkstationWorkerLevels.LEVEL4 &&
      !workstationTargets.workersWithLevel4WorkstationTarget?.isPercentage)
  ) {
    logger.info(
      'Targets already in decimals for workstation/shift:',
      workstationId,
      ' on level ',
      level,
    );
    return result;
  }

  let _numOfWorkers = numOfWorkers;

  if (_numOfWorkers === undefined) {
    const numOfAssignedWorkers = await workersAssignedInShiftOrWorkstation(workstationId, shiftId);
    if (API.isFailure(numOfAssignedWorkers)) return numOfAssignedWorkers;

    _numOfWorkers = numOfAssignedWorkers;
  }

  if (needActual) {
    result.workersWithLevel2AtLeastActual = toDecimal(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel2AtLeastActual,
    );

    result.workersWithLevel3AtLeastActual = toDecimal(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel3AtLeastActual,
    );

    result.workersWithLevel4Actual = toDecimal(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel4Actual,
    );
  }

  if (
    workstationTargets.workersWithLevel2AtLeastWorkstationTarget?.isPercentage &&
    level === API.WorkstationWorkerLevels.LEVEL2
  ) {
    result.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker = toDecimal(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker,
    );

    result.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker = toDecimal(
      _numOfWorkers,
      false,
      workstationTargets.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker,
    );
  }

  if (
    workstationTargets.workersWithLevel3AtLeastWorkstationTarget?.isPercentage &&
    level === API.WorkstationWorkerLevels.LEVEL3
  ) {
    result.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker = toDecimal(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker,
    );

    result.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker = toDecimal(
      _numOfWorkers,
      false,
      workstationTargets.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker,
    );
  }

  if (
    workstationTargets.workersWithLevel4WorkstationTarget?.isPercentage &&
    level === API.WorkstationWorkerLevels.LEVEL4
  ) {
    result.workersWithLevel4WorkstationTarget.minNumberOfWorker = toDecimal(
      _numOfWorkers,
      true,
      workstationTargets.workersWithLevel4WorkstationTarget.minNumberOfWorker,
    );

    result.workersWithLevel4WorkstationTarget.idealNumberOfWorker = toDecimal(
      _numOfWorkers,
      false,
      workstationTargets.workersWithLevel4WorkstationTarget.idealNumberOfWorker,
    );
  }

  return result;
}

export async function convertTargetActualFromDecimalToPercentage(
  actualValue: number,
  workstationId: string,
  shiftId: string | undefined,
) {
  const numOfAssignedWorkers = await workersAssignedInShiftOrWorkstation(workstationId, shiftId);
  if (API.isFailure(numOfAssignedWorkers)) return numOfAssignedWorkers;

  return toPercentage(numOfAssignedWorkers, true, actualValue);
}

export async function convertTargetActualFromPercentageToDecimal(
  actualValue: number,
  workstationId: string,
  shiftId: string | undefined,
) {
  const numOfAssignedWorkers = await workersAssignedInShiftOrWorkstation(workstationId, shiftId);
  if (API.isFailure(numOfAssignedWorkers)) return numOfAssignedWorkers;

  return toDecimal(numOfAssignedWorkers, true, actualValue);
}

async function _copyWorkstationOrOrganizationalUnit(
  workstationOrOrganizationalUnit: API.TreeObject,
  parentId?: string,
): Promise<API.Result<API.TreeObject>> {
  let dataType: API.DataType;
  if (API.isWorkstation(workstationOrOrganizationalUnit)) {
    dataType = API.DataType.WORKSTATION;
  } else if (API.isOrganizationalUnit(workstationOrOrganizationalUnit)) {
    dataType = API.DataType.ORGUNIT;
  } else {
    return API.createFailure_Unspecified(
      'Tree Object dataType not handled yet: ' + JSON.stringify(workstationOrOrganizationalUnit),
    );
  }

  const copyObjectCreateInput = API.deepClone(workstationOrOrganizationalUnit) as
    | API.WorkstationCreateInput
    | API.OrganizationalUnitCreateInput;
  copyObjectCreateInput.parentId = parentId ?? copyObjectCreateInput.parentId;
  copyObjectCreateInput.name =
    copyObjectCreateInput.name + ' ' + t('common:button.aCopy', undefined, false);

  
  delete copyObjectCreateInput.id;
  delete copyObjectCreateInput.pathIds;

  let createdObject: API.TreeObject;
  if (dataType === API.DataType.WORKSTATION) {
    const workstation = await API.createWorkstation(
      copyObjectCreateInput as API.WorkstationCreateInput,
    );
    if (API.isFailure(workstation)) return workstation;

    createdObject = workstation;
  } else {
    const unit = await API.createOrganizationalUnit(
      copyObjectCreateInput as API.OrganizationalUnitCreateInput,
    );
    if (API.isFailure(unit)) return unit;

    createdObject = unit;
  }

  

  return createdObject;
}

export function isWorkstationLevel2AtLeastTargetReached(
  workstationTargetActual: API.WorkstationTargetActual,
  targetInPercentage?: number,
) {
  return (
    (targetInPercentage ?? workstationTargetActual.workersWithLevel2AtLeastActual) >=
    (workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker ?? 0)
  );
}

export function isWorkstationLevel3AtLeastTargetReached(
  workstationTargetActual: API.WorkstationTargetActual,
  targetInPercentage?: number,
) {
  return (
    (targetInPercentage ?? workstationTargetActual.workersWithLevel3AtLeastActual) >=
    (workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker ?? 0)
  );
}

export function isWorkstationLevel4TargetReached(
  actualAndMinimum: API.WorkstationTargetActual,
  targetInPercentage?: number,
) {
  return (
    (targetInPercentage ?? actualAndMinimum.workersWithLevel4Actual) >=
    (actualAndMinimum.workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0)
  );
}

export function isShift2AtLeastTargetReached(
  workstationTargetActual: API.WorkstationTargetActual,
  shiftWorkersTarget: API.ShiftWorkersTarget,
) {
  return (
    workstationTargetActual.workersWithLevel2AtLeastActual >=
    (shiftWorkersTarget.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker ?? 0)
  );
}

export function isShift3AtLeastTargetReached(
  workstationTargetActual: API.WorkstationTargetActual,
  shiftWorkersTarget: API.ShiftWorkersTarget,
) {
  return (
    workstationTargetActual.workersWithLevel3AtLeastActual >=
    (shiftWorkersTarget.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker ?? 0)
  );
}

export function isShift4TargetReached(
  actualAndMinimum: API.WorkstationTargetActual,
  shiftWorkersTarget: API.ShiftWorkersTarget,
) {
  return (
    actualAndMinimum.workersWithLevel3AtLeastActual >=
    (shiftWorkersTarget.workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0)
  );
}

export interface WorkstationsTargetAndWorkerAlert {
  isWorkersOK: boolean;
  isTargetOK: boolean;
  workstationsTargetAndActual: WorkstationsTargetActual;
}

interface WorkstationTargetAndWorkerAlert {
  isWorkersOK: boolean;
  isTargetOK: boolean;
  workstationTargetAndActual: WorkstationTargetActual;
}

/**
 * This function accepts a list of workstations and return the sum of targets of their workstations.
 * If the workstation contains shiftIds it will ignore the workstation target and calculate the sum of shift targets.
 * @param workstations
 * @param workstationTargetsStartingAtLevel
 */
export async function fetchWorkstationsTargetAndWorkersAlert(
  workstations: API.Workstation[],
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  optimizeOperation: boolean = false,
): Promise<API.Result<WorkstationTargetAndWorkerAlert>> {
  let workstationTargetAndActual: WorkstationTargetActual = {
    workersWithLevel1AtLeastActual: 0,
    workersWithLevel2AtLeastActual: 0,
    workersWithLevel3AtLeastActual: 0,
    workersWithLevel4Actual: 0,
    workersWithLevel2AtLeastWorkstationTarget: {
      minNumberOfWorker: 0,
      idealNumberOfWorker: 0,
    },
    workersWithLevel3AtLeastWorkstationTarget: {
      minNumberOfWorker: 0,
      idealNumberOfWorker: 0,
    },
    workersWithLevel4WorkstationTarget: {
      minNumberOfWorker: 0,
      idealNumberOfWorker: 0,
    },
  };
  let isWorkersOK = true;
  let isTargetOK = true;

  await Aigle.map(workstations, async workstation => {
    const shiftIds = workstation.shiftIds;

    if (optimizeOperation) await wait(lowPerformaceIterationTimeout);
    const _workstationTarget = await API.fetchWorkstationTargetAndWorkersAlert(
      workstation,
      workstationTargetsStartingAtLevel,
      API.deepClone(shiftIds) ?? undefined,
      true,
      API.TargetUnit.DECIMAL,
    );
    if (API.isFailure(_workstationTarget)) {
      logger.warn(_workstationTarget);
      return _workstationTarget;
    }

    if (!_workstationTarget.isWorkersOK && isWorkersOK) {
      isWorkersOK = false;
    }
    if (!_workstationTarget.isTargetOK && isTargetOK) {
      isTargetOK = false;
    }

    workstationTargetAndActual.workersWithLevel1AtLeastActual =
      (workstationTargetAndActual.workersWithLevel1AtLeastActual ?? 0) +
      (_workstationTarget.workstationsTargetAndActual[workstation.id]
        .workersWithLevel1AtLeastActual ?? 0);

    workstationTargetAndActual.workersWithLevel2AtLeastActual =
      workstationTargetAndActual.workersWithLevel2AtLeastActual +
      _workstationTarget.workstationsTargetAndActual[workstation.id].workersWithLevel2AtLeastActual;

    workstationTargetAndActual.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker =
      (workstationTargetAndActual.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker ??
        0) +
      (_workstationTarget.workstationsTargetAndActual[workstation.id]
        .workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker ?? 0);

    workstationTargetAndActual.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker =
      (workstationTargetAndActual.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker ??
        0) +
      (_workstationTarget.workstationsTargetAndActual[workstation.id]
        .workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker ?? 0);

    workstationTargetAndActual.workersWithLevel3AtLeastActual =
      workstationTargetAndActual.workersWithLevel3AtLeastActual +
      _workstationTarget.workstationsTargetAndActual[workstation.id].workersWithLevel3AtLeastActual;

    workstationTargetAndActual.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker =
      (workstationTargetAndActual.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker ??
        0) +
      (_workstationTarget.workstationsTargetAndActual[workstation.id]
        .workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker ?? 0);

    workstationTargetAndActual.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker =
      (workstationTargetAndActual.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker ??
        0) +
      (_workstationTarget.workstationsTargetAndActual[workstation.id]
        .workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker ?? 0);

    workstationTargetAndActual.workersWithLevel4Actual =
      workstationTargetAndActual.workersWithLevel4Actual +
      _workstationTarget.workstationsTargetAndActual[workstation.id].workersWithLevel4Actual;

    workstationTargetAndActual.workersWithLevel4WorkstationTarget.idealNumberOfWorker =
      (workstationTargetAndActual.workersWithLevel4WorkstationTarget.idealNumberOfWorker ?? 0) +
      (_workstationTarget.workstationsTargetAndActual[workstation.id]
        .workersWithLevel4WorkstationTarget.idealNumberOfWorker ?? 0);

    workstationTargetAndActual.workersWithLevel4WorkstationTarget.minNumberOfWorker =
      (workstationTargetAndActual.workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0) +
      (_workstationTarget.workstationsTargetAndActual[workstation.id]
        .workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0);
  });

  return {
    isTargetOK,
    isWorkersOK,
    workstationTargetAndActual,
  };
}

function computeWorkstationWorkersLevelCount(
  workstation: API.Workstation,
  workerIds: Set<string>,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
): ComputeWorkstationWorkersLevelCount {
  let level1AndAboveCount = 0;
  let level2AndAboveCount = 0;
  let level3AndAboveCount = 0;
  let level4Count = 0;
  let _isWorkersOK = true;

  workerIds.forEach(workerId => {
    const _workerWorkstation = getWorkerWorkstations(workstation.id, workerId);
    if (_workerWorkstation) {
      if (_workerWorkstation.level) {
        if (
          API.api2workstationWorkerLevels(_workerWorkstation.level) ===
          API.WorkstationWorkerLevels.LEVEL4
        ) {
          level1AndAboveCount++;
          level2AndAboveCount++;
          level3AndAboveCount++;
          level4Count++;
        }

        if (
          API.api2workstationWorkerLevels(_workerWorkstation.level) ===
          API.WorkstationWorkerLevels.LEVEL3
        ) {
          level3AndAboveCount++;
          level2AndAboveCount++;
          level1AndAboveCount++;
        }

        if (
          API.api2workstationWorkerLevels(_workerWorkstation.level) ===
          API.WorkstationWorkerLevels.LEVEL2
        ) {
          level2AndAboveCount++;
          level1AndAboveCount++;
        }

        if (
          API.api2workstationWorkerLevels(_workerWorkstation.level) ===
          API.WorkstationWorkerLevels.LEVEL1
        ) {
          level1AndAboveCount++;
        }
      }

      if (
        API.isWorkerSkilledOrPlannedToBeSkilled(
          _workerWorkstation,
          workstationTargetsStartingAtLevel,
        ) &&
        (_workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRED ||
          _workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON)
      ) {
        _isWorkersOK = false;
      }
    }
  });

  return {
    level1AndAboveCount,
    level2AndAboveCount,
    level3AndAboveCount,
    level4Count,
    isWorkerOK: _isWorkersOK,
  };
}

/**
 * This function is to return the targets for workstation and shifts.
 * If we pass shiftIds, it will provide target for each shifts not workstation
 * If we enable the option needSumOfShiftTargets, it will return the sum of shift targets
 */
export async function fetchWorkstationTargetAndWorkersAlert(
  workstation: API.Workstation,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  shiftIds?: string[],
  needSumOfShiftTargets?: boolean,
  convertTo?: TargetUnit,
  filterData?: {
    workers?: API.Worker[];
    assignments?: Map<string, API.Assignment[]>;
  },
): Promise<API.Result<WorkstationsTargetAndWorkerAlert>> {
  const result: WorkstationsTargetAndWorkerAlert = {
    isTargetOK: false,
    isWorkersOK: false,
    workstationsTargetAndActual: {},
  };
  let workers: API.Worker[];
  if (!filterData?.workers) {
    if (API.enableGlobalLevelComputation) {
      const _workers = await API.getWorkers([API.Permission.workerIsOperational]);
      if (API.isFailure(_workers)) {
        logger.warn(_workers);
        return _workers;
      }
      workers = _workers.result;
    } else {
      const _workers = await API.getWorkersInOrganizationalUnits(
        [workstation.parentId],
        [API.Permission.workerIsOperational],
      );
      if (API.isFailure(_workers)) {
        logger.warn(_workers);
        return _workers;
      }
      workers = _workers.result;
    }
  } else {
    workers = filterData.workers;
  }

  if (shiftIds?.length) {
    let workstationData: WorkstationTargetActual = {
      workersWithLevel1AtLeastActual: 0,
      workersWithLevel2AtLeastActual: 0,
      workersWithLevel3AtLeastActual: 0,
      workersWithLevel4Actual: 0,
      workersWithLevel2AtLeastWorkstationTarget: {
        minNumberOfWorker: 0,
        idealNumberOfWorker: 0,
      },
      workersWithLevel3AtLeastWorkstationTarget: {
        minNumberOfWorker: 0,
        idealNumberOfWorker: 0,
      },
      workersWithLevel4WorkstationTarget: {
        minNumberOfWorker: 0,
        idealNumberOfWorker: 0,
      },
    };

    let isTargetOk = true;
    let numOfAssignedWorkersOnShifts: number = 0;

    let filteredAssignments: API.Result<Map<string, API.Assignment[]>> | undefined =
      filterData?.assignments;
    if (!filteredAssignments) {
      filteredAssignments = await API.getWorkersAssignments(
        true,
        false,
        workers.map(worker => worker.id),
      );
      if (API.isFailure(filteredAssignments)) return filteredAssignments;
    }

    await Aigle.mapSeries(shiftIds, async shiftId => {
      const _workersTargetOnShift = API.extractWORKSTATIONWorkersTargetOnShift(
        workstation,
        shiftId,
      );

      const workerIdsInShift = new Set<string>();
      Array.from(filteredAssignments.entries()).forEach(([workerId, value]) => {
        value.forEach(_inheritedOrNonInheritedAssignment => {
          if (_inheritedOrNonInheritedAssignment.inherited) return;

          if (
            _inheritedOrNonInheritedAssignment.shift &&
            _inheritedOrNonInheritedAssignment.shift.id === shiftId
          ) {
            workerIdsInShift.add(workerId);
          } else {
            const node = API.Tree.getTreeNode<API.DataType.ORGUNIT>(
              _inheritedOrNonInheritedAssignment.organizationalUnitId,
            );
            if (API.isFailure(node)) {
              logger.error('Failed to fetch OrgUnit ', node);
            } else {
              node.object.shiftIds?.forEach(nodeShiftId => {
                if (nodeShiftId === shiftId) {
                  workerIdsInShift.add(workerId);
                }
              });
            }
          }
        });
      });

      numOfAssignedWorkersOnShifts += workerIdsInShift.size;

      const {
        level1AndAboveCount,
        level2AndAboveCount,
        level3AndAboveCount,
        level4Count,
        isWorkerOK,
      } = computeWorkstationWorkersLevelCount(
        workstation,
        workerIdsInShift,
        workstationTargetsStartingAtLevel,
      );

      result.isWorkersOK = isWorkerOK;
      if (needSumOfShiftTargets) {
        if (convertTo === TargetUnit.DECIMAL) {
          const _decimalTargets = await convertAllTargetsFromPercentageToDecimal(
            {
              ...workstationData,
              workersWithLevel2AtLeastWorkstationTarget: _workersTargetOnShift
                ? _workersTargetOnShift.workersWithLevel2AtLeastWorkstationTarget
                : { minNumberOfWorker: 0, idealNumberOfWorker: 0 },
              workersWithLevel3AtLeastWorkstationTarget: _workersTargetOnShift
                ? _workersTargetOnShift.workersWithLevel3AtLeastWorkstationTarget
                : { minNumberOfWorker: 0, idealNumberOfWorker: 0 },
              workersWithLevel4WorkstationTarget: _workersTargetOnShift
                ? _workersTargetOnShift.workersWithLevel4WorkstationTarget
                : { minNumberOfWorker: 0, idealNumberOfWorker: 0 },
            },
            workstation.id,
            shiftId,
            true,
          );
          if (API.isFailure(_decimalTargets)) return _decimalTargets;

          workstationData.workersWithLevel1AtLeastActual =
            (workstationData.workersWithLevel1AtLeastActual ?? 0) + level1AndAboveCount;

          workstationData.workersWithLevel2AtLeastWorkstationTarget.isPercentage =
            _decimalTargets.workersWithLevel2AtLeastWorkstationTarget?.isPercentage;
          workstationData.workersWithLevel2AtLeastActual =
            workstationData.workersWithLevel2AtLeastActual + level2AndAboveCount;

          workstationData.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker =
            (workstationData.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0) +
            (_decimalTargets.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0);
          workstationData.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker =
            (workstationData.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker ?? 0) +
            (_decimalTargets.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker ?? 0);

          workstationData.workersWithLevel3AtLeastWorkstationTarget.isPercentage =
            _decimalTargets.workersWithLevel3AtLeastWorkstationTarget?.isPercentage;
          workstationData.workersWithLevel3AtLeastActual =
            workstationData.workersWithLevel3AtLeastActual + level3AndAboveCount;
          workstationData.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker =
            (workstationData.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0) +
            (_decimalTargets.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0);
          workstationData.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker =
            (workstationData.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker ?? 0) +
            (_decimalTargets.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker ?? 0);

          workstationData.workersWithLevel4WorkstationTarget.isPercentage =
            _decimalTargets.workersWithLevel4WorkstationTarget?.isPercentage;
          workstationData.workersWithLevel4Actual =
            workstationData.workersWithLevel4Actual + level4Count;
          workstationData.workersWithLevel4WorkstationTarget.idealNumberOfWorker =
            (workstationData.workersWithLevel4WorkstationTarget?.idealNumberOfWorker ?? 0) +
            (_decimalTargets.workersWithLevel4WorkstationTarget?.idealNumberOfWorker ?? 0);
          workstationData.workersWithLevel4WorkstationTarget.minNumberOfWorker =
            (workstationData.workersWithLevel4WorkstationTarget?.minNumberOfWorker ?? 0) +
            (_decimalTargets.workersWithLevel4WorkstationTarget?.minNumberOfWorker ?? 0);

          const _isWorkstationLevelTargetReached = await isWorkstationLevelTargetReached(
            workstationData,
            workstationTargetsStartingAtLevel,
            workstation.id,
            shiftId,
          );
          if (API.isFailure(_isWorkstationLevelTargetReached))
            return _isWorkstationLevelTargetReached;

          if (!_isWorkstationLevelTargetReached && isTargetOk) {
            isTargetOk = false;
          }
        } else if (convertTo === TargetUnit.PERCENTAGE) {
          workstationData.workersWithLevel1AtLeastActual =
            (workstationData.workersWithLevel1AtLeastActual ?? 0) + level1AndAboveCount;

          workstationData.workersWithLevel2AtLeastWorkstationTarget.isPercentage =
            _workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.isPercentage ?? true;

          workstationData.workersWithLevel2AtLeastActual =
            workstationData.workersWithLevel2AtLeastActual + level2AndAboveCount;

          workstationData.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker =
            (workstationData.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0) +
            (_workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget
              ?.idealNumberOfWorker ?? 0);
          workstationData.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker =
            (workstationData.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker ?? 0) +
            (_workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker ??
              0);

          workstationData.workersWithLevel3AtLeastWorkstationTarget.isPercentage =
            _workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.isPercentage ?? true;

          workstationData.workersWithLevel3AtLeastActual =
            workstationData.workersWithLevel3AtLeastActual + level3AndAboveCount;

          workstationData.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker =
            (workstationData.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0) +
            (_workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget
              ?.idealNumberOfWorker ?? 0);
          workstationData.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker =
            (workstationData.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker ?? 0) +
            (_workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker ??
              0);

          workstationData.workersWithLevel4WorkstationTarget.isPercentage =
            _workersTargetOnShift?.workersWithLevel4WorkstationTarget?.isPercentage ?? true;

          workstationData.workersWithLevel4Actual =
            workstationData.workersWithLevel4Actual + level4Count;

          workstationData.workersWithLevel4WorkstationTarget.idealNumberOfWorker =
            (workstationData.workersWithLevel4WorkstationTarget?.idealNumberOfWorker ?? 0) +
            (_workersTargetOnShift?.workersWithLevel4WorkstationTarget?.idealNumberOfWorker ?? 0);
          workstationData.workersWithLevel4WorkstationTarget.minNumberOfWorker =
            (workstationData.workersWithLevel4WorkstationTarget?.minNumberOfWorker ?? 0) +
            (_workersTargetOnShift?.workersWithLevel4WorkstationTarget?.minNumberOfWorker ?? 0);
        }
      } else {
        result.workstationsTargetAndActual[shiftId] = {
          workersWithLevel1AtLeastActual: level1AndAboveCount,

          workersWithLevel2AtLeastActual: level2AndAboveCount,
          workersWithLevel2AtLeastWorkstationTarget: {
            minNumberOfWorker:
              _workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker,
            idealNumberOfWorker:
              _workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker,
            isPercentage:
              _workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.isPercentage,
          },

          workersWithLevel3AtLeastActual: level3AndAboveCount,
          workersWithLevel3AtLeastWorkstationTarget: {
            minNumberOfWorker:
              _workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker,
            idealNumberOfWorker:
              _workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker,
            isPercentage:
              _workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.isPercentage,
          },

          workersWithLevel4Actual: level4Count,
          workersWithLevel4WorkstationTarget: {
            minNumberOfWorker:
              _workersTargetOnShift?.workersWithLevel4WorkstationTarget?.minNumberOfWorker,
            idealNumberOfWorker:
              _workersTargetOnShift?.workersWithLevel4WorkstationTarget?.idealNumberOfWorker,
            isPercentage: _workersTargetOnShift?.workersWithLevel4WorkstationTarget?.isPercentage,
          },
        };

        const _isWorkstationLevelTargetReached = await isWorkstationLevelTargetReached(
          result.workstationsTargetAndActual[shiftId],
          workstationTargetsStartingAtLevel,
          workstation.id,
          shiftId,
        );
        if (API.isFailure(_isWorkstationLevelTargetReached))
          return _isWorkstationLevelTargetReached;

        if (!_isWorkstationLevelTargetReached && isTargetOk) {
          isTargetOk = false;
        }

        switch (convertTo) {
          case TargetUnit.PERCENTAGE:
            const _percentageTargets = await convertAllTargetsFromDecimalToPercentage(
              result.workstationsTargetAndActual[shiftId],
              workstation.id,
              shiftId,
              true,
            );
            if (API.isFailure(_percentageTargets)) return _percentageTargets;

            result.workstationsTargetAndActual[shiftId] = _percentageTargets;
            break;

          case TargetUnit.DECIMAL:
            const _decimalTargets = await convertAllTargetsFromPercentageToDecimal(
              result.workstationsTargetAndActual[shiftId],
              workstation.id,
              shiftId,
              true,
            );
            if (API.isFailure(_decimalTargets)) return _decimalTargets;

            result.workstationsTargetAndActual[shiftId] = _decimalTargets;
            break;
        }
      }
    });

    if (needSumOfShiftTargets) {
      if (convertTo === TargetUnit.PERCENTAGE) {
        const _percentageTargets = await convertAllTargetsFromDecimalToPercentage(
          workstationData,
          workstation.id,
          undefined,
          true,
          numOfAssignedWorkersOnShifts,
        );
        if (API.isFailure(_percentageTargets)) return _percentageTargets;

        _percentageTargets.workersWithLevel2AtLeastActual =
          _percentageTargets.workersWithLevel2AtLeastActual > 100
            ? 100
            : _percentageTargets.workersWithLevel2AtLeastActual;

        _percentageTargets.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker =
          (_percentageTargets.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker ?? 0) >
          100
            ? 100
            : _percentageTargets.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker;

        _percentageTargets.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker =
          (_percentageTargets.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0) >
          100
            ? 100
            : _percentageTargets.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker;

        _percentageTargets.workersWithLevel3AtLeastActual =
          _percentageTargets.workersWithLevel3AtLeastActual > 100
            ? 100
            : _percentageTargets.workersWithLevel3AtLeastActual;

        _percentageTargets.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker =
          (_percentageTargets.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker ?? 0) >
          100
            ? 100
            : _percentageTargets.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker;

        _percentageTargets.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker =
          (_percentageTargets.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker ?? 0) >
          100
            ? 100
            : _percentageTargets.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker;

        _percentageTargets.workersWithLevel4Actual =
          _percentageTargets.workersWithLevel4Actual > 100
            ? 100
            : _percentageTargets.workersWithLevel4Actual;

        _percentageTargets.workersWithLevel4WorkstationTarget.minNumberOfWorker =
          (_percentageTargets.workersWithLevel4WorkstationTarget?.minNumberOfWorker ?? 0) > 100
            ? 100
            : _percentageTargets.workersWithLevel4WorkstationTarget.minNumberOfWorker;

        _percentageTargets.workersWithLevel4WorkstationTarget.idealNumberOfWorker =
          (_percentageTargets.workersWithLevel4WorkstationTarget?.idealNumberOfWorker ?? 0) > 100
            ? 100
            : _percentageTargets.workersWithLevel4WorkstationTarget.idealNumberOfWorker;

        result.workstationsTargetAndActual[workstation.id] = _percentageTargets;

        const _isWorkstationLevelTargetReached = await isWorkstationLevelTargetReached(
          _percentageTargets,
          workstationTargetsStartingAtLevel,
          workstation.id,
          undefined,
        );
        if (API.isFailure(_isWorkstationLevelTargetReached))
          return _isWorkstationLevelTargetReached;

        if (!_isWorkstationLevelTargetReached && isTargetOk) {
          isTargetOk = false;
        }
      } else {
        result.workstationsTargetAndActual[workstation.id] = workstationData;
      }
    }

    result.isTargetOK = isTargetOk;
  } else {
    const {
      level1AndAboveCount,
      level2AndAboveCount,
      level3AndAboveCount,
      level4Count,
      isWorkerOK,
    } = computeWorkstationWorkersLevelCount(
      workstation,
      new Set(workers.map(worker => worker.id)),
      workstationTargetsStartingAtLevel,
    );

    const _workersTargetOnShift = API.extractWORKSTATIONWorkersTargetOnShift(workstation);
    result.isWorkersOK = isWorkerOK;
    result.workstationsTargetAndActual[workstation.id] = {
      workersWithLevel1AtLeastActual: level1AndAboveCount,

      workersWithLevel2AtLeastActual: level2AndAboveCount,
      workersWithLevel2AtLeastWorkstationTarget: {
        minNumberOfWorker:
          _workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker,
        idealNumberOfWorker:
          _workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker,
        isPercentage:
          _workersTargetOnShift?.workersWithLevel2AtLeastWorkstationTarget?.isPercentage,
      },

      workersWithLevel3AtLeastActual: level3AndAboveCount,
      workersWithLevel3AtLeastWorkstationTarget: {
        minNumberOfWorker:
          _workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker,
        idealNumberOfWorker:
          _workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker,
        isPercentage:
          _workersTargetOnShift?.workersWithLevel3AtLeastWorkstationTarget?.isPercentage,
      },

      workersWithLevel4Actual: level4Count,
      workersWithLevel4WorkstationTarget: {
        minNumberOfWorker:
          _workersTargetOnShift?.workersWithLevel4WorkstationTarget?.minNumberOfWorker,
        idealNumberOfWorker:
          _workersTargetOnShift?.workersWithLevel4WorkstationTarget?.idealNumberOfWorker,
        isPercentage: _workersTargetOnShift?.workersWithLevel4WorkstationTarget?.isPercentage,
      },
    };

    const _isWorkstationLevelTargetReacted = await isWorkstationLevelTargetReached(
      result.workstationsTargetAndActual[workstation.id],
      workstationTargetsStartingAtLevel,
      workstation.id,
      undefined,
    );
    if (API.isFailure(_isWorkstationLevelTargetReacted)) return _isWorkstationLevelTargetReacted;

    switch (convertTo) {
      case TargetUnit.PERCENTAGE:
        const _percentageTargets = await convertAllTargetsFromDecimalToPercentage(
          result.workstationsTargetAndActual[workstation.id],
          workstation.id,
          undefined,
          true,
        );
        if (API.isFailure(_percentageTargets)) return _percentageTargets;

        result.workstationsTargetAndActual[workstation.id] = _percentageTargets;
        break;

      case TargetUnit.DECIMAL:
        const _decimalTargets = await convertAllTargetsFromPercentageToDecimal(
          result.workstationsTargetAndActual[workstation.id],
          workstation.id,
          undefined,
          true,
        );
        if (API.isFailure(_decimalTargets)) return _decimalTargets;

        result.workstationsTargetAndActual[workstation.id] = _decimalTargets;
        break;
      default:
        break;
    }
    result.isTargetOK = _isWorkstationLevelTargetReacted;
  }

  return result;
}

/**
 * Function returns value which determins the workstation's worker count fullfilled or not
 * If its a positive number meaning that the workstation fullfilled and the worker count exceeded.
 * If its a negative number meaning that the workstation needs more number of workers.
 */
function getWorkstationWorkersFullfillCount(
  workstationTargetAndActual: API.WorkstationTargetActual,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
): number {
  let fullfillCount = 0;

  const level2WorkerCount =
    workstationTargetAndActual?.workersWithLevel2AtLeastActual -
    (workstationTargetAndActual.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker ?? 0);
  const level3WorkerCount =
    workstationTargetAndActual?.workersWithLevel3AtLeastActual -
    (workstationTargetAndActual.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker ?? 0);
  const level4WorkerCount =
    workstationTargetAndActual?.workersWithLevel4Actual -
    (workstationTargetAndActual.workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0);

  switch (workstationTargetsStartingAtLevel) {
    case API.WorkstationWorkerLevels.LEVEL2:
      return (fullfillCount = level2WorkerCount + level3WorkerCount + level4WorkerCount);
    case API.WorkstationWorkerLevels.LEVEL3:
      return (fullfillCount = level3WorkerCount + level4WorkerCount);
    case API.WorkstationWorkerLevels.LEVEL4:
      return (fullfillCount = level4WorkerCount);
    default:
      logger.error('Wrong level passed', workstationTargetsStartingAtLevel);
  }

  return fullfillCount;
}

/**
 * returns the workstation with the count of how much workers it has that are over or under the needed amount
 * we are calculating the under and over together so we wont have to do the same computation twice for under and over staffed workstations but this will cause the component to be dependent to each other and they cant be used separately
 * @param workers array
 * @param worksations array
 * @param workstationTargetsStartingAtLevel WorkstationWorkerLevel
 */
export async function getWorkstationsWithOverAndUnderWorkersCount(
  workstations: API.Workstation[],
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  optimizeOperation: boolean = false,
): Promise<API.Result<WorkstationWithOverAndUnderWorkersCount[] | undefined>> {
  const _workstationNameAndStaffCapacityCount: WorkstationWithOverAndUnderWorkersCount[] = [];
  const errors: API.Failure[] = [];

  await Aigle.map(workstations, async workstation => {
    if (optimizeOperation) await wait(lowPerformaceIterationTimeout);

    const workstationTarget = await API.fetchWorkstationTargetAndWorkersAlert(
      workstation,
      workstationTargetsStartingAtLevel,
      workstation.shiftIds ? API.deepClone(workstation.shiftIds) : undefined,
      true,
      TargetUnit.DECIMAL,
    );
    if (API.isFailure(workstationTarget)) {
      errors.push(workstationTarget);
      return workstationTarget;
    }

    const workstationTargetAndActual =
      workstationTarget.workstationsTargetAndActual[workstation.id];

    const workstationWorkersFullfillCount = getWorkstationWorkersFullfillCount(
      workstationTargetAndActual,
      workstationTargetsStartingAtLevel,
    );

    
    if (workstationWorkersFullfillCount)
      _workstationNameAndStaffCapacityCount.push({
        workstation: workstation,
        overAndUnderWorkersCount: workstationWorkersFullfillCount,
      });
  });

  if (errors.length) return API.createFailure_Multiple(errors);

  return _workstationNameAndStaffCapacityCount;
}

export function validateWorkstationWorkerLevelTargets(input: ShiftWorkersTarget): boolean {
  if (
    input.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker &&
    input.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker &&
    input.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker >
      input.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker
  )
    return false;

  if (
    input.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker &&
    input.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker &&
    input.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker >
      input.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker
  )
    return false;

  if (
    input.workersWithLevel4WorkstationTarget?.minNumberOfWorker &&
    input.workersWithLevel4WorkstationTarget?.idealNumberOfWorker &&
    input.workersWithLevel4WorkstationTarget.minNumberOfWorker >
      input.workersWithLevel4WorkstationTarget.idealNumberOfWorker
  )
    return false;

  return true;
}

export async function getWorkstationsTargetActual(
  workstations: API.Workstation[],
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  shiftId?: string,
  isPercentage?: boolean,
): Promise<API.Result<API.WorkstationTargetActual>> {
  let workers: API.Worker[];

  if (API.enableGlobalLevelComputation) {
    const _workers = await API.getWorkers([API.Permission.workerIsOperational]);
    if (API.isFailure(_workers)) {
      logger.warn(_workers);
      return _workers;
    }
    workers = _workers.result;
  } else {
    const _workers = await API.getWorkersInOrganizationalUnits(
      workstations.map(workstation => workstation.parentId),
      [API.Permission.workerIsOperational],
    );
    if (API.isFailure(_workers)) {
      logger.warn(_workers);
      return _workers;
    }
    workers = _workers.result;
  }

  const actual = API.getWorkersVersatilityAndWorkstationsObjectiveActuals(
    workers,
    workstations,
    workstationTargetsStartingAtLevel,
    shiftId,
  );

  const _workstationTargetActual: WorkstationTargetActual = {
    workersWithLevel1AtLeastActual: 0,
    workersWithLevel2AtLeastActual: 0,
    workersWithLevel3AtLeastActual: 0,
    workersWithLevel4Actual: 0,
    workersWithLevel2AtLeastWorkstationTarget: {
      minNumberOfWorker: 0,
      idealNumberOfWorker: 0,
    },
    workersWithLevel3AtLeastWorkstationTarget: {
      minNumberOfWorker: 0,
      idealNumberOfWorker: 0,
    },
    workersWithLevel4WorkstationTarget: {
      minNumberOfWorker: 0,
      idealNumberOfWorker: 0,
    },
  };

  Object.values(actual.workstationsTargetActual).forEach(workstationTargetActual => {
    _workstationTargetActual.workersWithLevel1AtLeastActual =
      (_workstationTargetActual.workersWithLevel1AtLeastActual ?? 0) +
      (workstationTargetActual.workersWithLevel1AtLeastActual ?? 0);

    _workstationTargetActual.workersWithLevel2AtLeastActual =
      _workstationTargetActual.workersWithLevel2AtLeastActual +
      workstationTargetActual.workersWithLevel2AtLeastActual;

    _workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker =
      (_workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker ?? 0) +
      (workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker ?? 0);

    _workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker =
      (_workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker ??
        0) +
      (workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker ?? 0);

    _workstationTargetActual.workersWithLevel3AtLeastActual =
      _workstationTargetActual.workersWithLevel3AtLeastActual +
      workstationTargetActual.workersWithLevel3AtLeastActual;

    _workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker =
      (_workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker ?? 0) +
      (workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker ?? 0);

    _workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker =
      (_workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker ??
        0) +
      (workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker ?? 0);

    _workstationTargetActual.workersWithLevel4Actual =
      _workstationTargetActual.workersWithLevel4Actual +
      workstationTargetActual.workersWithLevel4Actual;

    _workstationTargetActual.workersWithLevel4WorkstationTarget.idealNumberOfWorker =
      (_workstationTargetActual.workersWithLevel4WorkstationTarget.idealNumberOfWorker ?? 0) +
      (workstationTargetActual.workersWithLevel4WorkstationTarget.idealNumberOfWorker ?? 0);

    _workstationTargetActual.workersWithLevel4WorkstationTarget.minNumberOfWorker =
      (_workstationTargetActual.workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0) +
      (workstationTargetActual.workersWithLevel4WorkstationTarget.minNumberOfWorker ?? 0);
  });

  return _workstationTargetActual;
}

export async function isWorkstationLevelTargetReached(
  workstationTargetActual: API.WorkstationTargetActual,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  workstationId: string,
  shiftId: string | undefined,
): Promise<API.Result<boolean>> {
  let level2ActualValueInPercentage;
  let level3ActualValueInPercentage;
  let level4ActualValueInPercentage;

  if (workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget?.isPercentage) {
    level2ActualValueInPercentage = await API.convertTargetActualFromDecimalToPercentage(
      workstationTargetActual.workersWithLevel2AtLeastActual,
      workstationId,
      shiftId,
    );

    if (API.isFailure(level2ActualValueInPercentage)) {
      logger.warn(level2ActualValueInPercentage);
      return level2ActualValueInPercentage;
    }
  }

  if (workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget?.isPercentage) {
    level3ActualValueInPercentage = await API.convertTargetActualFromDecimalToPercentage(
      workstationTargetActual.workersWithLevel3AtLeastActual,
      workstationId,
      shiftId,
    );

    if (API.isFailure(level3ActualValueInPercentage)) {
      logger.warn(level3ActualValueInPercentage);
      return level3ActualValueInPercentage;
    }
  }

  if (workstationTargetActual.workersWithLevel4WorkstationTarget?.isPercentage) {
    level4ActualValueInPercentage = await API.convertTargetActualFromDecimalToPercentage(
      workstationTargetActual.workersWithLevel4Actual,
      workstationId,
      shiftId,
    );

    if (API.isFailure(level4ActualValueInPercentage)) {
      logger.warn(level4ActualValueInPercentage);
      return level4ActualValueInPercentage;
    }
  }

  switch (workstationTargetsStartingAtLevel) {
    case API.WorkstationWorkerLevels.LEVEL2:
      return (
        isWorkstationLevel2AtLeastTargetReached(
          workstationTargetActual,
          level2ActualValueInPercentage,
        ) &&
        isWorkstationLevel3AtLeastTargetReached(
          workstationTargetActual,
          level3ActualValueInPercentage,
        ) &&
        isWorkstationLevel4TargetReached(workstationTargetActual, level4ActualValueInPercentage)
      );
    case API.WorkstationWorkerLevels.LEVEL3:
      return (
        isWorkstationLevel3AtLeastTargetReached(
          workstationTargetActual,
          level3ActualValueInPercentage,
        ) &&
        isWorkstationLevel4TargetReached(workstationTargetActual, level4ActualValueInPercentage)
      );
    case API.WorkstationWorkerLevels.LEVEL4:
      return isWorkstationLevel4TargetReached(
        workstationTargetActual,
        level4ActualValueInPercentage,
      );
    default:
      false;
  }

  return false;
}





export const shiftColors = [
  '#FDD22F',
  '#49636E',
  '#A7E1FA',
  '#009DEE',
  '#D5EBC3',
  '#51B463',
  '#FFA289',
  '#F45687',
  '#971CA3',
];

export async function createShift(
  shiftInput: API.ShiftCreateInput,
): Promise<API.Result<API.Shift>> {
  const factory = await API.createFactoryBusinessObject(API.DataType.SHIFT, shiftInput);
  if (API.isFailure(factory)) return factory;

  const shift = {
    ...factory.shift,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };

  return shift;
}

export async function updateShift(
  shiftInput: API.ShiftPartialUpdateInput,
): Promise<API.Result<API.Shift>> {
  const factory = await API.updateFactoryBusinessObject(API.DataType.SHIFT, shiftInput);
  if (API.isFailure(factory)) return factory;

  return { ...factory.shift, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function getShift(shiftId: string): Promise<API.Result<API.Shift>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.SHIFT, shiftId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.shift, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function getShifts(): Promise<API.Result<API.Shift[]>> {
  const factories = await API.listFactoriesWithDataType<API.DataType.SHIFT>(API.DataType.SHIFT);
  if (API.isFailure(factories)) return factories;

  return _.map(factories.result, _factory => {
    return { ..._factory.shift, updatedAt: _factory.updatedAt, updatedBy: _factory.updatedBy };
  });
}

/**
 * function that replaces on the assignments on a shift or Unit
 * @param shiftIdToBeReplaced string
 * @param unitOrShiftIdToReplaceWith string
 */
export async function updateUnitOrShiftAssignments(
  unitId: string,
  unitOrShiftIdToReplaceWith: string,
) {
  const workers = await API.getWorkersAssignedToUnit(unitId);
  if (API.isFailure(workers)) return workers;

  for (var worker of workers) {
    const scope = API.extractScopeFromWorker(worker);

    const assignmentKeys = API.getAssignmentKeysOnUnit(unitId, scope);

    let permissions: API.Permission[] = [];
    let roleId: string = '';

    assignmentKeys.forEach(key => {
      const roleAndPermissions = scope.nonInheritedRolesOnOrgUnits[key];
      if (!roleAndPermissions) return;

      permissions = _.unionBy(roleAndPermissions.permissions, permissions);
      roleId = roleAndPermissions.roleId;

      delete scope.nonInheritedRolesOnOrgUnits[key];
    });

    scope.nonInheritedRolesOnOrgUnits[unitOrShiftIdToReplaceWith] = {
      permissions: permissions,
      roleId: roleId,
    };

    await API.updateWorker({
      ...API.deepClone(worker),
      scope: JSON.stringify(scope),
    });
  }
}

/**
 * Get the OrgUnit's Workers Roles
 * @see getWorkersOrganizationalUnitRoles()
 * @param orgUnitId
 * @param includeInherited
 * @param permissions
 * @returns
 */
export async function getOrgUnitWorkersAssignments(
  orgUnitId: string,
  includeInherited: boolean,
  permissions?: API.Permission[],
): Promise<API.Result<API.AssignmentWithUnitDetails[]>> {
  const workersAsssignmentsMap = await API.getWorkersAssignments(
    includeInherited,
    true,
    undefined,
    [orgUnitId],
    permissions,
  );
  if (API.isFailure(workersAsssignmentsMap)) return workersAsssignmentsMap;

  const assignmentsWithUnitDetails: API.AssignmentWithUnitDetails[] = [];
  Array.from(workersAsssignmentsMap.values()).forEach(_indexedAssignments => {
    _indexedAssignments.forEach(_indexedAssignment =>
      assignmentsWithUnitDetails.push(_indexedAssignment),
    );
  });

  return assignmentsWithUnitDetails;
}

/**
 * it gives the highest level a worker can get on a workstation
 */
export async function getHighestReachableLevelOnWorkstation(
  workstationId: string,
): Promise<number | undefined> {
  const requirments = await API.getLevelsRequirementsWithInheritedAndOrDescendent(
    workstationId,
    true,
    false,
  );
  if (API.isFailure(requirments)) {
    logger.warn(requirments);
    return;
  }

  let highestReachableLevel = 0;
  for (const [level, requirment] of requirments) {
    requirment.forEach(_requirment => {
      if (level > highestReachableLevel && _requirment.skillTrainingVersions.length)
        highestReachableLevel = level;
    });
  }

  return highestReachableLevel;
}

export function getUnitChildWorkstationsOrUnits(
  unitId: string,
  dataType: API.DataType.WORKSTATION | API.DataType.ORGUNIT,
  includeNested: boolean = true,
): API.Result<(Immutable<API.Workstation> | Immutable<API.OrganizationalUnit>)[]> {
  const orgUnitChildren = API.Tree.getChildren(unitId, includeNested, dataType);
  if (API.isFailure(orgUnitChildren)) return orgUnitChildren;

  return orgUnitChildren;
}

/**
 * Compute actual targets depending upon isPercentage value
 * @param workstationTargets
 * @param workstationId
 * @param workstationTargetsStartingAtLevel
 * @returns
 */
export async function computeActualValues(
  workstationTargets: API.WorkstationTargetActual,
  workstationId: string,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  shiftId: string | undefined,
): Promise<API.Result<WorkstationTargetValues>> {
  let _level2Actual = workstationTargets.workersWithLevel2AtLeastActual;
  let _level3Actual = workstationTargets.workersWithLevel3AtLeastActual;
  let _level4Actual = workstationTargets.workersWithLevel4Actual;

  if (workstationTargets.workersWithLevel2AtLeastWorkstationTarget.isPercentage) {
    const value = await API.convertTargetActualFromDecimalToPercentage(
      _level2Actual,
      workstationId,
      shiftId,
    );

    if (API.isFailure(value)) {
      logger.warn(value);
      return value;
    }

    _level2Actual = value;
  }

  if (workstationTargets.workersWithLevel3AtLeastWorkstationTarget.isPercentage) {
    const value = await API.convertTargetActualFromDecimalToPercentage(
      _level3Actual,
      workstationId,
      shiftId,
    );

    if (API.isFailure(value)) {
      logger.warn(value);
      return value;
    }

    _level3Actual = value;
  }

  if (workstationTargets.workersWithLevel4WorkstationTarget.isPercentage) {
    const value = await API.convertTargetActualFromDecimalToPercentage(
      _level4Actual,
      workstationId,
      shiftId,
    );

    if (API.isFailure(value)) {
      logger.warn(value);
      return value;
    }

    _level4Actual = value;
  }

  return {
    workersWithLevel2AtLeastActual: _level2Actual,
    workersWithLevel3AtLeastActual: _level3Actual,
    workersWithLevel4Actual: _level4Actual,
  };
}

/**
 * To get the workstation's OrgUnit
 * @param workstationId
 * @returns
 */
export async function getOrgUnitOfWorkstation(
  workstationId: string,
): Promise<API.Result<API.OrganizationalUnit>> {
  const workstation = await API.getWorkstation(workstationId);
  if (API.isFailure(workstation)) {
    logger.warn(workstation);
    return workstation;
  }

  const orgUnit = API.getOrganizationalUnit(workstation.parentId);
  if (API.isFailure(orgUnit)) {
    logger.warn(orgUnit);
    return orgUnit;
  }

  return orgUnit;
}

export async function getWorkersAssignedToUnit(unitId: string): Promise<API.Result<API.Worker[]>> {
  const workersAssignedToUnitOrShift: API.Worker[] = [];

  const workers = await API.getWorkers();
  if (API.isFailure(workers)) return workers;

  workers.result.forEach(worker => {
    const scope = API.extractScopeFromWorker(worker);

    const assignmentKeys = API.getAssignmentKeysOnUnit(unitId, scope);
    if (assignmentKeys.length) workersAssignedToUnitOrShift.push(worker);
  });

  return workersAssignedToUnitOrShift;
}
