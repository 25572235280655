import { StyleSheet } from 'react-native';
import { Colors, createRoundedStyle, Spacings, TextFontStyles } from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: Colors.White,
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: 12,
    color: Colors.Grey,
    ...TextFontStyles.LatoRegular,
  },
  dropdownContainer: {
    backgroundColor: Colors.White,
    borderRadius: Spacings.Small,
    shadowColor: Colors.Black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    position: 'absolute',
    width: '100%',
    top: Spacings.Medium,
  },
  tagContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 6,
  },
  border: {
    borderBottomColor: Colors.Background,
    flexDirection: 'row',
  },
  label: {
    height: Spacings.Standard,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: Spacings.Small,
  },
  noResultContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 60,
  },
  noResult: {
    color: Colors.Grey,
    fontSize: 14,
    ...TextFontStyles.LatoRegular,
  },
  inputText: {
    flex: 1,
    padding: Spacings.Small,
    paddingLeft: 0,
    placeholderTextColor: Colors.Grey,
    borderBottomWidth: 1,
    borderColor: '#D6D6D6',
    color: Colors.DarkBlack,
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
  },
  tagView: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 100,
    paddingLeft: Spacings.Small,
    paddingRight: Spacings.Unit,
    paddingVertical: Spacings.Unit,
    backgroundColor: Colors.Background,
    marginLeft: Spacings.Small,
    marginVertical: Spacings.Unit,
    height: Spacings.Standard,
  },
  tagText: {
    color: Colors.Black,
    fontWeight: '500',
    marginLeft: 5,
    fontSize: 12,
  },
  autoCompleteMenu: {
    width: '90%',
    backgroundColor: Colors.White,
    borderTopColor: Colors.Transparent,
    borderWidth: 1,
    borderColor: Colors.Background,
  },
  addItemContainer: { width: '100%', paddingLeft: 4, paddingBottom: 8, paddingTop: 12 },
  suggestionText: {
    padding: 3,
    color: Colors.Black,
  },
  addItemTextStyle: { fontFamily: 'Lato, Regular', fontWeight: 'normal', fontSize: 14 },
  itemTextStyle: { fontFamily: 'Lato, Black', fontWeight: '800' },
  highlight: {
    ...TextFontStyles.LatoBlack,
  },
  iconContainer: {
    flexDirection: 'row',
    marginLeft: -Spacings.Small,
  },
  circleView: {
    ...createRoundedStyle(6),
    backgroundColor: Colors.Yellow,
  },
  autoCompleteStyle: {
    paddingLeft: Spacings.Medium,
    paddingVertical: Spacings.Small,
  },
  disabled: {
    borderBottomWidth: 0,
  },
});

export default styles;
