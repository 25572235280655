import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  outerContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  rowFlexView: {
    flexDirection: 'row',
  },
  scrollViewContainer: {
    flex: 1,
    backgroundColor: Colors.LightBlue,
  },
  sideBarContainer: {
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    zIndex: 10,
    alignItems: 'flex-start',
    backgroundColor: Colors.BlackGrey,
  },
  touchableSideBarContainer: {
    paddingTop: Spacings.Small + Spacings.Unit,
    paddingLeft: 13,
    height: '100%',
    width: '100%',
  },
  navBurgerContainer: {
    width: '100%',
    height: Spacings.Standard,
    paddingLeft: 3,
    flexDirection: 'row',
  },
  navBurgerTouchable: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
  navBurgerSVG: { width: Spacings.Standard, height: Spacings.Standard },
  tenantAppTextContainer: {
    flex: 1,
    paddingLeft: Spacings.Standard - Spacings.Unit,
    paddingRight: Spacings.Unit,
    height: '100%',
    zIndex: 1,
  },
  tenantAppTextStyle: {
    height: 27,
    color: Colors.White,
    fontSize: 22,
    fontFamily: 'Lato, Black',
    fontWeight: '900',
  },
  toolTiptenantAppTextStyle: {
    height: 'auto',
    color: Colors.Black,
  },
  routeKeysContainer: {
    paddingTop: 36,
    height: '52%',
    zIndex: 0,
  },
  footerIconContainer: {
    width: '100%',
    height: 37,
    position: 'absolute',
    bottom: 12,
    paddingLeft: 4,
    flexDirection: 'row',
    paddingRight: 20,
    justifyContent: 'space-between',
  },
  footerIconInnerContainer: {
    height: 32,
    marginTop: 1,
    marginBottom: 1,
    alignItems: 'center',
    alignContent: 'center',
  },
  footerIconSVG: {
    height: 24,
    width: 24,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  footerIconText: {
    color: Colors.White,
    fontSize: fontSize.Small,
    letterSpacing: 0.24,
    ...TextFontStyles.LatoBold,
  },
  routeKeyOuterContainer: { paddingTop: 14, paddingBottom: 14 },
  routeKeyTouchable: {
    height: 30,
    width: 30,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  routeKeyTextContainer: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  routeKeyTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    paddingLeft: 17,
    opacity: 1,
  },
  routeKeyNumberInnerContainer: {
    height: Spacings.Small * 2,
    borderRadius: Spacings.Small * 2,
    paddingHorizontal: 6,
    paddingVertical: Spacings.Unit,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Grey,
  },
  routeKeyNumberText: {
    ...TextFontStyles.LatoBlack,
    fontSize: 11,
    textAlign: 'center',
  },
  subRouteTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;
