import React, { useState } from 'react';
import { Platform, View, ActivityIndicator, StyleProp, ViewStyle } from 'react-native';
import WebModal from 'modal-react-native-web';
import Styles from './Styles';
import { useIsMounted } from '../../hooks/IsMounted';
import Modal from 'react-native-modal';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const MobileModalOpacity = 0.5;
export const Loader: React.FC<Props> = props => {
  const { style } = props;
  const [modalVisible, setModalVisible] = useState(true);
  const isMounted = useIsMounted();

  if (Platform.OS === 'web') {
    return (
      <WebModal
        animationType={isMounted.current ? 'fade' : undefined}
        transparent
        visible={modalVisible}
        onRequestClose={() => {
          if (isMounted.current) setModalVisible(true);
        }}
      >
        <View style={[Styles.loaderContainer, style]}>
          <ActivityIndicator color="#FCD200" size="large" />
        </View>
      </WebModal>
    );
  } else {
    return (
      <Modal
        style={{ margin: 0 }}
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={modalVisible}
        onBackdropPress={() => {
          if (isMounted.current) setModalVisible(true);
        }}
        backdropOpacity={MobileModalOpacity}
      >
        <View style={Styles.loaderContainer}>
          <ActivityIndicator color="#FCD200" size="large" />
        </View>
      </Modal>
    );
  }
};
