import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView, GestureResponderEvent } from 'react-native';
import styles from './components/style';
import { t } from 'shared/localisation/i18n';
import { TreeNodeMenu, MenuType } from './components/node-menu/container/index';
import { maxModalHeight } from './components/node-menu/component/style';
import { AppContext } from 'shared/context/AppContext';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import ReactDOM from 'react-dom';
import { Spacings } from 'shared/styles';
import { TreeNodeComponent, DragReferenceObject } from './components/TreeNodeComponent';
import logger from 'shared/util/Logger';
import { MyHub } from 'shared/util/MyHub';
import * as _ from 'lodash-es';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TreeNode, NodeState } from 'shared/backend-data/factoryCache/Tree';
import { ActivityIndicator } from 'shared/ui-component/Loader/ActivityIndicator';
import { ShadowOnHoverButton } from 'shared/ui-component/Button';
import { Colors } from 'shared/styles/Colors';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { DropdownConfigKey, extractFilterTags } from '../../../header-layout/headerFilterConfig';
import { isArrayEmpty } from 'shared/util-ts/Functions';
import { useParams } from 'react-router-dom';
import { ProfileRouteParam } from '../../../navigation/Routes';

export enum LoaderTrigger {
  duplicateNode,
}

export const WorkstationTreePanel: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuType, setMenuType] = useState<MenuType>(MenuType.AddOptionsMenu);
  
  const [loading, setLoading] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [defaultOrgUnit, setDefaultOrgUnit] = useState<API.OrganizationalUnit>();
  const [topTreeNodes, setTopTreeNodes] = useState<TreeNode<API.DataType.ORGUNIT>[]>([]);
  const [filteredTreeNodes, setFilteredTreeNodes] = useState<TreeNode[]>([]);
  const [yCoordinate, setYCoordinate] = useState(0);
  const [nodeStates, setNodeStates] = useState<Map<string, NodeState>>(
    new Map<string, NodeState>(),
  );
  const [selectedMenuTreeNode, setSelectedMenuTreeNode] = useState<TreeNode>();
  const [selectedWorkstation, setSelectedWorkstation] = useState<string>();
  const { id } = useParams<ProfileRouteParam>();

  const {
    treeNode: [treeNode, setTreeNode],
    scrollPosition: [lastScrollPosition, setLastScrollPosition],
  } = useContext(MenuFactoryContext);
  const {
    workstationScreenFilterTags: [workstationScreenFilterTags],
  } = useContext(HeaderFilterContext);

  const refView = React.useRef<View>(null);
  const refScrollView = React.useRef<ScrollView>(null);
  const refScrollPosition = React.useRef<number>();

  const isMounted = useIsMounted();

  const { isValidPermission } = useContext(PermissionManagementContext);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('TreeStructureUpdate', () => {
      _loadTreeData();
    });

    _loadTreeData();

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    const filterTags = extractFilterTags(workstationScreenFilterTags);
    filterTree(filterTags);
  }, [workstationScreenFilterTags, topTreeNodes]);

  useEffect(() => {
    return () => {
      if (nodeStates.size) saveTreeState();
    };
  }, [nodeStates]);

  useEffect(() => {
    refScrollPosition.current = lastScrollPosition;
  }, [lastScrollPosition]);

  useEffect(() => {
    if (id) setSelectedWorkstation(id);
  }, [id]);

  useEffect(() => {
    return () => {
      if (refScrollView.current !== undefined && refScrollPosition.current !== undefined) {
        setLastScrollPosition(refScrollPosition.current);
      }
    };
  }, []);

  async function _loadTreeData() {
    const loadResult = await loadTreeData();
    if (!isMounted.current) return;
    if (API.isFailure(loadResult)) {
      logger.error('Failed to loadTree data', loadResult);
    }
  }

  function findChildTreeNode(
    firstTreeNode: TreeNode,
    secondTreeNode: TreeNode,
  ): TreeNode | undefined {
    if (secondTreeNode.parentIds.includes(firstTreeNode.id)) {
      return secondTreeNode;
    }
    if (firstTreeNode.parentIds.includes(secondTreeNode.id)) {
      return firstTreeNode;
    }

    return undefined;
  }

  function isWorkstationAvailableInTree(treeNode: TreeNode[], workstationId: string): boolean {
    const orgUnit = API.Tree.getParent(workstationId);
    if (API.isFailure(orgUnit)) {
      logger.warn(orgUnit);
      return false;
    }

    return treeNode.some(_treeNode => orgUnit?.pathIds.includes(_treeNode.id));
  }

  function parseTreeToOrderWorkstation(
    treeNodes: TreeNode[],
    worksatationIds: string[],
    orderedWorkstationIds: string[],
  ): string[] {
    treeNodes.forEach(treeNode => {
      if (worksatationIds.includes(treeNode.id)) {
        orderedWorkstationIds.push(treeNode.id);
      }
      if (treeNode.children.length) {
        parseTreeToOrderWorkstation(treeNode.children, worksatationIds, orderedWorkstationIds);
      }
    });

    return orderedWorkstationIds;
  }

  function fixOrderOfWorkstation(workstationTags: TagExtended[]): TagExtended[] {
    const worksatationIds = workstationTags.map(workstationTag => workstationTag.key);
    const orderedWorkstationIds: string[] = [];
    const ids = parseTreeToOrderWorkstation(topTreeNodes, worksatationIds, orderedWorkstationIds);

    return _.compact(
      ids.map(eachId => workstationTags.find(workstationTag => workstationTag.key === eachId)),
    );
  }

  function filterData(
    isKeywordFiltering: boolean,
    filterTags: TagExtended[],
    filteredTreeNode?: API.TreeNode[],
  ): API.TreeNode<API.TreeDataType>[] {
    if (isKeywordFiltering && isArrayEmpty(filterTags)) {
      setTreeNode(undefined);
      setSelectedMenuTreeNode(undefined);
      setDefaultOrgUnit(undefined);
      return [];
    }

    const resultOfWorkstationFilter: TreeNode[] = [];
    const shiftFilters: TagExtended[] = [];
    const orgUnitFilters: TagExtended[] = [];
    const workstationFilters: TagExtended[] = [];
    const parentOrgUnitTags: TagExtended[] = [];

    let _filteredTreeNode: TreeNode[] = filteredTreeNode ?? [];

    filterTags.forEach(tag => {
      if (tag.type === DropdownConfigKey.ORGUNIT) orgUnitFilters.push(tag);
      else if (tag.type === DropdownConfigKey.SHIFT) shiftFilters.push(tag);
      else if (tag.type === DropdownConfigKey.WORKSTATION) workstationFilters.push(tag);
    });

    if (shiftFilters.length) {
      _.map(shiftFilters, eachFilter => {
        const orgUnit = API.Tree.getTreeNode(eachFilter.value.parentId);
        if (API.isFailure(orgUnit)) {
          logger.warn(orgUnit);
          return;
        }

        orgUnitFilters.push({
          key: eachFilter.value.parentId,
          label: '',
          value: orgUnit,
        });
      });
    }

    
    if (orgUnitFilters.length > 1) {
      for (let i = 0; i <= orgUnitFilters.length; i++) {
        let childTreeNode: API.TreeNode[] = [];
        if (orgUnitFilters[i]) {
          parentOrgUnitTags.forEach((parentOrgUnitTag, index) => {
            const _childTreeNode = findChildTreeNode(
              parentOrgUnitTag.value,
              orgUnitFilters[i].value,
            );
            if (_childTreeNode) {
              childTreeNode.push(_childTreeNode);
            }
          });

          
          if (childTreeNode.length) {
            childTreeNode.forEach(_treeNode => {
              let index = parentOrgUnitTags.findIndex(tag => tag.value.id === _treeNode.id);
              if (index > -1) {
                parentOrgUnitTags.splice(index, 1);

                index = parentOrgUnitTags.findIndex(tag => tag.value.id === orgUnitFilters[i].key);
                if (index === -1) {
                  parentOrgUnitTags.push(orgUnitFilters[i]);
                }
              }
            });
          } else {
            parentOrgUnitTags.push(orgUnitFilters[i]);
          }
        }
      }
    } else {
      parentOrgUnitTags.push(...orgUnitFilters);
    }

    _.map(_.compact(_.uniqBy(parentOrgUnitTags, 'key')), filterTag => {
      const _treeNode = API.Tree.getTreeNode(filterTag.value.id);
      if (API.isFailure(_treeNode)) {
        handleLoader(false);
        return _treeNode;
      }

      _filteredTreeNode.push(_treeNode);
    });

    if (workstationFilters.length) {
      let filterTreeForWorkstation: TreeNode[] = _filteredTreeNode;

      
      const _workstationFilters = fixOrderOfWorkstation(workstationFilters);

      if (!orgUnitFilters.length || isKeywordFiltering) {
        filterTreeForWorkstation = topTreeNodes;
      }
      _.map(_workstationFilters, workstation => {
        
        const isExist = isWorkstationAvailableInTree(filterTreeForWorkstation, workstation.key);

        if (isExist) {
          const orgUnit = API.Tree.getParent(workstation.key);
          if (API.isFailure(orgUnit)) {
            logger.warn(orgUnit);
            return orgUnit;
          }

          if (orgUnit) {
            const treeNodeOrgUnit = API.Tree.getTreeNode(orgUnit.id);
            if (API.isFailure(treeNodeOrgUnit)) return treeNodeOrgUnit;

            const treeNodeWorkstation = API.Tree.getTreeNode(workstation.key);
            if (API.isFailure(treeNodeWorkstation)) return treeNodeWorkstation;

            const _treeNodeOrgUnit = API.deepClone(treeNodeOrgUnit);

            if (!isKeywordFiltering) {
              
              _treeNodeOrgUnit.children = [API.deepClone(treeNodeWorkstation)];
            }

            if (
              isKeywordFiltering &&
              !orgUnitFilters.find(orgUnitFilter => orgUnitFilter.key === orgUnit.id)
            ) {
              
              _treeNodeOrgUnit.children = [API.deepClone(treeNodeWorkstation)];
            }

            resultOfWorkstationFilter.push(_treeNodeOrgUnit);
          }
        }
      });
    }

    
    if (resultOfWorkstationFilter.length) {
      _filteredTreeNode = resultOfWorkstationFilter;
    }

    return _filteredTreeNode;
  }

  function filterTree(filterTags: TagExtended[]): void {
    if (!filterTags.length) {
      setFilteredTreeNodes([]);
      if (topTreeNodes.length) {
        if (!treeNode) setTreeNode(_.clone(topTreeNodes[0]));
        setDefaultOrgUnit(_.clone(topTreeNodes[0].object));
      }
    } else {
      handleLoader(true);

      let _filteredTreeNode: TreeNode[] = [];
      let _filterTags: TagExtended[] = [];
      let _keywordTags: TagExtended[] = [];
      let containsKeywordTags = false;

      const groupedTreeNode: TreeNode[] = [];

      _.forEach(filterTags, tag => {
        if (tag.isActiveBookmarkTag) {
          _filterTags.push(...(tag.children ?? []));
        } else if (tag.isKeywordTag) {
          containsKeywordTags = true;
          _keywordTags.push(...(tag.children ?? []));
        } else if (!tag.isBookmarkTag) {
          _filterTags.push(tag);
        }
      });

      if (containsKeywordTags) {
        const data = filterData(true, _keywordTags);
        if (API.isFailure(data)) {
          logger.warn(data);
          return;
        }

        _filteredTreeNode = data;
      }

      if (_filterTags.length) {
        const data = filterData(false, _filterTags, _filteredTreeNode);
        if (API.isFailure(data)) {
          logger.warn(data);
          return;
        }

        _filteredTreeNode = data;
      }

      
      _.map(_.groupBy(_filteredTreeNode, 'id'), eachId => {
        let _data: TreeNode = {
          ...eachId[0],
          children: [],
        };
        eachId.forEach(eachItem => {
          _data.children.push(...eachItem.children);
        });
        groupedTreeNode.push(_data);
      });

      
      if (groupedTreeNode.length && API.isOrganizationalUnit(groupedTreeNode[0].object)) {
        setTreeNode(_.clone(groupedTreeNode[0]));
        setDefaultOrgUnit(_.clone(groupedTreeNode[0].object));
      }

      setFilteredTreeNodes(groupedTreeNode);
      handleLoader(false);
    }
  }

  async function loadTreeData(): Promise<API.Result<void>> {
    handleLoader(true);

    await getTreeStates();
    if (!isMounted.current) return;

    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) {
      handleLoader(false);
      return appContext;
    }

    const _topTreeNodes = API.Tree.getTopTreeNodes();
    setTopTreeNodes([..._topTreeNodes]);

    if (_topTreeNodes.length) {
      if (!treeNode) setTreeNode(_.clone(_topTreeNodes[0]));
    }

    for (const treeNode of _topTreeNodes) {
      setDefaultOrgUnit(treeNode.object); 
      break;
    }

    handleLoader(false);
  }

  const openEditMenu = (event: GestureResponderEvent) => {
    if (refView.current) {
      const node = ReactDOM.findDOMNode(refView.current) as Element;
      const parentoffset = node.getBoundingClientRect().top;
      const clickOffset = event.nativeEvent.pageY;
      const modalRange = clickOffset + maxModalHeight;

      if (modalRange > window.innerHeight) {
        setYCoordinate(window.innerHeight - maxModalHeight - parentoffset - Spacings.Medium);
      } else {
        setYCoordinate(clickOffset - parentoffset - Spacings.Medium);
      }

      setShowMenu(true);
      setMenuType(MenuType.NodeEditMenu);
    }
  };

  async function getTreeStates(): Promise<void> {
    const userPreference = await API.getUserPreference<Map<string, NodeState>>(
      UserPreferenceKeys_SkillMgtApp.TreePanelNodesState,
    );
    if (!isMounted.current) return;
    if (API.isFailure(userPreference)) {
      logger.warn('Error in fectching user filters', userPreference);
      return;
    }

    if (userPreference) {
      setNodeStates(userPreference);
    }
  }

  async function saveTreeState(): Promise<void> {
    const result = await API.saveUserPreference<Map<string, NodeState>>(
      UserPreferenceKeys_SkillMgtApp.TreePanelNodesState,
      nodeStates,
    );
    if (API.isFailure(result)) {
      logger.warn('Error in saving treeStates to UserPreferences', result);
    }
  }

  async function moveTreeNodeToParent(
    item: DragReferenceObject,
    parentId: string,
    nodeOrder?: number,
  ): Promise<void> {
    if (item.id === parentId || (item.object.parentId === parentId && nodeOrder === undefined)) {
      
      
      return;
    }

    const mutatedObject = API.deepClone(item.object);
    handleLoader(true);

    await saveTreeState();
    if (!isMounted.current) return;

    if (API.isOrganizationalUnit(mutatedObject)) {
      const updateOrganizationalUnit = await API.updateOrganizationalUnit({
        ...mutatedObject,
        parentId: parentId,
        order: nodeOrder,
      });
      if (!isMounted.current) return;
      if (API.isFailure(updateOrganizationalUnit)) {
        handleLoader(false);
        logger.error(updateOrganizationalUnit);
      }
    } else {
      const updateWorkstation = await API.updateWorkstation({
        ...mutatedObject,
        parentId: parentId,
        order: nodeOrder,
      });
      if (!isMounted.current) return;
      if (API.isFailure(updateWorkstation)) {
        handleLoader(false);
        logger.error(updateWorkstation);
      }
    }
    handleLoader(false);
  }

  function handleLoader(isLoading: boolean, loaderTrigger?: LoaderTrigger) {
    switch (loaderTrigger) {
      case LoaderTrigger.duplicateNode:
        setDuplicateLoading(isLoading);
        break;
      default:
        setLoading(isLoading);
    }
  }

  function isLoading(): boolean {
    return duplicateLoading || loading;
  }

  return (
    <>
      {isLoading() && (
        <View style={styles.blockView}>
          <ActivityIndicator />
        </View>
      )}

      <View
        ref={refView}
        style={[
          styles.mainContainer,
          loading && styles.treePanelOpacity,
          {
            display: treeNode?.factory.dataType === API.DataType.WORKSTATION ? 'none' : 'flex',
          },
        ]}
      >
        <DndProvider backend={HTML5Backend}>
          <View style={styles.mainCardContainer}>
            <View style={styles.contentContainer}>
              {defaultOrgUnit && (
                <TreeNodeMenu
                  openMenu={showMenu}
                  selectedNode={
                    menuType === MenuType.NodeEditMenu ? selectedMenuTreeNode : treeNode
                  }
                  defaultOrgUnit={defaultOrgUnit}
                  menuType={menuType}
                  topTreeNodes={topTreeNodes}
                  yCoordinate={yCoordinate}
                  setOpenMenu={setShowMenu}
                  setMenuType={setMenuType}
                  handleLoader={handleLoader}
                  saveTreeState={saveTreeState}
                  setSelectedNode={setTreeNode}
                />
              )}
              <View style={styles.treeHeader}>
                <Text style={styles.headerText} numberOfLines={1}>
                  {t('alex:header.navigation.3')}
                </Text>
                <View style={{ flex: 1 }} />
                {isValidPermission(API.Permission.workstations_edit, treeNode) && (
                  <ShadowOnHoverButton
                    size={Spacings.Standard}
                    iconSize={Spacings.xMedium}
                    iconContainerStyle={{ backgroundColor: Colors.Yellow }}
                    onPress={() => {
                      setShowMenu(!showMenu);
                      setMenuType(MenuType.AddOptionsMenu);
                      setYCoordinate(0);
                    }}
                  />
                )}
              </View>

              <ScrollView
                style={[{ height: '100%' }, styles.border]}
                ref={refScrollView}
                onScroll={event => {
                  refScrollPosition.current = event.nativeEvent.contentOffset.y;
                  setShowMenu(false);
                }}
                scrollEventThrottle={25}
                onLayout={() => {
                  if (!selectedWorkstation) {
                    refScrollView.current?.scrollTo(refScrollPosition.current);
                  }
                }}
              >
                {(workstationScreenFilterTags.length ? filteredTreeNodes : topTreeNodes).map(
                  (node, index) => (
                    <View
                      key={node.id}
                      style={{
                        marginBottom: Spacings.Standard - Spacings.Unit,
                        zIndex: -index,
                      }}
                    >
                      <TreeNodeComponent
                        index={index - 1}
                        level={0}
                        node={node}
                        nodeStates={nodeStates}
                        refScrollView={refScrollView}
                        setSelectedMenuTreeNode={setSelectedMenuTreeNode}
                        openEditMenu={openEditMenu}
                        moveTreeNodeToParent={moveTreeNodeToParent}
                        selectedWorkstation={selectedWorkstation}
                      />
                    </View>
                  ),
                )}
              </ScrollView>
            </View>
          </View>
        </DndProvider>
      </View>
    </>
  );
};
