import * as API from 'shared/backend-data';
import { getWorker } from 'shared/util/Worker';
import * as _ from 'lodash-es';
import Aigle from 'aigle';
import { t } from 'shared/localisation/i18n';
import { convertHtmlToPdfOrCreatePdf } from 'shared/util/PDF';
import { convertDate_ddMMYYYY, isSameDate } from 'shared/util-ts/DateUtils';
import {
  CertificateWidth,
  CertificateHieght,
  autoCertificateHtmlContent,
  signCertificateHtmlContent,
} from 'shared/util/CertificateTemplates';
import logger from './Logger';
import { AsyncLock } from '../util-ts/AsyncLock';
import { ReviewState } from 'shared/backend-data';
import { isPlatformServerless } from '../util-ts/Functions';

const proofBundleParallelCreation = 100;

export enum AddIndividualOrCollectiveProofData {
  COLLECTIVE = 'COLLECTIVE',
  INDIVIDUAL = 'INDIVIDUAL',
}

export interface ProofFile {
  uri?: string;
  fileContentBase64?: any;
  name: string;
  type: ProofFileType;
}

export enum ProofFileType {
  Photo = 'Photo',
  SignedCertificate = 'SignedCertificate',
  AutoCertificate = 'autoCertificate',
  Document = 'Document',
}

export function isReview(obj: any): obj is API.ReviewInput {
  const state = (obj as API.ReviewInput).state;
  return state !== undefined && Object.values(API.ReviewState).includes(state);
}
export function isSameReview(object1: API.ReviewInput, object2: API.ReviewInput): boolean {
  if (object1.state !== object2.state) return false;
  if (object1.date !== object2.date) return false;
  if (object1.workerId !== object2.workerId) return false;

  return true;
}

/**
 * Don't export, use @see createTrainingProofBundle() or @see createWorkerSkillProofBundle() instead
 */
async function _createProofBundle(
  proofBundleInput: Omit<API.ProofBundleCreateInput, 'files'> & {
    files: API.S3ObjectInput[] | (() => Promise<API.Result<API.S3Object[]>>);
  },
): Promise<API.Result<API.ProofBundle>> {
  {
    
    const workerSkillId = API.getWorkerSkillId(proofBundleInput.workerId, proofBundleInput.skillId);
    if (API.isFailure(workerSkillId)) return workerSkillId;

    const proofBundles = await API.getProofBundles(workerSkillId);
    if (API.isFailure(proofBundles)) return proofBundles;

    let existingProofBundle = proofBundles.find(proofBundle =>
      API.isSimilarProofBundle(proofBundle, proofBundleInput),
    );
    if (existingProofBundle) {
      logger.info(
        'A proof for the skill ' +
          proofBundleInput.skillId +
          ' and Worker ' +
          proofBundleInput.workerId +
          ' with similar properties already exist. It will be returned instead of creating a new one.',
      );
      return existingProofBundle;
    }
  }

  let _proofBundleInput: API.ProofBundleCreateInput;
  if (Array.isArray(proofBundleInput.files)) {
    _proofBundleInput = { ...proofBundleInput, files: proofBundleInput.files };
  } else {
    const s3Files = await proofBundleInput.files();
    if (API.isFailure(s3Files)) return s3Files;

    _proofBundleInput = { ...proofBundleInput, files: s3Files };
  }

  const factory = await API.createFactoryBusinessObject(
    API.DataType.PROOFBUNDLE,
    _proofBundleInput,
  );
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.proofBundle,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

/**
 * Don't export, use @see createTrainingProofBundle() or @see createWorkerSkillProofBundle() instead
 * @param proofBundleInput
 * @param workerId
 * @param skillIds
 * @param originObjectId (optional) if not set, the skillId is used
 * @returns
 */
async function _createProofBundles(
  proofBundleInput: Omit<
    API.ProofBundleCreateInput,
    'originObjectId' | 'workerId' | 'skillId' | 'files'
  > & {
    files: API.S3ObjectInput[] | (() => Promise<API.Result<API.S3Object[]>>);
  },
  workerId: string,
  skillIds: readonly string[],
  originObjectId?: string,
): Promise<API.Result<API.ProofBundle[]>> {
  
  if (skillIds.length > 10) {
    const preFetchProofBundles = await API.getWorkerProofBundles(workerId);
    if (API.isFailure(preFetchProofBundles)) return preFetchProofBundles;
  }

  const proofBundles = await API.mapLimit(skillIds, proofBundleParallelCreation, async skillId => {
    return _createProofBundle({
      ...proofBundleInput,
      originObjectId: originObjectId ?? skillId,
      workerId,
      skillId,
    });
  });
  if (API.isFailure(proofBundles)) return proofBundles;

  return proofBundles;
}

/**
 * Create a ProofBundle for a Worker on some Skills.
 * The backend business rules will update/create the releated WorkerSkills.
 * @param proofBundleInput
 * @param workerId
 * @param skillId
 */
export async function createWorkerSkillProofBundle(
  proofBundleInput: Omit<
    API.ProofBundleCreateInput,
    'originObjectId' | 'workerId' | 'skillId' | 'files'
  > & {
    files: API.S3ObjectInput[] | (() => Promise<API.Result<API.S3Object[]>>);
  },
  workerId: string,
  skillId: string,
): Promise<API.Result<API.ProofBundle>>;
export async function createWorkerSkillProofBundle(
  proofBundleInput: Omit<
    API.ProofBundleCreateInput,
    'originObjectId' | 'workerId' | 'skillId' | 'files'
  > & {
    files: API.S3ObjectInput[] | (() => Promise<API.Result<API.S3Object[]>>);
  },
  workerId: string,
  skillIds: string[],
): Promise<API.Result<API.ProofBundle[]>>;
export async function createWorkerSkillProofBundle(
  proofBundleInput: Omit<
    API.ProofBundleCreateInput,
    'originObjectId' | 'workerId' | 'skillId' | 'files'
  > & {
    files: API.S3ObjectInput[] | (() => Promise<API.Result<API.S3Object[]>>);
  },
  workerId: string,
  skillIdOrSkillIds: string | string[],
): Promise<API.Result<API.ProofBundle | API.ProofBundle[]>> {
  if (Array.isArray(skillIdOrSkillIds)) {
    return _createProofBundles(proofBundleInput, workerId, skillIdOrSkillIds);
  } else {
    const proofBundle = await _createProofBundles(proofBundleInput, workerId, [skillIdOrSkillIds]);
    if (API.isFailure(proofBundle)) return proofBundle;

    return proofBundle[0]; 
  }
}

/**
 * Create a ProofBundle for the TrainingSession's trainees.
 * This function takes care to map the given ProofBundle with the TrainingSession's skills and trainees.
 * The backend business rules will update/create the releated WorkerSkills.
 * @param proofBundleInput the proofBundle to add
 *
 * @param traineeId required if trainingVersion is passed: the proofBundle will be created only for the given trainee
 * @param skillId (optional) if given a value, the proofBundle will be created only for the given Skill of the Training (otherwise a common proof for all the Skills of the Training will be created)
 */
export async function createTrainingProofBundle(
  proofBundleInput: Omit<
    API.ProofBundleCreateInput,
    'originObjectId' | 'workerId' | 'skillId' | 'files'
  > & {
    files: API.S3ObjectInput[] | (() => Promise<API.Result<API.S3Object[]>>);
  },
  trainingVersionIdOrTrainingSessionId: string,
  traineeId?: string | undefined,
  skillId?: string | undefined,
  maybeUpdate?: boolean, 
): Promise<API.Result<API.ProofBundle[]>> {
  maybeUpdate = false; 

  let trainingSession: API.TrainingSession;
  const dataType = API.getDataType(trainingVersionIdOrTrainingSessionId);
  if (API.isFailure(dataType)) return dataType;
  if (dataType === API.DataType.TRAININGVERSION) {
    if (!traineeId)
      return API.createFailure_Unspecified(
        'trainee is a required parameter when trainingVersionId is specified',
      );

    
    const date = new Date().toISOString();
    const _trainingSession = await API.createTrainingSession({
      originId: trainingVersionIdOrTrainingSessionId,
      traineeIds: [traineeId],
      scheduledTraineeIds: [traineeId],
      scheduledTrainers: [{ trainerId: API.externalTrainerWorkerId, percentage: '100%' }],
      trainers: [{ trainerId: API.externalTrainerWorkerId, percentage: '100%' }],
      scheduledStartDate: date,
      startDate: date,
      endDate: date,
      trainingVersionId: trainingVersionIdOrTrainingSessionId,
      requestState: API.ReviewState.VALIDATED,
    });
    if (API.isFailure(_trainingSession)) return _trainingSession;

    trainingSession = _trainingSession;
  } else if (dataType === API.DataType.TRAININGSESSION) {
    const _trainingSession = await API.getTrainingSession(trainingVersionIdOrTrainingSessionId);
    if (API.isFailure(_trainingSession)) return _trainingSession;

    trainingSession = _trainingSession;
  } else {
    return API.createFailure_Unspecified(
      'Unsupported dataType=' +
        dataType +
        ' for trainingVersionOrTrainingSessionId=' +
        trainingVersionIdOrTrainingSessionId,
    );
  }

  const trainingVersion = await API.getTrainingVersion(trainingSession.trainingVersionId);
  if (API.isFailure(trainingVersion)) return trainingVersion;

  if (traineeId && !trainingSession.traineeIds.includes(traineeId))
    return API.createFailure_Unspecified(
      'trainee with workerId : ' + traineeId + ' shall be part of the TrainingSession ',
    );

  const trainingVersionSkills = await API.mapSeries(trainingVersion.skillIds, async skillId => {
    return API.getSkill(skillId);
  });
  if (API.isFailure(trainingVersionSkills)) return trainingVersionSkills;

  let trainingVersionSkillIdsSet: Set<string> = new Set();
  const failure = await API.mapSeries(trainingVersionSkills, async skill => {
    if (API.isSkillGroup(skill)) {
      const subSkills = await API.getSubSkillsFromSkillGroup(skill);
      if (API.isFailure(subSkills)) return subSkills;
      const subSkillIds = subSkills.map(subSkill => subSkill.id);
      trainingVersionSkillIdsSet = new Set([...trainingVersionSkillIdsSet, ...subSkillIds]);
    } else {
      trainingVersionSkillIdsSet.add(skill.id);
    }
  });
  if (API.isFailure(failure)) return failure;
  const trainingVersionSkillIds = Array.from(trainingVersionSkillIdsSet);

  if (skillId && !trainingVersionSkillIds.includes(skillId)) {
    return API.createFailure_Unspecified(
      'skillId : ' + skillId + " shall be part of the TrainingSession' skills",
    );
  }

  const traineeIds = traineeId ? [traineeId] : trainingSession.traineeIds;
  const skillIds = skillId ? [skillId] : trainingVersionSkillIds;

  const proofBundles: API.ProofBundle[] = [];

  if (maybeUpdate) {
    for (const traineeId of traineeIds) {
      const traineeProofBundles = await _createProofBundles(
        proofBundleInput,
        traineeId,
        skillIds,
        trainingSession.id,
      );
      if (API.isFailure(traineeProofBundles)) return traineeProofBundles;

      proofBundles.push(...traineeProofBundles);
    }
  } else {
    
    const existingProofBundleMap = await getLatestProofBundleAndCommonFiles(trainingSession.id);
    if (API.isFailure(existingProofBundleMap)) return existingProofBundleMap;

    const proofBundles: API.ProofBundle[] = [];
    const errors: API.Failure[] = [];
    await Aigle.forEachSeries(traineeIds, async traineeId => {
      await Aigle.forEachLimit(skillIds, async skillId => {
        const existingProofBundle = existingProofBundleMap
          .get(traineeId)
          ?.get(skillId)?.proofBundle;

        
        if (!existingProofBundle) {
          const createdProofBundle = await _createProofBundle({
            ...proofBundleInput,
            originObjectId: trainingSession.id,
            workerId: traineeId,
            skillId: skillId,
          });
          if (API.isFailure(createdProofBundle)) return createdProofBundle;

          proofBundles.push(createdProofBundle);
        }  else {
          let _proofBundleInput: Omit<
            API.ProofBundleCreateInput,
            'originObjectId' | 'workerId' | 'skillId'
          >;
          if (Array.isArray(proofBundleInput.files)) {
            _proofBundleInput = { ...proofBundleInput, files: proofBundleInput.files };
          } else {
            const s3Files = await proofBundleInput.files();
            if (API.isFailure(s3Files)) return s3Files;

            _proofBundleInput = { ...proofBundleInput, files: s3Files };
          }

          const updatedProofBundle = maybeUpdate
            ? await updateProofBundle({
                
                ...existingProofBundle,
                ..._proofBundleInput,
                id: existingProofBundle.id,
                files: [...existingProofBundle.files, ..._proofBundleInput.files],
                
              })
            : await updateProofBundle({ ..._proofBundleInput, id: existingProofBundle.id });
          if (API.isFailure(updatedProofBundle)) return updatedProofBundle;

          proofBundles.push(updatedProofBundle);
        }
      });
    });
    if (errors.length) return API.createFailure_Multiple(errors);
  }

  return proofBundles;
}

export async function updateProofBundle(
  proofBundleInput: API.ProofBundlePartialUpdateInput,
): Promise<API.Result<API.ProofBundle>> {
  const factory = await API.updateFactoryBusinessObject(API.DataType.PROOFBUNDLE, proofBundleInput);
  if (API.isFailure(factory)) return factory;

  return { ...factory.proofBundle, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function getProofBundle(proofBundleId: string): Promise<API.Result<API.ProofBundle>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.PROOFBUNDLE, proofBundleId);
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.proofBundle,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

/**
 * Get the ProofBundles for the given WorkerSkill or TrainingSession or TrainingVersion
 * @param workerSkillIdOrTrainingVersionIdOrTrainingSessionId
 */
export async function getProofBundles(
  workerSkillIdOrTrainingVersionIdOrTrainingSessionId: string,
): Promise<API.Result<API.ProofBundle[]>> {
  const _result: Array<API.Factory<API.DataType.PROOFBUNDLE>> = [];

  const dataType = API.getDataType(workerSkillIdOrTrainingVersionIdOrTrainingSessionId);
  if (API.isFailure(dataType)) return dataType;

  let proofBundles: API.ProofBundle[] = [];
  if (dataType === API.DataType.TRAININGSESSION || dataType === API.DataType.TRAININGVERSION) {
    const dataString1 = API.DataType.PROOFBUNDLE + API.SeparatorDataType + API.SeparatorIds;
    let dataString2 = '';
    const dataString3 = API.SeparatorIds + workerSkillIdOrTrainingVersionIdOrTrainingSessionId;

    
    await Aigle.map(API.ReviewState, async _reviewState => {
      dataString2 = _reviewState;

      const proofBundleFactories = await API.listFactoriesWithData(
        API.DataType.PROOFBUNDLE,
        dataString1 + dataString2 + dataString3,
        API.KeyComparator.eq,
      );
      if (API.isFailure(proofBundleFactories)) return proofBundleFactories;

      _result.push(...proofBundleFactories.result);
    });

    proofBundles = _.map(_result, factory => {
      return {
        ...factory.proofBundle,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      };
    });

    return proofBundles;
  } else if (dataType === API.DataType.WORKERSKILL) {
    const r = API.getWorkerIdSkillId(workerSkillIdOrTrainingVersionIdOrTrainingSessionId);
    if (API.isFailure(r)) return r;

    const { workerId, skillId } = r;

    const sk =
      API.DataType.PROOFBUNDLE +
      API.SeparatorDataType +
      API.SeparatorIds +
      workerId +
      API.SeparatorIds +
      skillId;

    const proofBundleFactories = await API.listFactoriesWithSk(
      API.DataType.PROOFBUNDLE,
      sk,
      API.KeyComparator.beginsWith,
    );
    if (API.isFailure(proofBundleFactories)) return proofBundleFactories;
    _result.push(...proofBundleFactories.result);

    _.forEach(_result, factory => {
      if (factory.proofBundle.workerId === workerId) {
        proofBundles.push({
          ...factory.proofBundle,
          updatedAt: factory.updatedAt,
          updatedBy: factory.updatedBy,
        });
      }
    });
    return proofBundles;
  } else {
    return API.createFailure_Unspecified(
      'workerSkillOrTrainingSession is neither a WorkerSkill nor a TrainingSession ' +
        workerSkillIdOrTrainingVersionIdOrTrainingSessionId,
    );
  }
}

/**
 * Get the ProofBundles for a given review state
 * @param reviewState
 * @param skillIdOrTrainingSessionIdOrTrainingVersionId can be null
 */
export async function getProofBundlesByReviewStateAndOriginObject(
  reviewState: API.ReviewState,
  skillIdOrTrainingSessionIdOrTrainingVersionId: string | undefined | null,
): Promise<API.Result<API.ProofBundle[]>> {
  const dataString =
    API.DataType.PROOFBUNDLE +
    API.SeparatorDataType +
    API.SeparatorIds +
    reviewState +
    (skillIdOrTrainingSessionIdOrTrainingVersionId
      ? API.SeparatorIds + skillIdOrTrainingSessionIdOrTrainingVersionId
      : '');
  const proofBundleFactories = await API.listFactoriesWithData(
    API.DataType.PROOFBUNDLE,
    dataString,
    skillIdOrTrainingSessionIdOrTrainingVersionId
      ? API.KeyComparator.eq
      : API.KeyComparator.beginsWith,
  );

  if (API.isFailure(proofBundleFactories)) return proofBundleFactories;

  const proofBundles = _.map(proofBundleFactories.result, factory => {
    return {
      ...factory.proofBundle,
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    };
  });
  return proofBundles;
}

/**
 * To get the ProofBundles of a worker
 * @param workerId
 * @returns
 */
export async function getWorkerProofBundles(
  workerId: string,
): Promise<API.Result<API.ProofBundle[]>> {
  const sk = API.DataType.PROOFBUNDLE + API.SeparatorDataType + API.SeparatorIds + workerId;

  const proofBundleFactories = await API.listFactoriesWithSk(
    API.DataType.PROOFBUNDLE,
    sk,
    API.KeyComparator.beginsWith,
  );
  if (API.isFailure(proofBundleFactories)) return proofBundleFactories;

  return proofBundleFactories.result.map(factory => {
    return {
      ...factory.proofBundle,
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    };
  });
}

/**
 * Get all the ProofBundles for the given TrainingSession/TrainingVersion organized into a map (workerId -> proofBundles)
 * @param trainingSessionIdOrTrainingVersionId
 * @returns traineesProofBundlesMap
 */
export async function getTraineesProofBundles(
  trainingSessionIdOrTrainingVersionId: string,
): Promise<API.Result<Map<string, API.ProofBundle[]>>> {
  const proofBundles = await API.getProofBundles(trainingSessionIdOrTrainingVersionId);
  if (API.isFailure(proofBundles)) return proofBundles;

  
  const traineesProofBundlesMap = new Map<string, API.ProofBundle[]>();

  proofBundles.forEach(proofBundle => {
    const _workerId = proofBundle.workerId;
    const workerProofBundles = traineesProofBundlesMap.get(_workerId);

    if (workerProofBundles) {
      workerProofBundles.push(proofBundle);
    } else {
      traineesProofBundlesMap.set(_workerId, [proofBundle]);
    }
  });

  return traineesProofBundlesMap;
}

/**
 * Get the ProofBundles of a Trainee, for the given TrainingSession
 * @param trainingSessionId
 * @param traineeId
 * @returns traineesProofBundlesMap
 */
export async function getTraineeProofBundles(
  trainingSessionId: string,
  traineeId: string,
): Promise<API.Result<API.ProofBundle[] | undefined>> {
  const traineesProofBundlesMap = await getTraineesProofBundles(trainingSessionId);
  if (API.isFailure(traineesProofBundlesMap)) return traineesProofBundlesMap;

  return traineesProofBundlesMap.get(traineeId);
}

/**
 * Extract the latest ProofBundle from the given proofBundles
 * @param proofBundles
 * @returns
 */
export function extractLatestProofBundle(
  proofBundles: API.NoMetadata<API.ProofBundle>[],
): API.NoMetadata<API.ProofBundle> {
  return proofBundles.reduce((_proofBundleA, _proofBundleB) =>
    new Date(_proofBundleA.startingDate).getTime() > new Date(_proofBundleB.startingDate).getTime()
      ? _proofBundleA
      : _proofBundleB,
  );
}

/**
 *
 * @param proofBundles
 * @returns map of workerId -> skillId -> {proofBundle, commonFiles}
 */
export function extractLatestProofBundleAndCommonFiles(
  proofBundles: API.ProofBundle[],
): Map<string, Map<string, { proofBundle: API.ProofBundle; commonFiles: API.S3Object[] }>> {
  const workerSkillProofBundlesMap = new Map<
    string,
    Map<string, { proofBundle: API.ProofBundle; commonFiles: API.S3Object[] }>
  >();

  proofBundles.forEach(proofBundle => {
    const _workerId = proofBundle.workerId;
    const _skillId = proofBundle.skillId;

    let skillMap = workerSkillProofBundlesMap.get(_workerId);
    if (!skillMap) {
      skillMap = new Map();
      workerSkillProofBundlesMap.set(_workerId, skillMap);
    }

    const _proofBundle = skillMap.get(_skillId);
    if (_proofBundle) {
      
      
      
      if (
        new Date(_proofBundle.proofBundle.updatedAt).getTime() <
          new Date(proofBundle.updatedAt).getTime() &&
        (_proofBundle.proofBundle.review.state !== API.ReviewState.TO_REVIEW ||
          proofBundle.review.state === API.ReviewState.TO_REVIEW ||
          proofBundle.review.state === API.ReviewState.DRAFT)
      ) {
        skillMap.set(_skillId, { proofBundle, commonFiles: [] });
      }
    } else {
      skillMap.set(_skillId, { proofBundle, commonFiles: [] });
    }
  });

  
  
  {
    let firstWorkerSkillProofBundle: API.ProofBundle | undefined;
    _.forEach(Array.from(workerSkillProofBundlesMap.values()), (skillsMap, index) => {
      if (index === 0) firstWorkerSkillProofBundle = Array.from(skillsMap.values())[0].proofBundle; 

      const commonFiles: API.S3Object[] = [];

      const firstProofBundle = Array.from(skillsMap.values())[0].proofBundle; 

      for (const file of firstProofBundle.files) {
        let isCommon = false;

        Array.from(skillsMap.values()).forEach(_otherProofBundleDetail => {
          if (
            firstProofBundle.id !== _otherProofBundleDetail.proofBundle.id &&
            _otherProofBundleDetail.proofBundle.files.some(_file => API.isSameS3Object(_file, file))
          ) {
            isCommon = true;
          }
        });

        if (isCommon) commonFiles.push(file);
      }

      if (commonFiles.length) {
        _.forEach(Array.from(skillsMap), ([skillId, proofBundleDetails]) => {
          skillsMap.set(skillId, { ...proofBundleDetails, commonFiles: [...commonFiles] });
        });
      }
    });

    const result = new Map(workerSkillProofBundlesMap);

    
    if (
      Array.from(workerSkillProofBundlesMap.values()).length > 1 &&
      firstWorkerSkillProofBundle?.files
    ) {
      const commonFiles: API.S3Object[] = [];

      firstWorkerSkillProofBundle.files.forEach(file => {
        let isCommonWorkersSkillProofs = true;

        _.forEach(Array.from(workerSkillProofBundlesMap.values()), skillsMap => {
          isCommonWorkersSkillProofs = Array.from(skillsMap.values()).every(
            _otherProofBundleDetail => _.find(_otherProofBundleDetail.proofBundle.files, file),
          );
          if (!isCommonWorkersSkillProofs) {
            isCommonWorkersSkillProofs = false;
          }
        });

        if (isCommonWorkersSkillProofs) {
          commonFiles.push(file);
        }
      });

      if (commonFiles.length) {
        _.forEach(Array.from(workerSkillProofBundlesMap.values()), skillsMap => {
          _.forEach(Array.from(skillsMap), ([skillId, proofBundleDetails]) => {
            skillsMap.set(skillId, {
              ...proofBundleDetails,
              commonFiles: _.uniqBy(
                [...(skillsMap.get(skillId)?.commonFiles ?? []), ...commonFiles],
                'key',
              ),
            });
          });
        });
      }
    }
  }

  return workerSkillProofBundlesMap;
}

/**
 * @see extractLatestProofBundleAndCommonFiles() for the given TrainingSession/TrainingVersion
 * @param workerSkillIdOrTrainingVersionIdOrTrainingSessionId
 * @returns map of workerId -> skillId -> {proofBundle, commonFiles}
 */
export async function getLatestProofBundleAndCommonFiles( 
  workerSkillIdOrTrainingVersionIdOrTrainingSessionId: string,
): Promise<
  API.Result<
    Map<string, Map<string, { proofBundle: API.ProofBundle; commonFiles: API.S3Object[] }>>
  >
> {
  const proofBundles = await API.getProofBundles(
    workerSkillIdOrTrainingVersionIdOrTrainingSessionId,
  );
  if (API.isFailure(proofBundles)) return proofBundles;

  return API.extractLatestProofBundleAndCommonFiles(proofBundles);
}

export async function reviewProofBundle(
  proofBundleId: string,
  reviewState: API.ReviewState,
  startingDate: Date,
  notes?: string | null,
  acquired?: boolean | null,
  reviewer?: API.Worker,
): Promise<API.Result<API.ProofBundle>> {
  const worker = reviewer ?? (await getWorker());
  if (API.isFailure(worker)) return worker;
  const isNotAcquired = Boolean(!acquired && !_.isNull(acquired) && !_.isUndefined(acquired));

  if (isNotAcquired) {
    if (reviewState === API.ReviewState.REJECTED)
      return API.createFailure('UpdateVeto', 'cannot reject a not acquired proof', {
        dependencyIds: [proofBundleId],
      });
    else
      return API.updateProofBundle({
        id: proofBundleId,
        description: notes,
        startingDate: startingDate.toISOString(),
        review: {
          state:
            reviewState === API.ReviewState.REJECTED_TO_RESUBMIT
              ? API.ReviewState.REJECTED_TO_RESUBMIT
              : API.ReviewState.REJECTED,
          date: new Date().toISOString(),
          workerId: worker.id,
        },
      });
  }

  return API.updateProofBundle({
    id: proofBundleId,
    description: notes,
    startingDate: startingDate.toISOString(),
    review: {
      state: reviewState,
      date: new Date().toISOString(),
      workerId: worker.id,
    },
  });
}

const createCertificateProofLock = new AsyncLock();

/**
 * Generate a pdf for auto certificate or signed certificate
 * @param workersNames names of workers to show on the certificate
 * @param skillsOrTrainingsNames names of skills or Trainings obtained by this certificate
 * @param validatorName name of the validator of the certificate
 * @param isProofOfTraining set to true if it is a proof of training otherwise it is considered as proof of skill
 * @param revokeCertificate if passed the function will generate a revoke certificate
 * @param comment if passed, add the given comment to the certificate. If a Date is passed a tag specifing excel import on date will be added.
 * @param signatureBase64 (optional) image of the signature. If not passed an auto certificate will be generated.
 * @param skillsIncludedInTraining (optional) needed when isProofOfTraining=true
 */
export async function createCertificateProof(
  workersNames: string[],
  skillsOrTrainingsNames: string[],
  validatorName: string,
  isProofOfTraining: boolean,
  revokeCertificate: boolean,
  comment?: string | Date,
  signatureBase64?: string,
  skillsIncludedInTraining?: string[],
): Promise<ProofFile> {
  
  return createCertificateProofLock.runSerial(async () => {
    let certificateComment = '';
    if (comment) {
      if (_.isDate(comment)) {
        certificateComment = t(
          'alex:mobile.addProofPage.autoCertificate.dateOfExcelImportedCertificate',
          {
            date: comment.toISOString(),
          },
        );
      } else {
        certificateComment = comment;
      }
    }

    const _date = convertDate_ddMMYYYY(new Date());

    let workersNamesString = '';
    workersNames.map((name, index) => {
      workersNamesString += name;
      if (index + 1 !== workersNames.length) workersNamesString += ', ';
    });

    let skillsOrTrainingsNamesString = '';
    skillsOrTrainingsNames.map((name, index) => {
      skillsOrTrainingsNamesString += name;
      if (index + 1 !== skillsOrTrainingsNames.length) skillsOrTrainingsNamesString += ', ';
    });

    let _skillsIncludedInTraining = '';
    if (skillsIncludedInTraining?.length) {
      skillsIncludedInTraining.map((name, index) => {
        _skillsIncludedInTraining += name;
        if (index + 1 !== skillsIncludedInTraining?.length) _skillsIncludedInTraining += ', ';
      });
    }

    const fileName = signatureBase64
      ? t('alex:mobile.addProofPage.autoCertificate.autoSignatureFileName')
      : t('alex:mobile.addProofPage.autoCertificate.autoCertificateFileName', {
          validatorName: validatorName.trim(),
        });

    const htmlTemplate = signatureBase64
      ? signCertificateHtmlContent(
          workersNamesString,
          skillsOrTrainingsNamesString,
          _date,
          signatureBase64,
          isProofOfTraining,
          revokeCertificate,
          certificateComment,
          _skillsIncludedInTraining,
        )
      : autoCertificateHtmlContent(
          workersNamesString,
          skillsOrTrainingsNamesString,
          _date,
          validatorName,
          isProofOfTraining,
          revokeCertificate,
          certificateComment,
          _skillsIncludedInTraining,
          isPlatformServerless,
        );
    const pdfFile = await convertHtmlToPdfOrCreatePdf({
      htmlOrFileContent: htmlTemplate,
      fileName: fileName,
      width: CertificateWidth,
      height: CertificateHieght,
      padding: 0,
    });

    return {
      uri: pdfFile.uri,
      fileContentBase64: pdfFile.fileContent,
      name: pdfFile.fileName,
      type: signatureBase64 ? ProofFileType.SignedCertificate : ProofFileType.AutoCertificate,
    };
  });
}

/**
 * Check if a ProofBundle for the given worker, skill and issueDate
 * exist already as active or pending to review proofBundle.
 * @param workerId
 * @param skillId
 * @param issueDate
 * @returns ProofBundle if found, null otherwise
 */
export async function isActiveOrToReviewProofBundle(
  workerId: string,
  skillId: string,
  issueDate: Date,
): Promise<API.Result<API.NoMetadata<API.ProofBundle> | null>> {
  const ws = await API.getWorkerSkill(workerId, skillId);
  if (API.isFailure(ws) && ws.type !== 'ObjectNotFound') return ws;
  if (!API.isFailure(ws) && ws.proofBundleIds.length) {
    
    
    

    if (ws.activeProofBundle && isSameDate(new Date(ws.activeProofBundle.startingDate), issueDate))
      return ws.activeProofBundle;
    if (
      ws.toReviewProofBundle &&
      isSameDate(new Date(ws.toReviewProofBundle.startingDate), issueDate)
    )
      return ws.toReviewProofBundle;
  }
  return null;
}

/**
 * Update the ProofBundle as acquired or not if the proof does not exist it creates it
 * and if the proof is not acquired and doesnt have any files and no comment it will delete it
 * @param skillId
 * @param trainingSessionId
 * @param workerId
 * @param acquired tri-state boolean : True, False or Null
 */
export async function createOrUpdateProofAsAcquiredOrNot(
  skillId: string,
  trainingSessionId: string,
  workerId: string,
  acquired: boolean | null,
): Promise<API.Result<API.ProofBundle | undefined>> {
  const trainingSessionProofBundles = await API.getProofBundles(trainingSessionId);
  if (API.isFailure(trainingSessionProofBundles)) return trainingSessionProofBundles;

  let proofBundle: API.Result<API.ProofBundle | undefined>;
  const _proofBundle = _.find(trainingSessionProofBundles, proofBundle => {
    return proofBundle.skillId === skillId && proofBundle.workerId === workerId;
  });

  if (
    _proofBundle &&
    _proofBundle.review.state !== ReviewState.VALIDATED &&
    _proofBundle.review.state !== ReviewState.REJECTED
  ) {
    const proofBundle = await API.updateProofBundle({
      id: _proofBundle.id,
      acquired: acquired,
    });
    if (API.isFailure(proofBundle)) return proofBundle;
  } else {
    const createdProof = await API.createTrainingProofBundle(
      {
        startingDate: new Date().toISOString(),
        files: [],
        review: {
          state: API.ReviewState.DRAFT,
        },
        acquired: acquired,
      },
      trainingSessionId,
      workerId,
      skillId,
      true,
    );
    if (API.isFailure(createdProof)) return createdProof;

    
    proofBundle = createdProof[0];
  }

  return proofBundle;
}

/**
 * Tells if 2 ProofBundles have the same starting date and review state
 * WARNING: this is not a strict equality (many properties may differ)
 * @param workerId
 * @param skillId
 * @param issueDate
 */
export function isSimilarProofBundle(
  proofBundle: API.Immutable<Omit<API.ProofBundleCreateInput, 'files'>>,
  proofBundle2: API.Immutable<Omit<API.ProofBundleCreateInput, 'files'>>,
): boolean {
  return (
    proofBundle.skillId === proofBundle2.skillId &&
    proofBundle.workerId === proofBundle2.workerId &&
    proofBundle.startingDate === proofBundle2.startingDate &&
    proofBundle.review.state === proofBundle2.review.state &&
    
    proofBundle.acquired == proofBundle2.acquired
    
    
    
  );
}

/**
 * Returns the latest REJECTED_TO_RESUBMIT proofBundles
 * @returns proofBundles
 */
export async function getRejectToResubmitProofBundles(): Promise<API.Result<API.ProofBundle[]>> {
  

  
  const rejectedToResubmitProofBundles = await getProofBundlesByReviewStateAndOriginObject(
    API.ReviewState.REJECTED_TO_RESUBMIT,
    undefined,
  );
  if (API.isFailure(rejectedToResubmitProofBundles)) return rejectedToResubmitProofBundles;

  
  const rejectedToResubmitProofBundlesMap: Map<string, API.ProofBundle> = new Map();
  rejectedToResubmitProofBundles.forEach(proofBundle => {
    rejectedToResubmitProofBundlesMap.set(proofBundle.id, proofBundle);
  });

  
  const _rejectedToResubmitProofBundles: API.ProofBundle[] = [];
  await Aigle.mapSeries(rejectedToResubmitProofBundles, async proofBundle => {
    const workerSkill = await API.getWorkerSkill(proofBundle.workerId, proofBundle.skillId);
    if (API.isFailure(workerSkill)) return workerSkill;

    if (
      !workerSkill.toReviewProofBundle &&
      workerSkill.activeProofBundle?.id === proofBundle.id &&
      workerSkill.activeProofBundle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
    ) {
      const metaDataProofBundle = rejectedToResubmitProofBundlesMap.get(
        workerSkill.activeProofBundle.id,
      );
      if (metaDataProofBundle) _rejectedToResubmitProofBundles.push(metaDataProofBundle);
    }
  });

  return _rejectedToResubmitProofBundles;
}
