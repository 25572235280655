import { StyleSheet } from 'react-native-web';
import { TextFontStyles, fontSize } from 'shared/styles';

const style = StyleSheet.create({
  workstationIcon: {
    marginRight: 16,
  },
  workstationNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableText: {
    width: '80%',
    textAlign: 'left',
    fontSize: fontSize.Regular,
    color: '#606060',
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
  },
  ellipsisText: {
    width: '85%',
  },
});

export default style;
