import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, InteractionManager } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { Colors } from 'shared/styles';
import { DropDownMultiSelection } from 'shared/ui-component/DropDown';
import { Tag } from 'shared/ui-component/Input/InputTag';
import Styles from './styles';
import { ValidityDisplay } from 'shared/ui-component/validity-display/ValidityDisplay';
import { Chapter } from 'shared/layout/summaryBook/SummaryBook';
import { AcquiredAndNotAcquiredButtons } from 'shared/layout/summaryBook/AcquiredAndNotAcquiredButtons/AcquiredAndNotAcquiredButtons';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';

interface Props {
  chapter: Chapter;
  isChapterContainsGhostTrainee?: boolean;

  handleProofs: (chapter: Chapter) => void;
  setChapter: React.Dispatch<React.SetStateAction<Chapter | undefined>>;
}

export const ChapterDetails: React.FC<Props> = props => {
  const { chapter, isChapterContainsGhostTrainee, handleProofs } = props;
  const [isSkillAcquired, setIsSkillAcquired] = useState<number>(-1);
  const [dropdownSkillTags, setDropdownSkillTags] = useState<Tag[]>([]);
  const [dropdownTraineeTags, setDropdownTraineeTags] = useState<Tag[]>([]);
  const [skillObtentionDate, setSkillObtentionDate] = useState<Date>(new Date());
  const [radioButtonColor, setRadioButtonColor] = useState<string>(Colors.Black);
  const [comment, setComment] = useState<string>('');

  const totalSkillCount = useRef<number>(0);

  const { isValidPermission } = useContext(PermissionManagementContext);

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      await skillsAndSubSkillsCount();
      setDropdownSkillTags(
        chapter.skills.map(skill => {
          return {
            key: skill.id,
            label: skill.name,
          };
        }),
      );

      setDropdownTraineeTags(
        chapter.trainees.map(trainee => {
          return {
            key: trainee.id,
            label: trainee.name,
          };
        }),
      );
      handleAcquiredSkill();

      if (chapter.issueDate) {
        setSkillObtentionDate(new Date(chapter.issueDate));
      } else {
        setSkillObtentionDate(new Date());
      }

      if (chapter.comment) {
        setComment(chapter.comment);
      } else {
        setComment('');
      }
    });
  }, [chapter]);

  async function skillsAndSubSkillsCount() {
    const skillsAndSubSkillsIds = await API.getSkillsAndSubSkillsIds(chapter.skills);
    if (!isMounted.current) return;
    if (API.isFailure(skillsAndSubSkillsIds)) return skillsAndSubSkillsIds;

    totalSkillCount.current = skillsAndSubSkillsIds.skillIds.size;
  }

  function handleAcquiredSkill() {
    if (totalSkillCount.current > 1 || chapter.trainees.length > 1) {
      if (
        !chapter?.proofBundles?.length ||
        chapter.proofBundles.length !== totalSkillCount.current * chapter.trainees.length
      ) {
        setIsSkillAcquired(-1);

        return;
      }

      if (
        chapter.proofBundles.every(proofBundle => proofBundle.acquired === true) &&
        chapter.proofBundles.length === totalSkillCount.current * chapter.trainees.length
      ) {
        setIsSkillAcquired(0);
        setRadioButtonColor(Colors.Black);
      } else if (
        chapter.proofBundles.every(proofBundle => proofBundle.acquired === false) &&
        chapter.proofBundles.length === totalSkillCount.current * chapter.trainees.length
      ) {
        setIsSkillAcquired(1);
        setRadioButtonColor(Colors.Black);
      } else {
        setIsSkillAcquired(-1);
        setRadioButtonColor(Colors.Grey);
      }
    } else if (chapter.proofBundles?.length === 1) {
      setIsSkillAcquired(
        chapter.proofBundles[0].acquired === null ? -1 : chapter.proofBundles[0].acquired ? 0 : 1,
      );
      setRadioButtonColor(Colors.Black);
    } else {
      setIsSkillAcquired(-1);
      setRadioButtonColor(Colors.Black);
    }
  }

  function handleRadioButtons(item: string, index: number): void {
    setIsSkillAcquired(index);

    chapter.acquired = Boolean(!index);

    handleProofs(chapter);
  }

  function handleObtentionDate(date: Date) {
    chapter.issueDate = date.toISOString();

    handleProofs(chapter);
  }

  function handleCommentSection(inputId: string, text: string) {
    chapter.comment = text;

    setComment(text);

    handleProofs(chapter);
  }

  function handleDateChange(date: Date) {
    setSkillObtentionDate(date);

    handleObtentionDate(date);
  }

  function isButtonDisabled(): boolean {
    return (
      chapter.isChapterValidated ||
      isChapterContainsGhostTrainee ||
      (chapter.isChapterSubmitted && !isValidPermission(API.Permission.workersSkillProof_review))
    );
  }

  return (
    <View style={Styles.rightPanelContainer}>
      <View style={Styles.rightPanelInputsContainer}>
        <DropDownMultiSelection
          placeholder={t('glossary:skill')}
          title={t('glossary:skill')}
          containerStyle={Styles.skillDropDown}
          inputListContainerStyle={Styles.fullWidth}
          notEditable
          dropDownMenuContainerStyle={Styles.trainingMenuContainer}
          values={dropdownSkillTags}
        />
        <DropDownMultiSelection
          values={dropdownTraineeTags}
          placeholder={t('alex:workerSkillReviewModal.chooseWorker')}
          containerStyle={Styles.workerDropDown}
          inputListContainerStyle={Styles.fullWidth}
          title={t('glossary:worker')}
          notEditable
        />

        <AcquiredAndNotAcquiredButtons
          onChange={handleRadioButtons}
          initialItemIndex={isSkillAcquired}
          radioButtonColor={radioButtonColor}
          textStyle={{ color: radioButtonColor }}
          disabled={isButtonDisabled()}
        />

        <View style={Styles.obtentionDateAndValidityContainer}>
          <InputDateWeb
            hasDateIcon
            inputPlaceholder={t('alex:workerSkillReviewModal.obtention')}
            onChange={(inputName, date) => {
              
              const currentDate = new Date();
              date.setHours(currentDate.getHours());
              date.setMinutes(currentDate.getMinutes());
              date.setSeconds(currentDate.getSeconds());
              handleDateChange(date);
            }}
            inputName={t('alex:workerSkillReviewModal.obtention')}
            value={skillObtentionDate}
            maxDate={new Date()}
            notEditable={isButtonDisabled()}
          />
          {chapter?.skills && chapter?.skills.length === 1 ? (
            <ValidityDisplay startingDate={skillObtentionDate} skill={chapter?.skills[0]} />
          ) : null}
        </View>

        {chapter.skills && chapter.skills.length === 1 ? (
          <InputTextDescription
            placeHolder={t('alex:workerSkillReviewModal.comments')}
            label={t('alex:workerSkillReviewModal.comments')}
            containerStyle={Styles.commentsContainer}
            defaultValue={comment ?? ''}
            onBlur={handleCommentSection}
            notEditable={isButtonDisabled()}
          />
        ) : null}
      </View>
    </View>
  );
};
