import React from 'react';
import { Text } from 'react-native';
import { StyleSheet, StyleProp, TextStyle, ViewStyle, Platform } from 'react-native';
import { Colors, TextFontStyles, fontSize } from 'shared/styles';
import { TouchableOpacityPreventDoubleClick } from '../TouchableOpacityPreventDoubleClick';
interface TextButtonProps {
  text: string;
  disabled?: boolean;
  onPress?: () => void;
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
}

export const TextButton: React.FC<TextButtonProps> = props => {
  const isWebPlatform = Platform.OS === 'web';
  const styles = StyleSheet.create({
    containerStyle: {},
    textStyleWeb: {
      ...TextFontStyles.LatoRegular,
      fontSize: fontSize.Regular,
      color: props.disabled ? Colors.Grey : Colors.Black,
    },
    textStyleMobile: {
      ...TextFontStyles.LatoRegular,
      fontSize: fontSize.Regular,
      color: props.disabled ? Colors.Grey : Colors.Black,
    },
  });

  return (
    <TouchableOpacityPreventDoubleClick
      style={[styles.containerStyle, props.containerStyle]}
      disabled={props.disabled}
      onPress={props.onPress}
    >
      <Text style={[isWebPlatform ? styles.textStyleWeb : styles.textStyleMobile, props.textStyle]}>
        {props.text}
      </Text>
    </TouchableOpacityPreventDoubleClick>
  );
};
