import React from 'react';
import { ViewStyle, TextStyle } from 'react-native';
import { PageSwitchComponent } from '../component/index';

export interface PageSwitchProps {
  containerStyle?: ViewStyle;
  switchTextStyle?: TextStyle;
  startFromZero?: boolean;
  maxIndex: number;
  currentIndex?: number;
  onSwitch?: (index: number) => void;
}

export const PageSwitch: React.FC<PageSwitchProps> = props => {
  return <PageSwitchComponent {...props} />;
};
