import React from 'react';
import Styles from 'skillmgtweb/src/components/my-factory/workstations/component/workstation-modal/component/style';
import { View, TouchableOpacity } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { ModalHeaderText } from 'shared/ui-component/Modal/ModalHeaderText';
import { ModalHeaderStyle } from 'shared/styles/ModalStyles';

interface HeadeRrops {
  title: string;
  handleModalClose: () => void;
}

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

export const ModalHeader: React.FC<HeadeRrops> = props => {
  const { handleModalClose, title } = props;

  return (
    <View style={ModalHeaderStyle}>
      <TouchableOpacity
        style={Styles.workstationModalCloseIconContainer}
        onPress={handleModalClose}
      >
        <IconSVG svgComponent={closeIcon} />
      </TouchableOpacity>
      <ModalHeaderText title={title} />
    </View>
  );
};
