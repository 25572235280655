import React, { useState, useEffect, useContext } from 'react';
import { View, ScrollView, InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as SharedStyles from 'shared/styles';
import Styles from '../../../my-factory/skills/components/Styles';
import { TrainingWorkerTableContainer } from './TrainingWorkerTable/container';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { TrainingBanner } from '../../../banner/trainingBanner/TrainingBanner';
import { Spacings } from 'shared/styles';
import { TrainingWorkstationTableContainer } from './TrainingWorkstationTable/container';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';

interface Props {
  training?: API.Training | undefined;
  handleGoBack: () => void;
}

enum TabIndex {
  Worker,
  Workstation,
}

export const ViewTrainingComponent: React.FC<Props> = props => {
  const tabs: Tab[] = [
    {
      title: {
        title: t('alex:trainingsTab.table.header.5'),
      },
    },
    {
      title: {
        title: t('alex:trainingsTab.table.header.3'),
      },
    },
  ];
  const {
    disableFilter: [, setDisableFilter],
  } = useContext(HeaderFilterContext);

  const isMounted = useIsMounted();

  const [trainingTab, setTrainingTab] = useState<Tab[]>(tabs);

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(TabIndex.Worker);

  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;
      if (props.training) {
        setFirstTitle(capitalizeFirstLetter(props.training.name));
        setSecondTitle('');
      } else {
        setFirstTitle(t('alex:skills.addEditSkill.navigationHeaderTitle'));
        setSecondTitle('');
      }
    });
  }, [props.training]);

  function navigatePage(index: number) {
    if (index === TabIndex.Worker) {
      setDisableFilter(false);
    } else if (index === TabIndex.Workstation) {
      setDisableFilter(true);
    }
    setCurrentTabIndex(index);
  }

  function setTabCount(count: number, index: number) {
    const alterTabs = [...trainingTab];
    alterTabs[index].title.tabTotal = count;
    alterTabs[index].title.disabled = false;
    setTrainingTab([...alterTabs]);
  }

  return (
    <View style={Styles.container}>
      {props.training && <TrainingBanner training={props.training} />}
      <View
        style={[SharedStyles.Styles.cardMain, { flex: 1, padding: 0, marginLeft: Spacings.Medium }]}
      >
        <AnimatedTab
          currentTabIndex={currentTabIndex}
          tabs={trainingTab}
          onTabPress={navigatePage}
        />
        <ScrollView
          style={[
            Styles.horizontalScrollView,
            currentTabIndex !== TabIndex.Worker && { display: 'none' },
          ]}
          contentContainerStyle={Styles.horizontalInnerScrollView}
        >
          {props.training && (
            <TrainingWorkerTableContainer
              training={props.training}
              setWorkerCount={(count: number) => setTabCount(count, 0)}
            />
          )}
        </ScrollView>
        <ScrollView
          style={[
            Styles.horizontalScrollView,
            currentTabIndex !== TabIndex.Workstation && { display: 'none' },
          ]}
          contentContainerStyle={Styles.horizontalInnerScrollView}
        >
          {props.training && (
            <TrainingWorkstationTableContainer
              training={props.training}
              setWorkstationCount={(count: number) => setTabCount(count, 1)}
            />
          )}
        </ScrollView>
      </View>
    </View>
  );
};
