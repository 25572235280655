import React from 'react';
import { View, TouchableOpacity, Text, ScrollView } from 'react-native';
import styles from './styles';
import { Menu, MenuItem } from 'shared/ui-component/Menu';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import { useHistory } from 'react-router-dom';
import { ActivityIndicator } from 'shared/ui-component/Loader/ActivityIndicator';
import { S3Object } from 'backend/src/api';
import { ListFiles } from 'shared/ui-component/ListFiles';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';

const BackIcon = require('shared/assets/svg/icon.arrowLeft.mobile-2.svg').default;

interface Props {
  headerIcon: React.ReactElement;
  headerTitle: string;
  subHeaderComponent?: React.ReactElement;
  menu?: {
    showMenu: boolean;
    menuItems: MenuItem[];
  };
  bannerContent: {
    component: React.ReactElement;
    removeScrollForComponent?: boolean;
  };
  bannerFooter?: React.ReactElement | null;
  loader?: boolean;
  files?: readonly S3Object[];
  backIconHidden?: boolean;

  onBackPress?: () => void;
}

export const BannerSkeleton: React.FC<Props> = props => {
  const {
    menu,
    headerIcon,
    headerTitle,
    subHeaderComponent,
    bannerContent,
    loader,
    files,
    backIconHidden,
    bannerFooter,

    onBackPress,
  } = props;

  const history = useHistory();

  function _onBackPress() {
    if (onBackPress) onBackPress();

    history.goBack();
  }

  return (
    <>
      {loader && (
        <View style={{ position: 'absolute' }}>
          <ActivityIndicator />
        </View>
      )}
      <View style={[SharedStyles.Styles.cardMain, SharedStyles.Styles.bannerStyle]}>
        <TouchableOpacity style={styles.backButtonContainer} onPress={_onBackPress}>
          {!backIconHidden && <IconSVG svgComponent={BackIcon} />}
        </TouchableOpacity>
        <View style={styles.menuContainer}>
          {menu && menu.showMenu && <Menu menuItems={menu.menuItems} position={'bottom-right'} />}
        </View>
        <View style={styles.headerIconContainer}>{headerIcon}</View>
        <View style={styles.headerTitleContainer}>
          <EllipsisWithTooltip
            textStyle={styles.headerTitle}
            text={headerTitle}
            style={styles.header}
          />
          <View>
            <Text style={styles.subHeaderComponentTitle}>{subHeaderComponent}</Text>
          </View>
        </View>

        <View style={styles.bannerContentContainer}>
          <View style={styles.bannerContent}>
            {bannerContent.removeScrollForComponent ? (
              <View>{bannerContent.component}</View>
            ) : (
              <ScrollView>{bannerContent.component}</ScrollView>
            )}

            {files && files.length ? (
              <ScrollView
                style={styles.scrollContainer}
                contentContainerStyle={styles.fileScrollContent}
              >
                <ListFiles files={files} containerStyle={styles.filesContainer} />
              </ScrollView>
            ) : null}
            {bannerFooter}
          </View>
        </View>
      </View>
    </>
  );
};
