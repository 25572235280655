import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as API from 'shared/backend-data';

export const HeaderTitleContext = createContext<{
  firstTitle: string;
  secondTitle: string;
  setFirstTitle: React.Dispatch<React.SetStateAction<string>>;
  setSecondTitle: React.Dispatch<React.SetStateAction<string>>;
  parentIdsWithDataType?: [API.TreeDataType, string[]];
  setParentIdsWithDataType: React.Dispatch<
    React.SetStateAction<[API.TreeDataType, string[]] | undefined>
  >;
  onPressBack: () => void;
  setOnPressBack: React.Dispatch<React.SetStateAction<() => void>>;
}>({
  firstTitle: '',
  secondTitle: '',
  setParentIdsWithDataType: () => { },
  setFirstTitle: () => { },
  setSecondTitle: () => { },
  onPressBack: () => { },
  setOnPressBack: () => () => { },
});

export const HeaderTitleProvider: React.FC = props => {
  const history = useHistory();
  const [firstTitle, setFirstTitle] = useState<string>('');
  const [secondTitle, setSecondTitle] = useState<string>('');
  const [parentIdsWithDataType, setParentIdsWithDataType] = useState<
    [API.TreeDataType, string[]]
  >();
  const [onPressBack, setOnPressBack] = useState(() => () => {
    history.goBack();
  });

  return (
    <HeaderTitleContext.Provider
      value={{
        firstTitle,
        secondTitle,
        parentIdsWithDataType,
        setParentIdsWithDataType,
        setFirstTitle,
        setSecondTitle,
        onPressBack,
        setOnPressBack,
      }}
    >
      {props.children}
    </HeaderTitleContext.Provider>
  );
};
