import React, { useState } from 'react';
import { View, TouchableOpacity, StyleProp, TextStyle } from 'react-native';
import style from './style';
import { IconSVG } from '../Icon';
import { Spacings } from '../../styles';
import { MenuCard } from './MenuCard';

const moreIcon = require('shared/assets/svg/button.more.svg').default;
export interface MenuItem {
  label: string;
  subLabel?: string;
  disable?: boolean;
  style?: StyleProp<TextStyle>;
  subLabelStyle?: StyleProp<TextStyle>;
  onPress: () => void;
}

export enum MenuWidth {
  Small,
  Medium,
  Large,
}

interface Props {
  menuItems: MenuItem[];
  position?: 'bottom-left' | 'top-left' | 'bottom-right' | 'top-right';
  largeThreeDot?: boolean;
  menuWidth?: MenuWidth;

  onMenuPress?: (value: boolean) => void;
}

export const Menu: React.FC<Props> = props => {
  const { largeThreeDot = false, onMenuPress, position } = props;

  const [showEditMenu, setShowEditMenu] = useState<boolean>(false);

  let iconSize = Spacings.Standard + Spacings.Small;
  if (largeThreeDot) {
    iconSize = Spacings.CardPadding * 2;
  }

  return (
    <View style={style.rootContainer}>
      <View style={style.rightIcon}>
        <TouchableOpacity
          onPress={() => {
            setShowEditMenu(!showEditMenu);
            onMenuPress && onMenuPress(true);
          }}
        >
          <IconSVG svgComponent={moreIcon} size={{ height: iconSize, width: iconSize }} />
        </TouchableOpacity>
      </View>
      {showEditMenu && (
        <MenuCard
          position={position}
          menuWidth={props.menuWidth}
          menuItems={props.menuItems}
          setShowMenu={setShowEditMenu}
          onMenuPress={onMenuPress}
        />
      )}
    </View>
  );
};
