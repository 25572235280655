import React, { useState, useEffect, useCallback, useContext } from 'react';
import * as API from 'shared/backend-data';
import { TouchableOpacity } from 'react-native';
import { ThumbIcon } from '../Icon';
import { useCallOnHover } from '../../hooks/CallOnHover';
import { Colors } from '../../styles';
import ReactDOM from 'react-dom';
import * as _ from 'lodash-es';
import logger from '../../util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { GlobalDataContext } from '../../skillmgt/context/GlobalDataContext';

interface Props {
  shift?: API.Shift;
  workstation: API.Workstation;
  workstationFooterData: API.WorkstationTargetActualWithDetails | undefined;
  workstationsTargetActual: API.WorkstationTargetActual;

  handleShiftIconPress: (workstationFooterData: API.WorkstationTargetActualWithDetails) => void;
  setOpenTargetsMenu: (bool: boolean) => void;
  setSelectedTargetsMenuElement: (element?: Element) => void;
  setTreeNode: (treeNode?: API.TreeNode<API.TreeDataType> | undefined) => void;
}

const ShiftThumbIcon: React.FC<Props> = props => {
  const {
    shift,
    workstationFooterData,
    workstation,
    workstationsTargetActual,

    handleShiftIconPress,
    setOpenTargetsMenu,
    setSelectedTargetsMenuElement,
    setTreeNode,
  } = props;

  const ref = useCallOnHover<TouchableOpacity>(
    Colors.BlueRollover,
    e => onHover(e),
    e => onUnHover(e),
  );

  const debounceHandleTreeNode = useCallback(
    _.debounce((workstationId?: string) => handleTreeNode && handleTreeNode(workstationId), 100),
    [],
  );

  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);

  const [isWorkstationLevelTargetReached, setIsWorkstationLevelTargetReached] = useState<
    boolean | string
  >('...');

  useEffect(() => {
    computeWorkstationObjectivesCountColor(workstation, workstationsTargetActual);
  }, [workstation, shift, workstationsTargetActual]);

  const isMounted = useIsMounted();

  async function computeWorkstationObjectivesCountColor(
    workstation: API.Workstation,
    workstationsTargetActual: API.WorkstationTargetActual,
  ) {
    if (shift && (!workstation.shiftIds || workstation.shiftIds.includes(shift?.id))) {
      setIsWorkstationLevelTargetReached('...');
    }
    const _isWorkstationLevelTargetReached = await API.isWorkstationLevelTargetReached(
      workstationsTargetActual,
      workstationTargetsStartingAtLevel,
      shift ? shift.id : workstation.id,
    );
    if (!isMounted.current) return;
    if (API.isFailure(_isWorkstationLevelTargetReached)) {
      logger.warn(_isWorkstationLevelTargetReached);
      return;
    }
    if (workstationsTargetActual) {
      setIsWorkstationLevelTargetReached(_isWorkstationLevelTargetReached);
    }
  }

  function handleClick() {
    if (ref) {
      const node = ReactDOM.findDOMNode(ref.current) as Element;

      setSelectedTargetsMenuElement(node);
      setOpenTargetsMenu(true);

      if (workstationFooterData) handleShiftIconPress(workstationFooterData);
    }
  }

  function onHover(event: Event) {
    debounceHandleTreeNode(workstation.id);
  }

  function onUnHover(event: Event) {}

  function handleTreeNode(workstationId?: string) {
    if (!workstationId) {
      if (setTreeNode) setTreeNode(undefined);
      return;
    }

    const _treeNode = API.Tree.getTreeNode(workstationId);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }
    if (setTreeNode) setTreeNode(_treeNode);
  }

  return (
    <TouchableOpacity
      ref={ref}
      onPress={() => {
        handleClick();
      }}
    >
      <ThumbIcon status={isWorkstationLevelTargetReached} type={'row'} />
    </TouchableOpacity>
  );
};

export default ShiftThumbIcon;
