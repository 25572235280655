import React, { useState, useContext, useRef } from 'react';
import WebModal from 'modal-react-native-web';
import Modal from 'react-native-modal';
import { Animated, Platform } from 'react-native';

/**
 * We provide modal using a generic Context API
 */
export interface ModalContext {
  displayLoader: (config: ModalConfig) => void;
  hideLoader: () => void;
  displayModal: (config: ModalConfig) => void;
  hideModal: () => void;
}

export interface ModalConfig {
  content: JSX.Element;
  hideOnClick?: boolean;
  nonDismissable?: boolean;
  
  /** opaque behinf the Modal (triggers an annimation) */
  opaque?: boolean;
  mobileModalOpacity?: number;
}

/**
 * Expose Context for use (typically with useContext)
 */
const ModalContext = React.createContext<ModalContext>({
  displayLoader: _ => {
    
    
    throw new Error('displayLoader() not implemented');
  },
  hideLoader: () => {
    throw new Error('hideLoader() not implemented');
  },
  
  displayModal: _ => {
    
    
    throw new Error('displayModal() not implemented');
  },

  hideModal: () => {
    throw new Error('hideModal() not implemented');
  },
});

/**
 * Hooks to use inside App
 */
export const useModal = () => {
  return useContext(ModalContext);
};

const ModalContainer: React.FC<{
  config: ModalConfig;
}> = props => {
  const modal = useModal();
  const { config } = props;
  const isPlatformWeb = Platform.OS === 'web';

  if (isPlatformWeb) {
    return (
      <WebModal animationType="fade" transparent={!config.opaque}>
        {config.content}
      </WebModal>
    );
  }
  return (
    <Modal
      style={[{ margin: 0 }]}
      animationIn="fadeIn"
      animationOut="fadeOut"
      backdropOpacity={config.opaque ? 1 : config.mobileModalOpacity}
      isVisible={true}
      onBackdropPress={modal.hideModal}
    >
      {config.content}
    </Modal>
  );
};

/**
 * ModalProvider acts as a Context Provider, and a state container
 */
export const ModalProvider: React.FC<{ children: any }> = props => {
  const [config, setConfig] = useState<ModalConfig | null>(null);
  const [loader, setLoader] = useState<ModalConfig | null>(null);

  const value = useRef(new Animated.Value(0));

  
  
  
  
  
  const hideModal = () => {
    if (!config?.nonDismissable) {
      if (config?.opaque) {
        Animated.timing(value.current, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }).start(() => setConfig(null));
      } else {
        setConfig(null);
      }
    }
  };

  const displayModal = (config: ModalConfig) => {
    setConfig(config);
    if (config.opaque) {
      Animated.timing(value.current, { toValue: 1, duration: 300, useNativeDriver: false }).start();
    }
  };

  const displayLoader = (LoaderConfig: ModalConfig) => {
    setLoader(LoaderConfig);
  };

  const hideLoader = () => {
    setLoader(null);
  };

  return (
    <ModalContext.Provider value={{ displayModal, hideModal, displayLoader, hideLoader }}>
      {props.children}
      {config ? ( 
        <ModalContainer config={config} />
      ) : loader ? (
        <ModalContainer config={loader} />
      ) : null}
    </ModalContext.Provider>
  );
};
