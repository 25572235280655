import { StyleSheet, Dimensions, ViewStyle, RegisteredStyle, View } from 'react-native';
import ReactDOM from 'react-dom';
import { TextFontStyles, Spacings } from '../../styles';
import { Colors } from 'shared/styles/Colors';

export const Styles = StyleSheet.create({
  cardMenuContainer: {
    position: 'absolute',
    width: Spacings.Big * 8,
    marginTop: 20,
    paddingVertical: 15,
    paddingHorizontal: 0,
    zIndex: 9,
    marginLeft: -(Spacings.Big * 8),
    alignItems: 'center',
  },
  cardMenuContainerLarge: {
    position: 'absolute',
    width: Spacings.Big * 8 + Spacings.Large,
    height: Spacings.Big * 7 + Spacings.Large,
    marginTop: 20,
    marginLeft: -343,
    paddingVertical: 15,
    paddingHorizontal: 0,
    zIndex: 9,
    alignItems: 'center',
  },
  cardMenuOnlyContainer: {
    width: Spacings.Big * 7,
    height: Spacings.Big * 4,
    marginTop: 20,
    paddingVertical: 15,
    paddingHorizontal: 0,
    zIndex: 9,
    alignItems: 'center',
  },
  cardMenuOption: {
    width: '100%',
    height: 40,
    paddingLeft: 23,
    alignItems: 'center',
    flexDirection: 'row',
  },
  cardMenuOptionWithTopSpace: {
    width: '100%',
    height: 40,
    paddingLeft: 23,
    marginTop: Spacings.xMedium,
    alignContent: 'center',
    justifyContent: 'center',
  },
  cardMenuOptionWithBottomSpace: {
    width: '100%',
    height: 40,
    paddingLeft: 23,
    marginBottom: Spacings.xMedium,
    alignContent: 'center',
    justifyContent: 'center',
  },
  cardMenuOptionText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
  },
  cardMenuOnlyOptionText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    paddingLeft: Spacings.xMedium,
  },
  closeIconContainer: {
    width: Spacings.Medium * 2,
    height: Spacings.Medium + Spacings.Standard,
    alignContent: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: Spacings.Unit,
    right: Spacings.Unit,
  },
  excelIconContainer: {
    width: Spacings.Medium * 2,
    height: Spacings.Medium + Spacings.Standard,
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  excelIconContainerDashboard: {
    width: Spacings.Medium * 2,
    height: Spacings.Medium + Spacings.Standard,
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  importProgressRowDetails: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: Spacings.Large,
  },
  importProgressRowSeparator: {
    height: 1,
    width: Spacings.Big * 4,
    backgroundColor: Colors.DarkYellow,
    marginTop: Spacings.xMedium,
    marginBottom: Spacings.Unit,
  },
  importProgressFooter: {
    paddingTop: Spacings.Large,
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressBar: {
    width: Spacings.Standard * 10,
    height: Spacings.Small,
    marginHorizontal: Spacings.Standard,
    marginBottom: Spacings.Medium,
  },
  percentageTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: Spacings.Medium + Spacings.xMedium,
    alignItems: 'center',
    marginLeft: Spacings.Medium,
  },
  percentageLabelTextStyle: {
    ...TextFontStyles.LatoMedium,
    fontSize: Spacings.Medium,
    alignItems: 'center',
    marginRight: Spacings.Medium,
  },
  infoIconContainer: {
    height: 20,
    width: 20,
  },
  infoMessageContainer: {
    width: '85%',
    paddingVertical: Spacings.Standard,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  infoMessageText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    fontSize: 14,
  },
  loaderCardContainerDuringImport: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: 10,
    width: 380,
    height: 280,
  },
  loaderCardContainerAfterImport: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: 10,
    width: 300,
    height: 200,
  },
  textStyle: {
    ...TextFontStyles.LatoRegular,
    textAlign: 'center',
    fontSize: Spacings.Medium,
    alignContent: 'center',
    alignItems: 'center',
  },
  rowView: {
    flexDirection: 'row',
    width: Spacings.Big * 4,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerRowView: {
    flexDirection: 'row',
    width: Spacings.Big * 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  importProgressTextStyle: {
    ...TextFontStyles.LatoRegular,
    textAlign: 'center',
    fontSize: Spacings.Medium,
    alignContent: 'center',
    alignItems: 'center',
  },
  exportLoadingTextStyle: {
    ...TextFontStyles.LatoRegular,
    textAlign: 'center',
    fontSize: Spacings.Medium,
  },
  headerTextStyle: {
    ...TextFontStyles.LatoBold,
    fontSize: Spacings.xMedium + Spacings.Small,
  },
  buttonTextStyle: {
    ...TextFontStyles.LatoNormal,
    padding: 3,
    fontSize: 14,
  },
  titleText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 26,
  },
  modalFlexView: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  buttonStyle: {
    width: 336,
    height: Spacings.Large,
    marginBottom: Spacings.Medium,
    alignSelf: 'center',
    borderWidth: 0,
    backgroundColor: Colors.Yellow,
  },
  messageText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    lineHeight: Spacings.Standard,
    paddingTop: 43,
    paddingLeft: Spacings.Medium + Spacings.Standard,
    paddingRight: Spacings.Large + Spacings.Standard,
  },
  okButton: { width: 100 },
  toolTipText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
  },
  toolTipMenuOnlyText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    paddingLeft: Spacings.xMedium,
  },
  toolTipContainerStyle: {
    marginLeft: -Spacings.Standard,
  },
});

export function getCardLoaderStyle(
  refView: React.RefObject<View>,
): ViewStyle | RegisteredStyle<ViewStyle> {
  let xOffset: number = 0;
  let yoffset: number = 0;

  const { height, width } = Dimensions.get('window');

  if (refView.current) {
    const node = ReactDOM.findDOMNode(refView.current) as Element;
    xOffset = node.getBoundingClientRect().left;
    yoffset = node.getBoundingClientRect().top;
  }

  return {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    flex: 1,
    height: height,
    width: width,
    left: -xOffset,
    top: -yoffset,
  };
}
