import React, { useEffect, useState, useRef, FC } from 'react';
import { View, Text } from 'react-native-web';
import { Card } from 'shared/ui-component/Card';
import * as _ from 'lodash-es';
import Styles from './Styles';
import ReactDOM from 'react-dom';
import { IEllipsisWithTooltip, Position } from '.';
import { t } from 'shared/localisation/i18n';
import { isGhostWorker, shortenLongWorkerNames } from 'shared/util/Worker';
import * as API from 'shared/backend-data';
import { IconSVG } from '../Icon';
import { Colors } from '../../styles/Colors';
import { Spacings } from '../../styles';
import { SvgProps } from 'react-native-svg';
import { WorkstationOrOrgUnitPath } from '../../components/WorkstationOrOrgUnitPath';

const ShiftIcon = require('shared/assets/svg/icon.shift.svg').default;
const WorkstationIcon: FC = require('shared/assets/svg/icon.workstation.svg').default;
const UnitIcon = require('shared/assets/svg/icon.unit.small.svg').default;

export const WebEllipsisText: React.FC<IEllipsisWithTooltip> = props => {
  const {
    text,
    textStyle,
    toolTipTextStyle = props.textStyle,
    type,
    position,
    mouseHoverText,
    mouseHoverTextContainer,
    disableToolTip,
    iconType,
    tagPath,

    handleHover,
    onMount,
  } = props;

  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [formattedText, setFormattedText] = useState<string>(text);
  const [isWorkerInvisible, setIsWorkerInvisible] = useState<boolean>(false);
  const [nameIsLong, setNameIsLong] = useState<boolean>(false);

  const textInput = useRef<any>();
  const viewInput = useRef<any>();

  useEffect(() => {
    if (onMount) onMount();
  }, []);

  useEffect(() => {
    if (textInput.current && viewInput.current) {
      setIsWorkerInvisible(isGhostWorker(text));
      if (tagPath) {
        if (tagPath[1].length > 1) setNameIsLong(true);
      } else {
        checkEllipsis(viewInput, textInput);
      }
    }
  }, [textInput, text, tagPath]);

  async function onMouseEnter() {
    await checkEllipsis(viewInput, textInput);
    if (handleHover) handleHover(true);
    setIsHover(true);
    if (nameIsLong || isWorkerInvisible) setShowToolTip(true);
  }

  const onMouseLeave = () => {
    if (handleHover) handleHover(false);
    setIsHover(false);
    setShowToolTip(false);
  };

  async function checkEllipsis(
    viewInput: React.MutableRefObject<any>,
    textInput: React.MutableRefObject<any>,
  ) {
    const viewElement = ReactDOM.findDOMNode(viewInput.current) as HTMLElement;
    const textElement = ReactDOM.findDOMNode(textInput.current) as HTMLElement;

    if (viewElement && textElement) {
      const textStyles = getComputedStyle(textElement);
      const textElementWidth = parseFloat(textStyles.height);

      const viewStyles = getComputedStyle(viewElement);
      const viewElementWidth = parseFloat(viewStyles.height);

      if (textElementWidth > viewElementWidth) {
        setNameIsLong(true);
        if (type === 'name') {
          const name = await shortenLongWorkerNames(text);
          if (API.isFailure(name)) return;

          setFormattedText(name);
        }
      } else if (textElementWidth < viewElementWidth) {
        setNameIsLong(false);
      }
    }
  }

  function getIcon(): FC<SvgProps> | undefined {
    switch (iconType) {
      case API.DataType.SHIFT:
        return ShiftIcon;
      case API.DataType.WORKSTATION:
        return WorkstationIcon;
      case API.DataType.ORGUNIT:
        return UnitIcon;
      default:
        undefined;
    }
  }

  function renderIcon() {
    return getIcon() ? (
      <IconSVG
        svgComponent={getIcon()!}
        containerStyle={Styles.tagIconContainer}
        color={Colors.Black}
        size={{ width: Spacings.Standard, height: Spacings.Standard }}
      />
    ) : null;
  }

  return (
    <View
      ref={viewInput}
      style={[Styles.container, props.style, isHover && mouseHoverTextContainer]}
      onMouseLeave={() => onMouseLeave()}
    >
      <View onMouseEnter={() => onMouseEnter()}>
        <Text numberOfLines={1} style={[Styles.tableText, textStyle, isHover && mouseHoverText]}>
          {type === 'name' && nameIsLong ? formattedText : text}
        </Text>
        <Text
          ref={textInput}
          numberOfLines={2}
          style={[Styles.tableText, textStyle, Styles.fakeTextContainter]}
        >
          {text}
        </Text>
      </View>
      {showToolTip && !disableToolTip && (
        <Card
          style={[
            Styles.cardContainer,
            position === Position.MIDDLE
              ? Styles.cardContainerMiddle
              : position === Position.TOP
              ? Styles.cardContainerTop
              : Styles.cardContainerBottom,
            props.toolTipContainerStyle,
          ]}
        >
          {renderIcon()}
          <View style={Styles.textDescriptionContainer}>
            <Text numberOfLines={10} style={[Styles.tableText, toolTipTextStyle]}>
              {isWorkerInvisible ? t('alex:workerProfile.ghostWorkerdescription') : text}
            </Text>
            {tagPath && (
              <WorkstationOrOrgUnitPath
                textStyle={Styles.pathStyle}
                parentIdsWithDataType={tagPath}
                containerStyle={Styles.pathContainerStyle}
              />
            )}
          </View>
        </Card>
      )}
    </View>
  );
};
