import { StyleSheet } from 'react-native';
import { TextFontStyles, Spacings, Colors } from 'shared/styles';

const styles = StyleSheet.create({
  cardContainer: {
    width: 300,
    shadowColor: Colors.ShadowColor2,
    borderRadius: 8,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    paddingTop: Spacings.Unit * 3,
    paddingRight: Spacings.Unit * 3.5,
    paddingLeft: Spacings.Unit * 5,
    backgroundColor: Colors.GreyUltraLight,
    marginBottom: Spacings.xMedium,
  },
  trainingTitle: {
    ...TextFontStyles.LatoMedium,
    fontSize: 16,
    marginBottom: Spacings.Unit * 1.5,
  },
  spacer: { height: Spacings.Unit * 2.5 },
  buttonSpacer: { height: Spacings.Medium },
});

export default styles;
