import React, { useEffect, useState, useCallback, useRef } from 'react';
import { View, Text } from 'react-native';
import * as _ from 'lodash-es';
import Styles from './MobileEllipsisTextStyles';
import { Colors } from '../../styles/Colors';
import { IEllipsisWithTooltip } from '.';
import { shortenLongWorkerNames } from 'shared/util/Worker';
import * as API from 'shared/backend-data';

export const MobileEllipsisText: React.FC<IEllipsisWithTooltip> = props => {
  const { text, textStyle, type, onMount } = props;
  useEffect(() => {
    if (onMount) onMount();
  }, []);

  const [formattedText, setFormattedText] = useState<string>(text);
  const nameIsLong = useRef<boolean>(false);

  const onTextLayout = useCallback(async event => {
    if (event.nativeEvent.lines.length > 1) {
      nameIsLong.current = true;
      if (type === 'name') {
        const name = await shortenLongWorkerNames(formattedText);
        if (API.isFailure(name)) return;

        setFormattedText(name);
      }
    } else {
      nameIsLong.current = false;
    }
  }, []);

  return (
    <View style={[{ width: '100%', flexDirection: 'column' }, props.style]}>
      <Text
        onTextLayout={onTextLayout}
        style={[Styles.tableText, textStyle, { color: Colors.Transparent, position: 'absolute' }]}
      >
        {text}
      </Text>
      <Text style={[Styles.tableText, textStyle]} numberOfLines={1}>
        {type === 'name' && nameIsLong.current ? formattedText : text}
      </Text>
    </View>
  );
};
