import React, { useState } from 'react';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { View, Text, TouchableOpacity } from 'react-native';
import { UserContext, CognitoAuthErrorCodes } from 'shared/context/UserContext';
import { InputVerificationCode } from 'shared/ui-component/Input';
import { KeyboardAvoidingViewWrapper } from 'shared/ui-component/KeyboardAvoidingView';
import Styles from './Style';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { IconSVG } from 'shared/ui-component/Icon';
import arrowLeft from 'shared/assets/svg/icon.arrowLeft.mobile-2.svg';
import { YellowButton, TextButton } from 'shared/ui-component/Button';
import { t } from 'shared/localisation/i18n';
import { Colors, TextFontStyles } from 'shared/styles';
import { InputsValue, useCustomForm } from 'shared/hooks/CustomForm';
import logger from '../../../util/Logger';

enum InputIds {
  UserVerificationCode = 'UserVerificationCode',
}

interface Props {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  isWebPlatfrom: boolean;
  handleBackPress: () => void;
  handleHomeNavigation: () => void;
}

export const ValidateUserLayout: React.FC<Props> = props => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [componentResetTrigger, setComponentResetTrigger] = useState<number>(0);
  const initialOtpValues = ['', '', '', '', '', ''];
  const isMounted = useIsMounted();

  const { enableFormSubmit, onChange, onSubmit } = useCustomForm<string[]>(
    {
      [InputIds.UserVerificationCode]: {
        isFieldMandatory: true,
        initialValue: initialOtpValues,
        validator: inputValidator,
      },
    },
    onSubmitForm,
  );

  function onSubmitForm(values: InputsValue<string[]>) {
    const otp = values[InputIds.UserVerificationCode].join('');
    if (!_.isEmpty(otp)) {
      handleConfirmOtp(otp);
    }
  }

  function inputValidator(inputValue: string[] | undefined) {
    if (!inputValue) return false;

    let inputValid = true;
    inputValue.forEach(value => {
      if (value === '') inputValid = false;
    });

    return inputValid;
  }

  async function handleConfirmOtp(otp: string): Promise<void> {
    const user = UserContext.getUser();
    if (API.isFailure(user)) {
      logger.error('user not set');
      return;
    }

    setLoading(true);
    const otpResult = await UserContext.confirmOtp(user.id, otp);
    if (isMounted.current) {
      if (API.isFailure(otpResult)) {
        setLoading(false);
        if (otpResult.data.code === CognitoAuthErrorCodes.CodeMismatchException) {
          setError(t('common:auth.validation.errorInvalidCode'));
        } else {
          setError(otpResult.data.message);
        }
      } else {
        const signInResult = await UserContext.signIn(props.username, props.password);
        if (isMounted.current) {
          setLoading(false);
          if (API.isFailure(signInResult)) {
            setError(signInResult.data.message);
          } else {
            props.handleHomeNavigation();
          }
        }
      }
    }
  }
  async function resendCode() {
    
    setComponentResetTrigger(componentResetTrigger + 1);

    const user = UserContext.getUser();
    if (API.isFailure(user)) {
      logger.error('User not set');
      return;
    }

    const result = await UserContext.resendSignUpCode(user.id);
    if (API.isFailure(result)) {
      if (isMounted.current) setError(result.data.message);
    }
  }

  return (
    <KeyboardAvoidingViewWrapper style={Styles.appContainer}>
      <View style={Styles.rootContainer}>
        {props.isWebPlatfrom ? (
          <View style={Styles.topContainer}>
            <TouchableOpacity style={Styles.backButton} onPress={props.handleBackPress}>
              <IconSVG
                svgComponent={arrowLeft}
                color={Colors.Black}
                containerStyle={Styles.IconContainer}
              />
            </TouchableOpacity>
            <View style={Styles.headerTextContainer}>
              <Text style={Styles.headerText}>
                {t('common:auth.signup.headerNewAccount', { app: t('alex:app.name') })}
              </Text>
            </View>
          </View>
        ) : (
          <View style={Styles.topContainer}>
            <TouchableOpacity style={Styles.backButton} onPress={props.handleBackPress}>
              <IconSVG svgComponent={arrowLeft} color={Colors.Black} />
            </TouchableOpacity>
            <View style={Styles.headerTextContainer}>
              <Text style={Styles.headerText}>
                {t('common:auth.signup.headerNewAccount', { app: t('alex:app.name') })}
              </Text>
            </View>
          </View>
        )}

        <View style={Styles.middleContainer}>
          <Text style={Styles.bodyText}>
            {t('common:auth.validateUser.instruction', { name: props.firstName })}
          </Text>
          <Text style={Styles.boldBodytext}> {props.username}</Text>
          <Text style={Styles.bodyText}>{t('common:auth.validateUser.enterCode')}</Text>
        </View>

        <View style={Styles.inputContainer}>
          <InputVerificationCode
            inputId={InputIds.UserVerificationCode}
            key={componentResetTrigger}
            style={{ backgroundColor: 'white', borderWidth: 0 }}
            inputStyle={{ ...TextFontStyles.LatoBlack, fontSize: 26, height: 26 }}
            initialValues={initialOtpValues}
            onChange={onChange}
            onFilled={onSubmit}
          />
        </View>

        <Text style={Styles.errorMessage}>{error ?? ''}</Text>
        <View style={Styles.bottomContainer}>
          <YellowButton
            style={enableFormSubmit ? Styles.connectButton : Styles.connectButtonDisabled}
            onPress={onSubmit}
            disabled={!enableFormSubmit}
            text={t('common:button.activate')}
            textStyle={Styles.connectText}
          />
          {!props.isWebPlatfrom && (
            <TextButton
              text={t('common:auth.validateUser.resendOtp')}
              onPress={resendCode}
              textStyle={Styles.resendText}
            />
          )}
        </View>
        {props.isWebPlatfrom && (
          <View style={Styles.termsConditionContainer}>
            <TextButton
              text={t('common:auth.validateUser.resendOtp')}
              onPress={resendCode}
              textStyle={Styles.resendText}
            />
          </View>
        )}
        {loading && <Loader />}
      </View>
    </KeyboardAvoidingViewWrapper>
  );
};
