import { StyleSheet } from 'react-native';
import { Spacings } from 'shared/styles/Dimensions';

const styles = StyleSheet.create({
  datePicker: {
    marginRight: Spacings.CardPadding * 2,
    top: -28,
  },
  inputList: {
    width: 250,
    alignSelf: 'flex-end',
    right: 0,
  },
});

export default styles;
