import React, { useState, useEffect } from 'react';
import WebModal from 'modal-react-native-web';
import { View, InteractionManager, ScrollView } from 'react-native';
import * as API from 'shared/backend-data';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import * as _ from 'lodash-es';
import Styles from './style';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { Header } from './Header';
import { LeftPanel } from './LeftPanel';
import { RightPanel } from './RightPanel';
import { t, capitalizeFirstLetter } from 'shared/localisation/i18n';
import {
  ModalBackgroundStyle,
  ModalBodyWithAnchorStyle,
  ModalCardStyle,
  ModalFooterStyle,
} from 'shared/styles/ModalStyles';
import { YellowButton, TextButton } from 'shared/ui-component/Button';
import { ModalUtils } from 'shared/ui-component/Modal';
import { ImportExportFailureMessageKey } from 'shared/util/ExcelUtils';
import { UnitModalProps } from '../container';
import { UnitDetails } from '../container/index';
import { ReassignmentModal } from 'skillmgtweb/src/components/reassignment-modal/container';
import { rootOrganizationalUnitParentId } from 'shared/backend-data/SharedUtil';

interface UnitModalComponentProps extends UnitModalProps {
  shifts: API.Shift[] | undefined;
  assignments: API.AssignmentWithUnitDetails[];
  preSelectedParentUnit?: API.OrganizationalUnit;

  setIsLoading: (loader: boolean) => void;
  onSubmit: (UnitDetails: UnitDetails) => Promise<void>;
  handleShifts: (shifts: API.ShiftCreateInput[]) => void;
}

export const UnitModalComponent: React.FC<UnitModalComponentProps> = props => {
  const isMounted = useIsMounted();

  const {
    node,
    shifts,
    assignments,
    preSelectedParentUnit,

    handleShifts,
    setIsLoading,
    handleModalClose,
    onSubmit,
    duplicateNode,
    deleteNode,
  } = props;

  const modal = ModalUtils.useModal();

  const [UnitName, setUnitName] = useState<string | undefined>(node?.object.name);
  const [shiftIds, setShiftIds] = useState<string[]>([]);
  const [unitDescription, setUnitDescription] = useState<string | null | undefined>(
    node?.object?.description,
  );
  const [selectedParentOrgUnit, setSelectedParentOrgUnit] = useState<
    DropDownOption<API.OrganizationalUnit> | undefined
  >();
  const [orgUnitOptions, setOrgUnitOptions] = useState<DropDownOption<API.OrganizationalUnit>[]>(
    [],
  );
  const [isRootUnit, setIsRootUnit] = useState<boolean>(false);
  const [showReassignmentModal, setShowReassignmentModal] = useState<boolean>(false);
  const [isDataValid, setIsDataValid] = useState<true | string>('');

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (preSelectedParentUnit)
        setSelectedParentOrgUnit({
          key: preSelectedParentUnit.id,
          label: preSelectedParentUnit.name,
          value: preSelectedParentUnit,
        });
      fetchOrgUnitOptions();
    });
  }, []);

  useEffect(() => {
    if (node?.object.order === 0) setIsRootUnit(true);
  }, [node]);

  useEffect(() => {
    checkDataValidity();
  }, [UnitName, selectedParentOrgUnit, isRootUnit]);

  async function submit(): Promise<void> {
    if (isDataValid !== true) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: isDataValid,
          warningAcceptButton: capitalizeFirstLetter(t('common:button.gotIt')),
        }),
      );
      return;
    }

    await onSubmit({
      name: UnitName,
      parentId: isRootUnit ? rootOrganizationalUnitParentId : selectedParentOrgUnit?.key,
      description: unitDescription,
      shiftIds: shiftIds,
    });

    if (handleModalClose) handleModalClose();
  }

  function handleUnitDeletion() {
    if (!node) return;

    if (assignments.length) {
      setShowReassignmentModal(true);
    } else {
      if (deleteNode) deleteNode(node);
      if (handleModalClose) handleModalClose();
    }
  }

  function fetchOrgUnitOptions(): void {
    const orgUnits = API.getOrganizationalUnits();

    setOrgUnitOptions(
      orgUnits.map(ou => {
        if (node?.object.parentId && node.object.parentId === ou.id)
          setSelectedParentOrgUnit({ key: ou.id, label: ou.name, value: ou });

        return { key: ou.id, label: ou.name, value: ou };
      }),
    );
  }

  function checkDataValidity(): void {
    if (!UnitName) {
      setIsDataValid(
        String(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, {
            name: t('glossary:workstation'),
          }),
        ),
      );
      return;
    }

    if (!selectedParentOrgUnit && !isRootUnit) {
      setIsDataValid(
        String(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, {
            name: t('glossary:organizationalUnit'),
          }),
        ),
      );
      return;
    }

    setIsDataValid(true);
  }

  return (
    <WebModal animationType="fade" transparent onRequestClose={() => handleModalClose()}>
      <View style={ModalBackgroundStyle}>
        <View style={ModalCardStyle}>
          <Header
            handleModalClose={handleModalClose}
            title={t('glossary:organizationalUnitAbbreviated')}
          />
          <ScrollView
            contentContainerStyle={[Styles.unitModalPanelContainer, ModalBodyWithAnchorStyle]}
          >
            <LeftPanel
              UnitDescription={unitDescription}
              setUnitDescription={setUnitDescription}
              UnitName={UnitName}
              orgUnitOptions={orgUnitOptions}
              selectedOrgUnit={selectedParentOrgUnit}
              setSelectedParentOrgUnit={setSelectedParentOrgUnit}
              setUnitName={setUnitName}
              onSubmitForm={submit}
              isRootUnit={isRootUnit}
            />

            <RightPanel
              handleShifts={handleShifts}
              shifts={shifts}
              unit={node && API.isOrganizationalUnit(node?.object) ? node.object : undefined}
              setIsLoading={setIsLoading}
              setShiftIds={shiftIds => setShiftIds(shiftIds)}
            />
          </ScrollView>
          <View style={ModalFooterStyle}>
            <View style={Styles.unitModalYellowButtonContainer}>
              {node && !isRootUnit && (
                <>
                  <TextButton
                    containerStyle={Styles.buttonsContainer}
                    onPress={() => {
                      handleUnitDeletion();
                    }}
                    text={t('common:button.delete')}
                  />
                  <TextButton
                    containerStyle={Styles.buttonsContainer}
                    onPress={() => {
                      duplicateNode(node);
                      if (handleModalClose) handleModalClose();
                    }}
                    text={t('alex:unitModal.duplicateUnit')}
                  />
                </>
              )}

              <YellowButton
                text={t('common:button.save')}
                onPress={submit}
                style={{ height: 35, paddingHorizontal: 35 }}
                textStyle={{ fontSize: 14 }}
              />
            </View>
          </View>
          {showReassignmentModal && node?.object.id && API.isOrganizationalUnit(node.object) && (
            <ReassignmentModal
              handleModalClose={() => setShowReassignmentModal(false)}
              orgUnit={node?.object}
              workerAssignments={assignments}
              confirmAction={() => {
                deleteNode(node);
                if (handleModalClose) handleModalClose();
              }}
            />
          )}
        </View>
      </View>
    </WebModal>
  );
};
