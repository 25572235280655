import React from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { AbstractButton } from 'shared/ui-component/Button';
import { Colors, createRoundedStyle, Spacings } from 'shared/styles';
import { SvgProps } from 'react-native-svg';
import * as _ from 'lodash-es';

export interface IconButtonProps {
  icon?: React.FC<SvgProps>;
  onPress?: () => void;
  color?: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
  iconSize?: number;
}

const defaultSize = 48;

const defaultStyle = StyleSheet.create({
  container: {
    backgroundColor: Colors.Background,
    justifyContent: 'center',
    
  },
});

export const IconButton: React.FC<IconButtonProps> = props => {
  const { color = Colors.BlackLight, iconSize } = props;

  const size = props.size ?? defaultSize;
  const alteredIconSize = iconSize || size - Spacings.Large; 

  const style = _.merge({}, createRoundedStyle(size), StyleSheet.flatten(props.style));

  return (
    <AbstractButton
      onPress={props.onPress}
      containerStyle={[defaultStyle.container, style]}
      icon={props.icon}
      iconColor={color}
      iconSize={{ width: alteredIconSize, height: alteredIconSize }}
    />
  );
};
