import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { ModalStyles } from 'shared/styles/ModalStyles';
import { Spacings, TextFontStyles, fontSize } from 'shared/styles';
import trainingModalStyles from '../../add-edit-lecture-traning-session-modal/component/styles';

const styles = {
  ...trainingModalStyles,
  ...StyleSheet.create({
    contentOfPractice: {
      ...SharedStyles.TextFontStyles.LatoBlack,
      fontSize: SharedStyles.fontSize.Regular,
      marginTop: Spacings.Small,
    },

    rightPanelContainer: {
      flex: 1,
      zIndex: 1,
    },
    leftPanelContainer: {
      marginRight: 50,
      flexDirection: 'row',
      zIndex: 2,
    },

    noMentor: {
      paddingTop: Spacings.Standard,
      ...SharedStyles.TextFontStyles.LatoRegular,
      color: SharedStyles.Colors.Grey,
      fontSize: fontSize.Regular,
    },

    inputListContainer: {
      width: '100%',
    },
    trainingInputContainer: { width: '100%' },
    targetLevelContaier: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    targetLevelTextStyle: {
      paddingBottom: Spacings.Small,
      maxWidth: '50%',
    },
    levelInputContainer: { width: 124 },
    trainingCustomMessageContainer: {
      width: '100%',
      paddingHorizontal: Spacings.Unit * 10,
      paddingTop: Spacings.Medium,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: Spacings.Large,
    },
    customMessageTextStyle: {
      ...TextFontStyles.LatoRegular,
      fontSize: fontSize.Regular,
      textAlign: 'center',
    },
    customMessageDivider: { height: Spacings.Standard },
    customMessageHyperLinkStyle: {
      textDecorationLine: 'underline',
      ...TextFontStyles.LatoRegular,
      fontSize: fontSize.Regular,
      textAlign: 'center',
    },
    complementaryTrainingsContainer: { flexDirection: 'row', maxWidth: '100%' },
    informationIconStyle: { width: 24, height: 24, marginRight: Spacings.Small },
    warningMessageContainer: { paddingTop: Spacings.Unit / 2, flex: 1 },
    warningTextStyle: { ...TextFontStyles.LatoRegular, fontSize: fontSize.Regular },
    complementaryWarningDivider: { height: Spacings.Unit },
    warningUnderLineTextStyle: { textDecorationLine: 'underline' },
    complementaryTrainingRowContainer: { flexDirection: 'row', alignItems: 'center' },

    skillAdvancmentRowContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: SharedStyles.Spacings.Small,
      paddingRight: SharedStyles.Spacings.Unit * 3,
      alignItems: 'center',

      paddingTop: Spacings.Small,
      minHeight: 50,
      marginTop: Spacings.Small,
    },

    acquiredIconContainer: {
      width: 24,
      height: 24,
      backgroundColor: SharedStyles.Colors.Transparent,
    },

    InfoMessage: {
      fontSize: fontSize.Regular,
      ...SharedStyles.TextFontStyles.LatoRegular,
      color: SharedStyles.Colors.Grey,
      marginTop: Spacings.Medium,
      paddingHorizontal: Spacings.Large,
    },
    acquiredContainer: { flexDirection: 'row' },
    acquiredTextStyle: {
      fontSize: fontSize.Regular,
      ...SharedStyles.TextFontStyles.LatoBlack,
      marginTop: Spacings.Small,
    },
    AcquiredIconContaner: {
      marginLeft: Spacings.Small,
      flexDirection: 'row',
    },

    advancementContainer: {
      marginBottom: Spacings.Medium,
      width: '100%',
    },
    inputListWorkerContainer: {
      width: 450,
    },
  }),
};

export default styles;
