import { StyleSheet } from 'react-native';
import { TextFontStyles, Colors } from 'shared/styles';

const styles = StyleSheet.create({
  placeholder: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
  },
  subText: {
    ...TextFontStyles.LatoRegular,
  },
  level: {
    ...TextFontStyles.LatoBlack,
  },
  header: {
    color: Colors.Grey,
    ...TextFontStyles.LatoNormal,
    fontSize: 12,
  },
});

export default styles;
