import React, { FC, useState } from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import * as API from 'shared/backend-data';
import styles from './Styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { Tag } from 'shared/ui-component/Input/InputTag';
import { AutoCompleteItem } from 'shared/ui-component/Input/InputTag/AutoCompleteItem';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors } from 'shared/styles';
import CloseIcon from 'shared/assets/svg/icon.close.svg';
import EditIcon from 'shared/assets/svg/icon.edit.svg';
import { InputText } from 'shared/ui-component/Input';

export enum TagType {
  NormalTag,
  AutoCompleteTag,
  FilterTag,
}
interface TagComponentProps {
  tag: Tag;
  tagType: TagType;
  highlightString?: string;
  filterTags?: Tag[];
  filterName?: string;
  onPress?: (tag: Tag) => void;
  deleteFilter?: (key: string) => void;
  filter?: (name: string, tags: Tag[], registeredfilter?: boolean) => void;
  editFilter?: (key: string, editedText: string) => void;
}

export const TagComponent: React.FC<TagComponentProps> = props => {
  const [editFilter, setEditFilter] = useState<boolean>(false);
  const TeamIcon: FC = require('shared/assets/svg/icon.team.svg').default;
  const WorkstationIcon: FC = require('shared/assets/svg/icon.workstation.svg').default;
  const WorkerIcon: FC = require('shared/assets/svg/icon.worker.svg').default;
  const SkillIcon: FC = require('shared/assets/svg/icon.skill.svg').default;

  const renderIcon = () => {
    let svgIcon;

    if (props.tag.tagType === API.DataType.WORKSTATION) {
      svgIcon = WorkstationIcon;
    } else if (props.tag.tagType === API.DataType.WORKERTAG) {
      svgIcon = TeamIcon;
    } else if (props.tag.tagType === API.DataType.WORKER) {
      svgIcon = WorkerIcon;
    } else if (props.tag.tagType === API.DataType.SKILL) {
      svgIcon = SkillIcon;
    }

    if (svgIcon) {
      return (
        <IconSVG svgComponent={svgIcon} containerStyle={styles.tagContainer} color={Colors.Black} />
      );
    }

    return null;
  };

  function editFilterName(filterName: string) {
    if (props.editFilter && props.filterName) {
      const _newName = filterName === '' ? props.filterName : filterName;
      props.editFilter(props.filterName, _newName);
    }
    setEditFilter(false);
  }

  const renderTagBody = () => {
    switch (props.tagType) {
      case TagType.NormalTag:
        return <Text style={styles.teamTagOption}>{props.tag.label} </Text>;
      case TagType.AutoCompleteTag:
        return (
          <View style={styles.tagOptionContainer}>
            {renderIcon()}
            {props.onPress && (
              <AutoCompleteItem
                key={props.tag.key}
                tag={props.tag}
                selectData={props.onPress}
                highlightString={props.highlightString}
                style={{ width: '80%' }}
              />
            )}
          </View>
        );
      case TagType.FilterTag:
        return (
          <View style={styles.filterOptionContainer}>
            <TouchableOpacity
              onPress={() => {
                if (!editFilter && props.filter && props.filterName && props.filterTags)
                  props.filter(props.filterName, props.filterTags, true);
              }}
            >
              {editFilter ? (
                <InputText
                  containerStyle={{ maxHeight: 35 }}
                  defaultValue={props.filterName}
                  showSaveButton
                  onSaveButtonClicked={editFilterName}
                  onSubmitEditing={(inputId, inputValue) => {
                    editFilterName(inputValue);
                  }}
                />
              ) : (
                <Text style={styles.listItemText}>{props.filterName}</Text>
              )}
            </TouchableOpacity>
            <View style={styles.filterIconContainer}>
              <TouchableOpacity
                onPress={() => {
                  setEditFilter(true);
                }}
              >
                <IconSVG svgComponent={EditIcon} />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  if (props.deleteFilter && props.filterName) props.deleteFilter(props.filterName);
                }}
              >
                <IconSVG svgComponent={CloseIcon} />
              </TouchableOpacity>
            </View>
          </View>
        );
    }
  };

  return (
    <TouchableOpacity
      ref={useCallOnHover()}
      onPress={() =>
        props.onPress
          ? props.onPress(props.tag)
          : props.filter &&
            props.filterName &&
            props.filterTags &&
            props.filter(props.filterName, props.filterTags, true)
      }
    >
      {renderTagBody()}
    </TouchableOpacity>
  );
};
