import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView } from 'react-native';
import * as API from 'shared/backend-data';
import { t } from 'shared/localisation/i18n';
import { loggerAPI as logger } from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as SharedStyles from 'shared/styles';
import { Styles } from './Styles';
import * as _ from 'lodash-es';
import {
  ModifyTrainingModal,
  TrainingModalConfig,
} from 'skillmgtweb/src/components/training/training-modal/container/TrainingModal';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { SkillCreateInputWithoutTrainingType } from './index';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { DeletableTrainingCard } from 'shared/layout/cards/training/training-card/deletable-training-card/component/index';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { MyHub } from 'shared/util/MyHub';
import { DropDownMultiSelection, DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import Aigle from 'aigle';
import { Spacings } from 'shared/styles';
import { ModalUtils } from 'shared/ui-component/Modal';
import { IconSVG } from 'shared/ui-component/Icon';

const TrainingIcon = require('shared/assets/svg/icon.training.svg').default;
const InfoSVG = require('shared/assets/svg/icon.info.svg').default;

interface Props {
  editSkill?: API.Skill;
  skill: SkillCreateInputWithoutTrainingType;
  trainingVersionsAndTrainings: API.TrainingVersionAndTrainingCreateInput[];
  isPanelEditable: boolean;
  setTrainingVersionsAndTrainings: React.Dispatch<
    React.SetStateAction<API.TrainingVersionAndTrainingCreateInput[]>
  >;
  onPassiveSubmit: (trainingVersionAndTraining: API.TrainingVersionAndTrainingCreateInput) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SkillTrainingsPanel: React.FC<Props> = props => {
  const {
    editSkill,
    skill,
    trainingVersionsAndTrainings,
    isPanelEditable,

    setTrainingVersionsAndTrainings,
    onPassiveSubmit,
    setLoading,
  } = props;

  const isMounted = useIsMounted();
  const modal = ModalUtils.useModal();

  const { isValidPermission } = useContext(PermissionManagementContext);

  const [isShowTrainingModal, setIsShowTrainingModal] = useState(false);
  const [trainingModalConfig, setTrainingModalConfig] = useState<TrainingModalConfig>({
    editMode: false,
    trainingVersion: null,
    skillIds: [props.skill?.id ?? ''],
  });
  const [practicalTrainingVersionsListing, setPracticalTrainingVersionsListing] = useState<
    API.TrainingVersionAndTraining[]
  >([]);
  const [notPracticalTrainingVersionsListing, setNotPracticalTrainingVersionsListing] = useState<
    (API.TrainingVersionAndTraining | API.TrainingVersionAndTrainingCreateInput)[]
  >([]);
  const [isShowScrollViewBar, setIsShowScrollViewBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trainingsNotPracticalOptions, setTrainingsNotPracticalOptions] = useState<
    DropDownOption[]
  >([]);
  const [searchInput, setSearchInput] = useState<string>();

  const scrollRef = useCallOnHover<ScrollView>(
    SharedStyles.Colors.Transparent,
    () => setIsShowScrollViewBar(true),
    () => setIsShowScrollViewBar(false),
  );

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.TRAININGVERSION) {
        getSkillTrainingsAndTrainingVersions();
        fetchNotPracticalTrainings();
      }
    });

    return () => {
      removeListener();
    };
  }, [editSkill]);

  useEffect(() => {
    setTrainingModalConfig({ ...trainingModalConfig, skillIds: [skill?.id ?? ''] });
  }, [skill]);

  useEffect(() => {
    getSkillTrainingsAndTrainingVersions();
  }, [editSkill?.isPractical, skill?.isPractical, trainingVersionsAndTrainings]);

  useEffect(() => {
    fetchNotPracticalTrainings();
  }, []);

  async function fetchNotPracticalTrainings() {
    const trainingsArray = await API.getTrainings();
    if (!isMounted.current) return;
    if (API.isFailure(trainingsArray)) {
      logger.warn('Failed to fetch trainings');
      return;
    }

    const notPracticalTrainings: API.Training[] = [];
    await Promise.all(
      _.map(trainingsArray.result, async training => {
        const isTrainingPractical = await API.isPracticalTraining(training.id);
        if (!isMounted.current) return;
        if (API.isFailure(isTrainingPractical)) return isTrainingPractical;
        if (!isTrainingPractical) notPracticalTrainings.push(training);
      }),
    );

    setTrainingsNotPracticalOptions(
      _.sortBy(notPracticalTrainings, eachTraining => eachTraining.name.toLowerCase()).map(
        _training => {
          return {
            key: _training.id,
            label: _training.name,
            value: _training,
          };
        },
      ),
    );
  }

  function displayTrainingsTitle(): string {
    if (skill.isPractical)
      return t('glossary:trainingPractical', { count: practicalTrainingVersionsListing.length });
    return t('glossary:trainingNotPractical', {
      count: notPracticalTrainingVersionsListing.length,
    });
  }

  function createNewTrainingShortcut(label: string): void {
    setIsShowTrainingModal(true);
    setSearchInput(label);
  }

  function displayTrainingsCreationWarningMessage(): string {
    switch (true) {
      
      case !editSkill && skill.isPractical:
        return '';
      case !editSkill && !skill.isPractical && !trainingVersionsAndTrainings.length:
        return t('alex:skills.addSkillToTrainingOrCreateTraining');

      

      

      
      case editSkill &&
        editSkill.isPractical &&
        editSkill.isPractical === skill.isPractical &&
        !practicalTrainingVersionsListing.length:
        return '';

      

      
      case editSkill &&
        !editSkill.isPractical &&
        editSkill.isPractical === skill.isPractical &&
        !notPracticalTrainingVersionsListing.length:
        return t('alex:skills.addSkillToTrainingOrCreateTraining');

      
      case editSkill && editSkill.isPractical !== skill.isPractical:
        return '';

      
      case editSkill &&
        editSkill.isPractical === skill.isPractical &&
        ((editSkill.isPractical && practicalTrainingVersionsListing.length > 0) ||
          (!editSkill.isPractical && notPracticalTrainingVersionsListing.length)):
        return '';

      default:
        return '';
    }
  }

  /**
   * Get all the Training and TrainingVersions containing the skill
   */
  async function getSkillTrainingsAndTrainingVersions(): Promise<void> {
    setLoading(true);
    if (!editSkill) {
      setPracticalTrainingVersionsListing([]);
      setNotPracticalTrainingVersionsListing(trainingVersionsAndTrainings);

      setLoading(false);
      return;
    }

    if (!skill.id) return;
    const skillTrainingAndTrainingVersions = await API.getTrainingVersionAndTrainingsForSkill(
      skill.id,
      true,
    );
    if (!isMounted.current) return;
    if (API.isFailure(skillTrainingAndTrainingVersions)) {
      logger.warn('Error fetching getSkillTrainingVersions', skillTrainingAndTrainingVersions);
      setLoading(false);
      return;
    }

    const practicalTrainings: API.TrainingVersionAndTraining[] = [];
    const notPracticalTrainings: API.TrainingVersionAndTraining[] = [];
    await Promise.all(
      _.map(skillTrainingAndTrainingVersions, async skillTrainingAndTrainingVersion => {
        const isPractical = await API.isPracticalTrainingVersion(
          skillTrainingAndTrainingVersion[0].id,
        );
        if (!isMounted.current) return;
        if (API.isFailure(isPractical)) {
          logger.warn('Failed to fetch training version practical/not practical property');
          setLoading(false);
          return;
        }

        if (isPractical) {
          practicalTrainings.push(skillTrainingAndTrainingVersion);
        } else {
          notPracticalTrainings.push(skillTrainingAndTrainingVersion);
        }
      }),
    );
    if (!isMounted.current) return;

    setPracticalTrainingVersionsListing(practicalTrainings);
    setNotPracticalTrainingVersionsListing(notPracticalTrainings);
    setLoading(false);
  }

  async function removeSkillFromTrainingVersion(trainingVersionId: string) {
    if (!editSkill) return;
    setIsLoading(true);
    const trainingVersion = await API.getTrainingVersion(trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(trainingVersion)) {
      setIsLoading(false);
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('common:error.retry'),
          warningAcceptButton: t('common:button.ok'),
        }),
      );
      return;
    }

    const newSkillIdsArr = [...trainingVersion.skillIds];
    newSkillIdsArr.splice(_.indexOf(newSkillIdsArr, editSkill.id), 1);

    const updatedTrainingVersion = await API.updateTrainingVersioMaybeDelete({
      ...trainingVersion,
      skillIds: newSkillIdsArr,
    });
    if (!isMounted.current) return;
    if (API.isFailure(updatedTrainingVersion)) {
      setIsLoading(false);
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('common:error.ok'),
          warningAcceptButton: t('common:button.retry'),
        }),
      );
      return;
    }

    setIsLoading(false);
  }

  function removeTrainingOnHold(index: number) {
    const tempTrainingsArr = [...trainingVersionsAndTrainings];
    tempTrainingsArr.splice(index, 1);
    setTrainingVersionsAndTrainings(tempTrainingsArr);
  }

  async function handleTrainingFilter(inputId: string, trainings: DropDownOption[] | undefined) {
    if (!editSkill) {
      const filteredTrainingVersionAndTraining: API.TrainingVersionAndTrainingCreateInput[] = [];

      await Promise.all(
        _.map(trainings, async training => {
          const latestTV = await API.getTrainingVersionsForTraining(training.key, true);
          if (!isMounted.current) return;
          if (API.isFailure(latestTV)) return latestTV;

          const _training = await API.getTraining(training.key);
          if (!isMounted.current) return;
          if (API.isFailure(_training)) return _training;

          filteredTrainingVersionAndTraining.push([
            API.deepClone(latestTV[0]),
            API.deepClone(_training),
          ]);
        }),
      );

      const _trainings = _.concat(trainingVersionsAndTrainings, filteredTrainingVersionAndTraining);
      if (_trainings.length) setTrainingVersionsAndTrainings(_trainings);
    } else {
      setLoading(true);
      let trainingVersionAndTrainingToDelete: (
        | API.TrainingVersionAndTraining
        | API.TrainingVersionAndTrainingCreateInput
      )[] = [];

      if (!trainings?.length) {
        trainingVersionAndTrainingToDelete = notPracticalTrainingVersionsListing;
      } else {
        trainingVersionAndTrainingToDelete = notPracticalTrainingVersionsListing.filter(
          eachNotPracticalTrainingVersion =>
            trainings?.some(
              eachTraining => eachNotPracticalTrainingVersion[1].id !== eachTraining.key,
            ),
        );
      }

      await Aigle.map(trainingVersionAndTrainingToDelete, async training => {
        if (training[0].id) await removeSkillFromTrainingVersion(training[0].id);
      });
      if (!isMounted.current) return;

      await Promise.all(
        _.map(trainings, async training => {
          const latestTV = await API.getTrainingVersionsForTraining(training.key, true);
          if (!isMounted.current) return;
          if (API.isFailure(latestTV)) {
            setLoading(false);
            return latestTV;
          }

          const updatedTV = await API.updateTrainingVersioMaybeDelete({
            ...latestTV[0],
            skillIds: [...new Set([...latestTV[0].skillIds, editSkill.id])],
          });
          if (!isMounted.current) return;
          if (API.isFailure(updatedTV)) {
            setLoading(false);
            return updatedTV;
          }
        }),
      );

      if (!isMounted.current) return;
      setLoading(false);
    }
  }

  function isHidePracticalTrainingTitle(): boolean {
    return (
      (Boolean(skill.isPractical) &&
        (!practicalTrainingVersionsListing.length ||
          (Boolean(editSkill) && !editSkill?.isPractical))) ||
      (Boolean(editSkill) &&
        Boolean(editSkill?.isPractical) &&
        (!practicalTrainingVersionsListing.length || !Boolean(skill.isPractical)))
    );
  }

  function isShowTrainingAdditionInput(): boolean {
    return (
      skill.isPractical === false &&
      isPanelEditable &&
      (!editSkill || skill.isPractical === editSkill.isPractical)
    );
  }

  function isShowRightPanelDivider(): boolean {
    return !!practicalTrainingVersionsListing.length || !isPanelEditable;
  }

  function skillHasMoreThanOneTraining(): boolean {
    return Boolean(
      practicalTrainingVersionsListing.length > 1 || notPracticalTrainingVersionsListing.length > 1,
    );
  }

  function getMarginBottom(): number {
    return (!editSkill && !skill.isPractical && trainingVersionsAndTrainings.length) ||
      (editSkill && !editSkill.isPractical && notPracticalTrainingVersionsListing.length) ||
      (editSkill && editSkill.isPractical && practicalTrainingVersionsListing.length)
      ? 0
      : SharedStyles.Spacings.Unit * 12;
  }

  function getPaddingBottom(): number {
    return skillHasMoreThanOneTraining() && !skill.isPractical
      ? SharedStyles.Spacings.xMedium
      : SharedStyles.Spacings.Unit * 5;
  }

  return (
    <View style={[Styles.rightPanelContainer]}>
      {isLoading && <Loader />}
      <View style={[Styles.rightPanelListingContainer]}>
        {isShowTrainingModal && (
          <ModifyTrainingModal
            config={trainingModalConfig}
            isPassive={!editSkill}
            onPassiveSubmit={onPassiveSubmit}
            trainingNamePlaceholder={searchInput}
            preSelectedSkill={
              {
                ...skill,
                name: skill.name ? skill.name : t('alex:skills.skillBeingCreated'),
              } as API.SkillCreateInput
            }
            
            handleModalClose={() => {
              setIsShowTrainingModal(false);
              setSearchInput(undefined);
            }}
            handleRefresh={getSkillTrainingsAndTrainingVersions}
          />
        )}
        <View style={Styles.listingTitleContainer}>
          {!isHidePracticalTrainingTitle() && (
            <Text style={[Styles.listingTitleTextStyle]}>
              {displayTrainingsTitle()}
              <Text style={[Styles.listingTitleTextStyle]}>
                {' '}
                {t('alex:skills.trainingsOfferingSkill', {}, false)}
              </Text>
            </Text>
          )}
          {isShowTrainingAdditionInput() && isValidPermission(API.Permission.trainings_edit) && (
            <View
              style={[
                Styles.touchableFilterTraining,
                {
                  marginBottom: getMarginBottom(),
                },
              ]}
            >
              <DropDownMultiSelection
                additionForm
                showAdditionFormList={false}
                placeholder={t('alex:skills.pickATraining')}
                searchPlaceholder={t('alex:skills.pickATraining')}
                containerStyle={[
                  Styles.trainingDropDownContainer,
                  { paddingBottom: getPaddingBottom() },
                ]}
                inputListContainerStyle={Styles.trainingDropDownInputListContainer}
                listIcon={TrainingIcon}
                values={_.map(
                  notPracticalTrainingVersionsListing,
                  (notPracticalTrainingVersion, index) => {
                    return {
                      key: notPracticalTrainingVersion[1].id || index.toString(), 
                      label: notPracticalTrainingVersion[1].name,
                      value: notPracticalTrainingVersion[1],
                    };
                  },
                )}
                options={trainingsNotPracticalOptions}
                notEditable={!isPanelEditable}
                createNewOption={{
                  createItemHandler: () => {
                    setIsShowTrainingModal(true);
                  },
                  createItemIcon: TrainingIcon,
                  createItemPlaceHolder: t('alex:skills.createATraining'),
                }}
                createNewOptionShortcut={createNewTrainingShortcut}
                handleChange={handleTrainingFilter}
              />
            </View>
          )}
          {skillHasMoreThanOneTraining() && (
            <View
              style={
                skill.isPractical ? Styles.infoContainerPractice : Styles.infoContainerTraining
              }
            >
              <IconSVG
                svgComponent={InfoSVG}
                size={{ width: 24, height: 24 }}
                color={SharedStyles.Colors.MediumGray}
              />
              <Text style={Styles.infoTextStyle}>
                {skill.isPractical
                  ? t('alex:skills.addEditSkill.skillHasMoreThanOnePractice')
                  : t('alex:skills.addEditSkill.skillHasMoreThanOneLecture')}
              </Text>
            </View>
          )}
          {!!displayTrainingsCreationWarningMessage() && (
            <View
              style={[
                Styles.warningMessageContainer,
                !isPanelEditable && { marginTop: Spacings.Unit * 5 },
              ]}
            >
              <Text numberOfLines={3} style={[Styles.warningMessageTextStyle]}>
                {displayTrainingsCreationWarningMessage()}
              </Text>
            </View>
          )}
        </View>
        {isShowRightPanelDivider() && <View style={[Styles.rightPanelDivider]} />}
        <ScrollView
          ref={scrollRef}
          showsVerticalScrollIndicator={isShowScrollViewBar}
          scrollEnabled
          style={[Styles.trainingsListingContainer]}
        >
          {_.map(
            skill.isPractical
              ? practicalTrainingVersionsListing
              : notPracticalTrainingVersionsListing,
            (trainingVersionAndTraining, index) => {
              return (
                <DeletableTrainingCard
                  key={trainingVersionAndTraining[0].id ?? index}
                  containerStyle={Styles.deletableTrainingCardContainerStyle}
                  trainingVersionAndTraining={trainingVersionAndTraining}
                  forceDisplayIsPracticalTraining={
                    trainingVersionAndTraining[0].id ? undefined : skill.isPractical
                  }
                  forceDisplaySkillNames={
                    !editSkill ? [skill.name ? skill.name : t('alex:skills.skillBeingCreated')] : []
                  }
                  disabled={!isPanelEditable || !API.isSkillUnlinkable(skill.isPractical)}
                  onDelete={() => {
                    if (editSkill) {
                      removeSkillFromTrainingVersion(trainingVersionAndTraining[0].id!);
                    } else {
                      removeTrainingOnHold(index);
                    }
                  }}
                />
              );
            },
          )}
        </ScrollView>
      </View>
    </View>
  );
};
