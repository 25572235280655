import { StyleSheet } from 'react-native';
import { TextFontStyles, fontSize, Spacings, Colors } from 'shared/styles';

const styles = StyleSheet.create({
  rootContainer: {
    padding: Spacings.CardPadding,
    flex: 1,
  },
  graphTitle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.CardPadding,
  },
  graphContainer: {
    left: -Spacings.Large,
    flex: 1,
    zIndex: -1,
  },
  axis2Font: {
    fontSize: fontSize.Small,
    color: Colors.Grey,
  },
  infoContainer: {
    top: Spacings.Standard,
    right: -50,
    backgroundColor: Colors.White,
    width: 300,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  toolTipContainer: {
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  radioText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  cardStyle: {
    padding: Spacings.CardPadding,
    position: 'absolute',
    right: 18,
    width: 270,
  },
  noDataContainer: {
    flex: 1,
    zIndex: 999,
    position: 'absolute',
    width: '90%',
    height: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    top: 45,
  },
  toolTipText: {
    color: Colors.Black,
  },
  noDataTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
  cursorToolTipContainer: {
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: Colors.White,
    borderRadius: Spacings.Small,
    paddingLeft: Spacings.Medium,
    paddingTop: 10,
    paddingRight: Spacings.Standard,
  },
  cursorToolTipText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  cursorToolTipValue: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    paddingRight: Spacings.xMedium,
  },
  cursorTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 13,
  },
  moreIcon: {
    marginLeft: Spacings.Small,
  },
  infoIcon: {
    top: 1,
  },
  bottomButtonStyle: {
    backgroundColor: Colors.Yellow,
    height: 32,
    paddingHorizontal: Spacings.Unit * 8,
  },
  bottomButtonTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.White,
  },
});

export default styles;
