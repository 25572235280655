import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import Styles from './Styles';

export const Loader: React.FC = () => {
  return (
    <View style={Styles.loaderContainer}>
      <ActivityIndicator color="#FCD200" size="large" />
    </View>
  );
};
