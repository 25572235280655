import { Hub, HubCallback, HubCapsule, HubPayload } from '@aws-amplify/core';
import { FactoryMutationCacheEvent } from '../backend-data/DataLayer';
import { logger, loggerPerf } from './Logger';
import * as _ from 'lodash-es';
import { Cast, DataType, Factory } from 'shared/backend-data';

const PERF_limitForStopSendingIndividualBusinessObjectMutateEvent = 10;

/** Warning : shall be > 50 as it is important to delay the broadcast of the event to let time to the original query Promise to fulfill. */
export const broadcastBusinesObjectMutateEventDebounce = 250;
export const broadcastBusinesObjectMutateEventDebounce_longWait = 2000;

export enum HubChannels {
  
  
  Core = 'core',
  Auth = 'auth',
  Api = 'api',
  Analytics = 'analytics',
  Interactions = 'interactions',
  Pubsub = 'pubsub',
  Storage = 'storage',
  Xr = 'xr',
  

  AppContext = 'appContext',
  BusinessObject = 'businessObject',
  TaskProgress = 'taskProgress',
  Error = 'error',
  Unknown = 'unknown',
}

type MyHubEvent = { event: string; message?: string };

type HubAppContextEvent = MyHubEvent &
  (
    | {
        
        event: 'UserSet';
        userId: string;
        username?: string;
      }
    | {
        
        event: 'UserUnset';
        userId: string;
      }
    | {
        event: 'TenantAppLoading';
        loadingPercentage: number | undefined;
        closeModal?: boolean;
      }
    | {
        event: 'TenantAppCacheSyncNeeded';
        pk: string;
      }
    | {
        event: 'ForceTenantAppCacheBaseSync';
        isForceBaseSync: boolean;
      }
    | {
        event: 'TenantAppSet';
        pk: string;
        factory: Factory<DataType.WORKER>;
      }
    | {
        event: 'TenantAppUnset';
        pk: string;
      }
    | {
        event: 'SubscriptionClientReconnected';
      }
  );

type HubErrorsEvent = MyHubEvent & {
  event: 'DependencyVeto';
  dependencyIds: string[];
  vetoAcceptCallback?: () => void;
  disableRedirection?: boolean;
  message: string;
  forceDelete?: boolean;
};

type HubTaskProgressEvent = MyHubEvent & {
  event: 'ExcelImportExportLoadingStep';
  /** pass to true to reset the progressBar */
  reset?: boolean;
  /** number of step to increment the progressBar  */
  incrementStep: number;
  totalSteps: number;
};

type HubBusinessObjectEvent = MyHubEvent &
  (
    | {
        event: 'BusinessObjectMutate';
        data: FactoryMutationCacheEvent<DataType, true | false>;
      }
    | {
        event: 'TreeStructureUpdate';
        message?: string;
      }
  );

/**
 * A proxy class to aws-amplify Hub to add strong Type support
 */
class _MyHub {
  /**
   * Returns the function to call to stopListening (equivalent to call #remove(channel, ref to callback)
   * @param channel
   * @param callback
   * @param listenerName
   */
  listen(
    channel: HubChannels | RegExp,
    callback: MyHubCallback,
    listenerName?: string,
  ): () => void {
    const _callback: HubCallback = (capsule: HubCapsule) => {
      let channel: HubChannels = HubChannels.Unknown;
      if (capsule.channel in HubChannels) channel = capsule.channel as HubChannels;

      callback({
        channel,
        payload: capsule.payload,
        source: capsule.source,
        patternInfo: capsule.patternInfo,
      });
    };
    return Hub.listen(channel, _callback, listenerName);
  }

  /**
   * Dispatch an event with its payload to listeners.
   * Please specify the <generic type> to not get "not assignable to parameter of type 'never'" error".
   * @param channel
   * @param event
   * @param hubPayload
   * @param source
   */
  dispatch(channel: HubChannels, hubPayload: HubPayload, source?: string): void {
    Hub.dispatch(channel, hubPayload, source);
  }

  /**
   * You should rather call the function returned by #listen() or #listenXYZ() for removing a listener
   * @param channel
   * @param listener
   */
  remove(channel: HubChannels | RegExp, listener: HubCallback): void {
    Hub.remove(channel, listener);
  }

  /**
   * Returns the function to call to stopListening (equivalent to call #remove(channel, ref to callback)
   * @param event
   * @param callback
   * @param listenerName
   */
  listenAppContext<Event extends HubAppContextEvent['event']>(
    event: Event,
    callback: (payload: DispatchArgs<HubAppContextEvent, Event>[1], source: string) => void,
    listenerName?: string,
  ): () => void {
    const _callback: HubCallback = (capsule: HubCapsule) => {
      if (capsule.payload.event !== event) return;

      callback(capsule.payload.data, capsule.source);
    };

    return Hub.listen(HubChannels.AppContext, _callback, listenerName);
  }

  dispatchAppContext<Event extends HubAppContextEvent['event']>(
    ...args: DispatchArgs<HubAppContextEvent, Event>
  ): void {
    Hub.dispatch(
      HubChannels.AppContext,
      {
        event: args[0],
        data: args[1],
        message: args[1].message, 
      },
      args[2],
    );
  }

  /**
   * Returns the function to call to stopListening (equivalent to call #remove(channel, ref to callback)
   * @param event
   * @param callback
   * @param listenerName
   */
  listenError<Event extends HubErrorsEvent['event']>(
    event: Event,
    callback: (payload: DispatchArgs<HubErrorsEvent, Event>[1], source: string) => void,
    listenerName?: string,
  ): () => void {
    const _callback: HubCallback = (capsule: HubCapsule) => {
      if (capsule.payload.event !== event) return;

      callback(capsule.payload.data, capsule.source);
    };

    return Hub.listen(HubChannels.Error, _callback, listenerName);
  }

  dispatchError<Event extends HubErrorsEvent['event']>(
    ...args: DispatchArgs<HubErrorsEvent, Event>
  ): void {
    Hub.dispatch(
      HubChannels.Error,
      {
        event: args[0],
        data: args[1],
        message: args[1].message,
      },
      args[2],
    );
  }

  /**
   * Returns the function to call to stopListening (equivalent to call #remove(channel, ref to callback)
   * @param event
   * @param callback
   * @param listenerName
   */
  listenTaskProgress<Event extends HubTaskProgressEvent['event']>(
    event: Event,
    callback: (payload: DispatchArgs<HubTaskProgressEvent, Event>[1], source: string) => void,
    listenerName?: string,
  ): () => void {
    const _callback: HubCallback = (capsule: HubCapsule) => {
      if (capsule.payload.event !== event) return;

      callback(capsule.payload.data, capsule.source);
    };

    return Hub.listen(HubChannels.TaskProgress, _callback, listenerName);
  }

  dispatchTaskProgress<Event extends HubTaskProgressEvent['event']>(
    ...args: DispatchArgs<HubTaskProgressEvent, Event>
  ): void {
    Hub.dispatch(
      HubChannels.TaskProgress,
      {
        event: args[0],
        data: args[1],
        message: args[1].message,
      },
      args[2],
    );
  }

  private listenBusinessObjectTotalCounter = 0;
  private listenBusinessObjectCallBacksMap = new Map<
    (payload: any, source: string) => void,
    number
  >();
  /**
   * Returns the function to call to stopListening (equivalent to call #remove(channel, ref to callback)
   * @param event
   * @param callback
   * @param listenerName
   */
  listenBusinessObject<Event extends HubBusinessObjectEvent['event']>(
    event: Event,
    callback: (payload: DispatchArgs<HubBusinessObjectEvent, Event>[1], source: string) => void,
    listenerName?: string,
  ): () => void {
    const _callback: HubCallback = (capsule: HubCapsule) => {
      if (capsule.payload.event !== event) return;

      
      if (!logger.isPROD) {
        let counter = this.listenBusinessObjectCallBacksMap.get(callback);
        if (!counter) {
          counter = 0;
        }
        this.listenBusinessObjectCallBacksMap.set(callback, counter + 1);

        this.listenBusinessObjectTotalCounter++;
        loggerPerf.debug(
          "'listenBusinessObject callback " + counter,
          this.listenBusinessObjectTotalCounter,
          callback,
        );
      }

      callback(capsule.payload.data, capsule.source);
    };

    return Hub.listen(HubChannels.BusinessObject, _callback, listenerName);
  }

  /**
   * We queue events before broadcast to minimize the number of UI renderings
   * @param args
   */
  dispatchBusinessObject<Event extends HubBusinessObjectEvent['event']>(
    ...args: DispatchArgs<HubBusinessObjectEvent, Event>
  ): void {
    if (this.isArgs('BusinessObjectMutate', args)) {
      const dataType = args[1].data.factory.dataType;

      
      if (dataType === DataType.WORKERWORKSTATION) {
        let queue = this.businessObjectMutateQueueMap_longWait.get(dataType);
        if (!queue) {
          queue = [];
          this.businessObjectMutateQueueMap_longWait.set(dataType, queue);
        }
        queue.push(args);
        this.broadcastBusinessObjectMutateEvents_longWait();
      } else {
        let queue = this.businessObjectMutateQueueMap.get(dataType);
        if (!queue) {
          queue = [];
          this.businessObjectMutateQueueMap.set(dataType, queue);
        }
        queue.push(args);
        this.broadcastBusinessObjectMutateEvents();
      }
    } else {
      Hub.dispatch(
        HubChannels.BusinessObject,
        {
          event: args[0],
          data: args[1],
          message: args[1].message,
        },
        args[2],
      );
    }
  }

  private isArgs<Event extends HubBusinessObjectEvent['event']>(
    event: Event,
    argsToTest: DispatchArgs<HubBusinessObjectEvent, HubBusinessObjectEvent['event']>,
  ): argsToTest is DispatchArgs<HubBusinessObjectEvent, Event> {
    return event === argsToTest[0];
  }

  private businessObjectMutateQueueMap: Map<
    DataType,
    DispatchArgs<HubBusinessObjectEvent, 'BusinessObjectMutate'>[]
  > = new Map();
  private businessObjectMutateQueueMap_longWait: Map<
    DataType,
    DispatchArgs<HubBusinessObjectEvent, 'BusinessObjectMutate'>[]
  > = new Map();
  /**
   * WARNING : the order of BusinessObjectMutate events received are not kept.
   *
   * Queuing and debouncing BusinessObjectMutate events have 2 goals:
   *  1) batch BusinessObjectMutate to limit the number of component UI rendering:
   *     in case the component receive several events in a row that trigers several setState(),
   *     React should (hopefully) not trigger a UI rendering for each setState().
   *  2) squash BusinessObjectMutate of the same dataType when there are too many received in a few seconds (like for WorkerWorkstationMutate)
   *     in order to not overwhelmed the UI components. In this case a single event "tooManyMutations" is broadcasted instead of hundreds
   */
  private debounceBroadcastBusinessObjectMutateEvents =
    (
      businessObjectMutateQueueMap: Map<
        DataType,
        DispatchArgs<HubBusinessObjectEvent, 'BusinessObjectMutate'>[]
      >,
    ) =>
    () => {
      for (const [dataType, queue] of businessObjectMutateQueueMap.entries()) {
        if (!queue.length) continue;

        if (queue.length > PERF_limitForStopSendingIndividualBusinessObjectMutateEvent) {
          loggerPerf.debug(
            'Broadcasting ' +
              dataType +
              ': a single BusinessObjectMutate "might have changed" event instead of ' +
              queue.length +
              ' BusinessObjectMutate events.',
          );
          Hub.dispatch(HubChannels.BusinessObject, {
            event: 'BusinessObjectMutate',
            data: {
              data: {
                factory: { dataType: dataType },
                tooManyMutations: true,
              },
            } as DispatchArgs<HubBusinessObjectEvent, 'BusinessObjectMutate'>[1],
          });
        } else {
          loggerPerf.debug(
            'Broadcasting ' +
              dataType +
              ': ' +
              queue.length +
              ' BusinessObjectMutate events to the UI ...',
          );
          for (const args of queue) {
            Hub.dispatch(
              HubChannels.BusinessObject,
              {
                event: args[0],
                data: args[1],
                message: args[1].message,
              },
              args[2],
            );
          }
        }

        
        queue.length = 0;
      }
    };
  private broadcastBusinessObjectMutateEvents = _.debounce(
    this.debounceBroadcastBusinessObjectMutateEvents(this.businessObjectMutateQueueMap),
    broadcastBusinesObjectMutateEventDebounce,
    { leading: false, maxWait: 2000, trailing: true },
  );
  private broadcastBusinessObjectMutateEvents_longWait = _.debounce(
    this.debounceBroadcastBusinessObjectMutateEvents(this.businessObjectMutateQueueMap_longWait),
    broadcastBusinesObjectMutateEventDebounce_longWait,
    { leading: false, maxWait: 10000, trailing: true },
  );
}
export const MyHub = new _MyHub();

type MyHubCallback = (capsule: MyHubCapsule) => void;
interface MyHubCapsule extends HubCapsule {
  channel: HubChannels;
}

type DispatchArgs<Payload extends HubPayload, Event extends Payload['event']> = [
  event: Event,
  payload: Omit<Cast<Payload, 'event', Event>, 'event'>,
  source?: string,
];
