import { InteractionManager } from 'react-native';
import { wait } from './Functions';

/**
 * Call InteractionManager.runAfterInteractions with an optional extra time if needed.
 * @param additionalTimeMultiplier (optional) multiplier of additional time to wait.
 */
export async function runAfterInteractions(
    func: () => Promise<void>,
    additionalTimeMultiplier: number = 0,
) {
    InteractionManager.runAfterInteractions(async () => {
        if (additionalTimeMultiplier) await wait(additionalTimeMultiplier * 50);
        func();
    });
}