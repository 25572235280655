import React, { useState, useEffect } from 'react';
import * as API from 'shared/backend-data';
import { UsersProfileDisplayer } from '../component';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { WorkersStatus } from 'shared/util/Worker';

interface Props {
  onPress: () => void;
}

export const InvitedUsersDisplayContainer: React.FC<Props> = props => {
  const [workers, setWorkers] = useState<API.Worker[]>([]);
  const isMounted = useIsMounted();

  useEffect(() => {
    getInvitedUsers();
  }, []);

  async function getInvitedUsers() {
    const workers = await API.getWorkers(
      undefined,
      undefined,
      false,
      WorkersStatus.Users,
    );
    if (!isMounted.current) return;
    if (API.isFailure(workers)) return workers;
    setWorkers(workers.result);
  }

  return workers.length ? (
    <UsersProfileDisplayer
      workers={workers}
      imageSize={36}
      overlapSize={7}
      profilesShownNumber={3}
      onPress={props.onPress}
    />
  ) : null;
};
