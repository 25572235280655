import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView } from 'react-native';
import * as SharedStyles from 'shared/styles';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { LevelSkillsPaneContent, UnauthorizedUnit } from './index';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { TrainingSkillsListing } from './TrainingSkillsListing';
import { SkillsWithNoTrainingVersionsListing } from './SkillsWithNoTrainingVersionsListing';
import { SkillsWithPossibleTrainingVersionsListing } from './SkillsWithPossibleTrainingVersionsListing';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import Styles from './style';
import { t } from 'shared/localisation/i18n';
import Aigle from 'aigle';

interface RequirementTrainingColumnProps {
  requirementLevel: API.WorkstationWorkerLevels;
  levelSkillsPaneContents: Map<API.WorkstationWorkerLevels, LevelSkillsPaneContent> | null;
  selectableSkillId?: string;
  handleWorkstationTrainingModal: (
    isShow: boolean,
    editMode: boolean,
    trainingVersion: API.TrainingVersion | null | undefined,
    skillIds: string[],
  ) => void;
  refreshLevelSkills: () => Promise<void>;
  treeNode?: API.TreeNode;
}

interface SkillRequirementWithOutTrainingVersions {
  skillIds: string[];
  requirement: API.Requirement;
}

interface InheritedSkillRequirementWithLinkedObject {
  skillsWithTrainingVersions: Map<string, string[]>;
  linkedObject?: API.OrganizationalUnit | API.Workstation | UnauthorizedUnit;
}

interface InheritedSkillIdsWtihLinkedObject {
  skillIds: string[];
  linkedObject?: API.OrganizationalUnit | API.Workstation | UnauthorizedUnit;
}

interface SkillRequirementWithPossibleTrainingVersions {
  requirement: API.Requirement;
  trainingVersions: API.TrainingVersion[];
}

interface SkillWithAndWithOutTrainingVersions {
  skillsWithTrainingVersions: Map<string, string[]>;
  skillsWithOutTrainingVersions: string[];
}

export const RequirementTrainingColumn: React.FC<RequirementTrainingColumnProps> = props => {
  const isMounted = useIsMounted();

  const {
    treeNode,
    requirementLevel,
    levelSkillsPaneContents,
    handleWorkstationTrainingModal,
    refreshLevelSkills,
  } = props;

  const [isHover, setIsHover] = useState(false);
  const [trainingVersionsUsedInRequirement, setTrainingVersionsUsedInRequirement] = useState<
    Map<string, string[]>
  >(new Map());
  const [inheritedTrainingVersionWithSkills, setInheritedTrainingVersionWithSkills] =
    useState<Map<string, InheritedSkillIdsWtihLinkedObject | undefined>>();
  const [skillRequirementWithOutTrainingVersions, setSkillRequirementWithOutTrainingVersions] =
    useState<Map<string, SkillRequirementWithOutTrainingVersions>>();
  const [
    skillRequirementWithPossibleTrainingVersions,
    setSkillRequirementWithPossibleTrainingVersions,
  ] = useState<Map<string, SkillRequirementWithPossibleTrainingVersions>>();

  const ref = useCallOnHover<ScrollView>(
    SharedStyles.Colors.White,
    () => {
      setIsHover(true);
    },
    () => {
      setIsHover(false);
    },
  );

  useEffect(() => {
    loadTrainings();
  }, [levelSkillsPaneContents]);

  function getTrainingVersionWithSkills(
    _sourceRequirement: API.Requirement,
  ): SkillWithAndWithOutTrainingVersions {
    const _trainingVersionWithSkills = new Map<string, string[]>();
    const _skillsWithoutTrainingVersion: string[] = [];

    if (_sourceRequirement.skillTrainingVersions) {
      _sourceRequirement.skillTrainingVersions.map(skillTrainingVersion => {
        if (skillTrainingVersion.trainingVersionId) {
          let _skillIds: string[] = [skillTrainingVersion.skillId];
          if (_trainingVersionWithSkills.get(skillTrainingVersion.trainingVersionId)) {
            _skillIds.push(
              ..._trainingVersionWithSkills.get(skillTrainingVersion.trainingVersionId)!,
            );
          }
          _trainingVersionWithSkills.set(skillTrainingVersion.trainingVersionId, _skillIds);
        } else {
          _skillsWithoutTrainingVersion.push(skillTrainingVersion.skillId);
        }
      });
    }
    return {
      skillsWithTrainingVersions: _trainingVersionWithSkills,
      skillsWithOutTrainingVersions: _skillsWithoutTrainingVersion,
    };
  }

  async function skillTrainingVersions(
    skillId: string,
  ): Promise<API.Result<API.TrainingVersion[]>> {
    const trainingVersions = await API.getTrainingVersions();
    if (!isMounted.current) return [];
    if (API.isFailure(trainingVersions)) {
      return trainingVersions;
    }

    const skillTrainingVersions: API.TrainingVersion[] = [];

    await Promise.all(
      trainingVersions.result.map(async trainingVersion => {
        if (trainingVersion.skillIds.includes(skillId)) {
          const latestTraininVersionForLinkedTraining =
            await API.getTrainingVersionLatestForTraining(trainingVersion.trainingId);
          if (!isMounted.current) return;
          if (API.isFailure(latestTraininVersionForLinkedTraining)) {
            return;
          }

          if (
            latestTraininVersionForLinkedTraining &&
            latestTraininVersionForLinkedTraining.id === trainingVersion.id
          )
            skillTrainingVersions.push(trainingVersion);
        }
      }),
    );

    return skillTrainingVersions;
  }

  async function loadTrainings() {
    const _inheritedTrainingVersionWithSkills = Array<InheritedSkillRequirementWithLinkedObject>();
    const _inheritedTrainingVersionWithSkillsActual = new Map<
      string,
      InheritedSkillIdsWtihLinkedObject | undefined
    >();
    const _skillRequirementWithOutTrainingVersions = new Map<
      string,
      SkillRequirementWithOutTrainingVersions
    >();
    const _skillRequirementWithPossibleTrainingVersions = new Map<
      string,
      SkillRequirementWithPossibleTrainingVersions
    >();

    
    const _sourceRequirement =
      levelSkillsPaneContents?.get(requirementLevel)?.levelSkills.sourceRequirement;
    if (_sourceRequirement) {
      const _trainingVersionWithSkills = getTrainingVersionWithSkills(_sourceRequirement);

      setTrainingVersionsUsedInRequirement(_trainingVersionWithSkills.skillsWithTrainingVersions);

      const _skillIds: string[] = [];

      if (_trainingVersionWithSkills.skillsWithOutTrainingVersions.length) {
        await Aigle.map(
          _trainingVersionWithSkills.skillsWithOutTrainingVersions,
          async eachSkillId => {
            const _skillTrainingVersions = await skillTrainingVersions(eachSkillId);
            if (!isMounted.current) return;
            if (API.isFailure(_skillTrainingVersions)) {
              return;
            }
            if (!_skillTrainingVersions.length) {
              _skillIds.push(eachSkillId);
            } else {
              _skillRequirementWithPossibleTrainingVersions.set(eachSkillId, {
                trainingVersions: _skillTrainingVersions,
                requirement: _sourceRequirement,
              });
            }
          },
        );

        if (_skillIds.length) {
          _skillRequirementWithOutTrainingVersions.set(_sourceRequirement.id, {
            skillIds: _skillIds,
            requirement: _sourceRequirement,
          });
        }
      }
    }

    
    const _inheritedRequirements =
      levelSkillsPaneContents?.get(requirementLevel)?.inheritedLevelSkills;
    if (_inheritedRequirements) {
      await Aigle.map(_inheritedRequirements, async inheritedRequirement => {
        if (inheritedRequirement.sourceRequirement) {
          _inheritedTrainingVersionWithSkills.push({
            linkedObject: inheritedRequirement.linkedObject,
            skillsWithTrainingVersions: getTrainingVersionWithSkills(
              inheritedRequirement.sourceRequirement,
            ).skillsWithTrainingVersions,
          });
          const _skillIds: string[] = [];

          if (
            getTrainingVersionWithSkills(inheritedRequirement.sourceRequirement)
              .skillsWithOutTrainingVersions.length
          ) {
            await Aigle.map(
              getTrainingVersionWithSkills(inheritedRequirement.sourceRequirement)
                .skillsWithOutTrainingVersions,
              async eachSkillId => {
                const _skillTrainingVersions = await skillTrainingVersions(eachSkillId);
                if (!isMounted.current) return;
                if (API.isFailure(_skillTrainingVersions)) {
                  return _skillTrainingVersions;
                }
                if (!_skillTrainingVersions.length) {
                  _skillIds.push(eachSkillId);
                } else {
                  _skillRequirementWithPossibleTrainingVersions.set(eachSkillId, {
                    trainingVersions: _skillTrainingVersions,
                    requirement: inheritedRequirement.sourceRequirement!,
                  });
                }
              },
            );

            if (!isMounted.current) return;
            if (_skillIds.length) {
              _skillRequirementWithOutTrainingVersions.set(
                inheritedRequirement.sourceRequirement!.id,
                {
                  skillIds: _skillIds,
                  requirement: inheritedRequirement.sourceRequirement!,
                },
              );
            }
          }
        }
      });
      if (!isMounted.current) return;
    }

    if (_inheritedTrainingVersionWithSkills) {
      _.map(_inheritedTrainingVersionWithSkills, inheritedLevelSkill => {
        _.map(
          Array.from(inheritedLevelSkill.skillsWithTrainingVersions.keys()),
          trainingVersionId => {
            _inheritedTrainingVersionWithSkillsActual.set(
              trainingVersionId,
              inheritedLevelSkill.skillsWithTrainingVersions.get(trainingVersionId)
                ? {
                    linkedObject: inheritedLevelSkill.linkedObject,
                    skillIds:
                      inheritedLevelSkill.skillsWithTrainingVersions.get(trainingVersionId)!,
                  }
                : undefined,
            );
          },
        );
      });
      setInheritedTrainingVersionWithSkills(_inheritedTrainingVersionWithSkillsActual);
    }

    setSkillRequirementWithOutTrainingVersions(_skillRequirementWithOutTrainingVersions);
    setSkillRequirementWithPossibleTrainingVersions(_skillRequirementWithPossibleTrainingVersions);
  }

  return (
    <View style={Styles.requirementTableInnerExtraContainer}>
      <View style={Styles.requirementTrainingInnerColumnContainer}>
        <Text style={Styles.requirementTrainingInnerColumnTitle}>
          {API.getWorkstationWorkerLevelLabel(requirementLevel)}
        </Text>
      </View>
      <ScrollView
        style={Styles.requirementTrainingScrollContainer}
        ref={ref}
        showsVerticalScrollIndicator={isHover}
      >
        {!skillRequirementWithOutTrainingVersions?.size &&
          !skillRequirementWithPossibleTrainingVersions?.size &&
          !trainingVersionsUsedInRequirement.size &&
          !inheritedTrainingVersionWithSkills?.size && (
            <Text style={Styles.noTrainingsText}>
              {t(
                'alex:workstations.workstationPanel.requirementTable.requirementTrainingColumn.noTraining',
              )}
            </Text>
          )}
        {skillRequirementWithOutTrainingVersions?.size ? (
          _.map(Array.from(skillRequirementWithOutTrainingVersions.entries()), skillRequirement => {
            return (
              <SkillsWithNoTrainingVersionsListing
                skillIds={skillRequirement[1].skillIds}
                handleWorkstationTrainingModal={handleWorkstationTrainingModal}
                requirement={skillRequirement[1].requirement}
              />
            );
          })
        ) : (
          <></>
        )}
        {skillRequirementWithPossibleTrainingVersions?.size
          ? _.map(
              Array.from(skillRequirementWithPossibleTrainingVersions.entries()),
              skillTrainingVersion => {
                return (
                  <SkillsWithPossibleTrainingVersionsListing
                    key={skillTrainingVersion[0]}
                    skillWithPossibleTrainingVersions={{
                      skillId: skillTrainingVersion[0],
                      trainingVersions: skillTrainingVersion[1].trainingVersions,
                    }}
                    requirement={skillTrainingVersion[1].requirement}
                    handleWorkstationTrainingModal={handleWorkstationTrainingModal}
                    refreshLevelSkills={refreshLevelSkills}
                  />
                );
              },
            )
          : null}

        <ScrollView style={Styles.skillTrainingsScroll}>
          {trainingVersionsUsedInRequirement &&
            levelSkillsPaneContents?.get(requirementLevel)?.levelSkills.sourceRequirement &&
            _.map(
              Array.from(trainingVersionsUsedInRequirement.entries()),
              trainingVersionAndSkillIds => {
                return (
                  <View
                    key={trainingVersionAndSkillIds[0]}
                    style={Styles.trainingSkillsListingCard}
                  >
                    <TrainingSkillsListing
                      trainingVersionId={trainingVersionAndSkillIds[0]}
                      skillIds={trainingVersionAndSkillIds[1]}
                      requirement={
                        levelSkillsPaneContents.get(requirementLevel)?.levelSkills
                          .sourceRequirement!
                      }
                      handleWorkstationTrainingModal={handleWorkstationTrainingModal}
                      refreshLevelSkills={refreshLevelSkills}
                      treeNode={treeNode}
                    />
                  </View>
                );
              },
            )}

          {inheritedTrainingVersionWithSkills &&
            _.map(
              Array.from(inheritedTrainingVersionWithSkills.entries()),
              trainingVersionAndSkillIds => {
                return (
                  <View
                    key={trainingVersionAndSkillIds[0]}
                    style={Styles.trainingSkillsListingCard}
                  >
                    <TrainingSkillsListing
                      key={trainingVersionAndSkillIds[0]}
                      trainingVersionId={trainingVersionAndSkillIds[0]}
                      skillIds={
                        trainingVersionAndSkillIds[1] ? trainingVersionAndSkillIds[1].skillIds : []
                      }
                      inheritedUnit={trainingVersionAndSkillIds[1]?.linkedObject}
                      disabled
                      treeNode={treeNode}
                    />
                  </View>
                );
              },
            )}
        </ScrollView>
      </ScrollView>
    </View>
  );
};
