import React, { useContext, useState } from 'react';
import { View, Text, Image } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors, Spacings, TextFontStyles } from 'shared/styles';
import { t } from 'shared/localisation/i18n';
import { Checkbox } from 'shared/ui-component/Checkbox';
import * as API from 'shared/backend-data';
import { LevelIcon } from 'shared/ui-component/Icon/LevelIcon/LevelIcon';
import { ActiveTrainingSessionsInput } from 'backend/src/api/api-auto';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';

const iconSize = { width: 28, height: 28 };

const IconInfo = require('shared/assets/svg/icon.info.svg').default;

const activeTrainingSessions: ActiveTrainingSessionsInput = {
  workstationActiveTrainingSessions: {
    lowerOrEqualToTarget: {
      fromNonInheritedRequirements: [],
      fromInheritedRequirements: [],
    },
    greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
  },
  nonWorkstationActiveTrainingSessions: {
    lowerOrEqualToTarget: {
      fromNonInheritedRequirements: [],
      fromInheritedRequirements: [],
    },
    greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
  },
};

const activeTrainingSessionsWithTraining: ActiveTrainingSessionsInput = {
  workstationActiveTrainingSessions: {
    lowerOrEqualToTarget: {
      fromNonInheritedRequirements: [],
      fromInheritedRequirements: [],
    },
    greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
  },
  nonWorkstationActiveTrainingSessions: {
    lowerOrEqualToTarget: {
      fromNonInheritedRequirements: [],
      fromInheritedRequirements: [],
    },
    greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
  },
};

export const Tutorial1: React.FC = () => {
  const { levelIconType } = useContext(GlobalDataContext);

  return (
    <View style={{ width: '100%', height: '100%', paddingVertical: 48, paddingHorizontal: 16 }}>
      <Text>{t('alex:updatesAcknowledgementModal.SKILLOP15.title')}</Text>
      <View style={{ flexDirection: 'row', marginTop: Spacings.Big, paddingLeft: Spacings.Medium }}>
        <Text
          style={{
            justifyContent: 'center',
          }}
        >
          - {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle1.0')}{' '}
          <Text style={{ ...TextFontStyles.LatoBold }}>
            {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle1.1', undefined, false)}
          </Text>
          {'     '}
        </Text>
        <View style={{ marginRight: 16 }}>
          <LevelIcon
            workerWorkstation={{
              level: API.WorkstationWorkerLevel.LEVEL0,
              previousLevel: API.WorkstationWorkerLevel.LEVEL0,
              targetLevel: undefined,
              isTrainAuto: false,
              activeTrainingSessions,
            }}
            iconSize={iconSize}
            levelIconType={levelIconType}
          />
        </View>
        <View style={{ marginRight: 16 }}>
          <LevelIcon
            workerWorkstation={{
              level: API.WorkstationWorkerLevel.LEVEL1,
              previousLevel: API.WorkstationWorkerLevel.LEVEL1,
              targetLevel: undefined,
              isTrainAuto: false,
              activeTrainingSessions,
            }}
            iconSize={iconSize}
            levelIconType={levelIconType}
          />
        </View>
        <View style={{ marginRight: 16 }}>
          <LevelIcon
            workerWorkstation={{
              level: API.WorkstationWorkerLevel.LEVEL2,
              previousLevel: API.WorkstationWorkerLevel.LEVEL2,
              targetLevel: undefined,
              isTrainAuto: false,
              activeTrainingSessions,
            }}
            iconSize={iconSize}
            levelIconType={levelIconType}
          />
        </View>
        <View style={{ marginRight: 16 }}>
          <LevelIcon
            workerWorkstation={{
              level: API.WorkstationWorkerLevel.LEVEL3,
              previousLevel: API.WorkstationWorkerLevel.LEVEL3,
              targetLevel: undefined,
              isTrainAuto: false,
              activeTrainingSessions,
            }}
            iconSize={iconSize}
            levelIconType={levelIconType}
          />
        </View>
        <View style={{ marginRight: 16 }}>
          <LevelIcon
            workerWorkstation={{
              level: API.WorkstationWorkerLevel.LEVEL4,
              previousLevel: API.WorkstationWorkerLevel.LEVEL4,
              targetLevel: undefined,
              isTrainAuto: false,
              activeTrainingSessions,
            }}
            iconSize={iconSize}
            levelIconType={levelIconType}
          />
        </View>
        <Text
          style={{
            justifyContent: 'center',
          }}
        >
          {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle1.2', undefined, false)}
        </Text>
      </View>

      <View style={{ flexDirection: 'row', marginTop: Spacings.Big, paddingLeft: Spacings.Medium }}>
        <Text style={{}}>
          - {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle2.0')}{' '}
          <Text style={{ ...TextFontStyles.LatoBold }}>
            {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle2.1', undefined, false)}
          </Text>{' '}
          {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle2.2', undefined, false)}
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          marginTop: Spacings.Large,
          paddingLeft: Spacings.Large,
          alignItems: 'flex-start',
        }}
      >
        <Text style={{ marginRight: 4 }}>
          {t('alex:updatesAcknowledgementModal.SKILLOP15.grey', undefined, false)}
        </Text>
        <LevelIcon
          workerWorkstation={{
            level: API.WorkstationWorkerLevel.LEVEL2,
            previousLevel: API.WorkstationWorkerLevel.LEVEL2,
            targetLevel: undefined,
            isTrainAuto: false,
            activeTrainingSessions,
          }}
          iconSize={iconSize}
          levelIconType={levelIconType}
        />

        <Text style={{}}>
          : {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle2.3', undefined, false)}
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          marginTop: Spacings.Large,
          paddingLeft: Spacings.Large,
          alignItems: 'flex-start',
        }}
      >
        <Text style={{ marginRight: 4 }}>
          {t('alex:updatesAcknowledgementModal.SKILLOP15.black', undefined, false)}
        </Text>
        <LevelIcon
          workerWorkstation={{
            level: API.WorkstationWorkerLevel.LEVEL2,
            previousLevel: API.WorkstationWorkerLevel.LEVEL2,
            targetLevel: API.WorkstationWorkerLevel.LEVEL3,
            isTrainAuto: false,
            activeTrainingSessions,
          }}
          iconSize={iconSize}
          levelIconType={levelIconType}
        />

        <Text style={{}}>
          : {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle2.4', undefined, false)}
        </Text>
      </View>

      <View style={{ flexDirection: 'row', marginTop: Spacings.Big, paddingLeft: Spacings.Medium }}>
        <Text style={{ marginRight: 8, justifyContent: 'center' }}>
          - {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle3.0')}
          <Text style={{ ...TextFontStyles.LatoBold }}>
            {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle3.1', undefined, false)}
          </Text>
        </Text>
        <LevelIcon
          workerWorkstation={{
            level: API.WorkstationWorkerLevel.LEVEL2,
            previousLevel: API.WorkstationWorkerLevel.LEVEL2,
            targetLevel: API.WorkstationWorkerLevel.LEVEL3,
            isTrainAuto: false,
            activeTrainingSessions: activeTrainingSessionsWithTraining,
          }}
          iconSize={iconSize}
          levelIconType={levelIconType}
        />
        <LevelIcon
          workerWorkstation={{
            level: API.WorkstationWorkerLevel.LEVEL2,
            previousLevel: API.WorkstationWorkerLevel.LEVEL2,
            targetLevel: API.WorkstationWorkerLevel.LEVEL3,
            isTrainAuto: true,
            activeTrainingSessions: activeTrainingSessionsWithTraining,
          }}
          iconSize={iconSize}
          levelIconType={levelIconType}
        />
        <Text>{t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle3.2', undefined, false)}</Text>
      </View>
    </View>
  );
};

export const Tutorial2: React.FC = () => {
  return (
    <View style={{ width: '100%', height: '100%', paddingVertical: 48, paddingHorizontal: 16 }}>
      <Text style={{ marginBottom: 16 }}>
        {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle4.0')}{' '}
        <Text style={{ ...TextFontStyles.LatoBold }}>
          {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle4.1', undefined, false)}
        </Text>{' '}
        {t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle4.2', undefined, false)}
      </Text>
      <View
        style={{
          flex: 1,
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.3,
          shadowRadius: 2,
        }}
      >
        <Image source={require('./levelTarget.gif')} style={{ flex: 1 }} />
      </View>
    </View>
  );
};

export const Tutorial3: React.FC = () => {
  const [isHideMessage, setIsHideMessage] = useState(false);

  const handleCheckBox = async (value: boolean | null) => {
    setIsHideMessage(!!value);

    await API.saveUserPreference(API.UserPreferenceKeys_Common.TutorialAcknowledged, value);
  };
  return (
    <View style={{ width: '100%', height: '100%', paddingVertical: 48, paddingHorizontal: 16 }}>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          marginBottom: Spacings.Small,
        }}
      >
        <IconSVG
          svgComponent={IconInfo}
          color={Colors.GreyLight}
          size={iconSize}
          containerStyle={{ marginRight: 8 }}
        />
        <Text>{t('alex:updatesAcknowledgementModal.SKILLOP15.subTitle5')}</Text>
      </View>
      <View
        style={{
          width: '100%',
          height: 160,
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.3,
          shadowRadius: 2,
          marginBottom: 120,
        }}
      >
        <Image source={require('./matrixInfo.png')} style={{ flex: 1 }} resizeMode="contain" />
      </View>
      <Checkbox
        initialState={isHideMessage}
        onChange={value => handleCheckBox(value)}
        titleStyle={{ paddingLeft: 36 }}
        text={t('common:button.hideTutorial')}
        style={{ marginBottom: Spacings.Small }}
      />
    </View>
  );
};
