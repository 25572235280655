import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity, InteractionManager } from 'react-native';
import { Colors, Spacings } from 'shared/styles';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { IconSVG } from 'shared/ui-component/Icon';
import { t, capitalizeFirstLetter } from 'shared/localisation/i18n';
import {
  TableColumn,
  Table,
  TableWidth,
  Tables,
  TableLargeColumnWidth,
  TableMediumColumnWidth,
} from 'shared/ui-component/Table';
import { EllipsisWithTooltip, Position } from 'shared/ui-component/EllipsisWithTooltip';
import { SortDirection } from '../../../sort';
import { TrainingRow, fetchRowDetails } from '../TrainingsLibrary';
import * as SharedStyles from 'shared/styles';
import Styles from './styles';
import { ModalUtils } from 'shared/ui-component/Modal';
import { loggerPerf, logger } from 'shared/util/Logger';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import {
  ModifyTrainingModal,
  TrainingModalConfig,
} from 'skillmgtweb/src/components/training/training-modal/container/TrainingModal';
import { deleteTrainingHandler } from './deleteTrainingHandler';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { TableNumberWithMenu } from 'sharedweb/src/NumberWithMenu';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../../navigation/Routes';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { ModifySkillModal } from '../../skills/components/modify-skill-modal/component';
import { replaceDiacriticsAndCapitalLetter, isArrayEmpty } from 'shared/util-ts/Functions';
import { ExportReport, ImportExportType } from 'shared/util/ExcelUtils';
import { ImportExportFileNames } from 'shared/util/ExcelUtils';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { DropdownConfigKey, TrainingTypeKey } from '../../../header-layout/headerFilterConfig';
import { MyFactoryContext } from '../../MyFactoryContext';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { Workbook } from 'exceljs';

const practicalTrainingIcon = require('shared/assets/svg/icon.practTrain.svg').default;
const notPracticalTrainingIcon = require('shared/assets/svg/icon.lecture.svg').default;

interface TrainingsTableProps {
  rows: TrainingRow[];
  filterTags: TagExtended[];
  fetchRows: (forceReload?: boolean) => Promise<void>;
  getExportData: (filteredRows: TrainingRow[]) => Promise<ExportReport>;
}

export const _TrainingsTable: React.FC<TrainingsTableProps> = props => {
  const modal = ModalUtils.useModal();
  const { rows, fetchRows, getExportData } = props;
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [skillId, setSkillId] = useState<string>();
  const [trainingModalConfig, setTrainingModalConfig] = useState<TrainingModalConfig>({
    editMode: false,
    trainingVersion: null,
    skillIds: [],
  });
  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);
  const isMounted = useIsMounted();
  const { isValidPermission } = useContext(PermissionManagementContext);
  const [filteredRows, setFilteredRows] = useState<TrainingRow[]>([]);
  const { setTrainingTableData } = useContext(MyFactoryContext);
  const history = useHistory();
  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      setFirstTitle(t('alex:header.skillsCatalog'));
      setSecondTitle(t('glossary:training_plural', {}, false));
    });
  }, []);

  useEffect(() => {
    setTrainingTableData({ rows, filteredRows });
  }, [filteredRows]);

  function onWorkstationPress(id: string) {
    const _treeNode = API.Tree.getTreeNode(id);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }

    setTreeNode(_treeNode);
    history.push(RouteLocations.Workstations(id));
  }

  const columns: TableColumn<TrainingRow>[] = [
    {
      label: t('alex:trainingsTab.table.header.0'),
      sort: sortByName,
      width: TableLargeColumnWidth,
      renderCell: (row, index, indexOfRow) => (
        <TouchableOpacity
          style={Styles.trainingInfoContainer}
          onPress={() => history.push(RouteLocations.TrainingProfile(row.training.id))}
          disabled={!isValidPermission(API.Permission.trainings_edit)}
        >
          <IconSVG
            svgComponent={row.isPractical ? practicalTrainingIcon : notPracticalTrainingIcon}
            size={{ width: Spacings.CardPadding, height: Spacings.CardPadding }}
            containerStyle={SharedStyles.Styles.tableObjectIconContainer}
            color={Colors.White}
          />
          <EllipsisWithTooltip
            text={capitalizeFirstLetter(row.training.name)}
            textStyle={SharedStyles.Styles.tableText}
            style={Styles.ellipsisText}
            position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
          />
        </TouchableOpacity>
      ),
    },
    {
      label: t('alex:trainingsTab.table.header.1'),
      width: TableWidth,
      sort: sortByType,
      renderCell: row => {
        return (
          <Text style={SharedStyles.Styles.tableText}>
            {row.isPractical === undefined ? (
              <LoaderThreeDots />
            ) : row.isPractical ? (
              t('glossary:trainingPractical')
            ) : (
              t('glossary:trainingNotPractical')
            )}
          </Text>
        );
      },
    },
    {
      label: t('alex:trainingsTab.table.header.2'),
      width: TableMediumColumnWidth,
      sort: sortByTag,
      renderCell: (row, _, indexOfRow) => {
        return (
          <EllipsisWithTooltip
            text={
              row.trainingTags
                ? row.trainingTags.map(tagItem => capitalizeFirstLetter(tagItem.name)).toString()
                : '-'
            }
            textStyle={SharedStyles.Styles.tableText}
            style={Styles.ellipsisText}
            position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
          />
        );
      },
    },
    {
      label: t('alex:trainingsTab.table.header.3'),
      width: TableWidth,
      sort: sortByWorkstations,
      renderCell: row => {
        return row.workstationsOrOrgUnits === undefined ? (
          <LoaderThreeDots />
        ) : (
          <TableNumberWithMenu
            list={row.workstationsOrOrgUnits}
            onMenuItemPress={onWorkstationPress}
          />
        );
      },
    },
    {
      label: t('alex:trainingsTab.table.header.4'),
      width: TableWidth,
      sort: sortBySkills,
      renderCell: row =>
        row.skills === undefined ? (
          <LoaderThreeDots />
        ) : (
          <TableNumberWithMenu
            list={row.skills}
            onMenuItemPress={(id: string) => setSkillId(id)}
            disabled={!isValidPermission(API.Permission.skills_edit)}
          />
        ),
    },
  ];

  function sortByName(rows: TrainingRow[], sortDirection: SortDirection) {
    return _.orderBy(rows, e => replaceDiacriticsAndCapitalLetter(e.training.name), [
      sortDirection,
    ]);
  }

  function sortByTag(rows: TrainingRow[], sortDirection: SortDirection) {
    return _.orderBy(
      rows,
      e =>
        _.map(e.trainingTags, tag => tag.name)
          .join(', ')
          .toLowerCase(),
      [sortDirection],
    );
  }

  function sortByType(rows: TrainingRow[], sortDirection: SortDirection) {
    return _.orderBy(rows, e => e.isPractical, [sortDirection]);
  }

  function sortByWorkstations(rows: TrainingRow[], sortDirection: SortDirection) {
    return _.orderBy(rows, e => e.workstationsOrOrgUnits?.length, [sortDirection]);
  }

  function sortBySkills(rows: TrainingRow[], sortDirection: SortDirection) {
    return _.orderBy(rows, e => e.skills?.length, [sortDirection]);
  }

  async function onMenuPress(trainingItem: TrainingRow, key: 'edit' | 'delete') {
    if (key === 'edit') await handleTrainingEdit(trainingItem);
    else if (key === 'delete') {
      await deleteTrainingHandler(trainingItem.training.id, modal, fetchRows);
    }
  }

  async function handleTrainingEdit(row: TrainingRow) {
    const latestTrainingVersion = await API.getTrainingVersionLatestForTraining(row.training.id);
    if (!isMounted.current) return;
    if (API.isFailure(latestTrainingVersion)) {
      logger.warn(latestTrainingVersion);
      return;
    }
    setTrainingModalConfig({
      editMode: true,
      trainingVersion: latestTrainingVersion,
      skillIds: latestTrainingVersion.skillIds,
    });
    setShowTrainingModal(true);
  }

  function handleSkillModalClose() {
    setSkillId(undefined);
  }

  function filterData(
    isKeywordFiltering: boolean,
    filterTags: TagExtended[],
    trainings: TrainingRow[],
  ): TrainingRow[] {
    if (isKeywordFiltering && isArrayEmpty(filterTags)) return [];
    let filteringRows = isKeywordFiltering ? [] : trainings;

    
    const workstationFilters: TagExtended[] = [];
    const skillFilters: TagExtended[] = [];
    const trainingTypeFilters: TagExtended[] = [];
    const trainingFilters: TagExtended[] = [];
    const trainingTagFilters: TagExtended[] = [];

    _.forEach(filterTags, tag => {
      if (tag.type === DropdownConfigKey.WORKSTATION) workstationFilters.push(tag);
      else if (tag.type === DropdownConfigKey.SKILL) skillFilters.push(tag);
      else if (tag.type === DropdownConfigKey.TYPE_OF_TRAINING) trainingTypeFilters.push(tag);
      else if (tag.type === DropdownConfigKey.TRAINING) trainingFilters.push(tag);
      else if (tag.type === DropdownConfigKey.TRAINING_TAG) trainingTagFilters.push(tag);
      else logger.warn('filter not found', tag);
    });

    
    if (trainingFilters.length) {
      const data = _.filter(isKeywordFiltering ? trainings : filteringRows, row =>
        _.some(trainingFilters, filter => row.training.id === filter.key),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    
    if (workstationFilters.length) {
      const data = _.filter(isKeywordFiltering ? trainings : filteringRows, row =>
        _.some(workstationFilters, filter => {
          if (row.workstationsOrOrgUnits === undefined) return true;

          const workstationIds = row.workstationsOrOrgUnits.map(workstation => workstation.id);
          if (workstationIds.includes(filter.key)) return true;

          return false;
        }),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    
    if (trainingTypeFilters.length) {
      const data = _.filter(isKeywordFiltering ? trainings : filteringRows, row =>
        _.some(trainingTypeFilters, filter => {
          if (row.isPractical === undefined) return true;

          if (filter.key === TrainingTypeKey.PRACTICAL) {
            return row.isPractical;
          } else {
            return !row.isPractical;
          }
        }),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    
    if (skillFilters.length) {
      const data = _.filter(isKeywordFiltering ? trainings : filteringRows, row =>
        _.some(skillFilters, filter => {
          if (row.skills === undefined) return true;

          const skillsIds = row.skills.map(skill => skill.id);
          if (skillsIds.includes(filter.key)) {
            return true;
          }

          return false;
        }),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    
    if (trainingTagFilters.length) {
      const data = _.filter(isKeywordFiltering ? trainings : filteringRows, row =>
        _.some(trainingTagFilters, filter => row.training.tagIds.includes(filter.key)),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    return filteringRows;
  }

  async function filterRows(rows: TrainingRow[], tags: TagExtended[]): Promise<TrainingRow[]> {
    if (!tags.length) return rows;

    let _filteredRows: TrainingRow[] = rows;
    let filterTags: TagExtended[] = [];
    let keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(tags, tag => {
      if (tag.isActiveBookmarkTag) {
        filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      _filteredRows = filterData(true, keywordTags, _.uniqBy(_filteredRows, 'key'));
    }

    if (filterTags.length) {
      _filteredRows = filterData(false, filterTags, _.uniqBy(_filteredRows, 'key'));
    }

    _filteredRows = _.uniqBy(_filteredRows, 'key');

    setFilteredRows(_filteredRows);

    return _filteredRows;
  }

  function saveRowData(rows: TrainingRow[]) {
    setTrainingTableData({ rows, filteredRows });
  }

  return (
    <View style={[SharedStyles.Styles.cardMain, Styles.tableContainer]}>
      <Table
        columnDescriptors={columns}
        rows={rows}
        sortPreferenceKey={Tables.TRAININGS}
        containerStyle={{
          borderRadius: Spacings.Small,
        }}
        importExport={{
          excelIcon: true,
          getExportData: getExportData,
          refreshData: async () => {
            fetchRows(true);
          },
          importExportType: ImportExportType.Training,
          importExportFileName: ImportExportFileNames.Trainings,
          showImportExport: isValidPermission(API.Permission.trainings_edit),
        }}
        disableRowClick={!isValidPermission(API.Permission.trainings_edit)}
        rowMenu={row => {
          return [
            {
              label: 'common:button.edit',
              onPress: () => onMenuPress(row, 'edit'),
              disable: !isValidPermission(API.Permission.trainings_edit),
            },
            {
              label: 'common:button.delete',
              onPress: () => onMenuPress(row, 'delete'),
              disable: !isValidPermission(API.Permission.trainings_edit),
            },
          ];
        }}
        rowLazyLoadProperties={fetchRowDetails}
        filter={{
          filterRows: filterRows,
          tags: props.filterTags,
        }}
        onRowPress={row => history.push(RouteLocations.TrainingProfile(row.training.id))}
        saveRowData={saveRowData}
      />
      {showTrainingModal && (
        <ModifyTrainingModal
          config={trainingModalConfig}
          handleModalClose={() => {
            setShowTrainingModal(false);
            setTrainingModalConfig({
              editMode: false,
              trainingVersion: null,
              skillIds: [],
            });
          }}
          handleRefresh={() => fetchRows(true)}
        />
      )}
      {skillId && <ModifySkillModal skillId={skillId} handleModalClose={handleSkillModalClose} />}
    </View>
  );
};

export const TrainingsTable = React.memo(_TrainingsTable, (prevProps, nextProps) => {
  const propsAreEqual =
    (prevProps.rows === nextProps.rows || (!prevProps.rows.length && !nextProps.rows.length)) &&
    (prevProps.filterTags === nextProps.filterTags ||
      (!prevProps.filterTags.length && !nextProps.filterTags.length));
  if (!propsAreEqual)
    loggerPerf.debug(
      'Re-Rendering component TrainingsTable (prevProps, nextProps)',
      prevProps,
      nextProps,
    );
  return propsAreEqual;
});
