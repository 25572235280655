import { loggerAPI as logger } from 'shared/util/Logger';
import * as API from 'shared/backend-data';
import { DataType, Factory } from 'shared/backend-data';
import * as _ from 'lodash-es';
import { FactoryCache } from './FactoryCache';
import { deepFreeze } from '../../util-ts/Functions';
import { FactoryMutationCacheEvent } from '../DataLayer';

type SkMap<T extends DataType> = Map<string, SkMap<T> | Factory<T>>;
type DataMap<T extends DataType> = Map<string, DataMap<T> | Factory<T>[]>;
export type GenericFactoryCacheMap<T extends DataType> = {
  sk: SkMap<T>;
  data: DataMap<T>;
};

export class GenericFactoryCache<T extends DataType> extends FactoryCache<
  T,
  GenericFactoryCacheMap<T>
> {
  constructor(pk: string, dataType: T, fill: boolean) {
    super(pk, dataType, fill, {
      sk: new Map(),
      data: new Map(),
    });
  }

  protected restore_deepFreezeFactories(data: GenericFactoryCacheMap<T>): void {
    this.deepFreezeSkOrDataMap(data.sk);
    this.deepFreezeSkOrDataMap(data.data);
  }

  private deepFreezeSkOrDataMap(skOrDataMap: SkMap<T> | DataMap<T>): void {
    skOrDataMap.forEach(value => {
      if (value instanceof Map) {
        this.deepFreezeSkOrDataMap(value);
      } else if (Array.isArray(value)) {
        value.forEach(factory => {
          deepFreeze(factory);
        });
      } else {
        deepFreeze(value);
      }
    });
  }

  protected structureData(factories: readonly Factory<T>[]): GenericFactoryCacheMap<T> {
    const cacheMap: GenericFactoryCacheMap<T> = {
      sk: new Map(),
      data: new Map(),
    };

    
    factories.forEach(factory => {
      this.addUpdateOrDeleteFactoryToSkMap(factory, cacheMap, true);
      this.addUpdateOrDeleteFactoryToDataMap(factory, cacheMap, true);
    });

    return cacheMap;
  }

  protected purge_inMemoryData(data: GenericFactoryCacheMap<T>): void {
    data.sk.clear();
    data.data.clear();
  }

  protected _getFactory(skOrIds: string | string[]): Factory<T> | undefined {
    const keys = this.getKeys(skOrIds);
    if (!keys.length) return;

    let _map = this.data.sk;
    for (let i = 0; i <= keys.length - 2; i++) {
      const key = keys[i];
      let __map = _map.get(key);
      if (!__map) return;
      if (!(__map instanceof Map)) {
        
        logger.warn(
          'Parsed sk (' +
            key +
            ') is not matching the expected format of the factoryCache (dataType=' +
            this.dataType +
            ')',
          keys.join(' ; '),
        );
        return;
      }
      _map = __map;
    }

    const factory = _map.get(keys[keys.length - 1]);
    if (factory instanceof Map) {
      logger.warn(
        'Passed sk is incomplete (by design only the last key shall contain a Factory): ' + skOrIds,
      );
      return;
    }

    return factory;
  }

  protected _getFactories(input?: {
    index: 'sk' | 'data';
    type: 'equal' | 'startingWith';
    factorySkOrData_or_keys: string | string[];
  }): Factory<T>[] {
    if (!input) return this.extractFactories(this.data.sk);

    const keys = this.getKeys(input.factorySkOrData_or_keys);
    if (!keys.length) return this.extractFactories(this.data.sk);

    let _map = input.index === 'sk' ? this.data.sk : this.data.data;
    for (let i = 0; i <= keys.length - 2; i++) {
      const key = keys[i];
      let __map = _map.get(key);
      if (!__map) return [];
      if (!(__map instanceof Map)) {
        
        logger.warn(
          'Parsed keysOrStartingWith (' +
            keys +
            ') is not matching the expected format of the factoryCache (dataType=' +
            this.dataType +
            ')',
          input.factorySkOrData_or_keys,
        );
        return [];
      }
      _map = __map;
    }

    const lastKey = keys[keys.length - 1];
    const result = _map.get(lastKey);

    if (result) {
      return this.extractFactories(result);
    } else {
      
      const _result: API.Factory<T>[] = [];
      _map.forEach((value, key) => {
        if (key.startsWith(lastKey)) {
          _result.push(...this.extractFactories(value));
        }
      });
      return _result;
    }
  }

  protected update_handleFactoryMutation(cacheEvent: FactoryMutationCacheEvent<T, false>): void {
    const { mutationType, factory } = cacheEvent;

    const addUpdateOrDelete =
      mutationType === 'createFactory' || mutationType === 'updateFactory' ? true : false;

    
    this.addUpdateOrDeleteFactoryToSkMap(factory, this.data, addUpdateOrDelete);

    
    
    
    
    if (cacheEvent.localPreviousFactory && factory.data !== cacheEvent.localPreviousFactory.data)
      this.addUpdateOrDeleteFactoryToDataMap(cacheEvent.localPreviousFactory, this.data, false);

    
    this.addUpdateOrDeleteFactoryToDataMap(factory, this.data, addUpdateOrDelete);
  }

  /**
   * Extract the factories contained inside the passed SkMap or DataMap
   * @param value
   */
  protected extractFactories(
    value: SkMap<T> | DataMap<T> | API.Factory<T> | API.Factory<T>[],
  ): Factory<T>[] {
    if (value instanceof Map) {
      const result: Factory<T>[] = [];
      value.forEach(_value => {
        result.push(...this.extractFactories(_value));
      });
      return result;
    } else if (Array.isArray(value)) {
      return value;
    } else {
      return [value];
    }
  }

  /** ####### SK ####### */
  protected addUpdateOrDeleteFactoryToSkMap(
    factory: Factory<T>,
    cacheMap: GenericFactoryCacheMap<T>,
    addUpdateOrDelete: boolean,
  ): void {
    const keys = this.getKeys(factory.sk);
    if (!keys.length) return;

    const maps: SkMap<T>[] = [cacheMap.sk];
    for (let i = 0; i <= keys.length - 2; i++) {
      const key = keys[i];
      let map = maps[i].get(key);
      if (!map) {
        if (!addUpdateOrDelete) return;
        map = new Map();
        maps[i].set(key, map);
      }
      if (!(map instanceof Map)) {
        
        logger.error(
          'FactoryCache (dataType=' +
            this.dataType +
            ') is corrupted or the passed factory.sk is incomplete. Please check.',
          cacheMap,
          factory.sk,
        );
        return;
      }

      maps.push(map);
    }

    const lastKey = keys[keys.length - 1];
    const lastMap = maps[keys.length - 1];
    if (addUpdateOrDelete) {
      lastMap.set(lastKey, factory);
    } else {
      lastMap.delete(lastKey);

      
      for (let i = keys.length - 1; i > 0; i--) {
        if (maps[i].size === 0) {
          maps[i - 1].delete(keys[i - 1]);
        }
      }
    }
  }

  /** ####### DATA ####### */
  private addUpdateOrDeleteFactoryToDataMap(
    factory: Factory<T>,
    cacheMap: GenericFactoryCacheMap<T>,
    addUpdateOrDelete: boolean,
  ): void {
    if (!factory.data) return;

    const keys = this.getKeys(factory.data);
    if (!keys.length) return;

    const maps: DataMap<T>[] = [cacheMap.data];
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      let map = maps[i].get(key);
      if (!map) {
        if (!addUpdateOrDelete) return;
        map = new Map();
        maps[i].set(key, map);
      }
      if (!(map instanceof Map)) {
        
        logger.error(
          'FactoryCache (dataType=' +
            this.dataType +
            ') is corrupted or the passed factory.data is incomplete. Please check.',
          cacheMap,
          factory.data,
        );
        return;
      }

      maps.push(map);
    }

    const lastKey = keys[keys.length - 1];
    const lastMap = maps[keys.length - 1];
    let factories = lastMap.get(lastKey);
    if (!factories) {
      if (!addUpdateOrDelete) return;
      factories = [];
      lastMap.set(lastKey, factories);
    }
    if (factories instanceof Map) {
      logger.error(
        'Passed data is incomplete (by design only the last key shal contain a Factory[]): ' +
          factory.data,
      );
      return;
    }

    const index = factories.findIndex(_factory => _factory.sk === factory.sk);
    if (addUpdateOrDelete) {
      if (index === -1) {
        factories.push(factory);
      } else {
        factories[index] = factory; 
      }
    } else {
      if (index !== -1) {
        factories.splice(index, 1);
      }
      if (!factories.length) lastMap.delete(lastKey);

      
      for (let i = keys.length - 1; i > 0; i--) {
        if (maps[i].size === 0) {
          maps[i - 1].delete(keys[i - 1]);
        }
      }
    }
  }
}
