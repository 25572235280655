import React, { useEffect, useState, useContext } from 'react';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { BannerSkeleton } from '../bannerSkeleton/BannerSkeletonComponent';
import { View, Text, InteractionManager, TouchableOpacity } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import styles from './styles';
import { Colors, Spacings } from 'shared/styles';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import BannerStyle from '../bannerSkeleton/styles';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { MenuItem } from 'shared/ui-component/Menu';
import { ModifySkillModal } from '../../my-factory/skills/components/modify-skill-modal/component/index';
import { MyHub } from 'shared/util/MyHub';

const SkillIcon = require('shared/assets/svg/icon.skill.svg').default;

interface Props {
  skill: API.Skill;
}

export const SkillBanner: React.FC<Props> = props => {
  const { skill } = props;

  const isMounted = useIsMounted();

  const [trainingVersionsAndTraining, setTrainingVersionsAndTraining] = useState<
    API.TrainingVersionAndTraining[]
  >([]);
  const [showModifySkillModal, setShowModifySkillModal] = useState(false);
  const [validityAndExpiry, setValidityAndExpiry] =
    useState<API.SkillValidityDurationAndExpiryNoticeDuration>();

  const { isValidPermission } = useContext(PermissionManagementContext);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.TRAININGVERSION) {
        _getTrainingVersionAndTrainingsForSkill();
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      const _validityAndExpiry = await API.getSkillValidityDurationAndExpiryNoticeDuration(skill);
      setValidityAndExpiry(_validityAndExpiry);

      _getTrainingVersionAndTrainingsForSkill();
    });

    return () => {
      removeListener();
    };
  }, [skill.id]);

  async function _getTrainingVersionAndTrainingsForSkill(): Promise<void> {
    const skillTrainingAndTrainingVersions = await API.getTrainingVersionAndTrainingsForSkill(
      skill.id,
      true,
    );
    if (!isMounted.current) return;
    if (API.isFailure(skillTrainingAndTrainingVersions)) {
      logger.warn(skillTrainingAndTrainingVersions);
      return;
    }
    setTrainingVersionsAndTraining(skillTrainingAndTrainingVersions);
  }

  function skillMenuItems(): MenuItem[] {
    return [
      {
        label: 'common:button.edit',
        onPress: () => setShowModifySkillModal(true),
        disable: !isValidPermission(API.Permission.skills_edit),
      },
    ];
  }

  return (
    <>
      <BannerSkeleton
        headerIcon={
          <View style={styles.iconContainer}>
            <TouchableOpacity onPress={() => setShowModifySkillModal(true)}>
              <IconSVG
                svgComponent={SkillIcon}
                size={{ height: 52, width: 52 }}
                color={Colors.White}
              />
            </TouchableOpacity>
          </View>
        }
        headerTitle={capitalizeFirstLetter(skill.name)}
        subHeaderComponent={
          <Text numberOfLines={3}>{capitalizeFirstLetter(skill.description ?? '')}</Text>
        }
        menu={{
          showMenu: isValidPermission(API.Permission.skills_edit),
          menuItems: skillMenuItems(),
        }}
        bannerContent={{
          component: (
            <View style={styles.contentContainer}>
              {validityAndExpiry?.validityDuration ? (
                <Text style={BannerStyle.contentTitle}>
                  {t('alex:skills.validityAfterObtainingSkill')}
                </Text>
              ) : (
                <Text style={[BannerStyle.contentTitle, { marginBottom: Spacings.Large }]}>
                  {t('alex:workerSkill.neverExpire')}
                </Text>
              )}
              {validityAndExpiry?.validityDuration && (
                <Text style={BannerStyle.contentSubTitle}>
                  {`${validityAndExpiry.validityDuration} ${t(
                    'alex:skills.validityCountInMonth',
                    {
                      count: validityAndExpiry.validityDuration,
                    },
                    false,
                  )}`}
                  {validityAndExpiry.expiryNoticeDuration && (
                    <Text style={BannerStyle.contentSubTitle}>
                      {` (${t(
                        'alex:skills.alertBeforeNDaysExpiration',
                        {
                          count: validityAndExpiry.expiryNoticeDuration,
                        },
                        false,
                      )})`}
                    </Text>
                  )}
                </Text>
              )}

              <Text style={BannerStyle.contentTitle}>{t('alex:skills.trainingType')}...</Text>
              <Text style={BannerStyle.contentSubTitle}>
                {skill.isPractical
                  ? t('glossary:trainingPractical')
                  : t('glossary:trainingNotPractical')}
              </Text>

              {trainingVersionsAndTraining.length ? (
                <>
                  <Text style={BannerStyle.contentTitle}>{t('alex:skills.trainingList')}...</Text>
                  <View style={styles.trainings}>
                    {trainingVersionsAndTraining.map((eachTraining, index) => (
                      <Text key={index} style={styles.trainingName}>
                        {eachTraining[1].name}
                      </Text>
                    ))}
                  </View>
                </>
              ) : (
                <>
                  <Text style={BannerStyle.contentTitle}>{t('alex:skills.noTraining')}</Text>
                  <View style={styles.trainings}>
                    <Text style={[styles.trainingName, { color: Colors.Grey }]}>
                      {t('alex:skills.noTrainingDescription')}
                    </Text>
                  </View>
                </>
              )}
            </View>
          ),
        }}
        files={skill.files}
      />
      {showModifySkillModal && (
        <ModifySkillModal
          skillId={skill.id}
          handleModalClose={() => setShowModifySkillModal(false)}
        />
      )}
    </>
  );
};
