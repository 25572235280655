import React from 'react';
import { ModalUtils } from 'shared/ui-component/Modal';
import { t } from 'shared/localisation/i18n';
import { ModalContext } from 'shared/ui-component/Modal/Modal';
import { UserContext } from '../context/UserContext';

export const Warning = (props: any) => {
  return (
    <div id="overlay">
      <div className="warning-wrapper">
        <span className="warning-message">{props.warningMessage}</span>
        <div
          className="warning-accept"
          onClick={() => {
            props.handleWarningAccept();
            if (props.isLogout) {
              UserContext.signOut();
              window && window.location.reload();
            }
          }}
        >
          {props.warningAcceptText}
        </div>
        <span className="warning-cancel" onClick={props.handleWarningCancel}>
          cancel
        </span>
      </div>
    </div>
  );
};

export function tagErrorModal(modal: ModalContext, message?: string) {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: message ?? t('common:error.tagExists'),
      warningAcceptButton: t('common:button.gotIt'),
    }),
  );
}
