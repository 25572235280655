import { StyleSheet } from 'react-native';
import { responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions';
import { Platform } from 'react-native';
const Styles = StyleSheet.create({
  box: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#F1F3F4',
    borderColor: '#808080',
    borderWidth: 1,
    borderRadius: 4,
  },
  input: {
    width: Platform.OS === 'web' ? '1.875vw' : responsiveWidth(6),
    height: Platform.OS === 'web' ? '2.28vh' : responsiveHeight(6),
    fontSize: Platform.OS === 'web' ? '2.28vh' : responsiveHeight(2),
    textAlign: 'center',
    borderBottomWidth: 2,
    borderBottomColor: '#AFAFAF',
  },
});

export default Styles;
