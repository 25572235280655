import React from 'react';
import { View, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { t } from 'shared/localisation/i18n';
import * as API from 'shared/backend-data/index';
import Styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { Spacings } from 'shared/styles';
import * as _ from 'lodash-es';

interface Props {
  complementaryTrainings?: API.Training[];
  level?: API.WorkstationWorkerLevels;
  handleTrainAWorkerModal?: () => void;
}

const ICON_INFO = require('shared/assets/svg/icon.info.svg').default;
const ICON_TRAINING = require('shared/assets/svg/icon.lecture.svg').default;

export const ComplementaryTrainingsMessage: React.FC<Props> = props => {
  const { complementaryTrainings, level, handleTrainAWorkerModal } = props;

  return (
    <View style={Styles.complementaryTrainingsContainer}>
      <IconSVG
        svgComponent={ICON_INFO}
        containerStyle={{ width: 24, height: 24, marginRight: Spacings.Small }}
        size={{ width: 24, height: 24 }}
      />
      <View style={Styles.warningMessageContainer}>
        <Text style={Styles.warningTextStyle}>
          {t('alex:TrainingModal.complementaryTrainingsMessage.0', {
            level: level,
            trainingsCount: complementaryTrainings?.length,
          })}{' '}
        </Text>
        <View style={Styles.complementaryWarningDivider} />
        {_.map(complementaryTrainings, _training => {
          return (
            <View style={Styles.complementaryTrainingRowContainer} key={_training.id}>
              <IconSVG
                svgComponent={ICON_TRAINING}
                size={{ width: 16, height: 16 }}
                containerStyle={{ height: 16, width: 16, marginRight: Spacings.Unit }}
              />
              <Text numberOfLines={1} style={Styles.warningTextStyle}>
                {_training.name}
              </Text>
            </View>
          );
        })}
        <View style={Styles.complementaryWarningDivider} />
        <Text>
          {t('alex:TrainingModal.complementaryTrainingsMessage.1')} {'"'}
          <TouchableOpacity
            onPress={() => {
              if (handleTrainAWorkerModal) {
                handleTrainAWorkerModal();
              }
            }}
          >
            <Text style={Styles.warningUnderLineTextStyle}>
              {t('alex:TrainingModal.complementaryTrainingsMessage.2')}
            </Text>
          </TouchableOpacity>
          {'"'} {t('alex:TrainingModal.complementaryTrainingsMessage.3', undefined, false)}
        </Text>
      </View>
    </View>
  );
};
