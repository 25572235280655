import { useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';
import { GlobalDataContext } from '../skillmgt/context/GlobalDataContext';
import { useHistory } from 'react-router-dom';
import { AppFeature } from '../backend-data';
import { RouteLocations } from 'skillmgtweb/src/components/navigation/Routes';
import { RouteNameCommon } from 'sharedmob/src/navigation/Route';
import { useNavigation } from '@react-navigation/native';

const isPlatformWeb = Platform.OS === 'web';

/**
 * Hook used to block the display of the current component is user doens't have the specified Feature
 */
export function useFeature(feature: AppFeature) {
  const modal = useModal();
  const { features } = useContext(GlobalDataContext);
  const [navigateToHome, setNavigateToHome] = useState<boolean>(false);

  function showFeatureMissingAcceptCallback() {
    setNavigateToHome(true);
  }

  if (isPlatformWeb) {
    const history = useHistory();

    useEffect(() => {
      if (navigateToHome) {
        history.push(RouteLocations.Home());
      } else {
      }
    }, [navigateToHome]);
  } else {
    useEffect(() => {
      if (navigateToHome) {
        const navigation = useNavigation();

        navigation.navigate(RouteNameCommon.HOME, {});
      }
    }, [navigateToHome]);
  }

  useEffect(() => {
    if (!features.some(f => f.feature === feature)) {
      ModalUtils.showFeatureMissing(modal, showFeatureMissingAcceptCallback, feature, true);
    } else {
      modal.hideModal();
    }
  }, [features]);
}
