import {
  deburr,
  forOwn,
  has,
  isArray,
  isEqual,
  isObject,
  isPlainObject,
  map,
  mapValues,
  omitBy,
  transform,
} from 'lodash-es';

export const imagePattern = /\.(jpeg|jpg|gif|png)$/;
export const imageMimePattern = /image\/(jpeg|jpg|gif|png)$/;

type ImmutablePrimitive = undefined | null | boolean | string | number | Function;
type ImmutableMap<K, V> = ReadonlyMap<Immutable<K>, Immutable<V>>;
type ImmutableSet<T> = ReadonlySet<Immutable<T>>;
/** DeepReadonly type that works for objects, arrays and tuples */
type ImmutableObject<T> = { readonly [K in keyof T]: Immutable<T[K]> };

export type Immutable<T> = T extends ImmutablePrimitive
  ? T
  : 
  T extends Map<infer K, infer V>
  ? ImmutableMap<K, V>
  : T extends Set<infer M>
  ? ImmutableSet<M>
  : ImmutableObject<T>;

/** Opposite of Immutable<T> */
export type Writable<T> = T extends ImmutablePrimitive
  ? T
  : 
  T extends ImmutableMap<infer K, infer V>
  ? Map<K, V>
  : T extends ImmutableSet<infer M>
  ? Set<M>
  : 
    WritableObject<T>;
type WritableObject<T> = { -readonly [K in keyof T]: Writable<T[K]> };

/** Union of primitives to skip with deep omit utilities. */
type Primitive = string | Function | number | boolean | Symbol | undefined | null;
type DeepOmitHelper<T, K extends keyof T> = {
  [P in K]: T[P] extends infer TP 
    ? TP extends Primitive
      ? TP 
      : TP extends any[]
      ? DeepOmitArray<TP, K> 
      : DeepOmit<TP, K>
    : never;
};
export type DeepOmit<T, K> = T extends Primitive ? T : DeepOmitHelper<T, Exclude<keyof T, K>>;
export type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};




export function hasKey<O extends object>(object: O, key: keyof any): key is keyof O {
  return key in object;
}

export function hasValue<O extends object>(obj: O, value: any): value is O[keyof O] {
  return enumValues(obj).some(v => v === value);
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function enumValues<O extends object>(obj: O): O[keyof O][] {
  return enumKeys(obj).map(key => obj[key]);
}

export function enumEntries<O extends object>(obj: O): [keyof O, O[keyof O]][] {
  return enumKeys(obj).map(key => [key, obj[key]]);
}

/**
 * Returns a random value between the given ones.
 * To get a random enum values use: randomValue(enumValues(enum))
 * @param values
 * @returns
 */
export function randomValue<T extends any>(values: T[]): T {
  return values[Math.floor(Math.random() * values.length)];
}

/**
 * Extract keys of an object into a flat array until the given nested level
 * @param object
 * @param nestedLevel (optional) if not set, it will do a deep extracting
 */
export function extractKeys(object: object, nestedLevel?: number): string[] {
  const result: string[] = [];

  for (const [key, value] of Object.entries(object)) {
    if ((!nestedLevel || nestedLevel > 0) && isPlainObject(value)) {
      result.push(...extractKeys(value, nestedLevel ? nestedLevel - 1 : undefined));
    } else {
      result.push(key);
    }
  }

  return result;
}

/**
 * function to avoid unnecessary conditional expressions for nested objects value and more code readability
  input: getNestedProperties({user:{name:'john'}}, "name.firstName")
  output: undefined
  returnType: string or undefined
 * @param obj 
 * @param key 
 */
export function getNestedProperties(obj: object, key: string) {
  return key.split('.').reduce(function (o: any, x: any) {
    return typeof o == 'undefined' || o === null ? o : o[x];
  }, obj);
}

/**
 * function to find key value from nested object without much mess
 * example if there is some key name 'abcd' is deep down in nested object it will find its value and give it in result
 * if there are multiple keys with same name it will give all values of that keys in array.
 * very handy function to avoid mess of conditions and code.
 */
export const findProp = (obj: any, key: string, out?: any) => {
  var i,
    proto = Object.prototype,
    ts = proto.toString,
    hasOwn = proto.hasOwnProperty.bind(obj);

  if ('[object Array]' !== ts.call(out)) out = [];

  for (i in obj) {
    if (hasOwn(i)) {
      if (i === key) {
        out.push(obj[i]);
      } else if ('[object Array]' === ts.call(obj[i]) || '[object Object]' === ts.call(obj[i])) {
        findProp(obj[i], key, out);
      }
    }
  }

  return out;
};

/**
 * Create a deep copy of the given object by omiting recursively properties
 * where the given predicate return true.
 * @param value
 * @param predicate
 */
export function omitByRecursively<T extends Object>(
  value: T,
  predicate: Parameters<typeof omitBy<T>>[1],
): any {
  const cb = (v: T) => omitByRecursively(v, predicate);
  return isObject(value)
    ? isArray(value)
      ? map(value, cb)
      : mapValues(omitBy(value, predicate), cb)
    : value;
}

/**
 * Deep diff between two objects - i.e. an object with the new value of new & changed fields.
 * Removed fields will be set as undefined on the result.
 * Only plain objects will be deeply compared (@see _.isPlainObject)
 *
 *
 *
 *
 * @param  {Object} base   Object to compare with (if falsy we return object)
 * @param  {Object} object Object compared
 * @return {Object}        Return a new object who represent the changed & new values
 */
export function deepDiffObj(base: object, object: object): object {
  if (!object) throw new Error(`The object compared should be an object: ${object}`);
  if (!base) return object;

  const result: any = transform(object, (result, value, key) => {
    if (!has(base, key)) result[key] = value; 
    if (!isEqual(value, base[key])) {
      result[key] =
        isPlainObject(value) && isPlainObject(base[key]) ? deepDiffObj(base[key], value) : value;
    }
  });

  
  forOwn(base, (value, key) => {
    if (!has(object, key)) result[key] = undefined;
  });

  return result as object;
}

/**
 * To make an object immutable, recursively freeze each property which is of type object (deep freeze).
 * Use the pattern on a case-by-case basis based on your design when you know the object contains no cycles
 * in the reference graph, otherwise an endless loop will be triggered.
 * Unfreeze is not possible: one need to deepClone the object instead @see API.deepClone()
 * @see Object.freeze
 * @param object
 * @returns same object frozen
 */
export function deepFreeze<T>(object: T): Immutable<T> {
  const t0 = Date.now();
  if (Object.isFrozen(object)) return object as Immutable<T>;

  
  const propNames = Object.getOwnPropertyNames(object) as Array<keyof T>;

  
  for (const name of propNames) {
    const value = object[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(object) as Immutable<T>;
}

/**
 * Pause the current function execution for the given number of milliseconds
 * @param ms
 */
export async function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isStringArray(test: any): test is string[] {
  return Array.isArray(test) && test.every(value => typeof value === 'string');
}
export function isSameStringArray(object1: string[], object2: string[]): boolean {
  return (
    object1.every(item => object2.includes(item)) && object2.every(item => object1.includes(item))
  );
}

export function isImage(url: string): boolean {
  return url.match(imagePattern) != null;
}

export function isImageMime(url: string): boolean {
  return url.match(imageMimePattern) != null;
}

/**
 * Check if the object is a Date an that it is a valid Date
 * @param date
 * @returns
 */
export function isValidDate(date: any): date is Date {
  return date instanceof Date && !isNaN(date.getTime());
}

export function sortByUpdatedAt<T extends { updatedAt: string }>(data: Array<T>) {
  return data.sort((a, b) => {
    if (a.updatedAt < b.updatedAt) return 1;
    if (a.updatedAt > b.updatedAt) return -1;
    return 0;
  });
}

export function convertDataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const mimeType = arr[0].match(/:(.*?);/);
  const mime = mimeType ? mimeType[1] : undefined;
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function removeFileNameExtension(fileName: string): string {
  return fileName.substr(0, fileName.lastIndexOf('.'));
}

/**
 * Test if a string equal or contains a search pattern.
 * Strings are compared after trimming unless specified.
 * Result is case and accent insensitive
 * @param stringToTest
 * @param stringToSearch
 * @param exactMatch (optional, default false) if true: will search for stringToSearch = stringToTest (case and accent insensitive).
 *                             if false: will search for stringToSearch included inside stringToTest (case and accent insensitive).
 * @param noTrim (optional, default false) if true: whitespaces before and after string are not removed for comparison
 * @return boolean - true if strings matches, else false
 */
export function searchMatch(
  stringToTest: string,
  stringToSearch: string,
  exactMatch: boolean,
  noTrim?: boolean,
): boolean {
  
  const _stringToTest: string = noTrim ? stringToTest : stringToTest.trim();
  const _stringToSearch: string = noTrim ? stringToSearch : stringToSearch.trim();
  if (!_stringToSearch.length) return false; 

  if (exactMatch) {
    return (
      replaceDiacriticsAndCapitalLetter(_stringToTest) ===
      replaceDiacriticsAndCapitalLetter(_stringToSearch)
    );
  } else {
    return replaceDiacriticsAndCapitalLetter(_stringToTest).includes(
      replaceDiacriticsAndCapitalLetter(_stringToSearch),
    );
  }
}

/**
 * To replace diactritics characters with normal text and to lowercase
 * eg: Contrôle Réception => controle reception
 */
export function replaceDiacriticsAndCapitalLetter(string: string): string {
  
  
  if (String.prototype.normalize()) {
    return string
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  } else {
    
    return deburr(string).toLowerCase();
  }
}


export const escapeJSON = (json: string) => {
  let escapable =
    /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
  let meta = {
    
    '\b': '\\b',
    '\t': '\\t',
    '\n': '\\n',
    '\f': '\\f',
    '\r': '\\r',
    '"': '\\"',
    '\\': '\\\\',
  };

  escapable.lastIndex = 0;
  return escapable.test(json)
    ? '"' +
        json.replace(escapable, function (a: string) {
          var c = meta[a as keyof typeof meta]; 
          return typeof c === 'string'
            ? c
            : '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
        }) +
        '"'
    : '"' + json + '"';
};

/**
 * takes a string and the length you want the string to be truncated to and give you a truncates it
 */
export function truncate(string: string, lenght?: number): string {
  return string.slice(0, lenght ?? 4) + '. ';
}

export function isArrayEmpty<T>(array: T[]): boolean {
  return !array.length;
}

export const isPlatformServerless = Boolean(!!process.env.LAMBDA_TASK_ROOT); 
