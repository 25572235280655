import React from 'react';
import { InputText, InputTextProps } from '../InputText/InputText';
import { Spacings } from '../../../styles';

/**
 * This component is used when we need Text Input as description,
 * which having multilines and number of lines as 4
 * @param props same as TextInput
 * @returns
 */
export const InputTextDescription: React.FC<InputTextProps> = props => {
  return (
    <InputText
      {...props}
      multiline
      nbLine={Spacings.Unit}
      containerStyle={[props.containerStyle, { height: 'auto', paddingTop: 12 }]}
    />
  );
};
