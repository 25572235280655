import { fontSize, Colors, TextFontStyles, Spacings } from 'shared/styles';
import { StyleSheet, Platform } from 'react-native';

const isPlatformWeb = Platform.OS === 'web';

export const Styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: isPlatformWeb ? fontSize.Small : fontSize.Medium,
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    marginBottom: Spacings.xMedium,
    marginLeft: Spacings.Unit,
  },
});
