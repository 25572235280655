import { Colors, TextFontStyles, fontSize, Spacings } from 'shared/styles';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 50,
    paddingHorizontal: 12,
    paddingTop: 12,
  },
  inputTextLabel: {
    ...TextFontStyles.LatoRegular,
    fontSize: 12,
    color: Colors.Grey,
  },
  inputTextContainer: {
    flex: 1,
    paddingLeft: 26,
    paddingRight: 34,
    marginBottom: Spacings.Standard + Spacings.Unit,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: 'rgba(52, 52, 52, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: '332px',
    backgroundColor: Colors.White,
    borderRadius: 10,
  },
  inputTextStyle: {
    height: 30,
    marginBottom: 0,
  },
  description: {
    fontSize: fontSize.Small,
    color: Colors.BlackLight,
    marginTop: Spacings.Medium,
    ...TextFontStyles.LatoRegular,
  },
});

export default styles;
