import { Platform } from 'react-native';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { Chapter, SkillsAndSkillGroups } from './SummaryBook';
import { logger } from '../../util/Logger';
import Aigle from 'aigle';
import { convertDataURLtoFile } from '../../util-ts/Functions';
import moment from 'moment';
import { t } from '../../localisation/i18n';
import { ProofBookType } from 'skillmgtweb/src/components/training/proof-book';

export enum PageType {
  Skill,
  SkillGroup,
}

export interface Page {
  worker: API.Worker;
  skill: SkillsAndSkillGroups;
  type: PageType;
  skillOriginSkillGroup?: string;
  proofBundle?: API.ProofBundle;
  commonFiles?: API.S3Object[];
  isProofBundleCompleted: boolean;
  isProofBundleSubmitted: boolean;
  isProofBundleValidated: boolean;
}

export interface ProofBook {
  workersPages: Map<string, Page[]>;
  isPracticalTraining: boolean; 
  numberOfSkills: number;
  numberOfWorkers: number;
  isProofBookCompleted: boolean;
  isProofBookSubmitted: boolean;
  isProofBookValidated: boolean;
}

const UriPrefix = 'file://';

function getProofBundleIssueDate(proofBundles: API.NoMetadata<API.ProofBundle>[]) {
  if (
    proofBundles.length &&
    proofBundles.every(proofBundle => proofBundle.startingDate === proofBundles[0].startingDate)
  ) {
    return proofBundles[0].startingDate;
  }

  return new Date().toISOString();
}

/**
 * To get the chapters for Proof Book
 * @param workersPages
 * @param totalNumberOfSkills
 */
export async function getChapters(
  workersPages: Map<string, Page[]>,
  totalNumberOfSkills: number,
  totalNumberOfWorkers: number,
): Promise<Chapter[]> {
  const chapters: Chapter[] = [];
  let commonFileForAllWorkers: API.S3Object[] = [];
  let commonFiles: Array<API.S3Object[]> = [];

  if (totalNumberOfWorkers > 1) {
    let isChapterCompleted = true;
    let isChapterSubmitted = true;
    let isChapterValidated = true;

    const skills: SkillsAndSkillGroups[] = [];
    const workers: API.Worker[] = [];
    const allProofBundles: API.ProofBundle[] = [];

    Array.from(workersPages.values()).forEach(workerPages => {
      workerPages.forEach(page => {
        if (page.proofBundle) allProofBundles.push(page.proofBundle);

        isChapterCompleted =
          isChapterCompleted && workerPages.every(page => page.isProofBundleCompleted);
        isChapterSubmitted =
          isChapterSubmitted && workerPages.every(page => page.isProofBundleSubmitted);
        isChapterValidated =
          isChapterValidated && workerPages.every(page => page.isProofBundleValidated);

        skills.push(page.skill);
        workers.push(page.worker);

        if ((page.commonFiles && !page.commonFiles.length) || !page.commonFiles) {
          
        } else if (page.commonFiles.length) {
          commonFiles.push(page.commonFiles);
        }
      });
    });

    if (commonFiles.length)
      commonFileForAllWorkers = commonFiles.reduce((fileA, fileB) =>
        fileA.filter(fileC => fileB.find(_fileB => _fileB.key === fileC.key)),
      );

    if (commonFileForAllWorkers.length) {
      commonFileForAllWorkers.forEach(eachCommonFile => {
        chapters.push({
          proofBundles: allProofBundles,
          file: [eachCommonFile],
          skills: _.uniqBy(skills, 'id'),
          trainees: _.uniqBy(workers, 'id'),
          isCommon: true,
          issueDate: getProofBundleIssueDate(allProofBundles),
          isChapterCompleted,
          isChapterSubmitted,
          isChapterValidated,
        });
      });
    } else {
      chapters.push({
        proofBundles: allProofBundles,
        file: undefined,
        skills: _.uniqBy(skills, 'id'),
        trainees: _.uniqBy(workers, 'id'),
        isCommon: false,
        issueDate: getProofBundleIssueDate(allProofBundles),
        isChapterCompleted,
        isChapterSubmitted,
        isChapterValidated,
      });
    }
  }

  _.map(Array.from(workersPages.entries()), ([workerId, pages]) => {
    if (!pages.length) return;

    const skills: SkillsAndSkillGroups[] = [];
    const proofBundles: API.ProofBundle[] = [];
    const filesUploaded: API.S3Object[] = [];

    pages.forEach((page, index) => {
      if (index === 0) {
        filesUploaded.push(...(page.commonFiles || []));
      }
      skills.push(page.skill);
      if (page.proofBundle) {
        proofBundles.push(page.proofBundle);
      }
    });

    
    if (totalNumberOfSkills > 1) {
      if (filesUploaded.length) {
        filesUploaded.forEach(eachFile => {
          chapters.push({
            proofBundles: proofBundles,
            file: [eachFile],
            skills: skills,
            trainees: [pages[0].worker],
            isCommon: true,
            issueDate: getProofBundleIssueDate(proofBundles),
            isChapterCompleted: pages.every(page => page.isProofBundleCompleted),
            isChapterSubmitted: pages.every(page => page.isProofBundleSubmitted),
            isChapterValidated: pages.every(page => page.isProofBundleValidated),
          });
        });
      } else {
        chapters.push({
          proofBundles: proofBundles,
          file: undefined,
          skills: skills,
          trainees: [pages[0].worker],
          isCommon: false,
          issueDate: getProofBundleIssueDate(proofBundles),
          isChapterCompleted: pages.every(page => page.isProofBundleCompleted),
          isChapterSubmitted: pages.every(page => page.isProofBundleSubmitted),
          isChapterValidated: pages.every(page => page.isProofBundleValidated),
        });
      }
    }

    pages.forEach(page => {
      if (page.proofBundle) {
        if (page.proofBundle.files.length) {
          for (const file of page.proofBundle.files) {
            chapters.push({
              proofBundles: [page.proofBundle!],
              file: [file],
              trainees: [page.worker],
              isCommon: false,
              skills: [page.skill],
              acquired: page.proofBundle?.acquired,
              issueDate: page.proofBundle?.startingDate,
              comment: page.proofBundle?.description,
              isChapterCompleted: page.isProofBundleCompleted,
              isChapterSubmitted: page.isProofBundleSubmitted,
              isChapterValidated: page.isProofBundleValidated,
            });
          }
        } else {
          chapters.push({
            proofBundles: [page.proofBundle],
            file: undefined,
            trainees: [page.worker],
            isCommon: false,
            skills: [page.skill],
            acquired: page.proofBundle?.acquired,
            issueDate: page.proofBundle?.startingDate,
            comment: page.proofBundle?.description,
            isChapterCompleted: page.isProofBundleCompleted,
            isChapterSubmitted: page.isProofBundleSubmitted,
            isChapterValidated: page.isProofBundleValidated,
          });
        }
      } else {
        if (page.type === PageType.SkillGroup) {
          const skillGroupSubSkills: Page[] = pages.filter(_page =>
            page.skill.skillIds?.includes(_page.skill.id),
          );
          const _proofBundles = _.compact(
            skillGroupSubSkills.map(eachPage => eachPage.proofBundle),
          );

          chapters.push({
            proofBundles: _.compact(_proofBundles),
            issueDate: getProofBundleIssueDate(_proofBundles),
            file: page.commonFiles ?? undefined,
            trainees: [page.worker],
            isCommon: true,
            skills: [page.skill],
            isChapterCompleted: skillGroupSubSkills.every(page => page.isProofBundleCompleted),
            isChapterSubmitted: skillGroupSubSkills.every(page => page.isProofBundleSubmitted),
            isChapterValidated: skillGroupSubSkills.every(page => page.isProofBundleValidated),
          });
        } else {
          chapters.push({
            proofBundles: undefined,
            file: undefined,
            trainees: [page.worker],
            isCommon: false,
            skills: [page.skill],
            isChapterCompleted: page.isProofBundleCompleted,
            isChapterSubmitted: page.isProofBundleSubmitted,
            isChapterValidated: page.isProofBundleValidated,
          });
        }
      }
    });
  });

  return chapters;
}

/**
 * Create proof book according to the skillIds and workerIds we passed
 * Normaly it will return DRAFT and TO_REVIEW Proof bundles
 * If VALIDATED or REJECTED or REJECTED_TO_RESUBMIT needed pass values for proofValidatedWorkers and proofValidatedSkills
 * @param skillIds
 * @param workerIds
 * @param proofValidatedWorkers Pass workerIds if we need VALIDATED or REJECTED or REJECTED_TO_RESUBMIT proof bundles
 * otherwise it will return DRAFT and TO_REVIEW proof bundles
 * @param proofValidatedSkills Pass skillIds if we need VALIDATED or REJECTED or REJECTED_TO_RESUBMIT proof bundles
 * * otherwise it will return DRAFT and TO_REVIEW proof bundles
 * @returns
 */
export async function getWorkerSkillProofBook(
  skillIds: string[],
  workerIds: string[],
  proofValidatedWorkers: string[],
  proofValidatedSkills: string[], 
): Promise<API.Result<ProofBook>> {
  const skillSet = new Set(skillIds);

  const proofBundles: API.ProofBundle[] = [];
  const failures: API.Failure[] = [];
  await Aigle.map(workerIds, async workerId => {
    const workerProofBundles = await API.getWorkerProofBundles(workerId);
    if (API.isFailure(workerProofBundles)) {
      failures.push(workerProofBundles);
      return workerProofBundles;
    }

    workerProofBundles.forEach(proofBundle => {
      if (
        skillSet.has(proofBundle.skillId) &&
        (proofBundle.review.state === API.ReviewState.DRAFT ||
          proofBundle.review.state === API.ReviewState.TO_REVIEW)
      ) {
        proofBundles.push(proofBundle);
      }

      const gotProofValidatedWorker = proofValidatedWorkers.find(
        validatedWorker => validatedWorker === workerId,
      );
      const gotProofValidatedSkill = proofValidatedSkills.find(
        validatedSkill => validatedSkill === proofBundle.skillId,
      );

      if (
        gotProofValidatedWorker &&
        gotProofValidatedSkill &&
        (proofBundle.review.state === API.ReviewState.VALIDATED ||
          proofBundle.review.state === API.ReviewState.REJECTED ||
          proofBundle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT)
      ) {
        proofBundles.push(proofBundle);
      }
    });
  });
  if (failures.length) return API.createFailure_Multiple(failures);

  return computeProofBook(workerIds, skillIds, proofBundles, false);
}

export async function computeProofBook(
  workerIds: readonly string[],
  skillIds: readonly string[],
  proofBundles: API.ProofBundle[],
  isPracticalTraining: boolean,
): Promise<API.Result<ProofBook>> {
  let _isProofBookCompleted: boolean = true;
  let _isProofBookSubmitted: boolean = true;
  let _isProofBookValidated: boolean = true;

  async function _getSkills(
    skillIds: readonly string[],
  ): Promise<API.Result<SkillsAndSkillGroups[]>> {
    const skills = await API.mapSeries(skillIds, async skillId => {
      const skill = await API.getSkill(skillId);
      if (API.isFailure(skill)) return skill;

      if (API.isSkillGroup(skill)) {
        const _subSkills = await _getSkills(skill.skillIds);
        if (API.isFailure(_subSkills)) return _subSkills;

        
        return {
          ...skill,
          subSkills: _subSkills,
          type: PageType.SkillGroup,
        };
      }

      return {
        ...skill,
        type: PageType.Skill,
      };
    });

    return skills;
  }

  const skills = await _getSkills(skillIds);
  if (API.isFailure(skills)) return skills;

  const proofBundleMap = API.extractLatestProofBundleAndCommonFiles(proofBundles);
  if (API.isFailure(proofBundleMap)) return proofBundleMap;

  const workersPages = new Map<string, Page[]>();
  const failures: API.Failure[] = [];
  await Aigle.map(workerIds, async workerId => {
    const pages: Page[] = [];

    const worker = await API.getWorker(workerId);
    if (API.isFailure(worker)) {
      failures.push(worker);
      return;
    }

    for (const skill of skills) {
      const subSkillPages: Page[] = [];

      let __isProofBookCompleted = true;
      let __isProofBookSubmitted = true;
      let __isProofBookValidated = true;
      if (API.isSkillGroup(skill)) {
        if (
          _.some(skill.skillIds, skillId => {
            if (
              !proofBundleMap.get(workerId)?.get(skillId)?.proofBundle.files.length ||
              proofBundleMap.get(workerId)?.get(skillId)?.proofBundle.acquired === null
            )
              return true;
          })
        ) {
          _isProofBookCompleted = false;
          __isProofBookCompleted = false;
        }

        if (
          _.some(skill.skillIds, skillId => {
            if (
              proofBundleMap.get(workerId)?.get(skillId)?.proofBundle.review.state ===
              API.ReviewState.DRAFT
            )
              return true;
          })
        ) {
          _isProofBookSubmitted = false;
          __isProofBookSubmitted = false;
        }

        if (
          _.some(skill.skillIds, skillId => {
            if (
              proofBundleMap.get(workerId)?.get(skillId)?.proofBundle.review.state ===
              API.ReviewState.TO_REVIEW
            )
              return true;
          })
        ) {
          _isProofBookValidated = false;
          __isProofBookValidated = false;
        }

        const _subSkillPages = await computeProofBook(
          [workerId],
          skill.skillIds,
          proofBundles,
          isPracticalTraining,
        );
        if (API.isFailure(_subSkillPages)) return _subSkillPages;

        const _pages = _subSkillPages.workersPages.get(workerId);
        if (_pages) subSkillPages.push(..._pages);
      } else {
        if (
          !proofBundleMap.get(workerId)?.get(skill.id)?.proofBundle.files.length ||
          proofBundleMap.get(workerId)?.get(skill.id)?.proofBundle.acquired === null
        ) {
          _isProofBookCompleted = false;
          __isProofBookCompleted = false;
        }

        if (
          proofBundleMap.get(workerId)?.get(skill.id)?.proofBundle.review.state ===
          API.ReviewState.DRAFT
        ) {
          _isProofBookSubmitted = false;
          __isProofBookSubmitted = false;
        }

        if (
          proofBundleMap.get(workerId)?.get(skill.id)?.proofBundle.review.state ===
          API.ReviewState.TO_REVIEW
        ) {
          _isProofBookValidated = false;
          __isProofBookValidated = false;
        }
      }

      const _commonFiles =
        proofBundleMap.get(workerId)?.get(skill.id)?.commonFiles ?? subSkillPages[0]?.commonFiles;

      pages.push({
        proofBundle: proofBundleMap.get(workerId)?.get(skill.id)?.proofBundle,
        skill,
        worker,
        type: skill.type,
        commonFiles: _commonFiles,
        isProofBundleCompleted: __isProofBookCompleted,
        isProofBundleSubmitted: __isProofBookCompleted && __isProofBookSubmitted,
        isProofBundleValidated:
          __isProofBookCompleted && __isProofBookSubmitted && __isProofBookValidated,
      });

      pages.push(...subSkillPages);
    }
    workersPages.set(workerId, pages);
  });
  if (failures.length) return API.createFailure_Multiple(failures);

  return {
    workersPages,
    isPracticalTraining,
    numberOfWorkers: workerIds.length,
    numberOfSkills: skillIds.length,
    isProofBookCompleted: _isProofBookCompleted,
    isProofBookSubmitted: _isProofBookCompleted && _isProofBookSubmitted,
    isProofBookValidated: _isProofBookCompleted && _isProofBookSubmitted && _isProofBookValidated,
  };
}

/**
 * To get the details for proofBook
 * @param trainingSession
 * @param traineeIds (Optional) if set only show the book for these specific trainee
 */
export async function getTrainingSessionProofBook(
  trainingSession: API.TrainingSession,
  traineeIds?: string[],
): Promise<API.Result<ProofBook>> {
  const proofBundles = await API.getProofBundles(trainingSession.id);
  if (API.isFailure(proofBundles)) return proofBundles;

  const trainingVersion = await API.getTrainingVersion(trainingSession.trainingVersionId);
  if (API.isFailure(trainingVersion)) return trainingVersion;

  const isPractical = await API.isPracticalTrainingSession(trainingSession);
  if (API.isFailure(isPractical)) return isPractical;

  return computeProofBook(
    traineeIds ?? trainingSession.traineeIds,
    trainingVersion.skillIds,
    proofBundles,
    isPractical,
  );
}

/**
 * To get the details for proofBook, same for web and mobile
 * @param trainingVersion
 * @param traineeIds (Optional) if set only show the book for these specific trainee
 */
export async function getTrainingVersionProofBook(
  trainingVersion: API.TrainingVersion,
  traineeIds?: string[],
): Promise<API.Result<ProofBook>> {
  const proofBundles = await API.getProofBundles(
    
    trainingVersion.id,
  );
  if (API.isFailure(proofBundles)) return proofBundles;

  return computeProofBook(
    traineeIds ?? [], 
    trainingVersion.skillIds,
    proofBundles,
    false,
  );
}

/**
 * Function to convert a URI to a Blob object
 * @param {string} uri - The URI of the file
 * @returns {Promise} - Returns a promise that resolves with the Blob object
 */
export function uriToBlob(uri: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    
    xhr.onload = function () {
      resolve(xhr.response);
    };

    
    xhr.onerror = function () {
      reject(new Error('uriToBlob failed'));
    };

    
    
    xhr.responseType = 'blob';

    
    
    xhr.open('GET', uri, true);

    
    xhr.send(null);
  });
}

export async function uploadFilesToS3Bucket(
  proofBundles: API.ProofFile[],
): Promise<API.S3Object[] | undefined> {
  const data: API.S3Object[] = [];

  await Aigle.map(proofBundles, async proofBundle => {
    let uri = proofBundle.uri;
    if (!uri) return;

    if (!uri.includes(UriPrefix) && proofBundle.type !== 'Document') uri = UriPrefix + uri; 
    await fetch(uri)
      .then(async response => {
        const blob = await response.blob();
        const s3Object = await API.uploadFile(
          blob,
          API.StorageVisibility.protected,
          proofBundle.name,
        );
        if (API.isFailure(s3Object)) {
          return s3Object;
        }
        data.push(s3Object);
      })
      .catch(err => {
        logger.warn(err);
        return err;
      });
  });

  return data;
}

export async function handleAutoCertificate(
  chapter: Chapter,
  base64Signature?: string,
  proofBookType?: ProofBookType,
): Promise<API.Result<API.S3Object | undefined>> {
  const worker = await API.getWorker();
  if (API.isFailure(worker)) {
    logger.warn('Error while fetching worker', worker);
    return worker;
  }

  const signature = "'data:image/png;base64," + base64Signature + "'";

  const autoCertificate = await API.createCertificateProof(
    chapter.trainees.map(trainee => trainee.name),
    chapter.skills.map(skill => skill.name),
    worker.name,
    proofBookType ? true : false,
    false,
    undefined,
    base64Signature ? signature : undefined,
  );

  if (API.isFailure(autoCertificate)) {
    logger.warn('Error while creating the autocertificate in proof book', autoCertificate);
    return autoCertificate;
  }

  let fileOrBlob: undefined | File | Blob = undefined;
  if (Platform.OS === 'web') {
    fileOrBlob = convertDataURLtoFile(autoCertificate.fileContentBase64, autoCertificate.name);
  } else {
    let uri = autoCertificate.uri;

    if (!uri) {
      return API.createFailure_Unspecified('Error while creating certificate proof');
    }
    if (!uri.includes(UriPrefix) && autoCertificate.type !== 'Document') {
      uri = UriPrefix + uri; 
    }

    await fetch(uri).then(async response => {
      fileOrBlob = await response.blob();
    });
  }

  if (fileOrBlob) {
    const s3Object = await API.uploadFile(
      fileOrBlob,
      API.StorageVisibility.protected,
      autoCertificate.name,
    );
    if (API.isFailure(s3Object)) {
      logger.warn('Failed to upload file', s3Object);
      return s3Object;
    }

    return s3Object;
  }
}

/**
 * Update or create the proof bundles with the given files inside the provided Chapter
 * @param chapter
 * @param filesUploaded
 * @param trainingVersionOrTrainingSessionId
 */
export async function updateOrCreateProofBookProofBundle(
  chapter: Chapter,
  filesUploaded: API.S3Object[],
  trainingVersionOrTrainingSessionId?: string, 
): Promise<API.Result<API.ProofBundle[]>> {
  const errors: API.Failure[] = [];
  const proofBundles: API.ProofBundle[] = [];

  const _chapterSkillGroups: API.Skill[] = [];
  const _chapterSkills: API.Skill[] = [];
  chapter.skills.forEach(_skill => {
    API.isSkillGroup(_skill) ? _chapterSkillGroups.push(_skill) : _chapterSkills.push(_skill);
  });

  for (const _skillGroup of _chapterSkillGroups) {
    if (API.isSkillGroup(_skillGroup)) {
      const skills = await API.getSubSkillsFromSkillGroup(_skillGroup);
      if (API.isFailure(skills)) return skills;
      _chapterSkills.push(...skills);
    }
  }

  await Aigle.mapSeries(_chapterSkills, async skill => {
    await Aigle.mapSeries(chapter.trainees, async trainee => {
      const proofBundle = chapter.proofBundles?.find(
        proofBundle => proofBundle.skillId === skill.id && proofBundle.workerId === trainee.id,
      );

      
      if (
        proofBundle &&
        (proofBundle.review.state === API.ReviewState.DRAFT ||
          proofBundle.review.state === API.ReviewState.TO_REVIEW)
      ) {
        const _files = [...proofBundle.files, ...filesUploaded];
        let data: API.ProofBundlePartialUpdateInput = {
          ...proofBundle,
          files: _files,
          startingDate:
            chapter.issueDate === undefined
              ? proofBundle.startingDate
              : chapter.issueDate ?? new Date().toISOString(),
          acquired: chapter.acquired === undefined ? proofBundle.acquired : chapter.acquired,
          description: chapter.comment === undefined ? proofBundle.description : chapter.comment,
        };

        const updateProofBundle = await API.updateProofBundle(data);
        if (API.isFailure(updateProofBundle)) {
          logger.warn('Failed to update proof bundle', updateProofBundle);
          errors.push(updateProofBundle);
        } else {
          proofBundles.push(updateProofBundle);
        }
      } else if (trainingVersionOrTrainingSessionId) {
        const dataType = API.getDataType(trainingVersionOrTrainingSessionId);
        if (API.isFailure(dataType)) {
          logger.error(dataType);
        }

        if (dataType === API.DataType.TRAININGVERSION) {
          const trainingVersionProofBundle = await API.createTrainingProofBundle(
            {
              startingDate: chapter.issueDate || new Date().toISOString(),
              files: filesUploaded,
              review: { state: API.ReviewState.DRAFT },
              acquired: chapter.acquired,
              description: chapter.comment,
            },
            trainingVersionOrTrainingSessionId,
            trainee.id,
            skill.id,
          );

          if (API.isFailure(trainingVersionProofBundle)) {
            logger.warn('Failed to update proof bundle file', trainingVersionProofBundle);
            errors.push(trainingVersionProofBundle);
          } else {
            proofBundles.push(...trainingVersionProofBundle);
          }
        } else if (dataType === API.DataType.TRAININGSESSION) {
          const trainingSessionProofBundle = await API.createTrainingProofBundle(
            {
              startingDate: chapter.issueDate || new Date().toISOString(),
              files: filesUploaded,
              review: { state: API.ReviewState.DRAFT },
              acquired: chapter.acquired,
              description: chapter.comment,
            },
            trainingVersionOrTrainingSessionId,
            trainee.id,
            skill.id,
            true,
          );

          if (API.isFailure(trainingSessionProofBundle)) {
            errors.push(trainingSessionProofBundle);
          } else {
            proofBundles.push(...trainingSessionProofBundle);
          }
        }
      } else {
        const workerSkillProofBundle = await API.createWorkerSkillProofBundle(
          {
            startingDate: chapter.issueDate || new Date().toISOString(),
            files: filesUploaded,
            review: { state: API.ReviewState.DRAFT },
            acquired: chapter.acquired,
            description: chapter.comment,
          },
          trainee.id,
          skill.id,
        );

        if (API.isFailure(workerSkillProofBundle)) {
          errors.push(workerSkillProofBundle);
        } else {
          proofBundles.push(workerSkillProofBundle);
        }
      }
    });
  });

  if (errors.length) return API.createFailure_Unspecified(errors);

  return proofBundles;
}

/**
 * Submit the proofs to review
 * @param trainingSessionId
 */
export async function submitProofBookToReview(
  chapter: Chapter,
): Promise<API.Result<API.ProofBundle[]>> {
  
  const proofBundles: API.ProofBundle[] = [];

  const _worker = await API.getWorker();
  if (API.isFailure(_worker)) {
    logger.warn(_worker);
    return _worker;
  }

  if (chapter.proofBundles) {
    await Aigle.mapSeries(chapter.proofBundles, async proofBundle => {
      if (proofBundle && proofBundle.review.state === API.ReviewState.DRAFT) {
        const updateProofBundle = await API.updateProofBundle({
          id: proofBundle.id,
          review: {
            state: API.ReviewState.TO_REVIEW,
            date: new Date().toISOString(),
            workerId: _worker.id,
          },
        });
        if (API.isFailure(updateProofBundle)) {
          logger.warn('Failed to upload file', updateProofBundle);
          return updateProofBundle;
        }

        proofBundles.push(updateProofBundle);
      }
    });
  }

  return proofBundles;
}

/**
 * Function to submit the proof bundles for training sessions according to the value isAcquired
 * @param trainingSessionId
 * @returns
 */
export async function validateProofBook(
  proofBundles: API.NoMetadata<API.ProofBundle>[],
  resubmit?: boolean,
  reviewer?: API.Worker,
): Promise<API.Result<API.ProofBundle | null>> {
  await Aigle.mapSeries(proofBundles, async proofBundle => {
    if (proofBundle.review.state === API.ReviewState.TO_REVIEW) {
      const reviewProofBundle = await API.reviewProofBundle(
        proofBundle.id,
        resubmit && !proofBundle.acquired
          ? API.ReviewState.REJECTED_TO_RESUBMIT
          : API.ReviewState.VALIDATED,
        proofBundle.startingDate ? new Date(proofBundle.startingDate) : new Date(),
        proofBundle.description,
        proofBundle.acquired,
        reviewer,
      );
      if (API.isFailure(reviewProofBundle)) {
        logger.warn('Failed to upload file', reviewProofBundle);
        return reviewProofBundle;
      }
    }
  });

  return null;
}

export async function getProofStateText(proofBundle: API.ProofBundle): Promise<API.Result<string>> {
  const reviewer = await getWorkerReviewer(proofBundle);
  return proofBundle.review.state === API.ReviewState.TO_REVIEW
    ? t('alex:workerSkillReviewModal.toBeValidated')
    : t('alex:workerSkillReviewModal.reviewStateDescription', {
        reviewState:
          proofBundle.review.state === API.ReviewState.VALIDATED
            ? t('alex:workerSkillReviewModal.validated')
            : t('alex:workerSkillReviewModal.disproved'),
        date: proofBundle.review.date
          ? moment(proofBundle.review.date).format('L')
          : t('alex:workerSkillReviewModal.dateNotPrecised', undefined, false),
        reviewer:
          reviewer?.name ?? t('alex:workerSkillReviewModal.reviewerNotPrecised', undefined, false),
      });
}

export async function getWorkerReviewer(proofBundle: API.ProofBundle): Promise<API.Worker | null> {
  if (!proofBundle.review.workerId) return null;

  const reviewer =
    proofBundle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
      ? await API.getWorkerFromUserId(proofBundle.updatedBy)
      : await API.getWorker(proofBundle.review.workerId);

  if (API.isFailure(reviewer)) {
    logger.warn('Failed to fetch validator worker', reviewer);
    return null;
  }
  return reviewer;
}

export async function deleteProofBundlesInDraftState(
  workersPages: Map<string, Page[]>, 
): Promise<API.Result<API.NoMetadata<API.ProofBundle>[]>> {
  const deletedProofBundles: API.NoMetadata<API.ProofBundle>[] = [];
  const failures: API.Failure[] = [];
  await Aigle.map(Array.from(workersPages.values()), async pages => {
    await Aigle.map(pages, async page => {
      if (page.proofBundle?.review.state === API.ReviewState.DRAFT) {
        const deleteProofBundle = await API.deleteFactoryBusinessObject(page.proofBundle.id);
        if (API.isFailure(deleteProofBundle)) failures.push(deleteProofBundle);
        else deletedProofBundles.push(page.proofBundle);
      }
    });
  });
  if (failures.length) return API.createFailure_Multiple(failures);

  return deletedProofBundles;
}

/**
 * This function return true if it contains invisible worker or ghost worker
 * @param chapter
 * @returns
 */
export function isChapterContainsGhostWorker(chapter: Chapter) {
  const hasGhostWorkers = chapter.trainees.some(trainee => API.isGhostWorker(trainee.name));

  return hasGhostWorkers;
}
