import React, { useState, useEffect, useContext } from 'react';
import { View, InteractionManager, ScrollView } from 'react-native';
import * as _ from 'lodash-es';
import { t } from 'shared/localisation/i18n';
import logger from 'shared/util/Logger';
import * as API from 'shared/backend-data';
import { WorkstationWorkerLevel } from 'backend/src/api';
import { LevelSkillsPaneContent, RequirementWithDetails, UnauthorizedUnit } from './index';
import { useIsMounted } from 'shared/hooks/IsMounted';
import Styles from './style';
import { TreeNode } from 'shared/backend-data/factoryCache/Tree';
import { RequirementTrainingColumn } from './RequirementTrainingColumn';
import { ModifyTrainingModal } from '../../../training/training-modal/container/TrainingModal';
import { TrainingModalConfig } from '../../../training/training-modal/container/TrainingModal';
import { RequirementSkillColumn } from './RequirementSkillColumn';
import { ModalUtils } from 'shared/ui-component/Modal';
import { ModalContext } from 'shared/ui-component/Modal/Modal';
import { MyHub } from 'shared/util/MyHub';
import { GlobalLocationState } from 'skillmgtweb/src/components/navigation/Routes';
import { useHistory } from 'react-router';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { ModifySkillModal } from '../../skills/components/modify-skill-modal/component';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';

interface RequirementTableProps {
  treeNode: TreeNode | undefined;
}

export function handleWarningModal(
  modal: ModalContext,
  warningMessage: string,
  warningSubMessage?: string,
  warningAcceptText?: string,
  warningCancelText?: string,
  handleWarningAccept?: () => void,
) {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: warningMessage,
      warningSubMessage: warningSubMessage,
      warningAcceptButton: warningAcceptText,
      warningCancelButton: warningCancelText,
      warningAcceptCallback: handleWarningAccept,
    }),
  );
}

export const RequirementTable: React.FC<RequirementTableProps> = props => {
  const { treeNode } = props;

  const isMounted = useIsMounted();

  const history = useHistory<GlobalLocationState>();

  const modal = ModalUtils.useModal();
  const { isValidPermission } = useContext(PermissionManagementContext);

  const [level1Loading, setLevel1Loading] = useState(false);
  const [level2Loading, setLevel2Loading] = useState(false);
  const [requirementTableCount, setRequirementTableCount] = useState(Number);
  const [trainingRequirementTableCount, setTrainingRequirementTableCount] = useState(Number);

  const [level3Loading, setLevel3Loading] = useState(false);
  const [level4Loading, setLevel4Loading] = useState(false);
  const [sourceRequirement, setSourceRequirement] = useState<API.Requirement | undefined | null>();
  const [sourceRequirementLevel, setSourceRequirementLevel] =
    useState<API.WorkstationWorkerLevels>();
  const [addedSkills, setAddedSkills] = useState<string[]>([]);
  const [levelSkillsPaneContents, setLevelSkillsPaneContents] = useState<Map<
    API.WorkstationWorkerLevels,
    LevelSkillsPaneContent
  > | null>(new Map());
  const [isRequiredSkillsSelected, setIsRequiredSkillsSelected] = useState<boolean>(true);
  const [isShowWorkstationTrainingModal, setIsShowWorkstationTrainingModal] =
    useState<boolean>(false);
  const [showSkillModal, setShowSkillModal] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string | undefined>();
  const [workstationTrainingModalConfig, setWorkstationTrainingModalConfig] =
    useState<TrainingModalConfig>({
      editMode: false,
      trainingVersion: null,
      skillIds: [],
    });
  const [skillsWithoutMapedTrainingsExist, setSkillsWithoutMapedTrainingsExist] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState<API.Skill>();
  const [isModifySkillModalReadOnly, setIsModifySkillModalReadOnly] = useState(false);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.REQUIREMENT) {
        loadLevelSkills(treeNode);
      }
    });

    levelSkillsPaneContents?.clear();
    setLevelSkillsPaneContents(
      levelSkillsPaneContents ? new Map(levelSkillsPaneContents) : new Map(),
    );
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      if (treeNode?.object) {
        await loadLevelSkills(treeNode);
      }
    });

    return () => {
      removeListener();
    };
  }, [treeNode]);

  
  useEffect(() => {
    if (!levelSkillsPaneContents) return;
    const tempAddedSkills: string[] = [];
    _.forEach(Array.from(levelSkillsPaneContents.values()), levelSkillPanelContent => {
      _.forEach(levelSkillPanelContent.levelSkills.skills, skill => {
        tempAddedSkills.push(skill.id);
      });
      _.forEach(
        _.map(levelSkillPanelContent.inheritedLevelSkills, inheritedLevelSkill => {
          return inheritedLevelSkill.skills;
        }),
        skill => {
          _.forEach(skill, skillItem => {
            tempAddedSkills.push(skillItem.id);
          });
        },
      );
    });
    setAddedSkills(tempAddedSkills);
  }, [levelSkillsPaneContents]);

  useEffect(() => {
    if (history.location.state) {
      const workstationsState = history.location.state;
      if (workstationsState.subTabIndex === 2) {
        setIsRequiredSkillsSelected(false);
      }
    }
  }, [history.location.state]);

  function handleWorkstationTrainingModal(
    isShow: boolean,
    editMode: boolean,
    trainingVersion: API.TrainingVersion | null | undefined,
    skillIds: string[],
  ): void {
    setIsShowWorkstationTrainingModal(isShow);
    setWorkstationTrainingModalConfig({
      editMode: editMode,
      trainingVersion: trainingVersion,
      skillIds: skillIds,
    });
  }

  function onDeleteSkill(skill: API.Skill, requirement: API.Requirement) {
    handleWarningModal(
      modal,
      t('alex:workstations.workstationPanel.requirementTable.removeSkill'),
      undefined,
      t('common:button.yes'),
      t('common:button.no'),
      () => deleteSkill(skill, requirement),
    );
  }

  async function deleteSkill(skill: API.Skill, requirement: API.Requirement) {
    showLoaderForLevel(requirement.level, true);

    const tempSkillTrainingVersions = [...requirement.skillTrainingVersions];
    tempSkillTrainingVersions.splice(
      _.indexOf(
        [...requirement.skillTrainingVersions],
        _.find([...requirement.skillTrainingVersions], skillTrainingVersion => {
          return skillTrainingVersion.skillId === skill.id;
        }),
      ),
      1,
    );
    const requirementWithRemovedSkill: API.RequirementPartialUpdateInput = {
      id: requirement.id,
      skillTrainingVersions: tempSkillTrainingVersions,
    };

    const updatedRequirement = await API.updateRequirement(requirementWithRemovedSkill);
    if (!isMounted.current) return;
    if (API.isFailure(updatedRequirement)) {
      logger.warn('Failed to update requirement ', updatedRequirement);
      if (API.isFailureType(updatedRequirement, 'DeletionVeto'))
        ModalUtils.showVetoModal(
          t('alex:workstations.workstationPanel.requirementTable.removeASkillVetoWarning'),
          updatedRequirement,
        );
      else handleErrorWarningModal();
      showLoaderForLevel(requirement.level, false);
      return;
    }

    await loadLevelSkills(treeNode);
    if (!isMounted.current) return;

    showLoaderForLevel(requirement.level, false);
  }

  async function loadLevelSkills(selectedNode: TreeNode | undefined): Promise<void> {
    levelSkillsPaneContents?.clear();
    setLevelSkillsPaneContents(
      levelSkillsPaneContents ? new Map(levelSkillsPaneContents) : new Map(),
    );
    setLevel1Loading(true);
    setLevel2Loading(true);
    setLevel3Loading(true);
    setLevel4Loading(true);

    if (!selectedNode || !selectedNode.object) {
      return;
    }

    const levelsRequirementWithInherited =
      await API.getLevelsRequirementsWithInheritedAndOrDescendent(
        selectedNode.object.id,
        true,
        false,
      );
    if (!isMounted.current) return;
    if (API.isFailure(levelsRequirementWithInherited)) {
      logger.warn(levelsRequirementWithInherited);
      handleErrorWarningModal();
      return;
    }

    const _levelSkillsPaneContentMap: Map<API.WorkstationWorkerLevels, LevelSkillsPaneContent> =
      new Map();
    for (
      let level = API.WorkstationWorkerLevels.LEVEL1;
      level <= API.WorkstationWorkerLevels.LEVEL4;
      level++
    ) {
      if (selectedNode === treeNode) {
        const paneContent = await getLevelSkillsPaneContent(
          levelsRequirementWithInherited,
          level,
          selectedNode,
        );
        if (!isMounted.current) return;
        if (paneContent) _levelSkillsPaneContentMap.set(level, paneContent);
      }
    }

    setRequirementTableCount(
      _.sum(
        Array.from(_levelSkillsPaneContentMap.values()).map(value => {
          let inheritedSkillsCount: number = 0;
          _.forEach(value.inheritedLevelSkills, inheritedRequirement => {
            inheritedSkillsCount = inheritedSkillsCount + inheritedRequirement.skills.length;
          });
          return value.levelSkills.skills.length + inheritedSkillsCount;
        }),
      ),
    );

    setTrainingRequirementTableCount(
      _.sum(
        Array.from(_levelSkillsPaneContentMap.values()).map(value => {
          const skillsLength = value.levelSkills.sourceRequirement?.skillTrainingVersions?.length;
          let inheritedTrainingsCount: number = 0;
          const inheritedTrainings: string[] = [];
          _.forEach(value.inheritedLevelSkills, inheritedRequirement => {
            if (inheritedRequirement.sourceRequirement?.skillTrainingVersions.length) {
              inheritedRequirement.sourceRequirement?.skillTrainingVersions.forEach(
                skillTrainingVersion => {
                  if (skillTrainingVersion.trainingVersionId)
                    inheritedTrainings.push(skillTrainingVersion.trainingVersionId);
                },
              );
            }
          });
          inheritedTrainingsCount = inheritedTrainings.filter(
            (v, i, a) => a.indexOf(v) === i,
          ).length;

          if (value.levelSkills.skills.length === 0) {
            return 0 + inheritedTrainingsCount;
          } else {
            let _trainingVersionIdString: String[] = [''];
            for (var _i = 0; _i < (skillsLength || 0); _i++) {
              let _trainingVersionId =
                value.levelSkills.sourceRequirement?.skillTrainingVersions[_i].trainingVersionId;
              _trainingVersionIdString[_i] = _trainingVersionId || '';
            }

            return (
              _trainingVersionIdString.filter((v, i, a) => a.indexOf(v) === i).length +
              inheritedTrainingsCount
            );
          }
        }),
      ),
    );

    setSkillsWithoutMapedTrainingsExist(false);
    Array.from(_levelSkillsPaneContentMap.values()).map(_value => {
      const skills = _.compact(_value.levelSkills.sourceRequirement?.skillTrainingVersions);
      const inheritedSkills = _.compact(
        _value.inheritedLevelSkills.flatMap(
          inheritedLevelSkill => inheritedLevelSkill.sourceRequirement?.skillTrainingVersions,
        ),
      );

      if (inheritedSkills.some(inheritedSkill => !inheritedSkill.trainingVersionId)) {
        setSkillsWithoutMapedTrainingsExist(true);
        return;
      } else if (skills.some(skill => !skill.trainingVersionId)) {
        setSkillsWithoutMapedTrainingsExist(true);
        return;
      }
    });

    if (treeNode && selectedNode.id === treeNode.id)
      setLevelSkillsPaneContents(new Map(_levelSkillsPaneContentMap));

    setLevel1Loading(false);
    setLevel2Loading(false);
    setLevel3Loading(false);
    setLevel4Loading(false);
  }

  async function getLevelSkillsPaneContent(
    levelsRequirementWithInherited: Map<API.WorkstationWorkerLevels, API.Requirement[]>,
    level: API.WorkstationWorkerLevels,
    selectedNode: TreeNode,
  ): Promise<LevelSkillsPaneContent | undefined> {
    const levelRequirementgWithInherited = levelsRequirementWithInherited.get(level);

    
    let levelSkills: RequirementWithDetails = {
      sourceRequirement: null,
      linkedObject: selectedNode.object!,
      level: level,
      skills: [],
    };
    const inheritedRequirements: RequirementWithDetails[] = [];
    if (levelRequirementgWithInherited && levelRequirementgWithInherited.length) {
      let inheritedRequirementsIndexStart;
      const lastRequirement =
        levelRequirementgWithInherited[levelRequirementgWithInherited.length - 1];
      if (lastRequirement.linkedObjectId === selectedNode.object.id!) {
        inheritedRequirementsIndexStart = levelRequirementgWithInherited.length - 2;
        const _levelSkills = await getLevelSkills(lastRequirement);
        if (!isMounted.current) return;
        if (API.isFailure(_levelSkills)) {
          logger.warn(
            'Error fetching LevelSkills, default value will be used instead',
            _levelSkills,
          );
        } else {
          levelSkills = _levelSkills;
        }
      } else inheritedRequirementsIndexStart = levelRequirementgWithInherited.length - 1;

      
      for (let i = inheritedRequirementsIndexStart; i >= 0; i--) {
        const requirement = levelRequirementgWithInherited[i];
        const _levelSkills = await getLevelSkills(requirement);
        if (!isMounted.current) return;
        if (API.isFailure(_levelSkills)) {
          logger.warn(
            'Error fetching LevelSkills, default value will be used instead',
            _levelSkills,
          );
        } else {
          const _skills = [..._levelSkills.skills];
          let _skillTrainingVersions: API.SkillTrainingVersion[] = [];
          if (_levelSkills.sourceRequirement) {
            _skillTrainingVersions = [..._levelSkills.sourceRequirement.skillTrainingVersions];
          }

          levelSkills.skills.forEach(eachSkill => {
            if (_levelSkills.skills.some(_eachSkill => _eachSkill.id === eachSkill.id)) {
              const indexOfSkill = _skills.findIndex(_eachSkill => _eachSkill.id === eachSkill.id);
              if (indexOfSkill > -1) {
                _skills.splice(indexOfSkill, 1);
              }
              const indexOfWorkerSkill = _skillTrainingVersions.findIndex(
                skillTrainingVersion => skillTrainingVersion.skillId === eachSkill.id,
              );
              if (indexOfWorkerSkill > -1) {
                _skillTrainingVersions.splice(indexOfWorkerSkill, 1);
              }
            }
          });

          inheritedRequirements.push({
            ..._levelSkills,
            skills: _skills,
            sourceRequirement: {
              ..._levelSkills.sourceRequirement!,
              skillTrainingVersions: _skillTrainingVersions,
            },
          });
        }
      }
    }

    return {
      title: API.getWorkstationWorkerLevelLabel(level),
      description: '',
      level,
      levelSkills,
      inheritedLevelSkills: inheritedRequirements,
    };
  }

  async function getLevelSkills(
    requirement: API.Requirement,
  ): Promise<API.Result<RequirementWithDetails>> {
    let linkedObject: API.TreeObject | UnauthorizedUnit;

    const _linkedObject = await API.getWorkstationOrOrganizationalUnit(requirement.linkedObjectId);
    if (API.isFailure(_linkedObject)) {
      linkedObject = {
        type: 'UnauthorizedUnitType',
      };
    } else {
      linkedObject = _linkedObject;
    }

    const skillIds = API.extractSkillIds(requirement);
    const errors: API.Failure[] = [];
    const skills = _.compact(
      await Promise.all(
        skillIds.map(async skillId => {
          const skill = await API.getSkill(skillId);
          if (API.isFailure(skill)) {
            errors.push(skill);
          } else {
            return skill;
          }
        }),
      ),
    );
    if (errors.length) return API.createFailure_Multiple(errors);

    return {
      sourceRequirement: requirement,
      linkedObject,
      level: API.api2workstationWorkerLevels(requirement.level),
      skills,
    };
  }

  async function refreshLevelSkills(): Promise<void> {
    await loadLevelSkills(treeNode);
  }

  function showLoaderForLevel(level: WorkstationWorkerLevel, show: boolean) {
    switch (level) {
      case WorkstationWorkerLevel.LEVEL1:
        setLevel1Loading(show);
        break;
      case WorkstationWorkerLevel.LEVEL2:
        setLevel2Loading(show);
        break;
      case WorkstationWorkerLevel.LEVEL3:
        setLevel3Loading(show);
        break;
      case WorkstationWorkerLevel.LEVEL4:
        setLevel4Loading(show);
        break;
    }
  }

  async function addSkillAndUpdateRequirment(skills: API.Skill[], level: WorkstationWorkerLevel) {
    if (!treeNode?.object?.id || !sourceRequirementLevel) return;
    if (!skills.length) return;

    const allTrainingVersions = await API.getTrainingVersions(undefined, undefined, true);
    if (API.isFailure(allTrainingVersions)) {
      logger.warn('Failed to fetch training versions');
      return;
    }

    showLoaderForLevel(level, true);
    let showPracticalSkillWarningModal = false;
    if (sourceRequirement) {
      const skillTrainingVersions: API.SkillTrainingVersionInput[] = [];
      _.forEach(skills, skill => {
        if (skill.isPractical) {
          if (API.isOrganizationalUnit(treeNode.object)) showPracticalSkillWarningModal = true;
          else
            skillTrainingVersions.push({
              skillId: skill.id,
              trainingVersionId: null,
            });
        } else {
          _.forEach(API.createSkillTrainingVersions([skill.id]), skillTrainingVersion => {
            const possibleTrainingVerisions: API.TrainingVersion[] = [];
            const trainingVersionId = API.extractTrainingVersionIdForSkill(
              sourceRequirement,
              skillTrainingVersion.skillId,
            );

            _.forEach(allTrainingVersions.result, trainingVersion => {
              if (_.includes(trainingVersion.skillIds, skill.id))
                possibleTrainingVerisions.push(trainingVersion);
            });
            if (skillTrainingVersion)
              skillTrainingVersions.push({
                skillId: skillTrainingVersion.skillId,
                trainingVersionId: trainingVersionId ?? null,
              });
          });
        }
      });

      const updated = await API.updateRequirement({
        ...sourceRequirement,
        skillTrainingVersions: [
          ...sourceRequirement.skillTrainingVersions,
          ...skillTrainingVersions,
        ],
      });
      if (!isMounted.current) return;
      if (API.isFailure(updated)) {
        handleErrorWarningModal();
        showLoaderForLevel(level, false);
        return updated;
      }
    } else {
      const skillIds: string[] = [];
      skills.forEach(skill => {
        if (API.isOrganizationalUnit(treeNode.object) && skill.isPractical)
          showPracticalSkillWarningModal = true;
        else skillIds.push(skill.id);
      });
      const skillTrainingVersions = API.createSkillTrainingVersions(skillIds);
      const created = await API.createRequirement({
        linkedObjectId: treeNode.object.id,
        level: API.workstationWorkerLevels2api(sourceRequirementLevel),
        skillTrainingVersions,
      });
      if (!isMounted.current) return;
      if (API.isFailure(created)) {
        showLoaderForLevel(level, false);
        return created;
      }
    }

    if (showPracticalSkillWarningModal)
      handleWarningModal(
        modal,
        t('alex:workstations.workstationPanel.requirementTable.addParcticalSkillOnUnitWarning'),
        undefined,
        t('common:button.gotIt'),
      );

    showLoaderForLevel(level, false);
  }

  const onCreateSkill = (input?: string) => {
    if (input) setSearchInput(input);
    setShowSkillModal(true);
  };

  function handleErrorWarningModal() {
    handleWarningModal(modal, t('common:error.unknown'), undefined, t('common:button.ok'));
  }
  const tabs: Tab[] = [
    {
      title: {
        title:
          treeNode && API.isOrganizationalUnit(treeNode.object)
            ? t('alex:workstations.workstationPanel.requirementTable.requiredSkillOnOrgUnit')
            : t('alex:workstations.workstationPanel.requirementTable.requiredSkillOnWorkstation'),
        tabTotal: requirementTableCount,
      },
    },
    {
      title: {
        title: t('alex:workstations.workstationPanel.requirementTable.trainingsToGetSkills'),
        warningIcon: skillsWithoutMapedTrainingsExist ? true : false,
        tabTotal: skillsWithoutMapedTrainingsExist ? undefined : trainingRequirementTableCount,
      },
    },
  ];

  function onPressSkill(skill: API.Skill) {
    setSelectedSkill(skill);
    const _isValidPermission = isValidPermission(API.Permission.skills_edit, treeNode);
    setIsModifySkillModalReadOnly(!_isValidPermission);
    setShowSkillModal(true);
  }

  function _handleModalClose(isShow: boolean, _newlyCreatedTrainingVersion?: API.TrainingVersion) {
    setIsShowWorkstationTrainingModal(isShow);
  }

  return (
    <>
      {isShowWorkstationTrainingModal && (
        <ModifyTrainingModal
          config={workstationTrainingModalConfig}
          handleModalClose={_handleModalClose}
          handleRefresh={refreshLevelSkills}
        />
      )}
      {showSkillModal && (
        <ModifySkillModal
          skillId={selectedSkill?.id}
          skillNamePlaceholder={searchInput}
          readOnly={isModifySkillModalReadOnly}
          addSkillToNodeLevelRequirement={{
            nodeId: treeNode!.id,
            requirementId: sourceRequirement?.id,
            requirementLevel: sourceRequirementLevel,
            isNodeOrgUnit: treeNode ? API.isOrganizationalUnit(treeNode.object) : false,
          }}
          handleModalClose={() => {
            setShowSkillModal(false);
            setSelectedSkill(undefined);
            setSearchInput(undefined);
            setIsModifySkillModalReadOnly(false);
          }}
        />
      )}
      <View style={Styles.requirementTableContainer}>
        <View style={Styles.requirementTableTitleContainer}>
          <View style={{ flex: 0.95 }}>
            <AnimatedTab
              currentTabIndex={isRequiredSkillsSelected ? 0 : 1}
              tabs={tabs}
              onTabPress={() => {
                setIsRequiredSkillsSelected(!isRequiredSkillsSelected);
              }}
            />
          </View>
        </View>

        {isRequiredSkillsSelected ? (
          <ScrollView contentContainerStyle={[Styles.requirementTableInnerContainer]} horizontal>
            <RequirementSkillColumn
              isLoading={level1Loading}
              addedSkills={addedSkills}
              levelSkillsPaneContents={levelSkillsPaneContents}
              requirementLevel={API.WorkstationWorkerLevels.LEVEL1}
              treeNode={treeNode}
              style={Styles.requirementColumn1}
              onPressSkill={onPressSkill}
              onDeleteSkill={onDeleteSkill}
              setSourceRequirement={setSourceRequirement}
              setSourceRequirementLevel={setSourceRequirementLevel}
              addSkillAndUpdateRequirment={(skills: API.Skill[]) =>
                addSkillAndUpdateRequirment(skills, WorkstationWorkerLevel.LEVEL1)
              }
              onCreateSkill={onCreateSkill}
            />
            <RequirementSkillColumn
              isLoading={level2Loading}
              addedSkills={addedSkills}
              levelSkillsPaneContents={levelSkillsPaneContents}
              requirementLevel={API.WorkstationWorkerLevels.LEVEL2}
              treeNode={treeNode}
              style={Styles.requirementColumn2}
              onPressSkill={onPressSkill}
              onDeleteSkill={onDeleteSkill}
              setSourceRequirement={setSourceRequirement}
              setSourceRequirementLevel={setSourceRequirementLevel}
              addSkillAndUpdateRequirment={(skills: API.Skill[]) =>
                addSkillAndUpdateRequirment(skills, WorkstationWorkerLevel.LEVEL2)
              }
              onCreateSkill={onCreateSkill}
            />
            <RequirementSkillColumn
              isLoading={level3Loading}
              addedSkills={addedSkills}
              levelSkillsPaneContents={levelSkillsPaneContents}
              requirementLevel={API.WorkstationWorkerLevels.LEVEL3}
              treeNode={treeNode}
              style={Styles.requirementColumn3}
              onPressSkill={onPressSkill}
              onDeleteSkill={onDeleteSkill}
              setSourceRequirement={setSourceRequirement}
              setSourceRequirementLevel={setSourceRequirementLevel}
              addSkillAndUpdateRequirment={(skills: API.Skill[]) =>
                addSkillAndUpdateRequirment(skills, WorkstationWorkerLevel.LEVEL3)
              }
              onCreateSkill={onCreateSkill}
            />
            <RequirementSkillColumn
              isLoading={level4Loading}
              addedSkills={addedSkills}
              levelSkillsPaneContents={levelSkillsPaneContents}
              requirementLevel={API.WorkstationWorkerLevels.LEVEL4}
              treeNode={treeNode}
              style={Styles.requirementColumn4}
              onPressSkill={onPressSkill}
              onDeleteSkill={onDeleteSkill}
              setSourceRequirement={setSourceRequirement}
              setSourceRequirementLevel={setSourceRequirementLevel}
              addSkillAndUpdateRequirment={(skills: API.Skill[]) =>
                addSkillAndUpdateRequirment(skills, WorkstationWorkerLevel.LEVEL4)
              }
              onCreateSkill={onCreateSkill}
            />
          </ScrollView>
        ) : (
          <>
            {!isRequiredSkillsSelected && (
              <ScrollView contentContainerStyle={Styles.requirementTableInnerContainer} horizontal>
                <RequirementTrainingColumn
                  requirementLevel={API.WorkstationWorkerLevels.LEVEL1}
                  levelSkillsPaneContents={levelSkillsPaneContents}
                  handleWorkstationTrainingModal={handleWorkstationTrainingModal}
                  refreshLevelSkills={refreshLevelSkills}
                  treeNode={treeNode}
                />
                <RequirementTrainingColumn
                  requirementLevel={API.WorkstationWorkerLevels.LEVEL2}
                  levelSkillsPaneContents={levelSkillsPaneContents}
                  handleWorkstationTrainingModal={handleWorkstationTrainingModal}
                  refreshLevelSkills={refreshLevelSkills}
                  treeNode={treeNode}
                />
                <RequirementTrainingColumn
                  requirementLevel={API.WorkstationWorkerLevels.LEVEL3}
                  levelSkillsPaneContents={levelSkillsPaneContents}
                  handleWorkstationTrainingModal={handleWorkstationTrainingModal}
                  refreshLevelSkills={refreshLevelSkills}
                  treeNode={treeNode}
                />
                <RequirementTrainingColumn
                  requirementLevel={API.WorkstationWorkerLevels.LEVEL4}
                  levelSkillsPaneContents={levelSkillsPaneContents}
                  handleWorkstationTrainingModal={handleWorkstationTrainingModal}
                  refreshLevelSkills={refreshLevelSkills}
                  treeNode={treeNode}
                />
              </ScrollView>
            )}
          </>
        )}
      </View>
    </>
  );
};
