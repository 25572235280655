import React, { useEffect, useState, useRef, useContext } from 'react';
import * as API from 'shared/backend-data';
import { View, ViewStyle, Text } from 'react-native';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import * as _ from 'lodash-es';
import Styles from '../style';
import { IconSVG } from 'shared/ui-component/Icon';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { LevelObjectives } from './LevelObjectives';
import { t, capitalizeFirstLetter } from 'shared/localisation/i18n';
import { DropdownType } from '../WorkstationModalComponent';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';

interface WorkstationLevelObjectivesProps {
  containerStyle?: ViewStyle;
  level: API.WorkstationWorkerLevels;
  orgUnitOptions: DropDownOption[];
  shiftId?: string;
  workstationId?: string;
  workstation: API.Workstation;
  index: number;
  disable: boolean;

  workersTargetOnShiftData?: API.WorkersTargetOnShift;
  setWorkersTargetOnShiftData: React.Dispatch<
    React.SetStateAction<API.WorkersTargetOnShift | undefined>
  >;
}

const ShiftIcon = require('shared/assets/svg/icon.shift.svg').default;

export const WorkstationLevelObjectives: React.FC<WorkstationLevelObjectivesProps> = props => {
  const {
    containerStyle,
    level,
    orgUnitOptions,
    shiftId,
    workstation,
    index,
    disable,

    workersTargetOnShiftData,
    setWorkersTargetOnShiftData,
  } = props;

  const [workstationTargetActual, setWorkstationTargetActual] =
    useState<API.WorkstationTargetActual | null>();
  const [shiftWorkersTarget, setShiftWorkersTarget] = useState<API.ShiftWorkersTarget>();
  const [shift, setShift] = useState<API.Shift>();

  const [level2Actual, setLevel2Actual] = useState<number>(0);
  const [level3Actual, setLevel3Actual] = useState<number>(0);
  const [level4Actual, setLevel4Actual] = useState<number>(0);

  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);

  const level2ActualInDecimalRef = useRef<number>();
  const level3ActualInDecimalRef = useRef<number>();
  const level4ActualInDecimalRef = useRef<number>();

  const level2ActualInPercentageRef = useRef<number>();
  const level3ActualInPercentageRef = useRef<number>();
  const level4ActualInPercentageRef = useRef<number>();

  const workstationTargetActualInitialValueRef = useRef<API.WorkstationTargetActual>();
  const workersTargetOnShiftDataRef = useRef<API.WorkersTargetOnShift | undefined>(
    workersTargetOnShiftData || {},
  );

  const isMounted = useIsMounted();

  useEffect(() => {
    fetchShiftDetails();
    findWorkstationActuals();
  }, [shiftId, workstation]);

  useEffect(() => {
    workersTargetOnShiftDataRef.current = workersTargetOnShiftData || {};
    if (workersTargetOnShiftData)
      setShiftWorkersTarget(workersTargetOnShiftData[shiftId ?? workstation.id]);
  }, [workersTargetOnShiftData, shiftId, workstation]);

  async function fetchShiftDetails() {
    if (shiftId) {
      const _shift = await API.getShift(shiftId);
      if (!isMounted.current) return;
      if (API.isFailure(_shift)) {
        logger.warn(_shift);
        return;
      }

      setShift(_shift);
    }

    const _shiftWorkersTarget = API.extractWORKSTATIONWorkersTargetOnShift(workstation, shiftId);
    if (!isMounted.current) return;
    if (API.isFailure(_shiftWorkersTarget)) {
      logger.warn(_shiftWorkersTarget);
      return;
    }

    setShiftWorkersTarget(_shiftWorkersTarget);
  }

  async function handleApplyPercentageTargetsCheckBox(
    state: boolean | null,
    level: API.WorkstationWorkerLevels,
  ) {
    const shiftIdOrWorkstationId: string = shiftId ?? workstation.id;
    let convertedData: API.WorkstationTargetActual;
    let _workstationTargetActual = _.cloneDeep(workstationTargetActual);

    if (
      workersTargetOnShiftDataRef.current &&
      !workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId] &&
      _workstationTargetActual
    ) {
      workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId] = {
        workersWithLevel2AtLeastWorkstationTarget:
          _workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget,
        workersWithLevel3AtLeastWorkstationTarget:
          _workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget,
        workersWithLevel4WorkstationTarget:
          _workstationTargetActual.workersWithLevel4WorkstationTarget,
      };
    }

    if (workersTargetOnShiftDataRef.current && _workstationTargetActual) {
      if (
        workstationTargetActualInitialValueRef.current &&
        level === API.WorkstationWorkerLevels.LEVEL2 &&
        state ===
          Boolean(
            workstationTargetActualInitialValueRef.current
              ?.workersWithLevel2AtLeastWorkstationTarget.isPercentage,
          )
      ) {
        _workstationTargetActual.workersWithLevel2AtLeastWorkstationTarget =
          workstationTargetActualInitialValueRef.current?.workersWithLevel2AtLeastWorkstationTarget;
        convertedData = _workstationTargetActual;
      } else if (
        workstationTargetActualInitialValueRef.current &&
        level === API.WorkstationWorkerLevels.LEVEL3 &&
        state ===
          Boolean(
            workstationTargetActualInitialValueRef.current
              ?.workersWithLevel3AtLeastWorkstationTarget.isPercentage,
          )
      ) {
        _workstationTargetActual.workersWithLevel3AtLeastWorkstationTarget =
          workstationTargetActualInitialValueRef.current?.workersWithLevel3AtLeastWorkstationTarget;

        convertedData = _workstationTargetActual;
      } else if (
        workstationTargetActualInitialValueRef.current &&
        level === API.WorkstationWorkerLevels.LEVEL4 &&
        state ===
          Boolean(
            workstationTargetActualInitialValueRef.current?.workersWithLevel4WorkstationTarget
              .isPercentage,
          )
      ) {
        _workstationTargetActual.workersWithLevel4WorkstationTarget =
          workstationTargetActualInitialValueRef.current?.workersWithLevel4WorkstationTarget;
        convertedData = _workstationTargetActual;
      } else if (state) {
        let _convertedData = await API.convertLevelTargetsFromDecimalToPercentage(
          _workstationTargetActual,
          workstation.id,
          shiftId,

          level,
        );
        if (!isMounted.current) return;
        if (API.isFailure(_convertedData)) {
          logger.warn(_convertedData);
          return;
        }
        convertedData = _convertedData;
      } else {
        let _convertedData = await API.convertLevelTargetsFromPercentageToDecimal(
          _workstationTargetActual,
          workstation.id,
          level,
          shiftId,
        );
        if (!isMounted.current) return;
        if (API.isFailure(_convertedData)) {
          logger.warn(_convertedData);
          return;
        }
        convertedData = _convertedData;
      }

      
      if (level === API.WorkstationWorkerLevels.LEVEL2) {
        const workersWithLevel2AtLeastWorkstationTarget: API.WorkstationTarget = {
          ...workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId]
            .workersWithLevel2AtLeastWorkstationTarget,
          minNumberOfWorker:
            convertedData.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker,
          idealNumberOfWorker:
            convertedData.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker,
          isPercentage: !!state,
        };

        workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId] = {
          ...workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId],
          workersWithLevel2AtLeastWorkstationTarget,
        };
        convertedData.workersWithLevel2AtLeastWorkstationTarget.isPercentage = !!state;

        if (!!state) {
          if (level2ActualInPercentageRef.current !== undefined) {
            setLevel2Actual(level2ActualInPercentageRef.current);
          } else {
            const value = await API.convertTargetActualFromDecimalToPercentage(
              level2Actual,
              workstation.id,
              shiftId,
            );

            if (!isMounted.current) return;
            if (API.isFailure(value)) {
              logger.warn(value);
              return;
            }

            setLevel2Actual(value);
          }
        } else {
          if (level2ActualInDecimalRef.current !== undefined) {
            setLevel2Actual(level2ActualInDecimalRef.current);
          } else {
            const value = await API.convertTargetActualFromPercentageToDecimal(
              level2Actual,
              workstation.id,
              shiftId,
            );

            if (!isMounted.current) return;
            if (API.isFailure(value)) {
              logger.warn(value);
              return;
            }

            setLevel2Actual(value);
          }
        }
      }

      
      if (level === API.WorkstationWorkerLevels.LEVEL3) {
        const workersWithLevel3AtLeastWorkstationTarget: API.WorkstationTarget = {
          ...workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId]
            .workersWithLevel3AtLeastWorkstationTarget,
          minNumberOfWorker:
            convertedData.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker,
          idealNumberOfWorker:
            convertedData.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker,
          isPercentage: !!state,
        };

        workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId] = {
          ...workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId],
          workersWithLevel3AtLeastWorkstationTarget,
        };

        convertedData.workersWithLevel3AtLeastWorkstationTarget.isPercentage = !!state;
        if (!!state) {
          if (level3ActualInPercentageRef.current !== undefined) {
            setLevel3Actual(level3ActualInPercentageRef.current);
          } else {
            const value = await API.convertTargetActualFromDecimalToPercentage(
              level3Actual,
              workstation.id,
              shiftId,
            );

            if (!isMounted.current) return;
            if (API.isFailure(value)) {
              logger.warn(value);
              return;
            }

            setLevel3Actual(value);
          }
        } else {
          if (level3ActualInDecimalRef.current !== undefined) {
            setLevel3Actual(level3ActualInDecimalRef.current);
          } else {
            const value = await API.convertTargetActualFromPercentageToDecimal(
              level3Actual,
              workstation.id,
              shiftId,
            );

            if (!isMounted.current) return;
            if (API.isFailure(value)) {
              logger.warn(value);
              return;
            }

            setLevel3Actual(value);
          }
        }
      }

      
      if (level === API.WorkstationWorkerLevels.LEVEL4) {
        const workersWithLevel4WorkstationTarget: API.WorkstationTarget = {
          ...workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId]
            .workersWithLevel4WorkstationTarget,
          minNumberOfWorker: convertedData.workersWithLevel4WorkstationTarget.minNumberOfWorker,
          idealNumberOfWorker: convertedData.workersWithLevel4WorkstationTarget.idealNumberOfWorker,
          isPercentage: !!state,
        };

        workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId] = {
          ...workersTargetOnShiftDataRef.current[shiftIdOrWorkstationId],
          workersWithLevel4WorkstationTarget,
        };

        convertedData.workersWithLevel4WorkstationTarget.isPercentage = !!state;

        if (!!state) {
          if (level4ActualInPercentageRef.current !== undefined) {
            setLevel4Actual(level4ActualInPercentageRef.current);
          } else {
            const value = await API.convertTargetActualFromDecimalToPercentage(
              level4Actual,
              workstation.id,
              shiftId,
            );

            if (!isMounted.current) return;
            if (API.isFailure(value)) {
              logger.warn(value);
              return;
            }

            setLevel4Actual(value);
          }
        } else {
          if (level4ActualInDecimalRef.current !== undefined) {
            setLevel4Actual(level4ActualInDecimalRef.current);
          } else {
            const value = await API.convertTargetActualFromPercentageToDecimal(
              level4Actual,
              workstation.id,
              shiftId,
            );

            if (!isMounted.current) return;
            if (API.isFailure(value)) {
              logger.warn(value);
              return;
            }

            setLevel4Actual(value);
          }
        }
      }

      setWorkstationTargetActual(convertedData);
    } else if (_workstationTargetActual) {
    }

    setWorkersTargetOnShiftData({ ...workersTargetOnShiftDataRef.current });
  }

  function handleChange(
    level: API.WorkstationWorkerLevels,
    value: DropDownOption,
    dropdownType: DropdownType,
  ) {
    const shiftIdOrWorkstationId: string = shiftId ?? workstation.id;

    (disable ? workstation.shiftIds ?? [] : [shiftIdOrWorkstationId]).map(
      (_shiftIdOrWorkstationId: string) => {
        if (workersTargetOnShiftDataRef.current) {
          if (workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId]) {
            
            if (level === API.WorkstationWorkerLevels.LEVEL2) {
              if (dropdownType === DropdownType.IDEAL) {
                const workersWithLevel2AtLeastWorkstationTarget: API.WorkstationTarget = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId]
                    .workersWithLevel2AtLeastWorkstationTarget,
                  idealNumberOfWorker: parseInt(value.key, 10),
                };

                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId],
                  workersWithLevel2AtLeastWorkstationTarget,
                };
              } else if (dropdownType === DropdownType.MINIMUM) {
                const workersWithLevel2AtLeastWorkstationTarget: API.WorkstationTarget = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId]
                    .workersWithLevel2AtLeastWorkstationTarget,
                  minNumberOfWorker: parseInt(value.key, 10),
                };

                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId],
                  workersWithLevel2AtLeastWorkstationTarget,
                };
              }
            }

            
            if (level === API.WorkstationWorkerLevels.LEVEL3) {
              if (dropdownType === DropdownType.IDEAL) {
                const workersWithLevel3AtLeastWorkstationTarget: API.WorkstationTarget = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId]
                    .workersWithLevel3AtLeastWorkstationTarget,
                  idealNumberOfWorker: parseInt(value.key, 10),
                };

                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId],
                  workersWithLevel3AtLeastWorkstationTarget,
                };
              } else if (dropdownType === DropdownType.MINIMUM) {
                const workersWithLevel3AtLeastWorkstationTarget: API.WorkstationTarget = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId]
                    .workersWithLevel3AtLeastWorkstationTarget,
                  minNumberOfWorker: parseInt(value.key, 10),
                };

                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId],
                  workersWithLevel3AtLeastWorkstationTarget,
                };
              }
            }

            
            if (level === API.WorkstationWorkerLevels.LEVEL4) {
              if (dropdownType === DropdownType.IDEAL) {
                const workersWithLevel4WorkstationTarget: API.WorkstationTarget = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId]
                    .workersWithLevel4WorkstationTarget,
                  idealNumberOfWorker: parseInt(value.key, 10),
                };

                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId],
                  workersWithLevel4WorkstationTarget,
                };
              } else if (dropdownType === DropdownType.MINIMUM) {
                const workersWithLevel4WorkstationTarget: API.WorkstationTarget = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId]
                    .workersWithLevel4WorkstationTarget,
                  minNumberOfWorker: parseInt(value.key, 10),
                };

                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  ...workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId],
                  workersWithLevel4WorkstationTarget,
                };
              }
            }
          } else {
            
            if (level === API.WorkstationWorkerLevels.LEVEL2) {
              if (dropdownType === DropdownType.IDEAL) {
                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  workersWithLevel2AtLeastWorkstationTarget: {
                    idealNumberOfWorker: parseInt(value.key, 10),
                  },
                  workersWithLevel3AtLeastWorkstationTarget: {},
                  workersWithLevel4WorkstationTarget: {},
                };
              } else if (dropdownType === DropdownType.MINIMUM) {
                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  workersWithLevel2AtLeastWorkstationTarget: {
                    minNumberOfWorker: parseInt(value.key, 10),
                  },
                  workersWithLevel3AtLeastWorkstationTarget: {},
                  workersWithLevel4WorkstationTarget: {},
                };
              }
            }

            
            if (level === API.WorkstationWorkerLevels.LEVEL3) {
              if (dropdownType === DropdownType.IDEAL) {
                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  workersWithLevel2AtLeastWorkstationTarget: {},
                  workersWithLevel3AtLeastWorkstationTarget: {
                    idealNumberOfWorker: parseInt(value.key, 10),
                  },
                  workersWithLevel4WorkstationTarget: {},
                };
              } else if (dropdownType === DropdownType.MINIMUM) {
                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  workersWithLevel2AtLeastWorkstationTarget: {},
                  workersWithLevel3AtLeastWorkstationTarget: {
                    minNumberOfWorker: parseInt(value.key, 10),
                  },
                  workersWithLevel4WorkstationTarget: {},
                };
              }
            }

            
            if (level === API.WorkstationWorkerLevels.LEVEL4) {
              if (dropdownType === DropdownType.IDEAL) {
                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  workersWithLevel2AtLeastWorkstationTarget: {},
                  workersWithLevel3AtLeastWorkstationTarget: {},
                  workersWithLevel4WorkstationTarget: {
                    idealNumberOfWorker: parseInt(value.key, 10),
                  },
                };
              } else if (dropdownType === DropdownType.MINIMUM) {
                workersTargetOnShiftDataRef.current[_shiftIdOrWorkstationId] = {
                  workersWithLevel2AtLeastWorkstationTarget: {},
                  workersWithLevel3AtLeastWorkstationTarget: {},
                  workersWithLevel4WorkstationTarget: {
                    minNumberOfWorker: parseInt(value.key, 10),
                  },
                };
              }
            }
          }
        }
      },
    );

    setWorkersTargetOnShiftData({ ...workersTargetOnShiftDataRef.current });
  }

  async function findWorkstationActuals() {
    const _workstationObjectiveAndActual = await API.fetchWorkstationTargetAndWorkersAlert(
      workstation,
      level,
      shiftId ? [shiftId] : undefined,
    );
    if (!isMounted.current) return;
    if (API.isFailure(_workstationObjectiveAndActual)) {
      logger.warn(_workstationObjectiveAndActual);
      return;
    }

    const targetValues = await API.computeActualValues(
      _workstationObjectiveAndActual.workstationsTargetAndActual[shiftId ?? workstation.id],
      workstation.id,
      workstationTargetsStartingAtLevel,
      shiftId,
    );
    if (!isMounted.current) return;
    if (API.isFailure(targetValues)) {
      logger.warn(targetValues);
      return;
    }

    workstationTargetActualInitialValueRef.current = _.cloneDeep(
      _workstationObjectiveAndActual.workstationsTargetAndActual[shiftId ?? workstation.id],
    );

    if (
      !workstationTargetActualInitialValueRef.current.workersWithLevel2AtLeastWorkstationTarget
        .isPercentage
    ) {
      level2ActualInDecimalRef.current = targetValues.workersWithLevel2AtLeastActual;
    } else {
      level2ActualInPercentageRef.current = targetValues.workersWithLevel2AtLeastActual;
    }

    if (
      !workstationTargetActualInitialValueRef.current.workersWithLevel3AtLeastWorkstationTarget
        .isPercentage
    ) {
      level3ActualInDecimalRef.current = targetValues.workersWithLevel3AtLeastActual;
    } else {
      level3ActualInPercentageRef.current = targetValues.workersWithLevel3AtLeastActual;
    }

    if (
      !workstationTargetActualInitialValueRef.current.workersWithLevel4WorkstationTarget
        .isPercentage
    ) {
      level4ActualInDecimalRef.current = targetValues.workersWithLevel4Actual;
    } else {
      level4ActualInPercentageRef.current = targetValues.workersWithLevel4Actual;
    }

    setLevel2Actual(targetValues.workersWithLevel2AtLeastActual);
    setLevel3Actual(targetValues.workersWithLevel3AtLeastActual);
    setLevel4Actual(targetValues.workersWithLevel4Actual);

    setWorkstationTargetActual(
      shiftId
        ? _workstationObjectiveAndActual.workstationsTargetAndActual[shiftId]
        : _workstationObjectiveAndActual.workstationsTargetAndActual[workstation.id],
    );
  }

  return (
    <View style={[Styles.workstationLevelObjectivesContainer, containerStyle, { zIndex: -index }]}>
      <View style={Styles.container}>
        <View style={Styles.shiftNameContainer}>
          {shift && (
            <>
              <IconSVG
                svgComponent={ShiftIcon}
                containerStyle={Styles.shiftIcon}
                color={shift.color}
              />
              <EllipsisWithTooltip
                textStyle={Styles.shiftNameTitle}
                text={capitalizeFirstLetter(shift.name)}
              />
            </>
          )}
        </View>

        <Text style={Styles.percentageTitle}>%</Text>

        <Text style={Styles.realValueTitle}>
          {t('alex:workstationModal.workstationLevelObjectives.real')}
        </Text>

        <Text style={Styles.minimumValue}>
          {t('alex:workstationModal.workstationLevelObjectives.minimum')}
        </Text>

        <Text style={Styles.idealValue}>
          {t('alex:workstationModal.workstationLevelObjectives.ideal')}
        </Text>
      </View>
      {level === API.WorkstationWorkerLevels.LEVEL2 && (
        <LevelObjectives
          level={level}
          workstation={workstation}
          shiftWorkersTarget={shiftWorkersTarget}
          orgUnitOptions={orgUnitOptions}
          title={t('alex:workerTable.lev2AndAbove', { count: API.WorkstationWorkerLevels.LEVEL2 })}
          containerStyle={{ zIndex: -1 }}
          handleChange={(value: DropDownOption, dropdownType: DropdownType) =>
            handleChange(API.WorkstationWorkerLevels.LEVEL2, value, dropdownType)
          }
          handleCheckBoxChange={handleApplyPercentageTargetsCheckBox}
          disable={disable && index !== 0}
          workstationTargetActual={level2Actual}
          workstationTargetActualObject={workstationTargetActual}
        />
      )}
      {level <= API.WorkstationWorkerLevels.LEVEL3 && (
        <LevelObjectives
          level={API.WorkstationWorkerLevels.LEVEL3}
          workstation={workstation}
          shiftWorkersTarget={shiftWorkersTarget}
          orgUnitOptions={orgUnitOptions}
          title={t('alex:workerTable.lev2AndAbove', { count: API.WorkstationWorkerLevels.LEVEL3 })}
          containerStyle={{ zIndex: -2 }}
          handleChange={(value: DropDownOption, dropdownType: DropdownType) =>
            handleChange(API.WorkstationWorkerLevels.LEVEL3, value, dropdownType)
          }
          handleCheckBoxChange={handleApplyPercentageTargetsCheckBox}
          disable={disable && index !== 0}
          workstationTargetActual={level3Actual}
          workstationTargetActualObject={workstationTargetActual}
        />
      )}
      {level <= API.WorkstationWorkerLevels.LEVEL4 && (
        <LevelObjectives
          level={API.WorkstationWorkerLevels.LEVEL4}
          workstation={workstation}
          shiftWorkersTarget={shiftWorkersTarget}
          orgUnitOptions={orgUnitOptions}
          title={t('alex:workerTable.lev4Actuals')}
          containerStyle={{ zIndex: -3 }}
          handleChange={(value: DropDownOption, dropdownType: DropdownType) =>
            handleChange(API.WorkstationWorkerLevels.LEVEL4, value, dropdownType)
          }
          handleCheckBoxChange={handleApplyPercentageTargetsCheckBox}
          disable={disable && index !== 0}
          workstationTargetActual={level4Actual}
          workstationTargetActualObject={workstationTargetActual}
        />
      )}
    </View>
  );
};
