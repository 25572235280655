import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ViewStyle, StyleProp } from 'react-native';
import styles from './styles';

interface ProgressBarProps {
  /** percentage for a progress between [0,100], undefined for inifinite loading */
  percentage: number | undefined;
  style?: StyleProp<ViewStyle>;
  progressBarStyle?: StyleProp<ViewStyle>;
}

export const ProgressBar: React.FC<ProgressBarProps> = props => {
  const [percentage, setPercentage] = useState<number | undefined>(0);

  useEffect(() => {
    if (props.percentage === undefined) {
      setPercentage(undefined);
    } else if (props.percentage !== 0) {
      const _percentage = Math.max(0, Math.min(props.percentage, 100));
      if (percentage === _percentage) {
        setPercentage(undefined);
      } else {
        setPercentage(_percentage);
      }
    }
  }, [props.percentage]);

  return (
    <View style={[styles.backgroundBar, props.style]}>
      {percentage === undefined ? (
        <View
          style={[
            styles.progressBar,
            styles.infiniteProgressBar,
            styles.infiniteProgressBarAnimation,
          ]}
        />
      ) : (
        <View style={[styles.progressBar, { width: `${percentage}%` }, props.progressBarStyle]} />
      )}
    </View>
  );
};
