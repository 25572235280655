import React, { useState, createContext, useEffect } from 'react';
import * as API from 'shared/backend-data';
import { MyHub } from 'shared/util/MyHub';
import { SkillItemRow } from './skills/containers/SkillsLibrary';
import { WorkerAndWorkstationVersatilityRow } from './workers/WorkersLibrary';
import { TrainingRow } from './trainings/TrainingsLibrary';

interface WorkerTableData {
  rows: WorkerAndWorkstationVersatilityRow[];
  filteredAndSortedRows: WorkerAndWorkstationVersatilityRow[];
}
interface TrainingTableData {
  rows: TrainingRow[];
  filteredRows: TrainingRow[];
}

export const MyFactoryContext = createContext<{
  workerTableData: WorkerTableData;
  trainingTableData: TrainingTableData;
  skillTableData: SkillItemRow[];
  setWorkerTableData: React.Dispatch<React.SetStateAction<WorkerTableData>>;
  setTrainingTableData: React.Dispatch<React.SetStateAction<TrainingTableData>>;
  setSkillTableData: React.Dispatch<React.SetStateAction<SkillItemRow[]>>;
}>({
  workerTableData: {
    rows: [],
    filteredAndSortedRows: [],
  },
  trainingTableData: {
    rows: [],
    filteredRows: [],
  },
  skillTableData: [],
  setWorkerTableData: () => {},
  setTrainingTableData: () => {},
  setSkillTableData: () => {},
});

export const MyFactoryContextProvider: React.FC = props => {
  const [workerTableData, setWorkerTableData] = useState<WorkerTableData>({
    rows: [],
    filteredAndSortedRows: [],
  });
  const [trainingTableData, setTrainingTableData] = useState<TrainingTableData>({
    rows: [],
    filteredRows: [],
  });
  const [skillTableData, setSkillTableData] = useState<SkillItemRow[]>([]);

  useEffect(() => {
    const workerListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKER) {
        setWorkerTableData({ rows: [], filteredAndSortedRows: [] });
      } else if (data.factory.dataType === API.DataType.TRAININGSESSION) {
        const _rows = workerTableData.rows.map(row => {
          return {
            ...row,
            trainingSessions: undefined,
          };
        });
        setWorkerTableData({ ...workerTableData, rows: _rows });
      } else if (data.factory.dataType === API.DataType.WORKERSKILL) {
        const _rows = workerTableData.rows.map(row => {
          return {
            ...row,
            workerSkills: undefined,
            versatility: undefined,
          };
        });
        setWorkerTableData({ ...workerTableData, rows: _rows });
      } else if (data.factory.dataType === API.DataType.WORKERWORKSTATION) {
        const _rows = workerTableData.rows.map(row => {
          return {
            ...row,
            badgeState: undefined,
          };
        });
        setWorkerTableData({ ...workerTableData, rows: _rows });
      }
    });

    return () => {
      workerListener();
    };
  }, []);

  useEffect(() => {
    const trainingListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.TRAINING) {
        setTrainingTableData({ rows: [], filteredRows: [] });
      } else if (data.factory.dataType === API.DataType.PROOFBUNDLE) {
        const _rows = trainingTableData.rows.map(row => {
          return {
            ...row,
            workers: undefined,
          };
        });
        setTrainingTableData({ ...trainingTableData, rows: _rows });
      } else if (data.factory.dataType === API.DataType.TRAININGVERSION) {
        const trainingId = data.tooManyMutations ? true : data.factory.trainingVersion.trainingId;
        const _rows = trainingTableData.rows.map(row => {
          if (trainingId || row.training.id === trainingId) {
            return {
              ...row,
              skills: undefined,
              isPractical: undefined,
            };
          }
          return row;
        });
        setTrainingTableData({ ...trainingTableData, rows: _rows });
      } else if (data.factory.dataType === API.DataType.REQUIREMENT) {
        const _rows = trainingTableData.rows.map(row => {
          return {
            ...row,
            workstations: undefined,
          };
        });

        setTrainingTableData({ ...trainingTableData, rows: _rows });
      }
    });

    return () => {
      trainingListener();
    };
  }, []);

  useEffect(() => {
    const skillListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.SKILL) {
        setSkillTableData([]);
      } else if (data.factory.dataType === API.DataType.REQUIREMENT) {
        const skillData = skillTableData.map(data => {
          return {
            ...data,
            workstations: undefined,
          };
        });
        setSkillTableData(skillData);
      } else if (data.factory.dataType === API.DataType.WORKERSKILL) {
        const skillData = skillTableData.map(data => {
          return {
            ...data,
            workers: undefined,
          };
        });
        setSkillTableData(skillData);
      }
    });

    return () => {
      skillListener();
    };
  }, []);

  return (
    <MyFactoryContext.Provider
      value={{
        workerTableData,
        trainingTableData,
        skillTableData,
        setWorkerTableData,
        setTrainingTableData,
        setSkillTableData,
      }}
    >
      {props.children}
    </MyFactoryContext.Provider>
  );
};
