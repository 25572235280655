import React, { useState, useContext, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { TableColumn, TableLargeColumnWidth } from 'shared/ui-component/Table';
import { Table } from 'shared/ui-component/Table';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { EllipsisWithTooltip, Position } from 'shared/ui-component/EllipsisWithTooltip';
import { capitalize, t } from 'shared/localisation/i18n';
import {
  WorkerState,
  sortWorkerCompare,
  AddIndividualOrCollectiveProofData,
} from 'shared/backend-data';
import * as _ from 'lodash-es';
import { Colors } from 'shared/styles';
import { IconSVG } from 'shared/ui-component/Icon';
import * as API from 'shared/backend-data';
import { TrainingWorkerTableRow } from '../container';
import styles from './style';
import { SkillBadge } from 'shared/ui-component/Badge/SkillBadge';
import { SkillBadgeStates } from 'shared/util/Skill';
import { SortDirection } from '../../../../../sort';
import * as SharedStyles from 'shared/styles';
import { TrainingProofReviewModal } from '../../../../add-training-proof-modal/container/index';
import { MenuWidth } from 'shared/ui-component/Menu';
import { convertDate_ddMMYYYY } from 'shared/util-ts/DateUtils';
import { TagExtended } from 'sharedweb/src/Filter/container';
import logger, { loggerPerf } from 'shared/util/Logger';
import { DropdownConfigKey } from '../../../../../header-layout/headerFilterConfig';
import {
  getExportDataForWorkersInTrainingScreen,
  ImportExportFileNames,
} from 'shared/util/ExcelUtils';
import { ImportExportType } from 'shared/util/ExcelUtils';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import Aigle from 'aigle';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { isArrayEmpty } from 'shared/util-ts/Functions';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';
import { InteractionManager } from 'react-native';
import { getProofBundleValidityColor } from 'shared/util/ProofBundleUi';
import { ProofBookContainerWeb } from '../../../../proof-book';

interface TrainingWorkerTable {
  training: API.Training;
  rows: TrainingWorkerTableRow[];
  filterTags: TagExtended[];

  setWorkerCount: (count: number) => void;
}

const EyeIcon = require('shared/assets/svg/icon.eye.svg').default;

const _TrainingWorkerTableComponent: React.FC<TrainingWorkerTable> = props => {
  const { rows, training, filterTags, setWorkerCount } = props;

  const { isValidPermission } = useContext(PermissionManagementContext);
  const { isWorkerLastNameFirst } = useContext(GlobalDataContext);

  const isMounted = useIsMounted();

  const [showWorkerTrainingReviewModal, setShowWorkerTrainingReviewModal] =
    useState<boolean>(false);
  const [selectedTrainingWorkerTableRow, setSelectedTrainingWorkerTableRow] =
    useState<TrainingWorkerTableRow>();
  const [showTrainingBook, setShowTrainingBook] = useState<boolean>(false);
  const [proofBookSkillIds, setProofBookSkillIds] = useState<string[]>();
  const [proofBookWorkerIds, setProofBookWorkerIds] = useState<string[]>();
  const [latestTrainingVersionId, setLatestTrainingVersionId] = useState<string>();
  const [trainingSessionsOfTraining, setTrainingSessionsOfTraining] = useState<
    API.TrainingSession[]
  >([]);

  function onPressWorkerSkillReview(row: TrainingWorkerTableRow) {
    setSelectedTrainingWorkerTableRow(row);
    setShowTrainingBook(true);
  }

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      if (!training) return;

      const latestTrainingVersion = await API.getTrainingVersionsForTraining(training.id, true);
      if (!isMounted.current) return;
      if (API.isFailure(latestTrainingVersion)) {
        logger.warn('error fatiching latest training version for training', latestTrainingVersion);
        return latestTrainingVersion;
      }
      setLatestTrainingVersionId(latestTrainingVersion[0].id);

      const trainingSessions = await API.getTrainingSessionsForATrainingOrATrainingVersion(
        training.id,
      );
      if (!isMounted.current) return;
      if (API.isFailure(trainingSessions)) {
        logger.warn(trainingSessions);
        return trainingSessions;
      }
      setTrainingSessionsOfTraining(trainingSessions);
    });
  }, [training]);

  function getTrainingProofState(reviewState: API.ReviewState): string {
    if (reviewState === API.ReviewState.TO_REVIEW) {
      return t('alex:trainingSessions.waitingForValidation', undefined, false);
    } else if (
      reviewState === API.ReviewState.REJECTED ||
      reviewState === API.ReviewState.REJECTED_TO_RESUBMIT
    ) {
      return '(' + t('common:time.rejected_m', undefined, false) + ')';
    } else {
      return '';
    }
  }

  const columnDescriptors: TableColumn<TrainingWorkerTableRow>[] = [
    {
      label: 'alex:trainingsTab.workerTable.header.0',
      width: TableLargeColumnWidth,
      renderCell: (eachWorker, index, indexOfRow) => {
        return (
          <TouchableOpacity
            onPress={() => onPressWorkerSkillReview(eachWorker)}
            style={styles.eachColumn}
          >
            {renderImage(
              eachWorker.worker.profilePicture,
              ImageSizes.Medium,
              eachWorker.worker.name,
            )}
            <SkillBadge badgeValue={SkillBadgeStates.OK} style={styles.collabBadge} />
            <EllipsisWithTooltip
              style={styles.ellipsisText}
              text={`${capitalize(eachWorker.worker.name)}`}
              textStyle={
                eachWorker.worker.state === WorkerState.ARCHIVED
                  ? styles.disabled
                  : SharedStyles.Styles.tableText
              }
              type={'name'}
              position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
            />
          </TouchableOpacity>
        );
      },
      sort: sortByName,
    },
    {
      label: t('alex:trainingsTab.workerTable.header.1'),
      sort: sortByName,
      width: TableLargeColumnWidth,
      renderCell: (eachWorker, index, indexOfRow) => (
        <TouchableOpacity
          style={styles.eachColumn}
          onPress={() => onPressWorkerSkillReview(eachWorker)}
        >
          {eachWorker.latestProofBundle && (
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <EllipsisWithTooltip
                text={
                  eachWorker.latestProofBundle.review.state === API.ReviewState.TO_REVIEW
                    ? getTrainingProofState(eachWorker.latestProofBundle.review.state)
                    : convertDate_ddMMYYYY(eachWorker.latestProofBundle.startingDate)
                }
                textStyle={[
                  SharedStyles.Styles.tableText,
                  {
                    marginTop: 3,
                    marginLeft: 2,
                    color: getProofBundleValidityColor(
                      eachWorker.latestProofBundle,
                      Colors.MediumGray,
                    ),
                  },
                ]}
                position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
              />
              <View>
                <IconSVG svgComponent={EyeIcon} color={Colors.Grey} />
              </View>
            </View>
          )}
        </TouchableOpacity>
      ),
    },
  ];

  function sortByName(rows: TrainingWorkerTableRow[], sortDirection: SortDirection) {
    return rows.sort((a, b) =>
      sortWorkerCompare(a.worker, b.worker, sortDirection, isWorkerLastNameFirst),
    );
  }

  async function filterData(
    isKeywordFiltering: boolean,
    filterTags: TagExtended[],
    trainings: TrainingWorkerTableRow[],
  ): Promise<TrainingWorkerTableRow[]> {
    if (isArrayEmpty(filterTags) && isKeywordFiltering) {
      return [];
    }

    let filteringRows = isKeywordFiltering ? [] : trainings;
    const shiftFilters: TagExtended[] = [];
    const orgUnitFilters: TagExtended[] = [];
    const workerFilters: TagExtended[] = [];

    _.forEach(filterTags, tag => {
      if (tag.type === DropdownConfigKey.ORGUNIT) orgUnitFilters.push(tag);
      else if (tag.type === DropdownConfigKey.SHIFT) shiftFilters.push(tag);
      else if (tag.type === DropdownConfigKey.WORKER) workerFilters.push(tag);
    });

    if (shiftFilters.length) {
      await Aigle.map(shiftFilters, async eachFilter => {
        const workersInShift = await API.getWorkersInShift(
          eachFilter.value.shiftId,
          eachFilter.value.parentId,
        );
        if (!isMounted.current) return;
        if (API.isFailure(workersInShift)) {
          logger.warn(workersInShift);
          return;
        }

        workersInShift.forEach(worker => {
          workerFilters.push({
            key: worker.id,
            label: '',
          });
        });
      });

      if (isArrayEmpty(workerFilters)) return [];
    }

    if (workerFilters.length) {
      const data = _.filter(isKeywordFiltering ? trainings : filteringRows, row =>
        _.some(workerFilters, filter => filter.key === row.worker.id),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    if (orgUnitFilters.length) {
      const data = _.filter(isKeywordFiltering ? trainings : filteringRows, row =>
        _.some(orgUnitFilters, filter => {
          if (row.orgUnits === undefined) return true;

          const orgUnitIds = row.orgUnits.map(orgUnit => orgUnit.id);

          return orgUnitIds.includes(filter.key);
        }),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    return filteringRows;
  }

  async function filterRows(
    rows: TrainingWorkerTableRow[],
    tags: TagExtended[],
  ): Promise<TrainingWorkerTableRow[]> {
    if (!tags.length) {
      setWorkerCount(rows.length);
      return rows;
    }

    let _filteredRows: TrainingWorkerTableRow[] = rows;
    let keywordTags: TagExtended[] = [];
    let filterTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(tags, tag => {
      if (tag.isActiveBookmarkTag) {
        filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      _filteredRows = await filterData(true, keywordTags, _.uniqBy(_filteredRows, 'key'));
    }

    if (filterTags.length) {
      _filteredRows = await filterData(false, filterTags, _.uniqBy(_filteredRows, 'key'));
    }

    const __filteredRows = _.uniqBy(_filteredRows, row => row.worker.id);
    setWorkerCount(__filteredRows.length);

    return __filteredRows;
  }

  async function fetchRowDetails(
    row: TrainingWorkerTableRow,
    isRowMounted: React.MutableRefObject<boolean>,
  ): Promise<boolean> {
    let updated = false;

    if (row.orgUnits === undefined) {
      const orgUnits = await API.getWorkerOrganizationalUnits(row.worker.id);
      if (!isRowMounted.current) return false;
      if (API.isFailure(orgUnits)) {
        logger.warn(orgUnits);
      } else {
        row.orgUnits = orgUnits;
      }
      updated = true;
    }

    if (row.trainingSessions === undefined) {
      const trainingSessionContainsProofBundles = trainingSessionsOfTraining.filter(
        trainingSession =>
          row.proofBundles.some(
            proofBundle =>
              proofBundle.originObjectId === trainingSession.id &&
              trainingSession.traineeIds.find(traineeId => traineeId === proofBundle.workerId),
          ),
      );

      row.trainingSessions = trainingSessionContainsProofBundles;

      updated = true;
    }

    return updated;
  }

  function handleOpenProofBook(workerIds: string[], skillIds: string[]) {
    setProofBookWorkerIds(workerIds);
    setProofBookSkillIds(skillIds);

    setShowTrainingBook(true);
    setShowWorkerTrainingReviewModal(false);
    setSelectedTrainingWorkerTableRow(undefined);
  }

  function getTrainingSessionFromProofBundles(
    proofBundles: API.NoMetadata<API.ProofBundle>[],
  ): string | undefined {
    const proofBundle = proofBundles.find(
      proofBundle => API.getDataType(proofBundle.originObjectId) === API.DataType.TRAININGSESSION,
    );

    return proofBundle?.originObjectId;
  }

  return (
    <View style={{ width: '100%' }}>
      <Table
        columnDescriptors={columnDescriptors}
        defaultSortColumn={[0, SortDirection.asc]}
        rows={rows}
        showAddButton
        plusMenuWidth={MenuWidth.Large}
        onPlusPress={() => setShowWorkerTrainingReviewModal(true)}
        onRowPress={row => onPressWorkerSkillReview(row)}
        importExport={{
          excelIcon: true,
          exportOnly: true,
          getExportData: rows => getExportDataForWorkersInTrainingScreen(rows, training),
          refreshData: async () => {}, 
          importExportType: ImportExportType.WorkerProfileSkill,
          importExportFileName: ImportExportFileNames.WorkerProfileSkill,
          showImportExport: isValidPermission(API.Permission.workersDetail_view),
        }}
        filter={{
          filterRows: filterRows,
          tags: filterTags,
        }}
        rowLazyLoadProperties={fetchRowDetails}
        noDataMessage={t('alex:table.noWorkerHasTraining')}
      />
      {showWorkerTrainingReviewModal && (
        <TrainingProofReviewModal
          trainingProof={{
            addProofWorkerId: selectedTrainingWorkerTableRow
              ? selectedTrainingWorkerTableRow.worker.id
              : undefined,
            trainingSessionOrTrainingVersionId: selectedTrainingWorkerTableRow
              ? selectedTrainingWorkerTableRow.latestProofBundle?.originObjectId
              : undefined,
            type: selectedTrainingWorkerTableRow
              ? AddIndividualOrCollectiveProofData.INDIVIDUAL
              : AddIndividualOrCollectiveProofData.COLLECTIVE,
            proofBundleId: selectedTrainingWorkerTableRow
              ? selectedTrainingWorkerTableRow.latestProofBundle?.id
              : undefined,
            traineeIdToValidate: selectedTrainingWorkerTableRow
              ? selectedTrainingWorkerTableRow.worker.id
              : undefined,
            proofBundles: selectedTrainingWorkerTableRow?.proofBundles,
          }}
          preSelectedTraining={selectedTrainingWorkerTableRow ? undefined : training}
          handleModalClose={() => {
            setShowWorkerTrainingReviewModal(false);
            setSelectedTrainingWorkerTableRow(undefined);
          }}
          openProofBook={handleOpenProofBook}
        />
      )}
      {showTrainingBook && selectedTrainingWorkerTableRow && (
        <ProofBookContainerWeb
          setShowAddTrainingProofModal={setShowTrainingBook}
          trainingSessionId={
            !selectedTrainingWorkerTableRow.trainingSessions?.length
              ? undefined
              : API.getDataType(
                  selectedTrainingWorkerTableRow.latestProofBundle?.originObjectId,
                ) === API.DataType.TRAININGSESSION
              ? selectedTrainingWorkerTableRow.latestProofBundle?.originObjectId
              : getTrainingSessionFromProofBundles(selectedTrainingWorkerTableRow.proofBundles)
          }
          closeModalTrigger={() => {
            setShowWorkerTrainingReviewModal(false);
            setSelectedTrainingWorkerTableRow(undefined);
          }}
          modalAutoClose={false}
          
          trainingSessionWorkers={[selectedTrainingWorkerTableRow.worker.id]}
          proofAlreadyValidatedWorkersAndSkills={
            !selectedTrainingWorkerTableRow.trainingSessions?.length
              ? {
                  workerIds: [selectedTrainingWorkerTableRow.worker.id],
                  skillIds: selectedTrainingWorkerTableRow.trainingSkillIds,
                }
              : undefined
          }
          workerIds={
            !selectedTrainingWorkerTableRow.trainingSessions?.length
              ? [selectedTrainingWorkerTableRow.worker.id]
              : undefined
          }
          skillIds={
            !selectedTrainingWorkerTableRow.trainingSessions?.length
              ? selectedTrainingWorkerTableRow.trainingSkillIds
              : undefined
          }
        />
      )}
      {proofBookSkillIds && proofBookWorkerIds && latestTrainingVersionId && showTrainingBook && (
        <ProofBookContainerWeb
          workerIds={proofBookWorkerIds}
          skillIds={proofBookSkillIds}
          setShowAddTrainingProofModal={setShowTrainingBook}
          trainingVersionId={latestTrainingVersionId}
          closeModalTrigger={() => {
            setProofBookWorkerIds(undefined);
            setProofBookSkillIds(undefined);
          }}
          allowPartialSubmission={false}
        />
      )}
    </View>
  );
};

export const TrainingWorkerTableComponent = React.memo(
  _TrainingWorkerTableComponent,
  (prevProps, nextProps) => {
    const propsAreEqual =
      (prevProps.rows === nextProps.rows || (!prevProps.rows.length && !nextProps.rows.length)) &&
      (prevProps.filterTags === nextProps.filterTags ||
        (!prevProps.filterTags.length && !nextProps.filterTags.length));
    if (!propsAreEqual)
      loggerPerf.debug(
        'Re-Rendering component TrainingsTable (prevProps, nextProps)',
        prevProps,
        nextProps,
      );
    return propsAreEqual;
  },
);
