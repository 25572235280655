import React from 'react';
import { View, StyleSheet, Platform, StyleProp, ViewStyle } from 'react-native';
import { ProgressBar } from 'shared/ui-component/ProgressBar';
import { Spacings } from '../../styles';
import * as _ from 'lodash-es';

const isMobilePlatform = Platform.OS === 'android' || Platform.OS === 'ios';

export interface TrainingSessionSkillsProgressProps {
  validSkillsCount: number;
  requiredSkillsCount: number;
  style?: StyleProp<ViewStyle>;
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressBar: { flex: 1, borderRadius: 0, height: 6 },
  parentRowContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  marginRight: { marginRight: Spacings.Unit * 1.5 },
});

export const TrainingSessionSkillsProgress: React.FC<
  TrainingSessionSkillsProgressProps
> = props => {
  const { validSkillsCount, requiredSkillsCount } = props;

  let JSXFragment: React.ReactFragment = <></>;

  JSXFragment = (
    <>
      <View style={[style.parentRowContainer, { backgroundColor: 'red' }]}>
        <ProgressBar
          percentage={(validSkillsCount / requiredSkillsCount) * 100}
          style={[style.progressBar, isMobilePlatform ? style.marginRight : {}]}
          progressBarStyle={{ borderRadius: 0 }}
        />
      </View>
    </>
  );

  return <View style={[style.container, props.style]}>{JSXFragment}</View>;
};
