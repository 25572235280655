import React from 'react';
import { Platform, StyleProp, ViewStyle } from 'react-native';
import { DeleteButton, TextButton } from 'shared/ui-component/Button';
import { t } from 'shared/localisation/i18n';
import { ModalUtils } from 'shared/ui-component/Modal';
import style from './styles';

interface Props {
  styles?: StyleProp<ViewStyle>;
}

export const DeleteAccount: React.FC<Props> = props => {
  const { styles } = props;
  const modal = ModalUtils.useModal();

  function handleSuccessUserDeletion() {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('common:userManagement.userPanel.deleteUserForm.successDeletion'),
        warningAcceptButton: t('common:button.ok'),
      }),
    );
  }

  function handleDeleteReconfirmation() {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('common:userManagement.userPanel.deleteUserForm.reconfirmation'),
        warningAcceptButton: t('common:userManagement.userPanel.deleteUserForm.submitButtonLabel'),
        warningCancelButton: t('common:userManagement.userPanel.deleteUserForm.cancelButtonLabel'),
        warningAcceptCallback: handleSuccessUserDeletion,
        buttonContainerStyle: style.warningModalbuttonContainer,
        buttonStyle: style.buttonStyle,
      }),
    );
  }

  function showInitialDeleteConfirmation() {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('common:userManagement.userPanel.deleteUserForm.initialConfirmation'),
        warningAcceptButton: t('common:userManagement.userPanel.deleteUserForm.submitButtonLabel'),
        warningCancelButton: t('common:userManagement.userPanel.deleteUserForm.cancelButtonLabel'),
        warningAcceptCallback: handleDeleteReconfirmation,
        buttonContainerStyle: style.warningModalbuttonContainer,
        buttonStyle: style.buttonStyle,
        needInputArea: true,
      }),
    );
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <TextButton
          text={t('common:userManagement.userPanel.deleteUserForm.deleteAccount')}
          onPress={showInitialDeleteConfirmation}
          containerStyle={styles}
        />
      ) : (
        <DeleteButton
          text={t('common:userManagement.userPanel.deleteUserForm.deleteAccount')}
          onPress={showInitialDeleteConfirmation}
          style={styles}
        />
      )}
    </>
  );
};
