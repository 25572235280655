import React from 'react';
import { View, ViewProps } from 'react-native';
import Style from './Style';

interface Props extends ViewProps {
  color?: string;
  ref?: React.Ref<View>;
}

export const Card: React.FC<Props> = React.forwardRef((props, ref) => {
  const { color = '#FFFFFF', style, ...otherProps } = props;

  return (
    <View
      ref={ref}
      style={[Style.CardContainer, { backgroundColor: color }, style]}
      {...otherProps}
    />
  );
});
