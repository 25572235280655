/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../api-auto";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getFactory = /* GraphQL */ `query GetFactory($pk: ID!, $sk: ID!) {
  getFactory(pk: $pk, sk: $sk) {
    pk
    sk
    dataType
    data
    createdAt
    owner
    updatedAt
    updatedBy
    _deleted
    _version
    _lastChangedAt
    tagSearch
    tenant {
      id
      name
      domain
      authenticationTypes
      logo
      glossary
      workstationTargetsStartingAtLevel
      workerFamilyNameFirst
      isDisableLostLevelAlert
      apps {
        id
        features {
          limit
          feature
          __typename
        }
        __typename
      }
      levelIconType
      __typename
    }
    tenantID {
      id
      __typename
    }
    app {
      id
      name
      logo
      version
      playStoreVersion
      appleStoreVersion
      __typename
    }
    role {
      id
      name
      permissions
      __typename
    }
    contractType {
      id
      name
      openEndDate
      isPauseContract
      __typename
    }
    organizationalUnit {
      id
      parentId
      name
      pathIds
      order
      shiftIds
      description
      thirdPartyIds
      __typename
    }
    workstation {
      id
      name
      parentId
      pathIds
      description
      order
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      shiftIds
      workersTargetOnShift
      __typename
    }
    shift {
      id
      parentId
      name
      color
      __typename
    }
    workerWorkstation {
      id
      workstationId
      workerId
      isTrainAuto
      warning
      targetLevel
      previousLevel
      activeTrainingSessions {
        workstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        nonWorkstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        isEverySkillToRenewOrAcquireCovered
        __typename
      }
      level
      numberOfRequiredSkills
      numberOfOkWorkerSkills
      validSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      validExpireSoonSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidExpiredSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidNoRefreshSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidMissingSkills {
        skillId
        requirmentIds
        __typename
      }
      __typename
    }
    worker {
      id
      userId
      email
      phone
      profilePicture
      matricule
      name
      firstName
      familyName
      tagIds
      contracts {
        contractTypeId
        startDate
        endDate
        comment
        __typename
      }
      description
      state
      isAdmin
      scope
      lastLogin
      __typename
    }
    workerTag {
      id
      name
      __typename
    }
    skill {
      id
      name
      isPractical
      description
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      skillIds
      tagIds
      validityDuration
      expiryNoticeDuration
      __typename
    }
    skillTag {
      id
      name
      __typename
    }
    training {
      id
      name
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      description
      notes
      tagIds
      defaultTrainerId
      maxTrainee
      __typename
    }
    trainingTag {
      id
      name
      __typename
    }
    trainingVersion {
      id
      version
      trainingId
      skillIds
      durationInMin
      __typename
    }
    trainingSession {
      id
      trainingVersionId
      requirementId
      originId
      requestState
      isDraft
      description
      location
      scheduledTrainers {
        trainerId
        percentage
        __typename
      }
      scheduledTraineeIds
      scheduledStartDate
      durationInMin
      calendarEvent {
        id
        sequence
        __typename
      }
      trainers {
        trainerId
        percentage
        __typename
      }
      trainerComment
      trainerFiles {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      traineeIds
      startDate
      endDate
      endDateLimit
      __typename
    }
    proofBundle {
      id
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      startingDate
      review {
        state
        date
        workerId
        __typename
      }
      description
      acquired
      originObjectId
      workerId
      skillId
      __typename
    }
    requirement {
      id
      linkedObjectId
      level
      skillTrainingVersions {
        skillId
        trainingVersionId
        __typename
      }
      practicalTrainingId
      __typename
    }
    workerSkill {
      id
      skillId
      workerId
      proofBundleIds
      activeProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      toReviewProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      validity
      __typename
    }
    workerTrainingSession {
      id
      trainingSessionId
      workerId
      __typename
    }
    userPreference {
      id
      userId
      data
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFactoryQueryVariables,
  APITypes.GetFactoryQuery
>;
export const listFactorys = /* GraphQL */ `query ListFactorys(
  $pk: ID
  $sk: ModelIDKeyConditionInput
  $filter: ModelFactoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listFactorys(
    pk: $pk
    sk: $sk
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      pk
      sk
      dataType
      data
      createdAt
      owner
      updatedAt
      updatedBy
      _deleted
      _version
      _lastChangedAt
      tagSearch
      tenant {
        id
        name
        domain
        authenticationTypes
        logo
        glossary
        workstationTargetsStartingAtLevel
        workerFamilyNameFirst
        isDisableLostLevelAlert
        apps {
          id
          features {
            limit
            feature
            __typename
          }
          __typename
        }
        levelIconType
        __typename
      }
      tenantID {
        id
        __typename
      }
      app {
        id
        name
        logo
        version
        playStoreVersion
        appleStoreVersion
        __typename
      }
      role {
        id
        name
        permissions
        __typename
      }
      contractType {
        id
        name
        openEndDate
        isPauseContract
        __typename
      }
      organizationalUnit {
        id
        parentId
        name
        pathIds
        order
        shiftIds
        description
        thirdPartyIds
        __typename
      }
      workstation {
        id
        name
        parentId
        pathIds
        description
        order
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        shiftIds
        workersTargetOnShift
        __typename
      }
      shift {
        id
        parentId
        name
        color
        __typename
      }
      workerWorkstation {
        id
        workstationId
        workerId
        isTrainAuto
        warning
        targetLevel
        previousLevel
        activeTrainingSessions {
          workstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          nonWorkstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          isEverySkillToRenewOrAcquireCovered
          __typename
        }
        level
        numberOfRequiredSkills
        numberOfOkWorkerSkills
        validSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        validExpireSoonSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidExpiredSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidNoRefreshSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidMissingSkills {
          skillId
          requirmentIds
          __typename
        }
        __typename
      }
      worker {
        id
        userId
        email
        phone
        profilePicture
        matricule
        name
        firstName
        familyName
        tagIds
        contracts {
          contractTypeId
          startDate
          endDate
          comment
          __typename
        }
        description
        state
        isAdmin
        scope
        lastLogin
        __typename
      }
      workerTag {
        id
        name
        __typename
      }
      skill {
        id
        name
        isPractical
        description
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        skillIds
        tagIds
        validityDuration
        expiryNoticeDuration
        __typename
      }
      skillTag {
        id
        name
        __typename
      }
      training {
        id
        name
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        description
        notes
        tagIds
        defaultTrainerId
        maxTrainee
        __typename
      }
      trainingTag {
        id
        name
        __typename
      }
      trainingVersion {
        id
        version
        trainingId
        skillIds
        durationInMin
        __typename
      }
      trainingSession {
        id
        trainingVersionId
        requirementId
        originId
        requestState
        isDraft
        description
        location
        scheduledTrainers {
          trainerId
          percentage
          __typename
        }
        scheduledTraineeIds
        scheduledStartDate
        durationInMin
        calendarEvent {
          id
          sequence
          __typename
        }
        trainers {
          trainerId
          percentage
          __typename
        }
        trainerComment
        trainerFiles {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        traineeIds
        startDate
        endDate
        endDateLimit
        __typename
      }
      proofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      requirement {
        id
        linkedObjectId
        level
        skillTrainingVersions {
          skillId
          trainingVersionId
          __typename
        }
        practicalTrainingId
        __typename
      }
      workerSkill {
        id
        skillId
        workerId
        proofBundleIds
        activeProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        toReviewProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        validity
        __typename
      }
      workerTrainingSession {
        id
        trainingSessionId
        workerId
        __typename
      }
      userPreference {
        id
        userId
        data
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFactorysQueryVariables,
  APITypes.ListFactorysQuery
>;
export const itemsByType = /* GraphQL */ `query ItemsByType(
  $pk: ID
  $dataType: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFactoryFilterInput
  $limit: Int
  $nextToken: String
) {
  itemsByType(
    pk: $pk
    dataType: $dataType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      dataType
      data
      createdAt
      owner
      updatedAt
      updatedBy
      _deleted
      _version
      _lastChangedAt
      tagSearch
      tenant {
        id
        name
        domain
        authenticationTypes
        logo
        glossary
        workstationTargetsStartingAtLevel
        workerFamilyNameFirst
        isDisableLostLevelAlert
        apps {
          id
          features {
            limit
            feature
            __typename
          }
          __typename
        }
        levelIconType
        __typename
      }
      tenantID {
        id
        __typename
      }
      app {
        id
        name
        logo
        version
        playStoreVersion
        appleStoreVersion
        __typename
      }
      role {
        id
        name
        permissions
        __typename
      }
      contractType {
        id
        name
        openEndDate
        isPauseContract
        __typename
      }
      organizationalUnit {
        id
        parentId
        name
        pathIds
        order
        shiftIds
        description
        thirdPartyIds
        __typename
      }
      workstation {
        id
        name
        parentId
        pathIds
        description
        order
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        shiftIds
        workersTargetOnShift
        __typename
      }
      shift {
        id
        parentId
        name
        color
        __typename
      }
      workerWorkstation {
        id
        workstationId
        workerId
        isTrainAuto
        warning
        targetLevel
        previousLevel
        activeTrainingSessions {
          workstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          nonWorkstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          isEverySkillToRenewOrAcquireCovered
          __typename
        }
        level
        numberOfRequiredSkills
        numberOfOkWorkerSkills
        validSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        validExpireSoonSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidExpiredSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidNoRefreshSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidMissingSkills {
          skillId
          requirmentIds
          __typename
        }
        __typename
      }
      worker {
        id
        userId
        email
        phone
        profilePicture
        matricule
        name
        firstName
        familyName
        tagIds
        contracts {
          contractTypeId
          startDate
          endDate
          comment
          __typename
        }
        description
        state
        isAdmin
        scope
        lastLogin
        __typename
      }
      workerTag {
        id
        name
        __typename
      }
      skill {
        id
        name
        isPractical
        description
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        skillIds
        tagIds
        validityDuration
        expiryNoticeDuration
        __typename
      }
      skillTag {
        id
        name
        __typename
      }
      training {
        id
        name
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        description
        notes
        tagIds
        defaultTrainerId
        maxTrainee
        __typename
      }
      trainingTag {
        id
        name
        __typename
      }
      trainingVersion {
        id
        version
        trainingId
        skillIds
        durationInMin
        __typename
      }
      trainingSession {
        id
        trainingVersionId
        requirementId
        originId
        requestState
        isDraft
        description
        location
        scheduledTrainers {
          trainerId
          percentage
          __typename
        }
        scheduledTraineeIds
        scheduledStartDate
        durationInMin
        calendarEvent {
          id
          sequence
          __typename
        }
        trainers {
          trainerId
          percentage
          __typename
        }
        trainerComment
        trainerFiles {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        traineeIds
        startDate
        endDate
        endDateLimit
        __typename
      }
      proofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      requirement {
        id
        linkedObjectId
        level
        skillTrainingVersions {
          skillId
          trainingVersionId
          __typename
        }
        practicalTrainingId
        __typename
      }
      workerSkill {
        id
        skillId
        workerId
        proofBundleIds
        activeProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        toReviewProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        validity
        __typename
      }
      workerTrainingSession {
        id
        trainingSessionId
        workerId
        __typename
      }
      userPreference {
        id
        userId
        data
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemsByTypeQueryVariables,
  APITypes.ItemsByTypeQuery
>;
export const itemsByData = /* GraphQL */ `query ItemsByData(
  $pk: ID
  $data: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFactoryFilterInput
  $limit: Int
  $nextToken: String
) {
  itemsByData(
    pk: $pk
    data: $data
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      dataType
      data
      createdAt
      owner
      updatedAt
      updatedBy
      _deleted
      _version
      _lastChangedAt
      tagSearch
      tenant {
        id
        name
        domain
        authenticationTypes
        logo
        glossary
        workstationTargetsStartingAtLevel
        workerFamilyNameFirst
        isDisableLostLevelAlert
        apps {
          id
          features {
            limit
            feature
            __typename
          }
          __typename
        }
        levelIconType
        __typename
      }
      tenantID {
        id
        __typename
      }
      app {
        id
        name
        logo
        version
        playStoreVersion
        appleStoreVersion
        __typename
      }
      role {
        id
        name
        permissions
        __typename
      }
      contractType {
        id
        name
        openEndDate
        isPauseContract
        __typename
      }
      organizationalUnit {
        id
        parentId
        name
        pathIds
        order
        shiftIds
        description
        thirdPartyIds
        __typename
      }
      workstation {
        id
        name
        parentId
        pathIds
        description
        order
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        shiftIds
        workersTargetOnShift
        __typename
      }
      shift {
        id
        parentId
        name
        color
        __typename
      }
      workerWorkstation {
        id
        workstationId
        workerId
        isTrainAuto
        warning
        targetLevel
        previousLevel
        activeTrainingSessions {
          workstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          nonWorkstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          isEverySkillToRenewOrAcquireCovered
          __typename
        }
        level
        numberOfRequiredSkills
        numberOfOkWorkerSkills
        validSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        validExpireSoonSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidExpiredSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidNoRefreshSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidMissingSkills {
          skillId
          requirmentIds
          __typename
        }
        __typename
      }
      worker {
        id
        userId
        email
        phone
        profilePicture
        matricule
        name
        firstName
        familyName
        tagIds
        contracts {
          contractTypeId
          startDate
          endDate
          comment
          __typename
        }
        description
        state
        isAdmin
        scope
        lastLogin
        __typename
      }
      workerTag {
        id
        name
        __typename
      }
      skill {
        id
        name
        isPractical
        description
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        skillIds
        tagIds
        validityDuration
        expiryNoticeDuration
        __typename
      }
      skillTag {
        id
        name
        __typename
      }
      training {
        id
        name
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        description
        notes
        tagIds
        defaultTrainerId
        maxTrainee
        __typename
      }
      trainingTag {
        id
        name
        __typename
      }
      trainingVersion {
        id
        version
        trainingId
        skillIds
        durationInMin
        __typename
      }
      trainingSession {
        id
        trainingVersionId
        requirementId
        originId
        requestState
        isDraft
        description
        location
        scheduledTrainers {
          trainerId
          percentage
          __typename
        }
        scheduledTraineeIds
        scheduledStartDate
        durationInMin
        calendarEvent {
          id
          sequence
          __typename
        }
        trainers {
          trainerId
          percentage
          __typename
        }
        trainerComment
        trainerFiles {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        traineeIds
        startDate
        endDate
        endDateLimit
        __typename
      }
      proofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      requirement {
        id
        linkedObjectId
        level
        skillTrainingVersions {
          skillId
          trainingVersionId
          __typename
        }
        practicalTrainingId
        __typename
      }
      workerSkill {
        id
        skillId
        workerId
        proofBundleIds
        activeProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        toReviewProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        validity
        __typename
      }
      workerTrainingSession {
        id
        trainingSessionId
        workerId
        __typename
      }
      userPreference {
        id
        userId
        data
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemsByDataQueryVariables,
  APITypes.ItemsByDataQuery
>;
export const allFactoriesByType = /* GraphQL */ `query AllFactoriesByType(
  $dataType: DataType!
  $limit: Int
  $nextToken: String
) {
  allFactoriesByType(
    dataType: $dataType
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      dataType
      data
      createdAt
      owner
      updatedAt
      updatedBy
      _deleted
      _version
      _lastChangedAt
      tagSearch
      tenant {
        id
        name
        domain
        authenticationTypes
        logo
        glossary
        workstationTargetsStartingAtLevel
        workerFamilyNameFirst
        isDisableLostLevelAlert
        apps {
          id
          features {
            limit
            feature
            __typename
          }
          __typename
        }
        levelIconType
        __typename
      }
      tenantID {
        id
        __typename
      }
      app {
        id
        name
        logo
        version
        playStoreVersion
        appleStoreVersion
        __typename
      }
      role {
        id
        name
        permissions
        __typename
      }
      contractType {
        id
        name
        openEndDate
        isPauseContract
        __typename
      }
      organizationalUnit {
        id
        parentId
        name
        pathIds
        order
        shiftIds
        description
        thirdPartyIds
        __typename
      }
      workstation {
        id
        name
        parentId
        pathIds
        description
        order
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        shiftIds
        workersTargetOnShift
        __typename
      }
      shift {
        id
        parentId
        name
        color
        __typename
      }
      workerWorkstation {
        id
        workstationId
        workerId
        isTrainAuto
        warning
        targetLevel
        previousLevel
        activeTrainingSessions {
          workstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          nonWorkstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          isEverySkillToRenewOrAcquireCovered
          __typename
        }
        level
        numberOfRequiredSkills
        numberOfOkWorkerSkills
        validSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        validExpireSoonSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidExpiredSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidNoRefreshSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidMissingSkills {
          skillId
          requirmentIds
          __typename
        }
        __typename
      }
      worker {
        id
        userId
        email
        phone
        profilePicture
        matricule
        name
        firstName
        familyName
        tagIds
        contracts {
          contractTypeId
          startDate
          endDate
          comment
          __typename
        }
        description
        state
        isAdmin
        scope
        lastLogin
        __typename
      }
      workerTag {
        id
        name
        __typename
      }
      skill {
        id
        name
        isPractical
        description
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        skillIds
        tagIds
        validityDuration
        expiryNoticeDuration
        __typename
      }
      skillTag {
        id
        name
        __typename
      }
      training {
        id
        name
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        description
        notes
        tagIds
        defaultTrainerId
        maxTrainee
        __typename
      }
      trainingTag {
        id
        name
        __typename
      }
      trainingVersion {
        id
        version
        trainingId
        skillIds
        durationInMin
        __typename
      }
      trainingSession {
        id
        trainingVersionId
        requirementId
        originId
        requestState
        isDraft
        description
        location
        scheduledTrainers {
          trainerId
          percentage
          __typename
        }
        scheduledTraineeIds
        scheduledStartDate
        durationInMin
        calendarEvent {
          id
          sequence
          __typename
        }
        trainers {
          trainerId
          percentage
          __typename
        }
        trainerComment
        trainerFiles {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        traineeIds
        startDate
        endDate
        endDateLimit
        __typename
      }
      proofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      requirement {
        id
        linkedObjectId
        level
        skillTrainingVersions {
          skillId
          trainingVersionId
          __typename
        }
        practicalTrainingId
        __typename
      }
      workerSkill {
        id
        skillId
        workerId
        proofBundleIds
        activeProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        toReviewProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        validity
        __typename
      }
      workerTrainingSession {
        id
        trainingSessionId
        workerId
        __typename
      }
      userPreference {
        id
        userId
        data
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AllFactoriesByTypeQueryVariables,
  APITypes.AllFactoriesByTypeQuery
>;
export const syncFactories = /* GraphQL */ `query SyncFactories(
  $pk: ID!
  $lastSync: AWSTimestamp!
  $limit: Int
  $nextToken: String
) {
  syncFactories(
    pk: $pk
    lastSync: $lastSync
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      dataType
      data
      createdAt
      owner
      updatedAt
      updatedBy
      _deleted
      _version
      _lastChangedAt
      tagSearch
      tenant {
        id
        name
        domain
        authenticationTypes
        logo
        glossary
        workstationTargetsStartingAtLevel
        workerFamilyNameFirst
        isDisableLostLevelAlert
        apps {
          id
          features {
            limit
            feature
            __typename
          }
          __typename
        }
        levelIconType
        __typename
      }
      tenantID {
        id
        __typename
      }
      app {
        id
        name
        logo
        version
        playStoreVersion
        appleStoreVersion
        __typename
      }
      role {
        id
        name
        permissions
        __typename
      }
      contractType {
        id
        name
        openEndDate
        isPauseContract
        __typename
      }
      organizationalUnit {
        id
        parentId
        name
        pathIds
        order
        shiftIds
        description
        thirdPartyIds
        __typename
      }
      workstation {
        id
        name
        parentId
        pathIds
        description
        order
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        shiftIds
        workersTargetOnShift
        __typename
      }
      shift {
        id
        parentId
        name
        color
        __typename
      }
      workerWorkstation {
        id
        workstationId
        workerId
        isTrainAuto
        warning
        targetLevel
        previousLevel
        activeTrainingSessions {
          workstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          nonWorkstationActiveTrainingSessions {
            lowerOrEqualToTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            greaterThanTarget {
              fromNonInheritedRequirements
              fromInheritedRequirements
              __typename
            }
            __typename
          }
          isEverySkillToRenewOrAcquireCovered
          __typename
        }
        level
        numberOfRequiredSkills
        numberOfOkWorkerSkills
        validSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        validExpireSoonSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidExpiredSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidNoRefreshSkills {
          workerSkillId
          requirmentIds
          __typename
        }
        invalidMissingSkills {
          skillId
          requirmentIds
          __typename
        }
        __typename
      }
      worker {
        id
        userId
        email
        phone
        profilePicture
        matricule
        name
        firstName
        familyName
        tagIds
        contracts {
          contractTypeId
          startDate
          endDate
          comment
          __typename
        }
        description
        state
        isAdmin
        scope
        lastLogin
        __typename
      }
      workerTag {
        id
        name
        __typename
      }
      skill {
        id
        name
        isPractical
        description
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        skillIds
        tagIds
        validityDuration
        expiryNoticeDuration
        __typename
      }
      skillTag {
        id
        name
        __typename
      }
      training {
        id
        name
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        description
        notes
        tagIds
        defaultTrainerId
        maxTrainee
        __typename
      }
      trainingTag {
        id
        name
        __typename
      }
      trainingVersion {
        id
        version
        trainingId
        skillIds
        durationInMin
        __typename
      }
      trainingSession {
        id
        trainingVersionId
        requirementId
        originId
        requestState
        isDraft
        description
        location
        scheduledTrainers {
          trainerId
          percentage
          __typename
        }
        scheduledTraineeIds
        scheduledStartDate
        durationInMin
        calendarEvent {
          id
          sequence
          __typename
        }
        trainers {
          trainerId
          percentage
          __typename
        }
        trainerComment
        trainerFiles {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        traineeIds
        startDate
        endDate
        endDateLimit
        __typename
      }
      proofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      requirement {
        id
        linkedObjectId
        level
        skillTrainingVersions {
          skillId
          trainingVersionId
          __typename
        }
        practicalTrainingId
        __typename
      }
      workerSkill {
        id
        skillId
        workerId
        proofBundleIds
        activeProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        toReviewProofBundle {
          id
          files {
            bucket
            region
            key
            fileName
            visibility
            owner
            __typename
          }
          startingDate
          review {
            state
            date
            workerId
            __typename
          }
          description
          acquired
          originObjectId
          workerId
          skillId
          __typename
        }
        validity
        __typename
      }
      workerTrainingSession {
        id
        trainingSessionId
        workerId
        __typename
      }
      userPreference {
        id
        userId
        data
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFactoriesQueryVariables,
  APITypes.SyncFactoriesQuery
>;
export const getFilePreSignedUrl = /* GraphQL */ `query GetFilePreSignedUrl($pk: ID!, $key: String!) {
  getFilePreSignedUrl(pk: $pk, key: $key)
}
` as GeneratedQuery<
  APITypes.GetFilePreSignedUrlQueryVariables,
  APITypes.GetFilePreSignedUrlQuery
>;
