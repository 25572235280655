import React, { useState, useRef, useEffect } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Animated,
  Platform,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { TextInput as TextInputWeb } from 'react-native-web';
import SearchIcon from 'shared/assets/svg/icon.search.mobile.svg';
import * as _ from 'lodash-es';
import { TextFontStyles, Colors, Spacings, getCorrectedSpacings, fontSize } from 'shared/styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';

interface InputSearchProps {
  placeHolder?: string;
  onInputTextChange: (text: string) => void;
  inputStyle?: StyleProp<ViewStyle>;
  autoFocus?: boolean;
  textFieldStyle?: StyleProp<ViewStyle>;
  onPressClearButton?: () => void;
}

const HEIGHT = getCorrectedSpacings(48);
const closeIcon = require('shared/assets/svg/icon.close.svg').default;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    backgroundColor: Colors.Background,
    height: HEIGHT,
    borderRadius: HEIGHT / 2,
    paddingHorizontal: Spacings.xMedium,
  },
  leftImage: {},
  textContainer: { flex: 1, marginLeft: 9 },
  textField: {
    
    ...Platform.select({ web: { outlineColor: Colors.Background } }),
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    width: '100%',
    flex: 1,
  },
  rightImage: {},
});

export const InputSearch: React.FC<InputSearchProps> = props => {
  const {
    onInputTextChange,
    placeHolder,
    inputStyle,
    autoFocus = false,
    onPressClearButton,
  } = props;

  const [editing, setEditing] = useState(false);
  const inputRef = useRef<TextInput>(null);

  
  const editValue = useRef(new Animated.Value(0));
  const searchIconWidth = editValue.current.interpolate({
    inputRange: [0, 1],
    outputRange: [24, 0],
  });
  const searchIconOpacity = editValue.current.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  });
  const clearIconWidth = editValue.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 24],
  });

  const placeHolderText = placeHolder ?? t('common:button.search');

  
  const onEdit = () => {
    setEditing(true);
  };

  
  const onBlur = () => {
    setEditing(false);
  };

  
  const onEndEditing = () => {
    inputRef.current?.blur();
  };

  const clearSearch = () => {
    inputRef.current?.clear();
    onPressClearButton && onPressClearButton();
  };

  useEffect(() => {
    
    editValue.current.setValue(editing ? 0 : 1);

    Animated.timing(editValue.current, {
      toValue: editing ? 1 : 0,
      duration: 200,
      useNativeDriver: false, 
    }).start();
  }, [editing]);

  return (
    <Animated.View style={[styles.container, inputStyle]}>
      <Animated.View
        style={{
          width: searchIconWidth,
          opacity: searchIconOpacity,
        }}
      >
        <IconSVG svgComponent={SearchIcon} color={Colors.Black} />
      </Animated.View>
      <Animated.View style={styles.textContainer}>
        {Platform.OS === 'web' ? (
          <TextInputWeb
            ref={inputRef}
            style={[styles.textField, { outline: 'none' }, props.textFieldStyle]}
            autoCapitalize={'none'}
            autoCorrect={false}
            onChangeText={onInputTextChange}
            onFocus={onEdit}
            onEndEditing={onEndEditing}
            onBlur={onBlur}
            placeholder={placeHolderText}
            placeholderTextColor={Colors.Grey}
            returnKeyType={'search'}
            autoFocus={autoFocus}
          />
        ) : (
          <TextInput
            ref={inputRef}
            style={[styles.textField, props.textFieldStyle]}
            autoCapitalize={'none'}
            autoCorrect={false}
            onChangeText={onInputTextChange}
            onFocus={onEdit}
            onEndEditing={onEndEditing}
            onBlur={onBlur}
            placeholder={placeHolderText}
            placeholderTextColor={Colors.Grey}
            returnKeyType={'search'}
            autoFocus={autoFocus}
          />
        )}
      </Animated.View>
      <Animated.View
        style={{
          width: clearIconWidth,
          opacity: editValue.current,
        }}
      >
        <TouchableOpacity onPress={clearSearch}>
          <IconSVG svgComponent={closeIcon} />
        </TouchableOpacity>
      </Animated.View>
    </Animated.View>
  );
};
