import { StyleSheet } from 'react-native';
import { Colors, fontSize, Spacings, TextFontStyles } from 'shared/styles';

const styles = StyleSheet.create({
  iconContainer: {
    height: 76,
    width: 76,
    borderRadius: 76 / 2,
    backgroundColor: Colors.Yellow,
    alignItems: 'center',
    justifyContent: 'center',
  },

  subHeaderComponent: {
    flexDirection: 'row',
    marginTop: Spacings.Medium,
  },

  contentContainer: {
    height: '100%',
  },

  contentItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 27,
    alignItems: 'flex-end',
  },

  count: {
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    fontSize: fontSize.XLarge,
  },

  contentTitle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Small,
  },

  contentSubTitle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },

  operationalSubTitle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },

  SkillfulnessContainer: {
    marginBottom: Spacings.Standard,
  },

  workersContainer: {
    marginTop: Spacings.Large,
  },
  bottomButtonStyle: {
    backgroundColor: Colors.Yellow,
    height: 32,
    paddingHorizontal: Spacings.Unit * 8,
    width: '100%',
    marginTop: Spacings.Standard,
  },
  bottomButtonTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.White,
  },
});

export default styles;
