import * as API from 'shared/backend-data';
import React, { useState, createContext, useEffect } from 'react';
import { MyHub } from 'shared/util/MyHub';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { WorkstationWorkerLevels } from 'shared/util/WorkerLevel';
import { Feature, LevelIconType } from 'backend/src/api/api-auto';
import { AppContext } from '../../context/AppContext';

export const GlobalDataContext = createContext<{
  levelIconType: LevelIconType;
  workstationTargetsStartingAtLevel: WorkstationWorkerLevels;
  features: Feature[];
  isWorkerLastNameFirst: boolean;
  hideMenuFactory: boolean;
  dashboardWorkersSortDirection?: API.SortDirection;

  setLevelIconType: React.Dispatch<React.SetStateAction<LevelIconType>>;
  setHideMenuFactory: React.Dispatch<React.SetStateAction<boolean>>;
  setDashboardWorkersSortDirection: React.Dispatch<React.SetStateAction<API.SortDirection>>;
}>({
  levelIconType: LevelIconType.LINE,
  workstationTargetsStartingAtLevel: WorkstationWorkerLevels.LEVEL3,
  features: [],
  isWorkerLastNameFirst: false,
  hideMenuFactory: false,

  setLevelIconType: () => {},
  setHideMenuFactory: () => {},
  setDashboardWorkersSortDirection: () => {},
});



export const GlobalDataProvider: React.FC = props => {
  const [workstationTargetsStartingAtLevel, setWorkstationTargetsStartingAtLevel] =
    useState<WorkstationWorkerLevels>(WorkstationWorkerLevels.LEVEL3);
  const [levelIconType, setLevelIconType] = useState<LevelIconType>(LevelIconType.LINE);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [isWorkerLastNameFirst, setIsWorkerLastNameFirst] = useState<boolean>(false);
  const [hideMenuFactory, setHideMenuFactory] = useState(false);
  const [dashboardWorkersSortDirection, setDashboardWorkersSortDirection] =
    useState<API.SortDirection>(API.SortDirection.asc);

  const isMounted = useIsMounted();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      switch (data.factory.dataType) {
        case API.DataType.TENANT:
          _getTenant();
          checkFeatures();
      }
    });

    checkFeatures();

    return () => {
      removeListener();
    };
  }, []);

  async function checkFeatures() {
    const tenant = await API.getTenant();
    if (API.isFailure(tenant)) {
      logger.warn('features cannot be set. Reason: ' + tenant.message, tenant);
      return;
    }

    if (tenant.levelIconType) {
      setLevelIconType(tenant.levelIconType);
    }

    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) {
      logger.warn('features cannot be set. Reason: ' + appContext.message, appContext);
      return;
    }

    let _features: Feature[] = [];
    for (const app of tenant.apps) {
      if (appContext.pkType === API.PkType.TenantApp) {
        if (
          appContext.pk ===
          tenant.id + API.SeparatorIds + (API.DataType.APP + API.SeparatorDataType + app.id)
        ) {
          _features = app.features.map(f => f);
          break;
        }
      }
    }

    setFeatures(_features);
  }

  
  async function _getTenant() {
    const tenant = await API.getTenant();
    if (!isMounted.current) return;
    if (API.isFailure(tenant)) {
      logger.warn(tenant);
      return;
    }
    setWorkstationTargetsStartingAtLevel(
      API.api2workstationWorkerLevels(tenant.workstationTargetsStartingAtLevel),
    );
    if (tenant.workerFamilyNameFirst) setIsWorkerLastNameFirst(true);
    else setIsWorkerLastNameFirst(false);
  }

  return (
    <GlobalDataContext.Provider
      value={{
        levelIconType: levelIconType,
        workstationTargetsStartingAtLevel,
        features,
        isWorkerLastNameFirst,
        hideMenuFactory,
        dashboardWorkersSortDirection,

        setLevelIconType,
        setHideMenuFactory,
        setDashboardWorkersSortDirection,
      }}
    >
      {props.children}
    </GlobalDataContext.Provider>
  );
};
