import i18n, {
  ResourceLanguage,
  StringMap,
  TFunctionKeys,
  TFunctionResult,
  TOptions,
} from 'i18next';
export { i18n };
import * as API from 'shared/backend-data';
import moment from 'moment';
import logger from '../util/Logger';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import * as _ from 'lodash-es';
import { Locale } from 'shared/backend-data';
import { Platform } from 'react-native'; 

export type { Locale } from 'shared/backend-data';


export const languages: Languages = {
  fr: {
    locale: 'fr',
    label: 'Français',
    defaultGlossary: glossaryFr,
  },
  en: {
    locale: 'en',
    label: 'English',
    defaultGlossary: glossaryEn,
  },
  es: {
    locale: 'es',
    label: 'Español',
    defaultGlossary: glossaryEs,
  },
  de: {
    locale: 'de',
    label: 'Deutsch',
    defaultGlossary: glossaryDe,
  },
};
import commonFr from './translation/fr/Common.json';
import commonEn from './translation/en/Common.json';
import commonEs from './translation/es/Common.json';
import commonDe from './translation/de/Common.json';

import glossaryFr from './translation/fr/Glossary.json';
import glossaryEn from './translation/en/Glossary.json';
import glossaryEs from './translation/es/Glossary.json';
import glossaryDe from './translation/de/Glossary.json';

import alexFr from './translation/fr/Alex.json';
import alexEn from './translation/en/Alex.json';
import alexEs from './translation/es/Alex.json';
import alexDe from './translation/de/Alex.json';


import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
registerLocale(languages.fr.locale, fr);
registerLocale(languages.en.locale, en);
registerLocale(languages.es.locale, es);
registerLocale(languages.de.locale, de);


import dayjs from 'dayjs'; 
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/de';





import 'moment/locale/fr';

import 'moment/locale/es';
import 'moment/locale/de';


const longDateFormat = {
  LT: 'h:mm A',
  LTS: 'h:mm:ss A',
  L: 'DD.MM.YYYY',
  l: 'D.M.YYYY',
  LL: 'MMMM Do YYYY',
  ll: 'MMM D YYYY',
  LLL: 'MMMM Do YYYY LT',
  lll: 'MMM D YYYY LT',
  LLLL: 'dddd, MMMM Do YYYY LT',
  llll: 'ddd, MMM D YYYY LT',
};
moment.updateLocale(languages.fr.locale, {
  longDateFormat,
});
moment.updateLocale(languages.en.locale, {
  longDateFormat,
});
moment.updateLocale(languages.es.locale, {
  longDateFormat,
});
moment.updateLocale(languages.de.locale, {
  longDateFormat,
});

export type Language = {
  locale: Locale;
  label: string;
  defaultGlossary: Glossary;
};
export type Languages = {
  [index in Locale]: Language;
};
export type LanguageGlossaries = {
  [locale in Locale]: Glossary;
};
interface Glossary {
  [index: string]: string;
}
type Resources = {
  [locale in Locale]: ResourceLanguage;
};


const resources: Resources = {
  fr: {
    common: commonFr,
    alex: alexFr,
  },
  en: {
    common: commonEn,
    alex: alexEn,
  },
  es: {
    common: commonEs,
    alex: alexEs,
  },
  de: {
    common: commonDe,
    alex: alexDe,
  },
};

const fallBackLanguage = languages.en.locale;

/**
 * Set the language and re load the glossary
 * @param language (optional) if not set the previously set language is used
 */
export async function setLanguageAndLoadGlossary(language: Language = getLanguage()) {
  
  dayjs.locale(language.locale);

  
  moment.locale(language.locale);

  
  setDefaultLocale(language.locale);

  
  const glossaries = await getLanguageGlossaries();

  i18n.addResourceBundle(language.locale, 'glossary', glossaries[language.locale], true, true);

  
  i18n.changeLanguage(language.locale);

  
  if (Platform.OS === 'web' && typeof document !== 'undefined') {
    const htmlTag = document.getElementsByTagName('html')[0]; 
    htmlTag.lang = language.locale;
  }
}

/**
 * Return the Language matching the given Locale
 * @param locale (optional) if not set, returns the current Locale
 */
export function getLanguage(locale?: Locale): Language {
  if (!locale) {
    locale = i18n.language as Locale; 
  }

  return languages[locale];
}

function detectLocale(): Locale | undefined {
  let locale: Locale | undefined = undefined;

  if (typeof navigator !== 'undefined') {
    const currentLanguige = _.find(languages, language => {
      if (!navigator.language) return false;

      return navigator.language.includes(language.locale);
    });
    locale = currentLanguige?.locale;
  }

  return locale;
}

i18n
  .init(
    {
      debug: false,
      resources,
      lng: detectLocale() ?? fallBackLanguage,
      fallbackLng: fallBackLanguage, 
      interpolation: {
        escapeValue: false, 
        format: function (value: any, format, _ ) {
          if (typeof value === 'string') {
            if (format === 'lowercase') {
              return value.toLowerCase();
            } else if (format === 'uppercase') {
              return value.toUpperCase();
            } else if (format === 'capitalizeFirstLetter') {
              return capitalizeFirstLetter(value);
            } else if (format === 'capitalize') {
              return capitalize(value);
            }
          }
          if (value instanceof Date) {
            return moment(value).format(format);
          }
          return value;
        },
      },
      returnObjects: true,
    },
    (err, t) => {
      if (err) {
        logger.error('Translations were not loaded', err);
        return;
      }
      logger.debug('Translations are ready');
    },
  )
  .then(() => {
    setLanguageAndLoadGlossary();
  });

/**
 * Get the Tenant's glossaries, fallback to the default glossaries
 */
export async function getLanguageGlossaries(): Promise<LanguageGlossaries> {
  const glossaries: LanguageGlossaries = _.mapValues(
    _.keyBy(languages, language => language.locale) as Languages, 
    language => language.defaultGlossary,
  );

  const tenant = await API.getTenant();
  if (API.isFailure(tenant)) {
    logger.debug('fetching tenant error', tenant);
  } else {
    if (tenant.glossary) {
      const diffGlossaries = JSON.parse(tenant.glossary);
      _.merge(glossaries, diffGlossaries);
    }
  }

  return glossaries;
}

export async function updateLanguageGlossaries(
  glossaries: LanguageGlossaries,
): Promise<API.Result<API.Tenant>> {
  const tenant = await API.getTenant();
  if (API.isFailure(tenant)) return tenant;

  const _tenant = API.deepClone(tenant);

  const glossariesJsonString = JSON.stringify(glossaries);
  _tenant.glossary = glossariesJsonString;

  return API.updateTenant(_tenant);
}

/**
 * Translate key: see i18next.t() for documentation
 * @param key
 * @param options (Optional)
 * @param capitalizeFirstLetter_ (Optional, default: true)
 * @param defaultValue (Optional)
 * @returns
 */
export function t<
  TResult extends TFunctionResult = string,
  TKeys extends TFunctionKeys = string,
  TInterpolationMap extends object = StringMap,
>(
  key: TKeys | TKeys[],
  options?: TOptions<TInterpolationMap> | string,
  capitalizeFirstLetter_ = true,
  defaultValue?: string,
): TResult {
  const s = i18n.t(key, defaultValue, options) as TResult;

  if (capitalizeFirstLetter_ && _.isString(s)) {
    return capitalizeFirstLetter(s as string) as TResult;
  }
  return s;
}

export function capitalizeFirstLetter(value: string): string {
  return (value.charAt(0).toUpperCase() + value.slice(1)).trim();
}

export function capitalize(value: string): string {
  return value
    .split(' ')
    .map(word => {
      return capitalizeFirstLetter(word);
    })
    .join(' ');
}

export function getGender(glossaryKey: string) {
  switch (glossaryKey) {
    case 'skill':
      return 'f';
  }
}

export function getCountryDefualtCode() {
  const language = getLanguage();

  switch (language.locale) {
    case 'en':
      return '+1';
    case 'fr':
      return '+33';
    case 'de':
      return '+41';
    case 'es':
      return '+34';
  }
}
