import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import * as API from 'shared/backend-data';
import { AppContext } from 'shared/context/AppContext';
import { InteractionManager } from 'react-native';
import { useIsMounted } from 'shared/hooks/IsMounted';
import Styles from './Style';
import { IconSVG } from 'shared/ui-component/Icon';
import alexIcon from 'shared/assets/svg/icon.alex.svg';
import { Colors } from 'shared/styles/Colors';
import { YellowButton } from 'shared/ui-component/Button';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { UserContext } from 'shared/context/UserContext';
import logger from '../../../util/Logger';
import { InputsValue, useCustomForm } from 'shared/hooks/CustomForm';
import { Loader } from '../../../ui-component/Loader/Loader';

enum InputIds {
  UserSelectedTenants = 'UserSelectedTenants',
}

interface Props {
  userTenantApps: API.Factory<API.DataType.WORKER_TENANT_APP>[];
  isWebPlatfrom: boolean;
}

export interface TenantAppDropDownOption extends DropDownOption {
  value: API.Factory<API.DataType.WORKER_TENANT_APP>;
}

export const TenantSelect: React.FC<Props> = props => {
  const [options, setOptions] = useState<TenantAppDropDownOption[]>([]);
  const [loader, setLoader] = useState<boolean>();
  const isMounted = useIsMounted();

  const user = UserContext.getUser();
  const firstName = API.isFailure(user) ? '' : user.userAttributes.given_name ?? '';

  const { values, enableFormSubmit, onChange, onSubmit } = useCustomForm<
    TenantAppDropDownOption | undefined
  >(
    {
      [InputIds.UserSelectedTenants]: {
        validator: inputValidator,
        isFieldMandatory: true,
      },
    },
    onSubmitForm,
  );

  async function onSubmitForm(values: InputsValue<TenantAppDropDownOption | undefined>) {
    const selectedOption = values[InputIds.UserSelectedTenants];
    if (selectedOption) handleDropDownInputs(selectedOption);
  }

  function inputValidator(inputValue: TenantAppDropDownOption | null | undefined) {
    if (!inputValue) return false;
    return true;
  }

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (isMounted.current) {
        setOptions(
          props.userTenantApps.map(workerTenantApp => {
            return {
              value: workerTenantApp,
              key: workerTenantApp.tenant.id + API.SeparatorIds + workerTenantApp.app.id,
              label:
                capitalizeFirstLetter(workerTenantApp.tenant.name) +
                ' - ' +
                capitalizeFirstLetter(workerTenantApp.app.name),
            };
          }),
        );
      }
    });
  }, [props.userTenantApps]);

  const handleDropDownInputs = async (option: TenantAppDropDownOption) => {
    setLoader(true);
    const result = await AppContext.setContext(option.key);
    if (API.isFailure(result)) logger.error(result);
    setLoader(false);
  };

  return (
    <View style={Styles.rootContainer}>
      {props.isWebPlatfrom && (
        <View style={Styles.topContainer}>
          <IconSVG svgComponent={alexIcon} size={{ height: 40, width: 40 }} color={Colors.Yellow} />
        </View>
      )}
      <View style={Styles.middleContainer}>
        <Text style={Styles.boldBodytext}>
          {t('common:auth.selectTenantApp.header', { name: firstName })}
        </Text>
        <Text style={Styles.bodyText}>{t('common:auth.selectTenantApp.instruction')}</Text>
      </View>
      <DropDownSingleSelection
        inputId={InputIds.UserSelectedTenants}
        placeholder={t('common:auth.selectTenantApp.placeholder')}
        options={options}
        value={values[InputIds.UserSelectedTenants] ?? undefined}
        handleChange={onChange}
      />

      <YellowButton
        style={enableFormSubmit ? Styles.connectButton : Styles.connectButtonDisabled}
        onPress={onSubmit}
        disabled={!enableFormSubmit}
        text={t('common:button.select')}
        textStyle={Styles.connectText}
      />
      {loader && <Loader />}
    </View>
  );
};
