import React, { useState, useEffect, useContext } from 'react';
import { View, ScrollView, InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as SharedStyles from 'shared/styles';
import Styles from './Styles';
import { SkillWorkstationTableContainer } from './SkillWorkstationTable/container';
import { SkillWorkerTableContainer } from './SkillWorkerTable/container';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { SkillBanner } from '../../../banner/skillBanner/SkillBanner';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';

/**
 * If no skill is passed, the form will create the Skill
 */
interface Props {
  skill?: API.Skill;
}

enum TabIndex {
  Worker,
  Workstation,
}

export const SkillProfileComponent: React.FC<Props> = props => {
  const tabs: Tab[] = [
    {
      title: {
        title: t('alex:skills.addEditSkill.tabs.2'),
      },
    },
    {
      title: {
        title: t('alex:skills.addEditSkill.tabs.1'),
      },
    },
  ];

  const isMounted = useIsMounted();

  const [skillTab, setSkillTab] = useState<Tab[]>(tabs);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(TabIndex.Worker);
  const {
    disableFilter: [, setDisableFilterTag],
  } = useContext(HeaderFilterContext);

  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current || !props.skill) return;
      
      setFirstTitle(capitalizeFirstLetter(props.skill.name));
      setSecondTitle('');
    });
  }, [props.skill]);

  function navigatePage(index: number) {
    if (index === TabIndex.Worker) {
      setDisableFilterTag(false);
    } else if (index === TabIndex.Workstation) {
      setDisableFilterTag(true);
    }
    setCurrentTabIndex(index);
  }

  function setTabCount(count: number, index: number) {
    const alterTabs = [...skillTab];
    alterTabs[index].title.tabTotal = count;
    alterTabs[index].title.disabled = false;
    setSkillTab([...alterTabs]);
  }

  return (
    <View style={Styles.container}>
      {props.skill && <SkillBanner skill={props.skill} />}
      <View style={[SharedStyles.Styles.cardMain, Styles.rightPanelContainer]}>
        <View style={Styles.tabContainer}>
          <AnimatedTab
            currentTabIndex={currentTabIndex}
            tabs={skillTab}
            onTabPress={navigatePage}
          />
        </View>
        <ScrollView
          style={[
            Styles.horizontalScrollView,
            currentTabIndex !== TabIndex.Worker && Styles.displayNone,
          ]}
          contentContainerStyle={Styles.horizontalInnerScrollView}
        >
          {props.skill && (
            <SkillWorkerTableContainer
              skill={props.skill}
              setWorkerCount={(count: number) => setTabCount(count, 0)}
            />
          )}
        </ScrollView>
        <ScrollView
          style={[
            Styles.horizontalScrollView,
            currentTabIndex !== TabIndex.Workstation && Styles.displayNone,
          ]}
          contentContainerStyle={Styles.horizontalInnerScrollView}
        >
          {props.skill && (
            <SkillWorkstationTableContainer
              skill={props.skill}
              setWorkstationCount={(count: number) => setTabCount(count, 1)}
            />
          )}
        </ScrollView>
      </View>
    </View>
  );
};
