import React, { useState, useEffect, useContext } from 'react';
import * as API from 'shared/backend-data';
import { SkillConformityModalComponent } from '../component';
import logger from 'shared/util/Logger';
import { Loader } from 'shared/ui-component/Loader/Loader';
import * as _ from 'lodash-es';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { MyHub } from 'shared/util/MyHub';
import { getWorkerWorkstations } from 'shared/backend-data';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import {
  fixTrainingNotSet,
  handleMaintain,
  handleUnassignWorkerAndStopTrainings,
} from 'shared/components/workstation-worker-level/container';
import { ModalUtils } from 'shared/ui-component/Modal';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { useHistory } from 'react-router-dom';

interface Props {
  workerId: string;
  workstationId: string;

  handleModalClose: () => void;
}

export const SkillConformityModal: React.FC<Props> = props => {
  const [worker, setWorker] = useState<API.Worker>();
  const [workstation, setWorkstation] = useState<API.Workstation>();
  const [workerWorkstation, setWorkerWorkstation] = useState<API.WorkerWorkstation>();
  const [loader, setLoader] = useState<boolean>(true);
  const [showTrainingModal, _setShowTrainingModal] = useState<boolean>(false);
  const [showTargetAndMaintain, setShowTargetAndMaintain] = useState<boolean>(false);

  const {
    treeNode: [, _setTreeNode],
  } = useContext(MenuFactoryContext);

  const modal = ModalUtils.useModal();

  const history = useHistory();

  const { isValidPermission } = useContext(PermissionManagementContext);

  const isMounted = useIsMounted();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKERWORKSTATION) {
        if (data.tooManyMutations) {
          fetchDetails();
        } else if (
          !API.isMutationDelete(data.mutationType) &&
          data.factory.workerWorkstation.workerId === props.workerId &&
          data.factory.workerWorkstation.workstationId === props.workstationId
        ) {
          fetchDetails();
        }
      }
    });

    fetchDetails();

    return () => {
      removeListener();
    };
  }, [props.workerId, props.workstationId]);

  async function fetchDetails() {
    const _worker = await API.getWorker(props.workerId);
    if (!isMounted.current) return;
    if (API.isFailure(_worker)) {
      logger.error('WorkstationWorkerModalContainer: failed to fetch worker', _worker);
      return;
    }
    setWorker(_worker);

    const _workstation = await API.getWorkstation(props.workstationId);
    if (!isMounted.current) return;
    if (API.isFailure(_workstation)) {
      logger.error('WorkstationWorkerModalContainer: failed to fetch workstation', _workstation);
      return;
    }
    setWorkstation(_workstation);

    const orgUnit = await API.getOrgUnitOfWorkstation(_workstation.id);
    if (!isMounted.current) return;
    if (API.isFailure(orgUnit)) {
      logger.warn(orgUnit);
      return;
    }

    const isWorkerManager = await API.isWorkerManager(orgUnit);
    if (!isMounted.current) return;
    if (API.isFailure(isWorkerManager)) {
      logger.warn(isWorkerManager);
      return;
    }

    setShowTargetAndMaintain(isWorkerManager);

    return _getWorkerWorkstation();
  }

  async function _getWorkerWorkstation(): Promise<void> {
    setLoader(true);

    const workerWorkstation = getWorkerWorkstations(props.workstationId, props.workerId);
    if (!workerWorkstation) {
      logger.error(
        `WorkerWorkstation not found while it should exist (workerId:(${props.workerId} workstationId:(${props.workstationId})`,
      );
      setLoader(false);
      return;
    }
    setWorkerWorkstation({ ...workerWorkstation });

    setLoader(false);
  }

  function isDisplayModalComponent(): boolean {
    return !!worker && !!workstation && !!workerWorkstation;
  }

  function isWorkstationHasTrainingEditPermission(): boolean {
    if (!workerWorkstation) return false;

    const orgUnit = API.Tree.getParent(props.workstationId);
    if (API.isFailure(orgUnit)) {
      logger.warn(orgUnit);
      setLoader(false);
      return false;
    }

    if (orgUnit) {
      return isValidPermission(API.Permission.trainingSessions_edit, orgUnit, [
        workerWorkstation.workerId,
      ]);
    }

    return false;
  }

  async function _handleUnassignWorkerAndStopTrainings(): Promise<void> {
    if (!workerWorkstation) return;
    setLoader(true);

    const _isValidPermission = isWorkstationHasTrainingEditPermission();

    await handleUnassignWorkerAndStopTrainings(workerWorkstation, _isValidPermission, modal);
    if (!isMounted.current) return;
    setLoader(false);
  }

  async function _handleMaintainSelect(maintainedLevel: API.WorkstationWorkerLevels) {
    if (!workerWorkstation) return;

    const isMaintained =
      API.isWorkerInTrainAutoOnWorkstation(workerWorkstation) &&
      API.isWorkerTargetingALevelOnWorkstation(workerWorkstation) &&
      maintainedLevel === API.api2workstationWorkerLevels(workerWorkstation.targetLevel!);

    await handleMaintain(
      workerWorkstation,
      isMaintained,
      maintainedLevel,
      modal,
      onWorkstationPress,
    );
    if (!isMounted.current) return;
  }

  /**
   * Proxy function to check if the user has the permission to show the training modal
   * and to close the menu if show=false
   */
  function setShowTrainingModal(show: boolean): void {
    if (show === true && !isWorkstationHasTrainingEditPermission()) {
      show = false;
    }

    _setShowTrainingModal(show);
  }

  /**
   * Callback function of hamdleMaintain, which will navitate to workstation screen
   * @returns
   */
  function onWorkstationPress() {
    if (workerWorkstation) {
      const _treeNode = API.Tree.getTreeNode(workerWorkstation?.workstationId);
      if (API.isFailure(_treeNode)) {
        logger.warn(_treeNode);
        return;
      }

      _setTreeNode(_treeNode);
      fixTrainingNotSet(workerWorkstation.workstationId, history);
    }
  }

  return isDisplayModalComponent() ? (
    <SkillConformityModalComponent
      worker={worker!}
      workstation={workstation!}
      workerWorkstation={workerWorkstation!}
      loading={loader}
      handleModalClose={props.handleModalClose}
      refetchData={_getWorkerWorkstation}
      handleUnassignWorkerAndStopTrainings={_handleUnassignWorkerAndStopTrainings}
      handleMaintainSelect={_handleMaintainSelect}
      showTrainingModal={showTrainingModal}
      setShowTrainingModal={setShowTrainingModal}
      showTargetAndMaintain={showTargetAndMaintain}
    />
  ) : (
    <Loader />
  );
};
