import { TextStyle, ViewStyle } from 'react-native';
import { Spacings, Colors, TextFontStyles } from 'shared/styles/index';
import { fontSize } from './Fonts';

export const ModalStyles = {
  backgroundPaddingTop: Spacings.Unit * 19,
  backgroundPaddingBottom: Spacings.Unit * 19,
  headerHeight: Spacings.Unit * 22,
  headerPaddingLeft: Spacings.Unit * 10,
  headerPaddingTop: Spacings.Unit * 8,
  headerPaddingBottom: Spacings.Unit * 7,
  bodyPaddingLeft: Spacings.Unit * 10,
  bodyPaddingRight: Spacings.Unit * 10,
  inputWidthWithAnchor: 280,
  inputWidthWithoutAnchor: 328,
  bodyDivider: Spacings.Unit * 11,
  anchorSize: { width: 48, height: 48 },
  anchorWidth: 48,
  anchorHeight: 48,
  anchorPaddingTop: Spacings.Unit * 5,
  anchorMarginRight: Spacings.Unit * 5,
  smallButtonMargin: Spacings.Large,
  footerHeight: Spacings.Unit * 15,
  footerPaddingLeft: Spacings.Unit * 10,
  footerPaddingRight: Spacings.Unit * 10,
  footerBigButtonPaddingTop: Spacings.Small,
  footerBigButtonPaddingBotom: Spacings.Medium,
  footerSmallButtonPaddingBotom: Spacings.Unit * 7,
  width: 800,
  minHeight: '60vh',
};

export const ModalBackgroundStyle: ViewStyle = {
  paddingBottom: ModalStyles.backgroundPaddingBottom,
  paddingTop: ModalStyles.backgroundPaddingTop,
  alignItems: 'center',
  backgroundColor: Colors.ModalBackground,
  flex: 1,
};

export const ModalCardStyle: ViewStyle = {
  width: ModalStyles.width,
  minHeight: ModalStyles.minHeight,
  borderRadius: 8,
  backgroundColor: Colors.White,
  maxHeight: '100%',
};

export const ModalHeaderStyle: ViewStyle = {
  height: ModalStyles.headerHeight,
  paddingLeft: ModalStyles.headerPaddingLeft,
  paddingTop: ModalStyles.headerPaddingTop,
  paddingBottom: ModalStyles.headerPaddingBottom,
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  borderBottomColor: '#F3F4F5',
  borderBottomWidth: 1,
};

export const ModalHeaderBoldTextStyle: TextStyle = {
  ...TextFontStyles.LatoBlack,
  fontSize: fontSize.XXXLarge,
};

export const ModalHeaderRegularTextStyle: TextStyle = {
  ...TextFontStyles.LatoRegular,
  fontSize: fontSize.XXXLarge,
};

export const ModalFooterStyle: ViewStyle = {
  height: ModalStyles.footerHeight,
  paddingLeft: ModalStyles.footerPaddingLeft,
  paddingRight: ModalStyles.footerPaddingRight,
};

export const ModalFooterBigButtonTextStyle: TextStyle = {
  ...TextFontStyles.LatoBold,
  fontSize: fontSize.Medium,
  color: Colors.White,
};
export const ModalBodyWithAnchorStyle: ViewStyle = {
  paddingLeft: ModalStyles.bodyPaddingLeft,
  paddingRight: ModalStyles.bodyPaddingRight,
  zIndex: 1,
};

export const ModalAnchorStyle: ViewStyle = {
  width: ModalStyles.anchorWidth,
  height: ModalStyles.anchorHeight,
  backgroundColor: Colors.Yellow,
  borderRadius: (ModalStyles.anchorWidth + ModalStyles.anchorHeight) / 2,
  alignItems: 'center',
  justifyContent: 'center',
};

export const ModalAnchorContainerStyle: ViewStyle = {
  width: ModalStyles.anchorWidth,
  marginTop: ModalStyles.anchorPaddingTop,
  marginRight: ModalStyles.anchorMarginRight,
};

export const ModalInputWithAnchorStyle: ViewStyle = {
  width: ModalStyles.inputWidthWithAnchor,
};
