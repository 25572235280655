import React from 'react';
import { TrainingModalComponent } from '../component/TrainingModalComponent';
import * as API from 'shared/backend-data';

export interface TrainingModalProps {
  config: TrainingModalConfig; 
  isPassive?: boolean;
  preSelectedSkill?: API.SkillCreateInput;
  trainingNamePlaceholder?: string;
  onPassiveSubmit?: (TrainingVersionAndTraining: API.TrainingVersionAndTrainingCreateInput) => void;
  handleModalClose: (isShow: boolean, newlyCreatedTrainingVersion?: API.TrainingVersion) => void;
  handleRefresh: () => void;
}

export interface TrainingModalConfig {
  editMode: boolean;
  trainingVersion: API.TrainingVersion | null | undefined;
  skillIds: readonly string[]; 
}

export const ModifyTrainingModal: React.FC<TrainingModalProps> = props => {
  return <TrainingModalComponent {...props} />;
};
