import * as _ from 'lodash-es';
import React from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { Colors } from 'shared/styles';
import { TextButton } from 'shared/ui-component/Button';
import { IconSVG } from 'shared/ui-component/Icon';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import * as API from 'shared/backend-data';
import * as SharedStyles from 'shared/styles';
import Styles from './styles';
import { Page, PageType } from './functions';
import { EllipsisWithTooltip } from '../../ui-component/EllipsisWithTooltip';
import { ActivityIndicator } from '../../ui-component/Loader/ActivityIndicator';

interface Props {
  workersPages: Map<string, Page[]>;

  onTraineePress: (workerId: string) => void;
  onSkillPress: (page: Page) => void;
  onTeamPress: () => void;
}

export interface SkillsAndSkillGroups extends API.Skill {
  type: PageType;
  subSkills?: SkillsAndSkillGroups[];
}

export interface Chapter {
  proofBundles?: API.ProofBundle[];
  file?: API.S3Object[];
  trainees: API.Worker[];
  skills: SkillsAndSkillGroups[];
  isCommon: boolean;
  acquired?: boolean | null;
  issueDate?: string;
  comment?: string | null;
  isChapterCompleted: boolean;
  isChapterSubmitted: boolean;
  isChapterValidated: boolean;
}

const SkillIcon = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const CompletedIcon = require('shared/assets/svg/icon.checked.small.svg').default;
const RightIcon = require('shared/assets/svg/icon.next.small.svg').default;
const ThumbsUp = require('shared/assets/svg/icon.thumbUp.svg').default;
const ThumbsDown = require('shared/assets/svg/icon.thumbDown.svg').default;
const TeamIcon = require('shared/assets/svg/icon.team.svg').default;

export const SummaryBook: React.FC<Props> = props => {
  const { workersPages, onTraineePress, onSkillPress, onTeamPress } = props;

  function renderAcquiredIcons(proofBundle: API.NoMetadata<API.ProofBundle>) {
    if (proofBundle.acquired) {
      return (
        <View style={Styles.acquiredIconContainer}>
          <IconSVG svgComponent={ThumbsUp} color={Colors.Green} size={{ width: 20, height: 20 }} />
          <IconSVG svgComponent={RightIcon} color={Colors.Black} />
        </View>
      );
    }

    return (
      <View style={Styles.acquiredIconContainer}>
        <IconSVG svgComponent={ThumbsDown} color={Colors.Red} size={{ width: 20, height: 20 }} />
        <IconSVG svgComponent={RightIcon} color={Colors.Black} />
      </View>
    );
  }

  function renderWorkerSkills() {
    return _.map(Array.from(workersPages.values()), (pages, index) => {
      if (!pages.length) return;

      const worker = pages[0].worker;
      return (
        <View key={index} style={{ zIndex: -index }}>
          <TouchableOpacity
            style={Styles.summaryEachRowContainer}
            onPress={() => onTraineePress(worker.id)}
          >
            {renderImage(undefined, ImageSizes.Medium, worker.name)}
            <EllipsisWithTooltip
              text={worker.name}
              textStyle={Styles.summaryWorkerName}
              style={{ width: '85%' }}
            />
          </TouchableOpacity>
          {_.map(pages, (eachSummaryBookDetail, _index) => (
            <TouchableOpacity
              key={eachSummaryBookDetail.skill.id}
              style={[
                Styles.summaryEachRowContainer,
                Styles.summarySkillContainer,
                { zIndex: -index + -_index },
              ]}
              onPress={() => onSkillPress(eachSummaryBookDetail)}
            >
              <View style={Styles.skillNameContainer}>
                {eachSummaryBookDetail.type !== PageType.SkillGroup ? (
                  <View style={Styles.skillIconContainer}>
                    <IconSVG svgComponent={SkillIcon} color={Colors.Black} />
                  </View>
                ) : (
                  <IconSVG
                    svgComponent={SkillIcon}
                    containerStyle={SharedStyles.Styles.tableObjectIconContainer}
                    color={Colors.White}
                  />
                )}
                <Text style={Styles.summarySkillName}>{eachSummaryBookDetail.skill.name}</Text>
              </View>

              <View>
                {eachSummaryBookDetail.isProofBundleSubmitted &&
                eachSummaryBookDetail.proofBundle ? (
                  renderAcquiredIcons(eachSummaryBookDetail.proofBundle)
                ) : eachSummaryBookDetail.proofBundle?.files.length &&
                  eachSummaryBookDetail.proofBundle.acquired !== null ? (
                  <IconSVG svgComponent={CompletedIcon} color={Colors.Green} />
                ) : eachSummaryBookDetail.type === PageType.Skill ||
                  eachSummaryBookDetail.type === PageType.SkillGroup ? (
                  <View style={Styles.completeContainer}>
                    <TextButton
                      text={t('alex:trainingSessions.addTrainingModal.complete')}
                      onPress={() => onSkillPress(eachSummaryBookDetail)}
                      textStyle={Styles.completeText}
                    />
                    <IconSVG svgComponent={RightIcon} color={Colors.Black} />
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </TouchableOpacity>
          ))}
        </View>
      );
    });
  }
  return (
    <View style={Styles.summaryBookContainer}>
      <View style={Styles.summaryTitle}>
        <Text style={Styles.summaryText}>
          {t('alex:trainingSessions.addTrainingModal.summary')}
        </Text>
      </View>
      {Array.from(workersPages.values()).length > 1 && (
        <TouchableOpacity style={Styles.teamIconContainer} onPress={onTeamPress}>
          <IconSVG svgComponent={TeamIcon} color={Colors.Black} />
          <Text style={Styles.commonProofTitle}>
            {t('alex:trainingSessions.addTrainingModal.commonProof')}
          </Text>
        </TouchableOpacity>
      )}
      {workersPages.size === 0 && (
        <View style={Styles.loader}>
          <ActivityIndicator />
        </View>
      )}
      <ScrollView>{renderWorkerSkills()}</ScrollView>
    </View>
  );
};
