import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  tagRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  tagOptionContainer: {
    padding: Spacings.Unit,
    paddingRight: Spacings.xMedium,
    paddingLeft: Spacings.Medium,
    minHeight: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  createTagContainerIcon: {
    marginRight: Spacings.Unit,
  },
  tagIconContainer: {
    backgroundColor: Colors.Background,
    borderRadius: 24,
    marginRight: Spacings.Small,
    width: 32,
    height: 32,
  },
  editIconStyles: {
    height: 32,
    width: 32,
    position: 'absolute',
    right: 0,
  },
  renderOptionContainer: {
    flex: 0.4,
  },
  pathStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: Colors.Grey,
  },
  orgUnitIconContainer: {
    marginRight: Spacings.Unit,
    marginLeft: Spacings.Unit,
  },
  listItemTextWrapper: { flex: 1, flexDirection: 'row', alignItems: 'center' },
  listItemInnerWrapper: { flex: 1 },
  extraSelectorsWrapper: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
  },
  extraSelectorTouchableWrapper: {
    width: 30,
    height: 30,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolTipContainer: {
    backgroundColor: Colors.White,
    position: 'absolute',
    right: 26,
    minWidth: 120,
    maxWidth: 200,
    bottom: -1,
  },
  toolTipTextStyle: { color: Colors.Black },
  shadowedSelectedExtraSelector: {
    borderWidth: 1,
    borderRadius: 28,
    borderColor: Colors.GreyLight,
    shadowColor: Colors.Grey,
    shadowOpacity: 0.3,
    shadowOffset: { width: 1, height: 1 },
    shadowRadius: 3,
  },
  listItemInnerWrapperWithRenderOption: {
    flex: 0.7,
  },
});
export default styles;
