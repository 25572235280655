import { StyleSheet } from 'react-native';
import { TextFontStyles, fontSize } from '../../styles';

const Styles = StyleSheet.create({
  tableText: {
    textAlign: 'left',
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
    alignSelf: 'baseline',
  },
});

export default Styles;
