import { StyleSheet, Platform } from 'react-native';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';
const isPlatformWeb = Platform.OS === 'web';

const styles = StyleSheet.create({
  maincontainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: Colors.GreyLight,
  },
  container: {
    backgroundColor: Colors.White,
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: 30,
    alignItems: 'center',
    marginTop: 6,
    borderTopRightRadius: Spacings.xMedium,
    borderTopLeftRadius: Spacings.xMedium,
  },
  inputText: {
    ...(isPlatformWeb ? { placeholderTextColor: Colors.Grey } : {}),
    flex: 1,
    paddingRight: Spacings.Small,
    paddingLeft: 0,
    color: Colors.DarkBlack,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    minWidth: 40,
    marginTop: Spacings.Unit,
    marginBottom: Spacings.Small,
  },
  tagView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 100,
    paddingLeft: Spacings.Small,
    paddingRight: Spacings.Unit,
    paddingVertical: Spacings.Unit,
    backgroundColor: Colors.Background,
    marginBottom: 6,
    marginLeft: Spacings.Small,
    height: Spacings.Standard,
    maxWidth: '45%',
  },
  tagText: {
    color: Colors.Black,
    fontWeight: '500',
    marginLeft: 5,
    fontSize: 12,
    maxWidth: '91%',
  },
  submitButtonContainer: {
    margin: Spacings.Small,
    backgroundColor: Colors.White,
  },
  hoverIconContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: Spacings.Small,
  },
  hoverIcon: {
    height: Spacings.Standard,
    width: Spacings.Standard,
  },
  keywordTag: {
    backgroundColor: Colors.White,
    borderWidth: 1,
    borderColor: Colors.GreyLighter,
  },
  toolTipContainer: {
    minWidth: 300,
  },
});

export default styles;
