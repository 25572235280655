import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';
import { InputText } from 'shared/ui-component/Input';
import { t, languages } from 'shared/localisation/i18n';
import { useCustomForm, InputValidationType } from 'shared/hooks/CustomForm';
import { CompanySettingsInputIds } from '../container';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import * as _ from 'lodash-es';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors, ModalStyles } from 'shared/styles';
import { DropDownWithCustomLevelIcon } from 'shared/ui-component/DropDown/DropDownWithCustomLevelIcon';
import { DropDownWithWorkerLevelOption } from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';

interface Props {
  data: {
    companyName: string;
    isDisableLostLevelAlert: boolean | null;
    mentorLevelOptions: DropDownOption[];
    workstationTargetsStartingAtLevelOptions: DropDownOption[];
    isAdmin: boolean;
    defaultLanguage?: DropDownOption;
    mentorMinLevel?: DropDownOption;
    workstationTargetsStartingAtLevel?: DropDownOption;
    levelIcon?: DropDownWithWorkerLevelOption;
    levelIcons: DropDownWithWorkerLevelOption[];
  };
  functions: {
    handleInput: (inputName: string, value: string) => void;
    handleInputDropdownWithLevel: (
      inputName: string,
      value?: DropDownWithWorkerLevelOption,
    ) => void;
    handleInputDropdown: (inputName: string, value: DropDownOption) => void;
  };
}

const dropdownOptions: DropDownOption[] = _.map(languages, language => {
  return {
    key: language.locale,
    label: language.label,
    value: language,
  };
});

export const CompanyDetails: React.FC<Props> = props => {
  const { functions, data } = props;

  const { errors, onBlur, onChange, refs } = useCustomForm<string>(
    {
      [CompanySettingsInputIds.CompanyName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
    },
    onSubmitForm,
  );

  function onSubmitForm() {}

  const handleInput = (inputId: string, inputValue: string) => {
    onChange(inputId, inputValue);
    functions.handleInput(inputId, inputValue.trim());
  };

  const handleInputDropdown = (inputId: string, value?: DropDownOption) => {
    if (value) {
      functions.handleInputDropdown(inputId, value);
    }
  };

  return (
    <View style={[{ width: ModalStyles.inputWidthWithAnchor }]}>
      <InputText
        defaultValue={data.companyName}
        containerStyle={styles.width}
        placeHolder={t('common:tenant.tenantName')}
        onTextChange={handleInput}
        inputId={CompanySettingsInputIds.CompanyName}
        inputRef={refs[CompanySettingsInputIds.CompanyName]}
        onBlur={onBlur}
        errorMessage={errors[CompanySettingsInputIds.CompanyName]?.inputErrorMessage}
        notEditable={!data.isAdmin}
      />
      <DropDownSingleSelection
        title={t('common:tenant.languagePlaceholder')}
        inputId={CompanySettingsInputIds.Language}
        containerStyle={[styles.width, { zIndex: 4 }]}
        options={dropdownOptions}
        handleChange={handleInputDropdown}
        value={data.defaultLanguage}
        hasSearch
        notEditable={!data.isAdmin}
      />
      <View style={[styles.separator, styles.width, { zIndex: 1 }]}>
        <View style={styles.companyTitleContainer}>
          <Text style={styles.title}>{t('common:tenant.tutorMinLevel')}</Text>
        </View>
        <DropDownSingleSelection
          title={t('common:tenant.tutorMinLevelPlaceholder')}
          inputId={CompanySettingsInputIds.MentorMinLevel}
          containerStyle={[styles.width, { zIndex: 2 }]}
          options={data.mentorLevelOptions}
          handleChange={handleInputDropdown}
          value={data.mentorMinLevel}
          hasSearch
          notEditable={!data.isAdmin}
        />
      </View>
      <View style={[styles.separator, styles.width, { zIndex: 0 }]}>
        <View style={styles.workstationTargetTitleContainer}>
          <Text style={styles.title}>{t('glossary:levelTarget_plural')}</Text>
          <ToolTipWeb
            title={t('common:tenant.workstationTargetsStartingAtLevelInfo')}
            style={styles.infoIcon}
            component={
              <IconSVG
                svgComponent={require('shared/assets/svg/icon.info.svg').default}
                color={Colors.Grey}
              />
            }
          />
        </View>
        <DropDownSingleSelection
          title={t('common:tenant.workstationTargets')}
          inputId={CompanySettingsInputIds.WorkstationTargetLevel}
          containerStyle={[styles.width, { zIndex: 1 }]}
          options={data.workstationTargetsStartingAtLevelOptions}
          handleChange={handleInputDropdown}
          value={data.workstationTargetsStartingAtLevel}
          hasSearch
          notEditable={!data.isAdmin}
        />
      </View>
      <View style={[styles.separator, styles.width, { zIndex: -1 }]}>
        <View style={styles.workstationTargetTitleContainer}>
          <Text style={styles.title}>{t('common:tenant.levelIcon')}</Text>
        </View>
        <DropDownWithCustomLevelIcon
          title={t('common:tenant.levelIcon')}
          inputId={CompanySettingsInputIds.LevelIcon}
          containerStyle={[styles.width, { zIndex: 1 }]}
          options={data.levelIcons}
          handleChange={functions.handleInputDropdownWithLevel}
          value={data.levelIcon}
          hasSearch
          notEditable={!data.isAdmin}
          iconContainerStyle={styles.iconContainer}
        />
      </View>
    </View>
  );
};
