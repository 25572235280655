import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import moment from 'moment';
import { i18n } from 'shared/localisation/i18n';
import logger from './Logger';
import { isSameDate } from '../util-ts/DateUtils';


export interface ContractAndType extends API.Contract {
  name: string;
  openEndDate?: boolean | null;
  isPauseContract?: boolean | null;
}





export async function createContractType(
  contractTypeInput: API.ContractTypeCreateInput,
): Promise<API.Result<API.ContractType>> {
  const factory = await API.createFactoryBusinessObject(
    API.DataType.CONTRACTTYPE,
    contractTypeInput,
  );
  if (API.isFailure(factory)) return factory;

  return { ...factory.contractType, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function updateContractType(
  contractTypeInput: API.ContractTypePartialUpdateInput,
): Promise<API.Result<API.ContractType>> {
  const factory = await API.updateFactoryBusinessObject(
    API.DataType.CONTRACTTYPE,
    contractTypeInput,
  );
  if (API.isFailure(factory)) return factory;

  return { ...factory.contractType, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function getContractType(
  contractTypeId: string,
): Promise<API.Result<API.ContractType>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.CONTRACTTYPE, contractTypeId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.contractType, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

/**
 *
 * @param pausedContractType if false returns the normal contractTypes, if true returns the paused contractTypes
 * @returns
 */
export async function getContractTypes(
  pausedContractType = false,
): Promise<API.Result<API.ContractType[]>> {
  const factories = await API.listFactoriesWithDataType(API.DataType.CONTRACTTYPE);
  if (API.isFailure(factories)) return factories;

  const contractTypes: API.ContractType[] = [];
  _.forEach(factories.result, factory => {
    if (!pausedContractType) {
      if (!factory.contractType.isPauseContract) {
        contractTypes.push({
          ...factory.contractType,
          updatedAt: factory.updatedAt,
          updatedBy: factory.updatedBy,
        });
      }
    } else if (factory.contractType.isPauseContract) {
      contractTypes.push({
        ...factory.contractType,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      });
    }
  });

  return contractTypes;
}





export function isContract(obj: any): obj is API.Contract {
  return (obj as API.Contract).contractTypeId !== undefined;
}

/** Check if 2 Contracts are considered equals */
export function isSameContract(contract1: API.Contract, contract2: API.Contract): boolean {
  if (contract1.contractTypeId !== contract2.contractTypeId) return false;

  if (contract1.startDate) {
    if (!contract2.startDate) return false;

    
    if (!isSameDate(new Date(contract1.startDate), new Date(contract2.startDate))) return false;
  } else {
    if (contract2.startDate) return false;
  }

  if (contract1.endDate) {
    if (!contract2.endDate) return false;

    
    if (!isSameDate(new Date(contract1.endDate), new Date(contract2.endDate))) return false;
  } else {
    if (contract2.endDate) return false;
  }

  return true;
}

/**
 * This function returns the last contract the worker currently has, if the worker is active we return the last non Pause contract other wise
 * if it is archived it returns the last contract which will be a pause one
 * @param worker
 * @returns
 */
export async function getWorkerLastContract(
  worker: API.Worker,
): Promise<API.Result<ContractAndType | undefined>> {
  if (!worker.contracts.length) return;

  let lastContract: ContractAndType | undefined;
  let contract;

  if (worker.state === API.WorkerState.ARCHIVED) {
    contract = worker.contracts[worker.contracts.length - 1];
  } else {
    
    await Promise.all(
      worker.contracts.map(async _contract => {
        const contractType = await API.getContractType(_contract.contractTypeId);
        if (API.isFailure(contractType)) return contractType;

        if (!contractType.isPauseContract) contract = { ..._contract, __typename: 'Contract' };
      }),
    );
  }

  if (contract) {
    const contractType = await API.getContractType(contract.contractTypeId);
    if (API.isFailure(contractType)) return contractType;

    lastContract = {
      ...contract,
      name: contractType.name,
      openEndDate: contractType.openEndDate,
      isPauseContract: contractType.isPauseContract,
    };
  }

  return lastContract;
}

/**
 * Get a string describing the contract duration
 * @param worker
 */
export async function getWorkerLastContractInfo(worker: API.Worker): Promise<string> {
  const lastContract = await getWorkerLastContract(worker);
  if (API.isFailure(lastContract)) {
    logger.warn(lastContract);
    return '';
  }
  if (!lastContract) return '';

  if (lastContract.endDate && moment(lastContract.endDate).isBefore()) {
    return `${lastContract.name} ${i18n.t('common:time.ended_m')} ${moment(
      lastContract.endDate,
    ).fromNow()}`;
  } else if (lastContract.startDate) {
    return `${lastContract.name} ${i18n.t('common:time.sinceDate', {
      date: moment(lastContract.startDate).toNow(true),
    })}`;
  } else {
    return `${lastContract.name} ${i18n.t('common:time.sinceDate', { date: '-' })}`;
  }
}
