import { StyleSheet } from 'react-native';
import { Colors, fontSize, Spacings, TextFontStyles } from 'shared/styles';

const styles = StyleSheet.create({
  rootContainer: {
    position: 'absolute',
    backgroundColor: Colors.Grey,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: Spacings.Unit,
    paddingRight: Spacings.CardPadding,
    paddingLeft: Spacings.Medium,
    paddingVertical: 6,
    minWidth: 148,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  text: {
    fontSize: fontSize.Regular,
    color: Colors.White,
    ...TextFontStyles.LatoRegular,
    lineHeight: 18,
  },
});

export default styles;
