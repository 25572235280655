import { StyleSheet } from 'react-native-web';
import { Spacings, Colors, ModalStyles, fontSize } from 'shared/styles';
import { TextFontStyles } from 'shared/styles/TextStyles';
import { createRoundedStyle } from 'shared/styles/Styles';

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  defaultHeaderTextStyle: {
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    fontSize: fontSize.XXXLarge,
  },
  defaultHeaderSecondTextStyle: {
    ...TextFontStyles.LatoNormal,
  },
  closeButtonIcon: {
    position: 'absolute',
    right: Spacings.xMedium,
    top: 9,
  },
  leftPanel: {
    flex: 1,
    maxWidth: '50%',
    backgroundColor: Colors.Background,
    borderBottomLeftRadius: Spacings.Small,
    maxHeight: ModalStyles.minHeight,
  },
  leftPanelContentContainer: {
    flex: 1,
    borderBottomLeftRadius: Spacings.Small,
  },
  leftPanelFirstPage: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    borderBottomLeftRadius: Spacings.Small,
    paddingTop: 28,
  },
  circleView: {
    ...createRoundedStyle(Spacings.Standard),
    backgroundColor: Colors.Yellow,
  },
  rightPanel: {
    flex: 1,
    width: '50%',
    backgroundColor: Colors.White,
    borderBottomRightRadius: Spacings.Small,
  },
  rightAndLeftPanel: {
    backgroundColor: Colors.White,
    width: '100%',
    flexDirection: 'row',
    flex: 1,
    borderBottomRightRadius: Spacings.Small,
    borderBottomLeftRadius: Spacings.Small,
  },
  featherContainer: {
    width: '100%',
    alignItems: 'center',
  },
  addProofOrAutomaticAttestContainer: {
    paddingTop: Spacings.Large,
    paddingLeft: 32,
    paddingRight: Spacings.Medium,
    flex: 1,
  },
  addProofOrAutomaticAttestInnerContainer: {
    flex: 1,
  },
  addProofRowContainer: {
    flexDirection: 'row',
  },
  addProofOrAutomaticAttestVerticalContainer: {
    width: Spacings.Standard,
    height: '100%',
    alignItems: 'center',
  },
  checkBoxContainer: {
    paddingLeft: 5,
  },
  addProofContainer: {
    height: '100%',
    flex: 1,
    paddingLeft: Spacings.xMedium,
    paddingTop: 2,
  },
  autoCertificateText: {
    ...TextFontStyles.LatoRegular,
    width: 220,
    fontSize: Spacings.Medium,
    textAlign: 'center',
    marginLeft: 26,
  },
  autoCertificateTextBold: {
    ...TextFontStyles.LatoBlack,
    fontSize: Spacings.Medium,
  },
  addProofTextStyle: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Black,
    fontSize: fontSize.Medium,
  },
  addProofTextDisabledStyle: {
    ...TextFontStyles.LatoRegular,
    color: Colors.GreyLight,
    fontSize: fontSize.Medium,
  },
  automaticAttestWarningContainer: {
    flex: 1,
    paddingTop: Spacings.Small,
  },
  automaticAttestTextStyle: {
    ...TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    width: '80%',
  },
  automaticAttestTextDisabledStyle: {
    ...TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    width: '80%',
    color: Colors.GreyLight,
  },
  leftPanelSecondPage: {
    width: '100%',
    borderBottomLeftRadius: Spacings.Small,
    paddingBottom: Spacings.CardPadding,
  },
  addRemoveProofHeader: {
    height: Spacings.Large,
    paddingRight: Spacings.xMedium,
    flexDirection: 'row-reverse',
    right: Spacings.Unit,
    backgroundColor: 'transparent',
    zIndex: Spacings.CardPadding,
  },
  addRemoveProofContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 60,
  },
  roundTouchable: {
    borderRadius: 100,
  },
  proofsPreviewContainer: {
    flex: 1,
    paddingBottom: Spacings.Unit,
  },
  proofsPreviewScrollContainer: {
    flex: 1,
  },
  proofsPreviewScrollContentContainer: {
    width: 400,
    height: 386,
  },
  rightPanelContainer: {
    backgroundColor: Colors.White,
    flex: 1,
    paddingBottom: Spacings.Standard,
    paddingLeft: Spacings.Unit * Spacings.Small,
    maxWidth: '50%',
    justifyContent: 'space-between',
    borderBottomRightRadius: Spacings.Small,
  },
  rightPanelInputsContainer: {
    width: 300,
  },
  workerDropDown: {
    zIndex: 0,
    width: '100%',
  },
  skillDropDown: {
    zIndex: -1,
    width: '100%',
  },
  obtentionDateAndValidityContainer: {
    width: '100%',
    height: 64,
    zIndex: -3,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputDateContainer: { width: '40%' },
  commentsContainer: {
    zIndex: -Spacings.Unit,
  },
  submitButtonContainer: {
    zIndex: -1,
    paddingRight: Spacings.Unit * Spacings.Small,
  },
  submitButtonStyle: {
    height: Spacings.Large,
    justifyContent: 'center',
    zIndex: 0,
  },
  submitButtonTextStyle: {
    fontSize: Spacings.Medium,
    ...TextFontStyles.LatoBold,
  },
  menuIconContainer: {
    position: 'absolute',
    zIndex: 999,
    right: 10,
    top: 15,
  },
  menuContainer: {
    position: 'absolute',
    right: 30,
    zIndex: 9,
    maxWidth: 309,
    maxHeight: 359,
    paddingLeft: Spacings.Standard - Spacings.Unit,
    paddingRight: Spacings.Medium,
    paddingVertical: 27,
  },
  menuOptionToucableOpacity: {
    marginBottom: Spacings.Standard,
  },
  optionText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Unit,
  },
  deleteOptionText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Regular,
    color: Colors.Red,
    marginBottom: Spacings.Unit,
  },
  optionDescriptionText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    fontSize: fontSize.Small,
  },
  arrowBackContainer: {
    position: 'absolute',
    top: Spacings.Unit * 3,
    left: Spacings.Unit * 2,
    zIndex: 999,
  },
  errorMessage: {
    padding: Spacings.Standard,
    ...TextFontStyles.LatoRegular,
  },
});

export default styles;
