import { StyleSheet, Platform } from 'react-native';
import { Spacings, Colors, TextFontStyles, fontSize } from 'shared/styles';

const INPUT_TEXT_HEIGHT = 48;

const style = StyleSheet.create({
  panel: {
    flex: 1,
    backgroundColor: Colors.White,
  },
  inputContainer: {
    paddingTop: Spacings.CardPadding,
    paddingHorizontal: Spacings.CardPadding,
    height: INPUT_TEXT_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
  },
  resultContainer: {
    paddingHorizontal: Spacings.Standard,
    paddingTop: Spacings.Standard,
    flex: 1,
  },
  parentTagContainer: {
    flexDirection: 'row',
    marginBottom: Spacings.Medium,
    justifyContent: 'space-between',
    flex: 1,
  },
  childTagContainer: {
    flexDirection: 'row',
    marginLeft: Spacings.CardPadding,
    marginBottom: Spacings.Standard,
    justifyContent: 'space-between',
    marginRight: 10,
  },
  parentTagText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    color: Colors.Grey,
    flex: 0.8,
  },
  checkBoxTitleText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    color: Colors.Grey,
    flex: 0.2,
    textAlign: 'center',
  },
  childTagText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    color: Colors.Black,
  },
  listContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 2,
    marginRight: 10,
  },
  underConstruction: {
    padding: Spacings.Standard,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    textAlign: 'center',
  },
  disable: {
    backgroundColor: Colors.GreyLight,
  },
  yellowButton: {
    margin: Spacings.Standard,
    padding: Spacings.Small,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.White,
    margin: 0,
    paddingTop: Platform.OS === 'android' ? 0 : Spacings.Medium,
  },
  tabTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
  },
  warningText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    color: Colors.Grey,
    textAlign: 'center',
    paddingTop: Spacings.Standard,
  },
  tagContainer: {
    backgroundColor: Colors.Background,
    borderRadius: 20,
    padding: Spacings.Unit,
    marginRight: Spacings.Unit,
  },
  teamTagOption: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    margin: Spacings.Small,
    paddingLeft: Spacings.Medium,
  },
  tagOptionContainer: {
    flexDirection: 'row',
    margin: '2%',
    marginLeft: 0,
    alignItems: 'center',
  },
  filterOptionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: Spacings.Medium,
  },
  listItemText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    margin: Spacings.Small,
  },
  filterIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default style;
