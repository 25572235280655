import { StyleSheet } from 'react-native';
import { Spacings, Colors, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  summaryText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Big,
  },
  summaryTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: Spacings.Standard,
    paddingBottom: Spacings.Small,
  },
  summaryBookContainer: {
    flex: 1,
    paddingLeft: Spacings.Large,
    maxHeight: 444,
  },
  summarySkillName: {
    paddingLeft: Spacings.Small,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    width: '85%',
  },
  skillIconContainer: {
    paddingLeft: Spacings.Medium,
  },
  skillNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  summarySkillContainer: {
    justifyContent: 'space-between',
  },
  summaryWorkerName: {
    paddingLeft: Spacings.xMedium,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  summaryEachRowContainer: {
    flexDirection: 'row',
    paddingVertical: Spacings.Medium,
    alignItems: 'center',
    borderBottomColor: Colors.GreyLighter,
    borderBottomWidth: 0.5,
    borderStyle: 'dashed',
    marginRight: Spacings.Big + Spacings.CardPadding,
  },
  completeText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  completeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  acquiredIconContainer: {
    flexDirection: 'row',
  },
  commonProofTitle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingLeft: 18,
  },
  teamIconContainer: {
    flexDirection: 'row',
    paddingTop: Spacings.xMedium,
    paddingBottom: Spacings.xMedium,
    alignItems: 'center',
  },
  loader: {
    flex: 1,
  },
});

export default styles;
