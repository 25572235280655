import React, { useState, useContext } from 'react';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { WorkerAndWorkstationVersatilityRow } from '../WorkersLibrary';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { View, ScrollView } from 'react-native';
import { capitalizeFirstLetter, t, capitalize } from 'shared/localisation/i18n';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { Spacings } from 'shared/styles';
import {
  Table,
  TableColumn,
  Tables,
  TableWidth,
  TableMediumColumnWidth,
  TableLargeColumnWidth,
} from 'shared/ui-component/Table';
import { EllipsisWithTooltip, Position } from 'shared/ui-component/EllipsisWithTooltip';
import { WorkerState, sortWorkerCompare } from 'shared/backend-data';
import * as SharedStyles from 'shared/styles';
import styles from './Styles';
import { SortDirection } from '../../../sort';
import { RouteLocations } from '../../../navigation/Routes';
import { ImportExportFileNames, ImportExportType } from 'shared/util/ExcelUtils';
import { CsvRow, getExportDataForWorkers } from 'shared/util/ExcelUtils';
import { useHistory } from 'react-router-dom';
import logger, { loggerPerf } from 'shared/util/Logger';
import { OrgUnitColumn } from './TableColumnComponents/OUColumn';
import { TrainingColumn } from './TableColumnComponents/TrainingColumn';
import { WorkerSkillBadge } from 'shared/ui-component/Badge';
import { WorkerArchiveORActivateModal } from './WorkerArchiveORActivateModal';
import { AddEditWorkerModalContainer } from './modify-worker-modal/container';
import { UsersModal } from '../../../users-modal/modal/container';
import { ModalUtils } from 'shared/ui-component/Modal';
import { InviteWorkerModalFilterProvider } from 'shared/context/inviteWorkerModalContext';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { DropdownConfigKey, CheckBoxConfigKey } from '../../../header-layout/headerFilterConfig';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import { TableNumberWithMenu } from 'sharedweb/src/NumberWithMenu';
import { MenuWidth } from 'shared/ui-component/Menu';
import { Aigle } from 'aigle';
import { MyFactoryContext } from '../../MyFactoryContext';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { AssignmentTable, AssignmentTreeRow, isRowOrgUnit } from './AssignmentTable';
import { isArrayEmpty } from 'shared/util-ts/Functions';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { TreeTableDataProvider } from 'sharedweb/src/context/TreeTableContext';
import { inviteWorker } from 'shared/util/WorkerUi';

interface Props {
  rows: WorkerAndWorkstationVersatilityRow[];
  filterTags: TagExtended[];
  fetchRows: (showArchived?: boolean, forceReload?: boolean) => Promise<void>;
}

enum TableSetting {
  WORKERSTABLE,
  ASSIGNMENTSTABLE,
}

const _WorkersTable: React.FC<Props> = props => {
  const { rows, fetchRows } = props;

  const isMounted = useIsMounted();

  const history = useHistory();

  const modal = ModalUtils.useModal();

  const [selectedWorker, setSelectedWorker] = useState<API.Worker>();
  const [showWorkerArchiveORActivateModal, setShowWorkerArchiveORActivateModal] =
    useState<boolean>(false);
  const [showInvitationModal, setShowInvitationModal] = useState<boolean>(false);
  const [showWorkerAddEditModal, setShowWorkerAddEditModal] = useState<boolean>(false);
  const [tableSetting, setTableSetting] = useState<TableSetting>(TableSetting.WORKERSTABLE);

  const { isValidPermission } = useContext(PermissionManagementContext);
  const { workerTableData, setWorkerTableData } = useContext(MyFactoryContext);
  const { workstationTargetsStartingAtLevel, isWorkerLastNameFirst } =
    useContext(GlobalDataContext);
  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);

  const tabs: Tab[] = [
    {
      title: {
        title: t('glossary:worker_plural'),
        tabTotal: workerTableData.filteredAndSortedRows.length,
      },
    },
    {
      title: {
        title: t('glossary:assignment_plural'),
      },
    },
  ];

  function onWorkstationPress(id: string) {
    const _treeNode = API.Tree.getTreeNode(id);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }

    setTreeNode(_treeNode);
    history.push(RouteLocations.Workstations(id));
  }

  const columns: TableColumn<WorkerAndWorkstationVersatilityRow>[] = [
    {
      label: t('alex:workers.table.header.1'),
      sort: sortByName,
      width: TableLargeColumnWidth,
      renderCell: (row, index, indexOfRow) => {
        return (
          <View style={styles.collabRowName}>
            {renderImage(row.profilePicture, ImageSizes.Medium, row.name)}
            <WorkerSkillBadge workerId={row.id} badgeValueState={row.badgeState} />
            <EllipsisWithTooltip
              style={styles.ellipsisTooltip}
              text={`${capitalize(row.name)}`}
              position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
              textStyle={
                row.state === WorkerState.ARCHIVED ? styles.disabled : SharedStyles.Styles.tableText
              }
            />
          </View>
        );
      },
    },
    {
      label: t('alex:workers.table.header.2'),
      width: TableMediumColumnWidth,
      sort: sortByUnit,
      renderCell: row => <OrgUnitColumn row={row} />,
    },
    {
      label: t('alex:workers.table.header.3'),
      width: TableWidth,
      sort: sortByTag,
      renderCell: (row, index, indexOfRow) => {
        return (
          <EllipsisWithTooltip
            textStyle={
              row.state === WorkerState.ARCHIVED ? styles.disabled : SharedStyles.Styles.tableText
            }
            position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
            text={
              row.tags
                ? row.tags.map(tagItem => capitalizeFirstLetter(tagItem.name)).toString()
                : '-'
            }
          />
        );
      },
    },
    {
      label: t('alex:workers.table.header.4'),
      width: TableWidth,
      sort: sortByPolyvalence,
      renderCell: row => {
        return row.versatility === undefined ? (
          <LoaderThreeDots />
        ) : (
          <TableNumberWithMenu
            list={row.versatility}
            countColor={
              row.state === WorkerState.ARCHIVED
                ? SharedStyles.Colors.GreyLight
                : SharedStyles.Colors.Black
            }
            onMenuItemPress={onWorkstationPress}
          />
        );
      },
    },
    {
      label: t('alex:workers.table.header.5'),
      width: TableWidth,
      sort: sortByTraining,
      renderCell: row => <TrainingColumn row={row} />,
    },
  ];

  function sortByPolyvalence(
    rows: WorkerAndWorkstationVersatilityRow[],
    sortDirection: SortDirection,
  ) {
    return _.orderBy(rows, e => e.versatility?.length, [sortDirection]);
  }

  function sortByTraining(
    rows: WorkerAndWorkstationVersatilityRow[],
    sortDirection: SortDirection,
  ) {
    return _.orderBy(rows, e => e.activeTrainingSessions?.length, [sortDirection]);
  }

  function sortByName(
    rows: WorkerAndWorkstationVersatilityRow[],
    sortDirection: SortDirection,
  ): WorkerAndWorkstationVersatilityRow[] {
    return rows.sort((a, b) => sortWorkerCompare(a, b, sortDirection, isWorkerLastNameFirst));
  }

  function sortByTag(rows: WorkerAndWorkstationVersatilityRow[], sortDirection: SortDirection) {
    return _.orderBy(
      rows,
      e =>
        _.map(e.tags, tag => tag.name)
          .join(', ')
          .toLowerCase(),
      [sortDirection],
    );
  }

  function sortByUnit(rows: WorkerAndWorkstationVersatilityRow[], sortDirection: SortDirection) {
    return _.orderBy(
      rows,
      row => {
        if (!row.workerAssignments) return;

        return _.map(row.workerAssignments, workerAssignment =>
          workerAssignment.organizationalUnit.name.toLowerCase().trim(),
        ).join(', ');
      },
      [sortDirection],
    );
  }

  function handleWorkerSuccess() {
    if (selectedWorker) {
      if (selectedWorker.state === API.WorkerState.ARCHIVED) {
        fetchRows(true, true);
      } else {
        fetchRows(false, true);
      }
    }
  }

  async function filterData(
    isKeywordFiltering: boolean,
    filterTags: TagExtended[],
    workers: WorkerAndWorkstationVersatilityRow[],
  ): Promise<WorkerAndWorkstationVersatilityRow[]> {
    if (isKeywordFiltering && isArrayEmpty(filterTags)) {
      return [];
    }

    let filteringRows: WorkerAndWorkstationVersatilityRow[] = isKeywordFiltering ? [] : workers;
    let includeWorkersNotOperational = true;
    let showSelectedOrgUnits = false;

    
    const workerFilters: TagExtended[] = [];
    const shiftFilters: TagExtended[] = [];
    const workerTagFilters: TagExtended[] = [];
    const orgUnitFilters: TagExtended[] = [];
    const workerVersatilityFilter: TagExtended[] = [];
    const contractFilters: TagExtended[] = [];
    const workerStateFilters: TagExtended[] = [];
    const archivedWorkers: WorkerAndWorkstationVersatilityRow[] = [];

    _.forEach(filterTags, filter => {
      if (filter.type === DropdownConfigKey.WORKER) workerFilters.push(filter);
      else if (filter.type === DropdownConfigKey.SHIFT) shiftFilters.push(filter);
      else if (filter.type === DropdownConfigKey.WORKER_TAG) workerTagFilters.push(filter);
      else if (filter.type === DropdownConfigKey.ORGUNIT) orgUnitFilters.push(filter);
      else if (filter.type === DropdownConfigKey.WORKER_VERSATILITY)
        workerVersatilityFilter.push(filter);
      else if (filter.type === DropdownConfigKey.CONTRACT) contractFilters.push(filter);
      else if (
        filter.type === DropdownConfigKey.WORKER_OTHER_FILTER &&
        (filter.value === API.WorkerState.ARCHIVED || filter.value === API.WorkerState.ACTIVE)
      )
        workerStateFilters.push(filter);
      else if (
        filter.type === DropdownConfigKey.WORKER_OTHER_FILTER &&
        (filter.value !== API.WorkerState.ARCHIVED || filter.value !== API.WorkerState.ACTIVE)
      )
        contractFilters.push(filter);
      else if (filter.key === CheckBoxConfigKey.INCLUDE_WORKERS_NOT_OPERATIONAL) {
        includeWorkersNotOperational = filter.value;
      } else if (filter.key === CheckBoxConfigKey.SHOW_SELECTED_ORG_UNITS) {
        showSelectedOrgUnits = filter.value;
      } else logger.warn('Filter tag not recognized', filter);
    });

    if (!includeWorkersNotOperational) {
      const data = _.filter(isKeywordFiltering ? workers : filteringRows, row => {
        if (row.workerAssignments === undefined) return true;

        return _.some(row.workerAssignments, assignment =>
          assignment.permissions.includes(API.Permission.workerIsOperational),
        );
      });

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    if (shiftFilters.length) {
      const workersInShifts: API.Worker[] = [];

      await Aigle.map(shiftFilters, async eachFilter => {
        const workersInShift = await API.getWorkersInShift(
          eachFilter.value.shiftId,
          eachFilter.value.parentId,
          undefined,
          !showSelectedOrgUnits,
          false,
          includeWorkersNotOperational ? undefined : [API.Permission.workerIsOperational],
        );
        if (!isMounted.current) return;
        if (API.isFailure(workersInShift)) {
          logger.warn(workersInShift);
          return;
        }

        workersInShifts.push(...workersInShift);
      });

      if (isArrayEmpty(workersInShifts) && showSelectedOrgUnits) return [];

      filteringRows = filteringRows.filter(
        _row => !!workersInShifts.find(worker => worker.id === _row.id),
      );
    }

    if (workerStateFilters.length) {
      const data = _.filter(isKeywordFiltering ? workers : filteringRows, row =>
        _.some(workerStateFilters, otherFilter => {
          if (otherFilter.key === API.WorkerState.ACTIVE) {
            
            return false;
          } else if (otherFilter.key === API.WorkerState.ARCHIVED) {
            return row.state === API.WorkerState.ARCHIVED;
          }
        }),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    } else if (!isKeywordFiltering || !filterTags.length) {
      const data = _.filter(
        isKeywordFiltering ? workers : filteringRows,
        row => row.state === API.WorkerState.ACTIVE || row.state === null,
      );

      filteringRows = data;
    }

    if (orgUnitFilters.length) {
      const workersWithOrgUnits: WorkerAndWorkstationVersatilityRow[] = [];

      await Aigle.map(isKeywordFiltering ? workers : filteringRows, async row => {
        const workerAssignments = await API.getWorkerAssignments(
          row.id,
          !showSelectedOrgUnits,
          false,
          undefined,
          includeWorkersNotOperational ? undefined : [API.Permission.workerIsOperational],
        );

        if (!isMounted.current) return [];
        if (API.isFailure(workerAssignments)) {
          logger.warn(workerAssignments);
          return [];
        }

        if (
          _.some(workerAssignments, workerAssignment =>
            _.some(
              orgUnitFilters,
              orgUnitFilter => orgUnitFilter.key === workerAssignment.organizationalUnitId,
            ),
          )
        ) {
          workersWithOrgUnits.push(row);
        }
      });

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...workersWithOrgUnits];
      } else {
        filteringRows = workersWithOrgUnits;
      }
    }

    if (workerFilters.length) {
      const data = _.filter(isKeywordFiltering ? workers : filteringRows, row =>
        _.some(workerFilters, filter => filter.key === row.id),
      );

      workerFilters.forEach(workerFilter => {
        if (workerFilter.value && workerFilter.value.state === API.WorkerState.ARCHIVED) {
          const _worker = rows.find(row => row.id === workerFilter.key);
          if (_worker) archivedWorkers.push(_worker);
        }
      });

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    if (workerTagFilters.length) {
      const data = _.filter(isKeywordFiltering ? workers : filteringRows, row =>
        _.some(workerTagFilters, filter => {
          if (row.tags === undefined) return true;

          return _.some(row.tags, workerTag => workerTag.id === filter.key);
        }),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    if (workerVersatilityFilter.length) {
      const data = _.filter(isKeywordFiltering ? workers : filteringRows, row =>
        _.some(workerVersatilityFilter, filter => {
          if (row.versatility === undefined) return true;
          if (parseInt(filter.key, 10) !== undefined) {
            if (row.versatility.length === parseInt(filter.key, 10)) {
              return true;
            }
          }
          return false;
        }),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    if (contractFilters.length) {
      const data = _.filter(isKeywordFiltering ? workers : filteringRows, row =>
        _.some(row.contracts, eachContract =>
          _.some(
            contractFilters,
            contractFilter => eachContract.contractTypeId === contractFilter.key,
          ),
        ),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    return _.uniqBy(filteringRows, row => row.id);
  }

  async function filterRows(
    rows: WorkerAndWorkstationVersatilityRow[],
    filterTags: TagExtended[],
  ): Promise<WorkerAndWorkstationVersatilityRow[]> {
    let _filteredRows: WorkerAndWorkstationVersatilityRow[] = rows;
    let _filterTags: TagExtended[] = [];
    let _keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(filterTags, tag => {
      if (tag.isActiveBookmarkTag) {
        _filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        _keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        _filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      _filteredRows = await filterData(true, _keywordTags, _filteredRows);
    }

    _filteredRows = await filterData(false, _filterTags, _filteredRows);

    return _filteredRows;
  }

  async function filterAssignmentTableRows(
    treeRows: AssignmentTreeRow[],
    filterTags: TagExtended[],
  ): Promise<AssignmentTreeRow[]> {
    let _rows: AssignmentTreeRow[] = [];

    const _filteredWorkerRows = await filterRows(props.rows, filterTags);
    const filteredWorkerIds = _filteredWorkerRows.map(filteredWorkerRow => filteredWorkerRow.id);

    treeRows.map(treeRow => {
      if (isRowOrgUnit(treeRow)) {
        _rows.push({
          ...treeRow,
          info: {
            ...treeRow.info,
            assignedWorkerIds: treeRow.info.assignedWorkerIds.filter(workerId =>
              filteredWorkerIds.includes(workerId),
            ),
          },
        });
      } else if (filteredWorkerIds.includes(treeRow.info.workerId)) {
        _rows.push(treeRow);
      }
    });

    return _rows;
  }

  async function onMenuPress(
    row: WorkerAndWorkstationVersatilityRow,
    key: 'edit' | 'invite' | 'openInvitationModal' | 'archive' | 'active',
  ): Promise<void> {
    const worker = await API.getWorker(row.id);
    if (!isMounted.current) return;
    if (API.isFailure(worker)) {
      logger.warn(worker);
      return;
    }

    switch (key) {
      case 'edit':
        setSelectedWorker(row);
        setShowWorkerAddEditModal(true);
        break;

      case 'invite':
        await inviteWorker(worker, modal, API.UserInvitationOperations.GIVE_ACCESS);
        break;

      case 'openInvitationModal':
        setShowInvitationModal(true);
        break;

      case 'archive':
        setSelectedWorker(worker);
        setShowWorkerArchiveORActivateModal(true);
        break;

      case 'active':
        setSelectedWorker(worker);

        const lastContract = await API.getWorkerLastContract(worker);
        if (!isMounted.current) return;
        if (API.isFailure(lastContract)) {
          logger.warn(lastContract);
          return;
        }

        if (!lastContract?.isPauseContract) {
          setShowWorkerAddEditModal(true);
        } else {
          setShowWorkerArchiveORActivateModal(true);
        }

        break;
    }
  }

  async function fetchRowDetails(
    row: WorkerAndWorkstationVersatilityRow,
    isMounted: React.MutableRefObject<boolean>,
  ): Promise<boolean> {
    let updated = false;

    if (row.versatility === undefined) {
      let _workstationIds: string[] | undefined = undefined;
      if (!API.enableGlobalLevelComputation) {
        const _workstations = await API.getWorkersLinkedWorkstations(
          [row],
          [API.Permission.workerIsOperational],
        );
        if (!isMounted.current) return updated;
        if (API.isFailure(_workstations)) {
          logger.warn('Failed to fetch workers linked to a workstations', _workstations);
        } else {
          _workstationIds = _workstations.map(eachWorkstation => eachWorkstation.id);
        }
      }

      const workstationIds = await API.getWorkerSkilledWorkstations(
        workstationTargetsStartingAtLevel,
        row.id,
        _workstationIds,
      );
      if (API.isFailure(workstationIds)) {
        logger.warn('Failed to fetch the workstations of workers', workstationIds);
      } else {
        const workstations: API.Workstation[] = _.compact(
          await Aigle.map(workstationIds, async workstationId => {
            const _workstation = await API.getWorkstation(workstationId);
            if (!isMounted.current) return;
            if (API.isFailure(_workstation)) {
              logger.warn(_workstation);
              return;
            }
            return _workstation;
          }),
        );

        row.versatility = workstations;
        updated = true;
      }
    }

    if (row.workerAssignments === undefined) {
      const workerAssignments = await API.getWorkerAssignments(row.id, true, true);

      if (!isMounted.current) return updated;
      if (API.isFailure(workerAssignments)) {
        logger.warn(
          'Failed to fetch the worker org unit roles inside worker tables',
          workerAssignments,
        );
      } else {
        const uniqueRoles: API.AssignmentWithUnitDetails[] = [];
        const uniqueOrgUnitIds: string[] = [];
        workerAssignments.forEach(workerAssignment => {
          if (!uniqueOrgUnitIds.includes(workerAssignment.organizationalUnit.id)) {
            uniqueRoles.push(workerAssignment);
            uniqueOrgUnitIds.push(workerAssignment.organizationalUnit.id);
          }
        });

        row.workerAssignments = uniqueRoles.sort(compareBasedOnUnitPathIds);
        updated = true;
      }
    }

    if (row.activeTrainingSessions === undefined) {
      const activeTrainingSessions = await API.getTrainingSessionsForWorker(
        row.id,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );
      if (!isMounted.current) return updated;
      if (API.isFailure(activeTrainingSessions)) {
        logger.warn('Failed to fetch trainingSessions inside worker table', activeTrainingSessions);
      } else {
        row.activeTrainingSessions = activeTrainingSessions;
        updated = true;
      }
    }

    if (row.badgeState === undefined) {
      const badgeState = API.computeWorkerBadgeState(row.id);
      if (API.isFailure(badgeState)) {
        logger.warn('Failed to fetch the badge state inside worker table', badgeState);
      } else {
        row.badgeState = badgeState;
        updated = true;
      }
    }

    if (row.tags === undefined) {
      const _workerTags: API.WorkerTag[] = [];
      await Promise.all(
        _.compact(
          _.map(row.tagIds, async tagId => {
            const _workerTag = await API.getWorkerTag(tagId);
            if (!isMounted.current) return;
            if (API.isFailure(_workerTag)) {
              logger.warn('Failed to fetch WorkerTag', _workerTag);
            } else {
              updated = true;
              _workerTags.push(_workerTag);
            }
          }),
        ),
      );

      row.tags = _workerTags;
    }

    return updated;
  }

  function compareBasedOnUnitPathIds(
    RoleA: API.AssignmentWithUnitDetails,
    RoleB: API.AssignmentWithUnitDetails,
  ) {
    if (RoleA.organizationalUnit.pathIds.length < RoleB.organizationalUnit.pathIds.length) {
      return -1;
    }
    if (RoleA.organizationalUnit.pathIds.length > RoleB.organizationalUnit.pathIds.length) {
      return 1;
    }
    if (
      RoleA.organizationalUnit.pathIds.length === RoleB.organizationalUnit.pathIds.length &&
      RoleA.organizationalUnit.order > RoleB.organizationalUnit.order
    ) {
      return 1;
    } else {
      return -1;
    }
  }

  function saveRowData(
    rows: WorkerAndWorkstationVersatilityRow[],
    filteredAndSortedRows: WorkerAndWorkstationVersatilityRow[],
  ) {
    setWorkerTableData({ rows, filteredAndSortedRows });
  }
  return (
    <View style={[SharedStyles.Styles.cardMain, styles.tableContainer]}>
      <View style={styles.tabContainer}>
        <AnimatedTab
          currentTabIndex={tableSetting}
          tabs={tabs}
          onTabPress={index => setTableSetting(index)}
        />
      </View>
      <ScrollView contentContainerStyle={styles.tableScrollView}>
        {tableSetting === TableSetting.ASSIGNMENTSTABLE ? (
          <TreeTableDataProvider>
            <AssignmentTable
              filter={{
                filterRows: filterAssignmentTableRows,
                tags: props.filterTags,
              }}
            />
          </TreeTableDataProvider>
        ) : (
          <Table
            filter={{
              filterRows: filterRows,
              tags: props.filterTags,
            }}
            sortPreferenceKey={Tables.WORKERS}
            columnDescriptors={columns}
            rows={rows}
            rowLazyLoadProperties={fetchRowDetails}
            onRowPress={row => history.push(RouteLocations.WorkerProfile(row.id))}
            disableRowClick={row => {
              return !isValidPermission(API.Permission.workersDetail_view, undefined, [row.id]);
            }}
            rowMenuWidth={MenuWidth.Medium}
            rowMenu={row => {
              let archiveOrReInstate = {
                key: 'archive',
                label: 'glossary:workerSuspend',
                onPress: () => onMenuPress(row, 'archive'),
                disable: !isValidPermission(API.Permission.workers_edit),
              };
              if (row.state === WorkerState.ARCHIVED) {
                archiveOrReInstate = {
                  key: 'active',
                  label: 'glossary:workerReinstate',
                  onPress: () => onMenuPress(row, 'active'),
                  disable: !isValidPermission(API.Permission.workers_edit),
                };
              }
              let workerInvitation = {
                key: 'invite',
                label: t('alex:inviteWorker.workerRowMenu'),
                onPress: () => onMenuPress(row, 'invite'),
                disable: !isValidPermission(API.Permission.workers_invite),
              };
              if (row.userId) {
                workerInvitation = {
                  key: 'invite',
                  label: t('alex:inviteWorker.toastMessages.workerHasBeenInvited'),
                  onPress: () => onMenuPress(row, 'openInvitationModal'),
                  disable: !isValidPermission(API.Permission.workers_invite),
                };
              }
              return [
                {
                  key: 'edit',
                  label: 'common:button.edit',
                  onPress: () => onMenuPress(row, 'edit'),
                  disable: !isValidPermission(API.Permission.workersDetail_view),
                },
                { ...workerInvitation },
                { ...archiveOrReInstate },
              ];
            }}
            containerStyle={[
              {
                borderRadius: Spacings.Small,
              },
            ]}
            importExport={{
              excelIcon: true,
              getExportData: (rows, exportType) =>
                getExportDataForWorkers(rows, exportType === ImportExportType.WorkerWithSkill),
              refreshData: async () => {
                fetchRows(true, true);
              },
              importExportType: ImportExportType.Worker,
              importExportFileName: ImportExportFileNames.Workers,
              showImportExport: isValidPermission(API.Permission.workers_edit),
            }}
            onPlusPress={() => {
              setSelectedWorker(undefined);
              setShowWorkerAddEditModal(true);
            }}
            saveRowData={saveRowData}
          />
        )}
      </ScrollView>
      {selectedWorker && showWorkerArchiveORActivateModal && (
        <WorkerArchiveORActivateModal
          worker={selectedWorker}
          successCallback={handleWorkerSuccess}
          setShowModal={setShowWorkerArchiveORActivateModal}
        />
      )}
      {showInvitationModal && (
        <InviteWorkerModalFilterProvider>
          <UsersModal showUsersModal={() => setShowInvitationModal(false)} />
        </InviteWorkerModalFilterProvider>
      )}
      {showWorkerAddEditModal && (
        <AddEditWorkerModalContainer
          workerId={selectedWorker ? selectedWorker.id : undefined}
          showHideModal={() => setShowWorkerAddEditModal(!showWorkerAddEditModal)}
          fetchWorkerItems={() => fetchRows(true, true)} 
        />
      )}
    </View>
  );
};

export const WorkersTable = React.memo(_WorkersTable, (prevProps, nextProps) => {
  const propsAreEqual =
    (prevProps.rows === nextProps.rows || (!prevProps.rows.length && !nextProps.rows.length)) &&
    (prevProps.filterTags === nextProps.filterTags ||
      (!prevProps.filterTags.length && !nextProps.filterTags.length));
  if (!propsAreEqual)
    loggerPerf.debug(
      'Re-Rendering component WorkersTable (prevProps, nextProps)',
      prevProps,
      nextProps,
    );
  return propsAreEqual;
});
