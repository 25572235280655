/**
 * These functions are used for both mobile and web UI filters
 */

import * as API from 'shared/backend-data';
import logger from './Logger';
import * as _ from 'lodash-es';


export async function filterTrainingSessionsForOrgUnits(
  orgUnitIds: string[],
  trainingSessions: API.TrainingSessionWithDetail[],
): Promise<API.Result<API.TrainingSessionWithDetail[]>> {
  const workersInOrgUnits = await API.getWorkersInOrganizationalUnits(orgUnitIds);

  if (API.isFailure(workersInOrgUnits)) {
    logger.warn(workersInOrgUnits);
    return workersInOrgUnits;
  }
  const finalList: API.TrainingSessionWithDetail[] = [];

  _.map(workersInOrgUnits.result, async worker => {
    _.map(trainingSessions, async (eachTrainingSession, index) => {
      if (
        eachTrainingSession &&
        ((eachTrainingSession.scheduledTraineeIds &&
          eachTrainingSession.scheduledTraineeIds.find(trainee => trainee === worker.id)) ||
          eachTrainingSession.scheduledTrainers.find(trainer => trainer.trainerId === worker.id))
      ) {
        if (!finalList.some(eachList => eachList.id === eachTrainingSession.id)) {
          finalList.push(eachTrainingSession);
        }
      }
    });
  });

  return finalList;
}
