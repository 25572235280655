import React, { useState } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Avatar } from 'shared/ui-component/Avatar';
import { ImageSizes } from 'shared/util/RenderImage';
import { WorkerProofBundleDetails } from '../container';
import style from './Style';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles';
import * as API from 'shared/backend-data';

interface Props {
  workerProofBundleDetails: Map<string, WorkerProofBundleDetails>;
  selectedTraineeId?: string;
  reviewState?: API.ReviewState;
  handleIconClick: (workerId: string) => void;
}

const plusIcon = require('shared/assets/svg/icon.plus.small.svg').default;
const CheckIcon = require('shared/assets/svg/icon.check.svg').default;

export const AvatarHeader: React.FC<Props> = props => {
  const { workerProofBundleDetails, handleIconClick, selectedTraineeId } = props;

  const [pagination, setPagination] = useState<number>(0);

  const [floor, setFloor] = useState<number>(0);
  const [ciel, setCiel] = useState<number>(8);

  const count = 9;
  const _workerProofBundleDetails: WorkerProofBundleDetails[] = [];

  workerProofBundleDetails.forEach((value, key) => {
    if (floor <= value.index && value.index <= ciel) {
      _workerProofBundleDetails.push(value);
    }
  });

  function remainingCount() {
    if (workerProofBundleDetails.size > ciel + 1) {
      return workerProofBundleDetails.size - (ciel + 1);
    }
  }

  function handleRightButton() {
    setFloor(floor + count);
    setCiel(ciel + count);
    setPagination(pagination + 1);
  }

  function handleLeftButton() {
    setFloor(floor - count);
    setCiel(ciel - count);
    setPagination(pagination - 1);
  }

  return (
    <View style={style.avatarHeaderContainer}>
      {pagination ? (
        <TouchableOpacity style={style.eachAvatar} onPress={handleLeftButton}>
          <View style={style.moreButton}>
            <Text style={style.moreButtonText}>{`<`}</Text>
          </View>
        </TouchableOpacity>
      ) : null}

      {_workerProofBundleDetails.map((value, key) => (
        <TouchableOpacity
          style={style.eachAvatar}
          key={key}
          onPress={() => handleIconClick(value.worker.id)}
        >
          <View style={selectedTraineeId === value.worker.id && style.selectedAvatarHeader}>
            <Avatar
              name={value.worker.name}
              imgS3Key={value.worker.profilePicture}
              size={ImageSizes.Medium}
            />
            <View
              style={[
                style.subIcon,
                selectedTraineeId === value.worker.id && style.selectedSubIcon,
                (value.reviewState === API.ReviewState.VALIDATED ||
                  value.reviewState === API.ReviewState.REJECTED ||
                  value.reviewState === API.ReviewState.REJECTED_TO_RESUBMIT) && {
                  backgroundColor: Colors.Green,
                },
              ]}
            >
              {value.reviewState === API.ReviewState.VALIDATED ||
              value.reviewState === API.ReviewState.REJECTED ||
              value.reviewState === API.ReviewState.REJECTED_TO_RESUBMIT ? (
                <View style={{ height: 10, width: 10 }}>
                  <CheckIcon color={Colors.White} />
                </View>
              ) : value.reviewState === API.ReviewState.TO_REVIEW ? (
                <View style={style.toReviewIconContainer}>
                  <Text style={style.toReviewIcon}>...</Text>
                </View>
              ) : (
                <IconSVG svgComponent={plusIcon} color={Colors.White} />
              )}
            </View>
          </View>
        </TouchableOpacity>
      ))}
      {remainingCount() && (
        <TouchableOpacity style={style.eachAvatar} onPress={handleRightButton}>
          <View style={style.moreButton}>
            <Text style={style.moreButtonText}>{`+${remainingCount()}`}</Text>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};
