import React from 'react';
import WebModal from 'modal-react-native-web';
import { TouchableOpacity, View } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { Spacings } from 'shared/styles';

import { FileViewerWeb, FileViewerProps } from './index';
import { Styles } from './Styles';

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

interface Props extends FileViewerProps {
  setShowModal: (show: boolean) => void;
}

export const FullScreenProof: React.FC<Props> = props => {
  const { filePath, onClick, setShowModal, fileType } = props;

  return (
    <WebModal animationType="fade" onRequestClose={() => setShowModal(false)}>
      <TouchableOpacity style={Styles.crossIcon} onPress={() => setShowModal(false)}>
        <IconSVG
          svgComponent={closeIcon}
          size={{ width: Spacings.Standard, height: Spacings.Standard }}
        />
      </TouchableOpacity>
      <View style={Styles.proofContainer}>
        <FileViewerWeb filePath={filePath} onClick={onClick} fileType={fileType} fullScreenView />
      </View>
    </WebModal>
  );
};
