import React, { useState, useEffect, useContext } from 'react';
import { InteractionManager } from 'react-native';
import { CompanySettingsComponent } from '../component';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { getLanguage, setLanguageAndLoadGlossary, t } from 'shared/localisation/i18n';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { WorkstationWorkerLevels } from 'shared/backend-data';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { ModalUtils } from 'shared/ui-component/Modal/index';
import { DropDownWithWorkerLevelOption } from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';

export enum CompanySettingsInputIds {
  CompanyName = 'CompanyName',
  Language = 'Language',
  MentorMinLevel = 'MentorMinLevel',
  WorkstationTargetLevel = 'WorkstationTargetLevel',
  WorkerFamilyNameFirst = 'WorkerFamilyNameFirst',
  DisableLostLevelAlert = 'DisableLostLevelAlert',
  LevelIcon = 'LevelIcon',
}

export const HOUR_LABOUR_COST = 10;
export const CURRENCY = '€';

interface Props {}

export const CompanySettings: React.FC<Props> = props => {
  const mentorLevelOptions: DropDownOption[] = [
    {
      key: '' + API.WorkstationWorkerLevels.LEVEL1,
      label: t('common:tenant.mentorMinLevels.0'),
      value: WorkstationWorkerLevels.LEVEL1,
    },
    {
      key: '' + API.WorkstationWorkerLevels.LEVEL2,
      label: t('common:tenant.mentorMinLevels.1'),
      value: WorkstationWorkerLevels.LEVEL2,
    },
    {
      key: '' + API.WorkstationWorkerLevels.LEVEL3,
      label: t('common:tenant.mentorMinLevels.2'),
      value: WorkstationWorkerLevels.LEVEL3,
    },
    {
      key: '' + API.WorkstationWorkerLevels.LEVEL4,
      label: t('common:tenant.mentorMinLevels.3'),
      value: WorkstationWorkerLevels.LEVEL4,
    },
  ];

  const workstationTargetsStartingAtLevelOptions: DropDownOption[] = [
    {
      key: '' + API.WorkstationWorkerLevels.LEVEL2,
      label: t('common:tenant.workstationTargetLevels.0'),
      value: API.WorkstationWorkerLevels.LEVEL2,
    },
    {
      key: '' + API.WorkstationWorkerLevels.LEVEL3,
      label: t('common:tenant.workstationTargetLevels.1'),
      value: API.WorkstationWorkerLevels.LEVEL3,
    },
    {
      key: '' + API.WorkstationWorkerLevels.LEVEL4,
      label: t('common:tenant.workstationTargetLevels.2'),
      value: API.WorkstationWorkerLevels.LEVEL4,
    },
  ];

  const levelIcons: DropDownWithWorkerLevelOption[] = [
    {
      key: API.LevelIconType.LINE,
      label: t('common:tenant.levelIconLabel.2'),
      level: WorkstationWorkerLevels.LEVEL3,
      value: API.LevelIconType.LINE,
    },
    {
      key: API.LevelIconType.PIE,
      label: t('common:tenant.levelIconLabel.0'),
      level: WorkstationWorkerLevels.LEVEL3,
      value: API.LevelIconType.PIE,
    },
    {
      key: API.LevelIconType.SQUARE,
      label: t('common:tenant.levelIconLabel.1'),
      level: WorkstationWorkerLevels.LEVEL3,
      value: API.LevelIconType.SQUARE,
    },
  ];

  const nameDisplayOptions: DropDownOption[] = [
    {
      key: 'false',
      label: t('common:tenant.displayOfNames.1'),
      value: false,
    },
    {
      key: 'true',
      label: t('common:tenant.displayOfNames.2'),
      value: true,
    },
  ];

  const modal = ModalUtils.useModal();
  const isMounted = useIsMounted();
  const [companyName, setCompanyName] = useState<string>('');
  const [defaultLanguage, setDefaultLanguage] = useState<DropDownOption>();
  const [mentorMinLevel, setMentorMinLevel] = useState<DropDownOption>(mentorLevelOptions[2]);
  const [workstationTargetsStartingAtLevel, setWorkstationTargetsStartingAtLevel] =
    useState<DropDownOption>(workstationTargetsStartingAtLevelOptions[1]);
  const [levelIcon, setLevelIcon] = useState<DropDownWithWorkerLevelOption>(levelIcons[0]);
  const [tenant, setTenant] = useState<API.Tenant>();
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [glossaries, setGlossaries] = useState<string | null>();
  const [selectedNameDisplayOption, setSelectedNameDisplayOption] = useState<DropDownOption>(
    nameDisplayOptions[0],
  );
  const [isDisableLostLevelAlert, setIsDisableLostLevelAlert] = useState<boolean | null>(false);

  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      setLoading(true);
      setFirstTitle(t('common:menu.companySettings'));
      setSecondTitle('');

      const _tenant = await API.getTenant();
      if (!isMounted.current) return;
      if (API.isFailure(_tenant)) {
        logger.warn(_tenant);
        setLoading(false);
        return;
      }

      if (_tenant.isDisableLostLevelAlert != null)
        setIsDisableLostLevelAlert(_tenant.isDisableLostLevelAlert);

      if (_tenant.workerFamilyNameFirst) setSelectedNameDisplayOption(nameDisplayOptions[1]);

      setTenant(_tenant);
      setGlossaries(_tenant.glossary);
      setCompanyName(_tenant.name);
      if (_tenant.workstationTargetsStartingAtLevel) {
        setWorkstationTargetsStartingAtLevel({
          key: _tenant.workstationTargetsStartingAtLevel,
          value: API.api2workstationWorkerLevels(_tenant.workstationTargetsStartingAtLevel),
          label: getStartingLevelDropdownLabel(
            API.api2workstationWorkerLevels(_tenant.workstationTargetsStartingAtLevel),
          ),
        });
      }

      const _language = getLanguage();
      setDefaultLanguage({
        key: _language.locale,
        value: _language,
        label: _language.label,
      });

      const _mentorMinLevel = await API.getUserPreference(
        UserPreferenceKeys_SkillMgtApp.MentorMinLevel,
      );
      if (!isMounted.current) return;
      if (API.isFailure(_mentorMinLevel)) {
        logger.warn(_mentorMinLevel);
        return;
      }

      const found = mentorLevelOptions.find(
        mentorLevelOption => mentorLevelOption.value === _mentorMinLevel,
      );
      if (found) {
        setMentorMinLevel(found);
      }

      const _isAdmin = await API.isWorkerAdminOnRootUnit();
      if (API.isFailure(_isAdmin)) {
        logger.warn(_isAdmin);
        return;
      }

      setIsAdmin(_isAdmin);
      setLoading(false);
    });
  }, []);

  function getStartingLevelDropdownLabel(level: API.WorkstationWorkerLevels): string {
    switch (level) {
      case API.WorkstationWorkerLevels.LEVEL2:
        return t('common:tenant.workstationTargetLevels.0');
      case API.WorkstationWorkerLevels.LEVEL3:
        return t('common:tenant.workstationTargetLevels.1');
      case API.WorkstationWorkerLevels.LEVEL4:
        return t('common:tenant.workstationTargetLevels.2');
    }

    return '';
  }

  function handleInput(inputName: string, value: string) {
    if (inputName === CompanySettingsInputIds.CompanyName) {
      setCompanyName(value);
      setIsFormUpdated(true);
    }
  }

  function handleGlossaries(glossaries: string) {
    setGlossaries(glossaries);
  }

  function handleInputDropdownWithLevel(inputName: string, value?: DropDownWithWorkerLevelOption) {
    if (value) {
      setLevelIcon(value);
      setIsFormUpdated(true);
    }
  }

  function handleInputDropdown(inputName: string, value: DropDownOption) {
    setIsFormUpdated(true);
    if (inputName === CompanySettingsInputIds.Language) {
      setDefaultLanguage(value);
    } else if (inputName === CompanySettingsInputIds.MentorMinLevel) {
      setMentorMinLevel(value);
    } else if (inputName === CompanySettingsInputIds.WorkstationTargetLevel) {
      setWorkstationTargetsStartingAtLevel(value);
    } else if (inputName === CompanySettingsInputIds.WorkerFamilyNameFirst) {
      setSelectedNameDisplayOption(value);
    }
  }

  async function handleSubmit() {
    if (tenant) {
      setLoading(true);

      if (!isAdmin) {
        logger.warn('Unauthorized to change company settings: user is not admin');
        setLoading(false);
        return;
      }

      const _tenant = await API.updateTenant({
        ...API.deepClone(tenant),
        name: companyName,
        workstationTargetsStartingAtLevel: API.workstationWorkerLevels2api(
          workstationTargetsStartingAtLevel.value,
        ),
        glossary: glossaries,
        workerFamilyNameFirst: selectedNameDisplayOption.value,
        isDisableLostLevelAlert: isDisableLostLevelAlert,
        levelIconType: levelIcon?.value,
      });
      if (!isMounted.current) return;
      if (API.isFailure(_tenant)) {
        logger.warn(_tenant);
        setLoading(false);
        return;
      }

      const rootOrgUnit = API.Tree.getRootOrganizationalUnit();

      if (rootOrgUnit && rootOrgUnit.name !== companyName) {
        
        

        const _rootOrgUnit = await API.updateOrganizationalUnit({
          ...API.deepClone(rootOrgUnit),
          name: companyName,
        });

        if (!isMounted.current) return;
        if (API.isFailure(_rootOrgUnit)) {
          logger.warn(_rootOrgUnit);
          setLoading(false);
          return;
        }
      }

      await setLanguageAndLoadGlossary(defaultLanguage?.value);
      const result = await API.saveUserPreference<Locale>(
        API.UserPreferenceKeys_Common.UserLanguage,
        defaultLanguage?.value.locale,
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        logger.warn('Error while saving user language Preference', result);
      }

      if (mentorMinLevel?.value) {
        const saveMentorMinLevel = await API.saveUserPreference<WorkstationWorkerLevels>(
          UserPreferenceKeys_SkillMgtApp.MentorMinLevel,
          mentorMinLevel.value,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saveMentorMinLevel)) {
          logger.warn(saveMentorMinLevel);
          setLoading(false);
          return;
        }
      }

      setLoading(false);
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('common:tenant.settingsSaved'),
        }),
      );
    }
  }

  return (
    <CompanySettingsComponent
      functions={{
        handleInput,
        handleInputDropdown,
        handleInputDropdownWithLevel,
        setLoading,
        handleSubmit,
        handleGlossaries,
      }}
      data={{
        companyName,
        defaultLanguage,
        mentorMinLevel,
        loading,
        mentorLevelOptions,
        isFormUpdated,
        isAdmin,
        workstationTargetsStartingAtLevelOptions,
        workstationTargetsStartingAtLevel,
        nameDisplayOptions,
        selectedNameDisplayOption,
        isDisableLostLevelAlert,
        levelIcons,
        levelIcon,
      }}
    />
  );
};
