import { StyleSheet, ViewStyle, RegisteredStyle } from 'react-native';
import { TextFontStyles, Spacings, fontSize, Colors } from 'shared/styles/index';
export const LEFT_PANEL_WIDTH = 238;
export const SCROLLBAR_WIDTH = 28;
export const HEADER_HEIGHT = 128;
export const HEADER_TEXT_WIDTH = 160;
export const CELL_WIDTH = 52;
export const MAX_INDEX = 99999999;

const styles = StyleSheet.create({
  TableViewContainer: {
    width: '100%',
    height: '93%',
    flexDirection: 'row',
  },
  tableViewContainerFullScreen: {
    height: '90%',
  },
  welcomeMessageContainer: {
    paddingBottom: Spacings.Large - 1, 
    paddingTop: Spacings.Standard + Spacings.Medium + Spacings.Unit, 
    flex: 1,
    overflow: 'hidden',
    alignItems: 'center',
  },
  alexIconContaier: {
    marginBottom: Spacings.Standard, 
  },
  enterpriseIconContaier: {
    marginBottom: Spacings.Standard * 2 - Spacings.Unit, 
  },
  greetingText: {
    ...TextFontStyles.PermanentMarker,
    marginBottom: Spacings.Standard, 
    fontSize: fontSize.XXXLarge,
  },
  greetingInfoText: {
    width: 346,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    textAlign: 'center',
    marginBottom: Spacings.Standard + Spacings.Unit, 
  },
  actionButton: {
    width: 252,
    height: 36,
    backgroundColor: Colors.Yellow,
    justifyContent: 'center',
  },
  actionText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Medium,
    justifyContent: 'center',
  },
  leftContainer: {
    width: '20%',
    zIndex: 1,
    height: '100%',
  },
  rightContainer: {
    zIndex: 2,
  },
  activityIndicatorView: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorMessageContainer: {
    position: 'absolute',
    width: '100%',
    marginTop: HEADER_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topViewContainer: {
    height: Spacings.Big + Spacings.Unit,
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 2,
    flex: 1,
    flexDirection: 'row',
  },
  iconsContainer: {
    flexDirection: 'row',
  },
  iconContainer: { marginRight: Spacings.xMedium },
  outerViewFullScreen: { height: '100%', width: '100%' },
  fullScreenIconContainer: {
    marginRight: Spacings.xMedium,
    bottom: Spacings.Unit,
  },
  exportIconContainer: {
    bottom: 1,
    left: -Spacings.Unit,
  },
  errorMessageHeader: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Medium,
    marginBottom: Spacings.Medium,
  },
  errorMessageText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    fontSize: fontSize.Regular,
    maxWidth: '50%',
    textAlign: 'center',
  },
  dragableSideStyle: {
    width: '8%',
    cursor: 'col-resize',
    position: 'absolute',
    left: '93%',
    top: '8.5%',
    bottom: 0,
  },
  commonScrollView: {
    paddingBottom: Spacings.Small,
  },
  draggableBorder: {
    borderLeftColor: Colors.Yellow,
    borderLeftWidth: 2,
  },
  dragableBorderOnHover: {
    borderLeftColor: Colors.GreyLight,
    borderLeftWidth: 2,
  },
});

export const getCardStyle = (
  innerHeight: number,
  innerWidth: number,
): ViewStyle | RegisteredStyle<ViewStyle> => ({
  height: innerHeight,
  width: innerWidth,
  padding: 0,
});
export default styles;
