import React from 'react';
import {
  View,
  Text,
  StyleProp,
  TextStyle,
  ViewStyle,
  StyleSheet,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { TouchableOpacityPreventDoubleClick } from 'shared/ui-component/TouchableOpacityPreventDoubleClick';
import { Colors, Fonts, TextFontStyles, fontSize } from '../../styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { SvgProps } from 'react-native-svg';

/**
 * A button has a text / image or both
 * Styles can be overriden
 */
interface Props {
  text?: string | undefined;
  textStyle?: StyleProp<TextStyle>;
  onPress?: (() => void) | undefined;
  containerStyle?: StyleProp<ViewStyle>;
  icon?: React.FC<SvgProps> | undefined;
  textStyleContainer?: StyleProp<ViewStyle>;
  iconColor?: string;
  iconSize?: { width: number; height: number };
  counter?: number;
  disabled?: boolean | undefined;
  ref?: React.Ref<TouchableOpacity>;
  isHoverable?: boolean;
}

/**
 * Default style, can be overriden by ButtonProps
 */
const style = StyleSheet.create({
  buttonStyle: {
    backgroundColor: Colors.BlueSky,
    borderRadius: 5,
    padding: 6,
    alignItems: 'center',
    alignContent: 'center',
  },
  textStyle: {
    fontFamily: Platform.OS === 'web' ? Fonts.Lato.LatoWeb : Fonts.Lato.Regular,
    fontSize: 12,
    color: Colors.White,
    justifyContent: 'center',
    alignSelf: 'center',
    alignContent: 'center',
  },
  counterStyle: {
    minWidth: 30,
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.XXXLarge,
  },
  touchableContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  iconContainer: {
    marginLeft: 10,
  },
  disabled: {
    backgroundColor: Colors.Background,
  },
});

/**
 * Our main (Text) Button Component
 * This Component should not be called directly
 * But instead used to build StyledButtons
 * @param props
 */
export const AbstractButton: React.FC<Props> = React.forwardRef((props, ref) => {
  const {
    icon,
    iconColor,
    iconSize,
    text,
    textStyle,
    onPress,
    containerStyle,
    counter,
    textStyleContainer,
    isHoverable,
  } = props;

  
  
  
  

  
  
  
  

  return (
    <TouchableOpacityPreventDoubleClick
      onPress={onPress ? onPress : () => {}}
      activeOpacity={0.33}
      style={[style.buttonStyle, containerStyle, props.disabled && style.disabled]}
      disabled={props.disabled ?? undefined}
      ref={ref}
    >
      <View style={style.touchableContainer}>
        {icon && <IconSVG svgComponent={icon} color={iconColor} size={iconSize} />}
        {(counter || counter === 0) && <Text style={style.counterStyle}>{counter}</Text>}

        {}
        {text && (icon || counter || counter === 0) ? <View style={style.iconContainer} /> : null}

        {text && (
          <View style={textStyleContainer}>
            <Text style={[style.textStyle, textStyle]}>{text}</Text>
          </View>
        )}
      </View>
    </TouchableOpacityPreventDoubleClick>
  );
});
