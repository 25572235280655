/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../api-auto";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const inviteUser = /* GraphQL */ `mutation InviteUser($input: InviteUserInput) {
  inviteUser(input: $input)
}
` as GeneratedMutation<
  APITypes.InviteUserMutationVariables,
  APITypes.InviteUserMutation
>;
export const createFactory = /* GraphQL */ `mutation CreateFactory(
  $input: CreateFactoryInput!
  $condition: ModelFactoryConditionInput
) {
  createFactory(input: $input, condition: $condition) {
    pk
    sk
    dataType
    data
    createdAt
    owner
    updatedAt
    updatedBy
    _deleted
    _version
    _lastChangedAt
    tagSearch
    tenant {
      id
      name
      domain
      authenticationTypes
      logo
      glossary
      workstationTargetsStartingAtLevel
      workerFamilyNameFirst
      isDisableLostLevelAlert
      apps {
        id
        features {
          limit
          feature
          __typename
        }
        __typename
      }
      levelIconType
      __typename
    }
    tenantID {
      id
      __typename
    }
    app {
      id
      name
      logo
      version
      playStoreVersion
      appleStoreVersion
      __typename
    }
    role {
      id
      name
      permissions
      __typename
    }
    contractType {
      id
      name
      openEndDate
      isPauseContract
      __typename
    }
    organizationalUnit {
      id
      parentId
      name
      pathIds
      order
      shiftIds
      description
      thirdPartyIds
      __typename
    }
    workstation {
      id
      name
      parentId
      pathIds
      description
      order
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      shiftIds
      workersTargetOnShift
      __typename
    }
    shift {
      id
      parentId
      name
      color
      __typename
    }
    workerWorkstation {
      id
      workstationId
      workerId
      isTrainAuto
      warning
      targetLevel
      previousLevel
      activeTrainingSessions {
        workstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        nonWorkstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        isEverySkillToRenewOrAcquireCovered
        __typename
      }
      level
      numberOfRequiredSkills
      numberOfOkWorkerSkills
      validSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      validExpireSoonSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidExpiredSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidNoRefreshSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidMissingSkills {
        skillId
        requirmentIds
        __typename
      }
      __typename
    }
    worker {
      id
      userId
      email
      phone
      profilePicture
      matricule
      name
      firstName
      familyName
      tagIds
      contracts {
        contractTypeId
        startDate
        endDate
        comment
        __typename
      }
      description
      state
      isAdmin
      scope
      lastLogin
      __typename
    }
    workerTag {
      id
      name
      __typename
    }
    skill {
      id
      name
      isPractical
      description
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      skillIds
      tagIds
      validityDuration
      expiryNoticeDuration
      __typename
    }
    skillTag {
      id
      name
      __typename
    }
    training {
      id
      name
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      description
      notes
      tagIds
      defaultTrainerId
      maxTrainee
      __typename
    }
    trainingTag {
      id
      name
      __typename
    }
    trainingVersion {
      id
      version
      trainingId
      skillIds
      durationInMin
      __typename
    }
    trainingSession {
      id
      trainingVersionId
      requirementId
      originId
      requestState
      isDraft
      description
      location
      scheduledTrainers {
        trainerId
        percentage
        __typename
      }
      scheduledTraineeIds
      scheduledStartDate
      durationInMin
      calendarEvent {
        id
        sequence
        __typename
      }
      trainers {
        trainerId
        percentage
        __typename
      }
      trainerComment
      trainerFiles {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      traineeIds
      startDate
      endDate
      endDateLimit
      __typename
    }
    proofBundle {
      id
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      startingDate
      review {
        state
        date
        workerId
        __typename
      }
      description
      acquired
      originObjectId
      workerId
      skillId
      __typename
    }
    requirement {
      id
      linkedObjectId
      level
      skillTrainingVersions {
        skillId
        trainingVersionId
        __typename
      }
      practicalTrainingId
      __typename
    }
    workerSkill {
      id
      skillId
      workerId
      proofBundleIds
      activeProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      toReviewProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      validity
      __typename
    }
    workerTrainingSession {
      id
      trainingSessionId
      workerId
      __typename
    }
    userPreference {
      id
      userId
      data
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFactoryMutationVariables,
  APITypes.CreateFactoryMutation
>;
export const updateFactory = /* GraphQL */ `mutation UpdateFactory(
  $input: UpdateFactoryInput!
  $condition: ModelFactoryConditionInput
) {
  updateFactory(input: $input, condition: $condition) {
    pk
    sk
    dataType
    data
    createdAt
    owner
    updatedAt
    updatedBy
    _deleted
    _version
    _lastChangedAt
    tagSearch
    tenant {
      id
      name
      domain
      authenticationTypes
      logo
      glossary
      workstationTargetsStartingAtLevel
      workerFamilyNameFirst
      isDisableLostLevelAlert
      apps {
        id
        features {
          limit
          feature
          __typename
        }
        __typename
      }
      levelIconType
      __typename
    }
    tenantID {
      id
      __typename
    }
    app {
      id
      name
      logo
      version
      playStoreVersion
      appleStoreVersion
      __typename
    }
    role {
      id
      name
      permissions
      __typename
    }
    contractType {
      id
      name
      openEndDate
      isPauseContract
      __typename
    }
    organizationalUnit {
      id
      parentId
      name
      pathIds
      order
      shiftIds
      description
      thirdPartyIds
      __typename
    }
    workstation {
      id
      name
      parentId
      pathIds
      description
      order
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      shiftIds
      workersTargetOnShift
      __typename
    }
    shift {
      id
      parentId
      name
      color
      __typename
    }
    workerWorkstation {
      id
      workstationId
      workerId
      isTrainAuto
      warning
      targetLevel
      previousLevel
      activeTrainingSessions {
        workstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        nonWorkstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        isEverySkillToRenewOrAcquireCovered
        __typename
      }
      level
      numberOfRequiredSkills
      numberOfOkWorkerSkills
      validSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      validExpireSoonSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidExpiredSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidNoRefreshSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidMissingSkills {
        skillId
        requirmentIds
        __typename
      }
      __typename
    }
    worker {
      id
      userId
      email
      phone
      profilePicture
      matricule
      name
      firstName
      familyName
      tagIds
      contracts {
        contractTypeId
        startDate
        endDate
        comment
        __typename
      }
      description
      state
      isAdmin
      scope
      lastLogin
      __typename
    }
    workerTag {
      id
      name
      __typename
    }
    skill {
      id
      name
      isPractical
      description
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      skillIds
      tagIds
      validityDuration
      expiryNoticeDuration
      __typename
    }
    skillTag {
      id
      name
      __typename
    }
    training {
      id
      name
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      description
      notes
      tagIds
      defaultTrainerId
      maxTrainee
      __typename
    }
    trainingTag {
      id
      name
      __typename
    }
    trainingVersion {
      id
      version
      trainingId
      skillIds
      durationInMin
      __typename
    }
    trainingSession {
      id
      trainingVersionId
      requirementId
      originId
      requestState
      isDraft
      description
      location
      scheduledTrainers {
        trainerId
        percentage
        __typename
      }
      scheduledTraineeIds
      scheduledStartDate
      durationInMin
      calendarEvent {
        id
        sequence
        __typename
      }
      trainers {
        trainerId
        percentage
        __typename
      }
      trainerComment
      trainerFiles {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      traineeIds
      startDate
      endDate
      endDateLimit
      __typename
    }
    proofBundle {
      id
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      startingDate
      review {
        state
        date
        workerId
        __typename
      }
      description
      acquired
      originObjectId
      workerId
      skillId
      __typename
    }
    requirement {
      id
      linkedObjectId
      level
      skillTrainingVersions {
        skillId
        trainingVersionId
        __typename
      }
      practicalTrainingId
      __typename
    }
    workerSkill {
      id
      skillId
      workerId
      proofBundleIds
      activeProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      toReviewProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      validity
      __typename
    }
    workerTrainingSession {
      id
      trainingSessionId
      workerId
      __typename
    }
    userPreference {
      id
      userId
      data
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFactoryMutationVariables,
  APITypes.UpdateFactoryMutation
>;
export const deleteFactory = /* GraphQL */ `mutation DeleteFactory(
  $input: DeleteFactoryInput!
  $condition: ModelFactoryConditionInput
) {
  deleteFactory(input: $input, condition: $condition) {
    pk
    sk
    dataType
    data
    createdAt
    owner
    updatedAt
    updatedBy
    _deleted
    _version
    _lastChangedAt
    tagSearch
    tenant {
      id
      name
      domain
      authenticationTypes
      logo
      glossary
      workstationTargetsStartingAtLevel
      workerFamilyNameFirst
      isDisableLostLevelAlert
      apps {
        id
        features {
          limit
          feature
          __typename
        }
        __typename
      }
      levelIconType
      __typename
    }
    tenantID {
      id
      __typename
    }
    app {
      id
      name
      logo
      version
      playStoreVersion
      appleStoreVersion
      __typename
    }
    role {
      id
      name
      permissions
      __typename
    }
    contractType {
      id
      name
      openEndDate
      isPauseContract
      __typename
    }
    organizationalUnit {
      id
      parentId
      name
      pathIds
      order
      shiftIds
      description
      thirdPartyIds
      __typename
    }
    workstation {
      id
      name
      parentId
      pathIds
      description
      order
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      shiftIds
      workersTargetOnShift
      __typename
    }
    shift {
      id
      parentId
      name
      color
      __typename
    }
    workerWorkstation {
      id
      workstationId
      workerId
      isTrainAuto
      warning
      targetLevel
      previousLevel
      activeTrainingSessions {
        workstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        nonWorkstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        isEverySkillToRenewOrAcquireCovered
        __typename
      }
      level
      numberOfRequiredSkills
      numberOfOkWorkerSkills
      validSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      validExpireSoonSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidExpiredSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidNoRefreshSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidMissingSkills {
        skillId
        requirmentIds
        __typename
      }
      __typename
    }
    worker {
      id
      userId
      email
      phone
      profilePicture
      matricule
      name
      firstName
      familyName
      tagIds
      contracts {
        contractTypeId
        startDate
        endDate
        comment
        __typename
      }
      description
      state
      isAdmin
      scope
      lastLogin
      __typename
    }
    workerTag {
      id
      name
      __typename
    }
    skill {
      id
      name
      isPractical
      description
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      skillIds
      tagIds
      validityDuration
      expiryNoticeDuration
      __typename
    }
    skillTag {
      id
      name
      __typename
    }
    training {
      id
      name
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      description
      notes
      tagIds
      defaultTrainerId
      maxTrainee
      __typename
    }
    trainingTag {
      id
      name
      __typename
    }
    trainingVersion {
      id
      version
      trainingId
      skillIds
      durationInMin
      __typename
    }
    trainingSession {
      id
      trainingVersionId
      requirementId
      originId
      requestState
      isDraft
      description
      location
      scheduledTrainers {
        trainerId
        percentage
        __typename
      }
      scheduledTraineeIds
      scheduledStartDate
      durationInMin
      calendarEvent {
        id
        sequence
        __typename
      }
      trainers {
        trainerId
        percentage
        __typename
      }
      trainerComment
      trainerFiles {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      traineeIds
      startDate
      endDate
      endDateLimit
      __typename
    }
    proofBundle {
      id
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      startingDate
      review {
        state
        date
        workerId
        __typename
      }
      description
      acquired
      originObjectId
      workerId
      skillId
      __typename
    }
    requirement {
      id
      linkedObjectId
      level
      skillTrainingVersions {
        skillId
        trainingVersionId
        __typename
      }
      practicalTrainingId
      __typename
    }
    workerSkill {
      id
      skillId
      workerId
      proofBundleIds
      activeProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      toReviewProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      validity
      __typename
    }
    workerTrainingSession {
      id
      trainingSessionId
      workerId
      __typename
    }
    userPreference {
      id
      userId
      data
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFactoryMutationVariables,
  APITypes.DeleteFactoryMutation
>;
export const createFactoryInternally = /* GraphQL */ `mutation CreateFactoryInternally(
  $input: CreateFactoryInput!
  $condition: ModelFactoryConditionInput
) {
  createFactoryInternally(input: $input, condition: $condition) {
    pk
    sk
    dataType
    data
    createdAt
    owner
    updatedAt
    updatedBy
    _deleted
    _version
    _lastChangedAt
    tagSearch
    tenant {
      id
      name
      domain
      authenticationTypes
      logo
      glossary
      workstationTargetsStartingAtLevel
      workerFamilyNameFirst
      isDisableLostLevelAlert
      apps {
        id
        features {
          limit
          feature
          __typename
        }
        __typename
      }
      levelIconType
      __typename
    }
    tenantID {
      id
      __typename
    }
    app {
      id
      name
      logo
      version
      playStoreVersion
      appleStoreVersion
      __typename
    }
    role {
      id
      name
      permissions
      __typename
    }
    contractType {
      id
      name
      openEndDate
      isPauseContract
      __typename
    }
    organizationalUnit {
      id
      parentId
      name
      pathIds
      order
      shiftIds
      description
      thirdPartyIds
      __typename
    }
    workstation {
      id
      name
      parentId
      pathIds
      description
      order
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      shiftIds
      workersTargetOnShift
      __typename
    }
    shift {
      id
      parentId
      name
      color
      __typename
    }
    workerWorkstation {
      id
      workstationId
      workerId
      isTrainAuto
      warning
      targetLevel
      previousLevel
      activeTrainingSessions {
        workstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        nonWorkstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        isEverySkillToRenewOrAcquireCovered
        __typename
      }
      level
      numberOfRequiredSkills
      numberOfOkWorkerSkills
      validSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      validExpireSoonSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidExpiredSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidNoRefreshSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidMissingSkills {
        skillId
        requirmentIds
        __typename
      }
      __typename
    }
    worker {
      id
      userId
      email
      phone
      profilePicture
      matricule
      name
      firstName
      familyName
      tagIds
      contracts {
        contractTypeId
        startDate
        endDate
        comment
        __typename
      }
      description
      state
      isAdmin
      scope
      lastLogin
      __typename
    }
    workerTag {
      id
      name
      __typename
    }
    skill {
      id
      name
      isPractical
      description
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      skillIds
      tagIds
      validityDuration
      expiryNoticeDuration
      __typename
    }
    skillTag {
      id
      name
      __typename
    }
    training {
      id
      name
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      description
      notes
      tagIds
      defaultTrainerId
      maxTrainee
      __typename
    }
    trainingTag {
      id
      name
      __typename
    }
    trainingVersion {
      id
      version
      trainingId
      skillIds
      durationInMin
      __typename
    }
    trainingSession {
      id
      trainingVersionId
      requirementId
      originId
      requestState
      isDraft
      description
      location
      scheduledTrainers {
        trainerId
        percentage
        __typename
      }
      scheduledTraineeIds
      scheduledStartDate
      durationInMin
      calendarEvent {
        id
        sequence
        __typename
      }
      trainers {
        trainerId
        percentage
        __typename
      }
      trainerComment
      trainerFiles {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      traineeIds
      startDate
      endDate
      endDateLimit
      __typename
    }
    proofBundle {
      id
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      startingDate
      review {
        state
        date
        workerId
        __typename
      }
      description
      acquired
      originObjectId
      workerId
      skillId
      __typename
    }
    requirement {
      id
      linkedObjectId
      level
      skillTrainingVersions {
        skillId
        trainingVersionId
        __typename
      }
      practicalTrainingId
      __typename
    }
    workerSkill {
      id
      skillId
      workerId
      proofBundleIds
      activeProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      toReviewProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      validity
      __typename
    }
    workerTrainingSession {
      id
      trainingSessionId
      workerId
      __typename
    }
    userPreference {
      id
      userId
      data
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFactoryInternallyMutationVariables,
  APITypes.CreateFactoryInternallyMutation
>;
export const updateFactoryInternally = /* GraphQL */ `mutation UpdateFactoryInternally(
  $input: UpdateFactoryInput!
  $condition: ModelFactoryConditionInput
) {
  updateFactoryInternally(input: $input, condition: $condition) {
    pk
    sk
    dataType
    data
    createdAt
    owner
    updatedAt
    updatedBy
    _deleted
    _version
    _lastChangedAt
    tagSearch
    tenant {
      id
      name
      domain
      authenticationTypes
      logo
      glossary
      workstationTargetsStartingAtLevel
      workerFamilyNameFirst
      isDisableLostLevelAlert
      apps {
        id
        features {
          limit
          feature
          __typename
        }
        __typename
      }
      levelIconType
      __typename
    }
    tenantID {
      id
      __typename
    }
    app {
      id
      name
      logo
      version
      playStoreVersion
      appleStoreVersion
      __typename
    }
    role {
      id
      name
      permissions
      __typename
    }
    contractType {
      id
      name
      openEndDate
      isPauseContract
      __typename
    }
    organizationalUnit {
      id
      parentId
      name
      pathIds
      order
      shiftIds
      description
      thirdPartyIds
      __typename
    }
    workstation {
      id
      name
      parentId
      pathIds
      description
      order
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      shiftIds
      workersTargetOnShift
      __typename
    }
    shift {
      id
      parentId
      name
      color
      __typename
    }
    workerWorkstation {
      id
      workstationId
      workerId
      isTrainAuto
      warning
      targetLevel
      previousLevel
      activeTrainingSessions {
        workstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        nonWorkstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        isEverySkillToRenewOrAcquireCovered
        __typename
      }
      level
      numberOfRequiredSkills
      numberOfOkWorkerSkills
      validSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      validExpireSoonSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidExpiredSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidNoRefreshSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidMissingSkills {
        skillId
        requirmentIds
        __typename
      }
      __typename
    }
    worker {
      id
      userId
      email
      phone
      profilePicture
      matricule
      name
      firstName
      familyName
      tagIds
      contracts {
        contractTypeId
        startDate
        endDate
        comment
        __typename
      }
      description
      state
      isAdmin
      scope
      lastLogin
      __typename
    }
    workerTag {
      id
      name
      __typename
    }
    skill {
      id
      name
      isPractical
      description
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      skillIds
      tagIds
      validityDuration
      expiryNoticeDuration
      __typename
    }
    skillTag {
      id
      name
      __typename
    }
    training {
      id
      name
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      description
      notes
      tagIds
      defaultTrainerId
      maxTrainee
      __typename
    }
    trainingTag {
      id
      name
      __typename
    }
    trainingVersion {
      id
      version
      trainingId
      skillIds
      durationInMin
      __typename
    }
    trainingSession {
      id
      trainingVersionId
      requirementId
      originId
      requestState
      isDraft
      description
      location
      scheduledTrainers {
        trainerId
        percentage
        __typename
      }
      scheduledTraineeIds
      scheduledStartDate
      durationInMin
      calendarEvent {
        id
        sequence
        __typename
      }
      trainers {
        trainerId
        percentage
        __typename
      }
      trainerComment
      trainerFiles {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      traineeIds
      startDate
      endDate
      endDateLimit
      __typename
    }
    proofBundle {
      id
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      startingDate
      review {
        state
        date
        workerId
        __typename
      }
      description
      acquired
      originObjectId
      workerId
      skillId
      __typename
    }
    requirement {
      id
      linkedObjectId
      level
      skillTrainingVersions {
        skillId
        trainingVersionId
        __typename
      }
      practicalTrainingId
      __typename
    }
    workerSkill {
      id
      skillId
      workerId
      proofBundleIds
      activeProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      toReviewProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      validity
      __typename
    }
    workerTrainingSession {
      id
      trainingSessionId
      workerId
      __typename
    }
    userPreference {
      id
      userId
      data
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFactoryInternallyMutationVariables,
  APITypes.UpdateFactoryInternallyMutation
>;
export const deleteFactoryInternally = /* GraphQL */ `mutation DeleteFactoryInternally(
  $input: DeleteFactoryInput!
  $condition: ModelFactoryConditionInput
) {
  deleteFactoryInternally(input: $input, condition: $condition) {
    pk
    sk
    dataType
    data
    createdAt
    owner
    updatedAt
    updatedBy
    _deleted
    _version
    _lastChangedAt
    tagSearch
    tenant {
      id
      name
      domain
      authenticationTypes
      logo
      glossary
      workstationTargetsStartingAtLevel
      workerFamilyNameFirst
      isDisableLostLevelAlert
      apps {
        id
        features {
          limit
          feature
          __typename
        }
        __typename
      }
      levelIconType
      __typename
    }
    tenantID {
      id
      __typename
    }
    app {
      id
      name
      logo
      version
      playStoreVersion
      appleStoreVersion
      __typename
    }
    role {
      id
      name
      permissions
      __typename
    }
    contractType {
      id
      name
      openEndDate
      isPauseContract
      __typename
    }
    organizationalUnit {
      id
      parentId
      name
      pathIds
      order
      shiftIds
      description
      thirdPartyIds
      __typename
    }
    workstation {
      id
      name
      parentId
      pathIds
      description
      order
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      shiftIds
      workersTargetOnShift
      __typename
    }
    shift {
      id
      parentId
      name
      color
      __typename
    }
    workerWorkstation {
      id
      workstationId
      workerId
      isTrainAuto
      warning
      targetLevel
      previousLevel
      activeTrainingSessions {
        workstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        nonWorkstationActiveTrainingSessions {
          lowerOrEqualToTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          greaterThanTarget {
            fromNonInheritedRequirements
            fromInheritedRequirements
            __typename
          }
          __typename
        }
        isEverySkillToRenewOrAcquireCovered
        __typename
      }
      level
      numberOfRequiredSkills
      numberOfOkWorkerSkills
      validSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      validExpireSoonSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidExpiredSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidNoRefreshSkills {
        workerSkillId
        requirmentIds
        __typename
      }
      invalidMissingSkills {
        skillId
        requirmentIds
        __typename
      }
      __typename
    }
    worker {
      id
      userId
      email
      phone
      profilePicture
      matricule
      name
      firstName
      familyName
      tagIds
      contracts {
        contractTypeId
        startDate
        endDate
        comment
        __typename
      }
      description
      state
      isAdmin
      scope
      lastLogin
      __typename
    }
    workerTag {
      id
      name
      __typename
    }
    skill {
      id
      name
      isPractical
      description
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      skillIds
      tagIds
      validityDuration
      expiryNoticeDuration
      __typename
    }
    skillTag {
      id
      name
      __typename
    }
    training {
      id
      name
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      description
      notes
      tagIds
      defaultTrainerId
      maxTrainee
      __typename
    }
    trainingTag {
      id
      name
      __typename
    }
    trainingVersion {
      id
      version
      trainingId
      skillIds
      durationInMin
      __typename
    }
    trainingSession {
      id
      trainingVersionId
      requirementId
      originId
      requestState
      isDraft
      description
      location
      scheduledTrainers {
        trainerId
        percentage
        __typename
      }
      scheduledTraineeIds
      scheduledStartDate
      durationInMin
      calendarEvent {
        id
        sequence
        __typename
      }
      trainers {
        trainerId
        percentage
        __typename
      }
      trainerComment
      trainerFiles {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      traineeIds
      startDate
      endDate
      endDateLimit
      __typename
    }
    proofBundle {
      id
      files {
        bucket
        region
        key
        fileName
        visibility
        owner
        __typename
      }
      startingDate
      review {
        state
        date
        workerId
        __typename
      }
      description
      acquired
      originObjectId
      workerId
      skillId
      __typename
    }
    requirement {
      id
      linkedObjectId
      level
      skillTrainingVersions {
        skillId
        trainingVersionId
        __typename
      }
      practicalTrainingId
      __typename
    }
    workerSkill {
      id
      skillId
      workerId
      proofBundleIds
      activeProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      toReviewProofBundle {
        id
        files {
          bucket
          region
          key
          fileName
          visibility
          owner
          __typename
        }
        startingDate
        review {
          state
          date
          workerId
          __typename
        }
        description
        acquired
        originObjectId
        workerId
        skillId
        __typename
      }
      validity
      __typename
    }
    workerTrainingSession {
      id
      trainingSessionId
      workerId
      __typename
    }
    userPreference {
      id
      userId
      data
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFactoryInternallyMutationVariables,
  APITypes.DeleteFactoryInternallyMutation
>;
