import { StyleSheet } from 'react-native';
import { Colors, fontSize, Spacings, TextFontStyles } from '../../../styles';

const styles = StyleSheet.create({
  rootContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: Spacings.Small,
    paddingTop: Spacings.xMedium,
  },
  text: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
});

export default styles;
