import React, { useEffect, useState, useContext } from 'react';
import * as API from 'shared/backend-data';
import { View } from 'react-native';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import ReactDOM from 'react-dom';
import { Spacings } from 'shared/styles';
import { Card } from 'shared/ui-component/Card';
import styles from './styles';
import { WorkstationTargetCounts } from '../../../banner/workstationBanner/WorkstationTargetCounts';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';

interface Props {
  targetsMenuElement: Element;
  parentPanelElement?: Element;
  selectedWorkstationFooterData: API.WorkstationTargetActualWithDetails;
  filterData?: {
    workers?: API.Worker[];
  };

  setOpenTargetsMenu: (bool: boolean) => void;
  setTreeNode?: (treeNode: API.TreeNode<API.TreeDataType> | undefined) => void;
  handleOnPress?: () => void;
}

export const WorkstationTargetsMenu: React.FC<Props> = props => {
  const {
    targetsMenuElement,
    parentPanelElement,
    selectedWorkstationFooterData,
    filterData,

    setOpenTargetsMenu,
    setTreeNode,
    handleOnPress,
  } = props;

  const [cordinates, setCordinates] = useState<{ x: number; y: number }>();

  const myRef = useDetectOutsideClick<View>(closeMenu);

  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);

  useEffect(() => {
    if (myRef.current && parentPanelElement) {
      const cordinates = { x: 0, y: 0 };
      const node = ReactDOM.findDOMNode(myRef.current) as Element;
      cordinates.x =
        targetsMenuElement.getBoundingClientRect().x +
        targetsMenuElement.getBoundingClientRect().width -
        parentPanelElement.getBoundingClientRect().left -
        Spacings.Medium;

      const modalHeightOffset =
        targetsMenuElement.getBoundingClientRect().y + node.clientHeight - Spacings.Standard;

      if (window.innerHeight > modalHeightOffset) {
        cordinates.y =
          targetsMenuElement.getBoundingClientRect().y -
          Spacings.Standard -
          parentPanelElement.getBoundingClientRect().top;
      } else {
        cordinates.y =
          targetsMenuElement.getBoundingClientRect().bottom -
          node.clientHeight -
          parentPanelElement.getBoundingClientRect().top;
      }
      setCordinates(cordinates);
    }
  }, [targetsMenuElement, parentPanelElement]);

  function closeMenu() {
    setOpenTargetsMenu(false);
    if (setTreeNode) setTreeNode(undefined);
  }

  if (!selectedWorkstationFooterData.workstation) return <></>;

  return (
    <Card
      ref={myRef}
      style={[
        styles.CardContainer,
        cordinates ? { left: cordinates.x, top: cordinates.y } : { opacity: 0 },
      ]}
    >
      <WorkstationTargetCounts
        workstations={[selectedWorkstationFooterData.workstation]}
        needIdealCount
        selectedShift={selectedWorkstationFooterData.shift}
        workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
        handleOnPress={handleOnPress}
        filterData={filterData}
      />
    </Card>
  );
};
