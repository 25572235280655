export { InputSearch } from './InputSearch';


export { InputTag } from './InputTag';
export { Tag } from './Tag';

export { InputText } from './InputText/InputText';
export { ParserType } from './InputText/InputText';

export { InputNumber } from './InputNumber';
export { InputVerificationCode } from './InputVerificationCode';
