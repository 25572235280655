import { StyleSheet } from 'react-native';
import { Spacings, Colors, fontSize } from 'shared/styles';
import { TextFontStyles } from 'shared/styles/TextStyles';

const styles = StyleSheet.create({
  menuContainer: {
    position: 'absolute',
    right: 30,
    zIndex: 9,
    maxWidth: 309,
    maxHeight: 359,
    paddingLeft: Spacings.Standard - Spacings.Unit,
    paddingRight: Spacings.Medium,
    paddingVertical: 27,
  },
  menuOptionToucableOpacity: {
    marginBottom: Spacings.Standard,
  },
  optionText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Unit,
  },
  optionDescriptionText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    fontSize: fontSize.Small,
  },
  deleteOptionText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Regular,
    color: Colors.Red,
    marginBottom: Spacings.Unit,
  },
  menuIconContainer: {
    position: 'absolute',
    zIndex: 999,
    right: 10,
    top: 15,
  },
});

export default styles;
