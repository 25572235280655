import React, { useState, useEffect, useRef } from 'react';
import { View, SafeAreaView, TouchableOpacity, FlatList, InteractionManager } from 'react-native';
import Modal from 'react-native-modal';
import { InputSearch } from 'shared/ui-component/Input';
import { Tag, escapeRegExp } from 'shared/ui-component/Input/InputTag';
import { IconSVG } from 'shared/ui-component/Icon';
import DownIcon from 'shared/assets/svg/icon.arrowDown.mobile.svg';
import CheckedIcon from 'shared/assets/svg/icon.checked.svg';
import { Checkbox } from 'shared/ui-component/Checkbox';
import { YellowButton } from 'shared/ui-component/Button';
import logger from 'shared/util/Logger';
import style from './Styles';
import { Colors, Spacings } from 'shared/styles';
import { AnimatedTab, Tab } from '../../layout/animated-tab/AnimatedTab';
import * as _ from 'lodash-es';
import { deepClone } from 'backend/src/api';
import { SearchItem } from './SearchItem';
import { useIsMounted } from 'shared/hooks/IsMounted';

export interface SearchScreenProps {
  showModal: boolean;
  placeholder: string;
  listOptions: SearchList[];
  initialList: SearchList[];
  multiCheck?: boolean | undefined;
  isTabView?: boolean | undefined;
  activeTabIndex?: number | undefined;
  onTabPress?: ((index: number) => void) | undefined;
  renderOption?: ((value: any) => JSX.Element) | undefined;
  toggleSearchScreen: () => void;
  handleSelectedTags: (values: SearchList[]) => void;
}

interface SearchListTab extends Tab {
  warningMessage?: string;
}

export interface SearchList extends Tag {
  isParentTagNotClickable?: boolean;
  children?: SearchList[];
  tab?: SearchListTab;
  checkBoxTitle?: string;
}

export const SearchScreen: React.FC<SearchScreenProps> = props => {
  const {
    placeholder,
    showModal,
    initialList,
    listOptions,
    multiCheck,
    isTabView,
    activeTabIndex,

    renderOption,
    onTabPress,
    handleSelectedTags,
    toggleSearchScreen,
  } = props;

  const searchListBatchCount = 15;

  const isMounted = useIsMounted();

  const [autoCompleteResult, setAutoCompleteResult] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<SearchList[]>(initialList);
  const [activeIndex, setActiveIndex] = useState<number>(activeTabIndex ?? 0);
  const [textInput, setTextInput] = useState<string>('');
  const [batchSize, setBatchSize] = useState<number>(searchListBatchCount);
  const [searchListItems, setSearchListItems] = useState<SearchList[]>([]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;
      setSelectedTags(initialList);

      if (activeTabIndex !== undefined) {
        setActiveIndex(activeTabIndex ?? 0);
      }
    });
  }, [activeTabIndex, initialList]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;
      setSearchListItems(listOptions);
    });
  }, [listOptions]);

  function onTyping(input: string) {
    if (input === '') {
      clearInput();
    } else {
      setAutoCompleteResult(autoComplete(input));
    }
    setTextInput(input);
  }

  function clearInput() {
    setAutoCompleteResult([]);
    setTextInput('');
  }

  function filterTag(tags: SearchList[], inputText: string): SearchList[] {
    const regex = RegExp(escapeRegExp(inputText), 'i');
    return tags.filter((tag: SearchList) => {
      return regex.test(tag.label);
    });
  }

  function autoComplete(inputText: string): SearchList[] {
    let suggestions: SearchList[] = [];
    const _listOptions = deepClone(searchListItems);
    if (_listOptions.length) {
      _listOptions.forEach((eachList, index) => {
        if (!eachList.isParentTagNotClickable) {
          suggestions = [...suggestions, ...filterTag([eachList], inputText)];
        } else {
          suggestions.push({
            key: eachList.key,
            label: eachList.label,
            isParentTagNotClickable: true,
            children: [],
          });
        }
        if (eachList.children && suggestions[index] && suggestions[index].children) {
          suggestions[index].children = filterTag(eachList.children, inputText);
        } else if (eachList.children) {
          suggestions = [...suggestions, ...filterTag(eachList.children, inputText)];
        }
      });
    }

    return suggestions;
  }

  function _handleSelectedTag(tag: SearchList) {
    if (!multiCheck) {
      handleSelectedTags([tag]);
      handleCloseModal();
    }

    const _tags = [...selectedTags];
    const isAlreadySelected = _tags.find(eachData => eachData.key === tag.key);
    if (isAlreadySelected) {
      const index = _tags.indexOf(isAlreadySelected);
      _tags.splice(index, 1);
    } else {
      _tags.push(tag);
    }

    setSelectedTags(_tags);
  }

  function onEndReached() {
    setBatchSize(batchSize + searchListBatchCount);
  }

  const onPressClearButton = () => {
    clearInput();
  };

  const handleCloseModal = () => {
    toggleSearchScreen();
    clearInput();
    setSelectedTags([]);
  };

  const handleYellowButton = () => {
    handleSelectedTags(selectedTags);
    handleCloseModal();
  };

  function handleTabPress(index: number) {
    setActiveIndex(index);
    onTabPress && onTabPress(index);
  }

  function getListOptions() {
    let finalResult: SearchList[] = [];
    let warningMessage: string | undefined = '';

    if (autoCompleteResult.length) {
      finalResult = autoCompleteResult;
    } else if (isTabView && searchListItems[activeIndex]) {
      finalResult = [searchListItems[activeIndex]];
      warningMessage = searchListItems[activeIndex].tab?.warningMessage;
    } else if (textInput === '' && searchListItems.length) {
      finalResult = searchListItems;
    }
    if (!finalResult.length) return [];

    return finalResult.slice(0, batchSize);
  }

  return showModal ? (
    <Modal isVisible={showModal} style={style.modalContainer}>
      <SafeAreaView style={style.panel}>
        <View style={style.inputContainer}>
          <TouchableOpacity onPress={() => handleCloseModal()}>
            <IconSVG
              svgComponent={DownIcon}
              color={Colors.Black}
              size={{ height: Spacings.MobileIconSize, width: Spacings.MobileIconSize }}
            />
          </TouchableOpacity>
          <InputSearch
            onInputTextChange={text => onTyping(text)}
            onPressClearButton={onPressClearButton}
            placeHolder={placeholder}
          />
        </View>
        {isTabView && !textInput && (
          <View
            style={{
              marginHorizontal: Spacings.Medium,
              marginTop: Spacings.Small,
            }}
          >
            <AnimatedTab
              currentTabIndex={activeIndex}
              tabs={searchListItems.map(eachList => eachList.tab!)}
              onTabPress={handleTabPress}
              tabTextStyle={style.tabTextStyle}
            />
          </View>
        )}
        <View style={style.resultContainer}>
          <FlatList
            data={getListOptions()}
            renderItem={({ item }) => (
              <View>
                <SearchItem
                  tag={item}
                  renderOption={renderOption}
                  textInput={textInput}
                  multiCheck={multiCheck}
                  handleSelectedTag={_handleSelectedTag}
                  tags={selectedTags}
                />
                {item.children &&
                  item.children.map(child => (
                    <SearchItem
                      tag={child}
                      renderOption={renderOption}
                      textInput={textInput}
                      multiCheck={multiCheck}
                      handleSelectedTag={_handleSelectedTag}
                      tags={selectedTags}
                    />
                  ))}
              </View>
            )}
            keyExtractor={item => item.key}
            onEndReached={onEndReached}
            onEndReachedThreshold={0}
          />
        </View>
        {multiCheck && (
          <YellowButton
            icon={CheckedIcon}
            style={
              selectedTags.length || initialList.length
                ? style.yellowButton
                : [style.disable, style.yellowButton]
            }
            onPress={handleYellowButton}
          />
        )}
      </SafeAreaView>
    </Modal>
  ) : null;
};
