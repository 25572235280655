import React, { useContext, useState, useEffect } from 'react';
import { View, Text, InteractionManager } from 'react-native';
import Styles from './Style';
import * as API from 'shared/backend-data';
import { YellowButton } from 'shared/ui-component/Button';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import { Colors } from 'shared/styles/Colors';
import {
  DropDownSingleSelection,
  DropDownOption,
  DropDownMultiSelection,
} from 'shared/ui-component/DropDown/DropDown';
import { t, capitalizeFirstLetter } from 'shared/localisation/i18n';
import { ValidityDisplay } from 'shared/ui-component/validity-display/ValidityDisplay';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import moment from 'moment';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import * as _ from 'lodash-es';
import { AcquiredAndNotAcquiredButtons } from 'shared/layout/summaryBook/AcquiredAndNotAcquiredButtons/AcquiredAndNotAcquiredButtons';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';
import { MyHub } from 'shared/util/MyHub';


interface RightPanelProps {
  validData: boolean;
  comment?: string;
  selectedSkill?: API.Skill;
  workerName?: string;
  preSelectedSkill?: API.Skill;
  proofAdded?: boolean;
  preSelectedWorker?: API.Worker;
  skillObtentionDate?: Date;
  selectedWorkerTags: DropDownOption[];
  training?: DropDownOption;
  trainingSkill?: API.Skill;
  reviewState?: API.ReviewState;
  selectedTrainerTags?: DropDownOption[];
  disableWorkerSelection?: boolean;
  isSkillsOrWorkersCountGreaterThanOne: boolean;
  isSkillAcquired: number;

  listenDropdownChange: (dropdownValueCount?: number) => void;
  setSkillObtentionDate: (date: Date) => void;
  setComment: (comment: string) => void;
  setSelectedSkill: React.Dispatch<React.SetStateAction<API.Skill | undefined>>;
  handleSubmitButton: () => void;
  reviewProofBundle: (reject: boolean) => void;
  setSelectedWorkerTags: (tags: DropDownOption[]) => void;
  setTraining: (tag?: DropDownOption) => void;
  getTrainingSkills: (trainingVersionId: string) => void;
  navigateToTrainingBook: () => void;
  setIsSkillAcquired: React.Dispatch<React.SetStateAction<number>>;
}

const IconCheck = require('shared/assets/svg/icon.check.svg').default;
const lectureIcon = require('shared/assets/svg/icon.lecture.svg').default;
const practicalTrainingIcon = require('shared/assets/svg/icon.practTrain.svg').default;
const WorkerIcon = require('shared/assets/svg/icon.worker.svg').default;

enum InputIds {
  WorkerName = 'WorkerName',
  TrainingName = 'TrainingName',
  Comments = 'Comments',
}

export const RightPanel: React.FC<RightPanelProps> = props => {
  const {
    selectedSkill,
    workerName,
    validData,
    proofAdded,
    skillObtentionDate,
    comment,
    selectedWorkerTags,
    training,
    trainingSkill,
    reviewState,
    selectedTrainerTags,
    disableWorkerSelection,
    isSkillsOrWorkersCountGreaterThanOne,
    isSkillAcquired,

    setIsSkillAcquired,
    listenDropdownChange,
    reviewProofBundle,
    setSkillObtentionDate,
    setComment,
    handleSubmitButton,
    setSelectedWorkerTags,
    setTraining,
    getTrainingSkills,
    navigateToTrainingBook,
  } = props;
  const isMounted = useIsMounted();

  const [trainingOptions, setTrainingOptions] = useState<DropDownOption[]>([]);
  const [workerOptions, setWorkerOptions] = useState<DropDownOption[]>([]);
  const [trainings, setTrainings] = useState<API.Training[]>([]);
  const [workers, setWorkers] = useState<API.Worker[]>([]);

  const { isValidPermission } = useContext(PermissionManagementContext);

  const { errors, onBlur, onChange, onSubmit, refs } = useCustomForm<string>(
    {
      [InputIds.WorkerName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
      [InputIds.TrainingName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
      [InputIds.Comments]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
    },
    handleSubmitButton,
  );

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async payload => {
      switch (true) {
        case payload.data.factory.dataType === API.DataType.WORKER:
          await fetchWorkers();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.TRAINING:
          await fetchTrainings();
          if (!isMounted.current) return;
          break;
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await fetchWorkers();
      if (!isMounted.current) return;

      await fetchTrainings();
      if (!isMounted.current) return;
    });

    return () => {
      removeListener();
    };
  }, []);

  async function fetchWorkers() {
    const __workers = await API.getWorkers();
    if (API.isFailure(__workers)) return __workers;
    setWorkers(__workers.result);
  }
  async function fetchTrainings() {
    const __trainings = await API.getTrainings();
    if (API.isFailure(__trainings)) return __trainings;
    setTrainings(__trainings.result);
  }

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const _workers: DropDownOption[] = workers.map(eachWorker => {
        return {
          label: capitalizeFirstLetter(eachWorker.name),
          key: eachWorker.id,
          value: eachWorker,
        };
      });
      setWorkerOptions(_.sortBy(_workers, eachWorker => eachWorker.label));

      const _trainingOptions = await getTrainingOptions();

      setTrainingOptions(_.sortBy(_trainingOptions, trainingOption => trainingOption.label));
    });
  }, [workers, trainings]);

  async function getTrainingOptions() {
    const trainingOptions: DropDownOption[] = [];
    await Promise.all(
      trainings.map(async eachTraining => {
        const trainingVersion = await API.getTrainingVersionLatestForTraining(eachTraining.id);
        if (API.isFailure(trainingVersion)) {
          logger.warn('Failed to fetch type of training version');
          return;
        }
        const _isPractical = await API.isPracticalTrainingVersion(trainingVersion.id);

        if (API.isFailure(_isPractical)) {
          logger.warn('Failed to fetch type of training version');
          return;
        }
        trainingOptions.push({
          label: eachTraining.name,
          key: eachTraining.id,
          tagIcon: _isPractical ? practicalTrainingIcon : lectureIcon,
        });
      }),
    );
    return trainingOptions;
  }

  async function handleTrainingDropdown(value?: DropDownOption) {
    if (value) {
      setTraining(value);
      const trainingVersion = await API.getTrainingVersionsForTraining(value.key, true);
      if (!isMounted.current) return;
      if (API.isFailure(trainingVersion)) {
        logger.error(trainingVersion);
        return;
      }

      if (trainingVersion[0]) getTrainingSkills(trainingVersion[0].id);
    }
  }

  function handleWorkerChange(inputId: string, workers?: DropDownOption[]) {
    if (workers) {
      setSelectedWorkerTags(workers);
      listenDropdownChange(workers?.length ?? 0);
    }
  }

  function enableGenerateSkillBook(): boolean {
    return !!(selectedWorkerTags?.length && selectedWorkerTags?.length > 1);
  }

  function handleRadioButtons(item: string, index: number): void {
    setIsSkillAcquired(index);
  }

  const renderRightPanel = () => {
    return (
      <View style={Styles.rightPanelContainer}>
        <View style={Styles.rightPanelInputsContainer}>
          <DropDownSingleSelection
            inputId={InputIds.TrainingName}
            placeholder={t('glossary:training')}
            title={t('glossary:training')}
            containerStyle={Styles.skillDropDown}
            inputListContainerStyle={Styles.fullWidth}
            notEditable={!!reviewState}
            value={training}
            dropDownMenuContainerStyle={Styles.trainingMenuContainer}
            options={trainingOptions}
            handleChange={(inputId, values) => {
              handleTrainingDropdown(values);
            }}
            errorMessage={
              proofAdded ? (training ? undefined : t('common:error.validationError')) : undefined
            }
            hasSearch
          />
          <DropDownMultiSelection
            inputId={InputIds.WorkerName}
            values={selectedWorkerTags}
            placeholder={t('alex:workerSkillReviewModal.chooseWorker')}
            containerStyle={Styles.workerDropDown}
            inputListContainerStyle={Styles.fullWidth}
            title={t('glossary:worker', { count: selectedWorkerTags.length })}
            notEditable={!!reviewState || disableWorkerSelection}
            options={workerOptions}
            handleChange={handleWorkerChange}
            listIcon={WorkerIcon}
          />

          {!isSkillsOrWorkersCountGreaterThanOne && (
            <AcquiredAndNotAcquiredButtons
              onChange={handleRadioButtons}
              initialItemIndex={isSkillAcquired}
              radioButtonColor={Colors.Black}
              textStyle={{ color: Colors.Black }}
              disabled={!!reviewState}
            />
          )}

          {!isSkillsOrWorkersCountGreaterThanOne ? (
            <>
              <View style={Styles.obtentionDateAndValidityContainer}>
                <InputDateWeb
                  hasDateIcon
                  inputPlaceholder={t('alex:workerSkillReviewModal.obtention')}
                  onChange={(inputName, date) => {
                    
                    const currentDate = new Date();
                    date.setHours(currentDate.getHours());
                    date.setMinutes(currentDate.getMinutes());
                    date.setSeconds(currentDate.getSeconds());
                    setSkillObtentionDate(date);
                  }}
                  inputName={t('alex:workerSkillReviewModal.obtention')}
                  value={skillObtentionDate}
                  maxDate={new Date()}
                  notEditable={
                    reviewState === API.ReviewState.VALIDATED ||
                    reviewState === API.ReviewState.REJECTED ||
                    reviewState === API.ReviewState.REJECTED_TO_RESUBMIT
                  }
                />
                {(selectedSkill || trainingSkill) && (
                  <ValidityDisplay
                    startingDate={skillObtentionDate}
                    skill={selectedSkill || trainingSkill}
                  />
                )}
              </View>
              {selectedTrainerTags && (
                <DropDownMultiSelection
                  values={selectedTrainerTags}
                  placeholder={t('alex:workerSkillReviewModal.chooseWorker')}
                  containerStyle={Styles.trainerDropDown}
                  inputListContainerStyle={Styles.fullWidth}
                  title={t('glossary:trainer')}
                  notEditable
                  options={workerOptions}
                />
              )}
              <View>
                <InputTextDescription
                  inputRef={refs[InputIds.Comments]}
                  placeHolder={t('alex:workerSkillReviewModal.comments')}
                  inputId={InputIds.Comments}
                  style={Styles.commentsContainer}
                  notEditable={!!reviewState}
                  onTextChange={(textid, text) => {
                    setComment(text);
                    onChange(textid, text);
                  }}
                  onBlur={onBlur}
                  errorMessage={errors[InputIds.Comments]?.inputErrorMessage}
                  onSubmitEditing={onSubmit}
                  defaultValue={comment}
                  label={t('alex:workerSkillReviewModal.comments')}
                />
              </View>
            </>
          ) : null}
        </View>

        <View style={Styles.submitButtonContainer}>
          {isSkillsOrWorkersCountGreaterThanOne ? (
            <YellowButton
              onPress={navigateToTrainingBook}
              style={[
                Styles.submitButtonStyle,
                { backgroundColor: enableGenerateSkillBook() ? Colors.Yellow : Colors.GreyLight },
              ]}
              text={t('alex:proofBook.generateTrainingBook')}
              textStyle={Styles.submitButtonTextStyle}
              disabled={
                !enableGenerateSkillBook() ||
                !isValidPermission(API.Permission.workersSkillsProof_edit)
              }
            />
          ) : (
            !reviewState && (
              <YellowButton
                onPress={handleSubmitButton}
                style={[
                  Styles.submitButtonStyle,
                  { backgroundColor: validData ? Colors.Yellow : Colors.GreyLight },
                ]}
                text={t('alex:workerSkillReviewModal.submitForValidation')}
                textStyle={Styles.submitButtonTextStyle}
                disabled={!validData || !isValidPermission(API.Permission.workersSkillsProof_edit)}
              />
            )
          )}
          {reviewState === API.ReviewState.TO_REVIEW &&
            isValidPermission(API.Permission.workersSkillProof_review) && (
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <YellowButton
                  onPress={() => reviewProofBundle(true)}
                  style={[
                    Styles.submitButtonStyle,
                    {
                      width: '47%',
                      backgroundColor: 'transparent',
                      borderWidth: 1,
                      borderColor: Colors.Black,
                    },
                  ]}
                  text={t('common:button.reject')}
                  textStyle={[Styles.submitButtonTextStyle, { color: Colors.Black }]}
                />
                <YellowButton
                  onPress={() => {
                    reviewProofBundle(false);
                  }}
                  style={[
                    Styles.submitButtonStyle,
                    {
                      width: '47%',
                      backgroundColor: Colors.Yellow,
                    },
                  ]}
                  text={t('common:button.validate')}
                  textStyle={Styles.submitButtonTextStyle}
                />
              </View>
            )}
          {(reviewState === API.ReviewState.VALIDATED ||
            reviewState === API.ReviewState.REJECTED ||
            reviewState === API.ReviewState.REJECTED_TO_RESUBMIT) && (
            <View style={Styles.checkIconContainer}>
              <Text style={Styles.validatedOnText}>
                {reviewState === API.ReviewState.VALIDATED
                  ? t('alex:mobile.addProofPage.proofValidatedOn', {
                      date: moment(skillObtentionDate).format('DD.MM.YY'),
                      name: workerName,
                    })
                  : t('alex:mobile.addProofPage.proofRejectedOn', {
                      date: moment(skillObtentionDate).format('DD.MM.YY'),
                      name: workerName,
                    })}
              </Text>
              <View style={Styles.checkIcon}>
                <IconCheck color={Colors.Green} />
              </View>
            </View>
          )}
        </View>
      </View>
    );
  };
  return <>{renderRightPanel()}</>;
};
