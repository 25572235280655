import React, { useState } from 'react';
import { TouchableOpacity, ViewStyle, StyleProp, Text } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { TextHighLight } from 'shared/ui-component/TextHighLight';
import { EllipsisWithTooltip } from '../EllipsisWithTooltip';

interface Props {
  text: string;
  highlightedString?: string;
  highlightedTextStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<ViewStyle>;
  onPress: () => void;
}

export const UnderLinedTextOnHover: React.FC<Props> = props => {
  const { text, onPress, textStyle, highlightedString, highlightedTextStyle } = props;
  const [isTextHover, setIsTextHover] = useState(false);

  return (
    <TouchableOpacity
      style={props.containerStyle}
      onPress={() => {
        onPress();
      }}
      ref={useCallOnHover<TouchableOpacity>(
        SharedStyles.Colors.Transparent,
        () => {
          setIsTextHover(true);
        },
        () => {
          setIsTextHover(false);
        },
      )}
    >
      {!highlightedString ? (
        <EllipsisWithTooltip
          text={text}
          textStyle={[textStyle, isTextHover ? { textDecorationLine: 'underline' } : {}]}
        />
      ) : (
        <>
          <TextHighLight
            text={text}
            style={[
              textStyle,
              isTextHover ? ({ textDecorationLine: 'underline' } as StyleProp<ViewStyle>) : {},
            ]}
            highlightedString={highlightedString}
            highlightedTextStyle={highlightedTextStyle}
          />
        </>
      )}
    </TouchableOpacity>
  );
};
