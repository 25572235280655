import React from 'react';
import { View, ViewStyle } from 'react-native';
import * as API from 'shared/backend-data';
import { Styles } from './Styles';
import { AttachFile } from 'shared/ui-component/AttachFile/index';

interface Props {
  s3Files: API.S3ObjectInput[];
  style?: ViewStyle;
  iconOnly?: boolean;
}

export const Files: React.FC<Props> = props => {
  const { s3Files, style, iconOnly = false } = props;

  return (
    <>
      <View style={Styles.divider} />
      <AttachFile
        initialFiles={s3Files}
        addFile={false}
        addFileButtonOnlyWithIcon={iconOnly}
        style={style}
      />
    </>
  );
};
