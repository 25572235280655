import React, { createContext, useState, useEffect } from 'react';
import { InteractionManager } from 'react-native';
import { DashboardFilterProvider } from './DashboardFilterContext';
import * as API from 'shared/backend-data';

export const DashboardContext = createContext(false);

export const DashboardContextProvider: React.FC = props => {
  const [accountHasNoWorkstations, setAccountHasNoWorkstations] = useState<boolean>(false);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      loadWorkstations();
    });
  }, []);

  function loadWorkstations() {
    const userWorkstations = API.getWorkstations();

    if (userWorkstations.length === 0) setAccountHasNoWorkstations(true);
  }

  return (
    <DashboardContext.Provider value={accountHasNoWorkstations}>
      <DashboardFilterProvider>{props.children}</DashboardFilterProvider>
    </DashboardContext.Provider>
  );
};
