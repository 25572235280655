import React, { useState, createContext } from 'react';
import { TreeNode } from 'shared/backend-data/factoryCache/Tree';

export const MenuFactoryContext = createContext<{
  treeNode: [TreeNode | undefined, (treeNode: TreeNode | undefined) => void];
  scrollPosition: [number | undefined, (scrollPosition: number | undefined) => void];
  panelWidth: [number | undefined, (panelWidth: number | undefined) => void];
}>({
  treeNode: [undefined, () => { }],
  scrollPosition: [undefined, () => { }],
  panelWidth: [undefined, () => { }],
});

export const MyFactoryProvider: React.FC = props => {
  const [treeNode, setTreeNode] = useState<TreeNode>();
  const [lastScrollPosition, setLastScrollPosition] = useState<number>();
  const [panelWidth, setPanelWidth] = useState<number>();

  return (
    <MenuFactoryContext.Provider
      value={{
        treeNode: [treeNode, setTreeNode],
        scrollPosition: [lastScrollPosition, setLastScrollPosition],
        panelWidth: [panelWidth, setPanelWidth],
      }}
    >
      {props.children}
    </MenuFactoryContext.Provider>
  );
};
