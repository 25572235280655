import React from 'react';
import { IconSVG } from 'shared/ui-component/Icon';
import LoaderThreeDotsIcon from 'shared/assets/svg/loader.threeDots.svg';
import { Text } from 'react-native';

interface LoaderThreeDotsProps {
  lowPerformance?: boolean;
}

export const LoaderThreeDots: React.FC<LoaderThreeDotsProps> = props => {
  const { lowPerformance = true } = props;
  return lowPerformance ? <Text>-</Text> : <IconSVG svgComponent={LoaderThreeDotsIcon} />;
};
