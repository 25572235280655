import React, { useState, useEffect } from 'react';
import { ReassignmentModalComponent } from '../component';
import * as API from 'shared/backend-data';
import { InteractionManager } from 'react-native';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { updateUnitOrShiftAssignments } from 'shared/util/Workstation';
import * as _ from 'lodash-es';
import Aigle from 'aigle';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import { constructShiftTagKey } from 'shared/layout/organization-unit/OrganizationUnit';
import { ModalUtils } from 'shared/ui-component/Modal/index';

export interface ReassignmentModalProps {
  orgUnit: API.OrganizationalUnit;
  selectedShift?: API.Shift;
  workerAssignments?: API.AssignmentWithUnitDetails[];
  hideSubHeader?: boolean;

  confirmAction?: () => void;
  handleModalClose: () => void;
}

export const ReassignmentModal: React.FC<ReassignmentModalProps> = props => {
  const {
    orgUnit,
    selectedShift,
    workerAssignments,
    hideSubHeader,

    handleModalClose,
    confirmAction,
  } = props;
  const [workersOnShiftOrUnit, setWorkersOnShiftOrUnit] = useState<API.Worker[]>([]);
  const [selectedShiftOrUnitToReplacewith, setSelectedShiftOrUnitToReplaceWith] =
    useState<API.IndexedAssignment>(API.getDefaultIndexedAssignment());
  const isMounted = useIsMounted();
  const [allShifts, setAllShifts] = useState<API.Shift[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [extraSelectorsToDisable, setExtraSelectorsToDisable] = useState<Tag[]>();
  const [nodesToDisable, setNodesToDisable] = useState<Tag[]>();
  const modal = ModalUtils.useModal();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !orgUnit) return;

      if (selectedShift) {
        const workers = await API.getWorkersInShift(
          selectedShift.id,
          undefined,
          undefined,
          false,
          true,
        );
        if (!isMounted.current) return;
        if (API.isFailure(workers)) {
          logger.error('getWorkersInShift:error', workers);
        } else {
          setWorkersOnShiftOrUnit(workers);
        }
      } else if (workerAssignments) {
        const _workers: API.Worker[] = [];
        await Aigle.map(workerAssignments, async workerAssignment => {
          const worker = await API.getWorker(workerAssignment.workerId);
          if (API.isFailure(worker)) {
            logger.error('worker', worker);
            return worker;
          }
          _workers.push(worker);
        });

        setWorkersOnShiftOrUnit(_workers);
      } else {
        const workers = await API.getWorkersInOrganizationalUnits(
          [orgUnit.id],
          undefined,
          false,
          false,
        );
        if (!isMounted.current) return;
        if (API.isFailure(workers)) {
          logger.error('getWorkersInOrganizationalUnits:error', workers);
        } else {
          setWorkersOnShiftOrUnit(workers.result);
        }
      }
    });
  }, [orgUnit]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      await fetchShifts();
    });
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (selectedShift) {
        const unitChildren = API.Tree.getChildren(orgUnit.id, true);
        if (API.isFailure(unitChildren)) return unitChildren;

        const extraSelectorsToDisable: Tag[] = [
          {
            key: constructShiftTagKey(selectedShift.id, orgUnit.id),
            value: { ...selectedShift, relatedListItemId: orgUnit.id },
            label: selectedShift.name,
          },
        ];

        unitChildren.forEach(unit => {
          extraSelectorsToDisable.push({
            key: constructShiftTagKey(selectedShift.id, unit.id),
            value: { ...selectedShift, relatedListItemId: unit.id },
            label: selectedShift.name,
          });
        });

        setExtraSelectorsToDisable(extraSelectorsToDisable);
        setNodesToDisable(undefined);
      } else if (orgUnit) {
        const shifts = await API.getShifts();
        if (API.isFailure(shifts)) return;

        const _extraSelectorsToDisable: Tag[] = [];
        orgUnit.shiftIds?.forEach(async shiftId => {
          const shift = shifts.find(shift => shift.id === shiftId);
          if (!shift) return;

          _extraSelectorsToDisable.push({
            key: constructShiftTagKey(shift.id, orgUnit.id),
            value: { ...shift, relatedListItemId: orgUnit.id },
            label: shift.name,
            isExtraSelector: true,
            tagPath: [API.DataType.SHIFT, [shift.parentId]],
          });
        });

        const _nodesToDisable = [
          {
            key: orgUnit.id,
            value: orgUnit,
            label: orgUnit.name,
          },
        ];
        const treeNode = API.Tree.getTreeNode(orgUnit.id);
        if (API.isFailure(treeNode)) {
          logger.error('updateShiftAssignments:error', treeNode);
          setNodesToDisable(_nodesToDisable);
        } else {
          setNodesToBeDisabled(treeNode, _nodesToDisable, _extraSelectorsToDisable, shifts);
          setNodesToDisable(_nodesToDisable);
          setExtraSelectorsToDisable(_extraSelectorsToDisable);
        }
      }
    });
  }, [selectedShift, orgUnit]);

  function setNodesToBeDisabled(
    treeNode: API.TreeNode,
    _nodesToDisable: Tag[],
    _extraSelectorsToDisable: Tag[],
    shifts: API.Shift[],
  ) {
    const treeNodeChildren = API.Tree.getChildrenTreeNode(treeNode, false, API.DataType.ORGUNIT);
    treeNodeChildren.map(child => {
      _nodesToDisable.push({
        key: child.id,
        value: child.object,
        label: child.object.name,
      });

      orgUnit.shiftIds?.forEach(async shiftId => {
        const shift = shifts.find(shift => shift.id === shiftId);
        if (!shift) return;

        _extraSelectorsToDisable.push({
          key: constructShiftTagKey(shift.id, child.id),
          value: { ...shift, relatedListItemId: child.id },
          label: shift.name,
          isExtraSelector: true,
          tagPath: [API.DataType.SHIFT, [shift.parentId]],
        });
      });

      if (child.children.length) {
        setNodesToBeDisabled(child, _nodesToDisable, _extraSelectorsToDisable, shifts);
      }
    });
  }

  async function fetchShifts() {
    const shifts = await API.getShifts();
    if (API.isFailure(shifts)) return shifts;
    setAllShifts(shifts);
  }

  async function submit(): Promise<void> {
    setIsLoading(true);
    if (!selectedShiftOrUnitToReplacewith) return;

    const assignmentKey = selectedShiftOrUnitToReplacewith.shift
      ? selectedShiftOrUnitToReplacewith.organizationalUnit.id +
        API.SeparatorIds +
        selectedShiftOrUnitToReplacewith.shift.id
      : selectedShiftOrUnitToReplacewith.organizationalUnit.id;

    const updateAssignments = await updateUnitOrShiftAssignments(orgUnit.id, assignmentKey);
    if (API.isFailure(updateAssignments)) {
      setIsLoading(false);
      ModalUtils.showWarningFailure(modal, updateAssignments);
      logger.error('updateShiftAssignments:error', updateAssignments);
      return;
    }

    setIsLoading(false);

    if (confirmAction) confirmAction();
    handleModalClose();
  }

  return (
    <ReassignmentModalComponent
      workersInShiftOrOrgUnit={workersOnShiftOrUnit}
      setSelectedOrgUnitOrShift={unit => setSelectedShiftOrUnitToReplaceWith(unit)}
      submit={submit}
      allShifts={allShifts}
      selectedRole={selectedShiftOrUnitToReplacewith}
      isLoading={isLoading}
      extraSelectorsToDisable={extraSelectorsToDisable}
      nodesToDisable={nodesToDisable}
      hideSubHeader={hideSubHeader}
      {...props}
    />
  );
};
