import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { ModalStyles } from 'shared/styles/ModalStyles';
import { Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  workstationModalCloseIconContainer: { position: 'absolute', top: 8, right: 12 },
  workstationModalHeaderNormalTextStyle: { ...SharedStyles.TextFontStyles.LatoNormal },
  workstationModalPanelContainer: {
    flexDirection: 'row',
    flex: 1,
    zIndex: 998,
    marginRight: Spacings.Standard,
  },
  workstationModalLeftPanelContainer: {
    flexDirection: 'row',
    marginRight: ModalStyles.bodyDivider,
  },
  workstationModalLeftPanelInputContainer: {
    width: ModalStyles.inputWidthWithAnchor,
    paddingTop: SharedStyles.Spacings.Unit,
  },
  workstationModalLeftPanelDropDownContainer: { zIndex: 999 },
  workstationModalRightPanelContainer: {
    width: 440,
    paddingTop: SharedStyles.Spacings.Unit * 10,
    zIndex: 998,
  },
  workstationModalRightPanelLevel2Container: {
    zIndex: 3,
  },
  workstationModalRightPanelLevel3Container: {
    zIndex: 2,
    marginBottom: SharedStyles.Spacings.Unit * 5,
  },
  workstationModalRightPanelLevel4Container: { zIndex: 1 },
  workstationModalRightPanelLevel3TextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    marginBottom: 23,
  },
  workstationModalRightPanelLevel3InnerContainer: { justifyContent: 'space-between', flex: 1 },
  workstationModalRightPanelLevel3MinimumContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 998,
  },
  workstationModalRightPanelLevel3MinimumTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
  },
  workstationModalRightPanelLevel3IdealContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 997,
  },
  workstationModalRightPanelLevel3IdealTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
  },
  workstationModalYellowButtonContainer: {
    zIndex: 996,
    paddingRight: Spacings.Standard,
  },
  saveButton: {
    zIndex: 996,
    paddingRight: Spacings.Standard,
    height: 32,
  },
  workstationModalYellowButtonTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
  },
  workstationLevelObjectivesContainer: { width: '100%' },
  dropDownContainerStyle: {
    width: SharedStyles.Spacings.Standard * 2,
    height: 26,
    alignSelf: 'center',
    marginBottom: SharedStyles.Spacings.Large * 2 + SharedStyles.Spacings.xMedium,
  },
  attachFileComponent: {
    marginTop: Spacings.Standard,
  },
  levelTargetTitle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.XLarge,
    color: SharedStyles.Colors.Black,
  },
  container: {
    flexDirection: 'row',
    paddingTop: Spacings.CardPadding,
    height: 46,
    alignItems: 'flex-end',
  },
  levelContainer: {
    flexDirection: 'row',
    paddingTop: Spacings.Medium,
    height: 46,
    alignItems: 'flex-end',
  },
  shiftNameTitle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Black,
    paddingLeft: Spacings.Unit,
  },
  shiftName: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Black,
    paddingLeft: Spacings.Small,
  },
  shiftNameContainer: {
    flexDirection: 'row',
    flex: 0.3,
    paddingRight: Spacings.Unit,
    alignItems: 'flex-end',
  },
  realValue: {
    flex: 0.2,
    paddingRight: Spacings.Unit,
    ...SharedStyles.TextFontStyles.LatoBlack,
    color: SharedStyles.Colors.Black,
    fontSize: fontSize.XLarge,
  },
  realValueTitle: {
    flex: 0.2,
    paddingRight: Spacings.Unit,
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  percentageTitle: {
    flex: 0.1,
    paddingRight: Spacings.Unit,
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  minimumValue: {
    flex: 0.2,
    paddingRight: Spacings.Small,
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  minimumValueDropdown: {
    flex: 0.2,
    paddingRight: Spacings.Small,
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  idealValueDropdown: {
    flex: 0.2,
    paddingRight: Spacings.Small,
  },
  idealValue: {
    flex: 0.2,
    paddingRight: Spacings.Unit,
  },
  toolTipContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  infoIcon: {
    left: Spacings.Standard,
  },
  percentageCheckBoxContainer: {
    paddingTop: Spacings.Standard,
    paddingBottom: 2,
    flex: 0.1,
    zIndex: 1,
  },
  checkBoxContainer: {
    paddingTop: Spacings.CardPadding,
    paddingBottom: Spacings.Medium,
    zIndex: 1,
  },
  checkBoxTitle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Black,
    paddingLeft: Spacings.Small,
  },
  shiftIcon: {
    top: 2,
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingTop: Spacings.Standard,
    justifyContent: 'flex-end',
    paddingRight: Spacings.CardPadding * 2,
    paddingBottom: Spacings.Standard,
    alignItems: 'center',
  },
  saveText: {
    color: SharedStyles.Colors.White,
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoBlack,
  },
  footerContainer: {
    height: 78,
  },
});
export default styles;
