import * as _ from 'lodash-es';
import { loggerPerf } from './Logger';

export enum Maps {
  createFactory,
  updateFactory,
  deleteFactory,
  listFactorys,
  itemsByType,
  itemsByData,
  allFactoriesByType,
  syncFactories,
  getFactory,
  inviteUser,
  getFilePreSignedUrl,
  default,
}

export const perfs = {
  functionsMap: new Map<string, Perf>(),
  functionsTraceMap: new Map<string, Perf>(),
  
  createFactoryMap: new Map<string, Perf>(),
  updateFactoryMap: new Map<string, Perf>(),
  deleteFactoryMap: new Map<string, Perf>(),
  listFactorysMap: new Map<string, Perf>(),
  itemsByTypeMap: new Map<string, Perf>(),
  itemsByDataMap: new Map<string, Perf>(),
  allFactoriesByType: new Map<string, Perf>(),
  syncItemsMap: new Map<string, Perf>(),
  getFactoryMap: new Map<string, Perf>(),
  inviteUser: new Map<string, Perf>(),
  getFilePreSignedUrl: new Map<string, Perf>(),
  defaultMap: new Map<string, Perf>(),
};

if (typeof window !== 'undefined') {
  (window as any).perf = perfs;
  (window as any).PERF = perfs;
}
interface Perf {
  count: number;
  time: number;
}

function logPerf() {
  for (const [prop, map] of Object.entries(perfs)) {
    const sortedMap = new Map(
      [...map.entries()].sort(
        (a, b) => (a[1].count > b[1].count && -1) || (a[1].count === b[1].count ? 0 : 1),
      ),
    );
    perfs[prop as keyof typeof perfs] = sortedMap;
    if (sortedMap.size) {
      
      loggerPerf.info(prop, sortedMap.values().next().value);
    }
  }

  loggerPerf.info(perfs);
}

if (typeof window !== 'undefined') {
  (window as any).logPerf = logPerf;
  (window as any).logPERF = logPerf;
}

const functionsToNotTrack = [
  'commitHookEffectListMount',
  'commitPassiveHookEffects',
  'HTMLUnknownElement',
  'invokeGuarded',
  'flushPassive',
  'flushSync',
  'runWithPriority',
  'performSyncWork',
  'scheduleUpdateOnFiber',
  'dispatchAction',
  'Promise.',
  
  'arrayMap',
  'arrayReduce',
  'Function.',
];

export function captureStackTrace(): void {
  if (loggerPerf.isPROD) return;

  let obj = {};
  if (Error.captureStackTrace) {
    Error.captureStackTrace(obj, captureStackTrace);
    loggerPerf.verbose('stackTrac:', (obj as any).stack);
  } else {
    obj = new Error();
    loggerPerf.verbose((obj as Error).stack);
  }

  let lines = (obj as any).stack.split('at ');
  const callers: string[] = [];
  lines.forEach((caller: string) => {
    
    if (_.some(functionsToNotTrack, functionToNotTrack => caller.includes(functionToNotTrack))) {
      return;
    }
    caller = caller.substr(0, caller.indexOf(' ('));
    caller = caller.replace('async ', '');
    caller = caller.replace('Module.', '');
    caller = caller.replace('Object.', '');
    if (caller.length) callers.push(caller);
  });

  _.forEach(callers, caller => {
    const perf: Perf = perfs.functionsMap.get(caller) ?? { count: 0, time: 0 };
    perf.count = perf.count + 1;
    perfs.functionsMap.set(caller, perf);
  });

  const key = callers.join('/');
  const perf: Perf = perfs.functionsMap.get(key) ?? { count: 0, time: 0 };
  perf.count = perf.count + 1;
  perfs.functionsTraceMap.set(key, perf);
}

/**
 * Capture the given string and increment a global counter for it.
 * @param key
 * @param mapType
 * @param startStopChrono
 */
export function captureString(key: string, mapType: Maps = Maps.default, timeElapsed?: number) {
  if (loggerPerf.isPROD) return;

  let map: Map<string, Perf>;
  let showLogEveryNCounts = 100;
  switch (mapType) {
    case Maps.createFactory:
      map = perfs.createFactoryMap;
      break;
    case Maps.updateFactory:
      map = perfs.updateFactoryMap;
      break;
    case Maps.deleteFactory:
      map = perfs.deleteFactoryMap;
      break;
    case Maps.listFactorys:
      map = perfs.listFactorysMap;
      break;
    case Maps.itemsByType:
      map = perfs.itemsByTypeMap;
      break;
    case Maps.itemsByData:
      map = perfs.itemsByDataMap;
      break;
    case Maps.syncFactories:
      map = perfs.syncItemsMap;
      break;
    case Maps.getFactory:
      map = perfs.getFactoryMap;
      break;
    case Maps.inviteUser:
      map = perfs.inviteUser;
      break;
    case Maps.getFilePreSignedUrl:
      map = perfs.getFilePreSignedUrl;
      break;
    case Maps.default:
      map = perfs.defaultMap;
      break;
    default:
      loggerPerf.warn('Key ' + key + ' not supported in Perf');
      return;
  }

  const perf: Perf = map.get(key) ?? { count: 0, time: 0 };
  perf.count = perf.count + 1;
  if (timeElapsed) perf.time = perf.time + timeElapsed;
  map.set(key, perf);

  if (Math.round(perf.count % showLogEveryNCounts) == 0) {
    loggerPerf.info(
      `${key} on mapType= ${mapType} counter= ${perf.count} time =${
        timeElapsed ? Math.round(timeElapsed / 1000) : '?'
      }s totalTime=${Math.round(perf.time / 1000)}s`,
    );
  }
}
