import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';

export const styles = StyleSheet.create({
  profilePictureContainer: {
    position: 'absolute',
    top: 0,
  },
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  remainingUsersCount: {
    color: SharedStyles.Colors.White,
    fontSize: SharedStyles.fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoBold,
  },
  remainingUsersCountView: {
    backgroundColor: SharedStyles.Colors.Yellow,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
