import React from 'react';
import Styles from './style';
import { View } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import { InputText } from 'shared/ui-component/Input';
import { DropDownSingleSelection, DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { t } from 'shared/localisation/i18n';
import {
  ModalAnchorStyle,
  ModalAnchorContainerStyle,
  ModalInputWithAnchorStyle,
} from 'shared/styles/ModalStyles';
import { AttachFile } from 'shared/ui-component/AttachFile';
import { S3ObjectInput } from 'backend/src/api';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';

interface LeftPanelProps {
  workstationName: string | undefined;
  workstationFiles: S3ObjectInput[];
  onWorkstationFilesChange: (s3Files: S3ObjectInput[]) => void;
  orgUnitOptions: DropDownOption[];
  selectedOrgUnit: DropDownOption | undefined;
  workstationDescription: string | null | undefined;
  setWorkstationName: (name: string) => void;
  setSelectedOrgUnit: (ou: DropDownOption | undefined) => void;
  setWorkstationDescription: (desc: string) => void;
  initialOrgUnitId: string;
  onSubmitForm: () => void;
}

const workstationIcon = require('shared/assets/svg/icon.workstation.svg').default;

enum InputIds {
  WorkstationName = 'WorkstationName',
  Description = 'Description',
}

export const LeftPanel: React.FC<LeftPanelProps> = props => {
  const {
    workstationName,
    orgUnitOptions,
    selectedOrgUnit,
    workstationDescription,
    setSelectedOrgUnit,
    setWorkstationName,
    setWorkstationDescription,
    initialOrgUnitId,
    workstationFiles,
    onSubmitForm,
  } = props;

  const { errors, onBlur, onChange, navigateInputField, refs } = useCustomForm<string>(
    {
      [InputIds.WorkstationName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
      [InputIds.Description]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: false,
      },
    },
    onSubmitForm,
  );

  return (
    <View style={Styles.workstationModalLeftPanelContainer}>
      <View style={ModalAnchorContainerStyle}>
        <View style={ModalAnchorStyle}>
          <IconSVG svgComponent={workstationIcon} color={SharedStyles.Colors.White} />
        </View>
      </View>
      <View style={[Styles.workstationModalLeftPanelInputContainer]}>
        <View>
          <InputText
            inputRef={refs[InputIds.WorkstationName]}
            inputId={InputIds.WorkstationName}
            style={ModalInputWithAnchorStyle}
            onBlur={onBlur}
            errorMessage={errors[InputIds.WorkstationName]?.inputErrorMessage}
            placeHolder={t('alex:workstationModal.nameInputPlaceholder')}
            defaultValue={workstationName}
            onTextChange={(inputId: string, inputValue: string) => {
              onChange(inputId, inputValue);
              setWorkstationName(inputValue);
            }}
            onSubmitEditing={(inputId: string, inputValue: string) => {
              setWorkstationName(inputValue);
              navigateInputField(InputIds.WorkstationName);
            }}
          />
        </View>
        <View style={[Styles.workstationModalLeftPanelDropDownContainer]}>
          <DropDownSingleSelection
            options={orgUnitOptions}
            value={selectedOrgUnit}
            placeholder={t('alex:workstationModal.dropdownPlaceHolder')}
            handleChange={(_, option) => setSelectedOrgUnit(option)}
            notEditable={!orgUnitOptions.length || !!initialOrgUnitId}
            title={t('glossary:organizationalUnit')}
          />
        </View>
        <InputTextDescription
          inputRef={refs[InputIds.Description]}
          inputId={InputIds.Description}
          onBlur={onBlur}
          errorMessage={errors[InputIds.Description]?.inputErrorMessage}
          style={ModalInputWithAnchorStyle}
          placeHolder={t('alex:workstationModal.description')}
          defaultValue={workstationDescription ?? ''}
          onTextChange={(inputId: string, inputValue: string) => {
            onChange(inputId, inputValue);
            setWorkstationDescription(inputValue);
          }}
          onSubmitEditing={(inputId: string, inputValue: string) => {
            setWorkstationDescription(inputValue);
            navigateInputField(InputIds.Description);
          }}
        />
        <AttachFile
          initialFiles={workstationFiles}
          onFilesChange={props.onWorkstationFilesChange}
          style={Styles.attachFileComponent}
        />
      </View>
    </View>
  );
};
