import React, { useState } from 'react';
import { TouchableOpacity, ViewStyle, StyleProp } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { SvgProps } from 'react-native-svg';

interface Props {
  svgIcon: React.FC<SvgProps>;
  dehoverColor?: string;
  hoverColor?: string;
  index?: number; 
  size?: { width: number; height: number };
  containerStyle?: StyleProp<ViewStyle>;
  onPress: () => void;
}

export const HoverableIcon: React.FC<Props> = props => {
  const { svgIcon, dehoverColor, hoverColor, index, onPress } = props;
  const [isIconHover, setIsIconHover] = useState(false);

  return (
    <TouchableOpacity
      style={[props.containerStyle, { zIndex: 999 - (index ?? 0) }]}
      onPress={() => {
        onPress();
      }}
      ref={useCallOnHover<TouchableOpacity>(
        SharedStyles.Colors.Transparent,
        () => {
          setIsIconHover(true);
        },
        () => {
          setIsIconHover(false);
        },
      )}
    >
      <IconSVG
        svgComponent={svgIcon}
        size={{ width: 24, height: 24 }}
        color={
          isIconHover
            ? hoverColor ?? SharedStyles.Colors.Black
            : dehoverColor ?? SharedStyles.Colors.Grey
        }
      />
    </TouchableOpacity>
  );
};
