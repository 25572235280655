import * as API from 'shared/backend-data';
import {
  Validity,
  getWorkstationByName,
  getOrCreateOrganizationalUnit,
  validateWorkstationWorkerLevelTargets,
  isSameFile,
  TreeNode,
  getWorkerByNameOrEmailOrPhoneOrMatricule,
} from 'shared/backend-data';
import { loggerAPI as logger } from 'shared/util/Logger';
import { i18n, capitalizeFirstLetter, t, languages } from 'shared/localisation/i18n';
import {
  Writable,
  convertDataURLtoFile,
  isValidDate,
  replaceDiacriticsAndCapitalLetter,
  searchMatch,
  wait,
} from 'shared/util-ts/Functions';
import FileSaver from 'file-saver';
import {
  Borders,
  Cell,
  CellErrorValue,
  CellSharedFormulaValue,
  CellValue,
  Column,
  Fill,
  Row,
  TableColumnProperties,
  ValueType,
  Workbook,
  Worksheet,
} from 'exceljs';
import dayjs from 'dayjs';
import _, { isNumber, round } from 'lodash-es';
import { AsyncLock } from '../util-ts/AsyncLock';
import Aigle from 'aigle';
import { isValidPhoneNumber } from 'shared/util/Worker';
import { HOUR_TO_MIN_MULTIPLIER, emailPattern, workerPersonalIdPattern } from './const';
import { getWorkerWorkstations } from 'shared/util/WorkerLevel';
import { SkillWorkerTableRow } from 'shared/util/Skill';
import { WorkerSkillRow } from 'skillmgtweb/src/components/my-factory/workers/component/worker-profile/container';
import { MyHub } from './MyHub';
import { DataLayer } from '../backend-data/DataLayer'; 
import { SKILL_MAX_MONTHS } from 'shared/util/Skill';
import { TRAINING_DURATION_MAX_HOURS } from './Training';
import { TrainingWorkerTableRow } from 'skillmgtweb/src/components/training/training-view/component/TrainingWorkerTable/container';
import { pdfContentType } from 'shared/util/PDF';
import { isPlatformServerless } from 'shared/util-ts/Functions';

/**
 * Keyword to use inside Excle improt to delete Object or Object's property
 */
const DELETE_keyword = 'DELETE';
export type DELETE_keyword = typeof DELETE_keyword;
/** type restricted to Excel import to mark some properties as to delete */
type DeletablePropObject<O extends object> = { [K in keyof O]: IsDeletableProp<O[K]> };
type IsDeletableProp<T> = T extends
  | null 
  | any[]
  ? T | DELETE_keyword
  : T;

/**
 * False = more strict: it forces the end user to know exactly the existing list of tags, contracts, assignements before adding more
 * True = more flexible: user can simply add tags, contracts, assignements whithout knowing what the targeted worker already has.
 */
const allowAddingListItemWithoutListingExistingItems = true;

/**
 * proofBundle.skillId + Separator + proofBundle.review.state
 */
const TraineeSkillValiditySeparator = ':';

/**
 * unit name + Separator + shift name | shift name + Searator + leve target
 */
const ShiftSeparator = '>';

const excelFontSize = 14;
const excelSpacings = {
  Small: 8,
  Standard: 24,
  Large: 36,
};

const excelMaxNumbeOfColumns = 16384;

export const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtension = '.xlsx';

let _delimiter: string | undefined;
export function getExcelDelimiter(): string {
  if (!_delimiter) {
    let d = t('common:excelImportExportDelimiter');
    if (!d || !d.length) {
      logger.warn(
        'excelImportExportDelimiter could not be fetched in common file. A default value will be used instead.',
      );
      d = ';';
    }
    _delimiter = d;
  }
  return _delimiter;
}

export type CsvRow = {
  [key: string]: CsvValue;
};
export type CsvValue = string | number | boolean | Date | undefined;

export interface Header {
  /** Optional key (helper to find columns) */
  key?: string;
  title: string;
  comment?: string | string[];
  /** pass empty array or undefined if no subHeaders */
  subHeaders?: Header[];
}

function getComment(comment: Header['comment']): string {
  if (!comment) return '';

  const c = Array.isArray(comment) ? comment.join('\n') : comment;
  return capitalizeFirstLetter(c);
}

export enum ImportExportType {
  Workstation,
  Workstation_FORCE,
  Workstation_RENAME_DELETE, 
  Skill,
  Skill_FORCE,
  Skill_RENAME_DELETE,
  Training,
  Training_FORCE,
  Training_RENAME_DELETE,
  Worker,
  Worker_FORCE,
  Worker_RENAME_DELETE,
  WorkerWithSkill,
  WorkerSkill,
  WorkerSkill_FORCE,
  WorkerProfileSkill,
  WorkerTraining,
  WorkerTrainingSession,
  WorkerWorkstationTarget,
  TrainingSession,
  TrainingSession_RENAME_DELETE,
  WorkerWorkstationTrainingRequest,
  SkillsMatrix,
  SkillsToRenew,
  SkillsToAcquire,
}

const WorkstationColumns = {
  workstationName: 'workstationName',
  orgUnitPathNames: 'orgUnitPathNames',
  description: 'description',
  level1SkillNames: 'level1SkillNames',
  level2SkillNames: 'level2SkillNames',
  level3SkillNames: 'level3SkillNames',
  level4SkillNames: 'level4SkillNames',
  level2MinimumTarget: 'level2MinimumTarget',
  level2IdealTarget: 'level2IdealTarget',
  level3MinimumTarget: 'level3MinimumTarget',
  level3IdealTarget: 'level3IdealTarget',
  level4MinimumTarget: 'level4MinimumTarget',
  level4IdealTarget: 'level4IdealTarget',
} as const;
type WorkstationCsvRow = { [key in keyof typeof WorkstationColumns]: CsvValue };
export function getWorkstationHeaders(
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
): Header[] {
  const workstationHeaders: Header[] = [
    {
      key: WorkstationColumns.workstationName,
      title: t('alex:importExport.headers.workstation.0'),
    },
    {
      key: WorkstationColumns.orgUnitPathNames,
      title: t('alex:importExport.headers.workstation.1'),
      comment: t('alex:importExport.headers.common.orgUnitComment'),
    },
    {
      key: WorkstationColumns.description,
      title: t('alex:importExport.headers.workstation.2'),
    },
    {
      key: WorkstationColumns.level1SkillNames,
      title: t('alex:importExport.headers.workstation.3'),
      comment: t('alex:importExport.headers.levelRequiredByWorkstation', { count: 1 }),
    },
    {
      key: WorkstationColumns.level2SkillNames,
      title: t('alex:importExport.headers.workstation.4'),
      comment: t('alex:importExport.headers.levelRequiredByWorkstation', { count: 2 }),
    },
    {
      key: WorkstationColumns.level3SkillNames,
      title: t('alex:importExport.headers.workstation.5'),
      comment: t('alex:importExport.headers.levelRequiredByWorkstation', { count: 3 }),
    },
    {
      key: WorkstationColumns.level4SkillNames,
      title: t('alex:importExport.headers.workstation.5'),
      comment: t('alex:importExport.headers.levelRequiredByWorkstation', { count: 4 }),
    },
  ];

  const level2MinAndIdeal: Header[] = [
    {
      key: WorkstationColumns.level2MinimumTarget,
      title: t('alex:importExport.headers.workstationTargets.0'),
      comment: t('alex:importExport.headers.workstationTargetsComments.0'),
    },
    {
      key: WorkstationColumns.level2IdealTarget,
      title: t('alex:importExport.headers.workstationTargets.1'),
      comment: t('alex:importExport.headers.workstationTargetsComments.1'),
    },
  ];

  const level3MinAndTarget: Header[] = [
    {
      key: WorkstationColumns.level3MinimumTarget,
      title: t('alex:importExport.headers.workstationTargets.2'),
      comment: t('alex:importExport.headers.workstationTargetsComments.2'),
    },
    {
      key: WorkstationColumns.level3IdealTarget,
      title: t('alex:importExport.headers.workstationTargets.3'),
      comment: t('alex:importExport.headers.workstationTargetsComments.3'),
    },
  ];

  const level4MinAndTarget: Header[] = [
    {
      key: WorkstationColumns.level4MinimumTarget,
      title: t('alex:importExport.headers.workstationTargets.4'),
      comment: t('alex:importExport.headers.workstationTargetsComments.4'),
    },
    {
      key: WorkstationColumns.level4IdealTarget,
      title: t('alex:importExport.headers.workstationTargets.5'),
      comment: t('alex:importExport.headers.workstationTargetsComments.5'),
    },
  ];

  switch (workstationTargetsStartingAtLevel) {
    case API.WorkstationWorkerLevels.LEVEL2:
      return [
        ...workstationHeaders,
        ...level2MinAndIdeal,
        ...level3MinAndTarget,
        ...level4MinAndTarget,
      ];

    case API.WorkstationWorkerLevels.LEVEL3:
      return [...workstationHeaders, ...level3MinAndTarget, ...level4MinAndTarget];

    case API.WorkstationWorkerLevels.LEVEL4:
      return [...workstationHeaders, ...level4MinAndTarget];

    default:
      return [];
  }
}

const SkillColumns = {
  skillName: 'skillName',
  isPractical: 'isPractical',
  skillTags: 'skillTags',
  validityDuration: 'validityDuration',
  expiryNoticeDuration: 'expiryNoticeDuration',
  description: 'description',
  subSkills: 'subSkills',
} as const;
type SkillCsvRow = { [key in keyof typeof SkillColumns]: CsvValue };
export function getSkillHeaders(): Header[] {
  return t<Header[]>('alex:importExport.headers.skill', {
    key1: SkillColumns.skillName,
    key2: SkillColumns.isPractical,
    key3: SkillColumns.skillTags,
    key4: SkillColumns.validityDuration,
    key5: SkillColumns.expiryNoticeDuration,
    key6: SkillColumns.description,
    key7: SkillColumns.subSkills,
  });
}

const TrainingColumns = {
  trainingName: 'trainingName',
  durationInHours: 'durationInHours',
  trainingTags: 'trainingTags',
  skillNames: 'skillNames',
  description: 'description',
  notes: 'notes',
} as const;
export function getTrainingHeaders(): Header[] {
  return t('alex:importExport.headers.training', {
    key1: TrainingColumns.trainingName,
    key2: TrainingColumns.durationInHours,
    key3: TrainingColumns.trainingTags,
    key4: TrainingColumns.skillNames,
    key5: TrainingColumns.description,
    key6: TrainingColumns.notes,
  });
}

const TrainingExportColumns = {
  ...TrainingColumns,
  trainingType: 'trainingType',
} as const;
type TrainingExportCsvRow = { [key in keyof typeof TrainingExportColumns]: CsvValue };
function getTrainingExportHeaders() {
  const headers: Header[] = getTrainingHeaders();

  headers.push(
    ...t<Header[]>('alex:importExport.headers.trainingExport_extends_training', {
      key1: TrainingExportColumns.trainingType,
    }),
  );

  return headers;
}

const TrainingSessionColumns = {
  trainingName: TrainingColumns.trainingName,
  scheduledStartDate: 'scheduledStartDate',
  durationInHours: TrainingColumns.durationInHours,
  endDateLimitColumn: 'endDateLimit',
  notes: TrainingColumns.notes,
  scheduledTrainersIdentifier: 'scheduledTrainersIdentifier',
  scheduledTraineeIdentifiers: 'scheduledTraineeIdentifiers',
} as const;
function getTrainingSessionHeaders() {
  return t<Header[]>('alex:importExport.headers.trainingSession', {
    key1: TrainingSessionColumns.trainingName,
    key2: TrainingSessionColumns.scheduledStartDate,
    key3: TrainingSessionColumns.durationInHours,
    key4: TrainingSessionColumns.endDateLimitColumn,
    key5: TrainingSessionColumns.notes,
    key6: TrainingSessionColumns.scheduledTrainersIdentifier,
    key7: TrainingSessionColumns.scheduledTraineeIdentifiers,
  });
}

export const WorkerColumns = {
  firstName: 'firstName',
  familyName: 'familyName',
  email: 'email',
  phone: 'phone',
  personalId: 'personalId',
  workerTags: 'workerTags',
  description: 'description',
  contractsTypeName: 'contractsTypeName',
  contractsStart: 'contractsStart',
  contractsEnd: 'contractsEnd',
  orgUnitsName: 'orgUnitsName',
  orgUnitsRoleName: 'orgUnitsRoleName',
  orgUnitsPermissions: 'orgUnitsPermissions',
} as const;
export type WorkerCsvRow = { [key in keyof typeof WorkerColumns]: CsvValue };
async function getWorkerCsvRow(
  worker: API.Worker,
): Promise<API.Result<[WorkerCsvRow, API.AssignmentWithUnitDetails[]]>> {
  const { email, phone, matricule, firstName, familyName, contracts, tagIds, description } = worker;
  const workerPermissions: string[] = [];
  const workerRoles: string[] = [];
  const orgUnitsNames: string[] = [];
  let workerTags: string[] = [];
  let contractTypeNames: string[] = [];
  let contractStartDates: string[] = [];
  let contractEndDates: string[] = [];

  const assignments = await API.getWorkerAssignments(worker.id, false, true);
  if (API.isFailure(assignments)) return assignments;

  for (const orgUnitRole of assignments) {
    workerRoles.push(orgUnitRole.role.name);
    workerPermissions.push('[' + orgUnitRole.permissions.join(getExcelDelimiter()) + ']');

    if (orgUnitRole.shift) {
      orgUnitsNames.push(
        orgUnitRole.organizationalUnit.name + ShiftSeparator + orgUnitRole.shift.name,
      );
    } else {
      
      orgUnitsNames.push(orgUnitRole.organizationalUnit.name);
    }
  }

  if (tagIds) {
    const tags = await getTags(tagIds, TagType.WorkerTag);
    if (API.isFailure(tags)) return tags;
    workerTags = tags.map(tag => tag.name);
  }

  if (contracts) {
    for (const contract of contracts) {
      const contractType = await API.getContractType(contract.contractTypeId);
      if (API.isFailure(contractType)) return contractType;
      contractTypeNames.push(contractType.name);
    }

    contractStartDates = contracts.map(contract =>
      contract.startDate ? new Date(contract.startDate).toLocaleDateString() : '',
    );

    contractEndDates = contracts.map(contract =>
      contract.endDate
        ? new Date(contract.endDate).toLocaleDateString()
        : contract.startDate
        ? t('alex:workerSkill.neverExpire')
        : '',
    );
  }

  const row: WorkerCsvRow = {
    [WorkerColumns.firstName]: firstName,
    [WorkerColumns.familyName]: familyName,
    [WorkerColumns.email]: email ?? '',
    [WorkerColumns.phone]: phone ?? '',
    [WorkerColumns.personalId]: matricule ?? '',
    [WorkerColumns.workerTags]: workerTags.join(getExcelDelimiter()),
    [WorkerColumns.description]: description ?? '',
    [WorkerColumns.contractsTypeName]: contractTypeNames.join(getExcelDelimiter()),
    [WorkerColumns.contractsStart]: contractStartDates.join(getExcelDelimiter()),
    [WorkerColumns.contractsEnd]: contractEndDates.join(getExcelDelimiter()),
    [WorkerColumns.orgUnitsName]: orgUnitsNames.join(getExcelDelimiter()),
    [WorkerColumns.orgUnitsRoleName]: workerRoles.join(getExcelDelimiter()),
    [WorkerColumns.orgUnitsPermissions]: workerPermissions.join(getExcelDelimiter()),
  };

  return [row, assignments];
}
async function getWorkerHeaders(): Promise<Header[]> {
  const roles: API.Role[] = [];
  const _roles = await API.getRoles();
  if (API.isFailure(_roles)) {
    logger.warn(_roles);
  } else {
    roles.push(..._roles);
  }

  const contractTypes: API.ContractType[] = [];
  const _contractTypes = await API.getContractTypes();
  if (API.isFailure(_contractTypes)) {
    logger.warn(_contractTypes);
  } else {
    contractTypes.push(..._contractTypes);
  }

  return t<Header[]>('alex:importExport.headers.worker', {
    key1: WorkerColumns.firstName,
    key2: WorkerColumns.familyName,
    key3: WorkerColumns.email,
    key4: WorkerColumns.phone,
    key5: WorkerColumns.personalId,
    key6: WorkerColumns.workerTags,
    key7: WorkerColumns.description,
    key8: WorkerColumns.contractsTypeName,
    key9: WorkerColumns.contractsStart,
    key10: WorkerColumns.contractsEnd,
    key11: WorkerColumns.orgUnitsName,
    key12: WorkerColumns.orgUnitsRoleName,
    key13: WorkerColumns.orgUnitsPermissions,
    contractTypes: contractTypes.map(contractType => contractType.name).toString(),
    roles: roles.map(role => role.name).toString(),
  });
}
export async function getWorkerImportHeaders() {
  const headers: Header[] = await getWorkerHeaders();

  headers.push(...t<Header[]>('alex:importExport.headers.workersImport_extends_worker'));

  return headers;
}

const WorkerShortColumns = {
  workerName: 'workerName',
  personalId: WorkerColumns.personalId,
  workerTags: WorkerColumns.workerTags,
  contractsTypeName: WorkerColumns.contractsTypeName,
  contractsStart: WorkerColumns.contractsStart,
  contractsEnd: WorkerColumns.contractsEnd,
  assignments: 'assignments',
} as const;
type WorkerShortCsvRow = { [key in keyof typeof WorkerShortColumns]: CsvValue };
async function getWorkerShortCsvRow(worker: API.Worker): Promise<API.Result<WorkerShortCsvRow>> {
  const workerCsvRow = await getWorkerCsvRow(worker);
  if (API.isFailure(workerCsvRow)) return workerCsvRow;

  const row: WorkerShortCsvRow = {
    [WorkerShortColumns.workerName]: worker.name,
    [WorkerShortColumns.personalId]: workerCsvRow[0].personalId,
    [WorkerShortColumns.workerTags]: workerCsvRow[0].workerTags,
    [WorkerShortColumns.contractsTypeName]: workerCsvRow[0].contractsTypeName,
    [WorkerShortColumns.contractsStart]: workerCsvRow[0].contractsStart,
    [WorkerShortColumns.contractsEnd]: workerCsvRow[0].contractsEnd,
    [WorkerShortColumns.assignments]: workerCsvRow[0].orgUnitsName,
  };

  return row;
}
function getWorkerShortHeaders(): Header[] {
  return t<Header[]>('alex:importExport.headers.workerShort', {
    key1: WorkerShortColumns.workerName,
    key2: WorkerShortColumns.personalId,
    key3: WorkerShortColumns.workerTags,
    key8: WorkerShortColumns.contractsTypeName,
    key9: WorkerShortColumns.contractsStart,
    key10: WorkerShortColumns.contractsEnd,
    key11: WorkerShortColumns.assignments,
  });
}

const WorkerProfileColumns = {
  ...WorkerColumns,
  skillName: SkillColumns.skillName,
  issueDate: 'issueDate',
  expiryDate: 'expiryDate',
  skillWorkstations: 'skillWorkstations',
} as const;
type WorkerProfileCsvRow = { [key in keyof typeof WorkerProfileColumns]: CsvValue };
async function getWorkerProfileHeaders(): Promise<Header[]> {
  const headers: Header[] = await getWorkerHeaders();

  headers.push(
    ...t<Header[]>('alex:importExport.headers.workerProfile_extends_worker', {
      key1: WorkerProfileColumns.skillName,
      key2: WorkerProfileColumns.issueDate,
      key3: WorkerProfileColumns.expiryDate,
      key4: WorkerProfileColumns.skillWorkstations,
    }),
  );

  return headers;
}

export const WorkerSkillImportColumns = {
  workerIdentifier: 'workerIdentifier',
  skillNames: 'skillNames',
  issueDate: 'issueDate',
  proofDescription: 'proofDescription',
} as const;
function getWorkerSkillHeaders(): Header[] {
  return t<Header[]>('alex:importExport.headers.workerSkill', {
    key1: WorkerSkillImportColumns.workerIdentifier,
    key2: WorkerSkillImportColumns.skillNames,
    key3: WorkerSkillImportColumns.issueDate,
    key4: WorkerSkillImportColumns.proofDescription,
  });
}

const WorkerTrainingImportColumns = {
  workerIdentifier: 'workerIdentifier',
  trainingNames: 'trainingNames',
  issueDate: 'issueDate',
  proofDescription: 'proofDescription',
} as const;
function getWorkerTrainingImportHeaders(): Header[] {
  return t<Header[]>('alex:importExport.headers.workerTrainingImport', {
    key1: WorkerTrainingImportColumns.workerIdentifier,
    key2: WorkerTrainingImportColumns.trainingNames,
    key3: WorkerTrainingImportColumns.issueDate,
    key4: WorkerTrainingImportColumns.proofDescription,
  });
}

const WorkerTrainingSessionExportColumns = {
  ...WorkerShortColumns,
  trainingName: TrainingColumns.trainingName,
  trainingType: TrainingExportColumns.trainingType,
  comment: 'comment',
  trainingState: 'trainingState',
  referenceDurationInHour: 'referenceDurationInHour',
  durationInHour: 'durationInHour',
  startingDate: 'startingDate',
  endingDate: 'endingDate',
  trainersName: 'trainersName',
  trainersPercentage: 'trainersPercentage',
  skillNames: 'skillNames',
  validation: 'validation',
} as const;
type TrainingSessionCsvRow = { [key in keyof typeof WorkerTrainingSessionExportColumns]: CsvValue };
function getWorkerTrainingSessionExportHeaders(): Header[] {
  return t('alex:importExport.headers.workerTrainingSessionExport_extends_workerShort', {
    key1: WorkerTrainingSessionExportColumns.trainingName,
    key2: WorkerTrainingSessionExportColumns.trainingType,
    key3: WorkerTrainingSessionExportColumns.comment,
    key4: WorkerTrainingSessionExportColumns.trainingState,
    key5: WorkerTrainingSessionExportColumns.referenceDurationInHour,
    key6: WorkerTrainingSessionExportColumns.durationInHour,
    key7: WorkerTrainingSessionExportColumns.startingDate,
    key8: WorkerTrainingSessionExportColumns.endingDate,
    key9: WorkerTrainingSessionExportColumns.trainersName,
    key10: WorkerTrainingSessionExportColumns.trainersPercentage,
    key11: WorkerTrainingSessionExportColumns.skillNames,
    key12: WorkerTrainingSessionExportColumns.validation,
  });
}

export const workerTrainingSessionImportColumns = {
  workerIdentifier: 'workerIdentifier',
  trainingName: TrainingColumns.trainingName,
  startDate: 'startDate',
  issueDate: 'issueDae',
  durationInMin: 'durationInMin',
  trainerIdentifier: 'trainerIdentifier',
  proofDescription: 'proofDescription',
} as const;
function getWorkerTrainingSessionImportHeaders(): Header[] {
  return t('alex:importExport.headers.workerTrainingSessionImport', {
    key1: workerTrainingSessionImportColumns.workerIdentifier,
    key2: workerTrainingSessionImportColumns.trainingName,
    key3: workerTrainingSessionImportColumns.startDate,
    key4: workerTrainingSessionImportColumns.issueDate,
    key5: workerTrainingSessionImportColumns.durationInMin,
    key6: workerTrainingSessionImportColumns.trainerIdentifier,
    key7: workerTrainingSessionImportColumns.proofDescription,
  });
}

const WorkerWorkstationTrainingSessionsRequestColumns = {
  workerIdentifier: 'workerIdentifier',
  workstationName: 'workstationName',
  targetLevel: 'targetLevel',
  endDateLimit: 'endDateLimit',
  trainingSessionNote: 'trainingSessionNote',
} as const;
function getWorkerWorkstationTrainingRequestImportHeaders(): Header[] {
  return t('alex:importExport.headers.workerWorkstationTrainingRequestImport', {
    key1: WorkerWorkstationTrainingSessionsRequestColumns.workerIdentifier,
    key2: WorkerWorkstationTrainingSessionsRequestColumns.workstationName,
    key3: WorkerWorkstationTrainingSessionsRequestColumns.targetLevel,
    key4: WorkerWorkstationTrainingSessionsRequestColumns.endDateLimit,
    key5: WorkerWorkstationTrainingSessionsRequestColumns.trainingSessionNote,
  });
}

const WorkerWorkstationTargetColumns = {
  workerIdentifier: 'workerIdentifier',
  workstationName: 'workstationName',
  targetLevel: 'targetLevel',
  autoTrain: 'autoTrain',
  issueDate: 'issueDate',
  comment: 'comment',
  ignoreSkillNames: 'ignoreSkillNames',
} as const;
function getWorkerWorkstationTargetImportHeaders(): Header[] {
  return t('alex:importExport.headers.workerWorkstationTargetImport', {
    key1: WorkerWorkstationTargetColumns.workerIdentifier,
    key2: WorkerWorkstationTargetColumns.workstationName,
    key3: WorkerWorkstationTargetColumns.targetLevel,
    key4: WorkerWorkstationTargetColumns.autoTrain,
    key5: WorkerWorkstationTargetColumns.issueDate,
    key6: WorkerWorkstationTargetColumns.comment,
    key7: WorkerWorkstationTargetColumns.ignoreSkillNames,
  });
}

const SkillMatrixColumns = {
  ...WorkerShortColumns,
} as const;
type SkillMatrixCsvRow = { [key in keyof typeof SkillMatrixColumns]: CsvValue } & {
  [workstationId_suffix: string]: CsvValue;
};
function getSkillMatrixHeader(): Header[] {
  const headers: Header[] = getWorkerShortHeaders();

  

  return headers;
}

const SkillToAcquireOrRenewColumns = {
  ...WorkerShortColumns,
  skillName: SkillColumns.skillName,
  expirationDate: 'expirationDate',
  workstationsName: 'workstationsName',
  trainingNames: 'trainingNames',
  skillValidityDuration: 'skillValidityDuration',
} as const;
type SkillToAcquireOrRenewCsvRow = { [key in keyof typeof SkillToAcquireOrRenewColumns]: CsvValue };
function getSkillToAcquireHeaders(): Header[] {
  const headers = getWorkerShortHeaders();

  headers.push(
    ...t<Header[]>('alex:importExport.headers.skillsToAcquireExport_extends_workerShort', {
      key1: SkillToAcquireOrRenewColumns.skillName,
      key2: SkillToAcquireOrRenewColumns.workstationsName,
      key3: SkillToAcquireOrRenewColumns.trainingNames,
      key4: SkillToAcquireOrRenewColumns.skillValidityDuration,
    }),
  );

  return headers;
}
function getSkillToRenewHeaders(): Header[] {
  const headers = getWorkerShortHeaders();

  headers.push(
    ...t<Header[]>('alex:importExport.headers.skillsToRenewExport_extends_workerShort', {
      key1: SkillToAcquireOrRenewColumns.skillName,
      key2: SkillToAcquireOrRenewColumns.expirationDate,
      key3: SkillToAcquireOrRenewColumns.workstationsName,
      key4: SkillToAcquireOrRenewColumns.trainingNames,
      key5: SkillToAcquireOrRenewColumns.skillValidityDuration,
    }),
  );

  return headers;
}

const BusinessObjectWorkSheetHeaders = {
  idOrName: 'idOrName',
  newName: 'newName',
} as const;
function getBusinessObjectMutateHeaders(): Header[] {
  return t('alex:importExport.headers.businessObjectMutateImport', {
    keyword: DELETE_keyword,
    key1: BusinessObjectWorkSheetHeaders.idOrName,
    key2: BusinessObjectWorkSheetHeaders.newName,
  });
}

export interface ImportReport<T extends {} = {}> {
  failures: ImportFailure[];
  successes: T[];
}

export interface ImportFailure {
  failureMessage: string;
  row?: Row;
}

/**
 * A Failure if the export failed completly.
 * Otherwise returns the workbook with the exported data and the list of errors that happened
 * (if length>0 it means that the export is partial and misses some data).
 */
export type ExportReport =
  | API.Failure
  | {
      partialExportFailures: API.Failure[];
      exportWorkbook: Workbook;
    };

export enum ImportExportFailureMessageKey {
  RequiredFieldMissing = 'alex:importExport.importErrors.requiredFieldMissing',
  DELETE_keyword_NotAllowded = 'alex:importExport.importErrors.deleteKeywordNotAllowed',
  InconsistentData = 'alex:importExport.importErrors.inconsistentData',
  ObjectDoesNotExist = 'alex:importExport.importErrors.objectDoesNotExist',
  ObjectAlreadyCreated = 'alex:importExport.importErrors.objectAlreadyCreated',
  LoggedInUserNotFound = 'alex:importExport.importErrors.loggedInUserNotFound',
  ContractTypesAndStartDatesInconsistent = 'alex:importExport.importErrors.contractTypesAndStartDatesInconsistent',
  WorkerRolesAndOrgUnitsInconsistent = 'alex:importExport.importErrors.workerRolesAndOrgUnitsInconsistent',
  InvalidPhoneNumber = 'alex:importExport.importErrors.invalidPhoneNumber',
  invalidContractType = 'alex:importExport.importErrors.invalidContractType',
  InvalidDate = 'alex:importExport.importErrors.invalidDate',
  InvalidMatricule = 'alex:importExport.importErrors.invalidMatricule',
  InvalidEmail = 'alex:importExport.importErrors.invalidEmail',
  NumberFormatError = 'alex:importExport.importErrors.numberFormatError',
  DurationNotInRange = 'alex:importExport.importErrors.durationNotInRange',
  WWLTValueError = 'alex:importExport.importErrors.wwltValueError',
  TopOrgUnitInaccessible = 'alex:importExport.importErrors.topOrgUnitInaccessible',
  ProofBundleReviewDateMissing = 'alex:importExport.importErrors.proofBundleReviewDateMissing',
  GenericError = 'common:error.generic',
  WorkstationDoesNotExistToAddLevelTarget = 'alex:importExport.importErrors.workstationDoesNotExistToAddLevelTarget',
  FetchingShiftError = 'alex:importExport.importErrors.fetchingShiftError',
}

const importSuffix = '-ImportTemplate';

export enum ImportExportFileNames {
  WorkerProfileSkill = 'WorkerSkills',
  Workstations = 'Workstations',
  TrainingSessions = 'TrainingSessions',
  SkillMatrix = 'SkillMatrix',
  Skills = 'Skills',
  Trainings = 'Trainings',
  Workers = 'Workers',
}

interface WorkerImportedData {
  workerInput: DeletablePropObject<API.WorkerCreateInput>;
  assignmentInputs: API.IndexedAssignment[];
}

const processWorksheetLock = new AsyncLock();
const getOrgUnitTagLock = new AsyncLock();
const getTrainingTagLock = new AsyncLock();
const getWorkerTagLock = new AsyncLock();
const getSkillTagLock = new AsyncLock();





export async function getExportDataForWorkers(
  workers: API.Worker[],
  includeWorkerSkills: boolean,
): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const firstSheetNumber = 1;
  const worksheet1 = workbook.addWorksheet('' + firstSheetNumber);

  addHeaders(worksheet1, await getWorkerHeaders());

  const obtentionDate = 'obtentionDate';
  const endOfValidityDate = 'endOfValidityDate';
  const skillHeaders: Map<string, { title: string; columnIndex: number; sheetName: string }> =
    new Map();
  const firstSkillColumn = Object.keys(WorkerColumns).length + 1;

  const failures: API.Failure[] = [];
  await Aigle.mapSeries(workers, async worker => {
    const data = await getWorkerCsvRow(worker);
    if (API.isFailure(data)) return data;
    const [workerCsvRow, assignements] = data;

    if (includeWorkerSkills) {
      const workerSkillMap = await getWorkerSkillsOnOrgUnit(
        worker,
        assignements.map(assignement => assignement.organizationalUnit.id),
      );
      if (API.isFailure(workerSkillMap)) {
        failures.push(workerSkillMap);
        return;
      }

      
      let csvRow:
        | (WorkerCsvRow & { [obtentionDate_or_endOfValidity: string]: CsvValue })
        | undefined = undefined;
      let worksheet: Worksheet | undefined = undefined;
      let previousWorksheet: Worksheet | undefined = undefined;
      
      for (const [skillId, workerSkill] of workerSkillMap.entries()) {
        if (!workerSkill.obtentionDate) continue;

        let skillHeader = skillHeaders.get(skillId);
        if (!skillHeader) {
          const numberOfSkillColumns = skillHeaders.size * 2; 
          const sheetName =
            '' +
            (firstSheetNumber +
              Math.floor(numberOfSkillColumns / (excelMaxNumbeOfColumns - firstSkillColumn)));
          const sheetColumnIndex =
            firstSkillColumn + (numberOfSkillColumns % (excelMaxNumbeOfColumns - firstSkillColumn));

          skillHeader = {
            title: workerSkill.name,
            columnIndex: sheetColumnIndex,
            sheetName,
          };
          skillHeaders.set(skillId, skillHeader);
        }

        worksheet = workbook.getWorksheet('' + skillHeader.sheetName);
        
        if (!worksheet) {
          worksheet = workbook.addWorksheet('' + skillHeader.sheetName);
          addHeaders(worksheet, await getWorkerHeaders());
        }

        
        if (!worksheet.getCell(1, skillHeader.columnIndex).value) {
          const header: Header = {
            title: skillHeader.title,
            subHeaders: [
              {
                key: obtentionDate + skillHeader.columnIndex,
                title: t('alex:importExport.headers.common.issueDateColumnTitle'),
                comment: t('alex:importExport.headers.common.issueDateColumnComment'),
              },
              {
                key: endOfValidityDate + skillHeader.columnIndex,
                title: t('alex:importExport.headers.common.expirationDateColumnTitle'),
                comment: t('alex:importExport.headers.common.expirationDateColumnComment'),
              },
            ],
          };
          addHeader(worksheet, header, 1, skillHeader.columnIndex);
        }

        
        if (previousWorksheet !== worksheet) {
          if (previousWorksheet !== undefined) {
            
            if (csvRow === undefined)
              return API.createFailure_Unspecified(
                'The row shall be initialized at this stage as workstations.length > 0. Please report, this is a bug.',
              );

            previousWorksheet.addRow(csvRow);
          }

          csvRow = {
            ...workerCsvRow,
          };
        }
        previousWorksheet = worksheet1;

        if (csvRow === undefined)
          return API.createFailure_Unspecified(
            'The row shall be initialized at this stage. Please report, this is a bug.',
          );

        csvRow[obtentionDate + skillHeader.columnIndex] = workerSkill.obtentionDate;
        csvRow[endOfValidityDate + skillHeader.columnIndex] = workerSkill.endOfValidityDate;
      }

      if (workerSkillMap.size) {
        if (worksheet === undefined)
          return API.createFailure_Unspecified(
            'The worksheet shall be initialized at this stage as workstations.length > 0. Please report, this is a bug.',
          );

        worksheet.addRow(csvRow);
      } else {
        csvRow = {
          ...workerCsvRow,
        };
        worksheet1.addRow(csvRow);
      }
    } else {
      worksheet1.addRow(workerCsvRow);
    }

    dispatchRowImportedExportedEvent(workers.length);
  });

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures: failures };
}

type WorkerSkillCsvData = {
  name: string;
  obtentionDate?: Date;
  endOfValidityDate?: Date;
};
/**
 * Get the workerSkills (including the missing skills
 * on the workstations where the Worker is assigned)
 * @param worker
 * @param assignments
 * @returns map skillId -> WorkerSkills (name, obtentionDate, expiryDate)
 */
async function getWorkerSkillsOnOrgUnit(
  worker: API.Worker,
  orgUnitIds: string[],
): Promise<API.Result<Map<string, WorkerSkillCsvData>>> {
  const workerAssignementSkillsMap = new Map<string, WorkerSkillCsvData>();

  const workerSkillsWithDetails = await API.getWorkerSkillsWithComplementaryDetails(worker.id);
  if (API.isFailure(workerSkillsWithDetails)) return workerSkillsWithDetails;

  for (const workerSkill of workerSkillsWithDetails.result) {
    const validity = workerSkill.validity;
    if (!logger.isPROD && !validity) {
      
      
      
      logger.warn(
        `Shall Validity always be defined (its seems according to the enum values)? Please fix the root issue for workerSkill "${workerSkill.id}".`,
        workerSkill,
      );
    }

    if (workerSkill.activeProofBundle) {
      

      let obtentionDate: Date | undefined;
      let endOfValidityDate: Date | undefined;
      if (
        validity === Validity.OK ||
        validity === Validity.OK_EXPIRE_SOON ||
        validity === Validity.KO_EXPIRED
      ) {
        obtentionDate = new Date(workerSkill.activeProofBundle.startingDate);
        endOfValidityDate = workerSkill.expiryDate ? new Date(workerSkill.expiryDate) : undefined;
      }

      workerAssignementSkillsMap.set(workerSkill.skillId, {
        name: workerSkill.skill.name,
        obtentionDate,
        endOfValidityDate,
      });
    }
  }

  
  const failures = await API.mapSeries(orgUnitIds, async orgUnitId => {
    const orgUnitRequirements = await API.getLevelsRequirementsWithInheritedAndOrDescendent(
      orgUnitId,
      true,
      true,
    );
    if (API.isFailure(orgUnitRequirements)) return orgUnitRequirements;

    let skillIds: string[] = [];
    orgUnitRequirements.forEach(requirment => {
      skillIds.push(
        ...API.extractSkillIds(requirment).filter(skillId => !skillIds.includes(skillId)),
      );
    });

    for (const skillId of skillIds) {
      if (!workerAssignementSkillsMap.get(skillId)) {
        const skill = await API.getSkill(skillId);
        if (API.isFailure(skill)) return skill;

        workerAssignementSkillsMap.set(skillId, {
          name: skill.name,
          obtentionDate: undefined,
          endOfValidityDate: undefined,
        });
      }
    }
  });
  if (API.isFailure(failures)) return failures;

  return workerAssignementSkillsMap;
}



export async function getExportDataForSkills(skills: API.Skill[]): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  addHeaders(worksheet, getSkillHeaders());

  const failures: API.Failure[] = [];
  for (const skill of skills) {
    const skillTags = await getTags(skill.tagIds, TagType.SkillTag);
    if (API.isFailure(skillTags)) {
      failures.push(skillTags);
      continue;
    }

    const { validityDuration, expiryNoticeDuration } =
      await API.getSkillValidityDurationAndExpiryNoticeDuration(skill);

    let subSkillNames: string[] | undefined = undefined;
    if (API.isSkillGroup(skill)) {
      const _subSkills = await API.mapSeries(skill.skillIds, async skillId => {
        return API.getSkill(skillId);
      });
      if (API.isFailure(_subSkills)) return _subSkills;

      subSkillNames = _subSkills.map(skill => skill.name);
    }

    const skillRow: SkillCsvRow = {
      [SkillColumns.skillName]: skill.name,
      [SkillColumns.isPractical]: skill.isPractical,
      [SkillColumns.skillTags]: skillTags.map(tag => tag.name).join(getExcelDelimiter()),
      [SkillColumns.validityDuration]: validityDuration ?? undefined,
      [SkillColumns.expiryNoticeDuration]: expiryNoticeDuration ?? undefined,
      [SkillColumns.description]: skill.description ?? '',
      [SkillColumns.subSkills]: subSkillNames?.join(getExcelDelimiter()),
    };
    worksheet.addRow(skillRow);

    dispatchRowImportedExportedEvent(skills.length);
  }

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures: failures };
}



export async function getExportDataForTrainings(trainings: API.Training[]): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  addHeaders(worksheet, getTrainingExportHeaders());

  const partialExportFailures = (
    await Aigle.mapLimit(trainings, async training => {
      const trainingVersion = await API.getTrainingVersionLatestForTraining(training.id);
      if (API.isFailure(trainingVersion)) return trainingVersion;

      const skills: string[] = [];
      for (const skillId of trainingVersion.skillIds) {
        const skill = await API.getSkill(skillId);
        if (API.isFailure(skill)) return skill;

        skills.push(skill.name);
      }

      const isTrainingPractical = await API.isPracticalTraining(training.id);
      if (API.isFailure(isTrainingPractical)) return isTrainingPractical;

      const tags: string[] = [];
      for (const trainingTagId of training.tagIds) {
        const trainingTag = await API.getTrainingTag(trainingTagId);
        if (API.isFailure(trainingTag)) return trainingTag;

        tags.push(trainingTag.name);
      }

      const row: TrainingExportCsvRow = {
        [TrainingExportColumns.trainingName]: training.name,
        [TrainingExportColumns.durationInHours]: trainingVersion.durationInMin / 60,
        [TrainingExportColumns.trainingTags]: tags.join(getExcelDelimiter()),
        [TrainingExportColumns.skillNames]: skills.join(getExcelDelimiter()),
        [TrainingExportColumns.description]: training.description ?? undefined,
        [TrainingExportColumns.notes]: training.notes ?? undefined,
        [TrainingExportColumns.trainingType]: isTrainingPractical
          ? t<string>('glossary:trainingPractical')
          : t<string>('glossary:trainingNotPractical'),
      };
      worksheet.addRow(row);

      dispatchRowImportedExportedEvent(trainings.length);
    })
  ).filter(f => f !== undefined);

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures };
}



export async function getExportDataForWorkerTrainingSessions(): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  addHeaders(worksheet, getWorkerTrainingSessionExportHeaders());

  const trainingSessions = await API.getTrainingSessions([
    API.TrainingSessionStage.Archived,
    API.TrainingSessionStage.InProgress,
    API.TrainingSessionStage.Proposal,
    API.TrainingSessionStage.Request,
  ]);
  if (API.isFailure(trainingSessions)) return trainingSessions;

  const errors = (
    await Aigle.mapSeries(trainingSessions, async trainingSession => {
      const sessionTrainees: (WorkerShortCsvRow & {
        proofBundles?: API.ProofBundle[];
      })[] = [];

      const state: string = API.isTrainingSessionDraftOrRequestValidated(trainingSession)
        ? t('alex:trainingSessions.trainingTypes.toBePlanned')
        : API.isTrainingSessionStartedOrStartedLate(trainingSession)
        ? t('common:time.started_f')
        : API.isTrainingSessionScheduledOrLateStart(trainingSession)
        ? t('alex:trainingSessions.trainingTypes.planned')
        : API.isTrainingSessionLateEnd(trainingSession)
        ? t('alex:trainingSessions.trainingTypes.toBeClosed')
        : API.isTrainingSessionEndedOrEndedLate(trainingSession)
        ? t('common:time.ended_f')
        : API.isTrainingSessionRequestRejected(trainingSession)
        ? t('common:time.rejected_f')
        : API.isTrainingSessionValidated(trainingSession)
        ? t('alex:trainingSessions.trainingTypes.validated')
        : API.isTrainingSessionRequested(trainingSession)
        ? t('alex:trainingSessions.trainingTypes.requested')
        : '';

      const traineesAndScheduledTraineesIds: string[] = [
        ...new Set([...trainingSession.scheduledTraineeIds, ...trainingSession.traineeIds]),
      ];

      const trainingSessionProofBundles = await API.getProofBundles(trainingSession.id);
      if (API.isFailure(trainingSessionProofBundles)) return trainingSessionProofBundles;

      const workersProofBundles: Map<string, API.ProofBundle[]> = new Map();
      for (const proofBundle of trainingSessionProofBundles) {
        const workerProofBundles = workersProofBundles.get(proofBundle.workerId);
        if (!workerProofBundles) {
          workersProofBundles.set(proofBundle.workerId, [proofBundle]);
        } else {
          workerProofBundles.push(proofBundle);
        }
      }

      for (const traineeId of traineesAndScheduledTraineesIds) {
        const worker = await API.getWorker(traineeId);
        if (API.isFailure(worker)) return worker;

        const workerShortCsvRow = await getWorkerShortCsvRow(worker);
        if (API.isFailure(workerShortCsvRow)) return workerShortCsvRow;

        sessionTrainees.push({
          ...workerShortCsvRow,
          proofBundles: workersProofBundles.get(traineeId) ?? [],
        });
      }

      const trainingVersion = await API.getTrainingVersion(trainingSession.trainingVersionId);
      if (API.isFailure(trainingVersion)) return trainingVersion;

      const training = await API.getTrainingForATrainingVersion(trainingSession.trainingVersionId);
      if (API.isFailure(training)) return training;

      const skills: string[] = [];
      for (const skillId of trainingVersion.skillIds) {
        const skill = await API.getSkill(skillId);
        if (API.isFailure(skill)) return skill;

        skills.push(skill.name);
      }

      const isTrainingPractical = await API.isPracticalTraining(training.id);
      if (API.isFailure(isTrainingPractical)) return isTrainingPractical;

      const tags: string[] = [];
      for (const trainingTagId of training.tagIds) {
        const trainingTag = await API.getTrainingTag(trainingTagId);
        if (API.isFailure(trainingTag)) return trainingTag;

        tags.push(trainingTag.name);
      }

      let trainersName: string = '';
      let trainersPercentage: string = '';
      if (trainingSession.trainers) {
        for (const trainer of trainingSession.trainers) {
          const _trainer = await API.getWorker(trainer.trainerId);
          if (API.isFailure(_trainer)) return _trainer;

          trainersName = trainersName + _trainer.name + getExcelDelimiter();
          trainersPercentage = trainersPercentage + trainer.percentage + getExcelDelimiter();
        }
      }

      for (const sessionTrainee of sessionTrainees) {
        let proofBundlesStates: string[] = [];

        if (sessionTrainee.proofBundles) {
          await Aigle.map(sessionTrainee.proofBundles, async proofBundle => {
            const date: string = proofBundle.review.date
              ? dayjs(proofBundle.review.date).format('LLL')
              : ImportExportFailureMessageKey.ProofBundleReviewDateMissing;

            let _proofBundleState = `${
              proofBundle.review.state === API.ReviewState.VALIDATED
                ? t('alex:importExport.proofBundleReviewStates.proofValidatedOn', {
                    date,
                  })
                : proofBundle.review.state === API.ReviewState.REJECTED ||
                  proofBundle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
                ? t('alex:importExport.proofBundleReviewStates.proofRejectedOn', {
                    date,
                  })
                : t('alex:importExport.proofBundleReviewStates.waitingForValidation')
            }`;
            if (proofBundle.skillId) {
              const skill = await API.getSkill(proofBundle.skillId);
              if (API.isFailure(skill)) {
                logger.error(`Failed to fetch Skill! ${proofBundle.skillId}`);
                return skill;
              }

              _proofBundleState = skill.name + TraineeSkillValiditySeparator + _proofBundleState;
            }

            proofBundlesStates.push(_proofBundleState);
          });
        }

        const startingDate = trainingSession.startDate
          ? new Date(trainingSession.startDate)
          : undefined;
        const endingDate = trainingSession.endDate ? new Date(trainingSession.endDate) : undefined;

        const row: TrainingSessionCsvRow = {
          [WorkerTrainingSessionExportColumns.workerName]: sessionTrainee.workerName,
          [WorkerTrainingSessionExportColumns.personalId]: sessionTrainee.personalId,
          [WorkerTrainingSessionExportColumns.workerTags]: sessionTrainee.workerTags,
          [WorkerTrainingSessionExportColumns.contractsTypeName]: sessionTrainee.contractsTypeName,
          [WorkerTrainingSessionExportColumns.contractsStart]: sessionTrainee.contractsStart,
          [WorkerTrainingSessionExportColumns.contractsEnd]: sessionTrainee.contractsEnd,
          [WorkerTrainingSessionExportColumns.assignments]: sessionTrainee.assignments,
          [WorkerTrainingSessionExportColumns.trainingName]: training.name,
          [WorkerTrainingSessionExportColumns.trainingType]: isTrainingPractical
            ? t<string>('glossary:trainingPractical')
            : t<string>('glossary:trainingNotPractical'),
          [WorkerTrainingSessionExportColumns.comment]: trainingSession.description ?? '',
          [WorkerTrainingSessionExportColumns.trainingState]: state,
          [WorkerTrainingSessionExportColumns.referenceDurationInHour]: round(
            trainingVersion.durationInMin / 60,
            1,
          ),
          [WorkerTrainingSessionExportColumns.durationInHour]: trainingSession.durationInMin
            ? round(trainingSession.durationInMin / 60, 1)
            : undefined,
          [WorkerTrainingSessionExportColumns.startingDate]: startingDate,
          [WorkerTrainingSessionExportColumns.endingDate]: endingDate,
          [WorkerTrainingSessionExportColumns.trainersName]: trainersName,
          [WorkerTrainingSessionExportColumns.trainersPercentage]: trainersPercentage,
          [WorkerTrainingSessionExportColumns.skillNames]: skills.join(getExcelDelimiter()),
          [WorkerTrainingSessionExportColumns.validation]: proofBundlesStates.join(', '),
        };
        worksheet.addRow(row);
      }

      dispatchRowImportedExportedEvent(trainingSessions.length);
    })
  ).filter(f => f !== undefined);

  dispatchImportExportFinishedEvent();
  return { partialExportFailures: errors, exportWorkbook: workbook };
}



export async function getExportDataForWorkstations(
  treeNode: API.TreeNode,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  addHeaders(worksheet, getWorkstationHeaders(workstationTargetsStartingAtLevel));

  
  const skillNameMap = new Map<string, string>();
  const skills = await API.getSkills();
  if (API.isFailure(skills)) return skills;
  for (const skill of skills.result) {
    skillNameMap.set(skill.id, skill.name);
  }

  const result = await addTreeNodeToWorksheet(treeNode, worksheet);

  /**
   * Add to the given worksheet - respecting order - the given TreeNode and its children recursively
   * @param treeNode
   * @returns array of Failures that might have arose
   */
  async function addTreeNodeToWorksheet(
    treeNode: TreeNode,
    worksheet: Worksheet,
  ): Promise<API.Failure[]> {
    let name, description: string;
    let level2MinimumTarget: string | undefined,
      level2IdealTarget: string | undefined,
      level3MinimumTarget: string | undefined,
      level3IdealTarget: string | undefined,
      level4MinimumTarget: string | undefined,
      level4IdealTarget: string | undefined;
    if (API.isFactory(API.DataType.WORKSTATION, treeNode.factory)) {
      name = treeNode.factory.workstation.name;
      description = treeNode.factory.workstation.description ?? '';

      if (workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL2) {
        const levelTargets = await constructLevelTargets(
          treeNode.factory.workstation,
          API.WorkstationWorkerLevels.LEVEL2,
          treeNode.factory.workstation.shiftIds,
        );
        level2MinimumTarget = levelTargets.minNumberOfWorker;
        level2IdealTarget = levelTargets.idealNumberOfWorker;
      }

      if (workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3) {
        const levelTargets = await constructLevelTargets(
          treeNode.factory.workstation,
          API.WorkstationWorkerLevels.LEVEL3,
          treeNode.factory.workstation.shiftIds,
        );
        level3MinimumTarget = levelTargets.minNumberOfWorker;
        level3IdealTarget = levelTargets.idealNumberOfWorker;
      }

      if (workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL4) {
        const levelTargets = await constructLevelTargets(
          treeNode.factory.workstation,
          API.WorkstationWorkerLevels.LEVEL4,
          treeNode.factory.workstation.shiftIds,
        );
        level4MinimumTarget = levelTargets.minNumberOfWorker;
        level4IdealTarget = levelTargets.idealNumberOfWorker;
      }
    } else {
      name = ''; 
      description = ''; 
    }

    const orgUnitPathNames = await getExportOrgUnitPathNames(treeNode.id);

    
    const levelsRequirements = await API.getLevelsRequirementsWithInheritedAndOrDescendent(
      treeNode.id,
      true,
      false,
    );
    if (API.isFailure(levelsRequirements)) return [levelsRequirements];

    
    const level1SkillNames = API.extractSkillIds(
      levelsRequirements.get(API.WorkstationWorkerLevels.LEVEL1) ?? [],
    )
      .map(skillId => skillNameMap.get(skillId))
      .join(getExcelDelimiter());
    const level2SkillNames = API.extractSkillIds(
      levelsRequirements.get(API.WorkstationWorkerLevels.LEVEL2) ?? [],
    )
      .map(skillId => skillNameMap.get(skillId))
      .join(getExcelDelimiter());
    const level3SkillNames = API.extractSkillIds(
      levelsRequirements.get(API.WorkstationWorkerLevels.LEVEL3) ?? [],
    )
      .map(skillId => skillNameMap.get(skillId))
      .join(getExcelDelimiter());
    const level4SkillNames = API.extractSkillIds(
      levelsRequirements.get(API.WorkstationWorkerLevels.LEVEL4) ?? [],
    )
      .map(skillId => skillNameMap.get(skillId))
      .join(getExcelDelimiter());

    const worksationRow: WorkstationCsvRow = {
      [WorkstationColumns.workstationName]: name,
      [WorkstationColumns.orgUnitPathNames]: orgUnitPathNames,
      [WorkstationColumns.description]: description,
      [WorkstationColumns.level1SkillNames]: level1SkillNames,
      [WorkstationColumns.level2SkillNames]: level2SkillNames,
      [WorkstationColumns.level3SkillNames]: level3SkillNames,
      [WorkstationColumns.level4SkillNames]: level4SkillNames,
      [WorkstationColumns.level2MinimumTarget]: level2MinimumTarget,
      [WorkstationColumns.level2IdealTarget]: level2IdealTarget,
      [WorkstationColumns.level3MinimumTarget]: level3MinimumTarget,
      [WorkstationColumns.level3IdealTarget]: level3IdealTarget,
      [WorkstationColumns.level4MinimumTarget]: level4MinimumTarget,
      [WorkstationColumns.level4IdealTarget]: level4IdealTarget,
    };
    worksheet.addRow(worksationRow);

    dispatchRowImportedExportedEvent(
      API.Tree.getNumberOfOrganizationalUnits() + API.Tree.getNumberOfWorkstations(),
    );

    const sortedChildren = treeNode.children.sort(child => child.object.order);

    
    const failures: API.Failure[] = [];
    for (const child of sortedChildren) {
      const result = await addTreeNodeToWorksheet(child, worksheet);
      if (API.isFailure(result)) failures.push(result);
    }

    return failures;
  }

  async function constructLevelTargets(
    workstation: API.NoMetadata<API.Workstation>,
    level: API.WorkstationWorkerLevels,
    shiftIds: readonly string[] | undefined | null,
  ): Promise<{ minNumberOfWorker: string | undefined; idealNumberOfWorker: string | undefined }> {
    const shiftsAndTargetLevels = new Map<string, API.ShiftWorkersTarget>();
    if (shiftIds && shiftIds.length) {
      Promise.all(
        await Aigle.map(shiftIds, async shiftId => {
          const shift = await API.getShift(shiftId);
          if (API.isFailure(shift)) return shift;

          const targetOnShift = API.extractWORKSTATIONWorkersTargetOnShift(workstation, shift.id);
          if (targetOnShift) shiftsAndTargetLevels.set(shift.name, targetOnShift);
        }),
      );
    } else {
      const targetOnWorkstation = API.extractWORKSTATIONWorkersTargetOnShift(workstation);
      if (targetOnWorkstation) shiftsAndTargetLevels.set('', targetOnWorkstation);
    }

    let _minNumberOfWorker: string | undefined = '';
    let _idealNumberOfWorker: string | undefined = '';

    switch (level) {
      case API.WorkstationWorkerLevels.LEVEL2:
        for (const [shift, targetLevel] of shiftsAndTargetLevels) {
          if (targetLevel.workersWithLevel2AtLeastWorkstationTarget?.minNumberOfWorker)
            if (shift.length)
              _minNumberOfWorker =
                _minNumberOfWorker && _minNumberOfWorker.length
                  ? _minNumberOfWorker +
                    getExcelDelimiter() +
                    shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker
                  : shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker;
            else
              _minNumberOfWorker =
                targetLevel.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker?.toString();

          if (targetLevel.workersWithLevel2AtLeastWorkstationTarget?.idealNumberOfWorker)
            if (shift.length)
              _idealNumberOfWorker =
                _idealNumberOfWorker && _idealNumberOfWorker.length
                  ? _idealNumberOfWorker +
                    getExcelDelimiter() +
                    shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker
                  : shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker;
            else
              _idealNumberOfWorker =
                targetLevel.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker?.toString();

          if (targetLevel.workersWithLevel2AtLeastWorkstationTarget?.isPercentage) {
            _minNumberOfWorker += '%';
            _idealNumberOfWorker += '%';
          }
        }
        return { minNumberOfWorker: _minNumberOfWorker, idealNumberOfWorker: _idealNumberOfWorker };
      case API.WorkstationWorkerLevels.LEVEL3:
        for (const [shift, targetLevel] of shiftsAndTargetLevels) {
          if (targetLevel.workersWithLevel3AtLeastWorkstationTarget?.minNumberOfWorker)
            if (shift.length)
              _minNumberOfWorker =
                _minNumberOfWorker && _minNumberOfWorker.length
                  ? _minNumberOfWorker +
                    getExcelDelimiter() +
                    shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker
                  : shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker;
            else
              _minNumberOfWorker =
                targetLevel.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker?.toString();

          if (targetLevel.workersWithLevel3AtLeastWorkstationTarget?.idealNumberOfWorker)
            _idealNumberOfWorker = shift.length
              ? _idealNumberOfWorker && _idealNumberOfWorker.length
                ? _idealNumberOfWorker +
                  getExcelDelimiter() +
                  shift +
                  ShiftSeparator +
                  targetLevel.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker
                : shift +
                  ShiftSeparator +
                  targetLevel.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker
              : targetLevel.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker?.toString();

          if (targetLevel.workersWithLevel3AtLeastWorkstationTarget?.isPercentage) {
            _minNumberOfWorker += '%';
            _idealNumberOfWorker += '%';
          }
        }
        return { minNumberOfWorker: _minNumberOfWorker, idealNumberOfWorker: _idealNumberOfWorker };
      case API.WorkstationWorkerLevels.LEVEL4:
        for (const [shift, targetLevel] of shiftsAndTargetLevels) {
          if (targetLevel.workersWithLevel4WorkstationTarget?.minNumberOfWorker)
            if (shift.length)
              _minNumberOfWorker =
                _minNumberOfWorker && _minNumberOfWorker.length
                  ? _minNumberOfWorker +
                    getExcelDelimiter() +
                    shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel4WorkstationTarget.minNumberOfWorker
                  : shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel4WorkstationTarget.minNumberOfWorker;
            else
              _minNumberOfWorker =
                targetLevel.workersWithLevel4WorkstationTarget.minNumberOfWorker?.toString();

          if (targetLevel.workersWithLevel4WorkstationTarget?.idealNumberOfWorker)
            if (shift.length)
              _idealNumberOfWorker =
                _idealNumberOfWorker && _idealNumberOfWorker.length
                  ? _idealNumberOfWorker +
                    getExcelDelimiter() +
                    shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel4WorkstationTarget.idealNumberOfWorker
                  : shift +
                    ShiftSeparator +
                    targetLevel.workersWithLevel4WorkstationTarget.idealNumberOfWorker;
            else
              _idealNumberOfWorker =
                targetLevel.workersWithLevel4WorkstationTarget.idealNumberOfWorker?.toString();

          if (targetLevel.workersWithLevel4WorkstationTarget?.isPercentage) {
            _minNumberOfWorker += '%';
            _idealNumberOfWorker += '%';
          }
        }
        return { minNumberOfWorker: _minNumberOfWorker, idealNumberOfWorker: _idealNumberOfWorker };
    }

    return { minNumberOfWorker: _minNumberOfWorker, idealNumberOfWorker: _idealNumberOfWorker };
  }

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures: result };
}

async function getExportOrgUnitPathNames(workstationOrOrgUnitId: string): Promise<string> {
  let orgUnitPathNames: string[] = await API.getOrgUnitPathNames(workstationOrOrgUnitId);

  
  

  
  orgUnitPathNames = orgUnitPathNames.filter(
    orgUnitPathName => orgUnitPathName !== API.orgUnitPathNameNotReachable,
  );

  return orgUnitPathNames.join(getExcelDelimiter());
}

async function getRowForWorkerProfileExport(
  worker: API.Worker,
  skill: API.Skill,
): Promise<API.Result<WorkerProfileCsvRow>> {
  const data = await getWorkerCsvRow(worker);
  if (API.isFailure(data)) return data;
  const [workerCsvRow] = data;

  const skillInfo = await API.getSkillInfo(skill.id, worker.id);
  if (API.isFailure(skillInfo)) return skillInfo;

  const workerSkill = await API.getWorkerSkill(worker.id, skill.id);
  if (API.isFailure(workerSkill) && workerSkill.type !== 'ObjectNotFound') return workerSkill;

  let obtentionDate: Date | undefined = undefined;
  let expiryDate: Date | undefined = undefined;

  if (!API.isFailure(workerSkill)) {
    const isProofValid: boolean =
      workerSkill.activeProofBundle?.review.state === API.ReviewState.VALIDATED;

    if (isProofValid) {
      const workerSkillInfo = await API.getWorkerSkillComplementaryDetails(workerSkill);
      if (API.isFailure(workerSkillInfo)) return workerSkillInfo;

      obtentionDate = workerSkill.activeProofBundle?.startingDate
        ? new Date(workerSkill.activeProofBundle!.startingDate)
        : undefined;
      expiryDate = workerSkillInfo.expiryDate ? new Date(workerSkillInfo.expiryDate) : undefined;
    }
  } 

  const csvData: WorkerProfileCsvRow = {
    [WorkerProfileColumns.familyName]: workerCsvRow.familyName,
    [WorkerProfileColumns.firstName]: workerCsvRow.firstName,
    [WorkerProfileColumns.email]: workerCsvRow.email,
    [WorkerProfileColumns.phone]: workerCsvRow.phone,
    [WorkerProfileColumns.personalId]: workerCsvRow.personalId,
    [WorkerProfileColumns.workerTags]: workerCsvRow.workerTags,
    [WorkerProfileColumns.description]: workerCsvRow.description,
    [WorkerProfileColumns.contractsTypeName]: workerCsvRow.contractsTypeName,
    [WorkerProfileColumns.contractsStart]: workerCsvRow.contractsStart,
    [WorkerProfileColumns.contractsEnd]: workerCsvRow.contractsEnd,
    [WorkerProfileColumns.orgUnitsName]: workerCsvRow.orgUnitsName,
    [WorkerProfileColumns.orgUnitsRoleName]: workerCsvRow.orgUnitsRoleName,
    [WorkerProfileColumns.orgUnitsPermissions]: workerCsvRow.orgUnitsPermissions,
    [WorkerProfileColumns.skillName]: skill.name,
    [WorkerProfileColumns.issueDate]: obtentionDate,
    [WorkerProfileColumns.expiryDate]: expiryDate,
    [WorkerProfileColumns.skillWorkstations]: skillInfo.workstations,
  };

  return csvData;
}



export async function getExportDataForWorkersInSkillScreen(
  rows: SkillWorkerTableRow[],
): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  addHeaders(worksheet, await getWorkerProfileHeaders());

  const partialExportFailures = (
    await Aigle.mapLimit(rows, async row => {
      if (!row.skillData) return API.createFailure('ObjectNotFound', 'Skill data missing');

      const skill = await API.getSkill(row.skillData.skillId);
      if (API.isFailure(skill)) return skill;

      const csvRow = await getRowForWorkerProfileExport(row.worker, skill);
      if (API.isFailure(csvRow)) return csvRow;

      worksheet.addRow(csvRow);
      dispatchRowImportedExportedEvent(rows.length);
    })
  ).filter(f => f !== undefined);

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures };
}



export async function getExportDataForWorkersInTrainingScreen(
  rows: TrainingWorkerTableRow[],
  training: API.Training,
): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();

  addHeaders(worksheet, await getWorkerProfileHeaders());

  const trainingVersion = await API.getTrainingVersionLatestForTraining(training.id);
  if (API.isFailure(trainingVersion)) return trainingVersion;

  const partialExportFailures = (
    await Aigle.mapSeries(rows, async row => {
      const workerSkills = await API.getWorkerSkillsWithComplementaryDetails(row.worker.id);
      if (API.isFailure(workerSkills)) return workerSkills;

      for (const workerSkill of workerSkills.result) {
        if (!trainingVersion.skillIds.includes(workerSkill.skillId)) continue;

        if (!workerSkill.activeProofBundle) continue;

        const csvRow = await getRowForWorkerProfileExport(row.worker, workerSkill.skill);
        if (API.isFailure(csvRow)) return csvRow;

        if (!csvRow) return; 

        worksheet.addRow(csvRow);
      }

      dispatchRowImportedExportedEvent(rows.length);
    })
  ).filter(f => f !== undefined);

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures };
}



export async function getExportDataForWorkerProfile(rows: WorkerSkillRow[]): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  addHeaders(worksheet, await getWorkerProfileHeaders());

  const partialExportFailures = (
    await Aigle.mapLimit(rows, async row => {
      const worker = await API.getWorker(row.workerId);
      if (API.isFailure(worker)) return worker;

      const csvRow = await getRowForWorkerProfileExport(worker, row.skill);
      if (API.isFailure(csvRow)) return csvRow;

      if (!csvRow) return; 

      worksheet.addRow(csvRow);
      dispatchRowImportedExportedEvent(rows.length);
    })
  ).filter(f => f !== undefined);

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures };
}



/**
 * getCsvDataForSkillsToRenew Or getCsvDataForSkillsToAcquire
 * @param workers
 * @param workstations
 * @param skillsToAquireOrToRenew true=toAcquire, false=toRenew
 */
export async function getExportDataForSkillsToRenewOrAcquire(
  workers: API.Worker[] | undefined,
  workstations: API.Workstation[] | undefined,
  skillsToAquireOrToRenew: boolean,
): Promise<ExportReport> {
  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  addHeaders(
    worksheet,
    skillsToAquireOrToRenew ? getSkillToAcquireHeaders() : getSkillToRenewHeaders(),
  );

  let workersWorkstations: API.WorkerWorkstation[];
  if (!workers && !workstations) {
    workersWorkstations = API.getWorkerWorkstations();
  } else {
    workersWorkstations = API.getWorkersWorkstations(
      workstations?.map(ws => ws.id) ?? [],
      workers?.map(w => w.id) ?? [],
    );
  }

  let skillsToRenewOrAcquire: API.WorkerSkillRequirement[] = [];
  {
    for (const workerWorkstation of workersWorkstations) {
      if (
        workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRED ||
        workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON ||
        
        API.isWorkerInTrainingOnWorkstation(workerWorkstation as API.WorkerWorkstation)
      ) {
        if (!skillsToAquireOrToRenew) {
          for (const validExpireSoonSkill of [
            ...(workerWorkstation.validExpireSoonSkills ?? []),
            ...(workerWorkstation.invalidExpiredSkills ?? []),
          ]) {
            if (validExpireSoonSkill.workerSkillId) {
              const ids = API.getWorkerIdSkillId(validExpireSoonSkill.workerSkillId);
              if (API.isFailure(ids)) return ids;

              skillsToRenewOrAcquire.push({
                skillId: ids.skillId,
                workerId: ids.workerId,
                workerSkillId: validExpireSoonSkill.workerSkillId,
              });
            }
          }
        } else {
          for (const invalidNoRefreshSkill of workerWorkstation.invalidNoRefreshSkills ?? []) {
            const ids = API.getWorkerIdSkillId(invalidNoRefreshSkill.workerSkillId);
            if (API.isFailure(ids)) return ids;

            skillsToRenewOrAcquire.push({
              skillId: ids.skillId,
              workerId: ids.workerId,
              workerSkillId: invalidNoRefreshSkill.workerSkillId,
            });
          }

          workerWorkstation.invalidMissingSkills?.forEach(invalidMissingSkill => {
            skillsToRenewOrAcquire.push({
              skillId: invalidMissingSkill.skillId,
              workerId: workerWorkstation.workerId,
            });
          });
        }
      }
    }

    skillsToRenewOrAcquire = _.uniqBy(
      skillsToRenewOrAcquire,
      skillToRenewOrAcquire => skillToRenewOrAcquire.skillId + skillToRenewOrAcquire.workerId,
    );
  }

  const workersMap = new Map<string, API.Worker>();
  workers?.forEach(worker => {
    workersMap.set(worker.id, worker);
  });

  const partialExportFailures = (
    await Aigle.mapSeries(skillsToRenewOrAcquire, async _skillToRenewOrAcquire => {
      const worker = workersMap.get(_skillToRenewOrAcquire.workerId);
      if (!worker)
        return API.createFailure(
          'ObjectNotFound',
          'worker not found id=' + _skillToRenewOrAcquire.workerId,
        );

      const workerShortCsvRow = await getWorkerShortCsvRow(worker);
      if (API.isFailure(workerShortCsvRow)) return workerShortCsvRow;

      const skillInfo = await API.getSkillInfo(_skillToRenewOrAcquire.skillId, worker.id, true);
      if (API.isFailure(skillInfo)) return skillInfo;

      const { validityDuration, expiryNoticeDuration } =
        await API.getSkillValidityDurationAndExpiryNoticeDuration(skillInfo.skill);

      let _date: Date | undefined = undefined;

      if (_skillToRenewOrAcquire.workerSkillId) {
        const workerSkill = await API.getWorkerSkillByWorkerSkillId(
          _skillToRenewOrAcquire.workerSkillId,
        );
        if (API.isFailure(workerSkill)) return workerSkill;

        const _workerSkillWithCompDetails = await API.getWorkerSkillComplementaryDetails(
          workerSkill,
        );

        if (API.isFailure(_workerSkillWithCompDetails)) return _workerSkillWithCompDetails;

        _date = _workerSkillWithCompDetails.expiryDate
          ? new Date(_workerSkillWithCompDetails.expiryDate)
          : undefined;
      }

      const row: SkillToAcquireOrRenewCsvRow = {
        ...workerShortCsvRow,
        [SkillToAcquireOrRenewColumns.skillName]: skillInfo.skill.name,
        [SkillToAcquireOrRenewColumns.expirationDate]: _date,
        [SkillToAcquireOrRenewColumns.workstationsName]: skillInfo.workstations,
        [SkillToAcquireOrRenewColumns.trainingNames]: skillInfo.trainings,
        [SkillToAcquireOrRenewColumns.skillValidityDuration]: validityDuration ?? undefined,
      };

      worksheet.addRow(row);

      dispatchRowImportedExportedEvent(skillsToRenewOrAcquire.length);
    })
  ).filter(f => f !== undefined);

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures };
}



export async function getExportDataForSkillMatrix(
  workers: API.Worker[] | undefined,
  workstations: API.Workstation[] | undefined,
): Promise<ExportReport> {
  if (!workers?.length || !workstations?.length)
    return API.createFailure_Unspecified('No workers or workstations selected');

  dispatchImportExportFinishedEvent();

  const workbook = new Workbook();
  const firstSheetNumber = 1;
  const firstWorkstationColumn = Object.keys(SkillMatrixColumns).length + 1;
  const levelSuffix = 'level';
  const targetSuffix = 'target';
  const completionSuffix = 'progress';
  const workstationHeaders: Map<string, { sheetName: string; columnIndex: number; title: string }> =
    new Map();

  const partialExportFailures = (
    await Aigle.mapLimit(workers, async worker => {
      const workerShortCsvRow = await getWorkerShortCsvRow(worker);
      if (API.isFailure(workerShortCsvRow)) return workerShortCsvRow;

      
      let csvRow: SkillMatrixCsvRow | undefined = undefined;
      let worksheet: Worksheet | undefined = undefined;
      let previousWorksheet: Worksheet | undefined = undefined;
      
      for (const workstation of workstations) {
        let workstationHeader = workstationHeaders.get(workstation.id);
        if (!workstationHeader) {
          const numberOfWorkstationColumns = workstationHeaders.size * 3; 
          const sheetName =
            '' +
            (firstSheetNumber +
              Math.floor(
                numberOfWorkstationColumns / (excelMaxNumbeOfColumns - firstWorkstationColumn),
              ));
          const sheetColumnIndex =
            firstWorkstationColumn +
            (numberOfWorkstationColumns % (excelMaxNumbeOfColumns - firstWorkstationColumn));

          const parent = API.Tree.getParent(workstation.id);
          const suffix = API.isFailure(parent) || !parent ? '' : ' (' + parent.name + ')';

          workstationHeader = {
            sheetName,
            columnIndex: sheetColumnIndex,
            title: workstation.name + suffix,
          };
          workstationHeaders.set(workstation.id, workstationHeader);
        }

        worksheet = workbook.getWorksheet('' + workstationHeader.sheetName);
        
        if (!worksheet) {
          worksheet = workbook.addWorksheet('' + workstationHeader.sheetName);
          addHeaders(worksheet, getSkillMatrixHeader());
        }

        
        if (!worksheet.getCell(1, workstationHeader.columnIndex).value) {
          const header: Header = {
            title: workstationHeader.title,
            subHeaders: [
              {
                key: workstation.id + levelSuffix,
                title: t('glossary:level'),
              },
              {
                key: workstation.id + targetSuffix,
                title: t('alex:scheduleTrainingModal.targetLevel'),
              },
              {
                key: workstation.id + completionSuffix,
                title: t('alex:skillConformityModal.headers.0'),
              },
            ],
          };

          addHeader(worksheet, header, 1, workstationHeader.columnIndex);
        }

        
        if (previousWorksheet !== worksheet) {
          if (previousWorksheet !== undefined) {
            
            if (csvRow === undefined)
              return API.createFailure_Unspecified(
                'The row shall be initialized at this stage as workstations.length > 0. Please report, this is a bug.',
              );

            previousWorksheet.addRow(csvRow);
          }

          csvRow = {
            ...workerShortCsvRow,
          };
        }
        previousWorksheet = worksheet;

        const workerWorkstation = getWorkerWorkstations(workstation.id, worker.id);

        let completion: number | undefined = undefined;
        if (workerWorkstation?.targetLevel) {
          const workstationSkillsRequiredInLevels =
            await API.getSkillsAndWorkerSkillsRequiredForWorkstationLevels(
              workstation.id,
              worker.id,
              true,
              false,
            );
          if (API.isFailure(workstationSkillsRequiredInLevels))
            return workstationSkillsRequiredInLevels;

          let validWorkerSkillsCount = 0;
          let totalSkillsCount = 0;
          for (
            let level = API.WorkstationWorkerLevels.LEVEL1;
            level <= API.api2workstationWorkerLevels(workerWorkstation.targetLevel);
            level++
          ) {
            const requiredSkillAndWorkerSkills =
              workstationSkillsRequiredInLevels.skillsRequiredInLevels.get(level);
            if (!requiredSkillAndWorkerSkills) continue;

            totalSkillsCount = totalSkillsCount + requiredSkillAndWorkerSkills.length;

            requiredSkillAndWorkerSkills.forEach(requiredSkillAndWorkerSkill => {
              const validity = requiredSkillAndWorkerSkill.workerSkill?.validity;

              if (validity === Validity.OK || validity === Validity.OK_EXPIRE_SOON) {
                validWorkerSkillsCount++;
              }
            });
          }

          completion = totalSkillsCount === 0 ? 1 : validWorkerSkillsCount / totalSkillsCount;
        }

        if (csvRow === undefined)
          return API.createFailure_Unspecified(
            'The row shall be initialized at this stage. Please report, this is a bug.',
          );

        csvRow[workstation.id + levelSuffix] = workerWorkstation?.level
          ? API.getWorkstationWorkerLevelLabel(workerWorkstation?.level, false)
          : t<string>('alex:workstationWorkerLevelMenu.notAssigned');
        csvRow[workstation.id + targetSuffix] = API.getWorkstationWorkerLevelLabel(
          workerWorkstation?.targetLevel,
          false,
        );
        csvRow[workstation.id + completionSuffix] = completion;
      }

      if (worksheet === undefined)
        return API.createFailure_Unspecified(
          'The worksheet shall be initialized at this stage as workstations.length > 0. Please report, this is a bug.',
        );

      worksheet.addRow(csvRow);

      dispatchRowImportedExportedEvent(workers.length);
    })
  ).filter(f => f !== undefined);

  dispatchImportExportFinishedEvent();

  return { exportWorkbook: workbook, partialExportFailures };
}



export async function processWorkstationData(
  workSheet: Worksheet,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<ImportReport<API.TreeObject>> {
  return processWorksheet(
    workSheet,
    getWorkstationHeaders(workstationTargetsStartingAtLevel),
    async row => {
      const name = parseCell(
        row.getCell(WorkstationColumns.workstationName),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(name)) return name;

      const orgUnitPathNames = parseCell(
        row.getCell(WorkstationColumns.orgUnitPathNames),
        ExpectedCellDataType.Array,
      );
      if (API.isFailure(orgUnitPathNames)) return orgUnitPathNames;
      if (isValueDelete(orgUnitPathNames))
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
            keyword: DELETE_keyword,
            name: 'orgUnitPathNames',
          }),
        );

      const orgUnit = await parseOrgUnitPathName(orgUnitPathNames, true);
      if (API.isFailure(orgUnit)) return orgUnit;

      const description = parseCell(
        row.getCell(WorkstationColumns.description),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(description)) return description;

      const levelsSkills = new Map<API.WorkstationWorkerLevels, API.Skill[]>();

      
      const level1SkillNames = parseCell(
        row.getCell(WorkstationColumns.level1SkillNames),
        ExpectedCellDataType.Array,
      );
      if (API.isFailure(level1SkillNames)) return level1SkillNames;
      if (isValueDelete(level1SkillNames)) {
        levelsSkills.set(API.WorkstationWorkerLevels.LEVEL1, []);
      } else if (level1SkillNames.length) {
        const skills = await findSkillsByName(level1SkillNames);
        if (API.isFailure(skills)) return skills;

        levelsSkills.set(API.WorkstationWorkerLevels.LEVEL1, skills);
      }

      const level2SkillNames = parseCell(
        row.getCell(WorkstationColumns.level2SkillNames),
        ExpectedCellDataType.Array,
      );
      if (API.isFailure(level2SkillNames)) return level2SkillNames;
      if (isValueDelete(level2SkillNames)) {
        levelsSkills.set(API.WorkstationWorkerLevels.LEVEL2, []);
      } else if (level2SkillNames.length) {
        const skills = await findSkillsByName(level2SkillNames);
        if (API.isFailure(skills)) return skills;

        levelsSkills.set(API.WorkstationWorkerLevels.LEVEL2, skills);
      }

      const level3SkillNames = parseCell(
        row.getCell(WorkstationColumns.level3SkillNames),
        ExpectedCellDataType.Array,
      );
      if (API.isFailure(level3SkillNames)) return level3SkillNames;
      if (isValueDelete(level3SkillNames)) {
        levelsSkills.set(API.WorkstationWorkerLevels.LEVEL3, []);
      } else if (level3SkillNames.length) {
        const skills = await findSkillsByName(level3SkillNames);
        if (API.isFailure(skills)) return skills;

        levelsSkills.set(API.WorkstationWorkerLevels.LEVEL3, skills);
      }

      const level4SkillNames = parseCell(
        row.getCell(WorkstationColumns.level4SkillNames),
        ExpectedCellDataType.Array,
      );
      if (API.isFailure(level4SkillNames)) return level4SkillNames;
      if (isValueDelete(level4SkillNames)) {
        levelsSkills.set(API.WorkstationWorkerLevels.LEVEL4, []);
      } else if (level4SkillNames.length) {
        const skills = await findSkillsByName(level4SkillNames);
        if (API.isFailure(skills)) return skills;

        levelsSkills.set(API.WorkstationWorkerLevels.LEVEL4, skills);
      }

      const level2Minimum =
        workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL2
          ? parseCell(
              row.getCell(WorkstationColumns.level2MinimumTarget),
              ExpectedCellDataType.String,
            )
          : undefined;
      if (API.isFailure(level2Minimum)) return level2Minimum;

      const level2Ideal =
        workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL2
          ? parseCell(
              row.getCell(WorkstationColumns.level2IdealTarget),
              ExpectedCellDataType.String,
            )
          : undefined;
      if (API.isFailure(level2Ideal)) return level2Ideal;

      const level3Minimum =
        workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3
          ? parseCell(
              row.getCell(WorkstationColumns.level3MinimumTarget),
              ExpectedCellDataType.String,
            )
          : undefined;
      if (API.isFailure(level3Minimum)) return level3Minimum;

      const level3Ideal =
        workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL3
          ? parseCell(
              row.getCell(WorkstationColumns.level3IdealTarget),
              ExpectedCellDataType.String,
            )
          : undefined;
      if (API.isFailure(level3Ideal)) return level3Ideal;

      const level4Minimum =
        workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL4
          ? parseCell(
              row.getCell(WorkstationColumns.level4MinimumTarget),
              ExpectedCellDataType.String,
            )
          : undefined;
      if (API.isFailure(level4Minimum)) return level4Minimum;

      const level4Ideal =
        workstationTargetsStartingAtLevel <= API.WorkstationWorkerLevels.LEVEL4
          ? parseCell(
              row.getCell(WorkstationColumns.level4IdealTarget),
              ExpectedCellDataType.String,
            )
          : undefined;
      if (API.isFailure(level4Ideal)) return level4Ideal;

      
      
      const isLevel2Percentage = isLevelTargetInPercentage(level2Minimum, level2Ideal);
      if (API.isFailure(isLevel2Percentage)) return isLevel2Percentage;
      const isLevel3Percentage = isLevelTargetInPercentage(level3Minimum, level3Ideal);
      if (API.isFailure(isLevel3Percentage)) return isLevel3Percentage;
      const isLevel4Percentage = isLevelTargetInPercentage(level4Minimum, level4Ideal);
      if (API.isFailure(isLevel4Percentage)) return isLevel4Percentage;

      const filesToUpload: File[] = []; 
      const files = await parseFiles([], filesToUpload, adminOnly_overwriteNoDelete);

      let workstationOrOrgUnit: API.TreeObject;
      if (!name) {
        
        if (
          level2Minimum ||
          level2Ideal ||
          level3Minimum ||
          level3Ideal ||
          level4Minimum ||
          level4Ideal
        ) {
          
          return API.createFailure_Unspecified(
            "if no 'name' is specified, then the organizational Unit will be considered instead of the Workstation, but it cannot have targets. Please remove them and try to import again",
          );
        }

        const partialUpdate: API.OrganizationalUnitPartialUpdateInput = {
          id: orgUnit.id,
        };

        let updateProperty = checkUpdatableProperty(
          orgUnit,
          'description',
          description,
          partialUpdate,
          adminOnly_overwriteNoDelete,
        );
        if (API.isFailure(updateProperty)) return updateProperty;

        
        if (Object.keys(partialUpdate).length > 1) {
          const _orgUnit = await API.updateFactoryBusinessObject(
            API.DataType.ORGUNIT,
            partialUpdate,
          );
          if (API.isFailure(_orgUnit)) return _orgUnit;
        }

        
        workstationOrOrgUnit = orgUnit;
      } else {
        const workstation = await createOrUpdateWorkstation(
          {
            name,
            parentId: orgUnit.id,
            description,
            files,
          },
          adminOnly_overwriteNoDelete,
        );
        if (API.isFailure(workstation)) return workstation;

        workstationOrOrgUnit = workstation;

        
        {
          const workersTargetOnShift = new Map<string, API.ShiftWorkersTarget>();

          if (level2Minimum) {
            if (workstationTargetsStartingAtLevel > API.WorkstationWorkerLevels.LEVEL2)
              return API.createFailure_Unspecified(
                'You cannot specify level 2 minimum target when workstationTargetsStartingAtLevel=' +
                  workstationTargetsStartingAtLevel,
              );

            const setTargets2Minimum = await setWorkersTargetOnShiftOrWorkstation(
              workersTargetOnShift,
              level2Minimum,
              API.WorkstationWorkerLevels.LEVEL2,
              true,
              WorkstationColumns.level2MinimumTarget,
              workstationOrOrgUnit.id,
              isLevel2Percentage,
            );
            if (API.isFailure(setTargets2Minimum)) return setTargets2Minimum;
          }

          if (level2Ideal) {
            if (workstationTargetsStartingAtLevel > API.WorkstationWorkerLevels.LEVEL2)
              return API.createFailure_Unspecified(
                'You cannot specify level 2 ideal target when workstationTargetsStartingAtLevel=' +
                  workstationTargetsStartingAtLevel,
              );

            const setTargets2Ideal = await setWorkersTargetOnShiftOrWorkstation(
              workersTargetOnShift,
              level2Ideal,
              API.WorkstationWorkerLevels.LEVEL2,
              false,
              WorkstationColumns.level2IdealTarget,
              workstationOrOrgUnit.id,
              isLevel2Percentage,
            );
            if (API.isFailure(setTargets2Ideal)) return setTargets2Ideal;
          }

          if (level3Minimum) {
            if (workstationTargetsStartingAtLevel > API.WorkstationWorkerLevels.LEVEL3)
              return API.createFailure_Unspecified(
                'You cannot specify level 3 minimum target when workstationTargetsStartingAtLevel=' +
                  workstationTargetsStartingAtLevel,
              );

            const setTargets3Minimum = await setWorkersTargetOnShiftOrWorkstation(
              workersTargetOnShift,
              level3Minimum,
              API.WorkstationWorkerLevels.LEVEL3,
              true,
              WorkstationColumns.level3MinimumTarget,
              workstationOrOrgUnit.id,
              isLevel3Percentage,
            );
            if (API.isFailure(setTargets3Minimum)) return setTargets3Minimum;
          }

          if (level3Ideal) {
            if (workstationTargetsStartingAtLevel > API.WorkstationWorkerLevels.LEVEL3)
              return API.createFailure_Unspecified(
                'You cannot specify level 3 ideal target when workstationTargetsStartingAtLevel=' +
                  workstationTargetsStartingAtLevel,
              );

            const setTargets3Ideal = await setWorkersTargetOnShiftOrWorkstation(
              workersTargetOnShift,
              level3Ideal,
              API.WorkstationWorkerLevels.LEVEL3,
              false,
              WorkstationColumns.level3IdealTarget,
              workstationOrOrgUnit.id,
              isLevel3Percentage,
            );
            if (API.isFailure(setTargets3Ideal)) return setTargets3Ideal;
          }

          if (level4Minimum) {
            const setTargets4Minimum = await setWorkersTargetOnShiftOrWorkstation(
              workersTargetOnShift,
              level4Minimum,
              API.WorkstationWorkerLevels.LEVEL4,
              true,
              WorkstationColumns.level4MinimumTarget,
              workstationOrOrgUnit.id,
              isLevel4Percentage,
            );
            if (API.isFailure(setTargets4Minimum)) return setTargets4Minimum;
          }

          if (level4Ideal) {
            const setTargets4Ideal = await setWorkersTargetOnShiftOrWorkstation(
              workersTargetOnShift,
              level4Ideal,
              API.WorkstationWorkerLevels.LEVEL4,
              false,
              WorkstationColumns.level4IdealTarget,
              workstationOrOrgUnit.id,
              isLevel4Percentage,
            );
            if (API.isFailure(setTargets4Ideal)) return setTargets4Ideal;
          }

          const shiftWorkersTarget = workersTargetOnShift.get(workstationOrOrgUnit.id);
          if (shiftWorkersTarget && !validateWorkstationWorkerLevelTargets(shiftWorkersTarget))
            return API.createFailure_Unspecified(t(ImportExportFailureMessageKey.WWLTValueError));

          const workersTargetOnShiftString = workersTargetOnShift.size
            ? JSON.stringify(Object.fromEntries(workersTargetOnShift))
            : undefined;

          
          const partialUpdate: API.WorkstationPartialUpdateInput = {
            id: workstationOrOrgUnit.id,
          };

          const updateProperty = checkUpdatableProperty(
            workstationOrOrgUnit,
            'workersTargetOnShift',
            workersTargetOnShiftString,
            partialUpdate,
            adminOnly_overwriteNoDelete,
          );
          if (API.isFailure(updateProperty)) return updateProperty;

          
          if (Object.keys(partialUpdate).length > 1) {
            const updatedWorkstation = await API.updateFactoryBusinessObject(
              API.DataType.WORKSTATION,
              partialUpdate,
            );
            if (API.isFailure(updatedWorkstation)) return updatedWorkstation;
          }
        }
      }

      
      const levelRequirements = await createOrUpdateRequirement(
        {
          workstationOrOrgUnitId: workstationOrOrgUnit.id,
          levelsSkills,
        },
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(levelRequirements)) return levelRequirements;

      return workstationOrOrgUnit;
    },
    false, 
  );
}

/**
 * Takes an array of org unit names that might exist or not.
 *
 * If createMissingOrgUnits = true:
 *  Loops through names, gets or creates orgUnits to complete the path.
 *
 * If createMissingOrgUnits = false:
 *  Tries to find the best matching orgUnit according to the given orgUnitPathNames. If not found retunrs a failure ObjectNotFound
 * @param orgUnitPathNames: string[] - the names of the org units in path. If empty, it will returns the topest accessible orgUnit to the user
 * @param createMissingOrgUnits
 * @return  the id of the deepest (most nested) org unit in the path
 */
async function parseOrgUnitPathName(
  orgUnitPathNames: string[],
  createMissingOrgUnits: boolean,
): Promise<API.Result<API.OrganizationalUnit>> {
  return getOrgUnitTagLock.runSerial(async () => {
    /**in case the passed name is an Id we know this import is being done from the lambda hence we dont need to find the match */
    if (orgUnitPathNames.length === 1 && API.isID(orgUnitPathNames[0]))
      return await API.getOrganizationalUnitFromNetwork(orgUnitPathNames[0]);

    /**
     * Returns the OrgUnits that match the given parentsName:
     *  - consecutiveMatches for OrgUnits that have the parentsName without hole: parentsName=['A','B','C','D'] will match A > B > C > D
     *  - nonConsecutiveMatches for OrgUnits that have the parentsName without holes: parentsName=['B','D'] will match A > B > C > D
     * parentsName=['D','C'] will not match A > B > C > D
     * If no OrgUnit match the given name an 'ObjectNotFound' Failure is returned.
     * If several OrgUnits match the given name a Failure is returned.
     * @param orgUnitPathNames
     */
    const getOrgUnitsThatMatchParents = (
      orgUnitPathNames: string[],
    ): API.Result<{
      consecutiveMatches: API.OrganizationalUnit[];
      nonConsecutiveMatches: API.OrganizationalUnit[];
    }> => {
      if (orgUnitPathNames.length === 0)
        return { consecutiveMatches: [], nonConsecutiveMatches: [] };

      const orgUnits = API.Tree.getTreeObjectsByName(
        orgUnitPathNames[orgUnitPathNames.length - 1],
        undefined,
        API.DataType.ORGUNIT,
      );

      const consecutiveMatches: API.OrganizationalUnit[] = [];
      const nonConsecutiveMatches: API.OrganizationalUnit[] = [];

      const parentsName = API.deepClone(orgUnitPathNames);
      parentsName.pop();
      parentsName.reverse();

      for (const orgUnit of orgUnits) {
        const treeObject = API.Tree.getTreeNode(orgUnit.id);
        if (API.isFailure(treeObject)) return treeObject;

        let parentTreeNode = API.Tree.getParentTreeNode(treeObject);
        let consecutiveMatch = true;
        if (
          parentsName.every(parentName => {
            while (parentTreeNode) {
              if (searchMatch(parentTreeNode.object.name, parentName, true)) {
                parentTreeNode = API.Tree.getParentTreeNode(parentTreeNode);
                return true;
              }

              consecutiveMatch = false;
              
              
              parentTreeNode = API.Tree.getParentTreeNode(parentTreeNode);
            }
            return false;
          })
        ) {
          if (consecutiveMatch) consecutiveMatches.push(orgUnit);
          else nonConsecutiveMatches.push(orgUnit);
        }
      }

      return { consecutiveMatches, nonConsecutiveMatches };
    };

    const topTreeNodes = API.Tree.getTopTreeNodes();
    if (!topTreeNodes.length)
      return API.createFailure_Unspecified(t(ImportExportFailureMessageKey.TopOrgUnitInaccessible));

    if (orgUnitPathNames.length === 0) {
      return topTreeNodes[0].object; 
    }

    if (!createMissingOrgUnits) {
      const result = getOrgUnitsThatMatchParents(orgUnitPathNames);
      if (API.isFailure(result)) return result;

      if (result.consecutiveMatches.length === 0) {
        if (result.nonConsecutiveMatches.length === 0) {
          return API.createFailure(
            'ObjectNotFound',
            'OrgUnit matching path "' +
              orgUnitPathNames.join(getExcelDelimiter()) +
              '" could not be found',
          );
        }

        if (result.nonConsecutiveMatches.length === 1) return result.nonConsecutiveMatches[0];
      } else {
        if (result.consecutiveMatches.length === 1) return result.consecutiveMatches[0];
      }

      return API.createFailure_Unspecified(
        'There are several OrgUnits matching the path "' +
          orgUnitPathNames.join(getExcelDelimiter()) +
          '". Please complete the path to remove the uncertainity.',
      );
    } else {
      let i: number = 0;
      let currentParentOrgUnit: API.OrganizationalUnit;

      const topParent = topTreeNodes.find(
        topTreeNode => topTreeNode.object.name === orgUnitPathNames[i],
      );
      if (topParent) {
        currentParentOrgUnit = topParent.object; 
        i++;
      } else {
        currentParentOrgUnit = topTreeNodes[0].object; 
      }

      for (; i < orgUnitPathNames.length; i++) {
        const factory = await getOrCreateOrganizationalUnit(
          orgUnitPathNames[i],
          currentParentOrgUnit.id,
        );
        if (API.isFailure(factory)) return factory;

        currentParentOrgUnit = {
          ...factory.organizationalUnit,
          updatedBy: factory.updatedBy,
          updatedAt: factory.updatedAt,
        };
      }

      return currentParentOrgUnit;
    }
  });
}

async function parseFiles(
  currentFiles: readonly API.S3Object[],
  newFiles: File[],
  adminOnly_overwriteNoDelete: boolean,
): Promise<API.S3Object[] | DELETE_keyword> {
  if (isValueDelete(newFiles.map(file => file.name))) return DELETE_keyword; 

  const s3Objects: API.S3Object[] = [];
  await Aigle.map(newFiles, async file => {
    if (!currentFiles.some(currentS3Object => isSameFile(file, currentS3Object))) {
      const s3Object = await API.uploadFile(file, API.StorageVisibility.protected);
      if (API.isFailure(s3Object)) return s3Object;

      s3Objects.push(s3Object);
    }
  });

  return s3Objects;
}

/**
 * Will create the Workstation if it doens't exist or update it only the fields that are not set already
 * @param input
 * @returns
 */
async function createOrUpdateWorkstation(
  input: DeletablePropObject<API.WorkstationCreateInput>,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<API.Result<API.Workstation>> {
  const factory = await API.getOrCreateFactory(
    API.DataType.WORKSTATION,
    async () => {
      

      const currentWorkstation = getWorkstationByName(input.name, input.parentId);
      if (API.isFailure(currentWorkstation)) {
        if (API.isFailureType(currentWorkstation, 'ObjectNotFound')) {
          if (!isObjectFreeOfDeletableProp(input)) {
            return API.createFailure_Unspecified(
              'Cannot add ' +
                DELETE_keyword +
                ' flag when creating new Workstation. Please check your input.',
            );
          }
          return API.createFactoryBusinessObject(API.DataType.WORKSTATION, input);
        } else return currentWorkstation;
      }

      const partialUpdate: API.WorkstationPartialUpdateInput = {
        id: currentWorkstation.id,
      };

      let updateProperty = checkUpdatableProperty(
        currentWorkstation,
        'description',
        input.description,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorkstation,
        'workersTargetOnShift',
        input.workersTargetOnShift,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorkstation,
        'files',
        input.files,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      
      if (Object.keys(partialUpdate).length > 1) {
        return API.updateFactoryBusinessObject(API.DataType.WORKSTATION, partialUpdate);
      } else {
        return API.getFactoryBusinessObject(API.DataType.WORKSTATION, currentWorkstation.id);
      }
    },
    input.name + input.parentId,
  );
  if (API.isFailure(factory)) return factory;

  return { ...factory.workstation, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

async function createOrUpdateRequirement(
  input: {
    workstationOrOrgUnitId: string;
    levelsSkills: Map<API.WorkstationWorkerLevels, API.Skill[]>;
  },
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<API.Result<void>> {
  const levelsRequirementWithInherited =
    await API.getLevelsRequirementsWithInheritedAndOrDescendent(
      input.workstationOrOrgUnitId,
      true,
      false,
    );
  if (API.isFailure(levelsRequirementWithInherited)) return levelsRequirementWithInherited;

  const levelsRequirement = await API.getLevelsRequirement(input.workstationOrOrgUnitId);
  if (API.isFailure(levelsRequirement)) return levelsRequirement;

  for (
    let level = API.WorkstationWorkerLevels.LEVEL1;
    level <= API.WorkstationWorkerLevels.LEVEL4;
    level++
  ) {
    const levelRequirementWithInherited = levelsRequirementWithInherited.get(level);
    const levelRequirement = levelsRequirement.get(level); 
    const levelSkills = input.levelsSkills.get(level);
    if (levelSkills) {
      const skillTrainingVersions: API.SkillTrainingVersion[] = []; 
      for (const skill of levelSkills) {
        if (!adminOnly_overwriteNoDelete) {
          
          if (
            !levelRequirementWithInherited ||
            levelRequirementWithInherited.every(requirement =>
              requirement.skillTrainingVersions.every(existing => existing.skillId !== skill.id),
            )
          ) {
            skillTrainingVersions.push({ skillId: skill.id, __typename: 'SkillTrainingVersion' });
          }
        } else {
          
          if (
            !levelRequirement ||
            levelRequirement.skillTrainingVersions.every(existing => existing.skillId !== skill.id)
          ) {
            skillTrainingVersions.push({ skillId: skill.id, __typename: 'SkillTrainingVersion' });
          }
        }
      }

      if (!levelRequirement) {
        const createInput: API.RequirementCreateInput = {
          skillTrainingVersions,
          linkedObjectId: input.workstationOrOrgUnitId,
          level: API.workstationWorkerLevels2api(level),
        };

        const createdRequirement = await API.createRequirement(createInput);
        if (API.isFailure(createdRequirement)) return createdRequirement;
      } else {
        const partialUpdate: API.RequirementPartialUpdateInput = {
          id: levelRequirement.id,
        };

        const check = checkUpdatableProperty(
          levelRequirement,
          'skillTrainingVersions',
          skillTrainingVersions,
          partialUpdate,
          adminOnly_overwriteNoDelete,
        );
        if (API.isFailure(check)) return check;

        
        if (Object.keys(partialUpdate).length > 1) {
          const updateRequirement = await API.updateFactoryBusinessObject(
            API.DataType.REQUIREMENT,
            partialUpdate,
          );
          if (API.isFailure(updateRequirement)) return updateRequirement;
        }
      }
    }
  }
}

/**
 * WARNING : mutate workersTargetOnShift
 * @param workersTargetOnShift
 * @param targets
 * @param level
 * @param minimum
 * @param column
 * @param workstationId
 * @param isPercentage
 * @returns
 */
async function setWorkersTargetOnShiftOrWorkstation(
  workersTargetOnShift: Map<string, API.ShiftWorkersTarget>,
  targets: string,
  level: API.WorkstationWorkerLevels,
  minimum: boolean,
  column: string,
  workstationId: string,
  isPercentage: boolean,
): Promise<API.Result<void>> {
  const workstation = await API.getWorkstation(workstationId);
  if (API.isFailure(workstation)) return workstation;

  for (const levelTarget of targets.split(getExcelDelimiter())) {
    let _targets: API.ShiftWorkersTarget = {
      workersWithLevel3AtLeastWorkstationTarget: {},
      workersWithLevel4WorkstationTarget: {},
      workersWithLevel2AtLeastWorkstationTarget: {},
    };

    let shiftOrWorkstationId = '';
    let levelOnShift = '';

    const _levelTarget = levelTarget.split(ShiftSeparator);
    if (_levelTarget.length > 1) {
      const shift = await parseShift(_levelTarget[0], workstation.parentId);
      if (API.isFailure(shift)) {
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.FetchingShiftError, {
            column: column,
          }),
        );
      }

      levelOnShift = _levelTarget[1];
      shiftOrWorkstationId = shift.id;
    } else {
      levelOnShift = levelTarget;
      shiftOrWorkstationId = workstationId;
    }

    const targetsOnShift = workersTargetOnShift.get(shiftOrWorkstationId);
    const target = formatPercentageTarget(levelOnShift, isPercentage);

    switch (level) {
      case API.WorkstationWorkerLevels.LEVEL2:
        if (targetsOnShift) _targets = targetsOnShift;

        if (minimum) _targets.workersWithLevel2AtLeastWorkstationTarget.minNumberOfWorker = target;
        else _targets.workersWithLevel2AtLeastWorkstationTarget.idealNumberOfWorker = target;

        _targets.workersWithLevel2AtLeastWorkstationTarget.isPercentage = isPercentage;

        workersTargetOnShift.set(shiftOrWorkstationId, _targets);
        break;

      case API.WorkstationWorkerLevels.LEVEL3:
        if (targetsOnShift) _targets = targetsOnShift;

        if (minimum) _targets.workersWithLevel3AtLeastWorkstationTarget.minNumberOfWorker = target;
        else _targets.workersWithLevel3AtLeastWorkstationTarget.idealNumberOfWorker = target;

        _targets.workersWithLevel3AtLeastWorkstationTarget.isPercentage = isPercentage;

        workersTargetOnShift.set(shiftOrWorkstationId, _targets);
        break;

      case API.WorkstationWorkerLevels.LEVEL4:
        if (targetsOnShift) _targets = targetsOnShift;

        if (minimum) _targets.workersWithLevel4WorkstationTarget.minNumberOfWorker = target;
        else _targets.workersWithLevel4WorkstationTarget.idealNumberOfWorker = target;

        _targets.workersWithLevel4WorkstationTarget.isPercentage = isPercentage;

        workersTargetOnShift.set(shiftOrWorkstationId, _targets);
        break;
    }
  }
}

async function findSkillsByName(skillNames: string[]): Promise<API.Result<API.Skill[]>> {
  const skills: API.Skill[] = [];
  for (const skillName of skillNames) {
    const skill = await API.getSkillByName(skillName);
    if (API.isFailure(skill)) return skill;

    if (skill) skills.push(skill);
    else
      return API.createFailure(
        'ObjectNotFound',
        t(ImportExportFailureMessageKey.ObjectDoesNotExist, {
          type: t('glossary:skill'),
          name: skillName,
        }),
      );
  }
  return skills;
}



export async function processSkillData(
  workSheet: Worksheet,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<ImportReport<API.Factory<API.DataType.SKILL>>> {
  return processWorksheet(
    workSheet,
    getSkillHeaders(),
    async row => {
      const name = parseCell(row.getCell(SkillColumns.skillName), ExpectedCellDataType.String);
      if (API.isFailure(name)) return name;
      if (!name?.length)
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'skill name' }),
        );

      const isPractical = parseCell(
        row.getCell(SkillColumns.isPractical),
        ExpectedCellDataType.Boolean,
      );
      if (API.isFailure(isPractical)) return isPractical;
      if (isPractical === undefined)
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'practical skill' }),
        );
      if (isPractical === DELETE_keyword)
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
            keyword: DELETE_keyword,
            name: 'isPractical',
          }),
        );

      const _tags = parseCell(row.getCell(SkillColumns.skillTags), ExpectedCellDataType.Array);
      if (API.isFailure(_tags)) return _tags;
      const skillTagIds = isValueDelete(_tags)
        ? DELETE_keyword
        : await parseSkillTags(_tags, adminOnly_overwriteNoDelete);
      if (API.isFailure(skillTagIds)) return skillTagIds;

      const validityDuration = parseCell(
        row.getCell(SkillColumns.validityDuration),
        ExpectedCellDataType.Number,
      );
      if (API.isFailure(validityDuration)) return validityDuration;

      if (validityDuration && !isValueDelete(validityDuration)) {
        if (validityDuration < 0 || validityDuration > SKILL_MAX_MONTHS) {
          return API.createFailure_Unspecified(
            t(ImportExportFailureMessageKey.DurationNotInRange, {
              min: 0,
              max: SKILL_MAX_MONTHS,
            }),
          );
        }
      }

      const expiryNoticeDuration = parseCell(
        row.getCell(SkillColumns.expiryNoticeDuration),
        ExpectedCellDataType.Number,
      );
      if (API.isFailure(expiryNoticeDuration)) return expiryNoticeDuration;

      let subSkillIds: string[] | undefined;
      const subSkillNames = parseCell(
        row.getCell(SkillColumns.subSkills),
        ExpectedCellDataType.Array,
      );
      if (API.isFailure(subSkillNames)) return subSkillNames;

      if (subSkillNames?.length && !isValueDelete(subSkillNames)) {
        const subSkills = await findSkillsByName(subSkillNames);
        if (API.isFailure(subSkills)) return subSkills;

        subSkillIds = subSkills.map(subSkill => subSkill.id);
      }

      const description = parseCell(
        row.getCell(SkillColumns.description),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(description)) return description;

      const filesToUpload: File[] = []; 
      const files = await parseFiles([], filesToUpload, adminOnly_overwriteNoDelete);

      return createOrUpdateSkill(
        {
          name,
          isPractical,
          description,
          tagIds: skillTagIds,
          validityDuration,
          expiryNoticeDuration,
          files,
          skillIds: subSkillIds,
        },
        adminOnly_overwriteNoDelete,
      );
    },
    false, 
  );
}

/**
 * Convert a list of tag names into a list of SkillTag ids.
 * It create the SkillTags if they don't exist
 * @param tagNames names to parse
 */
async function parseSkillTags(
  tagNames: string[],
  adminOnly_overwriteNoDelete: boolean,
): Promise<API.Result<string[]>> {
  return getSkillTagLock.runSerial(async () => {
    const _skillTags = await API.getSkillTags();
    if (API.isFailure(_skillTags)) return _skillTags;

    const skillTags: API.Result<API.SkillTag[]> = await API.mapSeries(tagNames, async tagName => {
      const existingTag = _skillTags.find(skillTag => searchMatch(skillTag.name, tagName, true));
      if (existingTag) {
        return existingTag;
      } else {
        return API.createSkillTag({
          name: tagName,
        });
      }
    });
    if (API.isFailure(skillTags)) return skillTags;

    return skillTags.map(skillTag => skillTag.id);
  });
}

async function createOrUpdateSkill(
  input: DeletablePropObject<API.SkillCreateInput>,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<API.Result<API.Factory<API.DataType.SKILL>>> {
  return API.getOrCreateFactory(
    API.DataType.SKILL,
    async () => {
      

      const currentSkill = await API.getSkillByName(input.name);
      if (API.isFailure(currentSkill)) return currentSkill;

      if (!currentSkill) {
        if (!isObjectFreeOfDeletableProp(input)) {
          return API.createFailure_Unspecified(
            'Cannot add ' +
              DELETE_keyword +
              ' flag when creating new Skill. Please check your input.',
          );
        }
        return API.createSkill(input);
      }

      const partialUpdate: API.SkillPartialUpdateInput = { id: currentSkill.id };

      let updateProperty = checkUpdatableProperty(
        currentSkill,
        'isPractical',
        input.isPractical,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentSkill,
        'description',
        input.description,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentSkill,
        'tagIds',
        input.tagIds,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentSkill,
        'validityDuration',
        input.validityDuration,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentSkill,
        'expiryNoticeDuration',
        input.expiryNoticeDuration,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentSkill,
        'files',
        input.files,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      
      if (Object.keys(partialUpdate).length > 1) {
        return API.updateFactoryBusinessObject(API.DataType.SKILL, partialUpdate);
      } else {
        return API.getFactoryBusinessObject(API.DataType.SKILL, currentSkill.id);
      }
    },
    input.name,
  );
}



export async function processTrainingData(
  workSheet: Worksheet,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<ImportReport<API.Factory<API.DataType.TRAININGVERSION>>> {
  return processWorksheet(
    workSheet,
    getTrainingHeaders(),
    async row => {
      const name = parseCell(
        row.getCell(TrainingColumns.trainingName),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(name)) return name;
      
      if (!name?.length)
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'name' }),
        );

      const _duration = parseCell(
        row.getCell(TrainingColumns.durationInHours),
        ExpectedCellDataType.Number,
      );
      if (API.isFailure(_duration)) return _duration;
      if (_duration && !isValueDelete(_duration)) {
        if (_duration < 0 || _duration > TRAINING_DURATION_MAX_HOURS) {
          return API.createFailure_Unspecified(
            t(ImportExportFailureMessageKey.DurationNotInRange, {
              min: 0,
              max: TRAINING_DURATION_MAX_HOURS,
            }),
          );
        }
      }
      
      const durationInMin = isValueDelete(_duration)
        ? DELETE_keyword
        : _duration
        ? Math.round(_duration * 60)
        : API.defaultTrainingVersionDurationInMin;
      if (durationInMin === DELETE_keyword)
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
            keyword: DELETE_keyword,
            name: 'durationInMin',
          }),
        );

      const _tagNames = parseCell(
        row.getCell(TrainingColumns.trainingTags),
        ExpectedCellDataType.Array,
      );
      if (API.isFailure(_tagNames)) return _tagNames;
      const tagIds = isValueDelete(_tagNames)
        ? DELETE_keyword
        : await parseTrainingTags(_tagNames, adminOnly_overwriteNoDelete);
      if (API.isFailure(tagIds)) return tagIds;

      const _skillNames = parseCell(
        row.getCell(TrainingColumns.skillNames),
        ExpectedCellDataType.Array,
      );
      if (API.isFailure(_skillNames)) return _skillNames;
      const skillIds = isValueDelete(_skillNames)
        ? DELETE_keyword
        : await parseSkillsForTraining(_skillNames, adminOnly_overwriteNoDelete);
      if (API.isFailure(skillIds)) return skillIds;

      const description = parseCell(
        row.getCell(TrainingColumns.description),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(description)) return description;

      const notes = parseCell(row.getCell(TrainingColumns.notes), ExpectedCellDataType.String);
      if (API.isFailure(notes)) return notes;

      const filesToUpload: File[] = []; 
      const files = await parseFiles([], filesToUpload, adminOnly_overwriteNoDelete);

      const trainingVersions = await createOrUpdateTrainingAndTrainingVersion(
        {
          name,
          description,
          notes,
          tagIds,
          files,
        },
        {
          skillIds,
          durationInMin,
        },
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(trainingVersions)) {
        return trainingVersions;
      } else if (Array.isArray(trainingVersions)) {
        if (!trainingVersions.length) return API.createFailure_Unspecified('Training failed'); 

        const failures: API.Failure[] = [];
        for (const trainingVersion of trainingVersions) {
          if (API.isFailure(trainingVersion)) {
            failures.push(trainingVersion);
          }
        }
        if (failures.length) return API.createFailure_Multiple(failures);
        else return trainingVersions[0]; 
      } else {
        return trainingVersions;
      }
    },
    false, 
  );
}

async function parseTrainingTags(
  tagNames: string[],
  adminOnly_overwriteNoDelete: boolean,
): Promise<API.Result<string[]>> {
  return getTrainingTagLock.runSerial(async () => {
    const existingTrainingTags: API.Result<API.TrainingTag[]> = await API.getTrainingTags();
    if (API.isFailure(existingTrainingTags)) return existingTrainingTags;

    const trainingTags = await API.mapSeries(tagNames, tag => {
      const existingTag = existingTrainingTags.find(skillTag =>
        searchMatch(skillTag.name, tag, true),
      );
      if (existingTag) {
        return existingTag;
      } else {
        return API.createTrainingTag({ name: tag });
      }
    });
    if (API.isFailure(trainingTags)) return trainingTags;

    return trainingTags.map(skillTag => skillTag.id);
  });
}

/**
 * Parse the skills column of the training catalog import
 * @param skillNames skills names
 * @returns skillIds to link to the trainingVersion
 */
async function parseSkillsForTraining(
  skillNames: string[],
  adminOnly_overwriteNoDelete: boolean,
): Promise<API.Result<string[]>> {
  let areSkillsPractical: boolean | undefined = undefined;
  const skillIds: string[] = [];
  for (const skillName of skillNames) {
    const skill = await API.getSkillByName(skillName);
    if (API.isFailure(skill)) return skill;

    if (!skill) return API.createFailure('ObjectNotFound', `Skill "${skillName}" not found`);

    if (!skillIds.includes(skill.id)) {
      
      if (areSkillsPractical !== undefined && areSkillsPractical !== skill.isPractical)
        return API.createFailure_Unspecified(
          `All skills shall be from the same type (practical or not practical). Skill ${skillName} is not matching other skills type.`,
        );
      areSkillsPractical = skill.isPractical;
      skillIds.push(skill.id);
    }
  }

  return skillIds;
}

async function createOrUpdateTrainingAndTrainingVersion(
  trainingInput: DeletablePropObject<API.TrainingCreateInput>,
  trainingVersionInput: DeletablePropObject<Omit<API.TrainingVersionCreateInput, 'trainingId'>>,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<
  | API.Result<API.Factory<API.DataType.TRAININGVERSION>>
  | API.Result<API.Factory<API.DataType.TRAININGVERSION>>[]
> {
  return API.getOrCreateFactory(
    API.DataType.TRAININGVERSION,
    async (): Promise<
      | API.Result<API.Factory<API.DataType.TRAININGVERSION>>
      | API.Result<API.Factory<API.DataType.TRAININGVERSION>>[]
    > => {
      const allTrainings = await API.listFactoriesWithDataType(API.DataType.TRAINING);
      if (API.isFailure(allTrainings)) return allTrainings;

      
      const trainings: API.Training[] = allTrainings.result
        .filter(f => searchMatch(f.training.name, trainingInput.name, true))
        .map(f => {
          return { ...f.training, updatedBy: f.updatedBy, updatedAt: f.updatedAt };
        });

      
      if (!trainings.length) {
        if (!trainingVersionInput.skillIds.length) {
          return API.createFailure_Unspecified(
            t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'skill' }),
          );
        }
        if (!isObjectFreeOfDeletableProp(trainingInput)) {
          return API.createFailure_Unspecified(
            'Cannot add ' +
              DELETE_keyword +
              ' flag when creating new Training. Please check your input.',
          );
        }
        if (!isObjectFreeOfDeletableProp(trainingVersionInput)) {
          return API.createFailure_Unspecified(
            'Cannot add ' +
              DELETE_keyword +
              ' flag when creating new Trainingversion. Please check your input.',
          );
        }

        const practicalSkillName = await API.findPracticalSkill(trainingVersionInput.skillIds);
        if (API.isFailure(practicalSkillName)) return practicalSkillName;
        if (practicalSkillName)
          return API.createFailure_Unspecified(
            `Skill "${practicalSkillName}" is a practical Skill. Practical training cannot be created through the Training excel import. You can use the Workstation excel import to create practical training automaticaly and then update the practical trainings through Training excel import.`,
          );

        
        const createdTrainingVersionAndTraining = await API.createTraining(
          trainingInput,
          trainingVersionInput,
        );
        if (API.isFailure(createdTrainingVersionAndTraining))
          return createdTrainingVersionAndTraining;

        return API.getFactoryBusinessObject(
          API.DataType.TRAININGVERSION,
          createdTrainingVersionAndTraining[0].id,
        );
      } else {
        return Aigle.map(trainings, async training => {
          
          const partialUpdate: API.TrainingPartialUpdateInput = {
            id: training.id,
          };

          let updateProperty = checkUpdatableProperty(
            training,
            'description',
            trainingInput.description,
            partialUpdate,
            adminOnly_overwriteNoDelete,
          );
          if (API.isFailure(updateProperty)) return updateProperty;

          updateProperty = checkUpdatableProperty(
            training,
            'tagIds',
            trainingInput.tagIds,
            partialUpdate,
            adminOnly_overwriteNoDelete,
          );
          if (API.isFailure(updateProperty)) return updateProperty;

          updateProperty = checkUpdatableProperty(
            training,
            'files',
            trainingInput.files,
            partialUpdate,
            adminOnly_overwriteNoDelete,
          );
          if (API.isFailure(updateProperty)) return updateProperty;

          
          if (Object.keys(partialUpdate).length > 1) {
            const updatedTraining = await API.updateTraining(partialUpdate);
            if (API.isFailure(updatedTraining)) return updatedTraining;
          }

          
          const trainingVersion = await API.getTrainingVersionLatestForTraining(training.id);
          if (API.isFailure(trainingVersion)) return trainingVersion;

          const partialUpdateTV: API.TrainingVersionPartialUpdateInput = {
            id: trainingVersion.id,
          };

          {
            
            if (
              trainingVersionInput.skillIds.length &&
              !isValueDelete(trainingVersionInput.skillIds)
            ) {
              const isPracticalTrainingVersion = await API.isPracticalTrainingVersion(
                trainingVersion.id,
              );
              if (API.isFailure(isPracticalTrainingVersion)) return isPracticalTrainingVersion;

              const practicalSkillName = await API.findPracticalSkill(
                trainingVersionInput.skillIds,
              );
              if (API.isFailure(practicalSkillName)) return practicalSkillName;
              if (isPracticalTrainingVersion !== (practicalSkillName !== undefined)) {
                
                return API.createFailure_Unspecified(
                  'Cannot add ' +
                    (isPracticalTrainingVersion ? 'not ' : '') +
                    'practical skills to a ' +
                    (isPracticalTrainingVersion ? '' : 'not ') +
                    'practical training.',
                );
              }
            }
          }
          updateProperty = checkUpdatableProperty(
            trainingVersion,
            'skillIds',
            trainingVersionInput.skillIds,
            partialUpdateTV,
            adminOnly_overwriteNoDelete,
          );
          if (API.isFailure(updateProperty)) return updateProperty;

          updateProperty = checkUpdatableProperty(
            trainingVersion,
            'durationInMin',
            trainingVersionInput.durationInMin,
            partialUpdateTV,
            adminOnly_overwriteNoDelete,
          );
          if (API.isFailure(updateProperty)) return updateProperty;

          
          if (Object.keys(partialUpdateTV).length > 1) {
            return API.updateFactoryBusinessObject(API.DataType.TRAININGVERSION, partialUpdateTV);
          } else {
            return API.getFactoryBusinessObject(API.DataType.TRAININGVERSION, trainingVersion.id);
          }
        });
      }
    },
    trainingInput.name,
  );
}



export async function processWorkerAnyData(
  workSheet: Worksheet,
  type:
    | ImportExportType.Worker
    | ImportExportType.Worker_FORCE
    | ImportExportType.WorkerSkill
    | ImportExportType.WorkerSkill_FORCE
    | ImportExportType.WorkerTraining
    | ImportExportType.WorkerTrainingSession
    | ImportExportType.WorkerWorkstationTarget,
): Promise<ImportReport<any>> {
  switch (type) {
    case ImportExportType.Worker:
      return processWorkerData(workSheet, false);
    case ImportExportType.Worker_FORCE:
      return processWorkerData(workSheet, true);
    case ImportExportType.WorkerSkill:
      return processWorkerSkillOrWorkerTrainingData(
        workSheet,
        getWorkerSkillHeaders(),
        parseWorkerSkillRow,
      );
    case ImportExportType.WorkerSkill_FORCE:
      return processWorkerSkillOrWorkerTrainingData(
        workSheet,
        getWorkerSkillHeaders(),
        (row: Row, validatorWorker: API.Worker) => parseWorkerSkillRow(row, validatorWorker, true),
      );
    case ImportExportType.WorkerTraining:
      return processWorkerSkillOrWorkerTrainingData(
        workSheet,
        getWorkerTrainingImportHeaders(),
        (row: Row, validatorWorker: API.Worker) =>
          parseWorkerTrainingOrWorkerTrainingSessionRow(
            row,
            validatorWorker,
            API.DataType.TRAINING,
          ),
      );
    case ImportExportType.WorkerTrainingSession:
      return processWorkerSkillOrWorkerTrainingData(
        workSheet,
        getWorkerTrainingSessionImportHeaders(),
        async (row: Row, validatorWorker: API.Worker) =>
          parseWorkerTrainingOrWorkerTrainingSessionRow(
            row,
            validatorWorker,
            API.DataType.TRAININGSESSION,
          ),
      );
    case ImportExportType.WorkerWorkstationTarget:
      return processWorkerSkillOrWorkerTrainingData(
        workSheet,
        getWorkerWorkstationTargetImportHeaders(),
        async (row: Row, validatorWorker: API.Worker) =>
          processWorkerWorkstationTargetData(row, validatorWorker),
      );
  }
}

async function processWorkerSkillOrWorkerTrainingData<T extends {}>(
  workSheet: Worksheet,
  headers: Header[],
  rowParser: (row: Row, validatorWorker: API.Worker) => Promise<API.Result<T>>,
): Promise<ImportReport<T>> {
  const importDate = new Date();

  const validatorWorker = await API.getWorker();
  if (API.isFailure(validatorWorker)) {
    return {
      failures: [
        {
          row: undefined,
          failureMessage: t(ImportExportFailureMessageKey.LoggedInUserNotFound),
        },
      ],
      successes: [],
    };
  }

  return processWorksheet(
    workSheet,
    headers,
    async row => {
      return rowParser(row, validatorWorker);
    },
    false,
  );
}

export async function processWorkerData(
  workSheet: Worksheet,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<ImportReport<API.Factory<API.DataType.WORKER>>> {
  return processWorksheet(
    workSheet,
    await getWorkerImportHeaders(),
    async row => {
      const workerImportData = await parseWorkerRow(row, adminOnly_overwriteNoDelete);
      if (API.isFailure(workerImportData)) return workerImportData;

      if (workerImportData.assignmentInputs.length) {
        const newAssignments: API.OrgUnitWithRoleAndPermissionInfo[] =
          workerImportData.assignmentInputs.map(assignment => {
            return {
              orgUnitId: assignment.organizationalUnit.id,
              roleAndPermissions: {
                roleId: assignment.role!.id,
                permissions: _.uniq(assignment.permissions),
              },
              shiftId: assignment.shift?.id,
            };
          });
        const currentWorker = await API.getWorkerByIdentifier(
          workerImportData.workerInput.name,
          workerImportData.workerInput.email,
          workerImportData.workerInput.phone,
          workerImportData.workerInput.matricule,
          !adminOnly_overwriteNoDelete, 
        );
        if (API.isFailure(currentWorker)) return currentWorker;

        
        if (currentWorker && !adminOnly_overwriteNoDelete) {
          const currentScope: API.Scope = JSON.parse(currentWorker.scope);

          if (!allowAddingListItemWithoutListingExistingItems) {
            
            if (
              Array.from(Object.keys(currentScope.nonInheritedRolesOnOrgUnits)).some(
                existingAssignment =>
                  !workerImportData.assignmentInputs.some(
                    indexedAssignment =>
                      indexedAssignment.organizationalUnit.id ===
                      API.extractOrgUnitIdFromScopeKey(existingAssignment),
                  ),
              )
            ) {
              return API.createFailure_Unspecified(
                'Passed assignements shall overlap the existing worker assignements. Please export the worker assignement and from this list, add more assignements and/or edit their role/permissions.',
              );
            }
          }

          for (const [unitAndShiftIds, roleAndPermission] of Object.entries(currentScope)) {
            const orgUnitId: string = API.extractOrgUnitIdFromScopeKey(unitAndShiftIds);
            const shiftId: string | undefined = API.extractShiftIdFromScopeKey(unitAndShiftIds);

            const currentAssignment = {
              orgUnitId: orgUnitId,
              shiftId: shiftId,
              roleAndPermissions: roleAndPermission,
            };

            const sameAssignment =
              newAssignments.findIndex(
                assignment => assignment.orgUnitId === orgUnitId && assignment.shiftId === shiftId,
              ) !== -1;

            if (sameAssignment)
              return API.createFailure_Unspecified('You cannot change the asignment'); 

            
            newAssignments.push(currentAssignment);
          }
        }

        workerImportData.workerInput.scope = API.constructNonInheritedAssignmentsAndCreateScope(
          newAssignments,
          currentWorker ? API.extractScopeFromWorker(currentWorker).inheritedRolesOnOrgUnits : {},
        );
      }

      const workerFactory = await createOrUpdateWorker(
        workerImportData,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(workerFactory)) return workerFactory;

      return workerFactory;
    },
    true, 
  );
}

async function parseWorkerRow(
  row: Row,
  adminOnly_overwriteNoDelete: boolean,
): Promise<API.Result<WorkerImportedData>> {
  let email = parseCell(row.getCell(WorkerColumns.email), ExpectedCellDataType.String);
  if (API.isFailure(email)) return email;
  
  if (!email?.length) email = undefined; 
  if (email) email = email.toLowerCase();
  if (email && !emailPattern.test(email))
    return API.createFailure(
      'InvalidArguments',
      `${t(ImportExportFailureMessageKey.InvalidEmail)}: ${email}`,
    );

  const _phone = parseCell(row.getCell(WorkerColumns.phone), ExpectedCellDataType.String);
  if (API.isFailure(_phone)) return _phone;
  
  let phone: string | undefined = undefined;
  if (_phone) {
    const phoneNumber = (_phone.includes('+') ? _phone : `+${_phone}`).replace(/-/g, '');

    if (!isValidPhoneNumber(phoneNumber)) {
      return API.createFailure(
        'InvalidArguments',
        `${t(ImportExportFailureMessageKey.InvalidPhoneNumber)}: ${_phone}`,
      );
    } else {
      phone = phoneNumber;
    }
  }

  const matricule = parseCell(row.getCell(WorkerColumns.personalId), ExpectedCellDataType.String);
  if (API.isFailure(matricule)) return matricule;
  
  if (matricule && !workerPersonalIdPattern.test(matricule))
    return API.createFailure(
      'InvalidArguments',
      `${t(ImportExportFailureMessageKey.InvalidMatricule)}: ${matricule}`,
    );

  
  if (!email && !matricule )
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, {
        name: 'email or marticule' + email + matricule,
      }),
    );

  const firstName = parseCell(row.getCell(WorkerColumns.firstName), ExpectedCellDataType.String);
  if (API.isFailure(firstName)) return firstName;
  

  const familyName = parseCell(row.getCell(WorkerColumns.familyName), ExpectedCellDataType.String);
  if (API.isFailure(familyName)) return familyName;
  

  let name: string | undefined;
  if (firstName && familyName) {
    name = firstName + ' ' + familyName;
  }

  const _tagNames = parseCell(row.getCell(WorkerColumns.workerTags), ExpectedCellDataType.Array);
  if (API.isFailure(_tagNames)) return _tagNames;
  const tagIds = isValueDelete(_tagNames)
    ? DELETE_keyword
    : await parseWorkerTags(_tagNames, adminOnly_overwriteNoDelete);
  if (API.isFailure(tagIds)) return tagIds;

  const description = parseCell(
    row.getCell(WorkerColumns.description),
    ExpectedCellDataType.String,
  );
  if (API.isFailure(description)) return description;

  const _startDates = parseCell(
    row.getCell(WorkerColumns.contractsStart),
    ExpectedCellDataType.ArrayOfDates,
  );
  if (API.isFailure(_startDates)) return _startDates;
  if (isValueDelete(_startDates))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'startDates',
      }),
    );

  const _endDates = parseCell(
    row.getCell(WorkerColumns.contractsEnd),
    ExpectedCellDataType.ArrayOfDates,
  );
  if (API.isFailure(_endDates)) return _endDates;
  if (isValueDelete(_endDates))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'endDates',
      }),
    );

  const _contractTypes = parseCell(
    row.getCell(WorkerColumns.contractsTypeName),
    ExpectedCellDataType.Array,
  );
  if (API.isFailure(_contractTypes)) return _contractTypes;

  const contracts = isValueDelete(_contractTypes)
    ? DELETE_keyword
    : await parseWorkerContract(_contractTypes, _startDates, _endDates);
  if (API.isFailure(contracts)) return contracts;

  const roles = await API.getRoles();
  if (API.isFailure(roles)) return roles;

  const firstOrgUnitColumn = Object.keys(WorkerColumns).length - 2;
  const permissions = Object.values(API.Permission);
  const assignments: API.IndexedAssignment[] = [];

  
  for (let index = firstOrgUnitColumn; index < row.cellCount; index = index + 3) {
    const orgUnitPathAndOptionalShiftNames = parseCell(
      row.getCell(index),
      ExpectedCellDataType.TwoDArray,
    );
    if (API.isFailure(orgUnitPathAndOptionalShiftNames)) return orgUnitPathAndOptionalShiftNames;

    const roleNames = parseCell(row.getCell(index + 1), ExpectedCellDataType.Array);
    if (API.isFailure(roleNames)) return roleNames;

    const permissionNames = parseCell(row.getCell(index + 2), ExpectedCellDataType.TwoDArray);
    if (API.isFailure(permissionNames)) return permissionNames;

    if (
      isValueDelete(orgUnitPathAndOptionalShiftNames) ||
      isValueDelete(roleNames) ||
      isValueDelete(permissionNames)
    )
      return API.createFailure_Unspecified(
        t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
          keyword: DELETE_keyword,
          name: 'assignement',
        }),
      );

    if (!orgUnitPathAndOptionalShiftNames.length) {
      if (index === firstOrgUnitColumn && !adminOnly_overwriteNoDelete) {
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, {
            name: 'assignement organizational unit',
          }),
        );
      } else {
        continue; 
      }
    }

    if (orgUnitPathAndOptionalShiftNames.length !== roleNames.length) {
      return API.createFailure_Unspecified(
        `${t(ImportExportFailureMessageKey.InconsistentData)}, ${t(
          ImportExportFailureMessageKey.WorkerRolesAndOrgUnitsInconsistent,
        )}`,
      );
    }

    const failures = await API.mapSeries(
      orgUnitPathAndOptionalShiftNames,
      async (orgUnitPathAndOptionalShiftName, index) => {
        if (!orgUnitPathAndOptionalShiftName.length) return;

        
        const unitNameWithShift =
          orgUnitPathAndOptionalShiftName[orgUnitPathAndOptionalShiftName.length - 1];
        orgUnitPathAndOptionalShiftName.pop();

        let shiftName: string | undefined = unitNameWithShift.split(ShiftSeparator)[1];
        if (shiftName) shiftName = shiftName.trim();

        const unitName = unitNameWithShift.split(ShiftSeparator)[0].trim();
        orgUnitPathAndOptionalShiftName.push(unitName); 
        

        const orgUnit = await parseOrgUnitPathName(orgUnitPathAndOptionalShiftName, false);
        if (API.isFailure(orgUnit)) return orgUnit;

        const role = roles.find(_role => searchMatch(_role.name, roleNames[index], true));
        if (!role)
          return API.createFailure_Unspecified('Could not find role with name : ' + roleNames);

        const workerPermissions: API.Permission[] = [];
        if (permissionNames.length) {
          permissionNames[index].forEach(permissionName => {
            const workerPermission = permissions.find(permission =>
              searchMatch(permission, permissionName, true),
            );
            if (!workerPermission)
              return API.createFailure_Unspecified(
                'Could not find permission with name : ' + permissionName,
              );

            workerPermissions.push(workerPermission);
          });
        } else {
          workerPermissions.push(...role.permissions);
        }

        let assignement = assignments.find(
          assignement => assignement.organizationalUnit.id === orgUnit.id,
        );
        if (shiftName) {
          
          const shift = await parseShift(shiftName, orgUnit.id);
          if (API.isFailure(shift)) return shift;

          if (assignement) {
            if (assignement.shift?.id === shift.id) {
              
              return API.createFailure_Unspecified(
                'Could not add twice the same Shift assignement in the same row or cell. Please check : ' +
                  orgUnit.name +
                  ' ' +
                  ShiftSeparator +
                  ' ' +
                  shift.name,
              );
            }

            assignement = {
              ...assignement,
              role: role,
              permissions: [...assignement.permissions, ...workerPermissions],
              shift: shift,
            };
          } else {
            assignments.push({
              workerId: '',
              organizationalUnit: orgUnit,
              role: role,
              permissions: [...workerPermissions],
              shift: shift,
            });
          }
        } else {
          assignments.push({
            workerId: '',
            role: role,
            permissions: [...workerPermissions],
            shift: undefined,
            organizationalUnit: orgUnit,
          });
        }
      },
    );
    if (API.isFailure(failures)) return failures;
  }

  const workerInput: DeletablePropObject<API.WorkerCreateInput> = {
    name: name ?? '',
    firstName: firstName ?? '',
    familyName: familyName ?? '',
    email,
    phone,
    matricule,
    tagIds,
    contracts,
    description,
  };

  return {
    workerInput,
    assignmentInputs: assignments,
  };
}

/**
 * Look for the given tagNames, if don't exist create them.
 * @param tagNames
 * @returns the existing/created tags'id
 */
async function parseWorkerTags(
  tagNames: string[],
  adminOnly_overwriteNoDelete: boolean,
): Promise<API.Result<string[]>> {
  return getWorkerTagLock.runSerial(async () => {
    const _workerTags = await API.getWorkerTags();
    if (API.isFailure(_workerTags)) return _workerTags;

    const errors: API.Failure[] = [];
    const workerTags = _.compact(
      await Aigle.map(tagNames, async tagName => {
        const _tag = _workerTags.find(tag => searchMatch(tag.name, tagName, true));
        if (_tag) {
          return _tag;
        } else {
          if (tagName !== '') {
            const createWorkerTag = await API.createWorkerTag({ name: tagName });
            if (API.isFailure(createWorkerTag)) {
              errors.push(createWorkerTag);
              return;
            }
            return createWorkerTag;
          }
        }
      }),
    );
    if (errors.length) return API.createFailure_Multiple(errors);

    return workerTags.map(workerTag => workerTag.id);
  });
}

/**
 * Try to find a Shift by name.
 * Returns a Failure if there are several Shifts with the same name.
 * @param shiftName name to parse
 * @param orgUnitId (Optional) the orgUnit parent of the Shift
 */
async function parseShift(shiftName: string, orgUnitId?: string): Promise<API.Result<API.Shift>> {
  let shifts = await API.getShifts();
  if (API.isFailure(shifts)) return shifts;

  if (orgUnitId) {
    shifts = shifts.filter(shift => shift.parentId === orgUnitId);
  }

  let result: API.Shift | undefined = undefined;
  for (const shift of shifts) {
    if (searchMatch(shift.name, shiftName, true)) {
      if (result) {
        return API.createFailure_Unspecified(
          'Several Shifts have the name "' + shiftName + '". Consider passing the id instead.',
        );
      }
      result = shift;
    }
  }
  if (result) return result;

  return API.createFailure('ObjectNotFound', 'Shift with name "' + shiftName + '" does not exist.');
}

export async function parseWorkerContract(
  contractNames: string[],
  startDates: (Date | undefined)[],
  endDates: (Date | undefined)[],
): Promise<API.Result<API.ContractInput[]>> {
  const _workerContracts: API.ContractInput[] = [];

  const contractTypes = await API.getContractTypes();
  if (API.isFailure(contractTypes)) return contractTypes;

  const errors: API.Failure[] = [];
  contractNames.map((contractName, index) => {
    const contractType = contractTypes.find(contractType =>
      searchMatch(contractType.name, contractName, true),
    );

    if (!contractType) {
      errors.push(
        API.createFailure(
          'InvalidArguments',
          `${t(ImportExportFailureMessageKey.invalidContractType)}: ${contractName}`,
        ),
      );
      return;
    }
    const _startDate = startDates[index];
    const _endDate = endDates[index];
    if (API.isFailure(_startDate)) {
      errors.push(_startDate);
      return;
    } else if (API.isFailure(_endDate)) {
      errors.push(_endDate);
      return;
    }

    _workerContracts.push({
      contractTypeId: contractType.id,
      startDate: _startDate ? _startDate.toISOString() : undefined,
      endDate: _endDate ? _endDate.toISOString() : undefined,
    });
  });
  if (errors.length) return API.createFailure_Multiple(errors);

  return _workerContracts;
}

/**
 * Update (if doens't exist, create a Worker)
 * @param workerImportData
 * @param overwrite Default = false. WARNING if true all existing worker data will be overwritten with the passed workerImportData
 * @param allowMarticuleModification Default = false
 * @returns
 */
async function createOrUpdateWorker(
  workerImportData: WorkerImportedData,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<API.Result<API.Factory<API.DataType.WORKER>>> {
  const { workerInput: input, assignmentInputs } = workerImportData;
  let lockId = input?.email ?? '' + input?.phone ?? '' + input?.matricule ?? '';
  if (!lockId.length) lockId = input.firstName + input.familyName;

  return API.getOrCreateFactory(
    API.DataType.WORKER,
    async () => {
      const currentWorker = await API.getWorkerByIdentifier(
        input.name,
        input.email,
        input.phone,
        input.matricule,
        !adminOnly_overwriteNoDelete, 
      );
      if (API.isFailure(currentWorker)) return currentWorker;

      if (!currentWorker) {
        if (!assignmentInputs.length)
          return API.createFailure_Unspecified('At least 1 assignement shall be specified');

        if (!isObjectFreeOfDeletableProp(input)) {
          return API.createFailure_Unspecified(
            'Cannot add ' +
              DELETE_keyword +
              ' flag when creating new Worker. Please check your input.',
          );
        }

        return API.createFactoryBusinessObject(API.DataType.WORKER, input);
      }

      let partialUpdate: API.WorkerPartialUpdateInput = { id: currentWorker.id };

      let updateProperty = checkUpdatableProperty(
        currentWorker,
        'email',
        input.email,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'phone',
        input.phone,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'matricule',
        input.matricule,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'firstName',
        input.firstName,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'familyName',
        input.familyName,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'name',
        input.name,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'description',
        input.description,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'tagIds',
        input.tagIds,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'contracts',
        input.contracts,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      updateProperty = checkUpdatableProperty(
        currentWorker,
        'scope',
        input.scope,
        partialUpdate,
        adminOnly_overwriteNoDelete,
      );
      if (API.isFailure(updateProperty)) return updateProperty;

      
      if (Object.keys(partialUpdate).length > 1) {
        return API.updateFactoryBusinessObject(API.DataType.WORKER, partialUpdate);
      } else {
        return API.getFactoryBusinessObject(API.DataType.WORKER, currentWorker.id);
      }
    },
    lockId,
  );
}



async function parseWorkerSkillRow(
  row: Row,
  validatorWorker: API.Worker,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<
  API.Result<{
    worker: API.Worker;
  }>
> {
  const workerIdentifier = parseCell(
    row.getCell(WorkerSkillImportColumns.workerIdentifier),
    ExpectedCellDataType.String,
  );
  if (API.isFailure(workerIdentifier)) return workerIdentifier;
  if (!workerIdentifier)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'workerIdentifier' }),
    );

  const worker = await getWorkerByNameOrEmailOrPhoneOrMatricule(workerIdentifier);
  if (API.isFailure(worker)) return worker;
  if (!worker)
    return API.createFailure(
      'ObjectNotFound',
      `Worker with identifier "${workerIdentifier}" not found`,
    );

  const skillNames = parseCell(
    row.getCell(WorkerSkillImportColumns.skillNames),
    ExpectedCellDataType.Array,
  );
  if (API.isFailure(skillNames)) return skillNames;
  if (!skillNames.length)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'skillNames' }),
    );
  if (isValueDelete(skillNames))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'skillNames',
      }),
    );

  const skills: API.Skill[] = [];
  for (const skillName of skillNames) {
    const skill = await API.getSkillByName(skillName);
    if (API.isFailure(skill)) return skill;

    if (!skill) return API.createFailure('ObjectNotFound', `Skill "${skillName}" not found`);
    skills.push(skill);
  }

  const issueDate = parseCell(
    row.getCell(WorkerSkillImportColumns.issueDate),
    ExpectedCellDataType.Date,
  );
  if (API.isFailure(issueDate)) return issueDate;
  if (!issueDate)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'issueDate' }),
    );
  if (isValueDelete(issueDate)) {
    if (!adminOnly_overwriteNoDelete)
      return API.createFailure_Unspecified(
        t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
          keyword: DELETE_keyword,
          name: 'issueDate',
        }),
      );

    const deletedWorkerSkills: API.Skill[] = [];
    const errors = (
      await Aigle.mapLimit(skills, async skill => {
        const workerSkillId = API.getWorkerSkillId(worker.id, skill.id);
        if (API.isFailure(workerSkillId)) return workerSkillId;

        
        const deleteWorkerSkill = await API.deleteFactoryBusinessObject<API.DataType.WORKERSKILL>(
          workerSkillId,
        );
        if (API.isFailure(deleteWorkerSkill)) {
          if (API.isFailureType(deleteWorkerSkill, 'ObjectNotFound')) {
            
          } else {
            return deleteWorkerSkill;
          }
        } else {
          deletedWorkerSkills.push(skill);
        }
      })
    ).filter(f => f !== undefined);
    if (errors.length) return API.createFailure_Multiple(errors);

    return {
      worker,
    };
  }

  const comment = parseCell(
    row.getCell(WorkerSkillImportColumns.proofDescription),
    ExpectedCellDataType.String,
  );
  if (API.isFailure(comment)) return comment;

  const proofBundles = await createProofBundles(
    worker,
    skills,
    issueDate,
    validatorWorker,
    issueDate, 
    validatorWorker,
    comment,
  );
  if (API.isFailure(proofBundles)) return proofBundles;

  return {
    worker,
  };
}

async function parseWorkerTrainingOrWorkerTrainingSessionRow(
  row: Row,
  validatorWorker: API.Worker,
  type: API.DataType.TRAINING | API.DataType.TRAININGSESSION,
  adminOnly_overwriteNoDelete: boolean = false,
): Promise<API.Result<API.Worker[]>> {
  const isTrainingSessionImport = type === API.DataType.TRAININGSESSION;

  const workerIdentifier = parseCell(
    row.getCell(
      isTrainingSessionImport
        ? workerTrainingSessionImportColumns.workerIdentifier
        : WorkerTrainingImportColumns.workerIdentifier,
    ),
    ExpectedCellDataType.String,
  );
  if (API.isFailure(workerIdentifier)) return workerIdentifier;
  if (!workerIdentifier)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'workerIdentifier' }),
    );

  const worker = await getWorkerByNameOrEmailOrPhoneOrMatricule(workerIdentifier);
  if (API.isFailure(worker)) return worker;
  if (!worker)
    return API.createFailure(
      'ObjectNotFound',
      `Worker with identifier "${workerIdentifier}" not found`,
    );

  const trainingNames = parseCell(
    row.getCell(
      isTrainingSessionImport
        ? workerTrainingSessionImportColumns.trainingName
        : WorkerTrainingImportColumns.trainingNames,
    ),
    ExpectedCellDataType.Array,
  );
  if (API.isFailure(trainingNames)) return trainingNames;
  if (!trainingNames.length)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'trainingNames' }),
    );
  if (isValueDelete(trainingNames))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'trainingNames',
      }),
    );

  const trainings: API.Training[] = [];
  for (const trainingName of trainingNames) {
    const training = await API.getTrainingByName(trainingName);
    if (API.isFailure(training)) return training;

    if (!training)
      return API.createFailure('ObjectNotFound', `Training "${trainingName}" not found`);
    trainings.push(training);
  }

  const issueDate = parseCell(
    row.getCell(
      isTrainingSessionImport
        ? workerTrainingSessionImportColumns.issueDate
        : WorkerTrainingImportColumns.issueDate,
    ),
    ExpectedCellDataType.Date,
  );
  if (API.isFailure(issueDate)) return issueDate;
  if (!issueDate)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'issueDate' }),
    );
  if (isValueDelete(issueDate))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'issueDate',
      }),
    );

  const trainingProofDescription =
    parseCell(
      row.getCell(
        isTrainingSessionImport
          ? workerTrainingSessionImportColumns.proofDescription
          : WorkerTrainingImportColumns.proofDescription,
      ),
      ExpectedCellDataType.String,
    ) ??
    t('alex:importExport.defaultComment', {
      date: issueDate.toISOString(),
    });
  if (API.isFailure(trainingProofDescription)) return trainingProofDescription;

  const result: API.Worker[] = [];
  for (const training of trainings) {
    const trainingVersion = await API.getTrainingVersionLatestForTraining(training.id);
    if (API.isFailure(trainingVersion)) return trainingVersion;

    let trainingSession: API.TrainingSession | undefined;
    if (isTrainingSessionImport) {
      
      const trainingSessionStartDate = parseCell(
        row.getCell(workerTrainingSessionImportColumns.startDate),
        ExpectedCellDataType.Date,
      );
      if (API.isFailure(trainingSessionStartDate)) return trainingSessionStartDate;
      if (!trainingSessionStartDate)
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'trainingStartDate' }),
        );
      if (isValueDelete(trainingSessionStartDate))
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
            keyword: DELETE_keyword,
            name: 'trainingSessionStartDate',
          }),
        );

      const trainingSessionDuration = parseCell(
        row.getCell(workerTrainingSessionImportColumns.durationInMin),
        ExpectedCellDataType.Number,
      );
      if (API.isFailure(trainingSessionDuration)) return trainingSessionDuration;
      
      const durationInMin = isValueDelete(trainingSessionDuration)
        ? null
        : trainingSessionDuration
        ? Math.round(trainingSessionDuration * 60)
        : null;

      let trainerIdentifier = parseCell(
        row.getCell(workerTrainingSessionImportColumns.trainerIdentifier),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(trainerIdentifier)) return trainerIdentifier;
      if (isValueDelete(trainingSessionStartDate))
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
            keyword: DELETE_keyword,
            name: 'trainingSessionStartDate',
          }),
        );

      let trainer: API.Result<API.Worker | undefined>;
      if (!trainerIdentifier) {
        trainer = await API.getWorker(API.externalTrainerWorkerId);
      } else {
        trainer = await getWorkerByNameOrEmailOrPhoneOrMatricule(trainerIdentifier);
      }
      if (API.isFailure(trainer)) return trainer;
      if (!trainer)
        return API.createFailure(
          'ObjectNotFound',
          `Trainer with identifier "${trainerIdentifier}" not found`,
        );

      const _trainingSession = await API.createTrainingSession({
        trainingVersionId: trainingVersion.id,
        scheduledTrainers: [{ trainerId: trainer.id, percentage: '100%' }],
        scheduledTraineeIds: [worker.id],
        scheduledStartDate: trainingSessionStartDate.toISOString(),
        durationInMin,
        requestState: API.ReviewState.VALIDATED,
        trainers: [{ trainerId: trainer.id, percentage: '100%' }],
        traineeIds: [worker.id],
        startDate: trainingSessionStartDate.toISOString(),
        endDate: issueDate.toISOString(),
        description: trainingProofDescription,
      });
      if (API.isFailure(_trainingSession)) return _trainingSession;
      trainingSession = _trainingSession;
    }

    const proofBundles = await createProofBundles(
      worker,
      trainingSession ?? trainingVersion,
      issueDate,
      validatorWorker,
      issueDate, 
      validatorWorker,
      trainingProofDescription,
    );
    if (API.isFailure(proofBundles)) return proofBundles;

    result.push(worker);
  }

  return result;
}

/**
 * Create ProofBundle for the given Worker and Skills or Training.
 * It takes care to not create duplicated ProofBundles.
 * Returns only the Skills where a ProofBundle was added (might be lower than the given skillNames if some duplicates were found)
 * @param worker
 * @param skillsOrTraining Skills array or a TrainingVersion or a TrainingSession
 * @param issueDate
 * @param proofMaker the Worker that generates the proof
 * @param importDate the date of proof generation (and review if reviewer is set)
 * @param reviewer (optional) the Worker that validated the proof. If not specify the proof is in TO_REVIEW state
 * @param comment (optional) note added to the generated certificate
 */
async function createProofBundles(
  worker: API.Worker,
  skillsOrTraining: API.Skill[] | API.TrainingVersion | API.TrainingSession,
  issueDate: Date,
  proofMaker: API.Worker,
  importDate: Date,
  reviewer?: API.Worker,
  comment?: string,
): Promise<API.Result<API.ProofBundle[]>> {
  let review: API.ReviewInput;
  if (reviewer) {
    review = {
      state: API.ReviewState.VALIDATED,
      date: importDate.toISOString(),
      workerId: reviewer.id,
    };
  } else {
    review = {
      state: API.ReviewState.TO_REVIEW,
    };
  }

  /**
   * Create a partial PoofBundleInput without the files and a function to will create the ProofBundle files
   * @param trainingVersionOrSkills
   */
  async function createProofFileAndProofBundleInput(
    trainingVersionOrSkills: API.TrainingVersion | API.Skill[],
  ): Promise<
    API.Result<
      Omit<API.ProofBundleCreateInput, 'originObjectId' | 'workerId' | 'skillId' | 'files'> & {
        files: API.S3ObjectInput[] | (() => Promise<API.Result<API.S3Object[]>>);
      }
    >
  > {
    let isTraining: boolean;
    let skillOrTrainingNames: string[];
    let trainingSkills: string[] | undefined;
    if (Array.isArray(trainingVersionOrSkills)) {
      isTraining = false;
      skillOrTrainingNames = trainingVersionOrSkills.map(skill => skill.name);
    } else {
      isTraining = false;

      const training = await API.getTraining(trainingVersionOrSkills.trainingId);
      if (API.isFailure(training)) return training;

      skillOrTrainingNames = [training.name];
      trainingSkills = [];
      for (const skillId of trainingVersionOrSkills.skillIds) {
        const skill = await API.getSkill(skillId);
        if (API.isFailure(skill)) return skill;

        trainingSkills.push(skill.name);
      }
    }

    const lock = new AsyncLock();
    let proofBundleFiles: API.Result<API.S3Object[]> | undefined = undefined;
    function createProofBundleFiles(): Promise<API.Result<API.S3Object[]>> {
      return lock.runSerial(async () => {
        if (proofBundleFiles) return proofBundleFiles;

        const proof = await API.createCertificateProof(
          [worker.name],
          skillOrTrainingNames,
          proofMaker.name,
          isTraining,
          false,
          comment ?? importDate,
          undefined,
          trainingSkills,
        );

        const proofFile = isPlatformServerless
          ? proof
          : convertDataURLtoFile(proof.fileContentBase64, proof.name);

        const s3File = await API.uploadFile(
          proofFile,
          API.StorageVisibility.protected,
          proof.name,
          undefined,
          pdfContentType,
        );
        if (API.isFailure(s3File)) return s3File;

        proofBundleFiles = [s3File];

        return proofBundleFiles;
      });
    }

    return {
      files: createProofBundleFiles,
      acquired: true,
      startingDate: issueDate.toISOString(),
      review,
      description:
        comment ??
        t('alex:importExport.defaultComment', {
          date: importDate.toISOString(),
        }),
    };
  }

  
  if (!Array.isArray(skillsOrTraining)) {
    let trainingVersion: API.TrainingVersion;
    if (API.isTrainingSession(skillsOrTraining)) {
      const _trainingVersion = await API.getTrainingVersion(skillsOrTraining.trainingVersionId);
      if (API.isFailure(_trainingVersion)) return _trainingVersion;
      trainingVersion = _trainingVersion;
    } else {
      trainingVersion = skillsOrTraining;
    }

    const proofBundleInput = await createProofFileAndProofBundleInput(trainingVersion);
    if (API.isFailure(proofBundleInput)) return proofBundleInput;

    return API.createTrainingProofBundle(proofBundleInput, skillsOrTraining.id, worker.id);
  } else {
    const proofBundleInput = await createProofFileAndProofBundleInput(skillsOrTraining);
    if (API.isFailure(proofBundleInput)) return proofBundleInput;

    return API.createWorkerSkillProofBundle(
      proofBundleInput,
      worker.id,
      skillsOrTraining.map(skill => skill.id),
    );
  }
}



export async function processWorkerWorkstationTargetData(
  row: Row,
  validatorWorker: API.Worker,
): Promise<API.Result<API.WorkerWorkstation | API.TrainingSession[]>> {
  const workerWorkstationTarget = await _parseWorkerWorkstationTargetRow(row, true);
  if (API.isFailure(workerWorkstationTarget)) return workerWorkstationTarget;

  const _isTrainAuto = parseCell(
    row.getCell(WorkerWorkstationTargetColumns.autoTrain),
    ExpectedCellDataType.Boolean,
  );
  if (API.isFailure(_isTrainAuto)) return _isTrainAuto;
  if (isValueDelete(_isTrainAuto))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'autoTrain',
      }),
    );
  const isTrainAuto = _isTrainAuto === true ? true : false;

  const issueDate = parseCell(
    row.getCell(WorkerWorkstationTargetColumns.issueDate),
    ExpectedCellDataType.Date,
  );
  if (API.isFailure(issueDate)) return issueDate;
  if (isValueDelete(issueDate))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'issueDate',
      }),
    );

  const comment = parseCell(
    row.getCell(WorkerWorkstationTargetColumns.comment),
    ExpectedCellDataType.String,
  );
  if (API.isFailure(comment)) return comment;

  const ignoreSkillNames = parseCell(
    row.getCell(WorkerWorkstationTargetColumns.ignoreSkillNames),
    ExpectedCellDataType.Array,
  );
  if (API.isFailure(ignoreSkillNames)) return ignoreSkillNames;
  if (isValueDelete(ignoreSkillNames))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'ignoreSkillNames',
      }),
    );
  const ignoreSkillIds: string[] = [];
  for (const skillName of ignoreSkillNames) {
    const skill = await API.getSkillByName(skillName);
    if (API.isFailure(skill)) return skill;

    if (skill) ignoreSkillIds.push(skill.id);
  }

  
  if (issueDate && workerWorkstationTarget.targetLevel !== null) {
    const levelsRequirements = await API.getLevelsRequirementsWithInheritedAndOrDescendent(
      workerWorkstationTarget.workstation.id,
      true,
      false,
    );
    if (API.isFailure(levelsRequirements)) return levelsRequirements;

    const skills: API.Skill[] = [];
    for (
      let level = API.WorkstationWorkerLevels.LEVEL1;
      level <= workerWorkstationTarget.targetLevel;
      level++
    ) {
      const skillTrainingVersions = (
        levelsRequirements.get(level)?.map(requirement => requirement.skillTrainingVersions) ?? []
      ).flat();

      for (const skillTrainingVersion of skillTrainingVersions) {
        if (ignoreSkillIds.includes(skillTrainingVersion.skillId)) continue;

        const skill = await API.getSkill(skillTrainingVersion.skillId);
        if (API.isFailure(skill)) return skill;

        skills.push(skill);
      }
    }

    const proofBundles = await createProofBundles(
      workerWorkstationTarget.worker,
      skills,
      issueDate,
      validatorWorker,
      issueDate,
      validatorWorker,
      comment,
    );
    if (API.isFailure(proofBundles)) return proofBundles;
  }

  if (!workerWorkstationTarget.existingWorkerWorkstation) {
    return API.createFailure_Unspecified(
      `Skills have been delievered to the Worker to reach level on the Workstation. But Worker is not assigned on the workstation and thus a target cannot be set. Make sure to add the worker permission ${API.Permission.workerIsOperational} on a unit containing the workstation, and try again.`,
    );
  }

  
  if (
    API.isSameWorkstationWorkerLevel(
      workerWorkstationTarget.existingWorkerWorkstation.targetLevel,
      workerWorkstationTarget.targetLevel,
    ) &&
    workerWorkstationTarget.existingWorkerWorkstation.isTrainAuto === isTrainAuto
  )
    return workerWorkstationTarget.existingWorkerWorkstation;

  if (workerWorkstationTarget.targetLevel === null) {
    return API.removeTargetWorkerAndStopTrainings(
      workerWorkstationTarget.existingWorkerWorkstation,
      true,
    );
  } else {
    return API.setTarget(
      workerWorkstationTarget.workstation.id,
      workerWorkstationTarget.worker.id,
      workerWorkstationTarget.targetLevel,
      isTrainAuto,
    );
  }
}

export async function processWorkerWorkstationTrainingRequestData(
  workSheet: Worksheet,
): Promise<ImportReport<API.TrainingSession[]>> {
  return processWorksheet(
    workSheet,
    getWorkerWorkstationTrainingRequestImportHeaders(),
    async row => {
      const workerWorkstationTarget = await _parseWorkerWorkstationTargetRow(row, false);
      if (API.isFailure(workerWorkstationTarget)) return workerWorkstationTarget;

      const endDateLimit = parseCell(
        row.getCell(WorkerWorkstationTrainingSessionsRequestColumns.endDateLimit),
        ExpectedCellDataType.Date,
      );
      if (API.isFailure(endDateLimit)) return endDateLimit;
      if (isValueDelete(endDateLimit))
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
            keyword: DELETE_keyword,
            name: 'endDateLimit',
          }),
        );

      const description = parseCell(
        row.getCell(WorkerWorkstationTrainingSessionsRequestColumns.trainingSessionNote),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(description)) return description;

      if (workerWorkstationTarget.targetLevel === null) {
        return API.createFailure_Unspecified(
          'Cannot remove WorkerWorkstation target while setting TrainingSessions',
        );
      }

      const requestedTrainingSessions = await API.setTargetAndTrainWorker(
        workerWorkstationTarget.workstation.id,
        workerWorkstationTarget.worker.id,
        workerWorkstationTarget.targetLevel,
        false,
        undefined,
        description,
        endDateLimit ? endDateLimit.toISOString() : undefined,
      );

      return requestedTrainingSessions;
    },
    false,
  );
}


{
  if (
    
    WorkerWorkstationTargetColumns.workerIdentifier !==
    WorkerWorkstationTrainingSessionsRequestColumns.workerIdentifier
  )
    throw new Error(
      'workerIdentifierColumn enums value shall match because there are consumed by _parseWorkerWorkstationTargetRow()',
    );
  if (
    
    WorkerWorkstationTargetColumns.workstationName !==
    WorkerWorkstationTrainingSessionsRequestColumns.workstationName
  )
    throw new Error(
      'workstationNameColumn enums value shall match because there are consumed by _parseWorkerWorkstationTargetRow()',
    );
  if (
    
    WorkerWorkstationTargetColumns.targetLevel !==
    WorkerWorkstationTrainingSessionsRequestColumns.targetLevel
  )
    throw new Error(
      'targetLevelColumn enums value shall match because there are consumed by _parseWorkerWorkstationTargetRow()',
    );
}
async function _parseWorkerWorkstationTargetRow(
  row: Row,
  adminOnly_overwriteNoDelete: boolean,
): Promise<
  API.Result<{
    worker: API.Worker;
    workstation: API.Workstation;
    targetLevel: API.WorkstationWorkerLevels | null;
    existingWorkerWorkstation: API.WorkerWorkstation | undefined;
  }>
> {
  const workerIdentifier = parseCell(
    row.getCell(WorkerWorkstationTrainingSessionsRequestColumns.workerIdentifier),
    ExpectedCellDataType.String,
  );
  if (API.isFailure(workerIdentifier)) return workerIdentifier;
  if (!workerIdentifier)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'workerIdentifier' }),
    );
  if (isValueDelete(workerIdentifier))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'workerIdentifier',
      }),
    );

  const worker = await getWorkerByNameOrEmailOrPhoneOrMatricule(workerIdentifier);
  if (API.isFailure(worker)) return worker;
  if (!worker)
    return API.createFailure(
      'ObjectNotFound',
      `Worker with identifier "${workerIdentifier}" not found`,
    );

  const workstationNameWithPath = parseCell(
    row.getCell(WorkerWorkstationTrainingSessionsRequestColumns.workstationName),
    ExpectedCellDataType.Array,
  );
  if (API.isFailure(workstationNameWithPath)) return workstationNameWithPath;
  if (!workstationNameWithPath.length)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'workstationName' }),
    );
  if (isValueDelete(workstationNameWithPath))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'workstationName',
      }),
    );

  const workstationName: string = workstationNameWithPath.pop()!; 

  let parentId: string | undefined;
  if (workstationNameWithPath.length) {
    const orgUnit = await parseOrgUnitPathName(workstationNameWithPath, false);
    if (API.isFailure(orgUnit)) return orgUnit;
    parentId = orgUnit.id;
  }
  const workstation = getWorkstationByName(workstationName, parentId);
  if (API.isFailure(workstation)) return workstation;

  const targetLevel = parseCell(
    row.getCell(WorkerWorkstationTrainingSessionsRequestColumns.targetLevel),
    ExpectedCellDataType.Number,
  );
  if (API.isFailure(targetLevel)) return targetLevel;
  if (targetLevel === undefined)
    return API.createFailure_Unspecified('Target level should be specified');
  let _targetLevel: number | null;
  if (isValueDelete(targetLevel)) {
    _targetLevel = null;
  } else {
    if (targetLevel < 0 || targetLevel > 4)
      return API.createFailure_Unspecified('Target level should be between 0 and 4');

    _targetLevel = targetLevel;
  }

  const existingWorkerWorkstation = getWorkerWorkstations(workstation.id, worker.id);
  if (!existingWorkerWorkstation) {
    const isWorkerAssignedOnWorkstation = await API.isWorkerAssignedToOrganizationalUnit(
      worker.id,
      workstation.parentId,
    );
    if (API.isFailure(isWorkerAssignedOnWorkstation)) return isWorkerAssignedOnWorkstation;
    if (isWorkerAssignedOnWorkstation) {
      return API.createFailure(
        'ObjectNotFound',
        `WorkerWorkstation not found while it should exist for worker(${worker.name},${worker.id}) workstation(${workstation.name},${workstation.id})`,
      );
    } else {
      if (adminOnly_overwriteNoDelete) {
        return {
          worker,
          workstation,
          targetLevel: _targetLevel,
          existingWorkerWorkstation: undefined,
        };
      } else {
        return API.createFailure_Unspecified(
          `Worker is not assigned on the workstation and thus a target cannot be set. Make sure to add the worker permission ${API.Permission.workerIsOperational} on a unit containing the workstation, and try again.`,
        );
      }
    }
  }

  if (
    !adminOnly_overwriteNoDelete &&
    existingWorkerWorkstation.targetLevel != null &&
    (_targetLevel === null ||
      API.workstationWorkerLevels2api(_targetLevel) !== existingWorkerWorkstation.targetLevel)
  ) {
    return API.createFailure_Unspecified(
      `Target for worker (${worker.name}) on workstation (${workstation.name}) is already set to ${existingWorkerWorkstation.targetLevel}`,
    );
  }

  return {
    worker,
    workstation,
    targetLevel: _targetLevel,
    existingWorkerWorkstation,
  };
}



export async function processTrainingSessionData(
  workSheet: Worksheet,
): Promise<ImportReport<API.TrainingSession>> {
  return processWorksheet(
    workSheet,
    getTrainingSessionHeaders(),
    async row => parseTrainingSessionRow(row),
    false,
  );
}

async function parseTrainingSessionRow(row: Row): Promise<API.Result<API.TrainingSession>> {
  const trainingName = parseCell(
    row.getCell(TrainingSessionColumns.trainingName),
    ExpectedCellDataType.String,
  );
  if (API.isFailure(trainingName)) return trainingName;
  if (!trainingName)
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'trainingName' }),
    );
  if (isValueDelete(trainingName))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'trainingNames',
      }),
    );

  const training = await API.getTrainingByName(trainingName);
  if (API.isFailure(training)) return training;
  if (!training) return API.createFailure('ObjectNotFound', `Training "${trainingName}" not found`);

  const trainingVersion = await API.getTrainingVersionLatestForTraining(training.id);
  if (API.isFailure(trainingVersion)) return trainingVersion;

  const scheduleStartDate = parseCell(
    row.getCell(TrainingSessionColumns.scheduledStartDate),
    ExpectedCellDataType.Date,
  );
  if (API.isFailure(scheduleStartDate)) return scheduleStartDate;
  if (isValueDelete(scheduleStartDate))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'scheduleStartDate',
      }),
    );

  const durationInHour = parseCell(
    row.getCell(TrainingSessionColumns.durationInHours),
    ExpectedCellDataType.Number,
  );
  if (API.isFailure(durationInHour)) return durationInHour;
  if (isValueDelete(durationInHour))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'durationInHour',
      }),
    );
  if (durationInHour !== undefined && durationInHour < 0)
    return API.createFailure_Unspecified('Duration shall be grater than 0');

  const endDateLimit = parseCell(
    row.getCell(TrainingSessionColumns.endDateLimitColumn),
    ExpectedCellDataType.Date,
  );
  if (API.isFailure(endDateLimit)) return endDateLimit;
  if (isValueDelete(endDateLimit))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'endDateLimit',
      }),
    );

  const note = parseCell(row.getCell(TrainingSessionColumns.notes), ExpectedCellDataType.String);
  if (API.isFailure(note)) return note;
  if (isValueDelete(note))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'location',
      }),
    );

  const scheduledTrainersIdentifier = parseCell(
    row.getCell(TrainingSessionColumns.scheduledTrainersIdentifier),
    ExpectedCellDataType.String,
  );
  if (API.isFailure(scheduledTrainersIdentifier)) return scheduledTrainersIdentifier;
  if (isValueDelete(scheduledTrainersIdentifier))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'scheduledTrainersIdentifier',
      }),
    );
  let scheduledTrainer: API.Worker | undefined = undefined;
  if (scheduledTrainersIdentifier) {
    const _scheduledTrainer = await getWorkerByNameOrEmailOrPhoneOrMatricule(
      scheduledTrainersIdentifier,
    );
    if (API.isFailure(_scheduledTrainer)) return _scheduledTrainer;
    if (!_scheduledTrainer)
      return API.createFailure(
        'ObjectNotFound',
        `Worker with identifier "${scheduledTrainersIdentifier}" not found`,
      );
    scheduledTrainer = _scheduledTrainer;
  }

  const traineeIdentifiers = parseCell(
    row.getCell(TrainingSessionColumns.scheduledTraineeIdentifiers),
    ExpectedCellDataType.Array,
  );
  if (API.isFailure(traineeIdentifiers)) return traineeIdentifiers;
  if (isValueDelete(traineeIdentifiers))
    return API.createFailure_Unspecified(
      t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
        keyword: DELETE_keyword,
        name: 'traineeIdentifiers',
      }),
    );
  const scheduledTrainee: API.Worker[] = [];
  for (const traineeIdentifier of traineeIdentifiers) {
    const trainee = await getWorkerByNameOrEmailOrPhoneOrMatricule(traineeIdentifier);
    if (API.isFailure(trainee)) return trainee;
    if (!trainee)
      return API.createFailure(
        'ObjectNotFound',
        `Worker with identifier "${traineeIdentifier}" not found`,
      );
    scheduledTrainee.push(trainee);
  }
  let isDraft: boolean;
  if (scheduledTrainer && scheduledTrainee.length >= 1 && scheduleStartDate) {
    isDraft = false; 
  } else if (!scheduledTrainer && scheduledTrainee.length === 1 && !scheduleStartDate) {
    isDraft = false; 
  } else {
    isDraft = true; 
  }

  const trainingSessionRequestInput: API.TrainingSessionCreateInput = {
    isDraft: isDraft,
    trainingVersionId: trainingVersion.id,
    scheduledTrainers: scheduledTrainer
      ? [{ trainerId: scheduledTrainer.id, percentage: '100%' }]
      : [],
    scheduledTraineeIds: scheduledTrainee.map(trainee => trainee.id),
    traineeIds: [],
    scheduledStartDate: scheduleStartDate?.toISOString(),
    durationInMin:
      durationInHour !== undefined ? durationInHour * HOUR_TO_MIN_MULTIPLIER : undefined,
    requestState: API.ReviewState.VALIDATED,
    location: note,
    endDateLimit: endDateLimit ? endDateLimit.toISOString() : undefined,
    trainers: [],
  };
  return API.createTrainingSession(trainingSessionRequestInput);
}



type BusinessObjectMutateTypes =
  | API.TreeDataType
  | API.DataType.SKILL
  | API.DataType.TRAINING
  | API.DataType.WORKER
  | API.DataType.TRAININGSESSION;
export async function processBusinessObjectMutateData(
  workSheet: Worksheet,
  dataType: BusinessObjectMutateTypes,
): Promise<ImportReport<API.Factory<API.DataTypeUnknown>>> {
  return processWorksheet(
    workSheet,
    getBusinessObjectMutateHeaders(),
    async row => {
      const idOrName = parseCell(
        row.getCell(BusinessObjectWorkSheetHeaders.idOrName),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(idOrName)) return idOrName;
      if (!idOrName)
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'id or name' }),
        );
      if (isValueDelete(idOrName))
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.DELETE_keyword_NotAllowded, {
            keyword: DELETE_keyword,
            name: 'idOrName',
          }),
        );

      const newName = parseCell(
        row.getCell(BusinessObjectWorkSheetHeaders.newName),
        ExpectedCellDataType.String,
      );
      if (API.isFailure(newName)) return newName;
      if (!newName)
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.RequiredFieldMissing, { name: 'new name' }),
        );

      let getBusinessObject: (
        nameOrId: string,
      ) => Promise<
        API.Result<
          API.Skill | API.Worker | API.Training | API.TrainingSession | API.TreeObject | undefined
        >
      >;
      let type: string = dataType;
      switch (dataType) {
        case API.DataType.SKILL:
          type = t('glossary:skill');
          getBusinessObject = API.isID(idOrName) ? API.getSkill : API.getSkillByName;
          break;

        case API.DataType.TRAINING:
          type = t('glossary:training');
          getBusinessObject = API.isID(idOrName) ? API.getTraining : API.getTrainingByName;
          break;

        case API.DataType.WORKER:
          type = t('glossary:worker');
          getBusinessObject = API.isID(idOrName)
            ? API.getWorker
            : API.getWorkerByNameOrEmailOrPhoneOrMatricule;
          break;

        case API.DataType.TRAININGSESSION:
          type = t('glossary:trainingSession');
          getBusinessObject = API.isID(idOrName)
            ? API.getTrainingSession
            : async (_name: string) => {
                
                return undefined;
              };
          break;

        
        case API.DataType.WORKSTATION:
        case API.DataType.ORGUNIT:
          type = t('glossary:workstation') + ' ' + t('glossary:organizationalUnit');
          getBusinessObject = async (nameOrId: string) => {
            if (API.isID(idOrName)) {
              const treeNode = API.Tree.getTreeNode(nameOrId);
              if (API.isFailure(treeNode)) return treeNode;

              return treeNode.object;
            } else {
              let orgUnit: API.Result<API.OrganizationalUnit> | undefined;
              const path = idOrName.split(getExcelDelimiter());
              if (!path.length) return API.createFailure('ObjectNotFound', 'idOrName is empty');

              orgUnit = await parseOrgUnitPathName(path, false);
              if (API.isFailure(orgUnit)) {
                if (API.isFailureType(orgUnit, 'ObjectNotFound')) {
                  const workstationName = path.pop()!; 
                  if (path.length) {
                    orgUnit = await parseOrgUnitPathName(path, false);
                    if (API.isFailure(orgUnit)) return orgUnit;
                  } else {
                    orgUnit = undefined;
                  }

                  return API.getWorkstationByName(workstationName, orgUnit?.id);
                }
              }

              return orgUnit;
            }
          };
          break;
      }

      const businessObject = await getBusinessObject(idOrName);
      if (API.isFailure(businessObject)) return businessObject;
      if (!businessObject) {
        return API.createFailure(
          'ObjectNotFound',
          t(ImportExportFailureMessageKey.ObjectDoesNotExist, {
            type: type,
            name: idOrName,
          }),
        );
      }

      if (isValueDelete(newName)) {
        
        if (dataType === API.DataType.TRAINING) {
          const idsToDelete: string[] = [];

          
          const trainingSessions = await API.getTrainingSessionsForATrainingOrATrainingVersion(
            businessObject.id,
          );
          if (API.isFailure(trainingSessions)) return trainingSessions;
          idsToDelete.push(...trainingSessions.map(trainingSession => trainingSession.id));

          
          const trainingVersions = await API.getTrainingVersionsForTraining(businessObject.id);
          if (API.isFailure(trainingVersions)) return trainingVersions;
          idsToDelete.push(...trainingVersions.map(trainingVersion => trainingVersion.id));

          
          for (const idToDelete of idsToDelete) {
            const result = await API.deleteFactoryBusinessObject(
              idToDelete,
              undefined,
              undefined,
              false,
              true,
            );
            if (API.isFailure(result)) return result;
          }

          return API.getFactory(dataType, businessObject.id); 
        } else {
          return API.deleteFactoryBusinessObject(
            businessObject.id,
            undefined,
            undefined,
            false,
            true,
          );
        }
      } else {
        if (dataType === API.DataType.TRAININGSESSION) {
          return API.createFailure_Unspecified('TrainingSession cannot be renamed');
        }

        
        const input: API.SharedProperties<
          API.BusinessObjectPartialUpdateInput<API.DataType.SKILL>,
          API.SharedProperties<
            API.BusinessObjectPartialUpdateInput<API.DataType.WORKER>,
            API.SharedProperties<
              API.BusinessObjectPartialUpdateInput<API.DataType.WORKSTATION>,
              API.SharedProperties<
                API.BusinessObjectPartialUpdateInput<API.DataType.ORGUNIT>,
                API.BusinessObjectPartialUpdateInput<API.DataType.TRAINING>
              >
            >
          >
        > = { id: businessObject.id, name: newName };
        return API.updateFactoryBusinessObject(dataType, input);
      }
    },
    false,
  );
}



export async function downloadImportTemplate(headers: Header[], fileName: string) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();

  addHeaders(worksheet, headers);

  const buffer = await workbook.xlsx.writeBuffer();
  const data = new Blob([buffer], { type: fileType });
  FileSaver.saveAs(data, fileName + importSuffix + fileExtension);
}

export async function downloadWorkerImportTemplates() {
  
  downloadImportTemplate(await getWorkerImportHeaders(), 'Workers');

  
  downloadImportTemplate(getWorkerSkillHeaders(), 'WorkerSkills');

  
  downloadImportTemplate(getWorkerTrainingImportHeaders(), 'WorkerTrainings');

  
  downloadImportTemplate(getWorkerTrainingSessionImportHeaders(), 'WorkerTrainingSessions');
}

export async function downloadTrainingSessionImportTemplates() {
  
  downloadImportTemplate(getTrainingSessionHeaders(), 'TrainingSession');

  
  downloadImportTemplate(
    getWorkerWorkstationTrainingRequestImportHeaders(),
    'WorkerWorkstationTrainingRequests',
  );
}

export async function downloadBusinessObjectImportTemplate() {
  downloadImportTemplate(getBusinessObjectMutateHeaders(), 'BusinessObject-MutateTemplate');
}

const initialRowAndColumnIndex = 1;
/**
 * Add headers to a worksheet by customizing the first rows according to passed array of headers
 * @returns [maxHeaderRowIndex, nextHeaderColumnIndex]
 * @param worksheet worksheet to be customized
 * @param headers the headers used for customization
 *  @param headerRowIndex (default 1) at which row shall this header be inserted
 * @param headerColumnIndex (default 1) at which column shall this header be inserted
 * @param setOnlyHeaderColumnKeys (default false) set to true when you don't want to add the headers but only set the column keys
 */
export function addHeaders(
  worksheet: Worksheet,
  headers: Header[],
  headerRowIndex: number = initialRowAndColumnIndex,
  headerColumnIndex: number = initialRowAndColumnIndex,
  setOnlyHeaderColumnKeys = false,
): [number, number] {
  let maxHeaderRowIndex = headerRowIndex;
  let nextHeaderColumnIndex = headerColumnIndex;
  for (const header of headers) {
    const [_maxHeaderRowIndex, _nextHeaderColumnIndex] = addHeader(
      worksheet,
      header,
      headerRowIndex,
      nextHeaderColumnIndex,
      setOnlyHeaderColumnKeys,
    );
    maxHeaderRowIndex = Math.max(maxHeaderRowIndex, _maxHeaderRowIndex);
    nextHeaderColumnIndex = _nextHeaderColumnIndex;
  }

  
  if (
    !setOnlyHeaderColumnKeys &&
    headerRowIndex === initialRowAndColumnIndex &&
    headerColumnIndex === headerColumnIndex
  ) {
    for (let colIndex = 1; colIndex < nextHeaderColumnIndex; colIndex++) {
      const column = worksheet.getColumn(colIndex);
      for (let rowIndex = maxHeaderRowIndex; rowIndex > 0; rowIndex--) {
        const cell = worksheet.getRow(rowIndex).getCell(colIndex);
        
        if (cell.value) {
          worksheet.mergeCells(
            cell.address + ':' + worksheet.getRow(maxHeaderRowIndex).getCell(colIndex).address,
          );

          rowIndex = 0; 
        }
      }
      column.width = excelSpacings.Standard + excelSpacings.Small;
      column.font = { size: excelFontSize, family: 2 };
    }
  }

  return [maxHeaderRowIndex, nextHeaderColumnIndex];
}

const headerBorder: Partial<Borders> = {
  top: { style: 'double' },
  left: { style: 'double' },
  bottom: { style: 'double' },
  right: { style: 'double' },
};
const headerFill: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFFCE500' },
};

/**
 * Add the header column, including its subHeaders.
 * @returns [maxHeaderRowIndex, nextHeaderColumnIndex]
 * @param worksheet
 * @param header
 * @param headerRowIndex at which row shall this header be inserted
 * @param headerColumnIndex at which column shall this header be inserted
 */
function addHeader(
  worksheet: Worksheet,
  header: Header,
  headerRowIndex: number,
  headerColumnIndex: number,
  setOnlyHeaderColumnKeys = false,
): [number, number] {
  const headerRow = worksheet.getRow(headerRowIndex);
  const headerCell = headerRow.getCell(headerColumnIndex);

  if (!setOnlyHeaderColumnKeys) {
    
    {
      if (headerRowIndex === 1) {
        headerRow.height = excelSpacings.Large;
        
      } else {
        headerRow.height = excelSpacings.Large / 2;
      }

      headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      
    }

    headerCell.value = capitalizeFirstLetter(header.title);
    headerCell.border = headerBorder;
    headerCell.fill = headerFill;
    if (header.comment) headerCell.note = getComment(header.comment);
  }

  const column = worksheet.getColumn(headerColumnIndex);
  if (header.key) column.key = header.key;

  if (!header.subHeaders?.length) {
    return [headerRowIndex, headerColumnIndex + 1];
  } else {
    const [maxHeaderRowIndex, nextHeaderColumnIndex] = addHeaders(
      worksheet,
      header.subHeaders,
      headerRowIndex + 1,
      headerColumnIndex,
      setOnlyHeaderColumnKeys,
    );

    if (!setOnlyHeaderColumnKeys) {
      
      worksheet.mergeCells(
        headerCell.address + ':' + headerRow.getCell(nextHeaderColumnIndex - 1).address,
      );
    }

    return [maxHeaderRowIndex, nextHeaderColumnIndex];
  }
}

/**
 * Add filters to the headers.
 * In case the headers is spread over several rows, then an additionnal row is inserted by concatenating the columns headers title.
 */
export function addHeaderFilters(workbook: Workbook): void {
  workbook.eachSheet(worksheet => {
    const lastRowNumber = worksheet.lastRow ? worksheet.lastRow.number : 1;
    const lastColumnNumber = worksheet.lastColumn ? worksheet.lastColumn.number : 1;

    let numberOfHeaderRows = 1;
    for (let r = 1; r <= lastRowNumber; r++) {
      const cell = worksheet.getCell(r, 1);
      if (!_.isEqual(cell.border, headerBorder) || !_.isEqual(cell.fill, headerFill)) {
        

        break;
      } else {
        numberOfHeaderRows = r;
      }
    }

    {
      
      const row: CellValue[] = [];
      for (let c = 1; c <= lastColumnNumber; c++) {
        row[c] = addHeaderFilters_getColumnName(worksheet, numberOfHeaderRows, c);
      }
      worksheet.insertRow(numberOfHeaderRows + 1, row, 'i').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00ffffff' },
      };

      worksheet.autoFilter = {
        from: {
          row: numberOfHeaderRows + 1,
          column: 1,
        },
        to: {
          row: numberOfHeaderRows + 1,
          column: lastColumnNumber,
        },
      };
    }

    
    

    
    worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: numberOfHeaderRows + 1 }];
  });
}

function addHeaderFilters_getColumnName(
  worksheet: Worksheet,
  numberOfHeaderRows: number,
  column: Partial<Column> | number,
): string {
  const columnIndex = isNumber(column) ? column : column.number;

  let columnName = '';
  for (let rowIndex = 1; rowIndex <= numberOfHeaderRows; rowIndex++) {
    const cell = worksheet.getCell(rowIndex, columnIndex);

    cell.master.fullAddress.col === columnIndex;
    
    if (
      cell.value &&
      (cell.type !== ValueType.Merge || cell.master.fullAddress.col !== columnIndex)
    ) {
      columnName = columnName + (rowIndex === 1 ? '' : ' - ') + (cell.value ?? '');
    }
  }

  return columnName;
}





/** Some of the values allowded inside a BusinessObject Input (shall include at least the ones that user can update through Excel import) */
type BusinessObjectNonNullableValue =
  | Array<BusinessObjectNonNullableValue>
  | string
  | number
  | boolean
  | API.ContractInput
  | API.S3ObjectInput
  | API.WorkerSkillInfoInput
  | API.MissingWorkerSkillInfoInput
  | API.SkillTrainingVersion 
  | API.ReviewInput;
type BusinessObjectValue = Readonly<BusinessObjectNonNullableValue | null | undefined>;

/**
 * Check whether the object can be updated by the given {property: value}
 * By default (see adminOnly_overwriteNoDelete) newValue can be added to the object's property as long as it doens't remove existing ones.
 * In case of success it updates (mutate) the {updateInput}
 * @param object existing object
 * @param property property subject to update
 * @param newValue (might be @see {DELETE_keyword} to remove the property. Only available when adminOnly_overwriteNoDelete=true)
 * @param updateInput object MUTATED to maybe add the newValue
 * @param adminOnly_overwriteNoDelete (default: false) if true, the object property will be updated with the newValue (except if it is nullish => we cannot delete the property)
 * @returns Nothing (it is the updateInput that is mutated accordingly) OR a Failure
 */
function checkUpdatableProperty<
  T extends API.DataTypeBusinessObject,
  O extends API.BusinessObject<T>,
  P extends keyof U,
  U extends API.BusinessObjectCreateInput<T>,
>(
  object: O,
  property: P,
  newValue: IsDeletableProp<U[P]>,
  updateInput: U,
  adminOnly_overwriteNoDelete = false,
): API.Result<void> {
  if (!isValueNotNullish(newValue)) return; 

  
  const objectValue = object[property];

  if (isValueDelete(newValue)) {
    if (!adminOnly_overwriteNoDelete)
      return API.createFailure_Unspecified(
        'Property ' + property.toString() + ' is marked for deletion, but this is not allowed',
      );

    if (Array.isArray(objectValue)) {
      
      
      updateInput[property] = [] as U[P]; 
    } else {
      updateInput[property] = null as U[P]; 
    }

    return;
  }

  let _newValue = newValue as NonNullable<U[P]>; 

  
  if (isValueNotNullish(objectValue)) {
    if (isValueEqual(objectValue, _newValue)) return; 

    if (!adminOnly_overwriteNoDelete) {
      if (Array.isArray(objectValue) && Array.isArray(_newValue)) {
        
        
        
        const __objectValue = objectValue as Array<BusinessObjectNonNullableValue>;
        const __newValue = _newValue as Array<BusinessObjectNonNullableValue>;
        if (allowAddingListItemWithoutListingExistingItems) {
          
          const allValues = [...__objectValue];
          __newValue.forEach(nV => {
            
            if (allValues.every(v => !isValueEqual(v, nV))) {
              allValues.push(nV);
            }
          });
          _newValue = allValues as NonNullable<U[P]>; 
        } else {
          
          if (__objectValue.some(v => __newValue.every(nV => !isValueEqual(nV, v)))) {
            return API.createFailure_Unspecified(
              'Passed ' +
                property.toString() +
                ' shall overlap the existing values. Please export the Object to get its ' +
                property.toString() +
                ' and enrich this list with new items.',
            );
          }
        }
      } else {
        return API.createFailure_Unspecified(
          'Object already have a ' +
            property.toString() +
            '.You cannot modify it through excel import.',
        );
      }
    }
  }

  updateInput[property] = _newValue;
}

function isValueEqual<T extends Readonly<BusinessObjectNonNullableValue>>(
  value: T,
  newValue: T,
): boolean {
  if (typeof value === 'string') return value === newValue;
  if (typeof value === 'number') return value === newValue;
  if (typeof value === 'boolean') return value === newValue;
  if (Array.isArray(value) && Array.isArray(newValue)) {
    if (value.length !== newValue.length) return false;
    return value.every(v => newValue.some(nV => isValueEqual(v, nV)));
  }
  if (API.isContract(value)) {
    return API.isSameContract(value, newValue as typeof value);
  }
  if (API.isS3Object(value) && API.isS3Object(newValue)) {
    return API.isSameS3Object(value, newValue);
  }
  if (API.isWorkerSkillInfo(value)) {
    return API.isSameWorkerSkillInfo(value, newValue as typeof value);
  }
  if (API.isMissingWorkerSkillInfo(value)) {
    return API.isSameMissingWorkerSkillInfo(value, newValue as typeof value);
  }
  if (API.isReview(value)) {
    return API.isSameReview(value, newValue as typeof value);
  }
  
  const _value = value as Exclude<
    T,
    Readonly<
      | BusinessObjectNonNullableValue[]
      | string
      | boolean
      | number
      | API.ContractInput
      | API.S3ObjectInput
      | API.WorkerSkillInfoInput
      | API.MissingWorkerSkillInfoInput
      | API.ReviewInput
    >
  >;
  if (API.isSkillTrainingVersion(_value)) {
    return API.isSameSkillTrainingVersion(_value, newValue as typeof _value);
  }

  logger.error('Unsupported type in isValueEqual(). Please fix.', value, newValue);
  return false;
}

/**
 * @param value
 * @returns Chek value is Not nullish
 */
function isValueNotNullish<T>(value: T): value is NonNullable<T> {
  if (value == null) return false;
  if (typeof value === 'string' && value.length === 0) return false;
  if (Array.isArray(value) && value.length === 0) return false;
  return true;
}

/**
 * @param value
 * @returns True if the given value contains the keywork @see {DELETE_keyword}
 */
function isValueDelete<T>(value: T | DELETE_keyword): value is DELETE_keyword {
  if (value === null || value === undefined) return false;

  
  if (typeof value === 'string' && value === DELETE_keyword) return true;

  
  if (Array.isArray(value) && value.length === 1) {
    if (value.some(item => isValueDelete(item))) return true;
  }

  return false;
}

/**
 * Tells if the obj contains at least one DeletableProperty @see {DELETE_keyword}
 * @param obj
 * @returns
 */
function isObjectFreeOfDeletableProp<O extends { [K in keyof O]: BusinessObjectValue }>(
  obj: DeletablePropObject<O>,
): obj is O {
  for (const key in obj) {
    const value = obj[key]; 

    if (isValueDelete(value)) return false;
  }

  return true;
}

/**
 * Import progress event dispatchers
 * @param totalSteps
 * @param incrementStep (optional, default = 1)
 */
function dispatchRowImportedExportedEvent(totalSteps: number, incrementStep: number = 1): void {
  MyHub.dispatchTaskProgress('ExcelImportExportLoadingStep', {
    incrementStep,
    totalSteps,
  });
}

/**
 * Reset the progressBar, import done
 */
function dispatchImportExportFinishedEvent(): void {
  MyHub.dispatchTaskProgress('ExcelImportExportLoadingStep', {
    reset: true,
    incrementStep: 0,
    totalSteps: 0,
  });
}

export enum ExpectedCellDataType {
  String,
  Array,
  TwoDArray,
  Number,
  Boolean,
  Date,
  ArrayOfDates,
}

enum TagType {
  WorkerTag,
  SkillTag,
}

export function parseCell(
  cell: Cell,
  expectedCellType: ExpectedCellDataType.Number,
): API.Result<number | undefined | DELETE_keyword>;
export function parseCell(
  cell: Cell,
  expectedCellType: ExpectedCellDataType.Date,
): API.Result<Date | undefined | DELETE_keyword>;
export function parseCell(
  cell: Cell,
  expectedCellType: ExpectedCellDataType.String,
): API.Result<string | undefined | DELETE_keyword>;
export function parseCell(
  cell: Cell,
  expectedCellType: ExpectedCellDataType.Boolean,
): API.Result<boolean | undefined | DELETE_keyword>;
export function parseCell(
  cell: Cell,
  expectedCellType: ExpectedCellDataType.Array,
): API.Result<string[] | DELETE_keyword>;
export function parseCell(
  cell: Cell,
  expectedCellType: ExpectedCellDataType.ArrayOfDates,
): API.Result<(Date | undefined)[] | DELETE_keyword>;
export function parseCell(
  cell: Cell,
  expectedCellType: ExpectedCellDataType.TwoDArray,
): API.Result<string[][] | DELETE_keyword>;
export function parseCell(
  cell: Cell,
  expectedCellType: ExpectedCellDataType,
): API.Result<
  | string[]
  | string[][]
  | (Date | undefined)[]
  | boolean
  | string
  | number
  | Date
  | undefined
  | DELETE_keyword
  | CellValue 
> {
  switch (expectedCellType) {
    case ExpectedCellDataType.Number:
      if (cell.type === ValueType.Number && Number.isFinite(cell.value)) return cell.value;

      const cellNumberValueAsString = parseCellValueAsString(cell);
      if (
        API.isFailure(cellNumberValueAsString) ||
        !cellNumberValueAsString ||
        isValueDelete(cellNumberValueAsString)
      )
        return cellNumberValueAsString;

      const number = Number.parseFloat(cellNumberValueAsString);
      if (Number.isNaN(number))
        return API.createFailure_Unspecified(
          t(ImportExportFailureMessageKey.NumberFormatError, {
            column: cell.col,
          }),
        );
      return number;

    case ExpectedCellDataType.Date:
      if (cell.type === ValueType.Date && isValidDate(cell.value)) return cell.value;

      const cellDateValueAsString = parseCellValueAsString(cell);
      if (
        API.isFailure(cellDateValueAsString) ||
        !cellDateValueAsString ||
        isValueDelete(cellDateValueAsString)
      )
        return cellDateValueAsString;

      return parseDateString(cellDateValueAsString);

    case ExpectedCellDataType.ArrayOfDates:
      const cellValueAsString = parseCellValueAsString(cell);
      if (API.isFailure(cellValueAsString) || isValueDelete(cellValueAsString))
        return cellValueAsString;
      if (!cellValueAsString) return [];

      const cellDatesArray = parseArray(cellValueAsString);
      return parseDatesArray(cellDatesArray);

    case ExpectedCellDataType.String:
      return parseCellValueAsString(cell);

    case ExpectedCellDataType.Boolean:
      if (cell.type === ValueType.Boolean) return cell.value;

      const s = parseCellValueAsString(cell);
      if (API.isFailure(s) || !s || isValueDelete(s)) return s;

      return parseBooleanLike(s);

    case ExpectedCellDataType.Array:
      const cellArrayValueAsString = parseCellValueAsString(cell);
      if (API.isFailure(cellArrayValueAsString) || isValueDelete(cellArrayValueAsString))
        return cellArrayValueAsString;
      if (!cellArrayValueAsString) return [];

      return parseArray(cellArrayValueAsString);

    case ExpectedCellDataType.TwoDArray:
      let cellTwoDArrayValueAsString = parseCellValueAsString(cell);
      if (API.isFailure(cellTwoDArrayValueAsString) || isValueDelete(cellTwoDArrayValueAsString))
        return cellTwoDArrayValueAsString;

      if (!cellTwoDArrayValueAsString) return [];

      
      const parsedArray: string[] = cellTwoDArrayValueAsString
        .split(/(?:^\[\[?)|(?:\]\s*;\s*\[)|(?:\]\]?$)/)
        .filter(e => e.length > 0);
      return parsedArray.map(item => parseArray(item));

    default:
      return parseCellValueAsString(cell);
  }
}

function convertDate2String(date: Date): string {
  return date.toISOString();
}

function isCellErrorValue(value: any): value is CellErrorValue {
  return !!(value as CellErrorValue).error;
}

/**
 * Parse the  given cell and return a non empty string.
 * If string is empty, undefined is returned.
 * If an error occured, a Failure is returned.
 * @see parseCell for a parsing returning native types
 * @param cell
 * @returns
 */
export function parseCellValueAsString(cell: Cell): API.Result<string | undefined> {
  function buildResult(value: string): string | undefined {
    const _value = value.trim();
    if (_value.length === 0) return undefined;
    return _value;
  }

  switch (cell.type) {
    case ValueType.Error:
      return API.createFailure_Unspecified((cell.value as CellErrorValue).error); 

    case ValueType.Null:
      return undefined;

    case ValueType.Merge:
      return parseCellValueAsString(cell.master);

    case ValueType.Date:
      if (isValidDate(cell.value)) return convertDate2String(cell.value);

    case ValueType.Formula:
      let result = (cell.value as CellSharedFormulaValue).result;
      if (result instanceof Date) {
        if (isValidDate(result)) return convertDate2String(result);
        
        result = cell.formula;
        
        
        if (result) result = result.replace(/^"(.*)"$/, '$1');
      }
      if (result === undefined) return undefined;
      if (isCellErrorValue(result)) {
        return API.createFailure_Unspecified(result.error);
      }

      return buildResult(result.toString());

    case ValueType.Number:
    case ValueType.Boolean:
    case ValueType.String:
    case ValueType.RichText:
    case ValueType.Hyperlink:
    case ValueType.SharedString:
      return buildResult(cell.text);
  }
}

async function getTags(
  tagIds: readonly string[],
  tagType: TagType.WorkerTag,
): Promise<API.Result<API.WorkerTag[]>>;
async function getTags(
  tagIds: readonly string[],
  tagType: TagType.SkillTag,
): Promise<API.Result<API.SkillTag[]>>;
async function getTags(
  tagIds: readonly string[],
  tagType: TagType,
): Promise<API.Result<(API.SkillTag | API.WorkerTag)[]>> {
  const failures: API.Failure[] = [];
  const tags = _.compact(
    await Aigle.map(tagIds, async tagId => {
      const tag =
        tagType === TagType.WorkerTag
          ? await API.getWorkerTag(tagId)
          : await API.getSkillTag(tagId);
      if (API.isFailure(tag)) {
        failures.push(tag);
        return;
      }
      return tag;
    }),
  );
  if (failures.length) return API.createFailure_Multiple(failures);

  return tags;
}

function parseArray(stringArray: string | null): string[] {
  return stringArray
    ? _.compact(
        stringArray.split(getExcelDelimiter()).map(item => {
          if (item !== '') return item.trim();
        }),
      )
    : [];
}

const neverExpiresLocaleStrings: string[] = Object.values(languages).map(language =>
  i18n.getFixedT(language.locale)('alex:workerSkill.neverExpire').toLocaleLowerCase(),
);
export function parseDateString(dateString: string): API.Result<Date | undefined> {
  
  if (neverExpiresLocaleStrings.includes(dateString.toLocaleLowerCase())) return undefined;

  const d = dayjs(dateString, ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'l HH:mm:ss', 'l HH:mm', 'l']);
  if (!d.isValid())
    return API.createFailure_Unspecified(
      `${t(ImportExportFailureMessageKey.InvalidDate)}: ${dateString}`,
    );

  if (!logger.isPROD)
    logger.verbose(
      'DateString ' +
        dateString +
        ' parsed with locale=' +
        dayjs.locale() +
        ' as: ' +
        d.toISOString(),
      d,
    );

  return d.toDate();
}

function parseDatesArray(dateStrings: string[]): API.Result<(Date | undefined)[]> {
  const parsedDates: (Date | undefined)[] = [];
  for (const dateString of dateStrings) {
    const parsedDate = parseDateString(dateString);
    if (API.isFailure(parsedDate)) return API.createFailure_Unspecified(parsedDate.message);
    else parsedDates.push(parsedDate);
  }

  return parsedDates;
}

/**
 * convert string like:
 *  - true/1/yes into true
 *  - false/0/no into false
 *  - null otherwise
 * @param value
 * @returns
 */
function parseBooleanLike(value: string): API.Result<boolean | undefined> {
  if (value.length === 0) return undefined;

  if (searchMatch(value, 'true', true) || searchMatch(value, '1', true)) return true;

  if (searchMatch(value, 'false', true) || searchMatch(value, '0', true)) return false;

  
  for (const language of Object.keys(languages)) {
    if (searchMatch(value, t('common:button.yes', { lng: language }), true)) return true;
    if (searchMatch(value, t('common:button.no', { lng: language }), true)) return false;
  }

  return API.createFailure_Unspecified(value + ' cannot be parsed as boolean');
}

function isPercentageDeclaredWithSymbol(target: string): boolean {
  return target.slice(-1) === '%';
}
function isPercentageDeclaredBetweenZeroAndOne(target: string): boolean {
  if (!target.includes('.')) return false;

  return parseFloat(target) >= 0 && parseFloat(target) <= 1;
}

function isLevelTargetInPercentage(minimum?: string, ideal?: string): API.Result<boolean> {
  if (!minimum && !ideal) return false; 

  let isMinimumInPercentage: boolean = false;
  if (minimum)
    isMinimumInPercentage =
      isPercentageDeclaredWithSymbol(minimum) || isPercentageDeclaredBetweenZeroAndOne(minimum);

  let isIdealInPercentage: boolean = false;
  if (ideal)
    isIdealInPercentage =
      isPercentageDeclaredWithSymbol(ideal) || isPercentageDeclaredBetweenZeroAndOne(ideal);

  if (minimum && !ideal) return isMinimumInPercentage;
  if (!minimum && ideal) return isIdealInPercentage;

  
  if (isMinimumInPercentage !== isIdealInPercentage)
    return API.createFailure_Unspecified(
      'Targets for the same level have to be input in the same unit',
    );

  return isMinimumInPercentage;
}

function formatPercentageTarget(target: string, isPercentage: boolean): number {
  if (!isPercentage) return parseInt(target);

  if (isPercentageDeclaredBetweenZeroAndOne(target)) return Math.floor(parseFloat(target) * 100);
  else return parseInt(target); 
}

/**
 * Wrapper function that manage progesse bar, lock to prevent 2 worksheets from behing imprted in parallel
 * @param workSheet to import
 * @param headers the headers template (usefull to set the column keys and know how many header rows does the file has)
 * @param processRow parsing a row
 * @param parallelRowProcessing True = row are processed in parallel (faster). False = row are processed in series respecting order
 * @returns
 */
async function processWorksheet<T extends {}>(
  workSheet: Worksheet,
  headers: Header[],
  processRow: (row: Row, totalRows: number) => Promise<API.Result<T>>,
  parallelRowProcessing: boolean,
): Promise<ImportReport<T>> {
  
  return processWorksheetLock.runSerial(async () => {
    dispatchImportExportFinishedEvent();

    
    const [maxHeaderRowIndex] = addHeaders(workSheet, headers, undefined, undefined, true);

    const failedRows: ImportFailure[] = [];
    const successRows: T[] = [];

    const rows: Row[] = [];
    
    workSheet.eachRow((row, rowNumber) => {
      
      if (rowNumber > maxHeaderRowIndex) {
        
        if (rowNumber === maxHeaderRowIndex + 1) {
          let mismatch = false;
          workSheet.getRow(rowNumber).eachCell((cell, col) => {
            if (
              !mismatch &&
              cell.value !== addHeaderFilters_getColumnName(workSheet, maxHeaderRowIndex, col)
            )
              mismatch = true;
          });

          if (mismatch) rows.push(row);
        } else rows.push(row);
      }
    });

    const enablePauseBroadcastFactoryMutationDuringImport = true;
    if (enablePauseBroadcastFactoryMutationDuringImport) {
      DataLayer.pauseFactoryMutationBroadcast(true);
    }

    const _processRow = async (row: Row): Promise<void> => {
      const processedRow = await processRow(row, rows.length);
      if (API.isFailure(processedRow)) {
        failedRows.push({
          row,
          failureMessage: processedRow.message,
        });
        await wait(10); 
      } else {
        successRows.push(processedRow);
      }
      dispatchRowImportedExportedEvent(
        rows.length + (enablePauseBroadcastFactoryMutationDuringImport ? 1 : 0),
      );
    };

    if (parallelRowProcessing) {
      
      await Aigle.mapLimit(rows, 10, _processRow); 
    } else {
      await Aigle.mapSeries(rows, _processRow);
    }

    if (enablePauseBroadcastFactoryMutationDuringImport) {
      DataLayer.pauseFactoryMutationBroadcast(false);
    }

    dispatchImportExportFinishedEvent();

    return {
      totalCount: successRows.length + failedRows.length,
      successCount: successRows.length,
      failureCount: failedRows.length,
      failures: failedRows,
      successes: successRows,
    };
  });
}

export const exportForTestingOnly = {
  parseCell,
  ExpectedCellDataType,
  DELETE_keyword,
  isValueDelete,
};
