import React, { useRef } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import * as API from 'shared/backend-data';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { YellowButton } from 'shared/ui-component/Button';
import { t } from 'shared/localisation/i18n';
import { Colors } from 'shared/styles';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { InputText } from 'shared/ui-component/Input';
import WebModal from 'modal-react-native-web';
import { ModalBackgroundStyle, ModalCardStyle } from 'shared/styles/ModalStyles';
import { ModalHeaderText } from 'shared/ui-component/Modal/ModalHeaderText';
import { ModalHeaderStyle } from 'shared/styles/ModalStyles';
import { styles } from './styles';

const PhotoIcon = require('shared/assets/svg/icon.camera.avatar.svg').default;
const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

interface Props {
  showLoader: boolean;
  worker: API.Worker | undefined;
  closeModal: () => void;
  submit: () => Promise<void>;
  handleInputs: (inputName: string, value: string) => void;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
enum CompleteUserInfoInputIds {
  FirstName = 'FirstName',
  LastName = 'LastName',
}

export const CompleteUserInfoComponent: React.FC<Props> = props => {
  const { showLoader, worker, closeModal, submit, onFileChange } = props;

  const inputFileRef = useRef<HTMLInputElement>(null);

  const { errors, onBlur, onChange, onSubmit, refs } = useCustomForm<string>(
    {
      [CompleteUserInfoInputIds.FirstName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
        initialValue: worker ? worker.firstName : '',
      },
      [CompleteUserInfoInputIds.LastName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
        initialValue: worker ? worker.familyName : '',
      },
    },
    submit,
  );

  const handleInputs = (inputId: string, inputValue: string) => {
    onChange(inputId, inputValue);
    props.handleInputs(inputId, inputValue);
  };

  return (
    <WebModal animationType="fade" transparent visible={true}>
      <View style={ModalBackgroundStyle}>
        <View style={ModalCardStyle}>
          <input
            ref={inputFileRef}
            hidden
            id="file-input"
            type="file"
            accept="image/*"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFileChange(e)}
          />
          <View style={ModalHeaderStyle}>
            <TouchableOpacity style={styles.closeIconContainer} onPress={closeModal}>
              <IconSVG svgComponent={closeIcon} />
            </TouchableOpacity>
            <ModalHeaderText title={t('common:account.myAccount')} />
          </View>
          <View style={styles.textInputContainer}>
            <View style={styles.photoContainer}>
              <TouchableOpacity style={styles.photo} onPress={() => inputFileRef.current?.click()}>
                {worker?.profilePicture ? (
                  renderImage(worker.profilePicture, ImageSizes.Large, worker.name)
                ) : (
                  <IconSVG svgComponent={PhotoIcon} color={Colors.Grey} />
                )}
              </TouchableOpacity>
            </View>
            <View style={styles.textInputOuterContainer}>
              <View style={styles.textRowContainer}>
                <InputText
                  inputRef={refs[CompleteUserInfoInputIds.FirstName]}
                  containerStyle={styles.leftInputText}
                  placeHolder={t('common:account.firstName')}
                  inputId={CompleteUserInfoInputIds.FirstName}
                  errorMessage={errors[CompleteUserInfoInputIds.FirstName]?.inputErrorMessage}
                  onBlur={onBlur}
                  defaultValue={worker && worker.firstName ? worker.firstName : ''}
                  onTextChange={handleInputs}
                />
                <InputText
                  inputRef={refs[CompleteUserInfoInputIds.LastName]}
                  containerStyle={styles.inputText}
                  placeHolder={t('common:account.lastName')}
                  inputId={CompleteUserInfoInputIds.LastName}
                  onBlur={onBlur}
                  defaultValue={worker && worker.familyName ? worker.familyName : ''}
                  errorMessage={errors[CompleteUserInfoInputIds.LastName]?.inputErrorMessage}
                  onTextChange={handleInputs}
                />
              </View>
            </View>
          </View>

          <View style={styles.buttons}>
            <YellowButton onPress={onSubmit} text={t('common:button.save')} isSmallButton />
          </View>
        </View>
        {showLoader && <Loader />}
      </View>
    </WebModal>
  );
};
