import { StyleSheet } from 'react-native';
import { TextFontStyles, fontSize, Colors } from 'shared/styles';

const styles = StyleSheet.create({
  path: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
  pathContainer: {
    flexDirection: 'row',
  },
  boldPath: {
    ...TextFontStyles.LatoBlack,
  },
});

export default styles;
