import React from 'react';
import { View, Text, StyleProp, ViewProps, TextStyle, StyleSheet } from 'react-native';
import { Badge } from './Badge';
import { Spacings } from 'shared/styles';

interface Props extends ViewProps {
  dotColor?: boolean | string | null;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  badgeValue?: string | number;
  badgeTextStyle?: StyleProp<TextStyle>;
  badgeStyle?: ViewProps;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    marginBottom: Spacings.Small + 2,
    marginLeft: 1,
  },
});

export const TextWithBadge: React.FC<Props> = props => {
  return (
    <View style={[styles.container, props.style]}>
      <Text style={props.textStyle}>{props.text}</Text>
      <Badge
        style={[styles.badge, props.badgeStyle]}
        dotColor={props.dotColor}
        value={props.badgeValue}
        valueStyle={props.badgeTextStyle}
      />
    </View>
  );
};
