import { StyleSheet } from 'react-native-web'; 
import * as SharedStyles from 'shared/styles';
import { Spacings, fontSize } from 'shared/styles';
import { ModalStyles } from 'shared/styles/ModalStyles';

const styles = StyleSheet.create({
  fullWidth: { width: '100%', zIndex: -6 },
  addSkillContainer: {
    width: ModalStyles.inputWidthWithAnchor,
    zIndex: 3,
    height: 36,
  },
  addSkillAdditionContainer: { top: -3 },
  leftPanelInnerContainer: {
    width: ModalStyles.inputWidthWithAnchor,
    paddingBottom: Spacings.Unit * 20,
  },
  workstationModalCloseIconContainer: { position: 'absolute', top: 8, right: 12 },
  workstationModalHeaderNormalTextStyle: { ...SharedStyles.TextFontStyles.LatoNormal },
  workstationModalPanelContainer: {
    flex: 1,
    flexDirection: 'row',
    zIndex: 998,
  },
  workstationModalPanelContainer2: {
    paddingRight: Spacings.Unit * 15,
  },
  workstationModalLeftPanelContainer: {
    flexDirection: 'row',
    marginRight: ModalStyles.bodyDivider,
    width: 348,
    zIndex: -1,
  },
  workstationModalLeftPanelIconContainer: { width: 64 },
  workstationModalLeftPanelIconInnerContainer: {
    height: 48,
    width: 48,
    backgroundColor: SharedStyles.Colors.Yellow,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  workstationModalLeftPanelInputTextContainer: {
    width: ModalStyles.inputWidthWithAnchor,
    zIndex: -8,
  },
  workstationModalLeftPanelDropDownContainer: {
    flexDirection: 'row',
    zIndex: 4,
  },
  dropDownTextStyle: { paddingLeft: SharedStyles.Spacings.Small },
  trainingModalRightPanelContainer: {
    paddingTop: SharedStyles.Spacings.Unit * 6.5,
    paddingBottom: Spacings.Unit * 20,
    zIndex: 5,
    flex: 1,
  },
  trainingModalRightPanel: {
    maxHeight: '95%',
    width: '100%',
    zIndex: 998,
  },
  trainingModalRightPanelTextStyle: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Unit * 5,
    paddingTop: SharedStyles.Spacings.Unit / 2,
  },
  trainingModalRightPaneInnerContainer: {
    justifyContent: 'space-between',
    height: 24,
    zIndex: 3,
  },
  skillsFilterContainer: { marginTop: 0 },
  addIconContainer: {
    width: 24,
    height: 24,
    marginRight: SharedStyles.Spacings.Small,
  },
  workstationModalRightPanelLevel4MinimumContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 998,
  },
  workstationModalRightPanelLevel4MinimumTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
  },
  workstationModalRightPanelLevel4IdealContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 997,
  },
  workstationModalRightPanelLevel4IdealTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
  },
  workstationModalRightPanelLevel3Container: { height: 125, width: '100%', zIndex: 996 },
  workstationModalRightPanelLevel3TextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    marginBottom: 23,
  },
  workstationModalRightPanelLevel3InnerContainer: { justifyContent: 'space-between', flex: 1 },
  workstationModalRightPanelLevel3MinimumContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 998,
  },
  workstationModalRightPanelLevel3MinimumTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
  },
  workstationModalRightPanelLevel3IdealContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 997,
  },
  workstationModalRightPanelLevel3IdealTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
  },
  buttonsContainer: {
    paddingRight: Spacings.Large,
    paddingBottom: Spacings.Medium,
    paddingTop: Spacings.xMedium,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: SharedStyles.ModalStyles.footerHeight,
  },
  trainingModalYellowButtonContainer: {
    borderRadius: 100,
    width: 124,
    height: 32,
  },
  trainingModalDeleteButtonContainer: {
    alignSelf: 'flex-end',
    marginRight: Spacings.Large,
    height: Spacings.Standard,
  },
  workstationModalYellowButtonTextStyle: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },
  addSkillsTouchable: { flexDirection: 'row' },
  addSkillTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    paddingTop: 3,
  },
  skillsListingOuterContainer: {
    maxHeight: '85%',
    paddingTop: SharedStyles.Spacings.Unit * 5,
    zIndex: 1,
  },
  skillsListingContainer: {
    flex: 1,
    zIndex: 2,
  },
  skillListingRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: SharedStyles.Spacings.Medium,
    width: '90%',
  },
  skillNameContainer: { flexDirection: 'row', maxWidth: '85%' },
  skillNameTextStyle: {
    ...SharedStyles.TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    paddingTop: 3,
    color: SharedStyles.Colors.Black,
  },
  skillIconContainer: {
    width: 24,
    height: 24,
    marginRight: SharedStyles.Spacings.Unit * 2.5,
  },
  closeIconContainer: {
    width: 16,
    height: 16,
    paddingTop: SharedStyles.Spacings.Unit * 1.5,
  },
  inputTag: {
    zIndex: 1,
    width: '100%',
  },
  inputDefaultTrainer: {
    zIndex: 3,
    width: '100%',
  },
  inputMaxTrainee: {
    zIndex: 2,
    width: '100%',
  },
  divider: { height: Spacings.Unit * 8, width: '100%', zIndex: -5 },
  smallDivider: { height: Spacings.Small, width: '100%', zIndex: -7 },
  skillCardContainerStyle: { width: ModalStyles.inputWidthWithAnchor },
  durationContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    zIndex: 4,
  },
  horizontalDivider: { width: Spacings.Medium },
  toolTipStyle: { top: Spacings.Standard, width: 328, right: -100 },
  workstationInput: { width: '60%', zIndex: -6 },
  textButtonContainor: { marginBottom: -39, zIndex: 2, marginTop: Spacings.xMedium },
  workstationContainor: {
    marginTop: Spacings.Small,
    width: '70%',
  },
  levelsContainer: {
    width: '30%',
    zIndex: -1,
    marginBottom: Spacings.Unit,
  },
  contentOfPractice: {
    ...SharedStyles.TextFontStyles.LatoBlack,
    fontSize: SharedStyles.fontSize.Regular,
    marginTop: Spacings.Medium,
    marginBottom: Spacings.Small,
  },
  modalBodyContainer: {
    maxHeight: ModalStyles.minHeight,
  },
  practiceInfo: {
    ...SharedStyles.TextFontStyles.LatoRegular,
    fontSize: SharedStyles.fontSize.Regular,
    marginTop: Spacings.Unit,
  },
  infoContainer: { flexDirection: 'row', width: '100%', marginTop: Spacings.Large },
});
export default styles;
