import { StyleSheet } from 'react-native';
import { TextFontStyles, Spacings, fontSize } from 'shared/styles/index';

export const styles = StyleSheet.create({
  container: {
    width: '50%',
    justifyContent: 'flex-end',
    height: 64,
    position: 'relative',
  },
  validityContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacings.Small,
  },
  validUntilTextStyle: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoNormal,
  },
  validityTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Medium,
    marginLeft: 3,
    marginTop: 2,
  },
  validityStateStringTextStyle: {
    fontSize: fontSize.Small,
    ...TextFontStyles.LatoNormal,
    width: '150%',
  },
  validUntilText: {},
  calendarStyle: {
    marginLeft: -Spacings.Unit,
    marginBottom: -Spacings.Unit,
  },
  infoIcon: {
    marginBottom: -Spacings.Unit,
    marginLeft: Spacings.Unit,
  },
  tooltipContainer: {
    top: Spacings.Standard,
    right: -50,
  },
});
