import React, { useState, useEffect, useContext } from 'react';
import { InteractionManager } from 'react-native';
import { PracticalTrainingSessionModalComponent } from '../component/TrainingModalComponent';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { DropDownWithWorkerLevelOption } from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';
import { WorkstationWorkerLevels, sortWorkers } from 'shared/backend-data';
import * as _ from 'lodash-es';
import { getTrainingDurationLabel } from 'shared/ui-component/DropDown/Data/DropDownData';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import Aigle from 'aigle';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { TrainWorkerModal } from '../../train-worker-modal/container';
import { filterMentorsForDropdown, getExternalTrainerLevel } from 'shared/util/TrainingUi';
import { ProofBookContainerWeb } from '../../proof-book';
import { WorkerDropDownOptionWithDetails } from 'shared/ui-component/DropDown/DropDown';

export interface WorkerDropdownOptions extends DropDownOption {
  profilePic?: string | null;
  level: WorkstationWorkerLevels;
}
interface Props {
  showModal: boolean;
  trainingSessionId?: string;

  handleModalClose: () => void;
}

export enum TrainingSessionInputElements {
  date = 'date',
  time = 'time',
  duration = 'duration',
  mentor = 'mentor',
  description = 'description',
  trainingSessionCoveragePercentage = 'trainingSessionCoveragePercentage',
}

export const PracticalTrainingSessionModalContainer: React.FC<Props> = props => {
  const {
    trainingSessionId,
    showModal,

    handleModalClose,
  } = props;

  const [workstation, setWorkstation] = useState<DropDownOption>();
  const [orgUnit, setOrgUnit] = useState<DropDownOption>();
  const [duration, setDuration] = useState<DropDownOption>();
  const [scheduledStartDate, setScheduledStartDate] = useState<Date>();
  const [mentors, setMentors] = useState<WorkerDropDownOptionWithDetails[]>();
  const [mentorsWithOutLevel, setMentorsWithOutLevel] =
    useState<WorkerDropDownOptionWithDetails[]>();
  const [worker, setWorker] = useState<WorkerDropdownOptions>();
  const [workerWithOutLevel, setWorkerWithOutLevel] = useState<DropDownOption>();
  const [mentorOptions, setMentorOptions] = useState<WorkerDropDownOptionWithDetails[]>([]);
  const [description, setDescription] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [workerDropdownOptions, setWorkerDropdownOptions] = useState<WorkerDropdownOptions[]>([]);
  const [workerWithOutLevelDropdownOptions, setWorkerWithOutLevelDropdownOptions] =
    useState<WorkerDropDownOptionWithDetails[]>();
  const [workstationDropdownOptions, setWorkstationDropdownOptions] = useState<DropDownOption[]>(
    [],
  );
  const [trainingWithLevel, setTrainingWithLevel] = useState<DropDownWithWorkerLevelOption>();
  const [trainingWithLevelOptions, setTrainingWithLevelOptions] =
    useState<DropDownWithWorkerLevelOption[]>();
  const [complementaryTrainings, setComplementaryTrainings] = useState<API.Training[]>();
  const [isTrainingSessionToBeClosed, setIsTrainingSessionToBeClosed] = useState<boolean>();
  const [isTrainingSessionToSchedule, setIsTrainingSessionToSchedule] = useState<boolean>();
  const [skillTags, setSkillTags] = useState<Tag<API.Skill>[]>([]);
  const [trainingDescription, setTrainingDescription] = useState<string>('');
  const [showAddTrainingProofModal, setShowAddTrainingProofModal] = useState(false);
  const [showTrainAWorkerOnAworkstationModal, setShowTrainAWorkerOnAworkstationModal] =
    useState(false);
  const [preSelectedTreeNode, setPreSelectedTreeNode] = useState<DropDownOption>();
  const [preSelectedWorker, setPreSelectedWorker] = useState<DropDownWithWorkerLevelOption>();
  const [preSelectedLevel, setPreSelectedLevel] = useState<API.WorkstationWorkerLevels>();

  const isMounted = useIsMounted();
  const { isValidPermission } = useContext(PermissionManagementContext);

  useEffect(() => {
    const _workstations = API.getWorkstations();

    const _workstationOptions: DropDownOption[] = _.map(_workstations, eachWorkstation => {
      return {
        key: eachWorkstation.id,
        label: eachWorkstation.name,
        tagPath: [API.DataType.WORKSTATION, eachWorkstation.pathIds.slice(0, -1)],
      };
    });
    setWorkstationDropdownOptions(_workstationOptions);
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      await setWorkerAsCurrentUser(false);
    });
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      if (!workstation) return;
      await setWorkerAsCurrentUser(true);
    });
  }, [workstation]);

  useEffect(() => {
    if (trainingSessionId && showModal) {
      fetchTrainingSessionDetails();
    }
  }, [trainingSessionId, showModal]);

  useEffect(() => {
    setDefaultTrainingDuration();
  }, [trainingSessionId, trainingWithLevel]);

  useEffect(() => {
    handleIsTrainingSessionToBeClosedOrToSchedule();
  }, [trainingSessionId]);

  useEffect(() => {
    fillTrainingDropDownOptions();
  }, [workstation]);

  useEffect(() => {
    fetchComplementaryTrainings();
  }, [workstation, trainingWithLevel, worker]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (workstation && isMounted.current)
        
        await fetchWorkers(workstation.key);
    });
  }, [worker, mentors, workstation]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (trainingWithLevel && trainingWithLevel.key) {
        await fetchSkillForTrainingVersion(trainingWithLevel.key);
        await setDefaultTrainer(trainingWithLevel);
      }
    });
  }, [trainingWithLevel]);

  function setDefaultTrainingDuration() {
    if (!trainingWithLevel || trainingSessionId || !trainingWithLevel.value.durationInMin) return;
    setDuration({
      key: trainingWithLevel.value.durationInMin.toString(),
      label: getTrainingDurationLabel(trainingWithLevel.value.durationInMin),
      value: trainingWithLevel.value.durationInMin,
    });
  }

  async function getWorkerDetails(workerId: string): Promise<API.Result<API.Worker>> {
    const worker = await API.getWorker(workerId);
    if (API.isFailure(worker)) {
      logger.warn('Error while fetching worker details', worker);
      return worker;
    }

    return worker;
  }

  /**
   * if the signed in user doesnt have training session edit permission by defult they will be chosen as the participants
   * because they can only request session for themselves
   * @param withLevel
   * @returns
   */
  async function setWorkerAsCurrentUser(withLevel: boolean) {
    if (
      !(worker && workerWithOutLevel) &&
      !isValidPermission(API.Permission.trainingSessions_edit) &&
      !trainingSessionId
    ) {
      const currentUser = await API.getWorker();
      if (!isMounted.current) return;
      if (API.isFailure(currentUser)) return currentUser;

      if (withLevel && workstation) {
        const _workerWorkstation = API.getWorkerWorkstations(workstation.key, currentUser.id);
        if (workerDropdownOptions)
          setWorker({
            key: currentUser.id,
            label: capitalizeFirstLetter(currentUser.name),
            level: _workerWorkstation?.level
              ? API.api2workstationWorkerLevels(_workerWorkstation.level)
              : API.WorkstationWorkerLevels.LEVEL0,
          });
        setWorkerWithOutLevel(undefined);
      } else
        setWorkerWithOutLevel({
          key: currentUser.id,
          label: currentUser.name,
        });
    }
  }

  function getTraineeId(trainingSession: API.TrainingSession): string {
    let traineeIds = trainingSession.scheduledTraineeIds;
    if (trainingSession.traineeIds.length) {
      traineeIds = trainingSession.traineeIds;
    }

    
    return traineeIds[0];
  }

  async function fetchTrainingSessionDetails() {
    const _trainingSession = await API.getTrainingSession(trainingSessionId!);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingSession)) {
      logger.warn('Error while fetching training session', _trainingSession);
      return;
    }

    const _training = await API.getTrainingForATrainingVersion(_trainingSession.trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(_training)) return _training;

    const isPractical = await API.isPracticalTrainingVersion(_trainingSession.trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(isPractical)) {
      logger.warn(isPractical);
      return;
    }

    if (isPractical && _trainingSession.requirementId) {
      const _requirementData = await API.getRequirement(_trainingSession.requirementId);
      if (!isMounted.current) return;
      if (API.isFailure(_requirementData)) {
        logger.warn(_requirementData);
        return;
      }
      const linkedObjectId = _requirementData.linkedObjectId;
      const linkedLevel = _requirementData.level;
      if (linkedLevel !== undefined) {
        setTrainingWithLevel({
          key: _trainingSession.trainingVersionId,
          label: _training.name,
          level: API.api2workstationWorkerLevels(linkedLevel),
        });
      }

      
      fetchSkillForTrainingVersion(_trainingSession.trainingVersionId);
      if (API.getDataType(linkedObjectId) === API.DataType.ORGUNIT) {
        const orgUnit = API.getOrganizationalUnit(linkedObjectId);
        if (API.isFailure(orgUnit)) {
          logger.warn(orgUnit);
          return;
        }
        setOrgUnit({
          key: orgUnit.id,
          label: orgUnit.name,
        });

        const traineeId = getTraineeId(_trainingSession);

        const _worker = await API.getWorker(traineeId);
        if (!isMounted.current) return;
        if (API.isFailure(_worker)) {
          logger.warn(_worker);
          return;
        }
        setWorkerWithOutLevel({
          key: _worker.id,
          label: _worker.name,
        });
        await fetchWorkers(linkedObjectId);
        if (!isMounted.current) return;

        const _mentors = [];

        if (_trainingSession.trainers) {
          for (const trainer of _trainingSession.trainers) {
            const _mentor = await getWorkerDetails(trainer.trainerId);
            if (!isMounted.current) return;
            if (API.isFailure(_mentor)) return _mentor;

            if (_mentor)
              _mentors.push({
                key: _mentor.id,
                label: _mentor.name,
                trainingSessionCoveragePercentage: trainer.percentage,
                value: _mentor,
              });
          }

          setMentorsWithOutLevel(_mentors);
        }
      } else {
        const workstation = await API.getWorkstation(linkedObjectId);
        if (!isMounted.current) return;
        if (API.isFailure(workstation)) {
          logger.warn('Error while fetching workstation', workstation);
          return;
        }
        if (workstation) {
          setWorkstation({
            key: workstation.id,
            label: workstation.name,
          });

          
          const traineeId = getTraineeId(_trainingSession);

          if (traineeId) {
            const result = await getWorkerDetails(traineeId);
            if (!isMounted.current) return;
            if (API.isFailure(result)) return result;

            const _workerWorkstation = API.getWorkerWorkstations(workstation.id, result.id);

            setWorker({
              key: result.id,
              label: result.name,
              profilePic: result.profilePicture,
              level: _workerWorkstation?.level
                ? API.api2workstationWorkerLevels(_workerWorkstation.level)
                : API.WorkstationWorkerLevels.LEVEL0,
              value: result,
            });
          }

          await fetchWorkers(workstation.id);

          
          if (_trainingSession.trainers) {
            const _mentors = [];

            for (const trainer of _trainingSession.trainers) {
              const _mentor = await getWorkerDetails(trainer.trainerId);
              if (!isMounted.current) return;
              if (API.isFailure(_mentor)) {
                return _mentor;
              }

              if (_mentor) {
                const _mentorWorkstation = API.getWorkerWorkstations(workstation.id, _mentor.id);

                _mentors.push({
                  key: _mentor.id,
                  label: _mentor.name,
                  level: _mentorWorkstation?.level
                    ? API.api2workstationWorkerLevels(_mentorWorkstation.level)
                    : _mentor.id === API.externalTrainerWorkerId
                    ? getExternalTrainerLevel()
                    : API.WorkstationWorkerLevels.LEVEL0,

                  trainingSessionCoveragePercentage: trainer.percentage,
                  value: _mentor,
                });
              }
            }

            if (_mentors) {
              setMentors(_mentors);
            }
          }

          
          fetchSkillForTrainingVersion(_trainingSession.trainingVersionId);
        }
      }

      if (_trainingSession.scheduledStartDate) {
        onChange(new Date(_trainingSession.scheduledStartDate));
      }
      if (_trainingSession.durationInMin) {
        setDuration({
          key: _trainingSession.durationInMin.toString(),
          label: getTrainingDurationLabel(_trainingSession.durationInMin),
          value: _trainingSession.durationInMin,
        });
      }
      _trainingSession.description && setDescription(_trainingSession.description);
    }
  }

  const onChange = (startDate?: Date) => {
    setScheduledStartDate(startDate);
  };

  function handleInputText(name: string, value?: string) {
    if (name === TrainingSessionInputElements.description) {
      setDescription(value);
    }
  }

  function handleDropdown(name: string, value?: DropDownOption) {
    if (name === API.DataType.WORKSTATION && value) {
      setWorkstation(value);
    } else if (name === API.DataType.TRAINING && value) {
      fetchSkillForTrainingVersion(value.key);
    } else if (name === TrainingSessionInputElements.duration && value) {
      setDuration(value);
    }
  }

  function handleTraineeDropDown(value?: DropDownWithWorkerLevelOption) {
    setWorker(value);
  }

  function handleMentorDropdown(value?: WorkerDropDownOptionWithDetails[]) {
    if (value) {
      setMentors(value);
    }
  }

  function handleMentorWithOutLevelDropdown(value?: WorkerDropDownOptionWithDetails[]) {
    if (value) {
      setMentorsWithOutLevel(value);
    }
  }

  async function fillTrainingDropDownOptions() {
    if (!workstation) return;
    setLoading(true);

    
    const levelRequirements = await API.getLevelsRequirement(workstation.key);
    if (!isMounted.current) return;
    if (API.isFailure(levelRequirements)) {
      setLoading(false);
      return levelRequirements;
    }

    const trainingVersionDropDownOptionsWithLevel: DropDownWithWorkerLevelOption[] = [];
    await Promise.all(
      _.map(Array.from(levelRequirements), async ([key, value]) => {
        if (!value.practicalTrainingId) return;

        const practicalTraining = await API.getTraining(value.practicalTrainingId);
        if (!isMounted.current) return;
        if (API.isFailure(practicalTraining)) {
          logger.warn(practicalTraining);
          return practicalTraining;
        }

        const _latestPracticalTrainingVersion = await API.getTrainingVersionLatestForTraining(
          value.practicalTrainingId,
        );
        if (!isMounted.current) return;
        if (API.isFailure(_latestPracticalTrainingVersion)) {
          logger.warn(_latestPracticalTrainingVersion);
          return _latestPracticalTrainingVersion;
        }
        trainingVersionDropDownOptionsWithLevel.push({
          key: _latestPracticalTrainingVersion.id,
          value: {
            ..._latestPracticalTrainingVersion,
            defaultTrainerId: practicalTraining.defaultTrainerId,
          },
          label: practicalTraining.name,
          level: key,
        });
      }),
    );
    setTrainingWithLevelOptions(
      _.sortBy(trainingVersionDropDownOptionsWithLevel, eachDropdown => eachDropdown.label),
    );
  }

  async function fetchWorkers(treeNodeId: string) {
    setLoading(true);
    const workerOptions: DropDownWithWorkerLevelOption[] = [];
    let workers: API.Worker[] = [];
    let workstationId = '';

    const dataType = API.getDataType(treeNodeId);
    if (API.isFailure(dataType)) {
      logger.warn(dataType);
      setLoading(false);
      return;
    }

    if (dataType === API.DataType.WORKSTATION) {
      const data = await API.getWorkstation(treeNodeId);
      if (!isMounted.current) return;
      if (API.isFailure(data)) {
        logger.warn(data);
        setLoading(false);
        return;
      }
      workstationId = data.parentId;
    }

    if (API.enableGlobalLevelComputation) {
      const _workers = await API.getWorkers([API.Permission.workerIsOperational]);
      if (!isMounted.current) return;
      if (API.isFailure(_workers)) {
        logger.warn(_workers);
        setLoading(false);
        return _workers;
      }
      workers = _workers.result;
    } else {
      const _workers = await API.getWorkersInOrganizationalUnits(
        [workstationId],
        [API.Permission.workerIsOperational],
      );
      if (API.isFailure(_workers)) {
        logger.warn(_workers);
        setLoading(false);
        return;
      }
      workers = _workers.result;
    }

    if (dataType === API.DataType.ORGUNIT) {
      const workers = await API.getWorkers([API.Permission.workerIsOperational]);
      if (!isMounted.current) return;
      if (API.isFailure(workers)) {
        logger.warn(workers);
        return;
      }
      const _workerOptions = sortWorkers(workers.result).map(worker => {
        return {
          key: worker.id,
          label: capitalizeFirstLetter(worker.name),
          value: worker,
        };
      });
      setWorkerWithOutLevelDropdownOptions(_workerOptions);
    } else {
      sortWorkers(workers).forEach(_worker => {
        const _workerWorkstation = API.getWorkerWorkstations(treeNodeId, _worker.id);
        if (_worker.id === worker?.key || mentors?.find(mentor => _worker.id === mentor?.key)) {
          workerOptions.push({
            key: _worker.id,
            label: capitalizeFirstLetter(_worker.name),
            level: _workerWorkstation?.level
              ? API.api2workstationWorkerLevels(_workerWorkstation.level)
              : API.WorkstationWorkerLevels.LEVEL0,
            disabled: true,
          });
        } else
          workerOptions.push({
            key: _worker.id,
            label: capitalizeFirstLetter(_worker.name),
            level: _workerWorkstation?.level
              ? API.api2workstationWorkerLevels(_workerWorkstation.level)
              : API.WorkstationWorkerLevels.LEVEL0,
            disabled: false,
          });
      });

      if (!workstation) return;
      const _mentorOptions = await filterMentorsForDropdown(workerOptions, workstation.key);
      if (!isMounted.current) return;
      if (API.isFailure(_mentorOptions)) {
        logger.warn(_mentorOptions);
        return;
      }

      setMentorOptions(_mentorOptions);
      setWorkerDropdownOptions(workerOptions);
    }

    setLoading(false);
  }

  function _handleModalClose() {
    setWorkstation(undefined);
    setDuration(undefined);
    setScheduledStartDate(undefined);
    setMentors(undefined);
    setWorker(undefined);
    setDescription(undefined);
    setWorkerDropdownOptions([]);
    setTrainingWithLevelOptions(undefined);
    setTrainingWithLevel(undefined);
    setComplementaryTrainings(undefined);
    handleModalClose();
    setSkillTags([]);
  }

  async function fetchComplementaryTrainings() {
    if (!worker || !trainingWithLevel || !workstation) return;

    if (worker.level < trainingWithLevel.level) {
      const _requiremet = await API.getLevelsRequirement(workstation.key);
      if (!isMounted.current) return;
      if (API.isFailure(_requiremet)) {
        logger.warn(_requiremet);
        return _requiremet;
      }
      const _levelRequirement = _requiremet.get(trainingWithLevel.level);
      if (_levelRequirement) {
        const _complementaryTrainings: API.Training[] = [];
        await Promise.all(
          _.map(_levelRequirement.skillTrainingVersions, async skillTrainingVersion => {
            if (
              skillTrainingVersion.trainingVersionId &&
              skillTrainingVersion.trainingVersionId !== trainingWithLevel.key
            ) {
              const _training = await API.getTrainingForATrainingVersion(
                skillTrainingVersion.trainingVersionId,
              );
              if (!isMounted.current) return;
              if (API.isFailure(_training)) {
                logger.warn(_training);
                return _training;
              }
              _complementaryTrainings.push(_training);
            }
          }),
        );
        setComplementaryTrainings(_complementaryTrainings);
      }
    }
  }

  async function handleIsTrainingSessionToBeClosedOrToSchedule() {
    if (!trainingSessionId) return;

    const _trainingSession = await API.getTrainingSession(trainingSessionId);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingSession)) {
      logger.warn('Failed to fetch TrainingSession', _trainingSession);
      return _trainingSession;
    }
    setIsTrainingSessionToBeClosed(
      API.isTrainingSessionStartedOrStartedLateOrLateEnd(_trainingSession),
    );
    setIsTrainingSessionToSchedule(API.isTrainingSessionDraftOrRequestValidated(_trainingSession));
  }

  async function fetchSkillForTrainingVersion(id: string) {
    const trainingVersion = await API.getTrainingVersion(id);
    if (!isMounted.current) return;
    if (API.isFailure(trainingVersion)) {
      logger.warn(trainingVersion);
      return;
    }

    const _training = await API.getTrainingForATrainingVersion(trainingVersion.id);
    if (!isMounted.current) return;
    if (API.isFailure(_training)) return _training;

    const _skillTags: Tag<API.Skill>[] = [];
    await Aigle.map(trainingVersion.skillIds, async skillId => {
      const data = await API.getSkill(skillId);
      if (!isMounted.current) return;
      if (API.isFailure(data)) {
        logger.warn('failed to fetch worker details', data);
        return;
      }

      const subSkills: Tag<API.Skill>[] = [];
      if (API.isSkillGroup(data)) {
        await Aigle.mapLimit(data.skillIds ?? [], 2, async skillId => {
          const subSkill = await API.getSkill(skillId);
          if (!isMounted.current) return;
          if (API.isFailure(subSkill)) return subSkill;

          subSkills.push({ value: subSkill, key: subSkill.id, label: subSkill.name });
        });
      }

      _skillTags.push({
        key: skillId,
        label: data.name,
        value: data,
        children: subSkills,
      });
    });
    if (_training.description) setTrainingDescription(_training.description);
    setSkillTags(_.sortBy(_skillTags, eachSkill => eachSkill.label));
  }

  async function setDefaultTrainer(training: DropDownOption): Promise<void> {
    if (trainingSessionId) return;
    if (!training || !training.value.defaultTrainerId) {
      setMentors(undefined);
      return;
    }

    mentorOptions.forEach(mentorOptions => {
      if (mentorOptions.key === training.value.defaultTrainerId) setMentors([mentorOptions]);
    });
  }

  function handleAddProof() {
    setShowAddTrainingProofModal(true);
  }

  function _handleTrainAWorkerModal(
    _preSelectedTreeNode: DropDownOption,
    _preSelectedWorker: DropDownWithWorkerLevelOption,
    _level: API.WorkstationWorkerLevels,
  ) {
    setPreSelectedTreeNode(_preSelectedTreeNode);
    setPreSelectedWorker(_preSelectedWorker);
    setPreSelectedLevel(_level);
    setShowTrainAWorkerOnAworkstationModal(true);
  }

  return (
    <>
      {showTrainAWorkerOnAworkstationModal && (
        <TrainWorkerModal
          openModal={showTrainAWorkerOnAworkstationModal}
          workerId={preSelectedWorker?.key}
          treeNodeId={preSelectedTreeNode?.key}
          trainingLevel={preSelectedLevel}
          handleModalClose={() => setShowTrainAWorkerOnAworkstationModal(false)}
          disableRedirection
        />
      )}
      {showAddTrainingProofModal && (
        <ProofBookContainerWeb
          trainingSessionId={trainingSessionId}
          setShowAddTrainingProofModal={setShowAddTrainingProofModal}
          disableProofAddition={!isValidPermission(API.Permission.trainingSessions_edit)}
          closeModalTrigger={_handleModalClose}
        />
      )}
      {showModal && (
        <PracticalTrainingSessionModalComponent
          loading={loading}
          workerDropdownOptions={workerDropdownOptions}
          workstationDropdownOptions={workstationDropdownOptions}
          scheduledStartDate={scheduledStartDate}
          duration={duration}
          mentors={mentors}
          worker={worker}
          description={description}
          trainingSessionId={trainingSessionId}
          trainingWithLevel={trainingWithLevel}
          trainingWithLevelOptions={trainingWithLevelOptions}
          mentorOptions={mentorOptions}
          selectedWorkstation={workstation}
          complementaryTrainings={complementaryTrainings}
          isTrainingSessionToBeClosed={isTrainingSessionToBeClosed}
          handleAddProof={handleAddProof}
          handleTrainAWorkerModal={_handleTrainAWorkerModal}
          handleModalClose={_handleModalClose}
          handleInputText={handleInputText}
          handleMentorsDropdown={handleMentorDropdown}
          handleTraineeDropDown={handleTraineeDropDown}
          handleTrainingWithLevel={setTrainingWithLevel}
          handleDropdown={handleDropdown}
          onChange={onChange}
          orgUnit={orgUnit}
          workerWithOutLevel={workerWithOutLevel}
          workerWithOutLevelDropdownOptions={workerWithOutLevelDropdownOptions}
          mentorsWithOutLevel={mentorsWithOutLevel}
          handleMentorsWithOutLevelDropdown={handleMentorWithOutLevelDropdown}
          skillTags={skillTags}
          isTrainingSessionToSchedule={isTrainingSessionToSchedule}
          trainingDescription={trainingDescription}
        />
      )}
    </>
  );
};
