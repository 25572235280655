import { Colors, fontSize } from 'shared/styles';
import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';
import { ModalStyles } from 'shared/styles/ModalStyles';

export const styles = StyleSheet.create({
  container: { flexGrow: 0, maxHeight: '100%' },
  scrollViewContentContainer: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  firstPageContainer: {
    width: ModalStyles.width,
    flex: 1,
  },
  itemsScrollView: {
    flexGrow: 1,
  },
  closeButtonIcon: {
    position: 'absolute',
    right: 12,
    top: 9,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttomButton: {
    width: 168,
    height: 32,
    justifyContent: 'center',
  },
  buttonContainer: {
    paddingBottom: 24,
    alignItems: 'center',
    paddingTop: 4,
  },
  workerDoesntExistText: {
    fontSize: SharedStyles.fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  inviteNewWorkerText: {
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Black,
    marginLeft: 36,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  textButtonContainer: {
    paddingRight: ModalStyles.smallButtonMargin,
    paddingTop: 18,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#F3F4F5',
  },
  inputSearchContainer: {
    width: 350,
    paddingLeft: SharedStyles.Spacings.Big * 2,
    height: 32,
  },
  inputStyle: { backgroundColor: Colors.Background },
  searchInputTextField: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  noResultView: {
    height: 377,
    paddingTop: SharedStyles.Spacings.Big * 2,
    alignItems: 'center',
  },
  noResultText: {
    fontSize: fontSize.Regular,
    color: SharedStyles.Colors.Grey,
    ...SharedStyles.TextFontStyles.LatoNormal,
  },
  goBackIconContainer: {
    paddingRight: 8,
    width: 24,
    height: 24,
  },
  inviteButton: {
    marginTop: SharedStyles.Spacings.Large,
    height: SharedStyles.Spacings.Standard,
    width: SharedStyles.Spacings.Big + SharedStyles.Spacings.xMedium,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inviteButtonText: {
    fontSize: fontSize.Small,
    ...SharedStyles.TextFontStyles.LatoBold,
  },
});
