import { StyleSheet } from 'react-native';
import { Spacings, Colors, fontSize } from 'shared/styles';
import { TextFontStyles } from 'shared/styles/TextStyles';
import { ImageSizes } from 'shared/util/RenderImage';
import { createRoundedStyle } from 'shared/styles/Styles';

const styles = StyleSheet.create({
  fullWidth: { width: '100%' },
  defaultHeaderTextStyle: {
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    fontSize: fontSize.XXXLarge,
  },
  defaultHeaderSecondTextStyle: {
    ...TextFontStyles.LatoNormal,
  },
  closeButtonIcon: {
    position: 'absolute',
    right: 12,
    top: 9,
  },
  leftPanel: {
    flex: 1,
    maxWidth: '50%',
    backgroundColor: Colors.Background,
    borderBottomLeftRadius: 8,
  },
  leftPanelContentContainer: { flex: 1, borderBottomLeftRadius: 8 },
  leftPanelFirstPage: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    borderBottomLeftRadius: 8,
    paddingTop: 28,
  },
  circleView: {
    ...createRoundedStyle(24),
    backgroundColor: Colors.Yellow,
  },
  rightPanel: {
    flex: 1,
    width: '50%',
    backgroundColor: Colors.White,
    borderBottomRightRadius: 8,
    borderColor: 'orange',
    borderWidth: 1,
  },
  rightAndLeftPanel: {
    backgroundColor: Colors.White,
    width: '100%',
    flexDirection: 'row',
    flex: 1,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    zIndex: -1,
  },
  featherContainer: { width: '100%', alignItems: 'center' },
  addProofOrAutomaticAttestContainer: {
    paddingTop: 36,
    paddingLeft: 32,
    paddingRight: 16,
    flex: 1,
  },
  addProofOrAutomaticAttestInnerContainer: {
    flex: 1,
  },
  addProofRowContainer: {
    flexDirection: 'row',
  },
  addProofOrAutomaticAttestVerticalContainer: {
    width: 24,
    height: '100%',
    alignItems: 'center',
  },
  checkBoxContainer: { paddingLeft: 5 },
  addProofContainer: {
    height: '100%',
    flex: 1,
    paddingLeft: 12,
    paddingTop: 2,
  },
  autoCertificateText: {
    ...TextFontStyles.LatoRegular,
    width: 220,
    fontSize: Spacings.Medium,
    textAlign: 'center',
    marginLeft: 26,
  },
  autoCertificateTextBold: {
    ...TextFontStyles.LatoBlack,
    fontSize: Spacings.Medium,
  },
  addProofTextStyle: {
    ...TextFontStyles.LatoNormal,
    color: Colors.Black,
    fontSize: 16,
  },
  addProofTextDisabledStyle: {
    ...TextFontStyles.LatoNormal,
    color: Colors.GreyLight,
    fontSize: 16,
  },
  automaticAttestWarningContainer: {
    flex: 1,
    paddingTop: Spacings.Small,
  },
  automaticAttestTextStyle: {
    ...TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    width: '80%',
  },
  automaticAttestTextDisabledStyle: {
    ...TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    width: '80%',
    color: Colors.GreyLight,
  },
  leftPanelSecondPage: {
    width: '100%',
    borderBottomLeftRadius: 8,
    paddingBottom: 20,
  },
  addRemoveProofHeader: {
    width: '100%',
    height: 36,
    flexDirection: 'row-reverse',
    paddingRight: 12,
    top: Spacings.Small,
    right: Spacings.Unit,
    backgroundColor: 'transparent',
    zIndex: 20,
  },
  addRemoveProofContainer: {
    flexDirection: 'row',
    width: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  roundTouchable: { borderRadius: 100 },
  proofsPreviewContainer: { flex: 1, paddingBottom: 4, width: '100%', height: '100%' },
  proofsPreviewScrollContainer: { flex: 1 },
  proofsPreviewScrollContentContainer: { width: 400, height: 386 },
  imageProofContainer: {
    width: '100%',
    height: '100%',
  },
  rightPanelContainer: {
    backgroundColor: Colors.White,
    flex: 1,
    paddingBottom: Spacings.Standard,
    paddingLeft: Spacings.Unit * 8,
    maxWidth: '50%',
    justifyContent: 'space-between',
    borderBottomRightRadius: 8,
  },
  rightPanelInputsContainer: {
    width: 300,
  },
  workerDropDown: {
    zIndex: 3,
    width: '100%',
  },
  trainerDropDown: {
    zIndex: 1,
    width: '100%',
  },
  skillDropDown: { width: '100%', zIndex: 4 },
  obtentionDateAndValidityContainer: {
    width: '100%',
    height: 64,
    zIndex: 2,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputDateContainer: { width: '40%' },
  commentsContainer: { zIndex: 1 },
  submitButtonContainer: {
    zIndex: -1,
    paddingRight: Spacings.Unit * 8,
    paddingTop: Spacings.CardPadding,
  },
  submitButtonStyle: {
    height: 36,
    justifyContent: 'center',
    zIndex: 0,
  },
  submitButtonTextStyle: {
    fontSize: fontSize.Medium,
    ...TextFontStyles.LatoBold,
  },
  avatarHeaderContainer: {
    flexDirection: 'row',
    width: 650,
    alignItems: 'center',
  },
  eachAvatar: {
    marginTop: Spacings.Standard,
    width: 65,
    alignItems: 'center',
  },
  moreButton: {
    height: ImageSizes.Medium,
    width: ImageSizes.Medium,
    borderRadius: ImageSizes.Medium / 2,
    backgroundColor: Colors.Yellow,
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreButtonText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Big,
    color: Colors.White,
  },
  subIcon: {
    height: Spacings.CardPadding,
    width: Spacings.CardPadding,
    borderRadius: Spacings.CardPadding / 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.GreyLight,
    position: 'absolute',
    right: -Spacings.Unit,
    bottom: -Spacings.Small,
  },
  selectedSubIcon: {
    backgroundColor: Colors.Yellow,
  },
  selectedAvatarHeader: {
    height: Spacings.Big,
    width: Spacings.Big,
    borderRadius: Spacings.Big / 2,
    backgroundColor: Colors.Yellow,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkIcon: {
    height: Spacings.Standard,
    width: Spacings.Standard,
    flex: 0.3,
  },
  checkIconContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  validatedOnText: {
    flex: 0.7,
    ...TextFontStyles.LatoMedium,
    fontSize: fontSize.Medium,
  },
  toReviewIconContainer: {
    flex: 1,
    bottom: Spacings.Unit,
  },
  toReviewIcon: {
    ...TextFontStyles.LatoBold,
    fontSize: Spacings.Medium,
    color: Colors.White,
  },
  certificateIcon: {
    width: 70,
    height: 88,
  },
  icon: {
    width: Spacings.Standard,
    height: Spacings.Standard,
  },
  proofContainer: {
    flex: 1,
    backgroundColor: Colors.ModalBackground,
    paddingHorizontal: 100,
    paddingTop: 70,
    paddingBottom: 100,
    zIndex: 1,
  },
  trainingMenuContainer: {
    top: Spacings.Large,
    width: '100%',
  },
  inputListContainer: {
    position: 'absolute',
    right: 57,
    top: Spacings.Small,
  },
  inputListContainerStyle: {
    width: 250,
    alignSelf: 'flex-end',
    right: 0,
  },
});

export default styles;
