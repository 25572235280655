import React from 'react';
import { View, Animated, TouchableOpacity, ScrollView } from 'react-native';
import WebModal from 'modal-react-native-web';
import { AddProof } from 'sharedweb/src/AddProof';
import { ModalBackgroundStyle, ModalCardStyle, ModalHeaderStyle } from 'shared/styles/ModalStyles';
import { IconSVG } from 'shared/ui-component/Icon';
import Styles from './styles';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { SummaryBook, Chapter } from 'shared/layout/summaryBook/SummaryBook';
import { BottomPanel } from 'shared/layout/summaryBook/BottomPanel/BottomPanel';
import { AddCommonProof } from 'shared/layout/summaryBook/AddCommonProof/AddCommonProof';
import { Page } from 'shared/layout/summaryBook/functions';

import { Tag } from 'shared/ui-component/Input/InputList/InputTag';

import { SkillPageValidateReviewModal } from 'skillmgtweb/src/components/training/proof-book/components/skillPageValidateReviewModal';
import { ProofRejectModal } from 'skillmgtweb/src/components/training/proof-book/components/proofRejectModal';
import { WorkerSkillAddModal } from 'skillmgtweb/src/components/add-workerskill-modal/container';
import { ProofHeaderTitle } from 'skillmgtweb/src/components/training/proof-book/components/headerText';
import { ProofHistoryPicker } from 'skillmgtweb/src/components/training/proof-book/components/proofHistoryPicker';
import { TrainingSessionHistoryPicker } from 'skillmgtweb/src/components/training/proof-book/components/trainingSessionHistoryPicker';
import { ChapterDetails } from 'skillmgtweb/src/components/training/proof-book/components/chapterDetails';
import { ProofActionsMenu } from 'skillmgtweb/src/components/training/proof-book/components/proofActionsMenu';
import { ProofBookType, ProofRejectModalProps, WorkerSkillModalProps } from '..';
import { ActivityIndicator } from 'shared/ui-component/Loader/ActivityIndicator';

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

interface Props {
  workersPages: Map<string, Page[]>;
  totalNumberOfSkills: number;
  isProofBookCompleted: boolean;
  isTrainingSessionPractical: boolean;
  totalNumberOfTrainees: number;
  disableProofValidation?: boolean;
  isProofBookSubmitted: boolean;
  isProofBookValidated: boolean;
  workerSkill?: API.WorkerSkill;
  proofBundle?: API.NoMetadata<API.ProofBundle>;
  proofBookType?: ProofBookType;
  showSkillPageValidateReviewModal: boolean;
  allowPartialSubmission: boolean;
  trainingSessionsOfWorker?: API.TrainingSession[];
  trainingSessionId?: string;
  chapter: Chapter | undefined;
  chapters: Chapter[];
  showCommonAddProofSection: boolean;
  isChapterContainsGhostTrainee: boolean;
  currentPageIndex: number;
  oneWorkerAndOneSkill: boolean;
  proofRejectModalProps?: ProofRejectModalProps;
  workerSkillModalProps?: WorkerSkillModalProps;
  loader: boolean;

  setChapter: React.Dispatch<React.SetStateAction<Chapter | undefined>>;
  onPageSwitch(index: number): void;
  handleSummaryButton(): void;
  handleChapterAddition(chapter: Chapter): void;
  handleDeleteChoosenFiles(chapter: Chapter): void;
  handleProofs(chapter: Chapter, files?: File[]): void;
  onSkillPress(page: Page): Promise<void>;
  onTraineePress(traineeId: string): void;
  onCommonProofPress(): void;
  updateTraningBookData: (chapter: Chapter, files?: File[], trainingVersionId?: string) => void;
  deleteProofBookChapter: (chapter: Chapter) => void;
  handleAutoCertificate: (chapter: Chapter) => void;
  handleSubmitToReview: (chapter?: Chapter, chapters?: Chapter[]) => void;
  onSelectProof: (proof: Tag) => void;
  handleAddWorkerSkillModal: (revoke: boolean) => void;
  handleSubmitAgainProof: () => void;
  deleteWorkerSkill(): Promise<void>;
  handleModalCloseButton(): void;
  handleSkillPageValidateCloseButton: () => void;
  onSelectTrainingSession: (trainingSession: Tag) => void;
}

export const ProofBookComponentWeb: React.FC<Props> = props => {
  const {
    workersPages,
    isProofBookCompleted,
    isTrainingSessionPractical,
    disableProofValidation,
    isProofBookSubmitted,
    isProofBookValidated,
    workerSkill,
    proofBundle,
    proofBookType,
    showSkillPageValidateReviewModal,
    allowPartialSubmission,
    trainingSessionsOfWorker,
    trainingSessionId,
    chapter,
    chapters,
    showCommonAddProofSection,
    isChapterContainsGhostTrainee,
    currentPageIndex,
    oneWorkerAndOneSkill,
    proofRejectModalProps,
    workerSkillModalProps,
    loader,

    setChapter,
    onPageSwitch,
    handleSummaryButton,
    handleChapterAddition,
    handleDeleteChoosenFiles,
    handleProofs,
    onSkillPress,
    onTraineePress,
    onCommonProofPress,
    handleModalCloseButton,
    deleteWorkerSkill,
    onSelectProof,
    handleAutoCertificate,
    handleSubmitToReview,
    handleAddWorkerSkillModal,
    handleSubmitAgainProof,
    handleSkillPageValidateCloseButton,
    onSelectTrainingSession,
  } = props;

  return (
    <>
      {showSkillPageValidateReviewModal ? (
        <SkillPageValidateReviewModal
          handleModalCloseButton={handleSkillPageValidateCloseButton}
          submitPage={() => handleSubmitToReview(chapter)}
          submitBook={() => handleSubmitToReview(chapters[0])}
          proofBookType={proofBookType}
        />
      ) : null}
      {proofRejectModalProps && proofRejectModalProps.showProofRejectModal && (
        <ProofRejectModal
          proofBundle={proofBundle}
          chapter={chapter}
          proofBookType={proofBookType}
          handleModalCloseButton={proofRejectModalProps.handleProofRejectModalCloseButton}
          proofUploader={proofRejectModalProps.proofUploader}
          setProofUploader={proofRejectModalProps.setProofUploader}
          onAccept={() => proofRejectModalProps.handleRejectModalProofSubmit(chapter, true)}
          onReject={() => proofRejectModalProps.handleRejectModalProofSubmit(chapter)}
          workerIdsWithNotAcquiredProofs={_.uniq(
            _.compact(proofRejectModalProps.workerIdsWithNotAcquiredProofs),
          )}
          skillIdsWithNotAcquiredProofs={_.uniq(
            _.compact(proofRejectModalProps.skillIdsWithNotAcquiredProofs),
          )}
        />
      )}
      {workerSkillModalProps &&
        workerSkillModalProps.showAddWorkerSkillModal &&
        workerSkill &&
        workerSkillModalProps.skill &&
        workerSkillModalProps.worker && (
          <WorkerSkillAddModal
            selectedSkills={[workerSkillModalProps.skill]}
            selectedWorkers={[workerSkillModalProps.worker]}
            revokeProof={workerSkillModalProps.showAddWorkerSkillModalRevoke}
            handleModalClose={workerSkillModalProps.handleModalClose}
            refreshSkills={async () => {
              workerSkillModalProps.setShowAddTrainingProofModal(false);
              workerSkillModalProps.handleModalClose();
            }}
            renewProof={workerSkillModalProps.renewProof}
            submitProofAgain={workerSkillModalProps.submitProofAgain}
          />
        )}
      <WebModal animationType="fade" transparent visible={true}>
        <Animated.View style={[ModalBackgroundStyle]}>
          <ScrollView>
            <View style={[ModalCardStyle]}>
              <View style={[ModalHeaderStyle, Styles.modalHeader]}>
                <TouchableOpacity style={Styles.closeButtonIcon} onPress={handleModalCloseButton}>
                  <IconSVG svgComponent={closeIcon} />
                </TouchableOpacity>
                <View style={Styles.headerTitleContainer}>
                  <ProofHeaderTitle proofBundle={proofBundle} proofBookType={proofBookType} />

                  {workerSkill && proofBundle && (
                    <ProofHistoryPicker
                      workerSkillId={workerSkill.id}
                      proofBundle={proofBundle}
                      onSelectProof={onSelectProof}
                    />
                  )}
                  {trainingSessionsOfWorker && (
                    <TrainingSessionHistoryPicker
                      trainingSessions={trainingSessionsOfWorker}
                      onSelectTrainingSession={onSelectTrainingSession}
                      initialTrainingSessionId={trainingSessionId}
                    />
                  )}
                </View>
              </View>

              {}
              {showCommonAddProofSection ? (
                <View style={Styles.rightAndLeftPanel}>
                  <AddCommonProof
                    onCommonProofPress={onCommonProofPress}
                    isProofBookCompleted={isProofBookCompleted}
                    isTrainingSessionPractical={isTrainingSessionPractical}
                    isChapterContainsGhostTrainee={isChapterContainsGhostTrainee}
                  />
                  <SummaryBook
                    workersPages={workersPages}
                    onTraineePress={onTraineePress}
                    onSkillPress={onSkillPress}
                    onTeamPress={onCommonProofPress}
                  />
                </View>
              ) : null}

              {}
              {!showCommonAddProofSection && chapter && Object.keys(chapter).length ? (
                <View style={Styles.rightAndLeftPanel}>
                  <AddProof
                    handleProofs={handleProofs}
                    chapter={chapter}
                    handleDeleteChoosenFiles={handleDeleteChoosenFiles}
                    handleChapterAddition={handleChapterAddition}
                    handleAutoCertificate={handleAutoCertificate}
                    isChapterContainsGhostTrainee={isChapterContainsGhostTrainee}
                  />
                  <ChapterDetails
                    chapter={chapter}
                    handleProofs={handleProofs}
                    setChapter={setChapter}
                    isChapterContainsGhostTrainee={isChapterContainsGhostTrainee}
                  />
                  {workerSkill && proofBundle && (
                    <ProofActionsMenu
                      workerSkill={workerSkill}
                      proofBundle={proofBundle}
                      handleAddWorkerSkillModal={handleAddWorkerSkillModal}
                      handleSubmitAgainProof={handleSubmitAgainProof}
                      deleteWorkerSkill={deleteWorkerSkill}
                    />
                  )}
                </View>
              ) : null}

              <BottomPanel
                handleSummaryButton={handleSummaryButton}
                onPageSwitch={onPageSwitch}
                chapters={chapters}
                currentPageIndex={currentPageIndex}
                isProofBookCompleted={isProofBookCompleted}
                handleSubmitToReview={() => handleSubmitToReview(chapter, chapters)}
                disableProofValidation={disableProofValidation}
                isProofBookSubmitted={isProofBookSubmitted}
                oneWorkerAndOneSkill={oneWorkerAndOneSkill}
                isProofBookValidated={isProofBookValidated}
                chapter={chapter}
                allowPartialSubmission={allowPartialSubmission}
              />
            </View>
          </ScrollView>
        </Animated.View>
        {loader && (
          <View style={Styles.loader}>
            <ActivityIndicator />
          </View>
        )}
      </WebModal>
    </>
  );
};
