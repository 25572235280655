import React, { useEffect, useState } from 'react';
import {
  Platform,
  StyleProp,
  TextStyle,
  ViewStyle,
  View,
  TouchableOpacity,
  Text,
} from 'react-native';
import Style from './Styles';
import moment from 'moment';
import { IconSVG } from '../../Icon';
import { Colors } from 'shared/styles/Colors';
import CalendarIcon from 'shared/assets/svg/icon.calendar.mobile.svg';
import ClockIcon from 'shared/assets/svg/icon.clock.mobile.svg';
import ArrowIcon from 'shared/assets/svg/icon.list.mobile.svg';
import { Mode } from 'shared/util-ts/DateUtils';
import { getLanguage, Locale } from 'shared/localisation/i18n';

export interface InputDateProps {
  inputName: string;
  inputPlaceholder: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  mode?: Mode | undefined;
  hasDateIcon?: boolean | undefined;
  hasTimeIcon?: boolean | undefined;
  value?: Date | undefined;
  maxDate?: Date | undefined;
  minDate?: Date | undefined;
  notEditable?: boolean | undefined;
  disableCalendar?: boolean | undefined;
  calendarContainerStyle?: StyleProp<ViewStyle>;
  showMandatoryWarning?: boolean | undefined;
  onChange: (inputName: string, date: Date) => void;
  onShowWebCalendar?: (show: boolean) => void;
  showCalendar: boolean;
  setShowCalendar: (show: boolean) => void;
}

const isPlatformWeb = Platform.OS === 'web';

export const InputDate: React.FC<InputDateProps> = props => {
  const {
    value: dateOrTime,
    mode,
    hasDateIcon,
    hasTimeIcon,
    notEditable,
    inputPlaceholder,
    disableCalendar,
    showMandatoryWarning,
    setShowCalendar,
    showCalendar,
  } = props;

  const [locale, setLocate] = useState<Locale>('en');

  useEffect(() => {
    const _locale = getLanguage().locale;
    setLocate(_locale);
  }, []);

  const chooseDate = () => {
    setShowCalendar(true);
  };

  const getDisplayText = (): string => {
    return moment(dateOrTime).format(mode === Mode.Time ? (locale === 'en' ? 'LT' : 'HH:mm') : 'L');
  };

  const getDisplayIcon = (): JSX.Element | null => {
    if (hasDateIcon || hasTimeIcon)
      return (
        <View style={Style.iconContainer}>
          <IconSVG svgComponent={hasDateIcon ? CalendarIcon : ClockIcon} color={Colors.Black} />
        </View>
      );

    return null;
  };

  return (
    <>
      <View style={[Style.rootContainer, props.style]}>
        {dateOrTime && (
          <View>
            <Text
              style={[
                Style.placeHolder,
                isPlatformWeb ? Style.webPlaceHolderFontSize : Style.mobilePlaceHolderFontSize,
              ]}
            >
              {props.inputPlaceholder}
            </Text>
          </View>
        )}
        <TouchableOpacity
          disabled={notEditable || disableCalendar}
          activeOpacity={1}
          onPress={() => {
            setShowCalendar(!showCalendar);
            if (!isPlatformWeb) chooseDate();
          }}
        >
          <View
            style={[
              Style.container,
              notEditable && { borderBottomWidth: 0 },
              showMandatoryWarning && { borderBottomColor: Colors.Red },
            ]}
          >
            <View style={[Style.alignItems]}>
              {getDisplayIcon()}
              <Text
                style={[
                  Style.text,
                  props.textStyle,
                  isPlatformWeb ? Style.webDateFontSize : Style.mobileDateFontSize,
                ]}
              >
                {!dateOrTime && inputPlaceholder}
                {dateOrTime && <Text style={Style.text2}>{getDisplayText()}</Text>}
              </Text>
            </View>
            {!notEditable && (
              <View style={Style.arrowIconContainer}>
                <IconSVG svgComponent={ArrowIcon} color={Colors.Black} />
              </View>
            )}
          </View>
        </TouchableOpacity>
      </View>
    </>
  );
};
