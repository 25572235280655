import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { TouchableOpacity } from 'react-native';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { Worker } from 'shared/backend-data';
import { WorkstationWorkerMenuObject } from 'skillmgtweb/src/components/dashboard/versatility-panel/VersatilityPanel';
import { useCallOnHover, highlightElement, lowlightElement } from 'shared/hooks/CallOnHover';
import * as _ from 'lodash-es';
import { IconSVG } from 'shared/ui-component/Icon';
import { useIsMounted } from 'shared/hooks/IsMounted';
import Styles from './Styles';
import ArrowDownIcon from 'shared/assets/svg/ic.open.list.svg';
import { Colors } from 'shared/styles/Colors';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { LevelIcon } from '../../../ui-component/Icon/LevelIcon/LevelIcon';

interface Props {
  workerWorkstation: API.WorkerWorkstation;
  worker?: Worker;
  selectedElement?: Element;
  routeFrom?: RoutePaths;
  noRequirementForWorkstation?: boolean;
  openMenu: boolean;
  menuObject: WorkstationWorkerMenuObject | undefined;
  parentOrgUnit?: API.OrganizationalUnit;
  levelIconType: API.LevelIconType;

  handleTreeNode?: (workstationId?: string) => void;
  setSelectedElement: (element?: Element) => void;
  setOpenMenu: (item: boolean) => void;
  setMenuObject: (item: WorkstationWorkerMenuObject) => void;
  handleUnassignWorkerAndStopTrainings: () => Promise<void>;
  handleMaintainSelect: (
    isMaintained: boolean,
    maintainLevel: API.WorkstationWorkerLevels,
  ) => Promise<API.Result<void>>;
  handleSilenceWarning: () => void;
  handleClick?: () => Promise<void>;
  setTreeNode?: (treeNode?: API.TreeNode<API.TreeDataType>) => void;
}

export const WorkStationWorkerLevelComponent: React.FC<Props> = props => {
  const {
    workerWorkstation,
    worker,
    parentOrgUnit,
    selectedElement,
    noRequirementForWorkstation,
    menuObject,
    openMenu,
    levelIconType,

    handleTreeNode,
    setMenuObject,
    setSelectedElement,
    setOpenMenu,
    handleClick: _handleClick,
    setTreeNode,
    handleUnassignWorkerAndStopTrainings,
  } = props;

  const [iconOnHover, setIconOnHover] = useState<boolean>(false);
  const [iconSelected, setIconSelected] = useState<boolean>(false);

  const isMounted = useIsMounted();

  const debounceHandleTreeNode = useCallback(
    _.debounce((workstationId?: string) => handleTreeNode && handleTreeNode(workstationId), 100),
    [],
  );

  const ref = useCallOnHover<TouchableOpacity>(
    Colors.BlueRollover,
    e => onHover(e),
    e => onUnHover(e),
  );

  const myMenuObject = useRef<WorkstationWorkerMenuObject>();

  useEffect(() => {
    if (worker && workerWorkstation) {
      const _myMenuObject: WorkstationWorkerMenuObject = {
        worker,
        workerWorkstation,
        handleMaintainSelect: handleMaintainSelect,
        handleUnassignWorkerAndStopTrainings: _handleUnassignWorkerAndStopTrainings,
        handleSilenceWarning: handleSilenceWarning,
        parentOrgUnit,
        noRequirementForWorkstation,
      };
      myMenuObject.current = _myMenuObject;
      
      if (ref && _myMenuObject) {
        const node = ReactDOM.findDOMNode(ref.current) as Element;
        if (selectedElement === node && !menuObject) {
          setMenuObject(_myMenuObject);
        }
      }
    }
  }, [worker, workerWorkstation, parentOrgUnit, noRequirementForWorkstation]);

  useEffect(() => {
    if (ref) {
      if (selectedElement === (ReactDOM.findDOMNode(ref.current) as Element)) {
        setIconSelected(true);
      } else {
        setIconSelected(false);
      }
    }
  }, [selectedElement]);

  async function _handleUnassignWorkerAndStopTrainings() {
    await handleUnassignWorkerAndStopTrainings();
    if (!isMounted.current) return;
    closeMenu();
  }

  async function handleMaintainSelect(maintainLevel: API.WorkstationWorkerLevels) {
    const isMaintain =
      API.isWorkerInTrainAutoOnWorkstation(workerWorkstation) &&
      API.isWorkerTargetingALevelOnWorkstation(workerWorkstation) &&
      maintainLevel === API.api2workstationWorkerLevels(workerWorkstation.targetLevel!);

    await props.handleMaintainSelect(isMaintain, maintainLevel);
    if (!isMounted.current) return;
    closeMenu();
  }

  function handleSilenceWarning() {
    closeMenu();
    props.handleSilenceWarning();
  }

  function closeMenu() {
    setOpenMenu(false);
    if (setTreeNode) setTreeNode(undefined);
    setIconSelected(false);
  }

  function handleClick() {
    if (ref && myMenuObject.current) {
      const node = ReactDOM.findDOMNode(ref.current) as Element;
      setSelectedElement(node);
      setOpenMenu(true);
      setMenuObject(myMenuObject.current);
    }

    if (_handleClick) {
      InteractionManager.runAfterInteractions(() => {
        _handleClick();
      });
    }
  }

  function onHover(event: Event) {
    highlightElement(Colors.BlueRollover, event);
    setIconOnHover(true);
    debounceHandleTreeNode(workerWorkstation.workstationId);
  }

  function onUnHover(event: Event) {
    lowlightElement(event);
    setIconOnHover(false);
  }

  return (
    <TouchableOpacity
      ref={ref}
      style={[
        Styles.iconContainer,
        openMenu &&
          selectedElement &&
          iconSelected && {
            backgroundColor: Colors.BlueRollover,
          },
      ]}
      onPress={handleClick}
    >
      {noRequirementForWorkstation ? (
        <IconSVG
          svgComponent={require('shared/assets/svg/icon.noSkill.svg').default}
          color={Colors.GreyLight}
          size={{ width: 35, height: 27 }}
        />
      ) : (
        <LevelIcon
          workerWorkstation={workerWorkstation}
          levelIconType={levelIconType}
          iconSize={{ width: 28, height: 28 }}
        />
      )}

      <IconSVG
        svgComponent={ArrowDownIcon}
        containerStyle={{ opacity: iconOnHover || iconSelected ? 1 : 0 }}
        color={Colors.Grey}
        size={{ width: 8, height: 4 }}
      />
    </TouchableOpacity>
  );
};
