import { StyleSheet } from 'react-native';
import { TextFontStyles, Colors, Spacings, fontSize, getCorrectedSpacings } from 'shared/styles';

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: 'rgba(52, 52, 52, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Medium,
    color: Colors.Black,
  },
  warningText: {
    ...TextFontStyles.LatoMedium,
    fontSize: fontSize.Medium,
    color: Colors.Black,
  },
  dependencyDetailContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Spacings.xMedium,
  },
  innerContainer: {
    backgroundColor: Colors.White,
    marginHorizontal: Spacings.Standard,
    minHeight: getCorrectedSpacings(196),
    borderRadius: Spacings.Small,
    paddingHorizontal: Spacings.Standard,
    paddingTop: getCorrectedSpacings(26.5),
    paddingBottom: Spacings.CardPadding,
  },
  innerContainerDependencyDetails: { flexDirection: 'row', alignItems: 'center' },
  iconContainer: {
    width: 24,
    height: 24,
    borderRadius: 24,
    backgroundColor: Colors.Background,
    marginRight: Spacings.Small,
  },
  textStyle: { ...TextFontStyles.LatoRegular, fontSize: fontSize.Regular },
  hoverIconContainer: { width: 24, height: 24 },
  textContainer: {
    paddingBottom: Spacings.Medium,
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingTop: Spacings.Unit * 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    padding: Spacings.Small,
    paddingHorizontal: getCorrectedSpacings(31),
    backgroundColor: Colors.White,
    borderRadius: Spacings.CardPadding,
    borderWidth: 1,
  },
});

export default styles;
