import { StyleSheet } from 'react-native';
import { Spacings, TextFontStyles, fontSize } from 'shared/styles';
export const scrollViewWidth = 472;

const styles = StyleSheet.create({
  CardContainer: {
    position: 'absolute',
    width: 504,
    height: 328,
    marginTop: Spacings.Standard,
    marginLeft: -475,
    paddingRight: Spacings.Medium,
    paddingLeft: Spacings.Medium,
    paddingBottom: 27,
    zIndex: 999,
    top: Spacings.Standard,
  },
  scrollViewContainer: {
    width: '100%',
  },
  rowView: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacings.Standard - Spacings.Unit,
  },
  centreRowView: {
    width: '100%',
    marginLeft: Spacings.Standard - Spacings.Unit,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: Spacings.Standard - Spacings.Unit,
  },
  infoContainerView: {
    width: '100%',
    paddingTop: Spacings.Medium,
  },
  firstScreenInfoContainerView: {
    width: '100%',
  },
  scrollBar: {
    flexDirection: 'row',
  },
  animatedView: {
    height: Spacings.Small,
    width: Spacings.Small,
    margin: 6,
    borderRadius: 5,
  },
  levelText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },
  levelTextDescription: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  centreLevelTextDescription: {
    ...TextFontStyles.LatoRegular,
    textAlign: 'center',
    alignSelf: 'center',
    width: 392,
    marginBottom: 26,
    fontSize: fontSize.Regular,
  },
  expireTextDescription: {
    ...TextFontStyles.LatoRegular,
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: 26,
    fontSize: fontSize.Regular,
    width: 300,
  },
  scrollbarContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 17,
    justifyContent: 'space-between',
  },
  scrollViewItem: {
    width: scrollViewWidth,
    paddingLeft: 12,
    paddingRight: 20,
    alignItems: 'center',
  },
  workerLevelEdgeIconStyle: { marginRight: 21 },
  workerLevelCenterIconStyle: { marginRight: Spacings.Standard },
  centeredText: {
    textAlign: 'center',
    marginBottom: Spacings.Small,
  },
  fadedLevelPadding: { paddingLeft: Spacings.Unit / 2 },
});

export default styles;
