import Aigle from 'aigle';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import {
  DropdownConfig,
  FilterConfig,
  TagExtended,
  checkBoxConfig,
} from 'sharedweb/src/Filter/container';
import * as _ from 'lodash-es';
import { Colors } from 'shared/styles';
import { FilterTagOrder } from 'sharedweb/src/Filter/component';

const skillValidityDurationTagColor = Colors.Orange;
const polyvalenceTagColor = Colors.LightBlue;

export interface DropdownInitialConfig {
  [key: string]: DropdownConfig;
}

export interface CheckNoxInitialConfig {
  [key: string]: checkBoxConfig[];
}

export interface FilterScreenConfig {
  [key: string]: FilterConfig;
}

export enum FilterConfigKey {
  WorkerSkill = 'WorkerSkill',
  WorkerWorkstation = 'WorkerWorkstation',
  TrainingWorker = 'TrainingWorker',
  TrainingWorkstation = 'TrainingWorkstation',
  SkillWorker = 'SkillWorker',
  SkillWorkstation = 'SkillWorkstation',
}

export enum DropdownConfigKey {
  ORGUNIT = 'ORGUNIT',
  SHIFT = 'SHIFT',
  WORKER = 'WORKER',
  WORKSTATION = 'WORKSTATION',
  LEVEL = 'LEVEL',
  WORKER_TAG = 'WORKER_TAG',
  SKILL_TAG = 'SKILL_TAG',
  TRAINING_TAG = 'TRAINING_TAG',
  HOME_OTHER_FILTER = 'HOME_OTHER_FILTER',
  WORKSTATION_OTHER_FILTER = 'WORKSTATION_OTHER_FILTER',
  WORKER_OTHER_FILTER = 'WORKER_OTHER_FILTER',
  WORKER_VERSATILITY = 'WORKER_VERSATILITY',
  CONTRACT = 'CONTRACT',
  SKILL = 'SKILL',
  VALIDITY = 'VALIDITY',
  TYPE_OF_TRAINING = 'TYPE_OF_TRAINING',
  TRAINING = 'TRAINING',
  SKILL_VALIDITY = 'SKILL_VALIDITY',
  WORKER_SKILL_OTHER_FILTER = 'WORKER_SKILL_OTHER_FILTER',
  SKILL_WORKER_OTHER_FILTER = 'SKILL_WORKER_OTHER_FILTER',
}

export enum CheckBoxConfigKey {
  INCLUDE_WORKERS_NOT_OPERATIONAL = 'INCLUDE_WORKERS_NOT_OPERATIONAL',
  SHOW_SELECTED_ORG_UNITS = 'SHOW_SELECTED_ORG_UNITS',
  SHOW_SUB_UNITS = 'SHOW_SUB_UNITS',
}

export enum WorkerSkillOtherFilterKey {
  SKILL_NOT_ACQUIRED = 'SKILL_NOT_ACQUIRED',
}

export enum HomeOtherFilterKey {
  ONE_SKILL_EXPIRING_SOON = 'ONE_SKILL_EXPIRING_SOON',
  ATLEAST_ONE_SKILL_EXPIRING_SOON = 'ATLEAST_ONE_SKILL_EXPIRING_SOON',
  ONE_SKILL_EXPIRED = 'ONE_SKILL_EXPIRED',
  ATLEAST_ONE_SKILL_EXPIRED = 'ATLEAST_ONE_SKILL_EXPIRED',
}

export enum TrainingTypeKey {
  PRACTICAL = 'PRACTICAL',
  LECTURE = 'LECTURE',
}

export enum ValidityFilterKey {
  ABOVE_3_MONTHS = 'ABOVE_3_MONTHS',
  ABOVE_6_MONTHS = 'ABOVE_6_MONTHS',
  ABOVE_1_YEAR = 'ABOVE_1_YEAR',
  ABOVE_2_YEAR = 'ABOVE_2_YEAR',
  LIMITLESS = 'LIMITLESS',
}

function getNumberDropdown(limit: number): TagExtended[] {
  const options = [];
  for (let i = 0; i <= limit; i++) {
    options.push({
      key: i.toString(),
      label: i.toString(),
      type: DropdownConfigKey.WORKER_VERSATILITY,
      value: i,
      color: polyvalenceTagColor,
      order: FilterTagOrder.Polyvalence,
    });
  }
  return options;
}

const polyvalenceOptions: TagExtended[] = getNumberDropdown(100);

const validityOptions: TagExtended[] = [
  {
    key: ValidityFilterKey.ABOVE_3_MONTHS,
    label: 'alex:filters.above3Months',
    value: 3,
    type: DropdownConfigKey.VALIDITY,
    color: skillValidityDurationTagColor,
    order: FilterTagOrder.Validity,
  },
  {
    key: ValidityFilterKey.ABOVE_6_MONTHS,
    label: 'alex:filters.above6Months',
    value: 6,
    type: DropdownConfigKey.VALIDITY,
    color: skillValidityDurationTagColor,
    order: FilterTagOrder.Validity,
  },
  {
    key: ValidityFilterKey.ABOVE_1_YEAR,
    label: 'alex:filters.above1Year',
    value: 12,
    type: DropdownConfigKey.VALIDITY,
    color: skillValidityDurationTagColor,
    order: FilterTagOrder.Validity,
  },
  {
    key: ValidityFilterKey.ABOVE_2_YEAR,
    label: 'alex:filters.above2Year',
    value: 24,
    type: DropdownConfigKey.VALIDITY,
    color: skillValidityDurationTagColor,
    order: FilterTagOrder.Validity,
  },
  {
    key: ValidityFilterKey.LIMITLESS,
    label: 'alex:workerSkill.neverExpire',
    value: null,
    type: DropdownConfigKey.VALIDITY,
    color: skillValidityDurationTagColor,
    order: FilterTagOrder.Validity,
  },
];



export const dropdownConfigInitialValues: DropdownInitialConfig = {
  [DropdownConfigKey.ORGUNIT]: {
    placeholder: 'glossary:organizationalUnitAbbreviated',
    options: [],
    value: undefined,
    key: DropdownConfigKey.ORGUNIT,
    label: 'glossary:organizationalUnitAbbreviated',
  },
  [DropdownConfigKey.SHIFT]: {
    placeholder: 'glossary:organizationalUnitAbbreviated',
    options: [],
    value: undefined,
    key: DropdownConfigKey.SHIFT,
    label: 'glossary:organizationalUnitAbbreviated',
  },
  [DropdownConfigKey.WORKER]: {
    key: DropdownConfigKey.WORKER,
    label: 'glossary:worker',
    placeholder: 'glossary:worker',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.WORKSTATION]: {
    key: DropdownConfigKey.WORKSTATION,
    label: 'glossary:workstation',
    placeholder: 'glossary:workstation',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.LEVEL]: {
    key: DropdownConfigKey.LEVEL,
    label: 'glossary:level',
    placeholder: 'glossary:level',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.WORKER_TAG]: {
    key: DropdownConfigKey.WORKER_TAG,
    label: 'glossary:workerTag',
    placeholder: 'glossary:workerTag',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.SKILL_TAG]: {
    key: DropdownConfigKey.SKILL_TAG,
    label: 'glossary:skillTag',
    placeholder: 'glossary:skillTag',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.TRAINING_TAG]: {
    key: DropdownConfigKey.TRAINING_TAG,
    label: 'glossary:trainingTag',
    placeholder: 'glossary:trainingTag',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.HOME_OTHER_FILTER]: {
    key: DropdownConfigKey.HOME_OTHER_FILTER,
    label: 'alex:filters.other',
    placeholder: 'alex:filters.other',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.WORKER_SKILL_OTHER_FILTER]: {
    key: DropdownConfigKey.WORKER_SKILL_OTHER_FILTER,
    label: 'alex:filters.other',
    placeholder: 'alex:filters.other',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.SKILL_WORKER_OTHER_FILTER]: {
    key: DropdownConfigKey.SKILL_WORKER_OTHER_FILTER,
    label: 'alex:filters.other',
    placeholder: 'alex:filters.other',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.WORKSTATION_OTHER_FILTER]: {
    key: DropdownConfigKey.WORKSTATION_OTHER_FILTER,
    label: 'alex:filters.other',
    placeholder: 'alex:filters.other',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.WORKER_OTHER_FILTER]: {
    key: DropdownConfigKey.WORKER_OTHER_FILTER,
    label: 'alex:filters.other',
    placeholder: 'alex:filters.other',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.WORKER_VERSATILITY]: {
    key: DropdownConfigKey.WORKER_VERSATILITY,
    label: 'glossary:workerVersatility',
    placeholder: 'glossary:workerVersatility',
    options: polyvalenceOptions,
    value: undefined,
  },
  [DropdownConfigKey.CONTRACT]: {
    key: DropdownConfigKey.CONTRACT,
    label: 'common:worker.contract',
    placeholder: 'common:worker.contract',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.SKILL]: {
    key: DropdownConfigKey.SKILL,
    label: 'glossary:skill',
    placeholder: 'glossary:skill',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.VALIDITY]: {
    key: DropdownConfigKey.VALIDITY,
    label: 'alex:workerSkillReviewModal.validity',
    placeholder: 'alex:workerSkillReviewModal.validity',
    options: validityOptions,
    value: undefined,
  },
  [DropdownConfigKey.TYPE_OF_TRAINING]: {
    key: DropdownConfigKey.TYPE_OF_TRAINING,
    label: 'alex:skills.typeOfTraining',
    placeholder: 'alex:skills.typeOfTraining',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.TRAINING]: {
    key: DropdownConfigKey.TRAINING,
    label: 'glossary:training',
    placeholder: 'glossary:training',
    options: [],
    value: undefined,
  },
  [DropdownConfigKey.SKILL_VALIDITY]: {
    key: DropdownConfigKey.SKILL_VALIDITY,
    label: 'alex:workerSkillReviewModal.validity',
    placeholder: 'alex:workerSkillReviewModal.validity',
    options: [],
    value: undefined,
  },
};

/**
 * This function removes outdated object which is already saved in the user preference for filter
 * @param userPreferenceKey
 * @param data
 */
export async function removeInvalidObjectFromUserPreference(
  userPreferenceKey: UserPreferenceKeys_SkillMgtApp,
  data: Map<string, TagExtended[]>,
): Promise<Map<string, TagExtended[]>> {
  const preference = data.get(userPreferenceKey) ?? [];
  await Aigle.map(preference, async (eachPreference, eachPreferenceIndex) => {
    if (eachPreference.isBookmarkTag && eachPreference.children?.length) {
      await Aigle.map(eachPreference.children, async (children, index) => {
        if (children.type === API.DataType.SHIFT) {
          const key = eachPreference.key.split(API.SeparatorIds)[0];
          const data = await API.getFactoryBusinessObject(API.DataTypeUnknown, key);
          if (API.isFailure(data)) {
            logger.warn(data);
            if (
              API.isFailureType(data, 'ObjectNotFound') ||
              API.isFailureType(data, 'Unauthorized')
            ) {
              preference.splice(index, 1);
            }
          }
        } else {
          const data = await API.getFactoryBusinessObject(API.DataTypeUnknown, children.key);
          if (API.isFailure(data)) {
            logger.warn(data);
            if (
              API.isFailureType(data, 'ObjectNotFound') ||
              API.isFailureType(data, 'Unauthorized')
            ) {
              eachPreference.children?.splice(index, 1);
              
              if (!eachPreference.children?.length) {
                preference.splice(eachPreferenceIndex, 1);
              }
            }
          }
        }
      });
    } else if (eachPreference.type === API.DataType.SHIFT) {
      const key = eachPreference.key.split(API.SeparatorIds)[0];
      const data = await API.getFactoryBusinessObject(API.DataTypeUnknown, key);
      if (API.isFailure(data)) {
        logger.warn(data);
        if (API.isFailureType(data, 'ObjectNotFound') || API.isFailureType(data, 'Unauthorized')) {
          preference.splice(eachPreferenceIndex, 1);
        }
      }
    } else {
      const data = await API.getFactoryBusinessObject(API.DataTypeUnknown, eachPreference.key);
      if (API.isFailure(data)) {
        logger.warn(data);
        if (API.isFailureType(data, 'ObjectNotFound') || API.isFailureType(data, 'Unauthorized')) {
          preference.splice(eachPreferenceIndex, 1);
        }
      }
    }
  });

  return data;
}

export function extractFilterTags(tags: TagExtended[]): TagExtended[] {
  let _filterTags: TagExtended[] = [];
  _.forEach(tags, tag => {
    if (tag.isActiveBookmarkTag) {
      _filterTags.push(...(tag.children ?? []));
    } else if (!tag.isBookmarkTag) {
      _filterTags.push(tag);
    }
  });

  return _filterTags;
}
