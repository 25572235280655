import { StyleSheet } from 'react-native';
import { TextFontStyles, Colors, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  tableNumber: {
    fontSize: fontSize.XLarge,
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    paddingRight: Spacings.Unit,
  },
  countContainer: {
    flexDirection: 'row',
    padding: Spacings.Small,
  },
  cardContainer: {
    position: 'absolute',
    left: Spacings.CardPadding * 2,
    top: 6,
    paddingHorizontal: Spacings.Small,
    paddingVertical: Spacings.Unit,
    minWidth: Spacings.CardPadding * 10,
  },
  menuName: {
    ...TextFontStyles.SemiBold,
    color: Colors.MediumGray,
  },
  menuNameContainer: {
    paddingVertical: Spacings.Unit,
  },
  scrollView: {
    maxHeight: 104,
  },
});

export default styles;
