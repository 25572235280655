import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.White,
    right: 60,
    position: 'absolute',
    top: 5,
    shadowOffset: {
      height: 2,
      width: 1,
    },
    shadowColor: Colors.ModalBackground,
    shadowOpacity: 1,
    shadowRadius: Spacings.Small,
    borderRadius: Spacings.Small,
    width: 358,
    zIndex: 999,
    paddingVertical: Spacings.Medium,
  },
  item: {
    paddingVertical: Spacings.Small,
    paddingLeft: 20,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Small,
  },
});

export default styles;
