import React, { useState } from 'react';
import { View, TouchableOpacity, Image, Text, Dimensions, FlatList, Platform, ImageStyle } from 'react-native';
import { CountryCodes } from './CountryCode';
import dropDownArrow from 'shared/assets/dropdown.png';
import Style from './Style';

const { width } = Dimensions.get('window');

interface Props {
  handleCountrySelect: any;
  defaultValue?: string;
  showCode?: boolean;
  width?: any;
}

export const CountryCodeDropDown: React.FC<Props> = props => {
  const [dropDownVisible, showDropDown] = useState(false);
  const [selectedValue, setDropDownValue] = useState(props.defaultValue ?? '+33');

  const keyExtractor = (item: any, index: any) => index.toString();

  const handleCountrySelect = (country: any) => {
    const value = props.showCode ? country.code : country.name;
    setDropDownValue(value);
    props.handleCountrySelect(value);
    showDropDown(false);
  };

  const handleDropDown = () => {
    if (dropDownVisible) {
      showDropDown(false);
    } else {
      showDropDown(true);
    }
  };

  return (
    <View style={Style.container}>
      <TouchableOpacity
        onPress={() => handleDropDown()}
        style={[
          Style.selectButton,
          {
            width: props.width
              ? Platform.OS === 'web'
                ? props.width
                : props.width * width
              : Platform.OS === 'web'
                ? '4.5vw'
                : 0.045 * width,
          },
        ]}
      >
        <View style={Style.iconContainer}>
          {}
          <Image source={dropDownArrow} style={Style.dropDownArrow as ImageStyle} />
        </View>
        <View style={Style.labelContainer}>
          <Text style={Style.dropDownLabel}>{selectedValue}</Text>
        </View>
      </TouchableOpacity>
      {dropDownVisible && (
        <View style={Style.flatListContainer}>
          <FlatList
            bounces={false}
            data={CountryCodes}
            keyExtractor={keyExtractor}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => handleCountrySelect(item)}
                style={[
                  Style.listItemContainer,
                  {
                    backgroundColor: index % 2 == 0 ? '#F1F3F4' : '#FFFFF',
                  },
                ]}
              >
                <Text style={Style.dropDownLabel}>
                  {props.showCode ? item['code'] : item['name']}
                </Text>
              </TouchableOpacity>
            )}
          />
        </View>
      )}
    </View>
  );
};

