import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
  useImperativeHandle,
  Ref,
  forwardRef,
} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
  NativeScrollEvent,
  InteractionManager,
} from 'react-native';
import { SortComponent, SortDirection } from 'skillmgtweb/src/components/sort';
import { Colors, Spacings } from 'shared/styles';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { ExportReport } from 'shared/util/ExcelUtils';
import { ImportExportComponent } from 'shared/ui-component/import-export-menu';
import { ImportExportType } from '../../util/ExcelUtils';
import style, { TableRowHeight } from './style';
import { t } from 'shared/localisation/i18n';
import { Menu, MenuItem, MenuWidth } from '../Menu';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import * as API from 'shared/backend-data';
import { Tag } from '../Input/InputTag';
import _, { DebouncedFunc } from 'lodash-es';
import logger, { loggerPerf } from 'shared/util/Logger';
import { ShadowOnHoverButton, TextButton } from '../Button';
import { MenuCard } from '../Menu/MenuCard';
import { TablePagination } from './TablePagination';

export enum Tables {
  SKILLS = 'SKILLS',
  WORKERS = 'WORKERS',
  WORKSTATIONS = 'WORKSTATIONS',
  TRAININGS = 'TRAININGS',
  SKILLSCONFORMITY = 'SKILLSCONFORMITY',
  SKILLSWORKERS = 'SKILLSWORKERS',
  SKILLSWORKSTATIONS = 'SKILLSWORKSTATIONS',
}

export interface TableColumn<R extends TableRow> {
  label: string;
  width?: number | string | undefined;
  sortPopupAlign?: 'left' | 'right';
  sort?: (rows: R[], sortDirection: SortDirection, sortOtherTables?: boolean) => R[];
  renderCell: (row: R, index: number, indexOfRow?: number) => React.ReactElement;
}

export const TableWidth = '10%';
export const TableMediumColumnWidth = '20%';
export const TableXMediumColumnWidth = '30%';
export const TableLargeColumnWidth = '40%';

const batchCount = 80;
const filterAndSortRowsDebounceInMs = 1000;

export interface TableRow {
  key: string;
  isCollapsed?: boolean;
}

interface TableProps<R extends TableRow> {
  ref?: Ref<TableRef>;
  columnDescriptors: TableColumn<R>[];
  /**
   * If not specified, default sorting is on the first column in ascending order
   * If null is set, no sorting is applyed
   */
  defaultSortColumn?: [number, SortDirection] | null;
  rows: R[];
  /**
   * Callback when the plus icon button is pressed
   */
  onPlusPress?: () => void;
  showAddButton?: boolean;
  /**
   * Callback when the whole touchable row is pressed
   */
  onRowPress: (row: R) => void;
  disableRowClick?: boolean | ((row: R) => boolean);
  disableHoverEffect?: boolean;
  disableSorting?: boolean;
  /**
   * Trigger to fetch more rows. Return true if there are even more rows to fetch, false if there is no more rows to fetch.
   */
  fetchMoreRows?: () => Promise<boolean>;
  /**
   * Add a menu at the end of the row
   */
  rowMenu?: (row: R) => MenuItem[];
  plusMenu?: MenuItem[];
  rowMenuWidth?: MenuWidth;
  plusMenuWidth?: MenuWidth;
  /**
   * Compute and mutate asynchronlously properties of a row.
   * Return true if some properties of the row were updated;
   * in such case the row will be re-rendered.
   */
  rowLazyLoadProperties?: (row: R, isMounted: React.MutableRefObject<boolean>) => Promise<boolean>;
  containerStyle?: StyleProp<ViewStyle>;
  importExport?: {
    showImportExport?: boolean;
    excelIcon: boolean;
    exportOnly?: boolean;
    importExportFileName: string;
    importExportType: ImportExportType;
    getExportData: (rows: R[], exportType: ImportExportType) => Promise<ExportReport>;
    refreshData: () => Promise<void>; 
  };
  filter?: {
    /**
     * Returns the filtered Rows
     * @param rows to filter
     * @param filters to apply
     */
    filterRows: (rows: R[], tags: Tag[]) => Promise<R[]>;
    /** The available tags inside the filter */
    tags: Tag[];
  };
  sortPreferenceKey?: Tables;

  
  saveRowData?: (rows: R[], filteredAndSortedRows: R[]) => void;

  noDataMessage?: string;

  style?: StyleProp<ViewStyle>;
  paginationContainerStyle?: StyleProp<ViewStyle>;
  avoidPagination?: boolean;
}

interface TablePreferences {
  sortDirection?: [number, SortDirection] | null;
  columnWidth?: string[];
}

export interface TableRef {
  sort?: (columnIndex: number, sortDirection: SortDirection, sortOtherTables?: boolean) => void;
}

const _Table = <R extends TableRow>(
  props: React.PropsWithChildren<TableProps<R>>,
  ref?: Ref<TableRef>,
) => {
  const {
    columnDescriptors,
    defaultSortColumn = [0, SortDirection.asc],
    rows,
    importExport,
    containerStyle,
    filter,
    disableSorting,
    disableRowClick,
    disableHoverEffect,
    plusMenu,
    showAddButton,
    avoidPagination,

    rowMenu,
    rowLazyLoadProperties,
    onRowPress,
    onPlusPress,
  } = props;

  const isMounted = useIsMounted();

  const _sortFunction = (
    columnIndex: number,
    sortDirection: SortDirection,
    sortOtherTables?: boolean,
  ) => {
    _filterAndOrSortRows(
      undefined,
      [columnIndex, sortDirection],
      rowsRef.current,
      rowsRef.current,
      sortOtherTables,
    );
  };
  useImperativeHandle(ref, () => ({ sort: _sortFunction }));

  const [filteredAndSortedRows, setFilteredAndSortedRows] = useState<R[]>([]);
  const [isMoreRows, setIsMoreRows] = useState<boolean>();

  const tablePreferences = useRef<Map<Tables, TablePreferences>>(new Map());
  const selectedSortColumn = useRef<[number, SortDirection] | null>();
  const selectedColumnWidth = useRef<string[]>();

  const layoutHeight = useRef<number>(0);
  const layoutWidth = useRef<number>(0);
  const selectedFilterTagsRef = useRef<Tag[]>([]);
  const [showPlusMenu, setShowPlusMenu] = useState<boolean>(false);
  const [startCount, setStartCount] = useState<number>(0);
  const [endCount, setEndCount] = useState<number>(batchCount);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showPagination, setShowPagination] = useState<boolean>(true);

  const [columnWidthArray, setColumnWidthArray] = useState<string[]>([]);
  const [columnIndex, setColumnIndex] = useState<number>(0);
  const [isScrollEnabled, setIsScrollEnabled] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const setLoadingDataCountRef = useRef<number>(0);

  
  const rowsRef = useRef(rows);

  
  const filterAndSortRows = useCallback(
    _.debounce(() => {
      _filterAndOrSortRows(
        selectedFilterTagsRef.current,
        selectedSortColumn.current,
        rowsRef.current,
        filteredAndSortedRows,
      );
    }, filterAndSortRowsDebounceInMs),
    [],
  );

  useEffect(() => {
    if (filter?.tags) {
      /**
       * Assigning selected filter tags to a ref because if we suddenly click on a filter before computing the values of whole table,
       * it will cause the debounceFilterAndSortRows function will get the old filter data and recomputing the whole process with old data
       * To avoid that, maintain this ref to make sure that debounceFilterAndSortRows function always get the updated data.
       */
      selectedFilterTagsRef.current = filter.tags;
    }
    rowsRef.current = rows;
    filterAndSortRows();
  }, [filter?.tags, rows]);

  useEffect(() => {
    if (props.fetchMoreRows) setIsMoreRows(true);
  }, [props.fetchMoreRows]);

  useEffect(() => {
    if (props.sortPreferenceKey) {
      fetchTablePreference(props.sortPreferenceKey);
    } else {
      setColumnWidthArray(
        columnDescriptors.map(columnsDescriptor => columnsDescriptor.width?.toString() || '0'),
      );
    }
  }, [props.sortPreferenceKey, columnDescriptors]);

  async function fetchTablePreference(tableKey: Tables): Promise<void> {
    const userSortPreference = await API.getUserPreference<Map<Tables, TablePreferences>>(
      API.UserPreferenceKeys_Common.TablePreferences,
    );
    if (!isMounted.current) return;
    if (API.isFailure(userSortPreference)) {
      logger.error('Error in fetching table preferences', userSortPreference);
      return;
    }

    selectedColumnWidth.current = columnDescriptors.map(columnsDescriptor =>
      columnsDescriptor.width ? columnsDescriptor.width?.toString() : '',
    );

    if (userSortPreference?.get(tableKey)?.columnWidth?.length) {
      selectedColumnWidth.current = userSortPreference?.get(tableKey)?.columnWidth ?? [];
    }

    setColumnWidthArray(selectedColumnWidth.current);

    if (userSortPreference) {
      tablePreferences.current = userSortPreference;
      const _sortColumn = userSortPreference.get(tableKey)?.sortDirection;

      if (_sortColumn) selectedSortColumn.current = _sortColumn;
    }
    selectedSortColumn.current = defaultSortColumn;
  }

  const saveTablePreference = async (tableKey: Tables) => {
    tablePreferences.current.set(tableKey, {
      sortDirection: selectedSortColumn.current,
      columnWidth: selectedColumnWidth.current,
    });

    const result = await API.saveUserPreference(
      API.UserPreferenceKeys_Common.TablePreferences,
      tablePreferences.current,
    );
    if (API.isFailure(result))
      logger.error('saveSortPreference: error in saving table preferences', result);
  };

  /**
   * Filter and/or Sort the rows
   * @param filterTags (optional) if not set or if length is 0, displayed rows are not filtered.
   * @param sortColumn (optional) if not set, displayed rows are not sorted. if null is set the sorting is removed on all rows.
   */
  async function _filterAndOrSortRows(
    filterTags?: Tag[],
    sortColumn?: [number, SortDirection] | null,
    _rows: R[] = rows,
    _filteredAndSortedRows: R[] = filteredAndSortedRows,
    sortOtherTables: boolean = true,
  ): Promise<R[] | void> {
    let newFilteredAndSortedRows: R[];

    
    if (filterTags === undefined) {
      newFilteredAndSortedRows = [..._filteredAndSortedRows];
    } else {
      newFilteredAndSortedRows = [..._rows];
      if (filter) {
        newFilteredAndSortedRows = await filter.filterRows(newFilteredAndSortedRows, filterTags);
      }
      if (!isMounted.current) return;
    }

    
    selectedSortColumn.current = sortColumn ?? defaultSortColumn;
    if (selectedSortColumn.current !== null) {
      const sortingFunction = columnDescriptors[selectedSortColumn.current[0]]?.sort;
      if (sortingFunction)
        newFilteredAndSortedRows = sortingFunction(
          newFilteredAndSortedRows,
          selectedSortColumn.current[1],
          sortOtherTables,
        );
    }

    if (props.saveRowData) props.saveRowData(_rows, newFilteredAndSortedRows);
    setFilteredAndSortedRows(newFilteredAndSortedRows);

    if (newFilteredAndSortedRows.length < batchCount) {
      setShowPagination(false);
      setStartCount(0);
      setEndCount(batchCount);
    } else {
      setShowPagination(true);
    }

    detectScrollView(newFilteredAndSortedRows.length);
  }

  function columnWidthChanged(index: number, width: string) {
    const _columnWidthArray = [...columnWidthArray];
    _columnWidthArray[index] = width;
    selectedColumnWidth.current = _columnWidthArray;

    setColumnWidthArray([..._columnWidthArray]);
    setColumnIndex(index);

    if (props.sortPreferenceKey) saveTablePreference(props.sortPreferenceKey);
  }

  /**
   * There is no api to detect the scroll is enabled or not in a ScrollView.
   * This function returns boolean to detect scroll is enabled or not
   * @param totalRowCount count of total number of rows in a table
   */
  function detectScrollView(totalRowCount: number) {
    if (layoutHeight.current && layoutHeight.current < TableRowHeight * totalRowCount) {
      setIsScrollEnabled(true);
    } else {
      setIsScrollEnabled(false);
    }
  }

  const renderHeaders = (hideColumnTitle?: boolean) => (
    <TouchableOpacity style={style.headerContainer} activeOpacity={1}>
      <View
        style={style.tableSortContainer}
        onLayout={e => {
          layoutWidth.current = e.nativeEvent.layout.width;
        }}
      >
        {!hideColumnTitle &&
          columnDescriptors.map((column, index) => (
            <SortComponent
              key={index}
              index={index}
              columnWidthArray={columnWidthArray}
              onSortClick={(sortDirection: SortDirection) => {
                selectedSortColumn.current = [index, sortDirection];
                filterAndSortRows();
                if (props.sortPreferenceKey) saveTablePreference(props.sortPreferenceKey);
              }}
              columnWidthChanged={columnWidthChanged}
              data={column}
              activeSort={
                !disableSorting && selectedSortColumn.current
                  ? selectedSortColumn.current[0] === index
                  : false
              }
              sortDirection={
                !disableSorting && selectedSortColumn.current
                  ? selectedSortColumn.current[1]
                  : SortDirection.asc
              }
              tableHeight={layoutHeight.current}
              tableWidth={layoutWidth.current}
            />
          ))}
      </View>

      <View style={style.tableRowRightIcon}>
        {showAddButton && (
          <View style={style.plusIconBox}>
            <ShadowOnHoverButton
              size={Spacings.Standard}
              iconSize={11}
              onPress={() => {
                if (plusMenu) {
                  setShowPlusMenu(true);
                }
                if (onPlusPress) {
                  onPlusPress();
                }
              }}
              iconContainerStyle={{ backgroundColor: Colors.Yellow }}
            />
          </View>
        )}
        {plusMenu && showPlusMenu && (
          <MenuCard
            menuWidth={props.plusMenuWidth}
            menuItems={plusMenu}
            setShowMenu={(value: boolean) => {
              setShowPlusMenu(value);
            }}
            onMenuPress={() => {
              setShowPlusMenu(false);
            }}
          />
        )}
        {importExport && filteredAndSortedRows && (
          <View style={importExport.exportOnly ? style.excelIconBox : undefined}>
            <ImportExportComponent
              importExportMenuType={importExport.importExportType}
              getExportData={exportType =>
                importExport.getExportData(filteredAndSortedRows, exportType)
              }
              exportOnly={importExport.exportOnly}
              fileName={importExport.importExportFileName}
              refreshData={importExport.refreshData}
            />
          </View>
        )}
      </View>
      {isScrollEnabled && <View style={{ width: 10 }} />}
    </TouchableOpacity>
  );

  const isCloseToBottom = (event: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return (
      event.layoutMeasurement.height + event.contentOffset.y >=
      event.contentSize.height - paddingToBottom
    );
  };

  async function handleShowMore(): Promise<void> {
    if (props.fetchMoreRows) {
      setLoading(true);
      const _isMoreRows = await props.fetchMoreRows();
      if (!isMounted.current) return;

      setIsMoreRows(_isMoreRows);
      filterAndSortRows();

      setLoading(false);
    }
  }

  function handlePreviewPagesSwitch(index: number) {
    setStartCount(index * batchCount);
    setEndCount((index + 1) * batchCount);
    setCurrentIndex(index);
  }

  function isFilterApplied(): boolean {
    if (!filter?.tags.length) return false;

    return !!filter.tags.filter(tag => !tag.isCheckBoxTag).length;
  }

  return (
    <>
      <View
        onLayout={event => {
          layoutHeight.current = event.nativeEvent.layout.height - Spacings.Large;
        }}
        style={[style.tableRootContainer, props.style]}
      >
        <View style={[style.paginationContainer, props.paginationContainerStyle]}>
          {loadingData && (
            <ActivityIndicator color={Colors.Yellow} size="small" style={style.activityIndicator} />
          )}
          {showPagination && (
            <TablePagination
              batchCount={batchCount}
              maxIndex={filteredAndSortedRows.length / batchCount}
              currentIndex={currentIndex}
              onSwitch={handlePreviewPagesSwitch}
              totalCount={filteredAndSortedRows.length}
              isFilterApplied={isFilterApplied()}
            />
          )}
        </View>

        <View style={style.headerOuterContainer}>{renderHeaders(!rows.length)}</View>
        {rows.length ? (
          <>
            {filteredAndSortedRows.length ? (
              
              
              <ScrollView
                contentContainerStyle={{ flex: 1 }}
                style={style.tableScroll}
                onScroll={async ({ nativeEvent }) => {
                  if (isMoreRows && isCloseToBottom(nativeEvent)) {
                    handleShowMore();
                  }
                }}
                scrollEventThrottle={400}
                stickyHeaderIndices={[0]}
              >
                <View style={[style.tableContainer, containerStyle]}>
                  {_.map(
                    avoidPagination
                      ? filteredAndSortedRows
                      : _.slice(filteredAndSortedRows, startCount, endCount),
                    (row: R, rowIndex: number) => {
                      if (!!row.isCollapsed) return <></>;

                      return (
                        <Row
                          key={row.key}
                          row={row}
                          rowIndex={rowIndex}
                          columnIndex={columnIndex}
                          columnWidthArray={columnWidthArray}
                          isMounted={isMounted}
                          columnsDescriptors={
                            columnDescriptors as unknown as TableColumn<TableRow>[] 
                          }
                          onRowPress={
                            onRowPress as (row: TableRow) => void 
                          }
                          rowMenu={
                            rowMenu as ((row: TableRow) => MenuItem[]) | undefined 
                          }
                          menuWidth={props.rowMenuWidth}
                          rowLazyLoadProperties={
                            rowLazyLoadProperties as
                              | ((
                                  row: TableRow,
                                  isMounted: React.MutableRefObject<boolean>,
                                ) => Promise<boolean>)
                              | undefined
                          }
                          filterAndSortRows={filterAndSortRows}
                          setLoadingData={setLoadingData}
                          setLoadingDataCountRef={setLoadingDataCountRef}
                          disableRowClick={
                            disableRowClick as boolean | ((row: TableRow) => boolean) | undefined
                          } 
                          disableHoverEffect={disableHoverEffect}
                        />
                      );
                    },
                  )}
                  {loading ? (
                    <View style={style.footerContainer}>
                      <Text style={style.footerTextStyle}>{t('common:loading.loading')}</Text>
                      <ActivityIndicator color={Colors.Yellow} size="small" />
                    </View>
                  ) : (
                    isMoreRows && (
                      <TextButton
                        text={t('alex:skills.showMore')}
                        onPress={handleShowMore}
                        textStyle={style.footerTextStyle}
                        containerStyle={style.footerContainer}
                      />
                    )
                  )}
                </View>
              </ScrollView>
            ) : (
              <View style={[style.noDataMessageContainer, { height: '40%' }]}>
                <Text style={style.noDataMessageText}>{t('alex:table.noFilteredResults')}</Text>
              </View>
            )}
          </>
        ) : (
          <View style={style.noDataContainer}>
            <View style={style.noDataMessageContainer}>
              <Text style={style.noDataMessageText}>
                {props.noDataMessage ?? t('alex:table.noAvailableData')}
              </Text>
            </View>
          </View>
        )}
      </View>
    </>
  );
};

export const Table = React.memo(
  forwardRef(_Table) as <R extends TableRow>(
    props: React.PropsWithChildren<TableProps<R>> & {
      ref: React.RefObject<TableRef>;
    },
  ) => ReturnType<typeof _Table>,
  (prevProps, nextProps) => {
    const propsAreEqual =
      (prevProps.rows === nextProps.rows || (!prevProps.rows.length && !nextProps.rows.length)) &&
      ((!prevProps.filter && !nextProps.filter) ||
        (!!prevProps.filter &&
          !!nextProps.filter &&
          (prevProps.filter.tags === nextProps.filter.tags ||
            (!prevProps.filter.tags.length && !nextProps.filter.tags.length))));

    if (!propsAreEqual) {
      loggerPerf.debug('Re-Rendering component Table (prevProps, nextProps)', prevProps, nextProps);
    }
    return propsAreEqual;
  },
) as <R extends TableRow>(props: React.PropsWithChildren<TableProps<R>>) => JSX.Element;



const _RenderRow = <R extends TableRow>(props: {
  row: R;
  columnsDescriptors: TableColumn<R>[];
  menu?: (row: R) => MenuItem[];
  lazyLoadProperties?: (row: R, isMounted: React.MutableRefObject<boolean>) => Promise<boolean>;
  rowIndex?: number;
  columnIndex: number;
  columnWidthArray: string[];
}) => {
  const {
    row,
    columnsDescriptors,
    lazyLoadProperties,
    rowIndex: indexOfRow,
    columnWidthArray,
  } = props;
  const isMounted = useIsMounted();
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      if (lazyLoadProperties) {
        if (await lazyLoadProperties(row, isMounted)) {
          if (!isMounted.current) return;
          forceUpdate();
        }
      }
    });
  }, [row]);

  return (
    <>
      {columnsDescriptors.map((eachCol, eachColumnIndex) => (
        <View
          key={eachCol.label + eachColumnIndex}
          style={[
            
            { width: columnWidthArray[eachColumnIndex] },
            style.tableCellPadding,
            { zIndex: -eachColumnIndex },
          ]}
        >
          {eachCol.renderCell(row, eachColumnIndex, indexOfRow)}
        </View>
      ))}
    </>
  );
};
const RenderRow = React.memo(_RenderRow, (prevProps, nextProps) => {
  const propsAreEqual =
    prevProps.row === nextProps.row &&
    prevProps.columnIndex === nextProps.columnIndex &&
    prevProps.columnWidthArray === nextProps.columnWidthArray;
  
  if (!propsAreEqual)
    loggerPerf.debug(
      'Re-Rendering component RenderRow (prevProps, nextProps) ' + prevProps.row.key,
      prevProps,
      nextProps,
    );
  return propsAreEqual;
});

const _Row = <R extends TableRow>(props: {
  row: R;
  rowIndex: number;
  columnsDescriptors: TableColumn<R>[];
  rowMenu: ((row: R) => MenuItem[]) | undefined;
  rowLazyLoadProperties?: (row: R, isMounted: React.MutableRefObject<boolean>) => Promise<boolean>;
  filterAndSortRows: DebouncedFunc<() => void>;
  onRowPress: (row: R) => void;
  menuWidth?: MenuWidth;
  disableRowClick?: boolean | ((row: R) => boolean);
  disableHoverEffect?: boolean;
  columnIndex: number;
  columnWidthArray: string[];
  isMounted: React.MutableRefObject<boolean>;
  setLoadingData: (loading: boolean) => void;
  setLoadingDataCountRef: React.MutableRefObject<number>;
}) => {
  const {
    row,
    rowIndex,
    columnsDescriptors,
    menuWidth,
    disableRowClick,
    disableHoverEffect,
    columnIndex,
    columnWidthArray,
    isMounted,

    setLoadingData,
    setLoadingDataCountRef,
    rowMenu,
    rowLazyLoadProperties,
    filterAndSortRows,
    onRowPress,
  } = props;

  const [isMenuPressed, setIsMenuPressed] = useState<boolean>(false);
  const [disableSingleRow, setDisableSingleRow] = useState<boolean>(false);

  const refCallOnHover = useCallOnHover<TouchableOpacity>(Colors.GreyUltraLight);

  function hideMenu(items: MenuItem[]): boolean {
    return items.every(eachItem => eachItem.disable);
  }

  return (
    <TouchableOpacity
      key={row.key}
      style={[
        style.tableRow,
        {
          zIndex: isMenuPressed ? 999 : -2 - rowIndex,
          justifyContent: 'space-between',
        },
      ]}
      onPress={() => onRowPress(row)}
      disabled={
        disableRowClick && typeof disableRowClick === 'function'
          ? disableRowClick(row)
          : disableRowClick === false
          ? false
          : true
      }
      ref={disableHoverEffect ? undefined : refCallOnHover}
    >
      <View
        style={{
          width: '95%',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <RenderRow
          row={row}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          columnWidthArray={columnWidthArray}
          menu={
            rowMenu as
              | ((row: TableRow) => MenuItem[])
              | undefined 
          }
          columnsDescriptors={
            columnsDescriptors as unknown as TableColumn<TableRow>[] 
          }
          lazyLoadProperties={async (
            row: TableRow,
            isRowMounted: React.MutableRefObject<boolean>,
          ) => {
            let someNewPropertiesLoaded = false;

            if (rowLazyLoadProperties) {
              setLoadingDataCountRef.current = setLoadingDataCountRef.current + 1;
              if (setLoadingDataCountRef.current === 1) {
                setLoadingData(true);
              }

              someNewPropertiesLoaded = await rowLazyLoadProperties(row as R, isRowMounted);
              if (isMounted.current) {
                /**
                 * Filter and sort again as the row has changed some properties.
                 * Eg: In worker table, training column value is calculated using lazy loading, so to sort that column need to wait for computing all the values.
                 * So this debounce filter will help to sort rows batch by batch instead of calling filter each time when the value updated.
                 */
                if (someNewPropertiesLoaded) {
                  filterAndSortRows();
                }
              }
            }

            
            setTimeout(() => {
              setLoadingDataCountRef.current = setLoadingDataCountRef.current - 1;
              if (setLoadingDataCountRef.current === 0) {
                if (isMounted.current) setLoadingData(false);
              }
            }, 1000);

            return someNewPropertiesLoaded;
          }}
        />
      </View>

      <View
        style={{
          width: Spacings.CardPadding * 2,
          zIndex: isMenuPressed ? 999 : -columnsDescriptors.length,
          paddingTop: Spacings.xMedium,
        }}
      >
        {props.rowMenu && !hideMenu(props.rowMenu(row)) && (
          <Menu
            menuItems={props.rowMenu(row)}
            largeThreeDot
            onMenuPress={(value: boolean) => setIsMenuPressed(value)}
            menuWidth={menuWidth}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

const Row = React.memo(_Row, (prevProps, nextProps) => {
  const propsAreEqual =
    prevProps.row === nextProps.row &&
    prevProps.rowIndex === nextProps.rowIndex &&
    prevProps.columnIndex === nextProps.columnIndex &&
    prevProps.columnWidthArray === nextProps.columnWidthArray;
  if (!propsAreEqual)
    loggerPerf.debug(
      'Re-Rendering component Row (prevProps, nextProps) ' + prevProps.row.key,
      prevProps,
      nextProps,
    );
  return propsAreEqual;
});
