import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles, getCorrectedSpacings } from 'shared/styles';
export const scrollViewWidth = 832;
const styles = StyleSheet.create({
  rootContainer: {
    backgroundColor: Colors.BlueRollover,
    flexDirection: 'row',
    height: '100%',
    overflow: 'hidden',
    minHeight: 700,
    minWidth: '100%',
  },
  appContainer: {
    width: 332,
    height: '100%',
    backgroundColor: Colors.White,
    zIndex: 2,
  },
  mobileContainer: {
    height: '100%',
  },
  arcView: {
    alignSelf: 'center',
    height: '20%',
    width: 63,
    transform: [{ scaleY: 6 }],
    borderTopEndRadius: 200,
    borderBottomEndRadius: 200,
    backgroundColor: Colors.White,
  },
  adContainer: {
    flex: 1,
    alignItems: 'center',
    zIndex: 1,
  },
  scrollViewContainer: {
    width: scrollViewWidth,
    maxHeight: 628,
    paddingTop: Spacings.Large + Spacings.Medium, 
    paddingBottom: Spacings.Large + 3,
  },
  scrollViewItem: {
    width: scrollViewWidth,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headertextStyle: {
    ...TextFontStyles.PermanentMarker,
    color: Colors.DarkBlack,
    fontSize: getCorrectedSpacings(30),
    height: 79,
    marginBottom: Spacings.Large + Spacings.Small + 1, 
    textAlign: 'center',
  },
  imageStyle: {
    width: scrollViewWidth,
    height: 412,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollBar: {
    flexDirection: 'row',
  },
  animatedView: {
    height: 10,
    width: 10,
    margin: 8,
    borderRadius: 5,
  },
  photoScrollView: {
    width: '100%',
    alignItems: 'center',
  },
});

export default styles;
