import React, { useContext } from 'react';
import { View } from 'react-native';
import {
  TableColumn,
  Table,
  TableLargeColumnWidth,
  TableMediumColumnWidth,
} from 'shared/ui-component/Table';
import { EllipsisWithTooltip, Position } from 'shared/ui-component/EllipsisWithTooltip';
import { IconSVG } from 'shared/ui-component/Icon';
import * as _ from 'lodash-es';
import { TrainingWorkstationTableRow } from '../container';
import Styles from './Styles';
import { Colors } from 'shared/styles';
import { SortDirection } from '../../../../../sort';
import * as SharedStyles from 'shared/styles';
import { replaceDiacriticsAndCapitalLetter } from 'shared/util-ts/Functions';
import { TouchableOpacityPreventDoubleClick } from 'shared/ui-component/TouchableOpacityPreventDoubleClick';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../../../../navigation/Routes';
import { t } from 'shared/localisation/i18n';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';

interface TrainingWorkstationTable {
  rows: TrainingWorkstationTableRow[];
}

const AddProofSVG = require('shared/assets/svg/icon.workstation.svg').default;

export const TrainingWorkstationTableComponent: React.FC<TrainingWorkstationTable> = props => {
  const history = useHistory();

  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);

  function onWorkstationPress(id: string) {
    const _treeNode = API.Tree.getTreeNode(id);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }

    setTreeNode(_treeNode);
    history.push(RouteLocations.Workstations(id));
  }

  const columnDescriptors: TableColumn<TrainingWorkstationTableRow>[] = [
    {
      label: 'glossary:workstation_plural',
      width: TableLargeColumnWidth,
      renderCell: (eachItem, index, indexOfRow) => (
        <TouchableOpacityPreventDoubleClick
          style={Styles.workstationNameContainer}
          onPress={() => onWorkstationPress(eachItem.key)}
        >
          <IconSVG
            svgComponent={AddProofSVG}
            containerStyle={SharedStyles.Styles.tableObjectIconContainer}
            color={Colors.White}
          />
          <EllipsisWithTooltip
            text={eachItem.workstationOrOrgUnit.name}
            style={Styles.ellipsisText}
            textStyle={SharedStyles.Styles.tableText}
            position={indexOfRow === props.rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
          />
        </TouchableOpacityPreventDoubleClick>
      ),
      sort: sortByName,
    },
    {
      label: 'glossary:organizationalUnit',
      sort: sortByUnit,
      width: TableMediumColumnWidth,
      renderCell: (eachItem, index, indexOfRow) => (
        <EllipsisWithTooltip
          text={eachItem.orgUnit.name}
          textStyle={SharedStyles.Styles.tableText}
          position={indexOfRow === props.rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
        />
      ),
    },
  ];

  function sortByName(
    rows: TrainingWorkstationTableRow[],
    sortDirection: SortDirection,
  ): TrainingWorkstationTableRow[] {
    return _.orderBy(rows, e => replaceDiacriticsAndCapitalLetter(e.workstationOrOrgUnit.name), [
      sortDirection,
    ]);
  }

  function sortByUnit(
    rows: TrainingWorkstationTableRow[],
    sortDirection: SortDirection,
  ): TrainingWorkstationTableRow[] {
    return _.orderBy(rows, e => e.orgUnit.name, [sortDirection]);
  }

  return (
    <View style={{ width: '100%' }}>
      <Table
        columnDescriptors={columnDescriptors}
        rows={props.rows}
        onRowPress={() => {}}
        disableRowClick
        disableHoverEffect
        noDataMessage={t('alex:table.noWorkstationForTraining')}
      />
    </View>
  );
};
