import { ModalUtils } from 'shared/ui-component/Modal';
import { t } from 'shared/localisation/i18n';
import { ModalContext } from 'shared/ui-component/Modal/Modal';
import * as API from 'shared/backend-data';

export enum TrainingSessionWarning {
  ErrorWarningForPractical,
  ErrorWarningForLecture,
  DeleteErrorWarningForPractical,
  DeleteErrorWarningForLecture,
  TrainingSessionDuplicate,
  DeleteWarningForPractical,
  DeleteWarningForPracticalTrainingNotToBePlanned,
  DeleteWarningForLecture,
  DeleteWarningForLectureNotToBePlanned,
  TrainingVersionNotSet,
  ScheduleWarningForNotPractical,
  ScheduleWarningForPractical,
  StartWarningForNotPractical,
  TrainingScheduleErrorForPractical,
  TrainingScheduleErrorForLecture,
  TrainingStartError,
  TrainingWithNoSkillWarning,
}

export function trainingSessionErrorMessage(errorType: TrainingSessionWarning) {
  switch (errorType) {
    case TrainingSessionWarning.DeleteErrorWarningForPractical:
      return t('alex:mobile.practicalTrainingScreen.deleteErrorMessage.0');
    case TrainingSessionWarning.DeleteErrorWarningForLecture:
      return t('alex:mobile.trainingScreen.deleteErrorMessage.0');
    case TrainingSessionWarning.TrainingSessionDuplicate:
      return t('alex:mobile.practicalTrainingScreen.trainingSessionDuplicateError.0');
    case TrainingSessionWarning.DeleteWarningForPracticalTrainingNotToBePlanned:
      return t('alex:mobile.practicalTrainingScreen.deleteTrainingWarning.0');
    case TrainingSessionWarning.DeleteWarningForPractical:
      return t('alex:mobile.practicalTrainingScreen.deleteTrainingWarning.1');
    case TrainingSessionWarning.DeleteWarningForLecture:
      return t('alex:mobile.trainingScreen.deleteTrainingWarning.1');
    case TrainingSessionWarning.DeleteWarningForLectureNotToBePlanned:
      return t('alex:mobile.trainingScreen.deleteTrainingWarning.0');
    case TrainingSessionWarning.ErrorWarningForPractical:
      return t('alex:mobile.practicalTrainingScreen.warningMessage.0');
    case TrainingSessionWarning.ErrorWarningForLecture:
      return t('alex:mobile.trainingScreen.warningMessage.0');
    case TrainingSessionWarning.TrainingVersionNotSet:
      return t('alex:mobile.trainingScreen.trainingVersionNotSetWarning');
    case TrainingSessionWarning.ScheduleWarningForNotPractical:
      return t('alex:mobile.trainingScreen.scheduleWarningForNotPractical');
    case TrainingSessionWarning.ScheduleWarningForPractical:
      return t('alex:mobile.trainingScreen.scheduleWarningForPractical');
    case TrainingSessionWarning.StartWarningForNotPractical:
      return t('alex:mobile.trainingScreen.startWarningForNotPractical');
    case TrainingSessionWarning.TrainingWithNoSkillWarning:
      return t('alex:mobile.trainingScreen.trainingWithNoSkillWarning');
    case TrainingSessionWarning.TrainingScheduleErrorForPractical:
      return t('alex:mobile.trainingScreen.practiceInvalidArguments');
    case TrainingSessionWarning.TrainingStartError:
      return t('alex:TrainingModal.toStartTraining');
    case TrainingSessionWarning.TrainingScheduleErrorForLecture:
      return t('alex:mobile.trainingScreen.lecureInvalidArguments');
  }
}

export function trainingSessionErrorSubMessage(errorType: TrainingSessionWarning) {
  switch (errorType) {
    case TrainingSessionWarning.DeleteErrorWarningForLecture:
    case TrainingSessionWarning.DeleteErrorWarningForPractical:
      return t('alex:mobile.practicalTrainingScreen.deleteErrorMessage.1');
    case TrainingSessionWarning.TrainingSessionDuplicate:
      return t('common:error.retry');
    case TrainingSessionWarning.ErrorWarningForLecture:
    case TrainingSessionWarning.ErrorWarningForPractical:
      return t('alex:mobile.practicalTrainingScreen.warningMessage.1');
  }
}

export async function showTrainingSessionErrorWarning(
  modal: ModalContext,
  errorType: TrainingSessionWarning,
  warningAcceptCallback?: () => void,
  warningCancelCallback?: () => void,
  warningAcceptButton?: string,
  warningCancelButton?: string,
) {
  modal.displayModal(
    ModalUtils.warningConfig({
      warningMessage: trainingSessionErrorMessage(errorType),
      warningSubMessage: trainingSessionErrorSubMessage(errorType),
      warningAcceptCallback: warningAcceptCallback,
      warningCancelCallback: warningCancelCallback,
      warningAcceptButton: warningAcceptButton,
      warningCancelButton: warningCancelButton,
    }),
  );
}

/**
 * Return
 * @param trainingSessionToInspect
 * @returns the traineeIds that have already a trainingSession going on for this trainingSessionToInspect
 */
export async function checkTrainingSessionDuplication(
  trainingSessionToInspect: API.TrainingSession,
): Promise<string[]> {
  const duplicateTraineeIds: string[] = [];

  const trainingSesions = await API.getTrainingSessions();
  if (API.isFailure(trainingSesions)) {
    API.logger.warn('Cannot checkTrainingSessionDuplication', trainingSesions);
    return duplicateTraineeIds;
  }

  const trainingVersionIdToInspect = trainingSessionToInspect.trainingVersionId;
  const trainingIdToInspect = API.getTrainingIdFromTrainingVersionId(trainingVersionIdToInspect);
  for (const trainingSession of trainingSesions) {
    const trainingVersionId = trainingSession.trainingVersionId;
    const trainingId = API.getTrainingIdFromTrainingVersionId(trainingVersionId);
    if (
      trainingIdToInspect === trainingId &&
      trainingSession.id !== trainingSessionToInspect.id &&
      !API.isTrainingSessionEndedOrEndedLate(trainingSession)
    ) {
      trainingSessionToInspect.scheduledTraineeIds?.forEach(scheduledTraineeId => {
        if (trainingSession.scheduledTraineeIds.includes(scheduledTraineeId)) {
          duplicateTraineeIds.push(scheduledTraineeId);
        }
      });
    }
  }
  return duplicateTraineeIds;
}
