import React, { useEffect, useState } from 'react';
import {
  Animated,
  View,
  TouchableOpacity,
  Text,
  InteractionManager,
  ScrollView,
} from 'react-native';
import WebModal from 'modal-react-native-web';
import { ModalBackgroundStyle, ModalCardStyle, ModalHeaderStyle } from 'shared/styles/ModalStyles';
import { IconSVG } from 'shared/ui-component/Icon';
import Styles from '../styles';
import { ProofHeaderTitle } from '../headerText';
import * as API from 'shared/backend-data';
import { t } from 'shared/localisation/i18n';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { AbstractButton } from 'shared/ui-component/Button';
import { Chapter } from 'shared/layout/summaryBook/SummaryBook';
import Aigle from 'aigle';
import * as _ from 'lodash-es';
import { ProofBookType } from '../..';
import { Immutable } from 'shared/util-ts/Functions';

interface Props {
  proofBundle?: API.NoMetadata<API.ProofBundle>;
  proofUploader?: API.Worker;
  chapter?: Chapter;
  proofBookType: ProofBookType | undefined;
  workerIdsWithNotAcquiredProofs: string[];
  skillIdsWithNotAcquiredProofs: string[];

  onAccept: () => void;
  onReject: () => void;
  setProofUploader: React.Dispatch<React.SetStateAction<Immutable<API.Worker> | undefined>>;
  handleModalCloseButton: () => void;
}

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

export const ProofRejectModal: React.FC<Props> = props => {
  const {
    proofBundle,
    proofUploader,
    chapter,
    proofBookType,
    workerIdsWithNotAcquiredProofs,
    skillIdsWithNotAcquiredProofs,

    onAccept,
    onReject,
    handleModalCloseButton,
    setProofUploader,
  } = props;

  const isMounted = useIsMounted();

  const [workerInputListOptions, setWorkerInputListOptions] = useState<TagExtended[]>([]);
  const [workersWithNotAcquiredProofs, setWorkersWithNotAcquiredProofs] = useState<API.Worker[]>(
    [],
  );
  const [skillsWithNotAcquiredProofs, setSkillsWithNotAcquiredProofs] = useState<API.Skill[]>([]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (proofBundle) {
        const workerId = proofBundle.workerId;

        const workers = await getWorkers([workerId]);
        if (!isMounted.current) return;
        if (API.isFailure(workers)) {
          logger.warn(workers);
          return;
        }

        const workerOptions = workers?.map(worker => {
          return {
            key: worker.id,
            label: worker.name,
            value: worker,
          };
        });
        if (workerOptions) setWorkerInputListOptions(workerOptions);
      }
    });
  }, [proofBundle]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (chapter) {
        const workerIds = chapter.trainees.map(trainee => trainee.id);

        const workers = await getWorkers(workerIds);
        if (!isMounted.current) return;
        if (API.isFailure(workers)) {
          logger.warn(workers);
          return;
        }

        const workerOptions = workers?.map(worker => {
          return {
            key: worker.id,
            label: worker.name,
            value: worker,
          };
        });
        if (workerOptions) setWorkerInputListOptions(workerOptions);
      }
    });
  }, [chapter]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const _workersWithNotAcquiredProofs: API.Worker[] = [];
      const _skillsWithNotAcquiredProofs: API.Skill[] = [];

      await Aigle.map(workerIdsWithNotAcquiredProofs, async workerId => {
        const worker = await API.getWorker(workerId);
        if (!isMounted.current) return;
        if (API.isFailure(worker)) {
          logger.warn(worker);
          return;
        }

        _workersWithNotAcquiredProofs.push(worker);
      });

      await Aigle.map(skillIdsWithNotAcquiredProofs, async skillId => {
        const skill = await API.getSkill(skillId);
        if (!isMounted.current) return;
        if (API.isFailure(skill)) {
          logger.warn(skill);
          return;
        }

        _skillsWithNotAcquiredProofs.push(skill);
      });

      setSkillsWithNotAcquiredProofs(_skillsWithNotAcquiredProofs);
      setWorkersWithNotAcquiredProofs(_workersWithNotAcquiredProofs);
    });
  }, [workerIdsWithNotAcquiredProofs, skillIdsWithNotAcquiredProofs]);

  async function getWorkers(workerIds: string[]): Promise<API.Result<API.Worker[] | undefined>> {
    const orgUnits: API.OrganizationalUnit[] = [];

    await Aigle.map(workerIds, async workerId => {
      const workerOrgUnits = await API.getWorkerOrganizationalUnits(workerId, undefined, false);
      if (!isMounted.current) return;
      if (API.isFailure(workerOrgUnits)) {
        logger.warn(workerOrgUnits);
        return;
      }

      orgUnits.push(...workerOrgUnits);
    });

    const _workers = await API.getWorkersInOrganizationalUnits(
      _.uniqBy(orgUnits, 'id').map(workerOrgUnit => workerOrgUnit.id),
      [API.Permission.workersSkillsProof_edit],
      false,
      true,
    );
    if (!isMounted.current) return;
    if (API.isFailure(_workers)) {
      logger.warn(_workers);
      return;
    }

    return _workers.result;
  }

  function handleDropdownChange(inputId: string, value?: TagExtended) {
    if (value) setProofUploader(value.value);
  }

  return (
    <WebModal
      animationType="fade"
      transparent
      visible={true}
      onRequestClose={handleModalCloseButton}
    >
      <Animated.View style={[ModalBackgroundStyle, Styles.rejectModalBackground]}>
        <View style={[ModalCardStyle, Styles.rejectModalContainer]}>
          <View style={[ModalHeaderStyle, Styles.modalHeader]}>
            <TouchableOpacity style={Styles.closeButtonIcon} onPress={handleModalCloseButton}>
              <IconSVG svgComponent={closeIcon} />
            </TouchableOpacity>
            <View style={Styles.headerTitleContainer}>
              <ProofHeaderTitle proofBundle={proofBundle} proofBookType={proofBookType} />
            </View>
          </View>
          <View style={Styles.proofModalContentContainer}>
            <Text style={Styles.proofModalContentWarning}>
              {t('alex:trainingSessions.addTrainingModal.proofResubmitWarning')}
            </Text>
            <ScrollView contentContainerStyle={Styles.workerSkillNameContainer}>
              <Text style={Styles.proofModalNotAcquiredNames}>{`${t('glossary:worker', {
                count: workersWithNotAcquiredProofs.length,
              })}: ${workersWithNotAcquiredProofs
                .map(worker => worker.name)
                .join(API.SeparatorIds)}`}</Text>
              <Text style={Styles.proofModalNotAcquiredNames}>{`${t('glossary:skill', {
                count: skillsWithNotAcquiredProofs.length,
              })}: ${skillsWithNotAcquiredProofs
                .map(skill => skill.name)
                .join(API.SeparatorIds)}`}</Text>
            </ScrollView>
            <DropDownSingleSelection
              placeholder={t('alex:workerSkillReviewModal.chooseWorker')}
              options={workerInputListOptions}
              value={
                proofUploader
                  ? {
                      key: proofUploader.id,
                      label: proofUploader.name,
                    }
                  : undefined
              }
              handleChange={handleDropdownChange}
              hasSearch
            />
          </View>
          <View style={Styles.proofRejectionModalButtonContainer}>
            <AbstractButton
              text={t('common:button.yes')}
              containerStyle={Styles.proofModalContainerButton}
              textStyle={Styles.proofModalContainerBottonText}
              onPress={onAccept}
              disabled={!proofUploader}
            />
            <AbstractButton
              text={t('common:button.no')}
              containerStyle={Styles.proofModalContainerButton}
              textStyle={Styles.proofModalContainerBottonText}
              onPress={onReject}
            />
          </View>
        </View>
      </Animated.View>
    </WebModal>
  );
};
