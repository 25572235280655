import React, { useState, useContext, useEffect } from 'react';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, t, capitalize } from 'shared/localisation/i18n';
import { BannerSkeleton } from '../bannerSkeleton/BannerSkeletonComponent';
import { View, Text, TouchableOpacity, InteractionManager, Image } from 'react-native';
import styles from './styles';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { MenuItem } from 'shared/ui-component/Menu';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { WorkerArchiveORActivateModal } from '../../my-factory/workers/component/WorkerArchiveORActivateModal';
import { AddEditWorkerModalContainer } from '../../my-factory/workers/component/modify-worker-modal/container';
import { renderImage, ImageSizes } from 'shared/util/RenderImage';
import moment from 'moment';
import BannerStyle from '../bannerSkeleton/styles';
import { YellowButton } from 'shared/ui-component/Button';
import { useModal } from 'shared/ui-component/Modal/Modal';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../navigation/Routes';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { TableNumberWithMenu } from 'sharedweb/src/NumberWithMenu';
import { Colors } from 'shared/styles/Colors';
import { Tag } from 'shared/ui-component/Input/InputTag';
import * as _ from 'lodash-es';
import { Spacings } from 'shared/styles';
import { WorkstationOrOrgUnitPath } from 'shared/components/WorkstationOrOrgUnitPath';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { LazyLoadRoles } from './lazyLoadRoles';
import QRCode from 'qrcode';
import { saveAs } from 'file-saver';
import { deleteWorkerWithErrorModalHandling } from 'shared/util/WorkerUi';

interface Props {
  worker: API.Worker;
  workerAssignmentsWithInherited?: Map<API.AssignmentWithUnitDetails, unitsNameAndId[]>;
  workersResponsableOfUnit?: Map<string, API.Worker[]>;

  loadAssignments: () => Promise<void>;
}

export interface unitsNameAndId {
  id: string;
  name: string;
}

interface ArchiveReason {
  reason: string;
  date: string;
}

export const WorkerBanner: React.FC<Props> = props => {
  const {
    worker,
    workerAssignmentsWithInherited,
    workersResponsableOfUnit,

    loadAssignments,
  } = props;

  const isMounted = useIsMounted();

  const [reinstateModal, setReinstateModal] = useState<boolean>(false);
  const [displayWorkerModifyModal, setDisplayWorkerModifyModal] = useState<boolean>(false);
  const [archiveReason, setArchiveReason] = useState<ArchiveReason>();
  const [loading, setLoading] = useState<boolean>(false);
  const [workerTags, setWorkerTags] = useState<Tag[]>([]);
  const [QRCodeImageUrl, setQRCodeImageUrl] = useState<string>();
  const [workerLatestContract, setWorkerLatestContract] = useState<API.ContractAndType>();

  const { isValidPermission } = useContext(PermissionManagementContext);

  const modal = useModal();

  const history = useHistory();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const latestContract = await API.getWorkerLastContract(worker);
      if (!isMounted.current) return;
      if (API.isFailure(latestContract)) {
        logger.warn(latestContract);
        return;
      }
      setWorkerLatestContract(latestContract);
    });
  }, [worker]);

  useEffect(() => {
    if (worker.state === API.WorkerState.ARCHIVED) showArchiveReason();
  }, [worker.state, workerLatestContract]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const formattedTeams = _.compact(
        await Promise.all(
          _.map(worker.tagIds, async tagId => {
            const _workerTag = await API.getWorkerTag(tagId);
            if (!isMounted.current) return;
            if (API.isFailure(_workerTag)) {
              logger.warn('Failed to fetch WorkerTag ', _workerTag);
              return;
            }

            return { label: _workerTag.name, key: _workerTag.id };
          }),
        ),
      );

      if (!isMounted.current) return;
      setWorkerTags(formattedTeams);
    });
  }, [worker.tagIds]);

  async function showArchiveReason(): Promise<void> {
    let reason = '';
    let dateString = '';

    if (workerLatestContract) {
      const startDate = workerLatestContract.startDate;
      const endDate = workerLatestContract.endDate;

      if (workerLatestContract.isPauseContract) {
        reason = workerLatestContract.name;
        if (endDate) {
          dateString = `${t('common:time.of', { date: moment(startDate).format('L') })} ${t(
            'common:time.at',
            { date: moment(endDate).format('L') },
            false,
          )}`;
        } else {
          dateString = `${t('common:time.of', { date: moment(startDate).format('L') })} ${t(
            'common:time.at',
            undefined,
            false,
          )} (${t('common:time.undated', undefined, false)})`;
        }
      } else {
        if (workerLatestContract.endDate && workerLatestContract.endDate < moment().toISOString()) {
          reason = t('alex:workerProfile.contractEnded');
          dateString = moment(workerLatestContract.endDate).format('L');
        }
      }
    }

    setArchiveReason({
      reason,
      date: dateString,
    });
  }

  async function deleteWorker() {
    setLoading(true);

    const _worker = await deleteWorkerWithErrorModalHandling(worker, modal);
    if (!isMounted.current) return;
    if (_worker) {
      setLoading(false);
      history.push(RouteLocations.Workers());
    } else {
      logger.warn(_worker);
      setLoading(false);
    }
  }

  const handleMenuClick = (key: 'edit' | 'archive' | 'reinstate' | 'delete') => async () => {
    if (key === 'archive') {
      setReinstateModal(true);
    } else if (key === 'reinstate') {
      if (!workerLatestContract?.isPauseContract) {
        setDisplayWorkerModifyModal(true);
      } else {
        setReinstateModal(true);
      }
    } else if (key === 'delete') {
      await deleteWorker();
    }
  };

  function workerMenuItems(): MenuItem[] {
    let button = {
      label: 'glossary:workerSuspend',
      onPress: handleMenuClick('archive'),
      disable: !isValidPermission(API.Permission.workers_edit),
    };

    if (worker.state === API.WorkerState.ARCHIVED) {
      button = {
        label: 'alex:worker.addEditWorker.deleteWorker',
        onPress: handleMenuClick('delete'),
        disable: !isValidPermission(API.Permission.workers_edit),
      };
    }

    return [
      {
        label: 'common:button.edit',
        onPress: () => setDisplayWorkerModifyModal(true),
        disable: !isValidPermission(API.Permission.workers_edit),
      },
      { ...button },
    ];
  }

  function generateQRCode() {
    const url = window.location.href;

    QRCode.toDataURL(url)
      .then((url: string) => {
        setQRCodeImageUrl(url);
      })
      .catch((err: string) => {
        logger.info(err);
      });
  }

  function downloadQRCode() {
    if (QRCodeImageUrl) saveAs(QRCodeImageUrl, worker.name);
  }

  return (
    <>
      <BannerSkeleton
        headerIcon={
          <TouchableOpacity
            style={styles.profilePicContainer}
            onPress={() => setDisplayWorkerModifyModal(true)}
            disabled={
              !isValidPermission(
                API.Permission.workers_edit,
                undefined,
                undefined,
                workerAssignmentsWithInherited
                  ? Array.from(workerAssignmentsWithInherited.keys())
                  : undefined,
              )
            }
          >
            {renderImage(worker.profilePicture, ImageSizes.Big, worker.name)}
          </TouchableOpacity>
        }
        headerTitle={capitalizeFirstLetter(worker.name)}
        menu={{
          showMenu: isValidPermission(
            API.Permission.workers_edit,
            undefined,
            undefined,
            workerAssignmentsWithInherited
              ? Array.from(workerAssignmentsWithInherited.keys())
              : undefined,
          ),
          menuItems: workerMenuItems(),
        }}
        subHeaderComponent={
          <View style={{ flexDirection: 'column' }}>
            <Text style={styles.normalText} numberOfLines={3}>
              {workerLatestContract?.startDate &&
                workerLatestContract.name +
                  ', ' +
                  t('common:time.sinceDate', undefined, false) +
                  moment(workerLatestContract.startDate).fromNow(true)}
            </Text>
            <Text style={[styles.normalText, { width: 150 }]}>
              {worker.matricule && t('glossary:workerPersonalId') + ' ' + worker.matricule}
            </Text>
            <Text style={styles.normalText}>{worker.phone}</Text>
          </View>
        }
        bannerContent={{
          component: (
            <View>
              {workerAssignmentsWithInherited &&
                Array.from(workerAssignmentsWithInherited.keys()).map((nonInheritedRole, index) => {
                  const inheritedRoles = workerAssignmentsWithInherited.get(nonInheritedRole);
                  const isWorkerManagerOnUnit = Boolean(
                    nonInheritedRole.permissions.includes(API.Permission.workerIsManager),
                  );
                  const responsibleOfUnit = workersResponsableOfUnit?.get(
                    nonInheritedRole.organizationalUnit.id,
                  );
                  return (
                    <View
                      key={nonInheritedRole.workerId}
                      style={{
                        marginBottom: Spacings.Large,
                        zIndex: 100 - index,
                      }}
                    >
                      <View style={styles.roleContainer}>
                        <Text style={BannerStyle.contentTitle}>
                          <LazyLoadRoles roleIds={[nonInheritedRole.role.id]} />
                        </Text>
                        <Text>{' ' + t('alex:worker.in', undefined, false) + ' '}</Text>
                        <WorkstationOrOrgUnitPath
                          parentIdsWithDataType={[
                            API.DataType.WORKSTATION,
                            nonInheritedRole.organizationalUnit.pathIds,
                          ]}
                          textStyle={{ color: Colors.Black }}
                          containerStyle={styles.unitPathContainer}
                          maxWidth={169}
                        />
                        {nonInheritedRole.organizationalUnit.parentId === 'root' && (
                          <Text style={BannerStyle.contentTitle}>
                            {capitalizeFirstLetter(nonInheritedRole.organizationalUnit.name)}
                          </Text>
                        )}
                      </View>
                      {inheritedRoles && inheritedRoles.length ? (
                        <View style={{ flexDirection: 'row', zIndex: 1 }}>
                          <Text style={styles.inheritedUnitsText}>
                            {t('alex:worker.inheritedOrgUnit', {
                              count: inheritedRoles.length,
                            })}
                          </Text>

                          <TableNumberWithMenu
                            list={inheritedRoles}
                            onMenuItemPress={() => history.push(RouteLocations.Workstations())}
                            count=""
                            persistIcon
                            style={styles.inheritedUnitsList}
                            iconColor={Colors.Black}
                            disabled
                          />
                        </View>
                      ) : (
                        <></>
                      )}
                      {isWorkerManagerOnUnit && (
                        <Text style={styles.inheritedUnitsText}>
                          {t('alex:worker.unitManager')}
                        </Text>
                      )}
                      {responsibleOfUnit ? (
                        responsibleOfUnit.map((responsible, index) => {
                          return (
                            <TouchableOpacity
                              onPress={() =>
                                history.push(RouteLocations.WorkerProfile(responsible.id))
                              }
                              key={responsible.id}
                              style={[styles.managerTextContainer, { zIndex: -index }]}
                            >
                              <Text style={[styles.inheritedUnitsText, { color: Colors.Grey }]}>
                                {t('glossary:manager') + ' '}
                              </Text>
                              <EllipsisWithTooltip
                                text={`${capitalize(responsible.name)}`}
                                textStyle={[styles.inheritedUnitsText, { color: Colors.Grey }]}
                                style={{ width: 120 }}
                              />
                            </TouchableOpacity>
                          );
                        })
                      ) : (
                        <Text style={[styles.inheritedUnitsText, { color: Colors.Grey }]}>
                          {t('alex:worker.noResponsibles')}
                        </Text>
                      )}
                    </View>
                  );
                })}
              {workerTags &&
                workerTags.map(workerTag => {
                  return (
                    <Text
                      key={workerTag.key}
                      style={[styles.inheritedUnitsText, { color: Colors.Grey }]}
                    >
                      {'#' + workerTag.label}
                    </Text>
                  );
                })}
            </View>
          ),
        }}
        bannerFooter={
          <>
            {QRCodeImageUrl && (
              <View style={styles.QRCodeContainer}>
                <Image source={{ uri: QRCodeImageUrl }} style={styles.QRCodeImage} />
              </View>
            )}
            <YellowButton
              style={styles.yellowButton}
              onPress={QRCodeImageUrl ? downloadQRCode : generateQRCode}
              text={t(
                QRCodeImageUrl
                  ? t('common:button.download')
                  : t('alex:workerProfile.generateQRCode'),
              )}
              textStyle={styles.yellowButtonStyle}
            />

            {worker.state === API.WorkerState.ARCHIVED ? (
              <View style={styles.archiveContainer}>
                <View>
                  <Text style={styles.reason}>{archiveReason?.reason}</Text>
                  <Text style={styles.dateString}>{archiveReason?.date}</Text>
                </View>
                <YellowButton
                  style={styles.yellowButton}
                  onPress={handleMenuClick('reinstate')}
                  text={t('glossary:workerReinstate')}
                  textStyle={styles.yellowButtonStyle}
                />
              </View>
            ) : null}
          </>
        }
      />
      {loading && <Loader />}
      {reinstateModal && (
        <WorkerArchiveORActivateModal
          setShowModal={setReinstateModal}
          worker={worker}
          successCallback={() => {
            if (!isMounted.current) return;
            loadAssignments();
          }}
        />
      )}
      {displayWorkerModifyModal && (
        <AddEditWorkerModalContainer
          workerId={worker.id}
          showHideModal={setDisplayWorkerModifyModal}
        />
      )}
    </>
  );
};
