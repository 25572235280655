import React, { useEffect, useState, useContext } from 'react';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import { TouchableOpacity } from 'react-native';
import * as _ from 'lodash-es';
import Aigle from 'aigle';
import { ThumbIcon } from 'shared/ui-component/Icon';
import * as API from 'shared/backend-data';
import { WorkstationModal } from 'skillmgtweb/src/components/my-factory/workstations/component/workstation-modal/container/WorkstationModal';
import { RouteLocations } from '../../navigation/Routes';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { ModalContext, ModalUtils } from 'shared/ui-component/Modal';
import { BannerSkeleton } from '../bannerSkeleton/BannerSkeletonComponent';
import { useHistory } from 'react-router-dom';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { WorkstationTargetCounts } from './WorkstationTargetCounts';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { MyHub } from 'shared/util/MyHub';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { InputShiftsList } from 'shared/components/InputShiftsList';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import {
  deleteWorkstation as _deleteWorkstation,
  deleteWorkstationWarning,
} from 'shared/util/WorkstationUi';

interface Props {
  workstationId: string;
  preSelectedShiftId?: string;
}

export const WorkstationBanner: React.FC<Props> = props => {
  const { workstationId, preSelectedShiftId } = props;

  const modal = ModalUtils.useModal();
  const history = useHistory();
  const isMounted = useIsMounted();

  const [workstationObjectiveActualState, setWorkstationObjectiveActualState] =
    useState<API.WorkstationTargetActual | null>();
  const [isWorkstationModalVisible, setIsWorkstationModalVisible] = useState<boolean>(false);
  const [workstation, setWorkstation] = useState<API.Workstation>();

  const [shiftsData, setShiftsData] = useState<API.Shift[]>();
  const [selectedShift, setSelectedShift] = useState<API.Shift | undefined>();
  const [shiftBadgeStates, setShiftBadgeStates] = useState<Map<string, boolean>>();
  const [thumbStatus, setThumbStatus] = useState<boolean>();

  const [loader, setLoader] = useState<boolean>(false);

  const { isValidPermission } = useContext(PermissionManagementContext);
  const {
    disableFilter: [, setDisableFilter],
  } = useContext(HeaderFilterContext);
  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);
  const {
    treeNode: [treeNode, setTreeNode],
  } = useContext(MenuFactoryContext);

  useEffect(() => {
    fillWorkstationDetails(workstationId);
  }, [workstationId]);

  useEffect(() => {
    handlePreSelectedShift(preSelectedShiftId);
  }, [preSelectedShiftId]);

  useEffect(() => {
    _setWorkstationTargetActual(workstationId);
  }, [workstationId, selectedShift]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (workstation) {
        if (
          data.factory.dataType === API.DataType.WORKSTATION &&
          (data.tooManyMutations || data.factory.workstation.id === workstation.id)
        ) {
          fillWorkstationDetails(workstation.id);
        } else if (
          data.factory.dataType === API.DataType.ORGUNIT &&
          (data.tooManyMutations || data.factory.organizationalUnit.id === workstation?.parentId)
        ) {
          fillWorkstationDetails(workstation.id);
        } else if (
          data.factory.dataType === API.DataType.SHIFT &&
          (data.tooManyMutations || data.factory.shift.id === selectedShift?.id)
        ) {
          fillWorkstationDetails(workstation.id);
        }
      }
    });

    setDisableFilter(true);

    return () => {
      setDisableFilter(false);
      removeListener();
    };
  }, [workstation, selectedShift]);

  useEffect(() => {
    getThumbIconStatus();
  }, [
    workstation,
    selectedShift,
    workstationObjectiveActualState,
    workstationTargetsStartingAtLevel,
  ]);

  async function fillWorkstationDetails(workstationId: string) {
    const workstationNewData = await API.getWorkstation(workstationId);
    if (!isMounted.current) return;
    if (API.isFailure(workstationNewData)) {
      logger.warn(workstationNewData);
      return;
    }

    await _setShiftsData(workstationNewData);

    setWorkstation({ ...workstationNewData });
    _setWorkstationTargetActual(workstationNewData.id);
  }

  async function _setWorkstationTargetActual(workstationId: string) {
    const workstationNewData = await API.getWorkstation(workstationId);
    if (!isMounted.current) return;
    if (API.isFailure(workstationNewData)) {
      logger.warn(workstationNewData);
      return;
    }
    setWorkstationTargetActual(workstationNewData, selectedShift ? selectedShift.id : undefined);
  }

  async function handlePreSelectedShift(preSelectedShiftId?: string): Promise<API.Result<void>> {
    if (!preSelectedShiftId) return;
    if (selectedShift && selectedShift.id === preSelectedShiftId) return;

    const shift = await API.getShift(preSelectedShiftId);
    if (API.isFailure(shift)) return shift;

    setSelectedShift(shift);
  }

  async function setWorkstationTargetActual(workstation: API.Workstation, shiftId?: string) {
    const result = await API.fetchWorkstationTargetAndWorkersAlert(
      workstation,
      workstationTargetsStartingAtLevel,
      shiftId ? [shiftId] : undefined,
    );
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      logger.warn(result);
      return;
    }

    if (result.workstationsTargetAndActual) {
      const workstationTargetAndActual = shiftId
        ? result.workstationsTargetAndActual[shiftId]
        : result.workstationsTargetAndActual[workstation.id];

      setWorkstationObjectiveActualState({ ...workstationTargetAndActual });
    }
  }

  async function _setShiftsData(workstation: API.Workstation): Promise<void> {
    if (!workstation.shiftIds) {
      setShiftsData(undefined);
      setShiftBadgeStates(undefined);
      setSelectedShift(undefined);

      return;
    }

    const _shifts: API.Shift[] = [];
    const _shiftBadgeStates: Map<string, boolean> = new Map();
    let _selectedShift: API.Shift | undefined;

    await Aigle.map(workstation.shiftIds, async shiftId => {
      const shift = await API.getShift(shiftId);
      if (API.isFailure(shift)) {
        logger.warn(shift);
        return;
      }

      _shifts.push(shift);
      if (!_selectedShift) _selectedShift = selectedShift ?? shift;

      const result = await API.fetchWorkstationTargetAndWorkersAlert(
        workstation,
        workstationTargetsStartingAtLevel,
        [shiftId],
      );
      if (!isMounted.current) return;
      if (API.isFailure(result)) {
        logger.warn(result);
        return;
      }

      const _isWorkstationLevelTargetReached = await API.isWorkstationLevelTargetReached(
        result.workstationsTargetAndActual[shiftId],
        workstationTargetsStartingAtLevel,
        workstation.id,
        shiftId,
      );
      if (API.isFailure(_isWorkstationLevelTargetReached)) return _isWorkstationLevelTargetReached;

      _shiftBadgeStates.set(shiftId, _isWorkstationLevelTargetReached);
    });

    setShiftsData(_shifts);
    setShiftBadgeStates(_shiftBadgeStates);

    setSelectedShift(_selectedShift);
  }

  async function deleteWorkstation(workstationId: string, modal: ModalContext) {
    setLoader(true);

    const deleteWorkstation = await _deleteWorkstation(workstationId, modal, undefined, () =>
      history.push(RouteLocations.Workstations()),
    );
    if (!isMounted.current) return;
    if (API.isFailure(deleteWorkstation)) {
      logger.warn(deleteWorkstation);
      setLoader(false);
      return;
    }

    setLoader(false);
  }

  function handleDeleteWorkstation() {
    if (workstation) {
      deleteWorkstationWarning(
        modal,
        () => deleteWorkstation(workstation.id, modal),
        workstation.name,
      );
    }
  }

  async function handleSelectShift(shiftId: string): Promise<API.Result<void>> {
    if (!shiftsData) return;

    if (workstation) {
      setWorkstationTargetActual(workstation, shiftId);
    } else {
      const workstationNewData = await API.getWorkstation(workstationId);
      if (!isMounted.current) return;
      if (API.isFailure(workstationNewData)) {
        logger.warn(workstationNewData);
        return;
      }
    }

    const _selectedShift = shiftsData.find(shift => shift.id === shiftId);
    if (_selectedShift) setSelectedShift(_selectedShift);
  }

  function onBackPress() {
    if (workstation) {
      const parentTreeNode = API.Tree.getTreeNode(workstation.parentId);
      if (API.isFailure(parentTreeNode)) return parentTreeNode;

      setTreeNode(parentTreeNode);
    }
  }

  async function getThumbIconStatus() {
    if (workstationObjectiveActualState) {
      const _isWorkstationLevelTargetReached = await API.isWorkstationLevelTargetReached(
        workstationObjectiveActualState,
        workstationTargetsStartingAtLevel,
        workstationId,
        selectedShift?.id,
      );
      if (API.isFailure(_isWorkstationLevelTargetReached)) return _isWorkstationLevelTargetReached;

      setThumbStatus(_isWorkstationLevelTargetReached);
    }
  }

  return (
    <>
      {isWorkstationModalVisible && (
        <WorkstationModal
          modalVisible={isWorkstationModalVisible}
          selectedWorkstation={workstation}
          initialOrgUnitId={workstation?.parentId ?? ''}
          refreshWorkstations={async () => {}}
          handleModalClose={() => setIsWorkstationModalVisible(false)}
          setSelectedWorkstation={() => {}}
          workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
        />
      )}

      <BannerSkeleton
        loader={!workstation}
        onBackPress={onBackPress}
        menu={{
          showMenu: isValidPermission(API.Permission.workstations_edit, treeNode),
          menuItems: [
            {
              label: t('common:button.edit'),
              onPress: () => {
                setIsWorkstationModalVisible(true);
              },
            },
            {
              label: t('common:button.delete'),
              onPress: handleDeleteWorkstation,
            },
          ],
        }}
        headerIcon={
          <TouchableOpacity
            onPress={() => setIsWorkstationModalVisible(true)}
            disabled={!isValidPermission(API.Permission.workstations_edit, treeNode)}
          >
            <ThumbIcon
              type={'header'}
              status={workstationObjectiveActualState ? thumbStatus : '...'}
            />
          </TouchableOpacity>
        }
        headerTitle={capitalizeFirstLetter(workstation?.name ?? '')}
        bannerContent={{
          removeScrollForComponent: true,
          component: (
            <>
              {shiftsData && shiftBadgeStates && selectedShift && (
                <InputShiftsList
                  shiftIds={[...shiftsData?.map(shift => shift.id)]}
                  handleSelection={handleSelectShift}
                  selectedShiftIds={selectedShift?.id ? [selectedShift.id] : []}
                  shiftBadgeStates={shiftBadgeStates}
                />
              )}

              {workstation && (
                <WorkstationTargetCounts
                  workstations={[workstation]}
                  needIdealCount
                  selectedShift={selectedShift}
                  workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
                />
              )}
            </>
          ),
        }}
        files={workstation?.files}
      />
      {loader && <Loader />}
    </>
  );
};
