import React, { useEffect, useRef, useState } from 'react';
import { InteractionManager, Platform } from 'react-native';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import Aigle from 'aigle';
import * as _ from 'lodash-es';

import { ModalUtils } from 'shared/ui-component/Modal';
import { t } from 'shared/localisation/i18n';
import { MyHub } from 'shared/util/MyHub';
import { Chapter } from 'shared/layout/summaryBook/SummaryBook';
import {
  getTrainingSessionProofBook,
  Page,
  handleAutoCertificate,
  submitProofBookToReview,
  getWorkerSkillProofBook,
  validateProofBook,
  updateOrCreateProofBookProofBundle,
  getTrainingVersionProofBook,
  computeProofBook,
  getChapters,
  isChapterContainsGhostWorker,
} from 'shared/layout/summaryBook/functions';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import { Immutable } from 'shared/util-ts/Functions';

interface ProofBookContainer {
  workersPages: Map<string, Page[]>;
  totalNumberOfSkills: number;
  isProofBookCompleted: boolean;
  isTrainingSessionPractical: boolean;
  totalNumberOfTrainees: number;
  disableProofValidation?: boolean;
  isProofBookSubmitted: boolean;
  isProofBookValidated: boolean;
  workerSkill?: API.WorkerSkill;
  proofBundle?: API.NoMetadata<API.ProofBundle>;
  proofBookType?: ProofBookType;
  showSkillPageValidateReviewModal: boolean;
  allowPartialSubmission: boolean;
  trainingSessionsOfWorker?: API.TrainingSession[];
  trainingSessionId?: string;
  chapter: Chapter | undefined;
  chapters: Chapter[];
  showCommonAddProofSection: boolean;
  isChapterContainsGhostTrainee: boolean;
  currentPageIndex: number;
  oneWorkerAndOneSkill: boolean;
  proofRejectModalProps?: ProofRejectModalProps;
  workerSkillModalProps?: WorkerSkillModalProps;
  loader: boolean;

  setChapter: React.Dispatch<React.SetStateAction<Chapter | undefined>>;
  onPageSwitch(index: number): void;
  handleSummaryButton(): void;
  handleChapterAddition(chapter: Chapter): void;
  handleDeleteChoosenFiles(chapter: Chapter): void;
  handleProofs(chapter: Chapter, files?: File[]): void;
  onSkillPress(page: Page): Promise<void>;
  onTraineePress(traineeId: string): void;
  onCommonProofPress(): void;
  updateTraningBookData: (chapter: Chapter, files?: File[], trainingVersionId?: string) => void;
  deleteProofBookChapter: (chapter: Chapter) => void;
  handleAutoCertificate: (chapter: Chapter) => void;
  handleSubmitToReview: (chapter?: Chapter, chapters?: Chapter[]) => void;
  onSelectProof: (proof: Tag) => void;
  handleAddWorkerSkillModal: (revoke: boolean) => void;
  handleSubmitAgainProof: () => void;
  deleteWorkerSkill(): Promise<void>;
  handleModalCloseButton(): void;
  handleSkillPageValidateCloseButton: () => void;
  onSelectTrainingSession: (trainingSession: Tag) => void;
}

interface Props {
  trainingSessionId?: string;
  proofBundleId?: string;
  disableProofAddition?: boolean;
  workerIds?: string[];
  skillIds?: string[];
  trainingVersionId?: string;
  allowPartialSubmission?: boolean;
  modalAutoClose?: boolean;
  trainingSessionsOfWorker?: API.TrainingSession[];
  trainingSessionWorkers?: string[];
  proofAlreadyValidatedWorkersAndSkills?: {
    workerIds: string[];
    skillIds: string[];
  };
  hasWorkerSkillReviewPermission: boolean;

  closeModalTrigger?: () => void;
  setShowAddTrainingProofModal: React.Dispatch<React.SetStateAction<boolean>>;
  proofBookComponent: React.FC<ProofBookContainer>;
}

export interface SummaryDetails {
  skillId: string;
  files?: File[];
  proofBundle?: API.NoMetadata<API.ProofBundle>;
  isObtained?: boolean;
  worker?: API.Worker;
}

export enum AddFileActionsSkillWeb {
  ChooseFile = 'ChooseFile',
  GenerateProof = 'GenerateProof',
  SignProof = 'SignProof',
}

export enum ProofBookType {
  SkillBook,
  TrainingBook,
}

export interface ProofRejectModalProps {
  showProofRejectModal: boolean;
  workerIdsWithNotAcquiredProofs: string[];
  skillIdsWithNotAcquiredProofs: string[];
  proofUploader?: API.Worker;

  setProofUploader: React.Dispatch<React.SetStateAction<Immutable<API.Worker> | undefined>>;
  handleRejectModalProofSubmit: (chapter?: Chapter, resubmit?: boolean) => void;
  handleProofRejectModalCloseButton: () => void;
}

export interface WorkerSkillModalProps {
  showAddWorkerSkillModal: boolean;
  skill?: API.Skill;
  worker?: API.Worker;
  showAddWorkerSkillModalRevoke: boolean;
  renewProof: boolean;
  submitProofAgain: boolean;

  handleModalClose: () => void;
  setShowAddTrainingProofModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToastDuration = 2000;


export const ProofBookContainer: React.FC<Props> = props => {
  const {
    proofBundleId,
    workerIds,
    skillIds,
    trainingSessionsOfWorker,
    trainingVersionId: _trainingVersionId,
    trainingSessionId: _trainingSessionId,
    allowPartialSubmission = true,
    modalAutoClose = true,
    trainingSessionWorkers,
    proofAlreadyValidatedWorkersAndSkills,
    proofBookComponent: ProofBookComponent,
    hasWorkerSkillReviewPermission,

    closeModalTrigger,
    setShowAddTrainingProofModal,
  } = props;

  const modal = ModalUtils.useModal();

  const isWebPlatform = Platform.OS === 'web';

  const [workersPages, setWorkersPages] = useState<Map<string, Page[]>>(new Map());
  const [totalNumberOfSkills, setTotalNumberOfSkills] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [isProofBookCompleted, setIsProofBookCompleted] = useState<boolean>(false);
  const [isProofBookSubmitted, setIsProofBookSubmitted] = useState<boolean>(false);
  const [isProofBookValidated, setIsProofBookValidated] = useState<boolean>(false);
  const [isTrainingSessionPractical, setIsTrainingSessionPractical] = useState<boolean>(false);
  const [totalNumberOfTrainees, setTotalNumberOfTrainees] = useState<number>(0);
  const [trainingSession, setTrainingSession] = useState<API.TrainingSession>(); 
  const [trainingSessionId, setTrainingSessionId] = useState<string>();
  const [trainingVersionId, setTrainingVersionId] = useState<string>();
  const [workerSkill, setWorkerSkill] = useState<API.WorkerSkill>();
  const [worker, setWorker] = useState<API.Worker>();
  const [skill, setSkill] = useState<API.Skill>();
  const [proofBundle, setProofBundle] = useState<API.NoMetadata<API.ProofBundle>>();
  const [showAddWorkerSkillModalRevoke, setShowAddWorkerSkillModalRevoke] =
    useState<boolean>(false);
  const [showAddWorkerSkillModal, setShowAddWorkerSkillModal] = useState<boolean>(false);
  const [renewProof, setRenewProof] = useState<boolean>(false);
  const [submitProofAgain, setSubmitProofAgain] = useState<boolean>(false);
  const [warningMenuOpen, setWarningMenuOpen] = useState<boolean>(false);
  const [proofUploader, setProofUploader] = useState<API.Worker>();
  const [showProofRejectModal, setShowProofRejectModal] = useState<boolean>(false);
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [chapter, setChapter] = useState<Chapter>();
  const [proofBookType, setProofBookType] = useState<ProofBookType>();
  const [proofValidatedWorkers, setProofValidatedWorkers] = useState<string[]>([]);
  const [proofValidatedSkills, setProofValidatedSkills] = useState<string[]>([]);
  const [showSkillPageValidateReviewModal, setShowSkillPageValidateReviewModal] =
    useState<boolean>(false);
  const [clickedOnValidationButton, setClickedOnValidationButton] = useState<boolean>(false);
  const [workerIdsWithNotAcquiredProofs, setWorkerIdsWithNotAcquiredProofs] = useState<string[]>(
    [],
  );
  const [skillIdsWithNotAcquiredProofs, setSkillIdsWithNotAcquiredProofs] = useState<string[]>([]);
  const [
    trainingSessionIdForTrainingVersionProofBundle,
    setTrainingSessionIdForTrainingVersionProofBundle,
  ] = useState<string>();
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(-1);
  const [oneWorkerAndOneSkill, setOneWorkerAndOneSkill] = useState<boolean>(false);
  const [showCommonAddProofSection, setShowCommonAddProofSection] = useState<boolean>(true);
  const [isChapterContainsGhostTrainee, setIsChapterContainsGhostTrainee] =
    useState<boolean>(false);
  const [skillIdsIncludingSubSkills, setSkillIdsIncludingSubSkills] = useState<string[]>();

  const isMounted = useIsMounted();
  const currentPageIndexRef = useRef<number>(-1);

  
  
  const chapterRef = useRef<Chapter>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (!skillIds) return;

      const _skills: API.Skill[] = [];

      const failure = await API.mapSeries(skillIds, async _skillId => {
        const _skill = await API.getSkill(_skillId);
        if (API.isFailure(_skill)) return _skill;

        _skills.push(_skill);
      });
      if (!isMounted.current) return;
      if (API.isFailure(failure)) return failure;

      const skillsAndSubSkillsIds = await API.getSkillsAndSubSkillsIds(_skills);
      if (!isMounted.current) return;
      if (API.isFailure(skillsAndSubSkillsIds)) return skillsAndSubSkillsIds;

      setSkillIdsIncludingSubSkills([
        ...Array.from(skillsAndSubSkillsIds.skillIds),
        ...Array.from(skillsAndSubSkillsIds.skillGroupIds),
      ]);
    });
  }, [skillIds]);

  useEffect(() => {
    setTrainingSessionId(_trainingSessionId);
  }, [_trainingSessionId]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      
      if (trainingVersionId && workerIds) {
        const date = new Date().toISOString();
        const _trainingSession = await API.createTrainingSession({
          originId: trainingVersionId,
          traineeIds: workerIds,
          scheduledTraineeIds: workerIds,
          scheduledTrainers: [{ trainerId: API.externalTrainerWorkerId, percentage: '100%' }],
          trainers: [{ trainerId: API.externalTrainerWorkerId, percentage: '100%' }],
          scheduledStartDate: date,
          startDate: date,
          endDate: date,
          trainingVersionId: trainingVersionId,
          requestState: API.ReviewState.VALIDATED,
        });
        if (!isMounted.current) return;
        if (API.isFailure(_trainingSession)) {
          logger.warn(_trainingSession);
          return;
        }

        setTrainingSessionIdForTrainingVersionProofBundle(_trainingSession.id);
      }
    });
  }, [trainingVersionId, workerIds]);

  useEffect(() => {
    setTrainingVersionId(_trainingVersionId);

    if (_trainingVersionId) setProofBookType(ProofBookType.TrainingBook);
  }, [_trainingVersionId]);

  useEffect(() => {
    if (proofBundleId) getProofBundleDetails(proofBundleId);
  }, [proofBundleId]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.PROOFBUNDLE &&
        (data.tooManyMutations || !!workersPages.get(data.factory.proofBundle.workerId))
      ) {
        if (trainingSessionId) {
          fetchTrainingSessionDetails(trainingSessionId, undefined, true);
        } else if (proofBundle) {
          fetchProofBundle(proofBundle);
        }
      }
    });

    return () => {
      removeListener();
    };
  }, [trainingSessionId, workersPages, trainingVersionId, proofBundle]);

  useEffect(() => {
    if (trainingSessionId) {
      fetchTrainingSessionDetails(trainingSessionId, trainingSessionWorkers);
      setProofBookType(ProofBookType.TrainingBook);
    }
  }, [trainingSessionId, trainingSessionWorkers]);

  useEffect(() => {
    if (proofBundle) {
      fetchProofBundle(proofBundle);
    }
  }, [proofBundle]);

  useEffect(() => {
    if (clickedOnValidationButton) {
      checkForModalClose();
    }
  }, [workersPages, clickedOnValidationButton]);

  
  useEffect(() => {
    if (workerIds && skillIds) {
      const _proofValidatedWorkers = proofAlreadyValidatedWorkersAndSkills
        ? proofAlreadyValidatedWorkersAndSkills.workerIds
        : proofValidatedWorkers;
      const _proofValidatedSkills = proofAlreadyValidatedWorkersAndSkills
        ? proofAlreadyValidatedWorkersAndSkills.skillIds
        : proofValidatedSkills;
      createProofBookFromWorkersAndSkills(
        workerIds,
        skillIds,
        _proofValidatedWorkers,
        _proofValidatedSkills,
      );
    }
  }, [workerIds, skillIds, proofAlreadyValidatedWorkersAndSkills]);

  
  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (isProofBookSubmitted && trainingSession && !trainingSession.endDate) {
        const endedTrainingSession = await API.endTrainingSession(API.deepClone(trainingSession));
        if (API.isFailure(endedTrainingSession)) {
          logger.warn('Failed to end the training session', endedTrainingSession);
          return endedTrainingSession;
        }
      }
    });
  }, [isProofBookSubmitted, trainingSession]);

  
  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.PROOFBUNDLE &&
        (data.tooManyMutations ||
          (workerIds?.includes(data.factory.proofBundle.workerId) &&
            skillIdsIncludingSubSkills?.includes(data.factory.proofBundle.skillId)))
      ) {
        if (!data.tooManyMutations) {
          updateProofData({
            ...data.factory.proofBundle,

            updatedAt: data.factory.updatedAt,
            updatedBy: data.factory.updatedBy,
          });
        } else if (workerIds && skillIds) {
          createProofBookFromWorkersAndSkills(
            workerIds,
            skillIds,
            proofValidatedWorkers,
            proofValidatedSkills,
          );
        }
      }
    });

    return () => {
      removeListener();
    };
  }, [
    workerIds,
    skillIds,
    proofValidatedWorkers,
    proofValidatedSkills,
    workersPages,
    skillIdsIncludingSubSkills,
  ]);

  useEffect(() => {
    fillChapters();
  }, [workersPages, totalNumberOfSkills, totalNumberOfTrainees]);

  useEffect(() => {
    if (totalNumberOfTrainees === 1 && totalNumberOfSkills === 1) {
      if (!Object.keys(chapter || {}).length) {
        onCommonProofPress();
      }
      setOneWorkerAndOneSkill(true);
    }
  }, [totalNumberOfTrainees, totalNumberOfSkills, chapter, chapters]);

  useEffect(() => {
    if (chapter) setIsChapterContainsGhostTrainee(isChapterContainsGhostWorker(chapter));
  }, [chapter]);

  function handleProofs(chapter: Chapter, files?: File[] | Blob[]) {
    updateTraningBookData(chapter, files);
  }

  function handleDeleteChoosenFiles(chapter: Chapter) {
    deleteProofBookChapter(chapter);
  }

  function onTraineePress(traineeId: string) {
    const index = chapters.findIndex(
      chapter => chapter.trainees.length === 1 && chapter.trainees[0].id === traineeId,
    );
    if (index > -1) {
      setChapter(chapters[index]);
      chapterRef.current = chapters[index];
      setCurrentPageIndex(index);
      currentPageIndexRef.current = index;
      setShowCommonAddProofSection(false);
    }
  }

  function handleSummaryButton() {
    setShowCommonAddProofSection(true);
    setChapter(chapters[0]);
    chapterRef.current = chapters[0];
    setCurrentPageIndex(-1);
    currentPageIndexRef.current = -1;
  }

  async function onSkillPress(page: Page) {
    const index = chapters.findIndex(
      chapter =>
        chapter.skills.length === 1 &&
        chapter.trainees.length === 1 &&
        chapter.skills[0].id === page.skill.id &&
        chapter.trainees[0].id === page.worker.id,
    );
    if (index > -1) {
      setChapter(chapters[index]);
      chapterRef.current = chapters[index];
      setCurrentPageIndex(index);
      currentPageIndexRef.current = index;
      setShowCommonAddProofSection(false);
    }
  }

  function onPageSwitch(index: number) {
    if (index !== -1) {
      setChapter(chapters[index]);
      chapterRef.current = chapters[index];
      setShowCommonAddProofSection(false);
      setCurrentPageIndex(index);
      currentPageIndexRef.current = index;
    }
  }

  function getProofBundlesCommonFiles(proofBundles: API.NoMetadata<API.ProofBundle>[]): string[] {
    const files = proofBundles.map(eachProofBundle =>
      eachProofBundle.files.map(eachFile => eachFile.key),
    );

    const result = (files.shift() || []).filter(file =>
      files.every(_files => _files.indexOf(file) !== -1),
    );

    return result;
  }

  function handleChapterAddition(chapter: Chapter) {
    const _chapters = [...chapters];
    let chaptersToAdd = 1;
    if (chapter.proofBundles) {
      if (chapter.proofBundles.length === 1 && chapter.proofBundles[0].files.length) {
        chaptersToAdd = chapter.proofBundles[0].files.length;

        const index = chapter.proofBundles[0].files.findIndex(file => {
          if (chapter.file && chapter.file[0]) return file.key === chapter.file[0].key;
        });

        if (index > -1) {
          chaptersToAdd = chaptersToAdd - index;
        }
      } else {
        const commonFiles = getProofBundlesCommonFiles(chapter.proofBundles);
        chaptersToAdd = commonFiles.length;

        const index = commonFiles.findIndex(file =>
          chapter.file?.some(eachFile => eachFile.key === file),
        );

        if (index > -1) {
          chaptersToAdd = chaptersToAdd - index;
        }
      }
    }

    _chapters.splice(currentPageIndex + chaptersToAdd, 0, {
      ...chapter,
      file: undefined,
    });

    setChapter(_chapters[currentPageIndex + chaptersToAdd]);
    chapterRef.current = _chapters[currentPageIndex + chaptersToAdd];
    setChapters([..._chapters]);
    setCurrentPageIndex(currentPageIndex + chaptersToAdd);
    currentPageIndexRef.current = currentPageIndex + chaptersToAdd;
  }

  function onCommonProofPress() {
    if (chapters[0]) {
      if (chapters[0].file && !chapters[0].isChapterCompleted) {
        const _chapters = [...chapters];

        _chapters.splice(0, 0, {
          ...chapters[0],
          file: undefined,
        });

        setChapters([..._chapters]);
        setChapter({ ..._chapters[0] });
        chapterRef.current = { ..._chapters[0] };
      } else {
        setChapter({ ...chapters[0] });
        chapterRef.current = { ...chapters[0] };
      }

      setCurrentPageIndex(0);
      currentPageIndexRef.current = 0;
    }

    setShowCommonAddProofSection(false);
  }

  async function fillChapters() {
    const _chapters = await getChapters(workersPages, totalNumberOfSkills, totalNumberOfTrainees);
    if (!isMounted.current) return;
    if (API.isFailure(_chapters)) {
      logger.warn(_chapters);
      return;
    }

    setChapters([..._chapters]);

    if (currentPageIndexRef.current > -1) {
      if (_chapters[currentPageIndexRef.current] && _chapters[currentPageIndexRef.current].skills) {
        setChapter({ ..._chapters[currentPageIndexRef.current] });
        chapterRef.current = { ..._chapters[currentPageIndexRef.current] };
      } else {
        setChapter({ ..._chapters[currentPageIndexRef.current - 1] });
        chapterRef.current = { ..._chapters[currentPageIndexRef.current - 1] };
      }
    } else {
      setChapter(_chapters[0]);
      chapterRef.current = _chapters[0];
    }
  }

  async function updateProofData(proofBundle: API.ProofBundle) {
    const workerId = proofBundle.workerId;
    const skillId = proofBundle.skillId;

    const pages = workersPages.get(workerId);
    if (pages) {
      const index = pages?.findIndex(page => page.skill.id === skillId);
      if (index !== undefined && index > -1) {
        pages[index].proofBundle = proofBundle;
      }
    }

    const proofBundles: API.ProofBundle[] = [];
    Array.from(workersPages.values()).forEach(pages => {
      pages.forEach(page => {
        if (page.proofBundle) proofBundles.push(page.proofBundle);
      });
    });

    if (workerIds && skillIds) {
      const computedProofBook = await computeProofBook(workerIds, skillIds, proofBundles, false);
      if (API.isFailure(computedProofBook)) {
        logger.warn('Failed while fetching computedProofBook', computedProofBook);
        setLoader(false);
        return computedProofBook;
      }

      setWorkersPages(computedProofBook.workersPages);
      setIsProofBookCompleted(computedProofBook.isProofBookCompleted);
      setIsProofBookSubmitted(computedProofBook.isProofBookSubmitted);
      setIsProofBookValidated(computedProofBook.isProofBookValidated);
      setTotalNumberOfSkills(skillIds.length);
      setTotalNumberOfTrainees(workerIds.length);
    }
  }

  async function getProofBundleDetails(proofBundleId: string) {
    const _proofBundle = await API.getProofBundle(proofBundleId);
    if (!isMounted.current) return;
    if (API.isFailure(_proofBundle)) {
      logger.warn(_proofBundle);
      return;
    }

    getProofUploader(_proofBundle);
    setProofBundle(_proofBundle);
  }

  function checkForModalClose() {
    let needToCloseTheModal = true;

    Array.from(workersPages.entries()).forEach(([workerId, proofBooks]) => {
      proofBooks.forEach(page => {
        if (!page.isProofBundleValidated) {
          needToCloseTheModal = false;
        }
      });
    });

    if (needToCloseTheModal) handleCloseModal();
  }

  async function createProofBookFromWorkersAndSkills(
    workerIds: string[],
    skillIds: string[],
    proofValidatedWorkers: string[],
    proofValidatedSkills: string[],
  ) {
    setLoader(true);

    const proofBook = await getWorkerSkillProofBook(
      skillIds,
      workerIds,
      proofValidatedWorkers,
      proofValidatedSkills,
    );
    if (!isMounted.current) return;
    if (API.isFailure(proofBook)) {
      setLoader(false);
      logger.warn('Failed while fetching proof book details', proofBook);
      return;
    }

    setWorkersPages(proofBook.workersPages);
    setIsProofBookCompleted(proofBook.isProofBookCompleted);
    setIsProofBookSubmitted(proofBook.isProofBookSubmitted);
    setIsProofBookValidated(proofBook.isProofBookValidated);
    setTotalNumberOfSkills(skillIds.length);
    setTotalNumberOfTrainees(workerIds.length);

    setLoader(false);
  }

  
  async function getProofUploader(proofBundle?: API.NoMetadata<API.ProofBundle>): Promise<void> {
    if (proofBundle?.review.workerId) {
      const _proofUploader = await API.getWorker(proofBundle.review.workerId);
      if (!isMounted.current) return;
      if (API.isFailure(_proofUploader)) {
        logger.warn(_proofUploader);
        return;
      }

      setProofUploader(_proofUploader);
    }
  }

  async function fetchProofBundle(proofBundle: API.NoMetadata<API.ProofBundle>) {
    const dataType = API.getDataType(proofBundle.originObjectId);
    if (API.isFailure(dataType)) {
      logger.error(dataType);
    }

    if (
      dataType === API.DataType.TRAININGSESSION &&
      proofBundle.review.state !== API.ReviewState.VALIDATED &&
      proofBundle.review.state !== API.ReviewState.REJECTED &&
      proofBundle.review.state !== API.ReviewState.REJECTED_TO_RESUBMIT
    ) {
      setProofBookType(ProofBookType.TrainingBook);
      fetchTrainingSessionDetails(proofBundle.originObjectId);
      setTrainingSessionId(proofBundle.originObjectId);
    } else if (
      dataType === API.DataType.TRAININGVERSION &&
      proofBundle.review.state !== API.ReviewState.VALIDATED &&
      proofBundle.review.state !== API.ReviewState.REJECTED &&
      proofBundle.review.state !== API.ReviewState.REJECTED_TO_RESUBMIT
    ) {
      setProofBookType(ProofBookType.TrainingBook);
      setTrainingVersionId(proofBundle.originObjectId);
      fetchTrainingVersionDetails(proofBundle.originObjectId);
    } else {
      setProofBookType(ProofBookType.SkillBook);
      fetchProofBundleDetails(proofBundle.id);
    }
  }

  async function fetchTrainingVersionDetails(trainingVersionId: string) {
    setLoader(true);
    const trainingVersion = await API.getTrainingVersion(trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(trainingVersion)) {
      logger.warn(trainingVersion);
      return;
    }

    const proofBook = await getTrainingVersionProofBook(trainingVersion, workerIds);
    if (!isMounted.current) return;
    if (API.isFailure(proofBook)) return proofBook;

    setTotalNumberOfTrainees(proofBook.numberOfWorkers);
    setIsProofBookCompleted(proofBook.isProofBookCompleted);
    setTotalNumberOfSkills(proofBook.numberOfSkills);
    setIsProofBookSubmitted(proofBook.isProofBookSubmitted);
    setWorkersPages(proofBook.workersPages);

    setLoader(false);
  }

  async function fetchProofBundleDetails(proofBundleId: string) {
    setLoader(true);

    const proofBundle = await API.getProofBundle(proofBundleId);
    if (!isMounted.current) return;
    if (API.isFailure(proofBundle)) {
      logger.warn(proofBundle);
      setLoader(false);
      return;
    }

    const proofBook = await computeProofBook(
      [proofBundle.workerId],
      [proofBundle.skillId],
      [proofBundle],
      false,
    );
    if (!isMounted.current) return;
    if (API.isFailure(proofBook)) {
      logger.warn(proofBook);
      setLoader(false);
      return;
    }

    
    
    const page = Array.from(proofBook.workersPages.values())[0][0];

    const _workerSkill = await API.getWorkerSkill(proofBundle.workerId, proofBundle.skillId);
    if (!isMounted.current) return;
    if (API.isFailure(_workerSkill)) {
      logger.warn(_workerSkill);
      setLoader(false);
      return;
    }

    setWorker(page.worker);
    setSkill(page.skill);
    setWorkerSkill(_workerSkill);
    setWorkersPages(proofBook.workersPages);
    setIsProofBookCompleted(proofBook.isProofBookCompleted);
    setIsProofBookSubmitted(proofBook.isProofBookSubmitted);
    setIsTrainingSessionPractical(proofBook.isPracticalTraining);
    setTotalNumberOfSkills(proofBook.numberOfSkills);
    setTotalNumberOfTrainees(proofBook.numberOfWorkers);
    setIsProofBookValidated(proofBook.isProofBookValidated);
    

    setLoader(false);
  }

  async function fetchTrainingSessionDetails(
    trainingSessionId: string,
    trainingSessionWorkerIds?: string[],
    fromListner?: boolean,
  ) {
    if (!trainingSessionId) return;
    if (!fromListner) setLoader(true);

    const _trainingSession = await API.getTrainingSession(trainingSessionId);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingSession)) {
      logger.warn('Failed while fetching proof bundles inside training book', _trainingSession);
      if (!fromListner) setLoader(false);
      return;
    }

    setTrainingSession(_trainingSession);
    setTotalNumberOfTrainees(_trainingSession.traineeIds.length);

    const proofBook = await getTrainingSessionProofBook(_trainingSession, trainingSessionWorkerIds);
    if (!isMounted.current) return;
    if (API.isFailure(proofBook)) {
      logger.warn('Failed while fetching proof book details', proofBook);
      return;
    }

    setWorkersPages(proofBook.workersPages);
    setTotalNumberOfSkills(proofBook.numberOfSkills);
    setIsTrainingSessionPractical(proofBook.isPracticalTraining);
    setIsProofBookCompleted(proofBook.isProofBookCompleted);
    setIsProofBookSubmitted(proofBook.isProofBookSubmitted);
    setIsProofBookValidated(proofBook.isProofBookValidated);

    if (proofBook.isProofBookValidated && modalAutoClose) {
      handleCloseModal();
    }

    if (proofBook.isProofBookSubmitted && modalAutoClose && !hasWorkerSkillReviewPermission) {
      handleCloseModal();
    }

    if (!fromListner) setLoader(false);
  }

  const showApiError = (errorMessage: string): void => {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('common:error.unknown'),
        warningSubMessage: errorMessage,
        warningAcceptButton: t('common:button.retry'),
        warningCancelButton: t('common:button.no'),
      }),
    );
  };

  
  async function updateTraningBookData(chapter: Chapter, files?: File[] | Blob[]) {
    const filesUploaded: API.S3Object[] = [];
    setLoader(true);

    if (files) {
      await Aigle.mapSeries(files, async file => {
        const s3Object = await API.uploadFile(file, API.StorageVisibility.protected);
        if (!isMounted.current) return;
        if (API.isFailure(s3Object)) {
          logger.warn('Failed to upload file', s3Object);
          showApiError('' + s3Object.data);
          setLoader(false);
          return;
        }
        filesUploaded.push(s3Object);
      });
    }

    const updateTrainingSessionProofBundle = await updateOrCreateProofBookProofBundle(
      chapter,
      filesUploaded,
      trainingSessionId ?? trainingSessionIdForTrainingVersionProofBundle ?? trainingVersionId,
    );
    if (!isMounted.current) return;
    if (API.isFailure(updateTrainingSessionProofBundle)) {
      showApiError(updateTrainingSessionProofBundle.message);
      logger.warn(updateTrainingSessionProofBundle);
      setLoader(false);
      return;
    }
    setLoader(false);
  }

  async function _handleAutoCertificate(chapter: Chapter, base64Signature?: string) {
    setLoader(true);

    const certificate = await handleAutoCertificate(chapter, base64Signature, proofBookType);
    if (!isMounted.current) return;
    if (API.isFailure(certificate)) {
      setLoader(false);
      logger.warn(certificate);
      showApiError('' + certificate.data);
      return;
    }

    if (certificate) {
      const updatedOrCreateProofBundle = await updateOrCreateProofBookProofBundle(
        chapter,
        [certificate],
        trainingSessionId ?? trainingSessionIdForTrainingVersionProofBundle ?? trainingVersionId,
      );
      if (API.isFailure(updatedOrCreateProofBundle)) {
        setLoader(false);
        logger.warn(updatedOrCreateProofBundle);
        return;
      }
    }

    setLoader(false);
  }

  async function deleteProofBookChapter(chapter: Chapter) {
    if (!chapter.proofBundles) return;

    const _proofBundle = API.deepClone(chapter.proofBundles);

    await Aigle.map(_proofBundle, async proofBundle => {
      const index = proofBundle.files.findIndex(file => {
        if (chapter.file && chapter.file[0]) return file.key === chapter.file[0].key;
      });
      if (index > -1) {
        proofBundle.files.splice(index, 1);
      }

      setLoader(true);
      const updateTrainingProofBundle = await API.updateProofBundle(proofBundle);
      if (!isMounted.current) return;
      if (API.isFailure(updateTrainingProofBundle)) {
        logger.warn('Failed to upload file', updateTrainingProofBundle);
        showApiError('' + updateTrainingProofBundle.data);
        setLoader(false);
        return;
      }
    });

    setLoader(false);
  }

  async function _submitProofBookToReview(chapter: Chapter) {
    const proofBundle = await submitProofBookToReview(chapter);
    if (!isMounted.current) return;
    if (API.isFailure(proofBundle)) {
      logger.warn(proofBundle);
      setLoader(false);
      return;
    }

    if (!allowPartialSubmission && trainingSession) {
      
      const _trainingSession = await API.updateTrainingSession({
        id: trainingSession.id, 
        endDate: new Date().toISOString(),
      });

      if (API.isFailure(_trainingSession)) {
        logger.warn('Failed to end the training session', _trainingSession);
        return trainingSession;
      }
    }

    setLoader(false);

    modal.displayModal(
      ModalUtils.toastConfig({
        text: t('alex:proofBook.proofAdded'),
        onScreenDuration: ToastDuration,
      }),
    );
  }

  async function _handleSubmitToReviewOrValidation(chapter?: Chapter, chapters?: Chapter[]) {
    setLoader(true);
    setShowSkillPageValidateReviewModal(false);

    if (chapter?.isChapterSubmitted) {
      setProofValidatedWorkers([
        ...proofValidatedWorkers,
        ...chapter.trainees.map(trainee => trainee.id),
      ]);
      setProofValidatedSkills([...proofValidatedSkills, ...chapter.skills.map(skill => skill.id)]);
      setChapter(chapter);

      if (chapter.proofBundles) {
        const containsNotAcquiredProofs = chapter.proofBundles.filter(
          proofBundle =>
            !proofBundle.acquired && proofBundle.review.state === API.ReviewState.TO_REVIEW,
        );
        if (containsNotAcquiredProofs.length && isWebPlatform) {
          setWorkerIdsWithNotAcquiredProofs(
            containsNotAcquiredProofs.map(proofBundle => proofBundle.workerId),
          );
          setSkillIdsWithNotAcquiredProofs(
            containsNotAcquiredProofs.map(proofBundle => proofBundle.skillId),
          );
          setShowProofRejectModal(true);
        } else {
          const proofBundle = await validateProofBook(chapter?.proofBundles);
          if (!isMounted.current) return;
          if (API.isFailure(proofBundle)) {
            logger.warn(proofBundle);
            setLoader(false);
            return;
          }

          setLoader(false);

          modal.displayModal(
            ModalUtils.toastConfig({
              text: t('alex:proofBook.proofValidated'),
              onScreenDuration: ToastDuration,
            }),
          );
        }
      }

      setClickedOnValidationButton(true);
    } else if (chapter?.isChapterCompleted) {
      if (
        chapters &&
        chapters.length > 1 &&
        chapters[0].isChapterCompleted &&
        (totalNumberOfSkills !== 1 || totalNumberOfTrainees !== 1) &&
        chapter.trainees.length === 1 &&
        chapter.skills.length === 1 &&
        allowPartialSubmission &&
        isWebPlatform
      ) {
        setShowSkillPageValidateReviewModal(true);
      } else {
        await _submitProofBookToReview(!allowPartialSubmission && chapters ? chapters[0] : chapter);
        if (!isMounted.current) return;
      }
    }

    setLoader(false);
  }

  async function handleRejectModalProofSubmit(chapter?: Chapter, resubmit?: boolean) {
    let _proofBundles;

    if (chapter?.proofBundles) {
      _proofBundles = chapter.proofBundles;
    } else if (proofBundle) {
      _proofBundles = [proofBundle];
    }

    if (_proofBundles) {
      setLoader(true);

      const _validatedProofBundle = await validateProofBook(_proofBundles, resubmit, proofUploader);
      if (!isMounted.current) return;
      if (API.isFailure(_validatedProofBundle)) {
        logger.warn(_validatedProofBundle);
        setLoader(false);
        return;
      }

      setLoader(false);
      setShowProofRejectModal(false);

      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:proofBook.proofValidated'),
          onScreenDuration: ToastDuration,
        }),
      );
    }
  }

  function onSelectProof(proof: Tag) {
    getProofUploader(proof.value);
    setProofBundle(proof.value);
  }

  function onSelectTrainingSession(trainingSession: Tag) {
    setTrainingSessionId(trainingSession.value.id);
  }

  function handleAddWorkerSkillModal(revoke: boolean) {
    setShowAddWorkerSkillModal(true);
    if (!revoke) {
      setRenewProof(true);
      setShowAddWorkerSkillModalRevoke(false);
    } else {
      setShowAddWorkerSkillModalRevoke(true);
    }
  }

  function handleModalClose() {
    if (!warningMenuOpen) {
      setShowAddWorkerSkillModal(false);
    }
  }

  function handleSubmitAgainProof() {
    setShowAddWorkerSkillModal(true);
    setSubmitProofAgain(true);
  }

  function showWarningAndCloseModal(failure: API.Failure): void {
    handleModalClose();
    ModalUtils.showWarningFailure(modal, failure);
  }

  async function deleteWorkerSkill() {
    if (!proofBundle) return;

    setWarningMenuOpen(true);
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:workerSkillReviewModal.modalMenu.removeProofWarning'),
        warningAcceptButton: t('common:button.yes'),
        warningCancelButton: t('common:button.no'),
        warningAcceptCallback: async () => {
          if (worker && skill) {
            setLoader(true);
            const workerSkill = await API.getWorkerSkill(worker.id, skill.id);
            if (!isMounted.current) return;
            if (API.isFailure(workerSkill)) {
              setLoader(false);
              logger.warn('Failed to fetch worker skill', workerSkill);
              return;
            }

            const deleteWorkerSkill = await API.deleteFactoryBusinessObject(workerSkill.id);
            if (!isMounted.current) return;
            if (API.isFailure(deleteWorkerSkill)) {
              setLoader(false);
              logger.warn('Failed to delete worker Skill ', proofBundle.id);
              showWarningAndCloseModal(deleteWorkerSkill);
              return;
            }
            setLoader(false);
            setWarningMenuOpen(false);
            handleModalClose();
            setShowAddTrainingProofModal(false);
          }
        },
        warningCancelCallback: () => {
          setWarningMenuOpen(false);
        },
      }),
    );
  }

  function handleCloseModal() {
    setShowAddTrainingProofModal(false);
    if (closeModalTrigger) closeModalTrigger();
  }

  function handleSkillPageValidateCloseButton() {
    setShowSkillPageValidateReviewModal(false);
  }

  function handleProofRejectModalCloseButton() {
    setShowProofRejectModal(false);
  }

  return (
    <>
      <ProofBookComponent
        loader={loader}
        workersPages={workersPages}
        totalNumberOfSkills={totalNumberOfSkills}
        isProofBookCompleted={isProofBookCompleted}
        isTrainingSessionPractical={isTrainingSessionPractical}
        totalNumberOfTrainees={totalNumberOfTrainees}
        isProofBookSubmitted={isProofBookSubmitted}
        isProofBookValidated={isProofBookValidated}
        workerSkill={workerSkill}
        proofBundle={proofBundle}
        proofBookType={proofBookType}
        showSkillPageValidateReviewModal={showSkillPageValidateReviewModal}
        allowPartialSubmission={allowPartialSubmission}
        trainingSessionsOfWorker={trainingSessionsOfWorker}
        trainingSessionId={trainingSessionId}
        chapter={chapter}
        chapters={chapters}
        showCommonAddProofSection={showCommonAddProofSection}
        isChapterContainsGhostTrainee={isChapterContainsGhostTrainee}
        currentPageIndex={currentPageIndex}
        oneWorkerAndOneSkill={oneWorkerAndOneSkill}
        proofRejectModalProps={{
          showProofRejectModal,
          workerIdsWithNotAcquiredProofs,
          skillIdsWithNotAcquiredProofs,
          proofUploader,

          setProofUploader,
          handleRejectModalProofSubmit,
          handleProofRejectModalCloseButton,
        }}
        workerSkillModalProps={{
          showAddWorkerSkillModal,
          skill,
          worker,
          showAddWorkerSkillModalRevoke,
          renewProof,
          submitProofAgain,

          handleModalClose,
          setShowAddTrainingProofModal,
        }}
        setChapter={setChapter}
        onPageSwitch={onPageSwitch}
        handleSummaryButton={handleSummaryButton}
        handleChapterAddition={handleChapterAddition}
        handleDeleteChoosenFiles={handleDeleteChoosenFiles}
        handleProofs={handleProofs}
        onSkillPress={onSkillPress}
        onTraineePress={onTraineePress}
        onCommonProofPress={onCommonProofPress}
        updateTraningBookData={updateTraningBookData}
        deleteProofBookChapter={deleteProofBookChapter}
        handleAutoCertificate={_handleAutoCertificate}
        handleSubmitToReview={_handleSubmitToReviewOrValidation}
        onSelectProof={onSelectProof}
        handleAddWorkerSkillModal={handleAddWorkerSkillModal}
        handleSubmitAgainProof={handleSubmitAgainProof}
        deleteWorkerSkill={deleteWorkerSkill}
        handleModalCloseButton={handleCloseModal}
        handleSkillPageValidateCloseButton={handleSkillPageValidateCloseButton}
        onSelectTrainingSession={onSelectTrainingSession}
      />
    </>
  );
};
