import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
  },
  rootContainer: {
    flex: 1,
    backgroundColor: Colors.White,
    paddingTop: Spacings.Large + Spacings.Standard - 2, 
    paddingLeft: Spacings.Large, 
    paddingBottom: Spacings.Large + Spacings.Unit + 1, 
  },
  topContainer: {
    flexDirection: 'row',
  },
  IconContainer: {
    alignSelf: 'flex-end',
  },
  middleContainer: {
    marginTop: Spacings.Standard + Spacings.Unit, 
  },
  bottomContainer: {
    marginTop: Spacings.Standard * 6 + Spacings.Medium - Spacings.Unit - 2, 
  },
  termsConditionContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  inputContainer: {
    marginTop: Spacings.Standard * 5, 
  },
  connectButton: {
    width: 144,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.Yellow,
    justifyContent: 'center',
  },
  connectButtonDisabled: {
    width: 144,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.GreyLight,
    justifyContent: 'center',
  },
  connectText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Medium,
    color: Colors.White,
  },
  backButton: {
    marginLeft: -Spacings.Small - Spacings.Unit, 
    marginRight: Spacings.Small,
    alignSelf: 'flex-end',
  },
  headerText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.XXXLarge,
  },
  headerTextContainer: {
    width: '100%',
  },
  errorMessage: {
    ...TextFontStyles.LatoBold,
    color: Colors.Red,
    marginTop: 15,
    fontSize: fontSize.Regular,
  },
  bodyText: {
    ...TextFontStyles.LatoNormal,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Unit,
  },
  boldBodytext: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Standard, 
  },
  resendText: {
    color: Colors.Grey,
    alignSelf: 'center',
    marginTop: '5%',
  },
});

export default styles;
