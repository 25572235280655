import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';
import { HEADER_HEIGHT, CELL_WIDTH } from '../../versatility-panel/styles';

const styles = StyleSheet.create({
  activityIndicatorView: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightContainer: {
    height: '100%',
    width: '100%',
  },
  workstationsContainer: {
    width: '100%',
    zIndex: 1,
    marginBottom: 6,
    position: 'absolute',
  },
  workstationWorkerLevelTableContainer: {
    zIndex: 100,
    width: '100%',
  },
  innerRightContainer: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  innerRightContainerHeader: {
    flexDirection: 'row',
    width: '100%',
    height: HEADER_HEIGHT,
  },
  workstationContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    justifyContent: 'center',
  },
  tooltipWorkstationText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  emptyWorkstationText: {
    alignSelf: 'center',
  },
  rightFooterItemContainer: {},
  rightFooterItemInnerContainer: {
    height: 50,
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rightFooterBigNumber: {
    height: 32,
    width: 32,
    textAlign: 'center',
    fontWeight: '900',
    fontFamily: 'Lato, Black',
    fontSize: 26,
  },
  rightFooterSmallNumber: {
    height: 16,
    width: 32,
    textAlign: 'center',
    fontWeight: '900',
    fontFamily: 'Lato, Black',
    fontSize: 16,
    color: Colors.GreyLight,
  },
  workstationNameContainer: {
    flexDirection: 'row',
    position: 'relative',
    height: HEADER_HEIGHT,
    alignItems: 'center',
    marginTop: 10,
  },
  reduceButtonContainer: {
    position: 'absolute',
    bottom: 0,
    right: -Spacings.Standard,
  },
  alexIconsScrollingContainer: {
    zIndex: -1,
    overflow: 'hidden',
    paddingLeft: Spacings.CardPadding,
    paddingTop: Spacings.Standard,
    position: 'absolute',
  },
  footerOrgUnitWhiteSpace: { height: '100%', width: CELL_WIDTH },
});

export default styles;
