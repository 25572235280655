import React, { useState, createContext, useEffect } from 'react';
import * as API from 'shared/backend-data';
import { MyHub } from '../../util/MyHub';
import * as _ from 'lodash-es';
import logger from '../../util/Logger';

/**
 * If the shift object does not exist it means that the parent is an orgunit
 */
export type ParentWithWorkers = {
  workers: API.Worker[];
  shift?: API.Shift;
};

export type WorkersByParentWithDetails = {
  shiftsAndOrgUnitCount: number;
  workersCount: number;
  workersByParents: Map<string, ParentWithWorkers>;
};

/**
 * This context is used to set/provide the dashboard filter data (workers/workstations) to all the dashboard components
 * @param workers is the workers that are selected by the workerFilterComponent
 * @param workstations is the workstations which the @param workers working on
 * @param renderStage is the variable that control the order of rendering of the dashboard
 */
export const DashboardFilterContext = createContext<{
  workers: [API.Worker[] | undefined, (item: API.Worker[] | undefined) => void];
  workstations: [API.Workstation[] | undefined, (item: API.Workstation[] | undefined) => void];
  workersByParentWithDetails: [
    WorkersByParentWithDetails | undefined,
    (obj: WorkersByParentWithDetails) => void,
  ];
}>({
  workers: [[], () => {}],
  workstations: [[], () => {}],
  workersByParentWithDetails: [undefined, () => {}],
});

export const DashboardFilterProvider: React.FC = props => {
  const [workers, setWorkers] = useState<API.Worker[] | undefined>();
  const [workersByParentWithDetails, setWorkersByParentWithDetails] = useState<
    WorkersByParentWithDetails | undefined
  >();
  const [workstations, setWorkstations] = useState<API.Workstation[] | undefined>();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async ({ data }) => {
      
      if (data.factory.dataType === API.DataType.WORKSTATION && workstations?.length) {
        if (data.tooManyMutations) {
          const _workstations: API.Workstation[] = [];
          for (const workstation of workstations) {
            const mayBeUpdatedWorkstation = await API.getWorkstation(workstation.id);
            if (API.isFailure(mayBeUpdatedWorkstation)) {
              logger.warn(
                'DashboardFilter error while updating workstations: ' + mayBeUpdatedWorkstation,
              );
              return;
            }
            _workstations.push(mayBeUpdatedWorkstation);
          }
          setWorkstations(_workstations);
        } else {
          const id = data.factory.workstation.id;
          const index = workstations.findIndex(workstation => workstation.id === id);
          if (index !== -1) {
            workstations[index] = {
              ...data.factory.workstation,
              updatedAt: data.factory.updatedAt,
              updatedBy: data.factory.updatedBy,
            };
            setWorkstations([...workstations]);
          }
        }
      }

      
      else if (data.factory.dataType === API.DataType.WORKER && workers?.length) {
        if (data.tooManyMutations) {
          const _workers: API.Worker[] = [];
          for (const worker of workers) {
            const mayBeUpdatedWorker = await API.getWorker(worker.id);
            if (API.isFailure(mayBeUpdatedWorker)) {
              logger.warn(
                'DashboardFilter error while updating workstations: ' + mayBeUpdatedWorker,
              );
              return;
            }
            _workers.push(mayBeUpdatedWorker);
          }
          setWorkers(_workers);
        } else {
          const id = data.factory.worker.id;
          const index = workers.findIndex(worker => worker.id === id);
          if (index !== -1) {
            workers[index] = {
              ...data.factory.worker,
              updatedAt: data.factory.updatedAt,
              updatedBy: data.factory.updatedBy,
            };
            setWorkers([...workers]);
          }
        }
      }
    });

    return () => {
      removeListener();
    };
  }, [workstations, workers]);

  return (
    <DashboardFilterContext.Provider
      value={{
        workers: [workers, setWorkers],
        workstations: [workstations, setWorkstations],
        workersByParentWithDetails: [workersByParentWithDetails, setWorkersByParentWithDetails],
      }}
    >
      {props.children}
    </DashboardFilterContext.Provider>
  );
};
