import { Dimensions, PixelRatio, Platform } from 'react-native';

/**
 * Functions to use CSS ViewPort units on mobile
 * @param number
 */


export const vw = (number: number) => Dimensions.get('window').width * (number / 100);
export const vh = (number: number) => Dimensions.get('window').height * (number / 100);

const baseUnit = 4;

export const getCorrectedSpacings = (value: number) => {
  if (Platform.OS === 'web') return value;
  const pixelRatio = PixelRatio.get();

  switch (true) {
    case pixelRatio === 2:
      return value;

    case pixelRatio >= 2.5 && pixelRatio < 3:
      return value + 0.5;

    case pixelRatio === 3:
      return value + 1;

    default:
      return value;
  }
};

/**
 * Spacings (margin, padding, etc.)
 */
export const Spacings = {
  /**
   * 4
   * @returns 4
   */
  Unit: getCorrectedSpacings(baseUnit), 
  /**
   * 8
   * @returns 8
   */
  Small: getCorrectedSpacings(baseUnit * 2),
  /**
   * 12
   * @returns 12
   */
  xMedium: getCorrectedSpacings(baseUnit * 3),
  /**
   * 16
   * @returns 16
   */
  Medium: getCorrectedSpacings(baseUnit * 4),
  /**
   * 24
   * @returns 24
   */
  Standard: getCorrectedSpacings(baseUnit * 6),
  /**
   * 36
   * @returns 36
   */
  Large: getCorrectedSpacings(baseUnit * 9),
  
  Big: getCorrectedSpacings(44),
  CardPadding: getCorrectedSpacings(20),
  MobileIconSize: getCorrectedSpacings(baseUnit * 7), 
};
