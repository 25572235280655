import React, { useState } from 'react';
import { View } from 'react-native';
import * as SharedStyles from 'shared/styles';
import Styles from './Styles';
import * as _ from 'lodash-es';
import { TrainingCardProps, TrainingCard } from '../../training-card/container/index';
import { HoverableIcon } from 'shared/ui-component/HoverableIcon/index';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { ModalUtils } from 'shared/ui-component/Modal';
import { t } from 'shared/localisation/i18n';

interface Props extends TrainingCardProps {
  disabled?: boolean;
  onDelete: () => void;
}

const trashIcon = require('shared/assets/svg/icon.trash.svg').default;

export const DeletableTrainingCard: React.FC<Props> = props => {
  const {
    trainingVersionAndTraining,
    forceDisplayIsPracticalTraining,
    forceDisplaySkillNames,
    disabled,
    onDelete,
  } = props;

  const [isHover, setIsHover] = useState(false);

  const ref = useCallOnHover<View>(
    SharedStyles.Colors.Transparent,
    () => setIsHover(true),
    () => setIsHover(false),
  );
  const modal = ModalUtils.useModal();

  function _onDelete() {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:skills.addEditSkill.warnings.deleteSkillTraining'),
        warningAcceptCallback: onDelete,
        warningAcceptButton: t('common:button.yes'),
        warningCancelButton: t('common:button.no'),
      }),
    );
  }

  return (
    <View style={Styles.container} ref={ref}>
      <TrainingCard
        containerStyle={[Styles.trainingSkillExpandCardContainerStyle, props.containerStyle]}
        trainingVersionAndTraining={trainingVersionAndTraining}
        forceDisplayIsPracticalTraining={forceDisplayIsPracticalTraining}
        forceDisplaySkillNames={forceDisplaySkillNames}
      />
      {isHover && !disabled && (
        <HoverableIcon
          svgIcon={trashIcon}
          size={{ width: 24, height: 24 }}
          containerStyle={Styles.trashIconContainerStyle}
          onPress={_onDelete}
        />
      )}
    </View>
  );
};
