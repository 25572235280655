import { StyleSheet, Dimensions } from 'react-native';
import { Colors, TextFontStyles, createRoundedStyle, fontSize } from 'shared/styles';
const { height, width } = Dimensions.get('window');

export const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: Colors.White,
  },
  scrollViewImageContainer: {
    width: '100%',
    height: '75%',
    marginTop: '15%',
  },
  scrollItemContainer: {
    width: width,
    alignItems: 'center',
  },
  headertextStyle: {
    ...TextFontStyles.PermanentMarker,
    width: '90%',
    minHeight: '20%',
    color: Colors.DarkBlack,
    textAlign: 'center',
    fontSize: fontSize.XLarge,
  },
  imageStyle: {
    height: '75%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  connectionContainer: {
    height: '20%',
    marginBottom: '5%',
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  scrollBar: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  connectionBartextStyle: {
    ...TextFontStyles.LatoMedium,
    color: Colors.DarkBlack,
    textAlign: 'center',
    fontSize: fontSize.Medium,
  },
  connectionBarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingLeft: '9.8%',
    paddingRight: '9.8%',
  },
  cirleContainer: {
    ...createRoundedStyle(width * 0.19),
    padding: 2,
    borderWidth: 1,
    borderColor: Colors.Grey,
    alignItems: 'center',
    justifyContent: 'center',
  },
  usertextStyle: {
    ...TextFontStyles.LatoBold,
    color: Colors.DarkBlack,
    textAlign: 'center',
    fontSize: fontSize.Small,
  },
  animatedView: {
    height: 10,
    width: 10,
    backgroundColor: Colors.Black,
    margin: 8,
    borderRadius: 5,
  },
  svgIconSmall: {
    height: height * 0.05,
    width: width * 0.06,
  },
  svgIconBig: {
    height: height * 0.05,
    width: width * 0.07,
  },
  loginButton: {
    width: '100%',
  },
});
