import React, { useState, useEffect, useContext } from 'react';
import * as API from 'shared/backend-data';
import { View, TouchableOpacity, InteractionManager, StyleProp, ViewStyle } from 'react-native';
import Styles from './Style';
import { t } from 'shared/localisation/i18n';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown';
import { DropDownOption, ExtraSelectorProps } from 'shared/ui-component/DropDown/DropDown';
import * as _ from 'lodash-es';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles/Colors';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import { RolePermissionList } from './RolePermissionList';
import { observerRoleId, pathIdsDelimeter, ShiftPermissionsAndRole } from 'shared/backend-data';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { MyHub } from '../../util/MyHub';
import { useModal } from '../../ui-component/Modal/Modal';
import { ModalUtils } from '../../ui-component/Modal';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { FilterTagOrder } from 'sharedweb/src/Filter/component';

const InfoIcon = require('shared/assets/svg/icon.info.svg').default;
const ExtendIcon = require('shared/assets/svg/icon.extend.svg').default;
const ReduceIcon = require('shared/assets/svg/icon.reduce.svg').default;
const TrashIcon = require('shared/assets/svg/icon.trash.svg').default;
const UnitIcon = require('shared/assets/svg/icon.unit.small.svg').default;
const ShiftIcon = require('shared/assets/svg/icon.shift.svg').default;

export enum DeleteIconState {
  Display,
  AdminPermissionsVeto,
  Hide,
}

export interface TreeNodeWithShift extends API.TreeNode<API.DataType.ORGUNIT> {
  selectedShift?: API.Shift;
  extraSelector?: ExtraSelectorProps;
  children: TreeNodeWithShift<API.DataType.ORGUNIT>[];
}

interface Props {
  defaultAssignment: API.IndexedAssignment;
  dropDownContainerStyle?: StyleProp<ViewStyle>;
  index: number;
  displayDeleteIcon: DeleteIconState;
  assignments: API.IndexedAssignment[];
  allShifts: API.Shift[];
  hidePermission?: boolean;
  inputListContainerStyle?: StyleProp<ViewStyle>;
  preDisabledExtraSelectors?: Tag[];
  preDisabledNodes?: Tag[];
  removeUnitRole: (indexedAssignment: API.IndexedAssignment) => void;
  handleChange: (indexedAssignment: API.IndexedAssignment) => void;
}

interface RoleDropDown extends DropDownOption {
  value: API.Role;
}

export const OrganizationUnit: React.FC<Props> = props => {
  const {
    defaultAssignment,
    index,
    dropDownContainerStyle,
    displayDeleteIcon,
    assignments,
    allShifts,
    hidePermission,
    inputListContainerStyle,
    preDisabledExtraSelectors,
    preDisabledNodes,

    removeUnitRole,
    handleChange,
  } = props;

  const isMounted = useIsMounted();

  const modal = useModal();

  const { isValidPermission } = useContext(PermissionManagementContext);

  const [roles, setRoles] = useState<RoleDropDown[]>([]);
  const [hover, setHover] = useState<boolean>(false);
  const [showSection, setShowSection] = useState<boolean>(false);
  const [treeNodeOptions, setTreeNodeOptions] = useState<Tag[]>([]);
  const [selectedTreeNode, setSelectedTreeNode] = useState<Tag<API.TreeNode>>();
  const [disableTreeNodes, setDisableTreeNodes] = useState<Tag<API.TreeNode>[]>();
  const [disabledExtraSelectors, setDisabledExtraSelectors] = useState<Tag[]>([]);

  useEffect(() => {
    fetchTreeNodeAndRoles();

    InteractionManager.runAfterInteractions(() => {
      const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
        if (API.isTreeDataType(data.factory.dataType)) {
          if (data.tooManyMutations) {
            getTreeNodeOptions();
          } else {
            if (API.isMutationUpdate(data.mutationType)) {
              getTreeNodeOptions();
            } else {
              
            }
          }
        }
      });

      const removeListener2 = MyHub.listenBusinessObject('TreeStructureUpdate', () => {
        getTreeNodeOptions();
      });

      return () => {
        removeListener();
        removeListener2();
      };
    });
  }, [allShifts]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!defaultAssignment.organizationalUnit.id) return;

      const data = getTreeNode(defaultAssignment.organizationalUnit.id, defaultAssignment.shift);
      if (API.isFailure(data)) {
        logger.warn(data);
        return;
      }
      setSelectedTreeNode(data);
    });
  }, [defaultAssignment]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      const _treeNodes: Tag<API.TreeNode>[] = [];
      const _disableExtraSelectors: Tag[] = [];

      const orgUnits = API.Tree.getTreeNodes(API.DataType.ORGUNIT);

      await Promise.all(
        orgUnits.map(async orgUnit => {
          if (!isValidPermission(API.Permission.workers_edit, orgUnit.object)) {
            const nodeToBeDisabled = getTreeNode(orgUnit.id);
            if (!isMounted.current) return;
            if (API.isFailure(nodeToBeDisabled)) {
              logger.warn(nodeToBeDisabled);
              return;
            }
            if (nodeToBeDisabled) _treeNodes.push(nodeToBeDisabled);

            if (orgUnit.object.shiftIds) {
              await Promise.all(
                orgUnit.object.shiftIds.map(async shiftId => {
                  const shift = await API.getShift(shiftId);
                  if (API.isFailure(shift)) {
                    logger.warn(shift);
                    return;
                  }

                  _disableExtraSelectors.push({
                    value: {
                      ...shift,
                      relatedListItemId: orgUnit.id,
                    },
                    key: constructShiftTagKey(shift.id, orgUnit.id),
                    label: shift.name,
                    isExtraSelector: true,
                    tagPath: [API.DataType.SHIFT, [shift.parentId]],
                  });
                }),
              );
            }
          }
        }),
      );

      assignments.forEach(organizationalUnitRole => {
        if (!organizationalUnitRole.shift) {
          const data = getTreeNode(
            organizationalUnitRole.organizationalUnit.id,
            organizationalUnitRole.shift,
          );
          if (!isMounted.current) return;
          if (API.isFailure(data)) {
            logger.warn(data);
            return;
          }
          if (data) _treeNodes.push(data);
        } else {
          _disableExtraSelectors.push({
            value: {
              ...organizationalUnitRole.shift,
              relatedListItemId: organizationalUnitRole.organizationalUnit.id,
            },
            key: constructShiftTagKey(
              organizationalUnitRole.shift.id,
              organizationalUnitRole.organizationalUnit.id,
            ),
            label: organizationalUnitRole.shift.name,
            isExtraSelector: true,
            tagPath: [API.DataType.SHIFT, [organizationalUnitRole.shift.parentId]],
          });
        }
      });

      setDisabledExtraSelectors([...(preDisabledExtraSelectors ?? []), ..._disableExtraSelectors]);
      setDisableTreeNodes([...(preDisabledNodes ?? []), ..._treeNodes]);
    });
  }, [assignments, preDisabledExtraSelectors, preDisabledNodes]);

  function fetchTreeNodeAndRoles() {
    getTreeNodeOptions();
    fetchRoles();
  }

  async function fetchRoles() {
    const _roles = await API.getRoles();
    if (!isMounted.current) return;
    if (API.isFailure(_roles)) {
      logger.warn(_roles);
      return;
    }

    const data = _roles.map(eachRole => {
      return {
        key: eachRole.id,
        label: eachRole.name,
        value: eachRole,
      };
    });

    setRoles(data);
  }

  function getTreeNode(
    organizationalUnitId: string,
    selectedShift?: API.Shift,
  ): API.Result<Tag<API.TreeNode<API.DataType.ORGUNIT>> | undefined> {
    const treeNode = API.Tree.getTreeNode<API.DataType.ORGUNIT>(organizationalUnitId);
    if (API.isFailure(treeNode)) return treeNode;

    const data = API.Tree.filterTree(treeNode, API.DataType.ORGUNIT);

    return getTreeNodeWithShiftTag({ ...data, selectedShift: selectedShift }, allShifts);
  }

  function getTreeNodeOptions() {
    setTreeNodeOptions(
      API.Tree.getOrganizationalUnitTreeStructure().map(child => {
        return {
          ...getTreeNodeWithShiftTag(
            {
              ...child,
              selectedShift: undefined,
            },
            allShifts,
          ),
        };
      }),
    );
  }

  function handleOrganizationalUnitDropdown(
    inputId: string,
    node: Tag<TreeNodeWithShift> | undefined,
  ) {
    if (!node?.value?.object) return;
    setSelectedTreeNode(node);

    const _state =
      defaultAssignment.organizationalUnit.id !== node.value.id
        ? 
          API.IndexedAssignmentState.UPDATE_OU
        : defaultAssignment.state === API.IndexedAssignmentState.DEFAULT
        ? (defaultAssignment.shift?.id &&
            defaultAssignment.shift.id !== node?.value.selectedShift?.id) ||
          (!defaultAssignment.shift?.id && node?.value.selectedShift?.id)
          ? 
            
            API.IndexedAssignmentState.UPDATE
          : defaultAssignment.state
        : defaultAssignment.state;

    handleChange({
      ...defaultAssignment,
      state: _state,
      organizationalUnit: node.value.object,
      shift: node?.value.selectedShift,
      permissions: defaultAssignment.permissions,
      role: defaultAssignment.role,
    });
  }

  async function handleRoleSelect(role: RoleDropDown | undefined) {
    if (!role) return;

    const _state =
      defaultAssignment.role?.id !== role.key &&
      defaultAssignment.state !== API.IndexedAssignmentState.UPDATE_OU
        ? API.IndexedAssignmentState.UPDATE
        : defaultAssignment.state;

    const _permissions =
      role.value.id !== observerRoleId
        ? [...role.value.permissions, API.Permission.workersSkillsProof_edit]
        : [...role.value.permissions];

    handleChange({
      ...defaultAssignment,
      state: _state,
      permissions: _permissions,
      role: role.value,
    });
  }

  function onPermissionChange(permissions: API.Permission[]): void {
    const _state =
      !_.isEqual([...defaultAssignment.permissions].sort(), permissions.sort()) &&
      defaultAssignment.state !== API.IndexedAssignmentState.UPDATE_OU
        ? API.IndexedAssignmentState.UPDATE
        : defaultAssignment.state;
    handleChange({
      ...defaultAssignment,
      permissions: permissions,
      role: defaultAssignment.role,
      state: _state,
    });
  }

  function getPermissionCount(): API.Result<string> {
    if (!defaultAssignment.role?.id) return '-';

    if (!!defaultAssignment.role?.id) {
      const defaultPermission = defaultAssignment.role.permissions.filter(
        permission =>
          !(
            permission === API.Permission.workersDetail_edit ||
            permission === API.Permission.workersSkillsProof_edit
          ),
      );
      const _checkBoxValues = defaultAssignment.permissions.filter(
        permission =>
          !(
            permission === API.Permission.workersDetail_edit ||
            permission === API.Permission.workersSkillsProof_edit
          ),
      );

      if (_checkBoxValues.length > defaultPermission.length) {
        const count = _.difference(_checkBoxValues, defaultPermission).length;
        if (!count) return '';

        return `(+${count} ${t('alex:worker.addEditWorker.permissions', {
          count: _.difference(_checkBoxValues, defaultPermission).length,
        })})`;
      }
      const count = _.difference(defaultPermission, _checkBoxValues).length;
      if (!count) return '';

      return `(${count === 0 ? 0 : -count} ${t('alex:worker.addEditWorker.permissions', {
        count: count,
      })})`;
    }
    return '';
  }

  function handleRemoveUnitRole(deleteIconState: DeleteIconState) {
    if (deleteIconState === DeleteIconState.AdminPermissionsVeto)
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('alex:worker.addEditWorker.warnings.editAdminPermissions'),
          warningAcceptButton: t('common:button.gotIt'),
        }),
      );
    else if (deleteIconState === DeleteIconState.Display)
      removeUnitRole({
        ...defaultAssignment,
        state: API.IndexedAssignmentState.TO_DELETE,
      });
  }

  function isDisplayDeleteIcon() {
    return displayDeleteIcon !== DeleteIconState.Hide && hover && !isAdminPermissionsDisbaled();
  }

  function isAdminPermissionsDisbaled() {
    if (
      !selectedTreeNode?.value ||
      (selectedTreeNode?.value &&
        isValidPermission(API.Permission.workers_edit, selectedTreeNode.value))
    ) {
      return displayDeleteIcon === DeleteIconState.AdminPermissionsVeto;
    }

    return true;
  }

  function hasWorkerEditPermissionDisabled() {
    if (
      !selectedTreeNode?.value ||
      (selectedTreeNode?.value &&
        isValidPermission(API.Permission.workers_edit, selectedTreeNode.value))
    ) {
      return false;
    }

    return true;
  }

  return (
    <View
      ref={useCallOnHover<View>(
        Colors.Transparent,
        () => setHover(true),
        () => setHover(false),
      )}
      style={{
        display: defaultAssignment.state !== API.IndexedAssignmentState.TO_DELETE ? 'flex' : 'none',
        flexDirection: 'row',
        zIndex: -index,
      }}
    >
      <View>
        <View style={Styles.regionSiteActivityDropDownContainer}>
          <View style={[Styles.unitContainer]}>
            <DropDownSingleSelection
              placeholder={t('glossary:organizationalUnitAbbreviated')}
              title={t('glossary:organizationalUnitAbbreviated')}
              searchPlaceholder={t(
                'common:userManagement.organizationalUnitWorkerRole.inputOrganizationalUnit.placeholder',
              )}
              containerStyle={[dropDownContainerStyle, { zIndex: 1 }]}
              inputListContainerStyle={[
                Styles.unitsDropDownInputListContainerStyle,
                inputListContainerStyle,
              ]}
              hasSearch
              notEditable={isAdminPermissionsDisbaled()}
              options={treeNodeOptions}
              value={selectedTreeNode}
              disableOptions={disableTreeNodes}
              listIcon={UnitIcon}
              extraSelectorIcon={ShiftIcon}
              disabledExtraSelectors={disabledExtraSelectors}
              handleChange={handleOrganizationalUnitDropdown}
            />
            {!hidePermission && (
              <ToolTipWeb
                title={t('alex:worker.addEditWorker.addUnitDescription')}
                component={<IconSVG svgComponent={InfoIcon} color={Colors.GreyLight} />}
                style={Styles.unitInfoContainer}
              />
            )}
          </View>

          {!hidePermission && (
            <View style={[Styles.roleDropDownContainer, { zIndex: -1 }]}>
              <DropDownSingleSelection
                placeholder={t('alex:worker.addEditWorker.profileAndPermission')}
                title={t('alex:worker.addEditWorker.profileAndPermission')}
                options={roles}
                value={
                  defaultAssignment.role?.id
                    ? {
                        key: defaultAssignment.role.id,
                        label: `${defaultAssignment.role.name} ${getPermissionCount()}`,
                        value: defaultAssignment.role,
                      }
                    : undefined
                }
                hasSearch
                notEditable={isAdminPermissionsDisbaled()}
                handleChange={(_, value) => handleRoleSelect(value)}
                containerStyle={dropDownContainerStyle}
              />
              <ToolTipWeb
                title={
                  defaultAssignment.role?.id === observerRoleId
                    ? t('alex:worker.addEditWorker.noPermission')
                    : t('alex:worker.addEditWorker.rolePermissionDescription')
                }
                component={<IconSVG svgComponent={InfoIcon} color={Colors.GreyLight} />}
                style={Styles.unitInfoContainer}
              />
            </View>
          )}
        </View>

        {!!defaultAssignment.permissions &&
          defaultAssignment.role?.id &&
          showSection &&
          !hidePermission && (
            <RolePermissionList
              role={defaultAssignment.role}
              permissions={defaultAssignment.permissions}
              disableAdminPermissions={isAdminPermissionsDisbaled()}
              disableWorkerEditPermission={hasWorkerEditPermissionDisabled()}
              onPermissionChange={onPermissionChange}
              style={[{ width: 572 }, isDisplayDeleteIcon() && Styles.border]}
            />
          )}
      </View>
      {!hidePermission && (
        <View style={Styles.iconContainer}>
          <TouchableOpacity onPress={() => setShowSection(!showSection)} style={Styles.toggleIcon}>
            <IconSVG svgComponent={showSection ? ReduceIcon : ExtendIcon} color={Colors.Black} />
          </TouchableOpacity>

          {isDisplayDeleteIcon() && (
            <TouchableOpacity
              onPress={() => handleRemoveUnitRole(displayDeleteIcon)}
              style={Styles.trashIconStyle}
            >
              <IconSVG svgComponent={TrashIcon} color={Colors.Grey} />
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
};

/**
 *  Key is constructed by two ids to prevent duplicate ids while mapping and rendering JSX elements, plus to make difference between shifts on different sub units
 * @param shiftId
 * @param orgUnitId
 * @returns
 */
export function constructShiftTagKey(shiftId: string, orgUnitId: string) {
  return shiftId + API.SeparatorIds + orgUnitId;
}

export function getTreeNodeWithShiftTag(
  treeNodeWithShift: TreeNodeWithShift,
  shifts: API.Shift[],
): Tag<TreeNodeWithShift> {
  let _selectorOptions: Tag[] = [];

  treeNodeWithShift.object.shiftIds
    ? treeNodeWithShift.object.shiftIds.forEach(_shiftId => {
        const shiftObject = _.find(shifts, shift => shift.id === _shiftId);
        if (shiftObject)
          _selectorOptions.push({
            value: shiftObject,
            key: constructShiftTagKey(shiftObject.id, treeNodeWithShift.object.id),
            label: shiftObject.name,
            isExtraSelector: true,
            tagPath: [API.DataType.SHIFT, [shiftObject.parentId]],
            type: API.DataType.SHIFT,
            extraSelectorRelatedItem: {
              key: treeNodeWithShift.id,
              label: treeNodeWithShift.object.name + pathIdsDelimeter + shiftObject.name,
              value: {
                ...treeNodeWithShift,
                extraSelector: {
                  selectorOptions: _selectorOptions,
                },
                selectedShift: shiftObject,
              },
              type: API.DataType.ORGUNIT,
              tagIcon: UnitIcon,
            },
          });
      })
    : [];

  return {
    key: treeNodeWithShift.id,
    label:
      treeNodeWithShift.object.name +
      (treeNodeWithShift.selectedShift
        ? pathIdsDelimeter + treeNodeWithShift.selectedShift.name
        : ''),
    value: {
      ...treeNodeWithShift,
      extraSelector: {
        selectorOptions: _selectorOptions,
      },
      selectedShift: treeNodeWithShift.selectedShift,
    },
    type: treeNodeWithShift.factory.dataType,
    tagPath: [treeNodeWithShift.factory.dataType, treeNodeWithShift.parentIds],
    tagIcon: UnitIcon,
    color: Colors.Orange,
    order: FilterTagOrder.OrganizationUnit,

    children: _.map(treeNodeWithShift.children, child => getTreeNodeWithShiftTag(child, shifts)),
  };
}
