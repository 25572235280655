import React, { useState } from 'react';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import * as API from 'shared/backend-data';
import { Text, View, TouchableOpacity, ScrollView } from 'react-native';
import { Colors, Spacings } from 'shared/styles';
import styles from './styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { InputProof } from 'shared/ui-component/Input/InputProof';
import { useModal, ModalUtils } from 'shared/ui-component/Modal';

const ICON_SKILL = require('shared/assets/svg/icon.addProof.mobile.svg').default;

const ThumbUp = require('shared/assets/svg/icon.thumbUp.svg').default;
const ThumbDown = require('shared/assets/svg/icon.thumbDownRight.svg').default;

interface Props {
  subSkills: Tag<API.Skill>[];
  reviewer?: API.Worker;
  trainee?: DropDownOption;
  trainingSessionId?: string;
  proofs: (API.ProofBundle | undefined)[];
  isSkillAcquiredArray: boolean[];
  isSkillRejectedArray: boolean[];
  setIsSkillAcquiredArray: (arr: boolean[]) => void;
  setIsSkillRejectedArray: (arr: boolean[]) => void;
  setProofs: (proof: (API.ProofBundle | undefined)[]) => void;
}

export const SubSkills: React.FC<Props> = props => {
  const {
    subSkills,
    reviewer,
    trainee,
    trainingSessionId,
    proofs,
    isSkillAcquiredArray,
    isSkillRejectedArray,

    setProofs,
    setIsSkillAcquiredArray,
    setIsSkillRejectedArray,
  } = props;

  const modal = useModal();

  async function setSkillAsAcquired(
    skillTag: Tag,
    index: number,
    acquiredValue: boolean | undefined,
  ) {
    if (trainingSessionId && isSkillAcquiredArray && trainee) {
      const _isSkillAcquiredArray = [...isSkillAcquiredArray];
      if (_isSkillAcquiredArray) _isSkillAcquiredArray[index] = !_isSkillAcquiredArray[index];
      setIsSkillAcquiredArray(_isSkillAcquiredArray);

      const _isSkillRejectedArray = [...isSkillRejectedArray];
      if (_isSkillRejectedArray) _isSkillRejectedArray[index] = false;
      setIsSkillRejectedArray(_isSkillRejectedArray);

      const setSkillToAcquired = await API.createOrUpdateProofAsAcquiredOrNot(
        skillTag.key,
        trainingSessionId,
        trainee.key,
        acquiredValue ? acquiredValue : null,
      );
      if (API.isFailure(setSkillToAcquired)) {
        API.logger.warn(setSkillToAcquired);

        return;
      }

      const _proofs = proofs;
      _proofs[index] = setSkillToAcquired;
      setProofs(_proofs);

      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:scheduleTrainingModal.toastMessageAcquired'),
        }),
      );
    }
  }
  async function setSkillAsRejected(
    skillTag: Tag<API.Skill>,
    index: number,
    acquiredValue: boolean,
  ) {
    if (trainingSessionId && isSkillRejectedArray && trainee) {
      const skillRejected = [...isSkillRejectedArray];
      if (skillRejected) skillRejected[index] = !skillRejected[index];
      setIsSkillRejectedArray(skillRejected);

      const skillAcquired = [...isSkillAcquiredArray];
      if (skillAcquired) skillAcquired[index] = false;
      setIsSkillAcquiredArray(skillAcquired);

      const setSkillToRejected = await API.createOrUpdateProofAsAcquiredOrNot(
        skillTag.key,
        trainingSessionId,
        trainee.key,
        acquiredValue ? !acquiredValue : null,
      );
      if (API.isFailure(setSkillToRejected)) {
        API.logger.warn(setSkillToRejected);
        return;
      }

      const _proofs = proofs;
      _proofs[index] = setSkillToRejected;
      setProofs(_proofs);
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:scheduleTrainingModal.toastMessageNotAcquired'),
        }),
      );
    }
  }

  return (
    <ScrollView
      style={{
        paddingHorizontal: Spacings.Large,
        paddingLeft: Spacings.Big,
      }}
      contentContainerStyle={{ width: '100%' }}
    >
      {subSkills.map((subSkill, index) => {
        return (
          <View
            key={subSkill.key}
            style={[styles.advancementContainer, { zIndex: 100 - index, width: '100%' }]}
          >
            <View
              style={[
                styles.skillAdvancmentRowContainer,
                { width: '75%', paddingLeft: Spacings.Small },
              ]}
            >
              <View style={[styles.skillNameContainer]}>
                <IconSVG
                  svgComponent={ICON_SKILL}
                  size={{ width: Spacings.Standard, height: Spacings.Standard }}
                  color={Colors.White}
                  containerStyle={styles.skillIconContainer}
                />
                <Text style={[styles.skillNameTextStyle]}>{subSkill.label}</Text>
              </View>
              <View style={styles.acquiredContainer}>
                <Text style={styles.acquiredTextStyle}>
                  {t('alex:scheduleTrainingModal.acquired')}
                </Text>
                <View style={styles.AcquiredIconContaner}>
                  <TouchableOpacity
                    onPress={() =>
                      setSkillAsAcquired(subSkill, index, !isSkillAcquiredArray[index])
                    }
                  >
                    <IconSVG
                      svgComponent={ThumbUp}
                      size={{ width: Spacings.Standard, height: Spacings.Standard }}
                      color={isSkillAcquiredArray[index] ? Colors.Green : Colors.GreyLight}
                      containerStyle={[styles.acquiredIconContainer, { marginTop: Spacings.Unit }]}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() =>
                      setSkillAsRejected(subSkill, index, !isSkillRejectedArray[index])
                    }
                  >
                    <IconSVG
                      svgComponent={ThumbDown}
                      size={{ width: Spacings.Standard, height: Spacings.Standard }}
                      color={isSkillRejectedArray[index] ? Colors.Red : Colors.GreyLight}
                      containerStyle={[styles.acquiredIconContainer, { marginTop: Spacings.Small }]}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            {reviewer && trainee && props.trainingSessionId && (
              <InputProof
                placeHolder={t('alex:scheduleTrainingModal.description')}
                style={{
                  width: 264,
                  marginLeft: Spacings.Large,
                }}
                workerIdAndName={{
                  id: trainee.key,
                  name: trainee.label,
                }}
                skillIdAndName={{ id: subSkill.key, name: subSkill.label }}
                validatorName={reviewer.name}
                trainingSessionId={props.trainingSessionId}
                itemIndex={index}
                initialProofBunble={proofs[index]}
              />
            )}
          </View>
        );
      })}
    </ScrollView>
  );
};
