import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, InteractionManager } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import * as _ from 'lodash-es';
import logger from 'shared/util/Logger';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { TrainingSkillsListing } from './TrainingSkillsListing';
import Styles from './style';
import { t } from 'shared/localisation/i18n';
import { Spacings } from 'shared/styles';

interface SkillWithPossibleTrainingVersions {
  skillId: string;
  trainingVersions: API.TrainingVersion[];
}

interface SkillsWithPossibleTrainingVersionsListingProps {
  skillWithPossibleTrainingVersions: SkillWithPossibleTrainingVersions;
  requirement: API.Requirement;
  handleWorkstationTrainingModal: (
    isShow: boolean,
    editMode: boolean,
    trainingVersion: API.TrainingVersion | null | undefined,
    skillIds: string[],
  ) => void;
  refreshLevelSkills: () => Promise<void>;
}

const skillIcon = require('shared/assets/svg/icon.skill.small.svg').default;

export const SkillsWithPossibleTrainingVersionsListing: React.FC<SkillsWithPossibleTrainingVersionsListingProps> = props => {
  const {
    requirement,
    skillWithPossibleTrainingVersions,
    handleWorkstationTrainingModal,
    refreshLevelSkills,
  } = props;
  const isMounted = useIsMounted();

  const [skill, setSkill] = useState<API.Skill>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !props.skillWithPossibleTrainingVersions) return;
      const skill = await API.getSkill(props.skillWithPossibleTrainingVersions.skillId);
      if (!isMounted.current) return;
      if (API.isFailure(skill)) {
        logger.warn('Failed to fetch skill ', skill);
        return;
      }
      setSkill(skill);
    });
  }, [props.skillWithPossibleTrainingVersions]);

  return (
    <View style={Styles.skillsWithPossibleTrainingsContainer}>
      <View style={Styles.skillsWithPossibleTrainingsInnerContainer}>
        <View style={Styles.skillTitleContainer}>
          <IconSVG
            svgComponent={skillIcon}
            color={SharedStyles.Colors.White}
            size={{
              width: SharedStyles.Spacings.Standard,
              height: SharedStyles.Spacings.Standard,
            }}
            containerStyle={{
              width: SharedStyles.Spacings.Standard,
              height: SharedStyles.Spacings.Standard,
              backgroundColor: SharedStyles.Colors.Red,
              borderRadius: 20,
              marginRight: 8,
            }}
          />
          <Text style={Styles.skillsWithPossibleTrainingsTextStyle}>{skill?.name}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginTop: SharedStyles.Spacings.Small,
            marginBottom: SharedStyles.Spacings.Small,
          }}
        >
          <IconSVG
            svgComponent={skillIcon}
            color={SharedStyles.Colors.White}
            size={{ width: 0, height: 0 }}
            containerStyle={{
              width: 6,
              height: 6,
              backgroundColor: SharedStyles.Colors.Red,
              borderRadius: 20,
              marginRight: 8,
            }}
          />
          <Text style={Styles.skillsWithPossibleTrainingsTitle}>
            {props.skillWithPossibleTrainingVersions.trainingVersions.length > 1
              ? t(
                'alex:workstations.workstationPanel.requirementTable.requirementTrainingColumn.linkTrainingsToSkill',
              )
              : t(
                'alex:workstations.workstationPanel.requirementTable.requirementTrainingColumn.linkTrainingToSkill',
              )}
          </Text>
        </View>
      </View>

      <ScrollView>
        {_.map(props.skillWithPossibleTrainingVersions.trainingVersions, trainingVersion => {
          return (
            <View
              style={{
                paddingRight: Spacings.Standard,
              }}
              key={trainingVersion.id}
            >
              <TrainingSkillsListing
                key={trainingVersion.id}
                selectableTraining
                selectableSkillId={skillWithPossibleTrainingVersions.skillId}
                trainingVersionId={trainingVersion.id}
                skillIds={_.map(requirement?.skillTrainingVersions, skillTrainingVersion => {
                  return skillTrainingVersion.skillId;
                })}
                requirement={requirement}
                handleWorkstationTrainingModal={handleWorkstationTrainingModal}
                refreshLevelSkills={refreshLevelSkills}
              />
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};
