import React, { useEffect } from 'react';
import { View } from 'react-native';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { TableRow } from 'shared/ui-component/Table';
import { RequirementTable } from './RequirementTable';
import Styles from './style';
import { TreeNode } from 'shared/backend-data/factoryCache/Tree';
import { CreateFactoryScreenComponent } from './CreateFactoryScreenComponent';
import { useHistory } from 'react-router-dom';
import { WorkstationsState } from '../WorkstationsLibrary';

export enum FetchingStatus {
  Ready = 'READY',
  Fetching = 'FETCHING',
  Done = 'DONE',
}
export interface UnitMenu {
  showMenu: boolean;
  firstOption: boolean;
  secondOption: boolean;
  thirdOption: boolean;
}
export interface UnitMenuHandler {
  menu: UnitMenu;
  firstOptionHandler: Function | null;
  secondOptionHandler: Function | null;
  thirdOptionHandler: Function | null;
}
export interface LevelSkillsPaneContent {
  title: string;
  description: string;
  level: API.WorkstationWorkerLevels;
  levelSkills: RequirementWithDetails;
  inheritedLevelSkills: RequirementWithDetails[];
}
export interface RequirementWithDetails {
  sourceRequirement: API.Requirement | null;
  linkedObject: API.TreeObject | UnauthorizedUnit;
  level: API.WorkstationWorkerLevels;
  skills: API.Skill[];
}
export interface UnauthorizedUnit {
  type: 'UnauthorizedUnitType';
}
export function isUnauthorizedUnitType(
  bo: API.TreeObject | UnauthorizedUnit,
): bo is UnauthorizedUnit {
  return (bo as UnauthorizedUnit).type === 'UnauthorizedUnitType';
}

interface WorkstationComponentProps {
  treeNode: TreeNode | undefined;
  setTreeNode: (treeNode: TreeNode | undefined) => void;
}
export interface WorkstationRow extends TableRow {
  workstation: API.Workstation;
  key: string;
  workerCount: number;
  actualAndMinimum: API.WorkstationTargetActual | null;
}

export const WorkstationsComponent: React.FC<WorkstationComponentProps> = props => {
  const { treeNode } = props;

  const history = useHistory<WorkstationsState>();

  useEffect(() => {
    if (_.isUndefined(history.location.state?.tabIndex) || !treeNode) return;
  }, [history.location.state?.tabIndex, treeNode]);

  return (
    <View style={Styles.workstationScreenRightPanelContainer}>
      {treeNode ? <RequirementTable treeNode={treeNode} /> : <CreateFactoryScreenComponent />}
    </View>
  );
};
