import React, { useContext, useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, InteractionManager } from 'react-native';
import styles from './styles';
import ReactDOM from 'react-dom';
import { Card } from 'shared/ui-component/Card';
import { WorkstationWorkerLevelTargetWarning } from 'shared/backend-data';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { createRoundedStyle, Spacings } from 'shared/styles';
import { t } from 'shared/localisation/i18n';
import { Colors } from 'shared/styles';
import { SkillConformityModal } from '../../dashboard/skill-conformity-modal/container';
import { TrainWorkerModal } from '../../training/train-worker-modal/container/index';
import { api2workstationWorkerLevels, WorkstationWorkerLevels } from 'shared/util/WorkerLevel';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { OutlineButton } from 'shared/ui-component/Button';
import { WorkstationWorkerMenuObject } from '../versatility-panel/VersatilityPanel';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../navigation/Routes';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { SkillConformityOption } from './SkillConfirmityOption';
import { LevelTargetAndMaintainOption } from './LevelTargetAndMaintainOption';
import { WarningOption } from './WarningOption';
import { PlainLevelIcon } from 'shared/ui-component/Icon/LevelIcon/LevelIcon';

interface Props {
  levelIconElement: Element;
  parentPanelElement?: Element;
  menuObject: WorkstationWorkerMenuObject;

  setOpenMenu: (bool: boolean) => void;
  setTreeNode?: (treeNode: API.TreeNode<API.TreeDataType> | undefined) => void;
}

interface Condinates {
  x: number;
  y: number;
}

export const WorkstationWorkerMenu: React.FC<Props> = props => {
  const { levelIconElement, parentPanelElement, menuObject, setTreeNode, setOpenMenu } = props;

  const isMounted = useIsMounted();
  const [showSkillConformityModal, setSkillConformityModal] = useState<boolean>(false);
  const [showTrainingModal, _setShowTrainingModal] = useState<boolean>(false);
  const [showTargetAndMaintain, setShowTargetAndMaintain] = useState<boolean>(false);
  /**
   * Proxy function to check if the user has the permission to show the training modal
   * and to close the menu if show=false
   */
  function setShowTrainingModal(show: boolean): void {
    if (
      show === true &&
      !isValidPermission(API.Permission.trainingSessions_edit, menuObject.parentOrgUnit, [
        menuObject.worker.id,
      ])
    ) {
      show = false;
    }

    _setShowTrainingModal(show);
    if (show == false) {
      closeMenu();
    }
  }
  const [cordinates, setCordinates] = useState<Condinates>();
  const [workstationSkillsRequiredInLevels, setWorkstationSkillsRequiredInLevels] =
    useState<API.WorkstationSkillsRequiredInLevels>();
  const [trainingLevel, _setTrainingLevel] = useState<WorkstationWorkerLevels>();

  async function setTrainingLevel(
    trainingLevel: WorkstationWorkerLevels | undefined,
  ): Promise<void> {
    if (trainingLevel) {
      const r = await API.setTarget(
        menuObject.workerWorkstation.workstationId,
        menuObject.workerWorkstation.workerId,
        trainingLevel,
        false,
      );
      if (!isMounted.current) return;
      if (API.isFailure(r)) {
        ModalUtils.showWarningFailure(modal, r);
        return;
      }
    }
    _setTrainingLevel(trainingLevel);
  }

  const history = useHistory();
  const modal = useModal();

  const isExpired =
    menuObject.workerWorkstation.warning === WorkstationWorkerLevelTargetWarning.EXPIRED;
  const showRemoveWarningOption =
    menuObject.workerWorkstation.warning === WorkstationWorkerLevelTargetWarning.EXPIRED ||
    menuObject.workerWorkstation.warning === WorkstationWorkerLevelTargetWarning.EXPIRE_SOON;
  const skillsMissingCount =
    (menuObject.workerWorkstation.numberOfRequiredSkills || 0) -
    (menuObject.workerWorkstation.validSkills?.length || 0);

  const alertTitle = isExpired
    ? t('alex:workstationWorkerLevelMenu.alertTitleExpired', { count: skillsMissingCount })
    : t('alex:workstationWorkerLevelMenu.alertTitleSoonExpire', { count: skillsMissingCount });

  const alertMessage = t('alex:workstationWorkerLevelMenu.alertMessageToTakeAction');

  const accept = t('common:button.yes');
  const reject = t('common:button.no');

  const { isValidPermission } = useContext(PermissionManagementContext);
  const { levelIconType } = useContext(GlobalDataContext);
  const {
    treeNode: [, _setTreeNode],
  } = useContext(MenuFactoryContext);

  const myRef = useDetectOutsideClick<View>(() => {
    if (!showSkillConformityModal && !showTrainingModal) {
      closeMenu();
    }
  });

  function closeMenu() {
    setOpenMenu(false);
    if (setTreeNode) setTreeNode(undefined);
  }

  useEffect(() => {
    if (myRef.current && parentPanelElement) {
      const cordinates = { x: 0, y: 0 };
      const node = ReactDOM.findDOMNode(myRef.current) as Element;
      cordinates.x =
        levelIconElement.getBoundingClientRect().x +
        levelIconElement.getBoundingClientRect().width -
        parentPanelElement.getBoundingClientRect().left;

      const modalHeightOffset =
        levelIconElement.getBoundingClientRect().y + node.clientHeight - Spacings.Standard;

      if (window.innerHeight > modalHeightOffset) {
        cordinates.y =
          levelIconElement.getBoundingClientRect().y -
          Spacings.Standard -
          parentPanelElement.getBoundingClientRect().top;
      } else {
        cordinates.y =
          levelIconElement.getBoundingClientRect().bottom -
          node.clientHeight -
          parentPanelElement.getBoundingClientRect().top;
      }
      setCordinates(cordinates);
    }
  }, [levelIconElement]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (menuObject.workerWorkstation) {
        const skillsAndWorkerSkills =
          await API.getSkillsAndWorkerSkillsRequiredForWorkstationLevels(
            menuObject.workerWorkstation.workstationId,
            menuObject.workerWorkstation.workerId,
            true,
            false,
          );

        if (!isMounted.current) return;
        if (API.isFailure(skillsAndWorkerSkills)) {
          logger.error('error while feching the skills and worker skills', skillsAndWorkerSkills);
          return skillsAndWorkerSkills;
        }

        setWorkstationSkillsRequiredInLevels(skillsAndWorkerSkills);

        const orgUnit = await API.getOrgUnitOfWorkstation(
          menuObject.workerWorkstation.workstationId,
        );
        if (!isMounted.current) return;
        if (API.isFailure(orgUnit)) {
          logger.warn(orgUnit);
          return;
        }

        const isWorkerManager = await API.isWorkerManager(orgUnit);
        if (!isMounted.current) return;
        if (API.isFailure(isWorkerManager)) {
          logger.warn(isWorkerManager);
          return;
        }

        setShowTargetAndMaintain(isWorkerManager);
      }
    });
  }, [menuObject.workerWorkstation]);

  function renderWarningOption(showWarning: boolean) {
    if (showWarning)
      return (
        <View style={{ marginTop: 6 }}>
          <View style={styles.LineView} />
          <View>
            <View>
              <View style={styles.alertBox}>
                <View
                  style={[
                    createRoundedStyle(6),
                    { backgroundColor: isExpired ? Colors.Red : Colors.Orange },
                  ]}
                />
                <Text style={styles.alertTextTitle}>{alertTitle}</Text>
              </View>
              <Text style={styles.alertTextMessage}>{alertMessage}</Text>
            </View>
            <View
              style={[
                styles.optionContainer,
                { justifyContent: 'space-between', marginTop: Spacings.Medium },
              ]}
            >
              <TouchableOpacity
                style={[styles.CircleView, , { flex: 0.45 }]}
                onPress={() => {
                  setTrainingLevel(
                    api2workstationWorkerLevels(menuObject.workerWorkstation.previousLevel),
                  );
                  setShowTrainingModal(true);
                }}
              >
                <Text style={styles.alertButtonText}>{accept}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.CircleView, { flex: 0.45 }]}
                onPress={() => {
                  menuObject.handleSilenceWarning();
                }}
              >
                <Text style={styles.alertButtonText}>{reject}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
  }

  function renderModals() {
    return (
      <>
        {showSkillConformityModal && (
          <SkillConformityModal
            workerId={menuObject.workerWorkstation.workerId}
            workstationId={menuObject.workerWorkstation.workstationId}
            handleModalClose={() => setSkillConformityModal(false)}
          />
        )}
        {showTrainingModal && (
          <TrainWorkerModal
            openModal={showTrainingModal}
            workerId={menuObject.workerWorkstation.workerId}
            treeNodeId={menuObject.workerWorkstation.workstationId}
            trainingLevel={trainingLevel}
            handleModalClose={() => {
              setShowTrainingModal(false);
            }}
            originatedFromDashboard
          />
        )}
      </>
    );
  }

  function onAddClick() {
    const _treeNode = API.Tree.getTreeNode(menuObject.workerWorkstation.workstationId);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }
    _setTreeNode(_treeNode);
    history.push(RouteLocations.Workstations(menuObject.workerWorkstation.workstationId));
  }

  return (
    <Card
      ref={myRef}
      style={[
        styles.CardContainer,
        cordinates ? { left: cordinates.x, top: cordinates.y } : { opacity: 0 },
      ]}
    >
      <View style={styles.ProfileContainer}>
        <View style={styles.ProfileImageContainer}>
          {menuObject.worker &&
            renderImage(menuObject.worker.profilePicture, ImageSizes.Large, menuObject.worker.name)}
        </View>

        <View style={styles.ProfileInfoContainer}>
          <EllipsisWithTooltip
            text={menuObject.worker.name}
            textStyle={styles.ProfileText}
            style={{ zIndex: 1 }}
          />
          {!menuObject.noRequirementForWorkstation && (
            <View style={styles.ProfileLevelContainer}>
              <PlainLevelIcon
                level={menuObject.workerWorkstation.level ?? API.WorkstationWorkerLevel.LEVEL0}
                iconSize={{ width: Spacings.Standard, height: Spacings.Standard }}
                levelIconType={levelIconType}
              />
              <Text
                style={[
                  styles.ProfileLevelText,
                  levelIconType === API.LevelIconType.SQUARE && styles.magicSquareLevelText,
                ]}
              >
                {API.getWorkstationWorkerLevelLabel(menuObject.workerWorkstation.level)}
              </Text>
            </View>
          )}
        </View>
      </View>
      <View style={styles.LineView} />
      {!menuObject.noRequirementForWorkstation ? (
        <View>
          {isValidPermission(API.Permission.workersDetail_view, menuObject.parentOrgUnit, [
            menuObject.worker.id,
          ]) && (
            <SkillConformityOption
              workerWorkstation={menuObject.workerWorkstation}
              onPress={() => setSkillConformityModal(true)}
              workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
            />
          )}
          {/**
           *
           */}
          <LevelTargetAndMaintainOption
            workerWorkstation={menuObject.workerWorkstation}
            handleMaintainSelect={menuObject.handleMaintainSelect}
            handleUnassignWorkerAndStopTrainings={menuObject.handleUnassignWorkerAndStopTrainings}
            workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
            setTrainingLevel={setTrainingLevel}
            setShowTrainingModal={setShowTrainingModal}
            maintainInfoModalCoordinates={{ x: Spacings.Standard, y: 0 }}
          />

          {isValidPermission(API.Permission.workersDetail_view, menuObject.parentOrgUnit, [
            menuObject.worker.id,
          ]) && <WarningOption workerWorkstation={menuObject.workerWorkstation} />}
          {isValidPermission(API.Permission.trainingSessions_edit, menuObject.parentOrgUnit, [
            menuObject.worker.id,
          ]) && renderWarningOption(showRemoveWarningOption)}
          {renderModals()}
        </View>
      ) : (
        <View style={styles.noRequirementContainer}>
          <Text style={styles.noRequirementText}>
            {t('alex:dashboard.workstationNoRequirementDesciption')}
          </Text>

          <OutlineButton
            text={t('common:button.add')}
            isSmallButton
            style={styles.addOutlineButton}
            onPress={onAddClick}
          />
        </View>
      )}
    </Card>
  );
};
