import _ from 'lodash';
import { loadFiles, logger } from 'shared/backend-data';
import { Immutable } from 'shared/util-ts/Functions';
import saveAs from 'file-saver';
import * as API from 'shared/backend-data';
import JSZip from 'jszip';
import { t } from 'shared/localisation/i18n';

/**
 * this function will download the files (proofs) of a proofBundle, if the proofBundle have more than one
 * file it will download them as a zip file.
 * @param _proofBubndle
 */
export async function downloadfiles(
  _proofBubndle: Immutable<API.NoMetadata<API.ProofBundle>>,
): Promise<API.Result<void>> {
  const files = await loadFiles(_proofBubndle);
  if (API.isFailure(files)) {
    logger.error(files);
    return;
  }
  const errors: API.Failure[] = [];
  const objectFiles = _.compact(
    await Promise.all(
      files.map(async file => {
        const object = await fetch(file.downloadLink);
        if (API.isFailure(object)) {
          errors.push(object);
          return;
        }
        return await object.blob();
      }),
    ),
  );
  if (errors.length) return API.createFailure_Multiple(errors);

  if (objectFiles.length > 1) {
    const zip = new JSZip();
    objectFiles.forEach((object, index) => {
      zip.file(files[index].fileName ?? 'noFileName' + index, object);
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, t('glossary:proof') + '.zip');
    });
  } else {
    saveAs(objectFiles[0], files[0].fileName ?? 'noFileName');
  }
}
