import { StyleSheet } from 'react-native';
import { TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  radioButtonText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  radioButtonContainer: {
    paddingTop: 33,
  },
  skillAcquiredText: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },
});

export default styles;
