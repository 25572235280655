import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings } from 'shared/styles';

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
  },
  rootContainer: {
    flex: 1,
    backgroundColor: Colors.White,
    paddingTop: Spacings.Large + Spacings.Standard - Spacings.Unit,
    paddingLeft: Spacings.Large,
  },
  topContainer: {
    justifyContent: 'flex-end',
  },
  bottomContainer: {
    marginTop: Spacings.Standard * 10 + Spacings.Small,
  },
  inputContainer: {
    marginTop: Spacings.Large + Spacings.Medium + Spacings.Unit,
  },
  connectButton: {
    width: 170,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.Yellow,
    justifyContent: 'center',
  },
  connectButtonDisabled: {
    width: 170,
    height: 36,
    alignSelf: 'flex-end',
    backgroundColor: Colors.GreyLight,
    justifyContent: 'center',
  },
  connectText: {
    ...TextFontStyles.LatoBold,
    fontSize: 16,
    color: Colors.White,
  },
  backButton: {
    position: 'absolute',
    zIndex: 1,
    marginLeft: -Spacings.Small - Spacings.Unit, 
  },
  headerText: {
    ...TextFontStyles.LatoBlack,
    fontSize: 26,
  },
  headerTextContainer: {
    width: '100%',
    marginBottom: Spacings.Standard + Spacings.Unit,
  },
  errorMessage: {
    ...TextFontStyles.LatoBold,
    color: Colors.Red,
    marginTop: 15,
    fontSize: 14,
  },
  bodyText: {
    ...TextFontStyles.LatoNormal,
    fontSize: 14,
    width: '75%',
  },
  boldBodytext: {
    ...TextFontStyles.LatoNormal,
    marginBottom: Spacings.Standard,
    fontSize: 14,
  },
  urlText: {
    ...TextFontStyles.LatoMedium,
    fontSize: 16,
    fontWeight: 'bold',
  },
  warningText: {
    ...TextFontStyles.LatoNormal,
    fontSize: 16,
  },
});

export default styles;
