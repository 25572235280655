import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  StyleProp,
  ViewStyle,
  Platform,
  TextStyle,
  TouchableOpacity,
} from 'react-native';
import { ModalUtils } from 'shared/ui-component/Modal';
import { SvgProps } from 'react-native-svg';
import ArrowIcon from 'shared/assets/svg/icon.list.mobile.svg';
import { IconSVG } from '../../Icon';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';
import { DropDownMenu } from './DropDownMenu';
import Styles from './Styles';
import { t } from 'shared/localisation/i18n';
import { InputList, CreateItemProp } from 'shared/ui-component/Input/InputList/index';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag/index';
import * as _ from 'lodash-es';
import { SearchScreen } from 'shared/ui-component/SearchScreen';
import { TagInputList } from '../../Input/InputList/TagInputList';
import * as API from 'shared/backend-data/index';
import { Avatar } from '../../Avatar';
import { ImageSizes } from '../../../util/RenderImage';
import { EMPTY_STRING } from '../../../hooks/CustomForm';
import { EllipsisWithTooltip } from '../../EllipsisWithTooltip';
import { WorkstationWorkerLevels } from 'shared/util/WorkerLevel';

export interface DropDownOption<T = any> extends Tag<T> { }

export interface WorkerDropDownOptionWithDetails extends DropDownOption<API.Worker> {
  isChecked?: boolean;
  value: API.Worker;
  trainingSessionCoveragePercentage?: string;
  level?: WorkstationWorkerLevels;
}

export interface ExtraSelectorProps {
  selectorOptions?: Tag[];
  onSelect?: (extraSelector: Tag) => void;
}

export const HEIGHT = 64;
export const WIDTH = 296;

const CloseIcon = require('shared/assets/svg/icon.close.svg').default;

export type DropdownProps<V extends DropDownOption<V>> = {
  inputId?: string;
  options?: V[];
  placeholder?: string;
  searchPlaceholder?: string;
  value?: V;
  values?: V[];
  title?: string;
  tagTitle?: string;
  icon?: React.FC<SvgProps>;
  listIcon?: React.FC<SvgProps>;
  extraSelectorIcon?: React.FC<SvgProps>;
  containerStyle?: StyleProp<ViewStyle>;
  dropDownMenuContainerStyle?: ViewStyle;
  clickableContainerStyle?: StyleProp<ViewStyle>;
  additionFormContainerStyle?: StyleProp<ViewStyle>;
  hasLevelOnly?: boolean;
  notEditable?: boolean;
  textStyle?: StyleProp<TextStyle>;
  additionForm?: boolean;
  multiSelection?: boolean;
  showAdditionFormList?: boolean;
  hasSearch?: boolean;
  createNewOption?: CreateItemProp;
  isTag?: boolean;
  inputListContainerStyle?: StyleProp<ViewStyle>;
  customMessage?: JSX.Element;
  showWorkerAvatar?: boolean;
  openDropDownMenu?: boolean;
  errorMessage?: string;
  noResultMessage?: string;
  disableOptions?: V[];
  extraSelectors?: ExtraSelectorProps;
  disabledExtraSelectors?: Tag[];
  iconColor?: string;
  iconContainerStyle?: StyleProp<ViewStyle>;

  isInputListOpen?: (isOpen: boolean) => void;
  onTagEdit?: (tag: Tag) => Promise<API.Result<void>>;
  onTagDelete?: (tag: Tag) => Promise<API.Result<void>>;
  onTagCreate?: (text: string) => Promise<API.Result<Tag>>;
  renderOption?: (value: V | undefined) => JSX.Element;
  handleSingleSelectionChange?: (inputId: string, value: V | undefined) => void;
  handleMultiSelectionChange?: (
    inputId: string,
    value: V[] | undefined,
    tagsNotFound?: string[],
  ) => void;
  createNewOptionShortcut?: (label: string) => void;
};

export interface DropDownMultiSelectionProps<T extends DropDownOption> {
  inputId?: string;
  options?: T[];
  values: T[] | undefined;
  placeholder?: string | undefined;
  searchPlaceholder?: string | undefined;
  title?: string | undefined;
  tagTitle?: string | undefined;
  icon?: React.FC<SvgProps> | undefined;
  listIcon?: React.FC<SvgProps> | undefined;
  containerStyle?: StyleProp<ViewStyle>;
  dropDownMenuContainerStyle?: ViewStyle;
  clickableContainerStyle?: StyleProp<ViewStyle>;
  additionFormContainerStyle?: StyleProp<ViewStyle>;
  hasLevelOnly?: boolean | undefined;
  notEditable?: boolean | undefined;
  textStyle?: StyleProp<TextStyle>;
  additionForm?: boolean | undefined;
  showAdditionFormList?: boolean | undefined;
  createNewOption?: CreateItemProp;
  noResultMessage?: string;
  disableOptions?: T[];
  disableExtraSelectors?: Tag[];
  isTag?: boolean;
  inputListContainerStyle?: StyleProp<ViewStyle>;
  customMessage?: JSX.Element;
  showWorkerAvatar?: boolean;
  openDropDownMenu?: boolean;
  errorMessage?: string;

  isInputListOpen?: (isOpen: boolean) => void;
  onTagEdit?: (tag: Tag) => Promise<API.Result<void>>;
  onTagDelete?: (tag: Tag) => Promise<API.Result<void>>;
  onTagCreate?: (text: string) => Promise<API.Result<Tag>>;
  renderOption?: (value: T | undefined) => JSX.Element;
  handleChange?: (inputId: string, value: T[] | undefined, tagsNotFound?: string[]) => void;
  createNewOptionShortcut?: (label: string) => void;
}

export interface DropDownSingleSelectionProps<T extends DropDownOption> {
  inputId?: string;
  options?: T[];
  value: T | undefined;
  placeholder?: string | undefined | undefined;
  searchPlaceholder?: string | undefined;
  title?: string | undefined;
  tagTitle?: string | undefined;
  icon?: React.FC<SvgProps>;
  listIcon?: React.FC<SvgProps>;
  extraSelectorIcon?: React.FC<SvgProps>;
  containerStyle?: StyleProp<ViewStyle>;
  dropDownMenuContainerStyle?: ViewStyle;
  clickableContainerStyle?: StyleProp<ViewStyle>;
  hasLevelOnly?: boolean;
  notEditable?: boolean;
  textStyle?: StyleProp<TextStyle>;
  additionForm?: boolean;
  hasSearch?: boolean;
  createNewOption?: CreateItemProp;
  inputListContainerStyle?: StyleProp<ViewStyle>;
  customMessage?: JSX.Element;
  errorMessage?: string;
  noResultMessage?: string;
  hideWorkerLevel?: boolean;
  disableOptions?: T[];
  disabledExtraSelectors?: Tag[];
  iconColor?: string;
  iconContainerStyle?: StyleProp<ViewStyle>;

  renderOption?: (value: T | undefined) => JSX.Element;
  handleChange?: (inputId: string, value: T | undefined) => void;
}

const isMobilePlatform = Platform.OS === 'ios' || Platform.OS === 'android';



const DropDown = <T extends DropDownOption>(
  props: React.PropsWithChildren<DropdownProps<T>>,
): React.ReactElement => {
  const {
    inputId = '',
    options,
    title,
    tagTitle,
    icon,
    iconColor,
    containerStyle,
    hasLevelOnly = false,
    value,
    values,
    notEditable,
    additionForm,
    multiSelection,
    showAdditionFormList = true,
    additionFormContainerStyle,
    listIcon,
    hasSearch,
    createNewOption,
    isTag = false,
    inputListContainerStyle,
    customMessage,
    showWorkerAvatar,
    openDropDownMenu,
    noResultMessage,
    disableOptions,
    disabledExtraSelectors,
    extraSelectorIcon,
    errorMessage,
    textStyle,
    iconContainerStyle,

    isInputListOpen,
    onTagEdit,
    onTagDelete,
    onTagCreate,
    handleSingleSelectionChange,
    handleMultiSelectionChange,
    renderOption,
    createNewOptionShortcut,
  } = props;

  const {
    placeholder = additionForm ? t('common:button.addItem') : t('common:label'),
    searchPlaceholder = t('common:button.search'),
  } = props;

  const modal = ModalUtils.useModal();

  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const [showInputList, setShowInputList] = useState(false);
  const [showSearchScreen, setShowSearchScreen] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setErrorMessage(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (isInputListOpen) isInputListOpen(showInputList);
  }, [showInputList]);

  useEffect(() => {
    if (openDropDownMenu) {
      setShowDropDownMenu(true);
      setShowInputList(true);
    }
  }, [openDropDownMenu]);

  function dropdownClick() {
    if (!options) return;
    if (isMobilePlatform) {
      multiSelection || hasSearch
        ? setShowSearchScreen(true)
        : modal.displayModal(
          ModalUtils.dropdownConfig({
            options: options,
            renderOption: renderOption as any, 
            handleClick: value => {
              if (handleSingleSelectionChange) handleSingleSelectionChange(inputId, value as any);
              
            },
            hasLevelOnly: hasLevelOnly,
          }),
        );
    } else {
      multiSelection || hasSearch ? setShowInputList(true) : setShowDropDownMenu(prev => !prev);
    }
  }

  function handleDeleteItem(index: number) {
    if (!values?.length || !handleMultiSelectionChange) return;

    const _values = [...values];
    _values.splice(index, 1);
    handleMultiSelectionChange(inputId ?? '', _values);
  }

  function handleSelection(inputId: string, values: Tag<any>[], tagsNotFound?: string[]) {
    if (multiSelection && !!handleMultiSelectionChange) {
      if (tagsNotFound?.length) {
        setErrorMessage(
          `'${tagsNotFound.join(', ')}' ${t('common:error.notFound', undefined, false)}`,
        );
      } else {
        setErrorMessage(undefined);
      }
      handleMultiSelectionChange(inputId, tagsNotFound?.length ? [] : values, tagsNotFound);
    } else if (!multiSelection && !!handleSingleSelectionChange)
      handleSingleSelectionChange(inputId, values[0]);
  }

  /**
   * Checks if:
   * 1- It's a web platform
   * 2- There's options to render
   * 3- It's not a Tag InputList
   * 4- It has Search feature
   * 5- There's a handler function for the multi/single selection
   * @returns boolean
   */
  function isShowInputList() {
    return (
      !isMobilePlatform &&
      showInputList &&
      !!options &&
      !isTag &&
      (!!hasSearch || multiSelection) &&
      (!!handleSingleSelectionChange || !!handleMultiSelectionChange)
    );
  }

  /**
   * Checks if:
   * 1- It's a mobile platform
   * 2- There's options to render
   * 3- There's a handler function for the multi/single selection
   * @returns boolean
   */
  function isShowSearchScreen() {
    return (
      isMobilePlatform &&
      showSearchScreen &&
      !!options &&
      (!!handleSingleSelectionChange || !!handleMultiSelectionChange)
    );
  }

  /**
   * Checks if:
   * 1- It's a web platform
   * 2- There's options to render
   * 3- There's option's icon
   * 4- It's a Tag InputList
   * 5- There's a handler function for the multi/single selection
   * @returns boolean
   */
  function isShowTagInputList() {
    return (
      !isMobilePlatform &&
      showInputList &&
      options &&
      listIcon &&
      isTag &&
      (!!handleMultiSelectionChange || !handleSingleSelectionChange)
    );
  }

  /**
   * Checks if:
   * 1- It's a web platform
   * 2- There's options to render
   * 3- It has no Search feature
   * 4- There's a handler function for the single selection
   */
  function isShowDropDownMenu() {
    return (
      !isMobilePlatform && showDropDownMenu && options && !hasSearch && handleSingleSelectionChange
    );
  }

  return (
    <TouchableOpacity
      style={[styles.outerContainer, containerStyle]}
      activeOpacity={1}
      disabled={notEditable === undefined ? undefined : notEditable}
      onPress={dropdownClick}
    >
      {!additionForm ? (
        <TouchableOpacity
          style={[
            styles.container,
            notEditable && styles.disabledClickableContainer,
            {
              borderBottomColor: showErrorMessage === EMPTY_STRING ? Colors.Red : Colors.GreyLight,
            },
          ]}
          disabled={notEditable === undefined ? undefined : notEditable}
          activeOpacity={1}
          onPress={dropdownClick}
        >
          <Text style={[Styles.header, { marginLeft: hasLevelOnly ? Spacings.Large : undefined }]}>
            {((title && value) || (title && !!values?.length)) && title}
          </Text>

          <View
            style={[
              styles.innerContainer,
              {
                paddingTop: notEditable ? Spacings.Unit / 2 : 0,
              },
            ]}
          >
            <View style={[styles.alignItem, hasLevelOnly && styles.alignItemLevelOnly]}>
              <View
                style={[styles.listValueContainer, hasLevelOnly && styles.hasLevelOnlyContainer]}
              >
                {icon && (
                  <IconSVG
                    svgComponent={icon}
                    size={{ width: 24, height: 24 }}
                    color={iconColor ?? Colors.Black}
                    containerStyle={[styles.dropdownIcon, iconContainerStyle]}
                  />
                )}
                {!hasLevelOnly ? (
                  <View style={styles.valueContainer}>
                    <EllipsisWithTooltip
                      textStyle={[
                        Styles.placeholder,
                        !value && !values?.length && { color: Colors.Grey },
                        textStyle,
                        { minWidth: 60 },
                      ]}
                      text={
                        value
                          ? value.label
                          : values?.length
                            ? _.map(values, value => value.label).join(`; `) 
                            : placeholder ?? ''
                      }
                    />
                  </View>
                ) : !value ? (
                  <Text style={[Styles.placeholder, { color: Colors.Grey }]}>{placeholder}</Text>
                ) : null}
              </View>
              {props.renderOption && props.renderOption(value)}
            </View>
            {!notEditable && (
              <IconSVG
                svgComponent={ArrowIcon}
                color={!notEditable && !isTag ? Colors.Black : Colors.Transparent}
                size={{ width: 24, height: 24 }}
                containerStyle={styles.arrowIconContainerStyle}
              />
            )}
          </View>

          {isShowDropDownMenu() && (
            <DropDownMenu
              inputId={props.inputId}
              hasLevelOnly={hasLevelOnly}
              dropDownMenuContainerStyle={props.dropDownMenuContainerStyle}
              options={options ?? []}
              renderOption={renderOption}
              handleMenuDisplay={setShowDropDownMenu}
              handleChange={handleSingleSelectionChange!}
            />
          )}
        </TouchableOpacity>
      ) : !isMobilePlatform && !notEditable ? (
        <View style={[styles.additionFormContainer, containerStyle]}>
          <TouchableOpacity style={styles.additionFormTouchableContainer} onPress={dropdownClick}>
            <Text style={styles.additionFormPlaceholder}>{placeholder}</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}
      {isShowInputList() && (
        <InputList
          inputId={inputId}
          placeholder={searchPlaceholder!}
          options={options!}
          optionIcon={listIcon!}
          extraSelectorIcon={extraSelectorIcon}
          containerStyle={[
            additionForm ? styles.additionFormInputListContainer : styles.inputListContainer,
            additionForm && additionFormContainerStyle ? additionFormContainerStyle : {},
            inputListContainerStyle,
          ]}
          createNewOption={createNewOption}
          initialSelection={multiSelection ? values : value ? [value] : undefined}
          singleSelection={!multiSelection}
          additionFormStyling={additionForm}
          customMessage={customMessage}
          noResultMessage={noResultMessage}
          disableOptions={disableOptions}
          disabledExtraSelectors={disabledExtraSelectors}
          renderOption={renderOption}
          openInputList={setShowInputList}
          handleSelection={handleSelection}
          createNewOptionShortcut={createNewOptionShortcut}
        />
      )}
      {isShowSearchScreen() && (
        <SearchScreen
          showModal={showSearchScreen}
          multiCheck={multiSelection}
          placeholder={placeholder!}
          listOptions={options!}
          initialList={values ?? []}
          renderOption={renderOption}
          handleSelectedTags={values => {
            multiSelection && !!handleMultiSelectionChange
              ? handleMultiSelectionChange(inputId, values)
              : !multiSelection && !!handleSingleSelectionChange
                ? handleSingleSelectionChange(inputId, values[0])
                : null;
          }}
          toggleSearchScreen={() => setShowSearchScreen(!showSearchScreen)}
        />
      )}
      {isShowTagInputList() && (
        <TagInputList
          placeholder={searchPlaceholder!}
          options={options!}
          optionIcon={listIcon!}
          containerStyle={
            additionForm ? styles.additionFormInputListContainer : styles.inputListContainer
          }
          title={tagTitle}
          createNewOption={createNewOption}
          initialSelection={multiSelection ? values : value ? [value] : undefined}
          singleSelection={!multiSelection}
          additionFormStyling={additionForm}
          customMessage={customMessage}
          noResultMessage={noResultMessage}
          onTagCreate={onTagCreate}
          onTagDelete={onTagDelete}
          onTagEdit={onTagEdit}
          openInputList={setShowInputList}
          handleSelection={handleSelection}
        />
      )}
      <View style={styles.listContainer}>
        {showAdditionFormList &&
          additionForm &&
          !isMobilePlatform &&
          _.map(values, (value, index) => {
            return (
              <View key={index} style={styles.listRowContainer}>
                <View style={styles.listRowInnerContainer}>
                  {showWorkerAvatar ? (
                    <View style={{ flexDirection: 'row' }}>
                      <Avatar
                        size={ImageSizes.Small}
                        name={value.label}
                        imgS3Key={value.value?.profilePicture}
                      />
                      <View style={{ width: Spacings.Small }} />
                    </View>
                  ) : listIcon ? (
                    <IconSVG
                      size={{ width: 24, height: 24 }}
                      containerStyle={styles.rowIconContainer}
                      svgComponent={listIcon}
                    />
                  ) : (
                    <></>
                  )}
                  <Text style={styles.rowTextStyle}>{value.label}</Text>
                </View>
                <TouchableOpacity onPress={() => handleDeleteItem(index)}>
                  <IconSVG
                    size={{ width: 16, height: 16 }}
                    containerStyle={styles.deleteRowIconContainer}
                    svgComponent={CloseIcon}
                  />
                </TouchableOpacity>
              </View>
            );
          })}
      </View>
      <View style={styles.errorMessageContainer}>
        <EllipsisWithTooltip text={showErrorMessage ?? ''} textStyle={styles.invalidInput} />
      </View>
    </TouchableOpacity>
  );
};

export const DropDownMultiSelection = <T extends DropDownOption>(
  props: React.PropsWithChildren<DropDownMultiSelectionProps<T>>,
): React.ReactElement => {
  return (
    <DropDown {...props} multiSelection hasSearch handleMultiSelectionChange={props.handleChange} />
  );
};

export const DropDownSingleSelection = <T extends DropDownOption>(
  props: React.PropsWithChildren<DropDownSingleSelectionProps<T>>,
): React.ReactElement => {
  return <DropDown {...props} handleSingleSelectionChange={props.handleChange} />;
};

const styles = StyleSheet.create({
  outerContainer: {
    height: HEIGHT,
    width: WIDTH,
  },
  container: {
    justifyContent: 'flex-end',
    height: HEIGHT,
    borderBottomColor: Colors.GreyLight,
    borderBottomWidth: 1,
  },
  innerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: Spacings.Unit,
  },
  disabledClickableContainer: {
    borderBottomWidth: 0,
  },
  levelOnly: {
    justifyContent: 'flex-end',
  },
  disabled: {
    color: Colors.Grey,
  },
  alignItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  listValueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0.9,
  },
  levelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownIcon: {
    marginLeft: -6,
    marginBottom: -2,
    width: 24,
    height: 24,
  },
  additionFormContainer: {
    justifyContent: 'flex-end',
    height: HEIGHT,
    width: WIDTH,
  },
  additionFormTouchableContainer: {
    height: 36,
    borderRadius: 18,
    backgroundColor: Colors.Background,
    paddingLeft: Spacings.Unit * 5,
    paddingTop: Spacings.Small,
    paddingBottom: Spacings.Unit * 2.5,
  },
  additionFormPlaceholder: { color: Colors.Grey, fontSize: 14, ...TextFontStyles.LatoRegular },
  listContainer: { paddingTop: Spacings.Medium },
  listRowContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: Spacings.Medium,
  },
  listRowInnerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '80%',
  },
  rowIconContainer: {
    width: 24,
    height: 24,
    marginRight: Spacings.xMedium,
  },
  rowTextStyle: { fontSize: fontSize.Regular, ...TextFontStyles.LatoRegular },
  deleteRowIconContainer: { width: 24, height: 24 },
  valueContainer: {
    maxWidth: '90%',
    alignItems: 'center',
  },
  hasLevelOnlyContainer: { flex: 0 },
  arrowIconContainerStyle: {
    width: 24,
    height: 24,
    marginBottom: -2,
  },
  additionFormInputListContainer: { position: 'absolute', top: 25 },
  inputListContainer: {
    position: 'absolute',
    top: 24,
  },
  errorMessageContainer: {
    top: -18,
  },
  invalidInput: {
    color: Colors.Red,
    fontSize: fontSize.Small,
    marginTop: Spacings.Unit - 1,
    alignSelf: 'center',
  },
  alignItemLevelOnly: {
    justifyContent: 'flex-start',
  },
});
