import React from 'react';
import { View, Text, StyleProp, ViewStyle } from 'react-native';
import { Styles } from './Styles';
import * as API from 'shared/backend-data';
import { LevelTargetAndMaintainOption } from '../../workstation-worker-menu/LevelTargetAndMaintainOption';
import { ProgressCircle } from 'sharedweb/src/ProgressCircle/progressCircle';
import { t } from 'shared/localisation/i18n';
import { WorkerSkillInfoWithRequirementsRow } from '.';
import { getValidityColor } from 'shared/util/skillUi';
import _ from 'lodash';

interface Props {
  workerWorkstation: API.WorkerWorkstation;
  workstationSkillsRequiredInLevels: API.WorkstationSkillsRequiredInLevels | undefined;
  percentage: number;
  estimatedTime: string | undefined;
  style?: StyleProp<ViewStyle>;
  skillValidity?: WorkerSkillInfoWithRequirementsRow | undefined;
  showTrainingLevel?: boolean;
  maintainInfoModalCoordinates?:
    | {
        x?: number | undefined;
        y?: number | undefined;
      }
    | undefined;
  showEstimatedTime?: boolean;
  showTargetAndMaintain: boolean;

  handleMaintainSelect: (maintainLevel: API.WorkstationWorkerLevels) => void;
  handleUnassignWorkerAndStopTrainings(): Promise<void>;
  setTrainingLevel(trainingLevel: API.WorkstationWorkerLevels | undefined): Promise<void>;
  setShowTrainingModal: (show: boolean) => void;
}

export const LevelProgressAndTrainingStatus: React.FC<Props> = props => {
  const {
    estimatedTime,
    percentage,
    workerWorkstation,
    workstationSkillsRequiredInLevels,
    style,
    showTrainingLevel,
    maintainInfoModalCoordinates,
    showEstimatedTime = true,
    skillValidity,
    showTargetAndMaintain,

    setTrainingLevel,
    setShowTrainingModal,
    handleUnassignWorkerAndStopTrainings,
    handleMaintainSelect,
  } = props;

  return (
    <View style={style}>
      <View style={Styles.trainingStatusContainer}>
        {/**
         *
         */}
        {!API.isWorkerTargetingALevelOnWorkstation(workerWorkstation) ? (
          <LevelTargetAndMaintainOption
            workerWorkstation={workerWorkstation}
            handleMaintainSelect={handleMaintainSelect}
            handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
            workstationSkillsRequiredInLevels={workstationSkillsRequiredInLevels}
            setTrainingLevel={setTrainingLevel}
            setShowTrainingModal={setShowTrainingModal}
            maintainInfoModalCoordinates={maintainInfoModalCoordinates}
            showIconWhenNoTarget
            style={Styles.levelTargetAndMaintainContainerForNoTarget}
          />
        ) : (
          <View style={Styles.progressCircleHeaderContainer}>
            <View style={Styles.progressCircleContainer}>
              <ProgressCircle percentage={_.isNaN(percentage) ? 0 : percentage} />
            </View>
          </View>
        )}
        {showEstimatedTime && (
          <Text style={Styles.estimationDateTextHeader}>
            <View>
              {showTrainingLevel &&
                workerWorkstation.targetLevel &&
                API.isWorkerInTrainingOnWorkstation(workerWorkstation) && (
                  <Text>
                    {t(
                      workerWorkstation.activeTrainingSessions.isEverySkillToRenewOrAcquireCovered
                        ? 'alex:workstationWorkerLevelMenu.inTrainingOnLevel'
                        : 'alex:workstationWorkerLevelMenu.inPartialTrainingOnLevel',
                      {
                        level: t(
                          `glossary:level_${API.api2workstationWorkerLevels(
                            workerWorkstation.targetLevel,
                          )}`,
                          undefined,
                          false,
                        ),
                      },
                    )}
                  </Text>
                )}
              <Text>{estimatedTime}</Text>
            </View>
          </Text>
        )}
      </View>
      {skillValidity && (
        <View style={Styles.skillValidtyContainer}>
          <Text style={Styles.estimationDateTextHeader}>
            {t('alex:skillConformityModal.headers.1')}:
          </Text>
          <Text
            style={[
              Styles.estimationDateTextHeader,
              { color: getValidityColor(skillValidity.validity) },
            ]}
          >
            {skillValidity.stateString}
          </Text>
        </View>
      )}
    </View>
  );
};
