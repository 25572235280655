import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, ScrollView, InteractionManager } from 'react-native';
import { t } from 'shared/localisation/i18n';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import styles from './styles';
import logger from 'shared/util/Logger';
import { IconSVG } from 'shared/ui-component/Icon';
import * as _ from 'lodash-es';
import { Colors } from 'shared/styles';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import WebModal from 'modal-react-native-web';
import { ModalBackgroundStyle, ModalCardStyle } from 'shared/styles/ModalStyles';
import { ModalHeader } from 'shared/ui-component/Modal/Header';
import { InputText } from 'shared/ui-component/Input/InputText/InputText';
import { ModifyWorkerInputIds } from '../../my-factory/workers/component/modify-worker-modal/container';
import { RolePermissionList } from 'shared/layout/organization-unit/RolePermissionList';
import { YellowButton, DeleteButton } from 'shared/ui-component/Button';
import { ModalUtils } from 'shared/ui-component/Modal';
import {
  observerRoleId,
  adminRoleId,
  workerRoleId,
  workerManagerRoleId,
  qualityRoleId,
  humanRessourceRoleId,
  isWorkerAdminOnRootUnit,
} from 'shared/backend-data';
import { DropDownSingleSelection } from 'shared/ui-component/DropDown';
import { CompanySettingsInputIds } from '../container';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';

interface Props {
  setLoading: (value: boolean) => void;
  handleInputDropdown: (inputName: string, value: DropDownOption) => void;
  nameDisplayOptions?: DropDownOption[];
  selectedNameDisplayOption?: DropDownOption;
  isAdmin: boolean;
}

interface DisplayRoleProps {
  role: API.Role;
}

const EditIcon = require('shared/assets/svg/icon.edit.svg').default;

export const RoleAndPermissions: React.FC<Props> = props => {
  const {
    setLoading,
    selectedNameDisplayOption,
    nameDisplayOptions,
    handleInputDropdown,
    isAdmin,
  } = props;
  const modal = ModalUtils.useModal();
  const isMounted = useIsMounted();
  const [roles, setRoles] = useState<API.Role[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isWorkerAdmin, setIsWorkerAdmin] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<API.Role>();
  const [roleName, setRoleName] = useState<string>();
  const [permissions, setPermissions] = useState<API.Permission[]>([]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await fetchRoles();
      if (!isMounted.current) return;

      await setIsAdminParamForCurrentUser();
      if (!isMounted.current) return;
    });
  }, []);

  async function setIsAdminParamForCurrentUser() {
    const isAdminOnRootUnit = await isWorkerAdminOnRootUnit();
    if (API.isFailure(isAdminOnRootUnit)) {
      logger.warn(isAdminOnRootUnit);
      return;
    }

    setIsWorkerAdmin(isAdminOnRootUnit);
  }

  async function fetchRoles() {
    const _roles = await API.getRoles();
    if (!isMounted.current) return;
    if (API.isFailure(_roles)) {
      logger.warn(_roles);
      return;
    }

    setRoles(
      _.orderBy(
        _roles,
        [
          role => {
            return role.permissions.length;
          },
        ],
        ['desc'],
      ),
    );
  }

  const _handleInputDropdown = (inputId: string, value?: DropDownOption) => {
    if (value) {
      handleInputDropdown(inputId, value);
    }
  };

  function handlePermission(role: API.Role): void {
    setShowModal(true);
    setSelectedRole(role);
    setRoleName(role.name);
    setPermissions(API.deepClone(role.permissions));
  }

  function handleInput(inputId: string, value: string): void {
    setRoleName(value);
  }

  function getRoleCount(permissions: readonly API.Permission[]): number {
    let count = 0;
    
    permissions.forEach(permission => {
      if (
        permission === API.Permission.workersSkillsProof_edit ||
        permission === API.Permission.workersDetail_edit
      ) {
      } else {
        count++;
      }
    });

    return count;
  }

  const DisplayRole: React.FC<DisplayRoleProps> = props => {
    const { role } = props;
    const [showEditIcon, setShowEditIcon] = useState<boolean>(false);
    const ref = useCallOnHover<View>(
      '',
      () => setShowEditIcon(true),
      () => setShowEditIcon(false),
    );

    return (
      <View style={styles.eachRoleList} ref={ref}>
        <View style={styles.roleNameContainer}>
          <Text style={styles.roleName}>{role.name}</Text>
          <View style={styles.permissionCountContainer}>
            {!getRoleCount(role.permissions) ? (
              <Text style={styles.permissions}>{t('common:tenant.noPermission')}</Text>
            ) : (
              <Text style={styles.permissions}>{`${getRoleCount(role.permissions)} ${t(
                'glossary:workerPermission',
                {
                  count: getRoleCount(role.permissions),
                },
              )}`}</Text>
            )}
          </View>
        </View>

        {role.id !== observerRoleId && isWorkerAdmin ? (
          <TouchableOpacity onPress={() => handlePermission(role)} style={{ flex: 0.1 }}>
            <IconSVG svgComponent={EditIcon} color={!showEditIcon ? Colors.White : Colors.Black} />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  };

  function onPermissionChange(permissions: API.Permission[]): void {
    setPermissions(permissions);
  }

  async function handleRoleSubmit(): Promise<void> {
    setLoading(true);
    if (selectedRole) {
      const updateRole = await API.updateRole({
        ...selectedRole,
        name: roleName,
        permissions: permissions,
      });
      if (!isMounted.current) return;
      if (API.isFailure(updateRole)) {
        logger.warn(updateRole);
        setLoading(false);
        return;
      }
    } else {
      if (!roleName) return;

      const createRole = await API.createRole({
        name: roleName,
        permissions: permissions,
      });
      if (!isMounted.current) return;
      if (API.isFailure(createRole)) {
        logger.warn(createRole);
        setLoading(false);
        return;
      }
    }

    setLoading(false);
    handleCloseModal();
    fetchRoles();
  }

  function handleCloseModal(): void {
    setSelectedRole(undefined);
    setShowModal(false);
    setPermissions([]);
    setRoleName(undefined);
  }

  async function deleteRole(): Promise<void> {
    if (!selectedRole) return;

    setLoading(true);
    const deleteRole = await API.deleteRole(selectedRole.id);
    if (!isMounted.current) return;
    if (API.isFailure(deleteRole)) {
      if (API.isFailureType(deleteRole, 'DeletionVeto')) {
        ModalUtils.showVetoModal(t('common:tenant.roleAssingedByWorker'), deleteRole);
      }
      logger.warn(deleteRole);
      setLoading(false);
      return;
    }

    setLoading(false);
    handleCloseModal();
    fetchRoles();
  }

  function handleDeleteRole(): void {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningAcceptButton: t('common:button.ok'),
        warningCancelButton: t('common:button.no'),
        warningAcceptCallback: deleteRole,
        warningMessage: t('common:tenant.deleteRoleWarning'),
      }),
    );
  }

  function isDefaultRole(): boolean {
    return (
      selectedRole?.id === observerRoleId ||
      selectedRole?.id === adminRoleId ||
      selectedRole?.id === workerRoleId ||
      selectedRole?.id === workerManagerRoleId ||
      selectedRole?.id === qualityRoleId ||
      selectedRole?.id === humanRessourceRoleId
    );
  }

  return (
    <View style={[styles.width, styles.roleContainer]}>
      <View style={styles.roleTitleContainer}>
        <Text style={styles.title}>{t('common:tenant.roleAndPermissions')}</Text>
        <Text style={styles.roleDescription}>{t('common:tenant.roleDescription')}</Text>
      </View>

      <View style={styles.roleListContainer}>
        <ScrollView>
          {roles.map((role, index) => (
            <DisplayRole role={role} key={index} />
          ))}
        </ScrollView>
        <TouchableOpacity
          onPress={() => setShowModal(true)}
          style={styles.eachRoleList}
          disabled={!isWorkerAdmin}
        >
          <Text style={styles.permissions}>{t('common:tenant.addRole')}</Text>
        </TouchableOpacity>
      </View>

      {/** workers display of names */}
      <View style={[styles.displayOfNamesContainer]}>
        <Text style={styles.title}>{t('glossary:worker')}</Text>
        <DropDownSingleSelection
          title={t('common:tenant.displayOfNames.0')}
          inputId={CompanySettingsInputIds.WorkerFamilyNameFirst}
          containerStyle={[styles.width, { zIndex: 2 }]}
          options={nameDisplayOptions}
          handleChange={_handleInputDropdown}
          value={selectedNameDisplayOption}
          hasSearch
          notEditable={!isAdmin}
        />
      </View>

      {showModal && (
        <WebModal
          animationType="fade"
          transparent
          visible={showModal}
          onRequestClose={handleCloseModal}
        >
          <View style={[ModalBackgroundStyle]}>
            <View style={ModalCardStyle}>
              <ModalHeader
                handleModalClose={handleCloseModal}
                title={t('common:tenant.roleOperator')}
              />
              <ScrollView>
                <View style={styles.roleInputContainer}>
                  <InputText
                    containerStyle={styles.roleTextInput}
                    placeHolder={t('common:tenant.roleInputTitle')}
                    defaultValue={roleName}
                    onTextChange={handleInput}
                    inputId={ModifyWorkerInputIds.RoleName}
                  />

                  <View>
                    <Text style={styles.defaultPermissionText}>
                      {t('common:tenant.defaultPermissions')}
                    </Text>
                    <View style={{ marginRight: 40 }}>
                      <RolePermissionList
                        role={selectedRole}
                        permissions={selectedRole?.permissions ?? permissions}
                        onPermissionChange={onPermissionChange}
                      />
                    </View>
                    <View style={styles.buttonContainer}>
                      {selectedRole && !isDefaultRole() && (
                        <DeleteButton
                          text={t('common:button.delete')}
                          textStyle={styles.deleteButton}
                          style={styles.deleteButtonContainer}
                          onPress={handleDeleteRole}
                        />
                      )}
                      <YellowButton
                        text={t('common:button.save')}
                        style={styles.buttonRoleSave}
                        textStyle={styles.buttonText}
                        onPress={handleRoleSubmit}
                        disabled={!roleName}
                      />
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>
          </View>
        </WebModal>
      )}
    </View>
  );
};
