import { StyleSheet, Platform } from 'react-native';
import { TextFontStyles, Spacings, Colors, fontSize } from 'shared/styles';
import * as _ from 'lodash-es';

const isWebPlatform = Platform.OS === 'web';

export const Styles = StyleSheet.create({
  fileRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: Colors.GreyLighter,
    borderRadius: 20,
    borderWidth: 1,
    paddingLeft: Spacings.Small,
    marginBottom: 6,
  },
  fileRowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileNameTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: isWebPlatform ? fontSize.Small : fontSize.Medium,
    maxWidth: '85%',
  },
  container: {},
  touchableContainer: {
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingBottom: Spacings.Unit / 2,
    marginLeft: Spacings.Unit,
  },
  filesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  disabledTextStyle: {
    color: Colors.Grey,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
  },
  divider: { height: Spacings.Unit * 6 },
  filesStyle: { width: 130, marginRight: Spacings.Unit },
  iconStyle: { width: Spacings.Standard, height: Spacings.Standard },
  containerStyle: {
    width: 28,
    height: 28,
    borderRadius: Spacings.Large,
  },
  buttonsContainer: { position: 'absolute', left: -2, bottom: Spacings.Small },
  iconContainerStyle: {
    width: Spacings.Large,
    height: Spacings.Large,
    backgroundColor: Colors.Yellow,
    borderRadius: Spacings.Standard,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
  },
});
