import { StyleSheet } from 'react-native';
import { Spacings, Colors, TextFontStyles, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: Colors.ModalBackground,
    justifyContent: 'center',
    alignItems: 'center',
  },
  passwordFormContainer: {
    backgroundColor: Colors.White,
    padding: Spacings.Small,
    borderRadius: 8,
  },
  formTitle: {
    color: Colors.Black,
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    marginBottom: Spacings.Standard,
  },
  setTopMarginForOldPasswordInput: {
    marginTop: '4.5vh',
  },

  setTopMarginForNewPasswordInput: {
    marginTop: '1.2vh',
  },
  cancelButton: {
    alignSelf: 'flex-end',
    marginBottom: Spacings.Standard,
  },
  forgetPasswordText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    fontSize: fontSize.Small,
  },
  apiErrorLabel: {
    color: '#F44336',
    fontFamily: 'Lato, Regular',
    fontSize: fontSize.Regular,
    textDecorationLine: 'underline',
    marginTop: '2vh',
    textAlign: 'center',
  },
});

export default styles;
