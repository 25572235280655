import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';
import { WIDTH } from '../../ui-component/DropDown/DropDown';

const styles = StyleSheet.create({
  eachSectionContainer: {
    marginBottom: Spacings.Small,
    backgroundColor: Colors.GreyUltraLight,
    paddingLeft: Spacings.xMedium,
    borderRadius: Spacings.Small,
  },
  label: {
    ...TextFontStyles.LatoBold,
    fontSize: 12,
    color: Colors.MediumGray,
    marginRight: 10,
  },
  regionSiteActivityDropDownContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.White,
    zIndex: 4,
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  roleDropDownContainer: {
    marginLeft: Spacings.Large - Spacings.Unit,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  roleDropdown: {
    height: 64,
  },
  switchContainer: {
    flexDirection: 'row',
    height: 44,
    alignItems: 'center',
  },
  switchLabel: {
    paddingLeft: Spacings.Small + Spacings.Unit,
  },
  tooltipContainer: {
    top: Spacings.Standard,
    right: -80,
    width: 250,
  },
  toolTip: {
    marginLeft: Spacings.Big,
  },
  checkBoxContainer: {
    flexDirection: 'row',
    marginBottom: Spacings.CardPadding,
    marginLeft: Spacings.Unit,
  },
  checkBox: {
    paddingTop: Spacings.Medium,
  },
  firstCheckBox: {
    paddingTop: Spacings.Unit,
  },
  unitContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  dropdownDescription: {
    fontSize: fontSize.Small,
    color: Colors.Grey,
    ...TextFontStyles.LatoRegular,
    paddingTop: Spacings.Small,
  },
  trashIconStyle: {
    marginLeft: Spacings.xMedium,
  },
  toggleIcon: {},
  checkBoxText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    marginLeft: Spacings.xMedium,
  },
  rootContainer: {
    paddingTop: Spacings.xMedium,
    paddingBottom: Spacings.CardPadding,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Background,
  },
  unitInfoContainer: {
    top: Spacings.Standard,
    right: -50,
  },
  iconContainer: {
    width: 90,
    top: Spacings.Big - Spacings.Unit,
    flexDirection: 'row',
    paddingLeft: Spacings.xMedium,
    height: Spacings.Standard,
  },
  unitsDropDownInputListContainerStyle: {
    width: WIDTH * 2 + Spacings.Small,
  },
});

export const newStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100',
  },
  treeContainer: {
    position: 'absolute',
    width: 'wrap',
    flexDirection: 'row',
    padding: 10,
  },
  treeView: {
    marginLeft: 5,
    width: 250,
  },
  NodeContainer: {
    marginLeft: 5,
    margin: 5,
    marginRight: 5,
  },
  arrowImage: {
    height: 20,
    width: 30,
    transform: [{ rotate: '-90deg' }],
  },
  ImageContainer: { alignSelf: 'center' },
  arrowDownImage: {
    height: 20,
    width: 30,
    alignSelf: 'center',
  },
  arrowMenuImage: {
    height: 6,
    width: 12,
    marginLeft: 5,
    alignSelf: 'center',
  },
  NodeTouchableOpacity: { flexDirection: 'row' },
  PathText: {
    alignSelf: 'center',
    fontSize: fontSize.Medium,
  },
  SelectedText: {
    alignSelf: 'center',
    marginLeft: 5,
    fontSize: fontSize.Medium,
  },
});

export default styles;
