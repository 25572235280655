import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { MyHub } from '../../util/MyHub';
import logger from '../../util/Logger';
import { searchMatch, deepFreeze } from '../../util-ts/Functions';
import { FactoryCache } from './FactoryCache';
import { DataType, Factory, ValueOf } from 'shared/backend-data';
import { FactoryMutationCacheEvent } from '../DataLayer';
import { CacheData } from './Cache';
import { AppContext } from '../../context/AppContext';


export const TreeDataTypes = {
  [DataType.ORGUNIT]: DataType.ORGUNIT,
  [DataType.WORKSTATION]: DataType.WORKSTATION,
} as const;
export type TreeDataType = ValueOf<typeof TreeDataTypes>;
export function isTreeDataType(dataType: API.DataType): dataType is TreeDataType {
  return Object.values(TreeDataTypes).includes(dataType as TreeDataType); 
}

export type TreeObject<T extends TreeDataType = TreeDataType> = T extends DataType.ORGUNIT
  ? API.OrganizationalUnit
  : T extends DataType.WORKSTATION
  ? API.Workstation
  : API.OrganizationalUnit | API.Workstation; 

export type TreeNode<T extends TreeDataType = TreeDataType> = {
  /** id of the underlying Factory's sk (unique through all Factory tpes)*/
  id: string;
  parentIds: string[];
  object: TreeObject<T>;
  children: TreeNode<TreeDataType>[];
} & {
  factory: Factory<
    T extends DataType.ORGUNIT
      ? DataType.ORGUNIT
      : T extends DataType.WORKSTATION
      ? DataType.WORKSTATION
      : TreeDataType
  >;
};
/**
 * Stronger typing that TreeNode where the undefined state TreeDataTypes is not allowed
 */
export type TreeNode2<T extends TreeDataType> = T extends DataType.ORGUNIT
  ? TreeNode<DataType.ORGUNIT>
  : TreeNode<DataType.WORKSTATION>;

/**
 * Tells whether the passed TreeNode is of the given dataType and cast it (for typescript)
 * @param dataType
 * @param treeNode
 * @returns
 */
export function isTreeNode<T extends TreeDataType>(
  dataType: T | undefined,
  treeNode: TreeNode<TreeDataType>,
): treeNode is TreeNode<T> {
  return !dataType || API.isFactory(dataType, treeNode.factory);
}
export function isTreeNode2<T extends TreeDataType>(
  dataType: T,
  treeNode: TreeNode<TreeDataType>,
): treeNode is TreeNode2<T> {
  return API.isFactory(dataType, treeNode.factory);
}

/**
 * An extention of the TreeNode type with extra property for use
 * in the orgUnitKPI and the worker assignment tables
 */
export type TreeTableNode = TreeNode & {
  isExpanded: boolean;
};

export interface NodeState {
  collapsed?: boolean;
  selected?: boolean;
}

type FilteredTreeNode<V extends TreeNode, T extends TreeDataType> = V & {
  children: TreeNode2<T>[];
};

interface TreeData {
  treeMap: Map<string, TreeNode>;
  rootNode?: TreeNode<DataType.ORGUNIT> | undefined;
  numberOfWorkstations: number;
  numberOfOrganizationalUnits: number;
  topTreeNodes: TreeNode<DataType.ORGUNIT>[];
}

export class Tree<T extends TreeDataType = TreeDataType> extends FactoryCache<T, TreeData> {
  private static createEmptyData(): TreeData {
    return {
      treeMap: new Map(),
      numberOfOrganizationalUnits: 0,
      numberOfWorkstations: 0,
      topTreeNodes: [],
    };
  }

  private static masterDataType: TreeDataType = DataType.ORGUNIT;
  private static _masterData: CacheData<TreeData> = {
    
    data: Tree.createEmptyData(),
    isFilled: false,
    isInitialized: false,
  };
  private static filledTreeDataTypes: Set<TreeDataType> = new Set();
  private static numberOfTreeDataTypes: number = Object.keys(TreeDataTypes).length;

  constructor(pk: string, dataType: T, fill: boolean) {
    if (!fill) logger.warn('For Tree, the fillPolicy is overwritten to true !');
    super(pk, dataType, true, Tree.createEmptyData());
  }

  private setFilledDataTypes(): void {
    if (this.cacheData.isFilled) {
      Tree.filledTreeDataTypes.add(this.dataType);
    } else {
      Tree.filledTreeDataTypes.delete(this.dataType);
    }
  }

  private static get masterData(): CacheData<TreeData> {
    if (Tree.filledTreeDataTypes.size !== Tree.numberOfTreeDataTypes) {
      throw new Error(
        'Tree is not filled yet. You shall not call Tree before cache is fully initialized for all TreeDataTypes.',
      );
    }
    return Tree._masterData;
  }

  async restore(): Promise<boolean> {
    const result = await super.restore();

    this.setFilledDataTypes();

    return result;
  }

  protected async getPersistedData(): Promise<CacheData<TreeData> | null> {
    const persistedData = await super.getPersistedData();

    if (this.dataType === Tree.masterDataType && persistedData !== null) {
      Tree._masterData = persistedData;
    }

    return persistedData;
  }

  protected restore_deepFreezeFactories(data: TreeData): void {
    data.treeMap.forEach(value => {
      deepFreeze(value.factory);
    });
  }

  async fill(force: boolean = false): Promise<void> {
    const result = await super.fill(force);

    this.setFilledDataTypes();

    return result;
  }

  protected structureData(factories: Factory<T>[]): TreeData {
    
    factories.forEach(treeObject => {
      this.createTreeNode(treeObject);
    });

    
    Tree._masterData.data.treeMap.forEach(treeNode => {
      this.hookTreeNodeToParent(treeNode);
    });

    this.setTopTreeNodes();

    return this.dataType === Tree.masterDataType ? Tree._masterData.data : Tree.createEmptyData();
  }

  async purge(): Promise<void> {
    const result = await super.purge();

    this.setFilledDataTypes();

    return result;
  }

  protected purge_inMemoryData(data: TreeData): void {
    if (this.dataType === Tree.masterDataType) {
      Tree._masterData.data = Tree.createEmptyData();
    }
  }

  protected async _persist(data: CacheData<TreeData>): Promise<boolean> {
    let result = await super._persist(data);

    
    
    if (this.dataType !== Tree.masterDataType) {
      
      const context = AppContext.getContext();
      if (API.isFailure(context)) {
        logger.warn('App context is not set, persist for the Tree failed');
        return false;
      } else {
        const masterDataFactoryCache = Tree.getFactoryCache(Tree.masterDataType);
        result = result && (await masterDataFactoryCache.persist(true));
      }
    }

    return result;
  }

  protected _getFactory(skOrIds: string | string[]): Factory<T> | undefined {
    const sk = typeof skOrIds === 'string' ? skOrIds : skOrIds.join(API.SeparatorIds);
    const treeNode = Tree.getTreeNode(sk);
    if (API.isFailure(treeNode)) {
      logger.debug(treeNode.message, treeNode);
      return;
    }

    
    if (API.isFactory(this.dataType, treeNode.factory)) {
      return treeNode.factory;
    }
  }

  protected _getFactories(input?: {
    index: 'sk' | 'data';
    type: 'equal' | 'startingWith';
    factorySkOrData_or_keys: string | string[];
  }): API.Factory<T>[] {
    if (input?.index === 'data') {
      logger.error("Tree objects don't have factory.data index");
      return [];
    }

    const skStartingWith = !input
      ? undefined
      : typeof input.factorySkOrData_or_keys === 'string'
      ? input.factorySkOrData_or_keys
      : input.factorySkOrData_or_keys.join(API.SeparatorIds); 

    const factories: Factory<T>[] = [];
    for (const treeNode of Tree.masterData.data.treeMap.values()) {
      
      if (API.isFactory(this.dataType, treeNode.factory)) {
        if (!skStartingWith || treeNode.factory.sk.startsWith(skStartingWith)) {
          factories.push(treeNode.factory);
        }
      }
    }
    return factories;
  }

  protected update_handleFactoryMutation(cacheEvent: FactoryMutationCacheEvent<T, false>): void {
    const { mutationType, factory, localPreviousFactory } = cacheEvent;

    if (mutationType === 'updateFactory') {
      this.handleFactoryUpdate(factory);
    } else if (mutationType === 'deleteFactory') {
      const id = API.isFactory(DataType.ORGUNIT, factory)
        ? factory.organizationalUnit.id
        : factory.workstation.id;
      const treeNode = Tree._masterData.data.treeMap.get(id);
      if (treeNode) {
        this.removeTreeNode(treeNode);
        this.treeStructureChanged(factory);
      }
    } else if (mutationType === 'createFactory') {
      if (localPreviousFactory) return; 

      const treeNode = this.createTreeNode(factory);
      this.hookTreeNodeToParent(treeNode);
      this.treeStructureChanged(factory);
    }
  }

  private treeStructureChanged(factory: Factory<TreeDataType>) {
    if (API.isFactory(API.DataType.ORGUNIT, factory)) {
      
      Tree._masterData.data.topTreeNodes = [];

      this.setTopTreeNodes();
    }

    MyHub.dispatchBusinessObject('TreeStructureUpdate', {});
  }

  private createTreeNode(factory: Factory<TreeDataType>, children: TreeNode[] = []): TreeNode {
    const workstationOrOrgUnit = {
      ...(API.isFactory(DataType.ORGUNIT, factory)
        ? factory.organizationalUnit
        : factory.workstation),
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    };
    const parentIds: string[] = workstationOrOrgUnit.pathIds.filter(
      _value => _value !== workstationOrOrgUnit.id,
    );

    const treeNode: TreeNode = {
      id: workstationOrOrgUnit.id, 
      parentIds,
      object: workstationOrOrgUnit,
      factory,
      children,
    };

    if (workstationOrOrgUnit.parentId === API.rootOrganizationalUnitParentId)
      Tree._masterData.data.rootNode = treeNode as TreeNode<DataType.ORGUNIT>; 

    Tree._masterData.data.treeMap.set(treeNode.id, treeNode);
    if (treeNode.factory.dataType === DataType.ORGUNIT) {
      Tree._masterData.data.numberOfOrganizationalUnits++;
    } else {
      Tree._masterData.data.numberOfWorkstations++;
    }

    return treeNode;
  }

  /**
   * Remove the treeNode from the TreeStructure
   * @param treeNode
   */
  private removeTreeNode(treeNode: TreeNode): void {
    const parentTreeNode = Tree.getParentTreeNode(treeNode, true);
    if (parentTreeNode) {
      parentTreeNode.children = parentTreeNode.children.filter(child => child.id !== treeNode.id);
    }

    if (treeNode.object.parentId === API.rootOrganizationalUnitParentId)
      Tree._masterData.data.rootNode = undefined;

    Tree._masterData.data.treeMap.delete(treeNode.id);

    if (treeNode.factory.dataType === DataType.ORGUNIT) {
      Tree._masterData.data.numberOfOrganizationalUnits--;

      this.setTopTreeNodes();
    } else {
      Tree._masterData.data.numberOfWorkstations--;
    }
  }

  /**
   * Hook a TreeNode to its parentTreeNode at he right position/order
   * (update the parentTreeNode's children array)
   * It shall be called after #createTreeNode()
   * @param treeNode to hook
   */
  private hookTreeNodeToParent(treeNode: TreeNode): void {
    const parentTreeNode = Tree.getParentTreeNode(treeNode, true);

    if (!parentTreeNode) {
      
    } else {
      const parentTreeNodeChildrenIds = parentTreeNode.children.map(child => child.id);

      
      if (parentTreeNodeChildrenIds.includes(treeNode.id)) {
        
        
        
      } else {
        parentTreeNode.children.splice(
          _.sortedIndex(
            parentTreeNode.children.map(child => child.object.order),
            treeNode.object.order,
          ),
          0,
          treeNode,
        );
      }
    }
  }

  private handleFactoryUpdate(factory: Factory<TreeDataType>): void {
    const workstationOrOrgUnit = {
      ...(API.isFactory(DataType.ORGUNIT, factory)
        ? factory.organizationalUnit
        : factory.workstation),
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    };
    const treeNode = Tree._masterData.data.treeMap.get(workstationOrOrgUnit.id);

    if (treeNode) {
      
      if (
        treeNode.object.parentId !== workstationOrOrgUnit.parentId ||
        treeNode.object.order !== workstationOrOrgUnit.order ||
        treeNode.object.id !== workstationOrOrgUnit.id 
      ) {
        const currentTreeNodeChildren = treeNode.children;
        this.removeTreeNode(treeNode);
        const newTreeNode = this.createTreeNode(factory, currentTreeNodeChildren);
        this.hookTreeNodeToParent(newTreeNode);

        this.treeStructureChanged(factory);
      } else {
        
        treeNode.factory = factory;
        treeNode.object = workstationOrOrgUnit;
      }
    } else {
      logger.error(
        'User shall not receive an update if user does not have permissions on it.' +
          'If user does have permission on ' +
          workstationOrOrgUnit.id +
          'currentTreeNode should exist',
        factory,
      );
    }
  }

  /**
   * Get the number of orgUnits in the tree
   */
  static getNumberOfOrganizationalUnits(): number {
    return Tree.masterData.data.numberOfOrganizationalUnits;
  }

  /**
   * Get the number of workstations in the tree
   */
  static getNumberOfWorkstations(): number {
    return Tree.masterData.data.numberOfWorkstations;
  }

  /**
   * Get the parentTreeNode of the given treeNode.
   * Return null if the treeNode is the root node or if
   * the User doesn't have read permissions on this parent TreeObject
   * @param treeNode
   * @param _calledFromPrivateTreefunction RESERVED for calling this function from a private function inside this Tree class
   */
  static getParentTreeNode(
    treeNode: TreeNode,
    _calledFromPrivateTreefunction: boolean = false,
  ): TreeNode | null {
    if (!treeNode.parentIds.length) return null; 
    const parentId = treeNode.parentIds[treeNode.parentIds.length - 1]!; 

    const parentTreeNode = (
      _calledFromPrivateTreefunction ? Tree._masterData : Tree.masterData
    ).data.treeMap.get(parentId);
    if (!parentTreeNode) {
      
      return null;
    }

    return parentTreeNode;
  }

  /**
   * Get the parent OrgUnit of the given OrgUnit/Workstation.
   * Return null if the OrgUnit/Workstation is the root OrgUnit
   * or a Failure if the User doesn't have read permissions on the parent OrgUnit.
   * @param orgUnitOrWorkstationId
   */
  static getParent(orgUnitOrWorkstationId: string): API.Result<API.OrganizationalUnit | null> {
    const treeNode = Tree.getTreeNode(orgUnitOrWorkstationId);
    if (API.isFailure(treeNode)) return treeNode;

    const parentTreeNode = Tree.getParentTreeNode(treeNode);
    if (!parentTreeNode) return parentTreeNode;

    return parentTreeNode.object as API.OrganizationalUnit; 
  }

  /**
   * Get all the children of a given TreeNode
   * @param treeNode
   * @param includeNested (optional, default = false) if true include nested children (in a flat flavor)
   * @param dataType (optional) if set, filters the returned children
   */
  static getChildrenTreeNode<T extends TreeDataType>(
    treeNode: TreeNode,
    includeNested = false,
    dataType?: T,
  ): TreeNode<T>[] {
    const children: TreeNode<T>[] = [];

    for (const child of treeNode.children) {
      if (isTreeNode(dataType, child)) children.push(child);

      if (includeNested) {
        children.push(...Tree.getChildrenTreeNode(child, includeNested, dataType));
      }
    }

    return _.uniqBy(children, 'id'); 
  }

  /**
   * Get all the children of a given OrganizationalUnit or Workstation
   * @param orgUnitOrWorkstationId
   * @param includeNested if true include nested children (in a flat flavor)
   * @param dataType (optional) if set, filters the returned children
   */
  static getChildren<T extends TreeDataType>(
    orgUnitOrWorkstationId: string,
    includeNested = false,
    dataType?: T,
  ): API.Result<TreeObject<T>[]> {
    const treeNode = Tree.getTreeNode(orgUnitOrWorkstationId);
    if (API.isFailure(treeNode)) return treeNode;

    const children = this.getChildrenTreeNode(treeNode, includeNested, dataType);
    return children.map(child => child.object);
  }

  /**
   * Build a new TreeNode by shaking the given Tree(Node) to retain only the TreeNodes with the given dataType.
   * By opoosition to #getTreeNodes() this function do not flaten the result.
   * @param treeNode treeNode to shake
   */
  static filterTree<V extends TreeNode, T extends TreeDataType>(
    treeNode: V,
    
    dataType: T,
  ): FilteredTreeNode<V, T> {
    const children: TreeNode2<T>[] = [];
    for (const child of treeNode.children) {
      if (isTreeNode2(dataType, child)) {
        children.push(this.filterTree(child, dataType));
      }
    }

    return {
      ...treeNode,
      children: _.uniqBy(children, 'id'), 
    };
  }

  /**
   * Get all Organizational Units Nodes starting from the top tree nodes
   */
  static getOrganizationalUnitTreeStructure(): TreeNode<DataType.ORGUNIT>[] {
    const data = Tree.getTopTreeNodes().map(treeNode => {
      return this.filterTree(treeNode, DataType.ORGUNIT);
    });

    return data;
  }

  static getFlatAndOrderedTree(topNodes?: TreeNode[], hideWorkstations?: false): TreeNode[];
  static getFlatAndOrderedTree(
    topNodes: TreeNode[] | undefined,
    hideWorkstations: true,
  ): TreeNode<API.DataType.ORGUNIT>[];
  /**
   * Get the tree as an array of nodes IN ORDER
   * @param topNodes - (optional) TreeNode[] top nodes to start recursion from
   * @param hideWorkstations (optional) boolean - if true get OrgUnits only
   * @return TreeNode[]
   */
  static getFlatAndOrderedTree(topNodes?: TreeNode[], hideWorkstations?: boolean): TreeNode[] {
    let nodes: TreeNode[] = [];

    if (!topNodes) topNodes = this.getTopTreeNodes();

    for (const node of topNodes) {
      if (hideWorkstations && API.isTreeNode(API.DataType.WORKSTATION, node)) continue;

      nodes.push(node);

      for (const child of node.children) {
        if (API.isTreeNode(API.DataType.ORGUNIT, child) && !node.children.length) nodes.push(child);
        else if (
          !hideWorkstations &&
          (API.isTreeNode(API.DataType.WORKSTATION, child) || !node.children.length)
        )
          nodes.push(child);
        else {
          if (hideWorkstations)
            nodes = [...nodes, ...this.getFlatAndOrderedTree([child], hideWorkstations)];
          else nodes = [...nodes, ...this.getFlatAndOrderedTree([child], hideWorkstations)];
        }
      }
    }
    return nodes;
  }

  /**
   * Get all the siblings of a TreeNode
   * @param treeNode
   */
  static getSiblingsTreeNode(treeNode: TreeNode): TreeNode[] {
    const parentTreeNode = Tree.getParentTreeNode(treeNode);

    if (parentTreeNode) {
      return parentTreeNode.children.filter(_treeNode => _treeNode.id !== treeNode.id);
    } else {
      
      
      const siblings: TreeNode[] = [];
      for (const [_id, _treeNode] of Tree.masterData.data.treeMap) {
        if (_treeNode.id !== treeNode.id && _.isEqual(_treeNode.parentIds, treeNode.parentIds)) {
          siblings.push(_treeNode);
        }
      }
      return siblings;
    }
  }

  /**
   * Get all the siblings of a given OrganizationalUnit or Workstation
   * @param orgUnitOrWorkstation
   */
  static getSiblings(orgUnitOrWorkstation: API.TreeObject): API.Result<TreeObject<TreeDataType>[]> {
    const treeNode = Tree.getTreeNode(orgUnitOrWorkstation.id);
    if (API.isFailure(treeNode)) return treeNode;

    return this.getSiblingsTreeNode(treeNode).map(sibling => sibling.object);
  }

  static isRootNode(treeNode: TreeNode): boolean {
    return !treeNode.parentIds.length;
  }
  static isRootOrganizationalUnit(organizationalUnitId: string): API.Result<boolean> {
    const treeNode = Tree.getTreeNode(organizationalUnitId);
    if (API.isFailure(treeNode)) return treeNode;

    return this.isRootNode(treeNode);
  }

  static getRootNode(): TreeNode<DataType.ORGUNIT> | undefined {
    return Tree.masterData.data.rootNode;
  }
  /**
   * Get the root OrganizationalUnit.
   * Null is returned when User does not have access to the root unit
   */
  static getRootOrganizationalUnit(): API.OrganizationalUnit | null {
    return this.getRootNode()?.object ?? null;
  }

  /**
   * Get the TreeNodes accessible to the signedin User that are the closest to the rootNode
   */
  static getTopTreeNodes(): TreeNode<DataType.ORGUNIT>[] {
    return Tree.masterData.data.topTreeNodes;
  }

  /**
   * If there are multiple top tree nodes, need arrange them in order
   * Limitation: we can only order the nodes which having same parentIds. Other items will not be sorted well
   * @param topTreeNodes
   * @returns topTreeNodes
   */
  private orderRootTreeNodes(topTreeNodes: API.TreeNode<API.DataType.ORGUNIT>[]) {
    const sortedNodesWithParentId = _.sortBy(topTreeNodes, [
      node => node.parentIds.length,
      node => JSON.stringify(node.parentIds),
      node => node.object.order,
    ]);

    return sortedNodesWithParentId;
  }

  /**
   * Compute the TreeNodes accessible to the signedin User that are the closest to the rootNode
   */
  private setTopTreeNodes(): void {
    const topTreeNodes: TreeNode<DataType.ORGUNIT>[] = [];
    for (const treeNode of Tree._masterData.data.treeMap.values()) {
      if (!isTreeNode(DataType.ORGUNIT, treeNode)) continue;

      if (treeNode.object.parentId === API.rootOrganizationalUnitParentId) {
        Tree._masterData.data.topTreeNodes = [treeNode];
        return;
      }
      if (!topTreeNodes.length) {
        topTreeNodes.push(treeNode);
      } else {
        if (
          !treeNode.parentIds.some(eachParentId =>
            topTreeNodes.some(eachTreeNodes => eachTreeNodes.id === eachParentId),
          )
        ) {
          topTreeNodes.push(treeNode);
        }
        const treeNodeData: TreeNode<DataType.ORGUNIT>[] = [];
        topTreeNodes.map(eachTreeNode => {
          if (eachTreeNode.parentIds.some(eachParentId => eachParentId === treeNode.id)) {
            treeNodeData.push(eachTreeNode);
          }
        });

        treeNodeData.forEach(eachData => {
          const index = topTreeNodes.findIndex(
            eachTopTreeNode => eachTopTreeNode.id === eachData.id,
          );
          if (index > -1) {
            topTreeNodes.splice(index, 1);
          }
        });
      }
    }

    Tree._masterData.data.topTreeNodes = this.orderRootTreeNodes(topTreeNodes);
  }

  /**
   * Get all Tree Nodes in a flat flavor (order is not respected)
   * @param dataType (optional)
   * @returns
   */
  static getTreeNodes<T extends TreeDataType>(dataType?: T): TreeNode<T>[] {
    const treeNodes: TreeNode<T>[] = [];
    for (const treeNode of Tree.masterData.data.treeMap.values()) {
      if (isTreeNode(dataType, treeNode)) treeNodes.push(treeNode);
    }
    return treeNodes;
  }
  /**
   * Get all Tree Objects in a flat flavor (order is not respected)
   * @param dataType (optional)
   * @returns
   */
  static getTreeObjects<T extends TreeDataType>(dataType?: T): TreeObject<T>[] {
    const treeObjects: TreeObject<T>[] = [];
    for (const treeNode of Tree.masterData.data.treeMap.values()) {
      if (isTreeNode(dataType, treeNode)) treeObjects.push(treeNode.object);
    }
    return treeObjects;
  }

  /**
   * Search for the given name inside the Tree.
   * N.B. it shall not be used to test the existence of a TreeObject as the result depends on the User scope (what data user can see).
   * @param objectName
   * @param parentId - optional: id of parent org unit - if undefined it considers all org units
   * @param dataType - optional: filter only on the given dataType
   * @param exactMatch (optional, default = true)
   * @returns a possibly empty array containing all the Objects matching the given objectName
   */
  static getTreeObjectsByName<T extends TreeDataType>(
    objectName: string,
    parentId?: string,
    dataType?: T,
    exactMatch = true,
  ): Array<TreeObject<T>> {
    const treeObjects: TreeObject<T>[] = [];

    for (const treeNode of Tree.masterData.data.treeMap.values()) {
      if (isTreeNode(dataType, treeNode)) {
        if (!parentId || treeNode.object.parentId === parentId) {
          if (searchMatch(treeNode.object.name, objectName, exactMatch)) {
            treeObjects.push(treeNode.object);
          }
        }
      }
    }
    return treeObjects;
  }

  /**
   * Get the TreeNode of the given orgUnitOrWorkstationId.
   * Return a Failure if User doesn't have read permissions on this TreeNode or if the id doesn't exist.
   * @param orgUnitOrWorkstationId
   */
  static getTreeNode<T extends TreeDataType>(
    orgUnitOrWorkstationId: string,
  ): API.Result<TreeNode<T>, 'ObjectNotFound'> {
    const treeNode = Tree.masterData.data.treeMap.get(orgUnitOrWorkstationId); 
    if (!treeNode)
      return API.createFailure(
        'ObjectNotFound',
        'No treeNode found for treeObject id="' +
          orgUnitOrWorkstationId +
          '". Object does not exist or current User do not have read permission on this object.',
      );

    return treeNode as TreeNode<T>; 
  }
}
