import React, { useContext } from 'react';
import { Text, ActivityIndicator, View } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { TouchableOpacityPreventDoubleClick } from 'shared/ui-component/TouchableOpacityPreventDoubleClick';
import * as API from 'shared/backend-data';
import { TrainingProgress } from 'shared/layout/cards/TrainingProgress';
import { SingleActionRow } from 'shared/layout/cards/SingleActionRow';
import { Card } from 'shared/ui-component/Card';
import { WorkerRow } from 'shared/layout/cards/WorkerRow';
import { Colors } from 'shared/styles';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import Styles from './Styles.native';
import * as _ from 'lodash-es';

export interface TrainingSessionCardMobileProps {
  trainingSessionWithDetails: API.TrainingSessionWithDetail | undefined;
  loader: boolean;
  mustBePlanned: boolean;
  startTrainingSession: boolean;
  validateProofBundle: boolean;
  addProofBundle: boolean;
  isTrainingPractical: boolean;
  traineeDetails: API.WorkerWorkstationDetails[];
  disabled?: boolean;
  mentorsDetails?: API.WorkerWorkstationDetails[];
  canWorkerMangeTrainingSession?: boolean;

  onCardPress: () => void;
}

export const TrainingSessionCardMobile: React.FC<TrainingSessionCardMobileProps> = props => {
  const {
    trainingSessionWithDetails,
    loader,
    mustBePlanned,
    startTrainingSession,
    validateProofBundle,
    addProofBundle,
    isTrainingPractical,
    mentorsDetails,
    traineeDetails,
    disabled,
    canWorkerMangeTrainingSession,

    onCardPress,
  } = props;

  const { isValidPermission } = useContext(PermissionManagementContext);

  if (!trainingSessionWithDetails) return null;

  return (
    <Card style={Styles.card}>
      <TouchableOpacityPreventDoubleClick
        style={Styles.container}
        onPress={onCardPress}
        disabled={disabled}
      >
        {trainingSessionWithDetails && (
          <TrainingProgress trainingSessionWithDetails={trainingSessionWithDetails} />
        )}
        <Text style={Styles.trainingTitle}>{trainingSessionWithDetails.training.name}</Text>
        {loader && <ActivityIndicator size={50} color={Colors.Yellow} />}
        {!loader &&
          traineeDetails.map(eachTrainee => (
            <WorkerRow
              key={eachTrainee.worker.id}
              displayWorkerLevelIcon
              worker={eachTrainee.worker}
              workstationWorkerLevel={eachTrainee.workerWorkstation}
              isTrainer={false}
              isPracticalTraining={isTrainingPractical}
              isAbsent={
                isTrainingPractical ||
                !API.isTrainingSessionStartedOrStartedLateOrLateEnd(trainingSessionWithDetails)
                  ? undefined
                  : !_.find(
                      trainingSessionWithDetails.traineeIds,
                      traineeId => traineeId === eachTrainee.worker.id,
                    )
              }
            />
          ))}
        {!loader &&
          mentorsDetails &&
          mentorsDetails.map(eachMentor => (
            <WorkerRow
              displayWorkerLevelIcon
              worker={eachMentor.worker}
              workstationWorkerLevel={eachMentor.workerWorkstation}
              isTrainer
              isPracticalTraining={isTrainingPractical}
            />
          ))}
        {!(
          (!mustBePlanned || !isValidPermission(API.Permission.trainingSessions_edit)) &&
          (!startTrainingSession || !isValidPermission(API.Permission.trainingSessions_edit)) &&
          (!validateProofBundle || !isValidPermission(API.Permission.workersSkillProof_review)) &&
          (!addProofBundle || !isValidPermission(API.Permission.trainingSessions_edit))
        ) && <View style={[Styles.spacerMobile]} />}
        {!loader &&
          mustBePlanned &&
          isValidPermission(API.Permission.trainingSessions_edit) &&
          trainingSessionWithDetails && (
            <SingleActionRow
              buttonText={t('common:time.schedule')}
              onAction={onCardPress}
              disabled={!canWorkerMangeTrainingSession}
            />
          )}
        {!loader &&
          startTrainingSession &&
          !isTrainingPractical &&
          isValidPermission(API.Permission.trainingSessions_edit) &&
          trainingSessionWithDetails && (
            <SingleActionRow
              buttonText={t('common:button.start')}
              onAction={onCardPress}
              disabled={!canWorkerMangeTrainingSession}
            />
          )}
        {!loader &&
          validateProofBundle &&
          isValidPermission(API.Permission.workersSkillProof_review) &&
          trainingSessionWithDetails && (
            <SingleActionRow
              buttonText={t('common:button.validate')}
              onAction={onCardPress}
              disabled={!canWorkerMangeTrainingSession}
            />
          )}
        {!loader &&
          addProofBundle &&
          isValidPermission(API.Permission.trainingSessions_edit) &&
          trainingSessionWithDetails && (
            <SingleActionRow
              buttonText={t('alex:scheduleTrainingModal.generateProof')}
              onAction={onCardPress}
              disabled={!canWorkerMangeTrainingSession}
            />
          )}
      </TouchableOpacityPreventDoubleClick>
    </Card>
  );
};
