import { StyleSheet } from 'react-native';
import { Spacings, Colors } from 'shared/styles';

const styles = StyleSheet.create({
  rightPanelContainer: {
    backgroundColor: Colors.White,
    flex: 1,
    paddingBottom: Spacings.Standard,
    paddingLeft: Spacings.Unit * 8,
    maxWidth: '50%',
    justifyContent: 'space-between',
    borderBottomRightRadius: 8,
  },
  rightPanelInputsContainer: {
    width: 300,
  },
  skillDropDown: {
    width: '100%',
    zIndex: 4,
  },
  fullWidth: {
    width: '100%',
  },
  trainingMenuContainer: {
    top: Spacings.Large,
    width: '100%',
  },
  workerDropDown: {
    zIndex: 3,
    width: '100%',
  },
  obtentionDateAndValidityContainer: {
    width: '100%',
    height: 64,
    zIndex: 2,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  commentsContainer: {
    zIndex: -4,
  },
});

export default styles;
