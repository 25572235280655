import React, { useContext } from 'react';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { Chapter } from 'shared/layout/summaryBook/SummaryBook';
import { Immutable } from 'shared/util-ts/Functions';
import { ProofBookContainer } from 'shared/layout/proof-book/ProofBookContainer';
import { ProofBookComponentWeb } from './components';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';

interface Props {
  trainingSessionId?: string;
  proofBundleId?: string;
  disableProofAddition?: boolean;
  workerIds?: string[];
  skillIds?: string[];
  trainingVersionId?: string;
  allowPartialSubmission?: boolean;
  modalAutoClose?: boolean;
  trainingSessionsOfWorker?: API.TrainingSession[];
  trainingSessionWorkers?: string[];
  proofAlreadyValidatedWorkersAndSkills?: {
    workerIds: string[];
    skillIds: string[];
  };

  closeModalTrigger?: () => void;
  setShowAddTrainingProofModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface SummaryDetails {
  skillId: string;
  files?: File[];
  proofBundle?: API.NoMetadata<API.ProofBundle>;
  isObtained?: boolean;
  worker?: API.Worker;
}

export enum AddFileActionsSkillWeb {
  ChooseFile = 'ChooseFile',
  GenerateProof = 'GenerateProof',
  SignProof = 'SignProof',
}

export enum ProofBookType {
  SkillBook,
  TrainingBook,
}

export interface ProofRejectModalProps {
  showProofRejectModal: boolean;
  workerIdsWithNotAcquiredProofs: string[];
  skillIdsWithNotAcquiredProofs: string[];
  proofUploader?: API.Worker;

  setProofUploader: React.Dispatch<React.SetStateAction<Immutable<API.Worker> | undefined>>;
  handleRejectModalProofSubmit: (chapter?: Chapter, resubmit?: boolean) => void;
  handleProofRejectModalCloseButton: () => void;
}

export interface WorkerSkillModalProps {
  showAddWorkerSkillModal: boolean;
  skill?: API.Skill;
  worker?: API.Worker;
  showAddWorkerSkillModalRevoke: boolean;
  renewProof: boolean;
  submitProofAgain: boolean;

  handleModalClose: () => void;
  setShowAddTrainingProofModal: React.Dispatch<React.SetStateAction<boolean>>;
}


export const ProofBookContainerWeb: React.FC<Props> = props => {
  const {
    proofBundleId,
    disableProofAddition,
    workerIds,
    skillIds,
    trainingSessionsOfWorker,
    trainingVersionId,
    trainingSessionId,
    allowPartialSubmission = true,
    modalAutoClose = true,
    trainingSessionWorkers,
    proofAlreadyValidatedWorkersAndSkills,

    closeModalTrigger,
    setShowAddTrainingProofModal,
  } = props;

  const { isValidPermission } = useContext(PermissionManagementContext);

  return (
    <ProofBookContainer
      proofBookComponent={ProofBookComponentWeb}
      trainingSessionId={trainingSessionId}
      proofBundleId={proofBundleId}
      disableProofAddition={disableProofAddition}
      workerIds={workerIds}
      skillIds={skillIds}
      allowPartialSubmission={allowPartialSubmission}
      modalAutoClose={modalAutoClose}
      trainingSessionsOfWorker={trainingSessionsOfWorker}
      trainingSessionWorkers={trainingSessionWorkers}
      proofAlreadyValidatedWorkersAndSkills={proofAlreadyValidatedWorkersAndSkills}
      trainingVersionId={trainingVersionId}
      hasWorkerSkillReviewPermission={isValidPermission(API.Permission.workersSkillProof_review)}
      setShowAddTrainingProofModal={setShowAddTrainingProofModal}
      closeModalTrigger={closeModalTrigger}
    />
  );
};
