import React, { useEffect, useState } from 'react';
import { View, Text, InteractionManager } from 'react-native';
import { t } from 'shared/localisation/i18n';
import Styles from './Styles';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { TextButton } from 'shared/ui-component/Button';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../navigation/Routes';
import {
  getTrainingsWithLateTrainingSessionsCount,
  TrainingsWithLateTrainingSessionCount,
} from 'shared/util/TrainingSession';
import { ActivityIndicator } from 'shared/ui-component/Loader/ActivityIndicator';
import { KPIData } from 'sharedweb/src/context/TreeTableContext';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { wait } from 'shared/util-ts/Functions';

interface Props {
  kpiData: KPIData;
  isGraphLoaded: boolean;
}

export const LateTrainingSessions: React.FC<Props> = props => {
  const { kpiData, isGraphLoaded } = props;
  const isMounted = useIsMounted();

  const history = useHistory();

  const [topLateTrainingSessions, setTopLateTrainingSessions] = useState<
    TrainingsWithLateTrainingSessionCount[]
  >([]);
  const [loader, setLoader] = useState<boolean>(true);
  const numberOfTopTrainingsNeeded = 5;

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      await wait(1000);
      if (!isMounted.current) return;

      if (kpiData.trainingSessionsWithState && isGraphLoaded) {
        await setTrainingsWithLateTrainingSessionsCount(kpiData.trainingSessionsWithState);
      }
    });
  }, [kpiData, isGraphLoaded]);

  async function setTrainingsWithLateTrainingSessionsCount(
    trainingSessionsWithState: API.TrainingSessionWithState[],
  ) {
    const trainingsWithLateTrainingSession = await getTrainingsWithLateTrainingSessionsCount(
      trainingSessionsWithState,
    );
    if (!isMounted.current) return;
    if (API.isFailure(trainingsWithLateTrainingSession)) {
      logger.warn(trainingsWithLateTrainingSession);
      setLoader(false);
      return;
    }

    if (trainingsWithLateTrainingSession) {
      const _topLateTrainingSessions = trainingsWithLateTrainingSession
        .sort(compareBasedOnNumberOfLateTrainingSessions)
        .slice(0, numberOfTopTrainingsNeeded);

      setTopLateTrainingSessions(_topLateTrainingSessions);
      setLoader(false);
    }
  }

  function compareBasedOnNumberOfLateTrainingSessions(
    a: TrainingsWithLateTrainingSessionCount,
    b: TrainingsWithLateTrainingSessionCount,
  ) {
    if (a.numberOfLateTrainingSessions < b.numberOfLateTrainingSessions) {
      return 1;
    }
    if (a.numberOfLateTrainingSessions > b.numberOfLateTrainingSessions) {
      return -1;
    }
    return 0;
  }

  return (
    <View style={[Styles.lateTrainingWidgetContainer, Styles.widget]}>
      <Text style={Styles.widgetTitle}>
        {t('alex:KPI.KPIDashboard.TopLateTrainingSessions', { count: numberOfTopTrainingsNeeded })}
      </Text>
      {loader ? (
        <ActivityIndicator />
      ) : topLateTrainingSessions.length ? (
        topLateTrainingSessions.map((overStaffedWorkstation, index) => (
          <View
            key={overStaffedWorkstation.training.id}
            style={[Styles.widgetContentRow, { zIndex: -index }]}
          >
            <EllipsisWithTooltip
              text={index + 1 + '.  ' + overStaffedWorkstation.training.name}
              textStyle={Styles.widgetContentText}
            />

            <Text style={[Styles.widgetContentTextCount, Styles.topLateTrainingSessionCount]}>
              {overStaffedWorkstation.numberOfLateTrainingSessions}
            </Text>
          </View>
        ))
      ) : (
        <Text style={Styles.noText}>{t('alex:KPI.KPIDashboard.noTopLateTrainingSessions')}</Text>
      )}

      {topLateTrainingSessions.length ? (
        <View style={Styles.buttonContainer}>
          <TextButton
            text={t('common:button.seeMore') + '...'}
            onPress={() => {
              history.push(RouteLocations.TrainingSessions());
            }}
            textStyle={Styles.buttonTextStyle}
          />
        </View>
      ) : null}
    </View>
  );
};
