import { StyleSheet } from 'react-native';
import { Spacings, TextFontStyles, Colors, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  rootContainer: {
    alignItems: 'flex-start',
    paddingHorizontal: 0,
    paddingTop: 0,
    minWidth: 224,
    maxWidth: 400,
    height: '100%',
  },
  routeContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '20%',
  },
  eachMenu: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.Background,
    width: '100%',
  },
  label: {
    ...TextFontStyles.LatoBlack,
    color: Colors.Grey,
    fontSize: fontSize.Regular,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: Spacings.xMedium,
    flex: 1,
  },
  subMenuContainer: {
    borderBottomWidth: 0,
    paddingLeft: 24 + Spacings.xMedium,
    paddingTop: Spacings.xMedium,
    paddingBottom: Spacings.xMedium,
    paddingRight: 0,
  },
  subMenuLabel: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
    paddingTop: 0,
    paddingBottom: 0,
  },
  clickableMenu: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    paddingLeft: Spacings.xMedium,
  },
  addIconContainer: {
    paddingRight: Spacings.xMedium,
  },
  workstationTreeContainer: {
    maxHeight: '80%',
    width: '100%',
  },
  dragableSideStyle: {
    width: 10,
    cursor: 'col-resize',
    position: 'absolute',
    right: -10,
    top: '8.5%',
    bottom: 0,
  },
});

export default styles;
