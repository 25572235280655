import React, { useState, useEffect, useRef, useContext } from 'react';
import { View, TouchableOpacity, Text, Animated, Easing, InteractionManager } from 'react-native';
import * as API from 'shared/backend-data/index';
import { Route, RouteKey } from './RouteKey';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import styles from './Style';
import { Colors } from 'shared/styles';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { wait } from 'shared/util-ts/Functions';
import { RouteLocations } from './Routes';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { InvitedUsersDisplayContainer } from '../users-profiles-displayer/container';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { containsNumber } from 'shared/util/const';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { AppFeature } from 'backend/src/api/api-auto';
import { MyHub } from 'shared/util/MyHub';

const BurgerSVG = require('shared/assets/svg/icon.navigation.mobile.svg').default;
const addUserIcon = require('shared/assets/svg/icon.user+.svg').default;
const AlexSVG = require('shared/assets/svg/icon.dashboard.svg').default;
const TrainingSessionSVG = require('shared/assets/svg/icon.lecture.svg').default;
const KPISVG = require('shared/assets/svg/icon.KPI.svg').default;
const WorkstationSVG = require('shared/assets/svg/icon.workstation2.svg').default;

interface SideBarProps extends RouteComponentProps {
  tenantApp?: API.TenantApp;
  innerHeight: number;
  headerSideBarFullScreen: boolean;
  setOpenUsersModal: (open: boolean) => void;
}

export const SideBar: React.FC<SideBarProps> = props => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const [selectedRoutePath, setSelectedRoutePath] = useState<RoutePaths>(RoutePaths.Home);
  const [drawerZIndex, setDrawerZIndex] = useState(10);
  const { isValidPermission } = useContext(PermissionManagementContext);
  const [skills, setSkills] = useState<API.Skill[]>([]);
  const [orgUnits, setOrgUnits] = useState<API.OrganizationalUnit[]>([]);
  const [trainings, setTrainings] = useState<API.Training[]>([]);
  const [workers, setWorkers] = useState<API.Worker[]>([]);
  const [workstations, setWorkstations] = useState<API.Workstation[]>([]);
  const drawerWidth = useRef(new Animated.Value(56)).current;
  const ref = useDetectOutsideClick<View>(() => setDrawerOpened(false));

  useEffect(() => {
    let pathName = history.location.pathname;

    if (containsNumber.test(pathName)) {
      const splittedArray = pathName.split('/');
      splittedArray.splice(-1);
      pathName = splittedArray.join('/');
    }
    switch (pathName) {
      case RoutePaths.Home:
        if (selectedRoutePath !== RoutePaths.Home) setSelectedRoutePath(RoutePaths.Home);
        break;
      case RoutePaths.Workstations:
      case RoutePaths.WorkstationProfile:
        if (selectedRoutePath !== RoutePaths.Workstations) {
          setSelectedRoutePath(RoutePaths.Workstations);
        }
        break;
      case RoutePaths.Workers:
      case RoutePaths.WorkerAdd:
      case RoutePaths.WorkerProfile:
        if (selectedRoutePath !== RoutePaths.Workers) {
          setSelectedRoutePath(RoutePaths.Workers);
        }
        break;
      case RoutePaths.TrainingSessions:
        setSelectedRoutePath(RoutePaths.TrainingSessions);
        break;
      case RoutePaths.Skills:
      case RoutePaths.SkillProfile:
        if (selectedRoutePath !== RoutePaths.Skills) setSelectedRoutePath(RoutePaths.Skills);
        break;
      case RoutePaths.Trainings:
      case RoutePaths.TrainingProfile:
        if (selectedRoutePath !== RoutePaths.Trainings) setSelectedRoutePath(RoutePaths.Trainings);
        break;
      case RoutePaths.MyFactory:
        setSelectedRoutePath(RoutePaths.Workstations);
        break;
      case RoutePaths.CompanySettings:
        setSelectedRoutePath(RoutePaths.CompanySettings);
        break;
      case RoutePaths.KPIOrgUnits:
        if (selectedRoutePath !== RoutePaths.KPI) setSelectedRoutePath(RoutePaths.KPI);
        break;
    }
  }, [history.location.pathname]);

  useEffect(() => {
    handleDrawer(drawerOpened);
  }, [drawerOpened]);

  useEffect(() => {
    handleFullScreenDrawer(props.headerSideBarFullScreen);
    if (props.headerSideBarFullScreen) {
      setDrawerZIndex(0);
    } else {
      wait(1300).then(() => {
        if (isMounted.current) setDrawerZIndex(10);
      });
    }
  }, [props.headerSideBarFullScreen]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async payload => {
      switch (true) {
        case payload.data.factory.dataType === API.DataType.WORKER:
          await fetchWorkers();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.TRAINING:
          await fetchTrainings();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.ORGUNIT:
          fetchOrgUnits();
          break;

        case payload.data.factory.dataType === API.DataType.SKILL:
          await fetchSkills();
          if (!isMounted.current) return;
          break;

        case payload.data.factory.dataType === API.DataType.WORKSTATION:
          fetchWorkstations();
          break;
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      fetchWorkstations();

      fetchOrgUnits();

      await fetchWorkers();
      if (!isMounted.current) return;

      await fetchTrainings();
      if (!isMounted.current) return;

      await fetchSkills();
      if (!isMounted.current) return;
    });

    return () => {
      removeListener();
    };
  }, []);

  function fetchWorkstations() {
    const __workstations = API.getWorkstations();
    if (API.isFailure(__workstations)) return __workstations;
    setWorkstations(__workstations);
  }
  async function fetchWorkers() {
    const __workers = await API.getWorkers();
    if (API.isFailure(__workers)) return __workers;
    setWorkers(__workers.result);
  }
  async function fetchTrainings() {
    const __trainings = await API.getTrainings();
    if (API.isFailure(__trainings)) return __trainings;
    setTrainings(__trainings.result);
  }
  function fetchOrgUnits() {
    const __orgUnits = API.getOrganizationalUnits();
    if (API.isFailure(__orgUnits)) return __orgUnits;
    setOrgUnits(__orgUnits);
  }
  async function fetchSkills() {
    const __skills = await API.getSkills();
    if (API.isFailure(__skills)) return __skills;
    setSkills(__skills.result);
  }

  const handleDrawer = (bool: boolean) => {
    Animated.timing(drawerWidth, {
      useNativeDriver: false,
      toValue: bool ? 300 : 56,
      duration: 150,
      easing: bool ? Easing.in(Easing.linear) : Easing.out(Easing.linear),
    }).start();
  };

  const handleFooterClick = () => {
    if (drawerOpened) {
      setDrawerOpened(false);
      props.setOpenUsersModal(true);
    } else setDrawerOpened(true);
  };

  const handleFullScreenDrawer = async (bool: boolean) => {
    Animated.timing(drawerWidth, {
      useNativeDriver: false,
      toValue: bool ? 0 : 56,
      duration: bool ? 1200 : 800,
      easing: Easing.elastic(1),
    }).start();
  };

  const handleRouteChange = (routePath: RoutePaths) => {
    switch (routePath) {
      case RoutePaths.Home:
        history.push(RouteLocations.Home());
        break;
      case RoutePaths.TrainingSessions:
        history.push(RouteLocations.TrainingSessions());
        break;
      case RoutePaths.MyFactory:
        
        
        history.push(RouteLocations.Workstations());
        break;
      case RoutePaths.Workstations:
        history.push(RouteLocations.Workstations());
        break;
      case RoutePaths.Skills:
        history.push(RouteLocations.Skills());
        break;
      case RoutePaths.TrainingSessions:
        history.push(RouteLocations.TrainingSessions());
        break;
      case RoutePaths.Trainings:
        history.push(RouteLocations.Trainings());
        break;
      case RoutePaths.Workers:
        history.push(RouteLocations.Workers());
        break;
      case RoutePaths.KPI:
        history.push(RouteLocations.KPI());
        break;

      default:
        break;
    }
    setSelectedRoutePath(routePath);
  };

  return (
    <Animated.View
      ref={ref}
      style={[
        styles.sideBarContainer,
        {
          height: props.innerHeight,
          width: drawerWidth,
          zIndex: drawerZIndex,
        },
      ]}
    >
      <TouchableOpacity
        style={[styles.touchableSideBarContainer, { paddingRight: drawerOpened ? 36 : 0 }]}
        onPress={() => {
          setDrawerOpened(!drawerOpened);
        }}
      >
        <Animated.View style={styles.navBurgerContainer}>
          <View style={styles.navBurgerTouchable}>
            <IconSVG
              svgComponent={BurgerSVG}
              color={Colors.Grey}
              containerStyle={styles.navBurgerSVG}
            />
          </View>
          {drawerOpened ? (
            <View style={styles.tenantAppTextContainer}>
              <EllipsisWithTooltip
                text={props.tenantApp ? props.tenantApp.tenant.name : ''}
                textStyle={styles.tenantAppTextStyle}
                toolTipTextStyle={[styles.tenantAppTextStyle, styles.toolTiptenantAppTextStyle]}
              />
            </View>
          ) : (
            <></>
          )}
        </Animated.View>
        <Animated.View
          style={[
            styles.routeKeysContainer,
            {
              width: Number(drawerWidth),
            },
          ]}
        >
          <RouteKey
            routeKeyPath={RoutePaths.Home}
            drawerOpened={drawerOpened}
            selectedRouteKeyPath={selectedRoutePath}
            routeKeyTitle={t('alex:header.navigation.0')}
            routeKeyIcon={AlexSVG}
            handleDrawer={setDrawerOpened}
            handleSelect={handleRouteChange}
          />
          <RouteKey
            routeKeyPath={RoutePaths.TrainingSessions}
            drawerOpened={drawerOpened}
            routeKeyIconSVGSize={{ width: 22, height: 22 }} 
            selectedRouteKeyPath={selectedRoutePath}
            routeKeyTitle={t('alex:header.navigation.1')}
            routeKeyIcon={TrainingSessionSVG}
            handleDrawer={setDrawerOpened}
            handleSelect={handleRouteChange}
            requireFeature={AppFeature.SKILLOP_TRAINING}
          />
          <RouteKey
            routeKeyPath={RoutePaths.KPI}
            drawerOpened={drawerOpened}
            routeKeyIconSVGSize={{ width: 22, height: 22 }} 
            selectedRouteKeyPath={selectedRoutePath}
            routeKeyTitle={t('alex:header.navigation.8')}
            routeKeyIcon={KPISVG}
            handleDrawer={setDrawerOpened}
            handleSelect={handleRouteChange}
            requireFeature={AppFeature.SKILLOP_TRAINING}
          />
          <RouteKey
            routeKeyPath={RoutePaths.MyFactory}
            subRouteKeypath={[
              RoutePaths.Workstations,
              RoutePaths.Skills,
              RoutePaths.Trainings,
              RoutePaths.Workers,
            ]}
            drawerOpened={drawerOpened}
            selectedRouteKeyPath={selectedRoutePath}
            routeKeyTitle={t('alex:header.navigation.2')}
            routeKeyIcon={WorkstationSVG}
            handleDrawer={setDrawerOpened}
            handleSelect={handleRouteChange}
          />
          <RouteKey
            routeKeyPath={RoutePaths.Workstations}
            drawerOpened={drawerOpened}
            selectedRouteKeyPath={selectedRoutePath}
            routeKeyTitle={t('alex:header.navigation.3')}
            routeKeyNumber={workstations.length + orgUnits.length}
            handleDrawer={setDrawerOpened}
            handleSelect={handleRouteChange}
          />
          <RouteKey
            routeKeyPath={RoutePaths.Skills}
            drawerOpened={drawerOpened}
            selectedRouteKeyPath={selectedRoutePath}
            routeKeyTitle={t('alex:header.navigation.4')}
            routeKeyNumber={skills.length}
            handleDrawer={setDrawerOpened}
            handleSelect={handleRouteChange}
          />
          <RouteKey
            routeKeyPath={RoutePaths.Trainings}
            drawerOpened={drawerOpened}
            selectedRouteKeyPath={selectedRoutePath}
            routeKeyTitle={t('alex:header.navigation.5')}
            routeKeyNumber={trainings.length}
            handleDrawer={setDrawerOpened}
            handleSelect={handleRouteChange}
          />
          <RouteKey
            routeKeyPath={RoutePaths.Workers}
            drawerOpened={drawerOpened}
            selectedRouteKeyPath={selectedRoutePath}
            routeKeyTitle={t('alex:header.navigation.6')}
            routeKeyNumber={workers.length}
            handleDrawer={setDrawerOpened}
            handleSelect={handleRouteChange}
          />
        </Animated.View>
        {isValidPermission(API.Permission.workers_invite) && (
          <View style={styles.footerIconContainer}>
            <TouchableOpacity
              style={[
                styles.footerIconInnerContainer,
                {
                  flexDirection: drawerOpened ? 'row' : 'column',
                  backgroundColor: drawerOpened ? Colors.Yellow : Colors.Transparent,
                  width: drawerOpened ? 104 : 40,
                  borderRadius: drawerOpened ? 15 : 0,
                  paddingLeft: drawerOpened ? 14 : 0,
                  paddingRight: drawerOpened ? 23 : 0,
                  justifyContent: drawerOpened ? 'space-between' : 'center',
                },
              ]}
              onPress={() => handleFooterClick()}
            >
              <View>
                <IconSVG
                  svgComponent={addUserIcon}
                  containerStyle={styles.footerIconSVG}
                  color={drawerOpened ? Colors.White : Colors.Grey}
                  size={{ width: 24, height: 24 }}
                />
              </View>
              {drawerOpened && (
                <Text
                  style={styles.footerIconText}
                  onPress={() => {
                    setDrawerOpened(false);
                    props.setOpenUsersModal(true);
                  }}
                >
                  {t('alex:header.navigation.7')}
                </Text>
              )}
            </TouchableOpacity>
            {drawerOpened && (
              <InvitedUsersDisplayContainer
                onPress={() => {
                  setDrawerOpened(false);
                  props.setOpenUsersModal(true);
                }}
              />
            )}
          </View>
        )}
      </TouchableOpacity>
    </Animated.View>
  );
};
