import { StyleSheet } from 'react-native';
import { Spacings, TextFontStyles, fontSize, Colors } from 'shared/styles';

const styles = StyleSheet.create({
  bottomPanel: {
    zIndex: -1,
  },
  bottomPanelContainer: {
    zIndex: -1,
    height: 64,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  summaryIconContainer: {
    paddingLeft: Spacings.Large + Spacings.Unit,
    paddingRight: Spacings.Large,
  },
  patchSwitchContainer: {
    width: 222,
  },
  buttonContainer: {
    paddingLeft: 120,
    flex: 1,
  },
  submitButtonStyle: {
    height: 32,
    flex: 0.9,
  },
  submitButtonTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.White,
  },
  skillButtonContainer: {
    flexDirection: 'row',
    flex: 0.9,
    justifyContent: 'space-evenly',
  },
  proofTextState: {
    ...TextFontStyles.LatoNormal,
    fontSize: fontSize.Medium,
    textAlign: 'center',
  },
  rejectButtonStyle: {
    flex: 0.4,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: Colors.Black,
  },
  validityContainer: {},
});

export default styles;
