import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View, FlatList } from 'react-native';
import styles from './styles';
import * as API from 'shared/backend-data';
import { ActivityIndicator } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { HEADER_HEIGHT } from '../../versatility-panel/styles';
import { Colors, Spacings } from 'shared/styles';
import {
  DashboardFilterContext,
  ParentWithWorkers,
  WorkersByParentWithDetails,
} from 'shared/context/dashboard-context/DashboardFilterContext';
import { ROW_HEIGHT } from '../../workstation-worker-level-table/styles';
import * as _ from 'lodash-es';
import { TouchableSortComponent } from '../../../sort/TouchableSortComponent';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { WorkersList } from './WorkersList';
import { WorkerWithParentNode } from '../../workstation-table/component/WorkstationTableComponent';

interface Props {
  adaptableHeight: number;
  workersScrollViewRef: React.MutableRefObject<FlatList | undefined>;
  displayedLeftPanelFilteredWorkersAndParents?: Array<WorkerWithParentNode | ParentWithWorkers>;

  setComputeToDoList: (bool: boolean) => void;
  onWorkerTableScroll: (offset: number) => void;
  setComputeMatrixLevelIcons: (bool: boolean) => void;
  setWorkersScrollViewFullHeight: (height: number) => void;
  setWorkersScrollViewDisplayedHeight: (height: number) => void;
  setWorkersScrollViewOffset: (offset: number) => void;
}

export interface Versatility {
  count: number | undefined;
  color: string;
}

export type NodeAndWorkers = Array<undefined | API.Shift | WorkerDetail>;
export interface WorkerDetail extends API.Worker {
  isRenderDetails?: boolean;
}

const sortings: API.SortDirection[] = [API.SortDirection.asc, API.SortDirection.desc];

const _WorkerTableComponent: React.FC<Props> = props => {
  const {
    adaptableHeight,
    workersScrollViewRef,
    displayedLeftPanelFilteredWorkersAndParents,

    setComputeToDoList,
    setWorkersScrollViewOffset,
    onWorkerTableScroll,
    setComputeMatrixLevelIcons,
    setWorkersScrollViewFullHeight,
    setWorkersScrollViewDisplayedHeight,
  } = props;

  const [_workersByShiftsOrOrgUnit, _setWorkersByShiftsOrOrgUnit] = useState<NodeAndWorkers>();

  const {
    workersByParentWithDetails: [workersByShiftsOrOrgUnitWithDetails, setWorkersByShiftsOrOrgUnit],
  } = useContext(DashboardFilterContext);
  const { isWorkerLastNameFirst, setDashboardWorkersSortDirection } = useContext(GlobalDataContext);

  const arrangeNodeAndWorkersDebounce = useCallback(
    _.debounce(arrangeNodeAndWorkers, API.PERF_workersWorkstationsUpdateDebounceInMs / 2, {
      leading: false,
      trailing: true,
    }),
    [workersByShiftsOrOrgUnitWithDetails, displayedLeftPanelFilteredWorkersAndParents],
  );

  useEffect(() => {
    if (!workersByShiftsOrOrgUnitWithDetails?.workersByParents) return;

    {
      setWorkersScrollViewFullHeight(
        (workersByShiftsOrOrgUnitWithDetails.workersCount +
          workersByShiftsOrOrgUnitWithDetails.shiftsAndOrgUnitCount) *
          ROW_HEIGHT +
          Spacings.xMedium +
          Spacings.Small,
      );
      setComputeMatrixLevelIcons(true);
    }

    {
      arrangeNodeAndWorkersDebounce(
        workersByShiftsOrOrgUnitWithDetails,
        displayedLeftPanelFilteredWorkersAndParents,
      );
    }
  }, [workersByShiftsOrOrgUnitWithDetails, displayedLeftPanelFilteredWorkersAndParents]);

  function arrangeNodeAndWorkers(
    workersByShiftsOrOrgUnitWithDetails: WorkersByParentWithDetails | undefined,
    displayedFilteredWorkers: (WorkerWithParentNode | ParentWithWorkers)[] | undefined,
  ) {
    if (!workersByShiftsOrOrgUnitWithDetails) return;

    const workersDetailsToBeRendered: Map<string, true> = new Map();
    displayedFilteredWorkers?.forEach(workerWithNodeOrShiftWorkers => {
      if (!('workers' in workerWithNodeOrShiftWorkers))
        workersDetailsToBeRendered.set(workerWithNodeOrShiftWorkers.id, true);
    });

    const allNodesAndAllWorkers: NodeAndWorkers = [];
    Array.from(workersByShiftsOrOrgUnitWithDetails.workersByParents.values()).forEach(
      workersByShiftsOrOrgUnit => {
        allNodesAndAllWorkers.push(workersByShiftsOrOrgUnit.shift);
        workersByShiftsOrOrgUnit.workers.forEach(_worker => {
          allNodesAndAllWorkers.push({
            ..._worker,
            isRenderDetails: workersDetailsToBeRendered.get(_worker.id),
          });
        });
      },
    );

    
    
    if (allNodesAndAllWorkers.length === 0) {
      setComputeToDoList(true);
    }
    _setWorkersByShiftsOrOrgUnit(allNodesAndAllWorkers);
  }

  function onSortClick(direction: API.SortDirection) {
    if (workersByShiftsOrOrgUnitWithDetails?.workersByParents) {
      Array.from(workersByShiftsOrOrgUnitWithDetails.workersByParents.entries()).forEach(
        ([id, value]) => {
          const _workers = API.sortWorkers(value.workers, direction, isWorkerLastNameFirst);

          workersByShiftsOrOrgUnitWithDetails.workersByParents.set(id, {
            shift: value.shift,
            workers: _workers,
          });
        },
      );

      setDashboardWorkersSortDirection(direction);
      setWorkersByShiftsOrOrgUnit({ ...workersByShiftsOrOrgUnitWithDetails });
    }
  }

  function isDisplayWorkersList() {
    return !!_workersByShiftsOrOrgUnit;
  }

  return (
    <View style={[styles.workerNameContainer]}>
      <View
        style={[
          styles.InnerTableContainer,
          {
            height: adaptableHeight,
          },
        ]}
      >
        <View style={styles.polyvalenceContainer}>
          <TouchableSortComponent
            sortDirection={API.SortDirection.asc}
            label={t('common:account.name')}
            needSort
            activeSort
            handleOnPress={onSortClick}
            sortings={sortings}
            needHover={false}
            style={styles.sortComponent}
          />
        </View>

        {isDisplayWorkersList() ? (
          <WorkersList
            onWorkerTableScroll={onWorkerTableScroll}
            workersScrollViewRef={workersScrollViewRef}
            setWorkersScrollViewDisplayedHeight={setWorkersScrollViewDisplayedHeight}
            adaptableHeight={adaptableHeight}
            setWorkersScrollViewOffset={setWorkersScrollViewOffset}
            nodeAndWorkers={_workersByShiftsOrOrgUnit!}
          />
        ) : (
          <View
            style={{
              width: 238,
              height: adaptableHeight - HEADER_HEIGHT,
              paddingTop: '50%',
            }}
          >
            <ActivityIndicator color={Colors.Yellow} size="large" />
          </View>
        )}
      </View>
    </View>
  );
};

export const WorkerTableComponent = React.memo(_WorkerTableComponent, (prevProps, nextProps) => {
  const _prevDisplayedFilteredWorkersSet = new Set<string>();
  const _nextDisplayedFilteredWorkersSet = new Set<string>();

  prevProps.displayedLeftPanelFilteredWorkersAndParents?.map(_worker => {
    if (!('workers' in _worker)) {
      _prevDisplayedFilteredWorkersSet.add(_worker.id + _worker.nodeId);
    }
  });
  nextProps.displayedLeftPanelFilteredWorkersAndParents?.map(_worker => {
    if (!('workers' in _worker)) {
      _nextDisplayedFilteredWorkersSet.add(_worker.id + _worker.nodeId);
    }
  });
  const areSetsEqual =
    _prevDisplayedFilteredWorkersSet.size === _nextDisplayedFilteredWorkersSet.size &&
    [..._prevDisplayedFilteredWorkersSet].every(value =>
      _nextDisplayedFilteredWorkersSet.has(value),
    );
  const isEqual =
    areSetsEqual &&
    _.isEqual(prevProps.workersScrollViewRef, nextProps.workersScrollViewRef) &&
    _.isEqual(prevProps.adaptableHeight, nextProps.adaptableHeight);

  return isEqual;
});
