import React, { useRef, useState, useEffect } from 'react';
import { View, Text, ScrollView, TouchableOpacity, FlatList } from 'react-native';
import Styles from './Style';
import { t } from 'shared/localisation/i18n';
import Dropzone from 'react-dropzone';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { IconSVG } from 'shared/ui-component/Icon';
import { Checkbox } from 'shared/ui-component/Checkbox';
import { PageSwitch } from 'shared/layout/page-switch/container';
import { Colors } from 'shared/styles/Colors';
import { Spacings } from 'shared/styles';
import moment from 'moment';
import { FullScreenProof } from 'sharedweb/src/FileViewer/FullScreenProof';
import * as _ from 'lodash-es';
import * as API from 'shared/backend-data';
import { FileViewerWeb, getFileTypeFromDataURL } from 'sharedweb/src/FileViewer';

const featherFileIcon = require('shared/assets/svg/Icon feather-file.svg').default;
const addIcon = require('shared/assets/svg/icon.plus.web.svg').default;
const trashIcon = require('shared/assets/svg/icon.trash.svg').default;
const certificateIcon = require('shared/assets/svg/icon.certificate.svg').default;
const arrowBackIcon = require('shared/assets/svg/icon.arrowLeft.mobile-2.svg').default;

interface LeftPanelProps {
  selectedDocs: File[];
  scannedDocs: (string | ArrayBuffer | null | undefined)[];
  attestAcquiredSkill: boolean;
  validData: boolean;
  reviewer?: API.Worker | undefined;
  showFullScreenProof: boolean;
  arrowBack?: boolean | undefined;
  revokeProof?: boolean | undefined;
  isSkillsOrWorkersCountGreaterThanOne: boolean;

  handleDiscardCheck?: () => void;
  setSelectedDocs: React.Dispatch<React.SetStateAction<File[]>>;
  setScannedDocs: React.Dispatch<React.SetStateAction<(string | ArrayBuffer | null | undefined)[]>>;
  setAttestAcquiredSkill: React.Dispatch<React.SetStateAction<boolean>>;
  handleDisplayPopUpToast: (message: string) => void;
  setShowFullScreenProof: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LeftPanel: React.FC<LeftPanelProps> = props => {
  const {
    selectedDocs,
    scannedDocs,
    attestAcquiredSkill,
    validData,
    reviewer,
    showFullScreenProof,
    arrowBack,
    revokeProof,

    isSkillsOrWorkersCountGreaterThanOne,
    handleDiscardCheck,
    setShowFullScreenProof,
    setSelectedDocs,
    setScannedDocs,
    setAttestAcquiredSkill,
    handleDisplayPopUpToast,
  } = props;

  const isMounted = useIsMounted();

  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<ScrollView>(null);
  const innerScrollRef = useRef<FlatList>(null);

  const [scrollViewWidth, setScrollViewWidth] = useState(0);
  const [innerScrollViewWidth, setInnerScrollViewWidth] = useState(0);
  const [selectedProofIndex, setSelectedProofIndex] = useState<number>(0);

  useEffect(() => {
    if (!selectedDocs.length) {
      handlePageSwitch(0);
    } else {
      handlePreviewPagesSwitch(selectedDocs.length - 1);
    }
    handleDocumentsReader();
  }, [selectedDocs]);

  const handleDocumentsReader = () => {
    if (!selectedDocs || !selectedDocs.length) return;

    scannedDocs.splice(0, scannedDocs.length);
    _.map(selectedDocs, (doc, docIndex) => {
      const reader = new FileReader();
      reader.readAsDataURL(doc);
      reader.onload = e => {
        if (!isMounted.current) return;
        scannedDocs[docIndex] = e.target?.result;
        if (scannedDocs.length === selectedDocs.length) {
          setScannedDocs([...scannedDocs]);
        }
      };
    });
  };

  const handleDocumentDeletion = (index: number) => {
    selectedDocs.splice(index, 1);
    scannedDocs.splice(index, 1);
    if (isMounted.current) {
      setScannedDocs([...scannedDocs]);
      setSelectedDocs([...selectedDocs]);
    }
    handleDisplayPopUpToast(t('alex:addWorkerSkillModal.proofsDeleted'));
  };

  function renderScannedDocs({ item }: { item: string | ArrayBuffer | null | undefined }) {
    if (!scannedDocs.length || !selectedDocs.length) return <View />;

    return typeof item === 'string' ? (
      <TouchableOpacity
        key={new Date().getTime()}
        style={[Styles.proofsPreviewScrollContentContainer]}
        onPress={() => setShowFullScreenProof(true)}
      >
        <FileViewerWeb
          filePath={item}
          fileType={getFileTypeFromDataURL(item)}
          onClick={() => setShowFullScreenProof(true)}
        />
      </TouchableOpacity>
    ) : null;
  }

  function handlePreviewPagesSwitch(index: number) {
    setTimeout(function () {
      setSelectedProofIndex(index);
      innerScrollRef.current?.scrollToOffset({ offset: innerScrollViewWidth * index });
    }, 6);
  }

  function keyExtractor(item: string | ArrayBuffer | null | undefined, index: number) {
    if (item) return `${item}${index}`;

    return index.toString();
  }

  const handleDocumentAddition = () => {
    inputRef.current?.click();
  };
  const handlePageSwitch = (index: number) => {
    scrollRef.current?.scrollTo({ x: scrollViewWidth * index });
  };
  const handleAddOrDropFiles = (files: File[]) => {
    handlePageSwitch(1);
    if (isMounted.current) setSelectedDocs([...selectedDocs, ...files]);
  };

  const renderLeftPanel = () => {
    return (
      <ScrollView
        ref={scrollRef}
        style={[Styles.leftPanel]}
        contentContainerStyle={Styles.leftPanelContentContainer}
        horizontal
        scrollEnabled={false}
        showsHorizontalScrollIndicator={false}
        onLayout={event => {
          setScrollViewWidth(event.nativeEvent.layout.width);
        }}
      >
        {isSkillsOrWorkersCountGreaterThanOne ? (
          <View />
        ) : (
          <>
            {arrowBack && handleDiscardCheck && (
              <TouchableOpacity style={Styles.arrowBackContainer} onPress={handleDiscardCheck}>
                <IconSVG svgComponent={arrowBackIcon} size={{ width: 24, height: 24 }} />
              </TouchableOpacity>
            )}
            <View style={Styles.leftPanelFirstPage}>
              {attestAcquiredSkill ? (
                <IconSVG svgComponent={certificateIcon} size={{ width: 70, height: 88 }} />
              ) : (
                <Dropzone
                  onDrop={drop => {
                    handleAddOrDropFiles(drop);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <TouchableOpacity style={[Styles.featherContainer]}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} ref={inputRef} />
                        {!attestAcquiredSkill && (
                          <IconSVG
                            svgComponent={featherFileIcon}
                            size={{ width: 70, height: 88 }}
                          />
                        )}
                      </div>
                    </TouchableOpacity>
                  )}
                </Dropzone>
              )}
              <View
                style={[
                  Styles.addProofOrAutomaticAttestContainer,
                  { paddingTop: attestAcquiredSkill && validData ? 20 : 36 },
                ]}
              >
                <View style={[Styles.addProofOrAutomaticAttestInnerContainer]}>
                  <View
                    style={[
                      Styles.addProofRowContainer,
                      {
                        height: attestAcquiredSkill && validData ? 131 : 115,
                      },
                    ]}
                  >
                    <View
                      style={[
                        Styles.addProofOrAutomaticAttestVerticalContainer,
                        { justifyContent: attestAcquiredSkill ? 'flex-end' : 'space-between' },
                      ]}
                    >
                      {!attestAcquiredSkill && (
                        <Dropzone
                          onDrop={drop => {
                            handleAddOrDropFiles(drop);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <TouchableOpacity>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <View style={Styles.circleView}>
                                  <IconSVG
                                    svgComponent={addIcon}
                                    color={Colors.White}
                                    size={{ width: 24, height: 24 }}
                                  />
                                </View>
                              </div>
                            </TouchableOpacity>
                          )}
                        </Dropzone>
                      )}
                      <View style={Styles.checkBoxContainer}>
                        <Checkbox
                          initialState={attestAcquiredSkill}
                          onChange={() => setAttestAcquiredSkill(prev => !prev)}
                        />
                      </View>
                    </View>
                    <View
                      style={[
                        Styles.addProofContainer,
                        {
                          justifyContent:
                            attestAcquiredSkill && !validData ? 'flex-end' : 'space-between',
                        },
                      ]}
                    >
                      {attestAcquiredSkill && validData && (
                        <Text style={Styles.autoCertificateText}>
                          <Text style={Styles.autoCertificateTextBold}>
                            {t('alex:mobile.addProofPage.certificateOfProof.0') + ' '}
                          </Text>
                          {t(
                            'alex:mobile.addProofPage.certificateOfProof.1',
                            {
                              date: moment(new Date()).format('DD.MM.YY'),
                              validatorName: reviewer?.name,
                            },
                            false,
                          )}
                        </Text>
                      )}
                      {!attestAcquiredSkill && (
                        <TouchableOpacity>
                          <Dropzone
                            onDrop={drop => {
                              handleAddOrDropFiles(drop);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <View>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <Text style={Styles.addProofTextStyle}>
                                    {revokeProof
                                      ? t('alex:addWorkerSkillModal.addAProofRevok')
                                      : t('alex:addWorkerSkillModal.addAProof')}
                                  </Text>
                                </div>
                              </View>
                            )}
                          </Dropzone>
                        </TouchableOpacity>
                      )}
                      {!attestAcquiredSkill && (
                        <Text style={Styles.addProofTextStyle}>
                          {t('alex:mobile.addProofPage.or', undefined, false)}
                        </Text>
                      )}
                      <TouchableOpacity onPress={() => setAttestAcquiredSkill(prev => !prev)}>
                        <Text style={Styles.addProofTextStyle}>
                          {revokeProof
                            ? t('alex:mobile.addProofPage.trainingCompleted.3')
                            : t('alex:mobile.addProofPage.trainingCompleted.0')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View
                    style={[
                      Styles.automaticAttestWarningContainer,
                      {
                        paddingLeft: !attestAcquiredSkill ? Spacings.Large : 0,
                      },
                    ]}
                  >
                    <Text style={Styles.automaticAttestTextStyle}>
                      {!attestAcquiredSkill ? (
                        t('alex:mobile.addProofPage.trainingCompleted.1')
                      ) : validData ? (
                        ''
                      ) : (
                        <Text style={{ color: Colors.Red }}>
                          {t('alex:addWorkerSkillModal.missingFields')}
                        </Text>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={Styles.leftPanelSecondPage}>
              <View style={[Styles.addRemoveProofHeader]}>
                <View style={[Styles.addRemoveProofContainer]}>
                  <TouchableOpacity
                    style={Styles.circleView}
                    onPress={() => {
                      handleDocumentAddition();
                    }}
                  >
                    <IconSVG
                      svgComponent={addIcon}
                      size={{ width: 24, height: 24 }}
                      color={Colors.White}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={Styles.roundTouchable}
                    onPress={() => {
                      handleDocumentDeletion(selectedProofIndex);
                    }}
                  >
                    <IconSVG
                      svgComponent={trashIcon}
                      size={{ width: 24, height: 24 }}
                      color={Colors.Grey}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <FlatList
                ref={innerScrollRef}
                data={scannedDocs}
                renderItem={renderScannedDocs}
                style={[Styles.proofsPreviewContainer]}
                horizontal
                showsHorizontalScrollIndicator={false}
                keyExtractor={keyExtractor}
                pagingEnabled
                contentContainerStyle={{ width: '100%', height: '100%' }}
                onLayout={event => {
                  setInnerScrollViewWidth(event.nativeEvent.layout.width);
                }}
              />
              <PageSwitch
                maxIndex={scannedDocs?.length}
                currentIndex={selectedProofIndex}
                onSwitch={handlePreviewPagesSwitch}
              />
            </View>
          </>
        )}
      </ScrollView>
    );
  };

  const selectedProof = scannedDocs[selectedProofIndex];

  return (
    <>
      {renderLeftPanel()}
      {showFullScreenProof && selectedProof && typeof selectedProof === 'string' ? (
        <FullScreenProof
          filePath={selectedProof}
          setShowModal={setShowFullScreenProof}
          fileType={getFileTypeFromDataURL(selectedProof)}
        />
      ) : null}
    </>
  );
};
