import React, { useState, useEffect, useContext, useRef } from 'react';
import { View, ScrollView, Text, TouchableOpacity, InteractionManager } from 'react-native';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import * as _ from 'lodash-es';
import Styles, { standardIconStyle } from './Style';
import { Card } from 'shared/ui-component/Card';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import { Checkbox } from 'shared/ui-component/Checkbox';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { Colors, Spacings } from 'shared/styles';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { TrainingSessionCard } from 'shared/layout/cards/training/training-session-card/TrainingSessionCard';
import Aigle from 'aigle';
import { MyHub } from 'shared/util/MyHub';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { RouteLocations } from '../../navigation/Routes';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import {
  DropdownConfigKey,
  TrainingTypeKey,
  removeInvalidObjectFromUserPreference,
} from '../../header-layout/headerFilterConfig';
import { ShadowOnHoverButton } from 'shared/ui-component/Button/ShadowOnHoverButton';
import { TrainWorkerModal } from '../../training/train-worker-modal/container/index';
import { DropDownWithWorkerLevelOption } from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { RequestTrainingMenu } from './RequestTrainingMenu';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { isArrayEmpty } from 'shared/util-ts/Functions';
import { LectureTrainingSessionModalContainer } from '../add-edit-lecture-traning-session-modal/container/LectureTrainingSessionModalContainer';
import { PracticalTrainingSessionModalContainer } from '../add-edit-practical-traning-session-modal/container/PracticalTrainingSessionModalContainer';
import { useFeature } from 'shared/hooks/useFeature';
import { ProofBookContainerWeb } from '../proof-book';
import { getTrainerIdsFromTraininersWithPercentage } from 'shared/backend-data/SharedUtil';
import { ButtonOnPressTypes } from 'shared/util/TrainingSession';
import { FlashList } from '@shopify/flash-list';

const ICON_THREE_DOTS = require('shared/assets/svg/icon.more.treeweb.svg').default;

export interface TrainingModalButton {
  label: string;
  onPressType: ButtonOnPressTypes;
}

enum trainingColumnType {
  ToReviewColumn,
  DraftColumn,
  ScheduledColumn,
  StartedColumn,
  LateEndColumn,
}

interface trainingColumnProps {
  columnType: trainingColumnType;
  zIndex: number;
  trainingSessions: API.TrainingSessionWithDetail[];
}

interface TrainingPanelProps {
  requestValidatedTrainingSessions: API.TrainingSessionWithDetail[];
  draftTrainingSessions: API.TrainingSessionWithDetail[];
  scheduledTrainingSessions: API.TrainingSessionWithDetail[];
  startedTrainingSessions: API.TrainingSessionWithDetail[];
  lateEndTrainingSessions: API.TrainingSessionWithDetail[];
}

const TrainingSVG = require('shared/assets/svg/icon.training2.svg').default;

export const TrainingsComponent: React.FC<RouteComponentProps> = () => {
  useFeature(API.AppFeature.SKILLOP_TRAINING);

  const { setFirstTitle, setSecondTitle, setOnPressBack } = useContext(HeaderTitleContext);
  const {
    trainingSessionScreenFilterTags: [trainingScreenFilterTags, setTrainingScreenFilterTags],
    currentRoute: [, setCurrentRoute],
  } = useContext(HeaderFilterContext);

  const filterTags = useRef<TagExtended[]>();

  const history = useHistory();

  const isMounted = useIsMounted();

  const [requestValidatedTrainingSessions, setRequestValidatedTrainingSessions] = useState<
    API.TrainingSessionWithDetail[]
  >([]);
  const [draftTrainingSessions, setDraftTrainingSessions] = useState<
    API.TrainingSessionWithDetail[]
  >([]);
  const [scheduledTrainingSessions, setScheduledTrainingSessions] = useState<
    API.TrainingSessionWithDetail[]
  >([]);
  const [startedTrainingSessions, setStartedTrainingSessions] = useState<
    API.TrainingSessionWithDetail[]
  >([]);
  const [lateEndTrainingSessions, setLateEndTrainingSessions] = useState<
    API.TrainingSessionWithDetail[]
  >([]);

  useEffect(() => {
    setFirstTitle(t('alex:header.trainings.0'));
    setSecondTitle(t('alex:header.trainings.1', undefined, false));
  }, []);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.TRAININGSESSION) {
        displayTrainingSessions(filterTags.current);
      }
    });

    return () => {
      removeListener();
      setCurrentRoute(undefined);
    };
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const trainingFilterResult = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.TrainingSessionFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(trainingFilterResult)) {
        logger.warn('fetch training filter: error in fetch user Preference', trainingFilterResult);
        return;
      }

      if (trainingFilterResult) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.TrainingSessionFilter,
          trainingFilterResult,
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.TrainingSessionFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save training sesssion filter: error while saving user Preference', saved);
          return;
        }
        setTrainingScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.TrainingSessionFilter) ?? [],
        );
      }

      setCurrentRoute(RoutePaths.TrainingSessions);

      return () => {
        setOnPressBack(() => () => {
          history.goBack();
        });
      };
    });
  }, []);

  useEffect(() => {
    displayTrainingSessions(trainingScreenFilterTags);
    filterTags.current = trainingScreenFilterTags;
  }, [trainingScreenFilterTags]);

  function displayTrainingSessions(trainingScreenFilterTags?: TagExtended[]) {
    if (trainingScreenFilterTags && trainingScreenFilterTags.length) {
      filterTrainingSessions(trainingScreenFilterTags);
    } else {
      _getTrainingSessions();
    }
  }

  async function _getTrainingSessions() {
    const _trainingSessionInfo = await getTrainingSessionWithIsPractical();
    if (!isMounted.current) return;
    if (API.isFailure(_trainingSessionInfo)) {
      logger.warn(_trainingSessionInfo);
      return;
    }
    if (_trainingSessionInfo) groupTrainingSessions(_trainingSessionInfo);
  }

  async function getTrainingSessionWithIsPractical(): Promise<
    API.TrainingSessionWithDetail[] | undefined
  > {
    const trainingSessions = await API.getTrainingSessions();
    if (!isMounted.current) return;
    if (API.isFailure(trainingSessions)) {
      logger.warn(trainingSessions);
      return;
    }

    const _trainingSessionWithDetails = await API.getTrainingSessionsWithDetail(trainingSessions);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingSessionWithDetails)) {
      logger.info('Failed while fetching training session details', _trainingSessionWithDetails);
      return;
    }

    return _trainingSessionWithDetails;
  }

  async function filterData(
    isKeyWordFiltering: boolean,
    filterTags: TagExtended[],
    trainingSessionDetails: API.TrainingSessionWithDetail[],
  ): Promise<API.TrainingSessionWithDetail[]> {
    if (isKeyWordFiltering && isArrayEmpty(filterTags)) return [];

    let _filteredTrainingSessions: API.TrainingSessionWithDetail[] = isKeyWordFiltering
      ? []
      : trainingSessionDetails;

    
    const shiftFilters: TagExtended[] = [];
    const workerFilters: TagExtended[] = [];
    const ouFilters: TagExtended[] = [];
    const workerTagFilters: TagExtended[] = [];
    const trainingTypeFilters: TagExtended[] = [];
    const workstationFilters: TagExtended[] = [];
    const trainingFilters: TagExtended[] = [];
    const trainingTagFilters: TagExtended[] = [];

    _.forEach(filterTags, filterTag => {
      if (filterTag.type === DropdownConfigKey.WORKER) workerFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.SHIFT) shiftFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.ORGUNIT) ouFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.WORKER_TAG) workerTagFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.TYPE_OF_TRAINING)
        trainingTypeFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.WORKSTATION) workstationFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.TRAINING) trainingFilters.push(filterTag);
      if (filterTag.type === DropdownConfigKey.TRAINING_TAG) trainingTagFilters.push(filterTag);
    });

    
    if (shiftFilters.length) {
      await Aigle.map(shiftFilters, async eachFilter => {
        const _filteredWorkers = await API.getWorkersInShift(
          eachFilter.value.shiftId,
          eachFilter.value.parentId,
        );
        if (!isMounted.current) return;
        if (API.isFailure(_filteredWorkers)) {
          logger.warn(_filteredWorkers);
          return;
        }

        _filteredWorkers.forEach(worker => {
          workerFilters.push({
            key: worker.id,
            label: '',
          });
        });
      });

      if (isArrayEmpty(workerFilters)) return [];
    }

    
    if (ouFilters.length) {
      const trainingSessions = await API.filterTrainingSessionsForOrgUnits(
        ouFilters.map(eachOU => eachOU.key),
        isKeyWordFiltering ? trainingSessionDetails : _filteredTrainingSessions,
      );
      if (!isMounted.current) return [];
      if (API.isFailure(trainingSessions)) {
        logger.warn(trainingSessions);
        return [];
      }

      if (isKeyWordFiltering) {
        _filteredTrainingSessions = [...trainingSessions, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = trainingSessions;
      }
    }

    
    if (workerFilters.length) {
      const _trainingSessionDetails = _.filter(
        isKeyWordFiltering ? trainingSessionDetails : _filteredTrainingSessions,
        eachData =>
          _.some(
            workerFilters,
            filter =>
              getTrainerIdsFromTraininersWithPercentage(
                API.deepClone(eachData.scheduledTrainers),
              ).includes(filter.key) ||
              eachData.scheduledTraineeIds.includes(filter.key) ||
              getTrainerIdsFromTraininersWithPercentage(API.deepClone(eachData.trainers)).includes(
                filter.key,
              ) ||
              eachData.traineeIds.includes(filter.key),
          ),
      );

      if (isKeyWordFiltering) {
        _filteredTrainingSessions = [..._trainingSessionDetails, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = _trainingSessionDetails;
      }
    }

    
    if (workerTagFilters.length) {
      const filteredDataForWorkerTags: API.TrainingSessionWithDetail[] = [];

      await Aigle.map(
        isKeyWordFiltering ? trainingSessionDetails : _filteredTrainingSessions,
        async filterData => {
          let _trainingSessionInfo;
          if (filterData.scheduledTrainers) {
            let found = false;

            for (const trainer of filterData.scheduledTrainers) {
              if (await findWorkerInWorkerTag(trainer.trainerId, workerTagFilters)) found = true;
            }

            if (found) {
              _trainingSessionInfo = filterData;
            }
          }
          if (filterData.scheduledTraineeIds && !_trainingSessionInfo) {
            await Aigle.map(filterData.scheduledTraineeIds, async scheduledTraineeId => {
              const found = await findWorkerInWorkerTag(scheduledTraineeId, workerTagFilters);
              if (found) {
                _trainingSessionInfo = filterData;
                return;
              }
            });
          }
          if (filterData.trainers && !_trainingSessionInfo) {
            let found = false;

            for (const trainer of filterData.trainers) {
              if (await findWorkerInWorkerTag(trainer.trainerId, workerTagFilters)) found = true;
            }

            if (found) {
              _trainingSessionInfo = filterData;
            }
          }
          if (filterData.traineeIds.length && !_trainingSessionInfo) {
            await Aigle.map(filterData.traineeIds, async traineeId => {
              const found = await findWorkerInWorkerTag(traineeId, workerTagFilters);
              if (found) {
                _trainingSessionInfo = filterData;
                return;
              }
            });
          }
          if (_trainingSessionInfo) filteredDataForWorkerTags.push(_trainingSessionInfo);
        },
      );

      if (isKeyWordFiltering) {
        _filteredTrainingSessions = [...filteredDataForWorkerTags, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = filteredDataForWorkerTags;
      }
    }

    
    if (trainingTypeFilters.length) {
      const data = _.filter(
        isKeyWordFiltering ? trainingSessionDetails : _filteredTrainingSessions,
        eachData =>
          _.some(
            trainingTypeFilters,
            filter =>
              (filter.key === TrainingTypeKey.PRACTICAL && eachData.isPractical) ||
              (filter.key === TrainingTypeKey.LECTURE && !eachData.isPractical),
          ),
      );

      if (isKeyWordFiltering) {
        _filteredTrainingSessions = [...data, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = data;
      }
    }

    
    if (workstationFilters.length) {
      const data = _.filter(
        isKeyWordFiltering ? trainingSessionDetails : _filteredTrainingSessions,
        eachData => _.some(workstationFilters, filter => filter.key === eachData.linkedObjectId),
      );

      if (isKeyWordFiltering) {
        _filteredTrainingSessions = [...data, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = data;
      }
    }

    
    if (trainingFilters.length) {
      const data = _.filter(
        isKeyWordFiltering ? trainingSessionDetails : _filteredTrainingSessions,
        eachData =>
          _.some(trainingFilters, filter => filter.key === eachData.trainingVersion.trainingId),
      );

      if (isKeyWordFiltering) {
        _filteredTrainingSessions = [...data, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = data;
      }
    }

    
    if (trainingTagFilters.length) {
      const filteredDataForTrainingTags: API.TrainingSessionWithDetail[] = [];

      await Aigle.map(
        isKeyWordFiltering ? trainingSessionDetails : _filteredTrainingSessions,
        async filterData => {
          const training = await API.getTrainingForATrainingVersion(filterData.trainingVersion.id);
          if (!isMounted.current) return;
          if (API.isFailure(training)) {
            logger.warn(training);
            return;
          }
          for (const trainingTagFilter of trainingTagFilters) {
            if (training.tagIds.includes(trainingTagFilter.key)) {
              filteredDataForTrainingTags.push(filterData);
              break;
            }
          }
        },
      );

      if (isKeyWordFiltering) {
        _filteredTrainingSessions = [...filteredDataForTrainingTags, ..._filteredTrainingSessions];
      } else {
        _filteredTrainingSessions = filteredDataForTrainingTags;
      }
    }

    return _.uniqBy(_filteredTrainingSessions, 'id');
  }

  async function filterTrainingSessions(filterTags: TagExtended[]) {
    const _trainingSessionInfo = await getTrainingSessionWithIsPractical();
    if (!isMounted.current) return;
    if (API.isFailure(_trainingSessionInfo)) {
      logger.warn(_trainingSessionInfo);
      return;
    }

    let _filteredData: API.TrainingSessionWithDetail[] = [..._trainingSessionInfo!];
    let _filterTags: TagExtended[] = [];
    let _keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(filterTags, tag => {
      if (tag.isActiveBookmarkTag) {
        _filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        _keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        _filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      const data = await filterData(true, _keywordTags, _filteredData);
      if (!isMounted.current) return;
      if (API.isFailure(data)) {
        logger.warn(data);
        return;
      }

      _filteredData = data;
    }

    if (_filterTags.length && _filteredData) {
      const data = await filterData(false, _filterTags, _filteredData);
      if (!isMounted.current) return;
      if (API.isFailure(data)) {
        logger.warn(data);
        return;
      }

      _filteredData = data;
    }

    groupTrainingSessions(_filteredData);
  }

  async function findWorkerInWorkerTag(
    workerId: string,
    workerTagFilters: TagExtended[],
  ): Promise<Boolean | undefined> {
    const _worker = await API.getWorker(workerId);
    if (!isMounted.current) return;
    if (API.isFailure(_worker)) {
      logger.warn(_worker);
      return;
    }
    return workerTagFilters.some(eachTagFilter => _worker.tagIds.includes(eachTagFilter.key));
  }

  async function groupTrainingSessions(trainingSessionsInfo: API.TrainingSessionWithDetail[]) {
    const _groupTrainingSessions = API.groupTrainingSessions(trainingSessionsInfo);

    setRequestValidatedTrainingSessions(_groupTrainingSessions.requested);
    setDraftTrainingSessions(_groupTrainingSessions.draft);
    setScheduledTrainingSessions(_groupTrainingSessions.scheduled);
    setStartedTrainingSessions(_groupTrainingSessions.started);
    setLateEndTrainingSessions(_groupTrainingSessions.lateEnded);
  }

  return (
    <View style={[Styles.container]}>
      <TrainingPanel
        scheduledTrainingSessions={scheduledTrainingSessions}
        requestValidatedTrainingSessions={requestValidatedTrainingSessions}
        draftTrainingSessions={draftTrainingSessions}
        startedTrainingSessions={startedTrainingSessions}
        lateEndTrainingSessions={lateEndTrainingSessions}
      />
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator
        contentContainerStyle={Styles.scrollViewContainer}
        style={Styles.scrollViewStyle}
      >
        <TrainingColumn
          zIndex={6}
          trainingSessions={requestValidatedTrainingSessions}
          columnType={trainingColumnType.ToReviewColumn}
        />
        <TrainingColumn
          zIndex={5}
          trainingSessions={draftTrainingSessions}
          columnType={trainingColumnType.DraftColumn}
        />
        <TrainingColumn
          zIndex={4}
          trainingSessions={scheduledTrainingSessions}
          columnType={trainingColumnType.ScheduledColumn}
        />

        <TrainingColumn
          zIndex={3}
          trainingSessions={startedTrainingSessions}
          columnType={trainingColumnType.StartedColumn}
        />
        <TrainingColumn
          zIndex={2}
          trainingSessions={lateEndTrainingSessions}
          columnType={trainingColumnType.LateEndColumn}
        />
      </ScrollView>
    </View>
  );
};

const TrainingColumn: React.FC<trainingColumnProps> = props => {
  const { columnType, zIndex, trainingSessions } = props;

  const { isValidPermission } = useContext(PermissionManagementContext);

  const history = useHistory();

  const [showColumnMenu, setShowColumnMenu] = useState<boolean>(false);
  const [showScrollBar, setShowScrollBar] = useState<boolean>(false);
  const [showPracticalTraining, setShowPracticalTraining] = useState<boolean>(true);
  const [showTrainings, setShowTrainings] = useState<boolean>(true);
  const [showAddTrainingProofTypeModal, setShowAddTrainingProofModal] = useState<boolean>(false);
  const [addProofTrainingSessionId, setAddProofTrainingSessionId] = useState<string>('');

  const ref = useDetectOutsideClick<View>(() => {
    setShowColumnMenu(false);
  });

  const hoverRef = useCallOnHover<View>(
    '',
    () => setShowScrollBar(true),
    () => setShowScrollBar(false),
  );

  function handleAddTrainingSessionProof(trainingSessionId: string) {
    setAddProofTrainingSessionId(trainingSessionId);
    setShowAddTrainingProofModal(true);
  }

  let trainingCount = trainingSessions.length;
  let trainingHeader = '';

  switch (columnType) {
    case trainingColumnType.ToReviewColumn:
      trainingHeader = t(
        'alex:trainingSessions.trainingTypes.request',
        {
          count: trainingSessions.length,
        },
        false,
      );
      break;
    case trainingColumnType.DraftColumn:
      trainingHeader = t(
        'alex:trainingSessions.trainingTypes.draft',
        {
          count: trainingSessions.length,
        },
        false,
      );
      break;
    case trainingColumnType.ScheduledColumn:
      trainingHeader = t(
        'alex:trainingSessions.trainingTypes.planned',
        {
          count: trainingSessions.length,
        },
        false,
      );
      break;
    case trainingColumnType.StartedColumn:
      trainingHeader = t('alex:trainingSessions.trainingTypes.inProgress', undefined, false);
      break;
    case trainingColumnType.LateEndColumn:
      trainingHeader = t('alex:trainingSessions.trainingTypes.toBeClosed', undefined, false);
      break;
    default:
      break;
  }

  function onCardPress(trainingSessionWithDetail: API.TrainingSessionWithDetail) {
    history.push(RouteLocations.TrainingSessions(trainingSessionWithDetail.id));
  }

  function renderItem({ item }: { item: API.TrainingSessionWithDetail }) {
    if (!showPracticalTraining && item.isPractical) {
      return null;
    }

    if (!showTrainings && !item.isPractical) {
      return null;
    }

    return (
      <View
        key={item.id}
        style={columnType !== trainingColumnType.ToReviewColumn && Styles.trainingCardContainer}
      >
        <TrainingSessionCard
          trainingSessionWithDetail={item}
          onCardPress={onCardPress}
          onAddProofTraining={handleAddTrainingSessionProof}
          onStartOrAddProofTraining={onCardPress}
        />
      </View>
    );
  }

  return (
    <Card ref={hoverRef} style={[Styles.bigColumn, { zIndex: zIndex }]}>
      <View style={Styles.columnHeaderContainer}>
        <Text style={Styles.columnHeaderStyle}>
          <Text style={Styles.textHeaderStyle}>
            <Text style={Styles.textCountStyle}>{trainingCount + ' '}</Text>
          </Text>
          {trainingHeader}
        </Text>
        <View style={Styles.flexView} />
        <TouchableOpacity
          style={{ marginTop: -4 }}
          onPress={() => setShowColumnMenu(!showColumnMenu)}
        >
          <IconSVG svgComponent={ICON_THREE_DOTS} size={standardIconStyle} />
        </TouchableOpacity>
        {showColumnMenu && (
          <Card ref={ref} style={Styles.columnMenu}>
            <Text style={Styles.columnHeaderText}>
              {t('alex:trainingSessions.columnMenu.display')}
            </Text>
            <Checkbox
              text={t('glossary:trainingPractical')}
              style={Styles.checkBox}
              onChange={(state: boolean | null) =>
                state ? setShowPracticalTraining(state) : setShowPracticalTraining(false)
              }
              initialState={showPracticalTraining ?? true}
            />
            <Checkbox
              text={t('glossary:training')}
              style={Styles.checkBox}
              onChange={(state: boolean | null) =>
                state ? setShowTrainings(state) : setShowTrainings(false)
              }
              initialState={showTrainings ?? true}
            />
          </Card>
        )}
      </View>
      <FlashList
        showsVerticalScrollIndicator={showScrollBar}
        data={trainingSessions}
        estimatedItemSize={50}
        keyExtractor={item => item.id}
        renderItem={renderItem}
      />
      {showAddTrainingProofTypeModal && (
        <ProofBookContainerWeb
          trainingSessionId={addProofTrainingSessionId}
          setShowAddTrainingProofModal={setShowAddTrainingProofModal}
          disableProofAddition={!isValidPermission(API.Permission.trainingSessions_edit)}
        />
      )}
    </Card>
  );
};

const TrainingPanel: React.FC<TrainingPanelProps> = props => {
  const {
    requestValidatedTrainingSessions,
    draftTrainingSessions,
    scheduledTrainingSessions,
    startedTrainingSessions,
    lateEndTrainingSessions,
  } = props;

  const totalTrainings =
    requestValidatedTrainingSessions.length +
    draftTrainingSessions.length +
    scheduledTrainingSessions.length +
    startedTrainingSessions.length +
    lateEndTrainingSessions.length;

  const [isCatalogHover, setIsCatalogHover] = useState(false);
  const [showAddTrainingMenu, setShowAddTrainingMenu] = useState<boolean>(false);
  const [showLectureModal, setShowLectureModal] = useState<boolean>(false);
  const [showPracticalModal, setShowPracticalModal] = useState<boolean>(false);
  const [showTrainAWorkerOnAworkstationModal, setShowTrainAWorkerOnAworkstationModal] =
    useState(false);
  const [preSelectedTreeNode, setPreSelectedTreeNode] = useState<DropDownOption>();
  const [preSelectedWorker, setPreSelectedWorker] = useState<DropDownWithWorkerLevelOption>();
  const [preSelectedLevel, setPreSelectedLevel] = useState<API.WorkstationWorkerLevels>();
  const { isValidPermission } = useContext(PermissionManagementContext);

  const history = useHistory();

  const textRef = useCallOnHover<Text>(
    Colors.Transparent,
    () => setIsCatalogHover(true),
    () => setIsCatalogHover(false),
  );

  return (
    <Card style={[Styles.smallColumn, { zIndex: -1 }]}>
      {showAddTrainingMenu && (
        <RequestTrainingMenu
          style={[
            Styles.requestTrainingMenuStyle,
            { minWidth: isValidPermission(API.Permission.trainingSessions_edit) ? 410 : 344 },
          ]}
          setShowAddTrainingMenu={setShowAddTrainingMenu}
          setShowLectureModal={setShowLectureModal}
          setShowPracticalModal={setShowPracticalModal}
          setShowTrainAWorkerOnAworkstationModal={isShow => {
            setPreSelectedLevel(undefined);
            setPreSelectedTreeNode(undefined);
            setPreSelectedWorker(undefined);
            setShowTrainAWorkerOnAworkstationModal(isShow);
          }}
        />
      )}
      {showLectureModal && (
        <LectureTrainingSessionModalContainer
          showModal={showLectureModal}
          handleModalClose={() => {
            setShowLectureModal(false);
          }}
        />
      )}
      {showPracticalModal && (
        <PracticalTrainingSessionModalContainer
          showModal={showPracticalModal}
          handleModalClose={() => {
            setShowPracticalModal(false);
          }}
        />
      )}
      {showTrainAWorkerOnAworkstationModal && (
        <TrainWorkerModal
          openModal={showTrainAWorkerOnAworkstationModal}
          workerId={preSelectedWorker?.key}
          treeNodeId={preSelectedTreeNode?.key}
          trainingLevel={preSelectedLevel}
          handleModalClose={() => setShowTrainAWorkerOnAworkstationModal(false)}
          disableRedirection
        />
      )}
      <TouchableOpacity
        ref={useCallOnHover<TouchableOpacity>()}
        style={[Styles.headerContainer]}
        onPress={() => {
          setShowAddTrainingMenu(true);
        }}
        disabled={!isValidPermission(API.Permission.trainingSessions_edit)}
      >
        <IconSVG
          svgComponent={TrainingSVG}
          size={standardIconStyle}
          containerStyle={{
            width: 24,
            height: 24,
            marginRight: Spacings.Unit,
          }}
        />
        <Text style={[Styles.textBoldHeaderStyle, Styles.centeredTextStyle]} numberOfLines={1}>
          {totalTrainings + ' ' + t('glossary:training', { count: totalTrainings })}
        </Text>
        <ShadowOnHoverButton
          size={24}
          iconSize={12}
          style={Styles.onHoverButtonStyle}
          iconContainerStyle={{ backgroundColor: Colors.Yellow }}
          onPress={() => {
            setShowAddTrainingMenu(true);
          }}
        />
      </TouchableOpacity>
      <View
        style={{
          paddingTop: Spacings.Unit * 2.5,
          paddingBottom: Spacings.Unit * 12,
          paddingHorizontal: Spacings.Medium,
        }}
      >
        <View style={[Styles.itemContainer]}>
          <Text style={Styles.textItemStyle}>
            {t('alex:trainingSessions.trainingTypes.request', {
              count: requestValidatedTrainingSessions.length,
            })}
          </Text>
          <View style={Styles.flexView} />
          <View style={Styles.greyBubbleView}>
            <Text style={Styles.countNumber}>{requestValidatedTrainingSessions.length}</Text>
          </View>
        </View>
        <View style={[Styles.itemContainer]}>
          <Text style={Styles.textItemStyle}>
            {t('alex:trainingSessions.trainingTypes.draft', {
              count: draftTrainingSessions.length,
            })}
          </Text>
          <View style={Styles.flexView} />
          <View style={Styles.greyBubbleView}>
            <Text style={Styles.countNumber}>{draftTrainingSessions.length}</Text>
          </View>
        </View>
        <View style={Styles.itemContainer}>
          <Text style={Styles.textItemStyle}>
            {t('alex:trainingSessions.trainingTypes.planned', {
              count: scheduledTrainingSessions.length,
            })}
          </Text>
          <View style={Styles.flexView} />
          <View style={Styles.greyBubbleView}>
            <Text style={Styles.countNumber}>{scheduledTrainingSessions.length}</Text>
          </View>
        </View>
        <View style={Styles.itemContainer}>
          <Text style={Styles.textItemStyle}>
            {t('alex:trainingSessions.trainingTypes.inProgress')}
          </Text>
          <View style={Styles.flexView} />
          <View style={Styles.greyBubbleView}>
            <Text style={Styles.countNumber}>{startedTrainingSessions.length}</Text>
          </View>
        </View>
        <View style={Styles.itemContainer}>
          <Text style={Styles.textItemStyle}>
            {t('alex:trainingSessions.trainingTypes.toBeClosed')}
          </Text>
          <View style={Styles.flexView} />
          <View style={Styles.greyBubbleView}>
            <Text style={Styles.countNumber}>{lateEndTrainingSessions.length}</Text>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => {
            history.push(RouteLocations.Trainings());
          }}
          style={[Styles.itemContainer, { marginBottom: 0 }]}
        >
          <Text
            ref={textRef}
            style={[
              Styles.textItemStyle,
              { color: Colors.Grey },
              isCatalogHover && { color: Colors.Black },
            ]}
          >
            {t('alex:trainingSessions.trainingPanel.catalog')}
          </Text>
          <View style={Styles.flexView} />
        </TouchableOpacity>
      </View>
    </Card>
  );
};
