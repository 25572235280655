import { useEffect, useState } from 'react';
import logger from 'shared/util/Logger';
import { MyHub } from 'shared/util/MyHub';
import * as API from 'shared/backend-data';
import { useModal } from '../ui-component/Modal/Modal';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { DataLayer, CacheUpdateEventSource } from '../backend-data/DataLayer';
import { Tree } from 'shared/backend-data';
import { ModalUtils } from '../ui-component/Modal';
import { AppContext } from '../context/AppContext';
import { checkClientVersionAndSyncClientCache } from 'shared/backend-data/DataLayerUi';

/**
 * Hook that returns if a Tenant/TenantApp is set.
 * Client shall use this hook to switch for select Tenant/TenantApp screen to the Tenant/TenantApp.
 */
export function useAppSubscription() {
  const [isTenantAppLoaded, setIsTenantAppLoaded] = useState<boolean>(false);
  const [isCacheContextSet, setIsCacheContextSet] = useState<boolean>(false);

  const modal = useModal();

  const isMounted = useIsMounted();

  useEffect(() => {
    initializeCache();
  }, []);

  useEffect(() => {
    if (!isCacheContextSet) return;

    
    const removeAppVersionListener = MyHub.listenBusinessObject(
      'BusinessObjectMutate',
      async ({ data }) => {
        if (data.factory.dataType === API.DataType.APP) {
          logger.debug('App - Hub event ServerVersionChange Listener');

          if (data.tooManyMutations) {
            
          } else {
            if (!API.isSubscriptionUpdate(data.mutationType)) return; 

            const oldAppFactory = API.getFactory(
              data.factory.dataType,
              data.factory.sk,
              'cache-only',
              data.factory.pk,
            );
            if (API.isFailure(oldAppFactory)) {
              
            } else if (data.factory.app.version === oldAppFactory.app.version) {
              return; 
            }
          }

          const result = await checkClientVersionAndSyncClientCache(
            CacheUpdateEventSource.ServerVersionChange,
            modal,
          );
          if (result === false) return;
        }
      },
    );

    
    
    initializeGlobalContext();

    
    const removeTenantOrAppSetListener = MyHub.listenAppContext('TenantAppSet', async payload => {
      logger.debug('App - Hub event TenantAppSet on AppContext channel:', payload);

      
      modal.displayModal(
        ModalUtils.TenantAppLoadingConfig({
          name: payload.factory.worker.firstName + ' ' + payload.factory.worker.familyName,
        }),
      );

      const result = await checkClientVersionAndSyncClientCache(
        CacheUpdateEventSource.TenantAppSet,
        modal,
      );
      if (result === false) return;

      const updatedWorker = await API.updateWorker({
        id: payload.factory.worker.id,
        lastLogin: new Date().toISOString(),
      });
      if (API.isFailure(updatedWorker)) {
        logger.warn('Failed to store User latest loginDate');
      }

      await AppContext.getUserPreferedLanguageAndSetLanguageAndGlossary();

      if (!(payload.factory.worker.familyName && payload.factory.worker.firstName))
        modal.displayModal(
          ModalUtils.CompleteUserData({
            showHideModal: () => {
              modal.hideModal();
            },
          }),
        );

      setIsTenantAppLoaded(true);
    });

    const removeTenantOrAppUnSetListener = MyHub.listenAppContext(
      'TenantAppUnset',
      async payload => {
        logger.debug('App - Hub event TenantAppUnset on AppContext channel');

        setIsTenantAppLoaded(false);
      },
    );

    return () => {
      removeTenantOrAppSetListener();
      removeTenantOrAppUnSetListener();
      removeAppVersionListener();
    };
  }, [isCacheContextSet]);

  async function initializeGlobalContext(): Promise<void> {
    await DataLayer.subscribeToUpdates(API.globalPk);

    const sync = await DataLayer.syncClientCacheWithServer(API.globalPk);
    if (API.isFailure(sync)) {
      logger.warn('Sync error for Global pk: ' + sync.message, sync);
    }
  }

  async function initializeCache(): Promise<void> {
    await DataLayer.initConfig({
      enableUpdates: {
        enableCache: {
          enablePersistence: {
            enableAutoPersistenceOnChange: true,
          },
          enableQueryCache: {
            queryCacheAreCacheItemsOrdered: false, 
          },
          factoryCacheDefaultFill: true,
          factoryCacheCustom: {
            
            
            TENANT: { fill: false }, 
            USERPREFERENCE: { fill: false }, 
            TRAINING_TRAININGVERSION: { fill: false },
            
            PROOFBUNDLE: { fill: false }, 
            WORKERSKILL: { fill: false },
            
            
            WORKSTATION: { fill: true, class: Tree },
            ORGUNIT: { fill: true, class: Tree },
            TRAININGSESSION: { fill: false },
          },
        },
      },
    });

    if (!isMounted.current) return;

    setIsCacheContextSet(true);
  }

  return [isCacheContextSet, isTenantAppLoaded];
}
