import React, { useContext, useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import * as API from 'shared/backend-data';
import { LectureTrainingSessionModalContainer } from '../training/add-edit-lecture-traning-session-modal/container/LectureTrainingSessionModalContainer';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { PracticalTrainingSessionModalContainer } from '../training/add-edit-practical-traning-session-modal/container/PracticalTrainingSessionModalContainer';
import { useHistory } from 'react-router-dom';
import { RemoveSearchParam } from 'shared/util/Routes';

interface Props {}

/**
 * This component is used to check for the search params in the url and render the component according to the key values
 * Now its support trainingSession modal
 */
export const CheckSearchParamAndRenderComponent: React.FC<Props> = () => {
  const isMounted = useIsMounted();
  const history = useHistory();

  const [showPracticalTraining, setShowPracticalTraining] = useState<boolean>(false);
  const [showLectureTraining, setShowLectureTraining] = useState<boolean>(false);
  const [trainingSessionId, setTrainingSessionId] = useState<string>();

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      const searchParam = window.location.search;
      const hashParam = window.location.hash;
      let param = searchParam + hashParam;

      if (param) {
        const routePathParam = param.split('=')[0]?.substring(1);
        const paramValue = param.split('=')[1];

        if (paramValue) {
          

          if (routePathParam === RoutePaths.TrainingSessionId && paramValue)
            displayTrainingSessionModal(paramValue);
        }
      }
    });
  }, [window.location.search]);

  function handleModalClose() {
    history.push(RemoveSearchParam());
    setShowPracticalTraining(false);
    setShowLectureTraining(false);
    setTrainingSessionId(undefined);
  }

  async function displayTrainingSessionModal(id: string) {
    const trainingSession = await API.getTrainingSession(id);
    if (!isMounted.current) return;
    if (API.isFailure(trainingSession)) {
      logger.warn(trainingSession);
      return;
    }

    const trainingSessionsWithDetail = await API.getTrainingSessionsWithDetail([trainingSession]);
    if (!isMounted.current) return;
    if (API.isFailure(trainingSessionsWithDetail)) {
      logger.warn(trainingSessionsWithDetail);
      return;
    }

    setTrainingSessionId(id);

    if (trainingSessionsWithDetail[0].isPractical) {
      setShowPracticalTraining(true);
    } else if (!trainingSessionsWithDetail[0].isPractical) {
      setShowLectureTraining(true);
    }
  }

  
  
  return (
    <>
      {showPracticalTraining && (
        <PracticalTrainingSessionModalContainer
          trainingSessionId={trainingSessionId}
          showModal={showPracticalTraining}
          handleModalClose={handleModalClose}
        />
      )}
      {showLectureTraining && (
        <LectureTrainingSessionModalContainer
          showModal={showLectureTraining}
          trainingSessionId={trainingSessionId}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};
