import React from 'react';
import { Text } from 'react-native';
import { ModalHeaderBoldTextStyle, ModalHeaderRegularTextStyle } from 'shared/styles/ModalStyles';

interface Props {
  title: string;
  invert?: boolean;
}

export const ModalHeaderText: React.FC<Props> = props => {
  const { title, invert } = props;

  const headerTitle = title.split(' ');

  return (
    <Text style={invert ? ModalHeaderRegularTextStyle : ModalHeaderBoldTextStyle}>
      {headerTitle?.length > 1 ? (
        <>
          <Text style={invert ? ModalHeaderBoldTextStyle : ModalHeaderRegularTextStyle}>
            {headerTitle[0]}{' '}
          </Text>
          <Text>{headerTitle.slice(1).join(' ')}</Text>
        </>
      ) : (
        <Text>{headerTitle[0]}</Text>
      )}
    </Text>
  );
};
