import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { AppContext } from '../context/AppContext';
import { capitalizeFirstLetter, i18n, t } from 'shared/localisation/i18n';
import Aigle from 'aigle';
import { emptyString } from 'shared/context/AppContext';
import { loggerAPI as logger } from 'shared/util/Logger';
import { replaceDiacriticsAndCapitalLetter } from 'shared/util-ts/Functions';
import { SkillBadgeStates } from './Skill';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { getWorkerWorkstations } from 'shared/backend-data';
import { _findWorkerByNameOrEmailOrPhoneOrMatricule } from 'backend/src/util-businessObject/Worker';
import * as PhoneNumberUtil from 'google-libphonenumber';

export interface WorkerWorkstationDetails {
  worker: API.Worker;
  workerWorkstation?: API.WorkerWorkstation;
}

export interface WorkerProofBundles {
  worker: API.Worker;
  proofBundles: API.NoMetadata<API.ProofBundle>[];
  latestProofBundle: API.NoMetadata<API.ProofBundle>;
}

export interface ContractTypeNameWithCount {
  contractTypeName: string;
  workerCount: number;
  workerCountPercentage: number;
}

export interface WorkerProofBundles {
  worker: API.Worker;
  proofBundles: API.ProofBundle[];
  latestProofBundle: API.ProofBundle;
}

export enum PhoneNumberFormat {
  international = 'int',
  local = 'local',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum WorkersStatus {
  ActiveUsers = 'ActiveUsers',
  InvitedUsers = 'InvitedUsers',
  AllWorkersExceptInvitedByEmailUsersWithoutProfileName = 'AllWorkersExceptInvitedUsersWithoutProfile', 
  Users = 'Users',
  NonUsers = 'NonUser',
}

/**
 * Upload the given file and add it to the given Worker
 * @param worker
 * @param file
 */
export async function uploadWorkerPicture(
  worker: API.Worker,
  file: File | Blob,
): Promise<API.Result<API.Worker>> {
  
  const s3Object = await API.uploadFile(file, API.StorageVisibility.public);
  if (API.isFailure(s3Object)) return s3Object;

  
  const workerInput: API.WorkerPartialUpdateInput = {
    id: worker.id,
    profilePicture: s3Object.key,
  };

  const _worker = await API.updateWorker(workerInput);
  if (API.isFailure(_worker)) return API.createFailure_Unspecified(_worker);

  return _worker;
}

/**
 * Update a Worker. It takes care of updating/creating/deleting
 * the Contracts optionaly passed in worker.contracts and
 * @param worker
 */
export async function updateWorker(
  workerInput: API.WorkerPartialUpdateInput,
): Promise<API.Result<API.Worker>> {
  
  const factory = await API.updateFactoryBusinessObject(API.DataType.WORKER, workerInput);
  if (API.isFailure(factory)) return factory;

  return { ...factory.worker, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

/**
 * Get the current Worker or the one specified by it id
 * @param workerId (Optional) if not set, returns the logged in worker
 * @param noGhostWorker (Optional) if set, unauthorized (ghost) workers as not returned
 */
export async function getWorker(
  workerId?: string,
  noGhostWorker?: boolean,
): Promise<API.Result<API.Worker>> {
  if (!workerId) {
    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) return appContext;

    workerId = appContext.workerId;
  }

  const factory = await API.getFactoryBusinessObject(API.DataType.WORKER, workerId);
  if (API.isFailure(factory)) {
    if (API.isFailureType(factory, 'Unauthorized') && !noGhostWorker)
      return { ...getGhostWorker(), updatedAt: '', updatedBy: '' };
    else return factory;
  }

  return { ...factory.worker, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

/**
 * Function to find the worker from userId
 * @param userId
 * @returns
 */
export async function getWorkerFromUserId(userId: string): Promise<API.Result<API.Worker>> {
  const workers = await API.getWorkers();
  if (API.isFailure(workers)) {
    return workers;
  }

  const workerFound = workers.result.find(worker => worker.userId === userId);
  if (workerFound) {
    return workerFound;
  }

  return API.createFailure('ObjectNotFound', `${userId} not found`);
}

/**
 * Get all the Workers
 * @param permissions (optional) if set return the Workers that have at least one of the given permissions
 * @param skillId (optional) if set return the Workers that have the given Skill
 * @param includeArchived (optional, default=false) if true, returns also the Workers with state='ARCHIVED'
 * @param workerStatus (optional, default= AllWorkersExceptInvitedByEmailUsersWithoutProfileName) return the workers matching the status filter passed, if null there's no filter applied
 * @param itemsLimit limit roughly the number of returned Workers
 * @param nextToken
 */
export async function getWorkers(
  permissions?: API.Permission[],
  skillId?: string,
  includeArchived: boolean = false,
  workerStatus: WorkersStatus | null = WorkersStatus.AllWorkersExceptInvitedByEmailUsersWithoutProfileName,
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.Worker[]>> {
  return _getWorkers(
    skillId,
    permissions,
    undefined,
    includeArchived,
    workerStatus,
    itemsLimit,
    nextToken,
  );
}

export function checkWorkerMatchTheStatusFilter(
  worker: API.NoMetadata<API.Worker>,
  workerStatus: WorkersStatus,
): boolean {
  switch (workerStatus) {
    case WorkersStatus.AllWorkersExceptInvitedByEmailUsersWithoutProfileName:
      return !isUserNameEmpty(worker);
    case WorkersStatus.Users:
      return isUser(worker);
    case WorkersStatus.ActiveUsers:
      return isActiveUser(worker);
    case WorkersStatus.InvitedUsers:
      return isInvitedUser(worker);
    case WorkersStatus.NonUsers:
      return isNonUserWorker(worker);
  }
}

export function isUserNameEmpty(worker: API.NoMetadata<API.Worker>): boolean {
  return isUser(worker) && worker.firstName == emptyString;
}

export function isUser(worker: API.NoMetadata<API.Worker>) {
  return worker.userId != null;
}

export function isActiveUser(worker: API.NoMetadata<API.Worker>): boolean {
  return isUser(worker) && worker.lastLogin != null;
}

export function isInvitedUser(worker: API.NoMetadata<API.Worker>): boolean {
  return isUser(worker) && !isActiveUser(worker);
}

export function isNonUserWorker(worker: API.NoMetadata<API.Worker>): boolean {
  return !isUser(worker);
}

async function _getWorkers(
  skillId?: string,
  permissions?: API.Permission[],
  organizationalUnitIds?: string[],
  includeArchived: boolean = false,
  workerStatus: WorkersStatus | null = WorkersStatus.AllWorkersExceptInvitedByEmailUsersWithoutProfileName,
  itemsLimit?: number,
  nextToken?: string,
  includeInherited: boolean = true,
): Promise<API.ResultWithNextToken<API.Worker[]>> {
  if (skillId) {
    const workerSkills = await API.getWorkerSkills(undefined, skillId, itemsLimit, nextToken);
    if (API.isFailure(workerSkills)) return workerSkills;

    const workers: API.Worker[] = [];
    const errors: API.Failure[] = [];
    await Aigle.forEach(workerSkills.result, async workerSkill => {
      const worker = await getWorker(workerSkill.workerId);
      if (API.isFailure(worker)) {
        errors.push(worker);
        return;
      }

      if (
        (includeArchived || worker.state !== API.WorkerState.ARCHIVED) &&
        (!workerStatus || checkWorkerMatchTheStatusFilter(worker, workerStatus))
      ) {
        if (!_.some(workers, worker)) workers.push(worker);
      }
    });
    if (errors.length) return API.createFailure_Multiple(errors);

    const workersOrgUnitRolesMap = await API.getWorkersAssignments(
      true,
      false,
      workers.map(worker => worker.id),
      organizationalUnitIds,
      permissions,
    );
    if (API.isFailure(workersOrgUnitRolesMap)) return workersOrgUnitRolesMap;

    const filteredWorkers = _.filter(workers, worker => workersOrgUnitRolesMap.has(worker.id));

    return {
      result: filteredWorkers,
      nextToken: workerSkills.nextToken,
    };
  } else if (!permissions && !organizationalUnitIds) {
    const workers: API.Worker[] = [];
    const factories = await API.listFactoriesWithDataType(
      API.DataType.WORKER,
      undefined,
      itemsLimit,
      nextToken,
    );
    if (API.isFailure(factories)) return factories;

    _.forEach(factories.result, factory => {
      if (
        (includeArchived || factory.worker!.state !== API.WorkerState.ARCHIVED) &&
        (!workerStatus || checkWorkerMatchTheStatusFilter(factory.worker!, workerStatus))
      ) {
        workers.push({
          ...factory.worker,
          updatedAt: factory.updatedAt,
          updatedBy: factory.updatedBy,
        });
      }
    });

    return {
      result: workers,
      nextToken: factories.nextToken,
    };
  } else {
    const workersOrgUnitRolesMap = await API.getWorkersAssignments(
      includeInherited,
      false,
      undefined,
      organizationalUnitIds,
      permissions,
    );
    if (API.isFailure(workersOrgUnitRolesMap)) return workersOrgUnitRolesMap;

    const workers: API.Worker[] = [];
    const errors: API.Failure[] = [];
    await Aigle.forEach(
      Array.from(workersOrgUnitRolesMap.entries()),
      async ([workerId, assignments]) => {
        if (!assignments.length) return;

        const worker = await API.getWorker(workerId);
        if (API.isFailure(worker)) {
          errors.push(worker);
        } else {
          if (
            (includeArchived || worker.state !== API.WorkerState.ARCHIVED) &&
            (!workerStatus || checkWorkerMatchTheStatusFilter(worker, workerStatus))
          ) {
            workers.push(worker);
          }
        }
      },
    );
    if (errors.length) return API.createFailure_Multiple(errors);

    return {
      result: workers,
    };
  }
}

/**
 * Look for a Worker with the given name or email or phone or employeeId.
 * If found, check that all identifiers match otherwise return a Failure.
 * Returns a Failure if there are several Workers with the same name.
 * WARNING it shall not be used to test the existence of a Worker as the result depends on the User scope (what data user can see).
 * @param workers (Optional) give list of workers to search in. If not passed all the workers accessible to the user will be used.
 */
export async function getWorkerByIdentifier(
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  matricule?: string | null,
  checkAllIdentifiersMatch = true,
  workers?: API.Worker[],
): Promise<API.Result<API.Worker | undefined>> {
  let _workers: API.Worker[] = [];

  if (workers) {
    _workers = workers;
  } else {
    const fetchedWorkers = await API.getWorkers(undefined, undefined, true);
    if (API.isFailure(fetchedWorkers)) return fetchedWorkers;

    _workers = fetchedWorkers.result;
  }

  return _findWorkerByNameOrEmailOrPhoneOrMatricule(
    _workers,
    name,
    email,
    phone,
    matricule,
    checkAllIdentifiersMatch,
  );
}

export async function getWorkerByNameOrEmailOrPhoneOrMatricule(
  identifier: string,
): Promise<API.Result<API.Worker | undefined>> {
  
  
  const workerByName = await API.getWorkerByIdentifier(identifier);
  if (API.isFailure(workerByName)) return workerByName;
  if (workerByName) return workerByName;

  const workerByEmail = await API.getWorkerByIdentifier(null, identifier);
  if (API.isFailure(workerByEmail)) return workerByEmail;
  if (workerByEmail) return workerByEmail;

  const workerByPhone = await API.getWorkerByIdentifier(null, null, identifier);
  if (API.isFailure(workerByPhone)) return workerByPhone;
  if (workerByPhone) return workerByPhone;

  const workerByMatricule = await API.getWorkerByIdentifier(null, null, null, identifier);
  if (API.isFailure(workerByMatricule)) return workerByMatricule;
  if (workerByMatricule) return workerByMatricule;
}

/**
 * Get all the Workers related to the given OrganizationalUnits
 * and optionaly their descendants OrganizationalUnits
 * @param organizationalUnitIds (it can contains duplicates)
 * @param perissions (optional) if set return the Workers that have at least one of the given Permissions
 * @param includeNested (optional, default=false) if true, it also return the Workers of the nested OrganizationalUnits
 * @param itemsLimit limit roughly the number of returned Workers
 * @param nextToken
 */
export async function getWorkersInOrganizationalUnits(
  organizationalUnitIds: string[],
  permissions?: API.Permission[],
  includeNested = false,
  includeInherited = true,
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.Worker[]>> {
  if (!organizationalUnitIds.length) return { result: [] };

  const allOrgUnitIdsNoDuplicate: Set<string> = new Set();
  for (const organizationalUnitId of organizationalUnitIds) {
    allOrgUnitIdsNoDuplicate.add(organizationalUnitId);
    if (includeNested) {
      const isRootOrganizationalUnit = API.Tree.isRootOrganizationalUnit(organizationalUnitId);
      if (API.isFailure(isRootOrganizationalUnit)) return isRootOrganizationalUnit;

      if (isRootOrganizationalUnit) {
        return _getWorkers(
          undefined,
          permissions,
          undefined,
          false,
          undefined,
          itemsLimit,
          nextToken,
          includeInherited,
        );
      }

      const orgUnitChildren = API.Tree.getChildren(
        organizationalUnitId,
        true,
        API.DataType.ORGUNIT,
      );
      if (API.isFailure(orgUnitChildren)) return orgUnitChildren;

      orgUnitChildren.forEach(orgUnitChild => {
        allOrgUnitIdsNoDuplicate.add(orgUnitChild.id);
      });
    }
  }

  return _getWorkers(
    undefined,
    permissions,
    Array.from(allOrgUnitIdsNoDuplicate.values()),
    false,
    undefined,
    itemsLimit,
    nextToken,
    includeInherited,
  );
}

/**
 * Tells if the given Worker is assigned to the given OrganizationalUnit (it means with workerIsOperational permission)
 * PS:
 * - to get Workers belonging to an OrganizationalUnit with permission, use @see getWorkersInOrganizationalUnit()
 * - to get the OrganizationalUnits of a Worker with permission
 * - to get is a Worker has a permission on a OrgUnit @see isValidPermission()
 * @param workerId
 * @param organizationalUnitId
 */
export async function isWorkerAssignedToOrganizationalUnit(
  workerId: string,
  organizationalUnitId: string,
): Promise<API.Result<boolean>> {
  const workerAssignments = await API.getWorkerAssignments(
    workerId,
    false,
    false,
    [organizationalUnitId],
    [API.Permission.workerIsOperational],
  );

  if (API.isFailure(workerAssignments)) {
    if (API.isFailureType(workerAssignments, 'ObjectNotFound')) {
      return false;
    } else {
      return workerAssignments;
    }
  }

  return workerAssignments.length > 0;
}

/**
 * Tells if the given Worker is admin on the root OrganizationalUnit
 * @param worker - optional, if not specified: current logged in user
 */
export async function isWorkerAdminOnRootUnit(worker?: API.Worker): Promise<API.Result<boolean>> {
  const rootOrgUnit = API.Tree.getRootOrganizationalUnit();
  if (!rootOrgUnit) return false; 

  return isWorkerAdmin(rootOrgUnit, worker);
}

/**
 * Tells if the given Worker is admin on the given OrganizationalUnit (meaning with workers_edit permission)
 * @param organizationalUnit
 * @param worker - optional, if not specified: current logged in user
 */
export async function isWorkerAdmin(
  organizationalUnit: API.OrganizationalUnit,
  worker?: API.Worker,
): Promise<API.Result<boolean>> {
  let workerId: string;

  if (!worker) {
    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) return appContext;

    workerId = appContext.workerId;
  } else workerId = worker.id;

  const workerAssignments = await API.getWorkerAssignments(workerId, true, false);
  if (API.isFailure(workerAssignments)) return workerAssignments;

  for (const workerAssignment of workerAssignments) {
    if (
      workerAssignment.organizationalUnitId === organizationalUnit.id &&
      workerAssignment.permissions.includes(API.Permission.workers_edit)
    )
      return true;
  }
  return false;
}

/**
 * Tells if the given Worker is manager on the given OrganizationalUnit (meaning with workerIsManager permission)
 * @param organizationalUnit
 * @param worker - optional, if not specified: current logged in user
 */
export async function isWorkerManager(
  organizationalUnit: API.OrganizationalUnit,
  worker?: API.Worker,
): Promise<API.Result<boolean>> {
  let workerId: string;

  if (!worker) {
    const appContext = AppContext.getContext();
    if (API.isFailure(appContext)) return appContext;

    workerId = appContext.workerId;
  } else workerId = worker.id;

  const workerAssignments = await API.getWorkerAssignments(workerId, true, false);
  if (API.isFailure(workerAssignments)) return workerAssignments;

  for (const workerAssignment of workerAssignments) {
    if (
      workerAssignment.organizationalUnitId === organizationalUnit.id &&
      workerAssignment.permissions.includes(API.Permission.workerIsManager)
    )
      return true;
  }
  return false;
}

/**
 * Get the Workers (among the specified list of Workers) who have a Level alert on at least one of the Workstation specified in the list of Workstations
 * The result contains a list of [WorkerId, WorkstationId and worker Level]
 * @param workstationIds (optional) if not set, all Workstations are considered
 * @param workerIds (optional) if not specified, all the company Workers are considered.
 */
export async function getWorkersWithLevelAlert(
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  workstationIds?: string[],
  workerIds?: string[],
): Promise<API.Result<API.WorkerWorkstation[]>> {
  if (!workstationIds) {
    const allWorkstations = API.getWorkstations();
    workstationIds = allWorkstations.map(workstation => workstation.id);
  }

  const workersWorkstations = await API.getWorkersSkilledOrPlannedToBeSkilled(
    workstationTargetsStartingAtLevel,
    workstationIds,
    workerIds,
  );
  if (API.isFailure(workersWorkstations)) return workersWorkstations;

  const result: API.WorkerWorkstation[] = [];
  _.forEach(workersWorkstations, workerWorkstation => {
    const alert =
      workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRED ||
      workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON;

    if (alert) result.push(workerWorkstation);
  });

  return result;
}

/**
 * Return the filtered list of passed Workers that are Skilled @see isWorkerSkilled() on the specified Workstations.
 * @param workstationIds
 * @param workerIds
 */
export async function getWorkersSkilled(
  workstationIds: string[],
  workerIds: string[],
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
): Promise<API.Result<API.WorkerWorkstation[]>> {
  const isWorkerSkilledArray: API.WorkerWorkstation[] = [];
  _.forEach(workstationIds, workstationId => {
    _.forEach(workerIds, workerId => {
      const workerWorkstation = getWorkerWorkstations(workstationId, workerId);
      if (
        workerWorkstation &&
        isWorkerSkilled(workerWorkstation, workstationTargetsStartingAtLevel)
      )
        isWorkerSkilledArray.push(workerWorkstation);
    });
  });

  return isWorkerSkilledArray;
}

/**
 * Get the Workers that are Skilled or Planned to be Skilled @see isWorkerSkilledOrPlannedToBeSkilled()
 * @param workstationTargetsStartingAtLevel WorkstationWorkerLevel
 * @param workstationIds
 * @param workerIds (optional) if not specified, all the company Workers (with permission workerIsOperational) are considered.
 */
export async function getWorkersSkilledOrPlannedToBeSkilled(
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  workstationIds: string[],
  workerIds?: string[],
): Promise<API.Result<API.WorkerWorkstation[]>> {
  if (!workerIds) {
    const workers = await API.getWorkers([API.Permission.workerIsOperational]);
    if (API.isFailure(workers)) return workers;

    workerIds = _.map(workers.result, worker => worker.id);
  }

  const isWorkerSkilledOrPlannedToBeSkilledArray: API.WorkerWorkstation[] = [];
  _.forEach(workstationIds, workstationId => {
    _.forEach(workerIds, workerId => {
      const workerWorkstation = getWorkerWorkstations(workstationId, workerId);
      if (
        workerWorkstation &&
        isWorkerSkilledOrPlannedToBeSkilled(workerWorkstation, workstationTargetsStartingAtLevel)
      )
        isWorkerSkilledOrPlannedToBeSkilledArray.push(workerWorkstation);
    });
  });

  return isWorkerSkilledOrPlannedToBeSkilledArray;
}

/** Tells whether a Worker is skilled (or train to be skilled) on a Workstation */
export function isWorkerSkilledOrPlannedToBeSkilled(
  workerWorkstation: API.WorkerWorkstation,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
): boolean {
  if (isWorkerSkilled(workerWorkstation, workstationTargetsStartingAtLevel)) {
    return true;
  } else if (
    API.isWorkerInTrainingOnWorkstation(workerWorkstation) ||
    API.isWorkerInTrainAutoOnWorkstation(workerWorkstation)
  ) {
    return true;
  } else {
    return false;
  }
}

/** Tells whether a Worker is skilled for a specific workstation*/
export function isWorkerSkilled(
  workerWorkstation: API.WorkerWorkstation,
  workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
): boolean {
  if (
    workerWorkstation.level &&
    API.api2workstationWorkerLevels(workerWorkstation.level) >= workstationTargetsStartingAtLevel
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * Compute the Worker's Managers.
 * If no manager can be found on the Worker's organizationalUnits,
 * it will look for parent's OrganizationalUnits' managers.
 * @param worker
 */
export async function getManagersForWorker(worker: API.Worker): Promise<API.Result<API.Worker[]>> {
  const orgUnitRoles = await API.getWorkerAssignments(worker.id, false, true);
  if (API.isFailure(orgUnitRoles)) return orgUnitRoles;

  const orgUnitsPathIds: string[][] = [];
  if (orgUnitRoles.length) {
    _.forEach(orgUnitRoles, orgUnitRole => {
      orgUnitsPathIds.push([...orgUnitRole.organizationalUnit.pathIds]);
    });
  }

  do {
    let organizationalUnitIds: string[] = [];
    orgUnitsPathIds.forEach((pathIds, index) => {
      const orgUnitId = pathIds.pop();
      if (!orgUnitId) {
        orgUnitsPathIds.splice(index, 1);
      } else {
        organizationalUnitIds = _.union(organizationalUnitIds, orgUnitId);
      }
    });

    const managers = await getManagersOfOrganizationalUnit(organizationalUnitIds);
    if (API.isFailure(managers)) return managers;

    if (managers.length) return managers;
    
  } while (orgUnitsPathIds.length);

  return [];
} 

/**
 * Compute the Managers of the specified OrganizationalUnits (relying on if the ManagerRole was set on the Managers)
 * The definition of a "Manager" is not strict and could vary through time.
 * @param organizationalUnitIds
 */ export async function getManagersOfOrganizationalUnit(
  organizationalUnitIds: string[],
): Promise<API.Result<API.Worker[]>> {
  const workersAssignmentsMap = await API.getWorkersAssignments(
    true,
    false,
    undefined,
    organizationalUnitIds,
  );
  if (API.isFailure(workersAssignmentsMap)) return workersAssignmentsMap;

  const managers = new Set<API.Worker>();
  for (const [workerId, workerAssignments] of workersAssignmentsMap) {
    if (
      workerAssignments.some(
        workerAssignment => workerAssignment.role.id === API.workerManagerRoleId,
      )
    ) {
      const worker = await API.getWorker(workerId);
      if (API.isFailure(worker)) return worker;

      managers.add(worker);
    }
  }

  return Array.from(managers);
}

export async function getWorkerTags(): Promise<API.Result<API.WorkerTag[]>> {
  const factories = await API.listFactoriesWithDataType(API.DataType.WORKERTAG);
  if (API.isFailure(factories)) return factories;

  return factories.result.map(factory => {
    return { ...factory.workerTag!, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
  });
}

export async function getWorkerTag(workerTagId: string): Promise<API.Result<API.WorkerTag>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.WORKERTAG, workerTagId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.workerTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export function sortWorkerCompare(
  a: API.Worker,
  b: API.Worker,
  sortDirection: SortDirection,
  workerLastNameFirst?: boolean,
): number {
  let compare: number = 0;
  const aSortFamilyName = replaceDiacriticsAndCapitalLetter(a.familyName.toLowerCase());
  const bSortFamilyName = replaceDiacriticsAndCapitalLetter(b.familyName.toLowerCase());
  const aSortFirstName = replaceDiacriticsAndCapitalLetter(a.firstName.toLowerCase());
  const bSortFirstName = replaceDiacriticsAndCapitalLetter(b.firstName.toLowerCase());

  if (workerLastNameFirst) {
    if (aSortFamilyName < bSortFamilyName) {
      compare = -1;
    } else if (aSortFamilyName > bSortFamilyName) {
      compare = 1;
    } else {
      if (aSortFirstName < bSortFirstName) {
        compare = -1;
      } else if (aSortFirstName > bSortFirstName) {
        compare = 1;
      }
    }
  } else {
    if (aSortFirstName < bSortFirstName) {
      compare = -1;
    } else if (aSortFirstName > bSortFirstName) {
      compare = 1;
    } else {
      if (aSortFamilyName < bSortFamilyName) {
        compare = -1;
      } else if (aSortFamilyName > bSortFamilyName) {
        compare = 1;
      }
    }
  }
  return sortDirection === SortDirection.asc ? compare : -compare;
}

/**
 * Sort the list of workers in family name order and if they are same we will go for first name
 * @param workers worker list
 * @param sortDirection asc or desc
 * @param workerLastNameFirst
 */
export function sortWorkers(
  workers: API.Worker[],
  sortDirection: SortDirection = SortDirection.asc,
  workerLastNameFirst?: boolean,
): API.Worker[] {
  return workers.sort((a, b) => sortWorkerCompare(a, b, sortDirection, workerLastNameFirst));
}

export function getInvitationErrorMessage(
  operation: API.UserInvitationOperations,
): string | undefined {
  if (operation === API.UserInvitationOperations.GIVE_ACCESS)
    return capitalizeFirstLetter(i18n.t('alex:inviteWorker.errorMessage.0'));
  else if (operation === API.UserInvitationOperations.REMOVE_ACCESS)
    return capitalizeFirstLetter(i18n.t('alex:inviteWorker.removeAccessErrorMessage'));
}

export function getInvitationToastMessage(operation: API.UserInvitationOperations): string {
  if (operation === API.UserInvitationOperations.REMOVE_ACCESS)
    return capitalizeFirstLetter(
      i18n.t('alex:inviteWorker.toastMessages.workerAccessHasBeenRemoved'),
    );
  return capitalizeFirstLetter(i18n.t('alex:inviteWorker.toastMessages.workerHasBeenInvited'));
}

export async function getWorkerWorkstationDetail(
  workerId: string,
  requirementId?: string | null,
): Promise<API.Result<WorkerWorkstationDetails>> {
  const worker = await API.getWorker(workerId);
  if (API.isFailure(worker)) return worker;

  let workerWorkstation = undefined;
  if (requirementId) {
    const requirement = await API.getRequirement(requirementId);
    if (API.isFailure(requirement)) return requirement;

    workerWorkstation = getWorkerWorkstations(requirement.linkedObjectId, workerId);
  }

  return { worker, workerWorkstation };
}

export async function getWorkersWorkstationDetails(
  workerIds: readonly string[],
  requirementId?: string | null,
): Promise<API.Result<WorkerWorkstationDetails[]>> {
  const workersWorkstationDetails: WorkerWorkstationDetails[] = [];
  if (requirementId) {
    const requirement = await API.getRequirement(requirementId);
    if (API.isFailure(requirement)) {
      logger.warn(requirement);
      return requirement;
    }

    await Promise.all(
      _.map(workerIds, async workerId => {
        const worker = await API.getWorker(workerId);
        if (API.isFailure(worker)) return worker;

        const workerWorkstation = getWorkerWorkstations(requirement.linkedObjectId, workerId);
        workersWorkstationDetails.push({ worker, workerWorkstation });
      }),
    );
  } else {
    await Promise.all(
      _.map(workerIds, async workerId => {
        const worker = await API.getWorker(workerId);
        if (API.isFailure(worker)) return worker;

        workersWorkstationDetails.push({ worker });
      }),
    );
  }

  return workersWorkstationDetails;
}

/**
 * Function to get the workers who got all the skills in the training
 * @param trainingId
 * @returns
 */
export async function getWorkersWhoHaveSkillsOfTraining(
  trainingId: string,
): Promise<API.Result<API.Worker[]>> {
  const workers = await API.getWorkers();
  if (API.isFailure(workers)) return workers;

  const trainingVersion = await API.getTrainingVersionLatestForTraining(trainingId);
  if (API.isFailure(trainingVersion)) return trainingVersion;

  const skillIds = trainingVersion.skillIds;
  const workersWhoHasSkills: API.Worker[] = [];

  const workerSkills = await API.getWorkerSkills();
  if (API.isFailure(workerSkills)) return workerSkills;

  workers.result.forEach(worker => {
    const foundAllSkills = skillIds.every(
      skillId =>
        !!workerSkills.result.find(
          workerSkill =>
            workerSkill.workerId === worker.id &&
            skillId === workerSkill.skillId &&
            workerSkill.activeProofBundle?.originObjectId &&
            API.getDataType(workerSkill.activeProofBundle.originObjectId) !==
              API.DataType.TRAININGSESSION,
        ),
    );

    if (foundAllSkills) {
      workersWhoHasSkills.push(worker);
    }
  });

  return workersWhoHasSkills;
}

/**
 * it gives a list of admins(workers who have editing permissions) for the signed in user
 */
export async function getWorkersAdmins(): Promise<API.Worker[] | undefined> {
  const workers = await API.getWorkers();
  if (API.isFailure(workers)) {
    logger.warn(workers);
    return;
  }

  const topOrgUnits = API.Tree.getTopTreeNodes();

  const admins: API.Worker[] = [];
  await Promise.all(
    await Promise.all(
      workers.result.map(async _worker => {
        await Promise.all(
          topOrgUnits.map(async orgUnit => {
            const _isWorkerAdmin = await isWorkerAdmin(orgUnit.object, _worker);
            if (API.isFailure(isWorkerAdmin)) {
              logger.warn(isWorkerAdmin);
              return;
            }
            if (_isWorkerAdmin && !admins.includes(_worker)) admins.push(_worker);
          }),
        );
      }),
    ),
  );

  return admins;
}

export function computeWorkerBadgeState(
  workerId: string,
): API.Result<SkillBadgeStates | undefined> {
  const workerWorkstations = getWorkerWorkstations(undefined, workerId);

  for (const workerWorkstation of workerWorkstations) {
    if (workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRED)
      return SkillBadgeStates.EXPIRED;
    if (workerWorkstation.warning === API.WorkstationWorkerLevelTargetWarning.EXPIRE_SOON)
      return SkillBadgeStates.EXPIRE_SOON;
  }

  return SkillBadgeStates.OK;
}

/**
 * returns the workers average experience
 * @param workers
 */
export function getWorkersAverageExperience(workers: API.Worker[]): number {
  let startDate: moment.Moment;
  let endDate: moment.Moment;
  let years = 0;

  workers.forEach(worker => {
    worker.contracts.forEach(contract => {
      if (moment(contract.startDate).valueOf() < moment().valueOf()) {
        startDate = moment(contract.startDate);

        if (moment(contract.endDate).valueOf() < moment().valueOf()) {
          endDate = moment(contract.endDate);
        } else {
          endDate = moment();
        }

        years += endDate.diff(startDate, 'years');
      }
    });
  });

  let averageExperience = 0;
  if (workers.length !== 0) averageExperience = years / workers.length;

  return Math.floor(averageExperience);
}

/**
 * gives back the contract type names with either the count or the percentage and if we have two different contract types with the same name they will be merged together
 * @param workers
 * @param contractTypes
 * @param getPercentage
 */
export async function getWorkersContractTypeNamewithCounts(
  workers: API.Worker[],
): Promise<ContractTypeNameWithCount[]> {
  const contractTypeNameWithCounts: ContractTypeNameWithCount[] = [];

  let allContractTypesCount = 0;

  await Aigle.map(workers, async worker => {
    const workerContract = await API.getWorkerLastContract(worker);
    if (API.isFailure(workerContract)) {
      logger.warn(workerContract);
      return;
    }

    const isContractEnded = workerContract?.endDate
      ? new Date(workerContract?.endDate) < new Date()
      : false;
    const contractTypeName: string = workerContract
      ? isContractEnded
        ? t('common:worker.contractEnded')
        : workerContract.name
      : t('common:worker.noContract');

    allContractTypesCount += 1;
    const index = contractTypeNameWithCounts.findIndex(object => {
      return object.contractTypeName === contractTypeName;
    });
    if (index > -1) {
      contractTypeNameWithCounts[index].workerCount += 1;
    } else {
      contractTypeNameWithCounts.push({
        contractTypeName,
        workerCount: 1,
        workerCountPercentage: 0,
      });
    }
  });

  
  if (allContractTypesCount !== 0)
    contractTypeNameWithCounts.forEach((contractTypeNameWithCount, index) => {
      const contractTypePersentage = Math.floor(
        (contractTypeNameWithCount.workerCount / allContractTypesCount) * 100,
      );

      contractTypeNameWithCounts[index].workerCountPercentage = contractTypePersentage;
    });

  return contractTypeNameWithCounts;
}

/**
 * gives the list of workers that are assigned to a shift
 * if we pass the shiftParentId it means we need the workers that are assigned to this shift in this specific unit
 * @param shiftId
 * @param shiftParentID
 * @param workerAssignmentsWithDetails
 */
export async function getWorkersInShift(
  shiftId: string,
  shiftParentId?: string,
  workerAssignmentsWithDetails?: API.AssignmentWithUnitDetails[],
  includeWorkersFromUpperAssignments: boolean = false,
  onlyWorkersDirectlyAssignedToShift: boolean = false,
  permissions?: API.Permission[],
): Promise<API.Result<API.Worker[]>> {
  let _workerAssignments: API.AssignmentWithUnitDetails[] = [];
  let workersOnShift: API.Worker[] = [];

  if (workerAssignmentsWithDetails) _workerAssignments = workerAssignmentsWithDetails;
  else {
    if (shiftParentId) {
      const __workerAssignments = await API.getOrgUnitWorkersAssignments(
        shiftParentId,
        includeWorkersFromUpperAssignments,
        permissions,
      );
      if (API.isFailure(__workerAssignments)) return __workerAssignments;

      _workerAssignments = __workerAssignments;
    } else {
      const __workerAssignments = await API.getRolesOnShift(shiftId);
      if (API.isFailure(__workerAssignments)) return __workerAssignments;

      _workerAssignments = __workerAssignments;
    }
  }

  await Aigle.map(_workerAssignments, async workerAssignments => {
    const worker = await API.getWorker(workerAssignments.workerId);
    if (API.isFailure(worker)) {
      logger.error('worker', worker);
      return worker;
    }

    if (onlyWorkersDirectlyAssignedToShift) {
      if (workerAssignments.shift?.id === shiftId) workersOnShift.push(worker);
    } else {
      if (
        workerAssignments.shift?.id === shiftId ||
        (shiftParentId &&
          workerAssignments.organizationalUnit.id === shiftParentId &&
          !workerAssignments.shift)
      )
        workersOnShift.push(worker);
    }
  });

  return workersOnShift;
}

/**
 * it gives you a ghost worker to put instead of a worker that you are not authorized to see
 */
export function getGhostWorker(): API.NoMetadata<API.Worker> {
  return {
    id: uuidv4(),
    name: t('common:worker.ghostWorkerName'),
    firstName: t('common:worker.invisible'),
    familyName: t('glossary:worker'),
    isAdmin: false,
    contracts: [],
    tagIds: [],
    scope: ghostScope,
    state: API.WorkerState.ACTIVE,
    __typename: 'Worker',
  };
}

const ghostScope = 'ghost';

/**
 * it tell's you if the worker is real or a ghost
 */
export function isGhostWorker(workerName: string): boolean {
  if (workerName === t('common:worker.ghostWorkerName')) return true;

  return false;
}

/**
 * it gives you all the non assignments on a specific shift across all units
 */
export async function getRolesOnShift(
  shiftId: string,
): Promise<API.Result<API.AssignmentWithUnitDetails[]>> {
  const assignmentsOnShift: API.AssignmentWithUnitDetails[] = [];

  const assignments = await API.getWorkersAssignments(true, false);
  if (API.isFailure(assignments)) return assignments;

  Object.values(assignments).forEach(assignment => {
    if (assignment && assignment.shift === shiftId && !assignment)
      assignmentsOnShift.push(assignment);
  });

  return assignmentsOnShift;
}

export function getSecondPartOfWorkerName(name: string): string {
  return `${_.join(_.split(name, ' ').slice(1), ' ')}`;
}

export async function shortenLongWorkerNames(name: string): Promise<API.Result<string>> {
  const tenant = await API.getTenant();
  if (API.isFailure(tenant)) {
    logger.warn(tenant);
  } else {
    if (tenant.workerFamilyNameFirst) {
      return name;
    }
  }
  const splitedName = _.split(name, ' ');
  const lastName = _.join(splitedName.slice(1), ' ');

  return `${splitedName[0].charAt(0)}. ${lastName}`;
}

/**
 * if check if the provided phone number is valide
 * @param phoneNumberString
 * @returns
 */
export function isValidPhoneNumber(
  phoneNumberString?: string | null,
  countryCode?: string,
): boolean {
  if (!phoneNumberString || phoneNumberString === countryCode) return true;

  const phoneUtil = PhoneNumberUtil.PhoneNumberUtil.getInstance();
  const parsedPhoneNumber = phoneUtil.parse(phoneNumberString, countryCode);
  return phoneUtil.isValidNumber(parsedPhoneNumber);
}
