import React, { useState, useEffect } from 'react';
import { Text, TouchableOpacity, InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import Styles from './Styles';
import * as _ from 'lodash-es';
import { FileExpand } from './FileExpand';
import { ViewStyle, View } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../components/navigation/Routes';
import { HoverableIcon } from 'shared/ui-component/HoverableIcon';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors, Spacings } from 'shared/styles/index';
import { useIsMounted } from 'shared/hooks/IsMounted';

interface SkillCardProps {
  skillOrSkillId: API.Skill | string;
  containerStyle?: ViewStyle;
  deletable?: boolean;
  onDelete?: () => void;
}

const SkillIcon = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const TrashIcon = require('shared/assets/svg/icon.trash.svg').default;

export const SkillCard: React.FC<SkillCardProps> = props => {
  const { skillOrSkillId, containerStyle, deletable, onDelete } = props;

  const [isHover, setIsHover] = useState(false);
  const [skill, setSkill] = useState<API.Skill>();

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (typeof skillOrSkillId === 'string') {
        const _skill = await API.getSkill(skillOrSkillId);
        if (!isMounted.current) return;
        if (API.isFailure(_skill)) return _skill;
        setSkill(_skill);
      } else {
        setSkill(skillOrSkillId);
      }
    });
  }, [skillOrSkillId]);

  const history = useHistory();

  const ref = useCallOnHover<TouchableOpacity>(
    Colors.Transparent,
    () => setIsHover(true),
    () => setIsHover(false),
  );

  return (
    <>
      {skill ? (
        <TouchableOpacity
          ref={ref}
          style={[Styles.skillRowContainer, containerStyle ?? {}]}
          onPress={() => {
            history.push(RouteLocations.SkillProfile(skill.id));
          }}
        >
          {deletable && isHover && onDelete && (
            <HoverableIcon
              svgIcon={TrashIcon}
              containerStyle={[
                Styles.hoverableIconContainerStyle,
                {
                  top: !!skill.files.length ? Spacings.Unit * 7 : Spacings.Unit * 4,
                },
              ]}
              onPress={onDelete}
            />
          )}
          <View style={Styles.rowContainer}>
            <IconSVG
              svgComponent={SkillIcon}
              size={{ width: Spacings.Standard, height: Spacings.Standard }}
              color={Colors.White}
              containerStyle={{
                width: 32,
                height: 32,
                backgroundColor: Colors.DimGray,
                borderRadius: 20,
              }}
            />
            <Text style={[Styles.skillNameTextStyle]} numberOfLines={1}>
              {capitalizeFirstLetter(skill.name)}
            </Text>
          </View>
          {!!skill.files.length && (
            <>
              <FileExpand files={skill.files} />
              <View style={Styles.divider} />
            </>
          )}
        </TouchableOpacity>
      ) : (
        <></>
      )}
    </>
  );
};
