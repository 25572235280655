import { StyleSheet } from 'react-native';
import { Spacings, fontSize } from 'shared/styles';
import * as SharedStyles from 'shared/styles';

const styles = StyleSheet.create({
  skillRowContainer: {
    marginBottom: SharedStyles.Spacings.Small,
    width: 280,
    paddingRight: SharedStyles.Spacings.Unit * 3,
    paddingLeft: SharedStyles.Spacings.xMedium,
    paddingTop: Spacings.Small,
    minHeight: 24,
    backgroundColor: SharedStyles.Colors.GreyUltraLight,
    borderRadius: 8,
    shadowColor: '#00000021',
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
  },
  trainingIconContainer: {
    height: 24,
    width: 24,
  },
  trainingTypeTextStyle: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoNormal,
    marginBottom: Spacings.Small + 1,
  },
  fileCountTextStyle: {
    color: SharedStyles.Colors.Grey,
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoRegular,
    paddingBottom: Spacings.Unit / 2,
    paddingLeft: Spacings.Small,
  },
  skillNameTextStyle: {
    fontSize: fontSize.Regular,
    ...SharedStyles.TextFontStyles.LatoRegular,
    paddingLeft: Spacings.Small,
    marginTop: -5,
  },
  touchableContainer: { flexDirection: 'row', justifyContent: 'space-between', width: '100%' },
  fileExpandContainer: { width: '100%' },
  rowView: { flexDirection: 'row', alignItems: 'center' },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacings.Small,
  },
  divider: { height: Spacings.xMedium },
  hoverableIconContainerStyle: { position: 'absolute', right: -Spacings.Unit * 6 },
});

export default styles;
