import React, { useState, useEffect } from 'react';
import * as API from 'shared/backend-data';
import { View, Text, ViewStyle, TextStyle, Platform, InteractionManager } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles/index';
import moment from 'moment';
import { styles } from './Styles';
import { t } from 'shared/localisation/i18n';
import CalendarIcon from 'shared/assets/svg/icon.calendar.mobile.svg';
import InfoIcon from 'shared/assets/svg/icon.info.svg';
import { ToolTipWeb } from '../ToolTip/ToolTipWeb';

interface Props {
  startingDate: Date | undefined;
  skill: API.Skill | undefined;
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
  rejectedProof?: boolean;
}

export const ValidityDisplay: React.FC<Props> = props => {
  const { startingDate, skill, containerStyle, textStyle, rejectedProof } = props;
  const [color, setColor] = useState(Colors.Black);
  const [date, setDate] = useState<moment.Moment | null | undefined>();
  const [state, setState] = useState<string>();
  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      handleValidityDisplay();
    });
  }, [skill, startingDate]);

  async function handleValidityDisplay() {
    if (startingDate && skill) {
      if (rejectedProof) {
        const workerSkillRejectedDate = moment(startingDate);
        setDate(workerSkillRejectedDate);
        setState(
          t('alex:workerSkill.rejectedSince', {
            duration: workerSkillRejectedDate.fromNow(true),
          }),
        );
        setColor(Colors.Red);
      } else {
        const { validityDuration, expiryNoticeDuration } =
          await API.getSkillValidityDurationAndExpiryNoticeDuration(skill);

        if (!validityDuration) {
          setDate(null);
          setState(t('alex:workerSkill.neverExpire'));
          setColor(Colors.Black);
        } else {
          const workerSkillExpiryDate = moment(startingDate).add(validityDuration, 'months');
          setDate(workerSkillExpiryDate);

          const days = workerSkillExpiryDate.diff(moment(new Date()).utc(), 'days');

          
          const months = workerSkillExpiryDate.diff(moment.utc(), 'months', true);
          const monthsRounded = Math.round(months);

          const duration =
            months < 1
              ? `${days} ${t('common:time.day', { count: days }, false)}`
              : `${monthsRounded} ${t('common:time.month', { count: monthsRounded }, false)}`;

          if (days <= 0) {
            setState(
              t('alex:workerSkill.expiredSince', {
                duration: workerSkillExpiryDate.fromNow(true),
              }),
            );
            setColor(Colors.Red);
          } else if (!expiryNoticeDuration || days > expiryNoticeDuration) {
            setState(
              t('alex:workerSkill.validUntil', {
                duration,
              }),
            );

            setColor(Colors.Black);
          } else {
            setState(
              t('alex:workerSkill.expiresIn', {
                duration,
              }),
            );
            setColor(Colors.Orange);
          }
        }
      }
    } else if (!startingDate) {
      setDate(undefined);
    }
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}
      >
        <View style={styles.validUntilText}>
          <Text
            style={[
              styles.validUntilTextStyle,
              {
                color: color === Colors.Black ? Colors.Grey : color,
              },
              isWeb ? { fontSize: 12 } : { fontSize: 14 },
            ]}
          >
            {date ? `${state} ` : ''}
          </Text>
        </View>
      </View>

      <View style={styles.validityContainer}>
        {date && (
          <IconSVG
            svgComponent={CalendarIcon}
            color={color}
            containerStyle={styles.calendarStyle}
          />
        )}
        <Text
          style={[
            styles.validityTextStyle,
            textStyle,
            { color: color },
            isWeb ? { fontSize: 14 } : { fontSize: 16 },
          ]}
        >
          {date ? date.format('L').toString() : date === null ? state : ''}
        </Text>
        {date && (
          <ToolTipWeb
            title={t('alex:mobile.addProofPage.validityTooltip')}
            style={styles.tooltipContainer}
            component={
              <IconSVG
                svgComponent={InfoIcon}
                containerStyle={styles.infoIcon}
                color={color === Colors.Black ? Colors.Grey : color}
              />
            }
          />
        )}
      </View>
    </View>
  );
};
