import React from 'react';
import { View, Text } from 'react-native';
import Styles from './style';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';

const tutoIcon = require('shared/assets/svg/tuto.workstScreen.svg').default;

export const CreateFactoryScreenComponent: React.FC = () => {

  return (
    <View style={Styles.createFactoryContainer}>
      <IconSVG
        svgComponent={tutoIcon}
        size={{ height: 237, width: 242 }}
        containerStyle={Styles.tutoIconContainer}
      />
      <Text style={Styles.createFactoryTextStyle}>
        {' '}
        {t('alex:createFactoryWelcomeScreen.createFactory')}
      </Text>
      <Text style={Styles.createFactoryDescriptionTextStyle}>
        {t('alex:createFactoryWelcomeScreen.createStructure')}{' '}
        <Text style={Styles.createFactoryDescriptionBlackTextStyle}>
          {t('alex:createFactoryWelcomeScreen.assignWorkers')}
        </Text>{' '}
        {t('alex:createFactoryWelcomeScreen.onOrgUnits')}
      </Text>
      <Text style={Styles.createFactoryDescriptionTextStyle}>
        <Text style={Styles.createFactoryDescriptionBlackTextStyle}>
          {t('alex:createFactoryWelcomeScreen.inviteTeamLeaders')}
        </Text>{' '}
        {t('alex:createFactoryWelcomeScreen.downloadAlex')}
      </Text>
    </View>
  );
};
