import React from 'react';
import { Platform, Linking } from 'react-native';
import { IconButton } from 'shared/ui-component/Button';
import { IconButtonProps } from './IconButton';
import CallIcon from 'shared/assets/svg/icon.phone.mobile.svg';
import logger from 'shared/util/Logger';
import { Colors } from 'shared/styles';

interface CallButtonProps extends IconButtonProps {
  phoneNumber: string;
  prompt?: boolean;
}

const openLink = async (url: string): Promise<any> => {
  const canOpen = await Linking.canOpenURL(url);
  if (!canOpen) {
    return Promise.reject(new Error(`invalid URL provided: ${url}`));
  } else {
    return Linking.openURL(url).catch(err => Promise.reject(err));
  }
};

const call = (phoneNumber: string, prompt = true) => {
  let url;
  if (Platform.OS === 'ios') {
    url = prompt ? `telprompt:${phoneNumber}` : `tel:${phoneNumber}`;
  } else if (Platform.OS === 'android') {
    url = prompt ? `telprompt:${phoneNumber}` : `tel:${phoneNumber}`;
  } else if (Platform.OS === 'web') {
    url = prompt ? `tel:${phoneNumber}` : `tel:${phoneNumber}`;
  } else {
    logger.warn(`Platform not supported ${Platform.OS}`);
    return;
  }
  openLink(url).catch(err => {
    logger.warn(err);
  });
};

export const CallButton: React.FC<CallButtonProps> = props => {
  return (
    <IconButton
      onPress={() => call(props.phoneNumber, props.prompt)}
      icon={CallIcon}
      color={props.color}
      size={props.size}
      style={props.style}
      iconSize={props.iconSize}
    />
  );
};
