import { View, Text, ActivityIndicator } from 'react-native';
import styles from './styles';
import * as API from 'shared/backend-data';
import React, { useContext } from 'react';
import { t } from 'shared/localisation/i18n';
import { Colors, Spacings } from 'shared/styles';
import { convertDate_ddMMYYYY } from 'shared/util-ts/DateUtils';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import { DashboardContext } from 'shared/context/dashboard-context/DashboardContextProvider';
import { ExportReport, getExportDataForSkillsToRenewOrAcquire } from 'shared/util/ExcelUtils';
import { ImportExportComponent } from 'shared/ui-component/import-export-menu';
import { ImportExportType } from 'shared/util/ExcelUtils';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { DropdownConfigKey } from '../../../header-layout/headerFilterConfig';
import logger from 'shared/util/Logger';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { IconSVG } from 'shared/ui-component/Icon';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';
import { DashboardFilterContext } from 'shared/context/dashboard-context/DashboardFilterContext';
import { Workbook } from 'exceljs';

const InfoSVG = require('shared/assets/svg/icon.info.svg').default;

interface Props {
  workersSkillsStatus: API.SkillsToRenewOrAcquire | undefined;
  loadingData: boolean;
  validatePermissionOnSubUnits?: boolean;
  isUserAuthorizedToViewWorkersDetails: boolean;

  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TrainingInfoPanelRow: React.FC<Props> = props => {
  const {
    workersSkillsStatus,
    loadingData,
    isUserAuthorizedToViewWorkersDetails,
    validatePermissionOnSubUnits,

    setLoadingData,
  } = props;

  const isMounted = useIsMounted();

  const modal = useModal();

  const {
    workers: [workers],
    workstations: [workstations],
  } = useContext(DashboardFilterContext);
  const accountHasNoWorkstations = useContext(DashboardContext);
  const { isValidPermission } = useContext(PermissionManagementContext);
  const {
    homeScreenFilterTags: [homeScreenFilterTags],
  } = useContext(HeaderFilterContext);

  async function getSkillToRenewExportData(): Promise<ExportReport> {
    if (!workersSkillsStatus) return API.createFailure_Unspecified(t('common.error.unknown'));

    return getExportDataForSkillsToRenewOrAcquire(workers, workstations, false);
  }

  async function getSkillsToAcquireExportData(): Promise<ExportReport> {
    if (!workersSkillsStatus) return API.createFailure_Unspecified(t('common.error.unknown'));

    return getExportDataForSkillsToRenewOrAcquire(workers, workstations, true);
  }

  function isUserAllowedToExport(): boolean {
    let filteredOrgUnits: API.OrganizationalUnit[] = _.compact(
      homeScreenFilterTags.map(eachTag => {
        if (eachTag.type === DropdownConfigKey.ORGUNIT) {
          const orgUnit = API.getOrganizationalUnit(eachTag.key);
          if (API.isFailure(orgUnit)) {
            logger.warn('OrgUnit id ' + eachTag.key + ' not found: ' + orgUnit.message, orgUnit);
          } else {
            return orgUnit;
          }
        }
      }),
    );

    if (!filteredOrgUnits.length) {
      
      const treeNodes = API.Tree.getFlatAndOrderedTree(undefined, true);
      treeNodes.forEach(treeNode => filteredOrgUnits.push(treeNode.object));
    }

    for (const orgUnit of filteredOrgUnits) {
      if (!isValidPermission(API.Permission.workersDetail_view, orgUnit)) return false;

      if (validatePermissionOnSubUnits) {
        const childrenOrgUnits = API.Tree.getChildren(orgUnit.id, true, API.DataType.ORGUNIT);
        if (API.isFailure(childrenOrgUnits)) {
          logger.warn(childrenOrgUnits);
          return false;
        }

        if (
          _.some(
            childrenOrgUnits,
            childUnit => !isValidPermission(API.Permission.workersDetail_view, childUnit),
          )
        )
          return false;
      }
    }

    return true;
  }

  function renderUnauthorizedToolTip(): JSX.Element {
    return (
      <ToolTipWeb
        style={styles.tooltipContainer}
        component={
          <IconSVG
            svgComponent={InfoSVG}
            size={{ width: Spacings.Standard, height: Spacings.Standard }}
            color={Colors.GreyLight}
          />
        }
        title={t('alex:trainingInfoPanel.unauthorizedToolTipMessage')}
      />
    );
  }

  const skillsToRenew: number | undefined = accountHasNoWorkstations
    ? 0
    : workersSkillsStatus
    ? workersSkillsStatus.skillsToRenew.length
    : undefined;

  const skillsToAcquire: number | undefined = accountHasNoWorkstations
    ? 0
    : workersSkillsStatus
    ? workersSkillsStatus.skillsToAcquire.length
    : undefined;

  const _isUserAllowedToExport = isUserAuthorizedToViewWorkersDetails && isUserAllowedToExport();

  return (
    <View style={styles.rootContainer}>
      {workersSkillsStatus && (
        <View>
          {loadingData ? (
            <ActivityIndicator color={Colors.Yellow} size="large" style={styles.loader} />
          ) : workersSkillsStatus ? (
            <Text
              style={[
                styles.rowTotalNumberStyle,
                {
                  color: Colors.Black,
                },
              ]}
            >
              {skillsToRenew}
            </Text>
          ) : null}
          <View style={styles.separator} />
          {loadingData ? (
            <ActivityIndicator color={Colors.Yellow} size="large" style={styles.loader} />
          ) : workersSkillsStatus ? (
            <Text
              style={[
                styles.rowTotalNumberStyle,
                {
                  color: Colors.Black,
                },
              ]}
            >
              {skillsToAcquire}
            </Text>
          ) : null}
        </View>
      )}

      <View style={styles.panelContainer}>
        <View style={styles.refreshPanel}>
          <View style={styles.innerContainer}>
            <View style={{ width: '100%' }}>
              <Text style={_isUserAllowedToExport ? styles.header : styles.headerDisabled}>
                {t('alex:trainingInfoPanel.refreshPanel.header')}
              </Text>
              <Text style={styles.footer} numberOfLines={2} ellipsizeMode="tail">
                {t('alex:trainingInfoPanel.refreshPanel.footer')}
              </Text>
            </View>

            <View>
              {_isUserAllowedToExport && !loadingData ? (
                <ImportExportComponent
                  importExportMenuType={ImportExportType.SkillsToRenew}
                  exportOnly={true}
                  getExportData={getSkillToRenewExportData}
                  fileName={t('alex:trainingInfoPanel.refreshPanel.fileName', {
                    date: convertDate_ddMMYYYY(new Date()),
                  })}
                />
              ) : (
                renderUnauthorizedToolTip()
              )}
            </View>
          </View>
        </View>

        <View style={styles.separator} />

        <View style={styles.trainingPanel}>
          <View style={styles.innerContainer}>
            <View style={{ width: '100%' }}>
              <Text style={_isUserAllowedToExport ? styles.header : styles.headerDisabled}>
                {t('alex:trainingInfoPanel.trainingPanel.header')}
              </Text>
              <Text style={styles.footer} numberOfLines={2} ellipsizeMode="tail">
                {t('alex:trainingInfoPanel.trainingPanel.footer')}
              </Text>
            </View>

            <View>
              {_isUserAllowedToExport && !loadingData ? (
                <ImportExportComponent
                  importExportMenuType={ImportExportType.SkillsToAcquire}
                  exportOnly={true}
                  getExportData={getSkillsToAcquireExportData}
                  fileName={t('alex:trainingInfoPanel.trainingPanel.fileName', {
                    date: convertDate_ddMMYYYY(new Date()),
                  })}
                />
              ) : (
                renderUnauthorizedToolTip()
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
