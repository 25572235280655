import React, { useState } from 'react';
import { TagExtended } from '../container';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from '../styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import { useCallOnHover } from 'shared/hooks/CallOnHover';

import { Spacings, Colors } from 'shared/styles';

interface Props {
  tags: TagExtended[];
  onBookmarkTagDelete: (tags: TagExtended[]) => void;
  onBookmarkTagSelect: (tags: TagExtended[]) => void;
}

interface BookmarkList {
  tag: TagExtended;
}

const BookmarkIcon = require('shared/assets/svg/icon.star.svg').default;
const TrashIcon = require('shared/assets/svg/icon.trash.svg').default;

export const Bookmark: React.FC<Props> = props => {
  const { tags, onBookmarkTagDelete, onBookmarkTagSelect } = props;

  const BookmarkList: React.FC<BookmarkList> = props => {
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const ref = useCallOnHover<TouchableOpacity>(
      undefined,
      () => setShowDelete(true),
      () => setShowDelete(false),
    );
    const { tag } = props;

    function onRemoveTag(tag: TagExtended) {
      const index = tags.findIndex(eachTag => eachTag.key === tag.key);
      if (index > -1) {
        tags.splice(index, 1);
        onBookmarkTagDelete(tags);
      }
    }

    function onBookmarkItemClick() {
      const index = tags.findIndex(eachTag => eachTag.key === tag.key);

      if (index > -1) {
        tags[index].isActiveBookmarkTag = true;
        onBookmarkTagSelect(tags);
      }
    }

    return (
      <TouchableOpacity
        style={[
          styles.bookmarkNotEmptyContainer,
          showDelete && { backgroundColor: Colors.BlueRollover },
        ]}
        ref={ref}
        onPress={onBookmarkItemClick}
      >
        <View style={styles.bookmarkListFirstSection}>
          <View style={styles.bookmarkIconContainer}>
            <IconSVG
              svgComponent={BookmarkIcon}
              size={{ height: Spacings.Medium, width: Spacings.Medium }}
            />
          </View>
          <Text style={styles.bookmarkName}>{tag.label}</Text>
        </View>
        <View>
          {showDelete && (
            <TouchableOpacity onPress={() => onRemoveTag(tag)} style={styles.trashIcon}>
              <IconSVG svgComponent={TrashIcon} />
            </TouchableOpacity>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.bookmarkRootContainer}>
      {tags.length ? (
        tags.map((eachTag: TagExtended, key: number) => <BookmarkList tag={eachTag} key={key} />)
      ) : (
        <View style={styles.bookmarkEmptyContainer}>
          <Text style={styles.bookmarkEmptyText}>{t('common:filters.noBookmark')}</Text>
        </View>
      )}
    </View>
  );
};
