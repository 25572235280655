import { StyleSheet } from 'react-native-web';
import { Colors, Spacings, TextFontStyles, createRoundedStyle, fontSize } from 'shared/styles';

export const TableRowHeight = 68;

const styles = StyleSheet.create({
  tableRootContainer: {
    height: '100%',
  },
  paginationContainer: {
    height: Spacings.Large,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
  },
  activityIndicator: {
    paddingTop: Spacings.xMedium,
    paddingRight: Spacings.xMedium,
  },
  tableContainer: {
    backgroundColor: Colors.White,
  },
  tableSortContainer: {
    flexDirection: 'row',
    width: '95%',
  },
  innerScrollView: { flex: 1 },
  headerOuterContainer: { zIndex: 999, position: 'sticky', top: 0 },
  verticalScrollView: { backgroundColor: Colors.White, zIndex: 1 },
  verticalScrollViewContentContainer: { flex: 1, height: '100%', zIndex: 1 },
  headerContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.White,
    borderTopLeftRadius: Spacings.Small,
    borderTopRightRadius: Spacings.Small,
    cursor: 'normal',
    height: Spacings.Standard * 2,
    paddingLeft: Spacings.Standard + Spacings.Unit,
    paddingRight: Spacings.Small,
    justifyContent: 'space-between',
  },
  circleView: {
    ...createRoundedStyle(6),
    backgroundColor: Colors.Yellow,
    marginTop: Spacings.Unit,
    marginLeft: -Spacings.Unit,
  },
  filterIcon: {
    paddingRight: Spacings.Standard - Spacings.Unit,
  },
  tableHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHeader: {
    ...TextFontStyles.LatoMedium,
    fontSize: fontSize.Regular,
    color: Colors.MediumGray,
  },
  mediumColWidth: {
    width: '200px',
  },
  smallColWidth: {
    width: '150px',
  },
  largeColWidth: {
    width: '250px',
  },
  icon: {
    marginTop: Spacings.Unit,
    marginLeft: Spacings.Small,
  },
  iconContainer: {
    flexDirection: 'row',
    marginLeft: -Spacings.Small,
  },
  plusIconBox: {
    marginRight: Spacings.Medium * 2,
  },
  excelIconBox: {
    marginRight: Spacings.Unit,
  },
  tableRow: {
    height: 'auto',
    minHeight: TableRowHeight,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: Colors.Background,
    backgroundColor: Colors.White,
    paddingRight: Spacings.Small,
    alignItems: 'center',
    marginLeft: Spacings.Standard + Spacings.Unit,
  },
  tableRowRightIcon: {
    alignItems: 'center',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    width: Spacings.CardPadding * 2,
  },
  deleteText: {
    color: Colors.Red,
  },
  footerContainer: {
    height: 50,
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerTextStyle: {
    marginRight: 30,
    textAlign: 'left',
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
    justifyContent: 'center',
  },
  addTableRowTextStyle: {
    ...TextFontStyles.LatoMedium,
    fontSize: fontSize.Regular,
    maxWidth: '50%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: Spacings.Unit - 1,
  },
  plusIconContainer: {
    marginRight: Spacings.Unit * 2.5,
  },
  addTableRowContainer: {
    backgroundColor: Colors.GreyBackground,
    width: '100%',
    height: 68,
    paddingLeft: Spacings.Unit * 8.5,
    paddingTop: 24,
    paddingBottom: 20,
    opacity: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#F3F4F5',
  },
  tableCellPadding: {
    paddingRight: Spacings.Small + Spacings.Unit,
    height: '100%',
    justifyContent: 'center',
  },
  tableScroll: {
    borderRadius: 8,
  },
  dragableArea: {
    width: 4,
    backgroundColor: 'red',
    cursor: 'col-resize',
  },
  noDataContainer: {
    width: '100%',
    height: '40%',
  },
  noDataMessageContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noDataMessageText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
});

export default styles;
