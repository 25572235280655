import React, { useEffect } from 'react';
import { useServerAndConnectionWarnings } from 'shared/hooks/ServerAndConnectionWarnings';
import { useRefreshToken } from 'shared/hooks/useRefreshToken';
import { useAppSubscription } from './hooks/AppSubscription';
import { AppVersionChecker } from './util/AppVersion';
import { MyHub } from './util/MyHub';

interface Props {
  clientVersion: string;
  /** Content to be displayed to authenticate the user */
  signInContent: JSX.Element;
  /** Content to be displayed once the user has been authenticated */
  appContent: JSX.Element;
}

export const AppWrapper: React.FC<Props> = props => {
  const [isCacheContextSet, isTenantAppLoaded] = useAppSubscription();

  useServerAndConnectionWarnings();

  useRefreshToken();

  useEffect(() => {
    if (props.clientVersion) AppVersionChecker.setClientVersion(props.clientVersion);
  }, []);

  useEffect(() => {
    if (isTenantAppLoaded) {
      MyHub.dispatchAppContext('TenantAppLoading', {
        loadingPercentage: undefined,
        closeModal: true,
      });
    }
  }, [isTenantAppLoaded]);

  return isCacheContextSet ? (isTenantAppLoaded ? props.appContent : props.signInContent) : null;
};
