import React, { useState, useEffect, createRef, useRef } from 'react';
import {
  View,
  TextInput,
  TextStyle,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  ViewStyle,
  StyleProp,
} from 'react-native';
import Styles from './Styles';
import * as _ from 'lodash-es';

interface Props {
  /**
   * Called every time on digit changes
   */
  onChange?: (inputId: string, values: string[]) => void;
  /**
   * Called when all the digits have been filled
   */
  onFilled: (inputId: string, values: string[]) => void;
  /**
   * initValues also define the number of digits, e.g. ['', '', '', ''] or ['Y', '4', '7', '']
   */
  inputId?: string;
  initialValues?: string[];
  onlyDigits?: boolean;
  style?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
}

export const InputVerificationCode: React.FC<Props> = props => {
  const onlyDigits = props.onlyDigits ?? true;
  const initialValues = props.initialValues ?? ['', '', '', '', '', ''];
  const elementsRef = useRef<React.RefObject<any>[]>(initialValues.map(() => createRef()));
  const [values, setValues] = useState<string[]>(initialValues);

  useEffect(() => {
    setValues(initialValues);
  }, initialValues);

  useEffect(() => {
    if (props.onChange) props.onChange(props.inputId ?? '', values);

    if (_.compact(values).length === initialValues.length) {
      props.onFilled(props.inputId ?? '', values);
    }
  }, [values]);

  function onChangeDigit(event: NativeSyntheticEvent<TextInputChangeEventData>, index: number) {
    const { text } = event.nativeEvent;

    
    if (values[index] === text) return;

    if (text === '') {
      
      const _values = _.clone(values);
      _values[index] = '';
      setValues(_values);
      if (elementsRef.current[index - 1]) elementsRef.current[index - 1].current.focus();
    } else if (onlyDigits && isNaN(parseInt(text, 10))) {
      const _values = _.clone(values);
      _values[index] = '';
      setValues(_values);
    } else {
      const _values = _.clone(values);
      _values[index] = text;
      setValues(_values);
      if (elementsRef.current[index + 1]) elementsRef.current[index + 1].current.focus();
    }
  }

  return (
    <View style={[Styles.box, props.style]}>
      {values.map((value, index) => {
        return (
          <TextInput
            testID={props.inputId}
            key={index}
            value={value}
            ref={elementsRef.current[index]}
            style={[Styles.input, props.inputStyle]}
            maxLength={1}
            onChange={e => onChangeDigit(e, index)}
            keyboardType="number-pad"
          />
        );
      })}
    </View>
  );
};
