import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  header: {
    position: 'relative',
    height: 84,
    width: '100%',
  },
});

export default styles;
