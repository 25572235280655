import { isString, round } from 'lodash-es';
import moment from 'moment';


export enum Mode {
  Time = 'time',
  Date = 'date',
  Default = 'datetime',
}

export const date_diff_inDays = function (date1: Date, date2: Date, precision?: number) {
  
  return round((date2.getTime() - date1.getTime()) / 86400000, precision);
};
export const date_diff_inHours = function (date1: Date, date2: Date, precision?: number) {
  
  return round((date2.getTime() - date1.getTime()) / 3600000, precision);
};
/**
 * Tells if 2 dates represent the same (UTC) Day regarless of the Time
 * @param date1
 * @param date2
 * @returns
 */
export function isSameDate(date1: Date, date2: Date) {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
}

export const isDateBetween = function (date: Date, startDate: Date, endDate: Date) {
  if (date.getTime() < startDate.getTime()) return false;
  if (date.getTime() > endDate.getTime()) return false;
  return true;
};

export function convertDate_ddMMYYYY(date: string | Date, forceDotSeparator: boolean = true) {
  let _date: number | Date;
  if (isString(date)) {
    _date = Date.parse(date);
    if (isNaN(_date)) return '-';
  } else {
    _date = date;
  }

  const formatedDate = moment(date).format('L');

  return forceDotSeparator ? formatedDate.split('/').join('.') : formatedDate;
}
