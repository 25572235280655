import React, { useState, useEffect, useRef } from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { HeaderLayout } from '../header-layout/HeaderLayout';
import styles from './Style';
import { HeaderTitleProvider } from 'shared/context/HeaderTitleContext';
import { PermissionManagementProvider } from 'shared/context/PermissionManagementContext';
import { LocationDescriptor } from 'history';
import { useHistory } from 'react-router-dom';
import { logger } from 'shared/util/Logger';
import { View } from 'react-native';
import { Trainings } from '../training';
import { Dashboard } from '../dashboard';
import { MyFactory } from '../my-factory';
import * as API from 'shared/backend-data';
import { Animated, Easing, Dimensions, InteractionManager } from 'react-native';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { SideBar } from './SideBar';
import { UsersModal } from '../users-modal/modal/container';
import { InviteWorkerModalFilterProvider } from 'shared/context/inviteWorkerModalContext';
import { DashboardContextProvider } from 'shared/context/dashboard-context/DashboardContextProvider';
import { ModalUtils } from 'shared/ui-component/Modal';
import { MyHub } from 'shared/util/MyHub';
import { useModal } from 'shared/ui-component/Modal/Modal';
import { DependencyVetoModalContent } from '../veto-modal/index';
import { MyFactoryProvider } from 'shared/context/MenuFactoryContext';
import { HeaderFilterProvider } from 'sharedweb/src/Filter/FilterContext';
import { GlobalDataProvider } from 'shared/skillmgt/context/GlobalDataContext';
import { CompanySettings } from '../company-settings/container';
import { MyFactoryContextProvider } from '../my-factory/MyFactoryContext';
import { KPIScreen } from '../KPI-screen/container';
import { TreeTableDataProvider } from 'sharedweb/src/context/TreeTableContext';
import { KPIOrgUnits } from 'skillmgtweb/src/components/KPI-screen/components/KPIOrgUnits/container';
import { ROUTE_PATHNAME_KEY, RedirectComponent } from './RedirectComponent';
import { extractIdForNonCombinedIds } from 'shared/backend-data';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { findForwardSlashAtEndOfString } from 'shared/util/const';
import { CheckSearchParamAndRenderComponent } from './CheckSearchParamAndRenderComponent';
import { WorkstationsState } from '../my-factory/workstations/WorkstationsLibrary';
import { WorkerProfileState } from '../my-factory/workers/component/worker-profile';
/**
 * History location state is common for all routes in the application.
 * It is actually the combination of all individual route states.
 * However properties in the location state are dynamically added and hence they are marked as optional.
 * If we add new Route State, it should be added in the GlobalLocationState
 */
export type GlobalLocationState = Partial<WorkstationsState & WorkerProfileState>;

interface EmptyState {}

export type ProfileRouteParam = {
  id: string;
};

export const RouteLocations = {
  Home: (): LocationDescriptor<WorkerProfileState> => {
    return {
      pathname: RoutePaths.Home,
    };
  },
  Auth: (): LocationDescriptor<WorkerProfileState> => {
    return {
      pathname: RoutePaths.Auth,
    };
  },

  /**
   * selectedNodeId can be a WorkstationId or an OrganizationalUnitId
   */
  Workstations: (
    selectedNodeId?: string,
    tabIndex?: number,
    subTabIndex?: number,
    selectedShiftId?: string,
  ): LocationDescriptor<WorkstationsState> => {
    return {
      pathname: selectedNodeId
        ? `${RoutePaths.WorkstationProfile}/${extractIdForNonCombinedIds(selectedNodeId)}`
        : RoutePaths.Workstations,
      state: { tabIndex, subTabIndex, selectedShiftId },
    };
  },

  Skills: (): LocationDescriptor<EmptyState> => {
    return {
      pathname: RoutePaths.Skills,
    };
  },

  SkillProfile: (skillId: string): LocationDescriptor => {
    return {
      pathname: `${RoutePaths.SkillProfile}/${extractIdForNonCombinedIds(skillId)}`,
    };
  },

  Workers: (): LocationDescriptor<EmptyState> => {
    return {
      pathname: RoutePaths.Workers,
    };
  },

  MyFactory: (): LocationDescriptor => {
    return {
      pathname: RoutePaths.MyFactory,
    };
  },

  CompanySettings: (): LocationDescriptor => {
    return {
      pathname: RoutePaths.CompanySettings,
    };
  },

  KPI: (): LocationDescriptor => {
    return {
      pathname: RoutePaths.KPI,
    };
  },

  KPIOrgUnits: (): LocationDescriptor => {
    return {
      pathname: RoutePaths.KPIOrgUnits,
    };
  },

  WorkerProfile: (workerId: string, tabIndex?: number): LocationDescriptor<WorkerProfileState> => {
    return {
      pathname: `${RoutePaths.WorkerProfile}/${extractIdForNonCombinedIds(workerId)}`,
      state: { tabIndex },
    };
  },

  TrainingSessions: (trainingSessionId?: string): LocationDescriptor<EmptyState> => {
    if (trainingSessionId) {
      let pathname = window.location.pathname;
      if (!findForwardSlashAtEndOfString.test(pathname)) {
        pathname = `${pathname}/`;
      }
      return {
        pathname,
        search: `${RoutePaths.TrainingSessionId}=${trainingSessionId}`,
      };
    }
    return {
      pathname: RoutePaths.TrainingSessions,
    };
  },

  Trainings: (): LocationDescriptor<EmptyState> => {
    return {
      pathname: RoutePaths.Trainings,
    };
  },

  TrainingProfile: (trainingId: string): LocationDescriptor => {
    return {
      pathname: `${RoutePaths.TrainingProfile}/${extractIdForNonCombinedIds(trainingId)}`,
    };
  },

  MyProfile: (): LocationDescriptor<WorkerProfileState> => {
    return {
      pathname: RoutePaths.WorkerProfile,
      state: {},
    };
  },
};

export const TenantAppRoutes: React.FC<RouteComponentProps> = props => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const modal = useModal();

  const [userTenantApp, setUserTenantApp] = useState<API.TenantApp>();
  const [innerHeight, setInnerHeight] = useState(Dimensions.get('window').height);
  const [verstatilityPanelFullScreen, setVersatilityPanelFullScreen] = useState(false);
  const [openUsersModal, setOpenUsersModal] = useState<boolean>(false);

  const sideBarPadding = useRef(new Animated.Value(56)).current;

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      await API.getTrainingSessions();
      if (!isMounted.current) return;
    });
  }, []);

  useEffect(() => {
    getLocationPath();

    const resizeListener = () => {
      setInnerHeight(Dimensions.get('window').height);
    };

    window.addEventListener('resize', resizeListener);

    const removeListener = MyHub.listenError('DependencyVeto', payload => {
      modal.displayModal(
        ModalUtils.CustomModalConfig({
          content: (
            <GlobalDataProvider>
              <DependencyVetoModalContent
                vetoMessage={payload.message}
                dependencyIds={payload.dependencyIds}
                vetoAcceptCallback={payload.vetoAcceptCallback}
                disableRedirection={payload.disableRedirection}
              />
            </GlobalDataProvider>
          ),
        }),
      );
    });

    getUserTenantApp();

    return () => {
      window.removeEventListener('resize', resizeListener);
      removeListener();
    };
  }, []);

  useEffect(() => {
    handleSideBar(verstatilityPanelFullScreen);
  }, [verstatilityPanelFullScreen]);

  async function getUserTenantApp() {
    const _tenantApp = await API.getUserTenantApp();
    if (!isMounted.current) return;
    if (API.isFailure(_tenantApp)) {
      logger.warn(
        'Failed to load TenantApp but this Component shall be called only when tenantApp context is set. Error message: ' +
          _tenantApp.message,
        _tenantApp,
      );
      return;
    }

    setUserTenantApp(_tenantApp);
  }

  function getLocationPath() {
    const pathName = window.localStorage.getItem(ROUTE_PATHNAME_KEY);
    if (pathName !== null) {
      window.localStorage.removeItem(ROUTE_PATHNAME_KEY);
      history.push(pathName.replace(findForwardSlashAtEndOfString, ''));
    } else {
      logger.info('There is no path to forward');
    }
  }

  const handleSideBar = (bool: boolean) => {
    Animated.timing(sideBarPadding, {
      useNativeDriver: false,
      toValue: bool ? -1 : 56,
      duration: bool ? 1200 : 800,
      easing: Easing.elastic(1),
    }).start();
  };

  return (
    <Animated.View
      style={[
        styles.outerContainer,
        {
          height: innerHeight,
          paddingLeft: sideBarPadding,
          overflow: 'hidden',
        },
      ]}
    >
      <GlobalDataProvider>
        <PermissionManagementProvider>
          <MyFactoryProvider>
            <SideBar
              innerHeight={innerHeight}
              tenantApp={userTenantApp}
              headerSideBarFullScreen={verstatilityPanelFullScreen}
              setOpenUsersModal={setOpenUsersModal}
              {...props}
            />
            {openUsersModal && (
              <InviteWorkerModalFilterProvider>
                <UsersModal showUsersModal={setOpenUsersModal} />
              </InviteWorkerModalFilterProvider>
            )}
            <View
              style={[
                styles.scrollViewContainer,
                {
                  height: innerHeight,
                },
              ]}
            >
              <HeaderFilterProvider>
                <HeaderTitleProvider>
                  {}
                  <MyFactoryContextProvider>
                    <CheckSearchParamAndRenderComponent />

                    <HeaderLayout
                      {...props}
                      headerSideBarFullScreen={verstatilityPanelFullScreen}
                    />
                    <Switch>
                      <Route
                        path={RoutePaths.Home}
                        exact
                        render={props2 => {
                          return (
                            <DashboardContextProvider>
                              <Dashboard
                                {...props2}
                                versatilityPanelFullScreenHandler={setVersatilityPanelFullScreen}
                                versatilityPanelFullScreen={verstatilityPanelFullScreen}
                              />
                            </DashboardContextProvider>
                          );
                        }}
                      />

                      <Route
                        path={RoutePaths.TrainingSessions}
                        exact
                        render={props2 => {
                          return <Trainings {...props2} />;
                        }}
                      />

                      <Route
                        path={RoutePaths.KPI}
                        exact
                        render={props2 => {
                          return (
                            <TreeTableDataProvider>
                              <KPIScreen />
                            </TreeTableDataProvider>
                          );
                        }}
                      />

                      <Route
                        path={RoutePaths.KPIOrgUnits}
                        exact
                        render={props2 => {
                          return (
                            <TreeTableDataProvider>
                              <KPIOrgUnits />
                            </TreeTableDataProvider>
                          );
                        }}
                      />

                      <Route path={RoutePaths.Auth} exact render={props2 => <Redirect to="/" />} />
                      <Route
                        path={RoutePaths.MyFactory}
                        render={props2 => {
                          return <MyFactory {...props2} />;
                        }}
                      />
                      <Route
                        path={RoutePaths.CompanySettings}
                        exact
                        render={props2 => {
                          return <CompanySettings />;
                        }}
                      />
                    </Switch>
                  </MyFactoryContextProvider>
                </HeaderTitleProvider>
              </HeaderFilterProvider>
            </View>
          </MyFactoryProvider>
        </PermissionManagementProvider>
      </GlobalDataProvider>
    </Animated.View>
  );
};

export const AuthenticationRoutes: React.FC<RouteComponentProps> = props => {
  logger.info('AuthenticationRoutes: User is not signed-in');

  return (
    <Switch>
      <RedirectComponent {...props} />
    </Switch>
  );
};
