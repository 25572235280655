import React, { useContext, useState } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { Spacings } from 'shared/styles';
import { Table, TableColumn } from 'shared/ui-component/Table';
import * as API from 'shared/backend-data';
import { TrainingSessionWithSkillsRow, UnassociatedTrainingId, WorkerSkillChildRow } from '.';
import _ from 'lodash';
import { replaceDiacriticsAndCapitalLetter } from 'shared/util-ts/Functions';
import { Styles } from './Styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { TrainingSessionSkillsProgress } from 'shared/layout/cards/TrainingSessionSkillsProgress';
import { RouteLocations } from '../../../navigation/Routes';
import * as SharedStyles from 'shared/styles';
import { useHistory } from 'react-router-dom';
import { SingleActionRow } from 'shared/layout/cards/SingleActionRow';
import { getValidityColor } from 'shared/util/skillUi';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { ModalUtils } from 'shared/ui-component/Modal';
import { DependencyVetoModalContent } from '../../../veto-modal';
import { ProofBookContainerWeb } from '../../../training/proof-book';
import { WorkerSkillAddModal } from '../../../add-workerskill-modal/container';

const arrowDownIcon = require('shared/assets/svg/icon.arrowDown.mobile.svg').default;
const arrowRightIcon = require('shared/assets/svg/icon.arrowRight.mobile.svg').default;
const trainingIcon = require('shared/assets/svg/icon.training.svg').default;
const skillsIcon = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const thumbsUp = require('shared/assets/svg/icon.thumbUp.svg').default;
const thumbsDown = require('shared/assets/svg/icon.thumbDown.svg').default;
const openInNewIcon = require('shared/assets/svg/icon.openInNew.svg').default;
const proofFile = require('shared/assets/svg/icon.proofFile.svg').default;
const nextArrow = require('shared/assets/svg/icon.next.small.svg').default;

interface Props {
  worker: API.Worker;
  trainingSessionsWithSkillsRow: (TrainingSessionWithSkillsRow | WorkerSkillChildRow)[];

  setTrainingSessionsForLevelSkills: React.Dispatch<
    React.SetStateAction<(TrainingSessionWithSkillsRow | WorkerSkillChildRow)[] | undefined>
  >;
}

export const WorkerSkillConformityTable: React.FC<Props> = props => {
  const { worker, trainingSessionsWithSkillsRow, setTrainingSessionsForLevelSkills } = props;
  const { isValidPermission } = useContext(PermissionManagementContext);
  const history = useHistory();
  const isMounted = useIsMounted();
  const modal = ModalUtils.useModal();

  const [selectedSkill, setSelectedSkill] = useState<API.Skill>();
  const [proofBundle, setProofBundle] = useState<API.NoMetadata<API.ProofBundle> | null>();
  const [showWorkerSkillReviewModal, setShowWorkerSkillReviewModal] = useState(false);
  const [showAddWorkerSkillModal, setShowAddWorkerSkillModal] = useState(false);
  const [proofBookSkillIds, setProofBookSkillIds] = useState<string[]>();
  const [proofBookWorkerIds, setProofBookWorkerIds] = useState<string[]>();
  const [showProofBook, setShowProofBook] = useState(false);
  const [selectedSkillRow, setSelectedSkillRow] = useState<WorkerSkillChildRow>();

  const isTrainingRow = (
    row: TrainingSessionWithSkillsRow | WorkerSkillChildRow,
  ): row is TrainingSessionWithSkillsRow => {
    return 'skills' in row;
  };

  function sortByName(
    rows: (WorkerSkillChildRow | TrainingSessionWithSkillsRow)[],
    sortDirection: API.SortDirection,
  ): (WorkerSkillChildRow | TrainingSessionWithSkillsRow)[] {
    const trainings: TrainingSessionWithSkillsRow[] = [];
    const skills: WorkerSkillChildRow[] = [];
    rows.forEach(row => {
      if (isTrainingRow(row)) trainings.push(row);
      else skills.push(row);
    });

    const orderedTrainings = _.orderBy(
      trainings,
      training => replaceDiacriticsAndCapitalLetter(training.trainingName),
      [sortDirection],
    );
    const orderedSkills = _.orderBy(
      skills,
      skill => replaceDiacriticsAndCapitalLetter(skill.skill.name),
      [sortDirection],
    );

    const orderedTrainingsWithOrderedWorkerSkills: (
      | WorkerSkillChildRow
      | TrainingSessionWithSkillsRow
    )[] = [];
    orderedTrainings.forEach(orderedTraining => {
      orderedTrainingsWithOrderedWorkerSkills.push(orderedTraining);
      orderedSkills.forEach(orderedSkill => {
        if (orderedSkill.parentTrainingKey === orderedTraining.key)
          orderedTrainingsWithOrderedWorkerSkills.push(orderedSkill);
      });
    });

    return orderedTrainingsWithOrderedWorkerSkills;
  }

  const displayModalLoader = () => {
    modal.displayLoader(ModalUtils.LoaderConfig({}));
  };

  const hideModalLoader = () => {
    modal.hideLoader();
  };

  async function _stopTrainingForWorker(workerId: string, trainingSessionId: string) {
    displayModalLoader();

    const trainingSession = await API.getTrainingSession(trainingSessionId);
    if (!isMounted.current) return;
    if (API.isFailure(trainingSession)) {
      hideModalLoader();
      return trainingSession;
    }

    const updatedTrainingSession = await API.removeAndUpdateWorkerFromTrainingSession(
      workerId,
      trainingSession,
    );
    if (!isMounted.current) return;
    if (API.isFailure(updatedTrainingSession)) {
      hideModalLoader();
      return updatedTrainingSession;
    }

    hideModalLoader();

    modal.displayModal(
      ModalUtils.toastConfig({
        text: API.capitalizeFirstLetter(t('alex:workstationWorkerLevelMenu.trainingStopedMessage')),
        callback: () => {},
      }),
    );
  }

  function stopTrainingForWorker(workerId: string, trainingSessionId: string) {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:workstationWorkerLevelMenu.stopTrainingPopup'),
        warningAcceptCallback: () => _stopTrainingForWorker(workerId, trainingSessionId),
        warningAcceptButton: API.capitalizeFirstLetter(t('common:button.yes')),
        warningCancelButton: API.capitalizeFirstLetter(t('common:button.no')),
      }),
    );
  }

  const renderLabelCell = (row: TrainingSessionWithSkillsRow | WorkerSkillChildRow) => {
    const isTrainingSession = isTrainingRow(row);

    if (isTrainingSession) {
      return (
        <View style={Styles.skillDetailsCellContainer}>
          <IconSVG
            svgComponent={row.isTrainingCollapsed ? arrowRightIcon : arrowDownIcon}
            color={SharedStyles.Colors.Black}
            containerStyle={{ marginRight: SharedStyles.Spacings.Small }}
            size={{
              width: SharedStyles.Spacings.Standard,
              height: SharedStyles.Spacings.Standard,
            }}
          />
          <IconSVG
            svgComponent={trainingIcon}
            containerStyle={Styles.iconContainer}
            color={SharedStyles.Colors.White}
            size={{ width: SharedStyles.Spacings.Standard, height: SharedStyles.Spacings.Standard }}
          />
          <Text
            style={[
              Styles.worstationInfoText,
              { paddingLeft: SharedStyles.Spacings.Small, width: '90%' },
              SharedStyles.Styles.tableText,
            ]}
          >
            {row.trainingName}
          </Text>
        </View>
      );
    } else {
      return (
        <View
          style={[
            Styles.skillDetailsCellContainer,
            { paddingLeft: SharedStyles.Spacings.Medium * 4 },
          ]}
        >
          <IconSVG
            svgComponent={skillsIcon}
            containerStyle={Styles.iconContainer}
            color={SharedStyles.Colors.White}
            size={{ width: SharedStyles.Spacings.Standard, height: SharedStyles.Spacings.Standard }}
          />
          <Text
            style={[
              Styles.worstationInfoText,
              { paddingLeft: SharedStyles.Spacings.Small, width: '90%' },
              SharedStyles.Styles.tableText,
            ]}
          >
            {row.skill.name}
          </Text>
        </View>
      );
    }
  };

  const _renderTrainingActionButtonInLevelCell = (
    row: TrainingSessionWithSkillsRow,
    isTrainingVersion: boolean,
    isTrainingSession: boolean,
    isUnassociatedTraining: boolean,
  ) => {
    if (isUnassociatedTraining)
      return (
        <TouchableOpacity
          onPress={() => {
            history.push(RouteLocations.Workstations(row.workstationId, 2, 2));
          }}
        >
          <IconSVG
            svgComponent={openInNewIcon}
            size={{ width: 24, height: 24 }}
            color={SharedStyles.Colors.MediumGray}
          />
        </TouchableOpacity>
      );
    else if (isTrainingSession)
      return (
        <TouchableOpacity
          onPress={() => {
            history.push(RouteLocations.TrainingSessions(row.trainingSession?.id));
          }}
          disabled={!isValidPermission(API.Permission.trainingSessions_edit)}
          style={Styles.inProgressTrainingSessionContainer}
        >
          <Text
            style={[SharedStyles.TextFontStyles.LatoBold, Styles.inProgressTrainingSessionText]}
          >
            {t('alex:trainingSessions.trainingTypes.inProgress', undefined, true)}
          </Text>
          <IconSVG
            svgComponent={openInNewIcon}
            size={{ width: 24, height: 24 }}
            color={SharedStyles.Colors.MediumGray}
          />
        </TouchableOpacity>
      );
    else if (isTrainingVersion)
      return (
        isValidPermission(API.Permission.trainingSessions_edit) && (
          <SingleActionRow
            buttonText={t('common:button.train')}
            onAction={() => {
              trainWorker(row, worker.id, row.trainingVersionId!);
            }}
          />
        )
      );
  };

  async function handleNonWorkstationTrainingSessionOnPress(
    trainingSessions: API.TrainingSession[],
    skillName: string,
  ) {
    if (trainingSessions.length === 1) {
      history.push(RouteLocations.TrainingSessions(trainingSessions[0].id));
    } else {
      modal.displayModal(
        ModalUtils.CustomModalConfig({
          content: (
            <DependencyVetoModalContent
              vetoMessage={t('alex:skillConformityModal.skillCoveredInOtherTrainings', {
                skillName,
              })}
              dependencyIds={trainingSessions.map(trainingSession => trainingSession.id)}
              buttonStyle={{ display: 'none' }}
            />
          ),
        }),
      );
    }
  }

  const renderLevelCell = (row: TrainingSessionWithSkillsRow | WorkerSkillChildRow) => {
    const isTraining = isTrainingRow(row);
    if (isTraining) {
      const isTrainingVersion: boolean = !!row.trainingVersionId;
      const isTrainingSession: boolean = !!row.trainingSession;
      const isUnassociatedTraining = row.key === UnassociatedTrainingId;
      const validSkills = row.skills.filter(skill => {
        return skill.validity && API.isWorkerSkillValid(skill.validity);
      });

      return (
        <View
          style={[
            Styles.fullWidth,
            {
              flex: 1,
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            },
          ]}
        >
          {_renderTrainingActionButtonInLevelCell(
            row,
            isTrainingVersion,
            isTrainingSession,
            isUnassociatedTraining,
          )}
          {isTrainingSession && <View style={{ height: 6 }} />}
          {isTrainingSession && isValidPermission(API.Permission.trainingSessions_edit) && (
            <TrainingSessionSkillsProgress
              style={{ width: '90%' }}
              validSkillsCount={validSkills.length}
              requiredSkillsCount={row.skills.length}
            />
          )}
        </View>
      );
    } else {
      const parentId = row.parentTrainingKey;
      let isParentTrainingSession = false;
      if (parentId.includes(API.DataType.TRAININGSESSION)) isParentTrainingSession = true;

      const isSkillCoveredByNonWorkstationTrainingSession =
        !isParentTrainingSession && !!row.nonWorkstationTrainingSessions?.length;

      return (
        <View style={[Styles.skillDetailsCellContainer, { justifyContent: 'center' }]}>
          {row.acquired ||
          (row.acquired === null && row.proofBundle?.review.state === API.ReviewState.VALIDATED) ||
          (row.acquired === undefined && row.validity === API.Validity.OK) ? (
            <IconSVG
              svgComponent={thumbsUp}
              color={SharedStyles.Colors.Green}
              size={{ width: 20, height: 20 }}
            />
          ) : isSkillCoveredByNonWorkstationTrainingSession ? (
            <TouchableOpacity
              style={Styles.nonWorkstationTrainingSessionInProgress}
              onPress={() =>
                handleNonWorkstationTrainingSessionOnPress(
                  row.nonWorkstationTrainingSessions!,
                  row.skill.name,
                )
              }
            >
              <Text
                style={[
                  SharedStyles.TextFontStyles.LatoBold,
                  Styles.inProgressTrainingSessionText,
                  { marginTop: 3 },
                ]}
              >
                {t('alex:skillConformityModal.inTraining', undefined, true)}
              </Text>
              <View style={{ width: Spacings.Unit }} />
              <IconSVG
                svgComponent={openInNewIcon}
                size={{ width: 24, height: 24 }}
                color={SharedStyles.Colors.MediumGray}
              />
            </TouchableOpacity>
          ) : (
            <IconSVG
              svgComponent={thumbsDown}
              color={SharedStyles.Colors.Red}
              size={{ width: 20, height: 20 }}
            />
          )}
        </View>
      );
    }
  };

  function sortByValidity(
    rows: (WorkerSkillChildRow | TrainingSessionWithSkillsRow)[],
    sortDirection: API.SortDirection,
  ) {
    const trainings: TrainingSessionWithSkillsRow[] = [];
    const skills: WorkerSkillChildRow[] = [];
    rows.forEach(row => {
      if (isTrainingRow(row)) trainings.push(row);
      else skills.push(row);
    });

    const orderedSkillsByValidity = API.orderByWorkerSkillValidity(skills, sortDirection);

    const orderedTrainingsWithOrderedWorkerSkills: (
      | WorkerSkillChildRow
      | TrainingSessionWithSkillsRow
    )[] = [];
    orderedSkillsByValidity.forEach(skill => {
      
      if (
        orderedTrainingsWithOrderedWorkerSkills.some(
          training => training.key === skill.parentTrainingKey,
        )
      )
        return;

      const training = trainings.find(training => training.key === skill.parentTrainingKey);
      if (training) {
        orderedTrainingsWithOrderedWorkerSkills.push(training);
        orderedSkillsByValidity.forEach(skill => {
          if (skill.parentTrainingKey === training.key)
            orderedTrainingsWithOrderedWorkerSkills.push(skill);
        });
      }
    });

    return orderedTrainingsWithOrderedWorkerSkills;
  }

  const renderValidityCell = (row: TrainingSessionWithSkillsRow | WorkerSkillChildRow) => {
    const isTrainingSession = isTrainingRow(row);

    const statusMessage = !isTrainingSession
      ? row.stateString
      : row.worstSkillValidity?.stateString ?? '';

    const statusMessageColor = !isTrainingSession
      ? getValidityColor(row.validity, SharedStyles.Colors.MediumGray)
      : getValidityColor(row.worstSkillValidity?.validity, SharedStyles.Colors.MediumGray);

    let buttontext: string = '';
    let buttoncallback = () => {};
    const buttonConfig = isTrainingSession ? undefined : handleButtonConfigByValidity(row);
    if (buttonConfig) {
      buttontext = buttonConfig[0];
      buttoncallback = buttonConfig[1];
    }
    return (
      <>
        {isTrainingSession ? (
          !!row.isTrainingCollapsed ? (
            <RowValidityCell
              statusMessage={statusMessage}
              statusMessageColor={statusMessageColor}
              buttontext={buttontext}
              buttoncallback={buttoncallback}
              isTrainingCollapsed={!!row.isTrainingCollapsed}
            />
          ) : (
            <></>
          )
        ) : (
          <RowValidityCell
            statusMessage={statusMessage}
            statusMessageColor={statusMessageColor}
            buttontext={buttontext}
            buttoncallback={buttoncallback}
          />
        )}
      </>
    );
  };

  function handleButtonConfigByValidity(
    row: WorkerSkillChildRow | TrainingSessionWithSkillsRow,
  ): any {
    if (isTrainingRow(row)) {
      let __trainingsAndSkills = [...(trainingSessionsWithSkillsRow ?? [])];

      __trainingsAndSkills.forEach(_trainingOrSkill => {
        if (!isTrainingRow(_trainingOrSkill) && _trainingOrSkill.parentTrainingKey === row.key) {
          _trainingOrSkill.isCollapsed = !_trainingOrSkill.isCollapsed;
        } else if (isTrainingRow(_trainingOrSkill) && _trainingOrSkill.key === row.key) {
          _trainingOrSkill.isTrainingCollapsed = !_trainingOrSkill.isTrainingCollapsed;
        }
      });

      setTrainingSessionsForLevelSkills(__trainingsAndSkills);
    } else {
      setSelectedSkillRow({ ...row });
      if (
        row.validity === API.Validity.KO_REJECTED &&
        isValidPermission(API.Permission.workersSkillsProof_edit)
      ) {
        return [
          t('common:button.renew'),
          () => {
            setSelectedSkill(row.skill);
            setProofBundle(row.proofBundle);
            setShowWorkerSkillReviewModal(true);
          },
        ];
      } else if (
        row.validity === API.Validity.KO_MISSING &&
        isValidPermission(API.Permission.workersSkillsProof_edit)
      ) {
        return [
          t('common:button.add'),
          () => {
            setSelectedSkill(row.skill);
            setShowAddWorkerSkillModal(true);
          },
        ];
      } else if (
        (row.toReviewProofBundle || row.validity === API.Validity.KO_NEW) && 
        isValidPermission(API.Permission.workersSkillProof_review)
      ) {
        return [
          t('common:button.validate'),
          () => {
            setSelectedSkill(row.skill);
            setProofBundle(row.proofBundle);
            setShowWorkerSkillReviewModal(true);
          },
        ];
      } else if (
        (row.validity === API.Validity.KO_EXPIRED ||
          row.validity === API.Validity.OK_EXPIRE_SOON ||
          row.validity === API.Validity.OK) &&
        isValidPermission(API.Permission.workersSkillsProof_edit)
      ) {
        return [
          ,
          () => {
            setSelectedSkill(row.skill);
            setProofBundle(row.proofBundle);
            setShowWorkerSkillReviewModal(true);
          },
        ];
      }
    }
  }

  function trainWorker(
    row: TrainingSessionWithSkillsRow,
    workerId: string,
    trainingVersionId: string,
  ) {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:workstationWorkerLevelMenu.createTrainingPopup'),
        warningAcceptButton: API.capitalizeFirstLetter(t('common:button.yes')),
        warningCancelButton: API.capitalizeFirstLetter(t('common:button.no')),
        warningAcceptCallback: () => _trainWorker(row, workerId, trainingVersionId),
      }),
    );
  }

  async function _trainWorker(
    row: TrainingSessionWithSkillsRow,
    workerId: string,
    trainingVersionId: string,
  ) {
    displayModalLoader();

    const requirementIds = new Set<string>();
    row.skills.forEach(skill => {
      requirementIds.add(skill.requirementId);
    });
    await Promise.all(
      Array.from(requirementIds).map(async requirementId => {
        const createdTrainingSession = await API.createTrainingSession({
          trainingVersionId: trainingVersionId,
          scheduledTraineeIds: [workerId],
          scheduledTrainers: [],
          originId: row.workstationId,
          requirementId: requirementId,
          requestState: API.ReviewState.VALIDATED,
          traineeIds: [],
          trainers: [],
        });
        if (!isMounted.current) return;
        if (API.isFailure(createdTrainingSession)) {
          hideModalLoader();
          return createdTrainingSession;
        }
      }),
    );

    hideModalLoader();

    modal.displayModal(
      ModalUtils.toastConfig({
        text: API.capitalizeFirstLetter(
          t('alex:workstationWorkerLevelMenu.trainingCreatedMessage'),
        ),
        callback: () => {},
      }),
    );
  }

  function handleOpenProofBook(workerIds: string[], skillIds: string[]) {
    setProofBookWorkerIds(workerIds);
    setProofBookSkillIds(skillIds);

    setShowProofBook(true);
    setShowAddWorkerSkillModal(false);
  }

  function getTableColumns(): TableColumn<TrainingSessionWithSkillsRow | WorkerSkillChildRow>[] {
    return [
      {
        label: t('glossary:training_plural'),
        sort: sortByName,
        width: '47%',
        renderCell: row => renderLabelCell(row),
      },
      {
        label: 'alex:skillConformityModal.headers.0',
        width: '16%',
        renderCell: row => renderLevelCell(row),
      },
      {
        label: t('alex:skillConformityModal.headers.1'),
        width: '37%',
        sort: sortByValidity,
        renderCell: row => renderValidityCell(row),
      },
    ];
  }

  return (
    <View>
      <Table
        avoidPagination
        columnDescriptors={getTableColumns()}
        defaultSortColumn={[2, API.SortDirection.asc]}
        rows={trainingSessionsWithSkillsRow}
        onRowPress={row => {
          const buttonConfig = handleButtonConfigByValidity(row);
          if (buttonConfig) buttonConfig[1]();
        }}
        style={[
          Styles.tableContainer,
          { paddingTop: Spacings.xMedium },
          { paddingLeft: Spacings.xMedium, paddingRight: Spacings.Unit * 10 },
        ]}
        paginationContainerStyle={{ height: 0 }}
        disableRowClick={false}
      />
      {showWorkerSkillReviewModal &&
        selectedSkillRow &&
        selectedSkillRow.skill.skillIds?.length && (
          <ProofBookContainerWeb
            setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
            workerIds={[worker.id]}
            skillIds={selectedSkillRow.skill.skillIds as string[]}
            proofAlreadyValidatedWorkersAndSkills={{
              workerIds: [worker.id],
              skillIds: selectedSkillRow.skill.skillIds as string[],
            }}
          />
        )}
      {showWorkerSkillReviewModal && proofBundle && (
        <ProofBookContainerWeb
          proofBundleId={proofBundle.id}
          setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
        />
      )}
      {showAddWorkerSkillModal && (
        <WorkerSkillAddModal
          arrowBack
          selectedSkills={selectedSkill ? [selectedSkill] : []}
          selectedWorkers={[worker]}
          handleModalClose={() => {
            setShowAddWorkerSkillModal(false);
            setSelectedSkill(undefined);
          }}
          openProofBook={handleOpenProofBook}
        />
      )}
      {proofBookSkillIds && proofBookWorkerIds && showProofBook && (
        <ProofBookContainerWeb
          workerIds={proofBookWorkerIds}
          skillIds={proofBookSkillIds}
          setShowAddTrainingProofModal={setShowProofBook}
        />
      )}
    </View>
  );
};

const RowValidityCell = (props: {
  statusMessage: string;
  statusMessageColor: string;
  buttontext: string;
  isTrainingCollapsed?: boolean;
  buttoncallback: () => void;
}) => {
  const { statusMessage, statusMessageColor, isTrainingCollapsed, buttoncallback } = props;

  return (
    <View style={[Styles.rowFilterContainer, Styles.fullWidth]}>
      <View style={[Styles.statusMessageContainer]}>
        <IconSVG
          svgComponent={proofFile}
          color={statusMessageColor}
          containerStyle={{ marginRight: Spacings.Small }}
        />
        <EllipsisWithTooltip
          textStyle={[SharedStyles.Styles.tableText, { color: statusMessageColor }]}
          text={statusMessage}
        />
      </View>
      <TouchableOpacity style={Styles.actionArrowStyle} onPress={buttoncallback}>
        {!isTrainingCollapsed && <IconSVG svgComponent={nextArrow} />}
      </TouchableOpacity>
    </View>
  );
};
