import React, { useState } from 'react';
import { Platform } from 'react-native';
import { StyleSheet, ViewStyle, TextStyle, TouchableOpacity, StyleProp } from 'react-native';
import { AbstractButton } from './Button';
import { Colors, Spacings, TextFontStyles, fontSize } from 'shared/styles';
import { SvgProps } from 'react-native-svg';
import { useCallOnHover } from 'shared/hooks/CallOnHover';

/**
 * Simplified props for our styled buttons
 */

interface StyledButtonProps {
  text?: string | undefined;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  icon?: React.FC<SvgProps>;
  textStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  isHoverable?: boolean;
  isSmallButton?: boolean;
}

interface ButtonStyle {
  containerStyle: ViewStyle;
  onHoverContainerStyle?: ViewStyle;
  textStyle: TextStyle;
  onHoverTextStyle?: TextStyle;
}

const isWebPlatform = Platform.OS === 'web';





const yellowStyle = (isHoverable?: boolean, isSmallButton?: boolean) => {
  return StyleSheet.create<ButtonStyle>({
    containerStyle: {
      backgroundColor: Colors.Yellow,
      borderRadius: 100,
      height: isWebPlatform
        ? isSmallButton
          ? Spacings.Large - Spacings.Unit
          : Spacings.Large + Spacings.Small
        : Spacings.Big,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: Spacings.Standard,
    },
    textStyle: {
      ...TextFontStyles.LatoBlack,
      color: Colors.White,
      fontSize: isSmallButton ? fontSize.Regular : fontSize.Large,
    },
    onHoverTextStyle: {
      ...TextFontStyles.LatoBlack,
      color: Colors.White,
      fontSize: isSmallButton ? fontSize.Regular : fontSize.Large,
    },
    onHoverContainerStyle: {
      backgroundColor: isHoverable ? Colors.Yellow : Colors.DarkYellow,
      borderRadius: 100,
      height: isWebPlatform
        ? isSmallButton
          ? Spacings.Large - Spacings.Unit
          : Spacings.Large + Spacings.Small
        : Spacings.Big,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: Spacings.Standard,
    },
  });
};

const deleteStyle = StyleSheet.create<ButtonStyle>({
  containerStyle: {
    backgroundColor: 'transparent',
  },
  onHoverContainerStyle: {
    backgroundColor: 'transparent',
  },
  textStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Large,
    color: Colors.Red,
  },
  onHoverTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Large,
    color: Colors.Red,
  },
});

const outlineStyle = (disable?: boolean, isHoverable?: boolean, isSmallButton?: boolean) => {
  return StyleSheet.create<ButtonStyle>({
    containerStyle: {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: disable ? Colors.Grey : Colors.Black,
      borderRadius: 100,
      height: isSmallButton ? Spacings.Standard : Spacings.Large,
      paddingHorizontal: Spacings.CardPadding,
      alignItems: 'center',
      justifyContent: 'center',
    },
    onHoverContainerStyle: {
      backgroundColor: isHoverable ? 'transparent' : Colors.Black,
      borderWidth: 1,
      borderColor: disable ? Colors.Grey : Colors.Black,
      borderRadius: 100,
      height: isSmallButton ? Spacings.Standard : Spacings.Large,
      paddingHorizontal: Spacings.CardPadding,
      alignItems: 'center',
      justifyContent: 'center',
    },
    textStyle: {
      ...TextFontStyles.LatoBold,
      color: disable ? Colors.Grey : Colors.Black,
      fontSize: isSmallButton ? Spacings.xMedium : Spacings.Medium,
    },
    onHoverTextStyle: {
      ...TextFontStyles.LatoBold,
      color: disable ? Colors.Grey : isHoverable ? Colors.Black : Colors.White,
      fontSize: isSmallButton ? Spacings.xMedium : Spacings.Medium,
    },
  });
};

const StyledButtonBuilder =
  (stylesheet: StyleSheet.NamedStyles<ButtonStyle>) => (props: StyledButtonProps) => {
    const textStyle = stylesheet.textStyle as TextStyle;
    const onHoverTextStyle = stylesheet.onHoverTextStyle as TextStyle;
    const containerStyle = stylesheet.containerStyle as ViewStyle;
    const onHoverContainerStyle = stylesheet.onHoverContainerStyle as ViewStyle;

    const [onHover, setOnHover] = useState<boolean>(false);
    const ref = useCallOnHover<TouchableOpacity>(
      Colors.BlueRollover,
      e => setOnHover(true),
      e => setOnHover(false),
    );

    return (
      <AbstractButton
        text={props.text}
        onPress={props.onPress}
        containerStyle={[onHover ? onHoverContainerStyle : containerStyle, props.style]}
        textStyle={[onHover ? onHoverTextStyle : textStyle, props.textStyle]}
        icon={props.icon}
        disabled={props.disabled}
        iconColor={textStyle.color}
        ref={ref}
        isHoverable={true}
      />
    );
  };

export const YellowButton: React.FC<StyledButtonProps> = props => {
  return StyledButtonBuilder(yellowStyle(props.isHoverable, props.isSmallButton))(props);
};

export const DeleteButton: React.FC<StyledButtonProps> = props => {
  return StyledButtonBuilder(deleteStyle)(props);
};

export const OutlineButton: React.FC<StyledButtonProps> = props => {
  return StyledButtonBuilder(outlineStyle(props.disabled, props.isHoverable, props.isSmallButton))(
    props,
  );
};
