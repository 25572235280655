import React from 'react';
import { View, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { t } from 'shared/localisation/i18n';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../../navigation/Routes';
import * as API from 'shared/backend-data/index';
import Styles from './styles';

interface Props {
  treeNodeId?: string;
}

export const NoTrainingsCustomMessage: React.FC<Props> = props => {
  const { treeNodeId } = props;

  const history = useHistory();

  function handleNavigation() {
    if (!treeNodeId) return;
    history.push(
      RouteLocations.Workstations(
        treeNodeId,
        API.getDataType(treeNodeId) === API.DataType.WORKSTATION ? 1 : 2,
      ),
    );
  }

  return (
    <View style={Styles.trainingCustomMessageContainer}>
      <Text style={Styles.customMessageTextStyle}>
        {t('alex:TrainingModal.noPracticalTrainingsCustomMessage.0')}
      </Text>
      <View style={Styles.customMessageDivider} />
      <Text style={Styles.customMessageTextStyle}>
        {t('alex:TrainingModal.noPracticalTrainingsCustomMessage.1')}{' '}
        <TouchableOpacity onPress={handleNavigation}>
          <Text style={Styles.customMessageHyperLinkStyle}>
            {t('alex:TrainingModal.noPracticalTrainingsCustomMessage.2', undefined, false)}
          </Text>
        </TouchableOpacity>
      </Text>
    </View>
  );
};
