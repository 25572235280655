import React, { useState, useEffect } from 'react';
import Style from './styles';
import { Text, TouchableOpacity, View, StyleProp, ViewStyle } from 'react-native';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles/Colors';
import { t } from 'shared/localisation/i18n';
import { SortDirection } from './index';
import { TextFontStyles } from 'shared/styles';

const ArrowDownIcon = require('shared/assets/svg/icon.down.verysmall.svg').default;

interface Props {
  label: string;
  activeSort: boolean;
  sortings: SortDirection[];
  handleOnPress: (direction: SortDirection) => void;
  sortDirection: SortDirection;
  needSort: boolean;
  needHover?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const TouchableSortComponent: React.FC<Props> = props => {
  const {
    label,
    activeSort,
    sortings,
    handleOnPress,
    sortDirection,
    needSort,
    needHover = true,
    style,
  } = props;

  const [isToggle, setIsToggle] = useState<boolean>(false);
  const [onHover, setOnHover] = useState<boolean>(false);

  useEffect(() => {
    if (sortDirection === SortDirection.desc) {
      setIsToggle(true);
    } else {
      setIsToggle(false);
    }
  }, [sortDirection]);

  return (
    <TouchableOpacity
      ref={useCallOnHover<TouchableOpacity>(
        Colors.GreyUltraLight,
        () => (needHover ? setOnHover(true) : null),
        () => (needHover ? setOnHover(false) : null),
      )}
      onPress={() => {
        handleOnPress(sortings[isToggle ? 0 : 1]);
        setIsToggle(prev => !prev);
      }}
      style={[
        style,
        Style.tableHeaderContainer,
        { backgroundColor: onHover ? Colors.Background : Colors.White },
      ]}
      disabled={!needSort}
    >
      <Text
        numberOfLines={onHover ? 3 : 1}
        style={[
          Style.tableHeader,
          { color: activeSort ? Colors.MediumGray : Colors.Grey },
          activeSort ? TextFontStyles.SemiBold : TextFontStyles.LatoRegular,
          { maxWidth: onHover ? '100%' : '90%' },
        ]}
      >
        {t(label)}
      </Text>
      {activeSort && needSort && (
        <View style={Style.iconContainer}>
          <IconSVG
            svgComponent={ArrowDownIcon}
            color={Colors.Black}
            containerStyle={[Style.icon, isToggle ? { transform: [{ rotate: '180deg' }] } : {}]}
          />
        </View>
      )}
    </TouchableOpacity>
  );
};
