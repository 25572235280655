import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  ReactElement,
  PropsWithChildren,
} from 'react';
import FileSaver from 'file-saver';
import Exceljs, { Workbook } from 'exceljs';
import { loggerAPI as logger } from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import {
  TouchableOpacity,
  View,
  ViewStyle,
  Text,
  ActivityIndicator,
  RegisteredStyle,
} from 'react-native';
import * as _ from 'lodash-es';
import { t } from 'shared/localisation/i18n';
import { IconSVG } from 'shared/ui-component/Icon';
import * as ExcelUtil from 'shared/util/ExcelUtils';
import { Card } from '../Card';
import { OutlineButton } from 'shared/ui-component/Button';
import { Styles, getCardLoaderStyle } from './Style';
import { ModalUtils } from 'shared/ui-component/Modal';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import * as API from 'shared/backend-data';
import { ModalStyles } from 'shared/styles/ModalStyles';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Spacings, Colors } from '../../styles';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { TextWithTooltip } from 'shared/ui-component/TextWithTooltip';
import { MyHub } from 'shared/util/MyHub';
import { ProgressBar } from 'shared/ui-component/ProgressBar';
import { DashboardFilterContext } from 'shared/context/dashboard-context/DashboardFilterContext';
import { ModalContext } from '../Modal/Modal';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { wait } from '../../util-ts/Functions';
import { ExportReport, ImportExportType } from '../../util/ExcelUtils';
import { Buffer } from 'buffer';

const ExcelIcon = require('shared/assets/svg/Icon.microsoftexcel.svg').default;
const CloseIcon = require('shared/assets/svg/icon.close.svg').default;
const InfoIcon = require('shared/assets/svg/icon.info.svg').default;

interface ImportExportComponentProps {
  importExportMenuType: ImportExportType;
  fileName: string;
  exportOnly?: boolean;
  style?: ViewStyle;
  refreshData?: () => Promise<void>;
  getExportData: (exportType: ImportExportType) => Promise<ExportReport>;
  menuOnly?: boolean;
  exportToCSVConfirmed?: () => void;
}

interface ImportExportProgress {
  rowsImported: number;
  totalRows: number;
}

export const ImportExportComponent: React.FC<ImportExportComponentProps> = props => {
  const {
    fileName,
    importExportMenuType,
    exportOnly,
    refreshData,
    getExportData,
    style,
    exportToCSVConfirmed,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [showImportLoading, setShowImportLoading] = useState<boolean>(false);
  const [showExportLoading, setShowExportLoading] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [importMessage, setImportMessage] = useState<string>();
  const [importReport, setImportReport] = useState<ExcelUtil.ImportReport>();
  const [importExportProgress, setImportExportProgress] = useState<ImportExportProgress>();
  const isMounted = useIsMounted();
  const modal = ModalUtils.useModal();
  const refView = React.useRef<View>(null);
  const refView3 = useDetectOutsideClick<View>(() => setShowOptions(false));
  const refIcon = useCallOnHover<TouchableOpacity>(Colors.GreyLight);
  const { isValidPermission } = useContext(PermissionManagementContext);
  const stepNumber = React.useRef<number>(0);
  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);
  const {
    workstations: [filteredWorkstations],
  } = useContext(DashboardFilterContext);

  function isUserAllowedToImport(type: ImportExportType): boolean {
    let importPermissions: API.Permission[] = [];
    switch (type) {
      case ImportExportType.Skill:
      case ImportExportType.Skill_FORCE:
        importPermissions = [API.Permission.skills_edit, API.Permission.trainings_edit];
        break;

      case ImportExportType.Training:
      case ImportExportType.Training_FORCE:
        importPermissions = [API.Permission.skills_edit, API.Permission.trainings_edit];
        break;

      case ImportExportType.Workstation:
      case ImportExportType.Workstation_FORCE:
        importPermissions = [API.Permission.workstations_edit];
        break;

      case ImportExportType.Worker:
      case ImportExportType.Worker_FORCE:
        importPermissions = [API.Permission.workers_edit, API.Permission.workersDetail_edit];
        break;

      case ImportExportType.WorkerSkill:
      case ImportExportType.WorkerSkill_FORCE:
      case ImportExportType.WorkerTraining:
      case ImportExportType.WorkerTrainingSession:
        importPermissions = [
          API.Permission.workersSkillsProof_edit,
          API.Permission.workersSkillProof_review,
          API.Permission.trainingSessions_edit,
        ];
        break;

      case ImportExportType.WorkerWorkstationTarget:
        importPermissions = [];
        break;

      case ImportExportType.TrainingSession:
        importPermissions = [API.Permission.trainingSessions_edit];
        break;

      case ImportExportType.WorkerProfileSkill:
      case ImportExportType.SkillsMatrix:
      case ImportExportType.SkillsToAcquire:
      case ImportExportType.SkillsToRenew:
        importPermissions = [];
        break;

      case ImportExportType.Workstation_RENAME_DELETE:
      case ImportExportType.Skill_RENAME_DELETE:
      case ImportExportType.Training_RENAME_DELETE:
      case ImportExportType.Worker_RENAME_DELETE:
      case ImportExportType.TrainingSession_RENAME_DELETE:
        importPermissions = [
          API.Permission.workstations_edit,
          API.Permission.skills_edit,
          API.Permission.trainings_edit,
          API.Permission.workers_edit,
          API.Permission.trainingSessions_edit,
        ];
        break;
    }

    return importPermissions.every(permission => isValidPermission(permission));
  }

  useEffect(() => {
    const removeExcelImportExportListener = MyHub.listenTaskProgress(
      'ExcelImportExportLoadingStep',
      payload => {
        if (payload.reset) {
          stepNumber.current = 0;
          setImportExportProgress(undefined);
          logger.debug(`Excel import/export reset`);
        } else {
          stepNumber.current = stepNumber.current + payload.incrementStep;

          setImportExportProgress({
            rowsImported: stepNumber.current,
            totalRows: payload.totalSteps,
          });

          logger.debug(
            `Excel import/export ${(stepNumber.current / payload.totalSteps) * 100}%. Done=${
              stepNumber.current
            }, Total: ${payload.totalSteps}`,
          );
        }
      },
    );

    return () => {
      removeExcelImportExportListener();
    };
  }, []);

  async function acknowledgeGDPR(modal: ModalContext, onAcknwoldege: () => void) {
    const _result = await API.saveUserPreference(
      API.UserPreferenceKeys_Common.RGPDExcelExportNotice,
      true,
    );
    if (API.isFailure(_result)) {
      logger.error('Error in saving user preference', _result);
      return;
    }

    modal.hideModal();

    onAcknwoldege();
  }

  async function processImportData(type: ImportExportType, workSheet: Exceljs.Worksheet) {
    logger.debug('Excel data to import', workSheet);

    setLoading(true);

    let report: ExcelUtil.ImportReport<{}> | undefined = undefined;
    switch (type) {
      case ImportExportType.Workstation:
        report = await ExcelUtil.processWorkstationData(
          workSheet,
          workstationTargetsStartingAtLevel,
        );
        break;
      case ImportExportType.Workstation_FORCE:
        report = await ExcelUtil.processWorkstationData(
          workSheet,
          workstationTargetsStartingAtLevel,
          true,
        );
        break;
      case ImportExportType.Workstation_RENAME_DELETE:
        report = await ExcelUtil.processBusinessObjectMutateData(
          workSheet,
          API.DataType.WORKSTATION  satisfies API.TreeDataType,
        );
        break;

      case ImportExportType.Skill:
        report = await ExcelUtil.processSkillData(workSheet);
        break;
      case ImportExportType.Skill_FORCE:
        report = await ExcelUtil.processSkillData(workSheet, true);
        break;
      case ImportExportType.Skill_RENAME_DELETE:
        report = await ExcelUtil.processBusinessObjectMutateData(workSheet, API.DataType.SKILL);
        break;

      case ImportExportType.Training:
        report = await ExcelUtil.processTrainingData(workSheet);
        break;
      case ImportExportType.Training_FORCE:
        report = await ExcelUtil.processTrainingData(workSheet, true);
        break;
      case ImportExportType.Training_RENAME_DELETE:
        report = await ExcelUtil.processBusinessObjectMutateData(workSheet, API.DataType.TRAINING);
        break;

      case ImportExportType.Worker:
      case ImportExportType.Worker_FORCE:
      case ImportExportType.WorkerSkill:
      case ImportExportType.WorkerSkill_FORCE:
      case ImportExportType.WorkerTraining:
      case ImportExportType.WorkerTrainingSession:
      case ImportExportType.WorkerWorkstationTarget:
        report = await ExcelUtil.processWorkerAnyData(workSheet, type);
        break;
      case ImportExportType.Worker_RENAME_DELETE:
        report = await ExcelUtil.processBusinessObjectMutateData(workSheet, API.DataType.WORKER);
        break;

      case ImportExportType.TrainingSession:
        report = await ExcelUtil.processTrainingSessionData(workSheet);
        break;
      case ImportExportType.TrainingSession_RENAME_DELETE:
        report = await ExcelUtil.processBusinessObjectMutateData(
          workSheet,
          API.DataType.TRAININGSESSION,
        );
        break;

      case ImportExportType.WorkerWorkstationTrainingRequest:
        report = await ExcelUtil.processWorkerWorkstationTrainingRequestData(workSheet);
        break;
    }
    if (!isMounted.current) return;

    if (!report) {
      logger.error('ImportExportType "' + type + '" not supported!');
      return;
    }

    setImportMessage(
      t('alex:importExport.importResult', {
        successCount: report.successes.length,
        failureCount: report.failures.length,
      }),
    );
    setImportReport(report);

    importEnded();
  }

  function handleImportError(errorMessage?: string) {
    if (!isMounted.current) return;

    setImportMessage(errorMessage ?? t('alex:importExport.importResultFailure'));
    setImportReport(undefined);

    importEnded();
  }

  function importStarted() {
    setImportMessage('');
    setImportReport(undefined);
    setShowImportLoading(true);
  }

  function importEnded() {
    setImportExportProgress(undefined);
    setLoading(false);

    if (refreshData) refreshData();
  }

  function getImportProgressMetrics(progress: ImportExportProgress | undefined): {
    percentage: number;
    rowsImported: number;
    rowsRemaining: number;
    totalRows: number;
  } {
    return {
      percentage: progress ? Math.round((progress.rowsImported / progress.totalRows) * 100) : 0,
      rowsImported: progress ? progress.rowsImported : 0,
      rowsRemaining: progress ? progress.totalRows - progress.rowsImported : 0,
      totalRows: progress ? progress.totalRows : 0,
    };
  }

  const isSmallIcon = props.importExportMenuType === ImportExportType.SkillsMatrix;

  const getExcelIconStyle = () => {
    const dimension = isSmallIcon ? Spacings.Medium + Spacings.Unit : Spacings.Standard;

    return { height: dimension, width: dimension };
  };

  const getExcelIconContainerStyle = () => {
    return isSmallIcon ? Styles.excelIconContainerDashboard : Styles.excelIconContainer;
  };

  async function exportToCSV(type: ImportExportType, fileName: string) {
    if (exportToCSVConfirmed) exportToCSVConfirmed();

    const exportExcelNotice = await API.getUserPreference<boolean>(
      API.UserPreferenceKeys_Common.RGPDExcelExportNotice,
    );
    if (API.isFailure(exportExcelNotice)) {
      logger.error('Error in fetching user preference', exportExcelNotice);
      return;
    }

    if (!exportExcelNotice) {
      showGDPRModal(() => exportToCSV(type, fileName));
      return;
    }

    setShowExportLoading(true);

    await wait(500); 

    const report = await getExportData(type);
    if (API.isFailure(report)) {
      setShowExportLoading(false);
      ModalUtils.showWarningFailure(modal, report);
      return;
    }

    ExcelUtil.addHeaderFilters(report.exportWorkbook);

    const excelBuffer = await report.exportWorkbook.xlsx.writeBuffer().catch(error => {
      return API.createFailure_Unspecified('Excel export error', error);
    });
    if (API.isFailure(excelBuffer)) {
      setShowExportLoading(false);
      ModalUtils.showWarningFailure(modal, excelBuffer);
      return;
    }

    const data = new Blob([excelBuffer], { type: ExcelUtil.fileType });
    FileSaver.saveAs(data, fileName + ExcelUtil.fileExtension);

    setShowExportLoading(false);

    
    if (report.partialExportFailures.length) {
      ModalUtils.showWarningFailure(
        modal,
        API.createFailure_Multiple(report.partialExportFailures),
      );
    }
  }

  async function importFromCSV(files: FileList | null, type: ImportExportType) {
    setShowOptions(false);

    importStarted();

    if (files) {
      const f = files[0]; 

      const workbook = new Exceljs.Workbook();

      
      
      const reader = new FileReader();
      reader.readAsBinaryString(f);
      reader.onload = async function (element) {
        const data = element.target?.result;

        if (!data) {
          handleImportError('Cannot read file content');
          return;
        }

        let buffer: Exceljs.Buffer;
        if (data instanceof ArrayBuffer) {
          buffer = data;
        } else {
          buffer = Buffer.from(data, 'binary');
        }

        await workbook.xlsx
          .load(buffer)
          .then(workbook => {
            const worksheets = workbook.worksheets;
            
            if (worksheets.length) {
              processImportData(type, worksheets[0]);
            } else {
              handleImportError('Excel file shall have at least one non-empty sheet.');
            }
          })
          .catch(error => {
            logger.error(error);
            handleImportError();
          });
      };
    }
  }

  function showExportModal(type: ImportExportType) {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:importExport.downloadCheck'),
        warningAcceptButton: t('common:button.confirm'),
        warningCancelButton: t('common:button.cancel'),
        warningAcceptCallback: () => exportToCSV(type, fileName),
        warningCancelCallback: () => modal.hideModal(),
      }),
    );
  }

  function showGDPRModal(onAcknowldege: () => void) {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:importExport.titleOfRGPD'),
        warningSubMessage: t('alex:importExport.messageOfRGPD'),
        warningAcceptButton: t('alex:importExport.acknowledgeRGPD'),
        warningAcceptCallback: () => acknowledgeGDPR(modal, onAcknowldege),
        
        containerStyle: { width: ModalStyles.width, height: 'auto' },
        isBlocking: false,
        isGeneric: true,
      }),
    );
  }

  async function downloadReport(importReport: ExcelUtil.ImportReport) {
    const workbook = new Exceljs.Workbook();
    const worksheet = workbook.addWorksheet();
    ExcelUtil.addHeaders(
      worksheet,
      t('alex:importExport.headers.report', {
        joinArrays: undefined,
      }),
    );

    worksheet.addRows(
      importReport.failures.map(failure => {
        const values: any[] = [failure.row?.number, failure.failureMessage];
        failure.row?.eachCell({ includeEmpty: true }, cell => {
          values.push(ExcelUtil.parseCellValueAsString(cell));
        });
        return values;
      }),
    );

    const excelBuffer = await workbook.xlsx.writeBuffer().catch(error => {
      logger.error('Excel export error', error);
      return;
    });
    if (!excelBuffer) return;

    const data = new Blob([excelBuffer], { type: ExcelUtil.fileType });
    FileSaver.saveAs(data, t('alex:importExport.importReport'));
  }

  const renderMenuOptions = () => {
    switch (importExportMenuType) {
      case ImportExportType.Worker:
        return (
          <>
            <RenderOption
              optionText={t('alex:importExport.menu.workerMenu.exportWorkers')}
              action={() => showExportModal(ImportExportType.Worker)}
              userAllowed={true}
            />
            <RenderOption
              spaceTop
              optionText={t('alex:importExport.menu.workerMenu.workerImport')}
              isImportOption={true}
              action={files => importFromCSV(files, ImportExportType.Worker)}
              userAllowed={isUserAllowedToImport(ImportExportType.Worker)}
            />
            <RenderOption
              optionText={t('alex:importExport.menu.workerMenu.exportWorkersAndSkills')}
              action={() => showExportModal(ImportExportType.WorkerWithSkill)}
              userAllowed={isValidPermission(API.Permission.workersDetail_view)}
            />
            <RenderOption
              optionText={t('alex:importExport.menu.workerMenu.proofOfSkillImport')}
              isImportOption={true}
              action={files => importFromCSV(files, ImportExportType.WorkerSkill)}
              userAllowed={isUserAllowedToImport(ImportExportType.WorkerSkill)}
            />
            <RenderOption
              optionText={t('alex:importExport.menu.workerMenu.proofOfTrainingImports')}
              isImportOption={true}
              action={files => importFromCSV(files, ImportExportType.WorkerTraining)}
              userAllowed={isUserAllowedToImport(ImportExportType.WorkerTraining)}
            />
            <RenderOption
              optionText={t('alex:importExport.importWorkerTrainingSession')}
              isImportOption={true}
              action={files => importFromCSV(files, ImportExportType.WorkerTrainingSession)}
              userAllowed={isUserAllowedToImport(importExportMenuType)}
              excelIcon={props.menuOnly}
            />
            <RenderOption
              optionText={t('alex:importExport.importWorkerWorkstationTarget')}
              isImportOption={true}
              action={files => importFromCSV(files, ImportExportType.WorkerWorkstationTarget)}
              userAllowed={isUserAllowedToImport(importExportMenuType)}
              excelIcon={props.menuOnly}
            />
            <RenderOption
              spaceTop
              optionText={t('alex:importExport.downloadTemplate_plural')}
              action={() => {
                ExcelUtil.downloadWorkerImportTemplates();
              }}
              userAllowed={true}
            />
          </>
        );

      case ImportExportType.TrainingSession:
        return (
          <>
            <RenderOption
              optionText={t('alex:importExport.exportData')}
              isImportOption={false}
              action={() => showExportModal(importExportMenuType)}
              userAllowed={true}
              spaceTop={props.menuOnly}
              excelIcon={props.menuOnly}
            />
            <RenderOption
              optionText={t('alex:importExport.importTrainingSession')}
              isImportOption={true}
              action={files => importFromCSV(files, ImportExportType.TrainingSession)}
              userAllowed={isUserAllowedToImport(importExportMenuType)}
              excelIcon={props.menuOnly}
            />
            <RenderOption
              optionText={t('alex:importExport.importWorkerWorkstationTrainingRequest')}
              isImportOption={true}
              action={files =>
                importFromCSV(files, ImportExportType.WorkerWorkstationTrainingRequest)
              }
              userAllowed={isUserAllowedToImport(importExportMenuType)}
              excelIcon={props.menuOnly}
            />
            <RenderOption
              optionText={t('alex:importExport.downloadTemplate_plural')}
              isImportOption={false}
              action={() => {
                ExcelUtil.downloadTrainingSessionImportTemplates();
              }}
              userAllowed={true}
              spaceBottom={props.menuOnly}
              excelIcon={props.menuOnly}
            />
          </>
        );

      default:
        return (
          <>
            <RenderOption
              optionText={t('alex:importExport.exportData')}
              isImportOption={false}
              action={() => showExportModal(importExportMenuType)}
              userAllowed={true}
              spaceTop={props.menuOnly}
              excelIcon={props.menuOnly}
            />
            <RenderOption
              optionText={t('alex:importExport.importData')}
              isImportOption={true}
              action={files => importFromCSV(files, importExportMenuType)}
              userAllowed={isUserAllowedToImport(importExportMenuType)}
              excelIcon={props.menuOnly}
            />
            <RenderOption
              optionText={t('alex:importExport.downloadTemplate')}
              isImportOption={false}
              action={() => {
                switch (importExportMenuType) {
                  case ImportExportType.Workstation:
                    ExcelUtil.downloadImportTemplate(
                      ExcelUtil.getWorkstationHeaders(workstationTargetsStartingAtLevel),
                      fileName,
                    );
                    break;

                  case ImportExportType.Skill:
                    ExcelUtil.downloadImportTemplate(ExcelUtil.getSkillHeaders(), fileName);
                    break;

                  case ImportExportType.Training:
                    ExcelUtil.downloadImportTemplate(ExcelUtil.getTrainingHeaders(), fileName);
                    break;

                  default:
                    break;
                }
              }}
              userAllowed={true}
              spaceBottom={props.menuOnly}
              excelIcon={props.menuOnly}
            />
          </>
        );
    }
  };

  const isShowInfoMessage_importIsNotDeletingData = (): boolean => {
    return [
      ImportExportType.Worker,
      ImportExportType.Skill,
      ImportExportType.Training,
      ImportExportType.Workstation,
    ].includes(importExportMenuType);
  };

  const isShowMenu = (): boolean => {
    return (showOptions || props.menuOnly) ?? false;
  };

  const renderMenu = () => {
    return (
      <>
        {props.menuOnly ? (
          renderMenuOptions()
        ) : (
          <Card style={Styles.cardMenuContainer} ref={refView3}>
            {renderMenuOptions()}
            {isShowInfoMessage_importIsNotDeletingData() && <InfoMessage />}
          </Card>
        )}
      </>
    );
  };

  const getCardStyle = (): ViewStyle | RegisteredStyle<ViewStyle> => {
    return importMessage && !loading
      ? Styles.loaderCardContainerAfterImport
      : Styles.loaderCardContainerDuringImport;
  };

  const renderImportExportProgress = (
    progress: ImportExportProgress | undefined,
    isImport: boolean,
  ): JSX.Element => {
    return progress ? (
      <>
        <View style={Styles.importProgressRowDetails}>
          <View style={Styles.rowView}>
            <Text style={Styles.importProgressTextStyle}>
              {isImport
                ? t('alex:importExport.importProgressLabels.imported')
                : t('alex:importExport.importProgressLabels.exported')}
            </Text>
            <Text style={Styles.importProgressTextStyle}>
              {getImportProgressMetrics(progress).rowsImported}
            </Text>
          </View>
          <View style={Styles.rowView}>
            <Text style={Styles.importProgressTextStyle}>
              {t('alex:importExport.importProgressLabels.remaining')}
            </Text>
            <Text style={Styles.importProgressTextStyle}>
              {getImportProgressMetrics(progress).rowsRemaining}
            </Text>
          </View>

          <View style={Styles.importProgressRowSeparator} />

          <View style={Styles.rowView}>
            <Text style={Styles.importProgressTextStyle}>
              {t('alex:importExport.importProgressLabels.totalRows')}
            </Text>
            <Text style={Styles.importProgressTextStyle}>
              {getImportProgressMetrics(progress).totalRows}
            </Text>
          </View>

          <View style={Styles.importProgressFooter}>
            <ProgressBar
              percentage={getImportProgressMetrics(progress).percentage}
              style={Styles.progressBar}
            />
            <View style={Styles.footerRowView}>
              <Text style={Styles.percentageLabelTextStyle}>
                {t('alex:importExport.importProgressLabels.percentageLabel')}
              </Text>
              <Text style={Styles.percentageTextStyle}>
                {getImportProgressMetrics(progress).percentage}%
              </Text>
            </View>
          </View>
        </View>
      </>
    ) : (
      <>
        <ActivityIndicator
          color={Colors.Yellow}
          size={Spacings.Big}
          style={{ marginVertical: Spacings.Standard }}
        />
        <Text style={Styles.importProgressTextStyle}>{t('alex:importExport.readingFile')}</Text>
      </>
    );
  };

  return (
    <View style={[{ width: '100%' }, style]} ref={refView}>
      {(showExportLoading || importExportProgress) && (
        <View style={getCardLoaderStyle(refView)}>
          <Card style={getCardStyle()}>
            {importExportProgress ? (
              renderImportExportProgress(importExportProgress, false)
            ) : (
              <>
                <ActivityIndicator
                  color={Colors.Yellow}
                  size={Spacings.Big}
                  style={{ marginVertical: Spacings.Standard }}
                />
                <Text style={Styles.exportLoadingTextStyle}>
                  {t('alex:importExport.exportLoading')}
                </Text>
              </>
            )}
          </Card>
        </View>
      )}

      {showImportLoading && (
        <View style={getCardLoaderStyle(refView)}>
          <Card style={getCardStyle()}>
            {importMessage && !loading ? (
              <TouchableOpacity
                style={Styles.closeIconContainer}
                onPress={() => setShowImportLoading(false)}
              >
                <IconSVG
                  size={{ width: Spacings.Large, height: Spacings.Large }}
                  svgComponent={CloseIcon}
                />
              </TouchableOpacity>
            ) : null}

            <Text style={Styles.headerTextStyle}>{t('alex:importExport.importTitle')}</Text>

            {importMessage && !loading ? (
              <>
                <Text style={Styles.textStyle}>{importMessage}</Text>
                {importReport?.failures.length ? (
                  <OutlineButton
                    onPress={() => downloadReport(importReport)}
                    textStyle={Styles.buttonTextStyle}
                    text={t('alex:importExport.downloadFaliureReport')}
                  />
                ) : (
                  <OutlineButton
                    onPress={() => setShowImportLoading(false)}
                    textStyle={Styles.buttonTextStyle}
                    text={t('common:button.ok')}
                    style={Styles.okButton}
                  />
                )}
              </>
            ) : (
              renderImportExportProgress(importExportProgress, true)
            )}
          </Card>
        </View>
      )}

      {!props.menuOnly && (
        <TouchableOpacity
          ref={refIcon}
          style={getExcelIconContainerStyle()}
          onPress={() =>
            exportOnly ? showExportModal(importExportMenuType) : setShowOptions(true)
          }
        >
          <IconSVG size={getExcelIconStyle()} svgComponent={ExcelIcon} />
        </TouchableOpacity>
      )}

      {isShowMenu() && renderMenu()}
    </View>
  );
};

interface RenderOptionProps<T = boolean> {
  optionText: string;
  userAllowed: boolean;
  isImportOption?: T;
  action: T extends true ? (event: FileList | null) => void : () => void;
  spaceTop?: boolean;
  spaceBottom?: boolean;
  excelIcon?: boolean;
}
const RenderOption: React.FC<RenderOptionProps> = props => {
  const inputRef = useRef<HTMLInputElement>(null);

  const getContainerStyle = () => {
    
    

    return props.spaceTop
      ? Styles.cardMenuOptionWithTopSpace
      : props.spaceBottom
      ? Styles.cardMenuOptionWithBottomSpace
      : Styles.cardMenuOption;
  };

  const getOptionTextStyle = () => {
    return props.excelIcon ? Styles.cardMenuOnlyOptionText : Styles.cardMenuOptionText;
  };

  return (
    <TouchableOpacity
      style={[getContainerStyle(), { backgroundColor: Colors.Transparent }]}
      onPress={() => {
        if (!props.userAllowed) return;
        if (isImportOption(props)) {
          inputRef.current?.click();
        } else {
          (props as RenderOptionProps<false>).action();
        }
      }}
    >
      {isImportOption(props) && (
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          onChange={e => {
            e.preventDefault();
            props.action(e.target.files);
          }}
        />
      )}
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {props.excelIcon && (
          <IconSVG
            size={{ width: Spacings.Medium, height: Spacings.Medium }}
            svgComponent={ExcelIcon}
          />
        )}
        {props.userAllowed ? (
          <Text style={[getOptionTextStyle(), { color: Colors.Black }]}>{props.optionText}</Text>
        ) : (
          <TextWithTooltip
            text={props.optionText}
            tooltipText={t('alex:importExport.importDisabledMessage')}
            textStyle={props.excelIcon ? Styles.toolTipMenuOnlyText : Styles.toolTipText}
            style={{ flex: 1 }}
            toolTipContainerStyle={Styles.toolTipContainerStyle}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

function isImportOption(props: RenderOptionProps): props is RenderOptionProps<true> {
  return props.isImportOption ? true : false;
}

const InfoMessage: React.FC = () => {
  return (
    <View style={Styles.infoMessageContainer}>
      <IconSVG
        containerStyle={Styles.infoIconContainer}
        color={Colors.Grey}
        size={{ width: Spacings.Big, height: Spacings.Big }}
        svgComponent={InfoIcon}
      />
      <Text style={Styles.infoMessageText}>{t('alex:importExport.menu.infoMessage')}</Text>
    </View>
  );
};
