import { getCorrectedSpacings } from './Dimensions';


const Lato = {
  Regular: 'Lato-Regular',
  Black: 'Lato-Black',
  Medium: 'Lato-Medium',
  Bold: 'Lato-Bold',
  LatoWeb: 'Lato', 
  LatoItalic: 'Lato-Italic',
};

const fonts = {
  Lato: Lato,
  PermanentMarker: 'Permanent Marker',
};
export const fontSize = {
  XXXLarge: getCorrectedSpacings(26),
  XXLarge: getCorrectedSpacings(24),
  XLarge: getCorrectedSpacings(22),
  Large: getCorrectedSpacings(20),
  Big: getCorrectedSpacings(18),
  Medium: getCorrectedSpacings(16),
  Regular: getCorrectedSpacings(14),
  Standard: getCorrectedSpacings(10),
  Small: getCorrectedSpacings(12),
};

export default fonts;
