import { Platform, StyleSheet } from 'react-native';
import { TextFontStyles, Spacings, Colors, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  rootContainer: {
    zIndex: 999,
    justifyContent: 'flex-end',
    height: 64,
  },
  container: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.GreyLight,
    minWidth: 95,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingRight: Spacings.Unit,
    paddingBottom: Spacings.Small,
  },
  text: {
    marginLeft: 3,
    color: Colors.Black,
    paddingTop: 2,
    ...TextFontStyles.LatoRegular,
  },
  placeHolder: {
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
  },
  text2: {
    color: Colors.Black,
  },
  iconContainer: {
    marginLeft: -Spacings.Unit,
    marginBottom: -Spacings.Unit,
  },
  alignItems: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  calendarContainer: {
    position: 'absolute',
    top: 64,
    zIndex: 999,
  },
  webPlaceHolderFontSize: {
    fontSize: 12,
  },
  mobilePlaceHolderFontSize: {
    fontSize: 14,
  },
  webDateFontSize: {
    fontSize: 14,
  },
  mobileDateFontSize: {
    fontSize: 16,
  },
  arrowIconContainer: {
    marginBottom: -Spacings.Unit,
  },
});
export default styles;
