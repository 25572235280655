import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import Styles from './styles';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import { Colors, Spacings } from 'shared/styles';
import { FileViewerWeb, getFileTypeFromDataURL } from '../FileViewer';
import { isImage } from 'shared/util-ts/Functions';
import logger from 'shared/util/Logger';
import { FullScreenProof } from '../FileViewer/FullScreenProof';
import { Proof } from 'skillmgtweb/src/components/worker-skill-review-modal/Proof';
import FileSaver from 'file-saver';
import { Chapter } from 'shared/layout/summaryBook/SummaryBook';
import { PageType } from 'shared/layout/summaryBook/functions';

const AddIcon = require('shared/assets/svg/icon.plus.web.svg').default;
const TrashIcon = require('shared/assets/svg/icon.trash.svg').default;
const AlexIcon = require('shared/assets/svg/icon.alex.proof.svg').default;
const FileIcon = require('shared/assets/svg/icon.file.svg').default;
const CheckIcon = require('shared/assets/svg/icon.checked.small.svg').default;
const downloadIcon = require('shared/assets/svg/icon.download.svg').default;

interface Props {
  chapter: Chapter;
  isChapterContainsGhostTrainee?: boolean;

  handleProofs: (chapter: Chapter, files: File[]) => void;
  handleDeleteChoosenFiles: (chapter: Chapter) => void;
  handleChapterAddition: (chapter: Chapter) => void;
  handleAutoCertificate: (chapter: Chapter) => void;
}

export const AddProof: React.FC<Props> = props => {
  const {
    chapter,
    isChapterContainsGhostTrainee,

    handleProofs,
    handleDeleteChoosenFiles,
    handleChapterAddition,
    handleAutoCertificate,
  } = props;

  const scrollRef = useRef<ScrollView>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedFileToPreview, setSelectedFileToPreview] = useState<
    string | ArrayBuffer | null | undefined
  >();
  const [s3ObjectFileToPreview, setS3ObjectFileToPreview] = useState<API.S3ObjectWithLink>();
  const [showFullScreenProof, setShowFullScreenProof] = useState<boolean>(false);

  const [, setScrollViewWidth] = useState(0);

  useEffect(() => {
    handleDocumentsReader(chapter);

    return () => {
      setS3ObjectFileToPreview(undefined);
      setSelectedFileToPreview(undefined);
    };
  }, [chapter.file]);

  async function _handleAutoCertificate() {
    handleAutoCertificate(chapter);
  }

  function handleDocumentAddition() {
    inputRef.current?.click();
  }

  function handleDocumentDeletion() {
    handleDeleteChoosenFiles(chapter);
  }

  async function handleDocumentsReader(chapter: Chapter) {
    if (chapter.file && chapter.file[0]) {
      const file = chapter.file[0];
      const _isImage = isImage(file.key);

      const downloadLink = await API.getFileLink(file);
      if (API.isFailure(downloadLink)) {
        logger.error(downloadLink);
        return;
      }

      setS3ObjectFileToPreview({
        ...file,
        downloadLink,
        allowDownload: _isImage,
      });
    }
  }

  function renderPreviewDocs() {
    if (!selectedFileToPreview) return <View />;

    return typeof selectedFileToPreview === 'string' ? (
      <TouchableOpacity
        key={new Date().getTime()}
        style={[Styles.proofsPreviewScrollContentContainer]}
        onPress={() => setShowFullScreenProof(true)}
      >
        <FileViewerWeb
          filePath={selectedFileToPreview}
          fileType={getFileTypeFromDataURL(selectedFileToPreview)}
          onClick={() => setShowFullScreenProof(true)}
        />
      </TouchableOpacity>
    ) : null;
  }

  function renderS3ObjectFile() {
    if (!s3ObjectFileToPreview) return <View />;

    return (
      <TouchableOpacity style={Styles.proofsPreviewScrollContentContainer}>
        <Proof file={s3ObjectFileToPreview} />
      </TouchableOpacity>
    );
  }

  function handleInputFile(e: any) {
    if (!e.target.files) return;

    handleProofs(chapter, e.target.files);
  }

  function handleFullScreen() {
    setShowFullScreenProof(false);
  }

  function _handleChapterAddition() {
    handleChapterAddition(chapter);
  }

  function renderLeftPanel() {
    let isCommonProofForTraining = false;
    let isCommonProofForWorker = false;
    let isCommonProofForSkillGroup = false;
    if ((chapter?.skills || []).length === 1) {
      const skill = (chapter?.skills || [])[0];
      if (skill.skillIds?.length) {
        isCommonProofForSkillGroup = true;
      }
    } else if ((chapter?.skills || []).length > 1) {
      isCommonProofForWorker = true;
    }

    return (
      <ScrollView
        ref={scrollRef}
        style={[Styles.leftPanel]}
        contentContainerStyle={Styles.leftPanelContentContainer}
        horizontal
        scrollEnabled={false}
        showsHorizontalScrollIndicator={false}
        onLayout={event => {
          setScrollViewWidth(event.nativeEvent.layout.width);
        }}
      >
        {selectedFileToPreview || s3ObjectFileToPreview ? (
          <View style={Styles.leftPanelSecondPage}>
            <View style={[Styles.addRemoveProofHeader]}>
              <View>
                <Text style={Styles.commonProofLabel}>
                  {chapter?.isCommon ? t('alex:trainingSessions.addTrainingModal.commonProof') : ''}
                </Text>
              </View>
              <View style={[Styles.addRemoveProofContainer]}>
                {!chapter.isChapterSubmitted ? (
                  <TouchableOpacity style={Styles.circleView} onPress={_handleChapterAddition}>
                    <IconSVG
                      svgComponent={AddIcon}
                      size={{ width: Spacings.Standard, height: Spacings.Standard }}
                      color={Colors.White}
                    />
                  </TouchableOpacity>
                ) : null}
                {!chapter.isChapterSubmitted ? (
                  <TouchableOpacity style={Styles.roundTouchable} onPress={handleDocumentDeletion}>
                    <IconSVG
                      svgComponent={TrashIcon}
                      size={{ width: Spacings.Standard, height: Spacings.Standard }}
                      color={Colors.Grey}
                    />
                  </TouchableOpacity>
                ) : null}

                {s3ObjectFileToPreview && (
                  <TouchableOpacity
                    style={Styles.roundTouchable}
                    onPress={() => {
                      FileSaver.saveAs(
                        s3ObjectFileToPreview.downloadLink,
                        s3ObjectFileToPreview.fileName ?? undefined,
                      );
                    }}
                  >
                    <IconSVG
                      svgComponent={downloadIcon}
                      size={{ width: Spacings.Standard, height: Spacings.Standard }}
                      color={Colors.Grey}
                    />
                  </TouchableOpacity>
                )}
              </View>
            </View>
            {selectedFileToPreview && renderPreviewDocs()}
            {s3ObjectFileToPreview && renderS3ObjectFile()}
          </View>
        ) : (
          <View style={Styles.addProofContainer}>
            <View style={Styles.alexIcon}>
              <IconSVG svgComponent={AlexIcon} size={{ width: 88, height: 88 }} />
            </View>
            <View style={Styles.addCommonProofForWorkerSkillsContainer}>
              <Text style={Styles.addCommonProofForWorkerSkills}>
                {isCommonProofForSkillGroup
                  ? t('alex:trainingSessions.addTrainingModal.addCommonProofForSubSkills.0')
                  : isCommonProofForWorker
                  ? t('alex:trainingSessions.addTrainingModal.addCommonProofForWorkerSkills.0')
                  : t('alex:workerSkill.addProof')}
              </Text>
              <Text style={Styles.addCommonProofForWorkerDescription}>
                {isCommonProofForSkillGroup
                  ? t('alex:trainingSessions.addTrainingModal.addCommonProofForSubSkills.1')
                  : isCommonProofForWorker
                  ? t('alex:trainingSessions.addTrainingModal.addCommonProofForWorkerSkills.1')
                  : ''}
              </Text>
            </View>

            <View style={Styles.proofAddContainer}>
              <input
                hidden
                ref={inputRef}
                type="file"
                multiple
                onChange={handleInputFile}
                id="file-input"
              />
              <TouchableOpacity
                onPress={handleDocumentAddition}
                disabled={isChapterContainsGhostTrainee}
              >
                <View style={Styles.proofAddIconContainer}>
                  <IconSVG svgComponent={FileIcon} />
                  <Text style={Styles.proofAddText}>
                    {t('alex:trainingSessions.addTrainingModal.chooseFile')}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={Styles.proofAddIconContainer}
                onPress={_handleAutoCertificate}
                disabled={isChapterContainsGhostTrainee}
              >
                <IconSVG svgComponent={CheckIcon} />
                <Text style={Styles.proofAddText}>
                  {t('alex:trainingSessions.addTrainingModal.generateProof')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </ScrollView>
    );
  }

  return (
    <>
      {renderLeftPanel()}
      {showFullScreenProof && selectedFileToPreview && typeof selectedFileToPreview === 'string' ? (
        <FullScreenProof
          filePath={selectedFileToPreview}
          setShowModal={handleFullScreen}
          fileType={getFileTypeFromDataURL(selectedFileToPreview)}
        />
      ) : null}
    </>
  );
};
