import React, { useEffect, useState } from 'react';
import { View, InteractionManager, ScrollView } from 'react-native';
import { t } from 'shared/localisation/i18n';
import { InputText } from 'shared/ui-component/Input';
import { IconSVG } from 'shared/ui-component/Icon';
import { Checkbox } from 'shared/ui-component/Checkbox';
import {
  DropDownMultiSelection,
  DropDownOption,
  DropDownSingleSelection,
} from 'shared/ui-component/DropDown/DropDown';
import * as SharedStyles from 'shared/styles';
import { Styles } from './Styles';
import * as _ from 'lodash-es';
import { SkillCreateInputWithoutTrainingType } from './index';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { ModalAnchorContainerStyle, ModalInputWithAnchorStyle } from 'shared/styles/ModalStyles';
import {
  getSkillExpiryNoticeDurationLabel,
  getSkillExpiryNoticeDurationOptions,
  getSkillValidityDurationLabel,
  getSkillValidityDurationOptions,
} from 'shared/ui-component/DropDown/Data/DropDownData';
import { InputValidationType, useCustomForm } from 'shared/hooks/CustomForm';
import { isSkillGroup } from 'shared/backend-data';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { MyHub } from 'shared/util/MyHub';
import { SkillCard } from '../../../../../skill-card';

interface Props {
  skill: SkillCreateInputWithoutTrainingType;
  isPanelEditable?: boolean;

  setSkill: React.Dispatch<React.SetStateAction<SkillCreateInputWithoutTrainingType>>;
  onSubmitForm: () => void;
}

const clockSVG = require('shared/assets/svg/icon.clock.mobile.svg').default;
const skillIcon = require('shared/assets/svg/icon.addProof.mobile.svg').default;
const alertIcon = require('shared/assets/svg/icon.notif.mobile.svg').default;

const DEFAULT_DURATION = 24;
const DEFAULT_NOTICE = 30;

enum InputIds {
  SkillName = 'SkillName',
}

export const SkillInputPanel: React.FC<Props> = props => {
  const { skill, isPanelEditable, setSkill, onSubmitForm } = props;

  const isMounted = useIsMounted();

  const [skillOptions, setSkillOptions] = useState<DropDownOption[]>();
  const [subSkills, setSubSkills] = useState<DropDownOption[]>();
  const [validityAndExpiry, setValidityAndExpiry] =
    useState<API.SkillValidityDurationAndExpiryNoticeDuration>();

  const { errors, onBlur, onChange, navigateInputField, refs } = useCustomForm<string>(
    {
      [InputIds.SkillName]: {
        validator: [InputValidationType.NotEmpty],
        isFieldMandatory: true,
      },
    },
    onSubmitForm,
  );

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async payload => {
        if (payload.data.factory.dataType === API.DataType.SKILL) {
          await fetchSkillOptions();
        }
      });

      fetchSkillOptions();

      return () => {
        removeListener();
      };
    });
  }, [skill.id]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      fetchSkillOptions();
    });
  }, [skill.isPractical]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      
      if (
        isSkillGroup(skill) &&
        isSkillToBeCreated() &&
        (skill.validityDuration || skill.expiryNoticeDuration)
      )
        setSkill({
          ...skill,
          validityDuration: null,
          expiryNoticeDuration: null,
        });
    });
  }, [skill.skillIds]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (isSkillGroupOrSkillGroupEdit(skill)) {
        const _subSkills = await API.mapLimit(skill.skillIds, async skillId => {
          const _skill = await API.getSkill(skillId);
          if (API.isFailure(_skill)) return _skill;
          return { key: _skill.id, label: _skill.name, value: _skill };
        });
        if (!isMounted.current) return;
        if (API.isFailure(_subSkills)) return _subSkills;
        setSubSkills(_subSkills);
      } else {
        setSubSkills(undefined);
      }
    });
  }, [skill.skillIds]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const _validityAndExpiry = await API.getSkillValidityDurationAndExpiryNoticeDuration(
        skill as any as API.Skill,
      );
      if (!isMounted.current) return;
      setValidityAndExpiry(_validityAndExpiry);
    });
  }, [skill]);

  async function fetchSkillOptions() {
    const isPractical = skill.isPractical;

    const skills = await API.getSkills();
    if (!isMounted.current) return;
    if (API.isFailure(skills)) return skills;

    setSkillOptions(
      _.compact(
        skills.result.map(_skill => {
          if (skill.id && skill.id === _skill.id) return;

          if (_skill.isPractical == isPractical)
            return { key: _skill.id, label: _skill.name, value: _skill };
        }),
      ),
    );
  }

  function handleSubSkillsSelection(_subSkills?: DropDownOption[]) {
    setSkill({
      ...skill,
      skillIds: _subSkills ? _subSkills.map(_subSkill => _subSkill.key) : [],
    });
  }

  function handleSubSkillRemoval(skillId: string) {
    setSkill({
      ...skill,
      skillIds: skill.skillIds ? skill.skillIds.filter(_subSkillId => _subSkillId !== skillId) : [],
    });
  }

  function isSkillHasLimitedValidity(): boolean {
    return (
      validityAndExpiry?.validityDuration != null && validityAndExpiry.expiryNoticeDuration != null
    );
  }

  function isSkillToBeCreated(): boolean {
    return !skill.id;
  }

  function isValidityAndNoticeDisabled(): boolean {
    return !isPanelEditable || isSkillGroupOrSkillGroupEdit(skill);
  }

  function isSkillGroupCheckBoxDisabled(): boolean {
    return !isPanelEditable || (isSkillGroup(skill) && !isSkillToBeCreated());
  }

  function isDisplaySkillValidityAndExpiryNotice(): boolean {
    return !skill.skillIds || isSkillHasLimitedValidity();
  }

  function isSkillGroupOrSkillGroupEdit(
    skill: SkillCreateInputWithoutTrainingType,
  ): skill is API.Modify<SkillCreateInputWithoutTrainingType, { skillIds: string[] }> {
    return isSkillGroup(skill) || !!skill.skillIds;
  }

  return (
    <View style={[Styles.leftPanelOuterContainer]}>
      <View style={[ModalAnchorContainerStyle]}>
        <IconSVG
          svgComponent={skillIcon}
          containerStyle={Styles.skillIconInnerContainer}
          size={{ width: 28, height: 28 }}
          color={SharedStyles.Colors.White}
        />
      </View>

      <View style={[ModalInputWithAnchorStyle]}>
        <View style={[Styles.skillTextContainer]}>
          <InputText
            inputRef={refs[InputIds.SkillName]}
            inputId={InputIds.SkillName}
            errorMessage={errors[InputIds.SkillName]?.inputErrorMessage}
            style={Styles.skillInputTextStyle}
            placeHolder={t('alex:skills.enterSkillName')}
            defaultValue={skill.name}
            label={t('alex:skills.skillName')}
            notEditable={!isPanelEditable}
            onTextChange={(inputId, inputValue) => {
              setSkill({ ...skill, name: inputValue });
              onChange(inputId, inputValue);
            }}
            onSubmitEditing={(inputId, inputValue) => {
              setSkill({ ...skill, name: inputValue });
              navigateInputField(InputIds.SkillName);
            }}
            onBlur={onBlur}
          />
        </View>

        {(isSkillToBeCreated() || isSkillGroupOrSkillGroupEdit(skill)) && (
          <View style={[Styles.skillGroupRowContainer, { zIndex: 999 }]}>
            <View style={[Styles.skillGroupTextStyle]}>
              <Checkbox
                initialState={isSkillGroupOrSkillGroupEdit(skill)}
                style={Styles.leftPanelCheckboxContainer}
                disabled={isSkillGroupCheckBoxDisabled()}
                onChange={value => {
                  setSkill({
                    ...skill,
                    skillIds: value ? [] : undefined,
                    
                    
                    isPractical:
                      skill.isPractical === true || skill.isPractical === false
                        ? skill.isPractical
                        : false,
                  });
                }}
              />
              <EllipsisWithTooltip
                text={t('alex:skills.subSkills')}
                style={[Styles.expirationTextStyle, { maxWidth: '100%' }]}
                textStyle={Styles.durationValidityTextStyle}
              />
            </View>
            {!!skill.skillIds && (
              <DropDownMultiSelection
                additionForm
                showAdditionFormList={false}
                placeholder={t('alex:skills.pickASkill')}
                searchPlaceholder={t('alex:skills.pickASkill')}
                containerStyle={[{ width: '100%' }]}
                inputListContainerStyle={[Styles.trainingDropDownInputListContainer]}
                listIcon={skillIcon}
                values={subSkills}
                options={skillOptions}
                notEditable={!isPanelEditable}
                handleChange={(inputId, values) => handleSubSkillsSelection(values)}
              />
            )}
            <ScrollView
              style={Styles.subSkillSrollViewContainerStyle}
              contentContainerStyle={Styles.subSkillSrollViewContentContainerStyle}
              showsVerticalScrollIndicator
            >
              {skill.skillIds?.map(subSkillId => {
                return (
                  <SkillCard
                    containerStyle={Styles.skillCardContainerStyle}
                    deletable
                    skillOrSkillId={subSkillId}
                    onDelete={() => handleSubSkillRemoval(subSkillId)}
                  />
                );
              })}
            </ScrollView>
          </View>
        )}
        {isDisplaySkillValidityAndExpiryNotice() && (
          <>
            <View
              style={[
                Styles.expirationRowContainer,
                {
                  minHeight:
                    isSkillHasLimitedValidity() || isSkillGroupOrSkillGroupEdit(skill)
                      ? undefined
                      : 64,
                },
              ]}
            >
              <View style={[Styles.alignItems]}>
                <Checkbox
                  initialState={isSkillHasLimitedValidity()}
                  style={Styles.leftPanelCheckboxContainer}
                  disabled={isValidityAndNoticeDisabled()}
                  onChange={value =>
                    setSkill({
                      ...skill,
                      validityDuration: value ? DEFAULT_DURATION : null,
                      expiryNoticeDuration: value ? DEFAULT_NOTICE : null,
                    })
                  }
                />

                <EllipsisWithTooltip
                  text={t('alex:skills.validityPeriod')}
                  style={[Styles.expirationTextStyle]}
                  textStyle={Styles.durationValidityTextStyle}
                />
              </View>
              {isSkillHasLimitedValidity() && (
                <DropDownSingleSelection
                  title={t('common:time.duration')}
                  containerStyle={[Styles.expirationDropDownStyle]}
                  icon={clockSVG}
                  placeholder={t('common:time.month_plural')}
                  options={getSkillValidityDurationOptions()}
                  notEditable={isSkillGroupOrSkillGroupEdit(skill) || !isPanelEditable}
                  value={
                    validityAndExpiry?.validityDuration
                      ? {
                          key: validityAndExpiry.validityDuration.toString(),
                          label: getSkillValidityDurationLabel(validityAndExpiry.validityDuration),
                          value: validityAndExpiry.validityDuration,
                        }
                      : undefined
                  }
                  handleChange={(_, value) => {
                    if (!value) return;
                    setSkill({ ...skill, validityDuration: value.value });
                  }}
                />
              )}
            </View>

            {isSkillHasLimitedValidity() && (
              <View style={[Styles.notificationRowContainer]}>
                <View style={[Styles.alignItems]}>
                  <IconSVG
                    svgComponent={alertIcon}
                    size={{ width: 24, height: 24 }}
                    containerStyle={Styles.alertIconContainerStyle}
                  />
                  <EllipsisWithTooltip
                    text={t('alex:skills.alertBeforeExpiration')}
                    style={[Styles.notificationTextStyle]}
                    textStyle={Styles.durationValidityTextStyle}
                  />
                </View>
                <DropDownSingleSelection
                  title={t('common:time.duration')}
                  icon={clockSVG}
                  placeholder={t('common:time.day_plural')}
                  options={getSkillExpiryNoticeDurationOptions()}
                  notEditable={isSkillGroupOrSkillGroupEdit(skill) || !isPanelEditable}
                  value={
                    validityAndExpiry?.expiryNoticeDuration
                      ? {
                          key: validityAndExpiry.expiryNoticeDuration.toString(),
                          label: getSkillExpiryNoticeDurationLabel(
                            validityAndExpiry.expiryNoticeDuration,
                          ),
                          value: validityAndExpiry.expiryNoticeDuration,
                        }
                      : undefined
                  }
                  containerStyle={[Styles.notificationDropDownStyle]}
                  handleChange={(_, value) => {
                    if (!value) return;
                    setSkill({ ...skill, expiryNoticeDuration: value.value });
                  }}
                />
              </View>
            )}
          </>
        )}
      </View>
    </View>
  );
};
