import React, { useState } from 'react';
import { Text, View, TouchableOpacity, Keyboard } from 'react-native';
import { InputText, ParserType } from 'shared/ui-component/Input';
import { UserContext, CognitoAuthErrorCodes, Challenges } from 'shared/context/UserContext';
import { KeyboardAvoidingViewWrapper } from 'shared/ui-component/KeyboardAvoidingView';
import Styles from './Style';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { IconSVG } from 'shared/ui-component/Icon';
import arrowLeft from 'shared/assets/svg/icon.arrowLeft.mobile-2.svg';
import alexIcon from 'shared/assets/svg/icon.alex.svg';
import { YellowButton, TextButton } from 'shared/ui-component/Button';
import { Colors } from 'shared/styles/Colors';
import { t } from 'shared/localisation/i18n';
import { ModalUtils } from 'shared/ui-component/Modal';
import { InputsValue, useCustomForm, InputValidationType } from 'shared/hooks/CustomForm';

interface Props {
  username: string;
  isWebPlatfrom: boolean;
  handleBackPress: () => void;
  handleSignedInNavigation: () => Promise<void>;
  handleResetPasswordNavigation: () => void;
  handleInvitedUserResetPasswordNavigation: () => void;
}

enum InputIds {
  UserId = 'UserId',
  UserPassword = 'UserPassword',
}

export const LoginLayout: React.FC<Props> = props => {
  const [error, setError] = useState<string>();

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const { errors, enableFormSubmit, onBlur, onChange, onSubmit, navigateInputField, refs } =
    useCustomForm<string>(
      {
        [InputIds.UserId]: {
          validator: [
            InputValidationType.Email,
            InputValidationType.PhoneNumber,
            InputValidationType.WorkerPersonalIdPattern,
          ],
          initialValue: props.username,
          isFieldMandatory: true,
        },
        [InputIds.UserPassword]: {
          isFieldMandatory: true,
        },
      },
      onSubmitForm,
    );

  async function onSubmitForm(values: InputsValue<string>) {
    const userName = values[InputIds.UserId];
    const password = values[InputIds.UserPassword];

    if (!values[InputIds.UserPassword]) {
      logger.debug('password shall be set for logging-in');
      return;
    }

    Keyboard.dismiss();
    modal.displayLoader(ModalUtils.LoaderConfig({}));
    setError(undefined);
    const result = await UserContext.signIn(userName, password);
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      if (result.data.code === CognitoAuthErrorCodes.NotAuthorizedException) {
        if (
          result.data.message.includes(
            'Temporary password has expired and must be reset by an administrator', 
          )
        )
          setError(t('common:auth.login.password.expiredTemporaryPassword'));
        else setError(t('common:auth.login.password.incorrectPassword'));
      } else setError(result.data.message);
      modal.hideLoader();

      return;
    }

    if (result.challengeName && result.challengeName === Challenges.NEW_PASSWORD_REQUIRED) {
      props.handleInvitedUserResetPasswordNavigation();
      modal.hideLoader();
      return;
    }

    await props.handleSignedInNavigation();
    modal.hideLoader();
  }

  async function handleResetPassword() {
    modal.displayLoader(ModalUtils.LoaderConfig({}));
    const result = await UserContext.forgotPassword(props.username);
    modal.hideLoader();
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      if (
        result.data.code === CognitoAuthErrorCodes.NotAuthorizedException &&
        result.data.message.includes('User password cannot be reset in the current state') 
      )
        setError(t('common:auth.resetPassword.unauthorized'));
      else setError(result.data.message);

      return;
    }

    props.handleResetPasswordNavigation();
  }

  return (
    <KeyboardAvoidingViewWrapper style={Styles.appContainer}>
      <View style={Styles.rootContainer}>
        {props.isWebPlatfrom ? (
          <View style={Styles.topContainer}>
            <IconSVG
              svgComponent={alexIcon}
              size={{ height: 40, width: 40 }}
              color={Colors.Yellow}
              containerStyle={Styles.IconContainer}
            />
            <Text style={Styles.headerText}>{t('common:auth.login.header')}</Text>
          </View>
        ) : (
          <View style={Styles.topContainer}>
            <TouchableOpacity style={Styles.backButton} onPress={props.handleBackPress}>
              <IconSVG svgComponent={arrowLeft} color={Colors.Black} />
            </TouchableOpacity>
            <View style={Styles.headerTextContainer}>
              <Text style={Styles.headerText}>{t('common:auth.login.header')}</Text>
            </View>
          </View>
        )}
        <View style={Styles.middleContainer}>
          <InputText
            inputRef={refs[InputIds.UserId]}
            inputId={InputIds.UserId}
            placeHolder={t('common:account.email')}
            onTextChange={onChange}
            defaultValue={props.username}
            errorMessage={errors[InputIds.UserId]?.inputErrorMessage}
            onSubmitEditing={() => {
              navigateInputField(InputIds.UserPassword);
            }}
            parser={[ParserType.CaseInsensitive]}
          />
          <InputText
            autoFocus
            inputRef={refs[InputIds.UserPassword]}
            inputId={InputIds.UserPassword}
            containerStyle={Styles.passwordTextContainer}
            style={Styles.inputText}
            placeHolder={t('common:auth.login.password.input.placeholder')}
            secureTextEntry
            onTextChange={onChange}
            onSubmitEditing={onSubmit}
            onBlur={onBlur}
            errorMessage={errors[InputIds.UserPassword]?.inputErrorMessage}
          />
          {!!errors[InputIds.UserPassword]?.inputErrorMessage && (
            <View style={{ marginBottom: 20 }}></View>
          )}
          <TextButton
            text={t('common:auth.login.password.resetPassword')}
            textStyle={Styles.forgetPasswordText}
            onPress={handleResetPassword}
          />
          <Text style={Styles.errorMessage}>{error ?? ''}</Text>
        </View>
        <YellowButton
          style={enableFormSubmit ? Styles.connectButton : Styles.connectButtonDisabled}
          onPress={onSubmit}
          disabled={!enableFormSubmit}
          text={t('common:auth.login.submitButton')}
          textStyle={Styles.connectText}
        />
      </View>
    </KeyboardAvoidingViewWrapper>
  );
};
