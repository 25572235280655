import React, { ReactElement, useState } from 'react';
import { View, Text, StyleProp, ViewStyle, TextStyle } from 'react-native';
import styles from './webStyles';
import { useCallOnHover } from '../../hooks/CallOnHover';

interface ToolTipProps {
  title: string;
  style?: StyleProp<ViewStyle>;
  component: ReactElement;
  toolTipTextStyle?: StyleProp<TextStyle>;
  isInnerHover?: boolean;
  numberOfLines?: number;
  containerStyle?: StyleProp<ViewStyle>;
}

export const ToolTipWeb: React.FC<ToolTipProps> = props => {
  const {
    title,
    style,
    component,
    toolTipTextStyle,
    isInnerHover = false,
    numberOfLines,
    containerStyle,
  } = props;

  const [showToolTip, setShowToolTip] = useState<boolean>(false);

  const outerRef = useCallOnHover<View>(
    '',
    () => {
      if (!isInnerHover) setShowToolTip(true);
    },
    () => {
      if (!isInnerHover) setShowToolTip(false);
    },
  );

  const innerRef = useCallOnHover<View>(
    '',
    () => {
      if (isInnerHover) setShowToolTip(true);
    },
    () => {
      if (isInnerHover) setShowToolTip(false);
    },
  );

  return (
    <View ref={outerRef} style={containerStyle}>
      <View ref={innerRef}>{component}</View>
      {showToolTip && title && (
        <View style={[styles.rootContainer, style]}>
          <Text numberOfLines={numberOfLines} style={[styles.text, toolTipTextStyle]}>
            {title}
          </Text>
        </View>
      )}
    </View>
  );
};
