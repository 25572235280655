import { StyleSheet } from 'react-native';
import * as SharedStyles from 'shared/styles';

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center' },
  trainingSkillExpandCardContainerStyle: { marginRight: SharedStyles.Spacings.Unit * 3 },
  trashIconContainerStyle: { width: 24, height: 24 },
});

export default styles;
