import React, { useEffect, Component } from 'react';
import ReactDOM from 'react-dom';

/**
 * Hook used to call the callback function passed to it when
 * a click is detected outside the components with the attached refrence
 * @param callback 
 */
export function useDetectOutsideClick<T extends Component>(callback: Function) {
  const node = React.createRef<T>();

  const handleClick = (event: MouseEvent) => {
    if (node.current) {
      if (ReactDOM.findDOMNode(node.current)?.contains(event.target as Node)) {
        
        return;
      }
      
      callback();
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', event => handleClick(event));

    return () => {
      document.removeEventListener('mousedown', event => handleClick(event));
    };
  });
  return node;
}
