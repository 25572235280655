import { MyHub } from 'shared/util/MyHub';
import { DataType, Failure } from '../../backend-data';
import * as API from 'shared/backend-data';
import logger from '../../util/Logger';
import { isStringArray } from '../../util-ts/Functions';

/**
 * Dispatch 'DependencyVeto' event
 * that will show a <DependencyVetoModalContent> with a message and a list of dependencies (Factory objects)
 * @param message
 * @param failure Failure, if it contains { data: { dependencyIds: string[] } } it will populate the list of dependencies
 * @param vetoAcceptCallback
 * @param dataType
 * @param disableRedirection
 */
export function showVetoModal( 
  message: string,
  failure: Failure,
  vetoAcceptCallback?: () => void,
  dataType?: DataType, 
  disableRedirection?: boolean,
  forceDelete?: boolean,
) {
  let dependencyIds: string[] = [];

  if (failure.type === 'MultipleFailures') {
    for (const _failure of failure.data) {
      const ids = extractFailureDependencyIds(_failure);
      if (ids) {
        dependencyIds.push(...ids);
      }
    }
  } else {
    const ids = extractFailureDependencyIds(failure);
    if (ids) {
      dependencyIds.push(...ids);
    }
  }

  if (dataType) {
    const dependencyIdsForSpecificType: string[] = [];
    dependencyIds.forEach(dependencyId => {
      const _dataType = API.getDataType(dependencyId);
      if (API.isFailure(_dataType)) {
        logger.warn(_dataType);
        return;
      }
      if (_dataType === dataType) return dependencyIdsForSpecificType.push(dependencyId);
    });
    dependencyIds = dependencyIdsForSpecificType;
  }

  
  MyHub.dispatchError('DependencyVeto', {
    dependencyIds: dependencyIds,
    vetoAcceptCallback: vetoAcceptCallback,
    disableRedirection: disableRedirection,
    message: message,
    forceDelete: forceDelete,
  });
}

/**
 * Extract the 'dependencyIds' property if it exists
 * @param failure
 * @returns
 */
function extractFailureDependencyIds(failure: Failure): string[] | undefined {
  if ((failure.data as any)?.dependencyIds) {
    const array = (failure.data as any)?.dependencyIds;
    if (isStringArray(array)) {
      return array;
    }
  }
}
