import React, { useContext, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { TableColumn, TableLargeColumnWidth } from 'shared/ui-component/Table';
import { Table } from 'shared/ui-component/Table';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { EllipsisWithTooltip, Position } from 'shared/ui-component/EllipsisWithTooltip';
import { capitalize, t } from 'shared/localisation/i18n';
import { WorkerState, sortWorkerCompare } from 'shared/backend-data';
import * as _ from 'lodash-es';
import { Colors } from 'shared/styles';
import { IconSVG } from 'shared/ui-component/Icon';
import * as API from 'shared/backend-data';
import { SkillWorkerTableRow } from 'shared/util/Skill';
import styles from './style';
import { SkillBadge } from 'shared/ui-component/Badge/SkillBadge';
import { SkillBadgeStates } from 'shared/util/Skill';
import { SortDirection } from '../../../../../sort';
import { ModalUtils } from 'shared/ui-component/Modal';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as SharedStyles from 'shared/styles';
import { MenuWidth } from 'shared/ui-component/Menu';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { WorkerSkillAddModal } from '../../../../../add-workerskill-modal/container';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { DropdownConfigKey } from '../../../../../header-layout/headerFilterConfig';
import {
  getExportDataForWorkersInSkillScreen,
  ImportExportFileNames,
} from 'shared/util/ExcelUtils';
import { ImportExportType } from 'shared/util/ExcelUtils';
import Aigle from 'aigle';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { Immutable, isArrayEmpty } from 'shared/util-ts/Functions';
import { downloadfiles } from 'sharedweb/src/util-ts/S3Upload';
import { getValidityColor } from 'shared/util/skillUi';
import { ProofBookContainerWeb } from '../../../../../training/proof-book';

interface SkillWorkerTable {
  skill: API.Skill;
  rows: SkillWorkerTableRow[];
  filterTags: TagExtended[];
  workerNotHavingSkill: SkillWorkerTableRow[];
  fetchWorkerSkill: (skillId: string) => void;
}

const EyeIcon = require('shared/assets/svg/icon.eye.svg').default;

export const SkillWorkerTableComponent: React.FC<SkillWorkerTable> = props => {
  const { skill, rows, filterTags, workerNotHavingSkill } = props;

  const [showSkillNotAcquired, setShowSkillNotAcquired] = useState<boolean>();
  const [showWorkerSkillReviewModal, setShowWorkerSkillReviewModal] = useState<boolean>(false);
  const [proofBundle, setProofBundle] = useState<API.NoMetadata<API.ProofBundle> | null>();
  const [isShowAddWorkerSkillModal, setIsShowAddWorkerSkillModal] = useState(false);
  const [proofBookSkillIds, setProofBookSkillIds] = useState<string[]>();
  const [proofBookWorkerIds, setProofBookWorkerIds] = useState<string[]>();
  const [showProofBook, setShowProofBook] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<API.SkillWorkerTableRow>();

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();
  const { isWorkerLastNameFirst } = useContext(GlobalDataContext);

  const { isValidPermission } = useContext(PermissionManagementContext);

  function onPressWorkerSkillReview(row: SkillWorkerTableRow) {
    const proofBundle: API.NoMetadata<API.ProofBundle> | null | undefined =
      row.skillData?.toReviewProofBundle ?? row.skillData?.activeProofBundle;
    setProofBundle(proofBundle);
    setSelectedRow(row);

    setShowWorkerSkillReviewModal(true);
  }

  const columnDescriptors: TableColumn<SkillWorkerTableRow>[] = [
    {
      label: 'common:account.name',
      width: TableLargeColumnWidth,
      renderCell: (eachWorker, index, indexOfRow) => {
        return (
          <TouchableOpacity
            style={styles.eachColumn}
            onPress={() => onPressWorkerSkillReview(eachWorker)}
            disabled={!isValidPermission(API.Permission.workersDetail_view)}
          >
            {renderImage(
              eachWorker.worker.profilePicture,
              ImageSizes.Medium,
              eachWorker.worker.name,
            )}
            <SkillBadge badgeValue={SkillBadgeStates.OK} style={styles.collabBadge} />
            <EllipsisWithTooltip
              style={styles.ellipsisText}
              text={`${capitalize(eachWorker.worker.name)}`}
              textStyle={
                eachWorker.worker.state === WorkerState.ARCHIVED
                  ? styles.disabled
                  : SharedStyles.Styles.tableText
              }
              type={'name'}
              position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
            />
          </TouchableOpacity>
        );
      },
      sort: sortByName,
    },
    {
      label: t('alex:skills.skillWorkerTable.header.0'),
      sort: sortByValidity,
      width: TableLargeColumnWidth,
      renderCell: (eachWorker, index, indexOfRow) => (
        <TouchableOpacity
          style={styles.eachColumn}
          onPress={() => onPressWorkerSkillReview(eachWorker)}
        >
          {eachWorker.skillData && (
            <View style={{ flexDirection: 'row' }}>
              <EllipsisWithTooltip
                style={[{ width: 392, marginTop: SharedStyles.Spacings.xMedium }]}
                text={eachWorker.skillData.stateString}
                textStyle={[
                  SharedStyles.Styles.tableText,
                  { color: getValidityColor(eachWorker.skillData.validity, Colors.MediumGray) },
                ]}
                position={indexOfRow === rows.length - 1 ? Position.MIDDLE : Position.BOTTOM}
              />
              <IconSVG svgComponent={EyeIcon} containerStyle={{ width: 60 }} color={Colors.Grey} />
            </View>
          )}
        </TouchableOpacity>
      ),
    },
  ];

  function sortByName(rows: SkillWorkerTableRow[], sortDirection: SortDirection) {
    return rows.sort((a, b) =>
      sortWorkerCompare(a.worker, b.worker, sortDirection, isWorkerLastNameFirst),
    );
  }

  function sortByValidity(
    rows: SkillWorkerTableRow[],
    sortDirection: SortDirection,
  ): SkillWorkerTableRow[] {
    
    return rows.slice().sort((a, b) => {
      if (!a.skillData && !b.skillData) return 0;
      if (!a.skillData) return sortDirection === SortDirection.asc ? -1 : 1;
      if (!b.skillData) return sortDirection === SortDirection.asc ? 1 : -1;

      const result = API.compareWorkerSkillValidity(a.skillData, b.skillData);
      return sortDirection === SortDirection.asc ? result : -result;
    });
  }

  async function downloadProofBundle(
    activeProofBundle: Immutable<API.NoMetadata<API.ProofBundle>>,
  ) {
    const _files = await downloadfiles(activeProofBundle);
    if (!isMounted.current) return;
    if (API.isFailure(_files)) {
      logger.warn(_files);
      return;
    }
  }

  async function deleteWorkerSkill(workerSkill: API.WorkerSkill) {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:skills.skillWorkerTable.deleteSkillWarning'),
        warningAcceptButton: t('common:button.yes'),
        warningCancelButton: t('common:button.no'),
        warningAcceptCallback: async () => {
          const deleteSkill = await API.deleteFactoryBusinessObject(workerSkill.id);
          if (!isMounted.current) return;
          if (API.isFailure(deleteSkill)) {
            logger.warn(deleteSkill);
          }
        },
      }),
    );
  }

  function onMenuPress(row: SkillWorkerTableRow, key: 'proof' | 'downloadProof' | 'deleteSkill') {
    const proofBundle: API.NoMetadata<API.ProofBundle> | null | undefined =
      row.skillData?.toReviewProofBundle ?? row.skillData?.activeProofBundle;
    setProofBundle(proofBundle);

    switch (key) {
      case 'proof':
        setShowWorkerSkillReviewModal(true);
        break;
      case 'downloadProof':
        if (row.skillData?.activeProofBundle) downloadProofBundle(row.skillData?.activeProofBundle);
        break;
      case 'deleteSkill':
        if (row.skillData) deleteWorkerSkill(row.skillData);
        break;
    }
  }

  async function filterData(
    isKeyWordFiltering: boolean,
    filterTags: TagExtended[],
    skillWorkers: SkillWorkerTableRow[],
  ): Promise<SkillWorkerTableRow[]> {
    if (isKeyWordFiltering && isArrayEmpty(filterTags)) return [];

    let _filteredSkills: SkillWorkerTableRow[] = isKeyWordFiltering ? [] : skillWorkers;

    const shiftFilters: TagExtended[] = [];
    const orgUnitFilters: TagExtended[] = [];
    const workerFilters: TagExtended[] = [];

    _.forEach(filterTags, tag => {
      if (tag.type === DropdownConfigKey.ORGUNIT) orgUnitFilters.push(tag);
      if (tag.type === DropdownConfigKey.SHIFT) shiftFilters.push(tag);
      else if (tag.type === DropdownConfigKey.WORKER) workerFilters.push(tag);
    });

    if (shiftFilters.length) {
      await Aigle.map(shiftFilters, async eachFilter => {
        const workersInShift = await API.getWorkersInShift(
          eachFilter.value.shiftId,
          eachFilter.value.parentId,
        );
        if (!isMounted.current) return;
        if (API.isFailure(workersInShift)) {
          logger.warn(workersInShift);
          return;
        }

        workersInShift.forEach(worker => {
          workerFilters.push({
            key: worker.id,
            label: '',
          });
        });
      });

      if (isArrayEmpty(workerFilters)) return [];
    }

    if (orgUnitFilters.length) {
      const data = _.filter(isKeyWordFiltering ? skillWorkers : _filteredSkills, row =>
        _.some(orgUnitFilters, filter => {
          if (row.orgUnits === undefined) return true;
          const orgUnitIds = row.orgUnits.map(orgUnit => orgUnit.id);
          return orgUnitIds.includes(filter.key);
        }),
      );

      if (isKeyWordFiltering) {
        _filteredSkills = [..._filteredSkills, ...data];
      } else {
        _filteredSkills = data;
      }
    }

    if (workerFilters.length) {
      const data = _.filter(isKeyWordFiltering ? skillWorkers : _filteredSkills, row =>
        _.some(workerFilters, filter => filter.key === row.worker.id),
      );

      if (isKeyWordFiltering) {
        _filteredSkills = [..._filteredSkills, ...data];
      } else {
        _filteredSkills = data;
      }
    }

    return _.uniqBy(_filteredSkills, worker => worker.worker.id);
  }

  async function filterRows(
    rows: SkillWorkerTableRow[],
    tags: TagExtended[],
  ): Promise<SkillWorkerTableRow[]> {
    if (tags.find(tag => tag.type === DropdownConfigKey.SKILL_WORKER_OTHER_FILTER)) {
      tags = tags.filter(tag => tag.type === DropdownConfigKey.SKILL_WORKER_OTHER_FILTER);
      setShowSkillNotAcquired(true);
    } else {
      setShowSkillNotAcquired(false);
    }

    let _filteredRows: SkillWorkerTableRow[] = rows;
    let _filterTags: TagExtended[] = [];
    let _keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(tags, tag => {
      if (tag.isActiveBookmarkTag) {
        _filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        _keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        _filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      _filteredRows = await filterData(true, _keywordTags, _filteredRows);
    }

    if (_filterTags.length) {
      _filteredRows = await filterData(false, _filterTags, _filteredRows);
    }

    return _filteredRows;
  }

  async function fetchRowDetails(
    row: SkillWorkerTableRow,
    isRowMounted: React.MutableRefObject<boolean>,
  ): Promise<boolean> {
    let updated = false;

    if (row.orgUnits === undefined) {
      const orgUnits = await API.getWorkerOrganizationalUnits(row.worker.id);
      if (!isRowMounted.current) return false;
      if (API.isFailure(orgUnits)) {
        logger.warn(orgUnits);
      } else {
        row.orgUnits = orgUnits;
      }
      updated = true;
    }

    return updated;
  }

  function handleOpenProofBook(workerIds: string[], skillIds: string[]) {
    setProofBookWorkerIds(workerIds);
    setProofBookSkillIds(skillIds);

    setShowProofBook(true);
    setIsShowAddWorkerSkillModal(false);
  }

  return (
    <View style={{ width: '100%' }}>
      <Table
        columnDescriptors={columnDescriptors}
        defaultSortColumn={[0, SortDirection.asc]}
        rows={showSkillNotAcquired ? workerNotHavingSkill : rows}
        filter={{
          filterRows: filterRows,
          tags: filterTags,
        }}
        noDataMessage={t('alex:table.noWorkerHasSkill')}
        rowLazyLoadProperties={fetchRowDetails}
        onRowPress={row => onPressWorkerSkillReview(row)}
        rowMenuWidth={MenuWidth.Medium}
        disableRowClick={!isValidPermission(API.Permission.workersDetail_view)}
        plusMenuWidth={MenuWidth.Large}
        importExport={{
          excelIcon: true,
          exportOnly: true,
          getExportData: getExportDataForWorkersInSkillScreen,
          refreshData: async () => {}, 
          importExportType: ImportExportType.WorkerProfileSkill,
          importExportFileName: ImportExportFileNames.WorkerProfileSkill,
          showImportExport: isValidPermission(API.Permission.workersDetail_view),
        }}
        showAddButton
        rowMenu={row => {
          const menuItems = [];
          menuItems.push({
            label: 'alex:skills.skillWorkerTable.skillWorkerTableRowMenu.0',
            onPress: () => onMenuPress(row, 'proof'),
          });

          menuItems.push({
            label: 'alex:skills.skillWorkerTable.skillWorkerTableRowMenu.1',
            onPress: () => onMenuPress(row, 'downloadProof'),
            disable: !row.skillData?.activeProofBundle,
          });

          if (
            row.skillData?.validity === API.Validity.KO_EXPIRED ||
            row.skillData?.activeProofBundle?.review?.state === API.ReviewState.REJECTED ||
            row.skillData?.activeProofBundle?.review?.state === API.ReviewState.REJECTED_TO_RESUBMIT
          )
            menuItems.push({
              label: 'alex:skills.skillWorkerTable.skillWorkerTableRowMenu.2',
              onPress: () => onMenuPress(row, 'deleteSkill'),
              disable: !row.skillData || !isValidPermission(API.Permission.workersSkillsProof_edit),
            });
          return menuItems;
        }}
        onPlusPress={() => setIsShowAddWorkerSkillModal(true)}
      />
      {showWorkerSkillReviewModal && proofBundle && (
        <ProofBookContainerWeb
          proofBundleId={proofBundle.id}
          setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
        />
      )}
      {showWorkerSkillReviewModal && selectedRow && skill.skillIds?.length && (
        <ProofBookContainerWeb
          setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
          workerIds={[selectedRow?.worker.id]}
          skillIds={skill.skillIds as string[]}
          proofAlreadyValidatedWorkersAndSkills={{
            workerIds: [selectedRow.worker.id],
            skillIds: skill.skillIds as string[],
          }}
        />
      )}
      {isShowAddWorkerSkillModal && (
        <WorkerSkillAddModal
          selectedSkills={[skill]}
          refreshSkills={async () => {}}
          handleModalClose={() => setIsShowAddWorkerSkillModal(false)}
          openProofBook={handleOpenProofBook}
        />
      )}
      {proofBookSkillIds && proofBookWorkerIds && showProofBook && (
        <ProofBookContainerWeb
          workerIds={proofBookWorkerIds}
          skillIds={proofBookSkillIds}
          setShowAddTrainingProofModal={setShowProofBook}
          allowPartialSubmission={false}
        />
      )}
    </View>
  );
};
