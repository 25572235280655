import React, { useState } from 'react';
import { View, Text, StyleProp, ViewStyle } from 'react-native';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import * as API from 'shared/backend-data';
import { styles } from './styles';
import { TextHighLight } from 'shared/ui-component/TextHighLight';
import { OutlineButton, TextButton } from 'shared/ui-component/Button';
import { UnderLinedTextOnHover } from 'shared/ui-component/TextUnderLineOnHover';
import * as SharedStyles from 'shared/styles';
import { useHistory } from 'react-router-dom';
import { RouteLocations } from '../../../navigation/Routes';
import { isUser, isActiveUser, isUserNameEmpty } from 'shared/util/Worker';
import { Avatar } from 'shared/ui-component/Avatar';

export interface WorkerOrgUnitRolesInfo {
  worker: API.Worker;
  organizationalUnits: string;
  roles: string;
  authoizationsCount: number;
}

interface Props {
  index: number;
  item: WorkerOrgUnitRolesInfo;
  removeUserAccess: (worker: API.Worker) => void;
  giveUserAccess: (worker: API.Worker) => void;
  handleModalClose: () => void;
  searchedInput: string | undefined;
  containerStyle?: StyleProp<ViewStyle>;
}

export const UserInfoComponent: React.FC<Props> = props => {
  const {
    item: workerOrgUnitRoles,
    giveUserAccess,
    removeUserAccess,
    handleModalClose,
    index,
  } = props;
  const [isHover, setIsHover] = useState(false);
  const history = useHistory();

  return (
    <View
      style={[
        styles.mainContainer,
        {
          backgroundColor: isHover ? SharedStyles.Colors.GreyUltraLight : SharedStyles.Colors.White,
          zIndex: -index,
        },
      ]}
      key={workerOrgUnitRoles.worker.id}
      ref={useCallOnHover<View>(
        undefined,
        () => {
          setIsHover(true);
        },
        () => {
          setIsHover(false);
        },
      )}
    >
      <View style={props.containerStyle ?? styles.row}>
        <View style={styles.profilePictureContainer}>
          {
            <Avatar
              name={workerOrgUnitRoles.worker.name}
              size={32}
              imgS3Key={workerOrgUnitRoles.worker.profilePicture}
            />
          }
        </View>
        <View style={styles.userNameContainer}>
          <UnderLinedTextOnHover
            text={
              isUserNameEmpty(workerOrgUnitRoles.worker)
                ? t('alex:inviteWorker.newlyAddedWorkerName')
                : capitalizeFirstLetter(workerOrgUnitRoles.worker.name)
            }
            highlightedString={props.searchedInput}
            onPress={() => {
              handleModalClose();
              history.push(RouteLocations.WorkerProfile(workerOrgUnitRoles.worker.id));
            }}
            textStyle={styles.userName}
          />
          {workerOrgUnitRoles.worker.email && (
            <TextHighLight
              text={workerOrgUnitRoles.worker.email}
              highlightedString={props.searchedInput}
              style={styles.userEmail}
            />
          )}
        </View>
        <View style={styles.userOrganizationalUnitRoleContainer}>
          <TextHighLight
            text={workerOrgUnitRoles.organizationalUnits}
            highlightedString={props.searchedInput}
            style={styles.userOrganizationalUnitsText}
          />
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.userRolesText}>{workerOrgUnitRoles.roles}</Text>
            <View style={styles.pointContainer}>
              <View style={styles.point} />
            </View>
            <Text style={styles.permissionText}>
              {workerOrgUnitRoles.authoizationsCount +
                ' ' +
                t(
                  'glossary:workerPermission',
                  { count: workerOrgUnitRoles.authoizationsCount },
                  false,
                )}
            </Text>
          </View>
        </View>
        {isUser(workerOrgUnitRoles.worker) ? (
          isActiveUser(workerOrgUnitRoles.worker) ? (
            isHover && (
              <TextButton
                text={t('alex:inviteWorker.removeAccess')}
                onPress={() => removeUserAccess(workerOrgUnitRoles.worker)}
                containerStyle={styles.removeAccessTextContainer}
              />
            )
          ) : (
            <View style={styles.rightTextContainer}>
              <Text style={styles.userDidntRespondText} numberOfLines={1}>
                {t('alex:inviteWorker.notAcceptingInvitationtext')}
              </Text>
              <View style={styles.userDidntRespondButtons}>
                <TextButton
                  text={t('alex:inviteWorker.sendInvitationAgainText')}
                  onPress={() => giveUserAccess(workerOrgUnitRoles.worker)}
                  textStyle={styles.sendInvitationAgainText}
                />
                <TextButton
                  text={t('common:button.cancelInvitation')}
                  onPress={() => removeUserAccess(workerOrgUnitRoles.worker)}
                  textStyle={styles.cancelInvitationText}
                />
              </View>
            </View>
          )
        ) : (
          <View style={styles.rightButtonContainer}>
            <OutlineButton
              style={styles.rightButton}
              textStyle={styles.inviteButtonText}
              onPress={() => giveUserAccess(workerOrgUnitRoles.worker)}
              text={t('common:button.invite')}
            />
          </View>
        )}
      </View>
    </View>
  );
};
