import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import closeButtonIcon from 'shared/assets/close.png';
import Style from './Style';

interface Props {
  tagValue: string;
  tagCloseHandler: any;
}

export const Tag: React.FC<Props> = props => {
  const [showTag, handleTagDisplay] = useState(true);

  let tagInput: any = null;

  const handleSubmit = () => {
    handleTagDisplay(true);
    tagInput.clear();
  };

  const handleTagClose = () => {
    handleTagDisplay(false);
    props.tagCloseHandler();
  };

  return (
    <View style={Style.container}>
      {showTag && (
        <TouchableOpacity onPress={() => handleTagClose()} style={Style.tagButton}>
          <View style={Style.tagLabelContainer}>
            <Text style={Style.tagLabel}>{props.tagValue}</Text>
          </View>
          <View style={Style.closeButtonContainer}>
            <Image source={closeButtonIcon} style={Style.closeButtonIcon} />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

