import React, { useState, useEffect, useContext, useCallback } from 'react';
import { SkillValidatePanelComponent } from '../component';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { InteractionManager } from 'react-native';
import { MyHub } from 'shared/util/MyHub';
import { DashboardFilterContext } from 'shared/context/dashboard-context/DashboardFilterContext';
import * as _ from 'lodash-es';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import moment from 'moment';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import Aigle from 'aigle';
import { TagExtended } from 'sharedweb/src/Filter/container';

export interface ProofBundleItem {
  worker: API.Worker;
  skill: API.Skill;
  reSubmitionWorker?: API.Worker;
  proofBundles: API.ProofBundle[];
  trainingSession?: API.TrainingSessionWithDetail;
  training?: API.Training;
  trainingVersion?: API.TrainingVersion;
  trainingVersionSkillIds?: string[];
}

interface Props {
  computeToDoList: boolean;
}

export const SkillValidatePanelContainer: React.FC<Props> = props => {
  const { computeToDoList } = props;

  const [proofBundleItemsToValidateAndReSubmit, setProofBundleItemsToValidateAndReSubmit] =
    useState<API.ProofBundle[]>();
  const [
    filteredProofBundleItemsToValidateAndReSubmit,
    setFilteredProofBundleItemsToValidateAndReSubmit,
  ] = useState<ProofBundleItem[]>();
  const [lastProofBundleSeenDate, setLastProofBundleSeenDate] = useState<string>();
  const [loader, setLoader] = useState<boolean>(false);
  const [allWorkers, setAllWorkers] = useState<API.Worker[]>();
  const [toDoListWorkers, setToDoListWorkers] = useState<API.Worker[]>();

  const filterWorkerSkillsToValidateDebounce = useCallback(
    _.debounce(filterWorkerSkillsToValidate, 1000, { leading: false, trailing: true }),
    [],
  );

  const { isValidPermission } = useContext(PermissionManagementContext);
  const {
    workers: [workers],
  } = useContext(DashboardFilterContext);
  const {
    homeScreenFilterTags: [homeScreenFilterTags],
  } = useContext(HeaderFilterContext);

  const isMounted = useIsMounted();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', async payload => {
      switch (true) {
        case payload.data.factory.dataType === API.DataType.WORKER:
          await fetchAllWorkers();
          if (!isMounted.current) return;
          break;
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const _lastProofBundleSeenDate = await API.getUserPreference<string>(
        UserPreferenceKeys_SkillMgtApp.ProofBundleLastUpdatedDate,
      );
      if (!isMounted.current) return;
      if (API.isFailure(_lastProofBundleSeenDate)) {
        logger.warn(_lastProofBundleSeenDate);
        return;
      }

      setLastProofBundleSeenDate(_lastProofBundleSeenDate);

      await fetchAllWorkers();
    });

    return () => {
      removeListener();
      saveProofBundleLastSeenDate();
    };
  }, []);

  useEffect(() => {
    getWorkersForToDoList();
  }, [homeScreenFilterTags, workers, allWorkers]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.PROOFBUNDLE ||
        data.factory.dataType === API.DataType.WORKERSKILL
      )
        fetchWorkersSkillsToValidate();
    });

    return () => {
      removeListener();
    };
  }, [toDoListWorkers]);

  useEffect(() => {
    if (computeToDoList) fetchWorkersSkillsToValidate();
  }, [toDoListWorkers, computeToDoList]);

  useEffect(() => {
    filterWorkerSkillsToValidateDebounce(proofBundleItemsToValidateAndReSubmit, toDoListWorkers);
  }, [toDoListWorkers, proofBundleItemsToValidateAndReSubmit]);

  async function fetchAllWorkers() {
    const _allWorkers = await API.getWorkers();
    if (!isMounted.current) return;
    if (API.isFailure(_allWorkers)) return _allWorkers;

    setAllWorkers(_allWorkers.result);
  }

  async function getWorker(tag: TagExtended): Promise<API.Result<API.Worker | undefined>> {
    if (tag.type === API.DataType.WORKER) {
      const worker = await API.getWorker(tag.key);
      if (!isMounted.current) return;
      if (API.isFailure(worker)) {
        logger.warn('Error while fetching the worker', worker);
      } else {
        return worker;
      }
    }
  }

  async function getWorkersForToDoList() {
    const filterTags = homeScreenFilterTags
      .filter(tag => !tag.isCheckBoxTag)
      .filter(tag => tag.order !== undefined || tag.isActiveBookmarkTag);

    if (filterTags.length) {
      const workersInFilterTag: API.Worker[] = [];

      
      
      await Aigle.mapSeries(filterTags, async tag => {
        if (tag.isActiveBookmarkTag && tag.children) {
          await Aigle.mapSeries(tag.children, async tag => {
            const worker = await getWorker(tag);
            if (!isMounted.current) return;
            if (API.isFailure(worker)) {
              logger.warn(worker);
            } else if (worker) {
              workersInFilterTag.push(worker);
            }
          });
        }
        const worker = await getWorker(tag);
        if (!isMounted.current) return;
        if (API.isFailure(worker)) {
          logger.warn(worker);
        } else if (worker) {
          workersInFilterTag.push(worker);
        }
      });

      setToDoListWorkers([...(workers ?? []), ...workersInFilterTag]);
    } else {
      setToDoListWorkers(allWorkers);
    }
  }

  async function saveProofBundleLastSeenDate() {
    const userPreference = await API.saveUserPreference(
      UserPreferenceKeys_SkillMgtApp.ProofBundleLastUpdatedDate,
      moment().toISOString(),
    );
    if (API.isFailure(userPreference)) return;
  }

  async function fetchWorkersSkillsToValidate() {
    if (!isValidPermission(API.Permission.workersSkillProof_review)) return;

    setLoader(true);

    const toReviewProofBundles = await API.getProofBundlesByReviewStateAndOriginObject(
      API.ReviewState.TO_REVIEW,
      undefined,
    );
    if (!isMounted.current) return;
    if (API.isFailure(toReviewProofBundles)) {
      setLoader(false);
      logger.warn(toReviewProofBundles);
      return;
    }

    const proofBundlesToReviewAndResubmit = await API.getRejectToResubmitProofBundles();
    if (!isMounted.current) return;
    if (API.isFailure(proofBundlesToReviewAndResubmit)) {
      setLoader(false);
      logger.warn(proofBundlesToReviewAndResubmit);
      return;
    }

    setProofBundleItemsToValidateAndReSubmit([
      ...(proofBundlesToReviewAndResubmit ?? []),
      ...toReviewProofBundles,
    ]);
    setLoader(false);
  }

  async function filterWorkerSkillsToValidate(
    proofBundleItemsToValidateAndReSubmit: API.ProofBundle[] | undefined,
    toDoListWorkers: API.Worker[] | undefined,
  ) {
    if (!proofBundleItemsToValidateAndReSubmit) return;

    setLoader(true);
    const _proofBundleItemsToValidate: ProofBundleItem[] = [];
    await Aigle.mapSeries(proofBundleItemsToValidateAndReSubmit, async _proofBundle => {
      const _skill = await API.getSkill(_proofBundle.skillId);
      if (!isMounted.current) return;
      if (API.isFailure(_skill)) {
        setLoader(false);
        logger.warn(_skill);
        return;
      }

      const skillGroups = await API.getSkillGroupsForASkill(_proofBundle.skillId);
      if (API.isFailure(skillGroups)) {
        setLoader(false);
        logger.warn(skillGroups);
        return;
      }

      const dataType = API.getDataType(_proofBundle.originObjectId);
      if (API.isFailure(dataType)) {
        logger.error(dataType);
      }

      let _trainingSessionWithDetail: API.TrainingSessionWithDetail | undefined;
      let _training: API.Training | undefined;
      let _trainingVersion: API.TrainingVersion | undefined;

      if (
        dataType === API.DataType.TRAININGVERSION &&
        _proofBundle.review.state !== API.ReviewState.REJECTED_TO_RESUBMIT
      ) {
        const training = await API.getTrainingForATrainingVersion(_proofBundle.originObjectId);
        if (!isMounted.current) return;
        if (API.isFailure(training)) {
          logger.warn(training);
          return;
        }

        const trainingVersion = await API.getTrainingVersion(_proofBundle.originObjectId);
        if (!isMounted.current) return;
        if (API.isFailure(trainingVersion)) {
          logger.warn(trainingVersion);
          return;
        }

        _trainingVersion = trainingVersion;
        _training = training;
      } else if (
        dataType === API.DataType.TRAININGSESSION &&
        _proofBundle.review.state !== API.ReviewState.REJECTED_TO_RESUBMIT
      ) {
        const trainingSession = await API.getTrainingSession(_proofBundle.originObjectId);
        if (!isMounted.current) return;
        if (API.isFailure(trainingSession)) {
          logger.warn(trainingSession);
          return;
        }
        const trainingSessionWithDetail = await API.getTrainingSessionsWithDetail([
          trainingSession,
        ]);
        if (API.isFailure(trainingSessionWithDetail)) {
          logger.error(trainingSessionWithDetail);
          return;
        }

        if (trainingSessionWithDetail[0]) {
          _trainingSessionWithDetail = trainingSessionWithDetail[0];
          _training = trainingSessionWithDetail[0].training;
        }
      }

      
      let reSubmitionWorker;
      let worker = toDoListWorkers?.find(worker => worker.id === _proofBundle.workerId);

      if (_proofBundle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT) {
        worker = toDoListWorkers?.find(worker => worker.id === _proofBundle.review.workerId);
        reSubmitionWorker = toDoListWorkers?.find(worker => worker.id === _proofBundle.workerId);
      }
      if (!worker) return;

      _proofBundleItemsToValidate.push({
        worker: worker,
        skill: {
          ..._skill,
        },
        proofBundles: [_proofBundle],
        trainingSession: _trainingSessionWithDetail,
        reSubmitionWorker,
        training: _training,
        trainingVersion: _trainingVersion,
        trainingVersionSkillIds: _trainingVersion?.skillIds as string[],
      });

      if (skillGroups.length) {
        skillGroups.forEach(skillGroup => {
          const foundIndex = _proofBundleItemsToValidate.findIndex(
            proofItem => proofItem.skill.id === skillGroup.id && worker!.id === proofItem.worker.id,
          );
          if (foundIndex < 0) {
            _proofBundleItemsToValidate.push({
              worker: worker!,
              skill: skillGroup,
              proofBundles: [_proofBundle],
              trainingSession: _trainingSessionWithDetail,
              training: _training,
              trainingVersion: _trainingVersion,
              trainingVersionSkillIds: _trainingVersion?.skillIds as string[],
            });
          } else {
            _proofBundleItemsToValidate[foundIndex].proofBundles = [
              ..._proofBundleItemsToValidate[foundIndex].proofBundles,
              _proofBundle,
            ];
          }
        });
      }
    });
    if (!isMounted.current) return;
    setFilteredProofBundleItemsToValidateAndReSubmit(_proofBundleItemsToValidate);
    setLoader(false);
  }

  async function proofBundleReviewed() {
    await fetchWorkersSkillsToValidate();
  }

  return (
    <SkillValidatePanelComponent
      proofBundleItemsToValidate={filteredProofBundleItemsToValidateAndReSubmit}
      lastProofBundleSeenDate={lastProofBundleSeenDate}
      loader={loader}
      proofBundleReviewed={proofBundleReviewed}
    />
  );
};
