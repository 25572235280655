import React, { useState, useEffect } from 'react';
import styles from './Styles';
import { View, TouchableOpacity, InteractionManager } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import WebModal from 'modal-react-native-web';
import * as API from 'shared/backend-data';
import { ImageSizes, renderImage } from 'shared/util/RenderImage';
import { Text } from 'react-native';
import { YellowButton } from 'shared/ui-component/Button';
import { t } from 'shared/localisation/i18n';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { DropDownOption, DropDownSingleSelection } from 'shared/ui-component/DropDown/DropDown';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import moment from 'moment';
import { ModalUtils } from 'shared/ui-component/Modal';
import { WorkerEditAddWarning, showWarningWorkerEditAdd } from './modify-worker-modal/warningModal';
import { Writable } from 'shared/util-ts/Functions';
import { InputTextDescription } from 'shared/ui-component/Input/InputTextDescription';
import { findIndex } from 'lodash-es';
import { archiveWorkerWithErrorModalHandling } from 'shared/util/WorkerUi';


const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

interface Props {
  worker: API.Worker;
  setShowModal: (value: boolean) => void;
  successCallback: () => void;
}

const DefaultContractOption = 'DefaultContractOption';

export const WorkerArchiveORActivateModal: React.FC<Props> = props => {
  const { worker, successCallback, setShowModal } = props;

  const isMounted = useIsMounted();

  const defaultDropdownOptions: DropDownOption = {
    key: DefaultContractOption,
    label: t('alex:workerProfile.endOfContract'),
  };

  const [dropdownOptions, setDropdownOptions] = useState<DropDownOption[]>([
    defaultDropdownOptions,
  ]);
  const [loader, setLoader] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<DropDownOption>();
  const [contractStartDate, setContractStartDate] = useState<string>();
  const [contractEndDate, setContractEndDate] = useState<string>();
  const [contracts, setContracts] = useState<Writable<API.Contract>[]>([]);
  const [lastContract, setLastContract] = useState<API.Contract>();
  const [contractIndex, setContractIndex] = useState<number>();
  const [comment, setComment] = useState<string>();

  const modal = ModalUtils.useModal();
  let workerState = worker.state;
  if (workerState === null) workerState = API.WorkerState.ACTIVE;

  useEffect(() => {
    handleDropDownOptions();
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      const lastContract = await API.getWorkerLastContract(worker);
      if (!isMounted.current) return;
      if (API.isFailure(lastContract)) {
        logger.warn(lastContract);
        return;
      }
      if (!lastContract) return;

      const lastContractIndex = findIndex(worker.contracts, contract =>
        API.isSameContract(contract, lastContract),
      );
      if (lastContractIndex === -1) return;

      setContracts(API.deepClone(worker.contracts));
      setLastContract(lastContract);
      setContractIndex(lastContractIndex);
    });
  }, [worker]);

  useEffect(() => {
    if (contractStartDate && contractEndDate && contractStartDate > contractEndDate) {
      showWarningWorkerEditAdd(modal, WorkerEditAddWarning.StartEndDateContract);
      setContractStartDate(undefined);
      setContractEndDate(undefined);
    }
  }, [contractStartDate, contractEndDate]);

  async function handleDropDownOptions() {
    const contractTypes = await API.getContractTypes(true);
    if (!isMounted.current) return;
    if (API.isFailure(contractTypes)) {
      logger.warn(contractTypes);
      return;
    }

    const _contractTypes = contractTypes.map(eachContract => {
      return {
        key: eachContract.id,
        label: eachContract.name,
      };
    });
    setDropdownOptions([...dropdownOptions, ..._contractTypes]);
  }

  async function handleWorker(): Promise<void> {
    if (dropdown && dropdown.key === DefaultContractOption && contracts.length === 0) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t(
            'alex:worker.addEditWorker.warnings.archiveWorkerWithoutContractWarning',
          ),
          warningAcceptButton: t('common:button.gotIt'),
          warningAcceptCallback: () => {
            setShowModal(false);
            setLoader(false);
          },
        }),
      );
    }
    if (
      contractIndex !== undefined &&
      dropdown &&
      dropdown.key === DefaultContractOption &&
      contracts.length
    ) {
      if (comment !== undefined) {
        contracts[contractIndex].comment = comment;
      }
      if (workerState === API.WorkerState.ACTIVE) {
        const _updateWorker = {
          ...worker,
          contracts,
          tagIds: [...worker.tagIds],
        };
        const done = await archiveWorkerWithErrorModalHandling(_updateWorker, modal);
        if (!isMounted.current) return;
        if (done) successCallback();
      }
    } else if (
      workerState === API.WorkerState.ACTIVE &&
      dropdown &&
      dropdown.key !== DefaultContractOption
    ) {
      
      const _updateWorker: API.WorkerPartialUpdateInput = {
        ...worker,
        contracts: [
          ...contracts,
          {
            contractTypeId: dropdown.key,
            endDate: contractEndDate,
            startDate: contractStartDate,
            comment,
          },
        ],
        tagIds: [...worker.tagIds],
      };
      const done = await archiveWorkerWithErrorModalHandling(_updateWorker, modal);
      if (!isMounted.current) return;
      if (done) successCallback();
    } else if (workerState === API.WorkerState.ARCHIVED) {
      
      setLoader(true);
      const _updateWorker = await API.updateWorker({
        ...worker,
        state: API.WorkerState.ACTIVE,
        contracts,
        tagIds: [...worker.tagIds],
      });
      if (!isMounted.current) return;
      if (API.isFailure(_updateWorker)) {
        logger.warn(_updateWorker);
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: t('alex:worker.addEditWorker.warnings.activateWorkerPermissionWarning'),
            warningAcceptButton: t('common:button.gotIt'),
          }),
        );
        setLoader(false);
        return;
      }
      modal.displayModal(
        ModalUtils.toastConfig({
          text: t('alex:worker.addEditWorker.warnings.reinstateWorker'),
          callback: successCallback,
        }),
      );
    }

    setShowModal(false);
    setLoader(false);
  }

  function handleDropdown(value: DropDownOption | undefined) {
    if (value) {
      if (value.key === DefaultContractOption && lastContract?.startDate) {
        setContractStartDate(lastContract.startDate);
      } else {
        setContractStartDate(moment().toISOString());
      }
      setContractEndDate(undefined);
      setDropdown(value);
    }
  }

  function showWarningModalIfNeeded(date: Date, archivingDate: boolean) {
    if (new Date() < date)
      showWarningWorkerEditAdd(
        modal,
        archivingDate
          ? WorkerEditAddWarning.ArchiveInFuture
          : WorkerEditAddWarning.ReInstateInFuture,
      );
  }

  
  function handleEndDateInput(date: Date) {
    if (
      (lastContract?.startDate && date.toISOString() < lastContract.startDate) ||
      (contractStartDate && date.toISOString() < contractStartDate)
    ) {
      showWarningWorkerEditAdd(modal, WorkerEditAddWarning.StartEndDateContract);
    } else {
      if (contracts) {
        if (
          contractIndex !== undefined &&
          ((dropdown && dropdown.key === DefaultContractOption) ||
            workerState === API.WorkerState.ARCHIVED)
        ) {
          contracts[contractIndex].endDate = date ? date.toISOString() : null;
          setContracts([...contracts]);
        }
      }
      setContractEndDate(moment(date).toISOString());
    }
  }

  function disableYellowButton(): boolean {
    if (workerState === API.WorkerState.ACTIVE) {
      if (
        dropdown &&
        dropdown.key === DefaultContractOption &&
        ((lastContract && lastContract.endDate) || contractEndDate)
      ) {
        return false;
      } else if (dropdown && dropdown.key !== DefaultContractOption && contractStartDate) {
        return false;
      }
      return true;
    } else if (workerState === API.WorkerState.ARCHIVED) {
      if ((lastContract && lastContract.endDate) || contractEndDate) {
        return false;
      }
      return true;
    }
    return true;
  }

  function closeModalAndClearState() {
    setContractStartDate(undefined);
    setContractEndDate(undefined);
    setContracts([]);
    setComment(undefined);
    setContractIndex(undefined);
    setLastContract(undefined);
    setDropdown(undefined);
    setShowModal(false);
  }

  function getStartDate(): Date | undefined {
    if (!dropdown) return;

    return contractStartDate
      ? new Date(contractStartDate)
      : dropdown.key === DefaultContractOption && lastContract?.startDate
      ? new Date(lastContract.startDate)
      : new Date();
  }

  function getEndDate(): Date | undefined {
    if (!dropdown) return;

    return contractEndDate
      ? new Date(contractEndDate)
      : dropdown.key === DefaultContractOption && lastContract?.endDate
      ? new Date(lastContract.endDate)
      : undefined;
  }

  const handleComment = (id: string, value: string) => setComment(value);

  if (!workerState) return null;

  return (
    <WebModal animationType="fade" transparent visible={true}>
      <View style={styles.modalContainer}>
        <View style={styles.innerContainer}>
          <TouchableOpacity
            style={styles.closeButtonIcon}
            onPress={() => closeModalAndClearState()}
          >
            <IconSVG svgComponent={closeIcon} />
          </TouchableOpacity>

          <View style={styles.contentContainer}>
            <View style={styles.workerRow}>
              <View>{renderImage(worker.profilePicture, ImageSizes.Large, worker.name)}</View>
              <View style={styles.workerNameContainer}>
                <Text style={styles.workerText}>{t('glossary:worker')}</Text>
                <Text style={styles.workerName}>{worker.name}</Text>
              </View>
            </View>

            {workerState === API.WorkerState.ACTIVE && (
              <View>
                <DropDownSingleSelection
                  options={dropdownOptions}
                  title={t('alex:workerProfile.reasonDropdownTitle')}
                  placeholder={t('alex:workerProfile.reason')}
                  value={dropdown}
                  containerStyle={styles.reasonDropDownStyle}
                  dropDownMenuContainerStyle={styles.reasonDropDownContainerStyle}
                  handleChange={(_, value) => handleDropdown(value)}
                />
                <View style={styles.dateInputOuterContainer}>
                  {dropdown ? (
                    <View style={styles.dateInputInnerContainer}>
                      <InputDateWeb
                        hasDateIcon
                        inputPlaceholder={t('common:time.from')}
                        inputName={t('common:time.from')}
                        value={
                          dropdown.key === DefaultContractOption ? getEndDate() : getStartDate()
                        }
                        style={styles.archiveDatePicker}
                        onChange={(_, date) => {
                          showWarningModalIfNeeded(date, true);
                          dropdown.key === DefaultContractOption
                            ? handleEndDateInput(date)
                            : setContractStartDate(date.toISOString());
                        }}
                        minDate={
                          dropdown.key === DefaultContractOption ? getStartDate() : undefined
                        }
                      />

                      {dropdown.key !== DefaultContractOption && (
                        <InputDateWeb
                          hasDateIcon
                          inputPlaceholder={t('common:time.to')}
                          minDate={getStartDate()}
                          inputName={t('common:time.to')}
                          value={getEndDate()}
                          calendarContainerStyle={styles.endDateDropDownContainerStyle}
                          style={styles.archiveDatePicker}
                          onChange={(_, date) => {
                            showWarningModalIfNeeded(date, false);
                            handleEndDateInput(date);
                          }}
                        />
                      )}
                    </View>
                  ) : (
                    <View style={styles.verticalDivider} />
                  )}
                </View>
              </View>
            )}

            {workerState === API.WorkerState.ARCHIVED && (
              <View>
                <View style={styles.activateContainer}>
                  <Text style={styles.activateText}>
                    {t('alex:worker.addEditWorker.resumptionOfWork')}
                  </Text>
                  <InputDateWeb
                    hasDateIcon
                    inputPlaceholder={t('common:time.endDate')}
                    inputName={t('common:time.endDate')}
                    value={
                      contractEndDate
                        ? new Date(contractEndDate)
                        : lastContract?.endDate
                        ? new Date(lastContract?.endDate)
                        : undefined
                    }
                    calendarContainerStyle={styles.archivedEndDate}
                    notEditable={
                      !!(
                        lastContract &&
                        lastContract.endDate &&
                        lastContract.endDate < moment().toISOString()
                      )
                    }
                    onChange={(_, date) => {
                      showWarningModalIfNeeded(date, false);
                      handleEndDateInput(date);
                    }}
                  />
                </View>
              </View>
            )}

            <View style={styles.commentsContainer}>
              <InputTextDescription
                containerStyle={styles.inputText}
                placeHolder={t('alex:worker.addEditWorker.comment')}
                defaultValue={lastContract?.comment || ''}
                onTextChange={handleComment}
              />
            </View>

            <View style={styles.buttonContainer}>
              <YellowButton
                style={styles.buttonStyle}
                text={
                  workerState === API.WorkerState.ACTIVE
                    ? t('glossary:workerSuspend')
                    : t('glossary:workerReinstate')
                }
                textStyle={styles.yellowButtonStyle}
                disabled={disableYellowButton()}
                onPress={() => handleWorker()}
              />
            </View>
          </View>
        </View>
      </View>
      {loader && <Loader />}
    </WebModal>
  );
};
