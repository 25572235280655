import React, { useState, createContext } from 'react';
import * as API from 'shared/backend-data';
import { logger } from 'shared/util/Logger';

export const CollaborateurProfileContext = createContext<
  [API.Worker | undefined, (worker: API.Worker) => void]
>([undefined, () => logger.debug('Default value. No implementation.')]);

export const HeaderTitleProvider: React.FC = props => {
  const [selectedWorker, setSelectedWorker] = useState<API.Worker>();

  return (
    <CollaborateurProfileContext.Provider value={[selectedWorker, setSelectedWorker]}>
      {props.children}
    </CollaborateurProfileContext.Provider>
  );
};
