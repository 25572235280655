import * as API from 'shared/backend-data';
import * as APIf from 'shared/backend-data/FactoryApiWrapper';
import * as _ from 'lodash-es';
import { isFailure, WorkstationWorkerLevels } from 'shared/backend-data';
import moment from 'moment';
import logger from './Logger';
import i18n from 'i18next';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import { date_diff_inDays } from 'shared/util-ts/DateUtils';
import Aigle from 'aigle';
import { ReviewState } from 'backend/src/api';
import { WorkerDropDownOptionWithDetails } from 'shared/ui-component/DropDown/DropDown';
import { TrainingSessionWarning } from 'shared/util/TrainingSessionWarningModal';
import { getTrainerIdsFromTraininersWithPercentage } from 'shared/backend-data';
import { deepFreeze, Writable } from '../util-ts/Functions';

export interface TrainingSessionsMetric {
  hours: number;
  trainingSessions: API.TrainingSession[];
}

export interface TrainingSessionWithDetail extends API.TrainingSession {
  training: API.Training;
  isPractical: boolean;
  trainingVersion: API.TrainingVersion;
  linkedObjectId?: string;
}

export interface GroupedTrainingSessions {
  draft: API.TrainingSessionWithDetail[];
  requested: API.TrainingSessionWithDetail[];
  scheduled: API.TrainingSessionWithDetail[];
  started: API.TrainingSessionWithDetail[];
  lateEnded: API.TrainingSessionWithDetail[];
}

export enum ButtonOnPressTypes {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  START = 'START',
  SCHEDULE = 'SCHEDULE',
  REJECTREQUEST = 'REJECT-REQUEST',
  VALIDATEREQUEST = 'VALIDATE-REQUEST',
  SAVEASDRAFT = 'SAVE-AS-DRAFT',
  ADDPROOF = 'ADD-PROOF',
  UNLOCKEDIT = 'UNLOCK-EDIT',
}

export interface TrainingSessionWithState extends API.TrainingSession {
  state: API.TrainingSessionState;
}

export type TrainingsWithLateTrainingSessionCount = {
  training: API.Training;
  numberOfLateTrainingSessions: number;
};





export async function createTrainingSession(
  trainingSession: API.TrainingSessionCreateInput,
): Promise<API.Result<API.TrainingSession>> {
  const factory = await API.createFactoryBusinessObject(
    API.DataType.TRAININGSESSION,
    trainingSession,
  );
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.trainingSession,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

/**
 * This function creates a seperate request for the worker list provided
 * @param trainingVersionId
 * @param workerIds
 */

export async function createTrainingRequestForEachTrainee(
  trainingVersionId: string,
  workerIds: string[],
  requirementId?: string | null,
  originId?: string | null,
): Promise<void> {
  workerIds.map(async workerId => {
    const _trainingSession = await createTrainingSession({
      trainingVersionId: trainingVersionId,
      scheduledTraineeIds: [workerId],
      traineeIds: [],
      requestState: API.ReviewState.VALIDATED,
      requirementId,
      originId,
      scheduledTrainers: [],
      trainers: [],
    });

    if (API.isFailure(_trainingSession)) return _trainingSession;
  });
}

export async function updateTrainingSession(
  trainingSession: API.TrainingSessionPartialUpdateInput,
): Promise<API.Result<API.TrainingSession>> {
  const factory = await API.updateFactoryBusinessObject(
    API.DataType.TRAININGSESSION,
    trainingSession,
  );
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.trainingSession,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function startTrainingSession(
  trainingSession: API.TrainingSessionPartialUpdateInput,
): Promise<API.Result<API.TrainingSession>> {
  const startedTrainingSession = await updateTrainingSession({
    ...trainingSession,
    startDate: new Date().toISOString(),
  });
  if (API.isFailure(startedTrainingSession)) {
    logger.warn('Failed to start TrainingSession ', startedTrainingSession);
  }
  return startedTrainingSession;
}

export async function rejectTrainingSession(
  trainingSessionId: string,
): Promise<API.Result<API.TrainingSession>> {
  const trainingSession = await getTrainingSession(trainingSessionId);
  if (API.isFailure(trainingSession)) return trainingSession;

  if (API.isTrainingSessionToReview(trainingSession)) {
    const _updatedTrainingSession = await updateTrainingSession({
      id: trainingSession.id,
      requestState: ReviewState.REJECTED,
    });
    if (API.isFailure(_updatedTrainingSession)) return _updatedTrainingSession;
    return _updatedTrainingSession;
  }

  if (API.isTrainingSessionRequestRejected(trainingSession)) {
    return API.createFailure('UpdateVeto', 'Training Session already rejected', {
      dependencyIds: [trainingSessionId],
    });
  }
  if (API.isTrainingSessionValidated(trainingSession)) {
    return API.createFailure('UpdateVeto', 'Cannot reject a validated Training Session', {
      dependencyIds: [trainingSessionId],
    });
  }

  return API.createFailure('UpdateVeto', 'Cannot reject an unkown Training Session request state', {
    dependencyIds: [trainingSessionId],
  });
}

export async function validateTrainingSession(
  trainingSessionId: string,
): Promise<API.Result<API.TrainingSession>> {
  const trainingSession = await getTrainingSession(trainingSessionId);
  if (API.isFailure(trainingSession)) return trainingSession;

  if (API.isTrainingSessionToReview(trainingSession)) {
    const _updatedTrainingSession = await updateTrainingSession({
      id: trainingSession.id,
      requestState: ReviewState.VALIDATED,
    });
    if (API.isFailure(_updatedTrainingSession)) return _updatedTrainingSession;
    return _updatedTrainingSession;
  }

  if (API.isTrainingSessionValidated(trainingSession)) {
    return API.createFailure('UpdateVeto', 'Training Session already rejected', {
      dependencyIds: [trainingSessionId],
    });
  }
  if (API.isTrainingSessionRequestRejected(trainingSession)) {
    return API.createFailure('UpdateVeto', 'Cannot validate a rejected Training Session', {
      dependencyIds: [trainingSessionId],
    });
  }

  return API.createFailure(
    'UpdateVeto',
    'Cannot validate an unkown Training Session request state',
    { dependencyIds: [trainingSessionId] },
  );
}

export async function saveTrainingSessionAsADraft(
  trainingSessionUpdateInput: API.TrainingSessionPartialUpdateInput,
) {
  const updatedTrainingSession = await updateTrainingSession({
    ...trainingSessionUpdateInput,
    isDraft: true,
  });
  if (API.isFailure(updatedTrainingSession)) return updatedTrainingSession;
}

/**
 * Update TrainingSessions after having removed the workerId from traineeIds, scheduledTraineeIds, trainers and scheduledTrainers
 * @param workerId
 * @param workstationId
 * @returns the list of updated TrainingSessions
 */

export async function removeWorkerFromTrainingSessionsAndUpdate(
  workerId: string,
  workstationId: string,
): Promise<API.Result<API.TrainingSession[]>> {
  const trainingSessions = await getTrainingSessionsForWorkstationOrOrgUnit(workstationId);
  if (API.isFailure(trainingSessions)) return trainingSessions;

  return API.mapSeries(trainingSessions, async trainingSession => {
    return removeAndUpdateWorkerFromTrainingSession(workerId, trainingSession);
  });
}

export async function endTrainingSession(
  trainingSession: API.TrainingSessionUpdateInput,
): Promise<API.Result<API.TrainingSession>> {
  const endedSession = await updateTrainingSession({
    ...trainingSession,
    endDate: new Date().toISOString(),
  });
  if (API.isFailure(endedSession)) return endedSession;

  return endedSession;
}

/**
 * Remove the Worker from the given TrainingSession and return the updated TrainingSession
 * (it might ba a different object than the passed TrainingSession as it returns the centralized/cache TrainingSession)
 *
 * @param workerId
 * @param trainingSession
 * @returns
 */


export async function removeAndUpdateWorkerFromTrainingSession(
  workerId: string,
  trainingSession: API.TrainingSession,
): Promise<API.Result<API.TrainingSession>> {
  let isWorkerInTrainingSession = false;

  const scheduledTraineeIds = trainingSession.scheduledTraineeIds.filter(
    scheduledTraineeId => scheduledTraineeId !== workerId,
  );
  if (scheduledTraineeIds.length !== trainingSession.scheduledTraineeIds.length)
    isWorkerInTrainingSession = true;

  const traineeIds = trainingSession.traineeIds.filter(traineeId => traineeId !== workerId);
  if (!isWorkerInTrainingSession && traineeIds.length !== trainingSession.traineeIds.length)
    isWorkerInTrainingSession = true;

  const scheduledTrainers = [];
  for (const trainer of trainingSession.scheduledTrainers) {
    if (trainer.trainerId === workerId) scheduledTrainers.push(trainer);
  }

  if (!isWorkerInTrainingSession && _.isEqual(scheduledTrainers, trainingSession.scheduledTrainers))
    isWorkerInTrainingSession = true;

  const trainers = [];
  for (const trainer of trainingSession.trainers) {
    if (trainer.trainerId === workerId) trainers.push(trainer);
  }

  if (!isWorkerInTrainingSession && _.isEqual(trainers, trainingSession.trainers))
    isWorkerInTrainingSession = true;

  if (isWorkerInTrainingSession)
    return updateTrainingSession({
      ...trainingSession,
      scheduledTraineeIds,
      traineeIds,
      trainers,
      scheduledTrainers: scheduledTrainers,
      trainerFiles: trainingSession.trainerFiles
        ? [...trainingSession.trainerFiles]
        : trainingSession.trainerFiles,
    });
  else return trainingSession;
}

export function isTrainingSession(obj: { __typename: string }): obj is API.TrainingSession {
  return (obj as API.TrainingSession).__typename === 'TrainingSession';
}

export async function isPracticalTrainingSession(
  trainingSession: API.TrainingSession,
): Promise<API.Result<boolean>> {
  return API.isPracticalTrainingVersion(trainingSession.trainingVersionId);
}

export async function getTrainingSessionsWithDetail(
  trainingSessions: API.TrainingSession[],
  trainingNameWithoutLevel?: boolean,
): Promise<TrainingSessionWithDetail[]> {
  const trainingSessionsWithDetail: TrainingSessionWithDetail[] = [];
  await Aigle.mapSeries(trainingSessions, async trainingSession => {
    let linkedObjectId: string | undefined = undefined;
    const trainingId = API.getTrainingIdFromTrainingVersionId(trainingSession.trainingVersionId);
    if (!trainingId) {
      logger.warn('getTrainingSessionsWithDetail Training Id not found');
      return;
    }
    let _training = await API.getTraining(trainingId);
    if (API.isFailure(_training)) {
      logger.warn(_training);
      return;
    }

    if (trainingNameWithoutLevel) {
      const index = _training.name.indexOf(i18n.t('glossary:levelAbbreviated'));
      if (index > -1) {
        const level = _training.name.slice(index);
        _training = {
          ..._training,
          name: _training.name.replace(level, ''),
        };
      }
    }

    if (!_training.name) {
      _training = {
        ..._training,
        name: i18n.t('alex:mobile.trainingScreen.trainingDeleted'),
      };
    }

    const trainingVersion = await API.getTrainingVersion(trainingSession.trainingVersionId);
    if (API.isFailure(trainingVersion)) {
      logger.warn(trainingVersion);
      return;
    }

    const isPractical = await API.isPracticalTrainingVersion(trainingVersion.id);
    if (API.isFailure(isPractical)) {
      logger.warn(isPractical);
      return;
    }
    if (trainingSession.requirementId) {
      const requirement = await API.getRequirement(trainingSession.requirementId);
      if (API.isFailure(requirement)) {
        logger.warn(requirement);
        return;
      }
      linkedObjectId = requirement.linkedObjectId;
    }

    trainingSessionsWithDetail.push({
      ...trainingSession,
      training: _training,
      linkedObjectId,
      trainingVersion,
      isPractical,
    });
  });
  return trainingSessionsWithDetail;
}

export function getTrainingSession(
  trainingSessionId: string,
  fetchPolicy: 'cache-only',
): API.Result<API.TrainingSession>;
export function getTrainingSession(
  trainingSessionId: string,
  fetchPolicy: 'network-no-cache',
): Promise<API.Result<Writable<API.TrainingSession>>>;
export function getTrainingSession(
  trainingSessionId: string,
  fetchPolicy?: Exclude<API.FetchPolicy, 'network-no-cache'>,
): API.Result<API.TrainingSession> | Promise<API.Result<API.TrainingSession>>;
export function getTrainingSession(
  trainingSessionId: string,
  fetchPolicy?: API.FetchPolicy,
):
  | API.Result<API.TrainingSession>
  | Promise<API.Result<Writable<API.TrainingSession>>>
  | Promise<API.Result<API.TrainingSession>> {
  if (fetchPolicy === 'cache-only') {
    const factory = API.getFactoryBusinessObject(
      API.DataType.TRAININGSESSION,
      trainingSessionId,
      fetchPolicy,
    );
    if (API.isFailure(factory)) return factory;

    return deepFreeze({
      ...factory.trainingSession,
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    });
  } else if (fetchPolicy === 'network-no-cache') {
    return API.getFactoryBusinessObject(
      API.DataType.TRAININGSESSION,
      trainingSessionId,
      fetchPolicy,
    ).then(factory => {
      if (API.isFailure(factory)) return factory;

      return {
        ...factory.trainingSession,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      };
    });
  } else {
    const factoryOrPromise = API.getFactoryBusinessObject(
      API.DataType.TRAININGSESSION,
      trainingSessionId,
      fetchPolicy,
    );
    if (factoryOrPromise instanceof Promise) {
      return factoryOrPromise.then(factory => {
        if (API.isFailure(factory)) return factory;

        return deepFreeze({
          ...factory.trainingSession,
          updatedAt: factory.updatedAt,
          updatedBy: factory.updatedBy,
        });
      });
    } else {
      if (API.isFailure(factoryOrPromise)) return factoryOrPromise;

      return deepFreeze({
        ...factoryOrPromise.trainingSession,
        updatedAt: factoryOrPromise.updatedAt,
        updatedBy: factoryOrPromise.updatedBy,
      });
    }
  }
}

/**
 * Get the TrainingSessions of the given Worker and optionaly filter for a given Requirement
 * @param workerId
 * @param requirementId (optional)
 * @param hideReviewedTrainingSessions (optional, default false)
 * @param originId (optional)
 * @param onlyTrainee (optional)
 * @param onlyActiveTrainingSessions (optional)
 */

export async function getTrainingSessionsForWorker(
  workerId: string,
  requirementId?: string,
  hideReviewedTrainingSessions?: boolean,
  originId?: string,
  onlyTrainee: boolean | undefined = false,
  onlyActiveTrainingSessions: boolean | undefined = false,
): Promise<API.Result<API.TrainingSession[]>> {
  let _sk =
    API.DataType.WORKERTRAININGSESSION + API.SeparatorDataType + API.SeparatorIds + workerId;
  if (onlyTrainee) {
    _sk =
      API.DataType.WORKERTRAININGSESSION +
      API.SeparatorDataType +
      API.SeparatorIds +
      workerId +
      API.SeparatorIds +
      API.TraineeOrTrainer.TRAINEE;
  }

  const factories = await API.listFactoriesWithSk(
    API.DataType.WORKERTRAININGSESSION,
    _sk,
    API.KeyComparator.beginsWith,
  );
  if (API.isFailure(factories)) return factories;

  const result: API.TrainingSession[] = [];
  await Aigle.mapSeries(factories.result, async factory => {
    const trainingSession = await getTrainingSession(
      factory.workerTrainingSession.trainingSessionId,
      onlyActiveTrainingSessions ? 'cache-only' : undefined,
    );
    if (API.isFailure(trainingSession)) return trainingSession;

    let iterateThisTrainingSession = true;
    if (onlyActiveTrainingSessions && !API.isTrainingSessionActive(trainingSession)) {
      iterateThisTrainingSession = false;
    }

    if (iterateThisTrainingSession) {
      if (
        (!requirementId || requirementId === trainingSession.requirementId) &&
        (!originId || originId === trainingSession.originId)
      ) {
        if (hideReviewedTrainingSessions) {
          const notReviewed = await isTrainingSessionNeedingReview(trainingSession);
          if (isFailure(notReviewed)) return notReviewed;

          if (notReviewed)
            result.push({
              ...trainingSession,
              updatedAt: factory.updatedAt,
              updatedBy: factory.updatedBy,
            });
        } else {
          result.push({
            ...trainingSession,
            updatedAt: factory.updatedAt,
            updatedBy: factory.updatedBy,
          });
        }
      }
    }
  });
  return result;
}

/**
 * Get the TrainingSessions of the given Workstation or OrganizationalUnit
 * @param workstationOrOrgUnitId
 * @param hideReviewedTrainingSessions (optional, default = false)
 */

export async function getTrainingSessionsForWorkstationOrOrgUnit(
  workstationOrOrgUnitId: string,
): Promise<API.Result<API.TrainingSession[]>> {
  const _trainingSessions = await _getTrainingSessionsForWorkstationOrOrgUnit(
    workstationOrOrgUnitId,
  );
  if (API.isFailure(_trainingSessions)) return _trainingSessions;

  return _trainingSessions;
}

/**
 * Get the TrainingSessions of the given Workstation or OrganizationalUnit
 * @param workstationOrOrgUnitId
 */

async function _getTrainingSessionsForWorkstationOrOrgUnit(
  workstationOrOrgUnitId: string,
): Promise<API.Result<API.TrainingSession[]>> {
  return getTrainingSessions(undefined, workstationOrOrgUnitId);
}

/**
 * @param trainingSession
 * @returns true only if all ProofBundles for all the participants exists and are reviewed
 */

export async function isTrainingSessionNeedingReview(
  trainingSession: API.TrainingSession,
): Promise<API.Result<boolean>> {
  const trainingVersion = await API.getTrainingVersion(trainingSession.trainingVersionId);
  if (API.isFailure(trainingVersion)) return trainingVersion;

  const _traineeIds = [...trainingSession.traineeIds];

  const proofBundlesMap = await API.getLatestProofBundleAndCommonFiles(trainingSession.id);
  if (API.isFailure(proofBundlesMap)) return proofBundlesMap;

  const isNeedingReview = _traineeIds.some(_traineeId => {
    const skillsMap = proofBundlesMap.get(_traineeId);
    return (
      !skillsMap ||
      trainingVersion.skillIds.some(_skillId => {
        const traineeIdSkillIdProofBundleDetails = skillsMap.get(_skillId);
        const proofNotReviewed =
          traineeIdSkillIdProofBundleDetails &&
          traineeIdSkillIdProofBundleDetails.proofBundle.review.state !==
            API.ReviewState.VALIDATED &&
          traineeIdSkillIdProofBundleDetails.proofBundle.review.state !==
            API.ReviewState.REJECTED &&
          traineeIdSkillIdProofBundleDetails.proofBundle.review.state !==
            API.ReviewState.REJECTED_TO_RESUBMIT;
        return !traineeIdSkillIdProofBundleDetails || proofNotReviewed;
      })
    );
  });

  return isNeedingReview;
}

/**
 * Get TrainingSession for a Training
 * @param trainingOrTrainingVersionId
 */

export async function getTrainingSessionsForATrainingOrATrainingVersion(
  trainingOrTrainingVersionId: string,
): Promise<API.Result<API.TrainingSession[]>> {
  const trainingSessionSkString = API.getTrainingSessionSkStringByTrainingIdOrTrainingVersionId(
    trainingOrTrainingVersionId,
  );

  const trainingSessions = await APIf.listFactoriesWithSk(
    API.DataType.TRAININGSESSION,
    trainingSessionSkString,
    API.KeyComparator.beginsWith,
  );
  if (API.isFailure(trainingSessions)) return trainingSessions;

  return trainingSessions.result.map(trainingSession => {
    return {
      ...trainingSession.trainingSession,
      updatedAt: trainingSession.updatedAt,
      updatedBy: trainingSession.updatedBy,
    };
  });
}

export async function getTrainingSessions(
  stages: API.TrainingSessionStage[] = [
    API.TrainingSessionStage.InProgress,
    API.TrainingSessionStage.Proposal,
    API.TrainingSessionStage.Request,
  ],
  originId?: string,
  requirementId?: string,
): Promise<API.Result<API.TrainingSession[]>> {
  const trainingSessions: API.TrainingSession[] = [];

  const failures = await API.mapSeries(stages, async stage => {
    const _trainingSessions = await _getTrainingSessions(stage, originId, requirementId);
    if (API.isFailure(_trainingSessions)) return _trainingSessions;
    trainingSessions.push(..._trainingSessions);
  });
  if (API.isFailure(failures)) return failures;

  return trainingSessions;
}

async function _getTrainingSessions(
  stage: API.TrainingSessionStage,
  originId?: string,
  requirementId?: string,
) {
  const dataString = API.getTrainingSessionDataString(stage, originId, requirementId);

  const _trainingSessions = await API.listFactoriesWithData(
    API.DataType.TRAININGSESSION,
    dataString,
    API.KeyComparator.beginsWith,
  );
  if (API.isFailure(_trainingSessions)) return _trainingSessions;

  return _trainingSessions.result.map(factory => {
    return {
      ...factory.trainingSession,
      updatedAt: factory.updatedAt,
      updatedBy: factory.updatedBy,
    };
  });
}


/**
 * Returns the TrainingSessions that:
 *  - have ended during the period -> done (on time, late)
 *  - are in progress -> in progress
 *  - are scheduled to be performed during the period (scheduledStartDate within the invertal and noStartDate) -> schedulded
 * @param interval
 */

export async function getTrainingSessionsMetric(
  startDate: Date,
  endDate: Date,
): Promise<
  API.Result<{
    scheduled: TrainingSessionsMetric;
    inProgress: TrainingSessionsMetric;
    ended: TrainingSessionsMetric;
  }>
> {
  const scheduled: TrainingSessionsMetric = {
    hours: 0,
    trainingSessions: [],
  };
  const inProgress: TrainingSessionsMetric = {
    hours: 0,
    trainingSessions: [],
  };
  const ended: TrainingSessionsMetric = {
    hours: 0,
    trainingSessions: [],
  };

  const trainingSessions = await getTrainingSessions();
  if (API.isFailure(trainingSessions)) return trainingSessions;

  await Aigle.forEach(trainingSessions, async trainingSession => {
    if (trainingSession.endDate) {
      const trainingSessionEndDate = new Date(trainingSession.endDate);
      if (!trainingSession.startDate) {
        logger.error(
          'trainingSession startDate shall be defined when an endDate is defined. Please check factory object with id=' +
            trainingSession.id +
            '. getTrainingSessionsMetric will not return an accurate result.',
        );
        return;
      }
      const trainingSessionStartDate = new Date(trainingSession.startDate);

      if (trainingSessionEndDate.getTime() < startDate.getTime()) {
        
      } else if (trainingSessionEndDate.getTime() > endDate.getTime()) {
        if (trainingSessionStartDate.getTime() > endDate.getTime()) {
          
        } else {
          const trainingSessionDurationWithinThePeriod =
            endDate.getTime() - Math.max(startDate.getTime(), trainingSessionStartDate.getTime());

          inProgress.hours = inProgress.hours + trainingSessionDurationWithinThePeriod;
          inProgress.trainingSessions.push(trainingSession);
        }
      } else {
        
        const trainingSessionDurationWithinThePeriod = endDate.getTime() - startDate.getTime();

        inProgress.hours = inProgress.hours + trainingSessionDurationWithinThePeriod;
        inProgress.trainingSessions.push(trainingSession);
      }
    } else if (trainingSession.startDate) {
      const trainingSessionStartDate = new Date(trainingSession.startDate);

      if (trainingSessionStartDate.getTime() <= endDate.getTime()) {
        const trainingSessionDurationWithinThePeriod =
          endDate.getTime() - Math.max(startDate.getTime(), trainingSessionStartDate.getTime());
        inProgress.hours = inProgress.hours + trainingSessionDurationWithinThePeriod;
        inProgress.trainingSessions.push(trainingSession);
      } else {
        if (!trainingSession.scheduledStartDate) {
          logger.error(
            'trainingSession scheduledStartDate shall be defined when an startDate is defined. Please check factory object with id=' +
              trainingSession.id +
              '. getTrainingSessionsMetric will not return an accurate result.',
          );
          return;
        }
        const trainingSessionScheduledStartDate = new Date(trainingSession.scheduledStartDate);

        if (trainingSessionScheduledStartDate.getTime() <= endDate.getTime()) {
          const trainingSessionDurationWithinThePeriod =
            endDate.getTime() -
            Math.max(startDate.getTime(), trainingSessionScheduledStartDate.getTime());
          scheduled.hours = scheduled.hours + trainingSessionDurationWithinThePeriod;
          scheduled.trainingSessions.push(trainingSession);
        } else {
          
        }
      }
    } else {
      if (!trainingSession.scheduledStartDate) {
        logger.error(
          'trainingSession scheduledStartDate shall be defined when an startDate is defined. Please check factory object with id=' +
            trainingSession.id +
            '. getTrainingSessionsMetric will not return an accurate result.',
        );
        return;
      }
      const trainingSessionScheduledStartDate = new Date(trainingSession.scheduledStartDate);
      const trainingVersion = await API.getTrainingVersion(trainingSession.trainingVersionId);
      if (API.isFailure(trainingVersion)) {
        logger.warn(
          'Failed to retrieve trainingVersion. getTrainingSessionsMetric will not return an accurate result.',
          trainingVersion.data,
        );
        return;
      }
      const trainingSessionScheduledEndDate = new Date(
        trainingSessionScheduledStartDate.getTime() + trainingVersion.durationInMin,
      );

      if (
        trainingSessionScheduledEndDate.getTime() < startDate.getTime() ||
        trainingSessionScheduledStartDate.getTime() > endDate.getTime()
      ) {
        
      } else {
        const trainingSessionDurationWithinThePeriod =
          Math.min(endDate.getTime(), trainingSessionScheduledEndDate.getTime()) -
          Math.max(startDate.getTime(), trainingSessionScheduledStartDate.getTime());
        scheduled.hours = scheduled.hours + trainingSessionDurationWithinThePeriod;
        scheduled.trainingSessions.push(trainingSession);
      }
    }
  });

  return {
    scheduled,
    inProgress,
    ended,
  };
}

/**
 * Return the Training with how many late training sessions it has
 * @param trainingSessionsWithState
 */

export async function getTrainingsWithLateTrainingSessionsCount(
  trainingSessionsWithState: TrainingSessionWithState[],
): Promise<API.Result<TrainingsWithLateTrainingSessionCount[] | undefined>> {
  const trainingsWithLateTrainingSession: TrainingsWithLateTrainingSessionCount[] = [];
  await Aigle.mapSeries(trainingSessionsWithState, async trainingSession => {
    if (trainingSession.state === API.TrainingSessionState.STARTING_LATE) {
      const training = await API.getTrainingForATrainingVersion(trainingSession.trainingVersionId);
      if (API.isFailure(training)) {
        logger.warn(training);
        return training;
      }

      const index = trainingsWithLateTrainingSession.findIndex(
        object => object.training.name === training.name,
      );

      if (index > -1) {
        trainingsWithLateTrainingSession[index] = {
          training: training,
          numberOfLateTrainingSessions:
            trainingsWithLateTrainingSession[index].numberOfLateTrainingSessions + 1,
        };
      } else {
        trainingsWithLateTrainingSession.push({
          training: training,
          numberOfLateTrainingSessions: 1,
        });
      }
    }
  });

  return trainingsWithLateTrainingSession;
}

export function getTrainingEstimatedEndDate(
  trainingVersion: API.TrainingVersion,
  startingDate: number,
): moment.Moment {
  return moment(startingDate).add(trainingVersion.durationInMin, 'minutes');
}

/**
 * checks if the user has permission on all workers on unit
 * @param trainingSessionId
 */

export async function doesWorkerHavePermissionOnAllParticipants(
  trainingSessionId: string,
): Promise<boolean> {
  const trainingSession = await getTrainingSession(trainingSessionId);
  if (API.isFailure(trainingSession)) {
    logger.warn(trainingSession);
    return false;
  }

  const traineeAndTrainerIds = _.compact(
    _.union(
      ...getTrainerIdsFromTraininersWithPercentage(API.deepClone(trainingSession.trainers)),
      ...getTrainerIdsFromTraininersWithPercentage(
        API.deepClone(trainingSession.scheduledTrainers),
      ),
      trainingSession.traineeIds,
      trainingSession.scheduledTraineeIds,
    ),
  );

  let notAuthorizedOnSomeWorker = false;

  await Aigle.mapSeries(traineeAndTrainerIds, async traineeAndTrainerId => {
    if (traineeAndTrainerId) {
      const worker = await API.getWorker(traineeAndTrainerId, true);
      if (API.isFailure(worker)) {
        logger.warn(worker);
        notAuthorizedOnSomeWorker = true;
      }
    }
  });

  return !notAuthorizedOnSomeWorker;
}

/**
 * Check whether the login worker is a trainer on a training session
 * @param trainingSessionId
 * @returns
 */

export async function isLoginWorkerTrainerOnATrainingSession(
  trainingSessionId: string,
): Promise<API.Result<boolean>> {
  const worker = await API.getWorker();
  if (API.isFailure(worker)) {
    logger.warn(worker);
    return worker;
  }

  const trainingSession = await getTrainingSession(trainingSessionId);
  if (API.isFailure(trainingSession)) {
    logger.warn(trainingSession);
    return false;
  }

  const trainers = _.uniq([...trainingSession.trainers, ...trainingSession.scheduledTrainers]);

  if (
    trainers.find(trainer => trainer.trainerId === worker.id) &&
    API.getTrainingSessionStage(trainingSession) === API.TrainingSessionStage.InProgress
  )
    return true;

  return false;
}

/**
 * Create the TrainingSessions required for the given Worker
 * to reach the given targetLevel on the given Workstation.
 * @param workstation
 * @param workerId
 * @param targetLevel
 * @param removeWorkerFromTrainingSessionOnUpperLevel (default = true)
 * @param onlyForTrainingVersionIds (optional) if set, create Training request omly for the required Trainings that match this list
 * @param comments (optional) comment to add to the created TrainingSessions
 * @param endDateLimit (optional) endDateLimit to add to the created TrainingSessions
 */




export async function createTrainingSessionRequests(
  workstationId: string,
  workerId: string,
  targetLevel: WorkstationWorkerLevels,
  removeWorkerFromTrainingSessionOnUpperLevel: boolean = true,
  onlyForTrainingVersionIds?: string[],
  comments?: string,
  endDateLimit?: string,
): Promise<API.Result<API.TrainingSession[]>> {
  const errors: API.Failure[] = [];

  if (removeWorkerFromTrainingSessionOnUpperLevel) {
    const workerWorkstation = API.getWorkerWorkstations(workstationId, workerId);
    if (!workerWorkstation)
      return API.createFailure(
        'ObjectNotFound',
        `WorkerWorkstation not found while it should exist (workerId:(${workerId} workstationId:(${workstationId})`,
      );

    
    if (
      API.isWorkerInTrainAutoOnWorkstation(workerWorkstation) &&
      API.api2workstationWorkerLevels(workerWorkstation.targetLevel!) > targetLevel 
    ) {
      const trainingSessionsForWorker = await getTrainingSessionsForWorker(
        workerId,
        undefined,
        undefined,
        workstationId,
        true,
        true,
      );
      if (API.isFailure(trainingSessionsForWorker)) return trainingSessionsForWorker;

      await Aigle.map(trainingSessionsForWorker, async trainingSession => {
        if (trainingSession.requirementId) {
          const requirement = await API.getRequirement(trainingSession.requirementId);
          if (API.isFailure(requirement)) {
            errors.push(requirement);
            return;
          }

          
          if (API.api2workstationWorkerLevels(requirement.level) > targetLevel) {
            const updatedTrainingSession = await removeAndUpdateWorkerFromTrainingSession(
              workerId,
              trainingSession,
            );
            if (API.isFailure(updatedTrainingSession)) {
              errors.push(updatedTrainingSession);
              return;
            }
          }
        }
      });
      if (errors.length) return API.createFailure_Multiple(errors);
    }
  }

  const requiredTrainingVersionIds = await API.getTrainingVersionIdsForWorkerToReachLevel(
    workerId,
    workstationId,
    targetLevel,
    onlyForTrainingVersionIds ? true : false,
  );
  if (API.isFailure(requiredTrainingVersionIds)) return requiredTrainingVersionIds;

  const trainingSessions: API.TrainingSession[] = [];
  await Aigle.map(
    Array.from(requiredTrainingVersionIds),
    async ([trainingVersionId, requirement]) => {
      if (!onlyForTrainingVersionIds || onlyForTrainingVersionIds.includes(trainingVersionId)) {
        let defaultTrainerId;
        const trainingVersion = await API.getTrainingVersion(trainingVersionId);
        if (API.isFailure(trainingVersion)) errors.push(trainingVersion);
        else {
          const training = await API.getTraining(trainingVersion.trainingId);
          if (API.isFailure(training)) errors.push(training);
          else defaultTrainerId = training.defaultTrainerId;
        }

        const trainingSession = await createTrainingSession({
          trainingVersionId,
          requirementId: requirement.id,
          originId: workstationId,
          scheduledTraineeIds: [workerId],
          traineeIds: [],
          requestState: API.ReviewState.VALIDATED,
          description: comments ?? null,
          endDateLimit,
          scheduledTrainers: defaultTrainerId
            ? [{ trainerId: defaultTrainerId, percentage: '100%' }]
            : [],
          trainers: [],
        });
        if (API.isFailure(trainingSession)) {
          errors.push(trainingSession);
        } else {
          trainingSessions.push(trainingSession);
        }
      }
    },
  );
  if (errors.length) return API.createFailure_Multiple(errors);

  return trainingSessions;
}

/***
 * Function returns a string that display the status of the training
 * @param
 */

export function getTrainingStatusDisplayString(
  workerTrainingSessions: API.TrainingSession,
): string {
  let statusOfTrainings = '';

  const trainingSessionState = API.getTrainingSessionState(workerTrainingSessions);
  switch (trainingSessionState) {
    case API.TrainingSessionState.ENDED:
      statusOfTrainings = capitalizeFirstLetter(
        i18n.t('alex:trainingInfoPanel.trainingStates.ENDED'),
      );
      break;
    case API.TrainingSessionState.REQUEST:
      statusOfTrainings = capitalizeFirstLetter(
        i18n.t('alex:trainingInfoPanel.trainingStates.REQUEST'),
      );
      break;
    case API.TrainingSessionState.SCHEDULED:
      const _duration = Math.round(workerTrainingSessions.durationInMin ?? 0 / (60 * 24 * 30));
      statusOfTrainings = capitalizeFirstLetter(
        i18n.t('alex:trainingInfoPanel.trainingStates.SCHEDULED', {
          startDate: moment(workerTrainingSessions.scheduledStartDate ?? '').format('L'),
          duration: _duration,
          count: _duration,
        }),
      );
      break;
    case API.TrainingSessionState.STARTED:
      const daysTillEnd = date_diff_inDays(
        new Date(),
        workerTrainingSessions.endDate ? new Date(workerTrainingSessions.endDate) : new Date(),
      );
      statusOfTrainings = capitalizeFirstLetter(
        i18n.t('alex:trainingInfoPanel.trainingStates.STARTED', {
          daysSinceStart: date_diff_inDays(
            workerTrainingSessions.startDate
              ? new Date(workerTrainingSessions.startDate)
              : new Date(),
            new Date(),
          ),
          daysTillEnd,
        }),
      );
      break;
    case API.TrainingSessionState.DRAFT || API.TrainingSessionState.REQUEST_VALIDATED:
      statusOfTrainings = capitalizeFirstLetter(
        i18n.t('alex:trainingInfoPanel.trainingStates.TO_SCHEDULE'),
      );
      break;
  }

  return statusOfTrainings;
}

/**
 * Function checks for scheduled trainings are not yet started after the current date
 *
 * @param workerId string
 *
 * @returns boolean, true if trainings are fine else false
 */

export async function checkTrainingOK(workerId: string): Promise<boolean> {
  const trainingSessions = await getTrainingSessionsForWorker(workerId);
  if (API.isFailure(trainingSessions)) {
    logger.warn(trainingSessions);
    return false;
  }

  const trainingSessionStates = _.map(trainingSessions, trainingSession => {
    return API.getTrainingSessionState(trainingSession);
  });

  const isOk = (eachState: API.TrainingSessionState) =>
    eachState !== API.TrainingSessionState.STARTING_LATE;

  return trainingSessionStates.every(isOk);
}

/**
 * Checks that the repartition of the trainers in is equal to 100%
 * @param mentors
 * @returns
 */
export function checkMentorsPercentageValidy(
  mentors: WorkerDropDownOptionWithDetails[] | undefined,
): Boolean {
  if (!mentors) return false;

  let allPercentages = 0;
  mentors.map(mentor => {
    if (!mentor.trainingSessionCoveragePercentage) return;
    allPercentages = allPercentages + parseFloat(mentor.trainingSessionCoveragePercentage);
  });

  return Boolean(allPercentages === 100);
}

export function getTrainingSessionErrorType(
  buttonType: string,
  isPractical?: Boolean,
): TrainingSessionWarning {
  switch (buttonType) {
    case ButtonOnPressTypes.SCHEDULE:
      if (isPractical) return TrainingSessionWarning.TrainingScheduleErrorForPractical;
      else return TrainingSessionWarning.TrainingScheduleErrorForLecture;
    case ButtonOnPressTypes.START:
      return TrainingSessionWarning.TrainingStartError;
  }

  return TrainingSessionWarning.ErrorWarningForLecture;
}

export function groupTrainingSessions(
  trainingSessions: API.TrainingSessionWithDetail[],
): GroupedTrainingSessions {
  const _draftTrainingSessions: API.TrainingSessionWithDetail[] = [];
  const _requestValidatedSessions: API.TrainingSessionWithDetail[] = [];
  const _scheduledTrainingSessions: API.TrainingSessionWithDetail[] = [];
  const _startedTrainingSessions: API.TrainingSessionWithDetail[] = [];
  const _lateEndTrainingSessions: API.TrainingSessionWithDetail[] = [];

  _.forEach(trainingSessions, trainingSessionInfo => {
    switch (true) {
      case API.isTrainingSessionRequestValidated(trainingSessionInfo):
        _requestValidatedSessions.push(trainingSessionInfo);
        break;
      case API.isTrainingSessionDraft(trainingSessionInfo):
        _draftTrainingSessions.push(trainingSessionInfo);
        break;

      case API.isTrainingSessionScheduledOrLateStart(trainingSessionInfo):
        _scheduledTrainingSessions.push(trainingSessionInfo);
        break;

      case API.isTrainingSessionStartedOrStartedLate(trainingSessionInfo):
        _startedTrainingSessions.push(trainingSessionInfo);
        break;

      case API.isTrainingSessionLateEnd(trainingSessionInfo):
        _lateEndTrainingSessions.push(trainingSessionInfo);
        break;

      default:
        break;
    }
  });

  return {
    requested: _requestValidatedSessions,
    draft: _draftTrainingSessions,
    scheduled: _scheduledTrainingSessions,
    started: _startedTrainingSessions,
    lateEnded: _lateEndTrainingSessions,
  };
}
