import { StyleSheet } from 'react-native';
import { Spacings, Colors, TextFontStyles, fontSize, getCorrectedSpacings } from 'shared/styles';

const styles = StyleSheet.create({
  rightPanelContainer: {
    backgroundColor: Colors.White,
    flex: 1,
    paddingBottom: Spacings.Standard,
    paddingLeft: Spacings.Unit * 8,
    maxWidth: '50%',
    justifyContent: 'space-between',
    borderBottomRightRadius: 8,
  },
  rightPanelInputsContainer: {
    width: 300,
  },
  skillDropDown: {
    width: '100%',
    zIndex: 4,
  },
  fullWidth: {
    width: '100%',
  },
  trainingMenuContainer: {
    top: Spacings.Large,
    width: '100%',
  },
  workerDropDown: {
    zIndex: 3,
    width: '100%',
  },
  obtentionDateAndValidityContainer: {
    width: '100%',
    height: 64,
    zIndex: 2,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  rightAndLeftPanel: {
    backgroundColor: Colors.White,
    width: '100%',
    flexDirection: 'row',
    flex: 1,
    borderBottomRightRadius: Spacings.Small,
    borderBottomLeftRadius: Spacings.Small,
  },
  closeButtonIcon: {
    position: 'absolute',
    right: Spacings.xMedium,
    top: 9,
  },
  bottomPanelContainer: {
    zIndex: -1,
    height: 64,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  summaryIconContainer: {
    paddingLeft: Spacings.Large + Spacings.Unit,
    paddingRight: Spacings.Large,
  },
  patchSwitchContainer: {
    width: 222,
  },
  submitButtonStyle: {
    width: 300,
    height: 32,
  },
  submitButtonTextStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.White,
  },
  buttonContainer: {
    paddingLeft: 120,
  },
  leftPanel: {
    flex: 1,
    maxWidth: '50%',
    backgroundColor: Colors.Background,
    borderBottomLeftRadius: 8,
    maxHeight: 444,
    paddingTop: 64,
    paddingHorizontal: 77,
  },
  trainingIcon: {
    paddingBottom: Spacings.Standard,
  },
  proofBookCompleteTitle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    color: Colors.MediumLighterGrey,
    textAlign: 'center',
  },
  proofBookCompleteDescription: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.MediumLighterGrey,
    textAlign: 'center',
  },
  addCommonProofText: {
    ...TextFontStyles.LatoBold,
    fontSize: fontSize.Small,
  },
  addCommonProofContainer: {
    height: Spacings.Standard,
  },
  addCommonProofButtonContainer: {
    paddingTop: Spacings.Big,
  },
  proofBookComplete: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  summaryTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: Spacings.Standard,
    paddingBottom: Spacings.Small,
  },
  summaryBookContainer: {
    flex: 1,
    paddingLeft: Spacings.Large,
    maxHeight: 444,
  },
  summaryText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingLeft: Spacings.Medium,
  },
  summaryEachRowContainer: {
    flexDirection: 'row',
    paddingVertical: Spacings.Medium,
    alignItems: 'center',
    borderBottomColor: Colors.GreyLighter,
    borderBottomWidth: 0.5,
    borderStyle: 'dashed',
    marginRight: Spacings.Big + Spacings.CardPadding,
  },
  summaryWorkerName: {
    paddingLeft: Spacings.xMedium,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  summarySkillName: {
    paddingLeft: Spacings.Small,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  skillIconContainer: {
    paddingLeft: Spacings.Small,
  },
  skillNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  summarySkillContainer: {
    justifyContent: 'space-between',
  },
  radioButtonContainer: {
    paddingTop: 33,
  },
  radioButtonText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  commentsContainer: {
    zIndex: -4,
  },
  modalHeader: {
    zIndex: 1,
  },
  headerTitleContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  rejectModalContainer: {
    minHeight: 300,
    width: 700,
  },
  proofModalContentContainer: {
    padding: Spacings.CardPadding * 2,
    zIndex: 1,
  },
  proofModalValidationContentContainer: {
    padding: Spacings.CardPadding * 2,
    zIndex: 1,
    alignItems: 'center',
  },
  proofModalContentWarning: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Large,
    color: Colors.Black,
  },

  proofModalNotAcquiredNames: {
    paddingTop: Spacings.Small,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },

  proofModalContainerButton: {
    padding: Spacings.Small,
    paddingHorizontal: getCorrectedSpacings(31),
    backgroundColor: Colors.White,
    borderRadius: Spacings.CardPadding,
    borderWidth: 1,
    width: 250,
  },
  proofModalReviewContainerButton: {
    padding: Spacings.Small,
    paddingHorizontal: getCorrectedSpacings(31),
    backgroundColor: Colors.White,
    borderRadius: Spacings.CardPadding,
    borderWidth: 1,
    width: 500,
  },
  proofModalContainerBottonText: {
    ...TextFontStyles.LatoBold,
    fontSize: 16,
    color: Colors.Black,
  },
  proofModalContainer: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingBottom: Spacings.CardPadding,
  },
  rejectModalBackground: {
    paddingTop: 231,
  },
  workerSkillNameContainer: {
    maxHeight: 150,
  },

  reviewModalPadding: {
    marginTop: Spacings.Standard,
    marginBottom: Spacings.Standard * 2,
  },
  proofRejectionModalButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingBottom: Spacings.Standard,
  },
  loader: {
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
});

export default styles;
