import { Platform } from 'react-native'; 
import moment from 'moment';
import { A4HeightInPoints, A4WidthInPoints } from 'shared/util/CertificateTemplates';
import { AsyncLock } from '../util-ts/AsyncLock';
import { isPlatformServerless } from 'shared/util-ts/Functions';

const PDFFileExtension = '.pdf';

export interface PDFFile {
  uri?: string;
  fileName: string;
  fileContent: string;
}

export interface PDFOptionsObject {
  htmlOrFileContent: string;
  fileName?: string;
  width: number;
  height: number;
  padding?: number;
}

export const pdfContentType = 'application/pdf';

/**
 * This lock was added because mutliple parallel calls for html2canvas will start the rendering
 * of multiple Html and it will get overloaded and will not finish the rendering
 */
const convertHtmlToPdfLock = new AsyncLock();
export async function convertHtmlToPdfOrCreatePdf(options: PDFOptionsObject): Promise<PDFFile> {
  return convertHtmlToPdfLock.runSerial(async () => {
    const isPlatformWeb = Platform.OS === 'web';
    const bottomPadding = 30;

    const uniqueFileName = options.fileName + moment().toISOString() + PDFFileExtension;
    const fileName = options.fileName ? options.fileName + PDFFileExtension : undefined;

    if (isPlatformServerless) {
      const jsPDF = require('jspdf').default;
      const pageWidth = options.width;
      const pageHeight = options.height;

      const doc = new jsPDF('p', 'px', [pageWidth, pageHeight]);
      doc.setFontSize(20);
      doc.text(options.htmlOrFileContent, 10, 10);

      return {
        fileContent: doc.output('arraybuffer'),
        fileName: fileName ?? uniqueFileName,
      };
    } else if (isPlatformWeb) {
      const jsPDF = require('jspdf').default;
      const html2canvas = require('html2canvas');
      const pageWidth = options.width;
      const htmlElement = document.createElement('div');

      htmlElement.setAttribute('style', `width: ${options.width}px;`);
      htmlElement.innerHTML = options.htmlOrFileContent;

      document.body.appendChild(htmlElement);

      const pageHeight =
        htmlElement.offsetHeight < options.height
          ? options.height
          : htmlElement.offsetHeight + bottomPadding;
      const doc = new jsPDF('p', 'px', [pageWidth, pageHeight]);
      const canvas = await html2canvas(htmlElement, {
        height: pageHeight,
        width: pageWidth,
      });

      htmlElement.remove();

      const image = canvas.toDataURL('image/jpeg', 1);

      doc.addImage(image, 'JPEG', 20, 20, pageWidth, pageHeight);
      return {
        fileContent: doc.output('datauristring'),
        fileName: fileName ?? uniqueFileName,
      };
    } else {
      const RNHTMLtoPDF = require('react-native-html-to-pdf').default;

      const _options = {
        html: options.htmlOrFileContent,
        fileName: uniqueFileName,
        width: A4WidthInPoints,
        height: A4HeightInPoints,
        padding: options.padding,
        base64: true,
      };

      const file = await RNHTMLtoPDF.convert(_options);

      return {
        uri: file.filePath!,
        fileContent: file.base64!,
        fileName: fileName ?? uniqueFileName,
      };
    }
  });
}
