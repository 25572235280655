import React from 'react';
import { ViewStyle, StyleProp } from 'react-native';
import * as API from 'shared/backend-data';
import * as _ from 'lodash-es';
import { TrainingCardComponent } from '../component/index';

export interface TrainingCardProps {
  trainingVersionAndTraining:
  | API.TrainingVersionAndTraining
  | API.TrainingVersionAndTrainingCreateInput;
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * forceDisplayIsPracticalTraining is passed when the Training Version is not created yet, so we can't retrieve the isPractical property from it
   */
  forceDisplayIsPracticalTraining?: boolean;
  /**
   * forceDisplaySkillNames is passed to display an list of skill names, that are not created yet (unable to fetch them from skillId)
   */
  forceDisplaySkillNames?: string[];
}

export const TrainingCard: React.FC<TrainingCardProps> = props => {
  return <TrainingCardComponent {...props} />;
};
