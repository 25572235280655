import { StyleSheet } from 'react-native';
import { Colors, Spacings, TextFontStyles } from 'shared/styles';
import * as SharedStyles from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.LightBlue,
    height: '100%',
    width: '100%',
    paddingLeft: SharedStyles.Spacings.CardPadding,
    paddingBottom: SharedStyles.Spacings.Small + Spacings.Unit,
  },
  smallColumn: {
    maxHeight: 320,
    width: 224,
    marginRight: 12,
    padding: 0,
    shadowColor: Colors.ShadowColor,
    shadowOpacity: 1,
    shadowRadius: 2,
  },
  bigColumn: {
    width: 330,
    marginRight: 12,
    shadowColor: Colors.ShadowColor,
    shadowOpacity: 1,
    shadowRadius: 2,
    paddingTop: Spacings.Medium,
    paddingBottom: Spacings.Small,
    paddingHorizontal: Spacings.Small + Spacings.Unit,
  },
  scrollViewContainer: {
    flexDirection: 'row',
    paddingBottom: 2,
  },
  textCountStyle: { ...TextFontStyles.LatoBlack, fontSize: Spacings.Medium },
  textHeaderStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: Spacings.Medium,
  },
  textBoldHeaderStyle: {
    ...TextFontStyles.LatoBlack,
    fontSize: Spacings.Medium,
  },
  textItemStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
  },
  columnHeaderStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: Spacings.Medium,
  },
  catalogContainer: {
    paddingHorizontal: Spacings.Medium,
    paddingTop: Spacings.Small + Spacings.Unit,
    paddingBottom: 14,
    flexDirection: 'row',
    flex: 1,
  },
  catalogTextBoldStyle: {
    ...TextFontStyles.LatoBold,
    fontSize: Spacings.Medium,
    marginLeft: Spacings.Small,
  },
  catalogTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: Spacings.Medium,
    marginLeft: Spacings.Small,
  },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: Spacings.Medium,
    paddingTop: Spacings.Medium,
    paddingBottom: Spacings.xMedium,
  },
  itemContainer: {
    height: Spacings.Medium,
    flexDirection: 'row',
    marginBottom: Spacings.Standard,
  },
  greyBubbleView: {
    borderRadius: 10,
    minWidth: Spacings.Standard,
    height: Spacings.Medium,
    backgroundColor: Colors.GreyLight,
    marginBottom: -1,
    paddingHorizontal: Spacings.Unit,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countNumber: {
    ...TextFontStyles.LatoBlack,
    fontSize: 11,
    color: Colors.White,
  },
  flexView: {
    flex: 1,
  },
  columnHeaderContainer: {
    flexDirection: 'row',
    marginBottom: 17,
    paddingLeft: Spacings.Small,
    zIndex: 2,
  },
  addTrainingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: Spacings.Standard * 2,
    backgroundColor: Colors.GreyUltraLight,
    borderRadius: Spacings.Small,
    marginBottom: Spacings.Small + Spacings.Unit,
    paddingLeft: Spacings.Standard - Spacings.Small,
    paddingRight: Spacings.Small + Spacings.Unit,
  },
  columnMenu: {
    position: 'absolute',
    marginLeft: '28%',
    zIndex: 2,
    width: 176,
    height: 140,
    paddingLeft: Spacings.Standard,
    paddingTop: Spacings.Standard - Spacings.Unit,
  },
  columnHeaderText: {
    ...TextFontStyles.LatoRegular,
    fontSize: Spacings.Small + Spacings.Unit,
    color: Colors.Grey,
  },
  checkBox: {
    marginTop: Spacings.Medium,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: 'rgba(52, 52, 52, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addTrainingMenu: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: Colors.White,
    shadowColor: Colors.ShadowColor2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
  },
  addTrainingModalTitle: {
    ...TextFontStyles.LatoMedium,
    width: 200,
    textAlign: 'center',
    fontSize: SharedStyles.fontSize.Big,
    marginTop: 45,
    marginRight: 25,
    marginLeft: 61,
  },
  croosIconStyle: {
    alignSelf: 'flex-start',
    marginTop: Spacings.Small + Spacings.Unit,
  },
  addTrainingRowContainerStyle: {
    width: '100%',
    paddingRight: 40,
    paddingLeft: Spacings.Medium,
    paddingTop: Spacings.Medium,
    paddingBottom: Spacings.Medium,
    borderRadius: 8,
  },
  trainingCardContainer: {
    marginBottom: Spacings.Small + Spacings.Unit,
  },
  boldTextOption: {
    ...TextFontStyles.LatoBold,
    fontSize: Spacings.Medium,
    maxWidth: '100%',
  },
  textOption: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    maxWidth: '100%',
  },
  rowView: {
    flexDirection: 'row',
  },
  divider: {
    width: Spacings.xMedium,
  },
  onHoverButtonStyle: { position: 'absolute', right: 12, top: 15 },
  centeredTextStyle: { textAlign: 'center', maxWidth: 130 },
  scrollViewStyle: { zIndex: -2 },
  requestTrainingMenuStyle: {
    zIndex: 999,
    position: 'absolute',
    left: 225,
    top: 0,
    borderRadius: 8,
    minWidth: 344,
  },
});

export const standardIconStyle = { width: Spacings.Standard, height: Spacings.Standard };

export default styles;
