import * as _ from 'lodash-es';
import React from 'react';
import { Text, View, ViewProps, TextStyle, StyleProp, StyleSheet } from 'react-native';
import { Colors, TextFontStyles, fontSize } from 'shared/styles';

interface Props extends ViewProps {
  /**
   * true/false/null will give default colors
   * undefined will give loading color
   * string will set the given color
   */
  dotColor?: (boolean | null) | string;
  /**
   * If set, dotColor is ignored and no badge point color is displayed
   */
  value?: string | number;
  valueStyle?: StyleProp<TextStyle>;
}

export const Badge: React.FC<Props> = props => {
  const { dotColor, value, style, valueStyle, ...otherProps } = props;

  let containerStyle = [style];

  if (!value && value !== 0) {
    const loading = dotColor === '...' || dotColor === undefined;

    let badgeColor;
    if (loading) badgeColor = Colors.Grey;
    else if (dotColor === null) badgeColor = Colors.Orange;
    else if (_.isString(dotColor)) badgeColor = dotColor;
    else if (dotColor) badgeColor = Colors.Green;
    else badgeColor = Colors.Red;

    containerStyle = [styles.container, { backgroundColor: badgeColor }, style];
  }

  return (
    <View style={containerStyle} {...otherProps}>
      {(value || value === 0) && <Text style={[styles.badgeText, valueStyle]}>{value}</Text>}
    </View>
  );
};

const BADGE_SIZE = 6;
const styles = StyleSheet.create({
  container: {
    width: BADGE_SIZE,
    height: BADGE_SIZE,
    borderRadius: BADGE_SIZE / 2,
  },
  badgeText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
});
