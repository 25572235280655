import React, { useEffect, useState, useContext } from 'react';
import { View, ScrollView } from 'react-native';
import WebModal from 'modal-react-native-web';
import {
  ModalBackgroundStyle,
  ModalBodyWithAnchorStyle,
  ModalCardStyle,
} from 'shared/styles/ModalStyles';
import { ModalHeader } from 'shared/ui-component/Modal/Header';
import { t, getGender } from 'shared/localisation/i18n';
import { useHistory } from 'react-router-dom';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as API from 'shared/backend-data';
import { MyHub } from 'shared/util/MyHub';
import * as _ from 'lodash-es';
import { ModalUtils } from 'shared/ui-component/Modal';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { loggerAPI as logger } from 'shared/util/Logger';
import { SkillInputPanel } from './SkillInputPanel';
import { SkillTrainingTypePanel } from './SkillTrainingTypePanel';
import { ButtonsPanel } from './ButtonsPanel';
import { SkillTrainingsPanel } from './SkillTrainingsPanel';
import { SkillSecondaryInputPanel } from './SkillSecondaryInputPanel';
import { Styles } from './Styles';
import { handleWarningModal } from '../../../../workstations/component/RequirementTable';
import { deleteSkillWithErrorModalHandling } from 'shared/util/skillUi';

export interface AddSkillToNodeLevelRequirement {
  nodeId: string;
  requirementId?: string;
  requirementLevel?: API.WorkstationWorkerLevels;
  isNodeOrgUnit?: boolean;
}

interface Props {
  skillId?: string;
  skillNamePlaceholder?: string;
  addSkillToNodeLevelRequirement?: AddSkillToNodeLevelRequirement;
  readOnly?: boolean;

  handleModalClose: () => void;
}

export interface SkillCreateInputWithoutTrainingType
  extends Omit<API.SkillCreateInput, 'isPractical'> {
  isPractical?: boolean;
}

const defaultSkillCreateInput: SkillCreateInputWithoutTrainingType = {
  name: '',
  isPractical: undefined,
  tagIds: [],
  description: '',
  validityDuration: null,
  expiryNoticeDuration: null,
  files: [],
};

export const ModifySkillModal: React.FC<Props> = props => {
  const { skillId, skillNamePlaceholder, readOnly, handleModalClose } = props;

  const history = useHistory();

  const isMounted = useIsMounted();

  const modal = ModalUtils.useModal();

  const [skill, setSkill] = useState<SkillCreateInputWithoutTrainingType>(defaultSkillCreateInput);
  const [isValid, setIsValid] = useState(false);
  const [skillFiles, setSkillFiles] = useState<API.S3ObjectInput[]>([]);
  const [loading, setLoading] = useState(false);
  const [trainingVersionsAndTrainingsOnHold, setTrainingVersionsAndTrainingsOnHold] = useState<
    API.TrainingVersionAndTrainingCreateInput[]
  >([]);
  const [originalSkill, setOriginalSkill] = useState<API.Skill>();

  const { isValidPermission } = useContext(PermissionManagementContext);

  useEffect(() => {
    if (skillId) {
      fetchSkill(skillId);
    } else {
      setSkill({ ...defaultSkillCreateInput, name: skillNamePlaceholder ?? '' });
      setOriginalSkill(undefined);
    }
  }, [skillId, skillNamePlaceholder]);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.SKILL &&
        (data.tooManyMutations || data.factory.skill.id === skill.id)
      ) {
        fetchSkill();
      }
    });

    return () => {
      removeListener();
    };
  }, [skill]);

  useEffect(() => {
    validateSkillInput();
  }, [skill]);

  async function fetchSkill(skillId?: string) {
    const _skillId = skillId || skill.id;
    if (!_skillId) return;

    const _skill = await API.getSkill(_skillId);
    if (!isMounted.current) return;
    if (API.isFailure(_skill)) return _skill;

    setSkill(API.deepClone(_skill));
    setOriginalSkill(_skill);
  }

  async function onSubmit(): Promise<void> {
    if (!isValid) return;
    setLoading(true);

    
    const result = props.skillId
      ? await API.updateFactoryBusinessObject(API.DataType.SKILL, {
          ...skill,
          id: props.skillId,
          files: skillFiles,
        })
      : await API.createSkill({
          ...skill,
          isPractical: Boolean(skill.isPractical), 
          files: skillFiles,
        });
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      if (API.isFailureType(result, 'DeletionVeto')) {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: t('alex:skills.notAbleToDeleteTrainingVersion'),
            warningAcceptButton: t('common:button.ok'),
          }),
        );
      } else if (API.isFailureType(result, 'UpdateVeto')) {
        if (result.message.includes(API.DataType.ORGUNIT))
          ModalUtils.showVetoModal(
            t('alex:workstations.workstationPanel.requirementTable.addParcticalSkillOnUnitWarning'),
            result,
          );
        else ModalUtils.showVetoModal(t('alex:skills.practicalSkillUsedInRequirement'), result);
      } else if (API.isFailureType(result, 'DuplicateVeto')) {
        ModalUtils.showVetoModal(t('alex:skills.duplicateSkillName'), result);
      } else {
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: t('common:error.retry'),
            warningAcceptButton: t('common:button.ok'),
          }),
        );
      }
      logger.warn('Failed to create or update Skill', result);

      setLoading(false);
      return;
    }

    
    
    
    
    if (trainingVersionsAndTrainingsOnHold.length && !skill.isPractical) {
      const errors: API.Failure[] = [];

      await Promise.all(
        _.map(trainingVersionsAndTrainingsOnHold, async trainingVersionAndTraining => {
          if (!trainingVersionAndTraining[1].id) {
            const training = await API.createTraining(trainingVersionAndTraining[1], {
              ...trainingVersionAndTraining[0],
              skillIds: [...trainingVersionAndTraining[0].skillIds, result.skill.id],
            });
            if (isMounted.current) return;
            if (API.isFailure(training)) errors.push(training);
          } else if (trainingVersionAndTraining[0].id) {
            const trainingVersion = await API.updateTrainingVersioMaybeDelete({
              ...(trainingVersionAndTraining[0] as API.TrainingVersionUpdateInput), 
              
              skillIds: [...trainingVersionAndTraining[0].skillIds, result.skill.id],
            });
            if (isMounted.current) return;
            if (API.isFailure(trainingVersion)) errors.push(trainingVersion);
          }
        }),
      );

      if (errors.length)
        modal.displayModal(
          ModalUtils.warningConfig({
            warningMessage: t('alex:skills.trainingCreationFail'),
            warningAcceptButton: t('common:button.ok'),
          }),
        );
    }

    if (props.addSkillToNodeLevelRequirement && !props.skillId) {
      const _result = addSkillAndCreateOrUpdateRequirment(
        result.skill,
        props.addSkillToNodeLevelRequirement,
      );
      if (API.isFailure(_result))
        logger.error('Failed to attach the added skill to node level requirement', _result);
    }

    setLoading(false);

    handleModalClose();
  }

  async function addSkillAndCreateOrUpdateRequirment(
    skill: API.NoMetadata<API.Skill>,
    addSkillToNode: AddSkillToNodeLevelRequirement,
  ) {
    if (skill.isPractical && addSkillToNode.isNodeOrgUnit) {
      handleWarningModal(
        modal,
        t('alex:workstations.workstationPanel.requirementTable.addParcticalSkillOnUnitWarning'),
        undefined,
        t('common:button.gotIt'),
      );
      return;
    }
    if (addSkillToNode.requirementId) {
      const sourceRequirement = await API.getRequirement(addSkillToNode.requirementId);
      if (API.isFailure(sourceRequirement)) return;
      const allTrainingVersions = await API.getTrainingVersions(undefined, undefined, true);
      if (API.isFailure(allTrainingVersions)) {
        logger.warn('Failed to fetch training versions');
        return;
      }
      const skillTrainingVersions: API.SkillTrainingVersionInput[] = [];
      if (skill.isPractical) {
        skillTrainingVersions.push({
          skillId: skill.id,
          trainingVersionId: null,
        });
      } else {
        _.forEach(API.createSkillTrainingVersions([skill.id]), skillTrainingVersion => {
          const possibleTrainingVerisions: API.TrainingVersion[] = [];
          const trainingVersionId = API.extractTrainingVersionIdForSkill(
            sourceRequirement,
            skillTrainingVersion.skillId,
          );

          _.forEach(allTrainingVersions.result, trainingVersion => {
            if (_.includes(trainingVersion.skillIds, skill.id))
              possibleTrainingVerisions.push(trainingVersion);
          });

          if (possibleTrainingVerisions.length === 1) {
            skillTrainingVersions.push({
              skillId: skillTrainingVersion.skillId,
              trainingVersionId: possibleTrainingVerisions[0].id,
            });
          } else if (skillTrainingVersion)
            skillTrainingVersions.push({
              skillId: skillTrainingVersion.skillId,
              trainingVersionId: trainingVersionId ?? null,
            });
        });
      }

      const updated = await API.updateRequirement({
        ...sourceRequirement,
        skillTrainingVersions: [
          ...sourceRequirement.skillTrainingVersions,
          ...skillTrainingVersions,
        ],
      });
      if (!isMounted.current) return;
      if (API.isFailure(updated)) {
        return updated;
      }
    } else if (addSkillToNode.requirementLevel) {
      const skillTrainingVersions = API.createSkillTrainingVersions([skill.id]);
      const created = await API.createRequirement({
        linkedObjectId: addSkillToNode.nodeId,
        level: API.workstationWorkerLevels2api(addSkillToNode.requirementLevel),
        skillTrainingVersions,
      });
      if (!isMounted.current) return;
      if (API.isFailure(created)) {
        return created;
      }
    }
  }

  function onPassiveSubmit(
    trainingVersionAndTraining: API.TrainingVersionAndTrainingCreateInput,
  ): void {
    setTrainingVersionsAndTrainingsOnHold(prev => [...prev, trainingVersionAndTraining]);
  }

  async function onPressDeleteSkill() {
    if (!props.skillId) return;

    const skillDependencies = await API.getSkillDependencies(props.skillId);
    if (!isMounted.current) return;
    if (API.isFailure(skillDependencies)) return;

    if (!skillDependencies.length) {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: `${t('common:error.delete', { context: getGender('skill') })} ${
            skill.name
          }?`,
          warningAcceptCallback: onConfirmDeleteSkill,
          warningAcceptButton: t('common:button.yes'),
          warningCancelButton: t('common:button.no'),
        }),
      );
    } else {
      onConfirmDeleteSkill();
    }
  }

  async function onConfirmDeleteSkill() {
    if (!props.skillId) return;

    const skill = await API.getSkill(props.skillId);
    if (!isMounted.current) return;
    if (API.isFailure(skill)) {
      logger.warn('error deleting Skill', skill);
      return;
    }

    const deletedSkill = await deleteSkillWithErrorModalHandling(skill, modal);
    if (!isMounted.current) return;
    if (deletedSkill === false) {
      return;
    }
    history.goBack();
  }

  function validateSkillInput() {
    let valid = true;
    
    if (!skill.name.length) valid = false;

    
    if (
      (skill.validityDuration && !skill.expiryNoticeDuration) ||
      (!skill.validityDuration && skill.expiryNoticeDuration)
    )
      valid = false;

    
    if (!_.isBoolean(skill.isPractical)) valid = false;

    
    if (!!skill.skillIds && !skill.skillIds.length) valid = false;

    setIsValid(valid);
  }

  function onSkillFilesChange(files: API.S3ObjectInput[]) {
    setSkillFiles([...files]);
  }

  function isShowTrainingsPanel(): boolean {
    return _.isBoolean(skill.isPractical);
  }

  function isShowBottomPanel(): boolean {
    return isValidPermission(API.Permission.skills_edit) && !readOnly;
  }

  return (
    <WebModal animationType="fade" transparent visible={true}>
      {loading && <Loader />}
      <View style={ModalBackgroundStyle}>
        <View style={[ModalCardStyle]}>
          <ModalHeader handleModalClose={() => handleModalClose()} title={t('glossary:skill')} />
          <ScrollView invertStickyHeaders stickyHeaderIndices={[1]}>
            <View style={[ModalBodyWithAnchorStyle, Styles.rowDirection]}>
              <View style={Styles.leftPanelContainer}>
                <SkillInputPanel
                  isPanelEditable={isValidPermission(API.Permission.skills_edit) && !readOnly}
                  skill={skill}
                  setSkill={setSkill}
                  onSubmitForm={onSubmit}
                />

                <SkillSecondaryInputPanel
                  skill={skill}
                  isPanelEditable={isValidPermission(API.Permission.skills_edit) && !readOnly}
                  setSkill={setSkill}
                  onFilesChange={onSkillFilesChange}
                />
              </View>
              <View>
                <SkillTrainingTypePanel
                  skill={skill}
                  isPanelEditable={isValidPermission(API.Permission.skills_edit) && !readOnly}
                  setSkill={setSkill}
                />
                {isShowTrainingsPanel() && (
                  <SkillTrainingsPanel
                    trainingVersionsAndTrainings={trainingVersionsAndTrainingsOnHold}
                    editSkill={originalSkill}
                    skill={skill}
                    isPanelEditable={isValidPermission(API.Permission.skills_edit) && !readOnly}
                    setTrainingVersionsAndTrainings={setTrainingVersionsAndTrainingsOnHold}
                    onPassiveSubmit={onPassiveSubmit}
                    setLoading={setLoading}
                  />
                )}
              </View>
            </View>
          </ScrollView>
          {isShowBottomPanel() ? (
            <ButtonsPanel
              editedSkill={originalSkill}
              isValid={isValid}
              onSubmit={onSubmit}
              onPressDeleteSkill={onPressDeleteSkill}
            />
          ) : (
            <View style={Styles.bottomPanelContainer} />
          )}
        </View>
      </View>
    </WebModal>
  );
};
