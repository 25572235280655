import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '5.8vw',
    height: '4.4vh',
    backgroundColor: '#F1F3F4',
    borderColor: '#D6D6D6',
    borderWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
    borderRadius: 5,
  },
  count: {
    fontSize: '1.75vh' as any,
    color: '#666666',
    fontFamily: 'Lato, Regular',
  },
  countContainer: {
    width: '65%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: '0.6vw',
  },
  buttonContainer: {
    width: '35%',
  },
  buttonUp: {
    height: '50%',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonDown: {
    height: '50%',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    height: '1vh',
    width: '1vh',
    resizeMode: 'contain',
  },
  countLabel: {
    fontSize: '1.75vh' as any,
    color: '#666666',
    fontFamily: 'Lato, Regular',
  },
});

export default styles;
