import React from 'react';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Text, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import { Tag } from './index';
import Style from './Style';

interface Props {
  tag: Tag;
  highlightString?: string | undefined;
  selectData: (tag: Tag) => void;
  style?: StyleProp<ViewStyle> | undefined;
}

export const AutoCompleteItem: React.FC<Props> = props => {
  const highlight = props.highlightString ? props.highlightString : '';
  const SplitedTexts = props.tag.label.split(RegExp(`(${highlight})`, 'gi'));
  return (
    <TouchableOpacity
      style={props.style}
      onPress={() => props.selectData(props.tag)}
      key={props.tag.key}
      ref={useCallOnHover()}
    >
      {
        <Text style={Style.suggestionText} numberOfLines={2}>
          {SplitedTexts.map((part, i) => (
            <Text
              key={i}
              style={part.toLowerCase() === highlight.toLowerCase() ? Style.highlight : {}}
            >
              {part}
            </Text>
          ))}
        </Text>
      }
    </TouchableOpacity>
  );
};
