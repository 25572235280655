import React, { useState, useContext, useEffect } from 'react';
import { InviteNonReferencedWorkerPageComponent } from '../component';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { isValidIndexedAssignment } from 'skillmgtweb/src/components/my-factory/workers/component/modify-worker-modal/container';
import { inviteWorkerModalFilterContext } from 'shared/context/inviteWorkerModalContext';
import { ModalUtils } from 'shared/ui-component/Modal';
import {
  showWarningWorkerEditAdd,
  WorkerEditAddWarning,
} from 'skillmgtweb/src/components/my-factory/workers/component/modify-worker-modal/warningModal';
import { t } from 'shared/localisation/i18n';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { WorkerAssignmentsInfo } from 'shared/context/inviteWorkerModalContext';
import { whiteSpaceWorkerName, emptyString } from 'shared/context/AppContext';
import * as Pattern from 'shared/util/const';
import { InteractionManager } from 'react-native';
import { showInvitationErrorModal, inviteWorker } from 'shared/util/WorkerUi';

interface Props {
  searchedValue?: string;
  handleModalClose: () => void;
}

export type Input = {
  name: string;
  value: string;
};

export type InputEvent = {
  target: Input;
};

export const InviteNonReferencedWorkerPage: React.FC<Props> = props => {
  const [existingWorkerData, setExistingWorkerData] = useState<WorkerAssignmentsInfo>();
  const [email, setEmail] = useState<string | null>(null);
  const [validEmail, setValidEmail] = useState(false);
  const [allowProceeding, setAllowProceeding] = useState(false);
  const [assignments, setAssignments] = useState<API.IndexedAssignment[]>([]);
  const [inviteUserRetryCount, setInviteUserRetryCount] = useState<number>();
  const [createdWorker, setCreatedWorker] = useState<API.NoMetadata<API.Worker>>();

  const modal = ModalUtils.useModal();

  const isMounted = useIsMounted();

  const maxNumberOfInviteRetries = 10;

  const {
    workers: [workers],
    loading: [, setLoading],
  } = useContext(inviteWorkerModalFilterContext);

  useEffect(() => {
    if (existingWorkerData) {
      const _existingWorkers = workers.filter(workerData => {
        return workerData.worker.email === existingWorkerData.worker.email;
      });
      setExistingWorkerData(_existingWorkers[0]);
    }
  }, [workers]);

  useEffect(() => {
    if (assignments.length) {
      if (isValidIndexedAssignment(assignments)) {
        setAllowProceeding(true);
      } else {
        if (allowProceeding) setAllowProceeding(false);
      }
    }
  }, [assignments]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      if (inviteUserRetryCount && createdWorker) {
        if (inviteUserRetryCount < maxNumberOfInviteRetries) {
          logger.warn('retrying the invite user operation', inviteUserRetryCount);
          const invite = await inviteWorker(
            createdWorker,
            modal,
            API.UserInvitationOperations.GIVE_ACCESS,
            props.handleModalClose,
          );
          if (!isMounted.current) return;
          if (API.isFailure(invite)) setInviteUserRetryCount(inviteUserRetryCount + 1);
          else setLoading(false);
        } else {
          showInvitationErrorModal(modal, API.UserInvitationOperations.GIVE_ACCESS);
          setLoading(false);
        }
      }
    });
  }, [inviteUserRetryCount]);

  useEffect(() => {
    if (props.searchedValue) {
      setEmail(props.searchedValue);
      if (Pattern.emailPattern.test(props.searchedValue)) {
        const existingWorker = workers.filter(workerData => {
          return workerData.worker.email === props.searchedValue;
        });
        if (existingWorker.length) {
          setExistingWorkerData(existingWorker[0]);
        } else {
          setExistingWorkerData(undefined);
          if (!validEmail) setValidEmail(true);
        }
      } else if (validEmail) setValidEmail(false);
    }
  }, [props.searchedValue]);

  function showErrorWarning(failure: API.Failure) {
    setLoading(false);

    if (API.isFailureType(failure, 'WorkerIdentifierNotUnique')) {
      if (failure.data.type !== 'Email') {
        logger.warn(
          "Failure's type shall be email, not '" + failure.data.type + "'. Please check the code.",
          failure,
        );
      }
      ModalUtils.showVetoModal(
        t('alex:worker.addEditWorker.warnings.duplicateEmail'),
        failure,
        () => {
          modal.hideModal();
          props.handleModalClose();
        },
      );
    } else {
      modal.displayModal(
        ModalUtils.warningConfig({
          warningMessage: t('alex:inviteWorker.errorMessage.0'),
          warningSubMessage: t('alex:inviteWorker.errorMessage.1'),
          warningAcceptCallback: handleSubmit,
          warningAcceptButton: t('common:button.yes'),
          warningCancelButton: t('common:button.no'),
        }),
      );
    }
  }

  async function handleSubmit() {
    setLoading(true);

    let _assignments: Record<string, API.RoleAndPermissionInfo> = {};

    assignments.forEach(assignment => {
      if (assignment.state !== API.IndexedAssignmentState.TO_DELETE) {
        _assignments[assignment.organizationalUnit.id] = {
          roleId: assignment.role?.id!,
          permissions: _.uniq(assignment.permissions),
        };
      }
    });

    const updatedScope: API.Scope = {
      notOrgUnitScopedPermissions: [],
      nonInheritedRolesOnOrgUnits: _assignments,
      inheritedRolesOnOrgUnits: {},
    };

    if (!isValidIndexedAssignment(assignments)) {
      showWarningWorkerEditAdd(modal, WorkerEditAddWarning.MissingWorkerAssignment);
      return;
    }

    const workerInput: API.WorkerCreateInput = {
      email: email,
      firstName: emptyString,
      familyName: emptyString,
      name: whiteSpaceWorkerName,
      contracts: [],
      tagIds: [],
      scope: JSON.stringify(updatedScope),
    };

    const createdWorker = await API.createFactoryBusinessObject(API.DataType.WORKER, workerInput);
    if (API.isFailure(createdWorker)) {
      showErrorWarning(createdWorker);
      return;
    } else setCreatedWorker(createdWorker.worker);

    const invite = await inviteWorker(
      createdWorker.worker,
      modal,
      API.UserInvitationOperations.GIVE_ACCESS,
      props.handleModalClose,
    );
    if (!isMounted.current) return;
    if (API.isFailure(invite)) setInviteUserRetryCount(1);
    else setLoading(false);
  }

  const handleInputs = (e: InputEvent) => {
    const { value }: Input = e.target;
    setEmail(value);

    if (Pattern.emailPattern.test(value)) {
      const existingWorker = workers.filter(workerData => {
        return workerData.worker.email === value;
      });
      if (existingWorker.length) {
        setExistingWorkerData(existingWorker[0]);
      } else {
        if (existingWorker) setExistingWorkerData(undefined);
        if (!validEmail) setValidEmail(true);
      }
    } else if (validEmail) setValidEmail(false);
  };

  return (
    <InviteNonReferencedWorkerPageComponent
      validEmail={validEmail}
      infoValidity={allowProceeding}
      worker={existingWorkerData}
      searchedValue={props.searchedValue}
      organizationalUnitRoles={assignments}
      handleModalClose={props.handleModalClose}
      handleSubmit={handleSubmit}
      handleInputs={handleInputs}
      setOrganizationalUnitRoles={setAssignments}
    />
  );
};
