import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import * as API from 'shared/backend-data';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import { InputList } from 'shared/ui-component/Input/InputList';
import { t } from 'shared/localisation/i18n';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import moment from 'moment';
import styles from './styles';

interface Props {
  workerSkillId: string;
  proofBundle?: API.NoMetadata<API.ProofBundle>;

  onSelectProof: (proof: Tag) => void;
}

export const ProofHistoryPicker: React.FC<Props> = props => {
  const { workerSkillId, proofBundle, onSelectProof } = props;

  const isMounted = useIsMounted();

  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
  const [proofBundleOption, setProofBundleOption] = useState<Tag>();
  const [proofBundleOptions, setProofBundleOptions] = useState<Tag[]>([]);

  useEffect(() => {
    if (!workerSkillId) return;

    getProofBundles();
  }, [workerSkillId, proofBundle]);

  function sortProofBundles(proofBundles: Tag[]): Tag[] {
    return proofBundles.sort((a, b) => b.value.startingDate.localeCompare(a.value.startingDate));
  }

  function handleSelectProof(inputId: string, proof: Tag[]) {
    if (!proof[0]) return;

    setProofBundleOption(proof[0]);
    onSelectProof(proof[0]);
  }

  async function getProofBundles() {
    let proofBundles: API.ProofBundle[] = [];

    const result = await API.getProofBundles(workerSkillId);
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      if (API.isFailureType(result, 'ProofBundleSomeObjectNotFound')) {
        proofBundles = result.data.sucesses.map(f => {
          return {
            ...f.proofBundle,
            updatedAt: f.updatedAt,
            updatedBy: f.updatedBy,
          };
        });
        logger.warn("Some proofBundles don't exist, please update workerskill ! " + result.message);
      } else {
        return;
      }
    } else {
      proofBundles = result;
    }

    if (proofBundles.length) {
      const sortedProofs: Tag[] = _.orderBy(
        proofBundles.map(_proofBundle => {
          return {
            key: _proofBundle.id,
            label:
              t('glossary:proof') +
              ' ' +
              (_proofBundle.review.state === API.ReviewState.REJECTED ||
              _proofBundle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
                ? t('alex:workerSkillReviewModal.disproved', undefined, false)
                : _proofBundle.review.state === API.ReviewState.VALIDATED
                ? t('alex:workerSkillReviewModal.validated', undefined, false)
                : t('alex:workerSkillReviewModal.toBeValidated', undefined, false)) + 
              (_proofBundle.review.state === API.ReviewState.TO_REVIEW
                ? ''
                : t(
                    'alex:workerSkillReviewModal.proofsObtained',
                    {
                      date: _proofBundle.review.date
                        ? moment(_proofBundle.review.date).format('L')
                        : t('alex:workerSkillReviewModal.dateNotPrecised', undefined, false),
                    },
                    false,
                  )),
            value: _proofBundle,
          };
        }),
        item => item.value.review.date,
        ['desc'],
      );

      setProofBundleOptions([...sortedProofs]);

      if (proofBundle) {
        setProofBundleOption(
          sortedProofs.find(eachProofBundle => eachProofBundle.key === proofBundle?.id),
        );
      }
    }
  }

  if (proofBundleOptions.length === 1 || !proofBundleOptions.length) return null;

  return (
    <View>
      {showDropDownMenu && (
        <InputList
          placeholder={t('common:filters.filterData')}
          options={proofBundleOptions}
          containerStyle={styles.inputList}
          handleSelection={(inputId, option) => handleSelectProof(inputId, option)}
          openInputList={setShowDropDownMenu}
          singleSelection
          initialSelection={proofBundleOption && [proofBundleOption]}
        />
      )}
      <TouchableOpacity
        disabled={!proofBundleOptions.length}
        onPress={() => setShowDropDownMenu(!showDropDownMenu)}
        style={styles.datePicker}
      >
        <InputDateWeb
          hasDateIcon
          inputPlaceholder={t('alex:workerSkillReviewModal.history')}
          inputName={t('alex:workerSkillReviewModal.history')}
          onChange={() => null}
          value={
            proofBundleOption?.value.review.state === API.ReviewState.TO_REVIEW
              ? new Date(proofBundleOption?.value.startingDate)
              : proofBundleOption?.value.review.state === API.ReviewState.VALIDATED ||
                proofBundleOption?.value.review.state === API.ReviewState.REJECTED ||
                proofBundleOption?.value.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
              ? new Date(proofBundleOption?.value.review.date)
              : undefined
          }
          disableCalendar
        />
      </TouchableOpacity>
    </View>
  );
};
