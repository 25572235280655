import React, { useState } from 'react';
import { TouchableOpacity, Platform, StyleSheet } from 'react-native';
import checkedButton from 'shared/assets/svg/icon.checked.small.svg';
import { IconSVG } from 'shared/ui-component/Icon';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import { Colors, Spacings } from 'shared/styles';

const isPlatformWeb = Platform.OS === 'web';

export const Style = StyleSheet.create({
  submissionIconContainer: {
    borderWidth: 1,
    width: Spacings.Standard,
    height: Spacings.Standard,
    borderColor: Colors.Grey,
    borderRadius: Spacings.Standard,
    justifyContent: 'center',
  },
  submissionIconOnHoverContainer: {
    borderWidth: 0,
    width: Spacings.Standard,
    height: Spacings.Standard,
    backgroundColor: Colors.Yellow,
    borderColor: Colors.Grey,
    borderRadius: Spacings.Standard,
    justifyContent: 'center',
  },
});

export const HoverableSaveButton: React.FC<{ onPress: () => void }> = props => {
  const [onHover, setOnHover] = useState<boolean>(false);
  const ref = useCallOnHover<TouchableOpacity>(
    Colors.BlueRollover,
    e => setOnHover(true),
    e => setOnHover(false),
  );
  return (
    <TouchableOpacity
      ref={ref}
      onPress={() => {
        if (props.onPress) props.onPress();
      }}
      style={
        isPlatformWeb
          ? onHover
            ? Style.submissionIconOnHoverContainer
            : Style.submissionIconContainer
          : Style.submissionIconOnHoverContainer
      }
    >
      <IconSVG svgComponent={checkedButton} color={onHover ? Colors.White : Colors.Black} />
    </TouchableOpacity>
  );
};
