import React, { useState } from 'react';
import { View } from 'react-native';
import Style from './Styles';
import DatePicker from 'react-datepicker';
import { Mode } from 'shared/util-ts/DateUtils';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { InputDate, InputDateProps } from 'shared/ui-component/Input/InputDate/InputDate';

interface InputDatePropsWeb extends Omit<InputDateProps, 'showCalendar' | 'setShowCalendar'> {}


const timeIntervals = 10;

export const InputDateWeb: React.FC<InputDatePropsWeb> = props => {
  const { calendarContainerStyle, maxDate, minDate, inputName, onChange, value, mode } = props;
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const getPickerSettings = () => {
    switch (mode) {
      case Mode.Time:
        return {
          showTimeSelect: true,
          showTimeSelectOnly: true,
          timeIntervals,
        };
      case Mode.Date:
        return {
          showTimeInput: false,
        };
      case Mode.Default:
      default:
        return {
          showTimeInput: true,
        };
    }
  };

  const calendarRef = useDetectOutsideClick<View>(closeCalendar);

  return (
    <>
      <InputDate {...props} setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
      {showCalendar ? (
        <View style={[Style.calendarContainer, calendarContainerStyle]} ref={calendarRef}>
          <DatePicker
            showTimeInput={getPickerSettings().showTimeInput}
            showTimeSelect={getPickerSettings().showTimeSelect}
            showTimeSelectOnly={getPickerSettings().showTimeSelectOnly}
            timeIntervals={getPickerSettings().timeIntervals}
            selected={value ?? new Date()}
            onChange={(date: Date) => {
              onChange(inputName, date);
            }}
            inline
            onSelect={() => setShowCalendar(false)}
            showYearDropdown
            minDate={minDate}
            maxDate={maxDate}
          />
        </View>
      ) : null}
    </>
  );
};
