import React, { useState, useEffect } from 'react';
import { Text, View, TouchableOpacity, InteractionManager } from 'react-native';
import {
  ModalHeaderBoldTextStyle,
  ModalHeaderRegularTextStyle,
  ModalHeaderStyle,
} from 'shared/styles/ModalStyles';
import { IconSVG } from 'shared/ui-component/Icon';
import * as API from 'shared/backend-data';
import { t } from 'shared/localisation/i18n';
import { InputList } from 'shared/ui-component/Input/InputList';
import moment from 'moment';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import { Tag } from '../Input/InputList/InputTag';
import { Spacings } from '../../styles';
import { useIsMounted } from '../../hooks/IsMounted';

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

interface Props {
  historyProofBundles: Tag[];
  workerSkillValidity?: API.WorkerSkill | undefined;
  revokeProof?: boolean | undefined;
  renewProof?: boolean | undefined;
  submitProofAgain?: boolean | undefined;
  skill?: API.Skill | undefined;
  handleModalClose: () => void;
  onSelectProof: (proof: Tag) => void;
}

export const SkillModalHeader: React.FC<Props> = props => {
  const {
    historyProofBundles,
    workerSkillValidity,
    revokeProof,
    skill,
    renewProof,
    submitProofAgain,

    handleModalClose,
    onSelectProof,
  } = props;

  const isMounted = useIsMounted();

  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
  const [proofBundleOption, setProofBundleOption] = useState<Tag>();
  const [boldHeaderText, setBoldHeaderText] = useState<string>('');
  const [regularHeaderText, setRegularHeaderText] = useState<string>('');

  useEffect(() => {
    setBoldHeaderText(t('glossary:proof'));
    setRegularHeaderText(t('alex:worker.addSkillModal.title.0', undefined, false));
  }, []);

  useEffect(() => {
    if (revokeProof) {
      setBoldHeaderText(t('common:button.revoke'));
      setRegularHeaderText(t('alex:worker.addSkillModal.title.4', undefined, false));
    } else if (renewProof) {
      setBoldHeaderText(t('common:button.renew'));
      setRegularHeaderText(t('alex:worker.addSkillModal.title.3', undefined, false));
    } else if (submitProofAgain) {
      setBoldHeaderText(t('common:button.submit'));
      setRegularHeaderText(t('alex:worker.addSkillModal.title.5', undefined, false));
    }
  }, [revokeProof, renewProof, submitProofAgain]);

  useEffect(() => {
    if (workerSkillValidity) {
      if (workerSkillValidity.toReviewProofBundle) {
        setProofBundleOption(
          historyProofBundles.find(
            eachProofBundle => eachProofBundle.key === workerSkillValidity.toReviewProofBundle?.id,
          ),
        );
      } else if (workerSkillValidity.activeProofBundle) {
        setProofBundleOption(
          historyProofBundles.find(
            eachProofBundle => eachProofBundle.key === workerSkillValidity.activeProofBundle?.id,
          ),
        );
      }
    }
  }, [workerSkillValidity]);

  useEffect(() => {
    if (historyProofBundles.length === 1) {
      setProofBundleOption(historyProofBundles[0]);
    }
  }, [historyProofBundles]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      handleRegularHeaderText(proofBundleOption);
    });
  }, [proofBundleOption, skill]);

  async function handleRegularHeaderText(proofBundleOption: Tag<any> | undefined) {
    if (!proofBundleOption) return;

    if (
      proofBundleOption.value.review.state === API.ReviewState.REJECTED ||
      proofBundleOption.value.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
    ) {
      setRegularHeaderText(t('alex:workerSkillReviewModal.disproved', undefined, false));
    } else if (proofBundleOption.value.review.state === API.ReviewState.TO_REVIEW) {
      setRegularHeaderText(t('alex:workerSkillReviewModal.toBeValidated', undefined, false));
    } else if (proofBundleOption.value.review.state === API.ReviewState.VALIDATED && skill) {
      const { validityDuration, expiryNoticeDuration } =
        await API.getSkillValidityDurationAndExpiryNoticeDuration(skill);

      if (!validityDuration) {
        setRegularHeaderText(t('alex:workerSkillReviewModal.valid', undefined, false));
      } else {
        const workerSkillExpiryDate = moment(proofBundleOption.value.startingDate).add(
          validityDuration,
          'months',
        );
        const days = workerSkillExpiryDate.diff(moment.utc(), 'days');

        if (days <= 0) {
          setRegularHeaderText(t('alex:workerSkillReviewModal.expired', undefined, false));
        } else if (!expiryNoticeDuration || days > expiryNoticeDuration) {
          setRegularHeaderText(t('alex:workerSkillReviewModal.valid', undefined, false));
        } else {
          setRegularHeaderText(t('alex:workerSkillReviewModal.expireSoon', undefined, false));
        }
      }
    }
  }

  function handleSelectProof(inputId: string, proof: Tag[]) {
    if (!proof) return;
    setProofBundleOption(proof[0]);
    onSelectProof(proof[0]);
  }

  function sortProofBundles(proofBundles: Tag[]): Tag[] {
    return proofBundles.sort((a, b) => b.value.startingDate.localeCompare(a.value.startingDate));
  }

  return (
    <View style={[ModalHeaderStyle, { zIndex: 2 }]}>
      <TouchableOpacity
        onPress={handleModalClose}
        style={{ position: 'absolute', right: 12, top: 9 }}
      >
        <IconSVG svgComponent={closeIcon} />
      </TouchableOpacity>
      {historyProofBundles.length > 1 && !submitProofAgain && (
        <View style={{ position: 'absolute', right: 57, top: 8 }}>
          {showDropDownMenu && (
            <InputList
              placeholder={t('common:filters.filterData')}
              options={sortProofBundles(historyProofBundles)}
              containerStyle={{
                width: 250,
                alignSelf: 'flex-end',
                top: Spacings.Standard,
                right: 0,
              }}
              handleSelection={(inputId, option) => handleSelectProof(inputId, option)}
              openInputList={setShowDropDownMenu}
              singleSelection
              initialSelection={proofBundleOption && [proofBundleOption]}
            />
          )}
          <TouchableOpacity
            disabled={!historyProofBundles.length}
            onPress={() => setShowDropDownMenu(!showDropDownMenu)}
          >
            <InputDateWeb
              hasDateIcon
              inputPlaceholder={t('alex:workerSkillReviewModal.history')}
              inputName={t('alex:workerSkillReviewModal.history')}
              onChange={() => null}
              value={
                proofBundleOption?.value.review.state === API.ReviewState.TO_REVIEW
                  ? new Date(proofBundleOption?.value.startingDate)
                  : proofBundleOption?.value.review.state === API.ReviewState.VALIDATED ||
                    proofBundleOption?.value.review.state === API.ReviewState.REJECTED ||
                    proofBundleOption?.value.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
                  ? new Date(proofBundleOption?.value.review.date)
                  : undefined
              }
              disableCalendar
            />
          </TouchableOpacity>
        </View>
      )}
      <Text style={ModalHeaderRegularTextStyle}>
        <Text style={ModalHeaderBoldTextStyle}>{`${boldHeaderText} `}</Text>
        <Text> {`${regularHeaderText} `}</Text>
      </Text>
    </View>
  );
};
