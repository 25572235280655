import React, { useState, useEffect, useContext } from 'react';
import { View, Text, ScrollView, InteractionManager } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import * as SharedStyles from 'shared/styles';
import * as _ from 'lodash-es';
import logger from 'shared/util/Logger';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import Styles from './style';
import { t } from 'shared/localisation/i18n';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { OutlineButton } from 'shared/ui-component/Button';

interface SkillsWithNoTrainingVersionsListingProps {
  skillIds: string[];
  requirement?: API.Requirement;
  handleWorkstationTrainingModal: (
    isShow: boolean,
    editMode: boolean,
    trainingVersion: API.TrainingVersion | null | undefined,
    skillIds: string[],
  ) => void;
}

const skillIcon = require('shared/assets/svg/icon.skill.small.svg').default;

export const SkillsWithNoTrainingVersionsListing: React.FC<
  SkillsWithNoTrainingVersionsListingProps
> = props => {
  const { skillIds, handleWorkstationTrainingModal } = props;
  const isMounted = useIsMounted();
  const { isValidPermission } = useContext(PermissionManagementContext);
  const [skills, setSkills] = useState<API.Skill[]>([]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !skillIds.length) return;

      const tempSkills: API.Skill[] = [];
      await Promise.all(
        _.map(skillIds, async skillId => {
          const skill = await API.getSkill(skillId);
          if (!isMounted.current) return;
          if (API.isFailure(skill)) {
            logger.warn('Failed to fetch skill ', skill);
            return;
          }
          tempSkills.push(skill);
        }),
      );
      if (isMounted.current) setSkills(tempSkills);
    });
  }, [skillIds]);

  return (
    <View style={Styles.skillsWithNoTrainingsContainer}>
      <ScrollView>
        {_.map(skills, skill => {
          return (
            <View key={skill.id} style={Styles.possibleTrainingRowScrollContainer}>
              <IconSVG
                svgComponent={skillIcon}
                color={SharedStyles.Colors.White}
                size={{ width: 24, height: 24 }}
                containerStyle={{
                  width: 24,
                  height: 24,
                  backgroundColor: SharedStyles.Colors.Red,
                  borderRadius: 20,
                  marginRight: 8,
                }}
              />
              <Text style={Styles.skillNameRowText}>{skill.name}</Text>
            </View>
          );
        })}
      </ScrollView>
      <View style={Styles.skillsWithNoTrainingsInnerContainer}>
        <View
          style={{
            flexDirection: 'row',
            marginBottom: SharedStyles.Spacings.xMedium,
            marginTop: SharedStyles.Spacings.Unit,
          }}
        >
          <IconSVG
            svgComponent={skillIcon}
            color={SharedStyles.Colors.White}
            size={{ width: 0, height: 0 }}
            containerStyle={{
              width: 6,
              height: 6,
              backgroundColor: SharedStyles.Colors.Red,
              borderRadius: 20,
              marginRight: 8,
            }}
          />
          <Text style={Styles.noSkillTrainingText}>
            {t(
              'alex:workstations.workstationPanel.requirementTable.requirementTrainingColumn.skillWithNoTrainingListing',
            )}
          </Text>
        </View>
        {isValidPermission(API.Permission.trainings_edit) && (
          <OutlineButton
            text={t(
              'alex:workstations.workstationPanel.requirementTable.requirementTrainingColumn.createTraining',
            )}
            onPress={() => {
              handleWorkstationTrainingModal(true, false, null, skillIds);
            }}
            isSmallButton={true}
            style={{ marginHorizontal: 10 }}
          />
        )}
      </View>
    </View>
  );
};
