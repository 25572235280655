import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Text } from 'react-native-web';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import styles from './styles';
import { MyHub } from 'shared/util/MyHub';
import { useIsMounted } from 'shared/hooks/IsMounted';
import logger from 'shared/util/Logger';
import { DashboardFilterContext } from 'shared/context/dashboard-context/DashboardFilterContext';
import * as _ from 'lodash-es';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import { Colors } from 'shared/styles';

interface Props {
  worker: API.Worker;
}

export const WorkerVersatilityCount: React.FC<Props> = props => {
  const { worker } = props;

  const [versatilityOrMagicSquareCount, setVersatilityOrMagicSquareCount] = useState<number | '-'>(
    '-',
  );
  const [versatilityOrMagicSquareCountColor, setVersatilityOrMagicSquareCountColor] =
    useState<string>(Colors.Black);

  const {
    workstations: [workstations],
  } = useContext(DashboardFilterContext);
  const { workstationTargetsStartingAtLevel, levelIconType } = useContext(GlobalDataContext);

  const isMounted = useIsMounted();

  const fetchWorkerVersatilityDebounce = useCallback(
    _.debounce(fetchWorkerVersatility, API.PERF_workersWorkstationsUpdateDebounceInMs),
    [],
  );

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.WORKERWORKSTATION &&
        (data.tooManyMutations || worker.id === data.factory.workerWorkstation.workerId)
      ) {
        fetchWorkerVersatilityDebounce(worker, workstationTargetsStartingAtLevel);
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await fetchWorkerVersatility(worker, workstationTargetsStartingAtLevel);
    });
    return () => {
      removeListener();
    };
  }, [worker, workstationTargetsStartingAtLevel]);

  async function fetchWorkerVersatility(
    worker: API.Worker,
    workstationTargetsStartingAtLevel: API.WorkstationWorkerLevels,
  ) {
    if (!workstations) return;

    let filteredWorkstations: API.Workstation[];
    if (API.enableGlobalLevelComputation) {
      filteredWorkstations = [...workstations];
    } else {
      const workerOrgUnits = await API.getWorkerOrganizationalUnits(
        worker.id,
        [API.Permission.workerIsOperational],
        true,
      );
      if (!isMounted.current) return;
      if (API.isFailure(workerOrgUnits)) {
        logger.warn(workerOrgUnits);
        return;
      }

      filteredWorkstations = workstations.filter(workstation =>
        workerOrgUnits.some(orgUnit => orgUnit.id === workstation.parentId),
      );
    }

    const result = API.getWorkersVersatilityAndWorkstationsObjectiveActuals(
      [worker],
      filteredWorkstations,
      workstationTargetsStartingAtLevel,
    );

    if (result.workersVersatility[worker.id]) {
      setVersatilityOrMagicSquareCount(
        levelIconType === API.LevelIconType.SQUARE
          ? result.workersVersatility[worker.id].magicSquares
          : result.workersVersatility[worker.id].versatility,
      );
    }

    let color = Colors.Black;
    if (levelIconType === API.LevelIconType.SQUARE) {
      if (result.workersVersatility[worker.id].magicSquares > API.WorkstationWorkerLevels.LEVEL4) {
        color = Colors.Green;
      } else if (
        result.workersVersatility[worker.id].magicSquares < API.WorkstationWorkerLevels.LEVEL4 &&
        result.workersVersatility[worker.id].workstationsWithMagicSquares <= 1
      ) {
        color = Colors.Red;
      } else {
        color = Colors.Orange;
      }
    }
    setVersatilityOrMagicSquareCountColor(color);
  }

  return (
    <Text
      style={[styles.userPolyNo, { color: versatilityOrMagicSquareCountColor }]}
      letterSpacing={16}
    >
      {versatilityOrMagicSquareCount}
    </Text>
  );
};
