import * as API from 'shared/backend-data';
import * as APIf from 'shared/backend-data/FactoryApiWrapper';
import * as _ from 'lodash-es';
import { isFailure } from 'shared/backend-data';
import Aigle from 'aigle';
import { searchMatch } from '../util-ts/Functions';
import { MONTH_TO_MIN_MULTIPLIER } from 'shared/util/const';

export type TrainingVersionAndTraining = [API.TrainingVersion, API.Training];
export type TrainingVersionAndTrainingCreateInput = [
  Omit<API.TrainingVersionCreateInput, 'trainingId'>,
  API.TrainingCreateInput,
];

export const TRAINING_MAX_MONTHS = 12;
export const TRAINING_MAX_WEEKS = 7;
export const TRAINING_MAX_HOURS = 23;
export const TRAINING_MAX_HALVED_HOURS = 7;
export const TRAINING_MAX_DAYS = 13;
export const TRAINING_DURATION_MAX_HOURS = (TRAINING_MAX_MONTHS * MONTH_TO_MIN_MULTIPLIER) / 60;

export function isTraining(obj: { __typename: string }): obj is API.Training {
  return (obj as API.Training).__typename === 'Training';
}

export function isTrainingVersion(obj: { __typename: string }): obj is API.TrainingVersion {
  return (obj as API.TrainingVersion).__typename === 'TrainingVersion';
}

export function isSkillTrainingVersion(obj: {
  __typename: string;
}): obj is API.SkillTrainingVersion {
  return (obj as API.SkillTrainingVersion).__typename === 'SkillTrainingVersion';
}

/** Check if 2 SkillTrainingVersions are considered equals */
export function isSameSkillTrainingVersion(
  skillTrainingVersion1: API.SkillTrainingVersion,
  skillTrainingVersion2: API.SkillTrainingVersion,
): boolean {
  return (
    skillTrainingVersion1.skillId === skillTrainingVersion2.skillId &&
    skillTrainingVersion1.trainingVersionId === skillTrainingVersion2.trainingVersionId
  );
}





/**
 * Create a Training and a TrainingVersion
 * @param trainingInput
 * @param trainingVersionInput (optional) if not passed, a default TrainingVersion is created
 */
export async function createTraining(
  trainingInput: API.TrainingCreateInput,
  trainingVersionInput: Omit<API.TrainingVersionCreateInput, 'trainingId'>,
): Promise<API.Result<TrainingVersionAndTraining>> {
  const _isTrainingNameNotUnique = await isTrainingNameNotUnique(trainingInput);
  if (API.isFailure(_isTrainingNameNotUnique)) return _isTrainingNameNotUnique;

  if (_isTrainingNameNotUnique)
    return API.createFailure(
      'DuplicateVeto',
      'The training name is already assigned to another training ',
      { dependencyIds: [_isTrainingNameNotUnique.id] },
    );

  const factory = await API.createFactoryTrainingTrainingVersionRelation(trainingInput, {
    ...trainingVersionInput,
    trainingId: '',
  });
  if (API.isFailure(factory)) {
    return factory;
  }
  const training = {
    ...factory.training,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };

  const trainingVersion = {
    ...factory.trainingVersion,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
  return [trainingVersion, training];
}

export async function updateTraining(
  training: API.TrainingPartialUpdateInput,
): Promise<API.Result<API.Training>> {
  const factory = await APIf.updateFactoryBusinessObject(API.DataType.TRAINING, training);
  if (API.isFailure(factory)) return factory;

  return { ...factory.training, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function getTraining(trainingId: string): Promise<API.Result<API.Training>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.TRAINING, trainingId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.training, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

/**
 * Get all the Trainings
 * @param itemsLimit
 * @param nextToken
 */
export async function getTrainings(
  itemsLimit?: number,
  nextToken?: string,
): Promise<API.ResultWithNextToken<API.Training[]>> {
  const factories = await API.listFactoriesWithDataType(
    API.DataType.TRAINING,
    undefined,
    itemsLimit,
    nextToken,
  );
  if (API.isFailure(factories)) return factories;

  return {
    result: _.map(factories.result, factory => {
      return { ...factory.training!, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
    }),
    nextToken: factories.nextToken,
  };
}

async function isTrainingNameNotUnique(
  trainingInput: API.TrainingCreateInput,
): Promise<API.Result<API.Training | undefined>> {
  const trainings = await API.getTrainings();
  if (isFailure(trainings)) return trainings;

  let foundTrainingName;
  if (trainings.result.length) {
    foundTrainingName = _.find(trainings.result, training => training.name === trainingInput.name);
  }

  return foundTrainingName;
}





export async function createTrainingVersion(
  trainingVersionInput: API.BusinessObjectCreateInput<API.DataType.TRAININGVERSION>,
): Promise<API.Result<API.Factory<API.DataType.TRAININGVERSION>>> {
  return API.createFactoryBusinessObject(API.DataType.TRAININGVERSION, trainingVersionInput);
}

export async function updateTrainingVersioMaybeDelete(
  trainingVersionInput: API.TrainingVersionUpdateInput,
): Promise<API.Result<API.Factory<API.DataType.TRAININGVERSION>>> {
  return API.updateFactoryBusinessObject(API.DataType.TRAININGVERSION, trainingVersionInput);
}

export async function getTrainingVersion(
  trainingVersionId: string,
): Promise<API.Result<API.TrainingVersion>> {
  const factory = await API.getFactoryBusinessObject(
    API.DataType.TRAININGVERSION,
    trainingVersionId,
  );
  if (API.isFailure(factory)) return factory;

  return {
    ...factory.trainingVersion,
    updatedAt: factory.updatedAt,
    updatedBy: factory.updatedBy,
  };
}

export async function getTrainingVersions(
  itemsLimit?: number,
  nextToken?: string,
  onlyLatest?: boolean,
): Promise<API.ResultWithNextToken<API.TrainingVersion[]>> {
  const factories = await API.listFactoriesWithDataType(
    API.DataType.TRAININGVERSION,
    undefined,
    itemsLimit,
    nextToken,
  );
  if (API.isFailure(factories)) return factories;

  if (onlyLatest) {
    const latestTrainingVersionsMap: Map<
      string,
      API.Factory<API.DataType.TRAININGVERSION>
    > = new Map();
    _.forEach(factories.result, factory => {
      const _latestFactory = latestTrainingVersionsMap.get(factory.trainingVersion.trainingId);
      if (_latestFactory?.trainingVersion) {
        if (_latestFactory.trainingVersion.version <= factory.trainingVersion.version) {
          latestTrainingVersionsMap.set(factory.trainingVersion.trainingId, factory);
        }
      } else {
        latestTrainingVersionsMap.set(factory.trainingVersion.trainingId, factory);
      }
    });

    return {
      result: _.map(Array.from(latestTrainingVersionsMap.values()), factory => {
        return {
          ...factory.trainingVersion,
          updatedAt: factory.updatedAt,
          updatedBy: factory.updatedBy,
        };
      }),
      nextToken: factories.nextToken,
    };
  } else {
    return {
      result: _.map(factories.result, factory => {
        return {
          ...factory.trainingVersion,
          updatedAt: factory.updatedAt,
          updatedBy: factory.updatedBy,
        };
      }),
      nextToken: factories.nextToken,
    };
  }
}

/**
 * Get all the TrainingVersion And Trainings
 * @param itemsLimit
 * @param nextToken
 * @returns
 */
export async function getTrainingVersionAndTrainings(
  onlyLatestTrainingVersion = true,
): Promise<API.ResultWithNextToken<TrainingVersionAndTraining[]>> {
  
  const trainings = await API.getTrainings();
  if (API.isFailure(trainings)) return trainings;

  const trainingVersionTrainings: TrainingVersionAndTraining[] = [];
  const failures = await API.mapLimit(trainings.result, async _training => {
    const _trainingVersions = await API.getTrainingVersionsForTraining(
      _training.id,
      onlyLatestTrainingVersion,
    );
    if (API.isFailure(_trainingVersions)) return _trainingVersions;

    _trainingVersions.forEach(_trainingVersion =>
      trainingVersionTrainings.push([_trainingVersion, _training]),
    );
  });
  if (API.isFailure(failures)) return failures;

  return { result: trainingVersionTrainings, nextToken: trainings.nextToken };
}

/**
 * Get the list of TrainingVerionTrainings that grant the given Skill
 * @param skillId
 * @param onlyLatestTrainingVersions if true, when a Skill has several TrainingVersions of the same Training,
 * returns only the lastest (greatest version) one, so it will return one TrainingVersion per Training
 */
export async function getTrainingVersionAndTrainingsForSkill(
  skillId: string,
  onlyLatestTrainingVersions: boolean,
): Promise<API.Result<TrainingVersionAndTraining[]>> {
  
  const trainingVersionTrainings = await getTrainingVersionAndTrainings(onlyLatestTrainingVersions);
  if (API.isFailure(trainingVersionTrainings)) return trainingVersionTrainings;

  return _.filter(trainingVersionTrainings.result, trainingVersionTraining =>
    trainingVersionTraining[0].skillIds.includes(skillId),
  );
}

/**
 * Get the list of TrainingVerions that grant the given skill.
 * @param skillId
 * @param onlyLatestTrainingVersions if true, when a Skill has several TrainingVersions of the same Training, returns only the lastest (greatest version) one
 */
export async function getTrainingVersionsForSkill(
  skillId: string,
  onlyLatestTrainingVersion: boolean,
): Promise<API.Result<API.TrainingVersion[]>> {
  const trainingVersionAndTrainings = await getTrainingVersionAndTrainingsForSkill(
    skillId,
    onlyLatestTrainingVersion,
  );
  if (API.isFailure(trainingVersionAndTrainings)) return trainingVersionAndTrainings;

  return _.map(
    trainingVersionAndTrainings,
    trainingVersionAndTraining => trainingVersionAndTraining[0],
  );
}

/**
 * Get the latest (greatest version) TrainingVersion for a given Training. By design this cannot be null.
 * @param trainingId
 */
export async function getTrainingVersionLatestForTraining(
  trainingId: string,
): Promise<API.Result<API.TrainingVersion>> {
  const latestTrainingVersion = await _getTrainingVersionsForTraining(trainingId, true);
  if (API.isFailure(latestTrainingVersion)) return latestTrainingVersion;

  return latestTrainingVersion[0]; 
}
/**
 * Get the TrainingVersions for a given Training. By design this list will never be empty (length > 0)
 * @param trainingId
 * @param onlyLatestTrainingVersion (dafault: false) if true, returns only the lastest (greatest version) one, so length==1.
 */
export async function getTrainingVersionsForTraining(
  trainingId: string,
  onlyLatestTrainingVersion: boolean = false,
): Promise<API.Result<API.TrainingVersion[]>> {
  return _getTrainingVersionsForTraining(trainingId, onlyLatestTrainingVersion);
}

/**
 * Get the TrainingVersions for a given Training. By design this list will never be empty (length > 0)
 * @param trainingId
 * @param onlyLatestTrainingVersion if true, returns only the lastest (greatest version) one, so length==1.
 */
async function _getTrainingVersionsForTraining(
  trainingId: string,
  onlyLatestTrainingVersion: boolean,
): Promise<API.Result<API.TrainingVersion[]>> {
  const skString = API.getTrainingVersionSkString(trainingId);
  const factories = await API.listFactoriesWithSk(
    API.DataType.TRAININGVERSION,
    skString,
    API.KeyComparator.beginsWith,
  );
  if (API.isFailure(factories)) return factories;

  if (!factories.result.length) {
    return API.createFailure(
      'ObjectNotFound',
      'Training id=' +
        trainingId +
        ' shall be linked to at least one TrainingVersion. Check createTraining function/business rules.',
    );
  }

  if (onlyLatestTrainingVersion) {
    let latestTrainingVersion: API.TrainingVersion | undefined = undefined;
    _.forEach(factories.result, factory => {
      const trainingVersion = {
        ...factory.trainingVersion,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      };
      if (
        latestTrainingVersion === undefined ||
        latestTrainingVersion.version < trainingVersion.version
      ) {
        latestTrainingVersion = trainingVersion;
      }
    });

    
    if (!latestTrainingVersion)
      return API.createFailure_Unspecified('This shall never be triggered');
    return [latestTrainingVersion];
  } else {
    return _.map(factories.result, factory => {
      return {
        ...factory.trainingVersion,
        updatedAt: factory.updatedAt,
        updatedBy: factory.updatedBy,
      };
    });
  }
}

/**
 * Get the TrainingVersionIds required for a given Workstation/OrganizationalUnit without duplicates.
 * It returns only the levels that are registered in the database.
 * @param workstationOrOrganizationalUnitId
 * @param includeInherited (optional, default false) if true, returns also the TrainingVersionIds of the given
 *        Workstation/OrganizationalUnit's ancestors
 */
export async function getTrainingVersionIdsForWorkstationOrOrgUnit(
  workstationOrOrganizationalUnitId: string,
  includeInherited = false,
): Promise<API.Result<Map<API.WorkstationWorkerLevels, string[]>>> {
  return _getTrainingVersionIds(workstationOrOrganizationalUnitId, includeInherited);
}

/**
 * Get the TrainingVersionIds required for a given Workstation/OrganizationalUnit without duplicates.
 * It returns only the levels that are registered in the database.
 * @param workstationOrOrganizationalUnitId
 * @param includeInherited (optional, default false) if true, returns also the TrainingVersionIds of the given Workstation/OrganizationalUnit's ancestors
 */
async function _getTrainingVersionIds(
  workstationOrOrganizationalUnitId: string,
  includeInherited = false,
): Promise<API.Result<Map<API.WorkstationWorkerLevels, string[]>>> {
  let levelsRequirements: API.Result<
    Map<API.WorkstationWorkerLevels, API.Requirement[] | API.Requirement>
  >;
  if (includeInherited) {
    levelsRequirements = await API.getLevelsRequirementsWithInheritedAndOrDescendent(
      workstationOrOrganizationalUnitId,
      true,
      false,
    );
  } else {
    levelsRequirements = await API.getLevelsRequirement(workstationOrOrganizationalUnitId);
  }
  if (API.isFailure(levelsRequirements)) return levelsRequirements;

  const levelsTrainingVersionIds = new Map<API.WorkstationWorkerLevels, string[]>();
  for (const [level, requirements] of levelsRequirements.entries()) {
    let skillTrainingVersions: readonly API.SkillTrainingVersion[];
    if (_.isArray(requirements)) {
      skillTrainingVersions = _.union(
        ...requirements.map(requirement => requirement.skillTrainingVersions),
      );
    } else {
      skillTrainingVersions = requirements.skillTrainingVersions;
    }

    const trainingversionIds = new Set<string>();
    const errors: API.Failure[] = [];

    skillTrainingVersions.forEach(skillTrainingVersion => {
      
      if (skillTrainingVersion.trainingVersionId)
        trainingversionIds.add(skillTrainingVersion.trainingVersionId);
    });
    if (errors.length) return API.createFailure_Multiple(errors);

    levelsTrainingVersionIds.set(level, Array.from(trainingversionIds));
  }

  return levelsTrainingVersionIds;
}

/**
 * Compute the required TrainingVersionIds (and its soure Requirement) for a Worker to reach a Level on a Workstation.
 * It includes the inherited Workstation's Requirements and the Skills valid that are about to expire.
 * If training not set for a skill, throw 'TrainingVersionNotSet' Failure type
 * @param workerId
 * @param workstationId
 * @param targetLevel
 */



export async function getTrainingVersionIdsForWorkerToReachLevel(
  workerId: string,
  workstationId: string,
  targetLevel: API.WorkstationWorkerLevels,
  allowTrainingVersionNotSet?: boolean,
): Promise<API.Result<Map<string, API.Requirement>>> {
  
  const levelsRequirementsWithInherited =
    await API.getLevelsRequirementsWithInheritedAndOrDescendent(workstationId, true, false);
  if (API.isFailure(levelsRequirementsWithInherited)) return levelsRequirementsWithInherited;

  const skillIdsWithoutTrainingVersionSet: string[] = [];
  let requiredTrainingVersionIds: Map<string, { requirement: API.Requirement; skills: string[] }> =
    new Map();

  for (let level = API.WorkstationWorkerLevels.LEVEL1; level <= targetLevel; level++) {
    const levelRequirements = levelsRequirementsWithInherited.get(level);

    levelRequirements?.forEach(requirement => {
      const skillsAndTrainingVersions =
        API.extractTrainingVersionIdsAndSkillIdsWithoutTrainingSet(requirement);

      
      if (skillsAndTrainingVersions.skillIdsWithoutTrainingSet.length) {
        skillIdsWithoutTrainingVersionSet.push(
          ...skillsAndTrainingVersions.skillIdsWithoutTrainingSet,
        );
      }

      
      skillsAndTrainingVersions.validTrainingVersionsIds.forEach(skillTrainingVersion => {
        
        const _trainingVersionId = skillTrainingVersion.trainingVersionId;
        if (!!_trainingVersionId) {
          const _requirementAndSkills = requiredTrainingVersionIds.get(_trainingVersionId);
          if (!_requirementAndSkills) {
            requiredTrainingVersionIds.set(_trainingVersionId, {
              requirement: requirement,
              skills: [skillTrainingVersion.skillId],
            });
          } else {
            const _skills = [..._requirementAndSkills.skills, skillTrainingVersion.skillId];
            requiredTrainingVersionIds.set(_trainingVersionId, {
              ..._requirementAndSkills,
              skills: _skills,
            });
          }
        }
      });
    });
  }

  
  const neededTrainingVersionIds: Map<string, API.Requirement> = new Map();
  const errors: API.Failure[] = [];
  await Aigle.map(
    Array.from(requiredTrainingVersionIds),
    async ([trainingVersionId, requirementAndSkills]) => {
      const neededSkills = [];
      let i = 0;
      while (!neededSkills.length && i < requirementAndSkills.skills.length) {
        const skillId = requirementAndSkills.skills[i];

        i++;

        const workerSkill = await API.getWorkerSkill(workerId, skillId);
        if (API.isFailure(workerSkill)) {
          if (API.isFailureType(workerSkill, 'ObjectNotFound')) {
            neededSkills.push(skillId);
          } else {
            errors.push(workerSkill);
          }
        } else {
          if (workerSkill.validity !== API.Validity.OK) {
            neededSkills.push(skillId);
          }
        }
      }

      if (neededSkills.length > 0) {
        neededTrainingVersionIds.set(trainingVersionId, requirementAndSkills.requirement);
      }
    },
  );
  if (errors.length) return API.createFailure_Multiple(errors);

  if (skillIdsWithoutTrainingVersionSet.length && !allowTrainingVersionNotSet) {
    return API.createFailure('TrainingVersionNotSet', '', {
      skillIdsWithoutTrainingSet: skillIdsWithoutTrainingVersionSet,
      neededTrainingVersionIds,
    });
  }

  return neededTrainingVersionIds;
}

/**
 * Tells if this TrainingVersion contains at least one practical Skill
 * @param trainingVersionId
 */
export async function isPracticalTrainingVersion(
  trainingVersionId: string,
): Promise<API.Result<boolean>> {
  const trainingVersion = await API.getTrainingVersion(trainingVersionId);
  if (API.isFailure(trainingVersion)) return trainingVersion;

  const isPracticalSkill = await API.findPracticalSkill(trainingVersion.skillIds);
  if (API.isFailure(isPracticalSkill)) return isPracticalSkill;

  return isPracticalSkill !== undefined;
}

/**
 * Tells if this training latest trainingVersion contains at least one practical Skill
 * @param trainingId
 */
export async function isPracticalTraining(trainingId: string): Promise<API.Result<boolean>> {
  const latestTrainingVersion = await getTrainingVersionLatestForTraining(trainingId);
  if (API.isFailure(latestTrainingVersion)) return latestTrainingVersion;

  const isPracticalSkill = await API.findPracticalSkill(latestTrainingVersion.skillIds);
  if (API.isFailure(isPracticalSkill)) return isPracticalSkill;

  return isPracticalSkill !== undefined;
}

/**
 * Get workstations and organizationalUnits that require the latest trainingVersion of a given training
 * @param trainingId
 */
export async function getTrainingWorkstationOrOrgUnit(
  trainingId: string,
): Promise<API.Result<API.TreeObject[]>> {
  const latestTrainingVersion = await getTrainingVersionLatestForTraining(trainingId);
  if (API.isFailure(latestTrainingVersion)) return latestTrainingVersion;

  const errors: API.Failure[] = [];
  const workstationsOrOrgUnits: API.TreeObject[] = [];
  await Promise.all(
    _.map(API.Tree.getTreeObjects(), async treeObject => {
      const trainingVersionIds = await getTrainingVersionIdsForWorkstationOrOrgUnit(treeObject.id);
      if (API.isFailure(trainingVersionIds)) {
        errors.push(trainingVersionIds);
        return;
      }
      for (
        let level = API.WorkstationWorkerLevels.LEVEL1;
        level <= API.WorkstationWorkerLevels.LEVEL4;
        level++
      ) {
        const levelTrainingVersionIds = trainingVersionIds.get(level);
        if (levelTrainingVersionIds && levelTrainingVersionIds.includes(latestTrainingVersion.id)) {
          workstationsOrOrgUnits.push(treeObject);
          break;
        }
      }
    }),
  );
  if (errors.length) return API.createFailure_Multiple(errors);

  return workstationsOrOrgUnits;
}

const notFoundTrainingName = '';
export async function getTrainingNameForATrainingVersion(
  trainingVersionId: string,
): Promise<string> {
  const trainingVersion = await API.getTrainingVersion(trainingVersionId);
  if (API.isFailure(trainingVersion)) {
    return notFoundTrainingName;
  }
  if (!trainingVersion) return notFoundTrainingName;

  const training = await API.getTraining(trainingVersion.trainingId);
  if (API.isFailure(training)) {
    return notFoundTrainingName;
  }
  return training.name;
}

export async function getTrainingForATrainingVersion(
  trainingVersionId: string,
): Promise<API.Result<API.Training>> {
  const trainingId = API.getTrainingIdFromTrainingVersionId(trainingVersionId);
  if (!trainingId)
    return API.createFailure(
      'ObjectNotFound',
      'getTrainingForATrainingVersion: trainingId not found in trainingVersionId',
    );

  return API.getTraining(trainingId);
}

export async function getTrainingTags(): Promise<API.Result<API.TrainingTag[]>> {
  const factories = await API.listFactoriesWithDataType(API.DataType.TRAININGTAG);
  if (API.isFailure(factories)) return factories;

  return _.map(factories.result, factory => {
    return { ...factory.trainingTag!, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
  });
}

export async function getTrainingTag(trainingTagId: string): Promise<API.Result<API.TrainingTag>> {
  const factory = await API.getFactoryBusinessObject(API.DataType.TRAININGTAG, trainingTagId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.trainingTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function createTrainingTag(trainingTagInput: API.TrainingTagCreateInput) {
  const factory = await API.createFactoryBusinessObject(API.DataType.TRAININGTAG, trainingTagInput);
  if (API.isFailure(factory)) return factory;

  return { ...factory.trainingTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function updateTrainingTag(trainingTagInput: API.TrainingTagPartialUpdateInput) {
  const factory = await API.updateFactoryBusinessObject(API.DataType.TRAININGTAG, trainingTagInput);
  if (API.isFailure(factory)) return factory;

  return { ...factory.trainingTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function getWorkersWhoCompletedTheTraining(
  trainingId: string,
): Promise<API.Result<string[]>> {
  const latestTrainingVersion = await getTrainingVersionLatestForTraining(trainingId);
  if (API.isFailure(latestTrainingVersion)) return latestTrainingVersion;
  const skillIds = latestTrainingVersion.skillIds;
  if (!skillIds.length) return [];

  
  const workerSkillCount = new Map<string, number>();
  const failures = await API.mapSeries(skillIds, async skillId => {
    const skillWorkerSkills = await API.getWorkerSkills(undefined, skillId);
    if (API.isFailure(skillWorkerSkills)) return skillWorkerSkills;

    skillWorkerSkills.result.forEach(skillWorkerSkill => {
      if (!API.isWorkerSkillValid(skillWorkerSkill.validity)) return;

      const skillsCount = workerSkillCount.get(skillWorkerSkill.workerId) || 0;
      workerSkillCount.set(skillWorkerSkill.workerId, skillsCount + 1);
    });
  });
  if (API.isFailure(failures)) return failures;

  
  const workersWhoCompletedTheTraining: string[] = [];
  Array.from(workerSkillCount.entries()).forEach(([workerId, count]) => {
    if (count === skillIds.length) workersWhoCompletedTheTraining.push(workerId);
  });

  return workersWhoCompletedTheTraining;
}

/**
 * Return the Training for the given name.
 * Returns a Failure if there are several Training with the same name.
 * N.B. it shall not be used to test the existence of a Training as the result depends on the User scope (what data user can see).
 * @param trainingName
 */
export async function getTrainingByName(
  trainingName: string,
): Promise<API.Result<API.Training | undefined>> {
  const trainings = await API.getTrainings();
  if (API.isFailure(trainings)) return trainings;

  let result: API.Training | undefined = undefined;
  for (const training of trainings.result) {
    if (searchMatch(training.name, trainingName, true)) {
      if (result)
        return API.createFailure_Unspecified(
          'Several Trainings have the name (' +
            trainingName +
            '). Consider passing the id instead.',
        );
      result = training;
    }
  }
  return result;
}
