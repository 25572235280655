import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { t } from 'shared/localisation/i18n';
import { WorkstationWorkerLevels } from 'shared/util/WorkerLevel';
import { DropDownWithWorkerLevelOption } from 'shared/ui-component/DropDown/DropDownWithWorkerLevel';
import {
  TRAINING_MAX_MONTHS,
  TRAINING_MAX_WEEKS,
  TRAINING_MAX_HOURS,
  TRAINING_MAX_HALVED_HOURS,
  TRAINING_MAX_DAYS,
} from 'shared/util/Training';
import {
  SKILL_MAX_MONTHS,
  SKILL_MAX_YEARS,
  SKILL_EXPIRY_NOTICE_MAX_DAYS,
} from 'shared/util/skillUi';
import {
  DAY_TO_MIN_MULTIPLIER,
  HALF_AN_HOUR_TO_MIN_MULTIPLIER,
  HOUR_TO_MIN_MULTIPLIER,
  MONTH_TO_MIN_MULTIPLIER,
  WEEK_TO_MIN_MULTIPLIER,
  YEAR_TO_MONTH_MULTIPLIER,
} from 'shared/util/const';

export function getTrainingDurationOptions(): DropDownOption[] {
  const durationOptions: DropDownOption[] = [];

  durationOptions.push({
    key: (HALF_AN_HOUR_TO_MIN_MULTIPLIER * HOUR_TO_MIN_MULTIPLIER).toString(),
    label: getTrainingDurationLabel(HALF_AN_HOUR_TO_MIN_MULTIPLIER),
    value: HALF_AN_HOUR_TO_MIN_MULTIPLIER,
  });

  for (let i = 1; i <= TRAINING_MAX_HOURS; i++) {
    durationOptions.push({
      key: (i * HOUR_TO_MIN_MULTIPLIER).toString(),
      label: getTrainingDurationLabel(i * HOUR_TO_MIN_MULTIPLIER),
      value: i * HOUR_TO_MIN_MULTIPLIER,
    });

    if (i <= TRAINING_MAX_HALVED_HOURS) {
      durationOptions.push({
        key: (i * HOUR_TO_MIN_MULTIPLIER + HALF_AN_HOUR_TO_MIN_MULTIPLIER).toString(),
        label: getTrainingDurationLabel(
          i * HOUR_TO_MIN_MULTIPLIER + HALF_AN_HOUR_TO_MIN_MULTIPLIER,
        ),
        value: i * HOUR_TO_MIN_MULTIPLIER + HALF_AN_HOUR_TO_MIN_MULTIPLIER,
      });
    }
  }

  for (let i = 1; i <= TRAINING_MAX_DAYS; i++) {
    durationOptions.push({
      key: (i * DAY_TO_MIN_MULTIPLIER).toString(),
      label: getTrainingDurationLabel(i * DAY_TO_MIN_MULTIPLIER),
      value: i * DAY_TO_MIN_MULTIPLIER,
    });
  }

  for (let i = 2; i <= TRAINING_MAX_WEEKS; i++) {
    durationOptions.push({
      key: (i * WEEK_TO_MIN_MULTIPLIER).toString(),
      label: getTrainingDurationLabel(i * WEEK_TO_MIN_MULTIPLIER),
      value: i * WEEK_TO_MIN_MULTIPLIER,
    });
  }

  for (let i = 2; i <= TRAINING_MAX_MONTHS; i++) {
    durationOptions.push({
      key: (i * MONTH_TO_MIN_MULTIPLIER).toString(),
      label: getTrainingDurationLabel(i * MONTH_TO_MIN_MULTIPLIER),
      value: i * MONTH_TO_MIN_MULTIPLIER,
    });
  }

  return durationOptions;
}

export function getTrainingDurationLabel(durationInMin: number): string {
  if (durationInMin === HALF_AN_HOUR_TO_MIN_MULTIPLIER)
    return `${HALF_AN_HOUR_TO_MIN_MULTIPLIER.toString()} ${t(
      'common:time.minute',
      { count: HALF_AN_HOUR_TO_MIN_MULTIPLIER },
      false,
    )}`;

  if (
    durationInMin < (TRAINING_MAX_HALVED_HOURS + 1) * HOUR_TO_MIN_MULTIPLIER &&
    durationInMin % 30 === 0 &&
    durationInMin % 60 !== 0
  )
    return `${Math.floor(
      durationInMin / HOUR_TO_MIN_MULTIPLIER,
    ).toString()}h${HALF_AN_HOUR_TO_MIN_MULTIPLIER}m`;

  if (durationInMin <= TRAINING_MAX_HOURS * HOUR_TO_MIN_MULTIPLIER) {
    return (
      Math.floor(durationInMin / HOUR_TO_MIN_MULTIPLIER) +
      ' ' +
      t('common:time.hour', { count: Math.floor(durationInMin / HOUR_TO_MIN_MULTIPLIER) }, false)
    );
  }

  if (durationInMin <= TRAINING_MAX_DAYS * DAY_TO_MIN_MULTIPLIER) {
    return (
      Math.floor(durationInMin / DAY_TO_MIN_MULTIPLIER) +
      ' ' +
      t('common:time.day', { count: Math.floor(durationInMin / DAY_TO_MIN_MULTIPLIER) }, false)
    );
  }

  if (durationInMin <= TRAINING_MAX_WEEKS * WEEK_TO_MIN_MULTIPLIER) {
    return (
      Math.floor(durationInMin / WEEK_TO_MIN_MULTIPLIER) +
      ' ' +
      t('common:time.week', { count: Math.floor(durationInMin / WEEK_TO_MIN_MULTIPLIER) }, false)
    );
  }

  return (
    Math.floor(durationInMin / MONTH_TO_MIN_MULTIPLIER) +
    ' ' +
    t('common:time.month', { count: Math.floor(durationInMin / MONTH_TO_MIN_MULTIPLIER) }, false)
  );
}

export function getSkillValidityDurationOptions(): DropDownOption[] {
  const durationInMonthsOptions: DropDownOption[] = [];

  for (let i = 1; i <= SKILL_MAX_MONTHS; i++) {
    durationInMonthsOptions.push({
      key: i.toString(),
      label: getSkillValidityDurationLabel(i),
      value: i,
    });
  }

  for (let i = SKILL_MAX_MONTHS * YEAR_TO_MONTH_MULTIPLIER + 1; i <= SKILL_MAX_YEARS; i++) {
    durationInMonthsOptions.push({
      key: (i * YEAR_TO_MONTH_MULTIPLIER).toString(),
      label: getSkillValidityDurationLabel(i * YEAR_TO_MONTH_MULTIPLIER),
      value: i * YEAR_TO_MONTH_MULTIPLIER,
    });
  }

  return durationInMonthsOptions;
}

export function getSkillValidityDurationLabel(durationInMonths: number): string {
  if (durationInMonths <= SKILL_MAX_MONTHS) {
    return durationInMonths + ' ' + t('common:time.month', { count: durationInMonths }, false);
  } else {
    return (
      Math.floor(durationInMonths / YEAR_TO_MONTH_MULTIPLIER) +
      ' ' +
      t(
        'common:time.year',
        { count: Math.floor(durationInMonths / YEAR_TO_MONTH_MULTIPLIER) },
        false,
      )
    );
  }
}

export function getSkillExpiryNoticeDurationOptions() {
  const durationOptions: DropDownOption[] = [];
  for (let i = 1; i <= SKILL_EXPIRY_NOTICE_MAX_DAYS; i++) {
    durationOptions.push({
      key: i.toString(),
      label: getSkillExpiryNoticeDurationLabel(i),
      value: i,
    });
  }

  return durationOptions;
}

export function getSkillExpiryNoticeDurationLabel(durationInDays: number) {
  return durationInDays + ' ' + t('common:time.day', { count: durationInDays }, false);
}

export const levels: DropDownWithWorkerLevelOption[] = [
  { key: '2', label: '' + WorkstationWorkerLevels.LEVEL2, level: WorkstationWorkerLevels.LEVEL2 },
  { key: '3', label: '' + WorkstationWorkerLevels.LEVEL3, level: WorkstationWorkerLevels.LEVEL3 },
  { key: '4', label: '' + WorkstationWorkerLevels.LEVEL4, level: WorkstationWorkerLevels.LEVEL4 },
];
