import { StyleSheet } from 'react-native-web';
import { TextFontStyles } from 'shared/styles/TextStyles';
import { Colors } from 'shared/styles/Colors';
import * as SharedStyles from 'shared/styles';
import { Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.LightBlue,
    height: '100%',
    width: '100%',
  },
  innerContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.LightBlue,
    flexDirection: 'row',
  },
  leftContainer: {
    width: 224,
    height: '100%',
    backgroundColor: Colors.White,
    borderRadius: 8,
    shadowColor: '#00000029',
    shadowOpacity: 1,
    shadowRadius: 2,
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 28,
    paddingTop: 20,
    marginRight: 16,
    justifyContent: 'space-between',
  },
  leftContainerEdit: { position: 'absolute', right: -36, top: -14 },
  infoContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: -1,
  },
  nameTextStyle: {
    color: Colors.Black,
    fontSize: 18,
    fontFamily: 'Lato, Regular',
    fontWeight: 'normal',
    textAlign: 'center',
    letterSpacing: 0.36,
    margin: 2,
    maxWidth: 144,
  },
  normalText: {
    letterSpacing: 0.28,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    margin: 2,
    color: Colors.Grey,
    textAlign: 'center',
    maxWidth: 144,
  },
  phoneTextStyle: {
    letterSpacing: 0.28,
    fontWeight: 'normal',
    fontFamily: 'Lato, Regular',
    fontSize: fontSize.Regular,
    margin: 2,
    color: Colors.Black,
  },
  leftPanelMidContainer: {
    width: '100%',
    height: '17%',
    paddingTop: 28,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'red',
  },
  polyvalenceContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  polyvalenceTextStyle: {
    color: Colors.Black,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    letterSpacing: 0.28,
    maxWidth: '80%',
  },
  polyvalenceTotalTextStyle: {
    color: Colors.Black,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
    letterSpacing: 0.28,
  },
  trainingContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  trainingTextStyle: {
    color: Colors.Black,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    letterSpacing: 0.28,
    maxWidth: '80%',
  },
  trainingTotalTextStyle: {
    color: Colors.Black,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
    letterSpacing: 0.28,
  },
  skillContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  skillTextStyle: {
    color: Colors.Black,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    letterSpacing: 0.28,
    maxWidth: '80%',
  },
  skillTotalTextStyle: {
    color: Colors.Black,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
    letterSpacing: 0.28,
  },
  workerTagsContainer: {
    height: '48%',
    width: '100%',
    paddingTop: 39,
    overflow: 'hidden',
  },
  workerTagsInnerContainer: { width: '100%', paddingTop: 32, backgroundColor: 'green' },
  workerTagsTextStyle: {
    color: Colors.Black,
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
  },
  nullWorkerTagsTextStyle: { fontWeight: 'normal', fontStyle: 'italic', color: Colors.LightGrey },
  orgUnitRolesContainer: { width: '100%', paddingTop: 32 },
  orgUnitRolesRow: {
    ...TextFontStyles.LatoRegular,
    fontSize: 16,
    paddingBottom: SharedStyles.Spacings.Unit,
    color: Colors.Black,
  },
  orgUnitRolesTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingBottom: 2,
    color: Colors.Black,
  },
  managerText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
    marginBottom: SharedStyles.Spacings.Unit,
  },
  subtitle: {
    ...TextFontStyles.LatoBlack,
    fontSize: fontSize.Regular,
    paddingBottom: 2,
  },
  managersTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingBottom: 2,
  },
  rightPanel: {
    flex: 1,
    backgroundColor: Colors.White,
    borderRadius: 8,
    shadowColor: '#00000029',
    shadowOpacity: 1,
    shadowRadius: 2,
    marginLeft: Spacings.Medium,
  },
  tabContainer: {
    width: '100%',
    flexWrap: 'wrap',
  },
  animatedTabContainer: {
    height: 60,
    backgroundColor: Colors.White,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  animatedTouchableContainer: { justifyContent: 'center', alignItems: 'center' },
  tabsContainer: { height: 60 },
  horizontalScrollView: {
    height: '100%',
    width: '100%',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  horizontalInnerScrollView: {
    width: '100%',
    height: '100%',
  },
  verticalScrollView: { width: '100%' },
  workstationPlusIconSize: { width: 24, height: 24 },
  columnBar: {
    width: '100%',
    height: 68,
    paddingLeft: 34,
    paddingTop: 24,
    paddingRight: 20,
    paddingBottom: 15,
    flexDirection: 'row',
    borderTopColor: '#F5F5F5',
    borderBottomColor: '#F5F5F5',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    backgroundColor: Colors.White,
  },
  filterIconContainer: {
    marginRight: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  innerColumnBar: { flex: 1, flexDirection: 'row', paddingTop: 2 },
  nameColumnTextStyle: {
    width: '15.5%',
    color: '#606060',
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
  },
  orgUnitColumnTextStyle: {
    width: '16.5%',
    color: '#606060',
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
  },
  tagsColumnTextStyle: {
    color: '#606060',
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
    width: '11%',
  },
  levelTextStyle: {
    color: '#606060',
    fontSize: fontSize.Regular,
    fontWeight: '600',
    fontFamily: 'Lato, Black',
    width: '24.5%',
    paddingLeft: 12,
  },
  trainingColumnTextStyle: {
    color: '#606060',
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoBlack,
    width: '32.5%',
  },
  workstationFlatListContainer: {
    flex: 1,
    height: 200,
  },
  workstationRowContainer: {
    paddingLeft: 34,
    paddingRight: 20,
    width: '100%',
    minHeight: 68,
    borderBottomColor: '#F5F5F5',
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  workstationTextStyle: {
    width: '18.6%',
    paddingRight: 5,
    paddingLeft: 5,
    color: '#606060',
    fontFamily: 'Lato, Regular',
    fontWeight: '600',
    fontSize: fontSize.Regular,
  },
  orgUnitContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tagsTextStyle: { width: '10.7%' },
  workerWorkstationLevelContainer: {
    justifyContent: 'center',
  },
  trainingsTextStyle: { width: '31.3%' },
  loadingWorkstationsContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 200,
    paddingTop: 20,
  },
  loadingWorkstationsTextStyle: {
    fontFamily: 'Lato, Regular',
    fontSize: fontSize.Regular,
    color: Colors.GreyLight,
    fontWeight: '600',
    fontStyle: 'italic',
  },
  loadingSkillsTextStyle: {
    fontFamily: 'Lato, Regular',
    fontSize: fontSize.Regular,
    color: Colors.GreyLight,
    fontWeight: '600',
    fontStyle: 'italic',
  },
  skillInfoOuterContainer: {
    width: 24,
    height: 24,
    paddingRight: 62,
  },
  skillNameTextStyle: {
    width: '100%',
    paddingRight: 5,
    color: '#606060',
    fontFamily: 'Lato, Regular',
    fontWeight: '600',
    fontSize: fontSize.Regular,
  },
  skillNameContainer: {
    zIndex: 0,
    width: '100%',
  },
  skillOuterContainer: {
    paddingLeft: 20,
    paddingRight: 16,
    width: '100%',
    height: 68,
    borderBottomColor: '#F5F5F5',
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  skillFlatlistContainer: {
    flex: 1,
    height: '100%',
    zIndex: 1,
  },
  skillFlatListFixView: {
    height: 68,
    width: '100%',
  },
  skillStatusColumnTextStyle: {
    width: '50%',
    color: '#606060',
    fontSize: fontSize.Regular,
    fontWeight: '600',
    fontFamily: 'Lato, Black',
  },
  skillNameColumnTextStyle: {
    width: '50%',
    color: '#606060',
    fontSize: fontSize.Regular,
    fontWeight: '600',
    fontFamily: 'Lato, Black',
  },
  skillColumnBar: { flex: 1, flexDirection: 'row', paddingTop: 2 },
  skillFilterIcon: {
    marginRight: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  skillColumnBarContainer: {
    width: '100%',
    height: 68,
    paddingLeft: 34,
    paddingTop: 24,
    paddingRight: 20,
    paddingBottom: 15,
    flexDirection: 'row',
    borderTopColor: '#F5F5F5',
    borderBottomColor: '#F5F5F5',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    backgroundColor: Colors.White,
  },
  addSkillTextStyle: {
    fontSize: fontSize.Regular,
    fontWeight: '600',
    fontFamily: 'Lato, Black',
    color: '#2E2E2E',
    maxWidth: '50%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: 4,
  },
  addSkillPlusIcon: { marginRight: 10 },
  addSkillTouchableContainer: {
    backgroundColor: '#EFF1F233',
    width: '100%',
    height: 68,
    paddingLeft: 34,
    paddingTop: 24,
    paddingBottom: 20,
    opacity: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  verticalSkillScrollView: { width: '100%' },
  skillsContainer: { height: '100%' },
  profilePanel: {
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    flexDirection: 'row',
    paddingTop: '1.75vh',
    paddingBottom: '2.75vh',
  },
  profileDetailsContainer: {
    width: '40%',
    paddingLeft: '1.7vw',
    flexDirection: 'row',
  },
  profilePictureContainer: {
    width: '4.60%',
  },
  profileMetaDataContainer: {
    width: '95.40%',
    paddingLeft: '5.3vw',
    paddingTop: '1vh',
  },
  absentLabel: {
    ...TextFontStyles.LatoRegular,
    color: '#FF302B',
    textAlign: 'left',
    marginTop: '4.5vh',
    marginLeft: '0.6vw',
  },
  otherDetailsContainer: {
    paddingTop: '1vh',
    paddingRight: '1.6vw',
    width: '13.23%',
  },
  scopeDetailsContainer: {
    width: '46.87%',
    height: '100%',
    paddingTop: '1.75vh',
    paddingBottom: '2.75vh',
  },
  scopeLabel: {
    ...TextFontStyles.LatoRegular,
    color: '#666666',
    textAlign: 'left',
  },
  scopeUnitRoleText: {
    ...TextFontStyles.LatoRegular,
    color: '#666666',
    textAlign: 'left',
    marginTop: '0.5vh',
  },
  profileName: {
    ...TextFontStyles.LatoBlack,
    fontWeight: 'bold',
    color: '#666666',
    textAlign: 'left',
  },
  contactDetailsContainer: {
    flexDirection: 'row',
    marginTop: '1vh',
  },
  contactInfoLabel: {
    ...TextFontStyles.LatoRegular,
    color: '#666666',
    textAlign: 'left',
  },
  setMarginForContactLabel: {
    marginLeft: '0.8vw',
  },
  matriculeLabel: {
    ...TextFontStyles.LatoRegular,
    color: '#666666',
    textAlign: 'left',
  },
  setMarginForMatriculeLabel: {
    marginRight: '0.5vw',
  },
  mentorDetailsContainer: {
    flexDirection: 'row',
    marginTop: '2vh',
  },
  label: { ...TextFontStyles.LatoRegular, color: '#666666', textAlign: 'left' },
  setMarginForEquipeLabel: {
    marginLeft: '3.2vw',
  },
  value: {
    ...TextFontStyles.LatoBlack,
    fontWeight: 'bold',
    color: '#666666',
    textAlign: 'left',
    marginLeft: '0.3vw',
  },
  dateLabel: {
    ...TextFontStyles.LatoBlack,
    fontWeight: 'bold',
    color: '#666666',
    textAlign: 'right',
  },
  ansLabel: {
    ...TextFontStyles.LatoRegular,
    color: '#666666',
    textAlign: 'right',
    marginTop: '1.4vh',
  },
  ansValue: { ...TextFontStyles.LatoBlack, fontWeight: 'bold', color: '#666666' },
  excelIconContainer: {
    alignSelf: 'flex-end',
  },
  modifyProfileButtonView: {
    marginTop: '3.7vh',
    marginRight: '3.5vw',
    flexDirection: 'row',
  },
  exportView: {
    position: 'absolute',
    height: '4.5vh',
    width: '4.5vh',
    backgroundColor: '#30E8A4',
    marginLeft: '7.5vw',
    marginTop: '9.0vh',
  },
  workerSkillMenuContainer: {
    width: 180,
    height: 100,
    backgroundColor: Colors.White,
    position: 'absolute',
    shadowColor: Colors.GreyLight,
    shadowOpacity: 1,
    shadowRadius: 6,
    right: 36,
    borderRadius: 8,
    top: 24,
    paddingLeft: 20,
    justifyContent: 'space-around',
    zIndex: 999,
  },
  skillMenuRow: {
    height: '35%',
    justifyContent: 'center',
    backgroundColor: Colors.White,
    zIndex: 999,
  },
  skillMenuTextStyle: {
    fontFamily: 'Lato, Regular',
    fontWeight: 'normal',
    fontSize: fontSize.Regular,
    color: Colors.Black,
    zIndex: 999,
  },
  workstationNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableText: {
    textAlign: 'left',
    fontSize: fontSize.Regular,
    color: '#606060',
    ...TextFontStyles.LatoMedium,
    justifyContent: 'center',
  },
  tableTextCount: {
    width: '80%',
    textAlign: 'left',
    fontSize: 22,
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    justifyContent: 'center',
  },
  workstationIcon: {
    marginRight: 16,
  },
  tableNumber: {
    fontSize: 22,
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    paddingRight: SharedStyles.Spacings.Unit,
  },
  alignItems: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    paddingVertical: SharedStyles.Spacings.Small,
    paddingHorizontal: SharedStyles.Spacings.CardPadding,
    color: Colors.Black,
  },
  cardContainer: {
    marginLeft: 10,
    right: 0,
    top: SharedStyles.Spacings.Large,
    padding: 0,
    paddingVertical: SharedStyles.Spacings.Small,
    minWidth: '155px',
    position: 'absolute',
    zIndex: 1,
  },
  workstationIconContainer: {
    height: 28,
    width: 28,
    backgroundColor: Colors.Yellow,
    borderRadius: 14,
    marginBottom: SharedStyles.Spacings.Small,
  },
  permissionsContainer: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
    marginBottom: SharedStyles.Spacings.Unit,
  },
  profilePicContainer: {
    zIndex: -1,
    marginBottom: 16,
    alignItems: 'center',
  },
  disabled: {
    color: Colors.Grey,
  },
  disabledBackground: {
    backgroundColor: Colors.Background,
  },
  ellipsisText: {
    width: '80%',
  },
  skillEllipsisText: {
    width: '85%',
  },
  skillValidityText: {
    width: 284,
    paddingRight: Spacings.Small,
  },
});

export default styles;
