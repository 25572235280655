import { StyleSheet } from 'react-native';
import { Colors } from 'shared/styles';

export const sharedGlobalStyle = StyleSheet.create({
  label1: {
    fontFamily: 'Lato',
    fontSize: 18,
  },
  label2: {
    fontFamily: 'Lato',
    fontSize: 12,
  },
  label3: {
    fontFamily: 'Lato',
    fontSize: 36,
  },
  label4: {
    fontFamily: 'Lato',
    fontSize: 14,
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  mainBody: {
    backgroundColor: '#f7f7f7',
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 60.34,
    height: '100%',
  },
  cardMain: {
    backgroundColor: Colors.White,
    padding: 30,
    borderRadius: 6,
    textAlign: 'left',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  whiteColor: {
    color: Colors.White,
  },
});
