import * as API from 'shared/backend-data';
import * as APIf from './FactoryApi';
import { Result, isFailure } from './Failure';
import * as _ from 'lodash-es';
import { AsyncLock } from 'shared/util-ts/AsyncLock';
export * from './FactoryApi';

const createSkillLock = new AsyncLock();

/**
 * Create a Skill.
 * If skill is not Practical skill, link this Skill with the given TrainingVersions
 * @param skillInput
 * @param trainingVersionIds (Optional) specify which TrainingVersions are granting this Skill.
 */
export async function createSkill(
  skillInput: API.SkillCreateInput,
  trainingVersionIds: string[] = [],
): Promise<Result<API.Factory<API.DataType.SKILL>>> {
  
  return createSkillLock.runSerial(async () => {
    const _isSkillNameNotUnique = await isSkillNameNotUnique(skillInput);
    if (API.isFailure(_isSkillNameNotUnique)) return _isSkillNameNotUnique;

    if (_isSkillNameNotUnique)
      return API.createFailure(
        'DuplicateVeto',
        'The skill name is already assigned to another skill ',
        { dependencyIds: [_isSkillNameNotUnique.id] },
      );

    const factory = await API.createFactoryBusinessObject(API.DataType.SKILL, skillInput);
    if (isFailure(factory)) return factory;

    
    if (!factory.skill.isPractical) {
      if (trainingVersionIds.length > 0) {
        const failures = await API.mapLimit(trainingVersionIds, async trainingVersionId => {
          const trainingVersion = await API.getTrainingVersion(trainingVersionId);
          if (isFailure(trainingVersion)) return trainingVersion;

          return API.updateTrainingVersioMaybeDelete({
            ...trainingVersion,
            skillIds: _.union(trainingVersion.skillIds, [factory.skill.id]),
          });
        });
        if (API.isFailure(failures)) return failures;
      }
    }

    return factory;
  });
}

async function isSkillNameNotUnique(
  skillInput: API.SkillCreateInput,
): Promise<API.Result<API.Skill | undefined>> {
  const skills = await API.getSkills();
  if (isFailure(skills)) return skills;

  let foundSkillName;
  if (skills.result.length) {
    foundSkillName = _.find(skills.result, skill => skill.name === skillInput.name);
  }

  return foundSkillName;
}

const createSkillTagLock = new AsyncLock();
export async function createSkillTag(
  skillTagInput: API.SkillTagCreateInput,
): Promise<Result<API.SkillTag>> {
  return createSkillTagLock.runSerial(async () => {
    const skillTag = await skillTagExists(skillTagInput);
    if (API.isFailure(skillTag)) return skillTag;

    if (skillTag) {
      return API.createFailure('DuplicateVeto', 'Duplicate name for skill tag', {
        dependencyIds: [skillTag.id],
      });
    }

    const factory = await API.createFactoryBusinessObject(API.DataType.SKILLTAG, skillTagInput);
    if (isFailure(factory)) return factory;

    return { ...factory.skillTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
  });
}

export async function updateSkillTag(
  skillTag: API.SkillTagUpdateInput,
): Promise<API.Result<API.SkillTag>> {
  return createSkillTagLock.runSerial(async () => {
    const _skillTag = await skillTagExists(skillTag);
    if (API.isFailure(_skillTag)) return _skillTag;

    if (_skillTag)
      return API.createFailure('DuplicateVeto', 'Duplicate name for skill tag', {
        dependencyIds: [skillTag.id],
      });

    const factory = await API.updateFactoryBusinessObject(API.DataType.SKILLTAG, skillTag);
    if (isFailure(factory)) return factory;

    return { ...factory.skillTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
  });
}

export async function updateRole(role: API.RolePartialUpdateInput): Promise<Result<API.Role>> {
  const factory = await APIf.updateFactoryBusinessObject(API.DataType.ROLE, role);
  if (API.isFailure(factory)) return factory;

  return { ...factory.role, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function createRole(role: API.RoleCreateInput): Promise<Result<API.Role>> {
  const factory = await APIf.createFactoryBusinessObject(API.DataType.ROLE, role);
  if (API.isFailure(factory)) return factory;

  return { ...factory.role, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function deleteRole(roleId: string): Promise<Result<API.Role>> {
  const factory = await APIf.deleteFactoryBusinessObject<API.DataType.ROLE>(roleId);
  if (API.isFailure(factory)) return factory;

  return { ...factory.role, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
}

export async function getOrCreateWorkerSkill(
  workerId: string,
  skillId: string,
): Promise<Result<API.WorkerSkill>> {
  return API.getOrCreateFactory(
    API.DataType.WORKERSKILL,
    async () => {
      const workerSkill = await API.getWorkerSkill(workerId, skillId);
      if (API.isFailure(workerSkill)) {
        if (API.isFailureType(workerSkill, 'ObjectNotFound')) {
          const factory = await API.createFactoryBusinessObject(API.DataType.WORKERSKILL, {
            skillId: skillId,
            workerId: workerId,
            proofBundleIds: [],
          });
          if (isFailure(factory)) return factory;

          return {
            ...factory.workerSkill,
            updatedAt: factory.updatedAt,
            updatedBy: factory.updatedBy,
          };
        } else {
          return workerSkill;
        }
      }
      return workerSkill;
    },
    workerId + skillId,
  );
}

const createWorkerTagLock = new AsyncLock();
export async function createWorkerTag(
  workerTagInput: API.WorkerTagCreateInput,
): Promise<Result<API.WorkerTag>> {
  
  return createWorkerTagLock.runSerial(async () => {
    const _isWorkerTagUnique = await isWorkerTagUnique(workerTagInput);
    if (API.isFailure(_isWorkerTagUnique)) return _isWorkerTagUnique;
    if (!_isWorkerTagUnique)
      return API.createFailure('DuplicateVeto', 'Duplicate name for worker tag', {
        dependencyIds: [],
      });

    const factory = await API.createFactoryBusinessObject(API.DataType.WORKERTAG, workerTagInput);
    if (isFailure(factory)) return factory;

    return { ...factory.workerTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
  });
}

export async function updateWorkerTag(
  workerTagUpdateInput: API.WorkerTagUpdateInput,
): Promise<Result<API.WorkerTag>> {
  
  return createWorkerTagLock.runSerial(async () => {
    const _isWorkerTagUnique = await isWorkerTagUnique(workerTagUpdateInput);
    if (API.isFailure(_isWorkerTagUnique)) return _isWorkerTagUnique;
    if (!_isWorkerTagUnique)
      return API.createFailure('DuplicateVeto', 'Duplicate name for worker tag', {
        dependencyIds: [],
      });

    const factory = await API.updateFactoryBusinessObject(
      API.DataType.WORKERTAG,
      workerTagUpdateInput,
    );
    if (isFailure(factory)) return factory;

    return { ...factory.workerTag, updatedAt: factory.updatedAt, updatedBy: factory.updatedBy };
  });
}

async function isWorkerTagUnique(workerTag: API.WorkerTagCreateInput): Promise<Result<boolean>> {
  const workerTags = await API.getWorkerTags();
  if (API.isFailure(workerTags)) return workerTags;

  return !_.some(workerTags, wt => wt.name === workerTag.name);
}

async function skillTagExists(
  skillTagInput: API.SkillTagCreateInput,
): Promise<Result<API.SkillTag | null>> {
  const skillTags = await API.getSkillTags();
  if (API.isFailure(skillTags)) return skillTags;

  for (const skillTag of skillTags) {
    if (skillTag.name === skillTagInput.name) return skillTag;
  }
  return null;
}

export async function getSkillDependencies(skillId: string): Promise<API.Result<string[]>> {
  let dependencyIds: string[] = [];

  
  const workerSkills = await API.getWorkerSkills(undefined, skillId);
  if (API.isFailure(workerSkills)) {
    return workerSkills;
  }

  if (workerSkills.result.length) {
    const workerSkillsIds = _.map(workerSkills.result, workerSkill => workerSkill.id);
    dependencyIds = _.concat(dependencyIds, workerSkillsIds);
  }

  
  const factoryRequirements = await API.listFactoriesWithDataType(API.DataType.REQUIREMENT);
  if (API.isFailure(factoryRequirements)) return factoryRequirements;

  const requirementIds = _.compact(
    _.map(factoryRequirements.result, factory => {
      if (
        _.includes(
          _.map(
            factory.requirement!.skillTrainingVersions,
            skillTrainingVersion => skillTrainingVersion.skillId,
          ),
          skillId,
        )
      )
        return factory.requirement!.id;
    }),
  );
  if (requirementIds.length) dependencyIds = _.concat(dependencyIds, requirementIds);

  
  const trainingVersions = await API.getTrainingVersionsForSkill(skillId, false);
  if (API.isFailure(trainingVersions)) {
    return trainingVersions;
  }
  let trainingVersionIncluded = _.some(trainingVersions, trainingVersion => {
    if (trainingVersion.skillIds.length > 1) return true;
  });
  if (trainingVersionIncluded)
    dependencyIds = _.concat(
      dependencyIds,
      _.map(trainingVersions, trainingVersion => trainingVersion.id),
    );

  return dependencyIds;
}












































































