import { ExternalLinkAuthParams } from 'shared/layout/authentication';

export enum RouteNameCommon {
  SPLASH_SCREEN = 'SPLASH_SCREEN',

  UnauthorizedRoute = 'Unauthorized_Route',
  AuthorizedRoute = 'Authorized_Route',
  Authentication_Layout = 'Authentication_Layout',

  HOME = 'HOME',

  ACCOUNT_SCREEN = 'ACCOUNT_SCREEN',
  SETTINGS = 'SETTINGS',
  NOTIFICATION = 'NOTIFICATION',
  LANGUAGE = 'LANGUAGE',
  TEAM = 'TEAM',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
}

export type StackNavigatorParamlistCommon = {
  [RouteNameCommon.UnauthorizedRoute]: undefined;
  [RouteNameCommon.AuthorizedRoute]: undefined;
  [RouteNameCommon.Authentication_Layout]?: ExternalLinkAuthParams;

  [RouteNameCommon.HOME]: {
    
  };

  [RouteNameCommon.ACCOUNT_SCREEN]: undefined;
  [RouteNameCommon.SETTINGS]: undefined;

  [RouteNameCommon.UPDATE_ACCOUNT]: {
    type?: string;
  };
};
