import { StyleSheet } from 'react-native-web';
import { Colors, Spacings } from 'shared/styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.LightBlue,
    flex: 1,
  },
  containerFullScreen: { position: 'absolute', zIndex: 20, top: 0 },
  title: {
    marginTop: 30,
    marginBottom: 30,
  },
  mainCnt: {
    flexDirection: 'row',
    flex: 1,
  },
  mainContainerFullScreen: { height: '100%', width: '100%' },
  filterContainer: {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: 999,
    paddingTop: Spacings.Medium,
    paddingLeft: Spacings.Medium,
  },
  leftSide: {
    marginRight: Spacings.CardPadding,
    zIndex: 2,
    flex: 1,
    minWidth: 400,
  },
  lefSideFullScreen: {
    width: '100%',
    height: '100%',
    zIndex: 2,
    marginRight: 0,
  },
  rightContainer: {
    width: 336,
    height: '100%',
  },
  trainingPanelContainer: {
    width: 336,
    zIndex: 2,
    marginBottom: Spacings.CardPadding,
  },
  skillValidateContainer: { flex: 1 },
});

export default styles;
