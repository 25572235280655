import { StyleSheet } from 'react-native';

const WebStyles = StyleSheet.create({
  CardContainer: {
    padding: 30,
    borderRadius: 6,
    textAlign: 'left',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});

export default WebStyles;
