import React, { useState, useEffect } from 'react';
import * as Pattren from 'shared/util/const';
import _, { isFunction, isString } from 'lodash-es';
import logger from '../util/Logger';
import { TextInput } from 'react-native';
import { t } from '../localisation/i18n';

/**
 * @isFieldMandatory boolean set to true to disable form submit if this field is not filled
 * @initialValue initial value of the field
 * @validator either InputValidationType[] or ValidatorFunctionType<T> or null used to validate the inputted value to this field,
 * @null to disable validation, @InputValidationType predefined input validation patterns @ValidatorFunctionType custom function to validate the field
 * @inputErrorMessage the error message to be displayed if user inputted incorrect value
 */
export interface InputSchema<T> {
  isFieldMandatory: boolean;
  initialValue?: T | null;
  validator?: InputValidationType[] | ValidatorFunctionType<T> | null;
  inputErrorMessage?: string;
}

export interface FormSchema<T> {
  [inputId: string]: InputSchema<T>;
}

export interface InputsValue<T> {
  [inputId: string]: T;
}

export interface InputsValidity {
  [inputId: string]: boolean;
}

export interface InputsError<T> {
  [inputId: string]: Pick<InputSchema<T>, 'inputErrorMessage'>;
}

export interface RefsForInput<T> {
  [inputId: string]: React.RefObject<T>;
}

export enum InputValidationType {
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  Password = 'Password',
  WorkerPersonalIdPattern = 'WorkerPersonalIdPattern',
  NotEmpty = 'NotEmpty',
}

export const EMPTY_STRING = ' ';

type ValidatorFunctionType<T> = (inputValue: T | undefined) => boolean;

/**
 * @param schema of the the form
 * @param onSubmitForm call back function to be executed on form submission
 * @returns
 * @onBlur @onChange @onSubmit to be given to the input fields in the form
 * @navigateInputField to be used for navigating the input filed when we press Enter key
 * @values @errors values and errors from the entered data in the form
 * @enableFormSubmit flag to enable form submission when the required fields are filled
 */
export function useCustomForm<T>(
  schema: FormSchema<T>,
  onSubmitForm: (values: InputsValue<T>) => void,
) {
  const [values, setValues] = useState<InputsValue<T>>({});
  const [formInputsValidity, setFormInputsValidity] = useState<InputsValidity>({});
  const [errors, setErrors] = useState<InputsError<T>>({});
  const [refs, setRefs] = useState<RefsForInput<TextInput>>({});
  const [enableFormSubmit, setEnableSubmit] = useState<boolean>(false);

  for (const inputId in schema) {
    const ref = React.createRef<TextInput>();
    if (!refs[inputId]) {
      setRefs({
        ...refs,
        [inputId]: ref,
      });
    }
  }

  useEffect(() => {
    for (const inputId in schema) {
      const initialValue = schema[inputId].initialValue;
      if (initialValue && values[inputId] === undefined) {
        setValues({ ...values, [inputId]: initialValue });
        setFormInputsValidity({
          ...formInputsValidity,
          [inputId]: validateInput(inputId, initialValue),
        });
      }
    }
  }, [schema]);

  useEffect(() => {
    for (const inputId in schema) {
      if (!schema[inputId].isFieldMandatory) {
        setEnableSubmit(true);
        return;
      }
    }
  }, []);

  function validateForm(): boolean {
    for (const inputId in schema) {
      const value = values[inputId];
      
      if (
        schema[inputId].isFieldMandatory &&
        ((isString(value) && value === '') || !value || formInputsValidity[inputId] === false)
      ) {
        setErrors({ ...errors, [inputId]: { inputErrorMessage: EMPTY_STRING } });
        return false;
      }
    }
    return true;
  }

  function passwordChecker(value: string, inputName: string): boolean {
    const atLeastOneNumber = /[0-9]/;
    const atLeastOneLowerCase = /[a-z]/;
    const atLeastOneUpperCase = /[A-Z]/;
    const oneSpecialCharacter = /(?=.*?[#?!@$%^&*-])/;
    const containsMin12Characters = /.{12,}/;

    if (!containsMin12Characters.test(value)) {
      setErrors({
        ...errors,
        [inputName]: { inputErrorMessage: t('common:auth.login.password.min12Characters') },
      });

      return false;
    } else if (!oneSpecialCharacter.test(value)) {
      setErrors({
        ...errors,
        [inputName]: { inputErrorMessage: t('common:auth.login.password.needOneSpecialCharacter') },
      });

      return false;
    } else if (!atLeastOneUpperCase.test(value)) {
      setErrors({
        ...errors,
        [inputName]: { inputErrorMessage: t('common:auth.login.password.needOneUpperCase') },
      });

      return false;
    } else if (!atLeastOneLowerCase.test(value)) {
      setErrors({
        ...errors,
        [inputName]: { inputErrorMessage: t('common:auth.login.password.needOneLowerCase') },
      });

      return false;
    } else if (!atLeastOneNumber.test(value)) {
      setErrors({
        ...errors,
        [inputName]: { inputErrorMessage: t('common:auth.login.password.needOneNumber') },
      });

      return false;
    }

    return true;
  }

  function validateInput(inputName: string, value: T | undefined): boolean {
    const validator = schema[inputName].validator;
    if (validator) {
      if (Array.isArray(validator)) {
        if (!isString(value)) return true;
        let valid = false;
        validator.forEach(validationType => {
          if (validationType === InputValidationType.Password) {
            valid = passwordChecker(value, inputName);
            return;
          } else {
            const validationPattren = getPattren(validationType);
            if (validationPattren) {
              if (validationPattren.test(value)) {
                valid = true;
                return;
              }
            } else {
              valid = false;
              return;
            }
          }
        });
        setFormInputsValidity({ ...formInputsValidity, [inputName]: valid });
        return valid;
      } else if (isFunction(validator)) {
        const valid = validator(value);
        setFormInputsValidity({ ...formInputsValidity, [inputName]: valid });
        return valid;
      }
    }
    return true;
  }

  function getPattren(validationType: InputValidationType): RegExp | null {
    switch (validationType) {
      case InputValidationType.Email:
        return Pattren.emailPattern;
      case InputValidationType.Password:
        return Pattren.passwordPattern;
      case InputValidationType.PhoneNumber:
        return Pattren.phoneNumberPattern;
      case InputValidationType.WorkerPersonalIdPattern:
        return Pattren.workerPersonalIdPattern;
      case InputValidationType.NotEmpty:
        return Pattren.notEmptyPattern;
      default:
        logger.error(
          validationType,
          ' validationType is not implemented.By default the test will fail',
        );
        return null;
    }
  }

  function updateInputValidity(inputName: string, value: T) {
    const newInputValidity = validateInput(inputName, value);
    const oldInputValidity = formInputsValidity[inputName];

    setFormInputsValidity({
      ...formInputsValidity,
      [inputName]: newInputValidity,
    });

    if (newInputValidity !== oldInputValidity) {
      let allInputsAreValid = true;

      for (const inputId in schema) {
        const inputValidity =
          inputId === inputName ? newInputValidity : formInputsValidity[inputId];
        if (!inputValidity) {
          allInputsAreValid = false;
          break;
        }
      }
      setEnableSubmit(allInputsAreValid);
    }
  }

  function onChange(inputName: string, value: T) {
    if (value && isString(value)) {
      value = value.trim() as unknown as T; 
    }
    setValues({ ...values, [inputName]: value });
    setErrors({ ...errors, [inputName]: { inputErrorMessage: undefined } });
    updateInputValidity(inputName, value);
  }

  function onSubmit() {
    const isFormValid = validateForm();
    if (isFormValid) {
      onSubmitForm(values);
    }
  }

  function navigateInputField(inputName?: string) {
    if (inputName && refs[inputName] && refs[inputName].current) {
      refs[inputName].current?.focus();
    }
  }

  function onBlur(inputName: string, value: T) {
    if (!formInputsValidity[inputName]) {
      if (schema[inputName]?.isFieldMandatory || value) {
        setErrors({
          ...errors,
          [inputName]: {
            inputErrorMessage: schema[inputName]?.inputErrorMessage ?? EMPTY_STRING,
          },
        });
      }
    }
  }

  return {
    refs,
    values,
    errors,
    enableFormSubmit,
    onBlur,
    onChange,
    onSubmit,
    navigateInputField,
  };
}
