import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import AlexIcon from 'shared/assets/svg/iconAlex512.svg';
import { ProgressBar } from 'shared/ui-component/ProgressBar';
import { IconSVG } from 'shared/ui-component/Icon';
import { t } from 'shared/localisation/i18n';
import { TextFontStyles, Spacings, Colors, fontSize } from 'shared/styles';

interface Props {
  workerSkillTotalCount: number;
  proofTotalCount: number;
  proofUploadedCount: number;
  workerSkillUploadedCount: number;
}

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    backgroundColor: Colors.White,
    paddingTop: 132,
    justifyContent: 'space-between',
  },
  title: {
    paddingTop: 74,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Big,
    color: Colors.BlackLight,
  },
  progressBar: {
    marginTop: Spacings.CardPadding,
    width: 300,
    marginHorizontal: Spacings.Standard,
  },
  icon: {
    height: 112,
    width: 112,
  },
  footerText: {
    fontSize: fontSize.Medium,
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    textAlign: 'center',
    lineHeight: Spacings.Standard,
    marginBottom: 60,
    marginHorizontal: Spacings.Standard,
  },
});

export const AddProofLoading: React.FC<Props> = props => {
  const {
    workerSkillTotalCount,
    proofTotalCount,
    proofUploadedCount,
    workerSkillUploadedCount,
  } = props;
  const totalCount = workerSkillTotalCount + proofTotalCount;
  const count = proofUploadedCount + workerSkillUploadedCount;
  const percentage = (count / totalCount) * 100 || 0;

  return (
    <View style={styles.rootContainer}>
      <View>
        <IconSVG svgComponent={AlexIcon} size={{ height: 100, width: 100 }} />
        <View style={{ alignItems: 'center' }}>
          <Text style={styles.title}>{`${t(
            'alex:mobile.addProofPage.loadingScreenTitle',
          )} ${Math.round(percentage)}%`}</Text>
          <ProgressBar percentage={percentage} style={styles.progressBar} />
        </View>
      </View>
      <View>
        <Text style={styles.footerText}>{t('alex:mobile.addProofPage.loadingScreenFooter')}</Text>
      </View>
    </View>
  );
};
