import React from 'react';
import { WorkstationWorkerLevel, WorkstationWorkerLevelTargetWarning } from 'backend/src/api';
import { WorkstationWorkerLevels, api2workstationWorkerLevels } from 'shared/util/WorkerLevel';
import * as API from 'shared/backend-data';
import { defaultSVGSize } from '../IconSVG';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';
import { Colors } from 'shared/styles';
import logger from 'shared/util/Logger';

interface LevelIconProps {
  workerWorkstation: Pick<
    API.WorkerWorkstation,
    'level' | 'previousLevel' | 'targetLevel' | 'activeTrainingSessions' | 'isTrainAuto' | 'warning'
  >;
  level?: API.WorkstationWorkerLevels | null;
  levelIconType?: API.LevelIconType;
  iconSize?: { width: number; height: number };
}

interface PlainLevelIconProps {
  level: API.WorkstationWorkerLevel;
  levelIconType?: API.LevelIconType;
  iconSize?: { width: number; height: number };
}

/**
 * A LevelIcon that will not show any warning, color code or decorator:
 * a dumb icon used only to draw the icon.
 * @see LevelIcon
 * @param props
 * @returns
 */
export const PlainLevelIcon: React.FC<PlainLevelIconProps> = props => {
  return (
    <LevelIcon
      workerWorkstation={{
        level: props.level,
        previousLevel: props.level,
        targetLevel: props.level,
        isTrainAuto: false,
        activeTrainingSessions: {
          workstationActiveTrainingSessions: {
            lowerOrEqualToTarget: {
              fromNonInheritedRequirements: [],
              fromInheritedRequirements: [],
            },
            greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
          },
          nonWorkstationActiveTrainingSessions: {
            lowerOrEqualToTarget: {
              fromNonInheritedRequirements: [],
              fromInheritedRequirements: [],
            },
            greaterThanTarget: { fromNonInheritedRequirements: [], fromInheritedRequirements: [] },
          },
        },
      }}
      levelIconType={props.levelIconType}
      iconSize={props.iconSize}
    />
  );
};

const cache = new Map<string, React.JSX.Element>();
/**
 * LevelIcon that computes its color, opacity, and decorator icon (train, lock, etc.)
 * @see PlainLevelIcon
 */
export const LevelIcon: React.FC<LevelIconProps> = props => {
  const wW = props.workerWorkstation;
  const { width, height } = props.iconSize ?? defaultSVGSize();

  let warning:
    | WorkstationWorkerLevelTargetWarning.EXPIRE_SOON
    | WorkstationWorkerLevelTargetWarning.EXPIRED
    | undefined;
  if (
    wW?.warning === WorkstationWorkerLevelTargetWarning.EXPIRE_SOON ||
    wW?.warning === WorkstationWorkerLevelTargetWarning.EXPIRED
  ) {
    warning = wW.warning;
  }

  const decorator = API.isWorkerInTrainAutoOnWorkstation(wW)
    ? 'trainauto'
    : API.isWorkerInTrainingOnWorkstation(wW)
    ? 'train'
    : undefined;

  
  const key =
    '' + props.levelIconType + wW.level + wW.previousLevel + wW.targetLevel + warning + decorator;
  let icon = cache.get(key);
  if (!icon) {
    icon = decorate(
      buildLevels(
        props.levelIconType ?? API.LevelIconType.LINE,
        api2workstationWorkerLevels(wW.level ?? WorkstationWorkerLevel.LEVEL0),
        api2workstationWorkerLevels(wW.previousLevel),
        wW.targetLevel
          ? api2workstationWorkerLevels(wW.targetLevel)
          : WorkstationWorkerLevels.LEVEL0,
        warning,
      ),
      decorator,
    );
    cache.set(key, icon);
  }

  if (!logger.isPROD) {
    API.loggerPerf.info(
      'PERF: Why the levelIcon is recomputed each time we mouse over the level icon ?',
    );
  }

  return (
    <Svg viewBox="0 0 28 28" width={width} height={height}>
      {icon}
    </Svg>
  );
};

const level0 = (color: string, iconLevelType: API.LevelIconType) => {
  switch (iconLevelType) {
    case API.LevelIconType.LINE:
      return (
        <G fill={color}>
          <Path d="M14 8c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6m0-4c5.5 0 10 4.5 10 10s-4.5 10-10 10S4 19.5 4 14 8.5 4 14 4Z" />
          <Path d="m4.807 21.778 16.97-16.97a.998.998 0 0 1 1.415 0 .998.998 0 0 1 0 1.414l-16.97 16.97a.998.998 0 0 1-1.415 0 .998.998 0 0 1 0-1.414z" />
        </G>
      );
    

    case API.LevelIconType.PIE:
      return (
        <Path
          d="M26.4 14A12.4 12.4 0 0 1 14 26.4 12.4 12.4 0 0 1 1.6 14 12.4 12.4 0 0 1 14 1.6 12.4 12.4 0 0 1 26.4 14Z"
          fill="none"
          stroke={color}
          strokeWidth="1.2"
        />
      );

    case API.LevelIconType.SQUARE:
      return <Path d="M1.5 1.5h25v25h-25z" stroke={color} fill="none" />;
  }
};
const level1 = (color: string, iconLevelType: API.LevelIconType): React.JSX.Element => {
  switch (iconLevelType) {
    case API.LevelIconType.LINE:
      return <Path d="M4 4h6v20H4Z" fill={color} />;

    case API.LevelIconType.PIE:
      return <Path d="M13.971 2a12 12 0 0 1 8.504 3.505A12 12 0 0 1 26 14H14Z" fill={color} />;

    case API.LevelIconType.SQUARE:
      return <Path d="M14.5 14.5H27V27H14.5Z" fill={color} />;
  }
};
const level2 = (color: string, iconLevelType: API.LevelIconType): React.JSX.Element => {
  switch (iconLevelType) {
    case API.LevelIconType.LINE:
      return <Path d="M10 18h14v6H10Z" fill={color} />;

    case API.LevelIconType.PIE:
      return (
        <Path d="M26 13.986a12 12 0 0 1-3.51 8.495A12 12 0 0 1 13.997 26L14 14Z" fill={color} />
      );

    case API.LevelIconType.SQUARE:
      return <Path d="M1 14.5h12.5V27H1Z" fill={color} />;
  }
};
const level3 = (color: string, iconLevelType: API.LevelIconType): React.JSX.Element => {
  switch (iconLevelType) {
    case API.LevelIconType.LINE:
      return <Path d="M18 4h6v14h-6z" fill={color} />;

    case API.LevelIconType.PIE:
      return (
        <Path d="M13.998 26a12 12 0 0 1-8.486-3.517A12 12 0 0 1 2 13.995L14 14Z" fill={color} />
      );

    case API.LevelIconType.SQUARE:
      return <Path d="M1 1h12.5v12.5H1Z" fill={color} />;
  }
};
const level4 = (color: string, iconLevelType: API.LevelIconType): React.JSX.Element => {
  switch (iconLevelType) {
    case API.LevelIconType.LINE:
      return <Path d="M10 4h8v6h-8z" fill={color} />;

    case API.LevelIconType.PIE:
      return <Path d="M2 13.995A12 12 0 0 1 13.995 2L14 14Z" fill={color} />;

    case API.LevelIconType.SQUARE:
      return <Path d="M14.5 1H27v12.5H14.5Z" fill={color} />;
  }
};

function buildLevels(
  levelIconType: API.LevelIconType,
  level: WorkstationWorkerLevels,
  previousLevel: WorkstationWorkerLevels,
  target: WorkstationWorkerLevels,
  warning?:
    | WorkstationWorkerLevelTargetWarning.EXPIRE_SOON
    | WorkstationWorkerLevelTargetWarning.EXPIRED,
): React.JSX.Element {
  let noTargetColor = Colors.GreyLight;
  let targetColor =
    levelIconType === API.LevelIconType.SQUARE ? Colors.GreenSquareIcon : Colors.DarkBlack;
  if (warning === WorkstationWorkerLevelTargetWarning.EXPIRE_SOON) {
    targetColor = Colors.Orange;
  } else if (warning === WorkstationWorkerLevelTargetWarning.EXPIRED) {
    targetColor = Colors.Red;
  }

  
  
  
  let color0 = target !== WorkstationWorkerLevels.LEVEL0 ? targetColor : noTargetColor;
  let color1 =
    level > WorkstationWorkerLevels.LEVEL0
      ? target > WorkstationWorkerLevels.LEVEL0
        ? targetColor
        : noTargetColor
      : undefined;
  let color2 =
    level > WorkstationWorkerLevels.LEVEL1
      ? target > WorkstationWorkerLevels.LEVEL1
        ? targetColor
        : noTargetColor
      : undefined;
  let color3 =
    level > WorkstationWorkerLevels.LEVEL2
      ? target > WorkstationWorkerLevels.LEVEL2
        ? targetColor
        : noTargetColor
      : undefined;
  let color4 =
    level > WorkstationWorkerLevels.LEVEL3
      ? target > WorkstationWorkerLevels.LEVEL3
        ? targetColor
        : noTargetColor
      : undefined;

  
  
  
  
  if (warning && previousLevel > level) {
    if (level < WorkstationWorkerLevels.LEVEL1 && previousLevel >= WorkstationWorkerLevels.LEVEL1) {
      color1 =
        (color1 ?? target > WorkstationWorkerLevels.LEVEL0 ? targetColor : noTargetColor) + '55'; 
    }
    if (level < WorkstationWorkerLevels.LEVEL2 && previousLevel >= WorkstationWorkerLevels.LEVEL2) {
      color2 =
        (color2 ?? target > WorkstationWorkerLevels.LEVEL1 ? targetColor : noTargetColor) + '55'; 
    }
    if (level < WorkstationWorkerLevels.LEVEL3 && previousLevel >= WorkstationWorkerLevels.LEVEL3) {
      color3 =
        (color3 ?? target > WorkstationWorkerLevels.LEVEL2 ? targetColor : noTargetColor) + '55'; 
    }
    if (level < WorkstationWorkerLevels.LEVEL4 && previousLevel >= WorkstationWorkerLevels.LEVEL4) {
      color4 =
        (color4 ?? target > WorkstationWorkerLevels.LEVEL3 ? targetColor : noTargetColor) + '55'; 
    }
  }

  return (
    <>
      {!color1 && level0(color0, levelIconType)}
      {color1 && level1(color1, levelIconType)}
      {color2 && level2(color2, levelIconType)}
      {color3 && level3(color3, levelIconType)}
      {color4 && level4(color4, levelIconType)}
      {levelIconType === API.LevelIconType.PIE && color1 && (
        <Path
          d="M26.4 14A12.4 12.4 0 0 1 14 26.4 12.4 12.4 0 0 1 1.6 14 12.4 12.4 0 0 1 14 1.6 12.4 12.4 0 0 1 26.4 14Z"
          fill="none"
          stroke={color0}
          strokeWidth="1.2"
        />
      )}
    </>
  );
}

function decorate(
  component: React.JSX.Element,
  decorator?: 'train' | 'trainauto' | undefined,
): React.JSX.Element {
  if (decorator) {
    return (
      <>
        {decorator === 'train' && <Path d="m0 26.3 2.8-2.8L.2 21H7v6.8l-2.5-2.6L1.7 28Z" />}
        {decorator === 'trainauto' && (
          <Path d="M6.25 23.5h-.375v-1.125a2.375 2.375 0 1 0-4.75 0V23.5H.75a.75.75 0 0 0-.75.75v3a.75.75 0 0 0 .75.75h5.5a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75Zm-1.625 0h-2.25v-1.125a1.125 1.125 0 0 1 2.25 0z" />
        )}
        <Defs>
          <ClipPath id="a">
            <Path d="M-4-4h36v36H-4Zm4 32h10.3c1.3-2.8.8-6.4-1.5-8.8-2.4-2.3-5.9-2.8-8.8-1.5Z" />
          </ClipPath>
        </Defs>
        <G clipPath="url(#a)">{component}</G>
      </>
    );
  }

  return <>{component}</>;
}
