import React, { useState } from 'react';
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from 'react-native';
import { Text as WebText } from 'react-native-web';
import Styles from './Style';
import { Spacings, Colors } from 'shared/styles';
import { AbstractButton } from 'shared/ui-component/Button/Button';
import { IconSVG } from 'shared/ui-component/Icon';
import { InputText } from 'shared/ui-component/Input';
import { t } from 'shared/localisation/i18n';
import { useModal } from '../Modal';
import { Checkbox } from '../../Checkbox';


export interface WarningModalProps {
  warningMessage: string | JSX.Element; 
  warningSubMessage?: string;
  details?: string; 
  warningAcceptButton?: string | undefined;
  isGeneric?: boolean;
  isBlocking?: boolean;
  needInputArea?: boolean;
  warningCancelButton?: string | undefined;
  loader?: boolean; 
  buttonStyle?: StyleProp<ViewStyle>;
  buttonContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  checkBoxWarning?: string;

  onModalHide?: () => void;
  warningAcceptCallback?: () => void;
  warningCancelCallback?: () => void;
  checkBoxCallback?: (value: boolean) => void;
}

const closeIcon = require('shared/assets/svg/icon.cross.mobile.svg').default;

const WarningModalContent: React.FC<WarningModalProps> = props => {
  const { buttonContainerStyle, needInputArea, checkBoxWarning, checkBoxCallback } = props;

  const isPlatformWeb = Platform.OS === 'web';

  const [isShowDetailsPressed, setIsShowDetailsPressed] = useState<boolean>(false);
  const [checkBoxValue, setCheckBoxValue] = useState<boolean>(false);

  const onAccept = (): void => {
    if (props.warningAcceptCallback) props.warningAcceptCallback();
    if (props.isGeneric) setIsShowDetailsPressed(!isShowDetailsPressed);
  };

  const onCancel = (): void => {
    if (props.warningCancelCallback) props.warningCancelCallback();
  };

  /**
   * Checks if:
   * 1- It's a generic modal (used for failures), and
   * 2- It received a 'details' prop, and
   * 3- The 'Details' button was pressed
   * @returns boolean
   */
  const isShowDetails = (): boolean => {
    return !!props.isGeneric && !!props.details && isShowDetailsPressed;
  };

  /**
   * Checks if:
   * 1- It received an accept button prop, and it is not a generic modal, or if:
   * 2- It's a generic modal (used for failures), and the accept button hasn't been pressed yet
   * @returns boolean
   */
  const isShowAcceptButton = (): boolean => {
    return (
      (!!props.warningAcceptButton && !props.isGeneric) ||
      (!!props.isGeneric && !isShowDetailsPressed)
    );
  };

  /**
   * Checks if:
   * It's not a blocking modal
   * It is a generic modal
   * @returns boolean
   */
  const isShowCloseButton = (): boolean => {
    return (!props.isBlocking && props.isGeneric)!;
  };

  function _checkBoxCallback(value: boolean | null) {
    setCheckBoxValue(!!value);
    if (checkBoxCallback) checkBoxCallback(!!value);
  }

  return (
    <TouchableOpacity style={{ flex: 1 }} activeOpacity={1} onPress={onCancel}>
      <View style={Styles.contentContainer}>
        <TouchableWithoutFeedback>
          <View style={[Styles.innerContainer, props.containerStyle ?? {}]}>
            {isShowCloseButton() && (
              <TouchableOpacity onPress={onCancel} style={Styles.closeIcon}>
                <IconSVG
                  svgComponent={closeIcon}
                  color={Colors.Black}
                  size={{ width: Spacings.Standard, height: Spacings.Standard }}
                />
              </TouchableOpacity>
            )}
            <View style={Styles.textContainer}>
              {isPlatformWeb ? (
                <>
                  <WebText style={Styles.warningText}>
                    {isShowDetails() ? props.details : props.warningMessage}
                  </WebText>
                  {!isShowDetails() && (
                    <View style={Styles.warningSubTextContainer}>
                      <WebText
                        style={[
                          Styles.warningSubText,
                          Styles.warningText,
                          isPlatformWeb && Styles.warningText,
                        ]}
                      >
                        {props.warningSubMessage}
                      </WebText>
                    </View>
                  )}
                </>
              ) : (
                <>
                  <Text style={[Styles.warningText]}>
                    {isShowDetails() ? props.details : props.warningMessage}
                  </Text>
                  {!isShowDetails() && (
                    <View style={[Styles.warningSubTextContainer]}>
                      <Text style={[Styles.warningSubText, Styles.warningText]}>
                        {props.warningSubMessage}
                      </Text>
                    </View>
                  )}
                </>
              )}
            </View>
            {needInputArea && (
              <View style={Styles.inputTextContainer}>
                <InputText
                  label={t('alex:mobile.workstationTrainingScreen.comments')}
                  placeHolder={t('alex:mobile.workstationTrainingScreen.comments')}
                />
              </View>
            )}

            {checkBoxWarning && (
              <Checkbox
                onChange={_checkBoxCallback}
                text={checkBoxWarning}
                initialState={checkBoxValue}
                style={Styles.checkBoxText}
              />
            )}

            <View style={[Styles.buttonContainer, buttonContainerStyle]}>
              <View style={{ flex: 1 }}>
                {props.loader && <ActivityIndicator size="small" color={Colors.Yellow} />}
                {isShowAcceptButton() && (
                  <AbstractButton
                    text={props.warningAcceptButton}
                    containerStyle={[Styles.button, props.buttonStyle]}
                    textStyle={Styles.buttonText}
                    onPress={onAccept}
                  />
                )}
              </View>
              <View style={Styles.buttonSeparator} />
              {!!props.warningCancelButton && (
                <View style={Styles.cancelButtonContainer}>
                  <AbstractButton
                    containerStyle={[Styles.button, props.buttonStyle]}
                    textStyle={Styles.buttonText}
                    text={props.warningCancelButton}
                    onPress={onCancel}
                  />
                </View>
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableOpacity>
  );
};

export const Warning: React.FC<WarningModalProps> = props => {
  const modal = useModal();

  const onAccept = (): void => {
    if (!props.isBlocking && !props.isGeneric) modal.hideModal();
    if (props.warningAcceptCallback) props.warningAcceptCallback();
  };

  const onCancel = (): void => {
    if (!props.isBlocking) modal.hideModal();
    if (props.warningCancelCallback) props.warningCancelCallback();
  };

  return (
    <WarningModalContent
      {...{
        ...props,
        warningAcceptCallback: onAccept,
        warningCancelCallback: onCancel,
      }}
    />
  );
};
