import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
  modalOverlay: {
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
  },
  textInput: {
    textAlignVertical: 'top',
    textAlign: 'left',
    fontFamily: 'Lato, Regular',
    color: '#707070',
    borderWidth: 1,
    borderColor: '#D6D6D6',
    backgroundColor: 'white',
    borderRadius: 5,
    fontSize: '1.6vh',
    paddingLeft: '1.5vh',
    paddingRight: '1.5vh',
    height: '4vh',
    width: '44.5vw',
  },
  flatListContainer: {
    height: '19.9vh',
    backgroundColor: 'white',
    width: '44.5vw',
    borderWidth: 1,
    borderColor: '#78DBD9',
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontFamily: 'Lato, Regular',
    color: '#666666',
    fontSize: '1.8vh',
    marginBottom: '1vh',
  },
  listItemContainer: {
    paddingLeft: '1.4vw',
    paddingTop: '1.5vh',
    paddingBottom: '1.4vh',
  },
  dropDownLabel: {
    color: '#666666',
    fontFamily: 'Lato, Regular',
    fontSize: '1.7vh',
  },
});

export default styles;
