import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import * as API from 'shared/backend-data';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import { InputList } from 'shared/ui-component/Input/InputList';
import { t } from 'shared/localisation/i18n';
import { InputDateWeb } from 'sharedweb/src/InputDate';
import moment from 'moment';
import styles from './styles';
import orderBy from 'lodash-es/orderBy';

interface Props {
  trainingSessions: API.TrainingSession[];
  initialTrainingSessionId?: string;

  onSelectTrainingSession: (trainingSession: Tag) => void;
}

export const TrainingSessionHistoryPicker: React.FC<Props> = props => {
  const { initialTrainingSessionId, trainingSessions, onSelectTrainingSession } = props;

  const [showDropDownMenu, setShowDropDownMenu] = useState<boolean>(false);
  const [trainingSessionOption, setTrainingSessionOption] = useState<Tag>();
  const [trainingSessionOptions, setTrainingSessionOptions] = useState<Tag[]>([]);

  useEffect(() => {
    if (!trainingSessions) return;

    createTrainingSessionTags();
  }, [trainingSessions, initialTrainingSessionId]);

  function sortTrainingSessions(trainingSessions: Tag[]): Tag[] {
    return trainingSessions.sort((a, b) => b.value.startDate.localeCompare(a.value.startDate));
  }

  function handleSelectProof(inputId: string, trainingSession: Tag[]) {
    if (!trainingSession[0]) return;

    setTrainingSessionOption(trainingSession[0]);
    onSelectTrainingSession(trainingSession[0]);
  }

  async function createTrainingSessionTags() {
    const sortedTrainingSessions: Tag[] = orderBy(
      trainingSessions.map(trainingSession => {
        return {
          key: trainingSession.id,
          label: moment(trainingSession.startDate).format('L'),
          value: trainingSession,
        };
      }),
      item => item.value.startDate,
      ['desc'],
    );

    if (initialTrainingSessionId) {
      const found = sortedTrainingSessions.find(
        sortedTrainingSession => sortedTrainingSession.key === initialTrainingSessionId,
      );
      setTrainingSessionOption(found);
    }

    setTrainingSessionOptions(sortedTrainingSessions);
  }

  if (trainingSessionOptions.length === 1 || !trainingSessionOptions.length) return null;

  return (
    <View>
      {showDropDownMenu && (
        <InputList
          placeholder={t('common:filters.filterData')}
          options={sortTrainingSessions(trainingSessionOptions)}
          containerStyle={styles.inputList}
          handleSelection={(inputId, option) => handleSelectProof(inputId, option)}
          openInputList={setShowDropDownMenu}
          singleSelection
          initialSelection={trainingSessionOption && [trainingSessionOption]}
        />
      )}
      <TouchableOpacity
        disabled={!trainingSessionOptions.length}
        onPress={() => setShowDropDownMenu(!showDropDownMenu)}
        style={styles.datePicker}
      >
        <InputDateWeb
          hasDateIcon
          inputPlaceholder={t('alex:workerSkillReviewModal.history')}
          inputName={t('alex:workerSkillReviewModal.history')}
          onChange={() => null}
          value={new Date(trainingSessionOption?.value.startDate)}
          disableCalendar
        />
      </TouchableOpacity>
    </View>
  );
};
