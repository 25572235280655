import { StyleSheet } from 'react-native';
import { TextFontStyles } from 'shared/styles/TextStyles';
import { Colors, fontSize, Spacings } from 'shared/styles';

const styles = StyleSheet.create({
  activityIndicator: {
    flex: 1,
  },
  card: {
    padding: Spacings.CardPadding,
    position: 'absolute',
    right: Spacings.Standard,
    top: Spacings.Standard,
    width: Spacings.Big * 6,
  },
  firstColumnCellContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstColumnText: {
    textAlign: 'left',
    fontSize: 14,
    color: Colors.DarkGrey,
    ...TextFontStyles.LatoBlack,
    justifyContent: 'center',
  },
  arrowIconContainer: {
    marginRight: Spacings.Small,
  },
  backArrowIconContainer: {
    marginLeft: Spacings.Medium,
    marginTop: Spacings.Medium,
  },
  moreIcon: {
    right: Spacings.Standard * 4,
    top: Spacings.Standard,
  },
  radioText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Black,
  },
  rootContainer: {
    backgroundColor: Colors.White,
    flex: 1,
    flexDirection: 'row',
  },
  tableOuterContainer: { flex: 1 },
  leftGraph: {
    marginRight: Spacings.Medium,
  },
  tabContainer: {
    width: '100%',
    flexWrap: 'wrap',
    marginRight: Spacings.Medium,
  },
  tableContainer: {
    marginLeft: Spacings.CardPadding,
    marginRight: Spacings.CardPadding,
    padding: 0,
    flex: 1,
  },
  topBarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 9999,
  },
  tableObjectIconContainer: {
    borderRadius: 30,
    height: 30,
    width: 30,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  orgUnitIconSize: {
    width: Spacings.Medium,
    height: Spacings.Medium,
  },
  workstationIconSize: {
    width: Spacings.Unit,
    height: Spacings.Unit,
  },
  shiftsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: Spacings.Small,
    maxWidth: 424,
  },
  minimumValueDropdown: {
    width: 110,
    left: 6,
    top: -15,
    paddingRight: Spacings.Small,
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
  },
  countStyle: {
    fontSize: fontSize.XLarge,
    ...TextFontStyles.LatoBlack,
    color: Colors.Black,
    paddingRight: Spacings.Unit,
  },
  toolTip: {
    top: Spacings.CardPadding * 2,
  },
  textCountStyle: {
    alignItems: 'flex-end',
  },
});

export default styles;
