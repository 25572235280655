import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { TouchableOpacity, StyleProp, TextStyle, ViewStyle, DimensionValue } from 'react-native';
import styles from './styles';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { capitalizeFirstLetter } from 'shared/localisation/i18n';
import { RouteLocations } from '../../navigation/Routes';
import * as _ from 'lodash-es';
import { Colors, Spacings } from 'shared/styles';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';

export const EllipsePadding = 52;
export const NodeCellHorizontalTranslation = 21;

interface NodeCellProps {
  node: API.TreeObject;
  index: number;
  isHighlighted?: boolean;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  setTreeNode: (treeNode?: API.TreeNode<API.TreeDataType>) => void;
}

const _NodeCell: React.FC<NodeCellProps> = props => {
  const { node, index, isHighlighted, setTreeNode } = props;

  const isWorkstation = API.isWorkstation(node);

  const history = useHistory();

  const debounceHandleTreeNode = useCallback(_.debounce(hoverOnWorkstation, 200), []);

  const {
    treeNode: [, _setTreeNode],
  } = useContext(MenuFactoryContext);

  function hoverOnWorkstation(isHover: boolean, workstationId: string) {
    if (isHover) {
      const _treeNode = API.Tree.getTreeNode(workstationId);
      if (API.isFailure(_treeNode)) {
        logger.warn(_treeNode);
        return;
      }

      setTreeNode(_treeNode);
    }
  }

  function handlePress(nodeId: string) {
    const _treeNode = API.Tree.getTreeNode(nodeId);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }
    _setTreeNode(_treeNode);
    const dataType = API.getDataType(node.id);
    if (dataType === API.DataType.WORKSTATION) {
      history.push(RouteLocations.Workstations(node.id));
    } else {
      history.push(RouteLocations.Workstations());
    }
  }

  return (
    <TouchableOpacity
      key={node.id}
      style={[
        styles.workstationContainer,
        {
          left: EllipsePadding * index - NodeCellHorizontalTranslation,
          top: 48,
          zIndex: 1000 - index,
        },
        props.style,
      ]}
      onPress={() => {
        handlePress(node.id);
      }}
    >
      <EllipsisWithTooltip
        text={capitalizeFirstLetter(node.name)}
        toolTipContainerStyle={{
          top: 60,
          left: 80,
          transform: [{ rotate: '45deg' }],
          width: 190,
        }}
        style={[
          styles.workstationTooltipContainer,
          !isWorkstation && {
            borderRadius: 10,
            backgroundColor: Colors.Background,
            paddingHorizontal: Spacings.Unit + Spacings.Unit / 2,
            paddingVertical: Spacings.Unit / 2,
            alignSelf: 'baseline',
            width: 'WrapText' as DimensionValue,
          },
        ]}
        mouseHoverText={styles.workstationHoverText}
        textStyle={[
          isWorkstation ? styles.workstationText : styles.orgUnitText,
          isWorkstation && isHighlighted && styles.workstationHoverText,
          props.textStyle,
        ]}
        mouseHoverTextContainer={styles.workstationHoverTextContainer}
        handleHover={isHover => {
          debounceHandleTreeNode(isHover, node.id);
        }}
      />
    </TouchableOpacity>
  );
};

const NodeCell = React.memo(_NodeCell, (prevProps, nextProps) => {
  const isEqual =
    _.isEqual(prevProps.node, nextProps.node) &&
    _.isEqual(prevProps.index, nextProps.index) &&
    _.isEqual(prevProps.isHighlighted, nextProps.isHighlighted);

  return isEqual;
});

export default NodeCell;
