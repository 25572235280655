import React, { useState, useEffect } from 'react';
import { View, Text, StyleProp, ViewStyle } from 'react-native';
import * as API from 'shared/backend-data';
import Styles from './Style';
import { ToggleSwitch } from 'shared/ui-component/Button/ToggleButton';
import { Checkbox } from '../../ui-component/Checkbox';
import { t } from 'shared/localisation/i18n';
import { observerRoleId } from 'shared/backend-data';
import { ToolTipWeb } from '../../ui-component/ToolTip/ToolTipWeb';
import { IconSVG } from '../../ui-component/Icon';
import { Colors } from '../../styles/Colors';
import * as _ from 'lodash-es';

interface Props {
  permissions: readonly API.Permission[];
  role?: API.Role | undefined;
  style?: StyleProp<ViewStyle>;
  disableAdminPermissions?: boolean;
  disableWorkerEditPermission?: boolean;
  onPermissionChange: (permissions: API.Permission[]) => void;
}

const InfoIcon = require('shared/assets/svg/icon.info.svg').default;

export const RolePermissionList: React.FC<Props> = props => {
  const {
    permissions,
    role,
    style,
    disableAdminPermissions,
    disableWorkerEditPermission,
    onPermissionChange,
  } = props;
  const Permissions = {
    [API.Permission.skills_edit]: t('alex:worker.permissions.0'),
    [API.Permission.trainingSessions_edit]: t('alex:worker.permissions.1'),
    [API.Permission.trainings_edit]: t('alex:worker.permissions.2'),
    [API.Permission.workersSkillProof_review]: t('alex:worker.permissions.3'),
    [API.Permission.workersSkillsProof_edit]: t('alex:worker.permissions.4'),
    [API.Permission.workers_edit]: t('alex:worker.permissions.5'),
    [API.Permission.workstations_edit]: t('alex:worker.permissions.6'),
    [API.Permission.workers_invite]: t('alex:worker.permissions.7'),
    [API.Permission.workersDetail_view]: t('alex:worker.permissions.8'),
  };
  const [checkBoxValues, setCheckBoxValues] = useState<readonly API.Permission[]>([]);

  useEffect(() => {
    setCheckBoxValues(permissions);
  }, [permissions]);

  const checkBoxChange = (state: boolean | null, permissions: API.Permission[]): void => {
    const _permissions = [...checkBoxValues];

    _.forEach(permissions, permission => {
      const index = _permissions.findIndex(value => value === permission);
      if (!_permissions.includes(API.Permission.workersSkillsProof_edit)) {
        _permissions.push(API.Permission.workersSkillsProof_edit);
      }

      if (state && index === -1) {
        
        if (API.Permission.workers_edit === permission) {
          _permissions.push(API.Permission.workersDetail_edit);
          if (!_permissions.includes(API.Permission.workersDetail_view))
            _permissions.push(API.Permission.workersDetail_view);
        }
        _permissions.push(permission);
      } else if (!state && index >= 0) {
        _permissions.splice(index, 1);
        if (API.Permission.workersDetail_view === permission) {
          let workerDetailEditIndex = _permissions.findIndex(
            value => value === API.Permission.workersDetail_edit,
          );
          if (workerDetailEditIndex > -1) _permissions.splice(workerDetailEditIndex, 1);

          let workerEditIndex = _permissions.findIndex(
            value => value === API.Permission.workers_edit,
          );
          if (workerEditIndex > -1) _permissions.splice(workerEditIndex, 1);
        } else if (API.Permission.workers_edit === permission) {
          let index = _permissions.findIndex(value => value === API.Permission.workersDetail_edit);
          _permissions.splice(index, 1);
        }
      }
    });

    setCheckBoxValues(_permissions);
    onPermissionChange(_permissions);
  };

  function toggleSecondSectionSwitch(value: boolean) {
    checkBoxChange(value, [
      API.Permission.trainingSessions_edit,
      API.Permission.workersSkillProof_review,
      API.Permission.workers_invite,
      API.Permission.workersDetail_view,
    ]);
  }

  function toggleFourthSectionSwitch(value: boolean) {
    checkBoxChange(value, [
      API.Permission.workstations_edit,
      API.Permission.skills_edit,
      API.Permission.trainings_edit,
      API.Permission.workers_edit,
    ]);
  }

  function secondSectionSwitchValue(): boolean {
    return (
      (checkBoxValues.includes(API.Permission.trainingSessions_edit) ||
        checkBoxValues.includes(API.Permission.workersSkillProof_review) ||
        checkBoxValues.includes(API.Permission.workersDetail_view) ||
        checkBoxValues.includes(API.Permission.workers_invite)) ??
      (!!permissions.includes(API.Permission.trainingSessions_edit) ||
        !!permissions.includes(API.Permission.workersSkillProof_review) ||
        !!permissions.includes(API.Permission.workersDetail_view) ||
        !!permissions.includes(API.Permission.workers_invite))
    );
  }

  function fourthSectionSwitchValue(): boolean {
    return (
      (checkBoxValues.includes(API.Permission.workstations_edit) ||
        checkBoxValues.includes(API.Permission.skills_edit) ||
        checkBoxValues.includes(API.Permission.trainings_edit) ||
        checkBoxValues.includes(API.Permission.workers_edit)) ??
      (!!permissions.includes(API.Permission.workstations_edit) ||
        !!permissions.includes(API.Permission.skills_edit) ||
        !!permissions.includes(API.Permission.trainings_edit) ||
        !!permissions.includes(API.Permission.workers_edit))
    );
  }

  if (role && role.id === observerRoleId) return null;

  return (
    <View style={[Styles.rootContainer, style]}>
      {}
      <View style={Styles.eachSectionContainer}>
        <View style={Styles.switchContainer}>
          <ToggleSwitch
            value={
              checkBoxValues.includes(API.Permission.workerIsOperational) ??
              !!permissions.includes(API.Permission.workerIsOperational)
            }
            onValueChange={(value: boolean) => {
              disableWorkerEditPermission
                ? () => {}
                : checkBoxChange(value, [API.Permission.workerIsOperational]);
            }}
          />
          <Text style={Styles.switchLabel}>{t('alex:worker.addEditWorker.checkBoxLabels.0')}</Text>
        </View>
      </View>

      {}
      <View style={Styles.eachSectionContainer}>
        <View style={Styles.switchContainer}>
          <ToggleSwitch
            value={secondSectionSwitchValue()}
            onValueChange={disableWorkerEditPermission ? () => {} : toggleSecondSectionSwitch}
          />
          <Text style={Styles.switchLabel}>{t('alex:worker.addEditWorker.checkBoxLabels.1')}</Text>
        </View>

        {secondSectionSwitchValue() ? (
          <View style={Styles.checkBoxContainer}>
            <View style={{ width: 290 }}>
              <Checkbox
                text={Permissions.trainingSessions_edit}
                style={Styles.firstCheckBox}
                onChange={(state: boolean | null) =>
                  checkBoxChange(state, [API.Permission.trainingSessions_edit])
                }
                initialState={
                  checkBoxValues.includes(API.Permission.trainingSessions_edit) ??
                  !!permissions.includes(API.Permission.trainingSessions_edit)
                }
                titleStyle={Styles.checkBoxText}
                disabled={disableWorkerEditPermission}
              />
              <Checkbox
                text={Permissions.workersSkillProof_review}
                style={Styles.checkBox}
                onChange={(state: boolean | null) =>
                  checkBoxChange(state, [API.Permission.workersSkillProof_review])
                }
                initialState={
                  checkBoxValues.includes(API.Permission.workersSkillProof_review) ??
                  !!permissions.includes(API.Permission.workersSkillProof_review)
                }
                titleStyle={Styles.checkBoxText}
                disabled={disableWorkerEditPermission}
              />
            </View>

            <View style={{ width: 290 }}>
              <Checkbox
                text={Permissions.workersDetail_view}
                style={Styles.firstCheckBox}
                onChange={(state: boolean | null) =>
                  checkBoxChange(state, [API.Permission.workersDetail_view])
                }
                initialState={
                  checkBoxValues.includes(API.Permission.workersDetail_view) ??
                  !!permissions.includes(API.Permission.workersDetail_view)
                }
                titleStyle={Styles.checkBoxText}
                disabled={disableWorkerEditPermission}
              />
              <Checkbox
                text={Permissions.workers_invite}
                style={Styles.checkBox}
                onChange={(state: boolean | null) =>
                  checkBoxChange(state, [API.Permission.workers_invite])
                }
                initialState={
                  checkBoxValues.includes(API.Permission.workers_invite) ??
                  !!permissions.includes(API.Permission.workers_invite)
                }
                titleStyle={Styles.checkBoxText}
                disabled={disableWorkerEditPermission}
              />
            </View>
          </View>
        ) : null}
      </View>

      {}
      <View style={[Styles.eachSectionContainer, { zIndex: -1 }]}>
        <View style={Styles.switchContainer}>
          <ToggleSwitch
            value={
              checkBoxValues.includes(API.Permission.workerIsManager) ??
              !!permissions.includes(API.Permission.workerIsManager)
            }
            onValueChange={(value: boolean) => {
              disableWorkerEditPermission
                ? () => {}
                : checkBoxChange(value, [API.Permission.workerIsManager]);
            }}
          />
          <Text style={Styles.switchLabel}>{t('alex:worker.addEditWorker.checkBoxLabels.2')}</Text>
          <View style={Styles.toolTip}>
            <ToolTipWeb
              component={<IconSVG svgComponent={InfoIcon} color={Colors.GreyLight} />}
              title={t('alex:worker.addEditWorker.workerManagerPermissionInfo')}
              style={Styles.tooltipContainer}
            />
          </View>
        </View>
      </View>

      {}
      <View style={[Styles.eachSectionContainer, { zIndex: -2 }]}>
        <View style={Styles.switchContainer}>
          <ToggleSwitch
            
            
            
            
            
            value={fourthSectionSwitchValue()}
            onValueChange={disableAdminPermissions ? () => {} : toggleFourthSectionSwitch}
          />
          <Text style={Styles.switchLabel}>{t('alex:worker.addEditWorker.checkBoxLabels.3')}</Text>
          {disableAdminPermissions && (
            <View style={[Styles.toolTip]}>
              <ToolTipWeb
                component={<IconSVG svgComponent={InfoIcon} color={Colors.GreyLight} />}
                title={t('alex:worker.addEditWorker.warnings.editAdminPermissions')}
                style={Styles.tooltipContainer}
              />
            </View>
          )}
        </View>
        {fourthSectionSwitchValue() ? (
          <View style={[Styles.checkBoxContainer, { zIndex: -3 }]}>
            <View style={{ width: 290 }}>
              <Checkbox
                disabled={disableAdminPermissions}
                text={Permissions.workstations_edit}
                style={Styles.firstCheckBox}
                onChange={(state: boolean | null) =>
                  checkBoxChange(state, [API.Permission.workstations_edit])
                }
                initialState={
                  checkBoxValues.includes(API.Permission.workstations_edit) ??
                  !!permissions.includes(API.Permission.workstations_edit)
                }
                titleStyle={Styles.checkBoxText}
              />
              <Checkbox
                disabled={disableAdminPermissions}
                text={Permissions.skills_edit}
                style={Styles.checkBox}
                onChange={(state: boolean | null) =>
                  checkBoxChange(state, [API.Permission.skills_edit])
                }
                initialState={
                  checkBoxValues.includes(API.Permission.skills_edit) ??
                  !!permissions.includes(API.Permission.skills_edit)
                }
                titleStyle={Styles.checkBoxText}
              />
            </View>
            <View style={{ width: 290 }}>
              <Checkbox
                disabled={disableAdminPermissions}
                text={Permissions.trainings_edit}
                style={Styles.firstCheckBox}
                onChange={(state: boolean | null) =>
                  checkBoxChange(state, [API.Permission.trainings_edit])
                }
                initialState={
                  checkBoxValues.includes(API.Permission.trainings_edit) ??
                  !!permissions.includes(API.Permission.trainings_edit)
                }
                titleStyle={Styles.checkBoxText}
              />
              <Checkbox
                disabled={disableAdminPermissions}
                text={Permissions.workers_edit}
                style={Styles.checkBox}
                onChange={(state: boolean | null) =>
                  checkBoxChange(state, [API.Permission.workers_edit])
                }
                initialState={
                  checkBoxValues.includes(API.Permission.workers_edit) ??
                  !!permissions.includes(API.Permission.workers_edit)
                }
                titleStyle={Styles.checkBoxText}
              />
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );
};
