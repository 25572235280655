import React, { useState, useEffect, useContext } from 'react';
import { BannerSkeleton } from '../bannerSkeleton/BannerSkeletonComponent';
import { View, TouchableOpacity, Text, InteractionManager } from 'react-native';
import { IconSVG } from 'shared/ui-component/Icon';
import styles from './styles';
import { Colors } from 'shared/styles';
import { capitalizeFirstLetter, t } from 'shared/localisation/i18n';
import * as _ from 'lodash-es';
import { GlobalDataContext } from 'shared/skillmgt/context/GlobalDataContext';
import * as API from 'shared/backend-data';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { useHistory } from 'react-router-dom';
import logger from 'shared/util/Logger';
import {
  getWorkersAverageExperience,
  getWorkersContractTypeNamewithCounts,
} from 'shared/util/Worker';
import { ContractTypeNameWithCount } from 'shared/util/Worker';
import { KPIData } from 'sharedweb/src/context/TreeTableContext';
import { WorkstationTargetCounts } from '../workstationBanner/WorkstationTargetCounts';
import { RouteLocations } from 'skillmgtweb/src/components/navigation/Routes';

const KPISVG = require('shared/assets/svg/icon.KPI.svg').default;
const MoreSVG = require('shared/assets/svg/icon.next.small.svg').default;

interface Props {
  kpiData: KPIData;
  isGraphLoaded: boolean;
}

export const KPIBanner: React.FC<Props> = props => {
  const { kpiData, isGraphLoaded } = props;

  const [companyName, setCompanyName] = useState<string>('');
  const [workersAverageExperience, setWorkersAverageExperience] = useState<number>(0);
  const [contractTypeNameWithCounts, setContractTypeNameWithCounts] = useState<
    ContractTypeNameWithCount[]
  >([]);
  const { workstationTargetsStartingAtLevel } = useContext(GlobalDataContext);
  const isMounted = useIsMounted();

  const history = useHistory();

  useEffect(() => {
    loadBanner();
  }, [kpiData, isGraphLoaded]);

  async function loadBanner() {
    if (!kpiData.workers) return;

    if (isGraphLoaded) {
      await setFactoryName();

      if (kpiData.workers) {
        setAverageExperience(kpiData.workers);
        await setWorkersContractTypeNamewithCounts(kpiData.workers);
      }
    }
  }

  async function setFactoryName() {
    const _tenant = await API.getTenant();
    if (!isMounted.current) return;
    if (API.isFailure(_tenant)) {
      logger.warn(_tenant);
      return;
    }

    setCompanyName(_tenant.name);
  }

  async function setWorkersContractTypeNamewithCounts(workers: API.Worker[]) {
    const _contractTypeNameWithCounts = await getWorkersContractTypeNamewithCounts(workers);
    if (!isMounted.current) return;
    if (API.isFailure(_contractTypeNameWithCounts)) {
      logger.warn(_contractTypeNameWithCounts);
      return;
    }

    setContractTypeNameWithCounts(_contractTypeNameWithCounts);
  }

  function setAverageExperience(workers: API.Worker[]) {
    setWorkersAverageExperience(getWorkersAverageExperience(workers));
  }

  return (
    <View>
      <BannerSkeleton
        headerIcon={
          <View style={styles.iconContainer}>
            <TouchableOpacity>
              <IconSVG
                svgComponent={KPISVG}
                size={{ height: 52, width: 52 }}
                color={Colors.White}
              />
            </TouchableOpacity>
          </View>
        }
        headerTitle={capitalizeFirstLetter(companyName)}
        subHeaderComponent={
          <TouchableOpacity
            style={styles.subHeaderComponent}
            onPress={() => history.push(RouteLocations.KPIOrgUnits())}
          >
            <Text>{t('common:button.seeMore')}</Text>
            <IconSVG
              svgComponent={MoreSVG}
              size={{ height: 22, width: 22 }}
              color={Colors.GreyLight}
            />
          </TouchableOpacity>
        }
        bannerContent={{
          component: (
            <View style={styles.contentContainer}>
              <WorkstationTargetCounts
                workstations={
                  kpiData && isGraphLoaded && kpiData.workstations ? kpiData.workstations : []
                }
                workstationTargetsStartingAtLevel={workstationTargetsStartingAtLevel}
                needSumOfTargetCounts
              />
              <View style={styles.workersContainer}>
                <Text style={styles.contentTitle}>{t('glossary:operationalWorker_plural')}</Text>

                <View style={styles.contentItems}>
                  <Text style={styles.operationalSubTitle}>{t('alex:menuFactory.total')}</Text>
                  <Text style={styles.count}>{kpiData.workers?.length}</Text>
                </View>

                {contractTypeNameWithCounts.map(contractTypeNameWithCount => {
                  return (
                    <View
                      key={contractTypeNameWithCount.contractTypeName}
                      style={styles.contentItems}
                    >
                      <Text style={styles.operationalSubTitle}>
                        {contractTypeNameWithCount.contractTypeName}
                      </Text>
                      <Text style={styles.operationalSubTitle}>
                        {contractTypeNameWithCount.workerCountPercentage + '%'}
                      </Text>
                    </View>
                  );
                })}

                <View style={styles.contentItems}>
                  <Text style={styles.operationalSubTitle}>
                    {t('alex:KPI.KPIBanner.averageExperience')}
                  </Text>
                  <Text style={styles.operationalSubTitle}>
                    {t('alex:KPI.KPIBanner.numberOfYears', { count: workersAverageExperience })}
                  </Text>
                </View>
              </View>
            </View>
          ),
        }}
        backIconHidden
      />
    </View>
  );
};
