import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoAuthErrorCodes } from 'shared/context/UserContext';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { MyHub } from 'shared/util/MyHub';
import { CognitoAuthError } from '../backend-data';
import { useModal } from '../ui-component/Modal/Modal';
import { CacheUpdateEventSource } from '../backend-data/DataLayer';
import { checkClientVersionAndSyncClientCache } from 'shared/backend-data/DataLayerUi';

const ONE_DAY_EXPIRY_TIME_AS_CONFIGURED_IN_COGNITO = 1000 * 60 * 60 * 24;
const REFRESH_TWO_MILLISECONDS_POST_EXPIRY = 2;
const REFRESH_RETRY_TWO_MINUTES_ON_NETWORK_ERROR = 2 * 60 * 1000;


export function useRefreshToken() {
  const [dynamicRefreshIntervalTime, setDynamicRefreshIntervalTime] = useState<number>(
    ONE_DAY_EXPIRY_TIME_AS_CONFIGURED_IN_COGNITO,
  );
  const isMounted = useIsMounted();
  const modal = useModal();

  useEffect(() => {
    refreshIfExpiredAndSetTimeForRefreshingTokenAccordingToExpiryTime();
  }, []);

  useEffect(() => {
    const removeSubscriptionClientListener = MyHub.listenAppContext(
      'SubscriptionClientReconnected',
      async payload => {
        refreshIfExpiredAndSetTimeForRefreshingTokenAccordingToExpiryTime();
        const result = await checkClientVersionAndSyncClientCache(
          CacheUpdateEventSource.SubscriptionClientReconnect,
          modal,
        );
        if (result === false) return;
      },
    );

    return () => {
      removeSubscriptionClientListener();
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refreshIfExpiredAndSetTimeForRefreshingTokenAccordingToExpiryTime();
    }, dynamicRefreshIntervalTime + REFRESH_TWO_MILLISECONDS_POST_EXPIRY);

    return () => clearInterval(interval);
  }, [dynamicRefreshIntervalTime]);

  async function refreshIfExpiredAndSetTimeForRefreshingTokenAccordingToExpiryTime() {
    const session = await Auth.currentSession()
      .then(session => {
        return session;
      })
      .catch((err: CognitoAuthError) => {
        if (err.code === CognitoAuthErrorCodes.NetworkError && isMounted.current)
          setDynamicRefreshIntervalTime(REFRESH_RETRY_TWO_MINUTES_ON_NETWORK_ERROR);
        return;
      });

    if (!session || !isMounted.current) return;

    const idTokenExpiryTimeInSeconds = session.getIdToken().getExpiration();
    const currentTimeInSeconds = Math.round(+new Date() / 1000);
    const numberOfMillisecondsTillExpiry =
      (idTokenExpiryTimeInSeconds - currentTimeInSeconds) * 1000;
    if (numberOfMillisecondsTillExpiry >= 0)
      setDynamicRefreshIntervalTime(numberOfMillisecondsTillExpiry);
    else setDynamicRefreshIntervalTime(REFRESH_RETRY_TWO_MINUTES_ON_NETWORK_ERROR);
  }
}
