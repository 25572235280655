import React from 'react';
import { View, Text, FlatList, TouchableHighlight, StyleSheet } from 'react-native';
import { Spacings, Colors, TextFontStyles, fontSize } from 'shared/styles';
import { useModal } from './Modal';
import { DropDownOption } from '../DropDown/DropDown';

type Props<T extends DropDownOption> = {
  options: T[];
  renderOption?: (value: T | undefined) => JSX.Element;
  handleClick: (value: T | undefined) => void;
  hasLevelOnly: boolean;
};

export const DropdownPicker = <T extends DropDownOption>(props: Props<T>) => {
  const { options, handleClick } = props;
  const modal = useModal();

  const handleListItem = (item: T) => {
    modal.hideModal();
    handleClick(item);
  };

  const renderListItem = (item: T) => (
    <TouchableHighlight
      underlayColor={Colors.Background}
      onPress={() => handleListItem(item)}
      style={styles.listItemContainer}
    >
      <>
        {!props.hasLevelOnly && <Text style={styles.listItemText}>{item.label}</Text>}
        {props.renderOption && props.renderOption(item)}
      </>
    </TouchableHighlight>
  );

  return (
    <View style={styles.modalContainer}>
      <View style={styles.modalContentContainer}>
        <FlatList
          bounces={false}
          data={options}
          renderItem={({ item }) => renderListItem(item)}
          keyExtractor={item => item.key}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContentContainer: {
    backgroundColor: Colors.White,
    width: '80%',
    paddingVertical: Spacings.xMedium,
    borderRadius: Spacings.Small,
    maxHeight: 400,
  },
  listItemText: {
    ...TextFontStyles.LatoMedium,
    fontSize: fontSize.Big,
    flexWrap: 'wrap',
    flex: 1,
  },
  alignItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: Spacings.xMedium,
    paddingHorizontal: Spacings.CardPadding,
  },
});
