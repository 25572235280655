import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
  },
  rootContainer: {
    flex: 1,
    backgroundColor: Colors.White,
    paddingTop: Spacings.Large + Spacings.Standard - Spacings.Unit, 
    paddingLeft: Spacings.Large, 
    paddingBottom: Spacings.Standard + Spacings.Unit, 
  },
  topContainer: {
    flexDirection: 'row',
  },
  IconContainer: {
    alignSelf: 'flex-end',
  },
  middleContainer: {
    marginBottom: Spacings.Large + Spacings.Standard + Spacings.Medium + Spacings.Unit, 
  },
  termsConditionContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  connectButton: {
    width: 144,
    height: 36,
    marginBottom: Spacings.Standard * 5,
    alignSelf: 'flex-end',
    backgroundColor: Colors.Yellow,
    justifyContent: 'center',
  },
  connectButtonDisabled: {
    width: 144,
    height: 36,
    marginBottom: Spacings.Standard * 5,
    alignSelf: 'flex-end',
    backgroundColor: Colors.GreyLight,
    justifyContent: 'center',
  },
  connectText: {
    ...TextFontStyles.LatoBold,
    fontSize: 16,
    color: Colors.White,
  },
  backButton: {
    marginLeft: -Spacings.Small - Spacings.Unit, 
    marginRight: Spacings.Small,
    alignSelf: 'flex-end',
  },
  headerText: {
    ...TextFontStyles.LatoBlack,
    fontSize: 26,
  },
  headerTextContainer: {
    width: '100%',
  },
  errorMessage: {
    ...TextFontStyles.LatoBold,
    color: Colors.Red,
    marginTop: 15,
    fontSize: 14,
  },
  forgetPasswordText: {
    ...TextFontStyles.LatoRegular,
    color: Colors.Grey,
    marginTop: Spacings.Unit,
    fontSize: 12,
  },
  inputText: {
    marginTop: Spacings.Small,
  },
  bodyText: {
    ...TextFontStyles.LatoRegular,
    fontSize: 14,
    width: '90%',
    marginTop: Spacings.Standard + Spacings.Unit,
    marginBottom: Spacings.Standard * 2 + Spacings.Medium - Spacings.Small,
  },
  boldBodyText: {},
  greyText: {
    color: Colors.Grey,
  },
  termsConditionText: {
    ...TextFontStyles.LatoMedium,
    fontSize: 12,
    alignSelf: 'flex-end',
  },
  passwordRulesContainer: {
    paddingTop: Spacings.CardPadding,
  },
  passwordRuleText: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
    color: Colors.Grey,
  },
});

export default styles;
