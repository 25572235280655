import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { WorkersLibrary } from './workers/WorkersLibrary';
import { Workstations, WorkstationsState } from './workstations/WorkstationsLibrary';
import { SkillsLibrary } from './skills/containers/SkillsLibrary';
import { HeaderTitleProvider } from 'shared/context/collaborateur-profile-context/CollaborateurProfileContext';
import { WorkerProfile, WorkerProfileState } from './workers/component/worker-profile';
import { AddEditSkill } from 'skillmgtweb/src/components/my-factory/skills/containers/SkillProfile';
import { ViewTraining } from 'skillmgtweb/src/components/training/training-view/container/ViewTraining';
import { MenuFactory } from './menuFactory/MenuFactory';
import { View } from 'react-native';
import { Spacings } from 'shared/styles';
import { KPIOrgUnits } from 'skillmgtweb/src/components/KPI-screen/components/KPIOrgUnits/container';
import { TreeTableDataProvider } from 'sharedweb/src/context/TreeTableContext';
import { TrainingsLibrary } from './trainings/TrainingsLibrary';

export const MyFactory: React.FC<RouteComponentProps> = props => {
  return (
    <HeaderTitleProvider>
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          marginHorizontal: Spacings.CardPadding,
          marginBottom: Spacings.Small,
        }}
      >
        <MenuFactory />
        <Switch>
          <Route
            path={RoutePaths.Workstations}
            exact
            render={props2 => {
              return (
                <Workstations {...(props2 as RouteComponentProps<{}, {}, WorkstationsState>)} />
              );
            }}
          />
          <Route
            path={RoutePaths.WorkstationProfileRoute}
            exact
            render={props2 => {
              return (
                <Workstations {...(props2 as RouteComponentProps<{}, {}, WorkstationsState>)} />
              );
            }}
          />
          <Route
            path={RoutePaths.Workers}
            exact
            render={props2 => {
              return <WorkersLibrary />;
            }}
          />
          <Route
            path={RoutePaths.WorkerProfileRoute}
            exact
            render={props2 => {
              return (
                <WorkerProfile {...(props2 as RouteComponentProps<{}, {}, WorkerProfileState>)} />
              );
            }}
          />
          <Route
            path={RoutePaths.Skills}
            exact
            render={props2 => {
              return <SkillsLibrary {...props2} />;
            }}
          />
          <Route
            path={RoutePaths.SkillProfileRoute}
            exact
            render={props2 => {
              return <AddEditSkill {...(props2 as RouteComponentProps<{}, {}>)} />;
            }}
          />
          <Route
            path={RoutePaths.TrainingProfileRoute}
            exact
            render={props2 => {
              return <ViewTraining {...(props2 as RouteComponentProps<{}, {}>)} />;
            }}
          />
          <Route
            path={RoutePaths.Trainings}
            exact
            render={props2 => {
              return <TrainingsLibrary />;
            }}
          />
          <Route
            path={RoutePaths.KPIOrgUnits}
            exact
            render={props2 => {
              return (
                <TreeTableDataProvider>
                  <KPIOrgUnits />
                </TreeTableDataProvider>
              );
            }}
          />
        </Switch>
      </View>
    </HeaderTitleProvider>
  );
};
