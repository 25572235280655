import React, { useEffect, useState } from 'react';
import { TrainingProofReviewModalComponent } from '../component';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import * as _ from 'lodash-es';
import { DropDownOption } from 'shared/ui-component/DropDown/DropDown';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { InteractionManager } from 'react-native';
import { AddIndividualOrCollectiveProofData } from 'shared/backend-data';
import { Tag } from 'shared/ui-component/Input/InputList/InputTag';
import Aigle from 'aigle';
import { capitalize, t } from 'shared/localisation/i18n';
import { S3ObjectWithLink, loadFiles } from 'shared/util/S3Upload';
import { Loader } from 'shared/ui-component/Loader/Loader';
import moment from 'moment';

export interface SkillDropDownOption extends DropDownOption {
  value: API.Skill;
}
export interface WorkerDropDownOption extends DropDownOption {
  value: API.Worker;
}
export interface AddTrainingProof {
  trainingSessionOrTrainingVersionId?: string;
  type?: AddIndividualOrCollectiveProofData;
  traineeIdToValidate?: string;
  proofBundleId?: string;
  addProofWorkerId?: string;
  proofBundles?: API.NoMetadata<API.ProofBundle>[];
}

/**
 * To use this component need to pass the training session ID.
 * by default the type will be add proof for multiple trainee
 * If we want to validate a proof for a single trainee need to pass traineeIdToValidate value
 */
interface Props {
  trainingProof: AddTrainingProof;
  preSelectedTraining?: API.Training;
  disableWorkerSelection?: boolean;

  handleModalClose: () => void;
  openProofBook?: (workerIds: string[], skillIds: string[], trainingVersionId?: string) => void;
}

export interface WorkerProofBundleDetails {
  startingDate?: Date;
  docs?: File[];
  previewFiles: S3ObjectWithLink[];
  isProofAttested?: boolean;
  comment?: string;
  worker: API.Worker;
  index: number;
  reviewState?: API.ReviewState;
  proofBundles?: API.ProofBundle[];
}


export const TrainingProofReviewModal: React.FC<Props> = props => {
  const { trainingProof, preSelectedTraining, disableWorkerSelection, openProofBook } = props;

  const [selectedWorkerTags, setSelectedWorkerTags] = useState<DropDownOption[]>([]);
  const [selectedTrainerTags, setSelectedTrainerTags] = useState<DropDownOption[]>();
  const [training, setTraining] = useState<DropDownOption>();
  const [trainingSkill, setTrainingSkill] = useState<API.Skill>();
  const [workerProofBundleDetails, setWorkerProofBundleDetails] =
    useState<Map<string, WorkerProofBundleDetails>>();
  const [skillObtentionDate, setSkillObtentionDate] = useState<Date>(new Date());
  const [comment, setComment] = useState<string>();
  const [attestAcquiredSkill, setAttestAcquiredSkill] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
  const [files, setFiles] = useState<S3ObjectWithLink[]>();
  const [reviewState, setReviewState] = useState<API.ReviewState>();
  const [workerName, setWorkerName] = useState<string>();
  const [showLoader, setShowLoader] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [proofBundleOption, setProofBundleOption] = useState<Tag>();
  const [proofBundleOptions, setProofBundleOptions] = useState<Tag[]>([]);
  const [proofBundleId, setProofBundleId] = useState<string>();
  const [trainingVersion, setTrainingVersions] = useState<API.TrainingVersion>();
  const [trainingSkillIds, setTrainingSkillIds] = useState<string[]>();
  const [isSkillAcquired, setIsSkillAcquired] = useState<number>(-1);

  const isMounted = useIsMounted();

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;
      await fetchLoggedInWorkerName();
      if (!isMounted.current) return;
    });
  }, []);

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current || !trainingProof.addProofWorkerId) return;
      const worker = await API.getWorker(trainingProof.addProofWorkerId);
      if (!isMounted.current) return;
      if (API.isFailure(worker)) {
        logger.warn(worker);
        return;
      }
      setSelectedWorkerTags([
        {
          key: worker.id,
          label: worker.name,
          value: worker,
        },
      ]);
    });
  }, [trainingProof.addProofWorkerId]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;
      fetchTrainingDetails(trainingProof?.trainingSessionOrTrainingVersionId);
    });
  }, [trainingProof.trainingSessionOrTrainingVersionId, preSelectedTraining]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (!isMounted.current) return;

      fetchTrainingDetails(proofBundleOption?.value.originObjectId);
    });
  }, [proofBundleOption, proofBundleId]);

  useEffect(() => {
    setProofBundleId(proofBundleId);
  }, [trainingProof.proofBundleId]);

  useEffect(() => {
    if (trainingProof.proofBundles && trainingProof.proofBundles.length) {
      const sortedProofs: Tag[] = _.orderBy(
        trainingProof.proofBundles.map(_proofBunbdle => {
          return {
            key: _proofBunbdle.id,
            label:
              t('glossary:proof') +
              ' ' +
              (_proofBunbdle.review.state === API.ReviewState.REJECTED ||
              _proofBunbdle.review.state === API.ReviewState.REJECTED_TO_RESUBMIT
                ? t('alex:workerSkillReviewModal.disproved', undefined, false)
                : _proofBunbdle.review.state === API.ReviewState.VALIDATED
                ? t('alex:workerSkillReviewModal.validated', undefined, false)
                : t('alex:workerSkillReviewModal.toBeValidated', undefined, false)) + 
              (_proofBunbdle.review.state === API.ReviewState.TO_REVIEW
                ? ''
                : t(
                    'alex:workerSkillReviewModal.proofsObtained',
                    {
                      date: _proofBunbdle.review.date
                        ? moment(_proofBunbdle.review.date).format('L')
                        : t('alex:workerSkillReviewModal.dateNotPrecised', undefined, false),
                    },
                    false,
                  )),
            value: _proofBunbdle,
          };
        }),
        item => item.value.review.date,
        ['desc'],
      );

      if (trainingProof.proofBundleId) {
        const _proof = sortedProofs.find(proof => proof.key === trainingProof.proofBundleId);
        setProofBundleOption(_proof);
        setProofBundleId(trainingProof.proofBundleId);
      }

      setProofBundleOptions([...sortedProofs]);
    }
  }, [trainingProof.proofBundles]);

  function getPreSelectedTrainingsDropDownOptions(
    preSelectedTrainingsArr: API.Training,
  ): DropDownOption {
    return {
      key: preSelectedTrainingsArr.id,
      label: preSelectedTrainingsArr.name,
      value: preSelectedTraining,
    };
  }

  async function fetchTrainingDetails(trainingSessionOrTrainingVersionId?: string) {
    if (!trainingSessionOrTrainingVersionId) {
      if (preSelectedTraining) {
        const _preSelectedTrainingsDDoptions =
          getPreSelectedTrainingsDropDownOptions(preSelectedTraining);
        setTraining(_preSelectedTrainingsDDoptions);

        const _latesTrainingVersion = await API.getTrainingVersionLatestForTraining(
          preSelectedTraining.id,
        );
        if (!isMounted.current) return;
        if (API.isFailure(_latesTrainingVersion)) {
          logger.warn(
            'Failed to fetch latest Training Version for a training ',
            _latesTrainingVersion,
          );
          return _latesTrainingVersion;
        }

        await getTrainingSkills(_latesTrainingVersion.id);
        if (!isMounted.current) return;
      }

      return;
    }

    let trainingVersionId = '';

    const dataType = API.getDataType(trainingSessionOrTrainingVersionId);
    if (API.isFailure(dataType)) {
      logger.error('dataType error', dataType);
      return;
    }

    switch (dataType) {
      case API.DataType.TRAININGSESSION:
        const _trainingSession = await API.getTrainingSession(trainingSessionOrTrainingVersionId);
        if (!isMounted.current) return;
        if (API.isFailure(_trainingSession)) {
          logger.warn(_trainingSession);
          return;
        }
        trainingVersionId = _trainingSession.trainingVersionId;
        await getSelectedTraineeDetails(_trainingSession);
        if (!isMounted.current) return;
        await getSelectedTrainerDetails(_trainingSession);
        break;

      case API.DataType.TRAININGVERSION:
        trainingVersionId = trainingSessionOrTrainingVersionId;
        if (trainingProof.traineeIdToValidate && proofBundleId)
          await getSelectedWorkerDetails(trainingProof.traineeIdToValidate, proofBundleId);
        break;

      default:
        return API.createFailure_Unspecified(
          'type of id is not supported Id=' + trainingSessionOrTrainingVersionId,
        );
    }

    
    const _training = await API.getTrainingForATrainingVersion(trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(_training)) {
      logger.warn(_training);
      return;
    }

    if (preSelectedTraining) {
      setTraining({
        key: preSelectedTraining.id,
        label: preSelectedTraining.name,
        value: preSelectedTraining,
      });
    } else {
      setTraining({
        key: _training.id,
        label: _training.name,
        value: _training,
      });
    }

    await getTrainingSkills(trainingVersionId);
    if (!isMounted.current) return;
  }

  async function getTrainingSkills(trainingVersionId: string) {
    const _trainingVerison = await API.getTrainingVersion(trainingVersionId);
    if (!isMounted.current) return;
    if (API.isFailure(_trainingVerison)) {
      logger.warn(_trainingVerison);
      return;
    }

    setTrainingVersions(_trainingVerison);

    
    
    if (_trainingVerison.skillIds.length > 1) {
      setTrainingSkill(undefined);
    } else if (_trainingVerison.skillIds.length === 1) {
      const skill = await API.getSkill(_trainingVerison.skillIds[0]);
      if (!isMounted.current) return;
      if (API.isFailure(skill)) {
        logger.warn(skill);
        return;
      }
      setTrainingSkill(skill);
    }

    setTrainingSkillIds(_trainingVerison.skillIds.map(skillId => skillId));
  }

  async function setTrainee(
    traineeId: string,
    workerProofBundleDetailsFrom?: Map<string, WorkerProofBundleDetails>,
  ) {
    const _trainee = await API.getWorker(traineeId);
    if (!isMounted.current) return;
    if (API.isFailure(_trainee)) {
      logger.warn('failed to fetch worker details', _trainee);
      return;
    }
    setSelectedWorkerTags([
      {
        key: _trainee.id,
        label: `${capitalize(_trainee.name)}`,
        value: _trainee,
      },
    ]);
    const _workerProofBundleDetails = workerProofBundleDetailsFrom?.get(traineeId);
    if (_workerProofBundleDetails?.startingDate)
      setSkillObtentionDate(_workerProofBundleDetails.startingDate);
    setComment(_workerProofBundleDetails?.comment);
    setAttestAcquiredSkill(!!_workerProofBundleDetails?.isProofAttested);
    setSelectedDocs(_workerProofBundleDetails?.docs ?? []);
    setFiles(_workerProofBundleDetails?.previewFiles);
    setReviewState(_workerProofBundleDetails?.reviewState);
  }

  async function getSelectedTrainerDetails(trainingSession: API.TrainingSession) {
    if (trainingSession.trainers) {
      const trainers = [];
      for (const _trainer of trainingSession.trainers) {
        const trainer = await API.getWorker(_trainer.trainerId);
        if (!isMounted.current) return;
        if (API.isFailure(trainer)) {
          logger.warn(trainer);
          return;
        }

        trainers.push({
          key: trainer.id,
          label: trainer.name,
          value: trainer,
        });
      }

      setSelectedTrainerTags(trainers);
    }
  }

  async function getSelectedTraineeDetails(trainingSession: API.TrainingSession) {
    const _workerProofBundleDetails = new Map<string, WorkerProofBundleDetails>();

    let files: S3ObjectWithLink[] = [];
    let comment: string | undefined;
    let startingDate: Date;
    let reviewState: API.ReviewState;
    let proofBundles: API.ProofBundle[] | undefined;

    await Aigle.map(trainingSession.traineeIds, async (traineeId, index) => {
      const _worker = await API.getWorker(traineeId);
      if (!isMounted.current) return;
      if (API.isFailure(_worker)) {
        logger.warn('failed to fetch worker details', _worker);
        return;
      }

      const traineeProofBundles = await API.getTraineeProofBundles(trainingSession.id, traineeId);
      if (!isMounted.current) return;
      if (API.isFailure(traineeProofBundles)) return traineeProofBundles;
      if (traineeProofBundles) {
        const firstProofBundle = traineeProofBundles[0];
        const _files = await loadFiles(firstProofBundle);
        if (!isMounted.current) return;
        if (API.isFailure(_files)) {
          logger.warn(_files);
          return;
        }
        files = _files;
        comment = firstProofBundle.description ?? undefined;
        startingDate = new Date(firstProofBundle.startingDate);
        reviewState = firstProofBundle.review.state;
        proofBundles = [firstProofBundle];
      }

      _workerProofBundleDetails.set(traineeId, {
        worker: _worker,
        previewFiles: files,
        index,
        comment,
        startingDate,
        reviewState,
        proofBundles,
      });
    });

    await setTrainee(
      trainingProof.traineeIdToValidate || trainingSession.traineeIds[0],
      _workerProofBundleDetails,
    );

    setWorkerProofBundleDetails(new Map(_workerProofBundleDetails));
  }

  async function getSelectedWorkerDetails(workerId: string, proofbundleId: string) {
    const _workerProofBundleDetails = new Map<string, WorkerProofBundleDetails>();
    let files: S3ObjectWithLink[] = [];
    let comment: string | undefined;
    let startingDate: Date;
    let reviewState: API.ReviewState;
    let proofBundles: API.ProofBundle[] | undefined;
    let index = 0;

    const _worker = await API.getWorker(workerId);
    if (!isMounted.current) return;
    if (API.isFailure(_worker)) {
      logger.warn('failed to fetch worker details', _worker);
      return;
    }

    const _proofBundle = await API.getProofBundle(proofbundleId);
    if (!isMounted.current) return;
    if (API.isFailure(_proofBundle)) {
      logger.warn(_proofBundle);
      return;
    }
    const _files = await loadFiles(_proofBundle);
    if (!isMounted.current) return;
    if (API.isFailure(_files)) {
      logger.warn(_files);
      return;
    }

    files = _files;
    comment = _proofBundle.description ?? undefined;
    startingDate = new Date(_proofBundle.startingDate);
    reviewState = _proofBundle.review.state;
    proofBundles = [_proofBundle];

    _workerProofBundleDetails.set(workerId, {
      worker: _worker,
      previewFiles: files,
      index,
      comment,
      startingDate,
      reviewState,
      proofBundles,
    });

    await setTrainee(workerId, _workerProofBundleDetails);

    setWorkerProofBundleDetails(new Map(_workerProofBundleDetails));
  }

  const fetchLoggedInWorkerName = async () => {
    const worker = await API.getWorker();
    if (!isMounted.current) return;
    if (API.isFailure(worker)) {
      logger.warn('Error while fetching loggedin user', worker);
      return;
    }
    setWorkerName(worker.name);
  };

  function handleSkillObtentionDate(date: Date) {
    const _workerProofBundleDetails = workerProofBundleDetails?.get(selectedWorkerTags[0].key);
    if (_workerProofBundleDetails) {
      setWorkerProofBundleDetails(
        prev =>
          prev &&
          new Map([
            ...prev,
            [
              _workerProofBundleDetails.worker.id,
              {
                ..._workerProofBundleDetails,
                startingDate: date,
              },
            ],
          ]),
      );
    }
    setSkillObtentionDate(date);
  }

  function handleComment(comment: string) {
    const _workerProofBundleDetails = workerProofBundleDetails?.get(selectedWorkerTags[0].key);
    if (_workerProofBundleDetails) {
      setWorkerProofBundleDetails(
        prev =>
          prev &&
          new Map([
            ...prev,
            [
              _workerProofBundleDetails.worker.id,
              {
                ..._workerProofBundleDetails,
                comment,
              },
            ],
          ]),
      );
    }
    setComment(comment);
  }

  function handleAssetAcquiredSkill(value: boolean) {
    const _workerProofBundleDetails = workerProofBundleDetails?.get(selectedWorkerTags[0].key);
    if (_workerProofBundleDetails) {
      setWorkerProofBundleDetails(
        prev =>
          prev &&
          new Map([
            ...prev,
            [
              _workerProofBundleDetails.worker.id,
              {
                ..._workerProofBundleDetails,
                isProofAttested: value,
              },
            ],
          ]),
      );
    }
    setAttestAcquiredSkill(value);
  }

  function handleSelectedDocs(files: File[]) {
    const _workerProofBundleDetails = workerProofBundleDetails?.get(selectedWorkerTags[0].key);
    if (_workerProofBundleDetails) {
      setWorkerProofBundleDetails(
        prev =>
          prev &&
          new Map([
            ...prev,
            [
              _workerProofBundleDetails.worker.id,
              {
                ..._workerProofBundleDetails,
                docs: files,
              },
            ],
          ]),
      );
    }
    setSelectedDocs(files);
  }

  async function saveProofBundle(proofBundles: API.ProofBundle[]) {
    const _workerProofBundleDetails = workerProofBundleDetails?.get(selectedWorkerTags[0].key);
    if (_workerProofBundleDetails) {
      const _files = await loadFiles(proofBundles[0]);
      if (!isMounted.current) return;
      if (API.isFailure(_files)) {
        logger.warn(_files);
        return;
      }
      setWorkerProofBundleDetails(
        prev =>
          prev &&
          new Map([
            ...prev,
            [
              _workerProofBundleDetails.worker.id,
              {
                ..._workerProofBundleDetails,
                proofBundles,
                previewFiles: _files,
                reviewState: API.ReviewState.TO_REVIEW,
              },
            ],
          ]),
      );
      setReviewState(API.ReviewState.TO_REVIEW);
      setFiles(_files);
    }
  }

  async function reviewProofBundle(reject: boolean): Promise<void> {
    if (!skillObtentionDate) return;

    setShowLoader(true);
    const _workerProofBundleDetails = workerProofBundleDetails?.get(selectedWorkerTags[0].key);

    if (_workerProofBundleDetails && _workerProofBundleDetails.proofBundles) {
      await Aigle.map(_workerProofBundleDetails.proofBundles, async proofBundle => {
        const result = await API.reviewProofBundle(
          proofBundle.id,
          reject ? API.ReviewState.REJECTED : API.ReviewState.VALIDATED,
          skillObtentionDate,
          comment,
          proofBundle.acquired,
        );
        if (!isMounted.current) return;
        if (API.isFailure(result)) {
          logger.error(result);
          setShowLoader(false);
          return;
        }

        setWorkerProofBundleDetails(
          prev =>
            prev &&
            new Map([
              ...prev,
              [
                _workerProofBundleDetails.worker.id,
                {
                  ..._workerProofBundleDetails,
                  reviewState: result.review.state,
                },
              ],
            ]),
        );
        setReviewState(result.review.state);
      });
    }
    setShowLoader(false);
    props.handleModalClose();
  }

  function handleSelectProof(tag: Tag[]) {
    if (tag[0] && tag[0].key) {
      setProofBundleOption(tag[0]);
      setProofBundleId(tag[0].key);
    }
  }

  function navigateToTrainingBook() {
    if (openProofBook && selectedWorkerTags && trainingSkillIds) {
      openProofBook(
        selectedWorkerTags.map(worker => worker.key),
        trainingSkillIds,
        trainingVersion?.id,
      );
      handleModalClose();
    }
  }

  const handleModalClose = (): void => {
    setModalVisible(false);

    props.handleModalClose();
  };

  return modalVisible ? (
    <>
      {showLoader && <Loader />}
      <TrainingProofReviewModalComponent
        workerName={workerName}
        trainingProof={trainingProof}
        selectedWorkerTags={selectedWorkerTags}
        training={training}
        trainingSkill={trainingSkill}
        workerProofBundleDetails={workerProofBundleDetails}
        skillObtentionDate={skillObtentionDate}
        comment={comment}
        attestAcquiredSkill={attestAcquiredSkill}
        selectedDocs={selectedDocs}
        files={files}
        reviewState={reviewState}
        trainingVersion={trainingVersion}
        selectedTrainerTags={selectedTrainerTags}
        setTrainee={setTrainee}
        handleSkillObtentionDate={handleSkillObtentionDate}
        handleComment={handleComment}
        handleAssetAcquiredSkill={handleAssetAcquiredSkill}
        handleSelectedDocs={handleSelectedDocs}
        saveProofBundle={saveProofBundle}
        reviewProofBundle={reviewProofBundle}
        setShowLoader={setShowLoader}
        handleModalClose={handleModalClose}
        setModalVisible={setModalVisible}
        setSelectedWorkerTags={setSelectedWorkerTags}
        setTrainings={setTraining}
        getTrainingSkills={getTrainingSkills}
        disableWorkerSelection={disableWorkerSelection}
        proofBundleOptions={proofBundleOptions}
        handleSelectProof={handleSelectProof}
        proofBundleOption={proofBundleOption}
        navigateToTrainingBook={navigateToTrainingBook}
        setIsSkillAcquired={setIsSkillAcquired}
        isSkillAcquired={isSkillAcquired}
      />
    </>
  ) : null;
};
