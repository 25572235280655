import React, { useContext, useEffect, useState } from 'react';
import {
  InteractionManager,
  TouchableOpacity,
  Text,
  View,
  StyleProp,
  ViewStyle,
} from 'react-native';
import * as API from 'shared/backend-data';
import { useCallOnHover } from 'shared/hooks/CallOnHover';
import styles from './styles';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { t } from 'shared/localisation/i18n';
import { LevelTargetAndMaintainInfoModal } from './LevelTargetAndMaintainInfoModal';
import { IconSVG } from 'shared/ui-component/Icon';
import { LoaderThreeDots } from 'shared/ui-component/Loader/LoaderThreeDots';
import { EllipsisWithTooltip } from 'shared/ui-component/EllipsisWithTooltip';
import { Colors, Spacings } from 'shared/styles';
import {
  ActivityIndicator,
  ActivityIndicatorSize,
} from 'shared/ui-component/Loader/ActivityIndicator';

const InfoSVG = require('shared/assets/svg/icon.down.verysmall.svg').default;

interface Props {
  workerWorkstation: API.WorkerWorkstation;
  workstationSkillsRequiredInLevels?: API.WorkstationSkillsRequiredInLevels;
  style?: StyleProp<ViewStyle>;
  maintainInfoModalCoordinates?: { x?: number; y?: number };
  showOnlyTargetLevel?: boolean;
  showIconWhenNoTarget?: boolean;

  handleMaintainSelect: (maintainLevel: API.WorkstationWorkerLevels) => void;
  setShowTrainingModal: (bool: boolean) => void;
  setTrainingLevel: (trainingLevel: API.WorkstationWorkerLevels | undefined) => void;
  handleUnassignWorkerAndStopTrainings: () => Promise<void>;
}

enum OptionStates {
  NO_TARGET,
  MAINTAIN,
  IN_TRAINING,
  TARGET,
}

export const LevelTargetAndMaintainOption: React.FC<Props> = props => {
  const {
    workerWorkstation,
    workstationSkillsRequiredInLevels,
    style,
    maintainInfoModalCoordinates,
    showOnlyTargetLevel,
    showIconWhenNoTarget,

    setTrainingLevel,
    handleMaintainSelect,
    setShowTrainingModal,
    handleUnassignWorkerAndStopTrainings,
  } = props;

  const [optionLabel, setOptionLabel] = useState<string>('');
  const [additionalOptionLabel, setAdditionalOptionLabel] = useState('');
  const [isEstimatedEndDateLoading, setIsEstimatedEndDateLoading] = useState(false);
  const [isShowTargetAndMaintainInfoModal, setIsShowTargetAndMaintainInfoModal] = useState(false);
  const [workerSkillsAndRequiredSkillsByLevel, setWorkerSkillsAndRequiredSkillsByLevel] = useState<
    Map<
      number,
      {
        numberOfRequiredSkills: number;
        numberOfValidSkills: number;
        numberOfRequiredSkillsOnSingleLevel: number;
      }
    >
  >();

  const isMounted = useIsMounted();

  const ref = useCallOnHover<TouchableOpacity>(
    showIconWhenNoTarget ? Colors.White : Colors.BlueRollover,
  );

  useEffect(() => {
    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      displayTargetInfo();
    });
  }, [workstationSkillsRequiredInLevels]);

  function handleClick() {
    setIsShowTargetAndMaintainInfoModal(prev => !prev);
  }

  async function _setOptionLabel(optionState: OptionStates | undefined, level: number) {
    if (optionState == undefined) return;

    if (showOnlyTargetLevel) {
      if (optionState !== OptionStates.NO_TARGET) {
        setOptionLabel(
          t('alex:workstationWorkerLevelMenu.levelTargetOnLevel', {
            level: t(`glossary:level_${level}`, undefined, false),
          }),
        );
      } else {
        setOptionLabel(t('alex:workstationWorkerLevelMenu.levelTargetOnLevel'));
      }
    } else {
      switch (optionState) {
        case OptionStates.TARGET:
          setOptionLabel(
            t('alex:workstationWorkerLevelMenu.levelTargetOnLevel', {
              level: t(`glossary:level_${level}`, undefined, false),
            }),
          );
          break;

        case OptionStates.NO_TARGET:
          setOptionLabel(t('alex:workstationWorkerLevelMenu.assignLevelTarget'));
          break;

        case OptionStates.IN_TRAINING:
          setOptionLabel(
            t(
              workerWorkstation.activeTrainingSessions.isEverySkillToRenewOrAcquireCovered
                ? 'alex:workstationWorkerLevelMenu.inTrainingOnLevel'
                : 'alex:workstationWorkerLevelMenu.inPartialTrainingOnLevel',
              {
                level: t(`glossary:level_${level}`, undefined, false),
              },
            ),
          );
          await dispalyAdditionalOptionLabel(workerWorkstation);
          break;

        case OptionStates.MAINTAIN:
          setOptionLabel(
            t('alex:workstationWorkerLevelMenu.maintainedOption', {
              level: t(`glossary:level_${level}`, undefined, false),
            }),
          );
          break;
      }
    }
  }

  async function dispalyAdditionalOptionLabel(workerWorkstation: API.WorkerWorkstation) {
    setIsEstimatedEndDateLoading(true);

    const estimatedEndDate = await API.getWorkerWorkstationActiveTrainingSessionsEstimatedEndDate(
      workerWorkstation,
    );
    if (estimatedEndDate) {
      setAdditionalOptionLabel(`(${estimatedEndDate})`);
    }

    setIsEstimatedEndDateLoading(false);
  }

  async function displayTargetInfo() {
    let _optionState = undefined;
    let _level = API.WorkstationWorkerLevels.LEVEL0;

    if (!API.isWorkerTargetingALevelOnWorkstation(workerWorkstation)) {
      _optionState = OptionStates.NO_TARGET;
    } else if (!!workerWorkstation.isTrainAuto) {
      _optionState = OptionStates.MAINTAIN;
      _level = API.api2workstationWorkerLevels(workerWorkstation.targetLevel!);
    } else if (API.isWorkerInTrainingOnWorkstation(workerWorkstation)) {
      _optionState = OptionStates.IN_TRAINING;
      _level = API.api2workstationWorkerLevels(workerWorkstation.targetLevel!);
    } else {
      _optionState = OptionStates.TARGET;
      _level = API.api2workstationWorkerLevels(workerWorkstation.targetLevel!);
    }

    await _setOptionLabel(_optionState, _level);

    if (workstationSkillsRequiredInLevels) {
      const maxLevel = API.WorkstationWorkerLevels.LEVEL4;
      const minLevel = API.WorkstationWorkerLevels.LEVEL1;

      let totalNumberOfRequiredSkills = 0;
      let totalNumberOfValidSkills = 0;
      const _workerSkillsAndRequiredSkillsByLevel = new Map();
      for (let level = minLevel; level <= maxLevel; level++) {
        let numberOfRequiredSkillsOnSingleLevel = 0;

        (workstationSkillsRequiredInLevels.skillsRequiredInLevels.get(level) ?? []).forEach(
          eachSkill => {
            if (eachSkill.workerSkill) {
              if (eachSkill.workerSkill.validity === API.Validity.OK_EXPIRE_SOON) {
                totalNumberOfValidSkills++;
              } else if (
                eachSkill.workerSkill.validity === API.Validity.KO_EXPIRED ||
                eachSkill.workerSkill.validity === API.Validity.KO_MISSING ||
                eachSkill.workerSkill.validity === API.Validity.KO_REJECTED ||
                eachSkill.workerSkill.validity === API.Validity.KO_NEW
              ) {
              } else {
                totalNumberOfValidSkills++;
              }
            }
            totalNumberOfRequiredSkills++;
            numberOfRequiredSkillsOnSingleLevel++;
          },
        );

        _workerSkillsAndRequiredSkillsByLevel.set(level, {
          numberOfRequiredSkills: totalNumberOfRequiredSkills,
          numberOfRequiredSkillsOnSingleLevel: numberOfRequiredSkillsOnSingleLevel,
          numberOfValidSkills: totalNumberOfValidSkills,
        });
      }
      setWorkerSkillsAndRequiredSkillsByLevel(_workerSkillsAndRequiredSkillsByLevel);
    }
  }

  return (
    <>
      <TouchableOpacity
        ref={ref}
        style={[styles.optionContainer, { zIndex: 1, flexDirection: 'column' }, style]}
        onPress={handleClick}
        disabled={!workstationSkillsRequiredInLevels}
      >
        {showIconWhenNoTarget ? (
          <View style={styles.setTargetCircleIcon}>
            <Text style={styles.setTargetCircleIconText}>
              {t('alex:workstationWorkerLevelMenu.assignLevelTarget')}
            </Text>
          </View>
        ) : (
          <View style={styles.targetLabelContainer}>
            <Text
              style={[styles.OptionText, !workstationSkillsRequiredInLevels && styles.disabledText]}
            >
              {optionLabel}
            </Text>
            <IconSVG
              svgComponent={InfoSVG}
              color={!workstationSkillsRequiredInLevels ? Colors.GreyLight : Colors.Black}
            />
            {!workstationSkillsRequiredInLevels && (
              <ActivityIndicator
                size={ActivityIndicatorSize.small}
                style={styles.activityIndicator}
              />
            )}
          </View>
        )}

        {isShowTargetAndMaintainInfoModal && (
          <LevelTargetAndMaintainInfoModal
            workerWorkstation={workerWorkstation}
            cordinates={{
              x: maintainInfoModalCoordinates?.x ?? Spacings.Standard * 2,
              y: maintainInfoModalCoordinates?.y ?? -130,
            }}
            workerSkillsAndRequiredSkillsByLevel={workerSkillsAndRequiredSkillsByLevel}
            handleUnassignWorkerAndStopTrainings={handleUnassignWorkerAndStopTrainings}
            setTrainingLevel={setTrainingLevel}
            setShowTrainingModal={setShowTrainingModal}
            handleMaintainSelect={handleMaintainSelect}
            closeMenu={() => setIsShowTargetAndMaintainInfoModal(false)}
          />
        )}
        <View
          style={[
            styles.optionContainer,
            { zIndex: 1 },
            {
              marginTop: -Spacings.Unit,
              width: '100%',
              paddingLeft: 0,
              minHeight: !additionalOptionLabel ? undefined : Spacings.Large,
            },
          ]}
        >
          {isEstimatedEndDateLoading ? (
            <LoaderThreeDots lowPerformance={false} />
          ) : (
            <EllipsisWithTooltip style={styles.OptionText} text={additionalOptionLabel} />
          )}
        </View>
      </TouchableOpacity>
    </>
  );
};
