import React, { useState, useEffect, useContext } from 'react';
import { View, StyleProp, ViewStyle, Text, TouchableOpacity, ScrollView } from 'react-native';
import { WorkStationWorkerLevelComponent } from '../component';
import * as API from 'shared/backend-data';
import { logger } from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import styles from './Styles';
import { IconSVG } from 'shared/ui-component/Icon';
import { Colors } from 'shared/styles/Colors';
import { MyHub } from 'shared/util/MyHub';
import { Loader } from 'shared/ui-component/Loader/Loader';
import { RouteLocations } from 'skillmgtweb/src/components/navigation/Routes';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { ModalContext, ModalUtils } from 'shared/ui-component/Modal';
import {
  showTrainingSessionErrorWarning,
  TrainingSessionWarning,
} from 'shared/util/TrainingSessionWarningModal';
import { t } from 'shared/localisation/i18n';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import * as _ from 'lodash-es';
import { WorkstationWorkerMenuObject } from 'skillmgtweb/src/components/dashboard/versatility-panel/VersatilityPanel';
import { MenuFactoryContext } from '../../../context/MenuFactoryContext';
import { TouchableOpacityPreventDoubleClick } from '../../../ui-component/TouchableOpacityPreventDoubleClick';
import { useCallOnHover } from '../../../hooks/CallOnHover';
import { PermissionManagementContext } from '../../../context/PermissionManagementContext';
import { GlobalDataContext } from '../../../skillmgt/context/GlobalDataContext';

/**
 * This Component is built to render the workstation Worker Level Icon
 */
export interface Props {
  worker: API.Worker;
  workstationId: string;
  isComputationDisabled?: boolean;
  selectedElement?: Element;
  routeFrom?: RoutePaths;
  openMenu: boolean;
  workerWorkstation: API.WorkerWorkstation | undefined;
  menuObject: WorkstationWorkerMenuObject | undefined;
  style?: StyleProp<ViewStyle>;

  setSelectedElement: (element?: Element) => void;
  setOpenMenu: (item: boolean) => void;
  setMenuObject: (item: WorkstationWorkerMenuObject) => void;
  handleLevelUpdate?: (level: API.WorkerWorkstation) => void;
  onMount?: () => void;
  setTreeNode?: (treeNode?: API.TreeNode<API.TreeDataType>) => void;
}

const TrainingIcon = require('shared/assets/svg/icon.lecture.svg').default;

interface TrainingSessionsListingProps {
  trainingSessions: API.TrainingSessionWithDetail[];
}

const TrainingSessionsListing: React.FC<TrainingSessionsListingProps> = props => {
  const { trainingSessions } = props;

  const history = useHistory();

  return (
    <View style={styles.trainingSessionsListingContainer}>
      <View>
        <Text style={styles.warningText}>
          {t('alex:workstationWorkerLevelMenu.unmaintainWorkerLevelWarning')}
        </Text>
      </View>
      <ScrollView contentContainerStyle={styles.trainingSessionsList}>
        {trainingSessions.map(trainingSession => {
          const ref = useCallOnHover<TouchableOpacity>();

          return (
            <TouchableOpacityPreventDoubleClick
              ref={ref}
              key={trainingSession.id}
              style={styles.trainingSessionRow}
              onPress={() => {
                history.push(RouteLocations.TrainingSessions());
              }}
            >
              <IconSVG
                svgComponent={TrainingIcon}
                size={{ height: 24, width: 24 }}
                containerStyle={styles.iconContainer}
              />
              <Text style={styles.trainingSessionName}>{trainingSession.training.name}</Text>
            </TouchableOpacityPreventDoubleClick>
          );
        })}
      </ScrollView>
    </View>
  );
};

export const _WorkstationWorkerLevelContainer: React.FC<Props> = props => {
  const {
    worker,
    selectedElement,
    routeFrom,
    openMenu,
    workstationId,

    setSelectedElement,
    setOpenMenu,
    setMenuObject,
    handleLevelUpdate,
    onMount,
    setTreeNode,
  } = props;

  const modal = ModalUtils.useModal();

  const history = useHistory();

  const isMounted = useIsMounted();
  const {
    treeNode: [, _setTreeNode],
  } = useContext(MenuFactoryContext);

  const [parentOrgUnit, setParentOrgUnit] = useState<API.OrganizationalUnit>();
  const [workerWorkstation, setWorkerWorkstation] = useState<API.WorkerWorkstation>();
  const [noRequirementForWorkstation, setNoRequirementForWorkstation] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { isValidPermission } = useContext(PermissionManagementContext);
  const { levelIconType } = useContext(GlobalDataContext);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.WORKERWORKSTATION) {
        if (data.tooManyMutations) {
          const _workerWorkstation = API.getWorkerWorkstations(workstationId, worker.id);
          if (workerWorkstation && _workerWorkstation) {
            setWorkerWorkstation({ ..._workerWorkstation });
          }
        } else {
          if (
            !API.isMutationDelete(data.mutationType) &&
            data.factory.workerWorkstation.workerId === worker.id &&
            data.factory.workerWorkstation.workstationId === workstationId
          ) {
            setWorkerWorkstation({
              ...data.factory.workerWorkstation,
              updatedAt: data.factory.updatedAt,
              updatedBy: data.factory.updatedBy,
            });
          }
        }
      }
    });

    if (onMount) onMount();

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    if (routeFrom === RoutePaths.Home) {
      fetchRequirements();
    }
  }, [routeFrom, workstationId]);

  useEffect(() => {
    handleWorkstationWorkerLevel();
  }, [props.workerWorkstation, props.isComputationDisabled]);

  async function _handleUnassignWorkerAndStopTrainings(): Promise<void> {
    if (!workerWorkstation) return;

    setLoader(true);

    const _isValidPermission = isValidPermission(
      API.Permission.trainingSessions_edit,
      parentOrgUnit,
      [worker.id],
    );

    await handleUnassignWorkerAndStopTrainings(workerWorkstation, _isValidPermission, modal);
    setLoader(false);
  }

  async function handleMaintainSelect(
    isMaintained: boolean,
    maintainLevel: API.WorkstationWorkerLevels,
  ): Promise<API.Result<void>> {
    if (!workerWorkstation) return;

    setLoader(true);

    const maintain = await handleMaintain(
      workerWorkstation,
      isMaintained,
      maintainLevel,
      modal,
      onWorkstationPress,
    );
    if (!isMounted.current) return;
    if (API.isFailure(maintain)) {
      logger.warn(maintain);
      setLoader(false);
      return;
    }
    setLoader(false);
  }

  function onWorkstationPress() {
    const _treeNode = API.Tree.getTreeNode(workstationId);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }

    _setTreeNode(_treeNode);
    fixTrainingNotSet(workstationId, history);
  }

  async function handleSilenceWarning(): Promise<void> {
    if (!workerWorkstation) return;

    const result = await API.silenceWorkerWorkstationWarning(workerWorkstation);
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      logger.warn('error with removeWorkerLevelWarning', result);
      setLoader(false);
      return;
    }

    setLoader(false);
  }

  async function handleWorkstationWorkerLevel() {
    if (props.isComputationDisabled && !props.workerWorkstation) {
      setWorkerWorkstation(undefined);
      return;
    }

    if (!props.workerWorkstation) return;

    setWorkerWorkstation(props.workerWorkstation);

    if (handleLevelUpdate) handleLevelUpdate(props.workerWorkstation);
  }

  async function handleClick() {
    const orgUnit = API.Tree.getParent(props.workstationId);
    if (API.isFailure(orgUnit)) {
      logger.warn(orgUnit);
      return;
    }

    if (orgUnit) setParentOrgUnit(orgUnit);
  }

  async function fetchRequirements() {
    const requirements = await API.getLevelsRequirementsWithInheritedAndOrDescendent(
      workstationId,
      true,
      false,
    );
    if (!isMounted.current) return;
    if (API.isFailure(requirements)) {
      logger.warn(requirements);
      return;
    }

    if (requirements.size === 0) {
      setNoRequirementForWorkstation(true);
    } else {
      
      let hasRequirementOnLevel = false;
      Array.from(requirements).map(([, requirement]) => {
        if (hasRequirementOnLevel) return;

        for (const value of requirement) {
          if (value.skillTrainingVersions.length) {
            hasRequirementOnLevel = true;
            return;
          }
        }
      });
      setNoRequirementForWorkstation(!hasRequirementOnLevel);
    }
  }

  async function handleTreeNode(workstationId?: string) {
    if (!workstationId) {
      if (setTreeNode) setTreeNode(undefined);
      return;
    }

    const _treeNode = API.Tree.getTreeNode(props.workstationId);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }
    if (setTreeNode) setTreeNode(_treeNode);
  }

  return (
    <>
      <View style={[styles.container, props.style]}>
        {props.isComputationDisabled ? (
          <IconSVG
            svgComponent={require('shared/assets/svg/icon.notAssign.svg').default}
            color={Colors.GreyLight}
            size={{ width: 28, height: 28 }}
            containerStyle={styles.notAssignIcon}
          />
        ) : workerWorkstation ? (
          <WorkStationWorkerLevelComponent
            workerWorkstation={workerWorkstation}
            worker={worker}
            selectedElement={selectedElement}
            parentOrgUnit={parentOrgUnit}
            routeFrom={routeFrom}
            noRequirementForWorkstation={noRequirementForWorkstation}
            openMenu={openMenu}
            menuObject={props.menuObject}
            setSelectedElement={setSelectedElement}
            setOpenMenu={setOpenMenu}
            setMenuObject={setMenuObject}
            handleMaintainSelect={handleMaintainSelect}
            handleUnassignWorkerAndStopTrainings={_handleUnassignWorkerAndStopTrainings}
            handleSilenceWarning={handleSilenceWarning}
            handleClick={handleClick}
            handleTreeNode={handleTreeNode}
            setTreeNode={setTreeNode}
            levelIconType={levelIconType}
          />
        ) : (
          <View style={{ width: 35, height: 27 }}>
            <IconSVG
              svgComponent={require('shared/assets/svg/icon.dashboard.svg').default}
              color={Colors.GreyLight}
              size={{ width: 35, height: 27 }}
            />
          </View>
        )}
      </View>
      {loader && <Loader />}
    </>
  );
};

/**
 * This function navigate to the workstation page, where we need to add training for a skill
 * @param workstationId workstation id
 * @param history history to navigate
 */

export function fixTrainingNotSet(workstationId: string, history: History) {
  history.push(RouteLocations.Workstations(workstationId, 2, 2));
}

export function fixNoSkillSetForWorkstation(workstationId: string, history: History) {
  history.push(RouteLocations.Workstations(workstationId, 1, 1));
}

export const WorkstationWorkerLevelContainer = React.memo(
  _WorkstationWorkerLevelContainer,
  (prevProps, nextProps) => {
    const isEqual: boolean = 
      _.isEqual(prevProps.worker, nextProps.worker) &&
      _.isEqual(prevProps.workstationId, nextProps.workstationId) &&
      _.isEqual(prevProps.workerWorkstation, nextProps.workerWorkstation) &&
      _.isEqual(prevProps.isComputationDisabled, nextProps.isComputationDisabled) &&
      _.isEqual(prevProps.selectedElement, nextProps.selectedElement);
    return isEqual;
  },
);

/**
 * Function to handle stop trainings and remove worker from training sessions
 * @param workerWorkstation
 * @param isValidPermission boolean
 * @param modal modal context
 */
export async function handleUnassignWorkerAndStopTrainings(
  workerWorkstation: API.WorkerWorkstation,
  isValidPermission: boolean,
  modal: ModalContext,
) {
  if (isValidPermission) {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:workstationWorkerLevelMenu.stopTrainingPopup'),
        warningCancelButton: t('common:button.no'),
        warningAcceptButton: t('common:button.yes'),
        warningAcceptCallback: async () => {
          await removeTargetWorkerAndStopTrainingsUI(workerWorkstation, true);
        },
        warningCancelCallback: async () => {
          await removeTargetWorkerAndStopTrainingsUI(workerWorkstation, false);
        },
      }),
    );
  } else {
    await removeTargetWorkerAndStopTrainingsUI(workerWorkstation, false);
  }

  async function removeTargetWorkerAndStopTrainingsUI(
    workerWorkstation: API.WorkerWorkstation,
    stopTrainings: boolean,
  ): Promise<void> {
    modal.displayLoader(ModalUtils.LoaderConfig({}));

    const result = await API.removeTargetWorkerAndStopTrainings(workerWorkstation, stopTrainings);
    modal.hideLoader();
    if (API.isFailure(result)) {
      ModalUtils.showWarningFailure(modal, result);
    } else {
      if (stopTrainings) {
        modal.displayModal(
          ModalUtils.toastConfig({
            text: t('alex:workstationWorkerLevelMenu.trainingStopedMessage'),
          }),
        );
      }
    }
  }
}

/**
 * Function to maintain a worker on a level
 * @param workerWorkstation
 * @param isMaintained
 * @param maintainLevel
 * @param modal
 * @param onWorkstationPressCallBack this callback function is used when there is no training version set on a training
 * and we can navigate to workstation screen to add training version
 * @returns Promise
 */
export async function handleMaintain(
  workerWorkstation: API.WorkerWorkstation,
  isMaintained: boolean,
  maintainLevel: API.WorkstationWorkerLevels,
  modal: ModalContext,
  onWorkstationPressCallBack: Function,
): Promise<API.Result<void>> {
  if (!workerWorkstation) return;

  if (isMaintained) {
    const trainingSessions = await API.getTrainingSessionsForWorker(
      workerWorkstation.workerId,
      undefined,
      undefined,
      workerWorkstation.workstationId,
      true,
      true,
    );
    if (API.isFailure(trainingSessions)) {
      logger.error(
        'Failed to fetch training sessions for a worker on the workstation',
        trainingSessions,
      );
      return trainingSessions;
    }

    const trainingSessionsWithDetails = await API.getTrainingSessionsWithDetail(trainingSessions);
    if (API.isFailure(trainingSessionsWithDetails)) {
      logger.error(
        'Failed to fetch training sessions with details for a worker on the workstation',
        trainingSessionsWithDetails,
      );
      return trainingSessionsWithDetails;
    }

    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: <TrainingSessionsListing trainingSessions={trainingSessionsWithDetails} />,
        warningAcceptCallback: () =>
          _handleMaintainSelect(
            workerWorkstation,
            isMaintained,
            maintainLevel,
            true,
            modal,
            onWorkstationPressCallBack,
          ),
        warningCancelCallback: () =>
          _handleMaintainSelect(
            workerWorkstation,
            isMaintained,
            maintainLevel,
            false,
            modal,
            onWorkstationPressCallBack,
          ),
        warningAcceptButton: t('common:button.yes'),
        warningCancelButton: t('common:button.no'),
      }),
    );
  } else {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:workstationWorkerLevelMenu.maintainWorkerLevelWarning'),
        warningAcceptCallback: () =>
          _handleMaintainSelect(
            workerWorkstation,
            isMaintained,
            maintainLevel,
            true,
            modal,
            onWorkstationPressCallBack,
          ),
        warningAcceptButton: t('common:button.gotIt'),
      }),
    );
  }
}

async function _handleMaintainSelect(
  workerWorkstation: API.WorkerWorkstation,
  isMaintained: boolean,
  maintainLevel: API.WorkstationWorkerLevels,
  isDeleteTrainingSessionRequests: boolean = true,
  modal: ModalContext,
  onWorkstationPressCallBack: Function,
): Promise<API.Result<void>> {
  if (!workerWorkstation) return;

  if (isMaintained) {
    const result = await API.unmaintainWorkerLevel(
      workerWorkstation,
      isDeleteTrainingSessionRequests,
    );
    if (API.isFailure(result)) {
      logger.warn('stopTrainingWorkerToLevel', result);
      return;
    }
  } else {
    const result = await API.maintainWorkerLevel(workerWorkstation, maintainLevel);
    if (API.isFailure(result)) {
      if (API.isFailureType(result, 'TrainingVersionNotSet')) {
        showTrainingSessionErrorWarning(
          modal,
          TrainingSessionWarning.TrainingVersionNotSet,
          () => onWorkstationPressCallBack(),
          undefined,
          t('common:button.add'),
        );
      } else {
        logger.warn('removeWorkerLevelWarning:error-->', result);
      }
      return;
    }
  }
}
