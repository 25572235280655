import React, { useState, useEffect, useContext } from 'react';
import { InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import * as _ from 'lodash-es';
import { TableRow } from 'shared/ui-component/Table';
import { TrainingsTable } from './component/TrainingsTable';
import { View } from 'react-native';
import Styles from './component/styles';
import { MyHub } from 'shared/util/MyHub';
import { getExportDataForTrainings } from 'shared/util/ExcelUtils';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { TagExtended } from 'sharedweb/src/Filter/container';
import {
  extractFilterTags,
  removeInvalidObjectFromUserPreference,
} from '../../header-layout/headerFilterConfig';
import { UserPreferenceKeys_SkillMgtApp } from 'shared/skillmgt/SkillmgtConstants';
import { MyFactoryContext } from '../MyFactoryContext';
import Aigle from 'aigle';
import { ModalUtils, useModal } from 'shared/ui-component/Modal';

export interface TrainingRow extends TableRow {
  training: API.Training;
  skills?: API.Skill[];
  workstationsOrOrgUnits?: API.TreeObject[];
  isPractical?: boolean;
  workers?: API.Worker[];
  trainingTags?: API.TrainingTag[];
}

export async function fetchRowDetails(
  row: TrainingRow,
  isRowMounted: React.MutableRefObject<boolean>,
): Promise<boolean> {
  let updated = false;

  if (row.workstationsOrOrgUnits === undefined) {
    const trainingNodes = await API.getTrainingWorkstationOrOrgUnit(row.training.id);
    if (!isRowMounted.current) return updated;

    if (API.isFailure(trainingNodes)) {
      logger.warn(
        'Failed to fetch the training workstation and org unit in training table',
        trainingNodes,
      );
    } else {
      row.workstationsOrOrgUnits = trainingNodes;
      updated = true;
    }
  }

  if (row.skills === undefined) {
    const latestTrainingVersion = await API.getTrainingVersionLatestForTraining(row.training.id);
    if (!isRowMounted.current) return updated;

    if (API.isFailure(latestTrainingVersion)) {
      logger.warn(latestTrainingVersion);
      return updated;
    }

    const skills: API.Skill[] = [];
    await Aigle.map(latestTrainingVersion.skillIds, async skillId => {
      const skill = await API.getSkill(skillId);
      if (!isRowMounted.current) return updated;
      if (API.isFailure(skill)) {
        logger.warn('Failed to fetch the skill inside training table', skill);
      } else {
        skills.push(skill);
      }
    });
    row.skills = skills;

    updated = true;
  }

  if (row.isPractical === undefined) {
    const trainingPractical = await API.isPracticalTraining(row.training.id);
    if (!isRowMounted.current) return updated;
    if (API.isFailure(trainingPractical)) {
      logger.warn(
        'Failed to fetch the training practical or not in training table',
        trainingPractical,
      );
    } else {
      row.isPractical = trainingPractical;
      updated = true;
    }
  }

  if (row.trainingTags === undefined) {
    const trainingTags = _.compact(
      await Promise.all(
        _.map(row.training.tagIds, async tagId => {
          const _trainingTag = await API.getTrainingTag(tagId);
          if (!isRowMounted.current) return;
          if (API.isFailure(_trainingTag)) {
            logger.warn('Failed to fetch training tag', _trainingTag);
          } else {
            updated = true;
            return _trainingTag;
          }
        }),
      ),
    );

    row.trainingTags = trainingTags;
  }

  return updated;
}

export const TrainingsLibrary: React.FC = props => {
  const [rows, setRows] = useState<TrainingRow[]>([]);
  const [filterTags, setFilterTags] = useState<TagExtended[]>([]);
  const isMounted = useIsMounted();
  const {
    trainingScreenFilterTags: [trainingScreenFilterTags, setTrainingScreenFilterTags],
    currentRoute: [, setCurrentRoute],
  } = useContext(HeaderFilterContext);
  const { trainingTableData } = useContext(MyFactoryContext);
  const modal = useModal();

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (data.factory.dataType === API.DataType.TRAINING) {
        fetchRows(true);
      }
    });

    InteractionManager.runAfterInteractions(async () => {
      if (!isMounted.current) return;

      await fetchRows();
      if (!isMounted.current) return;

      setCurrentRoute(RoutePaths.Trainings);
      const skillResult = await API.getUserPreference<Map<string, TagExtended[]>>(
        UserPreferenceKeys_SkillMgtApp.TrainingFilter,
      );
      if (!isMounted.current) return;
      if (API.isFailure(skillResult)) {
        logger.warn('fetch training filter: error in saving user Preference', skillResult);
        return;
      }
      if (skillResult) {
        const userPreferenceData = await removeInvalidObjectFromUserPreference(
          UserPreferenceKeys_SkillMgtApp.TrainingFilter,
          skillResult ?? [],
        );
        if (!isMounted.current) return;
        const saved = await API.saveUserPreference(
          UserPreferenceKeys_SkillMgtApp.TrainingFilter,
          userPreferenceData,
        );
        if (!isMounted.current) return;
        if (API.isFailure(saved)) {
          logger.warn('save training filter: error while saving user Preference', saved);
          return;
        }
        setTrainingScreenFilterTags(
          userPreferenceData.get(UserPreferenceKeys_SkillMgtApp.TrainingFilter) ?? [],
        );
      }
    });

    return () => {
      setCurrentRoute(undefined);
      removeListener();
    };
  }, []);

  useEffect(() => {
    setFilterTags(extractFilterTags(trainingScreenFilterTags));
  }, [trainingScreenFilterTags]);

  /**
   * Fetch more TrainingItems.
   * Returns true if there are more skillItems to fetch.
   * @param reset
   */
  async function fetchRows(forceReload: boolean = false): Promise<void> {
    if (!forceReload && trainingTableData.rows.length) {
      setRows(trainingTableData.rows);
      return;
    }

    const trainings = await API.getTrainings();
    if (!isMounted.current) return;
    if (API.isFailure(trainings)) {
      logger.warn(trainings);
      return;
    }
    const newTrainingItems: TrainingRow[] = _.map(trainings.result, training => {
      return {
        key: training.id,
        training,
        isPractice: undefined,
        workstations: undefined,
        workers: undefined,
        skills: undefined,
        trainingTags: undefined,
      };
    });
    setRows(newTrainingItems);
  }

  return (
    <View style={Styles.tableOuterContainer}>
      <TrainingsTable
        rows={rows}
        fetchRows={fetchRows}
        getExportData={filteredRows =>
          getExportDataForTrainings(filteredRows.map(item => item.training))
        }
        filterTags={filterTags}
      />
    </View>
  );
};
