import React, { useState, useEffect, useContext, useCallback } from 'react';
import * as API from 'shared/backend-data';
import logger from 'shared/util/Logger';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { DashboardFilterContext } from 'shared/context/dashboard-context/DashboardFilterContext';
import * as _ from 'lodash-es';
import { MyHub } from 'shared/util/MyHub';
import {
  PERF_workersWorkstationsUpdateDebounceInMs,
  capitalizeFirstLetter,
} from 'shared/backend-data';
import moment from 'moment';
import { sharedGlobalStyle } from 'shared/styles/shared-global';
import styles from '../component/styles';
import { View, Text } from 'react-native';
import { TrainingInfoPanelRow } from '../component/TrainingInfoPanelRow';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';

interface TrainingInfoPanelContainerProps {
  validatePermissionOnSubUnits?: boolean;
}

export const TrainingInfoPanelContainer: React.FC<TrainingInfoPanelContainerProps> = props => {
  const { validatePermissionOnSubUnits } = props;

  const [workersSkillsStatus, setWorkersSkillsStatus] = useState<API.SkillsToRenewOrAcquire>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isUserAuthorizedToViewWorkersDetails, setIsUserAuthorizedToViewWorkersDetails] =
    useState(false);

  const isMounted = useIsMounted();

  const { isValidPermission } = useContext(PermissionManagementContext);

  const {
    workers: [workers],
    workstations: [workstations],
  } = useContext(DashboardFilterContext);

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      
      

      let recompute = false;
      
      if (data.factory.dataType === API.DataType.REQUIREMENT) {
        recompute = true;
      }
      
      else if (
        data.factory.dataType === API.DataType.WORKERWORKSTATION &&
        (data.tooManyMutations ||
          (workersIncludes(data.factory.workerWorkstation.workerId) &&
            workstationsIncludes(data.factory.workerWorkstation.workstationId)))
      ) {
        recompute = true;
      }

      if (recompute) {
        getWorkersSkillStatusThrottle();
      }

      function workersIncludes(workerId: string) {
        return !workers || workers.some(worker => worker.id === workerId);
      }

      function workstationsIncludes(workstationId: string) {
        return !workstations || workstations.some(workstation => workstation.id === workstationId);
      }
    });

    getWorkersSkillStatus(workers, workstations);

    return () => {
      removeListener();
    };
  }, [workstations, workers]);

  const getWorkersSkillStatusThrottle = useCallback(
    _.debounce(() => {
      getWorkersSkillStatus(workers, workstations);
    }, PERF_workersWorkstationsUpdateDebounceInMs),
    [workstations, workers],
  );

  async function getWorkersSkillStatus(
    workers: API.Worker[] | undefined,
    workstations: API.Workstation[] | undefined,
  ) {
    if (!workers?.length || !workstations?.length) return;

    const isAuthorized = await _isUserAuthorizedToViewWorkersDetails();
    setIsUserAuthorizedToViewWorkersDetails(isAuthorized);
    if (!isAuthorized) return;

    setLoadingData(true);

    const result = await API.getWorkstationsWorkersSkillsStatus(
      workers.map(worker => worker.id),
      workstations.map(workstation => workstation.id),
    );
    if (!isMounted.current) return;
    if (API.isFailure(result)) {
      setLoadingData(false);
      logger.warn(result);
      return;
    }
    setWorkersSkillsStatus(result);
    setLoadingData(false);
  }

  async function _isUserAuthorizedToViewWorkersDetails() {
    if (!workers) return false;

    const filteredWorkersAssignmentsMap = await API.getWorkersAssignments(
      true,
      false,
      workers.map(worker => worker.id),
    );

    if (API.isFailure(filteredWorkersAssignmentsMap)) {
      logger.error(
        'TrainingInfoPanel Failed to fetch the assignments',
        filteredWorkersAssignmentsMap,
      );
      return false;
    }

    const isAuthorized = Array.from(filteredWorkersAssignmentsMap.entries()).every(
      ([, workerAssignments]) =>
        workerAssignments.some(workerAssignment => {
          const treeNode = API.Tree.getTreeNode(workerAssignment.organizationalUnitId);
          if (API.isFailure(treeNode)) {
            logger.error('Failed to fetch treeNode ', treeNode);
            return false;
          }
          return isValidPermission(API.Permission.workersDetail_view, treeNode);
        }),
    );
    return isAuthorized;
  }

  return (
    <View style={[sharedGlobalStyle.cardMain, styles.container]}>
      <View style={styles.dateTextContainer}>
        <Text style={styles.dateTextStyle}>
          {capitalizeFirstLetter(moment().format('dddd D MMMM'))}
        </Text>
      </View>
      <TrainingInfoPanelRow
        validatePermissionOnSubUnits={validatePermissionOnSubUnits}
        workersSkillsStatus={workersSkillsStatus}
        loadingData={loadingData}
        isUserAuthorizedToViewWorkersDetails={isUserAuthorizedToViewWorkersDetails}
        setLoadingData={setLoadingData}
      />
    </View>
  );
};
