import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { View, TouchableOpacity, ScrollView, InteractionManager } from 'react-native';
import * as API from 'shared/backend-data';
import { capitalizeFirstLetter, t, capitalize } from 'shared/localisation/i18n';
import logger from 'shared/util/Logger';
import { Colors } from 'shared/styles/Colors';
import { IconSVG } from 'shared/ui-component/Icon';
import { AnimatedTab, Tab } from 'shared/layout/animated-tab/AnimatedTab';
import { WorkstationWorkerLevelContainer } from 'shared/components/workstation-worker-level/container';
import { useIsMounted } from 'shared/hooks/IsMounted';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { WorkerSkillAddModal } from 'skillmgtweb/src/components/add-workerskill-modal/container';
import {
  TableColumn,
  Table,
  TableWidth,
  TableMediumColumnWidth,
  TableXMediumColumnWidth,
  TableLargeColumnWidth,
} from 'shared/ui-component/Table';
import { MyHub } from 'shared/util/MyHub';
import * as _ from 'lodash-es';
import { EllipsisWithTooltip, Position } from 'shared/ui-component/EllipsisWithTooltip';
import { WorkerSkillRow } from '../container/index';
import Styles from './Style';
import { SortDirection } from '../../../../../sort';
import { RouteLocations, GlobalLocationState } from '../../../../../navigation/Routes';
import { RoutePaths } from 'shared/skillmgt/RoutePaths';
import { SkillConformityModal } from '../../../../../dashboard/skill-conformity-modal/container';
import { ModalUtils } from 'shared/ui-component/Modal';
import { HeaderTitleContext } from 'shared/context/HeaderTitleContext';
import { AddEditWorkerModalContainer } from '../../modify-worker-modal/container';
import { Tag } from 'shared/ui-component/Input/InputTag';
import { TrainWorkerModal } from '../../../../../training/train-worker-modal/container/index';
import { PermissionManagementContext } from 'shared/context/PermissionManagementContext';
import { TableRow } from 'shared/ui-component/Table';
import { WorkstationWorkerMenu } from 'skillmgtweb/src/components/dashboard/workstation-worker-menu/index';
import { WorkerBanner, unitsNameAndId } from '../../../../../banner/workerBanner/WorkerBanner';
import { Spacings } from 'shared/styles';
import * as SharedStyles from 'shared/styles';
import { SkillCount } from '../../TableColumnComponents/SkillCount';
import Aigle from 'aigle';
import { TableNumberWithMenu } from 'sharedweb/src/NumberWithMenu';
import {
  ModifyTrainingModal,
  TrainingModalConfig,
} from '../../../../../training/training-modal/container/TrainingModal';
import { MenuWidth } from 'shared/ui-component/Menu';
import { TrainingProofReviewModal } from '../../../../../training/add-training-proof-modal/container';
import { ModifySkillModal } from '../../../../skills/components/modify-skill-modal/component';
import { HeaderFilterContext } from 'sharedweb/src/Filter/FilterContext';
import { TagExtended } from 'sharedweb/src/Filter/container';
import { DropdownConfigKey } from '../../../../../header-layout/headerFilterConfig';
import { ImportExportType } from 'shared/util/ExcelUtils';
import {
  CsvRow,
  getExportDataForWorkerProfile,
  ImportExportFileNames,
} from 'shared/util/ExcelUtils';
import { WorkstationWorkerMenuObject } from '../../../../../dashboard/versatility-panel/VersatilityPanel';
import { isArrayEmpty } from 'shared/util-ts/Functions';
import { MenuFactoryContext } from 'shared/context/MenuFactoryContext';
import { WorkerTrainingStatusForFilter } from '../../../../../header-layout/HeaderLayout';
import { Immutable } from 'shared/util-ts/Functions';
import { downloadfiles } from 'sharedweb/src/util-ts/S3Upload';
import { getValidityColor } from 'shared/util/skillUi';
import { ProofBookContainerWeb } from '../../../../../training/proof-book';
import { WorkerSkillConformityTable } from '../../../../../dashboard/skill-conformity-modal/component/WorkerSkillConformityTable';
import {
  TrainingSessionWithSkillsRow,
  WorkerSkillChildRow,
} from '../../../../../dashboard/skill-conformity-modal/component';

export interface WorkerWorkstationRow extends TableRow {
  worker: API.Worker;
  workstation: API.Workstation;
  workerWorkstation: API.WorkerWorkstation;
}
const AddProofIcon = require('shared/assets/svg/icon.skill.svg').default;
const workstationIcon = require('shared/assets/svg/icon.workstation.svg').default;
const EyeIcon = require('shared/assets/svg/icon.eye.svg').default;

enum TabIndex {
  Training,
  Skill,
  Workstation,
}

interface WorkerWorkstationRowWithOrgUnits extends WorkerWorkstationRow {
  orgUnit: API.OrganizationalUnit;
  orgUnitPathName: string;
  trainings: API.Training[];
}

interface Props extends RouteComponentProps {
  worker: API.Worker;
  contractType: API.ContractType | undefined;
  managers: API.Worker[] | undefined;
  workerSkills: WorkerSkillRow[] | undefined;
  skilledOrPlanToBeSkilledWorkstations: WorkerWorkstationRow[] | undefined;
  workerWorkstationFilterTags: TagExtended[];
  workerSkillFilterTags: TagExtended[];
  skillsNotAcquired: WorkerSkillRow[];
  fetchRowDetailsForWorkerSkills: (
    row: WorkerSkillRow,
    isMounted: React.MutableRefObject<boolean>,
  ) => Promise<boolean>;
  trainingSessionsForLevelSkills:
    | (TrainingSessionWithSkillsRow | WorkerSkillChildRow)[]
    | undefined;
  setTrainingSessionsForLevelSkills: React.Dispatch<
    React.SetStateAction<(TrainingSessionWithSkillsRow | WorkerSkillChildRow)[] | undefined>
  >;
}

export const WorkerProfileComponent: React.FC<Props> = props => {
  const {
    worker,
    workerWorkstationFilterTags,
    workerSkillFilterTags,
    skillsNotAcquired,
    trainingSessionsForLevelSkills,

    fetchRowDetailsForWorkerSkills,
    setTrainingSessionsForLevelSkills,
  } = props;

  const isMounted = useIsMounted();

  const { setFirstTitle, setSecondTitle } = useContext(HeaderTitleContext);
  const {
    treeNode: [, setTreeNode],
  } = useContext(MenuFactoryContext);
  const { isValidPermission } = useContext(PermissionManagementContext);

  const scrollRef = React.useRef<ScrollView>(null);

  const [selectedWorkerSkillRow, setSelectedWorkerSkillRow] = useState<WorkerSkillRow>();
  const [workstationRows, setWorkstationRows] = useState<WorkerWorkstationRowWithOrgUnits[]>([]);
  const [workerSkillRows, setWorkerSkillRows] = useState<WorkerSkillRow[]>([]);
  const [workerWorkstationMap, setWorkerWorkstation] = useState<Map<string, API.WorkerWorkstation>>(
    new Map(),
  );
  const [workerAssignmentsWithDetails, setWorkerAssignmentsWithDetails] = useState<
    Map<API.AssignmentWithUnitDetails, unitsNameAndId[]>
  >(new Map());
  const [workersResponsibleOfUnit, setWorkersResponsibleOfUnit] = useState<
    Map<string, API.Worker[]>
  >(new Map());
  const [displayWorkerModifyModal, setDisplayWorkerModifyModal] = useState<boolean>(false);
  const [displayWorkstationTrainModal, setDisplayWorkstationTrainModal] = useState<boolean>(false);
  const [displayWorkerSkillAddModal, setDisplayWorkerSkillAddModal] = useState<boolean>(false);
  const [displayAddTrainingProofModal, setDisplayAddTrainingProofModal] = useState<boolean>(false);
  const [showWorkerSkillReviewModal, setShowWorkerSkillReviewModal] = useState<boolean>(false);
  const [displaySkillConformityModal, setDisplaySkillConformityModal] =
    useState<API.Workstation | null>(null);
  const {
    currentRoute: [, setCurrentRoute],
    currentTabIndex: [, setFilterTabIndex],
  } = useContext(HeaderFilterContext);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [trainingModalConfig, setTrainingModalConfig] = useState<TrainingModalConfig>({
    editMode: false,
    trainingVersion: null,
    skillIds: [],
  });
  const [proofBookSkillIds, setProofBookSkillIds] = useState<string[]>();
  const [proofBookWorkerIds, setProofBookWorkerIds] = useState<string[]>();
  const [proofBookTrainingVersionId, setProofBookTrainingVersionId] = useState<string>();
  const [showProofBook, setShowProofBook] = useState<boolean>(false);
  const [scrollViewWidth] = useState<number>(0);
  const [tabs, setTabs] = useState<Tab[]>([
    {
      title: {
        title: t('glossary:training_plural'),
        tabTotal: 0,
      },
    },
    {
      title: {
        title: t('glossary:skill_plural'),
        tabTotal: props.workerSkills?.length,
      },
    },
    {
      title: {
        title: t('glossary:workerVersatility'),
        tabTotal: props.skilledOrPlanToBeSkilledWorkstations?.length,
      },
    },
  ]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedElement, setSelectedElement] = useState<Element>();
  const [openMenu, setOpenMenu] = useState(false);
  const [menuObject, setMenuObject] = useState<WorkstationWorkerMenuObject>();
  const [parentPanelElement, setParentPanelElement] = useState<Element>();
  const [selectedSkillId, setSelectedSkillId] = useState<string>();
  const [showSkillNotAcquired, setShowSkillNotAcquired] = useState<boolean>(false);

  const workerSkillTabPlusMenu = [
    {
      label: 'alex:workerProfile.workerSkillsTablePlusRowMenu.0',
      onPress: () => onPlusMenu(API.DataType.SKILL),
    },
    {
      label: 'alex:workerProfile.workerSkillsTablePlusRowMenu.1',
      onPress: () => onPlusMenu(API.DataType.TRAINING),
    },
  ];

  const tableWrapperRef = React.createRef<ScrollView>();

  const modal = ModalUtils.useModal();

  const history = useHistory<GlobalLocationState>();

  function onPlusMenu(key: string) {
    switch (key) {
      case API.DataType.SKILL:
        setDisplayWorkerSkillAddModal(true);
        break;
      case API.DataType.TRAINING:
        setDisplayAddTrainingProofModal(true);
    }
  }

  useEffect(() => {
    loadAssignments();
  }, [worker]);

  useEffect(() => {
    if (props.skilledOrPlanToBeSkilledWorkstations)
      loadWorkstationRows(props.skilledOrPlanToBeSkilledWorkstations);
  }, [props.skilledOrPlanToBeSkilledWorkstations]);

  useEffect(() => {
    if (_.isUndefined(history.location.state?.tabIndex)) return;

    setCurrentTabIndex(history.location.state.tabIndex);
    navigatePage(history.location.state.tabIndex);
  }, [history.location.state?.tabIndex, workerSkillRows]);

  async function handleTrainingEdit(id: string) {
    const latestTrainingVersion = await API.getTrainingVersionLatestForTraining(id);
    if (!isMounted.current) return;
    if (API.isFailure(latestTrainingVersion)) {
      logger.warn(latestTrainingVersion);
      return;
    }
    setTrainingModalConfig({
      editMode: true,
      trainingVersion: latestTrainingVersion,
      skillIds: latestTrainingVersion.skillIds,
    });
    setShowTrainingModal(true);
  }

  const workstationColumns: TableColumn<WorkerWorkstationRowWithOrgUnits>[] = [
    {
      label: t('glossary:workstation_plural'),
      sort: sortWorkstationsByName,
      width: TableXMediumColumnWidth,
      renderCell: (row, index, indexOfRow) => (
        <View style={Styles.workstationNameContainer}>
          <IconSVG
            svgComponent={workstationIcon}
            containerStyle={SharedStyles.Styles.tableObjectIconContainer}
            color={Colors.White}
          />
          <EllipsisWithTooltip
            text={capitalizeFirstLetter(row.workstation.name)}
            style={Styles.ellipsisText}
            textStyle={SharedStyles.Styles.tableText}
            position={
              indexOfRow === workstationRows?.length - 1 ? Position.MIDDLE : Position.BOTTOM
            }
          />
        </View>
      ),
    },
    {
      label: t('glossary:organizationalUnitAbbreviated_plural'),
      width: TableXMediumColumnWidth,
      sort: sortWorkstationByOrgUnit,
      renderCell: (row, index, indexOfRow) => (
        <EllipsisWithTooltip
          text={row.orgUnit.name}
          position={indexOfRow === workstationRows?.length - 1 ? Position.MIDDLE : Position.BOTTOM}
          textStyle={SharedStyles.Styles.tableText}
        />
      ),
    },
    {
      label: t('glossary:level'),
      width: TableMediumColumnWidth,
      sort: sortByLevel,
      renderCell: row => (
        <View style={Styles.workerWorkstationLevelContainer}>
          {
            <WorkstationWorkerLevelContainer
              worker={worker}
              workstationId={row.workstation.id}
              menuObject={menuObject}
              workerWorkstation={API.getWorkerWorkstations(row.workstation.id, worker.id)}
              openMenu={openMenu}
              handleLevelUpdate={handleLevelUpdate}
              setSelectedElement={setSelectedElement}
              setOpenMenu={setOpenMenu}
              setMenuObject={setMenuObject}
            />
          }
        </View>
      ),
    },
    {
      label: t('alex:workerProfile.workerWorkstationTableHeader.0'),
      width: TableWidth,
      sort: sortWorkstationByConformity,
      renderCell: row => {
        return (
          <>
            {row.workerWorkstation && (
              <SkillCount
                totalSkills={row.workerWorkstation.numberOfRequiredSkills ?? 0}
                validSkillIds={
                  row.workerWorkstation.validSkills
                    ? row.workerWorkstation.validSkills.map(eachSkill => eachSkill.workerSkillId)
                    : []
                }
                nbOfExpireSoonSkills={row.workerWorkstation.validExpireSoonSkills?.length}
                nbOfExpiredSkills={
                  (row.workerWorkstation.invalidExpiredSkills?.length ?? 0) +
                  (row.workerWorkstation.invalidMissingSkills?.length ?? 0)
                }
              />
            )}
          </>
        );
      },
    },
    {
      label: t('alex:workerProfile.workerWorkstationTableHeader.1'),
      width: TableWidth,
      sort: sortByTraining,
      renderCell: row => {
        return row.trainings.length ? (
          <TableNumberWithMenu
            list={row.trainings}
            onMenuItemPress={handleTrainingEdit}
            disabled={!isValidPermission(API.Permission.trainings_edit, undefined, [worker.id])}
          />
        ) : (
          <View />
        );
      },
    },
  ];

  function onWorkstationPress(id: string) {
    const _treeNode = API.Tree.getTreeNode(id);
    if (API.isFailure(_treeNode)) {
      logger.warn(_treeNode);
      return;
    }

    setTreeNode(_treeNode);
    history.push(RouteLocations.Workstations(id));
  }

  const workerskillColumns: TableColumn<WorkerSkillRow>[] = [
    {
      label: t('glossary:skill_plural'),
      sort: sortWorkerSkillsByName,
      width: TableLargeColumnWidth,
      renderCell: (row, index, indexOfRow) => (
        <TouchableOpacity
          style={Styles.workstationNameContainer}
          onPress={() => {
            setSelectedWorkerSkillRow(row);
            setShowWorkerSkillReviewModal(true);
          }}
          disabled={!isValidPermission(API.Permission.skills_edit, undefined, [worker.id])}
        >
          <IconSVG
            svgComponent={AddProofIcon}
            size={{ width: Spacings.Standard, height: Spacings.Standard }}
            containerStyle={SharedStyles.Styles.tableObjectIconContainer}
            color={Colors.White}
          />
          <View style={Styles.skillNameContainer}>
            <EllipsisWithTooltip
              text={capitalizeFirstLetter(row.skill.name)}
              style={Styles.skillEllipsisText}
              textStyle={SharedStyles.Styles.tableText}
              position={
                indexOfRow === workstationRows?.length - 1 ? Position.MIDDLE : Position.BOTTOM
              }
            />
          </View>
        </TouchableOpacity>
      ),
    },
    {
      label: t('alex:skillConformityModal.headers.1'),
      sort: sortByValidity,
      width: TableLargeColumnWidth,
      renderCell: (row, index, indexOfRow) => (
        <TouchableOpacity
          style={Styles.orgUnitContainer}
          onPress={() => {
            setSelectedWorkerSkillRow(row);
            setShowWorkerSkillReviewModal(true);
          }}
        >
          <EllipsisWithTooltip
            style={[Styles.skillValidityText]}
            text={row.stateString ?? '-'}
            textStyle={[
              SharedStyles.Styles.tableText,
              ,
              {
                color: row.validity
                  ? getValidityColor(
                      row.toReviewProofBundle ? API.Validity.KO_NEW : row.validity,
                      Colors.MediumGray,
                    )
                  : Colors.MediumGray,
              },
            ]}
            position={
              indexOfRow === workstationRows?.length - 1 ? Position.MIDDLE : Position.BOTTOM
            }
          />
          <View style={[Styles.skillInfoOuterContainer, { zIndex: 998 - index }]}>
            <IconSVG svgComponent={EyeIcon} color={Colors.Grey} />
          </View>
        </TouchableOpacity>
      ),
    },
    {
      label: t('alex:skillConformityModal.headers.2'),
      sort: sortByWorkstationCount,
      width: TableWidth,
      renderCell: (row, index) =>
        row.workstations ? (
          <TableNumberWithMenu list={row.workstations} onMenuItemPress={onWorkstationPress} />
        ) : (
          <></>
        ),
    },
  ];

  useEffect(() => {
    const removeListener = MyHub.listenBusinessObject('BusinessObjectMutate', ({ data }) => {
      if (
        data.factory.dataType === API.DataType.WORKER &&
        (data.tooManyMutations || worker.id === data.factory.worker.id)
      ) {
        loadAssignments();
      }
    });

    InteractionManager.runAfterInteractions(() => {
      loadAssignments();
    });

    setCurrentRoute(RoutePaths.WorkerProfile);
    setFilterTabIndex(0);

    return () => {
      removeListener();
      setFilterTabIndex(undefined);
    };
  }, []);

  useEffect(() => {
    setFirstTitle(`${capitalize(worker.name)}`);
    setSecondTitle('');
  }, [worker]);

  useEffect(() => {
    
    const count = trainingSessionsForLevelSkills?.filter(
      trainingSessionForLevelSkills =>
        API.getDataType(trainingSessionForLevelSkills.key) === API.DataType.TRAININGVERSION ||
        API.getDataType(trainingSessionForLevelSkills.key) === API.DataType.TRAININGSESSION,
    ).length;
    const _tabs = [...tabs];
    _tabs.splice(0, 1, {
      ...tabs[0],
      title: {
        ...tabs[0].title,
        tabTotal: count,
      },
    });
    setTabs(_tabs);
  }, [trainingSessionsForLevelSkills]);

  useEffect(() => {
    
    const _tabs = [...tabs];
    _tabs.splice(2, 1, {
      ...tabs[2],
      title: {
        ...tabs[2].title,
        tabTotal: props.skilledOrPlanToBeSkilledWorkstations?.length,
      },
    });
    setTabs(_tabs);
  }, [props.skilledOrPlanToBeSkilledWorkstations]);

  useEffect(() => {
    if (props.workerSkills) {
      setWorkerSkillRows(props.workerSkills);

      
      const _tabs = [...tabs];
      _tabs.splice(1, 1, {
        ...tabs[1],
        title: {
          ...tabs[1].title,
          tabTotal: props.workerSkills?.length,
        },
      });
      setTabs(_tabs);
    }
  }, [props.workerSkills]);

  const navigatePage = (index: number) => {
    scrollRef.current?.scrollTo({ x: scrollViewWidth * index, animated: false });
    setCurrentTabIndex(index);
    setFilterTabIndex(index);
  };

  function showToastMessage(text: string, callback: Function): void {
    modal.displayModal(
      ModalUtils.toastConfig({
        text: capitalizeFirstLetter(text),
        callback: callback,
      }),
    );
  }

  async function loadWorkstationRows(rows: WorkerWorkstationRow[]): Promise<void> {
    const tags: Tag[] = [];

    const result: WorkerWorkstationRowWithOrgUnits[] = _.compact(
      await Promise.all(
        _.map(rows, async row => {
          const orgUnit = API.getOrganizationalUnit(row.workstation.parentId);
          if (API.isFailure(orgUnit)) {
            logger.warn('Cannot fetch orgUnits. This item will be skiped', orgUnit);
            return;
          }
          tags.push({
            key: row.workstation.id,
            label: row.workstation.name,
            tagType: API.DataType.WORKSTATION,
          });

          const _trainingVersionIds = await API.getTrainingVersionIdsForWorkstationOrOrgUnit(
            row.workstation.id,
          );
          if (!isMounted.current) return;
          if (API.isFailure(_trainingVersionIds)) {
            logger.warn(_trainingVersionIds);
            return;
          }

          const trainings: API.Training[] = [];
          _trainingVersionIds.forEach(async (trainingVersionIds, level) => {
            Aigle.map(trainingVersionIds, async trainingVersionId => {
              const _trainings = await API.getTrainingForATrainingVersion(trainingVersionId);
              if (!isMounted.current) return;
              if (API.isFailure(_trainings)) {
                logger.warn(_trainings);
                return;
              }
              trainings.push(_trainings);
            });
          });

          return {
            ...row,
            orgUnit,
            orgUnitPathName: API.displayOrgUnitPathNames(await API.getOrgUnitPathNames(orgUnit)),
            workerWorkstationWithInfo: undefined,
            trainings,
          };
        }),
      ),
    );
    if (!isMounted.current) return;
    setWorkstationRows(result);
  }

  async function loadAssignments() {
    const workerAssignments = await API.getWorkerAssignments(worker.id, true, true);
    if (!isMounted.current) return;
    if (API.isFailure(workerAssignments)) {
      logger.warn('Failed to assignments info', workerAssignments);
      return;
    }

    const workerAssignmentsWithInherited = new Map<
      API.AssignmentWithUnitDetails,
      unitsNameAndId[]
    >();

    const _workersResponsibleOfUnit = new Map<string, API.Worker[]>();

    
    workerAssignments.map(async workerAssignments => {
      if (!workerAssignments.inherited) {
        workerAssignmentsWithInherited.set(workerAssignments, []);
        const workersAssignments = await API.getWorkersAssignments(true, false, undefined, [
          workerAssignments.organizationalUnit.id,
        ]);
        if (!isMounted.current) return;
        if (API.isFailure(workersAssignments)) {
          logger.warn('Failed to workersOrgUnitRolesOnUnit', workersAssignments);
          return;
        }

        for (let [key, value] of workersAssignments) {
          
          if (value[0]?.permissions.includes(API.Permission.workerIsManager) && key !== worker.id) {
            const worker = await API.getWorker(key);
            if (!isMounted.current) return;
            if (API.isFailure(worker)) {
              logger.warn('Failed to get worker', worker);
              return;
            }

            if (worker.state !== API.WorkerState.ARCHIVED) {
              
              const workers = _workersResponsibleOfUnit.get(
                workerAssignments.organizationalUnit.id,
              );
              if (workers) {
                workers.push(worker);
                _workersResponsibleOfUnit.set(workerAssignments.organizationalUnit.id, workers);
              } else {
                _workersResponsibleOfUnit.set(workerAssignments.organizationalUnit.id, [worker]);
              }
            }
          }
        }
      }
    });
    setWorkersResponsibleOfUnit(_workersResponsibleOfUnit);

    
    workerAssignments.forEach(workerAssignment => {
      if (workerAssignment.inherited) {
        const ParentOrgUnitRole = Array.from(workerAssignmentsWithInherited.keys()).find(
          assignment =>
            assignment.organizationalUnit.id === workerAssignment.organizationalUnit.parentId,
        );
        if (ParentOrgUnitRole) {
          const inheritedOrgUnits = workerAssignmentsWithInherited.get(ParentOrgUnitRole);
          if (inheritedOrgUnits) {
            inheritedOrgUnits.push({
              name: workerAssignment.organizationalUnit.name,
              id: workerAssignment.organizationalUnit.id,
            });
            workerAssignmentsWithInherited.set(ParentOrgUnitRole, inheritedOrgUnits);
          }
        }
      }
    });

    setWorkerAssignmentsWithDetails(workerAssignmentsWithInherited);
  }

  async function downloadProofBundle(
    activeProofBundle: Immutable<API.NoMetadata<API.ProofBundle>>,
  ) {
    const _files = await downloadfiles(activeProofBundle);
    if (!isMounted.current) return;
    if (API.isFailure(_files)) {
      logger.warn(_files);
      return;
    }
  }

  async function deleteWorkerSkill(workerSkill: API.WorkerSkill) {
    modal.displayModal(
      ModalUtils.warningConfig({
        warningMessage: t('alex:workerProfile.deleteSkillWarning'),
        warningAcceptButton: t('common:button.yes'),
        warningCancelButton: t('common:button.no'),
        warningAcceptCallback: async () => {
          const deleteSkill = await API.deleteFactoryBusinessObject(workerSkill.id);
          if (!isMounted.current) return;
          if (API.isFailure(deleteSkill)) {
            logger.warn(deleteSkill);
          }
        },
      }),
    );
  }

  function onMenuPress(row: WorkerSkillRow, key: 'proof' | 'downloadProof' | 'deleteSkill') {
    setSelectedWorkerSkillRow(row);
    switch (key) {
      case 'proof':
        setShowWorkerSkillReviewModal(true);
        break;
      case 'downloadProof':
        if (row.activeProofBundle) downloadProofBundle(row.activeProofBundle);
        break;
      case 'deleteSkill':
        if (!row.id) return;
        deleteWorkerSkill(row);
        break;
    }
  }

  function sortByTraining(
    rows: WorkerWorkstationRowWithOrgUnits[],
    sortDirection: SortDirection,
  ): WorkerWorkstationRowWithOrgUnits[] {
    return _.orderBy(rows, e => e.trainings.length, [sortDirection]);
  }

  function sortWorkstationsByName(
    rows: WorkerWorkstationRowWithOrgUnits[],
    sortDirection: SortDirection,
  ): WorkerWorkstationRowWithOrgUnits[] {
    return _.orderBy(rows, e => e.workstation.name, [sortDirection]);
  }

  function sortWorkstationByOrgUnit(
    rows: WorkerWorkstationRowWithOrgUnits[],
    sortDirection: SortDirection,
  ): WorkerWorkstationRowWithOrgUnits[] {
    return _.orderBy(rows, e => e.orgUnit.name, [sortDirection]);
  }

  function sortWorkstationByConformity(
    rows: WorkerWorkstationRowWithOrgUnits[],
    sortDirection: SortDirection,
  ): WorkerWorkstationRowWithOrgUnits[] {
    return _.orderBy(rows, e => e.workerWorkstation.numberOfOkWorkerSkills, [sortDirection]);
  }

  function sortWorkerSkillsByName(
    rows: WorkerSkillRow[],
    sortDirection: SortDirection,
  ): WorkerSkillRow[] {
    return _.orderBy(rows, e => e.skill.name, [sortDirection]);
  }

  function sortByLevel(
    rows: WorkerWorkstationRowWithOrgUnits[],
    sortDirection: SortDirection,
  ): WorkerWorkstationRowWithOrgUnits[] {
    return _.orderBy(
      _.orderBy(rows, e => workerWorkstationMap.get(e.workstation.id)?.level, [sortDirection]),
      e => workerWorkstationMap.get(e.workstation.id)?.targetLevel,
      [sortDirection],
    );
  }

  function sortByValidity(rows: WorkerSkillRow[], sortDirection: SortDirection): WorkerSkillRow[] {
    return API.orderByWorkerSkillValidity(rows, sortDirection);
  }

  function sortByWorkstationCount(
    rows: WorkerSkillRow[],
    sortDirection: SortDirection,
  ): WorkerSkillRow[] {
    return _.orderBy(rows, e => e.workstations?.length, [sortDirection]);
  }

  function handleLevelUpdate(workerWorkstation: API.WorkerWorkstation) {
    workerWorkstationMap.set(workerWorkstation.workstationId, workerWorkstation);
    setWorkerWorkstation(new Map(workerWorkstationMap));
  }

  function filterWorkerSkillData(
    isKeywordFiltering: boolean,
    filterTags: TagExtended[],
    workerSkills: WorkerSkillRow[],
  ) {
    if (isKeywordFiltering && isArrayEmpty(filterTags)) return [];

    let filteringRows: WorkerSkillRow[] = isKeywordFiltering ? [] : workerSkills;

    if (filterTags.find(tag => tag.type === DropdownConfigKey.WORKER_SKILL_OTHER_FILTER)) {
      filterTags = filterTags.filter(
        tag => tag.type === DropdownConfigKey.WORKER_SKILL_OTHER_FILTER,
      );
      setShowSkillNotAcquired(true);
    } else {
      setShowSkillNotAcquired(false);
    }
    

    const skillFilters: TagExtended[] = [];
    const validityFilters: TagExtended[] = [];
    const workstationFilters: TagExtended[] = [];

    _.forEach(filterTags, filter => {
      if (filter.type === DropdownConfigKey.SKILL) skillFilters.push(filter);
      else if (filter.type === DropdownConfigKey.SKILL_VALIDITY) {
        validityFilters.push(filter);
      } else if (filter.type === DropdownConfigKey.WORKSTATION) {
        workstationFilters.push(filter);
      } else logger.warn('Filter tag not recognized', filter);
    });

    if (skillFilters.length) {
      const data = _.filter(isKeywordFiltering ? workerSkills : filteringRows, row =>
        _.some(skillFilters, filter => filter.key === row.skill.id),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    if (validityFilters.length) {
      const data = _.filter(filteringRows ? workerSkills : filteringRows, row =>
        _.some(validityFilters, filter => filter.key === row.validity),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    if (workstationFilters.length) {
      const data = _.filter(filteringRows ? workerSkills : filteringRows, row =>
        _.some(workstationFilters, filter =>
          (row.workstations ?? []).map(workstation => workstation.id).includes(filter.key),
        ),
      );

      if (isKeywordFiltering) {
        filteringRows = [...filteringRows, ...data];
      } else {
        filteringRows = data;
      }
    }

    return filteringRows;
  }

  async function filterWorkerSkillFilterRows(
    rows: WorkerSkillRow[],
    filterTags: TagExtended[],
  ): Promise<WorkerSkillRow[]> {
    let _filteredRows: WorkerSkillRow[] = rows;

    let _filterTags: TagExtended[] = [];
    let _keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(filterTags, tag => {
      if (tag.isActiveBookmarkTag) {
        _filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        _keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        _filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      _filteredRows = filterWorkerSkillData(true, _keywordTags, _filteredRows);
    }

    if (_filterTags.length) {
      _filteredRows = filterWorkerSkillData(false, _filterTags, _filteredRows);
    }

    return _.uniqBy(_filteredRows, 'key');
  }

  function filteredWorkerWorkstationData(
    isKeywordFiltering: boolean,
    filterTags: TagExtended[],
    workerWorkstations: WorkerWorkstationRowWithOrgUnits[],
  ): WorkerWorkstationRowWithOrgUnits[] {
    if (isKeywordFiltering && isArrayEmpty(filterTags)) return [];
    let filteringRows: WorkerWorkstationRowWithOrgUnits[] = isKeywordFiltering
      ? []
      : workerWorkstations;

    
    const orgUnitFilters: TagExtended[] = [];
    const shiftFilters: TagExtended[] = [];
    const workstationFilters: TagExtended[] = [];
    const skillFilters: TagExtended[] = [];
    const levelFilters: TagExtended[] = [];
    const trainingFilters: TagExtended[] = [];

    _.forEach(filterTags, filter => {
      if (filter.type === DropdownConfigKey.ORGUNIT) orgUnitFilters.push(filter);
      else if (filter.type === DropdownConfigKey.SHIFT) shiftFilters.push(filter);
      else if (filter.type === DropdownConfigKey.WORKSTATION) workstationFilters.push(filter);
      else if (filter.type === DropdownConfigKey.SKILL) skillFilters.push(filter);
      else if (filter.type === DropdownConfigKey.LEVEL) levelFilters.push(filter);
      else if (filter.type === DropdownConfigKey.TRAINING) trainingFilters.push(filter);
      else logger.warn('Filter tag not recognized', filter);
    });

    if (shiftFilters.length) {
      _.map(shiftFilters, eachFilter => {
        orgUnitFilters.push({
          key: eachFilter.value.parentId,
          label: '',
        });
      });
    }

    if (orgUnitFilters.length) {
      filteringRows = _.filter(isKeywordFiltering ? workerWorkstations : filteringRows, row =>
        _.some(orgUnitFilters, filter => filter.key === row.orgUnit.id),
      );
    }
    if (workstationFilters.length) {
      filteringRows = _.filter(isKeywordFiltering ? workerWorkstations : filteringRows, row =>
        _.some(workstationFilters, filter => filter.key === row.workstation.id),
      );
    }
    if (skillFilters.length) {
      filteringRows = _.filter(isKeywordFiltering ? workerWorkstations : filteringRows, row =>
        _.some(
          skillFilters,
          filter =>
            row.workerWorkstation.validSkills &&
            row.workerWorkstation.validSkills.some(
              eachSkill => eachSkill.workerSkillId.split(API.SeparatorIds)[2] === filter.key,
            ),
        ),
      );
    }
    if (levelFilters.length) {
      filteringRows = _.filter(isKeywordFiltering ? workerWorkstations : filteringRows, row =>
        _.some(
          levelFilters,
          filter =>
            filter.key === row.workerWorkstation.level ||
            (API.isWorkerInTrainingOnWorkstation(row.workerWorkstation) &&
              filter.key === WorkerTrainingStatusForFilter.TRAIN),
        ),
      );
    }

    if (trainingFilters.length) {
      filteringRows = _.filter(isKeywordFiltering ? workerWorkstations : filteringRows, row =>
        _.some(trainingFilters, filter =>
          row.trainings.some(eachTraining => eachTraining.id === filter.key),
        ),
      );
    }

    return _.uniqBy(filteringRows, 'key');
  }

  async function filterWorkerWorkstationFilterRows(
    rows: WorkerWorkstationRowWithOrgUnits[],
    filterTags: TagExtended[],
  ): Promise<WorkerWorkstationRowWithOrgUnits[]> {
    let _filteredRows: WorkerWorkstationRowWithOrgUnits[] = rows;

    let _filterTags: TagExtended[] = [];
    let _keywordTags: TagExtended[] = [];
    let containsKeywordTags = false;

    _.forEach(filterTags, tag => {
      if (tag.isActiveBookmarkTag) {
        _filterTags.push(...(tag.children ?? []));
      } else if (tag.isKeywordTag) {
        containsKeywordTags = true;
        _keywordTags.push(...(tag.children ?? []));
      } else if (!tag.isBookmarkTag) {
        _filterTags.push(tag);
      }
    });

    if (containsKeywordTags) {
      _filteredRows = filteredWorkerWorkstationData(true, _keywordTags, _filteredRows);
    }

    if (_filterTags.length) {
      _filteredRows = filteredWorkerWorkstationData(false, _filterTags, _filteredRows);
    }

    return _.uniqBy(_filteredRows, 'key');
  }

  function handleOpenProofBook(
    workerIds: string[],
    skillIds: string[],
    trainingVersionId?: string,
  ) {
    setProofBookWorkerIds(workerIds);
    setProofBookSkillIds(skillIds);
    setProofBookTrainingVersionId(trainingVersionId);

    setShowProofBook(true);
    setDisplayWorkstationTrainModal(false);
    setDisplayAddTrainingProofModal(false);
  }

  return (
    <View style={Styles.container}>
      <View style={Styles.innerContainer}>
        <WorkerBanner
          worker={worker}
          workerAssignmentsWithInherited={workerAssignmentsWithDetails}
          workersResponsableOfUnit={workersResponsibleOfUnit}
          loadAssignments={loadAssignments}
        />

        <View style={Styles.rightPanel}>
          <View style={Styles.tabContainer}>
            <AnimatedTab currentTabIndex={currentTabIndex} tabs={tabs} onTabPress={navigatePage} />
          </View>
          <ScrollView
            contentContainerStyle={Styles.horizontalInnerScrollView}
            style={[
              Styles.horizontalScrollView,
              currentTabIndex !== TabIndex.Training && { display: 'none' },
            ]}
            ref={tableWrapperRef}
            onLayout={() => {
              if (!tableWrapperRef.current) return;
              const node = ReactDOM.findDOMNode(tableWrapperRef.current) as Element;
              setParentPanelElement(node);
            }}
          >
            {trainingSessionsForLevelSkills && (
              <WorkerSkillConformityTable
                worker={worker}
                trainingSessionsWithSkillsRow={trainingSessionsForLevelSkills}
                setTrainingSessionsForLevelSkills={setTrainingSessionsForLevelSkills}
              />
            )}
          </ScrollView>
          <ScrollView
            contentContainerStyle={Styles.horizontalInnerScrollView}
            style={[
              Styles.horizontalScrollView,
              currentTabIndex !== TabIndex.Skill && { display: 'none' },
            ]}
          >
            <Table
              filter={{
                filterRows: filterWorkerSkillFilterRows,
                tags: workerSkillFilterTags,
              }}
              columnDescriptors={workerskillColumns}
              rows={showSkillNotAcquired ? skillsNotAcquired : workerSkillRows}
              rowMenuWidth={MenuWidth.Medium}
              plusMenuWidth={MenuWidth.Large}
              showAddButton={isValidPermission(API.Permission.workersSkillsProof_edit, undefined, [
                worker.id,
              ])}
              disableRowClick={!isValidPermission(API.Permission.skills_edit)}
              rowLazyLoadProperties={fetchRowDetailsForWorkerSkills}
              importExport={{
                excelIcon: true,
                exportOnly: true,
                getExportData: getExportDataForWorkerProfile,
                refreshData: async () => {}, 
                importExportType: ImportExportType.WorkerProfileSkill,
                importExportFileName: ImportExportFileNames.WorkerProfileSkill,
                showImportExport: isValidPermission(API.Permission.workersDetail_view, undefined, [
                  worker.id,
                ]),
              }}
              rowMenu={row => {
                const menuItems = [];
                menuItems.push({
                  label: 'alex:workerProfile.workerSkillsTableRowMenu.0',
                  onPress: () => onMenuPress(row, 'proof'),
                });

                menuItems.push({
                  label: 'alex:workerProfile.workerSkillsTableRowMenu.1',
                  onPress: () => onMenuPress(row, 'downloadProof'),
                  disable: !row?.activeProofBundle,
                });
                if (
                  row.validity === API.Validity.KO_EXPIRED ||
                  row.activeProofBundle?.review?.state === API.ReviewState.REJECTED ||
                  row.activeProofBundle?.review?.state === API.ReviewState.REJECTED_TO_RESUBMIT
                )
                  menuItems.push({
                    label: 'alex:workerProfile.workerSkillsTableRowMenu.2',
                    onPress: () => onMenuPress(row, 'deleteSkill'),
                  });
                return menuItems;
              }}
              plusMenu={workerSkillTabPlusMenu}
              onRowPress={row => {}}
              noDataMessage={t('alex:table.workerHasNoSkill')}
            />
          </ScrollView>
          <ScrollView
            contentContainerStyle={Styles.horizontalInnerScrollView}
            style={[
              Styles.horizontalScrollView,
              currentTabIndex !== TabIndex.Workstation && { display: 'none' },
            ]}
            ref={tableWrapperRef}
            onLayout={() => {
              if (!tableWrapperRef.current) return;
              const node = ReactDOM.findDOMNode(tableWrapperRef.current) as Element;
              setParentPanelElement(node);
            }}
          >
            {openMenu && selectedElement && menuObject && (
              <WorkstationWorkerMenu
                levelIconElement={selectedElement}
                parentPanelElement={parentPanelElement}
                menuObject={menuObject}
                setOpenMenu={setOpenMenu}
              />
            )}
            <Table
              filter={{
                filterRows: filterWorkerWorkstationFilterRows,
                tags: workerWorkstationFilterTags,
              }}
              columnDescriptors={workstationColumns}
              rows={workstationRows}
              onRowPress={() => null}
              disableHoverEffect
              disableRowClick
              noDataMessage={t('alex:table.noSkillfulness')}
            />
          </ScrollView>
        </View>

        <TrainWorkerModal
          openModal={
            props.skilledOrPlanToBeSkilledWorkstations ? displayWorkstationTrainModal : false
          }
          workerId={worker.id}
          handleModalClose={() => setDisplayWorkstationTrainModal(false)}
        />

        {proofBookSkillIds && proofBookWorkerIds && showProofBook && (
          <ProofBookContainerWeb
            workerIds={proofBookWorkerIds}
            skillIds={proofBookSkillIds}
            trainingVersionId={proofBookTrainingVersionId}
            allowPartialSubmission={false}
            setShowAddTrainingProofModal={setShowProofBook}
          />
        )}

        {displayWorkerSkillAddModal && (
          <WorkerSkillAddModal
            selectedWorkers={[worker]}
            handleModalClose={() => setDisplayWorkerSkillAddModal(false)}
            showToastMessage={showToastMessage}
            openProofBook={handleOpenProofBook}
          />
        )}
        {displayAddTrainingProofModal && (
          <TrainingProofReviewModal
            trainingProof={{
              addProofWorkerId: worker.id,
            }}
            handleModalClose={() => setDisplayAddTrainingProofModal(false)}
            openProofBook={handleOpenProofBook}
          />
        )}
        {displayWorkerModifyModal && (
          <AddEditWorkerModalContainer
            workerId={worker.id}
            showHideModal={setDisplayWorkerModifyModal}
          />
        )}
        {showWorkerSkillReviewModal &&
          selectedWorkerSkillRow &&
          selectedWorkerSkillRow.skill.skillIds?.length && (
            <ProofBookContainerWeb
              setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
              workerIds={[selectedWorkerSkillRow.workerId]}
              skillIds={selectedWorkerSkillRow.skill.skillIds as string[]}
              proofAlreadyValidatedWorkersAndSkills={{
                workerIds: [selectedWorkerSkillRow.workerId],
                skillIds: selectedWorkerSkillRow.skill.skillIds as string[],
              }}
            />
          )}
        {showWorkerSkillReviewModal &&
          selectedWorkerSkillRow &&
          (selectedWorkerSkillRow.toReviewProofBundle ? (
            <ProofBookContainerWeb
              proofBundleId={selectedWorkerSkillRow.toReviewProofBundle.id}
              setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
              workerIds={[selectedWorkerSkillRow.workerId]}
            />
          ) : selectedWorkerSkillRow.activeProofBundle ? (
            <ProofBookContainerWeb
              proofBundleId={selectedWorkerSkillRow.activeProofBundle.id}
              setShowAddTrainingProofModal={setShowWorkerSkillReviewModal}
              workerIds={[selectedWorkerSkillRow.workerId]}
            />
          ) : null)}
        {displaySkillConformityModal && (
          <SkillConformityModal
            workstationId={displaySkillConformityModal.id}
            workerId={worker.id}
            handleModalClose={() => setDisplaySkillConformityModal(null)}
          />
        )}
        {showTrainingModal && (
          <ModifyTrainingModal
            config={trainingModalConfig}
            handleModalClose={() => {
              setShowTrainingModal(false);
              setTrainingModalConfig({
                editMode: false,
                trainingVersion: null,
                skillIds: [],
              });
            }}
            handleRefresh={() => null}
          />
        )}
        {selectedSkillId && (
          <ModifySkillModal
            skillId={selectedSkillId}
            handleModalClose={() => setSelectedSkillId(undefined)}
          />
        )}
      </View>
    </View>
  );
};
