import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-web';
import Styles from './styles';
import { t } from 'shared/localisation/i18n';
import {
  DeleteIconState,
  OrganizationUnit,
} from 'shared/layout/organization-unit/OrganizationUnit';
import { StyleProp, ViewStyle } from 'react-native';
import { ShadowOnHoverButton } from 'shared/ui-component/Button/ShadowOnHoverButton';
import { Colors } from 'shared/styles';
import * as API from 'shared/backend-data';
import { adminRoleId } from 'shared/backend-data';
import * as _ from 'lodash-es';
import { UserContext } from 'shared/context/UserContext';

interface Props {
  worker: API.Worker | undefined;
  assignments: API.IndexedAssignment[];
  containerStyle?: StyleProp<ViewStyle>;
  allShifts: API.Shift[];
  handleEditAddUniteButton: () => void;
  removeUnitAssignment: (indexedAssignment: API.IndexedAssignment) => void;
  handleChange: (indexedAssignment: API.IndexedAssignment) => void;
}

export const WorkerAssignmentDropDownsComponent: React.FC<Props> = props => {
  const {
    worker,
    assignments,
    containerStyle,
    allShifts,

    removeUnitAssignment,
    handleEditAddUniteButton,
    handleChange,
  } = props;

  const [highestAdminWorkerAssignment, setHighestAdminWorkerAssignment] = useState<
    API.IndexedAssignment[]
  >([]);

  useEffect(() => {
    filterHighestAdminWorkerAssignments();
  }, [assignments]);

  function filterHighestAdminWorkerAssignments() {
    const map = new Map<number, API.IndexedAssignment[]>();

    

    assignments.forEach(assignment => {
      if (
        assignment.permissions &&
        assignment.role?.id === adminRoleId &&
        assignment.state !== API.IndexedAssignmentState.TO_DELETE
      ) {
        const depth = assignment.organizationalUnit.pathIds.length;
        const orgUnitRolesArray = map.get(depth);
        if (orgUnitRolesArray) {
          orgUnitRolesArray.push(assignment);
        } else {
          map.set(depth, [assignment]);
        }
      }
    });

    const lowestDepth = _.min(Array.from(map.keys()));
    setHighestAdminWorkerAssignment(lowestDepth ? map.get(lowestDepth)! : []);
  }

  /**
   * To be able to delete an OrgUnitRole
   * 1- The total of the organizationalUnitRoles should be greater than 1
   * 2- The total of the valid organizationalUnitRoles(!== TO_DELETE) should be greater than 1
   * 3- @see isAdminRoleDeletable()
   * @param item
   * @returns boolean
   */
  function isDisplayDeleteIcon(item: API.IndexedAssignment): DeleteIconState {
    if (
      assignments.length > 1 &&
      assignments.filter(orgUnitRole => orgUnitRole.state !== API.IndexedAssignmentState.TO_DELETE)
        .length > 1
    ) {
      if (item.permissions && item.role?.id !== adminRoleId) {
        return DeleteIconState.Display;
      } else {
        if (isAdminRoleDeletable(item)) {
          return DeleteIconState.Display;
        } else {
          return DeleteIconState.AdminPermissionsVeto;
        }
      }
    } else {
      if (isAdminRoleDeletable(item)) return DeleteIconState.Hide;
      return DeleteIconState.AdminPermissionsVeto;
    }
  }

  /**
   * The Role id should be different than adminRoleId
   * OR (Role id equals adminRoleId
   *     AND (SignedInUser Worker is not editing his own worker object
   *          OR this Admin assignment is not the only one with the highest level))
   * @param item
   * @returns
   */
  function isAdminRoleDeletable(item: API.IndexedAssignment): boolean {
    let userId: string | undefined = undefined;
    const user = UserContext.getUser();
    if (!API.isFailure(user)) {
      userId = user.id;
    }

    if (
      (item.permissions && item.role?.id !== adminRoleId) ||
      !userId ||
      !worker ||
      !worker.userId ||
      userId !== worker.userId ||
      !highestAdminWorkerAssignment.length ||
      highestAdminWorkerAssignment.length > 1
    )
      return true;

    if (
      highestAdminWorkerAssignment.length === 1 &&
      _.find(highestAdminWorkerAssignment, _item => _item.uuid === item.uuid)
    )
      return false;
    else return true;
  }

  return (
    <View style={[containerStyle ?? Styles.unitContainer]}>
      <View style={Styles.titleContainer}>
        <Text style={Styles.addUnit}>{t('alex:worker.addEditWorker.addUnitAndPermissions')}</Text>
        <ShadowOnHoverButton
          size={24}
          iconSize={12}
          onPress={handleEditAddUniteButton}
          iconContainerStyle={{
            backgroundColor: Colors.Yellow,
          }}
        />
      </View>
      <View style={Styles.unitDropdownContainer}>
        {!assignments.length ? (
          <Text style={Styles.addWorkerAndUnitText}>{t('alex:worker.addEditWorker.addUnit')}</Text>
        ) : (
          assignments.map((item, index) => {
            return (
              <View
                style={[
                  Styles.eachUnitContainer,
                  {
                    zIndex: -index,
                  },
                ]}
                key={item.uuid}
              >
                {allShifts && (
                  <OrganizationUnit
                    defaultAssignment={item}
                    index={index}
                    displayDeleteIcon={isDisplayDeleteIcon(item)}
                    dropDownContainerStyle={{ width: 256 }}
                    assignments={assignments}
                    allShifts={allShifts}
                    removeUnitRole={removeUnitAssignment}
                    handleChange={handleChange}
                  />
                )}
              </View>
            );
          })
        )}
      </View>
    </View>
  );
};
