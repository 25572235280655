import React, { useState } from 'react';
import { Text, View, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Card } from 'shared/ui-component/Card';
import styles from './styles';
import { fontSize, Colors, Fonts, Spacings } from 'shared/styles';
import { ToolTipWeb } from 'shared/ui-component/ToolTip/ToolTipWeb';
import { IconSVG } from 'shared/ui-component/Icon';
import { RadioButton, YellowButton } from 'shared/ui-component/Button';
import { MenuItem } from 'shared/ui-component/Menu';
import { ActivityIndicator } from 'shared/ui-component/Loader/ActivityIndicator';
import { useDetectOutsideClick } from 'shared/hooks/DetectOutsideClick';
import { t } from 'shared/localisation/i18n';

const InfoIcon = require('shared/assets/svg/icon.info.svg').default;
const MoreIcon = require('shared/assets/svg/icon.more.small.svg').default;

interface Props<T> {
  data: T[];
  title: string;
  config: ChartConfig;
  toolTipText: string;
  menuItems?: MenuItem[];
  loader: boolean;
  noData: boolean;
  noDataLabel: string;
  containerStyle?: StyleProp<ViewStyle>;
  yAxisMaxLimit?: number | string;
  formatXaxisWithHour: boolean;
  isTooMuchDataToLoad?: boolean;

  onRadioButtonChange?: (index: number) => void;
  loadFullData?: () => void;
}

interface yAxisKey {
  key: string;
  label: string;
  color: string;
}

export interface ChartConfig {
  xAxisKey: string;
  xAxisKey1: string;
  xAxisKey2?: string;
  yAxisConfig: yAxisKey[];
}

export const StackedAreaGraph = <T extends {}>(props: React.PropsWithChildren<Props<T>>) => {
  const {
    data,
    title,
    config,
    toolTipText,
    menuItems,
    containerStyle,
    loader,
    noData,
    noDataLabel,
    formatXaxisWithHour,
    yAxisMaxLimit = 'auto',
    isTooMuchDataToLoad,

    loadFullData,
    onRadioButtonChange,
  } = props;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [radioIndex, setRadioIndex] = useState<number>(0);

  const ref = useDetectOutsideClick<View>(() => {
    setShowMenu(false);
  });

  function handleRadioChange(text: string, index: number) {
    if (onRadioButtonChange) onRadioButtonChange(index);
    setShowMenu(false);
    setRadioIndex(index);
  }

  function CustomTooltip(value: any) {
    if (value.active && value.payload && value.payload.length) {
      return (
        <View style={styles.cursorToolTipContainer}>
          {value.payload.reverse().map((eachPayload: any) => {
            return (
              <View style={styles.cursorTextContainer}>
                <Text style={[styles.cursorToolTipValue, { color: eachPayload.color }]}>
                  {eachPayload.value}
                </Text>
                <Text style={styles.cursorToolTipText}>
                  {(eachPayload.payload.pluralLabels &&
                    eachPayload.payload.pluralLabels[eachPayload.dataKey]) ??
                    eachPayload.name}
                </Text>
              </View>
            );
          })}
        </View>
      );
    }

    return null;
  }

  return (
    <Card style={[containerStyle, styles.rootContainer]}>
      <View style={styles.titleContainer}>
        <Text style={styles.graphTitle}>{title}</Text>
        {isTooMuchDataToLoad && (
          <YellowButton
            text={t('alex:KPI.KPIGraph.showFullData')}
            style={styles.bottomButtonStyle}
            textStyle={styles.bottomButtonTextStyle}
            onPress={loadFullData}
          />
        )}
        <View style={styles.toolTipContainer}>
          <View style={styles.infoIcon}>
            <ToolTipWeb
              title={toolTipText}
              component={<IconSVG svgComponent={InfoIcon} color={Colors.GreyLight} />}
              style={styles.infoContainer}
              toolTipTextStyle={styles.toolTipText}
            />
          </View>
          {menuItems ? (
            <TouchableOpacity onPress={() => setShowMenu(!showMenu)} style={styles.moreIcon}>
              <IconSVG svgComponent={MoreIcon} />
            </TouchableOpacity>
          ) : null}
          {showMenu && menuItems ? (
            <Card style={styles.cardStyle} ref={ref}>
              <RadioButton
                items={menuItems.map(item => {
                  return { item: item.label };
                })}
                onChange={handleRadioChange}
                initialItemIndex={radioIndex}
                radioButtonColor={Colors.Black}
                textStyle={styles.radioText}
              />
            </Card>
          ) : null}
        </View>
      </View>

      {noData && !loader && (
        <View style={styles.noDataContainer}>
          <Text style={styles.noDataTextStyle}>{noDataLabel}</Text>
        </View>
      )}

      <View style={styles.graphContainer}>
        <ResponsiveContainer height="100%" width="100%">
          {loader ? (
            <ActivityIndicator />
          ) : (
            <AreaChart data={data}>
              {!noData && <CartesianGrid strokeDasharray="3 3" horizontal={false} />}
              <XAxis
                dataKey={config.xAxisKey}
                tick={{ fill: Colors.Black }}
                style={{
                  fontSize: fontSize.Small,
                  fontFamily: Fonts.Lato.LatoWeb,
                  fontWeight: 'normal',
                }}
                tickLine={false}
                tickMargin={8}
                axisLine={!noData}
              />

              <YAxis tick={false} axisLine={false} type="number" domain={[0, yAxisMaxLimit]} />
              {noData ? null : (
                <XAxis
                  tickLine={false}
                  xAxisId="3"
                  orientation="top"
                  dataKey={config.xAxisKey1}
                  axisLine={false}
                  tick={{ fill: Colors.MediumGray }}
                  tickFormatter={tick => (formatXaxisWithHour && tick ? `${tick} h` : tick)}
                  style={{
                    fontSize: fontSize.Regular,
                    fontFamily: Fonts.Lato.LatoWeb,
                    fontWeight: '900',
                  }}
                  tickMargin={2}
                />
              )}

              {config.xAxisKey2 && !noData ? (
                <XAxis
                  tickLine={false}
                  xAxisId="2"
                  orientation="top"
                  dataKey={config.xAxisKey2}
                  axisLine={false}
                  tick={{ fill: Colors.Grey }}
                  style={{
                    fontSize: fontSize.Small,
                    fontFamily: Fonts.Lato.LatoWeb,
                    fontWeight: 'normal',
                  }}
                  tickMargin={10}
                />
              ) : null}
              <Tooltip
                cursor={{
                  stroke: 'rgba(0, 0, 0, 0.02)',
                  strokeWidth: Spacings.Big,
                }}
                content={CustomTooltip}
                position={{ y: 65 }}
              />
              {config.yAxisConfig.map(yAxisKeyLabel => (
                <Area
                  key={yAxisKeyLabel.key}
                  type="linear"
                  dataKey={yAxisKeyLabel.key}
                  stroke={noData ? Colors.White : yAxisKeyLabel.color}
                  fill={noData ? Colors.White : yAxisKeyLabel.color}
                  name={yAxisKeyLabel.label}
                  fillOpacity={1}
                  stackId="1"
                />
              ))}
            </AreaChart>
          )}
        </ResponsiveContainer>
      </View>
    </Card>
  );
};
