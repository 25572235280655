import { StyleSheet } from 'react-native';
import { Colors, TextFontStyles, Spacings, createRoundedStyle, fontSize } from 'shared/styles';

const styles = StyleSheet.create({
  animatedTabContainer: {
    height: 106,
  },
  flatListContainer: {
    flex: 1,
  },
  flatListStyle: {
    width: '100%',
    height: '100%',
    flexGrow: 0,
  },
  flatListItemStyle: {
    flexDirection: 'row',
    width: '100%',
    borderBottomColor: Colors.Background,
    borderBottomWidth: 1,
    paddingVertical: Spacings.Medium,
    paddingLeft: 20,
  },
  profilePictureContainer: {
    flex: 0.16,
    height: '100%',
    paddingRight: Spacings.Medium,
  },
  skillsContainer: { flex: 0.84, height: '100%' },
  workerNameContainer: {
    width: '50%',
    zIndex: 1,
  },
  workerNameTextStyle: {
    paddingTop: Spacings.xMedium,
    fontSize: fontSize.Regular,
    ...TextFontStyles.LatoRegular,
    color: Colors.Black,
  },
  skillsInnerContainer: { flex: 1 },
  skillsFlatListStyle: {
    width: '100%',
    height: '100%',
  },
  skillsFlatListContentContainerStyle: { flex: 1 },
  skillValidateRow: {
    paddingTop: Spacings.Standard,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  skillNameContainer: {
    width: '55%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: Spacings.Standard,
  },
  skillValidateSVG: { top: 0, left: -Spacings.Unit },
  skillNameTextStyle: {
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Small,
  },
  validateSkillContainer: {
    paddingRight: Spacings.CardPadding,
  },
  validateSkillTouchable: {
    height: '100%',
    borderColor: Colors.Black,
    borderWidth: 1,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: 76,
  },
  validateSkillTextStyle: {
    fontSize: 12,
    fontFamily: 'Lato, Black',
    fontWeight: 'bold',
  },
  durationContainer: {
    width: 'auto',
    height: 15,
    position: 'absolute',
    right: Spacings.Standard + Spacings.Unit,
    top: -Spacings.Unit,
    zIndex: 2,
  },
  durationTextStyle: {
    fontWeight: 'normal',
    fontFamily: 'Lato, Regular',
    fontSize: 12,
    color: '#606060',
    textAlign: 'right',
  },
  titleContainer: {
    justifyContent: 'center',
    borderBottomColor: Colors.Background,
    borderBottomWidth: 1,
  },
  title: {
    ...TextFontStyles.LatoBlack,
    fontSize: Spacings.Medium,
    paddingLeft: Spacings.CardPadding,
    paddingVertical: Spacings.Medium,
  },
  listCount: {
    ...TextFontStyles.LatoRegular,
    fontSize: Spacings.Medium,
  },
  circleView: {
    ...createRoundedStyle(6),
    backgroundColor: Colors.Yellow,
    right: 6,
    top: -2,
  },
  emptyListContainer: {
    alignItems: 'center',
    paddingTop: 88,
  },
  emptyListIconContainer: {
    height: 42,
    width: 42,
  },
  emptyListText: {
    paddingTop: Spacings.CardPadding,
    width: 86,
    textAlign: 'center',
    ...TextFontStyles.LatoRegular,
    fontSize: fontSize.Regular,
    color: Colors.Grey,
  },
  activityIndicatorContainer: {
    paddingTop: Spacings.Big,
  },
  toolTipContainer: {
    top: 30,
  },
});

export default styles;
